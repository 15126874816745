import { Col, Row } from 'antd';
import CommonInfoCard from '../CommonInfoCard';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { EnergyAnalysisOptimizeEchartsRes } from '../EnergyComparison';

interface Props {
  data: EnergyAnalysisOptimizeEchartsRes;
  isShowRecent: boolean;
}

export enum BackgroundColorMap {
  default = 0,
  red = 1,
  blue = 2,
  green = 3,
}

const getMinBackColor = (data: EnergyAnalysisOptimizeEchartsRes) => {
  const arr = [
    {
      num: data.energyBeforeOptimization as number,
      color: BackgroundColorMap.red,
    },
    {
      num: data.energyAfterOptimization as number,
      color: BackgroundColorMap.blue,
    },
    {
      num: data.energyRecently as number,
      color: BackgroundColorMap.green,
    },
  ]
    .filter(item => !isNil(item.num))
    .sort((a, b) => a.num - b.num);

  return arr.length === 0 ? BackgroundColorMap.default : arr[0].color;
};

const MediumComparison: React.FC<Props> = ({ data, isShowRecent }) => {
  const [beforeColor, setBeforeCole] = useState<BackgroundColorMap>(BackgroundColorMap.default);
  const [afterColor, setAfterCole] = useState<BackgroundColorMap>(BackgroundColorMap.default);
  const [recentColor, setRecentCole] = useState<BackgroundColorMap>(BackgroundColorMap.default);

  useEffect(() => {
    if (data) {
      // 切换优化项目时，清空颜色
      setBeforeCole(BackgroundColorMap.default);
      setAfterCole(BackgroundColorMap.default);
      setRecentCole(BackgroundColorMap.default);

      const color = getMinBackColor(data);
      if (color === BackgroundColorMap.red) {
        setBeforeCole(color);
      } else if (color === BackgroundColorMap.blue) {
        setAfterCole(color);
      } else {
        setRecentCole(color);
      }
    }
  }, [data]);

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        <div className={styles.divi}>
          <div className={styles.bef} />
          <p>{data.energyMediumName}用量对比</p>
          <div className={styles.aft} />
        </div>
      </Col>
      {isNil(data.energyBeforeOptimization) ? null : (
        <Col span={24}>
          <CommonInfoCard
            title={`优化前日均基准用量 (${data.defaultPhysicalUnitName})`}
            num={data.energyBeforeOptimization}
            beforeColor={beforeColor}
          />
        </Col>
      )}
      {isNil(data.energyAfterOptimization) ? null : (
        <Col span={24}>
          <CommonInfoCard
            title={`优化后30日均用量 (${data.defaultPhysicalUnitName})`}
            num={data.energyAfterOptimization}
            afterColor={afterColor}
          />
        </Col>
      )}
      {isNil(data.energyRecently) || !isShowRecent ? null : (
        <Col span={24}>
          <CommonInfoCard
            title={`近期日均用量 (${data.defaultPhysicalUnitName})`}
            num={data.energyRecently}
            recentColor={recentColor}
          />
        </Col>
      )}
    </Row>
  );
};
export default MediumComparison;
