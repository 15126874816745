import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, Button, Modal } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, useEffect, useMemo, useState } from 'react';
import { BalanceAlarmStatusType, BalanceAlarmStatusTypeValue } from '../../../../api/balanceAlarmManagement';
import {
  getServerAlarmConfigTableHeader,
  ServerAlarmConfigTableHeader,
  ServerAlarmPushRuleResponse,
} from '../../../../api/serverAlarm';
import { RuleLogic, RuleLogicDisplay } from '@/pages/PvAlarm/types';

import styles from './index.module.scss';

import RuleModal from './RuleModal';

export interface SelectServerAlarmRulesProps {
  value?: ServerAlarmPushRuleResponse[];
  onChange?: (value: ServerAlarmPushRuleResponse[]) => void;
  ouId?: number;
  sceneType?: number;
  disabled?: boolean;
}
export enum SceneTypeEnum {
  BalanceAlarm = 1,
  LineLossAlarm = 2,
  EnergyAlarm = 3,
  DiscreteRateAlarm = 4,
}

const SelectServerAlarmRules: FC<SelectServerAlarmRulesProps> = ({
  value,
  onChange,
  ouId,
  sceneType,
  disabled = false,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<ServerAlarmPushRuleResponse[]>([]);

  const [dynamicColumns, setDynamicColumns] = useState<ServerAlarmConfigTableHeader[]>([]);

  const PvAlarmColumns = useMemo(() => {
    if (sceneType === SceneTypeEnum.DiscreteRateAlarm) {
      return [
        {
          title: '光伏站',
          dataIndex: 'pvName',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        {
          title: '规则名称',
          dataIndex: 'name',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
        {
          title: '规则逻辑',
          dataIndex: 'ruleLogic',
          ellipsis: { showTitle: true },
          render: (v: RuleLogic) => <EllipsisSpan value={RuleLogicDisplay[v]} />,
        },
      ];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneType]);

  useEffect(() => {
    if (sceneType) {
      getServerAlarmConfigTableHeader(sceneType).then(res => setDynamicColumns(res.list || []));
    }
  }, [sceneType]);

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (ouId === undefined) {
      Modal.warning({
        title: '请先选择运营单元',
      });
      return;
    }
    if (sceneType === undefined) {
      Modal.warning({
        title: '请先选择报警规则场景',
      });
      return;
    }
    setVisible(true);
  };

  const unBind = (record: ServerAlarmPushRuleResponse) => {
    Modal.confirm({
      title: '取消绑定？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.id !== record.id);
        onChange?.(newValue);
      },
    });
  };

  const columns: ColumnType<ServerAlarmPushRuleResponse>[] = useMemo(() => {
    return [
      {
        title: '规则编号',
        dataIndex: 'code',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      ...(dynamicColumns ?? [])
        .sort((a, b) => a.sequence - b.sequence)
        .map(i => ({
          title: i.title,
          dataIndex: i.key,
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        })),
      ...PvAlarmColumns,
      ...(sceneType === SceneTypeEnum.DiscreteRateAlarm
        ? []
        : [
            {
              title: '规则名称',
              dataIndex: 'name',
              ellipsis: { showTitle: true },
              render: (v: string) => <EllipsisSpan value={v} />,
            },
          ]),

      ...(sceneType === SceneTypeEnum.EnergyAlarm
        ? [
            {
              title: '报警等级',
              dataIndex: 'name',
              ellipsis: { showTitle: true },
              render: (v: string) => <EllipsisSpan value={v} />,
            },
            {
              title: '报警对象',
              dataIndex: 'name',
              ellipsis: { showTitle: true },
              render: (v: string) => <EllipsisSpan value={v} />,
            },
          ]
        : []),

      {
        title: '状态',
        dataIndex: 'status',
        ellipsis: { showTitle: true },
        render: (v: BalanceAlarmStatusType) => <EllipsisSpan value={BalanceAlarmStatusTypeValue[v]} />,
      },
    ];
  }, [dynamicColumns, PvAlarmColumns, sceneType]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: ServerAlarmPushRuleResponse) => {
        return (
          <Button
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      {!disabled && (
        <Space direction="vertical">
          <Button type="primary" icon={<PlusOutlined />} wrapStyle={{ marginBottom: 10 }} onClick={onAdd}>
            添加
          </Button>
          <div />
        </Space>
      )}
      <Table
        sticky
        scroll={{ x: 1300, y: 400 }}
        columns={disabled ? columns : buildColumns}
        rowKey="id"
        dataSource={value ?? []}
      />
      <Modal
        className={styles.modal}
        title="报警规则选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <RuleModal
          ouId={ouId?.toString()}
          onChange={setDataSource}
          value={dataSource}
          disabledIds={disabledIds}
          columns={columns}
          sceneType={sceneType}
        />
      </Modal>
    </>
  );
};

export default SelectServerAlarmRules;
