import { CarbonFootprintSourceDisplay, MaterielTypeDisplay, SystemBoundaryDisplay } from '@/api/materielManagement';
import { OutputUnitTypeDisplay } from '@/api/outputConfig';

export const MaterielTypeOptions = Object.entries(MaterielTypeDisplay).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export const CarbonFootprintSourceOptions = Object.entries(CarbonFootprintSourceDisplay).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export const SystemBoundaryOptions = Object.entries(SystemBoundaryDisplay).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export const OutputUnitTypeOptions = Object.entries(OutputUnitTypeDisplay).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));
