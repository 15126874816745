import { V2StationSignboardNodeDetailIndicatorChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';
import { ChartsDataReq } from '.';

export interface DateBtn {
  aggrby: DatePickerType;
}

export enum DatePickerType {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}

export const datePickerTypeDisplay = {
  [DatePickerType.MINUTE_1]: '1分钟',
  [DatePickerType.MINUTE_15]: '15分钟',
  [DatePickerType.HOUR]: '按小时',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

export interface ParamsOption {
  chartData?: V2StationSignboardNodeDetailIndicatorChartValuePostResponse;
  query?: ChartsDataReq;
}

// 单设备指标枚举
export enum SingleIndicatorType {
  AirCompressorStatus = 6, // 状态
  AirCompressorPowerConsumption = 7, // 用电量
  AirCompressorPowerToElectricityRatio = 8, // 气电比
  AirCompressorExhaustPressure = 9, // 排气压力
  AirCompressorOutputFlow = 10, // 输出流量
  AirCompressorCurrent = 11, // 电流
  AirCompressorVoltage = 12, // 电压
  AirCompressorPower = 13, // 功率
  AirTankPressure = 14, // 压力
  AirStationExitPressure = 15, // 出口压力
  AirStationExitFlow = 16, // 出口流量
  AirThermometerTemperature = 17, // 温度

  AirColdDryerPowerConsumption = 36, // 空压站-冷干机-用电量
  AirColdDryerEnergyEffciencyRatio = 37, // 空压站-冷干机-能效比
  AirColdDryerCurrent = 38, // 空压站-冷干机-电流
  AirColdDryerVoltage = 39, // 空压站-冷干机-电压
  AirColdDryerProcessingCapacity = 40, // 空压站-冷干机-气体处理量
  AirColdDryerDewPointTemperature = 41, // 空压站-冷干机-露点温度
  AirColdDryerWorkingPressure = 42, // 空压站-冷干机-工作压力
  AirColdDryerIntakeAirTemperature = 43, // 空压站-冷干机-进气温度
  AirColdDryerPower = 44, // 空压站-冷干机-功率
  AirFlowMeterInstantaneous = 45, // 空压站-流量计-瞬时流量
  AirFlowMeterCumulative = 46, // 空压站-流量计-累计流量
  AirPressureGaugePressure = 47, // 空压站-压力表-压力

  NGCompressorStatus = 23, // 状态
  NGCompressorPowerConsumption = 24, // 用电量
  NGCompressorPowerToElectricityRatio = 25, // 气电比
  NGCompressorExhaustPressure = 26, // 氮气纯度
  NGCompressorOutputFlow = 27, // 输出流量
  NGCompressorCurrent = 28, // 电流
  NGCompressorVoltage = 29, // 电压
  NGCompressorPower = 30, // 功率
  NGTankPressure = 31, // 压力
  NGStationExitPressure = 32, // 出口压力
  NGStationExitFlow = 33, // 出口流量
  NGThermometerTemperature = 34, // 温度
}

export interface SingleCurrentItemType {
  id?: SingleIndicatorType;
  btnName?: string;
  unit?: string;
  name?: string; // tab名称
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
  getChartOption?: (value: ParamsOption) => any;
}
