import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  usePaging,
  useUpdate,
  Button,
  Form,
  Modal,
  Select,
  Input,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BalanceAlarmListPageSearchRequest,
  BalanceAlarmListResponse,
  BalanceAlarmStatusType,
  BalanceAlarmStatusTypeValue,
  deleteBalanceAlarmRecord,
  getBalanceAlarmList,
  updateBalanceAlarmStatus,
} from '../../../../api/balanceAlarmManagement';
import { getOuListOwned, OuListResponse } from '../../../../api/ou';
import { getSaleClientList, SaleClientInfo } from '../../../../api/sale';
import { PermissionsType } from '../../../../common/permissionsConst';
import { useHasPermission } from '../../../../utils/utils';
import styles from './index.module.scss';

const formatString = 'YYYY-MM-DD HH:mm:ss';

const columns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    width: 114,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: ['balanceSceneInfo', 'saleClientName'],
    width: 141,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '客户账户',
    dataIndex: ['balanceSceneInfo', 'saleClientAccountName'],
    width: 103,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    width: 142,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 142,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 110,
    ellipsis: { showTitle: true },
    render: (v: BalanceAlarmStatusType, record: BalanceAlarmListResponse) => {
      let statusText = '';
      switch (v) {
        case BalanceAlarmStatusType.Disable:
          statusText = '禁用';
          break;
        case BalanceAlarmStatusType.Enable:
          statusText = '启用';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 135,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format(formatString)} `} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 155,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface SearchParams extends Omit<BalanceAlarmListPageSearchRequest, 'page' | 'size'> {}

const BalanceAlarmManagementList: FC = () => {
  const hasBalanceServerRuleAdd = useHasPermission(PermissionsType.B_BALANCESERVERRULEADD); // 新增余额报警规则
  const hasBalanceServerRuleBatchAdd = useHasPermission(PermissionsType.B_BALANCESERVERRULEBATCHADD); // 批量新增余额报警规则
  const hasBalanceServerRuleDelete = useHasPermission(PermissionsType.B_BALANCESERVERRULEDELETE); // 删除余额报警规则
  const hasBalanceServerRuleUpdate = useHasPermission(PermissionsType.B_BALANCESERVERRULEDATE); // 编辑余额报警规则
  const hasBalanceServerRuleEnableDisable = useHasPermission(PermissionsType.B_BALANCESERVERRULEENABLEDISABLE); // 禁用/启用余额报警规则
  const [ou, setOu] = useState<OuListResponse[]>([]);
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [searchParams, setSearchParams] = useState<SearchParams>(); // 搜索参数
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [updateState, updateFn] = useUpdate(); // 更新
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置
  const [data, setData] = useState<BalanceAlarmListResponse[]>([]); // 后端返回数据

  useEffect(() => {
    getOuListOwned().then(setOu); // 所属运营单元下拉选择
    getSaleClientList().then(setCustomList); // 所属客户下拉选择搜索
  }, []);

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  useEffect(() => {
    setLoading(true);
    getBalanceAlarmList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  // 搜索表单提交
  const onFinish = (val: SearchParams) => {
    const params = {
      saleClientId: val.saleClientId,
      code: val.code,
      status: val.status,
      ouId: val.ouId,
      saleClientAccountName: val.saleClientAccountName,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  // 搜索重置
  const onReset = () => {
    const params = {
      code: undefined, // 规则编号
      saleClientAccountName: undefined, // 客户账户名称
      status: undefined, // 状态
      ouId: undefined, // 所属运营单元
      saleClientId: undefined, // 所属客户id
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  // 删除
  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      title: <span>确认删除？</span>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          <span style={{ color: 'red' }}>删除后不可恢复，</span>
          <span style={{ color: 'gray' }}>你还要继续吗？</span>
        </span>
      ),
      okText: '继续',
      onOk() {
        deleteBalanceAlarmRecord(id)
          .then(res => {
            // message.success('删除成功');
            updateFn();
          })
          .catch(err => {
            // message.error('删除失败', err)
          });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  // 改变状态
  const onChangeStatus = (record: BalanceAlarmListResponse) => {
    Modal.confirm({
      title: (
        <span>{`确认${
          record.status === BalanceAlarmStatusType.Enable
            ? BalanceAlarmStatusTypeValue[BalanceAlarmStatusType.Disable]
            : BalanceAlarmStatusTypeValue[BalanceAlarmStatusType.Enable]
        }？`}</span>
      ),
      content:
        record.status === BalanceAlarmStatusType.Enable ? (
          <span>
            <span style={{ color: '#E64242' }}>禁用后报警规则不再生效，</span>
            <span style={{ color: 'gray' }}>你还要继续吗？</span>
          </span>
        ) : (
          <></>
        ),
      okText: '继续',
      onOk() {
        updateBalanceAlarmStatus(record.id, {
          status:
            record.status === BalanceAlarmStatusType.Enable
              ? BalanceAlarmStatusType.Disable
              : BalanceAlarmStatusType.Enable,
        })
          .then(res => {
            // message.success('修改状态成功');
            updateFn();
          })
          .catch(err => {
            // message.error('修改状态失败', err)
          });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  // 表格操作栏
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: BalanceAlarmListResponse) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasBalanceServerRuleDelete && (
              <Button
                disabled={record.status === BalanceAlarmStatusType.Enable}
                type="link"
                onClick={() => onDeleteRecord(record.id)}
              >
                删除
              </Button>
            )
          }
          {hasBalanceServerRuleEnableDisable && (
            <Button
              // disabled={(record.status === ElectricitySettlementRulesStatusType.ABORTED)}
              type="link"
              onClick={() => onChangeStatus(record)}
            >
              {record.status === BalanceAlarmStatusType.Disable ? '启用' : '禁用'}
            </Button>
          )}
          {hasBalanceServerRuleUpdate && (
            <Button
              // disabled={!(record.status === ElectricitySaleContractStatusType.INEFFECTIVE || record.status === ElectricitySaleContractStatusType.EFFECTIVE)}
              type="link"
            >
              <Link to={`/operation/balanceAlarm/management/edit/${record.id}`}>编辑</Link>
            </Button>
          )}
          {
            <Button
              // disabled={!record.editable || record.status === ElectricityContractStatus.INVALID || !(dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date')))}
              type="link"
            >
              <Link to={`/operation/balanceAlarm/management/details/${record.id}`}>查看</Link>
            </Button>
          }
        </Space>
      ),
    },
  ];
  const breadcrumbRoutes = useBreadcrumbRoutes();
  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      className={styles.content}
      filters={
        <CustomFilter form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset} colSpan={6}>
          <Form.Item label="规则编号" name="code">
            <Input placeholder=" 请输入编号查询" />
          </Form.Item>

          <Form.Item label="客户账户名称" name="saleClientAccountName">
            <Input placeholder=" 请输入名称查询" />
          </Form.Item>

          <Form.Item label="状态" name="status">
            <Select placeholder="请选择">
              <Select.Option value={BalanceAlarmStatusType.Disable}>
                {BalanceAlarmStatusTypeValue[BalanceAlarmStatusType.Disable]}
              </Select.Option>
              <Select.Option value={BalanceAlarmStatusType.Enable}>
                {BalanceAlarmStatusTypeValue[BalanceAlarmStatusType.Enable]}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="ouId" label="所属运营单元">
            <Select placeholder="请选择" showSearch options={ouOptions} optionFilterProp="label" />
          </Form.Item>

          <Form.Item name="saleClientId" label="所属客户">
            <Select placeholder="请选择">
              {customList.map(i => (
                <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </CustomFilter>
      }
    >
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasBalanceServerRuleAdd && (
          <Button type="primary" icon={<PlusOutlined />}>
            <Link to={`/operation/balanceAlarm/management/create`}>新建规则</Link>
          </Button>
        )}
        {hasBalanceServerRuleBatchAdd && (
          <Button type="primary">
            <Link to={`/operation/balanceAlarm/management/mutiCreate`}>批量新建规则</Link>
          </Button>
        )}
      </Space>

      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={data}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default BalanceAlarmManagementList;
