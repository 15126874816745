import {
  Button,
  EllipsisSpan,
  SubContent,
  Paging,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  FormTitle,
} from '@maxtropy/components';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { Space } from 'antd';
import CreateForm from './CreateForm';
import { useEffect, useState } from 'react';
import { ActionType } from '@/pages/Account/utils';
import { isNil } from 'lodash-es';
import { RatePageRes, delRate, getRatePage } from '@/api/workingSubstance';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const formatString = 'YYYY-MM-DD';

const RateConfig: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [update, updateFn] = useUpdate();
  const [isShow, setIsShow] = useState<ActionType | undefined>();
  const pagingInfo = useSearchParams(50);
  const { searchParams, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_unit = urlSearchParams.get('unit') || undefined;
  const url_precision = urlSearchParams.get('precision') || undefined;
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<RatePageRes[]>([]);
  const [row, setRow] = useState<RatePageRes>();
  const navigate = useNavigate();

  const columns = [
    {
      title: '时间',
      dataIndex: 'startTime',
      ellipsis: { showTitle: true },
      render: (v: string, record: RatePageRes) => (
        <EllipsisSpan
          value={`${dayjs(v).format(formatString)} 至 ${
            isNil(record.endTime) ? '--' : dayjs(record.endTime).format(formatString)
          }`}
        />
      ),
    },
    {
      title: `价格（元/${url_unit ?? '--'}）`,
      dataIndex: 'price',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v.toFixed(Number(url_precision))} />,
    },
    {
      title: `操作`,
      ellipsis: { showTitle: true },
      render: (v: number, record: RatePageRes) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setIsShow(ActionType.UPDATE);
                setRow(record);
              }}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => {
                delRate(record.id).then(() => {
                  updateFn();
                });
              }}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      getRatePage({
        mediumContractId: id,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, searchParams, update, setTotalCount]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyle}>
      <FormTitle title="费率详情" />
      <SubContent>
        <div style={{ paddingBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsShow(ActionType.CREATE);
              setRow(undefined);
            }}
          >
            新增费率区间
          </Button>
        </div>

        <Table rowKey="id" columns={columns} loading={loading} dataSource={data} />
        <Paging pagingInfo={pagingInfo} />
      </SubContent>
      <div className="sticky-footer">
        <Button
          onClick={() => {
            navigate(`/operation/energy/contract?tab=${'work'}`);
          }}
        >
          返回
        </Button>
      </div>
      {!!isShow && (
        <CreateForm
          mediumContractId={id}
          isShow={isShow}
          unit={url_unit}
          precision={Number(url_precision)}
          setIsShow={setIsShow}
          updateFn={updateFn}
          data={row}
        />
      )}
    </Wrapper>
  );
};
export default RateConfig;
