import {
  Form,
  Input,
  Select,
  useBreadcrumbRoutes,
  useTenantPermissions,
  Wrapper,
  Button,
  CustomFilter,
} from '@maxtropy/components';
import { Space, SelectProps } from 'antd';
import ListTable from './components/List';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs';
import { PermissionsType } from '../../common/permissionsConst';
import { AlarmType, AlarmTypeDisplay } from './types';

import styles from './index.module.scss';
import {
  V2ServeAlarmRulePagePostRequest,
  apiV2ServeAlarmRulePvStationListPost,
} from '@maxtropy/device-customer-apis-v2';

const { Option } = Select;

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const fetch = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    apiV2ServeAlarmRulePvStationListPost({ name: value }).then(res => {
      if (currentValue === value) {
        const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id }));
        callback(options);
      }
    });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

const PvAlarmList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<V2ServeAlarmRulePagePostRequest>();
  const [pvOption, setPvOption] = useState<SelectProps['options']>([]);

  const permission = useTenantPermissions();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const onFinish = (val: V2ServeAlarmRulePagePostRequest) => {
    setSearchParams({ ...val });
    const paramsMap = new Map<string, string>();
    Object.entries(val).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params = {
      pageNum: 1,
      pageSize: 10,
      pvName: undefined,
      ruleName: undefined,
      pvCode: undefined,
      status: undefined,
      ruleCode: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
  };

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setPvOption);
    }
  };

  const getPvstationOptions = async (value: string) => {
    const res = await apiV2ServeAlarmRulePvStationListPost({ name: value });
    if (res.list) {
      const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id, code: item.code }));
      setPvOption(options);
      return res.list ?? [];
    }
  };

  useEffect(() => {
    getPvstationOptions('');
  }, []);

  const filters = (
    <CustomFilter form={form} onFinish={val => onFinish(val as V2ServeAlarmRulePagePostRequest)} onReset={onReset}>
      <Form.Item
        name="pvCode"
        label="光伏站编号"
        rules={[
          { max: 30 },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '只允许输入字母和数字',
          },
        ]}
      >
        <Input placeholder="请输入光伏站编号"></Input>
      </Form.Item>
      <Form.Item name="pvIds" label="光伏站名称">
        <Select
          placeholder="请输入并选择光伏站"
          showSearch
          mode="multiple"
          options={pvOption}
          allowClear
          key="id"
          optionFilterProp="label"
          onSearch={handleSearch}
          onChange={() => {
            getPvstationOptions('');
          }}
        ></Select>
      </Form.Item>

      <Form.Item name="status" label="规则状态">
        <Select placeholder="请选择规则状态">
          <Option value={AlarmType.Enable}>{AlarmTypeDisplay[AlarmType.Enable]}</Option>
          <Option value={AlarmType.Disable}>{AlarmTypeDisplay[AlarmType.Disable]}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="ruleCode"
        label="规则编号"
        rules={[
          { max: 30 },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '只允许输入字母和数字',
          },
        ]}
      >
        <Input placeholder="请输入规则编号"></Input>
      </Form.Item>
      <Form.Item name="ruleName" label="规则名称" rules={[{ max: 20 }]}>
        <Input placeholder="请输入规则名称"></Input>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      <div className={styles.operationArea}>
        <Space>
          {(permission ?? []).includes(PermissionsType.B_SERVERALARMRULECREATE) && (
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to={`/pvOps/server/alarm/create`}>新建规则</Link>
            </Button>
          )}
        </Space>
      </div>

      <ListTable searchParams={searchParams} />
    </Wrapper>
  );
};

export default PvAlarmList;
