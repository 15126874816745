import SrceenImg from './images/screen.jpg';
import styles from './index.module.scss';

const Sceen: React.FC = () => {
  return (
    <div style={{ width: '100vw', overflowX: 'hidden', position: 'relative' }}>
      <img style={{ width: '100vw' }} src={SrceenImg} alt="" />
      <div
        className={styles.gasHot}
        onClick={() => {
          window.open('/temporary/basic/gasLoss', '_blank');
        }}
      ></div>
      <div
        className={styles.deviceHot}
        onClick={() => window.open(`${window.DMEPLATFORMSORIGIN}/dmes/alarm/record`, '_blank')}
      ></div>
    </div>
  );
};

export default Sceen;
