import dayjs, { Dayjs } from 'dayjs';

// 时间颗粒度, 按小时, 天, 月
export enum TimeGranularity {
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}

export const TimeGranularityDisplay = {
  [TimeGranularity.HOUR]: '按小时',
  [TimeGranularity.DAY]: '按日',
  [TimeGranularity.MONTH]: '按月',
};

export const TimeGranularityFormatDisplay = {
  [TimeGranularity.HOUR]: 'YYYY-MM-DD HH:mm',
  [TimeGranularity.DAY]: 'YYYY-MM-DD',
  [TimeGranularity.MONTH]: 'YYYY-MM',
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// 不同时间段下的初始值
export const TimeGranularityInitialValue: Record<string, RangeValue> = {
  [TimeGranularity.DAY]: [dayjs().subtract(30, 'day').startOf('date'), dayjs().endOf('date')],
  [TimeGranularity.MONTH]: [
    dayjs().startOf('month').subtract(11, 'month').startOf('month'),
    dayjs().endOf('month').endOf('month'),
  ],
  [TimeGranularity.HOUR]: [dayjs().startOf('date'), dayjs().endOf('date')],
};
