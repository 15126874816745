import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Key,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Tag,
  Paging,
  CustomFilter,
  Form,
  Select,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import AddMaterielModal from './AddMaterielModal';
import {
  MaterielManagementListParams,
  MaterielManagementListRes,
  MaterielStatus,
  MaterielStatusDisplay,
  MaterielType,
  MaterielTypeDisplay,
  delMateriel,
  getMaterielManagementList,
} from '@/api/materielManagement';
import { MaterielTypeOptions } from './untils';
import dayjs from 'dayjs';
import DetailMaterielModal from './DetailMaterielModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const statusOptions = [
  { label: '全部', value: null },
  ...Object.entries(MaterielStatusDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

const columns = [
  {
    title: '物料编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: MaterielType) => <EllipsisSpan value={MaterielTypeDisplay[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: MaterielStatus) => (
      <Tag border="solid" color={v === MaterielStatus.ENABLE ? 'success' : 'default'}>
        {MaterielStatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<MaterielManagementListParams, 'page' | 'size'> {}

const MaterielManagement: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<MaterielManagementListRes[]>([]);
  const [selectedId, setSelectedId] = useState<Key>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(20, {
    state: MaterielStatus.ENABLE,
    type: null,
  });
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_MATERIAL_ADD); // 新建物料
  const hasEdit = useHasPermission(PermissionsType.B_MATERIAL_EDIT); // 编辑物料
  const hasDelete = useHasPermission(PermissionsType.B_MATERIAL_DEL); // 删除物料

  useEffect(() => {
    setLoading(true);
    getMaterielManagementList({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onDelete = (id: Key) => {
    delMateriel(id).then(() => {});
    updateFn();
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 191,
      fixed: 'right' as 'right',
      render: (value: undefined, record: MaterielManagementListRes) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setSelectedId(record.id);
                setDetailModalOpen(true);
              }}
            >
              查看
            </Button>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  setSelectedId(record.id);
                  setModalOpen(true);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm placement="top" title="是否删除此物料？" onConfirm={() => onDelete(record.id)}>
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      state: MaterielStatus.ENABLE,
      type: null,
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: MaterielStatus.ENABLE,
        type: null,
      }}
    >
      <Form.Item label="状态" name="state">
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item label="物料类型" name="type">
        <Select options={[{ label: '全部', value: null }, ...MaterielTypeOptions]} />
      </Form.Item>
      <Form.Item label="物料" name="codeOrNameOrSpec">
        <Input placeholder="请输入物料编码/名称/规格搜索" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <Space style={{ marginBottom: 10 }}>
        {hasAdd && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedId(undefined);
              setModalOpen(true);
            }}
          >
            新建物料
          </Button>
        )}
      </Space>
      <Table columns={buildColumns} dataSource={data} rowKey="id" loading={loading} />
      <Paging pagingInfo={pagingInfo} />
      {modalOpen && (
        <AddMaterielModal open={modalOpen} selectedId={selectedId} setOpen={setModalOpen} updateFn={updateFn} />
      )}
      {detailModalOpen && (
        <DetailMaterielModal open={detailModalOpen} setOpen={setDetailModalOpen} selectedId={selectedId} />
      )}
    </Wrapper>
  );
};

export default MaterielManagement;
