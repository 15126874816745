import BillImg from './images/bill.png';
import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {}

const EnergyBill: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapperContent} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <img style={{ width: '100%' }} src={BillImg} alt="" />
    </Wrapper>
  );
};

export default EnergyBill;
