import { AlarmDataStatusDisplay, AlarmDataType, AlarmDataTypeDisplay } from '@/shared/types';
import { V2RuleInfoPostResponse } from '@maxtropy/device-customer-apis-v2';
import { Descriptions } from 'antd';
import { isEmpty } from 'lodash-es';

export const TripartiteServer = (info?: V2RuleInfoPostResponse) => {
  return (
    <>
      <Descriptions.Item label="三方报警码">{info?.thirdAlarmCode ?? '--'} </Descriptions.Item>
      <Descriptions.Item label="是否具备报警数据">
        {AlarmDataStatusDisplay[info?.hasAlamData!] ?? '--'}
      </Descriptions.Item>
      <Descriptions.Item label="报警数据">
        {isEmpty(info?.alamData)
          ? '--'
          : info?.alamData?.map(item => AlarmDataTypeDisplay[item as AlarmDataType]).join(', ')}
      </Descriptions.Item>
    </>
  );
};
