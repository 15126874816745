import { Key, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import {
  apiV2CarbonFootprintOverviewDistributionPost,
  V2CarbonFootprintOverviewDistributionPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Spin } from 'antd';
import { Empty } from '@maxtropy/components';
import Subtitle from '../Subtitle';
import { transCarbonFootprintQuantity, transPercent } from '../../utils';
import styles from './index.module.scss';

const DistributionOfProductCarbonFootprint = ({ id }: { id?: Key }) => {
  const [data, setData] = useState<V2CarbonFootprintOverviewDistributionPostResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    apiV2CarbonFootprintOverviewDistributionPost({ id })
      .then(res => {
        const rawMaterialAcquisitionLinks = res.links?.filter(link => link.source === '原材料获取');
        const rawMaterialTransportationLinks = res.links?.filter(link => link.source === '原材料运输');
        const productManufacturingLinks = res.links?.filter(link => link.source === '生产');

        const allNode =
          res.nodes?.map(node => {
            let color = '#52E7FF';
            if (node.name === '原材料获取') {
              color = '#16DD8E';
            }
            if (node.name === '原材料运输') {
              color = '#00ADFF';
            }
            if (node.name === '生产') {
              color = '#FFCB47';
            }
            if (rawMaterialAcquisitionLinks?.find(link => link.target === node.name)) {
              color = '#ABD335';
            }
            if (rawMaterialTransportationLinks?.find(link => link.target === node.name)) {
              color = '#CE90D1';
            }
            if (productManufacturingLinks?.find(link => link.target === node.name)) {
              color = '#FF9247';
            }
            if (node.name === '其他') {
              color = '#FF477B';
            }
            return {
              ...node,
              label: node.name,
              name: node.key,
              itemStyle: {
                color,
              },
              lineStyle: {
                color: 'gradient',
                opacity: 0.4,
              },
            };
          }) || [];

        const links =
          res.links?.map(link => {
            return {
              ...link,
              sourceName: link.source,
              targetName: link.target,
              source: link.sourceId,
              target: link.targetId,
            };
          }) || [];

        setData({ links, nodes: allNode });
      })
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <div className={styles.distributionOfPCFCon}>
      <Subtitle title="产品碳足迹分布" />
      <Spin spinning={loading}>
        <div className={styles.distributionChart}>
          {data ? (
            <ReactEcharts
              style={{
                width: '100%',
                height: '100%',
              }}
              option={getChartOption(data)}
              notMerge
              lazyUpdate={false}
            />
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </div>
  );
};
const getChartOption = (data: V2CarbonFootprintOverviewDistributionPostResponse) => {
  return {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 0, 12],
      className: 'carbon-chart-tooltip',
      formatter: (tooltipData: any) => {
        let title;
        if (tooltipData.dataType === 'node') {
          // hover节点
          title = `${tooltipData.data.label}`;
        }
        if (tooltipData.dataType === 'edge') {
          // hover连接线
          title = `${tooltipData.data.sourceName}-${tooltipData.data.targetName}`;
        }

        return `
          <div class='carbon-tooltip-title'>${title}</div>
          <div>碳足迹<span>${transCarbonFootprintQuantity(tooltipData.data.value).join(' ')}</span></div>
          ${tooltipData.data.percent ? `<div>占比<span>${transPercent(tooltipData.data.percent)}</span></div>` : ''}
        `;
      },
    },
    label: {
      color: 'rgba(255,255,255,0.85)',
      formatter: (v: any) => {
        return `${v.data.label}`;
      },
    },
    series: [
      {
        type: 'sankey',
        nodeWidth: 8,
        nodeGap: 16,
        left: 0,
        right: 10,
        bottom: 10,
        top: 10,
        label: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
          distance: 8,
        },
        data: data.nodes,
        links: data.links,
        emphasis: {
          focus: 'adjacency',
        },
        levels: [1, 2, 3].map((i, index) => ({
          depth: index,
          lineStyle: {
            color: 'gradient',
            opacity: 0.4,
          },
          label: {
            position: index === 2 ? 'left' : 'right',
          },
        })),
      },
    ],
  };
};

export default DistributionOfProductCarbonFootprint;
