import TopoImg from './images/topo.jpg';
import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {}

const EnergyTopo: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapperContent} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <img style={{ width: '100%' }} src={TopoImg} alt="" />
      <div
        className={styles.powerHot}
        onClick={() => {
          window.open('/temporary/basic/powerLoss', '_blank');
        }}
      ></div>
      <div
        className={styles.energyHot}
        onClick={() => window.open(`${window.DMEPLATFORMSORIGIN}/dmes/alarm/record`, '_blank')}
      ></div>
    </Wrapper>
  );
};

export default EnergyTopo;
