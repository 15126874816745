import {
  Tooltip,
  Select,
  Tag,
  DatePicker,
  Form,
  Input,
  EllipsisSpan,
  Table,
  Wrapper,
  Button,
  usePaging,
  Paging,
  useBreadcrumbRoutes,
  CustomFilter,
} from '@maxtropy/components';
import { Progress, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { UseEnergyUnitAlarmList } from '@/api/alarm';
import dayjs from 'dayjs';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import {
  apiV2EnergyUnitServerAlarmGetRulePost,
  apiV2EnergyUnitServerAlarmPageRecordPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
const columns: any = [
  {
    title: '报警消息',
    dataIndex: 'alarmMsg',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '报警对象',
    dataIndex: 'alarmObjectName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    dataIndex: 'ruleName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmLevel',
    width: 150,
    render: (v: AlarmLevel) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
            {AlarmLevelDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '超限率',
    dataIndex: 'overRate',
    render: (v: number, record: any) =>
      isNil(record?.attribute?.overRate) ? (
        <EllipsisSpan value={'--'} />
      ) : (
        <Progress
          className={styles.prog}
          percent={record?.attribute?.overRate}
          format={overRate => (
            <div className={styles.progress_div}>
              <Tooltip placement="top" title={`${record?.attribute?.overRate}%`}>
                <div className={styles.ant_progress_text}>{record?.attribute?.overRate}%</div>
              </Tooltip>
            </div>
          )}
          status={record?.attribute?.overRate > 100 ? 'exception' : 'active'}
        />
      ),
  },
  {
    title: '报告时间',
    dataIndex: 'alarmTime',
    render: (v: any) => <EllipsisSpan className={styles.spanStyle} value={`${dayjs(v).format('YYYY-MM-DD HH:mm')} `} />,
  },
];
interface FilterParams extends Omit<UseEnergyUnitAlarmList, 'page' | 'size'> {}

// 点击发起工单时, 用设备id去查下ouId
const goHref = (alarmId: number, ruleId: number) => {
  apiV2EnergyUnitServerAlarmGetRulePost({ ruleId }).then(res => {
    window.open(
      `${window.ALARMWORKORDER}/workOrder/repairWorkOrder/workOrderList/workOrderAdd?${qs.stringify(
        {
          problemSource: 10,
          operationUnitCode: res.ouId,
          alarmId,
          alarmTypeCode: 3,
        },
        { indices: false }
      )}`,
      '_self'
    );
  });
};

const EnergyAlarmList: FC = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';
  const [data, setData] = useState<any>();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const navigate = useNavigate();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigator = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const alarmObjectName_url = urlSearchParams.get('alarmObjectName') || undefined;
  const alarmLevels_url = urlSearchParams.getAll('alarmLevels');
  const startDate_url = urlSearchParams.get('startDate') || undefined;
  const endDate_url = urlSearchParams.get('endDate') || undefined;

  const [searchParams, setSearchParams] = useState<FilterParams>({
    alarmObjectName: alarmObjectName_url ?? undefined,
    // @ts-ignore 定的是传number[]，结果传的是string[],暂先ignore
    alarmLevels: alarmLevels_url,
    startDate: startDate_url ? dayjs(Number(startDate_url)).format('YYYY-MM-DD') : '',
    endDate: endDate_url ? dayjs(Number(endDate_url)).format('YYYY-MM-DD') : '',
  });

  useEffect(() => {
    form.setFieldsValue({
      alarmObjectName: alarmObjectName_url,
      alarmLevels: alarmLevels_url,
      startDate:
        !isNil(startDate_url) && !isNil(endDate_url) ? [dayjs(+startDate_url), dayjs(+endDate_url)] : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: FilterParams) => {
    const params = {
      alarmObjectName: val.alarmObjectName ?? undefined,
      alarmLevels: val.alarmLevels ?? [],
      startDate: val.startDate ? val.startDate[0].format('YYYY-MM-DD') : '',
      endDate: val.startDate ? val.startDate[1].format('YYYY-MM-DD') : '',
    };

    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  useEffect(() => {
    //  报警列表初始渲染
    apiV2EnergyUnitServerAlarmPageRecordPost({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      setData(res.list);
      setTotalCount(Number(res.total));
    });
  }, [searchParams, pageOffset, pageSize, setTotalCount, setSearchParams]);

  const alarmLevelOptions = () => {
    return Object.entries(AlarmLevelDisplay).map(i => ({
      label: i[1],
      value: i[0],
    }));
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 200,
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigate(`/energy/analysisGroup/energyAlarmList/detail/${record.recordId}`);
              }}
            >
              查看
            </Button>
            <Button
              type="link"
              onClick={() => {
                navigator(
                  `/energy/unit/kanban?${qs.stringify(
                    {
                      energyGroupId: record.analysisGroupId,
                      unitId: record.unitId,
                      startDate: dayjs(record.alarmTime).format('YYYY-MM-DD HH:mm:ss'),
                      endDate: dayjs(record.alarmTime).format('YYYY-MM-DD HH:mm:ss'),
                    },
                    {
                      indices: false,
                    }
                  )}`
                );
              }}
            >
              用能数据
            </Button>
            <Button type="link" onClick={() => goHref(record.recordId, record.ruleId)}>
              发起工单
            </Button>
          </Space>
        );
      },
    },
  ];
  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="alarmObjectName" label="报警对象">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="alarmLevels" label="报警等级">
        <Select placeholder="请选择" options={alarmLevelOptions()} mode="multiple" />
      </Form.Item>
      <Form.Item name="startDate" label="报告时间">
        <RangePicker
          style={{ width: '100%' }}
          disabledDate={date => {
            return dayjs().isBefore(date);
          }}
          format={dateFormat}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.content} filters={filters}>
        <div className={styles.contentTable}>
          <Table
            rowKey="recordId"
            sticky
            scroll={{ x: 1300 }}
            dataSource={data}
            columns={buildColumns}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Wrapper>
    </>
  );
};

export default EnergyAlarmList;
