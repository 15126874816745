import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  TreeSelect,
  ShowInput,
} from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Space, Spin, Col, Row } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '@/shared/components/MemberSelect/interface';
import {
  ElectricitySaleContractStatus,
  ElectricitySaleContractVo,
  PropertyItem,
  getPropertyList,
  getSaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientId,
} from '@/api/electricitySalesContract';
import dayjs, { Dayjs } from 'dayjs';
import { SaleClientInfo, getSaleClientList } from '@/api/sale';
import CustomTariff from '../components/CustomTariff';
import PhotovoltaicDeviceTable from '../components/PhotovoltaicDeviceTable';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ElectricityAccountRes,
  EnergyAssetsPVStationDevice,
  EnergyAssetsPVStationInOu,
  EnergyAssetsSettlementsRulesBody,
  PriceType,
  addOrEditEnergyAssetsSettlementsRules,
  getElectricityAccountList,
  getEnergyAssetsPVStationDevice,
  getEnergyAssetsPVStationInOu,
  getEnergyAssetsSettlementsRulesDetail,
} from '@/api/energyAssetsSettlementsRules';
import { ElectricitySaleContractSettlementType, SettlementGeneratorMode } from '@/api/electricitySettlementRules';
import { isNil } from 'lodash-es';
import { limitDecimalsF, limitDecimalsFTwo, limitDecimalsPEight, limitDecimalsPTwo } from '../units';
import { BSADeviceItem, BSAItem, getBSADeviceItem, getBSAList } from '@/api/energyAssetsBill';
import BsaDeviceTable from '../components/BsaDeviceTable';

const formatTreeData = (data: any[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    // console.log(res)
    return res;
  });
};

interface Props {
  isCopy?: boolean;
}

interface FormProps extends EnergyAssetsSettlementsRulesBody {
  timeOfDayRateChecked: number[];
  customerMcid: string;
  effectiveTime: [Dayjs, Dayjs];
  priceType: PriceType; // 结算电价
  tariffTemplate: Key; // 电价模板， 用电账户id
}

export const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const { RangePicker } = DatePicker;
export const DATE_FORMAT = 'YYYY-MM-DD';

const CreateEnergyAssetsSettlementsRules: React.FC<Props> = ({ isCopy }) => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]); // 项目list 其实是物业list
  const [findSaleElectricityContract, setFindSaleElectricityContract] = useState<ElectricitySaleContractVo>();
  const customerMcid = Form.useWatch('customerMcid', form); // 选择树的id
  const propertyManagementConfigId = Form.useWatch('propertyManagementConfigId', form); // 选择物业的时候保存物业id，用于合同查找
  const priceType = Form.useWatch('priceType', form); // 结算电价
  const saleClientId = Form.useWatch('saleClientId', form); // 客户id
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [saleElectricityContractList, setSaleElectricityContractList] = useState<ElectricitySaleContractVo[]>([]); // 根据ou权限获取合同列表

  const [arrayId, setArrayId] = useState<Key>(); // 光伏站id
  const [pvTableLoading, setPVTableLoading] = useState(false);
  const [bsaTableLoading, setBsaTableLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState<boolean>(false); // 点击保存loading
  const [electricityAccountList, setElectricityAccountList] = useState<ElectricityAccountRes[]>([]); // 用电账户列表
  const [pvStationList, setPVStationList] = useState<EnergyAssetsPVStationInOu[]>([]); // 光伏站列表
  const [bsaStationList, setBsaStationList] = useState<BSAItem[]>([]);
  const [bsaStationDeviceData, setBsaStationDeviceData] = useState<BSADeviceItem[]>([]);
  const [pvStationDeviceData, setPVStationDeviceData] = useState<EnergyAssetsPVStationDevice[]>([]); // 光伏站下的设备列表
  const [copy, setCopy] = useState<boolean>(isCopy ? true : false); // 是否复制

  const routes = useMemo(() => {
    return [{ name: `${isCopy ? '复制结算规则' : '新建结算规则'}` }];
  }, [isCopy]);

  useEffect(() => {
    if (id && copy) {
      setLoading(true);
      getEnergyAssetsSettlementsRulesDetail(id)
        .then(res => {
          if (res) {
            const timeOfDayRateChecked = [res.summitPrice, res.peakPrice, res.plainPrice, res.valleyPrice]
              .map((i, index) => (isNil(i) ? 0 : index + 1))
              .filter(i => i !== 0);
            form.setFieldsValue({
              name: res.name,
              customerMcid: res.customerMcid,
              propertyManagementConfigId: res.propertyManagementConfigId,
              saleClientId: res.saleClientId,
              saleElectricityContractId: res.saleElectricityContractId,
              effectiveTime: [dayjs(res.startTime), dayjs(res.endTime)],
              priceType: res.settlementTemplate ? PriceType.TEMPLATE : PriceType.CUSTOM,
              tariffTemplate: res.settlementTemplate,
              timeOfDayRateChecked,
              summitPrice: res.summitPrice,
              peakPrice: res.peakPrice,
              plainPrice: res.plainPrice,
              valleyPrice: res.valleyPrice,
              gridPrice: res.gridPrice,
              discountForOwner: res.discountForOwner,
              gridPercentageForOwner: res.gridPercentageForOwner,
              settlementType: res.settlementType,
              generationMode: res.generationMode,
              arrayId: res.arrayId,
            });
            setArrayId(res.arrayId);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, copy, form]);

  // 查询所属组织
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
      if (res.children?.length === 0 && res.data.hasPermission === true) {
        form.setFieldsValue({ customerMcid: res.data.mcid });
      }
    });
  }, [form]);

  useEffect(() => {
    // 所属客户
    getSaleClientList().then(setCustomList);
    // 查询客户ou下所有启用的光伏站
    getEnergyAssetsPVStationInOu().then(setPVStationList);
    getBSAList().then(setBsaStationList);
  }, []);

  // 通过组织id查找物业list
  useEffect(() => {
    if (customerMcid) {
      // 切换组织的时候清空物业等信息
      // 非复制时
      if (!copy) {
        form.setFieldsValue({
          propertyManagementConfigId: null,
          saleElectricityContractId: null,
          settlementType: null,
        });
        setFindSaleElectricityContract(undefined);
      }
      getPropertyList([customerMcid]).then(res => {
        if (res) {
          setPropertyList(res);
          // 如果只有一个物业，自动填充 非复制时
          if (!copy) {
            if (res.length === 1) {
              form.setFieldsValue({ propertyManagementConfigId: res[0].id });
            }
          }
        }
      });
      // 根据所属组织查询用电账户列表
      getElectricityAccountList(customerMcid).then(res => setElectricityAccountList(res.list || []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMcid, form]);

  // 通过客户id和物业id查找合同列表
  useEffect(() => {
    if (saleClientId && propertyManagementConfigId) {
      getSaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientId({
        saleClientId,
        propertyManagementConfigId,
      }).then(res => {
        setSaleElectricityContractList(res);
        let find = res.find(item => item.id === form.getFieldValue('saleElectricityContractId')); // 找到这一整条信息
        setFindSaleElectricityContract(find); // 为了显示合同生效周期
      });
    }
  }, [saleClientId, propertyManagementConfigId, form]);

  // 查询光伏站下的设备
  useEffect(() => {
    const settlementType = form.getFieldValue('settlementType');
    if (arrayId && settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
      setPVTableLoading(true);
      getEnergyAssetsPVStationDevice(arrayId)
        .then(setPVStationDeviceData)
        .finally(() => {
          setPVTableLoading(false);
        });
    }
    if (arrayId && settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
      setBsaTableLoading(true);
      getBSADeviceItem(arrayId)
        .then(res => {
          setBsaStationDeviceData([res]);
        })
        .finally(() => {
          setBsaTableLoading(false);
        });
    }
  }, [arrayId, form]);

  const propertyOptions = useMemo(() => {
    return (propertyList ?? []).map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [propertyList]);

  const customListOptions = useMemo(() => {
    return (customList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [customList]);

  const saleElectricityContractListOptions = useMemo(() => {
    return (saleElectricityContractList ?? []).map(i => ({
      label: `${i.code}【${ElectricitySaleContractStatus[i.status]}】【${i.name}】`,
      value: i.id,
    }));
  }, [saleElectricityContractList]);

  // 用电账户列表
  const electricityAccountListOptions = useMemo(() => {
    return (electricityAccountList ?? []).map(i => ({
      label: i.number,
      value: i.id,
    }));
  }, [electricityAccountList]);

  // 光伏站选项
  const pvStationListOptions = useMemo(() => {
    return (pvStationList ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [pvStationList]);

  const bsaStationListOptions = useMemo(() => {
    return (bsaStationList ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [bsaStationList]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    if (findSaleElectricityContract && findSaleElectricityContract.startTime && findSaleElectricityContract.endTime) {
      return (
        current &&
        (current < dayjs(findSaleElectricityContract.startTime).startOf('day') ||
          current > dayjs(findSaleElectricityContract.endTime).endOf('day'))
      );
    }

    return false;
  };

  // 物业选择的时候改变下面的业务范围（主要是结算规则）
  const onPropertyManagementConfigIdChange = (id: string) => {
    form.setFieldsValue({
      settlementType: null,
      saleElectricityContractId: null,
    });
  };

  // 切换所属客户的时候清空合同（因为合同是通过客户和物业进行查找的）
  const onSaleClientIdChange = (val: string) => {
    form.setFieldsValue({
      saleElectricityContractId: null,
    });
  };

  // 当合同选择的时候显示在下方显示时间
  const onSaleElectricityContractChange = (id: number) => {
    let find = saleElectricityContractList.find(item => item.id === id);
    setFindSaleElectricityContract(find);
  };

  const onFinish = (v: FormProps) => {
    const startTime = dayjs(v.effectiveTime[0]).format(DATE_FORMAT);
    const endTime = dayjs(v.effectiveTime[1]).format(DATE_FORMAT);
    const settlementTemplate = v.priceType === PriceType.TEMPLATE ? v.tariffTemplate : null;
    const commonBody = {
      ...v,
      startTime,
      endTime,
      settlementTemplate,
    };
    setFinishLoading(true);
    addOrEditEnergyAssetsSettlementsRules(commonBody)
      .then(() => {
        navigate('/operation/settlementRules/energyAssetsRules');
      })
      .finally(() => {
        setFinishLoading(false);
      });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={isCopy ? '复制结算规则' : '新建结算规则'}></FormTitle>

      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={(v: FormProps) => onFinish(v)}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
        preserve={false}
        initialValues={{
          generationMode: SettlementGeneratorMode.AUTO,
          timeOfDayRateChecked: [1, 2, 3, 4],
          // settlementType: ElectricitySaleContractSettlementType.DISTRIBUTED_PV,
        }}
      >
        <Spin spinning={loading}>
          <SubContent>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="结算规则名称"
                  rules={[
                    { required: true, message: '请输入结算规则名称' },
                    { max: 50, message: '最多输入50个字符' },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="请输入结算规则名称,不超过五十个字" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="customerMcid" label="关联组织" rules={[{ required: true, message: '请选择关联组织' }]}>
                  <TreeSelect
                    treeDefaultExpandAll
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    onChange={() => {
                      setCopy(false);
                    }}
                    treeData={treeData}
                    placeholder="请选择"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="propertyManagementConfigId"
                  label="所属项目公司"
                  rules={[{ required: true, message: '请选择所属项目公司' }]}
                >
                  <Select
                    placeholder="请选择"
                    disabled={!customerMcid}
                    onChange={val => onPropertyManagementConfigIdChange(val)}
                    options={propertyOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属客户" name="saleClientId">
                  <Select
                    placeholder="请选择"
                    onChange={val => onSaleClientIdChange(val)}
                    options={customListOptions}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="saleElectricityContractId"
                  label="所属合同"
                  extra={
                    findSaleElectricityContract ? (
                      <span>
                        合同有效期：
                        {`${dayjs(findSaleElectricityContract.startTime).format(DATE_FORMAT)}~${dayjs(
                          findSaleElectricityContract.endTime
                        ).format(DATE_FORMAT)}`}
                      </span>
                    ) : (
                      false
                    )
                  }
                  rules={[{ required: true, message: '请选择所属合同' }]}
                >
                  <Select
                    placeholder={'请选择'}
                    onChange={e => onSaleElectricityContractChange(e)}
                    disabled={!(!!saleClientId && !!propertyManagementConfigId)}
                    options={saleElectricityContractListOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="effectiveTime"
                  label="结算规则生效区间"
                  rules={[{ required: true, message: '请输入生效区间' }]}
                >
                  <RangePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="结算场景">
                  <ShowInput value="能源资产营销" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="generationMode"
                  label="结算单生成方式"
                  rules={[{ required: true, message: '请选择结算单生成方式' }]}
                >
                  <Radio.Group>
                    <Radio value={SettlementGeneratorMode.AUTO}>跟随合同结算日自动生成</Radio>
                    <Radio value={SettlementGeneratorMode.MANUAL}>手动生成</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="settlementType"
                  label="结算类型"
                  rules={[{ required: true, message: '请选择结算类型' }]}
                >
                  <Radio.Group
                    onChange={() => {
                      form.setFieldsValue({ arrayId: undefined });
                      setBsaStationDeviceData([]);
                      setPVStationDeviceData([]);
                    }}
                  >
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTED_PV}>分布式光伏</Radio>
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTED_BSA}>分布式储能</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="priceType" label="结算电价" rules={[{ required: true, message: '请选择结算电价' }]}>
                  <Radio.Group>
                    <Radio value={PriceType.CUSTOM}>自定义</Radio>
                    <Radio value={PriceType.TEMPLATE}>电价模板</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {priceType === PriceType.CUSTOM && (
                <Col span={24}>
                  <CustomTariff />
                </Col>
              )}
              {priceType === PriceType.TEMPLATE && (
                <Col span={8}>
                  <Form.Item
                    name="tariffTemplate"
                    label="电价模板"
                    rules={[{ required: true, message: '请选择电价模板' }]}
                  >
                    <Select placeholder={'请选择'} options={electricityAccountListOptions} />
                  </Form.Item>
                </Col>
              )}
              <Form.Item noStyle dependencies={['settlementType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="gridPrice"
                            label="上网电价"
                            rules={[{ required: true, message: '请输入上网电价' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={99999999.99999999}
                              min={0}
                              step="0.00000001"
                              addonAfter="元/kWh"
                              formatter={limitDecimalsF}
                              parser={limitDecimalsPEight}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="discountForOwner"
                            label="电价折扣"
                            rules={[{ required: true, message: '请输入电价折扣' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="gridPercentageForOwner"
                            label="上网分成比例"
                            rules={[{ required: true, message: '请输入上网分成比例' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="arrayId"
                            label="光伏站"
                            rules={[{ required: true, message: '请选择光伏站' }]}
                          >
                            <Select
                              style={{ width: '100%' }}
                              placeholder={'请选择'}
                              options={pvStationListOptions}
                              onChange={v => setArrayId(v)}
                              showSearch
                              optionFilterProp="label"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="discountForOwner"
                            label="放电折扣"
                            rules={[{ required: true, message: '请输入电价折扣' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="arrayId"
                            label="储能站"
                            rules={[{ required: true, message: '请选择储能站' }]}
                          >
                            <Select
                              style={{ width: '100%' }}
                              placeholder={'请选择'}
                              options={bsaStationListOptions}
                              onChange={v => setArrayId(v)}
                              showSearch
                              optionFilterProp="label"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                }}
              </Form.Item>

              <Col span={24}>
                <Form.Item noStyle dependencies={['settlementType']}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                      return <PhotovoltaicDeviceTable data={pvStationDeviceData} loading={pvTableLoading} />;
                    }
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                      return <BsaDeviceTable data={bsaStationDeviceData} loading={bsaTableLoading} />;
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary" loading={finishLoading}>
            保存
          </Button>
          <Button onClick={() => navigate('/operation/settlementRules/energyAssetsRules')}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateEnergyAssetsSettlementsRules;
