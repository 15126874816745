import { EllipsisSpan, Table, Button, Modal } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useState } from 'react';

import DeviceDataPropertyModal from './DeviceDataPropertyModal';
import { DataProperty, ReportStrategyDataPropertyList } from '../../../../api/reportStrategy';
import { ReportStrategyStatisticalTypeEnum } from '@/shared/types';
import StatisticalDataModal from '@/pages/ReportStrategy/components/SelectDevicesDataProperty/StatisticalDataModal';
import { apiV2ReportStrategyDeviceLinkPropertyListPost } from '@maxtropy/device-customer-apis-v2';
import { OuListResponse } from '@/api/ou';

const columns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '统计的数据',
    dataIndex: 'dataPropertyList',
    ellipsis: { showTitle: true },
    render: (v: DataProperty[]) => <EllipsisSpan value={(v ?? []).map(i => i.name).join(',')} />,
  },
];

export interface SelectDevicesProps {
  value?: ReportStrategyDataPropertyList[];
  onChange?: (value: ReportStrategyDataPropertyList[]) => void;
  ous?: OuListResponse[];
  statisticalType?: ReportStrategyStatisticalTypeEnum;
  disabled?: boolean;
}

const SelectDevices: FC<SelectDevicesProps> = ({ value, onChange, ous, statisticalType, disabled = false }) => {
  const [statisticalModalVisible, setStatisticalModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<ReportStrategyDataPropertyList[]>([]);
  const [deviceVisible, setDeviceVisible] = useState<boolean>(false);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (!ous?.length) {
      Modal.warning({ title: '请先选择运营单元' });
      return;
    }
    if (statisticalType === undefined) {
      Modal.warning({ title: '请先选择统计类型' });
      return;
    }
    setDeviceVisible(true);
  };

  const onBatch = () => {
    if (!ous?.length) {
      Modal.warning({ title: '请先选择运营单元' });
      return;
    }
    if (!value?.length) {
      Modal.warning({ title: '请先添加设备' });
      return;
    }
    setStatisticalModalVisible(true);
  };

  const unBind = (record: ReportStrategyDataPropertyList) => {
    Modal.confirm({
      title: '确定移出？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.deviceId !== record.deviceId);
        onChange?.(newValue);
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: ReportStrategyDataPropertyList) => {
        return (
          <Button
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            移出
          </Button>
        );
      },
    },
  ];

  const onSubmit = (val: DataProperty[]) => {
    setLoading(true);
    setStatisticalModalVisible(false);
    const deviceIds = value?.map(k => k.deviceId);

    apiV2ReportStrategyDeviceLinkPropertyListPost({ deviceIds, measurementType: statisticalType })
      .then(res => {
        const originList = res.list ?? [];

        const newValues = value?.map(v => {
          const realDevice = originList.find(k => k.deviceId === v.deviceId);
          const realPropertys = val.filter(m => m.id && realDevice?.dataPropertyIds?.includes(m.id));

          return {
            ...v,
            dataPropertyList: realPropertys,
          };
        });
        onChange?.(newValues as ReportStrategyDataPropertyList[]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {!disabled && (
        <Space direction="horizontal" size={8} style={{ marginBottom: 10 }}>
          <Button type="primary" onClick={onAdd}>
            添加设备
          </Button>
          <Button type="primary" onClick={onBatch}>
            批量选择统计数据
          </Button>
          <div />
        </Space>
      )}
      <Table
        loading={loading}
        sticky
        scroll={{ y: 400 }}
        columns={disabled ? columns : buildColumns}
        rowKey="deviceId"
        dataSource={value ?? []}
      />
      {deviceVisible && (
        <DeviceDataPropertyModal
          open={deviceVisible}
          ous={ous}
          statisticalType={statisticalType}
          onChange={val => {
            onChange?.(val);
          }}
          onClose={setDeviceVisible}
          value={dataSource}
        />
      )}
      <StatisticalDataModal
        open={statisticalModalVisible}
        statisticalType={statisticalType}
        deviceIds={value?.map(k => k.deviceId)}
        onSubmit={onSubmit}
        onClose={() => setStatisticalModalVisible(false)}
      />
    </>
  );
};

export default SelectDevices;
