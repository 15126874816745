import { fetch } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import qs from 'qs';
import { UnitPageRequest } from './page';

// 工作中心工站工序用能单元的树
export interface UnitTreeItem {
  // 用能单元id
  energyUnitId: number;
  // 用能单元名称
  energyUnitName: string;
  // 工作中心id
  workCenterId: number;
  // 中心工序关联id
  centerProcedureLinkId: number;
  // 工序工站关联id
  procedureStationLinkId: number;
}

export interface StationTreeItem {
  workCenterId: number;
  centerProcedureLinkId: number; // 中心工序关联id
  stationId: number;
  procedureStationLinkId: number; // 工序工站关联id
  stationName: string; // 工站名称
  unitTree?: UnitTreeItem[];
}

export interface ProcedureTreeItem {
  workCenterId: number;
  procedureId: number;
  centerProcedureLinkId: number; // 中心工序关联id
  procedureName: string; //工序名称
  procedureOrder: number; // 排序
  stationTree?: StationTreeItem[];
}

// 左侧树
export interface WorkCenterTreeItem {
  id: number;
  name: string;
  procedureTree?: ProcedureTreeItem[];
}

// 获取工作中心, 工站, 工序和用能单元树
export const getWorkCenterTree = () => {
  return fetch<WorkCenterTreeItem[]>('/api/work-center/tree');
};

// 能耗类型属性
export enum ConsumptionAttribute {
  // 发热量
  HEAT = 200,
  // 标准煤
  COAL = 100,
  // 工质累计用量
  MEDIUM = 300,
}

export const ConsumptionAttributeDisplay = {
  [ConsumptionAttribute.HEAT]: '发热量',
  [ConsumptionAttribute.COAL]: '标准煤',
  [ConsumptionAttribute.MEDIUM]: '用量',
};

// 综合能耗下发热量和标准煤对应的单位, 工质的单位走接口不写死
export const ConsumptionAttributeUnitDisplay = {
  [ConsumptionAttribute.HEAT]: 'MJ',
  [ConsumptionAttribute.COAL]: 'kgce',
  [ConsumptionAttribute.MEDIUM]: '--',
};

// 生产维度, 生产能耗, 生产单耗
export enum ConsumptionDimension {
  // 生产能耗
  TOTAL = '1',
  // 生产单耗
  UNIT = '2',
}

export const ConsumptionDimensionDisplay = {
  [ConsumptionDimension.TOTAL]: '生产能耗',
  [ConsumptionDimension.UNIT]: '生产单耗',
};

// 时间颗粒度, 15 分钟, 天, 月
export enum TimeGranularity {
  FIFTEEN_MINUTES = '15m',
  DAY = 'day',
  MONTH = 'month',
}

export const TimeGranularityDisplay = {
  [TimeGranularity.FIFTEEN_MINUTES]: '15分钟',
  [TimeGranularity.DAY]: '按日',
  [TimeGranularity.MONTH]: '按月',
};

// 单耗的时间颗粒度, 只有小时
export enum TimeGranularityUnit {
  HOUR = 'hour',
}

export const TimeGranularityUnitDisplay = {
  [TimeGranularityUnit.HOUR]: '小时',
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// 不同时间段下的初始值
export const TimeGranularityInitialValue: Record<string, RangeValue> = {
  [TimeGranularity.FIFTEEN_MINUTES]: [dayjs().startOf('day'), dayjs()],
  [TimeGranularity.DAY]: [dayjs().subtract(30, 'day'), dayjs()],
  [TimeGranularity.MONTH]: [dayjs().startOf('month').subtract(11, 'month'), dayjs().endOf('month')],
  [TimeGranularityUnit.HOUR]: [dayjs().startOf('day'), dayjs()],
};

// 根节点不用传任何参数
export interface BoardChartDataQuery {
  // 工作中心 id
  workCenterId: string | number;
  // 工作中心和工序关联id
  centerProcedureLinkId?: string | number;
  // 工序工站管理 id
  procedureStationLinkId?: string | number;
  // 用能单元id
  energyUnitId?: string | number;
  // 能源介质id, 如果是能源介质的话, 传 100 为标准煤, 200 为发热量
  energyMediumId?: string | number;
  // 基础物理量id
  basePhysicalQuantityId?: number;
  // 是否是综合能耗
  isTotal: boolean;
  // 开始日期
  startDate: string;
  // 结束日期
  endDate: string;
}

export enum ExceedingFlag {
  // 正常
  NORMAL = 0,
  // 超出上限
  HIGH = 1,
  // 低于下限
  LOW = 2,
}

export interface BoardChartData {
  // 时间戳
  ts: string | number;
  // 工质数据, 例如电, 新水
  value?: number;
  cost?: number; //能耗成本
  // 发热量
  // calorificValue?: number;
  // 标准煤
  // standardCoal?: number;
  // 费用
  amount?: number;
  // 单耗
  unitConsumption?: number;
  unitConsumptionCost?: number; // 单耗成本
  // 上限
  upperLimit?: number;
  // 超出限定值, 具体可以是能耗超过上限值的值, 或者是下限值超过能耗的值
  exceedingLimit?: number | null;
  // 是否超出上限, true 为超出上限, false 为低于下限
  exceedingFlag?: ExceedingFlag;
  // 下限
  lowerLimit?: number;
  // 投入数量
  inputNum?: number;
  // 产出数量
  outputNum?: number;
  // 状态
  status?: Status;
}

// 工质, 发热量, 标准煤的所有用量
export interface TotalValues {
  // 工质总用量
  totalValue?: number;
  // // 发热量总量
  // totalCalorificValue?: number;
  // // 标准煤总量
  // totalStandardCoal?: number;
  // 总费用
  totalCost?: number;
}

export interface AvgValues {
  // 平均用量(单耗)
  avgValue?: number;
  // 每小时成本
  avgCost?: number;
  // // 平均发热量(单耗)
  // avgCalorificValue?: number;
  // // 平均标准煤	(单耗)
  // avgStandardCoal?: number;
}

export interface BoardChartResponse extends TotalValues {
  // 物理单位 id
  physicalUnitId?: number;
  // 物理单位
  generalName?: string;
  // 中文单位
  chineseName?: string;
  // 转换为标准物理量方程的系数
  k?: number;
  data?: BoardChartData[];
}

// 获取 15 分钟颗粒度下的所有数据, 包括电量, 发热量, 标准煤和费用以及单位
export function getBoardChartQuarterData(query: BoardChartDataQuery) {
  return fetch<BoardChartResponse>(
    `/api/v2/production/energy/consumption/quarter-data`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 获取日颗粒度下的所有数据, 包括电量, 发热量, 标准煤
export function getBoardChartDayData(query: BoardChartDataQuery) {
  return fetch<BoardChartResponse>(
    `/api/v2/production/energy/consumption/day-data`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 获取日颗粒度下的所有数据, 包括电量, 发热量, 标准煤
export function getBoardChartMonthData(query: BoardChartDataQuery) {
  return fetch<BoardChartResponse>(
    `/api/v2/production/energy/consumption/month-data`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface UnitData {
  ts?: string | number;
  // 用量
  unitConsumption?: number;
  unitConsumptionCost?: number; //单耗成本
  // 上限
  upperLimit?: number;
  // 超出限定值, 具体可以是能耗超过上限值的值, 或者是下限值超过能耗的值
  exceedingLimit?: number | null;
  // 是否超出上限, true 为超出上限, false 为低于下限
  exceedingFlag?: ExceedingFlag;
  // 下限
  lowerLimit?: number;
  // 开始时间
  startTime?: string | number;
  // 结束时间
  endTime?: string | number;
}

export interface BoardChartUnitData {
  // 物理单位 id
  physicalUnitId?: number;
  // 物理单位
  generalName?: string;
  // 中文单位
  chineseName?: string;
  // 生产单编码
  productSheetCode?: string;
  // 产出物 id
  outputProductId?: number;
  // 产出物编码
  outputProductCode?: string;
  // 产出物名称
  outputProductName?: string;
  // 单耗数据
  data?: UnitData[];
}

// 获取单耗数据
export function getBoardChartUnitData(query: BoardChartDataQuery) {
  return fetch<{ list: BoardChartUnitData[] }>(
    `/api/v2/production/energy/consumption/unit-data`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 根据时间复杂度对应不同的请求
export const TimeGranularityRequest = {
  [TimeGranularity.FIFTEEN_MINUTES]: getBoardChartQuarterData,
  [TimeGranularity.DAY]: getBoardChartDayData,
  [TimeGranularity.MONTH]: getBoardChartMonthData,
  [TimeGranularityUnit.HOUR]: getBoardChartUnitData,
};

export interface MediumQuery {
  // 工作中心 id
  workCenterId: string | number;
  // 工作中心和工序关联id
  centerProcedureLinkId?: string | number;
  // 工序工站管理 id
  procedureStationLinkId?: string | number;
  // 用能单元id
  energyUnitId?: string | number;
}

export interface EnergyUnitMedium {
  id: number;
  // 工质 id
  energyMediumId: number;
  // 工质名称, 例如电能, 新水, 硬水
  energyMediumName: string;
  // 物理单位名称
  generalName: string;
  // 工质对应的一级场景 id, 例如新水喝硬水对应给排水, 500
  sceneId?: number;
  // 基础物理量id
  basePhysicalQuantityId?: number;
  createTime?: string | null;
  updateTime?: string | null;
}

// 获取工作中心下绑定的用能单元关联的用能分析组计量设置的工质列表
// 即根据用能单元/工站/工序/工作中心 id 获取能源介质信息
export function getMediumById(query: MediumQuery) {
  return fetch<{ list: EnergyUnitMedium[] }>(
    `/api/v2/production/energy/consumption/energyMediumList`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 几种状态枚举
export enum Status {
  // 正常生产
  WORK = 1,
  // 空闲
  FREE = 2,
  // 休息
  REST = 3,
  // 停线
  LINE_STOP = 4,
}

export const StatusDisplay = {
  [Status.WORK]: '正常生产',
  [Status.FREE]: '空闲',
  [Status.REST]: '休息',
  [Status.LINE_STOP]: '停线',
};

export const StatusColorDisplay = {
  [Status.WORK]: 'rgba(52,211,124,0.4)',
  [Status.FREE]: 'rgba(255,203,71,0.4)',
  [Status.REST]: 'rgba(255,255,255,0.2)',
  [Status.LINE_STOP]: 'rgba(255,77,79,0.5)',
};

export interface BoardChartQuarterStatusListQuery {
  // 工作中心 id
  workCenterId: number | string;
  // 开始日期
  startDate: string;
  // 结束日期
  endDate: string;
}

// 一个状态的形式
export interface BoardChartQuarterStatus {
  status: Status;
  startTime: string;
  endTime: string;
}

// 获取产线15min状态, 包含休息/空闲/正常生产/停线, 图表里对应为 MarkArea
export function getBoardChartQuarterStatusList(query: BoardChartQuarterStatusListQuery) {
  return fetch<BoardChartQuarterStatus[]>(
    `/api/production/energy/consumption/quarter-status?${qs.stringify(query, {
      indices: false,
    })}`
  );
}

export interface ConsumptionStatusDataListQuery {
  workCenterId: number | string;
  centerProcedureLinkId?: number | string;
  procedureStationLinkId?: number | string;
  energyUnitId?: number | string;
  // 能源介质id, 如果是能源介质的话, 传 100 为标准煤, 200 为发热量
  energyMediumId?: string | number;
  // 基础物理量id
  basePhysicalQuantityId?: number;
  // 是否是综合能耗
  isTotal: boolean;
  startDate: string;
  endDate: string;
}

export interface ConsumptionStatusData extends TotalValues, AvgValues {
  // 状态, 1 :正常生产 2 :空闲 3 :休息 4 :停线
  status: Status;
  // 时长
  duration?: number;
}

// 获取能耗下各状态的用量(用于顶部卡片展示)
export function getConsumptionStatusDataList(query: ConsumptionStatusDataListQuery) {
  return fetch<{
    list: ConsumptionStatusData[];
  }>(
    `/api/v2/production/energy/consumption/status-data`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}
export interface UnitConsumptionItem {
  id?: number;
  /**
   * 工作中心id
   */
  workCenterId?: number;
  /**
   * 工作中心name
   */
  workCenterName?: string;
  /**
   * 工作中心code
   */
  workCenterCode?: string;
  /**
   * 产出物id
   */
  outputProductId?: number;
  /**
   * 产出物Nmae
   */
  outputProductName?: string;
  /**
   * 产出物code
   */
  outputProductCode?: string;
  /**
   * 能源阈值设定
   */
  shopFlowUnitConsumptionThresholdValueDetailDtos?: {
    id?: number;
    /**
     * 单耗设定id
     */
    unitConsumptionConfigId?: number;
    /**
     * 能源介质id 100:标准煤，200:发热量
     */
    energyMediumId?: number;
    /**
     * 平均单耗上限
     */
    averageUnitConsumptionUpLimit?: number;
    /**
     * 平均单耗下限
     */
    averageUnitConsumptionDownLimit?: number;
    /**
     * 正常生产单耗下限
     */
    normalProductionUnitConsumptionUpLimit?: number;
    /**
     * 正常生产单耗下限
     */
    normalProductionUnitConsumptionDownLimit?: number;
    /**
     * 停线单耗上限
     */
    stopLineUnitConsumptionUpLimit?: number;
    /**
     * 停线单耗下限
     */
    stopLineUnitConsumptionDownLimit?: number;
    /**
     * 空闲能耗上限
     */
    leisureEnergyConsumptionUpLimit?: number;
    /**
     * 空闲能耗下限
     */
    leisureEnergyConsumptionDownLimit?: number;
    /**
     * 休息能耗上限
     */
    restEnergyConsumptionUpLimit?: number;
    /**
     * 休息能耗下限
     */
    restEnergyConsumptionDownLimit?: number;
    /**
     * 创建时间
     */
    createTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    /**
     * 更新时间
     */
    updateTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    /**
     * 所属租户
     */
    tenantMcid?: string;
  }[];
}

export interface UniconsumablePagingItem extends UnitPageRequest {
  outputNameOrCode: string;
  workCenterNameOrCode: string;
}

export interface meterProjectPagingItem extends UnitPageRequest {
  meterReadingProjectName: string;
  meterReadingObjectName: string;
  readingPeriod: number;
}

export interface V2UniverseMeterReadingProjectPostResponse {
  id?: number;
  /**
   * 抄表项目名称
   */
  projectName?: string;
  /**
   * 用能分析组id
   */
  energyGroupId?: number;
  /**
   * 抄表周期 1按年 2按月 3按周 4按日
   */
  readingPeriod?: number;
  /**
   * 抄表对象类型 1用能单元
   */
  readingObjectType?: number;
  /**
   * 抄表对象类型 1用能单元
   */
  readingObjects?: {
    id?: number;
    name?: string;
  }[];
  /**
   * 能源工质
   */
  energyMediums?: {
    id?: number;
    name?: string;
  }[];
  /**
   * 首次抄表时间
   */
  firstReadingTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 最近一次抄表时间
   */
  lastReadingTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 抄表项目状态 0禁用 1启用
   */
  status?: number;
  /**
   * 创建时间
   */
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 更新时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 所属租户
   */
  tenantMcid?: string;
}
