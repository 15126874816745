import { FC, useCallback } from 'react';
import { isNil } from 'lodash-es';
import { Table, Paging, usePaging, Button } from '@maxtropy/components';
import { useQuery } from '@/shared/utils/utils';
import { AttributeTemplate, getAttributeTemplateList } from '../../../../api/template';
import { ActionType } from '../index';

export interface DevicesModalProps {
  value?: AttributeTemplate;
  onChange?: (value?: AttributeTemplate) => void;
  deviceType?: number[];
  templateId?: number;
  actionType?: ActionType;
}

const columns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
  },
  {
    title: '模板名称',
    dataIndex: 'name',
  },
  {
    title: '版本号',
    dataIndex: 'version',
  },
  {
    title: '已使用设备数量',
    dataIndex: 'useCount',
  },
];

const TemplateVersionModal: FC<DevicesModalProps> = ({ value, onChange, deviceType, templateId, actionType }) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (deviceType && !isNil(actionType)) {
        const page = await getAttributeTemplateList({
          page: pageOffset,
          size: pageSize,
          deviceTypeId: deviceType[deviceType.length - 1],
          operationType: actionType,
          templateId,
        });
        if (page) {
          setTotalCount(page.total);
          return page.list;
        }
      }
    }, [pageOffset, pageSize, setTotalCount, deviceType, actionType, templateId])
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v: undefined, record: AttributeTemplate) => {
        const selected = record.id === value?.id && record.version === value?.version;
        return (
          <Button
            type="link"
            disabled={selected}
            onClick={() => {
              onChange?.(record);
            }}
          >
            {selected ? '已选择' : '选择'}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Table<AttributeTemplate>
        loading={isLoading}
        sticky
        scroll={{ y: 300 }}
        rowKey={record => `${record.id}_${record.version}`}
        columns={buildColumns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TemplateVersionModal;
