import React, { FC, useEffect } from 'react';
import { Col } from 'antd';
import { Form, Modal } from '@maxtropy/components';
import { AnalysisUnit } from '..';

interface Params {
  detailModalOpen: boolean;
  setDetailModalOpen: (v: boolean) => void;
  energyConsumptionDetail?: AnalysisUnit;
}

const DetailEnergyConsumptionModal: FC<Params> = ({ detailModalOpen, setDetailModalOpen, energyConsumptionDetail }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (energyConsumptionDetail) {
      let { name, customerNameList } = energyConsumptionDetail;
      form.setFieldsValue({ name, mcidList: (customerNameList ?? []).join() });
    }
  }, [energyConsumptionDetail, form]);

  const onCancel = () => {
    setDetailModalOpen(false);
  };

  return (
    <Modal
      title="查看用能分析组"
      open={detailModalOpen}
      size="normal"
      contentClassName="modal-form-content"
      onCancel={onCancel}
      onOk={onCancel}
    >
      <Form layout="horizontal" form={form}>
        <Col span={24}>
          <Form.Item label="名称" name="name">
            <ShowFormItem />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="所属组织" name="mcidList">
            <ShowFormItem />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};
const ShowFormItem: React.FC<{ value?: string }> = ({ value }) => <div>{value ? value : '--'}</div>;

export default DetailEnergyConsumptionModal;
