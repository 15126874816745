import { Col, Row } from 'antd';
import { Checkbox, Form, InputNumber } from '@maxtropy/components';
import { limitDecimalsF, limitDecimalsPEight } from '../../units';
import { TimeIntervalType, TimeIntervalTypeDisplay } from '@/api/energyAssetsSettlementsRules';

const CustomTariff: React.FC = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: 'rgba(var(--base-text-color), 0.04)',
          padding: '20px 20px 0px 20px',
          marginBottom: '20px',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        电度电价：
        <Row>
          <Col
            span={23}
            offset={1}
            style={{
              padding: '20px',
              paddingBottom: '0px',
            }}
          >
            <Form.Item name="timeOfDayRateChecked" label="时段选择" rules={[{ required: true, message: '请选择时段' }]}>
              <Checkbox.Group
                options={[
                  {
                    label: TimeIntervalTypeDisplay[TimeIntervalType.SHARP],
                    value: TimeIntervalType.SHARP,
                  },
                  {
                    label: TimeIntervalTypeDisplay[TimeIntervalType.PEAK],
                    value: TimeIntervalType.PEAK,
                  },
                  {
                    label: TimeIntervalTypeDisplay[TimeIntervalType.AVERAGE],
                    value: TimeIntervalType.AVERAGE,
                  },
                  {
                    label: TimeIntervalTypeDisplay[TimeIntervalType.VALLEY],
                    value: TimeIntervalType.VALLEY,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {/* 时段选择尖峰平谷输入框 */}
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.SHARP) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="summitPrice" label="尖" rules={[{ required: true, message: '请输入尖值' }]}>
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '80%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          // stringMode
                          addonAfter="元/kWh"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPEight}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.PEAK) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="peakPrice" label="峰" rules={[{ required: true, message: '请输入峰值' }]}>
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '80%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          // stringMode
                          addonAfter="元/kWh"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPEight}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.AVERAGE) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="plainPrice" label="平" rules={[{ required: true, message: '请输入平值' }]}>
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '80%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          // stringMode
                          addonAfter="元/kWh"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPEight}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'priceType']}>
            {({ getFieldValue }) => {
              return (
                (getFieldValue('timeOfDayRateChecked') ?? []).includes(TimeIntervalType.VALLEY) && (
                  <>
                    <Col span={23} offset={1} style={{ paddingLeft: '20px' }}>
                      <Form.Item name="valleyPrice" label="谷" rules={[{ required: true, message: '请输入谷值' }]}>
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '80%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          // stringMode
                          addonAfter="元/kWh"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPEight}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
        </Row>
      </div>
    </>
  );
};

export default CustomTariff;
