import React, { useEffect, useState } from 'react';
import { Calendar, Col, Row, Space, Spin } from 'antd';
import { useBreadcrumbRoutes, Wrapper, Button, Select, Tooltip, useUpdate, FormTitle } from '@maxtropy/components';
import styles from './index.module.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import {
  getWorkShiftsPerDay,
  getWorkTeamList,
  ScheduleColors,
  updateSingleSchedule,
  WorkShiftsPerDay,
  WorkTeam,
} from '@/api/schedulePlan';
import AdjustWorkdayModal from './AdjustWorkdayModal';
import FastScheduleModal from './FastScheduleModal';
import ImportScheduleModal from './ImportScheduleModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';

// 排班详情日历
export default function SchedulePlanDetail() {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // 这里拿的 id 是用能单元 id
  const { id } = useParams();
  const [urlSearchParams] = useSearchParams();
  const unitName = urlSearchParams.get('unitName') ?? undefined;
  const [updateState, forceUpdate] = useUpdate();
  // 切换 panel 也就是月份的时候当前时间
  const [panelValue, setPanelValue] = useState<Dayjs>(dayjs());
  // 该用能单元下的所有班组
  const [workTeams, setWorkTeams] = useState<WorkTeam[]>([]);
  // 一个月的排班计划, 包含每天的班次和班组信息
  const [workShiftsPerMonth, setWorkShiftsPerMonth] = useState<WorkShiftsPerDay[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 工作日调整模态框
  const [showAdjustWorkdayModal, setShowAdjustWorkdayModal] = useState<boolean>(false);
  // 快捷排班
  const [showFastScheduleModal, setShowFastScheduleModal] = useState<boolean>(false);
  // 导入排班
  const [showImportScheduleModal, setShowImportScheduleModal] = useState<boolean>(false);
  // 快捷排班权限
  const hasFastSchedulePermission = useHasPermission(PermissionsType.B_WORKSHIFTAPPLYSWIFT);
  // 工作日调整权限
  const hasAdjustSchedulePermission = useHasPermission(PermissionsType.B_WORKSHIFTAPPLYWEEKDAY);
  // 导入排班权限
  const hasImportSchedulePermission = useHasPermission(PermissionsType.B_WORKSHIFTAPPLYIMPORT);
  const panelChangeRef = React.useRef(false);

  // 获取当前用能单元下的所有班组
  useEffect(() => {
    if (id) {
      getWorkTeamList(id).then(res => {
        setWorkTeams(res.list || []);
      });
    }
  }, [id]);

  // 根据 panel 选择的不同获取不同的班组班次
  useEffect(() => {
    // 如果切换月份, 清空之前的状态
    if (panelChangeRef.current) {
      setWorkShiftsPerMonth([]);
    }
    setLoading(true);
    getWorkShiftsPerDay({
      energyUnitId: id!,
      date: panelValue.startOf('month').format('YYYY-MM-DD'),
    })
      .then(res => {
        setWorkShiftsPerMonth(res.list || []);
      })
      .finally(() => {
        panelChangeRef.current = false;
        setLoading(false);
      });
  }, [id, panelValue, updateState]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <FormTitle title={`${unitName ?? ''}排班计划`} />

      <div className={styles.operationArea}>
        <Space size={8}>
          {hasFastSchedulePermission && (
            <Button
              type="primary"
              onClick={() => {
                setShowFastScheduleModal(true);
              }}
            >
              快捷排班
            </Button>
          )}
          {hasAdjustSchedulePermission && (
            <Button
              type="primary"
              disabled={!workShiftsPerMonth?.length}
              onClick={() => {
                setShowAdjustWorkdayModal(true);
              }}
            >
              工作日调整
            </Button>
          )}
          {hasImportSchedulePermission && (
            <Button
              type="primary"
              onClick={() => {
                setShowImportScheduleModal(true);
              }}
            >
              导入排班
            </Button>
          )}
        </Space>
      </div>
      <div className={styles.calendar}>
        <Spin spinning={loading}>
          <Calendar
            // value={value}
            // onChange={v => {
            //   console.log('onchange');
            //   setValue(v);
            // }}
            // onSelect={v => {
            //   console.log('onselect');
            //   setSelectedValue(v);
            // }}
            onPanelChange={v => {
              panelChangeRef.current = true;
              setPanelValue(v);
            }}
            dateCellRender={date => {
              // 不在当月的不展示
              if (panelValue.month() !== date.month()) {
                return null;
              }

              return (
                <Space
                  direction="vertical"
                  size="small"
                  className={styles.calendarCell}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '24px',
                    width: '100%',
                  }}
                >
                  {workShiftsPerMonth?.length ? (
                    workShiftsPerMonth?.[date.date() - 1]?.isWorkingDay ? (
                      workShiftsPerMonth?.[date.date() - 1]?.workShifts?.map((s, index) => (
                        <Tooltip
                          placement="left"
                          title={`${s?.workShiftName}${s?.workTeamName ? '-' : ''}${s?.workTeamName ?? ''}${
                            s?.customerNames ? '-' : ''
                          }${(s?.customerNames ?? []).join('-')}${s?.workTeamName ? '' : '-'}${
                            s?.workTeamName ? '' : '待排班'
                          }`}
                        >
                          <Select
                            size="small"
                            className={
                              s.workTeamName
                                ? styles[`calendarSelect${ScheduleColors[index]}`]
                                : styles.calendarSelectUnSchedule
                            }
                            style={{ width: '100%' }}
                            optionLabelProp="display"
                            onChange={v => {
                              console.log('onchange select', v);
                              updateSingleSchedule({
                                workTeamId: v === null ? null : Number(v),
                                energyUnitWorkShiftApplyShiftId: s.id!,
                              }).finally(() => {
                                forceUpdate();
                              });
                            }}
                            value={
                              workTeams.find(t => t.id === s.workTeamId) || s.workTeamId === null
                                ? s.workTeamId
                                : `${s?.workShiftName}-${s?.workTeamName ? s?.workTeamName : '待排班'}`
                            }
                            options={[...workTeams, { id: null, name: '待排班' }].map(v => ({
                              label: `${v.name}${(v.customers ?? []).length > 0 ? '-' : ''}${(v.customers ?? [])
                                .map(i => i.name)
                                .join('-')}`,
                              value: v.id,
                              display: `${s?.workShiftName}-${
                                isNil(v.id)
                                  ? '待排班'
                                  : `${v.name}${(v.customers ?? []).length > 0 ? '-' : ''}${(v.customers ?? [])
                                      .map(i => i.name)
                                      .join('-')} `
                              }`,
                            }))}
                          />
                        </Tooltip>
                      ))
                    ) : (
                      <div className={styles.dayOff}>
                        <p style={{ paddingTop: '50px' }}>休息日</p>
                      </div>
                    )
                  ) : (
                    <div>
                      <p>无班次配置</p>
                    </div>
                  )}
                </Space>
              );
            }}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];
              let current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current = current.month(i);
                months.push(localeData.monthsShort(current));
              }
              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>
                );
              }
              const year = value.year();
              const month = value.month();
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>
                );
              }

              return (
                <div style={{ padding: 8 }}>
                  <Row gutter={8} justify="end" align="middle">
                    <Col>
                      <div>时间选择</div>
                    </Col>
                    <Col>
                      <Select
                        size="middle"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        value={year}
                        onChange={newYear => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                        }}
                      >
                        {options}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="middle"
                        dropdownMatchSelectWidth={false}
                        value={month}
                        onChange={newMonth => {
                          const now = value.clone().month(newMonth);
                          onChange(now);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                  </Row>
                </div>
              );
            }}
          />
        </Spin>
      </div>

      {showAdjustWorkdayModal && (
        <AdjustWorkdayModal
          open={showAdjustWorkdayModal}
          closeModal={() => {
            setShowAdjustWorkdayModal(false);
          }}
          panelValue={panelValue}
          forceUpdate={forceUpdate}
          workShiftsPerMonth={workShiftsPerMonth}
          unitId={Number(id)!}
        />
      )}

      {showFastScheduleModal && (
        <FastScheduleModal
          unitName={unitName ?? ''}
          unitId={Number(id)!}
          open={showFastScheduleModal}
          closeModal={() => {
            setShowFastScheduleModal(false);
          }}
          forceUpdate={forceUpdate}
        />
      )}

      {showImportScheduleModal && (
        <ImportScheduleModal
          open={showImportScheduleModal}
          unitId={Number(id)!}
          panelValue={panelValue}
          closeModal={() => {
            setShowImportScheduleModal(false);
          }}
          forceUpdate={forceUpdate}
        />
      )}
    </Wrapper>
  );
}
