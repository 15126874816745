import { Space, Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiEmtIndicatorConfPost, apiEmtIndicatorConfSetPost } from '@maxtropy/device-customer-apis';
import { Button, Form, Modal, Select } from '@maxtropy/components';
import { useEffect } from 'react';
interface QuotaSetModalProps {
  emtId: string;
  emtName: string;
  close: () => void;
}
const QuotaSetModal = (props: QuotaSetModalProps) => {
  const [form] = Form.useForm<{ [key: string]: number | undefined }>();

  const { data: quotaInfo, loading: searchLoading } = useRequest(
    () => apiEmtIndicatorConfPost({ energyMediumTopologyId: props.emtId }),
    {
      refreshDeps: [props.emtId],
      ready: !!props.emtId,
    }
  );

  const { run, loading } = useRequest(
    parmas =>
      apiEmtIndicatorConfSetPost({
        energyMediumTopologyId: +props.emtId,
        indicatorConfList: parmas,
      }),
    {
      ready: true,
      manual: true,
      onSuccess: () => {
        props?.close();
      },
    }
  );

  useEffect(() => {
    // 表单回显
    if (quotaInfo?.indicatorConfList) {
      let formData: { [key: string]: number | undefined } = {};
      quotaInfo?.indicatorConfList.forEach(item => {
        formData[`${item.energyMediumId}`] = item?.indicatorConfList?.find(
          child => (child as any).selected
        )?.energyMediumIndicatorId;
      });
      form.setFieldsValue(formData);
    }
  }, [quotaInfo, form]);
  const confirmBtn = () => {
    let values = form.getFieldsValue();
    let parmas = Object.entries(values).map(([key, value]) => {
      return {
        energyMediumId: +key,
        indicatorConfList: [{ energyMediumIndicatorId: value }],
      };
    });
    run(parmas);
  };
  return (
    <Modal
      contentClassName="modal-form-content"
      title="配置计量指标"
      open
      onCancel={() => props?.close()}
      footer={
        <Space size={8}>
          <Button onClick={() => props?.close()}>取消</Button>
          <Button type="primary" loading={loading} onClick={confirmBtn}>
            确定
          </Button>
        </Space>
      }
    >
      <>
        <Spin spinning={searchLoading}>
          <Form form={form}>
            <Form.Item label="当前拓扑">
              <div>{props.emtName}</div>
            </Form.Item>
            {quotaInfo?.indicatorConfList?.map(item => {
              return (
                <Form.Item key={item.energyMediumId} label={item.energyMediumName} name={item.energyMediumId}>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    options={item.indicatorConfList?.map(child => ({
                      label: child.energyMediumIndicatorName,
                      value: child.energyMediumIndicatorId,
                    }))}
                  ></Select>
                </Form.Item>
              );
            })}
          </Form>
        </Spin>
      </>
    </Modal>
  );
};

export default QuotaSetModal;
