import {
  Button,
  EllipsisSpan,
  Paging,
  Table,
  Wrapper,
  DatePicker,
  Form,
  Input,
  PopConfirm,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
  CarbonYearDisChargePlanListParams,
  CarbonYearDisChargePlanListRes,
  delCarbonYearDisChargePlan,
  getCarbonYearDisChargePlanList,
} from '@/api/carbonYearDisChargePlan';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from '../../utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';

const columns = [
  {
    title: '核算单元',
    dataIndex: 'unitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '财年',
    dataIndex: 'fillingYear',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '碳排放权(t)',
    dataIndex: 'emissionRights',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '减排碳排放(t)',
    dataIndex: 'totalReductedEmission',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '预计碳排放(t)',
    dataIndex: 'totalEmission',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateCorpUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParam extends Omit<CarbonYearDisChargePlanListParams, 'page' | 'size'> {}

const CarbonYearDisChargePlan: React.FC = () => {
  const pagingInfo = useSearchParams<SearchParam>(20);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tableData, setTableData] = useState<CarbonYearDisChargePlanListRes[]>([]);
  const navigate = useNavigate();
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_CARBONEMISSIONPLANCREATE);
  const hasEdit = useHasPermission(PermissionsType.B_CARBONEMISSIONPLANUPDATE);
  const hasDelete = useHasPermission(PermissionsType.B_CARBONEMISSIONPLANDELETE);
  const hasLook = useHasPermission(PermissionsType.B_CARBONEMISSIONPLANDETAIL);

  useEffect(() => {
    setLoading(true);
    getCarbonYearDisChargePlanList({
      accountingUnitName:
        !isNil(searchParams.accountingUnitName) && searchParams.accountingUnitName !== ''
          ? searchParams.accountingUnitName
          : undefined,
      fillingYear: searchParams.fillingYear ? dayjs(searchParams.fillingYear).get('year') : undefined,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTableData(res.list ?? []);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onReset = () => {
    reset();
  };

  const onFinish = (v: SearchParam) => {
    finish(v);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (v: any, record: CarbonYearDisChargePlanListRes) => {
        return (
          <Space size={16}>
            {hasLook && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/carbonManage/mainAccount/yearDisChargePlan/detail/${record.id}`);
                }}
              >
                查看
              </Button>
            )}

            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/carbonManage/mainAccount/yearDisChargePlan/edit/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm
                title="是否删除该碳排放计划？删除后无法恢复。"
                onConfirm={() => {
                  delCarbonYearDisChargePlan(record.id).then(() => {
                    updateFn();
                  });
                }}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter
      form={form}
      onReset={onReset}
      onFinish={(v: SearchParam) => {
        onFinish(v);
      }}
    >
      <Form.Item label="核算单元" name="accountingUnitName">
        <Input placeholder="请输入核算单元" />
      </Form.Item>
      <Form.Item label="财年" name="fillingYear">
        <DatePicker style={{ width: '100%' }} picker="year" />
      </Form.Item>
    </CustomFilter>
  );
  return (
    <Wrapper className={styles.wrapperStyle} filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      {hasAdd && (
        <div style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate('/carbonManage/mainAccount/yearDisChargePlan/add');
            }}
          >
            新建碳排放计划
          </Button>
        </div>
      )}

      <Table columns={buildColumns} rowKey="id" loading={loading} dataSource={tableData} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};
export default CarbonYearDisChargePlan;
