import qs from 'qs';
import { fetch, Key } from '@maxtropy/components';
import { IotProtocolType, Operator, GatewayNetStatus, GatewayStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';
import { Device } from './device';
import { DistributeStatus, NetworkingType } from './netWorking';

export enum SystemType {
  ARM,
  X86,
}

export enum EdgeNetworkStatusEnum {
  // 未进行
  NO_PROCESS = 1,
  // 进行中
  PROCESSING = 2,
  // 成功
  SUCCESS = 3,
  // 失败
  FAILURE = 4,
}

export const EdgeNetworkStatusDisplay = {
  [EdgeNetworkStatusEnum.NO_PROCESS]: '待下发',
  [EdgeNetworkStatusEnum.PROCESSING]: '进行中',
  [EdgeNetworkStatusEnum.SUCCESS]: '成功',
  [EdgeNetworkStatusEnum.FAILURE]: '失败',
};

type BaseType = 'secondary' | 'success' | 'warning' | 'danger' | undefined;

export const EdgeNetworkStatusTextType = {
  [EdgeNetworkStatusEnum.NO_PROCESS]: undefined as BaseType,
  [EdgeNetworkStatusEnum.PROCESSING]: 'warning' as BaseType,
  [EdgeNetworkStatusEnum.SUCCESS]: 'success' as BaseType,
  [EdgeNetworkStatusEnum.FAILURE]: 'danger' as BaseType,
};

export const SYSTEM_TYPE_MAP = {
  [SystemType.ARM]: 'ARM(智能网关)',
  [SystemType.X86]: 'x86(FCS)',
};

export interface EdgeNetWorkType {
  distributeStatus: DistributeStatus; // 组网下发状态
  gatewayId: Key;
  gatewayIp: string;
  id: Key;
  netName: string;
  netNo: string;
  netType: NetworkingType;
  netVersion: number;
  tenantMcid: Key;
}
export interface Gateway {
  configUpdated?: boolean;
  deviceName?: string;
  createByOrgId: string;
  createByTenantId: string;
  customerFullName: string;
  customerName: string;
  deviceCount: number;
  id: number;
  iotProtocol: IotProtocolType;
  name: string;
  netState: GatewayNetStatus;
  remark?: string;
  serialNumber?: string;
  state: GatewayStatus;
  tenantName: string;
  tenantCode?: string;
  updateSource: Operator;
  updateByUsername: string;
  updateTime: string;
  uploadFrequency?: object;
  deviceWithEdgeDeviceDtos: DeviceWithEdgeDevice[];
  systemType?: SystemType;
  edgeNetwork?: EdgeNetWorkType;
}

export type GatewayResponse = PageResponse<Gateway>;

export interface GatewayRequest extends PageRequest {
  name?: string;
  iotProtocol?: IotProtocolType;
  netState?: GatewayNetStatus;
  rootMcid?: string;
  ifCancel?: boolean;
  ifRed?: boolean;
  netName?: string;
  netType?: NetworkingType;
  serialNumber?: string;
  code?: string;
}

export const getGatewayList = (params: GatewayRequest) =>
  fetch<GatewayResponse>(
    `/api/v2/gateway/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface DeviceWithEdgeDevice {
  device: Device;
  edgeDevice: {
    id: number;
    edgeGatewayId?: number;
    deviceId?: number;
    deviceTypeId?: number;
    iotProtocol?: number;
    deviceModelId?: IotProtocolType;
    edgeDeviceTemplateId?: number;
    edgeDeviceTemplateVersion?: number;
  };
}

export interface GatewayDetail extends Gateway {
  deviceWithEdgeDeviceDtos: DeviceWithEdgeDevice[];
  deviceId?: number;
  deviceName?: string;
  transferProtocol: TransferProtocolType;
}

export interface FcsNetworkDetail {
  id?: number; //组网id
  netNo?: string; //组网编号
  netName?: string; //组网名称
  netType?: number; //组网类型 1: 静态IP
  netVersion?: number; //	组网版本
  tenantMcid?: string; //所属租户id
  gatewayId?: number; //网关id
  gatewayIp?: string; //网关ip
}

export const fetchGatewayById = (id: string) =>
  fetch<GatewayDetail>(
    `/api/v2/gateway/get`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export function getGatewayBaseInfoById(id: Key) {
  return fetch<GatewayDetail>(
    `/api/v2/gateway/base-info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export const fetchFcsNetworkByEdgeGatewayId = (edgeGatewayId: string) =>
  fetch<GatewayDetail>(
    `/api/v2/fcs/network`,
    {
      method: 'POST',
      body: JSON.stringify({ id: edgeGatewayId }),
    },
    true
  );

export enum TransferProtocolType {
  json = 0,
  kryo = 1,
}

export const TransferProtocolTypeDisplay = {
  [TransferProtocolType.json]: 'json',
  [TransferProtocolType.kryo]: 'kryo',
};
export interface CreateGatewayForm {
  name: string;
  createByTenantId: string;
  createByOrgId: string;
  iotProtocol: IotProtocolType;
  protocol: IotProtocolType;
  remark?: string;
  deviceId?: number;
  transferProtocol: TransferProtocolType;
  gatewayId: number;
}

export const createGateway = (params: CreateGatewayForm) =>
  fetch<{ id: number }>(
    `/api/v2/gateway/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const updateGateway = (params: CreateGatewayForm) =>
  fetch<{ id: number }>(
    `/api/v2/gateway/update`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const changeGatewayStatus = (id: number, state: GatewayStatus) =>
  fetch<void>(`/api/gateway/${id}/state?state=${state}`, {
    method: 'PUT',
  });

export interface DevicePageRequest extends PageRequest {
  name?: string;
  deviceTypes?: Array<number>;
  iotProtocol: IotProtocolType;
  gatewayId: string;
  tag?: string;
}

export function getDevicePage(params: DevicePageRequest): Promise<PageResponse<Device>> {
  return fetch(`/api/gateway/device/page?${qs.stringify(params, { indices: false })}`);
}

export interface GatewayDevicePageRequest extends PageRequest {
  iotProtocol: IotProtocolType;
  codeOrName?: string;
  primaryTypeId?: number;
  secondaryTypeId?: number;
  edgeGatewayId?: number;
  tag?: string;
}

export function getGatewayDevicePage(params: GatewayDevicePageRequest): Promise<PageResponse<Device>> {
  return fetch(
    `/api/v2/device/meta/data/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export const checkSerialNumber = (serialNumber: string) => {
  return fetch<{ ret: boolean }>(
    `/api/v2/gateway/checkSerialNumber`,
    {
      method: 'POST',
      body: JSON.stringify({ serialNumber }),
    },
    true
  );
};

interface UpdateGatewayDeviceForm {
  edgeGatewayId: number;
  deviceIds: number[];
  protocol: IotProtocolType;
}

export const updateGatewayDevice = (params: UpdateGatewayDeviceForm) =>
  fetch<void>(`/api/gateway/modify`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const getUploadFrequencyOpts = () => {
  return fetch<{ list: string[] }>(
    `/api/v2/gateway/uploadFrequency`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);
};

export interface ApplyLogResponse {
  id: number;
  time: number;
  state: boolean;
  distributeStatus: EdgeNetworkStatusEnum;
  updateByUsername: string;
}

export const getApplyLog = (id: number) => {
  return fetch<{ list: ApplyLogResponse[] }>(
    `/api/v2/gateway/config/distribute/log`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export const applyGateway = (id: number) => {
  return fetch<{ ret?: string }>(
    `/api/v2/gateway/config/distribute`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export const getGatewayListByFcs = () => {
  return fetch<{ list: Gateway[] }>(`/api/v2/gateway/list/fcs`, { method: 'POST' }, true);
};
