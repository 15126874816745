import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { DataPropertyType } from '@/shared/types';
import dayjs from 'dayjs';

export enum MeasurementType {
  ACCUM = 1,
  INSTANT = 2,
}

export interface Company {
  id: number;
  name: string;
}

export function getDeviceCompany(): Promise<{ list: Company[] }> {
  return fetch(
    `/api/v2/device/scene/list`,
    {
      method: 'POST',
    },
    true
  );
}

export interface Device {
  id: number;
  name: string;
}

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  parentId?: number;
  parentKey?: string;
  devices: Device[];
}

export interface DeviceTreeResponse {
  id: number;
  name: string;
  children: DeviceTypes[];
}

export type DataTypeDisplayName = Record<number, string>;

export interface DevicePoint {
  id: number;
  name: string;
  measurementType: MeasurementType;
  generalName?: string;
  type?: DataPropertyType;
  enumValue?: DataTypeDisplayName;
}

export function getDevicePoints(deviceId: number): Promise<{ list: DevicePoint[] }> {
  return fetch(
    `/api/v2/dataProperty/list-by-device-id`,
    {
      method: 'POST',
      body: JSON.stringify({ deviceId }),
    },
    true
  );
}

export enum FrequencyType {
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
  ORIGINAL = 'O',
}

export interface HistoryDataRequest {
  deviceId: number;
  propertyId: number;
  frequency: FrequencyType;
  measurementType: MeasurementType;
  startTime?: number;
  endTime?: number;
}

export interface RawData {
  time: number | null;
  value: number | string | null;
  updateTime: string | null;
}

export function getHistoryData(params: HistoryDataRequest): Promise<{ list: RawData[] }> {
  return fetch(
    `/api/v2/history/data/resampled`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface RawPageDataRequest extends PageRequest {
  deviceId: number;
  propertyId: number;
  startTime?: number;
  endTime?: number;
  type: DataPropertyType;
  filter?: boolean;
}

export type RawPageDataResponse = PageResponse<RawData>;

export function getRawPageData(params: RawPageDataRequest): Promise<RawPageDataResponse> {
  return fetch(
    `/api/v2/history/data/raw/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function getRawStrPageData(params: RawPageDataRequest): Promise<RawPageDataResponse> {
  return fetch(
    `/api/v2/history/data/raw/str/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface RawChartDataRequest {
  deviceId: number;
  propertyId: number;
  startTime?: number;
  endTime?: number;
}

export function getRawChartData(params: RawChartDataRequest): Promise<{ list: RawData[] }> {
  return fetch(
    `/api/v2/history/data/raw/list`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface DeviceSceneTreeRequest {
  deviceName?: string;
}

export interface DeviceSceneTreeProps {
  id: number;
  name: string;
  deviceTypeList: DeviceTreeResponse[];
}

export function getDeviceSceneTree(params: DeviceSceneTreeRequest): Promise<{ list: DeviceSceneTreeProps[] }> {
  return fetch(
    `/api/v2/device/sceneTree`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
export interface HistoryDataExportRequest {
  deviceId: number;
  propertyId: number[];
  timeSolution: FrequencyType;
  startTime?: number;
  endTime?: number;
  type: string;
}
export function exportHistoryData(params: HistoryDataExportRequest) {
  return window.open(
    `/api/v2/history/data/exportExcel?${qs.stringify({ timestamp: dayjs().valueOf(), ...params }, { indices: false })}`
  );
}
