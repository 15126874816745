import ReactEcharts from 'echarts-for-react';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';

export interface HistoryChartProps {
  option?: any;
}

const HistoryChart = (props: HistoryChartProps) => {
  const { option } = props;

  const echartsTheme = useEchartsTheme();

  return (
    <>
      <ReactEcharts theme={echartsTheme} option={option} style={{ height: 400 }} notMerge lazyUpdate={false} />
    </>
  );
};

export default HistoryChart;
