import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { OutputUnitType } from './outputConfig';

// 物料类型
export enum MaterielType {
  RAW_MATERIAL_AND_MATERIAL = 1000,
  CONSUMABLES,
  PACKAGING_MATERIALS,
  CARBON_SINKS,
  WASTE_GASES,
  WASTEWATER,
  SOLID_WASTE,
  HAZARDOUS_WASTE,
  RENEWABLE_WASTE,
}

export const MaterielTypeDisplay = {
  [MaterielType.RAW_MATERIAL_AND_MATERIAL]: '原材料/物料',
  [MaterielType.CONSUMABLES]: '耗材',
  [MaterielType.PACKAGING_MATERIALS]: '包装材料',
  [MaterielType.CARBON_SINKS]: '碳汇',
  [MaterielType.WASTE_GASES]: '废气',
  [MaterielType.WASTEWATER]: '废水',
  [MaterielType.SOLID_WASTE]: '固废',
  [MaterielType.HAZARDOUS_WASTE]: '危险废物',
  [MaterielType.RENEWABLE_WASTE]: '可再生废料',
};

// 碳足迹来源
export enum CarbonFootprintSource {
  SUPPLIERS_PROVIDE = 100,
  MEASURED_DATA,
  INDUSTRY_DATA,
  THEORETICAL_CALCULATIONS,
  LITERATURE_REPORT,
  OTHER,
}

export const CarbonFootprintSourceDisplay = {
  [CarbonFootprintSource.SUPPLIERS_PROVIDE]: '供应商提供',
  [CarbonFootprintSource.MEASURED_DATA]: '实测数据',
  [CarbonFootprintSource.INDUSTRY_DATA]: '行业数据',
  [CarbonFootprintSource.THEORETICAL_CALCULATIONS]: '理论计算',
  [CarbonFootprintSource.LITERATURE_REPORT]: '文献报告',
  [CarbonFootprintSource.OTHER]: '其他',
};

// 系统边界
export enum SystemBoundary {
  CRADLE_TO_DOOR = 100,
  DOOR_TO_DOOR,
  CRADLE_TO_GRAVE,
  CRADLE_TO_CRADLE,
}

export const SystemBoundaryDisplay = {
  [SystemBoundary.CRADLE_TO_DOOR]: '摇篮到大门',
  [SystemBoundary.DOOR_TO_DOOR]: '大门到大门',
  [SystemBoundary.CRADLE_TO_GRAVE]: '摇篮到坟墓',
  [SystemBoundary.CRADLE_TO_CRADLE]: '摇篮到摇篮',
};

export enum MaterielStatus {
  DISABLE = 0,
  ENABLE,
}

export const MaterielStatusDisplay = {
  [MaterielStatus.DISABLE]: '禁用',
  [MaterielStatus.ENABLE]: '启用',
};

// 物料管理列表
export interface MaterielManagementListParams extends PageRequest {
  state?: MaterielStatus | null; // 状态 不传查询全部
  type?: MaterielType | null; // 物料类型 不传查询全部
  codeOrNameOrSpec?: string; // 物料编码/名称/规格 不传查询全部
}

export interface MaterielManagementListRes {
  id: Key; // id
  type: MaterielType; // 物料类型
  code: string; // 编码
  name: string; // 名称
  spec: string; // 规格型号
  unitTypeCode: OutputUnitType; // 单位类型code （1 计量单位 2 计件单位）
  unitCode: string; // 单位code
  unitWeight: number; // 单位重量
  carbonFootPrint: number; // 碳足迹
  footPrintOrigin: CarbonFootprintSource; // 碳足迹来源
  boundary: SystemBoundary; // 系统边界
  state: MaterielStatus; // 状态0启用 1禁用
  remark: string; // 备注
  createTime: string; // 创建时间
}

export const getMaterielManagementList = (params: MaterielManagementListParams) =>
  fetch<PageResponse<MaterielManagementListRes>>(
    '/api/v2/material/page',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

// 物料管理新增
export interface MaterielManagementAddParams {
  id?: Key;
  type: MaterielType; // 物料类型
  code: string; // 编码
  name: string; // 名称
  spec?: string; // 规格型号
  unitTypeCode: OutputUnitType; // 单位类型code （1 计量单位 2 计件单位）
  unitCode: string; // 单位code
  unitWeight: number; // 单位重量
  carbonFootPrint: number; // 碳足迹
  footPrintOrigin: CarbonFootprintSource; // 碳足迹来源
  boundary: SystemBoundary; // 系统边界
  state: MaterielStatus;
  remark?: string; // 备注
}

export const addMateriel = (params: MaterielManagementAddParams) =>
  fetch(
    '/api/v2/material/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

// 物料修改
export const editMateriel = (params: MaterielManagementAddParams) =>
  fetch(
    '/api/v2/material/edit',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

// 物料详情
export const getMaterielDetail = (id: Key) =>
  fetch<MaterielManagementListRes>(
    '/api/v2/material/detail',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 物料删除
export const delMateriel = (id: Key) =>
  fetch(
    '/api/v2/material/delete',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

//物料入库管理
export interface MaterialWarehousingManagementParams extends PageRequest {
  name?: string;
}
// 碳足迹填报
export interface MaterialWarehousingReportingParams extends PageRequest {
  name?: string; // 物料编码/名称/规格 不传查询全部
}
