import React, { FC } from 'react';
import { Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { SettlementCycleType } from '@/shared/types';

interface SettlementTimeFormProps {
  value?: string;
  onChange?: (val: string | undefined) => void;
  type: SettlementCycleType;
  disabled?: boolean;
}

const SettlementCycleTypeTip = {
  [SettlementCycleType.DAY]: '每日',
  [SettlementCycleType.MONTH]: '每月',
};

const TimeData: Array<{ name: string; value: string }> = Array.from({ length: 24 }, (v, k) => k).map(item => ({
  name: `${item}时`,
  value: `${item}`,
}));

const MonthData: Array<{ name: string; value: string }> = Array.from({ length: 28 }, (v, k) => k)
  .map(item => ({ name: `${item + 1}日`, value: `${item + 1}` }))
  .concat({ name: '月末', value: '0' });

const settlementRegex = '/';
const SettlementTimeForm: FC<SettlementTimeFormProps> = ({ value, onChange, type, disabled }) => {
  return (
    <div>
      <div className={styles.timeTip}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
        <span>{'结算时间到达后，系统48小时内，会生成该周期的电费核查联'}</span>
      </div>
      <div className={styles.inline}>
        <div className={styles.suffix}>{SettlementCycleTypeTip[type]}</div>
        <div className={styles.content}>
          {type === SettlementCycleType.DAY ? (
            <div className={styles.form}>
              <Select
                disabled={disabled}
                style={{ width: 120 }}
                placeholder="请选择"
                value={type === SettlementCycleType.DAY ? value?.split('/')[0] : undefined}
                onChange={v => onChange && onChange(v)}
              >
                {TimeData.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ) : (
            <div className={styles.form}>
              <Select
                disabled={disabled}
                value={type === SettlementCycleType.MONTH ? value?.split('/')[0] : undefined}
                style={{ width: 120 }}
                placeholder={'请选择'}
                onChange={v => {
                  if (type === SettlementCycleType.MONTH) {
                    if (onChange) {
                      if (value) {
                        const dayAndHour = value.split(settlementRegex);
                        onChange(v + settlementRegex + (dayAndHour[1] || ''));
                      } else {
                        onChange(v + settlementRegex);
                      }
                    }
                  }
                }}
              >
                {MonthData.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
              <Select
                disabled={disabled}
                style={{ marginLeft: 10, width: 120 }}
                placeholder="请选择"
                value={type === SettlementCycleType.MONTH ? value?.split('/')[1] : undefined}
                onChange={v => {
                  if (type === SettlementCycleType.MONTH) {
                    if (onChange) {
                      if (value) {
                        const dayAndHour = value.split(settlementRegex);
                        onChange((dayAndHour[0] || '') + settlementRegex + v);
                      } else {
                        onChange(settlementRegex + v);
                      }
                    }
                  }
                }}
              >
                {TimeData.map(item => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettlementTimeForm;
