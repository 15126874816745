export enum ValueType {
  // 固定值 1
  fixed = 1,
  // 自动数采取数 = 2
  auto = 2,
}

export const ValueTypeDisplay = {
  [ValueType.fixed]: '固定值',
  [ValueType.auto]: '自动数采取数',
};

export interface AutoDataSettingTypes {
  /**
   * 要求物理量单位id
   */
  requirePhysicalUnitId?: number;
  transformCoefficient?: number;
  /**
   * 用能分析组id
   */
  energyGroupId?: number;
  /**
   * 用能单元id
   */
  energyUnitIds?: number[];
  /**
   * 计量类别id
   */
  meterTypeId?: number;
  /**
   * 默认物理量单位id
   */
  defaultPhysicalUnitId?: number;
}
