import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { V2OeeConfigHistoryPostResponse, apiV2OeeConfigHistoryPost } from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import { DatabaseFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { IsEqual } from '../../utils';

export interface EditOEEConfigModalProps {
  itemId?: number;
  visible: boolean;
  onCancel: () => void;
}

type RecordItem = Exclude<V2OeeConfigHistoryPostResponse['list'], undefined>[number] & {
  range: string;
};

const HistoryRecordModal: React.FC<EditOEEConfigModalProps> = ({ itemId, visible, onCancel }) => {
  const [loading, setLoading] = useState(true);
  const [recordList, setRecordList] = useState<RecordItem[]>([]);
  const [currentRecordId, setCurrentRecordId] = useState<number>();

  useEffect(() => {
    if (isNil(itemId)) return;
    setLoading(true);
    apiV2OeeConfigHistoryPost({ id: itemId })
      .then(res => {
        const list = (res.list ?? []).map(i => ({
          ...i,
          range: `${i.effectiveDate} ~ ${i.endDate ?? '无限期'}`,
        }));
        setRecordList(list);
        setCurrentRecordId(list?.[0]?.id);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const currentRecord = useMemo(() => {
    return recordList.find(k => k.id === currentRecordId);
  }, [recordList, currentRecordId]);

  return (
    <Modal
      size="large"
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          关闭
        </Button>,
      ]}
      title="历史记录"
    >
      <Spin spinning={loading}>
        <div className={styles.bothBox}>
          <div className={styles.leftDate}>
            <div className={styles.title}>生效日期</div>
            <div className={styles.dateList}>
              {recordList.map(k => (
                <div
                  key={k.id}
                  className={`${styles.rangeName} ${currentRecordId === k.id && styles.selected}`}
                  onClick={() => {
                    setCurrentRecordId(k.id);
                  }}
                >
                  {k.range}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.rightInfo}>
            <div className={styles.title}>
              <DatabaseFilled style={{ color: 'var(--mx-primary-color)' }} />
              {currentRecord?.name ?? '--'}
            </div>
            <div className={styles.row}>
              <span className={styles.label}>计量设备：</span>
              <div>{currentRecord?.deviceName ?? '--'}</div>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>关联生产过程：</span>
              <div>{currentRecord?.productionProcessName ?? '--'}</div>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>关联产出物：</span>
              <div>{currentRecord?.outputList?.map(m => m.outputProductName)?.join('，') ?? '--'}</div>
            </div>
            <div className={styles.row}>
              <span className={styles.label}>产出物-理论节拍：</span>
              <div>
                {currentRecord?.outputList?.map((m, index) => (
                  <div key={index} className={styles.beatInfo}>
                    {m.outputProductName}理论节拍:
                    {currentRecord.beatEquals === IsEqual.YES ? currentRecord.outputBeat : m.outputBeat}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default HistoryRecordModal;
