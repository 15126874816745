import { CustomFilter, EllipsisSpan, Modal, Paging, Table, usePaging } from '@maxtropy/components';
import { Form, Input, Select } from 'antd';
import { FC, Key, useEffect, useState } from 'react';

import styles from './index.module.scss';

import { useRequest } from 'ahooks';
import { getDistributionCabinetList } from '@/api/circuit';
import { getOuListOwned } from '@/api/ou';
import {
  apiV2CircuitLabelAllPost,
  apiV2CircuitLabelBindPost,
  apiV2CircuitPagePost,
} from '@maxtropy/device-customer-apis-v2';

interface SearchParamsProps {
  codeOrName?: string;
  typeIds?: number[]; // 产品
  customerMcid?: string; // 所属组织
  ouIds?: number[];
}

type FormValues = {
  codeOrName?: string;

  labelId?: number[];

  distributionCabinetId?: number;
  ouId?: number;
};

const columns = [
  {
    title: '回路名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属配电柜',
    dataIndex: 'distributionCabinetName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路标签',
    dataIndex: 'labelName',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v?.toString() ?? '--'} />,
  },
];
interface Iprops {
  linkIds?: number[];
  labelId?: number;
  onChangeSelectKeys?: (keys?: number[]) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const CircuitLinkModal: FC<Iprops> = ({ linkIds, labelId, onChangeSelectKeys, onConfirm, onCancel }) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParamsProps>();

  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { data: cabinetList } = useRequest(getDistributionCabinetList);
  const { data: ouList } = useRequest(getOuListOwned);
  const { data: labelList } = useRequest(() => apiV2CircuitLabelAllPost({}));

  const { data: list, loading } = useRequest(
    () =>
      apiV2CircuitPagePost({
        page: pageOffset,
        size: pageSize,
        ...searchParams,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      }),
    {
      refreshDeps: [pageOffset, pageSize, searchParams],
    }
  );
  const { runAsync, loading: confirmLoading } = useRequest(params => apiV2CircuitLabelBindPost(params), {
    manual: true,
    refreshDeps: [labelId, selectedRowKeys],
  });
  const onFinish = (v: FormValues) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      codeOrName: undefined,
      labelId: undefined,

      distributionCabinetId: undefined,
      ouId: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };
  useEffect(() => {
    if (linkIds && linkIds.length) {
      setSelectedRowKeys(linkIds);
    }
  }, [linkIds]);

  const onSelectChange = (rowKeys: Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  const rowSelection = {
    hideSelectAll: true,

    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    selectedRowKeys,
  };
  const confirmBtn = () => {
    if (!labelId) return;
    runAsync({ circuitIds: selectedRowKeys, labelId }).then(() => {
      onConfirm?.();
    });
  };
  return (
    <Modal
      title="关联回路"
      width={1024}
      confirmLoading={confirmLoading}
      open
      onOk={confirmBtn}
      onCancel={() => onCancel?.()}
    >
      <CustomFilter onFinish={onFinish} colSpan={8} form={form} onReset={onReset}>
        <Form.Item name="codeOrName" label="编号/名称">
          <Input placeholder="请输入编号或名称查询" />
        </Form.Item>
        <Form.Item name="distributionCabinetId" label="所属配电柜">
          <Select
            placeholder="请选择"
            allowClear
            showSearch
            optionFilterProp="label"
            options={(cabinetList?.list ?? []).map(i => ({ value: i.id, label: i.name }))}
          />
        </Form.Item>
        <Form.Item name="ouId" label="所属运营单元">
          <Select
            placeholder="请选择"
            allowClear
            optionFilterProp="label"
            options={(ouList ?? []).map(i => ({ value: i.id, label: i.name }))}
          />
        </Form.Item>
        <Form.Item name="labelId" label="回路标签">
          <Select
            placeholder="请选择"
            allowClear
            mode="multiple"
            optionFilterProp="label"
            options={(labelList?.list ?? []).map(i => ({ value: i.id, label: i.name }))}
          />
        </Form.Item>
      </CustomFilter>
      <Table
        loading={loading}
        pagination={false}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={list}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
      />
      <div className={styles.page_box}>
        <div className={styles.page_before}>
          已选择<span className={styles.red}>{(selectedRowKeys ?? []).length}</span>项
        </div>
        <div className={styles.page_after}>
          <Paging pagingInfo={pagingInfo} />
        </div>
      </div>
    </Modal>
  );
};

export default CircuitLinkModal;
