import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  Checkbox,
  Form,
  InputNumber,
  Modal,
  Radio,
  DatePicker,
  Select,
  Tabs,
  Tooltip,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, Result, Row, Space, Spin, TabsProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { SaleClientInfo, getSaleClientList } from '@/api/sale';
import {
  EnergyAssetsPVStationDevice,
  EnergyAssetsPVStationInOu,
  EnergyAssetsSettlementsRulesDetailRes,
  TimeIntervalType,
  getEnergyAssetsPVStationDevice,
  getEnergyAssetsPVStationInOu,
  getEnergyAssetsSettlementsRulesDetail,
} from '@/api/energyAssetsSettlementsRules';
import { ElectricitySaleContractSettlementType } from '@/api/electricitySettlementRules';
import {
  BSADeviceItem,
  BSAItem,
  Direction,
  ElectricitySaleContractItem,
  EnergyAssetsBillStatus,
  EnergyAssetsSettlementsRulesItem,
  ManualGenerateEnergyAssetsBillBody,
  MeterReadingRecordSource,
  MeterType,
  getBSADeviceItem,
  getBSAList,
  getElectricitySaleContractList,
  getEnergyAssetsSettlementsRulesList,
  manualGenerateEnergyAssetsBill,
} from '@/api/energyAssetsBill';
import CustomTariff from '@/pages/EnergyAssetsSettlementsRules/components/CustomTariff';
import {
  limitDecimalsF,
  limitDecimalsFTwo,
  limitDecimalsPEight,
  limitDecimalsPTwo,
} from '@/pages/EnergyAssetsSettlementsRules/units';
import dayjs, { Dayjs } from 'dayjs';
import { DateDisplay } from '../utils';
import InputTableItem from '../components/InputTableItem';
import { Link, useNavigate } from 'react-router-dom';
import {
  ExclamationCircleOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import { isNil } from 'lodash-es';

const routes = [{ name: '生成结算单' }];
const { RangePicker } = DatePicker;

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export interface DataType {
  forwardKey: string; // 正向key
  reverseKey: string; // 反向key
  deviceId: Key; // 设备id
  deviceCode: string; // 设备编号
  deviceName: string; // 设备名称
  rate: number; // 倍率
  forward?: string; // 正向
  reverse?: string; // 反向
  interval: TimeIntervalType; // 时段
  meterType: MeterType; // 电表类型
  addressId: Key; // 电表地址id
  deviceTypeId: Key; // 设备类型id
}

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface FormValues {
  saleClientId: Key; // 客户id
  type: number; // 结算规则类型
  settlementRuleId?: Key | null; // 已创建结算规则： 结算规则id
  timeOfDayRateChecked?: number[]; // 时段选择
  summitPrice?: number; // 尖
  peakPrice?: number; // 峰
  plainPrice?: number; // 平
  valleyPrice?: number; // 谷
  saleElectricityContractId?: Key; // 所属合同
  settlementType?: ElectricitySaleContractSettlementType; // 结算类型
  settlementTime: [Dayjs, Dayjs]; // 选择结算周期
  gridPrice?: number; // 上网电价
  discountForOwner?: number; // 放电折扣 / 电价折扣
  gridPercentageForOwner?: number; // 上网分成比例
  arrayId?: Key; // 光伏站 / 储能站
  meterReadingRecordSource: MeterReadingRecordSource; // 抄表来源
  inputTime?: string[]; // 录入日期
}

const formatDataToMeterReadingData = (data: DataType[]) => {
  return [Direction.FORWARD, Direction.REVERSE]
    .map(direction => {
      return data.map(i => ({
        deviceId: i.deviceId,
        timeInterval: i.interval,
        direction,
        value: direction === Direction.FORWARD ? i.forward ?? null : i.reverse ?? null,
        addressId: i.addressId,
        deviceTypeId: i.deviceTypeId,
        rate: i.rate,
      }));
    })
    .flat();
};

const CreateEnergyAssetsBill: React.FC = () => {
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]); // 客户列表
  const [settlementRulesDetail, setSettlementRulesDetail] = useState<EnergyAssetsSettlementsRulesDetailRes>(); // 结算规则详情
  const saleClientId = Form.useWatch('saleClientId', form); // 客户id
  const type = Form.useWatch('type', form); // 结算规则类型
  const settlementRuleId = Form.useWatch('settlementRuleId', form); // 已创建结算规则： 结算规则id
  const settlementType = Form.useWatch('settlementType', form); // 结算类型
  const arrayId = Form.useWatch('arrayId', form); // 光伏站id
  const meterReadingRecordSource = Form.useWatch('meterReadingRecordSource', form); // 抄表来源
  const inputTime = Form.useWatch('inputTime', form); // 录入日期
  const settlementTime = Form.useWatch('settlementTime', form); // 选择结算周期
  const [pvStationDeviceData, setPVStationDeviceData] = useState<EnergyAssetsPVStationDevice[]>([]); // 光伏站下的设备列表
  const [bsaStationDeviceData, setBSAStationDeviceData] = useState<BSADeviceItem[]>([]); // 储能站下的设备列表
  const [pvStationList, setPVStationList] = useState<EnergyAssetsPVStationInOu[]>([]); // 光伏站列表
  const [bsaStationList, setBSAStationList] = useState<BSAItem[]>([]); // 储能站列表
  const [startTimeDataSource, setStartTimeDataSource] = useState<DataType[]>([]); // 期初值
  const [endTimeDataSource, setEndTimeDataSource] = useState<DataType[]>([]); // 期末值
  const [electricitySaleContractList, setElectricitySaleContractList] = useState<ElectricitySaleContractItem[]>([]); // 合同列表
  const [settlementDates, setSettlementDates] = useState<RangeValue>(null);
  const [settlementValue, setSettlementValue] = useState<RangeValue>(null);
  const [settlementsRules, setSettlementRules] = useState<EnergyAssetsSettlementsRulesItem[]>([]);
  const [creatingVisible, setCreatingVisible] = useState<boolean>(false);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [warningTitle, setWarningTitle] = useState<React.ReactNode>();

  useEffect(() => {
    // 获取客户列表
    getSaleClientList().then(setCustomList);
    // 查询客户ou下所有启用的光伏站
    getEnergyAssetsPVStationInOu().then(setPVStationList);
    // 查询客户ou下所有启用的储能站
    getBSAList().then(setBSAStationList);
  }, []);

  useEffect(() => {
    if (saleClientId) {
      // 查询客户下的所有结算规则
      getEnergyAssetsSettlementsRulesList(saleClientId).then(res => {
        setSettlementRules(res?.list);
      });
      // 查询客户下的所有合同
      getElectricitySaleContractList(saleClientId).then(setElectricitySaleContractList);
    }
  }, [saleClientId]);

  useEffect(() => {
    // 根据结算规则id查询结算规则详情
    if (settlementRuleId) {
      getEnergyAssetsSettlementsRulesDetail(settlementRuleId).then(res => {
        setSettlementRulesDetail(res);
        // 分布式光伏
        if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
          getEnergyAssetsPVStationDevice(res.arrayId).then(res => {
            if (res) {
              if (res.some(i => isNil(i.rate))) {
                Modal.warning({
                  title: '电表倍率为空，无法生成结算单。',
                });
              }
              setPVStationDeviceData(res);
            }
          });
        }
        // 分布式储能
        if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
          getBSADeviceItem(res.arrayId).then(res => {
            if (res) {
              const list = [res];
              if (list.some(i => isNil(i.rate))) {
                Modal.warning({
                  title: '电表倍率为空，无法生成结算单。',
                });
              }
              setBSAStationDeviceData(list);
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementRuleId]);

  // 光伏设备数据转成期初值、期末值table数据
  useEffect(() => {
    setStartTimeDataSource([]);
    setEndTimeDataSource([]);
    const data = pvStationDeviceData.map((i, ind) => {
      let arr: DataType[] = [];
      const time = [1, 2, 3, 4];
      for (let num = 0; num < 4; num++) {
        arr.push({
          forwardKey: `forward-${i.name}-${ind}${num}`,
          reverseKey: `reverse-${i.name}-${ind}${num}`,
          deviceId: i.id,
          deviceCode: i.code,
          deviceName: i.name,
          rate: i.rate,
          addressId: i.addressId,
          deviceTypeId: i.deviceTypeId,
          forward: undefined,
          reverse: undefined,
          interval: time[num],
          meterType: i.address === '并网点' ? MeterType.POINT_OF_INTEGRATION : MeterType.GATEWAY,
        });
      }
      return arr;
    });
    setStartTimeDataSource(
      data.flat().map(i => ({ ...i, forwardKey: `start-${i.forwardKey}`, reverseKey: `start-${i.reverseKey}` }))
    );
    setEndTimeDataSource(
      data.flat().map(i => ({ ...i, forwardKey: `end-${i.forwardKey}`, reverseKey: `end-${i.reverseKey}` }))
    );
  }, [pvStationDeviceData]);

  // 储能设备数据转成期初值、期末值table数据
  useEffect(() => {
    setStartTimeDataSource([]);
    setEndTimeDataSource([]);
    const data = bsaStationDeviceData.map((i, ind) => {
      let arr: DataType[] = [];
      const time = [1, 2, 3, 4];
      for (let num = 0; num < 4; num++) {
        arr.push({
          forwardKey: `forward-${i.name}-${ind}${num}`,
          reverseKey: `reverse-${i.name}-${ind}${num}`,
          deviceId: i.id,
          deviceCode: i.code,
          deviceName: i.name,
          rate: i.rate,
          addressId: i.addressId,
          deviceTypeId: i.deviceTypeId,
          forward: undefined,
          reverse: undefined,
          interval: time[num],
          meterType: MeterType.METERING,
        });
      }
      return arr;
    });

    setStartTimeDataSource(
      data.flat().map(i => ({ ...i, forwardKey: `start-${i.forwardKey}`, reverseKey: `start-${i.reverseKey}` }))
    );
    setEndTimeDataSource(
      data.flat().map(i => ({ ...i, forwardKey: `end-${i.forwardKey}`, reverseKey: `end-${i.reverseKey}` }))
    );
  }, [bsaStationDeviceData]);

  useEffect(() => {
    if (arrayId) {
      // 查询光伏站下的设备
      if (settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
        getEnergyAssetsPVStationDevice(arrayId).then(res => {
          if (res) {
            if (res.some(i => isNil(i.rate))) {
              Modal.warning({
                title: '电表倍率为空，无法生成结算单。',
                // content: '',
              });
            }
            setPVStationDeviceData(res);
          }
        });
      }
      // 查询储能站下的设备
      if (settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
        getBSADeviceItem(arrayId).then(res => {
          if (res) {
            const list = [res];
            if (list.some(i => isNil(i.rate))) {
              Modal.warning({
                title: '电表倍率为空，无法生成结算单。',
                // content: '',
              });
            }
            setBSAStationDeviceData(list);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayId, settlementType]);

  //  客户列表
  const customListOptions = useMemo(() => {
    return (customList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [customList]);

  // 光伏站选项
  const pvStationListOptions = useMemo(() => {
    return (pvStationList ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [pvStationList]);

  // 储能站选项
  const bsaStationListOptions = useMemo(() => {
    return (bsaStationList ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [bsaStationList]);

  // 结算规则选项
  const settlementRulesOptions = useMemo(() => {
    return (settlementsRules ?? []).map(i => ({
      label: i.ruleName,
      value: i.ruleId,
    }));
  }, [settlementsRules]);

  // 合同选项
  const electricitySaleContractListOptions = useMemo(() => {
    return (electricitySaleContractList ?? []).map(i => ({
      label: `${i.code} 【${i.name}】`,
      value: i.id,
    }));
  }, [electricitySaleContractList]);

  // 自定义结算规则 选择结算周期
  const cusSettlementDisabledDate = (current: Dayjs) => {
    if (!settlementDates) {
      return false;
    }
    const tooLate = settlementDates[0] && current.diff(settlementDates[0], 'year') >= 1;
    const tooEarly = settlementDates[1] && settlementDates[1].diff(current, 'year') >= 1;
    return !!tooEarly || !!tooLate;
  };

  const pvStationSame = (
    <>
      <Col span={24}>
        <Form.Item label="并网点电表">
          <ShowInput
            value={
              pvStationDeviceData.filter(i => i.address === '并网点').length === 0
                ? undefined
                : pvStationDeviceData
                    .filter(i => i.address === '并网点')
                    .map(i => i.name)
                    .join('、')
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="关口表">
          <ShowInput
            value={
              pvStationDeviceData.filter(i => i.address === '关口表').length === 0
                ? undefined
                : pvStationDeviceData
                    .filter(i => i.address === '关口表')
                    .map(i => i.name)
                    .join('、')
            }
          />
        </Form.Item>
      </Col>
    </>
  );

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '期初值',
      children: (
        <>
          <p>日期：{settlementTime ? `${dayjs(settlementTime[0]).format(DateDisplay)} 0点` : '--'}</p>
          <InputTableItem
            dataSource={startTimeDataSource}
            onChange={v => {
              setStartTimeDataSource(v);
            }}
          />
        </>
      ),
    },
    {
      key: '2',
      label: '期末值',
      children: (
        <>
          <p>日期：{settlementTime ? `${dayjs(settlementTime[1]).add(1, 'day').format(DateDisplay)} 0点` : '--'}</p>
          <InputTableItem
            dataSource={endTimeDataSource}
            onChange={v => {
              setEndTimeDataSource(v);
            }}
          />
        </>
      ),
    },
  ];

  const onFinish = (v: FormValues) => {
    let body: ManualGenerateEnergyAssetsBillBody = {
      settlementRuleId: v.type === 1 ? v.settlementRuleId : null,
      settlementStartDate: v.settlementTime[0].format(DateDisplay),
      settlementEndDate: v.settlementTime[1].format(DateDisplay),
      meterReadingRecordSource: v.meterReadingRecordSource,
      beginDayData:
        v.meterReadingRecordSource === MeterReadingRecordSource.MANUAL && v.inputTime?.includes('1')
          ? formatDataToMeterReadingData(startTimeDataSource)
          : undefined,
      endDayData:
        v.meterReadingRecordSource === MeterReadingRecordSource.MANUAL && v.inputTime?.includes('2')
          ? formatDataToMeterReadingData(endTimeDataSource)
          : undefined,
    };
    // 自定义结算规则
    if (v.type === 2) {
      body = {
        ...body,
        settlementType: v.settlementType,
        saleElectricityContractId: v.saleElectricityContractId,
        summitPrice: v.summitPrice,
        peakPrice: v.peakPrice,
        plainPrice: v.plainPrice,
        valleyPrice: v.valleyPrice,
        gridPrice: v.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV ? v.gridPrice : undefined,
        gridPercentageForOwner:
          v.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV
            ? v.gridPercentageForOwner
            : undefined,
        discountForOwner: v.discountForOwner,
        arrayId: v.arrayId,
      };
    }
    setCreatingVisible(true);
    manualGenerateEnergyAssetsBill(body)
      .then(res => {
        if (res) {
          // 判断类型
          const { errorCode, data } = res;
          // 成功
          if (errorCode === EnergyAssetsBillStatus.NORMAL) {
            setSuccessVisible(true);
            return;
          }
          // 设备抄表获取失败
          let title;
          // 缺少计量表计
          if (errorCode === EnergyAssetsBillStatus.ABNORMAL_FAIL_GET) {
            title = (
              <span>
                设备抄表获取失败，请重试，设备编号：{data}。
                <Button disabled type="link" onClick={() => {}}>
                  <span style={{ fontSize: 16 }}>点击查看</span>
                </Button>
                抄表记录。
              </span>
            );
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_METER) {
            title = (
              <span>
                结算规则中的站点缺少计量电表，
                <Button
                  type="link"
                  onClick={() => {
                    window.open(`/operation/settlementRules/energyAssetsRules/details/${data}`, '_blank');
                  }}
                >
                  <span style={{ fontSize: 16 }}>点击查看</span>
                </Button>
                结算规则。
              </span>
            );
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_MODAL_PRICE) {
            title = <span>未获取到电价模版中的电度电价。</span>;
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_UET_ID) {
            title = <span>uetId获取失败。</span>;
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MANUAL_FAILED) {
            title = <span>手工抄表失败。</span>;
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_FEE) {
            title = <span>未获取到电价模版中的电度电价。</span>;
          } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_RATE_NULL) {
            title = <span>电表倍率为空，无法生成结算单。</span>;
          } else {
            title = <span>系统错误。请重试或联系管理员。</span>;
          }
          setWarningTitle(title);
          setWarningOpen(true);
        }
      })
      .finally(() => {
        setCreatingVisible(false);
      });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="生成结算单" />

      <Form
        form={form}
        {...formLayout}
        onFinish={(v: FormValues) => onFinish(v)}
        initialValues={{
          timeOfDayRateChecked: [1, 2, 3, 4],
          inputTime: ['2'],
        }}
        preserve={false}
      >
        <SubContent className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item label="客户" name="saleClientId" rules={[{ required: true, message: '请选择客户' }]}>
                <Select
                  placeholder="请输入并选择"
                  options={customListOptions}
                  showSearch
                  optionFilterProp="label"
                  onChange={v => {
                    // 客户切换，清空form
                    form.resetFields();
                    setBSAStationDeviceData([]);
                    setPVStationDeviceData([]);
                    setSettlementValue(null);
                    setSettlementDates(null);
                    setSettlementRulesDetail(undefined);
                    form.setFieldsValue({
                      saleClientId: v,
                      timeOfDayRateChecked: [1, 2, 3, 4], // 时段选择
                      inputTime: ['2'], // 录入日期
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="结算规则" name="type" rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group
                  onChange={() => {
                    setBSAStationDeviceData([]); // 清空储能设备列表
                    setPVStationDeviceData([]); // 清空光伏设备列表
                    setSettlementValue(null); // 清空结算周期
                    setSettlementDates(null); // 清空结算周期
                    setSettlementRulesDetail(undefined); // 清空结算规则详情
                    form.setFieldsValue({
                      settlementRuleId: undefined, // 结算规则
                      settlementTime: undefined, // 结算周期
                      meterReadingRecordSource: undefined, // 抄表来源
                      timeOfDayRateChecked: [1, 2, 3, 4], // 时段选择
                      summitPrice: undefined, // 尖
                      peakPrice: undefined, // 峰
                      plainPrice: undefined, // 平
                      valleyPrice: undefined, // 谷
                      inputTime: ['2'], // 录入日期
                      gridPrice: undefined, // 上网电价
                      discountForOwner: undefined, // 放电折扣 / 电价折扣
                      gridPercentageForOwner: undefined, // 上网分成比例
                      arrayId: undefined, // 光伏站 / 储能站
                    });
                  }}
                >
                  <Radio value={1}>已创建的结算规则</Radio>
                  <Radio value={2}>自定义</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* 已创建的结算规则  */}
            {type === 1 ? (
              <>
                <Col span={24}>
                  <Form.Item
                    label="结算规则"
                    name="settlementRuleId"
                    rules={[{ required: true, message: '请选择结算规则' }]}
                  >
                    <Select
                      disabled={!saleClientId}
                      placeholder={!saleClientId ? '请先选择客户' : '请输入并选择'}
                      options={settlementRulesOptions}
                      showSearch
                      optionFilterProp="label"
                      onChange={() => {
                        setBSAStationDeviceData([]); // 清空储能设备列表
                        setPVStationDeviceData([]); // 清空光伏设备列表
                        setSettlementValue(null); // 清空结算周期
                        setSettlementDates(null); // 清空结算周期
                        setSettlementRulesDetail(undefined); // 清空结算规则详情
                        form.setFieldsValue({
                          settlementTime: undefined, // 结算周期
                          meterReadingRecordSource: undefined, // 抄表来源
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                {settlementRuleId && settlementRulesDetail ? (
                  <>
                    {isNil(settlementRulesDetail.settlementTemplate) ? (
                      <>
                        <Col span={6}>
                          <Form.Item label="尖">
                            <ShowInput addonAfter="元/kWh" value={settlementRulesDetail.summitPrice} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label="峰">
                            <ShowInput addonAfter="元/kWh" value={settlementRulesDetail.peakPrice} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label="平">
                            <ShowInput addonAfter="元/kWh" value={settlementRulesDetail.plainPrice} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label="谷">
                            <ShowInput addonAfter="元/kWh" value={settlementRulesDetail.valleyPrice} />
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={6}>
                          <Form.Item label="电价模板">
                            <ShowInput value={settlementRulesDetail.settlementTemplateName} />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {/* 分布式光伏 */}
                    <>
                      {settlementRulesDetail.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV ? (
                        <>
                          <Col span={6}>
                            <Form.Item label="上网电价">
                              <ShowInput addonAfter="元/kWh" value={settlementRulesDetail.gridPrice} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label={
                                <>
                                  <span style={{ marginRight: 10 }}>电价折扣</span>
                                  <Tooltip title="按电价的折扣，将光伏的电卖给业主方，其中1代表无折扣。">
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </>
                              }
                            >
                              <ShowInput value={settlementRulesDetail.discountForOwner} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              label={
                                <>
                                  <span style={{ marginRight: 10 }}>上网分成比例</span>
                                  <Tooltip title="按上网收入的比例分成给业主方，如上网收益为100元，此处填写0.2，则20元作为业主方的收入。">
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </>
                              }
                            >
                              <Space></Space>
                              <ShowInput value={settlementRulesDetail.gridPercentageForOwner} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="光伏站">
                              <ShowInput value={settlementRulesDetail.arrayName} />
                            </Form.Item>
                          </Col>
                          {pvStationSame}
                        </>
                      ) : null}
                    </>
                    {/* 分布式储能 */}
                    <>
                      {settlementRulesDetail.settlementType ===
                      ElectricitySaleContractSettlementType.DISTRIBUTED_BSA ? (
                        <>
                          <Col span={12}>
                            <Form.Item
                              label={
                                <>
                                  <span style={{ marginRight: 10 }}>放电折扣</span>
                                  <Tooltip title="按电价的折扣，将储能放的电按折扣后的电价卖给业主方，其中1代表无折扣">
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </>
                              }
                            >
                              <ShowInput value={settlementRulesDetail.discountForOwner} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="储能站">
                              <ShowInput value={settlementRulesDetail.arrayName} />
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item label="计量电表">
                              <ShowInput
                                value={
                                  bsaStationDeviceData.length === 0
                                    ? undefined
                                    : bsaStationDeviceData.map(i => i.name).join('、')
                                }
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : null}
                    </>
                  </>
                ) : null}
                <Col span={24}>
                  <Form.Item
                    label="选择结算周期"
                    name="settlementTime"
                    rules={[{ required: true, message: '请选择结算周期' }]}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      disabledDate={date => {
                        const disabledStart = settlementRulesDetail
                          ? dayjs(settlementRulesDetail.startTime).isBefore(
                              dayjs(settlementRulesDetail.contractStartTime)
                            )
                            ? dayjs(settlementRulesDetail.contractStartTime)
                            : settlementRulesDetail.startTime
                          : null;
                        const disabledEnd = settlementRulesDetail
                          ? dayjs(settlementRulesDetail.endTime).isAfter(dayjs(settlementRulesDetail.contractEndTime))
                            ? dayjs(settlementRulesDetail.contractEndTime)
                            : dayjs(settlementRulesDetail.endTime)
                          : null;
                        return settlementRulesDetail
                          ? date.isBefore(disabledStart) || date.isAfter(disabledEnd?.add(1, 'day'))
                          : false;
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
            {/* 自定义  */}
            {type === 2 ? (
              <>
                <Col span={24}>
                  <Form.Item
                    label="所属合同"
                    name="saleElectricityContractId"
                    rules={[{ required: true, message: '请选择所属合同' }]}
                  >
                    <Select
                      disabled={!saleClientId}
                      placeholder={!saleClientId ? '请先选择客户' : '请输入并选择'}
                      options={electricitySaleContractListOptions}
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="结算类型"
                    name="settlementType"
                    rules={[{ required: true, message: '请选择结算类型' }]}
                  >
                    <Radio.Group
                      onChange={e => {
                        setBSAStationDeviceData([]); // 清空储能设备列表
                        setPVStationDeviceData([]); // 清空光伏设备列表
                        setSettlementValue(null); // 清空结算周期
                        setSettlementDates(null); // 清空结算周期
                        setSettlementRulesDetail(undefined); // 清空结算规则详情

                        form.setFieldsValue({
                          gridPrice: undefined, // 上网电价
                          discountForOwner: undefined, // 放电折扣 / 电价折扣
                          gridPercentageForOwner: undefined, // 上网分成比例
                          arrayId: undefined, // 光伏站 / 储能站
                          settlementTime: undefined, // 结算周期
                          meterReadingRecordSource: undefined, // 抄表来源
                        });
                      }}
                    >
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTED_PV}>分布式光伏</Radio>
                      <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTED_BSA}>分布式储能</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <CustomTariff />
                </Col>
                {/* 分布式光伏 */}
                {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV ? (
                  <>
                    <Col span={24}>
                      <Form.Item
                        name="gridPrice"
                        label="上网电价"
                        rules={[{ required: true, message: '请输入上网电价' }]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          addonAfter="元/kWh"
                          formatter={limitDecimalsF}
                          parser={limitDecimalsPEight}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="discountForOwner"
                        label="电价折扣"
                        rules={[{ required: true, message: '请输入电价折扣' }]}
                        extra={
                          <div style={{ marginTop: 8 }}>
                            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 6 }} />
                            填写范围：0-1，支持小数点后两位，按电价的折扣，将光伏的电卖给业主方，其中1代表无折扣
                          </div>
                        }
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          max={1}
                          min={0}
                          step="0.01"
                          formatter={limitDecimalsFTwo}
                          parser={limitDecimalsPTwo}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="gridPercentageForOwner"
                        label="上网分成比例"
                        rules={[{ required: true, message: '请输入上网分成比例' }]}
                        extra={
                          <div style={{ marginTop: 8 }}>
                            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 6 }} />
                            填写范围：0-1，支持小数点后两位，按上网收入的比例分成给业主方。如上网收益为100元，此处填写0.2，则20元作为业主方的收入
                          </div>
                        }
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          max={1}
                          min={0}
                          step="0.01"
                          formatter={limitDecimalsFTwo}
                          parser={limitDecimalsPTwo}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="arrayId" label="光伏站" rules={[{ required: true, message: '请选择光伏站' }]}>
                        <Select
                          placeholder={'请选择'}
                          options={pvStationListOptions}
                          showSearch
                          optionFilterProp="label"
                          onChange={() => {
                            setBSAStationDeviceData([]); // 清空储能设备列表
                            setPVStationDeviceData([]); // 清空光伏设备列表
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {pvStationSame}
                  </>
                ) : null}
                {/* 分布式储能 */}
                {settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA ? (
                  <>
                    <Col span={24}>
                      <Form.Item
                        label="放电折扣"
                        name="discountForOwner"
                        rules={[{ required: true, message: '请输入放电折扣' }]}
                        extra={
                          <div style={{ marginTop: 8 }}>
                            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 6 }} />
                            填写范围：0-1，支持小数点后两位，按电价的折扣，将储能放的电按折扣后的电价卖给业主方，其中1代表无折扣
                          </div>
                        }
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="请输入"
                          max={1}
                          min={0}
                          step="0.01"
                          formatter={limitDecimalsFTwo}
                          parser={limitDecimalsPTwo}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="arrayId" label="储能站" rules={[{ required: true, message: '请选择储能站' }]}>
                        <Select
                          placeholder={'请选择'}
                          options={bsaStationListOptions}
                          showSearch
                          optionFilterProp="label"
                          onChange={() => {
                            setBSAStationDeviceData([]); // 清空储能设备列表
                            setPVStationDeviceData([]); // 清空光伏设备列表
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="计量电表">
                        <ShowInput
                          value={
                            bsaStationDeviceData.length === 0
                              ? undefined
                              : bsaStationDeviceData.map(i => i.name).join('、')
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
                <Col span={24}>
                  <Form.Item
                    label="选择结算周期"
                    name="settlementTime"
                    rules={[{ required: true, message: '请选择结算周期' }]}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      value={settlementDates || settlementValue}
                      disabledDate={cusSettlementDisabledDate}
                      allowClear={false}
                      onCalendarChange={val => setSettlementDates(val)}
                      onChange={val => setSettlementValue(val)}
                      onOpenChange={open => {
                        if (open) {
                          form.setFieldsValue({
                            settlementTime: undefined,
                          });
                          setSettlementDates([null, null]);
                        } else {
                          setSettlementDates(null);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}

            <Col span={24}>
              <Form.Item
                label="抄表来源"
                name="meterReadingRecordSource"
                rules={[{ required: true, message: '请选择抄表来源' }]}
              >
                <Radio.Group>
                  <Radio value={MeterReadingRecordSource.SYSTEM}>系统自动抄表</Radio>
                  <Radio value={MeterReadingRecordSource.MANUAL}>人工录入</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* 人工录入 */}
            {meterReadingRecordSource === MeterReadingRecordSource.MANUAL && (
              <Col span={24}>
                <Form.Item label="录入日期" name="inputTime" rules={[{ required: true, message: '请选择录入日期' }]}>
                  <Checkbox.Group>
                    <Checkbox value={'1'}>期初值</Checkbox>
                    <Checkbox value={'2'}>期末值</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <>
                  <Tabs items={tabItems.filter(i => (inputTime ?? []).includes(i.key))} />
                </>
              </Col>
            )}
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary">
            生成
          </Button>
          <Button
            onClick={() => {
              navigate('/operation/settlements/energyAssetsBill');
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
      <Modal size="small" open={creatingVisible} footer={null} closable={false} maskClosable={false} keyboard={false}>
        <Result
          icon={<Spin size="large" />}
          title={<span style={{ fontSize: 16 }}>正在生成中...</span>}
          subTitle={<span style={{ fontSize: 12 }}>请勿关闭本页。</span>}
        />
      </Modal>
      <Modal size="small" open={successVisible} footer={null} closable={false} maskClosable={false} keyboard={false}>
        <Result
          icon={
            <CheckCircleFilled
              style={{
                fontSize: 48,
                color: 'var(--success-color)',
              }}
            />
          }
          title={<span style={{ fontSize: 16 }}>生成成功！</span>}
          extra={
            <Button type="primary">
              <Link to={'/operation/settlements/energyAssetsBill'}>返回结算单列表</Link>
            </Button>
          }
        />
      </Modal>
      <Modal size="small" open={warningOpen} footer={null} closable={false} maskClosable={false} keyboard={false}>
        <Result
          icon={<ExclamationCircleFilled style={{ fontSize: 48, color: 'var(--warning-color)' }} />}
          title={<p style={{ fontSize: 16 }}>{warningTitle}</p>}
          extra={
            <Space>
              <Button
                onClick={() => {
                  setWarningOpen(false);
                }}
              >
                关闭
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                重试
              </Button>
            </Space>
          }
        />
      </Modal>
    </Wrapper>
  );
};

export default CreateEnergyAssetsBill;
