import {
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useUpdate,
  useAsync,
  Input,
  Select,
  Modal,
  Table,
  CustomFilter,
  useSearchParams,
  Form,
} from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOuListOwned } from '@/api/ou';
import styles from './index.module.scss';
import { CircuitType, CircuitTypeFormat, deleteCircuitRecord, exportCircuitList } from '@/api/circuit';
import BatchUploadCreateCircuit from './components/BatchUploadCreateCircuit';
import LogListModal from './components/LogListModal';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { useRequest } from 'ahooks';
import {
  V2CircuitPagePostResponse,
  apiV2CircuitLabelAllPost,
  apiV2CircuitPagePost,
} from '@maxtropy/device-customer-apis-v2';
import LightHeightOption from './components/LightHeightOption';
import { PlusOutlined } from '@ant-design/icons';

interface SearchParams {
  codeOrName?: string;
  type?: number;
  ouIds?: number[];
  labelId?: number[];
}

const circuitTypeOptions = [
  {
    label: CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT],
    value: CircuitType.GRID_BILLING_CIRCUIT,
  },
  {
    label: CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT],
    value: CircuitType.INCOMING_CIRCUIT,
  },
  {
    label: CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT],
    value: CircuitType.OUTGOING_CIRCUIT,
  },
  {
    label: CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT],
    value: CircuitType.BUS_COUPLER_CIRCUIT,
  },
  {
    label: CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT],
    value: CircuitType.TRANSFORMER_CIRCUIT,
  },
];

const { Option } = Select;

export type CircuitItem = Exclude<V2CircuitPagePostResponse['list'], undefined>[number];

const Circuit: FC = () => {
  const routesContext = useBreadcrumbRoutes();
  const hasCreatePermission = useHasPermission(PermissionsType.B_CIRCUITDCCREATE); // 创建回路权限
  const hasEditPermission = useHasPermission(PermissionsType.B_CIRCUITDCEDIT); // 编辑回路权限
  const hasDelPermission = useHasPermission(PermissionsType.B_CIRCUITDCDELETE); // 编辑回路权限
  const hasBatchCreatePermission = useHasPermission(PermissionsType.B_CIRCUITDCCREATEBATCH); // 批量新建回路权限
  const hasCircuitConfigPermission = useHasPermission(PermissionsType.B_CIRCUITDCCONFIG); // 回路指标权限
  // const hasCircuitTopoPermission = useHasPermission(PermissionsType.B_CIRCUITDCTOPOLOGY); // 回路拓扑权限
  const hasCircuitDetailPermission = useHasPermission(PermissionsType.B_CIRCUITDCDETAIL); // 查看回路权限
  const hasCircuitOutPutPermission = useHasPermission(PermissionsType.B_CIRCUITDCOUTPUT); // 导出回路权限
  const [form] = Form.useForm();
  const [refreshState, refresh] = useUpdate();
  const [logVisible, setLogVisible] = useState<boolean>(false); // 操作日志列表弹框显示/隐藏
  const [batchCreateCircuitVisible, setBatchCreateCircuitVisible] = useState<boolean>(false); // 批量新建回路
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false); // 加载状态

  const [circuitList, setCircuitList] = useState<CircuitItem[]>([]);

  const ouList = useAsync(getOuListOwned, []);

  const { data: labelList } = useRequest(apiV2CircuitLabelAllPost);

  const columns = [
    {
      title: '回路编号',
      dataIndex: 'code',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '回路名称',
      dataIndex: 'name',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string, record: any) =>
        hasCircuitDetailPermission ? (
          <Button type="link" style={{ padding: 0, fontSize: '12px' }}>
            <Link to={`/dc/circuit/circuitManage/detail/${record.id}`} target="_blank">
              <EllipsisSpan value={v} />
            </Link>
          </Button>
        ) : (
          <div style={{ fontSize: '12px' }}>
            <EllipsisSpan value={v} />
          </div>
        ),
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: CircuitType, record: any) => {
        return <EllipsisSpan value={CircuitTypeFormat[v]} />;
      },
    },
    {
      title: '回路标签',
      dataIndex: 'labelName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string[], record: any) => {
        return <EllipsisSpan value={v?.toString()} />;
      },
    },
    {
      title: '所属运营单元',
      dataIndex: 'ouName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
    },
  ];

  useEffect(() => {
    setLoading(true);
    apiV2CircuitPagePost({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTotalCount(res.total!);
        setCircuitList(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, refreshState]);

  const [ouSearchValue, setOuSearchValue] = useState<string>();
  const [labelSearchValue, setLabelSearchValue] = useState<string>();
  const [ouOptions, setOuOptions] = useState<{ label?: any; value?: number }[]>([]);
  const [labelOptions, setLabelOptions] = useState<{ label?: any; value?: number }[]>([]);

  useEffect(() => {
    if (ouList && ouList.length !== 0) {
      setOuOptions(ouList.map((i: { name: string; id: number }) => ({ label: i.name, value: i.id })));
    }
  }, [ouList]);
  useEffect(() => {
    if (labelList?.list && labelList.list?.length !== 0) {
      setLabelOptions(labelList?.list.map(i => ({ label: i.name, value: i.id })));
    }
  }, [labelList]);

  const filters = (
    <CustomFilter colSpan={6} form={form} onFinish={v => finish(v as SearchParams)} onReset={() => reset()}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input className={styles.search} allowClear placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item name="type" label="回路类型">
        <Select placeholder="请选择" options={circuitTypeOptions} allowClear />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          placeholder="请选择"
          allowClear
          mode="multiple"
          showSearch
          onSearch={(value: string) => setOuSearchValue(value)}
          filterOption={(input: string, option: any) =>
            (option?.children && option?.children?.props?.text.toLowerCase().indexOf(input.toLowerCase())) >= 0
          }
        >
          {(ouOptions ?? []).map((option, index) => (
            <Option key={`${option.value}+${index}`} value={option.value}>
              <LightHeightOption text={option.label} filterTxt={ouSearchValue} />
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="labelId" label="回路标签">
        <Select
          placeholder="请选择"
          allowClear
          mode="multiple"
          showSearch
          onSearch={(value: string) => setLabelSearchValue(value)}
          filterOption={(input: string, option: any) =>
            (option?.children && option?.children?.props?.text.toLowerCase().indexOf(input.toLowerCase())) >= 0
          }
        >
          {(labelOptions ?? []).map((option, index) => (
            <Option key={`${option.value}+${index}`} value={option.value}>
              <LightHeightOption text={option.label} filterTxt={labelSearchValue} />
            </Option>
          ))}
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      title: '确定删除？',
      // content: '删除后不可恢复，你还要继续吗',
      okText: '继续',
      onOk: () => {
        deleteCircuitRecord(id).then(_ => {
          refresh();
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 110,
      render: (record: any) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasDelPermission && (
              <Button type="link" onClick={() => onDeleteRecord(record.id)}>
                删除
              </Button>
            )
          }
          {hasEditPermission && (
            <Button type="link">
              <Link to={`/dc/circuit/circuitManage/edit/${record.id}`}>编辑</Link>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // 导出回路列表
  const exportBtn = () => {
    console.log('999', searchParams);
    window.open(
      exportCircuitList({
        codeOrName: searchParams?.codeOrName,
        ouIds: searchParams?.ouIds,
        type: searchParams?.type,
      }),
      '_blank'
    );
  };

  return (
    <Wrapper routes={routesContext?.routes} filters={filters} className="page_wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <Space size={8}>
          {hasCreatePermission && (
            <Link to={`/dc/circuit/circuitManage/create`}>
              <Button type="primary" icon={<PlusOutlined />}>
                新建回路
              </Button>
            </Link>
          )}
          {hasBatchCreatePermission && (
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setBatchCreateCircuitVisible(true)}>
              批量新建回路
            </Button>
          )}
          <Button type="primary" onClick={() => setLogVisible(true)}>
            批量操作日志
          </Button>
          {hasCircuitOutPutPermission && (
            <Button type="primary" onClick={exportBtn}>
              导出回路
            </Button>
          )}
        </Space>
        <Space size={8}>
          {hasCircuitConfigPermission && (
            <Button
              type={'primary'}
              onClick={() => window.open(`${window.DMEPLATFORMSORIGIN}/dmes/circuit/circuit`, '_blank')}
            >
              回路指标绑定
            </Button>
          )}
          {/* {hasCircuitTopoPermission && (
              <Button
                type={'primary'}
                onClick={() =>
                  window.open(`${window.DMEPLATFORMSORIGIN}/dmes/circuit/circuit/topologicalGraph`, '_blank')
                }
              >
                回路拓扑图
              </Button>
            )} */}
        </Space>
      </div>
      {/* 批量新建回路 */}
      <BatchUploadCreateCircuit
        refresh={refresh}
        batchCreateCircuitVisible={batchCreateCircuitVisible}
        setBatchCreateCircuitVisible={(visible: boolean) => {
          setBatchCreateCircuitVisible(visible);
        }}
      />
      {/* 批量操作日志 */}
      <LogListModal
        visible={logVisible}
        setVisible={(visible: boolean) => {
          setLogVisible(visible);
        }}
      />

      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={circuitList}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default Circuit;
