import { InfoCircleOutlined } from '@ant-design/icons';
import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Checkbox,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSaleClient, ChargeTypeEnum, ChargeTypeEnumDisplay, SaleClientInfo } from '../../../api/sale';
import dayjs from 'dayjs';
import ClientContact from '../components/ClientContact';
import styles from './index.module.scss';
import Cookies from 'js-cookie';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: `查看客户` }];

const formatString = 'YYYY-MM-DD';

const DetailClient: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [clientData, setClientData] = useState<SaleClientInfo>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getSaleClient(id).then(setClientData);
    }
  }, [id]);

  useEffect(() => {
    if (clientData) {
      form.setFieldsValue({
        code: clientData.code,
        name: clientData.name,
        ouName: clientData.ouName,
        companyName: clientData.companyName,
        companyAddress: clientData.companyAddress,
        uscc: clientData.uscc,
        meterTypes: clientData.meterTypes,
        meterTypeName: clientData.meterTypes.map(i => ChargeTypeEnumDisplay[i]).join('、'),
        electricityAccountNumbers: clientData.electricityAccounts.map(i => i.number).join('、'),
        thermalAccountNumbers: clientData.thermalAccounts.map(i => i.number).join('、'),
        importantLevelName: clientData.importantLevelName,
        contacts: clientData.contacts,
        averagePriceAuto: clientData.averagePriceAuto, //度电均价系统生成
        averagePriceManual: clientData.averagePriceManual, // 度电均价手工确认
        isManual: clientData.isManual, // 是否手工确认
        averagePriceStartDate: clientData.averagePriceStartDate, // 均价开始日期
        averagePriceEndDate: clientData.averagePriceEndDate, // 均价结束日期
        beneficialBank: clientData.beneficialBank,
        bankAccount: clientData.bankAccount,
      });
    }
  }, [clientData, form]);

  const goList = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/operation/client-related/client');
    }
  };

  console.log(Cookies.get('siteTheme'));

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form layout="vertical" form={form} {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent>
          <Row>
            <Col span={8}>
              <Form.Item name="code" label="客户编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="客户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouName" label="所属运营单元">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="companyName" label="企业名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="companyAddress" label="企业地址">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="uscc" label="纳税号">
                <ShowInput />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="beneficialBank" label="开户行">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="bankAccount" label="账号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="importantLevelName" label="客户重要等级">
                <ShowInput />
              </Form.Item>
            </Col>
            <Form.Item hidden name="meterType" label="收费类型">
              <Checkbox.Group>
                <Checkbox value={ChargeTypeEnum.POWER}>{ChargeTypeEnumDisplay[ChargeTypeEnum.POWER]}</Checkbox>
                <Checkbox value={ChargeTypeEnum.THERMAL}>{ChargeTypeEnumDisplay[ChargeTypeEnum.THERMAL]}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Col span={8}>
              <Form.Item name="meterTypeName" label="收费类型">
                <ShowInput />
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.POWER) && (
                    <Col span={8}>
                      <Form.Item name="electricityAccountNumbers" label="用电账户">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.THERMAL) && (
                    <Col span={8}>
                      <Form.Item name="thermalAccountNumbers" label="用汽账户">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.POWER) && (
                    <>
                      <Col span={24}>
                        <div style={{ marginBottom: '36px' }}>
                          <h3 style={{ display: 'inline-block', marginRight: '12px', fontSize: '14px' }}>度电均价</h3>
                          <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                          <span style={{ color: 'var(--mx-text-desc-color)' }}>
                            度电均价根据核查联每日更新，未生成核查联的用户度电均价为空。
                            如手动输入，生效期间内以人工确认为准。如客户核查联26日生成，手动填写建议截止到28日
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: '36px' }}>
                          <h3 style={{ fontSize: '14px' }}>系统计算：</h3>
                          <span style={{ color: 'var(--mx-text-desc-color)' }}>
                            {clientData?.averagePriceAuto
                              ? clientData?.averagePriceAuto + '元'
                              : '因未查询到核查联，建议手工输入度电均价'}
                          </span>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div style={{ marginBottom: '36px' }}>
                          <h3 style={{ fontSize: '14px' }}>手工确认：</h3>
                          <span style={{ color: 'var(--mx-text-desc-color)' }}>
                            {clientData?.isManual
                              ? clientData?.averagePriceManual
                                ? `${clientData?.averagePriceManual}元（生效时间：${dayjs(
                                    clientData.averagePriceStartDate
                                  ).format(formatString)}至${dayjs(clientData.averagePriceEndDate).format(
                                    formatString
                                  )}）`
                                : '--'
                              : '因未查询到核查联，建议手动输入度电均价'}
                          </span>
                        </div>
                      </Col>
                    </>
                  )
                );
              }}
            </Form.Item>
            <Col span={24}>
              <Form.Item name="contacts" wrapperCol={{ span: 24 }} label="客户联系人信息">
                <ClientContact disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer">
          <Button onClick={goList}>返回</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default DetailClient;
