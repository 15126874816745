import { Button, Empty, DatePicker, message, FormTitle } from '@maxtropy/components';
import { Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import water from '../imgs/water.png';
import steam from '../imgs/steam.png';
import IndustrialLiquid from '../imgs/IndustrialLiquid.png';
import IndustrialGas from '../imgs/IndustrialGas.png';
import gas from '../imgs/gas.png';
import electricity from '../imgs/electricity.png';
import compressedAir from '../imgs/compressedAir.png';
import AC from '../imgs/AC.png';
import EchartsGroup from '../EchartsGroup';
import { EnergyMediumSceneIds } from '@/api/energyMedium';
import { isNil } from 'lodash-es';
import {
  apiV2EnergyCompareGetMediumIndicatorProcessStatisticsPost,
  apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost,
  V2EnergyCompareGetMediumIndicatorProcessStatisticsPostResponse,
  V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

export interface IProcessingEfficiencyProps {
  processId?: React.Key;
  selectedTopoSceneId?: number;
  selectedProcessName?: string;
  topoId?: number;
}

export const ImgEnumDisplay = {
  [EnergyMediumSceneIds.WATER_SUPPLY_AND_DRAINAGE]: water,
  [EnergyMediumSceneIds.GAS_SUPPLY_AND_CONSUMPTION]: gas,
  [EnergyMediumSceneIds.HOT_STEAM]: steam,
  [EnergyMediumSceneIds.COMPRESSED_AIR]: compressedAir,
  [EnergyMediumSceneIds.INDUSTRIAL_GASES]: IndustrialGas,
  [EnergyMediumSceneIds.HEATING_VENTILATION_AND_AIR_CONDITIONING]: AC,
  [EnergyMediumSceneIds.INDUSTRIAL_LIQUIDS]: IndustrialLiquid,
  [EnergyMediumSceneIds.ELEC]: electricity,
};

// 场景id枚举
export enum EnergyMediumSceneId {
  WATER_SUPPLY_AND_DRAINAGE = 500, // 给排水/用气
  GAS_SUPPLY_AND_CONSUMPTION = 501, // 供气/用气
  HOT_STEAM = 502, // 热蒸汽
  COMPRESSED_AIR = 503, // 压缩空气
  INDUSTRIAL_GASES = 504, // 工业气体
  HEATING_VENTILATION_AND_AIR_CONDITIONING = 505, // 供暖通风与空气调节
  INDUSTRIAL_LIQUIDS = 506, // 工业液体
}
export const UrlEnumDisplay = {
  [EnergyMediumSceneId.WATER_SUPPLY_AND_DRAINAGE]: '/energyAssets/energyMediumDashboard/water',
  [EnergyMediumSceneId.GAS_SUPPLY_AND_CONSUMPTION]: '/energyAssets/energyMediumDashboard/gas',
  [EnergyMediumSceneId.HOT_STEAM]: '/energyAssets/energyMediumDashboard/hotSteam',
  [EnergyMediumSceneId.COMPRESSED_AIR]: '/energyAssets/energyMediumDashboard/compressedAir',
  [EnergyMediumSceneId.INDUSTRIAL_GASES]: '/energyAssets/energyMediumDashboard/industrialGas',
  [EnergyMediumSceneId.HEATING_VENTILATION_AND_AIR_CONDITIONING]:
    '/energyAssets/energyMediumDashboard/heatingVentilationAndAirConditioning',
  [EnergyMediumSceneId.INDUSTRIAL_LIQUIDS]: '/energyAssets/energyMediumDashboard/industrialLiquids',
};

const uetUnitType = 5; // 拓扑类型

const ProcessingEfficiency: FC<IProcessingEfficiencyProps> = props => {
  const { processId, selectedTopoSceneId, selectedProcessName, topoId } = props;
  const [processStatistics, setProcessStatistics] =
    useState<V2EnergyCompareGetMediumIndicatorProcessStatisticsPostResponse['mediumIndicatorProcessCardVos']>();
  const [statisticsDataLoading, setStatisticsDataLoading] = useState<boolean>(false);
  const [mediumIndicatorDisplayUnitData, setMediumIndicatorDisplayUnitData] =
    useState<V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse['list']>();
  const [rangeDate, setRangeDate] = useState<[Dayjs, Dayjs]>([
    dayjs(dayjs(), 'x').subtract(30, 'days').startOf('day'),
    dayjs(dayjs(), 'x').endOf('day'),
  ]);

  // 单位
  useEffect(() => {
    if (!topoId) return;
    apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
      uetUnitType: uetUnitType,
      uetUnitId: topoId,
    }).then(res => {
      setMediumIndicatorDisplayUnitData(res.list);
    });
  }, [topoId]);

  useEffect(() => {
    if (processId) {
      setStatisticsDataLoading(true);
      apiV2EnergyCompareGetMediumIndicatorProcessStatisticsPost({
        processId: Number(processId),
        timeResolution: 'D',
        from: rangeDate[0].startOf('day').valueOf(),
        to: rangeDate[1].endOf('day').valueOf(),
      }).then(res => {
        setProcessStatistics(res.mediumIndicatorProcessCardVos ?? []);
        setStatisticsDataLoading(false);
      });
    }
  }, [processId, rangeDate]);

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
      const mostDays = 31;
      if (diff >= mostDays) {
        return message.warning(`最大上限为${mostDays}天`);
      }
      setRangeDate([dayjs(beginDate), dayjs(endDate)]);
    }
  };

  const unitDisplay = useCallback(
    (indicatorId?: number) => {
      return (mediumIndicatorDisplayUnitData ?? []).find(i => i.energyMediumIndicatorId === indicatorId)
        ?.displayPhysicalUnitGeneralName;
    },
    [mediumIndicatorDisplayUnitData]
  );

  const navigateToEnergyMediumDashboard = () => {
    const jumpUrl = UrlEnumDisplay[selectedTopoSceneId as EnergyMediumSceneId];
    window.open(
      `${jumpUrl}?processId=${processId}&timeResolution=D&from=${rangeDate[0]
        .startOf('day')
        .valueOf()}&to=${rangeDate[1].endOf('day').valueOf()}`
    );
  };

  return (
    <>
      <FormTitle
        title="处理节点效率"
        style={{ padding: '0px 20px', marginBottom: 18 }}
        extraContent={
          <Button type="primary" onClick={() => navigateToEnergyMediumDashboard()}>
            详情数据
          </Button>
        }
      ></FormTitle>
      <div style={{ padding: '0px 20px' }}>
        <div className={styles.nameAndTimeSty}>
          <div className={styles.currentSelectedName}>
            <span style={{ color: '#ffffff65' }}>当前选择：</span>
            <span className={styles.nameTextStyle} title={selectedProcessName ?? '--'}>
              {selectedProcessName ?? '--'}
            </span>
          </div>
          <div>
            <span>时间：</span>
            <RangePicker
              value={rangeDate}
              disabledDate={disabledDate}
              onChange={onChangeRangePicker}
              picker={'date'}
              allowClear={false}
            />
          </div>
        </div>
        <div style={{ height: 'calc(80vh - 100px)', overflowY: 'auto' }}>
          {processStatistics && processStatistics.length > 0 ? (
            processStatistics.map((item, index) => (
              <div key={index}>
                <div className={styles.cardContent}>
                  <div className={styles.mainCardContent}>
                    <div>
                      <img
                        src={ImgEnumDisplay[item.outputVo?.sceneId as EnergyMediumSceneIds]}
                        className={styles.mainCardImg}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className={styles.mainCardTitle}>
                        {item.outputVo?.energyMediumName ?? '--'}（{unitDisplay(item.outputVo?.indicatorId)}）
                      </span>
                      <span className={styles.mainCardValue}>
                        {!isNil(item.outputVo?.value) ? item.outputVo?.value.toFixed(2) : '--'}
                      </span>
                    </div>
                  </div>
                  <div className={styles.restCardContent}>
                    <div className={styles.restCardContentTitle}>
                      单位{item.outputVo?.energyMediumName ?? '--'}消耗能源介质
                    </div>
                    <div className={styles.allresCardContent}>
                      {item.inputVos?.map(i => (
                        <div
                          key={i.energyMediumId! + i.energyMediumName! + i.indicatorId}
                          className={styles.restCardContentValue}
                        >
                          <div className={styles.restCardContentValueTitle}>
                            {i.energyMediumName}（{unitDisplay(i.indicatorId)}）
                          </div>
                          <div className={styles.restCardContentValuevalue}>
                            {!isNil(i.value) ? i.value.toFixed(2) : '--'}
                          </div>
                          {!isNil(i.lossAmount) && (
                            <span className={styles.restCardContentMoney}>{i.lossAmount.toFixed(2)}元</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <EchartsGroup
                  item={item}
                  processId={processId}
                  rangeDate={rangeDate}
                  mediumIndicatorDisplayUnitData={mediumIndicatorDisplayUnitData}
                  outPutIndicatorId={item.outputVo?.indicatorId}
                />
              </div>
            ))
          ) : (
            <Spin spinning={statisticsDataLoading}>
              <Empty style={{ marginTop: '20vh' }} />
            </Spin>
          )}
        </div>
      </div>
    </>
  );
};

export default ProcessingEfficiency;
