import { EllipsisSpan, Table } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getElectricityLightingQuantityResponse,
  LightingQuantityItem,
  QuantityTypeEnum,
} from '../../../../api/electricity';
import { units } from '../utils';

const columns = [
  {
    title: '',
    dataIndex: 'firstCol',
    ellipsis: { showTitle: true },
    render: () => <EllipsisSpan value={'平'} />,
    width: 70,
    // className: `${styles.firstCell}`,
  },
  {
    title: '有功总电量',
    dataIndex: 'totalActivePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '定比',
    dataIndex: 'fixedRatio',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v}%` : v} />,
  },
  {
    title: '照明总电量',
    dataIndex: 'totalLightingPowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '照明单价',
    dataIndex: 'unitPowerRate',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(8)} ${units['元/千瓦时']}` : v} />
    ),
  },
  {
    title: '照明电费',
    dataIndex: 'totalLightingElectricFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
  {
    title: '力调费',
    dataIndex: 'powerFactorFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
];

const photoColumns = columns.slice(0, 6);

const ElectricityBillLightingPower: FC<{ quantityType: QuantityTypeEnum }> = ({ quantityType }) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<LightingQuantityItem[]>([]);

  useEffect(() => {
    if (id) {
      getElectricityLightingQuantityResponse(id).then(res => {
        if (res.list.length > 0) {
          const data = res.list.filter(i => i.quantityTypeEnum === quantityType);
          setData(data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Table
        rowKey="id"
        pagination={false}
        columns={quantityType === QuantityTypeEnum.QUANTITY ? columns : photoColumns}
        dataSource={data}
      />
    </>
  );
};

export default ElectricityBillLightingPower;
