import { CircuitProps, getCircuitList } from '@/api/circuit';
import { checkBindCircuit, NetInfoProp } from '@/api/pv';
import { Form, Modal, Select } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PvStationContext } from './PvStation';
interface Iprops {
  row?: NetInfoProp;
  onConfirm?: (circuitInfo?: CircuitProps) => void;
  onCancel?: () => void;
  selectedIds?: number[];
}
const CircuitBindModal = (props: Iprops) => {
  const { ou, updateCircuitEmit } = useContext(PvStationContext);
  const { data: list } = useRequest(() => getCircuitList({ ouId: ou?.id }), {
    ready: !!ou?.id,
    refreshDeps: [ou?.id],
  });
  // 排除自身
  const hasSelectedIds = useMemo(() => {
    return props.selectedIds?.filter(id => id !== props.row?.circuitId);
  }, [props.selectedIds, props.row]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const circuitId = Form.useWatch('circuitId', form);
  useEffect(() => {
    if (props.row?.circuitId) {
      form.setFieldsValue({
        circuitId: props.row?.circuitId,
      });
    }
  }, [props.row]);
  const currentCircuitInfo = useMemo(() => {
    return list?.find(item => item.id === circuitId);
  }, [circuitId, list]);

  const check = () => {
    if (!circuitId) {
      props.onConfirm?.(currentCircuitInfo);
      return;
    }
    if (hasSelectedIds?.includes(+circuitId)) {
      form.setFields([{ name: 'circuitId', errors: ['回路重复, 请重新选择'] }]);
      return;
    }
    setLoading(true);
    checkBindCircuit({
      circuitId,
      gridConnectPointId: props.row?.id,
    })
      .then(_ => {
        props.onConfirm?.(currentCircuitInfo);
        updateCircuitEmit?.();
      })
      .finally(() => setLoading(false));
  };
  return (
    <Modal
      title="关联回路"
      open
      contentClassName="modal-form-content"
      onCancel={() => props.onCancel?.()}
      confirmLoading={loading}
      onOk={() => check()}
    >
      <Form form={form}>
        <Form.Item label="回路" name="circuitId">
          <Select allowClear options={list?.map(item => ({ label: item.name, value: item.id }))} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CircuitBindModal;
