import { Form, Col, Row } from 'antd';
import {
  MainDataSourcesTypeMap,
  MainDataSources,
  BoundaryType,
  BoundaryTypeMap,
} from '../../../ProductCarbonFootprint/NewModel/utils';
import IMGCarbonFootprint from '../../assets/carbonFootprint.svg';
import styles from './index.module.scss';
import { FirstDataType } from '../AddFilling/const';
import BarEcharts from '../BarEcharts';
import {
  V2CarbonFootprintCalculateDetailPostResponse,
  apiV2CarbonFootprintCalculateLifeCycleCarbonPost,
} from '@maxtropy/device-customer-apis-v2';
import { useEffect, useState } from 'react';
import { ShowInput } from '@maxtropy/components';

interface Props {
  calculateData?: any;
  lcaData?: V2CarbonFootprintCalculateDetailPostResponse;
  firstStepData?: FirstDataType;
  id?: number;
}
const LCAView: React.FC<Props> = ({ lcaData, firstStepData, id, calculateData }) => {
  const [carbonData, setCarbonData] = useState<any>();
  useEffect(() => {
    apiV2CarbonFootprintCalculateLifeCycleCarbonPost({ id }).then(res => {
      const data = res?.list?.map(item => Number(item.carbonFootPrint).toFixed(2) || 0).reverse();
      setCarbonData(data ?? []);
    });
  }, [id]);

  return (
    <div className={styles.content}>
      <div className={styles.productonTitle}>产品信息</div>
      <Row>
        <Col span={8}>
          <Form.Item label="产品编码">
            <ShowInput value={lcaData?.code} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="产品名称">
            <ShowInput value={lcaData?.name} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="规格型号">
            <ShowInput value={lcaData?.spec} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="生产单号">
            <ShowInput value={firstStepData?.productSheetCode} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="生产数量">
            <ShowInput value={`${firstStepData?.quantity}${lcaData?.unitCode ?? '--'}`} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="生产时间">
            <ShowInput
              value={`${firstStepData?.productionStartTime.format(
                'YYYY-MM-DD  HH:mm:ss'
              )}～${firstStepData?.productionEndTime.format('YYYY-MM-DD  HH:mm:ss')}`}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className={styles.activityContent}>
        <div className={styles.productonTitle}>数据填报</div>
        <Row>
          <Col span={8}>
            <Form.Item label="核算边界">
              <ShowInput value={BoundaryTypeMap[lcaData?.boundary as BoundaryType]} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="主要数据来源">
              <ShowInput value={MainDataSourcesTypeMap[lcaData?.dataOrigin as MainDataSources]}></ShowInput>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="基准年">
              <ShowInput value={lcaData?.standardYear}></ShowInput>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="产品产地">
              <ShowInput value={lcaData?.madeIn}></ShowInput>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="补充说明">
              <ShowInput value={lcaData?.remark}></ShowInput>
            </Form.Item>
          </Col>
        </Row>
        <>
          <div className={styles.analysisTitle}>生命周期影响分析</div>
          <div className={styles.GwpTitle}>
            通过建模计算生产时间为：
            <span className={styles.blueSpan}>{`${firstStepData?.productionStartTime.format(
              'YYYY-MM-DD  HH:mm:ss'
            )}～${firstStepData?.productionEndTime.format('YYYY-MM-DD  HH:mm:ss')}`}</span>
            生产的，生产单号为：
            <span className={styles.blueSpan}>{firstStepData?.productSheetCode}</span> 的
            <span className={styles.blueSpan}>
              1 {lcaData?.unitCode ?? '--'} {lcaData?.name ?? '--'}
            </span>
            的产品碳足迹，计算指标为气候变化
            <span className={styles.blueSpan}>（GWP）</span>，结果如下：
          </div>
          <div className={styles.Gwp}>
            <div className={styles.GwpFirst}>
              <img src={IMGCarbonFootprint} alt="" className={styles.picCarbonFoot} />
              <div className={styles.carbonFootTitle}>产品碳足迹</div>
              <div className={styles.carbonFootNum}>{calculateData?.unitCarbonFootPrint ?? '--'}</div>
              <div className={styles.right_Line}></div>
            </div>
            <div className={styles.GwpSecond}>
              <div className={styles.unitTitle}>影响类型指标单位</div>
              <div className={styles.unit}>kg CO2</div>
              <div className={styles.right_Line}></div>
            </div>
            <div className={styles.GwpThird}>
              <div className={styles.typeTitle}>环境影响类型指标</div>
              <div className={styles.type}>GWP</div>
            </div>
          </div>
        </>
        <div>
          <div className={styles.productonTitle}>
            <BarEcharts carbonData={carbonData}></BarEcharts>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LCAView;
