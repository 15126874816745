import { BaseInfoContent, DatePicker, Form, Modal, Radio, Select, ShowInput, Tooltip } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2HvacNodeIndicatorConfigDataPropertyListPost,
  apiV2HvacNodeIndicatorConfigUpdatePost,
  V2HvacNodeIndicatorConfigListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Col, Row, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { DataPropertyType, DataPropertyTypeDisplay } from '@/shared/types';

enum EffectTimeType {
  IMMEDIATE,
  SPECIFIED,
}

const timeFormat = 'YYYY-MM-DD HH:mm:ss';

interface Props {
  open: boolean;
  onClose: (needUpdate?: boolean) => void;
  data: Exclude<V2HvacNodeIndicatorConfigListPostResponse['list'], undefined>[number] | undefined;
}

const EditModal: FC<Props> = props => {
  const { open, onClose, data } = props;
  const [form] = Form.useForm();
  const [effectTimeType, setEffectTimeType] = useState<EffectTimeType>(EffectTimeType.SPECIFIED);
  const [specifyTime, setSpecifyTime] = useState<Dayjs>();
  const [loading, setLoading] = useState(false);

  const { data: dataPropertyList } = useRequest(
    async () => {
      const res = await apiV2HvacNodeIndicatorConfigDataPropertyListPost({
        linkId: data?.linkId,
      });
      return res.list ?? [];
    },
    {
      refreshDeps: [data?.linkId],
      ready: !!data?.linkId,
    }
  );

  useEffect(() => {
    if (!data) {
      form.resetFields();
      return;
    }
    form.setFieldsValue(data);
    setEffectTimeType(EffectTimeType.SPECIFIED);
    setSpecifyTime(dayjs(data.effectiveTime));
  }, [data]);

  const onConfirm = () => {
    form.validateFields().then((values: { dataPropertyId: number | undefined; effectiveTime: string }) => {
      setLoading(true);
      apiV2HvacNodeIndicatorConfigUpdatePost({
        linkId: data?.linkId,
        dataPropertyId: values.dataPropertyId,
        effectiveTime:
          effectTimeType === EffectTimeType.IMMEDIATE
            ? dayjs().format(timeFormat)
            : dayjs(specifyTime).format(timeFormat),
      })
        .then(() => onClose(true))
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      title="编辑指标"
      size="large"
      onOk={onConfirm}
      confirmLoading={loading}
      contentClassName="modal-form-content"
    >
      <Form form={form}>
        <BaseInfoContent layout="horizontal" className={styles.topLine}>
          <Form.Item label="指标名称：" name="name">
            <ShowInput />
          </Form.Item>
          <Form.Item label="指标类型：">
            <ShowInput value={DataPropertyTypeDisplay[data?.dataPropertyType as DataPropertyType]} />
          </Form.Item>
          <Form.Item label="设备名称：" name="deviceName">
            <ShowInput />
          </Form.Item>
        </BaseInfoContent>
        <Row>
          <Col span={24}>
            <Form.Item
              name="dataPropertyId"
              label={
                <span>
                  数据属性
                  <Tooltip title="可选择符合指标类型和物理量的数据属性。">
                    <InfoCircleOutlined
                      style={{
                        margin: '0 5px',
                        color: 'var(--warning-color)',
                      }}
                    />
                  </Tooltip>
                </span>
              }
            >
              <Select
                allowClear
                placeholder="请选择"
                options={dataPropertyList?.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
              ></Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  生效时间
                  <Tooltip title="选择指定时间生效, 从该日期起监控指标数据将从新的设备/数据属性查询新的数据。">
                    <InfoCircleOutlined
                      style={{
                        margin: '0 5px',
                        color: 'var(--warning-color)',
                      }}
                    />
                  </Tooltip>
                </span>
              }
            >
              <Radio.Group value={effectTimeType} onChange={e => setEffectTimeType(e.target.value)}>
                <Space direction="vertical" size={8}>
                  <Radio value={EffectTimeType.IMMEDIATE}>立即生效</Radio>
                  <Radio value={EffectTimeType.SPECIFIED}>
                    从指定时间生效
                    {effectTimeType === EffectTimeType.SPECIFIED && (
                      <DatePicker
                        showTime
                        style={{ marginLeft: 15 }}
                        value={specifyTime}
                        onChange={setSpecifyTime}
                      ></DatePicker>
                    )}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditModal;
