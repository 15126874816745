import CostImg from './images/cost.png';
import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {}

const EnergyCost: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapperContent} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <img style={{ width: '100%' }} src={CostImg} alt="" />
    </Wrapper>
  );
};

export default EnergyCost;
