import * as React from 'react';
import { Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Button, Paging, Table, Input, usePaging, CustomFilter } from '@maxtropy/components';
import { Form } from 'antd';
import {
  EdgeTemplate,
  EdgeTemplatePageRequest,
  EdgeTemplateResponse,
  getEdgeTemplatePage,
} from '../../../api/template';
import { useQuery } from '../../../utils/utils';
import styles from '../index.module.scss';
import { DataAcquisitionFormProps } from '../index';
import { IotProtocolType } from '@/shared/types';

function formatListData(response: EdgeTemplateResponse) {
  return response;
}

interface FilterParams {
  name: string | undefined;
}

export interface TemplateModalRef {
  selectedRowKeys: number | undefined;
}

type SearchParams = Omit<EdgeTemplatePageRequest, 'page' | 'size'>;

interface TemplateModalProps extends DataAcquisitionFormProps {}

const TemplateModal = React.forwardRef(({ state }: TemplateModalProps, ref: Ref<TemplateModalRef>) => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { deviceType: deviceTypeArray, templateVersionId, iotProtocolType, driveType, objectModalType } = state;
  const deviceType = deviceTypeArray?.slice(-1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number | undefined>(templateVersionId);
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };
  const onFinish = (val: FilterParams) => {
    setSearchParams({ ...val, deviceType });
    setPageOffset(1);
  };

  useImperativeHandle(ref, () => ({ selectedRowKeys }));

  const onSelect = (record: EdgeTemplate) => {
    setSelectedRowKeys(record.id);
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getEdgeTemplatePage({
          ...searchParams,
          deviceType,
          iotProtocol: iotProtocolType,

          physicalModelId: objectModalType,

          ...(iotProtocolType === IotProtocolType.MOCKINGBIRD ? { driveType } : {}),
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const columns = [
    {
      title: '模板编号',
      dataIndex: 'templateSerialNumber',
    },
    {
      title: '模板名称',
      dataIndex: 'templateName',
    },
    {
      title: '版本号',
      dataIndex: 'version',
    },
    {
      title: '已使用设备数量',
      dataIndex: 'usedByDevice',
    },
    {
      title: '版本备注',
      dataIndex: 'remark',
    },
    {
      title: '操作',
      width: 100,
      render: (record: EdgeTemplate) => {
        return selectedRowKeys === record.id ? (
          <span>已选择</span>
        ) : (
          <Button type="link" onClick={() => onSelect(record)}>
            选择
          </Button>
        );
      },
    },
  ];

  return (
    <div className={styles.modal}>
      <CustomFilter<FilterParams>
        form={form}
        colSpan={8}
        onFinish={val => onFinish(val as FilterParams)}
        onReset={onReset}
      >
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </CustomFilter>
      <Table<EdgeTemplate>
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={data?.list}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
});

export default TemplateModal;
