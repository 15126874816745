// 抄表周期
export enum ReadingPeriod {
  YEAR = 'yearly',
  MONTH = 'monthly',
  WEEK = 'weekly',
  DAY = 'daily',
}

export const ReadingPeriodFormat = {
  [ReadingPeriod.YEAR]: '年',
  [ReadingPeriod.MONTH]: '月',
  [ReadingPeriod.WEEK]: '周',
  [ReadingPeriod.DAY]: '日',
};

export enum ActionType {
  CREATE = 'new',
  UPDATE = 'edit',
  DETAIL = 'detail',
}

export const ActionTypeDisplay = {
  [ActionType.CREATE]: '新增',
  [ActionType.UPDATE]: '编辑',
  [ActionType.DETAIL]: '查看',
};

// 抄表周期
export enum ReadingMeterPeriod {
  YEAR = 1,
  MONTH = 2,
  WEEK = 3,
  DAY = 4,
}

export const ReadingMeterPeriodFormat = {
  [ReadingMeterPeriod.YEAR]: '按年',
  [ReadingMeterPeriod.MONTH]: '按月',
  [ReadingMeterPeriod.WEEK]: '按周',
  [ReadingMeterPeriod.DAY]: '按日',
};

// 费率取值
export enum RateValueType {
  UNIT = 1,
}

export const RateValueTypeFormat = {
  [RateValueType.UNIT]: '按用能单元费率配置',
};

//调整值计费方法

export enum AdjustmentBillingMethodType {
  SEPARATE = 1,
  AUTOMATIC = 2,
}

export const AdjustmentBillingMethodTypeFormat = {
  [AdjustmentBillingMethodType.SEPARATE]: '单独调整费用',
  [AdjustmentBillingMethodType.AUTOMATIC]: '根据调整量自动计算',
};

//分摊值计费方法
export enum AmortizedBillingMethodType {
  SEPARATE = 1,
  AUTOMATIC = 2,
}

export const AmortizedBillingMethodTypeFormat = {
  [AmortizedBillingMethodType.SEPARATE]: '单独分摊费用',
  [AmortizedBillingMethodType.AUTOMATIC]: '根据分摊量自动计算',
};
export interface meterReadingObjectsType {
  id?: number;
  level?: number;
  parentReadingObjectId?: number;
  [key: string]: unknown;
}
export interface accountUnitListType {
  id?: number;
  energyName?: string;
  sceneId?: number;
}

export interface treeToListType {
  energyUnitId: number;
  name: string;
  level: number;
  parentId: number;
}

export interface meterReadingObjsType {
  label?: string;
  value?: number;
}
