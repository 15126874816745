import { EllipsisSpan } from '@maxtropy/components';

export interface loadTransformers {
  level: string;
  sequence: number;
  code: string;
  name: string;
  typeName: string;
  capacity: number;
  targetLoad?: number;
  targetLoadRate?: number;
  totalLoadDeviceId?: number;
  totalLoadDeviceCode?: string;
  totalLoadDeviceName?: string;
  productionLoadDeviceId?: number;
  productionLoadDeviceName?: string;
  productionLoadDeviceCode?: string;
  parentDeviceId?: number;
  rootDeviceId?: number;
  deviceId: number;
  parentInfo?: {
    level: string;
    sequence: number;
  };
  color?: string;
  ouIdOfTotalLoadDevice?: number;
  ouIdOfProductionLoadDevice?: number;
  electricityAccountId?: number;
  electricityAccountNumber?: string;
}

export const columns = (targetLoadColumns: any[]) => [
  {
    title: '父级变压器',
    ellipsis: { showTitle: true },
    render: (v: loadTransformers) => <EllipsisSpan value={`${v.parentInfo?.level}级-${v.parentInfo?.sequence}#`} />,
  },
  {
    title: '网络内编号',
    dataIndex: 'sequence',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${v}#`} />,
  },
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '容量',
    dataIndex: 'capacity',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  ...targetLoadColumns,
  {
    title: '总负载电表设备编号',
    dataIndex: 'totalLoadDeviceCode',
    width: 150,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'totalLoadDeviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生产负载电表设备编号',
    dataIndex: 'productionLoadDeviceCode',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'productionLoadDeviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export const formatTwoLevelColor = (list: loadTransformers[]) => {
  // const colors = ['EDFAFF', 'FFFCEC', 'EFF9F0'];
  const res: loadTransformers[] = [];
  if (list.length > 0) {
    for (let j = 0; j < list.length; j++) {
      // let nextColor;
      // if (res[j - 1]?.color) {
      //   const indexOfNumber = colors.indexOf(res[j - 1].color!) + 1;
      //   nextColor = colors[indexOfNumber - parseInt((indexOfNumber / 3).toString()) * 3];
      // } else {
      //   nextColor = colors[0];
      // }
      // const color = res[j - 1]?.parentInfo!.sequence === list[j].parentInfo!.sequence ? res[j - 1].color : nextColor;
      // res.push({ ...list[j], sequence: j + 1, color: color });
      res.push({ ...list[j], sequence: j + 1 });
    }
  }
  return res;
};

export const formatTwoLevelList = (list: loadTransformers[], data: loadTransformers, rootDeviceId: number) => {
  let newList;
  const twoList = list.filter(i => i.level === '2');
  if (twoList.find(i => i.deviceId === data.deviceId)) {
    newList = twoList.map(i => {
      if (i.deviceId === data.deviceId) return { ...data, rootDeviceId: rootDeviceId };
      return { ...i };
    });
  } else {
    newList = twoList.concat([{ ...data, rootDeviceId: rootDeviceId }]).sort((a, b) => {
      const aSequence = Number(a.parentInfo!.sequence);
      const bSequence = Number(b.parentInfo!.sequence);
      if (aSequence < bSequence) {
        return -1;
      }
      if (aSequence > bSequence) {
        return 1;
      }
      return 0;
    });
  }
  return formatTwoLevelColor(newList);
};
