import { Modal, Select, Form } from '@maxtropy/components';
import {
  apiV2LeanEnergyFlowAnalysisConfigDetailPost,
  apiV2LeanEnergyFlowAnalysisConfigPost,
} from '@maxtropy/device-customer-apis-v2';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import { Key, useEffect, useState } from 'react';

interface LevelConfigModalProps {
  open: boolean;
  energyUnitGroupId?: Key;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
}

const LevelConfigModal: React.FC<LevelConfigModalProps> = props => {
  const { open, energyUnitGroupId, setOpen, updateFn } = props;

  const [form] = Form.useForm();
  const [levelOptions, setLevelOptions] = useState<any>();

  useEffect(() => {
    apiV2LeanEnergyFlowAnalysisConfigDetailPost({ energyGroupId: energyUnitGroupId }).then(res => {
      if (!isNil(res.maxLevel)) {
        const list = Array.from({ length: res.maxLevel ?? 0 }, (_, i) => ({ value: i + 1, label: `${i + 1}级` }));
        form.setFieldsValue({
          fromLevel: res.fromLevel ?? 1,
          toLevel: res.toLevel ?? Math.min(3, res.maxLevel ?? 1),
        });
        setLevelOptions(list);
      }
    });
  }, []);

  const saveConfig = () => {
    form.validateFields().then(values => {
      const { fromLevel, toLevel } = values;
      apiV2LeanEnergyFlowAnalysisConfigPost({ energyGroupId: energyUnitGroupId, fromLevel, toLevel }).then(res => {
        setOpen(false);
        updateFn();
      });
    });
  };

  return (
    <Modal
      title="配置"
      open={open}
      onOk={saveConfig}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{ flex: '128px' }}
        style={{ padding: '8px 48px' }}
        onValuesChange={() => {
          form.validateFields();
        }}
      >
        <Form.Item
          label="默认展示层级"
          name="levelRange"
          dependencies={['fromLevel', 'toLevel']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const fromLevel = getFieldValue('fromLevel');
                const toLevel = getFieldValue('toLevel');

                if (fromLevel > toLevel) {
                  return Promise.reject(new Error('下级节点数需大于等于上级节点数'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Row>
            <Col span={10}>
              <Form.Item name="fromLevel">
                <Select options={levelOptions} placeholder="请选择"></Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <div style={{ textAlign: 'center', paddingTop: 4 }}>～</div>
            </Col>
            <Col span={10}>
              <Form.Item name="toLevel">
                <Select options={levelOptions} placeholder="请选择"></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LevelConfigModal;
