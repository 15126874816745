import { FC, useEffect, useMemo, useState } from 'react';

import ElectricRatePrice from './ElectricRatePrice';
import ElectricRateBasicPricce from './ElectricRateBasicPrice';
import ElectricAdditionalPrice from './ElectricAdditionalPrice';

import { useBreadcrumbRoutes, Wrapper, Button, FormTitle } from '@maxtropy/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ElectricityContractVo, getElectricityContract } from '../../../../api/electricity';
import { Col, Row } from 'antd';

const ElectricRate: FC<{ disabled?: boolean }> = ({ disabled = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const routes = useMemo(() => {
    return [{ name: `${disabled ? '查看' : '编辑'}费率` }];
  }, [disabled]);

  const [contract, setContract] = useState<ElectricityContractVo>();

  useEffect(() => {
    if (id) {
      getElectricityContract(id).then(setContract);
    }
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title={routes[0].name} />
      <ElectricRatePrice disabled={disabled} contract={contract} />
      <ElectricRateBasicPricce disabled={disabled} contract={contract} />
      <ElectricAdditionalPrice disabled={disabled} contract={contract} />
      <Row
        style={{
          position: 'sticky',
          bottom: 15,
          zIndex: 99,
        }}
      >
        <Col
          span={24}
          style={{
            // background: '#FFF',
            paddingTop: 15,
            paddingLeft: 30,
          }}
        >
          <Button
            onClick={() => {
              navigate('/operation/energy/contract');
            }}
          >
            返回
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ElectricRate;
