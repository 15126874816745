import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  useAsync,
  CustomFilter,
} from '@maxtropy/components';
import { Form, Input, Space } from 'antd';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getOuTypeList, OuType } from '@/shared/api/options';

import { useQuery } from '@/shared/utils/utils';
import { getImplementPage, Implement, ImplementPageRequest } from '../../api/implement';

type SearchParams = Omit<ImplementPageRequest, 'page' | 'size'>;

interface TableData {
  id: number;
  serialNumber: string;
  name: string;
  ouTypeName: string;
  tenantName: string;
  adminNum: number;
  memberNum: number;
  updateByUsername: string;
  updateTime: string;
}

const basicColumns = [
  {
    title: '编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '管理员总数',
    dataIndex: 'adminNum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={String(v ?? 0)} />,
  },
  {
    title: '成员总数',
    dataIndex: 'memberNum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={String(v ?? 0)} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (record: TableData) => (
      <Space size={16}>
        <Link to={`/device/ou/authority/manage/${record.id}`}>管理员调整</Link>

        <Link to={`/device/ou/authority/detail/${record.id}`}>查看</Link>
      </Space>
    ),
  },
];

// const routes = [
//   {name: 'Iot配置'},
//   {name: '单元管理'},
//   {name: '运营单元（实施）'}
// ];

function formatTableData(res: Array<Implement>): Array<TableData> {
  return res.map(item => ({
    ...item,
    updateTime: item.updateTime && dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss'),
  }));
}

const ImplementList: React.FC = () => {
  const navigate = useNavigate();
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const ouTypes = useAsync<Array<OuType>>(getOuTypeList, []);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nameOrSerialNum = urlSearchParams.get('nameOrSerialNum');
    form.setFieldsValue({ nameOrSerialNum });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
    const paramsObj = {
      nameOrSerialNum: val.nameOrSerialNum,
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      nameOrSerialNum: undefined,
    };
    setPageOffset(1);
    setSearchParams(params);
    navigate(`?`);
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getImplementPage({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatTableData(res.list);
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const typeColumn = {
    title: '类型',
    dataIndex: 'ouTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  };

  const withOuTypeColumns = basicColumns.map(item => item);
  withOuTypeColumns.splice(2, 0, typeColumn);
  const columns = ouTypes.length > 1 ? withOuTypeColumns : basicColumns;

  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      className="page_wrapper"
      filters={
        <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
          <Form.Item name="nameOrSerialNum" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
        </CustomFilter>
      }
    >
      <Table<TableData> rowKey="id" scroll={{ x: 1500 }} loading={isLoading} columns={columns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ImplementList;
