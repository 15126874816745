import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col, Space, Cascader, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Radio,
  Select,
  Modal,
  Form,
  Input,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  SubContent,
  TreeSelect,
  DatePicker,
  InputNumber,
  useAsync,
  Upload,
} from '@maxtropy/components';
import styles from '../index.module.scss';
import {
  apiV2DeviceGetByCodePost,
  apiV2DeviceLedgerAddPost,
  apiV2DeviceLedgerDetailPost,
  apiV2DeviceLedgerEditPost,
  apiV2PhysicalModelPhysicalModelAllListPost,
} from '@maxtropy/device-customer-apis-v2';
import { EquipmentState, EquipmentStateDisplay, OptionItem } from '../util';
import dayjs from 'dayjs';
import { getOuListByMcid } from '@/api/ou';
import { debounce, isNil, uniqBy } from 'lodash-es';
import { getDeviceTypeTree } from '@/api/deviceType';
import { OrganizationResponse, getOrganizationWithCodeWithCurrent } from '@/api/device';
import { formatTreeData } from '@/pages/Device/CreatePropertyInfo';
import { DefaultOptionType } from 'antd/es/cascader';

interface Props {
  isEdit?: boolean;
}

const EquipmentLedgerCreate: React.FC<Props> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceCodeOptions, setDeviceCodeOptions] = useState<OptionItem[]>([]);
  const [typeId, setTypeId] = useState<number>();
  const deviceCode = Form.useWatch('deviceCode', form);
  const typeIds = Form.useWatch('typeIds', form);
  const mcid = Form.useWatch('mcid', form);

  const fetchRef = useRef(0);

  const onFinish = async (values: any) => {
    const deviceId = deviceCodeOptions.find(i => i.value === deviceCode)?.id;
    if (isEdit) {
      apiV2DeviceLedgerEditPost({
        ...values,
        deviceId,
        typeId: values.typeIds?.[1],
        id: Number(id),
      }).then(res => {
        navigate(`/asset/equipment/ledger`);
      });
    } else {
      apiV2DeviceLedgerAddPost({ ...values, deviceId, typeId: values.typeIds?.[1] }).then(res => {
        navigate(`/asset/equipment/ledger`);
      });
    }
  };

  useEffect(() => {
    if (id) {
      apiV2DeviceLedgerDetailPost({ id }).then(res => {
        form.setFieldsValue({
          ...res,
          purchaseTime: res.purchaseTime ? dayjs(res.purchaseTime) : undefined,
          useTime: res.useTime ? dayjs(res.useTime) : undefined,
        });
        setTypeId(res.typeId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}设备台账` }];
  }, [isEdit]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  // 物模型型号
  const physicalModelList = useAsync(
    useCallback(() => {
      return apiV2PhysicalModelPhysicalModelAllListPost({}).then(res => res.list ?? []);
    }, [])
  );

  const ou = useAsync(
    useCallback(() => {
      if (!isNil(mcid)) {
        return getOuListByMcid(mcid);
      }
      return Promise.resolve([]);
    }, [mcid]),
    []
  );

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const manufacturerOptions = useMemo(() => {
    const deviceTypeId = typeIds?.[1];

    const list = (physicalModelList ?? [])
      .filter(m => isNil(deviceTypeId) || m.deviceTypeId === deviceTypeId)
      .map(i => ({ label: i.manufacturerName, value: i.manufacturerId }));
    return uniqBy(list, 'value');
  }, [physicalModelList, typeIds]);

  const objectModalTypeOptions = useMemo(() => {
    const deviceTypeId = typeIds?.[1];

    return (physicalModelList ?? [])
      .filter(m => isNil(manufacturerId) || m.manufacturerId === manufacturerId)
      .filter(m => isNil(deviceTypeId) || m.deviceTypeId === deviceTypeId)
      .map(i => ({ label: i.modelNo, value: i.id, ...i }));
  }, [physicalModelList, manufacturerId, typeIds]);

  const ouOptions = useMemo(() => {
    return (ou ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [ou]);

  const cascaderData = useMemo(() => {
    const list =
      (deviceTypeData?.tree ?? []).reduce((prev: any, curr) => {
        return [...prev, ...(curr.deviceTypes ?? [])];
      }, []) ?? [];
    const uniqList = uniqBy(list, 'id');
    return uniqList.map((m: any) => ({
      label: m.name!,
      value: m.id!,
      children: m.children?.map((i: any) => ({
        ...i,
        label: i.name!,
        value: i.id!,
      })),
    }));
  }, [deviceTypeData]);

  useEffect(() => {
    if (isNil(typeId) || cascaderData.length === 0) {
      return;
    }

    const deviceType = cascaderData.find((i: any) => i?.children?.some((j: any) => j.id === typeId))?.value;
    form.setFieldsValue({
      typeIds: [deviceType, typeId],
    });
  }, [typeId, cascaderData]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (value === '') {
        setDeviceCodeOptions([]);
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setDeviceCodeOptions([]);
      setLoading(true);

      apiV2DeviceGetByCodePost({ request: value })
        .then(res => {
          if (fetchId !== fetchRef.current) {
            return;
          }
          setDeviceCodeOptions((res.list ?? []).map(i => ({ label: i.code!, value: i.code!, ...i })));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 500);
  }, []);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`${isEdit ? '编辑' : '新建'}设备台账`}></FormTitle>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <SubContent title="基础信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item
                label="资产设备名称"
                name="assetDeviceName"
                rules={[
                  { required: true, message: '请输入规则名称' },
                  { max: 100, message: '最多输入100个字' },
                ]}
              >
                <Input placeholder="请输入"></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="资产设备SN/编码"
                name="assetDeviceSnCode"
                rules={[
                  { required: true, message: '请输入规则名称' },
                  { max: 100, message: '最多输入100个字' },
                ]}
              >
                <Input placeholder="请输入"></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="数采设备编号" name="deviceCode">
                <Select
                  allowClear
                  placeholder="请输入选择"
                  showSearch
                  optionFilterProp="label"
                  onSearch={debounceFetcher}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  options={deviceCodeOptions}
                  onChange={val => {
                    if (isNil(val)) {
                      form.setFieldsValue({
                        mcid: undefined,
                        manufacturerId: undefined,
                        physicalModelId: undefined,
                        ouIds: undefined,
                        typeIds: undefined,
                      });
                      setTypeId(undefined);
                    } else {
                      const item = deviceCodeOptions.find(i => i.value === val);

                      form.setFieldsValue({
                        mcid: item?.customerMcid,
                        manufacturerId: item?.manufacturerId,
                        physicalModelId: item?.physicalModelId,
                        ouIds: item?.ouIds,
                      });
                      cascaderData.map(i => {
                        if (i.children?.some((j: any) => j.value === item?.typeId)) {
                          form.setFieldValue('typeIds', [i.value, item?.typeId]);
                        }
                      });
                      setTypeId(item?.typeId);
                    }
                  }}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="所属组织" name="mcid" rules={[{ required: true, message: '请选择所属组织' }]}>
                <TreeSelect treeData={treeData} allowClear placeholder="请选择" disabled={!isNil(deviceCode)} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="运营单元" name="ouIds">
                <Select
                  placeholder="请选择"
                  showSearch
                  mode="multiple"
                  options={ouOptions}
                  allowClear
                  disabled={!isNil(deviceCode)}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="状态" name="status" rules={[{ required: true, message: '请选择状态' }]}>
                <Radio.Group>
                  <Space size={8}>
                    <Radio value={EquipmentState.INUSE}>{EquipmentStateDisplay[EquipmentState.INUSE]}</Radio>
                    <Radio value={EquipmentState.IDLE}>{EquipmentStateDisplay[EquipmentState.IDLE]}</Radio>
                    <Radio value={EquipmentState.MAINTAINED}>{EquipmentStateDisplay[EquipmentState.MAINTAINED]}</Radio>
                    <Radio value={EquipmentState.DAMAGEDED}>{EquipmentStateDisplay[EquipmentState.DAMAGEDED]}</Radio>
                    <Radio value={EquipmentState.SCRAPPED}>{EquipmentStateDisplay[EquipmentState.SCRAPPED]}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="设备类型" name="typeIds" rules={[{ required: true, message: '请选择设备类型' }]}>
                <Cascader
                  options={cascaderData}
                  disabled={!isNil(deviceCode)}
                  maxTagCount="responsive"
                  showSearch={{ filter }}
                  placeholder={'请选择所属类目'}
                  onChange={val => {
                    form.setFieldsValue({
                      manufacturerId: undefined,
                      physicalModelId: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="设备厂商" name="manufacturerId">
                <Select
                  options={manufacturerOptions}
                  allowClear
                  placeholder="请选择"
                  onChange={() => {
                    form.setFieldsValue({
                      physicalModelId: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="设备型号" name="physicalModelId">
                <Select options={objectModalTypeOptions} allowClear placeholder="请选择" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="设备图片" name="pic">
                <Upload tip="支持扩展名：.jpg .png .jpeg，大小不超过10M" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="采购/使用信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item label="购入日期" name="purchaseTime">
                <DatePicker style={{ width: '100%' }} placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="购入原值" name="purchasePrice">
                <InputNumber style={{ width: '100%' }} max={99999999} placeholder="请输入" addonAfter={'元'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="使用日期" name="useTime">
                <DatePicker style={{ width: '100%' }} placeholder="请选择" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="折旧年限" name="depreciationPeriod">
                <InputNumber style={{ width: '100%' }} min={1} max={999} placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
      </Form>

      <Space className="sticky-footer" size={8}>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          保存
        </Button>
        <Button
          onClick={() => {
            Modal.confirm({
              title: '是否放弃所有未保存信息并返回列表？',
              onOk: () => navigate(`/asset/equipment/ledger`),
            });
          }}
        >
          取消
        </Button>
      </Space>
    </Wrapper>
  );
};

export default EquipmentLedgerCreate;
