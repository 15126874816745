import { Button } from '@maxtropy/components';
import { Image } from 'antd';
import { useState } from 'react';
import powerFactorConfigImag from './powerFactorConfig.png';

const PowerFactorConfigModal: React.FC<{ tierConfigName?: string }> = ({ tierConfigName }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={powerFactorConfigImag}
        preview={{
          visible,
          src: powerFactorConfigImag,
          onVisibleChange: value => {
            setVisible(value);
          },
        }}
      />
      <Button
        onClick={() => setVisible(true)}
        type="link"
        style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        点击查看功率因数比例表
      </Button>
      <p style={{ position: 'absolute', top: '25px', right: '0', color: '#aaaaaa' }}>【{tierConfigName ?? ''}】</p>
    </>
  );
};

export default PowerFactorConfigModal;
