import { FormContent, Button, ShowInput } from '@maxtropy/components';
import { Col, Form, InputNumber, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  BaseElectricityBillResponse,
  BillStatusEnum,
  CapitalContributionRateRequest,
  checkCapitalContribution,
  ElectricityCapitalContributionQuantityResponse,
  getElectricityCapitalContributionQuantityResponse,
} from '../../../../api/electricity';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPriceProps,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceType,
} from '../../../../api/electricitySettlementRules';

import styles from './index.module.scss';

export interface ElectricityCapitalContributionRateProps {
  data?: BaseElectricityBillResponse;
  onChange?: (v: CapitalContributionRateRequest) => void;
  value?: CapitalContributionRateRequest;
}

const ElectricityCapitalContributionRate: FC<ElectricityCapitalContributionRateProps> = ({ data, onChange, value }) => {
  const [form] = Form.useForm();

  // const [state, setState] = useState<CapitalContributionRateRequest>()

  const [quantities, setQuantities] = useState<Array<ElectricityCapitalContributionQuantityResponse>>([]);

  useEffect(() => {
    if (value) {
      form.setFieldsValue(value);
    }
  }, [value, form]);

  useEffect(() => {
    if (
      data &&
      data?.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM &&
      data?.config?.hasCapitalContributionRate
    ) {
      getElectricityCapitalContributionQuantityResponse(data.id).then(res => {
        if (data.status === BillStatusEnum.DRAFT) {
          checkCapitalContribution(data.id).then(check => {
            if (check) {
              let formData: CapitalContributionRateRequest = {};
              const filterRes = res.list?.filter(i => (i.billId ?? undefined) !== undefined) || [];
              if (filterRes.length) {
                filterRes.forEach(i => {
                  switch (i.highLowType) {
                    case HasTimeOfUseElectricityPricePropsType.SHARPPEAK:
                      formData.summitPowerQuantity = i.totalActivePowerQuantity;
                      break;
                    case HasTimeOfUseElectricityPricePropsType.PEAK:
                      formData.peakPowerQuantity = i.totalActivePowerQuantity;
                      break;
                    case HasTimeOfUseElectricityPricePropsType.AVERAGE:
                      formData.plainPowerQuantity = i.totalActivePowerQuantity;
                      break;
                    case HasTimeOfUseElectricityPricePropsType.VALLEY:
                      formData.valleyPowerQuantity = i.totalActivePowerQuantity;
                      break;
                  }
                });
                onFinish(formData);
              }
            }
          });
        } else {
          setQuantities(res.list || []);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [edit, setEdit] = useState<boolean>(true);

  const isExit = (type: HasTimeOfUseElectricityPricePropsType) => {
    return (data?.config?.timeOfDayRateChecked ?? []).includes(type);
  };

  const onFinish = (v: CapitalContributionRateRequest) => {
    onChange?.(v);
    setEdit(false);
  };

  return data?.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM &&
    data?.config?.hasCapitalContributionRate ? (
    <FormContent title="公摊电量">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row className={styles.wrapper}>
          <Col
            className={styles.raw}
            span={24}
            style={{
              backgroundColor: 'rgba(var(--base-text-color), .03)',
              borderBottom: '1px solid rgba(var(--base-text-color), 0.15)',
            }}
          >
            <Row style={{ width: '100%' }} gutter={8}>
              <Col style={{ paddingLeft: 15, width: 70 }}></Col>
              <Col span={7}>单价</Col>
              <Col span={7}>有功电量</Col>
              <Col span={7}>有功电费</Col>
            </Row>
          </Col>
          {data?.status !== BillStatusEnum.DRAFT ? (
            <>
              {quantities.map(i => {
                return (
                  <Col className={styles.raw} span={24} key={i.highLowType}>
                    <Row style={{ width: '100%' }} gutter={8}>
                      <Col style={{ paddingLeft: 15, width: 70 }}>
                        {HasTimeOfUseElectricityPriceProps[i.highLowType!]}
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={i.unitPowerRate?.toFixed(8)}
                          addonAfter={(i.billId ?? undefined) !== undefined ? '元/kWh' : undefined}
                        />
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={i.totalActivePowerQuantity?.toFixed(2)}
                          addonAfter={(i.billId ?? undefined) !== undefined ? 'kWh' : undefined}
                        />
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={i.totalElectricFee?.toFixed(2)}
                          addonAfter={(i.billId ?? undefined) !== undefined ? '元' : undefined}
                        />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
              {data.config.timeUseTariffType === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE && (
                <Col className={styles.raw} span={24}>
                  <Row gutter={8} style={{ width: '100%' }}>
                    <Col style={{ paddingLeft: 15, width: 70 }}>总</Col>
                    <Col span={7}>
                      <ShowInput value={''} />
                    </Col>
                    <Col span={7}>
                      <ShowInput
                        value={quantities.reduce((a, c) => a + (c.totalActivePowerQuantity ?? 0), 0)?.toFixed(2)}
                        addonAfter="kWh"
                      />
                    </Col>
                    <Col span={7}>
                      <ShowInput
                        value={quantities.reduce((a, c) => a + (c.totalElectricFee ?? 0), 0)?.toFixed(2)}
                        addonAfter="元"
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </>
          ) : (
            <>
              {data.config.timeUseTariffType === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE && (
                <>
                  <Col span={24} className={styles.raw}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col style={{ paddingLeft: 15, width: 70 }}>
                        {HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.SHARPPEAK]}
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={data.config.unitSummitRate?.toFixed(8)}
                          addonAfter={isExit(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) ? '元/kWh' : undefined}
                        />
                      </Col>
                      <Col span={7}>
                        {isExit(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) ? (
                          <Form.Item name="summitPowerQuantity" rules={[{ required: true, message: '请输入' }]}>
                            {edit ? <InputNumber placeholder="请输入" min={0} precision={2} /> : <ShowInput />}
                          </Form.Item>
                        ) : (
                          <ShowInput />
                        )}
                      </Col>
                      <Col span={7}>
                        <Form.Item dependencies={['summitPowerQuantity']}>
                          {({ getFieldValue }) => {
                            const v = getFieldValue('summitPowerQuantity');
                            const fee = (v ?? 0) * (data?.config?.unitSummitRate ?? 0);
                            return (
                              <ShowInput
                                value={v !== undefined ? fee?.toFixed(2) : undefined}
                                addonAfter={
                                  v !== undefined && isExit(HasTimeOfUseElectricityPricePropsType.SHARPPEAK)
                                    ? '元'
                                    : undefined
                                }
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className={styles.raw}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col style={{ paddingLeft: 15, width: 70 }}>
                        {HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.PEAK]}
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={data.config.unitPeakRate?.toFixed(8)}
                          addonAfter={isExit(HasTimeOfUseElectricityPricePropsType.PEAK) ? '元/kWh' : undefined}
                        />
                      </Col>
                      <Col span={7}>
                        {isExit(HasTimeOfUseElectricityPricePropsType.PEAK) ? (
                          <Form.Item name="peakPowerQuantity" rules={[{ required: true, message: '请输入' }]}>
                            {edit ? <InputNumber placeholder="请输入" min={0} precision={2} /> : <ShowInput />}
                          </Form.Item>
                        ) : (
                          <ShowInput />
                        )}
                      </Col>
                      <Col span={7}>
                        <Form.Item dependencies={['peakPowerQuantity']}>
                          {({ getFieldValue }) => {
                            const v = getFieldValue('peakPowerQuantity');
                            const fee = (v ?? 0) * (data?.config?.unitPeakRate ?? 0);
                            return (
                              <ShowInput
                                value={v !== undefined ? fee?.toFixed(2) : undefined}
                                addonAfter={
                                  v !== undefined && isExit(HasTimeOfUseElectricityPricePropsType.PEAK)
                                    ? '元'
                                    : undefined
                                }
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col span={24} className={styles.raw}>
                <Row gutter={8} style={{ width: '100%' }}>
                  <Col style={{ paddingLeft: 15, width: 70 }}>
                    {HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.AVERAGE]}
                  </Col>
                  <Col span={7}>
                    <ShowInput
                      value={data.config.unitPlainRate?.toFixed(8)}
                      addonAfter={
                        data.config.timeUseTariffType ===
                          HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE ||
                        (data.config.timeUseTariffType ===
                          HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
                          isExit(HasTimeOfUseElectricityPricePropsType.AVERAGE))
                          ? '元/kWh'
                          : undefined
                      }
                    />
                  </Col>
                  <Col span={7}>
                    {data.config.timeUseTariffType ===
                      HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE ||
                    (data.config.timeUseTariffType === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
                      isExit(HasTimeOfUseElectricityPricePropsType.AVERAGE)) ? (
                      <Form.Item name="plainPowerQuantity" rules={[{ required: true, message: '请输入' }]}>
                        {edit ? <InputNumber placeholder="请输入" min={0} precision={2} /> : <ShowInput />}
                      </Form.Item>
                    ) : (
                      <ShowInput />
                    )}
                  </Col>
                  <Col span={7}>
                    <Form.Item dependencies={['plainPowerQuantity']}>
                      {({ getFieldValue }) => {
                        const v = getFieldValue('plainPowerQuantity');
                        const fee = (v ?? 0) * (data?.config?.unitPlainRate ?? 0);
                        return (
                          <ShowInput
                            value={v !== undefined ? fee?.toFixed(2) : undefined}
                            addonAfter={
                              v !== undefined &&
                              (data?.config?.timeUseTariffType ===
                                HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE ||
                                (data?.config?.timeUseTariffType ===
                                  HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
                                  isExit(HasTimeOfUseElectricityPricePropsType.AVERAGE)))
                                ? '元'
                                : undefined
                            }
                          />
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {data.config.timeUseTariffType === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE && (
                <>
                  <Col span={24} className={styles.raw}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col style={{ paddingLeft: 15, width: 70 }}>
                        {HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.VALLEY]}
                      </Col>
                      <Col span={7}>
                        <ShowInput
                          value={data.config.unitValleyRate?.toFixed(8)}
                          addonAfter={isExit(HasTimeOfUseElectricityPricePropsType.VALLEY) ? '元/kWh' : undefined}
                        />
                      </Col>
                      <Col span={7}>
                        {isExit(HasTimeOfUseElectricityPricePropsType.VALLEY) ? (
                          <Form.Item name="valleyPowerQuantity" rules={[{ required: true, message: '请输入' }]}>
                            {edit ? <InputNumber placeholder="请输入" min={0} precision={2} /> : <ShowInput />}
                          </Form.Item>
                        ) : (
                          <ShowInput />
                        )}
                      </Col>
                      <Col span={7}>
                        <Form.Item dependencies={['valleyPowerQuantity']}>
                          {({ getFieldValue }) => {
                            const v = getFieldValue('valleyPowerQuantity');
                            const fee = (v ?? 0) * (data?.config?.unitValleyRate ?? 0);
                            return (
                              <ShowInput
                                value={v !== undefined ? fee?.toFixed(2) : undefined}
                                addonAfter={
                                  v !== undefined && isExit(HasTimeOfUseElectricityPricePropsType.VALLEY)
                                    ? '元'
                                    : undefined
                                }
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className={styles.raw}>
                    <Row gutter={8} style={{ width: '100%' }}>
                      <Col style={{ paddingLeft: 15, width: 70 }}>总</Col>
                      <Col span={7}>
                        <ShowInput value={''} />
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          dependencies={[
                            'summitPowerQuantity',
                            'peakPowerQuantity',
                            'plainPowerQuantity',
                            'valleyPowerQuantity',
                          ]}
                        >
                          {({ getFieldValue }) => {
                            const summitPowerQuantity = getFieldValue('summitPowerQuantity');
                            const peakPowerQuantity = getFieldValue('peakPowerQuantity');
                            const plainPowerQuantity = getFieldValue('plainPowerQuantity');
                            const valleyPowerQuantity = getFieldValue('valleyPowerQuantity');
                            const manipulate =
                              summitPowerQuantity !== undefined ||
                              peakPowerQuantity !== undefined ||
                              plainPowerQuantity !== undefined ||
                              valleyPowerQuantity !== undefined;
                            return (
                              <ShowInput
                                value={
                                  manipulate
                                    ? (
                                        (summitPowerQuantity ?? 0) +
                                        (peakPowerQuantity ?? 0) +
                                        (plainPowerQuantity ?? 0) +
                                        (valleyPowerQuantity ?? 0)
                                      )?.toFixed(2)
                                    : undefined
                                }
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          dependencies={[
                            'summitPowerQuantity',
                            'peakPowerQuantity',
                            'plainPowerQuantity',
                            'valleyPowerQuantity',
                          ]}
                        >
                          {({ getFieldValue }) => {
                            const summitPowerQuantity = getFieldValue('summitPowerQuantity');
                            const peakPowerQuantity = getFieldValue('peakPowerQuantity');
                            const plainPowerQuantity = getFieldValue('plainPowerQuantity');
                            const valleyPowerQuantity = getFieldValue('valleyPowerQuantity');
                            const manipulate =
                              summitPowerQuantity !== undefined ||
                              peakPowerQuantity !== undefined ||
                              plainPowerQuantity !== undefined ||
                              valleyPowerQuantity !== undefined;
                            const fee =
                              (summitPowerQuantity ?? 0) * (data?.config?.unitSummitRate ?? 0) +
                              (peakPowerQuantity ?? 0) * (data?.config?.unitPeakRate ?? 0) +
                              (plainPowerQuantity ?? 0) * (data?.config?.unitPlainRate ?? 0) +
                              (valleyPowerQuantity ?? 0) * (data?.config?.unitValleyRate ?? 0);
                            return (
                              <ShowInput
                                value={manipulate ? fee?.toFixed(2) : undefined}
                                addonAfter={manipulate ? '元' : undefined}
                              />
                            );
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
              <Col span={24} style={{ marginBottom: 10, paddingRight: 15 }}>
                <Row justify="end">
                  {edit && (
                    <Button type="primary" htmlType="submit">
                      确认
                    </Button>
                  )}
                  {!edit && (
                    <Button
                      type="primary"
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      编辑
                    </Button>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </FormContent>
  ) : null;
};

export default ElectricityCapitalContributionRate;
