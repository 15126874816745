import { Empty } from '@maxtropy/components';
import { Skeleton } from 'antd';
import { FC, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { isNil } from 'lodash';
import { ChartItem } from '../../type';
import ResizeObserver from 'rc-resize-observer';

export interface ChartProps {
  loading?: boolean;
  chartData?: ChartItem[];
  option?: any;
  height?: number;
}

const Charts: FC<ChartProps> = props => {
  const { option, chartData, height } = props;
  const echartsTheme = useEchartsTheme();
  const chartRef = useRef<ReactEcharts>();
  const filteredChartData = chartData
    ?.map(c => c.chartValues?.filter(i => !isNil(i)))
    .filter(x => !isNil(x))
    .flat();

  return (
    <Skeleton style={{ padding: '120px 80px' }} loading={props.loading} paragraph={{ rows: 6 }}>
      {filteredChartData && filteredChartData.length ? (
        <ResizeObserver
          onResize={({ width }) => {
            chartRef.current?.getEchartsInstance().resize({ width });
          }}
        >
          <ReactEcharts
            ref={e => {
              if (e) chartRef.current = e;
            }}
            option={option}
            style={{ height: height ?? 'calc(100vh - 520px)', minHeight: 440 }}
            notMerge
            lazyUpdate={false}
            theme={echartsTheme}
          />
        </ResizeObserver>
      ) : (
        <Empty />
      )}
    </Skeleton>
  );
};

export default Charts;
