import styles from './index.module.scss';
import timeIcon from '../icon/time@2x.png';
import loadingPic from '../icon/loading@2x.png';
import finishedIcon from '../icon/check-circle@2x.png';
import doubleLeft from '../icon/doubleLeft@2x.png';
import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { V2ProductSheetDetailPostResponse, apiV2ProductSheetDetailPost } from '@maxtropy/device-customer-apis-v2';
interface Prop {
  setDetailOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

const StatusDisplayName: Record<number, string> = {
  0: '未生产',
  1: '已完工',
  2: '正在生产',
};

const Detail: React.FC<Prop> = props => {
  const { setDetailOpen, id } = props;
  const [detail, setDetail] = useState<V2ProductSheetDetailPostResponse>();
  const [startTime, setStartTime] = useState<string>();
  const [stopTime, setStopTime] = useState<string>();

  useEffect(() => {
    apiV2ProductSheetDetailPost({ id: id }).then(setDetail);
    setStartTime(dayjs(detail?.projectStartTime).format('YYYY-MM-DD HH:mm:ss'));
    setStopTime(dayjs(detail?.projectStopTime).format('YYYY-MM-DD HH:mm:ss'));
  }, [detail?.projectStartTime, detail?.projectStopTime, id]);

  return (
    <>
      <div className={styles.detailStyles}>
        <div>
          <div className={styles.detailBack} onClick={() => setDetailOpen(true)}>
            <span>
              <img className={styles.backIcon} src={doubleLeft} alt="" />
            </span>
            <span>返回</span>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.detailList}>
              <span>生产单编码（MO）</span>
              <span>{detail?.code}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>产出物编码</span>
              <span>{detail?.outputProductCode}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>产出物名称</span>
              <span>{detail?.outputProductName}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>计划开始时间</span>
              <span>{startTime}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>计划结束时间</span>
              <span>{stopTime}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>出货日期</span>
              <span>{detail?.outputGoodsDate}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>计划生产数量</span>
              <span>{detail?.projectProductNum}</span>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
          <div>
            <div className={styles.detailList}>
              <span>生产单状态</span>
              <div>
                <span>
                  <img
                    style={{ width: 16, height: 16, marginRight: 2 }}
                    className={`${styles.centerIconStyle} ${detail?.productSheetState === 2 ? styles.ani : ''}`}
                    src={
                      detail?.productSheetState === 0
                        ? timeIcon
                        : detail?.productSheetState === 1
                        ? finishedIcon
                        : detail?.productSheetState === 2
                        ? loadingPic
                        : ''
                    }
                    alt=""
                  />
                </span>
                <span
                  className={styles.InPro}
                  style={
                    detail?.productSheetState === 0
                      ? { color: 'rgba(255,255,255,0.45)' }
                      : detail?.productSheetState === 1
                      ? { color: '#49AA19' }
                      : { color: '#4A90E2' }
                  }
                >
                  {StatusDisplayName[detail?.productSheetState!]}
                </span>
              </div>
            </div>
            <Divider style={{ margin: '12px 0' }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
