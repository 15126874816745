import { Dayjs } from 'dayjs';

export enum StateType {
  STOP = 1,
  STANDBY = 2,
}

export enum TimeType {
  DAY = 1,
  CUSTOM = 2,
}

export enum TimeUnitType {
  MIN = '1M',
  H = 'H',
}

export const TimeUnitTypeName = {
  [TimeUnitType.MIN]: 'min',
  [TimeUnitType.H]: 'h',
};

export const StateTypeName = {
  [StateType.STANDBY]: '待机',
  [StateType.STOP]: '停机',
};

export const StateTypeTag = {
  [StateType.STANDBY]: 'default',
  [StateType.STOP]: 'error',
};

export interface PlanItem {
  id?: number;
  name?: string;
  timeType?: TimeType;
  dayDate?: Dayjs[];
  dayTime?: Dayjs[];
  customTimes?: Dayjs[][];
  timeRange?: string;
  reason?: string;
}

export interface StateItem {
  id?: number;
  name?: string;
  avgCount?: number;
  avgTime?: number;
  deviceType?: number;
  timeUnit?: TimeUnitType;
  timeType?: TimeType;
  dayDate?: Dayjs[];
  customTimes?: Dayjs[];
  dateRange?: string;
}
