import FixedBottomButton from '@/components/FixedBottomButton';
import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, useUpdate, Button, Form, Modal, Select, Input } from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getUetOuList, OuListByUetRes } from '../../../../api/ou';
import { addPipe, AddPipeReq, delPipe, getPipeList, PipeListRes, UetInfoProps } from '../../../../api/uet';
import { PermissionsType } from '../../../../common/permissionsConst';
import { useHasPermission } from '../../../../utils/utils';
import { apiV2LinePipeDeviceListPost, V2LinePipeDeviceListPostResponse } from '@maxtropy/device-customer-apis-v2';
interface BSAProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const Pipeline: React.FC<BSAProps> = ({ info, isDetail }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;
  const [visible, setVisible] = useState(false);
  const [x, forceUpdate] = useUpdate();
  const [form] = Form.useForm();
  const [pipelineList, setPipelineList] = useState<PipeListRes[]>([]);
  const [ou, setOu] = useState<string>();
  const [deviceList, setDeviceList] = useState<V2LinePipeDeviceListPostResponse['list']>([]);
  const { id } = useParams<{ id: string }>();
  const [ouList, setOuList] = useState<OuListByUetRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const hasLinePipeAdd = useHasPermission(PermissionsType.B_LINE_PIPE_ADD); // 新增管线
  const hasLinePipeDel = useHasPermission(PermissionsType.B_LINE_PIPE_DELETE); // 移除管线

  const columns = [
    {
      title: '序号',
      dataIndex: 'order',
      ellipsis: { showTitle: true },
      render: (v: string, rows: any, index: number) => <EllipsisSpan value={index + 1} />,
    },
    {
      title: '管线名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '运营单元',
      dataIndex: 'ouName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备编号',
      dataIndex: 'deviceCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      render: (v: string, record: PipeListRes) => {
        return (
          <Space>
            <Button
              type="link"
              disabled={btnDisabled ? btnDisabled : !hasLinePipeDel}
              onClick={() => {
                Modal.confirm({
                  title: '确定提交审核？',
                  content: (
                    <p>
                      <span style={{ color: 'red' }}>移除后不可恢复</span>，你还要继续吗
                    </p>
                  ),
                  bodyStyle: { color: 'red' },
                  onOk() {
                    delPipe(record.id).then(() => forceUpdate());
                  },
                  okText: '继续',
                });
              }}
            >
              移除
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      getUetOuList(id).then(setOuList);
      setLoading(true);
      getPipeList({ uetId: id })
        .then(setPipelineList)
        .finally(() => setLoading(false));
    }
  }, [id, x]);

  const ouOptions = useMemo(() => {
    if (ouList?.length !== 0) {
      return ouList?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [ouList]);

  const onOuChange = (v: string) => {
    form.setFieldsValue({ deviceId: undefined });
    setOu(v);
  };

  useEffect(() => {
    if (ou) {
      apiV2LinePipeDeviceListPost({
        id: ou,
      }).then(res => {
        setDeviceList(res.list);
      });
    }
  }, [ou]);

  const devOptions = useMemo(() => {
    if (deviceList?.length !== 0) {
      return deviceList?.map(item => ({ label: `${item.code}【${item.name}】`, value: item.id }));
    }
  }, [deviceList]);

  const onSave = () => {
    setEdit(false);
  };

  const onFinish = (v: Omit<AddPipeReq, 'uetId'>) => {
    if (id) {
      addPipe({
        ...v,
        uetId: id,
      }).then(() => {
        setVisible(false);
        form.resetFields();
        setOu(undefined);
        setLoading(true);
        getPipeList({
          uetId: id,
        })
          .then(setPipelineList)
          .finally(() => {
            setLoading(false);
          });
      });
    }
  };
  return (
    <>
      {hasLinePipeAdd && (
        <Button type="primary" disabled={btnDisabled} onClick={() => setVisible(true)}>
          <PlusOutlined />
          新增管线
        </Button>
      )}
      <Modal
        width={600}
        open={visible}
        title="新建管线"
        destroyOnClose
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setOu(undefined);
        }}
        onOk={() => form.submit()}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} onFinish={onFinish}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select options={ouOptions} placeholder="请选择" onChange={onOuChange} />
          </Form.Item>
          <Form.Item
            label="管线名称"
            name="name"
            rules={[
              { required: true, message: '请输入管线名称' },
              { max: 20, message: '最多20个字符' },
            ]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="设备选择" name="deviceId" rules={[{ required: true, message: '请选择设备' }]}>
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="请选择"
              disabled={!ou}
              filterOption={(input, option) =>
                (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
              options={devOptions}
            />
          </Form.Item>
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '最多50个字符' }]}>
            <Input placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
      <Table style={{ marginTop: '15px' }} loading={loading} columns={columns} dataSource={pipelineList}></Table>
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    forceUpdate();
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
    </>
  );
};

export default Pipeline;
