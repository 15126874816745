import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import { Form, Cascader } from 'antd';

import {
  Wrapper,
  Paging,
  Table,
  usePaging,
  EllipsisSpan,
  useBreadcrumbRoutes,
  useAsync,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Template, TemplateRequest, TemplateResponse, getTemplateList, getManufacturerList } from '../../api/template';
import { useQuery } from '../../utils/utils';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import dayjs from 'dayjs';
import { DefaultOptionType } from 'antd/es/cascader';
import { getPhysicalModelList } from '@/api/device';
import { getDeviceTypeTree } from '@/api/deviceType';

interface FilterParams {
  name: string | undefined;
  deviceType: Array<Array<number>> | undefined;
}

type SearchParams = Omit<TemplateRequest, 'page' | 'size'>;

interface TemplateTableData extends Omit<Template, 'attributeList'> {}

function formatListData(dataSource: TemplateResponse): Array<TemplateTableData> {
  return dataSource?.list.map(item => ({
    ...item,
  }));
}

// const routes = [
//   { name: "Iot配置" },
//   { name: "设备信息模板库" },
//   { name: "固定属性模板" },
// ];

const columns: ColumnsType<TemplateTableData> = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最新版本号',
    width: 120,
    dataIndex: 'lastVersion',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '使用数量',
    width: 120,
    dataIndex: 'useCount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={String(v)} />,
  },
  {
    title: '最后操作时间',
    width: 200,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    width: 200,
    dataIndex: 'lastUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const render = (record: TemplateTableData) => <Link to={`/device/info/template/detail/${record.id}`}>查看</Link>;

const TemplateList: React.FC = () => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const localParamsStr = sessionStorage.getItem(window.location.pathname);
  const localParamsObj = localParamsStr ? (JSON.parse(localParamsStr) as SearchParams) : {};
  const [searchParams, setSearchParams] = useState<SearchParams>(localParamsObj);

  useEffect(() => {
    if (localParamsStr) {
      form.setFieldsValue({
        ...localParamsObj,
        deviceType: localParamsObj.originDeviceType,
      });
    }
    return () => {
      sessionStorage.removeItem(window.location.pathname);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getTemplateList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const onFinish = (val: FilterParams) => {
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params: SearchParams = {
      ...val,
      deviceType: deviceType,
    };
    sessionStorage.setItem(
      window.location.pathname,
      JSON.stringify({
        ...params,
        originDeviceType: val.deviceType,
      })
    );
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
    sessionStorage.removeItem(window.location.pathname);
  };
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const deviceType = Form.useWatch('deviceType', form);
  const manufacturerList = useAsync(getManufacturerList, []);

  const physicalModelList = useAsync(
    useCallback(() => {
      const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree);
      const params = {
        deviceTypeIds,
        manufacturerId,
      };
      return getPhysicalModelList(params);
    }, [deviceType, manufacturerId, deviceTypeData])
  );

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList?.list && physicalModelList.list.length > 0) {
      return (physicalModelList.list ?? []).map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item name="name" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="deviceType" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => {
            form.setFieldsValue({
              physicalModelId: undefined,
            });
          }}
        />
      </Form.Item>
      <Form.Item name="manufacturerId" label="厂商">
        <Select
          optionFilterProp="label"
          placeholder="请选择"
          showSearch
          options={manufacturerOptions}
          onChange={() => {
            form.setFieldsValue({
              physicalModelId: undefined,
            });
          }}
        />
      </Form.Item>
      <Form.Item name="physicalModelId" label="物模型型号">
        <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} filtersStyle={{ marginTop: 10 }}>
      <Table<TemplateTableData>
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1500 }}
        columns={[...columns, { title: '操作', fixed: 'right', width: 150, render }]}
        sticky
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default TemplateList;
