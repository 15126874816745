import { EPriceTime, PricedisplayType, TimeOfUseTariffTypeDisplay, TimeOfUseTariffType } from '@/shared/types';

export const getItemTime = (index: number, displayType: PricedisplayType) => {
  index = displayType === PricedisplayType.HALF ? index : index * 2;
  return zeroPad(Math.floor(index / 2), 2) + ':' + (index % 2 === 0 ? '00' : '30');
};

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const getItemTypes = (times: EPriceTime[], displayType: PricedisplayType) =>
  times.reduce(
    (acc, cur) => {
      acc.splice(
        cur.intervalStart,
        cur.intervalEnd - cur.intervalStart + 1,
        ...new Array(cur.intervalEnd - cur.intervalStart + 1).fill(cur.type)
      );
      return acc;
    },
    [...new Array(displayType === PricedisplayType.HALF ? 48 : 24)]
  );

export const getPeriodTypes = (times: EPriceTime[], displayType: PricedisplayType): EPriceTime[] =>
  getItemTypes(times, displayType).reduce((acc, cur, index) => {
    const last = acc[acc.length - 1];
    if (last && last.type === cur) {
      last.intervalEnd = index;
    } else {
      acc.push({
        type: cur,
        intervalStart: index,
        intervalEnd: index,
      });
    }
    return acc;
  }, [] as EPriceTime[]);

export const getPeriodTime = (time: EPriceTime, displayType: PricedisplayType) =>
  `${TimeOfUseTariffTypeDisplay[time.type]}: ${getItemTime(time.intervalStart, displayType)}~${getItemTime(
    time.intervalEnd + 1,
    displayType
  )}`;

export const isFullDay = (times: EPriceTime[], displayType: PricedisplayType) =>
  !getItemTypes(times, displayType).some(t => t === undefined);

export const hasType = (times: EPriceTime[], type: TimeOfUseTariffType) => times && times.some(t => t.type === type);
