import {
  Button,
  EllipsisSpan,
  Paging,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Key,
  CustomFilter,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  TreeSelect,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '@/shared/components/MemberSelect/interface';
import { DateDisplay, formatTreeData } from './utils';
import { ElectricitySaleContractVo } from '@/api/electricitySalesContract';
import { getSaleElectricityContractList } from '@/api/electricitySettlementRules';
import { SaleClientInfo, getSaleClientList } from '@/api/sale';
import dayjs from 'dayjs';
import {
  DiscardStatus,
  EnergyAssetsBillPageBody,
  EnergyAssetsBillPageItem,
  EnergyAssetsBillStatus,
  EnergyAssetsBillStatusDisplay,
  discardEnergyAssetsBill,
  getEnergyAssetsBillPage,
} from '@/api/energyAssetsBill';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';

const { RangePicker } = DatePicker;

const columns = [
  {
    title: '结算单编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },

  {
    title: '结算周期',
    dataIndex: 'settlementStartTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string, record: EnergyAssetsBillPageItem) => (
      <EllipsisSpan
        value={`${dayjs(v).format(DateDisplay)} - ${dayjs(record.settlementEndTime).format(DateDisplay)}`}
      />
    ),
  },
  {
    title: '生成状态',
    dataIndex: 'errorCode',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: EnergyAssetsBillStatus, record: any) => (
      <EllipsisSpan
        value={EnergyAssetsBillStatusDisplay[v] + `${record.discard === DiscardStatus.DISCARD ? '(已作废)' : ''}`}
      />
    ),
  },

  {
    title: '生成日期',
    dataIndex: 'generatingTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(DateDisplay)} />,
  },
  {
    title: '所属站点',
    dataIndex: 'arrayName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算规则',
    dataIndex: 'settlementRuleName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? '自定义' : v} />,
  },
  {
    title: '所属合同',
    dataIndex: 'saleElectricityContractName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生成人',
    dataIndex: 'createUserName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface FilterProps {
  customerMcid?: string; //所属组织
  saleElectricityContractId?: Key; //合同id
  saleClientId?: Key; //客户id
  code?: string; //结算单编号
  settlementStartTime?: string; //结算周期-开始日期
  settlementEndTime?: string; //结算周期-结束日期
  showObsolete?: boolean[]; //是否展示已作废列表
  arrayName?: string; //站点名称
}

const EnergyAssetsBill: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [filterForm] = Form.useForm();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [contractData, setContractData] = useState<ElectricitySaleContractVo[]>([]); // 合同列表
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]); // 客户列表
  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset, antDPaginationOnShowSizeChange } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<EnergyAssetsBillPageItem[]>([]);
  const [refreshState, refresh] = useUpdate();
  // 手动生成结算单权限
  const hasCreatePermission = useHasPermission(PermissionsType.B_ENERGYPROPERTYSETTLEMENTSTATEMENTMANALCHECK);
  // 作废权限
  const hasCancellationPermission = useHasPermission(PermissionsType.B_ENERGYPROPERTYSETTLEMENTSTATEMENTBANCHECK);
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const url_pageOffset = urlSearchParams.get('pageOffset') || undefined;
  const url_pageSize = urlSearchParams.get('pageSize') || undefined;
  const [searchParams, setSearchParams] = useState<EnergyAssetsBillPageBody>({
    customerMcid: urlSearchParams.get('customerMcid') ?? undefined,
    saleElectricityContractId: urlSearchParams.get('saleElectricityContractId')
      ? Number(urlSearchParams.get('saleElectricityContractId'))
      : undefined,
    saleClientId: urlSearchParams.get('saleClientId') ? Number(urlSearchParams.get('saleClientId')) : undefined,
    code: urlSearchParams.get('code') ?? undefined,
    arrayName: urlSearchParams.get('arrayName') ?? undefined,
    settlementStartTimeLower: urlSearchParams.get('settlementStartTimeLower') ?? undefined,
    settlementStartTimeUpper: urlSearchParams.get('settlementStartTimeUpper') ?? undefined,
    settlementEndTimeLower: urlSearchParams.get('settlementEndTimeLower') ?? undefined,
    settlementEndTimeUpper: urlSearchParams.get('settlementEndTimeUpper') ?? undefined,
    showObsolete:
      urlSearchParams.get('valid') === 'true' || urlSearchParams.get('showObsolete') === 'true' ? true : false,
  });

  // 详情页带参返回
  useEffect(() => {
    const page = url_pageOffset ? Number(url_pageOffset) : pageOffset;
    const size = url_pageSize ? Number(url_pageSize) : pageSize;
    antDPaginationOnShowSizeChange(page, size);
    setPageOffset(page);
    filterForm.setFieldsValue({
      customerMcid: urlSearchParams.get('customerMcid') ?? undefined,
      saleElectricityContractId: urlSearchParams.get('saleElectricityContractId')
        ? Number(urlSearchParams.get('saleElectricityContractId'))
        : undefined,
      saleClientId: urlSearchParams.get('saleClientId') ? Number(urlSearchParams.get('saleClientId')) : undefined,
      code: urlSearchParams.get('code') ?? undefined,
      arrayName: urlSearchParams.get('arrayName') ?? undefined,
      settlementStartTime:
        urlSearchParams.get('settlementStartTimeLower') && urlSearchParams.get('settlementStartTimeUpper')
          ? [
              dayjs(urlSearchParams.get('settlementStartTimeLower')),
              dayjs(urlSearchParams.get('settlementStartTimeUpper')),
            ]
          : undefined,
      settlementEndTime:
        urlSearchParams.get('settlementEndTimeLower') && urlSearchParams.get('settlementEndTimeUpper')
          ? [dayjs(urlSearchParams.get('settlementEndTimeLower')), dayjs(urlSearchParams.get('settlementEndTimeUpper'))]
          : undefined,
      showObsolete: urlSearchParams.get('showObsolete') === 'true' ? [true] : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 获取组织树
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
    // 获取合同列表
    getSaleElectricityContractList().then(setContractData);
    // 获取客户列表
    getSaleClientList().then(setCustomList);
  }, []);

  useEffect(() => {
    setLoading(true);
    // 获取表格数据
    getEnergyAssetsBillPage(
      {
        page: pageOffset,
        size: pageSize,
      },
      searchParams
    )
      .then(res => {
        if (res) {
          setTableData(res.list);
          setTotalCount(res.total);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, refreshState, setTotalCount, pageOffset, pageSize]);

  // 合同列表
  const contractDataOptions = useMemo(() => {
    return (contractData ?? []).map(i => ({ label: `${i.code}【${i.name}】`, value: i.id }));
  }, [contractData]);

  //  客户列表
  const customListOptions = useMemo(() => {
    return (customList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [customList]);

  // 异常原因
  const onAbnormalReason = (record: EnergyAssetsBillPageItem) => {
    const { errorCode, errorDevices, settlementRuleId } = record;
    const deviceCode = (errorDevices ?? []).map(i => i.code).join('、');

    let title;
    if (errorCode === EnergyAssetsBillStatus.ABNORMAL_FAIL_GET) {
      title = (
        <span>
          设备抄表获取失败，请重试，设备编号：{deviceCode}，
          <Button disabled type="link" onClick={() => {}}>
            <span style={{ fontSize: 16 }}>点击查看</span>
          </Button>
          抄表记录。
        </span>
      );
      console.log(title);
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_METER) {
      title = (
        <span>
          结算规则中的站点缺少计量电表，
          <Button
            type="link"
            onClick={() => {
              window.open(`/operation/settlementRules/energyAssetsRules/details/${settlementRuleId}`, '_blank');
            }}
          >
            <span style={{ fontSize: 16 }}>点击查看</span>
          </Button>
          结算规则。
        </span>
      );
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_MODAL_PRICE) {
      title = `未获取到电价模版中的电度电价。`;
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_UET_ID) {
      title = 'uetId获取失败';
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MANUAL_FAILED) {
      title = '手工抄表失败';
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_MISSING_FEE) {
      title = '未获取到电价模版中的电度电价。';
    } else if (errorCode === EnergyAssetsBillStatus.ABNORMAL_RATE_NULL) {
      title = '电表倍率为空，无法生成结算单。';
    } else {
      title = `系统错误。请重试或联系管理员。`;
    }

    Modal.warning({
      title,
      okText: '关闭',
    });
  };

  // 作废结算单
  const onCancellation = (id: Key) => {
    discardEnergyAssetsBill(id).then(() => {
      refresh();
    });
  };

  const onFinish = (v: FilterProps) => {
    const {
      customerMcid,
      saleElectricityContractId,
      saleClientId,
      code,
      settlementStartTime,
      settlementEndTime,
      showObsolete,
      arrayName,
    } = v;
    const settlementStartTimeLower = settlementStartTime
      ? dayjs(settlementStartTime[0]).format(DateDisplay)
      : undefined;
    const settlementStartTimeUpper = settlementStartTime
      ? dayjs(settlementStartTime[1]).format(DateDisplay)
      : undefined;
    const settlementEndTimeLower = settlementEndTime ? dayjs(settlementEndTime[0]).format(DateDisplay) : undefined;
    const settlementEndTimeUpper = settlementEndTime ? dayjs(settlementEndTime[1]).format(DateDisplay) : undefined;
    const search = {
      customerMcid,
      saleElectricityContractId,
      saleClientId,
      code: code === '' ? undefined : code,
      arrayName: arrayName === '' ? undefined : arrayName,
      settlementStartTimeLower,
      settlementStartTimeUpper,
      settlementEndTimeLower,
      settlementEndTimeUpper,
      showObsolete: showObsolete && showObsolete.length > 0 ? true : false,
    };
    setSearchParams({
      ...search,
    });
    setPageOffset(1);
    navigate(`?${qs.stringify({ ...search, pageOffset, pageSize })}`);
  };

  const onReset = () => {
    setSearchParams({
      customerMcid: undefined,
      saleElectricityContractId: undefined,
      saleClientId: undefined,
      code: undefined,
      settlementStartTimeLower: undefined,
      settlementStartTimeUpper: undefined,
      settlementEndTimeLower: undefined,
      settlementEndTimeUpper: undefined,
      arrayName: undefined,
      showObsolete: false,
    });
    navigate('?');
    setPageOffset(1);
    antDPaginationOnShowSizeChange(1, 100);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      width: 160,
      fixed: 'right' as 'right',
      render: (v: any, record: EnergyAssetsBillPageItem) => {
        return (
          <Space size={16}>
            <Button type="link">
              <Link
                to={`/operation/settlements/energyAssetsBill/detail/${record.id}?${qs.stringify(
                  { ...searchParams, pageSize, pageOffset },
                  {
                    indices: false,
                  }
                )}`}
              >
                详情
              </Link>
            </Button>
            {hasCancellationPermission && (
              <Button
                type="link"
                disabled={record.discard === DiscardStatus.DISCARD}
                onClick={() => onCancellation(record.id)}
              >
                {record.discard === DiscardStatus.DISCARD ? '已作废' : '作废'}
              </Button>
            )}
            {/* // 异常状态且不是自定义结算单 显示异常原因 */}
            {record.errorCode !== EnergyAssetsBillStatus.NORMAL ? (
              !isNil(record.settlementRuleId) ? (
                <Button type="link" onClick={() => onAbnormalReason(record)}>
                  异常原因
                </Button>
              ) : null
            ) : null}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter form={filterForm} onFinish={(v: FilterProps) => onFinish(v)} onReset={onReset}>
      <Form.Item name="customerMcid" label="所属组织">
        <TreeSelect
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
      </Form.Item>
      <Form.Item name="saleElectricityContractId" label="所属合同">
        <Select placeholder="请选择" options={contractDataOptions} showSearch optionFilterProp="label" />
      </Form.Item>
      <Form.Item label="所属客户" name="saleClientId">
        <Select placeholder="请选择" options={customListOptions} showSearch optionFilterProp="label" />
      </Form.Item>
      <Form.Item
        name="code"
        label="结算单编号"
        rules={[{ pattern: /^[0-9a-zA-Z]*$/, message: '请输入数字、英文字母' }]}
      >
        <Input maxLength={15} placeholder="请输入编号查询" />
      </Form.Item>
      <Form.Item name="arrayName" label="站点名称">
        <Input placeholder="请输入站点名称" />
      </Form.Item>
      <Form.Item label="结算周期-开始日期" name="settlementStartTime">
        <RangePicker
          disabledDate={date => {
            // 不能选择2000年之前的日期
            return date.isBefore('2000-01-01');
          }}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item label="结算周期-结束日期" name="settlementEndTime">
        <RangePicker
          disabledDate={date => {
            // 不能选择2000年之前的日期
            return date.isBefore('2000-01-01');
          }}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Form.Item name="showObsolete">
        <Checkbox.Group>
          <Checkbox value={true}>显示已作废结算单</Checkbox>
        </Checkbox.Group>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      {hasCreatePermission && (
        <Button
          type="primary"
          wrapStyle={{ marginBottom: 10 }}
          onClick={() => {
            // 新页面打开
            window.open('/operation/settlements/energyAssetsBill/create', '_blank');
          }}
        >
          手动生成结算单
        </Button>
      )}

      <Table rowKey="id" scroll={{ x: 1300 }} sticky loading={loading} columns={buildColumns} dataSource={tableData} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default EnergyAssetsBill;
