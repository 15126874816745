import { ColumnsType } from 'antd/es/table';
import { EdgeDeviceTemplatePoint } from '../../EdgeDevicePointInfo';
import React, { useState } from 'react';
import { DataPointType, StaticPoint, VirtualPoint } from '../../../types';
import CollectionPoint from './Collection';
import VirtualSpot from './Virtual';
import StaticSpot from './Static';
import { Input, Tabs } from '@maxtropy/components';

const { TabPane } = Tabs;

interface AddEdgePointProps {
  onUpdate: (values: any) => void;
  editColumns: ColumnsType<EdgeDeviceTemplatePoint>;
  onSearch: (values: string) => void;
}

const AddEdgePoint: React.FC<AddEdgePointProps> = props => {
  const { onUpdate, editColumns, onSearch } = props;
  const [activeKey, setActiveKey] = useState<DataPointType>(DataPointType.BASE_POINT);

  return (
    <Tabs
      activeKey={`${activeKey}`}
      onChange={(key: string) => {
        setActiveKey(Number(key));
      }}
      destroyInactiveTabPane={true}
      tabBarExtraContent={
        <Input.Search
          style={{ width: 260 }}
          placeholder="请输入数据属性名称/标识符"
          allowClear
          onSearch={(value: any, event: any) => {
            event?.preventDefault();
            onSearch(value);
          }}
        />
      }
    >
      <TabPane tab="采集点" key={DataPointType.BASE_POINT} forceRender>
        <CollectionPoint editColumns={editColumns} onUpdate={onUpdate} />
      </TabPane>

      <TabPane tab="虚拟点" key={DataPointType.VIRTUAL_POINT} forceRender>
        <VirtualSpot onUpdate={onUpdate} editColumns={editColumns as ColumnsType<VirtualPoint>} />
      </TabPane>

      <TabPane tab="静态点" key={DataPointType.STATIC_POINT} forceRender>
        <StaticSpot onUpdate={onUpdate} editColumns={editColumns as ColumnsType<StaticPoint>} />
      </TabPane>
    </Tabs>
  );
};

export default AddEdgePoint;
