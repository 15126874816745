import {
  BaseInfoContent,
  Button,
  Form,
  FormTitle,
  ShowInput,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import CenterDataCompartion from './components/CenterDataCompartion';
import BottomSingleDevice from './components/BottomSingleDevice';
import { apiV2HvacDetailPost } from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { BoardType } from './type';

const HVACDashBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const routes = [{ name: '暖通空调站' }];

  // 请求接口
  const { data: detail, loading } = useRequest(() => apiV2HvacDetailPost({ id: Number(id) }), {
    ready: !!id,
    refreshDeps: [id],
  });

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        stationCode: detail?.code ?? '--',
        ouCode: detail?.ouCode ?? '--',
        ouName: detail?.ouName ?? '--',
      });
    }
  }, [detail]);

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Spin spinning={loading}>
        <FormTitle
          title={<div>{`${detail?.name}`}</div>}
          extraContent={
            <Button
              onClick={() => {
                navigate(`/production/manage/hvacBoard?stationId=${id}`);
              }}
            >
              返回拓扑
            </Button>
          }
        />
        <SubContent>
          <Form form={form}>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item name="stationCode" label="站房编号">
                <ShowInput />
              </Form.Item>
              <Form.Item name="ouCode" label="运营单元编号">
                <ShowInput />
              </Form.Item>
              <Form.Item name="ouName" label="运营单元名称">
                <ShowInput />
              </Form.Item>
            </BaseInfoContent>
          </Form>
        </SubContent>
        <SubContent>
          <CenterDataCompartion id={id} type={BoardType.STATION} />
        </SubContent>
        <SubContent>
          <BottomSingleDevice stationId={id} />
        </SubContent>
      </Spin>
    </Wrapper>
  );
};

export default HVACDashBoard;
