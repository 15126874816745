import { useEffect, useMemo, useState } from 'react';
import { Divider, Space, Spin, TreeProps } from 'antd';
import { PlusSquareOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Empty, Key, Button, Input, Tree, Tooltip, PopConfirm, Modal } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { isEmpty } from 'lodash-es';
import {
  apiV2OutputProductCategoryDeletePost,
  apiV2OutputProductCategoryListPost,
  V2OutputProductCategoryListPostResponse,
  apiV2OutputProductCategoryTreeUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import styles from './index.module.scss';
import { CurNode, TreeOperationType } from '../..';

interface Props {
  treeUpdate: number;
  treeUpdateFn: () => void;
  setCurNode: (node: CurNode | null) => void;
  curNode?: CurNode | null;
  isEdit: boolean;
  setOperationType: (v: TreeOperationType) => void;
}

export type CateNode = Exclude<V2OutputProductCategoryListPostResponse['children'], undefined>[number];

let dataList: { key: number; title: string; parentKey: number }[] = [];

function formatToNodeData(data?: CateNode[]): DataNode[] {
  if (isEmpty(data)) return [];

  return (
    data?.map((node: CateNode) => {
      const list = {
        key: node.id!,
        title: node.name!,
        parentKey: node.parentId!,
      };
      dataList.push(list);

      return {
        title: node.name!,
        key: node.id!,
        level: node.level!,
        rootId: node.rootId!,
        parentId: node.parentId!,
        children: formatToNodeData(node.children),
        disabled: node.level === 0,
      };
    }) || []
  );
}

const StationTree = ({ treeUpdate, treeUpdateFn, setCurNode, curNode, setOperationType, isEdit }: Props) => {
  const [data, setData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([0]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [searchParams, setSearchParams] = useState<any>();

  const hasAdd = useHasPermission(PermissionsType.B_ADDDESCRIPTIONANDSPECIFICATION); // 新增
  const hasDel = useHasPermission(PermissionsType.B_DELDESCRIPTIONANDSPECIFICATION); // 删除
  const hasCopy = useHasPermission(PermissionsType.B_COPYDESCRIPTIONANDSPECIFICATION); // 复制

  useEffect(() => {
    setLoading(true);
    apiV2OutputProductCategoryListPost({})
      .then((res: any) => {
        if (res) {
          dataList = [];

          setData(formatToNodeData([res as CateNode]));
          const allDataNodeKeys = dataList.map(i => i.key);
          setExpandedKeys(allDataNodeKeys);
          if (allDataNodeKeys && allDataNodeKeys.length > 0) {
            if (allDataNodeKeys.length === 1) {
              // 无分类,则默认是基于根节点的新增操作
              const rootNode = res;
              setCurNode({
                level: rootNode.level!,
                rootId: rootNode.rootId!,
                key: rootNode.id!,
              });
              setOperationType(TreeOperationType.ADD);
            } else if (isEmpty(curNode)) {
              // 默认选中第一个
              setCurNode({ key: allDataNodeKeys[1] });
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeUpdate, setExpandedKeys]);

  const onInputChange = (value: string) => {
    const newExpandedKeys = dataList.filter(item => item.title.indexOf(value) > -1).map(i => i.parentKey);

    setExpandedKeys([0, ...newExpandedKeys]);
    setSearchParams(value);
    setAutoExpandParent(true);
  };

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (data && data.length && searchParams) {
      const loop = (data: DataNode[]): DataNode[] =>
        data.map(item => {
          const strTitle = item.title as string;
          const searchName = searchParams ? searchParams : undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                {beforeStr}
                <span style={{ color: 'red' }}>{searchName}</span>
                {afterStr}
              </>
            ) : (
              strTitle
            );

          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(data);
    } else {
      return data;
    }
  }, [searchParams, data]);

  const onDrop: TreeProps['onDrop'] = info => {
    const { dragNode, node } = info;
    apiV2OutputProductCategoryTreeUpdatePost({ parentId: node.key, id: dragNode.key }).then(() => {
      treeUpdateFn();
    });
  };

  const onDelete = (id: number) => {
    apiV2OutputProductCategoryDeletePost({ id }).then(() => {
      treeUpdateFn();
      setOperationType(TreeOperationType.EDIT);
      setCurNode(null);
    });
  };

  return (
    <div className={styles.treeArea}>
      <div className={styles.treeTitle}>
        产出物分类
        <Button
          type="link"
          style={{ padding: 0 }}
          onClick={() => {
            window.open('/api/v2/outputProduct-category/export');
          }}
        >
          导出
        </Button>
      </div>
      <div className={styles.searchArea}>
        <Input.Search placeholder="请输入" allowClear onSearch={onInputChange} />
      </div>
      <Divider style={{ margin: '16px 0', backgroundColor: 'var(--mx-base-border-color)' }} />
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data.length > 0 ? (
            <Tree
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={[curNode?.key || 0]}
              onDrop={onDrop}
              draggable={{ icon: false }}
              titleRender={(nodeData: any = {}) => {
                return (
                  <div className={`${styles.treeTitle} ${nodeData.level === 0 && styles.baseTreeTitle}`}>
                    <span>{nodeData?.title}</span>
                    <Space size={4} className={styles.treeBtn}>
                      {hasAdd && (
                        <Tooltip title="添加子集">
                          <PlusSquareOutlined
                            className={styles.titleRenderIcon}
                            onClick={e => {
                              e.stopPropagation();
                              setCurNode({ ...nodeData });
                              setOperationType(TreeOperationType.ADD);
                            }}
                          />
                        </Tooltip>
                      )}
                      {hasCopy && nodeData.key !== 0 && (
                        <Tooltip title="复制">
                          <CopyOutlined
                            className={styles.titleRenderIcon}
                            onClick={e => {
                              e.stopPropagation();
                              setCurNode(nodeData);
                              setOperationType(TreeOperationType.COPY);
                            }}
                          />
                        </Tooltip>
                      )}
                      {hasDel && nodeData.key !== 0 && (
                        <Tooltip title="删除" zIndex={1}>
                          <PopConfirm title="是否删除该产出物分类?" onConfirm={() => onDelete(nodeData.key)}>
                            <DeleteOutlined onClick={e => e.stopPropagation()} className={styles.titleRenderIcon} />
                          </PopConfirm>
                        </Tooltip>
                      )}
                    </Space>
                  </div>
                );
              }}
              onExpand={v => {
                setExpandedKeys(v);
                setAutoExpandParent(false);
              }}
              onSelect={(_, option) => {
                if (isEdit) {
                  Modal.confirm({
                    title: '当前编辑内容还未保存，是否离开',
                    onOk: () => {
                      setCurNode({ key: option.node.key });
                      setOperationType(TreeOperationType.EDIT);
                    },
                  });
                } else {
                  setOperationType(TreeOperationType.EDIT);
                  setCurNode({ key: option.node.key });
                }
              }}
              autoExpandParent={autoExpandParent}
            />
          ) : (
            <Empty style={{ marginTop: 40 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default StationTree;
