import { Button, Empty, FormTitle, Select, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlarmNode } from './utils/const';
import { isNil } from 'lodash-es';
import FooterDrawer from './components/FooterDrawer';
import {
  apiV2HvacDashboardDetailGetStationIndicatorValuePost,
  apiV2HvacDashboardDetailGetStationListPost,
  V2HvacDashboardDetailGetStationListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useQuery } from '@/shared/utils/utils';
import MiddleGraph from './components/MiddleGraph';
import BenchmarkConfigModal from './BenchmarkConfigModal';
import { MeasurementType } from '../ProductionBeatConfig/utils';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

export type StationItem = Exclude<V2HvacDashboardDetailGetStationListPostResponse['list'], undefined>[number];

const HVACBoard: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const url_stationId = urlSearchParams.get('stationId');

  const [stationList, setStationList] = useState<StationItem[]>([]);
  const [currentStationId, setCurrentStationId] = useState<number>();
  const [currentStationCode, setCurrentStationCode] = useState<string>();
  const [benConfigVisible, setBenConfigVisible] = useState<boolean>(false);
  const [alarmNodes, setAlarmNodes] = useState<AlarmNode[]>([]);
  const [init, setInit] = useState<boolean>(true);

  const hasBoardConfig = useHasPermission(PermissionsType.B_KANBANHAVCMONITORINDEXCONFIG); // 看板配置
  const hasBaseConfig = useHasPermission(PermissionsType.B_KANBANHAVCUNITCONFIG); // 基础配置
  const hasStationDetail = useHasPermission(PermissionsType.P_HVACSTATIONDETAILS); // 站点详情

  // 获取站房列表
  useEffect(() => {
    apiV2HvacDashboardDetailGetStationListPost({}).then(res => {
      setStationList(res?.list ?? []);
    });
  }, []);

  // 站房下拉框
  const stationOptions = useMemo(() => {
    if (!stationList.length) return [];
    const list = stationList.map(m => ({
      label: m.name,
      value: m.id,
      code: m.code,
    }));
    if (init) {
      const defaultId = url_stationId ? Number(url_stationId) : list?.[0]?.value;
      const defaultCode = list.find(m => m.value === defaultId)?.code;
      setCurrentStationId(defaultId);
      setCurrentStationCode(defaultCode);
      setInit(false);
    } else {
      setCurrentStationId(list?.[0]?.value);
      setCurrentStationCode(list?.[0]?.code);
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationList]);

  // 获取指标
  const { data: indexList, isLoading } = useQuery(
    useCallback(async () => {
      if (isNil(currentStationId)) {
        return Promise.resolve([]);
      }
      return await apiV2HvacDashboardDetailGetStationIndicatorValuePost({
        id: currentStationId,
      }).then(res => {
        return res.list ?? [];
      });
    }, [currentStationId])
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      {stationList.length ? (
        <>
          <div className={styles.headerRow}>
            <div className={styles.rowLeft}>
              <FormTitle className={styles.titleSpan} title="暖通空调看板" />
              <Select
                className={styles.selectBox}
                value={currentStationId}
                placeholder="请选择"
                onChange={(val: number) => {
                  setInit(false);
                  setCurrentStationId(val);
                  setCurrentStationCode(stationOptions.find(k => k.value === val)?.code);
                  navigate(`${window.location.pathname}?stationId=${val}`);
                }}
                options={stationOptions}
              />
            </div>
            <Space size={8}>
              {hasStationDetail && !!indexList?.length && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isNil(currentStationId)) return;
                    navigate(`/production/manage/hvacBoard/stationDetail/${currentStationId}`);
                  }}
                >
                  站房详情
                </Button>
              )}
              {hasBoardConfig && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isNil(currentStationId)) return;
                    navigate(`/production/manage/hvacBoard/boardConfig/${currentStationId}`);
                  }}
                >
                  看板配置
                </Button>
              )}
              {hasBaseConfig && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isNil(currentStationId)) return;
                    navigate(`/systemConfig/eneryTopo/unitConfig`);
                  }}
                >
                  单位配置
                </Button>
              )}
            </Space>
          </div>
          <Spin spinning={isLoading}>
            <div className={styles.middleIndex}>
              {indexList?.map(item => {
                return (
                  <div className={styles.indexCard} key={item.indicatorId}>
                    <div className={styles.indexName}>
                      {item.indicatorName}
                      {item.unitName && `（${item.unitName}）`}
                    </div>
                    {item.measurementType === MeasurementType.ACCUM ? (
                      <div className={styles.indexInfo}>
                        <div className={styles.left}>
                          <span>小时：</span>
                          <span className={styles.val}>{item?.hourValue?.toFixed(2) ?? '--'}</span>
                        </div>
                        <div className={styles.splitLine}></div>
                        <div className={styles.right}>
                          <span>当日：</span>
                          <span className={styles.val}>{item?.todayValue?.toFixed(2) ?? '--'}</span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.indexInfo}>
                        <span className={styles.val}>{item?.rtValue?.toFixed(2) ?? '--'}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Spin>
          <MiddleGraph
            stationId={currentStationId}
            stationCode={currentStationCode}
            existIndex={!!indexList?.length}
            alarmNodes={alarmNodes}
          />
          <FooterDrawer stationId={currentStationId} callbackAlarms={setAlarmNodes} />
        </>
      ) : (
        <Empty description="暂无数据" style={{ marginTop: 200 }} />
      )}
      {benConfigVisible && (
        <BenchmarkConfigModal stationId={currentStationId} onCancel={() => setBenConfigVisible(false)} />
      )}
    </Wrapper>
  );
};

export default HVACBoard;
