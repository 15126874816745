import { MeterTaskPageItem, StatusEnum, StatusEnumDisplay } from '@/api/universeMeterQuery';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { Button, DatePicker, EllipsisSpan, Form, Modal, Paging, Table, Tag } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { formatTime, onDownload } from '../../utils';
import { isNil } from 'lodash';
import { useState } from 'react';
import { apiV2UniverseMeterReadingTaskReExecPost } from '@maxtropy/device-customer-apis-v2';

const statusColor = {
  [StatusEnum.NOT_RUN]: '#D48806',
  [StatusEnum.RUNNING]: '#3570BD',
  [StatusEnum.FINISHED]: '#389E0D',
  [StatusEnum.FAILED]: '#BF2E33',
};
const columns = [
  {
    title: '上期抄表时间',
    dataIndex: 'lastReadingTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? v : dayjs(v).format(formatTime)} />,
  },
  {
    title: '本期抄表时间',
    dataIndex: 'currentReadingTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? v : dayjs(v).format(formatTime)} />,
  },
  {
    title: '执行时间',
    dataIndex: 'execTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? v : dayjs(v).format(formatTime)} />,
  },
  {
    title: '抄表对象',
    dataIndex: 'readingObjectName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: StatusEnum) => {
      return (
        <Tag border="solid" color={statusColor[v]}>
          {StatusEnumDisplay[v]}
        </Tag>
      );
    },
  },
];

interface Props {
  dataSource: MeterTaskPageItem[];
  loading: boolean;
  pagingInfo: any;
  updateFn: () => void;
  isHierarchicalMeterReading: boolean;
}

const MeterTable: React.FC<Props> = ({ updateFn, dataSource, loading, pagingInfo, isHierarchicalMeterReading }) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const [currentRowInfo, setCurrentRowInfo] = useState<MeterTaskPageItem>();

  const hasExport = useHasPermission(PermissionsType.B_GLOBALMETERREADINGEXPORT); // 导出报表
  const hasDetail = useHasPermission(PermissionsType.B_GLOBALMETERREADINGDETAIL); // 详情

  const disabledDate = (current: Dayjs) => {
    const last = currentRowInfo?.lastReadingTime;
    const next = currentRowInfo?.nextReadingTime;
    const thisTime = currentRowInfo?.currentReadingTime;

    // 上次抄表时间不存在，下次抄表时间存在
    if (!last && next) {
      return current < dayjs(thisTime) || current > dayjs(next);
    }

    // 上次、下次抄表时间都存在
    if (last && next) {
      return current < dayjs(last) || current > dayjs(next);
    }

    // 上次抄表时间存在，下次抄表时间不存在
    if (last && !next) {
      return current < dayjs(last);
    }

    return false;
  };

  const onModalOk = () => {
    form.validateFields().then(values => {
      const params = {
        taskId: +currentRowInfo?.id!,
        readingTime: values.readingTime.format(formatTime),
      };

      apiV2UniverseMeterReadingTaskReExecPost(params).then(() => {
        updateFn();
        setModalVisible(false);
        setCurrentRowInfo(undefined);
      });
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 200,
      render: (v: any, record: MeterTaskPageItem) => {
        return (
          <Space size={16}>
            {hasDetail && (
              <Button
                type="link"
                onClick={() => {
                  window.open(`/energy/analysis/universeMeterQuery/detail/${record?.id}`, '_blank');
                }}
                disabled={
                  record.status === StatusEnum.FAILED ||
                  record.status === StatusEnum.NOT_RUN ||
                  record.status === StatusEnum.RUNNING
                }
              >
                详情
              </Button>
            )}
            {hasExport && (
              <Button
                type="link"
                disabled={
                  record.status === StatusEnum.FAILED ||
                  record.status === StatusEnum.NOT_RUN ||
                  record.status === StatusEnum.RUNNING
                }
                onClick={() => {
                  if (isHierarchicalMeterReading) {
                    onDownload(
                      `/api/v2/universe/meter/reading/task/multiLevelExport`,
                      `【${record.projectName}】【${
                        isNil(record.currentReadingTime) ? '--' : dayjs(record.currentReadingTime).format(formatTime)
                      }】`,
                      record.id
                    );
                  } else {
                    onDownload(
                      `/api/v2/universe/meter/reading/task/export`,
                      `【${record.projectName}】【${
                        isNil(record.currentReadingTime) ? '--' : dayjs(record.currentReadingTime).format(formatTime)
                      }】`,
                      record.id
                    );
                  }
                }}
              >
                导出报表
              </Button>
            )}
            {/* <Button type="link">费用报表</Button> */}

            <Button
              type="link"
              onClick={() => {
                setModalVisible(true);
                setCurrentRowInfo(record);
                form.setFieldsValue({
                  readingTime: dayjs(record.currentReadingTime),
                });
              }}
              disabled={record.status === StatusEnum.RUNNING}
            >
              调整执行
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={buildColumns}
        scroll={{ y: 'calc(100vh - 215px)' }}
        loading={loading}
        dataSource={dataSource}
      />
      <Paging pagingInfo={pagingInfo} />

      <Modal
        title="调整执行"
        open={modalVisible}
        onOk={onModalOk}
        contentClassName="modal-form-content"
        onCancel={() => {
          setModalVisible(false);
        }}
        size="small"
        okText="确定调整"
      >
        <Form form={form} isOrigin>
          <Form.Item name="readingTime" label="调整为" rules={[{ required: true, message: '请选择日期' }]}>
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              format="YYYY-MM-DD HH:mm"
              showTime={{ format: 'HH:mm' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MeterTable;
