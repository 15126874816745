interface NodeIprops {
  id: number;
  name: string;
  type: number;
  selected: boolean;
}

interface CascaderData {
  label: string;
  value: string;
  children?: Array<CascaderData>;
}

interface ProcessSimpleInfo extends NodeIprops {
  entryOrExitSimpleInfoList?: NodeIprops[];
}

export interface ConfigDataTreeIprops extends NodeIprops {
  processSimpleInfoList?: ProcessSimpleInfo[];
}

export const formatOptionData = (data: ConfigDataTreeIprops[]): Array<CascaderData> | undefined => {
  const loop = (data: ConfigDataTreeIprops[]): Array<CascaderData> => {
    return data.map(item => ({
      label: item.name,
      value: String(item.id),
      selected: item.selected,
      children: item.processSimpleInfoList?.map(child1 => {
        return {
          label: child1.name,
          value: String(child1.id),
          selected: child1.selected,
          children: child1.entryOrExitSimpleInfoList?.map(child2 => {
            return {
              label: child2.name,
              value: child2.id + '-' + child2.type,
              selected: child2.selected,
            };
          }),
        };
      }),
    }));
  };
  if (!data) return undefined;
  return loop(data);
};
// 找到包含特定子节点的所有父节点
export const findParentsOfNode = (tree?: CascaderData[], targetId?: string, path: string[] = []): string[] | null => {
  if (!tree || !targetId) return null;
  for (const node of tree) {
    const newPath = [...path, node.value];

    if (node.value === targetId) {
      return newPath; // 找到目标节点，返回路径
    }

    if (node.children && node.children.length > 0) {
      const found = findParentsOfNode(node.children, targetId, newPath);
      if (found) {
        return found; // 在子节点中找到目标节点，返回路径
      }
    }
  }

  return null; // 未找到目标节点
};

export enum ConfType {
  EQUIPMENT = 1,
  OVERVUIEW,
}

export const ConfTypeDisplay = {
  [ConfType.EQUIPMENT]: '重点用水设备监测',
  [ConfType.OVERVUIEW]: '用水概览',
};
