import { OutputRecord } from '@/api/outputConfig';

export interface UnitItem {
  // 单位 code
  unitCode: string;
  // 单位名
  unitName: string;
}

export enum CHARACTERISTIC {
  HIGH = 1, // 高频生产
  LOWER = 2, // 零星生产
}

export const CHARACTERISTIC_FORMART = {
  [CHARACTERISTIC.HIGH]: '高频生产',
  [CHARACTERISTIC.LOWER]: '零星生产',
};

export interface OptionItem {
  label?: string;
  value?: number;
}

export type MapList = Record<number, OptionItem[]>;

export interface TreeNode {
  key: number;
  value: number;
  title: string;
  children?: TreeNode[];
  disabled: boolean;
}

export interface OutputList extends OutputRecord {
  disabled: boolean;
}

export interface OptionItem {
  label?: string;
  value?: number;
}

export enum ConfigType {
  MANUALENTRY = 1, // 手动录入
  SETTING = 2, // 基于历史产量数据推算
}

export interface OptionUnit {
  name?: string;
  value?: number;
}

export enum DataSourceType {
  MANUAL = 1,
  DOCKING = 2,
}

export const DataSourceTypeDisplay = {
  [DataSourceType.MANUAL]: '手动创建',
  [DataSourceType.DOCKING]: '三方对接',
};
