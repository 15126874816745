import { FC, useCallback, useState } from 'react';
import { Form } from 'antd';
import { isNil } from 'lodash-es';
import { Table, Paging, usePaging, Button, Input, CustomFilter } from '@maxtropy/components';
import { useQuery } from '@/shared/utils/utils';
import { AttributeTemplate, AttributeTemplateListRequest, getAttributeTemplateList } from '../../../../api/template';
import { ActionType } from '../index';

export interface DevicesModalProps {
  value?: AttributeTemplate;
  onChange?: (value?: AttributeTemplate) => void;
  deviceType?: number[];
  actionType?: ActionType;
  physicalModelId?: number;
}

type SearchParams = Omit<AttributeTemplateListRequest, 'deviceTypeId' | 'operationType' | 'page' | 'size'>;

const columns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
  },
  {
    title: '模板名称',
    dataIndex: 'name',
  },
  {
    title: '版本号',
    dataIndex: 'version',
  },
  {
    title: '已使用设备数量',
    dataIndex: 'useCount',
  },
];

const TemplateModal: FC<DevicesModalProps> = ({ value, onChange, deviceType, actionType, physicalModelId }) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (deviceType && !isNil(actionType)) {
        const page = await getAttributeTemplateList({
          ...searchParams,
          deviceTypeId: deviceType[deviceType.length - 1],
          operationType: actionType,
          physicalModelId,
          page: pageOffset,
          size: pageSize,
        });
        if (page) {
          setTotalCount(page.total);
          return page.list;
        }
      }
    }, [pageOffset, pageSize, searchParams, setTotalCount, deviceType, actionType, physicalModelId])
  );

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
    });
    setPageOffset(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v: undefined, record: AttributeTemplate) => {
        const selected = record.id === value?.id && record.version === value?.version;
        return (
          <Button
            type="link"
            disabled={selected}
            onClick={() => {
              onChange?.(record);
            }}
          >
            {selected ? '已选择' : '选择'}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <CustomFilter form={form} colSpan={8} onFinish={onFinish} onReset={onReset}>
        <Form.Item name="codeOrName" label="编号/名称">
          <Input placeholder="请输入编号或名称查询" />
        </Form.Item>
      </CustomFilter>
      <Table<AttributeTemplate>
        loading={isLoading}
        sticky
        scroll={{ y: 300 }}
        rowKey={record => `${record.id}_${record.version}`}
        columns={buildColumns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TemplateModal;
