import React, { FC } from 'react';

interface ILightHeightOption {
  filterTxt: any;
  text: any;
}

const LightHeightOption: FC<ILightHeightOption> = props => {
  const { filterTxt, text } = props;
  const heightLightTxt = (txt: string, heightTxt: string | RegExp) => {
    if (heightTxt === '' || heightTxt === undefined) {
      return txt;
    }
    // 前面filterOption 不区分大小写，这里用i
    const regexp = new RegExp(heightTxt, 'gi');
    return txt.replace(regexp, `<span style="color:red">${heightTxt}</span>`);
  };

  return (
    <div
      ref={nodeElement => {
        if (nodeElement) {
          nodeElement.innerHTML = heightLightTxt(text, filterTxt);
        }
      }}
    />
  );
};

export default LightHeightOption;
