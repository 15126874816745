import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';

// 服务端报警推送分页列表返回数据
export interface ServerAlarmPushListResponse {
  id: number;
  code: string; // 推送编号
  name: string; // 推送名称
  status: ServerAlarmPushStatusType; // 状态
  ouId: number;
  ouName: string;
  ouNameNumber: number; // 推送人员数
  updateTime: string; // 最后操作时间
  updateByUserId: string; // 最后操作人
}

// 服务端报警推送搜索请求
export interface ServerAlarmPushListPageSearchRequest extends PageRequest {
  // code?: string; // 编号
  codeOrName?: string; // 名称
  status?: ServerAlarmPushStatusType; // 状态
}

// 服务端报警推送状态
export enum ServerAlarmPushStatusType {
  Disable = 0, // 禁用
  Enable = 1, // 启用
}

// 服务端报警推送状态值
export const ServerAlarmPushStatusTypeValue = {
  [ServerAlarmPushStatusType.Disable]: '禁用',
  [ServerAlarmPushStatusType.Enable]: '启用',
};

// 删除服务端报警推送
export function deleteServerAlarmPushRecord(id: number): Promise<boolean> {
  return fetch(
    `/api/v2/server-alarm/config/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 根据服务端报警推送id进行状态变更
export function updateServerAlarmPushStatus(params: { status: ServerAlarmPushStatusType; id: number }): Promise<void> {
  return fetch(
    `/api/v2/server-alarm/config/status`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 服务端报警推送分页查询
export function getServerAlarmPushList(params: ServerAlarmPushListPageSearchRequest) {
  return fetch<PageResponse<ServerAlarmPushListResponse>>(
    `/api/v2/server-alarm/config/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
