import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  useBreadcrumbRoutes,
  Paging,
  Form,
  CustomFilter,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2OeeProductionPlanDelPost,
  apiV2OeeProductionPlanPagePost,
  V2EnergyPagePostRequest,
  V2OeeProductionPlanPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';

export type PlanItem = Exclude<V2OeeProductionPlanPagePostResponse['list'], undefined>[number];

const columns: ColumnsType<PlanItem> = [
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生产过程',
    dataIndex: 'appAnalysisSubscriptionName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<V2EnergyPagePostRequest, 'page' | 'size'> {}

const EquipmentProductPlan: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDDEVICESCHEDULEDPRODUCTION); // 新建
  const hasEdit = useHasPermission(PermissionsType.B_EDITDEVICESCHEDULEDPRODUCTION); // 编辑
  const hasDelete = useHasPermission(PermissionsType.B_DELETEDEVICESCHEDULEDPRODUCTION); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, loading } = useRequest(
    () =>
      apiV2OeeProductionPlanPagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2OeeProductionPlanDelPost({ id }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/energy/analysis/equipmentProductPlan/edit/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm
                placement="top"
                title="是否删除该设备的生产计划？删除后相关页面数据会刷新。"
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };
  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item label="名称" name="deviceName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="生产过程" name="name">
        <Input placeholder="请输入" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      {hasAdd && (
        <Space style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate('/energy/analysis/equipmentProductPlan/add');
            }}
          >
            新增生产计划
          </Button>
        </Space>
      )}
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default EquipmentProductPlan;
