import { ImportType } from '@/api/outputEntry';
import { Button, EllipsisSpan, getRealUrl, Paging, useSearchParams, Table, Modal } from '@maxtropy/components';
import {
  V2EnergyAnalysisImportRecordPagePostResponse,
  apiV2EnergyAnalysisImportRecordPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface Props {
  recordModalOpen: boolean;
  setRecordModalOpen: (v: boolean) => void;
}

const columns = [
  {
    title: '录入时间',
    dataIndex: 'importTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
  {
    title: '类型',
    dataIndex: 'importType',
    ellipsis: { showTitle: true },
    render: (v: ImportType) => <EllipsisSpan value={v === 1 ? '按日录入' : '按班次录入'} />,
  },
  {
    title: '录入情况',
    dataIndex: 'successCount',
    ellipsis: { showTitle: true },
    render: (v: number, record: RecordListRes) => {
      const value = (
        <span>
          成功<span style={{ color: '#4AAA1A' }}>{v}</span> /失败
          <span style={{ color: '#A61D24' }}>{record?.failureCount}</span>
        </span>
      );
      return <EllipsisSpan value={value} />;
    },
  },
];
export type RecordListRes = Exclude<V2EnergyAnalysisImportRecordPagePostResponse['list'], undefined>[number];

const RecordModal: React.FC<Props> = ({ recordModalOpen, setRecordModalOpen }) => {
  const [recordData, setRecordData] = useState<RecordListRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = useSearchParams(50);
  const { searchParams, setTotalCount } = pagingInfo;

  useEffect(() => {
    setLoading(true);
    apiV2EnergyAnalysisImportRecordPagePost({ page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setRecordData(res.list ?? []);
        setTotalCount(res.total as number);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (v: string, record: RecordListRes) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                window.open(getRealUrl(record.fileKey));
              }}
            >
              文件导出
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Modal
      size="normal"
      onOk={() => {
        setRecordModalOpen(false);
      }}
      onCancel={() => {
        setRecordModalOpen(false);
      }}
      open={recordModalOpen}
      title="录入记录"
      footer={
        <Button
          onClick={() => {
            setRecordModalOpen(false);
          }}
        >
          返回
        </Button>
      }
    >
      <Spin spinning={loading}>
        <Table
          style={{ overflowY: 'auto', maxHeight: 500 }}
          dataSource={recordData}
          columns={buildColumns}
          rowKey="id"
        />
        <Paging pagingInfo={pagingInfo} />
      </Spin>
    </Modal>
  );
};

export default RecordModal;
