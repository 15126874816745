import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Select,
  Form,
  useBreadcrumbRoutes,
  Wrapper,
  FormTitle,
  Button,
  Table,
  EllipsisSpan,
  SubContent,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import {
  apiV2AppAnalysisSubscriptionAddPost,
  V2AppAnalysisSubscriptionAddPostRequest,
  apiV2AppAnalysisSubscriptionGetAnalysisSubscriptionByCodePost,
  apiV2AppAnalysisSubscriptionBatchDelPost,
} from '@maxtropy/device-customer-apis-v2';
import { analyticsOptions } from '../prop';
import styles from '../List/index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { RenderAnalyticsTypes } from '../List';
import AddProductionProcessModal, { DataListProp } from './AddProductionProcess';

export type ProductionProcessesProp = V2AppAnalysisSubscriptionAddPostRequest['productionProcesses'];
export type AnalyticsTypesProp = V2AppAnalysisSubscriptionAddPostRequest['analyticsTypes'];
export type ProductionProcessNode = Exclude<ProductionProcessesProp, undefined>[number];
type RowDataProp = {
  productionProcessName: string;
  code: string;
  analyticsTypes?: AnalyticsTypesProp;
};

const columns = [
  {
    title: '生产过程',
    width: 100,
    dataIndex: 'productionProcessName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用分析',
    width: 200,
    dataIndex: 'analyticsTypes',
    ellipsis: { showTitle: true },
    editable: true,
    render: (v: RowDataProp['analyticsTypes']) => RenderAnalyticsTypes(v, 2),
  },
];

const BatchOperationApp = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const operationType = urlSearchParams.get('operationType') || '1';
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<RowDataProp[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<DataListProp>([]);

  const [form] = Form.useForm();

  const title = operationType === '1' ? '添加' : '取消';

  const routes = useMemo(() => {
    return [{ name: `批量${title}应用` }];
  }, [title]);

  useEffect(() => {
    const codes = selectedData.map(({ productionBaseId, workCenterId, workProcedureId, workStationId, energyUnitId }) =>
      [productionBaseId, workCenterId, workProcedureId, workStationId, energyUnitId].filter(i => !!i).join('-')
    );

    apiV2AppAnalysisSubscriptionGetAnalysisSubscriptionByCodePost({ codes })
      .then(res => {
        if (res.list?.length) {
          let data = dataSource.map(item => ({
            productionProcessName: item.productionProcessName,
            code: item.code,
            analyticsTypes: res.list?.find(i => i.code === item.code)?.analyticsTypes,
          }));
          setDataSource(data);
        }
      })
      .finally(() => setLoading(false));
  }, [selectedData]);

  const onFinish = (values: V2AppAnalysisSubscriptionAddPostRequest) => {
    const api = operationType === '1' ? apiV2AppAnalysisSubscriptionAddPost : apiV2AppAnalysisSubscriptionBatchDelPost;

    api(values).then(() => navigate(`/energy/appAnalyticsSubscription/productionProcess`));
  };

  const handleObj = (data: DataListProp) => {
    const productionProcesses: ProductionProcessesProp = [];
    const newDataSource: RowDataProp[] = [];
    data?.forEach(
      ({
        productionBaseId,
        workCenterId,
        workProcedureId,
        workStationId,
        energyUnitId,
        title,
        productionProcessName,
      }) => {
        const code = [productionBaseId, workCenterId, workProcedureId, workStationId, energyUnitId]
          .filter(i => !!i)
          .join('-');
        let item: ProductionProcessNode = {
          productionBaseId: productionBaseId!,
          workCenterId,
          workProcedureId,
          workStationId,
          energyUnitId,
          code: code || title,
          productionProcessName,
        };

        productionProcesses.push(item);
        newDataSource.push({ productionProcessName, code });
      }
    );
    form.setFieldValue('productionProcesses', productionProcesses);
    setSelectedData(data);
    setLoading(true);
    setDataSource(newDataSource);
    setOpen(false);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`批量${title}应用`} />
      <SubContent>
        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item label={`批量${title}应用分析`} rules={[{ required: true }]} name="analyticsTypes">
                <Select placeholder="请选择" mode="multiple" options={analyticsOptions} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="选择对象" name="productionProcesses" rules={[{ required: true }]}>
                <Button type="primary" onClick={() => setOpen(true)} icon={<PlusOutlined />}>
                  选择对象
                </Button>
              </Form.Item>
            </Col>
            <Table columns={columns} loading={loading} rowKey="productionProcessName" dataSource={dataSource} />
          </Row>
          <Space size={8} className="sticky-footer" style={{ paddingLeft: 0 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button onClick={() => navigate(`/energy/appAnalyticsSubscription/productionProcess`)}>取消</Button>
          </Space>
        </Form>
      </SubContent>

      {open && (
        <AddProductionProcessModal defaultValues={selectedData} onCancel={() => setOpen(false)} onOk={handleObj} />
      )}
    </Wrapper>
  );
};

export default BatchOperationApp;
