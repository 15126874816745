export enum StatisticsPartition {
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
}

export interface ElectricityChartsRequest {
  resolution: StatisticsPartition;
  tsStart: number;
  tsEnd: number;
  deviceId: number;
  accountId: number;
}

export interface ElectricityConsumptionChart {
  ts: number;
  value: number | null;
  summitValue: number | null;
  peakValue: number | null;
  plainValue: number | null;
  valleyValue: number | null;
}
