import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Space } from 'antd';
import { Button, EllipsisSpan, Empty, Modal, Tag, useUpdate } from '@maxtropy/components';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import { V2HvacListPostResponse, apiV2HvacListPost } from '@maxtropy/device-customer-apis-v2';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { UetInfoProps } from '@/api/uet';
import FixedBottomButton from '@/components/FixedBottomButton';
import { OperationType, StationType, StationTypeDisplay, Status, StatusDisplay } from './utils';
import styles from './index.module.scss';
import { OuInfoProps } from '@/api/ou';
import TopolopyGraph from './components/TopologyGraph';
import NewStation from './components/NewStation';

export interface ModalProp {
  onCancel: () => void;
  onOk: () => void;
  ouList?: OuInfoProps[];
  operationType: OperationType;
  uetId?: string;
  stationId?: number;
}

interface Props {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const HVACStation = ({ info, isDetail }: Props) => {
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const navigate = useNavigate();

  const [edit, setEdit] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<OperationType>(OperationType.ADD);
  const [data, setData] = useState<V2HvacListPostResponse['list']>([]);
  const [stationId, setStationId] = useState<number>();
  const [updateState, updateFn] = useUpdate();

  const hasAddStation = useHasPermission(PermissionsType.B_ADDHVACSTATION); // 新建
  const hasEditStation = useHasPermission(PermissionsType.B_EDIEHVACSTATION); // 编辑
  const hasEditStationTopology = useHasPermission(PermissionsType.B_EDITHVACSTATIONTOPOLOGY); // 编辑站内拓扑
  const hasAddStationTopology = useHasPermission(PermissionsType.B_ADDHVACSTATIONTOPOLOGY); // 新建站内拓扑
  const hasNodeConfig = useHasPermission(PermissionsType.B_HVACSTATIONPROPERTYCONFIG); // 节点指标配置

  useEffect(() => {
    // 获取制备站数据
    apiV2HvacListPost({ uetId: id }).then(res => {
      setData(res.list ?? []);
    });
  }, [id, updateState]);

  const handleSubmit = () => {
    setOpenModal(false);
    updateFn();
  };

  const RenderEditStation = (stationId: number) => (
    <Button
      type="link"
      disabled={!edit}
      onClick={() => {
        setOpenModal(true);
        setOperationType(OperationType.EDIT);
        setStationId(stationId);
      }}
    >
      编辑站点
      <DoubleRightOutlined />
    </Button>
  );

  return (
    <>
      {hasAddStation && (
        <Space size={10} style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            disabled={!edit}
            icon={<PlusOutlined />}
            onClick={() => {
              setOpenModal(true);
              setOperationType(OperationType.ADD);
              setStationId(undefined);
            }}
          >
            新建暖通空调站
          </Button>
        </Space>
      )}
      {data?.map((item, index: number) => {
        const {
          name,
          code,
          ouName,
          ouCode,
          meteringCircuitName,
          energyDeviceName,
          status,
          topologyDiagram,
          id: stationId,
        } = item;
        return (
          <div className={styles.stationPanel} key={index}>
            <div className={styles.titleInfo}>
              <div>{name}</div>
              <Tag>
                编号：<span style={{ color: 'var(--mx-text-base-color)' }}>{code}</span>
              </Tag>
              <Tag border="solid" color="#52E7FF">
                {StationTypeDisplay[StationType.WATERSYSTEM]}
              </Tag>
              <Tag border="solid" color={status === Status.Enable ? 'success' : 'invalid'}>
                {StatusDisplay[status as Status]}
              </Tag>
              {hasEditStation && RenderEditStation(stationId!)}
            </div>
            <div className={styles.stationInfo}>
              <div>
                <label>运营单元编号：</label>
                <EllipsisSpan value={ouCode} />
              </div>
              <div>
                <label>运营单元名称：</label>
                <EllipsisSpan value={ouName} />
              </div>
              <div>
                <label>计量回路：</label>
                <EllipsisSpan value={meteringCircuitName} />
              </div>
              <div>
                <label>站房热量计：</label>
                <EllipsisSpan value={energyDeviceName} />
              </div>
            </div>
            <div className={styles.graphContainer}>
              {topologyDiagram ? (
                <>
                  {hasEditStationTopology && (
                    <Button
                      type="link"
                      disabled={!edit}
                      wrapStyle={{
                        position: 'absolute',
                        right: 116,
                        zIndex: 2,
                      }}
                      onClick={() =>
                        navigate(
                          `/energy/basic/uet/edit/${id}/${routerKey}/hvacTopology?stationId=${stationId}&stationName=${encodeURIComponent(
                            name!
                          )}`
                        )
                      }
                    >
                      编辑站内拓扑
                      <DoubleRightOutlined />
                    </Button>
                  )}
                  {hasNodeConfig && (
                    <Button
                      type="link"
                      disabled={!edit}
                      wrapStyle={{
                        position: 'absolute',
                        right: 0,
                        zIndex: 2,
                      }}
                      onClick={() => navigate(`/energy/basic/uet/hvacNodeList/${id}/${stationId}`)}
                    >
                      节点指标配置
                      <DoubleRightOutlined />
                    </Button>
                  )}
                  <TopolopyGraph data={topologyDiagram} />
                </>
              ) : (
                <div className={styles.empty}>
                  <Empty description={`暂未创建站内拓扑`} />
                  {hasAddStationTopology && (
                    <Button
                      type="link"
                      disabled={!edit}
                      onClick={() =>
                        navigate(
                          `/energy/basic/uet/edit/${id}/${routerKey}/hvacTopology?stationId=${stationId}&stationName=${encodeURIComponent(
                            name!
                          )}`
                        )
                      }
                    >
                      开始创建
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      {openModal && (
        <NewStation
          ouList={info?.ous}
          uetId={id}
          stationId={stationId}
          operationType={operationType}
          onCancel={() => setOpenModal(false)}
          onOk={handleSubmit}
        />
      )}
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
    </>
  );
};

export default HVACStation;
