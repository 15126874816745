import { FormInstance, Space } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, EllipsisSpan, Form, PopConfirm, Table } from '@maxtropy/components';
import { CircuitDeviceLinkFormat, CircuitDeviceLinkType, CircuitType, DeviceProps } from '@/api/circuit';
import DeviceModal, { DeviceModalRef } from './components/DeviceModal';

interface DeviceListFormProps {
  form: FormInstance;
  value?: DeviceProps[];
  onChange?: (value: DeviceProps[]) => void;
  isEdit?: boolean;
  // ouId?: number;
}

export const deviceColumns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类别',
    dataIndex: 'deviceType',
    ellipsis: { showTitle: true },
    render: (v: CircuitDeviceLinkType) => {
      let statusText = '';
      switch (v) {
        case CircuitDeviceLinkType.GAUGE:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.GAUGE];
          break;
        case CircuitDeviceLinkType.LOAD:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.LOAD];
          break;
        case CircuitDeviceLinkType.TRANSFORMER:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.TRANSFORMER];
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
];

const DeviceListForm: FC<DeviceListFormProps> = props => {
  const { form, onChange, value } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DeviceProps[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<CircuitDeviceLinkType>();
  const addDeviceRef = useRef<DeviceModalRef>(null);
  const type: CircuitType = Form.useWatch('type', form);

  useEffect(() => {
    if (value && value.length) {
      setDataSource(value);
    } else {
      setDataSource([]);
    }
  }, [value]);

  const unBind = (id: number, deviceType: CircuitDeviceLinkType) => {
    // 创建的时候进行前端解绑
    const newList = [...dataSource];
    const index = newList.findIndex(i => i.deviceId === id && i.deviceType === deviceType);
    newList.splice(index, 1);
    onChange?.(newList);
  };

  // 设置总电表
  const onSetMasterDevice = (id: number, deviceType: CircuitDeviceLinkType) => {
    // 原来的主表false
    const newList = [...dataSource];
    let findHostIndex = dataSource.findIndex(i => i.host);
    findHostIndex !== -1 &&
      newList.splice(findHostIndex, 1, {
        ...newList[findHostIndex],
        host: false,
      });
    // 更新新的主表
    const index = newList.findIndex(i => i.deviceId === id && i.deviceType === deviceType);
    index !== -1 &&
      newList.splice(index, 1, {
        ...newList[index],
        host: true,
      });
    onChange?.(newList);
  };

  const columns = {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (v: any, record: DeviceProps) => (
      <Space size={16}>
        {record.deviceType === CircuitDeviceLinkType.LOAD || record.deviceType === CircuitDeviceLinkType.TRANSFORMER ? (
          <Button type="link" onClick={() => unBind(record.deviceId!, record.deviceType!)}>
            解绑
          </Button>
        ) : (
          <PopConfirm
            title="解绑设备会导致相关回路配置解绑，请确认。"
            onConfirm={() => unBind(record.deviceId!, record.deviceType!)}
            okText="解绑"
            cancelText="取消"
          >
            <Button type="link">解绑</Button>
          </PopConfirm>
        )}
        {record.deviceType === CircuitDeviceLinkType.GAUGE && (
          <PopConfirm
            title={
              <>
                {dataSource.find(i => i.host)
                  ? `是否替代【${dataSource.find(i => i.host)!.deviceName}】总计量表设置`
                  : `是否设置该计量表为总计量电表`}
              </>
            }
            onConfirm={() => onSetMasterDevice(record.deviceId!, record.deviceType!)}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" disabled={record.host}>
              设为总计量电表
            </Button>
          </PopConfirm>
        )}
      </Space>
    ),
  };

  const disabledItems = useMemo(() => {
    return (value ?? []).map(i => i);
  }, [value]);

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = () => {
    if (addDeviceRef.current?.selectedRows.length) {
      const arr = value ? [...value] : [];
      addDeviceRef.current.selectedRows.forEach(i => {
        if (arr.find(n => n.deviceId === i.id && n.deviceType === selectedDeviceType)) {
          return;
        }
        arr.push({
          deviceId: i.id,
          deviceType: selectedDeviceType,
          host: false,
          deviceName: i.name,
          deviceCode: i.code,
          deviceTypeName: i.typeName,
          deviceTags: i.tags,
        });
      });
      onChange?.(arr);
    }
    setVisible(false);
  };

  return (
    <>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
            setSelectedDeviceType(CircuitDeviceLinkType.GAUGE);
          }}
        >
          选择计量表
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setSelectedDeviceType(CircuitDeviceLinkType.LOAD);
            setVisible(true);
          }}
        >
          选择生产负载表
        </Button>
        {(type === CircuitType.GRID_BILLING_CIRCUIT || type === CircuitType.TRANSFORMER_CIRCUIT) && (
          <Button
            type="primary"
            onClick={() => {
              setSelectedDeviceType(CircuitDeviceLinkType.TRANSFORMER);
              setVisible(true);
            }}
          >
            选择变压器
          </Button>
        )}
      </Space>
      <Table
        rowKey="id"
        sticky
        pagination={false}
        scroll={{ x: 1300, y: 500 }}
        columns={[...deviceColumns, columns]}
        dataSource={dataSource}
      />
      <DeviceModal
        ref={addDeviceRef}
        visible={visible}
        circuitform={form}
        onOk={onOk}
        onCancel={onCancel}
        selectedDeviceType={selectedDeviceType}
        disabledItems={disabledItems}
        circuitType={type}
      />
    </>
  );
};

export default DeviceListForm;
