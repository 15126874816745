import { CustomFilter, EllipsisSpan, Form, Input, Paging, Select, Table, usePaging } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, Key, useEffect, useState } from 'react';
import type { AddEnergyConsEquipmentModalContentProps } from '../type';

import styles from './index.module.scss';
import { ColumnType } from 'antd/es/table';
import { getDeviceTypeSubList } from '@/api/deviceType';
import type { DeviceType } from '@/shared/types';
import {
  V2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePostResponse,
  apiV2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePost,
  apiV2EnergyAnalysisUnitConfGroupLinkedDeviceRemovePagePost,
} from '@maxtropy/device-customer-apis-v2';

export type UnitConfGroupLinkDeviceResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePostResponse['list'],
  undefined
>[number];

interface SearchParamsProps {
  codeOrName?: string;
  typeIds?: number[]; // 产品
  mcid?: string; // 所属组织
  ouIds?: number[];
}

type FormValues = {
  codeOrName?: string;
  // 一级类目
  primaryTypeId?: number;
  // 二级类目
  secondaryTypeIds?: number[];
  mcid?: string; // 所属组织
  ouIds?: number[];
};

const columns: ColumnType<UnitConfGroupLinkDeviceResponse>[] = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备SN',
    dataIndex: 'sn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const primaryDeviceTypes = [
  {
    name: '电表',
    id: 5000,
  },
  {
    name: '积算仪',
    id: 5023,
  },
  {
    name: '流量仪表',
    id: 5031,
  },
];

const DeviceModalContent: FC<AddEnergyConsEquipmentModalContentProps> = ({
  id,
  isAdd,
  ouItems,
  mcidOptions,
  selectKeys,
  onChangeSelectKeys,
}) => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [devices, setDevices] = useState<UnitConfGroupLinkDeviceResponse[]>([]);
  const [form] = Form.useForm();
  const primaryTypeId = Form.useWatch('primaryTypeId', form);
  const [deviceTypes, setDeviceTypes] = useState<Array<DeviceType>>([]);

  useEffect(() => {
    if (primaryTypeId) {
      getDeviceTypeSubList(primaryTypeId).then(res => setDeviceTypes(res.list ?? []));
    }
  }, [primaryTypeId]);

  useEffect(() => {
    if (id && Array.isArray(ouItems) && ouItems.length > 0) {
      // 查询已绑定设备
      setLoading(true);
      if (isAdd) {
        // 查询添加能耗设备 已绑定设备
        apiV2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePost({
          ...searchParams,
          ouIds: searchParams?.ouIds ?? (ouItems ?? []).map(i => i.id as number),
          page: pageOffset,
          size: pageSize,
          unitConfGroupId: id,
        }).then(res => {
          console.log(res, '添加的能耗设备');
          setTotalCount(res.total as number);
          setDevices(res?.list as UnitConfGroupLinkDeviceResponse[]);
          setLoading(false);
        });
      } else {
        // 查询需剔除的能耗设备 已绑定设备
        apiV2EnergyAnalysisUnitConfGroupLinkedDeviceRemovePagePost({
          ...searchParams,
          ouIds: searchParams?.ouIds ?? (ouItems ?? []).map(i => i.id as number),
          page: pageOffset,
          size: pageSize,
          unitConfGroupId: id,
        }).then(res => {
          setTotalCount(res.total as number);
          setDevices(res.list as UnitConfGroupLinkDeviceResponse[]);
          setLoading(false);
        });
      }
    }
  }, [isAdd, id, ouItems, searchParams, pageOffset, pageSize, setTotalCount]);

  const onFinish = (v: FormValues) => {
    if (v?.secondaryTypeIds?.length) {
      // 选择了二级类目, 只传二级类目的 typeIds
      setSearchParams({
        codeOrName: v.codeOrName,
        mcid: v.mcid,
        ouIds: v.ouIds,
        typeIds: v.secondaryTypeIds,
      });
    } else {
      // 否则只传一级类目的 typeId
      setSearchParams({
        codeOrName: v.codeOrName,
        mcid: v.mcid,
        ouIds: v.ouIds,
        typeIds: v.primaryTypeId ? [v.primaryTypeId] : undefined,
      });
    }
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      codeOrName: undefined,
      mcid: undefined,
      typeIds: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter onFinish={onFinish} form={form} onReset={onReset} colSpan={8}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item name="primaryTypeId" label="一级类目">
        <Select
          placeholder="请选择一级类目"
          allowClear
          onClear={() => {
            setDeviceTypes([]);
          }}
          onChange={() => {
            form.setFieldValue('secondaryTypeIds', []);
          }}
          optionFilterProp="label"
          options={primaryDeviceTypes.map(t => ({
            label: t.name,
            value: t.id,
          }))}
        />
      </Form.Item>
      <Form.Item name="secondaryTypeIds" label="二级类目">
        <Select
          placeholder="请选择二级类目"
          allowClear
          mode="multiple"
          optionFilterProp="label"
          options={(deviceTypes ?? []).map(i => ({ value: i.id, label: i.name }))}
        />
      </Form.Item>
      <Form.Item name="mcid" label="所属组织">
        <Select
          placeholder="请选择"
          allowClear
          options={(mcidOptions ?? []).map(i => ({ value: i.mcid, label: i.name }))}
        />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          placeholder="请选择"
          allowClear
          mode="multiple"
          optionFilterProp="label"
          options={(ouItems ?? []).map(i => ({ value: i.id, label: i.name }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  const onSelectChange = (rowKeys: Key[]) => {
    const currentPageIds = devices.map(device => device.id);
    onChangeSelectKeys?.([...(selectKeys ?? []).filter(id => !currentPageIds.includes(id)), ...(rowKeys as number[])]);
  };

  const rowSelection = {
    hideSelectAll: true,
    getCheckboxProps: (record: UnitConfGroupLinkDeviceResponse) => ({
      disabled: !record.staffHasOuAuthority,
    }),
    // preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    selectedRowKeys: selectKeys ?? [],
  };

  return (
    <>
      {filters}
      <div className={styles.table_text}>
        <Space>
          <span style={{ fontWeight: 700, fontSize: '16px' }}>
            已选择<span className={styles.red}>{(selectKeys ?? []).length}</span>项
          </span>
          {/* <span>可选择【用能分析组】覆盖运营单元的设备</span> */}
        </Space>
        <span>
          点击前往
          <a rel="noreferrer" href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`} target="_blank">
            添加设备
          </a>
        </span>
      </div>
      <Table
        loading={loading}
        pagination={false}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={devices}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default DeviceModalContent;
