export enum EquipmentState {
  INUSE = 1,
  IDLE,
  MAINTAINED,
  DAMAGEDED,
  SCRAPPED,
}

export const EquipmentStateDisplay = {
  [EquipmentState.INUSE]: '在用',
  [EquipmentState.IDLE]: '闲置',
  [EquipmentState.MAINTAINED]: '维保',
  [EquipmentState.DAMAGEDED]: '损坏',
  [EquipmentState.SCRAPPED]: '报废',
};

export const EquipmentStateTag = {
  [EquipmentState.INUSE]: '#52C41A',
  [EquipmentState.IDLE]: '#4A90E2',
  [EquipmentState.MAINTAINED]: '#FAAD14',
  [EquipmentState.DAMAGEDED]: '#E64242',
  [EquipmentState.SCRAPPED]: 'default',
};

export interface OptionItem {
  [x: string]: any;
  label: string;
  value: number | string;
}
