import { PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ElectricityDeviceListResponse, getUetElectricityDeviceList, UetInfoProps } from '../../../../api/uet';
import { Link, useParams } from 'react-router-dom';
import { useTenantPermissions, useUpdate, Button, message, Modal } from '@maxtropy/components';
import NewPvModal from './components/NewPvModal';
import { getUetPvList, PvInfoProp, PvListProps } from '../../../../api/pv';
import { PermissionsType } from '../../../../common/permissionsConst';
import PvStation from './components/PvStation';
import FixedBottomButton from '@/components/FixedBottomButton';

interface BSAProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const PV: React.FC<BSAProps> = ({ info, isDetail }) => {
  // 获取uetid
  const { id } = useParams<{ id: string }>();
  // 光伏站显示/隐藏
  const [pvVisible, setPvVisible] = useState(false);
  // 光伏弹窗标记
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [pvList, setPvList] = useState<PvListProps[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;
  const [x, forceUpdate] = useUpdate();

  const [electricityDeviceList, setElectricityDeviceList] = useState<ElectricityDeviceListResponse[]>([]);

  useEffect(() => {
    if (!info?.mcid) return;
    getUetElectricityDeviceList({ mcid: info?.mcid }).then(res => {
      setElectricityDeviceList(res);
    });
  }, [info]);

  // 查询光伏列表
  useEffect(() => {
    getUetPvList(id!).then(res => {
      setPvList(res);
    });
  }, [id, x]);
  const pvModalBtn = (index: number) => {
    setPvVisible(true);
    setCurrentIndex(index);
  };
  // 创建/编辑光伏站基础信息
  const onPvFinish = async (v: PvInfoProp) => {
    setPvVisible(false);
    let ouItem = info?.ous.find(item => item.id === v.ouId * 1);
    v.ou = ouItem;
    if (currentIndex >= 0) {
      let currentPvStation = pvList[currentIndex]['pvStation'];
      // 选择的ou变化, 清空并网点列表中的回路, 以及电网计费回路
      if (currentPvStation.ou?.id !== v.ouId) {
        let gridConnectionPoints = pvList[currentIndex]['gridConnectionPoints'];
        gridConnectionPoints.forEach(item => {
          item.circuitId = undefined;
          item.circuitName = undefined;
        });
        pvList[currentIndex]['circuits'] = [];
      }

      currentPvStation['name'] = v.name;
      currentPvStation['installedCapacity'] = v.installedCapacity;
      currentPvStation['ou'] = ouItem;

      setPvList([...pvList]);
      return;
    }
    setPvList([...pvList, { pvStation: v, gridConnectionPoints: [], circuits: [] }]);
  };
  // 更新光伏站列表
  const updatePvList = (res: PvListProps) => {
    let currentPv = pvList.find(item => item.pvStation.id === res.pvStation.id);
    if (currentPv?.gridConnectionPoints) {
      currentPv.gridConnectionPoints = res.gridConnectionPoints;
    }
    if (currentPv?.pvStation) {
      currentPv.pvStation = res.pvStation;
    }
    setPvList([...pvList]);
  };
  // 投放状态更新
  const updateUetStatus = (index: number) => {
    pvList[index]['pvStation']['hasPutOn'] = true;
    setPvList([...pvList]);
  };

  const onDelete = (index: number) => {
    pvList.splice(index, 1);
    setPvList([...pvList]);
  };
  const onSave = () => {
    const unfinished = pvList.some(i => !i.pvStation.id);
    if (unfinished) {
      message.error('有未保存的光伏站！');
    } else {
      setEdit(false);
    }
  };
  const permission = useTenantPermissions();
  // 新增光伏站按钮
  const showAddPvBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_STATION_ADD) && !isDetail;
  }, [permission, isDetail]);

  return (
    <>
      {showAddPvBtn && (
        <Button type="primary" disabled={btnDisabled} onClick={() => pvModalBtn(-1)}>
          <PlusOutlined />
          新建光伏站
        </Button>
      )}
      {pvList.map((i, index) => {
        return (
          <PvStation
            key={index}
            circuits={i.circuits ?? []}
            pvStation={i.pvStation}
            pvIndex={index}
            gridConnectionPoints={i.gridConnectionPoints}
            isDetail={isDetail}
            electricityDeviceList={electricityDeviceList}
            onPvModal={pvModalBtn}
            onUetStatus={updateUetStatus}
            onUpdatePvList={updatePvList}
            onDelPv={onDelete}
          ></PvStation>
        );
      })}
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    forceUpdate();
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
      {/* 新建光伏站弹窗 */}
      <NewPvModal
        row={pvList[currentIndex] && pvList[currentIndex]['pvStation']}
        visible={pvVisible}
        ousList={info?.ous}
        onCancel={() => setPvVisible(false)}
        onConfirm={onPvFinish}
      />
    </>
  );
};

export default PV;
