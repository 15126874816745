import { DownstreamConsumptionsListItem } from '@/api/plustekSankey';
import { EllipsisSpan, Table } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { formatMomRatio } from '../MediumSwipper/CardItem';

interface Props {
  data: DownstreamConsumptionsListItem[];
  physicalUnitName?: string;
}

const EnergyTable: React.FC<Props> = ({ data, physicalUnitName }) => {
  const columns = useMemo(() => {
    return [
      {
        title: '下游单元',
        dataIndex: 'energyUnitName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: `用能量${isNil(physicalUnitName) ? '' : `(${physicalUnitName})`}`,
        dataIndex: 'energyConsumption',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />,
      },
      {
        title: '占比',
        dataIndex: 'proportion',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={isNil(v) ? null : `${v.toFixed(2)}%`} />,
      },
      {
        title: '用能环比',
        dataIndex: 'momRatio',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={formatMomRatio(v)} />,
      },
    ];
  }, [physicalUnitName]);

  return (
    <div
      style={{
        marginTop: 8,
      }}
    >
      <Table
        columns={columns}
        style={{
          maxHeight: 200,
          overflowY: 'auto',
        }}
        rowKey="energyUnitId"
        dataSource={data}
      />
    </div>
  );
};

export default EnergyTable;
