import { isNil } from 'lodash-es';

export type NumberLikeValue = string | number;
/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: NumberLikeValue[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

const divide = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);
/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};
/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: NumberLikeValue) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * 是否是有效的数字
 */
export const isNumber = (value: NumberLikeValue) => !isNaN(+value) && isFinite(+value);
