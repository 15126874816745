import React, { useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { FormInstance } from 'antd';
import { Form, Input, InputNumber, Select, ShowInput, Tooltip, TreeSelect } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { FieldProperty } from '@/pages/FillingRules/CreateAndEdit/types';
import {
  V2EnergyAnalysisListPostResponse,
  V2EnergyAnalysisMeterTypeListPostResponse,
  V2EnergyAnalysisUnitConfGroupListByOuPostResponse,
  apiV2EnergyAnalysisListPost,
  apiV2EnergyAnalysisMeterTypeListPost,
  apiV2EnergyAnalysisUnitConfGroupListByOuPost,
  apiV2CarbonEmissionAutoFillingRuleGetDefaultUnitPost,
} from '@maxtropy/device-customer-apis-v2';

export type UnitConfGroupItem = Exclude<V2EnergyAnalysisUnitConfGroupListByOuPostResponse['list'], undefined>[number];
export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];

const formLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};

interface TreeSelectItem {
  title: string;
  value: number;
  disabled?: boolean;
  children?: TreeSelectItem[];
}

interface LabelInValueType {
  label?: string;
  value?: number;
  halfChecked?: boolean;
  disabled?: boolean;
}

function flattenTreeData(data: UnitConfGroupItem[], result: TreeSelectItem[] = []): TreeSelectItem[] {
  for (const item of data) {
    const newItem: TreeSelectItem = {
      title: item.name as string,
      value: item.id as number,
    };
    result.push(newItem);
    if (item.childList && item.childList.length > 0) {
      flattenTreeData(item.childList, result);
    }
  }
  return result;
}

interface Props {
  name?: string;
  form: FormInstance;
  value?: FieldProperty;
}

export type V2EnergyAnalysisMeterTypeListPostResponseListItem = Exclude<
  V2EnergyAnalysisMeterTypeListPostResponse['list'],
  undefined
>[number];
const SetUpModal: React.FC<Props> = ({ name, form, value }) => {
  const currentSelectedEnergyGroupId = Form.useWatch('energyGroupId', form); // 当前选中的用能分析组id
  const currentSelectedEnergyUnitIds = Form.useWatch('energyUnitIds', form); // 当前选中的用能单元id
  const currentSelectedMeterTypeId = Form.useWatch('meterTypeId', form); // 当前选中的计量类别id
  const [energyAnalysisGroup, setEnergyAnalysisGroup] = useState<AnalysisUnit[]>(); // 用能分析组
  const [measurementCategory, setMeasurementCategory] = useState<V2EnergyAnalysisMeterTypeListPostResponseListItem[]>(
    []
  ); // 计量类别

  // 用能分析组
  const energyAnalysisGroupOptions = useMemo(() => {
    if (energyAnalysisGroup?.length) {
      return energyAnalysisGroup?.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
  }, [energyAnalysisGroup]);

  // 计量类别
  const measurementCategoryOptions = useMemo(() => {
    if (measurementCategory?.length) {
      return measurementCategory?.map(item => {
        return {
          label: item.energyName,
          value: item.id,
        };
      });
    }
  }, [measurementCategory]);

  const [energyConsumingUnitTree, setEnergyConsumingUnitTree] = useState<TreeSelectItem[]>(); // 用能单元树结构

  const onTreeNodeChange = (_value: LabelInValueType[]) => {
    form.setFieldsValue({
      meterTypeId: undefined,
      defaultPhysicalUnitName: undefined,
      requirePhysicalUnitId: undefined,
    });
    setMeasurementCategory([]);
  };

  useEffect(() => {
    Promise.all([
      apiV2EnergyAnalysisListPost({}), // 用能分析组
      // 要求单位
    ]).then(res => {
      const [energyConsumptionEvaluationAnalysisUnitListGetResponse] = res;
      setEnergyAnalysisGroup((energyConsumptionEvaluationAnalysisUnitListGetResponse.list ?? []) as AnalysisUnit[]);
    });
  }, []);

  useUpdateEffect(() => {
    if (!isNil(currentSelectedEnergyGroupId)) {
      apiV2EnergyAnalysisUnitConfGroupListByOuPost({ energyUnitId: currentSelectedEnergyGroupId }).then(res => {
        const data = flattenTreeData((res.list ?? []) as UnitConfGroupItem[]);
        setEnergyConsumingUnitTree(data);
      });
    }
  }, [currentSelectedEnergyGroupId]);

  useUpdateEffect(() => {
    if (!currentSelectedEnergyUnitIds?.length) {
      setMeasurementCategory([]);
      form.setFieldsValue({
        meterTypeId: undefined,
      });
      return;
    }
    const energyUnitIds = currentSelectedEnergyUnitIds;
    apiV2EnergyAnalysisMeterTypeListPost({ energyUnitIds }).then(res => {
      setMeasurementCategory(res?.list ?? []);
    });
  }, [currentSelectedEnergyUnitIds]);

  useEffect(() => {
    form.setFieldsValue({
      dataAcquisitionField: name,
    });

    if (!value?.autoDataSetting) return;
    const { energyGroupId, energyUnitIds, meterTypeId, transformCoefficient } = value.autoDataSetting;

    form.setFieldsValue({
      energyGroupId,
      energyUnitIds,
      meterTypeId,
      transformCoefficient,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (currentSelectedEnergyGroupId && currentSelectedMeterTypeId) {
      apiV2CarbonEmissionAutoFillingRuleGetDefaultUnitPost({
        energyGroupId: currentSelectedEnergyGroupId,
        meterTypeId: currentSelectedMeterTypeId,
      }).then(res => {
        form.setFieldsValue({
          defaultPhysicalUnitName: undefined,
          defaultPhysicalUnitId: undefined,
        });

        if (res) {
          form.setFieldsValue({
            defaultPhysicalUnitName: res.generalName,
            defaultPhysicalUnitId: res.physicalUnitId,
          });
        }
      });
    }
  }, [currentSelectedEnergyGroupId, currentSelectedMeterTypeId]);

  return (
    <Form form={form} {...formLayout} labelAlign="left">
      <Form.Item label="填报字段" name="dataAcquisitionField">
        <ShowInput />
      </Form.Item>

      <Form.Item label="用能分析组" name="energyGroupId" rules={[{ required: true, message: '请选择用能分析组' }]}>
        <Select
          placeholder="请选择"
          options={energyAnalysisGroupOptions}
          onChange={v => {
            form.resetFields();
            form.setFieldsValue({
              energyGroupId: v,
              dataAcquisitionField: name,
            });
            setMeasurementCategory([]);
            setEnergyConsumingUnitTree([]);
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <div>
            用能单元{' '}
            <Tooltip
              placement="top"
              title={
                <span>
                  选择多个用能单元
                  <br />
                  则为多个用能单元
                  <br />
                  的用能数据相加
                </span>
              }
            >
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            </Tooltip>
          </div>
        }
        name="energyUnitIds"
        rules={[{ required: true, message: '请选择用能单元' }]}
      >
        <TreeSelect
          treeData={energyConsumingUnitTree}
          treeCheckable
          treeDefaultExpandAll
          placeholder="请选择"
          onChange={onTreeNodeChange}
        />
      </Form.Item>

      <Form.Item label="计量类别" name="meterTypeId" rules={[{ required: true, message: '请选择计量类别' }]}>
        <Select placeholder="请选择" options={measurementCategoryOptions} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: '缺少数采单位',
          },
        ]}
        label="数采单位"
        name="defaultPhysicalUnitName"
      >
        <ShowInput />
      </Form.Item>
      <Form.Item
        label="单位转换系数"
        name="transformCoefficient"
        rules={[{ required: true, message: '请输入单位转换系数' }]}
        initialValue={1}
        extra="填报字段值=数采值*单位转换系数"
      >
        <InputNumber
          style={{
            width: '100%',
          }}
        />
      </Form.Item>

      <Form.Item hidden name="defaultPhysicalUnitId">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default SetUpModal;
