// 填报状态
export enum FillingStatus {
  // 状态(1:未填报 2：已填报3：已结束)
  NotFilled = 1,
  Filled = 2,
  Finished = 3,
}

export const FillingStatusMap = {
  [FillingStatus.NotFilled]: '未填报',
  [FillingStatus.Filled]: '已填报',
  [FillingStatus.Finished]: '已结束',
};

export const FillingStatusTagColorMap = {
  [FillingStatus.NotFilled]: 'warning',
  [FillingStatus.Filled]: 'success',
  [FillingStatus.Finished]: 'default',
};

export interface ListType {
  id?: number;
  /**
   * 盘查任务id
   */
  inventoryTaskId?: number;
  /**
   * 盘查任务名称
   */
  inventoryTaskName?: string;
  /**
   * 核算年
   */
  emissionYear?: number;
  /**
   * 核算日期(月)
   */
  emissionDate?: string;
  /**
   * 状态(1:未填报 2：已填报)
   */
  state?: number;
}
