import { useEffect, useState } from 'react';
/**
 *
 * @param
 * dom对象
 * @returns
 * number
 * @description
 * ResizeObserver监听元素宽度变化
 */
export function useGetElementWidth(el?: HTMLDivElement): number | undefined {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    if (!el) return;
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const { clientWidth } = entry.target;
        setWidth(clientWidth);
      });
    });
    resizeObserver.observe(el);
    return () => {
      resizeObserver.disconnect();
    };
  }, [el]);

  return width;
}
