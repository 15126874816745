import { Modal, Button, SubContent, useBreadcrumbRoutes, Wrapper, FormTitle } from '@maxtropy/components';
import { useBoolean } from 'ahooks';
import React from 'react';
import BasicEnergyManagementConfig from './components/BasicEnergyManagementConfig';
import BsaShowBtnConfig from './components/BsaShowBtnConfig';
import EvShowBtnConfig from './components/EvShowBtnConfig';
import LeanEnergyManagementConfig from './components/LeanEnergyManagementConfig';
import MajorShowBtnConfig from './components/MajorShowBtnConfig';
import PvShowBtnConfig from './components/PvShowBtnConfig';
import StaffCardModal from '@/pages/EnergyAssetsPortalConfig/components/StaffCardModal';
import styles from './index.module.scss';

const EnergyAssetsPortalConfig = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [staffCardVisible, { setTrue: setStaffCardVisibleTrue, setFalse: setStaffCardVisibleFalse }] =
    useBoolean(false);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperPadding}>
      <FormTitle title="能源资产门户配置" />
      <SubContent
        title="重点展示区域按钮配置"
        extraContent={
          <Button type="link" onClick={setStaffCardVisibleTrue}>
            员工级卡片设置
          </Button>
        }
      >
        <MajorShowBtnConfig />
      </SubContent>
      <SubContent title="光伏区域按钮配置">
        <PvShowBtnConfig />
      </SubContent>
      <SubContent title="储能区域按钮配置">
        <BsaShowBtnConfig />
      </SubContent>
      <SubContent title="充电区域按钮配置">
        <EvShowBtnConfig />
      </SubContent>
      <SubContent title="精益能管按钮配置">
        <LeanEnergyManagementConfig />
      </SubContent>
      <SubContent title="基础能管按钮配置">
        <BasicEnergyManagementConfig />
      </SubContent>

      <Modal
        destroyOnClose
        title="员工级卡片展示设置"
        open={staffCardVisible}
        width={1200}
        footer={null}
        onCancel={setStaffCardVisibleFalse}
      >
        <StaffCardModal></StaffCardModal>
      </Modal>
    </Wrapper>
  );
};

export default EnergyAssetsPortalConfig;
