import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Space } from 'antd';
import { Input, Modal, Radio, Select } from '@maxtropy/components';
import { isNil, isEmpty } from 'lodash-es';
import NumberRange from './NumberRange';
import { DataPointType, DataProperty, DataPropertyType } from '../../../../types';
import { EdgeDeviceTemplatePoint } from './index';
import { useQuery } from '../../../../utils/utils';
import { CrossDataPropertiesResponse, getPhysicalUnits } from '../../../../api/options';
import FormulaInput, { formulaValidator } from '../../../FormulaInput';
import { PointContext } from '../../contextTypes';
import FormSubmit from '../FormSubmit';
import { identifierValidatorBuilder } from '../Point/entity';

import { useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

interface FormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties: number[];
  usedIdentifier: string[];
  dataProperties: DataProperty[];
  crossDeviceDatapropertyList: CrossDataPropertiesResponse[];
}

const FormDialog: React.FC<FormDialogProps> = props => {
  const {
    row,
    onCancel,
    onOk,
    usedProperties,
    usedIdentifier,
    dataProperties: dataPropertiesAll,
    crossDeviceDatapropertyList,
  } = props;
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();
  const { isBatch, edgeGatewayId } = useContext(PointContext);

  const [dataPropertyId, setDataPropertyId] = useState<number>();
  const { setRow } = useContext(PointContext);

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties.includes(item.id)),
    [dataPropertiesAll, usedProperties]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
  }, [row]);
  useEffect(() => {}, []);

  // 先清空，在赋值，当新建或者编辑的时候
  useEffect(() => {
    form.resetFields();
    if (row) {
      form.setFieldsValue(row);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    if (shouldContinue) {
      onOk(values);
      form.resetFields();
      setRow?.({ pointType: DataPointType.VIRTUAL_POINT });
    } else {
      onOk(values);
      onCancel();
    }
  };

  // 呼出的公式下拉列表
  const formulaInputOptions = useMemo(() => {
    if (!isBatch) {
      return (crossDeviceDatapropertyList ?? []).map(item => {
        // 如果是当前设备则保持原来格式, 否则要拼上deviceId
        // 建模点
        let hasPropertyId =
          String(item.deviceId) === id ? `x_${item.datapropertyId}` : `x_${item.datapropertyId}_${item.deviceId}`;
        let hasPropertyDisplay =
          String(item.deviceId) === id ? `${item.datapropertyName}` : `${item.datapropertyName}@${item.deviceName}`;
        // 非建模点(跨设备标识符会放到datapropertyName中, 直接取即可)
        let noPropertyId = `x000${item.datapropertyName}()_${item.deviceId}`;
        let noPropertyDisplay = `${item.datapropertyName}@${item.deviceName}`;
        return {
          id: item.hasProperty ? hasPropertyId : noPropertyId,
          display: item.hasProperty ? hasPropertyDisplay : noPropertyDisplay,
          description: `${item.hasProperty ? '建模' : '非建模'}`,
        };
      });
    }
    return dataPropertiesAll.map(item => ({
      id: `x_${item.id}`,
      display: item.name,
      description: '建模',
    }));
  }, [crossDeviceDatapropertyList, dataPropertiesAll, isBatch, id]);
  return (
    <Modal
      bodyScroll={true}
      open={row?.pointType === DataPointType.VIRTUAL_POINT}
      onCancel={onCancel}
      title={`${row?.dataPropertyId ? '编辑' : '添加'}虚拟点`}
      centered={true}
      maskClosable={false}
      okText="确定"
      cancelText="取消"
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <Form form={form} {...formLayout}>
        <Form.Item noStyle name="pointType" />
        <Form.Item noStyle name="dataPropertyName" />
        <Form.Item noStyle name="physicalUnitGeneralName" />
        <Form.Item
          name="hasProperty"
          label="虚拟点类型"
          rules={[{ required: true, message: '请选择虚拟点类型' }]}
          initialValue={true}
        >
          <Radio.Group
            disabled={!isNil(row?.id)}
            onChange={() => {
              form.setFieldsValue({
                dataPropertyId: undefined,
                dataPropertyName: '',
                physicalUnitId: undefined,
                physicalUnitGeneralName: '',
                identifier: '',
              });
            }}
          >
            <Radio value={true}>建模虚拟点</Radio>
            <Radio value={false}>非建模虚拟点</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}>
          {({ getFieldsValue }) => {
            const { hasProperty } = getFieldsValue();
            return hasProperty ? (
              <>
                <Form.Item
                  name="dataPropertyId"
                  label="数据属性"
                  rules={[{ required: true, message: '请选择数据属性' }]}
                >
                  <Select
                    onChange={value => {
                      form.setFieldsValue({
                        dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                      });
                      setDataPropertyId(value as number);
                      form.setFieldsValue({
                        physicalUnitId: undefined,
                        physicalUnitGeneralName: '',
                      });
                    }}
                    placeholder="请输入/选择"
                    showSearch
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataProperties?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {dataPropertyType === DataPropertyType.YC && (
                  <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                    <Select
                      placeholder="请输入/选择"
                      showSearch
                      onSearch={value => units?.filter(item => item.generalName.includes(value))}
                      onChange={value => {
                        form.setFieldsValue({
                          physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                        });
                      }}
                    >
                      {units?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.generalName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            ) : (
              <Form.Item
                name="identifier"
                label="非建模虚拟点标识符"
                validateFirst
                required
                rules={[
                  {
                    validator: identifierValidatorBuilder(usedIdentifier),
                  },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          name="writable"
          label="是否可写"
          rules={[{ required: true, message: '请选择是否可写' }]}
          initialValue={false}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>
        {!edgeGatewayId && !isBatch && (
          <div style={{ padding: '0 0 10px 150px', color: 'var(--mx-text-desc-color)' }}>
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              添加跨设备虚拟点，需要将当前设备绑定到网关中
            </Space>
          </div>
        )}

        <Form.Item
          name="formula"
          label="公式"
          validateFirst
          rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
        >
          <FormulaInput
            identifier={usedIdentifier.map(value => ({
              // 当前的设备
              id: `x000${value}()`,
              display: value,
              description: '非建模',
            }))}
            dataProperties={formulaInputOptions}
          />
        </Form.Item>
        <Form.Item name="valueRange" label="数值合理区间">
          <NumberRange />
        </Form.Item>

        <Form.Item name="remark" label="备注">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormDialog;
