import { useEffect, useState, FC, ReactNode } from 'react';
import { useTenantPermissions } from '@maxtropy/components';
import { Spin } from 'antd';
import NotAuthorized from '../../pages/NotAuthorized';

interface AuthorizedPermissionProps {
  permissionKey: string | string[];
  children?: ReactNode;
}

const AuthorizedPermission: FC<AuthorizedPermissionProps> = props => {
  const { permissionKey, children } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [state, setState] = useState(false);

  const permission = useTenantPermissions();

  useEffect(() => {
    if (permission) {
      let state: boolean;
      if (Array.isArray(permissionKey)) {
        state = permissionKey.map(i => permission.includes(i)).some(i => i);
      } else {
        state = permission.includes(permissionKey);
      }
      setState(state);
      setLoading(false);
    }
  }, [permission, permissionKey]);

  return loading ? <Spin spinning={loading}></Spin> : state ? <>{children}</> : <NotAuthorized />;
};

export default AuthorizedPermission;
