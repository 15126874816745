import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { ChannelAppIDType } from '@/shared/types';
import { BalanceAlarmStatusType } from './balanceAlarmManagement';
import { AlrmPushStaffPageResponse } from './deviceAlarmPushStrategy';
import { PageRequest, PageResponse } from './page';

export interface ServerAlarmPushConfigOutsiderRequest {
  contactPhone: string;
  contactName: string;
  pushChannels: ChannelAppIDType[];
}

export interface ServerAlarmPushConfigStaffsRequest {
  staffId: number;
  serverAlarmPushConfigStaffId?: number;
  pushChannel: ChannelAppIDType;
}

export interface CreateServerAlarmPushConfigRequest {
  name: string;
  ouId: number;
  sceneType: number;
  serverAlarmRuleIds: number[];
  pushStaffs: ServerAlarmPushConfigStaffsRequest[];
  pushExtContacts: ServerAlarmPushConfigOutsiderRequest[];
}

export function createServerAlarmPushConfig(request: CreateServerAlarmPushConfigRequest) {
  return fetch<void>(
    `/api/v2/server-alarm/config/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface UpdateServerAlarmPushConfigRequest extends Omit<CreateServerAlarmPushConfigRequest, 'ouId'> {
  id: Key;
}

export function updateServerAlarmPushConfig(request: UpdateServerAlarmPushConfigRequest) {
  return fetch<void>(
    `/api/v2/server-alarm/config/edit`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface PushStaff extends AlrmPushStaffPageResponse {
  pushChannel: ChannelAppIDType;
  id: number;
}

export interface ServerAlarmPushConfigResponse {
  id: number;
  name: string;
  tenantName: string;
  rootMcid: string;
  ouId: number;
  ouName: string;
  sceneType: number;
  sceneName: string;
  serverAlarmRules: ServerAlarmPushRuleResponse[];
  pushStaffs: PushStaff[];
  pushExtContacts: ServerAlarmPushConfigOutsiderRequest[];
}

export function getServerAlarmPushConfig(id: Key) {
  return fetch<ServerAlarmPushConfigResponse>(
    `/api/v2/server-alarm/config/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface ServerAlarmConfigTableHeader {
  title: string;
  key: string;
  sequence: number;
}

export function getServerAlarmConfigTableHeader(type: number) {
  return fetch<{ list: ServerAlarmConfigTableHeader[] }>(
    `/api/v2/server-alarm/config/table-header`,
    {
      method: 'POST',
      body: JSON.stringify({ type }),
    },
    true
  );
}

export interface SceneTypeResponse {
  name: string;
  value: number;
}

export function getServerAlarmConfigSceneType() {
  return fetch<{ list: SceneTypeResponse[] }>(
    `/api/v2/server-alarm/config/scene/list`,
    {
      method: 'POST',
    },
    true
  );
}

export interface ServerAlarmPushRuleResponse {
  id: number;
  code: string;
  name: string;
  status: BalanceAlarmStatusType;
  [key: string]: any;
}

export interface ServerAlarmPushRuleRequest extends PageRequest {
  name?: string;
  code?: string;
  ouId: Key;
  sceneType: number;
  [key: string]: any;
}

export function getServerAlarmPushRulePage(request: ServerAlarmPushRuleRequest) {
  return fetch<PageResponse<ServerAlarmPushRuleResponse>>(
    `/api/v2/server-alarm/rule/dynamic/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}
