import {
  createNetwork,
  findNetworkDetailById,
  Gateway,
  gatewayBody,
  NetworkDetail,
  NetworkingType,
  NetworkingTypeDisPlay,
  updateNetworkById,
} from '@/api/netWorking';
import {
  Button,
  Form,
  SubContent,
  Input,
  Modal,
  Select,
  useBreadcrumbRoutes,
  Wrapper,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddGateway from './components/AddGateway';
import styles from './index.module.scss';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const networkingTypeOptions = [
  { label: NetworkingTypeDisPlay[NetworkingType.STATIC_IP], value: NetworkingType.STATIC_IP },
];

interface Props {
  isEdit?: boolean;
}

function formatGatewayIpToArray(data: Gateway[]) {
  return data.map(i => {
    return {
      ...i,
      gatewayIp: (i.gatewayIp as string).split('.'),
    };
  });
}
function formatGatewayIpToString(data: Gateway[]) {
  return data.map(i => {
    return {
      ...i,
      gatewayIp: i.gatewayIp ? (i.gatewayIp as number[]).join('.') : '',
    };
  });
}

function duplicateChecking(data: Gateway[]) {
  const duplicateId = [];
  for (let i = 0; i < data.length - 1; i++) {
    for (let j = i + 1; j < data.length; j++) {
      if ((data.at(i)?.gatewayIp as string) === (data.at(j)?.gatewayIp as string)) {
        duplicateId.push(data.at(i)?.gatewayId, data.at(j)?.gatewayId);
      }
    }
  }
  return [...new Set(duplicateId)];
}

const AddNetworking: React.FC<Props> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editDataSource, setEditDataSource] = useState<Gateway[]>([]);
  // const [editNetDetail, setEditNetDetail] = useState<NetworkDetail>();
  const [beforeGatewayList, setBeforeGatewayList] = useState<gatewayBody[]>([]);

  useEffect(() => {
    if (isEdit && id) {
      setLoading(true);
      findNetworkDetailById(id)
        .then(res => {
          if (res) {
            // setEditNetDetail(res);
            setEditDataSource(res.gatewayList);
            setBeforeGatewayList(
              res.gatewayList.map(i => {
                return {
                  gatewayId: i.gatewayId,
                  gateway_ip: i.gatewayIp as string,
                };
              })
            );
            form.setFieldsValue({
              gatewayList: formatGatewayIpToArray(res.gatewayList),
              netName: res.netName,
              netType: NetworkingTypeDisPlay[res.netType],
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id]);

  const onChange = (value: Gateway[]) => {
    form.setFieldsValue({
      gatewayList: value,
    });
  };

  const onFinish = (v: NetworkDetail) => {
    // ip未填写
    if (
      v.gatewayList.some(i => {
        return (i.gatewayIp as number[])?.some(item => isNaN(item) || item === null || item === undefined);
      })
    ) {
      return Modal.warning({
        title: '存在网关IP地址未填写，请填写后保存。',
        okText: '好的',
      });
    }

    const gatewayList = formatGatewayIpToString(v.gatewayList);

    //查重 输入框变红
    const duplicateId = duplicateChecking(gatewayList);
    if (duplicateId.length > 0) {
      form.setFieldsValue({
        gatewayList: formatGatewayIpToArray(gatewayList).map(i => {
          if (duplicateId.includes(i.gatewayId as number)) {
            return {
              ...i,
              isRepeat: true,
            };
          }
          return {
            ...i,
            isRepeat: false,
          };
        }),
      });
      return Modal.warning({
        title: '网关IP地址重复，请修改后保存。',
        okText: '好的',
      });
    }

    // 恢复输入框颜色
    form.setFieldsValue({
      gatewayList: v.gatewayList.map(i => {
        return {
          ...i,
          isRepeat: false,
        };
      }),
    });

    //编辑
    if (isEdit && id) {
      const body = {
        netId: id,
        netName: v.netName,
        beforeGatewayList,
        afterGatewayList: v.gatewayList.map(i => {
          return {
            gatewayId: i.gatewayId,
            gateway_ip: (i.gatewayIp as number[]).join('.'),
          };
        }),
      };
      updateNetworkById(body).then(() => {
        navigate('/device/networking/networkingList');
      });
      return;
    }

    // 新增
    const body = {
      netName: v.netName,
      netType: v.netType,
      gatewayList: v.gatewayList.map(i => {
        return {
          gatewayId: i.gatewayId,
          gateway_ip: (i.gatewayIp as number[]).join('.'),
        };
      }),
    };
    createNetwork(body).then(() => {
      navigate('/device/networking/networkingList');
    });
  };

  const routes = [{ name: isEdit ? '编辑组网' : '新建组网' }];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={isEdit ? '编辑组网' : '新建组网'} />
      <Spin spinning={loading}>
        <Form
          initialValues={{
            netType: 1,
          }}
          layout="vertical"
          {...formLayout}
          form={form}
          onFinish={onFinish}
        >
          <SubContent>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="netName"
                  label="组网名称"
                  rules={[
                    { required: true, message: '请输入' },
                    {
                      max: 30,
                      message: '最多输入三十个字符',
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="netType" label="组网类型" rules={[{ required: true, message: '请选择' }]}>
                  {isEdit ? <ShowInput /> : <Select placeholder="请选择" options={networkingTypeOptions} />}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                wrapperCol={{ span: 24 }}
                name="gatewayList"
                label="组网网关"
                rules={[{ required: true, message: '请添加至少一个网关' }]}
              >
                <AddGateway onChange={onChange} />
              </Form.Item>
            </Row>
            <Space size={8} style={{ marginTop: 30 }}>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
              <Button>
                <Link to={'/device/networking/networkingList'}>取消</Link>
              </Button>
            </Space>
          </SubContent>
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default AddNetworking;
