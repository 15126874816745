import { Button, InputNumber, Radio, Form, Checkbox } from '@maxtropy/components';
import { Col, FormInstance, Row } from 'antd';
import { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPriceProps,
  HasTimeOfUseElectricityPricePropsType,
  TwoPartSystemElectricityPriceType,
  TwoPartSystemElectricityPriceTypeValue,
  TwoPartSystemPowerConsumptionType,
  TwoPartSystemPowerConsumptionTypeValue,
} from '../../../../../api/electricitySettlementRules';

interface TwoPartSystemProps {
  form?: FormInstance<any>;
}

const TwoPartSystem: FC<TwoPartSystemProps> = props => {
  const { form } = props;
  const onAgreeWithMainTable = () => {
    form?.setFieldsValue({
      subMeterSummitRate: form.getFieldValue('summitRate'),
      subMeterPeakRate: form.getFieldValue('peakRate'),
      subMeterPlainRate: form.getFieldValue('plainRate'),
      subMeterValleyRate: form.getFieldValue('valleyRate'),
    });
  };

  return (
    <div>
      <Form.Item noStyle dependencies={['settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <div
                  style={{
                    backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                    padding: '20px',
                    paddingBottom: '0px',
                    fontWeight: 700,
                  }}
                >
                  {' '}
                  基本电价：
                </div>
                <Row>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  <Col
                    span={23}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      padding: '20px',
                      paddingBottom: '0px',
                    }}
                  >
                    <Form.Item
                      name="declarationType"
                      label="用电类型"
                      rules={[{ required: true, message: '请选择用电类型' }]}
                    >
                      <Radio.Group>
                        <Radio value={TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION}>
                          {
                            TwoPartSystemPowerConsumptionTypeValue[
                              TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION
                            ]
                          }
                        </Radio>
                        {/* 62迭代：根据调研：用电后报装为红豆提供的伪需求，前端隐藏 */}
                        {/* <Radio value={TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION}>
                          {
                            TwoPartSystemPowerConsumptionTypeValue[
                              TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION
                            ]
                          }
                        </Radio> */}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Row>
        <Form.Item noStyle dependencies={['declarationType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={23} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="rateType"
                      label="电度电价类型"
                      rules={[{ required: true, message: '请选择电度电价类型' }]}
                    >
                      <Radio.Group>
                        <Radio value={TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY}>
                          {TwoPartSystemElectricityPriceTypeValue[TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY]}
                        </Radio>
                        <Radio value={TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND}>
                          {
                            TwoPartSystemElectricityPriceTypeValue[
                              TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND
                            ]
                          }
                        </Radio>
                        <Radio value={TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND}>
                          {
                            TwoPartSystemElectricityPriceTypeValue[
                              TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND
                            ]
                          }
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY && (
              <>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="transformerCapacity"
                      label="变压器容量"
                      rules={[{ required: true, message: '请输入变压器容量' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999}
                        min={0}
                        step="1"
                        precision={0}
                        // stringMode
                        addonAfter="kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="unitCapacityRate"
                      label="容量单价"
                      rules={[{ required: true, message: '请输入容量单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND && (
              <>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="transformerCapacity"
                      label="变压器容量"
                      rules={[{ required: true, message: '请输入变压器容量' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999}
                        min={0}
                        step="1"
                        precision={0}
                        // stringMode
                        addonAfter="kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="declaredDemand"
                      label="申报需量"
                      rules={[{ required: true, message: '请输入申报需量' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="kW"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="unitDeclaredDemandRate"
                      label="申报需量单价"
                      rules={[{ required: true, message: '请输入申报需量单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND && (
              <>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="transformerCapacity"
                      label="变压器容量"
                      rules={[{ required: true, message: '请输入变压器容量' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999}
                        min={0}
                        step="1"
                        precision={0}
                        // stringMode
                        addonAfter="kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="unitDemandRate"
                      label="需量单价"
                      rules={[{ required: true, message: '请输入需量单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="transformerCapacity"
                      label="变压器容量"
                      rules={[{ required: true, message: '请输入变压器容量' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999}
                        min={0}
                        step="1"
                        precision={0}
                        // stringMode
                        addonAfter="kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="unitCapacityRate"
                      label="容量单价"
                      rules={[{ required: true, message: '请输入容量单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
                <Row>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item
                      name="unitDemandRate"
                      label="需量单价"
                      rules={[{ required: true, message: '请输入需量单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <div
                  style={{
                    backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                    padding: '20px',
                    fontSize: '14px',
                    fontWeight: 700,
                    paddingBottom: '0px',
                  }}
                >
                  {' '}
                  电度电价：
                </div>
                <Row>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  <Col
                    span={23}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      padding: '20px',
                      paddingBottom: '0px',
                    }}
                  >
                    <Form.Item
                      name="timeOfDayRateChecked"
                      label="时段选择"
                      rules={[{ required: true, message: '请选择时段' }]}
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.SHARPPEAK],
                            value: HasTimeOfUseElectricityPricePropsType.SHARPPEAK,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.PEAK],
                            value: HasTimeOfUseElectricityPricePropsType.PEAK,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.AVERAGE],
                            value: HasTimeOfUseElectricityPricePropsType.AVERAGE,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.VALLEY],
                            value: HasTimeOfUseElectricityPricePropsType.VALLEY,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Row>
        {/* 时段选择尖峰平谷输入框 */}
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="summitRate" label="尖峰" rules={[{ required: true, message: '请输入尖峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="peakRate" label="峰" rules={[{ required: true, message: '请输入峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="plainRate" label="平" rules={[{ required: true, message: '请输入平值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      paddingLeft: '20px',
                      fontWeight: 700,
                    }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col
                    span={1}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="valleyRate" label="谷" rules={[{ required: true, message: '请输入谷值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      paddingLeft: '20px',
                      fontWeight: 700,
                    }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>

      <Row>
        <Form.Item noStyle dependencies={['settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <Col span={8} style={{ marginTop: '20px' }}>
                  <Form.Item
                    name="hasSubMeterRateDeduct"
                    label="是否需要扣除子表费用"
                    rules={[{ required: true, message: '请选择是否需要扣除子表费用' }]}
                  >
                    <Radio.Group>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )
            );
          }}
        </Form.Item>
      </Row>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'rateType', 'declarationType']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;

          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct) {
            return (
              <>
                <div
                  style={{
                    backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                    padding: '20px',
                    paddingBottom: '0px',
                    fontWeight: 700,
                  }}
                >
                  {' '}
                  基本电价：
                </div>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col span={1}></Col>
                  <Col span={8} style={{ marginTop: '20px' }}>
                    <Form.Item
                      name="subMeterUnitDemandRate"
                      label="需量单价"
                      rules={[{ required: true, message: '请输入' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      {/* <Row>
        <Form.Item noStyle dependencies={["settlementType", 'hasSubMeterRateDeduct']}>
          {
            ({ getFieldValue }) => {
              return getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && getFieldValue('hasSubMeterRateDeduct') === true && (
                <Col span={8} style={{ marginBottom: '30px' }}>
                  <Form.Item name='isSubMeterRateConsistent' label='价格是否与主表一致'
                    rules={[
                      { required: true, message: '请选择价格是否与主表一致' },
                    ]}>
                    <Radio.Group>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )
            }
          }
        </Form.Item>
      </Row> */}

      {/* 不与主表一致的动态表单 */}

      {/* <Form.Item noStyle dependencies={["settlementType", 'hasSubMeterRateDeduct', 'isSubMeterRateConsistent', 'rateType', 'declarationType']}>
        {
          ({ getFieldValue }) => {
            let declarationType: TwoPartSystemPowerConsumptionType = getFieldValue('declarationType');
            let rateType: TwoPartSystemElectricityPriceType = getFieldValue('rateType');
            let isSettlementTypeTwoPart: boolean = getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
            let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
            let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
            // console.log(declarationType,'declarationType')
            // console.log(rateType,'rateType')
            // console.log(isSettlementTypeTwoPart,'isSettlementTypeTwoPart')
            // console.log(ishasSubMeterRateDeduct,'ishasSubMeterRateDeduct')
            // console.log(isisSubMeterRateConsistent,'isisSubMeterRateConsistent')
            if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct && isisSubMeterRateConsistent && declarationType === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && rateType === TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY) {
              return (
                <>
                  <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                    <Col span={1} ></Col>
                    <Col span={8} style={{ marginTop: '20px' }}>
                      <Form.Item name='subMeterUnitCapacityRate' label='容量单价'
                        rules={[
                          { required: true, message: '请输入' },
                        ]}>
                        <InputNumber
                          placeholder='请输入'
                          style={{ width: '100%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          precision={8}
                          // stringMode
                          addonAfter="元/kVA"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            } else if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct && isisSubMeterRateConsistent && declarationType === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && rateType === TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND) {
              return <>
                <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col span={1} ></Col>
                  <Col span={8} style={{ marginTop: '20px' }}>
                    <Form.Item name='subMeterUnitDeclaredDemandRate' label='申报需量单价'
                      rules={[
                        { required: true, message: '请输入' },
                      ]}>
                      <InputNumber
                        placeholder='请输入'
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            } else if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct && isisSubMeterRateConsistent && declarationType === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && rateType === TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND) {
              return <>
                <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col span={1} ></Col>
                  <Col span={8} style={{ marginTop: '20px' }}>
                    <Form.Item name='subMeterUnitDemandRate' label='需量单价'
                      rules={[
                        { required: true, message: '请输入' },
                      ]}>
                      <InputNumber
                        placeholder='请输入'
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            } else if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct && isisSubMeterRateConsistent && declarationType === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION) {
              return <>
                <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                <Row>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name='subMeterUnitCapacityRate' label='容量单价' rules={[
                      { required: true, message: '请输入容量单价' },
                    ]}>
                      <InputNumber
                        placeholder='请输入'
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kVA"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                </Row>
                <Row>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name='subMeterUnitDemandRate' label='需量单价' rules={[
                      { required: true, message: '请输入需量单价' },
                    ]}>
                      <InputNumber
                        placeholder='请输入'
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kW"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                </Row>
              </>
            } else {
              return <></>
            }
          }
        }
      </Form.Item> */}

      {/* 价格不与主表一致的电度电价 */}
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType', 'hasSubMeterRateDeduct']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          return (
            isSettlementTypeTwoPart &&
            ishasSubMeterRateDeduct &&
            getFieldValue('timeOfDayRateChecked').length > 0 && (
              <>
                <Row>
                  <Col
                    span={24}
                    style={{ paddingBottom: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}
                  >
                    <div style={{ padding: '20px', fontSize: '14px', fontWeight: 700, paddingBottom: '0px' }}>
                      {' '}
                      电度电价：
                    </div>
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'timeOfDayRateChecked']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct && getFieldValue('timeOfDayRateChecked').length > 0) {
            return (
              <>
                <Row style={{ paddingBottom: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col span={1}></Col>
                  <Col span={15}>
                    <Button type="primary" onClick={onAgreeWithMainTable}>
                      与主表电度电价一致
                    </Button>
                  </Col>
                </Row>
              </>
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'timeOfDayRateChecked']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct) {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(
                HasTimeOfUseElectricityPricePropsType.SHARPPEAK
              ) && (
                <>
                  <Row>
                    <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                    <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                      <Form.Item
                        name="subMeterSummitRate"
                        label="尖峰"
                        rules={[{ required: true, message: '请输入尖峰值' }]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '100%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          precision={8}
                          // stringMode
                          addonAfter="元/kWh"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  </Row>
                </>
              )
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'timeOfDayRateChecked']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct) {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) && (
                <>
                  <Row>
                    <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                    <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                      <Form.Item name="subMeterPeakRate" label="峰" rules={[{ required: true, message: '请输入峰值' }]}>
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '100%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          precision={8}
                          // stringMode
                          addonAfter="元/kWh"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  </Row>
                </>
              )
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'timeOfDayRateChecked']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct) {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) && (
                <>
                  <Row>
                    <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                    <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                      <Form.Item
                        name="subMeterPlainRate"
                        label="平"
                        rules={[{ required: true, message: '请输入平值' }]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '100%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          precision={8}
                          // stringMode
                          addonAfter="元/kWh"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  </Row>
                </>
              )
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct', 'timeOfDayRateChecked']}>
        {({ getFieldValue }) => {
          let isSettlementTypeTwoPart: boolean =
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM;
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          if (isSettlementTypeTwoPart && ishasSubMeterRateDeduct) {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) && (
                <>
                  <Row>
                    <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                    <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                      <Form.Item
                        name="subMeterValleyRate"
                        label="谷"
                        rules={[{ required: true, message: '请输入谷值' }]}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '100%' }}
                          max={99999999.99999999}
                          min={0}
                          step="0.00000001"
                          precision={8}
                          // stringMode
                          addonAfter="元/kWh"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  </Row>
                </>
              )
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Row>
        <Form.Item noStyle dependencies={['settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col span={5} style={{ marginBottom: '30px', marginTop: '30px' }}>
                    <Form.Item
                      name="hasLightingTariff"
                      label="是否有照明费用"
                      rules={[{ required: true, message: '请选择是否有照明费用' }]}
                    >
                      <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['hasLightingTariff', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('hasLightingTariff') === true &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col span={8} style={{ marginBottom: '30px', marginTop: '30px' }}>
                    <Form.Item
                      name="lightingTariffRatio"
                      label="定比"
                      rules={[{ required: true, message: '请输入定比' }]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        placeholder="请输入"
                        max={99.99}
                        min={0}
                        addonAfter="%"
                        step="0.01"
                        precision={2}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} style={{ marginBottom: '30px', marginTop: '30px' }}>
                    <Form.Item
                      name="unitLightingTariffRate"
                      label="照明电费单价"
                      rules={[{ required: true, message: '请输入照明电费单价' }]}
                    >
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={100}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default TwoPartSystem;
