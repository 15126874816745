import { CheckCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import styles from '../index.module.scss';
import { useQuery } from '@/shared/utils/utils';
import { BusinessUnitsProps, getUetUnitType } from '../../../../../api/uet';
import { Button } from '@maxtropy/components';

interface ChooseBusinessUnitProps {
  value?: number[];
  onChange?: (v?: number[]) => void;
  disabled: boolean;
  businessUnits: BusinessUnitsProps[];
}

const businessUnitsList = [
  { name: '负载/生产线', key: 'PROD_LOAD' },
  { name: '储能站', key: 'BSA' },
  { name: '光伏站', key: 'PV_STATION' },
  { name: '管线', key: 'LINE_PIPE' },
  { name: '微网电能拓扑', key: 'MICROGRID_POWER_TOPOLOGY' },
  { name: '能源介质拓扑', key: 'ENERGY_MEDIUM_TOPOLOGY' },
  { name: '充电站', key: 'CHARGING_STATION' },
  { name: '负载', key: 'LOAD' },
  { name: '备用电源', key: 'BACKUP_POWER' },
  { name: '储能站(新)', key: 'BSA_SECOND_EDITION' },
  { name: '气体制备站', key: 'GAS_PREPARATION_STATION' },
  { name: '暖通空调站', key: 'AIR_CONDITION' },
];

interface BusinessUnitsListProps {
  name: string;
  id: number;
  key: string;
  complete: boolean;
}

const ChooseBusinessUnit: React.FC<ChooseBusinessUnitProps> = ({ value, onChange, disabled, businessUnits }) => {
  const { data } = useQuery(
    useCallback(async () => {
      const res: BusinessUnitsListProps[] = [];
      await getUetUnitType().then(d => {
        d.forEach((i, index) => {
          const unit = businessUnits.find(item => item.businessUnitType === index);
          res.push({
            ...businessUnitsList.filter(f => f.key === i)[0],
            id: index,
            complete: unit?.complete || false,
          });
        });
      });
      return res;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  const isChecked = useCallback(
    (id: number) => {
      return (value ?? []).includes(id);
    },
    [value]
  );

  const onClick = (id: number, checked: boolean) => {
    const newValue = [...(value ?? [])];
    if (checked) {
      newValue.push(id);
    } else {
      const index = newValue.findIndex(i => i === id);
      if (index !== -1) {
        newValue.splice(index, 1);
      }
    }
    onChange && onChange(newValue);
  };

  return (
    <Space>
      {data?.map(i => {
        const checked = isChecked(i.id);
        const className = classNames(styles.col, { [styles.checked]: checked, [styles.disabled]: disabled });
        return (
          <Button
            onClick={() => {
              onClick(i.id, !checked);
            }}
            key={i.id}
            disabled={i.complete}
            // disabled={disabled}
            className={className}
            icon={checked && <CheckCircleOutlined />}
          >
            {i.name}
          </Button>
        );
      })}
    </Space>
  );
};

export default ChooseBusinessUnit;
