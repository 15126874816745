import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Space } from 'antd';
import { useBreadcrumbRoutes, Wrapper, Button, FormTitle, SubContent } from '@maxtropy/components';
import PropertyDetail from './PropertyDetail';
import AttributeDetail from './AttributeDetail';
import EdgeTemplateDetail from './EdgeTemplateDetail';

import styles from './index.module.scss';

const routes = [{ name: '模板详情' }];

const DeviceDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="模板详情" />
      {id && (
        <>
          <PropertyDetail id={id} />
          <AttributeDetail id={id} />
          <EdgeTemplateDetail id={id} />
        </>
      )}
      <SubContent>
        <Space className="sticky-footer-left">
          <Button
            onClick={() => {
              navigate(`/device/manage/batch?tabs=${tabs}`);
            }}
          >
            返回
          </Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default DeviceDetail;
