import { Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from '../api/page';

export interface Labels {
  [key: string]: string;
}

export interface LabelValue<V extends string | number> {
  label: string;
  value: V;
}

export interface NestedLabelValue<V extends string | number> {
  [key: string]: LabelValue<V>[];
}

export enum FieldType {
  ENUM = 1,
  MULTIPLE = 2,
  RANGE = 3,
  FIXED = 4,
}

export enum InputType {
  NUMBER = 1,
  TEXT = 2,
}

export enum NumberType {
  INTEGER = 1,
  DECIMAL = 2,
}

export enum DataPropertyType {
  YC = 1,
  ENUM,
  STRING,
  YK,
  YT,
}

export const DataPropertyTypeDisplay = {
  [DataPropertyType.YC]: '遥测值',
  [DataPropertyType.ENUM]: '枚举值',
  [DataPropertyType.STRING]: '字符串',
  [DataPropertyType.YK]: '遥控值',
  [DataPropertyType.YT]: '遥调值',
};

export interface DeviceType {
  id: number;
  key: string;
  name: string;
  children?: DeviceType[];
  deviceTypes?: DeviceType[];
}

export type MinMaxConstraintValue = {
  min: number;
  max: number;
};
export type OptionsConstraintValue = string[];
export type ConstraintValue = MinMaxConstraintValue | OptionsConstraintValue;

export interface DeviceAttribute {
  id: number;
  name: string;
  serialNumber: string;
  deviceFields: AttributeField[];
}

export interface AttributeField {
  id: number;
  businessPhysicalQuantityId?: number;
  name: string;
  type: FieldType;
  inputType?: InputType;
  numberType?: NumberType;
  digitalAccuracy?: number;
  constraintValue?: ConstraintValue;
  physicalUnits?: PhysicalUnit[];
}

export interface PhysicalUnit {
  id: number;
  generalName: string;
  chineseName: string;
  k: number;
  b: number;
  isBasic: boolean;
  // 是否是默认单位
  default?: boolean;
}

export interface RangeFieldValue {
  min?: number;
  max?: number;
}

export type FieldValue = {
  unitId?: number;
  value?: RangeFieldValue | string | number;
};

export enum IotProtocolType {
  ESTUN = 1,
  MOCKINGBIRD = 2,
  // 朗新
  LONGSHINE = 3,
}

// 是否具备报警数据
export enum AlarmDataStatus {
  NO = 0,
  YES = 1,
}

export const AlarmDataStatusDisplay = {
  [AlarmDataStatus.NO]: '否',
  [AlarmDataStatus.YES]: '是',
};

// 报警数据
export enum AlarmDataType {
  // 触发值
  TRIGGER = 1,
  // 恢复值
  RECOVER = 2,
  // 实时值
  REALTIME = 3,
}

export const AlarmDataTypeDisplay = {
  [AlarmDataType.TRIGGER]: '触发值',
  [AlarmDataType.RECOVER]: '恢复值',
  [AlarmDataType.REALTIME]: '实时值',
};

export const iotProtocolOptions: Array<[IotProtocolType, string]> = [
  [IotProtocolType.ESTUN, 'OPC-UA'],
  [IotProtocolType.MOCKINGBIRD, 'Mockingbird'],
];

export const IotProtocolTypeDisplay: Record<number, string> = {
  [IotProtocolType.ESTUN]: 'OPC-UA',
  [IotProtocolType.MOCKINGBIRD]: 'Mockingbird',
};

export enum ActionType {
  NONE = 1,
  ADD,
  EDIT,
  DELETE,
}

export const ActionTypeDisplay = {
  [ActionType.NONE]: '无操作',
  [ActionType.ADD]: '新增',
  [ActionType.EDIT]: '编辑',
  [ActionType.DELETE]: '删除',
};
export const ActionTypeColors = {
  [ActionType.NONE]: { background: '#EEEEEE', color: 'rgba(0, 0, 0, 0.35)' },
  [ActionType.ADD]: { color: '#1890FF', background: '#E6F7FF' },
  [ActionType.EDIT]: { color: '#53C41C', background: '#EBF4E3' },
  [ActionType.DELETE]: { color: '#53C41C', background: '#EBF4E3' },
};

export enum EdgeDeviceTemplateStatus {
  USED = 1,
  UNUSED,
  ABOLISHED,
}

export const EdgeDeviceTemplateStatusDisplay = {
  [EdgeDeviceTemplateStatus.USED]: '在用',
  [EdgeDeviceTemplateStatus.UNUSED]: '禁用',
  [EdgeDeviceTemplateStatus.ABOLISHED]: '作废',
};

export enum UploadTypeEnum {
  TIMING = 1,
  CHANGE,
}

export const UploadTypeEnumDisplay = {
  [UploadTypeEnum.TIMING]: '定时上传',
  [UploadTypeEnum.CHANGE]: '变化上传',
};

export interface EdgeDeviceTemplate {
  latestVersionId: number;
  id: number;
  name: string;
  serialNumber: number;
  iotProtocol: IotProtocolType;
  status: EdgeDeviceTemplateStatus;
  latestVersion: number;
  usedByDevice: number;
  updateTime: string;
  updateByUsername: string;
}

export interface EdgeDeviceTemplateDetail extends EdgeDeviceTemplate {
  deviceTypeId: number;
  deviceTypeName: string;
  deviceModelName: string;
  remark: string;
  version: number;
  versionRemark: string;
  driveType?: DriveType;
  physicalModelId: Key;
  modelNo: Key;
  manufacturerName: string;
  manufacturerId: Key;
}

export interface EdgeDeviceTemplateVersion {
  id: number;
  version: number;
  status: EdgeDeviceTemplateStatus;
  remark: string;
  usedByDevice: number;
  updateTime: string;
  updateByUsername: string;
}

export interface EdgeDeviceTemplatePoint {
  id: number;
  dataPropertyId: number;
  physicalUnitId: number;
  uploadType: UploadTypeEnum;
  iotProtocol: IotProtocolType;
  physicalUnitGeneralName: string;
  physicalUnitChineseName: string;
  dataPropertyName: string;
}

export interface EdgeDeviceTemplatePointDetail extends EdgeDeviceTemplatePoint {
  edgeTime?: string;
  edgeData?: string | number;
}

export interface DataProperty {
  id: number;
  name: string;
  /**
   * 1.遥测2.枚举值.字符串4.遥控5.遥调
   */
  type: DataPropertyType;
  /**
   * 类型：1.累计值;2.瞬时值
   */
  measurementType: number;
}

export interface DeviceModel {
  name: string;
  id: number;
}

export enum DeviceStatus {
  // 在用
  USING,
  // 维修
  REPAIRING,
  // 停用
  DEACTIVATING,
  // 调出
  CALLOUT,
  // 售出
  SOLD,
  // 报废
  SCRAPPED,
  // 作废
  INVALID,
}

export const DeviceStatusDisplay = {
  [DeviceStatus.USING]: '在用',
  [DeviceStatus.REPAIRING]: '维修',
  [DeviceStatus.DEACTIVATING]: '停用',
  [DeviceStatus.CALLOUT]: '调出',
  [DeviceStatus.SOLD]: '售出',
  [DeviceStatus.SCRAPPED]: '报废',
  [DeviceStatus.INVALID]: '作废',
};

export enum Operator {
  OPS,
  TENANT,
}

export const OperatorDisplay = {
  [Operator.OPS]: '运管',
  [Operator.TENANT]: '租户',
};

export enum GatewayNetStatus {
  // 离线
  OFFLINE = 0,
  // 在线
  ONLINE = 1,
}

export const GatewayNetStatusDisplay = {
  [GatewayNetStatus.OFFLINE]: '离线',
  [GatewayNetStatus.ONLINE]: '在线',
};

export enum GatewayStatus {
  // 作废
  DISABLE = 0,
  // 启用
  ENABLE = 1,
}

export const GatewayStatusDisplay = {
  [GatewayStatus.DISABLE]: '作废',
  [GatewayStatus.ENABLE]: '启用',
};

export enum BatchDeviceTemplateStatus {
  ADD = 0,
  USING = 1,
}

export const BatchDeviceTemplateStatusDisplay = {
  [BatchDeviceTemplateStatus.ADD]: '新建',
  [BatchDeviceTemplateStatus.USING]: '已使用',
};

export enum AlarmStatus {
  DISABLE = 1,
  ENABLE = 0,
}

export const AlarmStatusDisplay = {
  [AlarmStatus.DISABLE]: '禁用',
  [AlarmStatus.ENABLE]: '启用',
};

export enum AlarmChannel {
  // 极熵
  MAXTROPY = 1,
  // 朗新
  LONGSHINE = 2,
  //三方服务端报警
  THIRD = 3,
}

export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

export const AlarmLevelColorDisplay = {
  [AlarmLevel.HIGHEST]: 'error',
  [AlarmLevel.HIGH]: 'warning',
  [AlarmLevel.MIDDLE]: 'info',
  [AlarmLevel.LOW]: 'success',
  [AlarmLevel.LOWEST]: '#ABD335',
};

export enum AlarmType {
  SERVER,
  MARGIN,
}

export const AlarmTypeDisplay = {
  [AlarmType.SERVER]: '服务端报警',
  [AlarmType.MARGIN]: '边缘报警',
};

// 网关报警方式
export enum GatewayAlarmType {
  // 边缘报警
  EDGE = 1,
  // 云端代理边缘报警
  CLOUD = 2,
}

export const GatewayAlarmTypeDisplay = {
  [GatewayAlarmType.EDGE]: '边缘报警',
  [GatewayAlarmType.CLOUD]: '云端代理边缘报警',
};

// 网关报警逻辑
export enum GateAlarmLogic {
  OFFLINE = 1,
}

export const GateAlarmLogicDisplay = {
  [GateAlarmLogic.OFFLINE]: '网关离线报警',
};

// 报警冷却方式
export enum GatewayAlarmCoolDownMode {
  // 距报警时间
  TIME_TILL_ALARM = 1,
  // 距恢复时间
  TIME_TILL_COOL_DOWN = 2,
}

// 报警冷却方式和时间展示
export const GatewayAlarmCoolDownModeDisplay = {
  [GatewayAlarmCoolDownMode.TIME_TILL_ALARM]: '距报警时间',
  [GatewayAlarmCoolDownMode.TIME_TILL_COOL_DOWN]: '距恢复时间',
};

// 报警记录的报警状态
export enum AlarmState {
  RECOVER,
  UNRECOVERED,
}

export const AlarmStateDisplay = {
  [AlarmState.RECOVER]: '已恢复',
  [AlarmState.UNRECOVERED]: '未恢复',
};
export const AlarmStateColorDisplay = {
  [AlarmState.RECOVER]: 'success',
  [AlarmState.UNRECOVERED]: 'error',
};
export interface OUPageRequest extends PageRequest {
  nameOrSerialNum?: string;
  mcid: string;
}

export interface OU {
  id: number;
  serialNumber: string;
  name: string;
  ouTypeName: string;
  tenantName: string;
  manageNum: number;
  staffNum: number;
  manageUpdateByUsername: string;
  manageUpdateTime: string;
  userType: number | undefined;
}

export type OUPageResponse = PageResponse<OU>;

export enum TenantStatus {
  OPENACCOUNT = 1,
  NOACCOUNT = 0,
}

export const TenantStatusDisplay = {
  [TenantStatus.OPENACCOUNT]: '开户',
  [TenantStatus.NOACCOUNT]: '未开户',
};

export enum DriveType {
  MODBUS_RTU = 0,
  MODBUS_TCP = 1,
  DLT645_1997 = 16,
  DLT645_2007 = 17,
  IEC104 = 64,
}

export const DriveTypeLabels = {
  [DriveType.MODBUS_RTU]: 'Modbus_RTU',
  [DriveType.MODBUS_TCP]: 'Modbus_TCP',
  [DriveType.DLT645_1997]: 'DL/T645-1997',
  [DriveType.DLT645_2007]: 'DL/T645-2007',
  [DriveType.IEC104]: 'IEC104',
};

export enum DataPointType {
  // 采集点
  BASE_POINT = 1,
  // 虚拟点
  VIRTUAL_POINT = 2,
  // 静态点
  STATIC_POINT,
}

export enum FormulaMode {
  // 波动量
  FLUCTUATION = 0,
  // 增量
  INCREMENT = 1,
}

export enum DataPointFunction {
  // 启停点
  OPEN = 'OPEN',
}

export enum OBJECT_TYPE {
  COILSTATUS = 1,
  INPUTSTATUS = 2,
  SAVEREGISTER = 3,
  INPUTREGISTER = 4,
}

export enum ADDR_OFFSET {
  ZERO = 0,
  ONE = 1,
}

export enum LENGTH {
  ONE = 1,
  TWO = 2,
  FOUR = 4,
}

export enum FREQUENCY {
  LOW = 0,
  HIGH = 1,
}

export enum CRC_ORDER {
  BIG = 0,
  SMALL = 1,
}

export enum BIT_MODE {
  NULL,
  BIT,
  BYTE,
}

export enum BYTE_NUM {
  LOWBYTE,
  HIGHBYTE,
}

export enum BIT_NUM {
  BIT_0,
  BIT_1,
  BIT_2,
  BIT_3,
  BIT_4,
  BIT_5,
  BIT_6,
  BIT_7,
  BIT_8,
  BIT_9,
  BIT_10,
  BIT_11,
  BIT_12,
  BIT_13,
  BIT_14,
  BIT_15,
}

export type HANDLE_MODE = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum VALUE_TYPE {
  BOOLEAN = 0,
  COMPLEMENTINTERGER = 1,
  UNSIGNEDINTERGER = 2,
  FLOAT = 3,
  DOBULE = 4,
  INTERGER = 5,
}

export interface BasePoint {
  id?: number;
  pointType?: DataPointType;
  uploadType?: UploadTypeEnum;
  iotProtocol?: IotProtocolType;
  hasProperty?: boolean;
  writable?: boolean;
  dataPropertyId: number;
  physicalUnitId: number;
  dataPropertyName?: string;
  physicalUnitGeneralName?: string;
  physicalUnitChineseName?: string;
  identifier?: string;
  edgeDevicePointId?: string;
  enumMapping?: { [key: string]: any };
}

export interface ModbusPointBase {
  valueCeiling?: number;
  valueFloor?: number;
  objectType?: OBJECT_TYPE;
  addr_offset?: ADDR_OFFSET;
  starting?: number;
  length?: LENGTH;
  frequency?: FREQUENCY;
  crc_order?: CRC_ORDER;
  valueType?: VALUE_TYPE;
  opnot_flag?: boolean;
  bitMode?: BIT_MODE;
  bitNum?: BIT_NUM;
  byteNum?: BYTE_NUM;
  k?: number;
  b?: number;
  handleMode?: HANDLE_MODE;
  enumMapping?: { [key: string]: any };
  isEnumMapping?: boolean;
}

export interface ModbusPoint extends BasePoint {
  parameters: ModbusPointBase;
}

export interface DLT645Point extends BasePoint {
  parameters: {
    dataId?: string;
    k?: number;
    b?: number;
    valueFloor?: number;
    valueCeiling?: number;
  };
}

export interface IEC104Point extends BasePoint {
  parameters: {
    commonAddress?: string;
    informationAddress?: string;
    k?: number;
    b?: number;
    valueFloor?: number;
    valueCeiling?: number;
  };
}

export interface ESTUNPoint {
  id: number;
  dataPropertyId: number;
  physicalUnitId: number;
  uploadType: UploadTypeEnum;
  iotProtocol: IotProtocolType;
  physicalUnitGeneralName: string;
  physicalUnitChineseName: string;
  dataPropertyName: string;
  identifier?: string;
  edgeDevicePointId?: number;
}

// 数据点类型
export type PointType = ModbusPoint | DLT645Point | VirtualPoint | ESTUNPoint | StaticPoint;

export interface StaticPoint extends BasePoint {
  computationalLogic: number;
  remark: string;
}

export interface VirtualPoint extends BasePoint {
  remark: string;
  formula: string;
  parameters: {
    key: string;
    valueCeiling: number;
    valueFloor: number;
  };
}

export enum SerialPort {
  COM1 = 1,
  COM2,
  COM3,
  COM4,
}

export const SerialPortLabels = {
  [SerialPort.COM1]: 'COM1',
  [SerialPort.COM2]: 'COM2',
  [SerialPort.COM3]: 'COM3',
  [SerialPort.COM4]: 'COM4',
};

export const BuadRateOptions: LabelValue<number>[] = [
  { label: '110', value: 110 },
  { label: '300', value: 300 },
  { label: '600', value: 600 },
  { label: '1200', value: 1200 },
  { label: '2400', value: 2400 },
  { label: '4800', value: 4800 },
  { label: '9600', value: 9600 },
  { label: '14400', value: 14400 },
  { label: '19200', value: 19200 },
  { label: '38400', value: 38400 },
  { label: '57600', value: 57600 },
  { label: '115200', value: 115200 },
];

export enum CheckType {
  NULL = 0,
  ODD = 1,
  EVEN = 2,
  MARK = 3,
  SPACE = 4,
}

export const CheckTypeLabels = {
  [CheckType.NULL]: '无校验',
  [CheckType.ODD]: '奇校验',
  [CheckType.EVEN]: '偶校验',
  [CheckType.MARK]: 'Mark',
  [CheckType.SPACE]: 'Space',
};

export const DataBitOptions: LabelValue<number>[] = [
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
];

export const StopBitOptions: LabelValue<number>[] = [
  { label: '1', value: 1 },
  { label: '1.5', value: 3 },
  { label: '2', value: 2 },
];

export enum CrcOrder {
  BIG_END,
  SMALL_END,
}

export const CrcOrderLabels = {
  [CrcOrder.BIG_END]: '大端',
  [CrcOrder.SMALL_END]: '小端',
};

export const AddrOffsetOptions: LabelValue<number>[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
];

export enum AlarmPushStatus {
  DISABLE = 0,
  ENABLE = 1,
  DELETE = 2,
}

export const AlarmPushStatusDisplay = {
  [AlarmPushStatus.DISABLE]: '禁用',
  [AlarmPushStatus.ENABLE]: '启用',
  [AlarmPushStatus.DELETE]: '作废',
};

export enum PushThirdApp {
  WEB_SOCKET = 1,
  SMS = 2,
  VOICE = 3,
}

export type ChannelAppIDType = PushThirdApp | number;
export interface ChannelApp {
  channelId: ChannelAppIDType;
  channelName: string;
  code: string;
}

// export interface ThirdApp {
//   thirdAppId: PushThirdApp;
//   thirdAppName: string;
// }

export enum ComputationalLogic {
  CONNECTION = 2,
  POWER_SUPPLY = 4,
}

export const computationalLogicDisplay = {
  [ComputationalLogic.CONNECTION]: '设备连接状态',
  [ComputationalLogic.POWER_SUPPLY]: '网关供电状态',
};

// 报表策略统计类型

export enum ReportStrategyStatisticalTypeEnum {
  ACCUMULATION = 1,
  INSTANTANEOUS = 2,
}

export const ReportStrategyStatisticalTypeEnumDisplay = {
  [ReportStrategyStatisticalTypeEnum.ACCUMULATION]: '累积量',
  [ReportStrategyStatisticalTypeEnum.INSTANTANEOUS]: '瞬时量',
};

export enum ReportStrategyStatisticalCycleEnum {
  QUARTER = 1,
  HOUR = 2,
  DAY = 3,
  MONTH,
}

export const ReportStrategyStatisticalCycleEnumEnumDisplay = {
  [ReportStrategyStatisticalCycleEnum.QUARTER]: '15分钟',
  [ReportStrategyStatisticalCycleEnum.HOUR]: '1小时',
  [ReportStrategyStatisticalCycleEnum.DAY]: '1日',
  [ReportStrategyStatisticalCycleEnum.MONTH]: '1月',
};

export enum StrategyStatus {
  START,
  CUSTOMIZING,
  EXECUTING,
  COMPLETED,
  UNUSED,
  USED,
  TO_USE,
}

export const StrategyStatusDisplay = {
  [StrategyStatus.START]: '开始',
  [StrategyStatus.CUSTOMIZING]: '定制中',
  [StrategyStatus.EXECUTING]: '执行中',
  [StrategyStatus.COMPLETED]: '已完成',
  [StrategyStatus.UNUSED]: '停用',
  [StrategyStatus.USED]: '启用',
  [StrategyStatus.TO_USE]: '待启用',
};

export enum PricedisplayType {
  HALF = 0,
  HOUR = 1,
}

export const PricedisplayTypeDisplay = {
  [PricedisplayType.HALF]: '半点',
  [PricedisplayType.HOUR]: '整点',
};

export enum TimeOfUseTariffType {
  // 尖
  SUMMIT = 1,
  // 峰
  PEAK,
  // 平
  PLAIN,
  // 谷
  VALLEY,
}

export const TimeOfUseTariffTypeDisplay = {
  [TimeOfUseTariffType.SUMMIT]: '尖峰',
  [TimeOfUseTariffType.PEAK]: '高峰',
  [TimeOfUseTariffType.PLAIN]: '平段',
  [TimeOfUseTariffType.VALLEY]: '低谷',
};

export const TimeOfUseTariffTypeColor = {
  [TimeOfUseTariffType.SUMMIT]: '#FF6D2C',
  [TimeOfUseTariffType.PEAK]: '#CE90D1',
  [TimeOfUseTariffType.PLAIN]: '#0DB6D9',
  [TimeOfUseTariffType.VALLEY]: '#63BC7F',
};

export interface EPriceTime {
  type: TimeOfUseTariffType;
  intervalStart: number;
  intervalEnd: number;
}

export enum PricingMethod {
  // 变压器容量
  TRANSFORMERCAPACITY = 1,
  // 约定需量
  AGREEDDEMAND = 2,
  // 实际需量
  ACTUALDEMAND = 3,
}

export const PricingMethodDisplay = {
  [PricingMethod.TRANSFORMERCAPACITY]: '按变压器容量',
  [PricingMethod.AGREEDDEMAND]: '按约定需量',
  [PricingMethod.ACTUALDEMAND]: '按实际需量',
};

export enum TransactionMeansType {
  STATEGRID = 1,
  PROXY = 2,
  DIRECT = 3,
}

export const TransactionMeansTypeDisplay = {
  [TransactionMeansType.STATEGRID]: '国网购电',
  [TransactionMeansType.PROXY]: '代理购电',
  [TransactionMeansType.DIRECT]: '直购电',
};

export enum TaxPointType {
  ONE = 1,
  THREE = 2,
  THIRTEEN = 3,
}

export const TaxPointTypeDisplay = {
  [TaxPointType.ONE]: '1%',
  [TaxPointType.THREE]: '3%',
  [TaxPointType.THIRTEEN]: '13%',
};

export enum SettlementCycleType {
  DAY = 1,
  MONTH = 2,
}

export const SettlementCycleTypeDisplay = {
  [SettlementCycleType.DAY]: '按日',
  [SettlementCycleType.MONTH]: '按月',
};

export enum WorkDivisionEnum {
  METERREADER = 1,
  AUDITOR = 2,
  ACCOUNTANT = 3,
  REVIEWER = 4,
}

export const WorkDivisionEnumDisplay = {
  [WorkDivisionEnum.METERREADER]: '抄表员',
  [WorkDivisionEnum.AUDITOR]: '审核员',
  [WorkDivisionEnum.ACCOUNTANT]: '结算员',
  [WorkDivisionEnum.REVIEWER]: '复核员',
};

export enum PvDispersionRatioEnum {
  GENERAL,
  POOR,
  ABNORMAL,
}

export const PvDispersionRatioEnumDisplay = {
  [PvDispersionRatioEnum.GENERAL]: '一般',
  [PvDispersionRatioEnum.POOR]: '较差',
  [PvDispersionRatioEnum.ABNORMAL]: '异常',
};
