import { FC, useMemo } from 'react';
import { dateFormat, dateLimit, FormValue } from '../../utils';
import classNames from 'classnames';

import styles from './index.module.scss';
import { ReportStrategyStatisticalCycleEnum, ReportStrategyStatisticalTypeEnum } from '@/shared/types';

import dayjs, { Dayjs } from 'dayjs';

import { Col, Row } from 'antd';
import { ReportStrategyExecuteCycle } from '../../Create';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
export interface ReportStrategyPreviewProps {
  value?: FormValue;
}

const minColumns = 5;

const ReportStrategyPreview: FC<ReportStrategyPreviewProps> = props => {
  const { value } = props;
  const isAccumulation = useMemo(() => {
    return value?.measurementType === ReportStrategyStatisticalTypeEnum.ACCUMULATION;
  }, [value]);

  const timeList = useMemo(() => {
    if (value) {
      // 15分钟
      if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.QUARTER) {
        const [start, end] = value.time as [Dayjs, Dayjs];
        return Array.from(
          {
            length: dayjs(end).diff(start, 'minute') / 15 + 1,
          },
          (_, i) =>
            start
              .clone()
              .add(i * 15, 'minutes')
              .format('YYYY-MM-DD HH:mm')
        );
      }
      // 1小时
      else if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
        // 按月
        if (value.reportType === ReportStrategyExecuteCycle.MONTH) {
          const [start, end] = value.time as [Dayjs, Dayjs];
          return Array.from(
            {
              length:
                ((end.month() - start.month()) * start.daysInMonth() + end.date() - start.date() + 1) *
                (end.hour() - start.hour() + 1),
            },
            (_, i) => {
              let hourIndex = i % (end.hour() - start.hour() + 1);
              let dayIndex = Math.floor(i / (end.hour() - start.hour() + 1));

              return start.clone().add(dayIndex, 'days').add(hourIndex, 'hours').minute(0).format('YYYY-MM-DD HH:mm');
            }
          );
        }
        // 按周
        if (value.reportType === ReportStrategyExecuteCycle.WEEK) {
          const [start, end] = value.time as [Dayjs, Dayjs];
          return Array.from(
            {
              length: dayjs(end).diff(start, 'hour') + 1,
            },
            (_, i) => start.clone().add(i, 'hour').format('YYYY-MM-DD HH:mm')
          );
        }
        // 按日或单次
        const [start, end] = value.time as [Dayjs, Dayjs];
        return Array.from(
          {
            length: (end.date() - start.date()) * 24 + (end.hour() - start.hour()) + 1,
          },
          (_, i) => start.clone().add(i, 'hours').format('YYYY-MM-DD HH:mm')
        );
      }
      // 1月
      else if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.MONTH) {
        const [start, end] = value.time as [Dayjs, Dayjs];
        return Array.from(
          {
            length: (end.year() - start.year()) * 12 + 1 + end.month() - start.month(),
          },
          (_, i) => start.clone().add(i, 'month').format('YYYY-MM')
        );
      }

      const startTime = !Array.isArray(value.time)
        ? (value.time as Dayjs).clone().startOf('day').valueOf()
        : (value.time as RangeValue)![0]!.clone().startOf('day').valueOf();

      const endTime = !Array.isArray(value.time)
        ? (value.time as Dayjs).clone().endOf('day').valueOf()
        : (value.time as RangeValue)![1]!.clone().endOf('day').valueOf();

      const count = Math.ceil(
        (endTime - startTime) / dateLimit[value.statisticalResolution as ReportStrategyStatisticalCycleEnum]
      );

      const dateList = new Array(count <= minColumns ? minColumns : count);
      for (let i = 0; i < dateList.length; i++) {
        if (i < count) {
          dateList[i] = dayjs(
            startTime + i * dateLimit[value.statisticalResolution as ReportStrategyStatisticalCycleEnum]
          ).format(dateFormat[value.statisticalResolution as ReportStrategyStatisticalCycleEnum]);
        } else {
          dateList[i] = '';
        }
      }
      return dateList;
    }
    return [];
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <Row className={classNames(styles.row)} wrap={false}>
        <Col flex="none" className={classNames(styles.col, styles.content, styles.desc)} style={{ width: 180 }}>
          设备编号
        </Col>
        <Col flex="none" className={classNames(styles.col, styles.content, styles.desc)}>
          设备名称
        </Col>
        <Col flex="none" className={classNames(styles.col, styles.content, styles.desc)}>
          数据点
        </Col>
        <Col flex="none" className={classNames(styles.col, styles.content, styles.desc)}>
          单位
        </Col>
        {isAccumulation && (
          <Col flex="none" className={classNames(styles.col, styles.content, styles.desc)}>
            总计
          </Col>
        )}
        {timeList.map((i, index) => (
          <Col flex="none" key={index} className={classNames(styles.col, styles.content, styles.desc)}>
            {i ?? ''}
          </Col>
        ))}
      </Row>
      <div className={styles.pointWrapper}>
        {(value?.dataPropertyLinkList ?? []).map(device => (
          <Row key={device.deviceId} className={classNames(styles.row)} wrap={false}>
            <Col flex="none" className={classNames(styles.col, styles.desc)} style={{ width: 180 }}>
              {device.deviceCode}
            </Col>
            <Col flex="none" className={classNames(styles.col, styles.desc)}>
              {device.deviceName}
            </Col>
            <Col flex="0 0 auto">
              {device.dataPropertyList?.length ? (
                (device.dataPropertyList ?? []).map(point => (
                  <Row key={`${device.deviceId}-${point.id}`} className={classNames(styles.row)} wrap={false}>
                    <Col flex="none" className={classNames(styles.col, styles.content)}>
                      {point.name}
                    </Col>
                    <Col flex="none" className={classNames(styles.col, styles.content)}>
                      {point.physicalUnitName ?? '--'}
                    </Col>
                    {isAccumulation && (
                      <Col flex="none" className={classNames(styles.col, styles.content)}>
                        --
                      </Col>
                    )}
                    {timeList.map((i, index) => (
                      <Col
                        flex="none"
                        key={`${device.deviceId}-${point.id}-${index}`}
                        className={classNames(styles.col, styles.content)}
                      >
                        {i ? '--' : ''}
                      </Col>
                    ))}
                  </Row>
                ))
              ) : (
                <Row key={`${device.deviceId}-no`} className={classNames(styles.row)} wrap={false}>
                  <Col flex="none" className={classNames(styles.col, styles.content)}>
                    --
                  </Col>
                  <Col flex="none" className={classNames(styles.col, styles.content)}>
                    --
                  </Col>
                  {isAccumulation && (
                    <Col flex="none" className={classNames(styles.col, styles.content)}>
                      --
                    </Col>
                  )}
                  {timeList.map((i, index) => (
                    <Col
                      flex="none"
                      key={`${device.deviceId}-no-${index}`}
                      className={classNames(styles.col, styles.content)}
                    >
                      {i ? '--' : ''}
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default ReportStrategyPreview;
