import React, { FC, useEffect, useState, Key } from 'react';
import { Space } from 'antd';
import { ActionType } from '../index';
import DeviceModal from './DeviceModal';
import styles from './index.module.scss';
import { Button, Modal } from '@maxtropy/components';

export interface SelectDevicesProps {
  value?: Key[];
  onChange?: (value?: Key[]) => void;
  deviceType?: number[];
  templateId?: number;
  templateVersion?: number;
  actionType?: ActionType;
  deviceTypeName?: string;
  physicalModelId?: number;
}

const SelectDevices: FC<SelectDevicesProps> = ({
  value,
  onChange,
  deviceType,
  templateId,
  templateVersion,
  actionType,
  deviceTypeName,
  physicalModelId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<Key[]>();

  useEffect(() => {
    setDataSource(value);
  }, [value]);

  const onAdd = () => {
    if (deviceType === undefined) {
      Modal.warning({
        title: '请先选择所属类目',
      });
      return;
    }
    if (templateId === undefined) {
      Modal.warning({
        title: '请先选择固定属性模板',
      });
      return;
    }
    setVisible(true);
  };

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space>
        {value && (
          <div>
            <span>已选择</span>
            <span className={styles.count}>{value?.length || 0}</span>
            <span>个设备</span>
          </div>
        )}
        <Button type="link" style={{ padding: 0 }} onClick={onAdd}>
          {value === undefined ? '请' : '重新'}选择
        </Button>
      </Space>
      <Modal
        className={styles.modal}
        title="设备选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <DeviceModal
          deviceType={deviceType}
          templateId={templateId}
          actionType={actionType}
          physicalModelId={physicalModelId}
          deviceTypeName={deviceTypeName}
          templateVersion={templateVersion}
          onChange={setDataSource}
          value={dataSource}
        />
      </Modal>
    </>
  );
};

export default SelectDevices;
