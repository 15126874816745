import { CustomFilter, EllipsisSpan, Form, Key, Modal, Paging, Select, Table, usePaging } from '@maxtropy/components';
import { Space } from 'antd';
import { Ref, useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.scss';
import { BsaOrPVType } from '../../../../../api/energyConsumption';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  V2EnergyAnalysisUnitConfGroupGridSearchPostResponse,
  V2EnergyAnalysisUnitConfGroupPvSearchPagePostResponse,
  apiV2EnergyAnalysisUnitConfGroupGridSearchPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedArrayAllPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost,
  apiV2EnergyAnalysisUnitConfGroupPvSearchPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { OuInfoProps } from '../AddChildOrEditModal';

export interface PVModalProps {
  unitConfGroupId?: string;
  unitId: string;
  // isPVStation?: boolean; // 是否是光伏站
  visible?: boolean;
  onOk?: () => void;
  onCancel: () => void;
  // ouItems: OuItem[];
}

export interface PVModalRef {
  selectedRowKeys: Array<number> | undefined;
  selectedRows: { id: number; pvOuId: number }[];
}

interface SearchParamsProps {
  pvStationIds?: number[]; // 光伏站名称
  ouIds?: number[]; // ou
}

export type PVGroupGridResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupGridSearchPostResponse['list'],
  undefined
>[number];

export type PVStationResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupPvSearchPagePostResponse['list'],
  undefined
>[number];

const PVModal = React.forwardRef((props: PVModalProps, ref: Ref<PVModalRef>) => {
  const { unitConfGroupId, unitId, visible, onOk, onCancel } = props;
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<{ id: number; pvOuId: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [devices, setDevices] = useState<PVGroupGridResponse[]>([]);
  const [form] = Form.useForm();
  // const mcid = current?.tenant?.mcid;
  const [ouItems, setOuItems] = useState<OuInfoProps[]>([]);
  const [pvStationList, setPvStationList] = useState<PVStationResponse[]>([]);

  // 查询 ou
  useEffect(() => {
    if (unitConfGroupId) {
      apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost({
        energyUnitId: unitConfGroupId,
      }).then(res => {
        setOuItems(res?.list ?? []);
      });
    }
  }, [unitConfGroupId]);

  useEffect(() => {
    if (unitConfGroupId) {
      // 查询选中的pv
      apiV2EnergyAnalysisUnitConfGroupLinkedArrayAllPost({
        energyUnitGroupId: unitConfGroupId,
        arrayType: BsaOrPVType.PV,
      }).then(res => {
        if (res) {
          setSelectedRows((res.list ?? []).map(r => ({ id: r.id as number, pvOuId: r.ouId as number })));
        }
      });
    }
  }, [unitConfGroupId]);

  // 所属光伏站查询
  useEffect(() => {
    apiV2EnergyAnalysisUnitConfGroupPvSearchPagePost({
      energyUnitId: unitId,
      energyUnitGroupId: unitConfGroupId!,
      page: 1,
      size: 50,
    }).then(res => {
      setPvStationList((res.list ?? []) as PVStationResponse[]);
    });
  }, [unitConfGroupId, unitId]);

  useEffect(() => {
    if (unitConfGroupId && ouItems.length) {
      // 查询 PV 设备分页
      setLoading(true);
      apiV2EnergyAnalysisUnitConfGroupGridSearchPost({
        pvStationIds: searchParams?.pvStationIds!,
        ouIds: searchParams?.ouIds!,
        page: pageOffset,
        size: pageSize,
        unitConfGroupId: Number(unitConfGroupId!),
        unitId: Number(unitId),
        // tenantMcid: mcid,
      }).then(res => {
        setTotalCount(res.total as number);
        setDevices((res.list ?? []) as PVGroupGridResponse[]);
        setLoading(false);
      });
    }
  }, [unitConfGroupId, searchParams, pageOffset, pageSize, setTotalCount, ouItems, unitId]);

  const columns = [
    {
      title: `光伏站名称`,
      dataIndex: 'pvName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: `并网点名称`,
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: `所属运营单元`,
      dataIndex: 'pvOuName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '光伏计量表',
      dataIndex: 'meterName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const onFinish = (v: SearchParamsProps) => {
    setSearchParams({ ...v });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      pvStationIds: undefined,
      ouIds: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter onFinish={v => onFinish(v as SearchParamsProps)} colSpan={8} form={form} onReset={onReset}>
      <Form.Item name="pvStationIds" label={'所属光伏站'}>
        <Select
          mode="multiple"
          optionFilterProp="label"
          style={{ width: '100%' }}
          placeholder="请选择"
          options={pvStationList?.map(p => ({ label: p.name, value: p.id }))}
        />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          optionFilterProp="label"
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouItems.map(o => ({ label: o.name, value: o.id }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  // 将 ref 传递到外部
  useImperativeHandle(ref, () => ({ selectedRowKeys, selectedRows }));

  const onSelectChange = (rowKeys: Key[], rows: any[]) => {
    // const data = rows.filter(item => !!item).filter(item => !selectedRowKeys.includes(item.id));
    setSelectedRows(rows.map(r => ({ id: r.id, pvOuId: r.pvOuId })));
    setSelectedRowKeys(rowKeys as number[]);
  };

  return (
    <Modal
      open={visible}
      title={
        <Space align="center">
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: 'var(--heading-color)',
              marginRight: 10,
            }}
          >
            添加光伏站并网点
          </span>
          <Space align="center">
            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
            <span
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: 'ragb(255, 255, 255, 0.65)',
              }}
            >
              无运营单元权限不允许修改
            </span>
          </Space>
        </Space>
      }
      width={1200}
      destroyOnClose
      onOk={onOk}
      onCancel={() => {
        onCancel();
      }}
    >
      {filters}
      <div className={styles.table_text}>
        <span>
          已选择<span className={styles.red}>{selectedRows?.length}</span>项
        </span>
      </div>
      <Table
        loading={loading}
        sticky
        rowSelection={{
          hideSelectAll: false,
          preserveSelectedRowKeys: true,
          onChange: onSelectChange,
          selectedRowKeys: selectedRows.map(item => item.id),
          getCheckboxProps: (record: PVGroupGridResponse) => {
            // 如果无权限禁用该选择框
            return {
              disabled: record.staffHasOuAuthority ? false : true,
            };
          },
        }}
        dataSource={devices}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
});

export default PVModal;
