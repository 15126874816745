import { useEffect, useState } from 'react';
import { Space, Empty as antEmpty } from 'antd';
import { Button, message, PopConfirm, Empty, EllipsisSpan, Table } from '@maxtropy/components';
import { isEmpty } from 'lodash-es';
import {
  apiV2OutputProductBomTreePost,
  apiV2OutputProductBomDeletePost,
  V2OutputProductBomTreePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import EditBom from './EditBom';
import { BasicData } from '.';
import ImportBom from './ImportBom';
import { AccessType, AccessTypeEnum } from './type';
import { useUnit } from './hook';

const buildColumns = [
  {
    title: '物料编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
  {
    title: '物料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
];

interface BomTableProps {
  openAdd: (val: BomItem) => void;
  id: number;
  update: number;
  basicData: BasicData;
  updateFn: () => void;
}

export type BomItem = Exclude<V2OutputProductBomTreePostResponse['list'], undefined>[number];

export type EditBomData = BasicData & BomItem;

export const formatTreeData = (list: BomItem[]): BomItem[] => {
  return list.map(item => {
    const { bomTreeVoV2s, ...rest } = item;
    return {
      ...rest,
      bomTreeVoV2s: isEmpty(bomTreeVoV2s) ? undefined : formatTreeData(bomTreeVoV2s!),
    };
  });
};

const BomTable = (props: BomTableProps) => {
  const { update, updateFn, id, basicData, openAdd } = props;
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [importVisible, setImportVisible] = useState<boolean>(false);
  const [editBomData, setEditBomData] = useState<EditBomData>({});

  const [dataSource, setDataSource] = useState<BomItem[]>([]);
  const [getUnitName] = useUnit();

  const getBomData = () => {
    apiV2OutputProductBomTreePost({ id: Number(id) }).then(res => {
      setDataSource(formatTreeData(res?.list ?? []));
    });
  };

  useEffect(() => {
    getBomData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const onDel = (id: number) => {
    apiV2OutputProductBomDeletePost({ id }).then(res => {
      message.success('删除成功');
      updateFn();
    });
  };

  const onEdit = (record: BomItem) => {
    setEditBomData({ ...record, ...basicData });
    setEditVisible(true);
  };

  const columns = [
    ...buildColumns,
    {
      title: '数量/单位',
      dataIndex: 'quantity',
      ellipsis: { showTitle: true },
      render: (text: string, record: any) =>
        text ? <EllipsisSpan value={text + getUnitName(record.unitTypeCode, record.unitCode)} /> : '--',
    },
    {
      title: '单位物料重量',
      dataIndex: 'unitWeight',
      ellipsis: { showTitle: true },
      render: (text: string) => (text ? <EllipsisSpan value={text + 'kg'} /> : '--'),
    },
    {
      title: '获取方式',
      dataIndex: 'accessType',
      ellipsis: { showTitle: true },
      render: (text: AccessTypeEnum) => <EllipsisSpan value={AccessType[text]} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: BomItem) => (
        <Space size={16}>
          <Button type="link" onClick={() => openAdd(record)}>
            添加子物料
          </Button>
          <Button type="link" onClick={() => onEdit(record)}>
            编辑
          </Button>
          <PopConfirm title="您是否删除此物料，物料删除后如有子物料，也一并删除。" onConfirm={() => onDel(record.id!)}>
            <Button type="link">删除</Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        dataSource={dataSource}
        expandable={{
          childrenColumnName: 'bomTreeVoV2s',
        }}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={antEmpty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  本产品还未维护 BOM 信息,请
                  <Button type="link" onClick={() => setImportVisible(true)}>
                    导入BOM
                  </Button>
                </span>
              }
            />
          ),
        }}
      />
      {editVisible && <EditBom data={editBomData} setVisible={setEditVisible} updateFn={updateFn} />}
      {importVisible && (
        <ImportBom data={basicData} id={Number(id)} setVisible={setImportVisible} updateFn={updateFn} />
      )}
    </>
  );
};

export default BomTable;
