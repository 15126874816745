import React, { Key, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '../../utils/utils';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Select,
  Modal,
  FormTitle,
  SubContent,
  Form,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import InfoDisplay from '@/shared/components/EdgeDeviceDataMinng/InfoDislpay';
import DataAcquisitionForm, { DataAcquisitionFormRef } from '../../components/DataAcquisitionForm';
import { useDaqFormState } from '../../components/DataAcquisitionForm/common/useDaqFormState';
import styles from './index.module.scss';
import { getDeviceProperty } from '../../api/device';
import { getPointsByTemplateVersionId } from '../../api/template';
import { getProtocol } from '../../api/protocol';
import EdgeDevicePointInfo, { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { emptyEdgeDevice, queryEdgeDeviceInfo, updateEstunDevice, updateMockingbirdDevice } from '../../api/edgeDevice';
import { convertDataToEntities, DataNode, formatToDataNode, getDeviceType } from '@/shared/utils/cascade-util';
import { ActionType, IotProtocolType } from '@/shared/types';
import { omit, pick } from 'lodash-es';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { halfFormItemLayout } from '@/shared/components/DataAcquisitionForm/common';
import { MockingbirdFormValues } from '@/shared/components/DataAcquisitionForm/types';
import useIsGateway from '@/shared/hooks/useIsGateway';
import { getDeviceTypeTree } from '@/api/deviceType';

interface DataMiningProps {
  isEdit?: boolean;
}

const routes = [{ name: '配置数采信息' }];

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const EdgeTemplateDataCollection: React.FC<DataMiningProps> = ({ isEdit = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const state = useDaqFormState();
  const [edgeGatewayId, setEdgeGatewayId] = useState<Key>();
  const {
    iotProtocolType,
    setIotProtocolType,
    templateVersionId,
    setTemplateVersionId,
    setDeviceType,
    driveType,
    setDriveType,
    setObjectModalType,
  } = state;
  const daqFormRef: MutableRefObject<DataAcquisitionFormRef | null> = useRef(null);
  const cachePointsData = useRef<Record<string, EdgeDeviceTemplatePoint[]>>();
  const queryParams = useQueryParams();
  const [dataSource, setDataSource] = useState<EdgeDeviceTemplatePoint[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  console.log('datasource', dataSource);

  const { data: deviceInfo } = useQuery(useCallback(() => getDeviceProperty(id!), [id]));
  const { isGateway } = useIsGateway(deviceInfo?.typeId);

  const { data: detail, isLoading } = useQuery(
    useCallback(() => (isEdit ? queryEdgeDeviceInfo(id!) : Promise.resolve(undefined)), [id, isEdit])
  );
  const deviceTypes = useAsync(getDeviceTypeTree);
  const iotProtocolData = useAsync(getProtocol);
  const { data: points, isLoading: pointsLoading } = useQuery(
    useCallback(async () => {
      if (iotProtocolType && templateVersionId) {
        if (cachePointsData.current?.[`${iotProtocolType}-${templateVersionId}`]) {
          return cachePointsData.current?.[`${iotProtocolType}-${templateVersionId}`];
        }
        const points = await getPointsByTemplateVersionId(templateVersionId);
        if (isEdit) {
          // 编辑状态下检查当前的 templateVersionId 是否和替换后的一样, 不一样为新增, 一样继续用之前的
          if (detail?.edgeDeviceTemplateVersionId === templateVersionId) {
            return points.map(item => ({ ...item, actionType: ActionType.NONE }));
          } else {
            return points.map(item => ({ ...item, actionType: ActionType.ADD }));
          }
        } else {
          // 新增状态下更换模板 points 状态全部为 ADD
          return points.map(item => ({ ...item, actionType: ActionType.ADD }));
        }
      }
      return [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateVersionId])
  );

  const [submitting, setSubmitting] = useState(false);

  const canSubmit = useMemo(
    () => dataSource?.filter(item => item.actionType !== ActionType.DELETE).length,
    [dataSource]
  );

  useEffect(() => {
    if (deviceInfo && deviceTypes) {
      const tree: DataNode[] = formatToDataNode(deviceTypes.tree as any);
      const entities = convertDataToEntities(tree);
      const deviceType = getDeviceType(entities, deviceInfo.typeId!);
      setDeviceType(deviceType);
      setObjectModalType(deviceInfo.physicalModelId ? (deviceInfo.physicalModelId as number) : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceInfo, deviceTypes]);

  useEffect(() => {
    setDataSource(points ?? []);
  }, [points]);

  useEffect(() => {
    if (detail) {
      setEdgeGatewayId(detail.edgeGatewayId);
      form.setFieldsValue({ iotProtocol: detail.iotProtocol });
      setIotProtocolType(detail.iotProtocol);
      setTemplateVersionId(detail.edgeDeviceTemplateVersionId);
      daqFormRef.current?.form?.setFieldsValue(detail);
      if (detail.iotProtocol === IotProtocolType.MOCKINGBIRD) {
        setDriveType(detail.driveType);

        daqFormRef.current?.form?.setFieldsValue(
          pick(detail, [
            'driveType',
            'baudRate',
            'checkType',
            'dataBit',
            'stopBit',
            'samplingTimeout',
            'crcOrder',
            'addrOffset',
            'groupWords',
            'stationNum',
            'groupLength',
            'leadByte',
            'serialPort',
            'ip',
            'port',
            'samplingInterval',
            'samplingRetry',
            'address',
            'edgeGatewayId',
          ])
        );
      }
      cachePointsData.current = {
        [`${detail.iotProtocol}-${detail.edgeDeviceTemplateVersionId}`]: detail.points.map(item => ({
          ...item,
          actionType: ActionType.NONE,
        })),
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const submit = () => {
    Promise.all([form.validateFields(), daqFormRef.current?.form?.validateFields()]).then(() => {
      setSubmitting(true);
      if (iotProtocolType === IotProtocolType.ESTUN) {
        updateEstunDevice({
          deviceIds: [Number(id)],
          iotProtocol: iotProtocolType!,
          edgeDeviceTemplateVersionId: templateVersionId!,
        })
          .then(() => {
            navigate('/device/manage/device');
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else if (iotProtocolType === IotProtocolType.MOCKINGBIRD) {
        const values = daqFormRef.current?.form?.getFieldsValue() as MockingbirdFormValues;
        const params = omit(values, [
          'name',
          'version',
          'versionRemark',
          'deviceTypeName',
          'deviceModelName',
          'iotProtocol',
          'remark',
        ]);
        const deletePointIds = dataSource.filter(p => p.actionType === ActionType.DELETE).map(p => p.id!.toString());
        const addPoints = dataSource.filter(p => p.actionType === ActionType.ADD);
        const editPoints = dataSource.filter(p => p.actionType === ActionType.EDIT);

        if (id) {
          updateMockingbirdDevice({
            deviceIds: [+id],
            edgeDeviceTemplateVersionId: templateVersionId!,
            ...params,
            iotProtocol: iotProtocolType!,
            typeId: deviceInfo?.typeId!,
            points: [...addPoints, ...editPoints],
            deletePointIds: deletePointIds,
          })
            .then(() => {
              navigate('/device/manage/device');
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }
    });
  };

  const cancel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate(`/device/manage/device`);
      },
    });
  };

  const empty = () =>
    Modal.confirm({
      title: '确定清空数采信息？',
      icon: <ExclamationCircleOutlined />,
      content: (
        <span>
          <span style={{ color: '#f00' }}>清空数采信息，设备会从网关解绑，报警规则也会清空，但历史数据保留，</span>
          你还要继续吗？
        </span>
      ),
      okText: '确定',
      onOk() {
        return emptyEdgeDevice(id!)
          .then(() => navigate(`/device/manage/device`))
          .catch(error => console.error(error));
      },
    });

  const handleIotProtocolTypeChange = (value: number) => {
    if (isEdit) {
      form.setFieldsValue({ iotProtocol: iotProtocolType });
      Modal.confirm({
        title: `设备已经绑定【${iotProtocolData?.list?.find(item => item.id === iotProtocolType)?.name}】的网关`,
        icon: <ExclamationCircleOutlined />,
        content: (
          <span>
            <span style={{ color: '#f00' }}>更换物联层协议，设备会从网关解绑，</span>你还要继续吗？
          </span>
        ),
        okText: '确定',
        onOk() {
          form.setFieldsValue({ iotProtocol: value });
          setIotProtocolType(value);
          setTemplateVersionId(undefined);
          daqFormRef.current?.form?.resetFields();
        },
      });
    } else {
      setIotProtocolType(value);
      setTemplateVersionId(undefined);
      daqFormRef.current?.form?.resetFields();
    }
  };

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Spin spinning={isLoading}>
        <FormTitle title="配置数采信息"></FormTitle>
        <SubContent title="设备信息">
          <InfoDisplay data={deviceInfo} />
        </SubContent>
        <SubContent
          title={
            <div>
              <span>数采信息</span>
              {isEdit && (
                <Button type="link" onClick={empty}>
                  清空数采信息
                </Button>
              )}
            </div>
          }
        >
          <Form form={form} labelAlign="left">
            <Row>
              <Col span={12}>
                <Form.Item
                  {...halfFormItemLayout}
                  name="iotProtocol"
                  rules={[{ required: true, message: '请选择协议选择' }]}
                  label="协议选择"
                >
                  <Select onChange={handleIotProtocolTypeChange}>
                    {iotProtocolData?.list?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <>
              <DataAcquisitionForm state={state} ref={daqFormRef} />
              {iotProtocolType === IotProtocolType.ESTUN && templateVersionId && (
                <div className={styles.table}>
                  <EdgeDevicePointInfo
                    edgeGatewayId={edgeGatewayId}
                    loading={pointsLoading}
                    dataSource={dataSource}
                    type={iotProtocolType!}
                  />
                </div>
              )}
              {iotProtocolType === IotProtocolType.MOCKINGBIRD &&
                templateVersionId &&
                (driveType !== undefined || isGateway) && (
                  <div className={styles.table}>
                    <EdgeDevicePointInfo
                      loading={pointsLoading}
                      dataSource={dataSource}
                      setDataSource={setDataSource}
                      type={iotProtocolType}
                      editable
                      edgeGatewayId={edgeGatewayId}
                      info={{
                        iotProtocol: iotProtocolType,
                        deviceTypeId: deviceInfo?.typeId,
                        driveType: driveType,
                        physicalModelId: deviceInfo?.physicalModelId,
                      }}
                      promptSlot={
                        <span style={{ marginLeft: 'auto', color: '#FF4D4F' }}>
                          <InfoCircleOutlined style={{ marginRight: 5 }} />
                          提示：新增或编辑设备的数采信息和数采点的信息后，需要前往网关管理页面，进行配置下发后，才可以生效。
                        </span>
                      }
                    />
                  </div>
                )}
            </>
            <Space size={8} className="sticky-footer-left">
              {!isEdit && !!queryParams.get('hasPrevious') && (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/device/manage/device/${id}/attribute/create`);
                  }}
                >
                  上一步
                </Button>
              )}
              <Button htmlType="submit" type="primary" disabled={!canSubmit} loading={submitting} onClick={submit}>
                完成
              </Button>
              <Button onClick={cancel}>取消</Button>
            </Space>
          </Form>
        </SubContent>
      </Spin>
    </Wrapper>
  );
};

export default EdgeTemplateDataCollection;
