import {
  Checkbox,
  Radio,
  Select,
  Tabs,
  Tag,
  Button,
  FormContent,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import TotalSummaryMeterTable from '../TotalSummaryMeterTable';
import TotalEnergyMediumTable from '../TotalEnergyMediumTable';
import styles from './index.module.scss';
import TimeShareEleTable from '../TimeShareEleTable';
import {
  EnergyMediumListItem,
  MeterPeriodDisplay,
  MeterTaskBaseInfo,
  StatusEnum,
  getEnergyMediumList,
  getMeterReadingObjectLevelList,
  getMeterTaskBaseInfo,
} from '@/api/universeMeterQuery';
import {
  MeterPeriodBackgroundColor,
  MeterPeriodBorderColor,
  MeterPeriodColor,
  formatTime,
  onDownload,
} from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash';
import HierarchySummaryMeterTable from '../HierarchySummaryMeterTable';
import HierarchyEnergyMediumTable from '../HierarchyEnergyMediumTable';
import TotalTimeShareEleTable from '../TotalTimeShareEleTable';

// 改变抄表项目按钮类型
export enum MeterProjectChangeButtonType {
  CHANGE_COPY_NUM = 'CHANGE_COPY_NUM',
  BATCH_ADJUST = 'BATCH_ADJUST',
  APPORTION_INPUT = 'APPORTION_INPUT',
}

export enum SingleOrDetail {
  SINGLE = 1, // 简略
  DETAIL = 2, // 详细
}

export enum ViewType {
  TOTAL = 1, // 总视图
  HIERARCHY = 2, // 分层视图
}

const routes = [{ name: '抄表详情' }];

const MeterProjectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tabKey, setTabKey] = useState<string>('汇总报表');
  const [tabName, setTabName] = useState<string>('汇总报表');
  const [isSummaryMeter, setIsSummaryMeter] = useState<boolean>(true);
  const [isElectricity, setIsElectricity] = useState<boolean>(false);
  const [changeButtonType, setChangeButtonType] = useState<MeterProjectChangeButtonType>();
  const [toggleSingleOrDetail, setToggleSingleOrDetail] = useState<SingleOrDetail>(SingleOrDetail.SINGLE);
  const [energyMediumList, setEnergyMediumList] = useState<EnergyMediumListItem[]>([]); // 抄表能源介质列表
  const [basicInfo, setBasicInfo] = useState<MeterTaskBaseInfo>(); // 抄表基础信息
  const [unitName, setUnitName] = useState<string>(''); // 单位名称
  const hasExport = useHasPermission(PermissionsType.B_GLOBALMETERREADINGEXPORT); // 导出报表
  const hasCopyUpdate = useHasPermission(PermissionsType.B_GLOBALMETERREADINGVALUEUPDATE); // 抄见值变更
  const hasBatchAdjust = useHasPermission(PermissionsType.B_GLOBALMETERREADINGBATCHADJUST); // 批量调整
  const hasShare = useHasPermission(PermissionsType.B_GLOBALMETERREADINGAPPORTIONMENTENTRY); // 分摊录入
  const [viewType, setViewType] = useState<ViewType>(ViewType.TOTAL); // 视图类型
  const [isHierarchicalMeterReading, setIsHierarchicalMeterReading] = useState<boolean>(false); // 是否多层级抄表对象
  const [level, setLevel] = useState<number>(); // 层级
  const [levelList, setLevelList] = useState<number[]>([]); // 层级列表
  const [isAllExpand, setIsAllExpand] = useState<boolean>(false); // 是否全部展开
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      // 获取抄表能源介质列表
      getEnergyMediumList(id).then(res => {
        if (res && res.list && res.list.length > 0) {
          setEnergyMediumList(res.list);
        }
      });
      // 获取抄表基础信息
      getMeterTaskBaseInfo(id).then(res => {
        setBasicInfo(res);
        setIsHierarchicalMeterReading(res.hierarchicalMeterReading === 1);
      });
    }
  }, [id]);

  useEffect(() => {
    if (id && tabKey && viewType === ViewType.HIERARCHY) {
      getMeterReadingObjectLevelList(id, tabKey === '汇总报表' ? null : Number(tabKey)).then(res => {
        setLevelList(res.levels);
        const newLevels = res.levels ?? [];
        if (!level || !newLevels.includes(level)) {
          setLevel(res.levels.length > 0 ? res.levels[0] : undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tabKey, viewType]);

  const tabItems = useMemo(() => {
    const res = [
      {
        key: '汇总报表',
        label: '汇总报表',
      },
    ];
    if (id && energyMediumList.length > 0) {
      energyMediumList.forEach(i => {
        res.push({ key: i.mediumId.toString(), label: i.mediumName });
      });
    }
    return res;
  }, [energyMediumList, id]);

  const hierarchyOptions = useMemo(() => {
    return (levelList ?? []).map(i => ({ label: `${i}级`, value: i }));
  }, [levelList]);

  const onTabChange = (key: string) => {
    setIsSummaryMeter(key === '汇总报表');
    setIsElectricity(energyMediumList.find(i => i.mediumId.toString() === key)?.mediumName === '电能');
    setTabKey(key);
    if (energyMediumList.length > 0) {
      setUnitName(energyMediumList.find(i => i.mediumId.toString() === key)?.physicalUnitName ?? '');
    }
    setTabName(tabItems.find(i => i.key === key)?.label ?? '');
  };

  // 汇总报表-分层视图
  const hierarchySummaryMeterTable = (
    <HierarchySummaryMeterTable
      level={level}
      setChangeButtonType={setChangeButtonType}
      changeButtonType={changeButtonType}
    />
  );

  // 汇总报表-总视图
  const totalSummaryMeterTable = (
    <TotalSummaryMeterTable
      isAllExpand={isAllExpand}
      setIsAllExpand={setIsAllExpand}
      setChangeButtonType={setChangeButtonType}
      changeButtonType={changeButtonType}
    />
  );

  // 工质报表-分层视图
  const hierarchyEnergyMediumTable = (
    <HierarchyEnergyMediumTable
      level={level}
      unitName={unitName}
      basicInfo={basicInfo}
      mediumId={Number(tabKey)}
      setChangeButtonType={setChangeButtonType}
      changeButtonType={changeButtonType}
      singleOrDetail={toggleSingleOrDetail}
      isElectricity={isElectricity}
    />
  );

  // 工质报表-总视图
  const totalEnergyMediumTable = (
    <TotalEnergyMediumTable
      isAllExpand={isAllExpand}
      setIsAllExpand={setIsAllExpand}
      unitName={unitName}
      basicInfo={basicInfo}
      mediumId={Number(tabKey)}
      setChangeButtonType={setChangeButtonType}
      changeButtonType={changeButtonType}
      singleOrDetail={toggleSingleOrDetail}
      isElectricity={isElectricity}
    />
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormContent
        style={{ padding: 0 }}
        title={
          <>
            {basicInfo ? (
              <>
                <span style={{ paddingRight: 8 }}>{basicInfo.projectName}</span>
                <Tag
                  style={{
                    background: MeterPeriodBackgroundColor[basicInfo.readingPeriod],
                    borderColor: MeterPeriodBorderColor[basicInfo.readingPeriod],
                    color: MeterPeriodColor[basicInfo.readingPeriod],
                  }}
                >
                  {MeterPeriodDisplay[basicInfo.readingPeriod]}
                </Tag>
                <span className={styles.timeStyle}>{`${
                  isNil(basicInfo.lastReadingTime) ? '--' : dayjs(basicInfo.lastReadingTime).format(formatTime)
                } 至 ${
                  isNil(basicInfo.currentReadingTime) ? '--' : dayjs(basicInfo.currentReadingTime).format(formatTime)
                }`}</span>
                <Button
                  type="link"
                  disabled={basicInfo.previousTaskStatus !== StatusEnum.FINISHED}
                  onClick={() => {
                    navigate(`/energy/analysis/universeMeterQuery/detail/${basicInfo.previousTaskId}`);
                  }}
                >
                  上期抄表
                </Button>
                <Button
                  type="link"
                  disabled={basicInfo.nextTaskStatus !== StatusEnum.FINISHED}
                  onClick={() => {
                    navigate(`/energy/analysis/universeMeterQuery/detail/${basicInfo.nextTaskId}`);
                  }}
                >
                  下期抄表
                </Button>
              </>
            ) : null}
          </>
        }
      >
        {!changeButtonType && (
          <>
            <Tabs activeKey={tabKey} items={tabItems} onChange={onTabChange} />
            <Space size={8}>
              {
                // 汇总报表时不展示
                !isSummaryMeter && (
                  <>
                    {hasCopyUpdate && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setChangeButtonType(MeterProjectChangeButtonType.CHANGE_COPY_NUM);
                        }}
                      >
                        抄见数变更
                      </Button>
                    )}
                    {hasBatchAdjust && (
                      <Button
                        type="primary"
                        onClick={() => {
                          setChangeButtonType(MeterProjectChangeButtonType.BATCH_ADJUST);
                        }}
                      >
                        批量调整
                      </Button>
                    )}
                  </>
                )
              }
              {hasShare && (
                <Button
                  type="primary"
                  onClick={() => {
                    setChangeButtonType(MeterProjectChangeButtonType.APPORTION_INPUT);
                  }}
                >
                  分摊录入
                </Button>
              )}

              {hasExport && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isHierarchicalMeterReading) {
                      onDownload(
                        `/api/v2/universe/meter/reading/task/multiLevelExport`,
                        `【${basicInfo?.projectName}】【${
                          isNil(basicInfo?.currentReadingTime)
                            ? '--'
                            : dayjs(basicInfo?.currentReadingTime).format(formatTime)
                        }】`,
                        Number(id)
                      );
                    } else {
                      onDownload(
                        `/api/v2/universe/meter/reading/task/export`,
                        `【${basicInfo?.projectName}】【${
                          isNil(basicInfo?.currentReadingTime)
                            ? '--'
                            : dayjs(basicInfo?.currentReadingTime).format(formatTime)
                        }】`,
                        Number(id)
                      );
                    }
                  }}
                >
                  导出用量报表
                </Button>
              )}
            </Space>
            {isHierarchicalMeterReading && viewType === ViewType.TOTAL && (
              <Button
                type="link"
                onClick={() => {
                  setIsAllExpand(!isAllExpand);
                }}
              >
                {isAllExpand ? '全部收起' : '全部展开'}
              </Button>
            )}
            <div className={styles.rightButtons}>
              {!isSummaryMeter && (
                <Checkbox
                  className={styles.checkboxStyle}
                  onChange={e => {
                    setToggleSingleOrDetail(e.target.checked ? SingleOrDetail.SINGLE : SingleOrDetail.DETAIL);
                  }}
                  checked={toggleSingleOrDetail === SingleOrDetail.SINGLE}
                >
                  隐藏部分字段
                </Checkbox>
              )}
              {!isSummaryMeter && <p className={styles.unitStyle}>单位：{unitName}</p>}
              {isHierarchicalMeterReading && (
                <div className={styles.radioStyle}>
                  <Radio.Group
                    value={viewType}
                    optionType="button"
                    buttonStyle="solid"
                    onChange={e => {
                      setViewType(e.target.value);
                    }}
                  >
                    <Radio value={ViewType.TOTAL}>总视图</Radio>
                    <Radio value={ViewType.HIERARCHY}>分层视图</Radio>
                  </Radio.Group>
                </div>
              )}
            </div>
          </>
        )}

        {isHierarchicalMeterReading && viewType === ViewType.HIERARCHY && (
          <div className={styles.selectStyle}>
            <label>层级：</label>
            <Select
              style={{ width: 240 }}
              options={hierarchyOptions}
              onChange={v => {
                setLevel(v);
              }}
              value={level}
            />
          </div>
        )}
        {changeButtonType && (
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 15,
            }}
          >
            {tabName}
          </p>
        )}
        {isHierarchicalMeterReading && viewType === ViewType.TOTAL
          ? isSummaryMeter
            ? totalSummaryMeterTable
            : totalEnergyMediumTable
          : isSummaryMeter
          ? hierarchySummaryMeterTable
          : hierarchyEnergyMediumTable}
        {isHierarchicalMeterReading && viewType === ViewType.TOTAL
          ? isElectricity && !changeButtonType && <TotalTimeShareEleTable />
          : isElectricity && !changeButtonType && <TimeShareEleTable level={level} />}
      </FormContent>
    </Wrapper>
  );
};

export default MeterProjectDetail;
