import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Key,
  Form,
  Modal,
  TreeSelect,
  Select,
  Input,
  Table,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  ElectricitySaleContractVo,
  getPropertyList,
  getPropertyListOwned,
  PropertyItem,
} from '../../../api/electricitySalesContract';
import {
  deleteSettlementRulesRecord,
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementType,
  ElectricitySettlementRulesPageRequest,
  ElectricitySettlementRulesResponse,
  exportSettlementRule,
  exportSettlementRuleSelected,
  getElectricitySettlementRulesList,
  getSaleElectricityContractList,
  SettlementRulesStatusType,
  SettlementRulesStatusTypeValue,
  TakeEffectStatusType,
  TakeEffectStatusTypeValue,
  updateSettlementRulesStatus,
} from '../../../api/electricitySettlementRules';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/utils';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganizationWithCodeWithCurrent } from '../../../api/device';
import { getSaleClientList, SaleClientInfo } from '../../../api/sale';
import BatchEdge from './components/BatchEdge';
import BatchEdgeBlogList from './components/BatchEdgeBlogList';

const SettlementRulesStatusOptions = [
  { label: SettlementRulesStatusTypeValue[SettlementRulesStatusType.Enable], value: SettlementRulesStatusType.Enable },
  {
    label: SettlementRulesStatusTypeValue[SettlementRulesStatusType.Disable],
    value: SettlementRulesStatusType.Disable,
  },
];

const TakeEffectStatusOptions = [
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.IN_EFFECT],
    value: TakeEffectStatusType.IN_EFFECT,
  },
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.NOT_ACTIVE],
    value: TakeEffectStatusType.NOT_ACTIVE,
  },
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.EXPIRED],
    value: TakeEffectStatusType.EXPIRED,
  },
];

const columns = [
  {
    title: '结算规则编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算规则名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用电类型',
    dataIndex: 'settlementType',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: number) => {
      let statusText = '';
      switch (v) {
        case ElectricitySaleContractSettlementType.UNITARYSYSTEM:
          statusText = ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM];
          break;
        case ElectricitySaleContractSettlementType.TWOPARTSYSTEM:
          statusText = ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM];
          break;
        case ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST:
          statusText =
            ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST];
          break;
        case ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL:
          statusText =
            ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL];
          break;
        case ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY:
          statusText =
            ElectricitySaleContractSettlement[
              ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
            ];
          break;
        default:
          statusText = '--';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  // {
  //   title: '计量场景',
  //   dataIndex: 'meteringScenarios',
  //   width: 82,
  //   ellipsis: { showTitle: true },
  //   render: (v: MeasurementScenarioType, record: any) => {
  //     let statusText = '';
  //     switch (v) {
  //       case MeasurementScenarioType.LIGHTING:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.LIGHTING];
  //         break;
  //       case MeasurementScenarioType.PHOTOVOLTAIC:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.PHOTOVOLTAIC];
  //         break;
  //       case MeasurementScenarioType.SPECIALPURPOSE:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.SPECIALPURPOSE];
  //         break;
  //       default:
  //         statusText = '-'
  //         break;
  //     }
  //     return <EllipsisSpan value={statusText} />
  //   }
  // },
  {
    title: '生效区间',
    dataIndex: 'effectiveTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: any) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format('YYYY-MM-DD')} ~ ${dayjs(record.endTime).format('YYYY-MM-DD')}`}
      />
    ),
  },
  {
    title: '生效状态',
    dataIndex: 'effectiveState',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string, record: ElectricitySettlementRulesResponse) => {
      const current = dayjs().format('YYYY-MM-DD');
      const start = dayjs(record.startTime).format('YYYY-MM-DD');
      const end = dayjs(record.endTime).format('YYYY-MM-DD');
      let value = '生效中';
      if (start > current) {
        value = '未生效';
      }
      if (end < current) {
        value = '已过期';
      }
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属合同',
    dataIndex: 'saleElectricityContractName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: SettlementRulesStatusType, record: any) => {
      let statusText = '';
      switch (v) {
        case SettlementRulesStatusType.Enable:
          statusText = '启用';
          break;
        case SettlementRulesStatusType.Disable:
          statusText = '禁用';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

function formatTreeData(data: any[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
}

export interface SearchParams extends Omit<ElectricitySettlementRulesPageRequest, 'page' | 'size'> {}

interface ElectricitySettlementRulesProps {
  filterConditions?: string; // 过滤条件
}

const ElectricitySettlementRules: FC<ElectricitySettlementRulesProps> = props => {
  const hasSettlementRuleAdd = useHasPermission(PermissionsType.B_ELECSETTLERULEADD); // 新增电费结算规则
  const hasB_BATCHIMPORTLOG = useHasPermission(PermissionsType.B_BATCHIMPORTLOG); // 批量修改日志
  const hasB_BATCHIMPORTACTION = useHasPermission(PermissionsType.B_BATCHIMPORTACTION); // 批量修改操作
  const hasB_EXPORTSELECTED = useHasPermission(PermissionsType.B_EXPORTSELECTED); // 导出选中
  const hasB_EXPORTALL = useHasPermission(PermissionsType.B_EXPORTALL); // 导出全部
  const hasB_CHANGELOGLATEST = useHasPermission(PermissionsType.B_CHANGELOGLATEST); // 结算规则日志按钮
  const hasB_CHANGELOGDETAIL = useHasPermission(PermissionsType.B_CHANGELOGDETAIL); // 结算规则日志详情

  const [data, setData] = useState<ElectricitySettlementRulesResponse[]>([]); // 后端返回数据
  const [saleElectricityContractList, setSaleElectricityContractList] = useState<ElectricitySaleContractVo[]>([]); // 根据ou权限获取合同列表
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [refreshState, refresh] = useUpdate();
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]); // 物业list
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const searchParamsRef = useRef<SearchParams>({
    effectiveState: TakeEffectStatusType.IN_EFFECT,
    status: SettlementRulesStatusType.Enable,
  });
  const customerMcid: Key[] | undefined = Form.useWatch('customerMcid', searchForm);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  // 根据组织选择物业
  const getPropertyLists = useCallback((mcid: Key[]) => {
    return getPropertyList(mcid).then(res => {
      setPropertyList(res);
      console.log(mcid);
      const search = {
        customerMcid: mcid,
        propertyManagementConfigIds: [res.at(0)?.id],
      };
      return search;
    });
  }, []);

  useEffect(() => {
    if (!customerMcid || customerMcid.length === 0) {
      getPropertyListOwned().then(res => {
        setPropertyList(res);
      });
    }
  }, [customerMcid]);

  // 查询所属组织
  useEffect(() => {
    getSaleClientList().then(setCustomList);
    getSaleElectricityContractList().then(setSaleElectricityContractList);
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, [searchForm]);

  useEffect(() => {
    if (customerMcid && customerMcid?.length > 0) {
      searchForm.resetFields(['propertyManagementConfigIds']);
      getPropertyLists(customerMcid)?.then(search => {
        searchForm.setFieldsValue(search);
      });
    }
  }, [customerMcid, searchForm, getPropertyLists]);

  const getData = useCallback((query: ElectricitySettlementRulesPageRequest) => {
    setLoading(true);
    getElectricitySettlementRulesList(query).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = searchParamsRef.current;
    getData({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm, pageOffset, pageSize, refreshState]);

  // 所属物业选项
  const propertyOptions = useMemo(() => {
    return propertyList?.map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [propertyList]);

  const customListOptions = useMemo(() => {
    if (customList.length !== 0) {
      return customList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [customList]);

  const saleElectricityContractListOptions = useMemo(() => {
    if (saleElectricityContractList.length !== 0) {
      return saleElectricityContractList.map(i => ({ label: `${i.code}【${i.name}】`, value: i.id }));
    }
  }, [saleElectricityContractList]);

  const onFinish = (val: SearchParams) => {
    searchParamsRef.current = val;
    setPageOffset(1);
    refresh();
  };

  const onReset = () => {
    searchParamsRef.current = {
      effectiveState: TakeEffectStatusType.IN_EFFECT,
      status: SettlementRulesStatusType.Enable,
    };
    setPageOffset(1);
    refresh();
  };

  /**
   * 改变结算规则状态（启用与禁用）
   * @param record
   */
  const onChangeStatus = (record: ElectricitySettlementRulesResponse) => {
    Modal.confirm({
      // title: <span>确认终止？终止合同后，结算规则不再生效。系统以终止时间进行最后一次结算。</span>,
      icon: <ExclamationCircleOutlined />,
      content: `确认${
        record.status === SettlementRulesStatusType.Enable
          ? SettlementRulesStatusTypeValue[SettlementRulesStatusType.Disable]
          : SettlementRulesStatusTypeValue[SettlementRulesStatusType.Enable]
      }该结算规则吗？`,
      okText: '继续',
      onOk() {
        updateSettlementRulesStatus(record.id, {
          status:
            record.status === SettlementRulesStatusType.Enable
              ? SettlementRulesStatusType.Disable
              : SettlementRulesStatusType.Enable,
        })
          .then(res => {
            // message.success('修改状态成功');
            refresh();
          })
          .catch(err => {
            // message.error('修改状态失败', err)
          });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  /**
   * 删除
   */
  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认删除该结算规则吗？`,
      okText: '继续',
      onOk() {
        deleteSettlementRulesRecord(id)
          .then(res => {
            // message.success('删除成功');
            refresh();
          })
          .catch(err => {
            // message.error('删除失败', err)
          });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 240,
      render: (record: ElectricitySettlementRulesResponse) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasSettlementRuleAdd && (
              <Button
                disabled={record.status === SettlementRulesStatusType.Enable}
                type="link"
                onClick={() => onDeleteRecord(record.id)}
              >
                删除
              </Button>
            )
          }
          {hasSettlementRuleAdd && (
            <Button
              // disabled={(record.status === ElectricitySettlementRulesStatusType.ABORTED)}
              type="link"
              onClick={() => onChangeStatus(record)}
            >
              {record.status === SettlementRulesStatusType.Disable ? '启用' : '禁用'}
            </Button>
          )}
          {hasSettlementRuleAdd && (
            <Button
              disabled={record.status === SettlementRulesStatusType.Disable}
              type="link"
              onClick={() => {
                navigate(`/operation/settlementRules/electricity/edit/${record.id}`);
              }}
            >
              编辑
            </Button>
          )}
          {hasSettlementRuleAdd && (
            <Button
              // disabled={!record.editable || record.status === ElectricityContractStatus.INVALID}
              type="link"
            >
              <Link to={`/operation/settlementRules/electricity/create?copyId=${record.id}`}>复制</Link>
            </Button>
          )}
          {
            // hasPriceRateEdit &&
            <Button
              // disabled={!record.editable || record.status === ElectricityContractStatus.INVALID || !(dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date')))}
              type="link"
            >
              <Link to={`/operation/settlementRules/electricity/details/${record.id}`}>查看</Link>
            </Button>
          }
          {hasB_CHANGELOGDETAIL && (
            <Button type="link">
              <Link to={`/operation/settlementRules/electricity/blog?code=${record.code}`}>日志</Link>
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const filters = (
    <CustomFilter
      form={searchForm}
      onFinish={(val: SearchParams) => onFinish(val)}
      onReset={onReset}
      initialValues={{
        effectiveState: TakeEffectStatusType.IN_EFFECT,
        status: SettlementRulesStatusType.Enable,
      }}
      collapseOpen
    >
      <Form.Item name="customerMcid" label="所属组织">
        <TreeSelect
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          multiple
          maxTagCount="responsive"
          allowClear
        />
      </Form.Item>
      <Form.Item noStyle dependencies={['customerMcid']}>
        {() => {
          return (
            <Form.Item name="propertyManagementConfigIds" label="所属物业">
              <Select
                showSearch
                allowClear
                maxTagCount={1}
                optionFilterProp="label"
                mode="multiple"
                placeholder="请选择"
                options={propertyOptions}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item label="客户名称" name="saleClientIds">
        <Select
          mode="multiple"
          allowClear
          placeholder="请选择"
          maxTagCount={2}
          options={customListOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item name="saleElectricityContractIds" label="所属合同">
        <Select
          mode="multiple"
          allowClear
          maxTagCount={1}
          placeholder="请选择"
          options={saleElectricityContractListOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      {/* <Col span={6}>
            <Form.Item label='计量场景' name='meteringScenarios'>
              <Select placeholder="请选择">
                <Select.Option value={MeasurementScenarioType.LIGHTING}>{MeasurementScenarioTypeValue[MeasurementScenarioType.LIGHTING]}</Select.Option>
                <Select.Option value={MeasurementScenarioType.PHOTOVOLTAIC}>{MeasurementScenarioTypeValue[MeasurementScenarioType.PHOTOVOLTAIC]}</Select.Option>
                <Select.Option value={MeasurementScenarioType.SPECIALPURPOSE}>{MeasurementScenarioTypeValue[MeasurementScenarioType.SPECIALPURPOSE]}</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}

      <Form.Item label="结算规则编号" name="code">
        <Input placeholder=" 请输入编号查询" />
      </Form.Item>
      <Form.Item label="结算规则名称" name="name">
        <Input placeholder=" 请输入名称查询" />
      </Form.Item>
      <Form.Item label="生效状态" name="effectiveState">
        <Select placeholder="请选择" options={TakeEffectStatusOptions} />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select placeholder="请选择" options={SettlementRulesStatusOptions} />
      </Form.Item>
    </CustomFilter>
  );

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const exportSelect = () => {
    if (selectedRowKeys.length === 0) return;
    window.open(
      exportSettlementRuleSelected({
        ids: selectedRowKeys,
      }),
      '_blank'
    );
  };

  const exportAll = () => {
    const search = searchParamsRef.current;
    window.open(exportSettlementRule(search), '_blank');
  };

  const [batchEdgeOpen, setBatchEdgeOpen] = useState<boolean>(false);
  const [batchEdgeBlogListOpen, setBatchEdgeBlogListOpen] = useState<boolean>(false);

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
        <Space size={8} style={{ marginBottom: 10 }}>
          {hasSettlementRuleAdd && (
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to={`/operation/settlementRules/electricity/create`}>新建结算规则</Link>
            </Button>
          )}
          {hasB_EXPORTSELECTED && (
            <Button type="primary" onClick={exportSelect}>
              导出选中
            </Button>
          )}
          {hasB_EXPORTALL && (
            <Button type="primary" onClick={exportAll}>
              导出全部
            </Button>
          )}

          {hasB_BATCHIMPORTACTION && (
            <Button
              type="primary"
              onClick={() => {
                setBatchEdgeOpen(true);
              }}
            >
              批量修改操作
            </Button>
          )}
          {hasB_BATCHIMPORTLOG && (
            <Button
              type="primary"
              onClick={() => {
                setBatchEdgeBlogListOpen(true);
              }}
            >
              批量修改日志
            </Button>
          )}

          {hasB_CHANGELOGLATEST && (
            <Button type="primary">
              <Link to={`/operation/settlementRules/electricity/blog`}>结算规则日志</Link>
            </Button>
          )}
        </Space>

        <Table
          rowSelection={rowSelection}
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />

        <BatchEdge refresh={refresh} batchEdgeOpen={batchEdgeOpen} setBatchEdgeOpen={setBatchEdgeOpen} />
        <BatchEdgeBlogList
          refreshState={refreshState}
          batchEdgeBlogListOpen={batchEdgeBlogListOpen}
          setBatchEdgeBlogListOpen={setBatchEdgeBlogListOpen}
        />
      </Wrapper>
    </>
  );
};

export default ElectricitySettlementRules;
