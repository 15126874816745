import { Col, FormInstance, Row, Space } from 'antd';
import styles from './index.module.scss';
import {
  Button,
  Form,
  FormTitle,
  InputNumber,
  Modal,
  Select,
  ShowInput,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
  Radio,
  message,
} from '@maxtropy/components';
import { createContext, useEffect, useMemo, useState } from 'react';
import {
  ActionFormValues,
  AnalyticsType,
  AnalyticsTypeDisplay,
  NotProductionFormValues,
  TaktMode,
  TaktModeDisplay,
  TimeGranularityType,
  TimeGranularityTypeOptions,
} from './utils';
import BeatConfig from './components/BeatConfig';
import { useNavigate, useParams } from 'react-router-dom';
import {
  V2AppAnalysisSubscriptionDataPropertyGetByMeasurementPostResponse,
  V2AppAnalysisSubscriptionThresholdConfigByDeviceGetPostResponse,
  apiV2AppAnalysisSubscriptionDataPropertyGetByMeasurementPost,
  apiV2AppAnalysisSubscriptionDetailPost,
  apiV2AppAnalysisSubscriptionThresholdConfigByDeviceGetPost,
  apiV2ProductionRhythmConfigAddOrUpdatePost,
  apiV2ProductionRhythmConfigDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import LoadCurve from './components/LoadCurve';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import Waveforms from './components/Waveforms';
import dayjs, { Dayjs } from 'dayjs';

export const ProductionBeatConfigContext = createContext<{
  // 非生产状态列表
  statusList?: StatusListItem[];
  setStatusList?: (statusList: StatusListItem[]) => void;
  // 时间颗粒度
  timeResolution: TimeGranularityType;
  // 物理单位名称
  physicalUnitName?: string;
  // 非生产状态table数据
  notProductionStatusTableData?: NotProductionFormValues[];
  setNotProductionStatusTableData?: (notProductionStatusTableData: NotProductionFormValues[]) => void;
  // 计量属性名称
  quotaTypeName?: string;
  // 生产动作table数据
  actionTableData?: ActionFormValues[];
  setActionTableData?: (actionTableData: ActionFormValues[]) => void;
  date?: Dayjs[];
  setDate?: (values: Dayjs[]) => void;
  templateDate?: Dayjs[];
  setTemplateDate?: (values: Dayjs[]) => void;
  initTemplateDate?: boolean;
  setInitTemplateDate?: (value: boolean) => void;
  form?: FormInstance<any>;
  type?: TaktMode;
}>({ timeResolution: TimeGranularityType.MINUTE });

export type DeviceListItem = Exclude<
  V2AppAnalysisSubscriptionDataPropertyGetByMeasurementPostResponse['list'],
  undefined
>[number];
export type StatusListItem = Exclude<
  V2AppAnalysisSubscriptionThresholdConfigByDeviceGetPostResponse['list'],
  undefined
>[number];
const routes = [{ name: '生产节拍配置' }];

interface FormValues {
  analysisType: string[];
  beatInterval: {
    beatIntervalMax?: number;
    beatIntervalMin?: number;
  };
  deviceId: number;
  nonWorkingAlarm: {
    nonWorkingAlarmRate: number;
  };
  abnormalBeatAlarm: {
    abnormalBeatAlarmRate: number;
  };
  productionProcessName: string;
  quotaType: number;
  timeResolution: TimeGranularityType;
  waveformRequest?: {
    normalCoefficient?: number;
    abnormalCoefficient?: number;
    templateStartTime?: number;
    templateEndTime?: number;
    startTime?: number;
    endTime?: number;
    beatIntervalMax?: number;
    beatIntervalMin?: number;
  };
  productionRhythmIdConfActionAddRequests?: any;
}

const ProductionBeatConfig: React.FC = () => {
  // 应用分析订阅id
  const { id: appAnalysisSubId } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // 非生产状态列表
  const [statusList, setStatusList] = useState<StatusListItem[]>();
  // 时间颗粒度
  const [timeResolution, setTimeResolution] = useState<TimeGranularityType>(TimeGranularityType.MINUTE);
  // 选择表计
  const [deviceList, setDeviceList] = useState<DeviceListItem[]>([]);
  const deviceId = Form.useWatch('deviceId', form);
  // 计量属性名称
  const [quotaTypeName, setQuotaTypeName] = useState<string>();
  const quotaType = Form.useWatch('quotaType', form);
  // 非生产状态table数据
  const [notProductionStatusTableData, setNotProductionStatusTableData] = useState<NotProductionFormValues[]>([]);
  // 物理单位名称
  const [physicalUnitName, setPhysicalUnitName] = useState<string>();
  // 生产动作table数据
  const [actionTableData, setActionTableData] = useState<ActionFormValues[]>([]);

  // 节拍取值时间范围
  const [date, setDate] = useState<Dayjs[]>();

  // 模版取值范围
  const [templateDate, setTemplateDate] = useState<Dayjs[]>();
  const [initTemplateDate, setInitTemplateDate] = useState<boolean>(false);

  const type = Form.useWatch('type', { form, preserve: true });

  const navigate = useNavigate();

  const TaktTimeFollowTheAction = useHasPermission(PermissionsType.B_TAKTTIMEFOLLOWTHEACTION);

  useEffect(() => {
    if (appAnalysisSubId) {
      // 获取表计列表
      apiV2AppAnalysisSubscriptionDataPropertyGetByMeasurementPost({
        appAnalysisSubId,
      }).then(deviceList => {
        setDeviceList(deviceList?.list ?? []);
        // 节拍配置详情
        apiV2ProductionRhythmConfigDetailPost({
          id: appAnalysisSubId,
        }).then(config => {
          if (isNil(config)) {
            form.setFieldValue('type', 1);
            setInitTemplateDate(true);
            return;
          }
          if ((deviceList?.list ?? []).map(i => i.deviceId).includes(config?.deviceId)) {
            form.setFieldsValue({
              ...config,
              beatInterval: {
                beatIntervalMin:
                  config.type === TaktMode.FOLLOW_WAVEFORM
                    ? config?.waveformVo?.beatIntervalMin
                    : config?.rhythmConfigVo?.beatIntervalMin,
                beatIntervalMax:
                  config.type === TaktMode.FOLLOW_WAVEFORM
                    ? config?.waveformVo?.beatIntervalMax
                    : config?.rhythmConfigVo?.beatIntervalMax,
              },
              waveformRequest: config.waveformVo,
              abnormalBeatAlarm: { abnormalBeatAlarmRate: config?.abnormalBeatAlarmRate },
              nonWorkingAlarm: { nonWorkingAlarmRate: config?.nonWorkingAlarmRate },
            });

            if (config?.type === TaktMode.FOLLOW_WAVEFORM) {
              if (config?.waveformVo?.startTime && config?.waveformVo?.endTime) {
                setDate([dayjs(config?.waveformVo?.startTime), dayjs(config?.waveformVo?.endTime)]);
              }
              setTemplateDate([
                config?.waveformVo?.templateStartTime
                  ? dayjs(config?.waveformVo?.templateStartTime)
                  : dayjs().subtract(10, 'hour'),
                config?.waveformVo?.templateEndTime
                  ? dayjs(config?.waveformVo?.templateEndTime)
                  : dayjs().subtract(1, 'hour'),
              ]);
            }

            if (config?.type === TaktMode.FOLLOW_ACTION) {
              setActionTableData(
                (config?.rhythmConfigVo?.actionAddList ?? []).map(i => ({
                  ...i,
                  durationRange: {
                    durationRangeMin: i.durationRangeMin,
                    durationRangeMax: i.durationRangeMax,
                  },
                  useEnergy: {
                    useEnergyLowLimit: i.useEnergyLowLimit,
                    useEnergyUpperLimit: i.useEnergyUpperLimit,
                  },
                  startFromValueRange: {
                    startFromValueRangeMin: i.startFromValueRangeMin,
                    startFromValueRangeMax: i.startFromValueRangeMax,
                  },
                  startToValueRange: {
                    startToValueRangeMin: i.startToValueRangeMin,
                    startToValueRangeMax: i.startToValueRangeMax,
                  },
                  endFromValueRange: {
                    endFromValueRangeMin: i.endFromValueRangeMin,
                    endFromValueRangeMax: i.endFromValueRangeMax,
                  },
                  endToValueRange: {
                    endToValueRangeMin: i.endToValueRangeMin,
                    endToValueRangeMax: i.endToValueRangeMax,
                  },
                })) as ActionFormValues[]
              );
              setNotProductionStatusTableData(
                (config?.nonWorkingVos ?? []).map(i => ({
                  ...i,
                  useEnergyRange: `${i.useEnergyLowLimit}~${i.useEnergyUpperLimit}`,
                  startFromValueRange: {
                    startFromValueRangeMin: i.startFromValueRangeMin,
                    startFromValueRangeMax: i.startFromValueRangeMax,
                  },
                  startToValueRange: {
                    startToValueRangeMin: i.startToValueRangeMin,
                    startToValueRangeMax: i.startToValueRangeMax,
                  },
                  endFromValueRange: {
                    endFromValueRangeMin: i.endFromValueRangeMin,
                    endFromValueRangeMax: i.endFromValueRangeMax,
                  },
                  endToValueRange: {
                    endToValueRangeMin: i.endToValueRangeMin,
                    endToValueRangeMax: i.endToValueRangeMax,
                  },
                })) as NotProductionFormValues[]
              );
            }

            setPhysicalUnitName(config?.generalName);
            setTimeResolution((config?.timeResolution as TimeGranularityType) ?? TimeGranularityType.MINUTE);
          } else {
            Modal.error({
              title: `${config?.deviceName}表计已从当前生产过程中解绑，表计信息需要重新选择且“非生产状态”列表与“生产节拍”列表将被清空！`,
            });
          }
        });
      });
      // 基础信息
      apiV2AppAnalysisSubscriptionDetailPost({ id: appAnalysisSubId }).then(res => {
        form.setFieldsValue({
          analysisType: res.analyticsTypes?.map((i: AnalyticsType) => AnalyticsTypeDisplay[i]),
          productionProcessName: [
            res?.productionBaseName,
            res?.workCenterName,
            res?.workProcedureName,
            res?.workStationName,
            res?.energyUnitName,
          ]
            .filter(i => !isNil(i))
            .join('-'),
        });
      });
    }
  }, [appAnalysisSubId, form]);

  // 计量属性
  const quotaTypeList = useMemo(() => {
    if (deviceId) {
      return deviceList.find(item => item.deviceId === deviceId)?.dataProperties ?? [];
    }
  }, [deviceId, deviceList]);

  // 获取非生产状态列表
  useEffect(() => {
    if (appAnalysisSubId && deviceId && quotaType) {
      apiV2AppAnalysisSubscriptionThresholdConfigByDeviceGetPost({
        appAnalysisSubId,
        deviceId,
        dataPropertyId: quotaType,
      }).then(res => {
        setStatusList(res?.list ?? []);
      });
    }
  }, [appAnalysisSubId, deviceId, quotaType]);

  useEffect(() => {
    if (quotaType && quotaTypeList && quotaTypeList.length > 0) {
      const checked = (quotaTypeList ?? []).find(item => item.dataPropertyId === quotaType);
      setQuotaTypeName(checked?.dataPropertyName);
      setPhysicalUnitName(checked?.physicalUnitName);
    }
  }, [quotaType, quotaTypeList]);

  const deviceOptions = useMemo(() => {
    return (deviceList ?? []).map(item => ({
      label: item.deviceName,
      value: item.deviceId,
    }));
  }, [deviceList]);

  const quotaTypeOptions = useMemo(() => {
    return (quotaTypeList ?? []).map(item => ({
      label: item.dataPropertyName,
      value: item.dataPropertyId,
    }));
  }, [quotaTypeList]);

  const onFinish = () => {
    if (isNil(appAnalysisSubId)) return;
    const v = form.getFieldsValue(true);
    const params = {
      ...v,
      abnormalBeatAlarmRate: v.abnormalBeatAlarm.abnormalBeatAlarmRate,
      nonWorkingAlarmRate: v.nonWorkingAlarm.nonWorkingAlarmRate,
      appAnalyticsSubscriptionId: appAnalysisSubId,
      generalName: physicalUnitName,
      nonStatusAddRequestList: notProductionStatusTableData,
    };

    if (type === TaktMode.FOLLOW_WAVEFORM) {
      if (!v.waveformRequest?.templateStartTime || !v.waveformRequest?.templateStartTime) {
        message.error('请标定节拍用能模板');
        return;
      }
      params.waveformRequest = {
        ...(v.waveformRequest || {}),
        beatIntervalMin: v.beatInterval?.beatIntervalMin,
        beatIntervalMax: v.beatInterval?.beatIntervalMax,
        startTime: date?.[0] && dayjs(date[0]).valueOf(),
        endTime: date?.[1] && dayjs(date[1]).valueOf(),
      };
    } else {
      params.productionRhythmIdConfActionAddRequests = [
        {
          beatIntervalMin: v.beatInterval?.beatIntervalMin,
          beatIntervalMax: v.beatInterval?.beatIntervalMax,
          actionAddList: actionTableData.map((i, index) => ({
            ...i,
            sort: index + 1,
          })),
        },
      ];
    }
    apiV2ProductionRhythmConfigAddOrUpdatePost(params).then(() => {
      navigate('/energy/appAnalyticsSubscription/productionProcess');
    });
  };
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperPadding}>
      <ProductionBeatConfigContext.Provider
        value={{
          statusList,
          setStatusList,
          timeResolution,
          notProductionStatusTableData,
          setNotProductionStatusTableData,
          physicalUnitName,
          quotaTypeName,
          actionTableData,
          setActionTableData,
          setInitTemplateDate,
          initTemplateDate,
          date,
          setDate,
          templateDate,
          setTemplateDate,
          form,
          type,
        }}
      >
        <FormTitle title="生产节拍配置" />
        <Form
          form={form}
          initialValues={{
            timeResolution: TimeGranularityType.MINUTE,
            type: TaktMode.FOLLOW_WAVEFORM,
          }}
          onFinish={onFinish}
        >
          <SubContent title="基础信息">
            <Row>
              <Col span={12}>
                <Form.Item label="应用分析类型">
                  <ShowInput value="生产过程" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="生产过程名称" name="productionProcessName">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="选择表计" name="deviceId" rules={[{ required: true, message: '请选择表计' }]}>
                  <Select
                    placeholder="请选择"
                    options={deviceOptions}
                    onChange={() => {
                      form.setFieldsValue({
                        quotaType: undefined,
                      });
                    }}
                    disabled={notProductionStatusTableData.length > 0 || actionTableData.length > 0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="计量属性" name="quotaType" rules={[{ required: true, message: '请选择指标类型' }]}>
                  <Select
                    placeholder="请选择"
                    options={quotaTypeOptions}
                    disabled={notProductionStatusTableData.length > 0 || actionTableData.length > 0}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="时间颗粒度"
                  name="timeResolution"
                  rules={[{ required: true, message: '请选择时间颗粒度' }]}
                >
                  <Select
                    onChange={(v: TimeGranularityType) => {
                      setTimeResolution(v);
                    }}
                    options={TimeGranularityTypeOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item required label="异常节拍报警" name="abnormalBeatAlarm">
                  <Form.Item noStyle>
                    <p className={styles.wordStyle} style={{ marginRight: 8 }}>
                      占比超过
                    </p>
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: '请输入百分比' }]}
                    name={['abnormalBeatAlarm', 'abnormalBeatAlarmRate']}
                    noStyle
                  >
                    <InputNumber
                      style={{
                        width: 182,
                      }}
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2}
                      placeholder="请输入百分比"
                      addonAfter="%"
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.wordStyle} style={{ marginLeft: 8 }}>
                      诊断页面报警
                    </p>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="nonWorkingAlarm" required label="非工作时长报警">
                  <Form.Item noStyle>
                    <p className={styles.wordStyle} style={{ marginRight: 8 }}>
                      占比超过
                    </p>
                  </Form.Item>
                  <Form.Item
                    name={['nonWorkingAlarm', 'nonWorkingAlarmRate']}
                    rules={[{ required: true, message: '请输入百分比' }]}
                    noStyle
                  >
                    <InputNumber
                      style={{
                        width: 182,
                      }}
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2}
                      placeholder="请输入百分比"
                      addonAfter="%"
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.wordStyle} style={{ marginLeft: 8 }}>
                      诊断页面报警
                    </p>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            {TaktTimeFollowTheAction && (
              <Row>
                <Col span={24}>
                  <Form.Item name="type" label="节拍识别模式">
                    <Radio.Group>
                      {Object.entries(TaktModeDisplay).map(([key, label]) => (
                        <Radio key={key} value={Number(key)}>
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </SubContent>
          <SubContent title={type === TaktMode.FOLLOW_WAVEFORM ? '节拍用能模版设定' : '用能曲线'}>
            <LoadCurve
              quotaType={quotaType}
              hasBrush={type === TaktMode.FOLLOW_WAVEFORM ? true : false}
              deviceId={deviceId}
              physicalUnitName={physicalUnitName}
            />
          </SubContent>
          {type === TaktMode.FOLLOW_WAVEFORM && (
            <Waveforms form={form} quotaType={quotaType} deviceId={deviceId} physicalUnitName={physicalUnitName} />
          )}
          {type === TaktMode.FOLLOW_ACTION && (
            <>
              <SubContent title="生产节拍配置">
                <BeatConfig />
              </SubContent>
            </>
          )}
          <Space size={8} className="sticky-footer">
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              onClick={() => {
                navigate('/energy/appAnalyticsSubscription/productionProcess');
              }}
            >
              取消
            </Button>
          </Space>
        </Form>
      </ProductionBeatConfigContext.Provider>
    </Wrapper>
  );
};

export default ProductionBeatConfig;
