import { PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  usePaging,
  Table,
  Button,
  useAsync,
  Modal,
  Select,
  DatePicker,
  Input,
  Filter,
} from '@maxtropy/components';
import { Col, Row, Space, FormInstance, Form } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import {
  getSalesContractMeterPage,
  SaleContactMeterPageRequest,
  SaleContactMeterResponse,
  MeterFormValueRequest,
} from '../../../api/electricitySalesContract';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '../../../utils/utils';
import { getSaleClientElectricityAccountList } from '../../../api/sale';

function getLargerValue(a: Dayjs, b: Dayjs) {
  return a > b ? a : b;
}

export interface MeterFormValue extends SaleContactMeterResponse, MeterFormValueRequest {
  saleElectricityContractElectricityMeterInfoId: number | undefined;
}

interface MeterFormProps {
  disabled?: boolean;
  value?: MeterFormValue[];
  onChange?: (value: MeterFormValue[]) => void;
  saleClientId: number | undefined;
  contractEffectiveTime: [Dayjs, Dayjs] | undefined;
  isEdit: boolean | undefined;
  status: number | undefined;
  formTitle?: string | undefined;
  meterType: number;
}

type SearchParams = Omit<SaleContactMeterPageRequest, 'page' | 'size'>;

const columns: ColumnType<MeterFormValue>[] = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '表计标签',
    dataIndex: 'meterTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join('、')} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join('、')} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const modalColumns: ColumnType<SaleContactMeterResponse>[] = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '表计标签',
    dataIndex: 'meterTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join('、')} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join(',')} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const DATE_FORMAT = 'YYYY-MM-DD';

const MeterForm: FC<MeterFormProps> = ({
  disabled = false,
  value,
  onChange,
  saleClientId,
  contractEffectiveTime,
  isEdit,
  status,
  formTitle,
  meterType,
}) => {
  // 获取合同上的form
  const parentForm = Form.useFormInstance<{ effectiveTime?: [Dayjs | null, Dayjs | null] }>();
  const [form] = Form.useForm();
  const [timeForm] = Form.useForm();

  const effectiveTime = Form.useWatch('effectiveTime', parentForm);

  const [searchForm] = Form.useForm(); // 搜索的表单
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [visible, setVisible] = useState<boolean>(false);
  const [timeData, setTimeData] = useState<MeterFormValueRequest>();
  const [selectedRows, setSelectedRows] = useState<Array<SaleContactMeterResponse>>();
  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    if (visible && effectiveTime) {
      form.setFieldsValue({
        startTime: effectiveTime[0] ?? undefined,
        endTime: effectiveTime[1] ?? undefined,
      });
    }
  }, [visible, form, effectiveTime]);

  useEffect(() => {
    if (value) {
      setSelectedRows(value);
    }
  }, [value]);

  const onOk = async () => {
    try {
      const values = await form.validateFields();
      onChange &&
        selectedRows &&
        onChange(
          selectedRows.map(item => ({
            ...item,
            saleElectricityContractElectricityMeterInfoId: value?.find(v => v.id === item.id)
              ?.saleElectricityContractElectricityMeterInfoId,
            startTime: !value?.find(v => v.id === item.id)?.startTime
              ? values.startTime.format(DATE_FORMAT)
              : value.find(v => v.id === item.id)?.startTime,
            endTime: !value?.find(v => v.id === item.id)?.endTime
              ? values.endTime.format(DATE_FORMAT)
              : value.find(v => v.id === item.id)?.endTime,
            meterType,
          }))
        );
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const onTimeClose = () => {
    setTimeData(undefined);
    timeForm.resetFields();
  };

  const onTimeOk = async () => {
    try {
      const formValues = await timeForm.validateFields();
      if (onChange && selectedRows && value) {
        const data: MeterFormValue[] = value.map(item => {
          if (item.id === timeData!.id) {
            return {
              ...item,
              startTime: formValues.startTime.format(DATE_FORMAT),
              endTime: formValues.endTime.format(DATE_FORMAT),
            };
          }
          return item;
        });
        onChange(data);
      }
      onTimeClose();
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = () => {
    setVisible(false);
    setSearchParams(undefined);
    form.resetFields();
    searchForm.resetFields();
    setPageOffset(1);
  };

  const onRemove = (record: SaleContactMeterResponse) => {
    const data = value!.filter(item => item.id !== record!.id);
    onChange && onChange(data);
    setSelectedRows(data);
  };

  const editColumns = [
    ...columns,
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            width: 200,
            fixed: 'right' as const,
            render: (value: undefined, record: MeterFormValue) => {
              return (
                <Space size={16}>
                  <Button
                    type="link"
                    onClick={() => {
                      onConfirm(record);
                    }}
                  >
                    删除表计
                  </Button>
                  <Button
                    type="link"
                    disabled={!record.deviceId}
                    onClick={() => {
                      timeForm.setFieldsValue({
                        startTime: dayjs(record?.startTime),
                        endTime: dayjs(record?.endTime),
                      });
                      setTimeData(record);
                    }}
                  >
                    编辑时间
                  </Button>
                </Space>
              );
            },
          },
        ]),
  ];

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: ReactText[], rows: SaleContactMeterResponse[]) => {
    const columnIds = (data ?? []).map(i => i.id);
    const filterSelectRows = (selectedRows ?? []).filter(
      (item: SaleContactMeterResponse) => !columnIds.includes(item.id)
    );
    setSelectedRows([...filterSelectRows, ...rows]);
  };

  const rowSelection = {
    // preserveSelectedRowKeys: true,
    selectedRowKeys: (selectedRows ?? []).map(item => item.id),
    onChange: onSelectChange,
    getCheckboxProps: (record: SaleContactMeterResponse) => ({
      disabled: (value?.map(item => item.id) ?? []).includes(record.id),
    }),
  };

  const startTimeValid = (current: Dayjs): boolean => {
    // if (isEdit) {
    //   return current && contractEffectiveTime ?
    //     current < getLargerValue(contractEffectiveTime[0].startOf('day'), dayjs().startOf("day")) ||
    //     current > contractEffectiveTime[1].endOf('day')
    //     : true
    // }
    return current && contractEffectiveTime
      ? current < contractEffectiveTime[0].startOf('day') || current > contractEffectiveTime[1].endOf('day')
      : true;
  };

  const endTimeValid = (current: Dayjs, form: FormInstance): boolean => {
    if (isEdit && timeData) {
      return current && contractEffectiveTime && form.getFieldValue('startTime')
        ? current < getLargerValue(form.getFieldValue('startTime').endOf('day'), dayjs().endOf('day')) ||
            current > contractEffectiveTime[1].endOf('day')
        : true;
    }
    return current && contractEffectiveTime && form.getFieldValue('startTime')
      ? current < form.getFieldValue('startTime').endOf('day') || current > contractEffectiveTime[1].endOf('day')
      : true;
  };

  const { data, isLoading: isModalLoading } = useQuery(
    useCallback(async () => {
      if (saleClientId) {
        const res = await getSalesContractMeterPage({
          saleClientId,
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        });
        if (res) setTotalCount(res.total);
        return res.list;
      }
      return [];
    }, [pageOffset, pageSize, searchParams, saleClientId, setTotalCount])
  );

  const fetchCompanyRequest = useCallback(async () => {
    if (saleClientId) {
      return getSaleClientElectricityAccountList(saleClientId);
    }
  }, [saleClientId]);

  const accountData = useAsync(fetchCompanyRequest);

  const onConfirm = (record: SaleContactMeterResponse) =>
    Modal.confirm({
      title: '确定移除？',
      content: '移除后，该表计未结算的日期不再结算，你还要继续吗？',
      onOk: () => onRemove(record),
      onCancel: () => {},
    });

  return (
    <>
      {!disabled && (
        <Space direction="vertical" style={{ marginBottom: 10 }}>
          <Button disabled={!saleClientId} type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined />
            {formTitle}
          </Button>
          {/* <div /> */}
        </Space>
      )}
      <Table rowKey={`id`} sticky loading={false} dataSource={value ?? []} columns={editColumns} />
      <Modal title={`表计选择`} size="big" open={visible} onCancel={onClose} destroyOnClose onOk={onOk}>
        <div>
          <Filter form={searchForm} onFinish={(val: any) => onFinish(val)} onReset={onReset}>
            <Col span={8}>
              <Form.Item name="electricityAccountId" label="用电账户">
                <Select placeholder="请选择">
                  {accountData?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.number}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="设备标签" name="deviceTag">
                <Input placeholder=" 请输入" />
              </Form.Item>
            </Col>
          </Filter>
          <div className={styles.title}>时间设置</div>
          <Form form={form}>
            <Row gutter={15}>
              <Col span={8}>
                <Form.Item
                  rules={[{ required: true, message: '请选择开始时间' }]}
                  label="开始时间"
                  name="startTime"
                  required
                >
                  <DatePicker
                    disabled={!data?.length}
                    disabledDate={startTimeValid}
                    placeholder=" 请选择"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  dependencies={['startTime']}
                  rules={[
                    { required: true, message: '请选择结束时间' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('startTime') <= value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('开始时间不得大于结束时间，且不得早于今天'));
                      },
                    }),
                  ]}
                  label="结束时间"
                  name="endTime"
                  required
                >
                  <DatePicker
                    disabled={!data?.length}
                    disabledDate={c => endTimeValid(c, form)}
                    placeholder=" 请选择"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className={styles.content}>
          <div className={styles.tips}></div>
          <Table
            showSelectedCount
            selectedCount={selectedRows?.length || 0}
            rowKey="id"
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            scroll={{ y: 300 }}
            sticky
            loading={isModalLoading}
            columns={modalColumns}
            dataSource={data}
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </div>
      </Modal>
      <Modal title={`时间设置`} width={500} open={!!timeData} onCancel={onTimeClose} destroyOnClose onOk={onTimeOk}>
        <Form form={timeForm}>
          <div className={styles.modal}>
            <Form.Item
              rules={[{ required: true, message: '请选择开始时间' }]}
              label="开始时间"
              name="startTime"
              required
            >
              <DatePicker
                // disabled={isEdit ? (timeData?.startTime ?
                //   dayjs(timeData.startTime) <= dayjs() : false)
                //   : false}
                disabledDate={startTimeValid}
                placeholder="请选择"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              dependencies={['startTime']}
              rules={[
                { required: true, message: '请选择结束时间' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('startTime') <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('开始时间不得大于结束时间，且不得早于今天'));
                  },
                }),
              ]}
              label="结束时间"
              name="endTime"
              required
            >
              <DatePicker
                disabledDate={c => endTimeValid(c, timeForm)}
                placeholder="请选择"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default MeterForm;
