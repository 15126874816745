import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Operator, OperatorDisplay } from '@/shared/types';
import {
  freezeSaleClientAccount,
  getSaleClientAccountPage,
  getSaleClientList,
  restoreSaleClientAccount,
  SaleClientAccountInfo,
  SaleClientAccountPageRequest,
  SaleClientAccountStatus,
  SaleClientAccountStatusDisplay,
  SaleClientInfo,
} from '../../../api/sale';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/utils';
import { timeFormatString } from '../util';

import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';

// const routes = [
//   {name: '运营配置'},
//   {name: '客户相关'},
//   {name: '客户账户'},
// ];

const columns: ColumnType<SaleClientAccountInfo>[] = [
  {
    title: '账户编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '账户名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '账户余额',
    dataIndex: 'balance',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? undefined ? `${v}元` : undefined} />,
  },
  {
    title: '暂估余额',
    dataIndex: 'estimateBalance',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? undefined ? `${v}元` : undefined} />,
  },
  {
    title: '交易凭证数',
    dataIndex: 'voucherNumber',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '账户状态',
    dataIndex: 'status',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: SaleClientAccountStatus) => <EllipsisSpan value={SaleClientAccountStatusDisplay[v]} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(timeFormatString)} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 180,
    ellipsis: { showTitle: true },
    render: (value: string, record) => {
      return (
        <EllipsisSpan
          value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
        />
      );
    },
  },
];

type FilterParams = Omit<SaleClientAccountPageRequest, 'page' | 'size'>;

const ClientAccount: FC = () => {
  const hasAdd = useHasPermission(PermissionsType.B_CLIENT_ACCOUNT_ADD);
  const hasUpdate = useHasPermission(PermissionsType.B_CLIENT_ACCOUNT_UPDATE);
  const hasFreezeRestore = useHasPermission(PermissionsType.B_FREEZE_RESTORE);
  const hasRechargeRefund = useHasPermission(PermissionsType.B_RECHARGE_REFUND);
  const hasManualDeduction = useHasPermission(PermissionsType.B_MANUALDEDUCTION);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();

  const [searchParams, setSearchParams] = useState<FilterParams>({});

  const [clientData, setClientData] = useState<SaleClientInfo[]>([]);

  const [data, setData] = useState<SaleClientAccountInfo[]>([]);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getSaleClientList().then(setClientData);
  }, []);

  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    setLoading(true);
    getSaleClientAccountPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: FilterParams) => {
    setPageOffset(1);
    setSearchParams(val);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const freezeRecord = (id: number) => {
    freezeSaleClientAccount(id).then(() => {
      updateFn();
    });
  };

  const restoreRecord = (id: number) => {
    restoreSaleClientAccount(id).then(() => {
      updateFn();
    });
  };

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item name="saleClientId" label="所属客户">
        <Select
          showSearch
          placeholder="请选择"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {(clientData ?? []).map(item => (
            <Select.Option key={item.id} value={item.id}>{`${item.name} ${item.code}`}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="status" label="账户状态">
        <Select placeholder="请选择">
          <Select.Option value={SaleClientAccountStatus.NORMAL}>
            {SaleClientAccountStatusDisplay[SaleClientAccountStatus.NORMAL]}
          </Select.Option>
          <Select.Option value={SaleClientAccountStatus.FREEZE}>
            {SaleClientAccountStatusDisplay[SaleClientAccountStatus.FREEZE]}
          </Select.Option>
          <Select.Option value={SaleClientAccountStatus.ARREARS}>
            {SaleClientAccountStatusDisplay[SaleClientAccountStatus.ARREARS]}
          </Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 430,
      fixed: 'right' as const,
      render: (value: undefined, record: SaleClientAccountInfo) => {
        return (
          <Space size={16}>
            {hasFreezeRestore &&
              (record.status === SaleClientAccountStatus.FREEZE ? (
                <Button
                  type="link"
                  onClick={() => {
                    restoreRecord(record.id);
                  }}
                >
                  恢复
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    freezeRecord(record.id);
                  }}
                >
                  冻结
                </Button>
              ))}
            {hasUpdate && (
              <Button type="link">
                <Link to={`/operation/client-related/client-account/edit/${record.id}`}>编辑</Link>
              </Button>
            )}
            {hasRechargeRefund && (
              <Button type="link">
                <Link to={`/operation/client-related/client-account/recharge-refund/${record.id}`}>充值/退款</Link>
              </Button>
            )}
            {hasManualDeduction && (
              <Button type="link">
                <Link to={`/operation/client-related/client-account/manual-debition/${record.id}`}>手动扣款</Link>
              </Button>
            )}
            <Button type="link">
              <Link to={`/operation/client-related/client-account/bills/${record.id}`}>账户流水</Link>
            </Button>
            <Button type="link">
              <Link to={`/operation/client-related/client-account/voucher/${record.id}`}>凭证</Link>
            </Button>
            <Button type="link">
              <Link to={`/operation/client-related/client-account/detail/${record.id}`}>查看</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space size="small">
          {hasAdd && (
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to="/operation/client-related/client-account/create">新建账户</Link>
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              const query = {
                saleClientId: form.getFieldValue('saleClientId'),
                status: form.getFieldValue('status'),
              };
              window.open(`/api/v2/sale/client/account/export?${qs.stringify(query)}`, '_blank');
            }}
          >
            导出
          </Button>
        </Space>
      </div>
      <Table rowKey="id" sticky scroll={{ x: 1300 }} loading={loading} dataSource={data} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ClientAccount;
