import { Button, EllipsisSpan, Key, Paging, Table, usePaging, useUpdate, Form, Input } from '@maxtropy/components';
import BindStationModal from '../BindStationModal';
import { useEffect, useState } from 'react';
import {
  OrderEnum,
  WorkOrderRes,
  WorkStationPageRes,
  WorkStationQuery,
  bindWorkStation,
  getWorkStationPageById,
} from '@/api/energyUnitMapping';
import dayjs from 'dayjs';
import { SorterResult } from 'antd/es/table/interface';
import { isNil } from 'lodash';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import styles from './index.module.scss';
import BindUnitModal from '@/pages/EnergyUnitMapping/components/BindUnitModal';

interface Props {
  onJumpToTreeItem: (v: Key[]) => void;
  contentType?: string;
  productionBaseId: number;
  workOrderLinkId?: Key;
  workCenterId?: Key;
  workOrderDetail?: WorkOrderRes;
  treeUpdateFn: () => void;
  searchUpdate: number;
  infoUpdateFn: () => void;
  activeKey: string;
}

const otherColumns = [
  {
    title: '所属工序',
    dataIndex: 'workProcedureName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工序编码',
    dataIndex: 'workProcedureCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const inOrderColumns = [
  {
    title: '工站编码',
    dataIndex: 'workStationCode',
    ellipsis: { showTitle: true },
    sorter: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定用能单元数',
    dataIndex: 'energyUnitAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'opUpdateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'opUpdateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<WorkStationQuery, 'page' | 'size' | 'workCenterId'> {}

const WorkStationTable: React.FC<Props> = ({
  onJumpToTreeItem,
  contentType,
  workOrderLinkId,
  workCenterId,
  workOrderDetail,
  treeUpdateFn,
  searchUpdate,
  infoUpdateFn,
  productionBaseId,
  activeKey,
}) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [bindStationModalOpen, setBindStationModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<WorkStationPageRes[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const hasBindStation = useHasPermission(PermissionsType.B_BINDINGSTATION); // 绑定工站
  const [form] = Form.useForm();
  const [workStationLinkId, setWorkStationLinkId] = useState<Key>();
  const [unitModalOpen, setUnitModalOpen] = useState<boolean>(false);

  const inOrderBuildColumns = [
    {
      title: '工站名称',
      dataIndex: 'workStationName',
      ellipsis: { showTitle: true },
      render: (v: string, row: WorkStationPageRes) => {
        return (
          <EllipsisSpan
            value={
              v ? (
                <Button
                  type="link"
                  onClick={() => {
                    onJumpToTreeItem([
                      `workStation-${row.workCenterId}-${row.workProcedureLinkId}-${row.linkId}-${row.workStationId}`,
                    ]);
                  }}
                >
                  {v}
                </Button>
              ) : undefined
            }
          />
        );
      },
    },
    ...inOrderColumns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: WorkStationPageRes) => (
        <Button type="link" onClick={() => onClickBindUnit(record.linkId)}>
          绑定用能单元
        </Button>
      ),
    },
  ];

  const inCenterBuildColumns = [
    {
      title: '工站名称',
      dataIndex: 'workStationName',
      ellipsis: { showTitle: true },
      render: (v: string, row: WorkStationPageRes) => {
        return (
          <EllipsisSpan
            value={
              v ? (
                <Button
                  type="link"
                  onClick={() => {
                    onJumpToTreeItem([
                      `workStation-${row.workCenterId}-${row.workProcedureLinkId}-${row.linkId}-${row.workStationId}`,
                    ]);
                  }}
                >
                  {v}
                </Button>
              ) : undefined
            }
          />
        );
      },
    },
    ...inOrderColumns.slice(0, 1),
    ...otherColumns,
    ...inOrderColumns.slice(1),
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: WorkStationPageRes) => (
        <Button type="link" onClick={() => onClickBindUnit(record.linkId)}>
          绑定用能单元
        </Button>
      ),
    },
  ];

  const onClickBindUnit = (id: Key) => {
    setWorkStationLinkId(id);
    setUnitModalOpen(true);
  };

  // 切换工序时，清空搜索条件
  useEffect(() => {
    if (contentType === 'workOrder') {
      form.resetFields();
      setSearchParams(undefined);
      setPageOffset(1);
    }
  }, [searchUpdate, setPageOffset, form, contentType]);

  useEffect(() => {
    if (contentType === 'productionBase' && productionBaseId) {
      setLoading(true);
      getWorkStationPageById({
        ...searchParams,
        productionBaseId,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (contentType === 'workCenter') {
      if (workCenterId && productionBaseId) {
        setLoading(true);
        getWorkStationPageById({
          ...searchParams,
          workCenterId,
          productionBaseId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (contentType === 'workOrder') {
      if (workCenterId && workOrderLinkId && productionBaseId) {
        setLoading(true);
        getWorkStationPageById({
          ...searchParams,
          workCenterId,
          productionBaseId,
          workProcedureLinkId: workOrderLinkId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    contentType,
    workOrderLinkId,
    workCenterId,
    searchParams,
    pageOffset,
    pageSize,
    setTotalCount,
    update,
    productionBaseId,
    activeKey,
  ]);

  //绑定工站
  const onBindStation = (v: Key[]) => {
    if (workOrderLinkId) {
      bindWorkStation({
        centerProcedureLinkId: workOrderLinkId,
        workStationIds: v,
      }).then(() => {
        updateFn();
        setPageOffset(1);
        treeUpdateFn();
        infoUpdateFn();
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {hasBindStation && contentType === 'workOrder' && (
          <div style={{ marginRight: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setBindStationModalOpen(true);
              }}
            >
              绑定工站
            </Button>
          </div>
        )}
        <Form form={form} className={styles.formItemStyles}>
          <Form.Item name="workStationCodeOrName" style={{ marginBottom: 10 }}>
            <div style={{ width: 280 }}>
              <Input.Search
                placeholder="工站编码/名称"
                onSearch={(v: string) => {
                  setSearchParams({
                    ...searchParams,
                    workStationCodeOrName: v === '' ? undefined : v,
                  });
                  setPageOffset(1);
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>

      <Table
        loading={loading}
        rowKey="linkId"
        dataSource={data}
        columns={contentType === 'workCenter' ? inCenterBuildColumns : inOrderBuildColumns}
        onChange={(pagination, filters, sorter) => {
          const { order } = sorter as SorterResult<WorkStationPageRes>;
          setSearchParams({
            ...searchParams,
            orderBy: isNil(order) ? undefined : order === 'ascend' ? OrderEnum.ASC : OrderEnum.DESC,
          });
          setPageOffset(1);
        }}
      />
      <Paging pagingInfo={pagingInfo} />
      {bindStationModalOpen && (
        <BindStationModal
          open={bindStationModalOpen}
          setOpen={setBindStationModalOpen}
          onBind={onBindStation}
          workOrderDetail={workOrderDetail}
          contentType={contentType}
          workOrderLinkId={workOrderLinkId}
        />
      )}
      {unitModalOpen && (
        <BindUnitModal
          infoUpdateFn={infoUpdateFn}
          open={unitModalOpen}
          setOpen={setUnitModalOpen}
          workStationLinkId={workStationLinkId}
          updateFn={updateFn}
          setPageOffset={setPageOffset}
        />
      )}
    </>
  );
};

export default WorkStationTable;
