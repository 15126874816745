import {
  EllipsisSpan,
  Table,
  Button,
  Wrapper,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Form,
  Input,
  Modal,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { meterProjectPagingItem } from '@/api/productionConsumptionBoard';
import { ReadingMeterPeriodFormat, ReadingMeterPeriod } from './CreateMeterReading/CreateMeter';
import { EnergyConsumptionRequest } from '../../api/MeterReadingProject';
import {
  BalanceAlarmListResponse,
  BalanceAlarmStatusType,
  BalanceAlarmStatusTypeValue,
} from '@/api/MeterReadingProject';
import { useHasPermission, useQuery } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import {
  apiV2UniverseMeterReadingProjectChangeStatusPost,
  apiV2UniverseMeterReadingProjectPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import qs from 'qs';
import { PlusOutlined } from '@ant-design/icons';

type SearchParams = Omit<EnergyConsumptionRequest, 'page' | 'size'>;

const alarmLevelOptions = () => {
  return Object.entries(ReadingMeterPeriodFormat).map(i => ({
    label: i[1],
    value: i[0],
  }));
};

const formatString = 'YYYY-MM-DD HH:mm';

// 抄表对象类型
export type readingObjectsType = {
  id: number;
  name: string;
};

// 覆盖能源介质
export type energyMediumsType = {
  id: number;
  name: string;
};

const columns: any = [
  {
    title: '抄表项目',
    ellipsis: true,
    dataIndex: 'projectName',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '抄表对象',
    ellipsis: true,
    dataIndex: 'readingObjects',
    render: (v: readingObjectsType[]) => <EllipsisSpan value={v?.map(item => item?.name ?? '--').join('、')} />,
  },
  {
    title: '覆盖能源介质',
    dataIndex: 'energyMediums',
    ellipsis: true,
    render: (v: energyMediumsType[]) => <EllipsisSpan value={v?.map(item => item?.name).join('、')} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    render: (v: BalanceAlarmStatusType) => <EllipsisSpan value={BalanceAlarmStatusTypeValue[v]} />,
  },
  {
    title: '抄表周期',
    ellipsis: true,
    dataIndex: 'readingPeriod',
    render: (v: ReadingMeterPeriod) => <EllipsisSpan value={ReadingMeterPeriodFormat[v]} />,
  },
  {
    title: '上次抄表时间',
    ellipsis: true,
    dataIndex: 'lastReadingTime',
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(formatString) : '--'} />,
  },
];
interface FilterParams extends Omit<meterProjectPagingItem, 'page' | 'size'> {}

const MeterReadingProject: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [updateState, updateFn] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, contextHolder] = Modal.useModal();

  const hasMetterReadingAdd = useHasPermission(PermissionsType.B_GLOBALMETERREADINGADD); // 新建抄表
  const hasMeterReading = useHasPermission(PermissionsType.B_GLOBALMETERREADING);
  const hasMeterReadingSwitch = useHasPermission(PermissionsType.B_GLOBALMETERREADINGSWITCH); // 禁用/启用
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let meterReadingObjectName = urlSearchParams.get('meterReadingObjectName') || undefined;
  let readingPeriod = urlSearchParams.get('readingPeriod') || undefined;
  let meterReadingProjectName = urlSearchParams.get('meterReadingProjectName') || undefined;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    meterReadingObjectName: urlSearchParams.get('meterReadingObjectName')?.length
      ? urlSearchParams.get('meterReadingObjectName')!
      : undefined,
    meterReadingProjectName: urlSearchParams.get('meterReadingProjectName')?.length
      ? urlSearchParams.get('meterReadingProjectName')!
      : undefined,
    readingPeriod: urlSearchParams.get('readingPeriod')?.length
      ? Number(urlSearchParams.get('readingPeriod')!)
      : undefined,
  });

  useEffect(() => {
    if (urlSearchParams) {
      form.setFieldsValue({
        meterReadingObjectName: meterReadingObjectName,
        readingPeriod: readingPeriod,
        meterReadingProjectName: meterReadingProjectName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSearchParams, form]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2UniverseMeterReadingProjectPagePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (isNil(res.list)) return;
          setTotalCount(res.total ?? 0);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [searchParams, setTotalCount, pageOffset, pageSize, updateState]
    )
  );

  const onFinish = (val: any) => {
    const params = {
      meterReadingProjectName: val.meterReadingProjectName,
      meterReadingObjectName: val.meterReadingObjectName,
      readingPeriod: val.readingPeriod,
    };
    const paramsMap = new Map<string, string>();
    Object.entries(params).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
    setSearchParams(params);
  };

  // 改变状态
  const onChangeStatus = (record: BalanceAlarmListResponse) => {
    apiV2UniverseMeterReadingProjectChangeStatusPost({
      id: record.id,
      status:
        record.status === BalanceAlarmStatusType.Enable
          ? BalanceAlarmStatusType.Disable
          : BalanceAlarmStatusType.Enable,
    })
      .then(res => {
        // message.success('修改状态成功');
        updateFn();
      })
      .catch(err => {
        // message.error('修改状态失败', err)
      });
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
    navigate(`?`);
  };

  const onExport = () => {
    navigate(`/energy/consumption/meterReadingProject/createMeterReading`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 140,
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            {hasMeterReadingSwitch && (
              <Button type="link" onClick={() => onChangeStatus(record)}>
                {record.status === BalanceAlarmStatusType.Disable ? '启用' : '禁用'}
              </Button>
            )}
            {hasMeterReading && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/energy/consumption/meterReadingProject/detail/${record.id}?${urlSearchParams.toString()}`);
                }}
              >
                详情
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper
      className="page_wrapper"
      filters={
        <div>
          <CustomFilter<FilterParams> form={form} onFinish={onFinish} onReset={onReset}>
            <Form.Item name="meterReadingProjectName" label="抄表项目">
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item name="meterReadingObjectName" label="抄表对象">
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item name="readingPeriod" label="抄表周期">
              <Select placeholder="请选择" options={alarmLevelOptions()} />
            </Form.Item>
          </CustomFilter>
        </div>
      }
      routes={[...(breadcrumbRoutes?.routes ?? [])]}
    >
      <div>
        {hasMetterReadingAdd && (
          <Button type="primary" icon={<PlusOutlined />} onClick={onExport}>
            新建抄表项目
          </Button>
        )}
        <Table
          rowKey={'id'}
          sticky
          scroll={{ x: 1300 }}
          loading={isLoading}
          dataSource={data}
          columns={buildColumns}
          style={{ marginTop: 10 }}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
      {contextHolder}
    </Wrapper>
  );
};

export default MeterReadingProject;
