import { FC } from 'react';
import ElectricitySalesContract from './ElectricitySalesContract/index';

// const routes = [
//     { name: '运营配置' },
//     { name: '物业管理合同' },
//     { name: '物业管理合同列表' },
// ];

const SalesContract: FC = () => {
  return <ElectricitySalesContract />;
};

export default SalesContract;
