import { Col, Form, Row } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import { CapacityDemandType, ElectricityBillBasicFee } from '../../../../api/electricity';
import { TwoPartSystemPowerConsumptionType } from '../../../../api/electricitySettlementRules';
import { units } from '../utils';
import { ShowInput } from '@maxtropy/components';

interface Props {
  isSubMeter: boolean;
  data?: ElectricityBillBasicFee;
  childData?: ElectricityBillBasicFee;
}

const ElectricityBillBasicCharge: FC<Props> = ({ isSubMeter, data, childData }) => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.boxBorder}>
          <Form layout="vertical">
            {isSubMeter ? (
              <Row>
                <Col span={3}>
                  <Form.Item label="需量示数">
                    <ShowInput value={childData?.demand?.toFixed(4)} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="倍率">
                    <ShowInput value={childData?.multiplyRatio} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="最大需量">
                    <ShowInput value={childData?.maxDemand?.toFixed(4)} addonAfter={units.千瓦} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="需量单价">
                    <ShowInput value={childData?.unitDemandRate?.toFixed(8)} addonAfter={units['元/千瓦']} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="按最大需量总金额">
                    <ShowInput value={childData?.demandFee?.toFixed(2)} addonAfter={units.元} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="力调费">
                    <ShowInput value={'--'} />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={3}>
                  <Form.Item label="受电容量">
                    <ShowInput value={data?.useElectricCapacity} addonAfter={units.千伏安} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="需量示数">
                    <ShowInput value={data?.demand?.toFixed(4)} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="倍率">
                    <ShowInput value={data?.multiplyRatio} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="最大需量">
                    <ShowInput value={data?.maxDemand?.toFixed(4)} addonAfter={units.千瓦} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="计费容量">
                    <ShowInput
                      value={data?.chargeCapacity}
                      addonAfter={data?.capacityDemandType === CapacityDemandType.CAPACITY ? units.千伏安 : units.千瓦}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  {
                    // 用电前报装
                    data?.declarationType === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && (
                      <Row>
                        <Col span={8}>
                          <Form.Item label="计费单价">
                            <ShowInput
                              value={data?.unitChargeRate?.toFixed(8)}
                              addonAfter={
                                data?.capacityDemandType === CapacityDemandType.CAPACITY
                                  ? units['元/千伏安']
                                  : units['元/千瓦']
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="计费总金额">
                            <ShowInput value={data?.chargeFee?.toFixed(2)} addonAfter={units.元} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item label="力调费">
                            <ShowInput value={data?.chargePowerFactorFee?.toFixed(2)} addonAfter={units.元} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  }
                  {
                    // 用电后报装
                    data?.declarationType === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION && (
                      <>
                        <Row>
                          <Col span={8}>
                            <Form.Item label="容量单价">
                              <ShowInput value={data?.unitCapacityRate?.toFixed(8)} addonAfter={units['元/千伏安']} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="按容总金额">
                              <ShowInput value={data?.capacityFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="力调费">
                              <ShowInput value={data?.capacityPowerFactorFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Form.Item label="需量单价">
                              <ShowInput value={data?.unitDemandRate?.toFixed(8)} addonAfter={units['元/千瓦']} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="按最大需量总金额">
                              <ShowInput value={data?.demandFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="力调费">
                              <ShowInput value={data?.demandPowerFactorFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={8}>
                            <Form.Item label="计费单价">
                              <ShowInput
                                value={data?.unitChargeRate?.toFixed(8)}
                                addonAfter={data?.capacityFee < data?.demandFee ? units['元/千伏安'] : units['元/千瓦']}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="计费总金额">
                              <ShowInput value={data?.chargeFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="力调费">
                              <ShowInput value={data?.chargePowerFactorFee?.toFixed(2)} addonAfter={units.元} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )
                  }
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};
export default ElectricityBillBasicCharge;
