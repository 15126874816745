import dayjs from 'dayjs';
import { useMemo } from 'react';
import { RangeValue } from './Header';

export const pollingInterval = 1000 * 60 * 5; // 5分钟轮询一次

// 时间颗粒度
export enum DateType {
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
  CUSTOM = 'C',
}

export const DateTypeDisplay = {
  [DateType.DAY]: '按日',
  [DateType.MONTH]: '按月',
  [DateType.YEAR]: '按年',
  [DateType.CUSTOM]: '自定义',
};

export const DateTypeDayjs = {
  [DateType.DAY]: 'd',
  [DateType.MONTH]: 'M',
  [DateType.YEAR]: 'y',
};

// 图标渲染的下一级
export const DateTypeNext = {
  [DateType.DAY]: 'H',
  [DateType.MONTH]: 'D',
  [DateType.YEAR]: 'M',
};

// 图标渲染的下一级
export const SimpleTimeGapFormat: Record<string, string> = {
  H: 'MM-DD HH:00',
  D: 'MM-DD',
  M: 'M' + '月',
};

// 图标渲染的下一级
export const WholeTimeGapFormat: Record<string, string> = {
  H: 'YYYY-MM-DD HH:00',
  D: 'YYYY-MM-DD',
  M: 'M' + '月',
};

// 图标渲染的下一级
export const MinIntervalFormat: Record<string, number> = {
  H: 60 * 1000 * 60 * 4,
  D: 60 * 1000 * 60 * 24,
  M: 60 * 1000 * 60 * 24 * 31,
};

/**
 *
 * @param targetContainer 目标容器
 * @param currentClientWidth 目标容器所在的宽度
 * @param currentClientWidth 目标容器所在的高度
 */
export const setFitScale = (
  targetContainer?: HTMLDivElement,
  currentClientWidth?: number,
  currentClientHeight?: number
) => {
  if (!targetContainer || !currentClientWidth || !currentClientHeight) return;
  const designWidth = 1920;
  // const designHeight = 1030;

  let scale = currentClientWidth / designWidth;

  // 实际容器的高度(减去页面顶部导航栏的高度)
  let containerHeight = document.documentElement.clientHeight - 50;

  console.log('当前containerHeight', containerHeight);

  /**
   * 1. 当前容器的宽度>=设计稿的宽度时，才进行缩放
   * 2. 先等比缩放, 只考虑宽度, 高度未撑满的话, 取容器高度, 然后再进行flex布局
   *
   */

  if (currentClientWidth >= designWidth) {
    // let currentH = containerHeight >= designHeight ? containerHeight : designHeight;
    let currentH = containerHeight;

    targetContainer.style.width = `${designWidth}px`;

    targetContainer.style.height = `${currentH / scale}px`;
    targetContainer.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    targetContainer.style.transformOrigin = 'top left';

    return;
  }
  targetContainer.style.width = '';
  targetContainer.style.height = '';
  targetContainer.style.transform = '';
  targetContainer.style.transformOrigin = '';
};

// 计算时间颗粒度, 如果时间跨度大于5日，则显示为日颗粒度；时间跨度小于等于5日，显示为小时颗粒度。

export const useComputeTimeResolution = (rangeTime?: RangeValue, resolution?: DateType) => {
  const res = useMemo(() => {
    if (!rangeTime) return '';
    if (!rangeTime[0] || !rangeTime[1]) return '';

    if (resolution === DateType.CUSTOM) {
      const [from, to] = rangeTime;
      return dayjs(to).diff(from, 'day') > 5 ? 'D' : 'H';
    } else {
      return DateTypeNext[resolution!];
    }
  }, [rangeTime]);

  let simpleTimeGap = SimpleTimeGapFormat[res as string];
  let wholeTimeGap = WholeTimeGapFormat[res as string];
  let minInterval = MinIntervalFormat[res as string];
  return { timeResolution: res, formateTimeStr: simpleTimeGap, minInterval, wholeTimeTimeStr: wholeTimeGap };
};

export const getBarToolTips = () => {
  return {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderColor: 'transparent',
    textStyle: {
      color: 'rgba(255,255,255,0.65)',
    },
    showDelay: 0,
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: 'rgba(255,255,255,0.1)',
        width: '1',
      },
    },
  };
};

// 判断瞬时还是累积, 理应接口返回相关字段, 现在先写死 2->瞬时, 1->累积
export const DataType: Record<string, number> = {
  '101': 2,
  '102': 2,
  '103': 1,
  '104': 1,
  '105': 2,
  '106': 2,
  '107': 1,
  '108': 2,
  '109': 1,
  '11': 2,
  '111': 2,
  '112': 2,
  '113': 2,
  '114': 2,
};
