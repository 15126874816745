import { useEffect, useState } from 'react';
import { Layout, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Radio, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import DragResize from '@/components/DragResize';
import dayjs, { Dayjs } from 'dayjs';
import {
  apiV2OeeApplyGetOeeCustomPost,
  V2OeeApplyGetOeeCustomPostResponse,
  apiV2OeeApplyGetOeeDayPost,
  V2OeeApplyGetOeeDayPostResponse,
  apiV2OeeApplyGetOeeMonthPost,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import DeviceStatistics from './components/DeviceStatistics';
import CustomProgress, { RankType } from './components/CustomProgress';
import DeviceUptime from './components/DeviceUptime';
import DeviceAnalysis from './components/DeviceAnalysis';
import StationTree from './components/StationTree';
import SubTitle from './components/subTitle';
import DeviceStopTime from './components/DeviceStopTime';
import CustomDateDeviceAnalysis from './components/CustomDateDeviceAnalysis';
import ComparisonModal from './Comparison';
import styles from './index.module.scss';

// 时间颗粒度
export enum DatePickerType {
  CUSTOM,
  DAY,
  MONTH,
}

export const DatePickerTypeDisplay = {
  [DatePickerType.CUSTOM]: {
    name: '自定义日期',
    api: apiV2OeeApplyGetOeeCustomPost,
    format: 'YYYY-MM-DD',
  },
  [DatePickerType.DAY]: {
    name: '按日',
    api: apiV2OeeApplyGetOeeDayPost,
    format: 'YYYY-MM',
  },
  [DatePickerType.MONTH]: {
    name: '按月',
    api: apiV2OeeApplyGetOeeMonthPost,
    format: 'YYYY',
  },
};

export interface BaseIds {
  baseId?: number;
  workCenterId?: number;
  procedureId?: number;
  stationId?: number;
  energyUnitId?: number;
}

const OEEApplication = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [uniqueCode, setUniqueCode] = useState<string>();
  const [open, setOpen] = useState<boolean>(false); // 对比弹窗
  const [dateType, setDateType] = useState<DatePickerType>(DatePickerType.DAY);
  const [date, setDate] = useState<Dayjs>(dayjs().subtract(1, 'month'));
  const [picker, setPicker] = useState<'date' | 'month' | 'year'>('month');
  const [data, setData] = useState<V2OeeApplyGetOeeDayPostResponse & V2OeeApplyGetOeeCustomPostResponse>();

  const hasCompare = useHasPermission(PermissionsType.B_COMPAREBUTTON); // 对比
  const hasExport = useHasPermission(PermissionsType.B_EXPORTBUTTON); // 导出

  useEffect(() => {
    if (!uniqueCode) return;
    DatePickerTypeDisplay[dateType]
      .api({
        date: date.format(DatePickerTypeDisplay[dateType].format),
        uniqueCode,
      })
      .then(res => {
        setData(res);
      });
  }, [date, picker, dateType, uniqueCode]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree setUniqueCode={setUniqueCode} />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={styles.deviceInfo}>
            <div className={styles.top}>
              <Space size={8} className={styles.switchPicker}>
                <Radio.Group
                  buttonStyle="solid"
                  defaultValue={dateType}
                  onChange={e => {
                    let newPicker = 'month';
                    let newDate = dayjs().subtract(1, 'month');
                    switch (e.target.value) {
                      case DatePickerType.CUSTOM:
                        newPicker = 'date';
                        newDate = dayjs().subtract(1, 'day');
                        break;

                      case DatePickerType.MONTH:
                        newPicker = 'year';
                        newDate = dayjs();

                        break;
                    }
                    setDateType(e.target.value);
                    setPicker(newPicker as 'date' | 'month' | 'year');
                    setDate(newDate);
                  }}
                >
                  {Object.entries(DatePickerTypeDisplay).map(([key, item]) => (
                    <Radio.Button value={Number(key)}>{item.name}</Radio.Button>
                  ))}
                </Radio.Group>
                <DatePicker value={date as Dayjs} picker={picker} style={{ width: 240 }} onChange={v => setDate(v)} />
              </Space>
              <Space size={8}>
                {hasCompare && (
                  <Button type="primary" onClick={() => setOpen(true)}>
                    对比
                  </Button>
                )}
                {hasExport && (
                  <Button
                    type="primary"
                    onClick={() => {
                      window.open(
                        `/api/v2/oee/apply/download?date=${date.format(
                          DatePickerTypeDisplay[dateType].format
                        )}&uniqueCode=${uniqueCode}`
                      );
                    }}
                  >
                    导出设备OEE报表
                  </Button>
                )}
              </Space>
            </div>
            <div className={styles.deviceBoard}>
              <div className={styles.item}>
                <DeviceStatistics data={data?.oeeRateInfo} />
              </div>
              <div className={styles.item}>
                <SubTitle title="设备OEE排名" style={{ marginBottom: 8 }} />
                <CustomProgress data={data?.oeePercentList} type={RankType.OEEPERCENT} />
              </div>
              <div className={styles.item}>
                <SubTitle title="设备停机时长排名" style={{ marginBottom: 8 }} />
                <CustomProgress data={data?.shutdownTimeList} type={RankType.SHUTDOWNTIME} />
              </div>
            </div>
            {/* 设备时间稼动 */}
            <DeviceUptime data={data?.baseOeeInfo} />
            {dateType === DatePickerType.CUSTOM && (
              <>
                {/* 设备OEE分析 */}
                <CustomDateDeviceAnalysis data={data?.baseOeeInfo} date={date} />
                {/* 停机时长 */}
                <DeviceStopTime data={data?.shutdownTimeList} date={date} />
              </>
            )}
            {dateType !== DatePickerType.CUSTOM && (
              <DeviceAnalysis
                timedInfoList={data?.timedInfoList}
                baseOeeInfoList={data?.baseOeeInfo}
                dateType={dateType}
              />
            )}
            {open && <ComparisonModal setOpen={setOpen} />}
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default OEEApplication;
