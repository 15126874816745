import {
  EllipsisSpan,
  Key,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Modal,
  Tag,
  Input,
  DatePicker,
  Radio,
  CustomFilter,
} from '@maxtropy/components';
import { FC, useEffect, useRef, useState } from 'react';

import { Col, Divider, Row, Space } from 'antd';
import { DeviceModalRefProps } from './DeviceModal';
import { useParams } from 'react-router-dom';
import {
  ElectricityAccountDeviceResponse,
  AccountDeviceRequest,
  bindDevices,
  deviceUpdateStatisticsDate,
  ElectricityAccountDeviceBindRequest,
  ElectricityAccountDeviceUpdateRequest,
  ElectricityAccountVo,
  ElectricityTypeDisplay,
  getElectricityAccount,
  getElectricityAccountDevice,
  unBindDevice,
  SortableItemProps,
  ElectricityTypeEnumDisplay,
  updateMeterLogicById,
} from '../../../../api/electricity';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import { useAdcodeToName, useHasPermission } from '../../../../utils/utils';
import SelectDevice from './components/SelectDevice';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { HasTimeOfUseElectricityPricePropsType } from '@/api/electricitySettlementRules';
import { PermissionsType } from '@/common/permissionsConst';

const editMeterLogicOptions = [
  { label: '尖峰平谷抄表取抄见数', value: 1 },
  { label: '尖峰平谷抄表与总有功配平', value: 2 },
];

const formLayout = {
  labelCol: { flex: '140px' },
};

const formatString = 'YYYY-MM-DD';

const routes = [{ name: '用电账户配置' }, { name: '设备管理' }];

const columns: ColumnType<ElectricityAccountDeviceResponse>[] = [
  {
    title: '设备编号',
    dataIndex: 'code',
    // width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    // width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '关联组织',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  // {
  //   title: '统计数据属性',
  //   dataIndex: 'dataPropertyName',
  //   width: 200,
  //   ellipsis: { showTitle: true },
  //   render: (v: string) => <EllipsisSpan value={v} />
  // },
  {
    title: '开始统计日期',
    dataIndex: 'statisticsDate',
    // width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface SearchParams extends Omit<AccountDeviceRequest, 'page' | 'size'> {}

const ElectricityAccountDevice: FC = () => {
  const hasModifyMeterReadingDataType = useHasPermission(PermissionsType.B_MODIFY_METEREADING_DATATYPE);
  const { id } = useParams<{ id: string }>();

  const [updateState, updateFn] = useUpdate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<ElectricityAccountDeviceResponse[]>([]);

  const [account, setAccount] = useState<ElectricityAccountVo>();

  const locationName = useAdcodeToName(account?.regionCode);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getElectricityAccount(id).then(setAccount);
    }
  }, [id]);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [editMeterLogicOpen, setEditMeterLogicOpen] = useState<boolean>(false);
  const [editMeterLogicForm] = Form.useForm();
  const [electricityTypeSoftList, setElectricityTypeSoftList] = useState<HasTimeOfUseElectricityPricePropsType[]>([
    2, 3, 4, 1,
  ]); // 初始值：峰平谷尖
  const [electricityTypeSoftList_modal, setElectricityTypeSoftList_modal] = useState<
    HasTimeOfUseElectricityPricePropsType[]
  >([2, 3, 4, 1]);
  const [accountDeviceLinkId, setAccountDeviceLinkId] = useState<Key>();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getElectricityAccountDevice({
        ...searchParams,
        electricityAccountId: Number(id),
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setDevices(res.list);
        setTotalCount(res.total);
        setLoading(false);
      });
    }
  }, [id, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams(undefined);
  };

  const unBind = (record: ElectricityAccountDeviceResponse) => {
    Modal.confirm({
      title: '是否解除绑定？',
      okText: '确认解除',
      onOk: () => {
        unBindDevice({
          deviceId: record.id,
          electricityAccountId: account?.id!,
        }).then(() => {
          setPageOffset(1);
          updateFn();
        });
      },
    });
  };

  const operationColumn = {
    title: '操作',
    fixed: 'right' as 'right',
    width: 300,
    render: (v: any, record: ElectricityAccountDeviceResponse) => (
      <Space size={16}>
        <Button
          type="link"
          onClick={() => {
            unBind(record);
          }}
        >
          解除绑定
        </Button>
        <Button
          type="link"
          onClick={() => {
            setRecord(record);
          }}
        >
          更改统计日期
        </Button>
        {hasModifyMeterReadingDataType && (
          <Button
            type="link"
            onClick={() => {
              editMeterLogicForm.setFieldsValue({
                meterReadingRecordDataType: record.meterReadingRecordDataType,
              });
              if (record.energyAdjustSort && record.energyAdjustSort.length > 0) {
                setElectricityTypeSoftList(record.energyAdjustSort);
                setElectricityTypeSoftList_modal(record.energyAdjustSort);
              }
              setAccountDeviceLinkId(record.accountDeviceLinkId);
              setEditMeterLogicOpen(true);
            }}
          >
            修改抄表逻辑
          </Button>
        )}
      </Space>
    ),
  };

  const bildColumns = [...columns, operationColumn];

  const hasLogicColumns = [
    ...columns,
    {
      title: '设备抄表逻辑状态',
      dataIndex: 'meterReadingRecordDataType',
      // width: 200,
      ellipsis: { showTitle: true },
      render: (v: HasTimeOfUseElectricityPricePropsType) =>
        v === 1 ? (
          <EllipsisSpan value={<Tag color="success">尖峰平谷取抄见数</Tag>} />
        ) : (
          <EllipsisSpan value={<Tag color="blue">尖峰平谷与总有功配平</Tag>} />
        ),
    },
    operationColumn,
  ];
  const filter = (
    <CustomFilter<SearchParams> form={form} onFinish={val => onFinish(val)} onReset={onReset}>
      <Form.Item label="设备编码" name="code">
        <Input placeholder=" 请输入" />
      </Form.Item>
      <Form.Item label="设备名称" name="name">
        <Input placeholder=" 请输入" />
      </Form.Item>
    </CustomFilter>
  );

  const [addForm] = Form.useForm();

  const [deviceIds, setDeviceIds] = useState<number[]>([]);

  const deviceRef = useRef<DeviceModalRefProps>(null);

  const [addVisible, setAddVisible] = useState<boolean>(false);

  const onAddCancel = () => {
    addForm.resetFields();
    setDeviceIds([]);
    setAddVisible(false);
  };

  const onAddSubmit = () => {
    if (deviceIds && deviceIds.length > 0) {
      addForm
        .validateFields()
        .then((value: Omit<ElectricityAccountDeviceBindRequest, 'electricityAccountId' | 'deviceIds'>) => {
          bindDevices({
            statisticsDate: dayjs(value.statisticsDate).format(formatString),
            recalculate: value.recalculate,
            electricityAccountId: Number(id),
            deviceIds,
          }).then(() => {
            updateFn();
            onAddCancel();
          });
        });
    }
  };

  const [updateStatisticsDateForm] = Form.useForm();

  const [record, setRecord] = useState<ElectricityAccountDeviceResponse>();

  const onCancel = () => {
    setRecord(undefined);
    updateStatisticsDateForm.resetFields();
  };

  const onUpdateStatisicsDateSubmit = () => {
    if (record) {
      updateStatisticsDateForm.validateFields().then((value: Omit<ElectricityAccountDeviceUpdateRequest, 'id'>) => {
        deviceUpdateStatisticsDate({
          id: record.accountDeviceLinkId,
          statisticsDate: dayjs(value.statisticsDate).format(formatString),
          recalculate: value.recalculate,
        }).then(() => {
          updateFn();
          onCancel();
        });
      });
    }
  };

  const editMeterLogicModalOnCancel = () => {
    setEditMeterLogicOpen(false);
    editMeterLogicForm.resetFields();
    setElectricityTypeSoftList_modal(electricityTypeSoftList);
  };

  const editMeterLogicModalOnOk = () => {
    setElectricityTypeSoftList(electricityTypeSoftList_modal);
    const params = {
      meterReadingRecordDataType: editMeterLogicForm.getFieldValue('meterReadingRecordDataType'),
      energyAdjustSort: electricityTypeSoftList_modal,
      accountDeviceLinkId,
    };
    if (accountDeviceLinkId) {
      updateMeterLogicById(params).then(() => {
        setEditMeterLogicOpen(false);
        updateFn();
      });
    }
  };

  const SortableItem = SortableElement<SortableItemProps>((sortableItemProps: SortableItemProps) => {
    const { item } = sortableItemProps;
    return (
      <li className={styles.liStyle}>
        <MenuOutlined style={{ marginRight: '10px' }} />
        {ElectricityTypeEnumDisplay[item]}
      </li>
    );
  });

  const SortableList = SortableContainer<{ items: HasTimeOfUseElectricityPricePropsType[] }>(
    ({ items }: { items: HasTimeOfUseElectricityPricePropsType[] }) => {
      return (
        <ul
          style={{
            padding: '0 0px',
            overflow: 'auto',
            cursor: 'move',
            border: '1px solid rgba(255,255,255,0.2)',
          }}
        >
          {items.map((item, index) => (
            <SortableItem key={`item-${item}`} sortIndex={index} index={index} item={item} />
          ))}
        </ul>
      );
    }
  );

  // 排序结束时调用的回调
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    let newData = arrayMove(electricityTypeSoftList_modal, oldIndex, newIndex);
    setElectricityTypeSoftList_modal(newData);
  };
  return (
    <>
      <Wrapper
        routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}
        filters={
          <>
            {!!account && (
              <Row gutter={48} wrap={false} style={{ marginBottom: 10 }}>
                <Col>
                  <span className={styles.top_label}> 用电账户户号:</span> {account.number}
                </Col>
                <Col>
                  <span className={styles.top_label}>所在区域:</span> {locationName}
                </Col>
                <Col>
                  <span className={styles.top_label}>用电种类:</span> {ElectricityTypeDisplay[account.type]}
                </Col>
              </Row>
            )}
            {!!account && <Divider style={{ marginBottom: 24 }} />}
            {filter}
          </>
        }
        className="page_wrapper"
      >
        <div className={styles.operationArea}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddVisible(true);
              }}
            >
              新增绑定
            </Button>
          </Space>
        </div>
        <Table
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={hasModifyMeterReadingDataType ? hasLogicColumns : bildColumns}
          dataSource={devices}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      <Modal
        size="big"
        title="设备选择"
        okText="确定"
        destroyOnClose
        open={addVisible}
        onCancel={onAddCancel}
        onOk={onAddSubmit}
      >
        <SelectDevice
          addForm={addForm}
          value={deviceIds}
          onChange={setDeviceIds}
          accountId={account?.id}
          ref={deviceRef}
        />
      </Modal>
      <Modal
        title="更改开始统计日期"
        open={!!record}
        contentClassName="modal-form-content"
        onCancel={onCancel}
        onOk={onUpdateStatisicsDateSubmit}
        okText="确认更改"
      >
        <Form
          labelAlign="left"
          labelWrap
          {...formLayout}
          form={updateStatisticsDateForm}
          // className={styles.form}
        >
          <Form.Item name="oldstatisticsDate" label="原生效日期">
            {record?.statisticsDate ? `${dayjs(record?.statisticsDate).format(formatString)}` : '--'}
          </Form.Item>
          <Form.Item name="statisticsDate" label="更改为" rules={[{ required: true, message: '请选择' }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="recalculate"
            label="是否重算"
            rules={[{ required: true, message: '请选择' }]}
            initialValue={false}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={800}
        title="修改抄表逻辑"
        open={editMeterLogicOpen}
        onCancel={editMeterLogicModalOnCancel}
        onOk={editMeterLogicModalOnOk}
        okText="继续"
      >
        <Form
          {...formLayout}
          initialValues={{
            meterReadingRecordDataType: 1,
          }}
          form={editMeterLogicForm}
        >
          <Form.Item name="meterReadingRecordDataType">
            <Radio.Group options={editMeterLogicOptions} />
          </Form.Item>
          <Form.Item noStyle dependencies={['meterReadingRecordDataType']}>
            {({ getFieldValue }) => {
              const meterReadingRecordDataType = getFieldValue('meterReadingRecordDataType');
              return meterReadingRecordDataType === 1 ? (
                <>
                  <Form.Item>
                    <ol style={{ paddingInlineStart: '15px' }}>
                      <li>抄表记录中尖峰平谷取电表抄见数；</li>
                      <li>核查联费用计算以抄见数为准；</li>
                      <li>表计中尖峰平谷与总有功差额一般将在下个计费周期补齐；</li>
                    </ol>
                  </Form.Item>
                  <p style={{ color: 'var(--mx-primary-color)' }}>
                    请注意，如客户已有过往抄表记录，切换配置可能导致尖峰平谷本月示数小于上月示数无法生成核查联，请谨慎修改。
                  </p>
                </>
              ) : (
                <>
                  <Form.Item>
                    <ol style={{ paddingInlineStart: '15px' }}>
                      <li>抄表记录中尖峰平谷取电表抄见数≠总有功时；</li>
                      <li>
                        差额=总有功-尖峰平谷抄见数加和；
                        <ul className={styles.thirdUl}>
                          <li>差额为正：直接加到第一顺位</li>
                          <li>差额为负：根据分配优先级依次扣除尖峰平谷增量示数</li>
                        </ul>
                      </li>
                      <li>将差额根据优先级分配到尖峰平谷计算；</li>
                      <li>配平包括尖峰平谷及反向尖峰平谷电量；</li>
                    </ol>
                  </Form.Item>
                  <Form.Item
                    name="energyAdjustSort"
                    wrapperCol={{ span: 24 }}
                    labelCol={{ span: 24 }}
                    label={
                      <p>
                        电量配平顺序<span style={{ fontSize: '13px' }}>（拖拽修改分配优先级，保存后生效）</span>
                      </p>
                    }
                  >
                    <SortableList
                      items={electricityTypeSoftList_modal}
                      onSortEnd={onSortEnd}
                      helperClass={styles.helperClass}
                    />
                  </Form.Item>
                  <p style={{ color: 'var(--mx-primary-color)' }}>
                    请注意，如客户已有过往抄表记录，切换配置可能导致尖峰平谷本月示数小于上月示数无法生成核查联，请谨慎修改。
                  </p>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ElectricityAccountDevice;
