import { MeterType, MeterTypeDisplay } from '@/api/energyAssetsBill';
import { limitDecimalsFTwo, limitDecimalsPTwo } from '@/pages/EnergyAssetsSettlementsRules/units';
import { Table, Form, InputNumber, ShowInput } from '@maxtropy/components';
import { ColumnsType } from 'antd/es/table';
import { isNil } from 'lodash-es';
import React from 'react';
import { DataType } from '../../CreateEnergyAssetsBill';
import { TimeIntervalType, TimeIntervalTypeDisplay } from '@/api/energyAssetsSettlementsRules';

interface Props {
  dataSource: DataType[];
  onChange: (dataSource: DataType[]) => void;
}

const InputTableItem: React.FC<Props> = ({ dataSource = [], onChange }) => {
  // 保存反向
  const saveReverse = (v: string, key: string) => {
    onChange(
      dataSource.map(item => {
        if (item.reverseKey === key) {
          return {
            ...item,
            reverse: v,
          };
        }

        return item;
      })
    );
  };

  // 保存正向
  const saveForward = (v: string, key: string) => {
    onChange(
      dataSource.map(item => {
        if (item.forwardKey === key) {
          return {
            ...item,
            forward: v,
          };
        }

        return item;
      })
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '电表信息',
      dataIndex: 'deviceCode',
      onCell: (record, rowIndex) => ({
        rowSpan: isNil(rowIndex) ? 1 : rowIndex % 4 === 0 ? 4 : 0,
      }),
      render: (v: string, record) => {
        return (
          <div>
            <p>编号：{v ?? '--'}</p>
            <p>名称：{record.deviceName ?? '--'}</p>
            <p>倍率：{record.rate ?? '--'}</p>
            <p>{MeterTypeDisplay[record.meterType]}</p>
          </div>
        );
      },
    },
    {
      title: '正向',
      dataIndex: 'forward',
      render: (v, record) => {
        const inputItem = (
          <Form.Item
            style={{ margin: 0 }}
            name={record.forwardKey}
            rules={[
              {
                required: true,
                message: `请输入`,
              },
            ]}
          >
            <InputNumber
              placeholder="请输入"
              step="0.01"
              style={{ width: '100%' }}
              formatter={limitDecimalsFTwo}
              parser={limitDecimalsPTwo}
              onBlur={e => {
                saveForward(e.target.value, record.forwardKey);
              }}
            />
          </Form.Item>
        );
        // 光伏关口表
        if (record.meterType === MeterType.GATEWAY) {
          return <ShowInput value={undefined} />;
        }
        return inputItem;
      },
    },
    {
      title: '反向',
      dataIndex: 'reverse',
      render: (v, record) => {
        const inputItem = (
          <Form.Item
            style={{ margin: 0 }}
            name={record.reverseKey}
            rules={[
              {
                required: true,
                message: `请输入`,
              },
            ]}
          >
            <InputNumber
              placeholder="请输入"
              step="0.01"
              style={{ width: '100%' }}
              formatter={limitDecimalsFTwo}
              parser={limitDecimalsPTwo}
              onBlur={e => {
                saveReverse(e.target.value, record.reverseKey);
              }}
            />
          </Form.Item>
        );
        // 光伏并网点
        if (record.meterType === MeterType.POINT_OF_INTEGRATION) {
          return <ShowInput value={undefined} />;
        }
        return inputItem;
      },
    },
    {
      title: '时段',
      dataIndex: 'interval',
      render: (v: TimeIntervalType) => <ShowInput value={TimeIntervalTypeDisplay[v]} />,
    },
  ];

  return <Table rowKey="reverseKey" dataSource={dataSource} columns={columns} />;
};

export default InputTableItem;
