import dayjs from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { scale } from '../../utils';
import { MediumCostItem } from '../EnergyCost';

interface Props {
  isFullScreen: boolean;
  mediumCost?: MediumCostItem[];
  isTwoCol: boolean;
  allDataIsOverMillion: boolean;
  outerRef: any;
}

const BarEcharts: React.FC<Props> = ({ outerRef, allDataIsOverMillion, isFullScreen, mediumCost, isTwoCol }) => {
  const getChartOption = () => {
    return {
      grid: {
        left: 60 * zoom,
        right: 20,
        // top: 20,
        bottom: 20 * zoom,
      },
      tooltip: {
        transitionDuration: 0,
        show: true,
        // trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        // borderRadius: 2,
        // borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12 * zoom,
        },
        borderColor: 'rgba(0,0,0,0.8)',
        formatter: function (params: any) {
          const { data } = params;

          return `<p>${dayjs(data?.at(0)).format('YYYY-MM-DD')}</p><p>
          <span style='width:${8 * zoom}px; height:${
            8 * zoom
          }px; background:rgba(86,231,255,0.95);border-radius:50%;display:inline-block'></span> ${data?.at(1)} ${
            allDataIsOverMillion ? '万元' : '元'
          } </p>`;
        },
      },
      xAxis: {
        type: 'time',
        minInterval: 60 * 1000 * 60 * 24,
        axisLabel: {
          formatter: function (e: number) {
            return dayjs(e, 'x').format('MM-DD');
          },
          fontSize: 12 * zoom,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: allDataIsOverMillion ? '万元' : '元',
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
          fontSize: 12 * zoom,
        },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
        axisLabel: {
          fontSize: 12 * zoom,
        },
      },
      legend: {
        show: false,
      },
      series: {
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: mediumCost?.map(i => [i?.ts, i?.mediumCharges]),
        itemStyle: {
          color: 'rgba(86,231,255,0.95)',
        },
      },
    };
  };
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  return (
    <>
      <ReactEcharts
        option={getChartOption()}
        notMerge
        lazyUpdate={false}
        style={
          isTwoCol
            ? { width: '100%', height: 350 }
            : { height: '85%', width: '100%', minHeight: 200, zoom: `${1 / zoom}`, transform: `scale(${scale})` }
        }
      />
    </>
  );
};

export default BarEcharts;
