// 相关变量
export enum VariableType {
  PRODUCTION_RATE = 1,
  TIME = 2,
}
export const VariableTypeFormat = {
  [VariableType.PRODUCTION_RATE]: '生产速率',
  [VariableType.TIME]: '时间',
};

// 基线周期
export enum BaseLineCycle {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'date',
}

export const BaseLineCycleFormat = {
  [BaseLineCycle.YEAR]: '年',
  [BaseLineCycle.MONTH]: '月',
  [BaseLineCycle.WEEK]: '周',
  [BaseLineCycle.DAY]: '日',
};

// 基线时间
export enum BaseLineTime {
  HOUR = 'H',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'D',
  MINUTE = '15M',
}

export const BaseLineTimeFormat = {
  [BaseLineTime.MONTH]: '月',
  [BaseLineTime.WEEK]: '周',
  [BaseLineTime.DAY]: '日',
  [BaseLineTime.HOUR]: '小时',
  [BaseLineTime.MINUTE]: '15分钟',
};
//statisticalModel
export enum StatisticalModel {
  LINEAR_FIT = 1,
  EXPONENTIAL_FIT = 2,
  LOGARITHMIC_FIT = 4,
}
export const StatisticalModelFormat = {
  [StatisticalModel.LINEAR_FIT]: '线性拟合',
  [StatisticalModel.EXPONENTIAL_FIT]: '指数拟合',
  [StatisticalModel.LOGARITHMIC_FIT]: '多项式拟合',
};

// 基线周期
export enum DetailBaseLineCycle {
  YEAR = 'Y',
  MONTH = 'M',
  WEEK = 'W',
  DAY = 'D',
}

export const DetailBaseLineCycleFormat = {
  [BaseLineCycle.YEAR]: '年',
  [BaseLineCycle.MONTH]: '月',
  [BaseLineCycle.WEEK]: '周',
  [BaseLineCycle.DAY]: '日',
};

// Simultaneous
export enum Granularity {
  SIMULTANEOUS = 1,
  ORDER = 2,
}
export const GranularityFormat = {
  [Granularity.SIMULTANEOUS]: '同时序数据',
  [Granularity.ORDER]: '订单数据',
};

export enum BaselineType {
  FIX = 1,
  SCROLL = 2,
}
export const BaselineTypeFormat = {
  [BaselineType.FIX]: '固定基线',
  [BaselineType.SCROLL]: '滚动基线',
};

export enum ScrollBaseLineCycle {
  WEEK = 1,
  MONTH = 2,
  QUARTER = 3,
  HALF = 4,
  YEAR = 5,
}

export const ScrollBaseLineCycleFormat = {
  [ScrollBaseLineCycle.WEEK]: '近一周',
  [ScrollBaseLineCycle.MONTH]: '近一月',
  [ScrollBaseLineCycle.QUARTER]: '近三月',
  [ScrollBaseLineCycle.HALF]: '近六月',
  [ScrollBaseLineCycle.YEAR]: '近一年',
};

// 统计模型
export const statisticalModelOptions = [
  { value: StatisticalModel.LINEAR_FIT, label: StatisticalModelFormat[StatisticalModel.LINEAR_FIT] },
  {
    value: StatisticalModel.EXPONENTIAL_FIT,
    label: StatisticalModelFormat[StatisticalModel.EXPONENTIAL_FIT],
  },
  {
    value: StatisticalModel.LOGARITHMIC_FIT,
    label: StatisticalModelFormat[StatisticalModel.LOGARITHMIC_FIT],
  },
];

// 数据来源
export const dataOriginOptions = [
  {
    value: Granularity.ORDER,
    label: GranularityFormat[Granularity.ORDER],
  },
  {
    value: Granularity.SIMULTANEOUS,
    label: GranularityFormat[Granularity.SIMULTANEOUS],
  },
];

// 基线类型
export const lineTyoeOptions = [
  {
    value: BaselineType.FIX,
    label: BaselineTypeFormat[BaselineType.FIX],
  },
  {
    value: BaselineType.SCROLL,
    label: BaselineTypeFormat[BaselineType.SCROLL],
  },
];

export const baseLineCycleOptions = [
  {
    value: BaseLineCycle.YEAR,
    label: BaseLineCycleFormat[BaseLineCycle.YEAR],
  },
  {
    value: BaseLineCycle.MONTH,
    label: BaseLineCycleFormat[BaseLineCycle.MONTH],
  },
  {
    value: BaseLineCycle.WEEK,
    label: BaseLineCycleFormat[BaseLineCycle.WEEK],
  },
  {
    value: BaseLineCycle.DAY,
    label: BaseLineCycleFormat[BaseLineCycle.DAY],
  },
];
