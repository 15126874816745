import { UetInfoProps } from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import FixedBottomButton from '@/components/FixedBottomButton';
import { List } from '@/pages/UET/Edit/BackUpPower/types';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  EllipsisSpan,
  message,
  Modal,
  Paging,
  Table,
  usePaging,
  useTenantPermissions,
  useUpdate,
} from '@maxtropy/components';

import { Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { apiV2UetPageBsaSecondEditionPost } from '@maxtropy/device-customer-apis-v2';
import BsaNewModal from './components/BsaNewModal';

const otherColumns = [
  {
    title: '储能站编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '储能站名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '额定功率',
    dataIndex: 'ratedPower',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '硬件版本',
    dataIndex: 'hardwareVersion',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface IProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}
const BsaNewList: React.FC<IProps> = ({ info, isDetail }) => {
  const { id } = useParams<{ id: string }>();
  const [update, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [currentRowInfo, setCurrentRowInfo] = useState<List>();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;

  const permission = useTenantPermissions();

  const showAddBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_BSA_NEW_ADD) && !isDetail;
  }, [isDetail, permission]);
  const showEditBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_BSA_NEW_EDIT) && !isDetail;
  }, [isDetail, permission]);

  const { data: list, loading } = useRequest(
    () => {
      return apiV2UetPageBsaSecondEditionPost({
        page: pageOffset,
        size: pageSize,
        uetId: Number(id),
        mcid: info?.mcid,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      ready: !!id && !!info?.mcid,
      refreshDeps: [id, info?.mcid, update],
    }
  );

  const onAddLoad = () => {
    setModalVisible(true);
  };

  const onEditBackUpPower = (record: List) => {
    setCurrentRowInfo(record);
    setModalVisible(true);
  };

  const onSave = () => {
    const unfinished = list?.some(i => !i.id);
    if (unfinished) {
      message.warning('有未保存的负载');
    } else {
      setEdit(false);
    }
  };

  const onCancel = () => {
    Modal.confirm({
      title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
      onOk: () => {
        forceUpdate();
        setEdit(false);
      },
    });
  };

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: any) =>
        showEditBtn && (
          <Button type="link" disabled={btnDisabled} onClick={() => onEditBackUpPower(record)}>
            编辑储能站
          </Button>
        ),
    },
  ];

  return (
    <>
      {showAddBtn && (
        <Button type="primary" onClick={onAddLoad} disabled={btnDisabled}>
          <PlusOutlined />
          新建储能站
        </Button>
      )}

      <Table
        loading={loading}
        style={{ marginTop: 12 }}
        rowKey="id"
        dataSource={list}
        columns={columns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>

      {modalVisible && (
        <BsaNewModal
          modalVisible={modalVisible}
          setModalVisible={val => setModalVisible(val)}
          ouList={info?.ous}
          currentRowInfo={currentRowInfo}
          setCurrentRowInfo={val => setCurrentRowInfo(val)}
          forceUpdate={() => forceUpdate()}
        />
      )}
    </>
  );
};

export default BsaNewList;
