import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import {
  apiV2CarbonFootprintOverviewLcaColumnarPost,
  V2CarbonFootprintOverviewLcaColumnarPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Empty } from '@maxtropy/components';
import { transCarbonFootprintQuantity } from '../../utils';
import styles from './index.module.scss';

const ModeledProducts = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<V2CarbonFootprintOverviewLcaColumnarPostResponse>();

  useEffect(() => {
    apiV2CarbonFootprintOverviewLcaColumnarPost({})
      .then(res => {
        setData(res);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <div className={styles.modeledProductChart}>
      <div className={styles.modeledProductChartTitle}>已建模产品{data?.count! > 10 && '(TOP10)'}</div>
      {loading ? (
        <Spin spinning={loading} />
      ) : data?.count ? (
        <ReactEcharts
          style={{
            width: '100%',
            height: '100%',
          }}
          option={getChartOption(data)}
          notMerge
          lazyUpdate={false}
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

const getChartOption = (data: V2CarbonFootprintOverviewLcaColumnarPostResponse) => {
  return {
    grid: {
      left: 10,
      bottom: 0,
      right: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 0, 12],
      className: 'carbon-chart-tooltip',
      formatter: (tooltipData: any) => {
        const title = tooltipData[0]?.name ?? '--';
        let total = 0;
        tooltipData.forEach((item: any) => {
          total += item.value;
        });

        return `
          <div class='carbon-tooltip-title'>${title}</div>
          <div><div style='width:8px;height:8px;background:${
            tooltipData[0]?.color
          };border-radius:50%;margin-right:8px'></div> ${
          tooltipData[0]?.seriesName ?? '--'
        }<span>${transCarbonFootprintQuantity(tooltipData[0]?.value).join(' ')}</span></div>
          <div><div style='width:8px;height:8px;background:${
            tooltipData[1]?.color
          };border-radius:50%;margin-right:8px'></div> ${
          tooltipData[1]?.seriesName ?? '--'
        }<span>${transCarbonFootprintQuantity(tooltipData[1]?.value).join(' ')}</span></div>
          <div ><div style='width:8px;height:8px;background:${
            tooltipData[2]?.color
          };border-radius:50%;margin-right:8px'></div> ${
          tooltipData[2]?.seriesName ?? '--'
        }<span>${transCarbonFootprintQuantity(tooltipData[2]?.value).join(' ')}</span></div>
          <div><div style='width:8px;height:8px;background:transparent;border-radius:50%;margin-right:8px'></div>汇总<span>${transCarbonFootprintQuantity(
            total
          ).join(' ')}</span></div>
        `;
      },
    },
    legend: {
      right: 0,
      top: 0,
      bottom: 8,
      icon: 'rect',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      itemWidth: 12,
      itemHeight: 12,
      itemGap: 24,
      padding: [0, 8, 0, 0],
    },
    xAxis: {
      type: 'category',
      data: data?.nameList,
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 8,
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
    },
    yAxis: {
      type: 'value',
      name: 'kg CO2e',
      nameGap: 8,
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.3)',
        },
      },
      nameTextStyle: {
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
      axisLine: {},
      axisLabel: {
        margin: 8,
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
    },
    series: [
      {
        data: data?.materialCarbonList,
        type: 'bar',
        name: '原材料',
        barWidth: 16,
        stack: 'product',
        itemStyle: {
          color: '#16DD8E',
        },
      },
      {
        data: data?.materialTransportCarbonList,
        type: 'bar',
        name: '原材料运输',
        stack: 'product',
        barWidth: 16,
        itemStyle: {
          color: '#2D8DFF',
        },
      },
      {
        data: data?.productCarbonList,
        type: 'bar',
        name: '生产',
        stack: 'product',
        barWidth: 16,
        itemStyle: {
          color: '#FFCB47',
        },
      },
    ],
  };
};
export default ModeledProducts;
