import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  EllipsisSpan,
  usePaging,
  Paging,
  Form,
  Modal,
  Radio,
  Input,
  Table,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getFcsInfo, getFcsPwd, getPasscodeLog, PasscodeLogItem, PwdReq, setFcsPwd } from '../../../api/fcs';
import dayjs from 'dayjs';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

const routes = [{ name: '就地端锁屏密码配置' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const columns = [
  {
    title: '时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作内容',
    dataIndex: 'operationName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作人',
    dataIndex: 'staffName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const FcsLock: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [logList, setLogList] = useState<PasscodeLogItem[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [prevState, setPrevState] = useState<PwdReq>(); // 上一次的状态
  const [disabled, setDisabled] = useState<boolean>(true);
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getFcsInfo(id).then(res => {
        form.setFieldsValue({ ...res });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getFcsPwd(id)
        .then(res => {
          form.setFieldsValue({ ...res });
          setPrevState({ passcode: res.passcode, onOff: res.onOff });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, xx]);

  useEffect(() => {
    if (id) {
      getPasscodeLog(id, { page: pageOffset, size: pageSize }).then(res => {
        setLogList(res.list);
        setTotalCount(res.total);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, id]);

  // 更新下发
  const updateIssued = () => {
    if (!id) return;
    let { passcode, onOff } = form.getFieldsValue();
    setFcsPwd(id, { passcode, onOff }).then(res => {
      if (res) {
        Modal.success({
          content: '下发成功！',
          onOk() {
            forceUpdate();
          },
        });
      } else {
        Modal.info({ content: '下发失败，请稍后重试！' });
      }
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <Form
        form={form}
        initialValues={{ onOff: false }}
        layout="vertical"
        {...formLayout}
        onValuesChange={(_, allValues) => {
          const { passcode, onOff } = allValues;
          let isSame = prevState?.passcode === passcode && prevState?.onOff === onOff;
          setDisabled(isSame);
        }}
      >
        <FormTitle title={routes[0].name} />
        <SubContent>
          <Row>
            <Col span={12}>
              <Form.Item label="FCS编号" name="serialNumber">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="FCS名称" name="name">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="就地连接状态" dependencies={['online']} required>
                {({ getFieldValue }) => {
                  let online = getFieldValue(['online']);
                  return (
                    <>
                      <span style={online ? { color: 'var(--mx-primary-color)' } : { color: 'rgba(255,255,255,0.5)' }}>
                        {online ? '在线' : '离线'}
                      </span>
                      <Button
                        type="link"
                        icon={loading ? <LoadingOutlined /> : <ReloadOutlined />}
                        onClick={() => forceUpdate()}
                      >
                        刷新
                      </Button>
                    </>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="是否开启锁屏保护" name="onOff" required>
                <Radio.Group>
                  <Radio value={false}>否</Radio>
                  <Radio value={true}>是</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="就地锁屏密码"
                name="passcode"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (value.length !== 6) {
                        return Promise.reject('锁屏密码不足六位，请继续输入');
                      }
                      let reg = /^\d{6}$/;
                      if (!reg.test(value)) {
                        return Promise.reject('只能输入六位数字');
                      }
                      // 判断是否为连续数字
                      let arr = value.split('');
                      let flag = true;
                      for (let i = 0; i < arr.length - 1; i++) {
                        if (Number(arr[i]) + 1 !== Number(arr[i + 1])) {
                          flag = false;
                          break;
                        }
                      }
                      // 判断是否为重复数字
                      let max = 1;
                      arr.reduce((preVal: Record<string, number>, curVal: string) => {
                        preVal[curVal] ? preVal[curVal]++ : (preVal[curVal] = 1);
                        if (preVal[curVal] > max) {
                          max++;
                        }
                        return preVal;
                      }, {});
                      if (flag || max >= 5) {
                        return Promise.reject('密码过于简单，请重新输入');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password maxLength={6} />
              </Form.Item>
            </Col>
          </Row>
          <p>操作日志</p>
          <Table columns={columns} dataSource={logList} pagination={false} />
          <Paging pagingInfo={pagingInfo} />
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button type="primary" onClick={() => updateIssued()} disabled={disabled}>
            更新下发
          </Button>
          <Button>
            <Link to={`/device/fcs/list`}>返回</Link>
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default FcsLock;
