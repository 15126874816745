import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DriveType, IotProtocolType } from '@/shared/types';
import useIsGateway from '@/shared/hooks/useIsGateway';

export interface UseDaqFormStateProps {
  iotProtocolType: IotProtocolType | undefined;
  setIotProtocolType: Dispatch<SetStateAction<IotProtocolType | undefined>>;
  deviceType: Array<number> | undefined;
  setDeviceType: Dispatch<SetStateAction<Array<number> | undefined>>;
  templateVersionId: number | undefined;
  setTemplateVersionId: Dispatch<SetStateAction<number | undefined>>;
  driveType: DriveType | undefined;
  setDriveType: Dispatch<SetStateAction<DriveType | undefined>>;
  objectModalType: number | undefined;
  setObjectModalType: Dispatch<SetStateAction<number | undefined>>;
}

export function useDaqFormState(): UseDaqFormStateProps {
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType | undefined>();
  const [deviceType, setDeviceType] = useState<Array<number> | undefined>();
  const [templateVersionId, setTemplateVersionId] = useState<number | undefined>();
  const [driveType, setDriveType] = useState<DriveType | undefined>(DriveType.MODBUS_RTU);
  const { isGateway } = useIsGateway(deviceType);
  const [objectModalType, setObjectModalType] = useState<number>(); // 物模型型号

  useEffect(() => {
    if (isGateway) {
      setDriveType(undefined);
    }
  }, [isGateway]);

  return {
    iotProtocolType,
    setIotProtocolType,
    deviceType,
    setDeviceType,
    templateVersionId,
    setTemplateVersionId,
    driveType,
    setDriveType,
    objectModalType,
    setObjectModalType,
  };
}
