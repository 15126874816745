import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  Tag,
  useBreadcrumbRoutes,
  Paging,
  Form,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Space } from 'antd';
import { useState } from 'react';
import AddEnergyModal from './AddEnergyModal';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2EnergyDeletePost,
  apiV2EnergyPagePost,
  V2EnergyPagePostRequest,
  V2EnergyPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import DetailEnergyModal from './DetailEnergyModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { ColumnsType } from 'antd/es/table';
import styles from './index.module.scss';

export type EnergyItem = Exclude<V2EnergyPagePostResponse['list'], undefined>[number];

export enum StatusType {
  DISABLE = 0, // 禁用
  ENABLE = 1, // 启用
}

export const StatusDisplay = {
  [StatusType.DISABLE]: '禁用',
  [StatusType.ENABLE]: '启用',
};

const statusOptions = [
  { label: '全部', value: '' },
  { label: '启用', value: StatusType.ENABLE },
  { label: '禁用', value: StatusType.DISABLE },
];

const columns: ColumnsType<EnergyItem> = [
  {
    title: '能源编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '能源名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '能源工质类型',
    dataIndex: 'energyMediumName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '碳足迹',
    dataIndex: 'carbonFootPrint',
    ellipsis: { showTitle: true },
    render: (v, record) => <EllipsisSpan value={`${v}kgCO2e/${record?.physicalUnitName}`} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: StatusType) =>
      v === StatusType.ENABLE ? (
        <Tag border="solid" color="success">
          启用
        </Tag>
      ) : (
        <Tag border="solid" color="invalid">
          禁用
        </Tag>
      ),
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<V2EnergyPagePostRequest, 'page' | 'size'> {}

const EnergyManagement: React.FC = () => {
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const pagingInfo = useSearchParams<SearchParams>(20);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDENERGY); // 新建
  const hasEdit = useHasPermission(PermissionsType.B_EDITENERGY); // 编辑
  const hasDelete = useHasPermission(PermissionsType.B_DELENERGY); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, loading } = useRequest(
    () =>
      apiV2EnergyPagePost({ ...searchParams, page: searchParams.pageOffset, size: searchParams.pageSize }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2EnergyDeletePost({ id }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setSelectedId(record.id);
                setDetailModalOpen(true);
              }}
            >
              查看
            </Button>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  setSelectedId(record.id);
                  setModalOpen(true);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm placement="top" title="是否删除此物料？" onConfirm={() => onDelete(record.id)}>
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };
  const onReset = () => {
    reset({
      state: StatusType.ENABLE,
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: StatusType.ENABLE,
      }}
    >
      <Form.Item label="状态" name="state">
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item label="能源" name="nameOrCode">
        <Input placeholder="请输入能源编码/名称搜索" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      {hasAdd && (
        <Space style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedId(undefined);
              setModalOpen(true);
            }}
          >
            新建能源
          </Button>
        </Space>
      )}
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
      {modalOpen && (
        <AddEnergyModal open={modalOpen} selectedId={selectedId} updateData={() => updateFn()} setOpen={setModalOpen} />
      )}
      {detailModalOpen && (
        <DetailEnergyModal open={detailModalOpen} setOpen={setDetailModalOpen} selectedId={selectedId} />
      )}
    </Wrapper>
  );
};

export default EnergyManagement;
