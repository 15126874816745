import { getSaleClientTagList } from '../../../../../api/sale';
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { DeviceModalProps } from './DeviceModal';
import { Col, Row, Tag, Tooltip, AutoComplete, Input, Space } from 'antd';
import styles from './DeviceModal.module.scss';

export interface SaleClientMeterTags {
  id: number;
  name: string;
  saleClientId: number;
  createTime: string;
  updateTime: string;
}

const MeterTags: ForwardRefRenderFunction<string[], DeviceModalProps> = ({ saleClientId, buttonStatus }, ref) => {
  const [defaultTags, setDefaultTags] = useState<string[]>([]);
  const [customTags, setCustomTags] = useState<string[]>([]);

  const [inputValue, setInputValue] = useState<string | undefined>('');

  useImperativeHandle(ref, () => customTags, [customTags]);

  useEffect(() => {
    if (saleClientId) {
      getSaleClientTagList(saleClientId).then(res => {
        setDefaultTags([...(res.list ?? []).map(i => i.name)]);
      });
    }
  }, [saleClientId]);

  const handleClose = (removedTag: string) => {
    setCustomTags(customTags.filter(val => val !== removedTag));
    setDefaultTags([...defaultTags, removedTag]);
  };

  const onClick = (value: string) => {
    setInputValue('');
    setCustomTags([...customTags, value]);
    setDefaultTags([...defaultTags.filter(val => val !== value)]);
  };

  const handleInputConfirm = () => {
    if (inputValue && customTags.indexOf(inputValue) === -1) {
      setCustomTags([...customTags, inputValue]);
    }
    setInputValue('');
  };

  const DisplayTags: React.FC = () => {
    return (
      <Row align="middle" style={{ marginLeft: 35 }}>
        <Col span={20} offset={customTags.length === 3 ? 0 : 4}>
          {customTags.map((tag: string) => {
            const tagElem = (
              <Tag key={tag} closable onClose={() => handleClose(tag)}>
                {tag}
              </Tag>
            );
            return (
              <Space key={tag} align="center">
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              </Space>
            );
          })}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <Space style={{ marginTop: 2 }} className={styles.meterTags}>
          自定义表计分组标签：
        </Space>
        {customTags.length === 3 ? (
          <Col span={12}>
            <DisplayTags />
          </Col>
        ) : (
          <>
            <Col span={18}>
              <AutoComplete
                allowClear
                style={{ width: 300 }}
                value={inputValue}
                onSelect={onClick}
                searchValue={inputValue}
                onBlur={handleInputConfirm}
                options={defaultTags.map(str => {
                  return { value: str, text: str };
                })}
                onChange={setInputValue}
                placeholder="请输入标签名称"
                filterOption={(inputValue: string, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                <Input onPressEnter={handleInputConfirm} status={customTags.length >= 1 ? '' : buttonStatus} />
              </AutoComplete>
            </Col>
          </>
        )}
      </Row>
      {customTags.length === 0 && buttonStatus === 'error' && (
        <Row style={{ marginLeft: 9 }}>
          <Col offset={3} span={20}>
            <span style={{ color: '#ff4d4f', marginLeft: 50 }}>请先选择标签</span>
          </Col>
        </Row>
      )}
      {customTags.length === 3 ? undefined : <DisplayTags />}
      <div style={{ color: 'var(--mx-text-secondary-color)', marginTop: 5 }}>
        注释：添加自定义设备标签，提高对设备的辨识度，例如，当同一表计即是电量表计，也可能是需量表计。
      </div>
    </>
  );
};
export default forwardRef(MeterTags);
