import { FC, useEffect } from 'react';
import { FormInstance, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Button, DatePicker, Form, message, Radio, Select } from '@maxtropy/components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { V2OeeApplyOeeDevicesPostResponse } from '@maxtropy/device-customer-apis-v2';
import { colors, getDefaultDate } from '../util';
import { StatisticsPartition, DatePickerTypeDisplay, MonitorIndicatorDisplay } from '../../type';
import styles from '../index.module.scss';

const { RangePicker } = DatePicker;

export interface IHistoryCompare {
  form: FormInstance<any>;
  deviceData?: V2OeeApplyOeeDevicesPostResponse['list'];
}

const HistoryCompare: FC<IHistoryCompare> = props => {
  const { form, deviceData } = props;

  const timeResolution = Form.useWatch('timeResolution', form);

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  useEffect(() => {
    form.setFieldsValue({
      timeList: [{ fromAndTo: getDefaultDate(timeResolution) }],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeResolution]);

  const addCompareTime = (add: (defaultValue?: any, insertIndex?: number | undefined) => void, filedLength: number) => {
    if (filedLength >= 3) {
      message.warning('最多添加3组对比时间');
    } else {
      add();
    }
  };

  return (
    <div className={styles.compareSection}>
      <Form.Item name="groupUid" label="选择对象" rules={[{ required: true, message: '请选择对象' }]}>
        <Select
          placeholder="请选择对象"
          showSearch
          optionFilterProp="label"
          options={deviceData?.map(item => ({
            label: `${item.name}-${item.appAnalysisSubscriptionName}`,
            value: item.groupUid,
          }))}
        />
      </Form.Item>
      <Form.Item name="indicatorId" label="监控指标" rules={[{ required: true, message: '请选择监控指标' }]}>
        <Select
          placeholder="请选择对象"
          showSearch
          optionFilterProp="label"
          options={Object.entries(MonitorIndicatorDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
        />
      </Form.Item>
      <Form.Item
        name="timeResolution"
        label="颗粒度"
        initialValue={StatisticsPartition.DAY}
        rules={[{ required: true, message: '请选择颗粒度' }]}
      >
        <Radio.Group>
          <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
          <Radio value={StatisticsPartition.MONTH}>{DatePickerTypeDisplay[StatisticsPartition.MONTH]}</Radio>
          <Radio value={StatisticsPartition.YEAR}>{DatePickerTypeDisplay[StatisticsPartition.YEAR]}</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.List name="timeList">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  marginBottom: 8,
                  padding: '12px 6px',
                  borderRadius: 2,
                  backgroundColor: '#ffffff10',
                  borderLeft: `3px solid ${colors[name]}`,
                }}
              >
                <Space
                  style={{
                    display: 'flex',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    label={`时间${name + 1}`}
                    name={[name, 'fromAndTo']}
                    className={styles.compareTime}
                    rules={[
                      { required: true, message: '请选择对比时间' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            const timeResolution = getFieldValue('timeResolution');
                            if (timeResolution) {
                              const [beginDate, endDate] = value;

                              if (timeResolution === StatisticsPartition.DAY) {
                                const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
                                if (diff >= 31) {
                                  return Promise.reject(new Error(`最大上限为31天`));
                                }
                              }
                              if (timeResolution === StatisticsPartition.MONTH) {
                                const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
                                if (diff >= 12) {
                                  return Promise.reject(new Error(`最大上限为12个月`));
                                }
                              }
                              if (timeResolution === StatisticsPartition.YEAR) {
                                const diff = dayjs(endDate).diff(dayjs(beginDate), 'year');
                                if (diff >= 12) {
                                  return Promise.reject(new Error(`最大上限为12年`));
                                }
                              }
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <RangePicker
                      disabledDate={disabledDate}
                      picker={
                        timeResolution === StatisticsPartition.DAY
                          ? 'date'
                          : timeResolution === StatisticsPartition.MONTH
                          ? 'month'
                          : 'year'
                      }
                      allowClear={false}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    style={{ color: 'var(--mx-primary-color)', cursor: 'pointer' }}
                    onClick={() => {
                      if (fields.length === 1) {
                        message.warning('至少保留一组对比时间');
                      } else {
                        remove(name);
                      }
                    }}
                  />
                </Space>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                ghost
                wrapStyle={{ width: '100%' }}
                style={{ width: '100%' }}
                onClick={() => addCompareTime(add, fields.length)}
                block
                icon={<PlusOutlined />}
              >
                新增对比时间
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default HistoryCompare;
