import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeDisplay,
  EnergyWorkingProcessTypeLabelColor,
} from '@/api/uet';
import { Divider, Spin } from 'antd';
import React, { FC, useState } from 'react';
import NoRestoredAlarm from '../NoRestoredAlarm';
import ViewEachProcessTabContent from '../ViewEachProcessTabContent';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { usePaging, Tabs, Tag, Form, ShowInput } from '@maxtropy/components';
import { numberToString } from '@/pages/WaterDashBoard/utils';
import {
  apiV2MediumIndicatorDashboardDeviceAlarmPagePost,
  apiV2MediumIndicatorDashboardGetProcessDataDetailPost,
  V2MediumIndicatorDashboardGetProcessDataDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

interface IItemProcessDetail {
  itemDetailId?: number;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const pollingInterval = 1000 * 60 * 1; // 1分钟轮询一次

const ItemProcessDetail: FC<IItemProcessDetail> = props => {
  const { itemDetailId } = props;
  const [uetEMTProcessDetail, setUetEMTProcessDetail] =
    useState<V2MediumIndicatorDashboardGetProcessDataDetailPostResponse>(); // 每个折叠的详情

  const { loading } = useRequest(
    () => {
      return apiV2MediumIndicatorDashboardGetProcessDataDetailPost({ id: itemDetailId!.toString() }).then(res => {
        setUetEMTProcessDetail(res);
      });
    },
    {
      pollingInterval,
      refreshDeps: [itemDetailId],
      ready: !!itemDetailId,
    }
  );

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data: pageList, loading: alarmLoading } = useRequest(
    () => {
      return itemDetailId
        ? apiV2MediumIndicatorDashboardDeviceAlarmPagePost({
            processId: String(itemDetailId)!,
            page: numberToString(pageOffset)!,
            size: numberToString(pageSize)!,
          }).then(res => {
            setTotalCount(res.total!);
            return res.list;
          })
        : Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [pageOffset, pageSize, itemDetailId],
    }
  );

  const tabsHasAlarm = (uetEMTProcessDetail?: V2MediumIndicatorDashboardGetProcessDataDetailPostResponse) =>
    [
      {
        key: '1',
        label: <>未恢复报警</>,
        children: <NoRestoredAlarm pageList={pageList} alarmLoading={alarmLoading} pagingInfo={pagingInfo} />,
      },
      {
        key: '2',
        label: <>输入</>,
        children: <ViewEachProcessTabContent currentKey={'2'} uetEMTProcessDetail={uetEMTProcessDetail} />,
      },
      {
        key: '4',
        label: <>节点内</>,
        children: <ViewEachProcessTabContent currentKey={'4'} uetEMTProcessDetail={uetEMTProcessDetail} />,
        show: !!uetEMTProcessDetail?.processNodeMediumDataDto,
      },
      {
        key: '3',
        label: <>输出</>,
        children: <ViewEachProcessTabContent currentKey={'3'} uetEMTProcessDetail={uetEMTProcessDetail} />,
      },
    ].filter(item => item.show !== false);

  const tabsNoAlarm = (uetEMTProcessDetail?: V2MediumIndicatorDashboardGetProcessDataDetailPostResponse) =>
    [
      {
        key: '2',
        label: <>输入</>,
        children: <ViewEachProcessTabContent currentKey={'2'} uetEMTProcessDetail={uetEMTProcessDetail} />,
      },
      {
        key: '4',
        label: <>节点内</>,
        children: <ViewEachProcessTabContent currentKey={'4'} uetEMTProcessDetail={uetEMTProcessDetail} />,
        show: !!uetEMTProcessDetail?.processNodeMediumDataDto,
      },
      {
        key: '3',
        label: <>输出</>,
        children: <ViewEachProcessTabContent currentKey={'3'} uetEMTProcessDetail={uetEMTProcessDetail} />,
      },
    ].filter(item => item.show !== false);

  return (
    <div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: EnergyWorkingProcessTypeLabelColor[uetEMTProcessDetail?.type as EnergyWorkingProcessType],
            borderRadius: 4,
            padding: '9px 15px',
          }}
        >
          <div className={styles.TagStyle}>
            <div style={{ fontSize: 18, fontWeight: 700, marginRight: 12 }}>{uetEMTProcessDetail?.name}</div>{' '}
            <Tag
              style={{
                color: EnergyWorkingProcessTypeLabelColor[uetEMTProcessDetail?.type as EnergyWorkingProcessType],
              }}
            >
              {EnergyWorkingProcessTypeDisplay[uetEMTProcessDetail?.type as EnergyWorkingProcessType]}
            </Tag>
          </div>
          <Divider className={styles.DividerStyle} />
          <Form className={styles.FormCardItemStyle} layout="horizontal" {...formLayout}>
            <Form.Item
              label={
                uetEMTProcessDetail?.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE
                  ? '输出能源介质'
                  : uetEMTProcessDetail?.type === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION
                  ? '输配能源介质'
                  : '存储能源介质'
              }
            >
              <ShowInput value={uetEMTProcessDetail?.energyMediumVos?.map(i => i.energyName).join()} />
            </Form.Item>
            <Divider className={styles.DividerStyle} />
            <Form.Item label="所属运营单元">
              <ShowInput value={uetEMTProcessDetail?.ouSimpleVos?.map(i => i.name).join()} />
            </Form.Item>
            {uetEMTProcessDetail?.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
              <>
                <Divider className={styles.DividerStyle} dashed />
                <Form.Item label="是否发生转化">
                  <ShowInput value={uetEMTProcessDetail.hasConversion ? '是' : '否'} />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        <Tabs
          style={{ marginTop: 12 }}
          items={
            pageList && pageList.length !== 0 ? tabsHasAlarm(uetEMTProcessDetail) : tabsNoAlarm(uetEMTProcessDetail)
          }
        />
      </Spin>
    </div>
  );
};

export default ItemProcessDetail;
