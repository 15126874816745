import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  Select,
  Radio,
  InputNumber,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOuListOwned, OuListResponse } from '../../../../api/ou';
import SelectCilentAccount from './SelectCilentAccount';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  BalanceAlarmalarmTypeType,
  BalanceAlarmalarmTypeTypeValue,
  IntervalType,
  IntervalTypeValue,
  PushChannelType,
} from '../../../../api/balanceAlarmManagement';
import {
  apiV2ServerAlarmRuleBatchPost,
  apiV2ServerAlarmRuleUpdatePost,
  apiV2ServerAlarmRuleDetailPost,
  V2ServerAlarmRuleDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

interface CreateBalanceAlarmProps {
  isMuti?: boolean;
  isEdit?: boolean;
}

interface FormProps {
  saleClientAccountIds: any[];
  saleClientAccountId?: number;
  alarmType: BalanceAlarmalarmTypeType;
  intervalHours_SMS: number;
  intervalHours_WEBSOCKET: number;
  name: string;
  ouId: number;
  pushChannel_SMS: number;
  pushChannel_WEBSOCKET: number;
  threshold: number;
  alarmLevel: number[];
}

export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const CreateBalanceAlarm: FC<CreateBalanceAlarmProps> = ({ isMuti = false, isEdit = false }) => {
  const { id } = useParams<{ id: string }>(); // 编辑的时候参数id
  const [loading, setLoading] = useState<boolean>(false);
  const [ou, setOu] = useState<OuListResponse[]>([]);
  const [ouId, setOuId] = useState<number>(); // 保存ouId
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState<V2ServerAlarmRuleDetailPostResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getOuListOwned().then(setOu); // 所属运营单元下拉选择
  }, []);

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  // 编辑的时候为单选
  useEffect(() => {
    if (id && isEdit) {
      setLoading(true);
      apiV2ServerAlarmRuleDetailPost({ id }).then(res => {
        setData(res);
        setLoading(false);
        for (let item of res.pushChannelIntervalInfoList ?? []) {
          if (item.pushChannel === PushChannelType.WEB_SOCKET) {
            form.setFieldsValue({
              pushChannel_WEBSOCKET: item.intervalType,
              intervalHours_WEBSOCKET: item.intervalHours === 0 ? undefined : item.intervalHours,
            });
          } else if (item.pushChannel === PushChannelType.SMS) {
            form.setFieldsValue({
              pushChannel_SMS: item.intervalType,
              intervalHours_SMS: item.intervalHours === 0 ? undefined : item.intervalHours! / 24,
            });
          }
        }
        form.setFieldsValue({
          name: res.name,
          ouId: res.ouId,
          saleClientAccountIds: [
            {
              id: res.balanceSceneInfo?.saleClientAccountId,
              name: res.balanceSceneInfo?.saleClientAccountName,
              code: res.balanceSceneInfo?.saleClientAccountCode,
            },
          ],
          alarmType: res.alarmType,
          alarmLevel: res.alarmLevel,
          threshold: res.balanceSceneInfo?.ruleBalanceInsufFixed?.threshold,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit]);

  const formatPostRequest = (formValues: FormProps) => {
    let obj: any = {
      name: formValues.name,
      ouId: formValues.ouId,
      alarmType: formValues.alarmType,
      alarmLevel: formValues.alarmLevel,
      sceneType: 1,
      triggerType: 1,
      balanceSceneInfo: {
        saleClientAccountIds: formValues.saleClientAccountIds.map(i => i.id),
        ruleBalanceInsufFixed: {
          threshold: formValues.threshold,
        },
      },
      pushChannelIntervalInfoList: [
        {
          pushChannel: formValues.pushChannel_WEBSOCKET ? 1 : undefined,
          intervalType: formValues.pushChannel_WEBSOCKET,
          intervalHours:
            formValues.pushChannel_WEBSOCKET === IntervalType.ONCE ? 0 : formValues.intervalHours_WEBSOCKET,
        },
        {
          pushChannel: formValues.pushChannel_SMS ? 2 : undefined,
          intervalType: formValues.pushChannel_SMS,
          intervalHours: formValues.pushChannel_SMS === IntervalType.ONCE ? 0 : formValues.intervalHours_SMS * 24,
        },
      ],
    };
    if (isEdit && id) {
      obj.balanceSceneInfo.saleClientAccountId = formValues.saleClientAccountIds[0].id;
      return obj;
    } else {
      return obj;
    }
  };

  const routes = useMemo(() => {
    return [{ name: `${isMuti ? '批量新建' : isEdit ? '编辑' : '新建'}余额报警` }];
  }, [isMuti, isEdit]);

  const onFinish = (val: any) => {
    form.validateFields().then(() => {
      if (isMuti) {
        apiV2ServerAlarmRuleBatchPost({ ...formatPostRequest(val) })
          .then(res => {
            navigate(`/operation/balanceAlarm/management`);
          })
          .catch(e => {
            console.error(e);
          });
      } else if (isEdit) {
        apiV2ServerAlarmRuleUpdatePost({ id, ...formatPostRequest(val) })
          .then(res => {
            navigate(`/operation/balanceAlarm/management`);
          })
          .catch(e => {
            console.error(e);
          });
      } else {
        apiV2ServerAlarmRuleBatchPost({ ...formatPostRequest(val) })
          .then(res => {
            navigate(`/operation/balanceAlarm/management`);
          })
          .catch(e => {
            console.error(e);
          });
      }
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{
          pushChannel_WEBSOCKET: IntervalType.ONCE,
          pushChannel_SMS: IntervalType.ONCE,
          alarmType: BalanceAlarmalarmTypeType.BalanceLow,
        }}
      >
        <FormTitle title={routes[0].name} />
        <Spin spinning={loading}>
          <SubContent title="基础设定" className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="规则名称"
                  rules={[
                    { required: true, message: '请输入规则名称' },
                    { max: 50, message: '最多输入50个字' },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="请输入规则名称,不超过五十个字" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="ouId" label="所属运营单元" rules={[{ required: true, message: '请选择所属运营单元' }]}>
                  <Select
                    placeholder={'请选择'}
                    onChange={(v: number) => {
                      setOuId(v);
                    }}
                    disabled={isEdit}
                    showSearch
                    options={ouOptions}
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="alarmLevel" label="报警等级" rules={[{ required: true }]}>
                  <Select placeholder="请选择" maxTagCount={3}>
                    <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
                    <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
                    <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
                    <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
                    <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="逻辑设定" className="mb-8">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="saleClientAccountIds"
                  label="客户账户"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '请选择客户账户' }]}
                >
                  <SelectCilentAccount ouId={ouId ?? data?.ouId} isMuti={isMuti} isEdit={isEdit} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name="alarmType" label="报警逻辑" rules={[{ required: true, message: '请选择报警逻辑' }]}>
                  <Radio.Group>
                    <Radio value={BalanceAlarmalarmTypeType.BalanceLow}>
                      {BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.BalanceLow]}
                    </Radio>
                    <Radio value={BalanceAlarmalarmTypeType.EstimateBalanceLow}>
                      {BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.EstimateBalanceLow]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item noStyle dependencies={['alarmType']}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('alarmType') === BalanceAlarmalarmTypeType.BalanceLow) {
                      return (
                        <Form.Item
                          name="threshold"
                          label={
                            <>
                              <span style={{ marginRight: '14px' }}>余额小于等于</span>
                              <InfoCircleOutlined
                                style={{ fontSize: '14px', marginRight: '6px', color: 'var(--mx-warning-color)' }}
                              />
                              <span style={{ fontSize: '14px', color: 'rgba(var(--base-text-color),0.35)' }}>
                                逻辑说明：当余额小于等于输入的值时，报警
                              </span>
                            </>
                          }
                          rules={[{ required: true, message: '请输入余额小于等于' }]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            max={99999}
                            min={1}
                            placeholder="请输入"
                            step="1"
                            precision={0}
                            addonAfter="元"
                          />
                        </Form.Item>
                      );
                    } else if (getFieldValue('alarmType') === BalanceAlarmalarmTypeType.EstimateBalanceLow) {
                      return (
                        <Form.Item
                          name="threshold"
                          label={
                            <>
                              <span style={{ marginRight: '14px' }}>暂估余额小于等于</span>
                              <InfoCircleOutlined
                                style={{ fontSize: '14px', marginRight: '6px', color: 'var(--warning-color)' }}
                              />
                              <span style={{ fontSize: '14px', color: 'rgba(var(--base-text-color),0.35)' }}>
                                逻辑说明：预测余额=实际金额-当前周期用电预估成本，当预测余额小于等于输入的值时，报警。注意，配置暂估余额报警请先配置好客户度电均价
                              </span>
                            </>
                          }
                          rules={[
                            { required: true, message: '请输入余额小于等于' },
                            {
                              validator: (_, value) => {
                                if (value <= 0) {
                                  return Promise.reject('请输入大于0的数');
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber style={{ width: '100%' }} placeholder="请输入" step="1" addonAfter="元" />
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
          <SubContent title="触发设定">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="pushChannel_WEBSOCKET"
                  label="报警触发后平台通知周期"
                  rules={[{ required: true, message: '请选择报警触发后平台通知周期' }]}
                >
                  <Radio.Group>
                    <Radio value={IntervalType.ONCE}>{IntervalTypeValue[IntervalType.ONCE]}</Radio>
                    <Radio value={IntervalType.HOUR}>{IntervalTypeValue[IntervalType.HOUR]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle dependencies={['pushChannel_WEBSOCKET']}>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue('pushChannel_WEBSOCKET') !== IntervalType.ONCE && (
                        <Form.Item
                          name="intervalHours_WEBSOCKET"
                          label="通知间隔"
                          rules={[{ required: true, message: '请输入通知间隔' }]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            max={9999}
                            min={1}
                            placeholder="请输入"
                            step="1"
                            precision={0}
                            addonAfter="小时"
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="pushChannel_SMS"
                  label="报警触发后短信通知周期"
                  rules={[{ required: true, message: '请选择报警触发后短信通知周期' }]}
                >
                  <Radio.Group>
                    <Radio value={IntervalType.ONCE}>{IntervalTypeValue[IntervalType.ONCE]}</Radio>
                    <Radio value={IntervalType.DAY}>{IntervalTypeValue[IntervalType.DAY]}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle dependencies={['pushChannel_SMS']}>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue('pushChannel_SMS') !== IntervalType.ONCE && (
                        <Form.Item
                          name="intervalHours_SMS"
                          label="通知间隔"
                          rules={[{ required: true, message: '请输入通知间隔' }]}
                        >
                          <InputNumber
                            style={{ width: '100%' }}
                            max={99}
                            min={1}
                            placeholder="请输入"
                            step="1"
                            precision={0}
                            addonAfter="天"
                          />
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={() => navigate(-1)}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateBalanceAlarm;
