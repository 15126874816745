import {
  useBreadcrumbRoutes,
  useTenantPermissions,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Select,
  Modal,
  Input,
  Filter,
} from '@maxtropy/components';
import { Col, Descriptions, Divider, List } from 'antd';
import React, { useEffect, useState } from 'react';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import defaultPic from './pic.png';
import classnames from 'classnames';
import {
  disabledUet,
  getUetPage,
  Status,
  statusFormat,
  UetInfoProps,
  UetUnitTypeFormat,
  updateUetStatus,
} from '../../../api/uet';
import { getOrganization } from '../../../api/customer-user';
import { tiledArray, TiledArrayProps } from '../utils';
import dayjs from 'dayjs';
import { PermissionsType } from '../../../common/permissionsConst';

// const routes = [
//   {name: '全域能源管理'},
//   {name: '基础设置'},
//   {name: '全域能源拓扑管理'},
// ];

const UETList: React.FC = () => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState<TiledArrayProps[]>([]);
  const [searchParams, setSearchParams] = useState<{ mcid?: string }>();
  const [list, setList] = useState<UetInfoProps[]>();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [x, forceUpdate] = useUpdate();
  const [invalidForm] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const permission = useTenantPermissions();

  useEffect(() => {
    getOrganization().then(organization => {
      if (organization) {
        setOrganization(tiledArray([organization]));
      } else {
        setOrganization([]);
      }
    });
  }, []);

  useEffect(() => {
    getUetPage({
      ...searchParams,
      page,
      size: 3,
    }).then(data => {
      setList(data.list);
      setTotal(data.total);
    });
  }, [searchParams, x, page]);

  const filters = (
    <Filter<{ mcid?: string }>
      onFinish={v => {
        setSearchParams(v);
        setPage(1);
      }}
      form={form}
      onReset={() => {
        setSearchParams(undefined);
        setPage(1);
      }}
    >
      <>
        <p className={styles.title}>能源管理拓扑</p>
        <Col span={8}>
          <Form.Item name="mcid" label="选择组织">
            <Select placeholder="请选择">
              {organization.map(i => (
                <Select.Option value={i.key} key={i.key}>
                  {i.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const changeStatus = (id: number, status: Status) => {
    updateUetStatus(id, status).then(res => {
      forceUpdate();
    });
  };

  const disabledAction = (id: number) => {
    Modal.confirm({
      title: '停用全域能源拓扑',
      content: '确定要停用拓扑？停用后可能会有依赖拓扑关系的应用/策略等失效',
      onOk: () => {
        Modal.confirm({
          title: null,
          content: (
            <>
              <Form form={invalidForm} layout="vertical">
                <Form.Item
                  label="请输入操作密码"
                  name="password"
                  rules={[{ required: true, message: '请输入操作密码' }]}
                >
                  <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
                </Form.Item>
              </Form>
            </>
          ),
          onOk: () => {
            return invalidForm.validateFields().then(value => {
              disabledUet(id, {
                state: Status.DISABLE,
                password: value.password,
              }).then(() => {
                Modal.warning({
                  title: '停用成功！',
                });
                forceUpdate();
              });
            });
          },
          afterClose: () => {
            invalidForm.resetFields();
          },
        });
      },
    });
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      {(permission ?? []).includes(PermissionsType.B_CREATEUET) && (
        <div className={styles.operationArea}>
          <Button type="primary">
            <Link to={`/energy/basic/uet/create`}>
              <PlusOutlined />
              新建UET
            </Link>
          </Button>
        </div>
      )}
      <List
        className={styles.list}
        itemLayout="vertical"
        dataSource={list}
        rowKey="id"
        pagination={{
          showSizeChanger: false,
          pageSize: 3,
          total,
          current: page,
          showTotal: () => `共${total}条`,
          onChange: page => setPage(page),
        }}
        renderItem={item => (
          <List.Item className={styles.content}>
            <div className={styles.leftContent}>
              <img src={defaultPic} alt="" />
              <p className={styles.name}>{item.name}</p>
              <span className={styles.tag}>{item.serialNumber}</span>
            </div>
            <div>
              <Descriptions
                layout="vertical"
                labelStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                colon={false}
                column={5}
              >
                <Descriptions.Item label="状态">{statusFormat[item.status]}</Descriptions.Item>
                <Descriptions.Item label="所在组织">{item.customerName}</Descriptions.Item>
                <Descriptions.Item label="所在组织编号">{item.customerCode}</Descriptions.Item>
                <Descriptions.Item label="运营单元" contentStyle={{ display: 'block' }}>
                  {item.ous?.map(i => (
                    <span key={i.id} className={styles.badge}>
                      {i.name}
                      <span className={styles.bac} />
                    </span>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="运营单元编号" contentStyle={{ display: 'block' }}>
                  {item.ous?.map(i => (
                    <span key={i.id} className={styles.badge}>
                      {i.serialNumber}
                      <span className={styles.bac} />
                    </span>
                  ))}
                </Descriptions.Item>
              </Descriptions>
              <Divider dashed />
              <Descriptions
                layout="vertical"
                colon={false}
                column={5}
                labelStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
              >
                <Descriptions.Item label="主要拓扑组件" contentStyle={{ display: 'block' }}>
                  {item.businessUnits?.map(i => (
                    <span key={i.id} className={styles.badge}>
                      {UetUnitTypeFormat[i.businessUnitType]}
                      <span className={styles.bac} />
                    </span>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="创建日期">
                  {dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="创建人">{item.createCustomerUserName}</Descriptions.Item>
                <Descriptions.Item label="最后编辑日期">
                  {dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </Descriptions.Item>
                <Descriptions.Item label="最后编辑人">{item.updateCustomerUserName}</Descriptions.Item>
              </Descriptions>
              <div className={styles.actionContent}>
                {(permission ?? []).includes(PermissionsType.B_UPDATEUET) && (
                  <div className={styles.action}>
                    <Button className={styles.btn} type="link">
                      <Link to={`/energy/basic/uet/edit/${item.id}`}>编辑</Link>
                    </Button>
                  </div>
                )}
                {(permission ?? []).includes(PermissionsType.B_UPDATEUETSTATUS) && (
                  <div className={styles.action}>
                    {item.status === Status.DISABLE && (
                      <Button className={styles.btn} type="link" onClick={() => changeStatus(item.id, Status.ENABLE)}>
                        启用
                      </Button>
                    )}
                    {(item.status === Status.ENABLE || item.status === Status.INVALID) && (
                      <Button className={styles.btn} type="link" onClick={() => disabledAction(item.id)}>
                        停用
                      </Button>
                    )}
                    {(item.status === Status.DRAFT || item.status === Status.DISABLE) && (
                      <Button
                        className={classnames(styles.btn, styles.gray)}
                        type="link"
                        onClick={() => changeStatus(item.id, Status.INVALID)}
                      >
                        作废
                      </Button>
                    )}
                  </div>
                )}
                {(permission ?? []).includes(PermissionsType.B_UETINFO) && (
                  <div className={styles.action}>
                    <Button className={styles.btn} type="link">
                      <Link to={`/energy/basic/uet/detail/${item.id}`}>查看</Link>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export default UETList;
