import { FC, useCallback, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Col, Row, Space } from 'antd';

import styles from './index.module.scss';
import { Checkbox, Select, Tag } from '@maxtropy/components';

type TimeSpace = [number, number];

export interface MonthProps {
  startTime: Dayjs;
  endTime: Dayjs;
  disabledTimes?: TimeSpace[];
  value?: number[];
  onChange?: (v: number[]) => void;
}

const Month: FC<MonthProps> = ({ startTime, endTime, disabledTimes = [], value = [], onChange }) => {
  const [selectYear, setSelectYear] = useState<number>();

  // const [selectMonths, setSelectMonths] = useState<number[]>([]);

  const allYears = useMemo(() => {
    if (startTime && endTime) {
      const start = dayjs(startTime).year();
      const end = dayjs(endTime).year();
      return new Array(end - start + 1).fill(undefined).map((_, index) => {
        const time = dayjs(startTime).add(index, 'years').endOf('year');
        return time.isBetween(startTime, endTime) ? time.valueOf() : dayjs(endTime).valueOf();
      });
    } else {
      return [];
    }
  }, [startTime, endTime]);

  const selectYearsAllMonth = useMemo(() => {
    if (startTime && endTime && selectYear) {
      let s = dayjs(selectYear).startOf('year').valueOf();
      s = dayjs(s).isSameOrAfter(startTime) ? s : dayjs(startTime).valueOf();
      return new Array(dayjs(selectYear).month() - dayjs(s).month() + 1).fill(undefined).map((_, index) => {
        const time = dayjs(s).add(index, 'months').endOf('month');
        console.log(
          time.isBetween(startTime, endTime, null, '[]')
            ? time.format('YYYY-MM-DD')
            : dayjs(endTime).format('YYYY-MM-DD')
        );
        return time.isBetween(startTime, endTime, null, '[]') ? time.valueOf() : dayjs(endTime).valueOf();
      });
    } else {
      return [];
    }
  }, [selectYear, startTime, endTime]);

  const isDisabled = useCallback(
    (month: number) => {
      return disabledTimes.some(([start, end]) => {
        return (
          dayjs(start).startOf('date').isBetween(dayjs(month).startOf('month'), dayjs(month), null, '[]') ||
          dayjs(end).endOf('date').isBetween(dayjs(month).startOf('month'), dayjs(month), null, '[]')
        );
      });
    },
    [disabledTimes]
  );

  const onMonthChange = (months: number[]) => {
    const unEffectMonths = (value ?? [])?.filter(i => !selectYearsAllMonth.includes(i));
    console.log([...(unEffectMonths ?? []), ...months].map(i => dayjs(i).format('YYYY-MM-DD')));
    onChange?.([...(unEffectMonths ?? []), ...months]);
  };

  const onCloseMonth = (month: number) => {
    onChange?.([...value.filter(i => i !== month)]);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={20}>
      <Select
        placeholder="请选择"
        value={selectYear}
        onChange={(v: number) => {
          setSelectYear(v);
        }}
        style={{ width: 180 }}
      >
        {allYears.map(i => (
          <Select.Option key={i} value={i}>
            {dayjs(i).year()}
          </Select.Option>
        ))}
      </Select>
      {selectYear && (
        <Checkbox.Group
          value={value}
          style={{ width: '100%' }}
          onChange={(v: any) => {
            onMonthChange(v as number[]);
          }}
        >
          <Row style={{ width: '100%' }}>
            {selectYearsAllMonth.map(i => (
              <Col className={styles.col} key={i} span={4}>
                <Checkbox disabled={isDisabled(i)} value={i}>
                  {dayjs(i).month() + 1}月
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      )}
      {value.length > 0 && (
        <Row>
          <Col style={{ width: 57 }}>已选择：</Col>
          <Col span={21}>
            {value
              .sort((a, b) => a - b)
              .map(i => (
                <Tag
                  closable
                  onClose={() => {
                    onCloseMonth(i);
                  }}
                  key={i}
                >
                  {dayjs(i).format('YYYY-MM')}
                </Tag>
              ))}
          </Col>
        </Row>
      )}
    </Space>
  );
};

export default Month;
