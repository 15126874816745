import React, { Key, ReactText, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Cascader } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { Paging, Table, usePaging, useAsync, Form, Input, CustomFilter } from '@maxtropy/components';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { IotProtocolType } from '@/shared/types';

import { DefaultOptionType } from 'antd/es/cascader';
import { useQuery } from '../../../utils/utils';
import { getDevicePage, DevicePageRequest } from '../../../api/gateway';
import { DeviceList } from '../type';
import { deviceColumns as columns } from '../Detail';
import styles from '../index.module.scss';
import { Device } from '@/api/device';
import { getDeviceTypeTree } from '@/api/deviceType';

interface FilterParams {
  name?: string | undefined;
  tag?: string | undefined;
  typeId: Array<Array<number>> | undefined;
}

interface TableDataType {}

export interface DeviceModalRef {
  selectedRows?: DeviceList[];
}

type SearchParams = Omit<DevicePageRequest, 'page' | 'size' | 'gatewayId' | 'iotProtocol'>;

interface DeviceModalProps {
  iotProtocol?: IotProtocolType;
  bindDeviceIds?: number[];
}

interface SelectItemProps {
  id?: number;
}

const DeviceModal = React.forwardRef(({ iotProtocol, bindDeviceIds }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedRows, setSelectedRows] = useState<DeviceList[]>();
  const [selectedItems, setSelectItems] = useState<SelectItemProps[]>([]);
  const { id } = useParams<{ id: string }>();

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const { data, isLoading }: { data?: Device[]; isLoading?: boolean } = useQuery(
    useCallback(async () => {
      if (iotProtocol) {
        const res = await getDevicePage({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
          iotProtocol,
          gatewayId: id!,
        });
        if (res) setTotalCount(res.total);
        return res.list;
      }
    }, [id, iotProtocol, pageOffset, pageSize, searchParams, setTotalCount])
  );

  useEffect(() => {
    setSelectItems(bindDeviceIds?.map(item => ({ id: item })) ?? []);
  }, [bindDeviceIds]);

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    // const deviceTypes = getAllChild(val.typeId, formatOptionData(deviceTypeData));
    const deviceTypes = getChildNodesByParentIds(val.typeId, deviceTypeData?.tree);
    const params: SearchParams = {
      name: val.name,
      tag: val.tag,
      deviceTypes,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: ReactText[], rows: DeviceList[]) => {
    const data = rows.filter(item => !!item).filter(item => !selectedItems?.map(s => s.id).includes(item.id));
    setSelectedRows(data);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: (selectedRows || []).concat(selectedItems || []).map(item => item.id as Key),
    getCheckboxProps: (record: DeviceList) => ({ disabled: selectedItems?.map(s => s.id).includes(record.id) }),
    onChange: onSelectChange,
  };

  useImperativeHandle(ref, () => ({
    selectedRows,
  }));

  const length = (bindDeviceIds?.length || 0) + (selectedRows?.length || 0);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div className={styles.deviceModal}>
      <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} style={{ width: 160 }} />
        </Form.Item>

        <Form.Item name="typeId" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            multiple
            maxTagCount="responsive"
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
          />
        </Form.Item>

        <Form.Item name="tag" label="标签">
          <Input placeholder="请输入" />
        </Form.Item>
      </CustomFilter>
      <div className={styles.table_text}>
        <div>
          <InfoCircleOutlined style={{ margin: '0 4px 0 0', color: 'var(--mx-warning-color)' }} />
          <span className={styles.grey}>只展示已经配置数采，且未绑定到其他网关的在用设备。</span>
        </div>

        <div className={styles.action}>
          <span style={{ marginRight: 5 }}>点击前往</span>
          <Link to="/device/manage/device/create" target="_blank">
            添加设备
          </Link>
        </div>
      </div>
      <Table<TableDataType>
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns()}
        dataSource={data}
        bottomLeftExtra={
          <div className={styles.tips}>
            <span>本次新增勾选</span>
            <span className={styles.red}>{selectedRows?.length || '0'}</span>
            <span>项，</span>
            <span>总计</span>
            <span className={styles.red}>{length}</span>
            <span>项</span>
          </div>
        }
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
});

export default DeviceModal;
