import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
} from '@maxtropy/components';
import React, { useState } from 'react';
import {
  apiV2HvacNodePagePost,
  V2HvacNodePagePostRequest,
  V2HvacNodePagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

type SearchParams = Omit<V2HvacNodePagePostRequest, 'timestamp'>;
type DataItem = Exclude<V2HvacNodePagePostResponse['list'], undefined>[number];

const HVACNodeList = () => {
  const { stationId, hvacId } = useParams<{ stationId: string; hvacId: string }>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const paging = usePaging(50);
  const { setTotalCount, pageSize, pageOffset, setPageOffset } = paging;
  const hasEditPermission = useHasPermission(PermissionsType.B_HVAC_STATION_PROPERTY_LIST_CONFIG);

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onFinish = (val: SearchParams) => {
    setPageOffset(1);
    setSearchParams(val);
  };

  const { loading, data } = useRequest(
    async () => {
      const res = await apiV2HvacNodePagePost({
        stationId: Number(stationId),
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    { refreshDeps: [pageSize, pageOffset, searchParams] }
  );

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item label="节点名称" name="nodeName">
        <Input placeholder="请输入节点名称搜索" />
      </Form.Item>
    </CustomFilter>
  );

  const columns: ColumnType<DataItem>[] = [
    {
      title: '节点名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作',
      key: 'action',
      width: 150,
      ellipsis: { showTitle: true },
      render: (_: any, record) =>
        hasEditPermission && (
          <Button
            type="link"
            onClick={() => {
              navigate(`/energy/basic/uet/hvacNodeIndicatorConfig/${hvacId}/${stationId}/${record.id}`);
            }}
          >
            配置
          </Button>
        ),
    },
  ];

  return (
    <Wrapper
      routes={[...(breadcrumbRoutes?.routes ?? []), { name: '站内节点列表' }]}
      filters={filters}
      className={styles.wrapper}
    >
      <Table rowKey="id" loading={loading} columns={columns} dataSource={data}></Table>
      <Paging pagingInfo={paging} />

      <div className="sticky-footer" style={{ paddingLeft: 0 }}>
        <Button onClick={() => navigate(`/energy/basic/uet/edit/${hvacId}/AIR_CONDITION`)}>返回</Button>
      </div>
    </Wrapper>
  );
};

export default HVACNodeList;
