import dayjs from 'dayjs';
import { DevicePoint, MeasurementType, RawData } from '../../../../api/history';
import { createSplitTime } from '../../utils';
import { DatePickerType, OriginalType, StatisticsPartition } from '../DateSwitch';

// type FormatterItem = {
//   name: string;
//   value: number;
//   marker: string;
//   data: [string, number];
//   seriesName: string;
//   dataIndex: number
// }

const getXName = (mode: DatePickerType) => {
  switch (mode) {
    case DatePickerType.DAY:
      return '(日)';
    case DatePickerType.MONTH:
      return '(月)';
    case DatePickerType.YEAR:
      return '(年)';
    default:
      return '';
  }
};

const getXAxisData = (data: RawData[] | undefined, mode: DatePickerType) => {
  const xData = (data || []).map(item => item.time);
  switch (mode) {
    case DatePickerType.DAY:
      return xData.map(item => (item ? dayjs(item).format('YYYY-MM-DD') : null));
    case DatePickerType.MONTH:
      return xData.map(item => (item ? dayjs(item).format('YYYY-MM') : null));
    case DatePickerType.YEAR:
      return xData.map(item => (item ? dayjs(item).format('YYYY') : null));
    default:
      return [];
  }
};

export const chartOption = (
  data: RawData[] | undefined,
  mode: DatePickerType,
  activePoint: DevicePoint | undefined,
  dateMode?: StatisticsPartition
) => {
  const { name, generalName, measurementType } = activePoint || {};
  const xAxisData = getXAxisData(data, mode);
  const xName = getXName(mode);
  const seriesData = (data || []).map(item =>
    dateMode === OriginalType.ORIGINAL ? (item.value as number) : (item.value as number)?.toFixed(2)
  );

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),

    color: ['#73A0FA'],
    grid: {
      left: 100,
      right: 60,
      top: 90,
      bottom: 100,
    },
    title: {
      text: name,
      left: 'center',
      textStyle: {
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        bottom: 5,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: xName,
      axisLabel: {
        rotate: 30,
      },
    },
    yAxis: {
      name: `单位：${generalName || '--'}`,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      type: 'value',
    },
    series: [
      {
        data: seriesData,
        type: measurementType === MeasurementType.INSTANT ? 'line' : 'bar',
        barMaxWidth: 80,
      },
    ],
  };
};

export const scatterChartOption = (data: RawData[] | undefined, activePoint: DevicePoint | undefined) => {
  const { name, generalName } = activePoint || {};
  // 计算时间间隔, 显示完整的一天
  let newData = createSplitTime(data);

  const xAxisData = (newData || []).map(item => dayjs(item.time).format('HH:mm:ss'));
  const seriesData = (newData || []).map((item, index) => [index, (item.value as number)?.toFixed(2)]);

  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),

    title: {
      text: name,
      left: 'center',
      textStyle: {
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    grid: {
      left: 100,
      right: 60,
      top: 90,
      bottom: 100,
    },
    dataZoom: [
      {
        type: 'slider',
        start: 45,
        end: 55,
        bottom: 5,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    xAxis: {
      type: 'category',
      data: xAxisData,
      name: '时',
      axisLabel: {
        rotate: 30,
      },
    },
    yAxis: {
      name: `单位：${generalName || '--'}`,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      type: 'value',
    },
    series: [
      {
        symbolSize: 5,
        data: seriesData,
        type: 'scatter',
      },
    ],
  };
};
