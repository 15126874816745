import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  useBreadcrumbRoutes,
  Paging,
  CustomFilter,
  Select,
  Tag,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Form, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2OutputProductBaseLineDeletePost,
  apiV2OutputProductBaseLinePagePost,
  apiV2OutputProductBaseLineUpdateStatusPost,
  V2OutputProductBaseLinePagePostRequest,
  V2OutputProductBaseLinePagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router';
import {
  BaselineType,
  BaselineTypeFormat,
  Granularity,
  GranularityFormat,
  VariableType,
  VariableTypeFormat,
} from '../OutputConfig/Baseline/BaselineTs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { StatusType, statusOptions } from './utils/common';

export type BaseLineItem = Exclude<V2OutputProductBaseLinePagePostResponse['list'], undefined>[number];

interface SearchParamsProps {
  name?: string;
  status?: number | string;
}

const columns: ColumnsType<BaseLineItem> = [
  {
    title: '产出物编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '产出物名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规格编号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'X轴指标',
    dataIndex: 'variableType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={VariableTypeFormat[v as unknown as VariableType]} />,
  },
  {
    title: 'Y轴指标',
    dataIndex: 'medium',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '数据来源',
    dataIndex: 'dataSource',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={GranularityFormat[v as unknown as Granularity]} />,
  },
  {
    title: '基线类型',
    dataIndex: 'lineType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={BaselineTypeFormat[v as unknown as BaselineType]} />,
  },
  // {
  //   title: '基线评分',
  //   dataIndex: 'score',
  //   ellipsis: { showTitle: true },
  //   render: (v: string) => <EllipsisSpan value={v} />,
  // },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: number) =>
      v === StatusType.ENABLE ? (
        <Tag border="solid" color="success">
          启用
        </Tag>
      ) : (
        <Tag border="solid" color="default">
          禁用
        </Tag>
      ),
  },
];

interface SearchParams extends Omit<V2OutputProductBaseLinePagePostRequest, 'page' | 'size'> {}

const BaseLineManage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>(20);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>({ status: StatusType.ENABLE });
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDUNITCONSUMPTIONBASELINE); // 新建
  const hasEdit = useHasPermission(PermissionsType.B_EDITUNITCONSUMPTIONBASELINE); // 编辑
  const hasEnable = useHasPermission(PermissionsType.B_ENABLEUNITCONSUMPTIONBASELINE); // 删除
  const hasDelete = useHasPermission(PermissionsType.B_DELUNITCONSUMPTIONBASELINE); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, loading } = useRequest(
    () =>
      apiV2OutputProductBaseLinePagePost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, pageOffset, pageSize, update],
    }
  );

  const onFinish = (v: SearchParams) => {
    setSearchParams({ ...v });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      name: undefined,
      status: StatusType.ENABLE,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onDelete = (id: number) => {
    if (isNil(id)) return;
    apiV2OutputProductBaseLineDeletePost({ id }).then(res => {
      updateFn();
    });
  };

  const onUpdate = (id: number, status: number) => {
    apiV2OutputProductBaseLineUpdateStatusPost({ id, status }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigate(`/systemConfig/productionTopo/baseLineManage/detail/${record.id}`);
              }}
            >
              查看
            </Button>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/systemConfig/productionTopo/baseLineManage/edit/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasEnable && (
              <PopConfirm
                placement="top"
                title={`是否${record.status === StatusType.ENABLE ? '禁用' : '启用'}此基线`}
                onConfirm={() => onUpdate(record.id, 1 - (record.status ?? 0))}
              >
                <Button type="link">{record.status === StatusType.ENABLE ? '禁用' : '启用'}</Button>
              </PopConfirm>
            )}
            {hasDelete && (
              <PopConfirm
                placement="top"
                title="是否确认删除此基线，删除后数据不能找回"
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter initialValues={{ status: StatusType.ENABLE }} form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item label="产出物" name="name">
        <Input placeholder="输入产出物名称/编码/规格型号" />
      </Form.Item>
      <Form.Item label="产出物状态" name="status">
        <Select options={statusOptions} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      {hasAdd && (
        <Space style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate('/systemConfig/productionTopo/baseLineManage/create');
            }}
          >
            新建单耗基线
          </Button>
        </Space>
      )}
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default BaseLineManage;
