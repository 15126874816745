import { Space, Spin, Tooltip } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Key, PopConfirm, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { apiV2AccountingEntityListPost, apiV2AccountingEntityDeletePost } from '@maxtropy/device-customer-apis-v2';
import companyIcon from '../assets/company@2x.png';
import { useNavigate } from 'react-router-dom';
interface Props {
  selectedKeys: Key[];
  setSelectedKeys: (v: Key[]) => void;
  expandedKeys: Key[];
  setExpandedKeys: (v: Key[]) => void;
  treeUpdate: number;
  autoExpandParent: boolean;
  setAutoExpandParent: (v: boolean) => void;
  treeUpdateFn: () => void;
  setEntityId: (v: any) => void;
  setEntityName: (v: string) => void;
}

function formatTree(data: any) {
  let tree = [];

  tree = data.map((i: any) => ({
    key: i.id,
    title: i.companiesName,
  }));

  return tree;
}

let dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  dataList = [];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const EntityTree: React.FC<Props> = ({
  selectedKeys,
  setSelectedKeys,
  expandedKeys,
  setExpandedKeys,
  treeUpdate,
  autoExpandParent,
  setAutoExpandParent,
  treeUpdateFn,
  setEntityId,
  setEntityName,
}) => {
  const [data, setData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const Navigate = useNavigate();

  useEffect(() => {
    apiV2AccountingEntityListPost({})
      .then((res: any) => {
        if (res.list) {
          const dataNodes = formatTree(res.list);
          setData(dataNodes);
          generateList(dataNodes);
          const allDataNodeKeys = dataList.map(i => i.key);
          setExpandedKeys(allDataNodeKeys);
          if (allDataNodeKeys.length === 0) {
            setEntityId(0);
          }
          // 默认选中第一个
          if (allDataNodeKeys && allDataNodeKeys.length > 0) {
            setEntityName(dataList[0].title);
            setSelectedKeys([allDataNodeKeys[0]]);
            setEntityId(allDataNodeKeys[0]);
          }
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeUpdate, setExpandedKeys]);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      console.log(131);
      setExpandedKeys(dataList.map(i => i.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const treeData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length > 0 ? (
            <Tree
              className={styles.treeStyle}
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              titleRender={(nodeData: any = {}) => {
                return (
                  <div className={`${styles.flexBetween} ${styles.treeTitle}`}>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 4,
                      }}
                    >
                      <img src={companyIcon} alt="" className={styles.titleImg} />
                      <span className={styles.title}>{nodeData?.title}</span>
                    </span>
                    <Space size={4} className={styles.treeBtn} style={{ marginRight: 8 }}>
                      <Tooltip title="编辑">
                        <FormOutlined
                          className={styles.titieRenderIcon}
                          onClick={e => {
                            e.stopPropagation();
                            Navigate(`/carbonManage/mainAccount/carbonAccountUnits/create?id=${nodeData.key}`);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="删除" zIndex={1}>
                        <PopConfirm
                          title="是否删除此核算主体"
                          onCancel={e => {
                            e?.stopPropagation();
                          }}
                          onConfirm={() => {
                            apiV2AccountingEntityDeletePost({ id: nodeData.key }).then(() => {
                              setEntityId(0);
                              treeUpdateFn();
                            });
                          }}
                        >
                          <DeleteOutlined onClick={e => e.stopPropagation()} className={styles.titieRenderIcon} />
                        </PopConfirm>
                      </Tooltip>
                    </Space>
                  </div>
                );
              }}
              onExpand={v => {
                setExpandedKeys(v);
                setAutoExpandParent(false);
              }}
              onSelect={(v, info) => {
                setEntityName(info.node.title as string);
                setEntityId(v[0] ?? 0);
                setSelectedKeys(v);
              }}
              autoExpandParent={autoExpandParent}
            />
          ) : (
            <Empty style={{ marginTop: 40 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default EntityTree;
