import { Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Key, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost } from '@maxtropy/device-customer-apis-v2';
interface Props {
  selectedKeys: Key[];
  setSelectedKeys: (v: Key[]) => void;
  expandedKeys: Key[];
  setExpandedKeys: (v: Key[]) => void;
  id?: string;
  setAutoExpandParent: (v: boolean) => void;
  autoExpandParent: boolean;
}

const formatTreeData = (data: any[]) => {
  return data.map(item => {
    const res: DataNode = {
      ...item,
      key: item.id ?? '',
      title: item.name,
      children: item.childList ? formatTreeData(item.childList) : [],
    };
    return res;
  });
};
let dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const EntityTree: React.FC<Props> = ({
  selectedKeys,
  setSelectedKeys,
  expandedKeys,
  setExpandedKeys,
  id,
  setAutoExpandParent,
  autoExpandParent,
}) => {
  const [data, setData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost({ id: Number(id) })
      .then((res: any) => {
        if (res.list) {
          dataList = [];
          const dataNodes = formatTreeData(res.list);
          setData(dataNodes);
          generateList(dataNodes);
          const allDataNodeKeys = dataList?.map(i => i.key);
          setExpandedKeys(allDataNodeKeys);
          if (allDataNodeKeys.length === 0) {
            setSelectedKeys([0]);
          }
          // 默认选中第一个
          if (allDataNodeKeys && allDataNodeKeys.length > 0) {
            setSelectedKeys([allDataNodeKeys[0]]);
          }
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setExpandedKeys]);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      setExpandedKeys(dataList.map(i => i.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const treeData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length > 0 ? (
            <Tree
              className={styles.treeStyle}
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={v => {
                setExpandedKeys(v);
                setAutoExpandParent(false);
              }}
              onSelect={(v, info) => {
                setSelectedKeys(v);
              }}
              autoExpandParent={autoExpandParent}
            />
          ) : (
            <Empty style={{ marginTop: 40 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default EntityTree;
