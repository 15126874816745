import { CreateUETEMTProcessResponse, EnergyWorkingProcessType, EnergyWorkingProcessTypeDisplay } from '@/api/uet';
import { FormInstance } from 'antd';

export const processTypeOptions = [
  {
    label: EnergyWorkingProcessTypeDisplay[EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION],
    value: EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION,
  },
  {
    label: EnergyWorkingProcessTypeDisplay[EnergyWorkingProcessType.PREPARATION_AND_HANDLE],
    value: EnergyWorkingProcessType.PREPARATION_AND_HANDLE,
  },
  {
    label: EnergyWorkingProcessTypeDisplay[EnergyWorkingProcessType.STORAGE],
    value: EnergyWorkingProcessType.STORAGE,
  },
];

export const hasConversionOptions = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];

// 前端模糊查询
export const findfilterArr = (newList: CreateUETEMTProcessResponse[], topoNameOrEnterExitName: string) => {
  let res1 = newList?.filter(i => i.name?.includes(topoNameOrEnterExitName));
  let res2 = newList
    .map(i => {
      return {
        ...i,
        processEntryVos: i.processEntryVos?.map(item => item.name).join(),
        processExitVos: i.processExitVos?.map(item => item.name).join(),
      };
    })
    .filter(
      item =>
        item.processEntryVos?.includes(topoNameOrEnterExitName) ||
        item.processExitVos?.includes(topoNameOrEnterExitName)
    );
  let searchList = Array.from(new Set(res1.map(i => i.id).concat(res2.map(i => i.id))));
  return newList.filter(i => searchList.includes(i.id));
};

// 找出数组重复项
export const duplicates = (arr: string[]) => {
  return arr.filter((e, i) => arr.indexOf(e) !== arr.lastIndexOf(e) && arr.indexOf(e) === i);
};

// 保存时表单校验输入输出名称是否有重复项

export const checkFormRepeatName = ({
  form,
  namePath,
  errorName,
}: {
  form?: FormInstance<any>;
  namePath: any;
  errorName: string;
}) => {
  let values = form?.getFieldValue(namePath);
  let nameList = values?.map((item: any) => item.name) ?? [];
  let repeatNames = duplicates(nameList);
  if (repeatNames.length > 0) {
    let repeatName = repeatNames[0];
    let index = nameList.lastIndexOf(repeatName);
    let formKey = [...namePath, index, 'name'];
    form?.setFields([{ name: formKey, errors: [errorName] }]);
    return false;
  }

  return true;
};
