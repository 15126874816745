import SubTitleImg from '../../assets/subtittle@2x.png';
import styles from './index.module.scss';

const Subtitle = ({ title, rightContent }: { title: string; rightContent?: React.ReactNode }) => {
  return (
    <div className={styles.subtitle}>
      <img src={SubTitleImg} alt="pic" />
      {title}
      {rightContent && <div className={styles.rightContent}>{rightContent}</div>}
    </div>
  );
};
export default Subtitle;
