import React, { useState, useEffect, Key } from 'react';
import {
  Modal,
  CustomFilter,
  Form,
  Input,
  Table,
  EllipsisSpan,
  Paging,
  usePaging,
  useUpdate,
  useAsync,
} from '@maxtropy/components';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { Cascader } from 'antd';
import {
  V2EnergyAnalysisLinkedOuPagePostResponse,
  apiV2DispatchPlanDevicePagePost,
  V2DispatchPlanDevicePagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { V2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePostResponse } from '@maxtropy/device-customer-apis-v2';
import { Dispatch, SetStateAction } from 'react';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeTree } from '@/api/deviceType';

interface CreateFormProps {
  visible: boolean;
  close: () => void;
  addRow: () => void;
  ids: Array<number>;
  selectKeys?: number[];
  onChangeSelectKeys?: Dispatch<SetStateAction<Array<number>>>;
  selectDatas?: any;
  setDefaultData?: any;
  onChangeSelectData?: Dispatch<SetStateAction<Array<any>>>;
  id?: string;
  type?: number;
  changeCurrent?: string;
}

interface FilterParams {
  codeOrName?: string;
  typeIds?: number[];
  primaryTypeId?: number;
}
interface SearchParamsProps {
  codeOrName?: string;
  typeIds?: number[]; // 产品
}
//设备子表
export interface uipmentDataeqType {
  code?: string;
  name?: string;
  sn?: string;
  typeName?: string;
  modelName?: string;
  customerName?: string;
  id?: number;
}
export type OuItem = Exclude<V2EnergyAnalysisLinkedOuPagePostResponse['list'], undefined>[number];
export type UnitConfGroupLinkDeviceResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupLinkedDeviceAddPagePostResponse['list'],
  undefined
>[number];

const columnsEquipment: ColumnsType<uipmentDataeqType> = [
  {
    title: '设备编号',
    dataIndex: 'code',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '设备SN',
    dataIndex: 'sn',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
];
const ModalDevice: React.FC<CreateFormProps> = ({
  addRow,
  visible,
  close,
  ids,
  selectKeys,
  id,
  type,
  setDefaultData,
  changeCurrent,
}) => {
  const pagingInfo = usePaging(500);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [confirmLoading] = useState(false);
  const [form] = Form.useForm();
  const deviceTypeData = useAsync(getDeviceTypeTree);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [updateState] = useUpdate();
  //设备列表
  const [modalListData, setModalListData] = useState<V2DispatchPlanDevicePagePostResponse['list']>([]);
  const onFinish = (v: FilterParams) => {
    const typeIds = getChildNodesByParentIds(form.getFieldsValue().typeIds, deviceTypeData?.tree);
    setSearchParams({
      codeOrName: v.codeOrName,
      typeIds: typeIds,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={onFinish} colSpan={8} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="typeIds" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
    </CustomFilter>
  );
  const handleOk = () => {
    addRow();
  };

  useEffect(() => {
    if (ids && ids.length > 0) {
      let params = {
        dispatchPlanId: id,
        page: pageOffset,
        size: pageSize,
        circuits: type === 1 ? ids : undefined,
        processIds: type === 2 ? ids : undefined,
        ...searchParams,
        type,
      };
      apiV2DispatchPlanDevicePagePost({ ...params }).then(res => {
        if (res) {
          setModalListData(res.list);
          setTotalCount(res.total ?? 0);
          const newArr = (res.list ?? []).filter((item: any) => {
            return item.otherUser === false;
          });
          setDefaultData(newArr);
        } else {
          setModalListData([]);
          setTotalCount(0);
          setDefaultData([]);
        }
      });
    } else {
      setModalListData([]);
      setTotalCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams, updateState, changeCurrent]);

  const onSelectChange = (rowKeys: Key[], rowData: object[]) => {
    setDefaultData(rowData);
  };
  const rowSelection = {
    getCheckboxProps: (record: any) => ({
      disabled: record.otherUser,
    }),
    onChange: onSelectChange,
    selectedRowKeys: selectKeys ?? [],
  };

  return (
    <Modal
      title="设备选择"
      size="big"
      bodyScroll
      open={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={close}
    >
      {filters}
      <div className={styles.contentTable}>
        <Table
          rowKey="id"
          sticky
          dataSource={modalListData}
          columns={columnsEquipment}
          pagination={false}
          scroll={{ y: 300 }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </div>
      <div className={styles.page_box}>
        <div className={styles.page_before}>
          已选择<span className={styles.red}>{(selectKeys ?? []).length}</span>项
        </div>
        <div className={styles.page_after}>
          <Paging pagingInfo={pagingInfo} pageSizeOptions={['10', '20', '30', '50', '100', '500']} />
        </div>
      </div>
    </Modal>
  );
};
export default ModalDevice;
