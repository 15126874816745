import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Modal, Radio, Select, message } from '@maxtropy/components';
import { Spin } from 'antd';
import {
  V2HvacEnergyDeviceListPostResponse,
  apiV2HvacDetailPost,
  apiV2HvacEnergyDeviceListPost,
  apiV2HvacSavePost,
  apiV2HvacUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { CircuitProps, CircuitType, getCircuitList } from '@/api/uet';
import { OperationType, OperationTypeDisplay, StationOptions } from '../utils';
import { ModalProp } from '..';

const NewStation = ({ onCancel, onOk, operationType, ouList, uetId, stationId }: ModalProp) => {
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);
  const [deviceList, setDeviceList] = useState<V2HvacEnergyDeviceListPostResponse['list']>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    // 获取回路数据 1:
    getCircuitList({ uetId, type: CircuitType.GRID_BILLING_CIRCUIT }).then(res => {
      setCircuitList(res);
    });
  }, [uetId]);

  useEffect(() => {
    // 获取流量计数据
    apiV2HvacEnergyDeviceListPost({}).then(res => {
      setDeviceList(res.list ?? []);
    });
  }, [uetId]);

  useEffect(() => {
    if (stationId) {
      setLoading(true);

      // 获取站房详情
      apiV2HvacDetailPost({ id: stationId })
        .then(res => {
          form.setFieldsValue({
            ouId: res.ouId,
            name: res.name,
            type: res.type,
            meteringCircuitId: res.meteringCircuitId,
            energyDeviceId: res.energyDeviceId,
            status: res.status,
            id: res.id,
          });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  const circuitOptions = useMemo(() => {
    return (circuitList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [circuitList]);

  const deviceOptions = useMemo(() => {
    return (deviceList ?? []).map(i => ({ label: i.energyDeviceName, value: i.energyDeviceId }));
  }, [deviceList]);

  const handleForm = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        uetId,
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2HvacSavePost(params)
          .then(res => {
            message.success('新增成功');
            onOk();
          })
          .finally(() => setConfirmLoading(false));
      }
      if (operationType === OperationType.EDIT) {
        apiV2HvacUpdatePost({ ...params, id: stationId })
          .then(res => {
            message.success('修改成功');
            onOk();
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <Modal
      title={`${OperationTypeDisplay[operationType]}暖通空调站`}
      contentClassName="modal-form-content"
      open
      bodyScroll
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select
              placeholder="请选择"
              options={(ouList ?? []).map(i => ({
                label: i.name,
                value: i.id,
              }))}
              showSearch
              allowClear
              optionFilterProp="label"
            ></Select>
          </Form.Item>
          <Form.Item
            label="站房名称"
            name="name"
            rules={[
              { required: true, message: '请输入空调站名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 20, message: '最多输入20个字符' },
            ]}
          >
            <Input placeholder="请输入站房名称" allowClear />
          </Form.Item>
          <Form.Item label="类型" name="type" rules={[{ required: true, message: '请选择类型' }]}>
            <Select placeholder="请选择" options={StationOptions} />
          </Form.Item>
          <Form.Item label="计量回路" name="meteringCircuitId">
            <Select placeholder="请选择" options={circuitOptions} showSearch allowClear optionFilterProp="label" />
          </Form.Item>
          <Form.Item label="能量表" name="energyDeviceId">
            <Select placeholder="请选择" options={deviceOptions} showSearch allowClear optionFilterProp="label" />
          </Form.Item>
          <Form.Item label="状态" name="status" initialValue={1}>
            <Radio.Group>
              <Radio value={1}>启用</Radio>
              <Radio value={0}>禁用</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default NewStation;
