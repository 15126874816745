import { Form, Input, Modal, Select } from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetInfoProp, getLoadTransformerList } from '../../../../../api/pv';
import { LoadTransformerDetailDtosProps } from '../../../../../api/uet';
interface Iprops {
  opType?: string; // 操作类型
  onCancel?: () => void;
  onConfirm?: (v: any) => void;
  row?: NetInfoProp;
  gridList?: NetInfoProp[];
}

interface LevelProps {
  label: string;
  value: number;
}
export const levelListMap: LevelProps[] = [
  {
    label: '0.4kV',
    value: 0,
  },
  {
    label: '6kV',
    value: 3,
  },
  {
    label: '10kV',
    value: 1,
  },
  {
    label: '20kV',
    value: 2,
  },
  {
    label: '35kV',
    value: 4,
  },
  {
    label: '66kV',
    value: 5,
  },
  {
    label: '110kV',
    value: 6,
  },
  {
    label: '220kV',
    value: 7,
  },
];

const { TextArea } = Input;
const NewGridPoint: React.FC<Iprops> = ({ onCancel, onConfirm, opType = 'add', row, gridList }) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [netLevelList] = useState<LevelProps[]>(levelListMap); // 并网等级
  const [transformList, setTransformList] = useState<LoadTransformerDetailDtosProps[]>([]); // 变压器
  // 当前选择的所有变压器
  const hasSelectTransformIds = useMemo(() => {
    let ids: number[] = [];
    if (gridList && gridList.length > 0) {
      gridList.forEach(item => {
        let transforms = item.loadTransformers;
        if (!transforms || transforms.length === 0) return;
        ids.push(...transforms.map(v => v.id));
      });
    }
    return ids;
  }, [gridList]);
  useEffect(() => {
    if (id) {
      // 负载变压器列表
      getLoadTransformerList(id).then(res => {
        setTransformList(data => {
          let tempArr = [...res.loadTransformerDetailDtos, ...data];
          // 编辑去重, 新增时过滤已经选过的
          if (opType === 'edit') {
            return getUniqueData(tempArr);
          }
          return tempArr.filter(item => !hasSelectTransformIds.includes(item.id));
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, opType]);
  // 去重
  const getUniqueData = (data: LoadTransformerDetailDtosProps[]) => {
    if (!data || data.length === 0) return [];
    let map = new Map();
    data.forEach(item => {
      if (!map.has(item.id)) {
        map.set(item.id, item);
      }
    });
    return [...map.values()];
  };
  const onFinish = async (v: NetInfoProp) => {
    let loadTransformers = transformList.filter(item => v.loadTransformerIds?.includes(item.id));
    onConfirm &&
      onConfirm({
        ...v,
        loadTransformers,
      });
    form.resetFields();
  };
  const formLayout = {
    labelCol: { flex: '120px' },
  };
  const modalTile = useMemo(() => (opType === 'add' ? '新建' : '编辑'), [opType]);

  useEffect(() => {
    if (opType === 'edit') {
      if (row) {
        row.loadTransformerIds = row.loadTransformers?.map(v => v.id);
      }
      form.setFieldsValue(row);
      if (row?.loadTransformers) {
        setTransformList(row.loadTransformers);
      }
    }
  }, [row, form, opType]);
  return (
    <>
      <Modal
        contentClassName="modal-form-content"
        title={modalTile + '并网点'}
        open
        destroyOnClose
        onCancel={() => {
          onCancel && onCancel();
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form {...formLayout} form={form} preserve={false} onFinish={onFinish}>
          <Form.Item
            label="并网点名称"
            name="name"
            rules={[
              { required: true, message: '并网点名称' },
              { max: 20, message: '最多20个字符' },
            ]}
          >
            <Input placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item label="并网点地址" name="address" rules={[{ max: 100, message: '最多100个字符' }]}>
            <TextArea rows={3} maxLength={100} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="并网等级" name="level" rules={[{ required: true, message: '请选择并网等级' }]}>
            <Select placeholder="请选择">
              {levelListMap.map(item => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewGridPoint;
