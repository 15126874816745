import { Key, fetch } from '@maxtropy/components';
import {
  ElectricitySaleContractSettlementType,
  SettlementGeneratorMode,
  SettlementRulesStatusType,
  TakeEffectStatusType,
} from './electricitySettlementRules';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';

export interface EnergyAssetsSettlementsRulesPageRes {
  id: Key;
  name: string; // 结算规则名称
  code: string; // 结算规则编号
  settlementType: ElectricitySaleContractSettlementType;
  startTime: string; // 生效开始时间
  endTime: string; // 生效结束时间
  effectiveStatus: TakeEffectStatusType; // 生效状态
  ouId: Key;
  OuName: string;
  saleElectricityContractId: Key;
  saleElectricityContractName: string;
  status: SettlementRulesStatusType;
  updateTime: string;
  lastModifyUser: string;
}

export interface EnergyAssetsSettlementsRulesPageReq {
  name?: string; // 结算规则名称
  code?: string; // 结算规则编号
  propertyManagementConfigIds?: Key[]; // 物业id
  saleElectricityContractIds?: Key[]; // 所属合同id
  saleClientIds?: Key[]; // 客户id
  mcids?: Key[]; // 所属组织
  effectiveState?: TakeEffectStatusType[]; // 生效状态
  status?: SettlementRulesStatusType[]; // 状态
  page?: number;
  size?: number;
}

export const getEnergyAssetsSettlementsRulesPage = (
  query: PageRequest | '',
  body: EnergyAssetsSettlementsRulesPageReq
) => {
  return fetch<PageResponse<EnergyAssetsSettlementsRulesPageRes>>(
    `/api/sale-electricity/marketingOfEnergyAssets/page?${qs.stringify(query, {
      indices: false,
    })}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    }
  );
};

// 新增/修改 结算规则
export interface EnergyAssetsSettlementsRulesBody {
  id?: Key; // 结算规则id
  name?: string; // 结算规则名称
  saleClientId?: Key; // 销售客户id
  saleElectricityContractId?: Key; // 销售客户合同	id
  startTime?: string; // 生效开始时间
  endTime?: string; // 生效结束时间
  settlementScene?: number; // 结算场景
  settlementType?: ElectricitySaleContractSettlementType; //结算类型
  settlementTemplate?: Key | null; // 是用电账户id  null表示是自定义结算电价，非null表示使用电价模板
  summitPrice?: number | null; //  尖时电价
  peakPrice?: number | null; //  峰时电价
  plainPrice?: number | null; //  平时电价
  valleyPrice?: number | null; //  谷时电价
  gridPrice?: number; // 上网电价
  discountForOwner?: number; // 业主电价折扣 / 业主储能放电折扣（0-1）1代表无折扣
  gridPercentageForOwner?: number; // 业主上网分成比例（0-1）0.2代表20%
  arrayId?: Key; // 	阵列id(光伏站id/储能站id)
  propertyManagementConfigId?: Key; // 物业id
  generationMode?: number; // 结算单生成方式
}

export const addOrEditEnergyAssetsSettlementsRules = (body: EnergyAssetsSettlementsRulesBody) => {
  return fetch<{ flag: boolean }>(
    '/api/v2/sale-electricity/marketingOfEnergyAssets/pvSettlementRule/upSet',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 根据id查询结算规则
export interface EnergyAssetsSettlementsRulesDetailRes {
  id: Key;
  name: string; // 结算规则名称
  code: string; // 结算规则编号
  saleClientId: Key; // 销售客户id
  saleClientName: string; // 销售客户名称
  customerMcid: string; // 所属组织
  customerName: string; // 所属组织
  propertyManagementConfigId: Key; // 物业id
  propertyManagementConfigName: string; // 物业名称
  saleElectricityContractId: Key; // 销售用电合同id
  saleElectricityContractName: string; // 销售用电合同名称
  startTime: string; // 结算规则开始时间
  endTime: string; // 结算规则结束时间
  contractStartTime: string;
  contractEndTime: string;
  status: SettlementRulesStatusType; // 状态（0:禁用 1：启用）
  settlementScene: number; // 结算场景
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  settlementTemplate: Key | null; // 是用电账户id  null表示是自定义结算电价，非null表示使用电价模板
  settlementTemplateName: string; // 电价模板名称
  summitPrice: number; // 尖时电价
  peakPrice: number; // 峰时电价
  plainPrice: number; // 平时电价
  valleyPrice: number; // 谷时电价
  gridPrice: number; // 上网电价
  discountForOwner: number; // 业主电价折扣 / 业主储能放电折扣（0-1）1代表无折扣
  gridPercentageForOwner: number; // 业主上网分成比例（0-1）0.2代表20%
  arrayId: Key; // 阵列id(光伏站id/储能站id)
  arrayName: string; // 阵列名称(光伏站名称/储能站名称)
  generationMode: SettlementGeneratorMode;
}

export const getEnergyAssetsSettlementsRulesDetail = (id: Key) => {
  return fetch<EnergyAssetsSettlementsRulesDetailRes>(
    `/api/v2/sale-electricity/marketingOfEnergyAssets/pvSettlementRule/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

// 禁用结算规则
export const disableEnergyAssetsSettlementsRules = (id: Key, status: SettlementRulesStatusType) => {
  return fetch(
    `/api/v2/sale-electricity/marketingOfEnergyAssets/pvSettlementRule/disable`,
    {
      method: 'POST',
      body: JSON.stringify({ id, status }),
    },
    true
  );
};

// 删除结算规则
export const deleteEnergyAssetsSettlementsRules = (id: Key) => {
  return fetch(
    `/api/v2/sale-electricity/marketingOfEnergyAssets/pvSettlementRule/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export enum PriceType {
  CUSTOM = 0, // 自定义
  TEMPLATE = 1, // 电价模板
}

export const PriceTypeValue = {
  [PriceType.CUSTOM]: '自定义',
  [PriceType.TEMPLATE]: '电价模板',
};

// 根据组织获取用电账户列表
export interface ElectricityAccountRes {
  id: Key;
  number: string; // 用电账户编号
}

export const getElectricityAccountList = (mcids: string) => {
  return fetch<{ list: ElectricityAccountRes[] }>(
    `/api/v2/electricity/account/listByMcids`,
    {
      method: 'POST',
      body: JSON.stringify({ mcids: [mcids] }),
    },
    true
  );
};

// 查询客户ou下所有启用的光伏站
export interface EnergyAssetsPVStationInOu {
  id: Key;
  name: string; // 光伏站名称
}

export const getEnergyAssetsPVStationInOu = () => {
  return fetch<EnergyAssetsPVStationInOu[]>(`/api/uet/pvStationInOu`, {
    method: 'POST',
  });
};

// 根据光伏站id获取下面的设备以及关口表
export interface EnergyAssetsPVStationDevice {
  id: Key;
  name: string; // 设备名称
  code: string; // 设备编码
  address: string; // 设备地址
  addressId: Key; // 设备地址id
  deviceTypeId: Key; // 设备类型id
  rate: number; // 倍率
}

export const getEnergyAssetsPVStationDevice = (id: Key) => {
  return fetch<EnergyAssetsPVStationDevice[]>(`/api/uet/pvDeviceList?${qs.stringify({ id }, { indices: false })}`, {
    method: 'POST',
  });
};

export enum TimeIntervalType {
  SHARP = 1, // 尖
  PEAK = 2, // 峰
  AVERAGE = 3, // 平
  VALLEY = 4, // 谷
  TOTAL = 5, // 总
}

export const TimeIntervalTypeDisplay = {
  [TimeIntervalType.SHARP]: '尖',
  [TimeIntervalType.PEAK]: '峰',
  [TimeIntervalType.AVERAGE]: '平',
  [TimeIntervalType.VALLEY]: '谷',
  [TimeIntervalType.TOTAL]: '总',
};
