import { Key } from 'react';
import { PhysicalQuantity } from './energyConsumption';

export interface EnergyAnalysisRequest {
  codeOrName?: string;
  mcid?: string;
  mcids?: string[];
}

export interface EnergyUnitItem {
  name: string;
  energyUnitId: number;
  energyUnitGroupId: number;
  parentId: number;
  disabled: boolean;
  children: EnergyUnitItem[];
}

export interface BannerResponse {
  total: number;
  cycleRatio: number;
  maxConsume: number;
  maxConsumeDate: Key;
  minConsume: number;
  minConsumeDate: Key;
  avgConsume: number;
  unit: Key;
}

export interface EnergyMediumsProps {
  id: number;
  name: string;
  currentValue: number;
  momDataChange: number;
  yoyDataChange: number;
  basePhysicalQuantityId: PhysicalQuantity;
}

export interface OverviewItemProps {
  energyUnitId: number;
  energyUnitGroupId?: number;
  energyUnitName: string;
  energyMediums: EnergyMediumsProps[];
  comprehensiveEnergyConsumption: EnergyMediumsProps | null;
  disabled?: boolean;
}

export enum SignType {
  PLUS = 'plus',
  MINUS = 'minus',
  NONE = 'none',
}

export interface UnitBasicProps {
  name: string;
  energyUnitId: number;
  energyUnitGroupId: number;
  disabled: boolean;
}
