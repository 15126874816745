import { Col, Form, Row } from 'antd';
import { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPricePropsType,
} from '../../../../../api/electricitySettlementRules';

const DistributedNewEnergyDetails: FC = () => {
  return (
    <div style={{ marginBottom: '30px', marginTop: '-60px' }}>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <Row>
                <Col span={24} style={{ paddingBottom: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <div style={{ padding: '20px', fontSize: '14px', fontWeight: 700, paddingBottom: '0px' }}>
                    {' '}
                    电度电价：
                  </div>
                </Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      {/* 时段选择尖峰平谷输入框 */}
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'summitRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('summitRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>尖峰时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('summitRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'peakRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('peakRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>峰时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('peakRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'plainRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('plainRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>平时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('plainRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'valleyRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('valleyRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>谷时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('valleyRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
    </div>
  );
};

export default DistributedNewEnergyDetails;
