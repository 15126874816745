import {
  BusTieStatusType,
  BusTieStatusTypeFormat,
  CircuitDeviceLinkType,
  CircuitType,
  CircuitTypeFormat,
  getDistributionCabinetList,
  VoltageLevelTypeFormatList,
} from '@/api/circuit';
import { getOuListOwned, OuListResponse } from '@/api/ou';
import { Col, FormInstance, Row, Space } from 'antd';
import { debounce } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash-es';
import { apiV2CircuitLabelAllPost } from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { Button, Form, Input, InputNumber, Select } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
interface BasicInfoFormProps {
  form: FormInstance;
  isEdit?: boolean;
}

const BasicInfoForm: FC<BasicInfoFormProps> = props => {
  const { form, isEdit } = props;
  const [ouList, setOuList] = useState<OuListResponse[]>([]);
  const [distributionList, setDistributionList] = useState<{ id: number; name: string }[]>([]);
  const [searchValue, setSearchValue] = useState<string>();
  const devices: any[] = Form.useWatch('devices', form);
  const hasCreatePermission = useHasPermission(PermissionsType.B_CIRCUIT_LABEL_CEATE_V2);

  useEffect(() => {
    getOuListOwned().then(setOuList); // 所属运营单元下拉选择
    getDistributionCabinetList().then(res => setDistributionList(res.list ?? [])); // 配电柜下拉
  }, []);

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  // 填写装机容量回填目标负载率
  const capacityChange = (v: number | null) => {
    if (v === 0) {
      form.setFieldsValue({
        targetLoadRate: '--',
      });
    } else {
      if (v && !isNil(form.getFieldValue('targetLoad'))) {
        const res = (form.getFieldValue('targetLoad') / v) * 100;
        form.setFieldsValue({
          targetLoadRate: Number(res.toFixed(2)),
        });
      } else {
        form.setFieldsValue({
          targetLoadRate: undefined,
        });
      }
    }
  };
  // 填写目标负载回填目标负载率
  const targetLoadChange = (v: number | null) => {
    if (form.getFieldValue('capacity') === 0) {
      form.setFieldsValue({
        targetLoadRate: '--',
      });
    } else {
      if (!v || !form.getFieldValue('capacity')) {
        form.setFieldsValue({
          targetLoadRate: undefined,
        });
      } else {
        const res = (v / form.getFieldValue('capacity')) * 100;
        form.setFieldsValue({
          targetLoadRate: Number(res.toFixed(2)),
        });
      }
    }
  };

  // 所属配电柜选项
  const distributionCabinetOptions = useMemo(() => {
    if (!distributionList) return [];
    return distributionList.map(i => ({ label: i.name, value: i.name }));
  }, [distributionList]);

  const onSearch = debounce((value: string) => {
    setSearchValue(value);
  }, 10);

  const onBlur = () => {
    if (searchValue) {
      form.setFieldsValue({
        distributionCabinetName: searchValue,
      });
    }
  };
  const { data: labelList, refresh } = useRequest(apiV2CircuitLabelAllPost);
  return (
    <>
      <Row gutter={50}>
        <Col span={8}>
          <Form.Item
            name="name"
            label="回路名称"
            rules={[
              { required: true, message: '请输入回路名称' },
              { max: 30, message: '最多输入30个字符！' },
            ]}
          >
            <Input placeholder="请输入回路名称" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="ouId"
            label="所属运营单元"
            extra={isEdit && '更改所属运营单元前请解绑计量表和生产负载表。'}
            rules={[{ required: true, message: '请选择所属运营单元' }]}
          >
            <Select
              placeholder="请选择所属运营单元"
              options={ouOptions}
              showSearch
              optionFilterProp="label"
              disabled={
                isEdit
                  ? devices &&
                    (devices.map(i => i.deviceType).includes(CircuitDeviceLinkType.GAUGE) ||
                      devices.map(i => i.deviceType).includes(CircuitDeviceLinkType.TRANSFORMER))
                    ? true
                    : false
                  : false
              }
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="回路类型" name="type" rules={[{ required: true, message: '请选择回路类型' }]}>
            <Select placeholder="请选择" disabled={isEdit}>
              {Object.keys(CircuitTypeFormat).map(key => (
                <Select.Option key={key} value={Number(key)}>
                  {CircuitTypeFormat[key as unknown as CircuitType]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['type']}>
          {({ getFieldValue }) =>
            getFieldValue('type') !== CircuitType.TRANSFORMER_CIRCUIT && (
              <Col span={8}>
                <Form.Item label="电压等级" name="voltageLevel" rules={[{ required: true, message: '请选择电压等级' }]}>
                  <Select placeholder="请选择">
                    {VoltageLevelTypeFormatList().map(([value, label]) => {
                      return (
                        <Select.Option key={value} value={Number(value)}>
                          {label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
        <Form.Item noStyle dependencies={['type']}>
          {({ getFieldValue }) =>
            getFieldValue('type') === CircuitType.TRANSFORMER_CIRCUIT && (
              <>
                <Col span={8}>
                  <Form.Item
                    label="进线电压等级"
                    name="voltageLevelIn"
                    rules={[{ required: true, message: '请选择进线电压等级' }]}
                  >
                    <Select placeholder="请选择">
                      {VoltageLevelTypeFormatList().map(([value, label]) => {
                        return (
                          <Select.Option key={value} value={Number(value)}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="出线电压等级"
                    name="voltageLevelOut"
                    rules={[{ required: true, message: '请选择出线电压等级' }]}
                  >
                    <Select placeholder="请选择">
                      {VoltageLevelTypeFormatList().map(([value, label]) => {
                        return (
                          <Select.Option key={value} value={Number(value)}>
                            {label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )
          }
        </Form.Item>
        <Col span={8}>
          <Form.Item noStyle dependencies={['type']}>
            {({ getFieldValue }) => {
              // 只有电网计费回路、变压器回路中必填，其他回路为非必填；
              let isRequired = [CircuitType.TRANSFORMER_CIRCUIT, CircuitType.GRID_BILLING_CIRCUIT].includes(
                getFieldValue('type')
              );
              return (
                <Form.Item
                  label="装机容量"
                  name="capacity"
                  rules={[{ required: isRequired, message: '请输入装机容量' }]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    precision={0}
                    max={999999}
                    min={0}
                    addonAfter="kVA"
                    placeholder="请输入"
                    onChange={capacityChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="目标负载" name="targetLoad">
            <InputNumber
              style={{ width: '100%' }}
              addonAfter="kW"
              min={0}
              max={999999}
              precision={0}
              placeholder="请输入"
              onChange={targetLoadChange}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="目标负载率" name="targetLoadRate">
            <InputNumber
              disabled
              style={{ width: '100%' }}
              addonAfter="%"
              min={0}
              max={100}
              precision={2}
              placeholder="目标负载/装机容量"
            />
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['type']}>
          {({ getFieldValue }) =>
            getFieldValue('type') === CircuitType.BUS_COUPLER_CIRCUIT && (
              <Col span={8}>
                <Form.Item name="busTieStatus" label="母联状态" rules={[{ required: true, message: '请选择母联状态' }]}>
                  <Select placeholder="请选择">
                    {Object.keys(BusTieStatusTypeFormat).map(key => (
                      <Select.Option key={key} value={Number(key)}>
                        {BusTieStatusTypeFormat[key as unknown as BusTieStatusType]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
        <Col span={8}>
          <Form.Item
            label="所属配电柜"
            name="distributionCabinetName"
            rules={[{ required: true, message: '请选择所属配电柜' }]}
          >
            <Select
              placeholder="请输入/选择"
              allowClear
              showSearch
              optionFilterProp="label"
              onSearch={onSearch}
              onBlur={onBlur}
              options={distributionCabinetOptions}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="备注" name="remark" rules={[{ max: 150, message: '最多输入150个字符！' }]}>
            <Input placeholder="请输入" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="回路标签" name="labelIds">
            <Select
              placeholder="请选择"
              options={labelList?.list?.map(item => ({ value: item.id, label: item.name }))}
              allowClear
              mode="multiple"
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Col>
        <Col span={8} style={{ marginLeft: -112, marginTop: -5 }}>
          <Form.Item label={<span></span>}>
            <Space size={10}>
              <Button onClick={() => refresh()}>刷新</Button>
              {hasCreatePermission && (
                <Button
                  type="link"
                  onClick={() => {
                    window.open('/dc/circuit/labelsManage', '_blank');
                  }}
                >
                  新建回路标签
                  <DoubleRightOutlined />
                </Button>
              )}
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BasicInfoForm;
