import { Form, InputNumber, SubContent } from '@maxtropy/components';
import { Col, FormInstance, Row } from 'antd';
import BeatInterval from '../BeatInterval';
import { LoadCurveProp } from '../LoadCurve';

interface WaveformsProps extends LoadCurveProp {
  form: FormInstance<any>;
}

const Waveforms = ({ form }: WaveformsProps) => {
  return (
    <>
      <SubContent title="相似度系数">
        <Row>
          <Col span={12}>
            <Form.Item
              initialValue={0.95}
              rules={[
                { required: true, message: '请输入正常节拍系数' },
                {
                  validator: (_, normalCoefficient) => {
                    const abnormalCoefficient = form.getFieldValue(['waveformRequest', 'abnormalCoefficient']);
                    if (abnormalCoefficient >= normalCoefficient) {
                      return Promise.reject('正常节拍系数必须大于异常节拍系数');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name={['waveformRequest', 'normalCoefficient']}
              dependencies={[['waveformRequest', 'abnormalCoefficient']]}
              label="正常节拍系数"
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0.0001}
                max={0.9999}
                step={0.0001}
                precision={4}
                placeholder="请输入"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              initialValue={0.8}
              rules={[
                {
                  required: true,
                  message: '请输入异常节拍系数',
                },
                {
                  validator: (_, abnormalCoefficient) => {
                    const normalCoefficient = form.getFieldValue(['waveformRequest', 'normalCoefficient']);
                    if (abnormalCoefficient >= normalCoefficient) {
                      return Promise.reject('正常节拍系数必须大于异常节拍系数');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              name={['waveformRequest', 'abnormalCoefficient']}
              dependencies={[['waveformRequest', 'normalCoefficient']]}
              label="异常节拍系数"
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0.0001}
                max={0.9999}
                step={0.0001}
                precision={4}
                placeholder="请输入"
              />
            </Form.Item>
          </Col>
        </Row>
        <BeatInterval />
      </SubContent>
    </>
  );
};

export default Waveforms;
