import dayjs from 'dayjs';
import { Rule } from 'antd/lib/form';
//2018年至当前下一年
const startYear = 2018;
const currentYear = dayjs().year();
export const YearsOptions = Array.from({ length: currentYear - startYear + 2 }, (_, index) => {
  const year = startYear + index;
  return { label: year.toString(), value: year.toString() };
});

export const validateText = (maxLen: number): Rule => ({
  validator(_, value) {
    if (value && !new RegExp(`^[^\n\r]{1,${maxLen}}$`).test(value)) {
      return Promise.reject(`长度不能超过${maxLen}`);
    }
    return Promise.resolve();
  },
});

//主要数据来源
export enum MainDataSources {
  ProductionReality = 1,
  IndustryStatistical = 2,
  AuthoritativeAgency = 3,
  Literature = 4,
  Others = 5,
}

export const MainDataSourcesTypeMap = {
  [MainDataSources.ProductionReality]: '生产实景数据',
  [MainDataSources.IndustryStatistical]: '行业统计数据',
  [MainDataSources.AuthoritativeAgency]: '权威机构调查报告',
  [MainDataSources.Literature]: '文献',
  [MainDataSources.Others]: '其他',
};
//获取方式
export enum Access {
  SelfProduced = 1,
  Outsourcing = 2,
  ExternalCooperation = 3,
}

export const AccessTypeMap = {
  [Access.SelfProduced]: '自产',
  [Access.Outsourcing]: '外采',
  [Access.ExternalCooperation]: '外协',
};
//输入类型
export enum InputType {
  Material = 1000,
  CONSUMABLES,
  PACKAGING_MATERIALS,
  CARBON_SINKS,
  ENERGY = 1009,
}

export const InputTypeMap = {
  [InputType.Material]: '原材料/物料',
  [InputType.CONSUMABLES]: '耗材',
  [InputType.PACKAGING_MATERIALS]: '包装材料',
  [InputType.CARBON_SINKS]: '碳汇',
  [InputType.ENERGY]: '能源',
};
//交通工具
export enum Vehicle {
  WaterTransport = 1,
  Railway = 2,
  Traffic = 3,
  Aviation = 4,
}

export const VehicleTypeMap = {
  [Vehicle.WaterTransport]: '水运',
  [Vehicle.Railway]: '铁路',
  [Vehicle.Traffic]: '道路交通',
  [Vehicle.Aviation]: '航空',
};

//输出类型
export enum OutputType {
  ExhaustGas = 1004,
  WasteWater,
  SolidWaste,
  HazardousWaste,
  Renewing,
}

export const OutputTypeMap = {
  [OutputType.ExhaustGas]: '废气',
  [OutputType.WasteWater]: '废水',
  [OutputType.SolidWaste]: '固废',
  [OutputType.HazardousWaste]: '危险废物',
  [OutputType.Renewing]: '可再生废料',
};

//核算边界
export enum BoundaryType {
  CradleToGate = 100,
  GateToGate,
  CRADLE_TO_GRAVE,
  CRADLE_TO_CRADLE,
}

export const BoundaryTypeMap = {
  [BoundaryType.CradleToGate]: '摇篮到大门',
  [BoundaryType.GateToGate]: '大门到大门',
  [BoundaryType.CRADLE_TO_GRAVE]: '摇篮到坟墓',
  [BoundaryType.CRADLE_TO_CRADLE]: '摇篮到摇篮',
};

//是否运输
export enum TransportType {
  NO_NEED = 0,
  NEED = 1,
}

export const TransportTypeMap = {
  [TransportType.NO_NEED]: '不需要运输',
  [TransportType.NEED]: '需要运输',
};

// 是否自动填报
export enum AutoFillSupport {
  NO_SUPPORT = 0,
  SUPPORT = 1,
}

export const AutoFillSupportMap = {
  [AutoFillSupport.SUPPORT]: '支持自动填报',
  [AutoFillSupport.NO_SUPPORT]: '不支持自动填报',
};

// 是否需要分摊
export enum SharedFlag {
  NO_SHARED = 0,
  SHARED,
}

export const SharedFlagMap = {
  [SharedFlag.SHARED]: '需要分摊',
  [SharedFlag.NO_SHARED]: '不需要分摊',
};

// 分摊规则
export enum SharedRule {
  PRODUCTION = 1,
  WEIGHT,
}

export const SharedRuleMap = {
  [SharedRule.PRODUCTION]: '按产品值分摊',
  [SharedRule.WEIGHT]: '按产品重量分摊',
};

//碳足迹设置

export enum CarbonFootPrintSettingType {
  Non_Negligible = 1,
  Negligible = 2,
  DataUnavailable = 3,
}

export const CarbonFootPrintSettingTypeMap = {
  [CarbonFootPrintSettingType.Non_Negligible]: '不可忽略',
  [CarbonFootPrintSettingType.Negligible]: '可忽略',
  [CarbonFootPrintSettingType.DataUnavailable]: '数据不可得',
};

export const MainDataSourcesTypeOptions = Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export enum AllocationType {
  WEIGHT = 1,
  AMOUNT,
  VOLUME,
  COST,
}

export const AllocationTypeMap = {
  [AllocationType.WEIGHT]: '按产品重量分配',
  [AllocationType.AMOUNT]: '按产品数量分配',
  [AllocationType.VOLUME]: '按产品体积分配',
  [AllocationType.COST]: '按产品价值分配',
};

export const AllocationTypeOptions = Object.entries(AllocationTypeMap).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export enum LifeCycleStage {
  RAW = 1,
  PRODUCE,
  DISTRIBUTION,
  USE,
  DISCARD,
}

export const LifeCycleStageMap = {
  [LifeCycleStage.RAW]: '原材料',
  [LifeCycleStage.PRODUCE]: '生产',
  [LifeCycleStage.DISTRIBUTION]: '分销',
  [LifeCycleStage.USE]: '使用',
  [LifeCycleStage.DISCARD]: '废弃',
};

export const LifeCycleStageOptions = Object.entries(LifeCycleStageMap).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));
