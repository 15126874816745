import { PermissionsType } from '@/common/permissionsConst';
import {
  Modal,
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  useTenantPermissions,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../index.module.scss';
import { AlarmType, AlarmTypeDisplay, RuleListProps, RuleLogic, RuleLogicDisplay } from '../../types';
import {
  V2ServeAlarmRulePagePostRequest,
  V2ServeAlarmRulePagePostResponse,
  apiV2ServeAlarmRuleDeletePost,
  apiV2ServeAlarmRulePagePost,
  apiV2ServeAlarmRuleUpdateStatusPost,
} from '@maxtropy/device-customer-apis-v2';

const columnsOther = [
  {
    title: '光伏站名称',
    dataIndex: 'pvName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '光伏站编号',
    dataIndex: 'pvCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则逻辑',
    dataIndex: 'ruleLogic',
    ellipsis: { showTitle: true },
    render: (v: RuleLogic) => <EllipsisSpan value={RuleLogicDisplay[v]} />,
  },
  {
    title: '规则状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmType) => (
      <EllipsisSpan value={<span className={styles[`color_${v}`]}>{AlarmTypeDisplay[v]}</span>} />
    ),
  },
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface RuleProps {
  searchParams?: V2ServeAlarmRulePagePostRequest;
  permission?: string[];
}

const ListTable: React.FC<RuleProps> = ({ permission, searchParams }) => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<V2ServeAlarmRulePagePostResponse['list']>();
  const permisson = useTenantPermissions();

  const getList = () => {
    setLoading(true);
    apiV2ServeAlarmRulePagePost({ ...searchParams, page: pageOffset, size: pageSize })
      .then(res => {
        if (res) setTotalCount(res.total as number);
        setList(res.list ?? []);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const columns = [
    ...columnsOther,
    {
      title: '操作',
      width: 160,
      fixed: 'right' as const,
      render: (record: RuleListProps) => (
        <Space size={16}>
          {(permisson ?? []).includes(PermissionsType.B_SERVERALARMRULEDELETE) && (
            <Button
              type="link"
              disabled={record.status === AlarmType.Enable}
              onClick={() => {
                if (record.id !== undefined) {
                  onDelete(record.id);
                }
              }}
            >
              删除
            </Button>
          )}
          {(permisson ?? []).includes(PermissionsType.B_SERVERALARMRULESTATUS) && (
            <Button
              type="link"
              onClick={() => {
                if (record.status !== undefined && record.id !== undefined) {
                  onChangeStatus(record.id, record.status);
                }
              }}
            >
              {record.status === AlarmType.Disable ? '启用' : '禁用'}
            </Button>
          )}
          {(permisson ?? []).includes(PermissionsType.B_SERVERALARMRULEUPDATE) && (
            <Button type="link">
              <Link to={`/pvOps/server/alarm/edit/${record?.id}`}>编辑</Link>
            </Button>
          )}

          <Button type="link">
            <Link to={`/pvOps/server/alarm/detail/${record?.id}`}>查看</Link>
          </Button>
        </Space>
      ),
    },
  ];

  const onDelete = (id: number) => {
    Modal.confirm({
      title: '确定删除？',
      content: '删除后不可恢复，你还要继续吗',
      okText: '继续',
      onOk: () => {
        apiV2ServeAlarmRuleDeletePost({ id: id }).then(res => {
          update();
        });
      },
    });
  };

  const onChangeStatus = (id: number, status: AlarmType) => {
    if (status === AlarmType.Disable) {
      Modal.confirm({
        title: '确定启用？',
        content: '启用后将按照规则进行报警，你还要继续吗',
        okText: '继续',
        onOk: () => {
          apiV2ServeAlarmRuleUpdateStatusPost({ id: String(id), status: String(AlarmType.Enable) }).then(res => {
            update();
          });
        },
      });
    } else if (status === AlarmType.Enable) {
      Modal.confirm({
        title: '确定禁用？',
        content: '禁用后将不再按照规则进行报警，你还要继续吗',
        okText: '继续',
        onOk: () => {
          apiV2ServeAlarmRuleUpdateStatusPost({ id: String(id), status: String(AlarmType.Disable) }).then(res => {
            update();
          });
        },
      });
    }
  };

  return (
    <>
      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1900 }}
        loading={loading}
        columns={columns}
        dataSource={list}
        className={styles.table}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default ListTable;
