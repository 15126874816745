import {
  FormContent,
  Wrapper,
  useBreadcrumbRoutes,
  Paging,
  EllipsisSpan,
  Table,
  Button,
  useSearchParams,
  ShowInput,
} from '@maxtropy/components';
import { MaterialWarehousingManagementParams } from '@/api/materielManagement';

import { Col, Row, Form, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import {
  apiV2CarbonFootprintEntryDetailPost,
  apiV2CarbonFootprintEntryOutboundPagePost,
  V2CarbonFootprintEntryOutboundPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
interface SearchParams extends Omit<MaterialWarehousingManagementParams, 'page' | 'size'> {}

const DetailMaterialWarehousingManagement: React.FC = () => {
  const routes = [{ name: '详情' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get('id');
  const pagingInfo = useSearchParams<SearchParams>(10, {});
  const { searchParams, setTotalCount } = pagingInfo;
  const navigator = useNavigate();
  const [unitCode, setUnitCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<V2CarbonFootprintEntryOutboundPagePostResponse['list']>();
  useEffect(() => {
    apiV2CarbonFootprintEntryDetailPost({ id: Number(id) }).then((res: any) => {
      setUnitCode(res.unitCode);

      form.setFieldsValue({
        ...res,
        storageTime: dayjs(res.storageTime).format('YYYY-MM-DD HH:mm:ss'),
        carbonFootPrint: `${res.carbonFootPrint} kgCO2e/${res.unitCode}`,
        inventoryQuantity: `${res.inventoryQuantity} ${res.unitCode}`,
        remainingQuantity: `${res.inventoryQuantity - res.outboundQuantity} ${res.unitCode}`,
      });
    });
    apiV2CarbonFootprintEntryOutboundPagePost({
      id: Number(id),
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res?.total!);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setTotalCount]);

  const columns = [
    {
      title: '出库时间',
      dataIndex: 'outboundTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
    },
    {
      title: `出库数量(${unitCode})`,
      dataIndex: 'outboundQuantity',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联生产单',
      dataIndex: 'productSheetCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '产品编码',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '产品名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '规格型号',
      dataIndex: 'spec',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormContent title="物料入库详情" style={{ padding: '5px 10px' }}>
        <Form form={form} labelAlign="left" layout={'vertical'} className={styles.c}>
          <div className={styles.content}>
            <div className={styles.basicTitle}>基础信息</div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="入库单号" name="entryNumber">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="物料编码" name="code">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="物料名称" name="name">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="规格型号" name="spec">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="入库数量" name="inventoryQuantity">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="剩余数量" name="remainingQuantity">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="产品碳足迹" name="carbonFootPrint">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="供应商" name="supplierName">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="入库人" name="staff">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="入库时间" name="storageTime">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <div className={styles.basicTitle}>出库日志</div>
            <Table columns={columns} dataSource={data} rowKey="id" loading={loading} />
            <Paging pagingInfo={pagingInfo} />
            <Space size="small" className={styles.stickyShadowFooter} style={{ paddingLeft: 0 }}>
              <Button
                onClick={() => {
                  navigator(`/productCarbonFootPrint/allocation/materialWarehousingManagement`);
                }}
              >
                返回
              </Button>
            </Space>
          </div>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default DetailMaterialWarehousingManagement;
