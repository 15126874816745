import { EnergyFlowChartResponse } from '@/api/plustekSankey';

export const DateFormat = 'YYYY-MM-DD';
export const MonthFormat = 'YYYY-MM';

export const MinusGreen = '#52C41A';
export const PlusRed = '#E64242';

export const formatCompositeNumWithUnit = (num: number) => {
  if (num > 10000) {
    return `${(num / 1000).toFixed(2)}tce`;
  } else {
    return `${num.toFixed(2)}kgce`;
  }
};

export const formatMediaNumWithUnit = (num: number, unit?: string) => {
  if (num > 100000) {
    return `${(num / 10000).toFixed(2)}万${unit}`;
  } else {
    return `${num.toFixed(2)}${unit}`;
  }
};

// 判断nodes or links 里 value 都为null
export const isAllValueNull = (data: EnergyFlowChartResponse) => {
  const { nodes, links } = data;
  const nodesValue = nodes.map(i => i.value);
  const linksValue = links.map(i => i.value);
  const isNodesValueNull = nodesValue.every(i => i === null);
  const isLinksValueNull = linksValue.every(i => i === null);
  return isNodesValueNull && isLinksValueNull;
};

// 12个月数据
export const echartsDataMock = [
  { ts: 1704038400000, value: 500 },
  { ts: 1706723200000, value: 100 },
  { ts: 1709315200000, value: 12 },
  { ts: 1711996800000, value: 300 },
  { ts: 1714588800000, value: 400 },
  { ts: 1717267200000, value: 120 },
  { ts: 1719945600000, value: 250 },
  { ts: 1722537600000, value: 300 },
  { ts: 1725216000000, value: 600 },
  { ts: 1727808000000, value: 1000 },
  { ts: 1730486400000, value: 800 },
  { ts: 1733164800000, value: 600 },
];
