import {
  EllipsisSpan,
  Key,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  Form,
  Input,
  CustomFilter,
  Modal,
  TreeSelect,
} from '@maxtropy/components';
import { Space } from 'antd';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useEffect, useMemo, useState } from 'react';
import { Device, getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { getRuleDevicePage, unbindDeviceSearchParams, unbindRuleDevice } from '../../../api/rule';
import { getRuleGroupDevicePage, unbindRuleGroupDevice } from '../../../api/ruleGroup';
import styles from './index.module.scss';

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface Props {
  tabsKey: string;
  setUnbindDeviceModalOpen: (v: boolean) => void;
  unbindDeviceModalOpen: boolean;
  id: Key;
}

type SearchParams = Omit<unbindDeviceSearchParams, 'page' | 'size'>;

const UnBindDeviceModal: React.FC<Props> = ({ tabsKey, setUnbindDeviceModalOpen, unbindDeviceModalOpen, id }) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Device[]>([]);
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganization(res);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (id) {
      if (tabsKey === '1') {
        getRuleDevicePage(id, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            if (res) {
              setTotalCount(res.total);
              setDeviceList(res.list);
            }
          })
          .finally(() => setLoading(false));
      } else {
        getRuleGroupDevicePage(id, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            if (res) {
              setTotalCount(res.total);
              setDeviceList(res.list);
            }
          })
          .finally(() => setLoading(false));
      }
    }
  }, [pageOffset, pageSize, searchParams, setTotalCount, id, tabsKey, updateState]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onFinish = (value: unbindDeviceSearchParams) => {
    setSearchParams({ ...value });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter colSpan={8} form={form} onFinish={val => onFinish(val as unbindDeviceSearchParams)} onReset={onReset}>
      <Form.Item label="编号/名称" name="codeOrName">
        <Input placeholder=" 请输入编号或名称查询" />
      </Form.Item>

      <Form.Item name="customerMcid" label="所属组织">
        <TreeSelect treeData={treeData} placeholder="请选择" style={{ width: '100%' }} />
      </Form.Item>
    </CustomFilter>
  );

  const unBindConfirm = () => {
    Modal.confirm({
      title:
        tabsKey === '1' ? (
          selectedRows.length > 1 ? (
            <p>
              确定将设备从
              <span className={styles.ruleGroup}>{selectedRows[0].ruleGroupName}</span>
              规则组中进行解绑？
            </p>
          ) : (
            <p>
              确定将{selectedRows[0].name}设备从
              <span className={styles.ruleGroup}>{selectedRows[0].ruleGroupName}</span>
              规则组中解绑？
            </p>
          )
        ) : (
          <p>
            确定从<span className={styles.ruleGroup}>{selectedRows[0].ruleGroupName}</span>规则组中解绑设备？
          </p>
        ),
      content: '解绑后设备不存在该报警规则组下',
      onOk() {
        if (tabsKey === '1') {
          const deviceRuleGroupList = selectedRows.map(i => {
            return {
              deviceId: i.id,
              ruleGroupId: i.ruleGroupId,
            };
          });
          unbindRuleDevice({
            ruleId: id,
            deviceRuleGroupList,
          }).then(() => {
            setUnbindDeviceModalOpen(false);
            updateFn();
          });
        } else {
          unbindRuleGroupDevice({
            ruleGroupId: id,
            deviceIds: selectedRows.map(i => i.id),
          }).then(() => {
            setUnbindDeviceModalOpen(false);
            updateFn();
          });
        }
      },
    });
  };

  const rowSelection = {
    onChange: (_: Key[], selectedRows: Device[]) => {
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: (selectedRows ?? []).map(i => i.id),
  };

  return (
    <>
      <Modal
        open={unbindDeviceModalOpen}
        title="设备"
        onCancel={() => {
          setUnbindDeviceModalOpen(false);
          setSelectedRows([]);
          setSearchParams(undefined);
          form.resetFields();
          setPageOffset(1);
        }}
        width={1024}
        footer={null}
      >
        {filters}
        <Space style={{ marginBottom: 10 }}>
          <Button type="primary" disabled={!selectedRows.length} onClick={unBindConfirm}>
            解绑
          </Button>
        </Space>
        <Table
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          sticky
          showSelectedCount
          selectedCount={selectedRows.length}
          scroll={{ y: 320 }}
          loading={loading}
          columns={columns}
          dataSource={deviceList}
        >
          <Paging pagingInfo={pagingInfo} />
        </Table>
      </Modal>
    </>
  );
};

export default UnBindDeviceModal;
