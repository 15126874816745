import { Button, EllipsisSpan, getRealUrl, Paging, Table, Modal, usePaging } from '@maxtropy/components';
import { apiV2ExcelPagePost, V2ExcelPagePostResponse } from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { LogType } from './Prop';

interface Props {
  onClose: () => void;
  type: LogType;
}

export type LogItem = Exclude<V2ExcelPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '导入状态',
    width: 200,
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导入时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'createByUsername',
    ellipsis: { showTitle: true },
    render: (v: string, record: LogItem) => <EllipsisSpan value={record.updateByUsername} />,
  },
];

const ImportLog: React.FC<Props> = ({ onClose, type }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [data, setData] = useState<LogItem[]>([]);

  useEffect(() => {
    setLoading(true);
    // 获取导入日志数据
    apiV2ExcelPagePost({ type, page: pageOffset, size: pageSize })
      .then((res: any) => {
        setData(res?.list ?? []);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, type, setTotalCount, setPageOffset]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (v: string, record: LogItem) => {
        return (
          <Button
            type="link"
            onClick={() => {
              window.open(getRealUrl(record.fileKey));
            }}
            disabled={record.importResult === 1}
          >
            下载失败数据
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        title="导入日志"
        size="big"
        onCancel={onClose}
        footer={<Button onClick={onClose}>关闭</Button>}
        open={true}
      >
        <Table
          rowKey="id"
          sticky
          loading={loading}
          scroll={{ y: 500 }}
          dataSource={data}
          columns={buildColumns}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
};

export default ImportLog;
