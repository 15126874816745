import { EnergyAssetsPVStationDevice } from '@/api/energyAssetsSettlementsRules';
import { Table } from '@maxtropy/components';

interface Props {
  data: EnergyAssetsPVStationDevice[];
  loading?: boolean;
}

const columns = [
  {
    title: '计量电表名称',
    dataIndex: 'name',
  },
  {
    title: '计量电表编号',
    dataIndex: 'code',
  },
  {
    title: '电表位置',
    dataIndex: 'address',
  },
];

const PhotovoltaicDeviceTable: React.FC<Props> = ({ data, loading }) => {
  return <Table loading={loading} rowKey="id" columns={columns} dataSource={data} />;
};

export default PhotovoltaicDeviceTable;
