import { CreateUETEMTResponse, DeviceResProps, DeviceVoProps } from '@/api/uet';
import { Button } from '@maxtropy/components';
import { Modal, Tag } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import DeviceModal, { DeviceModalRef } from '../DeviceModal';

// export interface DeviceSelectProps {
//   rootTypeId?:number;
//   id?:number;
//   name?:string;
// }

interface DeviceFormProps {
  value?: DeviceVoProps[];
  onChange?: (values: DeviceVoProps[]) => void;
  selectedOuIds?: number[];
  eMTBasicInfo?: CreateUETEMTResponse;
  energyMediumId?: number;
}

// 积算仪id
export const TOTALIZERID = 5023;

const DeviceListForm: FC<DeviceFormProps> = ({ value, onChange, selectedOuIds, eMTBasicInfo, energyMediumId }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const addDeviceRef = useRef<DeviceModalRef>(null);

  const [changeStatus, setChangeStatus] = useState<boolean>(false);
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (changeStatus) {
      onChange?.([]);
    }
    if (energyMediumId) {
      setChangeStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumId]);

  // 移除设备
  const onClose = (e: React.MouseEvent<HTMLElement>, deviceId: number) => {
    e.preventDefault();
    modalApi.confirm({
      title: '确定移除该设备？',
      okText: '确定',
      onOk: async () => {
        const removedDevice = (value ?? []).filter(item => {
          return item.id !== deviceId;
        });
        onChange?.(removedDevice);
      },
    });
  };

  // 添加设备
  const onOk = () => {
    if (addDeviceRef.current?.selectedRows) {
      let arr = addDeviceRef.current?.selectedRows;
      onChange?.(
        arr.map(i => ({
          rootTypeId: i.rootTypeId,
          id: i.id,
          name: i.name,
        }))
      );
    }
    setVisible(false);
  };

  const disabledItems = useMemo(() => {
    // console.log('value', value);
    return (value ?? []).map(i => ({
      rootTypeId: i.rootTypeId,
      id: i.id,
      name: i.name,
    }));
  }, [value]);

  const onChooseDevice = () => {
    // 如果包含积算仪，就只能选择一个
    setVisible(true);
  };

  return (
    <>
      <Button type="link" style={{ display: 'flex' }} onClick={() => onChooseDevice()}>
        {value && value.length ? '重新选择' : '请选择'}
      </Button>

      <div className={styles.value}>
        {value?.map(item => {
          return (
            <Tag key={item.id} closable={true} className={styles.tag} onClose={e => onClose(e, item.id)}>
              {item.name}
            </Tag>
          );
        })}
      </div>
      <DeviceModal
        ref={addDeviceRef}
        visible={visible}
        selectedOuIds={selectedOuIds}
        eMTBasicInfo={eMTBasicInfo}
        onOk={onOk}
        onCancel={() => setVisible(false)}
        disabledItems={disabledItems}
        energyMediumId={energyMediumId} // 能源介质id
      />
      {modalContextHolder}
    </>
  );
};

export default DeviceListForm;
