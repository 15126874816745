import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Radio, TimePicker } from '@maxtropy/components';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PlanItem, TimeType } from '../../utils';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';

export interface PlanShutdownModalProps {
  planItem?: any;
  planList?: PlanItem[];
  visible: boolean;
  onCancel: () => void;
  onOk: (values: any) => void;
}

const PlanShutdownModal: React.FC<PlanShutdownModalProps> = ({ visible, planList, planItem, onCancel, onOk }) => {
  const [form] = Form.useForm();
  const [planTimeList, setPlanTimeList] = useState<any[]>([]);

  useEffect(() => {
    if (planItem) {
      form.setFieldsValue({
        ...planItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planItem]);

  // 已存表格的所有时间段
  useEffect(() => {
    const list = (planList ?? []).filter(i => isNil(planItem) || i.id !== planItem.id);

    let timeSlices = list
      ?.map(p => {
        if (p.timeType === TimeType.DAY) {
          return getDayTimeRange(p.dayTime, p.dayDate);
        }
        return (p.customTimes ?? []).map(k => {
          return {
            sTime: k[0],
            eTime: k[1],
          };
        });
      })
      .flat();
    setPlanTimeList(timeSlices);
  }, [planList, planItem]);

  const checkTimeOverlap = (selectList: any[], prevList: any[]) => {
    const list = [...selectList, ...prevList]
      .map(m => ({
        s: m.sTime.valueOf(),
        e: m.eTime.valueOf(),
      }))
      .sort((a, b) => a.s - b.s);

    for (let i = 0; i < list.length - 1; i++) {
      if (list[i + 1].s <= list[i].e) {
        return true;
      }
    }
    return false;
  };

  const onSave = () => {
    form.validateFields().then(values => {
      const timeRange =
        values.timeType === TimeType.DAY
          ? `${dayjs(values.dayDate[0]).format('YYYY-MM-DD')}~${dayjs(values.dayDate[1]).format('YYYY-MM-DD')} ${dayjs(
              values.dayTime[0]
            ).format('HH:mm')}~${dayjs(values.dayTime[1]).format('HH:mm')}`
          : values.customTimes
              .map((k: any[]) => `${dayjs(k[0]).format('YYYY-MM-DD HH:mm')}~${dayjs(k[1]).format('YYYY-MM-DD HH:mm')}`)
              .join('，');

      onOk({
        ...values,
        timeRange,
        id: planItem?.id ?? uuidv4(),
      });
    });
  };

  // 获取按日的时间段
  const getDayTimeRange = (timeRange: any, dateRange: any) => {
    const sMinute = dayjs(timeRange[0]).minute();
    const eMinute = dayjs(timeRange[1]).minute();
    const sHour = dayjs(timeRange[0]).hour();
    const eHour = dayjs(timeRange[1]).hour();
    // 列出当前选择的所有时间段
    const dateValue = dayjs(dateRange[1]).valueOf() - dayjs(dateRange[0]).valueOf();
    const dateLen = dateValue / 1000 / 60 / 60 / 24 + 1;
    let timeSlices = [];
    for (let i = 0; i < dateLen; i++) {
      timeSlices.push({
        sTime: dayjs(dateRange[0]).add(i, 'day').set('hour', sHour).set('minute', sMinute),
        eTime: dayjs(dateRange[0]).add(i, 'day').set('hour', eHour).set('minute', eMinute),
      });
    }

    return timeSlices;
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      size="normal"
      bodyScroll
      contentClassName="modal-form-content"
      onCancel={onCancel}
      onOk={onSave}
      title={`${planItem ? '编辑' : '新增'}计划停机时间`}
    >
      <Form labelAlign="left" form={form} initialValues={{ timeType: TimeType.DAY }}>
        <Form.Item
          name="name"
          label="计划名称"
          rules={[
            { required: true, message: '请输入计划名称' },
            { min: 1, message: '至少输入1个字符' },
            { max: 30, message: '最多输入30个字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="timeType" label="时间范围" required>
          <Radio.Group>
            <Radio value={TimeType.DAY}>按日</Radio>
            <Radio value={TimeType.CUSTOM}>自定义日期时间</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="停机时间" dependencies={['timeType']} required>
          {({ getFieldValue }) => {
            const timeType = getFieldValue('timeType');
            if (timeType === TimeType.DAY) {
              return (
                <>
                  <Form.Item
                    name="dayDate"
                    rules={[
                      {
                        required: true,
                        message: '请选择停机日期',
                      },
                    ]}
                  >
                    <DatePicker.RangePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                  </Form.Item>
                  <Form.Item
                    name="dayTime"
                    dependencies={['dayDate']}
                    rules={[
                      {
                        required: true,
                        message: '请选择停机时间',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const itemDate = getFieldValue('dayDate');
                          if (!isNil(itemDate) && !isNil(value)) {
                            // 列出planList的所有时间段
                            const currentList = getDayTimeRange(value, itemDate);
                            // 是否冲突
                            if (checkTimeOverlap(currentList, planTimeList)) {
                              return Promise.reject(new Error('时间范围有冲突'));
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker.RangePicker style={{ width: '100%' }} format={'HH:mm'} />
                  </Form.Item>
                </>
              );
            }
            return (
              <Form.List
                name="customTimes"
                rules={[
                  {
                    validator: async (_, values) => {
                      if (!values || !values.length) {
                        return Promise.reject(new Error('请选择停机时间'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item required={false} key={field.key} className={styles.formRow}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true,
                              message: '请选择',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!isNil(value)) {
                                  const customTimes = getFieldValue('customTimes');
                                  const currentList = (customTimes ?? [])
                                    .filter((m: any) => !isNil(m))
                                    .map((k: (string | number | Date | dayjs.Dayjs | null | undefined)[]) => {
                                      return {
                                        sTime: dayjs(k[0]),
                                        eTime: dayjs(k[1]),
                                      };
                                    });
                                  // 是否冲突
                                  if (checkTimeOverlap(currentList, planTimeList)) {
                                    return Promise.reject(new Error('时间范围有冲突'));
                                  }
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          noStyle
                        >
                          <DatePicker.RangePicker style={{ width: '100%' }} showTime format={'YYYY-MM-DD HH:mm'} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined className={styles.removeIcon} onClick={() => remove(field.name)} />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                      新建
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
        <Form.Item name="reason" label="停机原因">
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PlanShutdownModal;
