export enum NodeType {
  EnergyUnitNode = 'energyUnitNode', // 用能单元
  WorkStationNode = 'workStationNode', // 工站
  ProcessNode = 'processNode', // 工序
  WorkCenterNode = 'workCenterNode', // 工作中心
  ProductionBaseNode = 'productionBaseNode', // 生产基地
}

export enum NodeLevel {
  EnergyUnitNode = 5, // 用能单元
  WorkStationNode = 4, // 工站
  ProcessNode = 3, // 工序
  WorkCenterNode = 2, // 工作中心
  ProductionBaseNode = 1, // 生产基地
}

export const nodeLevelTypeMap = {
  [NodeLevel.EnergyUnitNode]: NodeType.EnergyUnitNode,
  [NodeLevel.WorkStationNode]: NodeType.WorkStationNode,
  [NodeLevel.ProcessNode]: NodeType.ProcessNode,
  [NodeLevel.WorkCenterNode]: NodeType.WorkCenterNode,
  [NodeLevel.ProductionBaseNode]: NodeType.ProductionBaseNode,
};

// 常规节点宽高
export const commonNodeSize = {
  width: 180,
  height: 48,
};

// 节点增高的step
export const nodeHeightAddStep = {
  expand: 24 + 4, // 展开状态下，即显示用能单元；行高+marginTop
  unexpand: 16, // 收起状态下，不显示用能单元
};
