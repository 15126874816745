import {
  Button,
  CustomFilter,
  Dropdown,
  EllipsisSpan,
  Input,
  Paging,
  PopConfirm,
  Select,
  Table,
  Tag,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Form,
  Tooltip,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  apiV2AppAnalysisSubscriptionPagePost,
  V2AppAnalysisSubscriptionPagePostRequest,
  V2AppAnalysisSubscriptionPagePostResponse,
  apiV2AppAnalysisSubscriptionDelPost,
  apiV2AppAnalysisSubscriptionUpdateAnalysisSubscriptionTypePost,
  apiV2AppAnalysisSubscriptionUpStatusPost,
} from '@maxtropy/device-customer-apis-v2';
import { statusOptions, analyticsOptions, StatusType, AnalyticsDisplay, AnalyticsType } from '../prop';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';

export const RenderAnalyticsTypes = (data: TableRow['analyticsTypes'], maxTagCount: number) => {
  let restNode;
  let showNode = data;
  if (data && data.length > maxTagCount) {
    restNode = data.length - maxTagCount;
    showNode = data.slice(0, maxTagCount);
  }
  return data?.length ? (
    <Tooltip
      placement={'topLeft'}
      title={data.map(i => AnalyticsDisplay[i as keyof typeof AnalyticsDisplay]).join('、')}
    >
      <>
        {showNode?.map(item => (
          <Tag>{AnalyticsDisplay[item as keyof typeof AnalyticsDisplay]}</Tag>
        ))}
        {restNode && <Tag>+{restNode}</Tag>}
      </>
    </Tooltip>
  ) : (
    <span>--</span>
  );
};

const columns = [
  {
    title: '生产过程',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string, record: TableRow) => {
      const joinWord = [
        'productionBaseName',
        'workCenterName',
        'workProcedureName',
        'workStationName',
        'energyUnitName',
      ];
      const text = joinWord
        .map(key => record[key as keyof typeof record])
        .filter(string => !!string)
        .join('/');
      return <EllipsisSpan value={text} />;
    },
  },
  {
    title: '应用分析',
    width: 200,
    dataIndex: 'analyticsTypes',
    ellipsis: { showTitle: true },
    editable: true,
    render: (v: TableRow['analyticsTypes']) => RenderAnalyticsTypes(v, 2),
  },
  {
    title: '状态',
    width: 100,
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === StatusType.ENABLE ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              禁用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '最后操作时间',
    width: 150,
    dataIndex: 'opUpdateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    width: 100,
    dataIndex: 'opUpdateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof TableRow;
  record: TableRow;
  editing: boolean;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  editing,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Select placeholder="请选择" mode="multiple" maxTagCount={2} options={analyticsOptions} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface SearchParams extends Omit<V2AppAnalysisSubscriptionPagePostRequest, 'page' | 'size' | 'status'> {
  status: number;
}

type List = V2AppAnalysisSubscriptionPagePostResponse['list'];
export type TableRow = Exclude<List, undefined>[number];

const ProductionProcess: React.FC = () => {
  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TableRow[]>([]);
  const [editingKey, setEditingKey] = useState<Number>();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(50, { status: 1 });
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;

  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const navigate = useNavigate();

  const hasBatchConfiguration = useHasPermission(PermissionsType.B_BATCHCONFIGURATION); // 批量配置
  const hasEdit = useHasPermission(PermissionsType.B_EDITAPPANALYTICSSUBSCRIPTION); // 编辑
  const hasConfiguration = useHasPermission(PermissionsType.B_APPANALYTICSSUBSCRIPTIONCONFIG); // 配置
  const hasDelete = useHasPermission(PermissionsType.B_DELETEAPPANALYTICSSUBSCRIPTION); // 删除

  const isEditing = (record: TableRow) => record.id === editingKey;

  useEffect(() => {
    setLoading(true);
    setEditingKey(undefined);
    apiV2AppAnalysisSubscriptionPagePost({
      ...searchParams,
      status: [0, 1].includes(searchParams.status) ? [searchParams.status] : [],
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTotalCount(res.total ?? 0);
        setData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onChangeStatus = (record: TableRow) => {
    apiV2AppAnalysisSubscriptionUpStatusPost({ id: record.id, status: record.status === 1 ? 0 : 1 }).then(() => {
      updateFn();
    });
  };

  const onDelete = (id?: number) => {
    apiV2AppAnalysisSubscriptionDelPost({ id }).then(() => {
      updateFn();
    });
  };

  const cancel = () => {
    setEditingKey(undefined);
  };

  const save = async (id?: number) => {
    try {
      const { analyticsTypes } = (await tableForm.validateFields()) as TableRow;

      const newData = [...data];
      const index = newData.findIndex(item => id === item.id);

      apiV2AppAnalysisSubscriptionUpdateAnalysisSubscriptionTypePost({ id, analyticsTypes }).then(res => {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          analyticsTypes,
        });
        setData(newData);
        setEditingKey(undefined);
        updateFn();
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record: TableRow) => {
    tableForm.setFieldsValue({ analyticsTypes: record.analyticsTypes ?? [], ...record });
    setEditingKey(record.id);
  };

  const mergedColumns = columns.map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
    return col;
  });

  const buildColumns = [
    ...mergedColumns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      render: (v: any, record: TableRow) => {
        const editable = isEditing(record);
        return (
          <Space size={16}>
            {hasEdit &&
              (editable ? (
                <>
                  <Button type="link" onClick={() => save(record.id)}>
                    保存
                  </Button>
                  <Button type="link" onClick={() => cancel()}>
                    取消
                  </Button>
                </>
              ) : (
                <Button type="link" disabled={record.status === 0 || !!editingKey} onClick={() => edit(record)}>
                  编辑
                </Button>
              ))}
            {hasConfiguration && (
              <Dropdown
                menu={{
                  items: configMenuItems(record),
                }}
                type="link"
                disabled={record.status === 0 || !!editingKey}
              >
                配置
              </Dropdown>
            )}
            <Button type="link" disabled={!!editingKey} onClick={() => onChangeStatus(record)}>
              {record.status === 1 ? '禁用' : '启用'}
            </Button>
            {hasDelete && (
              <PopConfirm title="是否删除该对象？删除后无法恢复。" onConfirm={() => onDelete(record.id)}>
                <Button type="link" danger disabled={record.status === 1 || !!editingKey}>
                  删除
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const configMenuItems = (record: TableRow) => {
    let list = [
      {
        key: '2',
        label: '用能阈值配置',
        onClick: () => {
          navigate(`/energy/appAnalyticsSubscription/productionProcess/intervalConfig/${record.id}`);
        },
      },
    ];

    if (record.analyticsTypes?.includes(AnalyticsType.productionCycle)) {
      list.unshift({
        key: '1',
        label: '生产节拍配置',
        onClick: () => {
          navigate(`/energy/appAnalyticsSubscription/productionProcess/${record.id}`);
        },
      });
    }
    if (record.analyticsTypes?.includes(AnalyticsType.OEE)) {
      list.push({
        key: '3',
        label: 'OEE配置',
        onClick: () => {
          navigate(`/energy/appAnalyticsSubscription/productionProcess/oeeConfig/${record.id}?code=${record?.code}`);
        },
      });
    }
    return list;
  };

  const batchMenuItems = [
    {
      key: '1',
      // label: '批量添加应用',
      label: <Link to={`/energy/appAnalyticsSubscription/productionProcess/batch?operationType=1`}>批量添加应用</Link>,
    },
    {
      key: '2',
      label: <Link to={`/energy/appAnalyticsSubscription/productionProcess/batch?operationType=2`}>批量取消应用</Link>,
    },
  ];

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={() => reset({ status: 1 })}>
      <Form.Item label="生产过程" name="name">
        <Input placeholder="请输入名称查询" />
      </Form.Item>
      <Form.Item label="应用分析" name="analyticsTypes">
        <Select placeholder="请选择" mode="multiple" options={analyticsOptions} />
      </Form.Item>
      <Form.Item label="状态" name="status" initialValue={1}>
        <Select placeholder="请选择" options={statusOptions} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? [])]} filters={filters}>
      {hasBatchConfiguration && (
        <Space style={{ marginBottom: 10 }}>
          <Dropdown
            menu={{
              items: batchMenuItems,
            }}
            type="primary"
          >
            批量配置
          </Dropdown>
        </Space>
      )}
      <Form form={tableForm} component={false}>
        <Table
          loading={loading}
          columns={buildColumns}
          rowKey="id"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
        />
        <Paging pagingInfo={pagingInfo} />
      </Form>
    </Wrapper>
  );
};
export default ProductionProcess;
