import {
  Table,
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  Form,
  Select,
  useUpdate,
  message,
  Tag,
  Tooltip,
} from '@maxtropy/components';
import { Space, TableProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import {
  V2UetUnitConfigGetPostResponse,
  apiV2UetUnitConfigGetPost,
  apiV2UetUnitConfigSavePost,
} from '@maxtropy/device-customer-apis-v2';
import UploadImport from '@/components/UploadImport';
import { LogType } from '@/components/ImportLog/Prop';
import { omit } from 'lodash-es';
import styles from './index.module.scss';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

export type IndicatorUnitItem = Exclude<V2UetUnitConfigGetPostResponse['list'], undefined>[number];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: any;
  index: number;
}

type ColumnItem = {
  title: string;
  width: number;
  dataIndex: number;
  editable: boolean;
};

type UnitOptionItem = {
  indicatorId: number;
  physicalUInitOptions?: {
    id?: number;
    name?: string;
  }[];
};

const MetricUnitConfiguration: FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<IndicatorUnitItem[]>([]);
  const [buildColumns, setBuildColumns] = useState<ColumnItem[]>([]);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [update, updateFn] = useUpdate();
  const hasPermission = useHasPermission(PermissionsType.B_UNITEDIT);

  const [data, setData] = useState<any>([]);
  const [editingKey, setEditingKey] = useState<string>('');
  const [unitOptions, setUnitOptions] = useState<UnitOptionItem[]>([]);

  const isEditing = (record: any) => record.key === editingKey;

  useEffect(() => {
    setLoading(true);
    apiV2UetUnitConfigGetPost({})
      .then(res => {
        setDetail(res.list ?? []);

        // 设置表格数据
        const list = (res.list ?? []).map(item => {
          const arr = (item.indicatorConfigs ?? []).map(k => [k.indicatorId, k.physicalUnitName]);
          return {
            key: item.id + '-' + item.type,
            name: item.name,
            energyMediumSceneName: item.energyMediumSceneName,
            ...Object.fromEntries(arr),
          };
        });
        setData([...list]);

        const unitOptions = (res.list?.[0].indicatorConfigs ?? []).map(item => ({
          indicatorId: item.indicatorId!,
          physicalUInitOptions: item.physicalUInitOptions,
        }));

        setUnitOptions(unitOptions);

        const newColumns = (res.list?.[0].indicatorConfigs ?? []).map((item, index) => {
          return {
            title: item.indicatorName!,
            width: 140,
            dataIndex: item.indicatorId!,
            editable: true,
          };
        });
        // 设置列
        setBuildColumns(newColumns);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [update]);

  const saveRow = async (rowIndex: number) => {
    try {
      const row = await form.validateFields();
      const configMap = omit(row, ['key', 'name']);

      apiV2UetUnitConfigSavePost({
        list: [
          {
            id: detail[rowIndex].id,
            type: detail[rowIndex].type as any,
            indicatorConfigs: Object.entries(configMap).map(k => ({
              indicatorId: Number(k[0]),
              physicalUnitId: Number(k[1]),
            })),
          },
        ],
      }).then(res => {
        message.success('保存成功');

        updateFn();
        setEditingKey('');
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    const options = unitOptions.find((k: any) => dataIndex === k.indicatorId)?.physicalUInitOptions;
    return (
      <td {...restProps} className={`${restProps.className} ${editing ? styles.editRow : ''}`}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }} rules={[{ required: true }]}>
            <Select
              placeholder="请选择"
              style={{ width: '100%' }}
              options={options}
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns: TableProps['columns'] = [
    {
      title: '拓扑',
      ellipsis: true,
      width: 300,
      dataIndex: 'name',
      render: (value: string, record: any) => {
        const node = (
          <div style={{ display: 'flex' }}>
            <div
              style={{
                maxWidth: 200,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: 8,
              }}
            >
              {value}
            </div>
            <Tag border="solid" color="info">
              {record.energyMediumSceneName}
            </Tag>
          </div>
        );
        return <Tooltip title={value}>{node}</Tooltip>;
      },
      editable: false,
      fixed: 'left' as const,
    },
    ...buildColumns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 120,
      editable: false,
      fixed: 'right' as const,
      render: (v: any, record: any, index: number) => {
        const editable = isEditing(record);
        return (
          <Space size={16}>
            {editable ? (
              <>
                <Button type="link" onClick={() => saveRow(index)}>
                  保存
                </Button>
                <Button type="link" onClick={() => setEditingKey('')}>
                  取消
                </Button>
              </>
            ) : (
              <Button
                type="link"
                disabled={editingKey !== '' || !hasPermission}
                onClick={() => {
                  const arr = (detail[index].indicatorConfigs ?? []).map(k => [k.indicatorId, k.physicalUnitId]);

                  form.setFieldsValue({ ...record, ...Object.fromEntries(arr) });
                  setEditingKey(record.key);
                }}
              >
                编辑
              </Button>
            )}
          </Space>
        );
      },
    },
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Wrapper className="page_wrapper" routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <FormTitle
        title="指标单位配置"
        extraContent={
          <Button
            type="primary"
            disabled={!!editingKey}
            onClick={() => {
              setOpenImportModal(true);
            }}
          >
            批量导入
          </Button>
        }
      />
      <SubContent style={{ marginBottom: 0 }}>
        <Form form={form} component={false}>
          <Table
            rowKey="key"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{ x: 1300, y: 'calc(100vh - 290px)' }}
            style={{ width: '100%', marginTop: 10 }}
            loading={loading}
            columns={mergedColumns}
            dataSource={data}
          />
        </Form>
      </SubContent>
      {openImportModal && (
        <UploadImport title="批量导入" type={LogType.UNITCONFIF} setOpen={setOpenImportModal} updateFn={updateFn} />
      )}
    </Wrapper>
  );
};

export default MetricUnitConfiguration;
