import React, { useEffect, useState } from 'react';
import { Col, Space } from 'antd';
import { EllipsisSpan, Filter, Form, Input, Modal, Paging, Select, Table, Tag, usePaging } from '@maxtropy/components';
import { getDeviceTypeSubList } from '@/api/deviceType';
import type { DeviceType } from '@/shared/types';
import styles from './index.module.scss';
import {
  V2EnergyGroupCostConfigDevicePagePostResponse,
  apiV2EnergyGroupCostConfigDevicePagePost,
} from '@maxtropy/device-customer-apis-v2';

type DemandMeterModalProps = {
  open: boolean;
  closeModal: () => void;
  selectedDemandMeters: { id?: number; name?: string }[];
  setDemandMeters: (ds: { id?: number; name?: string }[]) => void;
  unitConfGroupId?: React.Key;
};

type SearchParams = {
  // 编号或名称
  codeOrName?: string;
  // 类目 id
  typeIds?: number[];
  // 用能单元id
  unitConfGroupId: string | number;
  ouIds?: number[];
  // 标签
  deviceTag?: string;
};

type FormValues = {
  codeOrName?: string;
  // 一级类目
  primaryTypeId?: number;
  // 二级类目
  secondaryTypeIds?: number[];
  // 标签
  deviceTag?: string;
};

export type DemandMeter = Exclude<V2EnergyGroupCostConfigDevicePagePostResponse['list'], undefined>[number];

// 需量表模态框
export default function DemandMeterModal({
  open,
  closeModal,
  selectedDemandMeters,
  setDemandMeters,
  unitConfGroupId,
}: DemandMeterModalProps) {
  const [form] = Form.useForm<FormValues>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  // 二级类目
  const [deviceTypes, setDeviceTypes] = useState<Array<DeviceType>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [devices, setDevices] = useState<DemandMeter[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    typeIds: [5000],
    unitConfGroupId: unitConfGroupId!,
  });
  const [selectedDeviceRowKeys, setSelectedDeviceRowKeys] = useState<React.Key[]>(
    selectedDemandMeters.map(dm => dm.id!)
  );
  const [selectedDevices, setSelectedDevices] = useState<{ id?: number; name?: string }[]>(selectedDemandMeters!);

  // 获取电表二级类目
  useEffect(() => {
    getDeviceTypeSubList(5000).then(res => setDeviceTypes(res.list ?? []));
  }, []);

  // 获取设备
  useEffect(() => {
    if (unitConfGroupId) {
      setLoading(true);
      apiV2EnergyGroupCostConfigDevicePagePost({
        ...searchParams,
        energyUnitId: unitConfGroupId!,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setTotalCount(res.total as number);
          setDevices((res.list ?? []) as DemandMeter[]);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [pageOffset, pageSize, searchParams, setTotalCount, unitConfGroupId]);

  const columns = [
    {
      title: '设备编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      dataIndex: 'typeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备标签',
      dataIndex: 'tags',
      ellipsis: { showTitle: true },
      render: (v: string[] | null) => {
        return v?.length ? v?.map(tag => <Tag>{tag}</Tag>) : '--';
      },
    },
    {
      title: '所属组织',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  return (
    <Modal
      width={1200}
      title="需量表选择"
      open={open}
      onOk={() => {
        setDemandMeters(selectedDevices);
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <Filter
        onFinish={(v: FormValues) => {
          if (v?.secondaryTypeIds?.length) {
            setSearchParams({
              unitConfGroupId: unitConfGroupId!,
              deviceTag: v.deviceTag,
              codeOrName: v.codeOrName,
              typeIds: v.secondaryTypeIds,
            });
          } else {
            setSearchParams({
              deviceTag: v.deviceTag,
              unitConfGroupId: unitConfGroupId!,
              codeOrName: v.codeOrName,
              typeIds: [5000],
            });
          }
        }}
        form={form}
        onReset={() => {
          setSearchParams({
            unitConfGroupId: unitConfGroupId!,
            codeOrName: undefined,
            deviceTag: undefined,
            typeIds: [5000],
          });
          setPageOffset(1);
        }}
      >
        <Col span={8}>
          <Form.Item name="codeOrName" label="编号/名称">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="primaryTypeId" label="一级类目" initialValue={5000}>
            <Select
              options={[
                {
                  label: '电表',
                  value: 5000,
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="secondaryTypeIds" label="二级类目">
            <Select
              placeholder="请选择二级类目"
              allowClear
              mode="multiple"
              optionFilterProp="label"
              options={(deviceTypes ?? []).map(i => ({ value: i.id, label: i.name }))}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="deviceTag" label="标签">
            <Input placeholder="请输入标签" />
          </Form.Item>
        </Col>
      </Filter>
      <div className={styles.table_text}>
        <Space>
          <span />
        </Space>
        <span>
          点击前往
          <a rel="noreferrer" href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`} target="_blank">
            添加设备
          </a>
        </span>
      </div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'radio',
          hideSelectAll: true,
          selectedRowKeys: selectedDeviceRowKeys,
          preserveSelectedRowKeys: true,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedDeviceRowKeys(selectedRowKeys);
            setSelectedDevices(selectedRows.map(r => ({ id: r.id, name: r.name })));
          },
        }}
        dataSource={devices}
        rowKey="id"
        sticky
        scroll={{ y: 300 }}
        columns={columns}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
}
