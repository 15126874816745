import { Col, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import ChooseBusinessUnit from './component/ChooseBusinessUnit';
import { createUetBasic, CreateUetBasicReq, UetInfoProps, updateUet } from '../../../../api/uet';
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from '@maxtropy/components';
import FixedBottomButton from '@/components/FixedBottomButton';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 23 },
};

interface BaseSettingProps {
  info?: UetInfoProps;
  forceUpdate: () => void;
  isDetail?: boolean;
}

const BaseSetting: React.FC<BaseSettingProps> = ({ info, forceUpdate, isDetail }) => {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    if (info && info.businessUnits) {
      form.setFieldsValue({
        uetBusinessUnitTypeIds: info.businessUnits.map(i => i.businessUnitType),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const onFinish = async (v: CreateUetBasicReq) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res =
      info && info?.businessUnits
        ? await updateUet(info.id.toString(), { uetBusinessUnitTypeIds: v.uetBusinessUnitTypeIds })
        : await createUetBasic(info!.id, v);
    forceUpdate();
    setEdit(false);
  };

  return (
    <>
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="全域能源管理拓扑包含主要业务单元"
              name="uetBusinessUnitTypeIds"
              rules={[{ required: true, message: '请至少选择一个' }]}
            >
              <ChooseBusinessUnit disabled={!edit} businessUnits={info?.businessUnits ?? []} />
            </Form.Item>
          </Col>
        </Row>
        <FixedBottomButton>
          {edit ? (
            <Space size={8}>
              <Button htmlType="submit" type="primary">
                保存
              </Button>
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                    onOk: () => {
                      form.resetFields();
                      forceUpdate();
                      setEdit(false);
                    },
                  });
                }}
              >
                取消
              </Button>
            </Space>
          ) : (
            <>
              {isDetail ? (
                <Button type="primary">
                  <Link to={`/energy/basic/uet`}>返回列表</Link>
                </Button>
              ) : (
                <Button type="primary" onClick={() => setEdit(true)}>
                  编辑
                </Button>
              )}
            </>
          )}
        </FixedBottomButton>
      </Form>
    </>
  );
};

export default BaseSetting;
