import { OrganizationResponse } from '../../api/customer-user';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

export const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const node: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return node;
  });
};

export interface TiledArrayProps {
  key: string;
  title: string;
}

export const tiledArray = (data: OrganizationResponse[]) => {
  const res: TiledArrayProps[] = [];
  function loop(array: OrganizationResponse[]) {
    array.forEach(item => {
      if (item.data.name) {
        res.push({
          key: item.data.mcid,
          title: item.data?.name,
        });
      }
      if (item.children) loop(item.children);
    });
  }
  loop(data);
  return res;
};
