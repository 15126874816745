import { Badge, Card, Col, FormInstance, FormListFieldData, Row, Space } from 'antd';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import {
  AttachmentUpload,
  Button,
  Form,
  FormContent,
  Input,
  Modal,
  Select,
  Tabs,
  Tooltip,
  message,
} from '@maxtropy/components';
import {
  CreateUETEMTResponse,
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeListDisplay,
  UetEnergyMediumListResponse,
  getUetEnergyMediumList,
} from '@/api/uet';
import { OuListResponse, getOuListOwned } from '@/api/ou';
import { UetProcessPicPostResponse, apiUetProcessPicPost } from '@maxtropy/device-customer-apis';
import { checkFormRepeatName, hasConversionOptions, processTypeOptions } from '../../utils';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { ShareDataContext } from '../..';
import ProcessTabContent from '../ProcessTabContent';

const fileSize = 1 * 1024 * 1024;

export interface IEditItemInfo {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
  isNew?: boolean;
  processForm: FormInstance<any>;
  eMTBasicInfo?: CreateUETEMTResponse;
  onProcessFormFinish: (removeFunc?: Function, index?: number | number[]) => Promise<void>;
  initState: () => void;
}

const EditItemInfo: FC<IEditItemInfo> = props => {
  const { field, remove, isNew = false, processForm, eMTBasicInfo, onProcessFormFinish, initState } = props;

  const {
    processType,
    setProcessType,
    energyMediumIds,
    setEnergyMediumIds,
    selectedOuIds,
    setSelectedOuIds,
    inOutFieldsLength,
    setInOutFieldsLength,
    tabsActiveKey,
    setTabsActiveKey,
    setImgValue,
    imgValue,
    createdProcesses,
    setCreatedProcesses,
    setOptions,
  } = useContext(ShareDataContext);

  const [ouList, setOuList] = useState<OuListResponse[]>([]); // ou下拉
  const [pictureList, setPictureList] = useState<UetProcessPicPostResponse>([]); // 图片

  const [energyMediumList, setEnergyMediumList] = useState<UetEnergyMediumListResponse[]>([]); // 能源介质列表

  useEffect(() => {
    getOuListOwned().then(setOuList); // 所属运营单元下拉选择
    apiUetProcessPicPost().then(setPictureList); // 获取节点图片
  }, []);

  // 获取能源介质列表
  useEffect(() => {
    if (eMTBasicInfo && eMTBasicInfo.energyMediumSceneId) {
      getUetEnergyMediumList(String(eMTBasicInfo.energyMediumSceneId)).then(setEnergyMediumList);
    }
  }, [eMTBasicInfo]);

  // ou下拉列表
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  // 图片下拉框
  const pictureOptions = useMemo(() => {
    if (pictureList && pictureList.length !== 0) {
      return pictureList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [pictureList]);

  // 更改节点类型
  const onProcessTypeChange = (value: EnergyWorkingProcessType) => {
    // 先判断有没有输入输出的list，没有的话就不做弹窗校验
    const getEntryOrExit =
      !!processForm.getFieldValue('addProcess')[0].processEntryCreateRequests?.length ||
      !!processForm.getFieldValue('addProcess')[0].processExitCreateRequests?.length;

    if (getEntryOrExit) {
      processForm.setFieldsValue({
        addProcess: [
          {
            ...processForm.getFieldValue('addProcess')[0],
            type: processType,
          },
        ],
      });
      Modal.confirm({
        title: '确认是否更改？',
        icon: <ExclamationCircleOutlined />,
        content: '更改节点类型将清空所有节点输入、输出、节点内内容',
        okText: '确认更改',
        onOk: () => {
          processForm.setFieldValue('addProcess', [
            {
              ...processForm.getFieldValue('addProcess')[0],
              processEntryCreateRequests: undefined,
              processExitCreateRequests: undefined,
              processNodeCreateRequests: undefined,
              type: value,
            },
          ]);
          setProcessType?.(value);
          setInOutFieldsLength?.([]); // 计数清除
        },
        onCancel: () => {},
      });
    } else {
      setProcessType?.(value);
    }
  };

  // 能源介质change
  const onEnergyMediumChange = (ids: number[], type: EnergyWorkingProcessType) => {
    if ((energyMediumIds ?? []).length > ids.length) {
      processForm.setFieldsValue({
        addProcess: [
          {
            ...processForm.getFieldValue('addProcess')[0],
            energyMediumIds: energyMediumIds,
          },
        ],
      });
      Modal.confirm({
        title: '确认是否更改？',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            {type === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION
              ? '删除原有能源介质，会清空节点输入和输出的【能源介质】【引自】和【计量设备】字段'
              : type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE
              ? '删除原有能源介质，会清空节点输出的【能源介质】和【计量设备】字段'
              : '删除原有能源介质，会清空【能源介质】和【计量设备】字段'}
          </>
        ),
        okText: '确认更改',
        onOk: () => {
          setEnergyMediumIds?.(ids);
          if (type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE) {
            processForm.setFieldValue('addProcess', [
              {
                ...processForm.getFieldValue('addProcess')[0],
                energyMediumIds: ids,
                processExitCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processExitCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                    };
                  }),
              },
            ]);
          } else if (type === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION) {
            processForm.setFieldValue('addProcess', [
              {
                ...processForm.getFieldValue('addProcess')[0],
                energyMediumIds: ids,
                processEntryCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processEntryCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                      refId: undefined,
                    };
                  }),
                processExitCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processExitCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                    };
                  }),
              },
            ]);
          } else {
            processForm.setFieldValue('addProcess', [
              {
                ...processForm.getFieldValue('addProcess')[0],
                energyMediumIds: ids,
                processEntryCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processEntryCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                      refId: undefined,
                    };
                  }),
                processExitCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processExitCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                    };
                  }),
                processNodeCreateRequests: processForm
                  .getFieldValue('addProcess')[0]
                  .processNodeCreateRequests?.map((i: any) => {
                    return {
                      ...i,
                      energyMediumId: undefined,
                      deviceIds: undefined,
                      deviceVos: undefined,
                    };
                  }),
              },
            ]);
          }
        },
        onCancel: () => {
          setEnergyMediumIds?.([...(energyMediumIds ?? [])]);
        },
      });
    } else {
      setEnergyMediumIds?.(ids);
    }
  };

  // 切换ou时候的校验
  const onOuIdsChange = (ids: number[]) => {
    if ((selectedOuIds ?? []).length > ids.length) {
      processForm.setFieldsValue({
        addProcess: [
          {
            ...processForm.getFieldValue('addProcess')[0],
            // processEntryCreateRequests: processForm
            //   .getFieldValue('addProcess')[0]
            //   .processEntryCreateRequests?.map((i: any) => {
            //     return {
            //       ...i,
            //       refId: i.refFlag ? i?.refName : i.refId,
            //     };
            //   }),
            ouIds: selectedOuIds,
          },
        ],
      });
      Modal.confirm({
        title: '确认是否更改？',
        icon: <ExclamationCircleOutlined />,
        content: '删除原运营单元将清除所有计量设备',
        okText: '确认更改',
        onOk: () => {
          setSelectedOuIds?.(ids);
          processForm.setFieldValue('addProcess', [
            {
              ...processForm.getFieldValue('addProcess')[0],
              ouIds: ids,
              processEntryCreateRequests: processForm
                .getFieldValue('addProcess')[0]
                .processEntryCreateRequests?.map((i: any) => {
                  return {
                    ...i,
                    deviceIds: undefined,
                    deviceVos: undefined,
                  };
                }),
              processExitCreateRequests: processForm
                .getFieldValue('addProcess')[0]
                .processExitCreateRequests?.map((i: any) => {
                  return {
                    ...i,
                    deviceIds: undefined,
                    deviceVos: undefined,
                  };
                }),
              processNodeCreateRequests: processForm
                .getFieldValue('addProcess')[0]
                .processNodeCreateRequests?.map((i: any) => {
                  return {
                    ...i,
                    deviceIds: undefined,
                    deviceVos: undefined,
                  };
                }),
            },
          ]);
        },
        onCancel: () => {
          setSelectedOuIds?.([...(selectedOuIds ?? [])]);
        },
      });
    } else {
      setSelectedOuIds?.(ids);
    }
  };

  const tabs = useCallback(
    (fieldName: number) => {
      const tabsTemp = [
        {
          key: '1',
          label: (
            <div className={styles.tab_item_title}>
              <span>输入</span>
              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '1' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '1' ? '#FFF' : '#00ADFF'}
                count={(inOutFieldsLength ?? []).find(i => i.key === fieldName + 'in')?.number ?? 0}
              />
            </div>
          ),
        },
        {
          key: '2',
          label: (
            <div className={styles.tab_item_title}>
              <span>节点内</span>

              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '2' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '2' ? '#FFF' : '#00ADFF'}
                count={(inOutFieldsLength ?? []).find(i => i.key === fieldName + 'inside')?.number ?? 0}
              />
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className={styles.tab_item_title}>
              <span>输出</span>
              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '3' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '3' ? '#FFF' : '#00ADFF'}
                count={(inOutFieldsLength ?? []).find(i => i.key === fieldName + 'out')?.number ?? 0}
              />
            </div>
          ),
        },
      ];
      if (processType !== EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION) {
        return tabsTemp;
      } else {
        return tabsTemp.filter(i => i.key !== '2');
      }
    },
    [tabsActiveKey, inOutFieldsLength, processType]
  );

  const countInAndOutProcessNumber = (number: number, key: string) => {
    setInOutFieldsLength?.(list => {
      let newList = [...list];
      const findIndex = newList.findIndex(i => i.key === key);
      const findCurrent = newList.find(i => i.key === key);
      if (findCurrent) {
        newList.splice(findIndex, 1, {
          key,
          number,
        });
      } else {
        newList = [...newList, { key, number }];
      }
      return newList;
    });
  };

  return (
    <Card key={field.key} className={styles.CardStyle}>
      <FormContent
        key={field.key}
        style={{ padding: 0, backgroundColor: 'rgba(38, 45, 55, 1)' }}
        title={isNew ? undefined : `编辑节点`}
      >
        <Row key={field.key} gutter={10}>
          <Col span={8}>
            <Form.Item
              name={[field.name, 'type']}
              label="节点类型"
              rules={[{ required: true, message: '请选择节点类型' }]}
              initialValue={EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION}
            >
              <Select
                style={{ width: '90%' }}
                placeholder="请选择节点类型"
                onChange={value => onProcessTypeChange(value)}
                options={processTypeOptions}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={[field.name, 'name']}
              label="节点名称"
              rules={[
                { required: true, message: '请输入节点名称' },
                { max: 15, message: '最多输入15个字符！' },
                { pattern: /^[^/:*?"<>|\\]*$/g, message: '提示：禁用/、\\、:、*、?、"、<、>、|符号' },
              ]}
            >
              <Input style={{ width: '90%' }} placeholder="请输入" />
            </Form.Item>
          </Col>

          <Form.Item noStyle dependencies={[['addProcess', field.name, 'type']]}>
            {({ getFieldValue }) => {
              let type = getFieldValue(['addProcess', field.name, 'type']);
              return (
                <Col span={8}>
                  <Form.Item
                    name={[field.name, 'energyMediumIds']}
                    // label={`输${type === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION ? '配' : '出'}能源介质`}
                    label={EnergyWorkingProcessTypeListDisplay[type as EnergyWorkingProcessType]}
                    rules={[{ required: true, message: '请选择能源介质' }]}
                  >
                    <Select
                      style={{ width: '90%' }}
                      mode="multiple"
                      placeholder="请选择"
                      onChange={ids => onEnergyMediumChange(ids, type)}
                      options={energyMediumList?.map(i => ({ label: i.energyName, value: i.id }))}
                      showSearch
                      optionFilterProp="label"
                    />
                  </Form.Item>
                </Col>
              );
            }}
          </Form.Item>

          <Col span={8}>
            <Form.Item
              name={[field.name, 'ouIds']}
              label="所属运营单元"
              rules={[{ required: true, message: '请选择所属运营单元' }]}
            >
              <Select
                style={{ width: '90%' }}
                mode="multiple"
                placeholder="请选择所属运营单元"
                onChange={value => onOuIdsChange(value)}
                options={ouOptions}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>

          <Form.Item noStyle dependencies={[['addProcess', field.name, 'type']]}>
            {({ getFieldValue }) => {
              return (
                getFieldValue(['addProcess', field.name, 'type']) ===
                  EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, 'hasConversion']}
                      label={
                        <>
                          <Tooltip
                            placement="right"
                            title={'该节点是否涉及能源介质的制备、转换。如热能发电、空气压缩过程等'}
                          >
                            是否发生转化
                            <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: 5 }} />
                          </Tooltip>
                        </>
                      }
                      rules={[{ required: true, message: '请选择是否发生转换' }]}
                    >
                      <Select
                        style={{ width: '90%' }}
                        placeholder="请选择是否发生转换"
                        options={hasConversionOptions}
                      />
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>

          <Col span={8}>
            <Form.Item name={[field.name, 'picture']} label="附图">
              <Select
                style={{ width: '90%' }}
                placeholder="请选择附图"
                options={pictureOptions}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>

          <Form.Item noStyle dependencies={[['addProcess', field.name, 'picture']]}>
            {({ getFieldValue }) => {
              return (
                getFieldValue(['addProcess', field.name, 'picture']) === 0 && (
                  <Col span={8}>
                    <Form.Item name={[field.name, 'pictureKey']}>
                      <ImgCrop
                        maxZoom={10}
                        aspect={1}
                        beforeCrop={v => {
                          const { name } = v;
                          const accept = '.gif,.png,.jpg';
                          const suffix = name.split('.')[name.split('.').length - 1];
                          const limitFileType = accept ? accept.includes(suffix.toLocaleLowerCase()) : true;

                          if (v.size > fileSize) {
                            message.error('上传文件的大小不得超过1M');
                            return false;
                          }
                          if (!limitFileType) {
                            message.error(`请上传正确的文件格式(${accept})`);
                            return false;
                          }
                          return true;
                        }}
                        onModalOk={(v: any) => {
                          const formData = new FormData();
                          formData.append('file', v);
                          fetch('/api/file-center/upload', {
                            method: 'POST',
                            body: formData,
                          })
                            .then(res => res.json())
                            .then(v => {
                              setImgValue?.(v.key);
                              processForm?.setFieldValue(['addProcess', field.name, 'pictureKey'], v.key);
                            });
                        }}
                      >
                        <AttachmentUpload
                          value={imgValue}
                          onRemove={() => {
                            setImgValue?.(undefined);
                            processForm?.setFieldValue(['addProcess', field.name, 'pictureKey'], undefined);
                          }}
                          fileSize={1}
                          accept=".gif,.png,.jpg"
                        />
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                )
              );
            }}
          </Form.Item>

          <Col className={styles.AddProcessTabs} span={24}>
            <Tabs
              size="small"
              type="card"
              items={tabs(field.name)}
              onChange={key => setTabsActiveKey?.(key)}
              activeKey={tabsActiveKey}
            />
            {tabsActiveKey && (
              <ProcessTabContent
                callOptions={vals => {
                  setOptions?.(vals);
                }}
                countInAndOutProcessNumber={(number, key) => countInAndOutProcessNumber(number, key)}
                currentKey={tabsActiveKey}
                handleTabsActiveKey={(key: string) => setTabsActiveKey?.(key)}
                fieldName={field.name}
                eMTBasicInfo={eMTBasicInfo} // 创建EMT基础信息
                energyMediumIds={energyMediumIds} // 能源介质ids
                selectedOuIds={selectedOuIds} // 所选ouIds
                processType={processType} // 所选节点类型
                processForm={processForm} // 整个表单
                createdProcesses={createdProcesses}
              />
            )}
          </Col>
        </Row>
        <Space style={{ zIndex: 99, marginTop: 12 }}>
          <Button
            type="primary"
            onClick={() => {
              if (isNew) {
                onProcessFormFinish(remove, field.name);
              } else {
                // 校验输入名称重复项
                let entryRes = checkFormRepeatName({
                  form: processForm,
                  namePath: ['addProcess', field.name, 'processEntryCreateRequests'],
                  errorName: '入口名称重复！',
                });

                if (!entryRes) {
                  message.error('入口名称重复！');
                  setTabsActiveKey?.('1');
                }

                if (!entryRes) return;
                // 校验输出名称重复项
                let existRes = checkFormRepeatName({
                  form: processForm,
                  namePath: ['addProcess', field.name, 'processExitCreateRequests'],
                  errorName: '出口名称重复！',
                });

                if (!existRes) {
                  message.error('出口名称重复！');
                  setTabsActiveKey?.('3');
                }

                if (!existRes) return;
                onProcessFormFinish?.(remove, field.name);
              }
            }}
          >
            保存
          </Button>

          <Button
            onClick={() => {
              if (createdProcesses?.find(i => i.isEdit)) {
                setCreatedProcesses?.(list => {
                  return list.map(item => {
                    return {
                      ...item,
                      isEdit: false,
                    };
                  });
                });
                processForm?.resetFields();
              }
              remove(field.name);
              initState();
            }}
          >
            取消
          </Button>
        </Space>
      </FormContent>
    </Card>
  );
};

export default EditItemInfo;
