import styles from './index.module.scss';
import centerIcon from '../icon/center@2x.png';
import loadingPic from '../icon/loading@2x.png';
import finishedIcon from '../icon/check-circle@2x.png';
import doubleRight from '../icon/doubleRight@2x.png';
import timeIcon from '../icon/time@2x.png';
import React, { useEffect, useState } from 'react';
import Detail from './Detail';
import {
  apiV2ProductSheetPredictiveEnergyPost,
  V2ProductSheetPredictiveEnergyPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Empty } from '@maxtropy/components';

interface Props {
  sideWorkCenterId: string;
  currentKey?: number;
  setCurrentKey: (v: number) => void;
}

const StatusDisplayName: Record<number, string> = {
  0: '未生产',
  1: '已完工',
  2: '正在生产',
};

const SiderContent: React.FC<Props> = props => {
  const { sideWorkCenterId, currentKey, setCurrentKey } = props;
  const [detailOpen, setDetailOpen] = useState<boolean>(true);

  const [detail, setDetail] = useState<V2ProductSheetPredictiveEnergyPostResponse>();
  const [productId, setProductId] = useState<string>();

  // 根据工作中心获取侧边栏数据
  useEffect(() => {
    if (!(sideWorkCenterId === undefined)) {
      apiV2ProductSheetPredictiveEnergyPost({ id: sideWorkCenterId }).then(setDetail);
    }
  }, [sideWorkCenterId]);

  // 点击侧边卡片 查看该生产单详情
  const clickEveryCard = (id: string, itemKey: number) => {
    setDetailOpen(false);
    setProductId(id);
    setCurrentKey(itemKey);
  };
  const clickEveryCards = (itemKey: number) => {
    setCurrentKey(itemKey);
  };

  return (
    <>
      <div className={styles.layout}>
        {detailOpen ? (
          <div>
            <div className={styles.title}>
              <span>
                <img className={styles.centerIconStyle} src={centerIcon} alt="" />
              </span>
              <span>{detail?.workCenterName !== null || undefined ? detail?.workCenterName : '--'}</span>
            </div>
            <div className={styles.topCard}>
              <p>{detail?.predictiveValue !== null || undefined ? detail?.predictiveValue?.toFixed(2) : '--'}</p>
              <p>预计未来24小时用能 (MJ)</p>
            </div>
            <div className={styles.contentCard}>
              {detail?.productSheets ? (
                detail?.productSheets?.map(item => {
                  const itemKey = Number(sideWorkCenterId!) + item.id!;
                  return (
                    <div
                      key={itemKey}
                      className={`${styles.everyCard} ${currentKey === itemKey ? styles.everyCardCheck : ''}`}
                      onClick={() => clickEveryCards(itemKey)}
                    >
                      <p className={styles.proStatus}>
                        <span title={item.code} className={styles.proLength}>
                          {item.code}
                        </span>
                        <span className={styles.productStateBox}>
                          <span>
                            <img
                              className={`${styles.centerIconStyle} ${item.productSheetState === 2 ? styles.ani : ''}`}
                              src={
                                item.productSheetState === 0
                                  ? timeIcon
                                  : item.productSheetState === 1
                                  ? finishedIcon
                                  : loadingPic
                              }
                              alt=""
                            />
                          </span>
                          <span
                            className={styles.InPro}
                            style={
                              item.productSheetState === 0
                                ? { color: 'rgba(255,255,255,0.45)' }
                                : item.productSheetState === 1
                                ? { color: '#49AA19' }
                                : { color: '#4A90E2' }
                            }
                          >
                            {StatusDisplayName[item.productSheetState!]}
                          </span>
                        </span>
                      </p>
                      <p className={styles.rightIcon}>
                        <span title={item.outputProductCode} className={styles.proLength}>
                          {item.outputProductCode}
                        </span>
                        <span className={styles.doubleRightPosition}>
                          <img src={doubleRight} alt="" onClick={() => clickEveryCard(String(item.id), itemKey)} />
                        </span>
                      </p>
                      <div className={styles.proPlan}>
                        <div>
                          <p>计划生产数量</p>
                          <p>{item.projectProductNum}</p>
                        </div>
                        <div className={styles.leftLine}>
                          <div></div>
                          <div>
                            <p>投入</p>
                            <p>{item.inputNum}</p>
                          </div>
                        </div>
                        <div className={styles.leftLine}>
                          <div></div>
                          <div>
                            <p>产出</p>
                            <p>{item.outputNum}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty style={{ padding: '36px 0' }} description="暂无数据" />
              )}
            </div>
          </div>
        ) : (
          <Detail setDetailOpen={setDetailOpen} id={productId as string} />
        )}
      </div>
    </>
  );
};

export default SiderContent;
