import { RestType } from '@/api/shiftCalendar';
import {
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Select,
  Input,
  Radio,
  Checkbox,
  PopConfirm,
  message,
  DatePicker,
  Modal,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { Button as AntdButton, Form } from 'antd';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ShiftTag from '../ShiftTag';
import TimeGrid from '../TimeGrid';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import DragEditCalendar from './components/DragEditCalendar';
import {
  apiV2WorkShiftCalendarDetailPost,
  apiV2WorkShiftCalendarUpdatePost,
  apiV2WorkShiftListPost,
} from '@maxtropy/device-customer-apis-v2';
import {
  ColorItem,
  IsWorkTypeDisplay,
  ShiftCalendarItem,
  ShiftInfo,
  colorsItems,
  createRangeTimeStr,
  formateRestKey,
  formateRestType,
  hasExsitTime,
  isOver24Hours,
  options,
} from '../util';
const cx = classnames.bind(styles);

const EditShiftOp: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  const colors = useRef<ColorItem[]>([...colorsItems]);
  const [form] = Form.useForm<ShiftCalendarItem>();
  const [unshiftList, setUnShiftList] = useState<ShiftInfo[]>([]);
  const [selectShiftList, setSelectShiftList] = useState<any[]>([]);

  const [extraWorkShiftList, setExtraWorkShiftList] = useState<any>([]);

  const { id } = useParams<{ id: string }>();
  const { op } = useParams<{ op: string }>();
  const workShiftCalendarExtras = Form.useWatch('workShiftCalendarExtras', form); // 填报类型

  useEffect(() => {
    if (isNil(workShiftCalendarExtras)) return;
    const updatedWorkShiftCalendarExtras = form.getFieldValue('workShiftCalendarExtras')?.map((item: any) => {
      if (!item?.dateList || isNil(item?.dateList)) {
        return { ...item, dayOffFlag: !isNil(item?.dayOffFlag) ? item.dayOffFlag : 0, dateList: [undefined] };
      }
      return item;
    });
    form.setFieldsValue({ workShiftCalendarExtras: updatedWorkShiftCalendarExtras });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workShiftCalendarExtras]);
  useEffect(() => {
    if ((id && op === 'edit') || (id && op === 'view')) {
      // 编辑
      Promise.all([apiV2WorkShiftListPost({}), apiV2WorkShiftCalendarDetailPost({ id: Number(id) })]).then(
        ([list, detail]) => {
          let key = (formateRestKey as Record<string, string>)[detail.restType!];
          detail?.workShiftCalendarExtras?.forEach((item: any) => {
            item.dateList?.forEach((date: any) => {
              date.startDate = dayjs(date.startDate);
              date.endDate = dayjs(date.endDate);
            });
          });
          form.setFieldsValue({
            name: detail.name,
            restType: detail.restType,
            extra: {
              [key]: detail.restSeg?.split(',')!,
            },
            workShiftCalendarExtras: detail.workShiftCalendarExtras,
          });

          if (detail.workShiftCalendarExtras) {
            const workShiftCalendarExtras = detail.workShiftCalendarExtras?.map(i => i.workShifts);
            setExtraWorkShiftList(
              workShiftCalendarExtras.map(item => {
                return item?.map((subItem: any) => {
                  let tempColors = colors.current[0];
                  colors.current.shift();
                  return {
                    ...subItem,
                    colors: tempColors,
                  };
                });
              })
            );
          }
          let hasSelectIds: any[] = [];
          if (detail.workShifts) {
            hasSelectIds = detail.workShifts.map(item => item.id);
            setSelectShiftList(
              detail.workShifts?.map(item => {
                let tempColors = colors.current[0];
                colors.current.shift();
                return {
                  ...item,
                  colors: tempColors,
                };
              })
            );
          }
          // 过滤掉已选择的
          let unSelects = (list.list ?? []).filter(item => !hasSelectIds.includes(item.id));
          setUnShiftList([...unSelects]);
        }
      );
      return;
    }
    // 新增
    apiV2WorkShiftListPost({}).then(res => setUnShiftList(res.list ?? []));
  }, [id, form, op]);

  const disabledDate = (currentDate: Dayjs, dateList: any[]) => {
    const today = dayjs();
    // 禁用当前日期之前的日期
    if (currentDate.isBefore(today, 'day')) {
      return true;
    }
    // 禁用已有的日期列表中的日期
    if (!isNil(dateList[0]?.startDate) && !isNil(dateList[0]?.endDate)) {
      const disabledDates = dateList?.map(dateItem => {
        const startDate = dayjs(dateItem?.startDate);
        const endDate = dayjs(dateItem?.endDate);
        return startDate?.isSameOrBefore(currentDate, 'day') && endDate?.isSameOrAfter(currentDate, 'day');
      });
      if (!isNil(disabledDates) && disabledDates.includes(true)) {
        return true;
      }
    }
    return false;
  };

  const isDateListValid = (dateList: any) => {
    const existingDateRanges: any = [];

    for (const dateItem of dateList) {
      const startDate = new Date(dateItem.startDate).getTime();
      const endDate = new Date(dateItem.endDate).getTime();

      // 检查日期是否重复
      if (existingDateRanges.includes(startDate) || existingDateRanges.includes(endDate)) {
        return false; // 日期重复
      }

      // 检查日期区间是否有交集
      for (const [rangeStart, rangeEnd] of existingDateRanges) {
        if (!(endDate < rangeStart || startDate > rangeEnd)) {
          return false; // 日期区间有交集
        }
      }
      // 将日期范围添加到数组中
      existingDateRanges.push([startDate, endDate]);
    }

    return true;
  };

  const shiftTimes = useMemo(() => {
    return selectShiftList.map(item => item.timeSeg.split(';')).flat();
  }, [selectShiftList]);

  // removeList需要删除，newList为添加，result拖拽的元素信息
  const onResultChange = (removeList: ShiftInfo[], newList: ShiftInfo[], result: any) => {
    let flag = removeList.some(item => {
      if (item.id === +result.draggableId) {
        // 判断时间超过24小时
        if (isOver24Hours(item, shiftTimes)) {
          Modal.warning({
            title: '班次时间跨度超过24小时, 请重新选择',
          });
          return false;
        }
        // 判断时间有没有重叠
        let exist = hasExsitTime(item, shiftTimes);
        if (exist) {
          Modal.warning({
            title: '班次时间段重复, 请重新选择',
          });
          return false;
        }
        // 分配颜色
        let tempColors = colors.current[0];
        colors.current.shift();
        item.colors = tempColors;
        newList.splice(result.destination.index, 0, item);
        // 排序
        newList.sort((a, b) => {
          let aValue = (a.timeSeg as string).split('-')[0];
          let bValue = (b.timeSeg as string).split('-')[0];
          return aValue > bValue ? 1 : -1;
        });
        return true;
      }
      return false;
    });
    if (!flag) return;
    removeList.splice(result.source.index, 1);
    setUnShiftList([...removeList]);
    setSelectShiftList([...newList]);
  };
  const dragEnd = (result: any) => {
    // 最多5个班次
    if (selectShiftList.length >= 5) return;
    let { destination, source } = result;
    if (!destination || !source) return;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'droppableRight') {
        // 拖拽到了右边区域 左边list删除, 右边list增加
        onResultChange(unshiftList, selectShiftList, result);
      }
    }
  };
  //移除右侧已选择的班次标签，同时处理颜色和班次列表的变化。
  const onTagClose = (item: any, index: number) => {
    colors.current.unshift(item.colors);
    unshiftList.push(item);
    unshiftList.sort((a, b) => (a.id as number) - (b.id as number));
    setUnShiftList([...unshiftList]);
    selectShiftList.splice(index, 1);
    setSelectShiftList([...selectShiftList]);
  };

  const getColumns = (remove: any, workShiftCalendarExtrasIndex: number) => {
    return [
      {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        width: 240,
        render: (_: any, record: any, index: number) => {
          return <EllipsisSpan value={index + 1} />;
        },
      },
      {
        title: '起始日期',
        dataIndex: 'startDate',
        key: 'startDate',
        width: 280,
        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item
              name={[index, 'startDate']}
              style={{
                marginBottom: 0,
              }}
              rules={[
                { required: true, message: '请选择开始日期' },
                {
                  validator: (_, value) => {
                    const { getFieldValue } = form;
                    const endDate = getFieldValue([
                      'workShiftCalendarExtras',
                      workShiftCalendarExtrasIndex,
                      'dateList',
                      index,
                      'endDate',
                    ]);

                    if (value && endDate && dayjs(value).isAfter(dayjs(endDate), 'day')) {
                      return Promise.reject('开始日期不能晚于结束日期');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker
                showNow={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) =>
                  disabledDate(
                    currentDate,
                    workShiftCalendarExtras?.flatMap((item: any) => item?.dateList)
                  )
                }
                onChange={(currentDate: dayjs.Dayjs | null) => {
                  if (currentDate !== null) {
                    disabledDate(
                      currentDate,
                      workShiftCalendarExtras?.flatMap((item: any) => item?.dateList)
                    );
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '结束日期',
        width: 280,
        dataIndex: 'endDate',
        key: 'endDate',

        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item
              name={[index, 'endDate']}
              style={{
                marginBottom: 0,
              }}
              rules={[
                { required: true, message: '请选择结束日期' },
                {
                  validator: (_, value) => {
                    const { getFieldValue } = form;
                    const startDate = getFieldValue([
                      'workShiftCalendarExtras',
                      workShiftCalendarExtrasIndex,
                      'dateList',
                      index,
                      'startDate',
                    ]);
                    if (value && startDate && dayjs(value).isBefore(dayjs(startDate), 'day')) {
                      return Promise.reject('结束日期不能早于开始日期');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker
                showNow={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) =>
                  disabledDate(
                    currentDate,
                    workShiftCalendarExtras?.flatMap((item: any) => item?.dateList)
                  )
                }
                onChange={(currentDate: dayjs.Dayjs | null) => {
                  if (currentDate !== null) {
                    disabledDate(
                      currentDate,
                      workShiftCalendarExtras?.flatMap((item: any) => item?.dateList)
                    );
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: '操作',
        key: 'option',
        render: (_: any, record: any, index: number) => (
          <PopConfirm
            title="确认删除此记录?"
            onConfirm={() => {
              remove(index);
            }}
          >
            <Button type="link">删除</Button>
          </PopConfirm>
        ),
      },
    ];
  };

  const onDataFromChild = (data: any, FieldIndex: number) => {
    form.validateFields().then((res: any) => {
      const updatedFormList = res.workShiftCalendarExtras.map((item: any, index: any) => {
        if (index === FieldIndex) {
          // 在这里更新当前 formList 的 workShiftIds
          return { ...item, workShiftIds: data };
        }
        return item;
      });
    });
  };

  const confirmBtn = () => {
    form.validateFields().then(res => {
      let key = (formateRestKey as Record<string, string>)[res.restType!];
      let workShiftIds = selectShiftList.map(item => item.id);

      if (isNil(workShiftIds) || workShiftIds.length === 0) {
        message.error('工作日班次不能为空');
        return;
      }
      if (!isNil(res.workShiftCalendarExtras)) {
        const isValid = res.workShiftCalendarExtras.every((item: any) => item.dateList && item.dateList.length > 0);
        if (!isValid) {
          message.error('日期列表最少为1条');
          return;
        }
        const isWorkShiftIds = res.workShiftCalendarExtras.every(
          (item: any) => item.dayOffFlag === 1 || (item.workShiftIds && item.workShiftIds.length > 0)
        );
        if (!isWorkShiftIds) {
          message.error('关联班次不能为空');
          return;
        }
        const isWorkShiftCalendarExtrasValid = res.workShiftCalendarExtras.every((item: any) =>
          isDateListValid(item.dateList)
        );
        if (!isWorkShiftCalendarExtrasValid) {
          message.error('日期不能重复');
          return;
        }

        const timestampDateList = res.workShiftCalendarExtras.map((item: any) => {
          let dateList = item.dateList.map((dateItem: any) => {
            let startDate = dayjs(dateItem.startDate).format('YYYY-MM-DD');
            let endDate = dayjs(dateItem.endDate).format('YYYY-MM-DD');
            return {
              startDate,
              endDate,
            };
          });

          return {
            workShiftIds: item.dayOffFlag === 0 ? item.workShiftIds : [],
            typeName: item.typeName,
            dateList,
            dayOffFlag: item.dayOffFlag,
          };
        });
        let params = {
          name: !isNil(res.name) ? res.name?.trim() : '',
          restType: !isNil(res.restType) ? (res.restType as 1 | 2) : 1,
          restSeg: isNil(res.extra?.[key]) ? '' : res.extra?.[key]?.toString(),
          workShiftIds,
          workShiftCalendarExtras: timestampDateList,
          workShiftCalendarId: !isNil(id) ? Number(id) : 0,
        };

        if (id) {
          apiV2WorkShiftCalendarUpdatePost(params).then(() => {
            navigate(`/energy/teamShift/shiftCalendar`);
          });
          return;
        }
      }

      let params = {
        name: !isNil(res.name) ? res.name?.trim() : '',
        restType: !isNil(res.restType) ? (res.restType as 1 | 2) : 1,
        restSeg: isNil(res.extra?.[key]) ? '' : res.extra?.[key]?.toString(),
        workShiftIds,
        workShiftCalendarExtras: [],
        workShiftCalendarId: !isNil(id) ? Number(id) : 0,
      };

      if (id) {
        apiV2WorkShiftCalendarUpdatePost(params).then(() => {
          navigate(`/energy/teamShift/shiftCalendar`);
        });
        return;
      }
    });
  };
  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className="page_wrapper">
        <FormTitle title={'编辑班次日历'} />
        <SubContent>
          <div className={styles.outer}>
            <div className={styles.form_content}>
              <Form
                form={form}
                initialValues={{
                  restType: RestType.WEEK,
                }}
                labelAlign="left"
                labelCol={{ style: { width: 85, marginRight: 50 } }}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="班次日历"
                      rules={[
                        { required: true, message: '' },
                        {
                          validator: async (_, value) => {
                            if (!value || !value.trim()) {
                              throw new Error('班次日历不能为空');
                            }
                            let regEn = /[\\<>*?:|"/]+/im;
                            if (regEn.test(value)) {
                              throw new Error('班次日历不能包含\\、/、:、*、?、"、<、>、|字符');
                            }
                          },
                        },
                        { max: 15, message: '最多输入15个字符！' },
                      ]}
                    >
                      <Input placeholder="请输入名称" style={{ width: 418 }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.workDay}>工作日</Col>
                </Row>
                <Row>
                  <Form.Item name="restType" label="休息日设置">
                    <>
                      <Radio.Group>
                        <Radio value={RestType.WEEK}>{formateRestType[RestType.WEEK]}</Radio>
                        <Radio value={RestType.MONTH}>{formateRestType[RestType.MONTH]}</Radio>
                      </Radio.Group>
                    </>
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item noStyle dependencies={['restType']}>
                      {({ getFieldValue }) => {
                        let key = getFieldValue('restType') as RestType;
                        let ops = options[key];
                        let formName = formateRestKey[key];
                        return (
                          <>
                            <Form.Item
                              name={['extra', formName]}
                              colon={false}
                              label={<span style={{ opacity: 0 }}>none</span>}
                            >
                              <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                  {form.getFieldValue('restType') === RestType.MONTH
                                    ? ops?.map(item => (
                                        <Col span={4} key={item.value}>
                                          <Checkbox value={item.value}>{item.label}</Checkbox>
                                        </Col>
                                      ))
                                    : ops?.map(item => <Checkbox value={item.value}>{item.label}</Checkbox>)}
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>

                <>
                  <div className={styles.drag_area}>
                    <div className={styles.title}>
                      <span>工作日班次:</span>
                      <span className={styles.title_tips}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                        &nbsp;&nbsp;可拖动左侧列表中需要的班次至右侧时间轴，完成班次配置
                      </span>
                    </div>
                    <DragDropContext onDragEnd={dragEnd}>
                      <div className={styles.drag_box}>
                        <Droppable droppableId="droppableLeft">
                          {droppableProvidedLeft => (
                            <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                              {unshiftList.map((item, index) => (
                                <Draggable
                                  isDragDisabled={selectShiftList.length >= 5}
                                  key={'drag' + item.id}
                                  draggableId={`${item.id}`}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                                      >
                                        {item.name}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {droppableProvidedLeft.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <Droppable droppableId="droppableRight" direction="horizontal">
                          {droppableProvidedRight => (
                            <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                              <div className={styles.drag_right_top}>
                                <div>
                                  <Space size={[5, 8]} wrap>
                                    {selectShiftList.map((item, index) => (
                                      <Draggable
                                        isDragDisabled
                                        key={'drop' + item.id}
                                        draggableId={`${item.id}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <ShiftTag
                                                bg={item?.colors?.bg}
                                                borderBg={item?.colors?.border}
                                                onClose={() => onTagClose(item, index)}
                                              >
                                                {item.name + createRangeTimeStr(item.timeSeg)}
                                              </ShiftTag>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                  </Space>
                                  {droppableProvidedRight.placeholder}
                                </div>
                              </div>
                              <div className={styles.drag_right_bottom}>
                                <TimeGrid timeArr={selectShiftList} interDay />
                              </div>
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </DragDropContext>
                  </div>
                  <div className={styles.divider}></div>
                  <Form.List name="workShiftCalendarExtras">
                    {(fields, { add, remove }) => (
                      <>
                        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                          {fields.map((field, index) => {
                            return (
                              <Form.Item key={'workShiftCalendarExtras' + index} style={{ marginBottom: 0 }}>
                                <Form.Item
                                  name={[field.name, 'typeName']}
                                  className={styles.shiftType}
                                  rules={[
                                    { required: true, message: '' },
                                    { max: 10, message: '最多输入10个字符！' },
                                    {
                                      validator: async (_, value) => {
                                        if (!value || !value.trim()) {
                                          throw new Error('班次类型不能为空');
                                        }
                                        let regEn = /[\\<>*?:|"/]+/im;
                                        if (regEn.test(value)) {
                                          throw new Error('班次日历不能包含\\、/、:、*、?、"、<、>、|字符');
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="输入班次类型" className={styles.shiftTypeInput} />
                                </Form.Item>
                                <PopConfirm
                                  title={`确认删除该页卡?`}
                                  onConfirm={() => {
                                    remove(field.name);
                                    const abc = extraWorkShiftList.map((arr: any, i: number) => {
                                      return i === index ? [] : arr;
                                    });
                                    setExtraWorkShiftList(abc.filter((arr: any) => arr.length > 0));
                                  }}
                                >
                                  <AntdButton className={styles.deleteItems}>删除</AntdButton>
                                </PopConfirm>

                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, width: '100%' }}>
                                  <Space key={field.key} direction="vertical" style={{ width: '100%' }}>
                                    <Form.List name={[field.name, 'dateList']}>
                                      {(tableFields, { add, remove }) => {
                                        return (
                                          <>
                                            <Table
                                              dataSource={tableFields}
                                              columns={getColumns(remove, index)}
                                              pagination={false}
                                              scroll={{ y: 300 }}
                                            />
                                            <AntdButton
                                              onClick={() => add()}
                                              type="dashed"
                                              icon={<PlusOutlined />}
                                              className={styles.addRowBtn}
                                            >
                                              新增日期
                                            </AntdButton>
                                          </>
                                        );
                                      }}
                                    </Form.List>
                                  </Space>
                                </div>
                                <Row>
                                  <Col span={24}>
                                    <Form.Item name={[field.name, 'dayOffFlag']} label="是否休息日">
                                      <Select
                                        style={{ width: 418 }}
                                        options={Object.entries(IsWorkTypeDisplay).map(([k, v]) => ({
                                          label: v,
                                          value: Number(k),
                                        }))}
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues[field.name]?.dayOffFlag !== currentValues[field.name]?.dayOffFlag
                                  }
                                >
                                  {() => {
                                    return form.getFieldsValue().workShiftCalendarExtras[index]?.dayOffFlag === 0 ? (
                                      <>
                                        <div className={styles.title}>
                                          <span>班次:</span>
                                          <span className={styles.title_tips}>
                                            <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                                            &nbsp;可拖动左侧列表中需要的班次至右侧时间轴，完成班次配置
                                          </span>
                                        </div>
                                        <Form.Item name={[field.name, 'workShiftIds']}>
                                          <DragEditCalendar
                                            FieldIndex={index}
                                            key={index}
                                            extraWorkShiftList={extraWorkShiftList}
                                            setExtraWorkShiftList={setExtraWorkShiftList}
                                            onDataFromChild={onDataFromChild}
                                          ></DragEditCalendar>
                                        </Form.Item>
                                      </>
                                    ) : null;
                                  }}
                                </Form.Item>
                                <div className={styles.listDivider}></div>
                              </Form.Item>
                            );
                          })}
                        </div>
                        <div className={styles.slot}></div>
                        <Button
                          type="primary"
                          onClick={() => {
                            add();
                          }}
                        >
                          新增班次类型
                        </Button>
                      </>
                    )}
                  </Form.List>
                </>
                {/* ) : null} */}
              </Form>
            </div>
          </div>
        </SubContent>
        {op !== 'view' && (
          <Space className="sticky-footer" size={8} style={{ paddingLeft: 32 }}>
            <Button type="primary" onClick={confirmBtn}>
              确定
            </Button>
            <Button
              onClick={() => {
                navigate(`/energy/teamShift/shiftCalendar`);
              }}
            >
              取消
            </Button>
          </Space>
        )}
        {op === 'view' && (
          <Space className="sticky-footer" size={8} style={{ paddingLeft: 32 }}>
            <Button type="primary" onClick={confirmBtn}>
              确定
            </Button>
            <Button
              onClick={() => {
                navigate(`/energy/teamShift/shiftCalendar/detail/${id}/view`);
              }}
            >
              取消
            </Button>
          </Space>
        )}
      </Wrapper>
    </>
  );
};
export default EditShiftOp;
