import { fetch } from '@maxtropy/components';
//单位性质
export enum UnitNature {
  NATIONAL_ADMINISTRATIVE_ENTERPRISE = 200,
  PUBLIC_PRIVATE_PARTNERSHIP,
  JOINT_VENTURE,
  SOCIAL_ORGANIZATION,
  INTERNATIONAL_ORGANIZATION,
  FOREIGN_ENTERPRISE,
  PRIVATE_ENTERPRISE,
  COLLECTIVE_ENTERPRISE,
  NATIONAL_DEFENSE_MILITARY_ENTERPRISE,
}

export const UnitNatureFormat = {
  [UnitNature.NATIONAL_ADMINISTRATIVE_ENTERPRISE]: '国家行政企业',
  [UnitNature.PUBLIC_PRIVATE_PARTNERSHIP]: '公私合作企业',
  [UnitNature.JOINT_VENTURE]: '中外合资企业',
  [UnitNature.SOCIAL_ORGANIZATION]: '社会组织机构',
  [UnitNature.INTERNATIONAL_ORGANIZATION]: '国际组织机构',
  [UnitNature.FOREIGN_ENTERPRISE]: '外资企业',
  [UnitNature.PRIVATE_ENTERPRISE]: '私营企业',
  [UnitNature.COLLECTIVE_ENTERPRISE]: '集体企业',
  [UnitNature.NATIONAL_DEFENSE_MILITARY_ENTERPRISE]: '国防军事企业',
};

export interface AreaItem {
  adcode: string;
  name: string;
  center: string;
  level: string;
  citycode: string;
  polyline: string | null;
  districts?: AreaItem[];
}

export function fetchAreaJson() {
  return fetch<AreaItem>(
    `/api/v2/location/getDistrict`,
    {
      method: 'POST',
      body: JSON.stringify({ subDistrict: 3 }),
    },
    true
  );
  // return Promise.resolve(fetch(`/api/v2/location/district?subdistrict=3`).then(response => response.json()));
}

export function formatDistrictData(data?: AreaItem[], filterKey?: string): AreaItem[] {
  if (!data) return [];
  return data
    .filter(item => item.adcode !== filterKey)
    .map(item => ({
      ...item,
      districts: item.districts ? formatDistrictData(item.districts, item.adcode) : undefined,
    }));
}
