import { EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import React, { useCallback } from 'react';
import { OuStaffType, OuStaffTypeDisplay, PageResponse } from '../../const';
import { useQuery } from '../../utils/utils';
import CommonMemberSelect from './CommonMemberSelect';
import { CommonUserInfo } from './interface';

export interface MemberSelectProps {
  value?: CommonUserInfo[];
  onChange?: (value: CommonUserInfo[]) => void;
  userDataSource: (...args: any[]) => Promise<PageResponse<CommonUserInfo>>;
  disabled?: boolean;
  memberDataSource?: (...args: any[]) => Promise<PageResponse<CommonUserInfo>>;
}

export const columns: ColumnType<CommonUserInfo>[] = [
  {
    title: '用户编号',
    dataIndex: 'humanCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '用户登录账号',
    dataIndex: 'username',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '管理员/成员',
    dataIndex: 'staffType',
    ellipsis: { showTitle: true },
    render: (v: OuStaffType) => <EllipsisSpan value={OuStaffTypeDisplay[v]} />,
  },
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const MemberSelect: React.FC<MemberSelectProps> = props => {
  const { disabled, ...rest } = props;
  return <>{disabled ? <DisabledMemberSelect {...rest} /> : <CommonMemberSelect {...rest} />}</>;
};

const DisabledMemberSelect: React.FC<MemberSelectProps> = props => {
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const { memberDataSource } = props;

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        memberDataSource
          ? memberDataSource({
              page: pageOffset,
              size: pageSize,
            }).then(res => {
              if (res) {
                setTotalCount(res.total);
              }
              return res.list;
            })
          : Promise.resolve([]),
      [pageOffset, pageSize, setTotalCount, memberDataSource]
    )
  );

  return (
    <>
      <Table dataSource={data} columns={columns} rowKey="id" loading={isLoading} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default MemberSelect;
