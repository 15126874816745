import {
  Button,
  Empty,
  FormTitle,
  useBreadcrumbRoutes,
  Wrapper,
  Form,
  Input,
  Radio,
  Tabs,
  Tooltip,
  CustomFilter,
} from '@maxtropy/components';
import { Col, Row, Select, Spin, Pagination } from 'antd';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSize } from 'ahooks';
import VideoPlayer from './components/VideoPlayer';
import styles from './index.module.scss';
import { AccessMode, LayoutType, LayoutTypeNameDisplay, MonitorDeviceType, MonitorDeviceTypeDisplay } from './type';
import { useRequest } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import {
  apiV2SupervisoryAccountDeviceSceneTargetInOu2Post,
  apiV2SupervisoryAccountDeviceVideoPagePost,
} from '@maxtropy/device-customer-apis-v2';
import cls from 'classnames';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import HKLogo from './images/hk-logo@2x.png';
import YSYLogo from './images/ysy-logo@2x.png';
import { InfoCircleOutlined } from '@ant-design/icons';

interface SearchParams {
  supervisoryDeviceName?: string;
  supervisoryTarget?: number | string;
  layout?: LayoutType;
}
const { TabPane } = Tabs;

const IFRAMEHOST = window.location.origin;

const VideoMonitoring: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const hasVideoConfigBtnPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCEDEVICELIST);
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [urlSearchParams] = useSearchParams(); // 记忆
  const url_supervisoryType = urlSearchParams.get('supervisoryType') || undefined;
  const [deviceTabActiveKey, setDeviceTabActiveKey] = useState<string | undefined>(url_supervisoryType);

  const url_layout = urlSearchParams.get('layout') || undefined;
  const url_supervisoryTarget = urlSearchParams.get('supervisoryTarget') || undefined;
  const url_supervisoryDeviceName = urlSearchParams.get('supervisoryDeviceName') || undefined;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    supervisoryDeviceName: url_supervisoryDeviceName ?? undefined,
    supervisoryTarget: url_supervisoryTarget ?? undefined,
    layout: url_layout ? Number(url_layout) : LayoutType.FOUR,
  });
  const navigate = useNavigate();
  const scollRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [videoLoading, setVideoLoading] = useState<boolean>(true);

  const playerBoxRef = useRef<HTMLDivElement>(null);
  const size = useSize(playerBoxRef);

  const spanCol = useMemo(() => {
    return 24 / Math.sqrt(searchParams.layout!);
  }, [searchParams]);
  const pageSize = useMemo(() => {
    return searchParams.layout!;
  }, [searchParams]);

  const hasPVTabPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCELISTPVTAB);
  const hasBsaTabPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCELISTBSATAB);
  const hasDcTabPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCELISTDCTAB);
  const hasPcPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCELISTPCTAB);
  const hasOtherPermission = useHasPermission(PermissionsType.B_VIDEOSURVEILLANCELISTOTHERTAB);

  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountDeviceVideoPagePost({
        supervisoryType: deviceTabActiveKey as unknown as MonitorDeviceType,
        supervisoryDeviceName: searchParams.supervisoryDeviceName,
        supervisoryTarget: searchParams.supervisoryTarget as string,
        page: page,
        size: pageSize,
      });
      setTotal(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [deviceTabActiveKey, searchParams, page, pageSize],
    }
  );

  const list = useMemo(() => {
    if (loading) {
      return [];
    } else {
      return pageList ?? [];
    }
  }, [pageList, loading]);

  const defaultTabKey = useMemo(() => {
    if (hasPVTabPermission) {
      return MonitorDeviceType.PV.toString();
    }
    if (hasBsaTabPermission) {
      return MonitorDeviceType.BSA.toString();
    }
    if (hasDcTabPermission) {
      return MonitorDeviceType.MICROGRIDS.toString();
    }
    if (hasPcPermission) {
      return MonitorDeviceType.NONELECENERGYMED.toString();
    }
    if (hasOtherPermission) {
      return MonitorDeviceType.OTHERS.toString();
    }
    return undefined;
  }, [hasPVTabPermission, hasBsaTabPermission, hasDcTabPermission, hasPcPermission, hasOtherPermission]);

  useEffect(() => {
    setDeviceTabActiveKey(defaultTabKey);
  }, [defaultTabKey]);

  useEffect(() => {
    if (deviceTabActiveKey) {
      setSearchParams({
        ...searchParams,
        supervisoryDeviceName: undefined,
        supervisoryTarget: undefined,
      });
      searchForm.setFieldsValue({
        supervisoryDeviceName: undefined,
        supervisoryTarget: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceTabActiveKey]);

  const { data: monitorTargetList } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountDeviceSceneTargetInOu2Post({
        id: Number(deviceTabActiveKey),
      });
      return res ? res.list : [];
    },
    {
      ready: !!deviceTabActiveKey && deviceTabActiveKey !== MonitorDeviceType.OTHERS.toString(),
      refreshDeps: [deviceTabActiveKey],
    }
  );

  // 监控目标下拉列表
  const targetOptions = useMemo(() => {
    if (monitorTargetList && monitorTargetList.length !== 0) {
      return monitorTargetList.map(i => ({
        label: deviceTabActiveKey === '4' ? `${i.supervisoryTargetName}-${i.tenantInfoName}` : i.supervisoryTargetName,
        value: i.supervisoryTargetId ?? i.supervisoryTargetName,
      }));
    }
  }, [monitorTargetList, deviceTabActiveKey]);

  // 管理监控设备跳转
  const onNaviToMonitorAccountConfig = () => {
    navigate('/monitor/account/configList');
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
  };

  const filters = (
    <CustomFilter onFinish={() => {}} form={searchForm} initialValues={searchParams}>
      {deviceTabActiveKey !== MonitorDeviceType.OTHERS.toString() && (
        <Form.Item name="supervisoryTarget" label="监控目标">
          <Select
            placeholder="请选择"
            onClear={() => {
              onFinish(searchForm.getFieldsValue());
            }}
            onKeyDown={e => {
              if (e.code === 'Enter') {
                onFinish(searchForm.getFieldsValue());
              }
            }}
            onBlur={() => {
              onFinish(searchForm.getFieldsValue());
            }}
            options={targetOptions}
            showSearch
            optionFilterProp="label"
            allowClear
          />
        </Form.Item>
      )}
      <Form.Item name="supervisoryDeviceName" label="监控设备名称">
        <Input
          onKeyDown={e => {
            if (e.code === 'Enter') {
              onFinish(searchForm.getFieldsValue());
            }
          }}
          onBlur={() => {
            onFinish(searchForm.getFieldsValue());
          }}
          placeholder="请输入监控设备名称"
        />
      </Form.Item>
      <Form.Item name="layout" label="布局">
        <Radio.Group
          onChange={e => {
            setSearchParams({
              ...searchParams,
              layout: e.target.value,
            });
            setPage(1);
          }}
          options={Object.entries(LayoutTypeNameDisplay).map(([k, v]) => ({
            label: <Tooltip title={v}>{v}</Tooltip>,
            value: Number(k),
          }))}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
    </CustomFilter>
  );

  const navigateToDetail = (id: number) => {
    navigate(
      `/monitor/video/list/detail/${id}?${qs.stringify(
        {
          ...searchParams,
          supervisoryType: deviceTabActiveKey,
        },
        { indices: false }
      )}`
    );
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={cls('video_page_box', 'page_wrapper')}>
      <FormTitle
        title={
          <>
            <span>视频监控列表</span>
            <Tooltip title="通过海康摄像头方式接入的视频需在摄像头所在局域网下观看">
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginLeft: '4px', fontSize: ' 14px' }} />
            </Tooltip>
          </>
        }
        extraContent={
          hasVideoConfigBtnPermission && (
            <Button type="primary" onClick={() => onNaviToMonitorAccountConfig()}>
              管理监控设备
            </Button>
          )
        }
      ></FormTitle>

      {defaultTabKey ? (
        <>
          {/* todo: tabs下面的横线被全局样式影响了 */}
          <div>
            <Tabs onChange={(v: string) => setDeviceTabActiveKey(v)} activeKey={deviceTabActiveKey}>
              {hasPVTabPermission && (
                <TabPane tab={MonitorDeviceTypeDisplay[MonitorDeviceType.PV]} key={MonitorDeviceType.PV} />
              )}
              {hasBsaTabPermission && (
                <TabPane tab={MonitorDeviceTypeDisplay[MonitorDeviceType.BSA]} key={MonitorDeviceType.BSA} />
              )}
              {hasDcTabPermission && (
                <TabPane
                  tab={MonitorDeviceTypeDisplay[MonitorDeviceType.MICROGRIDS]}
                  key={MonitorDeviceType.MICROGRIDS}
                />
              )}
              {hasPcPermission && (
                <TabPane
                  tab={MonitorDeviceTypeDisplay[MonitorDeviceType.NONELECENERGYMED]}
                  key={MonitorDeviceType.NONELECENERGYMED}
                />
              )}
              {hasOtherPermission && (
                <TabPane tab={MonitorDeviceTypeDisplay[MonitorDeviceType.OTHERS]} key={MonitorDeviceType.OTHERS} />
              )}
            </Tabs>
          </div>
          <div className={styles.pageFilter}>{filters}</div>
          <Spin spinning={loading}>
            <div className={styles.video_box_outer}>
              <div className={styles.video_box_inner} ref={scollRef}>
                {!list || list.length === 0 ? (
                  <div className={styles.noData}>
                    <Empty />
                  </div>
                ) : (
                  <Row gutter={[16, 16]}>
                    {list.map((item, index) => {
                      return (
                        <Col span={spanCol} key={index} onClick={() => navigateToDetail(item.id!)}>
                          <div className={styles.viedoMode}>
                            {item.accessMode === AccessMode.HK ? (
                              <>
                                <img src={HKLogo} width={24} />
                                海康摄像头
                              </>
                            ) : (
                              <>
                                <img src={YSYLogo} width={12} />
                                萤石云
                              </>
                            )}
                          </div>
                          {item.accessMode === AccessMode.HK ? (
                            <div ref={playerBoxRef}>
                              <Spin spinning={videoLoading}>
                                <iframe
                                  src={`${IFRAMEHOST}/monitor/video/hkPlayer`}
                                  frameBorder="0"
                                  width={size?.width!}
                                  height={parseInt(String((size?.width! / 600) * 400)) + 40}
                                  id={`hk_video_${item.id}`}
                                  onClick={() => navigateToDetail(item.id!)}
                                  onLoad={() => {
                                    setVideoLoading(false);
                                    const hkVideoFrame = document.getElementById(
                                      `hk_video_${item.id}`
                                    ) as HTMLIFrameElement;

                                    hkVideoFrame?.contentWindow?.postMessage(
                                      {
                                        domId: `hk_video_${item.id}`,
                                        width: size?.width!,
                                        height: parseInt(String((size?.width! / 600) * 400)),
                                        monitorLocation: item.monitorLocation!,
                                        cameraUsername: item.cameraUsername!,
                                        cameraPassword: item.cameraPassword!,
                                        ipAddress: item.ipAddress!,
                                        port: item.port!,
                                        cameraPort: item.cameraPort!,
                                        rtspPort: item.rtspPort!,
                                        showFooter: true,
                                        leftText: item.supervisoryDeviceName,
                                        rightText: item.supervisoryTarget,
                                      },
                                      '*'
                                    );

                                    if (hkVideoFrame.contentDocument) {
                                      hkVideoFrame.contentDocument.body.style.minWidth = 'auto';
                                      hkVideoFrame.contentDocument?.body.addEventListener('click', () => {
                                        navigateToDetail(item.id!);
                                      });
                                    }
                                  }}
                                />
                              </Spin>
                            </div>
                          ) : (
                            <VideoPlayer
                              width="100%"
                              domId={`video${item.id}`}
                              leftText={item.supervisoryDeviceName}
                              rightText={item.supervisoryTarget}
                              videoToken={item.token!}
                              videoUrl={item.supervisoryUrl!}
                            />
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            </div>
          </Spin>
          <div style={{ padding: '10px 0' }}></div>
          <Row justify="end" align="middle">
            {total > 0 && (
              <>
                <span style={{ paddingRight: 10 }}>共{total}条</span>
                <Pagination
                  current={page}
                  pageSize={pageSize}
                  defaultCurrent={1}
                  total={total}
                  onChange={page => {
                    setPage(page);
                  }}
                />
              </>
            )}
          </Row>
        </>
      ) : (
        <Empty style={{ margin: 'auto' }} description={'无场景权限'} />
      )}
    </Wrapper>
  );
};

export default VideoMonitoring;
