import {
  EnergyMediumSceneIds,
  EnergyMediumSceneIdsColorDisplay,
  MediumIndicatorStatisticsReq,
} from '@/api/energyMedium';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { energyMediumBtns, ParamsOption } from '../../config';
import { StatisticsPartition } from '../DateSwitch';

interface ChartOptionProps {
  unit?: string;
  query: MediumIndicatorStatisticsReq;
}

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};
export type FormatterCustomItem = {
  name: string;
  value: number;
  marker: string;
  data: any[];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
  dimensionNames: string[];
};
export interface AxisPointerCrossFormat {
  axisDimension: string;
  axisIndex: number;
  seriesData: any[];
  value: number;
}

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

// tooltip 通用配置
export const getTooltip = ({ unit = '', mode }: { unit?: string; mode: StatisticsPartition }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
  formatter(items: FormatterItem[]) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string;
    switch (mode) {
      case StatisticsPartition.MINUTE_1:
      case StatisticsPartition.MINUTE_15:
        str = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
        break;
      case StatisticsPartition.DAY:
        str = dayjs(axisValue, 'x').format('MM-DD');
        break;
      default:
        str = dayjs(axisValue, 'x').format('YYYY-MM');
    }
    items.forEach(item => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
      str += `<br> ${marker}${seriesName}: ${value}`;
    });
    return str;
  },
});

export const rangeCatXAxis = (key: string) => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: key === 'Hour' ? 60 * 1000 : 60 * 1000 * 60 * 24,
      axisLabel: {
        formatter: function (e: number) {
          if (key === 'Hour') {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          } else if (key === 'Day') {
            return dayjs(e, 'x').format('MM-DD');
          } else {
            return dayjs(e, 'x').format('YYYY-MM');
          }
        },
      },
    },
  ];
};

export const getNormalAxis = (query: MediumIndicatorStatisticsReq) => {
  switch (query.timeResolution) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
      return rangeCatXAxis('Hour');
    case StatisticsPartition.DAY:
      return rangeCatXAxis('Day');
    case StatisticsPartition.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

export const getChartOption = ({ query, unit }: ChartOptionProps) => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    tooltip: getTooltip({
      unit,
      mode: query.timeResolution!,
    }),
    xAxis: getNormalAxis(query),
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

interface LegendProps {
  show: boolean;
  indicatorId: number;
  right?: number;
}

export const getLegend = ({ show, indicatorId, right }: LegendProps) => {
  const findName = energyMediumBtns().find(item => item.id === indicatorId);

  return {
    show,
    right: right ?? 20,
    top: 50,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: findName?.btnName,
        icon: 'rect',
      },
    ],
  };
};

// 累积体积流量
export const getAccumulatedVolumetricFlowRateChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
      right: 110,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      {
        type: 'bar',
        color: colors,
        unit,
        timeResolution: query.timeResolution,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};

// 累积质量流量
export const getAccumulatedMassFlowChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
      right: 110,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      {
        type: 'bar',
        color: colors,
        unit,
        timeResolution: query.timeResolution,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};

// 瞬时体积流量
export const getInstantaneousVolumetricFlowRateChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最大瞬时流量', '平均瞬时流量', '最小瞬时流量'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 瞬时质量流量
export const getInstantaneousMassFlowRateChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最大瞬时流量', '平均瞬时流量', '最小瞬时流量'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 压力
export const getPressureChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最大瞬时流量', '平均瞬时流量', '最小瞬时流量'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 温度
export const getTemperatureChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series: [
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.avg];
        }),
      },
    ],
  };
};

// 累积热量
export const getAccumulatedHeatChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
      right: 110,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      {
        type: 'bar',
        color: colors,
        unit,
        timeResolution: query.timeResolution,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};

// 密度
export const getDensityChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series: [
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: colors,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.avg];
        }),
      },
    ],
  };
};

// 电量
export const getElectricityChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
      right: 110,
    }),
    toolbox: {
      width: 200,
      height: 100,
      top: 45,
      right: 42,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      {
        type: 'bar',
        color: colors,
        unit,
        timeResolution: query.timeResolution,
        name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
        data: chartData.data?.map((a, index) => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};
// 功率
export const getPowerChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最大功率', '平均功率', '最小功率'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 绝对湿度
export const getAbsoluteHumidityChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最高湿度', '平均湿度', '最低湿度'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 相对湿度
export const getRelativeHumidityChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最高湿度', '平均湿度', '最低湿度'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 液位
export const getLevelChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最高液位', '平均液位', '最低液位'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 纯度
export const getPurityChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  const option = getChartOption({
    unit,
    query,
  });
  const { indicatorId } = query;
  const colors = ['#1890ff', '#4fc7ec'];

  const totalData = chartData.data?.map((item, index) => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);

  const options =
    query.timeResolution !== StatisticsPartition.DAY
      ? option
      : {
          ...option,
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
            axisPointer: {
              type: 'cross',
              label: {
                formatter: function (params: AxisPointerCrossFormat) {
                  if (params.axisDimension === 'x') {
                    return dayjs(params.value, 'x').format('YYYY-MM-DD');
                  } else {
                    return params.value.toFixed(2);
                  }
                },
              },
            },
          },
        };

  return {
    ...options,
    legend: getLegend({
      show: true,
      indicatorId: indicatorId as number,
    }),
    series:
      query.timeResolution === StatisticsPartition.DAY
        ? [
            {
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              type: 'custom',
              dimensions: ['-', '最高纯度', '平均纯度', '最低纯度'],
              itemStyle: {
                borderWidth: 1.5,
              },
              renderItem: function (params: any, api: any) {
                var xValue = api?.value(0);
                var highPoint = api?.coord([xValue, api.value(1)]);
                var avgPoint = api?.coord([xValue, api.value(2)]);
                var lowPoint = api?.coord([xValue, api.value(3)]);
                // var halfWidth = api?.size([0, 1])[1] / 2;
                var halfWidth = 8;
                var style = api?.style({
                  stroke: api?.visual('color'),
                  fill: undefined,
                });
                return {
                  type: 'group',
                  children: [
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0] - halfWidth,
                        y1: highPoint[1],
                        x2: highPoint[0] + halfWidth,
                        y2: highPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: highPoint[0],
                        y1: highPoint[1],
                        x2: lowPoint[0],
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: avgPoint[0] - halfWidth,
                        y1: avgPoint[1],
                        x2: avgPoint[0] + halfWidth,
                        y2: avgPoint[1],
                      },
                      style: style,
                    },
                    {
                      type: 'line',
                      transition: ['shape'],
                      shape: {
                        x1: lowPoint[0] - halfWidth,
                        y1: lowPoint[1],
                        x2: lowPoint[0] + halfWidth,
                        y2: lowPoint[1],
                      },
                      style: style,
                    },
                  ],
                };
              },
              encode: {
                x: 0,
                y: [1, 2, 3],
                tooltip: [1, 2, 3],
              },
              data: totalData,
            },
          ]
        : [
            {
              type: 'line',
              smooth: true,
              lineStyle: { normal: { width: 2 } },
              color: colors,
              name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
              data: chartData.data?.map((a, index) => {
                return [a.ts, a.avg];
              }),
            },
          ],
  };
};

// 获取过程的数据
export const getProcessChart = ({ chartData = {}, query, unit }: ParamsOption) => {
  if (JSON.stringify(chartData) !== '{}') {
    const option = getChartOption({
      unit,
      query,
    });
    const { sceneId, energyMediumName, indicatorId } = chartData;
    return {
      ...option,
      // title: {
      //   show: true,
      //   text: `${energyMediumName}${DataTypeNameDisplay[indicatorId as DataType]}`,
      //   textStyle: {
      //     color: '#fff',
      //     fontWeight: 700,
      //     fontSize: 16,
      //   },
      //   padding: [10, 10, 0, 20],
      // },
      legend: getLegend({
        show: true,
        indicatorId: indicatorId as number,
        right: 110,
      }),
      toolbox: {
        width: 200,
        height: 100,
        top: 45,
        right: 42,
        itemSize: 20, // 设置图标大小
        feature: {
          brush: {
            type: ['lineX', 'clear'], // 选择工具类型
            title: {
              lineX: '横向选择',
              clear: '清除',
            },
          },
        },
      },
      brush: {
        toolbox: ['lineX', 'clear'],
        xAxisIndex: 0,
      },
      series: [
        {
          type: 'bar',
          color: sceneId
            ? EnergyMediumSceneIdsColorDisplay[sceneId as EnergyMediumSceneIds]
            : EnergyMediumSceneIdsColorDisplay[EnergyMediumSceneIds.ELEC],
          unit,
          timeResolution: query.timeResolution,
          name: energyMediumBtns().find(item => item.id === indicatorId)?.btnName,
          data: chartData.data?.map((a, index) => {
            return [a.ts, a.sum];
          }),
        },
      ],
    };
  }
};
