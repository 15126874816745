import {
  Empty,
  Button,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
  triggerModal,
  FormTitle,
  Input,
  Form,
  Modal,
} from '@maxtropy/components';
import styles from './index.module.scss';
import React, { FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Space, Tree } from 'antd';
import { FormOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';
import cabinetIcon from './imgs/cabinet.svg';
import {
  addCabinet,
  CabinetWithCircuitResponse,
  deleteCabinet,
  editCabinet,
  getCabinetWithCircuitList,
} from '@/api/distributionCabinetManage';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import CircuitList from './components/CircuitList';
const { Search } = Input;

interface CabinetInfo {
  id?: number;
  name?: string;
}

type FunCallback = (cabinet: CabinetInfo) => void;
const IconOps = ({
  name,
  id,
  edit,
  remove,
  showIcon,
  hasEditPermission,
  hasRemovePermission,
}: {
  name: string;
  id: number;
  showIcon: boolean;
  edit?: FunCallback;
  remove?: FunCallback;
  hasEditPermission: boolean;
  hasRemovePermission: boolean;
}) => {
  return (
    <>
      <Space size={10}>
        {name}
        {showIcon && (
          <Space size={10}>
            {hasEditPermission && (
              <FormOutlined onClick={() => edit?.({ id, name })} style={{ color: 'var(--mx-primary-color)' }} />
            )}
            {hasRemovePermission && (
              <MinusCircleOutlined
                onClick={() => {
                  triggerModal({
                    type: 'confirm',
                    props: {
                      title: '确定删除该配电柜？删除后无法恢复',
                      onOk: () => remove?.({ id, name }),
                    },
                  });
                  // Modal.confirm({
                  //   title: '确定删除该配电柜？删除后无法恢复',
                  //   onOk: () => remove?.({ id, name }),
                  // });
                }}
                style={{ color: 'var(--mx-primary-color)' }}
              />
            )}
          </Space>
        )}
      </Space>
    </>
  );
};

const loop = (
  circuitData: CabinetWithCircuitResponse[],
  edit: FunCallback,
  remove: FunCallback,
  showIcon: boolean,
  hasEditPermission: boolean,
  hasRemovePermission: boolean
): DataNode[] => {
  return circuitData.map(item => {
    return {
      title: (
        <IconOps
          hasEditPermission={hasEditPermission}
          hasRemovePermission={hasRemovePermission}
          showIcon={showIcon}
          name={item.name}
          id={item.id}
          edit={edit}
          remove={remove}
        />
      ),
      key: item.id,
      icon: <img src={cabinetIcon} alt="" />,
      // 没有回路了，所以移除
      // children: item.circuitList?.map(cirs => {
      //   return {
      //     title: cirs.name,
      //     icon: <img src={circuitIcon} alt="" />,
      //     key: `${item.id}-${cirs.id}`,
      //   };
      // }),
    };
  });
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const filterCabinetsByCircuitName = (circuitData: CabinetWithCircuitResponse[], name: string) => {
  let data: CabinetWithCircuitResponse[] = [];
  if (circuitData.length === 0) return [];
  circuitData.forEach(item => {
    let temp = item.circuitList.filter(cir => cir.name.toLowerCase().indexOf(name.toLowerCase()) > -1);
    if (temp && temp.length > 0) {
      data.push({
        ...item,
        circuitList: temp,
      });
    }
  });
  return data;
};

const { Sider, Content } = Layout;

const DistributionCabinetManage: FC = () => {
  const routesContext = useBreadcrumbRoutes();
  // 权限
  const hasAddPermission = useHasPermission(PermissionsType.B_CABINET_ADD);
  const hasEditPermission = useHasPermission(PermissionsType.B_CABINET_EDIT);
  const hasDeletePermission = useHasPermission(PermissionsType.B_CABINET_DELETE);
  const [circuitData, setCircuitData] = useState<CabinetWithCircuitResponse[]>();
  const currentCabinet = useRef<CabinetInfo>();
  const [opType, setOpType] = useState<string>('add');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [update, setUpdate] = useUpdate();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const editInitCabinetName = useRef<string>();
  const [selectedDistributionId, setSelectedDistributionId] = useState<Key>(); // 所选配电柜
  // 备份数据搜索时用
  const tempCircuitData = useRef<CabinetWithCircuitResponse[]>();
  const [form] = Form.useForm();

  // 获取配电柜列表
  useEffect(() => {
    getCabinetWithCircuitList().then(res => {
      setCircuitData(res.list ?? []);
      setSelectedDistributionId(res.list?.[0].id);
      tempCircuitData.current = res.list;
    });
  }, [update]);

  // 搜索
  const onSearch = (value: string) => {
    if (value.trim()) {
      let dataList = tempCircuitData.current;
      // 找配电柜
      let filterCabinets = dataList?.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      if (filterCabinets && filterCabinets.length > 0) {
        setCircuitData(filterCabinets);
        return;
      }
      setCircuitData([]); // 现在没有回路了
      // 没找到配电柜, 找出回路
      // let temp = filterCabinetsByCircuitName(dataList!, value);
      // setCircuitData(temp);
      return;
    }
    setCircuitData(tempCircuitData.current);
  };

  // 新建配电柜安按钮
  const addBtn = () => {
    setOpType('add');
    setOpenModal(true);
  };

  // 编辑button
  const switchStatus = () => {
    setShowEditIcon(!showEditIcon);
  };
  // 编辑icon
  const editBtn = (cabinet: CabinetInfo) => {
    setOpType('edit');
    setOpenModal(true);
    form.setFieldsValue({ cabinetName: cabinet.name });
    currentCabinet.current = cabinet;
    editInitCabinetName.current = cabinet.name;
  };
  // 移除icon
  const removeBtn = (cabinet: CabinetInfo) => {
    deleteCabinet(cabinet.id!).then(setUpdate);
  };

  const formateCircuitData = useMemo(() => {
    if (circuitData && circuitData.length > 0) {
      return loop(circuitData, editBtn, removeBtn, showEditIcon, !!hasEditPermission, !!hasDeletePermission);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitData, showEditIcon, hasEditPermission, hasDeletePermission]);

  const clearFormValue = () => form.setFieldsValue({ cabinetName: undefined });

  const modalCancelBtn = () => {
    setOpenModal(false);
    clearFormValue();
  };

  // 当前配电柜名称
  const cabinetNames = useMemo(() => {
    return circuitData?.map(item => item.name);
  }, [circuitData]);

  // 显示弹框
  const showConfimModal = (title: string) => {
    Modal.warning({
      title: title,
      okText: '好的',
    });
  };

  // 弹框确认
  const confirmBtn = async () => {
    let isPass = await form
      .validateFields()
      .then(_ => true)
      .catch(_ => false);
    if (!isPass) return;
    let currentCabinetName = form.getFieldValue('cabinetName');
    // 校验回路名是否重复
    if (opType === 'add') {
      if (cabinetNames?.includes(currentCabinetName)) {
        showConfimModal('配电柜名称重复，请重新输入配电柜名称');
        return;
      }
      addCabinet(currentCabinetName).then(_ => {
        setUpdate();
        setOpenModal(false);
        clearFormValue();
      });
      return;
    }
    if (opType === 'edit') {
      if (currentCabinetName.trim() !== editInitCabinetName.current) {
        if (cabinetNames?.includes(currentCabinetName)) {
          showConfimModal('配电柜名称重复，请重新输入配电柜名称');
          return;
        }
        // api请求
        editCabinet(currentCabinet.current?.id!, currentCabinetName).then(_ => {
          setUpdate();
          setOpenModal(false);
          clearFormValue();
        });
        return;
      }
      // api请求
      editCabinet(currentCabinet.current?.id!, currentCabinetName).then(_ => {
        setUpdate();
        setOpenModal(false);
        clearFormValue();
      });
    }
  };

  const modalTitle = useMemo(() => {
    return opType === 'add' ? '新建配电柜' : '编辑配电柜';
  }, [opType]);

  // 配电柜选择
  const onCabinetSelect = (selectedKeys: Key[], e: any) => {
    setSelectedDistributionId(selectedKeys[0]);
  };

  return (
    <div className={styles.page_outer}>
      <Wrapper routes={routesContext?.routes ?? []}>
        <Layout className={styles.layout}>
          <Sider width={'320px'}>
            <div className={styles.content_left}>
              <FormTitle title="配电柜列表" />

              <div className={styles.searchArea}>
                <Search placeholder="请输入配电柜名称" allowClear onSearch={onSearch} />
              </div>
              <div className={styles.buttons}>
                <Space size={8}>
                  {hasAddPermission && (
                    <Button type="primary" icon={<PlusOutlined />} onClick={addBtn}>
                      新建配电柜
                    </Button>
                  )}
                  {hasEditPermission && formateCircuitData.length > 0 && (
                    <Button type="primary" onClick={switchStatus}>
                      {showEditIcon ? '关闭' : '编辑'}
                    </Button>
                  )}
                </Space>
              </div>
              <div className={styles.tree_outer}>
                <div className={styles.tree_area}>
                  {formateCircuitData.length === 0 ? (
                    <Empty style={{ paddingTop: '50px' }} />
                  ) : (
                    <Tree
                      blockNode
                      showIcon
                      defaultExpandAll
                      selectedKeys={selectedDistributionId ? [selectedDistributionId] : undefined}
                      treeData={formateCircuitData}
                      onSelect={onCabinetSelect}
                    />
                  )}
                </div>
              </div>
            </div>
          </Sider>
          <Content className={styles.content_right}>
            <FormTitle title="回路列表" />

            <CircuitList selectedDistributionId={selectedDistributionId} />
          </Content>
        </Layout>
      </Wrapper>
      <Modal open={openModal} size="small" onCancel={modalCancelBtn} onOk={confirmBtn} title={modalTitle}>
        <Form form={form}>
          <Form.Item label="配电柜名称" rules={[{ required: true }]} name="cabinetName">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DistributionCabinetManage;
