import { DatePicker, Radio } from '@maxtropy/components';
import dayjs from 'dayjs';

export default function StatisticsRangePicker(props: {
  disabledDate: (current: dayjs.Dayjs) => boolean;
  value?: any;
  onCalendarChange: (dates: any) => void;
  onChange: (val: any) => void;
  showTime: boolean;
}) {
  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Radio.Group buttonStyle="solid">
          <Radio.Button
            value="1"
            onClick={() =>
              props.onChange([dayjs().subtract(6, 'day').startOf('day'), dayjs().endOf('day').startOf('hour')])
            }
          >
            近7日
          </Radio.Button>
          <Radio.Button
            value="2"
            onClick={() =>
              props.onChange([dayjs().subtract(30, 'day').startOf('day'), dayjs().endOf('day').startOf('hour')])
            }
          >
            近31日
          </Radio.Button>
          <Radio.Button
            value="3"
            onClick={() =>
              props.onChange([
                dayjs().subtract(1, 'month').startOf('month'),
                dayjs().subtract(1, 'month').endOf('month').startOf('hour'),
              ])
            }
          >
            上月
          </Radio.Button>
        </Radio.Group>
      </div>

      <DatePicker.RangePicker
        value={props.value}
        allowClear={false}
        disabledDate={props.disabledDate}
        onCalendarChange={props.onCalendarChange}
        onChange={props.onChange}
        format={props.showTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
        showTime={
          props.showTime
            ? {
                format: 'HH',
                defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:00', 'HH:mm')],
              }
            : false
        }
      />
    </>
  );
}
