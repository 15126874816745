import { Space } from 'antd';
import classnames from 'classnames/bind';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ShiftTag from '../../ShiftTag';
import TimeGrid from '../../TimeGrid';
import styles from '../index.module.scss';
import { isNil } from 'lodash-es';
import { apiV2WorkShiftListPost } from '@maxtropy/device-customer-apis-v2';
import { Modal } from '@maxtropy/components';
import { ColorItem, ShiftInfo, createRangeTimeStr, hasExsitTime, isOver24Hours } from '../../util';

export const colorsItems: ColorItem[] = [
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
];

const cx = classnames.bind(styles);

interface Iprops {
  field: any;
  FieldIndex: number;
  modalApi?: any;
  value?: any;
  onChange?: (val: any) => void;
  onDataFromChild: (val: any, FieldIndex: any) => void;
}
const DragCreateCalendar: React.FC<Iprops> = ({ field, FieldIndex, modalApi, value, onChange, onDataFromChild }) => {
  const [extraUnshiftList, setExtraUnShiftList] = useState<ShiftInfo[]>([]);
  const colors = useRef<ColorItem[]>([...colorsItems]);
  const [extraSelectShiftList, setExtraSelectShiftList] = useState<any>([]);
  useEffect(() => {
    // 新增
    apiV2WorkShiftListPost({}).then(list => {
      setExtraUnShiftList(list.list ?? []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let workShiftIds = extraSelectShiftList.map((item: any) => item.id);
    onDataFromChild(workShiftIds, FieldIndex);
    if (!isNil(workShiftIds) && !isNil(onChange)) {
      onChange(workShiftIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraSelectShiftList]);

  const extraDragEnd = (result: any) => {
    // 最多5个班次
    if (extraSelectShiftList.length >= 5) return;
    let { destination, source } = result;
    if (!destination || !source) return;
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'droppableRight') {
        // 拖拽到了右边区域 左边list删除, 右边list增加
        onResultChange(extraUnshiftList, extraSelectShiftList, result);
      }
    }
  };

  const shiftTimes = useMemo(() => {
    return extraSelectShiftList.map((item: any) => item.timeSeg.split(';')).flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraSelectShiftList]);

  const onResultChange = (removeList: ShiftInfo[], newList: ShiftInfo[], result: any) => {
    let flag = removeList.some(item => {
      if (item.id === +result.draggableId) {
        // 判断时间超过24小时
        if (isOver24Hours(item, shiftTimes)) {
          Modal.warning({
            title: '班次时间跨度超过24小时, 请重新选择',
          });
          return false;
        }
        // 判断时间有没有重叠
        let exist = hasExsitTime(item, shiftTimes);
        if (exist) {
          Modal.warning({
            title: '班次时间段重复, 请重新选择',
          });
          return false;
        }
        // 分配颜色
        let tempColors = colors.current[0];
        colors.current.shift();
        item.colors = tempColors;
        newList.splice(result.destination.index, 0, item);
        // 排序
        newList.sort((a, b) => {
          let aValue = (a.timeSeg as string).split('-')[0];
          let bValue = (b.timeSeg as string).split('-')[0];
          return aValue > bValue ? 1 : -1;
        });
        return true;
      }
      return false;
    });
    if (!flag) return;
    removeList.splice(result.source.index, 1);
    setExtraUnShiftList([...removeList]);
    setExtraSelectShiftList([...newList]);
  };

  const onExtraTagClose = (item: any, dragindex: number, index: number) => {
    colors.current.unshift(item.colors);
    extraUnshiftList.push(item);
    extraUnshiftList.sort((a: any, b: any) => a.id - b.id);
    setExtraUnShiftList([...extraUnshiftList]);
    extraSelectShiftList.splice(dragindex, 1);
    setExtraSelectShiftList([...extraSelectShiftList]);
  };

  return (
    <>
      <DragDropContext onDragEnd={extraDragEnd}>
        <div className={styles.drag_box}>
          <Droppable droppableId="droppableLeft">
            {droppableProvidedLeft => (
              <div className={styles.drag_left} ref={droppableProvidedLeft.innerRef}>
                {extraUnshiftList.map((item: any, dragindex: number) => (
                  <Draggable
                    isDragDisabled={extraSelectShiftList.length >= 5}
                    key={'drag' + item.id}
                    draggableId={`${item.id}`}
                    index={dragindex}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={cx({ listItem: true, isDragging: snapshot.isDragging })}
                        >
                          {item.name}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvidedLeft.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="droppableRight" direction="horizontal">
            {droppableProvidedRight => (
              <div className={styles.drag_right} ref={droppableProvidedRight.innerRef}>
                <div className={styles.drag_right_top}>
                  <div>
                    <Space size={[5, 8]} wrap>
                      {extraSelectShiftList.map((item: any, dragindex: number) => (
                        <Draggable isDragDisabled key={'drop' + item.id} draggableId={`${item.id}`} index={dragindex}>
                          {(provided, snapshot) => {
                            return (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <ShiftTag
                                  bg={item?.colors?.bg}
                                  borderBg={item?.colors?.border}
                                  onClose={() => onExtraTagClose(item, dragindex, FieldIndex)}
                                >
                                  {item.name + createRangeTimeStr(item.timeSeg)}
                                </ShiftTag>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                    </Space>
                    {droppableProvidedRight.placeholder}
                  </div>
                </div>
                <div className={styles.drag_right_bottom}>
                  <TimeGrid timeArr={extraSelectShiftList} interDay />
                </div>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );
};
export default DragCreateCalendar;
