import { useEffect, useRef, useState } from 'react';
import { RadioChangeEvent, Space } from 'antd';
import { Button, Empty, Form, Modal, Radio, useUpdate } from '@maxtropy/components';
import {
  apiV2OeeApplyOeeDevicesPost,
  apiV2OeeApplyComparisonObjectPost,
  V2OeeApplyComparisonObjectPostRequest,
  V2OeeApplyComparisonObjectPostResponse,
  V2OeeApplyComparisonHistoryPostRequest,
  V2OeeApplyComparisonHistoryPostResponse,
  apiV2OeeApplyComparisonHistoryPost,
  V2OeeApplyOeeDevicesPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import ReactEcharts from 'echarts-for-react';
import { isEmpty } from 'lodash-es';
import { Dayjs } from 'dayjs';
import HistoryCompare from './components/HistoryCompare';
import { CompareType, CompareTypeDisplay, StatisticsPartition } from '../type';
import styles from './index.module.scss';
import ObjectCompare from './components/ObjectCompare';
import DateSwitch from './components/DateSwitch';
import { getBasicChartOptionByHis, getBasicChartOptionByObj } from './components/chartOption';
import { getDefaultDate } from './util';

interface Prop {
  setOpen: (value: boolean) => void;
}
const Comparison = ({ setOpen }: Prop) => {
  const [compareType, setCompareType] = useState<CompareType>(CompareType.OBJECT);
  const [deviceData, setDeviceData] = useState<V2OeeApplyOeeDevicesPostResponse['list']>(); // 设备数据
  const [searchParams, setSearchParams] = useState<
    V2OeeApplyComparisonObjectPostRequest | V2OeeApplyComparisonHistoryPostRequest
  >({}); // 条件
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(getDefaultDate(StatisticsPartition.DAY));
  const [timeResolution, setTimeResolution] = useState<StatisticsPartition>(StatisticsPartition.DAY);
  const [objChartData, setObjChartData] = useState<V2OeeApplyComparisonObjectPostResponse['list']>(); // 对象对比图表数据
  const [hisChartData, setHisChartData] = useState<V2OeeApplyComparisonHistoryPostResponse['list']>(); // 历史对比图表数据
  const objRef = useRef<any>();

  const [objectForm] = Form.useForm();
  const [historyForm] = Form.useForm();
  const [updateObj, setUpdateObj] = useUpdate();

  useEffect(() => {
    apiV2OeeApplyOeeDevicesPost({}).then(res => {
      setDeviceData(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (isEmpty(searchParams)) return;
    apiV2OeeApplyComparisonObjectPost({
      ...searchParams,
      timeResolution,
      from: dateRange[0].valueOf(),
      to: dateRange[1].valueOf(),
    }).then(res => {
      setObjChartData(res.list ?? []);
    });
  }, [updateObj, searchParams, timeResolution, dateRange]);

  const onCompareTypeChange = (e: RadioChangeEvent) => {
    objectForm.resetFields();
    historyForm.resetFields();
    setCompareType(e.target.value);
    setSearchParams({});
    setHisChartData([]);
    setObjChartData([]);
  };

  const onObjectFormFinish = (values: any) => {
    setSearchParams({
      indicatorId: values.indicatorId,
      groupUidList: values.groupUidList.map((i: any) => i.value),
    });
    setUpdateObj();
  };

  const onHistoryFormFinish = (values: any) => {
    apiV2OeeApplyComparisonHistoryPost({
      indicatorId: values.indicatorId,
      groupUid: values.groupUid,
      timeList: values.timeList.map((i: any) => ({
        from: i.fromAndTo[0].startOf('day').valueOf(),
        to: i.fromAndTo[1].endOf('day').valueOf(),
      })),
      timeResolution: values.timeResolution,
    }).then(res => {
      setHisChartData(res.list ?? []);
    });
  };

  const onChangeData = (value: { timeResolution: StatisticsPartition; fromAndTo: [Dayjs, Dayjs] }) => {
    setTimeResolution(value.timeResolution);
    setDateRange(value.fromAndTo);

    setUpdateObj();
  };

  const onReset = () => {
    setSearchParams({});
    if (compareType === CompareType.OBJECT) {
      objectForm.resetFields();
      setTimeResolution(StatisticsPartition.DAY);
      setDateRange(getDefaultDate(StatisticsPartition.DAY));
      setObjChartData([]);
      return;
    }
    historyForm.resetFields();
    setHisChartData([]);
  };

  return (
    <Modal open onCancel={() => setOpen(false)} title="数据对比" width={1200} contentClassName={styles.comparedModal}>
      <div className={styles.leftCol}>
        <Radio.Group
          options={Object.entries(CompareTypeDisplay).map(([k, v]) => ({
            label: v,
            value: k,
          }))}
          onChange={e => {
            onCompareTypeChange(e);
          }}
          value={compareType}
          optionType="button"
          buttonStyle="solid"
        />
        {compareType === CompareType.OBJECT && (
          <>
            <Form form={objectForm} isOrigin layout="vertical" onFinish={onObjectFormFinish}>
              <ObjectCompare form={objectForm} deviceData={deviceData} ref={objRef} />
            </Form>
          </>
        )}
        {compareType === CompareType.HISTORY && (
          <Form
            form={historyForm}
            isOrigin
            layout="vertical"
            initialValues={{
              timeList: [{ fromAndTo: getDefaultDate(timeResolution) }],
            }}
            onFinish={onHistoryFormFinish}
          >
            <HistoryCompare form={historyForm} deviceData={deviceData} />
          </Form>
        )}
        <Space className={styles.btnArea} size={8}>
          <Button
            type="primary"
            onClick={() => {
              compareType === CompareType.OBJECT ? objectForm.submit() : historyForm.submit();
            }}
          >
            对比
          </Button>
          <Button onClick={onReset}>重置</Button>
        </Space>
      </div>
      <div className={styles.rightCol}>
        <div className={styles.dateSwitch}>
          {compareType === CompareType.OBJECT && (
            <DateSwitch
              form={objectForm}
              onQueryChange={onChangeData}
              timeResolution={timeResolution}
              dateRange={dateRange}
            />
          )}
        </div>
        <div className={styles.chartArea} style={{ marginTop: 20 }}>
          {!isEmpty(objChartData) || !isEmpty(hisChartData) ? (
            <ReactEcharts
              style={{
                height: compareType === CompareType.OBJECT ? 470 : 560,
              }}
              option={
                compareType === CompareType.OBJECT
                  ? getBasicChartOptionByObj(objChartData, objectForm, timeResolution)
                  : getBasicChartOptionByHis(hisChartData, historyForm)
              }
              notMerge
              lazyUpdate={false}
            />
          ) : (
            <Empty style={{ marginTop: 20 }} />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Comparison;
