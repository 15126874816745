import React, { useEffect, useState } from 'react';
import { Collapse, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DistributeStatus,
  DistributeStatusDisplay,
  Gateway,
  networkDistributeRecord,
  NetworkDistributeRecordDetail,
} from '@/api/netWorking';
import { EllipsisSpan, Key, Table, Button } from '@maxtropy/components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

const modalColumns: ColumnsType<Gateway> = [
  {
    title: '网关编号',
    dataIndex: 'gatewayNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'gatewayName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'orgName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '下发状态/结果',
    dataIndex: 'gatewayDistributeStatus',
    ellipsis: { showTitle: true },
    render: (v: DistributeStatus) => <EllipsisSpan value={DistributeStatusDisplay[v]} />,
  },
  {
    title: '失败原因',
    width: 300,
    dataIndex: 'gatewayDistributeFailureReason',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const { Panel } = Collapse;

const DistributeRecordList: React.FC<{ netId?: Key }> = ({ netId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<NetworkDistributeRecordDetail[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [size, setSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    // setPage(i => i + 1);

    if (data.length >= totalCount) {
      setLoading(false);
    } else {
      setPage(i => i + 1);
    }
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (netId) {
      networkDistributeRecord({
        netId,
        page,
        size,
      })
        .then(res => {
          setData([...data, ...res.list]);
          setTotalCount(res.total);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 500,
        overflow: 'auto',
        padding: '0 16px',
      }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={data.length < totalCount}
        loader={<Skeleton title={false} paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>没有更多了</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={item => (
            <List.Item key={item.netLogId}>
              <Collapse
                // expandIconPosition="end"
                expandIcon={panelProps => {
                  return (
                    <>
                      <span>
                        【{item.updateByUserName}】在 {dayjs(item.time).format('YYYY-MM-DD HH:mm:ss')} 下发了配置
                        {item.distributeStatus === DistributeStatus.SUCCESS && (
                          <span style={{ marginLeft: '10px', color: '#5CCF27' }}>
                            <CheckOutlined style={{ color: '#5CCF27' }} />
                            成功
                          </span>
                        )}
                        {item.distributeStatus === DistributeStatus.FAIL && (
                          <span style={{ marginLeft: '10px', color: '#FF4D4F' }}>
                            <CloseOutlined style={{ color: '#FF4D4F' }} />
                            失败
                          </span>
                        )}
                        {(item.distributeStatus === DistributeStatus.NOTSTART ||
                          item.distributeStatus === DistributeStatus.STARTING) && (
                          <span style={{ marginLeft: '10px' }}>{DistributeStatusDisplay[item.distributeStatus]}</span>
                        )}
                      </span>
                      {panelProps.isActive ? (
                        <Button type="link" size="small">
                          收起
                        </Button>
                      ) : (
                        <Button type="link" size="small">
                          查看下发的网关
                        </Button>
                      )}
                    </>
                  );
                }}
                ghost={true}
              >
                <Panel header={<></>} key={item.netLogId}>
                  <Table
                    rowKey="gatewayId"
                    scroll={{ x: 500 }}
                    sticky
                    columns={modalColumns}
                    dataSource={item.gatewayList}
                    size="middle"
                  />
                </Panel>
              </Collapse>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default DistributeRecordList;
