import { CircuitDetailProps, createCircuit, getCircuitDetail } from '@/api/circuit';
import { SubContent, useBreadcrumbRoutes, Wrapper, Button, Form, FormTitle } from '@maxtropy/components';
import { apiV2CircuitEditPost } from '@maxtropy/device-customer-apis-v2';
import { Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BasicInfoForm from './components/BasicInfoForm';
import CircuitRelationForm from './components/CircuitRelationForm';
import DeviceListForm from './components/DeviceListForm';

interface CircuitCreateProps {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const CircuitCreate: FC<CircuitCreateProps> = props => {
  const { id } = useParams<{ id: string }>();
  const { isEdit } = props;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState<CircuitDetailProps>();

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}回路` }];
  }, [isEdit]);

  useEffect(() => {
    if (id) {
      getCircuitDetail(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        ouId: data?.ouId,
        type: data?.type,
        voltageLevel: data?.voltageLevel,
        voltageLevelIn: data?.voltageLevelIn,
        voltageLevelOut: data?.voltageLevelOut,
        capacity: data?.capacity,
        targetLoad: data?.targetLoad,
        targetLoadRate: data?.targetLoadRate ? (data?.targetLoadRate * 100).toFixed(2) : '--',
        electricityAccountId: data?.electricityAccountId,
        busTieStatus: data?.busTieStatus,
        distributionCabinetName: data?.distributionCabinetName,
        remark: data?.remark,
        upstreamCircuitId: data?.upstreamCircuitId,
        upstreamCircuitOuId: data?.upstreamCircuitOuId,
        upstreamCircuitOuName: data?.upstreamCircuitOuName,
        upstreamCircuitLeftId: data?.upstreamCircuitLeftId,
        upstreamCircuitLeftOuId: data?.upstreamCircuitLeftOuId,
        upstreamCircuitLeftOuName: data?.upstreamCircuitLeftOuName,
        upstreamCircuitRightId: data?.upstreamCircuitRightId,
        upstreamCircuitRightOuId: data?.upstreamCircuitRightOuId,
        upstreamCircuitRightOuName: data?.upstreamCircuitRightOuName,
        uetId: data?.uetId,
        linkType: data?.linkType,
        devices: data?.devices,
        labelIds: data?.labelId ?? [],
      });
    }
  }, [form, data]);

  const onFinish = async (values: any) => {
    const { linkObjectId, targetLoadRate } = values;
    if (isEdit && id) {
      apiV2CircuitEditPost({
        id,
        ...values,
        linkObjectId: linkObjectId ? linkObjectId[linkObjectId.length - 1] : undefined,
        targetLoadRate: targetLoadRate === '--' ? undefined : targetLoadRate,
      }).then(_ => {
        navigate(`/dc/circuit/circuitManage`);
      });
    } else {
      createCircuit({
        ...values,
        linkObjectId: linkObjectId ? linkObjectId[linkObjectId.length - 1] : undefined,
        targetLoadRate: targetLoadRate === '--' ? undefined : targetLoadRate,
      }).then(_ => {
        navigate(`/dc/circuit/circuitManage`);
      });
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title={routes[0].name} />
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <SubContent title="基本信息" className="mb-8">
          <BasicInfoForm form={form} isEdit={isEdit} />
        </SubContent>
        <SubContent title="设备绑定">
          <Form.Item name="devices" wrapperCol={{ span: 24 }}>
            <DeviceListForm form={form} isEdit={isEdit} />
          </Form.Item>
        </SubContent>
        <SubContent title="回路关系关联">
          <CircuitRelationForm form={form} isEdit={isEdit} data={data} />
        </SubContent>

        <Space size={8} className="sticky-footer-left" style={{ zIndex: 99, paddingLeft: 32 }}>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button
            onClick={() => {
              navigate(`/dc/circuit/circuitManage`);
            }}
          >
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CircuitCreate;

export const searchParent = (arr: string | any[], key: number): any[] => {
  let t = [];
  for (let i = 0; i < arr.length; i++) {
    const e = arr[i];
    if (e.id === key) {
      //若查询到对应的节点，则直接返回
      t.push(e);
      break;
    } else if (e.children && e.children.length !== 0) {
      //判断是否还有子节点，若有对子节点进行循环调用
      let p = searchParent(e.children, key);
      //若p的长度不为0，则说明子节点在该节点的children内，记录此节点，并终止循环
      if (p.length !== 0) {
        p.unshift(e);
        t = p;
        break;
      }
    }
  }
  return t;
};
