import { V2CarbonEmissionFillingDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
export enum EmissionsRange {
  // 范围一 = 1
  RangeOne = 1,
  // 范围二 = 2
  RangeTwo = 2,
  // 范围三 = 3
  RangeThree = 3,
}

// 删除类型
export enum DeleteType {
  // 移除 = 1
  Remove = 1,
  // 删除 = 2
  Delete = 2,
}

// 字段类型
export enum FieldType {
  // 数据关联 1
  DataAssociation = 1,
  // 数值 2
  Number = 2,
  // 输出 3
  Output = 3,
}

export interface RangeDataTypes {
  range1: null | V2CarbonEmissionFillingDetailPostResponse['guideClassificationList'];
  range2: null | V2CarbonEmissionFillingDetailPostResponse['guideClassificationList'];
  range3: null | V2CarbonEmissionFillingDetailPostResponse['guideClassificationList'];
  [key: string]: null | V2CarbonEmissionFillingDetailPostResponse['guideClassificationList'];
}

export interface FillingDataTypes {
  id?: number | string | undefined;
  isEditing?: boolean;
  /**
   * 排放填报id
   */
  emissionFillingId?: number;
  /**
   * 分类过程id
   */
  classificationProcessId?: number;
  /**
   * 排放过程id
   */
  emissionProcessId?: number;
  /**
   * 表单字段1id
   */
  formField1Id?: number;
  /**
   * 表单字段1值
   */
  formField1Value?: string;
  /**
   * 表单字段2id
   */
  formField2Id?: number;
  /**
   * 表单字段2值
   */
  formField2Value?: string;
  /**
   * 表单字段3id
   */
  formField3Id?: number;
  /**
   * 表单字段3值
   */
  formField3Value?: string;
  /**
   * 表单字段4id
   */
  formField4Id?: number;
  /**
   * 表单字段4值
   */
  formField4Value?: string;
  /**
   * 表单字段5id
   */
  formField5Id?: number;
  /**
   * 表单字段5值
   */
  formField5Value?: string;
  /**
   * 表单字段6id
   */
  formField6Id?: number;
  /**
   * 表单字段6值
   */
  formField6Value?: string;
  /**
   * 表单字段7id
   */
  formField7Id?: number;
  /**
   * 表单字段7值
   */
  formField7Value?: string;
  /**
   * 表单字段8id
   */
  formField8Id?: number;
  /**
   * 表单字段8值
   */
  formField8Value?: string;
  /**
   * 表单字段9id
   */
  formField9Id?: number;
  /**
   * 表单字段9值
   */
  formField9Value?: string;
  /**
   * 表单字段10id
   */
  formField10Id?: number;
  /**
   * 表单字段10值
   */
  formField10Value?: string;
  /**
   * 表单字段11id
   */
  formField11Id?: number;
  /**
   * 表单字段11值
   */
  formField11Value?: string;
  /**
   * 表单字段12id
   */
  formField12Id?: number;
  /**
   * 表单字段12值
   */
  formField12Value?: string;
  /**
   * 表单字段13id
   */
  formField13Id?: number;
  /**
   * 表单字段13值
   */
  formField13Value?: string;
  /**
   * 表单字段14id
   */
  formField14Id?: number;
  /**
   * 表单字段14值
   */
  formField14Value?: string;
  /**
   * 表单字段15id
   */
  formField15Id?: number;
  /**
   * 表单字段15值
   */
  formField15Value?: string;
  /**
   * 自动填报规则id
   */
  autoFillingRuleId?: number;
  /**
   * 作废标志(0:未作废 1:作废)
   */
  obsoleteFlag?: number;
  tenantMcid?: string;
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  [key: string]: any;
}

export interface FormFieldListTypes {
  id?: number;
  /**
   * 排放过程id
   */
  emissionProcessId?: number;
  dataIndex?: number;
  title?: string;
  copyTitle?: string;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 字段类型(1:数据关联 2:数值 3:输出)
   */
  fieldType?: number;
  /**
   * 字段属性id
   */
  fieldPropertyId?: number;
  /**
   * 字段属性类型(1燃料 2碳酸盐...)
   */
  fieldPropertyType?: number;
  /**
   * 字段来源(1:排放因子)
   */
  fieldSource?: number;
  /**
   * 字段序号
   */
  fieldOrder?: number;
  /**
   * 小数位数
   */
  decimalDigit?: number;
  /**
   * 数值表达式(例:(0,100])
   */
  numericalExpression?: string;
  /**
   * 筛选表达式
   */
  filterExpression?: string;
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 筛选逻辑
   */
  fieldFilterLogic?: {
    id?: null;
    /**
     * 表单字段id
     */
    formFieldId?: null;
    /**
     * 字段属性id
     */
    fieldPropertyId?: null;
    /**
     * 运算符号(1:等于 2:不等于)
     */
    operateSign?: null;
    /**
     * 匹配类型(1:匹配字段 2:自定义)
     */
    matchType?: null;
    /**
     * 匹配值(字段名称或自定义输入值)
     */
    matchValue?: null;
    createTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    updateTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
  }[];
}

export interface GuideClassificationProcessDtosTypes {
  id?: number;
  /**
   * 分类id
   */
  classificationId?: number;
  /**
   * 排放过程id
   */
  emissionProcessId?: number;
  /**
   * 排放过程name
   */
  emissionProcessName?: string;
  /**
   * 过程排序(1,2,3...)
   */
  processOrder?: number;
  /**
   * 过程表单列表
   */
  formFieldList?: FormFieldListTypes[];
  /**
   * 填报数据
   */
  fillingData?: FillingDataTypes[];
}

interface FillingData {
  id?: number | null;
  emissionFillingId?: number | null;
  classificationProcessId?: number | null;
  emissionProcessId?: number | null;
  formField1Id?: number | null;
  formField1Value?: string | null;
  formField2Id?: number | null;
  formField2Value?: string | null;
  formField3Id?: number | null;
  formField3Value?: string | null;
  formField4Id?: number | null;
  formField4Value?: string | null;
  formField5Id?: number | null;
  formField5Value?: string | null;
  formField6Id?: number | null;
  formField6Value?: string | null;
  formField7Id?: number | null;
  formField7Value?: string | null;
  formField8Id?: number | null;
  formField8Value?: string | null;
  formField9Id?: number | null;
  formField9Value?: string | null;
  formField10Id?: number | null;
  formField10Value?: string | null;
  formField11Id?: number | null;
  formField11Value?: string | null;
  formField12Id?: number | null;
  formField12Value?: string | null;
  formField13Id?: number | null;
  formField13Value?: string | null;
  formField14Id?: number | null;
  formField14Value?: string | null;
  formField15Id?: number | null;
  formField15Value?: string | null;
  autoFillingRuleId?: number | null;
  obsoleteFlag?: number | null;
  tenantMcid?: string | null;
  createTime?: string | null;
  updateTime?: string | null;
}

export type FillingDataMap = Record<string, FillingData>;
