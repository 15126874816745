import { useEffect, useRef } from 'react';
import { ProgressData, RankType, RankTypeDisplay } from '.';

const Progress = ({ data, type, width }: { data: ProgressData; width: number; type: RankType }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef?.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (ctx) {
      let gradient = ctx.createLinearGradient(0, 8, width, 8);
      gradient?.addColorStop(0, RankTypeDisplay[type].colors[0]);
      gradient?.addColorStop((data.percent || 0) / 100, RankTypeDisplay[type].colors[1]);
      gradient?.addColorStop((data.percent || 0) / 100, '#FFFFFF1A');
      gradient?.addColorStop(1, '#FFFFFF1A');

      ctx.strokeStyle = gradient;

      ctx.beginPath();
      ctx.moveTo(0, 8);
      ctx.setLineDash([4, 2]);
      ctx.lineTo(width, 8);
      ctx.lineWidth = 16;
      ctx.stroke();
    }
  }, [canvasRef, width, data, type]);

  return <canvas ref={canvasRef} height={8} width={width}></canvas>;
};

export default Progress;
