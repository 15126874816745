import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import { scale } from '../../utils';
import { MediumCostItem } from '../EnergyCost';

interface Props {
  isFullScreen: boolean;
  eleCost?: MediumCostItem[];
  isTwoCol: boolean;
  allDataIsOverMillion: boolean;
  outerRef: any;
}

enum ItemType {
  SUMMIT = 0,
  PEAK,
  PLAIN,
  VALLEY,
  ATTACH,
  BASIC,
}

const itemFieldMap: Record<string, string> = {
  [ItemType.SUMMIT]: 'summitCharges',
  [ItemType.PEAK]: 'peakCharges',
  [ItemType.PLAIN]: 'plainCharges',
  [ItemType.VALLEY]: 'valleyCharges',
  [ItemType.ATTACH]: 'attachCharges',
  [ItemType.BASIC]: 'basicCharges',
};

const itemNameMap: Record<string, string> = {
  [itemFieldMap[ItemType.SUMMIT]]: '尖电费',
  [itemFieldMap[ItemType.PEAK]]: '峰电费',
  [itemFieldMap[ItemType.PLAIN]]: '平电费',
  [itemFieldMap[ItemType.VALLEY]]: '谷电费',
  [itemFieldMap[ItemType.ATTACH]]: '附加电费',
  [itemFieldMap[ItemType.BASIC]]: '基本电费',
};

const itemColorMap: Record<string, string> = {
  [itemFieldMap[ItemType.SUMMIT]]: '#59DBFF',
  [itemFieldMap[ItemType.PEAK]]: '#FF477B',
  [itemFieldMap[ItemType.PLAIN]]: '#6F47FF',
  [itemFieldMap[ItemType.VALLEY]]: '#FFCB47',
  [itemFieldMap[ItemType.ATTACH]]: '#57FB8B',
  [itemFieldMap[ItemType.BASIC]]: '#477BFF',
};

const toolTipColorMap: Record<string, string> = {
  尖电费: '#59DBFF',
  峰电费: '#FF477B',
  平电费: '#6F47FF',
  谷电费: '#FFCB47',
  附加电费: '#57FB8B',
  基本电费: '#477BFF',
};

const ElectricEcharts: React.FC<Props> = ({ outerRef, isFullScreen, eleCost, isTwoCol, allDataIsOverMillion }) => {
  const getChartSeries = () => {
    const fieldList = Object.values(itemFieldMap);
    const series = fieldList
      .filter(v => eleCost?.some(i => !isNil(i[v as keyof typeof i])))
      .map(v => ({
        type: 'line',
        stack: 'Total',
        color: itemColorMap[v],
        emphasis: {
          focus: 'series',
        },
        showSymbol: false,

        // symbol: 'circle',
        areaStyle: {
          opacity: 0.4,
        },
        name: itemNameMap[v],
        data: eleCost?.map(i => i[v as keyof typeof i]),
      }))
      .reverse();
    return series;
  };

  const getLegendData = () => {
    const fieldList = Object.values(itemFieldMap);
    const data = fieldList
      .filter(v => eleCost?.some(i => !isNil(i[v as keyof typeof i])))
      .map(v => ({
        name: itemNameMap[v],
        icon: 'rect',
        itemStyle: {
          color: itemColorMap[v],
        },
      }));

    return data;
  };

  const geTooltip = (params: any) => {
    const time = dayjs(Number(params[0].axisValue)).format('YYYY-MM-DD');
    const allCostData = params?.map((i: any) => Number(i.data ?? 0));
    let allCost = allCostData.reduce((a: number, b: number) => a + b, 0).toFixed(2);
    if (params?.every((i: any) => isNil(i.data))) allCost = '--';

    const styleStr = `display:flex;width:${240 * zoom}px;justify-content:space-between;font-size: ${
      12 * zoom
    }px;font-weight: 500;color: rgba(255,255,255,0.65);line-height:${17 * zoom}px;margin-top:${8 * zoom}px;`;

    const items = params
      .map((i: any, index: number) => {
        return `<div style='${styleStr}'>
        <div style='width:${40 * zoom}px'>
          <span style='width:${8 * zoom}px;height:${8 * zoom}px;background:${
          toolTipColorMap[i.seriesName]
        }; border-radius:50%;display:inline-block' ></span>
          <span>${i.seriesName ?? '--'}</span>
        </div>
        <div  style='width:${130 * zoom}px; display:flex; justify-content:space-between;align-items:center;'>
          <span style ='color: rgba(255,255,255,0.85);' >${i.data ?? '--'}${allDataIsOverMillion ? '万元' : '元'}</span>
          <span style ='color: rgba(255,255,255,0.85);'>${i.data ? ((i.data / allCost) * 100).toFixed(2) : '--'}%</span>
        </div>
        </div>`;
      })
      .reverse();

    return (
      `<span style='font-size: ${12 * zoom}px;color: rgba(255,255,255,0.65);line-height: ${
        17 * zoom
      }px;'>${time}</span>` +
      ` <div style='display:flex; justify-content:space-between;align-items:center;font-size: ${
        12 * zoom
      }px;font-weight: 500;    color: rgba(255,255,255,0.85);'><span>总电费</span><span>${allCost}${
        allDataIsOverMillion ? '万元' : '元'
      }</span></div>` +
      `${items.join('')}`
    );
  };

  const getChartOption = () => {
    return {
      grid: {
        left: 60 * zoom,
        right: 20,
        // top: 20,
        bottom: 20 * zoom,
      },
      tooltip: {
        transitionDuration: 0,
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12 * zoom,
        },
        formatter(params: any) {
          const str = geTooltip(params);
          return str;
        },
      },
      xAxis: {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (e: number) {
            return dayjs(e, 'x').format('MM-DD');
          },
          fontSize: 12 * zoom,
        },
        data: eleCost?.map(i => Number(i.ts)),
      },
      yAxis: {
        type: 'value',
        name: allDataIsOverMillion ? '万元' : '元',
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
          fontSize: 12 * zoom,
        },
        axisLabel: {
          fontSize: 12 * zoom,
        },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
      },
      legend: {
        show: true,
        right: 0,
        itemHeight: 8 * zoom,
        itemWidth: 8 * zoom,
        data: getLegendData(),
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        height: 8 * zoom,
        itemGap: 10,
      },
      series: getChartSeries(),
    };
  };
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  return (
    <>
      <ReactEcharts
        option={getChartOption()}
        style={
          isTwoCol
            ? { width: '100%', height: 350 }
            : { height: '85%', width: '100%', minHeight: 200, zoom: `${1 / zoom}`, transform: `scale(${scale})` }
        }
        notMerge
        lazyUpdate={false}
      />
    </>
  );
};

export default ElectricEcharts;
