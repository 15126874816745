import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CustomFilter,
  DatePicker,
  Dropdown,
  EllipsisSpan,
  Input,
  Paging,
  PopConfirm,
  Select,
  Table,
  Tag,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
} from '@maxtropy/components';
import { Form, Space } from 'antd';
import AddEnergyPlanModal from './components/AddEnergyPlanModal';
import { useEffect, useMemo, useState } from 'react';
import {
  EnergyPlanDetailResponse,
  EnergyPlanListParams,
  ObjectType,
  ObjectTypeDisplay,
  PlanType,
  deleteEnergyPlan,
  getEnergyPlanList,
} from '@/api/plustekEnergyPlan';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import PreviewModal from './components/PreviewModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';

const columns = [
  {
    title: '用能计划名称',
    width: 200,
    dataIndex: 'planName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '对象类型',
    width: 150,
    dataIndex: 'planObjectType',
    ellipsis: { showTitle: true },
    render: (v: ObjectType) => <EllipsisSpan value={ObjectTypeDisplay[v]} />,
  },
  {
    title: '年度',
    width: 150,
    dataIndex: 'energyYear',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY')} />,
  },
  {
    title: '计划类型',
    width: 180,
    dataIndex: 'planTypeList',
    render: (v: PlanType[]) => {
      return (
        <Space size={0} className={styles.planTypeList}>
          <Tag border="solid" color={Array.isArray(v) && v.includes(PlanType.DAY) ? 'success' : 'default'}>
            日
          </Tag>
          <Tag border="solid" color={Array.isArray(v) && v.includes(PlanType.MONTH) ? 'success' : 'default'}>
            月
          </Tag>
          <Tag border="solid" color={Array.isArray(v) && v.includes(PlanType.QUARTER) ? 'success' : 'default'}>
            季度
          </Tag>
          <Tag border="solid" color={Array.isArray(v) && v.includes(PlanType.YEAR) ? 'success' : 'default'}>
            年
          </Tag>
        </Space>
      );
    },
  },
  {
    title: '最后操作人',
    width: 150,
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    width: 150,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<EnergyPlanListParams, 'page' | 'size'> {}

const PlustekEnergyPlan: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [hasEditPlan, setHasEditPlan] = useState<boolean>(false); // 是否已经编辑计划
  const [editId, setEditId] = useState<number>();
  const [objectType, setPlanType] = useState<ObjectType>(ObjectType.ENERGY_GROUP); // 计划类型
  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnergyPlanDetailResponse[]>([]);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [energyPlanId, setEnergyPlanId] = useState<number>();
  // 新建用能计划-用能分析组
  const has_B_BUILT_ENERGY_USE_PLAN = useHasPermission(PermissionsType.B_BUILT_ENERGY_USE_PLAN);
  // 用能计划配置按钮
  const has_B_EDIT_ENERGY_USE_PLAN = useHasPermission(PermissionsType.B_EDIT_ENERGY_USE_PLAN);
  // 查看计划按钮
  const has_B_ENERGY_USE_PLAN_VIEW = useHasPermission(PermissionsType.B_ENERGY_USE_PLAN_VIEW);
  // 删除按钮
  const has_B_DELETE_ENERGY_USE_PLAN = useHasPermission(PermissionsType.B_DELETE_ENERGY_USE_PLAN);
  // 预览按钮
  const has_B_ENERGY_PLAN_VIEW = useHasPermission(PermissionsType.B_ENERGY_PLAN_VIEW);
  // 编辑按钮
  const has_B_EDIT_ENERGY_PLAN = useHasPermission(PermissionsType.B_EDIT_ENERGY_PLAN);
  const [planYear, setPlanYear] = useState<string>();

  useEffect(() => {
    setLoading(true);
    getEnergyPlanList({
      ...searchParams,
      energyYear: isNil(searchParams.energyYear) ? undefined : dayjs(searchParams.energyYear).format('YYYY-MM-DD'),
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setData(res.list);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={reset}>
      <Form.Item label="用能计划名称" name="planName">
        <Input />
      </Form.Item>
      <Form.Item label="对象类型" name="planObjectType">
        <Select
          options={[
            { label: ObjectTypeDisplay[ObjectType.ENERGY_GROUP], value: ObjectType.ENERGY_GROUP },
            { label: ObjectTypeDisplay[ObjectType.PRODUCTIVE_PROCESS], value: ObjectType.PRODUCTIVE_PROCESS },
          ]}
          allowClear
        />
      </Form.Item>
      <Form.Item label="年度" name="energyYear">
        <DatePicker style={{ width: '100%' }} picker="year" />
      </Form.Item>
    </CustomFilter>
  );

  const onAdd = (objectType: ObjectType) => {
    setEditId(undefined);
    setHasEditPlan(false);
    setPlanType(objectType);
    setModalOpen(true);
  };

  const onEdit = (id: number, hasEditPlan: boolean, objectType: ObjectType) => {
    setEditId(id);
    setHasEditPlan(hasEditPlan);
    setPlanType(objectType);
    setModalOpen(true);
  };

  const onDelete = (id: number) => {
    deleteEnergyPlan(id).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 270,
      fixed: 'right' as 'right',
      render: (v: any, record: EnergyPlanDetailResponse) => {
        return (
          <Space size={16}>
            {has_B_ENERGY_PLAN_VIEW && (
              <Button
                type="link"
                onClick={() => {
                  setEnergyPlanId(record.id);
                  setPlanYear(record.energyYear);
                  setPreviewModalOpen(true);
                }}
              >
                预览
              </Button>
            )}

            {has_B_EDIT_ENERGY_USE_PLAN && (
              <Button
                type="link"
                onClick={() => {
                  window.open(`/energy/analysis/plustekEnergyPlan/edit/${record.id}`, '_self');
                }}
              >
                用能计划配置
              </Button>
            )}

            {has_B_ENERGY_USE_PLAN_VIEW && (
              <Button
                type="link"
                onClick={() => {
                  window.open(`/energy/analysis/plustekEnergyPlan/detail/${record.id}`, '_self');
                }}
              >
                查看计划
              </Button>
            )}
            {has_B_EDIT_ENERGY_PLAN && (
              <Button
                type="link"
                onClick={() =>
                  onEdit(
                    record.id,
                    Array.isArray(record.planTypeList) && record.planTypeList.length > 0,
                    record.planObjectType
                  )
                }
              >
                编辑
              </Button>
            )}

            {has_B_DELETE_ENERGY_USE_PLAN && (
              <PopConfirm
                placement="top"
                title="是否删除该用能计划？删除后无法恢复。"
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="link" danger>
                  删除
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const menuItems = useMemo(() => {
    const items = [];
    if (has_B_BUILT_ENERGY_USE_PLAN) {
      items.push({
        key: '1',
        label: '用能分析组',
        onClick: () => onAdd(ObjectType.ENERGY_GROUP),
      });
    }
    return items;
  }, [has_B_BUILT_ENERGY_USE_PLAN]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} filters={filters}>
      {menuItems.length > 0 && (
        <Space style={{ marginBottom: 10 }}>
          <Dropdown
            menu={{
              items: menuItems,
            }}
            type="primary"
            icon={<PlusOutlined />}
          >
            新建用能计划
          </Dropdown>
        </Space>
      )}

      <Table
        scroll={{
          x: 1300,
        }}
        loading={loading}
        columns={buildColumns}
        rowKey="id"
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalOpen && (
        <AddEnergyPlanModal
          open={modalOpen}
          setOpen={setModalOpen}
          hasEditPlan={hasEditPlan}
          editId={editId}
          objectType={objectType}
          updateFn={updateFn}
        />
      )}
      {previewModalOpen && (
        <PreviewModal
          planYear={planYear}
          energyPlanId={energyPlanId}
          open={previewModalOpen}
          setOpen={setPreviewModalOpen}
        />
      )}
    </Wrapper>
  );
};
export default PlustekEnergyPlan;
