import {
  Button,
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  Form,
  Wrapper,
  FormTitle,
  SubContent,
  Tag,
  ShowInput,
} from '@maxtropy/components';
import { useNavigate } from 'react-router-dom';
import {
  V2EnergyAnalysisListByOu2PostResponse,
  apiV2DispatchPlanDetailPost,
  V2DispatchPlanDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import { useParams } from 'react-router-dom';

//计划子表
interface planDataType {
  dispatchCycles: number[];
  key: string;
  dispatchStartTime: {
    hour: string;
    minute: string;
  };
  dispatchEndTime: {
    hour: string;
    minute: string;
  };
  processorsName: string;
  acceptorName: string;
}
//设备子表
export interface equipmentDataType {
  key: React.Key;
  name: string;
  code: string;
  sn: string;
  modelName: string;
  id: string;
}

export interface AddOrRemoveModalRef {
  selectDatas: Array<equipmentDataType> | undefined;
}

//用能分析组
export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];

export const energy = {
  ENERGYID: 507,
};

//场景枚举
export enum typeEnum {
  LOOP = 1, //回路
  ENEYGY = 2, //用能
}

//派工周期枚举
export enum DispatchCycleEmun {
  MONDAY = 1, // 周一
  TUESDAY = 2, // 周二
  WEDNESDAY = 3, // 周三
  THURSDAY = 4, // 周四
  FRIDAY = 5, // 周五
  SATURDAY = 6, // 周六
  SUNDAY = 7, //周日
}

export const DispatchCycleDisplay = {
  [DispatchCycleEmun.MONDAY]: '周一',
  [DispatchCycleEmun.TUESDAY]: '周二',
  [DispatchCycleEmun.WEDNESDAY]: '周三',
  [DispatchCycleEmun.THURSDAY]: '周四',
  [DispatchCycleEmun.FRIDAY]: '周五',
  [DispatchCycleEmun.SATURDAY]: '周六',
  [DispatchCycleEmun.SUNDAY]: '周日',
};

const PlanDetail: React.FC = props => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<V2DispatchPlanDetailPostResponse>();
  const [planData, setPlanData] = useState<any>([]);
  const [type, setType] = useState<number>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [tableDatas, setTableDatas] = useState<any>([]);
  const navigate = useNavigate();

  const routes = useMemo(() => {
    return [{ name: '详情' }];
  }, []);

  useEffect(() => {
    if (id) {
      apiV2DispatchPlanDetailPost({ id }).then(res => {
        setData(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      // mock
      setType(data.dispatchScene);
      form.setFieldsValue({
        planName: data.planName,
        ouName: data.ouName,
        dispatchScene: data.dispatchScene === 1 ? '回路管网' : '用能单元',
        energyUnitGroupName:
          data.dispatchPlanEnergyUnitLinks && data.dispatchPlanEnergyUnitLinks[0].energyUnitGroupName,
        //先取非电能介质
        energyMediumSceneName:
          data.dispatchPlanCircuitLinks && data.dispatchPlanCircuitLinks.length > 0
            ? data.dispatchPlanCircuitLinks[0].energyMediumSceneName
            : data.dispatchPlanProcessLinks && data.dispatchPlanProcessLinks.length > 0
            ? data.dispatchPlanProcessLinks[0].energyMediumSceneName
            : null,
        circuitName: getCircuitName(data),
      });
      setTableDatas(data.deviceList);
      setPlanData(data.dispatchPlanTriggerTimeProcessorsLinks);
    }
  }, [data, form]);

  //获取派工周期
  const getDay = (v: number[]) => {
    console.log(v, 111);

    //mock
    let arr = v.map(e => {
      return DispatchCycleDisplay[e as keyof typeof DispatchCycleDisplay];
    });
    return arr.join(',');
  };

  //获取派工时间
  const getTime = (data: planDataType) => {
    return `${data.dispatchStartTime}~${data.dispatchEndTime}`;
  };

  //用能单元
  const getEnergyUnitLinks = (data: V2DispatchPlanDetailPostResponse['dispatchPlanEnergyUnitLinks']) => {
    let arr = (data ?? []).map(e => {
      return e.energyUnitName;
    });
    return arr.join(',');
  };

  //回路官网
  const getCircuitName = (data: V2DispatchPlanDetailPostResponse) => {
    let arr = [];
    if (data.dispatchPlanCircuitLinks && data.dispatchPlanCircuitLinks.length > 0) {
      arr = data.dispatchPlanCircuitLinks.map(e => {
        return e.circuitName;
      });
      return arr && arr.join(',');
    }
    if (data.dispatchPlanProcessLinks && data.dispatchPlanProcessLinks.length > 0) {
      arr = data.dispatchPlanProcessLinks.map(e => {
        return e.processName;
      });
      return arr && arr.join(',');
    }
  };

  //派工计划子表
  const columnsPlan: ColumnsType<planDataType> = [
    {
      title: '报警派工周期',
      dataIndex: 'dispatchCycles',
      width: 280,
      render: (v: number[]) => <EllipsisSpan value={getDay(v)} />,
    },
    {
      title: '报警派工时段',
      dataIndex: 'dispatchTime',
      width: 300,
      render: (_, record: planDataType) => {
        return <EllipsisSpan value={getTime(record)} />;
      },
    },
    {
      title: '问题处理负责人',
      dataIndex: 'processorsName',
      width: 300,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工单验收人',
      dataIndex: 'acceptorName',
      width: 280,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  //设备列表
  const columnsEquipment: ColumnsType<equipmentDataType> = [
    {
      title: '编号',
      dataIndex: 'code',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备SN',
      dataIndex: 'sn',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '型号',
      dataIndex: 'modelName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperStyles}>
      <FormTitle title="派工计划详情"></FormTitle>
      <SubContent title="基本信息">
        <Form form={form}>
          <Row>
            <Col span={12}>
              <Form.Item label="计划名称" name="planName" className={styles.formItem}>
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="所属运营单元" name="ouName">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="派工场景" name="dispatchScene">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="报警等级">
                <ShowInput
                  value={
                    <>
                      {(data?.alarmLevels ?? []).map(e => {
                        return (
                          <Tag border="solid" color={AlarmLevelColorDisplay[e as keyof typeof AlarmLevelColorDisplay]}>
                            {AlarmLevelDisplay[e as keyof typeof AlarmLevelDisplay]}
                          </Tag>
                        );
                      })}
                    </>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          {type === typeEnum.ENEYGY && (
            <Row>
              <Col span={12}>
                <Form.Item name="energyUnitGroupName" label="用能分析组">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="用能单元">
                  <ShowInput value={<>{getEnergyUnitLinks(data?.dispatchPlanEnergyUnitLinks)}</>} />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === typeEnum.LOOP && (
            <Row>
              <Col span={12}>
                <Form.Item label="能源介质场景" name="energyMediumSceneName">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="回路管网" name="circuitName" rules={[{ required: true }]}>
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type === typeEnum.LOOP && <Table columns={columnsEquipment} dataSource={tableDatas} />}
          <SubContent className={styles['info-box']} title={<div>派工计划</div>}>
            <Table columns={columnsPlan} dataSource={planData} />
          </SubContent>
          <Button
            onClick={() => {
              navigate('/TicketCenter/WorkOrderManagement/list');
            }}
          >
            返回
          </Button>
        </Form>
      </SubContent>
    </Wrapper>
  );
};
export default PlanDetail;
