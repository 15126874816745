import { FC, useEffect } from 'react';
import { BaseInfoContent, Form, Modal, Paging, ShowInput, Table, usePaging } from '@maxtropy/components';
import {
  V2HvacNodeIndicatorConfigListPostResponse,
  apiV2HvacNodeIndicatorConfigDataLinkPagePost,
  V2HvacNodeIndicatorConfigDataLinkPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import styles from './index.module.scss';

type DataItem = Exclude<V2HvacNodeIndicatorConfigDataLinkPagePostResponse['list'], undefined>[number];

const timeFormat = 'YYYY-MM-DD HH:mm:ss';

interface Props {
  open: boolean;
  onClose: () => void;
  data: Exclude<V2HvacNodeIndicatorConfigListPostResponse['list'], undefined>[number] | undefined;
}

const DataLinkModal: FC<Props> = props => {
  const { open, onClose, data } = props;

  const [form] = Form.useForm();
  const paging = usePaging(5);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = paging;

  useEffect(() => {
    if (!data) {
      form.resetFields();
      return;
    }
    form.setFieldsValue(data);
  }, [data]);

  const { data: tableData, loading } = useRequest(
    async () => {
      if (!(open && !!data)) {
        setTotalCount(0);
        return [];
      }
      const res = await apiV2HvacNodeIndicatorConfigDataLinkPagePost({
        page: pageOffset,
        size: pageSize,
        nodeId: data?.nodeId,
        indicatorId: data?.id,
      });
      setTotalCount(res.total ?? 0);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, data],
    }
  );

  const columns: ColumnType<DataItem>[] = [
    {
      title: '时间',
      key: 'time',
      width: 290,
      ellipsis: { showTitle: true },
      render: (v: any, record) => (
        <ShowInput
          value={`${dayjs(record.effectiveTime).format(timeFormat)} 至 ${
            record.expirationTime ? dayjs(record.expirationTime).format(timeFormat) : '--'
          }`}
        />
      ),
    },
    {
      title: '对应设备',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <ShowInput value={v} />,
    },
    {
      title: '数据属性',
      dataIndex: 'dataPropertyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <ShowInput value={v} />,
    },
  ];

  return (
    <Modal title="数据链路" open={open} onCancel={onClose} onOk={onClose} size="large">
      <Form form={form}>
        <BaseInfoContent layout="horizontal" colNum={1} className={styles.mb0}>
          <Form.Item label="指标名称：" name="name">
            <ShowInput />
          </Form.Item>
        </BaseInfoContent>
      </Form>
      <Table rowKey="id" loading={loading} dataSource={tableData} columns={columns}></Table>
      <Paging pagingInfo={paging}></Paging>
    </Modal>
  );
};

export default DataLinkModal;
