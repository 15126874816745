import {
  DataPointListItem,
  DataPointMirrorRes,
  deleteDataPointMirror,
  DistributeStatus,
  getDataPointMirrorList,
  NetworkingTypeDisPlay,
} from '@/api/netWorking';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import {
  BaseInfoContent,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  Modal,
  ShowInput,
  SubContent,
  Table,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DataGatewayModal from './DataGatewayModal';
import DataMirrorOpModal from './DataMirrorOpModal';
import styles from './index.module.scss';

const columns: ColumnsType<DataPointListItem> = [
  {
    title: '数据发送网关名称',
    dataIndex: 'sendGatewayName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '数据发送网关编号',
    dataIndex: 'sendGatewayNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const NetworkingList: React.FC = () => {
  const hasNetworkDelete = useHasPermission(PermissionsType.B_NETWORK_DELETE); // 删除组网

  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<DataPointMirrorRes>();
  const [loading, setLoading] = useState<boolean>(false);

  const [updateState, updateFn] = useUpdate();

  const [gatewayModalVisible, setGatewayModalVisible] = useState<boolean>(false);
  const [dataMirrorModalVisible, setDataMirrorModalVisible] = useState<boolean>(false);
  const [opType, setOpType] = useState('add');
  const [mirrorId, setMirrorId] = useState<number>();

  // 编辑
  const editBtn = (id: number) => {
    setMirrorId(id);
    openDataMirrorModal('edit');
  };
  // 查看
  const viewBtn = (id: number) => {
    setGatewayModalVisible(true);
    setMirrorId(id);
  };
  // 删除
  const deleteBtn = (id: number) => {
    deleteDataPointMirror(id).then(res => {
      if (res) {
        updateFn();
      }
    });
  };
  const buildColumns = [
    {
      title: '数据接收网关信息',
      dataIndex: 'distributeStatus',
      ellipsis: { showTitle: true },
      width: 150,
      render: (v: DistributeStatus, record: DataPointListItem) => (
        <Button type="link" onClick={() => viewBtn(record.mirrorId)}>
          查看
        </Button>
      ),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      fixed: 'right' as const,
      width: 120,
      render: (v: any, record: DataPointListItem) => {
        return (
          <Space size={16}>
            {hasNetworkDelete && (
              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: '确定删除？',
                    content: (
                      <p>
                        <span style={{ color: '#f00' }}>删除后不可恢复</span>
                        ，你还要继续吗？
                      </p>
                    ),
                    onOk: () => {
                      deleteBtn(record.mirrorId);
                    },
                    okText: '继续',
                    cancelText: '取消',
                  });
                }}
              >
                删除
              </Button>
            )}

            {
              <Button type="link" onClick={() => editBtn(record.mirrorId)}>
                编辑
              </Button>
            }
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDataPointMirrorList(id)
        .then(res => {
          setDetail(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, updateState]);
  const openDataMirrorModal = (op: string) => {
    setOpType(op);
    setDataMirrorModalVisible(true);
  };

  const routes = [{ name: '数据点镜像' }];

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title="数据点镜像" />
        <SubContent>
          <Form form={form} isOrigin={true} style={{ marginBottom: 24 }}>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item label="组网名称">
                <ShowInput value={detail?.netName} />
              </Form.Item>

              <Form.Item label="所属租户">
                <ShowInput value={detail?.tenantName} />
              </Form.Item>

              <Form.Item label="组网类型">
                <ShowInput value={detail?.netType ? NetworkingTypeDisPlay[detail.netType] : '--'} />
              </Form.Item>
            </BaseInfoContent>
          </Form>
          <Space style={{ marginBottom: 10 }}>
            <Button type="primary" onClick={() => openDataMirrorModal('add')}>
              添加数据点镜像
            </Button>
          </Space>

          <Table
            rowKey="mirrorId"
            scroll={{ x: 1300 }}
            loading={loading}
            sticky
            columns={[...columns, ...buildColumns]}
            dataSource={detail?.dataPointList}
          />

          <div style={{ paddingTop: 40 }}>
            <Button>
              <Link to={'/device/networking/networkingList'}>返回</Link>
            </Button>
          </div>
        </SubContent>
      </Wrapper>
      <DataGatewayModal mirrorId={mirrorId} visible={gatewayModalVisible} close={() => setGatewayModalVisible(false)} />
      <DataMirrorOpModal
        visible={dataMirrorModalVisible}
        opType={opType}
        netId={id}
        update={updateFn}
        mirrorId={mirrorId}
        close={() => setDataMirrorModalVisible(false)}
      />
    </>
  );
};

export default NetworkingList;
