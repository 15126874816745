import { StatisticsPartition } from '../../../../api/statistics';
import { Query } from '../../Electricity';

export function getAxisFormatter(query: Query) {
  switch (query.resolution) {
    case StatisticsPartition.HOUR:
      return 'MM-DD[\n]HH:mm';
    case StatisticsPartition.DAY:
      return 'MM-DD';
    case StatisticsPartition.MONTH:
      return 'YYYY-MM';
    case StatisticsPartition.YEAR:
      return 'YYYY';
    default:
      return undefined;
  }
}
