import { InputNumber, Space } from 'antd';
import { addNum } from '../../utils';
import { SummaryTableDataItem } from '@/api/universeMeterQuery';
import { Key } from '@maxtropy/components';

interface EleShareInputProps {
  data: SummaryTableDataItem[];
  currentRow: SummaryTableDataItem;
  setData: (data: SummaryTableDataItem[]) => void;
  editedIds: Key[];
  setEditedIds: (ids: Key[]) => void;
}

const inputNumberProps = {
  style: { width: '100%' },
  min: 0,
  max: 999999,
  step: 0.01,
  precision: 2,
};

const TotalEleShareInput: React.FC<EleShareInputProps> = ({ currentRow, data, setData, editedIds, setEditedIds }) => {
  // 编辑尖分摊值
  const onSummitShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const summitShare = value ?? 0;
      const final = addNum(i.afterAdjust, summitShare, i.peakShare, i.plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          summitShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑峰分摊值
  const onPeakShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const peakShare = value ?? 0;
      const final = addNum(i.afterAdjust, i.summitShare, peakShare, i.plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          peakShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑平分摊值
  const onPlainShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const plainShare = value ?? 0;
      const final = addNum(i.afterAdjust, i.summitShare, i.peakShare, plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          plainShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑谷分摊值
  const onValleyShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const valleyShare = value ?? 0;
      const final = addNum(i.afterAdjust, i.summitShare, i.peakShare, i.plainShare, valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          valleyShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  return (
    <Space size={8}>
      <InputNumber
        {...inputNumberProps}
        value={currentRow.summitShare}
        addonBefore="尖"
        onChange={v => {
          onSummitShareChange(v);
          setEditedIds([...editedIds, currentRow.meterReadingObjectId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.peakShare}
        addonBefore="峰"
        onChange={v => {
          onPeakShareChange(v);
          setEditedIds([...editedIds, currentRow.meterReadingObjectId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.plainShare}
        addonBefore="平"
        onChange={v => {
          onPlainShareChange(v);
          setEditedIds([...editedIds, currentRow.meterReadingObjectId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.valleyShare}
        addonBefore="谷"
        onChange={v => {
          onValleyShareChange(v);
          setEditedIds([...editedIds, currentRow.meterReadingObjectId]);
        }}
      />
    </Space>
  );
};

export default TotalEleShareInput;
