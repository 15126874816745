import React, { useState } from 'react';

import { RadioChangeEvent } from 'antd/es/radio/interface';
import dayjs, { Dayjs } from 'dayjs';
import { getTs, getDefaultTime } from '../../utils';
import { StatisticsPartition } from '../../../../api/statistics';
import { Query } from '../../Electricity';
import styles from './index.module.scss';
import { Button, DatePicker, message, Radio } from '@maxtropy/components';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export type DatePickerType = 'hour' | 'date' | 'month' | 'year';

export const periodToQuery: Record<DatePickerType, StatisticsPartition> = {
  hour: StatisticsPartition.HOUR,
  date: StatisticsPartition.DAY,
  month: StatisticsPartition.MONTH,
  year: StatisticsPartition.YEAR,
};

export const queryToPeriod: Record<StatisticsPartition, DatePickerType> = {
  [StatisticsPartition.HOUR]: 'hour',
  [StatisticsPartition.DAY]: 'date',
  [StatisticsPartition.MONTH]: 'month',
  [StatisticsPartition.YEAR]: 'year',
};

export interface DateSwitchProps {
  value?: any;
  onQueryChange: (value: Query) => void;
}

const DateSwitch: React.FC<DateSwitchProps> = props => {
  const { value, onQueryChange } = props;
  const [dateMode, setDateMode] = useState<DatePickerType>('hour');

  const onRadioChange = (e: RadioChangeEvent) => {
    const value: DatePickerType = e.target.value;
    setDateMode(value);
    const period = periodToQuery[value];
    onQueryChange({
      resolution: period,
      ...getDefaultTime(period, dayjs()),
    });
  };

  const onChangeDatePicker = (date: Dayjs | null) => {
    if (date) {
      onQueryChange({
        resolution: periodToQuery[dateMode],
        ...getTs(periodToQuery[dateMode], date, date),
      });
    }
  };

  const onChangeRangePicker = (range: RangeValue) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (dateMode === 'date') {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
        if (diff >= 31) {
          return message.warning('最大上限为31天');
        }
      } else if (dateMode === 'month') {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
        if (diff >= 36) {
          return message.warning('最大上限为36个月');
        }
      } else if (dateMode === 'year') {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'year');
        if (diff >= 5) {
          return message.warning('最大上限为5年');
        }
      }
      onQueryChange({
        resolution: periodToQuery[dateMode],
        ...getTs(periodToQuery[dateMode], dayjs(beginDate), dayjs(endDate)),
      });
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  return (
    <div className={styles.dateSwitch}>
      <Radio.Group onChange={onRadioChange} value={dateMode} buttonStyle="solid">
        <Radio.Button value={'hour' as DatePickerType}>按小时统计</Radio.Button>
        <Radio.Button value={'date' as DatePickerType}>按天统计</Radio.Button>
        <Radio.Button value={'month' as DatePickerType}>按月统计</Radio.Button>
        <Radio.Button value={'year' as DatePickerType}>按年统计</Radio.Button>
      </Radio.Group>
      <div className={styles.datePickerArea}>
        {/*<span className={styles.label}>选择日期</span>*/}
        {dateMode === 'hour' && (
          <>
            <DatePicker
              value={value[0]}
              style={{ width: 200, marginRight: 20 }}
              disabledDate={disabledDate}
              onChange={onChangeDatePicker}
              allowClear={false}
              getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
            />
            <Button
              type="link"
              onClick={() => {
                onQueryChange({
                  resolution: periodToQuery[dateMode],
                  ...getDefaultTime(periodToQuery[dateMode], dayjs()),
                });
              }}
            >
              今天
            </Button>
            <Button
              type="link"
              onClick={() => {
                onQueryChange({
                  resolution: periodToQuery[dateMode],
                  ...getDefaultTime(periodToQuery[dateMode], dayjs().subtract(1, 'day')),
                });
              }}
            >
              昨天
            </Button>
          </>
        )}
        {dateMode !== 'hour' && (
          <RangePicker
            value={value}
            disabledDate={disabledDate}
            onChange={onChangeRangePicker}
            picker={dateMode}
            allowClear={false}
            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
          />
        )}
      </div>
    </div>
  );
};

export default DateSwitch;
