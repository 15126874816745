import { useEffect, useState } from 'react';
import { Form, Input, Modal, message } from '@maxtropy/components';
import { Spin } from 'antd';
import {
  apiV2GasPreparationStationGasManifoldAddPost,
  apiV2GasPreparationStationGasManifoldEditPost,
  apiV2GasPreparationStationGasManifoldGetPost,
} from '@maxtropy/device-customer-apis-v2';
import { OperationType } from '../../utils';
import { ModalProp } from '../../IntrasiteTopology';

const FormItem = Form.Item;

const GasCollectorNode = ({ onCancel, onOk, operationType, selectedNode, stationData }: ModalProp) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (operationType === OperationType.EDIT) {
      const { selfId } = selectedNode.getData();
      setLoading(true);
      apiV2GasPreparationStationGasManifoldGetPost({ id: selfId })
        .then(res => {
          form.setFieldsValue({
            code: res?.code,
            name: res?.name,
            id: res?.id,
          });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, operationType]);

  const handleForm = () => {
    form.validateFields().then(() => {
      const values = {
        ...form.getFieldsValue(true),
        canvasId: selectedNode?.id,
        stationId: stationData?.stationId,
        stationCode: stationData?.stationCode,
        stationType: stationData?.stationType,
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2GasPreparationStationGasManifoldAddPost(values)
          .then(res => {
            message.success('新增成功');
            onOk({ ...values, selfId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }

      if (operationType === OperationType.EDIT) {
        apiV2GasPreparationStationGasManifoldEditPost(values)
          .then(res => {
            message.success('编辑成功');
            onOk({ ...values, selfId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <Modal
      title="集/分气包节点"
      contentClassName="modal-form-content"
      open
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          {operationType === OperationType.EDIT && (
            <FormItem label="节点编号" name="code">
              <Input placeholder="请输入" disabled />
            </FormItem>
          )}
          <FormItem
            label="节点名称"
            name="name"
            rules={[
              { required: true, message: '请输入节点名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 10, message: '最多输入10个字符' },
            ]}
          >
            <Input placeholder="请输入节点名称" allowClear />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default GasCollectorNode;
