import { FC, useEffect, useState } from 'react';
import { Table, EllipsisSpan, Paging, usePaging, useUpdate, Button, Modal } from '@maxtropy/components';
import dayjs from 'dayjs';
import { Operator, OperatorDisplay } from '@/shared/types';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { BatchDeviceRecord, getBatchDeviceRecordList, deleteBatchDeviceRecord } from '../../../api/batch';
import { SearchProps } from './util';

const columns = [
  {
    title: '导入流水号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导入模板编号',
    dataIndex: 'templateCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '执行状态',
    width: 200,
    dataIndex: 'successCount',
    ellipsis: { showTitle: true },
    render: (v: number, record: BatchDeviceRecord) => (
      <EllipsisSpan
        value={
          <div>
            成功: {v ?? 0}/失败: {record.errorCount ?? 0}/总数:{' '}
            <span style={{ color: 'red' }}>{record.totalCount ?? 0}</span>
          </div>
        }
      />
    ),
  },
  {
    title: '导入时间',
    dataIndex: 'importTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '导入人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (value: string, record: BatchDeviceRecord) => {
      return (
        <EllipsisSpan
          value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
        />
      );
    },
  },
];

const DeviceRecord: FC<SearchProps> = ({ queryData, tabsKey }) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<BatchDeviceRecord[]>([]);

  const getList = () => {
    setLoading(true);
    getBatchDeviceRecordList({ ...queryData, page: pageOffset, size: pageSize }).then(res => {
      if (res) setTotalCount(res.total);
      setList(res.list);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const onDelete = (id: number) => {
    Modal.confirm({
      title: '是否删除流水？',
      okText: '继续',
      onOk: () => {
        deleteBatchDeviceRecord(id).then(res => {
          if (res) {
            update();
          }
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 320,
      fixed: 'right' as 'right',
      render: (value: undefined, record: BatchDeviceRecord) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                onDelete(record.id);
              }}
            >
              删除
            </Button>
            <Button
              type="link"
              disabled={!(record.errorCount && record.errorCount > 0)}
              href={`/api/batch/import/download/error?code=${record.templateCode}&recordId=${record.id}`}
              download
            >
              下载失败数据
            </Button>
            <Button type="link" href={`/api/batch/import/template/download?code=${record.templateCode}`} download>
              下载导入模板
            </Button>
            <Button type="link">
              <Link to={`/device/manage/batch/${record.templateId}/detail?tabs=${tabsKey}`}>查看模板</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table sticky scroll={{ x: 1900 }} rowKey="id" columns={buildColumns} dataSource={list} loading={loading} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default DeviceRecord;
