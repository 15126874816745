import { EnergyFlowChartResponse } from '@/api/plustekSankey';
import { Key } from '@maxtropy/components';

export const DateFormat = 'YYYY-MM-DD';
export const MonthFormat = 'YYYY-MM';

export const MinusGreen = '#52C41A';
export const PlusRed = '#E64242';

export const formatCompositeNumWithUnit = (num: number) => {
  if (num > 10000) {
    return `${(num / 1000).toFixed(2)}tce`;
  } else {
    return `${num.toFixed(2)}kgce`;
  }
};

export const formatMediaNumWithUnit = (num: number, unit?: string) => {
  if (num > 100000) {
    return `${(num / 10000).toFixed(2)}万${unit}`;
  } else {
    return `${num.toFixed(2)}${unit}`;
  }
};

// 判断nodes or links 里 value 都为null
export const isAllValueNull = (data: EnergyFlowChartResponse) => {
  const { nodes, links } = data;
  const nodesValue = nodes.map(i => i.value);
  const linksValue = links.map(i => i.value);
  const isNodesValueNull = nodesValue.every(i => i === null);
  const isLinksValueNull = linksValue.every(i => i === null);
  return isNodesValueNull && isLinksValueNull;
};

export interface baseCenterTypes {
  name?: string;
  disabled?: boolean;
  workCenterHierarchy?: workCenterHierarchyType[];
  id?: number;
  code?: string;
  iconKey?: string;
  sequence?: number;
}
export interface workCenterHierarchyType {
  name?: string;
  id?: number;
  code?: string;
  iconKey?: string;
  sequence?: number;
  productionBaseId?: number;
}

export interface OptionsType {
  label: string;
  value: number;
}
export interface ProductionBaseType {
  id?: number;
  code?: string;
  name?: string;
  productionBaseId?: number;
  workCenterHierarchy: CascadeOptionsType[];
}

export interface CascadeOptionsType {
  id?: number;
  code?: string;
  name?: string;
  productionBaseId?: number;
}

export function concatMessage(data: ProductionBaseType[][]) {
  let selectedData = [];
  for (const arr of data) {
    const innerArrData = arr[1];
    selectedData.push(innerArrData);
  }
  const childrenNames = selectedData.map((child: CascadeOptionsType) => child?.name).join('、');
  return `${data[0][0].name}下${childrenNames}工作中心`;
}

export interface unitTreeItem {
  energyUnitId: Key;
  centerProcedureLinkId: Key; // 中心工序关联id
  energyUnitName: Key;
  procedureStationLinkId: Key; // 工序工站关联id
  workCenterId: Key;
}

export interface StationTreeItem {
  workCenterId: Key;
  centerProcedureLinkId: Key; // 中心工序关联id
  stationId: Key;
  procedureStationLinkId: Key; // 工序工站关联id
  iconKey?: string;
  stationName: string; // 工站名称
  unitTree: unitTreeItem[];
}

export interface ProcedureTreeItem {
  workCenterId: Key;
  procedureId: Key;
  centerProcedureLinkId: Key; // 中心工序关联id
  procedureName: string; //工序名称
  iconKey?: string;
  stationTree: StationTreeItem[];
}

// 左侧树
export interface WorkCenterTreeItem {
  id: Key;
  name: string;
  iconKey?: string;
  procedureTree: ProcedureTreeItem[];
}

export interface edgesType {
  /**
   * 能源工质id
   */
  energyMediumId?: number;
  energyMediumName?: string;
  /**
   * point的来源id
   */
  sourceId?: string;
  /**
   * point的目标id
   */
  targetId?: string;
  /**
   * 名称
   */
  value?: number;
  /**
   * 单位名称
   */
  unit?: string;
  /**
   * 标准煤值
   */
  tceValue?: number;
}

export interface pointsType {
  /**
   * id
   */
  id?: string;
  /**
   * 前端无需使用
   */
  initId?: number;
  /**
   * 名称
   */
  name?: string;
  /**
   * 获取用能单元来自于哪一个工站
   */
  parentId?: number;
  /**
   * 标准煤值
   */
  tceValue?: number;
  /**
   * level2层级的用能值(无需展示)
   */
  value?: number;
  /**
   * 能源工质id(无需展示）
   */
  energyMediumId?: number;
  /**
   * 前端忽略字段
   */
  unit?: string;
  /**
   * 节点层级
   */
  level?: number;
  list?: {
    /**
     * 能源工质id
     */
    energyMediumId?: number;
    /**
     * 名称
     */
    name?: string;
    /**
     * 价值
     */
    value?: number;
    /**
     * 标准煤值
     */
    tceValue?: number;
    /**
     * 单位
     */
    unit?: string;
    /**
     * 比例
     */
    proportion?: number;
  }[];
}
