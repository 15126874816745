import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Key,
  Modal,
  Paging,
  Select,
  Table,
  useCurrent,
  usePaging,
} from '@maxtropy/components';
import { Space } from 'antd';
import { Ref, useEffect, useImperativeHandle, useState } from 'react';
import styles from './index.module.scss';
import { BsaOrPVResponse, BsaOrPVType } from '../../../../../api/energyConsumption';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  apiV2EnergyAnalysisUnitConfGroupLinkedArrayAllPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedBsaPagePost,
  apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost,
} from '@maxtropy/device-customer-apis-v2';
import { OuInfoProps } from '../AddChildOrEditModal';

export interface BSAModalProps {
  unitConfGroupId?: string;
  unitId: string;
  // isPVStation?: boolean; // 是否是光伏站
  visible?: boolean;
  onOk?: () => void;
  onCancel: () => void;
  // ouItems: OuItem[];
}

export interface BsaModalRef {
  selectedRowKeys: Array<number> | undefined;
  selectedRows: { id: number; ouId: number }[];
}

interface SearchParamsProps {
  name?: string; // 光伏站名称
  ouId?: number; // ou
}

const BSAModal = React.forwardRef((props: BSAModalProps, ref: Ref<BsaModalRef>) => {
  const { unitConfGroupId, unitId, visible, onOk, onCancel } = props;
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<{ id: number; ouId: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [devices, setDevices] = useState<BsaOrPVResponse[]>([]);
  const [form] = Form.useForm();
  const current = useCurrent();
  const mcid = current?.tenant?.mcid;
  const [ouItems, setOuItems] = useState<OuInfoProps[]>([]);

  // 查询 ou
  useEffect(() => {
    if (unitConfGroupId) {
      apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost({
        energyUnitId: unitConfGroupId,
      }).then(res => {
        setOuItems(res?.list ?? []);
      });
    }
  }, [unitConfGroupId]);

  useEffect(() => {
    if (unitConfGroupId) {
      // 查询选中的BSA的设备
      apiV2EnergyAnalysisUnitConfGroupLinkedArrayAllPost({
        energyUnitGroupId: unitConfGroupId,
        arrayType: BsaOrPVType.BSA,
      }).then(res => {
        if (res) {
          setSelectedRows((res.list ?? []) as { id: number; ouId: number }[]);
        }
      });
    }
  }, [unitConfGroupId]);

  useEffect(() => {
    if (unitConfGroupId && mcid && ouItems.length) {
      // 查询 bsa 设备分页
      setLoading(true);
      apiV2EnergyAnalysisUnitConfGroupLinkedBsaPagePost({
        bsaName: searchParams?.name,
        ouIdList: searchParams?.ouId ? [searchParams?.ouId] : ouItems.map(item => item.id as number),
        page: pageOffset,
        size: pageSize,
        energyUnitGroupId: unitConfGroupId!,
        tenantMcid: mcid,
      }).then(res => {
        setTotalCount(res.total as number);
        setDevices((res.list ?? []) as unknown as BsaOrPVResponse[]);
        setLoading(false);
      });
    }
  }, [mcid, unitConfGroupId, searchParams, pageOffset, pageSize, setTotalCount, ouItems]);

  const columns = [
    {
      title: `储能站id`,
      dataIndex: 'id',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: `储能站名称`,
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '运营单元',
      dataIndex: 'ouName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const onFinish = (v: SearchParamsProps) => {
    setSearchParams({ ...v });
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      name: undefined,
      ouId: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter onFinish={v => onFinish(v as SearchParamsProps)} form={form} colSpan={8} onReset={onReset}>
      <Form.Item name="name" label={'储能站名称'}>
        <Input placeholder="请输入名称" />
      </Form.Item>
      <Form.Item name="ouId" label="所属运营单元">
        <Select
          mode="multiple"
          optionFilterProp="label"
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouItems.map(o => ({ value: o.id, label: o.name }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  // 将 ref 传递到外部
  useImperativeHandle(ref, () => ({ selectedRowKeys, selectedRows }));

  const onSelectChange = (rowKeys: Key[], rows: any[]) => {
    console.log(rows);
    // const data = rows.filter(item => !!item).filter(item => !selectedRowKeys.includes(item.id));
    setSelectedRows(rows);
    setSelectedRowKeys(rowKeys as number[]);
  };

  return (
    <Modal
      open={visible}
      title={
        <Space align="center">
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: 'var(--heading-color)',
              marginRight: 10,
            }}
          >
            添加储能站
          </span>
          <Space align="center">
            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
            <span
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: 'ragb(255, 255, 255, 0.65)',
              }}
            >
              无运营单元权限不允许修改
            </span>
          </Space>
        </Space>
      }
      width={1200}
      destroyOnClose
      onOk={onOk}
      onCancel={() => {
        onCancel();
      }}
    >
      {filters}
      <div className={styles.table_text}>
        <span>
          已选择<span className={styles.red}>{selectedRows?.length}</span>项
        </span>
      </div>
      <Table
        loading={loading}
        sticky
        rowSelection={{
          hideSelectAll: false,
          preserveSelectedRowKeys: true,
          onChange: onSelectChange,
          selectedRowKeys: selectedRows.map(item => item.id),
          getCheckboxProps: (record: BsaOrPVResponse) => {
            // 如果无权限禁用该选择框
            return {
              disabled: record.staffHasOuAuthority ? false : true,
            };
          },
        }}
        dataSource={devices}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
});

export default BSAModal;
