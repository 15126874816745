import React from 'react';
import { ReportStrategyExecuteCycle } from './index';
import { DatePicker } from 'antd';
import { Select } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { WeekEnum } from '../type';

export interface ReportStrategyRes {
  time?: number;
  year?: number;
  month?: number;
  day?: number;
  week?: number;
  dayOfWeek?: number;
  hour?: number;
  minute?: number;
}

export interface ReportStrategyTime {
  time?: number;
  year?: number;
  month?: number;
  day?: number;
  week?: number;
  dayOfWeek?: number;
  hour?: Dayjs;
}

export const getDateFromReportStrategyTime = (time: ReportStrategyTime, executeTime?: Dayjs) => {
  if (time.time) {
    return dayjs.unix(time.time);
  }
  // 按年执行
  else if (!isNil(time.year)) {
    let t = dayjs();
    if (time.year === -1) {
      t = t.subtract(1, 'year');
    }
    t = t.month(time.month! - 1);

    if (time.day === -1) {
      // 月末
      t = t.endOf('month');
    } else {
      t = t.date(time.day!);
    }
    return t;
  }
  // 按月执行
  else if (!isNil(time.month)) {
    let t = dayjs();
    if (time.month === -1) {
      t = t.subtract(1, 'month');
    }
    if (time.day === -1) {
      t = t.endOf('month');
    } else {
      t = t.date(time.day!);
    }
    if (time.hour) {
      t = t.hour(time.hour.hour());
    }
    return t;

    // 按周执行
  } else if (!isNil(time.week)) {
    let t = dayjs();
    if (time.week === WeekEnum.LAST) {
      // 上周
      t = t
        .add(-1, 'week')
        .startOf('week')
        .add(time.dayOfWeek! - 1, 'day');
    } else {
      t = t.startOf('week').add(time.dayOfWeek! - 1, 'day');
    }
    if (time.hour) {
      t = t.hour(time.hour.hour()).minute(time.hour.minute());
    }
    return t;
  } else {
    let t = dayjs(executeTime);
    if (time.day === -2) {
      t = t.subtract(2, 'day');
    } else {
      t = t.subtract(1, 'day');
    }
    if (time.hour) {
      t = t.hour(time.hour.hour()).minute(time.hour.minute());
    }

    return t;
  }
};

export interface ExecuteTimePickerProps {
  executeCycle: ReportStrategyExecuteCycle;
  value?: ReportStrategyTime;
  onChange?: (value: ReportStrategyTime) => void;
  disabled?: boolean;
}

export const MONTH_OPTIONS = [...new Array(12)].map((_, i) => ({ k: i + 1, v: `${i + 1}月` }));
export const DAY_OPTIONS = [...[...new Array(28)].map((_, i) => ({ k: i + 1, v: `${i + 1}日` })), { k: -1, v: '月末' }];
export const HOUR_OPTIONS = [...new Array(24)].map((_, i) => ({ k: i, v: `${i}:00` }));
export const WEEK_OPTIONS = [
  { k: 1, v: '周一' },
  { k: 2, v: '周二' },
  { k: 3, v: '周三' },
  { k: 4, v: '周四' },
  { k: 5, v: '周五' },
  { k: 6, v: '周六' },
  { k: 7, v: '周日' },
];

const ExecuteTimePicker: React.FC<ExecuteTimePickerProps> = ({ value, onChange, executeCycle, disabled = false }) => {
  if (executeCycle === ReportStrategyExecuteCycle.DAY) {
    return (
      <>
        自&nbsp;
        <DatePicker
          disabled={disabled}
          allowClear={false}
          value={value?.time ? dayjs.unix(value.time) : undefined}
          onChange={e => onChange?.({ time: e!.unix() })}
          disabledDate={current => current && current <= dayjs().endOf('day')}
        />
        &nbsp;起，每日统计前一日设备数据并执行生成报表。
      </>
    );
  } else if (executeCycle === ReportStrategyExecuteCycle.MONTH) {
    return (
      <>
        每月&nbsp;
        <Select disabled={disabled} style={{ width: 138 }} value={value?.day} onChange={e => onChange?.({ day: e })}>
          {DAY_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;统计数据并执行生成报表
      </>
    );
  } else if (executeCycle === ReportStrategyExecuteCycle.WEEK) {
    return (
      <>
        每周&nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={value?.dayOfWeek}
          onChange={e => onChange?.({ dayOfWeek: e })}
        >
          {WEEK_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;统计数据并执行生成报表
      </>
    );
  } else {
    return (
      <>
        每年&nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={value?.month}
          onChange={e => onChange?.({ ...value, month: e })}
        >
          {MONTH_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={value?.day}
          onChange={e => onChange?.({ ...value, day: e })}
        >
          {DAY_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp; 统计数据并执行生成报表
      </>
    );
  }
};

export default ExecuteTimePicker;
