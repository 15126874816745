import { Empty } from '@maxtropy/components';
import { Skeleton } from 'antd';
import { FC } from 'react';
import ReactEcharts from 'echarts-for-react';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { V2StationSignboardStationDetailIndicatorChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash';

export interface ChartProps {
  loading?: boolean;
  chartData?: V2StationSignboardStationDetailIndicatorChartValuePostResponse;
  option?: any;
  height?: number;
}

const Charts: FC<ChartProps> = props => {
  const { option, chartData, height } = props;
  const echartsTheme = useEchartsTheme();
  const filteredChartData = chartData?.list
    ?.map(c => c.chartValues?.filter(i => !isNil(i)))
    .filter(x => !isNil(x))
    .flat();

  return (
    <>
      <Skeleton style={{ padding: '120px 80px' }} loading={props.loading} paragraph={{ rows: 6 }}>
        {filteredChartData && filteredChartData.length ? (
          <ReactEcharts
            option={option}
            style={{ height: height ?? 'calc(100vh - 500px)', minHeight: 500 }}
            notMerge
            lazyUpdate={false}
            theme={echartsTheme}
          />
        ) : (
          <Empty />
        )}
      </Skeleton>
    </>
  );
};

export default Charts;
