import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useAsync,
  Form,
  Modal,
  Select,
  Input,
  Checkbox,
  Radio,
  DatePicker,
  FormTitle,
  SubContent,
  InputNumber,
  Tooltip,
  ShowInput,
} from '@maxtropy/components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Divider, Row, Space } from 'antd';
import styles from './index.module.scss';
import {
  ReportStrategyStatisticalCycleEnum,
  ReportStrategyStatisticalCycleEnumEnumDisplay,
  ReportStrategyStatisticalTypeEnum,
  ReportStrategyStatisticalTypeEnumDisplay,
} from '@/shared/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import SelectDevicesDataProperty from '../components/SelectDevicesDataProperty';
import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import { getOuListOwned, OuListResponse } from '@/api/ou';

import { FormValue } from '../utils';

import ReportStrategyPreview from '../components/ReportStrategyPreview';
import qs from 'qs';
import StatisticsTimePicker from './StatisticsTimePicker';
import ExecuteTimePicker, {
  getDateFromReportStrategyTime,
  ReportStrategyRes,
  ReportStrategyTime,
} from './ExecuteTimePicker';
import StatisticsRangePicker from './StatisticsRangePicker';
import { isNil } from 'lodash-es';
import {
  apiV2ReportStrategyDetailPost,
  apiV2ReportStrategyInsertPost,
  apiV2ReportStrategyUpdatePost,
  V2ReportStrategyDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useSetDefaultFormData } from './hooks';
import { InfoCircleOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
type RangeValue = [Dayjs | null, Dayjs | null] | null;
interface DateLimitType {
  type: ReportStrategyStatisticalCycleEnum;
  unitOfTime: QUnitType | OpUnitType;
  num: number;
}

const dateLimit: DateLimitType[] = [
  {
    type: ReportStrategyStatisticalCycleEnum.HOUR,
    unitOfTime: 'months',
    num: 2,
  },
  {
    type: ReportStrategyStatisticalCycleEnum.DAY,
    unitOfTime: 'years',
    num: 1,
  },
  {
    type: ReportStrategyStatisticalCycleEnum.QUARTER,
    unitOfTime: 'day',
    num: 1,
  },
];

export enum ReportStrategyType {
  ONETIME,
  AUTOMATIC,
}

export enum ReportStrategyValues {
  MAXIMUM = 2,
  MINIMUM = 3,
  AVERAGE = 1,
}

export const reportStrategyValuesDisplay = {
  [ReportStrategyValues.MAXIMUM]: '最大值',
  [ReportStrategyValues.MINIMUM]: '最小值',
  [ReportStrategyValues.AVERAGE]: '平均值',
};

export enum ReportStrategyExecuteCycle {
  DAY = 2,
  MONTH,
  YEAR,
  WEEK,
}

export const reportStrategyExecuteCycleDisPlay = {
  [ReportStrategyExecuteCycle.DAY]: '按日执行',
  [ReportStrategyExecuteCycle.WEEK]: '按周执行',
  [ReportStrategyExecuteCycle.MONTH]: '按月执行',
  [ReportStrategyExecuteCycle.YEAR]: '按年执行',
};

let isInitialData = false;
let isNeedSetDefaultData = false;
const CreateReportStrategy: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { search } = useLocation();
  const { copyId = '', type = '0' }: { copyId?: string; type?: string } = qs.parse(search, { ignoreQueryPrefix: true });
  const strategyType: ReportStrategyType = +type;
  const [form] = Form.useForm();

  const [data, setData] = useState<V2ReportStrategyDetailPostResponse>();

  const [statisticalCycle, setStatisticalCycle] = useState<ReportStrategyStatisticalCycleEnum>(
    ReportStrategyStatisticalCycleEnum.HOUR
  );

  const [statisticalType, setStatisticalType] = useState<ReportStrategyStatisticalTypeEnum>();
  const [executeCycle, setExecuteCycle] = useState(ReportStrategyExecuteCycle.DAY);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (isEdit && id) {
      apiV2ReportStrategyDetailPost({ id }).then(res => {
        setData(res);
      });
    }
  }, [id, isEdit]);

  useEffect(() => {
    if (!isEdit && copyId) {
      apiV2ReportStrategyDetailPost({ id: +copyId }).then(res => {
        setData(res);
        setOus(res.ous as OuListResponse[]);
      });
    }
  }, [isEdit, copyId]);

  const [ous, setOus] = useState<OuListResponse[]>([]);

  const ou = useAsync(getOuListOwned, []);
  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const [ouSearchValue, setOuSearchValue] = useState<string>('');
  const ouIds = Form.useWatch('ouIds', form);
  const allOuSelected = useMemo(() => ouIds?.length === ou?.length, [ou?.length, ouIds?.length]);

  useEffect(() => {
    // 重新获取ou
    if (ous) {
      // 清空部分Form
      clearFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ous, form]);

  useEffect(() => {
    if (data) {
      // 设置周期
      setStatisticalCycle(data?.statisticalResolution as ReportStrategyStatisticalCycleEnum);
      setStatisticalType(data?.measurementType);
      const time = [
        dayjs.unix(data.statisticalBeginTime?.time!) ?? null,
        dayjs.unix(data.statisticalEndTime?.time!) ?? null,
      ].filter(i => i);
      setDateValue(time as RangeValue);

      form.setFieldsValue({
        name: data?.name,
        remark: data?.remark,
        measurementType: isEdit
          ? ReportStrategyStatisticalTypeEnumDisplay[data.measurementType as ReportStrategyStatisticalTypeEnum]
          : data.measurementType,
        ouIds: isEdit ? data?.ous?.map(item => item.name).join(',') : data.ous?.map(item => item.id),
        statisticalResolution: data?.statisticalResolution,
        dataPropertyLinkList: data?.dataPropertyLinkList,
        computeType: data?.computeType,
        reportType: data.reportType,
        time: strategyType === ReportStrategyType.ONETIME ? time : null,
        executionTime: data.executionTime,
        statisticalTime: [getHourMoment(data.statisticalBeginTime!), getHourMoment(data.statisticalEndTime!)],
        decimalPlaces: data.decimalPlaces,
      });
      if (data.reportType !== ReportStrategyExecuteCycle.DAY) {
        isInitialData = true;
      }
      setExecuteCycle(data.reportType!);
    }
  }, [data, form, isEdit, strategyType]);

  const getHourMoment = (time: ReportStrategyRes) => {
    if (!isNil(time?.hour)) {
      return {
        ...time,
        hour: dayjs()
          .hour(time?.hour ?? 0)
          .minute(time?.minute ?? 0),
      };
    }
    return time;
  };

  const clearFormData = () => {
    form.setFieldsValue({
      dataPropertyLinkList: [],
    });
  };

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}策略` }];
  }, [isEdit]);

  const goList = () => {
    navigate(`/data/report/strategy?type=${strategyType}`);
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const [dates, setDates] = useState<RangeValue>();
  const [hackValue, setHackValue] = useState<RangeValue>();
  const [dateValue, setDateValue] = useState<RangeValue>();

  const disabledDate = useCallback(
    (current: Dayjs) => {
      if (strategyType === ReportStrategyType.AUTOMATIC) {
        return false;
      }

      const limitEnd =
        current && (current >= dayjs().endOf('day') || current <= dayjs().subtract(3, 'years').endOf('day'));

      if (!dates || !dates.length) {
        return limitEnd;
      }
      const limit = dateLimit.find(i => i.type === statisticalCycle);
      if (limit) {
        const tooLate = dates[0] && current.diff(dates[0], limit.unitOfTime) >= limit.num;
        const tooEarly = dates[1] && dates[1].diff(current, limit.unitOfTime) >= limit.num;
        return !!tooEarly || !!tooLate || limitEnd;
      }
      return limitEnd;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statisticalCycle, dates]
  );

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(undefined);
    }
  };

  useEffect(() => {
    if (statisticalCycle !== ReportStrategyStatisticalCycleEnum.QUARTER) {
      form.setFieldsValue({
        time: hackValue || dateValue,
      });
    }
  }, [dateValue, hackValue, form, statisticalCycle]);

  useEffect(() => {
    if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
      const newDate: RangeValue = [dayjs().subtract(6, 'day').startOf('day'), dayjs().endOf('day').startOf('hour')];
      form.setFieldsValue({
        time: newDate,
      });
      setDateValue(newDate);
    }
  }, [statisticalCycle, form]);

  const onFinish = (value: any) => {
    if ((value.dataPropertyLinkList ?? []).length === 0) {
      Modal.warning({
        title: '请添加设备！',
      });
      return;
    }

    if (strategyType === ReportStrategyType.ONETIME) {
      // 需要修改
      const startTime = (value.time as RangeValue)![0]!.clone().unix();
      value.reportType = 1;

      const endTime = (value.time as RangeValue)![1]!.clone().unix();
      value.statisticalBeginTime = { time: startTime };
      value.statisticalEndTime = { time: endTime };
    } else {
      let beginValue = value.statisticalTime?.[0];
      let endValue = value.statisticalTime?.[1];
      if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.QUARTER) {
        value.statisticalBeginTime = { ...beginValue, hour: beginValue.hour.hour(), minute: beginValue.hour.minute() };
        value.statisticalEndTime = { ...endValue, hour: endValue.hour.hour(), minute: endValue.hour.minute() };
      } else if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
        value.statisticalBeginTime = { ...beginValue, hour: beginValue.hour.hour() };
        value.statisticalEndTime = { ...endValue, hour: endValue.hour.hour() };
      } else {
        value.statisticalBeginTime = beginValue;
        value.statisticalEndTime = endValue;
      }
    }

    if (isEdit && id) {
      // 编辑
      const newValue: any = {
        id: data!.id,
        ouIds: data!.ous?.map(item => item.id),
        name: value.name,
        remark: value.remark,
        measurementType: data!.measurementType,
        statisticalBeginTime: value.statisticalBeginTime,
        computeType: value.computeType,
        statisticalResolution: value.statisticalResolution,
        reportType: value.reportType,
        executionTime: value.executionTime,
        statisticalEndTime: value.statisticalEndTime,
        oldDataPropertyLinkList: (data!.dataPropertyLinkList ?? []).map(i => ({
          deviceId: i.deviceId,
          dataPropertyIdList: (i.dataPropertyList ?? []).map(i => i.id),
        })),
        newDataPropertyLinkList: (value.dataPropertyLinkList ?? []).map(
          (i: { deviceId: any; dataPropertyList: any }) => ({
            deviceId: i.deviceId,
            dataPropertyIdList: (i.dataPropertyList ?? []).map((i: { id: any }) => i.id),
          })
        ),
        decimalPlaces: value.decimalPlaces,
      };

      apiV2ReportStrategyUpdatePost(newValue).then(res => {
        goList();
      });
    } else {
      // 新建
      const newValue: any = {
        name: value.name,
        statisticalType: value.statisticalType,
        ouIds: value.ouIds,
        remark: value.remark,
        measurementType: value.measurementType,
        statisticalBeginTime: value.statisticalBeginTime,
        computeType: value.computeType,
        statisticalResolution: value.statisticalResolution,
        reportType: value.reportType,
        executionTime: value.executionTime,
        statisticalEndTime: value.statisticalEndTime,
        dataPropertyLinkList: (value.dataPropertyLinkList ?? []).map((i: { deviceId: any; dataPropertyList: any }) => ({
          deviceId: i.deviceId,
          dataPropertyIdList: (i.dataPropertyList ?? []).map((i: { id: any }) => i.id),
        })),
        decimalPlaces: value.decimalPlaces,
      };
      apiV2ReportStrategyInsertPost(newValue).then(res => {
        goList();
      });
    }
  };

  const [previewData, setPreviewData] = useState<FormValue>();

  const onPreview = () => {
    form.validateFields().then((value: any) => {
      if ((value.dataPropertyLinkList ?? []).length === 0) {
        Modal.warning({
          title: '请添加设备！',
        });
        return;
      }
      // 单次策略
      if (strategyType === ReportStrategyType.ONETIME) {
        setPreviewData(value);
        return;
      }
      // 定时策略
      // 按日执行
      if (value.reportType === ReportStrategyExecuteCycle.DAY) {
        // 15分钟
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.QUARTER) {
          let startTime = dayjs
            .unix(value.executionTime.time)
            .subtract(value.statisticalTime[0].day === 0 ? 1 : 2, 'day')
            .hour(value.statisticalTime[0].hour.hour())
            .minute(value.statisticalTime[0].hour.minute());
          let endTime = dayjs
            .unix(value.executionTime.time)
            .subtract(value.statisticalTime[1].day === 0 ? 1 : 2, 'day')
            .hour(value.statisticalTime[1].hour.hour())
            .minute(value.statisticalTime[1].hour.minute());
          const time = [startTime, endTime];
          setPreviewData({ ...value, time });
          return;
        }
        // 1小时
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
          let startTime = dayjs
            .unix(value.executionTime.time)
            .subtract(value.statisticalTime[0].day === 0 ? 1 : 2, 'day')
            .hour(value.statisticalTime[0].hour.hour())
            .minute(0);
          let endTime = dayjs
            .unix(value.executionTime.time)
            .subtract(value.statisticalTime[1].day === 0 ? 1 : 2, 'day')
            .hour(value.statisticalTime[1].hour.hour())
            .minute(0);
          const time = [startTime, endTime];
          setPreviewData({ ...value, time });
        }

        return;
      }
      // 按月执行
      if (value.reportType === ReportStrategyExecuteCycle.MONTH) {
        // 1小时
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
          const time = [getMonth(value.statisticalTime[0]), getMonth(value.statisticalTime[1])];
          setPreviewData({ ...value, time });
          return;
        }
        // 1日
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.DAY) {
          const time = [
            getDateFromReportStrategyTime(value.statisticalTime[0]),
            getDateFromReportStrategyTime(value.statisticalTime[1]),
          ];
          setPreviewData({ ...value, time });
        }
        return;
      }
      // 按周执行
      if (value.reportType === ReportStrategyExecuteCycle.WEEK) {
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
          const time = [getMonth(value.statisticalTime[0]), getMonth(value.statisticalTime[1])];
          setPreviewData({ ...value, time });
          return;
        }
        if (value.statisticalResolution === ReportStrategyStatisticalCycleEnum.QUARTER) {
          const time = [getWeekMinute(value.statisticalTime[0]), getWeekMinute(value.statisticalTime[1])];
          setPreviewData({ ...value, time });
          return;
        }
        const time = [
          getDateFromReportStrategyTime(value.statisticalTime[0]),
          getDateFromReportStrategyTime(value.statisticalTime[1]),
        ];
        setPreviewData({ ...value, time });
        return;
      }
      // 按年执行
      const time = [
        getDateFromReportStrategyTime(value.statisticalTime[0]),
        getDateFromReportStrategyTime(value.statisticalTime[1]),
      ];
      setPreviewData({ ...value, time });
    });
  };

  const getMonth = (time: ReportStrategyTime) => {
    let t = dayjs();
    // 上月
    if (time.month === -1) {
      t = t.subtract(1, 'month');
    }

    if (typeof time.day === 'number') {
      // 月末
      if (time.day === -1) {
        t = t.endOf('month');
      } else {
        t = t.date(time.day!);
      }
    }
    if (typeof time.week) {
      // 上周
      if (time.week === -1) {
        t = t.subtract(1, 'week');
      }
      t = t.startOf('week').add(time.dayOfWeek! - 1, 'day');
    }
    if (time.hour) {
      t = t.hour(time.hour.hour());
    }
    return t;
  };
  const getWeekMinute = (time: ReportStrategyTime) => {
    let t = dayjs();
    if (typeof time.week) {
      // 上周
      if (time.week === -1) {
        t = t.subtract(1, 'week');
      }
      t = t.startOf('week').add(time.dayOfWeek! - 1, 'day');
    }
    if (time.hour) {
      t = t.hour(time.hour.hour()).minute(time.hour.minute());
    }
    return t;
  };

  const closePreviewModal = () => {
    setPreviewData(undefined);
  };

  useEffect(() => {
    if (isInitialData) {
      isInitialData = false;
      return;
    }
    // 按日执行
    if (executeCycle === ReportStrategyExecuteCycle.DAY) {
      // 默认选中1小时
      form.setFieldsValue({
        statisticalResolution: ReportStrategyStatisticalCycleEnum.HOUR,
        executionTime: { time: dayjs().add(1, 'day').unix() },
      });
      setStatisticalCycle(ReportStrategyStatisticalCycleEnum.HOUR);
    }
    // 按月执行
    else if (executeCycle === ReportStrategyExecuteCycle.MONTH) {
      // 默认选中1小时
      form.setFieldsValue({
        statisticalResolution: ReportStrategyStatisticalCycleEnum.HOUR,
        executionTime: { day: 1 },
      });
      setStatisticalCycle(ReportStrategyStatisticalCycleEnum.HOUR);
    }
    // 按周执行
    else if (executeCycle === ReportStrategyExecuteCycle.WEEK) {
      form.setFieldsValue({
        statisticalResolution: ReportStrategyStatisticalCycleEnum.DAY,
        executionTime: { dayOfWeek: 1 },
      });

      setStatisticalCycle(ReportStrategyStatisticalCycleEnum.DAY);
    }
    // 按年执行
    else {
      // 默认选中1日
      form.setFieldsValue({
        statisticalResolution: ReportStrategyStatisticalCycleEnum.DAY,
        executionTime: { month: 1, day: 1 },
      });
      setStatisticalCycle(ReportStrategyStatisticalCycleEnum.DAY);
    }
  }, [executeCycle, form]);

  useEffect(() => {
    if (
      statisticalCycle !== ReportStrategyStatisticalCycleEnum.QUARTER &&
      statisticalType === ReportStrategyStatisticalTypeEnum.INSTANTANEOUS
    ) {
    } else {
      form.setFieldsValue({
        computeType: undefined,
      });
    }
  }, [form, statisticalCycle, statisticalType]);

  // 设置默认值
  useSetDefaultFormData({ form, executeCycle, statisticalCycle, isNeedSetDefaultData, isEdit });

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <FormTitle title={routes?.[0].name} />
        <SubContent title="基础信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="策略名称"
                rules={[
                  { required: true, message: '请输入策略名称' },
                  { max: 20, message: '最多输入二十个字' },
                  { pattern: /^((?![\\/:*?<>|.]).)+$/, message: '请确保策略名称中不含下列字符：\\ / : * ? < > | .' },
                ]}
              >
                <Input placeholder="请输入策略名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="measurementType"
                label="统计类型"
                rules={[{ required: true, message: '请选择统计类型' }]}
              >
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Select<ReportStrategyStatisticalTypeEnum>
                    placeholder="请选择"
                    onChange={e => {
                      clearFormData();
                      setStatisticalType(e);
                    }}
                  >
                    <Select.Option value={ReportStrategyStatisticalTypeEnum.ACCUMULATION}>
                      {ReportStrategyStatisticalTypeEnumDisplay[ReportStrategyStatisticalTypeEnum.ACCUMULATION]}
                    </Select.Option>
                    <Select.Option value={ReportStrategyStatisticalTypeEnum.INSTANTANEOUS}>
                      {ReportStrategyStatisticalTypeEnumDisplay[ReportStrategyStatisticalTypeEnum.INSTANTANEOUS]}
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouIds" label="运营单元" rules={[{ required: true, message: '请选择运营单元' }]}>
                {isEdit ? (
                  <ShowInput value={data?.ous?.map(item => item.name).join(',')} />
                ) : (
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    onChange={(v: number[]) => {
                      setOus(ou.filter(i => v.includes(i.id)));
                      setOuSearchValue('');
                    }}
                    options={ouOptions}
                    showSearch
                    optionFilterProp="label"
                    maxTagCount="responsive"
                    searchValue={ouSearchValue}
                    onSearch={value => setOuSearchValue(value)}
                    dropdownRender={menu => (
                      <>
                        {!ouSearchValue.length && ou.length ? (
                          <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                            <Checkbox
                              indeterminate={ouIds?.length && ouIds?.length < (ou?.length ?? 0)}
                              checked={allOuSelected}
                              onChange={e => {
                                if (e.target.checked) {
                                  // 全选
                                  form.setFieldValue(
                                    'ouIds',
                                    ou?.map(v => v.id)
                                  );
                                  setOus(ou);
                                } else {
                                  // 反选
                                  form.setFieldValue('ouIds', []);
                                  setOus([]);
                                }
                              }}
                            >
                              全选
                            </Checkbox>
                          </div>
                        ) : null}
                        <Divider style={{ margin: 0 }} />
                        {menu}
                      </>
                    )}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="decimalPlaces"
                label={
                  <div>
                    <span>报表数据小数点后保留位数</span>
                    <Tooltip title="支持 0~12 位整数,按照四舍五入保留小数">
                      <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginLeft: 5 }} />
                    </Tooltip>
                  </div>
                }
                rules={[{ type: 'integer', max: 12, min: 0, message: '请输入0~12位整数' }]}
              >
                <InputNumber style={{ width: '100%' }} placeholder="请输入" addonAfter="位" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="remark" label="备注" rules={[{ max: 50, message: '最多输入五十个字' }]}>
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="统计对象" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="dataPropertyLinkList" wrapperCol={{ span: 24 }}>
                <SelectDevicesDataProperty
                  ous={!isEdit ? ous : (data?.ous as OuListResponse[])}
                  statisticalType={statisticalType ?? data?.measurementType}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="统计周期" className="mb-8">
          <Row>
            {strategyType === ReportStrategyType.AUTOMATIC && (
              <Col span={24}>
                <Form.Item
                  name="reportType"
                  wrapperCol={{ span: 24 }}
                  label="执行周期"
                  initialValue={ReportStrategyExecuteCycle.DAY}
                  rules={[{ required: true, message: '请选择' }]}
                >
                  <Select
                    placeholder={'请选择'}
                    style={{ width: 174 }}
                    onChange={val => {
                      setExecuteCycle(val);
                      form.setFieldsValue({
                        time: undefined,
                        statisticalTime: undefined,
                      });
                      isNeedSetDefaultData = true;
                    }}
                  >
                    {/* 有顺序问题, 先写死 */}
                    {/* {Object.entries(reportStrategyExecuteCycleDisPlay).map(([k, v]) => (
                      <Select.Option key={k} value={+k}>
                        {v}
                      </Select.Option>
                    ))} */}
                    <Select.Option key={ReportStrategyExecuteCycle.DAY} value={ReportStrategyExecuteCycle.DAY}>
                      {reportStrategyExecuteCycleDisPlay[ReportStrategyExecuteCycle.DAY]}
                    </Select.Option>
                    <Select.Option key={ReportStrategyExecuteCycle.WEEK} value={ReportStrategyExecuteCycle.WEEK}>
                      {reportStrategyExecuteCycleDisPlay[ReportStrategyExecuteCycle.WEEK]}
                    </Select.Option>
                    <Select.Option key={ReportStrategyExecuteCycle.MONTH} value={ReportStrategyExecuteCycle.MONTH}>
                      {reportStrategyExecuteCycleDisPlay[ReportStrategyExecuteCycle.MONTH]}
                    </Select.Option>
                    <Select.Option key={ReportStrategyExecuteCycle.YEAR} value={ReportStrategyExecuteCycle.YEAR}>
                      {reportStrategyExecuteCycleDisPlay[ReportStrategyExecuteCycle.YEAR]}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="statisticalResolution"
                wrapperCol={{ span: 24 }}
                label="数据颗粒度"
                initialValue={ReportStrategyStatisticalCycleEnum.HOUR}
                rules={[{ required: true, message: '请选择数据颗粒度' }]}
              >
                <Radio.Group
                  onChange={e => {
                    form.setFieldsValue({
                      time: undefined,
                      statisticalTime: undefined,
                    });
                    setDateValue(undefined);
                    setStatisticalCycle(e.target.value);
                    isNeedSetDefaultData = true;
                  }}
                >
                  {strategyType === ReportStrategyType.ONETIME ? (
                    <>
                      <Radio value={ReportStrategyStatisticalCycleEnum.QUARTER}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.QUARTER]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.HOUR}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.HOUR]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.DAY}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.DAY]}
                      </Radio>
                    </>
                  ) : executeCycle === ReportStrategyExecuteCycle.DAY ? (
                    <>
                      <Radio value={ReportStrategyStatisticalCycleEnum.QUARTER}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.QUARTER]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.HOUR}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.HOUR]}
                      </Radio>
                    </>
                  ) : executeCycle === ReportStrategyExecuteCycle.MONTH ? (
                    <>
                      <Radio value={ReportStrategyStatisticalCycleEnum.HOUR}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.HOUR]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.DAY}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.DAY]}
                      </Radio>
                    </>
                  ) : executeCycle === ReportStrategyExecuteCycle.YEAR ? (
                    <>
                      <Radio value={ReportStrategyStatisticalCycleEnum.DAY}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.DAY]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.MONTH}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.MONTH]}
                      </Radio>
                    </>
                  ) : executeCycle === ReportStrategyExecuteCycle.WEEK ? (
                    <>
                      <Radio value={ReportStrategyStatisticalCycleEnum.QUARTER}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.QUARTER]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.HOUR}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.HOUR]}
                      </Radio>
                      <Radio value={ReportStrategyStatisticalCycleEnum.DAY}>
                        {ReportStrategyStatisticalCycleEnumEnumDisplay[ReportStrategyStatisticalCycleEnum.DAY]}
                      </Radio>
                    </>
                  ) : undefined}
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* 单次策略 */}
            {strategyType === ReportStrategyType.ONETIME && (
              <Col span={24} className={styles.formSelect}>
                <Form.Item
                  name="time"
                  wrapperCol={{ span: 24 }}
                  label="统计时间"
                  className={styles.formSelect}
                  rules={[
                    { required: true, message: '请选择' },
                    {
                      validator: (_, value) => {
                        if (
                          statisticalCycle === ReportStrategyStatisticalCycleEnum.QUARTER &&
                          value?.[0].date() !== value?.[1].date()
                        ) {
                          return Promise.reject('起始时间与截止时间的日期只可选择同一日');
                        }
                        if (value?.[0] && value?.[1] && value?.[0].unix() === value?.[1].unix()) {
                          return Promise.reject('起始时间应早于截止时间');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {statisticalCycle === ReportStrategyStatisticalCycleEnum.QUARTER ? (
                    <DatePicker.RangePicker
                      format="YYYY-MM-DD HH:mm"
                      showTime={{
                        format: 'HH:mm',
                        minuteStep: 15,
                        defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:45', 'HH:mm')],
                      }}
                      allowClear={false}
                      onCalendarChange={dates => {
                        setDates(dates);
                      }}
                      onChange={val => setDateValue(val)}
                      disabledDate={disabledDate}
                    />
                  ) : statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR ? (
                    <StatisticsRangePicker
                      disabledDate={disabledDate}
                      onCalendarChange={dates => {
                        setDates(dates);
                      }}
                      onChange={val => setDateValue(val)}
                      showTime={true}
                    />
                  ) : (
                    <StatisticsRangePicker
                      disabledDate={disabledDate}
                      onCalendarChange={dates => {
                        setDates(dates);
                      }}
                      onChange={val => setDateValue(val)}
                      showTime={false}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            {/* 定时策略 */}
            {strategyType === ReportStrategyType.AUTOMATIC && (
              <>
                <Col span={24} className={styles.reset_form_style}>
                  <Form.Item
                    name="executionTime"
                    wrapperCol={{ span: 24 }}
                    label="执行时间"
                    rules={[{ required: true, message: '请选择' }]}
                  >
                    <ExecuteTimePicker executeCycle={executeCycle} />
                  </Form.Item>
                </Col>
                <Col span={24} className={styles.formSelect}>
                  <Form.Item
                    name="statisticalTime"
                    dependencies={['executionTime', 'statisticalResolution']}
                    wrapperCol={{ span: 24 }}
                    label="统计时间"
                    rules={[
                      { required: true, message: '请选择' },
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          // 获取执行时间
                          const executionTimeValue = getFieldValue('executionTime');
                          const statisticalResolution = getFieldValue('statisticalResolution');
                          let executeTime = dayjs();
                          // 按年
                          if (executeCycle === ReportStrategyExecuteCycle.YEAR && executionTimeValue.month) {
                            executeTime = executeTime.month(executionTimeValue.month - 1);
                          }
                          // 按月
                          if (executeCycle === ReportStrategyExecuteCycle.MONTH) {
                            executeTime =
                              executionTimeValue.day === -1
                                ? dayjs().endOf('month')
                                : dayjs().date(executionTimeValue.day);
                            if (statisticalResolution === ReportStrategyStatisticalCycleEnum.HOUR) {
                              if (value[1]?.hour.isBefore(value[0]?.hour)) {
                                return Promise.reject('起始时间应早于截止时间');
                              }
                            }
                          }
                          // 按周, 执行时间
                          if (executeCycle === ReportStrategyExecuteCycle.WEEK) {
                            executeTime = dayjs()
                              .startOf('week')
                              .add(executionTimeValue.dayOfWeek - 1, 'day');
                          }
                          // 按日
                          if (executeCycle === ReportStrategyExecuteCycle.DAY) {
                            executeTime = dayjs(executionTimeValue.time, 'X');
                          }

                          // 获取统计起止时间
                          const beginTime = getDateFromReportStrategyTime(value?.[0], executeTime);
                          const endTime = getDateFromReportStrategyTime(value?.[1], executeTime);

                          if (endTime.isBefore(beginTime)) {
                            return Promise.reject('起始时间应早于截止时间');
                          }

                          if (endTime.isAfter(executeTime)) {
                            return Promise.reject('起始时间和截止时间应早于执行时间');
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <StatisticsTimePicker statisticalCycle={statisticalCycle} executeCycle={executeCycle} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </SubContent>
        {statisticalCycle !== ReportStrategyStatisticalCycleEnum.QUARTER &&
          statisticalType === ReportStrategyStatisticalTypeEnum.INSTANTANEOUS && (
            <SubContent title="取值方法">
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="computeType"
                    wrapperCol={{ span: 24 }}
                    label="瞬时量取值"
                    initialValue={ReportStrategyValues.MAXIMUM}
                    rules={[{ required: true, message: '请选择瞬时量取值' }]}
                  >
                    <Radio.Group>
                      <Radio value={ReportStrategyValues.MAXIMUM}>
                        {reportStrategyValuesDisplay[ReportStrategyValues.MAXIMUM]}
                      </Radio>
                      <Radio value={ReportStrategyValues.MINIMUM}>
                        {reportStrategyValuesDisplay[ReportStrategyValues.MINIMUM]}
                      </Radio>
                      <Radio value={ReportStrategyValues.AVERAGE}>
                        {reportStrategyValuesDisplay[ReportStrategyValues.AVERAGE]}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </SubContent>
          )}
        <Space className="sticky-footer" size={8} style={{ zIndex: 99 }}>
          <Button type="primary" onClick={onPreview}>
            预览
          </Button>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
        <Modal
          title="报表预览"
          footer={null}
          destroyOnClose
          open={!!previewData}
          size="big"
          onOk={closePreviewModal}
          onCancel={closePreviewModal}
        >
          <ReportStrategyPreview value={previewData} />
        </Modal>
      </Form>
    </Wrapper>
  );
};

export default CreateReportStrategy;
