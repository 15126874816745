import { Modal, ShowInput } from '@maxtropy/components';
import { Form, Button as AntdButton, Col, Row, FormInstance, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Select, Button, PopConfirm, InputNumber, Table } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { v4 as uuidv4 } from 'uuid';
import type { SelectProps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  apiV2CarbonFootprintCalculateAddFillPost,
  apiV2CarbonFootprintCalculateFillDetailPost,
  V2CarbonFootprintEntryListPostResponse,
  apiV2CarbonFootprintEntryListPost,
  apiV2CarbonFootprintCalculateReAutoFillPost,
} from '@maxtropy/device-customer-apis-v2';
import { cloneDeep, isNil } from 'lodash-es';
import { Procedure } from '../../components/AddFilling/const';
import debounce from 'lodash/debounce';

export type entries = {
  id?: number;
  entryNumber?: string;
  supplierName?: string;
  availableQuantity?: number;
  actualQuantity?: number;
  unitCode?: string;
};
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
  form: FormInstance;
  fillingData?: Procedure;
  id: number;
  fillingFlag?: boolean;
  materialOrEnergyId?: number;
}
const FillingModal: React.FC<Props> = ({
  open,
  setOpen,
  updateFn,
  fillingData,
  form,
  id,
  materialOrEnergyId,
  fillingFlag,
}) => {
  const [serachData, setSerachData] = useState<SelectProps['options']>([]);
  const [originData, setOriginData] = useState<V2CarbonFootprintEntryListPostResponse['list']>();
  const [totalActualQuantity, setTotalActualQuantity] = useState<number>(0);
  const [actual, setActual] = useState<entries[]>([]);
  const [detailEntires, setDetailEntires] = useState<entries[]>([]);
  const [isChange, setIsChange] = useState<boolean>(false);

  useEffect(() => {
    if (fillingFlag && serachData && id) {
      // 获取所有单位
      apiV2CarbonFootprintCalculateFillDetailPost({ id: Number(id) }).then((res: any) => {
        form.setFieldsValue({ entries: res?.entries });
        setActual(isNil(res?.entries) ? [] : res?.entries);
        setDetailEntires(isNil(res?.entries) ? [] : res?.entries);
        const totalActualQuantity = res?.entries?.reduce(
          (acc: number, item: entries) => acc + (item?.actualQuantity || 0),
          0
        );
        setTotalActualQuantity(isNil(totalActualQuantity) ? 0 : totalActualQuantity);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillingFlag]);

  useEffect(() => {
    changeSearchData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computedTotal = () => {
    const entries = form.getFieldsValue().entries;
    const cloneEntries = cloneDeep(entries);
    const totalActualQuantity = cloneEntries?.reduce(
      (acc: number, item: entries) => acc + (isNil(item.actualQuantity) ? 0 : Number(item.actualQuantity)),
      0
    );
    setTotalActualQuantity(totalActualQuantity);
  };

  const changeSearchData = debounce((value: string) => {
    apiV2CarbonFootprintEntryListPost({ materialId: materialOrEnergyId, name: value }).then(res => {
      const entries = form.getFieldsValue().entries;
      const cloneEntries = cloneDeep(entries);

      const existingEntryNumbers = cloneEntries.map((item: entries) => item.entryNumber);
      const mapList = res.list
        ?.filter(i => !existingEntryNumbers.includes(i.entryNumber) && !existingEntryNumbers.includes(i.id))
        .map(i => ({
          label: `${i.entryNumber}`,
          value: i.id,
        }));
      console.log(mapList);
      setSerachData(mapList ?? []);
      setOriginData(res.list ?? []);
    });
  }, 300);

  const columns: ColumnsType<entries> = [
    {
      title: '入库单',
      dataIndex: 'entryNumber',
      render(value, record, index) {
        return (
          <Form.Item
            name={['entries', index, 'entryNumber']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <Select
              options={serachData}
              onSearch={value => {
                changeSearchData(value);
              }}
              optionFilterProp="label"
              showSearch
              onChange={value => {
                const entries = form.getFieldsValue().entries;
                const cloneEntries = cloneDeep(entries);
                const findData = originData?.find((i: any) => i.id === value);
                const newFilling = [...cloneEntries];
                const newActual = [...cloneEntries];
                newFilling[index] = {
                  ...cloneEntries[index],
                  availableQuantity: findData?.remainingQuantity,
                  supplierName: findData?.supplierName,
                  unitCode: findData?.unitCode,
                  carbonFootPrint: findData?.carbonFootPrint,
                };
                form.setFieldsValue({ entries: newFilling });
                newActual[index] = {
                  ...cloneEntries[index],
                  availableQuantity: findData?.remainingQuantity,
                  supplierName: findData?.supplierName,
                  unitCode: findData?.unitCode,
                  carbonFootPrint: findData?.carbonFootPrint,
                  actualQuantity: 0,
                };
                setActual(newActual);
                changeSearchData('');
              }}
              onFocus={() => {
                changeSearchData('');
              }}
            ></Select>
          </Form.Item>
        );
      },
    },
    {
      title: '供应商',
      dataIndex: 'supplierName',
      render(value, record, index) {
        return (
          <Form.Item name={['entries', index, 'supplierName']} style={{ marginBottom: 0 }}>
            <ShowInput></ShowInput>
          </Form.Item>
        );
      },
    },
    {
      title: '可用数量',
      render(value, record, index) {
        return (
          <>
            <Space>
              <Form.Item name={['entries', index, 'availableQuantity']} style={{ marginBottom: 0 }}>
                <ShowInput />
              </Form.Item>
              <Form.Item name={['entries', index, 'unitCode']} style={{ marginBottom: 0 }}>
                <ShowInput />
              </Form.Item>
            </Space>
          </>
        );
      },
    },
    {
      title: '实际用量',
      dataIndex: 'actualQuantity',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['entries', index, 'actualQuantity']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入数字', required: true },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('实际用量必须大于0');
                    }
                    if (!isNil(actual)) {
                      if (value - actual?.[index]?.actualQuantity! > record?.availableQuantity!) {
                        return Promise.reject('实际用量不能超过可用数量');
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                addonAfter={record.unitCode ?? '--'}
                placeholder="请输入"
                onChange={() => {
                  computedTotal();
                }}
                style={{ width: '100%' }}
              ></InputNumber>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '操作',
      ellipsis: true,
      width: '10%',
      render(value, record, index) {
        return (
          <>
            {!isNil(record.entryNumber) ? (
              <PopConfirm
                placement="top"
                title={'您是否确认移除?'}
                onConfirm={() => {
                  onDel(record);
                  changeSearchData('');
                }}
              >
                <Button type="link" disabled={index === 0}>
                  移除
                </Button>
              </PopConfirm>
            ) : (
              <Button
                type="link"
                disabled={index === 0}
                onClick={() => {
                  onDel(record);
                }}
              >
                移除
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const onDel = (record: entries) => {
    const prevEntries = form.getFieldValue('entries');
    const removeId = prevEntries.findIndex((item: entries) => {
      return item.id === record.id;
    });
    prevEntries.splice(removeId, 1);
    form.setFieldsValue({ entries: prevEntries });
    computedTotal();
  };

  const onFinish = () => {
    form.validateFields().then(res => {
      if (isNil(res.entries) || res.entries.lentgh === 0) {
        Modal.info({
          content: '数据填报不能为空',
        });
        return;
      }
      const entryNumbers = new Set();
      for (const entry of res.entries) {
        if (entryNumbers.has(entry.entryNumber)) {
          Modal.warning({
            content: '入库单不能重复',
          });
          return;
        }
        entryNumbers.add(entry.entryNumber);
      }

      const threoeticalQuantity =
        res.theoreticalQuantity.split(' ')[0] === '--' ? 0 : Number(res.theoreticalQuantity.split(' ')[0]);

      const contentMessage =
        totalActualQuantity! > threoeticalQuantity
          ? '实际使用量大于理论用量，请确认数据填报是否正确。'
          : '实际使用量小于理论用量，请确认数据填报是否正确。';
      const newEntries = res.entries.map((item: entries) => ({
        id: typeof item.entryNumber === 'number' ? item.entryNumber : findIdFromStringArray(item?.entryNumber!),
        quantity: item.actualQuantity,
      }));
      const params = {
        calculateInputOrOutputId: fillingData?.id,
        type: fillingData?.isInput,
        actualQuantity: totalActualQuantity,
        autoGatherFlag: 0,
        entries: newEntries,
      };

      if (totalActualQuantity! === threoeticalQuantity) {
        setOpen(false);
        apiV2CarbonFootprintCalculateAddFillPost({ ...params }).then(result => {
          updateFn();
        });

        form.setFieldsValue({ entries: [{ id: uuidv4() }] });
        changeSearchData('');
      } else {
        Modal.confirm({
          content: contentMessage,
          onOk: () => {
            setOpen(false);
            apiV2CarbonFootprintCalculateAddFillPost({ ...params }).then(result => {
              updateFn();
            });
            form.setFieldsValue({ entries: [{ id: uuidv4() }] });
            changeSearchData('');
          },
        });
      }
    });
  };

  const findIdFromStringArray = (str: string) => {
    const entry = detailEntires?.find(item => item.entryNumber === str);
    const id = entry ? entry.id : null;
    return id;
  };

  const addRow = () => {
    changeSearchData('');
    const newRow = {
      id: uuidv4(),
    };
    const prevEntries = form.getFieldValue('entries') || [];
    prevEntries.push(newRow);
    form.setFieldsValue({ entries: prevEntries });
  };

  const reAutoFill = () => {
    Modal.confirm({
      title: '是否确认重新自动填报，重新自动填报后当前填报的数据将丢失。',
      onOk: () => {
        apiV2CarbonFootprintCalculateReAutoFillPost({ id: fillingData?.id }).then(res => {
          form.setFieldsValue({ entries: res?.entries });
          setActual(isNil(res?.entries) ? [] : res?.entries);
          setDetailEntires(isNil(res?.entries) ? [] : res?.entries);
          const totalActualQuantity = res?.entries?.reduce(
            (acc: number, item: entries) => acc + (item?.actualQuantity || 0),
            0
          );
          setTotalActualQuantity(isNil(totalActualQuantity) ? 0 : totalActualQuantity);
        });
      },
    });
  };

  return (
    <Modal
      size="big"
      title="填报"
      open={open}
      onOk={onFinish}
      onCancel={() => {
        form.setFieldsValue({ entries: [{ id: uuidv4() }] });
        setOpen(false);
      }}
    >
      <div className={styles.content}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            entries: [
              {
                id: uuidv4(),
              },
            ],
          }}
          onValuesChange={() => setIsChange(true)}
        >
          <Row gutter={[24, 48]}>
            <Col span={8}>
              <Form.Item label="名称" labelAlign="left" name="name">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="理论用量" labelAlign="left" name="theoreticalQuantity">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="实际用量汇总" labelAlign="left">
                <ShowInput value={`${totalActualQuantity}${fillingData?.unitCode ?? '--'}`}></ShowInput>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item labelAlign="left" name="entries" style={{ marginBottom: 0 }} valuePropName="dataSource">
            <Table rowKey="id" columns={columns} pagination={false} scroll={{ y: 340 }} />
          </Form.Item>
          <AntdButton
            type="default"
            icon={<PlusOutlined />}
            className={styles.addRowBtn}
            onClick={() => {
              addRow();
            }}
          >
            添加
          </AntdButton>
        </Form>
        {!fillingData?.autoFillSupport ? (
          <div className={styles.autoInfo}>
            <span>
              {!isChange && (
                <span>
                  <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginRight: 8 }} />
                  填报数据由算法根据投料单自动生成
                </span>
              )}
            </span>
            <span>
              清除已填写内容,
              <Button type="link" style={{ padding: 0 }} onClick={reAutoFill}>
                重新自动填报
              </Button>
            </span>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default FillingModal;
