import React, { useEffect } from 'react';
import { Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  BaseInfoContent,
  ShowInput,
  SubContent,
  Tag,
  Upload,
} from '@maxtropy/components';
import styles from '../index.module.scss';
import { apiV2DeviceLedgerDetailPost } from '@maxtropy/device-customer-apis-v2';
import { EquipmentState, EquipmentStateDisplay, EquipmentStateTag } from '../util';
import dayjs from 'dayjs';

const EquipmentLedgerDetail: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    apiV2DeviceLedgerDetailPost({ id }).then(res => {
      form.setFieldsValue({
        ...res,
        ouNameList: res.ouNameList?.join('、'),
        purchaseTime: res.purchaseTime ? dayjs(res.purchaseTime).format('YYYY-MM-DD') : undefined,
        useTime: res.useTime ? dayjs(res.useTime).format('YYYY-MM-DD') : undefined,
        status: (
          <Tag border="solid" color={EquipmentStateTag[res?.status as EquipmentState]}>
            {EquipmentStateDisplay[res?.status as EquipmentState]}
          </Tag>
        ),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), { name: `设备台账详情` }]} className={styles.wrapper}>
      <FormTitle title={'设备台账详情'}></FormTitle>
      <SubContent>
        <Form form={form} layout="vertical">
          <BaseInfoContent title="基础信息" className="mb-8">
            <Form.Item label="资产设备名称" name="assetDeviceName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="资产设备SN/编码" name="assetDeviceSnCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="数采设备编号" name="deviceCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="所属组织" name="mcidName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="运营单元" name="ouNameList">
              <ShowInput />
            </Form.Item>
            <Form.Item label="状态" name="status">
              <ShowInput />
            </Form.Item>
            <Form.Item label="设备类型" name="typeName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="设备厂商" name="manufacturerName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="设备型号" name="physicalModelName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="设备图片" name="pic">
              <Upload disabled />
            </Form.Item>
          </BaseInfoContent>
          <BaseInfoContent title="采购/使用信息" className="mb-8">
            <Form.Item label="购入日期" name="purchaseTime">
              <ShowInput />
            </Form.Item>
            <Form.Item label="购入原值" name="purchasePrice">
              <ShowInput />
            </Form.Item>
            <Form.Item label="使用日期" name="useTime">
              <ShowInput />
            </Form.Item>
            <Form.Item label="折旧年限" name="depreciationPeriod">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </SubContent>

      <Space className="sticky-footer" size={8}>
        <Button onClick={() => navigate(`/asset/equipment/ledger`)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default EquipmentLedgerDetail;
