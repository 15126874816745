import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Select, Table, message } from '@maxtropy/components';
import { Node } from '@antv/x6';
import { Spin } from 'antd';
import {
  apiV2HvacNodeDetailPost,
  apiV2HvacNodeDeviceListPost,
  apiV2HvacNodeIndicatorListPost,
  apiV2HvacNodeSavePost,
  apiV2HvacNodeUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { NodeType, NodeTypeDisplay, OperationType, OperationTypeDisplay, OptionItem } from '../utils';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { v4 as uuidv4 } from 'uuid';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';

export interface ModalProp {
  onCancel: () => void;
  onOk: (values: any) => void;
  operationType: OperationType;
  selectedNode?: Node<Node.Properties>;
  stationId?: string;
}

interface DataType {
  id: React.Key;
  indicatorId?: number;
  deviceId?: number;
}

const existIndicatorNodeTypes = [
  NodeType.COOLING_TOWER,
  NodeType.CHILLER,
  NodeType.COOLING_WATER_PUMP,
  NodeType.PRIMARY_CHILED_WATER_PUMP,
  NodeType.SECOND_CHILED_WATER_PUMP,
  NodeType.ICE_STORAGE_TANK,
  NodeType.HYGROTHERMOGROGH,
  NodeType.SUPPLY_WATER_PORT,
  NodeType.RETURN_WATER_PORT,
];

const NewNode = ({ onCancel, onOk, operationType, selectedNode, stationId }: ModalProp) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [indicatorList, setIndicatorList] = useState<OptionItem[]>([]);
  const nodeType = selectedNode?.getData()?.type as NodeType;
  const [deviceListMap, setDeviceListMap] = useState<Record<number, any>>();

  const existIndicator = useMemo(() => {
    if (isNil(selectedNode)) return false;
    return existIndicatorNodeTypes.some(k => Number(k) === Number(nodeType));
  }, [selectedNode, nodeType]);

  useEffect(() => {
    if (isNil(selectedNode)) return;
    if (!existIndicator) return;
    if (operationType === OperationType.ADD) {
      form.setFieldValue('list', [
        {
          id: uuidv4(),
          indicatorId: undefined,
          deviceId: undefined,
        },
      ]);
    }

    apiV2HvacNodeIndicatorListPost({ nodeType }).then(res => {
      setIndicatorList((res.list ?? []).map(k => ({ label: k.name!, value: k.id! })));
    });
  }, [selectedNode, existIndicator, nodeType, operationType, form]);

  useEffect(() => {
    (async () => {
      if (isNil(selectedNode)) return;
      if (operationType === OperationType.EDIT) {
        const { nodeId } = selectedNode.getData();
        setLoading(true);
        const res = await apiV2HvacNodeDetailPost({ nodeId });

        const indicatorIds = [...new Set((res?.indicators ?? []).map(k => k.indicatorId))];
        const tasks = indicatorIds.map(m => apiV2HvacNodeDeviceListPost({ indicatorId: m }));
        const vals = await Promise.all(tasks);
        const listMap = {} as Record<number, any>;
        indicatorIds.forEach((item, index) => {
          listMap[item!] = (vals[index].list ?? []).map(item => ({ label: item.name, value: item.id }));
        });
        setDeviceListMap({ ...listMap });
        form.setFieldsValue({
          code: res?.code,
          name: res?.name,

          list: (res?.indicators ?? []).map(k => ({
            id: uuidv4(),
            ...k,
          })),
        });
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, operationType]);

  const handleForm = () => {
    form.validateFields().then(values => {
      const params = {
        name: values.name,
        type: nodeType,
        stationId: stationId,
        indicators: (values.list ?? []).map((item: { indicatorId: any; deviceId: any }) => ({
          indicatorId: item.indicatorId,
          deviceId: item.deviceId,
        })),
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2HvacNodeSavePost(params)
          .then(res => {
            message.success('新增成功');
            onOk({ ...values, nodeId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }

      if (operationType === OperationType.EDIT) {
        const { nodeId } = selectedNode?.getData();
        apiV2HvacNodeUpdatePost({ ...params, id: nodeId })
          .then(res => {
            message.success('编辑成功');
            onOk({ ...values, nodeId });
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  const addRow = () => {
    const tableData = form.getFieldValue('list');

    const newRow = {
      id: uuidv4(),
      indicatorId: undefined,
      deviceId: undefined,
    };
    form.setFieldsValue({ list: tableData ? [...tableData, newRow] : [newRow] });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '指标名称',
      dataIndex: 'indicatorId',
      width: 160,
      render(_, record, index) {
        const list = form.getFieldValue('list');
        const selectedList = list.filter((_: any, i: number) => i !== index);
        const options = indicatorList.filter(
          m => !selectedList.some((k: { indicatorId: number }) => k.indicatorId === m.value)
        );
        return (
          <Form.Item
            name={['list', index, 'indicatorId']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请选择', required: true }]}
          >
            <Select
              placeholder="请选择监测类型"
              size="small"
              options={options}
              style={{ width: 150 }}
              onChange={val => {
                form.setFieldValue(['list', index, 'deviceId'], undefined);
                if (!deviceListMap?.[val]) {
                  apiV2HvacNodeDeviceListPost({ indicatorId: val }).then(res => {
                    setDeviceListMap({
                      ...deviceListMap,
                      [val]: (res.list ?? []).map(item => ({ label: item.name, value: item.id })),
                    });
                  });
                }
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '测量设备',
      dataIndex: 'deviceId',
      width: 220,
      render(_, record, index) {
        return (
          <Form.Item dependencies={[['list', index, 'indicatorId']]} noStyle>
            {({ getFieldValue }) => {
              let indicatorId = getFieldValue(['list', index, 'indicatorId']);
              const options = deviceListMap?.[indicatorId] ?? [];
              return (
                <Form.Item
                  name={['list', index, 'deviceId']}
                  style={{ marginBottom: 0 }}
                  rules={[{ message: '请选择', required: true }]}
                >
                  <Select placeholder="请选择设备" size="small" options={options} style={{ width: 200 }} />
                </Form.Item>
              );
            }}
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'option',
      width: 60,
      render: (_, record: DataType, index) => {
        return (
          <Button
            type="link"
            onClick={() => {
              const tableData = form.getFieldValue('list');

              tableData.splice(index, 1);
              form.setFieldsValue({ list: [...tableData] });
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={`${OperationTypeDisplay[operationType]}${NodeTypeDisplay[nodeType!]}节点`}
      contentClassName="modal-form-content"
      open
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          {operationType === OperationType.EDIT && (
            <Form.Item label="节点编号" name="code">
              <Input placeholder="请输入" disabled />
            </Form.Item>
          )}
          <Form.Item
            label="节点名称"
            name="name"
            rules={[
              { required: true, message: '请输入节点名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 10, message: '最多输入10个字符' },
            ]}
          >
            <Input placeholder="请输入节点名称" allowClear />
          </Form.Item>
          {existIndicator && (
            <>
              <Form.Item name="label" label="添加指标" style={{ marginBottom: 8 }}></Form.Item>
              <Form.Item name="list" noStyle valuePropName="dataSource">
                <Table size="middle" columns={columns} scroll={{ y: 280, x: 'max-content' }} />
              </Form.Item>
              <Button
                wrapClassName={styles.addRowBtn}
                className={styles.rowBtnInner}
                type="dashed"
                onClick={addRow}
                ghost
                icon={<PlusOutlined />}
              >
                添加指标
              </Button>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default NewNode;
