import { Button, EllipsisSpan, Table, Tag, Tooltip, useUpdate } from '@maxtropy/components';
import { Space } from 'antd';
import styles from './index.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import { useQuery } from '@/utils/utils';
import { apiV2HvacDashboardDetailGetAlarmListPost } from '@maxtropy/device-customer-apis-v2';
import { AlarmNode } from '../../utils/const';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';

interface FooterDrawerProps {
  stationId?: number;
  callbackAlarms?: (val: AlarmNode[]) => void;
}

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

export const columns = [
  {
    title: '节点名称',
    dataIndex: 'nodeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmLevel',
    ellipsis: { showTitle: true },
    width: 140,
    render: (v: AlarmLevel) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
            {AlarmLevelDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmInfo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警时间',
    dataIndex: 'alarmTime',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? dayjs(v).format(dateFormat) : '--'} />,
  },
];

const FooterDrawer: React.FC<FooterDrawerProps> = ({ stationId, callbackAlarms }) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [topLevelCount, setTopLevelCount] = useState<number>(0);
  const [highLevelCount, setHighLevelCount] = useState<number>(0);
  const [middleLevelCount, setMiddleLevelCount] = useState<number>(0);
  const [update, updateFn] = useUpdate();
  const timer = useRef<number>();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      updateFn();
    }, 60000);

    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const { data, isLoading } = useQuery(
    useCallback(() => {
      if (isNil(stationId)) return Promise.resolve([]);
      return apiV2HvacDashboardDetailGetAlarmListPost({
        id: stationId,
      }).then(res => {
        setTopLevelCount(res?.topLevelCount ?? 0);
        setHighLevelCount(res?.highLevelCount ?? 0);
        setMiddleLevelCount(res?.middleLevelCount ?? 0);
        const alarmNodes = (res?.alarms ?? [])
          .filter(m => !isNil(m.nodeId) && !isNil(m.nodeType))
          .map(k => ({ nodeId: k.nodeId!, nodeType: k.nodeType! }));
        callbackAlarms?.(alarmNodes);
        return res?.alarms ?? [];
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stationId, update])
  );

  // 表格操作栏
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 80,
      render: (record: any) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              if (isNil(record.deviceId)) return;
              window.open(`/device/alarm/record/detail/${record.id}`, '_blank');
            }}
          >
            详情
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.footerRow}>
      <div className={styles.rowWrapper} style={{ top: expand ? -160 : 0 }}>
        <div className={styles.expand} style={{ top: expand ? -24 : -25 }} onClick={() => setExpand(!expand)}>
          <div className={styles.desc}>
            {expand ? '收起' : '展开'}报警信息({data?.length ?? 0})
          </div>
          <DoubleRightOutlined style={{ marginLeft: 6, transform: expand ? 'rotate(90deg)' : 'rotate(270deg)' }} />
        </div>
        {expand && (
          <div className={styles.detailContent}>
            <div className={styles.leftSummary}>
              <div>
                <div>当前未恢复报警数</div>
                <div style={{ color: 'var(--mx-error-color)' }}>{data?.length ?? 0}</div>
              </div>
              <div className={styles.splitLine}></div>
              <div>
                <div>近30日报警总数</div>
                <div>
                  <Tooltip title="最高级">
                    <span className={styles.top}>{topLevelCount}</span>
                  </Tooltip>
                  /
                  <Tooltip title="高级">
                    <span className={styles.high}>{highLevelCount}</span>
                  </Tooltip>
                  /
                  <Tooltip title="中级">
                    <span className={styles.middle}>{middleLevelCount}</span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.rightTable}>
              <Table
                rowKey="id"
                sticky
                size="small"
                loading={isLoading}
                scroll={{ y: 120 }}
                dataSource={data}
                columns={buildColumns}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterDrawer;
