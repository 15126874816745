import {
  getSingleLineChart,
  getConsumptionChart,
  getCoolingWaterChart,
  getSingleLineAreaChart,
  getCOPChart,
  getInstantaneousFlowChart,
  getDoubleLineChart,
} from './charts';
import { DateBtn, DatePickerType, ParamsOption, SingleIndicatorType } from './type';

export interface IndicatorBtn {
  id: SingleIndicatorType;
  btnName?: string;
  unit?: string;
  getChartOption: (value: ParamsOption) => any;
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
}

// 每个指标的配置项
export const SingleIndicatorBtns = (): IndicatorBtn[] => {
  return [
    {
      // 冷水机组 - COP
      id: SingleIndicatorType.COP,
      getChartOption: getCOPChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },

    {
      // 冷水机组 - 用电量
      id: SingleIndicatorType.PowerConsumption1,
      getChartOption: getConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷水机组-制冷量
      id: SingleIndicatorType.CoolingCapacity,
      getChartOption: getConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷水机组-水温
      id: SingleIndicatorType.WaterTemperature,
      getChartOption: getCoolingWaterChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷水机组-瞬时流量
      id: SingleIndicatorType.InstantaneousFlow1,
      getChartOption: getInstantaneousFlowChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷水机组- 压力
      id: SingleIndicatorType.Pressure1,
      getChartOption: getDoubleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷水机组- 电荷负载量
      id: SingleIndicatorType.CurrentLoad,
      getChartOption: getSingleLineAreaChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷却塔- 用电量
      id: SingleIndicatorType.PowerConsumption2,
      getChartOption: getConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷却塔-风机频率
      id: SingleIndicatorType.FanFrequency,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 循环泵-用电量
      id: SingleIndicatorType.PowerConsumption3,
      getChartOption: getConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 循环泵-瞬时流量
      id: SingleIndicatorType.InstantaneousFlow2,
      getChartOption: getSingleLineAreaChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },

    {
      // 循环泵-频率
      id: SingleIndicatorType.Frequency,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 蓄冰罐-温度
      id: SingleIndicatorType.Temperature1,
      getChartOption: getDoubleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 蓄冰罐-瞬时流量
      id: SingleIndicatorType.InstantaneousFlow3,
      getChartOption: getDoubleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 蓄冰罐-液位
      id: SingleIndicatorType.LiquidLevel,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },

    {
      // 温湿度- 温度
      id: SingleIndicatorType.Temperature2,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 温湿度- 湿度
      id: SingleIndicatorType.Humidity,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 回水端口- 温度
      id: SingleIndicatorType.Temperature3,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 回水端口-压力
      id: SingleIndicatorType.Pressure2,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 回水端口-瞬时流量
      id: SingleIndicatorType.InstantaneousFlow4,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 回水端口-累计流量
      id: SingleIndicatorType.CumulativeTraffic,
      getChartOption: getSingleLineChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
  ];
};
