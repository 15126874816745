import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Table,
  Paging,
  usePaging,
  useBreadcrumbRoutes,
  Button,
  EllipsisSpan,
  SubContent,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, Form, Space, Row } from 'antd';
import {
  getSaleAccountVoucher,
  getSaleVoucherTransactionPage,
  SaleVoucherTransactionVo,
  SaleVoucherVo,
  TransactionType,
  TransactionTypeDisplay,
  TransactionTypeOperator,
} from '../../../api/sale';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ColumnType } from 'antd/es/table';

const columns: ColumnType<SaleVoucherTransactionVo>[] = [
  {
    title: '交易类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: TransactionType) => <EllipsisSpan value={TransactionTypeDisplay[v]} />,
  },
  {
    title: '说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '金额变化',
    dataIndex: 'amount',
    ellipsis: { showTitle: true },
    render: (v: number, record) => (
      <EllipsisSpan
        value={(v ?? undefined) !== undefined ? `${TransactionTypeOperator[record.type]}${v}元` : undefined}
      />
    ),
  },
  {
    title: '时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const VoucherDetail: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();

  const routes = useMemo(() => {
    return [{ name: '凭证', path: `/operation/client-related/client-account/voucher/${id}` }, { name: '凭证使用明细' }];
  }, [id]);

  const navigate = useNavigate();

  const [data, setData] = useState<SaleVoucherVo>();

  const [voucherData, setVoucherData] = useState<SaleVoucherTransactionVo[]>([]);
  const pagingInfo = usePaging(10);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 分页数据
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  useEffect(() => {
    if (id) {
      setLoading(true);
      getSaleVoucherTransactionPage({
        saleVoucherId: id,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        setVoucherData(res.list);
        setTotalCount(res.total);
      });
    }
  }, [pageOffset, pageSize, id, setTotalCount]);

  useEffect(() => {
    if (id) {
      getSaleAccountVoucher(id).then(setData);
    }
  }, [pageOffset, pageSize, id, setTotalCount]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        specifiedDate: dayjs(data.specifiedDate).format('YYYY-MM-DD HH:mm:ss'),
        balance: (data.balance ?? undefined) !== undefined ? `${data.balance}元` : undefined,
      });
    }
  }, [data, form]);

  const goList = useCallback(() => {
    if (data) {
      navigate(`/operation/client-related/client-account/voucher/${data.saleClientAccountId}`);
    }
  }, [data, navigate]);

  return (
    <>
      <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
        <FormTitle title={routes[1]?.name ?? ''} />
        <SubContent className="mb-8">
          <Form layout="vertical" className={styles.accountInfo} form={form}>
            <Row>
              <Col span={8}>
                <Form.Item name="name" label="凭证名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="specifiedDate" label="凭证收款日期">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="balance" label="凭证剩余可用金额">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </SubContent>
        <SubContent>
          <Table sticky loading={loading} dataSource={voucherData} columns={columns} />
          <Paging pagingInfo={pagingInfo} />
        </SubContent>
        <Space className="sticky-footer-c">
          <Button onClick={goList}>返回</Button>
        </Space>
      </Wrapper>
    </>
  );
};

export default VoucherDetail;
