import PowerLossImg from './images/powerLoss.png';
import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {}

const PowerLoss: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapperContent} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <img style={{ width: '100%' }} src={PowerLossImg} alt="" />
    </Wrapper>
  );
};

export default PowerLoss;
