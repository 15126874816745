import { RawData } from '@/api/history';
import dayjs from 'dayjs';

// 时间切片
export const createSplitTime = (data?: RawData[]) => {
  let gap = null; // 计算时间间隔, 显示完整的一天
  if (!data) return [];
  data = data.sort((a, b) => a.time! - b.time!); // 按时间正序排列，若为倒序下面会出现死循环
  if (data && data.length > 1) {
    gap = dayjs(data[1].time).diff(data[0].time, 'm') || 30;
    let startTime = dayjs(data[0].time).startOf('date');
    let endTime = dayjs(data[0].time).endOf('date');
    let insertBeforeTime = dayjs(data[0].time).subtract(gap, 'm');
    let insertAfterTime = dayjs(data[data.length - 1].time).add(gap, 'm');
    let canUnshift = insertBeforeTime.isAfter(startTime);
    let canPush = insertAfterTime.isBefore(endTime);
    while (canUnshift) {
      data.unshift({
        time: insertBeforeTime.valueOf(),
        value: null,
        updateTime: null,
      });
      insertBeforeTime = dayjs(data[0].time).subtract(gap, 'm');
      canUnshift = insertBeforeTime.isAfter(startTime);
    }
    while (canPush) {
      data.push({
        time: insertAfterTime.valueOf(),
        value: null,
        updateTime: null,
      });
      insertAfterTime = dayjs(data[data.length - 1].time).add(gap, 'm');
      canPush = insertAfterTime.isBefore(endTime);
    }
  }
  return data;
};
