import { Table } from '@maxtropy/components';
import { TableColumnsType } from 'antd';
import { TableDataItem } from '../RightPlanContent';

interface MediumTableProps {
  columns: TableColumnsType<TableDataItem>;
  tableData: TableDataItem[];
  loading?: boolean;
}

const MediumTable: React.FC<MediumTableProps> = props => {
  const { columns, tableData, loading } = props;

  return (
    <div
      style={{
        height: `calc(100vh - 450px)`,
        minWidth: 535,
        overflow: 'auto',
      }}
    >
      <Table rowKey="key" loading={loading} columns={columns} dataSource={tableData} scroll={{ x: 1300 }} />
    </div>
  );
};
export default MediumTable;
