import { DataPointMirrorDetail, getDataPointMirrorDetail, ReceiveGatewayItem } from '@/api/netWorking';
import { Button, EllipsisSpan, Form, Modal, ShowInput, Table } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
interface Iprops {
  visible?: boolean;
  close?: () => void;
  mirrorId?: number;
}
const columns: ColumnsType<ReceiveGatewayItem> = [
  {
    title: '数据接收网关名称',
    dataIndex: 'receiveGatewayName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '数据接收网关编号',
    dataIndex: 'receiveGatewayNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];
const formLayout = {
  labelCol: { style: { width: 140 } },
};
const DataGatewayModal: React.FC<Iprops> = ({ visible, close, mirrorId }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detail, setDetail] = useState<DataPointMirrorDetail>();
  const [form] = Form.useForm();
  useEffect(() => {
    setOpenModal(!!visible);
  }, [visible]);
  useEffect(() => {
    if (visible && mirrorId) {
      getDataPointMirrorDetail(mirrorId).then(setDetail);
    }
  }, [visible, mirrorId]);
  return (
    <>
      <Modal
        open={openModal}
        size="large"
        title="数据接收网关"
        onCancel={() => close?.()}
        footer={
          <Button type="primary" onClick={() => close?.()}>
            关闭
          </Button>
        }
      >
        <Form form={form} labelAlign="left" {...formLayout}>
          <Row>
            <Col span={12}>
              <Form.Item label="数据发送网关编号">
                <ShowInput value={detail?.sendGatewayNo} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="数据发送网关名称">
                <ShowInput value={detail?.sendGatewayName} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="设备名称">
                <ShowInput value={detail?.deviceName} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="数据属性">
                <ShowInput value={detail?.dataPropertyName} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          rowKey="id"
          scroll={{ y: 800 }}
          pagination={false}
          sticky
          columns={columns}
          dataSource={detail?.receiveGatewayList}
        />
      </Modal>
    </>
  );
};

export default DataGatewayModal;
