import { register } from '@antv/x6-react-shape';
import { Graph } from '@antv/x6/es';
import EnergyUnitNode from '../components/EnergyUnitNode';
import ProcessNode from '../components/ProcessNode';
import WorkCenterNode from '../components/WorkCenterNode';
import ProductionBaseNode from '../components/ProductionBaseNode';
import WorkStationNode from '../components/WorkStation';
import { commonNodeSize, NodeType } from './const';
export default function () {
  registerRightPortsLayout();
  registerUnitNode();
  registerWorkStationNode();
  registerProcessNode();
  registerWorkCenterNode();
  registerProductionBaseNode();
}

// 右侧连接桩的布局，间隔8，靠右，居中
function registerRightPortsLayout() {
  Graph.registerPortLayout(
    'custom-right',
    (portsPositionArgs, elemBBox) => {
      return portsPositionArgs.map((_, index) => {
        return {
          position: {
            x: elemBBox.width,
            y: elemBBox.height / 2 + (index - (portsPositionArgs.length - 1) / 2) * (8 + 8),
          },
          angle: 0,
        };
      });
    },
    true
  );
}

function registerUnitNode() {
  register({
    shape: NodeType.EnergyUnitNode,
    height: commonNodeSize.height,
    width: commonNodeSize.width,
    effect: ['data'],
    component: EnergyUnitNode,
    ports: {
      groups: {
        left: getPortsGroupObj('rgba(119, 202, 78, 1)', 'left'),
      },
    },
  });
}

function registerWorkStationNode() {
  register({
    shape: NodeType.WorkStationNode,
    height: commonNodeSize.height,
    width: commonNodeSize.width,
    effect: ['data'],
    component: WorkStationNode,
    ports: {
      groups: {
        left: getPortsGroupObj('#DDAA43', 'left'),
        right: getPortsGroupObj('#DDAA43', 'custom-right'),
      },
    },
  });
}

function registerProcessNode() {
  register({
    shape: NodeType.ProcessNode,
    height: 48,
    width: 180,
    effect: ['data'],
    component: ProcessNode,
    ports: {
      groups: {
        left: getPortsGroupObj('rgba(181, 124, 252, 1)', 'left'),
        right: getPortsGroupObj('rgba(181, 124, 252, 1)', 'custom-right'),
        top: getPortsGroupObj('rgba(181, 124, 252, 1)', 'top'),
        bottom: getPortsGroupObj('rgba(181, 124, 252, 1)', 'bottom'),
      },
    },
  });
}

function registerWorkCenterNode() {
  register({
    shape: NodeType.WorkCenterNode,
    height: 48,
    width: 180,
    effect: ['data'],
    component: WorkCenterNode,
    ports: {
      groups: {
        left: getPortsGroupObj('rgba(109, 175, 252, 1)', 'left'),
        right: getPortsGroupObj('rgba(109, 175, 252, 1)', 'custom-right'),
        top: getPortsGroupObj('rgba(109, 175, 252, 1)', 'top'),
        bottom: getPortsGroupObj('rgba(109, 175, 252, 1)', 'bottom'),
      },
    },
  });
}

function registerProductionBaseNode() {
  register({
    shape: NodeType.ProductionBaseNode,
    height: 48,
    width: 180,
    effect: ['data'],
    component: ProductionBaseNode,
    ports: {
      groups: {
        left: getPortsGroupObj('rgba(119, 202, 78, 1)', 'left'),
        right: getPortsGroupObj('rgba(119, 202, 78, 1)', 'custom-right'),
        top: getPortsGroupObj('rgba(119, 202, 78, 1)', 'top'),
        bottom: getPortsGroupObj('rgba(119, 202, 78, 1)', 'bottom'),
      },
    },
  });
}

function getPortsGroupObj(color: string, position: string) {
  return {
    markup: [
      {
        tagName: 'rect',
        selector: 'rect',
      },
    ],
    attrs: {
      rect: {
        magnet: true,
        fill: color,
        width: 8,
        height: 8,
        x: -4,
        y: -4,
      },
    },
    position: {
      name: position,
    },
  };
}
