import { InfoCircleOutlined } from '@ant-design/icons';
import {
  useBreadcrumbRoutes,
  Button,
  Wrapper,
  Form,
  FormTitle,
  SubContent,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import { Space, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { calMethodOptions } from '../CraftProcessCreate';
import styles from './index.module.scss';
import { CellView, Graph, Shape } from '@antv/x6';
import {
  apiV2ProcessFlowDetailPost,
  apiV2ProcessFlowProcedureListPost,
  V2ProcessFlowDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import noFlowIcon from '../../icons/noFlow@2x.png';
import ResizeObserver from 'rc-resize-observer';

const routes = [{ name: `查看工艺流程` }];

interface ProcessItem {
  name?: string;
  code?: string;
  id?: number;
}

const CraftProcessDetail = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const graph = useRef<Graph>();
  const [nodeList, setNodeList] = useState<any>([]); // 画布上的节点
  const [processList, setProcessList] = useState<ProcessItem[]>([]);
  const refContainer = useRef<HTMLDivElement>();
  const [data, setData] = useState<V2ProcessFlowDetailPostResponse>();

  useEffect(() => {
    apiV2ProcessFlowProcedureListPost({}).then(res => {
      setProcessList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (id) {
      apiV2ProcessFlowDetailPost({ id }).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        code: data.code,
        name: data.name,
        calMethodvalue: calMethodOptions.find(i => i.value === data.calMethod)?.label,
        workCenterId: (data.workCenters ?? []).map(i => i.name).join(','),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isNil(data) || !processList.length) return;
    graph.current = new Graph({
      container: refContainer.current!,
      panning: true,
      mousewheel: true,
      connecting: {
        router: 'manhattan',
        connector: {
          name: 'normal',
          args: {
            radius: 8,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },

        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#B57CFC',
                strokeWidth: 2,
                strokeDasharray: 6,
                targetMarker: 'classic',
                class: styles.antLine,
              },
            },
            zIndex: 0,
          });
        },
      },
      highlighting: {
        magnetAdsorbed: {
          name: 'stroke',
          args: {
            attrs: {
              fill: '#B57CFC',
              stroke: '#B57CFC',
            },
          },
        },
      },
      interacting: function (cellView: CellView) {
        return false;
      },
    });
    // 回显
    if (data?.nodes) {
      const nodeJson = JSON.parse(data?.nodes);
      graph.current.fromJSON(nodeJson as any);

      const nodes = graph.current?.getNodes();
      nodes.forEach(node => {
        const { processId } = node.getData();
        const processItem = processList?.find(k => k.id === processId);
        if (processItem) {
          node.updateData({ processId: processItem.id, label: processItem.name });
        }
      });
      setNodeList(nodes);
    }
  }, [data, processList]);

  const onNavigateToList = () => {
    navigate('/productionEnergy/craftProcess/management');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperStyles}>
      <FormTitle title={'查看工艺流程'}></FormTitle>
      <SubContent>
        <Form isOrigin form={form}>
          <BaseInfoContent layout="horizontal">
            <Form.Item
              label="工艺流程编码"
              name="code"
              required
              tooltip={{
                placement: 'topRight',
                title: '编码在生产日计划被使用后不可被修改',
                icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
              }}
            >
              <ShowInput />
            </Form.Item>
            <Form.Item label="工艺流程名称" required name="name">
              <ShowInput />
            </Form.Item>
            <Form.Item
              label="单耗计算方式"
              name="calMethodvalue"
              required
              tooltip={{
                title:
                  '每个工序的产出物跟最终产出物是1:1的关系使用单工序平均法；只要有工序产出物和最终产出物不是1:1关系则使用总能耗平均法',
                icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
              }}
            >
              <ShowInput />
            </Form.Item>
            <Form.Item label="工作中心" name="workCenterId" rules={[{ required: true }]}>
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>

          <Form.Item label="工艺流程图" required style={{ margin: '16px 0 12px' }}></Form.Item>
          <ResizeObserver
            onResize={({ width }) => {
              graph.current?.resize(width);
            }}
          >
            <div className={styles.container}>
              <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
              {nodeList.length ? null : (
                <div className={styles.noMap}>
                  <img className={styles.img} src={noFlowIcon} alt="" />
                  <div className={styles.desc}>暂无工艺流程图</div>
                </div>
              )}
            </div>
          </ResizeObserver>

          <Row style={{ marginTop: 32 }}>
            <Space size={10}>
              <Button onClick={() => onNavigateToList()}>返回</Button>
            </Space>
          </Row>
        </Form>
      </SubContent>
    </Wrapper>
  );
};

export default CraftProcessDetail;
