import React, { useState } from 'react';

import { GatewayStatus } from '@/shared/types';
import { Gateway } from '../../../api/gateway';
import styles from './index.module.scss';
import { DistributeStatus } from '@/api/netWorking';
import { Button } from '@maxtropy/components';

export interface OperationProps {
  record: Gateway;
  applyGateway: (id: number) => void;
}

const Operation: React.FC<OperationProps> = ({ record, applyGateway }) => {
  const [applying, setApplying] = useState<boolean>(false);

  // 配置下发
  const onApply = async (record: Gateway) => {
    setApplying(true);
    await applyGateway(record.id);
    setApplying(false);
  };
  return (
    <Button
      type="link"
      disabled={
        record?.edgeNetwork?.distributeStatus === DistributeStatus.NOTSTART ||
        record?.edgeNetwork?.distributeStatus === DistributeStatus.STARTING ||
        record.state === GatewayStatus.DISABLE ||
        applying
      }
      onClick={() => onApply(record)}
    >
      {applying ? (
        <div>正在下发</div>
      ) : (
        <div
          className={
            record.state === GatewayStatus.DISABLE ||
            !!!record.configUpdated ||
            record?.edgeNetwork?.distributeStatus === DistributeStatus.NOTSTART ||
            record?.edgeNetwork?.distributeStatus === DistributeStatus.STARTING
              ? styles.txt
              : styles.danger
          }
        >
          配置下发
        </div>
      )}
    </Button>
  );
};

export default Operation;
