import { EllipsisSpan } from '@maxtropy/components';
import { TopologyTypeFormat, TopologyType } from './EditEnergy';

// 主页表格
export const energyConsumptionColumns = [
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类别',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '对应拓扑类型',
    dataIndex: 'uetTopologyType',
    ellipsis: { showTitle: true },
    render: (v: TopologyType) => <EllipsisSpan value={TopologyTypeFormat[v]} />,
  },
  {
    title: '对应节点',
    width: 206,
    dataIndex: 'uetTopologyNodeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

// 拓扑节点编辑公用
export const editEnergyConsumptionColumns = [
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface TopologyTypeListType {
  id?: number | undefined;
  name?: string | undefined;
}

export interface UetTopologyNodeInfoType {
  uetId?: number;
  uetTopologyType?: number;
  uetTopologyNodeId?: number;
  uetTopologyRootNodeId?: number;
  uetTopologyNodeType?: string;
  uetTopologyNodeName?: string;
  topologyName?: string;
}

export interface DeviceListType {
  id?: number;
  deviceId?: number;
  meterTypeParentId?: number;
  meterTypeParentName?: string;
  uetTopologyType?: number;
  name?: string;
  // 类型 1 能耗设备 2 剔除设备 3光伏站并网点 4储能站
  type?: UnitConfGroupDeviceType;
  // 计量类别	id
  meterTypeId?: number;
  // 计量属性	id
  uetTopologyNodeName?: string;
  meterAttributeId?: number;
  uetId?: number;
  uetTopologyNodeId?: number;
  uetTopologyNodeType?: string;
  uetTopologyRootNodeId?: number;
  topologyName?: string;
}

export enum UnitConfGroupDeviceType {
  // 能耗设备
  ENERGY_CONSUMPTION = 1,
  // 剔除设备
  REMOVE = 2,
  // 光伏站并网点
  PV_Grid = 3,
  // 储能站
  BSA = 4,
}

// 用能单元详情设备展示
export const UnitConfGroupDeviceTypeDisplay = {
  [UnitConfGroupDeviceType.ENERGY_CONSUMPTION]: '能耗设备',
  [UnitConfGroupDeviceType.REMOVE]: '剔除设备',
  [UnitConfGroupDeviceType.PV_Grid]: '光伏站并网点',
  [UnitConfGroupDeviceType.BSA]: '储能站',
};
