import ReactEcharts from 'echarts-for-react';
import { isNil } from 'lodash-es';
import { EnergyAnalysisOptimizeEchartsRes } from '../EnergyComparison';
import dayjs from 'dayjs';
interface Props {
  data: EnergyAnalysisOptimizeEchartsRes;
  isShowRecent: boolean;
}

const color = [
  {
    '0%': '#FFE77D',
    '100%': '#FFCB47',
  },
  {
    '0%': '#92ED79',
    '100%': '#59D744 ',
  },
  {
    '0%': '#7DB2FF',
    '100%': '#477BFF',
  },
];

const getTs = () => {
  const ts = [];
  for (let i = 0; i < 1440; i = i + 15) {
    ts.push(i);
  }
  return ts;
};

const LineEcharts: React.FC<Props> = ({ data, isShowRecent }) => {
  const getSeries = () => {
    const res = [];
    if (data.energyBeforeOptimizationData && data.energyBeforeOptimizationData.length > 0) {
      res.push({
        name: '优化前用能',
        type: 'line',
        smooth: true,
        data: data.energyBeforeOptimizationData.map(i => (isNil(i.value) ? i.value : Number(i.value.toFixed(2)))) ?? [],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: color[0]['0%'], // 0% 处的颜色
              },
              {
                offset: 1,
                color: color[0]['100%'], // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        emphasis: {
          focus: 'series',
        },
        showSymbol: false,
      });
    }
    if (data.energyAfterOptimizationData && data.energyAfterOptimizationData.length > 0) {
      res.push({
        name: '优化后30日用能',
        type: 'line',
        smooth: true,
        data: data.energyAfterOptimizationData.map(i => (isNil(i.value) ? i.value : Number(i.value.toFixed(2)))) ?? [],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: color[1]['0%'], // 0% 处的颜色
              },
              {
                offset: 1,
                color: color[1]['100%'], // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        emphasis: {
          focus: 'series',
        },
        showSymbol: false,
      });
    }
    if (data.energyRecentlyData && data.energyRecentlyData.length > 0 && isShowRecent) {
      res.push({
        name: '近期用能',
        type: 'line',
        smooth: true,
        data: data.energyRecentlyData.map(i => (isNil(i.value) ? i.value : Number(i.value.toFixed(2)))) ?? [],
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: color[2]['0%'], // 0% 处的颜色
              },
              {
                offset: 1,
                color: color[2]['100%'], // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        },
        emphasis: {
          focus: 'series',
        },
        showSymbol: false,
      });
    }
    return res;
  };

  const getChartOption = () => {
    return {
      title: {
        text: data.energyMediumName,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 16,
          fontWeight: 600,
        },
        left: '3%',
      },
      tooltip: {
        trigger: 'axis',
        transitionDuration: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.5)',
          fontSize: 12,
          fontWeight: 500,
        },
        valueFormatter: (v: string) => `${isNil(v) ? '--' : v} ${data.defaultPhysicalUnitName}`,
      },
      legend: {
        right: 28,
        itemHeight: 8,
        itemWidth: 8,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        icon: 'rect',
      },
      grid: {
        left: '3%',
        right: 30,
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: getTs().map(i =>
          dayjs()
            .hour(Math.floor(i / 60))
            .minute(i % 60)
            .format('HH:mm')
        ),
      },
      yAxis: {
        type: 'value',
        name: data.defaultPhysicalUnitName,
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
        },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
      },
      series: getSeries(),
    };
  };
  return <ReactEcharts option={getChartOption()} notMerge lazyUpdate={false} style={{ height: 280 }} />;
};

export default LineEcharts;
