import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

// 填报类型
export enum FillingType {
  YEAR = 1,
  MONTH = 2,
}

// 新建或更新碳排计划
export interface CarbonEmissionMonthPlanDtosItem {
  emissionMonth: string; // 排放月
  emissionRights: number; // 碳排放权
  emissionReductionRatio: number; // 减排比例
  estimatedEmission: number; // 预计碳排放
}

export interface CarbonYearDisChargePlanBody {
  id?: Key;
  accountingUnitId: Key; // 核算单元id
  fillingYear: number; // 填报财年
  fillingType: FillingType; // 填报类型(1:按年 2:按月)
  emissionRights: number; // 碳排放权
  emissionReductionRatio?: number; // 减排比例
  totalEmission: number; // 总排放量
  totalReductedEmission: number; // 总减排放量
  carbonEmissionMonthPlanVos?: CarbonEmissionMonthPlanDtosItem[]; // 月度计划
}

export const saveCarbonYearDisChargePlan = (body: CarbonYearDisChargePlanBody) =>
  fetch(
    `/api/v2/carbon/emission-plan/save`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

// 删除计划
export const delCarbonYearDisChargePlan = (id: Key) =>
  fetch(
    `/api/v2/carbon/emission-plan/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 碳排放计划列表
export interface CarbonYearDisChargePlanListRes {
  id: Key;
  accountingUnitId: Key; // 核算单元id
  unitName: string; // 核算单元名称
  fillingYear: number; // 填报财年
  fillingType: FillingType; // 填报类型(1:按年 2:按月)
  tenantMcid: string;
  emissionRights: number; // 碳排放权
  emissionReductionRatio: number; // 减排比例
  totalEmission: number; // 总排放量
  createTime: string;
  updateTime: string;
  totalReductedEmission: number; // 总减排放量
  updateCorpUserName: string; // 最后操作人
  carbonEmissionMonthPlanVos: CarbonEmissionMonthPlanDtosItem[]; // 月度计划
}

export interface CarbonYearDisChargePlanListParams extends PageRequest {
  accountingUnitName?: string; // 核算单元名称
  fillingYear?: number; // 填报财年
}

export const getCarbonYearDisChargePlanList = (query: CarbonYearDisChargePlanListParams) =>
  fetch<PageResponse<CarbonYearDisChargePlanListRes>>(
    `/api/v2/carbon/emission-plan/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

// 碳排放计划详情
export const getCarbonYearDisChargePlanDetail = (id: Key) =>
  fetch<CarbonYearDisChargePlanListRes>(
    `/api/v2/carbon/emission-plan/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
