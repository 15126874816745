import { ExclamationCircleFilled } from '@ant-design/icons';
import { Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { columns } from './util';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { ElectricitySettlementRulesResponse } from '../../../../../api/electricitySettlementRules';
import { getElectricitySaleContractSettlementRuleList } from '../../../../../api/electricitySalesContract';
import { Button } from '@maxtropy/components';

export interface SettlementRulesTableProps {
  value?: any; // 暂时用any代替
  id: string; // id查询结算规则
}

const SettlementRulesTable: FC<SettlementRulesTableProps> = props => {
  const { id } = props;
  const [value, setValue] = useState<ElectricitySettlementRulesResponse[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getElectricitySaleContractSettlementRuleList(id).then(res => setValue(res.list ?? []));
    }
  }, [id]);

  // 查看结算规则详情
  const showSettlementRulesDetail = (id: string) => {
    navigate(`/operation/settlementRules/electricity/details/${id}`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: any) => {
        // 先暂时用any代替
        return (
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              showSettlementRulesDetail(record.id);
            }}
          >
            查看详情
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      sticky
      scroll={{ x: 1300, y: 400 }}
      columns={buildColumns}
      rowKey="id"
      dataSource={value ?? []}
      locale={{
        emptyText: (
          <>
            <ExclamationCircleFilled style={{ color: 'var(--mx-warning-color)', fontSize: 35, marginTop: '35px' }} />
            <div className="outer35">
              <span className="txt28">请添加结算规则，</span>
              <Button
                type="link"
                className="info29"
                onClick={() => navigate('/operation/settlementRules/electricity/create')}
              >
                点击前往
              </Button>
            </div>
          </>
        ),
      }}
    />
  );
};

export default SettlementRulesTable;
