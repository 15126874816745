import ReactEcharts from 'echarts-for-react';
import { StandardCoal_MediumListItem } from '@/api/plustekSankey';
import { isNil } from 'lodash-es';

interface Props {
  data: StandardCoal_MediumListItem[];
  physicalUnitName?: string;
}

const legendColors = [
  {
    '0%': '#8AF5FF',
    '100%': '#52E7FF',
  },
  {
    '0%': '#FF7DB2',
    '100%': '#FF477B',
  },
  {
    '0%': '#FFE77D',
    '100%': '#FFCB47',
  },
  {
    '0%': '#A87DFF',
    '100%': '#6F47FF',
  },
  {
    '0%': '#32F0C2',
    '100%': '#16DD8E',
  },
  {
    '0%': '#59C1FF',
    '100%': '#2D8DFF',
  },
];

const PieCharts: React.FC<Props> = ({ data, physicalUnitName }) => {
  const getChartOption = () => {
    return {
      tooltip: {
        // trigger: 'item',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          fontSize: 12,
          color: 'rgba(255,255,255,0.65)',
        },
        formatter: (e: any) => {
          return `
          <div style='display:flex;align-items:center;justify-content:center;' >
            <div style='width:12px;height:12px;background:linear-gradient(134deg, ${e.color.colorStops[0].color} 0%, ${
            e.color.colorStops[1].color
          } 100%);border-radius:50%;margin-right:8px'></div> 
            <div style='color: rgba(255,255,255,0.85);margin-right:15px;' >${
              isNil(e.value) ? '--' : `${Number(e.value).toFixed(2)}${physicalUnitName}`
            }</div>
            <div style='color: rgba(255,255,255,0.85);' >${
              isNil(e.data.rate) ? '--' : Number(e.data.rate).toFixed(2)
            }%</div>
          </div>
          `;
        },
      },
      legend: {
        orient: 'vertical',
        top: '244px',
        left: '10px',

        textStyle: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 22,
          width: 280,
          rich: {
            name: {
              // align: 'left',
              width: 110,
              color: 'rgba(255,255,255,0.65)',
              fontSize: 14,
            },
            b: {
              // align: 'right',
              width: 160,
              // padding: [0, 20, 0, 0],
              color: 'rgba(255,255,255,0.85)',
              fontSize: 14,
            },
            v: {
              // align: 'right',
              width: 30,
              color: 'rgba(255,255,255,0.85)',
              fontSize: 14,
            },
          },
        },
        formatter: (name: any) => {
          const item = data?.find(i => i.energyMediumName === name);
          return `{name|${name}} {b|${
            isNil(item) || isNil(item.standardCoal) ? '--' : `${item.standardCoal.toFixed(2)}${physicalUnitName}`
          }} {v|${isNil(item) || isNil(item.proportion) ? '--' : `${item.proportion.toFixed(2)}%`}}`;
        },
        data: data?.map(i => ({
          name: i.energyMediumName,
          icon: 'circle',
        })),
      },
      series: [
        {
          type: 'pie',
          top: '10px',
          left: '90px',
          radius: [70, 100],
          center: [98, 98],
          width: '200px',
          height: '200px',
          // avoidLabelOverlap: false, // 是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
          labelLine: {
            // 标签的视觉引导线配置。在 label 位置 设置为'outside'的时候会显示视觉引导线。
            show: false,
          },
          label: {
            show: false,
            // position: 'center',
          },
          itemStyle: {
            // borderRadius: 2,
            borderColor: '#232324',
            borderWidth: 2,
          },
          data: data?.map((i, index) => ({
            name: i.energyMediumName,
            value: i.standardCoal,
            rate: i.proportion,
            itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: legendColors[index]['0%'], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: legendColors[index]['100%'], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          })),
          minAngle: 2,
          emphasis: {
            label: {
              show: false,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          emptyCircleStyle: {
            color: '#3F3F3F',
          },
        },
      ],
    };
  };
  return (
    <ReactEcharts
      option={getChartOption()}
      notMerge
      lazyUpdate={false}
      style={{ height: '600px', width: '400px' }} // 样式的设置width与height
    />
  );
};

export default PieCharts;
