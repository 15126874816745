import { Form, Input, InputNumber, Modal, Select } from '@maxtropy/components';
import { useEffect, useMemo } from 'react';
import { PvInfoProp } from '../../../../../api/pv';
interface Iprops {
  visible: boolean;
  row?: PvInfoProp;
  ousList: OusItem[] | undefined;
  onCancel?: () => void;
  onConfirm?: (v: any) => void;
}
interface OusItem {
  id: number;
  name: string;
}
const NewPvModal: React.FC<Iprops> = ({ visible = false, ousList = [], onCancel, onConfirm, row }) => {
  const [form] = Form.useForm();
  const onFinish = async (v: { ouId: number; name: string }) => {
    form.resetFields();
    onConfirm!({
      ...v,
    });
  };
  const formLayout = {
    labelCol: { flex: '120px' },
  };
  const title = useMemo(() => {
    return row ? '编辑光伏站' : '新建光伏站';
  }, [row]);
  useEffect(() => {
    if (row && visible) {
      let ou = row.ou;
      form.setFieldsValue({ ...row, ouId: ou?.id });
    }
  }, [row, form, visible]);
  return (
    <>
      <Modal
        title={title}
        open={visible}
        contentClassName="modal-form-content"
        destroyOnClose
        onCancel={() => {
          onCancel && onCancel();
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form {...formLayout} form={form} onFinish={onFinish}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select disabled={row?.hasPutOn} placeholder="请选择">
              {ousList?.map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="光伏站名称"
            name="name"
            rules={[
              { required: true, message: '光伏站名称' },
              { max: 20, message: '最多20个字符' },
            ]}
          >
            <Input placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '装机容量' }]}>
            <InputNumber
              min={0.01}
              max={9999999999}
              precision={2}
              style={{ width: '100%' }}
              placeholder="请输入"
              addonAfter="kW"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewPvModal;
