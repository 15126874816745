import { fetch } from '@maxtropy/components';

export interface Organization {
  mcid: string;
  name: string;
  hasPermission: boolean;
}

export interface OrganizationResponse {
  data: Organization;
  children: OrganizationResponse[];
}

export interface OrganizationTreeData {
  code?: string;
  hasPermission?: boolean;
  levelName?: string;
  mcid: string;
  name?: string;
  canAddSub?: boolean;
  fullName?: string;
}

export interface OrganizationTreeProps {
  children: OrganizationTreeProps[];
  data?: OrganizationTreeData;
  parent?: number;
}

export const getOrganization = () =>
  fetch<OrganizationResponse>(
    `/api/v2/customer-user/organizationWithCodeWithCurrent`,
    {
      method: 'POST',
    },
    true
  );
