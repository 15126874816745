import { ColumnsType } from 'antd/es/table';
import { EdgeDeviceTemplatePoint } from '../../EdgeDevicePointInfo';
import React, { useContext, useMemo } from 'react';
import { BatchEdgeContent } from '../contentTypes';
import { ActionType, ActionTypeColors, DataPointType, VirtualPoint } from '../../../types';
import VirtualSpot from './Virtual';
import { Divider, Space } from 'antd';
import { PointContext, PointContextProps } from '../../EdgeDevicePointInfo/contextTypes';
import CollectionPoint from './Collection';
import { Button, PopConfirm } from '@maxtropy/components';

interface UpdateEdgePointProps {
  onUpdate: (values: any) => void;
  onAddIdentifier: (values: any) => void;
  onDelete: (values: any) => void;
}

const UpdateEdgePoint: React.FC<UpdateEdgePointProps> = props => {
  const { onUpdate, onAddIdentifier, onDelete } = props;
  const { setRow, editable } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointType, hasProperty, dataPropertyIds, parameter } = baseForm?.getFieldsValue();

  const showCollectionPoint = useMemo(
    () =>
      (hasProperty &&
        Array.isArray(dataPropertyIds) &&
        dataPropertyIds.length > 0 &&
        Array.isArray(parameter) &&
        parameter.length > 0) ||
      (!hasProperty && Array.isArray(parameter) && parameter.length > 0),
    [hasProperty, dataPropertyIds, parameter]
  );

  const showVirtualSpot = useMemo(
    () => (hasProperty && Array.isArray(dataPropertyIds) && dataPropertyIds.length > 0) || !hasProperty,
    [dataPropertyIds, hasProperty]
  );

  const stateColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作状态',
      dataIndex: 'actionType',
      fixed: 'right',
      width: 80,
      render: (v: ActionType) => (
        <div
          style={{
            width: 45,
            height: 20,
            textAlign: 'center',
            borderRadius: 10,
            fontSize: 12,
            color: ActionTypeColors[v].color,
            background: ActionTypeColors[v].background,
          }}
        >
          {v === ActionType.NONE ? '未编辑' : '已编辑'}
        </div>
      ),
    },
  ];

  const editVisible =
    (pointType === DataPointType.BASE_POINT && parameter?.includes('other')) || pointType !== DataPointType.BASE_POINT;

  const opColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作',
      width: 100,
      fixed: 'right',
      render: (_, record, index) => (
        <Space size={16}>
          {editVisible && (
            <Button
              type="link"
              onClick={() => {
                setRow?.(record);
              }}
            >
              编辑
            </Button>
          )}
          {editVisible && !hasProperty && <Divider type="vertical" />}
          {!hasProperty && (
            <PopConfirm
              okText="继续"
              placement="topRight"
              title={
                <>
                  <div>确定删除？</div>
                  <div style={{ color: '#f5222d' }}>
                    删除后不可恢复<span style={{ color: 'var(--disabled-color)' }}>，你还要继续吗？</span>
                  </div>
                </>
              }
              onConfirm={() => onDelete(record)}
            >
              <Button type="link">移除</Button>
            </PopConfirm>
          )}
        </Space>
      ),
    },
  ];

  const editColumns: any = [
    ...(editable && editVisible ? stateColumns : []),
    ...(editable && ((hasProperty && editVisible) || !hasProperty) ? opColumns : []),
  ];

  const renderContent = () => {
    if (pointType === DataPointType.BASE_POINT) {
      return showCollectionPoint ? (
        <CollectionPoint
          onUpdate={onUpdate}
          onAddIdentifier={onAddIdentifier}
          editColumns={editColumns}
          alterMode={true}
        />
      ) : undefined;
    } else if (pointType === DataPointType.VIRTUAL_POINT) {
      return showVirtualSpot ? (
        <VirtualSpot
          onUpdate={onUpdate}
          onAddIdentifier={onAddIdentifier}
          editColumns={editColumns as ColumnsType<VirtualPoint>}
          alterMode={true}
        />
      ) : undefined;
    } else {
      return undefined;
    }
  };

  return <>{renderContent()}</>;
};

export default UpdateEdgePoint;
