import { FC, useEffect, useState } from 'react';
import { Table, EllipsisSpan, Paging, usePaging, useUpdate, Button, Modal, Dropdown } from '@maxtropy/components';
import dayjs from 'dayjs';
import { OperatorDisplay, BatchDeviceTemplateStatus, BatchDeviceTemplateStatusDisplay, Operator } from '@/shared/types';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

import { getBatchDeviceTemplateList, BatchDeviceTemplate, deleteBatchDeviceTemplate } from '../../../api/batch';
import { SearchProps } from './util';

const columns = [
  {
    title: '导入模板编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板状态',
    dataIndex: 'templateStatus',
    ellipsis: { showTitle: true },
    render: (v: BatchDeviceTemplateStatus) => <EllipsisSpan value={BatchDeviceTemplateStatusDisplay[v]} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (value: string, record: BatchDeviceTemplate) => {
      return (
        <EllipsisSpan
          value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
        />
      );
    },
  },
];

const Template: FC<SearchProps> = ({ queryData, tabsKey }) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);

  const [list, setList] = useState<BatchDeviceTemplate[]>([]);

  const getList = () => {
    setLoading(true);
    getBatchDeviceTemplateList({ ...queryData, page: pageOffset, size: pageSize }).then(res => {
      if (res) setTotalCount(res.total);
      setList(res.list);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const onDelete = (id: number) => {
    Modal.confirm({
      title: '是否删除模板？',
      okText: '继续',
      onOk: () => {
        deleteBatchDeviceTemplate(id).then(res => {
          if (res) {
            update();
          }
        });
      },
    });
  };

  const onDownload = (record: BatchDeviceTemplate) => {
    if (record.existAttribute && record.existEdgeTemplate) {
      window.open(`/api/batch/import/template/download?code=${record.code}`);
    } else {
      Modal.confirm({
        title: '确定下载模版？',
        content: `您还没有添加${!record.existAttribute ? '属性' : '数采'}信息，模版导入后，不可编辑，你还要继续吗？`,
        okText: '继续',
        onOk: () => {
          window.open(`/api/batch/import/template/download?code=${record.code}`);
        },
      });
    }
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 420,
      fixed: 'right' as 'right',
      render: (value: undefined, record: BatchDeviceTemplate) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                onDelete(record.id);
              }}
            >
              删除
            </Button>
            <Dropdown
              type="link"
              disabled={record.templateStatus === BatchDeviceTemplateStatus.USING}
              menu={{
                items: [
                  {
                    key: '1',
                    label: <Link to={`/device/manage/batch/${record.id}/edit?tabs=${tabsKey}`}>编辑资产信息</Link>,
                  },
                  {
                    key: '2',
                    label: (
                      <Link to={`/device/manage/batch/${record.id}/attribute/edit?tabs=${tabsKey}`}>{`${
                        record.existAttribute ? '编辑' : '添加'
                      }属性信息`}</Link>
                    ),
                  },
                  {
                    key: '3',
                    label: (
                      <Link
                        to={`/device/manage/batch/${record.id}/dataMining/${
                          record.existEdgeTemplate ? 'edit' : 'new'
                        }?tabs=${tabsKey}`}
                      >{`${record.existEdgeTemplate ? '编辑' : '添加'}数采信息`}</Link>
                    ),
                  },
                ],
              }}
            >
              编辑
            </Dropdown>
            <Button
              type="link"
              onClick={() => {
                onDownload(record);
              }}
            >
              下载导入模板
            </Button>
            <Button type="link">
              <Link to={`/device/manage/batch/create?copyId=${record.id}&tabs=${tabsKey}`}>复制</Link>
            </Button>
            <Button type="link">
              <Link to={`/device/manage/batch/${record.id}/detail?tabs=${tabsKey}`}>查看模板</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table sticky scroll={{ x: 1900 }} rowKey="id" columns={buildColumns} dataSource={list} loading={loading} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default Template;
