import dayjs from 'dayjs';
import { DatePickerType, ParamsOption } from './type';
import * as echarts from 'echarts';
import { isNil } from 'lodash-es';

interface LegendProps {
  show: boolean;
  name?: string;
  isRect?: boolean;
}
interface LegendArrProps {
  show: boolean;
  name?: any[];
  isRect?: boolean;
}

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};

// 通用化配置项
export const getNormalChartOption = (selectBtn?: DatePickerType, unit?: string, isBoxTip = false) => {
  // const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 90,
      right: 30,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        left: 120,
        right: 120,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    // color: colors,
    tooltip:
      isBoxTip && selectBtn === DatePickerType.DAY
        ? {
            trigger: 'axis',
            // axisPointer: {
            //   type: 'cross',
            // },
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
          }
        : getTooltip({
            unit,
            mode: selectBtn,
          }),
    xAxis: getNormalAxis(selectBtn),
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        padding: -10,
        fontSize: 14,
        align: 'right',
        color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
      },
      nameGap: 30,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
      },
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

export const getCommonMarkArea = (stateData: any, isWork: boolean) => {
  const color = isWork ? 'rgba(45,141,255,0.2)' : 'rgba(255,255,255,0.2)';
  return {
    markArea: {
      data: (stateData ?? [])
        .filter((m: any) => (isWork ? m.value === 1 : m.value === 0))
        .map((i: any) => {
          return [
            {
              xAxis: i.from,
              itemStyle: {
                color,
              },
            },
            {
              xAxis: i.to,
            },
          ];
        }),
    },
  };
};

// tooltip 通用配置
export const getTooltip = ({ unit = '', mode }: { unit?: string; mode?: DatePickerType }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  padding: [8, 12],
  textStyle: {
    fontSize: 12,
    color: 'rgba(255,255,255,0.65)',
  },
  formatter(items: FormatterItem[]) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let dateStr: string;
    switch (mode) {
      case DatePickerType.MINUTE_1:
      case DatePickerType.MINUTE_15:
      case DatePickerType.HOUR:
        dateStr = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
        break;
      case DatePickerType.DAY:
        dateStr = dayjs(axisValue, 'x').format('MM-DD');
        break;
      default:
        dateStr = dayjs(axisValue, 'x').format('YYYY-MM');
    }
    let str = `<div style="width:140px;">${dateStr}</div>`;
    items.forEach(item => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
      str += `<div style="display:flex;justify-content:space-between;color:rgba(255,255,255,0.85);margin-top:8px;"><div>${seriesName}</div><div>${value}</div></div>`;
    });
    return str;
  },
});

// 图例通用配置
export const getLegend = ({ show, name, isRect = true }: LegendProps) => {
  return {
    show,
    right: 30,
    top: 50,
    itemWidth: 12,
    itemHeight: 12,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: name,
        icon: 'rect',
      },
    ],
  };
};

// 多图例图例通用配置
export const getLegendArr = ({ show, name, isRect = true }: LegendArrProps) => {
  return {
    show,
    right: 30,
    top: 50,
    textStyle: {
      color: '#AFBCC4',
    },
    itemGap: 20,
    itemWidth: 12,
    itemHeight: 12,
    data: name?.map(item => ({
      name: item.name,
      icon: isRect ? 'rect' : 'path://M0 426.666667h1024v170.666666H0z',
    })),
  };
};

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
export const rangeCatXAxis = (key: string) => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: key === 'Hour' ? 60 * 1000 : key === 'Day' ? 60 * 1000 * 60 * 24 : 60 * 1000 * 60 * 24 * 28,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
        formatter: function (e: number) {
          if (key === 'Hour') {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          } else if (key === 'Day') {
            return dayjs(e, 'x').format('MM-DD');
          } else {
            return dayjs(e, 'x').format('YYYY-MM');
          }
        },
      },
    },
  ];
};

export const getNormalAxis = (selectBtn?: DatePickerType) => {
  switch (selectBtn) {
    case DatePickerType.MINUTE_1:
    case DatePickerType.MINUTE_15:
    case DatePickerType.HOUR:
      return rangeCatXAxis('Hour');
    case DatePickerType.DAY:
      return rangeCatXAxis('Day');
    case DatePickerType.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

// 柱状图 + 工作时间
// 冷水机组-COP
export const getCOPChart = ({ chartData, query, stateData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);

  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,

    legend: {
      show: true,
      right: 30,
      top: 50,
      itemGap: 20,
      itemWidth: 12,
      itemHeight: 12,
      textStyle: {
        color: '#AFBCC4',
      },
      data: [
        {
          name: chartData?.[0]?.name,
          icon: 'path://M0 0h1024v1024H0z',
        },
        {
          name: '工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
        {
          name: '非工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
      ],
    },

    series: [
      {
        type: 'line',
        color: 'rgba(45,141,255,0.2)',
        name: '工作时间',
        data: [],
        ...getCommonMarkArea(stateData, true),
      },
      {
        type: 'line',
        color: 'rgba(255,255,255,0.2)',
        name: '非工作时间',
        data: [],
        ...getCommonMarkArea(stateData, false),
      },
      ...(chartData ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#4F9FFF' },
            { offset: 1, color: '#2D8DFF' },
          ]),
        },
      })),
    ],
  };
};

// 柱状图
// 冷水机组-用电量、制冷量、
// 冷却塔-用电量
// 循环泵-用电量
export const getConsumptionChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);

  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,

    legend: getLegend({
      show: true,
      name: chartData?.[0]?.name, // 总流量
    }),

    series: [
      ...(chartData ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#4F9FFF' },
            { offset: 1, color: '#2D8DFF' },
          ]),
        },
      })),
    ],
  };
};

// 蓝黄四虚线
// 冷水机组-水温
export const getCoolingWaterChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#FFCB47', '#FF9247', '#52E7FF', '#00ADFF'];

  return {
    ...option,
    legend: {
      show: true,
      right: 30,
      top: 50,
      itemGap: 16,
      textStyle: {
        color: '#AFBCC4',
      },
      data: [
        {
          name: '冷却水供水',
          icon: 'path://M1237.333333 533.333333H725.333333v-85.333333h512v85.333333z m-597.333333 0H128v-85.333333h512v85.333333z',
        },
        {
          name: '冷却水回水',
          icon: 'path://M1322.666667 533.333333h-256v-85.333333h256v85.333333z m-341.333334 0h-256v-85.333333h256v85.333333z m-341.333333 0H384v-85.333333h256v85.333333z m-341.333333 0H42.666667v-85.333333h256v85.333333z',
        },
        {
          name: '冷冻水供水',
          icon: 'path://M1237.333333 533.333333H725.333333v-85.333333h512v85.333333z m-597.333333 0H128v-85.333333h512v85.333333z',
        },
        {
          name: '冷冻水回水',
          icon: 'path://M1322.666667 533.333333h-256v-85.333333h256v85.333333z m-341.333334 0h-256v-85.333333h256v85.333333z m-341.333333 0H384v-85.333333h256v85.333333z m-341.333333 0H42.666667v-85.333333h256v85.333333z',
        },
      ],
    },

    series: [
      ...(chartData ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        lineStyle: {
          width: 2,
          type: (item?.name ?? '').indexOf('供水') > -1 ? [14, 3] : [6, 2],
        },
        smooth: true,
      })),
    ],
  };
};

// 蓝色单线 + 工作时间
// 循环泵-瞬时流量
// 冷水机组-电流负载率
export const getSingleLineAreaChart = ({ chartData, query, stateData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#51A1FF', '#59D744', '#FF4D4F'];
  return {
    ...option,
    legend: {
      show: true,
      right: 30,
      top: 50,
      itemWidth: 12,
      itemHeight: 12,
      itemGap: 20,
      textStyle: {
        color: '#AFBCC4',
      },
      data: [
        {
          name: chartData?.[0]?.name,
          icon: 'path://M0 426.666667h1024v170.666666H0z',
        },
        {
          name: '工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
        {
          name: '非工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
      ],
    },
    series: [
      {
        type: 'line',
        color: 'rgba(45,141,255,0.2)',
        name: '工作时间',
        data: [],
        ...getCommonMarkArea(stateData, true),
      },
      {
        type: 'line',
        color: 'rgba(255,255,255,0.2)',
        name: '非工作时间',
        data: [],
        ...getCommonMarkArea(stateData, false),
      },
      ...(chartData ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#51DBFF20' },
            { offset: 1, color: '#51A1FF20' },
          ]),
        },
      })),
    ],
  };
};

// 蓝色单线
// 蓄冰罐-液位
// 冷却塔-风机频率
// 回水供水-温度、压力、瞬时流量、累计流量
// 循环泵- 瞬时流量、频率
// 温湿度-温度、湿度
export const getSingleLineChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#51A1FF', '#FF4D4F'];
  return {
    ...option,
    legend: getLegendArr({
      show: true,
      name: chartData, // 电流
      isRect: false,
    }),
    series: [
      ...(chartData ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#89CFFF20' },
            { offset: 1, color: '#51A1FF10' },
          ]),
        },
      })),
    ],
  };
};

// 蓝红双线
// 蓄冰罐-瞬时流量、温度
// 冷水机组-压力
export const getDoubleLineChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#51A1FF', '#FF4D4F'];
  return {
    ...option,
    legend: getLegendArr({
      show: true,
      name: chartData, // 电流
      isRect: false,
    }),
    series: [
      ...(chartData ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        smooth: true,
      })),
    ],
  };
};

// 蓝黄四虚线 + 工作时间
// 冷水机组-瞬时流量
export const getInstantaneousFlowChart = ({ chartData, query, stateData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);
  const colors = ['#FFCB47', '#FF9247', '#52E7FF', '#00ADFF'];

  return {
    ...option,
    legend: {
      show: true,
      right: 30,
      top: 50,
      itemWidth: 20,
      itemGap: 16,
      textStyle: {
        color: '#AFBCC4',
      },
      data: [
        {
          name: '冷却水供水',
          icon: 'path://M1237.333333 533.333333H725.333333v-85.333333h512v85.333333z m-597.333333 0H128v-85.333333h512v85.333333z',
        },
        {
          name: '冷却水回水',
          icon: 'path://M1322.666667 533.333333h-256v-85.333333h256v85.333333z m-341.333334 0h-256v-85.333333h256v85.333333z m-341.333333 0H384v-85.333333h256v85.333333z m-341.333333 0H42.666667v-85.333333h256v85.333333z',
        },
        {
          name: '冷冻水供水',
          icon: 'path://M1237.333333 533.333333H725.333333v-85.333333h512v85.333333z m-597.333333 0H128v-85.333333h512v85.333333z',
        },
        {
          name: '冷冻水回水',
          icon: 'path://M1322.666667 533.333333h-256v-85.333333h256v85.333333z m-341.333334 0h-256v-85.333333h256v85.333333z m-341.333333 0H384v-85.333333h256v85.333333z m-341.333333 0H42.666667v-85.333333h256v85.333333z',
        },
        {
          name: '工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
        {
          name: '非工作时间',
          icon: 'path://M256 0h512v1024H256z',
        },
      ],
    },

    series: [
      {
        type: 'line',
        color: 'rgba(45,141,255,0.2)',
        name: '工作时间',
        data: [],
        ...getCommonMarkArea(stateData, true),
      },
      {
        type: 'line',
        color: 'rgba(255,255,255,0.2)',
        name: '非工作时间',
        data: [],
        ...getCommonMarkArea(stateData, false),
      },
      ...(chartData ?? []).map((item, index) => ({
        type: 'line',
        color: colors[index],
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        lineStyle: {
          width: 2,
          type: (item?.name ?? '').indexOf('供水') > -1 ? [14, 3] : [6, 2],
        },
        smooth: true,
      })),
    ],
  };
};
