import {
  EnergyMediumListItem,
  EnergyPlanDetailListItem,
  EnergyPlanDetailMediumObjectsItem,
  PlanRange,
  PlanType,
  executeEnergyPlanDetail,
  getEnergyMediumList,
  getEnergyPlanOverview,
  saveEnergyPlanDetail,
} from '@/api/plustekEnergyPlan';
import {
  Button,
  Checkbox,
  EllipsisSpan,
  Input,
  InputNumber,
  MXTabsProps,
  Select,
  ShowInput,
  Tabs,
  Tooltip,
} from '@maxtropy/components';
import { Col, Form, Row, Space, TableColumnsType } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PlanTypeDisplay } from '../LeftPlanList/CardItem';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';
import MediumTable from '../MediumTable';
import StandardCoalTable from '../StandardCoalTable';
import { ValueType } from '@rc-component/mini-decimal';

interface RightPlanContentProps {
  selectedEnergyPlanDetail?: EnergyPlanDetailListItem;
  selectedEnergyPlanDetailId?: number;
  energyPlanDistributionList: EnergyPlanDetailListItem[]; // 不包含日计划
  isEditPlanDetail: boolean; // 是否编辑计划详
  setIsEditPlanDetail: (v: boolean) => void; // 设置是否编辑计划详情
  isSave: boolean; // 是否保存
  setIsSave: (v: boolean) => void;
  isEdit?: boolean;
}

export interface TableDataItem {
  key: string; // table唯一值
  id: number; // 与介质列表相对应
  energyMediumId: number;
  energyMediumName: string;
  energyUnitId: number;
  energyUnitName: string;
  deviceCode: string;
  deviceName: string;
  deviceId: number;
  deviceNameWithCode: string;
  standardCoalRatio?: number;
  [date: string]: any; // 使用索引签名表示动态日期属性 例如：2021-01-01: 100
}

// 数字正则
const pattern = /^(-?[1-9]\d*|0)(\.\d+)?$/;

const months = new Array(12).fill(0).map((_, index) => index + 1);
const quarters = ['第一季度', '第二季度', '第三季度', '第四季度'];
const quarterMonths = [1, 4, 7, 10];

// 获取某年某月的所有日期
function getDaysInMonth(year: number, month: number) {
  // 使用Date对象获取下个月的第0天，即当前月的最后一天
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
  const res = [];
  // 遍历每一天
  for (let day = 1; day <= lastDayOfMonth; day++) {
    res.push(dayjs(`${year}-${month + 1}-${day}`).format('YYYY-MM-DD'));
  }
  return res;
}

// 合并对象
export const mergedObjectFn = (data: Array<{ [date: string]: any }>) => {
  return data.reduce((acc, obj) => {
    // 获取对象的第一个键值对
    const [key, value] = Object.entries(obj)[0];
    // 合并到累加器对象中
    acc[key] = value;
    return acc;
  }, {});
};

// 格式化table数据
function formatToTableData(mediumObjects: EnergyPlanDetailMediumObjectsItem[], mediumList: EnergyMediumListItem[]) {
  return (mediumObjects ?? []).map(medium => {
    return (medium.energyPlanDetailObjects ?? []).map(energyPlanDetail => {
      const { objectData } = energyPlanDetail;
      const dateAndValue = objectData.map((i, index) => {
        return {
          [i.fillingDate]: i.fillingData,
        };
      });
      const mergedObject = mergedObjectFn(dateAndValue);
      return {
        key: `${energyPlanDetail.energyUnitId}-${energyPlanDetail.deviceId}`,
        id: medium.id,
        energyMediumId: medium.energyMediumId,
        energyMediumName: medium.energyMediumName,
        energyUnitId: energyPlanDetail.energyUnitId,
        energyUnitName: energyPlanDetail.energyUnitName,
        deviceCode: energyPlanDetail.deviceCode,
        deviceName: energyPlanDetail.deviceName,
        deviceId: energyPlanDetail.deviceId,
        deviceNameWithCode: `${energyPlanDetail.deviceName}-${energyPlanDetail.deviceCode}`,
        standardCoalRatio: mediumList.filter(i => i.id === medium.id)[0]?.standardCoalRatio,
        ...mergedObject,
      };
    });
  });
}

const InputNumberSameProps = {
  style: { width: '120px' },
  min: 0,
  max: 99999999.99,
  step: 0.01,
  precision: 2,
};

const RightPlanContent: React.FC<RightPlanContentProps> = props => {
  const {
    selectedEnergyPlanDetail,
    selectedEnergyPlanDetailId,
    energyPlanDistributionList,
    isEditPlanDetail,
    setIsEditPlanDetail,
    isSave,
    setIsSave,
    isEdit,
  } = props;
  const [form] = Form.useForm();
  const [detailForm] = Form.useForm();
  const { id: energyPlanId } = useParams<{ id: string }>(); // 计划id
  const distributeType = Form.useWatch('distributeType', form); // 监听分配
  const ratio = Form.useWatch('ratio', form); // 监听系数
  const [planDetail, setPlanDetail] = useState<string | null>();
  // 介质列表
  const [mediumList, setMediumList] = useState<EnergyMediumListItem[]>([]);
  // tab key
  const [tabKey, setTabKey] = useState<string>();
  // 所有介质table数据
  const [allMediumTableData, setAllMediumTableData] = useState<Array<TableDataItem[]>>([]);
  const [origin_allMediumTableData, setOrigin_allMediumTableData] = useState<Array<TableDataItem[]>>([]);
  const [origin_allStandardCoalTableData, setOrigin_allStandardCoalTableData] = useState<Array<TableDataItem[]>>([]);
  // 各介质标准煤table数据
  const [allStandardCoalTableData, setAllStandardCoalTableData] = useState<Array<TableDataItem[]>>([]);
  // 单个介质table数据
  const [tableData, setTableData] = useState<TableDataItem[]>([]);
  //是否折算标准煤
  const [isConversionStandardCoal, setIsConversionStandardCoal] = useState<boolean>();
  // 标准煤合计table列
  const [standardCoalTableColumns, setStandardCoalTableColumns] = useState<TableColumnsType<TableDataItem>>([]);
  // 标准煤合计table数据
  const [standardCoalTableData, setStandardCoalTableData] = useState<TableDataItem[]>([]);
  // 当前介质的标准煤系数
  const [currentStandardCoalRatio, setCurrentStandardCoalRatio] = useState<number>();
  // 分配计划详情的id
  const [disEnergyPlanDetailId, setDisEnergyPlanDetailId] = useState<number>();
  // loading
  const [loading, setLoading] = useState<boolean>(false);

  // 系数校验
  useEffect(() => {
    if (isNil(ratio)) {
      form.setFields([{ name: 'ratio', errors: ['请输入系数'] }]);
    } else if (pattern.test(ratio) === false) {
      form.setFields([{ name: 'ratio', errors: ['请输入数字'] }]);
    } else if (Number(ratio) <= 0) {
      form.setFields([{ name: 'ratio', errors: ['请输入大于0的数字'] }]);
    } else {
      form.setFields([{ name: 'ratio', errors: [] }]);
    }
  }, [ratio, form]);

  useEffect(() => {
    if (selectedEnergyPlanDetailId) {
      form.resetFields();
      setPlanDetail(undefined);
    }
  }, [form, selectedEnergyPlanDetailId]);

  // 获取工质列表 根据计划id
  useEffect(() => {
    if (isNil(energyPlanId)) return;
    getEnergyMediumList(Number(energyPlanId)).then(res => {
      if (res) {
        setMediumList(res.list ?? []);
        if (res.list.length > 0) {
          setTabKey(res.list[0].id.toString());
        }
      }
    });
  }, [energyPlanId]);

  // 获取数据总览
  useEffect(() => {
    if (isNil(selectedEnergyPlanDetailId) || !energyPlanId || mediumList.length === 0) return;
    setLoading(true);
    form.resetFields();
    detailForm.resetFields();
    getEnergyPlanOverview(selectedEnergyPlanDetailId)
      .then(res => {
        if (res) {
          // 设置分配form
          if (res.distributeType) {
            if (isEdit) {
              form.setFieldsValue({
                distributeType: res.distributeType,
                ratio: res.distributeRatio,
              });
              setPlanDetail(isNil(res.originalPlanDetailName) ? undefined : res.originalPlanDetailName);
              setDisEnergyPlanDetailId(res.originalPlanDetailId);
            } else {
              detailForm.setFieldsValue({
                distributeType: isNil(res.distributeType)
                  ? undefined
                  : res.distributeType === PlanType.MONTH
                  ? '从月计划分配'
                  : res.distributeType === PlanType.QUARTER
                  ? '从季度计划分配'
                  : '从年计划分配',
                distributeRatio: res.distributeRatio,
                originalPlanName: res.originalPlanDetailName,
              });
            }
          }
          // 未折算标准煤
          const all = formatToTableData(res.mediumObjects ?? [], mediumList);
          // 折算标准煤
          const allStandardCoal = all.map(medium => {
            return medium.map((item: TableDataItem) => {
              const { standardCoalRatio } = item;
              // 没有标准煤系数或者为0， 不折算
              if (isNil(standardCoalRatio) || standardCoalRatio === 0) return item;
              // 筛选出日期
              const dateKeys = Object.keys(item).filter(key => dayjs(key).isValid());
              // 转换为标准煤
              const standardCoal = dateKeys.map(date => {
                return {
                  [date]: isNil(item[date]) ? null : item[date] * standardCoalRatio,
                };
              });
              // 合并对象
              const mergedObject = mergedObjectFn(standardCoal);
              return {
                ...item,
                ...mergedObject,
              };
            });
          });
          setAllMediumTableData(all);
          setOrigin_allMediumTableData(all);
          setAllStandardCoalTableData(allStandardCoal);
          setOrigin_allStandardCoalTableData(allStandardCoal);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [energyPlanId, mediumList, form, isEdit, detailForm, selectedEnergyPlanDetailId]);

  // 选中介质变化
  useEffect(() => {
    if (tabKey && tabKey !== 'standard_coal') {
      const data = allMediumTableData.filter(i => i && i.length > 0 && i[0].id === Number(tabKey))[0];
      setCurrentStandardCoalRatio(mediumList.filter(i => i.id === Number(tabKey))[0]?.standardCoalRatio);
      setTableData(data);
    }
    // 计算标准煤合计
    if (tabKey === 'standard_coal') {
      // 一个用能单元对应多个介质
      const energyUnitMap = new Map();
      allStandardCoalTableData.forEach(medium => {
        medium.forEach((item: TableDataItem) => {
          // 排除没有系数的介质 或者 系数为0的介质
          if (isNil(item.standardCoalRatio) || item.standardCoalRatio === 0) return;

          if (energyUnitMap.has(item.key)) {
            energyUnitMap.set(item.key, [...energyUnitMap.get(item.key), item]);
          } else {
            energyUnitMap.set(item.key, [item]);
          }
        });
      });
      // 多介质转换标准煤后相加
      const table = Array.from(energyUnitMap.values()).map(i => {
        return {
          ...i[0],
          ...i.reduce((prev: TableDataItem, cur: TableDataItem) => {
            const dateKeys = Object.keys(cur).filter(key => dayjs(key).isValid());
            dateKeys.forEach(date => {
              prev[date] = prev[date] ? prev[date] + cur[date] : cur[date];
            });
            return prev;
          }, {}),
        };
      });
      setStandardCoalTableData(table);
    }
  }, [allMediumTableData, allStandardCoalTableData, tabKey, mediumList]);

  // 转换为标准煤
  useEffect(() => {
    let data: TableDataItem[] = [];
    if (isConversionStandardCoal) {
      data = allStandardCoalTableData.filter(i => i && i.length > 0 && i[0].id === Number(tabKey))[0];
    } else {
      data = allMediumTableData.filter(i => i && i.length > 0 && i[0].id === Number(tabKey))[0];
    }
    setTableData(data);
  }, [allMediumTableData, allStandardCoalTableData, isConversionStandardCoal, tabKey]);

  const onSave = useCallback(async () => {
    if (isNil(selectedEnergyPlanDetailId)) return;
    const energyPlanDetailObjects = allMediumTableData.map(mediumTable => {
      return mediumTable.map((item: TableDataItem) => {
        // 筛选出日期
        const dateKeys = Object.keys(item).filter(key => dayjs(key).isValid());
        const medium = mediumList.filter(i => i.id === item.id)[0];
        return {
          energyUnitId: item.energyUnitId,
          deviceId: item.deviceId,
          energyMediumId: item.energyMediumId,
          physicalUnitId: medium.physicalUnitId,
          basePhysicalQuantityId: medium.basePhysicalQuantityId,
          objectData: dateKeys.map(key => {
            return {
              fillingDate: key,
              fillingData: item[key],
            };
          }),
        };
      });
    });

    const body = {
      id: selectedEnergyPlanDetailId,
      energyPlanDetailObjects: energyPlanDetailObjects.flat(),
    };
    await saveEnergyPlanDetail(body).then(res => {
      setIsEditPlanDetail(false);
    });
  }, [allMediumTableData, mediumList, selectedEnergyPlanDetailId, setIsEditPlanDetail]);

  useEffect(() => {
    if (isSave) {
      onSave().then(() => {
        setIsSave(false);
      });
    }
  }, [isSave, onSave, setIsSave]);

  const onInputNumberChange = useCallback(
    (v: ValueType | null, record: TableDataItem, date: string) => {
      // 折算标准煤
      if (isConversionStandardCoal) {
        const newStandardCoal = tableData.map(i => {
          if (i.key === record.key) {
            return {
              ...i,
              [date]: v,
            };
          }
          return i;
        });
        const newAllStandardCoal = allStandardCoalTableData.map(i => {
          if (i && i.length > 0 && i[0].id === record.id) {
            return newStandardCoal;
          }
          return i;
        });
        setAllStandardCoalTableData([...newAllStandardCoal]);
        // 同步修改未折算标准煤的数据
        if (isNil(currentStandardCoalRatio) || currentStandardCoalRatio === 0 || isNil(v)) return;
        const mediumData = allMediumTableData.filter(i => i && i.length > 0 && i[0].id === record.id)[0];
        const newMediumData = mediumData.map((i: TableDataItem) => {
          if (i.key === record.key) {
            return {
              ...i,
              [date]: isNil(v) ? null : (v as number) / currentStandardCoalRatio,
            };
          }
          return i;
        });
        const newAllMediumData = allMediumTableData.map(i => {
          if (i && i.length > 0 && i[0].id === record.id) {
            return newMediumData;
          }
          return i;
        });
        setAllMediumTableData([...newAllMediumData]);
      } else {
        // 未折算标准煤
        const newMediumData = tableData.map(i => {
          if (i.key === record.key) {
            return {
              ...i,
              [date]: v,
            };
          }
          return i;
        });
        const newAllMediumData = allMediumTableData.map(i => {
          // 替换当前介质的数据
          if (i && i.length > 0 && i[0].id === record.id) {
            return newMediumData;
          }
          return i;
        });
        setAllMediumTableData([...newAllMediumData]);
        // 同步修改折算标准煤的数据
        if (isNil(currentStandardCoalRatio) || isNil(v) || currentStandardCoalRatio === 0) return;
        const standardCoal = allStandardCoalTableData.filter(i => i && i.length && i[0].id === record.id)[0];
        const newStandardCoal = standardCoal.map((i: TableDataItem) => {
          if (i.key === record.key) {
            return {
              ...i,
              [date]: isNil(v) ? null : (v as number) * currentStandardCoalRatio,
            };
          }
          return i;
        });
        const newAllStandardCoal = allStandardCoalTableData.map(i => {
          if (i && i.length > 0 && i[0].id === record.id) {
            return newStandardCoal;
          }
          return i;
        });
        setAllStandardCoalTableData([...newAllStandardCoal]);
      }
    },
    [allMediumTableData, allStandardCoalTableData, currentStandardCoalRatio, isConversionStandardCoal, tableData]
  );

  const columns = useMemo(() => {
    const { planType, planDate, planRange } = selectedEnergyPlanDetail ?? {};
    const year = dayjs(planDate).get('year');

    const col: TableColumnsType<TableDataItem> = [
      {
        title: '用能单元',
        width: 120,
        dataIndex: 'energyUnitName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
    ];
    if (planRange === PlanRange.DEVICE) {
      col.push({
        title: '设备名称-编码',
        width: 200,
        fixed: 'left' as 'left',
        dataIndex: 'deviceNameWithCode',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      });
    }
    // 日计划
    if (planType === PlanType.DAY) {
      const month = dayjs(planDate).get('month');
      // 某年某月的所有日期
      const dates = getDaysInMonth(year, month);
      dates.forEach(date => {
        col.push({
          title: date,
          width: 160,
          dataIndex: date,
          ellipsis: { showTitle: true },
          render: (v: number, record: TableDataItem) => {
            return isEditPlanDetail ? (
              <InputNumber {...InputNumberSameProps} value={v} onChange={v => onInputNumberChange(v, record, date)} />
            ) : (
              <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />
            );
          },
        });
      });
    }
    // 月计划
    if (planType === PlanType.MONTH) {
      const dates = months.map(month => {
        return dayjs(`${year}-${month}`).format('YYYY-MM-DD');
      });
      dates.forEach(date => {
        col.push({
          title: dayjs(date).format('YYYY-MM'),
          width: 160,
          dataIndex: date,
          ellipsis: { showTitle: true },
          render: (v: number, record: TableDataItem) => {
            return isEditPlanDetail ? (
              <InputNumber {...InputNumberSameProps} value={v} onChange={v => onInputNumberChange(v, record, date)} />
            ) : (
              <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />
            );
          },
        });
      });
    }
    // 季度计划
    if (planType === PlanType.QUARTER) {
      const datesTitle = quarters.map(i => {
        return `${year} ${i}`;
      });
      const dates = quarterMonths.map(month => {
        return dayjs(`${year}-${month}`).format('YYYY-MM-DD');
      });
      dates.forEach((date, index) => {
        col.push({
          title: datesTitle[index],
          width: 160,
          dataIndex: date,
          ellipsis: { showTitle: true },
          render: (v: number, record: TableDataItem) => {
            return isEditPlanDetail ? (
              <InputNumber {...InputNumberSameProps} value={v} onChange={v => onInputNumberChange(v, record, date)} />
            ) : (
              <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />
            );
          },
        });
      });
    }
    // 年计划
    if (planType === PlanType.YEAR) {
      const date = dayjs(planDate).format('YYYY-MM-DD');
      col.push({
        title: year,
        width: 160,
        dataIndex: date,
        ellipsis: { showTitle: true },
        render: (v: number, record: TableDataItem) => {
          return isEditPlanDetail ? (
            <InputNumber {...InputNumberSameProps} value={v} onChange={v => onInputNumberChange(v, record, date)} />
          ) : (
            <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />
          );
        },
      });
    }
    setStandardCoalTableColumns(
      col.map(i => {
        if (i.fixed === 'left') return i;
        return { ...i, render: (v: number) => <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} /> };
      })
    );
    return col;
  }, [selectedEnergyPlanDetail, isEditPlanDetail, onInputNumberChange]);

  // 介质选项
  const mediumListTabItems = useMemo(() => {
    let res: MXTabsProps['items'] = [];
    if (mediumList.length > 0) {
      res = mediumList.map(i => ({
        key: i.id.toString(),
        label: `${i.energyMediumName} (${
          isConversionStandardCoal && !isNil(i.standardCoalRatio) && i.standardCoalRatio !== 0 ? 'kgce' : i.generalName
        })`,
      }));
      res.push({
        key: 'standard_coal',
        label: (
          <span>
            标准煤合计(kgce)
            <Tooltip title="标准煤合计中只能查看不能编辑">
              <InfoCircleOutlined
                style={{
                  color: 'var(--warning-color)',
                  marginLeft: 5,
                }}
              />
            </Tooltip>
          </span>
        ),
      });
    }
    return res;
  }, [mediumList, isConversionStandardCoal]);

  // 分配选项
  const distributionOptions = useMemo(() => {
    const options = [
      { label: '从月计划分配', value: PlanType.MONTH },
      { label: '从季度计划分配', value: PlanType.QUARTER },
      { label: '从年计划分配', value: PlanType.YEAR },
      { label: '否', value: 1000 },
    ];
    if (selectedEnergyPlanDetail?.planType === PlanType.MONTH) {
      return options.filter(i => i.value !== PlanType.MONTH);
    }
    if (selectedEnergyPlanDetail?.planType === PlanType.QUARTER) {
      return options.filter(i => i.value !== PlanType.MONTH && i.value !== PlanType.QUARTER);
    }
    return options;
  }, [selectedEnergyPlanDetail]);

  // 选择分配
  const onDistribution = (v?: number) => {
    // 选择否时，清空来源计划和系数
    if (v === 1000) {
      form.resetFields();
      setPlanDetail(undefined);
      return;
    }
    const list = energyPlanDistributionList.filter(
      i => i.planType === v && i.planRange === selectedEnergyPlanDetail?.planRange
    );
    if (list.length > 0) {
      setPlanDetail(
        `${
          list[0].planType === PlanType.DAY
            ? dayjs(list[0].planDate).format('YYYY年MM月')
            : dayjs(list[0].planDate).format('YYYY年')
        } ${PlanTypeDisplay[list[0].planType]} ${list[0].planRange === PlanRange.ENERGY_UNIT ? '用能单元' : '设备'}`
      );
      setDisEnergyPlanDetailId(list[0].id);
    } else {
      setPlanDetail(null);
      setDisEnergyPlanDetailId(undefined);
    }
  };

  return (
    <div className={styles.rightPlanContent}>
      {isEdit && (
        <div className={styles.btn}>
          {isEditPlanDetail ? (
            <Space size={8}>
              <Button type="primary" onClick={onSave}>
                保存
              </Button>
              <Button
                onClick={() => {
                  setAllMediumTableData(origin_allMediumTableData);
                  setAllStandardCoalTableData(origin_allStandardCoalTableData);
                  setIsEditPlanDetail(false);
                }}
              >
                取消
              </Button>
            </Space>
          ) : (
            <Button type="primary" onClick={() => setIsEditPlanDetail(true)}>
              编辑
            </Button>
          )}
        </div>
      )}
      {selectedEnergyPlanDetail &&
        isEditPlanDetail &&
        selectedEnergyPlanDetail.planType !== PlanType.YEAR &&
        isEdit && (
          <div className={styles.formStyle}>
            <Form
              form={form}
              initialValues={{
                distributeType: 1000, // 默认否
              }}
              onFinish={() => {
                form.validateFields().then(values => {
                  if (isNil(values.ratio)) {
                    form.setFields([{ name: 'ratio', errors: ['请输入系数'] }]);
                    return;
                  }
                  if (pattern.test(values.ratio) === false) {
                    form.setFields([{ name: 'ratio', errors: ['请输入数字'] }]);
                    return;
                  }
                  if (Number(values.ratio) <= 0) {
                    form.setFields([{ name: 'ratio', errors: ['请输入大于0的数字'] }]);
                    return;
                  }
                  if (
                    Number(values.distributeType) === 1000 ||
                    !selectedEnergyPlanDetail ||
                    isNil(selectedEnergyPlanDetailId)
                  )
                    return;
                  // 执行分配计划
                  executeEnergyPlanDetail({
                    id: selectedEnergyPlanDetailId,
                    distributeType: Number(values.distributeType),
                    distributeRatio: Number(values.ratio),
                    originalPlanDetailId: disEnergyPlanDetailId,
                  }).then(res => {
                    if (res) {
                      // 未折算标准煤
                      const all = formatToTableData(res.mediumObjects ?? [], mediumList);
                      // 折算标准煤
                      const allStandardCoal = all.map(medium => {
                        return medium.map((item: TableDataItem) => {
                          const { standardCoalRatio } = item;
                          // 没有标准煤系数或者为0，不折算
                          if (isNil(standardCoalRatio) || standardCoalRatio === 0) return item;
                          // 筛选出日期
                          const dateKeys = Object.keys(item).filter(key => dayjs(key).isValid());
                          // 转换为标准煤
                          const standardCoal = dateKeys.map(date => {
                            return {
                              [date]: isNil(item[date]) ? null : item[date] * standardCoalRatio,
                            };
                          });
                          // 合并对象
                          const mergedObject = mergedObjectFn(standardCoal);
                          return {
                            ...item,
                            ...mergedObject,
                          };
                        });
                      });
                      setAllMediumTableData(all);
                      setOrigin_allMediumTableData(all);
                      setAllStandardCoalTableData(allStandardCoal);
                      setOrigin_allStandardCoalTableData(allStandardCoal);
                    }
                  });
                });
              }}
            >
              <Row gutter={[24, 0]}>
                <Col span={5}>
                  <Form.Item
                    label={
                      <span
                        style={{
                          color: 'rgba(255,255,255,0.65)',
                        }}
                      >
                        分配
                      </span>
                    }
                    name="distributeType"
                    rules={[{ required: true, message: '请选择分配' }]}
                  >
                    <Select options={distributionOptions} onChange={onDistribution} />
                  </Form.Item>
                </Col>
                {
                  // 分配为否时，不显示来源计划和系数
                  distributeType !== 1000 && (
                    <>
                      <Col span={19}>
                        <Form.Item
                          label={
                            <span
                              style={{
                                color: 'rgba(255,255,255,0.65)',
                              }}
                            >
                              来源计划
                            </span>
                          }
                        >
                          <ShowInput
                            style={
                              planDetail === undefined
                                ? {}
                                : planDetail === null
                                ? {
                                    color: 'var(--mx-warning-color)',
                                  }
                                : {
                                    color: 'rgba(255,255,255,0.85)',
                                  }
                            }
                            value={planDetail === undefined ? null : planDetail === null ? '无计划' : planDetail}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          label={
                            <span
                              style={{
                                color: 'rgba(255,255,255,0.65)',
                              }}
                            >
                              系数
                            </span>
                          }
                          name="ratio"
                          required
                        >
                          <Input placeholder="请输入" />
                        </Form.Item>
                      </Col>
                      <Col span={19}>
                        <p style={{ marginTop: 5, marginBottom: 0, color: 'rgba(255,255,255,0.65)' }}>
                          <InfoCircleOutlined
                            style={{
                              color: 'var(--warning-color)',
                              marginRight: 4,
                            }}
                          />
                          将【来源计划】中填写的计划数值 * 系数分配到该计划中，需要点击执行分配计划；
                        </p>
                      </Col>
                      <div className={styles.formBtn}>
                        <Button
                          type="primary"
                          onClick={() => {
                            form.submit();
                          }}
                          disabled={isNil(planDetail)}
                        >
                          执行分配计划
                        </Button>
                      </div>
                    </>
                  )
                }
              </Row>
            </Form>
          </div>
        )}
      {!isNil(selectedEnergyPlanDetail) &&
        !isNil(selectedEnergyPlanDetail.planType) &&
        selectedEnergyPlanDetail.planType !== PlanType.YEAR &&
        !isEdit && (
          <div className={styles.formStyle}>
            <Form form={detailForm}>
              <Row>
                <Col span={8}>
                  <Form.Item label="分配" name="distributeType">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="来源计划" name="originalPlanName">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="系数" name="distributeTRatio">
                    <ShowInput
                      addonAfter={
                        <Tooltip placement="top" title="将【来源计划】中填写的计划数值 * 系数分配到该计划中">
                          <InfoCircleOutlined
                            style={{
                              color: 'var(--warning-color)',
                              marginRight: 4,
                            }}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}

      <div className={styles.tabAndCheckBox}>
        <div className={styles.tabStyle}>
          <Tabs
            items={mediumListTabItems}
            onChange={v => {
              setTabKey(v);
            }}
          />
        </div>

        {mediumList.length > 0 && (
          <>
            <div className={styles.checkBoxStyle}>
              <Checkbox
                onChange={e => {
                  setIsConversionStandardCoal(e.target.checked);
                }}
              >
                折算标准煤
              </Checkbox>
            </div>
            <div className={styles.borderStyle} />
          </>
        )}
      </div>

      {tabKey !== 'standard_coal' && <MediumTable loading={loading} columns={columns} tableData={tableData} />}
      {tabKey === 'standard_coal' && (
        <StandardCoalTable columns={standardCoalTableColumns} standardCoalTableData={standardCoalTableData} />
      )}
    </div>
  );
};

export default RightPlanContent;
