export enum CompareType {
  OBJECT = '1',
  HISTORY = '2',
}

export const CompareTypeDisplay = {
  [CompareType.OBJECT]: '对象对比',
  [CompareType.HISTORY]: '历史对比',
};

// 时间颗粒度
export enum StatisticsPartition {
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
}

export const DatePickerTypeDisplay = {
  [StatisticsPartition.DAY]: '按日',
  [StatisticsPartition.MONTH]: '按月',
  [StatisticsPartition.YEAR]: '按年',
};

// 监控指标
export enum MonitorIndicator {
  OEE = 1,
  UPTIMERATE,
  PERFUPTIMERATE,
  YIELDRATE,
}

export const MonitorIndicatorDisplay = {
  [MonitorIndicator.OEE]: 'OEE',
  [MonitorIndicator.UPTIMERATE]: '时间稼动率',
  [MonitorIndicator.PERFUPTIMERATE]: '性能稼动率',
  [MonitorIndicator.YIELDRATE]: '良品率',
};
