import { Button, Select, Form, DatePicker, Tabs, Empty, message, Modal } from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import {
  BaseLineTime,
  BaseLineTimeFormat,
  BaselineType,
  Granularity,
  lineTyoeOptions,
} from '@/pages/OutputConfig/Baseline/BaselineTs';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ImportDataModal from './ImportDataModal';
import {
  BaseLineContext,
  BaseLineFixCycle,
  BaseLineFixCycleFormat,
  BaseLineScrollCycleFormat,
  BaseLineTimeType,
  BaseLineTimeTypeFormat,
  Point,
  TabItem,
} from '../utils/common';
import {
  apiV2OutputProductBaseLineCheckBaseLineDataNormalPost,
  apiV2OutputProductBaseLineGenerateModelPost,
  apiV2OutputProductBaseLineGetBaseLineDataPost,
  apiV2OutputProductBaseLineUpsertPost,
} from '@maxtropy/device-customer-apis-v2';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { PickerMode } from 'rc-picker/es/interface';
import { isNil } from 'lodash';
import dayjs from 'dayjs';

interface CheckDataProps {
  prevStep: () => void;
  nextStep: () => void;
}

const CheckData: React.FC<CheckDataProps> = ({ prevStep, nextStep }) => {
  const [form] = Form.useForm();
  const echartsTheme = useEchartsTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const { lineItem, setLineItem } = useContext(BaseLineContext);
  const [tabItems, setTabItems] = useState<TabItem[]>([]);
  const [activeKey, setActiveKey] = useState<string>();
  const lineType = Form.useWatch('lineType', form);
  const baseLineCycle = Form.useWatch('baseLineCycle', form);
  const [open, setOpen] = useState<boolean>(false);
  const chartRef = useRef<ReactEcharts>();
  const [normalPoints, setNormalPoints] = useState<number[][]>([]);
  const [abnormalPoints, setAbnormalPoints] = useState<number[][]>([]);

  useEffect(() => {
    if (lineItem) {
      form.setFieldsValue({
        baseLineCycle: lineItem?.baseLineCycle,
        lineType: lineItem?.lineType,
        baseLineGranularity: lineItem.baseLineGranularity,
        baseLineTime: !isNil(lineItem.baseLineTime) ? dayjs(lineItem.baseLineTime) : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem]);

  useEffect(() => {
    if (lineItem?.originTabItems && lineItem?.originTabItems.length) {
      const tabs = lineItem?.originTabItems ?? [];
      setTabItems(tabs);
      const firstTabItem = tabs?.[0];
      if (firstTabItem) {
        setActiveKey(firstTabItem.key);
        setNormalPoints((firstTabItem?.valueList ?? []).map(k => [k.x!, k.y!]));
      }
      return;
    }
    if (!isNil(lineItem?.multiChartData) && lineItem?.multiChartData.length) {
      const tabs = (lineItem?.multiChartData ?? []).map(item => ({
        key: item.name + '-' + item.id,
        id: item.id,
        label: item.name,
        type: item.type,
        valueList: item?.valueList ?? [],
      }));

      setTabItems(tabs);

      const firstTabItem = tabs?.[0];
      if (firstTabItem) {
        setActiveKey(firstTabItem.key);
        setNormalPoints((firstTabItem?.valueList ?? []).map(k => [k.x!, k.y!]));
      }
    }
  }, [lineItem?.multiChartData, lineItem?.originTabItems]);

  useEffect(() => {
    if (chartRef.current) {
      try {
        const chartObject = chartRef.current?.getEchartsInstance();

        chartObject.setOption({
          graphic: [
            ...normalPoints.map((item, index) => {
              return {
                ...getBasicConfig(chartObject, item, index),
                ondrag: function () {
                  // @ts-ignore
                  updateIndexPos(normalPoints, 'normal-point', index, [this.x, this.y]);
                },
                ondragend: function () {
                  // @ts-ignore
                  updateIndexPos(normalPoints, 'normal-point', index, [this.x, this.y], val => {
                    setNormalPoints([...val]);
                    checkValueList();
                  });
                },
              };
            }),
            ...abnormalPoints.map((item, index) => {
              return {
                ...getBasicConfig(chartObject, item, index),
                ondrag: function () {
                  // @ts-ignore
                  updateIndexPos(abnormalPoints, 'abnormal-point', index, [this.x, this.y]);
                },
                ondragend: function () {
                  // @ts-ignore
                  updateIndexPos(abnormalPoints, 'abnormal-point', index, [this.x, this.y], val => {
                    setAbnormalPoints([...val]);
                    checkValueList();
                  });
                },
              };
            }),
          ],
        });
      } catch (error) {
        console.log('error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, normalPoints, abnormalPoints]);

  // 按钮-获取数据
  const getChartData = async () => {
    const values = await form.validateFields();
    const { baseLineCycle, lineType, baseLineGranularity, baseLineTime } = values;
    apiV2OutputProductBaseLineGetBaseLineDataPost({
      id: lineItem?.baseLineId,
      baseLineCycle: baseLineCycle,
      lineType: lineType,
      baseLineGranularity: baseLineGranularity,
      baseLineTime: dayjs(baseLineTime).format('YYYY-MM-DD'),
    }).then(res => {
      const tabs = (res?.list ?? []).map(item => ({
        key: item.name + '-' + item.id,
        id: item.id,
        label: item.name,
        type: item.type,
        valueList: item.valueList,
      }));
      setTabItems(tabs);

      const firstTabItem = tabs?.[0];
      if (firstTabItem) {
        setActiveKey(firstTabItem.key);
        setNormalPoints((firstTabItem?.valueList ?? []).map(k => [k.x!, k.y!]));
      }

      setLineItem?.({
        ...lineItem,
        baseLineCycle: baseLineCycle,
        lineType: lineType,
        baseLineGranularity: baseLineGranularity,
        baseLineTime: baseLineTime,
      });
    });
  };

  const getBasicConfig = (chartObject: any, item: any, index: number) => {
    return {
      type: 'circle',
      position: chartObject.convertToPixel('grid', item),
      shape: {
        cx: 0,
        cy: 0,
        r: 10,
      },
      invisible: true,
      draggable: 'vertical',
      onmousemove: function () {
        chartObject.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        });
      },
      onmouseout: function () {
        chartObject.dispatchAction({
          type: 'hideTip',
        });
      },
      z: 100,
    };
  };

  const updateIndexPos = (
    dataList: any[],
    dataId: string,
    dataIndex: number,
    pos: any,
    callback?: (val: any) => void
  ) => {
    const chartObject = chartRef.current?.getEchartsInstance();
    dataList[dataIndex] = chartObject?.convertFromPixel('grid', pos);
    // Update data
    chartObject?.setOption({
      series: [
        {
          id: dataId,
          data: dataList,
        },
      ],
    });
    callback?.(dataList);
  };

  const mergePoints = (pointList: Point[]) => {
    const currentTabIndex = tabItems?.findIndex(m => m.key === activeKey);
    setTabItems(
      tabItems.map((k, index) => {
        return currentTabIndex === index ? { ...k, valueList: pointList } : { ...k };
      })
    );
  };

  // 检查数据
  const checkValueList = () => {
    const pointList = [...normalPoints, ...abnormalPoints]
      .map(k => ({
        x: k[0],
        y: k[1],
      }))
      .sort((a, b) => a.x - b.x);
    const currentTabItem = tabItems?.find(m => m.key === activeKey);
    if (isNil(currentTabItem)) return;
    apiV2OutputProductBaseLineCheckBaseLineDataNormalPost({
      id: lineItem?.baseLineId,
      multiXYValueList: [
        {
          id: currentTabItem.id!,
          type: currentTabItem.type!,
          valueList: pointList,
        },
      ],
    }).then(res => {
      const pointList = res.list?.[0]?.valueList ?? [];
      setNormalPoints(filterPoint(pointList, true));
      setAbnormalPoints(filterPoint(pointList, false));
      mergePoints(pointList);
    });
  };

  const onTabChange = (activeKey: string) => {
    const tab = tabItems.find(k => k.key === activeKey);
    setNormalPoints(filterPoint(tab?.valueList, true));
    setAbnormalPoints(filterPoint(tab?.valueList, false));
    setActiveKey(activeKey);
  };

  const currentTabItem = useMemo(() => {
    return tabItems.find(k => k.key === activeKey);
  }, [tabItems, activeKey]);

  const option = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        triggerOn: 'none',
        backgroundColor: 'black',
        padding: [8, 12],
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
          fontSize: 12,
        },
        borderColor: 'black',
        formatter: function (params: any) {
          const { data } = params[0];
          return `<div style="width:158px;line-height:20px;display:flex;justify-content:space-between;">
            <div>单耗</div>
            <div>${data?.[1]?.toFixed(2)}${lineItem?.unitCode ?? 'pcs'}/每小时</div>
          </div>`;
        },
      },
      legend: {
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        top: 4,
        right: 0,
        itemGap: 16,

        data: [
          {
            name: '正常点',
            textStyle: {
              color: 'rgba(255,255,255,0.85)',
              fontSize: 12,
            },
            itemStyle: {
              color: '#10AEFF',
            },
          },
          {
            name: '异常点',
            textStyle: {
              color: 'rgba(255,255,255,0.85)',
              fontSize: 12,
            },
            itemStyle: {
              color: '#FFCB47',
            },
          },
        ],
      },
      grid: {
        top: 60,
        left: 60,
        right: 60,
      },
      backgroundColor: '#232324',
      xAxis: {
        name: `${lineItem?.unit}/时`,
        type: 'value',
        splitLine: {
          show: true, // 显示分隔线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        min: lineItem?.minOutput,
        max: lineItem?.maxOutput,
        nameTextStyle: {
          lineHeight: 24, //标题行高
          verticalAlign: 'top', //标题位置
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        axisLine: {
          show: false,
        },
        name: `单耗`,
        axisTick: { show: false },
        nameGap: 20,
      },
      series: [
        {
          id: 'normal-point',
          name: '正常点',
          type: 'line',
          data: normalPoints,
          itemStyle: {
            color: '#10AEFF',
            borderColor: 'rgba(16, 174, 255, 0.3)',
            borderWidth: 4,
          },
          lineStyle: {
            color: 'rgba(0,0,0,0)',
          },
          symbol: 'circle', //折线节点为小圆点
          symbolSize: 10, //折线点大小
        },
        {
          id: 'abnormal-point',
          name: '异常点',
          type: 'line',
          data: abnormalPoints,
          itemStyle: {
            color: '#FFCB47',
            borderColor: 'rgba(255,203,71,0.3)',
            borderWidth: 4,
          },
          lineStyle: {
            color: 'rgba(0,0,0,0)',
          },
          symbol: 'circle', //折线节点为小圆点
          symbolSize: 10, //折线点大小
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          bottom: 15,
          height: 20,
          backgroundColor: 'rgba(226,227,229,0.5)',
          fillerColor: 'rgba(142,177,224,0.3)',
          textStyle: {
            fontSize: 10,
          },
          // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalPoints, abnormalPoints]);

  //基线粒度
  const getGranularityoptions = () => {
    if (lineType === BaselineType.FIX) {
      if (baseLineCycle === BaseLineFixCycle.YEAR) {
        return [
          { value: BaseLineTime.DAY, label: BaseLineTimeFormat[BaseLineTime.DAY] },
          { value: BaseLineTime.MONTH, label: BaseLineTimeFormat[BaseLineTime.WEEK] },
          { value: BaseLineTime.MINUTE, label: BaseLineTimeFormat[BaseLineTime.MONTH] },
        ];
      } else if (baseLineCycle === BaseLineFixCycle.MONTH) {
        return [
          { value: BaseLineTime.HOUR, label: BaseLineTimeFormat[BaseLineTime.HOUR] },
          { value: BaseLineTime.DAY, label: BaseLineTimeFormat[BaseLineTime.DAY] },
        ];
      } else if (baseLineCycle === BaseLineFixCycle.WEEK) {
        return [
          { value: BaseLineTime.HOUR, label: BaseLineTimeFormat[BaseLineTime.HOUR] },
          { value: BaseLineTime.DAY, label: BaseLineTimeFormat[BaseLineTime.DAY] },
          { value: BaseLineTime.MINUTE, label: BaseLineTimeFormat[BaseLineTime.MINUTE] },
        ];
      } else if (baseLineCycle === BaseLineFixCycle.DAY) {
        return [
          { value: BaseLineTime.HOUR, label: BaseLineTimeFormat[BaseLineTime.HOUR] },
          { value: BaseLineTime.MINUTE, label: BaseLineTimeFormat[BaseLineTime.MINUTE] },
        ];
      }
    }
    if (lineType === BaselineType.SCROLL) {
      if (baseLineCycle === BaseLineFixCycle.WEEK) {
        return [
          { value: BaseLineTime.HOUR, label: BaseLineTimeFormat[BaseLineTime.HOUR] },
          { value: BaseLineTime.MINUTE, label: BaseLineTimeFormat[BaseLineTime.MINUTE] },
        ];
      }
      return [
        { value: BaseLineTime.HOUR, label: BaseLineTimeFormat[BaseLineTime.HOUR] },
        { value: BaseLineTime.DAY, label: BaseLineTimeFormat[BaseLineTime.DAY] },
      ];
    }
    return [];
  };

  // 基线周期
  const getBaseLineCycleOptions = () => {
    if (lineType === BaselineType.FIX) {
      return [
        {
          value: BaseLineFixCycle.YEAR,
          label: BaseLineFixCycleFormat[BaseLineFixCycle.YEAR],
        },
        {
          value: BaseLineFixCycle.MONTH,
          label: BaseLineFixCycleFormat[BaseLineFixCycle.MONTH],
        },
        {
          value: BaseLineFixCycle.WEEK,
          label: BaseLineFixCycleFormat[BaseLineFixCycle.WEEK],
        },
        {
          value: BaseLineFixCycle.DAY,
          label: BaseLineFixCycleFormat[BaseLineFixCycle.DAY],
        },
      ];
    }
    return [
      { value: BaseLineFixCycle.WEEK, label: BaseLineScrollCycleFormat[BaseLineFixCycle.WEEK] },
      { value: BaseLineFixCycle.MONTH, label: BaseLineScrollCycleFormat[BaseLineFixCycle.MONTH] },
      { value: BaseLineFixCycle.QUARTER, label: BaseLineScrollCycleFormat[BaseLineFixCycle.QUARTER] },
    ];
  };

  // 上一步
  const savePrev = () => {
    const { baseLineGranularity, baseLineTime } = form.getFieldsValue();
    setLineItem?.({
      ...lineItem,
      baseLineCycle: baseLineCycle,
      lineType: lineType,
      baseLineGranularity: baseLineGranularity,
      baseLineTime: baseLineTime,
    });
    prevStep?.();
  };

  const checkPointList = async () => {
    // 检查表单
    await form.validateFields();
    // 存在异常点的页卡集合
    const tabNameList = tabItems.filter(k => (k.valueList ?? []).some(m => m.abnormalFlag)).map(m => m.label);
    if (tabNameList.length) {
      message.error({
        content: (
          <div>
            页卡
            {tabNameList.map((name, index) => {
              return (
                <>
                  <span style={{ color: 'var(--mx-link-color)' }}>{name}</span>
                  {index === tabNameList.length - 1 ? null : '、'}
                </>
              );
            })}
            中有异常点或离群点，请进行调整
          </div>
        ),
      });
      return;
    }
    const lessPointList = tabItems.filter(k => (k.valueList ?? []).length < 5).map(m => m.label);
    if (lessPointList.length === tabItems.length) {
      message.error('数据点不足，无法生成基线');
      return;
    }
    if (lessPointList.length) {
      Modal.confirm({
        title: (
          <div>
            页卡
            {lessPointList.map((name, index) => {
              return (
                <>
                  <span style={{ color: 'var(--mx-link-color)' }}>{name}</span>
                  {index === lessPointList.length - 1 ? null : '、'}
                </>
              );
            })}
            数据点不足，对应页卡无法生成基线，是否继续？
          </div>
        ),
        onOk: () => {
          saveData();
        },
      });
    } else {
      saveData();
    }
  };

  // 保存下一步
  const saveData = async () => {
    const values = form.getFieldsValue();
    setLoading(true);
    await apiV2OutputProductBaseLineUpsertPost({
      id: lineItem?.baseLineId,
      baseLineCycle: values.baseLineCycle,
      lineType: values.lineType,
      baseLineGranularity: values.baseLineGranularity,
      baseLineTime: dayjs(values.baseLineTime).format('YYYY-MM-DD'),
    }).onError(error => {
      setLoading(false);
      throw error;
    });
    apiV2OutputProductBaseLineGenerateModelPost({
      id: lineItem?.baseLineId,
      multiXYValueList: tabItems.map(k => ({
        id: k.id!,
        type: k.type!,
        valueList: (k?.valueList ?? []).map(p => ({ x: p.x!, y: p.y! })),
      })),
    })
      .then(res => {
        setLineItem?.({
          ...lineItem,
          baseLineCycle: baseLineCycle,
          lineType: lineType,
          baseLineGranularity: values.baseLineGranularity,
          baseLineTime: values.baseLineTime,
          originTabItems: tabItems,
          multiChartData: res.multiChartData,
        });
        nextStep();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 过滤点
  const filterPoint = (points?: Point[], isNormal?: boolean) => {
    return (points ?? [])
      .filter(k => !k.abnormalFlag === isNormal)
      .map(m => {
        return [m.x!, m.y!];
      });
  };

  return (
    <div className={styles.checkData}>
      <Spin spinning={loading}>
        <Form form={form} layout="horizontal">
          <Row>
            <Col span={12}>
              <Form.Item name="lineType" label="基线类型" rules={[{ required: true, message: '请选择基线类型' }]}>
                <Select
                  options={lineTyoeOptions}
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  onChange={() => {
                    form.setFieldValue('baseLineCycle', undefined);
                    setTabItems([]);
                  }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="baseLineCycle" label="基线周期" rules={[{ required: true, message: '请选择基线周期' }]}>
                <Select
                  style={{ width: '100%' }}
                  options={getBaseLineCycleOptions()}
                  placeholder="请选择"
                  onChange={() => {
                    form.setFieldValue('baseLineGranularity', undefined);
                    setTabItems([]);
                  }}
                />
              </Form.Item>
            </Col>
            {lineType === BaselineType.FIX && (
              <Col span={12}>
                <Form.Item noStyle dependencies={['baseLineCycle']}>
                  {({ getFieldValue }) => {
                    const baseLineCycle = getFieldValue('baseLineCycle');
                    return (
                      <Form.Item
                        name="baseLineTime"
                        label="基线时间"
                        rules={[{ required: true, message: '请选择基线时间' }]}
                      >
                        <DatePicker
                          picker={BaseLineTimeTypeFormat[baseLineCycle as BaseLineTimeType] as PickerMode}
                          style={{ width: '100%' }}
                          placeholder="请选择"
                          onChange={() => {
                            setTabItems([]);
                          }}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            )}
            {lineItem?.dataSource === Granularity.ORDER && lineType === BaselineType.SCROLL ? null : (
              <Col span={12}>
                <Form.Item
                  name="baseLineGranularity"
                  label="基线粒度"
                  rules={[{ required: true, message: '请选择基线粒度' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={getGranularityoptions()}
                    onChange={() => {
                      setTabItems([]);
                    }}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        <div className={styles.btnRow}>
          <Button type="primary" onClick={getChartData}>
            获取数据
          </Button>
        </div>
        {!!tabItems.length && (
          <>
            <div className={styles.dataTitle}>数据列表</div>
            <Tabs
              activeKey={activeKey}
              items={tabItems.map(m => ({
                label: m.label,
                key: m.key,
              }))}
              tabBarExtraContent={
                lineType === BaselineType.FIX ? <Button onClick={() => setOpen(true)}>导入数据</Button> : null
              }
              onChange={onTabChange}
            />
            {lineItem?.dataSource === Granularity.SIMULTANEOUS && (
              <div className={styles.chartTip}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                <span>
                  图表中的数据已经根据3sigma法及箱形图法识别并调整了异常点与离群点；鼠标移动到点上后可以上下拖动改变点的位置
                </span>
              </div>
            )}
            {currentTabItem?.valueList?.length ? (
              <ReactEcharts
                className={styles.chartRow}
                option={option}
                ref={e => {
                  if (e) {
                    chartRef.current = e;
                  }
                }}
                style={{ minHeight: 500, position: 'relative' }}
                notMerge
                lazyUpdate={false}
                theme={echartsTheme}
              />
            ) : (
              <Empty style={{ margin: '80px 0' }} description="暂无数据" />
            )}
          </>
        )}
        <Space size={8}>
          <Button onClick={savePrev}>上一步</Button>
          <Button type="primary" onClick={checkPointList}>
            保存后下一步
          </Button>
        </Space>
      </Spin>
      {open && (
        <ImportDataModal
          modalOpen={open}
          setModalOpen={setOpen}
          tabName={currentTabItem?.label ?? '导入模板'}
          tabType={currentTabItem?.type ?? 1}
          setTabValueList={val => {
            setNormalPoints(filterPoint(val, true));
            setAbnormalPoints(filterPoint(val, false));
            mergePoints(val);
          }}
        ></ImportDataModal>
      )}
    </div>
  );
};
export default CheckData;
