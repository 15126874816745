import { EllipsisSpan, Table } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getElectricityPhotovoltaicAllFeedInQuantityResponse, TotalQuantityItem } from '../../../../api/electricity';
import { units } from '../utils';

const columns = [
  {
    title: '',
    dataIndex: 'firstCol',
    ellipsis: { showTitle: true },
    render: () => <EllipsisSpan value={'总计'} />,
    width: 70,
    // className: `${styles.firstCell}`,
  },
  {
    title: '尖收费电量',
    dataIndex: 'summitPowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '峰收费电量',
    dataIndex: 'peakPowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '平收费电量',
    dataIndex: 'plainPowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '谷收费电量',
    dataIndex: 'valleyPowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '总计收费电量',
    dataIndex: 'totalActivePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '总额',
    dataIndex: 'totalElectricFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
];

const ElectricityBillDistributedTotalElectric: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<TotalQuantityItem>();

  useEffect(() => {
    if (id) {
      getElectricityPhotovoltaicAllFeedInQuantityResponse(id).then(res => {
        res && setData(res.totalQuantity);
      });
    }
  }, [id]);

  return (
    <>
      <Table rowKey="id" pagination={false} columns={columns} dataSource={data ? [data] : []} />
    </>
  );
};

export default ElectricityBillDistributedTotalElectric;
