import {
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  Select,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { columns } from '../util';
import { useQuery } from '@/shared/utils/utils';
import { getOuByFcs } from '../../../api/ou';
import {
  FcsNetworkDetail,
  fetchFcsNetworkByEdgeGatewayId,
  fetchGatewayById,
  Gateway,
  getGatewayListByFcs,
} from '../../../api/gateway';
import { Device } from '../../../api/device';
import styles from './index.module.scss';
import { createFcs, CreateFcsReq, getFcsInfo, updateFcs } from '../../../api/fcs';
import { useNavigate, useParams } from 'react-router-dom';
import GatherDeviceList from '../components/GatherDeviceList';
import {
  V2FcsSoftwareVersionByFcsTypePostResponse,
  V2FcsTypeListPostResponse,
  apiV2FcsSoftwareVersionByFcsTypePost,
  apiV2FcsTypeListPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

interface CreateFcsProps {
  isEdit?: boolean;
}

export enum FCSType {
  BESS1 = 1,
  BESS2 = 2,
}

// fcs软件/硬件下拉列表 key->硬件版本, value->软件版本
// const hardWareSelectData: KeyValues = {
//   HM1: 'H.1.0',
//   HA1: 'H.2.0',
// };

const CreateFcs: React.FC<CreateFcsProps> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [deviceList, setDeviceList] = useState<Device[]>([]);
  const [gatewayList, setGatewayList] = useState<Gateway[]>([]);
  // fcs类型下拉列表
  const [fcsTypeList, setFcsTypeList] = useState<V2FcsTypeListPostResponse['list']>([]);
  // 当前选择的网关数据
  const [currentGateway, setCurrentGateway] = useState<{ id: number; deviceName?: string }>();

  const [fcsNetworkInfo, setFcsNetworkInfo] = useState<FcsNetworkDetail>();

  const [fcsSoftSel, setFcsSoftSel] = useState<string>();

  const breadcrumbRoutes = useBreadcrumbRoutes();

  // fcs软件版本
  const [softWareSelectData, setSoftWareSelectData] = useState<V2FcsSoftwareVersionByFcsTypePostResponse['list']>([]);
  // 类型
  const type: string | undefined = Form.useWatch('type', form);
  const [fcsType, setFcsType] = useState<number>();

  // 页面编辑
  useEffect(() => {
    if (id) {
      getFcsInfo(id).then(res => {
        // 表单回填
        form.setFieldsValue(res);
        // 设置已经选择的列表项重新添加到列表中
        setGatewayList(list => [...list, res.edgeGatewayAbstractVo]);
        // 当前网关数据
        setCurrentGateway(res.edgeGatewayAbstractVo);
        // 初始化网关设备列表
        setDeviceList(
          res.edgeGatewayAbstractVo.deviceWithEdgeDeviceDtos?.map(item => ({
            ...item.device,
          }))
        );
      });
    }
  }, [id, form]);

  // 初始化下拉列表
  useEffect(() => {
    // 网关
    getGatewayListByFcs().then(res => {
      setGatewayList(list => [...list, ...(res.list ?? [])]);
    });
    // fcs类型
    apiV2FcsTypeListPost({}).then(res => {
      if (res.list && res.list.length > 0) {
        setFcsTypeList(res.list);
      }
    });
  }, []);

  useEffect(() => {
    if (fcsTypeList && fcsTypeList.length) {
      if (!id) {
        form.setFieldsValue({
          type: fcsTypeList[0].type,
        });
      }
    }
  }, [fcsTypeList, id]);

  useEffect(() => {
    if (!isNil(type)) {
      apiV2FcsSoftwareVersionByFcsTypePost({ fcsType: type }).then(res => {
        setSoftWareSelectData(res.list);
      });
    }
  }, [type]);

  useEffect(() => {
    if (fcsType) {
      form.setFieldsValue({
        softwareVersion: undefined,
      });
    }
  }, [fcsType]);

  const { data: { list: ouList = [] } = {} } = useQuery(useCallback(() => getOuByFcs(), []));
  // 网关下拉选择事件
  const onSelectGateway = (v: number) => {
    // 查询绑定的设备
    fetchGatewayById(v.toString()).then(res => {
      if (res) {
        setDeviceList(
          res.deviceWithEdgeDeviceDtos?.map(item => ({
            ...item.device,
          }))
        );
        setCurrentGateway({ id: v, deviceName: res.deviceName });
      }
    });
    fetchFcsNetworkByEdgeGatewayId(v.toString()).then(res => {
      setFcsNetworkInfo(res);
    });
  };

  const onFinish = async (v: CreateFcsReq) => {
    const res = isEdit ? await updateFcs({ ...v, id: +id! }) : await createFcs(v);
    if (res) {
      navigate(`/device/fcs/list`);
    }
  };

  const routes = [{ name: `${isEdit ? '编辑' : '新建'}FCS` }];
  // 是否需要组网
  const needCombineNets = useMemo(() => {
    return (softWareSelectData ?? []).find(item => item.softwareVersion === fcsSoftSel)?.arrayType === 2;
  }, [softWareSelectData, fcsSoftSel]);
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title={`${isEdit ? '编辑' : '新建'}FCS`} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <SubContent>
          <Row>
            <Col span={12}>
              <Form.Item
                label="FCS名称"
                name="name"
                rules={[
                  { required: true, message: '请输入FCS名称' },
                  { max: 10, message: '最多输入10个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            {/* 确认接口key值,  临时占位 */}
            <Col span={12}>
              <Form.Item label="FCS类型" name="type" rules={[{ required: true, message: '请选择FCS类型' }]}>
                <Select placeholder="请选择" onChange={val => setFcsType(val)}>
                  {(fcsTypeList ?? []).map(i => (
                    <Select.Option value={i.type} key={i.type}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="FCS软件版本"
                name="softwareVersion"
                rules={[{ required: true, message: '请选择FCS软件版本' }]}
                extra={
                  fcsType === FCSType.BESS2 ? undefined : needCombineNets ? '请注意，该版本需组网支撑。' : undefined
                }
              >
                <Select placeholder="请选择" disabled={isEdit} onSelect={value => setFcsSoftSel(value)}>
                  {(softWareSelectData ?? []).map(i => (
                    <Select.Option value={i.softwareVersion} key={i.softwareVersion}>
                      {`${i.softwareVersion}${i.softwareName ? '-' + i.softwareName : ''}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="关联运营单元" name="ouId" rules={[{ required: true, message: '请选择关联运营单元' }]}>
                <Select
                  placeholder="请选择"
                  disabled={isEdit}
                  showSearch
                  optionFilterProp="label"
                  options={ouList?.map(item => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className={styles.colBox}>
                <Form.Item label="关联网关" name="gatewayId" rules={[{ required: true, message: '请选择关联网关' }]}>
                  <Select placeholder="请选择" onSelect={onSelectGateway}>
                    {gatewayList?.map(i => (
                      <Select.Option value={i.id} key={i.id}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {currentGateway && <GatherDeviceList {...currentGateway} fcsNetworkInfo={fcsNetworkInfo} />}
              </div>
            </Col>
            <Col span={12}>
              <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '最多输入50个字符' }]}>
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>

          <p style={{ paddingTop: currentGateway ? 75 : 0 }}>网关中绑定的设备</p>
          <Table columns={columns} dataSource={deviceList} />
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={() => navigate(`/device/fcs/list`)}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateFcs;
