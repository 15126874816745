import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Modal, Select, message } from '@maxtropy/components';
import { Spin } from 'antd';
import {
  apiV2GasPreparationStationDeviceListPost,
  apiV2GasPreparationStationThermometerAddPost,
  apiV2GasPreparationStationThermometerEditPost,
  apiV2GasPreparationStationThermometerGetPost,
} from '@maxtropy/device-customer-apis-v2';
import { DeviceType, NodeType, OperationType } from '../../utils';
import { DeviceList, ModalProp } from '../../IntrasiteTopology';

const FormItem = Form.Item;

const ThermometerNode = ({ onCancel, onOk, uetId, operationType, selectedNode, stationData }: ModalProp) => {
  const [form] = Form.useForm();

  const [thermometerList, setThermometerList] = useState<DeviceList[]>([]);
  const [id, setId] = useState<number>();
  const [type, setType] = useState<number>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (operationType === OperationType.EDIT) {
      const { selfId, type } = selectedNode.getData();
      setLoading(true);
      apiV2GasPreparationStationThermometerGetPost({ id: selfId })
        .then(res => {
          form.setFieldsValue({
            code: res?.code,
            name: res?.name,
            thermometerId: res?.thermometerId,
            id: res?.id,
          });
          setId(res?.id);
          setType(type);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, operationType]);

  useEffect(() => {
    if ((operationType === OperationType.EDIT && id) || operationType === OperationType.ADD) {
      apiV2GasPreparationStationDeviceListPost({
        nodeId: id,
        nodeType: type as NodeType,
        uetId,
        stationId: stationData?.stationId,
        stationType: stationData?.stationType,
        deviceType: [DeviceType.Thermometer],
      }).then(res => {
        setThermometerList(
          res?.list?.find(item => item.deviceType === DeviceType.Thermometer)?.deviceBasicDtoList ?? []
        );
      });
    }
  }, [id, operationType, stationData, type, uetId]);

  const thermometerOptions = useMemo(() => {
    if (thermometerList?.length !== 0) {
      return thermometerList?.map(i => ({ label: i.name, value: i.id }));
    }
  }, [thermometerList]);

  const handleForm = () => {
    form.validateFields().then(() => {
      const values = {
        ...form.getFieldsValue(true),
        canvasId: selectedNode?.id,
        stationId: stationData?.stationId,
        stationCode: stationData?.stationCode,
        stationType: stationData?.stationType,
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2GasPreparationStationThermometerAddPost(values)
          .then(res => {
            message.success('新增成功');
            onOk({ ...values, selfId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }

      if (operationType === OperationType.EDIT) {
        apiV2GasPreparationStationThermometerEditPost(values)
          .then(res => {
            message.success('编辑成功');
            onOk({ ...values, selfId: res.id });
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <Modal
      title="温度计节点"
      contentClassName="modal-form-content"
      open
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          {operationType === OperationType.EDIT && (
            <FormItem label="节点编号" name="code">
              <Input placeholder="请输入" disabled />
            </FormItem>
          )}
          <FormItem
            label="节点名称"
            name="name"
            rules={[
              { required: true, message: '请输入节点名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 10, message: '最多输入10个字符' },
            ]}
          >
            <Input placeholder="请输入节点名称" allowClear />
          </FormItem>
          <FormItem label="温度计" name="thermometerId">
            <Select placeholder="请选择" options={thermometerOptions} showSearch allowClear optionFilterProp="label" />
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ThermometerNode;
