import { Empty, Tooltip, Tree, Input, useTheme, Themes } from '@maxtropy/components';
import { Spin } from 'antd';
import { DataNode } from 'antd/es/tree';
import classNames from 'classnames';
import qs from 'qs';
import React, { CSSProperties, Key, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Device, DeviceSceneTreeProps, DeviceTypes, getDeviceSceneTree } from '../../../../api/history';
import { useQuery } from '../../../../utils/utils';
import styles from './index.module.scss';

const { Search } = Input;

// 不在树中显示的数据
export const specialCategoryList = [
  {
    label: '电池组',
    id: 5007,
  },
  {
    label: '电芯',
    id: 5008,
  },
  {
    label: '光伏组串',
    id: 5019,
  },
  {
    label: '光伏组件',
    id: 5080,
  },
];

export interface DeviceTreeProps {
  className?: string;
  tenantMcid?: string;
  companyId?: number;
  selectDevice: (selectedKeys: string, titlePath: string[]) => void;
  selectCategory: (selectedKeys: string, titlePath: string[], devices: Device[]) => void;
  style?: CSSProperties;
}

const formatTreeData = (data: DeviceSceneTreeProps[]) => {
  // 不在树中显示的类目数据
  let specialCategoryDataList: DeviceTypes[] = [];

  const format = (key: string, treeData: DeviceTypes[]) => {
    return treeData.map(item => {
      if (specialCategoryList.map(specialCategory => specialCategory.id).includes(item.id)) {
        specialCategoryDataList.push(item);
        return {
          key: `${key}-product-${item.id}`,
          title: item.name,
        };
      }

      if (item.devices && item.devices.length) {
        return {
          key: `${key}-product-${item.id}`,
          title: item.name,
          selectable: false,
          children: item.devices.map(item => ({
            key: `${key}-device-${item.id}`,
            title: item.name,
          })),
        };
      } else {
        return {
          key: `${key}-product-${item.id}`,
          title: item.name,
          selectable: false,
        };
      }
    });
  };
  const result = data.map(item => ({
    key: `industry-${item.id}`,
    title: item.name,
    selectable: false,
    children: item.deviceTypeList.map(i => ({
      key: `industry-${item.id}-brand-${i.id}`,
      title: i.name,
      selectable: false,
      children: format(`industry-${item.id}-brand-${i.id}`, i.children),
    })),
  }));

  return { result, specialCategoryDataList };
};
// 根据key找到相应的节点并添加锚点
const attrAnchorToTreeNodeByKey = (data: DataNode[], key: string, anchor: Key) => {
  data.some(item => {
    if (item.key === key) {
      item.title = <span id={`${anchor}`}>{item.title as string}</span>;
      return true;
    } else {
      if (item.children) {
        attrAnchorToTreeNodeByKey(item.children, key, anchor);
      }
    }
    return false;
  });
};
const DeviceTree: React.FC<DeviceTreeProps> = props => {
  const { tenantMcid, companyId, selectDevice, selectCategory, className, style } = props;

  const navigate = useNavigate();

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [keyValue, setKeyValue] = useState<string | undefined>();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [specialCategoryDataList, setSpecialCategoryDataList] = useState<DeviceTypes[]>([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const deviceId = urlSearchParams.get('deviceId') ?? undefined;
  const activeKey = urlSearchParams.get('activeKey') ?? undefined;
  const specialCategoryId = urlSearchParams.get('specialCategoryId') ?? undefined;

  // treeData
  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (tenantMcid && companyId) {
        const response = await getDeviceSceneTree({ deviceName: keyValue });
        if (!response.list || response.list.length === 0) {
          return [];
        } else {
          const { result: data, specialCategoryDataList } = formatTreeData(response.list);
          setSpecialCategoryDataList(specialCategoryDataList);
          // 默认展开树的前三级
          const defaulteExpandedKeys = initExpandedKeys(data);

          /* --- 默认高亮选中节点 --- */
          let selectedKey: string | undefined = undefined;
          // 存在特殊类目，默认选中该类目
          if (specialCategoryId) {
            selectedKey = getNodeKeyByCategoryId(data, specialCategoryId);
          }
          // 不存在特殊类目，存在设备Id，默认选中该设备
          else if (deviceId) {
            selectedKey = getNodeKeyByDeviceId(data, deviceId);
          }
          // 都不存在，默认选中第一个叶子节点；同时设置路由中的参数
          else {
            selectedKey = getFirstLeafKey(data);
          }

          if (selectedKey) {
            setSelectedKeys([selectedKey]);
            setExpandedKeys([selectedKey, ...defaulteExpandedKeys]);

            // 判断selectedKey是特殊类目级别还是设备级别
            const nodeId = selectedKey.split('-').pop()!;
            const path = getPathByNodeId(data, nodeId);
            if (selectedKey.includes('-device-')) {
              selectDevice(nodeId, path);
              navigate(
                qs.stringify(
                  {
                    deviceId: nodeId,
                    activeKey,
                  },
                  { addQueryPrefix: true }
                ),
                {
                  replace: true,
                }
              );
            } else {
              const specialCategoryData = specialCategoryDataList.find(item => item.id === Number(nodeId));
              selectCategory(nodeId, path, specialCategoryData?.devices ?? []);
              navigate(
                qs.stringify(
                  {
                    specialCategoryId: nodeId,
                    deviceId: deviceId || specialCategoryData?.devices[0].id,
                    activeKey,
                  },
                  { addQueryPrefix: true }
                ),
                {
                  replace: true,
                }
              );
            }
          } else {
            setExpandedKeys([...defaulteExpandedKeys]);
          }

          // 锚点定位, 根据唯一的key绑定id
          if (specialCategoryId && selectedKey) {
            attrAnchorToTreeNodeByKey(data, selectedKey, specialCategoryId);
          } else if (deviceId && selectedKey) {
            attrAnchorToTreeNodeByKey(data, selectedKey, deviceId);
          }

          return data;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantMcid, companyId, keyValue])
  );

  // 让锚点平滑滚到可视区域中间位置
  useEffect(() => {
    if (specialCategoryId && data && data.length > 0) {
      let anchor = document.getElementById(specialCategoryId);
      anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (deviceId && data && data.length > 0) {
      let anchor = document.getElementById(deviceId);
      anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [data, deviceId, specialCategoryId]);
  // 根据设备Id获取节点key
  const getNodeKeyByDeviceId = (data: DataNode[], deviceId: string): string | undefined => {
    let key: string = '';
    const loop = (nodeList: DataNode[]) => {
      for (const item of nodeList) {
        const id = item.key.toString().split('-device-')[1];
        if (id && Number(id) === Number(deviceId)) {
          key = String(item.key);
        }
        if (item.children) {
          loop(item.children);
        }
      }
    };
    loop(data);
    return key;
  };

  // 根据categoryId获取节点key
  const getNodeKeyByCategoryId = (data: DataNode[], categoryId: string): string | undefined => {
    let key: string = '';
    const loop = (nodeList: DataNode[]) => {
      for (const item of nodeList) {
        const id = item.key.toString().split('-product-')[1];
        if (id && Number(id) === Number(categoryId)) {
          key = String(item.key);
        }
        if (item.children) {
          loop(item.children);
        }
      }
    };
    loop(data);
    return key;
  };

  // 初始化展开的节点列表
  const initExpandedKeys = (data: DataNode[]) => {
    let keys: string[] = [];
    const loop = (nodeList: DataNode[], level: number = 0) => {
      for (const item of nodeList) {
        keys.push(String(item.key));
        if (level < 1 && item.children) {
          loop(item.children, level + 1);
        }
      }
    };
    loop(data);
    return keys;
  };

  // 根据节点的Id获取所有父节点
  const getPathByNodeId = (data: DataNode[], nodeId: string, path: string[] = []): string[] => {
    for (const node of data) {
      const id = node.key.toString().split('-').at(-1);
      path.push(node.title as string);
      if (id === nodeId) return path;
      if (node.children) {
        const findChildren = getPathByNodeId(node.children, nodeId, path);
        if (findChildren?.length) return findChildren;
      }
      path.pop();
    }
    return [];
  };

  // 获取树中第一个叶子节点
  const getFirstLeafKey = (data: DataNode[]) => {
    let nodeList = data;
    while (nodeList[0].children?.length) {
      nodeList = nodeList[0].children;
    }
    return String(nodeList[0].key);
  };

  const titleRender = (node: DataNode) => {
    return (node.title as string).length < 6 ? (
      (node.title as string)
    ) : (
      <Tooltip placement="right" title={node.title as string} align={{ offset: [24, 0] }}>
        <div style={{ maxWidth: 'fit-content' }}>{node.title as string}</div>
      </Tooltip>
    );
  };
  const theme = useTheme();

  // 选择节点
  const onSelect = (selectedKeys: React.Key[]) => {
    setSelectedKeys(selectedKeys as string[]);
    const key = String(selectedKeys[0]).split('-').pop();

    // 特殊类目，不在树中显示的数据
    if (key && specialCategoryDataList.map(item => item.id).includes(Number(key))) {
      const path = getPathByNodeId(data ?? [], key);
      const specialCategoryData = specialCategoryDataList.find(item => item.id === Number(key));
      selectCategory(key, path, specialCategoryData?.devices ?? []);
      navigate(
        qs.stringify(
          {
            specialCategoryId: key,
            deviceId: specialCategoryData?.devices[0].id,
            activeKey,
          },
          { addQueryPrefix: true }
        )
      );
      return;
    }

    if (key) {
      const path = getPathByNodeId(data ?? [], key);
      selectDevice(key, path);

      navigate(
        qs.stringify(
          {
            deviceId: key,
            activeKey,
          },
          { addQueryPrefix: true }
        )
      );
    }
  };

  return (
    <div className={classNames(className, styles.treeArea)} style={style}>
      <div className={styles.searchArea}>
        <Search placeholder="请输入设备名称" allowClear onSearch={(value: any) => setKeyValue(value)} />
      </div>
      <Spin spinning={isLoading}>
        <div className={`${styles.treeArea} ${theme === Themes.DARK ? styles.darkTreeArea : styles.lightTreeArea}`}>
          {data && data.length > 0 ? (
            <Tree
              autoExpandParent={autoExpandParent}
              titleRender={titleRender}
              treeData={data}
              blockNode
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={keys => {
                setExpandedKeys(keys as string[]);
                setAutoExpandParent(false);
              }}
              onSelect={onSelect}
              style={{ height: 'calc(100vh - 230px)' }}
            />
          ) : (
            <Empty style={{ marginTop: 30 }} description={'暂无数据'} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default DeviceTree;
