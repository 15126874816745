import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import leftIcon from '../../assets/leftIcon.png';
import rightIcon from '../../assets/rightIcon.png';
import { isNil } from 'lodash-es';
import { PieCostItem } from '../EnergyCost';

interface Props {
  detail: PieCostItem[];
}

// install Autoplay modules
SwiperCore.use([Autoplay]);

const EnergyStatisticsSwiper: React.FC<Props> = ({ detail }) => {
  const [slidesPerView, setSlidesPerView] = useState<number>(3);
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  useEffect(() => {
    setSlidesPerView(detail.length === 0 ? 1 : detail.length > 3 ? 3 : detail.length);
  }, [detail]);

  return (
    <div className={styles.energyStatisticsSwiper}>
      <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slidePrev()}>
        <img src={leftIcon} alt="pic" />
      </div>
      <Swiper
        spaceBetween={8}
        slidesPerView={slidesPerView}
        freeMode={true}
        observer={true}
        observeParents={true}
        onSwiper={swiper => setSwiperIntance(swiper)}
      >
        {detail.length === 0 ? (
          <SwiperSlide key={'0'}>
            <div className={styles.cardStyles}>
              <p
                style={{
                  color: 'rgba(255,255,255,0.65)',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                暂无数据
              </p>
            </div>
          </SwiperSlide>
        ) : (
          detail.map((m, index) => (
            <SwiperSlide key={m.energyMediumId}>
              <div className={styles.cardStyles}>
                <div className={styles.info}>
                  <div className={styles.firstRow}>
                    <p>{m.energyMediumName}</p>
                    {isNil(m.percentage) ? <p>--</p> : <p>{m.percentage}%</p>}
                  </div>
                  <div className={styles.secondRow}>
                    <p>{Number(m.consumption).toFixed(2)}</p>
                    <p>{m.generalName}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
      <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slideNext()}>
        <img src={rightIcon} alt="pic" />
      </div>
    </div>
  );
};

export default EnergyStatisticsSwiper;
