import { EllipsisSpan } from '@maxtropy/components';
import dayjs from 'dayjs';

// 生效表计
export const columns = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '表计标签',
    dataIndex: 'meterTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join('、')} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始计费时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '结束计费时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
];
