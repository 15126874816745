import {
  useBreadcrumbRoutes,
  Button,
  Modal,
  Select,
  PopConfirm,
  Form,
  Wrapper,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row, Space } from 'antd';

import styles from './index.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTemplateByVersion, getAllTemplateList, TemplateListProps, Template } from '../../../api/template';
import {
  getBatchDeviceTemplateProperty,
  BatchDeviceTemplatePropertyResponse,
  getBatchDeviceTemplateAttribute,
  updateBatchDeviceTemplateAttribute,
  BatchDeviceTemplateAttributeResponse,
} from '../../../api/batch';
import AttributeItem from '@/shared/components/AttributeItem';

import BaseInfo from '../components/BaseInfo';

import { FieldValue } from '@/shared/types';
import { formatValueFn, buildValueFn } from '@/shared/components/FieldItem/util';
import { CustomAttribute } from '@/shared/components/CustomAttributes/Editor';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';

const routes = [{ name: '固定信息' }];

const CreateAttributeInfo: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [templateList, setTemplateList] = useState<TemplateListProps[]>([]);
  const [currentTemplateId, setTemplateId] = useState<number>();
  const [currentVersion, setCurrentVersion] = useState<number>();
  const [template, setTemplate] = useState<Template>();
  const [form] = Form.useForm();
  const [templateForm] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeId, setTypeId] = useState<number>();

  const [batchDeviceTemplateProperty, setBatchDeviceTemplateProperty] = useState<BatchDeviceTemplatePropertyResponse>();

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateProperty(id).then(res => {
        setBatchDeviceTemplateProperty(res);
        setTypeId(res.typeId);
      });
    }
  }, [id]);

  useEffect(() => {
    if (batchDeviceTemplateProperty) {
      let { typeId, physicalModelId } = batchDeviceTemplateProperty;
      if (typeId) {
        getAllTemplateList(typeId, Number(physicalModelId)).then(res => setTemplateList(res.list ?? []));
      }
    }
  }, [batchDeviceTemplateProperty]);

  useEffect(() => {
    setTemplate(undefined);
    if (currentTemplateId !== undefined && currentVersion !== undefined) {
      getTemplateByVersion(currentTemplateId, currentVersion).then(res => {
        setTemplate(res.template);
      });
    }
    templateForm.setFieldsValue({
      template: currentTemplateId,
    });
  }, [currentTemplateId, currentVersion, templateForm]);

  const onTemplateChange = (value: number) => {
    setTemplateId(undefined);
    setCurrentVersion(undefined);
    if (templateList.length) {
      const template = templateList.find(i => i.id === value);
      if (template) {
        setTemplateId(value);
        setCurrentVersion(template.lastVersion);
      }
    }
  };

  const [batchDeviceTemplateAttribute, setBatchDeviceTemplateAttribute] =
    useState<BatchDeviceTemplateAttributeResponse>();

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateAttribute(id).then(res => {
        if (res) {
          setBatchDeviceTemplateAttribute(res);
          if (res.templateId && res.templateVersion) {
            setTemplateId(res.templateId);
            setCurrentVersion(res.templateVersion);
          }
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (template) {
      setCustomAttributes(template?.description ? JSON.parse(template?.description) : []);
    }
  }, [template]);

  const templateFlatten = useMemo(() => {
    if (template) {
      return template.deviceAttributes.map(i => i.deviceFields).flat();
    } else {
      return [];
    }
  }, [template]);

  useEffect(() => {
    if (batchDeviceTemplateAttribute) {
      if (batchDeviceTemplateAttribute.list && batchDeviceTemplateAttribute.list.length) {
        const data = batchDeviceTemplateAttribute.list.reduce((accumulator, currentValue) => {
          const findTemplate = templateFlatten.find(i => i.id === currentValue.fieldId);
          const buildValue = buildValueFn(currentValue.value!, findTemplate);
          accumulator[currentValue.fieldId] = { unitId: currentValue.unitId, value: buildValue };
          return accumulator;
        }, {} as Record<string, FieldValue>);
        form.setFieldsValue(data);
      }
    }
  }, [batchDeviceTemplateAttribute, form, templateFlatten]);

  useEffect(() => {
    if (batchDeviceTemplateAttribute) {
      if (batchDeviceTemplateAttribute.templateId) {
        setTemplateId(batchDeviceTemplateAttribute.templateId);
        setCurrentVersion(batchDeviceTemplateAttribute.templateVersion);
      }
    }
  }, [batchDeviceTemplateAttribute]);

  const onFinish = (type: boolean) => {
    form
      .validateFields()
      .then(() => {
        const v: { [key: string]: FieldValue } = form.getFieldsValue();
        const list = Object.entries(v).map(([fieldId, value]) => {
          const findTemplate = templateFlatten.find(i => i.id === Number(fieldId));
          const formatValue = formatValueFn(value.value, findTemplate);
          return { fieldId: Number(fieldId), unitId: value.unitId, value: formatValue };
        });
        updateBatchDeviceTemplateAttribute({
          batchTemplateId: Number(id),
          templateId: currentTemplateId!,
          templateVersion: currentVersion!,
          list,
        }).then(res => {
          if (type) {
            navigate(`/device/manage/batch/${res.batchTemplateId}/dataMining/new?hasPrevious=true&tabs=${tabs}`);
          } else {
            navigate(`/device/manage/batch?tabs=${tabs}`);
          }
        });
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onCancel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      okText: '确定',
      onOk: () => {
        navigate(`/device/manage/batch?tabs=${tabs}`);
      },
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="固定信息" />
      <SubContent>
        <BaseInfo data={batchDeviceTemplateProperty} />
      </SubContent>

      <SubContent title="固定信息">
        <Form labelAlign="left" form={templateForm}>
          <Row>
            <Col span={12}>
              <Form.Item name="template" label="模板选择">
                <Select placeholder="请选择" onChange={onTemplateChange}>
                  {templateList.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="版本号">
                {template && currentVersion
                  ? (
                      <Space>
                        <div className={styles.version}>{currentVersion}</div>
                        {template?.lastVersion > currentVersion && (
                          <div className={styles.tip}>
                            当前扩展信息模板版本号为 {currentVersion}，最新版本号为{template?.lastVersion}，可以
                            <PopConfirm
                              title={
                                <div className={styles.popConfirm}>
                                  <div className={styles.title}>确认更新?</div>
                                  <div className={styles.red}>更新至最新版本的模版，可能会导致</div>
                                  <div className={styles.gray}>
                                    <span className={styles.red}>部分信息丢失，</span>你还要继续吗？
                                  </div>
                                </div>
                              }
                              onConfirm={() => {
                                setCurrentVersion(template?.lastVersion);
                              }}
                              okText="继续"
                              cancelText="取消"
                            >
                              <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                点击更新
                              </Button>
                            </PopConfirm>
                            更新到最新版本
                          </div>
                        )}
                      </Space>
                    ) ?? '--'
                  : '--'}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="模版备注">{template ? template?.remark ?? '--' : '--'}</Form.Item>
            </Col>
          </Row>
        </Form>
      </SubContent>

      {template && (
        <>
          <SubContent>
            <div className={styles.body}>
              <div className={styles.list}>
                <Form form={form} validateTrigger="onBlur">
                  {template.deviceAttributes.map((item, index) => (
                    <AttributeItem key={index} disabled={false} attribute={item} />
                  ))}
                </Form>
              </div>
              {!!customAttributes.length && (
                <CustomAttributesDisplay style={{ marginTop: 30 }} customAttributes={customAttributes} />
              )}
            </div>
            <Space size={8} className="sticky-footer-left">
              {!isEdit && (
                <>
                  <Button type="primary">
                    <Link to={`/device/manage/batch/create/${id}?tabs=${tabs}`}>上一步</Link>
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      onFinish(true);
                    }}
                  >
                    保存并添加数采信息模板
                  </Button>
                </>
              )}
              <Button
                type="primary"
                onClick={() => {
                  onFinish(false);
                }}
              >
                确定
              </Button>
              <Button className={styles.button} onClick={onCancel}>
                取消
              </Button>
            </Space>
          </SubContent>
        </>
      )}
    </Wrapper>
  );
};

export default CreateAttributeInfo;
