import { Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';

import ElectricityContract from './Electricity';
import WorkingSubstanceContract from './WorkingSubstanceContract';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { useLocation } from 'react-router-dom';

// const routes = [
//   {name: '运营配置'},
//   {name: '能源费率管理'},
//   {name: '合同管理'},
// ];

type TabKey = PermissionsType.B_ELECTRICUSECONTRACT | PermissionsType.B_MEDIUMCONTRACT;

const Contract: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tabKey, setTabKey] = useState<TabKey>();
  const hasELECTRICUSECONTRACT = useHasPermission(PermissionsType.B_ELECTRICUSECONTRACT); // 用电合同
  const hasMEDIUMCONTRACT = useHasPermission(PermissionsType.B_MEDIUMCONTRACT); // 工质合同
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_Tab = urlSearchParams.get('tab') || undefined;

  const show = useMemo(() => {
    return hasELECTRICUSECONTRACT !== undefined && hasMEDIUMCONTRACT !== undefined;
  }, [hasELECTRICUSECONTRACT, hasMEDIUMCONTRACT]);

  useEffect(() => {
    if (hasELECTRICUSECONTRACT !== undefined && hasMEDIUMCONTRACT !== undefined) {
      if (hasELECTRICUSECONTRACT) {
        setTabKey(PermissionsType.B_ELECTRICUSECONTRACT);
        return;
      }
      if (hasMEDIUMCONTRACT) {
        setTabKey(PermissionsType.B_MEDIUMCONTRACT);
      }
    }
  }, [hasELECTRICUSECONTRACT, hasMEDIUMCONTRACT]);

  useEffect(() => {
    if (url_Tab === 'work') {
      setTabKey(PermissionsType.B_MEDIUMCONTRACT);
    }
  }, [url_Tab]);

  const tabItems = [
    {
      key: PermissionsType.B_ELECTRICUSECONTRACT,
      label: '用电合同',
      children: <ElectricityContract />,
      isShow: hasELECTRICUSECONTRACT,
    },
    {
      key: PermissionsType.B_MEDIUMCONTRACT,
      label: '工质合同',
      children: <WorkingSubstanceContract />,
      isShow: hasMEDIUMCONTRACT,
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      {show && <Tabs defaultActiveKey={tabKey} className={styles.tabs} items={tabItems.filter(i => i.isShow)} />}
    </Wrapper>
  );
};

export default Contract;
