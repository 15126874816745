import { Button, Radio, useUpdate } from '@maxtropy/components';
import { RadioChangeEvent, Spin } from 'antd';
import { FC, Key, useEffect, useRef, useState } from 'react';
import CircuitTabContent from '../CircuitTabContent';
import AddCircuitModal, { AddCircuitModalRef } from '../AddCircuitModal';
import styles from './index.module.scss';
import {
  CabinetListCircuitWithDataRes,
  distributionCabinetBandCircuits,
  getCabinetListCircuitWithData,
} from '@/api/distributionCabinetManage';
import { PlusOutlined } from '@ant-design/icons';

export interface ICircuitListProps {
  selectedDistributionId?: Key;
}

const CircuitList: FC<ICircuitListProps> = props => {
  const { selectedDistributionId } = props;
  const addCircuitRef = useRef<AddCircuitModalRef>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentKey, setCurrentKey] = useState<string>('1');
  const [circuitList, setCircuitList] = useState<CabinetListCircuitWithDataRes[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshState, refresh] = useUpdate();
  const timer = useRef<number>();

  // 每隔1min执行一次请求
  useEffect(() => {
    if (selectedDistributionId && currentKey) {
      clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        refresh();
      }, 60000);
    }
    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistributionId, currentKey, refreshState]);

  useEffect(() => {
    if (selectedDistributionId && currentKey) {
      setLoading(true);
      getCabinetListCircuitWithData({ id: selectedDistributionId, orderBy: Number(currentKey) }).then(res => {
        setCircuitList(res.list ?? []);
        setLoading(false);
      });
    }
  }, [selectedDistributionId, currentKey, refreshState]);

  const btnGroup = [
    {
      key: '1',
      label: '按电量',
    },
    {
      key: '2',
      label: '按功率',
    },
  ];

  const onOk = () => {
    if (addCircuitRef.current?.selectedRowKeys.length) {
      // 回路绑定配电柜
      distributionCabinetBandCircuits(
        selectedDistributionId!,
        addCircuitRef.current?.selectedRowKeys.map(i => i as number)
      ).then(_ => {
        setVisible(false);
        refresh();
      });
    }
  };

  return (
    <>
      {/* <div className={styles.tabStyle}>
        <Tabs
          size="small"
          type="card"
          tabBarExtraContent={operations}
          onTabClick={(key: string) => setCurrentKey(key)}
          items={tabs}
        />
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setCurrentKey(e.target.value);
          }}
          value={currentKey}
          buttonStyle="solid"
        >
          {btnGroup?.map(btn => (
            <Radio.Button key={btn.key} value={btn.key}>
              {btn.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        <Button type="primary" onClick={() => setVisible(true)} icon={<PlusOutlined />}>
          添加回路
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', paddingTop: 10 }}>
          <div className={styles.legend} style={{ marginRight: 24 }}>
            <div className={styles.Eleclegend}></div>
            <span>本日电量</span>
          </div>
          <div className={styles.legend}>
            <div className={styles.Powerlegend}></div>
            <span>实时功率</span>
          </div>
        </div>
      </div>

      <Spin spinning={loading}>
        <CircuitTabContent circuitList={circuitList} />
      </Spin>
      <AddCircuitModal
        ref={addCircuitRef}
        visible={visible}
        onOk={onOk}
        onCancel={() => setVisible(false)}
        selectedDistributionId={selectedDistributionId}
        circuitList={circuitList}
      />
    </>
  );
};

export default CircuitList;
