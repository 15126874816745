import { Space } from 'antd';
import React, { MutableRefObject, useRef, useState } from 'react';
import useMergedState from '../../../hooks/useMergedState';
import DeviceModal from './DeviceModal';
import { DeviceModalRef } from '@/pages/DaqDeployment/DeviceModal';
import { DriveType, IotProtocolType } from '../../../types';
import { Button, Modal } from '@maxtropy/components';

export interface DeviceSelectOtions {
  isMgmt: boolean;
  deviceType?: number[];
  rootMcid?: string;
  iotProtocol?: IotProtocolType;
  driveType?: DriveType;
  objectModalType?: number;
}

interface DeviceSelectProps {
  value?: number[];
  onChange?: (value?: number[]) => void;
  options?: DeviceSelectOtions;
  disabled?: boolean;
  onSelect?: (show: () => void) => void;
}

const DeviceSelect: React.FC<DeviceSelectProps> = props => {
  const [mergedValue, setMergedValue] = useMergedState([], { value: props.value, onChange: props.onChange });
  const [visible, setVisible] = useState(false);
  const deviceRef: MutableRefObject<DeviceModalRef | null> = useRef(null);
  const { disabled } = props;

  const hide = () => setVisible(false);
  const show = () => setVisible(true);
  const handleDeviceModal = () => {
    setMergedValue(deviceRef.current?.selectedRowKeys as number[]);
    hide();
  };

  return (
    <>
      <Space>
        {mergedValue && mergedValue.length > 0 && (
          <div>
            <span>已选择</span>
            <span style={{ color: '#d62500', fontWeight: 500, padding: '0 6px' }}>{mergedValue?.length || 0}</span>
            <span>个设备</span>
          </div>
        )}
        <Button type="link" onClick={() => props.onSelect?.(show)} style={{ paddingLeft: 0 }} disabled={disabled}>
          {mergedValue && mergedValue.length > 0 ? '继续选择' : '请选择'}
        </Button>
      </Space>
      <Modal size="big" title="设备选择" open={visible} destroyOnClose onOk={handleDeviceModal} onCancel={hide}>
        <DeviceModal
          deviceIds={mergedValue}
          ref={ref => {
            if (ref) {
              deviceRef.current = ref;
            }
          }}
          options={props.options}
        />
      </Modal>
    </>
  );
};

export default DeviceSelect;
