import ReactEcharts from 'echarts-for-react';
import { useMemo } from 'react';
import pieBg from '../../assets/pieBg.png';
import { PieCostItem } from '../EnergyCost';
interface Props {
  pieData: PieCostItem[];
  isTwoCol: boolean;
  outerRef: any;
}

const colors = ['#59DBFF', '#FF477B', '#6F47FF', '#FFCB47', '#57FB8B', '#477BFF'];

const PieEcharts: React.FC<Props> = ({ outerRef, pieData, isTwoCol }) => {
  const allCostData = pieData?.map(i => i.charges);
  const allCost = allCostData?.reduce((a, b) => a + b, 0);
  const isOverTenThousand = Number(allCost) >= 10000 || Number(allCost) <= -10000;

  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  const fontZoomSize = useMemo(() => {
    return zoom > 1 ? 12 * zoom : 12;
  }, [zoom]);

  const getChartOption = () => {
    return {
      tooltip: {
        // trigger: 'item',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: fontZoomSize,
        },
        formatter: (e: any) => {
          return `<div style='display:flex;align-items:center;justify-content:center' >
          <div style='width:${zoom * 12}px;height:${zoom * 12}px;background:${e.color};border-radius:50%;margin-right:${
            zoom * 10
          }px'></div> 
          <div>${isOverTenThousand ? Number((e.value ?? 0) / 10000).toFixed(2) : Number(e.value).toFixed(2)}</div>
          </div>`;
        },
      },
      legend: {
        orient: 'vertical',
        top: '20px',
        left: isTwoCol ? '48%' : '42%',

        textStyle: {
          fontSize: zoom > 1 ? 14 * zoom : 14,
          // fontWeight: 400,
          lineHeight: 20 * zoom,
          width: 280 * zoom,
          rich: {
            name: {
              // align: 'left',
              width: 100 * zoom,
              color: 'rgba(255,255,255,0.65)',
              fontSize: fontZoomSize,
            },
            b: {
              // align: 'right',
              width: 100 * zoom,
              // padding: [0, 20, 0, 0],
              color: '#FFFFFF',
              fontSize: fontZoomSize,
            },
            v: {
              // align: 'right',
              width: 60 * zoom,
              color: '#FFFFFF',
              fontSize: fontZoomSize,
            },
          },
        },
        formatter: (name: any) => {
          const item = pieData?.find(i => i.energyMediumName === name);
          return `{name|${name}} {b|${
            isOverTenThousand ? (Number(item?.charges ?? 0) / 10000).toFixed(2) : item?.charges.toFixed(2) ?? '--'
          }${isOverTenThousand ? '万元' : '元'} } {v|${((Number(item?.charges ?? 0) / allCost) * 100).toFixed(2)}%}`;
        },
        data: pieData?.map(i => ({
          name: i.energyMediumName,
          icon: 'circle',
        })),
      },
      title: {
        text: `{all|${isOverTenThousand ? (Number(allCost) / 10000).toFixed(2) : allCost.toFixed(2)}}`,
        subtext: `{allSub|总成本(${isOverTenThousand ? '万元' : '元'})}`,
        left: 42 * zoom,
        top: '37%',
        textStyle: {
          rich: {
            all: {
              align: 'center',
              width: 100 * zoom,
              fontSize: 18 * zoom,
              fontWeight: 600,
              color: '#fff',
              lineHeight: 25 * zoom,
            },
          },
        },
        subtextStyle: {
          rich: {
            allSub: {
              fontSize: 14 * zoom,
              fontWeight: 600,
              color: 'rgba(255,255,255,0.65)',
              lineHeight: 20 * zoom,
              align: 'center',
              width: 110 * zoom,
            },
          },
        },
      },
      graphic: {
        // 这个属性可以在饼图内部填充图片,文字等
        elements: [
          {
            type: 'image', //需要填充图片,配置image,如果不需要图片可以配置其他的, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,
            // origin: [98 * zoom, '52.5%'], //中心点
            // bounding: 'all', // 决定此图形元素在定位时，对自身的包围盒计算方式
            style: {
              image: pieBg, //这里添加图片地址
              width: 168 * zoom,
              height: 168 * zoom,
            },
            left: 14 * zoom,
            top: 14 * zoom,
          },
        ],
      },
      series: [
        {
          type: 'pie',
          radius: [60 * zoom, 76 * zoom],
          center: [98 * zoom, 98 * zoom],
          // avoidLabelOverlap: false, // 是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
          labelLine: {
            // 标签的视觉引导线配置。在 label 位置 设置为'outside'的时候会显示视觉引导线。
            show: false,
          },
          label: {
            show: false,
            // position: 'center',
          },
          itemStyle: {
            // borderRadius: 2,
            borderColor: '#232324',
            borderWidth: 2,
          },
          data: pieData?.map((i, index) => ({
            name: i.energyMediumName,
            value: i.charges,
            rate: Number(i.charges ?? 0) / allCost,
            itemStyle: {
              color: colors[index],
            },
          })),
          minAngle: 2,
          emphasis: {
            label: {
              show: false,
              fontSize: 10 * zoom,
              fontWeight: 'bold',
            },
          },
          emptyCircleStyle: {
            color: '#3F3F3F',
          },
        },
      ],
    };
  };
  return (
    <ReactEcharts
      option={getChartOption()}
      notMerge
      lazyUpdate={false}
      style={{ height: 180 * zoom }} // 样式的设置width与height
    />
  );
};

export default PieEcharts;
