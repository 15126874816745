import { Button, Form, FormTitle, SubContent, Tag, Tooltip, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Col, Progress, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import { isNil } from 'lodash';
import { apiV2EnergyUnitServerAlarmGetRecordPost } from '@maxtropy/device-customer-apis-v2';

const { Text } = Typography;

interface EnergyUnitServerAlarmGetRecordPostResponseItem {
  id?: number;
  /**
   * 报警规则主键
   */
  baseServerAlarmRuleId?: number;
  /**
   * 报警名称
   */
  name?: string;
  /**
   * 状态(0:禁用，1:启用)
   */
  status?: number;
  /**
   * 报告时间
   */
  alarmTime?: any;
  overRate?: number;
  /**
   * 报警场景
   */
  sceneType?: number;
  /**
   * 报警等级
   */
  alarmLevel?: 0 | 1 | 2 | 3 | 4;
  /**
   * 报警类型
   */
  alarmType?: number;
  /**
   * 触发类型
   */
  triggerType?: number;
  /**
   * 恢复时间
   */
  recoverTime?: string;
  /**
   * 额外字段
   */
  attributes?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 修改时间
   */
  updateTime?: string;
  ruleCode?: string;
  ruleName?: string;
  attribute?: {
    /**
     * 报警用能单元id
     */
    unitId?: number;
    /**
     * 报警用能单元
     */
    unitName?: string;
    /**
     * 报警类别 （用能报警/用能智能预警）
     */
    alarmTypeName?: string;
    /**
     * 周期
     */
    cycleName?: string;
    /**
     * 计量类别
     */
    meterTypeName?: string;
    /**
     * 设定限值
     */
    upperLimitValueStr?: string;
    /**
     * 实际值
     */
    actualValueStr?: string;
    /**
     * 超限度
     */
    overRate?: number;
    /**
     * 统计时间-起始
     */
    statisticsTimeStart?: string;
    /**
     * 统计时间-结束
     */
    statisticsTimeEnd?: string;
  };
}

const routes = [{ name: '查看报警' }];

const EnergyAlarmListDetail: React.FC = () => {
  const [recordInfo, setRecordInfo] = useState<EnergyUnitServerAlarmGetRecordPostResponseItem>();
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiV2EnergyUnitServerAlarmGetRecordPost({ id: Number(id) })
        .then(res => {
          setRecordInfo(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <Wrapper
      className={styles.wrapperPadding}
      isLoading={loading}
      routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}
    >
      <FormTitle title="查看报警" />
      <SubContent>
        <Form>
          <Row>
            <Col span={8}>
              <Form.Item name="recordName" label="报警消息">
                <div className={styles.messageTextStyle}>
                  <p>{recordInfo?.name}</p>
                </div>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="unitName" label="报警用能单元">
                {recordInfo?.attribute?.unitName}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="alarmLevel" label="报警等级">
                <Tag border="solid" color={AlarmLevelColorDisplay[recordInfo?.alarmLevel!]}>
                  {AlarmLevelDisplay[recordInfo?.alarmLevel!]}
                </Tag>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="alarmTypeName" label="报警类别">
                {recordInfo?.attribute?.alarmTypeName === '用能报警' ? (
                  <Tag border="solid" color={AlarmLevelColorDisplay[0]}>
                    {recordInfo?.attribute.alarmTypeName}
                  </Tag>
                ) : (
                  <Tag border="solid" color={AlarmLevelColorDisplay[1]}>
                    {recordInfo?.attribute?.alarmTypeName}
                  </Tag>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cycleName" label="周期">
                {recordInfo?.attribute?.cycleName}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="meterTypeName" label="计量类别">
                {recordInfo?.attribute?.meterTypeName}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="upperLimitValueStr" label="设定限值">
                {recordInfo?.attribute?.upperLimitValueStr}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="actualValueStr"
                label={recordInfo?.attribute?.alarmTypeName === '用能报警' ? '实际值' : '智能预计值'}
              >
                {recordInfo?.attribute?.actualValueStr}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="overRate"
                label={recordInfo?.attribute?.alarmTypeName === '用能报警' ? '超限率' : '预计超限率'}
              >
                {isNil(recordInfo?.attribute?.overRate) ? (
                  '--'
                ) : (
                  <Progress
                    style={{ marginLeft: 62 }}
                    percent={recordInfo ? recordInfo?.attribute?.overRate : undefined}
                    format={overRate => (
                      <div className={styles.progress_div}>
                        <Tooltip placement="top" title={`${recordInfo?.attribute?.overRate!}%`}>
                          <Text strong className={styles.ant_progress_text}>
                            {recordInfo?.attribute?.overRate!}%
                          </Text>
                        </Tooltip>
                      </div>
                    )}
                    status={recordInfo?.attribute?.overRate! > 100 ? 'exception' : 'active'}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="统计时间">
                {recordInfo?.attribute?.statisticsTimeStart} - {recordInfo?.attribute?.statisticsTimeEnd}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="alarmTime" label="报告时间">
                {dayjs(recordInfo?.alarmTime).format('YYYY-MM-DD HH:mm:ss')}
              </Form.Item>
            </Col>
          </Row>
          <Space>
            <Button onClick={() => navigate('/energy/analysisGroup/energyAlarmList')}>返回</Button>
          </Space>
        </Form>
      </SubContent>
    </Wrapper>
  );
};

export default EnergyAlarmListDetail;
