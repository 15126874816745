export enum LogType {
  // 物料单
  ENTRY = 1,
  // 投料单
  INPUT,
  // 报工单
  OUTPUT,
  // 单位配置
  UNITCONFIF,
  // 设备台账
  LEDGER,
  // mes数据
  MES
}
