import { fetch, Key } from '@maxtropy/components';

export interface MajorShowBtnConfigListReq {
  type?: AreaType; // 1重点区域 2光伏站 3储能站 4充电站
}

// 类型
export enum AreaType {
  MajorArea = 1, // 重点区域
  pvStation = 2, // 光伏站
  bsaStation = 3, // 储能站
  evStation = 4, // 充电站
  leanEMStation = 5, // 精益能管
  basicEMStation = 6, // 基础能管
}

// 类型的值
export const AreaTypeFormtter = {
  [AreaType.MajorArea]: '重点区域',
  [AreaType.pvStation]: '光伏站',
  [AreaType.bsaStation]: '储能站',
  [AreaType.evStation]: '充电站',
  [AreaType.leanEMStation]: '精益能管',
  [AreaType.basicEMStation]: '基础能管',
};

export interface MajorShowBtnConfigListRes {
  id: number;
  buttonName: string;
  url: string;
  remark: string;
  type: AreaType;
}

// 获取按钮列表
export const getPortalBtnList = async (query: MajorShowBtnConfigListReq) => {
  const res = await fetch<{ list: MajorShowBtnConfigListRes[] }>(
    `/api/v2/portal/button/list`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query }),
    },
    true
  );
  return res.list ?? [];
};

export interface UpdatePortalBtnReq {
  id: number; //id
  buttonName: string; //按钮名称
  url: string; //url
  remark: string; //备注
  type: AreaType; //按钮类型
}

// 编辑按钮
export function updatePortalBtn(params: UpdatePortalBtnReq) {
  return fetch(
    `/api/v2/portal/button/edit`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface AddBtnConfigReq {
  buttonName: string; //按钮名称
  url: string; //url
  remark: string; //备注
  type: AreaType; //按钮类型 1重点区域 2光伏站 3储能站 4充电站
}

// 添加按钮
export function addBtnConfig(params: AddBtnConfigReq) {
  return fetch(
    '/api/v2/portal/button/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 删除按钮
export function deleteBtnConfig(id: Key) {
  return fetch(
    `/api/v2/portal/button/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 类型
export enum StationType {
  PlatformArea = 1, // 平台数据
  ConfigArea = 2, // 配置数据
  Disable = 3, // 禁用
}

// 类型的值
export const StationTypeFormtter = {
  [StationType.PlatformArea]: '平台数据',
  [StationType.ConfigArea]: '配置数据',
  [StationType.Disable]: '不启用',
};

export enum BtnConfigActionType {
  ADDMAJOR = 'addMajor',
  ADDPV = 'addPv',
  ADDBSA = 'addBsa',
  ADDEV = 'addEv',
  ADDLEANEM = 'addLeanEM',
  ADDBASICEM = 'addBasicEM',
}

export interface PvBtnConfigReq {
  type: StationType; //1平台数据 2配置数据
  num?: number; //光伏站数量
  installedCapacity?: number; //装机容量
  pvInverterNum?: number; //逆变器数量
}

// 光伏区域配置
export function addOrEditPvBtnConfig(params: PvBtnConfigReq) {
  return fetch(
    '/api/v2/portal/pv/data/config/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface PvDataConfigRes {
  type: StationType; //1平台数据 2配置数据
  num: number; //光伏站数量
  installedCapacity: number; //装机容量
  pvInverterNum: number; //逆变器数量
}

// 获取光伏区域配置
export const getPvDataConfig = () => {
  return fetch<PvDataConfigRes>(
    `/api/v2/portal/pv/data/config/detail`,
    {
      method: 'POST',
    },
    true
  );
};

export interface BsaDataConfigRes {
  type: StationType; //1平台数据 2配置数据
  num: number; //光伏站数量
  bsaCellNum: number; // 电池芯数量
  installedCapacity: number; //装机容量
  ratedPower: number; //总额定功率
}

// 获取储能区域配置
export const getBsaDataConfig = () => {
  return fetch<BsaDataConfigRes>(
    `/api/v2/portal/bsa/data/config/detail`,
    {
      method: 'POST',
    },
    true
  );
};

export interface BsaBtnConfigReq {
  type: StationType; //1平台数据 2配置数据
  num?: number; //储能站数量
  bsaCellNum?: number; // 电池芯数量
  installedCapacity?: number; //装机容量
  ratedPower?: number; //总额定功率
}

// 储能区域配置
export function addOrEditBsaBtnConfig(params: BsaBtnConfigReq) {
  return fetch(
    '/api/v2/portal/bsa/data/config/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface EvDataConfigRes {
  type: StationType; //1平台数据 2配置数据
  num: number; //充电站数量
  installedCapacity: number; //装机容量
  chargingPileNum: number; //充电桩数量
}

// 获取充电区域配置
export const getEvDataConfig = () => {
  return fetch<EvDataConfigRes>(
    `/api/v2/portal/charging/data/config/detail`,
    {
      method: 'POST',
    },
    true
  );
};

export interface EvBtnConfigReq {
  type: StationType; //1平台数据 2配置数据
  num?: number; //充电站数量
  installedCapacity?: number; //装机容量
  chargingPileNum?: number; //充电桩数量
}

// 储能区域配置
export function addOrEditEvBtnConfig(params: EvBtnConfigReq) {
  return fetch(
    '/api/v2/portal/charging/data/config/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface LeanDataConfigRes {
  type: StationType; //1平台数据 2配置数据
  dayEnergyConsumption: number; //日综合能耗
  dayOutput: number; //日产量
  lineTotal: number; //总工作中心数
  lineOn: number; //一个小时内有产出的工作中心数
}

// 获取精益能管区域配置
export const getLeanDataConfig = () => {
  return fetch<LeanDataConfigRes>(
    `/api/v2/portal/leanManage/data/config/detail`,
    {
      method: 'POST',
    },
    true
  );
};

export interface LeanBtnConfigReq {
  type: StationType; //1平台数据 2配置数据
  dayEnergyConsumption?: number; //日综合能耗
  dayOutput?: number; //日产量
  lineTotal?: number; //总工作中心数
  lineOn?: number; //一个小时内有产出的工作中心数
}

// 精益能管区域配置
export function addOrEditLeanBtnConfig(params: LeanBtnConfigReq) {
  return fetch(
    '/api/v2/portal/leanManage/data/config/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface BasicEMDataConfigRes {
  type: StationType; //1平台数据 2配置数据
  circuitNum: number; //回路数
  waterMonitorNum: number; //水监测点数
  gasMonitorNum: number; //气监测点数
}

// 获取基础能管区域配置
export const getBasicEMDataConfig = () => {
  return fetch<BasicEMDataConfigRes>(
    `/api/v2/portal/basicManage/data/config/detail`,
    {
      method: 'POST',
    },
    true
  );
};

export interface BasicEMBtnConfigReq {
  type: StationType; //1平台数据 2配置数据
  circuitNum?: number; //回路数
  waterMonitorNum?: number; //水监测点数
  gasMonitorNum?: number; //气监测点数
}

// 精益能管区域配置
export function addOrEditBasicEMBtnConfig(params: BasicEMBtnConfigReq) {
  return fetch(
    '/api/v2/portal/basicManage/data/config/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
