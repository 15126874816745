import ResizeObserver from 'rc-resize-observer';
import { isEmpty } from 'lodash-es';
import { Empty } from '@maxtropy/components';
import Progress from './progress';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { V2OeeApplyGetOeeDayPostResponse } from '@maxtropy/device-customer-apis-v2';

export enum RankType {
  OEEPERCENT,
  SHUTDOWNTIME,
}

export const RankTypeDisplay = {
  [RankType.OEEPERCENT]: {
    unit: '%',
    colors: ['#2D8DFF', '#59FFFE'],
  },
  [RankType.SHUTDOWNTIME]: {
    unit: 'h',
    colors: ['#FFCB47', '#FFA97D'],
  },
};

export type ProgressList = V2OeeApplyGetOeeDayPostResponse['shutdownTimeList'];
export type ProgressData = Exclude<ProgressList, undefined>[number] & { percent?: number };

const CustomProgress = ({ data, type }: { data: any; type: RankType }) => {
  const [width, setWidth] = useState<number>(100);
  const [progressData, setProgressData] = useState<ProgressData[]>([]);

  useEffect(() => {
    if (isEmpty(data)) {
      setProgressData([]);
      return;
    }

    let progressData = data.slice(0, 3);

    if (type === RankType.SHUTDOWNTIME) {
      // 停机时长占比： 当前设备停机时长 / 总设备停机时长
      const totalShutdownTime = data.reduce((acc: number, cur: ProgressData) => acc + (cur.time || 0), 0);
      progressData = progressData.map((item: any) => ({
        ...item,
        percent: item.time ? Number(((item.time / totalShutdownTime) * 100).toFixed(2)) : item.time,
      }));
    }
    setProgressData(progressData);
  }, [data, type]);

  return isEmpty(data) ? (
    <Empty />
  ) : (
    <ResizeObserver onResize={({ width }) => setWidth(width - 20)}>
      <div>
        {progressData!.map((device, index) => (
          <div className={styles.list}>
            <span
              className={styles.rank}
              style={{ background: type === RankType.OEEPERCENT ? 'rgba(45, 141, 255, 0.3)' : 'rgba(255,203,71,0.3)' }}
            >
              0{index + 1}
            </span>
            <div className={styles.info}>
              <div className={styles.nameAndValue}>
                {device.deviceName}
                <span className={styles.value}>
                  {(device.time || device.percent) ?? '--'}
                  {RankTypeDisplay[type].unit}
                </span>
              </div>
              <Progress data={device} type={type} width={width} />
            </div>
          </div>
        ))}
      </div>
    </ResizeObserver>
  );
};

export default CustomProgress;
