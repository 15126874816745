import { DatePickerProps, Space, Spin } from 'antd';
import EnergyContent from '../EnergyContent';
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';

import diagnosis from '../../assets/diagnosis.png';
import { Button, DatePicker, Empty, Key } from '@maxtropy/components';
import { RangePickerProps } from 'antd/es/date-picker';
import { useRequest, useSize } from 'ahooks';
import { DoubleRightOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import { pollingInterval, scale } from '../../utils';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import qs from 'qs';
import {
  apiV2EnergyConsumptionOverviewEnergyFlowOverviewChartPost,
  apiV2EnergyConsumptionOverviewEnergyFlowOverviewInfoPost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  isFullScreen: boolean;
  isTwoCol: boolean;
  outerRef: any;
  configId?: Key;
  energyAnalysisUnitGroupId?: Key;
}

const sameStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const levelColor = ['#59DBFF', '#FF477B', '#477BFF', '#57FB8B'];

const Flow: React.FC<Props> = ({ energyAnalysisUnitGroupId, isFullScreen, isTwoCol, outerRef, configId }) => {
  const [date, setDate] = useState<Dayjs>(dayjs());
  const infoRef = useRef<HTMLDivElement>();
  const size = useSize(infoRef);

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      setDate(date);
    }
  };

  const [nodes, setNodes] = useState<any>([]);
  const [links, setLinks] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [containerHeight, setContainerHeight] = useState<number>();

  const hasEnergyFlowBtnTabPermission = useHasPermission(PermissionsType.B_ENERGYFLOWDETAIL);

  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  useEffect(() => {
    if (date && configId) {
      setLoading(true);
    }
  }, [date, configId]);

  const { data: flowOverviewInfoRes } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewEnergyFlowOverviewInfoPost({
          energyOverviewConfigId: configId,
          month: dayjs(date).endOf('month').format('YYYY-MM-DD'),
        }).then(res => {
          const { maxProportionUnitName, ...other } = res;
          const isAllEmpty = Object.values(other).every(i => (Array.isArray(i) && i.length === 0) || isNil(i));
          if (isAllEmpty) return undefined;
          return res;
        });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
    }
  );

  const { data: flowData } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewEnergyFlowOverviewChartPost({
          energyOverviewConfigId: configId,
          month: dayjs(date).endOf('month').format('YYYY-MM-DD'),
        })
          .then(res => {
            if (res) {
              const allNode = (res.nodes ?? []).map(i => {
                if (i.name === '用能损耗' || i.name === '未知能耗') {
                  return {
                    name: `${i.id} ${i.name}`,
                    value: i.value,
                    depth: i.level,
                    itemStyle: {
                      color: '#FFCE50',
                    },
                    lineStyle: {
                      color: 'source',
                      opacity: 0.3,
                    },
                  };
                }
                return {
                  name: `${i.id} ${i.name}`,
                  value: i.value,
                  depth: i.level,
                };
              });
              setNodes(allNode);
              const allLinks = (res.edges ?? []).map(i => ({
                source: `${i.sourceId} ${i.source}`,
                target: `${i.targetId} ${i.target}`,
                value: i.value,
              }));
              setLinks(allLinks);
              // ref.current?.scrollTo({ top: 56 });
              return res;
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
    }
  );

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const maxDepth = useMemo(() => {
    return Math.max(...nodes.map((i: any) => i.depth));
  }, [nodes]);

  const getChartOption = () => {
    return {
      labelLayout: {
        // hideOverlap: true,
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (v: any) => {
          const isTce = Number(flowOverviewInfoRes?.totalEnergyValue ?? 0) > 10000;
          const unit = isTce ? 'tce' : 'kgce';
          const value = isTce ? (Number(v.value ?? 0) / 1000).toFixed(2) : v.value;
          const nameArray = v.name?.split(' ').filter((i: any) => i !== '');

          let str = '';
          if (nameArray && nameArray.length === 2) {
            str = `${nameArray.at(1)}   ${value}${unit}`;
          }
          if (nameArray && nameArray.length > 2) {
            str = `${nameArray.at(1)} -> ${nameArray.at(nameArray.length - 1)}  ${value}${unit}`;
          }
          return str;
        },
      },
      series: [
        {
          type: 'sankey',
          // draggable: true,
          nodeWidth: 8,
          nodeGap: 16,
          left: 10,
          right: 120,
          bottom: 10,
          top: 20,
          // height: canvasHeight,
          label: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
            formatter: (v: any) => {
              const findMaxDepthItem = nodes.find((i: any) => i.depth === maxDepth);
              const nameFormatter = v?.name
                ?.split(' ')
                ?.filter((i: any) => i !== '')
                ?.at(1);
              return v.data.depth === findMaxDepthItem.depth && nameFormatter.length > 10
                ? nameFormatter.slice(0, 10) + '...'
                : nameFormatter;
            },
          },

          data: nodes,
          links: links,
          emphasis: {
            focus: 'adjacency',
          },
          levels: levelColor.map((i, index) => ({
            depth: index,
            itemStyle: {
              color: i,
            },
            lineStyle: {
              color: 'source',
              opacity: 0.3,
            },
          })),
          lineStyle: {
            curveness: 0.5,
          },
        },
      ],
    };
  };

  const twoColEchartHeight = useMemo(() => {
    return 520 - (size?.height ?? 0);
  }, [size]);

  const threeColEchartHeight = useMemo(() => {
    return 426 - (size?.height ?? 0);
  }, [size]);

  const FullScreenThreeColEchartHeight = useMemo(() => {
    return 520 - (size?.height ?? 0);
  }, [size]);

  const canvasHeight = useMemo(() => {
    if (isTwoCol) {
      return twoColEchartHeight;
    } else {
      if (document.body.clientWidth > 1920) {
        return FullScreenThreeColEchartHeight;
      } else {
        return threeColEchartHeight;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoColEchartHeight, threeColEchartHeight, FullScreenThreeColEchartHeight]);

  useEffect(() => {
    if (canvasHeight) {
      if (document.body.clientHeight >= 1080) {
        setContainerHeight(document.body.clientHeight - 780);
      } else {
        setContainerHeight(canvasHeight - 25);
      }
    }
  }, [canvasHeight]);

  return (
    <EnergyContent
      style={{ flex: 3 }}
      title="能流概览"
      extraContent={
        <Space size={8}>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            onChange={onDateChange}
            picker="month"
            value={date}
            getPopupContainer={() => outerRef}
          />
          {hasEnergyFlowBtnTabPermission && (
            <Button
              type="link"
              style={{ padding: '0' }}
              onClick={() => {
                window.open(
                  `/energy/analysis/unitSankey?${qs.stringify(
                    {
                      radioType: 2,
                      time: date.format('YYYY-MM'),
                      unitGroupId: energyAnalysisUnitGroupId,
                    },
                    { indices: false }
                  )} `,
                  '_self'
                );
              }}
            >
              查看详情
              <DoubleRightOutlined className={styles.translationStyles} />
            </Button>
          )}
        </Space>
      }
    >
      <div
        ref={dom => {
          if (dom) {
            infoRef.current = dom;
          }
        }}
        className={styles.info}
      >
        <Spin spinning={loading}>
          <div className={styles.title}>
            <img src={diagnosis} alt="pic" className={styles.icon} />
            <p>能流分析</p>
          </div>

          {flowOverviewInfoRes ? (
            <>
              <p
                style={{
                  color: 'rgba(255,255,255,0.85)',
                  marginBottom: 8,
                }}
              >
                <span style={{ color: '#FFFFFF' }}>{date ? dayjs(date).format('YYYY年MM月') : '--'}</span>总用能为
                <span
                  style={{
                    color: '#00ADFF',
                  }}
                >
                  {!isNil(flowOverviewInfoRes.totalEnergyValue)
                    ? Number(flowOverviewInfoRes.totalEnergyValue) > 10000
                      ? (Number(flowOverviewInfoRes.totalEnergyValue) / 1000).toFixed(2)
                      : Number(flowOverviewInfoRes.totalEnergyValue).toFixed(2)
                    : '--'}
                  {Number(flowOverviewInfoRes.totalEnergyValue) > 10000 ? 'tce' : 'kgce'}
                </span>
                ，其中用电
                <span
                  style={{
                    color: '#00ADFF',
                  }}
                >
                  {flowOverviewInfoRes.totalElectricityValue ?? '--'}kWh
                </span>
                。
                <span
                  style={{
                    color: '#00ADFF',
                  }}
                >
                  {flowOverviewInfoRes.maxProportionUnitName ?? '--'}
                </span>
                用能最高，占比
                <span
                  style={{
                    color: '#00ADFF',
                  }}
                >
                  {flowOverviewInfoRes.maxProportionValue ?? '--'}%
                </span>
                。
                {flowOverviewInfoRes.maxChangedCycleRatio && (
                  <>
                    <span
                      style={{
                        color: '#00ADFF',
                      }}
                    >
                      {flowOverviewInfoRes.maxChangedCycleRatioUnitName ?? '--'}
                    </span>
                    较上一个月用能环比
                    {flowOverviewInfoRes.maxChangedCycleRatio >= 0 ? (
                      <span
                        style={{
                          color: '#D89614',
                        }}
                      >
                        上升{flowOverviewInfoRes.maxChangedCycleRatio ?? '--'}%。
                      </span>
                    ) : (
                      <span
                        style={{
                          color: '#49AA19',
                        }}
                      >
                        下降{Math.abs(flowOverviewInfoRes.maxChangedCycleRatio) ?? '--'}%。
                      </span>
                    )}
                  </>
                )}
              </p>
              {flowOverviewInfoRes &&
              flowOverviewInfoRes.lossEnergyUnitNames &&
              flowOverviewInfoRes.lossEnergyUnitNames.length > 0 ? (
                <p>
                  经分析，
                  <span style={{ color: '#00ADFF' }}>{flowOverviewInfoRes.lossEnergyUnitNames.join('、')}</span>
                  对下游单元存在明显用能损耗，请检查用能情况和相关配置。
                </p>
              ) : null}
            </>
          ) : (
            <p className={styles.defaultInfo}>暂无数据</p>
          )}
        </Spin>
      </div>

      <div
        className={styles.chartContainerDiv}
        style={{
          margin: '10px 0 0 0',
          flex: isFullScreen || document.body.clientWidth > 1920 ? 1 : 'unset',
          display: 'flex',
          alignItems: isFullScreen || document.body.clientWidth > 1920 ? 'center' : 'unset',
          minHeight: 220,
          height: containerHeight,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {flowData && flowData.edges && flowData.nodes && flowData.edges.length > 0 && flowData.nodes.length > 0 ? (
          <ReactEcharts
            style={
              isTwoCol
                ? {
                    // height: twoColEchartHeight,
                    width: '100%',
                  }
                : isFullScreen
                ? document.body.clientWidth > 1920
                  ? {
                      // height: FullScreenThreeColEchartHeight,
                      width: '100%',
                      zoom: `${1 / zoom}`,
                      transform: `${document.body.clientWidth > 1920 ? `scale(${scale},${zoom})` : `scale(${scale})`}`,
                    }
                  : {
                      // height: FullScreenThreeColEchartHeight,
                      width: '100%',
                    }
                : document.body.clientWidth > 1920
                ? {
                    // height: threeColEchartHeight,
                    width: '100%',
                    zoom: `${1 / zoom}`,
                    transform: `${document.body.clientWidth > 1920 ? `scale(${scale},${zoom})` : `scale(${scale})`}`,
                  }
                : {
                    // height: threeColEchartHeight,
                    width: '100%',
                  }
            }
            option={getChartOption()}
            notMerge
            lazyUpdate={false}
          />
        ) : (
          <div
            style={
              isTwoCol
                ? { height: twoColEchartHeight, ...sameStyle }
                : isFullScreen
                ? {
                    height: 450,
                    ...sameStyle,
                  }
                : {
                    height: 260,
                    ...sameStyle,
                  }
            }
          >
            <Empty />
          </div>
        )}
      </div>
    </EnergyContent>
  );
};

export default Flow;
