import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

export enum StatusEnum {
  NOT_RUN = 1, // (1, "未开始"),
  RUNNING, // (2, "进行中"),
  FINISHED, // (3, "已完成"),
  FAILED, // (4, "执行失败");
}

export const StatusEnumDisplay = {
  [StatusEnum.NOT_RUN]: '待执行',
  [StatusEnum.RUNNING]: '执行中',
  [StatusEnum.FINISHED]: '已完成',
  [StatusEnum.FAILED]: '执行失败',
};

export interface MeterTaskPageItem {
  id: Key;
  lastReadingTime: string; // 上期抄表时间
  currentReadingTime: string; // 本期抄表时间
  nextReadingTime: string; // 下期抄表时间
  execTime: string; // 执行时间
  readingObjectName: string; // 抄表对象
  projectName: string; // 所属抄表项目
  status: StatusEnum;
  createTime: string;
  updateTime: string;
}

export interface MeterTaskPageReq extends PageRequest {
  lastStartDate?: string; //上期时间开始
  lastEndDate?: string; //上期时间结束
  currentStartDate?: string; //本期时间开始
  currentEndDate?: string; //本期时间结束
  projectId: Key; // 抄表项目ID
}

// 抄表任务分页查询
export const getMeterTaskPage = (body: MeterTaskPageReq) => {
  return fetch<PageResponse<MeterTaskPageItem>>(
    `/api/v2/universe/meter/reading/task/page`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export enum MeterPeriod {
  PER_YEAR = 1,
  PER_MONTH,
  PER_WEEK,
  PER_DAY,
}

export const MeterPeriodDisplay = {
  [MeterPeriod.PER_YEAR]: '按年',
  [MeterPeriod.PER_MONTH]: '按月',
  [MeterPeriod.PER_WEEK]: '按周',
  [MeterPeriod.PER_DAY]: '按日',
};

export interface MeterProjectListItem {
  id: Key;
  projectName: string; // 项目名称
  readingPeriod: MeterPeriod; // 抄表周期 1按年 2按月 3按周 4按日
  hierarchicalMeterReading: number; // 多层级对象抄表 1 :是 0 :否
}

// 抄表项目列表
export const getMeterProjectList = () => {
  return fetch<{ list: MeterProjectListItem[] }>(
    `/api/v2/universe/meter/reading/project/list`,
    {
      method: 'POST',
    },
    true
  );
};

export interface EnergyMediumTableDataItem {
  id: Key;
  itemId: Key; // 报表明细id
  meterReadingObjectId: Key; // 抄表对象id
  meterReadingObjectName: string;
  meterReadingLevel?: number; // 抄表对象层级
  deviceTypeName: string; // 设备类型名称
  deviceId: Key; // 设备id
  deviceCode: string; // 设备编号
  deviceName: string; // 设备名称
  topologyNodeName: string; // 拓扑节点名称
  dataPropertyName?: string | null; // 数据属性名称
  last?: number | null; // 上期抄见数
  current?: number | null; // 本期抄见数
  fact?: number | null; //实际抄表值
  adjust?: number | null; // 调整值
  afterAdjust?: number | null; // 调整后值
  share?: number | null; // 分摊值
  summitAdjust?: number | null; //尖调整值
  peakAdjust?: number | null; //峰调整值
  plainAdjust?: number | null; //平调整值
  valleyAdjust?: number | null; //谷调整值
  summitShare?: number | null; //尖分摊值
  peakShare?: number | null; //峰分摊值
  plainShare?: number | null; //平分摊值
  valleyShare?: number | null; //谷分摊值
  final?: number | null; // 最终值
  adjustReason?: string;
  isHightLight?: boolean;
  expand?: boolean; // 是否展开
  children?: EnergyMediumTableDataItem[];
  child?: EnergyMediumTableDataItem[];
}

export interface SummaryTableDataItem {
  id: Key;
  meterReadingObjectId: Key; // 抄表对象id
  meterReadingLevel?: number; // 抄表对象层级
  meterReadingObjectName: string;
  energyMediumId: Key; // 能源介质id
  energyMediumName: string; // 能源介质名称
  fact?: number | null;
  adjust?: number | null;
  afterAdjust?: number | null;
  share?: number | null;
  final?: number | null;
  summitShare?: number | null; //尖分摊值
  peakShare?: number | null; //峰分摊值
  plainShare?: number | null; //平分摊值
  valleyShare?: number | null; //谷分摊值
  expand?: boolean; // 是否展开
  children?: SummaryTableDataItem[];
  child?: SummaryTableDataItem[];
  isHightLight?: boolean;
}

export interface EnergyMediumListItem {
  mediumId: Key; // 能源工质id
  mediumName: string; // 能源工质名称
  physicalUnitName: string; // 物理单位
}
// 抄表能源介质列表
export const getEnergyMediumList = (taskId: Key) => {
  return fetch<{ list: EnergyMediumListItem[] }>(
    `/api/v2/universe/meter/reading/task/energyMedium/list`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

// 分项抄表数据列表
export interface EnergyMediumTableDataReq {
  taskId: Key; // 抄表任务ID
  mediumId: Key; // 能源工质ID
  level?: number; // 抄表对象层级
}

export interface EnergyMediumTableDataOriginRowItem {
  itemId: Key; // 报表明细id
  deviceTypeName: string; // 设备类型名称
  deviceId: Key; //设备id
  deviceCode: string; // 设备编号
  deviceName: string; // 设备名称
  topologyNodeName: string; // 拓扑节点名称
  dataPropertyName: string; // 数据属性名称
  lastPeriodReadingValue: number; // 上期抄见值
  currentPeriodReadingValue: number; // 本期抄见值
  realReadingAmount: number; // 实际抄表值
  adjustAmount: number; // 调整值 - 非电能
  summitAdjustAmount: number; // 尖调整值 - 电能
  peakAdjustAmount: number; // 峰调整值 - 电能
  plainAdjustAmount: number; // 平调整值 - 电能
  valleyAdjustAmount: number; // 谷调整值 - 电能
  afterAdjustAmount: number; // 调整后值
  adjustReason: string; // 调整原因
}
export interface EnergyMediumTableDataOriginListItem {
  meterReadingObjectId: Key; // 抄表对象id
  meterReadingObjectName: string; // 抄表对象名称
  meterReadingLevel?: number; // 抄表对象层级
  rows: EnergyMediumTableDataOriginRowItem[]; // 抄表对象下的数据
  summitApportionAmount: number; // 尖分摊值-电能
  peakApportionAmount: number; // 峰分摊值-电能
  plainApportionAmount: number; // 平分摊值-电能
  valleyApportionAmount: number; // 谷分摊值-电能
  apportionAmount: number; // 分摊值-非电能
  finalAmount: number; // 最终值
}

export interface EnergyMediumTableDataOriginRes {
  accumRealAmount: number; // 累计-实际抄表值
  accumAdjustAmount: number; // 累计-调整值
  accumAfterAdjustAmount: number; // 累计-调整后值
  accumApportionAmount: number; // 累计-分摊值
  accumFinalAmount: number; // 累计-最终值
  list: EnergyMediumTableDataOriginListItem[];
}

export const getEnergyMediumTableData = (body: EnergyMediumTableDataReq) => {
  return fetch<EnergyMediumTableDataOriginRes>(
    `/api/v2/universe/meter/reading/task/detail/medium`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export interface SummaryTableDataRowItem {
  energyMediumId: Key; //  能源介质id
  energyMediumName: string; //  能源介质名称
  realReadingAmount: number; //  实际抄表值
  adjustAmount: number; //  调整值
  afterAdjustAmount: number; //  调整后值
  apportionAmount: number; // 分摊值
  summitApportionAmount: number; // 尖分摊值
  peakApportionAmount: number; // 峰分摊值
  plainApportionAmount: number; // 平分摊值
  valleyApportionAmount: number; // 谷分摊值
  finalAmount: number; // 最终值
}

export interface SummaryTableDataListItem {
  meterReadingObjectId: Key; // 抄表对象id
  meterReadingLevel?: number; // 抄表对象层级
  parentMeterReadingObjectId?: Key; // 父级抄表对象id
  meterReadingObjectName: string; // 抄表对象名称
  rows: SummaryTableDataRowItem[]; // 汇总数据
}

export interface SummaryTableDataRes {
  list: SummaryTableDataListItem[];
}
// 汇总报表
export const getSummaryTableData = (taskId: Key, level?: number) => {
  return fetch<SummaryTableDataRes>(
    `/api/v2/universe/meter/reading/task/detail/gathering`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, level }),
    },
    true
  );
};

// 分时用电 抄表值

export interface TimeSharingElectricityRowItem {
  deviceTypeName: string; // 设备类型名称
  deviceId: Key; // 设备id
  deviceCode: string; // 设备编号
  deviceName: string; // 设备名称
  topologyNodeName: string; // 拓扑节点名称
  realReadingAmount: number; // 实际抄表值
  summitMeterReadingAmount: number; // 尖抄表值
  peakMeterReadingAmount: number; // 峰抄表值
  plainMeterReadingAmount: number; // 平抄表值
  valleyMeterReadingAmount: number; // 谷抄表值
  totalMeterReadingAmount: number; // 尖峰平谷合计
  checkAmount: number; // 核对差错
}
export interface TimeSharingElectricityListItem {
  meterReadingObjectId: Key; //  抄表对象id
  meterReadingObjectName: string; //  抄表对象名称
  parentMeterReadingObjectId?: Key; // 上级抄表对象id
  meterReadingLevel?: Key; // 抄表对象层级
  rows: TimeSharingElectricityRowItem[]; //  抄表对象下的数据
}

export interface TimeSharingElectricityRes {
  accumMeterReadingAmountSummit: number; // 累计-抄表值（尖）
  accumMeterReadingAmountPeak: number; // 累计-抄表值（峰）
  accumMeterReadingAmountPlain: number; // 累计-抄表值（平）
  accumMeterReadingAmountValley: number; // 累计-抄表值（谷）
  accumMeterReadingAmountTotal: number; // 累计-尖峰平谷合计
  accumRealAmount: number; // 累计-实际抄表值
  accumCheckAmount: number; // 累计-核对差值
  list: TimeSharingElectricityListItem[];
}

export interface TimeSharingElectricityTableDataItem extends TimeSharingElectricityRowItem {
  id: Key;
  meterReadingObjectId: Key; // 抄表对象id
  meterReadingObjectName: string; // 抄表对象名称
  meterReadingLevel?: Key; // 抄表对象层级
  isHightLight?: boolean;
  expand?: boolean;
  children?: TimeSharingElectricityTableDataItem[];
  child?: TimeSharingElectricityTableDataItem[];
}

export const getTimeSharingElectricityTableData = (taskId: Key, level?: number) => {
  return fetch<TimeSharingElectricityRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/meterReading`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, level }),
    },
    true
  );
};

// 分时用电 调整后值

export interface TimeSharingElectricityAfterAdjustRowItem {
  deviceTypeName: string; //  设备类型名称
  deviceId: Key; //  设备id
  deviceCode: string; //  设备编号
  deviceName: string; //  设备名称
  topologyNodeName: string; //  拓扑节点名称
  summitAfterAdjustAmount: number; //  尖调整后值
  peakAfterAdjustAmount: number; //  峰调整后值
  plainAfterAdjustAmount: number; //  平调整后值
  valleyAfterAdjustAmount: number; //  谷调整后值
  totalAfterAdjustAmount: number; //  尖峰平谷合计
  afterAdjustMeterReadingAmount: number; //  调整后抄表值
}
export interface TimeSharingElectricityAfterAdjustListItem {
  meterReadingObjectId: Key; //  抄表对象id
  meterReadingObjectName: string; //  抄表对象名称
  parentMeterReadingObjectId?: Key; // 上级抄表对象id
  meterReadingLevel?: Key; // 抄表对象层级
  rows: TimeSharingElectricityAfterAdjustRowItem[]; //  抄表对象下的数据
}

export interface TimeSharingElectricityAfterAdjustRes {
  accumAfterAdjustAmountSummit: number; //累计-调整后值（尖）
  accumAfterAdjustAmountPeak: number; //累计-调整后值（峰）
  accumAfterAdjustAmountPlain: number; //累计-调整后值（平）
  accumAfterAdjustAmountValley: number; //累计-调整后值（谷）
  accumAfterAdjustAmountTotal: number; //累计-尖峰平谷合计
  accumAfterAdjustMeterReadingAmount: number; //累计-调整后抄表值
  list: TimeSharingElectricityAfterAdjustListItem[];
}

export interface TimeSharingElectricityAfterAdjustTableDataItem extends TimeSharingElectricityAfterAdjustRowItem {
  id: Key;
  meterReadingObjectId: Key; //  抄表对象id
  meterReadingObjectName: string; //  抄表对象名称
  meterReadingLevel?: Key; // 抄表对象层级
  isHightLight?: boolean;
  expand?: boolean;
  children?: TimeSharingElectricityAfterAdjustTableDataItem[];
  child?: TimeSharingElectricityAfterAdjustTableDataItem[];
}

export const getTimeSharingElectricityAfterAdjustTableData = (taskId: Key, level?: number) => {
  return fetch<TimeSharingElectricityAfterAdjustRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/afterAdjust`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, level }),
    },
    true
  );
};

// 分时用电 最终值

export interface TimeSharingElectricityFinalListItem {
  meterReadingObjectId: Key; // 抄表对象id
  parentMeterReadingObjectId?: Key; // 上级抄表对象
  meterReadingLevel?: Key; // 抄表对象层级
  meterReadingObjectName: string; // 抄表对象名称
  finalAmountSummit: number; // 最终值（尖）
  finalAmountPeak: number; // 最终值（峰）
  finalAmountPlain: number; // 最终值（平）
  finalAmountValley: number; // 最终值（谷）
  finalAmountTotal: number; // 尖峰平谷合计
  afterApportionMeterReadingAmount: number; // 分摊后抄表值
}

export interface TimeSharingElectricityFinalRes {
  accumFinalAmountSummit: number; // 累计-最终值（尖）
  accumFinalAmountPeak: number; // 累计-最终值（峰）
  accumFinalAmountPlain: number; // 累计-最终值（平）
  accumFinalAmountValley: number; // 累计-最终值（谷）
  accumFinalAmountTotal: number; // 累计-尖峰平谷合计
  accumAfterApportionMeterReadingAmount: number; // 累计-分摊后抄表值
  list: TimeSharingElectricityFinalListItem[];
}

export const getTimeSharingElectricityFinalTableData = (taskId: Key, level?: number) => {
  return fetch<TimeSharingElectricityFinalRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/final`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, level }),
    },
    true
  );
};

// 调整值
export interface AdjustBody {
  itemId: Key; // 报表明细id
  adjustType: number; // 调整方式 1非电能 2电能
  adjustAmount?: number | null; // 调整值 - 非电能
  adjustAmountSummit?: number | null; // 调整值（尖） - 电能
  adjustAmountPeak?: number | null; // 调整值（峰） - 电能
  adjustAmountPlain?: number | null; // 调整值（平） - 电能
  adjustAmountValley?: number | null; // 调整值（谷） - 电能
  adjustReason?: string; // 调整原因
}

export const adjustFunc = (body: AdjustBody) => {
  return fetch(
    `/api/v2/universe/meter/reading/task/detail/adjustValue`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 抄见数变更
export interface MeterReadingAmountChangeBody {
  list: {
    itemId: Key; // 报表明细id
    updateMeterReadingAmount?: number | null; // 修改后抄见数
  }[];
}

export const meterReadingAmountChangeFunc = (body: MeterReadingAmountChangeBody) => {
  return fetch(
    `/api/v2/universe/meter/reading/task/detail/meterReadingValue/batchUpdate`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 批量调整
export interface BatchAdjustBody {
  list: AdjustBody[];
  adjustReason?: string; // 调整原因
}

export const batchAdjustFunc = (body: BatchAdjustBody) => {
  return fetch(
    `/api/v2/universe/meter/reading/task/detail/adjustValue/batchUpdate`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 分摊录入
export interface ApportionListItem {
  taskId: Key; // 抄表任务id
  objectId: Key; // 抄表对象id
  energyMediumId: Key; // 能源介质id
  adjustType: number; // 调整方式 1非电能 2电能
  apportionAmount?: number | null; // 分摊值 - 非电能
  apportionAmountSummit?: number | null; // 分摊值（尖） - 电能
  apportionAmountPeak?: number | null; // 分摊值（峰） - 电能
  apportionAmountPlain?: number | null; // 分摊值（平） - 电能
  apportionAmountValley?: number | null; // 分摊值（谷） - 电能
}
export interface ApportionBody {
  list: ApportionListItem[];
}

export const apportionFunc = (body: ApportionBody) => {
  return fetch(
    `/api/v2/universe/meter/reading/task/detail/apportion/batchUpdate`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 抄表任务基础信息
export interface MeterTaskBaseInfo {
  projectId: Key; // 所属抄表项目
  projectName: string; // 所属抄表项目名称
  readingPeriod: MeterPeriod; // 所属抄表项目的抄表周期 抄表周期 1按年 2按月 3按周 4按日
  readingPeriodName: string; // 所属抄表项目的抄表周期名称
  lastReadingTime: string; // 抄表任务-上期抄表时间
  currentReadingTime: string; // 抄表任务-本期抄表时间
  hierarchicalMeterReading: number; // 多层级对象抄表 1 :是 0 :否
  previousTaskId: Key | null; // 前一期抄表任务id / null表示没有
  nextTaskId: Key | null; // 后一期抄表任务id / null表示没有
  nextTaskStatus: StatusEnum; // 下一期状态
  previousTaskStatus: StatusEnum; // 上一期状态
}

export const getMeterTaskBaseInfo = (taskId: Key) => {
  return fetch<MeterTaskBaseInfo>(
    `/api/v2/universe/meter/reading/task/detail/basic`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

// 重新执行
export const reExecuteFunc = (taskId: Key) => {
  return fetch(
    `/api/v2/universe/meter/reading/task/reExec`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

export interface TotalEnergyMediumTableDataListItem extends EnergyMediumTableDataOriginListItem {
  list: TotalEnergyMediumTableDataListItem[];
}

export interface TotalEnergyMediumTableDataRes {
  list: TotalEnergyMediumTableDataListItem[];
}

// 分项抄表-总视图能源工质
export const getTotalEnergyMediumTableData = (taskId: Key, mediumId: Key) => {
  return fetch<TotalEnergyMediumTableDataRes>(
    `/api/v2/universe/meter/reading/task/detail/medium/all`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, mediumId }),
    },
    true
  );
};

export interface TotalSummaryTableDataListItem extends SummaryTableDataListItem {
  list: TotalSummaryTableDataListItem[];
}

export interface TotalSummaryTableDataRes {
  list: TotalSummaryTableDataListItem[];
}

// 汇总报表-总视图
export const getTotalSummaryTableData = (taskId: Key) => {
  return fetch<TotalSummaryTableDataRes>(
    `/api/v2/universe/meter/reading/task/detail/gathering/all`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

// 分时用电-抄表值-总视图
export interface TotalTimeSharingElectricityListItem extends TimeSharingElectricityListItem {
  list: TotalTimeSharingElectricityListItem[];
}

export interface TotalTimeSharingElectricityRes {
  list: TotalTimeSharingElectricityListItem[];
}

export const getTotalTimeSharingElectricityTableData = (taskId: Key) => {
  return fetch<TotalTimeSharingElectricityRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/meterReading/all`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

// 分时用电-调整后值-总视图
export interface TotalTimeSharingElectricityAfterAdjustListItem extends TimeSharingElectricityAfterAdjustListItem {
  list: TotalTimeSharingElectricityAfterAdjustListItem[];
}

export interface TotalTimeSharingElectricityAfterAdjustRes {
  list: TotalTimeSharingElectricityAfterAdjustListItem[];
}

export const getTotalTimeSharingElectricityAfterAdjustTableData = (taskId: Key) => {
  return fetch<TotalTimeSharingElectricityAfterAdjustRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/afterAdjust/all`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

// 分时用电-最终值-总视图

export interface TotalTimeSharingElectricityFinalListItem extends TimeSharingElectricityFinalListItem {
  list: TotalTimeSharingElectricityFinalListItem[];
}

export interface TotalTimeSharingElectricityFinalRes {
  list: TotalTimeSharingElectricityFinalListItem[];
}

export const getTotalTimeSharingElectricityFinalTableData = (taskId: Key) => {
  return fetch<TotalTimeSharingElectricityFinalRes>(
    `/api/v2/universe/meter/reading/task/detail/timeDivision/final/all`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId }),
    },
    true
  );
};

export interface TotalTimeSharingElectricityFinalTableItem extends TimeSharingElectricityFinalListItem {
  isHightLight?: boolean;
  expand?: boolean;
  children?: TimeSharingElectricityFinalListItem[];
  child?: TimeSharingElectricityFinalListItem[];
}

// 层级列表
export interface MeterReadingObjectLevelList {
  levels: number[]; // 任务下抄表对象层级
}

export const getMeterReadingObjectLevelList = (taskId: Key, mediumId?: Key | null) => {
  return fetch<MeterReadingObjectLevelList>(
    `/api/v2/universe/meter/reading/task/detail/levels`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId, mediumId }),
    },
    true
  );
};
