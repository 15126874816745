import { PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  usePaging,
  Table,
  Button,
  Form,
  Modal,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { ReactText, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';

import dayjs from 'dayjs';
import {
  ElectricitySaleContractPageSearchRequest,
  ElectricitySaleContractStatusType,
  ElectricitySaleContractVo,
  getElectricitySalesContractList,
  getPropertyListOwned,
  PropertyItem,
} from '../../../../api/electricitySalesContract';
import { getSaleClientList, SaleClientInfo } from '../../../../api/sale';
import { Link } from 'react-router-dom';
import {
  getSaleElectricitySettlementRuleListByConId,
  SaleElectricitySettlementRuleListByConIdRes,
} from '../../../../api/electricitySettlementRules';

interface Props {
  setSettlementRuleList: (v: SaleElectricitySettlementRuleListByConIdRes[]) => void;
  maxContract: number;
}

export interface SearchParams extends Omit<ElectricitySaleContractPageSearchRequest, 'page' | 'size'> {}

const DATE_FORMAT = 'YYYY-MM-DD';

const columns = [
  {
    title: '合同编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '位置/房号',
    dataIndex: 'address',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属物业',
    dataIndex: 'propertyManagementConfigName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同有效期',
    dataIndex: 'contractValidity',
    ellipsis: { showTitle: true },
    render: (v: undefined, record: ElectricitySaleContractVo) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format(DATE_FORMAT)} ~ ${dayjs(record.endTime).format(DATE_FORMAT)}`}
      />
    ),
  },
];

const modalColumns = [
  {
    title: '合同编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: ElectricitySaleContractStatusType, record: ElectricitySaleContractVo) => {
      let statusText = '';
      switch (v) {
        case ElectricitySaleContractStatusType.INEFFECTIVE:
          statusText = '未生效';
          break;
        case ElectricitySaleContractStatusType.EFFECTIVE:
          statusText = '生效中';
          break;
        case ElectricitySaleContractStatusType.EXPIRED:
          statusText = '已过期';
          break;
        case ElectricitySaleContractStatusType.ABORTED:
          statusText = '已终止';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '位置/房号',
    dataIndex: 'address',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属物业',
    dataIndex: 'propertyManagementConfigName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同有效期',
    dataIndex: 'contractValidity',
    ellipsis: { showTitle: true },
    render: (v: undefined, record: ElectricitySaleContractVo) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format(DATE_FORMAT)} ~ ${dayjs(record.endTime).format(DATE_FORMAT)}`}
      />
    ),
  },
];

const SelectContract: React.FC<Props> = ({ setSettlementRuleList, maxContract }) => {
  const form = Form.useFormInstance();
  const selectContract: ElectricitySaleContractVo[] = Form.useWatch('selectContract', form);
  const [searchForm] = Form.useForm(); // 搜索的表单
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ElectricitySaleContractVo[]>();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [propertyList, setPropertyList] = useState<PropertyItem[]>(); // 所属物业管理列表
  const [contractModalData, setContractModalData] = useState<ElectricitySaleContractVo[]>([]);

  useEffect(() => {
    getSaleClientList().then(setCustomList);
    getPropertyListOwned().then(setPropertyList);
  }, []);

  const customOptions = useMemo(() => {
    if (customList?.length !== 0) {
      return customList?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [customList]);

  useEffect(() => {
    setSelectedRows(selectContract ?? []);
    if (selectContract && selectContract.length > 0) {
      getSaleElectricitySettlementRuleListByConId(selectContract[0].id).then(res => {
        if (res.list?.length !== 0) {
          setSettlementRuleList(res.list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectContract]);

  // 所属物业选项
  const propertyOptions = useMemo(() => {
    return propertyList?.map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [propertyList]);

  useEffect(() => {
    setLoading(true);
    getElectricitySalesContractList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        setContractModalData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize, pageOffset, searchParams, setTotalCount]);

  const onOk = () => {
    if (selectedRows && selectedRows.length > 0) {
      form.setFieldsValue({ selectContract: selectedRows });
      getSaleElectricitySettlementRuleListByConId(selectedRows[0].id).then(res =>
        setSettlementRuleList(res.list ?? [])
      );
      setVisible(false);
    } else {
      setVisible(false);
    }
  };

  const onClose = () => {
    setVisible(false);
    setSearchParams(undefined);
    searchForm.resetFields();
    setSelectedRows(selectContract);
  };

  const editColumns = [
    ...columns,
    ...[
      {
        title: '操作',
        dataIndex: 'operation',
        width: 200,
        fixed: 'right' as const,
        render: (v: undefined, record: ElectricitySaleContractVo) => {
          return (
            <Space size={16}>
              <Button type="link">
                <Link target="_blank" to={`/operation/salesContract/electricity/detail/${record.id}`}>
                  查看详情
                </Link>
              </Button>
              <Button
                type="link"
                onClick={() => {
                  const data = selectContract!.filter((item: ElectricitySaleContractVo) => item.id !== record!.id);
                  // 单个合同情况下，移除时，清空选项
                  form.resetFields();
                  form.setFieldsValue({ selectContract: data });
                  setSelectedRows(data);
                  setSettlementRuleList([]);
                }}
              >
                移除
              </Button>
            </Space>
          );
        },
      },
    ],
  ];

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onReset = () => {
    searchForm.resetFields();
    setSearchParams(searchForm.getFieldsValue(true));
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: ReactText[], rows: ElectricitySaleContractVo[]) => {
    setSelectedRows(rows.filter(item => item));
  };

  const rowSelection = {
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows?.map(item => item.id),
    onChange: onSelectChange,
    getCheckboxProps: (record: ElectricitySaleContractVo) => {
      const selectedRowIds = (selectedRows ?? []).map(i => i.id);
      const selectContractIds = (selectContract ?? []).map(i => i.id);
      return {
        disabled:
          (selectContract ?? []).findIndex(i => i.id === record.id) !== -1 ||
          (Array.from(new Set([...selectedRowIds, ...selectContractIds])).length >= maxContract &&
            !(selectedRowIds.filter(i => !selectContractIds.includes(i)).findIndex(i => i === record.id) !== -1)),
      };
    },
  };

  return (
    <>
      <Space direction="vertical">
        <Button type="primary" onClick={() => setVisible(true)}>
          <PlusOutlined />
          添加合同
        </Button>
      </Space>
      {selectContract && selectContract.length !== 0 && (
        <Table
          rowKey={`id`}
          sticky
          loading={false}
          dataSource={selectContract}
          columns={editColumns}
          style={{ marginBottom: '44px', marginTop: 8 }}
        />
      )}
      <Modal title={`合同选择`} size="big" open={visible} onCancel={onClose} destroyOnClose onOk={onOk}>
        <div>
          <CustomFilter colSpan={8} form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
            <Form.Item name="code" label="合同编号">
              <Input placeholder=" 请输入" />
            </Form.Item>

            <Form.Item label="所属客户" name="saleClientId">
              <Select placeholder="请选择" options={customOptions} showSearch optionFilterProp="label" />
            </Form.Item>

            <Form.Item label="所属物业" name="propertyManagementConfigId">
              <Select placeholder="请选择" options={propertyOptions} />
            </Form.Item>

            <Form.Item name="address" label="位置/房号">
              <Input placeholder="请输入" />
            </Form.Item>
          </CustomFilter>
        </div>
        <div className={styles.content}>
          <Table
            showSelectedCount
            selectedCount={selectedRows?.length || 0}
            rowKey="id"
            loading={loading}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            scroll={{ y: 300 }}
            sticky
            columns={modalColumns}
            dataSource={contractModalData}
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default SelectContract;
