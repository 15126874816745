import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Tooltip, Col, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MentionsInput, Mention, MentionItem } from 'react-mentions';
import { uniqBy } from 'lodash-es';
import useMergedState from '../../hooks/useMergedState';
import classnames from 'classnames';
import './index.scss';

interface DataSourceProps {
  id: string;
  display: string;
  description: string;
}

interface FormulaInputOfAlarmProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  dataProperties: DataSourceProps[];
  onBlur?: () => void;
}

const FormulaInputOfAlarm: React.FC<FormulaInputOfAlarmProps> = props => {
  const prefixCls = 'mx-mentions';
  const { dataProperties } = props;
  const [mergedValue, setMergedValue] = useMergedState('', { value: props.value, onChange: props.onChange });
  const [dataSource, setDataSource] = useState<DataSourceProps[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    generateDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProperties]);

  const displayTransform = (id: string, display: string) => {
    return dataSource.find(item => item.id == id)?.display || id;
  };

  const renderSuggestion = (
    suggestion: any,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    const { display, description } = suggestion;
    if (focused) {
      window.requestAnimationFrame(() => {
        const item = document.getElementsByClassName(`${prefixCls}__suggestions__item-focused`).item(0);
        item?.scrollIntoView({ block: 'nearest' });
      });
    }
    return (
      <div
        className={classnames('${prefixCls}__suggestions__item', {
          [`${prefixCls}__suggestions__item-focused`]: focused,
        })}
      >
        <div className="point">
          <div className="display">{display}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    );
  };

  const generateDataSource = useCallback(() => {
    const dataSource = uniqBy([...dataProperties], 'id');
    setDataSource(dataSource);
  }, [dataProperties]);

  const onChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setMergedValue(newValue);
  };

  return (
    <Row wrap={false}>
      <Col ref={containerRef} style={{ flex: 1 }}>
        <MentionsInput
          onBlur={props.onBlur}
          allowSuggestionsAboveCursor
          value={mergedValue}
          onChange={onChange}
          placeholder="请输入"
          rows={4}
          className={classnames(prefixCls)}
          inputRef={(el: any) => {
            el?.classList.add('ant-input');
          }}
          suggestionsPortalHost={containerRef.current as Element}
        >
          <Mention
            data={dataSource}
            trigger="_"
            markup={'@[__id__]@'}
            appendSpaceOnAdd
            displayTransform={displayTransform}
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
      </Col>
      <Tooltip
        overlayStyle={{ width: 560, maxWidth: 560, height: 600, overflowX: 'hidden', overflowY: 'auto' }}
        placement="left"
        title={
          <div>
            <h3 style={{ color: '#FFFFFF' }}>公式说明</h3>
            <h4 style={{ color: '#FFFFFF' }}>示例</h4>
            <div>
              <div>{`_A相电压 > 10`}</div>
              <div>{`代表<A相电压>大于10`}</div>
              <div>{`_A相电压 > 200 && _A相电压 < 240`}</div>
              <div>{`代表<A相电压>大于200且小于240`}</div>
              <div>{`avg2(_A相电压, _B相电压) < 200`}</div>
              <div>{`代表<A相电压>和<B相电压>的平均值小于200`}</div>
              <div>{`_设备状态 == 0`}</div>
              <div>{`代表<设备状态>离线`}</div>
              <div>{`dailyTimeInterval(08:00,11:00) && A相电压 > 100`}</div>
              <div>{`每天8点-10点 满足A相电压>100触发报警`}</div>
              <div>{`(dailyTimeInterval(08:00,11:00) || dailyTimeInterval(13:00,17:00) ) && A相电压 > 100`}</div>
              <div>{`每天8点-10点 或 每天13点-17点 满足A相电压>100触发报警`}</div>
              <div>{`(dailyTimeInterval(08:00,11:00) && weekTimeInterval(1,4)) && A相电压 > 100`}</div>
              <div>{`每周一到周四 每天8点-10点 满足A相电压>100触发报警`}</div>
              <div>{`(dailyTimeInterval(08:00,11:00) && A相电压 > 100) || (dailyTimeInterval(13:00,17:00) && A相电压 < 60) `}</div>
              <div>{`每天8点-10点 满足A相电压>100 或者 每天13点-17点 满足A相电压<60 触发报警`}</div>
              <div>当输入的报警公式成立时，则设备触发报警。</div>
            </div>
            <br />
            <h4 style={{ color: '#FFFFFF' }}>详细说明</h4>
            <Space size={[0, 10]} direction="vertical">
              <div>
                <div>{`1. 输入下划线，可以调出数据属性列表，点击选择。`}</div>
                <div>{`_A相电压 代表数据属性为A相电压的数据属性，在下文中，统一描述为<数据属性1>`}</div>
              </div>

              <div>
                <div>{`2. 运算符（按优先级）：`}</div>
                <ul>
                  <li>{`!(非), ~(位取反)`}</li>
                  <li>{`*(乘), /(除), %(模)`}</li>
                  <li>{`+(加), -(减)`}</li>
                  <li>{`<< >> 左移右移`}</li>
                  <li>{`<(小于), <=(小于等于), >(大于), >=(大于等于)`}</li>
                  <li>{`==(等于), !=(不等于)`}</li>
                  <li>{`& 按位与`}</li>
                  <li>{`^ 按位异或`}</li>
                  <li>{`| 按位或`}</li>
                  <li>{`&&(与), ||(或)`}</li>
                </ul>
              </div>

              <div>
                <div>3. 函数表</div>
                <ul>
                  <li>{`avg2(_A相电压, _B相电压)  <数据属性1>和<数据属性2>的平均值`}</li>
                  <li>{`avg3(_A相电压, _B相电压, 400)  <数据属性1>、<数据属性2>和400的平均值`}</li>
                  <li>{`avg(_A相电压, _B相电压, 400)  <数据属性1>、<数据属性2>和400的平均值(不限个数)。avg系列函数参数顺序不限。`}</li>
                  <li>{`max2(_A相电压, 25)  <数据属性1>和25的较大值。`}</li>
                  <li>{`max3(_A相电压, _B相电压, 25)  <数据属性1>、<数据属性2>和25的较大值。`}</li>
                  <li>{`max(_A相电压, 25)  <数据属性1>和25的较大值(不限个数).max系列函数参数顺序不限。`}</li>
                  <li>{`min(_A相电压, 0.0)  <数据属性1>和0.0的较小值。min系列函数和max类似，有min2,min3`}</li>
                  <li>{`pow(10,_A相电压)  10的<数据属性1>次幂`}</li>
                  <li>{`abs(_A相电压)  <数据属性1>的绝对值`}</li>
                  <li>{`sqrt(_A相电压)  <数据属性1>的0.5次幂`}</li>
                  <li>{`sin(_A相电压)  <数据属性1>的正弦(弧度)`}</li>
                  <li>{`cos(_A相电压)  <数据属性1>的余弦(弧度)`}</li>
                  <li>{`tan(_A相电压)  <数据属性1>的正切(弧度)`}</li>
                  <li>{`asin(_A相电压)  <数据属性1>的反正弦(弧度)`}</li>
                  <li>{`acos(_A相电压)  <数据属性1>的反余弦(弧度)`}</li>
                  <li>{`atan(_A相电压)  <数据属性1>的反正切(弧度)`}</li>
                  <li>{`if(_A相电压, 1, -1) <数据属性1>不为0.0(即_1 != false)时返回1，否则返回-1。`}</li>
                  <li>{`up(_A相电压) 开关量0->1`}</li>
                  <li>{`down(_A相电压) 开关量1->0`}</li>
                  <li>{`dailyTimeInterval(开始时段, 结束时段) 分时段报警(按日) 时间格式为HH:mm, 比如dailyTimeInterval(08:00,10:00)`}</li>
                  <li>{`weekTimeInterval(开始时段, 结束时段) 分时段报警(按周) 周时间格式为(1-7), 比如weekTimeInterval(1,4)即周一到周四`}</li>
                </ul>

                <div style={{ margin: '0 0 10px 20px' }}>采集周期：网关配置中的采集间隔</div>
                <ul>
                  <li>{`pAvg(_A相电压) 采集周期内的平均值`}</li>
                  <li>{`pSum(_A相电压) 采集周期内的总值`}</li>
                  <li>{`pMax(_A相电压) 采集周期内的最大值`}</li>
                  <li>{`pMin(_A相电压) 采集周期内的最小值`}</li>
                  <li>{`pAscend(_A相电压) 采集周期内的上升值`}</li>
                  <li>{`pDescend(_A相电压) 采集周期内的下降值`}</li>
                  <li>{`pAbsDif(_A相电压) 采集周期内的绝对值`}</li>
                  <li>{`smavg(_A相电压,15) 时移平均：第一个参数时点id，第二个参数是时间窗口，单位为秒，具体范围是[now-windows,now]。**参数顺序级类型不能乱**。该函数求数据属性过去15秒内数据的算术平均数。`}</li>
                </ul>
              </div>
            </Space>
          </div>
        }
      >
        <InfoCircleOutlined style={{ marginLeft: 5, color: 'var(--mx-warning-color)' }} />
      </Tooltip>
    </Row>
  );
};

export default FormulaInputOfAlarm;
