import { RedoOutlined } from '@ant-design/icons';
import {
  Table,
  Paging,
  usePaging,
  useUpdate,
  Button,
  Checkbox,
  CustomFilter,
  Form,
  Input,
  Select,
} from '@maxtropy/components';
import { Space } from 'antd';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import { AlarmLevel, AlarmLevelDisplay, AlarmStatus, AlarmType, IotProtocolType, AlarmChannel } from '@/shared/types';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { modalColumns } from '../util';
import { apiV2RulePagePost, V2RulePagePostRequest, V2RulePagePostResponse } from '@maxtropy/device-customer-apis-v2';
import { RulePageList } from '@/pages/Alarm/List/types';

export interface AlarmRulesModalProps {
  value?: RulePageList[];
  onChange?: (value: RulePageList[]) => void;
  iotProtocolType?: IotProtocolType;
  deviceTypeId?: number;
  // 规则类型
  type: AlarmType;
  disabledIds?: Key[];
  alarmChannel: AlarmChannel;
  physicalModelId?: number; // 物理模型id
}

// type SearchParams = Omit<RulePageRequest, 'iotProtocol' | 'size' | 'page' | 'type' | 'status' | 'deviceTypeId'>;

const AlarmRulesModal: FC<AlarmRulesModalProps> = ({
  value,
  onChange,
  iotProtocolType,
  disabledIds,
  deviceTypeId,
  type,
  alarmChannel,
  physicalModelId,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<V2RulePagePostRequest>();
  const [rules, setRules] = useState<V2RulePagePostResponse['list']>([]);
  const [updateState, updateFn] = useUpdate();

  const ruleIds = useMemo<number[]>(() => {
    return (value ?? []).filter(i => i !== undefined).map(i => i.id as number);
  }, [value]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2RulePagePost({
      iotProtocol: alarmChannel === AlarmChannel.LONGSHINE ? IotProtocolType.LONGSHINE : iotProtocolType,
      channel: alarmChannel,
      physicalModelId,
      type,
      deviceTypeId,
      status: AlarmStatus.ENABLE,
      onlyPhysicalModel: true,
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      if (res) {
        setTotalCount(res.total ?? 0);
        setRules(res.list);
      }
    });
  }, [
    iotProtocolType,
    type,
    pageOffset,
    pageSize,
    searchParams,
    setTotalCount,
    updateState,
    deviceTypeId,
    alarmChannel,
    physicalModelId,
  ]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: RulePageList[]) => {
      // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
      // TODO: 这里需要合并value
      const rulesId = rules!.map(i => i.id);
      // const unSaveRulesId = rules.filter(i => !disabledIds?.includes(i.id)).map(i => i.id)
      const buildDataMap = new Map<number, RulePageList>();
      (value ?? [])
        .filter(i => !rulesId.includes(i.id))
        .forEach(i => {
          if (i.id !== undefined && !buildDataMap.has(i.id)) {
            buildDataMap.set(i.id, i);
          }
        });
      selectedRows.forEach(i => {
        if (i.id !== undefined && !buildDataMap.has(i.id)) {
          buildDataMap.set(i.id, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: RulePageList) => ({
      disabled: (disabledIds ?? []).includes(record.id!),
    }),
    selectedRowKeys: ruleIds,
  };

  const onFinish = (value: V2RulePagePostRequest) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    form.setFieldsValue({
      nameOrCode: undefined,
      alarmType: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={val => {
        onFinish(val as V2RulePagePostRequest);
      }}
      onReset={onReset}
    >
      <Form.Item name="nameOrCode" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="报警等级" name="alarmType">
        <Select placeholder="请选择">
          <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
          <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
          <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
          <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
          <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="onlyPhysicalModel" valuePropName="checked" style={{ marginLeft: 20 }} initialValue={true}>
        <Checkbox>只查看物模型型号下的规则</Checkbox>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips} style={{ marginBottom: 10 }}>
        <Space size={8}>
          <Button type="primary">
            <Link to={`/device/rule/list/create`} target="_blank">
              新建报警规则
            </Link>
          </Button>
          <Button type="primary" onClick={updateFn}>
            <div>
              <RedoOutlined rotate={270} />
              刷新页面
            </div>
          </Button>
        </Space>
      </div>
      <Table
        showSelectedCount
        selectedCount={(value ?? []).length}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 350 }}
        rowKey="id"
        columns={modalColumns}
        dataSource={rules as RulePageList[]}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </>
  );
};

export default AlarmRulesModal;
