import dayjs from 'dayjs';
import {
  V2OeeApplyComparisonHistoryPostResponse,
  V2OeeApplyComparisonObjectPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { FormInstance } from 'antd';
import { DeviceInfo } from './ObjectCompare';
import { MonitorIndicator, MonitorIndicatorDisplay, StatisticsPartition } from '../../type';

const baseSeriesOption = (seriesInfo: DeviceInfo, data: V2OeeApplyComparisonHistoryPostResponse['list']) => {
  const seriesData =
    data?.map(i => {
      const device = i.dataList?.find((device: any) => device.groupUid === seriesInfo.value);

      return { value: device?.percent, label: seriesInfo.label };
    }) || [];
  return {
    data: seriesData,
    type: 'line',
    smooth: true,
    symbol: 'circle',
    showSymbol: false,
    name: `${seriesInfo.label}`,
    symbolSize: 10,
    lineStyle: {
      type: 'dotted',
    },
    barWidth: 16,
    itemStyle: {
      borderWidth: 2,
      borderColor: '#fff',
    },
  };
};

export const getBasicChartOptionByHis = (
  hisChartData: V2OeeApplyComparisonHistoryPostResponse['list'],
  form: FormInstance
) => {
  const { indicatorId, timeResolution } = form.getFieldsValue();

  return {
    grid: {
      left: 10,
      right: 0,
      containLabel: true,
    },
    legend: {
      show: true,
      right: 20,
      top: 10,
      icon: 'rect',
      textStyle: {
        color: '#AFBCC4',
      },
      itemStyle: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      itemWidth: 16,
      itemHeight: 4,
      itemGap: 16,
      padding: [0, 8, 0, 0],
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        right: 10,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    color: ['#0DB6D9', '#63BC7F', '#CE90D1'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 8, 12],
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 12,
        lineHeight: 20,
      },
      formatter: (tooltipData: any) => {
        return `
        <div>${tooltipData
          ?.map(
            (item: any) =>
              `<div>
            <div>${
              item?.value?.[2]
                ? dayjs(item.value[2]).format(
                    timeResolution === StatisticsPartition.MONTH
                      ? 'YYYY-MM'
                      : timeResolution === StatisticsPartition.YEAR
                      ? 'YYYY'
                      : 'YYYY-MM-DD'
                  )
                : '--'
            }</div>
            <div style='width: 174px;display: flex;align-items: center;margin-bottom: 4px;'><span style='display:inline-block;width:8px;height:8px;background:${
              item?.color
            };border-radius:50%;margin-right:6px'></span> ${
                item.seriesName ?? '--'
              }<span style='flex:1;text-align: right'>${item.value?.[1] ?? '--'}%</span></div></div>`
          )
          .join('')}</div>
            </div>
        `;
      },
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisPointer: {
          type: 'line',
        },
        type: 'category',
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '%',
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
      },
    ],
    series:
      hisChartData?.map((item, index) => {
        return {
          type: 'line',
          smooth: true,
          symbol: 'circle',
          showSymbol: false,
          symbolSize: 10,
          lineStyle: {
            type: 'dotted',
          },
          name: `时间${index + 1}-${MonitorIndicatorDisplay[indicatorId as MonitorIndicator]}`,
          barWidth: 16,
          itemStyle: {
            borderWidth: 2,
            borderColor: '#fff',
          },
          data: item.dataList?.map((a, index) => {
            return [index + 1, a.percent, a.ts];
          }),
        };
      }) || [],
  };
};

export const getBasicChartOptionByObj = (
  objChartData: V2OeeApplyComparisonObjectPostResponse['list'],
  form: FormInstance,
  timeResolution: StatisticsPartition
) => {
  const deviceInfoList = form.getFieldValue('groupUidList');
  return {
    grid: {
      left: 10,
      right: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 8, 12],
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 12,
        lineHeight: 20,
      },
      formatter: (tooltipData: any) => {
        const title = tooltipData[0]?.name ?? '--';
        return `
          <div >${title}</div>
          <div>${tooltipData
            ?.map(
              (item: any) =>
                `<div style='width:220px;display: flex;align-items: center;margin-bottom: 4px;'><span style='display:inline-block;width:8px;height:8px;background:${
                  item?.color
                };border-radius:50%;margin-right:6px'></span> <div style='max-width: 140px;;overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;'>${item.data.label}</div><span style='flex:1;text-align: right'>${
                  item.value ?? '--'
                }%</span></div></div>`
            )
            .join('')}</div>
        `;
      },
    },
    legend: {
      right: 0,
      top: 10,
      bottom: 8,
      icon: 'rect',
      itemStyle: {
        borderColor: 'transparent',
        borderWidth: 0,
      },
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        width: 125,
        overflow: 'truncate',
      },
      data: deviceInfoList.map((item: any) => ({
        name: `${item.label}`,
      })),
      tooltip: {
        show: true,
      },
      itemWidth: 16,
      itemHeight: 4,
      itemGap: 16,
      padding: [0, 8, 0, 0],
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        left: 10,
        right: 10,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
      { type: 'inside' },
    ],
    color: ['#2D8DFF', '#52E7FF', '#6F47FF', '#FF477B', '#FFCB47'],
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        axisPointer: {
          type: 'line',
        },
        type: 'category',
        data: objChartData!.map(i =>
          dayjs(i.ts).format(
            timeResolution === StatisticsPartition.MONTH
              ? 'YYYY-MM'
              : timeResolution === StatisticsPartition.YEAR
              ? 'YYYY'
              : 'YYYY-MM-DD'
          )
        ),
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '%',
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
      },
    ],
    series: deviceInfoList?.map((item: DeviceInfo) => baseSeriesOption(item, objChartData)) || [],
  };
};
