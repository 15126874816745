import {
  AddEnergyPlanDetailBody,
  PlanRange,
  PlanType,
  addEnergyPlanDetail,
  getEnergyPlanDetail,
} from '@/api/plustekEnergyPlan';
import { Modal, Radio, Select, Form } from '@maxtropy/components';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface AddPlanModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
  unitSelectedMonth: number[];
  deviceSelectedMonth: number[];
}

const AddPlanModal: React.FC<AddPlanModalProps> = props => {
  const { open, setOpen, updateFn, unitSelectedMonth, deviceSelectedMonth } = props;
  const { id: energyPlanId } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const planType = Form.useWatch('planType', form); // 计划类型
  const planRange = Form.useWatch('planRange', form); // 计划范围
  const [energyYear, setEnergyYear] = useState<string>(); // 用能年度

  useEffect(() => {
    if (isNil(energyPlanId)) return;
    // 获取用能计划详细信息
    getEnergyPlanDetail(Number(energyPlanId)).then(res => {
      if (isNil(res)) return;
      setEnergyYear(dayjs(res.energyYear).format('YYYY'));
    });
  }, [energyPlanId]);

  const monthOptions = useMemo(() => {
    if (isNil(energyYear)) return [];
    const arr = Array.from({ length: 12 }, (v, i) => i + 1);

    const opts = arr.map(i => ({
      label: `${i}月`,
      value: `${energyYear}-${i < 10 ? `0${i}` : i}-01`,
    }));
    if (planRange === PlanRange.ENERGY_UNIT) {
      return opts.map(i => ({
        ...i,
        disabled: unitSelectedMonth.includes(dayjs(i.value).get('month') + 1),
      }));
    }
    return opts.map(i => ({
      ...i,
      disabled: deviceSelectedMonth.includes(dayjs(i.value).get('month') + 1),
    }));
  }, [energyYear, unitSelectedMonth, deviceSelectedMonth, planRange]);

  const onFinish = (v: AddEnergyPlanDetailBody) => {
    if (isNil(energyPlanId)) return;
    addEnergyPlanDetail({
      ...v,
      planDate: isNil(v.planDate) ? `${energyYear}-01-01` : dayjs(v.planDate).format('YYYY-MM-DD'),
      energyPlanId: Number(energyPlanId),
    }).then(() => {
      updateFn();
      setOpen(false);
    });
  };

  return (
    <Modal
      title="添加计划"
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <Form
        form={form}
        initialValues={{
          planType: PlanType.DAY,
          planRange: PlanRange.ENERGY_UNIT,
        }}
        labelAlign="left"
        labelCol={{ flex: '128px' }}
        style={{ padding: '8px 48px' }}
        onFinish={onFinish}
      >
        <Form.Item
          label="计划范围"
          name="planRange"
          rules={[
            {
              required: true,
              message: '请选择计划范围',
            },
          ]}
        >
          <Radio.Group>
            <Radio value={PlanRange.ENERGY_UNIT}>用能单元</Radio>
            <Radio value={PlanRange.DEVICE}>设备</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="计划类型"
          name="planType"
          rules={[
            {
              required: true,
              message: '请选择计划类型',
            },
          ]}
        >
          <Radio.Group>
            <Radio value={PlanType.DAY}>日</Radio>
            <Radio value={PlanType.MONTH}>月</Radio>
            <Radio value={PlanType.QUARTER}>季度</Radio>
            <Radio value={PlanType.YEAR}>年</Radio>
          </Radio.Group>
        </Form.Item>
        {planType === PlanType.DAY && (
          <Form.Item
            label="时间选择"
            name="planDate"
            rules={[
              {
                required: true,
                message: '请选择时间',
              },
            ]}
          >
            <Select options={monthOptions} placeholder="请选择" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddPlanModal;
