import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { GatewayStatus } from '@/shared/types';
import { Gateway } from './gateway';
import { OuInfoProps } from './ou';
import { MasterFcsProps, PcsStructureType } from './uet';

export interface FcsListReq extends PageRequest {
  nameOrCode?: string;
  status?: GatewayStatus;
}

export interface FcsListProps {
  type?: number; // FCS类型
  networkId: number | undefined;
  createTime: string;
  gatewayId: number;
  id: number;
  name: string;
  ouId: number;
  ouVoV2: OuInfoProps;
  serialNumber: string;
  updateByUserId: string;
  updateTime: string;
  edgeGatewayAbstractVo: Gateway;
  softwareVersion: string; // 软件版本
  networkName: string; // 组网名称
  softwareName: string;
}

export enum FCSLinkBSAType {
  EXCEPTMASTERORSLAVE = -1, // 老的
  MASTER = 0, // 主
  SLAVE = 1, // 从
}

export interface AllFcsListProps {
  networkName: string; // 组网名称
  networkId: number | undefined;
  createTime?: string;
  gatewayId: number;
  id?: number;
  name?: string;
  ouId?: number;
  serialNumber?: string;
  updateByUserId?: string;
  updateTime?: string;
  softwareVersion?: string; // 软件版本
  softwareName?: string; // 软件名称
  remark?: string;
  type?: number;
  linkType?: FCSLinkBSAType; // 判断主从还是老的
}
export interface FcsType {
  type: number;
  name: string;
}
// 获取FCS类型
export const getFcsType = () =>
  fetch<{ list: FcsType[] }>(
    `/api/v2/fcs/list_type`,
    {
      method: 'POST',
    },
    true
  );

export const getFcsList = (params: FcsListReq) =>
  fetch<PageResponse<FcsListProps>>(
    `/api/v2/fcs/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface CreateFcsReq {
  name: string;
  gatewayId: number;
  ouId: number;
  remark?: string;
}

export const createFcs = (body: CreateFcsReq) =>
  fetch(
    `/api/v2/fcs/add`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

// 获取fcs软件版本
export interface FcsVersion {
  softwareVersion: string;
  softwareName: string;
  arrayType: number; // 1 单pcs 2 多pcs
}
export const getFcsVersion = () => {
  fetch<{ list: FcsVersion[] }>(
    `/api/v2/fcs/software-version-all`,
    {
      method: 'POST',
    },
    true
  );
};

export const getFcsByOu = (ouId: number) =>
  fetch<{ list: FcsListProps[] }>(
    `/api/v2/fcs/get_by_ou`,
    {
      method: 'POST',
      body: JSON.stringify({ ouId }),
    },
    true
  );

export interface FcsByOuBsaVersionType {
  ouId: number;
  softwareVersionList: string[];
}

// 根据软件版本列表查询OU中可选的FCS
export const getFcsByOuBsaVersion = (params: FcsByOuBsaVersionType) =>
  fetch<{ list: FcsListProps[] }>(
    `/api/v2/fcs/get_by_ou_bsa_version`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

// 根据主控FCS的网关id查询组网中的从控FCS
export const getFCSByMasterGatewayId = (gatewayId: number) =>
  fetch<{ list: MasterFcsProps[] }>(
    `/api/v2/fcs/get_by_master_gateway_id`,
    {
      method: 'POST',
      body: JSON.stringify({ id: gatewayId }),
    },
    true
  );

export const getFcsInfo = (id: string) =>
  fetch<FcsListProps>(
    `/api/v2/fcs/get`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const updateFcs = (body: CreateFcsReq & { id: number }) =>
  fetch(
    `/api/v2/fcs/update`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export const deleteFcs = (id: number) =>
  fetch(
    `/api/v2/fcs/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface FcsInfoByHardwarePcsStructInterface {
  hardwareVersion?: string;
  pcsStruct?: PcsStructureType;
}

// 根据硬件版本和pcs构型 查询软件版本列表
export const getFcsInfoByHardwarePcsStruct = (params: FcsInfoByHardwarePcsStructInterface) =>
  fetch<{ list: string[] }>(
    `/api/v2/fcs/fcs-software-version`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface PwdReq {
  passcode: string;
  onOff: boolean;
}

export interface PwdRes {
  passcode: string;
  onOff: boolean;
  online: boolean;
}

export const getFcsPwd = (fcsId: string) =>
  fetch<PwdRes>(
    `/api/v2/fcs/getPasscode`,
    {
      method: 'POST',
      body: JSON.stringify({ id: fcsId }),
    },
    true
  );

export const setFcsPwd = (fcsId: string, body: PwdReq) =>
  fetch(
    `/api/v2/fcs/setPasscode`,
    {
      method: 'POST',
      body: JSON.stringify({ ...body, fcsId }),
    },
    true
  );

export interface PasscodeLogItem {
  staffId: number;
  staffName: string;
  operationName: string;
  operationType: number;
  createTime: string;
}

export const getPasscodeLog = (fcsId: string, query: PageRequest) =>
  fetch<PageResponse<PasscodeLogItem>>(
    `/api/v2/fcs/passcode/log/page`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query, fcsId }),
    },
    true
  );
