import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Paging,
  Select,
  ShowInput,
  Table,
  usePaging,
} from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { DeviceType } from '@/shared/types';
import { Device } from '../../../../api/device';
import {
  getThermalDeviceTypeList,
  getThermalUnBoundDevicePage,
  UnBoundDevicePageRequest,
} from '../../../../api/thermal';

import styles from './DeviceModal.module.scss';

interface SearchParams extends Omit<UnBoundDevicePageRequest, 'page' | 'size' | 'thermalAccountId'> {}

const columns: ColumnType<Device>[] = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : v} />,
  },
  {
    title: '关联组织',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DeviceModalProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  thermalId?: number;
}

export interface DeviceModalRefProps {
  getDataPropertyId: () => number | undefined;
}

const DeviceModal: FC<DeviceModalProps> = ({ value, onChange, thermalId }) => {
  const [form] = Form.useForm();

  const [devices, setDevices] = useState<Device[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const [deviceTypeList, setDeviceTypeList] = useState<DeviceType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    if (thermalId) {
      setLoading(true);
      getThermalUnBoundDevicePage({
        ...searchParams,
        thermalAccountId: thermalId,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setDevices(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [thermalId, pageOffset, pageSize, searchParams, setTotalCount]);

  useEffect(() => {
    getThermalDeviceTypeList().then(res => {
      setDeviceTypeList(res.list || []);
    });
  }, []);

  const onFinish = (val: SearchParams) => {
    setPageOffset(1);
    setSearchParams(val);
    onChange?.([]);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams(undefined);
    onChange?.([]);
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: Device[]) => {
      const currentPageDeviceIds = devices.map(i => i.id);
      onChange?.([...(value ?? []).filter(i => !currentPageDeviceIds.includes(i)), ...selectedRows.map(i => i.id)]);
    },
    selectedRowKeys: value ?? [],
  };

  const filters = (
    <CustomFilter form={form} onFinish={val => onFinish(val as SearchParams)} colSpan={8} onReset={onReset}>
      <Form.Item label="编号/名称" name="codeOrName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="标签" name="deviceTag">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="一级类目">
        <ShowInput value="积算仪" />
      </Form.Item>
      <Form.Item label="二级类目" name="deviceTypeId">
        <Select placeholder="请选择" options={deviceTypeList.map(i => ({ value: i.id, label: i.name }))} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <div className={styles.wrapper}>
      {filters}
      <div className={styles.tips}></div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        scroll={{ y: 300 }}
        rowKey="id"
        showSelectedCount
        selectedCount={(value ?? []).length}
        columns={columns}
        dataSource={devices}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
};

export default DeviceModal;
