import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, DatePickerProps } from 'antd';
import { EllipsisSpan, Table, Modal, DatePicker, Select, Form } from '@maxtropy/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import {
  fastSchedule,
  FastScheduleWorkShift,
  getFastScheduleWorkShiftList,
  getWorkTeamList,
  WorkTeam,
} from '@/api/schedulePlan';

type FastScheduleModalProps = {
  open: boolean;
  closeModal: () => void;
  forceUpdate: () => void;
  // 用能单元名称
  unitName: string;
  // 用能单元 id
  unitId?: number | null;
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

type FormValues = {
  range: [Dayjs, Dayjs];
  shiftTeamPair: {
    workShiftId: number;
    workTeamId: number | null;
  }[];
};

export default function FastScheduleModal(props: FastScheduleModalProps) {
  const { open, closeModal, forceUpdate, unitName, unitId } = props;
  const [form] = Form.useForm();
  const [workShifts, setWorkShifts] = useState<FastScheduleWorkShift[]>([]);
  const [workTeams, setWorkTeams] = useState<WorkTeam[]>([]);
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>([dayjs().add(1, 'month'), dayjs().add(1, 'month')]);

  const columns = [
    {
      title: '班次',
      dataIndex: 'workShiftId',
      ellipsis: { showTitle: true },
      render: (v: number) => {
        return <EllipsisSpan value={workShifts.find(s => s.id === v)?.name} />;
      },
    },
    {
      title: '班组选择',
      dataIndex: 'workTeamId',
      ellipsis: { showTitle: true },
      render: (v: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['shiftTeamPair', index, 'workTeamId']}>
            <Select
              placeholder="请选择班组"
              options={[...workTeams, { id: null, name: '待排班' }].map(t => ({
                label: `${t.name}${(t.customers ?? []).length > 0 ? '-' : ''}${(t.customers ?? [])
                  .map(i => i.name)
                  .join('-')}`,
                value: t.id,
              }))}
            />
          </Form.Item>
        );
      },
    },
  ];

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const disabled6MonthsDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (from) {
      const curMonth = current.year() * 12 + current.month();
      const fromMonth = from.year() * 12 + from.month();
      return Math.abs(fromMonth - curMonth) >= 6;
    }

    return false;
  };

  // 获取班次
  useEffect(() => {
    if (value?.length && unitId) {
      getFastScheduleWorkShiftList({
        startDate: value[0]?.startOf('month').format('YYYY-MM-DD')!,
        endDate: value[1]?.startOf('month').format('YYYY-MM-DD')!,
        energyUnitId: unitId!,
      }).then(res => {
        setWorkShifts(res.list || []);
      });
    }
  }, [unitId, value]);

  // 获取班组
  useEffect(() => {
    if (unitId) {
      getWorkTeamList(unitId!).then(res => {
        setWorkTeams(res.list || []);
      });
    }
  }, [unitId]);

  // 表格内设置初始值
  useEffect(() => {
    form.setFieldValue(
      'shiftTeamPair',
      workShifts.map(s => ({
        workShiftId: s.id,
        workTeamId: null,
      }))
    );
  }, [form, workShifts, workTeams]);

  useEffect(() => {
    form.setFieldValue('range', dates || value);
  }, [dates, form, value]);

  return (
    <Modal
      title="快捷排班"
      open={open}
      size="large"
      okButtonProps={{
        disabled: workShifts.length ? false : true,
      }}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <Row style={{ marginBottom: '24px' }}>
        <Typography.Link style={{ pointerEvents: 'none' }}>{unitName}</Typography.Link>
      </Row>
      <Form
        form={form}
        onFinish={(v: FormValues) => {
          console.log('快捷排班 form values', v);
          fastSchedule({
            startDate: v.range[0].startOf('month').format('YYYY-MM-DD'),
            endDate: v.range[1].startOf('month').format('YYYY-MM-DD'),
            energyUnitId: unitId!,
            shiftTeamPair: v.shiftTeamPair,
          }).finally(() => {
            forceUpdate();
            closeModal();
          });
        }}
        // initialValues={{
        //   range: [dayjs().add(1, 'month'), dayjs().add(1, 'month')],
        // }}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="range" label="时间范围" rules={[{ required: true }]}>
              <DatePicker.RangePicker
                picker="month"
                disabledDate={disabled6MonthsDate}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
                allowClear={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name="shiftTeamPair" valuePropName="dataSource">
              <Table
                rowKey="workShift"
                columns={columns}
                footer={() => (
                  <>
                    <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                    &nbsp;确定后覆盖更新已有排班配置
                  </>
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
