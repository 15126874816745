import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Modal, Select, Form, DatePicker } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CopyEnergyUnit, copySchedule, getCopyEnergyUnitList } from '@/api/schedulePlan';

type CopyScheduleModalProps = {
  open: boolean;
  closeModal: () => void;
  // 用能单元名称
  unitName: string;
  forceUpdate: () => void;
  // 源用能单元 Id, 以此用能单元为模板进行复制
  sourceEnergyUnitId: string | number;
};

type FormValues = {
  copyDate: Dayjs;
  targetEnergyUnitIds: number[];
};

export default function CopyScheduleModal(props: CopyScheduleModalProps) {
  const { open, closeModal, unitName, forceUpdate, sourceEnergyUnitId } = props;
  const [form] = Form.useForm<FormValues>();
  const copyDate = Form.useWatch<Dayjs>('copyDate', form);
  // 可选择时间范围内，和【所选用能单元】同一个班次日历，且有OU权限的用能单元
  const [units, setUnits] = useState<CopyEnergyUnit[]>([]);

  // 请求可复制到的用能单元
  useEffect(() => {
    if (copyDate) {
      getCopyEnergyUnitList({
        date: copyDate.startOf('month').format('YYYY-MM-DD'),
        energyUnitId: sourceEnergyUnitId.toString(),
      }).then(res => {
        setUnits(res.list || []);
      });
    }
  }, [copyDate, sourceEnergyUnitId]);

  return (
    <Modal
      contentClassName="modal-form-content"
      title="复制排班"
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <Form
        form={form}
        onFinish={(v: FormValues) => {
          copySchedule({
            copyDate: v.copyDate.startOf('month').format('YYYY-MM-DD'),
            targetEnergyUnitIds: v.targetEnergyUnitIds,
            sourceEnergyUnitId: Number(sourceEnergyUnitId),
          }).finally(() => {
            closeModal();
            forceUpdate();
          });
        }}
        initialValues={{
          // 默认选择下个月, 可选择历史月
          copyDate: dayjs().add(1, 'month'),
        }}
      >
        <Form.Item noStyle>
          <div style={{ marginBottom: 24 }}>
            从<Typography.Link style={{ pointerEvents: 'none' }}>【{unitName}】</Typography.Link>复制
          </div>
        </Form.Item>
        <Form.Item name="copyDate" label="时间范围" rules={[{ required: true }]}>
          <DatePicker
            style={{ width: '100%' }}
            picker="month"
            onChange={() => {
              // 重新选择时间清空单元
              form.setFieldValue('targetEnergyUnitIds', undefined);
            }}
          />
        </Form.Item>
        <Form.Item
          name="targetEnergyUnitIds"
          label="复制排班至"
          rules={[{ required: true }]}
          extra={
            <>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              <span>&nbsp;复制会覆盖原用能单元的已有排班信息,并清空班次产量信息。</span>
            </>
          }
        >
          <Select
            mode="multiple"
            maxTagCount={2}
            allowClear
            optionFilterProp="label"
            options={units?.map(v => ({ label: v?.energyUnitName, value: v?.energyUnitId }))}
            placeholder="请选择用能单元"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
