import {
  getAirCompressorCurrentChart,
  getAirCompressorExhaustPressureChart,
  getAirCompressorVoltageChart,
  getAirPowerToElectricityRatioChart,
  getAirTotalPowerConsumptionChart,
} from './charts';
import { DateBtn, DatePickerType, ParamsOption, SingleIndicatorType } from './type';

export interface IndicatorBtn {
  id: SingleIndicatorType;
  btnName?: string;
  unit?: string;
  getChartOption: (value: ParamsOption) => any;
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
}

// 每个指标的配置项
export const SingleIndicatorBtns = (): IndicatorBtn[] => {
  return [
    // 空压机
    {
      id: SingleIndicatorType.AirCompressorPowerToElectricityRatio,
      // btnName: chartData.list?.[0].name,
      // btnName: '气电比',
      // unit: chartData.list?.[0].physicalUnitName,
      getChartOption: getAirPowerToElectricityRatioChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: SingleIndicatorType.AirCompressorPowerConsumption,
      // btnName: '用电量',
      // unit: 'kWh',
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 电流
      id: SingleIndicatorType.AirCompressorCurrent,
      getChartOption: getAirCompressorCurrentChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 电压
      id: SingleIndicatorType.AirCompressorVoltage,
      getChartOption: getAirCompressorVoltageChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 排气压力(排气压力、功率、输出流量一致)
      id: SingleIndicatorType.AirCompressorExhaustPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 功率
      id: SingleIndicatorType.AirCompressorPower,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 输出流量
      id: SingleIndicatorType.AirCompressorOutputFlow,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 压力
      id: SingleIndicatorType.AirTankPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 出口压力
      id: SingleIndicatorType.AirStationExitPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 出口流量
      id: SingleIndicatorType.AirStationExitFlow,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 温度
      id: SingleIndicatorType.AirThermometerTemperature,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷干机能效比
      id: SingleIndicatorType.AirColdDryerEnergyEffciencyRatio,
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷干机用电量
      id: SingleIndicatorType.AirColdDryerPowerConsumption,
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷干机电流
      id: SingleIndicatorType.AirColdDryerCurrent,
      getChartOption: getAirCompressorCurrentChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷干机电压
      id: SingleIndicatorType.AirColdDryerVoltage,
      getChartOption: getAirCompressorVoltageChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷干机功率
      id: SingleIndicatorType.AirColdDryerPower,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷干机工作压力
      id: SingleIndicatorType.AirColdDryerWorkingPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 冷干机气体处理量
      id: SingleIndicatorType.AirColdDryerProcessingCapacity,
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 冷干机进气温度
      id: SingleIndicatorType.AirColdDryerIntakeAirTemperature,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 流量计-瞬时流量
      id: SingleIndicatorType.AirFlowMeterInstantaneous,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 流量计-累计流量
      id: SingleIndicatorType.AirFlowMeterCumulative,
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 压力
      id: SingleIndicatorType.AirPressureGaugePressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    // 制氮机
    {
      // 气电比
      id: SingleIndicatorType.NGCompressorPowerToElectricityRatio,
      getChartOption: getAirPowerToElectricityRatioChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: SingleIndicatorType.NGCompressorPowerConsumption,
      // btnName: '用电量',
      // unit: 'kWh',
      getChartOption: getAirTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      // 电流
      id: SingleIndicatorType.NGCompressorCurrent,
      getChartOption: getAirCompressorCurrentChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 电压
      id: SingleIndicatorType.NGCompressorVoltage,
      getChartOption: getAirCompressorVoltageChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 功率
      id: SingleIndicatorType.NGCompressorPower,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 输出流量
      id: SingleIndicatorType.NGCompressorOutputFlow,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 氮气纯度
      id: SingleIndicatorType.NGCompressorExhaustPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 压力
      id: SingleIndicatorType.NGTankPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 出口压力
      id: SingleIndicatorType.NGStationExitPressure,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 出口流量
      id: SingleIndicatorType.NGStationExitFlow,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      // 温度
      id: SingleIndicatorType.NGThermometerTemperature,
      getChartOption: getAirCompressorExhaustPressureChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
  ];
};
