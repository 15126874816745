import { Col, Form, Row } from 'antd';
import { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPrice,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceType,
} from '../../../../../api/electricitySettlementRules';

const UnitarySystemDetails: FC = () => {
  return (
    <div>
      <Row>
        <Form.Item noStyle dependencies={['settlementType', 'rateType']}>
          {({ getFieldValue }) => {
            if (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM &&
              getFieldValue('rateType')
            ) {
              let a: HasTimeOfUseElectricityPriceType = getFieldValue('rateType');
              return (
                <Col
                  span={24}
                  style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px' }}
                >
                  <div style={{ marginBottom: '30px', fontWeight: 700 }}>{HasTimeOfUseElectricityPrice[a]}</div>
                </Col>
              );
            }
          }}
        </Form.Item>
      </Row>

      <Form.Item noStyle dependencies={['rateType', 'settlementType', 'standingRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM &&
            getFieldValue('standingRate') && (
              <Row
                style={{
                  backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                  paddingLeft: '40px',
                  marginBottom: '30px',
                  paddingBottom: '20px',
                }}
              >
                <Col span={6}>电度电价：{getFieldValue('standingRate')}</Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      {/* 时段选择尖峰平谷输入框 */}
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'summitRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('summitRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    尖峰时段电价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('summitRate')}
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'peakRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('peakRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    峰时段电价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('peakRate')}
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'plainRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('plainRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    平时段电价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('plainRate')}
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'valleyRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('valleyRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    谷时段电价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('valleyRate')}
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
    </div>
  );
};

export default UnitarySystemDetails;
