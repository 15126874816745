import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Input,
  Select,
  Dropdown,
  CustomFilter,
} from '@maxtropy/components';
import { Menu, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Operator, OperatorDisplay } from '@/shared/types';
import { getOuListOwned, OuListResponse } from '../../../api/ou';
import { deleteSaleClient, getSaleClientPage, SaleClientInfo, SaleClientListRequest } from '../../../api/sale';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/utils';

import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';

// const routes = [
//   {name: '运营配置'},
//   {name: '客户相关'},
//   {name: '客户管理'},
// ];

const columns: ColumnType<SaleClientInfo>[] = [
  {
    title: '客户编号',
    dataIndex: 'code',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '客户名称',
    dataIndex: 'name',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 200,
    ellipsis: { showTitle: true },
    render: (value: string, record) => {
      return (
        <EllipsisSpan
          value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
        />
      );
    },
  },
];

type FilterParams = Omit<SaleClientListRequest, 'page' | 'size'>;

const Client: FC = () => {
  const hasAdd = useHasPermission(PermissionsType.B_CLIENT_ADD);
  const hasUpdate = useHasPermission(PermissionsType.B_CLIENT_UPDATE);
  const hasDelete = useHasPermission(PermissionsType.B_CLIENT_DELETE);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();

  const [ou, setOu] = useState<OuListResponse[]>([]);

  const [searchParams, setSearchParams] = useState<FilterParams>({});

  const [data, setData] = useState<SaleClientInfo[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getOuListOwned().then(setOu);
  }, []);

  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    setLoading(true);
    getSaleClientPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const deleteRecord = (val: number) => {
    deleteSaleClient(val).then(() => {
      setPageOffset(1);
      updateFn();
    });
  };

  const onFinish = (val: FilterParams) => {
    setPageOffset(1);
    setSearchParams(val);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item name="code" label="客户编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>
      <Form.Item name="name" label="客户名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>

      <Form.Item name="ouId" label="所属运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 160,
      fixed: 'right' as const,
      render: (value: undefined, record: SaleClientInfo) => {
        return (
          <Space size={16}>
            {hasDelete && (
              <Button
                type="link"
                onClick={() => {
                  deleteRecord(record.id);
                }}
              >
                删除
              </Button>
            )}
            {hasUpdate && (
              <Dropdown
                type="link"
                overlay={
                  <Menu
                    onClick={() => {}}
                    items={[
                      {
                        key: '1',
                        label: <Link to={`/operation/client-related/client/edit/${record.id}`}>编辑客户信息</Link>,
                      },
                      {
                        key: '2',
                        label: (
                          <Link to={`/operation/client-related/client/meter/edit/${record.id}`}>编辑计量表计</Link>
                        ),
                      },
                    ]}
                  />
                }
              >
                编辑
              </Dropdown>
            )}
            <Dropdown
              type="link"
              overlay={
                <Menu
                  onClick={() => {}}
                  items={[
                    {
                      key: '1',
                      label: <Link to={`/operation/client-related/client/detail/${record.id}`}>查看客户信息</Link>,
                    },
                    {
                      key: '2',
                      label: (
                        <Link to={`/operation/client-related/client/meter/detail/${record.id}`}>查看计量表计</Link>
                      ),
                    },
                  ]}
                />
              }
            >
              查看
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className="page_wrapper" filters={filters}>
      {hasAdd && (
        <div className={styles.operationArea}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              navigate('/operation/client-related/client/create');
            }}
          >
            新建
          </Button>
        </div>
      )}
      <Table rowKey="id" sticky scroll={{ x: 1300 }} loading={loading} dataSource={data} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default Client;
