import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { PageRequest, PageResponse } from './page';

export interface TenantPageSearchParams extends PageRequest {}

export interface AIReportTenantList {
  fileKey: string; // 附件阿里云key
  id: Key;
  name: string; // 报告类型名称
  uploadTime: string; // 上传时间
  analysisDate: string; // 分析时间
}

// 获取AI报告分页查询
export function getAIReportTenantPage(query: TenantPageSearchParams): Promise<PageResponse<AIReportTenantList>> {
  return fetch(
    `/api/v2/aiReport/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}
