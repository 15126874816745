import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { IotProtocolType, PointType, UploadTypeEnum } from '@/shared/types';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { MockingbirdDeviceProps } from '@/shared/components/DataAcquisitionForm/types';

export interface EdgeDevicePoint {
  id: number;
  physicalUnitId: number;
  physicalUnitGeneralName: string;
  dataPropertyId: number;
  dataPropertyName: string;
  edgeDeviceId: number;
  uploadType: UploadTypeEnum;
  symbol: string;
}

export interface EdgeDeviceInfo extends MockingbirdDeviceProps {
  id: number;
  edgeGatewayId?: number;
  deviceId: number;
  deviceTypeId: number;
  iotProtocol: number;
  deviceModelId: IotProtocolType;
  edgeDeviceTemplateId: number;
  edgeDeviceTemplateVersionId: number;
  edgeDeviceTemplateVersion: number;
  points: PointType[];
  edgeDeviceTemplateName?: string;
  edgeDeviceTemplateLatestVersion?: number;
  edgeDeviceTemplateVersionRemark?: string;
}

export const queryEdgeDeviceInfo = (id: string): Promise<EdgeDeviceInfo> =>
  fetch<{ dto: EdgeDeviceInfo }>(
    `/api/v2/edgeDevice/v2/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(data => ({
    ...data.dto,
    points: (data?.dto?.points ?? []).map((item: any) => ({ ...item, id: item.edgeDevicePointId })),
  }));

interface UpdateEstunDeviceBody {
  edgeDeviceTemplateVersionId: number;
  deviceIds: number[];
  iotProtocol: number;
  physicalModelId?: Key;
}

export const updateEstunDevice = (body: UpdateEstunDeviceBody) => {
  return fetch<void>(
    `/api/v2/edgeDevice/v2/edit`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

interface UpdateMockingbirdDeviceBody extends MockingbirdDeviceProps {
  edgeDeviceTemplateVersionId: number;
  deviceIds: number[];
  iotProtocol: number;
  points: EdgeDeviceTemplatePoint[];
  typeId: number;
  deletePointIds?: string[];
  physicalModelId?: Key;
}

export const updateMockingbirdDevice = (body: UpdateMockingbirdDeviceBody) =>
  fetch<any>(
    `/api/v2/edgeDevice/v2/edit`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export const emptyEdgeDevice = (id: string) =>
  fetch<{ flag: boolean }>(
    `/api/v2/edgeDevice/v2/clearByDeviceId`,
    { method: 'POST', body: JSON.stringify({ id }) },
    true
  );

export interface EdgeDeviceRealTimeDataProps {
  pointId: number;
  data: string;
  ts: any;
}

export const queryEdgeDeviceRealTimeData = (body: { pointIds: string[] }) =>
  fetch<{ list: EdgeDeviceRealTimeDataProps[] }>(
    `/api/v2/edgeDevice/v2/realTimeData`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  ).then(res => res.list ?? []);

export const queryEdgeDeviceRealTimeDataForMockingbird = (body: { pointIds: string[] }) =>
  fetch<{ list: EdgeDeviceRealTimeDataProps[] }>(
    `/api/v2/edgeDevice/v2/realTimeDataForMockingbird`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  ).then(res => res.list ?? []);
// 查询数据属性所对应的单位
interface DataPropertyIdUnitProps {
  dataPropertyId: number;
  generalName: string; // 单位
}
export const queryDataPropertyIdUnit = () =>
  fetch<{ list: DataPropertyIdUnitProps[] }>(
    `/api/v2/physicalUnit/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export interface EdgeDeviceDetailSimple {
  id: number;
  deviceTypeId: number;
  iotProtocol: IotProtocolType;
}

export const getEdgeDeviceInfoSimple = (id: string) =>
  fetch<EdgeDeviceDetailSimple>(
    `/api/v2/edgeDevice/v2/simpleByDeviceId`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface DevicePageRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number>;
  physicalModelId?: Key;
}

interface EdgeDevice {
  id: number;
  name: string;
  code: string;
  typeId: number;
  typeName: string;
  rootMcid: string;
  tenantCode: string;
  rootFullName: string;
}

export type DevicePageResponse = PageResponse<EdgeDevice>;

export function getNotConfiguredEdgeDevicePage(params: DevicePageRequest): Promise<DevicePageResponse> {
  return fetch(
    `/api/v2/edgeDevice/v2/device/list`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
