import { FormTitle, Key } from '@maxtropy/components';
import CommonInfoCard from '../CommonInfoCard';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import {
  V2EnergyOptimizationProjectOverviewPostResponse,
  apiV2EnergyOptimizationProjectOverviewPost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  energyOptimizationProjectId: Key;
}

// 选择TCE或KGCE
function chooseTCEOrKGCE(num?: number | null) {
  return (num ?? 0) > 10000 ? 'tce' : 'kgce';
}

// KGCE转TCE
function unitFromKGCEToTCE(num?: number | null) {
  return (num ?? 0) > 10000 ? Number(((num ?? 0) / 1000).toFixed(2)) : num;
}

const AnalysisInfo: React.FC<Props> = ({ energyOptimizationProjectId }) => {
  const [data, setData] = useState<V2EnergyOptimizationProjectOverviewPostResponse>();

  useEffect(() => {
    apiV2EnergyOptimizationProjectOverviewPost({ energyOptimizationProjectId }).then(setData);
  }, [energyOptimizationProjectId]);

  return (
    <div style={{ padding: 20 }}>
      <FormTitle title="优化分析" style={{ marginBottom: 18 }} />
      <Row gutter={8}>
        <Col span={6}>
          <CommonInfoCard isSpecial rate={data?.energySavingsRate} recentRate={data?.energySavingsRateRecently} />
        </Col>
        <Col span={6}>
          <CommonInfoCard
            title={`预计每月节省成本 (${
              (data?.estimatedMonthlySavings ?? 0) > 10000 || (data?.estimatedMonthlySavings ?? 0) < -10000
                ? '万元'
                : '元'
            })`}
            num={
              (data?.estimatedMonthlySavings ?? 0) > 10000 || (data?.estimatedMonthlySavings ?? 0) < -10000
                ? Number(((data?.estimatedMonthlySavings ?? 0) / 10000).toFixed(2))
                : data?.estimatedMonthlySavings
            }
          />
        </Col>
        <Col span={6}>
          <CommonInfoCard
            title={`优化前日均基准用能 (${chooseTCEOrKGCE(data?.dailyAverEnergyBeforeOptimization)})`}
            num={unitFromKGCEToTCE(data?.dailyAverEnergyBeforeOptimization)}
          />
        </Col>
        <Col span={6}>
          <CommonInfoCard
            title={`优化后日均用能 (${chooseTCEOrKGCE(data?.dailyAverEnergyAfterOptimization)})`}
            num={unitFromKGCEToTCE(data?.dailyAverEnergyAfterOptimization)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AnalysisInfo;
