import { InfoCircleOutlined } from '@ant-design/icons';
import { EllipsisSpan } from '@maxtropy/components';
import { V2MediumIndicatorDashboardGetProcessDataDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
import { Table, Tooltip } from 'antd';
import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';

export interface IViewEachProcessTabContent {
  uetEMTProcessDetail?: V2MediumIndicatorDashboardGetProcessDataDetailPostResponse;
  currentKey?: string;
}

export type TableRow = Exclude<
  V2MediumIndicatorDashboardGetProcessDataDetailPostResponse['importEnergyMediumDataDto'],
  undefined
>[number];

const columns = [
  {
    title: '能源介质',
    dataIndex: 'energyMediumName',
    width: '23%',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '指标',
    dataIndex: 'indicatorName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: (
      <>
        实时值
        <Tooltip title={<span>累积量为本日累积，瞬时量为最近数据，瞬时量数据时效为5min。</span>}>
          <span style={{ marginLeft: 8 }}>
            <InfoCircleOutlined style={{ color: 'orange' }} />
          </span>
        </Tooltip>
      </>
    ),
    dataIndex: 'value',
    render: (v: number, record: TableRow) => <EllipsisSpan value={(v ? v.toFixed(2) : '--') + (record.unit ?? '--')} />,
  },
];

const ViewEachProcessTabContent: FC<IViewEachProcessTabContent> = props => {
  const { uetEMTProcessDetail, currentKey } = props;

  const data = useMemo(() => {
    if (currentKey === '2') {
      return uetEMTProcessDetail?.importEnergyMediumDataDto;
    } else if (currentKey === '3') {
      return uetEMTProcessDetail?.exportEnergyMediumDataDto;
    } else {
      return uetEMTProcessDetail?.processNodeMediumDataDto;
    }
  }, [currentKey, uetEMTProcessDetail]);

  return (
    <>
      <div>
        <Table
          size={'small'}
          rowKey={'id'}
          bordered={false}
          dataSource={data}
          // @ts-ignore
          columns={columns}
          pagination={false}
          className={styles.relatedPartyMaint}
        />
      </div>
    </>
  );
};

export default ViewEachProcessTabContent;
