import {
  EllipsisSpan,
  FormContent,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Button,
  Filter,
  PopConfirm,
  Form,
  Select,
  Table,
} from '@maxtropy/components';
import { Col, Space } from 'antd';
import { FC, useState } from 'react';

import { CircuitLabelType, circuitLabelTypeDisplay } from '@/api/circuit';

import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { useRequest } from 'ahooks';
import {
  apiV2CircuitLabelAllPost,
  apiV2CircuitLabelDeletePost,
  apiV2CircuitLabelPagePost,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import CircuitLabelModal from './CircuitLabelModal';
import CircuitLinkModal from './CircuitLinkModal';
const columns = [
  {
    title: '回路标签',
    dataIndex: 'name',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类型',
    dataIndex: 'type',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: CircuitLabelType) => {
      return <EllipsisSpan value={circuitLabelTypeDisplay[v]} />;
    },
  },
  {
    title: '关联回路数',
    dataIndex: 'relatedCircuitNum',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },

  {
    title: '最后操作人',
    dataIndex: 'staffName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => {
      return <EllipsisSpan value={v} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];
interface SearchParams {
  ids?: number[];
}

const CircuitLabels: FC = () => {
  const routesContext = useBreadcrumbRoutes();

  const hasEditPermission = useHasPermission(PermissionsType.B_CIRCUIT_LABEL_EDIT);
  const hasLabelCreatePermission = useHasPermission(PermissionsType.B_CIRCUIT_LABEL_CEATE);
  const hasLabelDeletePermission = useHasPermission(PermissionsType.B_CIRCUIT_LABEL_DELETE);
  const hasLabelLinkPermission = useHasPermission(PermissionsType.B_CIRCUIT_LABEL_LINK);
  const [searchForm] = Form.useForm(); // 搜索的表单

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置

  const [showLabelModal, setShowLabelModal] = useState<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);

  const { data: labelList, refresh: labelListRefresh } = useRequest(() => apiV2CircuitLabelAllPost({}));

  const [opType, setOpType] = useState('add');
  const [currentLabelId, setCurrentLabelId] = useState<number>();
  const [currentLabelName, setCurrentLabelName] = useState('');
  const [currentLinkIds, setCurrentLinkIds] = useState([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const {
    data: list,
    refresh,
    loading: listLoading,
  } = useRequest(
    () => {
      return apiV2CircuitLabelPagePost({
        page: pageOffset,
        size: pageSize,
        ...(searchParams ?? {}),
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [searchParams, pageOffset, pageSize],
    }
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 120,
      render: (record: any) => (
        <Space size={16}>
          {hasLabelLinkPermission && (
            <Button
              type="link"
              onClick={() => {
                setCurrentLinkIds(record.circuitIds);
                setCurrentLabelId(record.id);
                setShowLinkModal(true);
              }}
            >
              关联回路
            </Button>
          )}

          {hasEditPermission && (
            <Button
              type="link"
              disabled={record.type === CircuitLabelType.PLATFORM}
              onClick={() => {
                setOpType('edit');
                setCurrentLabelId(record.id);
                setCurrentLabelName(record.name);
                setShowLabelModal(true);
              }}
            >
              编辑回路标签
            </Button>
          )}
          {hasLabelDeletePermission && (
            <PopConfirm
              placement="bottomRight"
              title={'是否删除该回路标签？删除后无法恢复。'}
              okText="确认"
              cancelText="取消"
              onConfirm={() => onDeleteRecord(record.id)}
            >
              <Button disabled={record.type === CircuitLabelType.PLATFORM} type="link">
                删除
              </Button>
            </PopConfirm>
          )}
        </Space>
      ),
    },
  ];

  const { runAsync: deleteApi } = useRequest(params => apiV2CircuitLabelDeletePost(params), {
    manual: true,
    refreshDeps: [currentLabelId],
  });
  // 表格删除按钮
  const onDeleteRecord = (id: number) => {
    deleteApi({ id }).then(() => {
      setPageOffset(1);
      refresh();
      labelListRefresh();
    });
  };

  return (
    <>
      <Wrapper
        routes={routesContext?.routes}
        filters={
          <Filter
            form={searchForm}
            onFinish={(values: any) => {
              setSearchParams(values);
              setPageOffset(1);
            }}
            onReset={() => {
              setSearchParams({ ids: undefined });
              setPageOffset(1);
            }}
          >
            <Col span={8}>
              <Form.Item name="ids" label="回路标签">
                <Select
                  placeholder="请选择"
                  options={labelList?.list?.map(item => ({ value: item.id, label: item.name }))}
                  allowClear
                  mode="multiple"
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
          </Filter>
        }
      >
        <Space>
          {hasLabelCreatePermission && (
            <Button
              type="primary"
              onClick={() => {
                setOpType('add');
                setShowLabelModal(true);
              }}
            >
              新建回路标签
            </Button>
          )}
        </Space>

        <FormContent style={{ padding: 0 }}>
          <Table
            rowKey="id"
            sticky
            scroll={{ x: 1300 }}
            loading={listLoading}
            columns={buildColumns}
            dataSource={list}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </FormContent>
      </Wrapper>
      {/* 回路标签弹框 */}
      {showLabelModal && (
        <CircuitLabelModal
          opType={opType}
          id={currentLabelId}
          name={currentLabelName}
          onCancel={() => setShowLabelModal(false)}
          onConfirm={() => {
            setShowLabelModal(false);
            setPageOffset(1);
            refresh();
            // 下拉列表刷新
            labelListRefresh();
          }}
        />
      )}
      {/* 关联回路弹框 */}
      {showLinkModal && (
        <CircuitLinkModal
          linkIds={currentLinkIds}
          labelId={currentLabelId}
          onCancel={() => setShowLinkModal(false)}
          onConfirm={() => {
            setShowLinkModal(false);
            setPageOffset(1);
            refresh();
          }}
        />
      )}
    </>
  );
};

export default CircuitLabels;
