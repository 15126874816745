import { Paging, Table, usePaging, useAsync, CustomFilter, Input, Form } from '@maxtropy/components';
import React, { Key, Ref, useCallback, useImperativeHandle, useState } from 'react';
import { getOUPage, getOuTypeList, OuType } from '../../api/options';
import { OUPageRequest, OUPageResponse } from '../../types';
import { useQuery } from '../../utils/utils';
import styles from './index.module.scss';

export interface OUModalRef {
  selectedRowKeys: Array<number> | undefined;
  data: Array<TableData> | undefined;
}

function formatListData(response: OUPageResponse): Array<TableData> {
  return response.list;
}

type SearchParams = Omit<OUPageRequest, 'page' | 'size'>;

interface TableData {
  id: number;
  serialNumber: string;
  name: string;
  ouTypeName: string;
  tenantName: string;
  manageNum: number;
  staffNum: number;
  manageUpdateByUsername: string;
  manageUpdateTime: string;
}

interface OUModalProps {
  ouIds: Array<number> | undefined;
  mcid: string | undefined;
}

const basicColumns = [
  {
    title: '编号',
    dataIndex: 'serialNumber',
  },
  {
    title: '名称',
    dataIndex: 'name',
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
  },
  {
    title: '设备数量',
    dataIndex: 'deviceNum',
  },
];

const OUModal = React.forwardRef((props: OUModalProps, ref: Ref<OUModalRef>) => {
  const { ouIds, mcid } = props;
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<number> | undefined>(ouIds || undefined);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const ouTypes = useAsync<Array<OuType>>(getOuTypeList, []);

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: Key[]) => {
    setSelectedRowKeys(rowKeys as number[]);
  };
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    getCheckboxProps: (record: TableData) => ({ disabled: ouIds?.includes(record.id) }),
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(() => {
      if (mcid) {
        return getOUPage({
          ...searchParams,
          mcid,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        });
      }
      return Promise.resolve(undefined);
    }, [pageOffset, pageSize, searchParams, setTotalCount, mcid])
  );

  useImperativeHandle(ref, () => ({ selectedRowKeys, data }));

  const typeColumn = {
    title: '类型',
    dataIndex: 'ouTypeName',
  };

  const withOuTypeColumns = basicColumns.map(item => item);
  withOuTypeColumns.splice(2, 0, typeColumn);
  const columns = ouTypes.length > 1 ? withOuTypeColumns : basicColumns;
  return (
    <div className={styles.ouModal}>
      <CustomFilter<SearchParams>
        form={form}
        onFinish={val => onFinish(val as SearchParams)}
        colSpan={8}
        onReset={onReset}
      >
        <Form.Item name="nameOrSerialNum" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </CustomFilter>
      <Table<TableData>
        scroll={{ y: 380 }}
        rowKey="id"
        loading={isLoading}
        showSelectedCount
        selectedCount={selectedRowKeys?.length ?? 0}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
});

export default OUModal;
