import { Node } from '@antv/x6';
import classNames from 'classnames';
import img from '../assets/energyConsumingUnit.png';

import qs from 'qs';
import styles from '../index.module.scss';
import { commonNodeSize } from '../utils/const';
import { apiV2EnergyConsumptionEnergyUnitAggrValuePost } from '@maxtropy/device-customer-apis-v2';
import { useEffect, useState } from 'react';
import { isNil } from 'lodash-es';
import { getRealUrl } from '@maxtropy/components';
import { Tooltip as AntdTooltip } from 'antd';

const EnergyUnitNode = ({ node }: { node: Node }) => {
  const [unitAggr, setUnitAggr] = useState<any>([]);
  const [totalEnergyConsumptionStandardCoal, setTotalEnergyConsumptionStandardCoal] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<string>('');

  const [totalcarbonEmissionConsumption, setTotalcarbonEmissionConsumption] = useState<any>([]);

  useEffect(() => {
    if (!isNil(unitAggr)) {
      const totalEnergyConsumptionStandardCoal = unitAggr.reduce((sum: number, item: any) => {
        return sum + item.energyConsumptionStandardCoal;
      }, 0);
      const totalcarbonEmissionConsumption = unitAggr.reduce((sum: number, item: any) => {
        return sum + item.carbonEmissionConsumption;
      }, 0);
      setTotalEnergyConsumptionStandardCoal(totalEnergyConsumptionStandardCoal);
      setTotalcarbonEmissionConsumption(totalcarbonEmissionConsumption);
    }
  }, [unitAggr]);

  const handleTitleNameHover = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const fromTime = searchParams.get('fromTime') || undefined;
    const toTime = searchParams.get('toTime') || undefined;
    const currentDate = searchParams.get('currentDate') || '--';
    setCurrentDate(currentDate);
    if (!isNil(fromTime) && !isNil(toTime)) {
      apiV2EnergyConsumptionEnergyUnitAggrValuePost({
        productionBaseId: node.getData().rootId,
        energyUnitId: node.getData().id,
        fromTime,
        toTime,
        workCenterId: node.getData().parentId,
      }).then(res => {
        setUnitAggr(res.list);
      });
    }
  };

  return (
    <div
      className={classNames(styles.commonNode, styles.flexCenter)}
      style={{
        background: 'rgba(119,202,78,0.25)',
        borderColor: 'rgba(119,202,78,1)',
      }}
    >
      <div className={styles.nodeContent}>
        <div className={styles.nodeTitle}>
          <img src={getRealUrl(node.getData()?.iconKey) ?? img} className={styles.nodeIcon} alt="" />
          <AntdTooltip
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
            title={
              <div className={styles.toolitipWrapper}>
                <div className={styles.toolitipTime}>{currentDate}</div>
                <div className={styles.name}>
                  <div className={styles.unitNodeBefore}></div>
                  <span className={styles.nameDetail}>{node.getData().name}</span>
                </div>
                <div className={styles.energyDetail}>
                  <div className={styles.totalConsumption}>
                    <div> 综合能耗 </div>
                    <div>
                      {totalEnergyConsumptionStandardCoal < 1000
                        ? Number(totalEnergyConsumptionStandardCoal).toFixed(2)
                        : Number(totalEnergyConsumptionStandardCoal / 1000).toFixed(2)}
                      {totalEnergyConsumptionStandardCoal >= 1000 ? 'tce' : 'kgce'}
                    </div>
                  </div>
                  <div className={styles.totalConsumption}>
                    <div> 碳排 </div>
                    <div>
                      {totalcarbonEmissionConsumption < 1000
                        ? Number(totalcarbonEmissionConsumption).toFixed(2)
                        : Number(totalcarbonEmissionConsumption / 1000).toFixed(2)}
                      {totalcarbonEmissionConsumption >= 1000 ? 'tCO2e' : 'kgCO2e'}
                    </div>
                  </div>
                  {unitAggr.map((item: any) => {
                    return (
                      <div className={styles.totalConsumption}>
                        <div>{item.energyMediumName}</div>
                        <div>
                          {Number(item.energyConsumption).toFixed(2)}
                          {item.physicalUnitGeneralName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          >
            <div
              className={styles.titleName}
              onClick={() => {
                const searchParams = new URLSearchParams(window.location.search);
                const currentDateTypeFromURL = searchParams.get('currentDateType');
                window.open(
                  `/productionManagement/report/board?${qs.stringify(
                    {
                      currentDateType: currentDateTypeFromURL,
                      id: `energyUnit-${node.getData().id}`,
                    },
                    {
                      indices: false,
                    }
                  )}`,
                  '_blank'
                );
              }}
              onMouseEnter={handleTitleNameHover}
            >
              {node.getData().name}
            </div>
          </AntdTooltip>
        </div>
      </div>
    </div>
  );
};

export default EnergyUnitNode;

export function getEnergyUnitNodeHeight() {
  return commonNodeSize.height;
}
