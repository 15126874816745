import { Table } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import { EllipsisSpan } from '@maxtropy/components';
import { PhotovoltaicQuantitiesItem } from '../../../../api/electricity';
import { units } from '../utils';
import {
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceProps,
} from '../../../../api/electricitySettlementRules';

const columns = [
  {
    title: '',
    dataIndex: 'highLowType',
    ellipsis: { showTitle: true },
    render: (v: HasTimeOfUseElectricityPricePropsType) => (
      <EllipsisSpan value={v in HasTimeOfUseElectricityPricePropsType ? HasTimeOfUseElectricityPriceProps[v] : '总'} />
    ),
    width: 70,
    // className: `${styles.firstCell}`,
  },
  {
    title: '光伏表计总计发电量',
    dataIndex: 'totalGeneratePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '总计上网电量',
    dataIndex: 'totalOnlinePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '光伏实际用电量',
    dataIndex: 'totalActivePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '单价',
    dataIndex: 'unitPowerRate',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(8)} ${units['元/千瓦时']}` : v} />
    ),
  },
  {
    title: '光伏有功电费',
    dataIndex: 'totalElectricFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
];

const PVRealityPower: FC<{ actualQuantities: PhotovoltaicQuantitiesItem[] }> = ({ actualQuantities }) => {
  const dataSource = actualQuantities.map(item => {
    return {
      id: item.id,
      totalGeneratePowerQuantity: item.totalGeneratePowerQuantity,
      totalOnlinePowerQuantity: item.totalOnlinePowerQuantity,
      totalActivePowerQuantity: item.totalActivePowerQuantity,
      unitPowerRate: item.unitPowerRate,
      totalElectricFee: item.totalElectricFee,
      highLowType: item.highLowType,
      actualActivePowerQuantity: item.actualActivePowerQuantity,
    };
  });

  const tableDataSource = [
    ...dataSource,
    ...[
      dataSource.reduce(
        (a, b) => {
          return {
            ...a,
            totalGeneratePowerQuantity: (a.totalGeneratePowerQuantity ?? 0) + (b.totalGeneratePowerQuantity ?? 0),
            totalOnlinePowerQuantity: (a.totalOnlinePowerQuantity ?? 0) + (b.totalOnlinePowerQuantity ?? 0),
            totalActivePowerQuantity: (a.totalActivePowerQuantity ?? 0) + (b.actualActivePowerQuantity ?? 0),
            totalElectricFee: (a.totalElectricFee ?? 0) + (b.totalElectricFee ?? 0),
          };
        },
        {
          id: '总',
          highLowType: 5,
          totalGeneratePowerQuantity: 0,
          totalOnlinePowerQuantity: 0,
          totalActivePowerQuantity: 0,
          totalElectricFee: 0,
        } as unknown as Partial<PhotovoltaicQuantitiesItem>
      ),
    ],
  ];

  return (
    <div className={styles.activePowerTable}>
      <Table rowKey="highLowType" pagination={false} dataSource={tableDataSource} columns={columns} />
    </div>
  );
};

export default PVRealityPower;
