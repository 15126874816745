import React, { useCallback } from 'react';
import { Tag, useAsync } from '@maxtropy/components';
import { getOUList } from '../../api/options';
import styles from './index.module.scss';

interface TagDisplayFormProps {
  value?: Array<number> | undefined;
}

const TagDisplayForm: React.FC<TagDisplayFormProps> = ({ value }) => {
  const fetchOuList = useCallback(async () => {
    if (value?.length) {
      return getOUList({ ids: value });
    }
    return undefined;
  }, [value]);

  const data = useAsync(fetchOuList);

  return (
    <div className={styles.tagValue}>
      {value?.length
        ? value.map(ouId => (
            <Tag key={ouId} border="solid" color="info" className={styles.tagItem}>
              {data?.list?.find(item => item.id === ouId)?.name}
            </Tag>
          ))
        : '--'}
    </div>
  );
};

export default TagDisplayForm;
