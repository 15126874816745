import { RedoOutlined } from '@ant-design/icons';
import { Table, Paging, usePaging, useUpdate, EllipsisSpan, Button, Input, CustomFilter } from '@maxtropy/components';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { BatchRuleGroupPageRequest } from '../../../../api/ruleGroup';
import { IotProtocolType } from '@/shared/types';
import { apiV2RuleGroupDevicePageRuleGroupPost } from '@maxtropy/device-customer-apis-v2';
import { RuleGroupList } from '@/pages/Device/BatchBindRuleGroup/SelectRuleGroup/types';

const columns = [
  {
    title: '规则组编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则组名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则数量',
    dataIndex: 'ruleCount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface SelectRuleGroupModalChildrenProps {
  value?: RuleGroupList;
  onChange?: (val: RuleGroupList) => void;
  iotProtocol?: IotProtocolType;
  deviceTypeId?: number;
  objectModalType?: number;
}

type SearchParams = Omit<BatchRuleGroupPageRequest, 'iotProtocol' | 'size' | 'page'>;

const SelectRuleGroupModalChildren: FC<SelectRuleGroupModalChildrenProps> = ({
  value,
  onChange,
  iotProtocol,
  deviceTypeId,
  objectModalType,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [ruleGroups, setRuleGroups] = useState<RuleGroupList[]>([]);
  const [updateState, updateFn] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (iotProtocol !== undefined) {
      setLoading(true);
      apiV2RuleGroupDevicePageRuleGroupPost({
        ...searchParams,
        physicalModelId: objectModalType,
        deviceTypeId,
        iotProtocol,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total ?? 0);
          res.list && setRuleGroups(res.list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotProtocol, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
    });
    setPageOffset(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v: undefined, record: RuleGroupList) => {
        return (
          <Button
            type="link"
            disabled={record.id === value?.id}
            onClick={() => {
              onChange?.(record);
            }}
          >
            {record.id === value?.id ? '已选择' : '选择'}
          </Button>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter form={form} colSpan={8} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="name" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips}>
        <span>
          <Button type="link">
            <Link to={`/device/rule/list/group/create`} target="_blank">
              新建规则组
            </Link>
          </Button>
          <Button onClick={updateFn} icon={<RedoOutlined rotate={270} />}>
            刷新页面
          </Button>
        </span>
      </div>
      {iotProtocol === IotProtocolType.MOCKINGBIRD && (
        <div className={styles.tip}>
          提示：新增或解绑设备的报警规则组信息，需要前往网关管理页面，进行配置下发后，才可以生效。
        </div>
      )}
      <Table loading={loading} sticky scroll={{ y: 300 }} rowKey="id" columns={buildColumns} dataSource={ruleGroups} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default SelectRuleGroupModalChildren;
