import { EnergyWorkingProcessType, EnergyWorkingProcessTypeLabelColor, ItemPort, NodesProps } from '@/api/uet';
import G6, { Graph } from '@antv/g6';

export const basicConfig = () => ({
  container: 'mountNode', // 指定图画布的容器 id，与第 9 行的容器对应
  // 画布宽高
  // width: width,
  // height: height,
  modes: {
    default: [
      // {
      //   type: 'drag-node',
      //   // enableDebounce: true, // 是否在拖动节点时使用 debounce 来避免频繁的更新计算。这个 boolean 类型的配置项可以为折线边的更新提高性能，减少折线边的频繁重新寻径。默认值为 false；
      //   // 不允许拖拽 id 为 'node1' 的节点
      //   // if (e.item && e.item.getModel().id === 'node1') return false;
      //   // },
      // },
      'drag-canvas',
      'drag-node',
      'zoom-canvas',
      'click-select',
    ],
  },
  layout: {
    type: 'dagre',
    rankdir: 'LR',
    // 节点对齐方式
    // 'UL'：对齐到左上角；
    //'UR'：对齐到右上角；
    //'DL'：对齐到左下角；
    //'DR'：对齐到右下角；
    //undefined：默认，中间对齐。
    align: undefined,
    preventOverlap: true, //防重叠
    // nodesep: 80, // 节点的水平间距
    // ranksep: 60, // 竖直方向相邻层间距
  },
  defaultNode: {
    type: 'rect',
    // type: 'dom-node', // 可以自定义样式
    style: {
      lineWidth: 2,
      stroke: '#5B8FF9',
      fill: '#C6E5FF',
    },
  },
  defaultEdge: {
    type: 'polyline',
    size: 1,
  },
  // defaultEdge: {
  //   type: 'line-dash', // 虚线动画
  //   size: 1,
  // },
  // 设定状态
  // nodeStateStyles: {
  //   selected: {
  //     stroke: '#d9d9d9',
  //     fill: '#5394ef',
  //   },
  // },

  fitView: true, //超出适配
  // fitViewPadding: [20, 10, 50, 10], //页面留白宽度
  fitViewPadding: [120, 100], //页面留白宽度
  // plugins: [minimap, grid], // 将 grid 实例配置到图上
});

// 注册节点。类型为开始或者结束类型
export const initChartNode = (
  name: string,
  type: EnergyWorkingProcessType,
  entryVos?: ItemPort[],
  exitVos?: ItemPort[]
) => {
  // 注册节点。类型为开始或者结束类型
  G6.registerNode(name, {
    draw: (cfg, group) => {
      const max = Math.max((entryVos ?? []).length, (exitVos ?? []).length);
      const size = [80, max * 18];
      const keyShape: any = group!.addShape('rect', {
        attrs: {
          width: size[0],
          height: size[1],
          x: -size[0] / 2,
          y: -size[1] / 2,
          fill: EnergyWorkingProcessTypeLabelColor[type],
          stroke: EnergyWorkingProcessTypeLabelColor[type],
          radius: 4,
          cursor: 'pointer',
        },
        draggable: true,
        name: 'root-keyshape',
      });
      group!.addShape('text', {
        attrs: {
          text: `${cfg!.label}`,
          fontSize: cfg!.label!.toString().length > 6 ? 8 : 12,
          fill: 'rgba(255, 255, 255, 0.85)',
          textAlign: 'center',
          lineHeight: size[1],
          textBaseline: 'middle',
          cursor: 'pointer',
        },
        draggable: true,
        name: 'ratio-shape',
      });
      entryVos?.forEach((item, index) => {
        if (item.hasDevice) {
          group!.addShape('rect', {
            attrs: {
              width: 8,
              height: 8,
              radius: 1,
              x: -size[0] / 2 - 4,
              y: -size[1] / 2 + (size[1] / (entryVos.length + 1)) * (index + 1) - 4,
              // fill: EnergyWorkingProcessTypeLabelColor[type],
              fill: '#fff',
              lineWidth: 0.2,
              cursor: 'pointer',
            },
            name: 'entry' + item.id,
          });
        } else {
          group!.addShape('circle', {
            attrs: {
              r: 4,
              x: -size[0] / 2,
              y: -size[1] / 2 + (size[1] / (entryVos.length + 1)) * (index + 1),
              fill: '#979797',
              stroke: '#979797',
              lineWidth: 0.2,
            },
            name: 'entry' + item.id,
          });
        }
      });

      exitVos?.forEach((item, index) => {
        if (item.hasDevice) {
          group!.addShape('rect', {
            attrs: {
              width: 8,
              height: 8,
              r: 1,
              x: size[0] / 2 - 4,
              y: -size[1] / 2 + (size[1] / (exitVos.length + 1)) * (index + 1) - 4,
              // fill: EnergyWorkingProcessTypeLabelColor[type],
              fill: '#fff',
              lineWidth: 0.2,
              cursor: 'pointer',
            },
            name: 'exit' + item.id,
          });
        } else {
          group!.addShape('circle', {
            attrs: {
              r: 4,
              x: size[0] / 2,
              y: -size[1] / 2 + (size[1] / (exitVos.length + 1)) * (index + 1),
              fill: '#979797',
              stroke: '#979797',
              lineWidth: 0.2,
            },
            name: 'exit' + item.id,
          });
        }
      });

      return keyShape;
    },
    setState: (name, value, item) => {
      const group = item!.getContainer();
      const shape = group.get('children')[0]; // 顺序根据 draw 时确定
      if (name === 'selected') {
        if (value) {
          shape.attr({
            stroke: '#fff',
            lineWidth: 2,
          });
        } else {
          shape.attr({
            stroke: EnergyWorkingProcessTypeLabelColor[type],
            lineWidth: 1,
          });
        }
      }
    },
  });
};

export const formatNodesAndEdges = (data: { nodes?: any[]; pointEdges?: any[] }) => {
  let formatData = JSON.parse(JSON.stringify(data));

  const finalResData = {
    nodes: formatData.nodes,
    edges: formatData.pointEdges,
  };

  // 通过出入口id，找到source和target节点与锚点
  finalResData.edges.forEach((item: any) => {
    let findSourceNode = finalResData.nodes.find((i: NodesProps) => i?.exitVos?.map(c => c.id).includes(item.sourceId));
    let findTargetNode = finalResData.nodes.find((i: NodesProps) =>
      i?.entryVos?.map(c => c.id).includes(item.targetId)
    );
    item.source = findSourceNode && findSourceNode?.id.toString();
    item.target = findTargetNode && findTargetNode?.id.toString();
    item.sourceAnchor = (findSourceNode?.entryVos.map((i: ItemPort) => i.id + 'entry') ?? [])
      .concat(findSourceNode?.exitVos.map((i: ItemPort) => i.id + 'exit') ?? [])
      .findIndex((m: string) => m === item.sourceId + 'exit');
    item.targetAnchor = (findTargetNode?.entryVos.map((i: ItemPort) => i.id + 'entry') ?? [])
      .concat(findTargetNode?.exitVos.map((i: ItemPort) => i.id + 'exit') ?? [])
      .findIndex((m: string) => m === item.targetId + 'entry');
    if (findSourceNode && findSourceNode.info) {
      item.style = {
        stroke: EnergyWorkingProcessTypeLabelColor[findSourceNode.info.type as EnergyWorkingProcessType],
        endArrow: {
          path: G6.Arrow.triangle(4, 8, 8), // 自定义箭头路径
          d: 10, // 偏移量
          fill: EnergyWorkingProcessTypeLabelColor[findSourceNode.info.type as EnergyWorkingProcessType],
        },
        radius: 3, // 拐弯处的圆角弧度，若不设置则为直角
        lineWidth: 1,
      };
    }
  });

  // 处理连线重叠问题，设置相同source与target的偏移量
  let newData: any = {};
  finalResData.edges.forEach((e: any) => {
    //新建属性名
    if (Object.keys(newData).indexOf(e.source + 'key' + e.target) === -1) {
      newData[e.source + 'key' + e.target] = [];
    }
    //对应插入属性值
    newData[e.source + 'key' + e.target].push(e);
  });
  // console.log(newData);
  Object.keys(newData).forEach(i => {
    newData[i].forEach((item: any, index: number) => {
      item.style = {
        ...item.style,
        offset: 15 + 10 * (index + 1),
      };
    });
  });

  finalResData.nodes.forEach(
    (item: {
      id: string;
      label: string;
      name: string;
      entryIds: number[];
      exitIds: number[];
      type: string;
      anchorPoints: any[];
      sourceAnchor: number;
      targetAnchor: number;
      info: {
        type: EnergyWorkingProcessType;
      };
      entryVos?: ItemPort[]; //入口id列表
      exitVos?: ItemPort[]; //出口id列表
    }) => {
      // 创建节点，根据进出水管的数量,建立左右管道与蓄水池的长宽
      item.type = item.name + item?.entryVos?.length + item?.exitVos?.length;
      // 锚点计算，通过进出水管的数量
      const a = [...new Array(item?.entryVos?.length)].map((_: any, index: number) => [
        0,
        (1 / ((item?.entryVos ?? []).length + 1)) * (index + 1),
      ]);
      const b = [...new Array(item?.exitVos?.length)].map((_: any, index: number) => [
        1,
        (1 / ((item?.exitVos ?? []).length + 1)) * (index + 1),
      ]);
      item.anchorPoints = a.concat(b);
      item.label = item.name;
      item.id = String(item.id);

      initChartNode(item.type, item.info.type, item.entryVos, item.exitVos);
    }
  );

  return finalResData;
};

// 自适应
export const fitScreenView = (graph: Graph, width: number, height: number) => {
  graph.changeSize(width, height);
  graph.fitView();
};

// 定义数据源
// const data: { nodes: any[]; edges: any[] } = {
//   // 点集
//   nodes: [
//     {
//       id: 'node0',
//       label: '起始点', // 节点文本
//       layer: 0,
//       info: {
//         label: '起始点',
//         name: 'zhangsan',
//         data: '123123',
//         jin: 3,
//         chu: 5,
//       },
//     },
//     {
//       id: 'node1',
//       label: '目标点1', // 节点文本
//       // layer: 1,
//       info: {
//         label: '目标点1',
//         name: 'zhangsan1',
//         data: '1231231',
//         jin: 8,
//         chu: 1,
//       },
//     },
//     {
//       id: 'node2',
//       label: '目标点2', // 节点文本
//       // layer: 1,
//       info: {
//         label: '目标点2',
//         name: 'zhangsan123',
//         data: 'dsadas',
//         jin: 10,
//         chu: 7,
//       },
//     },
//     {
//       id: 'node3',
//       label: '目标点3', // 节点文本
//       // layer: 3,
//       info: {
//         label: '目标点3',
//         name: 'zhangsan3',
//         data: '1231231',
//         jin: 2,
//         chu: 2,
//       },
//     },
//     {
//       id: 'node4',
//       label: '目标点4', // 节点文本
//       // layer: 4,
//       // anchorPoints: [[0.5, 0]],
//       info: {
//         label: '目标点4',
//         name: 'zhangsan4',
//         data: '1231231sdas',
//         jin: 3,
//         chu: 3,
//       },
//     },
//     {
//       id: 'node5',
//       label: '目标点5', // 节点文本
//       // layer: 5,
//       info: {
//         label: '目标点5',
//         name: 'zhangsan121',
//         data: '12312asd31',
//         jin: 3,
//         chu: 13,
//       },
//     },
//     {
//       id: 'node6',
//       label: '目标点6', // 节点文本
//       // layer: 6,
//       info: {
//         label: '目标点6',
//         name: 'zhangsan6',
//         data: '1231231as',
//         jin: 3,
//         chu: 1,
//       },
//     },
//     // {
//     //   id: 'node7',
//     //   label: '目标点7', // 节点文本
//     //   info: {
//     //     label: '目标点7',
//     //     name: 'zhangsan1991',
//     //     data: '1zx231231',
//     //     jin: 6,
//     //     chu: 12,
//     //   },
//     // },
//   ],
//   // 边集
//   edges: [
//     // 表示一条从 node1 节点连接到 node2 节点的边
//     {
//       source: 'node0',
//       target: 'node1',
//       sourceAnchor: 0,
//       targetAnchor: 0,

//       // style: {
//       //   offset: 10, // 拐弯处距离节点最小距离
//       // },
//       // label: '我是连线1', // 边的文本
//     },
//     {
//       source: 'node0',
//       target: 'node1',
//       sourceAnchor: 1,
//       targetAnchor: 2,
//       // style: {
//       //   offset: 15, // 拐弯处距离节点最小距离
//       // },
//       // label: '我是连线1', // 边的文本
//     },
//     {
//       source: 'node0',
//       target: 'node1',
//       sourceAnchor: 2,
//       targetAnchor: 5,
//       // style: {
//       //   offset: 20, // 拐弯处距离节点最小距离
//       // },
//       // label: '我是连线1', // 边的文本
//     },
//     {
//       source: 'node1',
//       target: 'node2',
//       sourceAnchor: 1,
//       // // 该边连入 source 点的第 1 个 anchorPoint，
//       targetAnchor: 6,
//       // label: '我是连线2', // 边的文本
//     },
//     {
//       source: 'node2',
//       target: 'node3',
//       sourceAnchor: 4,
//       targetAnchor: 1,
//     },
//     {
//       source: 'node3',
//       target: 'node0',
//     },
//     {
//       source: 'node3',
//       target: 'node4',
//       sourceAnchor: 2,
//       targetAnchor: 0,
//     },
//     {
//       source: 'node4',
//       target: 'node2',
//       sourceAnchor: 3,
//       targetAnchor: 1,
//     },
//     {
//       source: 'node4',
//       target: 'node5',
//     },
//     {
//       source: 'node5',
//       target: 'node6',
//     },
//     {
//       source: 'node6',
//       target: 'node4',
//     },
//   ],
// };
