import { fetch } from '@maxtropy/components';
import { Key } from 'react';

export interface SimpleCircuitInfo {
  id: number;
  name: string;
}

export interface CabinetWithCircuitResponse extends SimpleCircuitInfo {
  circuitList: SimpleCircuitInfo[];
}

// 删除配电柜
export function deleteCabinet(id: number) {
  return fetch(
    `/api/v2/distribution-cabinet/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取配电柜带回路列表
export function getCabinetWithCircuitList() {
  return fetch<{ list: CabinetWithCircuitResponse[] }>(
    `/api/v2/distribution-cabinet/listWithCircuit`,
    {
      method: 'POST',
    },
    true
  );
}

// 新建配电柜
export function addCabinet(name: string) {
  return fetch(
    `/api/v2/distribution-cabinet/add`,
    {
      method: 'POST',
      body: JSON.stringify({ name }),
    },
    true
  );
}
// 修改配电柜
export function editCabinet(id: number, name: string) {
  return fetch(
    `/api/v2/distribution-cabinet/edit`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
        name,
      }),
    },
    true
  );
}

export interface CabinetListCircuitWithDataProps {
  id: Key;
  orderBy: number;
}

export interface CabinetListCircuitWithDataRes {
  circuitId: number; // 回路id
  circuitName: string; //回路名称
  electricity: number; //电量
  activePower: number; //有功功率
}

// 根据配电柜查询回路带数据
export function getCabinetListCircuitWithData(params: CabinetListCircuitWithDataProps) {
  return fetch<{ list: CabinetListCircuitWithDataRes[] }>(
    `/api/v2/distribution-cabinet/listCircuitWithData`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 配电柜批量绑定回路
export function distributionCabinetBandCircuits(id: Key, circuitIds: number[]) {
  return fetch(
    `/api/v2/distribution-cabinet/circuits`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
        circuitIds,
      }),
    },
    true
  );
}
