import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Col, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import CustomTariff from '../components/CustomTariff';
import PhotovoltaicDeviceTable from '../components/PhotovoltaicDeviceTable';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ElectricityAccountRes,
  EnergyAssetsPVStationDevice,
  EnergyAssetsSettlementsRulesBody,
  EnergyAssetsSettlementsRulesDetailRes,
  PriceType,
  addOrEditEnergyAssetsSettlementsRules,
  getElectricityAccountList,
  getEnergyAssetsPVStationDevice,
  getEnergyAssetsSettlementsRulesDetail,
} from '@/api/energyAssetsSettlementsRules';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementType,
  SettlementGeneratorMode,
  SettlementRulesStatusTypeValue,
} from '@/api/electricitySettlementRules';
import { isNil } from 'lodash-es';
import {
  DATE_FORMAT,
  formLayout,
  limitDecimalsF,
  limitDecimalsFTwo,
  limitDecimalsPEight,
  limitDecimalsPTwo,
} from '../units';
import { BSADeviceItem, getBSADeviceItem, getBSAList } from '@/api/energyAssetsBill';
import BsaDeviceTable from '../components/BsaDeviceTable';

interface FormProps extends EnergyAssetsSettlementsRulesBody {
  timeOfDayRateChecked: number[];
  effectiveTime: [Dayjs, Dayjs];
  priceType: PriceType; // 结算电价
  tariffTemplate: Key; // 电价模板， 用电账户id
}

const routes = [{ name: '编辑结算规则' }];

const EditEnergyAssetsSettlementRules: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const priceType = Form.useWatch('priceType', form); // 结算电价
  const [finishLoading, setFinishLoading] = useState<boolean>(false); // 点击保存loading
  const [electricityAccountList, setElectricityAccountList] = useState<ElectricityAccountRes[]>([]); // 用电账户列表
  const [pvStationDeviceData, setPVStationDeviceData] = useState<EnergyAssetsPVStationDevice[]>([]); // 光伏站下的设备列表
  const [bsaStationDeviceData, setBsaStationDeviceData] = useState<BSADeviceItem[]>([]);
  const [detail, setDetail] = useState<EnergyAssetsSettlementsRulesDetailRes>(); // 编辑时的详情
  const [loading, setLoading] = useState<boolean>(false); // 页面loading

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEnergyAssetsSettlementsRulesDetail(id)
        .then(res => {
          if (res) {
            const timeOfDayRateChecked = [res.summitPrice, res.peakPrice, res.plainPrice, res.valleyPrice]
              .map((i, index) => (isNil(i) ? 0 : index + 1))
              .filter(i => i !== 0);
            form.setFieldsValue({
              name: res.name,
              code: res.code,
              effectiveTime: `${dayjs(res.startTime).format(DATE_FORMAT)}~${dayjs(res.endTime).format(DATE_FORMAT)}`,
              customerName: res.customerName,
              propertyManagementConfigName: res.propertyManagementConfigName,
              saleClientName: res.saleClientName,
              saleElectricityContractName: res.saleElectricityContractName,
              saleContractEffectTime: `${dayjs(res.contractStartTime).format(DATE_FORMAT)}~${dayjs(
                res.contractEndTime
              ).format(DATE_FORMAT)}`,
              status: SettlementRulesStatusTypeValue[res.status],
              settlementType: res.settlementType,
              settlementTypeName: ElectricitySaleContractSettlement[res.settlementType],
              priceType: res.settlementTemplate ? PriceType.TEMPLATE : PriceType.CUSTOM,
              tariffTemplate: res.settlementTemplate,
              timeOfDayRateChecked,
              summitPrice: res.summitPrice,
              peakPrice: res.peakPrice,
              plainPrice: res.plainPrice,
              valleyPrice: res.valleyPrice,
              gridPrice: res.gridPrice,
              discountForOwner: res.discountForOwner,
              gridPercentageForOwner: res.gridPercentageForOwner,
              arrayName: res.arrayName,
              generationMode: res.generationMode,
            });
            // 根据所属组织查询用电账户列表
            getElectricityAccountList(res.customerMcid).then(res => setElectricityAccountList(res.list || []));
            // 根据光伏站id查询光伏站下的设备
            if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
              getEnergyAssetsPVStationDevice(res.arrayId).then(setPVStationDeviceData);
            }
            if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
              getBSADeviceItem(res.arrayId).then(res => setBsaStationDeviceData([res]));
              getBSAList().then(list => {
                const deviceName = (list ?? [])?.find(k => k.id === res.arrayId)?.name;
                form.setFieldsValue({ arrayName: deviceName });
              });
            }

            setDetail(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, form]);

  // 用电账户列表
  const electricityAccountListOptions = useMemo(() => {
    return (electricityAccountList ?? []).map(i => ({
      label: i.number,
      value: i.id,
    }));
  }, [electricityAccountList]);

  const onFinish = (v: FormProps) => {
    const settlementTemplate = v.priceType === PriceType.TEMPLATE ? v.tariffTemplate : null;
    const commonBody = {
      ...v,
      startTime: detail?.startTime,
      endTime: detail?.endTime,
      settlementTemplate,
      id: detail?.id,
      customerMcid: detail?.customerMcid,
      propertyManagementConfigId: detail?.propertyManagementConfigId,
      saleClientId: detail?.saleClientId,
      saleElectricityContractId: detail?.saleElectricityContractId,
      settlementType: detail?.settlementType,
      arrayId: detail?.arrayId,
    };
    setFinishLoading(true);
    addOrEditEnergyAssetsSettlementsRules(commonBody)
      .then(() => {
        navigate('/operation/settlementRules/energyAssetsRules');
      })
      .finally(() => {
        setFinishLoading(false);
      });
  };
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="编辑结算规则"></FormTitle>

      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={(v: FormProps) => onFinish(v)}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
        preserve={false}
        initialValues={{
          timeOfDayRateChecked: [1, 2, 3, 4],
        }}
      >
        <Spin spinning={loading}>
          <SubContent>
            <Row>
              <Col span={8}>
                <Form.Item name="code" label="结算规则编号">
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="name"
                  label="结算规则名称"
                  rules={[
                    { required: true, message: '请输入结算规则名称' },
                    { max: 50, message: '最多输入50个字符' },
                    { whitespace: true },
                  ]}
                >
                  <Input placeholder="请输入结算规则名称,不超过五十个字" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="customerName" label="关联组织" rules={[{ required: true, message: '请选择关联组织' }]}>
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="propertyManagementConfigName"
                  label="所属项目公司"
                  rules={[{ required: true, message: '请选择所属项目公司' }]}
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属客户" name="saleClientName">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="saleElectricityContractName"
                  label="所属合同"
                  rules={[{ required: true, message: '请选择所属合同' }]}
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="effectiveTime"
                  label="结算规则生效区间"
                  rules={[{ required: true, message: '请输入生效区间' }]}
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="saleContractEffectTime" label="合同有效期">
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="status" label="结算规则状态">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="结算场景">
                  <ShowInput value="能源资产营销" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="generationMode"
                  label="结算单生成方式"
                  rules={[{ required: true, message: '请选择结算单生成方式' }]}
                >
                  <Radio.Group>
                    <Radio value={SettlementGeneratorMode.AUTO}>跟随合同结算日自动生成</Radio>
                    <Radio value={SettlementGeneratorMode.MANUAL}>手动生成</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="settlementTypeName" label="结算类型">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="priceType" label="结算电价" rules={[{ required: true, message: '请选择结算电价' }]}>
                  <Radio.Group>
                    <Radio value={PriceType.CUSTOM}>自定义</Radio>
                    <Radio value={PriceType.TEMPLATE}>电价模板</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {priceType === PriceType.CUSTOM && (
                <Col span={24}>
                  <CustomTariff />
                </Col>
              )}
              {priceType === PriceType.TEMPLATE && (
                <Col span={8}>
                  <Form.Item
                    name="tariffTemplate"
                    label="电价模板"
                    rules={[{ required: true, message: '请选择电价模板' }]}
                  >
                    <Select placeholder={'请选择'} options={electricityAccountListOptions} />
                  </Form.Item>
                </Col>
              )}
              <Form.Item noStyle dependencies={['settlementType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="gridPrice"
                            label="上网电价"
                            rules={[{ required: true, message: '请输入上网电价' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={99999999.99999999}
                              min={0}
                              step="0.00000001"
                              addonAfter="元/kWh"
                              formatter={limitDecimalsF}
                              parser={limitDecimalsPEight}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="discountForOwner"
                            label="电价折扣"
                            rules={[{ required: true, message: '请输入电价折扣' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="gridPercentageForOwner"
                            label="上网分成比例"
                            rules={[{ required: true, message: '请输入上网分成比例' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="arrayName"
                            label="光伏站"
                            rules={[{ required: true, message: '请选择光伏站' }]}
                          >
                            <ShowInput />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="discountForOwner"
                            label="放电折扣"
                            rules={[{ required: true, message: '请输入电价折扣' }]}
                          >
                            <InputNumber
                              placeholder="请输入"
                              style={{ width: '100%' }}
                              max={1}
                              min={0}
                              step="0.01"
                              formatter={limitDecimalsFTwo}
                              parser={limitDecimalsPTwo}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="arrayName" label="储能站">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                }}
              </Form.Item>
              <Col span={24}>
                <Form.Item noStyle dependencies={['settlementType']}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                      return <PhotovoltaicDeviceTable data={pvStationDeviceData} />;
                    }
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                      return <BsaDeviceTable data={bsaStationDeviceData} />;
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary" loading={finishLoading}>
            保存
          </Button>
          <Button onClick={() => navigate('/operation/settlementRules/energyAssetsRules')}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default EditEnergyAssetsSettlementRules;
