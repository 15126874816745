import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Button, Form, Input, message, Modal, Select, Switch, useAsync } from '@maxtropy/components';
import { Space } from 'antd';
import { uniqueId, cloneDeep } from 'lodash-es';
import { FC, useEffect, useState, Key } from 'react';
import { columns, contactColumns } from './util';

import { SaleClientInfo, getSaleClientList, SaleClientContactInfo, getSaleClient } from '../../api/sale';
import { OutsiderResponse } from '../../api/deviceAlarmPushStrategy';
import { getOutsidersChannelApp } from '@/shared/api/common';
import { ChannelApp } from '@/shared/types';

export interface SelectPushOutSiderProps {
  value?: OutsiderResponse[];
  onChange?: (value: OutsiderResponse[]) => void;
  disabled?: boolean;
  hasContact?: boolean;
  // 返回true，校验不通过, 否则校验通过
  validator?: (values: string[]) => boolean | undefined;
}

const errorMessage = '手机号已被添加/与内部推送人员重复，请重新';

const SelectPushOutSider: FC<SelectPushOutSiderProps> = ({
  disabled = false,
  hasContact = false,
  validator,
  value,
  onChange,
}) => {
  const [form] = Form.useForm();

  const outsidersChannelApps = useAsync(getOutsidersChannelApp);

  const [contactVisible, setContactVisible] = useState<boolean>(false);

  const [current, setCurrent] = useState<OutsiderResponse>();

  const onDelete = (index: number) => {
    onChange?.([...(value ?? []).slice(0, index), ...(value ?? []).slice(index + 1)]);
  };

  useEffect(() => {
    if (current) {
      form.setFieldsValue({
        contactName: current.contactName,
        contactPhone: current.contactPhone,
      });
    }
  }, [current, form]);

  const changeRecordThirdApp = (checked: boolean, channelApp: ChannelApp, record: OutsiderResponse) => {
    const index = (value ?? []).findIndex(i => i.id === record.id);
    const newRecord = cloneDeep(record);
    if (!Array.isArray(newRecord.thirdPartyIdList)) {
      newRecord.thirdPartyIdList = [];
    }
    if (index !== -1) {
      const thirdAppIndex = (newRecord.thirdPartyIdList ?? []).findIndex(i => i === channelApp.channelId);
      if (checked) {
        if (thirdAppIndex === -1) {
          newRecord.thirdPartyIdList.push(channelApp.channelId);
        }
      } else {
        if (thirdAppIndex !== -1) {
          newRecord.thirdPartyIdList = record.thirdPartyIdList.filter(i => i !== channelApp.channelId);
        }
      }
      const newValue = [...(value ?? []).slice(0, index), newRecord, ...(value ?? []).slice(index + 1)];
      onChange?.(newValue);
    }
  };

  const buildColumns = [
    ...columns,
    ...(outsidersChannelApps ?? []).map(i => ({
      title: i.channelName,
      render: (value: undefined, record: OutsiderResponse) => {
        const checked = !!(record.thirdPartyIdList ?? []).find(j => j === i.channelId);
        return disabled ? (
          checked ? (
            '开'
          ) : (
            '关'
          )
        ) : (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            onChange={(checked: boolean) => {
              changeRecordThirdApp(checked, i, record);
            }}
          />
        );
      },
    })),
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            width: 250,
            fixed: 'right' as const,
            render: (value: undefined, record: OutsiderResponse, index: number) => {
              return (
                <Space size={16}>
                  <Button
                    type="link"
                    onClick={() => {
                      onDelete(index);
                    }}
                  >
                    移除
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrent(record);
                    }}
                  >
                    编辑
                  </Button>
                </Space>
              );
            },
          },
        ]),
  ];

  /** 添加编辑 */

  const [visible, setVisible] = useState<boolean>(false);

  const onAdd = () => {
    setVisible(true);
  };

  const onOk = () => {
    form.validateFields().then((data: Omit<OutsiderResponse, 'id' | 'thirdPartyIdList'>) => {
      if (current) {
        const index = (value ?? []).findIndex(i => i.id === current.id);
        onChange?.([
          ...(value ?? []).slice(0, index),
          {
            ...current,
            ...data,
          },
          ...(value ?? []).slice(index + 1),
        ]);
      } else {
        onChange?.([
          {
            ...data,
            id: uniqueId(),
            thirdPartyIdList: [],
          },
          ...(value ?? []),
        ]);
      }
      onClose();
    });
  };

  const onClose = () => {
    setCurrent(undefined);
    setVisible(false);
    form.resetFields();
  };

  /** 添加编辑 */

  /** 添加客户联系人 */

  const [clientData, setClientData] = useState<SaleClientInfo[]>([]);
  const [saleClientId, setSaleClientId] = useState<number>();
  const [contacts, setContacts] = useState<SaleClientContactInfo[]>();

  const [selectContacts, setSelectContacts] = useState<SaleClientContactInfo[]>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (saleClientId) {
      setLoading(true);
      getSaleClient(saleClientId).then(res => {
        setContacts(res.contacts);
        setLoading(false);
      });
    }
  }, [saleClientId]);

  const rowSelection = {
    onChange: (_: Key[], selectedRows: SaleClientContactInfo[]) => {
      setSelectContacts(selectedRows);
    },
    selectedRowKeys: (selectContacts ?? []).map(i => i.id),
  };

  useEffect(() => {
    if (saleClientId) {
      setSelectContacts([]);
    }
  }, [saleClientId]);

  useEffect(() => {
    if (hasContact) {
      getSaleClientList().then(setClientData);
    }
  }, [hasContact]);

  const onContactAdd = () => {
    setContactVisible(true);
  };

  const onContactOk = () => {
    if ((selectContacts ?? []).length === 0) {
      return;
    }
    const contactCellphones = (selectContacts ?? []).map(i => i.contactCellphone);
    const uniqueContactCellphones = Array.from(new Set(contactCellphones));
    if (contactCellphones.length !== uniqueContactCellphones.length) {
      message.warning(`${errorMessage}选择`);
      return;
    }
    const validateStatus = validator?.(contactCellphones) || undefined;
    if (validateStatus !== true) {
      if ((value ?? []).some(i => contactCellphones.includes(i.contactPhone))) {
        message.warning(`${errorMessage}选择`);
        return;
      }
      const addValues: OutsiderResponse[] = (selectContacts ?? []).map(i => ({
        contactPhone: i.contactCellphone,
        contactName: i.contactName,
        id: uniqueId(),
        thirdPartyIdList: [],
      }));
      onChange?.([...addValues, ...(value ?? [])]);
      onContactClose();
    } else {
      message.warning(`${errorMessage}选择`);
      return;
    }
  };

  const onContactClose = () => {
    setContactVisible(false);
    setSaleClientId(undefined);
    setSelectContacts([]);
    setContacts([]);
  };

  /** 添加客户联系人 */

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {!disabled && (
          <Space style={{ marginBottom: 10 }} size={8}>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
              添加
            </Button>
            {hasContact && (
              <Button type="primary" icon={<PlusOutlined />} onClick={onContactAdd}>
                从客户联系人中选择
              </Button>
            )}
          </Space>
        )}
        <Table
          rowKey="contactPhone"
          scroll={{ x: 1300, y: 400 }}
          sticky
          loading={false}
          dataSource={value ?? []}
          columns={buildColumns}
        />
      </Space>
      <Modal
        contentClassName="modal-form-content"
        title="外部推送人员"
        open={!!current || visible}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
      >
        <Form form={form}>
          <Form.Item
            name="contactName"
            label="联系人"
            rules={[
              { required: true, message: '请输入联系人', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
          <Form.Item
            name="contactPhone"
            label="手机号"
            rules={[
              { required: true, message: '请输入手机号', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
              {
                validator: (_: any, v: string) => {
                  const validateStatus = validator?.([v]) || undefined;
                  if (validateStatus !== true) {
                    // 外部校验通过
                    // TODO： 排除current下的值，校验当前组件内的value是否通过
                    const find = (value ?? []).filter(i => i !== current).find(i => i.contactPhone === v);
                    if (find !== undefined) {
                      return Promise.reject(`${errorMessage}输入`);
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject(`${errorMessage}输入`);
                  }
                },
              },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="从客户中添加"
        width={800}
        open={contactVisible}
        onCancel={onContactClose}
        destroyOnClose
        onOk={onContactOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Space direction="vertical">
          <Form style={{ width: 450 }} labelCol={{ flex: '110px' }} labelAlign="right">
            <Form.Item label="客户选择" required>
              <Select placeholder="请选择" value={saleClientId} onChange={setSaleClientId}>
                {(clientData ?? []).map(item => (
                  <Select.Option key={item.id} value={item.id}>{`${item.name} ${item.code}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <Table
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            sticky
            scroll={{ y: 300 }}
            rowKey="id"
            columns={contactColumns}
            dataSource={contacts}
          />
        </Space>
      </Modal>
    </>
  );
};

export default SelectPushOutSider;
