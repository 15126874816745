import { RestType } from '@/api/shiftCalendar';
import { V2WorkShiftCalendarDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

export type WorkShiftsItem = Exclude<V2WorkShiftCalendarDetailPostResponse['workShifts'], undefined>[number];

export type OptionSelect = { label: string; value: string };

export type ShiftCalendarItem = V2WorkShiftCalendarDetailPostResponse & {
  extra?: Record<string, string[]>;
  items?: any;
  workShiftCalendarExtras?: any;
};

export type ColorItem = {
  bg: string;
  border: string;
};

export type ShiftInfo = WorkShiftsItem & {
  colors?: ColorItem;
};

export const colorsItems: ColorItem[] = [
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
  { bg: '#F633FF66', border: '#F633FF' },
  { bg: '#FF4D4F66', border: '#FF4D4F' },
  { bg: '#00ADFF66', border: '#00ADFF' },
  { bg: '#4EBE1C66', border: '#4EBE1C' },
  { bg: '#FCB41D66', border: '#FCB41D' },
];

// 这个函数用于检查班次时间是否存在重叠。
export const createRangeTimeStr = (pars: string) => {
  let time = pars.split(';');
  let str = '';
  time = convertTimeSegmentsToNextDay(time);
  if (time.length <= 1) {
    str = `(${time})`;
    return str;
  }
  time.forEach((item, index) => {
    str += index > 0 ? `~(${item})` : `(${item})`;
  });
  return `(${time.join(' ; ')})`;
};

// 是否存在重复数据
export const hasExsitTime = (item: ShiftInfo, shiftTimes: string[]) => {
  let timeArr = (item.timeSeg as string).split(';');
  let exist = timeArr.some(time => {
    if (shiftTimes.includes(time)) return true;
    let startTime = time.split('-')[0];
    let endTime = time.split('-')[1];
    return shiftTimes.some(exist => {
      let tempArr = exist.split('-');
      return (
        (startTime > tempArr[0] && startTime < tempArr[1]) ||
        (endTime > tempArr[0] && endTime < tempArr[1]) ||
        (tempArr[0] < endTime && tempArr[0] > startTime) ||
        (tempArr[1] < endTime && tempArr[1] > startTime)
      );
    });
  });
  return exist;
};

// 结束时间比开始时间是否大于24小时
export const isOver24Hours = (item: ShiftInfo, shiftTimes: string[]) => {
  const timeSegments = shiftTimes
    .concat(item.timeSeg as string)
    .map((item: any) => item.split(';'))
    .flat()
    .map((item: any) => item.split('-'))
    .flat();

  const timeArr = timeSegments
    .map(time => {
      const [hour, minute] = time.split(':').map(Number);
      return hour * 60 + minute;
    })
    .sort((a, b) => a - b);
  return timeArr[timeArr.length - 1] - timeArr[0] > 1440;
};

export const convertTimeSegmentsToNextDay = (timeSegments: string[]) => {
  return timeSegments.map(segment => {
    const [start, end] = segment.split('-');

    // 提取小时和分钟
    const [endHour, endMinute] = end.split(':').map(Number);

    // 如果结束时间的小时数大于或等于 24，表示次日
    if (endHour >= 24) {
      const adjustedHour = endHour - 24; // 将24小时制的小时数转换为次日
      const adjustedEnd = `次日${adjustedHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
      return `${start}-${adjustedEnd}`;
    } else {
      return segment;
    }
  });
};

export const createMonthsSelect = () => {
  let amount = 31;
  let arr: OptionSelect[] = [];
  for (let i = 1; i <= amount; i++) {
    arr[i] = {
      label: `${i}号`,
      value: `${i}`,
    };
  }
  arr.push({
    label: '月末',
    value: '0',
  });
  return arr;
};
export const createWeekSelect = () => {
  return [
    { label: '周日', value: '7' },
    { label: '周一', value: '1' },
    { label: '周二', value: '2' },
    { label: '周三', value: '3' },
    { label: '周四', value: '4' },
    { label: '周五', value: '5' },
    { label: '周六', value: '6' },
  ] as OptionSelect[];
};

export const options: Record<RestType, OptionSelect[]> = {
  [RestType.WEEK]: createWeekSelect(),
  [RestType.MONTH]: createMonthsSelect(),
};

export const formateRestType = {
  [RestType.WEEK]: '按周',
  [RestType.MONTH]: '按月',
};
export const formateRestKey = {
  [RestType.WEEK]: 'weeks',
  [RestType.MONTH]: 'months',
};

export enum IsWorkType {
  NO = 0,
  TRUE = 1,
}

export const IsWorkTypeDisplay = {
  [IsWorkType.NO]: '否',
  [IsWorkType.TRUE]: '是',
};
