import { Table, Paging, usePaging, useUpdate, EllipsisSpan, Input, CustomFilter, Form } from '@maxtropy/components';
import { FC, Key, useEffect, useState } from 'react';
import { BatchRuleGroupDeviceRequest } from '../../../../api/ruleGroup';
import { IotProtocolType } from '@/shared/types';
import {
  V2RuleGroupDevicePageDeviceBindingPostResponse,
  apiV2RuleGroupDevicePageDeviceBindingPost,
} from '@maxtropy/device-customer-apis-v2';

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DevicesModalProps {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  type?: number[];
  iotProtocol?: IotProtocolType;
  deviceTypeId?: number;
  objectModalType?: number;
}

type SearchParams = Omit<BatchRuleGroupDeviceRequest, 'iotProtocol' | 'size' | 'page' | 'deviceType'>;

const DevicesModal: FC<DevicesModalProps> = ({ value, onChange, iotProtocol, deviceTypeId, objectModalType }) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<V2RuleGroupDevicePageDeviceBindingPostResponse['list']>([]);
  const [updateState] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (iotProtocol !== undefined) {
      setLoading(true);
      apiV2RuleGroupDevicePageDeviceBindingPost({
        ...searchParams,
        iotProtocol,
        deviceTypeId,
        physicalModelId: objectModalType,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total ?? 0);
          res.list && setDevices(res.list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotProtocol, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      onChange?.(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: value ?? [],
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} colSpan={8} onReset={onReset}>
      <Form.Item name="name" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="deviceId"
        columns={columns}
        dataSource={devices}
        selectedCount={value?.length ?? 0}
        showSelectedCount
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </>
  );
};

export default DevicesModal;
