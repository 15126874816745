import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, useUpdate, Button, Modal } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import { FC, useEffect, useRef, useState } from 'react';
import {
  addSaleMeterDevice,
  deleteSaleMeterDevice,
  ElectricMeterTypeEnum,
  getSaleMeterDeviceList,
  SaleMeterDevice,
} from '../../../../../api/sale';

import DeviceModal, { DeviceModalRefProps } from './DeviceModal';

export interface SaleDeviceProps {
  disabled?: boolean;
  saleClientId: number;
  type: ElectricMeterTypeEnum;
}

const columns: ColumnType<SaleMeterDevice>[] = [
  {
    title: '表计序号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '表计标签',
    dataIndex: 'meterTags',

    ellipsis: { showTitle: true },
    render: (tags: string[]) => <EllipsisSpan value={tags.join('、')} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',

    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',

    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',

    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',

    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',

    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const SaleDevice: FC<SaleDeviceProps> = ({ disabled, saleClientId, type }) => {
  const [updateState, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);

  const [visible, setVisible] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const ref = useRef<DeviceModalRefProps>(null);
  const [buttonStatus, setButtonStatus] = useState<'error' | 'warning' | ''>('');

  const [data, setData] = useState<SaleMeterDevice[]>([]);

  useEffect(() => {
    if (saleClientId) {
      setLoading(true);
      getSaleMeterDeviceList(saleClientId).then(res => {
        setData(res.list ?? []);
        setLoading(false);
      });
    }
  }, [saleClientId, type, updateState]);

  const deleteRecord = (id: number) => {
    Modal.confirm({
      title: '确认删除？',
      content: (
        <>
          <span style={{ color: '#FF0000' }}>删除后不可恢复，同时会删除历史抄表记录</span>
          <span>，你还要继续吗？</span>
        </>
      ),
      okText: '继续',
      onOk: () => {
        deleteSaleMeterDevice(id).then(() => {
          updateFn();
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right' as const,
            render: (value: undefined, record: SaleMeterDevice) => {
              return (
                <Button
                  type="link"
                  onClick={() => {
                    deleteRecord(record.id);
                  }}
                >
                  删除表计
                </Button>
              );
            },
          },
        ]),
  ];

  const onCancel = () => {
    setButtonStatus('');
    setVisible(false);
  };

  const onOk = () => {
    const devices = ref.current?.getValue();
    const customTags = ref.current?.getMeterTags();
    if ((devices ?? []).length === 0 || (customTags ?? []).length === 0) {
      Modal.warning({
        title: null,
        icon: null,
        content: (devices ?? []).length === 0 ? '请先选择设备' : '请先选择标签',
      });
      setButtonStatus('error');
    } else {
      setBtnLoading(true);
      addSaleMeterDevice({
        saleClientId,
        type,
        devices: devices!,
        saleClientTagNames: customTags!,
      }).then(() => {
        setBtnLoading(false);
        onCancel();
        updateFn();
      });
      setButtonStatus('');
    }
  };

  return (
    <>
      {!disabled && (
        <div style={{ marginBottom: 10 }}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            // style={{ marginBottom: 20 }}
            onClick={() => {
              setVisible(true);
            }}
          >
            添加
          </Button>
        </div>
      )}
      <Table rowKey="id" sticky loading={loading} dataSource={data} columns={buildColumns} />

      <Modal
        size="big"
        title="设备选择"
        destroyOnClose
        open={visible}
        onCancel={onCancel}
        onOk={onOk}
        confirmLoading={btnLoading}
        bodyStyle={{
          maxHeight: 680,
          overflowY: 'auto',
        }}
      >
        <DeviceModal ref={ref} saleClientId={saleClientId} buttonStatus={buttonStatus} />
      </Modal>
    </>
  );
};

export default SaleDevice;
