import { Form, Layout } from 'antd';
import DarkWrapper from '../EnergyUnitKanban/DarkWrapper';
import { CustomFilter, Key, useBreadcrumbRoutes, useSearchParams, useUpdate, DatePicker } from '@maxtropy/components';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import MeterTable from './components/MeterTable';
import dayjs, { Dayjs } from 'dayjs';
import MeterProjectList from './components/MeterProjectList';
import { MeterTaskPageItem, getMeterTaskPage } from '@/api/universeMeterQuery';

const { RangePicker } = DatePicker;
const { Content, Sider } = Layout;

interface SearchParams {
  thisTime?: [Dayjs, Dayjs];
  lastTime?: [Dayjs, Dayjs];
}

const DateDisplay = 'YYYY-MM-DD';

const UniverseMeterQuery: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true);
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [filterForm] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [meterProjectKey, setMeterProjectKey] = useState<Key[]>([]); // 抄表项目 id
  const [meterTaskPageData, setMeterTaskPageData] = useState<MeterTaskPageItem[]>([]); // 抄表任务列表
  const [update, updateFn] = useUpdate();
  const disabledDate = (current: Dayjs) => current.isAfter(dayjs()); // 不能选择未来时间
  const [isHierarchicalMeterReading, setIsHierarchicalMeterReading] = useState<boolean>(false); // 是否多层级抄表对象

  useEffect(() => {
    // 获取抄表任务列表
    if (meterProjectKey.length === 0) return;
    setLoading(true);
    getMeterTaskPage({
      lastStartDate: searchParams.lastTime?.[0].format(DateDisplay),
      lastEndDate: searchParams.lastTime?.[1].format(DateDisplay),
      currentStartDate: searchParams.thisTime?.[0].format(DateDisplay),
      currentEndDate: searchParams.thisTime?.[1].format(DateDisplay),
      projectId: meterProjectKey[0],
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        if (res) {
          setMeterTaskPageData(res.list);
          setTotalCount(res.total);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, meterProjectKey, update]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={filterForm} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="lastTime" label="上期时间范围">
        <RangePicker allowClear={false} style={{ width: '100%' }} disabledDate={v => disabledDate(v)} />
      </Form.Item>
      <Form.Item name="thisTime" label="本期时间范围">
        <RangePicker allowClear={false} style={{ width: '100%' }} disabledDate={v => disabledDate(v)} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <DarkWrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Layout className={styles.layout}>
        <Sider
          className={styles.siderDemo}
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <MeterProjectList
            onTreeSelect={v => {
              setMeterProjectKey(v);
            }}
            setIsHierarchicalMeterReading={setIsHierarchicalMeterReading}
          />
        </Sider>
        <Content className={styles.content} style={{ marginLeft: openSider ? 10 : 0 }}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <MeterTable
            isHierarchicalMeterReading={isHierarchicalMeterReading}
            updateFn={updateFn}
            dataSource={meterTaskPageData}
            loading={loading}
            pagingInfo={pagingInfo}
          />
        </Content>
      </Layout>
    </DarkWrapper>
  );
};

export default UniverseMeterQuery;
