import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  FormTitle,
  Form,
  SubContent,
  Modal,
  ShowInput,
} from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from 'antd';
import styles from './index.module.scss';
import {
  ReportStrategyStatisticalCycleEnum,
  ReportStrategyStatisticalCycleEnumEnumDisplay,
  ReportStrategyStatisticalTypeEnum,
  ReportStrategyStatisticalTypeEnumDisplay,
} from '@/shared/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import SelectDevicesDataProperty from '../components/SelectDevicesDataProperty';
import dayjs from 'dayjs';

import ReportStrategyPreview from '../components/ReportStrategyPreview';
import { FormValue } from '../utils';
import ExecuteTimePicker, { ReportStrategyRes } from '../Create/ExecuteTimePicker';
import StatisticsTimePicker from '../Create/StatisticsTimePicker';
import {
  ReportStrategyExecuteCycle,
  reportStrategyExecuteCycleDisPlay,
  ReportStrategyType,
  ReportStrategyValues,
  reportStrategyValuesDisplay,
} from '../Create';
import qs from 'qs';
import { isNil } from 'lodash-es';
import { apiV2ReportStrategyDetailPost, V2ReportStrategyDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
import { ReportStrategyDataPropertyList } from '@/api/reportStrategy';
import { OuListResponse } from '@/api/ou';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const DetailReportStrategy: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [data, setData] = useState<V2ReportStrategyDetailPostResponse>();

  const { search } = useLocation();
  const { type = '0' }: { type?: string } = qs.parse(search, { ignoreQueryPrefix: true });
  const strategyType: ReportStrategyType = +type;
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      apiV2ReportStrategyDetailPost({ id }).then(res => {
        setData(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        remark: data?.remark,
        measurementType:
          ReportStrategyStatisticalTypeEnumDisplay[data.measurementType as ReportStrategyStatisticalTypeEnum],
        ous: data.ous,
        executionTime: data.executionTime,
        statisticalTime: [getHourMoment(data.statisticalBeginTime!), getHourMoment(data.statisticalEndTime!)],
        statisticalResolution:
          ReportStrategyStatisticalCycleEnumEnumDisplay[
            data?.statisticalResolution as ReportStrategyStatisticalCycleEnum
          ],
        dataPropertyLinkList: data?.dataPropertyLinkList,
        time:
          data.statisticalResolution === ReportStrategyStatisticalCycleEnum.DAY
            ? [
                dayjs.unix(data.statisticalBeginTime?.time!).format('YYYY-MM-DD'),
                dayjs.unix(data.statisticalEndTime?.time!).format('YYYY-MM-DD'),
              ].join(' ～ ')
            : [
                dayjs.unix(data.statisticalBeginTime?.time!).format('YYYY-MM-DD HH:mm'),
                dayjs.unix(data.statisticalEndTime?.time!).format('YYYY-MM-DD HH:mm'),
              ].join(' ～ '),
        decimalPlaces: data.decimalPlaces,
      });
    }
  }, [data, form]);

  const getHourMoment = (time: ReportStrategyRes) => {
    if (!isNil(time?.hour)) {
      return {
        ...time,
        hour: dayjs()
          .hour(time?.hour ?? 0)
          .minute(time?.minute ?? 0),
      };
    }
    return time;
  };

  const routes = useMemo(() => {
    return [{ name: `查看策略` }];
  }, []);

  const goList = () => {
    navigate(`/data/report/strategy?type=${strategyType}`);
  };

  const [previewData, setPreviewData] = useState<FormValue>();

  const onPreview = () => {
    if (data) {
      const formatData: FormValue = {
        name: data?.name!,
        remark: data?.remark,
        measurementType: data.measurementType!,
        ous: data.ous,
        reportType: data.reportType,
        statisticalResolution: data?.statisticalResolution!,
        dataPropertyLinkList: data?.dataPropertyLinkList! as ReportStrategyDataPropertyList[],
        time: [dayjs(data.beginTime), dayjs(data.endTime)],
      };
      setPreviewData(formatData);
    }
  };

  const closePreviewModal = () => {
    setPreviewData(undefined);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent title="基础信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item name="name" label="策略名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="measurementType" label="统计类型">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="运营单元">
                <ShowInput value={data?.ous?.map(item => item.name).join(',')} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="decimalPlaces" label="报表数据小数点后保留位数">
                <ShowInput addonAfter="位" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="remark" label="备注">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="统计对象" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="dataPropertyLinkList" wrapperCol={{ span: 24 }}>
                <SelectDevicesDataProperty
                  ous={data?.ous as OuListResponse[]}
                  statisticalType={data?.measurementType}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        {data && (
          <SubContent title="统计周期" className="mb-8">
            <Row>
              {strategyType === ReportStrategyType.AUTOMATIC && (
                <Col span={24}>
                  <Form.Item name="reportType" wrapperCol={{ span: 24 }} label="执行周期">
                    {reportStrategyExecuteCycleDisPlay[data.reportType! as ReportStrategyExecuteCycle]}
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item name="statisticalResolution" wrapperCol={{ span: 24 }} label="数据颗粒度">
                  {
                    ReportStrategyStatisticalCycleEnumEnumDisplay[
                      data.statisticalResolution as ReportStrategyStatisticalCycleEnum
                    ]
                  }
                </Form.Item>
              </Col>
              {strategyType === ReportStrategyType.ONETIME && (
                <Col span={24}>
                  <Form.Item name="time" wrapperCol={{ span: 24 }} label="统计时间">
                    <ShowInput />
                  </Form.Item>
                </Col>
              )}
              {strategyType === ReportStrategyType.AUTOMATIC && (
                <>
                  <Col span={24}>
                    <Form.Item name="executionTime" wrapperCol={{ span: 24 }} label="执行时间">
                      <ExecuteTimePicker executeCycle={data.reportType!} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={styles.reset_form_style}>
                    <Form.Item name="statisticalTime" wrapperCol={{ span: 24 }} label="统计时间">
                      <StatisticsTimePicker
                        executeCycle={data.reportType!}
                        statisticalCycle={data.statisticalResolution!}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </SubContent>
        )}
        {data?.computeType && (
          <SubContent title="取值方法">
            <Row>
              <Col span={24}>
                <Form.Item name="computeType" wrapperCol={{ span: 24 }} label="瞬时量取值">
                  {reportStrategyValuesDisplay[data.computeType as ReportStrategyValues]}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        )}

        <Space className="sticky-footer" size={8} style={{ zIndex: 99 }}>
          <Button type="primary" onClick={onPreview}>
            预览
          </Button>
          <Button className={styles.button} onClick={goList}>
            取消
          </Button>
        </Space>
        <Modal
          title="报表预览"
          footer={null}
          destroyOnClose
          open={!!previewData}
          size="big"
          onOk={closePreviewModal}
          onCancel={closePreviewModal}
        >
          <ReportStrategyPreview value={previewData} />
        </Modal>
      </Form>
    </Wrapper>
  );
};

export default DetailReportStrategy;
