import { Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Input, Empty, Key, getRealUrl, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { StationTreeItem, WorkCenterTreeItem } from '@/api/energyUnitMapping';
import ProductionBaseIcon from '../../assets/productionBase.png';
import workCenterIcon from '../../assets/workCenter.png';
import workOrderIcon from '../../assets/productionProcesse.png';
import workStationIcon from '../../assets/workStation.png';
import { apiV2ProductionBaseCompleteTreePost } from '@maxtropy/device-customer-apis-v2';
import { isEmpty, isNumber } from 'lodash-es';
interface Props {
  onTreeSelect: (v: Key[], selectedNodes: DataNode[]) => void;
  selectedKeys: Key[];
  setSelectedKeys: (v: Key[]) => void;
  expandedKeys: Key[];
  setExpandedKeys: (v: Key[]) => void;
  treeUpdate: number;
  autoExpandParent: boolean;
  setAutoExpandParent: (v: boolean) => void;
  setProductionBaseId: (v: number) => void;
  setTitleIcon: (v: string) => void;
}

function formatTree(data: any) {
  let tree = [];
  const format = (child: WorkCenterTreeItem[]) => {
    return child.map((i: WorkCenterTreeItem) => {
      return {
        key: `workCenter-${i.id}`,
        title: i.name,
        icon: (
          <img src={getRealUrl(i?.iconKey) ?? workCenterIcon} title="工作中心" alt="" className={styles.iconStyle} />
        ),
        children: (i?.procedureTree ?? []).map(item => {
          return {
            key: `workOrder-${item.workCenterId}-${item.centerProcedureLinkId}-${item.procedureId}`,
            title: item.procedureName,
            icon: (
              <img src={getRealUrl(item?.iconKey) ?? workOrderIcon} title="工序" alt="" className={styles.iconStyle} />
            ),
            children: (item?.stationTree ?? []).map((item: StationTreeItem) => {
              return {
                key: `workStation-${item.workCenterId}-${item.centerProcedureLinkId}-${item.procedureStationLinkId}-${item.stationId}`,
                title: item.stationName,
                icon: (
                  <img
                    src={getRealUrl(item?.iconKey) ?? workStationIcon}
                    title="工站"
                    alt=""
                    className={styles.iconStyle}
                  />
                ),
              };
            }),
          };
        }),
      };
    });
  };
  tree = data?.map((i: any) => ({
    key: `productionBase-${i.id}`,
    title: i.name,
    children: format(i.workCenterTree ?? []),
    icon: (
      <img src={getRealUrl(i?.iconKey) ?? ProductionBaseIcon} title="生产基地" alt="" className={styles.iconStyle} />
    ),
  }));
  return tree;
}

let dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const flattenArray = (data: DataNode[], arr: { key: string; title: string }[] = []) => {
  data.forEach(i => {
    arr.push({ key: i.key as string, title: i.title as string });
    if (i.children) {
      flattenArray(i.children, arr);
    }
  });
  return arr;
};

// 根据节点的Id获取所有父节点
const getPathByNodeId = (data: DataNode[], key: React.Key, path: string[] = []): string[] => {
  for (const node of data) {
    path.push(node.key as string);
    if (node.key === key) return path;
    if (node.children) {
      const findChildren = getPathByNodeId(node.children, key, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};
//获取生产基地id
function findProductionBaseIdByKey(completeTree: any, targetKey: string) {
  const [type, id] = targetKey?.split('-');
  if (type === 'productionBase') {
    return Number(id);
  }

  for (const productionBase of completeTree) {
    if (productionBase.workCenterTree) {
      for (const workCenter of productionBase.workCenterTree) {
        if (workCenter.id === Number(id)) {
          return productionBase.id;
        }
      }
    }
  }

  return null;
}

const StationTree: React.FC<Props> = ({
  onTreeSelect,
  selectedKeys,
  setSelectedKeys,
  expandedKeys,
  setExpandedKeys,
  treeUpdate,
  autoExpandParent,
  setAutoExpandParent,
  setTitleIcon,
  setProductionBaseId,
}) => {
  const [searchParams, setSearchParams] = useState<any>();
  const [data, setData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [prevTree, setPrevTree] = useState<any>();

  useEffect(() => {
    setLoading(true);
    apiV2ProductionBaseCompleteTreePost({})
      .then(res => {
        setPrevTree(res?.list);
        if (res) {
          const dataNodes = formatTree(res?.list);
          setData(dataNodes);
          dataList = [];
          generateList(dataNodes);
          isEmpty(selectedKeys) && setTitleIcon(dataNodes[0]?.icon?.props?.src);

          const allDataNodeKeys = dataList.map(i => i.key);
          setExpandedKeys(allDataNodeKeys);

          // 默认选中第一个
          if (allDataNodeKeys && allDataNodeKeys.length > 0) {
            const productionBaseId = findProductionBaseIdByKey(
              res,
              isNumber(allDataNodeKeys[0]) ? allDataNodeKeys[0].toString() : allDataNodeKeys[0]
            );
            isEmpty(selectedKeys) && setProductionBaseId(productionBaseId);
            isEmpty(selectedKeys) && onTreeSelect([allDataNodeKeys[0]], [dataNodes[0]]);
            setSelectedKeys(isEmpty(selectedKeys) ? [allDataNodeKeys[0]] : selectedKeys);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeUpdate, setExpandedKeys]);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      if (searchParams && searchParams.name) {
        const dataflatArr = flattenArray(data);
        const newExpandedKeys = dataflatArr
          ?.map(item => {
            if (item.title.includes(searchParams.name)) {
              return getPathByNodeId(data, item.key);
            }
            return null;
          })
          .filter(i => !!i);
        newExpandedKeys.forEach(i => i?.pop());
        setExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
      } else {
        setExpandedKeys(dataList.map(i => i.key));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, data]);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (data && data.length && searchParams && searchParams.name) {
      const loop = (data: DataNode[]): DataNode[] =>
        data.map(item => {
          const strTitle = item.title as string;
          const searchName = searchParams ? searchParams.name : undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span style={{ color: 'red' }}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(data);
    } else {
      return data;
    }
  }, [searchParams, data]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.searchArea}>
        <Input.Search
          placeholder="请输入"
          allowClear
          onSearch={(v: string) => {
            setAutoExpandParent(true);
            setSearchParams({ name: v });
          }}
        />
      </div>
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length > 0 ? (
            <Tree
              className={styles.treeStyle}
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={v => {
                setExpandedKeys(v);
                setAutoExpandParent(false);
              }}
              onSelect={(v, info) => {
                console.log(v, info);
                const productionBaseId = findProductionBaseIdByKey(prevTree, v[0] as string);
                const iconElement = info.node.icon as React.ReactElement;
                setTitleIcon(iconElement.props.src);
                setProductionBaseId(productionBaseId);
                onTreeSelect(v, info.selectedNodes);
                setSelectedKeys(v);
              }}
              autoExpandParent={autoExpandParent}
              showIcon
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default StationTree;
