import { PlusOutlined } from '@ant-design/icons';
import { Table, Button, Modal } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';

import DeviceModal from './DeviceModal';
import { AlrmPushDevice } from '../../../../api/deviceAlarmPushStrategy';

import { columns } from './util';

export interface SelectDevicesProps {
  value?: AlrmPushDevice[];
  onChange?: (value: AlrmPushDevice[]) => void;
  ouId?: number;
  disabled?: boolean;
}

const SelectDevices: FC<SelectDevicesProps> = ({ value, onChange, ouId, disabled = false }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<AlrmPushDevice[]>([]);

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.deviceId);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (ouId === undefined) {
      Modal.warning({
        title: '请先选择运营单元',
      });
      return;
    }
    setVisible(true);
  };

  const unBind = (record: AlrmPushDevice) => {
    Modal.confirm({
      title: '取消绑定？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.deviceId !== record.deviceId);
        onChange?.(newValue);
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: AlrmPushDevice) => {
        return (
          <Button
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      {!disabled && (
        <Space direction="vertical">
          <Button type="primary" icon={<PlusOutlined />} wrapStyle={{ marginBottom: 10 }} onClick={onAdd}>
            添加
          </Button>
          <div />
        </Space>
      )}
      <Table
        sticky
        scroll={{ x: 1300, y: 400 }}
        columns={disabled ? columns : buildColumns}
        rowKey="deviceId"
        dataSource={value ?? []}
      />
      <Modal
        className={styles.modal}
        title="设备选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <DeviceModal ouId={ouId?.toString()} onChange={setDataSource} value={dataSource} disabledIds={disabledIds} />
      </Modal>
    </>
  );
};

export default SelectDevices;
