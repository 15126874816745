import { useState } from 'react';
import { Form, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  useBreadcrumbRoutes,
  Paging,
  CustomFilter,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import {
  apiV2CarbonFootprintInputDeletePost,
  apiV2CarbonFootprintInputPagePost,
  V2CarbonFootprintInputPagePostResponse,
  V2CarbonFootprintInputPagePostRequest,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import UploadImport from '@/components/UploadImport';
import ImportLog from '@/components/ImportLog';
import DetailModal from './DetailModal';
import { LogType } from '@/components/ImportLog/Prop';

export type SupplierItem = Exclude<V2CarbonFootprintInputPagePostResponse['list'], undefined>[number];

const columns: ColumnsType<SupplierItem> = [
  {
    title: '订单号',
    dataIndex: 'productSheetCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '产出物编码',
    dataIndex: 'outputProductCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '产出物名称',
    dataIndex: 'outputProductName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料编码',
    dataIndex: 'materialCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料名称',
    dataIndex: 'materialName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '投料数量（单位）',
    dataIndex: 'inputNumber',
    ellipsis: { showTitle: true },
    render: (v: string, record: SupplierItem) => (
      <EllipsisSpan value={`${record?.inputNumber}${record?.materialUnitCode}`} />
    ),
  },
  {
    title: '投料日期',
    dataIndex: 'inputTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD')} />,
  },
  {
    title: '最后更新日期',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<V2CarbonFootprintInputPagePostRequest, 'page' | 'size'> {}

const MaterialFeedingManagement: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const [selectedMaterialId, setSelectedMaterialId] = useState<number>();
  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openImportLogModal, setOpenImportLogModal] = useState<boolean>(false);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasImport = useHasPermission(PermissionsType.B_IMPORTMATERIALFEEDING); // 导入投料单
  const hasImportLog = useHasPermission(PermissionsType.B_IMPORTMATERIALFEEDINGLOG); // 导入日志
  const hasDelete = useHasPermission(PermissionsType.B_DELMATERIALFEEDING); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, loading } = useRequest(
    () =>
      apiV2CarbonFootprintInputPagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2CarbonFootprintInputDeletePost({ id }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setSelectedMaterialId(record.id);
                setOpenDetailModal(true);
              }}
            >
              查看
            </Button>
            {hasDelete && (
              <PopConfirm
                placement="top"
                title="是否删除本行信息，删除后不能恢复"
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} colSpan={8} onReset={onReset}>
      <Form.Item label="投料单" name="codeOrName">
        <Input placeholder="请输入订单号/产出物编号/产出物编号" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasImport && (
          <Button
            type="primary"
            onClick={() => {
              setOpenImportModal(true);
            }}
          >
            导入投料单
          </Button>
        )}
        {hasImportLog && (
          <Button
            type="primary"
            onClick={() => {
              setOpenImportLogModal(true);
            }}
          >
            导入日志
          </Button>
        )}
      </Space>
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
      {openDetailModal && <DetailModal materialId={selectedMaterialId} onClose={() => setOpenDetailModal(false)} />}
      {openImportModal && (
        <UploadImport title="投料单导入" type={LogType.INPUT} setOpen={setOpenImportModal} updateFn={updateFn} />
      )}
      {openImportLogModal && <ImportLog onClose={() => setOpenImportLogModal(false)} type={LogType.INPUT} />}
    </Wrapper>
  );
};

export default MaterialFeedingManagement;
