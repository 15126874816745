import { CircuitDetailProps, CircuitDeviceLinkFormat, CircuitDeviceLinkType } from '@/api/circuit';
import { EllipsisSpan, Table } from '@maxtropy/components';

import React, { FC } from 'react';

interface DeviceListFormProps {
  data?: CircuitDetailProps;
}

export const deviceColumns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类别',
    dataIndex: 'deviceType',
    ellipsis: { showTitle: true },
    render: (v: CircuitDeviceLinkType) => {
      let statusText = '';
      switch (v) {
        case CircuitDeviceLinkType.GAUGE:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.GAUGE];
          break;
        case CircuitDeviceLinkType.LOAD:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.LOAD];
          break;
        case CircuitDeviceLinkType.TRANSFORMER:
          statusText = CircuitDeviceLinkFormat[CircuitDeviceLinkType.TRANSFORMER];
          break;
        default:
          statusText = '--';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
];

const DeviceListForm: FC<DeviceListFormProps> = props => {
  const { data } = props;
  return (
    <Table
      pagination={false}
      rowKey="deviceId"
      sticky
      scroll={{ y: 600 }}
      columns={[...deviceColumns]}
      dataSource={data?.devices ?? []}
    />
  );
};

export default DeviceListForm;
