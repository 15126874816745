import { fetch } from '@maxtropy/components';
import { DeviceStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

export interface DevicePageRequest extends PageRequest {
  nameOrCode?: string;
  deviceType?: Array<number>;
  id?: number;
}

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export interface Device {
  id: number;
  deviceId: number;
  name: string;
  sn: string;
  createTime: string;
  code: string;
  modelName: string;
  typeName: string;
  typeId: number;
  status: DeviceStatus;
}

export interface OUDeviceListRequest extends PageRequest {
  nameOrCode?: string;
  status?: DeviceStatus;
  deviceType?: number[];
  id?: number;
}

export const getOUDevices = (params?: OUDeviceListRequest) =>
  fetch<PageResponse<Device>>(
    `/api/v2/ou/ou-id/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const getDevicePage = (params?: DevicePageRequest) =>
  fetch<PageResponse<Device>>(
    `/api/v2/ou/addDevice/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const removeDevice = (params: { id: number; deviceId: number }) =>
  fetch<void>(
    `/api/v2/ou/removeDevice`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const addDevice = (params: { id: number; deviceIds: number[] }) =>
  fetch<void>(
    `/api/v2/ou/addDevice`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const checkDeviceBind = (params: { id: number; deviceIds: number[] }) =>
  fetch<boolean>(
    `/api/v2/ou/checkDevice`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export const checkPassword = (params: { password: string }) =>
  fetch(`/api/ou/checkPassword`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const getStaffType = (id: number): Promise<{ id: number }> =>
  fetch<{ id: number }>(
    `/api/v2/ou/getStaffType`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
