import { forwardRef } from 'react';
import { FormInstance } from 'antd';
import { isEmpty } from 'lodash-es';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from '@maxtropy/components';
import { V2OeeApplyOeeDevicesPostResponse } from '@maxtropy/device-customer-apis-v2';
import { MonitorIndicatorDisplay } from '../../type';
import styles from '../index.module.scss';

export interface IObjectCompare {
  form: FormInstance<any>;
  deviceData?: V2OeeApplyOeeDevicesPostResponse['list'];
}

export interface DeviceInfo {
  firstLabel: string;
  label: string;
  secondLabel: string;
  value: string;
}

const HistoryCompare = (props: IObjectCompare, ref: any) => {
  const { deviceData, form } = props;
  const groupUidList = Form.useWatch('groupUidList', form);

  return (
    <div className={styles.compareSection}>
      <Form.Item
        name="groupUidList"
        label="选择对象"
        tooltip={{
          title: '最多选择5个对象进行对比',
          icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
        }}
        rules={[{ required: true, message: '请选择对象' }]}
      >
        <Select
          placeholder="请选择对象"
          showSearch
          mode="multiple"
          maxCount={5}
          maxTagCount={1}
          maxTagTextLength={10}
          optionFilterProp="label"
          labelInValue
          options={deviceData?.map(item => ({
            label: `${item.name}-${item.appAnalysisSubscriptionName}`,
            value: item.groupUid,
          }))}
        />
      </Form.Item>
      {!isEmpty(groupUidList) && (
        <div className={styles.deviceList}>
          {groupUidList.map((item: any) => (
            <div className={styles.deviceItem}>
              <Tooltip title={item.label}>
                <div className={styles.label}>{item.label}</div>
              </Tooltip>
              <CloseOutlined
                onClick={() => {
                  form.setFieldsValue({ groupUidList: groupUidList.filter((v: any) => v.value !== item.value) });
                }}
              />
            </div>
          ))}
        </div>
      )}

      <Form.Item name="indicatorId" label="监控指标" rules={[{ required: true, message: '请选择监控指标' }]}>
        <Select
          placeholder="请选择对象"
          showSearch
          optionFilterProp="label"
          options={Object.entries(MonitorIndicatorDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
        />
      </Form.Item>
    </div>
  );
};

export default forwardRef(HistoryCompare);
