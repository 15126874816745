import { InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select, Button, useBreadcrumbRoutes, Wrapper, FormTitle, Radio, SubContent } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import styles from './index.module.scss';
import { Cascader } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2WaterOverviewIndicatorConfPost,
  apiV2WaterOverviewSetTotalIndicatorPost,
  apiV2WaterOverviewTotalConfEntryExitPost,
  apiV2WaterOverviewTotalConfPost,
} from '@maxtropy/device-customer-apis-v2';
import { ConfType, ConfTypeDisplay, ConfigDataTreeIprops, findParentsOfNode, formatOptionData } from './utils';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { getEnergyUnitGroupList } from '@/api/plustekSankey';
const TotalUsageConfInfoTemplate = [{ lastNode: [] }];
const KeyMonitoringConfInfoTemplate = [{ uniqueId: '', indicatorSimpleList: [] }];
interface Iprops {}
const WaterUseConfig: React.FC = (props: Iprops) => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const cacheData = useRef<any>();
  const [pageStatus, setPageStatus] = useState<'view' | 'edit'>('view');
  const [groupOptions, setGroupOptions] = useState<{ value: number; label: string }[]>([]);
  const isEdit = useMemo(() => pageStatus === 'edit', [pageStatus]);

  useEffect(() => {
    getEnergyUnitGroupList().then(res => {
      setGroupOptions((res.list ?? []).map(item => ({ label: item.name, value: item.id })));
    });
  }, []);

  // 总量配置级联选择器数据
  const { data: treeData } = useRequest(() => {
    return apiV2WaterOverviewTotalConfPost({}).then(res => {
      return formatOptionData(res.list as ConfigDataTreeIprops[]);
    });
  }, {});

  // 输入输入配置下拉列表
  const { data: inOrOutList } = useRequest(() => {
    return apiV2WaterOverviewIndicatorConfPost({}).then(res => {
      return res.list?.map(item => {
        return {
          uniqueId: item.importOrExportId + '-' + item.type, // 唯一标识
          ...item,
        };
      });
    });
  }, {});

  // 查询详情
  const {
    data: detailData,
    refresh,
    loading: pageLoading,
  } = useRequest(() => {
    return apiV2WaterOverviewTotalConfEntryExitPost({});
  });
  // 保存api
  const { loading, runAsync: saveApi } = useRequest(apiV2WaterOverviewSetTotalIndicatorPost, { manual: true });
  // 回显
  useEffect(() => {
    if (isNil(detailData)) return;
    let tempTotalUsageConfInfo = detailData?.totalUsageConfInfo?.map((item: any) => {
      let lastNodeId = item.id + '-' + item.type;
      return {
        lastNode: findParentsOfNode(treeData, lastNodeId),
      };
    });
    let tempKeyMonitoringConfInfo = detailData?.keyMonitoringConfInfo?.map((item: any) => {
      let uniqueId = item.importOrExportId + '-' + item.type;
      return {
        uniqueId: findParentsOfNode(treeData, uniqueId),
        indicatorIds: item.indicatorSimpleList?.map((i: any) => i.id),
      };
    });
    const totalUsageConfInfo =
      tempTotalUsageConfInfo && tempTotalUsageConfInfo.length > 0
        ? tempTotalUsageConfInfo
        : [...TotalUsageConfInfoTemplate];
    const keyMonitoringConfInfo =
      tempKeyMonitoringConfInfo && tempKeyMonitoringConfInfo.length > 0
        ? tempKeyMonitoringConfInfo
        : [...KeyMonitoringConfInfoTemplate];
    form.setFieldsValue({
      confType: detailData?.confType,
      energyUnitGroupId: detailData?.waterOverviewUnitConfVo?.energyUnitGroupId,
      totalUsageConfInfo,
      keyMonitoringConfInfo,
    });
    cacheData.current = {
      totalUsageConfInfo,
      keyMonitoringConfInfo,
    };
  }, [treeData, detailData, form]);

  // 保存按钮
  const onSave = () => {
    form.validateFields().then(values => {
      saveApi({
        confType: values.confType,
        unitConfInfo: { energyUnitGroupId: values.energyUnitGroupId },
        totalUsageConfInfo: values.totalUsageConfInfo?.map((item: any) => {
          let tempArr = item.lastNode?.[2]?.split('-');
          return {
            importOrExportId: tempArr?.[0],
            type: tempArr?.[1],
          };
        }),
        keyMonitoringConfInfo: values.keyMonitoringConfInfo?.map((item: any) => {
          let tempArr = item.uniqueId?.[2]?.split('-');
          return {
            importOrExportId: tempArr?.[0],
            type: tempArr?.[1],
            indicatorIds: item.indicatorIds,
          };
        }),
      }).then(_ => {
        setPageStatus('view');
        refresh();
      });
    });
  };
  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []}>
      <Spin spinning={pageLoading}>
        <FormTitle
          title={
            <Space size={12}>
              用水总览配置
              <div className={styles.sub_title}>
                <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                <span className={styles.sub_title_text}>仅显示权限内配置，不在权限内隐藏</span>
              </div>
            </Space>
          }
          extraContent={
            <>
              {pageStatus === 'view' && (
                <Space size={8}>
                  <Button type="primary" onClick={() => setPageStatus('edit')}>
                    编辑
                  </Button>
                  <Button onClick={() => navigate(-1)}>返回</Button>
                </Space>
              )}
              {pageStatus === 'edit' && (
                <Space size={8}>
                  <Button type="primary" loading={loading} onClick={onSave}>
                    保存
                  </Button>
                  <Button
                    onClick={() => {
                      form.setFieldsValue(cacheData.current);
                      setPageStatus('view');
                      refresh();
                    }}
                  >
                    取消
                  </Button>
                </Space>
              )}
            </>
          }
        />
        <Form
          labelAlign="left"
          labelCol={{ style: { width: 160 } }}
          form={form}
          initialValues={{
            totalUsageConfInfo: [{ lastNode: [] }],
            keyMonitoringConfInfo: [{ uniqueId: '', indicatorSimpleList: [] }],
            confType: ConfType.EQUIPMENT,
          }}
        >
          <SubContent title="总量配置">
            <Form.List name="totalUsageConfInfo">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key}>
                      <Space align="baseline" size={10}>
                        <Form.Item
                          className={name === 0 ? '' : styles.label_empty}
                          colon={false}
                          label={name === 0 ? '输入/输出:' : <span></span>}
                          {...restField}
                          name={[name, 'lastNode']}
                          rules={[{ required: true, message: '请选择能源节点计入总用水' }]}
                        >
                          <Cascader
                            disabled={!isEdit}
                            style={{ width: 400 }}
                            options={treeData}
                            showSearch
                            placeholder="请选择能源节点计入总用水"
                          />
                        </Form.Item>

                        {isEdit && (
                          <Space size={15}>
                            {name === fields.length - 1 && fields.length < 10 && (
                              <PlusCircleOutlined style={{ color: 'var(--mx-primary-color)' }} onClick={() => add()} />
                            )}

                            <MinusCircleOutlined
                              style={{ color: 'var(--mx-primary-color)' }}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        )}
                      </Space>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </SubContent>
          <SubContent title="页卡配置">
            <Form.Item name="confType" label="页卡" required>
              <Radio.Group disabled={!isEdit}>
                <Radio value={ConfType.EQUIPMENT}>{ConfTypeDisplay[ConfType.EQUIPMENT]}</Radio>
                <Radio value={ConfType.OVERVUIEW}>{ConfTypeDisplay[ConfType.OVERVUIEW]}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle dependencies={['confType']}>
              {({ getFieldValue }) => {
                const confType = getFieldValue('confType');
                return confType === ConfType.EQUIPMENT ? (
                  <Form.List name="keyMonitoringConfInfo">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key}>
                            <div>
                              <Space align="baseline" size={10}>
                                <Form.Item
                                  label={'输入/节点内/输出'}
                                  {...restField}
                                  name={[name, 'uniqueId']}
                                  rules={[{ required: true }]}
                                >
                                  <Cascader
                                    disabled={!isEdit}
                                    style={{ width: 400 }}
                                    options={treeData}
                                    showSearch
                                    placeholder="请选择输入输出"
                                    onChange={() => {
                                      form.setFieldValue(['keyMonitoringConfInfo', name, 'indicatorIds'], []);
                                    }}
                                  />
                                </Form.Item>

                                {isEdit && (
                                  <Space size={15}>
                                    {name === fields.length - 1 && fields.length < 10 && (
                                      <PlusCircleOutlined
                                        style={{ color: 'var(--mx-primary-color)' }}
                                        onClick={() => add()}
                                      />
                                    )}

                                    <MinusCircleOutlined
                                      style={{ color: 'var(--mx-primary-color)' }}
                                      onClick={() => remove(name)}
                                    />
                                  </Space>
                                )}
                              </Space>
                            </div>
                            <div>
                              <Form.Item noStyle dependencies={[['keyMonitoringConfInfo', name, 'uniqueId']]}>
                                {({ getFieldValue }) => {
                                  let uniqueId = getFieldValue(['keyMonitoringConfInfo', name, 'uniqueId']);
                                  let tempList = inOrOutList?.find(
                                    item => item.uniqueId === uniqueId?.[2]
                                  )?.indicatorSimpleList;

                                  return (
                                    <Form.Item
                                      label={'监控指标'}
                                      {...restField}
                                      name={[name, 'indicatorIds']}
                                      rules={[{ required: true }]}
                                    >
                                      <Select
                                        disabled={!isEdit}
                                        style={{ width: 400 }}
                                        mode="multiple"
                                        placeholder="请选择"
                                        optionFilterProp="label"
                                        showSearch
                                        options={tempList?.map(item => ({ label: item.name, value: item.id }))}
                                      />
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </Form.List>
                ) : (
                  <Form.Item
                    name="energyUnitGroupId"
                    label="用能分析组"
                    rules={[{ required: true, message: '请选择用能分析组' }]}
                  >
                    <Select disabled={!isEdit} placeholder="请选择" style={{ width: 400 }} options={groupOptions} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </SubContent>
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default WaterUseConfig;
