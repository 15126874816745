import React, { useEffect, useMemo, useState } from 'react';
import { Cascader } from 'antd';
import { AreaItem } from '../../../api/electricity';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { getParents } from '../../../utils/utils';
import { formatTreeData } from '../utils';
import { ThermalRequest, ThermalResponse } from '../../../api/thermal';
import type { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { getUetList, UetInfoProps } from '../../../api/uet';
import { Form, Input, Modal, Select, TreeSelect } from '@maxtropy/components';
import { apiV2LinePipeListPost, V2LinePipeListPostResponse } from '@maxtropy/device-customer-apis-v2';

export interface CreateFormProps {
  current?: Partial<ThermalResponse>;
  districtData?: AreaItem[];
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: FormProps) => void;
}

export interface FormProps extends Omit<ThermalRequest, 'regionCode' | 'customerMcids'> {
  regionCode: string[];
  customerMcids: DefaultOptionType[];
}

const CreateForm: React.FC<CreateFormProps> = ({ current, districtData, visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const [organization, setOrganition] = useState<OrganizationResponse>();

  const [uets, setUets] = useState<Array<UetInfoProps>>([]);

  const [customerMcids, setCustomerMcids] = useState<Array<string>>([]);

  const [pipes, setPipes] = useState<V2LinePipeListPostResponse['list']>([]);

  const uetId: number | undefined = Form.useWatch('uetId', form);

  useEffect(() => {
    if (current && current.id && districtData && current.regionCode) {
      const codes = getParents(districtData, current.regionCode).map(item => item.adcode);
      setCustomerMcids(current.customerMcids ?? []);
      form.setFieldsValue({
        number: current.number,
        customerMcids: (current.customerMcids ?? []).map(i => ({ value: i })),
        regionCode: codes,
        uetId: current.uetId,
        linePipeId: current.linePipeId,
      });
    } else if (current === undefined) {
      form.resetFields();
    }
  }, [current, districtData, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(customerMcids) && customerMcids.length) {
      getUetList(customerMcids).then(setUets);
    }
  }, [customerMcids, form]);

  useEffect(() => {
    if (uetId) {
      apiV2LinePipeListPost({ id: uetId }).then(res => setPipes(res.list));
    }
  }, [uetId]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onOK = async () => {
    const values: FormProps = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      width={600}
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      onOk={onOK}
      title={`${(current?.id ?? undefined) !== undefined ? '编辑' : '新增'}用汽账户`}
    >
      <Form form={form}>
        <Form.Item
          validateFirst
          name="number"
          label="用汽账户户号"
          rules={[
            { required: true, message: '请输入' },
            { pattern: /^[^\u4E00-\u9FFF]*$/g, message: '不支持输入中文' },
          ]}
        >
          <Input maxLength={20} placeholder="请输入" />
        </Form.Item>
        <Form.Item name="customerMcids" label="关联组织" rules={[{ required: true, message: '请选择' }]}>
          <TreeSelect
            treeDefaultExpandAll
            treeCheckable
            treeCheckStrictly
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            showArrow
            showSearch={false}
            treeData={treeData}
            onChange={(value: DefaultOptionType[]) => {
              setCustomerMcids((value ?? []).map(i => i.value as string));
              form.setFieldsValue({
                uetId: undefined,
                linePipeId: undefined,
              });
            }}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item name="regionCode" label="所在区域" rules={[{ required: true, message: '请输入' }]}>
          <Cascader
            options={districtData}
            fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item name="uetId" label="全域能源拓扑" rules={[{ required: true, message: '请选择' }]}>
          <Select
            placeholder="请选择"
            onChange={() => {
              form.setFieldsValue({
                linePipeId: undefined,
              });
            }}
            options={(uets ?? []).map(i => ({ value: i.id, label: i.name }))}
          />
        </Form.Item>
        <Form.Item name="linePipeId" label="管线选择" rules={[{ required: true, message: '请选择' }]}>
          <Select
            placeholder="请选择"
            options={(pipes ?? []).map(i => ({
              value: i.id,
              label: i.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
