import { getRealUrl, useCurrent } from '@maxtropy/components';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import defaultHomeImg from '@/assets/images/defaultHome.jpg';

const Home: React.FC = () => {
  const [homePageLogoUrl, setHomePageLogoUrl] = useState<string>();
  const current = useCurrent();

  useEffect(() => {
    if (current?.staff) {
      setHomePageLogoUrl(current.staff.homePageLogoUrl);
    }
  }, [current]);

  return (
    <div className={styles.img}>
      <img
        className={styles.home}
        src={!!homePageLogoUrl ? getRealUrl(homePageLogoUrl) : defaultHomeImg}
        alt="首页图片"
      />
    </div>
  );
};

export default Home;
