import {
  AreaType,
  BtnConfigActionType,
  MajorShowBtnConfigListRes,
  UpdatePortalBtnReq,
} from '@/api/energyAssetsPortalConfig';
import { Form, Input, Modal, Button } from '@maxtropy/components';
import { FC, useEffect, useImperativeHandle } from 'react';

interface IEditBtnModalForm {
  handleEditBtn: (v: UpdatePortalBtnReq | undefined) => void;
  row?: MajorShowBtnConfigListRes;
  addBtnTypeModal: BtnConfigActionType | undefined;
  cref?: React.MutableRefObject<IFieldForm | undefined>;
}

export interface IFieldForm {
  reset: () => void;
}

const { TextArea } = Input;

const EditBtnModalForm: FC<IEditBtnModalForm> = props => {
  const { row, handleEditBtn, addBtnTypeModal } = props;
  const [editform] = Form.useForm();

  useImperativeHandle(props.cref, () => ({
    // 重载数据
    reset: () => {
      editform.resetFields();
    },
  }));

  useEffect(() => {
    if (row && row?.id) {
      editform.setFieldsValue({
        buttonName: row.buttonName,
        url: row.url,
        remark: row.remark,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const onFinish = (val: UpdatePortalBtnReq) => {
    if (addBtnTypeModal === BtnConfigActionType.ADDMAJOR && !row) {
      handleEditBtn({ ...val, type: AreaType.MajorArea });
    } else if (addBtnTypeModal === BtnConfigActionType.ADDPV && !row) {
      handleEditBtn({ ...val, type: AreaType.pvStation });
    } else if (addBtnTypeModal === BtnConfigActionType.ADDBSA && !row) {
      handleEditBtn({ ...val, type: AreaType.bsaStation });
    } else if (addBtnTypeModal === BtnConfigActionType.ADDEV && !row) {
      handleEditBtn({ ...val, type: AreaType.evStation });
    } else if (addBtnTypeModal === BtnConfigActionType.ADDLEANEM && !row) {
      handleEditBtn({ ...val, type: AreaType.leanEMStation });
    } else if (addBtnTypeModal === BtnConfigActionType.ADDBASICEM && !row) {
      handleEditBtn({ ...val, type: AreaType.basicEMStation });
    } else {
      handleEditBtn({ ...val, type: row?.type!, id: row?.id! });
    }
  };

  return (
    <Modal
      destroyOnClose
      open={!!addBtnTypeModal}
      onCancel={() => handleEditBtn(undefined)}
      contentClassName="modal-form-content"
      footer={[
        <Button
          key="back"
          style={{ marginRight: 12 }}
          onClick={() => {
            handleEditBtn(undefined);
            editform.resetFields();
          }}
        >
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            editform.submit();
          }}
        >
          确认
        </Button>,
      ]}
      title={`按钮配置`}
    >
      <Form form={editform} onFinish={(val: any) => onFinish(val)}>
        <Form.Item name="buttonName" label="按钮名称" rules={[{ required: true, message: '请选择' }, { max: 6 }]}>
          <Input style={{ width: '100%' }} placeholder="最多六个字符" />
        </Form.Item>
        <Form.Item validateFirst name="url" label="URL" rules={[{ required: true, message: '请输入' }]}>
          <TextArea style={{ width: '100%' }} placeholder="请输入" />
        </Form.Item>
        <Form.Item name="remark" label="备注" rules={[{ required: true, message: '请选择' }, { max: 20 }]}>
          <TextArea style={{ width: '100%' }} placeholder="最多二十个字符" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditBtnModalForm;
