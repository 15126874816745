import React, { useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  FormTitle,
  SubContent,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import VideoPlayer from './components/VideoPlayer';
import { apiV2SupervisoryAccountDeviceDetailPost } from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { AccessMode, AccessModeDisplay } from './type';
import { Spin } from 'antd';

const routes = [{ name: '视频监控详情' }];

const IFRAMEHOST = window.location.origin;

const VideoDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const player = useRef<any>();
  const [videoLoading, setVideoLoading] = useState<boolean>(true);

  const { data: monitorDeviceDetail, loading } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountDeviceDetailPost({
        id: Number(id),
      });
      form.setFieldsValue(res);
      return res;
    },
    {
      ready: !!id,
      refreshDeps: [id, form],
    }
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} filters={null} className="page_wrapper">
      <FormTitle title={`${monitorDeviceDetail?.supervisoryDeviceName ?? ''}视频监控详情`}></FormTitle>
      <Spin spinning={loading}>
        <SubContent>
          <Form form={form} style={{ marginBottom: 24 }}>
            <BaseInfoContent layout="horizontal">
              <Form.Item name="supervisoryTargetName" label="监控目标">
                <ShowInput />
              </Form.Item>
              <Form.Item name="weighting" label="展示权重">
                <ShowInput />
              </Form.Item>
              <Form.Item name="accountName" label="监控账户名称">
                <ShowInput />
              </Form.Item>
              <Form.Item name="accessMode" label="接入方式">
                <ShowInput
                  value={
                    monitorDeviceDetail?.accessMode && AccessModeDisplay[monitorDeviceDetail.accessMode as AccessMode]
                  }
                />
              </Form.Item>
              {monitorDeviceDetail?.accessMode === AccessMode.HK && (
                <Form.Item name="ipAddress" label="IP地址">
                  <ShowInput />
                </Form.Item>
              )}
              {monitorDeviceDetail?.accessMode === AccessMode.YSY && (
                <Form.Item name="supervisoryUrl" label="监控地址(URL)-预览地址">
                  <ShowInput />
                </Form.Item>
              )}
            </BaseInfoContent>
          </Form>
          {monitorDeviceDetail?.accessMode === AccessMode.HK && (
            <Spin spinning={videoLoading}>
              <iframe
                src={`${IFRAMEHOST}/monitor/video/hkPlayer`}
                width={800}
                height={620}
                id={`hk_video_box`}
                frameBorder="0"
                onLoad={() => {
                  setVideoLoading(false);
                  const hkVideoFrame = document.getElementById('hk_video_box') as HTMLIFrameElement;
                  hkVideoFrame?.contentWindow?.postMessage(
                    {
                      domId: `hk_video_box`,
                      width: 800,
                      height: 450,
                      monitorLocation: monitorDeviceDetail.monitorLocation!,
                      cameraUsername: monitorDeviceDetail.cameraUsername!,
                      cameraPassword: monitorDeviceDetail.cameraPassword!,
                      ipAddress: monitorDeviceDetail.ipAddress!,
                      port: monitorDeviceDetail.port!,
                      cameraPort: monitorDeviceDetail.cameraPort!,
                      rtspPort: monitorDeviceDetail.rtspPort!,
                      enableFeatureList: monitorDeviceDetail.enableFeatureList!,
                      showTools: true,
                    },
                    '*'
                  );
                  if (hkVideoFrame.contentDocument) {
                    hkVideoFrame.contentDocument.body.style.minWidth = 'auto';
                  }
                }}
              />
            </Spin>
          )}
          {monitorDeviceDetail?.accessMode === AccessMode.YSY && (
            <VideoPlayer
              width={800}
              getPlayer={(ins: any) => {
                player.current = ins;
              }}
              domId={`video_detail_box`}
              leftText=""
              rightText=""
              showTools={true}
              showFooter={false}
              videoToken={monitorDeviceDetail?.token!}
              videoUrl={monitorDeviceDetail?.supervisoryUrl!}
            />
          )}

          <div className={styles.button_area}>
            <Button onClick={() => navigate(`/monitor/video/list${search}`)}>返回</Button>
          </div>
        </SubContent>
      </Spin>
    </Wrapper>
  );
};

export default VideoDetail;
