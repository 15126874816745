import { OuInfoProps } from '@/api/ou';
import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { BackUpPowerTypeLabel } from '@/pages/UET/Edit/BackUpPower/components/backUpPowerModalTypes';
import { List } from '@/pages/UET/Edit/BackUpPower/types';
import {
  apiV2CircuitListPost,
  apiV2UetBackupPowerCreatePost,
  apiV2UetBackupPowerUpdatePost,
  apiV2UetPowerSupplyDeviceUpsDieselGeneratorListPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

const formLayout = {
  labelCol: { flex: '120px' },
};

interface Props {
  modalVisible?: boolean;
  currentRowInfo?: List;
  ouList?: OuInfoProps[];
  setModalVisible: (v: boolean) => void;
  setCurrentRowInfo: (v: undefined) => void;
  forceUpdate: () => void;
}
const BackUpPowerModal: React.FC<Props> = ({
  modalVisible,
  currentRowInfo,
  ouList,
  setModalVisible,
  setCurrentRowInfo,
  forceUpdate,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>(); // 获取uetid

  const ouId = Form.useWatch('ouId', form);
  const type = Form.useWatch('type', form);

  const onOk = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        uetId: +id!,
      };

      const apiMethod = currentRowInfo ? apiV2UetBackupPowerUpdatePost : apiV2UetBackupPowerCreatePost;

      apiMethod(params).then(() => {
        form.resetFields();
        setModalVisible(false);
        setCurrentRowInfo(undefined);
        forceUpdate();
      });
    });
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setCurrentRowInfo(undefined);
  };

  // 获取回路列表
  const { data: circuitOptions = [] } = useRequest(
    async () => {
      if (modalVisible && !isNil(ouId)) {
        const res = await apiV2CircuitListPost({ ouId });
        if (res?.list?.length) {
          return res?.list?.map(item => ({
            value: item.id,
            label: item.name,
          }));
        }
      }
    },
    {
      refreshDeps: [modalVisible, ouId],
    }
  );

  // 关联设备list
  const { data: deviceOptions = [] } = useRequest(
    async () => {
      if (modalVisible && !isNil(ouId) && !isNil(type)) {
        const res = await apiV2UetPowerSupplyDeviceUpsDieselGeneratorListPost({ ouIds: [ouId], type });
        if (res?.list?.length) {
          return res?.list?.map(item => ({
            value: item.id,
            label: item.name,
          }));
        }
      }
    },
    {
      refreshDeps: [modalVisible, id, ouId, type],
    }
  );

  const title = useMemo(() => {
    return currentRowInfo ? '编辑电源' : '新建电源';
  }, [currentRowInfo]);

  useEffect(() => {
    if (currentRowInfo && modalVisible) {
      form.setFieldsValue({
        ...currentRowInfo,
        deviceId: currentRowInfo.relatedDeviceId,
      });
    }
  }, [currentRowInfo, form, modalVisible]);

  return (
    <>
      <Modal open={modalVisible} title={title} onCancel={onCancel} onOk={onOk} destroyOnClose>
        <Form form={form} {...formLayout} style={{ marginTop: 24 }}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select disabled={!!currentRowInfo} placeholder="请选择" allowClear>
              {(ouList ?? []).map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="电源名称" name="name" rules={[{ required: true, message: '请输入电源名称' }]}>
            <Input placeholder="请输入" allowClear />
          </Form.Item>

          <Form.Item label="电源类型" name="type" rules={[{ required: true, message: '请选择电源类型' }]}>
            <Select
              options={Object.entries(BackUpPowerTypeLabel).map(([key, value]) => ({
                value: +key,
                label: value,
              }))}
              placeholder="请选择"
              allowClear
            />
          </Form.Item>

          <Form.Item label="关联回路" name="circuitId">
            <Select placeholder="请选择" options={circuitOptions} allowClear />
          </Form.Item>

          <Form.Item label="关联设备" name="deviceId">
            <Select placeholder="请选择" options={deviceOptions} allowClear />
          </Form.Item>

          <Form.Item noStyle name="id" />
        </Form>
      </Modal>
    </>
  );
};

export default BackUpPowerModal;
