import { OuInfoProps } from '@/api/ou';
import { LoadTypeLabel } from '@/pages/UET/Edit/Load/components/loadModalTypes';

import React, { useEffect, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';
import {
  apiV2CircuitListPost,
  apiV2UetCreateLoadPost,
  apiV2UetUpdateLoadPost,
} from '@maxtropy/device-customer-apis-v2';
import { List } from '@/pages/UET/Edit/Load/types';
import { isNil } from 'lodash-es';
import { Form, Input, Modal, Select } from '@maxtropy/components';

const formLayout = {
  labelCol: { flex: '120px' },
};

interface Props {
  modalVisible?: boolean;
  currentRowInfo?: List;
  ouList?: OuInfoProps[];
  setModalVisible: (v: boolean) => void;
  setCurrentRowInfo: (v: undefined) => void;
  forceUpdate: () => void;
}
const LoadModal: React.FC<Props> = ({
  modalVisible,
  currentRowInfo,
  ouList,
  setModalVisible,
  setCurrentRowInfo,
  forceUpdate,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>(); // 获取uetid

  const ouId = Form.useWatch('ouId', form);

  const onOk = () => {
    form
      .validateFields()
      .then(values => {
        const params = {
          ...values,
          uetId: +id!,
        };

        const apiMethod = currentRowInfo ? apiV2UetUpdateLoadPost : apiV2UetCreateLoadPost;

        apiMethod(params).then(() => {
          form.resetFields();
          setModalVisible(false);
          setCurrentRowInfo(undefined);
          forceUpdate();
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setCurrentRowInfo(undefined);
  };

  // 获取回路列表
  const { data: circuitOptions = [] } = useRequest(
    async () => {
      if (modalVisible && !isNil(ouId)) {
        const res = await apiV2CircuitListPost({ ouId });
        if (res?.list?.length) {
          return res?.list?.map(item => ({
            value: item.id,
            label: item.name,
          }));
        }
      }
    },
    {
      refreshDeps: [modalVisible, ouId],
    }
  );

  const title = useMemo(() => {
    return currentRowInfo ? '编辑负载' : '新建负载';
  }, [currentRowInfo]);

  useEffect(() => {
    if (currentRowInfo && modalVisible) {
      form.setFieldsValue(currentRowInfo);
    }
  }, [currentRowInfo, form, modalVisible]);

  return (
    <>
      <Modal open={modalVisible} title={title} onCancel={onCancel} onOk={onOk} destroyOnClose>
        <Form form={form} {...formLayout} style={{ marginTop: 24 }}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select disabled={!!currentRowInfo} placeholder="请选择" allowClear>
              {(ouList ?? []).map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="负载名称" name="name" rules={[{ required: true, min: 2, max: 30 }]}>
            <Input placeholder="请输入" allowClear />
          </Form.Item>

          <Form.Item label="负载类型" name="type" rules={[{ required: true, message: '请选择负载类型' }]}>
            <Select
              placeholder="请选择"
              options={Object.entries(LoadTypeLabel).map(([key, value]) => ({
                value: +key,
                label: value,
              }))}
              allowClear
            />
          </Form.Item>

          <Form.Item label="关联回路" name="circuitId">
            <Select placeholder="请选择" options={circuitOptions} allowClear />
          </Form.Item>

          <Form.Item name="id" noStyle />
        </Form>
      </Modal>
    </>
  );
};

export default LoadModal;
