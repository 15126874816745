import {
  Form,
  Table,
  Input,
  Paging,
  usePaging,
  useAsync,
  CustomFilter,
  EllipsisSpan,
  Modal,
  message,
} from '@maxtropy/components';
import { Cascader } from 'antd';
import { FC, useEffect, useState } from 'react';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { getDeviceTypeTree } from '@/api/deviceType';
import {
  V2DeviceDevicePageByAppAnalysisSubscriptionPostRequest,
  apiV2DeviceDevicePageByAppAnalysisSubscriptionPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import { LineDeviceItem } from '../../utils';

export type DeviceItem = {
  id: number;
  name: string;
  code: string;
  typeName: string;
};

export interface SelectDeviceProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onChange?: (value: DeviceItem[]) => void;
  appAnalysisSubId?: string;
  prevRows?: LineDeviceItem[];
}

type SearchParams = Omit<V2DeviceDevicePageByAppAnalysisSubscriptionPostRequest, 'size' | 'page' | 'appAnalysisSubId'>;

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface FilterParams {
  name: string | undefined;
  deviceTypes: Array<Array<number>> | undefined;
}

const SelectDevice: FC<SelectDeviceProps> = ({ open, onChange, setOpen, appAnalysisSubId, prevRows }) => {
  const [form] = Form.useForm();

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<DeviceItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<DeviceItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isNil(appAnalysisSubId)) {
      setLoading(true);
      apiV2DeviceDevicePageByAppAnalysisSubscriptionPost({
        ...searchParams,
        appAnalysisSubId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total ?? 0);
          setDevices(
            (res?.list ?? []).map(i => ({
              id: i.id!,
              code: i.code!,
              name: i.name!,
              typeName: i.typeName!,
            }))
          );
        }
      });
    }
  }, [appAnalysisSubId, pageOffset, pageSize, searchParams, setTotalCount]);

  useEffect(() => {
    setSelectedRowKeys((prevRows ?? []).map(k => k.id!));
  }, []);

  const rowSelection = {
    hideSelectAll: true,
    preserveSelectedRowKeys: true,
    onChange: (_: any, selectedRows: DeviceItem[]) => {
      setSelectedRows([...selectedRows]);
    },
    onSelect: (record: any, selected: boolean) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(i => i !== record.id));
      }
    },
    selectedRowKeys,
  };

  const onFinish = (value: FilterParams) => {
    const deviceTypes = getChildNodesByParentIds(value.deviceTypes, deviceTypeData?.tree);
    setSearchParams({
      ...value,
      typeId: deviceTypes,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      deviceTypes: undefined,
    });
    setPageOffset(1);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter
      colSpan={8}
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      onReset={onReset}
    >
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" allowClear />
      </Form.Item>
      <Form.Item name="deviceTypes" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Modal
      title="设备选择"
      destroyOnClose
      open={open}
      onOk={() => {
        if (selectedRows.length > 10) {
          message.warning('产线设备最多选择10个');
          return;
        }
        onChange?.(selectedRows);
        setOpen?.(false);
      }}
      onCancel={() => setOpen?.(false)}
      size="big"
      bodyScroll
    >
      {filters}
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 400 }}
        rowKey="id"
        columns={columns}
        dataSource={devices}
        showSelectedCount
        selectedCount={(selectedRowKeys ?? []).length}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};

export default SelectDevice;
