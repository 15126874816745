import EZUIKit from 'ezuikit-js';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useSize } from 'ahooks';
import styles from '../index.module.scss';
import { themeDataShow, themeDataHide } from './themeData';
interface Iprops {
  domId: string;
  videoUrl: string;
  videoToken: string;
  width?: number | string;
  leftText?: React.ReactNode;
  rightText?: React.ReactNode;
  showTools?: boolean;
  showFooter?: boolean;
  getPlayer?: (player: any) => void;
}

export default forwardRef(function VideoPlayer(props: Iprops) {
  const { videoToken, videoUrl, width = 600, domId, leftText, rightText, showTools, showFooter = true } = props;
  const playerBoxRef = useRef<HTMLDivElement>(null);
  const size = useSize(playerBoxRef);
  const player = useRef<any>();
  const [isInViewport, setIsInViewport] = useState(true); // 元素是否在视口中

  useEffect(() => {
    if (!size?.width || !videoToken || !videoUrl) return;

    // 比列 600 : 400
    let domH = parseInt(String((size.width / 600) * 400));
    player.current = new EZUIKit.EZUIKitPlayer({
      id: domId,
      autoplay: true,
      url: videoUrl,
      accessToken: videoToken,
      // template: template,
      width: size.width,
      height: domH,
      plugins: ['talk'],
      themeData: showTools ? themeDataShow : themeDataHide,
      // enableSharedArrayBufferGuide: true,
      handleError: (err: any) => {
        console.log('err', err);
      },
    });

    return () => {
      player.current?.stop();
      player.current = null;
    };
  }, [domId, videoToken, videoUrl, size?.width, showTools]);

  // 监听元素是否在视口中
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      // intersectionRatio 为 0，则目标在可视区域之外
      if (entries[0].intersectionRatio <= 0) {
        setIsInViewport(false);
      } else {
        setIsInViewport(true);
      }
    });
    intersectionObserver.observe(playerBoxRef.current!);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);
  return (
    <div style={{ width }} ref={playerBoxRef} className={styles.video_box_item}>
      {videoToken && videoUrl ? (
        <div id={domId}></div>
      ) : isInViewport ? (
        <div className={styles.err_block}>加载失败</div>
      ) : (
        <div className={styles.loading_block}>正在加载</div>
      )}
      {showFooter && (
        <div className={styles.footer}>
          <span className={styles.left_text}>{leftText}</span>
          <span className={styles.right_text}>{rightText}</span>
        </div>
      )}
    </div>
  );
});
