import React from 'react';
import { Divider } from 'antd';
import { CarbonEmissionFillingDetailPostResponse } from '@maxtropy/device-customer-apis';
import ProcessTable from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions/ProcessTable';
import styles from './index.module.scss';

interface Props {
  rangeData?: CarbonEmissionFillingDetailPostResponse['guideClassificationList'] | null;
  emissionFillingId?: number;
  update: () => void;
}
const RangeContent: React.FC<Props> = ({ rangeData, emissionFillingId, update }) => {
  return (
    <>
      <div className={styles.contentWrapper}>
        {rangeData?.map(item => {
          return (
            <div key={item.id}>
              <Divider>
                <div className={styles.dividerText}>{item?.classificationName ?? '--'}</div>
              </Divider>

              {item?.guideClassificationProcessDtos?.map((innerItem, index) => {
                return (
                  <>
                    <div key={`innerItem-${item.id}-${innerItem.id}-${index}`}>
                      <ProcessTable
                        key={innerItem.id}
                        data={innerItem}
                        update={update}
                        emissionFillingId={emissionFillingId}
                        classificationProcessId={item.id}
                      />
                    </div>
                    {/* <div key={innerItem.id}>
                      <ProcessItem
                        data={innerItem}
                        update={update}
                        emissionFillingId={emissionFillingId}
                        classificationProcessId={item.id}
                      />
                    </div> */}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RangeContent;
