import {
  Form,
  Radio,
  Button,
  EllipsisSpan,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import {
  V2EnergyOptimizationProjectEnergyMediumDetailPostResponse,
  apiV2EnergyOptimizationProjectEnergyMediumDetailPost,
  apiV2EnergyOptimizationProjectGetPost,
} from '@maxtropy/device-customer-apis-v2';
import { EnergyAnalysisOptimizePageRes } from '../..';

interface Props {}

const getColorSpan = (v: number) => {
  const color = v === 0 || isNil(v) ? undefined : v > 0 ? '#59D744' : '#FF4D4F';
  const num = <span style={{ color }}> {isNil(v) ? '--' : v.toFixed(2)}%</span>;
  return <EllipsisSpan value={num} />;
};

const routes = [{ name: '优化明细' }];

export type EnergyAnalysisOptimizeDetailRes = Exclude<
  V2EnergyOptimizationProjectEnergyMediumDetailPostResponse['list'],
  undefined
>[number];

export type EnergyAnalysisOptimizeDetail = Exclude<EnergyAnalysisOptimizeDetailRes['data'], undefined>[number];

const AnalysisDetail: React.FC<Props> = props => {
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<EnergyAnalysisOptimizePageRes>();
  const [data, setData] = useState<EnergyAnalysisOptimizeDetail[]>([]);

  const [radioValue, setRadioValue] = useState<string>();
  const [unit, setUnit] = useState<string>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [energyAnalysisOptimizeDetail, setEnergyAnalysisOptimizeDetail] = useState<EnergyAnalysisOptimizeDetailRes[]>(
    []
  );

  useEffect(() => {
    if (id) {
      apiV2EnergyOptimizationProjectGetPost({ energyOptimizationProjectId: id }).then(res => {
        if (res && res.energyMediums && res.energyMediums.length > 0) {
          // 默认选中第一个工质
          setRadioValue(res.energyMediums[0].id?.toString());
          setDetail(res);
        }
      });
      // 获取table数据
      apiV2EnergyOptimizationProjectEnergyMediumDetailPost({ energyOptimizationProjectId: id }).then(res => {
        setEnergyAnalysisOptimizeDetail(res.list ?? []);
      });
    }
  }, [id]);

  useEffect(() => {
    if (radioValue && energyAnalysisOptimizeDetail.length > 0) {
      const findData =
        energyAnalysisOptimizeDetail.find(item => Number(item.energyMediumId) === Number(radioValue))?.data ?? [];
      const find = findData.map(i => {
        return {
          ...i,
          energySavingsRate: isNil(i.energySavingsRate) ? i.energySavingsRate : i.energySavingsRate * 100,
          energySavingsRateRecently: isNil(i.energySavingsRateRecently)
            ? i.energySavingsRateRecently
            : i.energySavingsRateRecently * 100,
        };
      });
      setData(find);
      if (findData && findData.length > 0) {
        setUnit(findData[0].defaultPhysicalUnitName);
      }
    }
  }, [radioValue, energyAnalysisOptimizeDetail]);

  const columns: ColumnsType<EnergyAnalysisOptimizeDetail> = useMemo(() => {
    return [
      {
        title: '用能单元',
        dataIndex: 'energyUnitName',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: `优化前日均基准用量 ${isNil(unit) ? '' : unit}`,
        dataIndex: 'dailyAverEnergyBeforeOptimization',
        render: (v: number) => <EllipsisSpan value={isNil(v) ? undefined : v.toFixed(2)} />,
      },
      {
        title: `优化后日均基准用量 ${isNil(unit) ? '' : unit}`,
        dataIndex: 'dailyAverEnergyAfterOptimization',
        render: (v: number) => <EllipsisSpan value={isNil(v) ? undefined : v.toFixed(2)} />,
      },
      {
        title: `近30日日均基准用量 ${isNil(unit) ? '' : unit}`,
        dataIndex: 'dailyAverEnergyRecently',
        render: (v: number) => <EllipsisSpan value={isNil(v) ? undefined : v.toFixed(2)} />,
      },
      {
        title: '节能率',
        dataIndex: 'energySavingsRate',
        render: (v: number) => getColorSpan(v),
        sorter: (a: EnergyAnalysisOptimizeDetail, b: EnergyAnalysisOptimizeDetail) =>
          (a.energySavingsRate as number) - (b.energySavingsRate as number),
      },
      {
        title: '近期节能率',
        dataIndex: 'energySavingsRateRecently',
        render: (v: number) => getColorSpan(v),
        sorter: (a: EnergyAnalysisOptimizeDetail, b: EnergyAnalysisOptimizeDetail) =>
          (a.energySavingsRateRecently as number) - (b.energySavingsRateRecently as number),
      },
      {
        title: '预计月节省费用 (元)',
        dataIndex: 'estimatedMonthlySavings',
        render: (v: number) => <EllipsisSpan value={isNil(v) ? undefined : v.toFixed(2)} />,
        sorter: (a: EnergyAnalysisOptimizeDetail, b: EnergyAnalysisOptimizeDetail) =>
          (a.estimatedMonthlySavings as number) - (b.estimatedMonthlySavings as number),
      },
    ];
  }, [unit]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="优化明细"></FormTitle>

      <SubContent>
        <Form>
          <BaseInfoContent colNum={2} layout="horizontal">
            <Form.Item label="优化项目">
              <ShowInput value={detail?.name} />
            </Form.Item>
            <Form.Item label="优化时间">
              <ShowInput
                value={`${dayjs(detail?.optimizationStartTime).format('YYYY-MM-DD')}至${dayjs(
                  detail?.optimizationEndTime
                ).format('YYYY-MM-DD')}`}
              />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </SubContent>
      <SubContent>
        <Radio.Group
          value={radioValue}
          onChange={e => {
            setRadioValue(e.target.value);
          }}
          buttonStyle="solid"
          style={{ marginBottom: 10 }}
        >
          {(detail?.energyMediums ?? []).map(item => (
            <Radio.Button value={item.id?.toString()}>{item.name}</Radio.Button>
          ))}
        </Radio.Group>
        <Table columns={columns} dataSource={data} />
      </SubContent>
      <Space className="sticky-footer">
        <Button
          onClick={() => {
            navigate(`/energy/analysis/optimizeAnalysis?energyOptimizationProjectId=${id}`);
          }}
        >
          返回
        </Button>
      </Space>
    </Wrapper>
  );
};

export default AnalysisDetail;
