import React from 'react';
import { RoutePathItem } from '@maxtropy/components';

import { PermissionsType } from '@/common/permissionsConst';

const ScadaEditor = React.lazy(() => import(/* webpackChunkName: "scadaEditor" */ '@/pages/ScadaEditor'));
const ScadaGraphPage = React.lazy(() => import(/* webpackChunkName: "scadaGraphPage" */ '@/pages/ScadaGraphPage'));

const scadaRoutes: RoutePathItem[] = [
  {
    path: '/scada/:id/edit',
    permission: PermissionsType.P_UPDATESCADA,
    element: <ScadaEditor />,
  },
  {
    path: '/scada/:id',
    permission: PermissionsType.P_SCADA_INFO,
    element: <ScadaGraphPage />,
  },
];

export default scadaRoutes;
