import { AdminStaffPageRequest, AdminStaffPageResponse, getAdminStaffPage } from '@/api/implement';
import { useQuery } from '@/utils/utils';
import { CustomFilter, Form, Input, Paging, Table, usePaging } from '@maxtropy/components';
import React, { ReactText, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';

export interface TableData {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
  staffId?: number;
}

export interface StaffAdminModalRef {
  selectedRows: Array<TableData> | undefined;
  data: Array<TableData> | undefined;
}

function formatListData(response: AdminStaffPageResponse): Array<TableData> {
  return response.list;
}

type SearchParams = Omit<AdminStaffPageRequest, 'page' | 'size'>;

interface StaffAdminModalProps {
  selectedItems: Array<TableData> | undefined;
}

const columns = [
  {
    title: '用户ID',
    dataIndex: 'humanCode',
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
  },
  {
    title: '用户登录账号',
    dataIndex: 'username',
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    width: 200,
  },
];

const StaffAdminModal = React.forwardRef((props: StaffAdminModalProps, ref: Ref<StaffAdminModalRef>) => {
  const { selectedItems } = props;
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRows, setSelectedRows] = useState<Array<TableData>>();
  const [searchParams, setSearchParams] = useState<SearchParams>();

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: ReactText[], rows: TableData[]) => {
    const newRows = rows.map(item => {
      return {
        ...item,
        staffId: item.id,
      };
    });
    setSelectedRows(newRows.filter(item => item));
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows?.map(item => item.staffId as number),
    getCheckboxProps: (record: TableData) => ({
      disabled: selectedItems?.map(item => item.staffId).includes(record.id),
    }),
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getAdminStaffPage({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return formatListData(res);
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  useEffect(() => {
    if (data) {
      setSelectedRows(selectedItems?.filter(item => data.map(item => item.id).includes(item.staffId as number)));
    }
  }, [selectedItems, data]);

  useImperativeHandle(ref, () => ({ selectedRows: selectedRows, data }));

  return (
    <div>
      <CustomFilter<SearchParams>
        form={form}
        onFinish={val => onFinish(val as SearchParams)}
        onReset={onReset}
        colSpan={8}
      >
        <Form.Item name="humanCode" label="用户ID">
          <Input placeholder={'请输入ID'} />
        </Form.Item>
        <Form.Item name="name" label="姓名">
          <Input placeholder={'请输入姓名'} />
        </Form.Item>
        <Form.Item name="staffCode" label="员工工号">
          <Input placeholder={'请输入员工工号'} />
        </Form.Item>
        <Form.Item name="phone" label="手机号">
          <Input placeholder={'请输入手机号'} />
        </Form.Item>
        <Form.Item name="username" label="员工登录账号">
          <Input placeholder={'请输入员工登录账号'} />
        </Form.Item>
      </CustomFilter>

      <Table<TableData>
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        showSelectedCount
        selectedCount={(selectedRows ?? []).length}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
});

export default StaffAdminModal;
