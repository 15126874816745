import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Select } from '@maxtropy/components';
export function splitTime(minutesGap: number, interDay?: boolean) {
  let gap = 1;
  let hours = 24;
  let startTime = 0;
  if (minutesGap > 60 || minutesGap <= 0) {
    gap = 1;
  }
  gap = Math.floor(60 / minutesGap);

  let amount = hours * gap; // 分割数量

  let temp: string[] = [];
  for (let i = 0; i <= amount; i++) {
    let hours = parseInt(String(startTime / 60));
    let minus = startTime % 60;
    let formateH = interDay ? hours + 24 : hours < 10 ? '0' + hours : hours;

    let formateM = minus < 10 ? '0' + minus : minus;
    temp.push(formateH + ':' + formateM);
    startTime = startTime + minutesGap;
  }
  return temp;
}

const DateOptions = [
  { value: 1, label: '当日' },
  { value: 2, label: '次日' },
];

interface Iprops {
  onChange?: (v?: string[]) => void;
  value?: string[];
  disabled?: boolean;
}
const timeSelectList = splitTime(30);
const TimeRangePicker: React.FC<Iprops> = ({ value, onChange, disabled }) => {
  // 后续考虑使用useMergedState
  const [prevDate, setPrevDate] = useState<number>(1);
  const [nextDate, setNextDate] = useState<number>(1);
  const [preValue, setPreValue] = useState<string>();
  const [nextValue, setNextValue] = useState<string>();
  const preValueRef = useRef<string>();
  const nextValueRef = useRef<string>();

  const preOptions = useMemo(() => {
    return timeSelectList
      .map(item => ({
        value: item,
        label: item,
        disabled: nextDate === 2 ? false : Boolean(nextValue && nextValue <= item),
      }))
      .filter(item => !item.disabled);
  }, [nextValue, nextDate]);

  const nextOptions = useMemo(() => {
    return timeSelectList
      .map(item => ({
        value: item,
        label: item,
        disabled: nextDate === 2 ? false : Boolean(preValue && preValue >= item),
      }))
      .filter(item => !item.disabled);
  }, [preValue, nextDate]);

  const preChange = (v: string) => {
    setPreValue(v);
    preValueRef.current = v;
    if (nextValueRef.current) {
      onChange?.([v, addRealTime(nextValueRef.current)]);
    }
  };

  const nextChange = (v: string) => {
    setNextValue(v);
    nextValueRef.current = v;
    if (preValueRef.current) {
      onChange?.([preValueRef.current, addRealTime(v)]);
    }
  };

  const addRealTime = (dateStr: string) => {
    if (nextDate === 2) {
      const hour = dateStr.split(':')[0];
      return `${Number(hour) > 24 ? hour : 24 + Number(hour)}:${dateStr.split(':')[1]}`;
    }
    return dateStr;
  };

  const minusRealTime = (dateStr: string) => {
    const hour = dateStr.split(':')[0];
    return `${(Number(hour) % 24).toString().padStart(2, '0')}:${dateStr.split(':')[1]}`;
  };

  const nextDateChange = (v: number) => {
    setNextValue(undefined);
    setNextDate(v);
  };

  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0]) {
        setPreValue(value[0]);
        preValueRef.current = value[0];
      }
      if (value[1]) {
        const hour = value[1].split(':')[0];
        const minute = value[1].split(':')[1];
        let real = `${hour}:${minute}`;
        if (Number(hour) > 24 || (Number(hour) === 24 && Number(minute) > 0)) {
          setNextDate(2);
          real = `${(Number(hour) % 24).toString().padStart(2, '0')}:${minute}`;
        }
        setNextValue(real);
        nextValueRef.current = real;
      }
    }
  }, [value]);

  return (
    <>
      <div className={styles.flex_outer}>
        <Select
          disabled={disabled}
          style={{ width: 80 }}
          options={[{ value: 1, label: '当日' }]}
          value={prevDate}
        ></Select>
        <Select
          style={{ flex: 1 }}
          disabled={disabled}
          value={preValue}
          onChange={preChange}
          placeholder="请选择"
          options={preOptions}
        ></Select>
        <span className={styles.split_line}>-</span>
        <Select
          disabled={disabled}
          style={{ width: 80 }}
          options={DateOptions}
          value={nextDate}
          onChange={nextDateChange}
        ></Select>
        <Select
          style={{ flex: 1 }}
          disabled={disabled}
          value={nextValue}
          onChange={nextChange}
          placeholder="请选择"
          options={nextOptions}
        ></Select>
      </div>
    </>
  );
};
export default TimeRangePicker;
