import { useState, useEffect, Key } from 'react';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import {
  apiV2CarbonFootprintOverviewAnalysisPost,
  V2CarbonFootprintOverviewAnalysisPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Empty } from '@maxtropy/components';
import TrophyFillImg from '../../assets/trophy-fill@2x.png';
import FundFillImg from '../../assets/fund-fill@2x.png';
import PieChartImg from '../../assets/pieChart-circle-fil@2x.png';
import Subtitle from '../Subtitle';
import { transCarbonFootprintQuantity, transPercent } from '../../utils';
import styles from './index.module.scss';

type CarbonColumnarVoProp = V2CarbonFootprintOverviewAnalysisPostResponse['carbonColumnarVo'];

const AnalysisOfProductCarbonFootprint = ({ id, isTwoCol }: { id?: Key; isTwoCol?: boolean }) => {
  const [data, setData] = useState<V2CarbonFootprintOverviewAnalysisPostResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiV2CarbonFootprintOverviewAnalysisPost({ id })
      .then(res => {
        setData(res);
      })
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <div className={`${styles.analysisOfPCFCon} ${isTwoCol && styles.flexBox} `}>
      <Subtitle title="产品碳足迹优化分析" />
      <div className={styles.analysisInfo}>
        <div className={styles.infoItem}>
          <img src={TrophyFillImg} alt="pic" />
          <div className={styles.detail}>
            <div className={styles.desc}>
              最佳碳足迹 ({transCarbonFootprintQuantity(data?.niceUnitCarbonFootPrint)[1]})
            </div>
            <div className={styles.count}>{transCarbonFootprintQuantity(data?.niceUnitCarbonFootPrint)[0]}</div>
          </div>
        </div>
        <div className={styles.infoItem}>
          <img src={FundFillImg} alt="pic" />
          <div className={styles.detail}>
            <div className={styles.desc}>可优化空间({transCarbonFootprintQuantity(data?.optimizationSpace)[1]})</div>
            <div className={styles.count}>{transCarbonFootprintQuantity(data?.optimizationSpace)[0]}</div>
          </div>
        </div>
        <div className={styles.infoItem}>
          <img src={PieChartImg} alt="pic" />
          <div className={styles.detail}>
            <div className={styles.desc}>可优化比例</div>
            <div className={styles.count}>{transPercent(data?.percent)}</div>
          </div>
        </div>
      </div>
      <div className={styles.analysisChartInfo}>
        <div className={styles.analysisChartTitle}>
          原材料碳足迹分析{data?.carbonColumnarVo?.count! > 10 && '(TOP10)'}
        </div>
        <div className={styles.analysisChart}>
          <Spin spinning={loading}>
            {data?.carbonColumnarVo ? (
              <ReactEcharts
                style={{ width: '100%', height: '100%' }}
                option={getChartOption(data.carbonColumnarVo)}
                notMerge
                lazyUpdate={false}
              />
            ) : (
              <Empty style={{ margin: 'auto' }} />
            )}
          </Spin>
        </div>
      </div>
      <div className={styles.analysisChartInfo}>
        <div className={styles.analysisChartTitle}>
          生产碳足迹分析{data?.productCarbonColumnarVo?.count! > 10 && '(TOP10)'}
        </div>
        <div className={styles.analysisChart}>
          <Spin spinning={loading}>
            {data?.productCarbonColumnarVo ? (
              <ReactEcharts
                style={{ width: '100%', height: '100%' }}
                option={getChartOption(data.productCarbonColumnarVo)}
                notMerge
                lazyUpdate={false}
              />
            ) : (
              <Empty style={{ margin: 'auto' }} />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

const getChartOption = (data: CarbonColumnarVoProp) => {
  return {
    grid: {
      left: 10,
      right: 0,
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          color: 'rgba(255,255,255,0.1)',
        },
      },
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      padding: [8, 12, 0, 12],
      className: 'carbon-chart-tooltip carbon-chart-tooltip2',
      formatter: (tooltipData: any) => {
        const title = tooltipData[0]?.name ?? '--';

        return `
          <div class='carbon-tooltip-title'>${title}</div>
          <div><div style='width:8px;height:8px;background:${
            tooltipData[0]?.color
          };border-radius:50%;margin-right:8px'></div> ${
          tooltipData[0]?.seriesName ?? '--'
        }<span>${transCarbonFootprintQuantity(tooltipData[0]?.value).join(' ')}</span></div>
          <div><div style='width:8px;height:8px;background:${
            tooltipData[1]?.color
          };border-radius:50%;margin-right:8px'></div> ${
          tooltipData[1]?.seriesName ?? '--'
        }<span>${transCarbonFootprintQuantity(tooltipData[1]?.value).join(' ')}</span></div>
          <div><div style='width:8px;height:8px;background:linear-gradient(180deg,rgba(255,109,44,0.5) 0%,rgba(255,109,44,0.2) 100%);border-radius:50%;margin-right:8px'></div> ${
            tooltipData[2]?.seriesName ?? '--'
          }<span>${transCarbonFootprintQuantity(tooltipData[2]?.value).join(' ')}</span></div>
        `;
      },
    },
    legend: {
      right: 0,
      top: 0,
      bottom: 8,
      icon: 'rect',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      itemWidth: 12,
      itemHeight: 12,
      itemGap: 24,
      padding: [0, 8, 0, 0],
    },
    xAxis: {
      type: 'category',
      data: data?.nameList,
      axisTick: {
        show: false,
      },
      axisLabel: {
        margin: 8,
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
    },
    yAxis: {
      type: 'value',
      name: 'kg CO2e',
      nameGap: 8,
      splitLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.3)',
        },
      },
      nameTextStyle: {
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
      axisLine: {},
      axisLabel: {
        margin: 8,
        color: 'rgba(255,255,255,0.85)',
        lineHeight: 20,
      },
    },
    series: [
      {
        data: data?.currentCarbonList,
        type: 'bar',
        name: '当前批次碳足迹',
        barWidth: 16,
        barGap: '50%',
        itemStyle: {
          color: '#00ADFF',
        },
      },
      {
        data: data?.niceCarbonList,
        type: 'bar',
        name: '最佳批次碳足迹',
        stack: 'Ad',
        barWidth: 16,
        itemStyle: {
          color: '#16DD8E',
        },
      },
      {
        data: data?.optimizationSpaceList,
        type: 'bar',
        name: '可优化空间',
        stack: 'Ad',
        barWidth: 16,
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(255,109,44,0.5)', // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(255,109,44,0.2)', // 100% 处的颜色
              },
            ],
          },
        },
      },
    ],
  };
};

export default AnalysisOfProductCarbonFootprint;
