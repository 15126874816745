import { CircuitProps, getCircuitListPage, getDistributionCabinetList } from '@/api/circuit';
import { getOuListOwned } from '@/api/ou';
import { EllipsisSpan, Paging, usePaging, useAsync, Input, Select, Modal, Table } from '@maxtropy/components';
import { Col, Row, Form } from 'antd';
import React, { Key, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styles from './index.module.scss';

import { CabinetListCircuitWithDataRes } from '@/api/distributionCabinetManage';

interface DeviceModalProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  selectedDistributionId?: Key;
  circuitList?: CabinetListCircuitWithDataRes[];
}

const columns = [
  {
    title: '回路名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属配电柜',
    dataIndex: 'distributionCabinetName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface AddCircuitModalRef {
  selectedRowKeys: Array<Key>;
}

const AddCircuitModal = React.forwardRef((props: DeviceModalProps, ref: Ref<AddCircuitModalRef>) => {
  const { visible, onOk, onCancel, circuitList } = props;
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [data, setData] = useState<CircuitProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [distributionList, setDistributionList] = useState<any[]>([]); // 配电柜列表

  const [circuitName, setCircuitName] = useState<string>(); // 搜索回路名称
  const [distributionCabinetId, setDistributionCabinetId] = useState<number>(); // 搜索配电柜
  const [ouId, setOuId] = useState<number>(); // 搜索所属运营单元

  const ouList = useAsync(getOuListOwned, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  // 当切换选择配电柜的时候，清空查询的表单,并查询该配电柜下已经选择的配电柜
  useEffect(() => {
    if (circuitList && circuitList.length) {
      setSelectedRowKeys(circuitList.map(i => i.circuitId));
    }
  }, [circuitList]);

  useEffect(() => {
    getDistributionCabinetList().then(res => setDistributionList(res.list ?? [])); // 配电柜下拉
  }, []);

  // 查询回路列表
  useEffect(() => {
    if (visible) {
      setLoading(true);
      getCircuitListPage({
        name: circuitName,
        distributionCabinetId,
        ouId: ouId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        if (res) {
          setLoading(false);
          setTotalCount(res.total);
          setData(res.list);
        }
      });
    }
  }, [pageOffset, pageSize, setTotalCount, circuitName, distributionCabinetId, ouId, visible]);

  // 搜索的ou列表
  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  // 搜索的所属配电柜
  const distributionCabinetOptions = useMemo(() => {
    if (distributionList && distributionList.length) {
      return distributionList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [distributionList]);

  const filters = (
    <Form form={form} layout="horizontal" labelCol={{ style: { width: 100 } }} labelAlign={'left'}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item name="circuitName" label="回路名称">
            <Input
              className={styles.search}
              allowClear
              placeholder="请输入回路名称查询"
              onChange={e => {
                // 清空输入框
                if (e.type === 'click') {
                  setCircuitName(undefined);
                  setPageOffset(1);
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setCircuitName(undefined);
                  setPageOffset(1);
                } else {
                  setCircuitName(e.target.value);
                }
              }}
              onPressEnter={(e: any) => setCircuitName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="所属配电柜" name="typeId">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={distributionCabinetOptions}
              allowClear
              onSelect={(value: number) => setDistributionCabinetId(value)}
              onClear={() => {
                setDistributionCabinetId(undefined);
                setPageOffset(1);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="ouId" label="所属运营单元">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={ouOptions}
              allowClear
              onSelect={(value: number) => setOuId(value)}
              onClear={() => {
                setOuId(undefined);
                setPageOffset(1);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  useImperativeHandle(ref, () => ({ selectedRowKeys }));

  const onSelectChange = (rowKeys: Key[], rows: any[]) => {
    setSelectedRowKeys(rowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    selectedRowKeys,
  };

  return (
    <>
      <Modal
        forceRender
        open={visible}
        title="添加回路"
        size="big"
        destroyOnClose
        onOk={() => {
          setPageOffset(1);
          form.resetFields();
          setCircuitName(undefined);
          setOuId(undefined);
          setDistributionCabinetId(undefined);
          onOk?.();
        }}
        onCancel={() => {
          setPageOffset(1);
          form.resetFields();
          setCircuitName(undefined);
          setOuId(undefined);
          setDistributionCabinetId(undefined);
          onCancel?.();
        }}
      >
        {filters}

        <Table
          loading={loading}
          sticky
          rowSelection={{
            type: 'checkbox',
            hideSelectAll: true,
            ...rowSelection,
          }}
          dataSource={data}
          rowKey="id"
          scroll={{ y: 300 }}
          columns={columns}
          pagination={false}
          showSelectedCount
          selectedCount={(selectedRowKeys ?? []).length}
        >
          <Paging pagingInfo={pagingInfo} />
        </Table>
      </Modal>
    </>
  );
});

export default AddCircuitModal;
