import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Input,
  Paging,
  Select,
  Table,
  useBreadcrumbRoutes,
  Form,
  Wrapper,
  usePaging,
  message,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import {
  V2DispatchPlanPagePostResponse,
  apiV2DispatchPlanDeletePost,
  apiV2DispatchPlanPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { getOuListOwned, OuListResponse } from '../../../../api/ou';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';
export interface FilterParams {
  planName?: string;
  ouIds?: number[];
  dispatchScenes: number[];
}

//派工周期枚举
export enum TypeEMun {
  CIRCUIT = 1, //
  ENERG = 2,
}

export const typeDisplay = {
  [TypeEMun.CIRCUIT]: '回路管网',
  [TypeEMun.ENERG]: '用能单元',
};

export interface searchParams {
  planName?: string;
  ouIds?: number[];
  dispatchScenes?: number[];
}
const Options = () => {
  return [
    { label: '回路管网', value: 1 },
    { label: '用能单元', value: 2 },
  ];
};
export type PlanItem = Exclude<V2DispatchPlanPagePostResponse['list'], undefined>[number];

const columns = [
  {
    title: '计划名称',
    dataIndex: 'planName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '派工场景',
    dataIndex: 'dispatchScene',
    render: (v: number) => (
      <EllipsisSpan value={v === TypeEMun.CIRCUIT ? typeDisplay[TypeEMun.CIRCUIT] : typeDisplay[TypeEMun.ENERG]} />
    ),
  },
  {
    title: '操作人',
    dataIndex: 'updateName',
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm')} `} />,
  },
];
const PlanList: React.FC = () => {
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging();
  const navigate = useNavigate();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [data, setData] = useState<PlanItem[]>([]);
  const [searchParams, setSearchParams] = useState<searchParams>();
  //运营单元
  const [ou, setOu] = useState<OuListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const hasEditStationPlan = useHasPermission(PermissionsType.B_EDITDISPATCHPROGRAM); // 编辑计划
  const hasAddStationPlan = useHasPermission(PermissionsType.B_ADDDISPATCHPROGRM); // 新增计划
  const hasDeleteStationPlan = useHasPermission(PermissionsType.B_DELETEDISPATCHPROGRAM); // 删除计划
  useEffect(() => {
    getOuListOwned().then(setOu); // 所属运营单元下拉选择
    getPlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams]);

  const ouOptions = useMemo(() => {
    return (ou ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [ou]);

  const onFinish = (v: FilterParams) => {
    setSearchParams({
      planName: v.planName,
      ouIds: v.ouIds,
      dispatchScenes: v.dispatchScenes,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const getPlanList = () => {
    setLoading(true);
    apiV2DispatchPlanPagePost({
      page: pageOffset,
      size: pageSize,
      planName: searchParams?.planName,
      ouIds: searchParams?.ouIds,
      dispatchScenes: searchParams?.dispatchScenes,
    }).then(res => {
      setLoading(false);
      if (res) {
        setData(res?.list ?? []);
        setTotalCount(res?.total ?? 0);
      }
    });
  };

  const onDelete = (id: string) => {
    let params = {
      id,
    };
    apiV2DispatchPlanDeletePost({ ...params }).then(res => {
      message.success('删除成功');
      getPlanList();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 200,
      render: (v: string, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigate(`/TicketCenter/WorkOrderManagement/list/detail/${record.id}`);
              }}
            >
              查看
            </Button>
            {hasEditStationPlan && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/TicketCenter/WorkOrderManagement/list/edit/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasDeleteStationPlan && (
              <Button
                type="link"
                onClick={() => {
                  onDelete(record.id);
                }}
              >
                删除
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="planName" label="计划名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="ouIds" label="运营单元">
        <Select placeholder="请选择" showSearch options={ouOptions} optionFilterProp="label" mode="multiple" />
      </Form.Item>
      <Form.Item name="dispatchScenes" label="派工场景">
        <Select placeholder="请选择" options={Options()} mode="multiple" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} filters={filters} className={styles.wrapperStyles}>
      <div>
        {hasAddStationPlan && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate('/TicketCenter/WorkOrderManagement/list/create');
            }}
          >
            新建派工计划
          </Button>
        )}

        <Table
          rowKey="id"
          sticky
          dataSource={data}
          columns={buildColumns}
          pagination={false}
          style={{ marginTop: '10px' }}
          loading={loading}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Wrapper>
  );
};
export default PlanList;
