import { InfoCircleOutlined } from '@ant-design/icons';
import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Radio,
  InputNumber,
  DatePicker,
  FormTitle,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ElectricityAccountVo, getElectricityAccounList } from '../../../api/electricity';
import { getOuListOwned, OuListResponse } from '../../../api/ou';
import {
  createSaleClient,
  getSaleClient,
  ChargeTypeEnum,
  ChargeTypeEnumDisplay,
  SaleClientInfo,
  SaleClientRequest,
  updateSaleClient,
  ImportantLevel,
  getImportantLevel,
} from '../../../api/sale';
import { getThermalList, ThermalResponse } from '../../../api/thermal';
import ClientContact from '../components/ClientContact';
import styles from './index.module.scss';
import dayjs from 'dayjs';
interface CreateClientProps {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const formatString = 'YYYY-MM-DD';

const CreateClient: FC<CreateClientProps> = ({ isEdit = false }) => {
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [ou, setOu] = useState<OuListResponse[]>([]);

  const [clientData, setClientData] = useState<SaleClientInfo>();

  const [loading, setLoading] = useState<boolean>(false);

  const [importantLevel, setImportantLevel] = useState<Array<ImportantLevel>>([]);
  const [thermalAccounts, setThermalAccounts] = useState<Array<ThermalResponse>>([]);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getOuListOwned().then(setOu);
    getElectricityAccounList().then(res => setElectricityAccounds(res.list || []));
    getImportantLevel().then(setImportantLevel);
    getThermalList().then(res => setThermalAccounts(res.list || []));
  }, []);

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const [electricityAccounds, setElectricityAccounds] = useState<ElectricityAccountVo[]>([]);

  useEffect(() => {
    if (id) {
      getSaleClient(id).then(setClientData);
    }
  }, [id]);

  useEffect(() => {
    if (clientData) {
      form.setFieldsValue({
        code: clientData.code,
        name: clientData.name,
        ouId: clientData.ouId,
        companyName: clientData.companyName,
        companyAddress: clientData.companyAddress,
        uscc: (clientData.uscc ?? '').toUpperCase(),
        meterTypes: clientData.meterTypes,
        electricityAccountIds: clientData.electricityAccounts.map(i => i.id),
        contacts: clientData.contacts,
        // 客户重要等级
        importantLevel: clientData.importantLevel,
        // 用汽账户
        thermalAccountIds: clientData.thermalAccounts.map(i => i.id),
        averagePriceManual: clientData.averagePriceManual,
        averagePriceAuto: clientData.averagePriceAuto,
        isManual: clientData.isManual,
        effectiveTimeOfAverPrice: clientData.averagePriceStartDate &&
          clientData.averagePriceEndDate && [
            dayjs(clientData.averagePriceStartDate, formatString),
            dayjs(clientData.averagePriceEndDate, formatString),
          ],
        beneficialBank: clientData.beneficialBank,
        bankAccount: clientData.bankAccount,
      });
    }
  }, [clientData, form]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}客户` }];
  }, [isEdit]);

  const onFinish = (val: SaleClientRequest) => {
    const createDateStart = !val.effectiveTimeOfAverPrice
      ? undefined
      : dayjs(val.effectiveTimeOfAverPrice[0])?.format(formatString);
    const createDateEnd = !val.effectiveTimeOfAverPrice
      ? undefined
      : dayjs(val.effectiveTimeOfAverPrice[1])?.format(formatString);
    const params = {
      ...val,
      averagePriceStartDate: createDateStart,
      averagePriceEndDate: createDateEnd,
    };
    setLoading(true);
    (id ? updateSaleClient(id, params) : createSaleClient(params))
      .then(() => {
        goList();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goList = () => {
    navigate('/operation/client-related/client');
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form
        layout="vertical"
        form={form}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
        {...formLayout}
        onFinish={onFinish}
      >
        <FormTitle title={routes[0].name} />
        <SubContent>
          <Row>
            <Col span={8}>
              <Form.Item
                name="code"
                label="客户编号"
                rules={[
                  { required: true, message: '请输入客户编号' },
                  { pattern: /^[^\u4E00-\u9FFF]*$/g, message: '不支持输入中文' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="name"
                label="客户名称"
                rules={[
                  { required: true, message: '请输入客户名称' },
                  { max: 100, message: '最多输入一百个字' },
                ]}
              >
                <Input maxLength={100} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouId" label="所属运营单元" rules={[{ required: true, message: '请选择所属运营单元' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={ouOptions}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="companyName"
                label="企业名称"
                rules={[
                  { required: true, message: '请输入企业名称' },
                  { max: 50, message: '最多输入五十个字' },
                ]}
              >
                <Input maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="companyAddress"
                label="企业地址"
                rules={[
                  { required: true, message: '请输入企业地址' },
                  { max: 50, message: '最多输入五十个字' },
                ]}
              >
                <Input maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="uscc"
                label="纳税号"
                rules={[
                  { max: 18, message: '最多输入十八个字' },
                  { pattern: /^[a-zA-Z0-9]*$/, message: '仅限输入数字和英文' },
                ]}
              >
                <Input maxLength={18} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="beneficialBank"
                label="开户行"
                rules={[
                  { max: 50, message: '最多输入五十个字' },
                  { pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入中文' },
                ]}
              >
                <Input maxLength={50} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="bankAccount"
                label="账号"
                rules={[
                  { max: 20, message: '最多输入二十个字' },
                  { pattern: /^[0-9]+$/, message: '请输入数字' },
                ]}
              >
                <Input maxLength={20} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="importantLevel" label="客户重要等级">
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={(importantLevel ?? []).map(i => ({ value: i.id, label: i.desc }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="meterTypes" label="收费类型" rules={[{ required: true, message: '请选择收费类型' }]}>
                <Checkbox.Group>
                  <Checkbox value={ChargeTypeEnum.POWER}>{ChargeTypeEnumDisplay[ChargeTypeEnum.POWER]}</Checkbox>
                  <Checkbox value={ChargeTypeEnum.THERMAL}>{ChargeTypeEnumDisplay[ChargeTypeEnum.THERMAL]}</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.POWER) && (
                    <Col span={8}>
                      <Form.Item
                        name="electricityAccountIds"
                        label="用电账户"
                        rules={[{ required: true, message: '请选择用电账户' }]}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="请选择"
                          options={electricityAccounds.map(i => ({ value: i.id, label: i.number }))}
                          showSearch
                          optionFilterProp="label"
                        />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.THERMAL) && (
                    <Col span={8}>
                      <Form.Item
                        name="thermalAccountIds"
                        label="用汽账户"
                        rules={[{ required: true, message: '请选择用汽账户' }]}
                      >
                        <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="请选择"
                          options={(thermalAccounts ?? []).map(i => ({ value: i.id, label: i.number }))}
                        />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['meterTypes']}>
              {({ getFieldValue }) => {
                return (
                  ((getFieldValue('meterTypes') ?? []) as ChargeTypeEnum[]).includes(ChargeTypeEnum.POWER) && (
                    <>
                      <Col span={24}>
                        <div style={{ marginBottom: '36px' }}>
                          <h3 style={{ display: 'inline-block', marginRight: '12px', fontSize: '14px' }}>度电均价</h3>
                          <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: 5 }} />
                          <span style={{ color: 'var(--mx-text-desc-color)' }}>
                            度电均价根据核查联每日更新，未生成核查联的用户度电均价为空。
                            如手动输入，生效期间内以人工确认为准。如客户核查联26日生成，手动填写建议截止到28日
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: '36px' }}>
                          {clientData?.averagePriceAuto ? (
                            <div style={{ fontSize: '14px' }}>
                              系统计算：{clientData?.averagePriceAuto ? clientData?.averagePriceAuto + '元' : '--'}
                            </div>
                          ) : (
                            <>
                              <h3 style={{ fontSize: '14px' }}>系统计算：--</h3>
                              <span style={{ color: 'var(--mx-text-desc-color)' }}>
                                未查询到核查联，建议手工输入度电均价
                              </span>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="isManual" label="是否手工确认">
                          <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Form.Item noStyle dependencies={['isManual']}>
                        {({ getFieldValue }) => {
                          console.log(getFieldValue('isManual'));
                          return (
                            getFieldValue('isManual') === true && (
                              <>
                                <Col span={8}>
                                  <Form.Item
                                    name="averagePriceManual"
                                    label="度电均价"
                                    rules={[
                                      { required: true, message: '请输入度电均价' },
                                      {
                                        validator: (_, value) => {
                                          // if (!value) {
                                          //   return Promise.reject('请输入余额小于等于');
                                          // }
                                          if (value <= 0) {
                                            return Promise.reject('请输入大于0的数');
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="请输入"
                                      style={{ width: '100%' }}
                                      step="0.00000001"
                                      precision={8}
                                      // stringMode
                                      addonAfter="元"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    name="effectiveTimeOfAverPrice"
                                    label="均价生效时间"
                                    rules={[{ required: true, message: '请输入均价生效时间' }]}
                                  >
                                    <DatePicker.RangePicker allowClear={false} />
                                  </Form.Item>
                                </Col>
                              </>
                            )
                          );
                        }}
                      </Form.Item>
                    </>
                  )
                );
              }}
            </Form.Item>

            <Col span={24}>
              <Form.Item
                name="contacts"
                label="客户联系人信息"
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: '请添加客户联系人信息', type: 'array' }]}
              >
                <ClientContact />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={goList}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateClient;
