import { IconKey, NodeType, NodeTypeDisplay } from '../utils';
import styles from './index.module.scss';

export const BaseNodeList = [
  {
    name: NodeTypeDisplay[NodeType.COMPRESSOR],
    type: NodeType.COMPRESSOR,
    selfId: NodeType.COMPRESSOR,
    iconKey: IconKey[NodeType.COMPRESSOR],
  },
  {
    name: NodeTypeDisplay[NodeType.NITROGEN_GENERATOR],
    type: NodeType.NITROGEN_GENERATOR,
    selfId: NodeType.NITROGEN_GENERATOR,
    iconKey: IconKey[NodeType.NITROGEN_GENERATOR],
  },
  {
    name: NodeTypeDisplay[NodeType.GAS_TANK],
    type: NodeType.GAS_TANK,
    selfId: NodeType.GAS_TANK,
    iconKey: IconKey[NodeType.GAS_TANK],
  },
  {
    name: NodeTypeDisplay[NodeType.STATION_OUTLET],
    type: NodeType.STATION_OUTLET,
    selfId: NodeType.STATION_OUTLET,
    iconKey: IconKey[NodeType.STATION_OUTLET],
  },
  {
    name: NodeTypeDisplay[NodeType.GAS_MANIFOLD],
    type: NodeType.GAS_MANIFOLD,
    selfId: NodeType.GAS_MANIFOLD,
    iconKey: IconKey[NodeType.GAS_MANIFOLD],
  },
  {
    name: NodeTypeDisplay[NodeType.THERMOMETER],
    type: NodeType.THERMOMETER,
    selfId: NodeType.THERMOMETER,
    iconKey: IconKey[NodeType.THERMOMETER],
  },
  {
    name: NodeTypeDisplay[NodeType.REFRIGERATOR],
    type: NodeType.REFRIGERATOR,
    selfId: NodeType.REFRIGERATOR,
    iconKey: IconKey[NodeType.REFRIGERATOR],
  },
  {
    name: NodeTypeDisplay[NodeType.FLOW_METER],
    type: NodeType.FLOW_METER,
    selfId: NodeType.FLOW_METER,
    iconKey: IconKey[NodeType.FLOW_METER],
  },
  {
    name: NodeTypeDisplay[NodeType.PRESSURE_GAUGE],
    type: NodeType.PRESSURE_GAUGE,
    selfId: NodeType.PRESSURE_GAUGE,
    iconKey: IconKey[NodeType.PRESSURE_GAUGE],
  },
];

export const customEdgeAttrs = (stop1Color: string, stop2Color: string) => ({
  fill: {
    connection: true,
    strokeWidth: 3,
    strokeDasharray: '8,2',
    fill: 'none',
    stroke: {
      type: 'linearGradient',
      stops: [
        { offset: '0%', color: stop1Color },
        { offset: '100%', color: stop2Color },
      ],
    },
    class: styles.antLine,
  },
  stroke: {
    fill: 'none',
    stroke: 'rgba(97, 216, 142, 0.3)',
    connection: true,
    strokeWidth: 9,
    strokeLinecap: 'square',
  },
});
