import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Button, useAsync, Switch, Modal, message } from '@maxtropy/components';

import { Space } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash-es';

import styles from './index.module.scss';

import PushStaffModal from './PushStaffModal';
import { PushCenterStaffLinkVoList } from '../../api/deviceAlarmPushStrategy';
import { getStaffChannelApp } from '@/shared/api/common';

import { columns } from './util';
import { ChannelApp } from '@/shared/types';

export interface SelectPushStaffProps {
  value?: PushCenterStaffLinkVoList[];
  onChange?: (value: PushCenterStaffLinkVoList[]) => void;
  ouId?: number;
  disabled?: boolean;
  // 返回true，校验不通过, 否则校验通过
  validator?: (values: string[]) => boolean | undefined;
}

const SelectPushStaff: FC<SelectPushStaffProps> = ({ value, onChange, ouId, disabled = false, validator }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const staffChannelApps = useAsync(getStaffChannelApp);

  const [dataSource, setDataSource] = useState<PushCenterStaffLinkVoList[]>([]);

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.staffId);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (ouId === undefined) {
      Modal.warning({
        title: '请先选择运营单元',
      });
      return;
    }
    setVisible(true);
  };

  const unBind = (record: PushCenterStaffLinkVoList) => {
    Modal.confirm({
      title: '取消绑定？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.staffId !== record.staffId);
        onChange?.(newValue);
      },
    });
  };

  const changeRecordThirdApp = (checked: boolean, channelApp: ChannelApp, record: PushCenterStaffLinkVoList) => {
    const index = (value ?? []).findIndex(i => i.staffId === record.staffId);
    const newRecord = cloneDeep(record);
    if (index !== -1) {
      const thirdAppIndex = newRecord.thirdPartyAppId.findIndex(i => i === channelApp.channelId);
      if (checked) {
        if (thirdAppIndex === -1) {
          newRecord.thirdPartyAppId.push(channelApp.channelId);
        }
      } else {
        if (thirdAppIndex !== -1) {
          newRecord.thirdPartyAppId = record.thirdPartyAppId.filter(i => i !== channelApp.channelId);
        }
      }
      const newValue = [...(value ?? []).slice(0, index), newRecord, ...(value ?? []).slice(index + 1)];
      onChange?.(newValue);
    }
  };

  const mergeColumns = [
    ...columns,
    ...(staffChannelApps ?? []).map(i => ({
      title: i.channelName,
      render: (value: undefined, record: PushCenterStaffLinkVoList) => {
        const checked = !!record.thirdPartyAppId.find(j => j === i.channelId);
        return disabled ? (
          checked ? (
            '开'
          ) : (
            '关'
          )
        ) : (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            onChange={(checked: boolean) => {
              changeRecordThirdApp(checked, i, record);
            }}
          />
        );
      },
    })),
  ];

  const buildColumns = [
    ...mergeColumns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: PushCenterStaffLinkVoList) => {
        return (
          <Button
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    const phones = dataSource.map(i => i.phone);
    const uniquePhones = Array.from(new Set(phones));
    if (phones.length !== uniquePhones.length) {
      message.warning(`手机号已被添加/与外部推送人员重复，请重新选择`);
      return;
    }

    const validateStatus = validator?.(phones) || undefined;
    if (validateStatus !== true) {
      onChange?.(dataSource);
      setVisible(false);
    } else {
      message.warning(`手机号已被添加/与外部推送人员重复，请重新选择`);
      return;
    }
  };

  return (
    <>
      {!disabled && (
        <Space direction="vertical">
          <Button type="primary" icon={<PlusOutlined />} wrapStyle={{ marginBottom: 10 }} onClick={onAdd}>
            添加
          </Button>
          <div />
        </Space>
      )}
      <Table
        sticky
        scroll={{ x: 1300, y: 400 }}
        columns={disabled ? mergeColumns : buildColumns}
        rowKey="staffId"
        dataSource={value ?? []}
      />
      <Modal
        className={styles.modal}
        title="推送人员选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="large"
      >
        <PushStaffModal ouId={ouId?.toString()} onChange={setDataSource} value={dataSource} disabledIds={disabledIds} />
      </Modal>
    </>
  );
};

export default SelectPushStaff;
