import {
  Tag,
  Wrapper,
  Button,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  BaseInfoContent,
  Form,
  ShowInput,
} from '@maxtropy/components';
import { Space } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styles from '../../index.module.scss';
import { AlarmLevelDisplay, DiscreteRateIntervalDisplay, RuleLogicDisplay } from '../../types';
import { AlarmLevelColorDisplay } from '@/shared/types';
import { V2ServeAlarmRuleDetailPostResponse, apiV2ServeAlarmRuleDetailPost } from '@maxtropy/device-customer-apis-v2';

interface Props {
  isEdit?: boolean;
}
const PvAlarmCreate: React.FC<Props> = ({ isEdit }) => {
  const { id } = useParams<{ id: string | undefined }>();
  const [info, setInfo] = useState<V2ServeAlarmRuleDetailPostResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      apiV2ServeAlarmRuleDetailPost({ id: id }).then(res => {
        setInfo(res);
        form.setFieldsValue(res);
      });
    }
  }, [id]);

  const routes = [{ name: '查看光伏服务端报警' }];
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.detail}>
      <FormTitle title="查看光伏服务端报警"></FormTitle>

      <SubContent>
        <Form form={form}>
          <BaseInfoContent>
            <Form.Item label="规则编号" name="code">
              <ShowInput></ShowInput>
            </Form.Item>
            <Form.Item label="规则名称" name="name">
              <ShowInput></ShowInput>
            </Form.Item>
            <Form.Item label="报警等级" name="alarmLevel">
              {info?.alarmLevel !== undefined && info?.alarmLevel !== null ? (
                <Tag border="solid" color={AlarmLevelColorDisplay[info?.alarmLevel]}>
                  {AlarmLevelDisplay[info?.alarmLevel]}
                </Tag>
              ) : (
                '--'
              )}
            </Form.Item>
            <Form.Item label="规则逻辑" name="ruleLogic">
              {info?.ruleLogic !== undefined && info?.ruleLogic !== null ? RuleLogicDisplay[info?.ruleLogic] : '--'}
            </Form.Item>
            <Form.Item label="光伏站名称" name="pvName">
              <ShowInput></ShowInput>
            </Form.Item>
            <Form.Item label="光伏站编号" name="pvCode">
              <ShowInput></ShowInput>
            </Form.Item>
            <Form.Item label="推送运营单元" name="ouName">
              <ShowInput></ShowInput>
            </Form.Item>
            <Form.Item
              label="离散率"
              name="discreteRateInterval"
              tooltip={{
                title: (
                  <div style={{ color: 'var(--mx-text-base-color)' }}>
                    <div>异常: 离散率在20%及以上</div>
                    <div>较差: 离散率在20%~10%</div>
                    <div>一般: 离散率在10%~5%</div>
                    <div>优秀: 离散率在5%以下</div>
                  </div>
                ),
                icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginLeft: 8 }} />,
              }}
            >
              <span style={{ marginRight: '20px' }}>
                {info?.discreteRateInterval !== undefined && info?.discreteRateInterval !== null
                  ? DiscreteRateIntervalDisplay[info.discreteRateInterval]
                  : '--'}
              </span>
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </SubContent>

      <Space size={8} className="sticky-footer">
        <Button>
          <Link to={`/pvOps/server/alarm`}>返回</Link>
        </Button>
      </Space>
    </Wrapper>
  );
};

export default PvAlarmCreate;
