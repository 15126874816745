import React, { useEffect, useMemo } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select } from '@maxtropy/components';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StateItem, StateType, StateTypeName, TimeType, TimeUnitType } from '../../utils';
import { Col, Row } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';

export interface CustomStateModalProps {
  stateItem?: any;
  visible: boolean;
  stateList?: StateItem[];
  onCancel: () => void;
  onOk: (values: any) => void;
}

const CustomStateModal: React.FC<CustomStateModalProps> = ({ visible, stateItem, stateList, onCancel, onOk }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (stateItem) {
      form.setFieldsValue({
        ...stateItem,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateItem]);

  const getDateRangeList = (dayDate: Dayjs[], deviceType: StateType, name: string) => {
    const interval = dayDate![1].valueOf() - dayDate![0].valueOf();
    const dayLen = interval / 1000 / 24 / 60 / 60 + 1;
    const dayList = [];
    for (let i = 0; i < dayLen; i++) {
      dayList.push({
        deviceType: deviceType,
        name: name,
        date: dayDate![0].add(i, 'day').format('YYYY-MM-DD'),
      });
    }
    return dayList;
  };

  // 已存表格的所有状态名称+设备状态+日期
  const dayList = useMemo(() => {
    return (stateList ?? [])
      .filter(i => isNil(stateItem) || i.id !== stateItem.id)
      .map(k => {
        if (k.timeType === TimeType.DAY) {
          return getDateRangeList(k.dayDate!, k.deviceType!, k.name!);
        } else {
          return (k.customTimes ?? []).map(m => ({
            deviceType: k.deviceType,
            name: k.name,
            date: m.format('YYYY-MM-DD'),
          }));
        }
      })
      .flat();
  }, [stateList, stateItem]);

  const checkForm = () => {
    const timeType = form.getFieldValue('timeType');
    if (timeType === TimeType.DAY) {
      const dayDate = form.getFieldValue('dayDate');
      if (!isNil(dayDate)) {
        form.validateFields(['dayDate']);
      }
    }
    if (timeType === TimeType.CUSTOM) {
      const customTimes = form.getFieldValue('customTimes');
      if (!isNil(customTimes)) {
        form.validateFields(['customTimes'], { recursive: true });
      }
    }
  };

  const onSave = () => {
    form.validateFields().then(values => {
      const dateRange =
        values.timeType === TimeType.DAY
          ? `${dayjs(values.dayDate[0]).format('YYYY-MM-DD')}~${dayjs(values.dayDate[1]).format('YYYY-MM-DD')}`
          : values.customTimes.map((k: any) => `${dayjs(k).format('YYYY-MM-DD')}`).join('，');
      onOk({
        ...values,
        dateRange,
        id: stateItem?.id ?? uuidv4(),
      });
    });
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      size="normal"
      bodyScroll
      contentClassName="modal-form-content"
      onCancel={onCancel}
      onOk={onSave}
      title={`${stateItem ? '编辑' : '新增'}设备自定义运行状态`}
    >
      <Form labelAlign="left" form={form} initialValues={{ timeType: TimeType.DAY }}>
        <Form.Item
          name="name"
          label="状态"
          rules={[
            { required: true, message: '请输入状态' },
            { max: 20, message: '最多输入20个字符' },
          ]}
        >
          <Input placeholder="请输入" onChange={() => checkForm()} />
        </Form.Item>
        <Form.Item
          name="deviceType"
          label="对应设备状态"
          tooltip={{
            title: '对应设备状态，会将该天对应设备状态的时长减去自定义运行状态的时长',
            icon: <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />,
          }}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select placeholder="请选择" onChange={() => checkForm()}>
            <Select.Option value={StateType.STANDBY}>{StateTypeName[StateType.STANDBY]}</Select.Option>
            <Select.Option value={StateType.STOP}>{StateTypeName[StateType.STOP]}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="timeType" label="日期范围" rules={[{ required: true, message: '请选择' }]}>
          <Radio.Group>
            <Radio value={TimeType.DAY}>按日</Radio>
            <Radio value={TimeType.CUSTOM}>自定义日期</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="日期" dependencies={['timeType', 'deviceType', 'name']} required>
          {({ getFieldValue }) => {
            const timeType = getFieldValue('timeType');
            if (timeType === TimeType.DAY) {
              return (
                <Form.Item
                  name="dayDate"
                  dependencies={['timeType', 'deviceType', 'name']}
                  rules={[
                    { required: true, message: '请选择日期' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const deviceType = getFieldValue('deviceType');
                        const name = getFieldValue('name');
                        if (!isNil(deviceType) && !isNil(name) && !isNil(value)) {
                          const itemDayList = getDateRangeList(value, deviceType, name);
                          const conflict = dayList.some(
                            k =>
                              itemDayList.findIndex(
                                m => m.date === k.date && m.deviceType === k.deviceType && m.name === k.name
                              ) > -1
                          );
                          // 是否冲突
                          if (conflict) {
                            return Promise.reject(new Error('时间范围有冲突'));
                          }
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <DatePicker.RangePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                </Form.Item>
              );
            }
            return (
              <Form.List
                name="customTimes"
                rules={[
                  {
                    validator: async (_, values) => {
                      if (!values || !values.length) {
                        return Promise.reject(new Error('请选择日期'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item key={field.key} className={styles.formRow}>
                        <Form.Item
                          {...field}
                          dependencies={['timeType', 'deviceType', 'name']}
                          rules={[
                            {
                              required: true,
                              message: '请选择日期',
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const deviceType = getFieldValue('deviceType');
                                const name = getFieldValue('name');

                                if (!isNil(deviceType) && !isNil(name) && !isNil(value)) {
                                  // 和表格已有的比对
                                  const conflict = dayList.some(
                                    k =>
                                      dayjs(value).format('YYYY-MM-DD') === k.date &&
                                      deviceType === k.deviceType &&
                                      name === k.name
                                  );
                                  // 是否冲突
                                  if (conflict) {
                                    return Promise.reject(new Error('时间范围有冲突'));
                                  }

                                  // 和当前已有的比对
                                  const customTimes = getFieldValue('customTimes');
                                  const isExist = (customTimes ?? [])
                                    .filter((m: any, i: number) => i !== index)
                                    .some(
                                      (k: string | number | Date | dayjs.Dayjs | null | undefined) =>
                                        dayjs(value).format('YYYY-MM-DD') === dayjs(k).format('YYYY-MM-DD')
                                    );
                                  // 是否冲突
                                  if (isExist) {
                                    return Promise.reject(new Error('时间范围有冲突'));
                                  }
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined className={styles.removeIcon} onClick={() => remove(field.name)} />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} icon={<PlusOutlined />}>
                      新建
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
        <Form.Item required label="状态平均单次时长">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item name="avgTime" rules={[{ required: true, message: '请输入' }]}>
                <InputNumber min={0} max={99999999.99} style={{ width: '100%' }} placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="timeUnit" rules={[{ required: true, message: '请选择' }]}>
                <Select placeholder="请选择">
                  <Select.Option value={TimeUnitType.MIN}>min</Select.Option>
                  <Select.Option value={TimeUnitType.H}>h</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="avgCount" label="平均每天次数" rules={[{ required: true, message: '请输入' }]}>
          <InputNumber min={0} max={99999999.99} style={{ width: '100%' }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomStateModal;
