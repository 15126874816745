export enum MonitorDeviceType {
  PV = 2,
  BSA = 3,
  MICROGRIDS = 4,
  NONELECENERGYMED = 5,
  OTHERS = 6,
}
export const MonitorDeviceTypeDisplay = {
  [MonitorDeviceType.PV]: '光伏站',
  [MonitorDeviceType.BSA]: '储能站',
  [MonitorDeviceType.MICROGRIDS]: '微电网',
  [MonitorDeviceType.NONELECENERGYMED]: '非电能源介质',
  [MonitorDeviceType.OTHERS]: '其他',
};

export enum LayoutType {
  FOUR = 4,
  NINE = 9,
  SIXTEEN = 16,
}
export const LayoutTypeNameDisplay = {
  [LayoutType.FOUR]: '2X2',
  [LayoutType.NINE]: '3X3',
  [LayoutType.SIXTEEN]: '4X4',
};

export enum AccessMode {
  YSY = 1,
  HK,
}

export const AccessModeDisplay = {
  [AccessMode.YSY]: '萤石云',
  [AccessMode.HK]: '海康',
};
