import { FC, useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { Input, Select, Button, PopConfirm, EllipsisSpan, InputNumber, Form, Table } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { validateText, VehicleTypeMap } from '../../utils';
import { Rule } from 'antd/lib/form';
import { v4 as uuidv4 } from 'uuid';
import {
  apiV2CarbonEmissionFactorListPost,
  V2CarbonEmissionFactorListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash';

export type roads = {
  id: number;
  startPlace: string;
  endPlace: string;
  distance: number;
  transportation: number;
  factorId: number;
};
interface Props {
  form: FormInstance;
}
export type factoryList = {
  id?: number;
  name?: string;
  num?: string;
  unit?: string;
};

function formatNumber(v: any) {
  if (isNil(v) || v === '') return '';
  let formattedNumber = parseFloat(v).toFixed(2);
  formattedNumber = formattedNumber.replace(/\.?0+$/, '');
  return formattedNumber as any;
}

const TransportTable: FC<Props> = ({ form }) => {
  const [factory, setFactory] = useState<factoryList[]>([]);

  useEffect(() => {
    apiV2CarbonEmissionFactorListPost({}).then((res: V2CarbonEmissionFactorListPostResponse) => {
      setFactory(res?.list ?? []);
    });
  }, []);

  const factoryOptions = useMemo(() => {
    if (factory.length !== 0) {
      return factory.map((item: factoryList) => ({
        label: `${item.name}(${item.num}${item.unit})`,
        value: item.id,
      }));
    }
  }, [factory]);

  const addRow = () => {
    const newRow = {
      id: uuidv4(),
      startPlace: '',
      endPlace: '',
      distance: '',
      transportation: '',
      factorId: '',
    };

    const prevRoads = form.getFieldValue('roads') || [];
    prevRoads.push(newRow);
    form.setFieldsValue({ roads: prevRoads });
  };

  const columns: ColumnsType<roads> = [
    {
      title: '路段名称',
      render(value, record, index) {
        return <EllipsisSpan value={`路段${index + 1}`}></EllipsisSpan>;
      },
    },
    {
      title: '始发地',
      dataIndex: 'startPlace',
      render(value, record, index) {
        return (
          <Form.Item
            name={['roads', index, 'startPlace']}
            style={{ marginBottom: 0 }}
            rules={[{ message: '请输入', required: true }, validateText(50) as Rule]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
        );
      },
    },
    {
      title: '目的地',
      dataIndex: 'endPlace',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['roads', index, 'endPlace']}
              style={{ marginBottom: 0 }}
              rules={[{ message: '请输入', required: true }, validateText(50) as Rule]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '距离（km）',
      dataIndex: 'distance',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['roads', index, 'distance']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入数字', required: true },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('距离必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber formatter={formatNumber} placeholder="请输入" style={{ width: '100%' }}></InputNumber>
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '交通工具',
      dataIndex: 'transportation',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['roads', index, 'transportation']}
              style={{ marginBottom: 0 }}
              rules={[{ message: '请选择', required: true }]}
            >
              <Select
                placeholder={'请选择'}
                options={Object.entries(VehicleTypeMap).map(([k, v]) => ({
                  label: v,
                  value: +k,
                }))}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '碳排放因子',
      dataIndex: 'factorId',
      render(value, record, index) {
        return (
          <>
            <Form.Item
              name={['roads', index, 'factorId']}
              style={{ marginBottom: 0 }}
              rules={[{ message: '请选择', required: true }]}
            >
              <Select placeholder={'请选择'} options={factoryOptions} />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '操作',
      ellipsis: true,
      render(value, record, index) {
        return (
          <>
            {index !== 0 && (
              <PopConfirm
                placement="top"
                title={'您是否确认删除?'}
                onConfirm={() => {
                  const prevRoads = form.getFieldValue('roads');
                  const removeId = prevRoads.findIndex((item: factoryList) => {
                    return item.id === record.id;
                  });
                  prevRoads.splice(removeId, 1);
                  form.setFieldsValue({ roads: prevRoads });
                }}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.content}>
      <Form.Item labelAlign="left" name="roads" style={{ marginBottom: 0 }} valuePropName="dataSource">
        <Table rowKey="id" columns={columns} pagination={false} />
      </Form.Item>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        wrapClassName={styles.addRowBtn}
        style={{ width: '100%' }}
        onClick={() => {
          addRow();
        }}
      >
        新增一行数据
      </Button>
    </div>
  );
};

export default TransportTable;
