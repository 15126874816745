import {
  V2EnergyUnitGetEnergyMediumByIdsPostResponse,
  V2OutputProductCategoryListPostResponse,
  V2OutputProductGetListByCategoryUnitWorkCenterPostResponse,
  V2OutputProductUnitsPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { TreeNode } from '@/pages/OutputConfig/utils';

export interface OptionItem {
  label: string;
  value: number;
  [x: string]: any;
}

export type MapList = Record<number, any>;

export interface OutputDataType {
  id: string;
}

export interface DeviceDataType {
  id: string;
}

export interface LineDeviceItem {
  id?: number;
  name?: string;
}

export const formatTreeData = (data: V2OutputProductCategoryListPostResponse['children']): TreeNode[] => {
  return (
    data?.map((item: any) => {
      const res = {
        key: item.id!,
        value: item.id,
        title: item.name,
        children: formatTreeData(item.children),
        disabled: item.id === 0,
      };
      return res;
    }) || []
  );
};

export type EnergyMediumItem = Exclude<V2EnergyUnitGetEnergyMediumByIdsPostResponse['list'], undefined>[number];

export type EnergyUnitItem = Exclude<V2OutputProductUnitsPostResponse['list'], undefined>[number];

export type OuputItem = Exclude<V2OutputProductGetListByCategoryUnitWorkCenterPostResponse['list'], undefined>[number];

export interface TabItem {
  title: string;
  key: string;
}

export interface ConfigItem extends TabItem {
  id?: number;
  appAnalysisSubscriptionId?: number;
  ouIds?: number[];
  deviceIds?: number[];
  workCenterIds?: number[];
  productionBaseId?: number;
  outputList?: OutputItem[];
  deviceList?: DeviceItem[];
  lineDevices?: {
    id?: number;
    name?: string;
  }[];
}

export interface OutputItem {
  id?: number;
  outputProductCategoryId?: number;
  outputTypeName?: string;
  unitCode?: string;
  unitTypeCode?: number;
  outputIds?: number[];
}

export interface DeviceItem {
  id?: number;
  deviceTypeName?: string;
  energyMediumId?: number;
  sequenceNum?: number;
  energyUnitIds?: number[];
}

export interface CompareConfigItem {
  title: string;
  id?: number;
  appAnalysisSubscriptionId?: number;
  ouIds?: number[];
  deviceIds?: number[];
  workCenterIds?: number[];
  productionBaseId?: number;
  outputList?: OutputItem[];
  deviceList?: DeviceItem[];
}
