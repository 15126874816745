import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

export interface SaveCarbonTaskBody {
  id?: Key;
  taskName?: string; // 盘查名称
  accountingUnitId?: Key; // 核算单元id
  inventoryYear?: number; // 盘查周期(年)
  state?: CarbonTaskState; // 状态(2填报中 3已结束)
}

// 保存或者更新盘查任务
export const saveCarbonTask = (body: SaveCarbonTaskBody) =>
  fetch(
    '/api/v2/carbon/inventory-task/save',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

export enum CarbonTaskState {
  FILLING = 2, // 填报中
  FINISHED = 3, // 已结束
}

export const CarbonTaskStateDisplay = {
  [CarbonTaskState.FILLING]: '填报中',
  [CarbonTaskState.FINISHED]: '已结束',
};

// 盘查任务列表
export interface CarbonTaskPageRes {
  id: Key;
  taskName: string; // 盘查名称
  accountingUnitId: Key; // 核算单元id
  accountingUnitName: string; // 核算单元名称
  inventoryYear: number; // 盘查周期(年)
  inventoryNum: number; // 盘查完成数
  state: CarbonTaskState; // 状态(1填报中 2已结束)
}

export interface CarbonTaskPageQuery extends PageRequest {
  taskName?: string; // 任务名称
  accountingUnitId?: string; // 核算单元id
}

export const getCarbonTaskPage = (query: CarbonTaskPageQuery) =>
  fetch<PageResponse<CarbonTaskPageRes>>(
    `/api/v2/carbon/inventory-task/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

// 详情
export const getCarbonTaskDetail = (id: Key) =>
  fetch<CarbonTaskPageRes>(
    `/api/v2/carbon/inventory-task/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 更新盘查任务状态
export const updateCarbonTaskState = (id: Key, state: CarbonTaskState) =>
  fetch(
    `/api/v2/carbon/inventory-task/updateState`,
    {
      method: 'POST',
      body: JSON.stringify({ id, state }),
    },
    true
  );
