import {
  Button,
  EllipsisSpan,
  Key,
  Paging,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  DatePicker,
  Form,
  Input,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import CreateAnalysis from './components/CreateAnalysis';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import DetailAnalysis from './components/DetailAnalysis';
import {
  V2EnergyOptimizationProjectPagePostResponse,
  apiV2EnergyOptimizationProjectDeletePost,
  apiV2EnergyOptimizationProjectPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

const DATE_FORMAT = 'YYYY-MM-DD';

const columns = [
  {
    title: '优化项目',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    fixed: 'left' as 'left',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '起始时间',
    dataIndex: 'optimizationStartTime',
    ellipsis: { showTitle: true },
    fixed: 'left' as 'left',
    render: (v: string, record: EnergyAnalysisOptimizePageRes) => (
      <EllipsisSpan
        value={`${dayjs(record.optimizationStartTime).format(DATE_FORMAT)} 至 ${dayjs(
          record.optimizationEndTime
        ).format(DATE_FORMAT)}`}
      />
    ),
  },
  {
    title: '收益计量单元',
    dataIndex: 'energyUnits',
    ellipsis: { showTitle: true },
    render: (v: Array<{ id: Key; name: string }>) => <EllipsisSpan value={(v ?? []).map(i => i.name).join('、')} />,
  },
  {
    title: '优化用能项',
    dataIndex: 'energyMediums',
    ellipsis: { showTitle: true },
    render: (v: Array<{ id: Key; name: string }>) => <EllipsisSpan value={(v ?? []).map(i => i.name).join('、')} />,
  },
  {
    title: '节能率',
    dataIndex: 'energySavingsRate',
    ellipsis: { showTitle: true },
    render: (v: number) => {
      return (
        <EllipsisSpan
          className={v === 0 || isNil(v) ? undefined : v > 0 ? styles.pulsGreen : styles.minusRed}
          value={`${isNil(v) ? '--' : (v * 100).toFixed(2)}%`}
        />
      );
    },
  },
];

interface SearchParams {
  name?: string;
  time?: [Dayjs, Dayjs];
}

export type EnergyAnalysisOptimizePageRes = Exclude<
  V2EnergyOptimizationProjectPagePostResponse['list'],
  undefined
>[number];

const EnergyAnalysisOptimize = () => {
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [data, setData] = useState<EnergyAnalysisOptimizePageRes[]>([]);
  const [currentDetail, setCurrentDetail] = useState<EnergyAnalysisOptimizePageRes>();
  const [update, updateFn] = useUpdate();

  const hasAdd = useHasPermission(PermissionsType.B_OPTIMIZEPROJECTCREATE);
  const hasEdit = useHasPermission(PermissionsType.B_OPTIMIZEPROJECTEDIT);
  const hasDel = useHasPermission(PermissionsType.B_OPTIMIZEPROJECTDELETE);

  useEffect(() => {
    setLoading(true);
    apiV2EnergyOptimizationProjectPagePost({
      name: searchParams.name === '' ? undefined : searchParams.name,
      optimizationStartTime: searchParams.time?.[0]?.format(DATE_FORMAT),
      optimizationEndTime: searchParams.time?.[1]?.format(DATE_FORMAT),
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  // 删除
  const delBtn = (record: EnergyAnalysisOptimizePageRes) => {
    Modal.confirm({
      // title: '确认删除',
      content: `确认是否删除优化项目【${record.name}】及相关数据？`,
      onOk: () => {
        apiV2EnergyOptimizationProjectDeletePost({ energyOptimizationProjectId: record.id }).then(() => {
          updateFn();
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 150,
      render: (record: EnergyAnalysisOptimizePageRes) => (
        <Space size={16}>
          {hasEdit && (
            <Button
              type="link"
              onClick={() => {
                setIsEdit(true);
                setModalOpen(true);
                setCurrentDetail(record);
              }}
            >
              编辑
            </Button>
          )}

          <Button
            type="link"
            onClick={() => {
              setDetailModalOpen(true);
              setCurrentDetail(record);
            }}
          >
            查看
          </Button>
          {hasDel && (
            <Button type="link" onClick={() => delBtn(record)}>
              删除
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish({ ...v });
  };

  const filters = (
    <CustomFilter form={form} onFinish={(v: SearchParams) => onFinish(v)}>
      <Form.Item name="name" label="优化项目">
        <Input
          placeholder="请输入"
          onBlur={() => {
            form.submit();
          }}
          allowClear
        />
      </Form.Item>
      <Form.Item name="time" label="优化时间">
        <DatePicker.RangePicker
          style={{ width: '100%' }}
          onChange={() => {
            form.submit();
          }}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
      <Space>
        {hasAdd && (
          <Button
            type="primary"
            wrapStyle={{ marginBottom: 10 }}
            icon={<PlusOutlined />}
            onClick={() => {
              setIsEdit(false);
              setModalOpen(true);
            }}
          >
            新建优化项目
          </Button>
        )}
      </Space>
      <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
      {modalOpen && (
        <CreateAnalysis
          currentDetail={currentDetail}
          setCurrentDetail={setCurrentDetail}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          updateFn={updateFn}
        />
      )}
      {detailModalOpen && (
        <DetailAnalysis
          currentDetail={currentDetail}
          setCurrentDetail={setCurrentDetail}
          modalOpen={detailModalOpen}
          setModalOpen={setDetailModalOpen}
        />
      )}
    </Wrapper>
  );
};

export default EnergyAnalysisOptimize;
