import { Input, Modal, Form } from '@maxtropy/components';
import {
  apiV2CarbonFootprintSupplierAddPost,
  apiV2CarbonFootprintSupplierUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import React, { useEffect } from 'react';
import { isNil } from 'lodash-es';
import { SupplierItem } from '..';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedSupplier?: SupplierItem;
  updateData: () => void;
}

const AddSupplierModal: React.FC<Props> = ({ open, selectedSupplier, setOpen, updateData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNil(selectedSupplier)) {
      form.setFieldsValue({
        name: selectedSupplier.name,
        code: selectedSupplier.code,
        address: selectedSupplier.address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplier]);

  const onFinish = (v: any) => {
    if (isNil(selectedSupplier?.id)) {
      apiV2CarbonFootprintSupplierAddPost({
        ...v,
      }).then(res => {
        setOpen(false);
        updateData?.();
      });
    } else {
      apiV2CarbonFootprintSupplierUpdatePost({
        id: selectedSupplier?.id,
        ...v,
      }).then(res => {
        setOpen(false);
        updateData?.();
      });
    }
  };

  return (
    <Modal
      title={isNil(selectedSupplier) ? '录入供应商' : '编辑供应商'}
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
    >
      <div style={{ padding: '0 48px' }}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="供应商编号"
            name="code"
            rules={[
              {
                required: true,
                message: '请输入供应商编号',
              },
              {
                pattern: /^[A-Za-z0-9_]+$/,
                message: '仅支持数字、字母、下划线',
              },
              { max: 50, message: '最多输入50个字符' },
            ]}
          >
            <Input disabled={!isNil(selectedSupplier?.code)} />
          </Form.Item>
          <Form.Item
            label="供应商名称"
            name="name"
            rules={[
              {
                required: true,
                message: '请输入供应商名称',
              },
              { max: 50, message: '最多输入50个字符' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="地址" name="address" rules={[{ max: 200, message: '最多输入200个字符' }]}>
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddSupplierModal;
