import qs from 'qs';
import { PageRequest, PageResponse } from './page';
import { fetch, Key } from '@maxtropy/components';
import { ElectricitySettlementRulesResponse } from './electricitySettlementRules';

/**
 * 销售合同
 */

// 请求的数据类型
interface ElectricitySaleContractRequest {
  saleClientId: number; // 销售客户id
  name: string; // 合同名称
  address: string; // 位置
  status: ElectricitySaleContractStatusType; // 合同状态
  startTime: string; // 生效开始时间
  endTime: string; // 生效结束时间
  saleElectricitySettlementRuleId: string; // 结算规则id
}

// 返回的数据类型
export interface ElectricitySaleContractVo extends ElectricitySaleContractRequest {
  customerMcid: string | number; // 关联组织id
  customerName: string; //组织名称
  segmentAccountNumber: string | number; // 段户号
  propertyManagementConfigId: number; // 物业管理ID
  propertyManagementConfigName: string; //物业名称
  archiveCode: string; // 存档合同编号
  saleClientName: string; // 客户名称
  settlementDay: string; // 结算时间 日
  settlementHour: string; // 结算时间 时
  settlementCycleType: number; // 周期频次
  meterListData?: ElectricitySaleContractMeterType[];
  id: number; // 唯一标识
  code: string; // 合同编号
  saleClientAccountName: string; // 客户账户
  saleClientAccountId: string; // 客户账户ID
  // taxPoint: ElectricitySaleContractTaxPointType; // 税点
  // settlementType: ElectricitySaleContractSettlementType; // 结算类型
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
  createByUserId: string; // 创建人id
  updateByUserId: string; // 修改人id
  updateByUsername: string; // 修改人
  updateSource: number; // 修改端
  deleteFlag: boolean; // 删除标记
  useCustomizeBillTitle: boolean;
  billTitle: string;
}

// 结算类型的枚举类型
// export enum ElectricitySaleContractSettlementType {
//   UNITARYSYSTEM = 1, // 单一制
// }

// 结算类型的枚举类型的值
// export const ElectricitySaleContractSettlement = {
//   [ElectricitySaleContractSettlementType.UNITARYSYSTEM]: '单一制',
// }

// 状态的枚举类型
export enum ElectricitySaleContractStatusType {
  INEFFECTIVE = 1, // 未生效
  EFFECTIVE = 2, // 生效中
  EXPIRED = 3, // 已过期
  ABORTED = 4, // 已终止
}

// 状态的枚举类型的值
export const ElectricitySaleContractStatus = {
  [ElectricitySaleContractStatusType.INEFFECTIVE]: '未生效',
  [ElectricitySaleContractStatusType.EFFECTIVE]: '生效中',
  [ElectricitySaleContractStatusType.EXPIRED]: '已过期',
  [ElectricitySaleContractStatusType.ABORTED]: '已终止',
};

// 税点的枚举类型
// export enum ElectricitySaleContractTaxPointType {
//   ONEPERCENT = 1,
//   THREEPERCENT = 2,
//   THIRTEENPERCENT = 3
// }

// 税点的枚举类型的值
// export const ElectricitySaleContractTaxPoint = {
//   [ElectricitySaleContractTaxPointType.ONEPERCENT]: '1%',
//   [ElectricitySaleContractTaxPointType.THREEPERCENT]: '3%',
//   [ElectricitySaleContractTaxPointType.THIRTEENPERCENT]: '13%',
// }

export interface ElectricitySaleContractPageSearchRequest extends PageRequest {
  saleClientId?: string; // 销售客户id
  name?: string; // 合同名称
  address?: string; // 位置
  status?: ElectricitySaleContractStatusType; // 合同状态
  startTime?: string; // 开始时间
  endTime?: string; // 结束时间
  saleElectricitySettlementRuleId?: string; // 结算规则id
  archiveCode?: number; //存档编号
  propertyManagementConfigId?: string | number; // 物业管理
  customer_mcid?: string | number; // 关联组织
  code?: string; // 合同编号
}

// filter 模糊搜索
export interface ElectricitySaleContractPageFilterRequest {
  saleClientId?: string; // 销售客户id
  name?: string; // 合同名称
  address?: string; // 位置
  status?: ElectricitySaleContractStatusType; // 合同状态
  startTime?: string; // 开始时间
  endTime?: string; // 结束时间
  saleElectricitySettlementRuleId?: string; // 结算规则id
}

// mock数据请求，查询销售合同列表
export function getElectricitySalesContractList(params: ElectricitySaleContractPageSearchRequest) {
  return fetch<PageResponse<ElectricitySaleContractVo>>(
    `/api/sale-electricity/contract/page?${qs.stringify(params, { indices: false })}`
  );
}

// 获取销售合同详情
export function getElectricitySaleContractById(id: Key): Promise<ElectricitySaleContractVo> {
  return fetch(`/api/sale-electricity/contract/${id}`);
}

// 客户名称定义 （客户有接口，所以这里废弃了）
// export interface CustomerType {
//   id?: number; // 唯一标识
//   name?: string; // 名称
//   code?: string; // 编号
//   ould?: number; // 运营单元id
//   ouName?: string; // 运营单元名称
//   companyName?: string; // 企业名称
//   uscc?: string; // 统一信用代码
//   meterType?: number; // 计量表计类型（0：电量计量表计）
//   createByUserId?: string; // 操作人
//   updateByUserId?: string; // 修改人
//   updateByUsername?: string; // 最后操作人
//   updateSource?: number; // 修改端(0:运管，1:租户)
//   createTime?: string; // 创建时间
//   updateTime?: string; // 修改时间
//   deleteFlag?: boolean; // 删除标记
//   electricityAccounts?: Object[]; // 用电账户
//   contacts?: Object[]; // 联系人
// }

// // 查询销售合同列表搜索栏中客户名称
// export function getCustomerName() {
//   return fetch<CustomerType[]>(`/api/sale/client/list`);
// }

// 生效表计类型
export interface ElectricitySaleContractMeterType {
  id?: number;
  saleElectricityContractId?: number; // 销售用电合同id
  saleElectricityMeterId?: number; // 销售用电表计id
  deviceId?: number; // 设备id
  electricityAccountId?: number; // 用电账户id
  startTime?: string; // 开始时间
  endTime?: string; // 结束时间
  meterTags?: string[]; // 表计标签
}

// 获取销售合同表计列表
export function getElectricitySaleContractMeterList(id: Key, meterType: number) {
  return fetch<ElectricitySaleContractMeterType[]>(
    `/api/sale-electricity/contract/meter/${id}?${qs.stringify({ meterType })}`
  );
}

// 获取销售合同结算规则列表
export function getElectricitySaleContractSettlementRuleList(id: string) {
  return fetch<{ list: ElectricitySettlementRulesResponse[] }>(
    `/api/v2/sale-electricity/settlement-rule/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 终止合同
export function abortSaleElectricityContract(saleElectricityContractId: string | number): Promise<boolean> {
  return fetch(`/api/sale-electricity/contract/abort/${saleElectricityContractId}`, {
    method: 'PUT',
  });
}

// 延长有效期
export function extendSaleElectricityContract(
  saleElectricityContractId: string | number,
  params: { endTime: string }
): Promise<boolean> {
  return fetch(
    `/api/sale-electricity/contract/extend/${saleElectricityContractId}?${qs.stringify(params, { indices: false })}`,
    {
      method: 'PUT',
      // body: JSON.stringify(params)
    }
  );
}

export interface SaleContactMeterPageRequest extends PageRequest {
  electricityAccountId?: number;
  deviceTag?: string;
  saleClientId: number;
  saleElectricityContractId?: string;
}

export interface SaleContactMeterResponse {
  id: number;
  deviceId: number;
  deviceCode: string;
  deviceName: string;
  deviceSn: string;
  deviceTags: Array<string>;
  electricityAccountId: number;
  electricityAccountNumber: string;
  meterType: number;
}

export function getSalesContractMeterPage(params: SaleContactMeterPageRequest) {
  return fetch<PageResponse<SaleContactMeterResponse>>(
    `/api/v2/sale-electricity/meter/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface MeterFormValueRequest {
  id: number;
  startTime: string;
  endTime: string;
}

export interface CreateSalesContractRequest extends UpdateSalesContractRequest {
  startTime: string;
  endTime: string;
  saleClientId: number;
  propertyManagementConfigId: number;
  customerMcid: string;
  saleClientAccountId: number;
}

export function createSalesContract(params: CreateSalesContractRequest): Promise<void> {
  return fetch(`/api/sale-electricity/contract`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export interface UpdateSalesContractRequest {
  name: string;
  meters: Array<MeterFormValueRequest>;
  address: string;
  settlementCycleType: number;
  settlementTime?: string;
  settlementHour: string;
  settlementDay?: string;
  useCustomizeBillTitle?: boolean;
  billTitle?: string;
  segmentAccountNumber: string;
  archiveCode: string;
}

export function updateSalesContract(
  saleElectricityContractId: number,
  params: UpdateSalesContractRequest
): Promise<void> {
  return fetch(`/api/sale-electricity/contract/${saleElectricityContractId}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export interface PropertyItem {
  id: number | string;
  customerMcid: string; // 关联组织
  code: string; // 编号
  salesOrg: string; // 销售单位
  invoiceAddress: string; // 开票地址
  taxId: string; // 纳税号
  beneficialBank: string; // 开户行
  bankAccount: string; // 账号
  businessScope: string; // 业务范围 逗号分割
  createTime: string;
  updateTime: string;
}

export interface SaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientIdRequest {
  propertyManagementConfigId?: Key;
  ouId?: Key;
  saleClientId?: Key;
}

export function getPropertyList(customer_mcid: Key[]) {
  return fetch<PropertyItem[]>(
    `/api/sale-electricity/contract/property-management-configs/list?${qs.stringify(
      { customerMcids: customer_mcid },
      { indices: false }
    )}`,
    {
      method: 'GET',
    }
  );
}

// 根据物业id，ouid，客户（saleClientId）查找合同列表
export function getSaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientId(
  request: SaleElectricityContractListByPropertyManagementConfigIdOrOuIdOrSaleClientIdRequest
) {
  return fetch<ElectricitySaleContractVo[]>(
    `/api/sale-electricity/contract/list-by-pmcid?${qs.stringify(request, { indices: false })}`
  );
}

export interface TreeSelectItem {
  disabled: boolean;
  halfChecked: string[] | undefined;
  label: string | undefined;
  value: string | undefined;
}

export interface ClientAccountItem {
  id: number;
  code: string;
  name: string;
}

// 查询客户账户
export function getClientAccountList(saleClientId: Key) {
  return fetch<{ list: ClientAccountItem[] }>(
    `/api/v2/sale/client/account/all/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id: saleClientId }),
    },
    true
  );
}

// 查询具备组织数据权限下的所有物业
export function getPropertyListOwned() {
  return fetch<PropertyItem[]>('/api/sale-electricity/contract/property-management-configs/list/owned', {
    method: 'GET',
  });
}
