import {
  FillingStatus,
  FillingStatusMap,
  FillingStatusTagColorMap,
  ListType,
} from '@/pages/CarbonEmissionReporting/List/components/List/listtypes';
import {
  EllipsisSpan,
  Paging,
  Button,
  DatePicker,
  Form,
  Select,
  Table,
  Tag,
  usePaging,
  CustomFilter,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import React, { useState } from 'react';

import { CarbonEmissionFillingPagePostRequest } from '@maxtropy/device-customer-apis';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import qs from 'qs';
import {
  apiV2CarbonEmissionFillingPagePost,
  V2CarbonEmissionFillingPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';

const otherColumns = [
  {
    title: '核算周期',
    ellipsis: { showTitle: true },
    render: (record: ListType) => <EllipsisSpan value={`${record?.emissionDate?.slice(0, 7)}`} />,
  },
  {
    title: '所属盘查任务',
    dataIndex: 'inventoryTaskName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: FillingStatus) => (
      <Tag color={FillingStatusTagColorMap[v]} border="solid">
        {FillingStatusMap[v]}
      </Tag>
    ),
  },
];

interface Props {
  selectedaccountingUnitId: string | undefined;
  treeRequestStatus: boolean;
}
const List: React.FC<Props> = ({ selectedaccountingUnitId, treeRequestStatus }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<CarbonEmissionFillingPagePostRequest>();
  const [list, setList] = useState<V2CarbonEmissionFillingPagePostResponse['list']>();

  const onFinish = (values: CarbonEmissionFillingPagePostRequest) => {
    const filteredData = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== undefined && v !== ''));
    if (filteredData?.fillingYear) {
      filteredData.fillingYear = dayjs(filteredData.fillingYear).format('YYYY');
    }
    setSearchParams(filteredData);
    setPageOffset(1);
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const { loading } = useRequest(
    () => {
      if (!treeRequestStatus) {
        throw new Error('treeRequestStatus is false');
      }
      return apiV2CarbonEmissionFillingPagePost({
        page: pageOffset.toString(),
        size: pageSize.toString(),
        accountingUnitId: selectedaccountingUnitId,
        ...searchParams,
      });
    },
    {
      onSuccess: res => {
        setTotalCount(res.total as number);
        setList(res.list);
      },
      debounceWait: 300,
      refreshDeps: [pageOffset, pageSize, searchParams, selectedaccountingUnitId, setTotalCount],
    }
  );

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      width: 150,
      ellipsis: { showTitle: true },
      render: (record: ListType) => (
        <Button
          type="link"
          disabled={record.state === FillingStatus.Finished}
          onClick={() => {
            navigate(
              `/leanCarbonManagement/carbonEmissionInventory/carbonEmissionReporting/edit/${record.id}?${qs.stringify({
                accountingUnitId: selectedaccountingUnitId,
              })}`
            );
          }}
        >
          填报
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className={styles.list}>
        <div className={styles.filterWrapper}>
          <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
            <Form.Item label="状态" name="state">
              <Select
                allowClear
                placeholder="请选择"
                options={Object.entries(FillingStatusMap).map(([key, value]) => ({ label: value, value: key }))}
              />
            </Form.Item>
            <Form.Item label="核算周期" name="fillingYear">
              <DatePicker picker="year" style={{ width: '100%' }} />
            </Form.Item>
          </CustomFilter>
        </div>

        <div className={styles.tableWrapper}>
          <Table rowKey="id" columns={columns} dataSource={list} loading={loading} pagination={false} />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </div>
    </>
  );
};

export default List;
