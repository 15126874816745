import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  initVideoPlugin,
  stopVideoPlay,
  clickStopRealPlay,
  clickFullScreen,
  clickCapturePic,
  clickOpenSound,
  clickStartRecord,
  clickStopRecord,
  clickStartRealPlay,
  clickSetVolume,
  clickEnableEZoom,
  mouseDownPTZControl,
  clickRecordSearch,
  clickStartDownloadRecord,
  clickStopPlayback,
  clickStartPlayback,
  clickPause,
  clickCloseSound,
  clickDisableEZoom,
} from './util';
import { Button, DatePicker, Form, message, Slider, Tooltip } from '@maxtropy/components';
import {
  timeOut,
  playCircle,
  camera,
  sound,
  mute,
  control,
  videoSvg,
  zoomin,
  history,
  expend,
  caretDown,
  plusCircle,
  minusCircle,
  caretUp,
  caretLeft,
  caretRight,
} from '../../svg';
import styles from './index.module.scss';

enum ToolbarControl {
  Play = 0,
  Capture,
  Sound,
  Pantile,
  Record,
  Zoom,
  PlayBack,
}

export const ToolbarControlDisplay = {
  [ToolbarControl.Play]: '播放',
  [ToolbarControl.Capture]: '截图',
  [ToolbarControl.Sound]: '声音',
  [ToolbarControl.Pantile]: '云台控制',
  [ToolbarControl.Record]: '录屏',
  [ToolbarControl.Zoom]: '电子放大',
  [ToolbarControl.PlayBack]: '历史回放',
};

interface RenderIconProp {
  type: ToolbarControl;
  name?: string;
  component: any;
  className: string;
  onClick: () => void;
}

interface Iprops {
  domId?: string;
  width?: number | string;
  height?: number | string;
  leftText?: React.ReactNode;
  rightText?: React.ReactNode;
  showTools?: boolean;
  showFooter?: boolean;
  enableFeatureList?: string[];
  monitorLocation?: string;
  cameraUsername?: string;
  cameraPassword?: string;
  ipAddress?: string;
  port?: number;
  cameraPort?: number;
  rtspPort?: number;
  g_iWndIndex?: number;
}

export default forwardRef(function VideoPlayer() {
  const [videoInfo, setVideoInfo] = useState<Iprops>({});
  const [isRecording, setIsRecording] = useState<boolean>(false); // 是否在录像
  const [isPlaying, setIsPlaying] = useState<boolean>(true); // 是否播放
  const [showVolume, setShowVolume] = useState<boolean>(false); // 是否显示音量配置
  const [volume, setVolume] = useState<number>(0); // 音量
  const [showControl, setShowControl] = useState<boolean>(false); // 是否显示云台控制
  const [showPlayblack, setShowPlayblack] = useState<boolean>(false); // 是否显示录像回放
  const [isZoomIn, setIsZoomIn] = useState<boolean>(false); // 是否放大
  const [szDeviceIdentify, setSzDeviceIdentify] = useState<string>(); // 设备标识
  const [isDisabled, setDisabled] = useState(true);
  const [channelIds, setChannelIds] = useState([]); // 通道
  const [size, setSize] = useState<{ width?: number | string; height?: number | string }>({});
  const [isInit, setInit] = useState<boolean>(true);
  const [showBackMark, setShowBackMark] = useState<boolean>(false);
  const [searchSuccess, setSearchSuccess] = useState<boolean>(false);
  const [isAutoControl, setAutoControl] = useState<boolean>(false); // 云台自动控制

  const [form] = Form.useForm();

  useEffect(() => {
    const func = ({ data }: { data: Iprops }) => {
      if (data.domId) {
        setSize({ width: data.width, height: data.height });
        setVideoInfo(data);
      }
    };

    window.addEventListener('message', func);

    return () => {
      window.removeEventListener('message', func);
    };
  }, []);

  const resizeFunc = () => {
    setSize({ width: window.innerWidth, height: videoInfo.showTools ? videoInfo.height : window.innerHeight - 40 });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeFunc);

    return () => {
      window.removeEventListener('resize', resizeFunc);
    };
  }, [videoInfo]);

  useEffect(() => {
    setSzDeviceIdentify(`${videoInfo.ipAddress}_${videoInfo.port}`);
  }, [videoInfo]);

  useEffect(() => {
    if (
      isInit &&
      size.width &&
      size.height &&
      videoInfo.ipAddress &&
      videoInfo.port &&
      videoInfo.cameraUsername &&
      videoInfo.cameraPassword &&
      videoInfo.rtspPort
    ) {
      //初始化摄像头
      const videoInitInfo = {
        width: size.width,
        height: size.height,
        szIP: videoInfo.ipAddress,
        szPort: videoInfo.port,
        rtspPort: videoInfo.rtspPort,
        domId: videoInfo.domId,
        szDeviceIdentify: `${videoInfo.ipAddress}_${videoInfo.port}`,
        szUsername: videoInfo.cameraUsername,
        szPassword: videoInfo.cameraPassword,
      };

      initVideoPlugin(videoInitInfo, setChannelIds, setDisabled, setInit);
    }
  }, [videoInfo, size, isInit]);

  useEffect(() => {
    showVolume && clickSetVolume(volume);
  }, [volume, showVolume]);

  const onSearch = () => {
    const { startTime, endTime } = form.getFieldsValue();

    if (!startTime) return message.error('请选择开始时间');
    if (!endTime) return message.error('请选择结束时间');

    if (dayjs(startTime) > dayjs(endTime)) return message.error('开始时间大于结束时间');

    clickRecordSearch(
      0,
      szDeviceIdentify,
      channelIds,
      dayjs(startTime).format('YYYY-MM-DD HH:mm:ss'),
      dayjs(endTime).format('YYYY-MM-DD HH:mm:ss'),
      setSearchSuccess
    );
  };

  const RenderIcon = ({ type, name, component, className, onClick }: RenderIconProp) => {
    return ['0', ...(videoInfo.enableFeatureList || [])]?.includes(String(type)) ? (
      <Tooltip title={name || ToolbarControlDisplay[type]}>
        <Icon component={component} className={`${styles.videoControlIcon} ${className}`} onClick={onClick} />
      </Tooltip>
    ) : null;
  };

  return (
    <div style={{ width: size.width, height: size.height }} className={styles.videoBoxItem}>
      {videoInfo.ipAddress && videoInfo.port && videoInfo.cameraUsername && videoInfo.cameraPassword && (
        <>
          {videoInfo.showTools && (
            <div className={styles.videoBaseInfo}>
              <div id="deviceInfo" className={styles.deviceInfo} />
              <div>{videoInfo.monitorLocation}</div>
            </div>
          )}
          <div id={videoInfo.domId} style={{ width: size.width, height: size.height }}></div>
          {showBackMark && (
            <div className={styles.backMark}>
              <Icon component={history} style={{ width: 12 }} />
              历史回放
            </div>
          )}
        </>
      )}
      {isInit && <div className={styles.loading_block}>正在加载</div>}
      {!isInit && isDisabled && <div className={styles.err_block}>加载失败</div>}
      {videoInfo.showTools && (
        <>
          <div className={styles.toolBar} style={{ width: size.width }}>
            <RenderIcon
              type={ToolbarControl.Play}
              name={isPlaying ? '暂停' : '播放'}
              className={`${(isDisabled || showPlayblack) && styles.disabled}`}
              component={isPlaying ? timeOut : playCircle}
              onClick={() => {
                if (!isDisabled && !showPlayblack) {
                  isPlaying
                    ? clickStopRealPlay()
                    : clickStartRealPlay(
                        {
                          szDeviceIdentify,
                          rtspPort: videoInfo.rtspPort,
                          domId: videoInfo.domId,
                          iChannelID: channelIds?.[0],
                        },
                        setDisabled
                      );
                  setIsPlaying(!isPlaying);
                }
              }}
            />
            <RenderIcon
              type={ToolbarControl.Capture}
              component={camera}
              className={`${(isDisabled || !isPlaying || showPlayblack) && styles.disabled}`}
              onClick={() => {
                !isDisabled && !showPlayblack && clickCapturePic();
              }}
            />
            <div className={styles.soundBox}>
              {showVolume && (
                <div className={styles.volumeBox}>
                  {volume}
                  <Icon
                    component={plusCircle}
                    onClick={() => {
                      if (volume + 5 > 100) {
                        setVolume(100);
                      }
                      setVolume(volume + 5);
                    }}
                  />
                  <Slider vertical value={volume} tooltip={{ open: false }} onChange={value => setVolume(value)} />
                  <Icon component={minusCircle} onClick={() => setVolume(volume - 5)} />
                </div>
              )}
              <RenderIcon
                type={ToolbarControl.Sound}
                className={`${(isDisabled || !isPlaying || showPlayblack) && styles.disabled} ${
                  showVolume && styles.active
                }`}
                component={volume > 0 ? sound : mute}
                onClick={() => {
                  if (!isDisabled && !showPlayblack) {
                    volume > 0 ? clickOpenSound(volume) : clickCloseSound();
                    setShowVolume(!showVolume);
                  }
                }}
              />
            </div>
            <RenderIcon
              type={ToolbarControl.Pantile}
              className={`${(isDisabled || !isPlaying || showPlayblack) && styles.disabled} ${
                showControl && styles.active
              }`}
              component={control}
              onClick={() => {
                !isDisabled && !showPlayblack && setShowControl(!showControl);
              }}
            />
            <RenderIcon
              type={ToolbarControl.Record}
              className={` ${(isDisabled || !isPlaying || showPlayblack) && styles.disabled} ${
                isRecording && styles.active
              }`}
              component={videoSvg}
              onClick={() => {
                if (!isDisabled && !showPlayblack) {
                  isRecording ? clickStopRecord() : clickStartRecord();
                  setIsRecording(!isRecording);
                }
              }}
            />
            <RenderIcon
              type={ToolbarControl.Zoom}
              className={`${(isDisabled || !isPlaying || showPlayblack) && styles.disabled} ${
                isZoomIn && styles.active
              }`}
              component={zoomin}
              onClick={() => {
                if (!isDisabled && !showPlayblack) {
                  isZoomIn ? clickDisableEZoom() : clickEnableEZoom();
                  setIsZoomIn(!isZoomIn);
                }
              }}
            />
            <RenderIcon
              type={ToolbarControl.PlayBack}
              className={`${isDisabled && styles.disabled} ${showPlayblack && styles.active}`}
              component={history}
              onClick={() => {
                !isDisabled && setShowPlayblack(!showPlayblack);
              }}
            />
            <Tooltip title="全屏">
              <Icon
                component={expend}
                className={`${styles.rightIcon} ${(isDisabled || showPlayblack) && styles.disabled}`}
                onClick={() => {
                  !isDisabled && !showPlayblack && clickFullScreen();
                }}
              />
            </Tooltip>
            {showControl && (
              <div className={styles.controlBox}>
                <div className={styles.controlOuter}>
                  <div
                    className={`${styles.controlInner} ${isAutoControl && styles.active}`}
                    onClick={() => mouseDownPTZControl(9, setAutoControl)}
                  >
                    自动
                  </div>
                </div>
                <div
                  className={`${styles.dirBox} ${styles.upBox}`}
                  onClick={() => mouseDownPTZControl(1, setAutoControl)}
                >
                  <Icon component={caretUp} />
                </div>
                <div
                  className={`${styles.dirBox} ${styles.downBox}`}
                  onClick={() => mouseDownPTZControl(2, setAutoControl)}
                >
                  <Icon component={caretDown} />
                </div>
                <div
                  className={`${styles.dirBox} ${styles.leftBox}`}
                  onClick={() => mouseDownPTZControl(3, setAutoControl)}
                >
                  <Icon component={caretLeft} />
                </div>
                <div
                  className={`${styles.dirBox} ${styles.rightBox}`}
                  onClick={() => mouseDownPTZControl(4, setAutoControl)}
                >
                  <Icon component={caretRight} />
                </div>
              </div>
            )}
          </div>
          {showPlayblack && (
            <Form form={form} isOrigin style={{ width: size.width }} className={styles.playbackControl}>
              <div className={styles.filter}>
                <Form.Item name="startTime" label="开始时间">
                  <DatePicker showTime style={{ width: '264px' }} />
                </Form.Item>
                <Form.Item name="endTime" label="结束时间">
                  <DatePicker showTime style={{ width: '264px' }} />
                </Form.Item>
                <Button type="primary" onClick={onSearch}>
                  查询
                </Button>
              </div>
              <div className={styles.playbackBtns}>
                <Button
                  type="primary"
                  disabled={!searchSuccess}
                  onClick={() => {
                    const { startTime, endTime } = form.getFieldsValue();

                    if (!startTime) return message.error('请选择开始时间');
                    if (!endTime) return message.error('请选择结束时间');

                    if (dayjs(startTime) > dayjs(endTime)) return message.error('开始时间大于结束时间');

                    clickStartPlayback(
                      { szDeviceIdentify, rtspPort: videoInfo.rtspPort, iChannelID: channelIds[0] },
                      dayjs(startTime).format('YYYY-MM-DD HH:mm:ss'),
                      dayjs(endTime).format('YYYY-MM-DD HH:mm:ss')
                    );
                    setShowBackMark(true);
                  }}
                >
                  开始回放
                </Button>
                <Button type="primary" disabled={!searchSuccess} onClick={() => clickPause()}>
                  暂停
                </Button>
                <Button
                  type="primary"
                  disabled={!searchSuccess}
                  onClick={() => clickStartDownloadRecord(szDeviceIdentify)}
                >
                  下载
                </Button>
                <Button
                  wrapStyle={{ float: 'right', marginRight: 0 }}
                  disabled={!searchSuccess}
                  onClick={() => {
                    clickStopPlayback();
                    setShowBackMark(false);
                    setShowPlayblack(!showPlayblack);
                  }}
                >
                  停止回放
                </Button>
              </div>
            </Form>
          )}
        </>
      )}
      {videoInfo.showFooter && (
        <div className={styles.footer}>
          <span className={styles.left_text}>{videoInfo.leftText}</span>
          <span className={styles.location_text}>{videoInfo.monitorLocation}</span>
          <span className={styles.right_text}>{videoInfo.rightText}</span>
        </div>
      )}
    </div>
  );
});
