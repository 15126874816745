import {
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  Tabs,
  MXTabsProps,
  Button,
  Form,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router';
import { isNil } from 'lodash';
import { apiV2OutputProductBaseLineDetailPost } from '@maxtropy/device-customer-apis-v2';
import GenerateBaseLine from '../components/GenerateBaseLine';
import { BaseLineContext, LineItemProps } from '../utils/common';
import {
  Granularity,
  GranularityFormat,
  StatisticalModel,
  StatisticalModelFormat,
  VariableType,
  VariableTypeFormat,
} from '@/pages/OutputConfig/Baseline/BaselineTs';
import { OutputUnitType } from '@/api/outputConfig';
import { Spin } from 'antd';

const items: MXTabsProps['items'] = [
  {
    key: '1',
    label: '产品基线',
  },
  {
    key: '2',
    label: '基本信息',
  },
];

const ViewBaseLine: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [lineItem, setLineItem] = useState<LineItemProps>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [{ name: '查看单耗基线' }];
  const [activeKey, setActiveKey] = useState<string>('1');

  useEffect(() => {
    if (isNil(id)) return;
    setLoading(true);
    apiV2OutputProductBaseLineDetailPost({ id })
      .then(res => {
        form.setFieldsValue({
          outputProductCode: res.outputProductCode,
          outputProductName: res.outputProductName,
          energyMediumName: res.energyMediumName,
          variableType: VariableTypeFormat[res.variableType as VariableType],
          dataSource: GranularityFormat[res.dataSource as Granularity],
          statisticalModel: StatisticalModelFormat[res.statisticalModel as StatisticalModel],
          minOutput: `${res.minOutput}${res.unitTypeCode === OutputUnitType.PIECE ? res.unitName : res.unitCode}/时`,
          maxOutput: `${res.maxOutput}${res.unitTypeCode === OutputUnitType.PIECE ? res.unitName : res.unitCode}/时`,
          warningUpperLimit: res.warningUpperLimit + '%',
          warningLowerLimit: res.warningLowerLimit + '%',
          advancedValuePercent: res.advancedValuePercent,
        });
        setLineItem({
          name: res.outputProductName,
          code: res.outputProductCode,
          energyMediumName: res.energyMediumName,
          minOutput: res.minOutput,
          maxOutput: res.maxOutput,
          unit: res.unitTypeCode === OutputUnitType.PIECE ? res.unitName : res.unitCode,
          warningUpperLimit: res.warningUpperLimit,
          warningLowerLimit: res.warningLowerLimit,
          multiChartData: res.multiChartData,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, form]);

  const onChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title="查看单耗基线"></FormTitle>
        <Tabs activeKey={activeKey} type="card" items={items} onChange={onChange} />
        <SubContent style={{ marginTop: 16 }}>
          <Spin spinning={loading}>
            <BaseLineContext.Provider value={{ lineItem, setLineItem, isDetail: true }}>
              {activeKey === '1' && <GenerateBaseLine></GenerateBaseLine>}
              {activeKey === '2' && (
                <Form form={form}>
                  <BaseInfoContent colNum={2} layout="horizontal">
                    <Form.Item name="outputProductCode" label="产出物编码">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="outputProductName" label="产出物名称">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="statisticalModel" label="统计模型">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="dataSource" label="数据来源">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="variableType" label="X轴指标">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="energyMediumName" label="Y轴指标">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="minOutput" label="最小生产速率">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="maxOutput" label="最大生产速率">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="warningUpperLimit" label="单耗预警上限">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item name="warningLowerLimit" label="单耗预警下限">
                      <ShowInput />
                    </Form.Item>
                    <Form.Item label="单耗先进值标准">
                      比基准单耗低
                      <Form.Item noStyle name="advancedValuePercent">
                        <ShowInput style={{ display: 'inline-block' }} />
                      </Form.Item>
                      %及以上为先进单耗
                    </Form.Item>
                  </BaseInfoContent>
                </Form>
              )}
            </BaseLineContext.Provider>
            <Button
              style={{ marginTop: 32 }}
              onClick={() => {
                navigate('/systemConfig/productionTopo/baseLineManage');
              }}
            >
              返回
            </Button>
          </Spin>
        </SubContent>
      </Wrapper>
    </>
  );
};
export default ViewBaseLine;
