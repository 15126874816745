import { useState, useEffect } from 'react';
import {
  apiV2CarbonFootprintOverviewLcaCountPost,
  V2CarbonFootprintOverviewLcaCountPostResponse,
} from '@maxtropy/device-customer-apis-v2';

import styles from './index.module.scss';
import Subtitle from '../Subtitle';

const ModelingOverview = () => {
  const [data, setData] = useState<V2CarbonFootprintOverviewLcaCountPostResponse>();
  useEffect(() => {
    apiV2CarbonFootprintOverviewLcaCountPost({}).then(res => {
      setData(res ?? {});
    });
  }, []);

  return (
    <div className={styles.modelingCon}>
      <Subtitle title="建模概览" />
      <div className={styles.modelInfo}>
        <div className={styles.detail}>
          <div className={styles.count}>{data?.appliedCount ?? '--'}</div>
          <div className={styles.desc}>已建模产品数（个）</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.count}>{data?.materialCount ?? '--'}</div>
          <div className={styles.desc}>物料种类（种）</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.count}>{data?.energyCount ?? '--'}</div>
          <div className={styles.desc}>能源种类（种）</div>
        </div>
        <div className={styles.detail}>
          <div className={styles.count}>{data?.supplyCount ?? '--'}</div>
          <div className={styles.desc}>供应商数量（家）</div>
        </div>
      </div>
    </div>
  );
};

export default ModelingOverview;
