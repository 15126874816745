import { apiV2SupervisoryAccountDetailPost } from '@maxtropy/device-customer-apis-v2';
import { Input } from 'antd';
import { Form, Modal, Select } from '@maxtropy/components';
import { FC, useEffect } from 'react';
import styles from './index.module.scss';
import { AccessMode, AccessModeDisplay } from '@/pages/VideoMonitoring/type';

export interface formSubmitProps {
  name: string;
  appKey: string;
  secret: string;
}

export interface ICreateOrEditAccountFormModalProps {
  accountId?: number;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: formSubmitProps) => Promise<void>;
}

const CreateOrEditAccountFormModal: FC<ICreateOrEditAccountFormModalProps> = props => {
  const { accountId, visible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();

  const accessMode = Form.useWatch('accessMode', form);

  useEffect(() => {
    if (accountId) {
      apiV2SupervisoryAccountDetailPost({
        id: accountId,
      }).then(res => {
        form.setFieldsValue({
          name: res.name,
          accessMode: res.accessMode,
          appKey: res.appKey,
          secret: res.secret,
        });
      });
    }
  }, [accountId, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onOK = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      onOk={onOK}
      title={accountId ? '编辑账户' : '添加账户'}
    >
      <Form className={styles.form} form={form} labelAlign="left">
        <Form.Item
          name="name"
          label="账户名称"
          rules={[
            { required: true, message: '请输入账户名称' },
            { max: 50, message: '最多输入50个字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="accessMode" label="接入方式" rules={[{ required: true, message: '请输入账户名称' }]}>
          <Select
            options={Object.entries(AccessModeDisplay).map(([key, label]) => ({ label, value: Number(key) }))}
            placeholder="请选择"
          />
        </Form.Item>
        {accessMode === AccessMode.YSY && (
          <>
            <Form.Item name="appKey" label="AppKey" rules={[{ required: true, message: '请输入AppKey' }]}>
              <Input placeholder="请输入" autoComplete="off" />
            </Form.Item>
            <Form.Item name="secret" label="Secret" rules={[{ required: true, message: '请输入Secret' }]}>
              <Input.Password placeholder="请输入" autoComplete="new-password" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateOrEditAccountFormModal;
