import {
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  Form,
  Input,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';
import dayjs from 'dayjs';
import type { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';
import { FC, useEffect, useState } from 'react';
import {
  apiV2ElectricityAccountPagePost,
  V2ElectricityAccountPagePostRequest,
  V2ElectricityAccountPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';

import {
  ElectricityAccountStatus,
  ElectricityAccountVo,
  ElectricityType,
  ElectricityTypeDisplay,
  createElectricityAccount,
  updateElectricityAccount,
  CreateElectricityAccountForm,
  invalidElectricityAccount,
  electricityAccountStatusDisplay,
} from '../../../api/electricity';
import CreateForm from './CreateForm';
import styles from '../utils.module.scss';
import { Link } from 'react-router-dom';
import { getParents, useAreaJson, useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { ActionType } from '../utils';
import { PlusOutlined } from '@ant-design/icons';

export interface SearchParams extends Omit<V2ElectricityAccountPagePostRequest, 'timestamp' | 'page' | 'size'> {}

const ElectricityAccount: FC = () => {
  const hasAccountAddConfig = useHasPermission(PermissionsType.B_ACCOUNT_ADD_CONFIG);
  const hasDeviceManage = useHasPermission(PermissionsType.B_DEVICE_MANAGE);
  const hasAccountEdit = useHasPermission(PermissionsType.B_ACCOUNT_EDIT);
  const hasAccountVoid = useHasPermission(PermissionsType.B_ACCOUNT_VOID);

  const [searchForm] = Form.useForm();
  const [updateState, updateFn] = useUpdate();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [visible, setVisible] = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<V2ElectricityAccountPagePostResponse['list']>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [current, setCurrent] = useState<ElectricityAccountVo>();

  const districtData = useAreaJson();

  useEffect(() => {
    setLoading(true);
    apiV2ElectricityAccountPagePost({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total!);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    const { regionCode } = val;
    const params = {
      number: val.number,
      regionCode: regionCode ? regionCode[regionCode.length - 1] : undefined,
      username: val.username,
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const onReset = () => {
    setPageOffset(1);
    const params = {
      number: undefined,
      regionCode: undefined,
      username: undefined,
    };
    setSearchParams(params);
  };

  const invalidAccount = (v: ElectricityAccountVo) => {
    Modal.confirm({
      title: '作废后，无法查看到对应户号数据，且解绑所有已绑定的设备',
      okText: '确认作废',
      onOk() {
        invalidElectricityAccount(v.id).then(() => {
          updateFn();
        });
      },
    });
  };

  const onAdd = () => {
    setVisible(true);
    setCurrent(undefined);
    setActionType(ActionType.CREATE);
  };

  const onCancel = () => {
    setVisible(false);
    setCurrent(undefined);
    setActionType(undefined);
  };

  const onSubmit = async (
    values: Omit<CreateElectricityAccountForm, 'regionCode' | 'mcids'> & {
      regionCode: string[];
      mcids: DefaultOptionType[];
    }
  ) => {
    const { regionCode, mcids } = values;
    if (actionType === ActionType.DETAIL) {
      onCancel();
    } else if (actionType === ActionType.CREATE) {
      await createElectricityAccount({
        ...values,
        mcids: mcids.map(item => item.value as string),
        regionCode: regionCode[regionCode.length - 1],
      });
      onCancel();
      setPageOffset(1);
      updateFn();
    } else if (actionType === ActionType.UPDATE && current) {
      await updateElectricityAccount({
        ...values,
        id: current.id,
        mcids: mcids.map(item => item.value as string),
        regionCode: regionCode[regionCode.length - 1],
      });
      onCancel();
      updateFn();
    }
  };

  const columns = [
    {
      title: '用电账户户号',
      dataIndex: 'number',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在区域',
      dataIndex: 'regionCode',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string) => {
        let value;
        if (districtData) {
          value = getParents(districtData, v).map(item => item.name);
        }
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '用电种类',
      dataIndex: 'type',
      width: 150,
      ellipsis: { showTitle: true },
      render: (v: ElectricityType) => {
        return <EllipsisSpan value={ElectricityTypeDisplay[v]} />;
      },
    },
    {
      title: '关联变压器',
      dataIndex: 'transformerId',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建人',
      dataIndex: 'createByUsername',
      width: 150,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: ElectricityAccountStatus) => {
        return <EllipsisSpan value={electricityAccountStatusDisplay[v]} />;
      },
    },
  ];

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 180,
      render: (record: ElectricityAccountVo) => (
        <Space size={16}>
          {hasAccountEdit && (
            <Button
              disabled={record.status === ElectricityAccountStatus.INVALID}
              type="link"
              onClick={() => {
                setVisible(true);
                setCurrent(record);
                setActionType(ActionType.UPDATE);
              }}
            >
              编辑
            </Button>
          )}
          <Button
            type="link"
            onClick={() => {
              setVisible(true);
              setCurrent(record);
              setActionType(ActionType.DETAIL);
            }}
          >
            查看
          </Button>
          {hasAccountVoid && (
            <Button
              disabled={record.status === ElectricityAccountStatus.INVALID}
              type="link"
              onClick={() => {
                invalidAccount(record);
              }}
            >
              作废
            </Button>
          )}
          {hasDeviceManage && (
            <Button type="link" disabled={record.status === ElectricityAccountStatus.INVALID}>
              <Link to={`/operation/energy/account/electricity/device/${record.id}`}>设备管理</Link>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className={styles.filterWrapper}>
        <CustomFilter form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
          <Form.Item label="用电账户户号" name="number">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="所在区域" name="regionCode">
            <Cascader
              options={districtData}
              fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item label="创建人" name="username">
            <Input placeholder="请输入" />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        {hasAccountAddConfig && (
          <div className={styles.operationArea}>
            <Button type="primary" icon={<PlusOutlined />} onClick={onAdd}>
              新增用电账户
            </Button>
          </div>
        )}
        <Table sticky scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={data} rowKey="id" />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <CreateForm
        current={current}
        districtData={districtData}
        actionType={actionType}
        visible={visible}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ElectricityAccount;
