import { EllipsisSpan, Table } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC } from 'react';
import { MeterFormValue } from '../../../../SalesContract/MeterForm';

interface EffectiveMeterFormProps {
  addType?: string; // 区分电量还是需量表计
  value?: any[]; // 数据
}

const columns: ColumnType<MeterFormValue>[] = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join(',')} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')} ` : '--'} />,
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')} ` : '--'} />,
  },
];

const EffectiveMeterForm: FC<EffectiveMeterFormProps> = props => {
  const { addType, value } = props;
  return (
    <>
      {<div style={{ fontWeight: 700, marginBottom: '10px' }}>{addType}-计量表计</div>}
      <Table rowKey={`id`} dataSource={value ?? []} columns={columns} />
    </>
  );
};

export default EffectiveMeterForm;
