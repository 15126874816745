import { ConfigurtionListItem, DataLinkRes, getDataLinkList } from '@/api/quality-monitor';
import { Button, EllipsisSpan, Form, Modal, Table } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';

interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  data?: ConfigurtionListItem;
}
const DataLinkModal: React.FC<Iprops> = ({ cancel, confirm, data }) => {
  const [form] = Form.useForm();
  const onOk = () => {
    form.validateFields().then(res => console.log(res));
    // confirm?.();
  };
  const onCancel = () => {
    cancel?.();
  };

  // 数据链路列表
  const { data: dataLinkList } = useRequest(
    () => {
      if (data) {
        return getDataLinkList({
          importAndExportId: data.importAndExportId,
          importAndExportType: data.importAndExportType,
          energyMediumMonitoringIndicatorsId: data.indicatorId,
        }).then(res => res.map(item => item));
      }
      return Promise.resolve([]);
    },
    { refreshDeps: [data] }
  );

  const columns = [
    {
      title: '时间',
      dataIndex: 'startTime',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string, record: DataLinkRes) => {
        let start = record.startTime ? dayjs(record.startTime).format('YYYY-MM-DD HH:mm') : '--';
        let end = record.endTime ? dayjs(record.endTime).format('YYYY-MM-DD HH:mm') : '--';
        return <EllipsisSpan value={`${start}至${end}`} />;
      },
    },

    {
      title: '对应设备',
      dataIndex: 'deviceName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据属性',
      dataIndex: 'dataPropertyName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
    },
  ];
  return (
    <>
      <Modal
        title="数据链路"
        open
        size="large"
        onOk={onOk}
        onCancel={onCancel}
        footer={
          <Button type="primary" onClick={() => cancel?.()}>
            确定
          </Button>
        }
      >
        <div style={{ paddingBottom: 10 }}>
          <Space>
            <div>指标名称 : </div>
            <div>{data?.indicatorName ?? '--'}</div>
          </Space>
        </div>

        <Table rowKey="id" sticky columns={columns} dataSource={dataLinkList} pagination={false} />
      </Modal>
    </>
  );
};

export default DataLinkModal;
