import { Divider, Drawer } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import * as echarts from 'echarts';
import 'echarts-liquidfill';
import ReactEcharts from 'echarts-for-react';
import { CloseOutlined } from '@ant-design/icons';
import { ProductionEnergyConsumptionScorePostResponse } from '@maxtropy/device-customer-apis';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { DiagnosisLevel, levelColors, linearColors, scoreRange } from '@/api/energyConsumption';
import { Empty } from '@maxtropy/components';

interface AnalysisDrawerProps {
  analysisData?: ProductionEnergyConsumptionScorePostResponse;
  show: boolean;
  close: () => void;
}

const colors = ['#59D744', '#FFCB47', '#FF4D4F', '#646464'];

enum Section {
  NORMAL = '正常生产',
  STOP = '停线',
  LEISURE = '空闲',
  REST = '休息',
}

const SectionFields: Record<Section, { timeField: string; percentField: string; energyField: string }> = {
  [Section.NORMAL]: {
    timeField: 'exceedNormalProductionTime',
    percentField: 'exceedNormalProductionProportion',
    energyField: 'normalProductionEnergyConsumption',
  },
  [Section.STOP]: {
    timeField: 'exceedStopLineTime',
    percentField: 'exceedStopLineProportion',
    energyField: 'stopLineEnergyConsumption',
  },
  [Section.LEISURE]: {
    timeField: 'exceedLeisureTime',
    percentField: 'exceedLeisureProportion',
    energyField: 'leisureEnergyConsumption',
  },
  [Section.REST]: {
    timeField: 'exceedRestTime',
    percentField: 'exceedRestProportion',
    energyField: 'restEnergyConsumption',
  },
};

const levelDesc: Record<DiagnosisLevel, string> = {
  [DiagnosisLevel.EXCELLENT]: '优',
  [DiagnosisLevel.GOOD]: '良',
  [DiagnosisLevel.NORMAL]: '一般',
  [DiagnosisLevel.RANGE]: '较差',
  [DiagnosisLevel.POOR]: '差',
};

const AnalysisDrawer: React.FC<AnalysisDrawerProps> = ({ analysisData, show, close }) => {
  const [visible, setVisible] = useState<boolean>(show);

  const currentLevel = useMemo(() => {
    if (!analysisData || isNil(analysisData.score)) return DiagnosisLevel.EXCELLENT;
    const score = analysisData.score;
    const level = scoreRange.find(item => item.min <= score && item.max > score)?.level;
    return level ?? DiagnosisLevel.EXCELLENT;
  }, [analysisData]);

  useEffect(() => {
    initChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisData]);

  const initChart = () => {
    const liquid = document.getElementById('liquid');
    if (!liquid) return;
    const map = echarts.init(liquid as HTMLDivElement);
    const option = getLiquidChartOption();
    map.clear();
    map.setOption(option);
  };

  const handleClose = () => {
    setVisible(false);
    close();
  };

  const getSectionType = (percent: number = 0) => {
    const p = percent * 100;
    if (p === 0) return 'normal';
    if (p <= 5) return 'tip';
    if (p <= 10) return 'warning';
    return 'danger';
  };

  const getSectionDesc = (percent: number = 0, section: Section) => {
    const p = percent * 100;
    if (p === 0) return '该时段能耗表现优秀，持续保持';
    if (p <= 5) return '存在间歇性能耗超限，请排查问题时段';
    if (p <= 10) {
      if (section === Section.LEISURE) return '请注意空闲时段设备空转';
      if (section === Section.NORMAL) return '请注意产线用能控制';
      if (section === Section.REST) return '检查照明、空调等公共能耗';
      if (section === Section.STOP) return '注意停线后部分工站能耗超标';
    }
    if (section === Section.LEISURE) return '空闲时段设备未按要求关闭';
    if (section === Section.NORMAL) return '近期生产单耗显著超限';
    if (section === Section.REST) return '非生产能耗严重超限';
    if (section === Section.STOP) return '加强停线工站值守，单耗较高';
  };

  const timeSectionList = useMemo(() => {
    return ['正常生产', '停线', '空闲', '休息'].map((item, index) => {
      const value =
        (analysisData?.[SectionFields[item as Section]?.timeField as keyof typeof analysisData] ?? '--') + 'h';
      const percent = analysisData?.[
        SectionFields[item as Section]?.percentField as keyof typeof analysisData
      ] as number;
      return {
        title: item + '时段',
        value,
        type: getSectionType(percent),
        desc: getSectionDesc(percent, item as Section),
      };
    });
  }, [analysisData]);

  const chartData = useMemo(() => {
    return ['正常生产', '空闲', '停线', '休息'].map((item, index) => {
      return {
        value: analysisData?.[SectionFields[item as Section]?.energyField as keyof typeof analysisData],
        name: item,
        itemStyle: {
          color: colors[index],
        },
      };
    });
  }, [analysisData]);

  const allConsumption = useMemo(() => {
    return chartData?.reduce((a, b) => {
      const currentValue = isNil(b.value) ? 0 : Number(b.value);
      return a + currentValue;
    }, 0);
  }, [chartData]);

  const chartIsEmpty = useMemo(() => {
    return chartData.every(item => isNil(item.value));
  }, [chartData]);

  const isOverTenThousand = Number(allConsumption) >= 10000 || Number(allConsumption) <= -10000;

  // 获取近七天时间字符串
  const getSevenDays = () => {
    return `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')} 到 ${dayjs().subtract(1, 'day').format('YYYY-MM-DD ')}`;
  };

  const getLiquidChartOption = () => {
    return {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        containLabel: true,
      },
      series: [
        {
          type: 'liquidFill',
          label: {
            show: true,
            color: '#fff',
            insideColor: '#fff',
            fontSize: 16,
            fontWeight: 'bold',
            formatter: (params: { value: any }) => {
              const score = params.value === '--' ? '--' : parseFloat((params.value * 100).toFixed(1));
              return [`{a|${score}}`, `{c|得分}`].join('\n');
            },
            rich: {
              a: {
                fontSize: 16,
                lineHeight: 22,
                fontWeight: 600,
              },
              c: {
                fontSize: 12,
                lineHeight: 17,
                color: 'rgba(255,255,255,0.65)',
              },
            },
          },
          data: [
            {
              value: isNil(analysisData?.score) ? '--' : analysisData?.score! / 100,
              direction: 'left',
              itemStyle: {
                color: `rgba(${levelColors[currentLevel]})`,
              },
            },
          ],
          backgroundStyle: {
            borderWidth: 2,
            borderColor: '#232324',
            color: `rgba(${levelColors[currentLevel]},0.2)`,
          },
          outline: {
            borderDistance: 2,
            itemStyle: {
              borderWidth: 3,
              borderColor: `rgba(${levelColors[currentLevel]})`,
            },
          },
        },
      ],
    };
  };

  const getPieChartOption = () => {
    return {
      grid: {
        containLabel: true,
      },
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },

        formatter: (e: any) => {
          const itemValue = chartData?.find(i => i.name === e.name)?.value;
          const displayPercent = isNil(itemValue) ? '--' : ((Number(itemValue ?? 0) / allConsumption) * 100).toFixed(2);
          return `<div style='width:140px;display:flex;align-items:center;justify-content:space-between'>
            <div style='width:80px;display:flex;align-items:center;justify-content:flex-start'>
              <div style='width:14px;height:12px;background:${e.color};border-radius:50%;margin-right:10px'></div> 
              <div style='color:rgba(255,255,255,.0.85)'>${e.name}</div>
            </div>
            <div style='color:rgba(255,255,255,.0.85)'>${displayPercent}%</div>
          </div>`;
        },
      },
      legend: {
        orient: 'vertical',
        bottom: '10%',
        left: 'center',
        icon: 'circle',
        textStyle: {
          fontSize: '14',
          width: 250,
          rich: {
            name: {
              width: 80,
              color: 'rgba(255,255,255,0.65)',
            },
            b: {
              width: 100,
              color: 'rgba(255,255,255,0.85)',
            },
            v: {
              width: 60,
              color: 'rgba(255,255,255,0.85)',
            },
          },
        },
        formatter: (name: any) => {
          const itemValue = chartData?.find(i => i.name === name)?.value;
          const displayValue = isNil(itemValue)
            ? '--'
            : isOverTenThousand
            ? (Number(itemValue) / 1000).toFixed(2)
            : Number(itemValue).toFixed(2);
          const displayPercent = isNil(itemValue) ? '--' : ((Number(itemValue ?? 0) / allConsumption) * 100).toFixed(2);
          return `{name|${name}} {b|${displayValue}${isOverTenThousand ? 'tce' : 'kgce'}}{v|${displayPercent}%}`;
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '56%'],
          center: ['180px', '30%'],
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: '#232324',
            borderWidth: 2,
          },
          data: chartData,
          minAngle: 2,
          emphasis: {
            label: {
              show: false,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          emptyCircleStyle: {
            color: '#3F3F3F',
          },
        },
      ],
    };
  };

  return (
    <Drawer
      title="智能分析诊断"
      open={visible}
      width={400}
      className={styles.analysisDrawer}
      mask={false}
      getContainer={false}
      forceRender={true}
    >
      <div className={styles.titleWrapper}>
        <div className={styles.titleTxt}>智能分析诊断</div>
        <CloseOutlined onClick={handleClose} size={16} style={{ color: 'rgba(255,255,255,0.45' }} />
      </div>
      <Divider />
      <div className={styles.contentWrapper}>
        <div className={styles.headerLiquid}>
          <div id="liquid" className={styles.liquidBox} style={{ width: 160, height: 160 }}></div>
          <div className={styles.chartCard} style={{ background: linearColors[currentLevel] }}>
            <div className={styles.cardLeft}>
              <div>{getSevenDays()}</div>
              <div>{analysisData?.workCenterName ?? '--'}</div>
            </div>
            <div className={styles.splitLine}></div>
            <div className={styles.cardRight}>
              <div style={{ color: `rgb(${levelColors[currentLevel]})` }}>{levelDesc[currentLevel]}</div>
              <div>生产能耗状况</div>
            </div>
          </div>
        </div>
        {timeSectionList.map((item, index) => {
          return (
            <div className={styles.timeCard} key={index}>
              <div className={styles.topLine}>
                <div>{item.title}</div>
                <div className={styles.lineRight}>
                  <span>超能耗基线</span>
                  <span className={styles[item.type]}>{item.value}</span>
                </div>
              </div>
              <div className={`${styles.bottomLine} ${styles[item.type]}`}>{item.desc}</div>
            </div>
          );
        })}
        <div className={styles.footerPie}>
          <div className={styles.pieTitle}>七日内工作中心能耗占比</div>
          {!chartIsEmpty ? (
            <ReactEcharts
              option={getPieChartOption()}
              notMerge
              lazyUpdate={false}
              style={{ height: 400, width: 360 }} // 样式的设置width与height
            />
          ) : (
            <Empty description={'暂无数据'} style={{ marginTop: 100 }} />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AnalysisDrawer;
