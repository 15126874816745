import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Space, Cascader, Row, Col } from 'antd';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Select,
  Modal,
  Form,
  useAsync,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import SelectDevices from './SelectDevices';
import SelectRuleGroup from './SelectRuleGroup';
import { batchDeviceBindingRuleGroup, BatchRuleGroupPageResponse } from '../../../api/ruleGroup';
// import {getDeviceTypeData} from "../../../api/template";
import { IotProtocolType } from '@/shared/types';
import { formatDeviceTypeToDataNode, useQuery } from '@/shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getProtocol } from '../../../api/protocol';
import { getDeviceTypeTreeWithoutScene } from '../../../api/deviceType';
import { getPhysicalModelList, PhysicalModelRes } from '@/api/device';

const routes = [{ name: '批量配置报警规则组' }];

interface FormValues {
  iotProtocolType: number;
  deviceType: Array<number>;
  deviceIdList: Array<number>;
  iotProtocol: IotProtocolType;
  ruleGroup: BatchRuleGroupPageResponse;
}

const BatchBindRuleGroup: React.FC = () => {
  const navigate = useNavigate();

  // const deviceTypeData = useAsync(getDeviceTypeData);
  const iotProtocolData = useAsync(getProtocol);

  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [deviceType, setDeviceType] = useState<number>();
  const [objectModalType, setObjectModalType] = useState<number>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // const [deviceType, setDeviceType] = useState<number[]>();

  const { data: deviceTypeTreeData } = useQuery(useCallback(() => getDeviceTypeTreeWithoutScene(), []));

  const [form] = Form.useForm();
  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  useEffect(() => {
    // 物模型型号
    if (deviceType) {
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(res => setPhysicalModelList(res.list));
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
    return [];
  }, [physicalModelList]);

  const onSubmit = (val: FormValues) => {
    batchDeviceBindingRuleGroup({
      deviceIdList: val.deviceIdList,
      ruleGroupId: val.ruleGroup.id,
    }).then(res => {
      if (res.flag) {
        goList();
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      deviceIdList: [],
      ruleGroup: undefined,
    });
  }, [iotProtocolType, deviceType, objectModalType, form]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     deviceIdList: [],
  //   })
  // }, [deviceType, form])

  // const onDeviceTypeSelect = (val: Array<string | number>) => {
  //   setDeviceType(val as number[]);
  // };

  const goList = () => {
    navigate(`/device/manage/device`);
  };

  const onCancel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      okText: '确定',
      onOk: goList,
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="批量配置报警规则组"></FormTitle>
      <SubContent>
        <Form form={form} onFinish={onSubmit}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="iotProtocolType"
                rules={[{ required: true, message: '请选择物联层协议' }]}
                label="物联层协议"
              >
                <Select
                  onChange={v => {
                    setIotProtocolType(v);
                    setDeviceType(undefined);
                    setObjectModalType(undefined);

                    form.setFieldsValue({
                      deviceTypeId: undefined,
                      objectModalType: undefined,
                    });
                  }}
                  placeholder={'请选择物联层协议'}
                >
                  {iotProtocolData?.list?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
              <>
                <Col span={24}>
                  <Form.Item
                    name="deviceTypeId"
                    label="适用设备类目"
                    rules={[{ required: true, message: '请选择适用设备类目' }]}
                  >
                    <Cascader
                      allowClear={false}
                      style={{ width: '100%' }}
                      options={formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? [])}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={(e: any) => {
                        if (e.length < 2) {
                          form.setFieldsValue({ typeId: undefined });
                        }
                        setDeviceType(e[1] as number | undefined);
                        setObjectModalType(undefined);
                        form.setFieldsValue({ objectModalType: undefined });
                      }}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="objectModalType" label="物模型型号">
                    <Select
                      allowClear
                      optionFilterProp="label"
                      showSearch
                      onChange={v => {
                        setObjectModalType(v);
                      }}
                      options={objectModalTypeOptions}
                      placeholder={form.getFieldValue('deviceTypeId') ? '请选择' : '请先选择适用设备类目'}
                      disabled={!form.getFieldValue('deviceTypeId')}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Form.Item name="deviceIdList" rules={[{ required: true, message: '请选择设备' }]} label="设备选择">
            <SelectDevices objectModalType={objectModalType} iotProtocol={iotProtocolType} deviceTypeId={deviceType} />
          </Form.Item>
          <Form.Item name="ruleGroup" rules={[{ required: true, message: '请选择规则组' }]} label="规则组">
            <SelectRuleGroup
              iotProtocol={iotProtocolType}
              deviceTypeId={deviceType}
              objectModalType={objectModalType}
            />
          </Form.Item>
          <Space size={8} className="sticky-footer-left">
            <Button htmlType="submit" type="primary">
              完成
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        </Form>
      </SubContent>
    </Wrapper>
  );
};

export default BatchBindRuleGroup;
