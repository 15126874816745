import {
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  SubContent,
  BaseInfoContent,
  Form,
} from '@maxtropy/components';
import { Spin } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/shared/utils/utils';
import { getImplementAdminList, getImplementDetail, ImplementAdmin } from '../../api/implement';
import styles from './index.module.scss';

interface TableData {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
}

function formatTableData(res: Array<ImplementAdmin>): Array<TableData> {
  return res as Array<TableData>;
}

const columns = [
  {
    title: '用户ID',
    dataIndex: 'humanCode',
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
  },
  {
    title: '用户登录账号',
    dataIndex: 'username',
  },
  {
    title: '管理员',
    render: (record: TableData) => (record.staffType === 0 ? '成员' : '管理员'),
  },
  {
    title: '姓名',
    dataIndex: 'name',
  },
  {
    title: '手机号',
    dataIndex: 'phone',
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
  },
];
const routes = [{ name: '运营单元详情' }];

const Detail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data: detail, isLoading: detailLoading } = useQuery(useCallback(() => getImplementDetail(Number(id)), []));

  const { data, isLoading: tableLoading } = useQuery(
    useCallback(async () => {
      const page = await getImplementAdminList(Number(id));
      return formatTableData(page.list ?? []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title="运营单元详情" />
      <SubContent title={'项目信息'}>
        <Spin spinning={detailLoading}>
          <BaseInfoContent>
            <Form.Item label="项目编号">{detail?.serialNumber}</Form.Item>
            <Form.Item label="项目名称">{detail?.name}</Form.Item>
            <Form.Item label="所属组织">{detail?.tenantName}</Form.Item>
            <Form.Item label="负责人">{detail?.directorName ? detail?.directorName : '--'}</Form.Item>
          </BaseInfoContent>
        </Spin>
      </SubContent>
      <SubContent title={'管理员列表'}>
        <div className={styles.table}>
          <Table<TableData> rowKey="id" loading={tableLoading} columns={columns} dataSource={data} />
        </div>
      </SubContent>
      <div className={styles.footer}>
        <Button className={styles.backBtn} onClick={() => navigate(-1)}>
          返回
        </Button>
      </div>
    </Wrapper>
  );
};

export default Detail;
