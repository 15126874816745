import React, { useEffect } from 'react';
import { PhysicalUnit } from '../../types';
import { Select } from '@maxtropy/components';

export interface UnitPropsProps {
  units?: PhysicalUnit[];
  disabled: boolean;
  value?: number;
  onChange?: (value: number) => void;
}

const UnitProps: React.FC<UnitPropsProps> = ({ units, disabled, value, onChange }) => {
  useEffect(() => {
    if (!value && units && units.length) {
      onChange && onChange(units[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units, value]);

  if (!units || !units.length) return null;
  if (units.length === 1) return <div>{units[0].generalName}</div>;
  return (
    <Select
      style={{ width: 80 }}
      placeholder={'请选择'}
      defaultValue={units[0].id}
      disabled={disabled}
      onChange={onChange}
      value={value}
    >
      {units.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.generalName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UnitProps;
