import {
  BaseInfoContent,
  Button,
  Form,
  FormTitle,
  ShowInput,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import React, { useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { MachineType } from './type';
import CenterDataCompartion from './components/CenterDataCompartion';
import BottomSingleDevice from './components/BottomSingleDevice';
import { apiV2AirCompressorGetPost, apiV2NitrogenProductionGetPost } from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';

const MonitorDeviceDashBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_type = urlSearchParams.get('stationType') || undefined;

  const routes = useMemo(() => {
    if (url_type) {
      return [{ name: `${Number(url_type) === MachineType.AIRCOMPRESSOR ? '空压站' : '制氮机'}` }];
    }
    return [];
  }, [url_type]);

  // 请求接口
  const { data: detail, loading } = useRequest(
    () => {
      if (Number(url_type) === MachineType.AIRCOMPRESSOR) {
        return apiV2AirCompressorGetPost({ id });
      } else {
        return apiV2NitrogenProductionGetPost({ id });
      }
    },
    {
      ready: !!url_type && !!id,
      refreshDeps: [url_type, id],
    }
  );

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        stationCode: detail?.stationCode ?? '--',
        ouSerialNumber: detail?.ouSerialNumber ?? '--',
        ouName: detail?.ouName ?? '--',
      });
    }
  }, [detail]);

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Spin spinning={loading}>
        <FormTitle
          title={<div>{`${detail?.stationName}`}</div>}
          extraContent={
            <Button
              onClick={() => {
                navigate(`/production/manage/gasMachineDashBoard?stationId=${id}&stationType=${url_type}`);
              }}
            >
              返回拓扑
            </Button>
          }
        />
        <SubContent>
          <Form form={form}>
            <BaseInfoContent colNum={3} layout="horizontal">
              <Form.Item
                name="stationCode"
                label={<>{`${Number(url_type) === MachineType.AIRCOMPRESSOR ? '空压站' : '制氮机'}编号`}</>}
              >
                <ShowInput />
              </Form.Item>
              <Form.Item name="ouSerialNumber" label="运营单元编号">
                <ShowInput />
              </Form.Item>
              <Form.Item name="ouName" label="运营单元名称">
                <ShowInput />
              </Form.Item>
            </BaseInfoContent>
          </Form>
        </SubContent>
        <SubContent>
          <CenterDataCompartion stationType={url_type} stationId={id} />
        </SubContent>
        <SubContent>
          <BottomSingleDevice stationType={url_type} stationId={id} />
        </SubContent>
      </Spin>
    </Wrapper>
  );
};

export default MonitorDeviceDashBoard;
