import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';
import styles from './index.module.scss';
import {
  Tabs,
  PopConfirm,
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  useUpdate,
  Empty,
} from '@maxtropy/components';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import OverviewItem, { OverviewItemRefProps } from './components/OverviewItem';
import { apiV2WorkshopConfigGetDetailListPost, apiV2WorkshopConfigSavePost } from '@maxtropy/device-customer-apis-v2';
import { isEqual, isNil, isNumber } from 'lodash-es';
import { CompareConfigItem, ConfigItem, TabItem } from './utils';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const WorkShopOverviewConfig: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);

  const [isDetail, setIsDetail] = useState<boolean>(true);
  const overviewItemRef: MutableRefObject<OverviewItemRefProps | null> = useRef(null);
  const [activeKey, setActiveKey] = useState<string>();
  const [itemDetail, setItemDetail] = useState<ConfigItem>();
  const [configList, setConfigList] = useState<ConfigItem[]>([]);
  const [prevConfigList, setPrevConfigList] = useState<CompareConfigItem[]>([]);
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    apiV2WorkshopConfigGetDetailListPost({}).then(res => {
      const list = (res.list ?? []).map(item => {
        return {
          key: uuidv4(),
          id: item.id,
          title: item.title!,
          appAnalysisSubscriptionId: item.appAnalysisSubscriptionId,
          ouIds: item.ouIds,
          deviceIds: item.deviceIds,
          workCenterIds: (item.workshopDashboardConfigWorkCenterLinkDtoList ?? []).map(k => k.shopFlowWorkCenterId!),
          originWorkCenterIds: (item.workshopDashboardConfigWorkCenterLinkDtoList ?? []).map(k => [
            k.shopFlowProductionBaseId,
            k.shopFlowWorkCenterId,
          ]),
          productionBaseId: item.workshopDashboardConfigWorkCenterLinkDtoList?.[0]?.shopFlowProductionBaseId,
          outputList: (item.workshopDashboardConfigKeyOutputLinkDtoList ?? []).map(k => ({
            ...k,
            outputIds: (k.outputProductDtoList ?? []).map(n => n.id!),
          })),
          deviceList: (item.workshopDashboardConfigDeviceEnergyConsumptionLinkDtoList ?? []).map(k => ({
            ...k,
            energyUnitIds: (k.energyUnitDtoList ?? []).map(n => n.id!),
          })),
          lineDevices: (item.workshopDashboardConfigProductionLineDeviceLinkDtoList ?? []).map(m => ({
            id: m.deviceId!,
            name: m.deviceName!,
          })),
        };
      });
      setIsDetail(true);
      setItemDetail(list?.[0]);
      setActiveKey(list?.[0]?.key);
      setConfigList([...list]);
      // 用来比对配置变化
      const prevList = (res.list ?? []).map(item => {
        return {
          id: item.id,
          title: item.title!,
          appAnalysisSubscriptionId: item.appAnalysisSubscriptionId,
          ouIds: item.ouIds,
          deviceIds: item.deviceIds,
          workCenterIds: (item.workshopDashboardConfigWorkCenterLinkDtoList ?? []).map(k => k.shopFlowWorkCenterId!),
          outputList: (item.workshopDashboardConfigKeyOutputLinkDtoList ?? []).map(k => ({
            id: k.id,
            outputProductCategoryId: k.outputProductCategoryId,
            outputTypeName: k.outputTypeName,
            unitCode: k.unitCode,
            unitTypeCode: k.unitTypeCode,
            outputIds: (k.outputProductDtoList ?? []).map(n => n.id!),
          })),
          deviceList: (item.workshopDashboardConfigDeviceEnergyConsumptionLinkDtoList ?? []).map(k => ({
            id: k.id,
            deviceTypeName: k.deviceTypeName,
            energyMediumId: k.energyMediumId,
            energyUnitIds: (k.energyUnitDtoList ?? []).map(n => n.id!),
            sequenceNum: k.sequenceNum,
          })),
        };
      });
      setPrevConfigList(prevList);
    });
  }, [updateState]);

  const onChange = async (newActiveKey: string) => {
    if (!isDetail) {
      await handleConfigList();
    }
    const item = configList.find(item => item.key === newActiveKey);
    setItemDetail(item);
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = uuidv4();
    const newConfig = {
      title: '车间精益能管驾驶舱',
      key: newActiveKey,
    };

    setItemDetail(newConfig);
    setConfigList([...configList, newConfig]);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    configList.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = configList.filter(item => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setConfigList(newPanes);

    const item = newPanes.find(item => item.key === newActiveKey);
    setItemDetail(item);
    setActiveKey(newActiveKey);
  };

  const onEdit = (_: any, action: 'add' | 'remove') => {
    if (action === 'add') {
      console.log('222');
      if (overviewItemRef.current?.form) {
        overviewItemRef.current?.form?.validateFields().then(() => {
          add();
        });
      } else {
        add();
      }
    }
  };

  const handleConfigList = async () => {
    const values = await overviewItemRef.current?.form?.validateFields();

    const lineDevices = overviewItemRef.current?.lineDeviceList;
    const productionBaseId = overviewItemRef.current?.productionBaseId;

    const tabIndex = configList.findIndex(item => item.key === activeKey);
    const newConfigList = [...configList];
    newConfigList[tabIndex] = {
      ...newConfigList[tabIndex],
      ...values,
      lineDevices,
      productionBaseId,
      key: activeKey,
    };
    setConfigList(newConfigList);
    return newConfigList;
  };

  const saveConfig = async () => {
    const newConfigList = await handleConfigList();

    const nextList = newConfigList.map((k: any) => {
      return {
        id: k?.id,
        title: k.title,
        appAnalysisSubscriptionId: k.appAnalysisSubscriptionId,
        ouIds: k.ouIds,
        workCenterIds: (k.originWorkCenterIds ?? []).map((m: number[][]) => m[1]),
        deviceIds: k.deviceIds,
        outputList: (k.outputList ?? []).map((m: any) => ({
          id: m.id,
          outputProductCategoryId: m.outputProductCategoryId,
          outputTypeName: m.outputTypeName,
          unitCode: m.unitCode,
          unitTypeCode: m.unitTypeCode,
          outputIds: m.outputIds,
        })),
        deviceList: (k.deviceList ?? []).map((m: any, index: number) => ({
          id: m.id,
          deviceTypeName: m.deviceTypeName,
          energyMediumId: m.energyMediumId,
          energyUnitIds: m.energyUnitIds,
          sequenceNum: index,
        })),
      };
    });

    // 新增或编辑的列表
    const filterList = nextList.filter(item => {
      // 新增
      if (isNil(item.id)) {
        return true;
      }
      // 修改
      const prevConfig = prevConfigList.find(k => k.id === item.id);
      if (!isEqual(prevConfig, item)) {
        return true;
      }
      return false;
    });

    // 删除的列表
    const deleteList = prevConfigList
      .filter(m => !isNil(m.id))
      .filter(item => {
        return nextList.findIndex(k => k.id === item.id) < 0;
      })
      .map(n => n.id!);

    const adds = filterList.map((k: any) => ({
      id: k?.id,
      title: k.title,
      appAnalysisSubscriptionId: k.appAnalysisSubscriptionId,
      ouIds: k.ouIds,
      workCenterIds: k.workCenterIds,
      workshopDashboardConfigKeyOutputLinkDtoList: (k.outputList ?? []).map((m: any) => ({
        id: isNumber(m?.id) ? m.id : undefined,
        outputTypeName: m.outputTypeName,
        unitTypeCode: m.unitTypeCode,
        unitCode: m.unitCode,
        outputProductCategoryId: m.outputProductCategoryId,
        outputIds: m.outputIds,
      })),
      workshopDashboardConfigDeviceEnergyConsumptionLinkDtoList: (k.deviceList ?? []).map((m: any, index: number) => ({
        id: isNumber(m?.id) ? m.id : undefined,
        deviceTypeName: m.deviceTypeName,
        energyMediumId: m.energyMediumId,
        energyUnitIds: m.energyUnitIds,
        sequenceNum: index,
      })),
      deviceIds: k.deviceIds,
    }));
    if (adds.length || deleteList.length) {
      apiV2WorkshopConfigSavePost({
        addOrUpdate: adds,
        delete: deleteList,
      }).then(res => {
        setIsDetail(true);
      });
    } else {
      setIsDetail(true);
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <FormTitle
        title="总览配置"
        extraContent={
          isDetail ? (
            <Space size={8}>
              <Button
                type="primary"
                onClick={() => {
                  setIsDetail(false);
                }}
              >
                编辑
              </Button>
              {/* <Button
                onClick={() => {
                  navigate('/energy/analysisGroup/overview');
                }}
              >
                返回
              </Button> */}
            </Space>
          ) : (
            <Space size={8}>
              <Button type="primary" onClick={saveConfig}>
                保存
              </Button>
              <Button
                onClick={() => {
                  updateFn();
                }}
              >
                取消
              </Button>
            </Space>
          )
        }
      />
      <Spin spinning={loading}>
        <Tabs
          addIcon={
            <Button icon={<PlusOutlined />} type="link" disabled={isDetail}>
              新增总览配置
            </Button>
          }
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          destroyInactiveTabPane
          items={[
            ...configList.map((item: TabItem) => {
              return {
                label: item.title,
                key: item.key,
                closable: !isDetail && configList.length > 1,
                closeIcon: (
                  <PopConfirm
                    title="确认删除该组总览配置？"
                    onConfirm={() => {
                      remove(item.key);
                    }}
                    okText="删除"
                    cancelText="取消"
                  >
                    <CloseOutlined />
                  </PopConfirm>
                ),
              };
            }),
          ]}
        />
        {itemDetail ? (
          <OverviewItem
            ref={overviewItemRef}
            detail={itemDetail}
            isDetail={isDetail}
            onTitleChange={title => {
              const tabIndex = configList.findIndex(item => item.key === activeKey);
              const newConfigList = [...configList];
              newConfigList[tabIndex] = {
                ...newConfigList[tabIndex],
                title,
              };
              setConfigList(newConfigList);
            }}
          />
        ) : (
          <Empty style={{ marginTop: 100 }} />
        )}
      </Spin>
    </Wrapper>
  );
};

export default WorkShopOverviewConfig;
