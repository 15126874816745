import { Table, Paging, Form, Input, usePaging, CustomFilter } from '@maxtropy/components';
import { FC, Key, useEffect, useMemo, useState } from 'react';

import { ColumnType } from 'antd/es/table';
import {
  getServerAlarmPushRulePage,
  ServerAlarmPushRuleResponse,
  ServerAlarmPushRuleRequest,
} from '../../../../api/serverAlarm';
import { apiServeAlarmRulePagePost } from '@maxtropy/device-customer-apis';
import { SceneTypeEnum } from '.';

export interface RuleModalProps {
  value?: ServerAlarmPushRuleResponse[];
  onChange?: (value: ServerAlarmPushRuleResponse[]) => void;
  ouId?: string;
  sceneType?: number;
  disabledIds?: Key[];
  columns: ColumnType<ServerAlarmPushRuleResponse>[];
}

type SearchParams = Omit<ServerAlarmPushRuleRequest, 'size' | 'page' | 'ouId' | 'sceneType'>;

const RuleModal: FC<RuleModalProps> = ({ value, onChange, ouId, disabledIds, columns, sceneType }) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [rules, setRules] = useState<ServerAlarmPushRuleResponse[]>([]);

  const ruleIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (ouId !== undefined && sceneType === SceneTypeEnum.DiscreteRateAlarm) {
      apiServeAlarmRulePagePost({
        ruleCode: searchParams?.code,
        ruleName: searchParams?.name,
        pageNum: pageOffset,
        pageSize: pageSize,
        ouIds: [Number(ouId)],
      }).then(res => {
        setLoading(true);
        if (res) {
          setTotalCount(res.total as number);
          setRules(res.list as ServerAlarmPushRuleResponse[]);
          setLoading(false);
        }
      });
      return;
    }
    if (ouId !== undefined && sceneType !== undefined) {
      setLoading(true);
      getServerAlarmPushRulePage({ ...searchParams, ouId, sceneType, page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setRules(res.list);
        }
      });
    }
  }, [ouId, sceneType, pageOffset, pageSize, searchParams, setTotalCount]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ServerAlarmPushRuleResponse[]) => {
      const rulesId = rules.map(i => i.id);
      const buildDataMap = new Map<number, ServerAlarmPushRuleResponse>();
      (value ?? [])
        .filter(i => !rulesId.includes(i.id))
        .forEach(i => {
          if (!buildDataMap.has(i.id)) {
            buildDataMap.set(i.id, i);
          }
        });
      selectedRows.forEach(i => {
        if (!buildDataMap.has(i.id)) {
          buildDataMap.set(i.id, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: ServerAlarmPushRuleResponse) => ({
      disabled: (disabledIds ?? []).includes(record.id),
    }),
    selectedRowKeys: ruleIds,
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams({
      ...value,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      code: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter
      colSpan={8}
      form={form}
      onFinish={val => {
        onFinish(val as SearchParams);
      }}
      onReset={onReset}
    >
      <Form.Item name="code" label="编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>
      <Form.Item name="name" label="名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="id"
        columns={columns}
        dataSource={rules}
        showSelectedCount
        selectedCount={(value ?? []).length}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </>
  );
};

export default RuleModal;
