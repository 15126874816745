import {
  getInstantaneousPowerChart,
  getCOPChart,
  getTotalPowerConsumptionChart,
  getCoolingChart,
} from './components/BottomSingleDevice/charts';
import { ChartItem, DatePickerType, IndicatorType } from './type';
import { ChartsDataReq } from './components/BottomSingleDevice';

export interface ParamsOption {
  chartData?: ChartItem[];
  query?: ChartsDataReq;
  stateData?: any;
  baselineConfigData?: {
    granularityType?: DatePickerType | string;
    value?: number;
  }[];
}

export interface DateBtn {
  aggrby: DatePickerType;
}

export interface IndicatorBtn {
  id: IndicatorType;
  btnName?: string;
  unit?: string;
  getChartOption: (value: ParamsOption) => any;
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
}

// 每个指标的配置项
export const indicatorBtns = (): IndicatorBtn[] => {
  return [
    {
      id: IndicatorType.COP,
      getChartOption: getCOPChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.PowerConsumption,
      getChartOption: getTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.CoolingCapacity,
      getChartOption: getCoolingChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.InstantaneousPower,
      getChartOption: getInstantaneousPowerChart,
      dateBtn: [{ aggrby: DatePickerType.MINUTE_1 }, { aggrby: DatePickerType.MINUTE_15 }],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
  ];
};
