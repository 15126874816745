import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBreadcrumbRoutes, useUpdate, Wrapper, Form, FormTitle, SubContent } from '@maxtropy/components';
import RangeEmissions from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions';
import TaskInfoForm from '@/pages/CarbonEmissionReporting/Reporting/TaskinfoForm';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import {
  apiV2CarbonEmissionFillingDetailPost,
  V2CarbonEmissionFillingDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const routes = [{ name: '数据填报' }];

interface Props {}
const CarbonEmissionReportingEdit: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [taskInfoForm] = Form.useForm();
  const [updateState, update] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [fillInDetails, setFillInDetails] = useState<V2CarbonEmissionFillingDetailPostResponse>();

  useEffect(() => {
    if (!id) return;
    apiV2CarbonEmissionFillingDetailPost({ id }).then(res => {
      taskInfoForm.setFieldsValue({
        ...res,
        issuingDate: res?.emissionDate ? dayjs(res?.emissionDate).format('YYYY-MM') : '--',
      });
      setFillInDetails(res);
    });
  }, [id, taskInfoForm, updateState]);

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title={`${fillInDetails?.industryName ?? '--'}`}></FormTitle>

        <SubContent>
          <TaskInfoForm form={taskInfoForm} />

          <RangeEmissions fillInDetails={fillInDetails} update={update} />
        </SubContent>
      </Wrapper>
    </>
  );
};

export default CarbonEmissionReportingEdit;
