import { Space } from 'antd';
import { FC, useEffect, useState, Key } from 'react';
import styles from './index.module.scss';
import DeviceModal from './DeviceModal';
import { IotProtocolType } from '@/shared/types';
import { Button, Modal } from '@maxtropy/components';

export interface SelectDevicesProps {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  type?: number[];
  iotProtocol?: IotProtocolType;
  deviceTypeId?: number;
  objectModalType?: number;
}

const SelectDevices: FC<SelectDevicesProps> = ({
  value,
  onChange,
  type,
  iotProtocol,
  deviceTypeId,
  objectModalType,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<Key[]>([]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (iotProtocol === undefined) {
      Modal.warning({
        title: '请先选择物联层协议',
      });
      return;
    }
    if (iotProtocol === IotProtocolType.MOCKINGBIRD && deviceTypeId === undefined) {
      Modal.warning({
        title: '请先选择适用设备类目',
      });
      return;
    }
    setVisible(true);
  };

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space>
        <div>
          <span>已选择</span>
          <span className={styles.count}>{value?.length || 0}</span>
          <span>个设备</span>
        </div>
        <Button type="link" onClick={onAdd}>
          继续选择
        </Button>
      </Space>
      <Modal
        className={styles.modal}
        title="设备选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <DeviceModal
          type={type}
          iotProtocol={iotProtocol}
          deviceTypeId={deviceTypeId}
          objectModalType={objectModalType}
          onChange={setDataSource}
          value={dataSource}
        />
      </Modal>
    </>
  );
};

export default SelectDevices;
