import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button } from '@maxtropy/components';
import { Space } from 'antd';
import Carousel, { CarouselRef } from 'antd/lib/carousel';
import { useRef } from 'react';
import location from '../../../assets/images/energyGroup/location.png';
import multiLayered from '../../../assets/images/energyGroup/multi-layered.png';
import organization from '../../../assets/images/energyGroup/organization.png';
import production from '../../../assets/images/energyGroup/production.png';
import styles from './index.module.scss';

const EnergyCarousel: React.FC = () => {
  const carouselRef = useRef<CarouselRef>(null);

  return (
    <Space>
      <Button
        type="link"
        icon={<DoubleLeftOutlined />}
        onClick={() => {
          carouselRef.current?.prev();
        }}
      />
      <div className={styles.border}>
        <Carousel dots={{ className: 'dotsStyle' }} autoplaySpeed={5000} autoplay ref={carouselRef}>
          <div>
            <img style={{ marginLeft: 50 }} src={production} alt="轮播图" />
            <p>基于生产系统边界</p>
          </div>
          <div>
            <img style={{ marginLeft: 35 }} src={location} alt="轮播图" />
            <p>基于地理位置边界</p>
          </div>
          <div>
            <img style={{ marginLeft: 35 }} src={organization} alt="轮播图" />
            <p>基于组织边界</p>
          </div>
          <div>
            <img style={{ marginLeft: 30 }} src={multiLayered} alt="轮播图" />
            <p>自定义多层级能源管理边界</p>
          </div>
        </Carousel>
      </div>
      <Button
        type="link"
        icon={<DoubleRightOutlined />}
        onClick={() => {
          carouselRef.current?.next();
        }}
      />
    </Space>
  );
};

export default EnergyCarousel;
