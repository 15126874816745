import React, { useEffect, useRef, useState } from 'react';
import { ReportStrategyExecuteCycle } from './index';
import { DAY_OPTIONS, MONTH_OPTIONS, ReportStrategyTime, WEEK_OPTIONS } from './ExecuteTimePicker';
import { TimePicker } from 'antd';
import { Select } from '@maxtropy/components';
import { ReportStrategyStatisticalCycleEnum } from '@/shared/types';
import {
  DayEnum,
  dayEnumDisplay,
  MonthEnum,
  monthEnumDisplay,
  WeekEnum,
  weekEnumDisplay,
  YearEnum,
  yearEnumDisplay,
} from '../type';
export interface StatisticsTimePickerProps {
  executeCycle: ReportStrategyExecuteCycle;
  statisticalCycle?: ReportStrategyStatisticalCycleEnum;
  value?: [ReportStrategyTime | undefined, ReportStrategyTime | undefined];
  onChange?: (value: [ReportStrategyTime | undefined, ReportStrategyTime | undefined]) => void;
  disabled?: boolean;
}
type RangeTime = ReportStrategyTime | undefined;
const StatisticsTimePicker: React.FC<StatisticsTimePickerProps> = ({
  value,
  onChange,
  statisticalCycle,
  executeCycle,
  disabled = false,
}) => {
  const [timeRangeValue, setTimeRangeValue] = useState<RangeTime[]>();

  const startCacheRangeValueRef = useRef<ReportStrategyTime>({});
  const endCacheRangeValueRef = useRef<ReportStrategyTime>({});

  useEffect(() => {
    startCacheRangeValueRef.current = timeRangeValue?.[0] ?? {};
    endCacheRangeValueRef.current = timeRangeValue?.[1] ?? {};
  }, [timeRangeValue]);

  useEffect(() => {
    if (value && value.length) {
      setTimeRangeValue(value);
    }
  }, [value]);

  const triggerChange = () => {
    onChange?.([startCacheRangeValueRef.current, endCacheRangeValueRef.current]);
  };

  // 按日执行
  if (executeCycle === ReportStrategyExecuteCycle.DAY) {
    // 1小时
    if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
      return (
        <>
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[0]?.day}
            onChange={e => {
              startCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            <Select.Option value={DayEnum.LAST}>{dayEnumDisplay[DayEnum.LAST]}</Select.Option>
            <Select.Option value={DayEnum.NOW}>{dayEnumDisplay[DayEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <TimePicker
            style={{ width: 138 }}
            value={timeRangeValue?.[0]?.hour}
            disabled={disabled}
            showNow={false}
            allowClear={false}
            onChange={e => {
              startCacheRangeValueRef.current.hour = e;
              triggerChange();
            }}
            format="HH"
          />
          &nbsp;至&nbsp;
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[1]?.day}
            onChange={e => {
              endCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            <Select.Option value={DayEnum.LAST}>{dayEnumDisplay[DayEnum.LAST]}</Select.Option>
            <Select.Option value={DayEnum.NOW}>{dayEnumDisplay[DayEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <TimePicker
            style={{ width: 138 }}
            disabled={disabled}
            value={timeRangeValue?.[1]?.hour}
            allowClear={false}
            showNow={false}
            onChange={e => {
              endCacheRangeValueRef.current.hour = e;
              triggerChange();
            }}
            format="HH"
          />
        </>
      );
    }
    // 15分钟
    else {
      return (
        <>
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[0]?.day}
            onChange={e => {
              startCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            <Select.Option value={DayEnum.LAST}>{dayEnumDisplay[DayEnum.LAST]}</Select.Option>
            <Select.Option value={DayEnum.NOW}>{dayEnumDisplay[DayEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <TimePicker
            style={{ width: 138 }}
            disabled={disabled}
            value={timeRangeValue?.[0]?.hour}
            onChange={e => {
              startCacheRangeValueRef.current.hour = e;
              triggerChange();
            }}
            allowClear={false}
            showNow={false}
            minuteStep={15}
            format="HH:mm"
          />
          &nbsp;至&nbsp;
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[1]?.day}
            onChange={e => {
              endCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            <Select.Option value={DayEnum.LAST}>{dayEnumDisplay[DayEnum.LAST]}</Select.Option>
            <Select.Option value={DayEnum.NOW}>{dayEnumDisplay[DayEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <TimePicker
            style={{ width: 138 }}
            disabled={disabled}
            value={timeRangeValue?.[1]?.hour}
            onChange={e => {
              endCacheRangeValueRef.current.hour = e;
              triggerChange();
            }}
            allowClear={false}
            showNow={false}
            minuteStep={15}
            format="HH:mm"
          />
        </>
      );
    }
  }
  // 按月执行
  else if (executeCycle === ReportStrategyExecuteCycle.MONTH) {
    // 1小时
    if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
      return (
        <>
          <div>
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.month}
              onChange={e => {
                startCacheRangeValueRef.current.month = e;
                triggerChange();
              }}
            >
              <Select.Option value={MonthEnum.LAST}>{monthEnumDisplay[MonthEnum.LAST]}</Select.Option>
              <Select.Option value={MonthEnum.NOW}>{monthEnumDisplay[MonthEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.day}
              onChange={e => {
                startCacheRangeValueRef.current.day = e;
                triggerChange();
              }}
            >
              {DAY_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;至&nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.month}
              onChange={e => {
                endCacheRangeValueRef.current.month = e;
                triggerChange();
              }}
            >
              <Select.Option value={MonthEnum.LAST}>{monthEnumDisplay[MonthEnum.LAST]}</Select.Option>
              <Select.Option value={MonthEnum.NOW}>{monthEnumDisplay[MonthEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.day}
              onChange={e => {
                endCacheRangeValueRef.current.day = e;
                triggerChange();
              }}
            >
              {DAY_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ marginTop: 10 }}>
            <Select disabled={disabled} style={{ width: 138 }} value={DayEnum.NOW_OTHER}>
              <Select.Option value={DayEnum.NOW_OTHER}>{dayEnumDisplay[DayEnum.NOW_OTHER]}</Select.Option>
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              disabled={disabled}
              value={timeRangeValue?.[0]?.hour}
              allowClear={false}
              showNow={false}
              onChange={e => {
                startCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH"
            />
            &nbsp;至&nbsp;
            <Select disabled={disabled} style={{ width: 138 }} value={DayEnum.NOW_OTHER}>
              <Select.Option value={DayEnum.NOW_OTHER}>{dayEnumDisplay[DayEnum.NOW_OTHER]}</Select.Option>
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              disabled={disabled}
              value={timeRangeValue?.[1]?.hour}
              allowClear={false}
              showNow={false}
              onChange={e => {
                endCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH"
            />
          </div>
        </>
      );
    } else {
      // 1日
      return (
        <>
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[0]?.month}
            onChange={e => {
              startCacheRangeValueRef.current.month = e;
              triggerChange();
            }}
          >
            <Select.Option value={MonthEnum.LAST}>{monthEnumDisplay[MonthEnum.LAST]}</Select.Option>
            <Select.Option value={MonthEnum.NOW}>{monthEnumDisplay[MonthEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[0]?.day}
            onChange={e => {
              startCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            {DAY_OPTIONS.map(item => (
              <Select.Option key={item.k} value={item.k}>
                {item.v}
              </Select.Option>
            ))}
          </Select>
          &nbsp;至&nbsp;
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[1]?.month}
            onChange={e => {
              endCacheRangeValueRef.current.month = e;
              triggerChange();
            }}
          >
            <Select.Option value={MonthEnum.LAST}>{monthEnumDisplay[MonthEnum.LAST]}</Select.Option>
            <Select.Option value={MonthEnum.NOW}>{monthEnumDisplay[MonthEnum.NOW]}</Select.Option>
          </Select>
          &nbsp;
          <Select
            disabled={disabled}
            style={{ width: 138 }}
            value={timeRangeValue?.[1]?.day}
            onChange={e => {
              endCacheRangeValueRef.current.day = e;
              triggerChange();
            }}
          >
            {DAY_OPTIONS.map(item => (
              <Select.Option key={item.k} value={item.k}>
                {item.v}
              </Select.Option>
            ))}
          </Select>
        </>
      );
    }
  }
  // 按周执行
  else if (executeCycle === ReportStrategyExecuteCycle.WEEK) {
    // 15分钟
    if (statisticalCycle === ReportStrategyStatisticalCycleEnum.QUARTER) {
      return (
        <>
          <div>
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.week}
              onChange={e => {
                startCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.dayOfWeek}
              onChange={e => {
                startCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.hour}
              disabled={disabled}
              showNow={false}
              allowClear={false}
              minuteStep={15}
              onChange={e => {
                startCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH:mm"
            />
            &nbsp;至&nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.week}
              onChange={e => {
                endCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.dayOfWeek}
              onChange={e => {
                endCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.hour}
              disabled={disabled}
              showNow={false}
              allowClear={false}
              minuteStep={15}
              onChange={e => {
                endCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH:mm"
            />
          </div>
        </>
      );
      // 1小时
    } else if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
      return (
        <>
          <div>
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.week}
              onChange={e => {
                startCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.dayOfWeek}
              onChange={e => {
                startCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.hour}
              disabled={disabled}
              showNow={false}
              allowClear={false}
              onChange={e => {
                startCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH"
            />
            &nbsp;至&nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.week}
              onChange={e => {
                endCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.dayOfWeek}
              onChange={e => {
                endCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;
            <TimePicker
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.hour}
              disabled={disabled}
              showNow={false}
              allowClear={false}
              onChange={e => {
                endCacheRangeValueRef.current.hour = e;
                triggerChange();
              }}
              format="HH"
            />
          </div>
        </>
      );
      // 1日
    } else {
      return (
        <>
          <div>
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.week}
              onChange={e => {
                startCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[0]?.dayOfWeek}
              onChange={e => {
                startCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
            &nbsp;至&nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.week}
              onChange={e => {
                endCacheRangeValueRef.current.week = e;
                triggerChange();
              }}
            >
              <Select.Option value={WeekEnum.LAST}>{weekEnumDisplay[WeekEnum.LAST]}</Select.Option>
              <Select.Option value={WeekEnum.NOW}>{weekEnumDisplay[WeekEnum.NOW]}</Select.Option>
            </Select>
            &nbsp;
            <Select
              disabled={disabled}
              style={{ width: 138 }}
              value={timeRangeValue?.[1]?.dayOfWeek}
              onChange={e => {
                endCacheRangeValueRef.current.dayOfWeek = e;
                triggerChange();
              }}
            >
              {WEEK_OPTIONS.map(item => (
                <Select.Option key={item.k} value={item.k}>
                  {item.v}
                </Select.Option>
              ))}
            </Select>
          </div>
        </>
      );
    }
  }
  // 按年执行
  else {
    return (
      <>
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={timeRangeValue?.[0]?.year}
          onChange={e => {
            startCacheRangeValueRef.current.year = e;
            triggerChange();
          }}
        >
          <Select.Option value={YearEnum.LAST}>{yearEnumDisplay[YearEnum.LAST]}</Select.Option>
          <Select.Option value={YearEnum.NOW}>{yearEnumDisplay[YearEnum.NOW]}</Select.Option>
        </Select>
        &nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={timeRangeValue?.[0]?.month}
          onChange={e => {
            startCacheRangeValueRef.current.month = e;
            triggerChange();
          }}
        >
          {MONTH_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;
        <Select
          disabled={disabled || statisticalCycle === ReportStrategyStatisticalCycleEnum.MONTH}
          style={{ width: 138 }}
          value={timeRangeValue?.[0]?.day}
          onChange={e => {
            startCacheRangeValueRef.current.day = e;
            triggerChange();
          }}
        >
          {DAY_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;至&nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={timeRangeValue?.[1]?.year}
          onChange={e => {
            endCacheRangeValueRef.current.year = e;
            triggerChange();
          }}
        >
          <Select.Option value={YearEnum.LAST}>{yearEnumDisplay[YearEnum.LAST]}</Select.Option>
          <Select.Option value={YearEnum.NOW}>{yearEnumDisplay[YearEnum.NOW]}</Select.Option>
        </Select>
        &nbsp;
        <Select
          disabled={disabled}
          style={{ width: 138 }}
          value={timeRangeValue?.[1]?.month}
          onChange={e => {
            endCacheRangeValueRef.current.month = e;
            triggerChange();
          }}
        >
          {MONTH_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
        &nbsp;
        <Select
          disabled={disabled || statisticalCycle === ReportStrategyStatisticalCycleEnum.MONTH}
          style={{ width: 138 }}
          value={timeRangeValue?.[1]?.day}
          onChange={e => {
            endCacheRangeValueRef.current.day = e;
            triggerChange();
          }}
        >
          {DAY_OPTIONS.map(item => (
            <Select.Option key={item.k} value={item.k}>
              {item.v}
            </Select.Option>
          ))}
        </Select>
      </>
    );
  }
};

export default StatisticsTimePicker;
