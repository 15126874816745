import { EnergyFlowChartResponse } from '@/api/plustekSankey';
import ReactEcharts from 'echarts-for-react';
import { getNodeMaxDepth } from '../UnitSankey';

interface Props {
  isMedium: boolean;
  data?: EnergyFlowChartResponse;
  physicalUnitName?: string;
}

const color = ['#2D8DFF', '#CE90D1'];

const SankeyCharts: React.FC<Props> = ({ isMedium, data, physicalUnitName }) => {
  const getChartOption = () => {
    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        backgroundColor: '#000',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
          fontSize: 12,
        },
        formatter: (v: any) => {
          let res = '';
          if (v.dataType === 'node') {
            res = `<div>
          <p>${v.data.energyUnitName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${Number(v.value).toFixed(
            2
          )}${physicalUnitName}</p>
          </div>`;
          }
          if (v.dataType === 'edge') {
            const sourceName = data?.nodes?.find(i => i.key === v.data.source)?.energyUnitName;
            const targetName = data?.nodes?.find(i => i.key === v.data.target)?.energyUnitName;
            res = `<div>
          <p>${sourceName}——${targetName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${Number(v.value).toFixed(
            2
          )}${physicalUnitName}</p>
          </div>`;
          }

          return res;
        },
      },
      series: [
        {
          type: 'sankey',
          left: 0,
          right: 0,
          layoutIterations: 0, // 解决能流图衔接问题
          nodeAlign: 'justify',
          label: {
            color: 'rgba(255,255,255,0.85)',
            rich: {
              a: {
                color: '#FF4D4F',
              },
              b: {
                color: '#ABD335',
              },
            },
            formatter: (v: any) => {
              if (v.data.energyUnitName === '未知能耗') {
                return `{a|${v.data.energyUnitName}}`;
              }
              if (v.data.energyUnitName === '用能损耗') {
                return `{b|${v.data.energyUnitName}}`;
              }
              return `${v.data.energyUnitName}`;
            },
          },
          labelLayout: {
            hideOverlap: true,
          },
          draggable: false,
          data: data?.nodes?.map(i => ({
            ...i,
            itemStyle: {
              color:
                i.energyUnitName === '用能损耗'
                  ? '#ABD335'
                  : i.energyUnitName === '未知能耗'
                  ? '#FF4D4F'
                  : i.depth === 0
                  ? color[0]
                  : color[1],
            },
            label: {
              position: i.depth === getNodeMaxDepth(data?.nodes) ? 'left' : 'right',
            },
          })),
          links: data?.links?.map(i => {
            return {
              ...i,
              lineStyle: {
                color: 'gradient',
                opacity: 0.3,
                curveness: 0.5,
              },
            };
          }),
        },
      ],
    };
  };
  return (
    <ReactEcharts
      style={{
        width: isMedium ? '976px' : '542px',
        height: 177,
      }}
      option={getChartOption()}
      notMerge
      lazyUpdate={false}
    />
  );
};

export default SankeyCharts;
