import { FC, useEffect, useState } from 'react';
import SelectRuleGroupModalChildren from './ModalChildren';
import { getDeviceRuleGroupId, unBindDeviceRuleGroup, updateDeviceRuleGroupId } from '../../../../api/ruleGroup';
import { getEdgeDeviceInfoSimple, EdgeDeviceDetailSimple } from '../../../../api/edgeDevice';
import { Device } from '../../../../api/device';
import styles from './index.module.scss';
import { IotProtocolType } from '@/shared/types';
import { Modal } from '@maxtropy/components';
import { isNil } from 'lodash-es';

interface SelectRuleGroupModalProps {
  device?: Device;
  onClose: () => void;
  updateFn?: () => void;
  visible?: boolean;
  isThirdRuleGroup?: boolean;
  thirdRuleTypeId?: number;
  physicalModelId?: number;
}

const SelectRuleGroupModal: FC<SelectRuleGroupModalProps> = ({
  device,
  onClose,
  updateFn,
  visible = false,
  isThirdRuleGroup,
  thirdRuleTypeId,
  physicalModelId,
}) => {
  const [ruleGroupId, setRuleGroupId] = useState<number>();
  const [info, setInfo] = useState<EdgeDeviceDetailSimple>();

  useEffect(() => {
    if (device) {
      getDeviceRuleGroupId(device.id).then(res => {
        if (res) {
          setRuleGroupId(res.ruleGroupId);
        }
      });
    }
  }, [device]);

  useEffect(() => {
    if (!isThirdRuleGroup) {
      if (device) {
        getEdgeDeviceInfoSimple(device.id.toString()).then(res => {
          if (res) {
            setInfo(res);
          }
        });
      } else {
        setInfo(undefined);
      }
    }
  }, [device, isThirdRuleGroup]);

  const onOk = () => {
    if (ruleGroupId && device) {
      updateDeviceRuleGroupId({
        deviceId: device.id,
        ruleGroupId,
      }).then(res => {
        if (res.flag) {
          onClose();
          updateFn?.();
        }
      });
    }
  };

  const unBind = () => {
    if (ruleGroupId && device) {
      Modal.confirm({
        title: '确定解绑？',
        okText: '继续',
        onOk: () => {
          if (isNil(device.id)) return;
          unBindDeviceRuleGroup(device.id).then(res => {
            if (res.flag) {
              onClose();
              updateFn?.();
            }
          });
        },
      });
    }
  };

  return (
    <Modal
      className={styles.modal}
      title="规则组选择"
      open={visible}
      destroyOnClose
      onOk={onOk}
      onCancel={onClose}
      size="large"
    >
      {device && info && (
        <SelectRuleGroupModalChildren
          value={ruleGroupId}
          onChange={setRuleGroupId}
          unBind={unBind}
          deviceId={device.id}
          iotProtocol={info.iotProtocol}
          deviceTypeId={info.deviceTypeId}
          physicalModelId={physicalModelId}
        />
      )}
      {device && isThirdRuleGroup && (
        <SelectRuleGroupModalChildren
          value={ruleGroupId}
          onChange={setRuleGroupId}
          unBind={unBind}
          deviceId={device.id}
          iotProtocol={IotProtocolType.LONGSHINE}
          deviceTypeId={thirdRuleTypeId}
          physicalModelId={physicalModelId}
        />
      )}
    </Modal>
  );
};

export default SelectRuleGroupModal;
