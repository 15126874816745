import { Modal } from '@maxtropy/components';
import {
  apiV2CircuitLabelCheckPost,
  apiV2CircuitLabelEditPost,
  apiV2CircuitLabelSavePost,
} from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { Form, Input } from 'antd';
import { useEffect, useMemo } from 'react';
interface Iprops {
  opType: string;
  id?: number;
  name?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const CircuitLabelModal = ({ opType, id, name, onConfirm, onCancel }: Iprops) => {
  const [form] = Form.useForm();
  const modalTile = useMemo(() => (opType === 'add' ? '新建回路标签' : '编辑回路标签'), [opType]);
  // 远程校验名称是否重复
  const { loading: remoteValidateLoading, runAsync: remoteValidateApi } = useRequest(
    valid => apiV2CircuitLabelCheckPost({ ...valid }).then(res => res.result),
    { manual: true }
  );
  // 保存/编辑api
  const { loading: saveLoading, runAsync: saveApi } = useRequest(
    params => {
      return opType === 'add' ? apiV2CircuitLabelSavePost({ ...params }) : apiV2CircuitLabelEditPost({ ...params });
    },
    {
      manual: true,
      refreshDeps: [opType],
    }
  );
  useEffect(() => {
    if (opType === 'edit') {
      form.setFieldsValue({ name });
    }
  }, [opType, name, form]);
  // 弹框确认按钮
  const confirmBtn = () => {
    form.validateFields().then(async valid => {
      // 验证是否重复
      let remoteValideStatus = await remoteValidateApi({ ...valid });
      if (remoteValideStatus) {
        // 重复
        form.setFields([{ name: 'name', errors: ['名称重复, 请重新输入'] }]);
        return;
      }

      saveApi({ ...valid, id: id ?? '' }).then(_ => {
        onConfirm?.();
      });
    });
  };
  return (
    <Modal
      title={modalTile}
      open
      width={500}
      onOk={confirmBtn}
      confirmLoading={remoteValidateLoading || saveLoading}
      onCancel={() => onCancel?.()}
    >
      <Form form={form}>
        <Form.Item
          label="回路标签名称"
          name="name"
          rules={[
            {
              required: true,
              pattern: /^.{2,20}$/,
              message: '请输入2-20个字符',
            },
            {
              validator(rule, value, callback) {
                if (value && value.includes(',')) {
                  return Promise.reject(`不支持输入","`);
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="请输入"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CircuitLabelModal;
