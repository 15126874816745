import { AutoFillSupport } from '@/pages/ProductCarbonFootprint/NewModel/utils';
import { Dayjs } from 'dayjs';

export enum StepsType {
  First = 0,
  Second,
  Third,
}
//活动数据填报
export interface CalculateDataType {
  id: number;
  name: string;
  parentId: number;
  children: Procedure[];
  isProcedure: number;
  isInput: number;
  type: string;
  actualQuantity?: number;
  theoreticalQuantity: number;
  autoGatherFlag: number;
  autoGatherMsg?: string;
  carbonFootPrint?: number;
  unitCarbonFootPrint: number;
  percent: number;
  productDtoList: ProductDto[];
}

export interface ProductDto {
  id: number;
  materialId: number;
  code: string;
  name: string;
  quantity: number;
  spec: string;
  dataOrigin: number;
  unitCode: string;
  unitTypeCode: number;
  unitWeight: number;
  remark: string;
  tenantMcid: string;
  createTime: string;
  updateTime: string;
  procedureId: number;
  percent: number;
  isMaster: number;
}

export interface Procedure {
  id: number;
  name: string;
  parentId: number;
  children: Procedure[];
  isProcedure: number;
  isInput: number;
  type: string;
  actualQuantity?: number;
  theoreticalQuantity?: number;
  autoGatherFlag: number;
  autoGatherFlagMsg?: string;
  carbonFootPrint?: number;
  unitCarbonFootPrint: number;
  percent: number;
  filledFlag: number;
  unitCode?: string;
  productDtoList: ProductDto[];
  materialOrEnergyId?: number;
  autoFillSupport?: AutoFillSupport;
}

//lca
export interface LCAOptionsType {
  label: string;
  value: number;
  outputProductId?: number;
}

export interface ProductSheetOtionsType {
  label: string;
  value: string;
}
//第一步数据
export interface FirstDataType {
  carbonLcaId?: number;
  quantity?: number;
  productionStartTime: Dayjs;
  productionEndTime: Dayjs;
  productSheetCode?: string;
  productSheetId?: number;
  unitCode?: string;
}
