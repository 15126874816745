import { Button, Divider } from 'antd';
import React, { FC } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { V2MediumIndicatorDashboardGetDashboardConfigPostResponse } from '@maxtropy/device-customer-apis-v2';

export interface IBothConfigCard {
  configData: V2MediumIndicatorDashboardGetDashboardConfigPostResponse;
}

const BothConfigCard: FC<IBothConfigCard> = props => {
  const { configData } = props;
  return (
    <>
      <div className={styles.dashboardSty}>
        <div className={styles.eachDivSty} style={{ width: 300 }}>
          <div className={styles.dashboardTitleSty}>当日总用量</div>
          {!configData.right ? (
            <div className={classNames(styles.dashboardInnerContentSty, styles.noPermissionSty)}>无对应过程权限</div>
          ) : (
            <div className={styles.dashboardInnerContentSty}>
              {!configData.energyMediumData || configData.energyMediumData?.length === 0 ? (
                <div className={styles.noPermissionSty}>暂无数据</div>
              ) : (
                configData.energyMediumData?.map(i => (
                  <div key={i.energyMediumId} className={styles.dashboardItemSty}>
                    <div className={styles.dashboardItemTitleSty}>
                      {i.energyMediumName} ({i.indicatorName})
                    </div>
                    <div className={styles.dashboardItemContentSty}>{i.value ? i.value.toFixed(2) + i.unit : '--'}</div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        <Divider type="vertical" className={styles.dividerSty} />

        <div className={styles.eachDivSty}>
          <div className={styles.alarmTitleSty}>
            <div className={styles.dashboardTitleSty}>报警信息</div>
            <div>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() =>
                  window.open(`/device/alarm/record?deviceAssetCode=${configData.deviceAssetCode}&state=1`)
                }
              >
                查看详情
              </Button>
            </div>
          </div>
          <div className={styles.dashboardItemSty}>
            <div className={styles.dashboardItemTitleSty}>设备未恢复报警数</div>
            <div className={styles.dashboardItemContentSty}>{configData.unRecoverDeviceNum ?? '--'}</div>
          </div>
          <div className={styles.dashboardItemSty}>
            <div className={styles.dashboardItemTitleSty}>近7日设备报警数</div>
            <div className={`${styles.dashboardItemContentSty} ${styles.alarmColor}`}>
              {configData.deviceAlarmNum ?? '--'}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BothConfigCard;
