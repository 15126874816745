import React, { useState } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button } from '@maxtropy/components';
import styles from './index.module.scss';
import CalcModal from './CalcModal';
import { Dayjs } from 'dayjs';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
interface FooterTipProps {
  dates?: RangeValue;
}

const FooterTip: React.FC<FooterTipProps> = ({ dates }) => {
  const [showModal, setShowCModal] = useState<boolean>(false);
  return (
    <>
      <div className={styles.footer}>
        <QuestionCircleFilled style={{ color: 'var(--mx-warning-color)', fontSize: 18 }} />
        <span className={styles.tip}>本页面显示用能数据异常？</span>

        <Button type="link" className={styles.link} onClick={() => setShowCModal(true)}>
          了解详情
        </Button>
      </div>
      <CalcModal show={showModal} initDates={dates} close={() => setShowCModal(false)} />
    </>
  );
};

export default FooterTip;
