import {
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useAsync,
  CustomFilter,
  Input,
  Form,
  Select,
  Radio,
  DatePicker,
} from '@maxtropy/components';
import { Col, Row, FormInstance } from 'antd';
import { ColumnType } from 'antd/es/table';
import { forwardRef, ForwardRefRenderFunction, useEffect, useMemo, useState } from 'react';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';

import styles from './index.module.scss';

import { CustomerListRes, Device, getElectricityAccountCustomerList } from '../../../../../../api/device';
import { getElectricityDevicePage } from '../../../../../../api/electricity';
import { Link } from 'react-router-dom';
import Cascader, { DefaultOptionType } from 'antd/es/cascader';
import { getDeviceTypeTree } from '@/api/deviceType';

interface FilterParams {
  codeOrName?: string;
  deviceTypeIds?: Array<number>;
  customerMcid?: string;
}

interface SearchParams {
  deviceTypeIds?: Array<Array<number>>;
  codeOrName?: string;
  customerMcid?: string;
}
const columns: ColumnType<Device>[] = [
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备SN',
    dataIndex: 'sn',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DeviceModalProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  accountId?: number;
  addForm: FormInstance;
}

export interface DeviceModalRefProps {
  getDataPropertyId: () => number | undefined;
}

const formLayout = {
  labelCol: { flex: '140px' },
};

const SelectDevice: ForwardRefRenderFunction<DeviceModalRefProps, DeviceModalProps> = (
  { value, onChange, accountId, addForm },
  ref
) => {
  const [form] = Form.useForm();
  const [devices, setDevices] = useState<Device[]>([]);
  const [filterParams, setFilterParams] = useState<FilterParams>();
  const deviceTypeData = useAsync(getDeviceTypeTree);
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [customerMcidList, setCustomerMcidList] = useState<CustomerListRes[]>([]);

  useEffect(() => {
    if (accountId) {
      setLoading(true);
      getElectricityDevicePage({
        ...filterParams,
        electricityAccountId: accountId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        setDevices(res.list);
        setTotalCount(res.total);
      });
    }
  }, [accountId, pageOffset, pageSize, filterParams, setTotalCount]);

  useEffect(() => {
    if (accountId) {
      getElectricityAccountCustomerList(accountId).then(setCustomerMcidList);
    }
  }, [accountId]);

  const customerMcidOptions = useMemo(() => {
    if (customerMcidList.length !== 0) {
      return customerMcidList.map(i => ({ label: i.fullName, value: i.mcid }));
    }
  }, [customerMcidList]);

  const onFinish = (val: SearchParams) => {
    const deviceTypeIds = getChildNodesByParentIds(val.deviceTypeIds, deviceTypeData?.tree);
    setPageOffset(1);
    setFilterParams({
      ...val,
      deviceTypeIds,
    });
    onChange?.([]);
  };

  const onReset = () => {
    setPageOffset(1);
    setFilterParams(undefined);
    onChange?.([]);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Device[]) => {
      const currentPageDeviceIds = devices.map(i => i.id);
      onChange?.([...(value ?? []).filter(i => !currentPageDeviceIds.includes(i)), ...selectedRows.map(i => i.id)]);
    },
    selectedRowKeys: value ?? [],
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter<FilterParams>
      form={form}
      colSpan={8}
      onFinish={val => onFinish(val as SearchParams)}
      onReset={onReset}
    >
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>

      <Form.Item name="customerMcid" label="所属组织">
        <Select placeholder="请选择" options={customerMcidOptions} />
      </Form.Item>

      <Form.Item name="deviceTypeIds" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <div className={styles.wrapper}>
      {filters}
      <Form labelAlign="left" labelWrap {...formLayout} form={addForm}>
        <Row>
          <Col span={8}>
            <Form.Item
              name="recalculate"
              label="是否补充历史数据"
              rules={[{ required: true, message: '请选择' }]}
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="statisticsDate" label="开始统计日期" rules={[{ required: true, message: '请选择' }]}>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <p style={{ float: 'left', marginBottom: 10 }}>
        点击前往
        <Link to={'/device/manage/device/create'}>&nbsp;添加设备</Link>
      </p>

      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        showSelectedCount
        selectedCount={(value ?? []).length}
        scroll={{ y: 300 }}
        rowKey="id"
        columns={columns}
        dataSource={devices}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
};

export default forwardRef(SelectDevice);
