import React from 'react';
import { Space } from 'antd';
import { AttributeField, DeviceAttribute } from '../../types';
import FieldItem from '../FieldItem';

export interface AttributeItemProps {
  attribute: DeviceAttribute;
  disabled: boolean;
  isDetail?: boolean;
  style?: React.CSSProperties;
  className?: string;
  buttons?: React.ReactNode;
  hideTitle?: boolean;
  checkable?: boolean;
  onCheck?: (checked: boolean, field: AttributeField) => void;
}

const AttributeItem: React.FC<AttributeItemProps> = ({
  attribute,
  disabled,
  isDetail,
  style,
  className,
  buttons,
  hideTitle,
  checkable,
  onCheck,
}) => {
  return (
    <div
      className={className}
      style={{
        borderTop: '1px dashed rgba(var(--base-text-color), .12)',
        padding: '16px 0 0',
        fontSize: 14,
        ...style,
      }}
    >
      {!hideTitle && (
        <Space size={10}>
          {<div style={{ fontWeight: 'bold', color: 'var(--mx-text-base-color)' }}>{attribute.name}</div>}
          {buttons}
        </Space>
      )}
      <div style={{ paddingTop: 16 }}>
        {attribute.deviceFields.map(item => (
          <FieldItem
            key={item.id}
            disabled={disabled}
            isDetail={isDetail}
            field={item}
            checkable={checkable}
            onCheck={onCheck}
          />
        ))}
      </div>
    </div>
  );
};

export default AttributeItem;
