import {
  EllipsisSpan,
  Paging,
  usePaging,
  useUpdate,
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  CustomFilter,
  Input,
  DatePicker,
  Select,
  Modal,
  Form,
  Table,
  TreeSelect,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  abortSaleElectricityContract,
  ElectricitySaleContractPageSearchRequest,
  ElectricitySaleContractStatus,
  ElectricitySaleContractStatusType,
  ElectricitySaleContractVo,
  extendSaleElectricityContract,
  getElectricitySalesContractList,
  getPropertyList,
  getPropertyListOwned,
  PropertyItem,
} from '../../../api/electricitySalesContract';
import { getSaleClientList, SaleClientInfo } from '../../../api/sale';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/utils';
import styles from './index.module.scss';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { PlusOutlined } from '@ant-design/icons';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
const formatString = 'YYYY-MM-DD';

const columns = [
  {
    title: '物业管理合同编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '存档编号',
    dataIndex: 'archiveCode',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同有效期',
    dataIndex: 'contractValidity',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: ElectricitySaleContractVo) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format(formatString)} ~ ${dayjs(record.endTime).format(formatString)}`}
      />
    ),
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 110,
    ellipsis: { showTitle: true },
    render: (v: ElectricitySaleContractStatusType, record: ElectricitySaleContractVo) => {
      let statusText = '';
      switch (v) {
        case ElectricitySaleContractStatusType.INEFFECTIVE:
          statusText = '未生效';
          break;
        case ElectricitySaleContractStatusType.EFFECTIVE:
          statusText = '生效中';
          break;
        case ElectricitySaleContractStatusType.EXPIRED:
          statusText = '已过期';
          break;
        case ElectricitySaleContractStatusType.ABORTED:
          statusText = '已终止';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children ?? []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

function contractStatusOptions() {
  const status: Array<{ label: string; value: ElectricitySaleContractStatusType }> = [
    {
      label: ElectricitySaleContractStatus[ElectricitySaleContractStatusType.INEFFECTIVE],
      value: ElectricitySaleContractStatusType.INEFFECTIVE,
    },
    {
      label: ElectricitySaleContractStatus[ElectricitySaleContractStatusType.EFFECTIVE],
      value: ElectricitySaleContractStatusType.EFFECTIVE,
    },
    {
      label: ElectricitySaleContractStatus[ElectricitySaleContractStatusType.EXPIRED],
      value: ElectricitySaleContractStatusType.EXPIRED,
    },
    {
      label: ElectricitySaleContractStatus[ElectricitySaleContractStatusType.ABORTED],
      value: ElectricitySaleContractStatusType.ABORTED,
    },
  ];
  return status.map(i => ({ label: i.label, value: i.value }));
}
export interface SearchParams extends Omit<ElectricitySaleContractPageSearchRequest, 'page' | 'size'> {}

const ElectricitySalesContract: FC = () => {
  const hasContractAdd = useHasPermission(PermissionsType.B_ELECSALECONADD); // 新增用电销售合同
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo; // 分页的一些配置
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [data, setData] = useState<ElectricitySaleContractVo[]>([]); // 后端返回数据
  const [eachExtensionRecord, setEachExtensionRecord] = useState<ElectricitySaleContractVo>();
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [eachExtensionRecordForm] = Form.useForm(); // 延长有效期的表单
  const [property, setProperty] = useState<PropertyItem[]>(); // 所属物业管理列表
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [refreshState, refresh] = useUpdate();
  const searchParamsRef = useRef<SearchParams>();
  const customerMcid: string | undefined = Form.useWatch('customerMcid', searchForm);

  const getPropertyLists = useCallback((mcid: string) => {
    return getPropertyList([mcid]).then(res => {
      setProperty(res);
      const search = {
        customerMcid: mcid,
        propertyManagementConfigId: res.at(0)?.id,
      };
      return search;
    });
  }, []);

  useEffect(() => {
    if (!customerMcid) {
      getPropertyListOwned().then(res => {
        setProperty(res);
      });
    }
  }, [customerMcid]);

  useEffect(() => {
    // 获取关联组织
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
    // 获取客户名称
    getSaleClientList().then(setCustomList);
  }, [searchForm]);

  useEffect(() => {
    if (customerMcid) {
      searchForm.resetFields(['propertyManagementConfigId']);
      getPropertyLists(customerMcid)?.then(search => {
        searchForm.setFieldsValue(search);
      });
    }
  }, [customerMcid, searchForm, getPropertyLists]);

  // 所属物业选项
  const propertyOptions = useMemo(() => {
    return property?.map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [property]);

  const customListOptions = useMemo(() => {
    if (customList.length !== 0) {
      return customList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [customList]);

  const getData = useCallback((query: ElectricitySaleContractPageSearchRequest) => {
    setLoading(true);
    getElectricitySalesContractList(query).then(res => {
      setLoading(false);
      setData(res.list);
      setTotalCount(res.total);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: Omit<SearchParams, 'startTime'> & { startTime: RangeValue }) => {
    const createDateStart =
      val.startTime === undefined || val.startTime === null ? undefined : val.startTime[0]?.format(formatString);
    const createDateEnd =
      val.startTime === undefined || val.startTime === null ? undefined : val.startTime[1]?.format(formatString);
    const params = {
      saleClientId: val.saleClientId,
      name: val.name,
      address: val.address,
      startTime: createDateStart,
      endTime: createDateEnd,
      saleElectricitySettlementRuleId: val.saleElectricitySettlementRuleId,
      status: val.status,
      archiveCode: val.archiveCode,
      propertyManagementConfigId: val.propertyManagementConfigId,
    };
    searchParamsRef.current = params;
    setPageOffset(1);
    refresh();
  };

  const onReset = () => {
    searchParamsRef.current = undefined;
    setPageOffset(1);
    refresh();
  };

  useEffect(() => {
    const searchParams = searchParamsRef.current;
    getData({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm, pageOffset, pageSize, refreshState]);

  const showTerminationConfirm = (record: ElectricitySaleContractVo) => {
    Modal.confirm({
      title: <span>确认终止？终止合同后，结算规则不再生效。系统以终止时间进行最后一次结算。</span>,
      content: (
        <div>
          <span style={{ color: 'red' }}>终止后不可恢复，</span>
          <span style={{ color: 'grey' }}>你还要继续吗？</span>
        </div>
      ),
      okText: '继续',
      onOk() {
        abortSaleElectricityContract(record.id).then(res => {
          // message.success('终止成功');
          refresh();
        });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  const handleExtensionValidity = (record: ElectricitySaleContractVo) => {
    if (dayjs(record.endTime).isAfter(dayjs().endOf('date'))) {
      eachExtensionRecordForm.setFieldsValue({ endTime: dayjs(record.endTime) });
    }

    setEachExtensionRecord(record);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 240,
      render: (record: ElectricitySaleContractVo) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasContractAdd && (
              <Button
                disabled={
                  !(
                    record.status === ElectricitySaleContractStatusType.INEFFECTIVE ||
                    record.status === ElectricitySaleContractStatusType.EFFECTIVE ||
                    record.status === ElectricitySaleContractStatusType.EXPIRED
                  )
                }
                type="link"
                onClick={() => showTerminationConfirm(record)}
              >
                终止
              </Button>
            )
          }
          {hasContractAdd && (
            <Button
              disabled={
                !(
                  record.status === ElectricitySaleContractStatusType.INEFFECTIVE ||
                  record.status === ElectricitySaleContractStatusType.EFFECTIVE ||
                  record.status === ElectricitySaleContractStatusType.EXPIRED
                )
              }
              type="link"
              onClick={() => handleExtensionValidity(record)}
            >
              延长有效期
            </Button>
          )}
          {hasContractAdd && (
            <Button
              disabled={
                !(
                  record.status === ElectricitySaleContractStatusType.INEFFECTIVE ||
                  record.status === ElectricitySaleContractStatusType.EFFECTIVE
                )
              }
              type="link"
              onClick={() => {
                navigate(`/operation/salesContract/electricity/edit/${record.id}`);
              }}
            >
              编辑
            </Button>
          )}
          {/* {
                        // hasContractDateEdit &&
                        (
                            <Button
                                disabled={!record.editable || record.status === ElectricityContractStatus.INVALID}
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                type="link"
                                onClick={() => {
                                    // setAdjustRecord(record)
                                }}
                            >复制</Button>
                        )
                    } */}
          {
            // hasPriceRateEdit &&
            <Button
              // disabled={!record.editable || record.status === ElectricityContractStatus.INVALID || !(dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date')))}
              type="link"
            >
              <Link to={`/operation/salesContract/electricity/detail/${record.id}`}>查看</Link>
            </Button>
          }
        </Space>
      ),
    },
  ];

  const onAdd = () => {
    setEachExtensionRecord(undefined);
  };

  const onClose = () => {
    setEachExtensionRecord(undefined);
    eachExtensionRecordForm.resetFields();
  };

  const onSubmit = () => {
    eachExtensionRecordForm.validateFields().then((values: { endTime: Dayjs }) => {
      let extendTime = values.endTime.format('YYYY-MM-DD');
      extendSaleElectricityContract(eachExtensionRecord!.id, { endTime: extendTime }).then(res => {
        // message.success('延长有效期成功！');
        onClose();
        refresh();
      });
    });
  };

  const disabledDate = (current: Dayjs) => {
    if (dayjs().isAfter(eachExtensionRecord?.endTime)) {
      return current && current < dayjs().subtract(1, 'days').endOf('day');
    }
    return current && current < dayjs(eachExtensionRecord?.endTime).endOf('day');
  };
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <>
      <Wrapper
        routes={breadcrumbRoutes?.routes}
        className="page_wrapper"
        filters={
          <CustomFilter form={searchForm} onFinish={(val: any) => onFinish(val)} onReset={onReset}>
            <Form.Item label="所属组织" name="customerMcid">
              <TreeSelect
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeDefaultExpandAll
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                showArrow
                showSearch={false}
                treeData={treeData}
                placeholder="请选择"
              />
            </Form.Item>
            <Form.Item noStyle dependencies={['customerMcid']}>
              {() => {
                return (
                  <Form.Item label="所属物业管理" name="propertyManagementConfigId">
                    <Select placeholder="请选择" options={propertyOptions} />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item label="客户名称" name="saleClientId">
              <Select placeholder="请选择" options={customListOptions} showSearch optionFilterProp="label" />
            </Form.Item>

            <Form.Item label="合同名称" name="name">
              <Input placeholder=" 请输入" />
            </Form.Item>

            <Form.Item name="status" label="合同状态">
              <Select placeholder="请选择" options={contractStatusOptions()} />
            </Form.Item>

            <Form.Item label="存档编号" name="archiveCode">
              <Input placeholder=" 请输入" />
            </Form.Item>
            <Form.Item name="startTime" label="合同开始日期">
              <DatePicker.RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </CustomFilter>
        }
      >
        {hasContractAdd && (
          <div className={styles.operationArea}>
            <Button icon={<PlusOutlined />} type="primary" onClick={onAdd}>
              <Link to={`/operation/salesContract/electricity/create`}>新建</Link>
            </Button>
          </div>
        )}
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />

        <Modal
          title={`延长有效期`}
          size="normal"
          open={!!eachExtensionRecord}
          onCancel={onClose}
          contentClassName="modal-form-content"
          onOk={onSubmit}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Form className={styles.form} form={eachExtensionRecordForm} labelCol={{ flex: '110px' }} labelAlign="left">
            <Form.Item name="endTime" label="结束日期" rules={[{ required: true, message: '请填写结束日期' }]}>
              <DatePicker style={{ width: '100%' }} format={'YYYY-MM-DD'} disabledDate={disabledDate} />
            </Form.Item>
          </Form>
        </Modal>
      </Wrapper>
    </>
  );
};

export default ElectricitySalesContract;
