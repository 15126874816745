import { Radio, Empty, Key } from '@maxtropy/components';
import EnergyContent from '../EnergyContent';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Progress, Space, Spin } from 'antd';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import YearPlanBarEcharts from '../YearPlanBarEcharts';
import MonthPlanLineEcharts from '../MonthPlanLineEcharts';
import { isNil } from 'lodash-es';
import { pollingInterval } from '../../utils';
import {
  V2EnergyConsumptionOverviewEnergyPlanMonthPostResponse,
  V2EnergyConsumptionOverviewYearPlanListPostResponse,
  apiV2EnergyConsumptionOverviewEnergyPlanMonthPost,
  apiV2EnergyConsumptionOverviewYearPlanListPost,
} from '@maxtropy/device-customer-apis-v2';

export type ActualValueItem = Required<
  Exclude<V2EnergyConsumptionOverviewEnergyPlanMonthPostResponse['actualValue'], undefined>[number]
>;

export type YearPlanListItem = Required<
  Exclude<V2EnergyConsumptionOverviewYearPlanListPostResponse['list'], undefined>[number]
>;

export type YearPlanResWithoutList = Required<Omit<V2EnergyConsumptionOverviewYearPlanListPostResponse, 'list'>>;

export interface YearPlanRes extends YearPlanResWithoutList {
  list: YearPlanListItem[];
}

export interface MonthPlanRes {
  planValue: number; // 月计划
  actualValue: ActualValueItem[];
}

interface EnergyPlanProps {
  configId?: Key;
  isTwoCol?: boolean;
  isFullScreen: boolean;
  outerRef: any;
}

enum RadioValue {
  Year = 1,
  Month = 2,
}

enum PercentRange {
  green = 1,
  blue = 2,
  yellow = 3,
  orange = 4,
  red = 5,
}

const PercentRangeColorMap = {
  [PercentRange.green]: { '0%': '#92ED79', '100%': '#59D744' },
  [PercentRange.blue]: { '0%': '#51A1FF', '100%': '#51DBFF' },
  [PercentRange.yellow]: { '0%': '#FFB219 ', '100%': '#D89614' },
  [PercentRange.orange]: { '0%': '#FF8921 ', '100%': '#DB761D' },
  [PercentRange.red]: { '0%': '#FF8487 ', '100%': '#FF4D4F' },
};

// 当前处于哪个百分比区间
const getPercentRange = (percent: number) => {
  if (percent <= 60) {
    return PercentRangeColorMap[PercentRange.green];
  }
  if (percent > 60 && percent <= 70) {
    return PercentRangeColorMap[PercentRange.blue];
  }
  if (percent > 70 && percent <= 80) {
    return PercentRangeColorMap[PercentRange.yellow];
  }
  if (percent > 80 && percent <= 90) {
    return PercentRangeColorMap[PercentRange.orange];
  }
  if (percent > 90) {
    return PercentRangeColorMap[PercentRange.red];
  }
};

const monthActualValueIsAllNull = (monthPlanRes: MonthPlanRes) => {
  const { actualValue } = monthPlanRes;
  const res = actualValue.every(item => isNil(item.value));
  return res;
};

const getMonthPlanPercent = (monthPlanRes?: MonthPlanRes) => {
  if (!monthPlanRes || isNil(monthPlanRes.planValue) || monthPlanRes.planValue === 0) return 0;
  const { planValue, actualValue } = monthPlanRes;
  const allValue = (actualValue ?? []).map(i => i.value ?? 0);
  const actual = (allValue ?? []).sort((a, b) => b - a).at(0) ?? 0;
  const percent = Number(((actual / planValue) * 100).toFixed(2));
  return percent;
};

function isAllNullOfYearPlan(data?: YearPlanRes) {
  if (!data) return true;
  const { actualYearPlan, list, percentage, yearPlan } = data;
  const res =
    list.every(item => isNil(item.actualVal) && isNil(item.planVal)) &&
    isNil(actualYearPlan) &&
    isNil(yearPlan) &&
    isNil(percentage);
  return res;
}

const EnergyPlan: React.FC<EnergyPlanProps> = ({ outerRef, isFullScreen, configId, isTwoCol }) => {
  const [radioValue, setRadioValue] = useState<RadioValue>(RadioValue.Month);
  const [isYearOverTenThousand, setIsYearOverTenThousand] = useState<boolean>(false);
  const [isMonthOverTenThousand, setIsMonthOverTenThousand] = useState<boolean>(false);
  const [monthPlanColor, setMonthPlanColor] = useState<
    | {
        '0%': string;
        '100%': string;
      }
    | undefined
  >(PercentRangeColorMap[PercentRange.green]);
  const [monthPlanPercent, setMonthPlanPercent] = useState<number>(0);

  // const getScaleX = (num: number) => {
  //   if (num <= 0.7) return 1.2;
  //   if (num <= 0.4) return 2;
  // };
  // const scaleX = getScaleX(1920 / document.body.clientWidth);

  const [loading, setLoading] = useState<boolean>(false);

  const { data: yearPlanRes } = useRequest(
    () => {
      if (configId && radioValue === RadioValue.Year) {
        setLoading(true);
        return apiV2EnergyConsumptionOverviewYearPlanListPost({
          energyOverviewConfigId: configId,
        })
          .then(res => {
            // 重置
            setIsYearOverTenThousand(false);
            if (isAllNullOfYearPlan(res as YearPlanRes)) return undefined;
            // 判断是否有月份实际用能收超过10000 是则单位转为tce
            if ((res.list ?? []).some(item => item.actualVal && item.actualVal > 10000)) {
              setIsYearOverTenThousand(true);
              return {
                ...res,
                list: (res.list ?? []).map(item => ({
                  ...item,
                  planVal: isNil(item.planVal) ? item.planVal : Number((item.planVal / 1000).toFixed(2)),
                  actualVal: isNil(item.actualVal) ? item.actualVal : Number((item.actualVal / 1000).toFixed(2)),
                })),
              };
            }
            return {
              ...res,
              list: (res.list ?? []).map(item => ({
                ...item,
                planVal: isNil(item.planVal) ? item.planVal : Number(item.planVal.toFixed(2)),
                actualVal: isNil(item.actualVal) ? item.actualVal : Number(item.actualVal.toFixed(2)),
              })),
            };
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId, radioValue],
    }
  );

  const { data: monthPlanRes } = useRequest(
    () => {
      if (configId && radioValue === RadioValue.Month) {
        setLoading(true);
        return apiV2EnergyConsumptionOverviewEnergyPlanMonthPost({
          energyOverviewConfigId: configId,
        })
          .then(res => {
            // 重置
            setIsMonthOverTenThousand(false);
            const per = getMonthPlanPercent(res as MonthPlanRes);
            setMonthPlanPercent(per);
            setMonthPlanColor(getPercentRange(per));
            if (res) {
              if (
                (isNil(res.planValue) && isNil(res.actualValue)) ||
                (isNil(res.planValue) && res.actualValue && res.actualValue.length === 0) ||
                (isNil(res.planValue) && monthActualValueIsAllNull(res as MonthPlanRes))
              )
                return undefined;
              // 判断是否有一天累计用能收超过10000 是则单位转为tce
              if ((res.actualValue ?? []).some(item => Number(item.value) > 10000)) {
                setIsMonthOverTenThousand(true);
                return {
                  planValue: isNil(res.planValue) ? res.planValue : Number((res.planValue / 1000).toFixed(2)),
                  actualValue: (res.actualValue ?? []).map(item => ({
                    ts: item.ts,
                    value: isNil(item.value) ? item.value : Number((item.value / 1000).toFixed(2)),
                  })),
                };
              }
              return {
                planValue: isNil(res.planValue) ? res.planValue : Number(res.planValue.toFixed(2)),
                actualValue: (res.actualValue ?? []).map(item => ({
                  ts: item.ts,
                  value: isNil(item.value) ? item.value : Number(item.value.toFixed(2)),
                })),
              };
            }
            return undefined;
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId, radioValue],
    }
  );

  return (
    <EnergyContent
      title={`用能计划 (${radioValue === RadioValue.Year ? dayjs().format('YYYY') : dayjs().format('YYYY-MM')})`}
      extraContent={
        <Space>
          <Radio.Group
            onChange={v => {
              setRadioValue(v.target.value);
            }}
            value={radioValue}
            className={styles.radioStyles}
            optionType="button"
          >
            <Radio value={RadioValue.Month}>月计划</Radio>
            <Radio value={RadioValue.Year}>年计划</Radio>
          </Radio.Group>
        </Space>
      }
      style={
        isTwoCol
          ? {
              paddingTop: 55,
            }
          : { flex: '0 0 auto' }
      }
    >
      <Spin spinning={loading}>
        <div
          className={styles.planContent}
          // style={
          //   document.body.clientWidth > 1920
          //     ? {
          //         zoom: `${1 / zoom}`,
          //         transform: `scale(${1 / scale}, ${scale})`,
          //       }
          //     : {}
          // }
        >
          <div className={styles.processStyle}>
            <Progress
              type="circle"
              percent={radioValue === RadioValue.Year ? yearPlanRes?.percentage ?? 0 : monthPlanPercent}
              strokeColor={
                radioValue === RadioValue.Year
                  ? getPercentRange(yearPlanRes?.percentage ?? 0)
                  : getPercentRange(monthPlanPercent)
              }
              size={136}
              strokeWidth={8}
              format={() => (
                <div className={styles.progressCircleStyle}>
                  <div className={styles.progressCircleInlineStyle}>
                    {radioValue === RadioValue.Year ? (
                      <p>{yearPlanRes && !isNil(yearPlanRes.percentage) ? `${yearPlanRes.percentage}%` : '--'}</p>
                    ) : (
                      <p>
                        {monthPlanRes &&
                        !isNil(monthPlanRes.planValue) &&
                        monthPlanRes.planValue !== 0 &&
                        monthPlanRes.actualValue.length > 0 &&
                        !monthActualValueIsAllNull(monthPlanRes as MonthPlanRes) &&
                        !isNil(monthPlanRes.actualValue)
                          ? `${monthPlanPercent}%`
                          : '--'}
                      </p>
                    )}
                    <p>{radioValue === RadioValue.Year ? '年计划进度' : '月计划进度'}</p>
                  </div>
                </div>
              )}
            />
          </div>
          {radioValue === RadioValue.Year ? (
            yearPlanRes && yearPlanRes.list && yearPlanRes.list.length > 0 ? (
              <YearPlanBarEcharts
                outerRef={outerRef}
                isOverTenThousand={isYearOverTenThousand}
                data={(yearPlanRes?.list ?? []) as YearPlanListItem[]}
              />
            ) : (
              <Empty
                style={
                  document.documentElement.clientWidth > 2560
                    ? {
                        width: 700,
                      }
                    : document.documentElement.clientWidth > 1920
                    ? {
                        width: 600,
                        height: 200,
                      }
                    : { width: 600, height: 136 }
                }
              />
            )
          ) : monthPlanRes ? (
            <MonthPlanLineEcharts
              outerRef={outerRef}
              monthPlanColor={monthPlanColor}
              isOverTenThousand={isMonthOverTenThousand}
              data={monthPlanRes as MonthPlanRes}
              isTwoCol={isTwoCol}
            />
          ) : (
            <Empty
              style={
                document.documentElement.clientWidth > 2560
                  ? {
                      width: 700,
                    }
                  : document.documentElement.clientWidth > 1920
                  ? {
                      width: 600,
                      height: 200,
                    }
                  : { width: 600, height: 136 }
              }
            />
          )}
        </div>
      </Spin>
    </EnergyContent>
  );
};
export default EnergyPlan;
