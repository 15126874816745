import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import EnergyOverviewConfig from '../EnergyOverviewConfig';
import { Space, Spin, TreeSelectProps } from 'antd';
import styles from './index.module.scss';
import { Tabs, Form, PopConfirm, Button, Key, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EnergyMediumDtosItem,
  EnergyOverviewConfigBody,
  getEnergyOverviewConfigDetail,
  saveEnergyOverviewConfig,
} from '@/api/energyOverview';
import { OuListResponse, getOuListOwned } from '@/api/ou';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import _ from 'lodash';
import { getOrganizationWithCodeWithCurrent } from '@/api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { MediumDataPropertyItem } from '@/pages/EnergyAnalysisOptimize/components/CreateAnalysis';

interface SubmitParamsItem extends EnergyOverviewConfigBody {
  imgConfig: number;
  energy: any;
  energyUnits: TreeSelectProps['treeData'];
  customerMcids: string[];
}

interface SubmitParams {
  configs: SubmitParamsItem[];
}

// 获取相同标题的最后一项的索引
function getSameTitleLastIndexs(configs: SubmitParamsItem[]) {
  if (configs.length < 2) return [];

  const titles = configs.map((item: SubmitParamsItem) => item.title);
  // 找出数组中的相同项
  const sameLastIndex = titles
    .map((item: string, index: number) => {
      if (titles.lastIndexOf(item) !== index) {
        return titles.lastIndexOf(item);
      }
      return null;
    })
    .filter((item: number | null) => item !== null);
  return sameLastIndex;
}

// 获取保存或编辑的传参
function formatConfigsToBody(configs: SubmitParamsItem[], childEnergyListMap: Record<Key, MediumDataPropertyItem[]>) {
  // let energyNameIdList = childEnergyList?.map(item => item.energyNameId) ?? [];
  return configs.map(config => {
    const energyMediumDtos: EnergyMediumDtosItem[] = [];
    if (config.energy) {
      for (const [key, val] of Object.entries(config.energy)) {
        if (key && val) {
          energyMediumDtos.push({
            energyMediumId: Number(key),
            dataPropertyId: val as Key,
          });
        }
      }
    }

    const energyUnitDtos = (config.energyUnits ?? []).map(i => ({ energyUnitId: i.value }));
    const energyNameIdList = childEnergyListMap[config.energyAnalysisUnitGroupId]?.map(item => item.energyNameId) ?? [];
    return {
      customerMcids: config.customerMcids,
      energyAnalysisUnitGroupId: config.energyAnalysisUnitGroupId,
      picUrl: config.imgConfig === 0 ? undefined : config.picUrl,
      energyFlowLevel1: config.energyFlowLevel1,
      energyFlowLevel2: config.energyFlowLevel2,
      energyFlowLevel3: config.energyFlowLevel3,
      energyMediumDtos: energyMediumDtos.filter(
        a => energyNameIdList.length === 0 || energyNameIdList.includes(a.energyMediumId as number)
      ),
      energyUnitDtos,
      yearPlan: config.yearPlan,
      januaryPlan: config.januaryPlan,
      februaryPlan: config.februaryPlan,
      marchPlan: config.marchPlan,
      aprilPlan: config.aprilPlan,
      mayPlan: config.mayPlan,
      junePlan: config.junePlan,
      julyPlan: config.julyPlan,
      augustPlan: config.augustPlan,
      septemberPlan: config.septemberPlan,
      octoberPlan: config.octoberPlan,
      novemberPlan: config.novemberPlan,
      decemberPlan: config.decemberPlan,
      title: config.title,
      ouIds: config.ouIds,
      id: config.id ?? undefined,
      planSource: config.planSource,
    };
  });
}

// 获取详情
function formatDetailToConfigs(detail: EnergyOverviewConfigBody[]) {
  return detail.map((item, index) => {
    const units = (item.energyUnitDtos ?? []).map(i => ({
      value: i.energyUnitId,
      title: i.energyUnitName,
    }));
    const medium = (item.energyMediumDtos ?? []).map(i => {
      return [`${i.energyMediumId}`, i.dataPropertyId];
    });
    const energy = Object.fromEntries(new Map(medium as any));
    const customerMcids = detail[index].customerMcidDtos?.map(i => i.mcid);

    return {
      ...item,
      energyUnits: units,
      energy: energy,
      imgConfig: item.picUrl ? 1 : 0, // 0默认，1自定义
      customerMcids: customerMcids,
    };
  });
}

function formatDetailToBody(detail: EnergyOverviewConfigBody[]) {
  return (detail ?? []).map(item => {
    const { energyAnalysisUnitGroupName, ...newItem } = item;

    const energyMediumDtos = (newItem.energyMediumDtos ?? []).map(i => ({
      energyMediumId: i.energyMediumId,
      dataPropertyId: i.dataPropertyId,
    }));
    const energyUnitDtos = (newItem.energyUnitDtos ?? []).map(i => ({
      energyUnitId: i.energyUnitId,
    }));

    return {
      ...newItem,
      energyMediumDtos,
      energyUnitDtos,
      picUrl: newItem.picUrl ?? undefined,
    };
  });
}

function getFieldName(errorFields: any[]) {
  return _.uniq(errorFields.map(i => i.name[1]));
}

function formatTreeData(data: any[]) {
  return data.map((item, index) => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: true,
    };
    return res;
  });
}

const MultipleOverviewConfig: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [detail, setDetail] = useState<EnergyOverviewConfigBody[]>([]);
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); //所属组织
  const [ou, SetOu] = useState<OuListResponse[]>([]);
  const [activeKey, setActiveKey] = useState<string>();
  const [allTabKey, setAllTabKey] = useState<string[]>([]);
  const [newTabIndex, setNewTabIndex] = useState<number>(0);
  const [allTitle, setAllTitle] = useState<string[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res])); //获取租户的所属组织
    });
    getOuListOwned().then(SetOu); // 获取ou
    getEnergyOverviewConfigDetail().then(res => {
      if (res) {
        setDetail(res.list);
        setAllTitle(res.list.map(item => item.title));
        setNewTabIndex(res.list.length);
        setAllTabKey(res.list.map((item, index) => index.toString()));
      }
    });
  }, []);

  useEffect(() => {
    if (detail.length > 0) {
      const configs = formatDetailToConfigs(detail);
      form.setFieldsValue({ configs });
      setLoading(false);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const onFinish = (v: SubmitParams) => {
    const { configs = [] } = v;
    const sameTitleLastIndexs = getSameTitleLastIndexs(configs);
    if (sameTitleLastIndexs.length > 0) {
      sameTitleLastIndexs.forEach((item: any) => {
        form.setFields([
          {
            name: ['configs', item, 'title'],
            errors: ['该标题已存在，请重新输入'],
          },
        ]);
      });
      setActiveKey(allTabKey[sameTitleLastIndexs[0] as number]);
      return;
    } else {
      setLoading(true);

      const body = formatConfigsToBody(configs, childEnergyListMap.current);
      const newDetail = formatDetailToBody(detail);
      const add = body.filter(item => !item.id);
      const del = newDetail.filter(item => !body.find(i => i.id === item.id)).map(i => i.id);

      const update = body
        .filter(item => item.id)
        .map(i => {
          const current = newDetail.find(item => item.id === i.id);
          return current ? (_.isEqual(i, current) ? null : i) : null;
        })
        .filter(i => i !== null);

      //新增不传id 编辑传id
      saveEnergyOverviewConfig({ add, delete: del, update: update as EnergyOverviewConfigBody[] })
        .then(res => {
          setIsDetail(true);
          getEnergyOverviewConfigDetail().then(result => {
            if (result) {
              setDetail(result.list);
              setAllTitle(result.list.map(item => item.title));
              setAllTabKey(result.list.map((item, index) => index.toString()));
              setActiveKey('0');
            }
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (info: ValidateErrorEntity<SubmitParams>) => {
    const fieldNameArr = getFieldName(info.errorFields);
    if (fieldNameArr.length > 0) {
      setActiveKey(allTabKey[fieldNameArr[0]]);
    }
  };

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const childEnergyListMap = useRef<Record<Key, MediumDataPropertyItem[]>>({});

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.List name="configs">
            {(fields, { add, remove }) => {
              const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove') => {
                if (action === 'add' && !isDetail) {
                  add();
                  const newActiveKey = newTabIndex.toString();
                  setNewTabIndex(newTabIndex + 1);
                  setAllTabKey([...allTabKey, newActiveKey]);
                  setActiveKey(newActiveKey);
                  setAllTitle([...allTitle, '精益能源管理驾驶舱']);
                } else {
                  return;
                }
              };
              return (
                <div className={styles.tabStyle}>
                  <Tabs
                    addIcon={
                      <Button icon={<PlusOutlined />} type="link" disabled={isDetail}>
                        新增总览配置
                      </Button>
                    }
                    type="editable-card"
                    onChange={onChange}
                    activeKey={activeKey}
                    onEdit={onEdit}
                    destroyInactiveTabPane
                    items={[
                      ...fields.map((field, index) => {
                        return {
                          label: allTitle[field.name] || '精益能源管理驾驶舱',
                          key: field.key.toString(),
                          children: (
                            <Space key={field.key}>
                              <EnergyOverviewConfig
                                getEnergyList={list => {
                                  if (activeKey) {
                                    let energyGroupId = detail[+activeKey].energyAnalysisUnitGroupId;
                                    childEnergyListMap.current[energyGroupId] = list;
                                  }
                                }}
                                organization={treeData}
                                ou={ou}
                                isDetail={isDetail}
                                detail={detail}
                                field={field}
                                setAllTitle={setAllTitle}
                                allTitle={allTitle}
                              />
                              <Form.Item noStyle name={[field.name, 'id']}>
                                <p></p>
                              </Form.Item>
                            </Space>
                          ),
                          closable: isDetail || (fields ?? []).length === 1 ? false : true,
                          closeIcon: (
                            <PopConfirm
                              title="确认删除该组总览配置？"
                              onConfirm={() => {
                                let targetKey = field.key;
                                setAllTabKey(allTabKey.filter(item => item !== targetKey.toString()));
                                setAllTitle(allTitle.filter((item, index) => index !== field.name));
                                fields.find(item => {
                                  if (item.key === targetKey) {
                                    remove(item.name);
                                    return true;
                                  }
                                  return false;
                                });

                                let newActiveKey = activeKey;
                                let lastIndex = -1;
                                fields.forEach((item, i) => {
                                  if (item.key === targetKey) {
                                    lastIndex = i - 1;
                                  }
                                });
                                if (fields.length && newActiveKey === targetKey.toString()) {
                                  if (lastIndex >= 0) {
                                    newActiveKey = fields[lastIndex].key.toString();
                                  } else {
                                    newActiveKey = fields[0].key.toString();
                                  }
                                }
                                setActiveKey(newActiveKey);
                              }}
                              okText="删除"
                              cancelText="取消"
                            >
                              <CloseOutlined />
                            </PopConfirm>
                          ),
                        };
                      }),
                    ]}
                  />
                </div>
              );
            }}
          </Form.List>

          {!isDetail && (
            <Space size={8} className={styles.saveButton}>
              <Button htmlType="submit" type="primary">
                保存
              </Button>
              <Button
                onClick={() => {
                  navigate('/energy/analysisGroup/overview');
                }}
              >
                取消
              </Button>
            </Space>
          )}

          {isDetail && (
            <Space size={8} className={styles.saveButton}>
              <Button
                type="primary"
                onClick={() => {
                  setIsDetail(false);
                }}
              >
                编辑
              </Button>
              <Button
                onClick={() => {
                  navigate('/energy/analysisGroup/overview');
                }}
              >
                返回
              </Button>
            </Space>
          )}
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default MultipleOverviewConfig;
