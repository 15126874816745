import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Button,
  Empty,
  useAsync,
  Filter,
} from '@maxtropy/components';
import { Col, Form, Input, Popconfirm, Select, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DeviceStatus, DeviceStatusDisplay } from '@/shared/types';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { formatOptionData, getAllChild } from '@/shared/components/CascadingMultipleSelector/utils';

import { Device, getOUDevices, getStaffType, OUDeviceListRequest, removeDevice } from '../../api/ouDevice';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission, useQuery } from '../../utils/utils';
import AddDevice from './AddDevice';
import emptyImage from './empty.png';
import { getDeviceTypeTree } from '@/api/deviceType';

// const routes = [
//   { name: "运营单元" },
//   { name: "运营单元" },
//   { name: "设备管理" },
// ];

const columns: ColumnType<Device>[] = [
  {
    title: '序号',
    width: 100,
    render: (_, record, index) => index + 1,
  },
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
    width: 200,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备SN',
    dataIndex: 'sn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    width: 300,
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: DeviceStatus) => <EllipsisSpan value={DeviceStatusDisplay[v]} />,
  },
  {
    title: '添加时间',
    width: 200,
    dataIndex: 'addTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface FilterParams {
  nameOrCode: string | undefined;
  deviceType: Array<Array<number>> | undefined;
  status?: DeviceStatus;
}

type SearchParams = Omit<OUDeviceListRequest, 'page' | 'size'>;

const DeviceManage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getStaffType(Number(id)).then(v => {
      setIsAdmin(v?.id === 1);
    });
  }, [id]);

  const [visible, setVisible] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const nameOrCode = urlSearchParams.get('nameOrCode') || undefined;
  const deviceType = urlSearchParams
    .get('deviceType')
    ?.split(',')
    .map(s => Number(s));
  const deviceTypeDisplay = urlSearchParams
    .get('deviceTypeDisplay')
    ?.split(',')
    .map(s => s.split('|').map(Number));
  // const rootMcid = urlSearchParams.get("rootMcid") || undefined;
  const status = urlSearchParams.get('status') || undefined;
  const [searchParams, setSearchParams] = useState<SearchParams>({
    nameOrCode,
    deviceType,
    status: status !== undefined ? Number(status) : undefined,
  });

  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldsValue({
      nameOrCode,
      deviceType: deviceTypeDisplay,
      status: status !== undefined ? Number(status) : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getOUDevices({
          ...searchParams,
          id: +id!,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      [id, pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const onRemoveDevice = (id: number, deviceId: number) => {
    removeDevice({ id, deviceId }).then(() => setSearchParams({ ...searchParams }));
  };

  const render = (record: Device) => (
    <Space size={16}>
      {isAdmin && (
        <Popconfirm
          title="确定移除设备？"
          onConfirm={() => onRemoveDevice(+id!, record.id)}
          okText="确定"
          cancelText="取消"
        >
          <Button type="link">设备移出</Button>
        </Popconfirm>
      )}
      <Button type="link">
        <Link to={`/device/manage/device/${record.id}/detail`} target="_blank">
          查看
        </Link>
      </Button>
    </Space>
  );

  const onFinish = (val: FilterParams) => {
    const deviceType = getAllChild(val.deviceType, formatOptionData(deviceTypeData));
    const params: SearchParams = {
      nameOrCode: val.nameOrCode,
      deviceType: deviceType,
      status: val.status,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      nameOrCode: val.nameOrCode,
      status: val.status?.toString(),
      deviceType: deviceType?.join(','),
      deviceTypeDisplay: val.deviceType?.map(item => item.join('|')).join(','),
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      nameOrCode: undefined,
      deviceType: undefined,
      status: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
    setPageOffset(1);
  };

  const filters = (
    <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <>
        <Col span={4}>
          <Form.Item name="nameOrCode" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="status" label="状态">
            <Select style={{ width: '100%' }} placeholder="请选择">
              <Select.Option value={DeviceStatus.USING}>{DeviceStatusDisplay[DeviceStatus.USING]}</Select.Option>
              <Select.Option value={DeviceStatus.REPAIRING}>
                {DeviceStatusDisplay[DeviceStatus.REPAIRING]}
              </Select.Option>
              <Select.Option value={DeviceStatus.DEACTIVATING}>
                {DeviceStatusDisplay[DeviceStatus.DEACTIVATING]}
              </Select.Option>
              <Select.Option value={DeviceStatus.CALLOUT}>{DeviceStatusDisplay[DeviceStatus.CALLOUT]}</Select.Option>
              <Select.Option value={DeviceStatus.SOLD}>{DeviceStatusDisplay[DeviceStatus.SOLD]}</Select.Option>
              <Select.Option value={DeviceStatus.SCRAPPED}>{DeviceStatusDisplay[DeviceStatus.SCRAPPED]}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} style={{ minWidth: 780 }}>
          <Form.Item name="deviceType">
            <CascadingMultipleSelector
              labels={deviceTypeData?.aliasName}
              data={formatOptionData(deviceTypeData)}
              mode={['single']}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const canAddDevice = useHasPermission(PermissionsType.B_CREATE_OU_DEVICE);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Space size={8}>
        {isAdmin && canAddDevice && (
          <div style={{ marginBottom: 10 }}>
            <Button onClick={() => setVisible(true)} type="primary">
              +添加设备
            </Button>
          </div>
        )}
      </Space>

      {!isLoading && data?.length === 0 ? (
        <Empty
          style={{ marginTop: 150 }}
          image={emptyImage}
          imageStyle={{ height: 124 }}
          description="您还没添加任何设备"
        ></Empty>
      ) : (
        <>
          <Table
            sticky
            rowKey="id"
            dataSource={data}
            loading={isLoading}
            scroll={{ x: 1500 }}
            columns={[...columns, { title: '操作', width: 220, fixed: 'right', render }]}
          />
          <Paging pagingInfo={pagingInfo} />
        </>
      )}

      {visible && (
        <AddDevice
          visible={visible}
          setVisible={setVisible}
          id={+id!}
          onSuccess={() => setSearchParams({ ...searchParams })}
        />
      )}
    </Wrapper>
  );
};

export default DeviceManage;
