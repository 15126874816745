import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  Form,
  FormTitle,
  BaseInfoContent,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { PushCenterStaffLinkVoList, OutsiderResponse } from '../../../api/deviceAlarmPushStrategy';

import SelectPushStaff from '../../../components/SelectPushStaff';
import SelectPushOutSider from '../../../components/SelectPushOutsider';
import { getServerAlarmPushConfig, ServerAlarmPushConfigResponse } from '../../../api/serverAlarm';
import SelectServerAlarmRules from '../components/SelectServerRule';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: `查看服务端推送` }];

const CreateServerAlarmPushConfig: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [data, setData] = useState<ServerAlarmPushConfigResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getServerAlarmPushConfig(id).then(res => {
        setData(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const staffs = Array.from(
        (data.pushStaffs ?? [])
          .reduce((previousValue, currentValue) => {
            const value = previousValue.get(currentValue.staffId);
            if (value) {
              previousValue.set(value.staffId, {
                ...value,
                thirdPartyAppId: [...value.thirdPartyAppId, currentValue.pushChannel],
              });
            } else {
              previousValue.set(currentValue.staffId, {
                ...currentValue,
                thirdPartyAppId: [currentValue.pushChannel],
              });
            }
            return previousValue;
          }, new Map<number, PushCenterStaffLinkVoList>())
          .values()
      );
      const contacts: OutsiderResponse[] = (data.pushExtContacts ?? []).map(i => ({
        ...i,
        id: i.contactPhone,
        thirdPartyIdList: i.pushChannels ?? [],
      }));
      form.setFieldsValue({
        name: data?.name,
        ouName: data.ouName,
        sceneName: data.sceneName,
        pushStaffs: staffs,
        pushExtContacts: contacts,
        serverAlarmRules: data.serverAlarmRules,
      });
    }
  }, [data, form]);

  const goList = () => {
    navigate(`/device/alarm/serverPush`);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="查看服务端推送"></FormTitle>

      <Form form={form} layout="vertical" {...formLayout}>
        <BaseInfoContent className="px-32 mb-8">
          <Form.Item name="name" label="推送名称">
            <ShowInput />
          </Form.Item>
          <Form.Item name="ouName" label="运营单元">
            <ShowInput />
          </Form.Item>
          <Form.Item name="sceneName" label="报警规则场景">
            <ShowInput />
          </Form.Item>
        </BaseInfoContent>

        <SubContent title="报警规则" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="serverAlarmRules" wrapperCol={{ span: 24 }}>
                <SelectServerAlarmRules ouId={data?.ouId} sceneType={data?.sceneType} disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="内部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushStaffs" wrapperCol={{ span: 24 }}>
                <SelectPushStaff ouId={data?.ouId} disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="外部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushExtContacts" wrapperCol={{ span: 24 }}>
                <SelectPushOutSider hasContact disabled />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <Space className="sticky-footer" style={{ zIndex: 99 }}>
          <Button className={styles.button} onClick={goList}>
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateServerAlarmPushConfig;
