import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { PageRequest, PageResponse } from './page';

// 输入输出分页查询
export interface EmtProcessReq extends PageRequest {
  emtId?: number;
  importAndExportName?: string;
}
export interface EmtProcessRes {
  emtName: string;
  energyName: string;
  energyMediumId: number;
  id: number;
  importAndExportName: string;
  processName: string;
  type: number;
}
export const getEmtProcessPage = (params: EmtProcessReq) => {
  return fetch<PageResponse<EmtProcessRes>>(`/api/emt/process/detail/page?${qs.stringify(params, { indices: false })}`);
};
// 获取所属拓扑列表
export interface TopologySelectItem {
  id: number;
  name: string;
}
export const getTopologySelectList = () => fetch<TopologySelectItem[]>('/api/emt/energy-medium-topology/list');
export enum IndicatorEnum {
  CUMULATE = 1, // 累积
  INSTANT, // 顺时
}

export const IndicatorEnumDisplay = {
  [IndicatorEnum.CUMULATE]: '累积量',
  [IndicatorEnum.INSTANT]: '瞬时量',
};
// 配置列表
export interface ConfigurationParams {
  energyMediumId?: number;
  importAndExportId?: number;
  // type?: IndicatorEnum;
  importAndExportType?: number; // 输入输出类型
}
export interface ConfigurtionListItem {
  basePhysicalQuantityId: number;
  basePhysicalQuantityName: string;
  dataPropertyId: number;
  dataPropertyName: string;
  deviceId: number;
  deviceName: string;
  indicatorId: number;
  indicatorName: string;
  importAndExportType: number; // 输入输出类型
  type: IndicatorEnum; //指标类型  1 累积量 2 瞬时量
  typeName: string;
  importAndExportId: number;
  startTime: number;
}
export const getConfigurationList = (params: ConfigurationParams) => {
  return fetch<ConfigurtionListItem[]>(
    `/api/emt/energy-medium-configuration/list?${qs.stringify(params, { indices: false })}`
  );
};

// 设备下拉列表
export interface DeviceSelectListItem {
  id: number;
  name: string;
}
export type DeviceParams = Omit<ConfigurationParams, 'energyMediumId'>;
export const getDeiveList = (params: DeviceParams) => {
  return fetch<DeviceSelectListItem[]>(`/api/emt/device/list?${qs.stringify(params, { indices: false })}`);
};

export interface PropertyParams {
  // type: IndicatorEnum;
  // basePhysicalQuantityId: number; // 物理量
  deviceId: number;
  indicatorId: number;
}
export interface PropertySelectListItem extends DeviceSelectListItem {}
// 属性列表
export const getPropertyList = (params: PropertyParams) => {
  return fetch<PropertySelectListItem[]>(`/api/emt/data-property/list?${qs.stringify(params, { indices: false })}`);
};

// 添加数据链路
export interface CreateDataLinkParams {
  energyMediumMonitoringIndicatorsId?: number; //检测指标id
  type?: IndicatorEnum;
  importAndExportType?: number;
  timePointType?: number; //时间点类型 1开始时间 0截止时间
  startTime?: number; //毫秒时间戳 生效时间 需要年月日时分   秒毫秒统一为0
  endTime?: number; //毫秒时间戳 失效时间 没有就传null
  importAndExportId?: number; // 输入输出id
  deviceId?: number; // 设备id
  dataPropertyId?: number; //数据属性id
  dataPropertyName?: string; //数据属性名称
  timeObj?: any;
}
export const updateConfigureInfo = (body: CreateDataLinkParams) => {
  return fetch<Boolean>('/api/emt/add/data-link', {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
export interface DataLinkParams {
  // type: IndicatorEnum; //输入输出类型 1输出 0输入
  importAndExportType: number; // 输入输出类型
  importAndExportId: number; //输入输出id
  energyMediumMonitoringIndicatorsId: number; //检测指标id
}

export interface DataLinkRes {
  dataPropertyId: number;
  dataPropertyName: string; //数据属性
  deviceId: number;
  deviceName: string; //设备名称
  endTime: number; //失效时间毫秒时间戳
  energyMediumMonitoringIndicatorsId: number;
  startTime: number; //生效时间毫秒时间戳
  importAndExportId: number; //输入输出id
  type: IndicatorEnum; //输入输出类型 1输出 0输入
  measurementType: number; //输入输出类型 1累计值 2瞬时值
}
// 数据链路列表
export const getDataLinkList = (params: DataLinkParams) => {
  return fetch<DataLinkRes[]>(`/api/emt/list/data-link?${qs.stringify(params, { indices: false })}`);
};

// 获取指标配置详情
export const getQuotaDetail = (params: { importAndExportType?: Key; importAndExportId?: Key }) => {
  return fetch<EmtProcessRes>(`/api/emt/topology-indicator/detail?${qs.stringify(params, { indices: false })}`);
};
