import electricityActive from './images/electricityActive.png';
import electricityPassive from './images/electricityPassive.png';
import waterActive from './images/waterActive.png';
import waterPassive from './images/waterPassive.png';
import gasActive from './images/gasActive.png';
import gasPassive from './images/gasPassive.png';
import hotSteamActive from './images/hotSteamActive.png';
import hotSteamPassive from './images/hotSteamPassive.png';
import compressedAirActive from './images/compressedAirActive.png';
import compressedAirPassive from './images/compressedAirPassive.png';
import IndustrialGasActive from './images/IndustrialGasActive.png';
import IndustrialGasPassive from './images/IndustrialGasPassive.png';
import haActive from './images/haActive.png';
import haPassive from './images/haPassive.png';
import IndustrialLiquidActive from './images/IndustrialLiquidActive.png';
import IndustrialLiquidPassive from './images/IndustrialLiquidPassive.png';
import barExceed from './images/barExceed.png';
import barDown from './images/barDown.png';

import {
  Status,
  StatusColorDisplay,
  StatusDisplay,
  TimeGranularity,
  TimeGranularityUnit,
} from '@/api/productionConsumptionBoard';

// markArea 基本配置
export const MarkAreaConfig = {
  rest: {
    text: StatusDisplay[Status.REST],
    color: StatusColorDisplay[Status.REST],
  },
  free: {
    text: StatusDisplay[Status.FREE],
    color: StatusColorDisplay[Status.FREE],
  },
  lineStop: {
    text: StatusDisplay[Status.LINE_STOP],
    color: StatusColorDisplay[Status.LINE_STOP],
  },
  work: {
    text: StatusDisplay[Status.WORK],
    color: StatusColorDisplay[Status.WORK],
  },
};

// 堆叠图图例
export const StackLegend = [
  {
    right: 400,
    text: '超过阈值上限',
    image: barExceed,
    width: 8,
    height: 16,
    vertical: true,
  },
  {
    right: 300,
    text: '低于阈值下限',
    image: barDown,
    width: 8,
    height: 16,
    vertical: true,
  },
];

// markArea 图例
export const MarkAreaLegend = [
  {
    right: 710,
    text: MarkAreaConfig.rest.text,
    legendColor: MarkAreaConfig.rest.color,
    vertical: false,
  },
  {
    right: 640,
    text: MarkAreaConfig.free.text,
    legendColor: MarkAreaConfig.free.color,
    vertical: false,
  },
  {
    right: 570,
    text: MarkAreaConfig.work.text,
    legendColor: MarkAreaConfig.work.color,
    vertical: false,
  },
  {
    right: 470,
    text: MarkAreaConfig.lineStop.text,
    legendColor: MarkAreaConfig.lineStop.color,
    vertical: false,
  },
];

// 工质场景 id 枚举
export enum EnergyMediumSceneIds {
  WATER_SUPPLY_AND_DRAINAGE = 500, // 给排水/用气
  GAS_SUPPLY_AND_CONSUMPTION = 501, // 供气/用气
  HOT_STEAM = 502, // 热蒸汽
  COMPRESSED_AIR = 503, // 压缩空气
  INDUSTRIAL_GASES = 504, // 工业气体
  HEATING_VENTILATION_AND_AIR_CONDITIONING = 505, // 供暖通风与空气调节
  INDUSTRIAL_LIQUIDS = 506, // 工业液体
  ELEC = 507, // 电能
}

// 工质场景对应柱状图颜色
export const EnergyMediumSceneIdsColorDisplay = {
  [EnergyMediumSceneIds.WATER_SUPPLY_AND_DRAINAGE]: '#53DEF4',
  [EnergyMediumSceneIds.GAS_SUPPLY_AND_CONSUMPTION]: '#F4C345',
  [EnergyMediumSceneIds.HOT_STEAM]: '#F48C45',
  [EnergyMediumSceneIds.COMPRESSED_AIR]: '#234CD0',
  [EnergyMediumSceneIds.INDUSTRIAL_GASES]: '#6844EA',
  [EnergyMediumSceneIds.HEATING_VENTILATION_AND_AIR_CONDITIONING]: '#51BC1C',
  [EnergyMediumSceneIds.INDUSTRIAL_LIQUIDS]: '#F44577',
  [EnergyMediumSceneIds.ELEC]: '#198BF4',
};

// 工质 icon 映射
export const EnergyMediumIconDisplay = {
  [EnergyMediumSceneIds.WATER_SUPPLY_AND_DRAINAGE]: {
    active: waterActive,
    passive: waterPassive,
  },
  [EnergyMediumSceneIds.GAS_SUPPLY_AND_CONSUMPTION]: {
    active: gasActive,
    passive: gasPassive,
  },
  [EnergyMediumSceneIds.HOT_STEAM]: {
    active: hotSteamActive,
    passive: hotSteamPassive,
  },
  [EnergyMediumSceneIds.COMPRESSED_AIR]: {
    active: compressedAirActive,
    passive: compressedAirPassive,
  },
  [EnergyMediumSceneIds.INDUSTRIAL_GASES]: {
    active: IndustrialGasActive,
    passive: IndustrialGasPassive,
  },
  [EnergyMediumSceneIds.HEATING_VENTILATION_AND_AIR_CONDITIONING]: {
    active: haActive,
    passive: haPassive,
  },
  [EnergyMediumSceneIds.INDUSTRIAL_LIQUIDS]: {
    active: IndustrialLiquidActive,
    passive: IndustrialLiquidPassive,
  },
  [EnergyMediumSceneIds.ELEC]: {
    active: electricityActive,
    passive: electricityPassive,
  },
};

// echarts x 轴时间格式化
export const TimeGranularityFormat = {
  [TimeGranularity.FIFTEEN_MINUTES]: 'YYYY-MM-DD[\n]HH:mm',
  [TimeGranularity.DAY]: 'YYYY-MM-DD',
  [TimeGranularity.MONTH]: 'YYYY-MM',
  [TimeGranularityUnit.HOUR]: 'YYYY-MM-DD[\n]HH:mm',
};

// 一些默认的静态配置
export const chartDefaultOptions = {
  grid: {},
  backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
  title: {},
  legend: {},
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: 2,
    borderColor: 'transparent',
    textStyle: {
      color: 'rgba(255,255,255,0.85)',
      fontSize: 12,
    },
  },
};
