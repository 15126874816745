import { Button, EllipsisSpan, PopConfirm, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { useContext, useState } from 'react';
import { ProductionBeatConfigContext } from '../..';
import {
  ActionFormValues,
  FeatureType,
  ModalClickType,
  ModalType,
  TimeGranularityTypeDisPlay,
  formatTableDataFeature,
  formatTableDataFromFormValues,
} from '../../utils';
import ActionModal from './ActionModal';
import { PlusOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import SortModal from './SortModal';
import ActionViewModal from './ActionViewModal';
import BeatInterval from '../BeatInterval';

export const BeatConfig: React.FC = () => {
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [actionViewModalOpen, setActionViewModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  // const [form] = Form.useForm();
  const { physicalUnitName, timeResolution, quotaTypeName, actionTableData, setActionTableData } =
    useContext(ProductionBeatConfigContext);

  const [editFormValues, setEditFormValues] = useState<ActionFormValues>();

  const columns = [
    {
      title: '状态名称',
      dataIndex: 'actionName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '用能范围',
      dataIndex: 'useEnergyLowLimit',
      ellipsis: { showTitle: true },
      render: (v: number, record: ActionFormValues) => (
        <EllipsisSpan value={`${v}~${record.useEnergyUpperLimit}${physicalUnitName ?? ''}`} />
      ),
    },
    {
      title: '时长范围',
      dataIndex: 'durationRangeMin',
      ellipsis: { showTitle: true },
      render: (v: number, record: ActionFormValues) => (
        <EllipsisSpan
          value={
            !isNil(v) && !isNil(record.durationRangeMax)
              ? `${v}~${record.durationRangeMax}${TimeGranularityTypeDisPlay[timeResolution]}`
              : undefined
          }
        />
      ),
    },
    {
      title: '动作开始特征',
      dataIndex: 'startCharacteristics',
      ellipsis: { showTitle: true },
      render: (v: FeatureType, record: ActionFormValues) => {
        return (
          <EllipsisSpan
            value={formatTableDataFeature('start', record, physicalUnitName ?? '', timeResolution, quotaTypeName)}
          />
        );
      },
    },
    {
      title: '动作结束特征',
      dataIndex: 'endCharacteristics',
      ellipsis: { showTitle: true },
      render: (v: FeatureType, record: ActionFormValues) => {
        return (
          <EllipsisSpan
            value={formatTableDataFeature('end', record, physicalUnitName ?? '', timeResolution, quotaTypeName)}
          />
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (v: any, record: ActionFormValues) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setActionModalOpen(true);
                setModalType(ModalType.EDIT);
                setEditFormValues(record);
              }}
            >
              编辑
            </Button>
            <PopConfirm
              title="确定删除该动作？"
              onConfirm={() => {
                const tableData = actionTableData?.filter(item => item.id !== record.id) ?? [];
                setActionTableData?.(tableData);
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  // modal 点击事件
  const onButtonClick = (type: ModalClickType, formValues?: ActionFormValues) => {
    if (type === ModalClickType.SAVE) {
      let data = actionTableData ?? [];
      let dataIndex = data.findIndex(item => item.id === formValues?.id);
      const tableData =
        modalType === ModalType.EDIT
          ? [
              ...data.slice(0, dataIndex),
              formatTableDataFromFormValues(formValues as ActionFormValues, 'action'),
              ...data.slice(dataIndex + 1),
            ]
          : [...data, formatTableDataFromFormValues(formValues as ActionFormValues, 'action')];
      setActionTableData?.(tableData as ActionFormValues[]);
      setActionModalOpen(false);
    } else {
      setActionModalOpen(false);
    }
  };

  return (
    <div>
      <BeatInterval />
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          type="primary"
          onClick={() => {
            setActionModalOpen(true);
            setModalType(ModalType.ADD);
          }}
        >
          <PlusOutlined />
          新增动作
        </Button>
        <div
          style={{
            width: 184,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              setSortModalOpen(true);
            }}
            disabled={actionTableData?.length === 0}
          >
            动作排序
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setActionViewModalOpen(true);
            }}
            disabled={actionTableData?.length === 0}
          >
            查看动作
          </Button>
        </div>
      </div>
      <Table rowKey="id" columns={columns} dataSource={actionTableData} />
      {actionModalOpen && (
        <ActionModal
          editFormValues={editFormValues}
          modalType={modalType}
          open={actionModalOpen}
          onButtonClick={onButtonClick}
        />
      )}
      {sortModalOpen && <SortModal sortModalOpen={sortModalOpen} setSortModalOpen={setSortModalOpen} />}
      {actionViewModalOpen && (
        <ActionViewModal actionViewModalOpen={actionViewModalOpen} setActionViewModalOpen={setActionViewModalOpen} />
      )}
    </div>
  );
};

export default BeatConfig;
