import { Button, EllipsisSpan, Key, Table } from '@maxtropy/components';
import { Empty, Space } from 'antd';
import { useEffect, useState } from 'react';
import UnitSelectModal from '../UnitSelectModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  apiV2WorkShiftCalendarConfigEnergyGroupUnitTreePost,
  V2WorkShiftCalendarConfigEnergyGroupUnitTreePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { GroupDetails } from '../..';

const columns = [
  {
    title: '用能分析组',
    dataIndex: 'energyGroupName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '应用单元数',
    dataIndex: 'amount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ?? 0} />,
  },
];

export interface Props {
  value?: GroupDetails[];
  onChange?: (v: GroupDetails[]) => void;
  endDate?: string;
  startDate?: string;
  id?: string | number;
  isEdit: boolean;
}

export type EnergyUnits = Exclude<V2WorkShiftCalendarConfigEnergyGroupUnitTreePostResponse['list'], undefined>[number];

const ApplicationUnitTable: React.FC<Props> = ({ value, onChange, endDate, startDate, id, isEdit }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tableData, setTableData] = useState<GroupDetails[]>([]);
  const [energyGroupId, setEnergyGroupId] = useState<Key>();
  const [energyGroupName, setEnergyGroupName] = useState<string>();
  const [appConfigEnergyData, setAppConfigEnergyData] = useState<EnergyUnits[]>([]);
  const [unitCheckedKeys, setUnitCheckedKeys] = useState<Key[]>([]);

  // 获取应用单元树
  useEffect(() => {
    if (energyGroupId && endDate && startDate) {
      apiV2WorkShiftCalendarConfigEnergyGroupUnitTreePost({
        endDate,
        startDate,
        energyUnitGroupId: energyGroupId,
        workShiftCalendarConfigId: isEdit ? id : undefined,
      }).then(res => {
        setAppConfigEnergyData(res.list ?? []);
      });
    }
  }, [energyGroupId, endDate, startDate, id, isEdit]);

  useEffect(() => {
    if (value) {
      setTableData(value);
    }
  }, [value]);
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 150,
      ellipsis: { showTitle: true },
      render: (v: string, record: GroupDetails) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                setEnergyGroupId(record.energyGroupId);
                setEnergyGroupName(record.energyGroupName);
                setUnitCheckedKeys((record.energyUnits ?? []).map(i => i.energyUnitId as number));
                setModalOpen(true);
              }}
            >
              选择
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {isEdit && (
        <p>
          <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginRight: 4 }} />
          解除对用能单元的应用会清空其对应时间的排班、产量信息。
        </p>
      )}
      <Table
        rowKey="energyGroupId"
        columns={buildColumns}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无用能分析组" /> }}
        dataSource={tableData}
      />

      {modalOpen && (
        <UnitSelectModal
          onChange={onChange}
          energyGroupId={energyGroupId}
          energyGroupName={energyGroupName}
          unitTableData={tableData}
          setUnitTableData={setTableData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          appConfigEnergyData={appConfigEnergyData}
          unitCheckedKeys={unitCheckedKeys}
          setUnitCheckedKeys={setUnitCheckedKeys}
        />
      )}
    </>
  );
};
export default ApplicationUnitTable;
