import classNames from 'classnames';
import styles from './index.module.scss';
import { EnergyPlanDetailListItem, PlanRange, PlanType, deleteEnergyPlanDetail } from '@/api/plustekEnergyPlan';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, PopConfirm, Tag } from '@maxtropy/components';
import dayjs from 'dayjs';

interface Props {
  cardInfo: EnergyPlanDetailListItem;
  selectId?: number;
  setSelectId: (v?: number) => void;
  setSelectedEnergyPlanDetail: (v?: EnergyPlanDetailListItem) => void; // 设置被选中的计划的计划详情
  isEditPlanDetail: boolean; // 是否编辑计划详情
  setIsEditPlanDetail: (v: boolean) => void; // 设置是否编辑计划详情
  updateFn: () => void;
  setIsSave: (v: boolean) => void; // 设置是否保存
  isEdit?: boolean;
}

export const PlanTypeDisplay = {
  [PlanType.DAY]: '日计划',
  [PlanType.MONTH]: '月计划',
  [PlanType.QUARTER]: '季度计划',
  [PlanType.YEAR]: '年计划',
};

const CardItem: React.FC<Props> = props => {
  const {
    updateFn,
    cardInfo,
    selectId,
    setSelectId,
    setSelectedEnergyPlanDetail,
    isEditPlanDetail,
    setIsEditPlanDetail,
    setIsSave,
    isEdit,
  } = props;

  const onDelete = (id: number) => {
    deleteEnergyPlanDetail(id).then(() => {
      updateFn();
    });
  };

  const dateDisplay = () => {
    return cardInfo.planType === PlanType.DAY
      ? dayjs(cardInfo.planDate).format('YYYY年MM月')
      : dayjs(cardInfo.planDate).format('YYYY年');
  };
  const infoDisplay = () => {
    return (
      <span style={{ color: 'var(--primary-color)' }}>{`${dateDisplay()} ${PlanTypeDisplay[cardInfo.planType]} ${
        cardInfo.planRange === PlanRange.ENERGY_UNIT ? '用能单元' : '设备'
      }`}</span>
    );
  };

  const onToggle = () => {
    // 用能计划详情id
    setSelectId(cardInfo.id);
    // 用能计划类型
    setSelectedEnergyPlanDetail(cardInfo);
    setIsEditPlanDetail(false);
  };

  return (
    <div
      className={classNames(styles.cardItem, selectId === cardInfo.id ? styles.cardItemClicked : null)}
      onClick={() => {
        // 切换卡片
        if (selectId !== cardInfo.id) {
          if (isEditPlanDetail) {
            Modal.confirm({
              title: <span>是否保存{infoDisplay()}?</span>,
              content: '若不保存，填写的数据将被清空。',
              okText: '保存',
              onOk: () => {
                setIsSave(true);
                onToggle();
              },
              onCancel: () => {
                setIsSave(false);
                onToggle();
              },
            });
          } else {
            onToggle();
          }
        }
      }}
    >
      <p className={styles.firstLine}>{dateDisplay()}</p>
      <div className={styles.secondLine}>
        <p className={styles.secondLine_left}>{PlanTypeDisplay[cardInfo.planType]}</p>
        {cardInfo.planRange === PlanRange.ENERGY_UNIT ? (
          <Tag border="solid" color="#52E7FF">
            用能单元
          </Tag>
        ) : (
          <Tag border="solid" color="#57FB8B">
            设备
          </Tag>
        )}
      </div>
      {isEdit && (
        <div className={styles.closeIcon}>
          <PopConfirm
            placement="top"
            title={
              <p>
                确认删除
                {infoDisplay()}
                ，删除后无法恢复；
              </p>
            }
            onConfirm={() => onDelete(cardInfo.id)}
          >
            <CloseOutlined style={{ color: 'rgba(255,255,255,0.45)' }} />
          </PopConfirm>
        </div>
      )}
    </div>
  );
};

export default CardItem;
