import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { useAsync, BaseInfoContent, Form } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { getProtocol } from '../../../api/protocol';
import { GatewayDetail, SYSTEM_TYPE_MAP, TransferProtocolTypeDisplay } from '../../../api/gateway';

export interface GatewayDisplayProps {
  row?: GatewayDetail;
  className?: string;
  style?: CSSProperties;
}

const GatewayDisplay: React.FC<GatewayDisplayProps> = ({ row, className, style }) => {
  const iotProtocolData = useAsync(getProtocol);

  return (
    <BaseInfoContent layout="horizontal">
      <Form.Item label="网关编号">{row?.serialNumber}</Form.Item>

      <Form.Item label="网关名称">{row?.name}</Form.Item>

      <Form.Item label="所属组织">{row?.customerFullName}</Form.Item>

      <Form.Item label="物联层协议">
        {iotProtocolData?.list?.find(item => item.id === row?.iotProtocol)?.name}
      </Form.Item>

      {row?.iotProtocol === IotProtocolType.MOCKINGBIRD && (
        <>
          <Form.Item label="上传频率">{row?.uploadFrequency ? Object.values(row?.uploadFrequency)[0] : '-'}</Form.Item>

          <Form.Item label="网关系统类型">
            {row?.systemType !== undefined ? SYSTEM_TYPE_MAP[row.systemType] : '-'}
          </Form.Item>

          <Form.Item label="网关元数据采集设备">
            {row?.deviceName || '-'}
            {row?.deviceId && (
              <span style={{ marginLeft: 10 }}>
                <Link to={`/device/manage/device/${row.deviceId}/detail`}>查看</Link>
              </span>
            )}
          </Form.Item>

          <Form.Item label="下发类型">
            {row?.transferProtocol !== undefined ? TransferProtocolTypeDisplay[row.transferProtocol] : '-'}
          </Form.Item>
        </>
      )}

      <Form.Item label="备注">{row?.remark || '-'}</Form.Item>

      <Form.Item label="所在组网">
        {row?.edgeNetwork?.netName || '-'}
        <span style={{ marginLeft: 10 }}>
          <Link to={`/device/networking/networkingList?netNo=${row?.edgeNetwork?.netNo ?? ''}`}>查看</Link>
        </span>
      </Form.Item>
    </BaseInfoContent>
  );
};

export default GatewayDisplay;
