import { DateBtn, ParamsOption } from './config';

// 机器类型
export enum MachineType {
  AIRCOMPRESSOR = 1,
  NGENERATOR = 2,
}

export const MachineTypeDisplay = {
  [MachineType.AIRCOMPRESSOR]: '空压机',
  [MachineType.NGENERATOR]: '制氮机',
};

export enum CenterTime {
  Yesterday = 1,
  LastMonth,
}

export const CenterTimeDisPlay = {
  [CenterTime.Yesterday]: '昨日',
  [CenterTime.LastMonth]: '上月',
};

export enum DatePickerType {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}

export const datePickerTypeDisplay = {
  [DatePickerType.MINUTE_1]: '1分钟',
  [DatePickerType.MINUTE_15]: '15分钟',
  [DatePickerType.HOUR]: '按小时',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

export enum IndicatorType {
  AirPowerToElectricityRatio = 1, // 气电比
  AirTotalPowerConsumption = 2, // 用电量
  AirTotalFlow = 3, // 总流量
  AirInstantaneousFlow = 4, // 瞬时流量
  AirInstantaneousPower = 5, // 瞬时功率

  NGPowerToElectricityRatio = 18, // 气电比
  NGTotalPowerConsumption = 19, // 用电量
  NGTotalFlow = 20, // 总流量
  NGInstantaneousFlow = 21, // 瞬时流量
  NGInstantaneousPower = 22, // 瞬时功率
}

export enum NodeType {
  COMPRESSOR = 1,
  NITROGEN_GENERATOR,
  GAS_TANK,
  STATION_OUTLET,
  GAS_MANIFOLD,
  THERMOMETER,
  REFRIGERATOR,
  FLOW_METER,
  PRESSURE_GAUGE,
}

export const NodeTypeDisplay = {
  [NodeType.COMPRESSOR]: '空压机',
  [NodeType.NITROGEN_GENERATOR]: '制氮机',
  [NodeType.GAS_TANK]: '储气罐',
  [NodeType.STATION_OUTLET]: '站房出口',
  [NodeType.GAS_MANIFOLD]: '集/分气包',
  [NodeType.THERMOMETER]: '温度计',
  [NodeType.REFRIGERATOR]: '冷干机',
  [NodeType.FLOW_METER]: '流量计',
  [NodeType.PRESSURE_GAUGE]: '压力表',
};

export interface CurrentItemType {
  id?: IndicatorType;
  btnName?: string;
  unit?: string;
  name?: string; // tab名称
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
  getChartOption?: (value: ParamsOption) => any;
}
