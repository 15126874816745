import { EllipsisSpan, Radio, Tooltip } from '@maxtropy/components';
import { Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  TimeSharingElectricityAfterAdjustTableDataItem,
  TimeSharingElectricityFinalListItem,
  TimeSharingElectricityTableDataItem,
  getTimeSharingElectricityAfterAdjustTableData,
  getTimeSharingElectricityFinalTableData,
  getTimeSharingElectricityTableData,
} from '@/api/universeMeterQuery';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { getTimeShareTableSum } from '../../handleTreeDataUtils';

enum RadioType {
  METER = 1,
  AFTER_ADJUST = 2,
  FINAL = 3,
}

const TimeShareEleTable: React.FC<{ level?: number }> = ({ level }) => {
  const { id } = useParams<{ id: string }>();
  const [radioValue, setRadioValue] = useState<RadioType>(RadioType.METER);
  const [meterTable, setMeterTable] = useState<TimeSharingElectricityTableDataItem[]>([]);
  const [afterAdjustTable, setAfterAdjustTable] = useState<TimeSharingElectricityAfterAdjustTableDataItem[]>([]);
  const [finalTable, setFinalTable] = useState<TimeSharingElectricityFinalListItem[]>([]);

  useEffect(() => {
    if (id) {
      if (radioValue === RadioType.METER) {
        getTimeSharingElectricityTableData(id, level).then(res => {
          if (res) {
            const tableData: TimeSharingElectricityTableDataItem[] = [];
            (res.list ?? []).forEach(project => {
              (project.rows ?? []).forEach((row, index) => {
                tableData.push({
                  id: `${project.meterReadingObjectId}-${row.deviceId}-${index}`,
                  meterReadingObjectId: project.meterReadingObjectId,
                  meterReadingObjectName: project.meterReadingObjectName,
                  meterReadingLevel: project.meterReadingLevel,
                  ...row,
                });
              });
            });
            setMeterTable(tableData);
          }
        });
      } else if (radioValue === RadioType.AFTER_ADJUST) {
        getTimeSharingElectricityAfterAdjustTableData(id, level).then(res => {
          if (res) {
            const tableData: TimeSharingElectricityAfterAdjustTableDataItem[] = [];
            (res.list ?? []).forEach(project => {
              (project.rows ?? []).forEach((row, index) => {
                tableData.push({
                  id: `${project.meterReadingObjectId}-${row.deviceId}-${index}`,
                  meterReadingObjectId: project.meterReadingObjectId,
                  meterReadingObjectName: project.meterReadingObjectName,
                  meterReadingLevel: project.meterReadingLevel,
                  ...row,
                });
              });
            });
            setAfterAdjustTable(tableData);
          }
        });
      } else if (radioValue === RadioType.FINAL) {
        getTimeSharingElectricityFinalTableData(id, level).then(res => {
          if (res) {
            setFinalTable(res.list ?? []);
          }
        });
      }
    }
  }, [id, radioValue, level]);

  const meterColumns = useMemo(() => {
    const levelMeterColumns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => {
          const firstIndex = meterTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = meterTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
        render: (v: number) => <EllipsisSpan value={v ? `${v}级` : v} />,
      },
    ];

    const columns = [
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: TimeSharingElectricityTableDataItem, index?: number) => {
          const firstIndex = meterTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = meterTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
      },
      {
        title: '类型',
        dataIndex: 'deviceTypeName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        isHide: true,
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '对应拓扑节点',
        dataIndex: 'topologyNodeName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        isHide: true,
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '抄表值 (尖)',
        dataIndex: 'summitMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '抄表值 (峰)',
        dataIndex: 'peakMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '抄表值 (平)',
        dataIndex: 'plainMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '抄表值 (谷) ',
        dataIndex: 'valleyMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '尖峰平谷合计',
        dataIndex: 'totalMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '实际抄表值',
        dataIndex: 'realReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: (
          <>
            <span style={{ paddingRight: 5 }}>核对差值</span>
            <Tooltip placement="topLeft" title={'核对差值=实际抄表值-尖峰平谷合计'}>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
            </Tooltip>
          </>
        ),
        dataIndex: 'checkAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
    ];

    if (!isNil(level)) {
      return [...levelMeterColumns, ...columns];
    }
    return columns;
  }, [level, meterTable]);

  const afterAdjustColumns = useMemo(() => {
    const levelAfterAdjustColumns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: TimeSharingElectricityAfterAdjustTableDataItem, index?: number) => {
          const firstIndex = afterAdjustTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = afterAdjustTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
        render: (v: number) => <EllipsisSpan value={v ? `${v}级` : v} />,
      },
    ];

    const columns = [
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: TimeSharingElectricityAfterAdjustTableDataItem, index?: number) => {
          const firstIndex = afterAdjustTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = afterAdjustTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
      },
      {
        title: '类型',
        dataIndex: 'deviceTypeName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        isHide: true,
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '对应拓扑节点',
        dataIndex: 'topologyNodeName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        isHide: true,
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后 (尖)',
        dataIndex: 'summitAfterAdjustAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后 (峰)',
        dataIndex: 'peakAfterAdjustAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后 (平)',
        dataIndex: 'plainAfterAdjustAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后 (谷) ',
        dataIndex: 'valleyAfterAdjustAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '尖峰平谷合计',
        dataIndex: 'totalAfterAdjustAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后抄表值',
        dataIndex: 'afterAdjustMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
    ];
    if (!isNil(level)) {
      return [...levelAfterAdjustColumns, ...columns];
    }
    return columns;
  }, [afterAdjustTable, level]);

  const finalColumns = useMemo(() => {
    const levelFinalColumns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: TimeSharingElectricityFinalListItem, index?: number) => {
          const firstIndex = finalTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = finalTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
        render: (v: number) => <EllipsisSpan value={v ? `${v}级` : v} />,
      },
    ];

    const columns = [
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: TimeSharingElectricityFinalListItem, index?: number) => {
          const firstIndex = finalTable.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
          const objectOccupyRows = finalTable.filter(
            i => i.meterReadingObjectId === record.meterReadingObjectId
          ).length;
          if (index === firstIndex) {
            return { rowSpan: objectOccupyRows };
          }
          return { rowSpan: 0 };
        },
      },
      {
        title: '最终值 (尖)',
        dataIndex: 'finalAmountSummit',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '最终值 (峰)',
        dataIndex: 'finalAmountPeak',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '最终值 (平)',
        dataIndex: 'finalAmountPlain',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '最终值 (谷) ',
        dataIndex: 'finalAmountValley',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '尖峰平谷合计',
        dataIndex: 'finalAmountTotal',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '分摊后抄表值',
        dataIndex: 'afterApportionMeterReadingAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
    ];
    if (!isNil(level)) {
      return [...levelFinalColumns, ...columns];
    }
    return columns;
  }, [finalTable, level]);

  return (
    <>
      <p className={styles.title}>分时电量抄表</p>
      <Radio.Group
        value={radioValue}
        optionType="button"
        buttonStyle="solid"
        onChange={e => {
          setRadioValue(e.target.value);
        }}
      >
        <Radio value={RadioType.METER}>抄表值</Radio>
        <Radio value={RadioType.AFTER_ADJUST}>调整后值</Radio>
        <Radio value={RadioType.FINAL}>最终值</Radio>
      </Radio.Group>

      {radioValue === RadioType.METER && (
        <div className={styles.tableStyles}>
          <Table
            columns={meterColumns}
            dataSource={meterTable}
            rowKey="id"
            pagination={false}
            bordered
            sticky
            summary={pageData => {
              const data = pageData.map(i => ({
                summit: i.summitMeterReadingAmount,
                peak: i.peakMeterReadingAmount,
                plain: i.plainMeterReadingAmount,
                valley: i.valleyMeterReadingAmount,
                sum: i.totalMeterReadingAmount,
                fact: i.realReadingAmount,
                check: i.checkAmount,
              }));
              const { totalSummit, totalPeak, totalPlain, totalValley, totalSum, totalFact, totalCheck } =
                getTimeShareTableSum(data, true);

              const nullCell = isNil(level) ? [1, 2, 3] : [1, 2, 3, 4];
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>累计</Table.Summary.Cell>
                    {nullCell.map((i, index) => (
                      <Table.Summary.Cell key={index} index={i}>
                        --
                      </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell index={isNil(level) ? 4 : 5}>
                      {isNil(totalSummit) ? '--' : totalSummit}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 5 : 6}>
                      {isNil(totalPeak) ? '--' : totalPeak}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 6 : 7}>
                      {isNil(totalPlain) ? '--' : totalPlain}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 7 : 8}>
                      {isNil(totalValley) ? '--' : totalValley}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 8 : 9}>
                      {isNil(totalSum) ? '--' : totalSum}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 9 : 10}>
                      {isNil(totalFact) ? '--' : totalFact}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 10 : 11}>
                      {isNil(totalCheck) ? '--' : totalCheck}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      )}
      {radioValue === RadioType.AFTER_ADJUST && (
        <div className={styles.tableStyles}>
          <Table
            columns={afterAdjustColumns}
            dataSource={afterAdjustTable}
            rowKey="id"
            pagination={false}
            bordered
            sticky
            summary={pageData => {
              const data = pageData.map(i => ({
                summit: i.summitAfterAdjustAmount,
                peak: i.peakAfterAdjustAmount,
                plain: i.plainAfterAdjustAmount,
                valley: i.valleyAfterAdjustAmount,
                sum: i.totalAfterAdjustAmount,
                fact: i.afterAdjustMeterReadingAmount,
              }));
              const { totalSummit, totalPeak, totalPlain, totalValley, totalSum, totalFact } =
                getTimeShareTableSum(data);

              const nullCell = isNil(level) ? [1, 2, 3] : [1, 2, 3, 4];
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>累计</Table.Summary.Cell>
                    {nullCell.map((i, index) => (
                      <Table.Summary.Cell key={index} index={i}>
                        --
                      </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell index={isNil(level) ? 4 : 5}>
                      {isNil(totalSummit) ? '--' : totalSummit}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 5 : 6}>
                      {isNil(totalPeak) ? '--' : totalPeak}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 6 : 7}>
                      {isNil(totalPlain) ? '--' : totalPlain}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 7 : 8}>
                      {isNil(totalValley) ? '--' : totalValley}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 8 : 9}>
                      {isNil(totalSum) ? '--' : totalSum}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 9 : 10}>
                      {isNil(totalFact) ? '--' : totalFact}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      )}
      {radioValue === RadioType.FINAL && (
        <div className={styles.tableStyles}>
          <Table
            columns={finalColumns}
            dataSource={finalTable}
            rowKey="meterReadingObjectId"
            pagination={false}
            bordered
            sticky
            summary={pageData => {
              const data = pageData.map(i => ({
                summit: i.finalAmountSummit,
                peak: i.finalAmountPeak,
                plain: i.finalAmountPlain,
                valley: i.finalAmountValley,
                sum: i.finalAmountTotal,
                fact: i.afterApportionMeterReadingAmount,
              }));
              const { totalSummit, totalPeak, totalPlain, totalValley, totalSum, totalFact } =
                getTimeShareTableSum(data);
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>累计</Table.Summary.Cell>
                    {!isNil(level) && <Table.Summary.Cell index={1}>--</Table.Summary.Cell>}
                    <Table.Summary.Cell index={isNil(level) ? 1 : 2}>
                      {isNil(totalSummit) ? '--' : totalSummit}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 2 : 3}>
                      {isNil(totalPeak) ? '--' : totalPeak}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 3 : 4}>
                      {isNil(totalPlain) ? '--' : totalPlain}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 4 : 5}>
                      {isNil(totalValley) ? '--' : totalValley}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 5 : 6}>
                      {isNil(totalSum) ? '--' : totalSum}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={isNil(level) ? 6 : 7}>
                      {isNil(totalFact) ? '--' : totalFact}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      )}
    </>
  );
};
export default TimeShareEleTable;
