import React, { FC, useEffect } from 'react';
import { AttachmentUpload, Form, BaseInfoContent, SubContent, ShowInput } from '@maxtropy/components';
import { getBatchDeviceTemplateProperty } from '../../../api/batch';
import TagDisplayForm from '@/shared/components/MultiModalSelect/TagDisplayForm';
import DeviceTagsDisplayForm from '@/shared/components/DeviceTags/TagsDisplayForm';

interface PropertyDetailProps {
  id: string;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const PropertyDetail: FC<PropertyDetailProps> = ({ id }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateProperty(id).then(res => {
        form.setFieldsValue({
          typeId: res.typeName,
          modelId: res.modelName,
          pic: res.pic,
          customerMcid: res.customerName,
          assetCode: res.assetCode,
          ouIds: res.ouIds,
          tags: res.tags ? res.tags.split(',') : [],
          modelNo: res.modelNo,
        });
      });
    }
  }, [id, form]);

  return (
    <Form form={form} layout="vertical" {...formLayout}>
      <SubContent title="设备信息">
        <BaseInfoContent>
          <Form.Item name="typeId" label="所属类目">
            <ShowInput />
          </Form.Item>
          <Form.Item name="modelId" label="所属型号">
            <ShowInput />
          </Form.Item>
          <Form.Item name="customerMcid" label="所属组织">
            <ShowInput />
          </Form.Item>
          <Form.Item name="assetCode" label="资产编码">
            <ShowInput />
          </Form.Item>
          <Form.Item name="pic" label="设备图片">
            <AttachmentUpload disabled fileSize={5} />
          </Form.Item>
          <Form.Item name="modelNo" label="物模型型号">
            <ShowInput />
          </Form.Item>
          <Form.Item name="ouIds" label="运营单元">
            <TagDisplayForm />
          </Form.Item>
          <Form.Item name="tags" label="设备标签">
            <DeviceTagsDisplayForm />
          </Form.Item>
        </BaseInfoContent>
      </SubContent>
    </Form>
  );
};

export default PropertyDetail;
