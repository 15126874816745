import { Button, Drawer, Space } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { basicConfig, fitScreenView, formatNodesAndEdges } from './basicConfig';
import { useSize } from 'ahooks';
import styles from './index.module.scss';
import G6, { Graph } from '@antv/g6';
import { FormContent } from '@maxtropy/components';
import ItemProcessDetail from './components/ItemProcessDetail';
import { EnergyWorkingProcessType, EnergyWorkingProcessTypeLabel, EnergyWorkingProcessTypeLabelColor } from '@/api/uet';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  V2UetEmtPreviewDashboardAuthorityPostResponse,
  apiV2UetEmtPreviewDashboardAuthorityPost,
} from '@maxtropy/device-customer-apis-v2';

const ViewEnergyWorkingTopo: FC = () => {
  const { energyId } = useParams<{ id: string; energyId: string }>();
  const [open, setOpen] = useState(false); // 抽屉的弹窗
  const ref = useRef<HTMLDivElement>(); // ref考虑自适应问题
  const [item, setItem] = useState<any>(); // 文档与版本脱离，所以用any 目标节点的实例
  const [itemDetailId, setItemDetailId] = useState<number>(); // 每个节点的详情id
  const graph = useRef<Graph>(); // graph图的实例
  const [wheelzoomPercent, setWheelzoomPercent] = useState<number>(100);
  const wheelzoomPercentRef = useRef<number>(1);
  const wheelzoomInitPercentRef = useRef<number>();
  const [data, setData] = useState<V2UetEmtPreviewDashboardAuthorityPostResponse>();

  useEffect(() => {
    if (energyId) {
      apiV2UetEmtPreviewDashboardAuthorityPost({ id: +energyId }).then(setData);
    }
  }, [energyId]);

  const size = useSize(ref);
  // 自适应dom的变化
  useEffect(() => {
    if (graph.current && size) {
      fitScreenView(graph.current, size.width, size.height);
      setWheelzoomPercent(100);
      wheelzoomInitPercentRef.current = graph.current.getZoom();
    }
  }, [size]);
  // 自适应屏幕缩放
  const handleResize = () => {
    if (ref.current && graph.current) {
      fitScreenView(graph.current, ref.current.clientWidth, ref.current.clientHeight);
      setWheelzoomPercent(100);
      wheelzoomInitPercentRef.current = graph.current.getZoom();
    }
  };

  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatData = useMemo(() => {
    // 对返回数据进行处理
    if (data) {
      // data.nodes.forEach(i => {
      //   i.entryVos = i.entryIds?.map((c, i) => ({ id: c, hasDevice: i % 2 === 0 ? true : false }));
      //   i.exitVos = i.exitIds?.map((c, i) => ({ id: c, hasDevice: i % 2 === 0 ? true : false }));
      // });
      return formatNodesAndEdges(data);
    }
  }, [data]);

  useEffect(() => {
    // console.log(formatData);
    // const grid = new G6.Grid();
    // const minimap = new G6.Minimap({
    //   size: [100, 100],
    //   className: 'minimap',
    //   type: 'delegate',
    // });
    if (formatData) {
      // 创建实例
      graph.current = new G6.Graph(basicConfig());
      // 读取数据
      graph.current.data(formatData);
      // 渲染图
      graph.current.render();
      // 绑定事件，打开抽屉
      graph.current.on('nodeselectchange', ev => {
        if ((ev.selectedItems as any).nodes.length) {
          showInfoByDrawer(ev.target, ev.select as boolean);
        } else {
          setOpen(false);
        }
      });

      // // 监听图上的点击事件
      // graph.current.on('node:click', (e: IG6GraphEvent) => {
      //   const item = e.item; // 获取被点击的项
      //   if (item) {
      //     // 如果点击的是节点或边，执行相应操作
      //     console.log('Clicked item:', item);
      //     console.log('Clicked model:', item.getModel());
      //     console.log('Clicked :', e.target.cfg);
      //     // 在这里编写你需要执行的代码
      //   }
      // });

      graph.current.on('wheelzoom', ev => {
        // 返回值zoom表示当前视口的缩放比例
        const zoom = graph.current!.getZoom();
        if (wheelzoomInitPercentRef.current) {
          setWheelzoomPercent((zoom / wheelzoomInitPercentRef.current) * 100);
        }
      });
    }
  }, [formatData]);

  const showInfoByDrawer = (item: any, selected: boolean) => {
    if (item && item._cfg.id) {
      setItem(item); // 保存antv G6 的node实例
      // 通过过程id查询详细信息
      setItemDetailId(item._cfg.id);
      setOpen(selected);
    }
  };

  // 视图放大与缩小
  const onChangeZoom = (key: string) => {
    if (key === 'minus') {
      graph.current!.zoom(wheelzoomPercentRef.current - 0.2);
      setWheelzoomPercent(num => {
        return (num -= 20);
      });
      graph.current!.fitCenter();
    } else {
      graph.current!.zoom(wheelzoomPercentRef.current + 0.2);
      setWheelzoomPercent(num => {
        return (num += 20);
      });
      graph.current!.fitCenter();
    }
  };

  return (
    <>
      <FormContent title="预览视图" style={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
        <div
          style={{
            width: 'calc(100% + 80px)',
            height: '95%',
            display: 'flex',
            marginLeft: '-40px',
            marginRight: '-40px',
          }}
        >
          <div
            className={styles.left}
            ref={d => {
              if (d) {
                ref.current = d;
              }
            }}
          >
            <div className={styles.wheelzoomStyle}>
              <div className={styles.wheelzoomBtnStyle}>
                <Button type="text" icon={<MinusOutlined />} onClick={() => onChangeZoom('minus')}></Button>
              </div>
              <div style={{ width: 68, textAlign: 'center' }}>{wheelzoomPercent.toFixed(0)}%</div>
              <div className={styles.wheelzoomBtnStyle}>
                <Button type="text" icon={<PlusOutlined />} onClick={() => onChangeZoom('plus')}></Button>
              </div>
            </div>
            <Space className={styles.block}>
              {Object.entries(EnergyWorkingProcessTypeLabel).map(([value, key]) => {
                const color = `${EnergyWorkingProcessTypeLabelColor[+value as EnergyWorkingProcessType]}`;
                return (
                  <div key={key} className={styles.item}>
                    <div
                      style={{
                        background: color,
                      }}
                      className={styles.pipe}
                    />
                    <div>{key}</div>
                  </div>
                );
              })}
            </Space>
            <div
              className={styles.bgImg}
              style={{ width: '100%', height: '100%', backgroundColor: '#232324' }}
              id="mountNode"
            ></div>
          </div>

          <div className={styles.right} style={{ width: open ? 400 : 0, height: '100%', backgroundColor: '#232324' }}>
            <Drawer
              className={styles.drawerStyle}
              title={'过程详情'}
              placement="right"
              width={400}
              mask={false}
              onClose={() => {
                graph.current?.setItemState(item, 'selected', false);
                setOpen(false);
              }}
              open={open}
            >
              <ItemProcessDetail itemDetailId={itemDetailId} />
            </Drawer>
          </div>
        </div>
      </FormContent>
    </>
  );
};

export default ViewEnergyWorkingTopo;
