import { Gateway } from '@/api/netWorking';
import { InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

interface Props {
  record: Gateway;
  setChoseGatewayDataSource: (v: Gateway[]) => void;
  choseGatewayDataSource: Gateway[];
  onChange: (value: Gateway[]) => void;
}

const IpInput: React.FC<Props> = ({ record, setChoseGatewayDataSource, choseGatewayDataSource, onChange }) => {
  const [first, setFirst] = useState<number>(
    isNaN(Number(record?.gatewayIp?.at(0))) ? 0 : Number(record?.gatewayIp?.at(0))
  );
  const [second, setSecond] = useState<number>(
    isNaN(Number(record?.gatewayIp?.at(1))) ? 0 : Number(record?.gatewayIp?.at(1))
  );
  const [third, setThird] = useState<number>(
    isNaN(Number(record?.gatewayIp?.at(2))) ? 0 : Number(record?.gatewayIp?.at(2))
  );
  const [fourth, setFourth] = useState<number>(
    isNaN(Number(record?.gatewayIp?.at(3))) ? 0 : Number(record?.gatewayIp?.at(3))
  );

  useEffect(() => {
    const choseGateway = choseGatewayDataSource.map(i => {
      if (i.gatewayId !== record.gatewayId) return i;
      return {
        ...i,
        gatewayIp: [first as number, second as number, third as number, fourth as number],
      };
    });
    setChoseGatewayDataSource(choseGateway);
    onChange(choseGateway);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, second, third, fourth]);

  function formatNum(v: number | undefined) {
    const value = Math.trunc(v as number);
    return `${value}`;
  }

  return (
    <div className={record.isRepeat ? styles.inputNum : styles.inputNumDefault}>
      <InputNumber
        formatter={formatNum}
        max={255}
        min={0}
        value={isNaN(first) ? null : first}
        onChange={v => setFirst(v as number)}
      />
      <span style={{ display: 'inline-block', padding: '0 10px' }}>.</span>
      <InputNumber
        formatter={formatNum}
        max={255}
        min={0}
        value={isNaN(second) ? null : second}
        onChange={v => setSecond(v as number)}
      />
      <span style={{ display: 'inline-block', padding: '0 10px' }}>.</span>
      <InputNumber
        formatter={formatNum}
        max={255}
        min={0}
        value={isNaN(third) ? null : third}
        onChange={v => setThird(v as number)}
      />
      <span style={{ display: 'inline-block', padding: '0 10px' }}>.</span>
      <InputNumber
        formatter={formatNum}
        max={255}
        min={0}
        value={isNaN(fourth) ? null : fourth}
        onChange={v => setFourth(v as number)}
      />
    </div>
  );
};

export default IpInput;
