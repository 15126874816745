import React from 'react';
import styles from './index.module.scss';
import { Themes, useTheme } from '@maxtropy/components';

export interface NotAuthorizedProps {
  text?: string;
}

const NotAuthorized: React.FC<NotAuthorizedProps> = props => {
  const { text = '您没有本页面的操作权限，请联系系统管理员，为您开通对应权限。' } = props;
  const theme = useTheme();

  return (
    <>
      <div className={styles.pageNotAuthorized}>
        <div className={styles.content}>
          <div className={`${styles.logo} ${theme === Themes.DARK ? styles.darkImg : styles.lightImg}`} />
          <div className={styles.text}>{text}</div>
        </div>
      </div>
    </>
  );
};

export default NotAuthorized;
