import ReactEcharts from 'echarts-for-react';
import SubTitle from '../subTitle';
import { BaseOeeInfoList } from '../DeviceUptime';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash-es';
import { Empty } from '@maxtropy/components';

const CustomDateDeviceAnalysis = ({ data, date }: { data: BaseOeeInfoList; date: Dayjs }) => {
  const getChartOption = () => {
    return {
      grid: {
        left: 10,
        right: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(255,255,255,0.1)',
          },
        },
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        padding: [8, 12, 8, 12],
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
          lineHeight: 20,
        },
        formatter: (tooltipData: any) => {
          return `
          <div style='color: rgba(255,255,255,0.65)'>${dayjs(date).format('YYYY-MM-DD') ?? '--'}</div>
          <div>${tooltipData[0]?.name ?? '--'}</div>
          <div>${tooltipData
            ?.map(
              (item: any) =>
                `<div style='width: 174px;display: flex;align-items: center;margin-bottom: 4px;'><span style='display:inline-block;width:8px;height:8px;background:${
                  item?.color
                };border-radius:50%;margin-right:6px'></span> ${
                  item.seriesName ?? '--'
                }<span style='flex:1;text-align: right'>${item.value ?? '--'}%</span></div></div>`
            )
            .join('')}</div>
        `;
        },
      },
      legend: {
        right: 0,
        top: 6,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 16,
        itemHeight: 4,
        itemGap: 16,
        padding: [0, 8, 0, 0],
      },
      xAxis: [
        {
          type: 'category',
          data: data!.map(i => i.deviceName),
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 8,
            color: 'rgba(255,255,255,0.85)',
            lineHeight: 14,
          },
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '%',
          nameGap: 8,
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.3)',
            },
          },
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            lineHeight: 20,
          },
          axisLine: {},
          axisLabel: {
            margin: 8,
            color: 'rgba(255,255,255,0.85)',
            lineHeight: 20,
          },
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          bottom: 20,
          height: 20,
          backgroundColor: 'rgba(226,227,229,0.5)',
          fillerColor: 'rgba(142,177,224,0.3)',
          textStyle: {
            fontSize: 10,
          },
        },
      ],
      series: [
        {
          name: 'OEE',
          type: 'bar',
          barWidth: 8,
          data: data!.map(i => i.oee),
          itemStyle: {
            color: '#2D8DFF',
          },
        },
        {
          name: '时间稼动率',
          type: 'bar',
          barWidth: 8,
          data: data!.map(i => i.timeOee),
          itemStyle: {
            color: '#52E7FF',
          },
        },
        {
          name: '性能稼动率',
          type: 'bar',
          barWidth: 8,
          data: data!.map(i => i.performanceOee),
          itemStyle: {
            color: '#6F47FF',
          },
        },
        {
          name: '良品率',
          type: 'bar',
          barWidth: 8,
          barGap: '100%',
          data: data!.map(i => i.yieldRate),
          itemStyle: {
            color: '#FF477B',
          },
        },
      ],
    };
  };

  return (
    <div>
      <SubTitle title="设备OEE分析" />
      {isEmpty(data) ? (
        <Empty style={{ marginTop: 20 }} />
      ) : (
        <ReactEcharts
          style={{
            width: '100%',
            height: 356,
          }}
          option={getChartOption()}
          notMerge
          lazyUpdate={false}
        />
      )}
    </div>
  );
};

export default CustomDateDeviceAnalysis;
