import {
  addBtnConfig,
  AreaType,
  BtnConfigActionType,
  deleteBtnConfig,
  getPortalBtnList,
  MajorShowBtnConfigListRes,
  StationType,
  StationTypeFormtter,
  updatePortalBtn,
  UpdatePortalBtnReq,
} from '@/api/energyAssetsPortalConfig';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  InputNumber,
  Modal,
  PopConfirm,
  Radio,
  Button,
  EllipsisSpan,
  Table,
  useUpdate,
} from '@maxtropy/components';
import { PortalHighlightDisplayDataConfigPostRequest } from '@maxtropy/device-customer-apis';

import { Col, Row, Space, Spin } from 'antd';
import { Key, useEffect, useRef, useState } from 'react';
import EditBtnModalForm, { IFieldForm } from '../EditBtnModalForm';
import {
  apiV2PortalHighlightDisplayDataConfigAddPost,
  apiV2PortalHighlightDisplayDataDetailPost,
  V2PortalHighlightDisplayDataDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const columns = [
  {
    title: '序号',
    dataIndex: 'sortNum',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '按钮名称',
    dataIndex: 'buttonName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'URL',
    dataIndex: 'url',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => {
      return <EllipsisSpan value={v} />;
    },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const MajorShowBtnConfig = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [topDataLoading, setTopDataLoading] = useState(false);
  const [majorDtaConfig, setMajorDataConfig] = useState<V2PortalHighlightDisplayDataDetailPostResponse>();
  const [dataSource, setDataSource] = useState<MajorShowBtnConfigListRes[]>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [row, setRow] = useState<MajorShowBtnConfigListRes>();
  const [updateState, updateFn] = useUpdate();
  const [updateTopState, updateTopFn] = useUpdate();
  const [addBtnTypeModal, setAddBtnTypeModal] = useState<BtnConfigActionType>();
  const cFormRef = useRef<IFieldForm>();

  useEffect(() => {
    setLoading(true);
    getPortalBtnList({ type: AreaType.MajorArea }).then(res => {
      setDataSource(
        res.map((i, index) => {
          return {
            ...i,
            sortNum: index + 1,
          };
        })
      );
      setLoading(false);
    });
  }, [updateState]);

  useEffect(() => {
    setTopDataLoading(true);
    apiV2PortalHighlightDisplayDataDetailPost({})
      .then(res => {
        setMajorDataConfig(res);
        form.setFieldsValue({
          sourceType: res.sourceType,
          greenEnergyUse: res.greenEnergyUse,
          totalCarbonEmissionReduction: res.totalCarbonEmissionReduction,
          unitOutputValueEnergyCost: res.unitOutputValueEnergyCost,
        });
      })
      .finally(() => {
        setTopDataLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTopState]);

  const removeMajorArea = (id: Key) => {
    deleteBtnConfig(id).then(_ => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 180,
      render: (_: undefined, record: MajorShowBtnConfigListRes) => (
        <Space size={16}>
          <PopConfirm title={'确定删除？'} onConfirm={() => removeMajorArea(record.id)} okText="确定" cancelText="取消">
            <Button type="link" style={{ padding: 0 }}>
              删除
            </Button>
          </PopConfirm>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
              setAddBtnTypeModal(BtnConfigActionType.ADDMAJOR);
            }}
          >
            编辑
          </Button>
        </Space>
      ),
    },
  ];

  // 重置
  const init = () => {
    setRow(undefined);
    setAddBtnTypeModal(undefined);
    cFormRef.current?.reset();
  };

  const handleEditBtn = (v: UpdatePortalBtnReq | undefined) => {
    if (v) {
      if (v.id) {
        updatePortalBtn(v).then(_ => {
          init();
          updateFn();
        });
      } else {
        addBtnConfig(v).then(_ => {
          init();
          updateFn();
        });
      }
    } else {
      init();
    }
  };

  const addMajorBtn = () => {
    if (dataSource && dataSource?.length >= 4) {
      Modal.error({ title: <span style={{ color: '#fff' }}>最多新建4个按钮！</span> });
    } else {
      setRow(undefined);
      setAddBtnTypeModal(BtnConfigActionType.ADDMAJOR);
    }
  };

  const onCancel = () => {
    setIsEdit(false);
    form.setFieldsValue({
      sourceType: majorDtaConfig?.sourceType,
      greenEnergyUse: majorDtaConfig?.greenEnergyUse,
      totalCarbonEmissionReduction: majorDtaConfig?.totalCarbonEmissionReduction,
      unitOutputValueEnergyCost: majorDtaConfig?.unitOutputValueEnergyCost,
    });
  };

  // 保存上层表单
  const onFinish = (val: PortalHighlightDisplayDataConfigPostRequest) => {
    if (val) {
      apiV2PortalHighlightDisplayDataConfigAddPost(val).then(_ => {
        setIsEdit(false);
        updateTopFn();
      });
    }
  };
  return (
    <div>
      {isEdit ? (
        <Space size={8}>
          <Button
            key="saveMajor"
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            保存
          </Button>
          <Button
            key="cancelMajor"
            onClick={() => {
              onCancel();
            }}
          >
            取消
          </Button>
        </Space>
      ) : (
        <Button
          key="editMajor"
          type="primary"
          onClick={() => {
            setIsEdit(true);
          }}
        >
          编辑
        </Button>
      )}
      <Spin spinning={topDataLoading}>
        <Form
          style={{ marginTop: 12 }}
          form={form}
          labelAlign="left"
          onFinish={(val: PortalHighlightDisplayDataConfigPostRequest) => onFinish(val)}
        >
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item name="sourceType" label="站点统计数据来源" rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group style={{ width: '100%' }} disabled={!isEdit}>
                  <Radio value={StationType.ConfigArea}>{StationTypeFormtter[StationType.ConfigArea]}</Radio>
                  <Radio value={StationType.Disable}>{StationTypeFormtter[StationType.Disable]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['sourceType']}>
                {({ getFieldValue }) =>
                  getFieldValue('sourceType') === StationType.ConfigArea && (
                    <>
                      <Form.Item
                        name="greenEnergyUse"
                        label="绿色能源使用率"
                        rules={[{ required: true, message: '请输入' }]}
                      >
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="%"
                          min={0}
                          max={100}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['sourceType']}>
                {({ getFieldValue }) =>
                  getFieldValue('sourceType') === StationType.ConfigArea && (
                    <>
                      <Form.Item
                        name="totalCarbonEmissionReduction"
                        label="累计碳减排"
                        rules={[{ required: true, message: '请选择' }]}
                      >
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="tCO2e"
                          min={0}
                          max={99999}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item noStyle dependencies={['sourceType']}>
                {({ getFieldValue }) =>
                  getFieldValue('sourceType') === StationType.ConfigArea && (
                    <>
                      <Form.Item
                        name="unitOutputValueEnergyCost"
                        label="单位产值能源成本"
                        rules={[{ required: true, message: '请选择' }]}
                      >
                        <InputNumber
                          disabled={!isEdit}
                          style={{ width: '80%' }}
                          addonAfter="元/万元"
                          min={0}
                          max={10000}
                          placeholder="请输入"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

      <div>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <Button type="primary" onClick={() => addMajorBtn()}>
            新建
          </Button>
          <span style={{ marginLeft: 12 }}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            <span style={{ marginLeft: 4, color: 'rgba(255,255,255,0.45)' }}>最多新建4个按钮</span>
          </span>
        </div>

        <Table
          scroll={{ y: 450 }}
          rowKey="id"
          loading={loading}
          columns={[...buildColumns]}
          dataSource={dataSource}
          pagination={false}
          locale={{
            emptyText: '请添加按钮',
          }}
        />
        <EditBtnModalForm cref={cFormRef} row={row} addBtnTypeModal={addBtnTypeModal} handleEditBtn={handleEditBtn} />
      </div>
    </div>
  );
};

export default MajorShowBtnConfig;
