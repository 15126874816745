import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';
import { BalanceAlarmalarmTypeType } from './balanceAlarmManagement';
export interface BalanceAlarmLogRequest extends PageRequest {
  ouId?: number; //运营单元Id
  saleClientAccountName?: string; //客户账户名称
  alarmStartTime?: number; //报警开始日期
  alarmEndTime?: number; //报警结束日期
  alarmLevels?: number[];
}

export interface ExtraAttr {
  saleClientAccountName?: string; // 客户账户名称
  saleClientName?: string; //客户名称
  balance?: number; // 触发时余额
}

export interface BalanceAlarmLogResponse {
  id?: number;
  saleClientAccountName?: string; // 客户账户名称
  saleClientName?: string; //客户名称
  ruleCode?: number; // 报警规则编号
  name?: string; // 报警名称
  alarmTime?: string; // 报警时间
  recoverTime?: string; // 恢复时间
  attribute?: ExtraAttr; // 额外属性
  ruleName?: string; // 规则名称
}

// 获取余额报警记录列表
export function getBalanceAlarmLogList(query: BalanceAlarmLogRequest): Promise<PageResponse<BalanceAlarmLogResponse>> {
  return fetch(
    `/api/v2/server/alarm/record/page`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query }),
    },
    true
  );
}

export interface BalanceAlarmLogDetailResponse extends BalanceAlarmLogResponse {
  baseServerAlarmRuleId?: number; //报警规则id
  saleClientAccountCode?: string; // 客户账户编号
  saleClientAccountId?: string; // 客户账户id
  name?: string; // 报警信息
  alarmType?: BalanceAlarmalarmTypeType; // 报警类型
  deleteFlag?: boolean; // 报警规则是否删除的标志
}

// 获取余额报警记录详情
export function getBalanceAlarmLogDetail(id: string): Promise<BalanceAlarmLogDetailResponse> {
  return fetch(
    `/api/v2/server/alarm/record/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
