import { InfoCircleOutlined } from '@ant-design/icons';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Radio,
  SubContent,
  FormTitle,
  Tag,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BalanceAlarmalarmTypeType,
  BalanceAlarmalarmTypeTypeValue,
  IntervalType,
  IntervalTypeValue,
  PushChannelType,
} from '../../../../api/balanceAlarmManagement';
import styles from './index.module.scss';
import { AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import { apiV2ServerAlarmRuleDetailPost } from '@maxtropy/device-customer-apis-v2';
const routes = [{ name: '查看余额报警' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const BalanceAlarmDetail: FC = () => {
  const { id } = useParams<{ id: string }>(); // 读取地址栏的id
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [data, setData] = useState<any>({ alarmLevel: null });
  useEffect(() => {
    if (id) {
      apiV2ServerAlarmRuleDetailPost({ id }).then(res => {
        setData(res);

        for (let item of res.pushChannelIntervalInfoList ?? []) {
          if (item.pushChannel === PushChannelType.WEB_SOCKET) {
            form.setFieldsValue({
              pushChannel_WEBSOCKET: item.intervalType,
              intervalHours_WEBSOCKET: item.intervalHours + '小时',
            });
          } else if (item.pushChannel === PushChannelType.SMS) {
            form.setFieldsValue({
              pushChannel_SMS: item.intervalType,
              intervalHours_SMS: item.intervalHours ?? 0 / 24 + '天',
            });
          }
        }
        form.setFieldsValue({
          name: res.name,
          ouName: res.ouName,
          saleClientAccountName: `【${res.balanceSceneInfo?.saleClientAccountCode}】${res.balanceSceneInfo?.saleClientAccountName}`,
          alarmType: res.alarmType,
          threshold: res.balanceSceneInfo?.ruleBalanceInsufFixed?.threshold + '元',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form layout="vertical" form={form} {...formLayout}>
        <SubContent title="基础设定" style={{ marginBottom: 8 }}>
          <Row>
            <Col span={8}>
              <Form.Item name="name" label="规则名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouName" label="所属运营单元">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="报警等级">
                <ShowInput
                  value={
                    data.alarmLevel && (
                      <Tag
                        border="solid"
                        color={AlarmLevelColorDisplay[data.alarmLevel as keyof typeof AlarmLevelColorDisplay]}
                      >
                        {AlarmLevelDisplay[data.alarmLevel as keyof typeof AlarmLevelDisplay]}
                      </Tag>
                    )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="逻辑设定" style={{ marginBottom: 8 }}>
          <Row>
            <Col span={8}>
              <Form.Item name="saleClientAccountName" label="客户账户">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item name="alarmType" label="报警逻辑">
                <Radio.Group disabled>
                  <Radio value={BalanceAlarmalarmTypeType.BalanceLow}>
                    {BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.BalanceLow]}
                  </Radio>
                  <Radio value={BalanceAlarmalarmTypeType.EstimateBalanceLow}>
                    {BalanceAlarmalarmTypeTypeValue[BalanceAlarmalarmTypeType.EstimateBalanceLow]}
                  </Radio>
                  {/* <Radio value={BalanceAlarmalarmTypeType.DynamicEstimationHistoricalElectricityLow}>动态估算历史电费余额不足</Radio>
                  <Radio value={BalanceAlarmalarmTypeType.CapacityBalanceLow}>容量预估余额不足</Radio>  */}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item noStyle dependencies={['alarmType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('alarmType') === BalanceAlarmalarmTypeType.BalanceLow) {
                    return (
                      <Form.Item
                        name="threshold"
                        label={
                          <>
                            <span style={{ marginRight: '14px' }}>余额小于等于</span>
                            <InfoCircleOutlined
                              style={{ fontSize: '14px', marginRight: '6px', color: 'var(--mx-warning-color)' }}
                            />
                            <span style={{ fontSize: '14px', color: 'rgba(var(--base-text-color),0.35)' }}>
                              逻辑说明：当余额小于等于输入的值时，报警
                            </span>
                          </>
                        }
                      >
                        <ShowInput />
                      </Form.Item>
                    );
                  } else if (getFieldValue('alarmType') === BalanceAlarmalarmTypeType.EstimateBalanceLow) {
                    return (
                      <Form.Item
                        name="threshold"
                        label={
                          <>
                            <span style={{ marginRight: '14px' }}>暂估余额小于等于</span>
                            <InfoCircleOutlined
                              style={{ fontSize: '14px', marginRight: '6px', color: 'var(--warning-color)' }}
                            />
                            <span style={{ fontSize: '14px', color: 'rgba(var(--base-text-color),0.35)' }}>
                              逻辑说明：预测余额=实际金额-当前周期用电预估成本，当预测余额小于等于输入的值时，报警。注意，配置暂估余额报警请先配置好客户度电均价
                            </span>
                          </>
                        }
                      >
                        <ShowInput />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="触发设定" style={{ marginBottom: 8 }}>
          <Row>
            <Col span={12}>
              <Form.Item name="pushChannel_WEBSOCKET" label="报警触发后平台通知周期">
                <Radio.Group disabled>
                  <Radio value={IntervalType.ONCE}>{IntervalTypeValue[IntervalType.ONCE]}</Radio>
                  <Radio value={IntervalType.HOUR}>{IntervalTypeValue[IntervalType.HOUR]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle dependencies={['pushChannel_WEBSOCKET']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('pushChannel_WEBSOCKET') !== IntervalType.ONCE && (
                      <Form.Item name="intervalHours_WEBSOCKET" label="通知间隔">
                        <ShowInput />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name="pushChannel_SMS" label="报警触发后短信通知周期">
                <Radio.Group disabled>
                  <Radio value={IntervalType.ONCE}>{IntervalTypeValue[IntervalType.ONCE]}</Radio>
                  <Radio value={IntervalType.DAY}>{IntervalTypeValue[IntervalType.DAY]}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item noStyle dependencies={['pushChannel_SMS']}>
                {({ getFieldValue }) => {
                  return (
                    getFieldValue('pushChannel_SMS') !== IntervalType.ONCE && (
                      <Form.Item name="intervalHours_SMS" label="通知间隔">
                        <ShowInput />
                      </Form.Item>
                    )
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer">
          <Button className={styles.button} onClick={() => navigate(`/operation/balanceAlarm/management`)}>
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default BalanceAlarmDetail;
