import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Input, Key, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';

import { treeDataMock } from '../../mockData';
interface Props {
  onTreeSelect: (v: Key[], selectedNodes: DataNode[]) => void;
  selectedKeys: Key[];
  expandedKeys: Key[];
  setExpandedKeys: (v: Key[]) => void;
  autoExpandParent: boolean;
  setAutoExpandParent: (v: boolean) => void;
}

const dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

generateList(treeDataMock);

const flattenArray = (data: DataNode[], arr: { key: string; title: string }[] = []) => {
  data.forEach(i => {
    arr.push({ key: i.key as string, title: i.title as string });
    if (i.children) {
      flattenArray(i.children, arr);
    }
  });
  return arr;
};

// 根据节点的Id获取所有父节点
const getPathByNodeId = (data: DataNode[], key: React.Key, path: string[] = []): string[] => {
  for (const node of data) {
    path.push(node.key as string);
    if (node.key === key) return path;
    if (node.children) {
      const findChildren = getPathByNodeId(node.children, key, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

const StationTree: React.FC<Props> = ({
  onTreeSelect,
  selectedKeys,
  expandedKeys,
  setExpandedKeys,
  autoExpandParent,
  setAutoExpandParent,
}) => {
  const [searchParams, setSearchParams] = useState<any>();

  // 找parentKey
  useEffect(() => {
    if (treeDataMock && treeDataMock.length) {
      if (searchParams && searchParams.name) {
        const dataflatArr = flattenArray(treeDataMock);
        const newExpandedKeys = dataflatArr
          ?.map(item => {
            if (item.title.includes(searchParams.name)) {
              return getPathByNodeId(treeDataMock, item.key);
            }
            return null;
          })
          .filter(i => !!i);
        newExpandedKeys.forEach(i => i?.pop());
        setExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
      } else {
        setExpandedKeys(dataList.map(i => i.key));
      }
    }
  }, [searchParams, setExpandedKeys]);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (treeDataMock && treeDataMock.length && searchParams && searchParams.name) {
      const loop = (data: DataNode[]): DataNode[] =>
        data.map(item => {
          const strTitle = item.title as string;
          const searchName = searchParams ? searchParams.name : undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span style={{ color: 'red' }}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(treeDataMock);
    } else {
      return treeDataMock;
    }
  }, [searchParams]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.searchArea}>
        <Input.Search
          placeholder="请输入名称"
          allowClear
          onSearch={v => {
            setAutoExpandParent(true);
            setSearchParams({ name: v });
          }}
        />
      </div>
      <div className={styles.treeBox}>
        <Tree
          className={styles.treeStyle}
          blockNode
          treeData={treeData}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          onExpand={v => {
            setExpandedKeys(v);
            setAutoExpandParent(false);
          }}
          onSelect={(v, info) => {
            onTreeSelect(v, info.selectedNodes);
          }}
          autoExpandParent={autoExpandParent}
          showIcon
        />
      </div>
    </div>
  );
};

export default StationTree;
