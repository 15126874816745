import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form, Row, Col } from 'antd';
import { Select, Input, Modal } from '@maxtropy/components';
import {
  DataPropertyType,
  EdgeDeviceTemplatePoint,
  IotProtocolType,
  UploadTypeEnum,
  UploadTypeEnumDisplay,
} from '../../../types';
import { useQuery } from '../../../utils/utils';
import { getDataProperties, getPhysicalUnits } from '../../../api/options';
import { AttributeInfoProps } from '../index';

export interface PointFormDialogProps {
  info?: AttributeInfoProps;
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties: number[];
}

const PointFormDialog: React.FC<PointFormDialogProps> = ({ row, onCancel, onOk, info, usedProperties }) => {
  const [form] = Form.useForm();

  const [dataPropertyId, setDataPropertyId] = useState<number>();

  const { data: dataPropertiesAll } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const dataProperties = useMemo(
    () =>
      dataPropertiesAll?.filter(
        item =>
          !usedProperties.includes(item.id) &&
          !(info?.iotProtocol !== IotProtocolType.ESTUN && item.name === '设备状态')
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataPropertiesAll, usedProperties]
  );
  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );
  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const submit = async () => {
    const values = await form.validateFields();
    await onOk(values);
    onCancel();
  };

  return (
    <Modal
      bodyScroll={true}
      open={row !== undefined}
      onCancel={onCancel}
      title={`${row?.id ? '编辑' : '绑定'}数据点`}
      centered={true}
      maskClosable={false}
      okText="确认"
      cancelText="取消"
      onOk={submit}
    >
      <Form form={form} initialValues={row} layout="vertical">
        <Row gutter={[0, 32]}>
          <Form.Item noStyle name="dataPropertyName" />
          <Form.Item noStyle name="physicalUnitGeneralName" />
          <Col span={12}>
            <Form.Item name="dataPropertyId" label="数据属性" rules={[{ required: true, message: '请选择数据属性' }]}>
              <Select
                onChange={value => {
                  form.setFieldsValue({
                    dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                  });
                  setDataPropertyId(value as number);
                  form.setFieldsValue({
                    physicalUnitId: undefined,
                    physicalUnitGeneralName: '',
                  });
                }}
                placeholder="请选择"
                style={{ width: 294 }}
              >
                {dataProperties?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {dataPropertyType !== undefined && dataPropertyType !== null && (
            <>
              {dataPropertyType === DataPropertyType.YC && (
                <Col span={12}>
                  <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                        });
                      }}
                      placeholder="请选择"
                      style={{ width: 294 }}
                    >
                      {units?.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.generalName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item name="symbol" label="物联层数据点标识" rules={[{ required: true, max: 50 }]}>
                  <Input placeholder="请输入" style={{ width: 294 }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="uploadType" label="上传方式" rules={[{ required: true, message: '请选择上传方式' }]}>
                  <Select placeholder="请选择" style={{ width: 294 }}>
                    {Object.keys(UploadTypeEnumDisplay).map(key => (
                      <Select.Option key={key} value={+key}>
                        {UploadTypeEnumDisplay[+key as UploadTypeEnum]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default PointFormDialog;
