import { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Form, InputNumber, Modal, Select, message } from '@maxtropy/components';
import { apiV2OutputProductBomEditPost, V2OutputProductBomEditPostRequest } from '@maxtropy/device-customer-apis-v2';
import { accessTypeOptions } from './AddBom/SetQuantity';
import { EditBomData } from './BomTable';
import { useUnit } from './hook';
import styles from './index.module.scss';

type Props = {
  data: EditBomData;
  setVisible: (visible: boolean) => void;
  updateFn: () => void;
};

const EditBom = ({ data, setVisible, updateFn }: Props) => {
  const [form] = Form.useForm();
  const [getUnitName] = useUnit();

  useEffect(() => {
    form.setFieldsValue({ ...data, accessType: data.accessType ?? 1 });
  }, [data]);

  const onOk = () => {
    form.validateFields().then(() => {
      const formData = form.getFieldsValue(true);
      const params: V2OutputProductBomEditPostRequest = {
        id: formData.id,
        quantity: formData.quantity,
        accessType: formData.accessType,
      };

      apiV2OutputProductBomEditPost(params).then(res => {
        message.info('修改成功');
        setVisible(false);
        updateFn();
      });
    });
  };

  return (
    <Modal
      title="编辑"
      size="normal"
      contentClassName="modal-form-content"
      open={true}
      onCancel={() => setVisible(false)}
      onOk={onOk}
    >
      <Form form={form} layout="vertical" className={styles.detailForm}>
        <Row>
          <Col span={12}>
            <Form.Item label="产出物编码">{data.code ?? '--'}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="产出物名称">{data.name ?? '--'}</Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="规格型号">{data.spec ?? '--'}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="数量"
              name="quantity"
              rules={[
                { required: true, message: '请填写数量' },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber addonAfter={getUnitName(data.unitTypeCode!, data.unitCode!)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="获取方式" name="accessType">
              <Select options={accessTypeOptions} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditBom;
