import { getImportLogPageDates, ImportLogStateDates, ImportLogPageResDates } from '@/api/electricitySettlementRules';
import {
  Button,
  EllipsisSpan,
  getRealUrl,
  Paging,
  Table,
  useSearchParams,
  useUpdate,
  Modal,
} from '@maxtropy/components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { PageRequestDates } from '@/shared/const';

interface Props {
  batchEdgeBlogListOpen: boolean;
  setBatchEdgeBlogListOpen: (v: boolean) => void;
  refreshState: number;
}

const columns = [
  {
    title: '导入状态',
    width: 200,
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: ImportLogStateDates, record: ImportLogPageResDates) => {
      let value = null;
      if (record.status === ImportLogStateDates.IMPORTING) {
        value = '正在导入中';
      }
      if (
        record.status === ImportLogStateDates.ALL_SUCCESS ||
        record.status === ImportLogStateDates.PART_SUCCESS_FAILED ||
        record.status === ImportLogStateDates.ALL_FAILED
      ) {
        value = `成功：${record.successAmount}  失败：${record.failAmount}`;
      }
      if (record.status === ImportLogStateDates.EXCEL_ERROR) {
        value = '导入表格错误(缺少表头或空表格)';
      }
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '导入时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'createByUsername',
    ellipsis: { showTitle: true },
    render: (v: string, record: ImportLogPageResDates) => <EllipsisSpan value={record.updateByUsername} />,
  },
];

interface SearchParams extends PageRequestDates {}

const ImportLog: React.FC<Props> = ({ refreshState, batchEdgeBlogListOpen, setBatchEdgeBlogListOpen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = useSearchParams<SearchParams>(20);
  const { searchParams, setTotalCount } = pagingInfo;
  const [data, setData] = useState<ImportLogPageResDates[]>([]);
  const [update, updateFn] = useUpdate();

  useEffect(() => {
    setLoading(true);
    // 获取导入日志数据
    getImportLogPageDates({ page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, refreshState, update]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (v: string, record: ImportLogPageResDates) => {
        return (
          <Button
            type="link"
            onClick={() => {
              window.open(getRealUrl(record.failFileKey));
            }}
            disabled={
              record.status === ImportLogStateDates.IMPORTING ||
              record.status === ImportLogStateDates.ALL_SUCCESS ||
              record.status === ImportLogStateDates.EXCEL_ERROR
            }
          >
            下载失败数据
          </Button>
        );
      },
    },
  ];
  return (
    <>
      <Modal
        title="导入日志"
        onCancel={() => {
          setBatchEdgeBlogListOpen(false);
          updateFn();
        }}
        open={batchEdgeBlogListOpen}
        footer={null}
      >
        <Table
          rowKey="id"
          sticky
          loading={loading}
          scroll={{ y: 500 }}
          dataSource={data}
          columns={buildColumns}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
};

export default ImportLog;
