import { WorkOrderRes, WorkStationRes, getBindWorkStationList, getWorkStationListById } from '@/api/energyUnitMapping';
import { EllipsisSpan, Key, Table, Form, Modal, ShowInput } from '@maxtropy/components';
import { Card, Col, Divider, Row, Transfer } from 'antd';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import type { TransferItem, TransferProps } from 'antd/es/transfer';
import { difference } from 'lodash-es';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { compareArray } from '../BindOrderModal';

interface TransferDataItem extends WorkStationRes {
  key: string;
}
interface TableTransferProps extends TransferProps<TransferItem> {
  dataSource: TransferDataItem[];
  leftColumns: ColumnsType<TransferDataItem>;
  rightColumns: ColumnsType<TransferDataItem>;
}

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  contentType?: string;
  workOrderDetail?: WorkOrderRes;
  onBind?: (v: Key[]) => void;
  workOrderLinkId?: Key;
}

const tableColumns: ColumnsType<TransferDataItem> = [
  {
    dataIndex: 'code',
    title: '工站编码',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },

  {
    dataIndex: 'name',
    title: '工站名称',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const BindStationModal: React.FC<Props> = ({
  workOrderLinkId,
  workOrderDetail,
  open,
  setOpen,
  contentType,
  onBind,
}) => {
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [data, setData] = useState<TransferDataItem[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBindKeys, setHasBindKeys] = useState<string[]>([]);

  useEffect(() => {
    if (workOrderDetail) {
      form.setFieldsValue({
        code: workOrderDetail.code,
        name: workOrderDetail.name,
        workCenterCode: workOrderDetail.workCenterCode,
        workCenterName: workOrderDetail.workCenterName,
      });
    }
  }, [workOrderDetail, form]);

  useEffect(() => {
    if (workOrderDetail && workOrderDetail.id && workOrderLinkId && contentType === 'workOrder') {
      setLoading(true);
      getWorkStationListById(workOrderDetail.id)
        .then(res => {
          if (res.list) {
            setData(
              res.list.map(i => ({
                ...i,
                key: i.id as string,
              }))
            );
          }
          getBindWorkStationList(workOrderLinkId).then(bindRes => {
            if (bindRes.list) {
              setTargetKeys(bindRes.list?.map(i => i.id as string) || []);
              // 已绑定key
              setHasBindKeys(bindRes.list?.map(i => i.id as string) || []);
              // 将禁用的工站正常显示
              if (bindRes.list.length > 0) {
                const disabledIds = difference(
                  bindRes.list.map(i => i.id),
                  res.list?.map(i => i.id) || []
                );
                const disabled: TransferDataItem[] = [];

                bindRes.list.forEach(i => {
                  if (disabledIds.includes(i.id)) {
                    disabled.push({
                      ...i,
                      key: i.id as string,
                    });
                  }
                });

                setData([
                  ...(res.list?.map(i => ({
                    ...i,
                    key: i.id as string,
                  })) || []),
                  ...disabled,
                ]);
              }
            }
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [workOrderDetail, workOrderLinkId, contentType]);

  const onChange = (nextTargetKeys: string[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
    <Transfer {...restProps}>
      {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection: TableRowSelection<TransferItem> = {
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys as string[], selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key as string, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            loading={loading}
            rowKey="id"
            rowSelection={rowSelection}
            // @ts-ignore
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ maxHeight: 265, overflowY: 'auto' }}
          />
        );
      }}
    </Transfer>
  );
  return (
    <>
      <Modal
        title="绑定工站"
        size="big"
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          const isSame = compareArray(targetKeys, hasBindKeys);
          if (isSame) {
            setOpen(false);
            return;
          }
          onBind?.(targetKeys);
          setOpen(false);
        }}
        okText="保存"
      >
        <div>
          <Card className={styles.infoCard} size="small">
            <Form form={form} size="small" layout="vertical">
              <Row>
                <Col span={6}>
                  <Form.Item label="工序编码" name="code">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Divider className={styles.dividerStyle} type="vertical" />
                <Col span={5}>
                  <Form.Item label="工序名称" name="name">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Divider className={styles.dividerStyle} type="vertical" />
                <Col span={5}>
                  <Form.Item label="工作中心编码" name="workCenterCode">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Divider className={styles.dividerStyle} type="vertical" />
                <Col span={5}>
                  <Form.Item label="工作中心名称" name="workCenterName">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <TableTransfer
            titles={['工站合集', '已绑定工站']}
            dataSource={data}
            targetKeys={targetKeys}
            onChange={onChange}
            leftColumns={tableColumns}
            rightColumns={tableColumns}
          />
        </div>
      </Modal>
    </>
  );
};

export default BindStationModal;
