import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Paging,
  getRealUrl,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import { MaterialWarehousingReportingParams } from '@/api/materielManagement';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  apiV2CarbonFootprintCalculateDeletePost,
  apiV2CarbonFootprintCalculatePagePost,
  apiV2FileCenterGetCarbonUrlPost,
} from '@maxtropy/device-customer-apis-v2';
import { SystemBoundary, SystemBoundaryDisplay } from '../../api/materielManagement';
import type { ColumnsType } from 'antd/es/table';
import { isNil } from 'lodash-es';

interface pageType {
  id: number;
  dataOrigin: number;
  standardYear: number;
  boundary: number;
  boundaryName: string;
  madeIn: string;
  remark: string;
  name: string;
  spec: string;
  unitCode: string;
  code: string;
  productSheetCode: string;
  quantity: number;
  carbonFootPrint: number;
  filledFlag: number;
  productionStartTime: string;
  productSheetId: number;
  productionEndTime: string;
  createTime: string;
  wordKey?: string;
}

interface SearchParams extends Omit<MaterialWarehousingReportingParams, 'page' | 'size'> {}

const CarbonFootprintReporting: React.FC = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<pageType[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(20, {
    name: undefined,
  });
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDPCFFILLING); // 新建填报
  const hasEdit = useHasPermission(PermissionsType.B_EDITPCFFILLING); // 编辑填报
  const hasDelete = useHasPermission(PermissionsType.B_DELPCFFILLING); // 删除填报
  const hasReportDown = useHasPermission(PermissionsType.B_PRODUUCTCARBONFOOTPRINTREPORTDOWNLOAD); // 下载报告
  const hasReportView = useHasPermission(PermissionsType.B_PRODUUCTCARBONFOOTPRINTREPORTVIEW); // 查看报告

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    apiV2CarbonFootprintCalculatePagePost({
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
      name: searchParams.name,
    })
      .then((res: any) => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onDelete = (id: number) => {
    apiV2CarbonFootprintCalculateDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns: ColumnsType<pageType> = [
    {
      title: '生产单号',
      dataIndex: 'productSheetCode',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '产品名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '规格型号',
      dataIndex: 'spec',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '生产数量',
      dataIndex: 'quantity',
      ellipsis: { showTitle: true },
      render: (v, record) => <EllipsisSpan value={`${v} ${record.unitCode ?? '--'}`} />,
    },
    {
      title: '产品碳足迹',
      dataIndex: 'unitCarbonFootPrint',
      ellipsis: { showTitle: true },
      render: (v: number, record) => (
        <EllipsisSpan value={`${isNil(v) ? '--' : v}kgCO2e/${record?.unitCode ?? '--'}`} />
      ),
    },
    {
      title: '系统边界',
      dataIndex: 'boundary',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={SystemBoundaryDisplay[v as SystemBoundary]} />,
    },
    {
      title: '创建日期',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 340,
      fixed: 'right' as 'right',
      render: (value, record) => {
        return (
          <Space size={16}>
            {hasReportView && (
              <Button
                type="link"
                disabled={record.filledFlag === 0}
                onClick={() => {
                  if (isNil(record.wordKey)) {
                    Modal.error({ content: '请重新填报生成word！' });
                    return;
                  }
                  apiV2FileCenterGetCarbonUrlPost({ key: record.wordKey as string }).then(res => {
                    const newUrl = res.url;
                    window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newUrl as string}`);
                  });
                }}
              >
                查看报告
              </Button>
            )}
            {hasReportDown && (
              <Button
                type="link"
                disabled={record.filledFlag === 0}
                onClick={() => {
                  if (isNil(record.wordKey)) {
                    Modal.error({ content: '请重新填报生成word！' });
                    return;
                  }
                  window.open(getRealUrl(record?.wordKey), '_blank');
                }}
              >
                下载报告
              </Button>
            )}
            <Button
              type="link"
              disabled={record.filledFlag === 0}
              onClick={() => {
                navigate(`/productCarbonFootPrint/basic/reporting/detail?id=${record.id}`);
              }}
            >
              查看
            </Button>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/productCarbonFootPrint/basic/reporting/addFilling?id=${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm
                placement="top"
                title="删除后本生产单已经填报的数据将被删除，且不能找回，是否确认删除？"
                onConfirm={() => onDelete(record.id)}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      name: undefined,
    });
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="name" label="碳足迹">
        <Input placeholder="请输入生产单号/产品名称/规格型号" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <Space style={{ marginBottom: 10 }}>
        {hasAdd && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate('/productCarbonFootPrint/basic/reporting/addFilling');
            }}
          >
            新建填报
          </Button>
        )}
      </Space>
      <Table columns={buildColumns} dataSource={data} rowKey="id" loading={loading} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default CarbonFootprintReporting;
