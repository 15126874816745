import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select } from '@maxtropy/components';
import React, { FC, useContext } from 'react';
import styles from './index.module.scss';
import { CreateUETEMTResponse, EnergyWorkingProcessType } from '@/api/uet';
import { ShareDataContext, createdFormLayout } from '../..';
import { Card, Col, Row } from 'antd';
import DeviceListForm from '../DeviceListForm';

export interface IProcessNode {
  fieldName: number; // 某一个filedname
  eMTBasicInfo?: CreateUETEMTResponse;
  entryEnergyMediumList?: {
    id: number;
    name: string;
  }[]; // 输入能源工质列表
  countInAndOutProcessNumber?: (number: number, key: string) => void;
  processType?: EnergyWorkingProcessType; // 节点类型，判断节点内添加数量
}

const ProcessNode: FC<IProcessNode> = props => {
  const { fieldName, entryEnergyMediumList, eMTBasicInfo, countInAndOutProcessNumber, processType } = props;

  const { selectedOuIds, energyMediumIds } = useContext(ShareDataContext);
  return (
    <Form.List name={[fieldName, 'processNodeCreateRequests']}>
      {(fields, { add, remove }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {fields.map((field, index) => {
            return (
              <Card
                key={fieldName + field.key}
                // style={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'gray' }}
                className={styles.processNodeItemStyle}
              >
                <Row key={fieldName + field.key}>
                  <Col span={22}>
                    <Form.Item
                      label="能源介质"
                      {...createdFormLayout}
                      name={[field.name, 'energyMediumId']}
                      rules={[{ required: true, message: '请选择能源介质' }]}
                      initialValue={energyMediumIds?.[0] ?? undefined}
                    >
                      <Select
                        placeholder="请选择"
                        options={(entryEnergyMediumList ?? []).map(i => ({ label: i.name, value: i.id }))}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item
                    noStyle
                    dependencies={[
                      ['addProcess', fieldName, 'processNodeCreateRequests', field.name, 'energyMediumId'],
                    ]}
                  >
                    {({ getFieldValue }) => {
                      let energyMediumId = getFieldValue([
                        'addProcess',
                        fieldName,
                        'processNodeCreateRequests',
                        field.name,
                        'energyMediumId',
                      ]);

                      return (
                        <Col span={22}>
                          <Form.Item label="计量设备" {...createdFormLayout} name={[field.name, 'deviceVos']}>
                            <DeviceListForm
                              energyMediumId={energyMediumId}
                              eMTBasicInfo={eMTBasicInfo}
                              selectedOuIds={selectedOuIds}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>

                  <Col span={2} style={{ position: 'absolute', top: 0, right: 2 }}>
                    <Button
                      type="link"
                      onClick={() => {
                        remove(field.name);
                        countInAndOutProcessNumber?.(fields.length - 1, fieldName + 'inside');
                      }}
                      icon={<CloseOutlined />}
                    />
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Card className={styles.addport_node_itemStyle}>
            <Button
              className={styles.addProcessBtnStyle}
              // style={{ height: 220 }}
              type="link"
              onClick={() => {
                if (fields.length + 1 > 1) {
                  Modal.error({
                    content: `最多添加1个`,
                  });
                } else {
                  add();
                  countInAndOutProcessNumber?.(fields.length + 1, fieldName + 'inside');
                }
              }}
              block
              icon={<PlusOutlined />}
            >
              添加节点内
            </Button>
          </Card>
        </div>
      )}
    </Form.List>
  );
};

export default ProcessNode;
