import { Tabs, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { useMemo, useState } from 'react';
import WorkCenterInfo from './components/WorkCenterInfo';
import WorkProcessInfo from './components/WorkProcessInfo';
import WorkstationInfo from './components/WorkstationInfo';
import ProductionBase from './components/ProductionBase';
import styles from './index.module.scss';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';

const TabKeyEnum = {
  PRODUCTION_BASE: '1',
  WORK_CENTER: '2',
  WORK_PROCEDURE: '3',
  WORK_STATION: '4',
};

const ProductiveProcessManage: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const hasWORKCENTER = useHasPermission(PermissionsType.P_WORKCENTER_INFO);
  const hasPROCESS = useHasPermission(PermissionsType.P_PROCESS_INFO);
  const hasSTATION = useHasPermission(PermissionsType.P_STATIONINFORMATION);

  const show = useMemo(() => {
    return hasWORKCENTER !== undefined && hasPROCESS !== undefined && hasSTATION !== undefined;
  }, [hasWORKCENTER, hasPROCESS, hasSTATION]);

  const [tabKey, setTabKey] = useState(TabKeyEnum.PRODUCTION_BASE);

  const items = [
    {
      key: TabKeyEnum.PRODUCTION_BASE,
      label: '生产基地',
      hasPermission: 'TRUE',
    },
    {
      key: TabKeyEnum.WORK_CENTER,
      label: '工作中心',
      hasPermission: hasWORKCENTER,
    },
    {
      key: TabKeyEnum.WORK_PROCEDURE,
      label: '工序',
      hasPermission: hasPROCESS,
    },
    {
      key: TabKeyEnum.WORK_STATION,
      label: '工站',
      hasPermission: hasSTATION,
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      {show && (
        <>
          <div className={styles.tabStyles}>
            <Tabs items={items.filter(i => i.hasPermission)} selectedLinePlacement="top" onChange={setTabKey} />
          </div>
          {tabKey === TabKeyEnum.PRODUCTION_BASE && <ProductionBase />}
          {tabKey === TabKeyEnum.WORK_CENTER && <WorkCenterInfo />}
          {tabKey === TabKeyEnum.WORK_PROCEDURE && <WorkProcessInfo />}
          {tabKey === TabKeyEnum.WORK_STATION && <WorkstationInfo />}
        </>
      )}
    </Wrapper>
  );
};

export default ProductiveProcessManage;
