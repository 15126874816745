import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Popconfirm, Tooltip } from 'antd';
import { getRealUrl } from '@maxtropy/components';
import { Node } from '@antv/x6';
import workProcedureIcon from '../../icons/workProcedure@2x.png';
import { isNil } from 'lodash-es';

// 自定义节点
const NodeComponent = ({ node }: { node: Node }) => {
  const { label, showIcon, first, last, iconKey } = node.getData();
  const [base64String, setBase64String] = useState<string>('');

  useEffect(() => {
    // 创建一个 Image 元素
    const img = new Image();
    // 设置图片源
    img.src = isNil(iconKey) ? workProcedureIcon : (getRealUrl(iconKey) as string);
    // 图片加载完成后执行回调
    img.onload = function () {
      // 创建一个 Canvas 元素
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置 Canvas 尺寸为图片尺寸
      canvas.width = img.width;
      canvas.height = img.height;

      // 将图片绘制到 Canvas 上
      if (ctx) {
        ctx.drawImage(img, 0, 0);
      }
      // 将 Canvas 中的图像转换为 Base64 字符串
      const dataURL = canvas.toDataURL('image/png');
      setBase64String(dataURL); // 更新状态
    };
  }, [iconKey]);

  return (
    <div className={`${styles.customNode} ${first ? styles.firstNode : last ? styles.lastNode : styles.otherNode}`}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
        <img
          alt="Base64"
          style={{
            width: 20,
            height: 20,
            marginRight: 6,
          }}
          src={base64String} // 使用状态中的 Base64 字符串
        />
        <Tooltip title={label}>
          <p style={{ margin: 0, padding: 0 }} className={styles.node_text}>
            {label}
          </p>
        </Tooltip>
      </div>

      {showIcon && (
        <Popconfirm
          overlayClassName={styles.popconfirmSty}
          title={<p style={{ color: 'rgba(255,255,255,0.65)' }}>是否删除此工序？</p>}
          onConfirm={() => node.remove()}
          onCancel={() => node.updateData({ showPop: false })}
          okText="确定"
          cancelText="取消"
        >
          <div className={styles.closeIcon} onClick={() => node.updateData({ showPop: true })}></div>
        </Popconfirm>
      )}
    </div>
  );
};

export default NodeComponent;
