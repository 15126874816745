import React, { useEffect, useMemo, useState } from 'react';
import { Button, useBreadcrumbRoutes, Wrapper, Form, FormTitle, SubContent, Input, Select } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useRequest, useUpdateEffect } from 'ahooks';
import { isNil } from 'lodash-es';
import { FieldList, FieldType, FieldPropertyList } from '@/pages/FillingRules/CreateAndEdit/types';
import NumberFormItem from '@/pages/FillingRules/CreateAndEdit/NumberInput';
import { useNavigate, useParams } from 'react-router-dom';
import { ValueType } from '@/pages/FillingRules/CreateAndEdit/NumberInput/types';
import styles from './index.module.scss';
import {
  V2CarbonEmissionAutoFillingRuleDetailPostResponse,
  apiV2CarbonEmissionAutoFillingRuleAddPost,
  apiV2CarbonEmissionAutoFillingRuleDetailPost,
  apiV2CarbonEmissionAutoFillingRuleUpdatePost,
  apiV2CarbonAccountingUnitProcessListPost,
  V2CarbonAccountingUnitProcessListPostResponse,
  apiV2CarbonEmissionProcessDetailPost,
  apiV2CarbonEmissionProcessGetFieldValueListPost,
  V2CarbonEmissionProcessGetFieldValueListPostRequest,
  apiV2CarbonAccountingUnitListPost,
} from '@maxtropy/device-customer-apis-v2';

function findNextId(arr: FieldList[] = [], targetId: number | undefined) {
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i].id === targetId) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[j].fieldType === FieldType.DataAssociation) {
          return arr[j].id;
        }
      }
      return null;
    }
  }
  return null;
}

interface Props {
  edit?: boolean;
}
const FillingRulesCreateAndEdit: React.FC<Props> = ({ edit }) => {
  const [form] = Form.useForm();

  const { id } = useParams<{ id: string }>();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  const { data: accountingUnit } = useRequest(async () => {
    const res = await apiV2CarbonAccountingUnitListPost({});
    return res.list;
  });
  const currentSelectAccountingUnitId = Form.useWatch('accountingUnitId', form);
  const currentSelectProcessId = Form.useWatch('emissionProcessId', form);
  const [emissionProcess, setEmissionProcess] = useState<V2CarbonAccountingUnitProcessListPostResponse['list']>();
  const [fieldList, setFieldList] = useState<FieldList[]>();
  const [detailData, setDetailData] = useState<V2CarbonEmissionAutoFillingRuleDetailPostResponse>();

  // 核算单元
  const accountingUnitOptions = useMemo(() => {
    if (accountingUnit?.length !== 0) {
      return accountingUnit?.map(item => {
        return {
          label: item.unitName,
          value: item.id,
        };
      });
    }
  }, [accountingUnit]);

  // 排放过程
  const processOptions = useMemo(() => {
    if (emissionProcess?.length !== 0) {
      return emissionProcess?.map(item => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    }
  }, [emissionProcess]);

  const onFinish = () => {
    form.validateFields().then(values => {
      const { name, accountingUnitId, emissionProcessId, ...restObj } = values;

      const fields = [];
      for (let key in restObj) {
        if (Object.prototype.hasOwnProperty.call(restObj, key)) {
          const value = restObj[key];
          let transformedValue = {};

          switch (value.valueType) {
            case ValueType.fixed:
              transformedValue = {
                formFieldId: +key,
                formFieldValue: +value.formFieldValue,
                valueType: value.valueType,
              };
              break;
            case ValueType.auto:
              transformedValue = {
                formFieldId: +key,
                valueType: +value.valueType,
                autoDataSetting: value.autoDataSetting,
              };
              break;
            default:
              transformedValue = {
                formFieldId: +key,
                formFieldValue: value,
              };
              break;
          }
          fields.push(transformedValue);
        }
      }

      const params = {
        ...(id ? { id: +id } : {}),
        name,
        accountingUnitId,
        emissionProcessId,
        fields,
      };
      const apiMethod = edit ? apiV2CarbonEmissionAutoFillingRuleUpdatePost : apiV2CarbonEmissionAutoFillingRuleAddPost;

      apiMethod(params as any).then(() => {
        navigate('/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement');
      });
    });
  };

  useUpdateEffect(() => {
    setEmissionProcess([]);
    setFieldList([]);
    form.setFieldsValue({
      emissionProcessId: undefined,
    });
    if (!isNil(currentSelectAccountingUnitId)) {
      apiV2CarbonAccountingUnitProcessListPost({ id: currentSelectAccountingUnitId }).then(res =>
        setEmissionProcess(res.list)
      );
    }
  }, [currentSelectAccountingUnitId]);

  useUpdateEffect(() => {
    if (!isNil(currentSelectProcessId) && !id) {
      apiV2CarbonEmissionProcessDetailPost({ id: currentSelectProcessId }).then(res => {
        const { fields } = res;
        const filterFields: FieldList[] | undefined = fields
          ?.filter(fields => (fields.fieldType as FieldType) !== FieldType.Output)
          .sort((a, b) => a?.fieldOrder! - b?.fieldOrder!);
        setFieldList(filterFields);

        const firstId = filterFields?.find(field => field.fieldType === FieldType.DataAssociation)?.id;
        if (!isNil(firstId)) {
          apiV2CarbonEmissionProcessGetFieldValueListPost({
            targetFieldId: firstId,
            fields: [],
          }).then(res => {
            const options = res?.list?.map(item => ({
              label: item,
              value: item,
            }));
            const updateFieldList = filterFields?.map(field => {
              if (field.id === firstId && field.fieldType === FieldType.DataAssociation) {
                field.options = options;
              }
              return field;
            });
            setFieldList(updateFieldList);
          });
        }
      });
    }
  }, [currentSelectProcessId]);

  const fetchScheme = async (
    id: number | undefined,
    scheme: V2CarbonEmissionAutoFillingRuleDetailPostResponse['fields']
  ) => {
    if (!id) return;
    apiV2CarbonEmissionProcessDetailPost({ id: id.toString() }).then(async res => {
      const { fields } = res;
      const filterFields: FieldList[] = (fields || [])
        ?.filter(fields => (fields.fieldType as FieldType) !== FieldType.Output)
        .sort((a, b) => a?.fieldOrder! - b?.fieldOrder!);

      const tasks: Promise<any>[] = [];
      const fieldsParams: V2CarbonEmissionProcessGetFieldValueListPostRequest['fields'] = [];

      filterFields?.forEach(field => {
        if (field.fieldType === FieldType.DataAssociation) {
          tasks.push(
            apiV2CarbonEmissionProcessGetFieldValueListPost({ targetFieldId: field.id, fields: fieldsParams }).then(
              res => res.list ?? []
            )
          );
          fieldsParams.push({
            formFieldName: field.fieldName,
            formFieldValue: scheme?.find(item => item.formFieldId === field.id)?.formFieldValue,
          });
        } else {
          tasks.push(Promise.resolve([]));
        }
      });
      const result = await Promise.all(tasks);
      result.forEach((resp, index) => {
        const options = resp?.map((item: any) => ({
          label: item,
          value: item,
        }));
        filterFields[index].options = options;
      });
      setFieldList([...filterFields]);
    });
  };

  const onSelectChange = (currentId: number | undefined, index: number) => {
    const before = fieldList?.slice(0, index + 1) ?? [];
    const after = fieldList?.slice(index + 1) ?? [];
    // 把 after 中的options清空
    after?.forEach(item => {
      if (item.fieldType === FieldType.DataAssociation) {
        form.setFieldsValue({
          [item.id as number]: undefined,
        });
        item.options = [];
      }
    });
    const newFieldList = [...before, ...after];
    setFieldList(newFieldList);

    // 根据before里面的id去找value
    const beforeSelectedOptions = before
      ?.map(item => {
        if (item.fieldType === FieldType.DataAssociation) {
          return item.id;
        }
        return null;
      })
      .filter(Boolean)
      .map(item => ({
        formFieldName: fieldList?.find(field => field.id === item)?.fieldName,
        formFieldValue: form.getFieldValue(item as number),
      }));

    // 设置下一个的options
    const nextId = findNextId(fieldList, currentId);
    if (!isNil(nextId)) {
      apiV2CarbonEmissionProcessGetFieldValueListPost({
        targetFieldId: nextId,
        fields: beforeSelectedOptions,
      }).then(res => {
        const options = res?.list?.map(item => ({
          label: item,
          value: item,
        }));
        const updateFieldList = fieldList?.map(field => {
          if (field.id === nextId && field.fieldType === FieldType.DataAssociation) {
            field.options = options;
          }
          return field;
        });
        setFieldList(updateFieldList);
      });
    }
  };

  useRequest(
    () => {
      if (!id) throw new Error('id is required');
      return apiV2CarbonEmissionAutoFillingRuleDetailPost({ id: +id });
    },
    {
      refreshDeps: [id],
      onSuccess: res => {
        const { emissionProcessId, fields } = res;
        const fieldsObj: FieldPropertyList = {};
        setDetailData(res);

        fetchScheme(emissionProcessId, fields);

        fields?.forEach(field => {
          // 下拉框
          if (!field.valueType) {
            fieldsObj[field.formFieldId!] = field.formFieldValue;
            return;
          }
          // 固定值 和 自动填报
          fieldsObj[field.formFieldId!] = {
            valueType: field.valueType,
            formFieldValue: field.formFieldValue,
            autoDataSetting: field.autoDataSetting,
          };
        });
        form.setFieldsValue(fieldsObj);
      },
    }
  );

  useEffect(() => {
    if (!detailData) return;
    const { name, accountingUnitId, emissionProcessId } = detailData;

    form.setFieldsValue({
      name,
      accountingUnitId: accountingUnitOptions?.length ? accountingUnitId : undefined,
      emissionProcessId: processOptions?.length ? emissionProcessId : undefined,
    });
  }, [accountingUnitOptions?.length, detailData, form, processOptions?.length]);

  const routes = useMemo(() => {
    return [{ name: `${edit ? '编辑' : '新建'}规则` }];
  }, [edit]);

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title={`${edit ? '编辑' : '新建'}规则`} />

        <SubContent className="mb-8">
          <Form form={form} onFinish={onFinish} layout="horizontal">
            <Row>
              <Col span={24}>
                <Form.Item label="规则名称" name="name" rules={[{ required: true, min: 2, max: 50 }]}>
                  <Input placeholder="请输入" allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="核算单元" name="accountingUnitId" rules={[{ required: true }]}>
                  <Select disabled={edit} placeholder="请选择" options={accountingUnitOptions} allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="排放过程" name="emissionProcessId" rules={[{ required: true }]}>
                  <Select disabled={edit} placeholder="请选择" options={processOptions} allowClear />
                </Form.Item>
              </Col>
              {fieldList?.map((field, index) => {
                return (
                  <Col span={24}>
                    <Form.Item
                      key={field.id}
                      name={field.id}
                      label={field.fieldName}
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            switch (value?.valueType) {
                              case ValueType.fixed:
                                if (!value?.formFieldValue) {
                                  return Promise.reject('请输入数值');
                                }
                                break;
                              case ValueType.auto:
                                if (!value?.autoDataSetting) {
                                  return Promise.reject('请编辑数采设置');
                                }
                                break;
                              default:
                                if (!value) {
                                  return Promise.reject(`请选择${field.fieldName}`);
                                }
                                break;
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      {field.fieldType === FieldType.DataAssociation ? (
                        <>
                          <Select onChange={_value => onSelectChange(field.id, index)} options={field.options} />
                        </>
                      ) : (
                        <>
                          <NumberFormItem name={field.fieldName} />
                        </>
                      )}
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </SubContent>

        <div className="sticky-footer">
          <Space size={8}>
            <Button type="primary" onClick={() => form.submit()}>
              保存
            </Button>
            <Button onClick={() => navigate('/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement')}>
              取消
            </Button>
          </Space>
        </div>
      </Wrapper>
    </>
  );
};

export default FillingRulesCreateAndEdit;
