import { Button, EllipsisSpan, Form, InputNumber, Modal, Table, message } from '@maxtropy/components';
import { Col, Space, Spin } from 'antd';
import React, { FC, useEffect } from 'react';
import { MachineType } from '../utils/const';
import { useRequest } from 'ahooks';
import {
  V2StationSignboardBaselineConfigGetPostResponse,
  apiV2StationSignboardBaselineConfigGetPost,
  apiV2StationSignboardBaselineConfigSavePost,
} from '@maxtropy/device-customer-apis-v2';
import { DatePickerType } from './type';

interface IBenchmarkConfigModal {
  onCancel: () => void;
  stationId?: number;
  currentType?: MachineType;
}

export type V2StationSignboardBaselineConfigGetPostResponseItem = Exclude<
  V2StationSignboardBaselineConfigGetPostResponse['list'],
  undefined
>[number];

const BenchmarkConfigModal: FC<IBenchmarkConfigModal> = props => {
  const { onCancel, stationId, currentType } = props;
  const [form] = Form.useForm();

  // 获取基准配置
  const { data: baselineConfigData, loading } = useRequest(
    () => {
      return apiV2StationSignboardBaselineConfigGetPost({
        stationType: Number(currentType),
        stationId: Number(stationId),
      });
    },
    {
      ready: !!stationId && !!currentType,
      refreshDeps: [stationId, currentType],
    }
  );

  useEffect(() => {
    if (baselineConfigData) {
      const baselineConfigList = baselineConfigData?.list ?? [];

      form.setFieldsValue({
        list: baselineConfigList.map(item => ({
          indicatorId: item.indicatorId,
          indicatorName: item.indicatorName,
          physicalUnitName: item.physicalUnitName,
          hourValue: item.granularityValues?.find(c => c.granularityType === DatePickerType.HOUR)?.value,
          dayValue: item.granularityValues?.find(c => c.granularityType === DatePickerType.DAY)?.value,
          monthValue: item.granularityValues?.find(c => c.granularityType === DatePickerType.MONTH)?.value,
        })),
      });
    }
  }, [baselineConfigData]);

  const columns = [
    {
      title: '指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      children: [
        {
          title: '',
          render: (_: undefined, record: V2StationSignboardBaselineConfigGetPostResponseItem) => (
            <EllipsisSpan value={`${record?.indicatorName ?? '--'}(${record?.physicalUnitName ?? '--'})`} />
          ),
        },
      ],
    },
    {
      title: '颗粒度',
      children: [
        {
          title: '小时',
          render(_: undefined, record: V2StationSignboardBaselineConfigGetPostResponseItem, index: number) {
            return (
              <Form.Item name={['list', index, 'hourValue']} style={{ marginBottom: 0 }}>
                <InputNumber style={{ width: 220 }} />
              </Form.Item>
            );
          },
        },
        {
          title: '按日',
          render(_: undefined, record: V2StationSignboardBaselineConfigGetPostResponseItem, index: number) {
            return (
              <Form.Item name={['list', index, 'dayValue']} style={{ marginBottom: 0 }}>
                <InputNumber style={{ width: 220 }} />
              </Form.Item>
            );
          },
        },
        {
          title: '按月',
          render(_: undefined, record: V2StationSignboardBaselineConfigGetPostResponseItem, index: number) {
            return (
              <Form.Item name={['list', index, 'monthValue']} style={{ marginBottom: 0 }}>
                <InputNumber style={{ width: 220 }} />
              </Form.Item>
            );
          },
        },
      ],
    },
  ];

  const onFinish = (values: any) => {
    const params = {
      stationType: Number(currentType),
      stationId: Number(stationId),
      indicators: (values?.list ?? []).map((item: any) => ({
        indicatorId: item.indicatorId,
        granularityTypes: [
          {
            granularityType: DatePickerType.HOUR,
            value: item?.hourValue,
          },
          {
            granularityType: DatePickerType.DAY,
            value: item?.dayValue,
          },
          {
            granularityType: DatePickerType.MONTH,
            value: item?.monthValue,
          },
        ],
      })),
    };
    apiV2StationSignboardBaselineConfigSavePost(params).then(_ => {
      message.success('保存成功！');
      onCancel();
    });
  };

  return (
    <Modal
      title={'基准配置'}
      open
      size="big"
      maskClosable={false}
      onCancel={onCancel}
      footer={
        <Space size={8}>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            确定
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Col span={24}>
            <Form.Item name="list" valuePropName="dataSource">
              <Table bordered rowKey="indicatorId" columns={columns} />
            </Form.Item>
          </Col>
        </Form>
      </Spin>
    </Modal>
  );
};

export default BenchmarkConfigModal;
