import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Table,
  TreeSelect,
  usePaging,
} from '@maxtropy/components';
import { ModalProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { OuStaffType, OuStaffTypeDisplay, PageResponse } from '../../const';
import { useQuery } from '../../utils/utils';

import { CommonUserInfo, OrganizationResponse, getOrganizationWithCodeWithCurrent } from './interface';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

interface ModalTemplateProps extends Omit<ModalProps, 'onOk'> {
  type: OuStaffType;
  dataSource: (...args: any[]) => Promise<PageResponse<CommonUserInfo>>;
  selectedStaffCodes: string[];
  disabledStaffCodes?: string[];
  onOk: (value: CommonUserInfo[]) => void;
}

interface SearchParams {
  name?: string;
  staffCode?: string;
  phone?: string;
  username?: string;
  humanCode?: string;
}

const columns: ColumnType<CommonUserInfo>[] = [
  {
    title: '用户ID',
    dataIndex: 'humanCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '用户登录账号',
    dataIndex: 'username',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const ModalTemplate: React.FC<ModalTemplateProps> = props => {
  const { type, dataSource, onOk, ...rest } = props;
  const title = `${OuStaffTypeDisplay[type]}选择`;
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<CommonUserInfo[]>([]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        dataSource({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount, dataSource]
    )
  );

  useEffect(() => {
    if (props.visible) {
      setSelectedRowKeys(props.selectedStaffCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  const afterClose = () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    form.resetFields();
    onReset();
  };

  const onFinish = (val: any) => {
    const params = {
      name: val.name ? val.name : undefined,
      staffCode: val.staffCode ? val.staffCode : undefined,
      phone: val.phone ? val.phone : undefined,
      username: val.username ? val.username : undefined,
      humanCode: val.humanCode ? val.humanCode : undefined,
      mcid: val.mcid ? val.mcid : undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    const params: SearchParams = {
      name: undefined,
      staffCode: undefined,
      phone: undefined,
      username: undefined,
      humanCode: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const handleOk = () => {
    const data = selectedRows
      .filter(Boolean)
      .filter(item => !(props.selectedStaffCodes ?? []).includes(item.staffCode))
      .map(item => ({
        ...item,
        staffType: type,
        id: item.id,
      }));
    props.onOk?.(data);
  };

  const rowSelection = {
    type: 'checkbox' as const,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: CommonUserInfo) => ({
      disabled: (props.disabledStaffCodes
        ? [...props.disabledStaffCodes, ...props.selectedStaffCodes]
        : props.selectedStaffCodes
      ).includes(record.staffCode),
    }),
    preserveSelectedRowKeys: true,
  };
  const [organization, setOrganition] = useState<OrganizationResponse>();

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  return (
    <Modal title={title} size="big" onOk={handleOk} afterClose={afterClose} {...rest}>
      <CustomFilter colSpan={8} form={form} onFinish={onFinish} onReset={onReset}>
        <Form.Item name="name" label="姓名">
          <Input placeholder={'请输入姓名查询'} />
        </Form.Item>

        <Form.Item name="humanCode" label="用户ID">
          <Input placeholder={'请输入用户ID查询'} />
        </Form.Item>

        <Form.Item name="staffCode" label="员工工号">
          <Input placeholder={'请输入'} />
        </Form.Item>

        <Form.Item name="phone" label="手机号">
          <Input placeholder={'请输入'} />
        </Form.Item>

        <Form.Item name="username" label="用户登录账号">
          <Input placeholder={'请输入'} />
        </Form.Item>

        <Form.Item name="mcid" label="所在组织">
          <TreeSelect
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeDefaultExpandAll
            treeData={treeData}
            placeholder="请选择"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </CustomFilter>

      <Table
        showSelectedCount
        selectedCount={selectedRowKeys.length}
        rowSelection={rowSelection}
        dataSource={data}
        scroll={{ y: 300 }}
        loading={isLoading}
        rowKey="staffCode"
        columns={columns}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};

export default ModalTemplate;
