import React, { useState, useEffect } from 'react';
import {
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
  Button,
  Table,
  EllipsisSpan,
  usePaging,
  Paging,
  Key,
  Select,
  Input,
  CustomFilter,
} from '@maxtropy/components';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Space, Layout } from 'antd';
import StationTree from './components/StationTree';

import {
  getUnits,
  OutputRecord,
  OutputState,
  OutputType,
  OutputTypeDisplay,
  OutputUnitType,
  UnitTypeWithUnitList,
  updateStateById,
} from '@/api/outputConfig';
import styles from './index.module.scss';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useNavigate } from 'react-router';
import { apiV2OutputProductPagePost, apiV2WorkCenterListByOuPost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import qs from 'qs';
import DragResize from '@/components/DragResize';
import { DataSourceType, DataSourceTypeDisplay } from './utils';

type SearchParams = Pick<OutputRecord, 'name' | 'type' | 'code' | 'nameOrCode'>;

// 产出物设定
const OutputConfig: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [form] = Form.useForm<SearchParams>();
  const [updateState, forceUpdate] = useUpdate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [outputData, setOutputData] = useState<OutputRecord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // 所有单位
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  // 查看产出物权限
  const hasOutputDetailPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTDETAIL);
  // 编辑产出物权限
  const hasOutputEditPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTEDIT);
  // 删除产出物权限
  const hasOutputDeletePermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTDELETE);
  // 新增产出物权限
  const hasOutputCreatePermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTCREATE);
  // BOM权限
  const hasBOMPermission = useHasPermission(PermissionsType.B_MAINTENANCEPRODUCTBOM);
  const navigate = useNavigate();

  const [categoryId, setCategoryId] = useState<Key>(0);
  const [productValue, setProductValue] = useState<number>(0);
  const [workCenterList, setWorkCenterList] = useState<any[]>([]);

  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态

  useEffect(() => {
    // 获取工作中心 有权限的
    apiV2WorkCenterListByOuPost({}).then(res => {
      const mapList = (res.list ?? []).map(item => ({
        label: `${item.code}-${item.name}`,
        value: item.id,
      }));
      setWorkCenterList([...mapList]);
    });
  }, []);

  const columns = [
    {
      title: '产出物名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '产出物编码',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工作中心',
      dataIndex: 'workCenterNames',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={isNil(v) ? '--' : v?.map((item: string) => item).join(';')} />,
    },
    {
      title: '型号',
      dataIndex: 'spec',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '单位',
      dataIndex: 'unitCode',
      ellipsis: { showTitle: true },
      render: (value: string, record: OutputRecord) => {
        const unitName = units
          ?.find(u => u?.unitTypeCode === record?.unitTypeCode)
          ?.outputProductUnitList?.filter(v => v?.unitCode === value)?.[0]?.unitName;
        return (
          <EllipsisSpan value={record?.unitTypeCode === OutputUnitType.PIECE ? unitName : `${value} (${unitName})`} />
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: OutputType) => <EllipsisSpan value={OutputTypeDisplay[v]} />,
    },
    {
      title: '数据来源',
      dataIndex: 'dataSource',
      ellipsis: { showTitle: true },
      render: (v: OutputType) => <EllipsisSpan value={DataSourceTypeDisplay[v]} />,
    },
    {
      title: '分类',
      dataIndex: 'categoryNames',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v.length === 0 ? '--' : v?.map((item: string) => item).join('/')} />,
    },
    {
      title: '操作',
      width: 250,
      render: (_: any, record: OutputRecord) => {
        return (
          <Space size={16}>
            {hasOutputDetailPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/energy/output/config/detail/${record.id}`);
                }}
              >
                查看
              </Button>
            )}
            {hasOutputEditPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/energy/output/config/update/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}
            {hasOutputDeletePermission &&
              (record.state === OutputState.ABLE ? (
                <Button
                  type="link"
                  onClick={() => {
                    if (record.id) {
                      updateStateById(record.id, { state: OutputState.DISABLE }).then(res => {
                        if (res) {
                          forceUpdate();
                        }
                      });
                    }
                  }}
                >
                  禁用
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    if (record.id) {
                      updateStateById(record.id, { state: OutputState.ABLE }).then(res => {
                        if (res) {
                          forceUpdate();
                        }
                      });
                    }
                  }}
                >
                  启用
                </Button>
              ))}
            {hasBOMPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/energy/output/config/bom/${record.id}`);
                }}
              >
                BOM
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    setSearchParams({
      ...v,
      name: v.name === '' ? undefined : v.name,
      code: v.code === '' ? undefined : v.code,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  // 获取所有单位
  useEffect(() => {
    getUnits().then(res => {
      setUnits(res.list);
    });
  }, []);

  // 获取产出物列表
  useEffect(() => {
    setIsLoading(true);

    apiV2OutputProductPagePost({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
      categoryId: Number(categoryId),
    })
      .then((res: any) => {
        if (!isNil(res)) {
          setOutputData(res?.list);
          setTotalCount(res?.total);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState, categoryId]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree setCategoryId={setCategoryId} setProductValue={setProductValue} />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={styles.filterTop}>
            <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
              <Form.Item name="name" label="产出物">
                <Input placeholder="请输入产出物名称" />
              </Form.Item>
              <Form.Item name="type" label="类型">
                <Select
                  allowClear
                  placeholder="全部"
                  style={{ width: '100%' }}
                  options={Object.entries(OutputTypeDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
                />
              </Form.Item>
              <Form.Item name="code" label="产出物编码">
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item name="workCenterIds" label="工作中心编码/名称">
                <Select
                  placeholder="请选择"
                  options={workCenterList}
                  allowClear
                  showSearch
                  mode="multiple"
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item name="dataSource" label="数据来源">
                <Select placeholder="请选择">
                  <Select.Option value={DataSourceType.MANUAL}>
                    {DataSourceTypeDisplay[DataSourceType.MANUAL]}
                  </Select.Option>
                  <Select.Option value={DataSourceType.DOCKING}>
                    {DataSourceTypeDisplay[DataSourceType.DOCKING]}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="spec" label="型号">
                <Input placeholder="请输入" />
              </Form.Item>
            </CustomFilter>
          </div>
          <div className={styles.operationArea}>
            <Space style={{ marginBottom: 10 }}>
              {hasOutputCreatePermission && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    navigate(
                      `/energy/output/config/add?${qs.stringify({
                        productValue: productValue,
                      })}`
                    );
                  }}
                >
                  新增产出物
                </Button>
              )}
            </Space>
            <Table rowKey="id" columns={columns} dataSource={outputData} loading={isLoading} />
            <Paging pagingInfo={pagingInfo} />
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default OutputConfig;
