import { FC } from 'react';

interface EstimateBalanceProps {
  value?: string;
  clientId?: number;
}

const EstimateBalance: FC<EstimateBalanceProps> = props => {
  const { value } = props;

  return (
    <div
      style={{
        overflowWrap: 'break-word',
      }}
    >
      {
        Number(value) ? value + '元' : '--'
        // clientId && (
        //     <span>
        //       该用户无法计算度电均价，点击前往
        //       <Button type="link">
        //         <Link to={`/operation/client-related/client/edit/${clientId}`}>客户详情</Link>
        //       </Button>
        //       手动配置
        //     </span>
        //   )
      }
    </div>
  );
};

export default EstimateBalance;
