import { EllipsisSpan, Paging, UsePagingProps } from '@maxtropy/components';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import styles from './index.module.scss';
import { V2MediumIndicatorDashboardDeviceAlarmPagePostResponse } from '@maxtropy/device-customer-apis-v2';

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const columns = [
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    render: (v: string, record: any) => (
      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => window.open(`/device/alarm/record/detail/${record.id}`, '_blank')}
      >
        {v}
      </Button>
    ),
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警时间',
    dataIndex: 'alarmTime',
    render: (v: number) => <EllipsisSpan value={v ? dayjs(v).format(dateFormat) : '--'} />,
  },
];

export interface INoRestoredAlarm {
  pageList?: V2MediumIndicatorDashboardDeviceAlarmPagePostResponse['list'];
  alarmLoading?: boolean;
  pagingInfo: UsePagingProps;
}

export type TableRow = Exclude<V2MediumIndicatorDashboardDeviceAlarmPagePostResponse['list'], undefined>[number];

const NoRestoredAlarm: FC<INoRestoredAlarm> = props => {
  const { pageList, alarmLoading, pagingInfo } = props;

  return (
    <div>
      <Table
        loading={alarmLoading}
        size={'small'}
        rowKey={'id'}
        bordered={false}
        dataSource={pageList ?? []}
        columns={columns}
        pagination={false}
        className={styles.relatedPartyMaint}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
};

export default NoRestoredAlarm;
