import {
  Button,
  EllipsisSpan,
  FormTitle,
  SubContent,
  Switch,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  Select,
} from '@maxtropy/components';
import {
  apiV2HvacDashboardDetailSetStationConfigPost,
  apiV2HvacDashboardDetailSetNodeConfigPost,
  apiV2HvacDashboardDetailGetStationConfigListPost,
  V2HvacDashboardDetailGetStationConfigListPostResponse,
  apiV2HvacDashboardDetailGetNodeConfigListPost,
  V2HvacDashboardDetailGetNodeConfigListPostResponse,
  apiV2HvacDashboardDetailGetNodeIndicatorInfoPost,
  V2HvacDashboardDetailGetNodeIndicatorInfoPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Position, PositionDisplay } from '../utils/const';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';

export type IndexItem = Exclude<V2HvacDashboardDetailGetStationConfigListPostResponse['list'], undefined>[number];
export type NodeConfigItem = Exclude<V2HvacDashboardDetailGetNodeConfigListPostResponse['list'], undefined>[number];
export type NodeIndicatorInfoItem = Exclude<
  V2HvacDashboardDetailGetNodeIndicatorInfoPostResponse['list'],
  undefined
>[number];

interface IndicatorInfoOption {
  label: string;
  value: number;
  disabled: boolean;
}

const routes = [{ name: '看板配置' }];

const DashBoardConfig: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [allIndexList, setAllIndexList] = useState<IndexItem[]>([]);
  const [nodeConfigList, setNodeConfigList] = useState<NodeConfigItem[]>([]);
  const [nodeIndicatorInfoList, setNodeIndicatorInfoList] = useState<NodeIndicatorInfoItem[]>([]);

  useEffect(() => {
    if (isNil(id)) return;
    setLoading(true);
    apiV2HvacDashboardDetailGetStationConfigListPost({ id: Number(id) })
      .then(res => {
        setAllIndexList(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (isNil(id)) return;
    setLoading(true);
    apiV2HvacDashboardDetailGetNodeConfigListPost({ id: Number(id) })
      .then(res => {
        setNodeConfigList(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    apiV2HvacDashboardDetailGetNodeIndicatorInfoPost({ stationId: id })
      .then(res => {
        setNodeIndicatorInfoList(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const columns = [
    {
      title: '监测指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '是否展示',
      dataIndex: 'display',
      ellipsis: { showTitle: true },
      render: (v: boolean, record: any, index: number) => {
        return (
          <Switch
            disabled={!record.editAble}
            checked={v}
            onChange={(checked: boolean) => {
              setAllIndexList(
                allIndexList.map((k, i) => {
                  return index === i ? { ...k, display: checked } : k;
                })
              );
            }}
          />
        );
      },
    },
  ];

  const deviceColumns = [
    {
      title: '节点类型',
      dataIndex: 'nodeTypeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (_: NodeConfigItem, index?: number) => {
        if (index === 0 || index! % 2 == 0) {
          return { rowSpan: 2 };
        } else {
          return { rowSpan: 0 };
        }
      },
    },
    {
      title: '展示位置',
      dataIndex: 'position',
      ellipsis: { showTitle: true },
      render: (v: Position) => <EllipsisSpan value={PositionDisplay[v]} />,
    },
    {
      title: '展示指标',
      dataIndex: 'indicatorId',
      ellipsis: { showTitle: true },
      render: (v: number, record: NodeConfigItem, index: number) => {
        const selectedList = nodeConfigList.filter((_: any, i: number) => i !== index);

        const defaultOptions =
          nodeIndicatorInfoList
            ?.find(k => k.nodeType === record.nodeType)
            ?.indicatorItemList?.filter(m => !selectedList.some(k => k.indicatorId === m.indicatorId))
            ?.map(k => ({
              label: k.indicatorName,
              value: k.indicatorId,
              disabled: !k.editAble,
            })) || [];

        return (
          <Select
            placeholder="请选择"
            style={{ width: '100%' }}
            defaultValue={v}
            allowClear
            disabled={!record.editAble}
            onChange={(v, option) => {
              setNodeConfigList(
                nodeConfigList.map((k, i) => {
                  return index === i
                    ? {
                        ...k,
                        indicatorName: (option as unknown as IndicatorInfoOption)?.label,
                        indicatorId: (option as unknown as IndicatorInfoOption)?.value,
                      }
                    : k;
                })
              );
            }}
            options={defaultOptions}
          />
        );
      },
    },
  ];

  const saveConfig = () => {
    setLoading(true);
    Promise.all([
      apiV2HvacDashboardDetailSetStationConfigPost({
        stationId: Number(id),
        configList: allIndexList,
      }),
      apiV2HvacDashboardDetailSetNodeConfigPost({
        stationId: Number(id),
        configList: nodeConfigList,
      }),
    ]).then(() => {
      console.log('then');
      navigate(`/production/manage/hvacBoard?stationId=${id}`);
    });

    setLoading(false);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="监控指标配置" />
      <Spin spinning={loading}>
        <SubContent title="站房监控指标设置">
          <Table rowKey="indicatorId" style={{ width: 480 }} columns={columns} dataSource={allIndexList}></Table>
        </SubContent>
        <SubContent title="节点监控指标设置">
          <Table
            rowKey="id"
            style={{ width: 600 }}
            columns={deviceColumns}
            bordered={true}
            dataSource={nodeConfigList}
          ></Table>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button onClick={saveConfig} type="primary">
            保存
          </Button>
          <Button
            onClick={() => {
              navigate(`/production/manage/hvacBoard?stationId=${id}`);
            }}
          >
            取消
          </Button>
        </Space>
      </Spin>
    </Wrapper>
  );
};

export default DashBoardConfig;
