import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Space } from 'antd';
import {
  Form,
  Input,
  Select,
  Modal,
  Table,
  Wrapper,
  EllipsisSpan,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  CustomFilter,
} from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import styles from './index.module.scss';
import {
  getAlrmPushPage,
  AlrmPushPage,
  AlarmPushPageRequest,
  changeAlrmStatusOrDelete,
} from '../../../api/deviceAlarmPushStrategy';
import { AlarmPushStatus, AlarmPushStatusDisplay, Operator, OperatorDisplay } from '@/shared/types';
import { useQuery } from '../../../utils/utils';
import qs from 'qs';
import { getOuList } from '../../../api/alarm';

type SearchParams = Omit<AlarmPushPageRequest, 'page' | 'size'>;

// const routes = [
//   {name: 'Iot配置'},
//   {name: '报警推送'},
//   {name: '设备推送管理'},
// ];

const columns = [
  {
    title: '推送编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '推送名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (value: AlarmPushStatus) => {
      return <EllipsisSpan value={AlarmPushStatusDisplay[value]} />;
    },
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string, record: AlrmPushPage) => <EllipsisSpan value={v} />,
  },
  {
    title: '推送人员数',
    dataIndex: 'pushUserNum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '负责设备数',
    dataIndex: 'linkDeviceNum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'lastUpdateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'lastUpdateUserName',
    ellipsis: { showTitle: true },
    render: (value: string, record: AlrmPushPage) => {
      return (
        <EllipsisSpan
          value={record.lastUpdateSource === Operator.OPS ? `${OperatorDisplay[record.lastUpdateSource]}操作` : value}
        />
      );
    },
  },
];

interface FilterParams {
  name: string | undefined;
  status?: AlarmPushStatus;
  ouId?: number;
}

function statusOptions() {
  const status: Array<{ label: string; value: AlarmPushStatus }> = [
    {
      label: AlarmPushStatusDisplay[AlarmPushStatus.DISABLE],
      value: AlarmPushStatus.DISABLE,
    },
    {
      label: AlarmPushStatusDisplay[AlarmPushStatus.ENABLE],
      value: AlarmPushStatus.ENABLE,
    },
  ];
  return status.map(i => ({ label: i.label, value: i.value }));
}

const AlarmPushList: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateState, update] = useUpdate();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const name = urlSearchParams.get('name') || undefined;
  const ouId = urlSearchParams.get('ouId') || undefined;
  const status = urlSearchParams.get('status') || undefined;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    name,
    ouId,
    status: status !== undefined ? Number(status) : undefined,
  });
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    form.setFieldsValue({ name, ouId, status: status !== undefined ? Number(status) : undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeStatus = useCallback(
    (pushId: number, status?: AlarmPushStatus) => {
      if (status !== undefined) {
        const isEnable = status === AlarmPushStatus.ENABLE;
        if (isEnable) {
          changeAlrmStatusOrDelete(pushId, status).then(res => {
            if (res) {
              update();
            }
          });
        } else {
          const title = status === AlarmPushStatus.DISABLE ? '确定禁用？' : '确认删除？';
          const constent =
            status === AlarmPushStatus.DISABLE ? '禁用后推送不再生效，你还要继续吗？' : '删除后不可恢复，你还要继续吗?';
          Modal.confirm({
            title: title,
            content: constent,
            okText: '继续',
            onOk: () => {
              changeAlrmStatusOrDelete(pushId, status).then(res => {
                if (res) {
                  update();
                }
              });
            },
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getAlrmPushPage({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  const ou = useAsync(getOuList, []);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: AlrmPushPage) => {
        const displayStatus =
          record.status === AlarmPushStatus.ENABLE ? '禁用' : record.status === AlarmPushStatus.DISABLE ? '启用' : '';
        const changeStatus =
          record.status === AlarmPushStatus.ENABLE
            ? AlarmPushStatus.DISABLE
            : record.status === AlarmPushStatus.DISABLE
            ? AlarmPushStatus.ENABLE
            : undefined;
        return (
          <Space size={16}>
            <Button
              disabled={!record.ouAdmin}
              type="link"
              onClick={() => {
                onChangeStatus(record.id, AlarmPushStatus.DELETE);
              }}
            >
              删除
            </Button>
            <Button
              disabled={!record.ouAdmin}
              type="link"
              onClick={() => {
                onChangeStatus(record.id, changeStatus);
              }}
            >
              {displayStatus}
            </Button>
            <Button disabled={!record.ouAdmin} type="link">
              <Link to={`/device/alarm/push/edit/${record.id}`}>编辑</Link>
            </Button>
            <Button type="link">
              <Link to={`/device/alarm/push/detail/${record.id}`}>查看</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFinish = (val: FilterParams) => {
    const params: SearchParams = {
      name: val.name,
      ouId: val.ouId?.toString(),
      status: val.status,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      name: val.name,
      rootMcid: val.ouId?.toString(),
      status: val.status?.toString(),
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      name: undefined,
      status: undefined,
      ouId: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item name="name" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="status" label="状态">
        <Select style={{ width: '100%' }} placeholder="请选择" options={statusOptions()} />
      </Form.Item>
      <Form.Item name="ouId" label="所属运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrap}>
      <div className={styles.operationArea}>
        <Space>
          <Button type="primary">
            <Link to="/device/alarm/push/create">
              <PlusOutlined />
              新建推送
            </Link>
          </Button>
        </Space>
      </div>
      <Table sticky scroll={{ x: 1900 }} loading={isLoading} dataSource={data} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default AlarmPushList;
