import React, { useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  useCurrent,
  Empty,
  Form,
  Select,
  Input,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  getAnalysisReportPage,
  getAnalysisReportTypeList,
  PageSearchParams,
  ReportsUploadListRes,
  ReportTypeDetail,
} from '../../api/reportDownloadList';
import ReportCard from './components/ReportCard';
import styles from './index.module.scss';

// 查询参数类型
type SearchParams = Omit<PageSearchParams, 'page' | 'size'>;

const ReportDownLoadList: React.FC = () => {
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [reportsList, setReportsList] = useState<ReportsUploadListRes[]>([]);
  const current = useCurrent();
  const [reportTypeList, setReportTypeList] = useState<ReportTypeDetail[]>([]);

  useEffect(() => {
    if (current && current.tenant && current.tenant.mcid) {
      getAnalysisReportTypeList({ tenantMcid: current.tenant.mcid }).then(setReportTypeList);
    }
  }, [current]);

  const reportTypeOptions = useMemo(() => {
    return reportTypeList.map(i => ({ label: i.name, value: i.id }));
  }, [reportTypeList]);

  // 列表查询
  useEffect(() => {
    setLoading(true);
    getAnalysisReportPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setReportsList(res.list);
        }
      })
      .finally(() => setLoading(false));
  }, [form, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const filters = (
    <Form form={form} isOrigin>
      <Row gutter={20}>
        <Col span={6}>
          <Form.Item name="typeIds" label="报告类型">
            <Select
              dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
              style={{ width: '100%' }}
              placeholder="请选择"
              options={reportTypeOptions}
              optionFilterProp="label"
              showSearch
              showArrow
              mode="multiple"
              maxTagCount={2}
              maxTagTextLength={5}
              onDeselect={() => {
                onFinish(form.getFieldsValue());
                updateFn();
              }}
              onClear={() => {
                onFinish(form.getFieldsValue());
                updateFn();
              }}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  onFinish(form.getFieldsValue());
                  updateFn();
                }
              }}
              onBlur={() => {
                onFinish(form.getFieldsValue());
                updateFn();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="报告名称">
            <Input
              allowClear
              placeholder="请输入"
              suffix={<SearchOutlined />}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  onFinish(form.getFieldsValue());
                  updateFn();
                }
              }}
              onBlur={() => {
                onFinish(form.getFieldsValue());
                updateFn();
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
        <FormTitle
          title={breadcrumbRoutes && breadcrumbRoutes.routes ? breadcrumbRoutes.routes.at(2)?.name : '报告中心'}
        ></FormTitle>

        <SubContent>
          {filters}
          {reportsList && reportsList.length > 0 ? (
            <>
              <Spin spinning={loading}>
                <Row gutter={[40, 40]}>
                  {reportsList.map(item => (
                    <Col key={item.id} span={6}>
                      <ReportCard info={item} />
                    </Col>
                  ))}
                </Row>
              </Spin>

              <Paging pagingInfo={pagingInfo} />
            </>
          ) : (
            <Empty />
          )}
        </SubContent>
      </Wrapper>
    </>
  );
};

export default ReportDownLoadList;
