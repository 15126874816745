import {
  EnergyCompareGetMediumIndicatorProcessStatisticsPostResponse,
  MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse,
} from '@maxtropy/device-customer-apis';
import {
  apiV2EnergyCompareGetConversionEfficiencyStaticsPost,
  V2EnergyCompareGetConversionEfficiencyStaticsPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Divider, RadioChangeEvent } from 'antd';
import { Radio } from '@maxtropy/components';
import { Dayjs } from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Chart from '../Chart';
import { getEnergyMediumChartOption } from '../Chart/chartOptions';

export interface IEchartsGroup {
  item?: EnergyCompareGetMediumIndicatorProcessStatisticsPostResponse[number];
  processId?: React.Key;
  rangeDate?: [Dayjs, Dayjs];
  mediumIndicatorDisplayUnitData?: MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse;
  outPutIndicatorId?: number;
}

const EchartsGroup: FC<IEchartsGroup> = props => {
  const { item, processId, rangeDate, mediumIndicatorDisplayUnitData, outPutIndicatorId } = props;
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [energyMediumValue, setEnergyMediumValue] = useState<number>();
  const [chartData, setChartData] = useState<V2EnergyCompareGetConversionEfficiencyStaticsPostResponse['list']>();
  const [selectedLabel, setSelectedLabel] = useState<string>();
  const [selectedIndicatorId, setSelectedIndicatorId] = useState<number>();
  const [selectedAverageValue, setSelectedAverageValue] = useState<number>();

  useEffect(() => {
    if (item && item.inputVos && item.inputVos.length > 0) {
      setEnergyMediumValue(item.inputVos[0].energyMediumId);
      setSelectedLabel(item.inputVos[0].energyMediumName);
      setSelectedIndicatorId(item.inputVos[0].indicatorId);
      setSelectedAverageValue(item.inputVos[0].value);
    }
  }, [item]);

  useEffect(() => {
    if (item && processId && rangeDate && energyMediumValue) {
      setChartLoading(true);
      apiV2EnergyCompareGetConversionEfficiencyStaticsPost({
        processId: Number(processId),
        inputMediumId: energyMediumValue,
        outputMediumId: item.outputVo?.energyMediumId,
        timeResolution: 'D',
        from: rangeDate[0].startOf('day').valueOf(),
        to: rangeDate[1].endOf('day').valueOf(),
      }).then(res => {
        setChartData(res.list || []);
        setChartLoading(false);
      });
    }
  }, [item, processId, rangeDate, energyMediumValue]);

  const onEnergyMediumValueChange = (e: RadioChangeEvent) => {
    setEnergyMediumValue(e.target.value);
    setSelectedLabel(options.find(i => i.value === e.target.value)?.label);
    setSelectedIndicatorId(item?.inputVos?.find(i => i.energyMediumId === e.target.value)?.indicatorId);
    setSelectedAverageValue(item?.inputVos?.find(i => i.energyMediumId === e.target.value)?.value);
  };

  const option = useMemo(
    () =>
      getEnergyMediumChartOption(
        selectedLabel,
        selectedIndicatorId,
        selectedAverageValue,
        outPutIndicatorId,
        chartData,
        mediumIndicatorDisplayUnitData
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  const options = useMemo(() => {
    if (item) {
      return (item.inputVos ?? []).map(i => ({ label: i.energyMediumName!, value: i.energyMediumId! }));
    } else {
      return [];
    }
  }, [item]);

  return (
    <>
      <div>
        <Radio.Group
          options={options}
          onChange={e => {
            onEnergyMediumValueChange(e);
          }}
          value={energyMediumValue}
          optionType="button"
          buttonStyle="solid"
        />
        <div style={{ marginTop: 20 }}>
          <Chart height={450} loading={chartLoading} chartData={chartData} option={option} />
        </div>
      </div>
      <Divider style={{ marginTop: 24 }} />
    </>
  );
};

export default EchartsGroup;
