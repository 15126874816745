import {
  CircuitDeviceLinkType,
  CircuitType,
  DeviceProps,
  deviceTypesProps,
  getDeviceTypeTreeWithoutScene,
} from '@/api/circuit';
import { Device, getCircuitDeviceList } from '@/api/device';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  message,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
} from '@maxtropy/components';
import { FormInstance } from 'antd';

import React, { ReactText, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styles from './index.module.scss';

interface DeviceModalProps {
  selectedDeviceType?: CircuitDeviceLinkType;
  disabledItems?: DeviceProps[];
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  circuitType?: CircuitType;
  circuitform?: FormInstance<any>;
}

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '已绑定回路',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v && v.length > 0 ? v.join('、') : '--'} />,
  },
  {
    title: '是否三方',
    dataIndex: 'thirdParty',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v === true ? '是' : v === false ? '否' : '--'} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface FilterProps {
  codeOrName?: string;
  tag?: string;
  typeId?: number[];
}

// const electricDeviceId = 5000; // 电表
// const transformer = 5011; // 变压器
export interface DeviceModalRef {
  // selectedRowKeys: Array<number> | undefined;
  selectedRows: Array<any>;
}

const DeviceModal = React.forwardRef((props: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const { selectedDeviceType, disabledItems, visible, onOk, onCancel, circuitform } = props;
  const [form] = Form.useForm();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [deviceTypeList, setDeviceTypeList] = useState<deviceTypesProps[]>([]);
  const [data, setData] = useState<Device[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [selectedRows, setSelectedRows] = useState<Device[]>([]);
  const ouId: number = Form.useWatch('ouId', circuitform);

  useEffect(() => {
    if (selectedDeviceType) {
      setSelectedRows([]);
    }
  }, [selectedDeviceType]);

  const distincDisabledItems = useMemo(() => {
    return disabledItems?.filter(i => i.deviceType === selectedDeviceType);
  }, [disabledItems, selectedDeviceType]);

  // 判断是什么类型的设备
  const typeName = useMemo(() => {
    return selectedDeviceType === CircuitDeviceLinkType.GAUGE || selectedDeviceType === CircuitDeviceLinkType.LOAD
      ? '电表'
      : selectedDeviceType === CircuitDeviceLinkType.TRANSFORMER
      ? '变压器'
      : '';
  }, [selectedDeviceType]);

  const typeId = useMemo(() => {
    return deviceTypeList.map(i => i.id!);
  }, [deviceTypeList]);

  useEffect(() => {
    // 二级类目
    if (selectedDeviceType) {
      getDeviceTypeTreeWithoutScene().then(res => {
        let dataTypeList = formatTreeData(res.deviceTypes, typeName);
        setDeviceTypeList(dataTypeList);
        setSearchParams({ typeId: dataTypeList.map(i => i.id!) });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceType]);

  // 当切换选择设备类型的时候，清空查询的表单
  useEffect(() => {
    if (selectedDeviceType) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceType]);

  useEffect(() => {
    if (JSON.stringify(searchParams) !== '{}' && searchParams) {
      setIsLoading(true);
      if (selectedDeviceType && selectedDeviceType === CircuitDeviceLinkType.LOAD) {
        getCircuitDeviceList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          setData(res.list);
          setIsLoading(false);
        });
      } else {
        if (ouId) {
          getCircuitDeviceList({
            ...searchParams,
            ouId,
            page: pageOffset,
            size: pageSize,
          }).then(res => {
            if (res) setTotalCount(res.total);
            setData(res.list);
            setIsLoading(false);
          });
        } else {
          message.error('请先选择所属运营单元');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams, setTotalCount, ouId]);

  const onReset = () => {
    const params = {
      codeOrName: undefined,
      tag: undefined,
      typeId: typeId,
      thirdParty: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onFinish = (v: any) => {
    setSearchParams({ ...v, typeId: v.typeId ? [v.typeId] : searchParams?.typeId });
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter colSpan={8} onFinish={v => onFinish(v as FilterProps)} form={form} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="一级类目">
        <span>
          {selectedDeviceType === CircuitDeviceLinkType.GAUGE || selectedDeviceType === CircuitDeviceLinkType.LOAD
            ? '电表'
            : selectedDeviceType === CircuitDeviceLinkType.TRANSFORMER
            ? '变压器'
            : '--'}
        </span>
      </Form.Item>
      <Form.Item label="二级类目" name="typeId">
        <Select placeholder="请选择">
          {deviceTypeList?.map(i => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="设备标签" name="tag">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="是否三方" name="thirdParty">
        <Select>
          <Select.Option value={true}>是</Select.Option>
          <Select.Option value={false}>否</Select.Option>
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  useImperativeHandle(ref, () => ({ selectedRows }));

  const onSelectChange = (rowKeys: ReactText[], rows: any[]) => {
    const data = rows.filter(item => !!item).filter(i => !distincDisabledItems?.map(s => s.deviceId).includes(i.id));
    setSelectedRows(data);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled:
        (selectedDeviceType === CircuitDeviceLinkType.LOAD ||
          selectedDeviceType === CircuitDeviceLinkType.TRANSFORMER) &&
        distincDisabledItems?.length
          ? true
          : (distincDisabledItems ?? []).map(i => i.deviceId).includes(record.id),
    }),
    selectedRowKeys: (selectedRows ?? [])
      .map(i => i.id)
      .concat((distincDisabledItems ?? []).map(item => item.deviceId!)),
  };

  const length = useMemo(() => {
    return [...new Set(distincDisabledItems?.map(i => i.deviceId).concat((selectedRows ?? []).map(i => i.id)))].length;
  }, [distincDisabledItems, selectedRows]);

  return (
    <Modal
      open={visible}
      title="设备选择"
      size="big"
      destroyOnClose
      onOk={() => {
        setPageOffset(1);
        onOk?.();
      }}
      onCancel={() => {
        setPageOffset(1);
        onCancel?.();
        setSelectedRows([]);
      }}
    >
      {filters}
      <div className={styles.table_text}>
        <span>
          <InfoCircleOutlined style={{ margin: '0 4px 0 0', color: 'var(--warning-color)' }} />
          <span style={{ color: 'rgba(255,255,255,0.45)' }}>如需更改请解绑后重新选择</span>
        </span>
        <span>
          <span style={{ color: 'rgba(255,255,255,0.45)' }}>点击前往</span>
          <span style={{ padding: '0 4px' }}></span>
          <a rel="noreferrer" href={`${(window as any).IOTPLATFORMORIGIN}/device/manage/device/create`} target="_blank">
            添加设备
          </a>
        </span>
      </div>
      <Table
        loading={isLoading}
        sticky
        selectedCount={length}
        showSelectedCount
        rowSelection={{
          type:
            selectedDeviceType === CircuitDeviceLinkType.LOAD ||
            selectedDeviceType === CircuitDeviceLinkType.TRANSFORMER
              ? 'radio'
              : 'checkbox',
          hideSelectAll: true,
          ...rowSelection,
        }}
        dataSource={data}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={columns}
        pagination={false}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
});

export default DeviceModal;

export const formatTreeData = (tree: deviceTypesProps[], typeName: string) => {
  let res: deviceTypesProps[] = [];
  tree.forEach(i => {
    if (i.name === typeName) {
      res = res.concat(i.children ?? []);
    }
  });
  return res;
};
