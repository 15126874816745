import ReactEcharts from 'echarts-for-react';
// import pieBg from '../../assets/chart-bg@2x.png';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';

interface Props {
  pieData: PieCostItem[];
  isFull?: boolean;
  openSider?: boolean;
}
export interface PieCostItem {
  fromTime?: number;
  endTime?: number;
  energyConsumptionStandardCoal?: number;
  energyMediumId?: number;
  energyMediumName?: string;
  energyConsumption?: number;
  physicalUnitId?: number;
  physicalUnitGeneralName?: string;
  workCenterId?: number;
  energyConsumptionPreviousPeriod?: number;
  energyConsumptionMomRatio?: number;
}

const colors = [
  '#52E7FF',
  '#FF477B',
  '#FFCB47',
  '#6F47FF',
  '#16DD8E',
  '#2D8DFF',
  '#ABD335',
  '#234ED9',
  '#FF9247',
  '#854ECA',
];
const PieEcharts: React.FC<Props> = ({ pieData, isFull, openSider }) => {
  const allCostData = pieData?.map(i => i.energyConsumptionStandardCoal);
  const allCost = allCostData.reduce((a, b) => ((a as number) + (b as number)) as number, 0);
  const isOverTenThousand = Number(allCost) >= 1000 || Number(allCost) <= -1000;
  const costUnit = isOverTenThousand ? 'tce' : 'kgce';

  const getChartOption = () => {
    return {
      tooltip: {
        show: true,
        trigger: 'item',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
        formatter: (e: any) => {
          return `<div style='' >
          <div style='width:${12}px;height:${12}px;background:${
            e.data.color
          };border-radius:50%;'><div style='display:'inline-block';padding-bottom:10px'>&nbsp;&nbsp;&nbsp;&nbsp; ${
            e.data.name
          }</div></div>
          </div>
          <div style='margin-left:${18}px;display:flex;justify-content:space-between;margin-top:5px'><span>${'用能量'}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>${e.data.energyConsumption.toFixed(
            2
          )}${e.data.physicalUnitGeneralName}</span></div>
          <div style='margin-left:${18}px'>${'占比'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${
            e.percent + '%'
          }</div>
          </div>
          `;
        },
      },
      legend: {
        left: 190,
        top: 'center',
        orient: 'vertical',

        textStyle: {
          fontSize: 14,
          lineHeight: 20,
          rich: {
            name: {
              width: 80,
              color: 'rgba(255,255,255,0.65)',
              fontSize: 12,
            },
            v: {
              width: 30,
              color: '#FFFFFF',
              fontSize: 12,
            },
            b: {
              // align: 'right',
              width: 100,
              padding: [0, 20, 0, 0],
              color: '#FFFFFF',
              fontSize: 12,
            },
          },
        },
        formatter: (name: string) => {
          let truncatedName = name.length > 6 ? name.slice(0, 6) + '...' : name;
          console.log('name', name);
          console.log('pieData', pieData);
          const item = pieData?.find(i => i.energyMediumName === name.split('(')[0]);
          console.log('item', item);
          return `{name|${truncatedName}}{v|${'环比'}}{b|${
            !isNil(item?.energyConsumptionMomRatio) ? Number(item?.energyConsumptionMomRatio).toFixed(2) : '--'
          }${!isNil(item?.energyConsumptionMomRatio) ? '%' : ''}}`;
        },
        tooltip: {
          show: true,
        },
        data: pieData?.map(i => {
          return {
            name: `${i.energyMediumName}(${i.physicalUnitGeneralName})`,
            icon: 'circle',
          };
        }),
      },
      title: {
        text: `{all|${
          pieData.length > 0
            ? isOverTenThousand
              ? (Number(allCost) / 1000).toFixed(2)
              : (isNil(allCost) ? 0 : allCost).toFixed(2)
            : '--'
        }}`,
        subtext: `{allSub|${pieData.length > 0 ? `综合能耗(${costUnit})` : '暂无数据'}}`,
        left: 40,
        top: '38%',
        textStyle: {
          rich: {
            all: {
              align: 'center',
              width: 100,
              fontSize: 18,
              fontWeight: 500,
              color: 'rgba(255,255,255,0.85)',
              lineHeight: 25,
            },
          },
        },
        subtextStyle: {
          rich: {
            allSub: {
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(255,255,255,0.65)',
              lineHeight: 20,
              align: 'center',
              width: 110,
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          radius: [60, 76],
          center: [98, '50%'],
          // avoidLabelOverlap: false, // 是否启用防止标签重叠策略，默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
          labelLine: {
            // 标签的视觉引导线配置。在 label 位置 设置为'outside'的时候会显示视觉引导线。
            show: false,
          },
          label: {
            show: false,
            // position: 'center',
          },
          itemStyle: {
            // borderRadius: 2,
            borderColor: '#232324',
            borderWidth: 2,
          },
          startAngle: 90,
          data: pieData?.map((i, index) => ({
            name: `${i.energyMediumName}(${i.physicalUnitGeneralName})`,
            value: i.energyConsumptionStandardCoal,
            rate: i.energyConsumptionMomRatio,
            energyConsumption: i.energyConsumption,
            physicalUnitGeneralName: i.physicalUnitGeneralName,
            color: colors[index % colors.length],
            // itemStyle: {
            // color: {
            //   type: 'linear',
            //   x: 0,
            //   y: 0,
            //   x2: 0,
            //   y2: 1, // 设置渐变的起始和结束点坐标（范围为 0 到 1）
            //   colorStops: [
            //     { offset: 0, color: colorsStart[index % colorsStart.length] }, // 渐变起始颜色
            //     { offset: 1, color: colorsEnd[index % colorsEnd.length] }, // 渐变结束颜色
            //   ],
            //   global: false, // 缺省为 false
            // },
            // color: colors[index % colors.length],
            // },
          })),
          minAngle: 2,
          emphasis: {
            label: {
              show: false,
              fontSize: 10,
              fontWeight: 'bold',
            },
          },
          emptyCircleStyle: {
            color: '#3F3F3F',
          },
        },
      ],
    };
  };
  return (
    <div style={{ height: '100%' }} className={styles.echart_box}>
      <div className={styles.title}>能源介质占比及环比数据</div>

      <div className={styles.echarts}>
        <div className={styles.pieBg}></div>
        <ReactEcharts
          style={{ height: '100%', width: '100%', visibility: openSider ? 'visible' : 'hidden' }}
          option={getChartOption()}
          notMerge
          lazyUpdate={false}
        />
      </div>
    </div>
  );
};

export default PieEcharts;
