import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

export enum ObjectType {
  ENERGY_GROUP = 1, //用能分析组
  PRODUCTIVE_PROCESS = 2, //生产过程
}

export const ObjectTypeDisplay = {
  [ObjectType.ENERGY_GROUP]: '用能分析组',
  [ObjectType.PRODUCTIVE_PROCESS]: '生产过程',
};

export enum PlanType {
  DAY = 1, //日计划
  MONTH = 2, //月计划
  QUARTER = 3, //季度计划
  YEAR = 4, //年计划
}

export enum PlanRange {
  ENERGY_UNIT = 1, //用能单元
  DEVICE = 2, //设备
}

// 新增用能计划-用能分析组
export interface AddEnergyPlanBody {
  planObjectId: number; // 计划对象id
  energyUnitIds:
    | number[]
    | Array<{
        label: string;
        value: number;
      }>; // 用能单元id
  energyYear: string; // 用能年度
  planObjectType: ObjectType; // 计划对象类型(1分析组 2过程)
  planName: string; // 计划名称
}

export function addEnergyPlan(body: AddEnergyPlanBody) {
  return fetch(
    '/api/v2/energy-plan/add-group',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 修改用能计划-用能分析组
export interface UpdateEnergyPlanBody extends AddEnergyPlanBody {
  id: number; // 计划id
}

export function updateEnergyPlan(body: UpdateEnergyPlanBody) {
  return fetch(
    '/api/v2/energy-plan/edit-group',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 删除用能计划
export function deleteEnergyPlan(id: number) {
  return fetch(
    '/api/v2/energy-plan/delete',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取用能计划详情

export interface EnergyPlanDetailResponse {
  id: number;
  planObjectId: number; // 计划对象id
  planObjectName: string; // 计划对象名称
  energyYear: string; // 用能年度
  tenantMcid: string; // 租户id
  createTime: string;
  updateTime: string;
  updateByUsername: string; // 最后操作人
  planObjectType: ObjectType; // 计划对象类型(1分析组 2过程)
  planTypeList: PlanType[]; // 计划类型
  planName: string; // 计划名称
  energyUnitIds: number[]; // 用能单元id
}

export function getEnergyPlanDetail(id: number) {
  return fetch<EnergyPlanDetailResponse>(
    '/api/v2/energy-plan/info',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取用能计划列表
export interface EnergyPlanListParams extends PageRequest {
  planName?: string; // 计划名称
  planObjectType?: ObjectType; // 计划对象类型(1分析组 2过程)
  energyYear?: string; // 用能年度
}

export function getEnergyPlanList(params: EnergyPlanListParams) {
  return fetch<PageResponse<EnergyPlanDetailResponse>>(
    '/api/v2/energy-plan/page',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 新增用能计划详情
export interface AddEnergyPlanDetailBody {
  energyPlanId: number; //用能计划id
  planType: PlanType; //计划类型(1天 2月 3季度 4年)
  planDate?: string; //计划日期
  planRange: PlanRange; //计划范围(1用能单元 2设备)
}

export function addEnergyPlanDetail(body: AddEnergyPlanDetailBody) {
  return fetch(
    '/api/v2/energy-plan-detail/add',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 删除用能计划详情
export function deleteEnergyPlanDetail(id: number) {
  return fetch(
    '/api/v2/energy-plan-detail/delete',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取用能计划详情列表
export interface EnergyPlanDetailListParams {
  energyPlanId: number; // 用能计划id
  planType?: PlanType; // 计划类型(1天 2月 3季度 4年)
  planRange?: PlanRange; // 计划范围(1用能单元 2设备)
}

export interface EnergyPlanDetailListItem {
  id: number;
  energyPlanId: number; // 用能计划id
  planType: PlanType; // 计划类型(1天 2月 3季度 4年)
  planDate: string; // 计划日期
  createTime: string;
  updateTime: string;
  tenantMcid: string; // 租户id
  planRange: PlanRange; // 计划范围(1用能单元 2设备)
  distributeType?: PlanType; // 分配计划类型(1天 2月 3季度 4年)
  distributeRatio?: number; // 分配系数
  originalPlanName?: string; // 来源计划
}

export interface EnergyPlanDetailRes {
  list: EnergyPlanDetailListItem[];
}

export function getEnergyPlanDetailList(body: EnergyPlanDetailListParams) {
  return fetch<EnergyPlanDetailRes>(
    '/api/v2/energy-plan-detail/list',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 数据总览
export interface ObjectData {
  fillingDate: string; // 填报日期
  fillingData?: number; // 填报数据
}
export interface EnergyPlanDetailObjectsItem {
  energyUnitName: string; // 用能单元名称
  energyUnitId: number; // 用能单元id
  deviceName: string; // 设备名称
  deviceCode: string; // 设备编号
  deviceId: number; // 用能单元id
  objectData: ObjectData[]; // 对象数据
}

export interface EnergyPlanDetailMediumObjectsItem {
  id: number;
  energyMediumId: number; // 能源介质id
  energyMediumName: string; // 能源介质名称
  energyPlanDetailObjects: EnergyPlanDetailObjectsItem[]; // 用能计划详情对象列表
}

export interface EnergyPlanOverviewRes {
  id: number; //用能计划详情id
  distributeType: PlanType; // 分配计划类型(1天 2月 3季度 4年)
  distributeRatio: number; // 分配系数
  originalPlanDetailId: number; // 来源计划详情id
  originalPlanDetailName: string; // 来源计划详情名称
  mediumObjects: EnergyPlanDetailMediumObjectsItem[];
}

export function getEnergyPlanOverview(id: number) {
  return fetch<EnergyPlanOverviewRes>(
    '/api/v2/energy-plan-detail/dataSummary',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 数据保存

export interface EnergyPlanDetailObjectsSaveItem {
  energyUnitId: number; // 用能单元id
  deviceId: number; // 用能单元id
  energyMediumId: number; // 能源介质id
  physicalUnitId: number; // 物理单位id
  basePhysicalQuantityId: number; // 基础物理量id
  objectData: ObjectData[]; // 对象数据
}
export interface EnergyPlanDetailSaveBody {
  id: number; // 用能计划详情id
  energyPlanDetailObjects: EnergyPlanDetailObjectsSaveItem[]; // 用能计划详情对象列表
}

export function saveEnergyPlanDetail(body: EnergyPlanDetailSaveBody) {
  return fetch(
    '/api/v2/energy-plan-detail/dataSave',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 执行分配计划
export interface EnergyPlanDetailExecuteBody {
  id: number; // 用能计划详情id
  distributeType?: PlanType; // 分配计划类型(1天 2月 3季度 4年)
  distributeRatio?: number; // 分配系数
  originalPlanDetailId?: number; // 来源计划
}

export function executeEnergyPlanDetail(body: EnergyPlanDetailExecuteBody) {
  return fetch<EnergyPlanOverviewRes>(
    '/api/v2/energy-plan-detail/execute',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export enum EnergyMediumSceneIds {
  WATER_SUPPLY_AND_DRAINAGE = 500, // 给排水/用气
  GAS_SUPPLY_AND_CONSUMPTION = 501, // 供气/用气
  HOT_STEAM = 502, // 热蒸汽
  COMPRESSED_AIR = 503, // 压缩空气
  INDUSTRIAL_GASES = 504, // 工业气体
  HEATING_VENTILATION_AND_AIR_CONDITIONING = 505, // 供暖通风与空气调节
  INDUSTRIAL_LIQUIDS = 506, // 工业液体
  ELEC = 'elec', // 电能
  STANDARD_COAL = 'standardCoal', // 标准煤
}

// 获取工质列表
export interface EnergyMediumListItem {
  id: number;
  energyMediumId: number; // 能源工质id
  energyMediumName: string; // 能源工质名称
  physicalUnitId: number; // 物理单位id
  generalName: string; // 物理单位名称
  k: number; // 转换单位和基本单位的系数
  b: number; //
  calorificRatio: number; // 发热量系数
  standardCoalRatio: number; // 标准煤系数
  basePhysicalQuantityId: number; // 基础物理量id
  energyGroupId: number; // 用能分析组id
  energyMediumSceneId: EnergyMediumSceneIds; // 能源场景id
}

export interface EnergyMediumListRes {
  list: EnergyMediumListItem[];
}

export function getEnergyMediumList(id: number) {
  return fetch<EnergyMediumListRes>(
    '/api/v2/energy-plan/energyMediumList',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取用能单元设备列表
export interface DeviceListItem {
  deviceId: number; // 设备id
  deviceName: string; // 设备名称
}

export interface EnergyUnitListItem {
  energyUnitId: number; // 用能单元id
  energyUnitName: string; // 用能单元名称
  deviceList: DeviceListItem[];
}

export interface EnergyUnitDeviceListRes {
  list: EnergyUnitListItem[];
}

export function getEnergyUnitDeviceList(id: number) {
  return fetch<EnergyUnitDeviceListRes>(
    '/api/v2/energy-plan/unitDeviceList',
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 预览
export interface EnergyPlanDetailPreviewBody {
  id: number; // 用能计划详情id
  planType: PlanType; // 计划类型(1天 2月 3季度 4年)
  energyUnitId?: number; // 用能单元id
  deviceId?: number; // 设备id
  energyMediumId: number; // 能源介质(100:标准煤)
  previewDate?: string; // 预览时间(格式:yyyy-MM-dd)
}

export interface EnergyPlanDetailPreviewListItem {
  ts: string;
  value: number;
}

export interface EnergyPlanDetailPreviewRes {
  list: EnergyPlanDetailPreviewListItem[];
}

export function previewEnergyPlanDetail(body: EnergyPlanDetailPreviewBody) {
  return fetch<EnergyPlanDetailPreviewRes>(
    '/api/v2/energy-plan/dataPreview',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

// 检查名称租户唯一性
export interface CheckNameUniqueBody {
  id?: number; // 计划id
  planName: string; // 计划名称
}

export function checkNameUnique(body: CheckNameUniqueBody) {
  return fetch<{ unique: boolean }>(
    '/api/v2/energy-plan/checkNameUnique',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}
