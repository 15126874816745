import { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  SubContent,
  Table,
  EllipsisSpan,
  useBreadcrumbRoutes,
  Button,
  Form,
  FormTitle,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Space } from 'antd';
import { Link, useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  BaseElectricityBillResponse,
  BillPaymentStatusEnumDisplay,
  getElectricityBillInfo,
  PaymentsItem,
} from '../../../api/electricity';
import { TransactionType, TransactionTypeDisplay, TransactionTypeOperator } from '../../../api/sale';
import { ColumnType } from 'antd/es/table';

// 时间格式化
const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'YYYY-MM-DD HH:mm:ss';

const routes = [{ name: '电费核查联明细' }];

const ElectricityBillChargeSchedule: FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [data, setData] = useState<BaseElectricityBillResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getElectricityBillInfo(id).then(res => {
        setData(res);
        form.setFieldsValue({ paymentStatus: BillPaymentStatusEnumDisplay[res.paymentStatus] });
      });
    }
  }, [id, form]);

  const columns: ColumnType<PaymentsItem>[] = [
    {
      title: '账户编号',
      dataIndex: 'saleClientAccountCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '账户名称',
      dataIndex: 'saleClientAccountName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: TransactionType) => {
        return <EllipsisSpan value={TransactionTypeDisplay[v]} />;
      },
    },
    {
      title: '金额',
      dataIndex: 'payAmount',
      ellipsis: { showTitle: true },
      render: (v: number, record) => <EllipsisSpan value={`${TransactionTypeOperator[record.type]}${v.toFixed(2)}`} />,
    },
    {
      title: '说明',
      dataIndex: 'description',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作日期',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={`${dayjs(v).format(displayDateFormat)}`} />,
    },
    {
      title: '操作人',
      dataIndex: 'createByUsername',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <SubContent title="基本信息">
        <BaseInfoContent>
          <Form.Item label="电费核查联编号">
            <Space size={8}>
              <ShowInput value={data?.billNo} />
              <Button type="link">
                <Link to={`/operation/settlementRules/electricityBill/detail/${data?.id}`}>查看电费核查联详情</Link>
              </Button>
            </Space>
          </Form.Item>

          <Form.Item label="缴费状态" name="paymentStatus">
            <ShowInput />
          </Form.Item>

          <Form.Item label="结算周期">
            <ShowInput
              value={`${dayjs(data?.settlementStartTime).format(dateFormat)} 
                          ~ 
                          ${dayjs(data?.settlementEndTime).subtract(1, 'days').format(dateFormat)}`}
            />
          </Form.Item>

          <Form.Item label="客户名称">
            <ShowInput value={data?.saleClientName} />
          </Form.Item>

          <Form.Item label="客户编号">
            <ShowInput value={data?.saleClientCode} />
          </Form.Item>

          <Form.Item label="应缴金额">
            <ShowInput value={data?.amount?.amount.toFixed(2)} />
          </Form.Item>

          <Form.Item label="累计已缴金额">
            <ShowInput value={data?.amount?.payedAmount.toFixed(2)} />
          </Form.Item>

          <Form.Item label="剩余待缴金额">
            <ShowInput value={data?.amount?.oweAmount.toFixed(2)} />
          </Form.Item>
        </BaseInfoContent>
      </SubContent>
      <SubContent title="缴费明细">
        <Table rowKey="id" sticky columns={columns} dataSource={data?.payments} pagination={false} />
      </SubContent>
      <Space className="sticky-footer">
        <Button
          className={styles.button}
          onClick={() => {
            navigate(`/operation/settlementRules/electricityBill`);
          }}
        >
          返回
        </Button>
      </Space>
    </Wrapper>
  );
};

export default ElectricityBillChargeSchedule;
