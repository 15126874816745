import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import {
  Input,
  Select,
  Radio,
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  InputNumber,
  Form,
  FormTitle,
  SubContent,
  Tag,
  TreeSelect,
  ShowInput,
} from '@maxtropy/components';
import {
  MainDataSourcesTypeMap,
  validateText,
  AccessTypeMap,
  InputTypeMap,
  InputType,
  TransportTypeMap,
  TransportType,
  CarbonFootPrintSettingType,
  CarbonFootPrintSettingTypeMap,
  AutoFillSupportMap,
  SharedRuleMap,
  SharedFlagMap,
  AutoFillSupport,
  SharedFlag,
  SharedRule,
} from '../../utils';
import { RoadType, FinishInputType } from '../LifeCycleModel/const';
import type { SelectProps } from 'antd';
import { Rule } from 'antd/lib/form';
import styles from './index.module.scss';
import TransportTable from '../TransportTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, isEmpty } from 'lodash-es';
import {
  apiV2CarbonFootprintLcaAddInputPost,
  apiV2CarbonFootprintLcaDetailInputPost,
  apiV2CarbonFootprintLcaUpdateInputPost,
  apiV2EnergyListPost,
  apiV2MaterialListPost,
  apiV2EnergyAnalysisListByOu2Post,
  apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost,
  V2EnergyAnalysisListByOu2PostResponse,
  V2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import debounce from 'lodash/debounce';
import { getUnits, UnitTypeWithUnitList, OutputUnitType } from '@/api/outputConfig';
import WorkStationModal, { DataType } from '../WorkStationModal';
import { TreeSelectProps } from 'antd/lib';

const { TextArea } = Input;
const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const routes = [{ name: '添加输入' }];

export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];
export type EnergyUnitsDetails = Exclude<
  V2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPostResponse['list'],
  undefined
>[number];

const formatToNodeData = (data: EnergyUnitsDetails[]): TreeSelectProps['treeData'] => {
  return data.map(i => {
    return {
      title: i.name,
      value: i.id,
      children: i.childList && i.childList.length > 0 ? formatToNodeData(i.childList) : [],
    };
  });
};

const NormalInput: FC = () => {
  const navigator = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [urlSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const paramsToGet = ['id', 'lcaId', 'isProcedure', 'name', 'procedureId'];
  const queryParams = Object.fromEntries(paramsToGet.map(param => [param, urlSearchParams.get(param)]));

  const type = Form.useWatch('type', form); // 填报类型
  const needTransport = Form.useWatch('needTransport', form);
  const sharedFlag = Form.useWatch(['energyInputSetting', 'sharedFlag'], form);
  const autoFillSupport = Form.useWatch(['energyInputSetting', 'autoFillSupport'], form);
  const energyGroupId = Form.useWatch(['energyInputSetting', 'energyGroupId'], form);

  const [serachData, setSerachData] = useState<SelectProps['options']>([]);
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  const [originData, setOriginData] = useState<any>([]);
  const [findData, setFindData] = useState<any>({});
  const [unitName, setUnitName] = useState<string>('--');
  const [openStationModal, setOpenStationModal] = useState<boolean>(false);
  const [selectedStations, setSelectedStations] = useState<DataType[]>([]);
  const [unitGroup, setUnitGroup] = useState<GroupDetails[]>([]);
  const [energyUnits, setEnergyUnits] = useState<TreeSelectProps['treeData']>([]);

  useEffect(() => {
    if (isNil(queryParams.id)) {
      getUnits().then(res => {
        setUnits(res.list);
      });
      materialOrEnergyList('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNil(queryParams.id)) return;
    apiV2CarbonFootprintLcaDetailInputPost({
      id: Number(queryParams.id),
    }).then(res => {
      let formData = { ...res };
      if (res.type === InputType.ENERGY && !formData.energyInputSetting?.autoFillSupport) {
        formData.energyInputSetting = {
          autoFillSupport: AutoFillSupport.NO_SUPPORT,
        };
      }

      form.setFieldsValue(formData);
      if (res.type === InputType.ENERGY) {
        apiV2EnergyListPost({ nameOrCode: '' }).then(result => {
          const mapList = result?.list?.map(i => {
            return {
              label: `${i.code}/${i.name}`,
              value: i.id,
            };
          });
          setSerachData(mapList ?? []);
          setOriginData(result?.list ?? []);
        });
        if (res.energyInputSetting?.stationMap) {
          setSelectedStations(
            Object.entries(res.energyInputSetting.stationMap).map(([key, value]) => ({ name: value, id: Number(key) }))
          );
        }
      } else {
        apiV2MaterialListPost({ codeOrNameOrSpec: '', type: res.type }).then(result => {
          const mapList = result?.list?.map(i => {
            return {
              label: `${i.code}/${i.name}${!isNil(i.spec) ? '/' : ''}${!isNil(i.spec) ? i.spec : ''}`,
              value: i.id,
            };
          });
          setSerachData(mapList ?? []);
          setOriginData(result?.list ?? []);
        });
      }
      getUnits().then(units => {
        setUnits(units.list);
        const unitCode =
          (res.unitTypeCode === OutputUnitType.PIECE
            ? units.list
                .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                ?.outputProductUnitList?.find(e => e?.unitCode === res.unitCode)?.unitName
            : res.unitCode) ?? '--';
        setUnitName(unitCode);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.id]);

  useEffect(() => {
    if (!autoFillSupport) return;

    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setUnitGroup(res.list ?? []);
    });
  }, [autoFillSupport]);

  useEffect(() => {
    if (isNil(energyGroupId)) return;

    apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost({
      id: Number(energyGroupId),
    }).then(res => {
      const treeData = res.list ? formatToNodeData(res.list) : [];

      setEnergyUnits(treeData);
    });
  }, [energyGroupId]);

  const debounceFetcher = debounce((value: string) => {
    materialOrEnergyList(value);
  }, 500);

  const materialOrEnergyList = (value: string) => {
    if (isNil(type)) {
      setSerachData([]);
      return;
    }
    if (type === InputType.ENERGY) {
      apiV2EnergyListPost({ nameOrCode: value }).then(res => {
        const mapList = res?.list?.map(i => {
          return {
            label: `${i.code}/${i.name}`,
            value: i.id,
          };
        });
        setSerachData(mapList ?? []);
        setOriginData(res?.list ?? []);
      });
    } else {
      apiV2MaterialListPost({ codeOrNameOrSpec: value, type }).then(res => {
        const mapList = res?.list?.map(i => {
          return {
            label: `${i.code}/${i.name}${!isNil(i.spec) ? '/' : ''}${!isNil(i.spec) ? i.spec : ''}`,
            value: i.id,
          };
        });
        setSerachData(mapList ?? []);
        setOriginData(res?.list ?? []);
      });
    }
  };

  const unitFind = (value: string) => {
    const findData = originData?.find((i: any) => {
      return i.id === value;
    });
    setFindData(findData);
    if (isNil(findData)) return;
    if (type === InputType.ENERGY) {
      setUnitName(findData.physicalUnitName);
    } else {
      const unitCode =
        (findData.unitTypeCode === OutputUnitType.PIECE
          ? units
              .find(u => u.unitTypeCode === OutputUnitType.PIECE)
              ?.outputProductUnitList?.find(e => e?.unitCode === findData.unitCode)?.unitName
          : findData.unitCode) ?? '--';
      setUnitName(unitCode);
    }
  };

  const onFinish = (values: FinishInputType) => {
    //新创建的路段不能将id传后端,只能作为前端的key暂时使用
    const newRoads = (values?.roads ?? []).map((road: RoadType) => {
      if (typeof road.id === 'string') {
        delete road.id;
      }
      return road;
    });

    const params = {
      ...values,
      roads: newRoads,
      isMaterial: type === InputType.ENERGY ? 0 : 1,
      name: findData.name,
      unitTypeCode: findData.unitTypeCode ?? undefined,
      unitCode: !isNil(findData.unitCode) ? findData.unitCode : findData.physicalUnitName,
      procedureId: Number(queryParams.procedureId),
    };

    if (!isEmpty(params.energyInputSetting)) {
      const energyUnitIdSetIds: { label: string; value: number }[] = form.getFieldValue([
        'energyInputSetting',
        'energyUnitIdSet',
      ]);
      params.energyInputSetting = {
        ...params.energyInputSetting,
        energyUnitIdSet: energyUnitIdSetIds?.map(i => (typeof i === 'object' ? i.value : i)),
        stationIdSet: selectedStations?.map(i => i.id!),
      };
    }

    if (queryParams.id) {
      apiV2CarbonFootprintLcaUpdateInputPost({ ...params, id: Number(queryParams.id) }).then(res => {
        navigator(
          `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
        );
      });
    } else {
      apiV2CarbonFootprintLcaAddInputPost({ ...params }).then(res => {
        navigator(
          `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
        );
      });
    }
  };
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="添加输入" />

      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{
          needTransport: TransportType.NO_NEED,
          carbonFootPrintSetting: CarbonFootPrintSettingType.Non_Negligible,
        }}
      >
        <SubContent title="基础信息" className="mb-8">
          <Row gutter={[48, 0]}>
            <Col span={8}>
              <Form.Item label="过程名称">
                <ShowInput value={queryParams.name}></ShowInput>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="type" label="输入类型 " rules={[{ message: '请选择', required: true }]}>
                <Select
                  placeholder={'请选择'}
                  onChange={v => {
                    form.setFieldsValue({ materialOrEnergyId: undefined });
                    setUnitName('--');

                    if (v === InputType.ENERGY) {
                      form.setFieldValue(['energyInputSetting', 'autoFillSupport'], AutoFillSupport.NO_SUPPORT);
                    }
                  }}
                  options={Object.entries(InputTypeMap).map(([k, v]) => ({
                    label: v,
                    value: +k,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="materialOrEnergyId"
                label={type === InputType.ENERGY ? '能源编码/能源名称' : '物料编码/名称/规格型号'}
                rules={[{ message: '请选择', required: true }]}
              >
                <Select
                  showSearch
                  onSearch={debounceFetcher}
                  optionFilterProp="label"
                  placeholder={'请选择'}
                  onChange={unitFind}
                  options={serachData}
                  onFocus={() => {
                    if (isNil(form.getFieldValue('materialOrEnergyId'))) {
                      materialOrEnergyList('');
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[48, 0]}>
            <Col span={8}>
              <Form.Item name="accessType" label="获取方式">
                <Select
                  placeholder={'请选择'}
                  options={Object.entries(AccessTypeMap).map(([k, v]) => ({
                    label: v,
                    value: +k,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="quantity"
                label="使用量"
                style={{ display: 'inline-block' }}
                rules={[
                  { message: '请输入', required: true },
                  {
                    pattern: /^(?!0+(\.0*)?$)0\.[1-9]\d*|0\.\d*[1-9]\d*$|[1-9]\d*(\.\d+)?$/,
                    message: '数值必须大于0',
                  },
                ]}
              >
                <InputNumber min={0} style={{ minWidth: 216 }}></InputNumber>
              </Form.Item>
              <Form.Item label="单位" style={{ display: 'inline-block', marginLeft: 12 }}>
                <ShowInput value={unitName}></ShowInput>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="dataOrigin" label="主要数据来源" rules={[{ message: '请选择', required: true }]}>
                <Select
                  placeholder={'请选择'}
                  options={Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
                    label: v,
                    value: +k,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[48, 0]}>
            <Col span={8}>
              <Form.Item name="remark" label="数据说明" style={{ maxWidth: 464 }} rules={[validateText(200) as Rule]}>
                <TextArea placeholder="请输入1-200字符" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="carbonFootPrintSetting"
                label="碳足迹设置"
                rules={[{ message: '请选择', required: true }]}
              >
                <Radio.Group>
                  <Radio value={CarbonFootPrintSettingType.Non_Negligible}>
                    {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Non_Negligible]}
                  </Radio>
                  <Radio value={CarbonFootPrintSettingType.Negligible}>
                    {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Negligible]}
                  </Radio>
                  <Radio value={CarbonFootPrintSettingType.DataUnavailable}>
                    {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.DataUnavailable]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="运输信息" className="mb-8">
          <Row>
            <Col span={6}>
              <Form.Item name="needTransport" label="是否需要运输" rules={[{ message: '请选择', required: true }]}>
                <Radio.Group>
                  <Radio value={TransportType.NO_NEED}>{TransportTypeMap[TransportType.NO_NEED]}</Radio>
                  <Radio
                    value={TransportType.NEED}
                    onChange={() => {
                      const roads = cloneDeep(form.getFieldValue('roads'));
                      if (isNil(roads) || roads?.length === 0) {
                        form.setFieldsValue({
                          roads: [
                            {
                              id: uuidv4(),
                              startPlace: undefined,
                              endPlace: undefined,
                              distance: undefined,
                              transportation: undefined,
                              factorId: undefined,
                            },
                          ],
                        });
                      }
                    }}
                  >
                    {TransportTypeMap[TransportType.NEED]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {needTransport === TransportType.NEED && (
              <Form.Item
                name="grossWeight"
                label=" "
                required={false}
                rules={[
                  { message: '请输入数字', required: true },
                  {
                    pattern: /^(?!0+(\.0*)?$)0\.[1-9]\d*|0\.\d*[1-9]\d*$|[1-9]\d*(\.\d+)?$/,
                    message: '数值必须大于0',
                  },
                ]}
              >
                <Space align="center">
                  <span style={{ color: 'var(--mx-form-item-label-color)' }}>1pcs对应的产品的运输毛重为：</span>
                  <InputNumber addonAfter={'kg'} min={0} style={{ minWidth: 123 }}></InputNumber>
                </Space>
              </Form.Item>
            )}
          </Row>
          {needTransport === TransportType.NEED && <TransportTable form={form}></TransportTable>}
        </SubContent>

        {type === InputType.ENERGY && (
          <SubContent title="活动数据填报设置">
            <Row>
              <Col span={8}>
                <Form.Item
                  name={['energyInputSetting', 'autoFillSupport']}
                  label="是否自动填报"
                  rules={[{ message: '请选择', required: true }]}
                >
                  <Radio.Group
                    onChange={e => {
                      if (e.target.value === AutoFillSupport.SUPPORT) {
                        form.setFieldValue(['energyInputSetting', 'sharedFlag'], SharedFlag.NO_SHARED);
                      }
                    }}
                  >
                    {Object.entries(AutoFillSupportMap).map(([key, label]) => (
                      <Radio value={Number(key)}>{label}</Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            {autoFillSupport === AutoFillSupport.SUPPORT && (
              <Row>
                <Col span={8}>
                  <Form.Item
                    name={['energyInputSetting', 'energyGroupId']}
                    label="用能配置"
                    rules={[{ required: true, message: '请选择' }]}
                  >
                    <Select
                      placeholder="请选择用能分析组"
                      fieldNames={{ label: 'energyGroupName', value: 'energyGroupId' }}
                      options={unitGroup}
                      onChange={() => {
                        form.setFieldValue(['energyInputSetting', 'energyUnitIdSet'], []);
                      }}
                    />
                  </Form.Item>
                </Col>
                {energyGroupId && (
                  <Col span={8}>
                    <Form.Item
                      name={['energyInputSetting', 'energyUnitIdSet']}
                      label=" "
                      rules={[
                        {
                          validator: (_, value) => {
                            if (isNil(value)) {
                              return Promise.reject('请选择');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <TreeSelect
                        placeholder="请选择用能单元"
                        treeCheckable
                        showCheckedStrategy="SHOW_ALL"
                        treeCheckStrictly
                        treeData={energyUnits}
                        maxTagCount={2}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={8}>
                  <Form.Item name={['energyInputSetting', 'sharedFlag']} label="是否需要分摊">
                    <Radio.Group
                      onChange={e => {
                        if (e.target.value === SharedFlag.SHARED) {
                          form.setFieldValue(['energyInputSetting', 'sharedRule'], SharedRule.PRODUCTION);
                        }
                      }}
                    >
                      {Object.entries(SharedFlagMap).map(([key, label]) => (
                        <Radio value={Number(key)}>{label}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {sharedFlag === SharedFlag.SHARED && (
              <Row>
                <Col span={8}>
                  <Form.Item name={['energyInputSetting', 'sharedRule']} label="分摊规则">
                    <Radio.Group>
                      {Object.entries(SharedRuleMap).map(([key, label]) => (
                        <Radio value={Number(key)}>{label}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item
                    name={['energyInputSetting', 'stationIdSet']}
                    label="选中用能单元服务的工站"
                    required
                    rules={[
                      {
                        validator: (_, value) => {
                          if (isEmpty(selectedStations)) {
                            return Promise.reject(new Error('请选择工站'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Button type="dashed" ghost onClick={() => setOpenStationModal(true)}>
                      请选择工站
                    </Button>
                  </Form.Item>
                  {selectedStations.length > 0 && (
                    <div>
                      {selectedStations.map(item => (
                        <Tag
                          key={item.id}
                          closable
                          style={{ marginBottom: 8 }}
                          onClose={() => {
                            setSelectedStations(selectedStations.filter(i => i.id !== item.id));
                          }}
                        >
                          {item.name}
                        </Tag>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </SubContent>
        )}
        {openStationModal && (
          <WorkStationModal
            setOpen={setOpenStationModal}
            selectedStations={selectedStations}
            setSelectedStations={setSelectedStations}
          />
        )}
        <Space className="sticky-footer" size={8}>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button
            onClick={() => {
              navigator(
                `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
              );
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default NormalInput;
