import { Input } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Key, Tree, getRealUrl } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import ProductionBaseIcon from '../../assets/productionBase.png';
import workCenterIcon from '../../assets/workCenter.png';
import workOrderIcon from '../../assets/productionProcesse.png';
import workStationIcon from '../../assets/workStation.png';
import unit from '../../assets/unit.png';

import {
  V2ProductionRhythmOverviewShopFlowTreeGetPostResponse,
  apiV2ProductionRhythmOverviewShopFlowTreeGetPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
interface Props {
  setAppAnalysisSubscriptionId: (v?: number | null) => void;
  setHasConfig?: (v: boolean) => void;
}

export type TreeListItem = Exclude<V2ProductionRhythmOverviewShopFlowTreeGetPostResponse['list'], undefined>[number];

const dataList: { key: React.Key; title: string }[] = [];

export interface TreeNode extends DataNode {
  appAnalysisSubId?: number | null;
}

const generateList = (data: TreeNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const flattenArray = (data: TreeNode[], arr: { key: string; title: string }[] = []) => {
  data.forEach(i => {
    arr.push({ key: i.key as string, title: i.title as string });
    if (i.children) {
      flattenArray(i.children, arr);
    }
  });
  return arr;
};

enum ShopFlowType {
  Production_BASE = 1,
  WORK_CENTER = 2,
  WORK_PROCESS = 3,
  WORK_STATION = 4,
  UNIT = 5,
}

const ShopFlowType_Icon = {
  [ShopFlowType.Production_BASE]: ProductionBaseIcon,
  [ShopFlowType.WORK_CENTER]: workCenterIcon,
  [ShopFlowType.WORK_PROCESS]: workOrderIcon,
  [ShopFlowType.WORK_STATION]: workStationIcon,
  [ShopFlowType.UNIT]: unit,
};

const formatTreeData = (data: TreeListItem[]): TreeNode[] => {
  return data.map((item: TreeListItem) => {
    return {
      key: item.uniqueCode as string,
      title: item.name,
      icon: (
        <img
          src={isNil(item.iconKey) ? ShopFlowType_Icon[item.shopFlowType as ShopFlowType] : getRealUrl(item.iconKey)}
          alt=""
          className={styles.iconStyle}
        />
      ),
      appAnalysisSubId: item.appAnalysisSubId,
      disabled: !(!isNil(item.appAnalysisSubId) && item.appAnalysisType === 1 && item.appAnalysisSubStatus === 1),
      children: item.children ? formatTreeData(item.children) : undefined,
    };
  });
};

function findFirstNodeWithNonNullAppAnalysisSubId(tree: TreeListItem[]): TreeListItem | null {
  for (const node of tree) {
    if (!isNil(node.appAnalysisSubId) && node.appAnalysisType === 1 && node.appAnalysisSubStatus === 1) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const result = findFirstNodeWithNonNullAppAnalysisSubId(node.children);
      if (!isNil(result)) {
        return result;
      }
    }
  }
  return null;
}

// 根据节点的Id获取所有父节点
const getPathByNodeId = (data: TreeNode[], key: React.Key, path: string[] = []): string[] => {
  for (const node of data) {
    path.push(node.key as string);
    if (node.key === key) return path;
    if (node.children) {
      const findChildren = getPathByNodeId(node.children, key, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

const StationTree: React.FC<Props> = ({ setAppAnalysisSubscriptionId, setHasConfig }) => {
  const [searchParams, setSearchParams] = useState<any>();
  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [tree, setTree] = useState<TreeNode[]>([]);

  useEffect(() => {
    apiV2ProductionRhythmOverviewShopFlowTreeGetPost({}).then(res => {
      const treeList = formatTreeData(res.list ?? []);
      const firstSelectedNode = findFirstNodeWithNonNullAppAnalysisSubId(res.list ?? []);

      generateList(treeList);
      setTree(treeList);
      if (!isNil(firstSelectedNode)) {
        setTreeSelectedKeys([firstSelectedNode.uniqueCode as string]);
        setAppAnalysisSubscriptionId(firstSelectedNode.appAnalysisSubId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 找parentKey
  useEffect(() => {
    if (tree && tree.length) {
      if (searchParams && searchParams.name) {
        const dataflatArr = flattenArray(tree);
        const newExpandedKeys = dataflatArr
          ?.map(item => {
            if (item.title.includes(searchParams.name)) {
              return getPathByNodeId(tree, item.key);
            }
            return null;
          })
          .filter(i => !!i);
        newExpandedKeys.forEach(i => i?.pop());
        setTreeExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
      } else {
        setTreeExpandedKeys(dataList.map(i => i.key));
      }
    }
  }, [searchParams, setTreeExpandedKeys, tree]);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (tree && tree.length > 0 && searchParams && searchParams.name) {
      const loop = (data: TreeNode[]): TreeNode[] =>
        data.map(item => {
          const strTitle = item.title as string;
          const searchName = searchParams ? searchParams.name : undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span style={{ color: 'red' }}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(tree);
    } else {
      return tree;
    }
  }, [searchParams, tree]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.searchArea}>
        <Input.Search
          placeholder="请输入名称"
          allowClear
          onSearch={v => {
            setAutoExpandParent(true);
            setSearchParams({ name: v });
          }}
        />
      </div>
      <div className={styles.treeBox}>
        {tree.length > 0 ? (
          <Tree
            className={styles.treeStyle}
            style={{ height: 'calc(100vh - 260px)' }}
            blockNode
            treeData={treeData}
            expandedKeys={treeExpandedKeys}
            selectedKeys={treeSelectedKeys}
            onExpand={v => {
              setTreeExpandedKeys(v);
              setAutoExpandParent(false);
            }}
            onSelect={(v, info) => {
              setHasConfig?.(false);
              setTreeSelectedKeys(v);
              setAppAnalysisSubscriptionId((info.selectedNodes[0] as TreeNode).appAnalysisSubId);
            }}
            autoExpandParent={autoExpandParent}
            showIcon
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default StationTree;
