import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import styles from './index.module.scss';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import CardItem from './CardItem';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Empty } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { Space, Spin } from 'antd';
import ResizeObserver from 'rc-resize-observer';
import { floor, isNil } from 'lodash-es';
import { OverviewItemProps, UnitBasicProps } from '@/api/energyUnitDashboard';
import { EnergyUnitContext } from '..';
import { useSearchParams } from 'react-router-dom';
import { apiV2EnergyUnitBoardComparisonOverviewPost } from '@maxtropy/device-customer-apis-v2';
const { RangePicker } = DatePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface UnitSwiperProps {
  oneLevelUnits: UnitBasicProps[];
  expose: (dates: RangeValue, swiperUnitName?: string, swiperUnitId?: number) => void;
}

let initFlag = false;

const UnitSwiper: React.FC<UnitSwiperProps> = ({ oneLevelUnits, expose }) => {
  // setTime 用来记忆时间，总览到详情，详情到总览
  const { groupId, setTime, time } = useContext(EnergyUnitContext);

  const [dates, setDates] = useState<RangeValue | []>();
  const [value, setValue] = useState<RangeValue>();
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();
  const [unitList, setUnitList] = useState<OverviewItemProps[]>([]);
  const [siderCount, setSiderCount] = useState<number>(3);
  const [currentUnitId, setCurrentUnitId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const [urlSearchParams] = useSearchParams();
  const url_startDate = urlSearchParams.get('startDate');
  const url_endDate = urlSearchParams.get('endDate');

  useLayoutEffect(() => {
    if (url_startDate && url_endDate && !initFlag) {
      setValue([dayjs(url_startDate), dayjs(url_endDate)]);
      initFlag = true;
    } else {
      if (!isNil(time)) {
        console.log('time', time[0]?.format('YYYY-MM-DD HH:mm:ss'), time[1]?.format('YYYY-MM-DD HH:mm:ss'));
        let fromTime = time[0]?.format('YYYY-MM-DD 00:00:00') ?? '';
        let toTime =
          (time[1]?.isToday() ? time[1].format('YYYY-MM-DD HH:mm:ss') : time[1]?.format('YYYY-MM-DD 23:59:59')) ?? '';
        setValue([dayjs(fromTime), dayjs(toTime)]);
      } else {
        setValue([dayjs().subtract(6, 'day'), dayjs()]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_startDate, url_endDate]);

  useEffect(() => {
    if (isNil(groupId)) return;
    if (!value || !value[0] || !value[1]) return;
    if (oneLevelUnits.length && !oneLevelUnits.some(item => item.energyUnitGroupId === groupId)) return;

    let fromTime = value[0].format('YYYY-MM-DD 00:00:00');
    let toTime = value[1].isToday() ? value[1].format('YYYY-MM-DD HH:mm:ss') : value[1].format('YYYY-MM-DD 23:59:59');

    if (!isNil(time)) {
      fromTime = time[0]?.format('YYYY-MM-DD 00:00:00') ?? '';
      toTime =
        (time[1]?.isToday() ? time[1].format('YYYY-MM-DD HH:mm:ss') : time[1]?.format('YYYY-MM-DD 23:59:59')) ?? '';
    }

    // setTime?.(value);
    setLoading(true);
    apiV2EnergyUnitBoardComparisonOverviewPost({
      energyUnitGroupId: String(groupId),
      startTime: fromTime,
      endTime: toTime,
    })
      .then(res => {
        const lastUnits = oneLevelUnits?.map(m => {
          const index = (res.list ?? [])?.findIndex(f => f.energyUnitId === m.energyUnitId);
          return index !== -1
            ? {
                ...(res.list ?? [])[index],
                disabled: m.disabled,
              }
            : {
                energyUnitId: m.energyUnitId,
                energyUnitName: m.name,
                energyMediums: [],
                disabled: m.disabled,
                comprehensiveEnergyConsumption: null,
              };
        });
        setUnitList(lastUnits as OverviewItemProps[]);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, oneLevelUnits]);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;
    const afterToday = current && current > dayjs().endOf('day');
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  useEffect(() => {
    if (!unitList.length) {
      setCurrentUnitId(undefined);
    } else {
      setCurrentUnitId(unitList[0].energyUnitId);
    }
  }, [unitList]);

  useEffect(() => {
    console.log(currentUnitId, 'currentUnitId');
    console.log(value, 'value');
    if (!value || !value[0] || !value[1]) return;
    if (isNil(currentUnitId)) {
      expose(value, undefined, undefined);
    } else {
      const currentUnitName = unitList.find(f => f.energyUnitId === currentUnitId)?.energyUnitName;
      expose(value, currentUnitName!, currentUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnitId, value]);

  useEffect(() => {
    setDates(value);
  }, [value]);

  const onClickDateLink = (type: string) => {
    let start = dayjs();
    let end = dayjs();
    switch (type) {
      case 'day':
        start = dayjs().subtract(0, 'day');
        break;
      case 'month':
        start = dayjs().startOf('month');
        break;
      case 'thirty':
        start = dayjs().subtract(29, 'day');
        break;
      case 'lastMonth':
        start = dayjs().subtract(1, 'month').startOf('month');
        end = dayjs().subtract(1, 'month').endOf('month');
        break;
    }
    setValue([start, end]);
    setTime?.([start, end]);
    setDates([start, end]);
  };
  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([]);
    }
  };
  return (
    <div className={styles.swiperBox}>
      <div>
        时间：
        <RangePicker
          allowClear={false}
          value={value}
          disabledDate={disabledDate}
          onCalendarChange={val => {
            setDates([val[0], null]);
          }}
          onOpenChange={onOpenChange}
          onChange={val => {
            setValue(val);
            setTime?.(val);
          }}
        />
        <Space size={16} style={{ marginLeft: 16 }}>
          <Button type="link" className={styles.linkBtn} onClick={() => onClickDateLink('day')}>
            当日
          </Button>
          <Button type="link" className={styles.linkBtn} onClick={() => onClickDateLink('month')}>
            本月
          </Button>
          <Button type="link" className={styles.linkBtn} onClick={() => onClickDateLink('lastMonth')}>
            上月
          </Button>
          <Button type="link" className={styles.linkBtn} onClick={() => onClickDateLink('thirty')}>
            近30日
          </Button>
        </Space>
      </div>
      <Spin spinning={loading}>
        <div className={styles.cardWrapper}>
          {unitList.length ? (
            <div className={styles.cardList}>
              <ResizeObserver
                onResize={({ width }) => {
                  setSiderCount(floor(width / 420));
                }}
              >
                <Swiper
                  className={styles.swiperWrapper}
                  spaceBetween={18}
                  slidesPerView={siderCount}
                  freeMode={true}
                  observer={true}
                  observeParents={true}
                  onSwiper={swiper => setSwiperIntance(swiper)}
                  navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                  }}
                >
                  {unitList.map((m, index) => (
                    <SwiperSlide key={index}>
                      <CardItem
                        Overview={m}
                        setSwiperUnitId={id => setCurrentUnitId(id)}
                        swiperUnitId={currentUnitId}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </ResizeObserver>
              <div className={`swiper-button-prev`} onClick={() => swiperIntance?.slidePrev()}>
                <LeftOutlined />
              </div>
              <div className={`swiper-button-next`} onClick={() => swiperIntance?.slideNext()}>
                <RightOutlined />
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default UnitSwiper;
