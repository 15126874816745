import React from 'react';
import { ControlProps } from '.';
import { InputType, MinMaxConstraintValue } from '../../types';
import { Input, InputNumber } from '@maxtropy/components';

const SingleInput: React.FC<ControlProps> = ({
  placeholder,
  disabled,
  isDetail,
  inputType,
  digitalAccuracy,
  constraintValue,
  value,
  onChange,
  unit,
  addonAfter,
}) => {
  const Component = inputType === InputType.NUMBER ? InputNumber : Input;
  const { min, max } = constraintValue
    ? (constraintValue as MinMaxConstraintValue)
    : { min: undefined, max: undefined };

  if (isDetail) {
    return <div>{value?.value as string | number}</div>;
  }
  return (
    <Component
      placeholder={placeholder ?? '请输入'}
      min={min}
      max={max}
      precision={digitalAccuracy}
      disabled={disabled}
      value={value?.value as string | number}
      onChange={e => {
        const v =
          inputType === InputType.NUMBER ? (e as number) : (e as React.ChangeEvent<HTMLInputElement>).target.value;
        onChange && onChange({ ...value, value: v });
      }}
      addonAfter={addonAfter}
    />
  );
};

export default SingleInput;
