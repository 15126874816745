import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';

export function formatTreeData(data: any[]) {
  return data.map(item => {
    const data = item?.data;
    const result: DefaultOptionType = {
      key: data?.mcid ?? '',
      value: data?.mcid ?? '',
      title: data?.name,
      children: formatTreeData(item.children),
      disabled: !data?.hasPermission,
    };
    return result;
  });
}

export const DateDisplay = 'YYYY-MM-DD';

export const DateZeroTimeDisplay = 'YYYY-MM-DD 00:00:00';
