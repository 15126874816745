interface MeterDate {
  value: number;
  label: string;
}
enum MaterType {
  AUTO = 0,
  MANUAL,
  IMPORT
}

const MaterTypeDisplay = {
  [MaterType.AUTO]: "合同自动抄表",
  [MaterType.MANUAL]: "手工生成核查联",
  [MaterType.IMPORT]: "抄表导入",
}
// 抄表来源的数据
const MeterReading: MeterDate[] = [
  {
    value: MaterType.AUTO,
    label: MaterTypeDisplay[MaterType.AUTO],
  },
  {
    value: MaterType.MANUAL,
    label: MaterTypeDisplay[MaterType.MANUAL],
  },
  {
    value: MaterType.IMPORT,
    label: MaterTypeDisplay[MaterType.IMPORT],
  },
];
export default MeterReading