import { Checkbox, Modal } from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { apiV2ReportStrategyDeviceTypeListPost } from '@maxtropy/device-customer-apis-v2';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DataProperty } from '@/api/reportStrategy';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ReportStrategyStatisticalTypeEnum } from '@/shared/types';

interface DeviceType {
  id?: number;
  name?: string;
  dataPropertySimpleList?: DataProperty[];
}

interface Props {
  deviceIds?: number[];
  statisticalType?: ReportStrategyStatisticalTypeEnum;
  open: boolean;
  onSubmit: (val: DataProperty[]) => void;
  onClose: () => void;
}
const StatisticalDataModal: React.FC<Props> = ({ open, onClose, deviceIds, statisticalType, onSubmit }) => {
  const [data, setData] = useState<DeviceType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);

  useEffect(() => {
    setCheckedList([]);
  }, [open]);

  useEffect(() => {
    if (!deviceIds?.length) return;
    apiV2ReportStrategyDeviceTypeListPost({ deviceIds, measurementType: statisticalType }).then(res => {
      const newList = (res.list ?? [])?.map(item => {
        return {
          ...item,
          dataPropertySimpleList: item.dataPropertySimpleList?.map(k => ({
            ...k,
            deviceId: item.id,
            key: item.id + '-' + k.id,
          })),
        };
      });
      setData(newList);
    });
  }, [deviceIds, statisticalType]);

  const indeterminate = useMemo(() => {
    const options = data.map(item => item.dataPropertySimpleList?.map(k => k.key)).flat();
    return checkedList.length > 0 && checkedList.length < options.length;
  }, [checkedList.length, data]);

  const checkAll = useMemo(() => {
    const options = data.map(item => item.dataPropertySimpleList?.map(k => k.key)).flat();
    return checkedList.length === options?.length;
  }, [checkedList.length, data]);

  const submit = () => {
    const checkedOptions = data
      .map(item => item.dataPropertySimpleList?.map(k => k))
      .flat()
      .filter(m => checkedList.some(k => k === m?.key));
    onSubmit(checkedOptions as DataProperty[]);
  };

  // 全选
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    const options = data.map(item => item.dataPropertySimpleList?.map(k => k.key)).flat();
    setCheckedList(checked ? (options as string[]) : []);
  };

  const onChangeItem = (checkedValues: CheckboxValueType[], itemValues?: string[]) => {
    // 找出不在当前group的keys
    const otherGroupCheckedVals = checkedList.filter(k => !itemValues?.includes(k));
    setCheckedList([...otherGroupCheckedVals, ...checkedValues] as string[]);
  };

  return (
    <Modal
      title="选择统计数据"
      size="large"
      open={open}
      bodyScroll={true}
      onCancel={onClose}
      onOk={submit}
      okText="应用到当前设备"
    >
      <div className={styles.toolTip}>
        <InfoCircleOutlined style={{ color: 'var(--warning-color)', marginRight: '8px' }} />
        <span className={styles.text}>
          应用后，当前设备有对应数据属性则计入报表策略，未选择数据属性不计入，更新之前的选择配置。
        </span>
      </div>
      <div className={styles.checkedTip}>
        <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll}>
          全选
        </Checkbox>
        <span>
          已选择 <span className={styles.checkedNum}>{`${checkedList?.length ?? 0}`}</span> 项
        </span>
      </div>
      {data.map((item, index) => (
        <div className={styles.dataGroup} key={index}>
          <div>{item.name}</div>
          <Checkbox.Group
            className={styles.checkboxContainer}
            options={item.dataPropertySimpleList?.map(i => ({
              label: i.name,
              value: i.key!,
            }))}
            value={checkedList}
            onChange={(checkedValues: any) => {
              const itemValues = item.dataPropertySimpleList?.map(k => k.key!);
              onChangeItem(checkedValues, itemValues);
            }}
          />
        </div>
      ))}
    </Modal>
  );
};

export default StatisticalDataModal;
