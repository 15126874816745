import ReactEcharts from 'echarts-for-react';
import {
  ChartData,
  ChartDataBeatStandardRes,
  ChartDataNode,
  ChartDataRes,
  GlassPressWorkStatusDisplay,
  PunchWorkStatusDisplay,
  UpDataItem,
  WorkProcedureType,
  WorkStatus,
  WorkStatusDisplay,
  graphicData,
} from '../../mockData';
import ResizeObserver from 'rc-resize-observer';
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';

const getTimeData = (date: string, value: number) => {
  let timestamp = new Date(date).getTime(); //  00:00:00 时间戳
  const data = [];
  for (let i = 0; i < 1440; i++) {
    data.push({
      ts: timestamp + i * 60 * 1000,
      value,
    });
  }
  return data;
};

interface BeatLineChartProps {
  cutChartMockData?: ChartDataRes;
  beatStandardMockData?: ChartDataBeatStandardRes;
  date: string;
  workProcedureType: WorkProcedureType;
}

const getPiecesDate = (allTimeNode: ChartDataNode[], isStandard: boolean) => {
  return allTimeNode.map(i => ({
    lt: i.end,
    color:
      i.status === WorkStatus.stop
        ? isStandard
          ? '#232324'
          : 'rgba(93,112,146,0.8)'
        : i.status === WorkStatus.wait
        ? isStandard
          ? '#232324'
          : 'rgba(250,173,20,0.8)'
        : i.status === WorkStatus.feed
        ? 'rgba(82,231,255,0.8)'
        : i.status === WorkStatus.cut
        ? 'rgba(45,141,255,0.8)'
        : 'rgba(22,221,142,0.8)',
  }));
};

const BeatLineChart: React.FC<BeatLineChartProps> = ({
  cutChartMockData,
  beatStandardMockData,
  date,
  workProcedureType,
}) => {
  const chartRef = useRef<ReactEcharts>();
  const [data, setData] = useState<ChartData[]>([]);
  const [timeNode, setTimeNode] = useState<ChartDataNode[]>([]);
  const [stopOrWaitNode, setStopOrWaitNode] = useState<ChartDataNode[]>([]);
  const [upData, setUpData] = useState<UpDataItem[]>([]);
  const [downData, setDownData] = useState<UpDataItem[]>([]);
  const [allTimeNode, setAllTimeNode] = useState<ChartDataNode[]>([]);
  const [standardAllTimeNode, setStandardAllTimeNode] = useState<ChartDataNode[]>([]);

  useEffect(() => {
    if (cutChartMockData) {
      setData(cutChartMockData.data);
      setTimeNode(cutChartMockData.timeNode);
      setStopOrWaitNode(cutChartMockData.stopOrWaitNode);
      setAllTimeNode(cutChartMockData.allTimeNode);
    }
  }, [cutChartMockData]);

  console.log(getPiecesDate(allTimeNode, false), '123132');

  useEffect(() => {
    if (beatStandardMockData) {
      setUpData(beatStandardMockData.upData);
      setDownData(beatStandardMockData.downData);
      setStandardAllTimeNode(beatStandardMockData.allTimeNode ?? []);
    }
  }, [beatStandardMockData]);

  console.log(dayjs().hour() * 60 + dayjs().minute(), '123');

  const getChartOption = useMemo(() => {
    return {
      grid: [
        {
          left: 35,
          right: 5,
          height: '65%',
        },
        {
          left: 35,
          right: 5,
          bottom: 65,
          height: '4%',
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (tooltipData: any) => {
          const factData = data.filter(i => i.ts === tooltipData[0].data[0]);
          const ts = dayjs(tooltipData[0].data[0]).format('YYYY-MM-DD HH:mm');
          // 所处时间节点
          const node = allTimeNode.filter(i => i.start <= tooltipData[0].data[0] && i.end > tooltipData[0].data[0]);
          // 实际工时
          const factTime = ((tooltipData[0].data[0] - (node[0]?.start ?? 0)) / 1000 / 60).toFixed(0);
          // 标准工时
          const standardTime =
            workProcedureType === WorkProcedureType.punch
              ? factData[0]?.status === WorkStatus.feed
                ? 2
                : factData[0]?.status === WorkStatus.cut
                ? 15
                : 3
              : factData[0]?.status === WorkStatus.feed
              ? 10
              : factData[0]?.status === WorkStatus.cut
              ? 30
              : 10;

          // 标准功率
          const standardPower =
            workProcedureType === WorkProcedureType.cut
              ? factData[0]?.status === WorkStatus.feed
                ? '10-15kW'
                : factData[0]?.status === WorkStatus.cut
                ? '50-70kW'
                : '15-25kW'
              : workProcedureType === WorkProcedureType.punch
              ? factData[0]?.status === WorkStatus.feed
                ? '5-10kW'
                : factData[0]?.status === WorkStatus.cut
                ? '100-120kW'
                : '15-25kW'
              : factData[0]?.status === WorkStatus.feed
              ? '80-120kW'
              : factData[0]?.status === WorkStatus.cut
              ? '110-150kW'
              : '20-40kW';
          // 停机或者待机节点
          const stopOrWait = stopOrWaitNode.filter(
            i => i.start <= tooltipData[0].data[0] && i.end >= tooltipData[0].data[0]
          );

          // 停机或者待机持续时间
          const stopOrWaitTime = (
            (tooltipData[0].data[0] - ((stopOrWait.length > 1 ? stopOrWait[1]?.start : stopOrWait[0]?.start) ?? 0)) /
            1000 /
            60
          ).toFixed(0);
          const workName =
            workProcedureType === WorkProcedureType.cut
              ? WorkStatusDisplay[factData[0]?.status]
              : workProcedureType === WorkProcedureType.punch
              ? PunchWorkStatusDisplay[factData[0]?.status]
              : GlassPressWorkStatusDisplay[factData[0]?.status];
          return factData[0]?.status === WorkStatus.stop || factData[0]?.status === WorkStatus.wait
            ? `
            <div style="width: 200px;" >
              <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${ts} </p>
              <div style="display:flex; justify-content: space-between; align-items: center;" > 
                <p style= "margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);"  >${workName}</p>
              </div>
              <div style="display:flex; justify-content: space-between; align-items: center;" > 
                <p style= "margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);"  >持续时长</p>
                ${
                  factData[0]?.isNormal
                    ? `<p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${stopOrWaitTime}min </p>`
                    : `<p style="margin: 0px; font-size: 12px; color: #E64242;">  ${stopOrWaitTime}min </p>`
                }
              </div>
              <div style="display:flex; justify-content: space-between; align-items: center;" > 
                <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >工况参数</p>
                <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${factData[0]?.value}kW </p>
              </div>
            </div>
            `
            : ` 
          <div style="width: 200px;" >
            <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${ts} </p>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
              <p style= "margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);"  >${workName}</p>
              ${
                factData[0]?.isNormal
                  ? `<p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > 正常 </p>`
                  : `<p style="margin: 0px; font-size: 12px; color: #E64242;"> 异常 </p>`
              }
            </div>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
              <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >实际工时</p>
              ${
                factData[0]?.isNormal
                  ? `<p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${factTime}min </p>`
                  : `<p style="margin: 0px; font-size: 12px; color: #E64242;"> ${factTime}min </p>`
              }
            </div>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
              <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >标准工时</p>
              <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${standardTime}min </p>
            </div>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
              <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >实际工况</p>
              ${
                factData[0]?.isNormal
                  ? `<p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${factData[0]?.value}kW </p>`
                  : `<p style="margin: 0px; font-size: 12px; color: #E64242;"> ${factData[0]?.value}kW </p>`
              }
            </div>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
              <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >标准工况区间</p>
              <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${standardPower} </p>
            </div>
          </div>`;
        },
      },
      graphic: graphicData(workProcedureType).map((i, index) => ({
        type: 'rect',
        top: i.top,
        bottom: i.bottom,
        right: i.right,
        left: i.left,
        shape: {
          width: 16,
          height: 10,
        },
        textContent: {
          type: 'text',
          style: {
            text: i.name,
            fill: i.name === '实际' || i.name === '标准' ? 'rgba(255,255,255,0.65)' : 'rgba(255,255,255,0.85)',
          },
        },
        textConfig: {
          position: 'right',
        },
        style: {
          fill: i.name === '实际' || i.name === '标准' ? undefined : i.color,
        },
      })),
      legend: {
        show: true,
        right: 360,
        top: 15,
        itemHeight: 4,
        itemWidth: 16,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        data: [
          { name: '实际节拍数据', icon: 'rect', itemStyle: { color: '#16DD8E' } },
          { name: '标准节拍数据', icon: 'rect', itemStyle: { color: 'rgba(0,173,255,0.4)' } },
        ],
      },
      axisPointer: {
        link: [
          {
            xAxisIndex: 'all',
            yAxisIndex: 'all',
          },
        ],
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
          end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
          xAxisIndex: [0, 1],
          bottom: 10,
        },
        {
          type: 'inside',
          realtime: true,
          start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
          end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
          xAxisIndex: [0, 1],
        },
      ],
      xAxis: [
        {
          type: 'time',
          minInterval: 60 * 1000,
          data: data.map(i => i.ts),
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
            margin: 16,
            formatter: function (e: number) {
              return dayjs(e, 'x').format('HH:mm');
            },
          },
        },
        {
          gridIndex: 1,
          type: 'time',
          position: 'top',
          data: data.map(i => i.ts),
          show: false,
        },
      ],
      yAxis: [
        {
          name: '工况参数',
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          type: 'value',
          splitLine: {
            lineStyle: { color: 'rgba(255,255,255,0.30)' },
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
        },
        {
          gridIndex: 1,
          type: 'value',
          inverse: true,
          show: false,
        },
      ],
      visualMap: [
        {
          show: false,
          dimension: 0,
          seriesIndex: 1,
          pieces: getPiecesDate(allTimeNode, false),
        },
        {
          show: false,
          dimension: 0,
          seriesIndex: 2,
          pieces: getPiecesDate(standardAllTimeNode, true),
        },
      ],
      series: [
        {
          name: '实际节拍数据',
          type: 'line',
          symbol: 'none',
          data: data.map(i => [i.ts, i.value]),
          itemStyle: {
            color: '#16DD8E',
          },
          markArea: {
            data: timeNode.map((i, index) => {
              const isAllNormal = data.filter(j => j.ts >= i.start && j.ts <= i.end).every(i => i.isNormal);

              return [
                {
                  xAxis: i.start,
                  itemStyle: {
                    color: isAllNormal ? 'rgba(82,196,26,0.1)' : 'rgba(230,66,66,0.2)',
                  },
                },
                {
                  xAxis: i.end,
                  itemStyle: {
                    color: isAllNormal ? 'rgba(82,196,26,0.1)' : 'rgba(230,66,66,0.2)',
                  },
                },
              ];
            }),
          },
        },
        {
          name: '实际',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbol: 'none',
          data: getTimeData(date, 0).map(i => [i.ts, i.value]),
          lineStyle: {
            width: 12,
          },
        },
        {
          name: '标准',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbol: 'none',
          data: getTimeData(date, 1).map(i => [i.ts, i.value]),
          lineStyle: {
            width: 12,
          },
        },
        {
          name: '标准节拍数据下限',
          type: 'line',
          symbol: 'none',
          data: downData.map(i => [i.ts, i.value]),
          lineStyle: {
            opacity: 0,
          },
          stack: 'confidence-band',
        },
        {
          name: '标准节拍数据',
          type: 'line',
          symbol: 'none',
          data: upData.map(i => [i.ts, i.value]),
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            color: 'rgba(0,173,255,0.6)',
          },
          stack: 'confidence-band',
        },
      ],
    };
  }, [allTimeNode, data, date, downData, stopOrWaitNode, timeNode, upData, workProcedureType]);

  return (
    <ResizeObserver
      onResize={({ width }) => {
        chartRef.current?.getEchartsInstance().resize({ width });
      }}
    >
      <ReactEcharts
        ref={e => {
          if (e) chartRef.current = e;
        }}
        option={getChartOption}
        style={{ height: 550 }}
        notMerge
        lazyUpdate={false}
      />
    </ResizeObserver>
  );
};

export default BeatLineChart;
