import { ChangeLogPageParams, ChangeLogRes, getChangeLogPage } from '@/api/electricitySettlementRules';
import {
  Button,
  CustomFilter,
  DatePicker,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  Table,
  useBreadcrumbRoutes,
  useSearchParams,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';

const routes = [{ name: '结算规则日志' }];

const columns = [
  {
    title: '结算规则编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算规则名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'createByUsername',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const modalColumns = [
  {
    title: '字段',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原始数据',
    dataIndex: 'originalData',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '修改后数据',
    dataIndex: 'currentData',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface Data {
  id: number;
  name: string;
  originalData: string;
  currentData: string;
  enName: string;
}

interface SearchParams extends Omit<ChangeLogPageParams, 'page' | 'size'> {}

const SettlementRulesBlog: React.FC = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_code = urlSearchParams.get('code') || undefined;
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50, { code: url_code });
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [blogList, setBlogList] = useState<ChangeLogRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<Data[]>([]);

  let modalData = [
    {
      id: 1,
      name: '容量单价',
      originalData: '',
      currentData: '',
      enName: 'unitCapacityRate',
    },
    {
      id: 2,
      name: '需量单价',
      originalData: '',
      currentData: '',
      enName: 'unitDemandRate',
    },
    {
      id: 3,
      name: '需量单价',
      originalData: '',
      currentData: '',
      enName: 'unitDeclaredDemandRate',
    },
    {
      id: 4,
      name: '电度电价',
      originalData: '',
      currentData: '',
      enName: 'standingRate',
    },
    {
      id: 5,
      name: '电度电价-尖（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'summitRate',
    },
    {
      id: 6,
      name: '电度电价-峰（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'peakRate',
    },
    {
      id: 7,
      name: '电度电价-平（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'plainRate',
    },

    {
      id: 8,
      name: '电度电价-谷（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'valleyRate',
    },
    {
      id: 9,
      name: '是否有照明定比（是/否）',
      originalData: '',
      currentData: '',
      enName: 'hasLightingTariff',
    },
    {
      id: 10,
      name: '照明定比（%）',
      originalData: '',
      currentData: '',
      enName: 'lightingTariffRatio',
    },
    {
      id: 11,
      name: '照明电费单价（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'unitLightingTariffRate',
    },
    {
      id: 12,
      name: '是否有优惠',
      originalData: '',
      currentData: '',
      enName: 'hasDiscountedRate',
    },
    {
      id: 13,
      name: '优惠方式',
      originalData: '',
      currentData: '',
      enName: 'discountedType',
    },
    {
      id: 14,
      name: '电度单价优惠（分）',
      originalData: '',
      currentData: '',
      enName: 'unitRateDiscountedRate',
    },
    {
      id: 15,
      name: '优惠折扣（%）',
      originalData: '',
      currentData: '',
      enName: 'totalRateDiscountedRatio',
    },
    {
      id: 16,
      name: '总优惠电度数（kWh）',
      originalData: '',
      currentData: '',
      enName: 'totalRateDiscountedQuantity',
    },
    {
      id: 17,
      name: '总优惠金额（元）',
      originalData: '',
      currentData: '',
      enName: 'totalRateDiscountedRate',
    },
    {
      id: 18,
      name: '是否有子表扣除费用（是/否）',
      originalData: '',
      currentData: '',
      enName: 'hasSubMeterRateDeduct',
    },
    {
      id: 19,
      name: '子表需量/容量单价',
      originalData: '',
      currentData: '',
      enName: 'subMeterUnitDemandRate',
    },
    {
      id: 20,
      name: '子表电度电价-尖（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'subMeterSummitRate',
    },
    {
      id: 21,
      name: '子表电度电价-峰（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'subMeterPeakRate',
    },
    {
      id: 22,
      name: '子表电度电价-平（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'subMeterPlainRate',
    },
    {
      id: 23,
      name: '子表电度电价-谷（元/kWh)',
      originalData: '',
      currentData: '',
      enName: 'subMeterValleyRate',
    },
  ];

  useEffect(() => {
    setLoading(true);
    getChangeLogPage({ ...searchParams, page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setBlogList(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount]);

  useEffect(() => {
    if (url_code) {
      // finish({ code: url_code });
      form.setFieldsValue({ code: url_code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_code]);

  const onFinish = (v: SearchParams) => {
    const searchParams = {
      ...v,
      code: v?.code === '' ? undefined : v?.code,
      name: v?.name === '' ? undefined : v?.name,
    };
    finish({ ...searchParams, updateTime: v.updateTime ? dayjs(v.updateTime).format('YYYY-MM-DD HH') : undefined });
  };

  const onReset = () => {
    url_code = undefined;
    reset();
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string, record: ChangeLogRes) => {
        return (
          <Button
            type="link"
            onClick={() => {
              const currentData = JSON.parse(record.currentData);
              const originalData = JSON.parse(record.originalData);

              let data: Data[] = [];
              for (let key in currentData) {
                if (currentData.hasOwnProperty(key)) {
                  data = modalData.map(i => {
                    if (i.enName === key) {
                      i.currentData = currentData[key];
                      i.originalData = originalData[key];
                      return i;
                    }
                    return i;
                  });
                }
              }
              for (let key in originalData) {
                if (originalData.hasOwnProperty(key)) {
                  data = modalData.map(i => {
                    if (i.enName === key) {
                      i.currentData = currentData[key];
                      i.originalData = originalData[key];
                      return i;
                    }
                    return i;
                  });
                }
              }
              const filterData = data
                .filter(i => i.currentData !== '' && i.originalData !== '')
                .filter(i => i.currentData !== i.originalData)
                .filter(i => Number(i.currentData) !== Number(i.originalData));

              setData(filterData);
              setModalOpen(true);
            }}
          >
            查看详情
          </Button>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter form={form} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
      <Form.Item label="结算规则编号" name="code">
        <Input placeholder=" 请输入编号查询" />
      </Form.Item>
      <Form.Item label="结算规则名称" name="name">
        <Input placeholder=" 请输入名称查询" />
      </Form.Item>
      <Form.Item label="修改时间" name="updateTime">
        <DatePicker style={{ width: '100%' }} showTime={{ format: 'HH' }} format="YYYY-MM-DD HH" placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} filters={filters} className={styles.wrapper}>
      <Space style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          onClick={() => {
            const search = form.getFieldsValue();
            finish({
              code: search.code && search.code !== '' ? search.code : undefined,
              name: search.name && search.name !== '' ? search.name : undefined,
              updateTime: search.updateTime,
              distinctOnRuleId: true,
            });
          }}
        >
          查看最新修改记录
        </Button>
      </Space>

      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        loading={loading}
        columns={buildColumns}
        dataSource={blogList}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />

      <Modal
        size="large"
        title="修改记录详情"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}
      >
        <Table rowKey="id" scroll={{ y: 500 }} columns={modalColumns} dataSource={data} pagination={false} />
      </Modal>
    </Wrapper>
  );
};
export default SettlementRulesBlog;
