import { Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';

import ElectricityAccount from './Electricity';
import ThermalList from './Thermal';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission } from '../../utils/utils';
import WorkingSubstance from './WorkingSubstance';

type TabKey = PermissionsType.B_ELECTRIC_ACCOUNT | PermissionsType.B_THERMAL_ACCOUNT | PermissionsType.B_MEDIUMACCOUNT;

const Account: FC = () => {
  const [activeKey, setActiveKey] = useState<TabKey>();
  const hasElectricAccount = useHasPermission(PermissionsType.B_ELECTRIC_ACCOUNT);
  const hasThermalAccount = useHasPermission(PermissionsType.B_THERMAL_ACCOUNT);
  const hasMEDIUMACCOUNT = useHasPermission(PermissionsType.B_MEDIUMACCOUNT);

  const show = useMemo(() => {
    return hasElectricAccount !== undefined && hasThermalAccount !== undefined && hasMEDIUMACCOUNT !== undefined;
  }, [hasElectricAccount, hasThermalAccount, hasMEDIUMACCOUNT]);

  useEffect(() => {
    if (hasElectricAccount !== undefined && hasThermalAccount !== undefined && hasMEDIUMACCOUNT !== undefined) {
      if (hasElectricAccount) {
        setActiveKey(PermissionsType.B_ELECTRIC_ACCOUNT);
        return;
      }
      if (hasThermalAccount) {
        setActiveKey(PermissionsType.B_THERMAL_ACCOUNT);
      }
      if (hasMEDIUMACCOUNT) {
        setActiveKey(PermissionsType.B_MEDIUMACCOUNT);
      }
    }
  }, [hasElectricAccount, hasThermalAccount, hasMEDIUMACCOUNT]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const tabItems = [
    {
      key: PermissionsType.B_ELECTRIC_ACCOUNT,
      label: '用电账户配置',
      children: <ElectricityAccount />,
      isShow: hasElectricAccount,
    },
    {
      key: PermissionsType.B_THERMAL_ACCOUNT,
      label: '用汽账户配置',
      children: <ThermalList />,
      isShow: hasThermalAccount,
    },
    {
      key: PermissionsType.B_MEDIUMACCOUNT,
      label: '工质账户配置',
      children: <WorkingSubstance />,
      isShow: hasMEDIUMACCOUNT,
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      {show && <Tabs defaultActiveKey={activeKey} className={styles.tabs} items={tabItems.filter(i => i.isShow)} />}
    </Wrapper>
  );
};

export default Account;
