import ReactEcharts from 'echarts-for-react';
import useEchartsTheme from '../../../../shared/hooks/useEchartsTheme';
import * as echarts from 'echarts/core';
import { useMemo } from 'react';
import styles from './index.module.scss';
import { GridComponent, GridComponentOption } from 'echarts/components';
import { BarChart, BarSeriesOption } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import deliveryTruck from '../../assets/deliveryTruck.svg';
import industryProduce from '../../assets/industryProduce.svg';
import rawMaterial from '../../assets/rawMaterial.svg';
interface BarEchartsProps {
  carbonData?: any;
}
type EChartsOption = echarts.ComposeOption<GridComponentOption | BarSeriesOption>;

echarts.use([GridComponent, BarChart, CanvasRenderer]);
const BarEcharts: React.FC<BarEchartsProps> = ({ carbonData }) => {
  const echartsTheme = useEchartsTheme();
  // const chartRef = useRef<any>(null);

  // const getChartDataURL = () => {
  //   // 检查图表实例是否存在
  //   if (chartRef.current && chartRef.current.getEchartsInstance) {
  //     // 获取图表的数据URL
  //     const echartsInstance = chartRef.current.getEchartsInstance();
  //     const chartDataURL = echartsInstance.getDataURL();
  //     // 在这里使用 chartDataURL
  //     console.log(chartDataURL);
  //   } else {
  //     console.error('图表实例未准备好');
  //   }
  // };

  const option: EChartsOption = useMemo(() => {
    return {
      title: [
        {
          text: '生命周期各阶段碳足迹',
          subtext: '',
          left: '-6px',
          top: '8px',
          textStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontWeight: 500,
            fontSize: 16,
          },
        },
        {
          text: '',
          subtext: '（单位：kgCO2e）',
          left: '150px',
          subtextStyle: {
            color: 'rgba(255,255,255,0.65)',
            fontWeight: 500,
            fontSize: 14,
          },
        },
      ],
      grid: {
        left: '2%',
        right: '4%',
        // bottom: '3%',
        containLabel: true,
      },
      backgroundColor: '#232324',
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          color: '#232324',
        },
        splitLine: {
          show: true, // 显示分隔线
          lineStyle: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
      yAxis: {
        type: 'category',
        data: ['生产', '原料运输', '原料获取'],
        axisLabel: {
          align: 'left',
          margin: 80,
          verticalAlign: 'middle',
          color: 'rgba(255,255,255,0.85)',
        },
      },
      series: [
        {
          itemStyle: {
            color: {
              type: 'linear',
              x: 1,
              y: 0,
              x2: 0,
              y2: 0, // 设置渐变的起始和结束点坐标（范围为 0 到 1）
              colorStops: [
                { offset: 0, color: '#23E994' }, // 渐变起始颜色
                { offset: 1, color: '#6DEA43' }, // 渐变结束颜色
              ],
              global: false, // 缺省为 false
            },
          },
          type: 'bar',
          data: carbonData,
          barWidth: '33%',
          label: {
            show: true,
            position: 'right',
          },
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carbonData]);

  return (
    <div className={styles.content}>
      <img src={rawMaterial} alt="" className={styles.rawMaterial} />
      <img src={deliveryTruck} alt="" className={styles.deliveryTruck} />
      <img src={industryProduce} alt="" className={styles.industryProduce} />
      <ReactEcharts
        className={styles.ReactEcharts}
        option={option}
        style={{ height: '286px', width: '100%', position: 'relative' }}
        notMerge
        lazyUpdate={false}
        // ref={chartRef}
        theme={echartsTheme}
        // onChartReady={getChartDataURL}
      />
    </div>
  );
};

export default BarEcharts;
