/**
 * 上一年LAST:-1, 本年NOW: 0, 枚举值
 */
export enum YearEnum {
  LAST = -1, // 上一年
  NOW, // 本年
}

/**
 * 上一年, 本年
 */
export const yearEnumDisplay = {
  [YearEnum.LAST]: '上一年',
  [YearEnum.NOW]: '本年',
};

/**
 * 上日LAST:-2, 本日NOW: 0
 * 本日NOW_OTHER: -1, 执行周期按月执行时, 1小时颗粒度中的本日枚举值
 */
export enum DayEnum {
  LAST = -2, // 上日
  NOW_OTHER = -1, // 本日
  NOW = 0, // 本日
}
/**
 * 上日, 本日
 */
export const dayEnumDisplay = {
  [DayEnum.LAST]: '上日',
  [DayEnum.NOW_OTHER]: '本日',
  [DayEnum.NOW]: '本日',
};

/**
 * 上月LAST:-1, 本月NOW: 0, 枚举值
 */
export enum MonthEnum {
  LAST = -1, // 上月
  NOW, // 本月
}

/**
 * 上月, 本月
 */
export const monthEnumDisplay = {
  [MonthEnum.LAST]: '上月',
  [MonthEnum.NOW]: '本月',
};

/**
 * 上周LAST:-1, 本周NOW: 0, 枚举值
 */
export enum WeekEnum {
  LAST = -1, // 上周
  NOW, // 本周
}

/**
 * 上周, 本周
 */
export const weekEnumDisplay = {
  [WeekEnum.LAST]: '上周',
  [WeekEnum.NOW]: '本周',
};
