import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Key, Modal, ShowInput, TreeSelect } from '@maxtropy/components';
import { Col, Form, Row, Button, Space, Spin } from 'antd';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
  apiV2WorkTeamAddPost,
  apiV2WorkTeamInfoPost,
  apiV2WorkTeamUpdatePost,
  V2WorkTeamInfoPostResponse,
  V2WorkTeamUpdatePostRequest,
} from '@maxtropy/device-customer-apis-v2';

const formItemLayout = {
  labelCol: { span: 4 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 24, offset: 4 },
};

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  treeData: DefaultOptionType[];
  isEdit?: boolean;
  teamId?: Key;
  isDetail?: boolean;
  updateFn?: () => void;
}

export type Worker = Exclude<V2WorkTeamInfoPostResponse['workers'], undefined>[number];

export interface CreateTeamGroupParams extends V2WorkTeamUpdatePostRequest {
  workTeamId?: string | number;
  deleteWorkers?: number[];
}

const CreateTeamModal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  treeData,
  isEdit = false,
  teamId,
  isDetail = false,
  updateFn,
}) => {
  const [form] = Form.useForm();
  const [notUpdateWorkers, setNotUpdateWorkers] = useState<Worker[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (teamId) {
      setLoading(true);
      apiV2WorkTeamInfoPost({ id: teamId })
        .then(res => {
          form.setFieldsValue({
            name: res.name,
            mcids: (res.customers ?? []).map(i => i.mcid),
            leadWorker: { id: res.leadWorkerId, no: res.leaderWorkerNo, name: res.leaderWorkerName },
            workers: res.workers,
            mcidNames: (res.customers ?? []).map(i => i.name).join('、'),
          });
          setNotUpdateWorkers(res.workers ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const onCancel = () => {
    setModalOpen(false);
  };

  const onFinish = (v: CreateTeamGroupParams) => {
    if (isEdit && teamId) {
      const updateWorkersId = v.workers?.map(i => i.id);
      const deleteWorkers = notUpdateWorkers.filter(i => {
        if (updateWorkersId && !updateWorkersId.includes(i.id as number)) {
          return true;
        }
        return false;
      });
      const addWorkers = v.workers
        ?.map(i => {
          if (!i.id) {
            return i;
          }
          return undefined;
        })
        .filter(i => i !== undefined);
      const updateWorkers = v.workers
        ?.filter(i => {
          if (i.id) {
            return i;
          }
          return undefined;
        })
        .map(i => {
          const worker = notUpdateWorkers.find(item => i.id === item.id);
          if (!(worker && worker.no === i.no && worker.name === i.name)) {
            return i;
          }
          return undefined;
        })
        .filter(i => i !== undefined);

      apiV2WorkTeamUpdatePost({
        workTeamId: teamId,
        name: v.name,
        mcids: v.mcids,
        leadWorker: form.getFieldValue(['leadWorker', 'no']) ? form.getFieldValue('leadWorker') : undefined,
        workers: [...(addWorkers ?? []), ...(updateWorkers ?? [])] as Worker[],
        deleteWorkers: (deleteWorkers ?? []).map(i => i.id as number),
      }).then(() => {
        setModalOpen(false);
        updateFn?.();
      });
      return;
    }

    apiV2WorkTeamAddPost({
      name: v.name,
      mcids: form.getFieldValue('mcids')?.map((i: DefaultOptionType) => i.value),
      leadWorker: form.getFieldValue(['leadWorker', 'no']) ? v.leadWorker : undefined,
      workers: v.workers as Worker[],
    }).then(() => {
      setModalOpen(false);
      updateFn?.();
    });
  };
  return (
    <Modal
      title={(isDetail ? '查看' : isEdit ? '编辑' : '新建') + '班组'}
      width={680}
      bodyStyle={{ overflowY: 'auto', maxHeight: 500 }}
      open={modalOpen}
      onCancel={onCancel}
      onOk={() => {
        if (isDetail) {
          setModalOpen(false);
          return;
        }
        form.submit();
      }}
      contentClassName="modal-form-content"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          {...formItemLayout}
          labelAlign="left"
          onFinish={(v: CreateTeamGroupParams) => {
            onFinish(v);
          }}
        >
          <Col span={22}>
            {isDetail ? (
              <Form.Item label="班组名称" name="name">
                <ShowInput />
              </Form.Item>
            ) : (
              <Form.Item
                label="班组名称"
                name="name"
                rules={[
                  { required: true, message: '请输入班组名称', whitespace: true, type: 'string' },
                  { max: 15, message: '最多输入15个字符' },
                  { pattern: /^((?![\\/:*?<>|.]).)+$/, message: '请确保班组名称中不含下列字符：\\ / : * ? < > | .' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            )}
          </Col>
          <Col span={22}>
            {isDetail ? (
              <Form.Item name="mcidNames" label="所属组织">
                <ShowInput />
              </Form.Item>
            ) : (
              <Form.Item name="mcids" label="所属组织" rules={[{ required: true, message: '请选择所属组织' }]}>
                <TreeSelect
                  showCheckedStrategy={TreeSelect.SHOW_ALL}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeDefaultExpandAll
                  treeData={treeData}
                  placeholder="请选择"
                  style={{ width: '100%' }}
                  allowClear
                  multiple
                  maxTagCount="responsive"
                  treeCheckable
                  treeCheckStrictly
                  disabled={isEdit}
                  showSearch={false}
                />
              </Form.Item>
            )}
          </Col>
          <Col span={22}>
            <Form.Item label="班组负责人">
              <Row gutter={4}>
                <Input.Group compact className={styles.formItemX}>
                  <Col span={12}>
                    <Form.Item noStyle dependencies={[['leadWorker', 'name']]}>
                      {({ getFieldValue }) => {
                        const name = getFieldValue(['leadWorker', 'name']);
                        if (name) {
                          return (
                            <Form.Item
                              name={['leadWorker', 'no']}
                              rules={[
                                { max: 30, message: '最多输入30个字符' },
                                { required: true, message: '请输入' },
                              ]}
                            >
                              <Input placeholder="请输入工号" disabled={isDetail} />
                            </Form.Item>
                          );
                        }
                        return (
                          <Form.Item name={['leadWorker', 'no']} rules={[{ max: 30, message: '最多输入30个字符' }]}>
                            <Input placeholder="请输入工号" disabled={isDetail} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item noStyle dependencies={[['leadWorker', 'no']]}>
                      {({ getFieldValue }) => {
                        const no = getFieldValue(['leadWorker', 'no']);
                        if (no) {
                          return (
                            <Form.Item
                              name={['leadWorker', 'name']}
                              rules={[
                                { max: 50, message: '最多输入50个字符' },
                                { required: true, message: '请输入' },
                              ]}
                            >
                              <Input placeholder="请输入名称" disabled={isDetail} />
                            </Form.Item>
                          );
                        }
                        return (
                          <Form.Item name={['leadWorker', 'name']} rules={[{ max: 50, message: '最多输入50个字符' }]}>
                            <Input placeholder="请输入名称" disabled={isDetail} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Input.Group>
              </Row>
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.List name="workers">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {isDetail
                    ? fields.map((field, index) => {
                        return (
                          <Form.Item
                            key={field.key}
                            label={index === 0 ? '班组人员' : ''}
                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                            className={styles.formItemX}
                          >
                            <Space align="baseline">
                              <Form.Item
                                name={[field.name, 'no']}
                                rules={[
                                  { required: true, message: '请输入工号', whitespace: true },
                                  { max: 30, message: '最多输入30个字符' },
                                ]}
                              >
                                <Input placeholder="请输入工号" disabled={isDetail} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'name']}
                                rules={[
                                  { required: true, message: '请输入名称', whitespace: true },
                                  { max: 50, message: '最多输入50个字符' },
                                ]}
                              >
                                <Input placeholder="请输入名称" disabled={isDetail} />
                              </Form.Item>
                            </Space>
                          </Form.Item>
                        );
                      })
                    : fields.map((field, index) => {
                        return (
                          <Form.Item
                            key={field.key}
                            label={index === 0 ? '班组人员' : ''}
                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                            className={styles.formItemX}
                          >
                            <Space style={{ display: 'flex', width: '110%' }} align="baseline">
                              <Form.Item
                                name={[field.name, 'no']}
                                rules={[
                                  { required: true, message: '请输入工号', whitespace: true },
                                  { max: 30, message: '最多输入30个字符' },
                                ]}
                              >
                                <Input placeholder="请输入工号" disabled={isDetail} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'name']}
                                rules={[
                                  { required: true, message: '请输入名称', whitespace: true },
                                  { max: 50, message: '最多输入50个字符' },
                                ]}
                              >
                                <Input placeholder="请输入名称" disabled={isDetail} />
                              </Form.Item>
                              {isDetail ? null : (
                                <>
                                  <Button
                                    type="link"
                                    onClick={() => remove(index)}
                                    icon={<MinusCircleOutlined style={{ color: '#838383' }} />}
                                  />
                                </>
                              )}
                            </Space>
                          </Form.Item>
                        );
                      })}

                  {isDetail ? null : (
                    <>
                      <Button
                        icon={<PlusOutlined />}
                        style={{ marginLeft: 82, width: '83%', color: '#4A90E2' }}
                        type="dashed"
                        onClick={() => add()}
                      >
                        添加班组人员
                      </Button>

                      <div style={{ paddingLeft: 120 }}>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </>
                  )}
                </>
              )}
            </Form.List>
          </Col>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateTeamModal;
