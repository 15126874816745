import { OuInfoProps } from '@/api/ou';
import { Form, Input, Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { List } from '@/pages/UET/Edit/BackUpPower/types';
import {
  apiV2UetCreateBsaSecondEditionPost,
  apiV2UetHardwareVersionListPost,
  apiV2UetUpdateBsaSecondEditionPost,
} from '@maxtropy/device-customer-apis-v2';

import { InputNumber, Modal } from '@maxtropy/components';

const formLayout = {
  labelCol: { flex: '120px' },
};

interface Props {
  modalVisible?: boolean;
  currentRowInfo?: List;
  ouList?: OuInfoProps[];
  setModalVisible: (v: boolean) => void;
  setCurrentRowInfo: (v: undefined) => void;
  forceUpdate: () => void;
}
// 3位小数点
const limitDecimalsF = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d\d).*$/;
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3');
};
// 截断3位小数
const limitDecimalsPThree = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d\d).*$/;
  return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
};
const BsaNewModal: React.FC<Props> = ({
  modalVisible,
  currentRowInfo,
  ouList,
  setModalVisible,
  setCurrentRowInfo,
  forceUpdate,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>(); // 获取uetid

  const { data: hardWareVersionListData } = useRequest(
    async () => {
      const list = await apiV2UetHardwareVersionListPost();
      return list;
    },
    {
      ready: !!modalVisible,
      refreshDeps: [modalVisible],
    }
  );

  const hardWareVersionListOptions = useMemo(() => {
    if (hardWareVersionListData && hardWareVersionListData.list) {
      return hardWareVersionListData.list.map(i => ({ label: i, value: i }));
    }
  }, [hardWareVersionListData]);

  // const [extraMessage, setExtraMessage] = useState<string>();
  const requestApi = useMemo(() => {
    return currentRowInfo ? apiV2UetUpdateBsaSecondEditionPost : apiV2UetCreateBsaSecondEditionPost;
  }, [currentRowInfo]);
  const { runAsync, loading } = useRequest(params => requestApi(params), {
    manual: true,
  });
  const onOk = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        uetId: +id!,
      };
      if (currentRowInfo) params.id = currentRowInfo.id;

      runAsync(params).then(() => {
        form.resetFields();
        setModalVisible(false);
        setCurrentRowInfo(undefined);
        forceUpdate();
      });
    });
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setCurrentRowInfo(undefined);
  };

  const title = useMemo(() => {
    return currentRowInfo ? '编辑储能站' : '新建储能站';
  }, [currentRowInfo]);

  useEffect(() => {
    if (currentRowInfo && modalVisible) {
      form.setFieldsValue({
        ...currentRowInfo,
        deviceId: currentRowInfo.relatedDeviceId,
      });
    }
  }, [currentRowInfo, form, modalVisible]);

  return (
    <>
      <Modal open={modalVisible} confirmLoading={loading} title={title} onCancel={onCancel} onOk={onOk} destroyOnClose>
        <Form form={form} {...formLayout} style={{ marginTop: 24 }}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select disabled={!!currentRowInfo} placeholder="请选择" allowClear>
              {(ouList ?? []).map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="储能阵列名称"
            name="name"
            rules={[
              { required: true, message: '请输入储能阵列名称' },
              { max: 30, message: '最多输入30个字符' },
            ]}
          >
            <Input placeholder="请输入" allowClear />
          </Form.Item>

          <Form.Item
            label="硬件版本"
            name="hardwareVersion"
            rules={[{ required: true, message: '请选择硬件版本' }]}
            // extra={extraMessage}
          >
            <Select
              placeholder="请选择"
              disabled={!!currentRowInfo}
              options={hardWareVersionListOptions}
              // onChange={v =>
              //   hardWareEnumArray.includes(getObjectKey(hardWareValueDisplay, v))
              //     ? setExtraMessage('支持PCS数量：单PCS')
              //     : setExtraMessage('支持PCS数量：多PCS')
              // }
            />
          </Form.Item>

          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '请输入装机容量' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              formatter={limitDecimalsF}
              parser={limitDecimalsPThree}
              max={99999999.999}
              min={0}
              precision={3}
              addonAfter="MWh"
            />
          </Form.Item>
          <Form.Item label="额定功率" name="ratedPower" rules={[{ required: true, message: '请输入额定功率' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              formatter={limitDecimalsF}
              parser={limitDecimalsPThree}
              max={99999999.999}
              min={0.001}
              addonAfter="MW"
              step="1"
              // precision={3}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BsaNewModal;
