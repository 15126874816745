import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Button, Form, Modal, Select, Tag } from '@maxtropy/components';
import {
  V2EnergyAnalysisUnitConfGroupHisPostResponse,
  apiV2EnergyAnalysisUnitConfGroupHisPost,
  apiV2EnergyUnitDeviceDetailPost,
  V2EnergyUnitDeviceDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import { RangeValue } from '../UnitDetail';

type UnitConfGroupHistoryRecordResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupHisPostResponse['list'],
  undefined
>[number];

interface Prop {
  setOpen: (value: boolean) => void;
  // 用能分析组
  energyUnitGroupId?: number;
  value?: RangeValue;
}

const getTs = (date: any) => {
  return dayjs(date).valueOf();
};

const DeviceDetailModal = ({ energyUnitGroupId, value, setOpen }: Prop) => {
  const [hisConfList, setHisConfList] = useState<UnitConfGroupHistoryRecordResponse[]>([]);
  const [data, setData] = useState<V2EnergyUnitDeviceDetailPostResponse['list']>([]);

  const [form] = Form.useForm();
  const hisConfId = Form.useWatch('hisConfId', form);

  useEffect(() => {
    apiV2EnergyAnalysisUnitConfGroupHisPost({ energyUnitGroupId }).then(res => {
      setHisConfList(res.list ?? []);
    });
  }, [energyUnitGroupId]);

  useEffect(() => {
    if (hisConfList && hisConfList.length > 0 && value?.[1]) {
      const defaultHisConfId = hisConfList.find(
        item =>
          getTs(item.effectiveDate!) <= getTs(value[1]!) &&
          (item.endDate ? getTs(item.endDate) >= getTs(value[1]!) : true)
      )?.id;

      if (defaultHisConfId) {
        form.setFieldValue('hisConfId', defaultHisConfId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hisConfList]);

  useEffect(() => {
    if (!hisConfId) return;

    apiV2EnergyUnitDeviceDetailPost({ id: hisConfId }).then(res => {
      setData(res.list ?? []);
    });
  }, [hisConfId]);

  return (
    <Modal
      title="设备详情"
      open
      size="normal"
      onCancel={() => setOpen(false)}
      bodyScroll
      footer={<Button onClick={() => setOpen(false)}>取消</Button>}
    >
      <Form form={form} isOrigin={true}>
        <Form.Item label="历史配置" name="hisConfId" required>
          <Select style={{ width: 360 }}>
            {hisConfList?.map((list, index) => (
              <Select.Option key={index} value={list.id}>
                {dayjs(list.effectiveDate).format('YYYY-MM-DD')}～
                {list.endDate ? dayjs(list.endDate).format('YYYY-MM-DD') : '无期限'}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div className={styles.energyMediumList}>
        {data?.map((item, index) => {
          return (
            <div key={index} className={styles.energyMediumItem}>
              <div className={styles.energyMediumName}>{item.energyMediumName}</div>
              <div>
                {item.devices?.map(device => (
                  <Tag
                    border="solid"
                    color="info"
                    onClick={() => {
                      window.open(
                        `/data/history/device?deviceId=${device.id}&dataPropertyId=${device.dataPropertyId}`,
                        '_blank'
                      );
                    }}
                  >
                    {device.name}
                  </Tag>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default DeviceDetailModal;
