import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import { getRealUrl, Button, Tooltip, Modal } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteScada, exportScada, ScadaPageRes } from '../../../api/scada';
import styles from '../index.module.scss';
import ModalComponent from './ModalComponent';
import { PermissionsType } from '../../../common/permissionsConst';

interface CardProps {
  data: ScadaPageRes;
  onCancel: (notGotoFirstPage?: boolean) => void;
  permission?: string[];
  isCardMode?: boolean;
}

const Card: FC<CardProps> = ({ data, onCancel, permission, isCardMode = true }) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const cancelAction = (notGotoFirstPage?: boolean) => {
    setIsEdit(false);
    setIsCopy(false);
    onCancel(notGotoFirstPage);
  };

  const ouNames = useMemo(() => {
    if (data.ous !== undefined) {
      const arr = data.ous.map(i => i.name);
      return arr.join('、');
    }
  }, [data]);

  const handleEdit = () => {
    if ((permission ?? []).includes(PermissionsType.P_UPDATESCADA)) {
      navigate(`/scada/${data.id}/edit`);
    }
  };

  const handleCopy = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setIsCopy(true);
    e.stopPropagation();
  };

  const handlePreview = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    window.open(`/scada/${data.id}`, '_blank');
    e.stopPropagation();
  };

  const handleExport = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    exportScada(data.id);
    e.stopPropagation();
  };

  const handleEditInfo = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setIsEdit(true);
    e.stopPropagation();
  };

  const handleDelete = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    Modal.confirm({
      title: '确定要删除该组态吗？删除后关联项目组态也会进行删除',
      okText: '确定',
      onOk: () => {
        deleteScada(data.id).then(data => cancelAction(true));
      },
    });
  };

  const handleOu = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    Modal.info({
      title: '所属运营单元:',
      width: 600,
      content: <p>{ouNames}</p>,
      okText: '好的',
    });
    e.stopPropagation();
  };

  return (
    <>
      {isCardMode ? (
        <div className={styles.card} onClick={handleEdit}>
          <div className={styles.top}>
            <img src={data.thumbnail ? getRealUrl(data.thumbnail) : ''} className={styles.img} alt="" />
            <div className={styles.icons}>
              {(permission ?? []).includes(PermissionsType.B_COPYSCADA) && (
                <Tooltip title="复制">
                  <PlusSquareOutlined onClick={handleCopy} />
                </Tooltip>
              )}
              <Tooltip title="预览">
                <PlaySquareOutlined onClick={handlePreview} />
              </Tooltip>
              {(permission ?? []).includes(PermissionsType.B_EXPORTSCADA) && (
                <Tooltip title="导出">
                  <CloudDownloadOutlined onClick={handleExport} />
                </Tooltip>
              )}
              {(permission ?? []).includes(PermissionsType.B_UPDATESCADA) && (
                <Tooltip title="编辑">
                  <EditOutlined onClick={handleEditInfo} />
                </Tooltip>
              )}
              {(permission ?? []).includes(PermissionsType.B_DELETESCADA) && (
                <Tooltip title="删除">
                  <DeleteOutlined onClick={handleDelete} />
                </Tooltip>
              )}
            </div>
          </div>
          <div className={styles.content}>
            <p className={styles.name}>{data.name}</p>
            <Row>
              <Col flex="auto" style={{ width: 0 }}>
                <p className={styles.ou}>所属运营单元: {ouNames}</p>
              </Col>
              <Col flex="90px">
                <Button className={styles.btn} type="primary" onClick={handleOu}>
                  查看所属运营单元
                </Button>
              </Col>
            </Row>
            <p className={styles.ou}>{dayjs(data.updateTime).format('YYYY-MM-DD')}</p>
          </div>
        </div>
      ) : (
        <Space size={16}>
          {
            <Button type="link" onClick={handlePreview}>
              预览
            </Button>
          }
          {(permission ?? []).includes(PermissionsType.P_UPDATESCADA) && (
            <Button type="link" onClick={handleEdit}>
              编辑组态
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_UPDATESCADA) && (
            <Button type="link" onClick={handleEditInfo}>
              编辑基本信息
            </Button>
          )}
          <Button type="link" onClick={handleOu}>
            查看所属运营单元
          </Button>
          {(permission ?? []).includes(PermissionsType.B_EXPORTSCADA) && (
            <Button type="link" onClick={handleExport}>
              导出
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_COPYSCADA) && (
            <Button type="link" onClick={handleCopy}>
              复制
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_DELETESCADA) && (
            <Button type="link" style={{ color: 'var(--error-color)' }} onClick={handleDelete}>
              删除
            </Button>
          )}
        </Space>
      )}
      {isCopy && <ModalComponent title="将组态复制到" isVisible isCopy onCancel={cancelAction} id={data.id} />}
      {isEdit && <ModalComponent title="编辑基本信息" isVisible isEdit onCancel={cancelAction} id={data.id} />}
    </>
  );
};

export default Card;
