import { EnergyMediumTreeFormatType } from '@/api/energyMedium';
import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeLabelColor,
  ItemPort,
  UetEMTPreviewResponse,
} from '@/api/uet';
import G6, { Graph, Item } from '@antv/g6';
import React, { FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import { basicConfig, EnergyWorkingProcessPermissions, formatNodesAndEdges } from './basicConfig';
import styles from './index.module.scss';
import { useSize } from 'ahooks';
import { Button } from 'antd';
import { getRealUrl } from '@maxtropy/components';
import { AlarmStatusType } from '../ViewEnergyMediumTopo';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  apiV2MediumIndicatorDashboardGetProcessStatusPost,
  apiV2UetEmtPreviewDashboardPost,
  V2MediumIndicatorDashboardGetProcessStatusPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

export interface IViewProcessTopo {
  energyMediumTopoId?: number;
  processId?: number;
  type?: EnergyMediumTreeFormatType;
  getKeyAndInfo?: (key: Key) => void;
  id?: number;
  noMonitorData?: boolean;
}

export const pollingInterval = 1000 * 60 * 1; // 1分钟轮询一次

const ViewProcessTopo: FC<IViewProcessTopo> = props => {
  const { energyMediumTopoId, processId, type, getKeyAndInfo, id, noMonitorData } = props;
  const timerRef = useRef<number>();
  const [topoPreviewData, setTopoPreviewData] = useState<UetEMTPreviewResponse>();
  const graph = useRef<Graph>(); // graph图的实例
  const [itemNodeState, setItemNodeState] = useState<Item>();
  const [alarmStatus, setAlarmStatus] = useState<V2MediumIndicatorDashboardGetProcessStatusPostResponse['list']>();

  const ref = useRef<HTMLDivElement>(); // ref考虑自适应问题
  const size = useSize(ref);

  const [topoCanvasLoading, setTopoCanvasLoading] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      if (graph.current) {
        graph.current.clear();
        graph.current.destroy();
        setItemNodeState(undefined);
        graph.current = undefined;
        window.clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (noMonitorData && graph.current) {
      graph.current.clear();
      graph.current.destroy();
      setItemNodeState(undefined);
      graph.current = undefined;
      window.clearTimeout(timerRef.current);
    }
  }, [noMonitorData]);

  // 自适应dom的变化
  useEffect(() => {
    if (graph.current && size) {
      graph.current.changeSize(size.width, size.height);
    }
  }, [size]);

  const { loading: alarmLoading } = useRequest(
    () => {
      return apiV2MediumIndicatorDashboardGetProcessStatusPost({ id: energyMediumTopoId!.toString() }).then(res =>
        setAlarmStatus(res.list)
      );
    },
    {
      pollingInterval,
      refreshDeps: [energyMediumTopoId],
      ready: !!energyMediumTopoId,
    }
  );

  const getAlarmTopoShape = (i: { processId?: number; alarmStatus?: number | string }) => {
    const itemNode = graph.current!.findById(i.processId!.toString());
    const group = itemNode!.getContainer();
    const imageShape = group.get('children')[group.get('children').length - 4];
    const textShape = group.get('children')[group.get('children').length - 5];
    const animate1Shape = group.get('children')[group.get('children').length - 1];
    const animate2Shape = group.get('children')[group.get('children').length - 2];
    const animate3Shape = group.get('children')[group.get('children').length - 3];
    return { imageShape, animate1Shape, animate2Shape, animate3Shape, textShape };
  };

  useEffect(() => {
    if (!topoCanvasLoading && !alarmLoading && alarmStatus) {
      alarmStatus.forEach(i => {
        if (!isNil(i?.alarmStatus) && (i.alarmStatus as unknown as AlarmStatusType) === AlarmStatusType.UNABLE) {
          const { imageShape, animate1Shape, animate2Shape, animate3Shape, textShape } = getAlarmTopoShape(i);
          imageShape.attr('opacity', 1);
          textShape.attr('fill', '#FF4D4F');
          animate1Shape.attr('fill', 'red');
          animate2Shape.attr('fill', 'red');
          animate3Shape.attr('fill', 'red');
        } else {
          const { imageShape, animate1Shape, animate2Shape, animate3Shape } = getAlarmTopoShape(i);
          imageShape.attr('opacity', 0);
          animate1Shape.attr('fill', 'transparent');
          animate2Shape.attr('fill', 'transparent');
          animate3Shape.attr('fill', 'transparent');
        }
      });
    }
  }, [topoCanvasLoading, alarmLoading, alarmStatus]);

  // 查询拓扑图
  useEffect(() => {
    if (graph.current) {
      graph.current.destroy();
      graph.current.clear();
      setItemNodeState(undefined);
      graph.current = undefined;
      window.clearTimeout(timerRef.current);
    }
    if (energyMediumTopoId) {
      setTopoCanvasLoading(true);
      apiV2UetEmtPreviewDashboardPost({ id: +energyMediumTopoId }).then(res => {
        const resTemp = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < (resTemp.nodes ?? []).length; i++) {
          resTemp.nodes[i].info!.pictureKey = getRealUrl(resTemp.nodes?.[i].info?.pictureKey);
        }
        setTopoPreviewData(resTemp);
      });
    }
  }, [energyMediumTopoId]);

  const formatData = useMemo(() => {
    // 对返回数据进行处理
    if (topoPreviewData) {
      return formatNodesAndEdges(topoPreviewData);
    }
  }, [topoPreviewData]);

  useEffect(() => {
    if (formatData) {
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        className: 'tooltip',
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node'],
        // 自定义 tooltip 内容
        getContent: e => {
          const outDiv = document.createElement('div');
          outDiv.style.width = 'fit-content';
          outDiv.style.zIndex = '99';
          outDiv.style.position = 'relative';
          outDiv.style.color = 'black';
          outDiv.style.backgroundColor = '#fff';
          outDiv.style.borderRadius = '3px';
          outDiv.innerHTML = `
            <h4>无权限</h4>
            `;
          return outDiv;
        },
        shouldBegin: e => {
          const item: any = e?.item?.getModel();
          let res = false;
          if (item.info.permissions === EnergyWorkingProcessPermissions.UNABLED) {
            res = true;
          }
          return res;
        },
      });
      // 创建实例
      graph.current = new G6.Graph(basicConfig(tooltip));
      // 读取数据
      graph.current.data(formatData);
      graph.current.fitView([12]);
      graph.current.render();

      // 绑定事件
      // graph.current.on('nodeselectchange', ev => {
      //   const getNodes = (ev.selectedItems as any).nodes;
      //   if (getNodes.length) {
      //     const itemDto: any = getNodes[0].getModel();
      //     if (itemDto && itemDto.info.permissions === EnergyWorkingProcessPermissions.ENABLED) {
      //       console.log(itemDto.id + 'process');
      //       getKeyAndInfo?.(itemDto.id + 'process');
      //     }
      //   }
      // });
      graph.current.on(`node:click`, e => {
        if (e.shape.cfg.name.includes('Keyshape') || e.shape.cfg.name.includes('imageShape')) {
          const itemDto = e.item?._cfg?.model as any;
          if (itemDto && itemDto.info.permissions === EnergyWorkingProcessPermissions.ENABLED) {
            getKeyAndInfo?.(itemDto.id + 'process');
          }
        }
      });

      const rectEntryIds: number[] = formatData.nodes
        .map((c: any) => (c.entryVos ?? []).filter((i: ItemPort) => i.hasDevice))
        .flat()
        .map((x: ItemPort) => x.id);
      const rectExitIds: number[] = formatData.nodes
        .map((c: any) => (c.exitVos ?? []).filter((i: ItemPort) => i.hasDevice))
        .flat()
        .map((x: ItemPort) => x.id);
      // 监听mouseenter事件
      rectEntryIds.forEach(id => {
        graph.current!.on(`${id}entry:mouseenter`, e => {
          e.target.attr('stroke', '#fff');
        });
        graph.current!.on(`${id}entry:click`, e => {
          getKeyAndInfo?.(id + 'entry');
        });
      });
      rectExitIds.forEach(id => {
        graph.current!.on(`${id}exit:mouseenter`, e => {
          e.target.attr('stroke', '#fff');
        });
        graph.current!.on(`${id}exit:click`, e => {
          getKeyAndInfo?.(id + 'exit');
        });
      });

      // 监听mouseleave事件
      rectEntryIds.forEach(id => {
        graph.current!.on(`${id}entry:mouseleave`, e => {
          e.target.attr('stroke', 'transparent');
        });
      });
      rectExitIds.forEach(id => {
        graph.current!.on(`${id}exit:mouseleave`, e => {
          e.target.attr('stroke', 'transparent');
        });
      });

      setEnergyMediumBackId(energyMediumTopoId);
      setTopoCanvasLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatData]);

  // 选择两次不同拓扑的对比
  const [energyMediumBackId, setEnergyMediumBackId] = useState<number | undefined>(energyMediumTopoId);

  // 过程与过程之间的切换
  useEffect(() => {
    if (
      processId &&
      !topoCanvasLoading &&
      topoPreviewData &&
      graph.current &&
      energyMediumBackId === energyMediumTopoId
    ) {
      // 清除所有
      itemNodeState && graph.current.clearItemStates(itemNodeState);
      const itemNode = graph.current.findById(processId.toString());
      setItemNodeState(itemNode);
      if (
        topoPreviewData.nodes.find(i => i.id === processId)?.info?.permissions ===
        EnergyWorkingProcessPermissions.UNABLED
      ) {
        graph.current.setItemState(itemNode, 'selected', false);
      } else {
        graph.current.setItemState(itemNode, 'selected', true);
        graph.current.fitView([12]);
        graph.current.focusItem(itemNode, true, {
          easing: 'easeCubic',
          duration: 500,
        });
        timerRef.current = window.setTimeout(() => {
          const canvasWidth = graph.current!.getWidth();
          const canvasHeight = graph.current!.getHeight();
          graph.current!.zoomTo(0.9, { x: canvasWidth / 2, y: canvasHeight / 2 }, true, {
            duration: 100,
          });
        }, 650);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoCanvasLoading, processId, energyMediumTopoId]);

  const backToTopo = () => {
    energyMediumTopoId && getKeyAndInfo?.(energyMediumTopoId + 'topo');
  };

  const lightEntryOrExitIndex = useMemo(() => {
    if (id && processId && !topoCanvasLoading) {
      const findIndex = topoPreviewData?.nodes.findIndex(i => i.id === processId);
      return (topoPreviewData?.nodes[findIndex!]?.entryVos ?? [])
        .map(c => c.id)
        ?.concat((topoPreviewData?.nodes[findIndex!]?.exitVos ?? []).map(c => c.id))
        .findIndex(i => i === id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoCanvasLoading, processId, id]);

  // 重置每个进出口亮点
  const initEntryAndExitLight = () => {
    const nodes = graph.current!.getNodes();
    for (let i = 0; i < nodes.length; i++) {
      const groups = nodes[i].getContainer().get('children');
      const nodeInfo: any = nodes[i].getModel();
      for (let j = 1; j < groups.length - 6; j++) {
        if (groups[j]?.cfg?.type === 'circle') {
          groups[j].attr('fill', '#979797');
        } else {
          groups[j].attr('fill', EnergyWorkingProcessTypeLabelColor[nodeInfo.info.type as EnergyWorkingProcessType]);
        }
      }
    }
  };

  // 显示进出口亮点
  useEffect(() => {
    if (id && !topoCanvasLoading && processId && topoPreviewData && graph.current) {
      initEntryAndExitLight();
      const itemNode = graph.current!.findById(processId.toString());
      if (type === EnergyMediumTreeFormatType.ENTRY || type === EnergyMediumTreeFormatType.EXIT) {
        const group = itemNode.getContainer();
        group.get('children')[lightEntryOrExitIndex! + 1].attr('fill', '#fff');
      }
      if (type === EnergyMediumTreeFormatType.NODEINSIDE) {
        graph.current.setItemState(itemNode, 'selected', true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoCanvasLoading, lightEntryOrExitIndex, processId, type, id]);

  return (
    <>
      <div
        className={styles.topoProcess_container}
        ref={d => {
          if (d) {
            ref.current = d;
          }
        }}
      >
        <div
          className={styles.bgImg}
          style={{ width: '100%', height: '100%', backgroundColor: '#232324' }}
          id="mountNode"
        ></div>
      </div>
      <Button
        type="link"
        onClick={() => backToTopo()}
        style={{
          position: 'absolute',
          right: 20,
          top: 20,
          zIndex: 2,
        }}
      >
        返回拓扑
        <DoubleRightOutlined className={styles.translationStyles} />
      </Button>
    </>
  );
};

export default ViewProcessTopo;
