import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import TimeRangePicker from './TimeRangePicker';
import { apiV2WorkShiftCreatePost, apiV2WorkShiftUpdatePost } from '@maxtropy/device-customer-apis-v2';
import { WorkShiftsItem } from '../ShiftCalendar/util';
interface Iprops {
  cancel?: () => void;
  confirm?: () => void;
  op?: string;
  row?: WorkShiftsItem;
}

const timeToMinutes = (time: string) => {
  const [hour, minute] = time.split(':').map(Number);
  return hour * 60 + minute;
};

function isNextDay(endTime: string) {
  const [hour, minute] = endTime.split(':').map(Number);
  return hour >= 24 ? (hour - 24) * 60 + minute : null;
}

const ShiftOpsModal: React.FC<Iprops> = ({ op = 'add', cancel, confirm, row }) => {
  const opTitle = useMemo(() => {
    return op === 'add' ? '新增班次' : '编辑班次';
  }, [op]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (op === 'edit' && row) {
      let timeSegArr = (row.timeSeg as string).split(';');
      let timeData = timeSegArr.map(item => ({
        timeSeg: item.split('-'),
      }));
      form.setFieldsValue({
        name: row.name,
        timeSegs: timeData,
      });
    }
  }, [op, row, form]);

  const confirmBtn = () => {
    const opApi = op === 'add' ? apiV2WorkShiftCreatePost : apiV2WorkShiftUpdatePost;

    form.validateFields().then(res => {
      setLoading(true);
      const body = {
        name: res.name?.trim(),
        timeSeg: res.timeSegs.map((item: { timeSeg: any[] }) => item.timeSeg.join('-')),
      };
      opApi(op === 'add' ? body : { ...body, workShiftId: row?.id })
        .then(_ => confirm?.())
        .finally(() => setLoading(false));
    });
  };
  // 班次时间是否存在班次日历中, 若存在,禁止编辑
  const editDisabled = useMemo(() => row?.inUse && op === 'edit', [row, op]);
  return (
    <>
      <Modal
        open
        title={opTitle}
        size="normal"
        onCancel={() => cancel?.()}
        footer={
          <Space size={10}>
            <Button onClick={() => cancel?.()}>取消</Button>
            <Button loading={loading} type="primary" onClick={confirmBtn}>
              确定
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          initialValues={{
            timeSegs: [{ timeSeg: [] }],
          }}
        >
          <Row>
            <Col span={23}>
              <Form.Item
                label="班次名称"
                name="name"
                rules={[
                  { required: true, message: '' },
                  {
                    validator: async (_, value) => {
                      if (!value || !value.trim()) {
                        throw new Error('班次名称不能为空');
                      }
                      //不允许\、/、:、*、?、"、<、>、|字符。
                      let regEn = /[\\<>*?:|"/]+/im;
                      if (regEn.test(value)) {
                        throw new Error('班次名称不能包含\\、/、:、*、?、"、<、>、|字符');
                      }
                    },
                  },
                ]}
              >
                <Input maxLength={15} placeholder="请输入"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="timeSegs">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  // 互相添加依赖 [['timeSegs', 0, 'timeSeg']]
                  const dependencies = fields
                    .filter(item => item.name !== name)
                    .map(a => ['timeSegs', a.name, 'timeSeg']);
                  return (
                    <Row key={index} gutter={16}>
                      <Col span={23}>
                        <Form.Item
                          dependencies={dependencies}
                          {...restField}
                          label={name === 0 ? '班次时间' : <span style={{ display: 'none' }}>none</span>}
                          colon={name === 0}
                          name={[name, 'timeSeg']}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator: async (_, value) => {
                                if (!value || value.length === 0) {
                                  throw new Error('请选择班次时间范围');
                                }
                                // 校验时间段是否有重叠, 只在最后一行显示error信息
                                if (name === fields.length - 1) {
                                  let collects = getFieldValue('timeSegs').filter((f: any) => f);

                                  let mapsNames = collects.map((item: any) => item.timeSeg);
                                  let strTimes = mapsNames.map((item: string[]) => item.join('-'));

                                  console.log('mmm', strTimes);

                                  const segments = strTimes.map((segment: string) => {
                                    const [start, end] = segment.split('-');
                                    const startMinutes = timeToMinutes(start);
                                    let endMinutes = timeToMinutes(end);

                                    // 如果结束时间超过24小时，将其视为次日的时间
                                    if (endMinutes >= 1440) {
                                      endMinutes = isNextDay(end) !== null ? 1440 + isNextDay(end)! : endMinutes;
                                    }

                                    return { start: startMinutes, end: endMinutes };
                                  });

                                  // 排序时间段，按开始时间排序
                                  segments.sort((a: { start: number }, b: { start: number }) => a.start - b.start);

                                  // 检查重叠
                                  for (let i = 1; i < segments.length; i++) {
                                    if (segments[i].start < segments[i - 1].end) {
                                      throw new Error('班次时间范围有重叠, 请重新选择');
                                    }
                                  }

                                  // 检查最晚结束时间和最早开始时间是否超过24小时
                                  const earliestStart = segments[0].start;
                                  const latestEnd = segments.reduce(
                                    (max: number, segment: any) => Math.max(max, segment.end),
                                    0
                                  );

                                  const exceeds24Hours = latestEnd - earliestStart > 1440; // 1440分钟 = 24小时
                                  if (exceeds24Hours) {
                                    throw new Error('班次时间范围不能超过24小时, 请重新选择');
                                  }
                                }
                              },
                            }),
                          ]}
                        >
                          <TimeRangePicker disabled={editDisabled} />
                        </Form.Item>
                      </Col>
                      {!editDisabled && (
                        <Col span={1}>
                          {name > 0 && <MinusCircleOutlined style={{ padding: 0 }} onClick={() => remove(name)} />}
                        </Col>
                      )}
                    </Row>
                  );
                })}
                <Row>
                  <Col span={23}>
                    <div style={{ marginLeft: 136 }}>
                      <Button
                        type="dashed"
                        disabled={fields.length >= 3 || editDisabled}
                        ghost
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                      >
                        新增班次时间
                      </Button>
                      {op === 'edit' && (
                        <div className={styles.tips}>
                          <ExclamationCircleOutlined style={{ color: '#C29801' }} />
                          &nbsp;班次在班次日历后不可编辑班次时间
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ShiftOpsModal;
