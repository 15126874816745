export const borderStyle = {
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'rgba(var(--base-text-color), 0.15)',
  padding: '20px 15px 0 15px',
};

// 单位
export const units = {
  千伏安: 'kVA',
  千瓦: 'kW',
  千瓦时: 'kWh',
  千乏时: 'kvarh',
  元: '元',
  '元/千伏安': '元/kVA',
  '元/千瓦': '元/kW',
  '元/千瓦时': '元/kWh',
};
