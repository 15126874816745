import {
  message,
  Steps,
  TreeSelect,
  Select,
  InputNumber,
  Radio,
  Input,
  Form,
  Upload,
  Button,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, FormListFieldData, Row, Space, TreeSelectProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import { InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { formatLevelOptions, getDepth } from '../utils';
import { EnergyOverviewConfigBody } from '@/api/energyOverview';
import { difference, flatten, isNil } from 'lodash-es';
import { OuListResponse } from '@/api/ou';
import { useWatch } from 'antd/es/form/Form';
import { getOrganizationWithCodeWithCurrent } from '@/api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import {
  apiV2EnergyAnalysisListByOuPost,
  V2EnergyAnalysisListPostResponse,
  apiV2EnergyConsumptionOverviewMediumDataPropertyPost,
} from '@maxtropy/device-customer-apis-v2';
import { MediumDataPropertyItem } from '@/pages/EnergyAnalysisOptimize/components/CreateAnalysis';
export interface Props {
  organization: DefaultOptionType;
  field: FormListFieldData;
  isDetail: boolean;
  detail?: EnergyOverviewConfigBody[];
  ou: OuListResponse[];
  setAllTitle: (v: string[]) => void;
  allTitle: string[];
  getEnergyList: (energyList: MediumDataPropertyItem[]) => void;
}
export type AnalysisUnit = Exclude<V2EnergyAnalysisListPostResponse['list'], undefined>[number];

function formatToNodeData(data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
      // disabled: i.disabled,
    };
  });
}

function getAllFirstTreeNode(tree: TreeSelectProps['treeData']) {
  if (tree && tree.length > 0) {
    const res: TreeSelectProps['treeData'] = [];
    tree.forEach(i => {
      // if (!i.disabled) {
      res.push({
        title: i.title,
        value: i.value,
        // disabled: i.disabled,
      });
      // }
    });
    return res;
  }
}

function formatTreeData(data: any[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
}

const dataList: { value: React.Key; title: string }[] = [];

const generateList = (data?: TreeSelectProps['treeData']) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { value, title } = node;
      dataList.push({ value: value as React.Key, title: title as string });
      if (node.children) {
        generateList(node.children);
      }
    }
  }
};

// 获取所属组织的key 用来和详情的组织key作比较
const flattenTree = (tree: any, targetKey: string) => {
  const result: any = [];

  function traverse(node: any) {
    if (node[targetKey]) {
      result.push(node[targetKey]);
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        traverse(child);
      }
    }
  }

  for (const node of tree) {
    traverse(node);
  }

  return result;
};

const fileSize = 3 * 1024 * 1024;

const EnergyOverviewConfig: React.FC<Props> = ({
  organization,
  field,
  isDetail = false,
  detail = undefined,
  ou = [],
  setAllTitle,
  allTitle = [],
  getEnergyList,
}) => {
  const [currentDot, setCurrentDot] = useState<number>(0); // 当前步骤
  const [stepLength, setStepLength] = useState<number>(4); // 总步骤

  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);
  const [maxDepth, setMaxDepth] = useState<number>(0); // 最大深度

  const form = Form.useFormInstance();
  const energyAnalysisUnitGroupId = Form.useWatch(['configs', field.name, 'energyAnalysisUnitGroupId'], form);
  const energyUnits = Form.useWatch(['configs', field.name, 'energyUnits'], form);
  const energyFlowLevel1 = Form.useWatch(['configs', field.name, 'energyFlowLevel1'], form);
  const energyFlowLevel2 = Form.useWatch(['configs', field.name, 'energyFlowLevel2'], form);
  const imgConfig = Form.useWatch(['configs', field.name, 'imgConfig'], form);
  const configs = Form.useWatch('configs', form);
  const title = useWatch(['configs', field.name, 'title'], form);
  const planSource = Form.useWatch(['configs', field.name, 'planSource'], form);

  const [imgValue, setImgValue] = useState<string>();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [energyList, setEnergyList] = useState<MediumDataPropertyItem[]>([]);

  const [currentDetail, setCurrentDetail] = useState<EnergyOverviewConfigBody>();

  // 所属组织数据
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  // 用能分析组
  const [unitGroup, setUnitGroup] = useState<AnalysisUnit[]>();
  // 所属组织为空，总用能配置置灰  负荷/流量配置置灰
  const [organizationDisable, setOrganizationDisable] = useState<boolean>(false);
  const [allOrganization, setAllOrganization] = useState<string[]>([]);

  const twelveMonths = [
    { label: '1月', name: [field.name, 'januaryPlan'] },
    { label: '2月', name: [field.name, 'februaryPlan'] },
    { label: '3月', name: [field.name, 'marchPlan'] },
    { label: '4月', name: [field.name, 'aprilPlan'] },
    { label: '5月', name: [field.name, 'mayPlan'] },
    { label: '6月', name: [field.name, 'junePlan'] },
    { label: '7月', name: [field.name, 'julyPlan'] },
    { label: '8月', name: [field.name, 'augustPlan'] },
    { label: '9月', name: [field.name, 'septemberPlan'] },
    { label: '10月', name: [field.name, 'octoberPlan'] },
    { label: '11月', name: [field.name, 'novemberPlan'] },
    { label: '12月', name: [field.name, 'decemberPlan'] },
  ];

  // 批量 设置 form 字段值
  const batchSetFormFieldValue = (fieldNameList: string[], value: any[]) => {
    fieldNameList.forEach((fieldName, index) => form.setFieldValue(['configs', field.name, fieldName], value[index]));
  };

  useEffect(() => {
    if (detail && detail.length > 0) {
      const id = form.getFieldValue(['configs', field.name, 'id']);
      if (id) {
        const current = detail.find(i => i.id === id);
        setCurrentDetail(current);
        const stepLength = [current?.energyFlowLevel1, current?.energyFlowLevel2, current?.energyFlowLevel3].findIndex(
          i => isNil(i)
        );
        setImgValue(current?.picUrl);
        setCurrentDot(stepLength >= 0 ? stepLength : 3);
        setStepLength(stepLength >= 0 ? stepLength + 1 : 4);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const ouOptions = useMemo(() => {
    return (ou ?? []).map(item => ({ label: item.name, value: item.id }));
  }, [ou]);

  // 更新tab页标题
  useEffect(() => {
    if (title) {
      const newAllTitle = [...allTitle];
      newAllTitle[field.name] = title;
      setAllTitle(newAllTitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const unitGroupOption = useMemo(() => {
    return (unitGroup ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [unitGroup]);

  // 点击配置 所属组织更新 拿权限下的用能分析组
  useEffect(() => {
    const organizationItem = flatten(
      //已选的所属组织
      currentDetail?.customerMcidDtos?.map(item => {
        return item.mcid;
      })
    );

    const differenceArr = difference(organizationItem, allOrganization);

    const disabledOrg = (currentDetail?.customerMcidDtos ?? [])
      .map(item => {
        if (differenceArr.includes(item.mcid)) {
          return {
            key: item.mcid,
            value: item.mcid,
            title: item.name,
            children: [],
            disabled: true,
          };
        }
        return null;
      })
      .filter(i => i !== null);

    setTreeData([...(disabledOrg as DefaultOptionType[]), ...treeData]);

    const mcidsId = flattenTree(organization, 'key');
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDetail?.customerMcidDtos, organization, allOrganization]);

  // 查询所属组织
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
      setAllOrganization(flattenTree(formatTreeData([res]), 'key'));
    });
  }, []);

  useEffect(() => {
    if (energyAnalysisUnitGroupId) {
      getEnergyUnitTree(energyAnalysisUnitGroupId).then(res => {
        const tree = formatToNodeData(res.list || []);
        setTreeNodeData(tree);
        // 有detail不再默认选择
        if (!currentDetail) {
          // 默认选中所有一级节点
          batchSetFormFieldValue(['energyUnits'], [getAllFirstTreeNode(tree)]);
        }
        if (tree && tree.length > 0) {
          let depths = tree.map(x => getDepth(x));
          setMaxDepth(Math.max(...depths));
        }
        generateList(formatToNodeData(res.list ?? []));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyAnalysisUnitGroupId, currentDetail]);

  useEffect(() => {
    if (energyUnits && energyUnits.length > 0) {
      apiV2EnergyConsumptionOverviewMediumDataPropertyPost({ ids: energyUnits.map((i: any) => i.value) }).then(res => {
        setEnergyList((res.list ?? []) as MediumDataPropertyItem[]);
        getEnergyList?.((res.list ?? []) as MediumDataPropertyItem[]);
      });
    }
  }, [energyUnits]);

  const customerOnchange = (v: string[]) => {
    setOrganizationDisable(Boolean(!v.length));
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setUnitGroup((res.list ?? []) as AnalysisUnit[]);
    });
    setEnergyList([]);
    batchSetFormFieldValue(
      [
        'energyAnalysisUnitGroupId',
        'energyUnits',
        'energyFlowLevel1',
        'energyFlowLevel2',
        'energyFlowLevel3',
        'energy',
        // 'ouIds', 所属组织变更时 监控运营单元清空
      ],
      [undefined, undefined, undefined, undefined, undefined, undefined]
    );
  };

  const firstLevelOptions = useMemo(() => {
    return formatLevelOptions(maxDepth ?? [], 0);
  }, [maxDepth]);

  const secondLevelOptions = useMemo(() => {
    if (maxDepth > 0 && energyFlowLevel1) {
      return formatLevelOptions(maxDepth ?? [], energyFlowLevel1);
    }
    return [];
  }, [maxDepth, energyFlowLevel1]);

  const thirdLevelOptions = useMemo(() => {
    if (maxDepth > 0 && energyFlowLevel2 && energyFlowLevel1) {
      return formatLevelOptions(maxDepth, energyFlowLevel2);
    }
    return [];
  }, [maxDepth, energyFlowLevel2, energyFlowLevel1]);

  const onTitleInput = (v: string) => {
    if (configs && configs.length >= 2) {
      const otherConfigTitles = configs
        .map((i: any) => i.title)
        .filter((i: any, index: number) => index !== field.name);
      if (otherConfigTitles.includes(v)) {
        form.setFields([
          {
            name: ['configs', field.name, 'title'],
            errors: ['该标题已存在，请重新输入。'],
            validating: true,
          },
        ]);
        return;
      }
    }
  };

  const defaultStep = [
    {
      description: (
        <Col span={20}>
          <Form.Item
            label="总用能"
            name={[field.name, 'energyUnits']}
            extra={
              <>
                <Space>
                  <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                  选择多个用能单元，加总后为总用能
                </Space>
              </>
            }
            rules={[
              {
                required: true,
                message: '总用能',
              },
            ]}
            style={{
              paddingLeft: 40,
            }}
          >
            <TreeSelect
              treeData={treeNodeData}
              disabled={!energyAnalysisUnitGroupId || isDetail || organizationDisable}
              treeCheckStrictly
              treeCheckable
              showCheckedStrategy="SHOW_ALL"
              treeDefaultExpandAll
              placeholder="请选择"
              style={{
                width: '100%',
              }}
              onClick={() => (isDetail ? null : setCurrentDot(0))}
            />
          </Form.Item>
        </Col>
      ),
    },
    {
      description: (
        <Row>
          <Col span={20}>
            <Form.Item
              label="能流层级1"
              name={[field.name, 'energyFlowLevel1']}
              rules={[
                {
                  required: true,
                  message: '请选择能流层级1',
                },
              ]}
              style={{
                paddingLeft: 20,
              }}
            >
              <Select
                placeholder="请选择"
                options={firstLevelOptions}
                disabled={!energyUnits || isDetail || organizationDisable}
                onChange={() => {
                  batchSetFormFieldValue(['energyFlowLevel2', 'energyFlowLevel3'], [undefined, undefined]);
                }}
                style={{
                  width: '100%',
                }}
                onClick={() => (isDetail ? null : setCurrentDot(1))}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            {stepLength === 2 && (
              <Space>
                {isDetail ? null : (
                  <Button
                    type="link"
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      setStepLength(3);
                    }}
                  />
                )}
              </Space>
            )}
          </Col>
        </Row>
      ),
    },
    {
      description: (
        <Row>
          <Col span={20}>
            <Form.Item
              label="能流层级2"
              name={[field.name, 'energyFlowLevel2']}
              rules={[
                {
                  required: true,
                  message: '请选择能流层级2',
                },
              ]}
              style={{
                paddingLeft: 20,
              }}
            >
              <Select
                placeholder="请选择"
                options={secondLevelOptions}
                disabled={!energyFlowLevel1 || isDetail || organizationDisable}
                onChange={() => {
                  batchSetFormFieldValue(['energyFlowLevel3'], [undefined]);
                }}
                style={{
                  width: '100%',
                }}
                onClick={() => (isDetail ? null : setCurrentDot(2))}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            {stepLength === 3 && (
              <Space>
                {isDetail ? null : (
                  <>
                    <Button
                      type="link"
                      shape="circle"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        setStepLength(4);
                      }}
                    />
                    <Button
                      type="link"
                      shape="circle"
                      icon={<MinusCircleOutlined />}
                      onClick={() => {
                        setStepLength(2);
                        batchSetFormFieldValue(['energyFlowLevel2'], [undefined]);
                      }}
                    />
                  </>
                )}
              </Space>
            )}
          </Col>
        </Row>
      ),
    },
    {
      description: (
        <Row>
          <Col span={20}>
            <Form.Item
              label="能流层级3"
              name={[field.name, 'energyFlowLevel3']}
              rules={[
                {
                  required: true,
                  message: '请选择能流层级3',
                },
              ]}
              style={{
                paddingLeft: 20,
              }}
            >
              <Select
                placeholder="请选择"
                options={thirdLevelOptions}
                disabled={!energyFlowLevel2 || isDetail || organizationDisable}
                onChange={() => {}}
                style={{
                  width: '100%',
                }}
                onClick={() => (isDetail ? null : setCurrentDot(3))}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            {stepLength === 4 && (
              <Space>
                {isDetail ? null : (
                  <Button
                    type="link"
                    shape="circle"
                    icon={<MinusCircleOutlined />}
                    onClick={() => {
                      setStepLength(3);
                      batchSetFormFieldValue(['energyFlowLevel3'], [undefined]);
                    }}
                  />
                )}
              </Space>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className={styles.singleConfig}>
      <FormTitle title="用能总览配置" />
      <SubContent title="名称配置">
        <div style={{ width: 700 }}>
          <Form.Item
            label="总览标题"
            name={[field.name, 'title']}
            rules={[
              {
                required: true,
                message: '请输入总览标题',
              },
              {
                max: 15,
              },
            ]}
            initialValue={'精益能源管理驾驶舱'}
          >
            <Input disabled={isDetail} placeholder="请输入" onChange={v => onTitleInput(v.target.value)} />
          </Form.Item>
          <Form.Item
            label="所属组织"
            name={[field.name, 'customerMcids']}
            rules={[
              {
                required: true,
                message: '请选择所属组织',
              },
            ]}
          >
            <TreeSelect
              showCheckedStrategy={'SHOW_ALL'}
              disabled={isDetail}
              placeholder="请选择"
              treeData={treeData}
              treeDefaultExpandAll
              multiple
              onChange={customerOnchange}
            />
          </Form.Item>
        </div>
      </SubContent>
      <SubContent title="总用能配置">
        <div style={{ width: 700 }}>
          <Form.Item
            label="用能分析组"
            name={[field.name, 'energyAnalysisUnitGroupId']}
            rules={[
              {
                required: true,
                message: '请选择用能分析组',
              },
            ]}
          >
            <Select
              placeholder="请选择"
              options={unitGroupOption}
              onChange={() => {
                batchSetFormFieldValue(
                  ['energyUnits', 'energyFlowLevel1', 'energyFlowLevel2', 'energyFlowLevel3', 'energy'],
                  [undefined, undefined, undefined, undefined, undefined]
                );
                setEnergyList([]);
                batchSetFormFieldValue(['energy'], [{ 2030: 10017 }]); // 2030 为电 10017 为总有功功率
                setCurrentDetail(undefined);
                setStepLength(4);
                setCurrentDot(0);
              }}
              disabled={isDetail || organizationDisable}
            />
          </Form.Item>
        </div>

        <div style={{ marginLeft: 40, width: 785 }}>
          <Steps
            progressDot
            current={currentDot}
            direction="vertical"
            items={
              stepLength === 4
                ? defaultStep
                : stepLength === 3
                ? defaultStep.slice(0, 3)
                : stepLength === 2
                ? defaultStep.slice(0, 2)
                : undefined
            }
          />
        </div>
      </SubContent>
      <SubContent title="用能计划配置">
        <Form.Item name={[field.name, 'planSource']} initialValue={1}>
          <Radio.Group disabled={isDetail}>
            <Radio value={1}>自定义设置</Radio>
            <Radio value={2}>平台数据</Radio>
          </Radio.Group>
        </Form.Item>
        {planSource === 1 && (
          <>
            <Form.Item name={[field.name, 'yearPlan']} label="设定本年计划">
              <InputNumber
                disabled={isDetail}
                placeholder="请输入"
                min={0}
                max={99999999}
                precision={2}
                addonAfter="kgce"
              />
            </Form.Item>
            <Row>
              {twelveMonths.map((i, index) => {
                return (
                  <Col span={6} key={index}>
                    <Form.Item labelAlign="right" labelCol={{ span: 3 }} label={i.label} name={i.name}>
                      <InputNumber
                        disabled={isDetail}
                        placeholder="请输入"
                        min={0}
                        max={99999999}
                        precision={2}
                        addonAfter="kgce"
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </SubContent>
      <SubContent title="负荷/流量数据属性配置">
        {energyList.length > 0 &&
          energyList.map(i => (
            <div style={{ width: 700 }} key={i.energyNameId}>
              <Form.Item
                initialValue={i.energyName === '电能' ? 10017 : null} // 2030 为电，10017 为总有功功率
                label={i.energyName}
                name={[field.name, 'energy', (i.energyNameId as number).toString()]}
              >
                <Select
                  allowClear
                  options={(i.dataPropertyList ?? []).map(item => ({
                    label: item.dataPropertyName,
                    value: item.dataPropertyId,
                  }))}
                  disabled={isDetail || organizationDisable}
                  placeholder="请选择"
                />
              </Form.Item>
            </div>
          ))}
      </SubContent>
      <SubContent title="中心展示图配置">
        <Col span={12}>
          <Form.Item initialValue={0} name={[field.name, 'imgConfig']}>
            <Radio.Group disabled={isDetail}>
              <Radio value={0}>默认设置</Radio>
              <Radio value={1}>自定义图片</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        {imgConfig === 1 && (
          <Col span={12}>
            <Form.Item name={[field.name, 'picUrl']}>
              <ImgCrop
                aspect={16 / 9}
                maxZoom={10}
                // minZoom={-10}
                // cropShape="round"
                beforeCrop={v => {
                  const { name } = v;
                  const accept = '.gif,.png,.jpg';
                  const suffix = name.split('.')[name.split('.').length - 1];
                  const limitFileType = accept ? accept.includes(suffix.toLocaleLowerCase()) : true;

                  if (v.size > fileSize) {
                    messageApi.error('上传文件的大小不得超过3M');
                    return false;
                  }
                  if (!limitFileType) {
                    messageApi.error(`请上传正确的文件格式(${accept})`);
                    return false;
                  }
                  return true;
                }}
                onModalOk={(v: any) => {
                  const formData = new FormData();
                  formData.append('file', v);
                  fetch('/api/file-center/upload', {
                    method: 'POST',
                    body: formData,
                  })
                    .then(res => res.json())
                    .then(v => {
                      setImgValue(v.key);
                      batchSetFormFieldValue(['picUrl'], [v.key]);
                    });
                }}
              >
                <Upload
                  value={imgValue}
                  onRemove={() => {
                    setImgValue(undefined);
                    batchSetFormFieldValue(['picUrl'], [undefined]);
                  }}
                  fileSize={3}
                  accept={['.gif', '.png', '.jpg']}
                  disabled={isDetail}
                />
              </ImgCrop>
            </Form.Item>
          </Col>
        )}
      </SubContent>
      <SubContent title="监控和设备范围">
        <Form.Item
          rules={[
            {
              required: true,
              message: '请选择运营单元',
            },
          ]}
          name={[field.name, 'ouIds']}
          label="运营单元"
        >
          <Select
            style={{ width: '100%' }}
            placeholder="请选择"
            options={ouOptions}
            showSearch
            optionFilterProp="label"
            mode="multiple"
            disabled={isDetail}
          />
        </Form.Item>
      </SubContent>

      {messageContextHolder}
    </div>
  );
};

export default EnergyOverviewConfig;
