import React from 'react';
import { Tabs } from '@maxtropy/components';
import { TabsProps } from 'antd/es/tabs';
import classNames from 'classnames';
import { uniqBy } from 'lodash-es';
import styles from './index.module.scss';
import ResizeObserver from 'rc-resize-observer';

export interface TabDataType {
  key: string;
  name: string;
}

export interface HistoryTabProps extends Partial<TabsProps> {
  onTabChange?: (tabTile: string) => void;
  tabData: TabDataType[];
  activeKey?: string;
  className?: string;
  extral?: React.ReactNode;
}

const HistoryTab: React.FC<HistoryTabProps> = props => {
  const { onTabChange, tabData = [], activeKey, className, extral } = props;

  return (
    <ResizeObserver onResize={({ width }) => {}}>
      <div className={classNames(styles.tabs, className)}>
        <div className={styles.tab_box}>
          <Tabs
            onChange={onTabChange}
            activeKey={activeKey}
            items={uniqBy(tabData, 'key').map(item => ({ key: item.key, label: item.name }))}
          ></Tabs>
        </div>

        <div className={styles.exportBtn}>{extral}</div>
      </div>
    </ResizeObserver>
  );
};

export default HistoryTab;
