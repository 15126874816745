import { Cascader, Col, FormInstance, Row, Spin } from 'antd';
import styles from './index.module.scss';
import { Button, Form, Input, PopConfirm, Select, SubContent, Table, Tag, TreeSelect } from '@maxtropy/components';
import { ColumnsType } from 'antd/es/table';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { MutableRefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { getOuListOwned } from '@/api/ou';
import {
  apiV2AppAnalysisSubscriptionGetAnalysisSubscriptionByOuPost,
  apiV2EnergyUnitGetEnergyUnitByCenterPost,
  apiV2OutputProductCategoryListPost,
  apiV2ProductionBaseCenterHierarchyPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil, uniq } from 'lodash-es';
import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DragRow from '../DragRow';
import { ConfigItem, DeviceDataType, LineDeviceItem, OptionItem, OutputDataType, formatTreeData } from '../../utils';
import SelectDevice from '../SelectDevice';
import React from 'react';
import MediumSelect from '../MediumSelect';
import UnitSelect from '../UnitSelect';
import OutputSelect from '../OutputSelect';

export interface OverviewItemProps {
  detail?: ConfigItem;
  isDetail?: boolean;
  onTitleChange?: (title: string) => void;
  onChange?: (values: any) => void;
}

export interface OverviewItemRefProps {
  form: FormInstance<any> | null;
  lineDeviceList?: LineDeviceItem[];
  productionBaseId?: number;
}

const OverviewItem = React.forwardRef(
  ({ detail, isDetail, onChange, onTitleChange }: OverviewItemProps, ref: React.Ref<OverviewItemRefProps>) => {
    const [form] = Form.useForm();
    const [ouOptions, setOuOptions] = useState<{ label?: string; value?: number }[]>([]);
    const [workCenterOptions, setWorkCenterOptions] = useState<any>();
    const [processOptions, setProcessOptions] = useState<any>([]);
    const [productionBaseId, setProductionBaseId] = useState<number>();
    const [treeData, setTreeData] = useState<any>([]);
    const originWorkCenterIds = Form.useWatch('originWorkCenterIds', form);
    const outputList = Form.useWatch('outputList', form);
    const deviceList = Form.useWatch('deviceList', form);
    const appAnalysisSubscriptionId = Form.useWatch('appAnalysisSubscriptionId', form);
    const [energyUnitOptions, setEnergyUnitOptions] = useState<OptionItem[]>([]);
    const [openSelectModal, setOpenSelectModal] = useState<boolean>(false);
    const formRef: MutableRefObject<FormInstance<any> | null> = React.useRef(null);
    const loadingRef = useRef<number>(0);

    const [lineDeviceList, setLineDeviceList] = useState<LineDeviceItem[]>([]);

    useImperativeHandle(ref, () => ({
      form: formRef.current,
      lineDeviceList,
      productionBaseId,
    }));

    useEffect(() => {
      form.resetFields();

      form.setFieldsValue({
        ...detail,
      });
      setLineDeviceList(detail?.lineDevices ?? []);

      setProductionBaseId(detail?.productionBaseId);
    }, [detail]);

    useEffect(() => {
      loadingRef.current = 4;
      // 获取运营单元
      getOuListOwned()
        .then(res => {
          setOuOptions((res ?? []).map(item => ({ label: item.name, value: item.id })));
        })
        .finally(() => loadingRef.current--);
      // 生产基地
      apiV2ProductionBaseCenterHierarchyPost({})
        .then(res => {
          const mappedData = res.list?.map(item => ({
            ...item,
            name: `${item.code}-${item.name}`,
            disabled: isNil(item.workCenterHierarchy),
            workCenterHierarchy: (item?.workCenterHierarchy ?? [])?.map(hierarchyItem => ({
              ...hierarchyItem,
              name: `${hierarchyItem.code}-${hierarchyItem.name}`,
            })),
          }));
          setWorkCenterOptions(mappedData);
        })
        .finally(() => loadingRef.current--);

      // 产出物分类
      apiV2OutputProductCategoryListPost({})
        .then(res => {
          setTreeData(formatTreeData(res?.children));
        })
        .finally(() => loadingRef.current--);

      // 生产过程
      apiV2AppAnalysisSubscriptionGetAnalysisSubscriptionByOuPost({})
        .then(res => {
          setProcessOptions(
            (res.list ?? []).map(m => ({
              label: m.productionProcessName,
              value: m.id,
            }))
          );
        })
        .finally(() => loadingRef.current--);
    }, []);

    const workCenterIds = useMemo(() => {
      return (originWorkCenterIds ?? []).map((k: number[][]) => k[1]);
    }, [originWorkCenterIds]);

    useEffect(() => {
      if (!workCenterIds.length) return;
      apiV2EnergyUnitGetEnergyUnitByCenterPost({ ids: workCenterIds }).then(res => {
        setEnergyUnitOptions(
          (res.list ?? []).map((item: any) => ({
            label: item.name,
            value: item.id,
            energyUnitGroupId: item.energyUnitGroupId,
          }))
        );
      });
    }, [workCenterIds]);

    const addOutputRow = () => {
      const prevList = form.getFieldValue('outputList') || [];

      const newRow = {
        id: uuidv4(),
        outputTypeName: '',
        outputProductCategoryId: undefined,
        unitCode: undefined,
        outputIds: undefined,
      };

      prevList.push(newRow);

      form.setFieldValue('outputList', [...prevList]);
    };

    const addDeviceRow = () => {
      const newRow = {
        id: uuidv4(),
        deviceTypeName: '',
        energyUnitIds: undefined,
        energyMediumId: undefined,
      };

      const prevList = form.getFieldValue('deviceList') || [];
      prevList.push(newRow);
      form.setFieldValue('deviceList', [...prevList]);
    };

    const deleteOutputRow = (id: string) => {
      const prevList = form.getFieldValue('outputList');
      const removeId = prevList.findIndex((item: OutputDataType) => {
        return item.id === id;
      });
      prevList.splice(removeId, 1);
      form.setFieldsValue({ outputList: prevList });
    };

    const deleteDeviceRow = (id: string) => {
      const prevList = form.getFieldValue('deviceList');
      const removeId = prevList.findIndex((item: DeviceDataType) => {
        return item.id === id;
      });
      prevList.splice(removeId, 1);
      form.setFieldsValue({ deviceList: prevList });
    };

    const ouputColumns: ColumnsType<OutputDataType> = [
      {
        title: (
          <div>
            产出物类型 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'outputTypeName',
        width: 280,
        render: (_, record: OutputDataType, index) => {
          return (
            <Form.Item
              name={['outputList', index, 'outputTypeName']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入', required: true },
                { max: 20, message: '最多输入20个字符' },
                {
                  validator: (_, value) => {
                    const outputList = form.getFieldValue('outputList');
                    const outputNameList = (outputList ?? [])
                      .map((k: any) => k.outputTypeName)
                      .filter((k: any) => k.outputTypeName);
                    if (uniq(outputNameList).length !== outputNameList.length) {
                      return Promise.reject('产出物类型名称重复');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <div>
            产出物分类 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'outputProductCategoryId',
        width: 280,
        render: (_, record: OutputDataType, index) => {
          return (
            <Form.Item
              name={['outputList', index, 'outputProductCategoryId']}
              style={{ marginBottom: 0 }}
              rules={[{ message: '请选择', required: true }]}
            >
              <TreeSelect
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeDefaultExpandAll
                treeData={treeData}
                placeholder="请选择产出物分类"
                onChange={val => {
                  form.setFieldValue(['outputList', index, 'unitCode'], undefined);
                  form.setFieldValue(['outputList', index, 'unitTypeCode'], undefined);
                  form.setFieldValue(['outputList', index, 'outputIds'], undefined);
                }}
                allowClear
              />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <div>
            单位 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'unitCode',
        width: 280,
        render: (_, record: OutputDataType, index) => {
          return (
            <Form.Item noStyle dependencies={[['outputList', index, 'outputProductCategoryId']]}>
              {({ getFieldValue }) => {
                let categoryId = getFieldValue(['outputList', index, 'outputProductCategoryId']);
                return (
                  <Form.Item
                    name={['outputList', index, 'unitCode']}
                    style={{ marginBottom: 0 }}
                    rules={[{ message: '请选择', required: true }]}
                  >
                    <UnitSelect
                      workCenterIds={workCenterIds}
                      categoryId={categoryId}
                      onChange={(val, typeCode) => {
                        form.setFieldValue(['outputList', index, 'unitTypeCode'], typeCode);
                        form.setFieldValue(['outputList', index, 'outputIds'], undefined);
                      }}
                    ></UnitSelect>
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: (
          <div>
            产出物 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'outputIds',
        render: (_, record: OutputDataType, index) => {
          return (
            <Form.Item
              noStyle
              dependencies={[
                ['outputList', index, 'outputProductCategoryId'],
                ['outputList', index, 'unitCode'],
              ]}
            >
              {({ getFieldValue }) => {
                const categoryId = getFieldValue(['outputList', index, 'outputProductCategoryId']);
                const unitCode = getFieldValue(['outputList', index, 'unitCode']);
                return (
                  <Form.Item
                    name={['outputList', index, 'outputIds']}
                    style={{ marginBottom: 0 }}
                    rules={[{ message: '请选择', required: true }]}
                  >
                    <OutputSelect
                      workCenterIds={workCenterIds}
                      categoryId={categoryId}
                      unitCode={unitCode}
                    ></OutputSelect>
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        width: 120,
        render: (_, record: OutputDataType) => {
          return (
            <PopConfirm
              placement="top"
              title={'是否删除该内容？删除后对应页面数据展示也将删除。'}
              onConfirm={() => {
                deleteOutputRow(record.id!);
              }}
            >
              <Button type="link" danger>
                删除
              </Button>
            </PopConfirm>
          );
        },
      },
    ];

    const deviceColumns: ColumnsType<DeviceDataType> = [
      {
        title: (
          <div>
            设备类型 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'deviceTypeName',
        width: 340,
        render: (_, record: DeviceDataType, index) => {
          return (
            <Form.Item
              name={['deviceList', index, 'deviceTypeName']}
              style={{ marginBottom: 0 }}
              rules={[
                { message: '请输入', required: true },
                { max: 20, message: '最多输入20个字符' },
                {
                  validator: (_, value) => {
                    const deviceList = form.getFieldValue('deviceList');
                    const deviceTypeNameList = (deviceList ?? [])
                      .map((k: any) => k.deviceTypeName)
                      .filter((k: any) => k.deviceTypeName);
                    if (uniq(deviceTypeNameList).length !== deviceTypeNameList.length) {
                      return Promise.reject('设备类型名称重复');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          );
        },
      },
      {
        title: (
          <div>
            设备用能 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'energyUnitIds',
        render: (_, record: DeviceDataType, index) => {
          return (
            <Form.Item
              name={['deviceList', index, 'energyUnitIds']}
              style={{ marginBottom: 0 }}
              rules={[{ message: '请选择', required: true }]}
            >
              <Select
                options={energyUnitOptions}
                placeholder="请选择"
                showSearch
                optionFilterProp="label"
                mode="multiple"
                onChange={(vals: number[]) => {
                  form.setFieldValue(['deviceList', index, 'energyMediumId'], undefined);
                }}
              ></Select>
            </Form.Item>
          );
        },
      },
      {
        title: (
          <div>
            能源介质 <span className={styles.required}>*</span>
          </div>
        ),
        dataIndex: 'energyMediumId',
        width: 340,
        render: (_, record: DeviceDataType, index) => {
          return (
            <Form.Item noStyle dependencies={[['deviceList', index, 'energyUnitIds']]}>
              {({ getFieldValue }) => {
                let vals = getFieldValue(['deviceList', index, 'energyUnitIds']);
                const filterIds = energyUnitOptions
                  .filter(m => vals?.includes(m.value!))
                  .map(m => m.energyUnitGroupId!);
                const ids = [...new Set(filterIds)];
                return (
                  <Form.Item
                    name={['deviceList', index, 'energyMediumId']}
                    style={{ marginBottom: 0 }}
                    rules={[{ message: '请选择', required: true }]}
                  >
                    <MediumSelect ids={ids}></MediumSelect>
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        width: 120,
        render: (_, record: DeviceDataType) => {
          return (
            <PopConfirm
              placement="top"
              title={'是否删除该内容？删除后对应页面数据展示也将删除。'}
              onConfirm={() => {
                deleteDeviceRow(record.id!);
              }}
            >
              <Button type="link" danger>
                删除
              </Button>
            </PopConfirm>
          );
        },
      },
    ];

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 1,
        },
      })
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const activeIndex = deviceList.findIndex((i: { id: UniqueIdentifier }) => i.id === active.id);
        const overIndex = deviceList.findIndex((i: { id: UniqueIdentifier | undefined }) => i.id === over?.id);

        form.setFieldsValue({
          deviceList: arrayMove(deviceList, activeIndex, overIndex),
        });
      }
    };

    return (
      <div className={styles.singleConfig}>
        <Spin spinning={loadingRef.current !== 0}>
          <Form form={form} ref={formRef} layout="vertical" disabled={isDetail}>
            <SubContent title="总览配置" className={styles.subContent}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="总览标题"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: '请输入总览标题',
                      },
                      {
                        max: 64,
                      },
                    ]}
                  >
                    <Input disabled={isDetail} placeholder="请输入" onChange={e => onTitleChange?.(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: '请选择运营单元',
                      },
                    ]}
                    name="ouIds"
                    label="运营单元"
                  >
                    <Select
                      placeholder="请选择"
                      options={ouOptions}
                      showSearch
                      optionFilterProp="label"
                      mode="multiple"
                      disabled={isDetail}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="originWorkCenterIds"
                    label="生产基地"
                    rules={[
                      {
                        required: true,
                        message: '请选择生产基地',
                      },
                    ]}
                  >
                    <Cascader
                      options={workCenterOptions}
                      multiple
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      allowClear={false}
                      maxTagCount="responsive"
                      showSearch
                      onChange={value => {
                        // 生产基地切换后
                        // 产出物列表数据清除
                        const prevOutputList = form.getFieldValue('outputList');
                        form.setFieldValue(
                          'outputList',
                          prevOutputList?.map((m: any) => ({
                            outputTypeName: m?.outputTypeName,
                            outputProductCategoryId: m?.outputProductCategoryId,
                            id: m?.id,
                          }))
                        );
                        // 设备列表数据清除
                        const prevDeviceList = form.getFieldValue('deviceList');
                        form.setFieldValue(
                          'deviceList',
                          prevDeviceList?.map((m: any) => ({
                            deviceTypeName: m?.deviceTypeName,
                            sequenceNum: m?.sequenceNum,
                            id: m?.id,
                          }))
                        );
                        if (isNil(productionBaseId)) {
                          form.setFieldsValue({ originWorkCenterIds: value });
                          setProductionBaseId(value?.[0]?.[0] as number);
                        } else {
                          const nextProductionBaseId = value
                            .map(k => k[0])
                            .filter(m => m !== productionBaseId)
                            .pop();
                          if (!isNil(nextProductionBaseId)) {
                            const filterSelected = value.filter(subArray => subArray[0] === nextProductionBaseId);
                            form.setFieldsValue({ originWorkCenterIds: filterSelected });
                            setProductionBaseId(nextProductionBaseId as number);
                          }
                        }
                      }}
                      changeOnSelect
                      showCheckedStrategy={'SHOW_CHILD'}
                      fieldNames={{ label: `name`, value: 'id', children: 'workCenterHierarchy' }}
                      placeholder="请选择工作中心"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </SubContent>
            <SubContent title="重点产出物监测">
              <Form.Item noStyle name="outputList" valuePropName="dataSource">
                <Table columns={ouputColumns} rowKey="id" />
              </Form.Item>
              <Button
                type="dashed"
                ghost
                icon={<PlusOutlined />}
                wrapClassName={styles.addRowBtn}
                style={{ width: '100%' }}
                disabled={outputList?.length >= 10 || isDetail}
                onClick={() => {
                  addOutputRow();
                }}
              >
                新增重点产出物
              </Button>
            </SubContent>
            <SubContent title="重点设备能耗监测">
              <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={(deviceList ?? []).map((i: any) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <Form.Item noStyle name="deviceList" valuePropName="dataSource">
                    <Table
                      components={{
                        body: {
                          row: DragRow,
                        },
                      }}
                      rowKey="id"
                      columns={deviceColumns}
                    />
                  </Form.Item>
                </SortableContext>
              </DndContext>

              <Button
                type="dashed"
                ghost
                icon={<PlusOutlined />}
                wrapClassName={styles.addRowBtn}
                style={{ width: '100%' }}
                disabled={deviceList?.length >= 10 || isDetail}
                onClick={() => {
                  addDeviceRow();
                }}
              >
                新增重点设备监测
              </Button>
            </SubContent>
            <SubContent title="产线运行监测">
              <Row>
                <Col span={8}>
                  <Form.Item
                    label="生产过程"
                    name="appAnalysisSubscriptionId"
                    rules={[
                      {
                        required: true,
                        message: '请选择生产过程',
                      },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      options={processOptions}
                      showSearch
                      optionFilterProp="label"
                      onChange={() => {
                        form.setFieldValue('deviceIds', undefined);
                        setLineDeviceList([]);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={14}>
                  <Form.Item
                    label="产线设备"
                    name="deviceIds"
                    rules={[
                      {
                        required: true,
                        message: '请选择产线设备',
                      },
                    ]}
                  >
                    <>
                      {!isDetail && (
                        <Button
                          type="link"
                          style={{ paddingLeft: 0 }}
                          onClick={() => {
                            setOpenSelectModal(true);
                          }}
                        >
                          选择/编辑
                        </Button>
                      )}
                      {lineDeviceList?.map((item: LineDeviceItem) => {
                        return (
                          <Tag
                            key={item.id}
                            closable={!isDetail}
                            onClose={() => {
                              const newList = lineDeviceList.filter((k: any) => k.id !== item.id);
                              setLineDeviceList(newList);
                              form.setFieldValue(
                                'deviceIds',
                                newList.map((k: any) => k.id)
                              );
                            }}
                          >
                            {item.name}
                          </Tag>
                        );
                      })}
                    </>
                  </Form.Item>
                </Col>
              </Row>
            </SubContent>
          </Form>
        </Spin>

        {openSelectModal && (
          <SelectDevice
            open={openSelectModal}
            prevRows={lineDeviceList}
            onChange={vals => {
              form.setFieldValue(
                'deviceIds',
                vals.map(k => k.id)
              );
              form.validateFields(['deviceIds']);
              setLineDeviceList(vals);
            }}
            appAnalysisSubId={appAnalysisSubscriptionId}
            setOpen={val => setOpenSelectModal(val)}
          ></SelectDevice>
        )}
      </div>
    );
  }
);

export default OverviewItem;
