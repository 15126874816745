import { Form, InputNumber } from '@maxtropy/components';
import { TimeGranularityTypeDisPlay } from '../../utils';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useContext } from 'react';
import { ProductionBeatConfigContext } from '../..';

// 时间InputNumber相同props
const TimeSameProps = {
  step: '1',
  precision: 0,
  style: { width: '100%' },
  min: 1,
};

const BeatInterval = () => {
  const { timeResolution } = useContext(ProductionBeatConfigContext);

  return (
    <div className={styles.beatIntervalRange}>
      <Form.Item
        name="beatIntervalRange"
        label="节拍间隔"
        dependencies={[
          ['beatInterval', 'beatIntervalMin'],
          ['beatInterval', 'beatIntervalMax'],
        ]}
        required
        rules={[
          ({ getFieldValue }) => ({
            validator() {
              const min = getFieldValue(['beatInterval', 'beatIntervalMin']);
              const max = getFieldValue(['beatInterval', 'beatIntervalMax']);
              if (!min && !max) {
                return Promise.reject(new Error('请填写节拍时间'));
              }
              if (!min || !max) {
                return Promise.reject(new Error('区间上下限需填写完整'));
              }
              if (min >= max) {
                return Promise.reject(new Error('最小值需要小于最大值'));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <div>
          <Form.Item
            name={['beatInterval', 'beatIntervalMin']}
            className={classNames(styles.formItemStyle, styles.inputNumberStart)}
          >
            <InputNumber {...TimeSameProps} />
          </Form.Item>
          <Form.Item noStyle>
            <p className={styles.pInterval}>~</p>
          </Form.Item>
          <Form.Item
            name={['beatInterval', 'beatIntervalMax']}
            className={classNames(styles.formItemStyle, styles.inputNumberEnd)}
          >
            <InputNumber {...TimeSameProps} />
          </Form.Item>
          <Form.Item noStyle>
            <p className={styles.pInterval} style={{ width: 50 }}>
              {TimeGranularityTypeDisPlay[timeResolution]}
            </p>
          </Form.Item>
        </div>
      </Form.Item>
    </div>
  );
};

export default BeatInterval;
