import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { scale } from '../../utils';
import { DayCostItem } from '../EnergyCost';

interface Props {
  isFullScreen: boolean;
  allData?: DayCostItem[];
  isTwoCol: boolean;
  allDataIsOverMillion: boolean;
  outerRef: any;
}
const colors = ['#59DBFF', '#FF477B', '#6F47FF', '#FFCB47', '#57FB8B', '#477BFF'];

const AllEnergyLineEcharts: React.FC<Props> = ({ outerRef, allDataIsOverMillion, isFullScreen, allData, isTwoCol }) => {
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  const fontZoomSize = useMemo(() => {
    return zoom > 1 ? 12 * zoom : 12;
  }, [zoom]);

  const geTooltip = (params: any) => {
    const time = dayjs(Number(params[0].axisValue)).format('YYYY-MM-DD');
    const allCostData = params?.map((i: any) => Number(i.data ?? 0));
    let allCost = allCostData.reduce((a: number, b: number) => Number(a) + Number(b), 0).toFixed(2);
    if (params?.every((i: any) => isNil(i.data))) allCost = '--';
    const styleStr = `display:flex;justify-content:space-between;align-items:center;font-size: ${fontZoomSize}px;font-weight: 500;color: rgba(255,255,255,0.65);line-height:${
      zoom * 17
    }px;margin-top:${zoom * 8}px;`;

    const items = params?.reverse()?.map((i: any, index: number) => {
      return `<div style='${styleStr}'>
        <div style='margin-right:${zoom * 30}px' >
          <span style='width:${zoom * 8}px;height:${zoom * 8}px;background:${
        colors[index]
      }; border-radius:50%;display:inline-block' ></span>
          <span>${i.seriesName ?? '--'}</span>
        </div>
        <div  style='width:${zoom * 130}px; display:flex; justify-content:space-between;align-items:center;'>
          <div style ='color: rgba(255,255,255,0.85); margin-right: ${zoom * 10}px' >${i.data ?? '--'}${
        allDataIsOverMillion ? '万元' : '元'
      }</div>
          <div style ='color: rgba(255,255,255,0.85);'>${i.data ? ((i.data / allCost) * 100).toFixed(2) : '--'}%</div>
        </div>
      </div>`;
    });
    return (
      `<span style='font-size: ${zoom * 12}px;color: rgba(255,255,255,0.65);line-height: ${
        zoom * 17
      }px;'>${time}</span>` +
      ` <div style='display:flex; justify-content:space-between;align-items:center;font-size: ${
        zoom * 12
      }px;font-weight: 500;color: rgba(255,255,255,0.85);' ><span>综合用能成本</span><span>${allCost}${
        allDataIsOverMillion ? '万元' : '元'
      }</span></div>` +
      `${items.join('')}`
    );
  };

  const getChartOption = () => {
    return {
      grid: {
        left: 60 * zoom,
        right: 20,
        // top: 20,
        bottom: 20 * zoom,
      },
      tooltip: {
        transitionDuration: 0,
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: fontZoomSize,
        },
        formatter(params: any) {
          const str = geTooltip(params);
          return str;
        },
      },
      xAxis: {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (e: number) {
            return dayjs(e, 'x').format('MM-DD');
          },
          fontSize: fontZoomSize,
        },
        data: allData?.map(i => Number(i.ts)),
      },
      yAxis: {
        type: 'value',
        name: allDataIsOverMillion ? '万元' : '元',
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
          fontSize: fontZoomSize,
        },
        axisLabel: {
          fontSize: fontZoomSize,
        },
        // nameGap: 30,
        // splitLine: {
        //   lineStyle: {
        //     color: '#FFFFFF30',
        //   },
        // },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
      },
      legend: {
        show: true,
        // y: 'top',
        x: 'right',
        // orient: 'vertical',
        itemHeight: 8 * zoom,
        itemWidth: 8 * zoom,
        data: allData?.at(0)?.details.map((i, index) => ({
          name: i.energyMediumName,
          icon: 'rect',
          itemStyle: {
            color: colors[index],
          },
        })),
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: fontZoomSize,
        },
        height: 8 * zoom,
        itemGap: 10,
      },
      series: allData
        ?.at(0)
        ?.details?.map((v, index) => {
          const currentMediumId = allData?.at(0)?.details[index]?.energyMediumId;
          return {
            type: 'line',
            stack: 'Total',
            color: colors[index],
            emphasis: {
              focus: 'series',
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.4,
            },

            name: v?.energyMediumName,
            data: allData?.map(k => k.details?.find(m => m?.energyMediumId === currentMediumId)?.charges),
          };
        })
        .reverse(),
    };
  };
  return (
    <>
      <ReactEcharts
        option={getChartOption()}
        style={
          isTwoCol
            ? { height: 350, width: '100%' }
            : {
                height: '85%',
                width: '100%',
                minHeight: 200,
                zoom: `${1 / zoom}`,
                transform: `scale(${scale})`,
              }
        }
        notMerge
        lazyUpdate={false}
      />
    </>
  );
};

export default AllEnergyLineEcharts;
