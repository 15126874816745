import { FC, useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import {
  apiV2ElectricEnergyConsumptionListPost,
  V2ElectricEnergyConsumptionListPostResponse,
  apiV2ElectricityAccountListByCustomerMcidPost,
  V2ElectricityAccountListByCustomerMcidPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import DateSwitch, { periodToQuery } from '../components/DateSwitch';
import Chart from '../components/Chart';
import { getChartOption } from '../components/Chart/chartOption';
import {
  Organization,
  ElectricityAccountDeviceResponse,
  getOrganizationWithCurrentStaff,
  getDeviceListByElectricityAccount,
  getPriceDetails,
  RateElectricityPriceDetailVo,
  ElectricityAccountStatus,
} from '../../../api/electricity';
import { ElectricityChartsRequest, StatisticsPartition } from '../../../api/statistics';
import { getDefaultTime } from '../utils';
import styles from './index.module.scss';
import { Empty, Form, Select } from '@maxtropy/components';

export type Query = Omit<ElectricityChartsRequest, 'deviceId' | 'accountId'>;

const ElectricityStatistics: FC = () => {
  const [form] = Form.useForm();

  const period = periodToQuery['hour'];
  const defaultQuery = {
    resolution: period,
    ...getDefaultTime(period, dayjs()),
  };

  const [query, setQuery] = useState<Query>(defaultQuery);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<V2ElectricEnergyConsumptionListPostResponse['list']>();
  const [organizations, setOrganitions] = useState<Organization[]>([]);
  const [electricityAccounts, setElectricityAccounts] = useState<
    V2ElectricityAccountListByCustomerMcidPostResponse['list']
  >([]);
  const [devices, setDevices] = useState<ElectricityAccountDeviceResponse[]>([]);
  const [mcid, setMcid] = useState<string>();
  const [electricityAccountId, setElectricityAccountId] = useState<number>();
  const [deviceId, setDeviceId] = useState<number>();
  const [ePriceTimes, setEPriceTimes] = useState<RateElectricityPriceDetailVo[]>();

  useEffect(() => {
    getOrganizationWithCurrentStaff().then(res => {
      setOrganitions(res.list);
    });
  }, []);

  useEffect(() => {
    if (mcid) {
      apiV2ElectricityAccountListByCustomerMcidPost({ customerMcid: mcid }).then(res =>
        setElectricityAccounts(res.list || [])
      );
    }
  }, [mcid]);

  useEffect(() => {
    if (electricityAccountId) {
      getDeviceListByElectricityAccount(electricityAccountId).then(res => setDevices(res.list || []));
    } else {
      setDevices([]);
    }
  }, [electricityAccountId]);

  useEffect(() => {
    if (electricityAccountId) {
      if (query && query.resolution === StatisticsPartition.HOUR && query.tsStart) {
        getPriceDetails(electricityAccountId, dayjs(query.tsStart).format('YYYY-MM-DD')).then(res =>
          setEPriceTimes(res.list || [])
        );
      }
    } else {
      setEPriceTimes([]);
    }
  }, [electricityAccountId, query]);

  useEffect(() => {
    if (query && !isNil(deviceId) && !isNil(electricityAccountId)) {
      setLoading(true);
      apiV2ElectricEnergyConsumptionListPost({
        ...query,
        accountId: electricityAccountId,
        deviceId,
      })
        .then(res => {
          setChartData(res.list || []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [query, deviceId, electricityAccountId]);

  const onChangeMcid = (mcid: string) => {
    setMcid(mcid);
    setElectricityAccountId(undefined);
    setDeviceId(undefined);
    setChartData(undefined);
    form.setFieldsValue({
      electricityAccountId: undefined,
      device: undefined,
    });
  };

  const onChangeAccount = (accoundId: number) => {
    setElectricityAccountId(accoundId);
    setDeviceId(undefined);
    setChartData(undefined);
    form.setFieldsValue({
      device: undefined,
    });
  };

  const onChangeData = (value: Query) => {
    setQuery(value);
  };

  return (
    <>
      <div className={styles.filterWrapper}>
        <Form className={styles.form} form={form} labelCol={{ style: { width: 'auto', marginRight: 0 } }}>
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item validateFirst name="mcid" label="组织" rules={[{ required: true, message: '请选择' }]}>
                <Select placeholder="请选择" onChange={onChangeMcid}>
                  {organizations.map(i => (
                    <Select.Option key={i.mcid} value={i.mcid}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="electricityAccountId" label="户号" rules={[{ required: true, message: '请选择' }]}>
                <Select placeholder="请选择" onChange={onChangeAccount}>
                  {electricityAccounts
                    ?.filter(i => i.status === ElectricityAccountStatus.NORMAL)
                    .map(i => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.number}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item validateFirst name="device" label="设备" rules={[{ required: true, message: '请选择' }]}>
                <Select placeholder="请选择" onChange={setDeviceId}>
                  {devices.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className={styles.line_bg}></div>
      <div className={styles.content}>
        <div className={styles.operationArea}>
          <DateSwitch value={[dayjs(query.tsStart, 'x'), dayjs(query.tsEnd, 'x')]} onQueryChange={onChangeData} />
        </div>
        <Spin spinning={loading}>
          <div className={styles.chartContainer}>
            {chartData &&
            (query.resolution !== StatisticsPartition.HOUR ||
              ((ePriceTimes ?? []).length > 0 && chartData.length > 0)) ? (
              <>
                <Chart option={getChartOption({ chartData, query, prices: ePriceTimes ?? [] })} />
              </>
            ) : (
              <Empty style={{ marginTop: 120 }} />
            )}
          </div>
        </Spin>
      </div>
    </>
  );
};

export default ElectricityStatistics;
