import { useState, useEffect, Key } from 'react';
import { Form, Select } from '@maxtropy/components';
import {
  apiV2CarbonFootprintCalculateLatestOnePost,
  apiV2CarbonFootprintCalculateListByLcaIdAndSheetCodePost,
  apiV2CarbonFootprintLcaListOrderByCreateTimeDescPost,
  apiV2CarbonFootprintOverviewDetailPost,
  V2CarbonFootprintOverviewDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import Subtitle from '../Subtitle';
import { transPercent } from '../../utils';

interface ProductDataProp {
  label: string;
  value: Key;
}

interface Prop {
  setProductionOrderId: (v: Key) => void;
  innerRef: any;
}

const ProductCarbonFootprintOverview = ({ setProductionOrderId, innerRef }: Prop) => {
  const [data, setData] = useState<V2CarbonFootprintOverviewDetailPostResponse>();
  const [productData, setProductData] = useState<ProductDataProp[]>();
  const [productionOrderData, setProductionOrderData] = useState<ProductDataProp[]>();
  const [percentColor, setPercentColor] = useState<string>('inherit');
  const [isInit, setIsInit] = useState<boolean>(true);
  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [productionOrderLoading, setProductionOrderLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [searchOrderValue, setSearchOrderValue] = useState<string>();
  const [form] = Form.useForm();
  const productId = Form.useWatch('productId', form);
  const productionOrderId = Form.useWatch('productionOrderId', form);

  useEffect(() => {
    setProductLoading(true);
    Promise.all([
      apiV2CarbonFootprintCalculateLatestOnePost({}),
      apiV2CarbonFootprintLcaListOrderByCreateTimeDescPost({ name: searchValue }),
    ])
      .then(values => {
        const options = values[1]?.list?.map(item => ({ label: item.name, value: item.id })) || [];
        let defaultValue = options[0]?.value;
        if (values[0]) {
          defaultValue = options.find(item => item.value === values[0].carbonLcaId)?.value;
        }
        form.setFieldValue('productId', defaultValue);
        setProductData(options as ProductDataProp[]);
      })
      .finally(() => setProductLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProductLoading(true);
    apiV2CarbonFootprintLcaListOrderByCreateTimeDescPost({ name: searchValue })
      .then(res => {
        const options = res.list?.map(item => ({ label: item.name, value: item.id })) || [];
        setProductData(options as ProductDataProp[]);
      })
      .finally(() => setProductLoading(false));
  }, [searchValue]);

  useEffect(() => {
    if (!productId) return;
    setProductionOrderLoading(true);
    apiV2CarbonFootprintCalculateListByLcaIdAndSheetCodePost({ id: productId, name: searchOrderValue })
      .then(res => {
        const options = res.list?.map(item => ({ label: item.productSheetCode, value: item.id })) || [];

        setProductionOrderData(options as ProductDataProp[]);
        setIsInit(false);
      })
      .finally(() => setProductionOrderLoading(false));
  }, [productId, searchOrderValue]);

  useEffect(() => {
    if (!isInit) {
      // 只有首次进来才会默认选中
      form.setFieldValue('productionOrderId', productionOrderData?.[0]?.value);
      setProductionOrderId(productionOrderData?.[0]?.value!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInit]);

  useEffect(() => {
    if (productionOrderId) {
      apiV2CarbonFootprintOverviewDetailPost({ id: productionOrderId }).then(res => {
        setData(res ?? {});
        if (res.percent !== undefined) {
          setPercentColor(res.percent > 0 ? '#52C41A' : '#E64242');
        }
      });
    }
  }, [productionOrderId]);

  return (
    <div className={styles.productCFCon}>
      <Subtitle
        title="产品碳足迹概览"
        rightContent={
          <Form form={form} className={styles.productModule}>
            <Form.Item name="productId">
              <Select
                placeholder="请选择产品"
                showSearch
                loading={productLoading}
                options={productData}
                style={{ width: 240 }}
                getPopupContainer={() => innerRef}
                onSearch={value => setSearchValue(value)}
                onChange={() => form.setFieldsValue({ productionOrderId: undefined })}
              />
            </Form.Item>
            <Form.Item name="productionOrderId">
              <Select
                placeholder="请选择生产单"
                showSearch
                style={{ width: 240 }}
                loading={productionOrderLoading}
                options={productionOrderData}
                getPopupContainer={() => innerRef}
                onSearch={value => setSearchOrderValue(value)}
                onChange={value => setProductionOrderId(value)}
              />
            </Form.Item>
          </Form>
        }
      />
      <div className={styles.carbonFootprintInfo}>
        <div className={styles.detail} style={{ flex: 2 }}>
          <div className={styles.desc}>一{data?.unitCode}碳足迹 (kg CO2e):</div>
          <div className={styles.count}>{data?.unitCarbonFootPrint ?? '--'}</div>
          {data?.percent ? (
            <>
              <div className={styles.desc} style={{ marginLeft: 24 }}>
                同比:
              </div>
              <div className={styles.count} style={{ color: percentColor }}>
                {transPercent(data.percent)}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
        <div className={styles.detail} style={{ flex: 1 }}>
          <div className={styles.desc}>生产数量({data?.unitCode}):</div>
          <div className={styles.count}>{data?.quantity ?? '--'}</div>
        </div>
        <div className={styles.detail} style={{ flex: 1 }}>
          <div className={styles.desc}>碳足迹排名:</div>
          <div className={styles.count}>
            {data?.rank ?? '--'}/{data?.total ?? '--'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCarbonFootprintOverview;
