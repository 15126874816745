import { FC, useEffect } from 'react';
import { BaseInfoContent, Form, ShowInput } from '@maxtropy/components';
import { BatchDeviceTemplatePropertyResponse } from '../../../../api/batch';

interface BaseInfoProps {
  data?: BatchDeviceTemplatePropertyResponse;
}

const BaseInfo: FC<BaseInfoProps> = ({ data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data, form]);

  return (
    <Form form={form} labelAlign="left">
      <BaseInfoContent layout="horizontal" colNum={2}>
        <Form.Item name="typeName" label="所属类目">
          <ShowInput />
        </Form.Item>
        <Form.Item name="modelName" label="所属型号">
          <ShowInput />
        </Form.Item>
        <Form.Item label="物模型型号">
          <ShowInput value={`${data?.modelNo ?? ''}${data?.manufacturerName ? '-' + data?.manufacturerName : ''}`} />
        </Form.Item>
      </BaseInfoContent>
    </Form>
  );
};

export default BaseInfo;
