import React, { useEffect, useState } from 'react';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Table,
  Paging,
  useUpdate,
  usePaging,
  EllipsisSpan,
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { getSchedulePlanList, Schedule } from '@/api/schedulePlan';
import CopyScheduleModal from './CopyScheduleModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import {
  apiV2EnergyAnalysisListByOu2Post,
  V2EnergyAnalysisListByOu2PostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';

type SearchParams = {
  // 用能分析组 id
  energyGroupIds?: number[] | string[];
  // 用能单元 id
  energyUnitName?: string;
};

export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];

// 排班计划列表
export default function SchedulePlan() {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({});
  const [form] = Form.useForm<SearchParams>();
  const [updateState, forceUpdate] = useUpdate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  // 用能分析组
  const [energyConsumptionList, setEnergyConsumptionList] = useState<GroupDetails[]>([]);
  // 排班计划列表
  const [scheduleList, setScheduleList] = useState<Schedule[]>([]);
  // 以次为模板的用能单元 id
  const [templateEnergyUnitId, setTemplateEnergyUnitId] = useState<string | number | undefined>(undefined);
  // 复制排班模态框显示
  const [showCopyScheduleModal, setShowCopyScheduleModal] = useState<boolean>(false);
  // 复制排班时候点击传入的排班信息
  const [templateSchedule, setTemplateSchedule] = useState<Schedule>();
  // 排班详情权限
  const hasScheduleDetailPermission = useHasPermission(PermissionsType.B_WORKSHIFTAPPLYDETAIL);
  // 复制排班权限
  const hasScheduleCopyPermission = useHasPermission(PermissionsType.B_WORKSHIFTAPPLYCOPY);

  const columns = [
    {
      title: '用能单元',
      dataIndex: 'energyUnitName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属用能分析组',
      dataIndex: 'energyGroupName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: () => <Tooltip title="显示前后3个月的最新排班时间">最新排班时间</Tooltip>,
      dataIndex: 'workDate',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
    },
    {
      title: '操作',
      width: 250,
      render: (value: any, record: Schedule) => {
        return (
          <Space size={16}>
            {hasScheduleDetailPermission && (
              <Button
                type="link"
                onClick={() => {
                  // 这里的 id 为用能单元 id, 对应一个排班计划详情
                  window.open(
                    `/energy/schedule/plan/detail/${record?.energyUnitId}?unitName=${record?.energyUnitName}`,
                    '_blank'
                  );
                }}
              >
                排班详情
              </Button>
            )}
            {hasScheduleCopyPermission && (
              <Button
                type="link"
                onClick={() => {
                  setTemplateEnergyUnitId(record?.energyUnitId);
                  setShowCopyScheduleModal(true);
                  setTemplateSchedule(record);
                }}
              >
                以此为模板复制排班
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    setSearchParams({
      ...v,
      energyUnitName: v.energyUnitName === '' ? undefined : v.energyUnitName,
    });

    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="energyGroupIds" label="用能分析组">
        <Select
          mode="multiple"
          maxTagCount={2}
          allowClear
          optionFilterProp="label"
          options={energyConsumptionList.map(v => ({
            value: v.energyGroupId,
            label: v.energyGroupName,
          }))}
          placeholder="全部"
        />
      </Form.Item>
      <Form.Item name="energyUnitName" label="用能单元">
        <Input placeholder="请输入用能单元名称" />
      </Form.Item>
    </CustomFilter>
  );
  // 获取用能分析组列表下拉框
  useEffect(() => {
    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setEnergyConsumptionList(res.list ?? []);
    });
  }, []);

  // 获取排班计划列表
  useEffect(() => {
    setIsLoading(true);
    getSchedulePlanList({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setScheduleList(res.list);
          setTotalCount(res.total);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper} filters={filters}>
      <Table rowKey="id" columns={columns} dataSource={scheduleList} loading={isLoading} />
      <Paging pagingInfo={pagingInfo} />

      {showCopyScheduleModal && (
        <CopyScheduleModal
          open={showCopyScheduleModal}
          closeModal={() => {
            setShowCopyScheduleModal(false);
          }}
          forceUpdate={forceUpdate}
          unitName={templateSchedule?.energyUnitName!}
          sourceEnergyUnitId={templateEnergyUnitId!}
        />
      )}
    </Wrapper>
  );
}
