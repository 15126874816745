import styles from './index.module.scss';
import {
  Button,
  FormTitle,
  SubContent,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  EllipsisSpan,
  useUpdate,
  message,
} from '@maxtropy/components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  V2OeeConfigListPostResponse,
  apiV2OeeConfigDelPost,
  apiV2OeeConfigListPost,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import EditOEEConfigModal from '../components/EditOEEConfigModal';
import HistoryRecordModal from '../components/HistoryRecordModal';
import dayjs from 'dayjs';

const routes = [{ name: '设备OEE配置' }];

export type OEEConfigItem = Exclude<V2OeeConfigListPostResponse['list'], undefined>[number];

const columns = [
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
    ellipsis: { showTitle: true },
  },
  {
    title: '计量设备',
    dataIndex: 'deviceName',
    render: (v: string) => <EllipsisSpan value={v} />,
    ellipsis: { showTitle: true },
  },
  {
    title: '关联生产过程',
    dataIndex: 'productionProcessName',
    render: (v: string) => <EllipsisSpan value={v} />,
    ellipsis: { showTitle: true },
  },
  {
    title: '关联产出物',
    dataIndex: 'outputList',
    render: (v: any) => (
      <EllipsisSpan value={v?.map((k: { outputProductName: any }) => k.outputProductName).join('、')} />
    ),
    ellipsis: { showTitle: true },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    ellipsis: { showTitle: true },
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUserName',
    render: (v: string) => <EllipsisSpan value={v} />,
    ellipsis: { showTitle: true },
  },
];

const EnergyOEEConfig: React.FC = () => {
  // 应用分析订阅id
  const { id: appAnalysisSubId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<OEEConfigItem[]>([]);
  const [configModal, setConfigModal] = useState<boolean>(false);
  const [recordModal, setRecordModal] = useState<boolean>(false);
  const [currentConfigId, setCurrentConfigId] = useState<number>();
  const [update, updateFn] = useUpdate();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (!appAnalysisSubId) return;
    setLoading(true);
    apiV2OeeConfigListPost({ appAnalysisSubId })
      .then(res => {
        setTableData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appAnalysisSubId, update]);

  // 删除配置
  const delConfigItem = (id: number) => {
    setLoading(true);
    apiV2OeeConfigDelPost({ id })
      .then(res => {
        message.success('删除成功！');
        updateFn();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setCurrentConfigId(record.id);
                setConfigModal(true);
              }}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => {
                delConfigItem(record.id);
              }}
            >
              删除
            </Button>
            <Button
              type="link"
              onClick={() => {
                setCurrentConfigId(record.id);
                setRecordModal(true);
              }}
            >
              历史记录
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="设备OEE配置"></FormTitle>

      <SubContent>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrentConfigId(undefined);
            setConfigModal(true);
          }}
          wrapStyle={{ marginBottom: 10 }}
        >
          新增设备OEE配置
        </Button>
        <Table
          rowKey="id"
          scroll={{ y: 'calc(100vh - 412px)' }}
          columns={buildColumns}
          loading={loading}
          dataSource={tableData}
        />
        <Space size={8}>
          <Button
            className={styles.button}
            onClick={() => {
              navigate(`/energy/appAnalyticsSubscription/productionProcess`);
            }}
          >
            返回
          </Button>
        </Space>
      </SubContent>

      {configModal && (
        <EditOEEConfigModal
          visible={configModal}
          itemId={currentConfigId}
          onClose={() => setConfigModal(false)}
          onUpdate={updateFn}
        ></EditOEEConfigModal>
      )}
      {recordModal && (
        <HistoryRecordModal
          visible={recordModal}
          itemId={currentConfigId}
          onCancel={() => setRecordModal(false)}
        ></HistoryRecordModal>
      )}
    </Wrapper>
  );
};

export default EnergyOEEConfig;
