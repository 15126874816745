import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Key,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Tag,
  Paging,
  CustomFilter,
  Form,
  Select,
  Modal,
  TreeSelect,
  useAsync,
  getRealUrl,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  V2DeviceLedgerPagePostRequest,
  V2DeviceLedgerPagePostResponse,
  apiV2DeviceLedgerDelPost,
  apiV2DeviceLedgerPagePost,
  apiV2ManufacturerListPost,
  apiV2PhysicalModelPhysicalModelAllListPost,
} from '@maxtropy/device-customer-apis-v2';
import { EquipmentState, EquipmentStateDisplay, EquipmentStateTag, OptionItem } from './util';
import StateChangeModal from './StateChangeModal';
import { OrganizationResponse, getOrganizationWithCodeWithCurrent } from '@/api/device';
import { formatTreeData } from '../Device/CreatePropertyInfo';
import UploadImport from '@/components/UploadImport';
import { LogType } from '@/components/ImportLog/Prop';
import { isNil, omit, uniqBy } from 'lodash-es';
import { getOuListOwned } from '@/api/ou';
import { getDeviceTypeTree } from '@/api/deviceType';
import { DefaultOptionType } from 'antd/es/cascader';
import ImportLog from '@/components/ImportLog';

const statusOptions = [
  ...Object.entries(EquipmentStateDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

const columns = [
  {
    title: '编号',
    dataIndex: 'code',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '资产设备名称',
    dataIndex: 'assetDeviceName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '资产设备SN/编码',
    dataIndex: 'assetDeviceSnCode',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    width: 80,
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: EquipmentState) => (
      <Tag border="solid" color={EquipmentStateTag[v]}>
        {EquipmentStateDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '资产设备类型',
    dataIndex: 'typeName',
    width: 260,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '型号',
    dataIndex: 'physicalModelName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'mcidName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouNameList',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={v?.join('、')} />,
  },
  {
    title: '购入原值',
    dataIndex: 'purchasePrice',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '购入日期',
    width: 160,
    dataIndex: 'purchaseTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={!isNil(v) ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '使用日期',
    width: 160,
    dataIndex: 'useTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={!isNil(v) ? dayjs(v).format('YYYY-MM-DD') : '--'} />,
  },
  {
    title: '使用年限',
    width: 120,
    dataIndex: 'depreciationPeriod',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '创建时间',
    width: 160,
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作时间',
    width: 160,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<V2DeviceLedgerPagePostRequest, 'page' | 'size' | 'typeIds' | 'mcids'> {
  typeIds: number[][];
  mcids: {
    label: string;
    value: string;
  }[];
}
type LedgerItem = Exclude<V2DeviceLedgerPagePostResponse['list'], undefined>[number];

const EquipmentLedger: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<LedgerItem[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>(20);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [stateModalVisable, setStateModalVisible] = useState<boolean>(false);
  const [currentIds, setCurrentIds] = useState<number[]>([]);
  const [currentState, setCurrentState] = useState<EquipmentState>();
  const [update, updateFn] = useUpdate();

  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionItem[]>([]);
  const [openImportLogModal, setOpenImportLogModal] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const [currentPic, setCurrentPic] = useState<string>();

  //先定义两个空的数组，用于存放选中的id和数据
  const [checkedData, setCheckedData] = useState<any>([]); // 选中的数据
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  useEffect(() => {
    setLoading(true);
    const params = omit(searchParams, ['pageOffset', 'pageSize', 'typeIds']);

    apiV2DeviceLedgerPagePost({
      ...params,
      typeIds: searchParams?.typeIds?.map(k => k?.[k?.length - 1]),
      mcids: searchParams?.mcids?.map(m => m.value),
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list ?? []);
        setTotalCount(res?.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  // 所属组织
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });

    apiV2ManufacturerListPost({}).then(res => {
      setManufacturerOptions(
        (res.list ?? []).map(item => ({
          label: item.name!,
          value: item.id!,
        }))
      );
    });
  }, []);

  // 物模型型号
  const physicalModelList = useAsync(
    useCallback(() => {
      return apiV2PhysicalModelPhysicalModelAllListPost({}).then(res => res.list ?? []);
    }, [])
  );

  const ou = useAsync(getOuListOwned, []);

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const objectModalTypeOptions = useMemo(() => {
    if (isNil(manufacturerId)) {
      return (physicalModelList ?? []).map(i => ({ label: i.modelNo, value: i.id }));
    }

    return (physicalModelList ?? [])
      .filter(m => m.manufacturerId === manufacturerId)
      .map(i => ({ label: i.modelNo, value: i.id }));
  }, [physicalModelList, manufacturerId]);

  const ouOptions = useMemo(() => {
    return (ou ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [ou]);

  const cascaderData = useMemo(() => {
    const list =
      (deviceTypeData?.tree ?? []).reduce((prev: any, curr) => {
        return [...prev, ...(curr.deviceTypes ?? [])];
      }, []) ?? [];
    const uniqList = uniqBy(list, 'id');
    return uniqList.map((m: any) => ({
      label: m.name!,
      value: m.id!,
      children: m.children?.map((i: any) => ({
        ...i,
        label: i.name!,
        value: i.id!,
      })),
    }));
  }, [deviceTypeData]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onDelete = (id: Key) => {
    apiV2DeviceLedgerDelPost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 260,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <PopConfirm placement="top" title="删除后不可恢复，确定删除吗？" onConfirm={() => onDelete(record.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>

            <Button
              type="link"
              onClick={() => {
                window.open(`/asset/equipment/ledger/detail/${record.id}`, '_blank');
              }}
            >
              详情
            </Button>

            <Button
              type="link"
              onClick={() => {
                window.open(`/asset/equipment/ledger/edit/${record.id}`, '_blank');
              }}
            >
              编辑
            </Button>

            <Button
              type="link"
              disabled={isNil(record.pic)}
              onClick={() => {
                setCurrentPic(record.pic);
                setPreviewOpen(true);
              }}
            >
              设备图片
            </Button>
            <Button
              type="link"
              onClick={() => {
                setCurrentIds([record.id]);
                setCurrentState(record.status);
                setStateModalVisible(true);
              }}
            >
              状态变更
            </Button>
          </Space>
        );
      },
    },
  ];

  // 手动选择/取消选择某行
  const tableSelect = (record: any, selected: any) => {
    //如果选中某一行，把选中的这行数据及id分别存到checkedData、selectedKeys中
    if (selected) {
      const arr = checkedData.map((item: any) => item.id);
      setSelectedKeys([...arr, record.id]);
      setCheckedData([...checkedData, record]);
      //取消选中，则在checkedData、selectedKeys中过滤掉这条数据
    } else {
      const newData = checkedData.filter((item: any) => item.id !== record.id);
      setCheckedData(newData);
      const arr = newData.map((item: any) => {
        return item.id;
      });
      setSelectedKeys([...arr]);
    }
  };
  // 表格全选/取消全选
  const tableSelectAll = (selected: boolean, selectedRows: any, changeRows: any) => {
    if (!selected) {
      changeRows.forEach((row: any) => {
        const newData = checkedData.filter((item: any) => item.id !== row.id);
        setCheckedData(newData);
      });
      const arr = changeRows.map((item: any) => item.id);
      const newArr = selectedKeys.filter((item: any) => !arr.some((ele: any) => ele === item));
      setSelectedKeys([...newArr]);
    }
    //全选，把
    else {
      const arr = changeRows.map((item: any) => item.id);
      setCheckedData([...checkedData, ...changeRows]);
      setSelectedKeys([...selectedKeys, ...arr]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys, //展示选中的数据
    onSelect: tableSelect, //单条数据取消/选中的回调
    onSelectAll: tableSelectAll, //全选/取消全选的回调
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset} collapseOpen={false}>
      <Form.Item label="设备名称" name="assetDeviceName">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="设备SN/编码" name="assetDeviceSnCode">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select options={statusOptions} allowClear mode="multiple" showSearch={false} placeholder="请选择" />
      </Form.Item>
      <Form.Item label="设备类型" name="typeIds">
        <Cascader
          options={cascaderData}
          allowClear
          multiple
          maxTagCount="responsive"
          showCheckedStrategy="SHOW_CHILD"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
          onChange={() => {
            form.setFieldsValue({
              physicalModelId: undefined,
            });
          }}
        />
      </Form.Item>
      <Form.Item label="品牌" name="manufacturerIds">
        <Select
          options={manufacturerOptions}
          mode="multiple"
          allowClear
          onChange={() => {
            form.setFieldsValue({
              physicalModelId: undefined,
            });
          }}
          showSearch
          optionFilterProp="label"
          placeholder="请选择"
        />
      </Form.Item>
      <Form.Item label="型号" name="physicalModelIds">
        <Select
          options={objectModalTypeOptions}
          mode="multiple"
          showSearch
          optionFilterProp="label"
          allowClear
          placeholder="请选择"
        />
      </Form.Item>
      <Form.Item label="所属组织" name="mcids">
        <TreeSelect
          treeData={treeData}
          allowClear
          treeCheckStrictly
          multiple
          showSearch={false}
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          treeCheckable
          placeholder="请选择"
        />
      </Form.Item>
      <Form.Item label="运营单元" name="ouIds">
        <Select
          options={ouOptions}
          mode="multiple"
          allowClear
          showSearch
          optionFilterProp="label"
          placeholder="请选择"
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <Space size={8} style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate(`/asset/equipment/ledger/create`);
          }}
        >
          新建设备台账
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setOpenImportModal(true);
          }}
        >
          批量导入
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setOpenImportLogModal(true);
          }}
        >
          导入日志
        </Button>
        <Button
          type="primary"
          disabled={selectedKeys.length === 0}
          onClick={() => {
            if (selectedKeys.length === 0) {
              Modal.warning({
                title: '请先选择要导出的设备',
              });
              return;
            }
            setCheckedData([]);
            setSelectedKeys([]);
            window.open(`/api/v2/device-ledger/export?ids=${selectedKeys.join()}`);
          }}
        >
          批量导出
        </Button>
        <Button
          type="primary"
          disabled={selectedKeys.length === 0}
          onClick={() => {
            if (selectedKeys.length === 0) {
              Modal.warning({
                title: '请先选择要状态变更的设备',
              });
              return;
            }
            setCurrentIds(selectedKeys);
            setCurrentState(undefined);
            setStateModalVisible(true);
          }}
        >
          状态变更
        </Button>
      </Space>
      <Table
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={buildColumns}
        scroll={{ x: 1300 }}
        dataSource={data}
        rowKey="id"
        loading={loading}
      />
      <Paging pagingInfo={pagingInfo} />
      {stateModalVisable && (
        <StateChangeModal
          ids={currentIds}
          currentState={currentState}
          visible={stateModalVisable}
          onCancel={() => setStateModalVisible(false)}
          onConfirm={() => {
            updateFn();
            setCheckedData([]);
            setSelectedKeys([]);
            setStateModalVisible(false);
          }}
        />
      )}
      {openImportModal && (
        <UploadImport title="设备台账导入" type={LogType.LEDGER} setOpen={setOpenImportModal} updateFn={updateFn} />
      )}
      {openImportLogModal && <ImportLog onClose={() => setOpenImportLogModal(false)} type={LogType.LEDGER} />}

      <Modal
        title="设备图片"
        open={previewOpen}
        destroyOnClose
        footer={() => (
          <Button type="primary" onClick={() => setPreviewOpen(false)}>
            确定
          </Button>
        )}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="preview" style={{ width: '100%' }} src={getRealUrl(currentPic)} />
      </Modal>
    </Wrapper>
  );
};

export default EquipmentLedger;
