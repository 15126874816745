import React, { useCallback, useEffect, useState } from 'react';
import { Cascader, Button as AntdButton, Space, Form } from 'antd';
import { Table, Button, Select, Modal } from '@maxtropy/components';
import { ColumnsType } from 'antd/es/table';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { PhysicalQuantityDisplay } from '@/api/energyConsumption';
import { getEnergyMediumSceneToMedium } from '@/api/workingSubstance';
import { isEqual } from 'lodash-es';
import {
  apiV2EnergyAnalysisAnalysisGroupMeasurementListPost,
  apiV2EnergyAnalysisSaveAnalysisGroupMeasurementPost,
} from '@maxtropy/device-customer-apis-v2';

type EnergyGroupMeterSettingModalProps = {
  open: boolean;
  closeModal: () => void;
  // 分析组 id
  energyGroupId: React.Key;
};

type EnergyGroupMeterSetting = {
  // 例如: [500, 2001], [给排水/用水, 新水]
  id?: number;
  meterTypeIds?: number[];
  physicalQuantityId?: number;
};

type FormValues = {
  energyGroupMeterSetting: EnergyGroupMeterSetting[];
};

type MeterTypeOption = {
  value?: React.Key;
  label?: string;
  children?: MeterTypeOption[];
  disabled?: boolean;
};

// 分析组计量设置(总)
export default function EnergyGroupMeterSettingModal({
  open,
  closeModal,
  // 分析组 id
  energyGroupId,
}: EnergyGroupMeterSettingModalProps) {
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [meterTypeOptions, setMeterTypeOptions] = useState<MeterTypeOption[]>([]);
  const energyGroupMeterSetting = Form.useWatch('energyGroupMeterSetting', form);

  console.log('energyGroupMeterSetting', energyGroupMeterSetting);
  console.log('meterTypeOptions', meterTypeOptions);

  // 获取互斥的计量类别, 已选择的需要被设置的 disable
  const getExclusiveMeterTypeOptions = useCallback(
    (currentValue?: React.Key[]) => {
      // 除自己以外已选择的计量类别
      const exclusiveSelectedMeterTypes = energyGroupMeterSetting
        ?.filter(s => s?.meterTypeIds?.length)
        ?.filter(s => (currentValue ? !isEqual(currentValue, s.meterTypeIds) : true));

      const options = meterTypeOptions.map(o => {
        return {
          ...o,
          children: o?.children?.map(v => ({
            ...v,
            // v 为二级类目, 其下的 v.value 为单个值, 例如 2002, 而 exclusiveSelectedMeterTypes 为 array, 包含一级和二级, 例如 [500, 2002]
            disabled: exclusiveSelectedMeterTypes.map(item => item.meterTypeIds?.[1]).includes(Number(v?.value)),
          })),
        };
      });

      return options;
    },
    [energyGroupMeterSetting, meterTypeOptions]
  );

  // 获取能源介质下拉列表
  useEffect(() => {
    getEnergyMediumSceneToMedium().then(res => {
      setMeterTypeOptions(
        res.map(v => ({
          value: v.id,
          label: v.sceneName,
          children: v.energyMediums.map(m => ({
            value: m.id,
            label: m.energyName,
          })),
        }))
      );
    });
  }, []);

  // 获取已配置的分析组力量设置
  useEffect(() => {
    setLoading(true);
    apiV2EnergyAnalysisAnalysisGroupMeasurementListPost({ energyUnitGroupId: energyGroupId })
      .then(res => {
        form.setFieldValue(
          'energyGroupMeterSetting',
          (res.list ?? []).map(s => ({
            id: s.id,
            physicalQuantityId: s.basePhysicalQuantityId,
            meterTypeIds: [s.energyMediumParentId, s.energyMediumChildId],
          }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [energyGroupId, form]);

  const columns: ColumnsType<EnergyGroupMeterSetting> = [
    {
      title: '计量类别',
      dataIndex: 'meterTypeIds',
      ellipsis: { showTitle: true },
      render: (v: number[], record, index) => {
        return (
          <Form.Item
            name={['energyGroupMeterSetting', index, 'meterTypeIds']}
            rules={[{ required: true, message: '请选择计量类别' }]}
            style={{ marginBottom: 0 }}
          >
            <Cascader
              displayRender={label => label.join('-')}
              options={getExclusiveMeterTypeOptions(energyGroupMeterSetting?.[index]?.meterTypeIds)}
              placeholder="请选择计量类别"
            />
          </Form.Item>
        );
      },
    },
    {
      title: '基础物理量',
      dataIndex: 'physicalQuantityId',
      ellipsis: { showTitle: true },
      render: (v: number, record, index) => {
        return (
          <Form.Item
            name={['energyGroupMeterSetting', index, 'physicalQuantityId']}
            rules={[{ required: true, message: '请选择基础物理量' }]}
            style={{ marginBottom: 0 }}
          >
            <Select
              allowClear
              options={Object.entries(PhysicalQuantityDisplay).map(([k, v]) => ({
                label: v,
                value: Number(k),
              }))}
              placeholder="请选择基础物理量"
            />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      width: '10%',
      render: (value: any, record, index: number) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                const newValue = energyGroupMeterSetting?.filter((v, i: number) => index !== i);
                form.setFieldValue('energyGroupMeterSetting', newValue);
              }}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Modal
      title="分析组计量设置"
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        closeModal();
      }}
      size="large"
      bodyScroll={true}
    >
      <div style={{ margin: '10px 0' }}>
        <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
        <span style={{ color: 'ragb(255, 255, 255, 0.45)', marginLeft: 4 }}>修改原有设置会重置相关单元计量属性。</span>
      </div>
      <Form
        form={form}
        onFinish={values => {
          apiV2EnergyAnalysisSaveAnalysisGroupMeasurementPost({
            energyAnalysisGroupId: Number(energyGroupId)!,
            list: values.energyGroupMeterSetting.map(v => ({
              energyMediumId: v.meterTypeIds?.[1],
              basePhysicalQuantityId: v.physicalQuantityId,
            })),
          }).then(() => {
            closeModal();
          });
        }}
      >
        <Form.Item name="energyGroupMeterSetting" valuePropName="dataSource">
          <Table columns={columns} loading={loading} rowKey="id" />
        </Form.Item>
        <Form.Item noStyle>
          <AntdButton
            style={{ marginBottom: 16 }}
            type="dashed"
            onClick={() => {
              const settings = form.getFieldValue('energyGroupMeterSetting') ?? [];
              form.setFieldValue('energyGroupMeterSetting', [
                ...settings,
                {
                  id: Math.random(),
                  meterTypeIds: undefined,
                  physicalQuantityId: undefined,
                },
              ]);
            }}
            block
            icon={<PlusOutlined />}
          >
            新增计量设置
          </AntdButton>
        </Form.Item>
      </Form>
    </Modal>
  );
}
