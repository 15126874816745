import { Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isNil, isEmpty } from 'lodash-es';
import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Tag,
  BaseInfoContent,
  Form,
  FormTitle,
} from '@maxtropy/components';
import {
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  GateAlarmLogicDisplay,
  GatewayAlarmCoolDownModeDisplay,
  GatewayAlarmType,
  GatewayAlarmTypeDisplay,
  IotProtocolType,
  IotProtocolTypeDisplay,
  AlarmChannel,
} from '@/shared/types';
import { parseOnDisplay } from '@/shared/components/FormulaInput';
import { sliceDeviceTypeName, useQuery } from '@/shared/utils/utils';
import { getDataProperties } from '@/shared/api/options';
import styles from '../List/index.module.scss';
import { getPlatformGatewayId } from '@/api/alarm';
import {
  V2RuleInfoPostResponse,
  apiV2RuleInfoPost,
  apiV2RuleGetChannelPost,
  V2RuleGetChannelPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { LongShineDetailItem } from '@/pages/Alarm/Detail/components/AlarmChannelDetailItem/LongShineDetailItem';
import { TripartiteServer } from '@/pages/Alarm/Detail/components/AlarmChannelDetailItem/TripartiteServer';

const routes = [{ name: '设备报警规则详情' }];

const AlarmDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [info, setInfo] = useState<V2RuleInfoPostResponse>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [platformGatewayId, setPlatformGatewayId] = useState<number>(5003);
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();

  const DetailItemMapByChannel = {
    [AlarmChannel.LONGSHINE as AlarmChannel]: LongShineDetailItem(info),
    [AlarmChannel.THIRD as AlarmChannel]: TripartiteServer(info),
  };

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  // 平台网关设备类目 id
  useEffect(() => {
    getPlatformGatewayId().then(res => {
      setPlatformGatewayId(res.id);
    });
  }, []);

  useEffect(() => {
    if (id) {
      apiV2RuleInfoPost({ id: +id }).then(res => {
        setInfo(res);
      });
    }
  }, [id]);

  const { data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        info?.iotProtocol && !isNil(info?.deviceTypeId)
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const dataPropertiesMap = useMemo(
    () => new Map(dataProperties.map(item => [String(item.id), item.name])),
    [dataProperties]
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.detail}>
      <FormTitle title={routes[0].name} />
      <SubContent>
        <BaseInfoContent layout="vertical">
          <Form.Item label="报警规则名称">{info?.name ?? '--'}</Form.Item>
          <Form.Item label="报警等级">
            {info ? (
              <Tag border="solid" color={AlarmLevelColorDisplay[info.alarmType!]}>
                {AlarmLevelDisplay[info.alarmType!]}
              </Tag>
            ) : (
              '--'
            )}
          </Form.Item>
          <Form.Item label="报警信息">{info?.alarmName ?? '--'}</Form.Item>
          {info && (
            <Form.Item label="渠道">{alarmChannelList?.find(i => i.code === info.channel)?.desc ?? '--'}</Form.Item>
          )}

          {info?.channel === AlarmChannel.MAXTROPY && (
            <>
              <Form.Item label="物联层协议">
                {info?.iotProtocol ? IotProtocolTypeDisplay[info.iotProtocol] : '--'}
              </Form.Item>
              {info?.iotProtocol === IotProtocolType.MOCKINGBIRD && (
                <>
                  <Form.Item label="适用设备类目">
                    {info?.deviceTypeName ? sliceDeviceTypeName(info?.deviceTypeName) : '--'}
                  </Form.Item>

                  <Form.Item label="物模型型号">{info?.modelNo ?? '--'}</Form.Item>

                  {info?.deviceTypeId === platformGatewayId ? (
                    <>
                      <Form.Item label="报警方式">{GatewayAlarmTypeDisplay[info?.alarmMode!]}</Form.Item>
                      {info?.alarmMode === GatewayAlarmType.CLOUD ? (
                        <>
                          <Form.Item label="报警逻辑">{GateAlarmLogicDisplay[info?.alarmLogic!]}</Form.Item>
                          <Form.Item label="离线持续时间">{info?.offlineDuration ?? '--'} min</Form.Item>
                          <Form.Item label="报警冷却时间">
                            {GatewayAlarmCoolDownModeDisplay[info?.coolDownMode!]}: {info?.coolDownTime ?? '--'} min
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item label="报警持续时间">{info?.duration ?? '--'} ms</Form.Item>
                          <Form.Item label="关联属性">
                            {isEmpty(info?.relatedPropertyIds)
                              ? '--'
                              : dataProperties
                                  .filter(v => info?.relatedPropertyIds?.includes(v.id))
                                  .map(v => v.name)
                                  .join(', ')}
                          </Form.Item>
                          <Form.Item label="报警公式">
                            {parseOnDisplay(info?.formula, dataPropertiesMap) ?? '--'}
                          </Form.Item>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Item label="报警持续时间">{info?.duration ?? '--'} ms</Form.Item>
                      <Form.Item label="报警公式">
                        <div style={{ wordBreak: 'break-all' }}>
                          {parseOnDisplay(info?.formula, dataPropertiesMap) ?? '--'}
                        </div>
                      </Form.Item>
                      <Form.Item label="关联属性">
                        {isEmpty(info.relatedPropertyIds)
                          ? '--'
                          : dataProperties
                              .filter(v => info.relatedPropertyIds?.includes(v.id))
                              .map(v => v.name)
                              .join(', ')}
                      </Form.Item>
                    </>
                  )}
                </>
              )}
              {info?.iotProtocol === IotProtocolType.ESTUN && (
                <>
                  <Form.Item label="边缘上报的报警编号">{info?.alarmCode ?? '--'}</Form.Item>
                  <Form.Item label="故障代码">{info?.faultCode ?? '--'}</Form.Item>
                </>
              )}
            </>
          )}
          {DetailItemMapByChannel[info?.channel as AlarmChannel]}
        </BaseInfoContent>
      </SubContent>
      <Space className="sticky-footer">
        <Button>
          <Link to={`/device/rule/list?tabs=${tabs}`}>返回</Link>
        </Button>
      </Space>
    </Wrapper>
  );
};

export default AlarmDetail;
