import React, { useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import dayjs, { Dayjs } from 'dayjs';
import { getDefaultTime, getTs } from '../../utils';
import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { MediumIndicatorStatisticsReq } from '@/api/energyMedium';
import { Button, DatePicker, Radio, message } from '@maxtropy/components';
import {
  apiV2MediumIndicatorDashboardGetProcessEntryOrExitMonitoringIndicatorsDevicePost,
  V2MediumIndicatorDashboardGetProcessEntryOrExitMonitoringIndicatorsDevicePostResponse,
} from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

export interface DateBtn {
  // btnType: DatePickerType;
  aggrby: StatisticsPartition;
  rangeDays?: number;
}

export enum StatisticsPartition {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  DAY = 'D',
  MONTH = 'M',
}

const datePickerTypeDisplay = {
  [StatisticsPartition.MINUTE_1]: '1分钟',
  [StatisticsPartition.MINUTE_15]: '15分钟',
  [StatisticsPartition.DAY]: '按日',
  [StatisticsPartition.MONTH]: '按月',
};

export interface DateSwitchProps {
  btnGroup?: DateBtn[];
  selectBtn: StatisticsPartition;
  value: any;
  style?: React.CSSProperties;
  onQueryChange: (value: MediumIndicatorStatisticsReq) => void;
  isEntryOrExit: boolean;
  query: MediumIndicatorStatisticsReq;
}

const DateSwitch: React.FC<DateSwitchProps> = props => {
  const { btnGroup = [], selectBtn, value, onQueryChange, style, isEntryOrExit, query } = props;
  const [deviceInfo, setDeviceInfo] =
    useState<V2MediumIndicatorDashboardGetProcessEntryOrExitMonitoringIndicatorsDevicePostResponse>();

  const onRadioChange = (e: RadioChangeEvent) => {
    const target = btnGroup.find(item => item.aggrby === e.target.value);
    if (target) {
      onQueryChange({
        timeResolution: target.aggrby,
        ...getDefaultTime(target.aggrby, dayjs()),
      });
    }
  };

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (
        selectBtn === StatisticsPartition.DAY ||
        selectBtn === StatisticsPartition.MINUTE_15 ||
        selectBtn === StatisticsPartition.MINUTE_1
      ) {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
        const mostDays =
          selectBtn === StatisticsPartition.DAY ? 31 : selectBtn === StatisticsPartition.MINUTE_1 ? 1 : 7;
        if (diff >= mostDays) {
          return message.warning(`最大上限为${mostDays}天`);
        }
      } else if (selectBtn === StatisticsPartition.MONTH) {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
        if (diff >= 24) {
          return message.warning('最大上限为24个月');
        }
      }
      onQueryChange({
        timeResolution: selectBtn,
        ...getTs(selectBtn, dayjs(beginDate), dayjs(endDate)),
      });
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const diffDays = dayjs(value[1]).diff(dayjs(value[0]), 'd');
  const diffMonths = dayjs(value[1]).diff(dayjs(value[0]), 'months');

  // 监听Query，请求设备数据，进行跳转
  useEffect(() => {
    if (query?.indicatorId && query.processEntryOrExitId && query.from && query.to) {
      console.log(query);
      apiV2MediumIndicatorDashboardGetProcessEntryOrExitMonitoringIndicatorsDevicePost({
        processEntryOrExitId: query.processEntryOrExitId,
        type: query.type,
        indicatorId: query.indicatorId,
        startTime: dayjs(query.from).unix().toString(),
        endTime: dayjs(query.to).unix().toString(),
      }).then(res => {
        setDeviceInfo(res);
      });
    }
  }, [query]);

  return (
    <div className={styles.dateSwitch} style={style}>
      <div className={styles.switchBtn}>
        <Radio.Group onChange={onRadioChange} value={selectBtn} buttonStyle="solid">
          {btnGroup?.map(btn => (
            <Radio.Button key={btn.aggrby} value={btn.aggrby}>
              {datePickerTypeDisplay[btn.aggrby as StatisticsPartition]}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      {isEntryOrExit && deviceInfo && (
        <Button
          style={{ paddingLeft: 8, paddingRight: 0 }}
          type="link"
          onClick={() => {
            window.open(
              `/data/history/device?deviceId=${deviceInfo?.deviceId}&dataPropertyId=${deviceInfo?.dataPropertyId}`,
              '_blank'
            );
          }}
        >
          原始数据
        </Button>
      )}
      <div className={styles.datePickerArea}>
        {/* <span className={styles.label}>日期</span> */}
        {(selectBtn === StatisticsPartition.MINUTE_15 || selectBtn === StatisticsPartition.MINUTE_1) && (
          <>
            <RangePicker
              value={value}
              onChange={onChangeRangePicker}
              disabledDate={disabledDate}
              allowClear={false}
              // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              // placement="bottomLeft"
            />
            <Button
              icon={<LeftOutlined />}
              className={styles.hidden}
              style={{ margin: '0 10px' }}
              onClick={() => {
                onQueryChange({
                  timeResolution: selectBtn,
                  ...getTs(selectBtn, dayjs(value[0]).subtract(diffDays + 1, 'd'), dayjs(value[0]).subtract(1, 'd')),
                });
              }}
            />
            <Button
              className={styles.hidden}
              icon={<RightOutlined />}
              disabled={
                dayjs(value[1])
                  .add(diffDays + 1, 'd')
                  .startOf('d') > dayjs().startOf('d')
              }
              onClick={() => {
                onQueryChange({
                  timeResolution: selectBtn,
                  ...getTs(selectBtn, dayjs(value[1]).add(1, 'd'), dayjs(value[1]).add(diffDays + 1, 'd')),
                });
              }}
            />
          </>
        )}
        {(selectBtn === StatisticsPartition.DAY || selectBtn === StatisticsPartition.MONTH) && (
          <>
            <RangePicker
              value={value}
              disabledDate={disabledDate}
              onChange={onChangeRangePicker}
              picker={selectBtn === StatisticsPartition.DAY ? 'date' : 'month'}
              allowClear={false}
              // getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
              // placement="bottomLeft"
            />
            <Button
              icon={<LeftOutlined />}
              style={{ margin: '0 10px' }}
              onClick={() => {
                const ts =
                  selectBtn === StatisticsPartition.MONTH
                    ? getTs(
                        selectBtn,
                        dayjs(value[0]).subtract(diffMonths + 1, 'months'),
                        dayjs(value[0]).subtract(1, 'months')
                      )
                    : getTs(selectBtn, dayjs(value[0]).subtract(diffDays + 1, 'd'), dayjs(value[0]).subtract(1, 'd'));
                onQueryChange({
                  timeResolution: selectBtn,
                  ...ts,
                });
              }}
            />
            <Button
              icon={<RightOutlined />}
              disabled={
                selectBtn === StatisticsPartition.MONTH
                  ? dayjs(value[1])
                      .add(diffMonths + 1, 'months')
                      .endOf('m') > dayjs().endOf('months')
                  : dayjs(value[1])
                      .add(diffDays + 1, 'd')
                      .startOf('d') > dayjs().startOf('d')
              }
              onClick={() => {
                const ts =
                  selectBtn === StatisticsPartition.MONTH
                    ? getTs(selectBtn, dayjs(value[1]).add(1, 'months'), dayjs(value[1]).add(diffMonths + 1, 'months'))
                    : getTs(selectBtn, dayjs(value[1]).add(1, 'd'), dayjs(value[1]).add(diffDays + 1, 'd'));
                onQueryChange({
                  timeResolution: selectBtn,
                  ...ts,
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DateSwitch;
