import {
  getRealUrl,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Empty,
  Tooltip,
} from '@maxtropy/components';
import { Card, Col, Row, Spin } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AIReportTenantList, getAIReportTenantPage } from '../../api/aireport';
import defaultPic from './file.png';
import styles from './index.module.scss';

// 下载文件并重命名
function onDownload(url: string, fileName: string) {
  let x = new XMLHttpRequest();
  x.open('GET', `${url}`, true);
  x.responseType = 'blob';
  x.onload = function () {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.pdf`;
    a.click();
  };
  x.send();
}

// 时间格式化
const dateFormat = 'YYYY[年]MM[月]DD[日]';

const AIReportList: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateState, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [aIReportList, setAIReportList] = useState<AIReportTenantList[]>([]);

  // 列表查询
  useEffect(() => {
    setLoading(true);
    getAIReportTenantPage({
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      if (res) {
        setLoading(false);
        setTotalCount(res.total);
        setAIReportList(res.list);
      }
    });
  }, [pageOffset, pageSize, setTotalCount, updateState]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      <Spin spinning={loading}>
        <Row gutter={24}>
          {aIReportList && aIReportList.length > 0 ? (
            aIReportList.map(item => (
              <Col key={item.id} lg={6} md={8} sm={24}>
                <Card
                  style={{ margin: '20px' }}
                  className={styles.cusCard}
                  actions={[
                    <Button
                      type="link"
                      key="preview"
                      onClick={e => {
                        e.stopPropagation();
                        if (item.fileKey) {
                          window.open(getRealUrl(item.fileKey) + '&preview=true', 'xxx');
                        }
                      }}
                    >
                      预览
                    </Button>,
                    <Button
                      type="link"
                      key="download"
                      onClick={e => {
                        e.stopPropagation();
                        if (item.fileKey) {
                          onDownload(getRealUrl(item.fileKey) as string, item.name);
                        }
                      }}
                    >
                      下载
                    </Button>,
                  ]}
                >
                  <Meta
                    avatar={<img src={defaultPic} alt=""></img>}
                    title={
                      <Tooltip title={item.name}>
                        <div>{item.name}</div>
                      </Tooltip>
                    }
                    description={`分析时间：${dayjs(item.analysisDate).format(dateFormat)}`}
                  />
                </Card>
              </Col>
            ))
          ) : (
            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Empty style={{ padding: '36px 0' }} description="暂无数据" />
            </Col>
          )}
        </Row>
      </Spin>
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default AIReportList;
