import { EnergyFlowChartNodeItem, EnergyFlowChartResponse } from '@/api/plustekSankey';
import ReactEcharts from 'echarts-for-react';
import { formatCompositeNumWithUnit, formatMediaNumWithUnit } from '../../utils';
import { getEmpty } from '../..';
import { Key } from '@maxtropy/components';
import { useMemo } from 'react';

interface Props {
  data?: EnergyFlowChartResponse;
  physicalUnitName?: string;
  isMedium: boolean;
  setModalOpen: (open: boolean) => void;
  setChartSelectedUnitId: (id: Key) => void;
}

const color = ['#57FB8BCC', '#52E7FFCC', '#2D8DFFCC', '#CE90D1CC', '#FF9247CC'];

export const formatNum = (num: number, isMedium: boolean, unit?: string) => {
  return isMedium ? formatMediaNumWithUnit(num, unit) : formatCompositeNumWithUnit(num);
};

export const getNodeMaxDepth = (nodes: EnergyFlowChartNodeItem[]) => {
  const all_depth = nodes.map(i => i.depth);
  return Math.max(...all_depth);
};

const UnitSankey: React.FC<Props> = ({ data, physicalUnitName, isMedium, setModalOpen, setChartSelectedUnitId }) => {
  const chartOption = useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        backgroundColor: '#000',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
          fontSize: 12,
        },
        formatter: (v: any) => {
          let res = '';
          if (v.dataType === 'node') {
            res = `<div>
          <p>${v.data.energyUnitName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${formatNum(
            v.value,
            isMedium,
            physicalUnitName
          )}</p>
          </div>`;
          }
          if (v.dataType === 'edge') {
            const sourceName = data?.nodes?.find(i => i.key === v.data.source)?.energyUnitName;
            const targetName = data?.nodes?.find(i => i.key === v.data.target)?.energyUnitName;
            res = `<div>
          <p>${sourceName}——${targetName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${formatNum(
            v.value,
            isMedium,
            physicalUnitName
          )}</p>
          </div>`;
          }

          return res;
        },
      },
      series: [
        {
          type: 'sankey',
          left: 0,
          right: 0,
          layoutIterations: 0, // 解决能流图衔接问题
          nodeAlign: 'justify',
          label: {
            color: 'rgba(255,255,255,0.85)',
            rich: {
              a: {
                color: '#FF4D4F',
              },
              b: {
                color: '#ABD335',
              },
            },
            formatter: (v: any) => {
              if (v.data.energyUnitName === '未知能耗') {
                return `{a|${v.data.energyUnitName}}`;
              }
              if (v.data.energyUnitName === '用能损耗') {
                return `{b|${v.data.energyUnitName}}`;
              }
              return `${v.data.energyUnitName}`;
            },
          },
          labelLayout: {
            hideOverlap: true,
          },
          draggable: false,
          data: data?.nodes?.map(i => {
            return {
              ...i,
              itemStyle: {
                color:
                  i.energyUnitName === '用能损耗'
                    ? '#ABD335'
                    : i.energyUnitName === '未知能耗'
                    ? '#FF4D4F'
                    : color[i.depth % color.length],
              },
              label: {
                position: i.depth === getNodeMaxDepth(data?.nodes) ? 'left' : 'right',
              },
            };
          }),
          links: data?.links?.map(i => {
            return {
              ...i,
              lineStyle: {
                color: 'gradient',
                opacity: 0.3,
                // curveness: 0.5, // sankey图边曲度，尽量不要设置，在苹果电脑上展示会有问题
              },
            };
          }),
        },
      ],
    };
  }, [data, physicalUnitName, isMedium]);

  const isEmptyChart = useMemo(() => {
    const all_depth = (data?.nodes ?? []).map(i => i.depth);
    const max_depth = Math.max(...all_depth);
    const min_depth = Math.min(...all_depth);
    return data?.nodes.length === 0 || data?.links.length === 0 || max_depth === min_depth;
  }, [data]);

  return isEmptyChart ? (
    getEmpty()
  ) : (
    <ReactEcharts
      style={{ height: `calc(100vh - 340px)`, minHeight: '500px', overflowX: 'hidden', width: '100%' }}
      option={chartOption}
      notMerge
      lazyUpdate={false}
      onEvents={{
        click: (v: any) => {
          if (v.dataType === 'node' && v.data.energyUnitName !== '未知能耗' && v.data.energyUnitName !== '用能损耗') {
            setModalOpen(true);
            setChartSelectedUnitId(v.data.energyUnitId);
          }
        },
      }}
    />
  );
};

export default UnitSankey;
