import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import classnames from 'classnames/bind';

import { useGetElementWidth } from './hooks';
const cx = classnames.bind(styles);

interface Iprops {
  children?: React.ReactNode;
  selectors?: string;
  el?: HTMLDivElement;
}
/**
 *
 * @description
 * 底部按钮布局问题, 动态计算按钮所在外层容器的宽度
 * 个人建议通过粘性布局解决
 */
const FixedBottomButton: React.FC<Iprops> = ({ children, el, selectors = '.ant-layout .central-menu-content' }) => {
  const [dependentDom, setDependentDom] = useState<HTMLDivElement>();
  const width = useGetElementWidth(dependentDom);
  useEffect(() => {
    if (el) {
      setDependentDom(el);
      return;
    }
    let temp = document.querySelector(selectors) as HTMLDivElement;
    setDependentDom(temp);
  }, [selectors, el]);

  return (
    <div className={cx({ bottomFixButtonWrapper: true, showBackground: !!width })} style={{ width: width ?? 'auto' }}>
      <div className={styles.bottomFixButtonInner}>{children}</div>
    </div>
  );
};
export default FixedBottomButton;
