import { WorkOrderRes, getAllWorkOrderList, getBindWorkOrderListById } from '@/api/energyUnitMapping';
import { EllipsisSpan, Key, Table, Button, Form, Modal, ShowInput } from '@maxtropy/components';
import { Col, Divider, Row, Transfer, Card, Space } from 'antd';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import type { TransferItem, TransferProps } from 'antd/es/transfer';
import { difference } from 'lodash-es';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { V2ProductionBaseDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
interface TransferDataItem extends WorkOrderRes {
  key: string;
}

interface TableTransferProps extends TransferProps<TransferItem> {
  dataSource: TransferDataItem[];
  leftColumns: ColumnsType<TransferDataItem>;
  rightColumns: ColumnsType<TransferDataItem>;
}

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  id?: Key;
  workCenterDetail?: V2ProductionBaseDetailPostResponse;
  onBind?: (v: Key[]) => void;
}

export function compareArray(a: Key[], b: Key[]) {
  if (a.length !== b.length) return false;
  let res = true;
  for (let i = 0; i < a.length; i++) {
    if (a[i] === b[i]) {
      continue;
    } else {
      res = false;
      break;
    }
  }
  return res;
}

const leftTableColumns: ColumnsType<TransferDataItem> = [
  {
    dataIndex: 'code',
    title: '工序编码',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },

  {
    dataIndex: 'name',
    title: '工序名称',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const BindOrderModal: React.FC<Props> = ({ open, setOpen, id, workCenterDetail, onBind }) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [data, setData] = useState<TransferDataItem[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBindKeys, setHasBindKeys] = useState<string[]>([]);

  useEffect(() => {
    if (workCenterDetail) {
      form.setFieldsValue({
        code: workCenterDetail.code,
        name: workCenterDetail.name,
      });
    }
  }, [workCenterDetail, form]);

  useEffect(() => {
    // 获取所有工序
    setLoading(true);
    getAllWorkOrderList()
      .then(res => {
        if (res.list) {
          setData(
            res.list.map(i => ({
              ...i,
              key: i.id as string,
            }))
          );
          if (id) {
            // 获取已绑
            getBindWorkOrderListById(id).then(bindRes => {
              setTargetKeys(bindRes.list.map(i => i.id as string));
              setHasBindKeys(bindRes.list.map(i => i.id as string));

              // 将禁用的工序正常显示
              if (bindRes.list.length > 0) {
                const disabledIds = difference(
                  bindRes.list.map(i => i.id),
                  res.list.map(i => i.id)
                );
                const disabled: TransferDataItem[] = [];

                bindRes.list.forEach(i => {
                  if (disabledIds.includes(i.id)) {
                    disabled.push({
                      ...i,
                      key: i.id as string,
                    });
                  }
                });

                setData([
                  ...res.list.map(i => ({
                    ...i,
                    key: i.id as string,
                  })),
                  ...disabled,
                ]);
              }
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const onChange = (nextTargetKeys: Key[]) => {
    setTargetKeys(nextTargetKeys);
  };

  const rightTableColumns: ColumnsType<TransferDataItem> = [
    ...leftTableColumns,
    {
      title: '操作',
      render: (v, record, index) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                let key = targetKeys[index];
                let keys = targetKeys.slice(0, index).concat(targetKeys.slice(index + 1));
                keys.splice(index - 1, 0, key);
                setTargetKeys(keys);
              }}
              disabled={index === 0}
            >
              上移
            </Button>
            <Button
              type="link"
              onClick={() => {
                let key = targetKeys[index];
                let keys = targetKeys.slice(0, index).concat(targetKeys.slice(index + 1));
                keys.splice(index + 1, 0, key);
                setTargetKeys(keys);
              }}
              disabled={index === targetKeys.length - 1}
            >
              下移
            </Button>
          </Space>
        );
      },
    },
  ];

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }: TableTransferProps) => (
    <Transfer {...restProps}>
      {({ direction, filteredItems, onItemSelectAll, onItemSelect, selectedKeys: listSelectedKeys }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;

        const rowSelection: TableRowSelection<TransferItem> = {
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows.map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys as string[], selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key as string, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            loading={loading}
            rowKey="id"
            rowSelection={rowSelection}
            // @ts-ignore
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ maxHeight: 265, overflowY: 'auto' }}
          />
        );
      }}
    </Transfer>
  );

  return (
    <>
      <Modal
        title="绑定工序"
        size="big"
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          const isSame = compareArray(targetKeys, hasBindKeys);
          if (isSame) {
            setOpen(false);
            return;
          }
          onBind?.(targetKeys);
          setOpen(false);
        }}
        okText="保存"
      >
        <div>
          <Card className={styles.infoCard} size="small">
            <Form form={form} layout="vertical" size="small">
              <Row>
                <Col span={11}>
                  <Form.Item label="工作中心编码" name="code">
                    <ShowInput />
                  </Form.Item>
                </Col>
                <Divider className={styles.dividerStyle} type="vertical" />
                <Col span={11}>
                  <Form.Item label="工作中心名称" name="name">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>

          <TableTransfer
            titles={['工序合集', '已绑定工序']}
            dataSource={data}
            targetKeys={targetKeys as string[]}
            onChange={onChange}
            showSearch={true}
            filterOption={(inputValue, item) =>
              item.name!.indexOf(inputValue) !== -1 || item.code!.indexOf(inputValue) !== -1
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
          />
        </div>
      </Modal>
    </>
  );
};

export default BindOrderModal;
