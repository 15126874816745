import { Wrapper, Steps, useBreadcrumbRoutes, FormTitle, SubContent } from '@maxtropy/components';
import { useMemo, useState } from 'react';
import styles from './index.module.scss';
import SelectProduct from '../components/SelectProduct';
import MaintenanceInfo from '../components/MaintenanceInfo';
import CheckData from '../components/CheckData';
import GenerateBaseLine from '../components/GenerateBaseLine';
import { BaseLineContext, LineItemProps } from '../utils/common';

interface CreateBaseLineProps {
  isEdit?: boolean;
}

enum StepsType {
  First = 0,
  Second,
  Third,
  Four,
}

const steps = [
  {
    key: 1,
    title: '维护信息',
  },
  {
    key: 2,
    title: '校验数据',
  },
  {
    key: 3,
    title: '生成基线',
  },
];

const CreateBaseLine: React.FC<CreateBaseLineProps> = ({ isEdit }) => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [lineItem, setLineItem] = useState<LineItemProps>();
  const routeTitle = `${isEdit ? '编辑' : '新建'}单耗基线`;
  const routes = [{ name: routeTitle }];
  const stepItems = isEdit ? steps : [{ key: 0, title: '选择产品' }, ...steps];
  const [current, setCurrent] = useState<number>(0);

  const currentStep = useMemo(() => (isEdit ? current + 1 : current), [isEdit, current]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
        <FormTitle title={routeTitle}></FormTitle>
        <div className={styles.stepsWrapper}>
          <Steps current={current} items={stepItems} />
        </div>

        <SubContent>
          <BaseLineContext.Provider value={{ lineItem, setLineItem, isEdit }}>
            {currentStep === StepsType.First && <SelectProduct nextStep={next}></SelectProduct>}
            {currentStep === StepsType.Second && <MaintenanceInfo nextStep={next} prevStep={prev}></MaintenanceInfo>}
            {currentStep === StepsType.Third && <CheckData nextStep={next} prevStep={prev}></CheckData>}
            {currentStep === StepsType.Four && <GenerateBaseLine prevStep={prev}></GenerateBaseLine>}
          </BaseLineContext.Provider>
        </SubContent>
      </Wrapper>
    </>
  );
};
export default CreateBaseLine;
