import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Tooltip, getRealUrl } from '@maxtropy/components';
import { Node } from '@antv/x6';
import { isNil } from 'lodash-es';
import { EnergyWorkingProcessType } from '@/api/uet';

// 自定义节点
const PipeNode = ({ node }: { node: Node }) => {
  const { id, name, iconKey, exitCostVos, entryCostVos, type } = node.getData();
  const [base64String, setBase64String] = useState<string>('');

  useEffect(() => {
    if (isNil(iconKey)) return;
    // 创建一个 Image 元素
    const img = new Image();
    // 设置图片源
    img.src = getRealUrl(iconKey) as string;
    // 图片加载完成后执行回调
    img.onload = function () {
      // 创建一个 Canvas 元素
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置 Canvas 尺寸为图片尺寸
      canvas.width = img.width;
      canvas.height = img.height;

      // 将图片绘制到 Canvas 上
      if (ctx) {
        ctx.drawImage(img, 0, 0);
      }
      // 将 Canvas 中的图像转换为 Base64 字符串
      const dataURL = canvas.toDataURL('image/png');
      setBase64String(dataURL); // 更新状态
    };
  }, [iconKey]);

  const getPortTxt = (costVos: any, portName: string) => {
    if (isNil(costVos) || !costVos.length) return null;
    return (
      <>
        <div className={styles.port}>{portName}</div>
        {costVos?.map((vo: any) => (
          <div className={styles.row}>
            <div style={{ backgroundColor: vo.color ?? '#1890ff' }} className={styles.circle}></div>
            <div className={styles.nodeName}>{vo.name}</div>
            <div>{`${vo?.sum?.toFixed(2) ?? '--'}${vo?.unit ?? ''}`}</div>
          </div>
        ))}
      </>
    );
  };

  const title = (
    <div className={styles.titleWrapper}>
      <div className={styles.nodeTitle}>{name}</div>
      {getPortTxt(entryCostVos, '输入')}
      {getPortTxt(exitCostVos, '输出')}
    </div>
  );

  return (
    <div className={styles.customNode} key={id}>
      <Tooltip title={title} placement="bottom" overlayClassName={styles.nodeTip}>
        <div
          className={`${styles.nodeIcon} ${
            type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE ? styles.handleNode : styles.distributeNode
          }`}
        >
          {!isNil(iconKey) && (
            <img
              className={styles.nodeImg}
              alt="Base64"
              src={base64String} // 使用状态中的 Base64 字符串
            />
          )}
        </div>
      </Tooltip>

      <div className={styles.nodeName}>{name}</div>
    </div>
  );
};

export default PipeNode;
