import { Key, useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Modal,
  Paging,
  Select,
  Table,
  message,
  usePaging,
} from '@maxtropy/components';
import {
  apiV2WorkStationPage2Post,
  V2WorkStationPage2PostResponse,
  V2WorkStationPage2PostRequest,
  apiV2WorkCenterSequenceListPost,
  V2WorkCenterSequenceListPostResponse,
  apiV2WorkProcedureGetListByWorkCenterIdsPost,
  V2WorkProcedureGetListByWorkCenterIdsPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export type DataType = Exclude<V2WorkStationPage2PostResponse['list'], undefined>[number];
type WorkCenterSequenceData = Exclude<V2WorkCenterSequenceListPostResponse['list'], undefined>[number];
type WorkProcedureData = Exclude<V2WorkProcedureGetListByWorkCenterIdsPostResponse['list'], undefined>[number];

const columns: ColumnsType<DataType> = [
  {
    title: '工站编号',
    dataIndex: 'code',
    render: (v: string) => <EllipsisSpan value={v} />,
    width: 180,
  },
  {
    title: '工站名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,

    width: 180,
  },
  {
    title: '工序名称',
    dataIndex: 'procedureName',
    render: (v: string) => <EllipsisSpan value={v} />,

    width: 180,
  },
  {
    title: '工作中心名称',
    dataIndex: 'workCenterName',
    render: (v: string) => <EllipsisSpan value={v} />,
    width: 180,
  },
  {
    title: '生产基地名称',
    dataIndex: 'productionBaseName	',
    render: (v: string) => <EllipsisSpan value={v} />,
    width: 180,
  },
];

interface Prop {
  setOpen: (value: boolean) => void;
  selectedStations: DataType[];
  setSelectedStations: (value: DataType[]) => void;
}

const WorkStationModal = ({ selectedStations, setSelectedStations, setOpen }: Prop) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<DataType[]>([]);
  const [searchParams, setSearchParams] = useState<V2WorkStationPage2PostRequest>();
  const [workCenterSequenceData, setWorkCenterSequenceData] = useState<WorkCenterSequenceData[]>([]);
  const [workProcedureData, setWorkProcedureData] = useState<WorkProcedureData[]>([]);
  const [selectedRows, setSelectedRows] = useState<DataType[]>(selectedStations);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const workCenterId = Form.useWatch('workCenterId', form);

  useEffect(() => {
    apiV2WorkCenterSequenceListPost({}).then(res => {
      setWorkCenterSequenceData(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (workCenterId) {
      apiV2WorkProcedureGetListByWorkCenterIdsPost({ ids: [workCenterId] }).then(res => {
        setWorkProcedureData(res.list ?? []);
      });
    }
  }, [workCenterId]);

  useEffect(() => {
    apiV2WorkStationPage2Post({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      setData(res.list ?? []);
      setTotalCount(res.total ?? 0);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount]);

  const rowSelection = {
    selectedRowKeys: selectedRows.map(item => item.id) as Key[],
    onChange: (_: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRows);
    },
    preserveSelectedRowKeys: true,
  };

  const onFinish = (params: V2WorkStationPage2PostRequest) => {
    setSearchParams(params);
    setPageOffset(1);
  };

  const handleOk = () => {
    if (selectedRows.length < 1) {
      message.warning('请选择工站');
      return;
    }
    setSelectedStations(selectedRows);
    setOpen(false);
  };

  return (
    <Modal title="请选择工站" open={true} size="large" bodyScroll onCancel={() => setOpen(false)} onOk={handleOk}>
      <CustomFilter form={form} onFinish={onFinish} colSpan={8}>
        <Form.Item name="workCenterId" label="工作中心">
          <Select
            placeholder="请选择工作中心"
            options={workCenterSequenceData.map(item => ({ label: item.name, value: item.id }))}
            onChange={() => form.setFieldsValue({ procedureId: undefined })}
          />
        </Form.Item>
        <Form.Item name="procedureId" label="工序">
          <Select
            placeholder="请选择工序"
            options={workProcedureData.map(item => ({ label: item.name, value: item.id }))}
          />
        </Form.Item>
      </CustomFilter>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        rowKey="id"
        showSelectedCount
        selectedCount={selectedRows.length}
        columns={columns}
        dataSource={data}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </Modal>
  );
};
export default WorkStationModal;
