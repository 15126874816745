import type { OrganizationResponse } from '../../api/device';
import type { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';

export enum ActionType {
  CREATE = 'new',
  UPDATE = 'edit',
  DETAIL = 'detail',
}

export const ActionTypeDisplay = {
  [ActionType.CREATE]: '新增',
  [ActionType.UPDATE]: '编辑',
  [ActionType.DETAIL]: '查看',
};

export function formatTreeData(data: OrganizationResponse[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children ?? []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
}
