import { Key, useEffect, useMemo, useState } from 'react';
import { Input, Tree, Empty, Modal } from '@maxtropy/components';
import type { DataNode } from 'antd/es/tree';
import {
  apiV2ProductionBaseCompleteTreePost,
  V2ProductionBaseCompleteTreePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Space, Spin, TreeDataNode } from 'antd';

// 生产过程树最多五层，依次为 生产基地 -> 工作中心 -> 工序 -> 工站 -> 用能单元
const TREE_CHILD_KEYS = ['workCenterTree', 'procedureTree', 'stationTree', 'unitTree'];
export const TREE_CHILD_IDS = ['productionBaseId', 'workCenterId', 'workProcedureId', 'workStationId', 'energyUnitId'];

type listTreeProp = Exclude<V2ProductionBaseCompleteTreePostResponse['list'], undefined>[number];
type workCenterTreeProp = Exclude<listTreeProp['workCenterTree'], undefined>[number];
type procedureTreeProp = Exclude<workCenterTreeProp['procedureTree'], undefined>[number];
type stationTreeProp = Exclude<procedureTreeProp['stationTree'], undefined>[number];
type unitTreeProp = Exclude<stationTreeProp['unitTree'], undefined>[number];

type NodeDataProp = listTreeProp & workCenterTreeProp & procedureTreeProp & stationTreeProp & unitTreeProp;

export type DataListProp = {
  key: string;
  title: string;
  productionProcessName: string;
  productionBaseId?: number;
  workCenterId?: number;
  workProcedureId?: number;
  workStationId?: number;
  energyUnitId?: number;
}[];

let dataList: DataListProp = [];

function formatToNodeData(data: any, depth: number, extra: Record<string, any> = {}): DataNode[] {
  return (
    data?.map((i: any, index: number) => {
      let title = i.name ?? i.procedureName ?? i.stationName ?? i.energyUnitName;
      let id = i.id ?? i.procedureId ?? i.stationId ?? i.energyUnitId;
      let productionProcessName = `${extra?.productionProcessName ? extra?.productionProcessName + '/' : ''}${title}`;
      let key = `${productionProcessName}_${id}_${index}`;

      const list = {
        key,
        title,
        [TREE_CHILD_IDS[depth]]: id,
        ...extra,
        productionProcessName,
      };
      dataList.push(list);

      return {
        title,
        key,
        children: i[TREE_CHILD_KEYS[depth] as keyof NodeDataProp]
          ? formatToNodeData(i[TREE_CHILD_KEYS[depth] as keyof NodeDataProp], depth + 1, {
              ...extra,
              productionProcessName,
              [TREE_CHILD_IDS[depth]]: id,
            })
          : [],
        disabled: i.isOuPermissions === undefined ? false : !i.isOuPermissions,
      };
    }) || []
  );
}

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

interface AddProductionProcessProp {
  onCancel: () => void;
  onOk: (data: any) => void;
  defaultValues: DataListProp;
}

const AddProductionProcessModal = (props: AddProductionProcessProp) => {
  const { onCancel, onOk, defaultValues } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataNode[]>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [unitCheckedKeys, setUnitCheckedKeys] = useState<Key[]>([]);

  useEffect(() => {
    const keys = defaultValues.map(i => i.key);
    setExpandedKeys(keys);
    setUnitCheckedKeys(keys);
  }, [defaultValues]);

  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({})
      .then(res => {
        dataList = [];
        setData(res.list ? formatToNodeData(res.list, 0) : []);
      })
      .finally(() => setLoading(false));
  }, []);

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        let title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{ color: 'red' }}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );

        if (item.children) {
          return {
            title,
            key: item.key,
            children: loop(item.children),
            disabled: item.disabled,
            selectable: true,
          };
        }
        return {
          title,
          key: item.key,
          disabled: item.disabled,
          selectable: true,
        };
      });

    return data ? loop(data) : [];
  }, [searchValue, data]);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (keys: any) => {
    setUnitCheckedKeys(keys.checked);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const newExpandedKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeData);
        }
        return null;
      })
      .filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i));

    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const handleSubmit = () => {
    const nodeData = dataList.filter(item => unitCheckedKeys.indexOf(item.key) > -1);
    onOk(nodeData);
  };

  return (
    <Modal open={true} size="normal" title="选择对象" bodyScroll onOk={handleSubmit} onCancel={onCancel}>
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        <Input.Search placeholder="请输入" onChange={onInputChange} />
        <Spin spinning={loading}>
          {treeData.length > 0 ? (
            <Tree
              checkable
              checkStrictly
              checkedKeys={unitCheckedKeys}
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeData}
              onCheck={onCheck}
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </Space>
    </Modal>
  );
};

export default AddProductionProcessModal;
