import {
  useBreadcrumbRoutes,
  useTenantPermissions,
  Wrapper,
  Button,
  Select,
  Form,
  Input,
  Tabs,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { useState } from 'react';

import { AlarmLevel, AlarmLevelDisplay, AlarmStatus, AlarmStatusDisplay } from '@/shared/types';
import styles from './index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs';
import { V2RuleGroupPagePostRequest, V2RulePagePostRequest } from '@maxtropy/device-customer-apis-v2';
import { PermissionsType } from '@/common/permissionsConst';
import Rule from '@/pages/Alarm/List/Rule';
import RuleGroup from '@/pages/Alarm/List/RuleGroup';

const { Option } = Select;
// const routes = [
//   {name: 'Iot配置'},
//   {name: '报警规则'},
//   {name: '设备报警规则'},
// ];

const AlarmList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [tabsKey, setTabsKey] = useState<string>(tabs);

  const [searchParams, setSearchParams] = useState<V2RulePagePostRequest | V2RuleGroupPagePostRequest>();

  const permission = useTenantPermissions();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const onFinish = (val: V2RulePagePostRequest | V2RuleGroupPagePostRequest) => {
    setSearchParams({ ...val });
    const paramsMap = new Map<string, string>();
    Object.entries(val).forEach(([key, value]) => {
      value && paramsMap.set(key, value as string);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params = {
      nameOrCode: undefined,
      status: undefined,
      type: undefined,
      alarmType: undefined,
      ruleCode: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
  };

  const filters = (
    <CustomFilter form={form} onFinish={val => onFinish(val as any)} onReset={onReset}>
      <Form.Item label="编号/名称" name="nameOrCode">
        <Input placeholder=" 请输入编号或名称查询" />
      </Form.Item>

      {/* {tabsKey === '1' &&
        <Col span={4}>
          <Form.Item label='规则类型' name='type'>
            <Select placeholder='请选择'>
              <Option value={AlarmType.MARGIN}>{AlarmTypeDisplay[AlarmType.MARGIN]}</Option>
              <Option value={AlarmType.SERVER}>{AlarmTypeDisplay[AlarmType.SERVER]}</Option>
            </Select>
          </Form.Item>
        </Col>
      } */}

      <Form.Item label="状态" name="status">
        <Select placeholder="请选择">
          <Option value={AlarmStatus.ENABLE}>{AlarmStatusDisplay[AlarmStatus.ENABLE]}</Option>
          <Option value={AlarmStatus.DISABLE}>{AlarmStatusDisplay[AlarmStatus.DISABLE]}</Option>
        </Select>
      </Form.Item>

      {tabsKey === '1' && (
        <Form.Item label="报警等级" name="alarmType">
          <Select placeholder="请选择">
            <Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Option>
            <Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Option>
            <Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Option>
            <Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Option>
            <Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Option>
          </Select>
        </Form.Item>
      )}
      {tabsKey === '2' && (
        <Form.Item label="规则编号" name="ruleCode">
          <Input placeholder=" 请输入编号查询" />
        </Form.Item>
      )}
    </CustomFilter>
  );

  const onChangeTabs = (v: string) => {
    setTabsKey(v);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('tabs');
    urlSearchParams.append('tabs', v);
    navigate(`?${urlSearchParams.toString()}`);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      <div className={styles.operationArea}>
        <Space size={8}>
          {(permission ?? []).includes(PermissionsType.B_CREATE_RULE) && (
            <Button type="primary">
              <Link to={`/device/rule/list/create?tabs=${tabsKey}`}>
                <PlusOutlined />
                新建规则
              </Link>
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_CREATE_RULE_GROUP) && (
            <Button type="primary">
              <Link to={`/device/rule/list/group/create?tabs=${tabsKey}`}>
                <PlusOutlined />
                新建规则组
              </Link>
            </Button>
          )}
        </Space>
      </div>
      <Tabs
        defaultActiveKey={tabs}
        tabBarStyle={{
          marginBottom: 3,
        }}
        tabBarGutter={43}
        className={styles.tabs}
        onChange={v => onChangeTabs(v)}
      >
        <Tabs.TabPane tab="规则" key="1">
          <Rule searchParams={searchParams} permission={permission ?? []} tabsKey={tabsKey ?? '1'} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="规则组" key="2">
          <RuleGroup searchParams={searchParams} permission={permission ?? []} tabsKey={tabsKey ?? '1'} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default AlarmList;
