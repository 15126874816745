import { useBreadcrumbRoutes, Wrapper, Tabs, FormTitle } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import BasicInformation from './components/BasicInformation';
import LifeCycleModel from './components/LifeCycleModel';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash-es';

const CreateModel: FC = () => {
  const [activeKey, setActiveKey] = useState<string>('2');

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const tabKey = urlSearchParams.get('tabKey') || undefined;
  const routes = [{ name: '完善模型' }];
  useEffect(() => {
    if (isNil(tabKey)) return;
    setActiveKey(tabKey);
  }, [tabKey]);

  const tabItems = [
    {
      key: '2',
      label: '生命周期建模',
      children: <LifeCycleModel />,
    },
    {
      key: '1',
      label: '基本信息',
      children: <BasicInformation />,
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
      <FormTitle title="完善模型" />
      <Tabs defaultActiveKey={activeKey} items={tabItems} />
    </Wrapper>
  );
};

export default CreateModel;
