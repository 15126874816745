import {
  EnergyMediumListItem,
  EnergyPlanDetailPreviewListItem,
  EnergyUnitListItem,
  PlanType,
  getEnergyMediumList,
  getEnergyPlanDetail,
  getEnergyUnitDeviceList,
  previewEnergyPlanDetail,
} from '@/api/plustekEnergyPlan';
import { DatePicker, Empty, Input, MXTabsProps, Modal, Radio, Tabs, Tooltip, Tree } from '@maxtropy/components';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DataNode, TreeProps } from 'antd/es/tree';
import styles from './index.module.scss';
import { Space, Spin } from 'antd';
import deviceImg from '../../assets/device@2x.png';
import energyUnitImg from '../../assets/unit@2x.png';
import BarEcharts from '../BarEcharts';
import dayjs from 'dayjs';

interface PreviewModalProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  energyPlanId?: number;
  planYear?: string; // 计划年度
}

const dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const getParentKey = (key: React.Key, tree: DataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

function formatToNodeData(data: EnergyUnitListItem[]): DataNode[] {
  return data.map(item => {
    return {
      title: item.energyUnitName,
      key: item.energyUnitId,
      icon: <img src={energyUnitImg} alt="" style={{ width: 16, height: 16, marginTop: 4 }} />,
      children: item.deviceList.map(i => {
        return {
          title: i.deviceName,
          key: `${i.deviceId}-${item.energyUnitId}-device`,
          icon: <img src={deviceImg} alt="" style={{ width: 16, height: 16, marginTop: 4 }} />,
        };
      }),
    };
  });
}

const PreviewModal: React.FC<PreviewModalProps> = props => {
  const { open, setOpen, energyPlanId, planYear } = props;
  const [treeNodeData, setTreeNodeData] = useState<DataNode[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [planType, setPlanType] = useState<PlanType>(PlanType.DAY);
  // 介质列表
  const [mediumList, setMediumList] = useState<EnergyMediumListItem[]>([]);
  // 用能单元或者设备id
  const [energyUnitOrDeviceId, setEnergyUnitOrDeviceId] = useState<number>();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>();
  const [selectedNode, setSelectedNode] = useState<DataNode>();
  const [isDevice, setIsDevice] = useState<boolean>(false);
  const [previewDate, setPreviewDate] = useState<string>();
  const [chartData, setChartData] = useState<EnergyPlanDetailPreviewListItem[]>([]);
  const [selectedMedium, setSelectedMedium] = useState<EnergyMediumListItem>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(planYear)) {
      // 当前年度+当月当日
      setPreviewDate(dayjs().format('YYYY-MM-DD'));
      return;
    }
    // 计划年度+当月当日
    setPreviewDate(dayjs(planYear).format('YYYY-MM-DD'));
  }, [planYear]);

  useEffect(() => {
    if (isNil(energyPlanId)) return;
    // 获取用能单元+设备列表
    getEnergyUnitDeviceList(energyPlanId).then(res => {
      if (res) {
        setTreeNodeData(formatToNodeData(res.list));
        generateList(formatToNodeData(res.list));
        if (res.list.length > 0) {
          setEnergyUnitOrDeviceId(res.list[0].energyUnitId);
          setSelectedKeys([res.list[0].energyUnitId]);
          setSelectedNode(formatToNodeData(res.list)[0]);
        }
      }
    });
    // 获取用能计划信息
    getEnergyPlanDetail(energyPlanId).then(res => {
      if (res) {
        if (isNil(planYear)) {
          setPreviewDate(dayjs(res.energyYear).format('YYYY-MM-DD'));
        }
      }
    });
  }, [energyPlanId, planYear]);

  // 获取工质列表 根据计划id
  useEffect(() => {
    if (isNil(energyPlanId)) return;
    getEnergyMediumList(Number(energyPlanId)).then(res => {
      if (isNil(res) || res.list.length === 0) return;
      setMediumList(res.list);
      // setSelectedMedium(res.list[0]);
    });
  }, [energyPlanId]);

  // 获取echarts数据
  useEffect(() => {
    if (isNil(energyPlanId) || isNil(previewDate) || isNil(energyUnitOrDeviceId)) return;
    setLoading(true);
    const body = {
      id: energyPlanId,
      planType,
      energyUnitId: isDevice ? undefined : energyUnitOrDeviceId,
      deviceId: isDevice ? energyUnitOrDeviceId : undefined,
      energyMediumId: isNil(selectedMedium) ? 100 : Number(selectedMedium.energyMediumId),
      previewDate,
    };
    previewEnergyPlanDetail(body)
      .then(res => {
        setChartData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [energyPlanId, energyUnitOrDeviceId, isDevice, planType, previewDate, selectedMedium]);

  // 介质选项
  const mediumListTabItems = useMemo(() => {
    let res: MXTabsProps['items'] = [];
    if (mediumList.length > 0) {
      res = mediumList.map(i => ({
        key: i.id.toString(),
        label: i.energyMediumName,
      }));
      res.unshift({
        key: 'standard_coal',
        label: '标准煤',
      });
    }
    return res;
  }, [mediumList]);

  const treeData = useMemo(() => {
    const loop = (data: DataNode[]): DataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.siteTreeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title: strTitle.length > 12 ? <Tooltip title={title}>{title}</Tooltip> : title,
            key: item.key,
            children: loop(item.children),
            icon: item.icon,
          };
        }
        return {
          title: strTitle.length > 12 ? <Tooltip title={title}>{title}</Tooltip> : title,
          key: item.key,
          icon: item.icon,
        };
      });

    return loop(treeNodeData);
  }, [searchValue, treeNodeData]);

  const onSelect: TreeProps['onSelect'] = selectedKeys => {
    if (isNil(selectedKeys) || selectedKeys.length === 0) return;
    const key = selectedKeys[0];
    if (key.toString().includes('device')) {
      // 选中的是设备
      const [deviceId] = key.toString().split('-');
      setEnergyUnitOrDeviceId(Number(deviceId));
      setIsDevice(true);
    } else {
      // 选中的是用能单元
      setEnergyUnitOrDeviceId(Number(key));
      setIsDevice(false);
    }
    const node = dataList.find(i => i.key === key);
    setSelectedKeys(selectedKeys);
    setSelectedNode(node);
  };

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (isNil(value) || value === '') {
      setExpandedKeys([]);
      setSearchValue('');
      return;
    }
    const newExpandedKeys = dataList
      .map(item => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, treeNodeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(newExpandedKeys as React.Key[]);
    setSearchValue(value);
  };

  return (
    <Modal
      size="big"
      open={open}
      title="预览"
      onOk={() => {
        setOpen(false);
      }}
      onCancel={() => {
        setOpen(false);
      }}
      contentStyle={{ paddingBottom: 0, paddingTop: 0 }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: 260, height: '100%', paddingTop: 24 }}>
          <div style={{ marginRight: 16 }}>
            <Input value={searchValue} placeholder="请输入用能单元\设备名称" onChange={onInputChange} />
          </div>

          {treeData && treeData.length > 0 ? (
            <div
              style={{
                marginTop: 16,
              }}
            >
              <Tree
                showIcon
                onExpand={onExpand}
                treeData={treeData}
                expandedKeys={expandedKeys}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                style={{ height: 525, overflow: 'auto' }}
              />
            </div>
          ) : (
            <Empty style={{ height: 300, marginTop: 100 }} />
          )}
        </div>
        <div style={{ width: 716, paddingLeft: 24, paddingTop: 24, borderLeft: '1px solid rgba(255, 255, 255, 0.2)' }}>
          <Space size={17}>
            <Radio.Group
              buttonStyle="solid"
              value={planType}
              onChange={(v: any) => {
                setPlanType(v.target.value);
              }}
            >
              <Radio.Button value={PlanType.DAY}>日</Radio.Button>
              <Radio.Button value={PlanType.MONTH}>月</Radio.Button>
              <Radio.Button value={PlanType.QUARTER}>季度</Radio.Button>
              <Radio.Button value={PlanType.YEAR}>年</Radio.Button>
            </Radio.Group>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ width: 70, color: 'rgba(255,255,255,0.65)' }}>时间选择：</label>
              <DatePicker
                style={{ width: '240px' }}
                picker={planType === PlanType.DAY ? 'month' : 'year'}
                value={previewDate ? dayjs(previewDate) : undefined}
                allowClear={false}
                onChange={v => {
                  if (v) setPreviewDate(v.format('YYYY-MM-DD'));
                }}
              />
            </div>
          </Space>
          {mediumList.length > 0 ? (
            <>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <Tabs
                  items={mediumListTabItems}
                  onChange={v => {
                    setSelectedMedium(mediumList.find(i => i.id.toString() === v));
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: 1,
                    borderBottom: '1px solid rgba(255,255,255,0.2)',
                    bottom: 15,
                  }}
                />
              </div>
              <Spin spinning={loading}>
                {chartData.length > 0 && chartData.find(i => !isNil(i.value)) ? (
                  <BarEcharts
                    planType={planType}
                    chartData={chartData}
                    selectedNode={selectedNode}
                    selectedMedium={selectedMedium}
                  />
                ) : (
                  <Empty style={{ height: 250, marginTop: 100 }} />
                )}
              </Spin>
            </>
          ) : (
            <Empty style={{ height: 300, marginTop: 100 }} />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default PreviewModal;
