import {
  CreateUETEMTProcessResponse,
  CreateUETEMTResponse,
  energyMediumIdLinkName,
  EnergyWorkingProcessType,
  getUetEMTAllEnergyMediumList,
  UetEMTAllEnergyMediumListResponse,
} from '@/api/uet';
import { FormInstance } from 'antd';
import { FC, useEffect, useState } from 'react';
import ProcessEntry from './ProcessEntry';
import ProcessExit from './ProcessExit';
import ProcessNode from './ProcessNode';

export interface ProcessTabContentProps {
  currentKey?: string;
  countInAndOutProcessNumber?: (number: number, key: string) => void;
  fieldName: number;
  eMTBasicInfo?: CreateUETEMTResponse;
  energyMediumIds?: number[];
  selectedOuIds?: number[];
  processType?: EnergyWorkingProcessType;
  handleTabsActiveKey?: (key: string) => void; // 改变tabs方法
  processForm?: FormInstance<any>;
  createdProcesses?: CreateUETEMTProcessResponse[];
  callOptions?: (options?: any[]) => void;
}

const ProcessTabContent: FC<ProcessTabContentProps> = props => {
  const {
    currentKey,
    countInAndOutProcessNumber,
    fieldName,
    eMTBasicInfo,
    energyMediumIds,
    processType,
    handleTabsActiveKey,
    processForm,
    callOptions,
  } = props;
  const [entryEnergyMediumList, setEntryEnergyMediumList] = useState<{ id: number; name: string }[]>([]);
  const [allEnergyMediumList, setAllEnergyMediumList] = useState<UetEMTAllEnergyMediumListResponse[]>([]);

  // 转换节点输入可以选择全部的能源介质
  useEffect(() => {
    if (processType === EnergyWorkingProcessType.PREPARATION_AND_HANDLE) {
      getUetEMTAllEnergyMediumList().then(setAllEnergyMediumList);
    }
  }, [processType]);

  // 监听能源介质变化，改变输出输入的能源介质
  useEffect(() => {
    if (energyMediumIds && currentKey) {
      let energyMediumEntryProcessIds = energyMediumIdLinkName.filter(i => energyMediumIds.includes(i.id));
      if (currentKey === '1') {
        if (processType === EnergyWorkingProcessType.PREPARATION_AND_HANDLE) {
          // 转换节点节点中的输入可以选择全部能源介质
          setEntryEnergyMediumList(
            (allEnergyMediumList ?? []).map(i => {
              return {
                id: i.id,
                name: i.energyName,
              };
            })
          );
        } else {
          // 分配节点中的输入选择能源介质中并加上电能
          energyMediumEntryProcessIds.push({ id: 2030, name: '电能' });
          setEntryEnergyMediumList(energyMediumEntryProcessIds);
        }
      } else {
        setEntryEnergyMediumList(energyMediumEntryProcessIds);
      }
    }
  }, [energyMediumIds, currentKey, processType, allEnergyMediumList, processForm]);

  return (
    <>
      {currentKey === '1' && (
        <ProcessEntry
          fieldName={fieldName}
          handleTabsActiveKey={handleTabsActiveKey}
          entryEnergyMediumList={entryEnergyMediumList}
          eMTBasicInfo={eMTBasicInfo}
          countInAndOutProcessNumber={countInAndOutProcessNumber}
          callOptions={vals => {
            callOptions?.(vals);
          }}
        />
      )}
      {currentKey === '2' && (
        <ProcessNode
          fieldName={fieldName}
          entryEnergyMediumList={entryEnergyMediumList}
          eMTBasicInfo={eMTBasicInfo}
          countInAndOutProcessNumber={countInAndOutProcessNumber}
          processType={processType}
        />
      )}
      {currentKey === '3' && (
        <ProcessExit
          fieldName={fieldName}
          handleTabsActiveKey={handleTabsActiveKey}
          entryEnergyMediumList={entryEnergyMediumList}
          eMTBasicInfo={eMTBasicInfo}
          countInAndOutProcessNumber={countInAndOutProcessNumber}
        />
      )}
    </>
  );
};

export default ProcessTabContent;
