import { Button, EllipsisSpan, getRealUrl, Modal, Table } from '@maxtropy/components';
import { V2IndicatorLogPostResponse, apiV2IndicatorLogPost } from '@maxtropy/device-customer-apis-v2';
import { Space } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useRequest } from 'ahooks';

interface LogListType {
  importTime?: {
    seconds?: number;
    nanos?: number;
  };
  successCount?: number;
  failureCount?: number;
  fileKey?: string;
  fileName?: string;
  operatorName?: string;
}

const otherColumns = [
  {
    title: '批量导入状态',
    ellipsis: { showTitle: true },
    render: (record: LogListType) => (
      <EllipsisSpan
        value={
          <>
            <span
              style={{
                color: 'var(--success-color)',
              }}
            >
              成功行{record.successCount ?? '--'}
            </span>
            /
            <span
              style={{
                color: 'var(--error-color)',
              }}
            >
              失败行{record.failureCount ?? '--'}
            </span>
          </>
        }
      />
    ),
  },
  {
    title: '批量导入时间',
    dataIndex: 'importTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'operatorName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface Props {
  close: () => void;
}
const OperationLogModal: React.FC<Props> = ({ close }) => {
  const [logList, setLogList] = useState<V2IndicatorLogPostResponse['list']>();

  const { loading } = useRequest(() => apiV2IndicatorLogPost(), {
    onSuccess: data => {
      setLogList(data.list);
    },
  });

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      render: (record: LogListType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              window.open(getRealUrl(record.fileKey));
            }}
          >
            导出查看
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="批量操作日志"
        open
        onCancel={close}
        size="large"
        footer={
          <>
            <Space size={8}>
              <Button onClick={close}>取消</Button>
              <Button type="primary" onClick={close}>
                确定
              </Button>
            </Space>
          </>
        }
      >
        <Table rowKey="id" loading={loading} dataSource={logList} columns={columns} pagination={false} />
      </Modal>
    </>
  );
};

export default OperationLogModal;
