import { useRef, useState } from 'react';
import styles from './index.module.scss';
interface Iprops {
  min?: number;
  max?: number;
  init: number;
  dragChange?: (width: number) => void;
  dragOver?: () => void;
  dragStart?: () => void;
  dragStatus?: (status: boolean) => void;
}
const DragResize: React.FC<Iprops> = ({ min = 100, max = 500, init, dragChange, dragStart, dragOver, dragStatus }) => {
  // 拖动范围
  const startP = useRef(min);
  const endP = useRef(max);
  const startX = useRef(0);
  const initW = useRef(init);
  // 初始值
  const [siderWidth, setSiderWidth] = useState(initW.current);

  const mouseDown = (e: any) => {
    startX.current = e.clientX;
    dragStart?.();
    dragStatus?.(true);
    document.onselectstart = function () {
      return false;
    };
    document.onmousemove = function (e) {
      // 移动的距离
      let moveDistance = e.clientX - startX.current;
      // sider宽度
      let siderW = moveDistance + siderWidth;
      if (siderW >= startP.current && siderW <= endP.current) {
        setSiderWidth(siderW);
        // 拖动
        dragChange?.(siderW);
      }
    };
    document.onmouseup = function () {
      this.onmousemove = null;
      document.onselectstart = null;
      dragOver?.();
      dragStatus?.(false);
    };
    document.onmouseleave = function () {
      this.onmousemove = null;
    };
  };
  return (
    <>
      <div className={styles.drag_box}>
        <div className={styles.drag_target} onMouseDown={mouseDown}></div>
      </div>
    </>
  );
};

export default DragResize;
