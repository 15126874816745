import coollingTowerImg from './icons/coollingTower@2x.png';
import chillerImg from './icons/chiller@2x.png';
import coolingWaterPumpImg from './icons/coolingWaterPump@2x.png';
import primaryChiledWaterPumpImg from './icons/primaryChiledWaterPump@2x.png';
import secondChiledWaterPumpImg from './icons/secondChiledWaterPump@2x.png';
import iceStorageTankImg from './icons/iceStorageTank@2x.png';
import waterSeparatorImg from './icons/waterSeparator@2x.png';
import decouplingTankImg from './icons/decouplingTank@2x.png';
import hygrothermogrophImg from './icons/hygrothermogroph@2x.png';
import supplyWaterPortImg from './icons/supplyWaterPort.png';
import returnWaterPortImg from './icons/returnWaterPort@2x.png';

export enum OperationType {
  ADD,
  EDIT,
}

export const OperationTypeDisplay = {
  [OperationType.ADD]: '新增',
  [OperationType.EDIT]: '编辑',
};

export enum Status {
  Disabled = 0,
  Enable,
}

export const StatusDisplay = {
  [Status.Enable]: '启用',
  [Status.Disabled]: '禁用',
};

export enum NodeType {
  COOLING_TOWER = 1,
  CHILLER,
  COOLING_WATER_PUMP,
  PRIMARY_CHILED_WATER_PUMP,
  SECOND_CHILED_WATER_PUMP,
  ICE_STORAGE_TANK,
  WATER_SEPARATOR,
  DECOUPLING_TANK,
  HYGROTHERMOGROGH,
  SUPPLY_WATER_PORT,
  RETURN_WATER_PORT,
}

export const NodeTypeDisplay = {
  [NodeType.COOLING_TOWER]: '冷却塔',
  [NodeType.CHILLER]: '冷水机',
  [NodeType.COOLING_WATER_PUMP]: '冷却水泵',
  [NodeType.PRIMARY_CHILED_WATER_PUMP]: '一次冷冻水泵',
  [NodeType.SECOND_CHILED_WATER_PUMP]: '二次冷冻水泵',
  [NodeType.ICE_STORAGE_TANK]: '蓄冰罐',
  [NodeType.WATER_SEPARATOR]: '集/分水器',
  [NodeType.DECOUPLING_TANK]: '解耦罐',
  [NodeType.HYGROTHERMOGROGH]: '温湿度计',
  [NodeType.SUPPLY_WATER_PORT]: '供水端口',
  [NodeType.RETURN_WATER_PORT]: '回水端口',
};

export const IconKey = {
  [NodeType.COOLING_TOWER]: coollingTowerImg,
  [NodeType.CHILLER]: chillerImg,
  [NodeType.COOLING_WATER_PUMP]: coolingWaterPumpImg,
  [NodeType.PRIMARY_CHILED_WATER_PUMP]: primaryChiledWaterPumpImg,
  [NodeType.SECOND_CHILED_WATER_PUMP]: secondChiledWaterPumpImg,
  [NodeType.ICE_STORAGE_TANK]: iceStorageTankImg,
  [NodeType.WATER_SEPARATOR]: waterSeparatorImg,
  [NodeType.DECOUPLING_TANK]: decouplingTankImg,
  [NodeType.HYGROTHERMOGROGH]: hygrothermogrophImg,
  [NodeType.SUPPLY_WATER_PORT]: supplyWaterPortImg,
  [NodeType.RETURN_WATER_PORT]: returnWaterPortImg,
};

export const nodeSize: Record<number, [number, number]> = {
  [NodeType.COOLING_TOWER]: [98, 104],
  [NodeType.CHILLER]: [106, 74],
  [NodeType.COOLING_WATER_PUMP]: [36, 80],
  [NodeType.PRIMARY_CHILED_WATER_PUMP]: [36, 80],
  [NodeType.SECOND_CHILED_WATER_PUMP]: [36, 80],
  [NodeType.ICE_STORAGE_TANK]: [46, 76],
  [NodeType.WATER_SEPARATOR]: [76, 61],
  [NodeType.DECOUPLING_TANK]: [56, 175],
  [NodeType.HYGROTHERMOGROGH]: [27, 51],
  [NodeType.SUPPLY_WATER_PORT]: [73, 59],
  [NodeType.RETURN_WATER_PORT]: [73, 59],
};

export enum StationType {
  WATERSYSTEM = 0,
}

export const StationTypeDisplay = {
  [StationType.WATERSYSTEM]: '水系统空调',
};

export const StationOptions = Object.entries(StationTypeDisplay).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

export const BaseNodeList = Object.entries(NodeTypeDisplay).map(([k, v]) => ({
  name: NodeTypeDisplay[k as unknown as NodeType],
  type: Number(k) as NodeType,
  selfId: v,
}));

export enum LineType {
  CHILLED_SUPPLY,
  CHILLED_RETURN,
  COOLING_SUPPLY,
  COOLING_RETURN,
}

export const LineTypeDisplay = {
  [LineType.CHILLED_SUPPLY]: '冷冻水供水管',
  [LineType.CHILLED_RETURN]: '冷冻水回水管',
  [LineType.COOLING_SUPPLY]: '冷却水供水管',
  [LineType.COOLING_RETURN]: '冷却水回水管',
};

export const LineTypeColor = {
  [LineType.CHILLED_SUPPLY]: '#00adff',
  [LineType.CHILLED_RETURN]: '#52e7ff',
  [LineType.COOLING_SUPPLY]: '#ff9247',
  [LineType.COOLING_RETURN]: '#ffcb47',
};

export const LineTypeDashed = {
  [LineType.CHILLED_SUPPLY]: '14 3',
  [LineType.CHILLED_RETURN]: '6 2',
  [LineType.COOLING_SUPPLY]: '14 3',
  [LineType.COOLING_RETURN]: '6 2',
};

export const LineTypeStyle = {
  [LineType.CHILLED_SUPPLY]:
    'repeating-linear-gradient(to right, #00adff, #00adff 14px, transparent 14px, transparent 17px)',
  [LineType.CHILLED_RETURN]:
    'repeating-linear-gradient(to right, #52e7ff, #52e7ff 6px, transparent 6px, transparent 8px)',
  [LineType.COOLING_SUPPLY]:
    'repeating-linear-gradient(to right, #ff9247, #ff9247 14px, transparent 14px, transparent 17px)',
  [LineType.COOLING_RETURN]:
    'repeating-linear-gradient(to right, #ffcb47, #ffcb47 6px, transparent 6px, transparent 8px)',
};

export interface OptionItem {
  [x: string]: any;
  label: string;
  value: number;
}

export const chilledNodeList = [
  NodeType.CHILLER,
  NodeType.PRIMARY_CHILED_WATER_PUMP,
  NodeType.SECOND_CHILED_WATER_PUMP,
  NodeType.ICE_STORAGE_TANK,
  NodeType.DECOUPLING_TANK,
  NodeType.WATER_SEPARATOR,
  NodeType.SUPPLY_WATER_PORT,
  NodeType.RETURN_WATER_PORT,
];

export const coolingdNodeList = [
  NodeType.COOLING_TOWER,
  NodeType.COOLING_WATER_PUMP,
  NodeType.CHILLER,
  NodeType.WATER_SEPARATOR,
  NodeType.DECOUPLING_TANK,
];
