import React, { FC } from 'react';
import { isNil, isNumber } from 'lodash-es';
import styles from './index.module.scss';
import classNames from 'classnames';

export interface CardForDataProps {
  value?: number | null;
  title?: string;
  titleTop?: string;
  valueTop?: number | null;
  titleBottom?: string;
  valueBottom?: number | null;
  costTitle?: string;
  cost?: number | null;
}

const CardForData: FC<CardForDataProps> = props => {
  const { value, title, titleTop, valueTop, titleBottom, valueBottom, costTitle, cost } = props;
  return (
    <div
      className={classNames(styles.card, isNil(title) ? null : styles.onlyTwoRow)}
      style={{ gap: isNil(value) ? 9 : 8 }}
    >
      <div className={styles.cardTop}>
        {isNil(title) ? (
          <>
            <span>{titleTop}</span>
            <span className={styles.valueTop}>{isNumber(valueTop) ? valueTop?.toFixed(2) ?? '--' : valueTop}</span>
          </>
        ) : (
          <span>{title}</span>
        )}
      </div>
      <div className={styles.cardBottom}>
        {value === undefined ? (
          <>
            <span>{titleBottom}</span>
            <span className={styles.valueBottom}>{isNil(valueBottom) ? '--' : valueBottom.toFixed(2)}</span>
          </>
        ) : (
          <span className={styles.value}>{isNil(value) ? '--' : value.toFixed(2)}</span>
        )}
      </div>

      <div className={styles.cardBottom}>
        {cost === undefined ? null : (
          <>
            <span>{costTitle}</span>
            <span className={styles.valueBottom}>{isNil(cost) ? '--' : cost.toFixed(2)}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default CardForData;
