import React, { useEffect, useState } from 'react';
import { Modal, Input, Filter, useUpdate, Table, Paging, useSearchParams, message } from '@maxtropy/components';
import { Form, Col } from 'antd';
import {
  apiV2MaterialPagePost,
  V2MaterialPagePostRequest,
  V2MaterialPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from '../index.module.scss';

interface SearchParams extends Omit<V2MaterialPagePostRequest, 'page' | 'size'> {}

export type SelectedRows = V2MaterialPagePostResponse['list'];

type AddBomProps = {
  setVisible: (val: boolean) => void;
  openNext: (val: SelectedRows) => void;
  id: number;
};

const columns = [
  {
    title: '物料编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
  },
  {
    title: '物料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
  },
];

const AddBom = ({ setVisible, openNext, id }: AddBomProps) => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>();
  const { searchParams, setTotalCount, reset, finish } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<V2MaterialPagePostResponse['list']>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<SelectedRows>([]);
  const [update] = useUpdate();

  const getMaterialData = () => {
    setLoading(true);
    apiV2MaterialPagePost({ ...searchParams, state: 1, page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getMaterialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, update]);

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: SelectedRows) => {
    if (newSelectedRowKeys.length > 20) {
      message.warning('最多选择20个物料');
      newSelectedRowKeys = newSelectedRowKeys.slice(0, 20);
      selectedRows = (selectedRows || []).slice(0, 20);
    }
    console.log('selectedRowKeys changed: ', newSelectedRowKeys, selectedRows);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const onOk = () => {
    if (selectedRows?.length === 0) {
      message.warning('请选择至少一种物料！');
      return;
    }
    openNext(selectedRows);
    setVisible(false);
  };

  return (
    <>
      <Modal
        title="选择物料"
        okText="下一步"
        open={true}
        size="big"
        bodyScroll
        onOk={onOk}
        onCancel={() => setVisible(false)}
      >
        <Filter<SearchParams> form={form} onFinish={finish} onReset={reset}>
          <Col span={8}>
            <Form.Item name="codeOrNameOrSpec" label="物料">
              <Input placeholder="请输入编号/名称/规格型号" />
            </Form.Item>
          </Col>
        </Filter>
        <Table rowKey="id" loading={loading} rowSelection={rowSelection} columns={columns} dataSource={dataSource} />
        <div className={styles.pagingCon}>
          <Paging pagingInfo={pagingInfo} />
          <div className={styles.selectedTxt}>
            已选择<span>{selectedRowKeys?.length}</span>项
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBom;
