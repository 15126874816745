import styles from '../index.module.scss';
import { EllipsisSpan, Table } from '@maxtropy/components';
import React, { useEffect, useState } from 'react';
import { ElectricityBillPhotovoltaicQuantityType, QuantitiesItem } from '../../../../api/electricity';
import {
  HasTimeOfUseElectricityPriceProps,
  HasTimeOfUseElectricityPricePropsType,
} from '../../../../api/electricitySettlementRules';
import { units } from '../../components/utils';

export interface ElectricityInfoData {
  type?: ElectricityBillPhotovoltaicQuantityType;
  data?: QuantitiesItem[];
}

const surplusColumns = [
  {
    title: '',
    key: 'name',
    dataIndex: 'name',
    fixed: true,
    className: `${styles.col}`,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向电上月示数',
    key: 'lastQuantity',
    dataIndex: 'lastQuantity',
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '反向电本月示数',
    key: 'currentQuantity',
    dataIndex: 'currentQuantity',
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '倍率',
    key: 'multiplyRatio',
    dataIndex: 'multiplyRatio',
    render: (v: number) => <EllipsisSpan value={v?.toFixed()} />,
  },
  {
    title: '反向上网电量',
    key: 'totalActivePowerQuantity',
    dataIndex: 'totalActivePowerQuantity',
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
];

const photovoltaicColumns = [
  {
    title: '',
    key: 'name',
    dataIndex: 'name',
    fixed: true,
    className: `${styles.col}`,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上月示数',
    key: 'lastQuantity',
    dataIndex: 'lastQuantity',
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '本月示数',
    key: 'currentQuantity',
    dataIndex: 'currentQuantity',
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '倍率',
    key: 'multiplyRatio',
    dataIndex: 'multiplyRatio',
    render: (v: number) => <EllipsisSpan value={v?.toFixed()} />,
  },
  {
    title: '发电量',
    key: 'totalActivePowerQuantity',
    dataIndex: 'totalActivePowerQuantity',
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
];

const realityColumns = [
  {
    title: '',
    key: 'name',
    dataIndex: 'name',
    fixed: true,
    className: `${styles.col}`,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '总计有功电量',
    key: 'totalActivePowerQuantity',
    dataIndex: 'totalActivePowerQuantity',
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '单价',
    key: 'unitPowerRate',
    dataIndex: 'unitPowerRate',
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(8)} ${units['元/千瓦时']}` : v} />
    ),
  },
  {
    title: '有功电费',
    key: 'totalElectricFee',
    dataIndex: 'totalElectricFee',
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
];

const ElectricityBillPhotovoltaicQuantity: React.FC<ElectricityInfoData> = ({ type, data }) => {
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    switch (type) {
      case ElectricityBillPhotovoltaicQuantityType.PHOTOVOLTAIC:
        setColumns(photovoltaicColumns);
        break;
      case ElectricityBillPhotovoltaicQuantityType.SURPLUS:
        setColumns(surplusColumns);
        break;
      case ElectricityBillPhotovoltaicQuantityType.ACTUAL:
        setColumns(realityColumns);
        break;
    }
  }, [type]);

  const getQuantityMeterGroupsData = (v?: QuantitiesItem[]) => {
    if (!v) return;
    const formatData = (v ?? []).map((i: QuantitiesItem) => ({
      key: i.id,
      name: HasTimeOfUseElectricityPriceProps[i.highLowType as HasTimeOfUseElectricityPricePropsType],
      lastQuantity: i.lastQuantity,
      currentQuantity: i.currentQuantity,
      multiplyRatio: i.multiplyRatio,
      totalActivePowerQuantity: i.totalActivePowerQuantity,
    }));

    return [
      ...formatData,
      ...[
        formatData.reduce(
          (a, b) => {
            return {
              ...a,
              lastQuantity: (a.lastQuantity ?? 0) + (b.lastQuantity ?? 0),
              currentQuantity: (a.currentQuantity ?? 0) + (b.currentQuantity ?? 0),
              totalActivePowerQuantity: (a.totalActivePowerQuantity ?? 0) + (b.totalActivePowerQuantity ?? 0),
              multiplyRatio: b.multiplyRatio ?? a.multiplyRatio,
            };
          },
          {
            key: '总',
            name: '总',
            lastQuantity: 0,
            currentQuantity: 0,
            totalActivePowerQuantity: 0,
          } as unknown as Partial<QuantitiesItem>
        ),
      ],
    ];
  };

  const getActualQuantitiesData = (v?: QuantitiesItem[]) => {
    if (!v) return;
    const formatData = (v ?? []).map((i: QuantitiesItem, index) => ({
      key: i.id + index,
      name: HasTimeOfUseElectricityPriceProps[i?.highLowType as HasTimeOfUseElectricityPricePropsType],
      totalActivePowerQuantity: i.totalActivePowerQuantity,
      unitPowerRate: i.unitPowerRate,
      totalElectricFee: i.totalElectricFee,
    }));

    return [
      ...formatData,
      formatData.reduce(
        (a, b) => {
          return {
            ...a,
            totalActivePowerQuantity: a.totalActivePowerQuantity + (b.totalActivePowerQuantity ?? 0),
            totalElectricFee: a.totalElectricFee + (b.totalElectricFee ?? 0),
          };
        },
        {
          key: '总',
          name: '总',
          totalActivePowerQuantity: 0,
          totalElectricFee: 0,
        }
      ),
    ];
  };

  return (
    <>
      {type === ElectricityBillPhotovoltaicQuantityType.ACTUAL ? (
        <Table rowKey="key" pagination={false} bordered dataSource={getActualQuantitiesData(data)} columns={columns} />
      ) : (
        <Table
          rowKey="key"
          pagination={false}
          bordered
          dataSource={getQuantityMeterGroupsData(data)}
          columns={columns}
        />
      )}
    </>
  );
};
export default ElectricityBillPhotovoltaicQuantity;
