import { EnergyMediumTreeFormatType, EnergyMediumTreeFormatTypeDisplay } from '@/api/energyMedium';
import { Tag } from 'antd';
import { FC } from 'react';
import { CardData } from '../../config';
import { isNumber } from '../../utils';
import styles from './index.module.scss';

export interface CardForProcessDataProps {
  cardData: CardData;
}

const CardForProcessData: FC<CardForProcessDataProps> = props => {
  const { cardData } = props;

  return (
    <div
      className={styles.card}
      style={{ borderTop: `3px solid ${cardData.type === EnergyMediumTreeFormatType.ENTRY ? '#177DDC' : '#49AA19'}` }}
    >
      <div className={styles.cardTitle}>
        <div>
          {cardData.cardName}（{cardData.unit}）
        </div>
        <div className={styles.value}>
          {cardData.value ? (isNumber(cardData.value) ? cardData.value.toFixed(2) : '--') : '--'}
        </div>
      </div>
      <div className={styles.valueContainer}>
        <Tag color={cardData.type === EnergyMediumTreeFormatType.ENTRY ? 'blue' : 'green'}>
          {EnergyMediumTreeFormatTypeDisplay[cardData.type as EnergyMediumTreeFormatType]}
        </Tag>
      </div>
    </div>
  );
};

export default CardForProcessData;
