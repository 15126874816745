import { BaseInfoContent, Button, Form, Modal, ShowInput } from '@maxtropy/components';
import React, { useEffect, useState } from 'react';
import {
  apiV2CarbonFootprintOutputDetailPost,
  V2CarbonFootprintOutputDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { Spin } from 'antd';

interface Props {
  onClose: () => void;
  materialId?: number;
}

const DetailModal: React.FC<Props> = ({ onClose, materialId }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<V2CarbonFootprintOutputDetailPostResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (materialId) {
      setLoading(true);
      apiV2CarbonFootprintOutputDetailPost({ id: materialId })
        .then(res => {
          form.setFieldsValue(res);
          setData(res);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialId]);

  return (
    <Modal title="查看" open={true} onCancel={onClose} footer={<Button onClick={onClose}>关闭</Button>}>
      <Spin spinning={loading}>
        <Form form={form}>
          <BaseInfoContent colNum={2}>
            <Form.Item label="订单号" name="productSheetCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="工站号" name="workStationCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="产出物编码" name="outputProductCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="产出物名称" name="outputProductName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="生产班次" name="workShiftName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="生产日期">
              <ShowInput value={dayjs(data?.outputTime).format('YYYY-MM-DD')} />
            </Form.Item>
            <Form.Item label="合格品数" name="qualifiedNumber">
              <ShowInput />
            </Form.Item>
            <Form.Item label="最后更新时间">
              <ShowInput value={dayjs(data?.updateTime).format('YYYY-MM-DD HH:mm')} />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DetailModal;
