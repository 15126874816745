import { Graph, Model } from '@antv/x6';
// @ts-ignore 没有@types
import Hierarchy from '@antv/hierarchy';
import { NodeData } from '../index';
import { getProductionBaseNodeExpandHeight } from '../components/ProductionBaseNode';

import { getWorkCenterNodeExpandHeight } from '../components/WorkCenterNode';
import { getProcessNodeExpandHeight } from '../components/ProcessNode';
import { getWorkStationNodeHeight } from '../components/WorkStation';
import { getEnergyUnitNodeHeight } from '../components/EnergyUnitNode';
import { commonNodeSize, NodeLevel, nodeLevelTypeMap, NodeType } from './const';
import styles from '../index.module.scss';

interface HierarchyResult {
  id: string;
  x: number;
  y: number;
  height: number;
  width: number;
  data: NodeData;
  children: HierarchyResult[];
}

export function initGraph(dom: HTMLElement): Graph {
  return new Graph({
    container: dom,
    width: dom.clientWidth,
    height: dom.clientHeight,
    interacting: false,
    panning: true,
    mousewheel: true,
  });
}

export function drawGraph(graph: Graph, data: NodeData, currentDateType: any) {
  const result = Hierarchy.mindmap(data, {
    direction: 'H',
    getHeight(node: NodeData) {
      return getNodeHeight(node);
    },
    getWidth() {
      return commonNodeSize.width;
    },
    getHGap() {
      return 80;
    },
    getVGap(node: NodeData) {
      return 12;
    },
    getSide: () => {
      return 'right';
    },
  });

  const model: Model.FromJSONData = { nodes: [], edges: [] };
  const traverse = (data: any) => {
    // data.data['currentDateType'] = 1231;
    if (data) {
      const ports = []; // 连接桩
      // 有pid，设置左侧连接桩
      if (data.data.pid) {
        ports.push({
          id: `left-${data.data.level}-${data.data.id}`,
          group: 'left',
        });
      }
      // 有子元素，设置右侧连接桩
      if (data.data.children?.length) {
        for (const item of data.data.children) {
          ports.push({
            id: `right-${data.data.level}-${data.data.id}-${item.id}`,
            group: 'right',
          });
        }
      }

      // 添加节点
      model.nodes!.push({
        id: `${data.data.level}-${data.data.pid}-${data.data.id}`, // id添加level，防止不同层级id重复；id添加父级id，防止不同父级下有同一个子级
        x: data.x,
        y: data.y,
        width: commonNodeSize.width,
        height: getNodeHeight(data.data),
        shape: nodeLevelTypeMap[data.data.level as NodeLevel],
        data: data.data,
        ports: ports,
      });
    }

    // 添加父节点和子节点的连线
    if (data.children) {
      data.children.forEach((item: HierarchyResult) => {
        model.edges!.push({
          source: {
            cell: `${data.data.level}-${data.data.pid}-${data.data.id}`,
            port: `right-${data.data.level}-${data.data.id}-${item.data.id}`,
          },
          target: {
            cell: `${item.data.level}-${item.data.pid}-${item.data.id}`,
            port: `left-${item.data.level}-${item.data.id}`,
          },
          router: {
            name: 'manhattan',
            args: {
              padding: 75,
            },
          },
          attrs: {
            line: {
              stroke: getLineColor(data.data),
              strokeWidth: 2,
              strokeDasharray: 5,
              targetMarker: {
                name: 'classic',
                size: 8,
              },
              class: styles.antLine,
            },
          },
        });
        traverse(item);
      });
    }
  };
  traverse(result);
  graph.fromJSON(model);

  // 工序间的连接桩和连线
  const nodes = graph.getNodes();
  const workStationNodes = nodes.filter(item => item.shape === NodeType.WorkCenterNode);
  workStationNodes.forEach(item => {
    const { id } = item.getData();
    const processNodes = nodes.filter(node => {
      return node.shape === NodeType.ProcessNode && node.data.parentId === id;
    });
    for (let i = 0; i < processNodes.length; i++) {
      if (i !== 0) {
        processNodes[i].addPort({
          group: 'top',
          id: 'top' + processNodes[i].id,
        });
      }
      if (i !== processNodes.length - 1) {
        processNodes[i].addPort({
          group: 'bottom',
          id: 'bottom' + processNodes[i].id,
        });
      }
    }
    // 连线
    for (let i = 0; i < processNodes.length - 1; i++) {
      graph.addEdge({
        source: {
          cell: processNodes[i],
          port: 'bottom' + processNodes[i].id,
        },
        target: {
          cell: processNodes[i + 1],
          port: 'top' + processNodes[i + 1].id,
        },
        attrs: {
          line: {
            stroke: getLineColor(processNodes[i].data),
            strokeWidth: 2,
            strokeDasharray: 5,
            targetMarker: {
              name: 'classic',
              size: 8,
            },
            class: styles.antLine,
          },
        },
      });
    }
  });
  // const processNodes = nodes.filter(item => item.shape === NodeType.ProcessNode);
  // // 连接桩
  // for (let i = 0; i < processNodes.length; i++) {
  //   if (i !== 0) {
  //     processNodes[i].addPort({
  //       group: 'top',
  //       id: 'top' + processNodes[i].id,
  //     });
  //   }
  //   if (i !== processNodes.length - 1) {
  //     processNodes[i].addPort({
  //       group: 'bottom',
  //       id: 'bottom' + processNodes[i].id,
  //     });
  //   }
  // }
  // // 连线
  // for (let i = 0; i < processNodes.length - 1; i++) {
  //   graph.addEdge({
  //     source: {
  //       cell: processNodes[i],
  //       port: 'bottom' + processNodes[i].id,
  //     },
  //     target: {
  //       cell: processNodes[i + 1],
  //       port: 'top' + processNodes[i + 1].id,
  //     },
  //     attrs: {
  //       line: {
  //         stroke: getLineColor(processNodes[i].data),
  //         strokeWidth: 2,
  //         strokeDasharray: 5,
  //         targetMarker: {
  //           name: 'classic',
  //           size: 8,
  //         },
  //         class: styles.antLine,
  //       },
  //     },
  //   });
  // }

  graph.centerContent();
}

function getNodeHeight(node: NodeData): number {
  // 生产基地
  if (node.level === NodeLevel.ProductionBaseNode) {
    return getProductionBaseNodeExpandHeight(node);
  }
  // 工作中心
  if (node.level === NodeLevel.WorkCenterNode) {
    return getWorkCenterNodeExpandHeight(node);
  }
  // 工序
  else if (node.level === NodeLevel.ProcessNode) {
    return getProcessNodeExpandHeight(node);
  }
  // 工站
  else if (node.level === NodeLevel.WorkStationNode) {
    return getWorkStationNodeHeight(node);
  }
  // 用能单元
  else if (node.level === NodeLevel.EnergyUnitNode) {
    return getEnergyUnitNodeHeight();
  }
  return commonNodeSize.height;
}

function getLineColor(node: NodeData): string {
  if (node.level === NodeLevel.ProductionBaseNode) {
    return 'rgba(119, 202, 78, 1)';
  }
  // 工作中心
  if (node.level === NodeLevel.WorkCenterNode) {
    return 'rgba(109, 175, 252, 1)';
  }
  // 工序
  else if (node.level === NodeLevel.ProcessNode) {
    return 'rgba(181, 124, 252, 1)';
  }
  // 工站
  else if (node.level === NodeLevel.WorkStationNode) {
    return '#DDAA43';
  }
  // 用能单元
  else if (node.level === NodeLevel.EnergyUnitNode) {
    return '#855af2';
  }
  return 'rgba(109, 175, 252, 1)';
}
