import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Button } from '@maxtropy/components';
import { EnergyMediumIconDisplay, EnergyMediumSceneIds } from '@/pages/ProductionEnergyConsumptionBoard/chartConfigs';
import {
  apiV2ProductionConsumptionEnergyMediumListPost,
  V2ProductionConsumptionEnergyMediumListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';

const RadioConsumption = (props: any) => {
  const {
    selectedKeys,
    selectedBasePhysicalQuantityId,
    setSelectedBasePhysicalQuantityId,
    setSelectedConsumption,
    setSelectedGeneralName,
    selectedConsumption,
    generalQuery,
    memoConsumption,
    setMemoConsumption,
    memoBasePhysicalQuantityId,
    setMemoBasePhysicalQuantityId,
  } = props;

  // 工质列表, 根据左侧 id 获取  获取的每一次切换工作中心拿到的所有能源介质
  const [mediums, setMediums] = useState<V2ProductionConsumptionEnergyMediumListPostResponse['list']>([]);

  // 根据选择的工作中心/工序/工站/用能单元 id 获取顶部工质
  useEffect(() => {
    if (selectedKeys.length) {
      apiV2ProductionConsumptionEnergyMediumListPost(generalQuery).then(res => {
        // 切换工作中心时，判断这个工作中心上有没有现在的能源介质，有保留，没有回到综合能耗
        const found = res.list?.some(item => item.energyMediumId === memoConsumption);
        if (found) {
          setSelectedConsumption(memoConsumption);
          setSelectedBasePhysicalQuantityId(memoBasePhysicalQuantityId);
        } else {
          setSelectedConsumption(res.list?.[0]?.energyMediumId);
          setSelectedBasePhysicalQuantityId(res.list?.[0]?.physicalUnitId);
          setMemoBasePhysicalQuantityId(res.list?.[0]?.physicalUnitId);
          setMemoConsumption(res.list?.[0]?.energyMediumId);
          setSelectedGeneralName(res.list?.[0]?.generalName);
        }

        setMediums(res.list || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalQuery, selectedKeys]);

  return (
    <Space size={8} wrap style={{ marginBottom: 16 }}>
      {mediums!.map(medium => (
        <Button
          style={{ paddingLeft: 12, paddingRight: 16 }}
          onClick={() => {
            setSelectedConsumption(medium?.energyMediumId!);
            setSelectedBasePhysicalQuantityId(medium.physicalUnitId);
            setSelectedGeneralName(medium.generalName);
            setMemoBasePhysicalQuantityId(medium.physicalUnitId);
            setMemoConsumption(medium?.energyMediumId!);
          }}
          className={
            `${selectedBasePhysicalQuantityId}-${selectedConsumption}` ===
            `${medium.physicalUnitId}-${medium.energyMediumId}`
              ? styles.titleButtonChecked
              : styles.titleButtonUnChecked
          }
        >
          <Space size="small" align="center">
            <img
              width={24}
              src={
                selectedConsumption === medium.energyMediumId
                  ? EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].active
                  : EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].passive
              }
              alt=""
            />
            <span>
              {medium.energyMediumName}-{medium.generalName}
            </span>
          </Space>
        </Button>
      ))}
    </Space>
  );
};

export default RadioConsumption;
