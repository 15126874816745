import { fetch } from '@maxtropy/components';
import qs from 'qs';

export interface BoardTreeInfo {
  id: number;
  name: string;
  workProcedures: {
    id: number;
    name: string;
    workStations: {
      id: number;
      name: string;
      energyUnits: {
        energyUnitId: number;
        energyUnitName: string;
      }[];
    }[];
  }[];
}

export function getBoardTreeInfo() {
  // return fetch<BoardTreeInfo>(`/api/production/energy/consumption/tree`);
  return fetch<BoardTreeInfo>(`/api/production/energy/consumption/demo/tree`);
}

// 能耗类型
export enum ConsumptionType {
  // 综合能耗
  GENERAL = 'general',
  // 电
  ELECTRICITY = 'electricity',
}

export const ConsumptionTypeDisplay = {
  [ConsumptionType.GENERAL]: '综合能耗',
  [ConsumptionType.ELECTRICITY]: '电',
};

// 能耗类型属性
export enum ConsumptionAttribute {
  // 发热量
  HEAT = 'heat',
  // 标准煤
  COAL = 'coal',
  // 有功电能
  ACTIVE_POWER = 'activePower',
}

export const ConsumptionAttributeDisplay = {
  [ConsumptionAttribute.HEAT]: '发热量',
  [ConsumptionAttribute.COAL]: '标准煤',
  [ConsumptionAttribute.ACTIVE_POWER]: '有功电能',
};

// 能耗类型属性对应的单位
export const ConsumptionAttributeUnitDisplay = {
  [ConsumptionAttribute.HEAT]: 'MJ',
  [ConsumptionAttribute.COAL]: 'KG',
  [ConsumptionAttribute.ACTIVE_POWER]: 'kWh',
};

// 时间颗粒度, 15 分钟, 天, 月
export enum TimeGranularity {
  FIFTEEN_MINUTES = '15m',
  DAY = 'day',
  MONTH = 'month',
}

export const TimeGranularityDisplay = {
  [TimeGranularity.FIFTEEN_MINUTES]: '15分钟',
  [TimeGranularity.DAY]: '按日',
  [TimeGranularity.MONTH]: '按月',
};

// 根节点不用传任何参数
export interface BoardChartDataQuery {
  // 工序id
  workProcedureId?: string | number;
  // 工站id
  workStationId?: string | number;
  // 单元id
  energyUnitId?: string | number;
  // 日期
  date?: string;
}

export interface BoardChartData {
  // 时间戳
  ts?: string | number;
  // 电量
  value?: number;
  // 发热量
  calorificValue?: number;
  // 标准煤
  standardCoal?: number;
  // 费用
  amount?: number;
}

// 获取 15 分钟颗粒度下的所有数据, 包括电量, 发热量, 标准煤和费用
export function getBoardChartQuarterData(query: BoardChartDataQuery) {
  return fetch<BoardChartData[]>(
    `/api/production/energy/consumption/demo/quarter-data?${qs.stringify(query, {
      indices: false,
    })}`
  );
}

// 获取日颗粒度下的所有数据, 包括电量, 发热量, 标准煤
export function getBoardChartDayData(query: BoardChartDataQuery) {
  return fetch<BoardChartData[]>(
    `/api/production/energy/consumption/demo/day-data?${qs.stringify(query, {
      indices: false,
    })}`
  );
}

// 获取日颗粒度下的所有数据, 包括电量, 发热量, 标准煤
export function getBoardChartMonthData(query: BoardChartDataQuery) {
  return fetch<BoardChartData[]>(
    `/api/production/energy/consumption/demo/month-data?${qs.stringify(query, {
      indices: false,
    })}`
  );
}
