// 电源类型
export enum BackUpPowerType {
  // 柴油发电机
  ups = 1,
  // UPS
  diesel = 2,
}

export const BackUpPowerTypeLabel = {
  [BackUpPowerType.ups]: 'UPS',
  [BackUpPowerType.diesel]: '柴油发电机',
};
