export enum StatusType {
  DISABLE = 0, // 禁用
  ENABLE = 1, // 启用
}

export const StatusDisplay = {
  [StatusType.DISABLE]: '禁用',
  [StatusType.ENABLE]: '启用',
};

export const statusOptions = [
  { label: '全部', value: '' },
  { label: '启用', value: StatusType.ENABLE },
  { label: '禁用', value: StatusType.DISABLE },
];

export enum AnalyticsType {
  productionCycle = 1, // 生产节拍
  OEE = 2,
}

export const AnalyticsDisplay = {
  [AnalyticsType.productionCycle]: '生产节拍',
  [AnalyticsType.OEE]: 'OEE服务',
};

export const analyticsOptions = [
  { label: AnalyticsDisplay[AnalyticsType.productionCycle], value: AnalyticsType.productionCycle },
  { label: AnalyticsDisplay[AnalyticsType.OEE], value: AnalyticsType.OEE },
];

export enum OperationType {
  ADD = '0',
  CANCEL = '1',
}

export const OperationTypeDisplay = {
  [OperationType.ADD]: '添加',
  [OperationType.CANCEL]: '取消',
};
