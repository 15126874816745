import { Checkbox, Col, Form, InputNumber, Row } from 'antd';
import React, { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPriceProps,
  HasTimeOfUseElectricityPricePropsType,
} from '../../../../../api/electricitySettlementRules';

const DistributedNewEnergy: FC = () => {
  return (
    <div>
      <Form.Item noStyle dependencies={['settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
              <>
                <div
                  style={{
                    backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                    padding: '20px',
                    fontSize: '14px',
                    fontWeight: 700,
                    paddingBottom: '0px',
                  }}
                >
                  {' '}
                  电度电价：
                </div>
                <Row>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}></Col>
                  <Col
                    span={23}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px' }}
                  >
                    <Form.Item
                      name="timeOfDayRateChecked"
                      label="时段选择"
                      rules={[{ required: true, message: '请选择时段' }]}
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.SHARPPEAK],
                            value: HasTimeOfUseElectricityPricePropsType.SHARPPEAK,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.PEAK],
                            value: HasTimeOfUseElectricityPricePropsType.PEAK,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.AVERAGE],
                            value: HasTimeOfUseElectricityPricePropsType.AVERAGE,
                          },
                          {
                            label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.VALLEY],
                            value: HasTimeOfUseElectricityPricePropsType.VALLEY,
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Row>
        {/* 时段选择尖峰平谷输入框 */}
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                <>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="summitRate" label="尖峰" rules={[{ required: true, message: '请输入尖峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                </>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                <>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="peakRate" label="峰" rules={[{ required: true, message: '请输入峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={15} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                <>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="plainRate" label="平" rules={[{ required: true, message: '请输入平值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px', fontWeight: 700 }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                <>
                  <Col span={1} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}></Col>
                  <Col span={8} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="valleyRate" label="谷" rules={[{ required: true, message: '请输入谷值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={15}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px', fontWeight: 700 }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default DistributedNewEnergy;
