import { EllipsisSpan, Input, Table, TreeSelect, Form, Button, Select } from '@maxtropy/components';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '@/api/device';
import { FillingDataTypes } from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions/types';
import { Col, Row } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FC, useEffect, useMemo } from 'react';
import styles from '../CreateMeterReading/index.module.scss';
import cloneDeep from 'lodash-es/cloneDeep';
import { FixedType } from 'rc-table/lib/interface';
import { useRequest } from 'ahooks';
import * as React from 'react';
import { apiV2EnergyConsumptionEvaluationAnalysisUnitFormToTreePost } from '@maxtropy/device-customer-apis-v2';
import {
  RateValueTypeFormat,
  RateValueType,
  AdjustmentBillingMethodType,
  AdjustmentBillingMethodTypeFormat,
  AmortizedBillingMethodTypeFormat,
  AmortizedBillingMethodType,
  meterReadingObjsType,
} from '../CreateMeterReading/CreateMeter';
interface BaselineChartProps {
  form: any;
  data: any;
  Flag?: boolean;
  meterReadingObjs?: meterReadingObjsType[];
  tableData: any;
  setTableData: (val: any) => void;
  unitTreeOptions?: any;
  setSearchValue: (val: any) => void;
  unitCheckedKeys: number[];
  setUnitTree: (val: any) => void;
}
interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}
interface nodeItem extends flattenNode {
  children: nodeItem[];
}

const BillingConfiguration: FC<BaselineChartProps> = ({
  form,
  data,
  Flag,
  meterReadingObjs,
  setTableData,
  tableData,
  unitTreeOptions,
  setSearchValue,
  unitCheckedKeys,
  setUnitTree,
}) => {
  const formatTreeData = (data: OrganizationResponse[]) => {
    return data.map(item => {
      const res: nodeItem = {
        key: item.data?.mcid ?? '',
        title: item.data?.name,
        children: formatTreeData(item.children || []),
        disabled: !item.data.hasPermission,
      };
      return res;
    });
  };

  const flattenTreeData = (data: nodeItem[]) => {
    let treeList: nodeItem[] = [];
    let flatten = function (data: nodeItem[]) {
      for (let i = 0; i < data.length; i++) {
        treeList.push(data[i]);
        if (data[i].children.length) {
          flatten(data[i].children);
        }
      }
    };
    flatten(data);
    return treeList;
  };
  const { data: organization } = useRequest(getOrganizationWithCodeWithCurrent);
  const mcidTreeOptions = useMemo(() => {
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { value: k.key, label: k.title };
        });
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    if (Flag && unitCheckedKeys.length > 0) {
      apiV2EnergyConsumptionEvaluationAnalysisUnitFormToTreePost({
        energyUnitIds: unitCheckedKeys,
      }).then(res => {
        setUnitTree(res.list);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Flag, unitCheckedKeys]);

  const handleAdd = () => {
    // 新增一行数据
    let newData: any = {};
    if (!columns) {
      return;
    }

    columns
      ?.map((item: { dataIndex: any }) => item.dataIndex)
      .forEach((key: string | number) => {
        newData[key] = null;
      });

    const currentCostCenterConfig = tableData?.costCenterConfig || [];

    setTableData({ ...tableData, costCenterConfig: [...currentCostCenterConfig, newData] });
  };

  const handleRemove = (index: number) => {
    const newData = form.getFieldsValue();
    const costCenterConfig = newData.costCenterConfig;
    costCenterConfig.splice(index, 1);
    setTableData({ ...tableData, costCenterConfig });
    form.setFieldsValue({
      costCenterConfig,
    });
  };

  const columns: any = [
    {
      title: <EllipsisSpan value={'成本中心'} />,
      fixed: false as FixedType,
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'name']}
              style={{
                marginBottom: 0,
              }}
              rules={[
                { required: true, message: '请输入成本中心' },
                { max: 20, message: '最多输入20字' },
                { pattern: /^((?![\\/:*?"<>|]).)+$/, message: '请确保产出物名称中不含下列字符： / : * ? " < > |' },
              ]}
            >
              <Input
                disabled={!Flag}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: <EllipsisSpan value={'所属组织'} />,
      fixed: false as FixedType,
      ellipsis: { showTitle: true },
      dataIndex: 'customerMcid',
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'customerMcid']}
              style={{
                marginBottom: 0,
              }}
            >
              <Select style={{ width: '100%' }} disabled={!Flag} placeholder="请选择" options={mcidTreeOptions} />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: <EllipsisSpan value={'抄表对象'} />,
      fixed: false as FixedType,
      ellipsis: { showTitle: true },
      dataIndex: 'readingObjectIds',
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'readingObjectIds']}
              rules={[{ required: true, message: '请选择抄表对象' }]}
              style={{
                marginBottom: 0,
              }}
            >
              <TreeSelect
                disabled={!Flag || unitCheckedKeys.length === 0}
                showSearch
                style={{ width: '100%' }}
                treeNodeFilterProp="title"
                fieldNames={{ label: 'title', value: 'value' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="请选择"
                allowClear
                treeDefaultExpandAll
                showCheckedStrategy={'SHOW_ALL'}
                treeData={unitTreeOptions}
                onBlur={() => setSearchValue('')}
                multiple
                onSearch={searchValue => {
                  setSearchValue(searchValue);
                }}
                filterTreeNode={(inputValue, treeNode) => {
                  const title = treeNode.title;
                  // 如果 title 是 React 元素对象
                  if (React.isValidElement(title) && title.props && title.props.children) {
                    const textContent = title.props.children;
                    if (textContent.length === 3) {
                      return true;
                    } else {
                      return false;
                    }
                    // console.log(textContent.toString().includes(inputValue));
                  }
                  if (typeof title === 'string') {
                    return title.includes(inputValue);
                  }

                  return false;
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: '操作',
      ellipsis: { showTitle: true },
      width: '16%',
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            {Flag ? (
              <Button
                type="link"
                onClick={() => {
                  handleRemove(index);
                }}
              >
                删除
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];
  const columnsView: any = [
    {
      title: <EllipsisSpan value={'成本中心'} />,
      fixed: false as FixedType,
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'name']}
              style={{
                marginBottom: 0,
              }}
              rules={[
                { required: true, message: '请输入成本中心' },
                { max: 20, message: '最多输入20字' },
                { pattern: /^((?![\\/:*?"<>|]).)+$/, message: '请确保产出物名称中不含下列字符： / : * ? " < > |' },
              ]}
            >
              <Input
                disabled={!Flag}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: <EllipsisSpan value={'所属组织'} />,
      fixed: false as FixedType,
      ellipsis: { showTitle: true },
      dataIndex: 'customerMcid',
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'customerMcid']}
              style={{
                marginBottom: 0,
              }}
            >
              <Select style={{ width: '100%' }} disabled={!Flag} placeholder="请选择" options={mcidTreeOptions} />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: <EllipsisSpan value={'抄表对象'} />,
      fixed: false as FixedType,
      ellipsis: { showTitle: true },
      dataIndex: 'readingObjectIds',
      render: (text: string, record: FillingDataTypes, index: number) => {
        return (
          <>
            <Form.Item
              name={['costCenterConfig', index, 'readingObjectIds']}
              rules={[{ required: true, message: '请选择抄表对象' }]}
              style={{
                marginBottom: 0,
              }}
            >
              <Select
                disabled={!Flag}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="请选择"
                options={meterReadingObjs}
              />
            </Form.Item>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setTableData((pre: { costCenterConfig: { filter: (arg0: (item: any) => any) => never[] } }) => {
      const newData = cloneDeep(data);
      return newData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Row>
        <Col span={10}>
          <Form.Item
            labelCol={{ flex: '128px' }}
            label="费率取值"
            name="rateValueType"
            tooltip={{
              title: '同期抄表按照抄表时间生效合同计算。电能仅计算电度电费。',
              icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
            }}
          >
            <Select
              disabled={!Flag}
              options={[{ value: RateValueType.UNIT, label: RateValueTypeFormat[RateValueType.UNIT] }]}
              placeholder="请选择费率取值"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item labelCol={{ flex: '128px' }} label="调整值计费方法" name="adjustmentBillingMethodType">
            <Select
              disabled={!Flag}
              placeholder="请选择调整值计费方法"
              options={[
                {
                  value: AdjustmentBillingMethodType.SEPARATE,
                  label: AdjustmentBillingMethodTypeFormat[AdjustmentBillingMethodType.SEPARATE],
                },
                {
                  value: AdjustmentBillingMethodType.AUTOMATIC,
                  label: AdjustmentBillingMethodTypeFormat[AdjustmentBillingMethodType.AUTOMATIC],
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item labelCol={{ flex: '128px' }} label="分摊值计费方法" name="amortizedBillingMethodType">
            <Select
              disabled={!Flag}
              placeholder="请选择分摊值计费方法"
              options={[
                {
                  value: AmortizedBillingMethodType.SEPARATE,
                  label: AmortizedBillingMethodTypeFormat[AmortizedBillingMethodType.SEPARATE],
                },
                {
                  value: AmortizedBillingMethodType.AUTOMATIC,
                  label: AmortizedBillingMethodTypeFormat[AmortizedBillingMethodType.AUTOMATIC],
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item labelCol={{ span: 24 }} label="成本中心配置" name="costCenterConfig">
            {Flag ? (
              <Table
                className={styles.TableData}
                dataSource={tableData?.costCenterConfig}
                pagination={false}
                scroll={{ x: 200 }}
                rowKey={'rowKey'}
                columns={columns}
                bordered
              />
            ) : (
              <Table
                className={styles.TableData}
                dataSource={tableData?.costCenterConfig}
                pagination={false}
                scroll={{ x: 200 }}
                rowKey={'rowKey'}
                columns={columnsView}
                bordered
              />
            )}
            {Flag && (
              <Button
                onClick={handleAdd}
                type="dashed"
                icon={<PlusOutlined />}
                className={styles.addRowBtn}
                wrapStyle={{ width: '100%' }}
                disabled={!Flag}
              >
                新增一行数据
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default BillingConfiguration;
