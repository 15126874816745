import { FC, useEffect, useState } from 'react';
import { Row, Col, Space } from 'antd';
import {
  Input,
  Select,
  Radio,
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  InputNumber,
  Form,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import {
  MainDataSourcesTypeMap,
  validateText,
  TransportTypeMap,
  TransportType,
  CarbonFootPrintSettingType,
  CarbonFootPrintSettingTypeMap,
} from '../../utils';
import { Rule } from 'antd/lib/form';
import styles from '../../components/NormalInput/index.module.scss';
import TransportTable from '../TransportTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  apiV2CarbonFootprintLcaDetailInputPost,
  apiV2CarbonFootprintLcaUpdateInputPost,
  V2CarbonFootprintLcaDetailInputPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import { RoadType, FinishInputType } from '../LifeCycleModel/const';
import { InputTypeMap, InputType, AccessTypeMap, Access } from '../../utils';
import { v4 as uuidv4 } from 'uuid';
import { getUnits, OutputUnitType } from '@/api/outputConfig';
import { cloneDeep } from 'lodash-es';

const EditInput: FC = () => {
  const navigator = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [{ name: '编辑输入' }];

  const paramsToGet = ['id', 'lcaId', 'isProcedure', 'procedureName', 'procedureId'];
  const queryParams = Object.fromEntries(paramsToGet.map(param => [param, urlSearchParams.get(param)]));

  const needTransport = Form.useWatch('needTransport', form);
  const [lcaDetail, setlcaDetail] = useState<V2CarbonFootprintLcaDetailInputPostResponse>();
  const [unitName, setUnitName] = useState<string>('--');

  useEffect(() => {
    if (isNil(queryParams.id)) return;
    apiV2CarbonFootprintLcaDetailInputPost({
      id: Number(queryParams.id),
    }).then(res => {
      setlcaDetail(res);
      form.setFieldsValue({ ...res });
      getUnits().then(units => {
        const unitCode =
          (res.unitTypeCode === OutputUnitType.PIECE
            ? units.list
                .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                ?.outputProductUnitList?.find(e => e?.unitCode === res.unitCode)?.unitName
            : res.unitCode) ?? '--';
        setUnitName(unitCode);
      });
    });
  }, [queryParams.id, form]);

  const onFinish = (values: FinishInputType) => {
    //新创建的路段不能将id传后端,只能作为前端的key暂时使用
    const newRoads = (values?.roads ?? []).map((road: RoadType) => {
      if (typeof road.id === 'string') {
        delete road.id;
      }
      return road;
    });
    const params = {
      ...values,
      roads: newRoads,
      id: Number(queryParams.id),
    };
    apiV2CarbonFootprintLcaUpdateInputPost({ ...params }).then(res => {
      navigator(
        `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
      );
    });
  };
  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title="编辑输入" />

        <Form layout="vertical" form={form} {...formLayout} onFinish={onFinish}>
          <SubContent title="基础信息" className="mb-8">
            <Row gutter={[48, 0]}>
              <Col span={8}>
                <Form.Item label="过程名称">
                  <ShowInput value={queryParams.procedureName}></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="输入类型 ">
                  <ShowInput value={InputTypeMap[lcaDetail?.type as InputType]}></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="code" label="物料编码 ">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[48, 0]}>
              <Col span={8}>
                <Form.Item name="name" label="物料名称 ">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="spec" label="规格型号 ">
                  <ShowInput></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="获取方式 ">
                  <ShowInput value={AccessTypeMap[lcaDetail?.accessType as Access]}></ShowInput>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[48, 0]}>
              <Col span={8}>
                <Form.Item label="使用量 ">
                  <ShowInput value={`${lcaDetail?.quantity}${unitName}`}></ShowInput>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="dataOrigin" label="主要数据来源" rules={[{ message: '请选择', required: true }]}>
                  <Select
                    placeholder={'请选择'}
                    options={Object.entries(MainDataSourcesTypeMap).map(([k, v]) => ({
                      label: v,
                      value: +k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="remark" label="数据说明" style={{ maxWidth: 464 }} rules={[validateText(200) as Rule]}>
                  <TextArea placeholder="请输入1-200字符" />
                </Form.Item>
              </Col>
            </Row>

            {queryParams.isProcedure !== '1' && (
              <Row gutter={[48, 0]}>
                <Col span={8}>
                  <Form.Item name="carbonFootPrintSetting" label="碳足迹设置">
                    <Radio.Group>
                      <Radio value={CarbonFootPrintSettingType.Non_Negligible}>
                        {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Non_Negligible]}
                      </Radio>
                      <Radio value={CarbonFootPrintSettingType.Negligible}>
                        {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.Negligible]}
                      </Radio>
                      <Radio value={CarbonFootPrintSettingType.DataUnavailable}>
                        {CarbonFootPrintSettingTypeMap[CarbonFootPrintSettingType.DataUnavailable]}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </SubContent>

          <SubContent title="运输信息" className="mb-8">
            <Row gutter={[48, 0]}>
              <Col span={6}>
                <Form.Item name="needTransport" label="是否需要运输" rules={[{ message: '请选择', required: true }]}>
                  <Radio.Group>
                    <Radio value={TransportType.NO_NEED}>{TransportTypeMap[TransportType.NO_NEED]}</Radio>
                    <Radio
                      value={TransportType.NEED}
                      onChange={() => {
                        const roads = cloneDeep(form.getFieldValue('roads'));
                        if (isNil(roads) || roads?.length === 0) {
                          form.setFieldsValue({
                            roads: [
                              {
                                id: uuidv4(),
                                startPlace: undefined,
                                endPlace: undefined,
                                distance: undefined,
                                transportation: undefined,
                                factorId: undefined,
                              },
                            ],
                          });
                        }
                      }}
                    >
                      {TransportTypeMap[TransportType.NEED]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {needTransport === TransportType.NEED && (
                <Form.Item
                  name="grossWeight"
                  label=" "
                  required={false}
                  rules={[
                    { message: '请输入数字', required: true },
                    {
                      pattern: /^(?!0+(\.0*)?$)0\.[1-9]\d*|0\.\d*[1-9]\d*$|[1-9]\d*(\.\d+)?$/,
                      message: '数值必须大于0',
                    },
                  ]}
                >
                  <Space align="center">
                    <span style={{ color: 'var(--mx-form-item-label-color)' }}>1pcs对应的产品的运输毛重为：</span>
                    <InputNumber addonAfter={'kg'} min={0} style={{ minWidth: 123 }}></InputNumber>
                  </Space>
                </Form.Item>
              )}
            </Row>
            {needTransport === TransportType.NEED && <TransportTable form={form}></TransportTable>}
          </SubContent>

          <Space className="sticky-footer" size={8}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              onClick={() => {
                navigator(
                  `/productCarbonFootPrint/basic/modelManage/newModel?id=${queryParams.lcaId}&&procedureId=${queryParams.procedureId}`
                );
              }}
            >
              返回
            </Button>
          </Space>
        </Form>
      </Wrapper>
    </>
  );
};

export default EditInput;
