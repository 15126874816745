import { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  usePaging,
  Table,
  Paging,
  useBreadcrumbRoutes,
  Button,
  Form,
  SubContent,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Row, Col, Space } from 'antd';
import { RulePageProps } from '../../../api/rule';
import { getRulesPageById } from '../../../api/ruleGroup';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { columns } from '../util';
import { IotProtocolType, IotProtocolTypeDisplay, AlarmChannel } from '@/shared/types';
import { sliceDeviceTypeName } from '@/shared/utils/utils';
import {
  V2RuleGroupInfoPostResponse,
  apiV2RuleGroupInfoPost,
  apiV2RuleGetChannelPost,
  V2RuleGetChannelPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: '规则组详情' }];

const AlarmRulesGroupDetail: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { search } = useLocation();

  const [ruleGroup, setRuleGroup] = useState<V2RuleGroupInfoPostResponse>();
  const [rules, setRules] = useState<RulePageProps[]>([]);
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const [alarmChannel, setAlarmChannel] = useState<AlarmChannel>();

  const pagingInfo = usePaging();

  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (ruleGroup) {
      setIotProtocolType(ruleGroup.iotProtocol);
      setAlarmChannel(ruleGroup.channel);
      form.setFieldsValue({
        name: ruleGroup.name,
        alarmChannel: alarmChannelList?.find(i => i.code === ruleGroup.channel)?.desc,
        iotProtocol: IotProtocolTypeDisplay[ruleGroup.iotProtocol!],
        deviceTypeId: ruleGroup.deviceTypeName ? sliceDeviceTypeName(ruleGroup.deviceTypeName) : '--',
        physicalModelId: ruleGroup.modelNo ?? '--',
      });
    }
  }, [ruleGroup, form, alarmChannelList]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getRulesPageById({ id: Number(id), page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setRules(res.list);
        }
      });
    }
  }, [id, pageOffset, pageSize, setTotalCount]);

  useEffect(() => {
    if (id) {
      apiV2RuleGroupInfoPost({ id: +id }).then(res => {
        setRuleGroup(res);
      });
    }
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form form={form} layout="vertical" {...formLayout}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item name="name" label="规则组名称">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="报警规则">
          <Row className={styles.info}>
            <Col span={8}>
              <Form.Item label="渠道" name="alarmChannel">
                <ShowInput />
              </Form.Item>
            </Col>
            {alarmChannel === AlarmChannel.LONGSHINE ? (
              <>
                <Col span={8}>
                  <Form.Item name="deviceTypeId" label="适用设备类目">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={8}>
                  <Form.Item name="iotProtocol" label="物联层协议">
                    <ShowInput />
                  </Form.Item>
                </Col>
                {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
                  <>
                    <Col span={8}>
                      <Form.Item name="deviceTypeId" label="适用设备类目">
                        <ShowInput />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="physicalModelId" label="物模型型号">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col span={24}>
              <Form.Item
                // name="rules"
                wrapperCol={{ span: 24 }}
              >
                {/* 分页列表 */}
                <Table
                  loading={loading}
                  sticky
                  scroll={{ x: 1300, y: 400 }}
                  rowKey="id"
                  columns={columns}
                  dataSource={rules}
                />
                <Paging pagingInfo={pagingInfo} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button
            className={styles.button}
            onClick={() => {
              navigate(`/device/rule/list${search}`);
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AlarmRulesGroupDetail;
