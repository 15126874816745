import { Space, Spin } from 'antd';
import { Checkbox, Form, Input, InputNumber, Modal, Select } from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  BindDeviceFormSubmitProps,
  MonitorDeviceManageType,
  MonitorDeviceManageTypeDisplay,
} from '../MonitorDeviceManage';
import { useRequest } from 'ahooks';
import { Button } from '@maxtropy/components';
import {
  apiV2SupervisoryAccountDeviceDetailPost,
  apiV2SupervisoryAccountDeviceSceneTargetInOu2Post,
  apiV2SupervisoryAccountDeviceVerifyDeviceUrlPost,
  apiV2SupervisoryAccountDeviceVerifyDeviceWeightingPost,
  apiV2OuOuListPost,
  V2OuOuListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { AccessMode } from '@/pages/VideoMonitoring/type';
import { ToolbarControlDisplay } from '@/pages/VideoMonitoring/components/HKVideoPlayer';
import { ipAddressValidator } from '@/shared/components/IpAddress';

export interface IBindOrEditMonitorDeviceFormModalProps {
  deviceTabActiveKey: string;
  monitorDeviceId?: number;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: BindDeviceFormSubmitProps) => Promise<void>;
  submitBtnLoading: boolean;
  accessMode?: AccessMode;
}

const BindOrEditMonitorDeviceFormModal: FC<IBindOrEditMonitorDeviceFormModalProps> = props => {
  const { monitorDeviceId, visible, onCancel, onSubmit, deviceTabActiveKey, submitBtnLoading, accessMode } = props;
  const [form] = Form.useForm();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [ou, setOu] = useState<V2OuOuListPostResponse['list']>();

  useEffect(() => {
    apiV2OuOuListPost({}).then(res => setOu(res.list || []));
  }, []);

  const ouOptions = useMemo(() => {
    if (ou?.length !== 0) {
      return ou?.map(item => ({ label: `${item.name}-${item.serialNumber}-${item.tenantName}`, value: item.id }));
    }
  }, [ou]);

  useEffect(() => {
    if (monitorDeviceId) {
      setDataLoading(true);
      apiV2SupervisoryAccountDeviceDetailPost({
        id: monitorDeviceId,
      }).then(res => {
        if (res) {
          setDataLoading(false);
          form.setFieldsValue({
            ...res,
            supervisoryTarget:
              res.supervisoryType === MonitorDeviceManageType.OTHERS
                ? res.supervisoryTarget
                : Number(res.supervisoryTarget),
          });
        }
      });
    }
  }, [monitorDeviceId, form]);

  const { data: monitorTargetList } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountDeviceSceneTargetInOu2Post({
        id: Number(deviceTabActiveKey),
      });
      return res ? res.list : [];
    },
    {
      ready: !!deviceTabActiveKey && visible,
      refreshDeps: [deviceTabActiveKey, visible],
    }
  );

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onOK = (values: BindDeviceFormSubmitProps) => {
    onSubmit(values);
  };

  // 是否有ou下拉列表
  const hasOuSelectOptions = useMemo(() => {
    const deviceActiveKey = Number(deviceTabActiveKey);
    return (
      deviceActiveKey === MonitorDeviceManageType.PV ||
      deviceActiveKey === MonitorDeviceManageType.BSA ||
      deviceActiveKey === MonitorDeviceManageType.NONELECENERGYMED
    );
  }, [deviceTabActiveKey]);

  // 监控目标下拉列表
  const targetOptions = useMemo(() => {
    if (monitorTargetList && monitorTargetList.length !== 0) {
      return monitorTargetList.map(i => ({
        label: deviceTabActiveKey === '4' ? `${i.supervisoryTargetName}-${i.tenantInfoName}` : i.supervisoryTargetName,
        value: i.supervisoryTargetId ?? i.supervisoryTargetName,
      }));
    }
  }, [monitorTargetList, deviceTabActiveKey]);

  const checkUniqueWeight = async (rule: any, value: number) => {
    if (value) {
      const res = await apiV2SupervisoryAccountDeviceVerifyDeviceWeightingPost({
        weighting: value,
        id: monitorDeviceId ?? undefined,
        supervisoryType: Number(deviceTabActiveKey),
      });
      if (!res.flag) {
        return Promise.reject('权重已被使用！');
      } else {
        return Promise.resolve();
      }
    }
  };
  const checkUniqueUrl = async (rule: any, value: string) => {
    if (value) {
      if (!/^((ezopen:)\/\/)(.{1,}\/)(.{1,}\/)(.{1,})$/.test(value)) {
        return Promise.reject(new Error('请输入正确URL'));
      }

      const res = await apiV2SupervisoryAccountDeviceVerifyDeviceUrlPost({
        supervisoryUrl: value,
        id: monitorDeviceId ?? undefined,
      });
      if (!res.flag) {
        return Promise.reject('监控地址(URL)-预览地址已被使用！');
      } else {
        return Promise.resolve();
      }
    }
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="large"
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      title={
        monitorDeviceId
          ? `编辑${MonitorDeviceManageTypeDisplay[deviceTabActiveKey as unknown as MonitorDeviceManageType]}监控设备`
          : `绑定${MonitorDeviceManageTypeDisplay[deviceTabActiveKey as unknown as MonitorDeviceManageType]}监控设备`
      }
      okText="保存"
      footer={
        <Space size={8}>
          <Button onClick={onCancel}>取消</Button>
          <Button
            type="primary"
            loading={submitBtnLoading}
            onClick={() => {
              form.submit();
            }}
          >
            保存
          </Button>
        </Space>
      }
    >
      <Spin spinning={dataLoading}>
        <Form onFinish={onOK} form={form}>
          {accessMode === AccessMode.YSY && (
            <Form.Item
              name="supervisoryDeviceName"
              label="监控设备名称"
              rules={[
                { required: true, message: '请输入' },
                { max: 50, message: '最多输入五十个字符' },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          )}
          <Form.Item
            name="supervisoryTarget"
            label="监控目标"
            rules={
              Number(deviceTabActiveKey) === MonitorDeviceManageType.OTHERS
                ? [
                    {
                      required: true,
                      message: '请输入',
                    },
                    { max: 20, message: '最多输入二十个字符' },
                  ]
                : [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ]
            }
          >
            {Number(deviceTabActiveKey) === MonitorDeviceManageType.OTHERS ? (
              <Input placeholder="请输入" />
            ) : (
              <Select placeholder="请选择" options={targetOptions} showSearch optionFilterProp="label" />
            )}
          </Form.Item>
          {!hasOuSelectOptions && (
            <Form.Item name="ouId" label="运营单元" rules={[{ required: true, message: '请选择' }]}>
              <Select placeholder="请选择" options={ouOptions} showSearch optionFilterProp="label" />
            </Form.Item>
          )}

          <Form.Item
            name="weighting"
            label="权重"
            validateTrigger={['onBlur']}
            rules={[
              { required: true, message: '请输入权重' },
              { validator: (rule: any, value: number) => checkUniqueWeight(rule, value) },
            ]}
          >
            <InputNumber style={{ width: '100%' }} min={1} step={1} precision={0} placeholder="请输入" />
          </Form.Item>
          {accessMode === AccessMode.YSY && (
            <Form.Item
              name="supervisoryUrl"
              label="监控地址(URL)-预览地址"
              validateTrigger={['onBlur']}
              rules={[
                { required: true, message: '请输入监控地址(URL)-预览地址' },
                { validator: (rule: any, value: string) => checkUniqueUrl(rule, value) },
              ]}
              extra={'格式如下：ezopen://[验证码@]域名/序列号/通道号[.清晰度].live[?参数1=值&参数2=值]'}
            >
              <Input placeholder="请输入" />
            </Form.Item>
          )}
          {accessMode === AccessMode.HK && (
            <>
              <Form.Item
                name="supervisoryDeviceName"
                label="监控设备名称"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 20, message: '最多输入20个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                name="monitorLocation"
                label="监控位置"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 50, message: '最多输入50个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                name="cameraUsername"
                label="用户名"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 50, message: '最多输入50个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item
                name="cameraPassword"
                label="密码"
                rules={[
                  { required: true, message: '请输入' },
                  { max: 50, message: '最多输入50个字符' },
                ]}
              >
                <Input.Password placeholder="请输入" />
              </Form.Item>
              <Form.Item
                name="ipAddress"
                label="IP地址"
                rules={[{ required: true, message: '请输入' }, { validator: ipAddressValidator }]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
              <Form.Item name="port" label="端口号" rules={[{ required: true, message: '请输入' }]}>
                <InputNumber placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="cameraPort" label="设备端口">
                <InputNumber placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="rtspPort" label="RTSP端口" rules={[{ required: true, message: '请输入' }]}>
                <InputNumber placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="enableFeatureList" label="启用功能" rules={[{ required: true, message: '请输入' }]}>
                <Checkbox.Group>
                  {Object.entries(ToolbarControlDisplay).map(([key, label]) => {
                    return (
                      key !== '0' && (
                        <Checkbox key={key} value={key}>
                          {label}
                        </Checkbox>
                      )
                    );
                  })}
                </Checkbox.Group>
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default BindOrEditMonitorDeviceFormModal;
