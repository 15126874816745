import {
  useBreadcrumbRoutes,
  useTenantPermissions,
  useUpdate,
  Wrapper,
  Button,
  usePaging,
  Paging,
  Table,
  EllipsisSpan,
  Select,
  Radio,
  Input,
  useAsync,
  CustomFilter,
  Form,
} from '@maxtropy/components';
import { List } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Card from './component/Card';
import ModalComponent from './component/ModalComponent';
import { getScadaPage, ScadaPageRes } from '../../api/scada';
import { getOuListOwned } from '../../api/ou';
import { PermissionsType } from '../../common/permissionsConst';
import { OuStatus } from '@/shared/const';
import dayjs from 'dayjs';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import styles from './index.module.scss';

// const routes = [
//   {name: 'Iot配置'},
//   {name: '组态管理'},
//   {name: '组态列表'},
// ];

interface FilterParams {
  name?: string;
  ouIds?: number[];
}

enum Mode {
  CARD = 1,
  LIST = 2,
}

export const ModeDisplay = {
  [Mode.CARD]: '卡片模式',
  [Mode.LIST]: '列表模式',
};

const ScadaList: React.FC = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<FilterParams>();
  const [list, setList] = useState<ScadaPageRes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, forceUpdate] = useUpdate();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset, totalCount } = pagingInfo;
  const [selectedMode, setSelectedMode] = useState(Mode.LIST);

  const permission = useTenantPermissions();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const ou = useAsync(getOuListOwned, []).filter(i => i.status === OuStatus.ENABLE);

  const columns = [
    {
      title: '组态名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作',
      render: (text: string, record: ScadaPageRes) => {
        return (
          <Card
            isCardMode={false}
            data={record}
            onCancel={notGotoFirstPage => {
              if (pageOffset * pageSize)
                if (!notGotoFirstPage) {
                  setPageOffset(1);
                } else {
                  const totalPage = Math.ceil((totalCount! - 1) / pageSize);
                  setPageOffset(pageOffset > totalPage ? totalPage : pageOffset);
                }
              forceUpdate();
            }}
            permission={permission}
          />
        );
      },
      width: 500,
    },
  ];

  useEffect(() => {
    setLoading(true);
    getScadaPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(data => {
      setTotalCount(data.total);
      setList(data.list);
      setLoading(false);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, update]);

  const onFinish = (val: FilterParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      name: undefined,
      ouIds: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item label="组态名称" name="name">
        <Input placeholder="请输入组态名称" />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择所属运营单元"
          showSearch
          mode="multiple"
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {ou.map(i => (
            <Select.Option key={i.id} value={i.id}>
              {i.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {(permission ?? []).includes(PermissionsType.B_CREATESCADA) ? (
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined />
            新建组态
          </Button>
        ) : (
          <div />
        )}
        <Radio.Group
          options={Object.entries(ModeDisplay).map(([key, value]) => ({
            label: value,
            value: Number(key),
          }))}
          onChange={(e: RadioChangeEvent) => {
            setSelectedMode(e.target.value);
          }}
          value={selectedMode}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      {selectedMode === Mode.CARD ? (
        <List
          loading={loading}
          style={{ marginTop: 15 }}
          grid={{ gutter: 16, column: 4 }}
          dataSource={list}
          renderItem={item => (
            <List.Item>
              <Card
                data={item}
                onCancel={notGotoFirstPage => {
                  if (pageOffset * pageSize)
                    if (!notGotoFirstPage) {
                      setPageOffset(1);
                    } else {
                      const totalPage = Math.ceil((totalCount! - 1) / pageSize);
                      setPageOffset(pageOffset > totalPage ? totalPage : pageOffset);
                    }
                  forceUpdate();
                }}
                permission={permission}
              />
            </List.Item>
          )}
        />
      ) : (
        <Table style={{ marginTop: 12 }} rowKey="id" loading={loading} columns={columns} dataSource={list} />
      )}
      <Paging pagingInfo={pagingInfo} />
      {visible && (
        <ModalComponent
          title="新建组态"
          isVisible
          isAdd
          onCancel={() => {
            setVisible(false);
            setPageOffset(1);
            forceUpdate();
          }}
        />
      )}
    </Wrapper>
  );
};

export default ScadaList;
