import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CardItem from './CardItem';
import classNames from 'classnames';
import styles from './index.module.scss';
import { Key } from '@maxtropy/components';
import { isNil } from 'lodash-es';

export interface SwipperListItem {
  energyMediumId?: number; //能源工质i
  energyMediumName?: string;
  consumptionValue?: number;
  momPercent?: number;
  generalName?: string;
  basePhysicalQuantityId?: number; // 基础物理量id
}

interface Props {
  list: SwipperListItem[] | null;
  selectedMediumType: Key;
  selectedBasePhysicalQuantityId: number;
  setSelectedMediumType: (id: Key) => void;
  setCurrentSelectedIds: (id: number[]) => void;
  setSelectedBasePhysicalQuantityId: (id: number) => void;
}

const MediumSwipper: React.FC<Props> = ({
  list,
  selectedMediumType,
  selectedBasePhysicalQuantityId,
  setSelectedMediumType,
  setCurrentSelectedIds,
  setSelectedBasePhysicalQuantityId,
}) => {
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();
  return (
    <div className={styles.swiperWrapper}>
      <Swiper
        spaceBetween={18}
        slidesPerView={4}
        freeMode={true}
        observer={true}
        observeParents={true}
        onSwiper={swiper => setSwiperIntance(swiper)}
        navigation={{
          nextEl: '.swiper-button-prev',
          prevEl: '.swiper-button-next',
        }}
      >
        {!isNil(list) &&
          list.map((item, index) => (
            <SwiperSlide key={index}>
              <CardItem
                setCurrentSelectedIds={setCurrentSelectedIds}
                cardInfo={item}
                selectedBasePhysicalQuantityId={selectedBasePhysicalQuantityId}
                selectedMediumType={selectedMediumType}
                setSelectedMediumType={setSelectedMediumType}
                setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      <div
        className={classNames(`swiper-button-prev`, styles.swiper_prev_btn)}
        onClick={() => swiperIntance?.slidePrev()}
      >
        <LeftOutlined />
      </div>
      <div
        className={classNames(`swiper-button-next`, styles.swiper_next_btn)}
        onClick={() => swiperIntance?.slideNext()}
      >
        <RightOutlined />
      </div>
    </div>
  );
};

export default MediumSwipper;
