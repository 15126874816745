import { fetch } from '@maxtropy/components';
import { DeviceType } from '@/shared/types';
import { OuInfoProps } from './ou';
import { Device } from './device';
import { PageRequest, PageResponse } from './page';
import { CircuitProps, LoadTransformerDetailDtosProps } from './uet';
import { Key } from 'react';

export enum GridConnectionStatus {
  FULL = 0,
  RESIDUAL,
  SELF,
}

export const GRID_CONNECTION_STATUS_MAP = {
  [GridConnectionStatus.FULL]: '全额上网',
  [GridConnectionStatus.RESIDUAL]: '余电上网',
  [GridConnectionStatus.SELF]: '余电弃光',
};

// 光伏站基础信息
export interface UetPvBaseInfoProps {
  uetId: number;
  name: string;
  ouId: number;
  ouName?: string;
  isSurplusPowerToGrid: boolean;
  pvStationId?: number;
}
// 并网点基础信息
export interface GridConnectionPointBaseProps {
  name?: string;
  address?: string;
  level?: number;
  meterId?: number;
  gatewayMeterId?: number;
  loadTransformerIds?: number[];
  pvStationId?: number;
  gridConnectionStatus?: GridConnectionStatus;
}
export interface UetPvReq {
  [key: string]: UetPvBaseInfoProps | GridConnectionPointBaseProps[];
}

export interface PriceItem {
  id?: number;
  electricityPrice?: number;
  beginDate?: string;
  endDate?: string;
}

// 光伏站信息
export interface PvInfoProp extends UetPvBaseInfoProps {
  id?: number;
  ou?: OuInfoProps;
  code?: string;
  hasPutOn?: boolean; // 是否已投放
  status?: number;
  createTime?: any;
  updateTime?: any;
  mcid?: string;
  updateByUserId?: string;
  updateByUsername?: string;
  costElectricityPrice?: number;
  feedInElectricityPrices: PriceItem[];
  subsidyElectricityPrices: PriceItem[];
  gatewayMeterId?: number; // 关口表id
  gatewayMeterName?: string; // 关口表名称
  installedCapacity?: Key;
}

export enum GenerateDirectionStatus {
  FORWARD = 1,
  REVERSE = 2,
}

export const GenerateDirectionStatusFormat = {
  [GenerateDirectionStatus.FORWARD]: '正向',
  [GenerateDirectionStatus.REVERSE]: '反向',
};
// 并网点信息
export interface NetInfoProp extends GridConnectionPointBaseProps {
  id?: number;
  meter?: Device;
  gatewayMeter?: Device;
  createTime?: any;
  updateTime?: any;
  circuitId?: number;
  circuitName?: string;
  loadTransformers?: LoadTransformerDetailDtosProps[];
  isForwOrRevEdit?: boolean; // 是否编辑正反向
  meterGenerateDirection?: GenerateDirectionStatus; // 计量电表发电方向(1:正向 2:反向)
  gatewayMeterGenerateDirection?: GenerateDirectionStatus; // 关口计量表计发电方向(1:正向 2:反向)
}

// UET下所有的光伏站
export interface PvListProps {
  pvStation: PvInfoProp;
  gridConnectionPoints: NetInfoProp[];
  circuits?: CircuitProps[];
}

// 创建光伏站
export const createUetPv = (id: string, body: UetPvReq) =>
  fetch<PvListProps>(
    `/api/v2/uet/pv-station/create/pvStation`,
    {
      method: 'POST',
      body: JSON.stringify({
        uetId: id,
        ...body,
      }),
    },
    true
  );

// 更新光伏站信息
export const updateUetPv = (id: string, body: UetPvReq) =>
  fetch<PvListProps>(
    `/api/v2/uet/pv-station/update/pvStation`,
    {
      method: 'POST',
      body: JSON.stringify({
        uetId: id,
        ...body,
      }),
    },
    true
  );

// 查询uet下所有的光伏站
export const getUetPvList = (id: string) =>
  fetch<{ list: PvListProps[] }>(
    `/api/v2/uet/pv-station/all/pvStation`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    },
    true
  ).then(res => res.list);
// 查询变压器列表
export const getLoadTransformerList = (id: any) =>
  fetch<{ loadTransformerDetailDtos: LoadTransformerDetailDtosProps[] }>(`/api/uet/${id}/available_prod_load`);
// 投放光伏站
export const openUetPv = (id: any, pvId?: number) =>
  fetch<boolean>(`/api/uet/${id}/pvStation/${pvId}`, {
    method: 'PUT',
  });
// 获取指定回路相关的计费回路
export const getGridBindingList = (ids: number[]) =>
  fetch<{ list: CircuitProps[] }>(
    `/api/v2/circuit/grid-billing/list`,
    {
      body: JSON.stringify({
        ids,
      }),
      method: 'POST',
    },
    true
  ).then(res => res.list);

// 获取电表类目
export const getMeterDevice = () =>
  fetch<{ deviceTypes: DeviceType[] }>(
    `/api/v2/deviceType/treeWithoutScene/electricityMeter`,
    {
      method: 'POST',
    },
    true
  );
// 获取电表类目设备
export interface MeterDevicePageRequest extends PageRequest {
  nameOrCode?: string;
  deviceType?: string;
  tag?: string;
  circuitId?: Key;
}
export interface MeterListItem {
  id?: number;
  code?: string;
  name?: string;
  deviceType?: string;
  deviceTags?: string[];
  customerName?: string;
  typeId?: number;
}
export const getMeterDevicePage = (params: MeterDevicePageRequest) =>
  fetch<PageResponse<MeterListItem>>(
    `/api/v2/uet/pv-station/device/page/electricityMeter`,
    {
      body: JSON.stringify(params),
      method: 'POST',
    },
    true
  );
// 校验该回路是否绑定了并网点
export const checkBindCircuit = (params: { circuitId: number; gridConnectPointId?: number }) =>
  fetch(
    `/api/v2/uet/pv-station/check/bindCircuit`,
    {
      body: JSON.stringify(params),
      method: 'POST',
    },
    true
  );
// 获取指定回路相关的计费回路
export const getBindingCircuitList = (ids: number[]) =>
  fetch<{ list: CircuitProps[] }>(
    `/api/v2/circuit/grid-billing/list`,
    {
      body: JSON.stringify({ ids }),
      method: 'POST',
    },
    true
  ).then(res => res.list);
