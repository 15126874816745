import { RestType } from '@/api/shiftCalendar';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { createFromIconfontCN } from '@ant-design/icons';
import { Button, PopConfirm } from '@maxtropy/components';
import { Divider, Space } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { CardListItem } from '..';
import { apiV2WorkShiftCalendarDeletePost } from '@maxtropy/device-customer-apis-v2';
import { options } from '../util';
interface Iprops {
  data?: CardListItem;
  update?: () => void;
}
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2552569_omes2bz54ze.js',
});

const formateTimeSeg = (restType?: RestType, restSeg?: string) => {
  if (!restSeg || !restType) return '-';
  let arr = restSeg.split(',');
  let formateData = options[restType];
  let filters = formateData.filter(item => arr.includes(item.value));
  return filters.map(item => item.label).join(' , ');
};

const CalendarCard: React.FC<Iprops> = ({ data, update }) => {
  const hasViewPermission = useHasPermission(PermissionsType.B_SHIFTCALENDAR_DETAIL);
  const hasDeletePermission = useHasPermission(PermissionsType.B_SHIFTCALENDAR_DELETE);
  const hasEditPermission = useHasPermission(PermissionsType.B_SHIFTCALENDAR_UPDATE);
  // TODO权限要更新
  const hasUsePermission = useHasPermission(PermissionsType.B_SHIFTCALENDAR_USE);

  const navigate = useNavigate();
  const viewBtn = () => {
    navigate(`/energy/teamShift/shiftCalendar/detail/${data?.id}/view`);
  };
  const editBtn = () => {
    navigate(`/energy/teamShift/shiftCalendar/edit/${data?.id}/edit`);
  };
  const deleteBtn = () => {
    apiV2WorkShiftCalendarDeletePost({ id: data?.id! }).then(_ => update?.());
  };

  return (
    <>
      <div className={styles.outer}>
        <div className={styles.header}>
          <Space size={5}>
            <div className={styles.head_icon}>
              <IconFont type="icon-calendar-check" />
            </div>
            <div className={styles.title}>{data?.name ?? '--'}</div>
          </Space>
        </div>
        <div className={styles.content}>
          <div className={styles.line}>
            <div className={styles.label}>休息日: </div>
            <div className={styles.value}>{formateTimeSeg(data?.restType, data?.restSeg)}</div>
          </div>
          <div className={styles.line}>
            <div className={styles.label}>班次: </div>
            <div className={styles.value}>{data?.workShifts?.map(item => item.name).join(' , ')}</div>
          </div>
        </div>
        <div className={styles.footer}>
          {hasViewPermission && (
            <>
              <Button type="link" onClick={viewBtn}>
                查看
              </Button>
              <Divider type="vertical"></Divider>
            </>
          )}

          {hasEditPermission && (
            <>
              <Button type="link" onClick={editBtn}>
                编辑
              </Button>
              <Divider type="vertical"></Divider>
            </>
          )}
          {hasDeletePermission && (
            <>
              <PopConfirm title={`确认删除${data?.name}?`} onConfirm={deleteBtn} okText="确定" cancelText="取消">
                <Button type="link">删除</Button>
              </PopConfirm>
              <Divider type="vertical"></Divider>
            </>
          )}
          {hasUsePermission && (
            <Button type="link">
              <Link to={`/energy/teamShift/shiftCalendar/configuration/${data?.id}`} target="_blank">
                应用
              </Link>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default CalendarCard;
