import { ActionType, ActionTypeDisplay } from '@/pages/Account/utils';
import {
  EnergyMediumItem,
  IndicatorsItem,
  MediumAccountItem,
  PhysicalUnitItem,
  WorkingSubstanceContractCreateParams,
  addWorkingSubstanceContract,
  getEnergyMediumByContractId,
  getIndicatorsByEnergyMediumId,
  getMediumAccountList,
  getMediumContractSingleById,
  getPhysicalUnitByPhysicalQuantityId,
  updateWorkingSubstanceContract,
} from '@/api/workingSubstance';
import { Form, Input, InputNumber, Key, Modal, Select } from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  isShow: ActionType;
  setIsShow: (v: ActionType | undefined) => void;
  updateFn: () => void;
  contractId?: Key;
}

const CreateForm: React.FC<Props> = ({ isShow, setIsShow, updateFn, contractId }) => {
  const [form] = Form.useForm();
  const [mediumAccountList, setMediumAccountList] = useState<MediumAccountItem[]>([]); // 关联账户
  const [energyMediumList, setEnergyMediumList] = useState<EnergyMediumItem[]>([]); // 适用能源介质
  const [indicatorsList, setIndicatorsList] = useState<IndicatorsItem[]>([]); // 对应监测指标
  const [physicalUnitList, setPhysicalUnitList] = useState<PhysicalUnitItem[]>([]); // 费率单位
  const mediumAccountId = Form.useWatch('mediumAccountId', form); // 关联账户
  const energyMediumId = Form.useWatch('energyMediumId', form); // 适用能源介质
  const energyMediumIndicatorId = Form.useWatch('energyMediumIndicatorId', form); // 对应监测指标

  useEffect(() => {
    if (contractId) {
      getMediumContractSingleById(contractId).then(res => {
        form.setFieldsValue({
          contractNumber: res.contractNumber,
          name: res.name,
          mediumAccountId: res.mediumAccountName,
          energyMediumId: res.energyMediumName,
          energyMediumIndicatorId: res.energyMediumIndicatorName,
          physicalUnitId: `元/${res.physicalUnitName}`,
          precision: res.precision,
          remark: res.remark,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  useEffect(() => {
    //得到staff权限下相关工质账户列表
    if (isShow === ActionType.CREATE) getMediumAccountList().then(res => setMediumAccountList(res.list));
  }, [isShow]);

  useEffect(() => {
    // 根据关联工质合同账户查询适用的能源介质
    if (mediumAccountId && isShow === ActionType.CREATE) {
      getEnergyMediumByContractId(mediumAccountId).then(res => setEnergyMediumList(res.list));
    }
  }, [mediumAccountId, isShow]);

  useEffect(() => {
    // 对应监测指标
    if (energyMediumId && isShow === ActionType.CREATE) {
      getIndicatorsByEnergyMediumId(energyMediumId).then(res => {
        setIndicatorsList(res);
      });
    }
  }, [energyMediumId, isShow]);

  useEffect(() => {
    // 费率单位
    if (energyMediumIndicatorId && isShow === ActionType.CREATE) {
      const basePhysicalQuantityId = indicatorsList
        .filter(i => i.indicatorId === energyMediumIndicatorId)
        .at(0)?.basePhysicalQuantityId;
      if (basePhysicalQuantityId) getPhysicalUnitByPhysicalQuantityId(basePhysicalQuantityId).then(setPhysicalUnitList);
    }
  }, [energyMediumIndicatorId, indicatorsList, isShow]);

  // 关联账户
  const mediumAccountOptions = useMemo(() => {
    return mediumAccountList.map(i => ({ label: i.accountNumber, value: i.id }));
  }, [mediumAccountList]);

  // 适用能源介质
  const energyMediumOptions = useMemo(() => {
    return energyMediumList.map(i => ({ label: i.energyName, value: i.id }));
  }, [energyMediumList]);

  // 对应监测指标
  const indicatorsOptions = useMemo(() => {
    return indicatorsList.map(i => ({ label: i.indicatorName, value: i.indicatorId }));
  }, [indicatorsList]);

  // 费率单位
  const physicalUnitOptions = useMemo(() => {
    return physicalUnitList.map(i => ({ label: `元/${i.generalName}`, value: i.id }));
  }, [physicalUnitList]);

  const onFinish = (v: WorkingSubstanceContractCreateParams) => {
    if (isShow === ActionType.CREATE) {
      addWorkingSubstanceContract(v).then(() => {
        setIsShow(undefined);
        updateFn();
      });
    }
    if (isShow === ActionType.UPDATE && contractId) {
      updateWorkingSubstanceContract({
        name: v.name,
        remark: v.remark,
        id: contractId,
      }).then(() => {
        setIsShow(undefined);
        updateFn();
      });
    }
  };

  const disabled = isShow === ActionType.UPDATE;
  return (
    <>
      <Modal
        title={`${ActionTypeDisplay[isShow]}工质合同`}
        contentClassName="modal-form-content"
        open={!!isShow}
        onCancel={() => {
          setIsShow(undefined);
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form form={form} onFinish={(v: WorkingSubstanceContractCreateParams) => onFinish(v)}>
          <Form.Item
            name="contractNumber"
            label="合同编号"
            rules={[
              { required: true, message: '请输入合同编号', whitespace: true },
              {
                max: 30,
                message: '最多输入30个字符',
              },
            ]}
          >
            <Input placeholder="请输入" disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="name"
            label="合同名称"
            rules={[
              { required: true, message: '请输入合同名称', whitespace: true },
              {
                max: 30,
                message: '最多输入30个字符',
              },
            ]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item name="mediumAccountId" label="关联账户" rules={[{ required: true, message: '请选择账户' }]}>
            <Select
              placeholder="请选择"
              disabled={disabled}
              options={mediumAccountOptions}
              onChange={() => {
                form.setFieldsValue({
                  energyMediumId: undefined,
                  energyMediumIndicatorId: undefined,
                  physicalUnitId: undefined,
                });
                setEnergyMediumList([]);
                setIndicatorsList([]);
                setPhysicalUnitList([]);
              }}
            />
          </Form.Item>
          <Form.Item
            name="energyMediumId"
            label="适用能源介质"
            rules={[{ required: true, message: '请选择适用能源介质' }]}
          >
            <Select
              placeholder="请选择"
              disabled={disabled}
              options={energyMediumOptions}
              onChange={() => {
                form.setFieldsValue({
                  energyMediumIndicatorId: undefined,
                  physicalUnitId: undefined,
                });
                setIndicatorsList([]);
                setPhysicalUnitList([]);
              }}
            />
          </Form.Item>
          <Form.Item
            name="energyMediumIndicatorId"
            label="对应监测指标"
            rules={[{ required: true, message: '请选择对应监测指标' }]}
          >
            <Select
              placeholder="请选择"
              disabled={disabled}
              options={indicatorsOptions}
              onChange={() => {
                form.setFieldsValue({
                  physicalUnitId: undefined,
                });
                setPhysicalUnitList([]);
              }}
            />
          </Form.Item>
          <Form.Item name="physicalUnitId" label="费率单位" rules={[{ required: true, message: '请选择费率单位' }]}>
            <Select placeholder="请选择" disabled={disabled} options={physicalUnitOptions} />
          </Form.Item>
          <Form.Item
            name="precision"
            label="价格精确位数"
            rules={[{ required: true, message: '请输入价格精确位数' }]}
            initialValue={2}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={8}
              placeholder="请输入"
              disabled={disabled}
              precision={0}
            />
          </Form.Item>
          <Form.Item name="remark" label="备注" rules={[{ max: 100, message: '最多输入100个字符' }]}>
            <Input.TextArea placeholder="请输入" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateForm;
