import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { MentionsInput, Mention, MentionItem } from 'react-mentions';
import useMergedState from '../../hooks/useMergedState';
import classnames from 'classnames';
import './index.scss';
import { uniqBy } from 'lodash-es';
import { DataProperty } from '../../types';
import { Tooltip } from '@maxtropy/components';
import dayjs from 'dayjs';

interface DataSourceProps {
  id: string;
  display: string;
  description: string;
}

interface FormulaInputProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  dataProperties: DataSourceProps[];
  identifier: DataSourceProps[];
}

function extractIdentifier(str: string): string {
  const rx = /_([a-zA-Z][a-zA-Z0-9]*)/;
  const arr = rx.exec(str);
  if (Array.isArray(arr)) {
    if (arr[1].length > 30) {
      return '';
    }
    return arr[1];
  } else {
    return '';
  }
}

function extractIdentifierArray(str: string) {
  const rx = /x000([a-zA-Z][a-zA-Z0-9]*)\(\)/g;
  const arr = Array.from(str.matchAll(rx));
  const _arr = arr.map(item => item[1]).filter(Boolean);
  return _arr.map(value => ({
    id: `x000${value}()`,
    display: value,
    description: '非建模',
  }));
}

const FormulaInput: React.FC<FormulaInputProps> = props => {
  const prefixCls = 'mx-mentions';
  const { dataProperties, identifier } = props;
  const [mergedValue, setMergedValue] = useMergedState('', { value: props.value, onChange: props.onChange });
  const [originalDataSource, setOriginalDataSource] = useState<DataSourceProps[]>([]);
  const [dataSource, setDataSource] = useState<DataSourceProps[]>([]);
  const [inputValue, setInputValue] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    generateOriginalDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedValue]);

  useEffect(() => {
    generateDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalDataSource, inputValue]);

  const displayTransform = (id: string, display: string) => {
    return dataSource.find(item => item.id == id)?.display || id;
  };

  const renderSuggestion = (
    suggestion: any,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    const { display, description } = suggestion;
    if (focused) {
      window.requestAnimationFrame(() => {
        const item = document.getElementsByClassName(`${prefixCls}__suggestions__item-focused`).item(0);
        item?.scrollIntoView({ block: 'nearest' });
      });
    }
    return (
      <div
        className={classnames('${prefixCls}__suggestions__item', {
          [`${prefixCls}__suggestions__item-focused`]: focused,
        })}
      >
        <div className="point">
          <div className="display">{display}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    );
  };

  const generateOriginalDataSource = () => {
    const identifierArray = extractIdentifierArray(mergedValue);
    const _originalDataSource = uniqBy([...dataProperties, ...identifier, ...identifierArray], 'id');
    setOriginalDataSource(_originalDataSource);
  };

  const generateDataSource = () => {
    setDataSource([
      ...originalDataSource,
      ...(!!inputValue && !originalDataSource.some(item => item.display === inputValue)
        ? [
            {
              id: `x000${inputValue}()`,
              display: inputValue,
              description: '非建模',
            },
          ]
        : []),
    ]);
  };

  const onChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setMergedValue(newValue);

    const value = extractIdentifier(newValue);
    setInputValue(value);
  };

  return (
    <Row wrap={false}>
      <Col ref={containerRef} style={{ flex: 1 }}>
        <MentionsInput
          allowSuggestionsAboveCursor
          value={mergedValue}
          onChange={onChange}
          placeholder="请输入"
          rows={4}
          className={classnames(prefixCls)}
          inputRef={(el: any) => {
            el?.classList.add('ant-input');
          }}
          suggestionsPortalHost={containerRef.current as Element}
        >
          {
            <Mention
              data={dataSource}
              trigger="_"
              markup={'@[__id__]@'}
              appendSpaceOnAdd
              displayTransform={displayTransform}
              renderSuggestion={renderSuggestion}
            />
          }
        </MentionsInput>
      </Col>
      <Tooltip
        overlayStyle={{ width: 560, maxWidth: 560, height: 600, overflowX: 'hidden', overflowY: 'auto' }}
        placement="left"
        title={
          <div>
            <h3 style={{ color: '#FFFFFF' }}>公式说明</h3>
            <h4 style={{ color: '#FFFFFF' }}>示例</h4>
            <div>
              <div>{`_A相电压 * pow(10, _A相电流)`}</div>
              <div>{`代表<A相电压>乘以10的<A相电流>次方。`}</div>
              <div>{`_A相电压 > 200 && _A相电压 < 240`}</div>
              <div>{`代表<A相电压>大于200且小于240时，值为1.0，否则为0.0`}</div>
              <div>{`abs(avg(_A相电压,_B相电压,_C相电压)-max(_A相电压,_B相电压,_C相电压))/avg(_A相电压,_B相电压,_C相电压)`}</div>
              <div>{`代表<A相电压>,<B相电压>,<C相电压>的不平衡度`}</div>
            </div>
            <br />
            <h4 style={{ color: '#FFFFFF' }}>详细说明</h4>
            <Space size={[0, 10]} direction="vertical">
              <div>
                <div>{`1. 输入下划线，可以调出数据属性列表，点击选择。`}</div>
                <div>{`_A相电压 代表数据属性为A相电压的数据属性，在下文中，统一描述为<数据属性1>`}</div>
              </div>

              <div>
                <div>{`2. 运算符（按优先级）：`}</div>
                <ul>
                  <li>{`!(非)， ~(位取反)`}</li>
                  <li>{`+(加),-(减)`}</li>
                  <li>{`<< >> 左移右移`}</li>
                  <li>{`<(小于),<=(小于等于),>(大于),>=(大于等于)`}</li>
                  <li>{`==(等于),!=(不等于)`}</li>
                  <li>{`& 按位与`}</li>
                  <li>{`^ 按位异或`}</li>
                  <li>{`| 按位或`}</li>
                  <li>{`&&(与),||(或)`}</li>
                </ul>
              </div>

              <div>
                <div>3. 函数表</div>
                <ul>
                  <li>{`avg2(_A相电压, _B相电压)  <数据属性1>和<数据属性2>的平均值`}</li>
                  <li>{`avg3(_A相电压, _B相电压, 400)  <数据属性1>、<数据属性2>和400的平均值`}</li>
                  <li>{`avg(_A相电压, _B相电压, 400)  <数据属性1>、<数据属性2>和400的平均值(不限个数)。avg系列函数参数顺序不限。`}</li>
                  <li>{`max2(_A相电压, 25)  <数据属性1>和25的较大值。`}</li>
                  <li>{`max3(_A相电压, _B相电压, 25)  <数据属性1>、<数据属性2>和25的较大值。`}</li>
                  <li>{`max(_A相电压, 25)  <数据属性1>和25的较大值(不限个数).max系列函数参数顺序不限。`}</li>
                  <li>{`min(_A相电压, 0.0)  <数据属性1>和0.0的较小值。min系列函数和max类似，有min2,min3`}</li>
                  <li>{`pow(10,_A相电压)  10的<数据属性1>次幂`}</li>
                  <li>{`abs(_A相电压)  <数据属性1>的绝对值`}</li>
                  <li>{`sqrt(_A相电压)  <数据属性1>的0.5次幂`}</li>
                  <li>{`sin(_A相电压)  <数据属性1>的正弦(弧度)`}</li>
                  <li>{`cos(_A相电压)  <数据属性1>的余弦(弧度)`}</li>
                  <li>{`tan(_A相电压)  <数据属性1>的正切(弧度)`}</li>
                  <li>{`asin(_A相电压)  <数据属性1>的反正弦(弧度)`}</li>
                  <li>{`acos(_A相电压)  <数据属性1>的反余弦(弧度)`}</li>
                  <li>{`atan(_A相电压)  <数据属性1>的反正切(弧度)`}</li>
                  <li>{`if(_A相电压, 1, -1) <数据属性1>不为0.0(即_1 != false)时返回1，否则返回-1。`}</li>
                  <li>{`up(_A相电压) 开关量0->1`}</li>
                  <li>{`down(_A相电压) 开关量1->0`}</li>
                </ul>
                <div style={{ margin: '0 0 10px 20px' }}>采集周期：网关配置中的采集间隔</div>
                <ul>
                  <li>{`pAvg(_A相电压) 采集周期内的平均值`}</li>
                  <li>{`pSum(_A相电压) 采集周期内的总值`}</li>
                  <li>{`pMax(_A相电压) 采集周期内的最大值`}</li>
                  <li>{`pMin(_A相电压) 采集周期内的最小值`}</li>
                  <li>{`pAscend(_A相电压) 采集周期内的上升值`}</li>
                  <li>{`pDescend(_A相电压) 采集周期内的下降值`}</li>
                  <li>{`pAbsDif(_A相电压) 采集周期内的绝对值`}</li>
                  <li>{`smavg(_A相电压,15) 时移平均：第一个参数时点id，第二个参数是时间窗口，单位为秒，具体范围是[now-windows,now]。**参数顺序级类型不能乱**。该函数求数据属性过去15秒内数据的算术平均数。`}</li>
                </ul>
              </div>
            </Space>
          </div>
        }
      >
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Tooltip>
    </Row>
  );
};

export default FormulaInput;

export const formatOnSave = (value: string = '') => {
  let str = formateDailyTimeIntervalToS(value ?? '');
  return formateWeekTimeIntervalToForm(str ?? '')
    .replace(/@\[/g, '')
    .replace(/]@/g, '');
};

export const parseOnEcho = (value: string = '') => {
  let str = formateDailyTimeIntervalTo24(value ?? '');
  return formateWeekTimeIntervalToDisplay(str)
    .replace(/(x_\w+)/g, '@[' + '$1' + ']@')
    .replace(/(x000[a-zA-Z][a-zA-Z0-9]*\(\))/g, '@[' + '$1' + ']@');
};
export const parseOnDisplay = (value: string = '', map: Map<string, string>) => {
  let str1 = formateDailyTimeIntervalTo24(value ?? '');
  let str2 = formateWeekTimeIntervalToDisplay(str1);
  // 如果是非建模点, 将非建模点x000***()***替换掉
  let _value = str2.replace(/x000([a-zA-Z][a-zA-Z0-9]*)\(\)/g, '$1'); // "x000k123()_6745630 >1" --> 'k123_6745630 >1'

  const x_rx = /x_(\w+)_?(\w?)/g;
  const x000_rx = /[a-zA-Z][a-zA-Z0-9]*_(\w+)/g;

  let arr = Array.from(_value.matchAll(x_rx));

  /**
   * 上述正则匹配arr的结果如下
   *  x_123_1334 --> [['x_123_1334', '123_1334']]
   *  x_123 --> [['x_123', '123']]
   */
  if (arr.length === 0) {
    arr = Array.from(_value.matchAll(x000_rx));
    /**
     * 上述正则匹配arr的结果如下
     *  'k123_6745630' --> [['k123_6745630', '6745630']]
     */
    if (arr.length > 0) {
      // 非建模点map中的key为k123_6745630
      let key = arr[0][0];
      arr = [[key, key]];
    }
  }

  const matchedStrIdMap = new Map(arr.map(item => [item[0], item[1]]));

  Array.from(matchedStrIdMap.keys()).forEach(key => {
    _value = _value.replaceAll(key, map.get(matchedStrIdMap.get(key)!) ?? key);
  });
  return _value;
};

// 拿到公式字符串中所有属性
export const getRelatedDataPropertiesFromFormula = (value: string = '', dataProperties: DataProperty[]) => {
  let _value = (value ?? '').replace(/x000([a-zA-Z][a-zA-Z0-9]*)\(\)/g, '$1');
  const rx = /x_(\w+)/g;
  const arr = Array.from(_value.matchAll(rx));
  const matchedStrIdMap = new Map(arr.map(item => [item[0], item[1]]));
  return dataProperties.filter(d => Array.from(matchedStrIdMap.values()).includes(d.id.toString()));
};

const checkBrackets = (formula: string) => {
  const stack = [];
  for (const i of formula) {
    if (i === '(') {
      stack.push(i);
    }
    if (i === ')') {
      if (!stack.length) {
        return false;
      }
      stack.pop();
    }
  }
  return !stack.length;
};

const unique = (array: any[]) => Array.from(new Set(array));

// 格式化dailyTimeInterval(10: 15, ?)将其转换成毫秒 -->  start <= dailyTimeInterval(x000()) <= end
export const formateDailyTimeIntervalToS = (formula?: string): string => {
  if (!formula) return '';
  let _formula = formula.replace(/\s+/g, '');
  let rangeTimeReg = /dailyTimeInterval\((\d{2}:\d{2})(?:,(\d{2}:\d{2}))?\)/;

  let rangeTimeMatch = rangeTimeReg.exec(_formula);
  if (!rangeTimeMatch) return _formula;
  let wholeContent = rangeTimeMatch[0];
  let startTimeStr = rangeTimeMatch[1];
  let endTimeStr = rangeTimeMatch[2] ?? rangeTimeMatch[1];
  let startArr = startTimeStr?.split(':');
  let endArr = endTimeStr?.split(':');
  let startS = (+startArr?.[0] ?? 0) * 60 * 60 + +(startArr?.[1] ?? 0) * 60 + +(startArr?.[2] ?? 0);
  let endS = (+endArr?.[0] ?? 0) * 60 * 60 + +(endArr?.[1] ?? 0) * 60 + +(endArr?.[2] ?? 0);
  let newFormula =
    endS > 0
      ? `${startS * 1000}<=dailyTimeInterval(x000())<=${endS * 1000}`
      : `${startS * 1000}<=dailyTimeInterval(x000())<=${startS * 1000}`;
  let finalStr = _formula.replace(wholeContent, newFormula);
  let isNeedContinue = rangeTimeReg.exec(finalStr);
  if (!isNeedContinue) return finalStr;
  return formateDailyTimeIntervalToS(finalStr);
};

// 格式化 weekTimeInterval(1, 7) 将其转换成 -->  1<=weekTimeInterval(x000())<=7
export const formateWeekTimeIntervalToForm = (formula?: string): string => {
  if (!formula) return '';
  let rangeTimeReg = /weekTimeInterval\((\d{1})(?:,(\d{1}))?\)/;
  let _formula = formula.replace(/\s+/g, '');
  let rangeTimeMatch = rangeTimeReg.exec(_formula);
  if (!rangeTimeMatch) return _formula;
  let wholeContent = rangeTimeMatch[0];
  let startTimeStr = rangeTimeMatch[1];
  let endTimeStr = rangeTimeMatch[2] ?? rangeTimeMatch[1];

  let newFormula = `${startTimeStr}<=weekTimeInterval(x000())<=${endTimeStr}`;
  let finalStr = _formula.replace(wholeContent, newFormula);
  if (rangeTimeReg.exec(finalStr)) {
    return formateWeekTimeIntervalToForm(finalStr);
  }
  return finalStr;
};

// 格式化 开始时间戳 <=dailyTimeInterval(x000())<= 结束时间戳 将其转换成dailyTimeInterval(10: 15, 12:15)
export const formateDailyTimeIntervalTo24 = (formula?: string): string => {
  if (!formula) return '';
  let rangeTimeReg = /(\d+)<\=dailyTimeInterval\(.*?\)<\=(\d+)/;
  let _formula = formula.replace(/\s+/g, '');
  let rangeTimeMatch = rangeTimeReg.exec(_formula);
  if (!rangeTimeMatch) return formula;
  let wholeContent = rangeTimeMatch[0];
  let startTimeContent = rangeTimeMatch[1];
  let endTimeContent = rangeTimeMatch[2] ?? rangeTimeMatch[1];
  let startTimeS = dayjs.duration(+startTimeContent, 'milliseconds').format('HH:mm');
  let endTimeS = dayjs.duration(+endTimeContent, 'milliseconds').format('HH:mm');
  let newFormula = `dailyTimeInterval(${startTimeS},${endTimeS})`;
  let finalStr = _formula.replace(wholeContent, newFormula);
  let isContinue = rangeTimeReg.exec(finalStr);
  if (!isContinue) {
    return finalStr;
  }
  return formateDailyTimeIntervalTo24(finalStr);
};

// 格式化 1<=weekTimeInterval(x000())<=7 将其转换成 dailyTimeInterval(1, 7)
export const formateWeekTimeIntervalToDisplay = (formula?: string): string => {
  if (!formula) return '';
  let rangeTimeReg = /(\b\d+)<=weekTimeInterval\((.*?)\)<=(\b\d+)/;
  let _formula = formula.replace(/\s+/g, '');
  let rangeTimeMatch = rangeTimeReg.exec(_formula);
  if (!rangeTimeMatch) return formula;
  let wholeContent = rangeTimeMatch[0];
  let startTimeContent = rangeTimeMatch[1];
  let endTimeContent = rangeTimeMatch[3] ?? rangeTimeMatch[1];
  let newFormula = `weekTimeInterval(${startTimeContent},${endTimeContent})`;
  let finalStr = _formula.replace(wholeContent, newFormula);
  if (!rangeTimeReg.exec(finalStr)) {
    return finalStr;
  }
  return formateWeekTimeIntervalToDisplay(finalStr);
};
// 校验weekTimeInterval函数括号中输入的数值
export const checkWeekTimeIntervalValid = (text: string): string => {
  let _text = text.replace(/\s+/g, '');
  let weekTimeReg = /weekTimeInterval\((.*?)\)/;
  let weekTimeRegMath = weekTimeReg.exec(_text);
  if (!weekTimeRegMath) return '';

  let extractedContent = weekTimeRegMath[1]; // 1, 5
  let strs = extractedContent.split(',');
  let valid = strs.every(n => {
    return /^[1-7]{1}$/.test(n);
  });
  if (!valid) return 'weekTimeInterval()中的星期格式不正确';
  if (strs.length <= 1) {
    return 'weekTimeInterval()中的星期格式不正确';
  }
  if (strs[0] > strs[1]) {
    return 'weekTimeInterval()中的起始时间要小于结束时间';
  }

  let _str = _text.replace(weekTimeRegMath[0], '');
  if (weekTimeReg.exec(_str)) {
    return checkWeekTimeIntervalValid(_str);
  }

  return '';
};
const checkDailyTimeIntervalValid = (formula: string): string => {
  let _formula = formula.replace(/\s+/g, '');
  let rangeTimeReg = /dailyTimeInterval\((.*?)\)/;
  let rangeTimeMatch = rangeTimeReg.exec(_formula);
  if (!rangeTimeMatch) return '';
  let timeReg = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/; // 24小时时间格式校验
  let extractedContent = rangeTimeMatch[1];
  if (!extractedContent.includes(',')) {
    return 'dailyTimeInterval()中的时间格式不正确';
  }
  let arr = extractedContent.split(',');
  if (arr.length !== 2) return 'dailyTimeInterval()中的参数不正确';
  let startTimeStr = extractedContent.split(',')[0];
  let endTimeStr = extractedContent.split(',')[1];
  if (!timeReg.test(startTimeStr) || !timeReg.test(endTimeStr)) {
    return 'dailyTimeInterval()中的时间格式不正确';
  }
  if (dayjs(`2024-10-01 ${startTimeStr}`).isAfter(dayjs(`2024-10-01 ${endTimeStr}`))) {
    return 'dailyTimeInterval()中的开始时间要小于结束时间';
  }

  let _str = _formula.replace(rangeTimeMatch[0], '');
  if (rangeTimeReg.exec(_str)) {
    return checkDailyTimeIntervalValid(_str);
  }
  return '';
};
export const getFormulaErrorMsg = (formula: string) => {
  const _formula = formatOnSave(formula);
  const matchReg = /[^\s\w:!%&()*+,./<=>|-]/g;
  if (_formula.match(matchReg)) {
    return `公式中存在特殊符号: ${unique(_formula.match(matchReg) || []).join(' ')}，请修改后再提交`;
  }
  if (!checkBrackets(_formula)) {
    return '公式不合法，检查括号是否配对正确，请修改后提交';
  }
  if (_formula.length > 500) {
    return '公式限制在500字符之内';
  }
  // 校验 dailyTimeInterval(10: 15, ?)或weekTimeInterval(1, ?) 是否合法
  let weekValid = checkWeekTimeIntervalValid(formula);
  if (weekValid) {
    return weekValid;
  }
  let dailyValid = checkDailyTimeIntervalValid(formula);
  if (dailyValid) {
    return dailyValid;
  }
  return '';
};

export const formulaValidator = (_: any, formula: string) => {
  const errMsg = getFormulaErrorMsg(formula);
  if (errMsg) {
    return Promise.reject([new Error(errMsg)]);
  }
  return Promise.resolve();
};
