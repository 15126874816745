import { Radio, Tooltip } from '@maxtropy/components';
import React, { FC, useState } from 'react';
import { CenterTime, CenterTimeDisPlay } from '../../type';
import styles from './index.module.scss';
import { RadioChangeEvent, Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2HvacDashboardDetailMomYoyPost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

interface ICenterDataCompartion {
  id?: string;
  type?: number;
}

const CenterDataCompartion: FC<ICenterDataCompartion> = props => {
  const { id, type } = props;
  const [selectBtn, setSelectBtn] = useState<CenterTime>(CenterTime.Yesterday);

  // 请求接口
  const { data: indicatorValueList, loading } = useRequest(
    () => {
      return apiV2HvacDashboardDetailMomYoyPost({
        id: Number(id),
        granularityType: selectBtn,
        type,
      });
    },
    {
      ready: !isNil(id) && !isNil(type) && !!selectBtn,
      refreshDeps: [id, type, selectBtn],
    }
  );

  // 渲染颜色
  const renderColor = (value?: number, indicatorName?: string) => {
    if (indicatorName === 'COP') {
      if (isNil(value) || value === 0) {
        return 'rgba(255,255,255,0.85)';
      } else if (value > 0) {
        return '#52C41A';
      } else {
        return '#E64242';
      }
    } else {
      return 'rgba(255,255,255,0.85)';
    }
  };

  return (
    <div className={styles.center_wrapper}>
      <div className={styles.center_wrapper_top}>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => setSelectBtn(e.target.value)}
          value={selectBtn}
          buttonStyle="solid"
        >
          <Radio.Button value={CenterTime.Yesterday}>{CenterTimeDisPlay[CenterTime.Yesterday]}</Radio.Button>
          <Radio.Button value={CenterTime.LastMonth}>{CenterTimeDisPlay[CenterTime.LastMonth]}</Radio.Button>
        </Radio.Group>
      </div>
      <Spin spinning={loading}>
        <div className={styles.center_content_wrapper}>
          {(indicatorValueList?.list ?? []).map(item => (
            <div className={styles.itemcard_wrapper} key={item.indicatorName}>
              <div className={styles.itemcard_wrapper_top}>
                <Tooltip title={`${item?.indicatorName ?? '--'}(${item?.physicalUnit?.generalName ?? '--'})`}>
                  <span className={styles.itemcard_wrapper_top_label}>{`${item?.indicatorName ?? '--'}${
                    item.indicatorName !== 'COP' ? `(${item?.physicalUnit?.generalName ?? '--'})` : ''
                  }`}</span>
                </Tooltip>
                <Tooltip title={!isNil(item?.value) ? item?.value.toFixed(2) : '--'}>
                  <span className={styles.itemcard_wrapper_top_value}>
                    {!isNil(item?.value) ? item?.value.toFixed(2) : '--'}
                  </span>
                </Tooltip>
              </div>
              <div className={styles.itemcard_wrapper_bottom}>
                <span className={styles.itemcard_wrapper_bottom_label} style={{ marginRight: 6 }}>
                  同比：
                  <Tooltip
                    title={`${
                      !isNil(item?.yoyRatio)
                        ? `${item?.yoyRatio > 0 ? `+${item?.yoyRatio.toFixed(2)}` : item?.yoyRatio.toFixed(2)}`
                        : '--'
                    }%`}
                  >
                    <span style={{ color: renderColor(item?.yoyRatio, item?.indicatorName) }}>{`${
                      !isNil(item?.yoyRatio)
                        ? `${item?.yoyRatio > 0 ? `+${item?.yoyRatio.toFixed(2)}` : item?.yoyRatio.toFixed(2)}`
                        : '--'
                    }%`}</span>
                  </Tooltip>
                </span>
                <span className={styles.itemcard_wrapper_bottom_label} style={{ textAlign: 'right' }}>
                  环比：
                  <Tooltip
                    title={`${
                      !isNil(item?.momRatio)
                        ? `${item?.momRatio > 0 ? `+${item?.momRatio.toFixed(2)}` : item?.momRatio.toFixed(2)}`
                        : '--'
                    }%`}
                  >
                    <span style={{ color: renderColor(item?.momRatio, item?.indicatorName) }}>{`${
                      !isNil(item?.momRatio)
                        ? `${item?.momRatio > 0 ? `+${item?.momRatio.toFixed(2)}` : item?.momRatio.toFixed(2)}`
                        : '--'
                    }%`}</span>
                  </Tooltip>
                </span>
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
};

export default CenterDataCompartion;
