import { CloseCircleFilled } from '@ant-design/icons';
import { ReactNode } from 'react';
import styles from './index.module.scss';
interface Iprops {
  children: ReactNode;
  bg?: string;
  borderBg?: string;
  onClose?: () => void;
  showClose?: boolean;
}
const ShiftTag: React.FC<Iprops> = ({ bg, borderBg, children, onClose, showClose = true }) => {
  return (
    <>
      <div className={styles.tag_box} style={{ backgroundColor: bg, borderColor: borderBg }}>
        <div className={styles.text}>{children}</div>
        {showClose && (
          <div className={styles.icon} onClick={() => onClose?.()}>
            <CloseCircleFilled />
          </div>
        )}
      </div>
    </>
  );
};
export default ShiftTag;
