import { FC, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Space, Form, Image, Cascader } from 'antd';
import {
  Wrapper,
  EllipsisSpan,
  Paging,
  usePaging,
  getRealUrl,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Checkbox,
  Input,
  Select,
  Radio,
  TreeSelect,
  Modal,
  Dropdown,
  CustomFilter,
  FieldTable,
} from '@maxtropy/components';

import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import {
  getDeviceList,
  Device,
  DeviceListRequest,
  updateDeviceStatus,
  exportDevice,
  OrganizationResponse,
  getOrganizationWithCodeWithCurrent,
} from '../../../api/device';
import { DeviceStatus, DeviceStatusDisplay, Operator, OperatorDisplay } from '@/shared/types';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { useHasPermission, useQuery } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';

import SelectRuleGroupModal from '../components/SelectRuleGroupModal';
import { formatTreeData } from '../CreatePropertyInfo';
import { DefaultOptionType } from 'antd/es/cascader';
import { getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeTree } from '@/api/deviceType';

type SearchParams = Omit<DeviceListRequest, 'page' | 'size'>;

// const routes = [
//   {name: 'Iot配置'},
//   {name: '设备管理'},
//   {name: '设备管理'},
// ];

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 260,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouNames',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备图片',
    dataIndex: 'pic',
    ellipsis: { showTitle: true },
    render: (v: string) =>
      v ? <Image width={50} wrapperClassName={styles.image} src={getRealUrl(v)} /> : <div style={{ height: 50 }} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
  {
    title: '第三方对接',
    dataIndex: 'thirdParty',
    ellipsis: { showTitle: true },
    render: (v: boolean) => {
      return <EllipsisSpan value={v ? '是' : '否'} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (value: string, record: Device) => {
      return (
        <EllipsisSpan
          value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
        />
      );
    },
  },
];

interface FilterParams {
  codeOrName: string | undefined;
  typeId: Array<Array<number>> | undefined;
  status?: DeviceStatus;
  invalid?: boolean;
  tag?: string;
  customerMcid?: string;
  thirdParty?: boolean;
}

const DeviceList: FC = () => {
  const [form] = Form.useForm();
  const [updateState, update] = useUpdate();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const hasStatusChange = useHasPermission(PermissionsType.BDEVICEUPDATESTATUS);
  const canBatchConfigAttribute = useHasPermission(PermissionsType.B_BATCHCONFIGATTRIBUTE);
  const canBatchConfigEdgeInfo = useHasPermission(PermissionsType.B_BATCHCONFIGEDGEINFO);
  const canExport = useHasPermission(PermissionsType.B_DEVICE_OUTPUT);
  const deviceTypeData = useAsync(getDeviceTypeTree);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const cacheStr = urlSearchParams.get('cacheParams');
  const cacheParams = cacheStr ? (JSON.parse(cacheStr) as SearchParams) : {};
  const wrapperRef = useRef(null);

  const [searchParams, setSearchParams] = useState<SearchParams>(cacheParams);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [selectRuleGroupDevice, setSelectRuleGroupDevice] = useState<Device>();
  const [selectThirdRuleGroupDevice, setSelectThirdRuleGroupDevice] = useState<Device>();
  const [isThirdRuleGroup, setIsThirdRuleGroup] = useState<boolean>(false);
  const [thirdRuleTypeId, setThirdRuleTypeId] = useState<number>();
  const [physicalModelId, setPhysicalModelId] = useState<number>(); // 物模型型号ID

  const [statusDevice, setStatusDevice] = useState<Device>();
  useEffect(() => {
    if (cacheParams) {
      form.setFieldsValue({
        ...cacheParams,
        typeId: cacheParams.originDeviceType,
      });
    }
  }, [cacheParams]);
  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDeviceList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list ?? [];
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 140,
      showForce: true,
      fixed: 'right' as const,
      render: (value: undefined, record: Device) => {
        return (
          <Space size={16}>
            <Dropdown
              type="link"
              disabled={record.status === DeviceStatus.INVALID}
              menu={{
                items: [
                  {
                    show: true,
                    key: '1',
                    label: (
                      <Link to={`/device/manage/device/${record.id}/edit`} target="_blank">
                        编辑资产信息
                      </Link>
                    ),
                  },
                  {
                    show: true,
                    key: '2',
                    label: (
                      <Link to={`/device/manage/device/${record.id}/attribute/edit`} target="_blank">{`${
                        record.existAttribute ? '编辑' : '添加'
                      }固定信息`}</Link>
                    ),
                  },
                  {
                    show: !record.thirdParty,
                    key: '3',
                    label: (
                      <Link
                        to={`/device/manage/device/${record.id}/dataMining/${
                          record.existEdgeTemplate ? 'edit' : 'new'
                        }`}
                        target="_blank"
                      >{`${record.existEdgeTemplate ? '编辑' : '添加'}数采信息`}</Link>
                    ),
                  },
                  {
                    show: true,
                    key: '4',
                    label: (
                      <span
                        onClick={() => {
                          record.physicalModelId !== null && setPhysicalModelId(record.physicalModelId);
                          if (record.thirdParty) {
                            setSelectThirdRuleGroupDevice(record);
                            setIsThirdRuleGroup(true);
                            setThirdRuleTypeId(record.typeId);
                            return;
                          }
                          if (record.existEdgeTemplate) {
                            setSelectRuleGroupDevice(record);
                          } else {
                            Modal.warning({
                              title: '请先添加设备的数采信息',
                            });
                          }
                        }}
                      >
                        {record.existRuleGroup ? '编辑' : '添加'}报警规则
                      </span>
                    ),
                  },
                ].filter(item => item.show),
              }}
            >
              编辑
            </Dropdown>
            {hasStatusChange && (
              <Button
                disabled={record.status === DeviceStatus.INVALID}
                type="link"
                onClick={() => {
                  setStatusDevice(record);
                }}
              >
                状态变更
              </Button>
            )}
            <Dropdown
              type="link"
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <Link
                        to={`/device/manage/device/${record.id}/detail?thirdParty=${!!record.thirdParty}`}
                        target="_blank"
                      >
                        查看设备信息
                      </Link>
                    ),
                  },
                  {
                    key: '2',
                    disabled: !record.existRuleGroup,
                    label: (
                      <Link target="_blank" to={`/device/rule/list/group/detail/${record.ruleGroupId}`}>
                        查看报警规则
                      </Link>
                    ),
                  },
                ],
              }}
            >
              查看
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onFinish = (val: FilterParams) => {
    const typeId = getChildNodesByParentIds(val.typeId, deviceTypeData?.tree);
    const params: SearchParams = {
      codeOrName: val.codeOrName,
      typeId: typeId,
      status: val.status,
      invalid: val.invalid ?? false,
      tag: val.tag,
      customerMcid: val.customerMcid,
      thirdParty: val.thirdParty,
    };
    let cacheParams = encodeURIComponent(
      JSON.stringify({
        ...params,
        originDeviceType: val.typeId,
      })
    );
    console.log('cacheParams', cacheParams);
    let newUrl = `${window.location.pathname}?cacheParams=${cacheParams}`;

    window.history.replaceState(null, document.title, newUrl);
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
    window.history.replaceState(null, document.title, window.location.pathname);
  };

  const onOk = () => {
    if (statusDevice) {
      updateDeviceStatus({
        deviceId: statusDevice.id,
        status: statusDevice.status,
      }).then(res => {
        if (res.flag) {
          setStatusDevice(undefined);
          update();
        }
      });
    }
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val)} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>
      <Form.Item name="customerMcid" label="所属组织">
        <TreeSelect treeData={treeData} placeholder="请选择" />
      </Form.Item>
      <Form.Item name="status" label="状态">
        <Select style={{ width: '100%' }} placeholder="请选择">
          <Select.Option value={DeviceStatus.USING}>{DeviceStatusDisplay[DeviceStatus.USING]}</Select.Option>
          <Select.Option value={DeviceStatus.REPAIRING}>{DeviceStatusDisplay[DeviceStatus.REPAIRING]}</Select.Option>
          <Select.Option value={DeviceStatus.DEACTIVATING}>
            {DeviceStatusDisplay[DeviceStatus.DEACTIVATING]}
          </Select.Option>
          <Select.Option value={DeviceStatus.CALLOUT}>{DeviceStatusDisplay[DeviceStatus.CALLOUT]}</Select.Option>
          <Select.Option value={DeviceStatus.SOLD}>{DeviceStatusDisplay[DeviceStatus.SOLD]}</Select.Option>
          <Select.Option value={DeviceStatus.SCRAPPED}>{DeviceStatusDisplay[DeviceStatus.SCRAPPED]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="typeId" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
      <Form.Item name="thirdParty" label="第三方对接">
        <Select>
          <Select.Option value={true}>是</Select.Option>
          <Select.Option value={false}>否</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="invalid" valuePropName="checked">
        <Checkbox>显示已作废设备</Checkbox>
      </Form.Item>
      <Form.Item name="tag" label="标签">
        <Input placeholder="请输入" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper className={styles.wrapper} routes={breadcrumbRoutes?.routes} filters={filters}>
      <div id="table-parent" ref={wrapperRef}>
        <div className={styles.operationArea}>
          <Space size={8}>
            <Button type="primary" icon={<PlusOutlined />}>
              <Link to="/device/manage/device/create">新建设备</Link>
            </Button>
            <Button type="primary">
              <Link to="/device/manage/device/daq-deployment">批量配置数采信息</Link>
            </Button>
            <Button type="primary">
              <Link to="/device/manage/device/rule-group/bind">批量配置报警规则</Link>
            </Button>
            {canBatchConfigEdgeInfo && (
              <Button type="primary">
                <Link to="/device/manage/device/edge/update">批量修改数采信息</Link>
              </Button>
            )}
            {canBatchConfigAttribute && (
              <Button type="primary">
                <Link to="/device/manage/device/attribute/batch">批量配置固定属性</Link>
              </Button>
            )}
            {canExport && (
              <Button type="primary" onClick={() => exportDevice(searchParams)}>
                导出
              </Button>
            )}
          </Space>
        </div>
        <FieldTable
          adjustField={true}
          adjustWidth={true}
          cacheKey={'device_List_table'}
          rowKey={'id'}
          // sticky
          sticky={{
            getContainer: () => {
              return document.querySelector('.central-menu-content') as HTMLElement;
            },
          }}
          scroll={{ x: 1900 }}
          loading={isLoading}
          dataSource={data}
          columns={buildColumns}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>

      <Modal
        className={styles.modal}
        open={!!statusDevice}
        size="large"
        title="状态变更"
        onOk={onOk}
        onCancel={() => {
          setStatusDevice(undefined);
        }}
      >
        <Radio.Group
          value={statusDevice?.status}
          onChange={e => {
            setStatusDevice({
              ...statusDevice!,
              status: e.target.value,
            });
          }}
        >
          <Radio value={DeviceStatus.USING}>{DeviceStatusDisplay[DeviceStatus.USING]}</Radio>
          <Radio value={DeviceStatus.REPAIRING}>{DeviceStatusDisplay[DeviceStatus.REPAIRING]}</Radio>
          <Radio value={DeviceStatus.DEACTIVATING}>{DeviceStatusDisplay[DeviceStatus.DEACTIVATING]}</Radio>
          <Radio value={DeviceStatus.CALLOUT}>{DeviceStatusDisplay[DeviceStatus.CALLOUT]}</Radio>
          <Radio value={DeviceStatus.SOLD}>{DeviceStatusDisplay[DeviceStatus.SOLD]}</Radio>
          <Radio value={DeviceStatus.SCRAPPED}>{DeviceStatusDisplay[DeviceStatus.SCRAPPED]}</Radio>
          <Radio value={DeviceStatus.INVALID}>
            {DeviceStatusDisplay[DeviceStatus.INVALID]}
            （历史数据保留，一旦作废，不允许变更状态和编辑）
          </Radio>
        </Radio.Group>
      </Modal>
      <SelectRuleGroupModal
        visible={selectRuleGroupDevice !== undefined || selectThirdRuleGroupDevice !== undefined}
        device={selectRuleGroupDevice || selectThirdRuleGroupDevice}
        thirdRuleTypeId={thirdRuleTypeId}
        updateFn={update}
        isThirdRuleGroup={isThirdRuleGroup}
        physicalModelId={physicalModelId}
        onClose={() => {
          setSelectRuleGroupDevice(undefined);
          setSelectThirdRuleGroupDevice(undefined);
        }}
      />
    </Wrapper>
  );
};

export default DeviceList;
