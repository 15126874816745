import React, { FC } from 'react';
import { Space } from 'antd';
import { SubContent, Form, BaseInfoContent, ShowInput } from '@maxtropy/components';
import { Link } from 'react-router-dom';
import {
  BaseElectricityBillResponse,
  ElectricityIndustryCategoryEnum,
  ElectricityIndustryCategoryEnumDisplay,
} from '../../../../api/electricity';

export interface BaseEleBillProps {
  data?: BaseElectricityBillResponse;
}

const BaseInfo: FC<BaseEleBillProps> = ({ data }) => {
  return (
    <>
      <SubContent title="基础信息">
        <BaseInfoContent>
          <Form.Item label="电费核查联编号">
            <ShowInput value={data?.billNo} />
          </Form.Item>

          <Form.Item label="行业分类">
            <ShowInput
              value={
                ElectricityIndustryCategoryEnumDisplay[
                  Number(data?.industryCategory) as ElectricityIndustryCategoryEnum
                ]
              }
            />
          </Form.Item>
        </BaseInfoContent>
      </SubContent>

      <SubContent title="用户信息">
        <BaseInfoContent>
          <Form.Item label="客户编号">
            <Space size={8}>
              <ShowInput value={data?.saleClientCode} />
              <Link to={`/operation/client-related/client/detail/${data?.saleClientId}`}>查看客户详情</Link>
            </Space>
          </Form.Item>

          <Form.Item label="客户名称">
            <ShowInput value={data?.saleClientName} />
          </Form.Item>

          <Form.Item label="合同编号">
            <Space size={8}>
              <ShowInput value={data?.electricityContractCode} />
              <Link to={`/operation/salesContract/electricity/detail/${data?.saleElectricityContractId}`}>
                查看合同详情
              </Link>
            </Space>
          </Form.Item>
        </BaseInfoContent>
      </SubContent>
    </>
  );
};

export default BaseInfo;
