import {
  DataType,
  EnergyMediumTreeFormatType,
  MediumIndicatorStatisticsReq,
  MediumIndicatorStatisticsRes,
} from '@/api/energyMedium';
import { Key } from 'react';
import {
  getAbsoluteHumidityChart,
  getAccumulatedHeatChart,
  getAccumulatedMassFlowChart,
  getAccumulatedVolumetricFlowRateChart,
  getDensityChart,
  getElectricityChart,
  getInstantaneousMassFlowRateChart,
  getInstantaneousVolumetricFlowRateChart,
  getLevelChart,
  getPowerChart,
  getPressureChart,
  getProcessChart,
  getPurityChart,
  getRelativeHumidityChart,
  getTemperatureChart,
} from './components/Chart/chartOptions';
import { DateBtn, StatisticsPartition } from './components/DateSwitch';
import { average, max, min, sum } from './utils';
import { V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse } from '@maxtropy/device-customer-apis-v2';

export interface ParamsOption {
  chartData: MediumIndicatorStatisticsRes & { energyMediumName?: string };
  query: MediumIndicatorStatisticsReq;
  unit?: string;
}

export interface CardData {
  energyMediumName?: string; // 能源介质名称
  loading?: boolean; // 加载中
  indicatorId?: number; // 指标id
  energyMediumId?: number; // 能源介质id
  cardName?: string;
  key?: string | number;
  unit?: string;
  value?: number;
  sceneId?: number;
  type?: EnergyMediumTreeFormatType;
  chartData?: MediumIndicatorStatisticsRes;
}

export interface energyMediumBtn {
  id?: DataType;
  btnName?: string;
  unit?: string;
  name?: any | any[]; // tab名称
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
  getChartOption?: (value: ParamsOption) => any;
  cardData?: CardData[];
  key?: Key;
  type?: EnergyMediumTreeFormatType;
  processEntryOrExitId?: Key; //过程输入/输出id
  processId?: Key; // 过程id
  indicatorId?: Key; //指标id
  timeResolution?: StatisticsPartition; //时间颗粒度
  from?: number; //开始时间（毫秒时间戳）
  to?: number; //结束时间（毫秒时间戳）
}

export const calculateSUMCardData = ({ chartData = {}, query }: ParamsOption) => {
  if (chartData.data && chartData.data.length > 0) {
    const arr = chartData.data?.map(item => item.sum);
    return {
      total: sum(arr),
    };
  }
};

export const getCardData = ({ chartData, query }: ParamsOption) => {
  switch (query.indicatorId) {
    case DataType.AVFR:
    case DataType.AMFR:
    case DataType.E:
    case DataType.AHEAT:
      return calculateSUMCardData({ chartData, query });
    case DataType.IMFR:
    case DataType.IVFR:
    case DataType.T:
    case DataType.D:
    case DataType.PA:
    case DataType.RH:
    case DataType.AH:
    case DataType.POWER:
    case DataType.LEVEL:
    case DataType.PURITY: {
      if (query.timeResolution === StatisticsPartition.DAY) {
        // 箱型图
        const maxlist = chartData.data?.map(item => item.max);
        const avglist = chartData.data?.map(item => item.avg);
        const minlist = chartData.data?.map(item => item.min);
        const maxValue = max(maxlist);
        const minValue = min(minlist);
        const avg = average(avglist);
        return {
          max: maxValue,
          min: minValue,
          avg: avg,
        };
      } else {
        // 平均 最大, 最小
        const list = chartData.data?.map(item => item.avg);
        const maxValue = max(list);
        const minValue = min(list);
        const avg = average(list);
        return {
          max: maxValue,
          min: minValue,
          avg: avg,
        };
      }
    }
    default:
      return {};
  }
};

// 每个指标的配置项
export const energyMediumBtns = (
  MediumIndicatorDisplayUnit?: V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse['list']
): energyMediumBtn[] => {
  return [
    {
      id: DataType.AVFR,
      btnName: '累积体积流量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AVFR)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
        { aggrby: StatisticsPartition.MONTH },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getAccumulatedVolumetricFlowRateChart,
      cardData: [
        {
          cardName: '累积流量',
          key: 'total',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AVFR)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.AMFR,
      btnName: '累积质量流量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AMFR)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
        { aggrby: StatisticsPartition.MONTH },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getAccumulatedMassFlowChart,
      cardData: [
        {
          cardName: '累积流量',
          key: 'total',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AMFR)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.IVFR,
      btnName: '瞬时体积流量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IVFR)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getInstantaneousVolumetricFlowRateChart,
      cardData: [
        {
          cardName: '最大瞬时流量',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IVFR)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最小瞬时流量',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IVFR)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均瞬时流量',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IVFR)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.IMFR,
      btnName: '瞬时质量流量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IMFR)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getInstantaneousMassFlowRateChart,
      cardData: [
        {
          cardName: '最大瞬时流量',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IMFR)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最小瞬时流量',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IMFR)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均瞬时流量',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.IMFR)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.PA,
      btnName: '压力',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PA)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getPressureChart,
      cardData: [
        {
          cardName: '最大压力',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PA)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最小压力',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PA)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.T,
      btnName: '温度',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.T)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [{ aggrby: StatisticsPartition.MINUTE_1 }, { aggrby: StatisticsPartition.MINUTE_15 }],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getTemperatureChart,
      cardData: [
        {
          cardName: '最高温度',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.T)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最低温度',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.T)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均温度',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.T)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.AHEAT,
      btnName: '累积热量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AHEAT)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
        { aggrby: StatisticsPartition.MONTH },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getAccumulatedHeatChart,
      cardData: [
        {
          cardName: '累积热量',
          key: 'total',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AHEAT)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.D,
      btnName: '密度',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.D)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [{ aggrby: StatisticsPartition.MINUTE_1 }, { aggrby: StatisticsPartition.MINUTE_15 }],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getDensityChart,
      cardData: [
        {
          cardName: '最大密度',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.D)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最小密度',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.D)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.E,
      btnName: '电量',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.E)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
        { aggrby: StatisticsPartition.MONTH },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getElectricityChart,
      cardData: [
        {
          cardName: '累计用电量',
          key: 'total',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.E)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.POWER,
      btnName: '功率',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.POWER)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getPowerChart,
      cardData: [
        {
          cardName: '最大功率',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.POWER)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最小功率',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.POWER)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.AH,
      btnName: '绝对湿度',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AH)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getAbsoluteHumidityChart,
      cardData: [
        {
          cardName: '最高湿度',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AH)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最低湿度',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AH)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均湿度',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.AH)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.RH,
      btnName: '相对湿度',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.RH)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getRelativeHumidityChart,
      cardData: [
        {
          cardName: '最高湿度',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.RH)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最低湿度',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.RH)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均湿度',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.RH)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.LEVEL,
      btnName: '液位',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.LEVEL)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getLevelChart,
      cardData: [
        {
          cardName: '最高液位',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.LEVEL)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最低液位',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.LEVEL)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均液位',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.LEVEL)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      id: DataType.PURITY,
      btnName: '纯度',
      unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PURITY)
        ?.displayPhysicalUnitGeneralName,
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getPurityChart,
      cardData: [
        {
          cardName: '最高纯度',
          key: 'max',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PURITY)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '最低纯度',
          key: 'min',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PURITY)
            ?.displayPhysicalUnitGeneralName,
        },
        {
          cardName: '平均纯度',
          key: 'avg',
          unit: MediumIndicatorDisplayUnit?.find(i => i.energyMediumIndicatorId === DataType.PURITY)
            ?.displayPhysicalUnitGeneralName,
        },
      ],
    },
    {
      name: '过程',
      btnName: '过程',
      dateBtn: [
        { aggrby: StatisticsPartition.MINUTE_1 },
        { aggrby: StatisticsPartition.MINUTE_15 },
        { aggrby: StatisticsPartition.DAY },
        { aggrby: StatisticsPartition.MONTH },
      ],
      defaultSelectBtn: { aggrby: StatisticsPartition.MINUTE_15 },
      getChartOption: getProcessChart,
      cardData: [],
    },
  ];
};
