import React, { useState, useEffect } from 'react';
import { DatePicker } from '@maxtropy/components';
import { PickerMode } from 'rc-picker/es/interface';
import dayjs, { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import styles from './index.module.scss';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export type RangePickerValue = RangePickerProps<dayjs.Dayjs>['value'];

export enum DatePickerType {
  DAY = 'date',
  MONTH = 'month',
  YEAR = 'year',
}

export enum OriginalType {
  ORIGINAL = 'original',
  ORIGINALENUM = 'originalenum',
}

export type StatisticsPartition = DatePickerType | OriginalType;

export const partitionToPicker: { [K: string]: PickerMode } = {
  [DatePickerType.DAY]: 'date',
  [DatePickerType.MONTH]: 'month',
  [DatePickerType.YEAR]: 'year',
  [OriginalType.ORIGINAL]: 'date',
  [OriginalType.ORIGINALENUM]: 'date',
};

export interface DateSwitchProps {
  mode: StatisticsPartition;
  rangePickerValue: RangePickerValue | any;
  handleRangePickerChange: (dates: RangePickerValue, dateStrings: [string, string]) => void;
}

interface DateLimitType {
  name: StatisticsPartition;
  unitOfTime: QUnitType | OpUnitType;
  num: number;
}

const dateLimit: DateLimitType[] = [
  {
    name: DatePickerType.DAY,
    unitOfTime: 'days',
    num: 30,
  },
  {
    name: DatePickerType.MONTH,
    unitOfTime: 'months',
    num: 11,
  },
  {
    name: DatePickerType.YEAR,
    unitOfTime: 'years',
    num: 9,
  },
  {
    name: OriginalType.ORIGINAL,
    unitOfTime: 'days',
    num: 1,
  },
  {
    name: OriginalType.ORIGINALENUM,
    unitOfTime: 'days',
    num: 31,
  },
];

export const getTimeDistance = (mode: StatisticsPartition): RangePickerValue => {
  switch (mode) {
    case DatePickerType.DAY:
      return [dayjs().subtract(6, 'day'), dayjs()];
    case DatePickerType.MONTH:
      return [dayjs().subtract(5, 'month'), dayjs()];
    case DatePickerType.YEAR:
      return [dayjs().subtract(4, 'year'), dayjs()];
    case OriginalType.ORIGINAL:
      return [dayjs(), dayjs()];
    case OriginalType.ORIGINALENUM:
      return [dayjs(), dayjs()];
  }
};

export const getTsRange = (date: RangePickerValue, mode: StatisticsPartition) => {
  if (!date) {
    return {};
  } else {
    switch (mode) {
      case OriginalType.ORIGINAL:
      case OriginalType.ORIGINALENUM:
        let tsEnd;
        if (dayjs().isSame(dayjs(date[0]), 'day')) {
          tsEnd = dayjs().valueOf();
        } else {
          tsEnd = dayjs(date[1]).endOf('day').valueOf();
        }
        return {
          tsStart: dayjs(date[0]).startOf('day').valueOf(),
          tsEnd,
        };
      case DatePickerType.DAY:
        return {
          tsStart: dayjs(date[0]).startOf('day').valueOf(),
          tsEnd: dayjs(date[1]).endOf('day').valueOf(),
        };
      case DatePickerType.MONTH:
        return {
          tsStart: dayjs(date[0]).startOf('month').valueOf(),
          tsEnd: dayjs(date[1]).endOf('month').valueOf(),
        };
      case DatePickerType.YEAR:
        return {
          tsStart: dayjs(date[0]).startOf('year').valueOf(),
          tsEnd: dayjs(date[1]).endOf('year').valueOf(),
        };
      default:
        return {};
    }
  }
};

export const getColumnConfig = (mode: StatisticsPartition) => {
  switch (mode) {
    case DatePickerType.DAY:
      return {
        name: '日期',
        dateFormat: 'YYYY-MM-DD',
      };
    case DatePickerType.MONTH:
      return {
        name: '月份',
        dateFormat: 'YYYY-MM',
      };
    case DatePickerType.YEAR:
      return {
        name: '年份',
        dateFormat: 'YYYY',
      };
    case OriginalType.ORIGINAL:
    case OriginalType.ORIGINALENUM:
      return {
        name: '日期',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
      };
    default:
      return {};
  }
};

const DateSwitch: React.FC<DateSwitchProps> = props => {
  const { mode, rangePickerValue, handleRangePickerChange } = props;

  const [dates, setDates] = useState<RangeValue | [] | undefined>();

  useEffect(() => {
    setDates(rangePickerValue);
  }, [rangePickerValue]);

  const disabledDate = (current: Dayjs) => {
    const limitEnd = current && current >= dayjs().endOf('day');
    if (!dates || dates.length === 0) {
      return limitEnd;
    }
    const disabledRanges = dateLimit.find(item => item.name === mode) as DateLimitType;
    const { num, unitOfTime } = disabledRanges;
    const tooLate = dates[0] && current.diff(dates[0], unitOfTime) >= num;
    const tooEarly = dates[1] && dates[1].diff(current, unitOfTime) >= num;
    return !!tooLate || !!tooEarly || limitEnd;
  };
  const originDataDisabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([]);
    }
  };

  return (
    <div className={styles.pickerArea}>
      <div className={styles.label}>统计时间</div>

      {mode === OriginalType.ORIGINAL || mode === OriginalType.ORIGINALENUM ? (
        <DatePicker
          style={{ width: 150 }}
          allowClear={false}
          onChange={(date, dateString) => {
            handleRangePickerChange([date, date], [dateString as string, dateString as string]);
          }}
          value={rangePickerValue?.[1]}
          disabledDate={originDataDisabledDate}
        />
      ) : (
        <RangePicker
          style={{ width: 294 }}
          allowClear={false}
          picker={partitionToPicker[mode]}
          onChange={handleRangePickerChange}
          onCalendarChange={val => {
            setDates([val[0], null]);
          }}
          onOpenChange={onOpenChange}
          value={rangePickerValue}
          disabledDate={disabledDate}
        />
      )}
    </div>
  );
};

export default DateSwitch;
