import { MenuOutlined } from '@ant-design/icons';
import React, { Ref, useImperativeHandle } from 'react';
import { useEffect, useState } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './index.module.scss';
import { ActionFormValues } from '@/pages/ProductionBeatConfig/utils';

interface SortModalListProps {
  tabData?: ActionFormValues[];
}

export interface SortModalListModalRef {
  tabDataList: ActionFormValues[];
}

const SortModalList = React.forwardRef((props: SortModalListProps, ref: Ref<SortModalListModalRef>) => {
  const { tabData } = props;
  const [tabDataList, setTabDataList] = useState<ActionFormValues[]>([]);

  const SortableItem = SortableElement<{ value: string; sortIndex: number }>(
    ({ value }: { value: string; sortIndex: number }) => (
      <li className={styles.liStyle}>
        <MenuOutlined style={{ marginRight: '8px' }} />
        <span>{value}</span>
      </li>
    )
  );

  useEffect(() => {
    if (tabData) {
      setTabDataList(tabData);
    }
  }, [tabData]);

  const SortableList = SortableContainer<{ items: ActionFormValues[] }>(({ items }: { items: ActionFormValues[] }) => {
    return (
      <ul style={{ padding: 0, cursor: 'move' }}>
        {items.map((item, index) => (
          <SortableItem key={`item-${item.id}`} sortIndex={index} index={index} value={item.actionName} />
        ))}
      </ul>
    );
  });

  // 排序结束时调用的回调
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    let newData = arrayMove(tabDataList, oldIndex, newIndex);
    setTabDataList(newData);
  };

  useImperativeHandle(ref, () => ({ tabDataList }));

  return <SortableList items={tabDataList} onSortEnd={onSortEnd} helperClass={styles.helperClass} />;
});

export default SortModalList;
