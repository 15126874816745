export enum AlarmType {
  // 禁用
  Disable = 0,
  // 启用
  Enable = 1,
}

export const AlarmTypeDisplay = {
  [AlarmType.Disable]: '禁用',
  [AlarmType.Enable]: '启用',
};

export enum AlarmLevel {
  // 最高级
  Highest = 0,
  // 高级
  High = 1,
  // 中级
  Medium = 2,
  // 低级
  Low = 3,
  // 预警
  Warning = 4,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.Highest]: '最高级',
  [AlarmLevel.High]: '高级',
  [AlarmLevel.Medium]: '中级',
  [AlarmLevel.Low]: '低级',
  [AlarmLevel.Warning]: '最低级',
};

export enum RuleLogic {
  // 逆变器离散率
  InverterDiscreteRate = 1,
}
export const RuleLogicDisplay = {
  [RuleLogic.InverterDiscreteRate]: '逆变器离散率',
};

//枚举备注: 1 :小于5% 2 :大于等于5%，小于10% 3 :大于等于10%小于20% 4 :大于等于20%

export enum DiscreteRateInterval {
  // 未发电
  UNWORK = 0,
  // 小于5%
  LessThanFive = 1,
  // 大于等于5%，小于10%
  FiveToTen = 2,
  // 大于等于10%小于20%
  TenToTwenty = 3,
  // 大于等于20%
  MoreThanTwenty = 4,
}

export const DiscreteRateIntervalDisplay = {
  [DiscreteRateInterval.UNWORK]: '未发电',
  [DiscreteRateInterval.LessThanFive]: '小于5%',
  [DiscreteRateInterval.FiveToTen]: '5%~10%',
  [DiscreteRateInterval.TenToTwenty]: '10%~20%',
  [DiscreteRateInterval.MoreThanTwenty]: '20%及以上',
};

export interface RuleListProps {
  /**
   * id
   */
  id?: number;
  /**
   * 报警规则名称
   */
  name?: string;
  /**
   * 离散率
   */
  rate?: number;
  /**
   * 启用状态
   */
  status?: number;
  /**
   * 运营单元id
   */
  ouId?: number;
  /**
   * 报警类型
   */
  alarmType?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  /**
   * 场景类型
   */
  sceneType?: 1 | 2 | 3 | 4;
  /**
   * 规则逻辑
   */
  ruleLogic?: 1;
  /**
   * 报警编号
   */
  code?: string;
  /**
   * 光伏站id
   */
  pvId?: number;
  /**
   * 离散率区间
   */
  discreteRateInterval?: 1 | 2 | 3 | 4;
  /**
   * 逆变器数量
   */
  inverterNum?: number;
  /**
   * 片区id
   */
  pvAreaId?: number;
  /**
   * 创建人信息
   */
  createByUserId?: string;
  /**
   * 记录时间
   */
  recordDate?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 更新人信息
   */
  updateByUserId?: string;
  /**
   * 创建时间
   */
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 修改时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
}
