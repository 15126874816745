import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Space, Spin, TreeSelect } from 'antd';
import styles from './compareModal.module.scss';
import { Button, DatePicker, Empty, Form, Modal, Radio, Select } from '@maxtropy/components';
import { UetEMTAllEnergyMediumListResponse, getUetEMTAllEnergyMediumList } from '@/api/uet';
import {
  BoardChartData,
  BoardChartDataQuery,
  ConsumptionAttribute,
  TimeGranularity,
  TimeGranularityDisplay,
  getWorkCenterTree,
  ConsumptionAttributeUnitDisplay,
  TimeGranularityRequest,
  TimeGranularityInitialValue,
} from '@/api/productionConsumptionBoard';
import dayjs, { Dayjs } from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { TimeGranularityFormat, chartDefaultOptions } from './chartConfigs';

// Register the required components
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
]);

type CompareModalProps = {
  open: boolean;
  closeModal: () => void;
  // 外部已选择的节点的 key
  selectedConsumptionNode?: React.Key;
  // 外部已选择的节点的 name
  selectedConsumptionNodeName?: string;
  // 外部已选择的综合能耗/工质
  selectedConsumption?: number;
  // 外部已选择的综合能耗下的发热量, 标准煤还是累计用量
  selectedMonitorType?: ConsumptionAttribute;
};

// 综合能耗 id 为 1, 类似比如工质, 电是 2001, 新水是 2003(虚拟)
const GENERAL_CONSUMPTION = 1;

type TreeDataNode = {
  value: React.Key;
  title: React.Key;
  children?: TreeDataNode[];
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

type FormValues = {
  // 节点
  consumptionNodeBottom: {
    disabled?: boolean;
    halfChecked?: boolean;
    label?: string;
    value?: React.Key;
  };
  consumptionNodeTop: {
    disabled?: boolean;
    halfChecked?: boolean;
    label?: string;
    value?: React.Key;
  };
  // 能耗类型, 工质还是综合能耗
  consumptionTypeBottom?: number;
  consumptionTypeTop?: number;
  // 监控指标, 综合能耗-发热量/标准煤, 工质-用量
  monitorTypeBottom?: ConsumptionAttribute;
  monitorTypeTop?: ConsumptionAttribute;
};

export default function CompareModal({
  open,
  closeModal,
  selectedConsumptionNode,
  selectedConsumptionNodeName,
  selectedConsumption,
  selectedMonitorType,
}: CompareModalProps) {
  console.log('selectedConsumption', selectedConsumption);
  console.log('selectedMonitorType', selectedMonitorType);
  const echartsTheme = useEchartsTheme();
  const [form] = Form.useForm<FormValues>();
  // 监听顶部能耗类型
  const consumptionTypeTop = Form.useWatch<number>('consumptionTypeTop', form);
  // 监听顶部监控指标
  const monitorTypeTop = Form.useWatch<ConsumptionAttribute>('monitorTypeTop', form);
  // 获取所有能源介质, 不包含场景, 例如新水, 包含电能
  const [mediumList, setMediumList] = useState<UetEMTAllEnergyMediumListResponse[]>([]);
  const consumptionTypes = useMemo(
    () => [{ id: GENERAL_CONSUMPTION, energyName: '综合能耗' }, ...mediumList],
    [mediumList]
  );
  const monitorTypeOptions = useMemo(() => {
    if (!consumptionTypeTop) {
      return [];
    }

    if (consumptionTypeTop === GENERAL_CONSUMPTION) {
      return [
        { label: '综合能耗-发热量', value: ConsumptionAttribute.HEAT },
        { label: '综合能耗-标准煤', value: ConsumptionAttribute.COAL },
      ];
    } else {
      return [{ label: '用量', value: ConsumptionAttribute.MEDIUM }];
    }
  }, [consumptionTypeTop]);
  const [tree, setTree] = useState<TreeDataNode[]>([]);
  // 时间颗粒度, 15 分钟/天/月
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>(TimeGranularity.FIFTEEN_MINUTES);
  // 时间范围, dates 为临时时间, value 为用户选择的状态
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  // 图表数据
  const [chartDataTop, setChartDataTop] = useState<BoardChartData[]>([]);
  const [chartDataBottom, setChartDataBottom] = useState<BoardChartData[]>([]);
  // 单位(共享)
  const [generalName, setGeneralName] = useState<string>('');
  const [loading, setLoading] = useState(false);
  // 图表搜索条件
  const [searchParams, setSearchParams] = useState<FormValues>();
  // modal

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    let tooLate = null;
    let tooEarly = null;
    const afterToday = current && current > dayjs().endOf('day');
    // 月颗粒度下最多选择 36 月
    if (TimeGranularity.MONTH === timeGranularity) {
      tooLate = dates[0] && current.diff(dates[0], 'months') >= 35;
      tooEarly = dates[1] && dates[1].diff(current, 'months') >= 35;
    } else {
      // 15 分钟颗粒度下最多选择 7 天, 日颗粒度下最多选择 31 天
      const limitDays = timeGranularity === TimeGranularity.FIFTEEN_MINUTES ? 7 : 31;
      tooLate = dates[0] && current.diff(dates[0], 'days') >= limitDays;
      tooEarly = dates[1] && dates[1].diff(current, 'days') >= limitDays;
    }
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  const setFormInitialValues = useCallback(() => {
    form.setFieldsValue({
      consumptionTypeTop: selectedConsumption,
      monitorTypeTop: selectedMonitorType,
      // 因为设置成 labelInValue 形式, form 里的数据为这样
      consumptionNodeTop: {
        disabled: undefined,
        halfChecked: undefined,
        label: selectedConsumptionNodeName,
        value: selectedConsumptionNode,
      },

      consumptionTypeBottom: selectedConsumption,
      monitorTypeBottom: selectedMonitorType,
    });
  }, [form, selectedConsumption, selectedConsumptionNode, selectedConsumptionNodeName, selectedMonitorType]);

  const options = useMemo(() => {
    return {
      ...chartDefaultOptions,
      grid: {
        // left: 80,
        right: 40,
      },
      xAxis: {
        min: 'dataMin',
        max: 'dataMax',
        type: 'time',
        axisLabel: {
          formatter: (v: number) => {
            return dayjs(v).format(TimeGranularityFormat[timeGranularity]);
          },
        },
      },
      tooltip: {
        ...chartDefaultOptions.tooltip,
        valueFormatter: (value: number | string) => {
          return `${Number(value).toFixed(2) ?? '--'} ${
            consumptionTypeTop === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[monitorTypeTop] : generalName
          }`;
        },
      },
      yAxis: [
        {
          type: 'value',
          // y 轴显示单位
          name:
            searchParams?.consumptionTypeTop === GENERAL_CONSUMPTION
              ? ConsumptionAttributeUnitDisplay[searchParams?.monitorTypeTop!]
              : generalName,
          position: 'left',
          // 和右边的 y 轴对齐
          alignTicks: true,
          axisLine: {
            show: true,
          },
        },
      ],
      series: [
        {
          name: searchParams?.consumptionNodeTop?.label,
          type: 'bar',
          data: chartDataTop?.map(d => [d.ts, d.value]),
        },
        {
          name: searchParams?.consumptionNodeBottom?.label,
          type: 'bar',
          data: chartDataBottom?.map(d => [d.ts, d.value]),
        },
      ],
    };
  }, [chartDataBottom, chartDataTop, consumptionTypeTop, generalName, monitorTypeTop, searchParams, timeGranularity]);

  const onFinish = (values: FormValues) => {
    console.log('onfinish', values);
    setSearchParams(values);
  };

  // 获取所有工质列表, 包含电能
  useEffect(() => {
    getUetEMTAllEnergyMediumList().then(res => {
      setMediumList(res);
    });
  }, []);

  // 获取所有工作中心, 工站, 工序和用能单元
  useEffect(() => {
    getWorkCenterTree().then(res => {
      const formattedTree: TreeDataNode[] = res.map(wc => {
        return {
          title: wc.name,
          value: `workCenter-${wc.id}`,
          display: wc.name,
          children: wc?.procedureTree?.map(wp => ({
            title: wp.procedureName,
            value: `workProcedure-${wp.procedureId}-${wp.centerProcedureLinkId}-${wp.workCenterId}`,
            display: `${wc.name}-${wp.procedureName}`,
            children: wp?.stationTree?.map(ws => ({
              title: ws.stationName,
              value: `workStation-${ws.stationId}-${ws.procedureStationLinkId}-${ws.centerProcedureLinkId}-${ws.workCenterId}`,
              display: `${wc.name}-${wp.procedureName}-${ws.stationName}`,
              children: ws?.unitTree?.map(ut => ({
                title: ut.energyUnitName,
                value: `energyUnit-${ut.energyUnitId}-${ut.procedureStationLinkId}-${ut.centerProcedureLinkId}-${ut.workCenterId}`,
                display: `${wc.name}-${wp.procedureName}-${ws.stationName}-${ut.energyUnitName}`,
              })),
            })),
          })),
        };
      });

      setTree(formattedTree);
    });
  }, []);

  useEffect(() => {
    // 设置外部的选中的节点和工质, 属性设置能耗类型, 监控指标和能耗节点
    if (selectedConsumptionNode && tree.length && mediumList.length) {
      // 当存在 tree 下拉列表和工质下拉列表以及外部有选中某个节点的时候才设置初始值
      setFormInitialValues();
    }
  }, [mediumList, selectedConsumptionNode, setFormInitialValues, tree]);

  // 设置默认时间范围, 15 分钟的为当天, 日颗粒度是 30 天, 月颗粒度是 12 个月
  useEffect(() => {
    setValue(TimeGranularityInitialValue[timeGranularity]);
  }, [timeGranularity]);

  const getQuery = useCallback(
    (searchParams: FormValues, isTop: boolean = true) => {
      const key = isTop
        ? (searchParams?.consumptionNodeTop?.value as string)
        : (searchParams?.consumptionNodeBottom?.value as string);
      const consumptionType = isTop ? searchParams?.consumptionTypeTop : searchParams?.consumptionTypeBottom;

      const query: BoardChartDataQuery = {
        energyUnitId: key?.includes('energyUnit') ? key?.split('-')[1] : undefined,
        // 工序工站关联id
        procedureStationLinkId:
          key.includes('workStation') || key.includes('energyUnit') ? key.split('-').at(-3) : undefined,
        // 工作中心和工序关联id
        centerProcedureLinkId: !key.includes('workCenter') ? key.split('-').at(-2) : undefined,
        // 必传工作中心 id
        workCenterId: key.split('-').at(-1)!,
        // 如果是选择的综合能耗, 那么 energyMediumId 传发热量(200)或者标准煤(100), 否则就传工质 id
        energyMediumId: consumptionType !== GENERAL_CONSUMPTION ? consumptionType : searchParams.monitorTypeTop,
        isTotal: consumptionType === GENERAL_CONSUMPTION,
        startDate: value?.[0]?.format('YYYY-MM-DD')!,
        endDate: value?.[1]?.format('YYYY-MM-DD')!,
      };

      return query;
    },
    [value]
  );

  // 获取图表数据
  useEffect(() => {
    if (searchParams?.consumptionNodeTop && searchParams.consumptionNodeBottom && value?.[0] && value?.[1]) {
      setLoading(true);
      const topQuery = getQuery(searchParams);
      const bottomQuery = getQuery(searchParams, false);

      Promise.allSettled([
        TimeGranularityRequest[timeGranularity](topQuery).onError(err => {
          Modal?.error({
            title: err.cause.errorMessage ?? '未知错误！ 请联系管理员。',
          });
          setChartDataTop([]);
          throw err;
        }),
        TimeGranularityRequest[timeGranularity](bottomQuery).onError(err => {
          Modal?.error({
            title: err.cause.errorMessage ?? '未知错误！ 请联系管理员。',
          });
          setChartDataBottom([]);
          throw err;
        }),
      ]).then(results => {
        const [rTop, rBottom] = results;
        console.log('promise all', results);
        if (rTop.status === 'fulfilled') {
          setChartDataTop(rTop.value.data ?? []);
          setGeneralName(rTop.value.generalName ?? '');
        }

        if (rBottom.status === 'fulfilled') {
          setChartDataBottom(rBottom.value.data ?? []);
          setGeneralName(rBottom.value.generalName ?? '');
        }
        setLoading(false);
      });
    } else {
      // 数据重置
      setLoading(false);
      setChartDataTop([]);
      setChartDataBottom([]);
    }
    // 这里原则上只需要依赖 value, 虽然也依赖了 timeGranularity,
    // 但是当 timeGranularity 变化时 value 也变化, 会导致发两次请求, 因此只要依赖 value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuery, searchParams, value]);

  return (
    <Modal open={open} width={1400} maskClosable={false} title="数据对比" onCancel={() => closeModal()} footer={null}>
      <div className={styles.compareModalBody}>
        <div className={styles.leftCol}>
          <Form form={form} onFinish={onFinish} labelCol={{ style: { width: 85 } }}>
            <div className={styles.compareSection}>
              <Form.Item
                name="consumptionTypeTop"
                label="能耗类型"
                rules={[{ required: true, message: '请选择能耗类型' }]}
              >
                <Select
                  placeholder="请选择能耗类型"
                  options={consumptionTypes.map(ct => ({
                    label: ct.energyName,
                    value: ct.id,
                  }))}
                  onChange={(value: number) => {
                    if (value === GENERAL_CONSUMPTION && consumptionTypeTop !== GENERAL_CONSUMPTION) {
                      // 如果能耗类型从工质切换到综合能耗, 监控指标设置为 发热量
                      form.setFieldValue('monitorTypeTop', ConsumptionAttribute.HEAT);
                      form.setFieldValue('monitorTypeBottom', ConsumptionAttribute.HEAT);
                    } else {
                      // 如果能耗类型从综合能耗切换到工质, 监控指标设置为 累计用量
                      form.setFieldValue('monitorTypeTop', ConsumptionAttribute.MEDIUM);
                      form.setFieldValue('monitorTypeBottom', ConsumptionAttribute.MEDIUM);
                    }
                    form.setFieldValue('consumptionTypeBottom', value);
                  }}
                />
              </Form.Item>
              <Form.Item name="monitorTypeTop" label="监控指标" rules={[{ required: true, message: '请选择监控指标' }]}>
                <Select
                  placeholder="请选择监控指标"
                  options={monitorTypeOptions}
                  onChange={value => {
                    form.setFieldValue('monitorTypeBottom', value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="consumptionNodeTop"
                label="能耗节点"
                rules={[{ required: true, message: '请选择能耗节点' }]}
              >
                <TreeSelect
                  // 为了获取 label 的值, 表单获取到的值为:
                  // consumptionNodeTop: { label: xx-yy-aa, value: key }
                  labelInValue
                  treeNodeLabelProp="display"
                  treeNodeFilterProp="title"
                  allowClear
                  showSearch
                  placeholder="请选择能耗节点"
                  treeData={tree}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeDefaultExpandAll
                />
              </Form.Item>
            </div>

            <Divider dashed>
              VS <i className={styles.circle} />
            </Divider>

            <div className={styles.compareSection}>
              <Form.Item name="consumptionTypeBottom" label="能耗类型">
                <Select
                  options={consumptionTypes.map(ct => ({
                    label: ct.energyName,
                    value: ct.id,
                  }))}
                  disabled
                />
              </Form.Item>
              <Form.Item name="monitorTypeBottom" label="监控指标">
                <Select options={monitorTypeOptions} disabled />
              </Form.Item>
              <Form.Item
                name="consumptionNodeBottom"
                label="能耗节点"
                rules={[
                  { required: true, message: '请选择能耗节点' },
                  ({ getFieldValue }) => ({
                    validator(_, v) {
                      if (getFieldValue('consumptionNodeTop').value !== v.value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('不可选择同一能耗节点'));
                    },
                  }),
                ]}
              >
                <TreeSelect
                  // 为了获取 label 的值, 表单获取到的值为:
                  // consumptionNodeTop: { label: xx-yy-aa, value: key }
                  labelInValue
                  treeNodeLabelProp="display"
                  treeNodeFilterProp="title"
                  allowClear
                  showSearch
                  placeholder="请选择能耗节点"
                  treeData={tree}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeDefaultExpandAll
                />
              </Form.Item>
            </div>
          </Form>

          <div className={styles.btnArea}>
            <Space size={8}>
              <Button
                onClick={() => {
                  // 重置表单
                  form.resetFields();
                  setFormInitialValues();
                  // 重置搜索条件
                  const values = form.getFieldsValue();
                  setSearchParams(values);
                }}
              >
                重置
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                对比
              </Button>
            </Space>
          </div>
        </div>
        <div className={styles.rightCol}>
          <div className={styles.dateSwitch}>
            <div className={styles.switchBtn}>
              <Radio.Group
                options={Object.entries(TimeGranularityDisplay).map(([k, v]) => ({
                  label: v,
                  value: k,
                }))}
                onChange={e => {
                  setTimeGranularity(e.target.value);
                }}
                value={timeGranularity}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
            <div className={styles.datePickerArea}>
              <DatePicker.RangePicker
                allowClear={false}
                picker={timeGranularity === TimeGranularity.MONTH ? 'month' : 'date'}
                value={dates || value}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
                onChange={val => setValue(val)}
                onOpenChange={onOpenChange}
              />
            </div>
          </div>

          <Spin spinning={loading}>
            <div className={styles.chartArea} style={{ marginTop: 36 }}>
              {chartDataTop?.length || chartDataBottom.length ? (
                <ReactEChartsCore
                  notMerge
                  lazyUpdate={false}
                  style={{ height: 400 }}
                  option={options}
                  echarts={echarts}
                  theme={echartsTheme}
                />
              ) : (
                <Empty style={{ marginTop: 180 }} />
              )}
            </div>
          </Spin>
        </div>
      </div>
    </Modal>
  );
}
