import { Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { EllipsisSpan } from '@maxtropy/components';
import { DatabaseOutlined } from '@ant-design/icons';
import {
  BaseElectricityBillResponse,
  BillActiveQuantityItem,
  getBillActiveQuantity,
  QuantitiesItem,
} from '../../../../api/electricity';
import { useParams } from 'react-router-dom';
import { units } from '../utils';
import {
  HasTimeOfUseElectricityPriceType,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceProps,
} from '../../../../api/electricitySettlementRules';

const columns = [
  {
    title: '',
    dataIndex: 'highLowType',
    ellipsis: { showTitle: true },
    render: (v: HasTimeOfUseElectricityPricePropsType) => (
      <EllipsisSpan value={v in HasTimeOfUseElectricityPricePropsType ? HasTimeOfUseElectricityPriceProps[v] : '总'} />
    ),
    width: 70,
    // className: `${styles.firstCell}`,
  },
  {
    title: '本月示数',
    dataIndex: 'currentQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '上月示数',
    dataIndex: 'lastQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
  {
    title: '倍率',
    dataIndex: 'multiplyRatio',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '单价',
    dataIndex: 'unitPowerRate',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(8)} ${units['元/千瓦时']}` : v} />
    ),
  },
  {
    title: '有功电量',
    dataIndex: 'totalActivePowerQuantity',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.千瓦时}` : v} />
    ),
  },
  {
    title: '有功电费',
    dataIndex: 'totalElectricFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
  {
    title: '力调费',
    dataIndex: 'powerFactorFee',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v !== undefined && v !== null ? `${v?.toFixed(2)} ${units.元}` : v} />,
  },
];

const ElectricityBillActivePower: FC<{ isUnitarySystem: boolean; billInfo: BaseElectricityBillResponse }> = ({
  isUnitarySystem,
  billInfo,
}) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<BillActiveQuantityItem[]>([]);

  useEffect(() => {
    if (id) {
      getBillActiveQuantity(id).then(res => setData(res.list || []));
    }
  }, [id]);

  const activePowerTables = data.map(i => {
    const dataSource = i.quantities.map(item => {
      return {
        id: item.id,
        lastQuantity: item.lastQuantity,
        currentQuantity: item.currentQuantity,
        multiplyRatio: item.multiplyRatio,
        unitPowerRate: item.unitPowerRate,
        totalActivePowerQuantity: item.totalActivePowerQuantity,
        actualActivePowerQuantity: item.actualActivePowerQuantity,
        totalElectricFee: item.totalElectricFee,
        powerFactorFee: item.powerFactorFee,
        highLowType: item.highLowType,
      };
    });

    const tableDataSource = [
      ...dataSource,
      ...(billInfo?.config?.timeUseTariffType === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE
        ? [
            dataSource.reduce(
              (a, b) => {
                if (isUnitarySystem) {
                  return {
                    ...a,
                    lastQuantity: (a.lastQuantity ?? 0) + (b.lastQuantity ?? 0),
                    currentQuantity: (a.currentQuantity ?? 0) + (b.currentQuantity ?? 0),
                    totalActivePowerQuantity: (a.totalActivePowerQuantity ?? 0) + (b.totalActivePowerQuantity ?? 0),
                    totalElectricFee: (a.totalElectricFee ?? 0) + (b.totalElectricFee ?? 0),
                    powerFactorFee: (a.powerFactorFee ?? 0) + (b.powerFactorFee ?? 0),
                    multiplyRatio: b.multiplyRatio ?? a.multiplyRatio,
                  };
                }
                return {
                  ...a,
                  lastQuantity: (a.lastQuantity ?? 0) + (b.lastQuantity ?? 0),
                  currentQuantity: (a.currentQuantity ?? 0) + (b.currentQuantity ?? 0),
                  totalActivePowerQuantity: (a.totalActivePowerQuantity ?? 0) + (b.actualActivePowerQuantity ?? 0),
                  totalElectricFee: (a.totalElectricFee ?? 0) + (b.totalElectricFee ?? 0),
                  powerFactorFee: (a.powerFactorFee ?? 0) + (b.powerFactorFee ?? 0),
                  multiplyRatio: b.multiplyRatio ?? a.multiplyRatio,
                };
              },
              {
                id: '总',
                highLowType: 5,
                lastQuantity: 0,
                currentQuantity: 0,
                totalActivePowerQuantity: 0,
                totalElectricFee: 0,
                powerFactorFee: 0,
                multiplyRatio: undefined,
              } as unknown as Partial<QuantitiesItem>
            ),
          ]
        : []),
    ];

    // 单一制没有力调费
    let unitarySystemColumns = columns.slice(0, columns.length - 1);

    return (
      <div key={i.meterId}>
        <div style={{ marginBottom: 8 }}>
          <DatabaseOutlined style={{ color: 'var(--primary-color)', fontSize: 18 }} />
          设备名称：{i.deviceName}
        </div>
        <div className={styles.activePowerTable}>
          <Table
            rowKey="highLowType"
            pagination={false}
            dataSource={tableDataSource}
            columns={isUnitarySystem ? unitarySystemColumns : columns}
          />
        </div>
      </div>
    );
  });

  return <>{activePowerTables}</>;
};

export default ElectricityBillActivePower;
