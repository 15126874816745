import { Dayjs } from 'dayjs';
import { ReportStrategyStatisticalCycleEnum } from '@/shared/types';
import { V2ReportStrategyDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
export interface FormValue
  extends Omit<
    V2ReportStrategyDetailPostResponse,
    'id' | 'serialNumber' | 'ouName' | 'startTime' | 'endTime' | 'beginTime'
  > {
  time?: RangeValue | Dayjs;
}

export const dateLimit = {
  [ReportStrategyStatisticalCycleEnum.QUARTER]: 15 * 60 * 1000,
  [ReportStrategyStatisticalCycleEnum.HOUR]: 60 * 60 * 1000,
  [ReportStrategyStatisticalCycleEnum.DAY]: 24 * 60 * 60 * 1000,
  [ReportStrategyStatisticalCycleEnum.MONTH]: 30 * 24 * 60 * 60 * 1000,
};

export const dateFormat = {
  [ReportStrategyStatisticalCycleEnum.QUARTER]: 'YYYY-MM-DD HH:mm',
  [ReportStrategyStatisticalCycleEnum.HOUR]: 'YYYY-MM-DD HH:mm',
  [ReportStrategyStatisticalCycleEnum.DAY]: 'YYYY-MM-DD',
  [ReportStrategyStatisticalCycleEnum.MONTH]: 'YYYY-MM',
};
