import { FC } from 'react';
import BalanceAlarmManagementList from './BalanceAlarmManagementList';

// const routes = [
//     { name: '运营配置' },
//     { name: '余额报警' },
//     { name: '报警管理' }
// ];

const BalanceAlarmManagement: FC = () => {
  return <BalanceAlarmManagementList />;
};

export default BalanceAlarmManagement;
