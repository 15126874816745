import React, { createContext, useState } from 'react';
import { Layout } from 'antd';
import { useBreadcrumbRoutes } from '@maxtropy/components';

import styles from './index.module.scss';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import EnergyTree from './EnergyTree';
import UnitSwiper from './UnitSwiper';
import SubUnitTable from './SubUnitTable';
import UnitDetail from './UnitDetail';
import dayjs, { Dayjs } from 'dayjs';
import DarkWrapper from './DarkWrapper';
import { EnergyUnitItem } from '@/api/energyUnitDashboard';

const { Content, Sider } = Layout;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

export const EnergyUnitContext = createContext<{
  groupId?: number;
  unitId?: number;
  setGroupId?: (groupId: number) => void;
  setUnitId?: (unitId: number | undefined) => void;
  showDetail?: boolean;
  setShowDetail?: (showDetail: boolean) => void;
  setTime?: (time: RangeValue) => void;
  time?: RangeValue;
}>({});

const EnergyUnitKanban: React.FC = () => {
  const [openSider, setOpenSider] = useState(true);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number>();
  const [unitId, setUnitId] = useState<number>();
  const [currentUnit, setCurrentUnit] = useState<{ unitName?: string; unitId?: number; dates: RangeValue }>();
  const [unitList, setUnitList] = useState<any>([]);
  const [unitPath, setUnitPath] = useState<string>('');
  const [time, setTime] = useState<RangeValue>([dayjs().subtract(30, 'day'), dayjs()]);
  const [defaultData, setDefaultData] = useState<EnergyUnitItem[]>([]);

  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  return (
    <DarkWrapper routes={breadcrumbRoutes?.routes}>
      <EnergyUnitContext.Provider
        value={{ groupId, unitId, setGroupId, setUnitId, showDetail, setShowDetail, setTime, time }}
      >
        <Layout className={styles.layout}>
          <Sider
            className={styles.siderDemo}
            width={openSider ? siderWidth : 0}
            style={{ transition: dragStatus ? 'none' : 'all 0.2s' }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <EnergyTree
              setNodePath={path => setUnitPath(path)}
              setOneLevelUnits={items => setUnitList(items)}
              defaultData={defaultData}
              setDefaultData={setDefaultData}
            />
          </Sider>
          <Content className={styles.content} style={{ marginLeft: openSider ? 10 : 0 }}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            {showDetail ? (
              // 详情页
              <UnitDetail unitPath={unitPath} times={time} defaultData={defaultData} />
            ) : (
              <>
                {/* 单元详情卡片 */}
                <UnitSwiper
                  oneLevelUnits={unitList}
                  expose={(dates, name, id) => {
                    setCurrentUnit({ unitName: name, unitId: id, dates: dates });
                  }}
                />
                {/* table表格 */}
                <SubUnitTable swiperUnit={currentUnit} oneLevelUnits={unitList} />
              </>
            )}
          </Content>
        </Layout>
      </EnergyUnitContext.Provider>
    </DarkWrapper>
  );
};

export default EnergyUnitKanban;
