import {
  Button,
  EllipsisSpan,
  Form,
  Paging,
  Table,
  useSearchParams,
  useUpdate,
  Tag,
  Input,
  PopConfirm,
  MultiSelect,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import StationModalOp from './StationModalOp';
import dayjs from 'dayjs';
import { IsMapping, WorkStationState } from '@/api/workstationInfo';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';
import { statusList } from '../WorkCenterInfo';
import { PlusOutlined } from '@ant-design/icons';
import {
  V2WorkStationPagePostRequest,
  V2WorkStationPagePostResponse,
  apiV2WorkStationDeletePost,
  apiV2WorkStationOperateStatePost,
  apiV2WorkStationPagePost,
} from '@maxtropy/device-customer-apis-v2';

const columns = [
  {
    title: '工站编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工站名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属工序',
    dataIndex: 'workProcedureCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工序名称',
    dataIndex: 'workProcedureName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === WorkStationState.OPEN ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              禁用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '最后操作人',
    dataIndex: 'updateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<V2WorkStationPagePostRequest, 'page' | 'size'> {}
export type TableRow = Exclude<V2WorkStationPagePostResponse['list'], undefined>[number];

const WorkstationInfo: React.FC = () => {
  const [data, setData] = useState<TableRow[]>([]);
  const [stationModalVisible, setStationModalVisible] = useState(false);
  const [opType, setOpType] = useState('add');
  const hasLook = useHasPermission(PermissionsType.B_VIEWINGSTATIONINFORMATION); // 查看工站
  const hasEdit = useHasPermission(PermissionsType.B_EDITSTATIONINFORMATION); // 编辑工站
  const hasAbleOrDisable = useHasPermission(PermissionsType.B_CONTROLSTATIONINFORMATION); // 启用禁用工站
  const hasScrap = useHasPermission(PermissionsType.B_SCRAPSTATIONINFORMATION); // 废弃工站信息
  const hasAdd = useHasPermission(PermissionsType.B_CREATE_STATION);

  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<TableRow>();

  const pageInfo = useSearchParams<SearchParams>(20, {
    state: [WorkStationState.OPEN],
  });
  const { searchParams, reset, finish, setTotalCount } = pageInfo;
  const [form] = Form.useForm();

  useEffect(() => {
    setLoading(true);

    const search = {
      ...searchParams,
    };
    apiV2WorkStationPagePost({
      ...search,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list!);
        setTotalCount(res.total!);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (_: any, row: TableRow) => {
        return (
          <Space size={16}>
            {hasLook && (
              <Button type="link" onClick={() => viewBtn(row)}>
                查看
              </Button>
            )}
            {hasEdit && (
              <Button type="link" onClick={() => editBtn(row)}>
                编辑
              </Button>
            )}

            {hasAbleOrDisable ? (
              row.state === WorkStationState.CLOSE ? (
                <Button
                  type="link"
                  onClick={() => {
                    apiV2WorkStationOperateStatePost({
                      id: row.id,
                      state: WorkStationState.OPEN,
                    }).then(() => {
                      updateFn();
                    });
                  }}
                >
                  启用
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    apiV2WorkStationOperateStatePost({
                      id: row.id,
                      state: WorkStationState.CLOSE,
                    }).then(() => {
                      updateFn();
                    });
                  }}
                >
                  禁用
                </Button>
              )
            ) : null}

            {hasScrap && (
              <PopConfirm
                title="废弃后不可再恢复，请确认。"
                onConfirm={() => {
                  apiV2WorkStationDeletePost({ id: row.id }).then(updateFn);
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link" disabled={row.isMapping === IsMapping.YES}>
                  废弃
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const addBtn = () => {
    setCurrentRow(undefined);
    setOpType('add');
    setStationModalVisible(true);
  };
  const editBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('edit');
    setStationModalVisible(true);
  };
  const viewBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('view');
    setStationModalVisible(true);
  };

  const onFinish = (v: any) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      state: [WorkStationState.OPEN],
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: [WorkStationState.OPEN],
      }}
    >
      <Form.Item name="word" label="工站编码/名称/所属工序">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="state" label="工站状态">
        <MultiSelect options={statusList} />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <div>
      <div className={styles.filterWrapper}>{filters}</div>
      <div className={styles.spaceStyle} />
      <div className={styles.filterWrapper}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 10 }}>
          <div>
            {hasAdd && (
              <Button type="primary" onClick={addBtn} icon={<PlusOutlined />}>
                新建工站
              </Button>
            )}
          </div>
          <Button
            type="primary"
            onClick={() => {
              window.open('/productionManagement/production/energyUnitMapping', '_blank');
            }}
          >
            能耗单元映射
          </Button>
        </div>
        <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} scroll={{ x: 1300 }} />
        <Paging pagingInfo={pageInfo} />
      </div>

      {stationModalVisible && (
        <StationModalOp
          type={opType}
          data={currentRow}
          cancel={() => setStationModalVisible(false)}
          confirm={() => {
            if (opType === 'add') {
              onReset();
            } else {
              updateFn();
            }
            setStationModalVisible(false);
          }}
        />
      )}
    </div>
  );
};
export default WorkstationInfo;
