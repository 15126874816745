import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { Modal, Form, DatePicker, Button } from '@maxtropy/components';
import { Dayjs } from 'dayjs';
import { adjustWorkday, WorkShiftsPerDay } from '@/api/schedulePlan';

type AdjustWorkdayModalProps = {
  open: boolean;
  closeModal: () => void;
  forceUpdate: () => void;
  panelValue: Dayjs;
  workShiftsPerMonth: WorkShiftsPerDay[];
  unitId: number;
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// 工作日调整模态框
export default function AdjustWorkdayModal(props: AdjustWorkdayModalProps) {
  const { open, closeModal, forceUpdate, panelValue, workShiftsPerMonth, unitId } = props;
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const [form] = Form.useForm();

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
    const notCurrentMonth = current.month() !== panelValue.month();
    return !!tooEarly || !!tooLate || notCurrentMonth;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    form.setFieldValue('range', dates || value);
  }, [dates, form, value]);

  return (
    <Modal
      contentClassName="modal-form-content"
      title="工作日调整"
      size="normal"
      open={open}
      onOk={() => {}}
      onCancel={() => {
        closeModal();
      }}
      footer={
        <Space size={8}>
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            取消
          </Button>

          <Button
            type="primary"
            onClick={() => {
              form.validateFields().then(values => {
                console.log('value', values);
                const [startDate, endDate]: [Dayjs, Dayjs] = values.range;
                adjustWorkday({
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                  isWorkingDay: true,
                  energyUnitId: unitId,
                }).finally(() => {
                  closeModal();
                  forceUpdate();
                });
              });
            }}
          >
            转为工作日
          </Button>

          <Button
            type="primary"
            onClick={() => {
              form.validateFields().then(values => {
                // values 为表单值
                // 这里去校验所选日期内是否存在产量, 存在手动给表单添加错误且禁止提交
                const [startDate, endDate]: [Dayjs, Dayjs] = values.range;
                // 是否在所选日期内的某个班次存在日产量
                let existOutputAmount = false;
                for (let i = startDate.date() - 1; i < endDate.date(); i++) {
                  const workShiftsPerDay = workShiftsPerMonth?.[i];
                  if (workShiftsPerDay?.isWorkingDay) {
                    if (workShiftsPerDay?.workShifts?.some(s => s.outputAmount)) {
                      existOutputAmount = true;
                      break;
                    }
                  }
                }

                if (existOutputAmount) {
                  // 存在日产量, 不允许提交
                  form.setFields([{ name: 'range', errors: ['当前日存在产量，需先清空产量数据。'] }]);
                } else {
                  adjustWorkday({
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD'),
                    isWorkingDay: false,
                    energyUnitId: unitId,
                  }).finally(() => {
                    closeModal();
                    forceUpdate();
                  });
                }
              });
            }}
          >
            转为休息日
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        // onFinish={(v: any) => {
        //   console.log('adjust workday form submit', v);
        // }}
      >
        <Form.Item name="range" rules={[{ required: true }]} label="所选日期">
          <DatePicker.RangePicker
            // value={dates || value}
            disabledDate={disabledDate}
            onCalendarChange={val => setDates(val)}
            onChange={val => setValue(val)}
            onOpenChange={onOpenChange}
            // 设置下拉框里预选的范围
            defaultPickerValue={[panelValue, panelValue]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
