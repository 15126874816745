import { PhysicalQuantityUnit } from '@/api/energyConsumption';
import { EnergyMediumsProps, OverviewItemProps, SignType } from '@/api/energyUnitDashboard';
import classnames from 'classnames';
import { isNil } from 'lodash-es';
import React, { useContext } from 'react';
import { EnergyUnitContext } from '..';
import styles from './index.module.scss';
import NoDataPic from './noDataPic.png';
import NoPermissionPic from './noPermissionPic.png';

interface CardItemProps {
  Overview: OverviewItemProps;
  swiperUnitId?: number;
  setSwiperUnitId?: (id: number) => void;
}

const CardItem: React.FC<CardItemProps> = ({ Overview, swiperUnitId, setSwiperUnitId }) => {
  const { setUnitId, setShowDetail } = useContext(EnergyUnitContext);

  const getComprehensive = (v: EnergyMediumsProps | null) => {
    if (!v) return null;
    const momPreSign = !v.momDataChange ? SignType.NONE : v.momDataChange > 0 ? SignType.PLUS : SignType.MINUS;
    const yoyPreSign = !v.yoyDataChange ? SignType.NONE : v.yoyDataChange > 0 ? SignType.PLUS : SignType.MINUS;
    return (
      <div key={v.id} className={styles.infoLine}>
        <div className={styles.medium}>综合能耗（kgce）</div>
        <div className={styles.val}>{v.currentValue?.toFixed(2) ?? '--'}</div>
        <div className={styles.chain}>
          <span className={styles.desc}>同比</span>
          <span className={styles[yoyPreSign]}>
            {yoyPreSign === SignType.PLUS ? '+' : ''}
            {!isNil(v.yoyDataChange) ? v.yoyDataChange?.toFixed(2) : '--'}%
          </span>
        </div>

        <div className={styles.chain}>
          <span className={styles.desc}>环比</span>
          <span className={styles[momPreSign]}>
            {momPreSign === SignType.PLUS ? '+' : ''}
            {!isNil(v.momDataChange) ? v.momDataChange?.toFixed(2) : '--'}%
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames(styles.cardBox, swiperUnitId === Overview.energyUnitId && styles.currentBox)}
      onClick={() => setSwiperUnitId?.(Overview.energyUnitId)}
    >
      <div className={styles.boxHeader}>
        <div className={styles.title}>{Overview.energyUnitName}</div>
        <div className={styles.link}>
          <span
            onClick={() => {
              console.log('123');
              setSwiperUnitId?.(Overview.energyUnitId);
            }}
          >
            查看下级
          </span>
          {!Overview.disabled && Overview.energyMediums.length ? (
            <span
              onClick={() => {
                setShowDetail?.(true);
                setUnitId?.(Overview.energyUnitId);
              }}
            >
              详情
            </span>
          ) : null}
        </div>
      </div>
      {Overview.disabled ? (
        <div className={styles.emptyPic}>
          <img src={NoPermissionPic} alt="" />
          <div>暂无单元数据权限</div>
        </div>
      ) : !Overview.energyMediums.length ? (
        <div className={styles.emptyPic}>
          <img src={NoDataPic} alt="" />
          <div>暂无数据</div>
        </div>
      ) : (
        <div className={styles.infoBox}>
          {[
            getComprehensive(Overview.comprehensiveEnergyConsumption),
            ...Overview.energyMediums.map(item => {
              const momPreSign = !item.momDataChange
                ? SignType.NONE
                : item.momDataChange > 0
                ? SignType.PLUS
                : SignType.MINUS;
              const yoyPreSign = !item.yoyDataChange
                ? SignType.NONE
                : item.yoyDataChange > 0
                ? SignType.PLUS
                : SignType.MINUS;
              return (
                <div key={item.id} className={styles.infoLine}>
                  <div className={styles.medium}>
                    {item.name}（{PhysicalQuantityUnit[item.basePhysicalQuantityId] ?? 'kWh'}）
                  </div>
                  <div className={styles.val}>{item.currentValue?.toFixed(2) ?? '--'}</div>
                  <div className={styles.chain}>
                    <span className={styles.desc}>同比</span>
                    <span className={styles[yoyPreSign]}>
                      {yoyPreSign === SignType.PLUS ? '+' : ''}
                      {!isNil(item.yoyDataChange) ? item.yoyDataChange?.toFixed(2) : '--'}%
                    </span>
                  </div>

                  <div className={styles.chain}>
                    <span className={styles.desc}>环比</span>
                    <span className={styles[momPreSign]}>
                      {momPreSign === SignType.PLUS ? '+' : ''}
                      {!isNil(item.momDataChange) ? item.momDataChange?.toFixed(2) : '--'}%
                    </span>
                  </div>
                </div>
              );
            }),
          ]}
        </div>
      )}
    </div>
  );
};

export default CardItem;
