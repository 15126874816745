import ReactEcharts from 'echarts-for-react';

import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ProductionBeatConfigContext } from '@/pages/ProductionBeatConfig';
import { ActionColors, TimeGranularityTypeDisPlay } from '@/pages/ProductionBeatConfig/utils';

interface ChartDataNode {
  ts: number;
  value: number;
  actionName: string;
  timeRange: string;
}

const getPiecesDate = (chartData: ChartDataNode[]) => {
  return chartData.map((i, index) => ({
    lt: i.ts,
    color: index === 1 || index === 0 ? ActionColors[0] : ActionColors[index - 1],
  }));
};

const BeatLineChart: React.FC = () => {
  const { actionTableData } = useContext(ProductionBeatConfigContext);
  const [upData, setUpData] = useState<ChartDataNode[]>([]);

  const { physicalUnitName, timeResolution } = useContext(ProductionBeatConfigContext);

  const [downData, setDownData] = useState<ChartDataNode[]>([]);

  const [maxNumLength, setMaxNumLength] = useState(0);

  useEffect(() => {
    if (actionTableData && actionTableData.length > 0) {
      const today = dayjs().startOf('day').valueOf();
      let ts = 0;
      const data = actionTableData.map(i => {
        ts += Number(((i.durationRangeMin + i.durationRangeMax) / 2).toFixed(2));

        return {
          ts,
          down: i.useEnergyLowLimit,
          up: i.useEnergyUpperLimit,
          timeRange: `${i.durationRangeMin}~${i.durationRangeMax}`,
          actionName: i.actionName,
        };
      });
      setUpData([
        {
          ts: today,
          value: data[0].up - data[0].down,
          actionName: data[0].actionName,
          timeRange: data[0].timeRange,
        },
        ...data.map(i => ({
          ts: today + i.ts * 60 * 1000,
          value: i.up - i.down,
          actionName: i.actionName,
          timeRange: i.timeRange,
        })),
      ]);
      setDownData([
        {
          ts: today,
          value: data[0].down,
          actionName: data[0].actionName,
          timeRange: data[0].timeRange,
        },
        ...data.map(i => ({
          ts: today + i.ts * 60 * 1000,
          value: i.down,
          actionName: i.actionName,
          timeRange: i.timeRange,
        })),
      ]);
    }
  }, [actionTableData]);

  useEffect(() => {
    const max = Math.max(...(upData ?? []).map(i => i.value as number));
    setMaxNumLength(max.toFixed(0).toString().length);
  }, [upData]);

  const getChartOption = {
    grid: [
      {
        left: maxNumLength * 10 + 10,
        right: 60,
        height: '65%',
      },
      {
        left: maxNumLength * 10 + 10,
        right: 60,
        bottom: 65,
        height: '4%',
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: '#fff',
      },
      formatter: (tooltipData: any) => {
        const ts = tooltipData[0].axisValue;
        console.log(tooltipData);
        const up = upData.find(i => i.ts === ts);
        const down = downData.find(i => i.ts === ts);
        const actionName = up?.actionName;
        const timeRange = up?.timeRange;

        return `<div style="width: 200px;" >
           <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${actionName} </p>
           <div style="margin-top:8px; display:flex; justify-content: space-between; align-items: center;" >
             <p style= "margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);"  >时间范围</p>
             <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${timeRange}${
          TimeGranularityTypeDisPlay[timeResolution]
        } </p>
           </div>
           <div style="margin-top:8px; display:flex; justify-content: space-between; align-items: center;" >
             <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"  >用能范围</p>
             <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" > ${down?.value}~${
          (up?.value ?? 0) + (down?.value ?? 0)
        }${physicalUnitName ?? ''} </p>
           </div>
         </div>`;
      },
    },
    graphic: upData.slice(1).map((i, index) => ({
      type: 'rect',
      top: 21,
      right: (index + 1) * 100,
      shape: {
        width: 16,
        height: 10,
      },
      textContent: {
        type: 'text',
        style: {
          text: i.actionName,
          fill: 'rgba(255,255,255,0.85)',
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        fill: ActionColors[index],
      },
    })),
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
          yAxisIndex: 'all',
        },
      ],
    },
    xAxis: [
      {
        type: 'time',
        name: `${TimeGranularityTypeDisPlay[timeResolution]}`,
        data: upData.map(i => i.ts),
        minInterval: 60 * 1000,
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
          margin: 16,
          formatter: function (e: number) {
            return dayjs(e, 'x').format('mm');
          },
        },
      },
      {
        gridIndex: 1,
        type: 'time',
        position: 'top',
        data: upData.map(i => i.ts),
        show: false,
      },
    ],

    yAxis: [
      {
        name: `${physicalUnitName ?? ''}`,
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },
        type: 'value',
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
        axisLabel: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },
      },
      {
        gridIndex: 1,
        type: 'value',
        inverse: true,
        show: false,
      },
    ],

    visualMap: [
      {
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: getPiecesDate(upData),
      },
    ],
    series: [
      {
        name: '实际',
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 1,
        symbol: 'none',
        data: upData.map(i => [i.ts, 0]),
        lineStyle: {
          width: 12,
        },
      },
      {
        name: '下限',
        type: 'line',
        symbol: 'none',
        // step: 'end',
        data: downData.map(i => [i.ts, i.value]),
        lineStyle: {
          opacity: 0,
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        stack: 'confidence-band',
      },
      {
        name: '上限',
        type: 'line',
        // step: 'start',
        symbol: 'none',
        data: upData.map(i => [i.ts, i.value]),
        lineStyle: {
          opacity: 0,
        },
        xAxisIndex: 0,
        yAxisIndex: 0,
        areaStyle: {
          color: 'rgba(0,173,255,0.6)',
        },
        stack: 'confidence-band',
      },
    ],
  };

  return (
    <ReactEcharts option={getChartOption} style={{ width: '976px', height: '555px' }} notMerge lazyUpdate={false} />
  );
};

export default BeatLineChart;
