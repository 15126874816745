/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { StatisticsPartition } from '../../api/statistics';

export type NumberLikeValue = string | number;

/**
 * 是否是有效的数字
 */
export const isNumber = (value: NumberLikeValue) => !isNaN(+value) && isFinite(+value);

/**
 * 值是否相等？？
 */
export const overLimit = (value: NumberLikeValue, limit: number) => +value === limit;

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: NumberLikeValue) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * f0(f1(f2(value))) ...
 */
export const compose =
  (...fns: any[]) =>
  (value: any) =>
    [...fns].reverse().reduce((prev, fn) => fn(prev), value);

/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: NumberLikeValue[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

const add = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a + b, a, b);

const minus = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a - b, a, b);

const multiply = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => a * b, a, b);

const divide = (a: NumberLikeValue, b: NumberLikeValue) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);

const abs = (a: NumberLikeValue) => validateNumbers(a => Math.abs(a), a);

const sqrt = (a: NumberLikeValue) => validateNumbers(a => (a >= 0 ? Math.sqrt(a) : NaN), a);

const percent = (a: NumberLikeValue, b: NumberLikeValue) =>
  validateNumbers((a, b) => (b !== 0 ? divide(a, b) * 100 : NaN), a, b);

/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};

/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

/**
 * (a - b) / [b] * 100
 */
const monthOnMonth = (a: NumberLikeValue, b: NumberLikeValue) => percent(minus(a, b), abs(b));

/**
 * 求数组中的最大值
 */
export const max = (set: any) => {
  const numbers = filterNumber(set);
  return Math.max(...numbers);
};

/**
 * 求数组中的最小值
 */
export const min = (set: any) => Math.min(...filterNumber(set));

export const getMax = (chartData: any[]) => {
  const arr = chartData
    .map(item => item.data)
    .flat()
    .map(item => item[1]);
  return max(arr);
};

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode: StatisticsPartition, date: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.HOUR:
      return {
        tsStart: dayjs(date, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.DAY:
      return {
        tsStart: dayjs(date, 'x').subtract(30, 'days').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        tsStart: dayjs(date, 'x').subtract(11, 'month').startOf('month').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('month').valueOf(),
      };
    case StatisticsPartition.YEAR:
      return {
        tsStart: dayjs(date, 'x').subtract(2, 'year').startOf('year').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('year').valueOf(),
      };
    default:
      return {
        tsStart: dayjs(date, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(date, 'x').endOf('day').valueOf(),
      };
  }
};

export const getTs = (mode: StatisticsPartition, start: Dayjs, end: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.HOUR:
    case StatisticsPartition.DAY:
      return {
        tsStart: dayjs(start, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        tsStart: dayjs(start, 'x').startOf('month').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('month').valueOf(),
      };
    case StatisticsPartition.YEAR:
      return {
        tsStart: dayjs(start, 'x').startOf('year').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('year').valueOf(),
      };
    default:
      return {
        tsStart: dayjs(start, 'x').startOf('day').valueOf(),
        tsEnd: dayjs(end, 'x').endOf('day').valueOf(),
      };
  }
};

// 校验图非空
export const notEmpty = (arr: any[]) => arr.filter(item => !isNil(item.value)).length > 0;
