import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Table, Button, Modal, Form, Input } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { SaleClientContactInfo, SaleClientContactRequest } from '../../../../api/sale';

import { uniqueId } from 'lodash-es';

interface ClientContactProps {
  disabled?: boolean;
  value?: SaleClientContactInfo[];
  onChange?: (value: SaleClientContactRequest[]) => void;
}

const columns: ColumnType<SaleClientContactRequest>[] = [
  {
    title: '联系人',
    dataIndex: 'contactName',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'contactCellphone',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '备注',
    dataIndex: 'description',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const ClientContact: FC<ClientContactProps> = ({ disabled = false, value, onChange }) => {
  const [form] = Form.useForm();

  const [current, setCurrent] = useState<Partial<SaleClientContactRequest>>();
  const [visible, setVisible] = useState<boolean>(false);

  const onDelete = (index: number) => {
    onChange?.([...(value ?? []).slice(0, index), ...(value ?? []).slice(index + 1)]);
  };

  useEffect(() => {
    if (current) {
      form.setFieldsValue({
        contactName: current.contactName,
        contactCellphone: current.contactCellphone,
        description: current.description,
      });
    }
  }, [current, form]);

  const onOk = () => {
    form.validateFields().then((data: SaleClientContactRequest) => {
      if (current) {
        const index = (value ?? []).findIndex(i => i === current);
        onChange?.([...(value ?? []).slice(0, index), data, ...(value ?? []).slice(index + 1)]);
      } else {
        onChange?.([...(value ?? []), data]);
      }
      onClose();
    });
  };

  const onClose = () => {
    setCurrent(undefined);
    setVisible(false);
    form.resetFields();
  };

  const buildColumns = [
    ...columns,
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            width: 100,
            fixed: 'right' as const,
            render: (value: undefined, record: SaleClientContactRequest, index: number) => {
              return (
                <Space size={16}>
                  <Button
                    type="link"
                    onClick={() => {
                      onDelete(index);
                    }}
                  >
                    移除
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrent(record);
                    }}
                  >
                    编辑
                  </Button>
                </Space>
              );
            },
          },
        ]),
  ];

  return (
    <>
      {!disabled && (
        <Space direction="vertical" style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            <PlusOutlined />
            添加
          </Button>
          <div />
        </Space>
      )}
      <Table
        rowKey={() => {
          return uniqueId();
        }}
        sticky
        loading={false}
        dataSource={value ?? []}
        columns={buildColumns}
      />
      <Modal
        title={`客户联系人信息`}
        size="normal"
        contentClassName="modal-form-content"
        open={!!current || visible}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
      >
        <Form form={form}>
          <Form.Item
            name="contactName"
            label="客户联系人"
            rules={[
              { required: true, message: '请输入客户联系人', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
          <Form.Item
            name="contactCellphone"
            label="客户联系方式"
            rules={[
              { required: true, message: '请输入客户联系方式', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="description" label="备注" rules={[{ max: 50, message: '最多输入50个字' }]}>
            <Input maxLength={50} disabled={disabled} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ClientContact;
