import { useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import DeviceBatchEdge from '@/shared/components/DeviceBatchEdge';
import styles from './index.module.scss';

const routes = [{ name: '批量修改数采信息' }];

const BatchUpdateEdge: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <DeviceBatchEdge isMgmt={false} />
    </Wrapper>
  );
};

export default BatchUpdateEdge;
