import {
  CustomFilter,
  EllipsisSpan,
  Paging,
  Table,
  useSearchParams,
  Form,
  Input,
  Select,
  DatePicker,
} from '@maxtropy/components';
import { useEffect, useState } from 'react';
import { RangeValue, tableColumns } from '../List';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import {
  ReBuildEleMeterPageRequest,
  ReBuildEleMeterPageRes,
  getReBuildEleMeterRecordPage,
} from '@/api/saleElectricity';
import { isNil } from 'lodash-es';
import MeterReading from '../../components/MeterReadingSourceData';
import { SaleElectricityMeterReadingTypeDisplay } from '@/api/saleElectricity'; //抄表来源字段转换
const columns = [
  {
    title: '表计编号',
    dataIndex: 'saleMeterCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '客户名称',
    dataIndex: 'saleClientName',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '计划抄表时间',
    dataIndex: 'meterReadingTs',
    width: 180,
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '抄表来源',
    dataIndex: 'type',
    width: 220,
    render: (v: string | number) => <EllipsisSpan value={meterChange(v)} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  ...tableColumns,
  {
    title: '操作时间',
    dataIndex: 'createTime',
    width: 180,
    fixed: 'right' as 'right',
    render: (v: string) => <EllipsisSpan value={isNil(v) ? undefined : `${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '操作人',
    dataIndex: 'updateByUsername',
    width: 180,
    fixed: 'right' as 'right',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams extends Omit<ReBuildEleMeterPageRequest, 'page' | 'size'> {
  time?: [Dayjs, Dayjs];
}

const meterChange = (val: string | number) => {
  let meterValue;
  switch (val) {
    case 0:
      meterValue = SaleElectricityMeterReadingTypeDisplay[0];
      break;
    case 1:
      meterValue = SaleElectricityMeterReadingTypeDisplay[1];
      break;
    case 2:
      meterValue = SaleElectricityMeterReadingTypeDisplay[2];
      break;
    default:
      meterValue = '--';
  }
  return meterValue;
};

const MeterRecord: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(100);
  const { finish, reset, searchParams, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [data, setData] = useState<ReBuildEleMeterPageRes[]>([]);

  useEffect(() => {
    form.setFieldsValue({
      plannedMeterReadingTime: hackValue || value,
    });
  }, [hackValue, value, form]);

  useEffect(() => {
    setLoading(true);
    getReBuildEleMeterRecordPage({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTotalCount(res.total);
        setData(res.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount]);

  const onFinish = (v: SearchParams) => {
    const params = {
      ...v,
      meterReadingStartTs: isNil(v.time) ? undefined : dayjs(v.time[0]).format('YYYY-MM-DD HH:00:00'),
      meterReadingEndTs: isNil(v.time) ? undefined : dayjs(v.time[1]).format('YYYY-MM-DD HH:00:00'),
      createTime: isNil(v.createTime) ? undefined : dayjs(v.createTime).format('YYYY-MM-DD'),
      time: undefined, // 去除time字段，不需上传
    };
    finish({ ...params });
  };
  const onReset = () => {
    reset();
  };

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') > 6;
    // if (!!tooEarly && !!tooLate) {
    //   form.setFieldsValue({ plannedMeterReadingTime: undefined })
    // }
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <>
      {/* 筛选条件 */}
      <div className={styles.filterWrapper}>
        <CustomFilter form={form} onFinish={(v: SearchParams) => onFinish(v)} onReset={onReset}>
          <Form.Item name="type" label="抄表来源">
            <Select allowClear placeholder="请选择" style={{ width: '100%' }} options={MeterReading} />
          </Form.Item>
          <Form.Item label="客户名称" name="saleClientName">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="表计编号" name="meterCode">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="设备编号" name="deviceCode">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="计划抄表时间" name="time">
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              showTime
              format="YYYY-MM-DD HH"
              // allowClear={false}
              // value={hackValue || value}
              onCalendarChange={val => setDates(val)}
              onChange={val => {
                setValue(val);
              }}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
          <Form.Item label="操作时间" name="createTime">
            <DatePicker style={{ width: '100%' }} placeholder="请选择" />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        <Table rowKey="id" sticky dataSource={data} loading={loading} scroll={{ x: 1300 }} columns={columns} />
        <Paging pagingInfo={pagingInfo} />
      </div>
    </>
  );
};

export default MeterRecord;
