import { Space } from 'antd';
import EnergyContent from '../EnergyContent';
import { FullscreenExitOutlined, FullscreenOutlined, ToolOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { useEffect, useMemo, useState } from 'react';
import { Select, Empty, Key } from '@maxtropy/components';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';
import { useRequest } from 'ahooks';
import { pollingInterval, scale } from '../../utils';
import {
  V2EnergyConsumptionOverviewTotalLoadFlowEnergyMediumListPostResponse,
  apiV2EnergyConsumptionOverviewTotalLoadFlowEnergyMediumListPost,
  apiV2EnergyConsumptionOverviewTotalLoadFlowMonitorListPost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  fullBtn: () => void;
  isFullScreen: boolean;
  isTwoCol?: boolean;
  outerRef: any;
  configId?: Key;
}

const sameStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const colors = ['#51DBFF', '#FFCB47', '#59D744', '#477BFF'];
const times = ['今日', '昨日', '近三日均值', '当日均值'];

export type TotalLoadFlowEnergyMediumItem = Exclude<
  V2EnergyConsumptionOverviewTotalLoadFlowEnergyMediumListPostResponse['list'],
  undefined
>[number];

const TotalLoad: React.FC<Props> = ({ outerRef, fullBtn, isFullScreen, isTwoCol, configId }) => {
  const navigate = useNavigate();
  const [energyMediumList, setEnergyMediumList] = useState<TotalLoadFlowEnergyMediumItem[]>([]);
  const [energyMediumId, setEnergyMediumId] = useState<Key>();
  const hasConfig = useHasPermission(PermissionsType.B_CONFIGUREBUTTONS);
  const [ts, setTs] = useState<number[]>([]);
  const [yesterdayTs, setYesterdayTs] = useState<Key[]>([]);

  useEffect(() => {
    if (configId) {
      apiV2EnergyConsumptionOverviewTotalLoadFlowEnergyMediumListPost({ energyOverviewConfigId: configId }).then(
        res => {
          setEnergyMediumList((res.list ?? []) as TotalLoadFlowEnergyMediumItem[]);
          if (res && res.list && res.list.length > 0) {
            setEnergyMediumList(res.list);

            // 默认选择第一个
            const ids = res.list.map(i => i.id);
            if (ids.length > 0) {
              if (ids.includes(2030)) {
                setEnergyMediumId(2030);
              } else {
                setEnergyMediumId(ids.at(0));
              }
            }
          } else {
            setEnergyMediumId(undefined);
          }
        }
      );
    }
  }, [configId]);

  const energyMediumOptions = useMemo(() => {
    if (energyMediumList && energyMediumList.length > 0) {
      return energyMediumList.map(i => ({
        label: i.energyName,
        value: i.id,
      }));
    }
  }, [energyMediumList]);

  const { data: chartData } = useRequest(
    () => {
      if (configId && energyMediumId) {
        return apiV2EnergyConsumptionOverviewTotalLoadFlowMonitorListPost({
          energyMediumId,
          energyOverviewConfigId: configId,
        }).then(res => {
          const noUnit = {
            totalLoadFlowInfo: res.totalLoadFlowInfo,
            yesterdayTotalLoadFlowInfo: res.yesterdayTotalLoadFlowInfo,
          };
          const isAllEmpty = Object.values(noUnit).every(v => {
            if (isNil(v) || (Array.isArray(v) && v?.length === 0)) {
              return true;
            }
            return false;
          });

          if (isAllEmpty) {
            return undefined;
          }

          setTs((res.totalLoadFlowInfo ?? []).map(i => i.ts as number));

          // 昨天加一天，保持时间一致
          setYesterdayTs((res.yesterdayTotalLoadFlowInfo ?? []).map(i => dayjs(i.ts, 'x').add(1, 'day').valueOf()));
          return res;
        });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [energyMediumId, configId],
    }
  );
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  const fontZoomSize = useMemo(() => {
    return zoom > 1 ? 12 * zoom : 12;
  }, [zoom]);

  const getChartOption = () => {
    return {
      grid: {
        right: 10 * zoom,
        top: 50 * zoom,
        bottom: 20 * zoom,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: 2,
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
          fontSize: fontZoomSize,
        },
        formatter: function (params: any) {
          const date = dayjs(params?.at(0).value?.at(0), 'x').format('HH:mm:ss');
          const styleStr = `display:flex;width:${
            200 * zoom
          }px;justify-content:space-between;font-size: ${fontZoomSize}px;font-weight: 500;color: rgba(255,255,255,0.65);line-height:${
            17 * zoom
          }px;margin-top:8px;`;

          const getStr = (index: number, data?: number) => {
            return `<div style='${styleStr}' >
              <div>
                <div style='width:${8 * zoom}px;height:${8 * zoom}px;background:${
              colors[index]
            }; border-radius:50%;display:inline-block'></div>  ${times[index]}
              </div>
             <div>${data ?? '--'}${chartData?.physicalUnitName ?? '--'}</div></div>`;
          };
          const str1 = getStr(0, params?.at(0).seriesName === '今日' ? params?.at(0)?.value?.at(1) : undefined); // 今日
          const str2 = getStr(
            1,
            params?.at(0).seriesName === '今日' ? params?.at(1)?.value?.at(1) : params?.at(0)?.value?.at(1)
          ); // 昨日
          const str3 = getStr(2, chartData?.pastThreeDayAvg);
          const str4 = getStr(3, chartData?.currentDayAvg);

          let str =
            `<span style='font-size: ${fontZoomSize}px;color: rgba(255,255,255,0.65);line-height: ${
              17 * zoom
            }px;'>${date}</span>` +
            '<br>' +
            `${str1}` +
            `${str2}` +
            `${str3}` +
            `${str4}`;

          return str;
        },
      },
      graphic: colors.slice(2).map((i, index) => {
        return {
          type: 'rect',
          top: 10,
          right: index === 0 ? zoom * 70 : zoom * 143,
          shape: {
            // r: 4,
            width: zoom > 1 ? 8 * zoom : 8,
            height: zoom > 1 ? 8 * zoom : 8,
          },
          textContent: {
            type: 'text',
            style: {
              text: times.slice(2)[index],
              fontSize: fontZoomSize,
              fill: 'white',
            },
            cursor: 'default',
          },
          textConfig: {
            position: 'right',
          },
          style: {
            fill: i,
          },
          cursor: 'default',
        };
      }),

      legend: {
        icon: 'rect',
        itemWidth: zoom > 1 ? 8 * zoom : 8,
        itemHeight: zoom > 1 ? 8 * zoom : 8,
        top: 4,
        right: zoom * 157,

        data: [
          {
            name: '今日',
            textStyle: {
              color: 'rgba(255,255,255,0.85)',
              fontSize: fontZoomSize,
            },
            itemStyle: {
              color: '#51DBFF',
            },
          },
          {
            name: '昨日',
            textStyle: {
              color: 'rgba(255,255,255,0.85)',
              fontSize: fontZoomSize,
            },
            itemStyle: {
              color: '#FFCB47',
            },
          },
        ],
      },
      xAxis: {
        type: 'time',
        minInterval: 60 * 1000,
        axisLabel: {
          formatter: '{HH}:{mm}',
          fontSize: fontZoomSize,
        },
      },
      yAxis: {
        type: 'value',
        name: chartData?.physicalUnitName ?? '--',
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
          fontSize: fontZoomSize,
        },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
        axisLabel: {
          fontSize: fontZoomSize,
        },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          showSymbol: false,
          symbol: 'circle',
          name: '今日',
          data:
            chartData && chartData.totalLoadFlowInfo && chartData.totalLoadFlowInfo?.length > 0
              ? chartData.totalLoadFlowInfo.map((i, index) => [ts[index], i.uploadValue])
              : undefined,
          lineStyle: {
            color: colors[0],
          },
          itemStyle: {
            color: colors[0],
          },
          markLine: {
            silent: true,
            symbol: ['none', 'none'],
            lineStyle: {
              type: 'dashed',
            },
            data: chartData?.currentDayAvg
              ? [
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#59D744', width: 2 },
                    yAxis: chartData?.pastThreeDayAvg,
                  },
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#477BFF', width: 2 },
                    yAxis: chartData?.currentDayAvg,
                  },
                ]
              : [
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#59D744', width: 2 },
                    yAxis: chartData?.pastThreeDayAvg,
                  },
                ],
          },
        },
        {
          type: 'line',
          smooth: true,
          showSymbol: false,
          name: '昨日',
          symbol: 'circle',
          data:
            chartData && chartData.yesterdayTotalLoadFlowInfo && chartData.yesterdayTotalLoadFlowInfo?.length > 0
              ? chartData.yesterdayTotalLoadFlowInfo.map((i, index) => [yesterdayTs[index], i.uploadValue])
              : undefined,
          lineStyle: {
            color: colors[1],
          },
          itemStyle: {
            color: colors[1],
          },
          markLine: {
            silent: true,
            symbol: ['none', 'none'],
            lineStyle: {
              type: 'dashed',
            },
            data: chartData?.currentDayAvg
              ? [
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#59D744', width: 2 },
                    yAxis: chartData?.pastThreeDayAvg,
                  },
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#477BFF', width: 2 },
                    yAxis: chartData?.currentDayAvg,
                  },
                ]
              : [
                  {
                    label: { formatter: `` },
                    lineStyle: { color: '#59D744', width: 2 },
                    yAxis: chartData?.pastThreeDayAvg,
                  },
                ],
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
      ],
    };
  };

  return (
    <EnergyContent
      style={{ flex: 2 }}
      title="负荷/流量监测"
      extraContent={
        <Space size={8}>
          <Select
            options={energyMediumOptions}
            style={{ width: '150px' }}
            value={energyMediumId}
            onChange={v => {
              setEnergyMediumId(v);
            }}
            placeholder="请选择"
            getPopupContainer={() => outerRef}
          />
          {hasConfig && (
            <div
              onClick={() => {
                navigate('/energy/analysisGroup/overview/config');
              }}
              className={styles.btn}
            >
              <ToolOutlined />
              &nbsp;
              <span>配置</span>
              <div className={styles.bottomLine} />
            </div>
          )}

          <div className={styles.btn} onClick={fullBtn}>
            {isFullScreen ? (
              <FullscreenExitOutlined className={styles.icon} />
            ) : (
              <FullscreenOutlined className={styles.icon} />
            )}
            &nbsp;
            <span className={styles.text}>{isFullScreen ? '退出' : '全屏'}</span>
            <div className={styles.bottomLine} />
          </div>
        </Space>
      }
      // style={isTwoCol ? {} : isFullScreen ? {} : {}}
    >
      {chartData ? (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <ReactEcharts
            option={getChartOption()}
            notMerge
            lazyUpdate={false}
            style={
              isTwoCol
                ? {
                    height: '90%',
                    width: '100%',
                  }
                : {
                    height: '90%',
                    width: '100%',
                    zoom: `${1 / zoom}`,
                    transform: `scale(${scale})`,
                  }
            }
          />
        </div>
      ) : (
        <div
          style={
            isTwoCol
              ? { height: 280, ...sameStyle }
              : isFullScreen
              ? { height: 280, ...sameStyle }
              : { height: 250, ...sameStyle }
          }
        >
          <Empty />
        </div>
      )}
    </EnergyContent>
  );
};

export default TotalLoad;
