import React, { FC, useEffect, useState } from 'react';
import { Space } from 'antd';
import { Button, Modal } from '@maxtropy/components';
import { AttributeTemplate } from '../../../../api/template';
import { ActionType } from '../index';
import TemplateVersionModal from './TemplateVersionModal';
import styles from './index.module.scss';

export interface SelectDevicesProps {
  value?: AttributeTemplate;
  onChange?: (value?: AttributeTemplate) => void;
  deviceType?: number[];
  actionType?: ActionType;
  templateId?: number;
}

const SelectDevices: FC<SelectDevicesProps> = ({ value, onChange, deviceType, actionType, templateId }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<AttributeTemplate>();

  useEffect(() => {
    setDataSource(value);
  }, [value]);

  const onAdd = () => {
    if (deviceType === undefined) {
      Modal.warning({
        title: '请先选择所属类目',
      });
      return;
    }
    setVisible(true);
  };

  const onCancel = () => {
    setDataSource(value);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space>
        {value !== undefined && <div className={styles.version}>{value?.version ?? ''}</div>}
        <Button type="link" style={{ padding: 0 }} onClick={onAdd} disabled={actionType === ActionType.ADD}>
          {value === undefined ? '请' : '重新'}选择
        </Button>
      </Space>
      <Modal
        className={styles.modal}
        title="版本选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <TemplateVersionModal
          deviceType={deviceType}
          actionType={actionType}
          templateId={templateId}
          onChange={setDataSource}
          value={dataSource}
        />
      </Modal>
    </>
  );
};

export default SelectDevices;
