import React, { useEffect, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { FormInstance, Space, message, type InputRef } from 'antd';
import { Tag, Input } from '@maxtropy/components';
import { isEmpty } from 'lodash-es';
import { apiV2OutputProductCategoryDeleteSpecEnumPost } from '@maxtropy/device-customer-apis-v2';
import { TagProp } from '../..';

const tagInputStyle: React.CSSProperties = {
  width: 64,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: 'top',
};

interface CustomTagsProps {
  disabled: boolean;
  form: FormInstance;
  index: number;
  value?: TagProp[];
}

const CustomTags = ({ disabled, form, value, index }: CustomTagsProps) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>, tag: TagProp) => {
    e.preventDefault();
    if (tag.id) {
      apiV2OutputProductCategoryDeleteSpecEnumPost({
        id: tag.id,
      })
        .then(() => {
          message.success('删除成功');
          form.setFieldValue(
            ['specList', index, 'enumList'],
            value?.filter(v => v.id !== tag.id)
          );
        })
        .catch(() => {
          form.setFieldValue(['specList', index, 'enumList'], value);
        });
    } else {
      form.setFieldValue(
        ['specList', index, 'enumList'],
        value?.filter(v => v.value !== tag.value)
      );
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (!inputValue) return;

    if (isEmpty(value)) {
      form.setFieldValue(['specList', index, 'enumList'], [{ value: inputValue }]);
    } else if (!value?.find(tag => tag.value === inputValue)) {
      form.setFieldValue(['specList', index, 'enumList'], [...value!, { value: inputValue }]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...value!];
    newTags[editInputIndex].value = editInputValue;
    form.setFieldValue(['specList', index, 'enumList'], newTags);

    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <Space size={4} style={{ gap: '4px 0', display: 'flex', flexWrap: 'wrap' }}>
      {value?.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag.value}
              size="small"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }
        const tagElem = (
          <Tag key={tag.value} closable={!disabled} onClose={e => handleClose(e, tag)}>
            <span
              onDoubleClick={e => {
                if (!tag.id) {
                  setEditInputIndex(index);
                  setEditInputValue(tag.value);
                  e.preventDefault();
                }
              }}
            >
              {tag.value}
            </span>
          </Tag>
        );
        return tagElem;
      })}
      {!disabled &&
        (inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={tagInputStyle}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag border="dashed" onClick={() => setInputVisible(true)} style={{ cursor: 'pointer' }}>
            <PlusOutlined /> 添加枚举
          </Tag>
        ))}
    </Space>
  );
};

export default CustomTags;
