import { Modal } from '@maxtropy/components';
import BeatLineChart from './BeatLineChart.tsx';

interface ActionViewModalProps {
  actionViewModalOpen: boolean;
  setActionViewModalOpen: (open: boolean) => void;
}

const ActionViewModal: React.FC<ActionViewModalProps> = props => {
  const { actionViewModalOpen, setActionViewModalOpen } = props;

  return (
    <Modal
      size="big"
      title="查看生产节拍"
      open={actionViewModalOpen}
      onCancel={() => {
        setActionViewModalOpen(false);
      }}
      onOk={() => {
        setActionViewModalOpen(false);
      }}
    >
      <BeatLineChart />
    </Modal>
  );
};

export default ActionViewModal;
