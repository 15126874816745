import { ConsumptionType } from '@/api/energyConsumptionBoardDemo';

// 时间间隔, 用于 markArea
export const getTimeIntervals = () => {
  // 休息时间间隔
  const restIntervals = [
    [11, 11.75],
    [16, 16.75],
    [23, 23.75],
    [4, 4.75],
  ];
  // 空闲时间间隔
  const freeIntervals = [
    [0, 0.25],
    [5, 5.25],
    [8.25, 8.5],
    [12.5, 12.75],
    [17, 17.25],
    [20.75, 21],
  ];
  // 停线时间间隔
  const lineStopIntervals = [
    [1, 1.5],
    [6.75, 7.25],
    [13, 13.5],
    [18, 18.5],
    [22.25, 22.75],
  ];

  const sortedIntervals = [...restIntervals, ...freeIntervals, ...lineStopIntervals].sort((a, b) => a[0] - b[0]);

  // 算出工作时间间隔
  const workIntervals = [];
  for (let i = 0; i < sortedIntervals.length; i++) {
    const interval = sortedIntervals[i];
    const nextInterval = sortedIntervals[i + 1] ?? [24, 24];
    workIntervals.push([interval[1], nextInterval[0]]);
  }

  return {
    restIntervals,
    freeIntervals,
    lineStopIntervals,
    workIntervals,
  };
};

// 基本配置
export const MarkAreaConfig = {
  rest: {
    text: '休息',
    color: 'rgba(255,255,255,0.2)',
  },
  free: {
    text: '空闲',
    color: 'rgba(255,203,71,0.4)',
  },
  lineStop: {
    text: '停线',
    color: 'rgba(255,77,79,0.5)',
  },
  work: {
    text: '正常生产',
    color: 'rgba(52,211,124,0.4)',
  },
};

// markArea 图例
export const MarkAreaLegend = [
  {
    right: 480,
    text: MarkAreaConfig.rest.text,
    legendColor: MarkAreaConfig.rest.color,
  },
  {
    right: 410,
    text: MarkAreaConfig.free.text,
    legendColor: MarkAreaConfig.free.color,
  },
  {
    right: 335,
    text: MarkAreaConfig.work.text,
    legendColor: MarkAreaConfig.work.color,
  },
  {
    right: 240,
    text: MarkAreaConfig.lineStop.text,
    legendColor: MarkAreaConfig.lineStop.color,
  },
];

// 柱状图颜色
export const BarColor = {
  // 综合能耗
  [ConsumptionType.GENERAL]: '#53DEF4',
  // 电能
  [ConsumptionType.ELECTRICITY]: '#41FBB1',
};
