import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import {
  Button,
  EllipsisSpan,
  Paging,
  PopConfirm,
  Select,
  Tag,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Table,
  Input,
  Form,
} from '@maxtropy/components';
import { Row, Space } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { CraftProcessState } from '@/api/craftProcess';
import { useNavigate } from 'react-router-dom';
import {
  apiV2ProcessFlowLogicDeletePost,
  apiV2ProcessFlowOperateStatePost,
  apiV2ProcessFlowPagePost,
  V2ProcessFlowPagePostRequest,
  V2ProcessFlowPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

type SearchParamsProps = Omit<V2ProcessFlowPagePostRequest, 'page' | 'size'>;
export type TableRow = Exclude<V2ProcessFlowPagePostResponse['list'], undefined>[number];

const CraftProcess = () => {
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const hasAddPers = useHasPermission(PermissionsType.B_NEWPROCESSFLOW);
  const hasViewPers = useHasPermission(PermissionsType.B_VIEWPROCESSFLOW);
  const hasEditPers = useHasPermission(PermissionsType.B_EDITPROCESSFLOW);
  const hasEnabledPers = useHasPermission(PermissionsType.B_CONTROLPROCESSFLOW);
  const hasDropPers = useHasPermission(PermissionsType.B_SCRAPPROCESSFLOW);
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>({});
  const navigate = useNavigate();
  const [update, setUpdate] = useUpdate();

  const { data: pageList, loading } = useRequest(
    () => {
      return apiV2ProcessFlowPagePost({
        ...searchParams,
        state: searchParams.state === 'total' ? undefined : searchParams.state,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams, update],
    }
  );

  const columns = [
    {
      title: '工艺流程编码',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工艺流程名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工作中心',
      dataIndex: 'workCenterName',
      ellipsis: { showTitle: true },
      render: (v: string[]) => <EllipsisSpan value={(v ?? []).join(',')} />,
    },
    {
      title: '状态',
      dataIndex: 'state',
      ellipsis: { showTitle: true },
      render: (v: number) => (
        <EllipsisSpan
          value={
            v === CraftProcessState.ENABLED ? (
              <Tag border="solid" color="success">
                启用
              </Tag>
            ) : (
              <Tag border="solid" color="default">
                禁用
              </Tag>
            )
          }
        />
      ),
    },
    {
      title: '最后操作人',
      dataIndex: 'updateName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
  ];

  const filters = (
    <div className={styles.form_box}>
      <Form
        form={form}
        onFinish={v => {
          setSearchParams({ ...searchParams, ...v });
          setPageOffset(1);
        }}
        initialValues={{
          state: 'total',
        }}
      >
        <Row>
          <Space.Compact>
            <Form.Item name="state">
              <Select
                style={{ width: 70 }}
                options={[
                  { label: '全部', value: 'total' },
                  { label: '启用', value: CraftProcessState.ENABLED },
                  { label: '禁用', value: CraftProcessState.DISABLED },
                ]}
                onChange={() => {
                  form.submit();
                }}
              />
            </Form.Item>

            <Form.Item name="codeOrName">
              <Input.Search
                style={{ width: 325 }}
                onKeyDown={(v: any) => {
                  if (v.key === 'Enter') {
                    form.submit();
                  } else {
                    return;
                  }
                }}
                placeholder="工艺流程编码/名称"
                onSearch={() => {
                  form.submit();
                }}
              />
            </Form.Item>
          </Space.Compact>
        </Row>
      </Form>
    </div>
  );

  const addBtn = () => {
    navigate('/productionEnergy/craftProcess/management/create');
  };

  const onEdit = (row?: TableRow) => {
    navigate(`/productionEnergy/craftProcess/management/create/${row?.id}`);
  };

  const onView = (row?: TableRow) => {
    navigate(`/productionEnergy/craftProcess/management/detail/${row?.id}`);
  };

  const enableBtn = (status?: number, id?: number) => {
    apiV2ProcessFlowOperateStatePost({ id, state: status }).then(_ => {
      setUpdate();
    });
  };

  const onDrop = (id?: number) => {
    apiV2ProcessFlowLogicDeletePost({ id }).then(() => {
      setUpdate();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',

      render: (_: any, record: TableRow) => {
        return (
          <Space size={16}>
            {hasViewPers && (
              <Button type="link" onClick={() => onView(record)}>
                查看
              </Button>
            )}

            {hasEditPers && (
              <Button type="link" onClick={() => onEdit(record)}>
                编辑
              </Button>
            )}

            {record.state === CraftProcessState.DISABLED && hasEnabledPers && (
              <PopConfirm
                title="是否启用此工艺流程。"
                onConfirm={() => {
                  enableBtn(CraftProcessState.ENABLED, record.id);
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">启用</Button>
              </PopConfirm>
            )}
            {record.state === CraftProcessState.ENABLED && hasEnabledPers && (
              <PopConfirm
                title="是否禁用此工艺流程。"
                onConfirm={() => enableBtn(CraftProcessState.DISABLED, record.id)}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">禁用</Button>
              </PopConfirm>
            )}
            {hasDropPers && (
              <PopConfirm
                title="是否废弃此工艺流程，废弃后不能恢复。"
                onConfirm={() => onDrop(record.id)}
                okText="确定"
                cancelText="取消"
              >
                <Button disabled={record.isUse} type="link">
                  废弃
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <div className={styles.row_line}>
        {hasAddPers && (
          <div style={{ marginRight: 12 }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={addBtn}>
              新建工艺流程
            </Button>
          </div>
        )}
        <div>{filters}</div>
      </div>
      <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={pageList} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default CraftProcess;
