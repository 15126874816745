import './public-path';
import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import 'dayjs/locale/zh-cn';
import { ThemeProvider, Themes, isDev, microAppRewrite } from '@maxtropy/components';

import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import isToday from 'dayjs/plugin/isToday';
dayjs.locale('zh-cn');

dayjs.extend(localeData);

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(isToday);

microAppRewrite();

const root = createRoot(document.getElementById('root') as Element);
const StrictModeComponent = isDev() ? Fragment : StrictMode;
const theme = window.THEME === Themes.LIGHT ? Themes.LIGHT : Themes.DARK;

root.render(
  <StrictModeComponent>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictModeComponent>
);
