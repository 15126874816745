export enum AccessTypeEnum {
  SELF_BUILT = 1,
  OUTSOURCING,
  EXTERNAL_COOP,
}
export const AccessType = {
  [AccessTypeEnum.SELF_BUILT]: '自产',
  [AccessTypeEnum.OUTSOURCING]: '外采',
  [AccessTypeEnum.EXTERNAL_COOP]: '外协',
};
