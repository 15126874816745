export enum ActiveTabType {
  ACTIVE = '1', // 当前生效
  NOT_ACTIVE = '2', // 未保存草稿
}

export enum SimpleTopologyType {
  MICROGRID_POWER_TOPO = 3,
  ENERGY_MEDIUM_TOPO = 5,
}

export const SimpleTopologyTypeFormat = {
  [SimpleTopologyType.MICROGRID_POWER_TOPO]: '微网电能拓扑',
  [SimpleTopologyType.ENERGY_MEDIUM_TOPO]: '能源介质拓扑',
};

// 拓扑类型
export enum TopologyType {
  PROD_LOAD = 0,
  ENERGYSTOARGE = 1,
  PV = 2,
  MICROGRID_POWER_TOPOLOGY = 3,
  ENERGY_MEDIUM_TOPOLOGY = 5,
  CHARGING_STATION = 4,
  ENERGY_TOPOLOGY = 6,
}

export const TopologyTypeFormat = {
  [TopologyType.PROD_LOAD]: '生产/负载线',
  [TopologyType.ENERGYSTOARGE]: '储能',
  [TopologyType.PV]: '光伏',
  [TopologyType.MICROGRID_POWER_TOPOLOGY]: '微网电能拓扑',
  [TopologyType.ENERGY_MEDIUM_TOPOLOGY]: '能源介质拓扑',
  [TopologyType.CHARGING_STATION]: '充电站',
  [TopologyType.ENERGY_TOPOLOGY]: '能源拓扑',
};

export type TypeOptions = {
  label: string;
  value: number;
}[];

// 拓扑类型
export enum ReverseTopologyType {
  PROD_LOAD = '生产/负载线',
  ENERGYSTOARGE = '储能',
  PV = '光伏',
  MICROGRID_POWER_TOPOLOGY = '微网电能拓扑',
  ENERGY_MEDIUM_TOPOLOGY = '能源介质拓扑',
  CHARGING_STATION = '充电站',
  ENERGY_TOPOLOGY = '能源拓扑',
}

export const ReverseTopologyTypeFormat = {
  [ReverseTopologyType.PROD_LOAD]: 0,
  [ReverseTopologyType.ENERGYSTOARGE]: 1,
  [ReverseTopologyType.PV]: 2,
  [ReverseTopologyType.MICROGRID_POWER_TOPOLOGY]: 3,
  [ReverseTopologyType.ENERGY_MEDIUM_TOPOLOGY]: 5,
  [ReverseTopologyType.CHARGING_STATION]: 4,
  [ReverseTopologyType.ENERGY_TOPOLOGY]: 6,
};

export type TopologyTypeOptions = {
  label: string;
  value: number;
}[];
