// 对比类型
export enum CompareType {
  OBJECT = '1',
  HISTORY = '2',
}

export const CompareTypeDisplay = {
  [CompareType.OBJECT]: '对象对比',
  [CompareType.HISTORY]: '历史对比',
};

// 时间颗粒度
export enum StatisticsPartition {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  DAY = 'D',
  MONTH = 'M',
}

export const DatePickerTypeDisplay = {
  [StatisticsPartition.MINUTE_1]: '1分钟',
  [StatisticsPartition.MINUTE_15]: '15分钟',
  [StatisticsPartition.DAY]: '按日',
  [StatisticsPartition.MONTH]: '按月',
};

export enum DataType {
  IVFR = 101, // 瞬时体积流量
  IMFR = 102, // 瞬时质量流量
  AVFR = 103, // 累积体积流量
  AMFR = 104, // 累积质量流量
  PA = 105, // 压力
  T = 106, // 温度
  E = 107, // 电量
  POWER = 108, // 功率
  AHEAT = 109, // 累积热量
  D = 110, // 密度
  RH = 111, // 相对湿度
  AH = 112, // 绝对湿度
  LEVEL = 113, // 液位
  PURITY = 114, // 纯度
}

export enum DataAORIType {
  ACCUMULATE = 1, // 累积
  INSTANTANEOUS = 2, // 瞬时
}

export interface DataBtn {
  aggrby: StatisticsPartition;
}

export interface DataQuery {
  timeResolution: StatisticsPartition;
  from?: number;
  to?: number;
}

export interface ObjectSubmitParams {
  topoIdTop: number;
  topoIdBottom: number;
  indicatorIdTop: number;
  indicatorIdBottom: number;
  entryAndExitIdTop: [number, string];
  entryAndExitIdBottom: [number, string];
}
