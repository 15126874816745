import {
  BaseInfoContent,
  Button,
  Form,
  FormTitle,
  ShowInput,
  SubContent,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import React from 'react';
import styles from './index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import SingleDeviceContent from './components/SingleDeviceContent';
import CenterDataCompartion from '../components/CenterDataCompartion';
import { BoardType, NodeType } from '../type';
import { apiV2HvacNodeDetailPost } from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';

export type TabItem = {
  key: string;
  label: string;
  indicatorList?: number[];
};

const SingleDeviceBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [stationId, setStationId] = React.useState<number>();
  const [nodeName, setNodeName] = React.useState<string>();
  const [nodeType, setNodeType] = React.useState<number>();

  // 请求接口
  // tabs请求
  const { data: tabData, loading: tabDataLoading } = useRequest(
    async () => {
      const res = await apiV2HvacNodeDetailPost({ nodeId: Number(id) });

      form.setFieldsValue({
        nodeCode: res.code,
        ouCode: res?.ouCode,
        ouName: res?.ouName,
      });
      setStationId(res.stationId);
      setNodeName(res.name);
      setNodeType(res.type);
      // 挑出来cop放在数组首项
      const cop = (res?.indicatorEnums ?? []).filter(item => item.name === 'COP');
      const rest = (res?.indicatorEnums ?? []).filter(item => item.name !== 'COP');

      return [...cop, ...rest].map(item => {
        return {
          key: item.id!.toString(),
          label: item.name!,
          indicatorList: item.indicatorList,
        };
      });
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <FormTitle
        title={nodeName ?? '--'}
        extraContent={
          <Button onClick={() => navigate(`/production/manage/hvacBoard?stationId=${stationId}`)}>返回拓扑</Button>
        }
      />
      <SubContent>
        <Form form={form}>
          <BaseInfoContent colNum={3} layout="horizontal">
            <Form.Item name="nodeCode" label="节点编号">
              <ShowInput />
            </Form.Item>
            <Form.Item name="ouCode" label="运营单元编号">
              <ShowInput />
            </Form.Item>
            <Form.Item name="ouName" label="运营单元名称">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </SubContent>
      {Number(nodeType) === NodeType.CHILLER && (
        <SubContent>
          <CenterDataCompartion id={id} type={BoardType.NODE} />
        </SubContent>
      )}
      <SubContent>
        <SingleDeviceContent tabData={tabData} nodeId={id} />
      </SubContent>
    </Wrapper>
  );
};

export default SingleDeviceBoard;
