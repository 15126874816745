import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  SubContent,
  useBreadcrumbRoutes,
  useCurrent,
  useUpdate,
  Wrapper,
  Button,
  Empty,
  Table,
  Form,
  Modal,
  Dropdown,
  Tabs,
  Input,
  FormTitle,
  Tooltip,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space, Spin, Tree, Typography } from 'antd';
import { DataNode } from 'antd/lib/tree';
import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ResizeObserver from 'rc-resize-observer';
import { energyConsumptionColumns } from './Column';
import { AddOrRemoveType, mcidItem, UnitConfGroupSettingViewRecord } from '../../../api/energyConsumption';
import AddChildOrEditModal from './components/AddChildOrEditModal';
import AddOrRemoveEnergyConsEquipmentModal, {
  AddOrRemoveModalRef,
} from './components/AddOrRemoveEnergyConsEquipmentModal';
import EffectiveDateModal from './components/EffectiveDateModal';
import HistoryRecordModalContent from './components/HistoryRecordModalContent';
import MeterSettingModalContent from './components/MeterSettingModalContent';
import BSAModal, { BsaModalRef } from './components/PVOrEnergyStorageModal/BSAModal';
import PVModal, { PVModalRef } from './components/PVOrEnergyStorageModal/PVModal';
import styles from './index.module.scss';
import EnergyGroupMeterSettingModal from './EnergyGroupMeterSettingModal';
import EditEnergyConsumption from './EditEnergyConsumption';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import {
  V2EnergyAnalysisLinkedOuPagePostResponse,
  V2EnergyAnalysisUnitConfGroupChangedListPostResponse,
  V2EnergyAnalysisUnitConfGroupDetailPostResponse,
  apiV2EnergyAnalysisDetailPost,
  apiV2EnergyAnalysisLinkedOuPagePost,
  apiV2EnergyAnalysisUnitConfGroupChangedListPost,
  apiV2EnergyAnalysisUnitConfGroupDelPost,
  apiV2EnergyAnalysisUnitConfGroupDetailPost,
  apiV2EnergyAnalysisUnitConfGroupEffectMirrorDetailPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedBsaUpdatePost,
  apiV2EnergyAnalysisUnitConfGroupLinkedDeviceUpdatePost,
  apiV2EnergyAnalysisUnitConfGroupLinkedPvUpdatePost,
  apiV2EnergyAnalysisUnitConfGroupSettingSavePost,
  apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import debounce from 'lodash/debounce';

const formLayout = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 8 },
};

export interface CurrentDataProps {
  name: string; // 当前name
  currentId?: string; // 当前id
}

const routes = [
  {
    name: '用能单元配置',
  },
];

export type OuItem = Exclude<V2EnergyAnalysisLinkedOuPagePostResponse['list'], undefined>[number];

export type UnitConfGroupDetailEffectMirrorResponse = Omit<
  V2EnergyAnalysisUnitConfGroupDetailPostResponse,
  'isModified'
> | null;

const EnergyConsumptionConfig: FC = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshState, refresh] = useUpdate();
  const [urlSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Flag, setFlag] = useState<boolean>(false);
  const [expandedKeys, setExpandKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  // 记录是否是第一次进入的选中状态, 默认选中第一个节点后一直保持 true
  const initialSelected = useRef<boolean>(false);

  // 最上面详情的表单
  const [form] = Form.useForm();
  // 添加总分析组、子组、编辑的表单
  const [addEditform] = Form.useForm();

  // 用能单元详情
  // 草稿
  const [unitConfGroupDetail, setUnitConfGroupDetail] = useState<V2EnergyAnalysisUnitConfGroupDetailPostResponse>();
  // 当前生效
  const [unitConfGroupDetailEffectMirror, setUnitConfGroupDetailEffectMirror] =
    useState<UnitConfGroupDetailEffectMirrorResponse>();

  // 草稿deep
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deepUnitConfGroupDetail, setDeepUnitConfGroupDetail] =
    useState<V2EnergyAnalysisUnitConfGroupDetailPostResponse>();
  // 当前生效deep
  const [deepUnitConfGroupDetailEffectMirror, setDeepUnitConfGroupDetailEffectMirror] =
    useState<UnitConfGroupDetailEffectMirrorResponse>();

  const [unitConfGroupDetailActiveTab, setUnitConfGroupDetailActiveTab] = useState<string>('1');
  // 变更用能单元
  const [changedUnitConfGroupList, setChangedUnitConfGroupList] =
    useState<Required<V2EnergyAnalysisUnitConfGroupChangedListPostResponse>>();
  // 模态框
  const [addRootAnalysisGroupVisible, setAddRootAnalysisGroupVisible] = useState<boolean>(false); // 添加总分析组
  const [addChildModalVisible, setAddChildModalVisible] = useState<boolean>(false); // 添加子组
  const [addEnergyConsEquipmentModalVisible, setAddEnergyConsEquipmentModalVisible] = useState<boolean>(false); // 添加能耗设备
  const [needRemoveEquipmentModalVisible, setNeedRemoveEquipmentModalVisible] = useState<boolean>(false); // 添加需剔除的设备
  const [addPVStationModalVisible, setAddPVStationModalVisible] = useState<boolean>(false); // 添加光伏站
  const [addEnergyStorageModalVisible, setAddEnergyStorageModalVisible] = useState<boolean>(false); // 添加储能站
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false); // 编辑
  // 生效日期模态框
  const [showEffectiveDateModal, setShowEffectiveDateModal] = useState<boolean>(false);
  // 新增拓扑节点弹窗
  const [showTopologyNodeModal, setShowTopologyNodeModal] = useState<boolean>(false);

  // 用能单元历史记录模态框
  const [showHistoryRecordModal, setShowHistoryRecordModal] = useState<boolean>(false);
  // 用能单元计量设置模态框
  const [meterSettingDetails, setMeterSettingDetails] = useState<UnitConfGroupSettingViewRecord[]>([]);
  const [showMeterSettingModal, setShowMeterSettingModal] = useState<boolean>(false);

  const [mcidOptions, setMcidOptions] = useState<mcidItem[]>([]); // 组织树传下去

  // 当前用户租户等详情
  const current = useCurrent();
  const mcid = current?.tenant?.mcid;

  // currentInfo 为 { name, currentId }, 其中 name 和 currentId 分别为当前用能单元某个节点的 name 和 unitConfId
  // 如果是最顶上的添加一级用能单元的话为 undefined
  const [currentInfo, setCurrentInfo] = useState<CurrentDataProps>();

  // 存放分析组数据, 树结构
  const [list, setList] = useState<DataNode[]>([]);
  const [prevlist, setPrevList] = useState<DataNode[]>([]);

  // 存ou
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ouItems, setOuItems] = useState<OuItem[]>([]);
  const [width, setWidth] = useState<number>(500);

  // 存放对应 modal 中的 selectedRows, selectRowKeys 等数据
  const addOrRemoveRef = useRef<AddOrRemoveModalRef>(null);
  const bsaRef = useRef<BsaModalRef>(null);
  const pvRef = useRef<PVModalRef>(null);

  // 分析组总计量设置弹框
  const [showEnergyGroupMeterSettingModal, setShowEnergyGroupMeterSettingModal] = useState<boolean>(false);
  // 分析组计量设置权限
  const hasEnergyGroupMeterSettingPermission = useHasPermission(PermissionsType.B_ANALYSIS_GROUP_MEASUREMENT_SETTINGS);
  const hasTOPOLOGYNODEMANAGE = useHasPermission(PermissionsType.B_TOPOLOGYNODEMANAGE);
  const [searchValue, setSearchValue] = useState<string>('');
  const [groupList, setGroupList] = useState<any>();
  // 将每一个分析组节点进行 format
  let copyKey: any[] = [];

  const titleFormat = (name: string, id: number, useFlag: boolean, parentId: number, modifyStatus: number) => {
    const strTitle = name as string;
    const index = strTitle.indexOf(searchValue);
    const beforeStr = strTitle.substring(0, index);
    const afterStr = strTitle.slice(index + searchValue.length);
    if (name.includes(searchValue) && searchValue !== '') {
      const rootPath = findRootPath(prevlist, id);
      if (!isNil(rootPath)) {
        const concatData = copyKey.concat(rootPath);
        copyKey = concatData;
        setExpandKeys(concatData);
      }
    }
    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className={styles.siteTreeSearchValue}>{searchValue}</span>
          {afterStr}
        </span>
      ) : (
        <span>{strTitle}</span>
      );
    return (
      <div>
        {modifyStatus === 1 && (
          <Tooltip placement="top" title="存在待保存草稿">
            <div className={styles.titleBefore}></div>
          </Tooltip>
        )}
        {title}
        <Space className={styles.titleFormatIcon}>
          <>
            {/*添加子级*/}
            <Button
              type="link"
              onClick={() => {
                setCurrentInfo({
                  name,
                  currentId: String(id),
                });
                setAddChildModalVisible(true);
              }}
            >
              添加子级
            </Button>
            {/*编辑*/}
            <Button
              type="link"
              onClick={() => {
                setCurrentInfo({
                  name,
                  currentId: String(id),
                });
                setEditModalVisible(true);
              }}
            >
              编辑
            </Button>

            {/*删除*/}
            {useFlag && (
              <Button
                type="link"
                onClick={() => {
                  onDeleteRecordInUse(id);
                }}
              >
                删除
              </Button>
            )}
            {!useFlag && (
              <Button
                type="link"
                onClick={() => {
                  setCurrentInfo({
                    name,
                    currentId: String(id),
                  });
                  onDeleteRecord(id);
                }}
              >
                删除
              </Button>
            )}

            {/*添加能耗设备*/}
            <Button
              type="link"
              onClick={() => {
                setCurrentInfo({
                  name,
                  currentId: String(id),
                });
                setAddEnergyConsEquipmentModalVisible(true);
              }}
            >
              添加能耗设备
            </Button>
            <Dropdown
              trigger={['click']}
              type="link"
              menu={{
                items: [
                  {
                    key: 'removeEquipment',
                    label: (
                      // 需要剔除的设备
                      <Typography.Link
                        onClick={() => {
                          setCurrentInfo({
                            name,
                            currentId: String(id),
                          });
                          setNeedRemoveEquipmentModalVisible(true);
                        }}
                      >
                        剔除设备
                      </Typography.Link>
                    ),
                  },
                  {
                    key: 'pvStationGrid',
                    label: (
                      <Typography.Link
                        onClick={() => {
                          setCurrentInfo({
                            name,
                            currentId: String(id),
                          });
                          setAddPVStationModalVisible(true);
                        }}
                      >
                        添加光伏站并网点
                      </Typography.Link>
                    ),
                  },
                  {
                    key: 'energyStorage',
                    label: (
                      <Typography.Link
                        onClick={() => {
                          setCurrentInfo({
                            name,
                            currentId: String(id),
                          });
                          setAddEnergyStorageModalVisible(true);
                        }}
                      >
                        添加储能站
                      </Typography.Link>
                    ),
                  },
                ],
              }}
            >
              更多
            </Dropdown>
          </>
        </Space>
      </div>
    );
  };

  //  删除
  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: '删除后将清空单元历史配置，请确认删除？',
      okText: '删除',
      onOk() {
        apiV2EnergyAnalysisUnitConfGroupDelPost({ energyUnitGroupId: id })
          .then(res => {
            // message.success('删除成功');
            // 清空选中的节点, 因为该节点被删除
            setSelectedKeys([]);
            refresh();
          })
          .catch(err => {
            // message.error('删除失败', err)
          });
      },
      onCancel() {
        // console.error('Cancel');
      },
    });
  };

  const onDeleteRecordInUse = (id: number) => {
    Modal.confirm({
      content: `该用能单元或下游单元在抄表项目中，请移除相关配置后删除单元。`,
      okText: '确认',
    });
  };
  const formatModalTreeData = (data: any[]) => {
    return data.map(item => {
      const res: any = {
        value: item.id.toString(),
        title: item.name,
        children: item.childList ? formatModalTreeData(item.childList) : [],
      };
      return res;
    });
  };

  // format后端返回的树结构
  const formatTreeData = (data: any[]) => {
    return data.map(item => {
      const res: DataNode = {
        ...item,
        key: item.id ?? '',
        value: item.id,
        title: titleFormat(item.name, item.id, item?.useFlag, item.parentId, item.modifyStatus),
        children: item.childList ? formatTreeData(item.childList) : [],
      };
      return res;
    });
  };
  const findRootPath = (treeData: any[], nodeId: number, path: number[] = []): number[] | null => {
    for (const node of treeData) {
      if (node.childList && node.childList.length > 0) {
        for (const childNode of node.childList) {
          if (childNode.id === nodeId) {
            return [...path, node.id ?? nodeId]; // 返回找到的路径数组
          }
        }
        const rootPath = findRootPath(node.childList, nodeId, [...path, node.id ?? nodeId]); // 递归查找子节点的根路径
        if (rootPath !== null) {
          return rootPath;
        }
      }
    }
    return null; // 如果没有找到匹配的节点，则返回 null
  };
  const treeData = useMemo(() => {
    return formatTreeData(prevlist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, prevlist]);

  const modalTreeData = useMemo(() => {
    if (isNil(groupList)) return;
    return formatModalTreeData(groupList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  // 获取分析组list
  const getUnitConfGroupListList = () => {
    setLoading(true);

    apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost({ id: Number(id) }).then((res: any) => {
      const formatTreeList = formatTreeData(res?.list);
      setPrevList(formatTreeList);
      setGroupList(res?.list);
      setList(formatTreeList);
      setLoading(false);
    });
  };

  // 分析组树展开时的回调
  const onExpand = (expandedKeys: any[]) => {
    setExpandKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  // 分析组节点选中时的回调
  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    // setSelectedKeys(selectedKeysValue);
    setSelectedKeys([info.node.id]);
    //'2'是未保存草稿
    setUnitConfGroupDetailActiveTab(unitConfGroupDetail?.isModified === true ? '2' : '1');
  };

  // 添加子租/总分析组, 编辑时候模态框的确认按钮触发
  // const onFinish = (values: CurrentDataProps) => {
  //   if (addChildModalVisible) {
  //     // 添加子组
  //     addAnasGroup(values.name, currentInfo?.currentId);
  //   } else if (addRootAnalysisGroupVisible) {
  //     // 添加总分析组
  //     addAnasGroup(values.name);
  //   } else {
  //     // 编辑
  //     updateAnasGroup(values.name, currentInfo?.currentId!);
  //   }
  // };

  // 添加能耗设备或者添加需剔除设备接口调用
  const onOkAddOrRemove = debounce(() => {
    if (needRemoveEquipmentModalVisible) {
      // 需要剔除的设备
      apiV2EnergyAnalysisUnitConfGroupLinkedDeviceUpdatePost({
        energyUnitGroupId: currentInfo?.currentId!,
        deviceIdList: addOrRemoveRef.current ? addOrRemoveRef.current.selectedRowKeys : [],
        type: AddOrRemoveType.Remove,
        tenantMcid: mcid!,
        unitId: Number(id),
      }).then(res => {
        setNeedRemoveEquipmentModalVisible(false);
        refresh();
      });
    } else {
      // 添加能耗设备
      apiV2EnergyAnalysisUnitConfGroupLinkedDeviceUpdatePost({
        energyUnitGroupId: currentInfo?.currentId!,
        deviceIdList: addOrRemoveRef.current ? addOrRemoveRef.current.selectedRowKeys : [],
        type: AddOrRemoveType.Add,
        tenantMcid: mcid!,
        unitId: Number(id),
      }).then(res => {
        setAddEnergyConsEquipmentModalVisible(false);
        refresh();
      });
    }
  }, 300);

  // 添加光伏站和添加储能站接口调用
  const onOkBSA = () => {
    // 储能设备
    let params: { ouId: number; arrayId: number }[] = [];
    bsaRef.current?.selectedRows.map((item: { ouId: number; id: number }) => {
      params.push({
        ouId: item.ouId,
        arrayId: Number(item.id),
      });
      return item;
    });
    apiV2EnergyAnalysisUnitConfGroupLinkedBsaUpdatePost({
      unitConfGroupId: currentInfo?.currentId!,
      arrayLinkList: params,
    }).then(res => {
      setAddEnergyStorageModalVisible(false);
      refresh();
    });
  };

  const onOkPV = () => {
    // 光伏并网点
    let params: { ouId: number; arrayId: number }[] = [];
    pvRef.current?.selectedRows.map(item => {
      params.push({
        ouId: item.pvOuId,
        arrayId: Number(item.id),
      });
      return item;
    });
    apiV2EnergyAnalysisUnitConfGroupLinkedPvUpdatePost({
      unitConfGroupId: currentInfo?.currentId!,
      arrayLinkList: params,
    }).then(res => {
      setAddPVStationModalVisible(false);
      refresh();
    });
  };

  // 默认选中第一个节点
  useEffect(() => {
    if (!initialSelected.current && list.length) {
      setSelectedKeys([list[0].key]);
      initialSelected.current = true;
    }
  }, [list]);

  // 最上面的详情
  useEffect(() => {
    if (id) {
      setLoading(true);
      apiV2EnergyAnalysisDetailPost({ energyUnitId: id }).then(res => {
        setLoading(false);
        let { name, customerList, customerNameList } = res;
        form.setFieldsValue({ name: name ?? '--', mcidList: customerNameList ? customerNameList.join() : '--' });
        setMcidOptions(
          (customerList ?? []).map(i => ({
            mcid: i.mcid as string,
            name: i.name as string,
          }))
        );
      });
      apiV2EnergyAnalysisLinkedOuPagePost({ energyUnitId: id }).then(res => {
        setOuItems(res.list ?? []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 查询分析组list
  useEffect(() => {
    if (id) {
      getUnitConfGroupListList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refreshState]);

  // 用能单元详情待保存草稿
  useEffect(() => {
    if (selectedKeys.length) {
      apiV2EnergyAnalysisUnitConfGroupDetailPost({ energyUnitGroupId: selectedKeys?.[0] }).then(res => {
        setUnitConfGroupDetail(res);
        setDeepUnitConfGroupDetail(JSON.parse(JSON.stringify(res)));
        setUnitConfGroupDetailActiveTab(res.isModified === true ? '2' : '1');
      });
    }
  }, [selectedKeys, refreshState]);

  useEffect(() => {
    if (selectedKeys.length) {
      apiV2EnergyAnalysisUnitConfGroupEffectMirrorDetailPost({ energyUnitGroupId: selectedKeys?.[0] }).then(res => {
        setUnitConfGroupDetailEffectMirror(res);
        setDeepUnitConfGroupDetailEffectMirror(res ? JSON.parse(JSON.stringify(res)) : null);
      });
    }
  }, [selectedKeys, refreshState]);

  const onTopoModalCancel = () => {
    setMeterSettingDetails([]);
    setShowTopologyNodeModal(false);
    setDeepUnitConfGroupDetailEffectMirror(JSON.parse(JSON.stringify(unitConfGroupDetailEffectMirror)));
    setDeepUnitConfGroupDetail(JSON.parse(JSON.stringify(unitConfGroupDetail)));
  };
  // 变更用能单元
  useEffect(() => {
    apiV2EnergyAnalysisUnitConfGroupChangedListPost({ energyUnitId: Number(id!) }).then(res => {
      setChangedUnitConfGroupList(res as Required<V2EnergyAnalysisUnitConfGroupChangedListPostResponse>);
    });
  }, [id, refreshState]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form layout="horizontal" isOrigin form={form} {...formLayout}>
        <FormTitle title={routes[0].name} />
        <Spin spinning={loading}>
          <SubContent>
            <BaseInfoContent colNum={2} layout="horizontal" title="用能单元配置">
              <Form.Item name="name" label="名称">
                <ShowInput />
              </Form.Item>
              <Form.Item name="mcidList" label="所属组织">
                <ShowInput />
              </Form.Item>
            </BaseInfoContent>
          </SubContent>
          <div style={{ marginLeft: 32 }}>
            {hasEnergyGroupMeterSettingPermission && (
              <Button
                type="primary"
                onClick={() => {
                  setShowEnergyGroupMeterSettingModal(true);
                }}
              >
                分析组计量设置
              </Button>
            )}
          </div>

          {/* 变更用能单元 */}
          {changedUnitConfGroupList?.groupNameList?.length ? (
            <div style={{ marginLeft: '32px' }}>
              <Typography.Link
                onClick={() => {
                  Modal.info({
                    title: '变更用能单元',
                    content: changedUnitConfGroupList?.groupNameList.join('、'),
                  });
                }}
              >
                未来用能单元变更
              </Typography.Link>
            </div>
          ) : null}

          <Row gutter={24} style={{ marginTop: 8, marginBottom: '32px' }}>
            <Col span={12}>
              <div className={styles.section} style={{ marginLeft: '32px' }}>
                <div className={styles.sectionTop}>
                  <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddRootAnalysisGroupVisible(true)}>
                    添加一级用能单元
                  </Button>
                  <Input.Search
                    placeholder="请输入用能单元名称查询"
                    className={styles.searchInput}
                    onSearch={e => {
                      if (e !== searchValue) {
                        setExpandKeys([]);
                      }
                      setSearchValue(e);
                    }}
                  ></Input.Search>
                </div>

                {list && list.length > 0 ? (
                  <Tree
                    className={styles.energyUnitTree}
                    height={500}
                    showLine={{ showLeafIcon: false }}
                    treeData={treeData}
                    expandedKeys={expandedKeys}
                    selectedKeys={selectedKeys}
                    onExpand={onExpand}
                    onSelect={onSelect}
                    autoExpandParent={autoExpandParent}
                    style={{ marginTop: 20 }}
                  />
                ) : (
                  <Empty />
                )}
              </div>
            </Col>

            <Col span={12}>
              <div className={styles.section} style={{ marginRight: '32px' }}>
                {selectedKeys.length ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography.Text style={{ fontSize: 16 }}>用能单元详情</Typography.Text>
                      <Space size="middle">
                        {hasTOPOLOGYNODEMANAGE && (
                          <Typography.Link
                            style={{ fontSize: 16 }}
                            onClick={() => {
                              setShowTopologyNodeModal(true);
                              setFlag(true);
                            }}
                          >
                            拓扑节点管理
                          </Typography.Link>
                        )}
                        <Typography.Link
                          style={{ fontSize: 16 }}
                          onClick={() => {
                            setShowHistoryRecordModal(true);
                          }}
                        >
                          历史记录
                        </Typography.Link>
                        <Typography.Link
                          style={{ fontSize: 16 }}
                          onClick={() => {
                            setShowMeterSettingModal(true);
                          }}
                        >
                          计量设置
                        </Typography.Link>
                      </Space>
                    </div>

                    <Tabs
                      activeKey={unitConfGroupDetailActiveTab}
                      onChange={activeKey => {
                        setUnitConfGroupDetailActiveTab(activeKey);
                      }}
                      items={[
                        ...(unitConfGroupDetail?.isModified
                          ? [
                              {
                                label: '待保存草稿',
                                key: '2',
                                disabled: !unitConfGroupDetail?.isModified,
                                children: (
                                  <ResizeObserver
                                    onResize={({ width }) => {
                                      width !== 0 && setWidth(width);
                                    }}
                                  >
                                    {unitConfGroupDetail?.isModified ? (
                                      <Space style={{ width: '100%' }} direction="vertical" size="middle">
                                        <Space size="middle" className={styles.middleSpace}>
                                          <Typography.Text>用能单元</Typography.Text>
                                          <Typography.Text>
                                            {unitConfGroupDetail?.name ? unitConfGroupDetail?.name : '--'}
                                          </Typography.Text>
                                        </Space>
                                        {!unitConfGroupDetail?.energyConsumptionDeviceList?.length &&
                                        !unitConfGroupDetail?.rejectDeviceList?.length &&
                                        !unitConfGroupDetail?.gridDeviceList?.length &&
                                        !unitConfGroupDetail?.bsaDeviceList?.length ? (
                                          <Empty />
                                        ) : (
                                          <>
                                            {unitConfGroupDetail?.energyConsumptionDeviceList?.length ? (
                                              <Space direction="vertical">
                                                <Typography.Text>能耗设备111</Typography.Text>
                                                <Table
                                                  rowKey="id"
                                                  scroll={{ y: 600 }}
                                                  style={{ width }}
                                                  columns={energyConsumptionColumns}
                                                  dataSource={unitConfGroupDetail?.energyConsumptionDeviceList}
                                                />
                                              </Space>
                                            ) : null}
                                            {unitConfGroupDetail?.rejectDeviceList?.length ? (
                                              <Space direction="vertical">
                                                <Typography.Text>剔除设备</Typography.Text>
                                                <Table
                                                  rowKey="id"
                                                  scroll={{ y: 600 }}
                                                  style={{ width }}
                                                  columns={energyConsumptionColumns}
                                                  dataSource={unitConfGroupDetail?.rejectDeviceList}
                                                />
                                              </Space>
                                            ) : null}
                                            {unitConfGroupDetail?.gridDeviceList?.length ? (
                                              <Space direction="vertical">
                                                <Typography.Text>光伏站并网点</Typography.Text>
                                                <Table
                                                  rowKey="id"
                                                  scroll={{ y: 600 }}
                                                  style={{ width }}
                                                  columns={energyConsumptionColumns}
                                                  dataSource={unitConfGroupDetail?.gridDeviceList}
                                                />
                                              </Space>
                                            ) : null}
                                            {unitConfGroupDetail?.bsaDeviceList?.length ? (
                                              <Space direction="vertical" size="small">
                                                <Typography.Text>储能站</Typography.Text>
                                                {/* <Space wrap>
                                                {unitConfGroupDetail?.bsaDeviceList?.map(v => (
                                                  <Tag key={v.id}>{v.name}</Tag>
                                                ))}
                                              </Space> */}
                                                <Table
                                                  rowKey="id"
                                                  scroll={{ y: 600 }}
                                                  style={{ width }}
                                                  columns={energyConsumptionColumns}
                                                  dataSource={unitConfGroupDetail?.bsaDeviceList}
                                                />
                                              </Space>
                                            ) : null}
                                          </>
                                        )}
                                      </Space>
                                    ) : (
                                      <Empty />
                                    )}
                                  </ResizeObserver>
                                ),
                              },
                            ]
                          : []),
                        {
                          label: '当前生效',
                          key: '1',
                          children: (
                            <ResizeObserver
                              onResize={({ width }) => {
                                width !== 0 && setWidth(width);
                              }}
                            >
                              {unitConfGroupDetailEffectMirror ? (
                                <Space style={{ width: '100%' }} direction="vertical" size="middle">
                                  <Space size="middle" className={styles.middleSpace}>
                                    <Typography.Text>用能单元</Typography.Text>
                                    <Typography.Text>
                                      {unitConfGroupDetailEffectMirror?.name
                                        ? unitConfGroupDetailEffectMirror?.name
                                        : '--'}
                                    </Typography.Text>
                                  </Space>
                                  {!unitConfGroupDetailEffectMirror?.energyConsumptionDeviceList?.length &&
                                  !unitConfGroupDetailEffectMirror?.rejectDeviceList?.length &&
                                  !unitConfGroupDetailEffectMirror?.gridDeviceList?.length &&
                                  !unitConfGroupDetailEffectMirror?.bsaDeviceList?.length ? (
                                    <Empty />
                                  ) : (
                                    <>
                                      {unitConfGroupDetailEffectMirror?.energyConsumptionDeviceList?.length ? (
                                        <Space direction="vertical">
                                          <Typography.Text>能耗设备</Typography.Text>
                                          <Table
                                            scroll={{ y: 600 }}
                                            rowKey="id"
                                            style={{ width }}
                                            columns={energyConsumptionColumns}
                                            dataSource={unitConfGroupDetailEffectMirror?.energyConsumptionDeviceList}
                                          />
                                        </Space>
                                      ) : null}
                                      {unitConfGroupDetailEffectMirror?.rejectDeviceList?.length ? (
                                        <Space direction="vertical">
                                          <Typography.Text>剔除设备</Typography.Text>
                                          {/* <Space wrap>
                                            {unitConfGroupDetailEffectMirror?.rejectDeviceList?.map(v => (
                                              <Tag key={v.id}>{v.name}</Tag>
                                            ))}
                                          </Space> */}
                                          <Table
                                            rowKey="id"
                                            scroll={{ y: 600 }}
                                            style={{ width }}
                                            columns={energyConsumptionColumns}
                                            dataSource={unitConfGroupDetailEffectMirror?.rejectDeviceList}
                                          />
                                        </Space>
                                      ) : null}
                                      {unitConfGroupDetailEffectMirror?.gridDeviceList?.length ? (
                                        <Space direction="vertical">
                                          <Typography.Text>光伏站并网点</Typography.Text>
                                          <Table
                                            rowKey="id"
                                            scroll={{ y: 600 }}
                                            style={{ width }}
                                            columns={energyConsumptionColumns}
                                            dataSource={unitConfGroupDetailEffectMirror?.gridDeviceList}
                                          />
                                        </Space>
                                      ) : null}
                                      {unitConfGroupDetailEffectMirror?.bsaDeviceList?.length ? (
                                        <Space direction="vertical" size="small">
                                          <Typography.Text>储能站</Typography.Text>
                                          <Table
                                            rowKey="id"
                                            scroll={{ y: 600 }}
                                            style={{ width }}
                                            columns={energyConsumptionColumns}
                                            dataSource={unitConfGroupDetailEffectMirror?.bsaDeviceList}
                                          />
                                        </Space>
                                      ) : null}
                                    </>
                                  )}
                                </Space>
                              ) : (
                                <Empty />
                              )}
                            </ResizeObserver>
                          ),
                        },
                      ]}
                    ></Tabs>
                  </>
                ) : (
                  <Empty style={{ marginTop: '2vw' }} />
                )}
              </div>
            </Col>
          </Row>
        </Spin>
      </Form>
      <Space className="sticky-footer" style={{ paddingLeft: 32 }}>
        <Space size="small">
          {list.length ? (
            <Button
              type="primary"
              onClick={() => {
                setShowEffectiveDateModal(true);
              }}
            >
              保存
            </Button>
          ) : null}
          <Button onClick={() => navigate(`/energy/consumption/analysis?${urlSearchParams.toString()}`)}>返回</Button>
        </Space>
      </Space>

      {/* 添加总分析组、子组、编辑共用一个Modal框 */}
      {(addChildModalVisible || editModalVisible || addRootAnalysisGroupVisible) && (
        <AddChildOrEditModal
          addChildModalVisible={addChildModalVisible}
          editModalVisible={editModalVisible}
          addRootAnalysisGroupVisible={addRootAnalysisGroupVisible}
          unitId={id!}
          mcid={mcid!}
          visible={addChildModalVisible || editModalVisible || addRootAnalysisGroupVisible}
          isAdd={addChildModalVisible || addRootAnalysisGroupVisible}
          currentData={currentInfo}
          addEditform={addEditform}
          // onFinish={onFinish}
          onCancel={() => {
            setAddRootAnalysisGroupVisible(false);
            setAddChildModalVisible(false);
            setEditModalVisible(false);
          }}
          refresh={refresh}
        />
      )}

      {/* 添加能耗  添加需剔除的设备*/}
      {(addEnergyConsEquipmentModalVisible || needRemoveEquipmentModalVisible) && (
        <AddOrRemoveEnergyConsEquipmentModal
          ref={addOrRemoveRef}
          onOk={onOkAddOrRemove}
          onCancel={() => {
            setAddEnergyConsEquipmentModalVisible(false);
            setNeedRemoveEquipmentModalVisible(false);
          }}
          mcidOptions={mcidOptions}
          visible={addEnergyConsEquipmentModalVisible || needRemoveEquipmentModalVisible}
          isAdd={addEnergyConsEquipmentModalVisible}
          id={currentInfo?.currentId}
          unitId={id}
        />
      )}

      {/* 储能站 */}
      {addEnergyStorageModalVisible && (
        <BSAModal
          ref={bsaRef}
          // ouItems={ouItems}
          onOk={onOkBSA}
          onCancel={() => {
            setAddEnergyStorageModalVisible(false);
          }}
          visible={addEnergyStorageModalVisible}
          unitConfGroupId={currentInfo?.currentId}
          unitId={id!}
        />
      )}

      {/* 光伏站并网点 */}
      {addPVStationModalVisible && (
        <PVModal
          ref={pvRef}
          onOk={onOkPV}
          onCancel={() => {
            setAddPVStationModalVisible(false);
          }}
          visible={addPVStationModalVisible}
          unitConfGroupId={currentInfo?.currentId}
          unitId={id!}
        />
      )}

      {/* 生效日期模态框 */}
      <EffectiveDateModal
        unitId={id!}
        open={showEffectiveDateModal}
        closeModal={() => {
          setShowEffectiveDateModal(false);
        }}
        refresh={refresh}
        treeData={modalTreeData}
        changedUnitConfGroupListDate={
          changedUnitConfGroupList?.groupNameList?.length
            ? dayjs(changedUnitConfGroupList?.date).format('YYYY-MM-DD')
            : undefined
        }
        setUnitConfGroupDetailActiveTab={setUnitConfGroupDetailActiveTab}
      />

      {/* 用能单元历史记录*/}
      <Modal
        destroyOnClose
        size="big"
        title="用能单元历史记录"
        open={showHistoryRecordModal}
        onOk={() => {
          setShowHistoryRecordModal(false);
        }}
        onCancel={() => {
          setShowHistoryRecordModal(false);
        }}
        bodyScroll={true}
      >
        <HistoryRecordModalContent confGroupId={selectedKeys[0]?.toString()} unitId={id!} />
      </Modal>

      {/* 用能单元计量设置 */}
      {showMeterSettingModal && (
        <Modal
          destroyOnClose
          size="big"
          title="用能计量设置"
          open={showMeterSettingModal}
          okText="保存"
          bodyScroll={true}
          onOk={() => {
            apiV2EnergyAnalysisUnitConfGroupSettingSavePost({
              settings: meterSettingDetails.map(d => ({
                id: d.id,
                meterAttributeId: d.meterAttributeId as number,
                meterTypeId: d.meterTypeId as number,
                type: d.type,
              })),
            })
              .then(res => {
                Modal.success({
                  content: '保存成功',
                  onOk: () => {
                    refresh();
                  },
                });
              })
              .catch(error => {
                Modal.error({
                  content: '保存失败',
                });
              })
              .finally(() => {
                setShowMeterSettingModal(false);
              });
          }}
          onCancel={() => {
            setMeterSettingDetails([]);
            setShowMeterSettingModal(false);
          }}
        >
          <MeterSettingModalContent
            meterSettingDetails={meterSettingDetails}
            setMeterSettingDetails={setMeterSettingDetails}
            confGroupId={selectedKeys[0]?.toString()}
            energyGroupId={id!}
          />
        </Modal>
      )}

      {showEnergyGroupMeterSettingModal && (
        <EnergyGroupMeterSettingModal
          open={showEnergyGroupMeterSettingModal}
          closeModal={() => {
            setShowEnergyGroupMeterSettingModal(false);
          }}
          energyGroupId={id!}
        />
      )}

      {showTopologyNodeModal && (
        <EditEnergyConsumption
          open={showTopologyNodeModal}
          onCancel={onTopoModalCancel}
          onOk={() => {
            setShowTopologyNodeModal(false);
            refresh();
          }}
          unitConfGroupDetailEffectMirror={deepUnitConfGroupDetailEffectMirror}
        />
      )}
    </Wrapper>
  );
};

export default EnergyConsumptionConfig;
