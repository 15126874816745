import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Form,
  PopConfirm,
  Select,
  Table,
  Tag,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { ListTypes, Status, StatusDisplay } from '@/pages/FillingRules/List/listTypes';
import styles from './index.module.scss';
import {
  apiV2CarbonEmissionAutoFillingRuleDeletePost,
  apiV2CarbonEmissionAutoFillingRuleDisablePost,
  apiV2CarbonEmissionAutoFillingRuleEnablePost,
  apiV2CarbonEmissionAutoFillingRulePagePost,
  V2CarbonEmissionAutoFillingRulePagePostResponse,
  apiV2CarbonAccountingUnitListPost,
  V2CarbonEmissionAutoFillingRulePagePostRequest,
} from '@maxtropy/device-customer-apis-v2';

const otherColumns = [
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '排放过程',
    dataIndex: 'emissionProcessName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '核算单元',
    dataIndex: 'accountingUnitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: Status) => {
      const tagColor = v === Status.ENABLE ? 'success' : 'default';
      return (
        <Tag color={tagColor} border="solid">
          {StatusDisplay[v]}
        </Tag>
      );
    },
  },
];

interface Props {}
const FillingRules: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [state, update] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const { data: accountingUnit } = useRequest(async () => {
    const res = await apiV2CarbonAccountingUnitListPost({});
    return res.list;
  });

  const [searchParams, setSearchParams] = useState<V2CarbonEmissionAutoFillingRulePagePostRequest>();
  const [list, setList] = useState<V2CarbonEmissionAutoFillingRulePagePostResponse['list']>([]);

  const onFinish = (values: V2CarbonEmissionAutoFillingRulePagePostRequest) => {
    const filteredData = Object.fromEntries(Object.entries(values).filter(([_, v]) => v !== undefined && v !== ''));
    setSearchParams(filteredData);
    setPageOffset(1);
  };

  const accountingUnitOptions = useMemo(() => {
    if (accountingUnit?.length !== 0) {
      return accountingUnit?.map(item => {
        return {
          label: item.unitName,
          value: item.id,
        };
      });
    }
  }, [accountingUnit]);

  const onReset = () => {
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const handleDelete = async (id: number | undefined) => {
    if (!id) return;
    try {
      await apiV2CarbonEmissionAutoFillingRuleDeletePost({ id: id.toString() });
    } finally {
      update();
    }
  };

  const handelChangeStatus = async (status: Status, id: number) => {
    try {
      status === Status.ENABLE
        ? await apiV2CarbonEmissionAutoFillingRuleDisablePost({ id: id.toString() })
        : await apiV2CarbonEmissionAutoFillingRuleEnablePost({ id: id.toString() });
    } finally {
      update();
    }
  };

  const { loading } = useRequest(
    () =>
      apiV2CarbonEmissionAutoFillingRulePagePost({
        page: String(pageOffset),
        size: String(pageSize),
        ...searchParams,
      }),
    {
      onSuccess: res => {
        setList(res.list);
        setTotalCount(res.total as number);
      },
      refreshDeps: [pageOffset, pageSize, searchParams, state, setTotalCount],
    }
  );

  const jumpToCreatePage = () => {
    navigate('/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement/create');
  };

  const jumpToEditPage = (id: number | undefined) => {
    if (!id) return;
    navigate(`/corporateCarbonInvestigation/setUp/automaticReportingRuleManagement/edit/${id}`);
  };

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      width: 150,
      fixed: 'right' as const,
      render: (record: ListTypes) => {
        const state = record.state as Status;
        const changeStatusTitle = state === Status.ENABLE ? '禁用' : '启用';
        return (
          <>
            <Space size={16}>
              <PopConfirm
                title={`你是否${changeStatusTitle}此规则？`}
                onConfirm={() => handelChangeStatus(record.state as Status, record.id as number)}
              >
                <Button type="link">{changeStatusTitle}</Button>
              </PopConfirm>

              <Button type="link" onClick={() => jumpToEditPage(record.id)}>
                编辑
              </Button>

              <PopConfirm title="您是否删除此规则" onConfirm={() => handleDelete(record.id)}>
                <Button type="link">删除</Button>
              </PopConfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const filters = (
    <>
      <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
        <Form.Item label="核算单元" name="accountingUnitId">
          <Select placeholder="全部核算单元" options={accountingUnitOptions} />
        </Form.Item>
        <Form.Item label="状态" name="state">
          <Select
            placeholder="全部"
            options={Object.entries(StatusDisplay).map(([k, v]) => ({
              label: v,
              value: k,
            }))}
          />
        </Form.Item>
      </CustomFilter>
    </>
  );

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes ?? []} filters={filters} className="page_wrapper">
        <Button type="primary" icon={<PlusOutlined />} onClick={jumpToCreatePage}>
          新建规则
        </Button>

        <Table className={styles.table} rowKey="id" columns={columns} dataSource={list} loading={loading} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
    </>
  );
};

export default FillingRules;
