import React, { useCallback, useMemo } from 'react';
import { Divider, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreadcrumbRoutes, Wrapper, Button, SubContent } from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import { getTemplateDetail, DeviceField } from '../../api/template';
import AttributeItem from '@/shared/components/AttributeItem';
import { AttributeField } from '@/shared/types';
import styles from './index.module.scss';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';

function formatField(fields: Array<DeviceField>): Array<AttributeField> {
  return fields.map(field => ({
    id: field.id,
    name: field.name,
    type: field.type,
    inputType: field.inputType,
    constraintValue: field.constraintValue,
    physicalUnits: field.physicalUnits?.map(unit => ({
      id: unit.id,
      generalName: unit.generalName,
      chineseName: unit.chineseName,
      k: unit.k,
      b: unit.b,
      isBasic: unit.isBasic,
    })),
  }));
}

const routes = [{ name: '模板详情' }];

const TemplateDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data } = useQuery(
    useCallback(() => {
      if (id) return getTemplateDetail(Number(id));
      return Promise.resolve(undefined);
    }, [id])
  );

  const template = data?.template;

  const customAttributes = useMemo(
    () => (data?.template.description ? JSON.parse(data.template.description) : []),
    [data]
  );

  return (
    <Wrapper
      routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}
      filters={
        <div className={styles.detail}>
          <Space className={styles.inline} size={60}>
            <div className={styles.field}>
              <div className={styles.label}>模板名称：</div>
              <div className={styles.value}>{template?.name || '-'}</div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>所属类目：</div>
              <div className={styles.value}>{template?.deviceTypeName || '-'}</div>
            </div>
            <div className={styles.field}>
              <div className={styles.label}>版本号：</div>
              <div className={styles.value}>
                <div className={styles.border}>{template?.lastVersion || '-'}</div>
              </div>
            </div>
          </Space>
          <div className={styles.field}>
            <div className={styles.label}>模板备注：</div>
            <div className={styles.textArea}>{template?.remark || '-'}</div>
          </div>
        </div>
      }
    >
      <SubContent className="mb-8">
        <div className={styles.attributeDetail}>
          {template ? (
            template.deviceAttributes.map((item, index) => (
              <AttributeItem
                disabled
                className={styles.attributeItem}
                attribute={{
                  id: 1,
                  name: item.name,
                  serialNumber: '1',
                  deviceFields: formatField(item.deviceFields),
                }}
              />
            ))
          ) : (
            <div />
          )}
        </div>
      </SubContent>

      {!!template?.deviceAttributes.length && <Divider />}
      <SubContent>
        <CustomAttributesDisplay customAttributes={customAttributes} />
      </SubContent>

      <Space className="sticky-footer">
        <Button onClick={() => navigate(-1)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default TemplateDetail;
