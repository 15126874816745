import React, { useEffect, useState } from 'react';
import { Table, EllipsisSpan, Modal, Button, Select, Empty } from '@maxtropy/components';
import { Space, Typography, Form } from 'antd';
import { editEnergyConsumptionColumns, UetTopologyNodeInfoType, DeviceListType } from './Column';
import styles from './index.module.scss';
import {
  apiV2EnergyUnitGetTopologyInfoPost,
  apiV2EnergyUnitSaveTopologyInfoPost,
} from '@maxtropy/device-customer-apis-v2';
import { TopologyTypeFormat, TopologyType, SimpleTopologyTypeFormat, SimpleTopologyType } from './EditEnergy';
import { isNil } from 'lodash-es';
import { UnitConfGroupDetailEffectMirrorResponse } from '.';
type EditEnergyConsumptionProps = {
  open: boolean;
  unitConfGroupDetailEffectMirror?: UnitConfGroupDetailEffectMirrorResponse;
  onOk?: () => void;
  onCancel?: () => void;
};

interface UetTopologyNodeInfo {
  uetId?: number;
  uetTopologyType?: number;
  uetTopologyNodeId?: number;
  uetTopologyRootNodeId?: number;
  uetTopologyNodeType?: any;
  uetTopologyNodeName?: string;
  topologyName?: string;
}

interface OptionItem {
  [x: string]: any;
  label: string;
  value: number;
}

export type MapList = Record<number, OptionItem[]>;
export type uetList = Record<number, UetTopologyNodeInfo[]>;

export type DeviceList = {
  deviceId?: number;
  deviceTypeId?: number;
  deviceTypeName?: string;
  firstClassTypeId?: number;
  id?: number;
  meterAttributeId?: number;
  meterAttributeName?: string;
  meterTypeId?: number;
  meterTypeName?: string;
  meterTypeParentId?: number;
  meterTypeParentName?: string;
  name?: string;
  staffHasOuAuthority?: any;
  type?: number;
  uetId?: number;
  uetTopologyNodeId?: number;
  uetTopologyNodeName?: string;
  uetTopologyNodeType?: any;
  uetTopologyRootNodeId?: number;
  uetTopologyType?: number;
}[];

export default function EditEnergyConsumption(props: EditEnergyConsumptionProps) {
  const {
    open,
    unitConfGroupDetailEffectMirror, //当前生效
    onCancel,
    onOk,
  } = props;
  const [Editform] = Form.useForm();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [energyListTable, setEnergyListTable] = useState<DeviceListType[]>([]);
  const [rejectDeviceTable, setRejectDeviceTable] = useState<DeviceListType[]>([]);
  const [gridDeviceTable, setGridDeviceTable] = useState<DeviceListType[]>([]);
  const [bsaDeviceListTable, setBsaDeviceListTable] = useState<DeviceListType[]>([]);
  const [TopologyTypeMap, setTopologyTypeMap] = useState<MapList>({}); //对应拓扑类型下拉框
  const [TopologyNameListCurrentMap, setTopologyNameListCurrentMap] = useState<MapList>({}); //对应节点下拉框
  const [firstFlag, setFirstFlag] = useState<boolean>(true);
  const [girdFlag, setGridFlag] = useState<boolean>(true);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    const extracted = unitConfGroupDetailEffectMirror;
    //能源
    setEnergyListTable(extracted?.energyConsumptionDeviceList || []);
    //剔除
    setRejectDeviceTable(extracted?.rejectDeviceList || []);
    //光伏
    setGridDeviceTable(extracted?.gridDeviceList || []);
    //储能
    setBsaDeviceListTable(extracted?.bsaDeviceList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //光伏和储能拓接口的table
  useEffect(() => {
    if (girdFlag) {
      if (gridDeviceTable.length) {
        const energyUnitTopologyInfos = gridDeviceTable.map((item: any) => ({
          deviceId: item.deviceId,
          energyUnitDeviceType: item.type,
        }));
        apiV2EnergyUnitGetTopologyInfoPost({ energyUnitTopologyInfos }).then(res => {
          const topologyInfos = res?.list ?? [];
          const newGridListTable = gridDeviceTable.map((i, index) => {
            const nodeItem = topologyInfos.find(k =>
              k.uetTopologyNodeInfo?.some(m => m?.uetTopologyNodeId === i.deviceId)
            );
            if (nodeItem) {
              const nodeInfo = nodeItem.uetTopologyNodeInfo?.find(n => n.uetTopologyNodeId === i.deviceId);
              if (nodeInfo) {
                return {
                  ...i,
                  uetTopologyType: nodeInfo?.uetTopologyType,
                  uetTopologyRootNodeId: nodeInfo?.uetTopologyRootNodeId,
                  uetTopologyNodeType: nodeInfo?.uetTopologyNodeType,
                  uetTopologyNodeName: nodeInfo?.uetTopologyNodeName,
                  uetTopologyNodeId: nodeInfo?.uetTopologyNodeId,
                  uetId: nodeInfo?.uetId,
                };
              }
              return i;
            }
            return i;
          });

          setGridDeviceTable(newGridListTable);
          setGridFlag(false);
        });
      }

      if (bsaDeviceListTable.length) {
        const energyUnitTopologyInfos = bsaDeviceListTable.map((item: any) => ({
          deviceId: item.deviceId,
          energyUnitDeviceType: item.type,
        }));
        apiV2EnergyUnitGetTopologyInfoPost({ energyUnitTopologyInfos }).then(res => {
          const topologyInfos = res?.list ?? [];
          const newBsaListTable = gridDeviceTable.map((i, index) => {
            const nodeItem = topologyInfos.find(k =>
              k.uetTopologyNodeInfo?.some(m => m?.uetTopologyNodeId === i.deviceId)
            );
            if (nodeItem) {
              const nodeInfo = nodeItem.uetTopologyNodeInfo?.find(n => n.uetTopologyNodeId === i.deviceId);
              if (nodeInfo) {
                return {
                  ...i,
                  uetTopologyType: nodeInfo?.uetTopologyType,
                  uetTopologyRootNodeId: nodeInfo?.uetTopologyRootNodeId,
                  uetTopologyNodeType: nodeInfo?.uetTopologyNodeType,
                  uetTopologyNodeName: nodeInfo?.uetTopologyNodeName,
                  uetTopologyNodeId: nodeInfo?.uetTopologyNodeId,
                  uetId: nodeInfo?.uetId,
                };
              }
              return i;
            }
            return i;
          });

          setBsaDeviceListTable(newBsaListTable);
          setGridFlag(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bsaDeviceListTable, gridDeviceTable]);

  //能耗和剔除设备接口的table
  useEffect(() => {
    if (firstFlag) {
      if (energyListTable.length) {
        apiV2EnergyUnitGetTopologyInfoPost({
          energyUnitTopologyInfos: energyListTable.map((item: any) => ({
            deviceId: item.deviceId,
            energyUnitDeviceType: item.type,
          })),
        }).then(res => {
          const newEnergyListTable = getSelectOptions(energyListTable, res?.list);
          newEnergyListTable.forEach((i, index) => {
            Editform?.setFieldValue(['energy', index, 'newUetTopologyType'], i.newUetTopologyType);
            Editform?.setFieldValue(['energy', index, 'uetTopologyNodeName'], i.uetTopologyNodeName);
          });

          setEnergyListTable(newEnergyListTable);
          setFirstFlag(false);
        });
      }
      if (rejectDeviceTable.length) {
        const energyUnitTopologyInfos = rejectDeviceTable.map((item: any) => ({
          deviceId: item.deviceId,
          energyUnitDeviceType: item.type,
        }));
        apiV2EnergyUnitGetTopologyInfoPost({ energyUnitTopologyInfos }).then(res => {
          const newEnergyListTable = getSelectOptions(rejectDeviceTable, res?.list);
          setTimeout(() => {
            newEnergyListTable.forEach((i, index) => {
              Editform?.setFieldValue(['reject', index, 'newUetTopologyType'], i.newUetTopologyType);
              Editform?.setFieldValue(['reject', index, 'uetTopologyNodeName'], i.uetTopologyNodeName);
            });

            setRejectDeviceTable(newEnergyListTable);
          }, 0);
          setFirstFlag(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyListTable, rejectDeviceTable]);

  const uniqueTopologyType = (arr: any[], valueName: string) => {
    const res = new Map();
    return arr.filter(item => !res.has(item[valueName]) && res.set(item[valueName], 1));
  };

  // 获取表格中下拉框选项
  const getSelectOptions = (listTable: DeviceListType[], resList?: any[]) => {
    const topologyTypeMap: MapList = {};
    const topologyNameListMap: MapList = {};
    const newEnergyListTable = listTable.map(k => {
      if (isNil(k.uetTopologyType) || isNil(k?.topologyName)) {
        return { ...k, newUetTopologyType: null };
      }
      return {
        ...k,
        newUetTopologyType: k.uetTopologyType + '-' + k.topologyName,
      };
    });
    const topologyInfos = resList ?? [];

    topologyInfos.forEach((item, index: number) => {
      item.uetTopologyNodeInfo = (item?.uetTopologyNodeInfo ?? []).map((k: UetTopologyNodeInfo) => {
        if (isNil(k.uetTopologyType) || isNil(k?.topologyName)) {
          return { ...k, newUetTopologyType: null };
        }
        return {
          ...k,
          newUetTopologyType: k.uetTopologyType + '-' + k.topologyName,
        };
      });
      const options = (item?.uetTopologyNodeInfo ?? []).map((i: any) => ({
        label: SimpleTopologyTypeFormat[i.uetTopologyType as SimpleTopologyType] + '-' + i.topologyName,
        value: i.uetTopologyType + '-' + i.topologyName,
        newUetTopologyType: i.uetTopologyType + '-' + i.topologyName,
        ...i,
      }));
      const topologyTypeOptions = uniqueTopologyType(options, 'value');

      const topologyNameOption = (item?.uetTopologyNodeInfo ?? []).map((i: UetTopologyNodeInfoType) => ({
        label: i.uetTopologyNodeName!,
        value: i.uetTopologyNodeId!,
        newUetTopologyType: i.uetTopologyType + '-' + i.topologyName,
        ...i,
      }));
      const tableIndex = newEnergyListTable.findIndex(k => k.deviceId === item.deviceId);
      if (tableIndex > -1) {
        topologyTypeMap[newEnergyListTable[tableIndex].id!] = topologyTypeOptions;
        topologyNameListMap[newEnergyListTable[tableIndex].id!] = topologyNameOption;

        // 默认选中第一条
        if (isNil(newEnergyListTable[tableIndex].uetTopologyNodeName)) {
          newEnergyListTable[tableIndex] = {
            ...newEnergyListTable[tableIndex],
            ...item?.uetTopologyNodeInfo?.[0],
          };
        }
      }
    });

    setTopologyTypeMap(topologyTypeMap);
    setTopologyNameListCurrentMap(topologyNameListMap);
    return newEnergyListTable;
  };

  // 能源设备和剔除设备colums
  const getEnergyColumns = (tableType: string) => {
    return [
      ...editEnergyConsumptionColumns,
      {
        title: '对应拓扑类型',
        width: 240,
        dataIndex: 'newUetTopologyType',
        ellipsis: { showTitle: true },
        render: (v: number, record: any, index: number) => {
          return (
            <Form.Item name={[tableType, index, 'newUetTopologyType']} style={{ marginBottom: 0 }}>
              <Select
                showSearch
                placeholder="请选择"
                style={{ width: '100%' }}
                optionFilterProp="label"
                onChange={value => {
                  const emptyNodeItem = {
                    uetTopologyType: undefined,
                    uetTopologyRootNodeId: undefined,
                    uetTopologyNodeType: undefined,
                    uetTopologyNodeName: undefined,
                    uetTopologyNodeId: undefined,
                    newUetTopologyType: value,
                    uetId: undefined,
                  };
                  const tableData = energyListTable.map((i: DeviceListType) => {
                    return i.id === record.id ? { ...i, ...emptyNodeItem } : i;
                  });
                  if (tableType === 'energy') {
                    setEnergyListTable([...tableData]);
                  }
                  if (tableType === 'reject') {
                    setRejectDeviceTable([...tableData]);
                  }

                  Editform?.setFieldValue([tableType, index, 'uetTopologyNodeName'], undefined);
                }}
                options={TopologyTypeMap[record.id]}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '对应节点',
        width: 260,
        dataIndex: 'uetTopologyNodeName',
        ellipsis: { showTitle: true },
        render: (v: string, record: any, index: number) => {
          return (
            <Form.Item dependencies={[[tableType, index, 'newUetTopologyType']]} noStyle>
              {({ getFieldValue }) => {
                const togoType = getFieldValue([tableType, index, 'newUetTopologyType']);
                const togoNodeOptions = (TopologyNameListCurrentMap[record.id] ?? []).filter(
                  k => k.newUetTopologyType === togoType
                );

                return (
                  <Form.Item name={[tableType, index, 'uetTopologyNodeName']} style={{ marginBottom: 0 }}>
                    <Select
                      showSearch
                      placeholder="请选择对应节点"
                      optionFilterProp="label"
                      onChange={value => {
                        const togoNodeItem = togoNodeOptions.find(k => k.uetTopologyNodeId === value);
                        const tableData = energyListTable.map((value: DeviceListType, tabIndex: number) => {
                          if (index === tabIndex) {
                            return {
                              ...value,
                              uetTopologyType: togoNodeItem?.uetTopologyType,
                              uetTopologyRootNodeId: togoNodeItem?.uetTopologyRootNodeId,
                              uetTopologyNodeType: togoNodeItem?.uetTopologyNodeType,
                              uetTopologyNodeName: togoNodeItem?.uetTopologyNodeName,
                              uetTopologyNodeId: togoNodeItem?.uetTopologyNodeId,
                              uetId: togoNodeItem?.uetId,
                            };
                          }
                          return { ...value };
                        });
                        if (tableType === 'energy') {
                          setEnergyListTable([...tableData]);
                        }
                        if (tableType === 'reject') {
                          setRejectDeviceTable([...tableData]);
                        }
                      }}
                      options={togoNodeOptions}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          );
        },
      },
    ];
  };

  //光伏和储能columns
  const girdComlunms = [
    ...editEnergyConsumptionColumns,
    {
      title: '对应拓扑类型',
      width: 180,
      dataIndex: 'uetTopologyType',
      ellipsis: { showTitle: true },
      render: (v: TopologyType) => <EllipsisSpan value={TopologyTypeFormat[v]} />,
    },
    {
      title: '对应节点',
      width: 260,
      dataIndex: 'uetTopologyNodeName',
      ellipsis: { showTitle: true },
    },
  ];

  const onEditEnergy = async () => {
    const energyAndRejectData = energyListTable.concat(rejectDeviceTable, gridDeviceTable, bsaDeviceListTable);
    Editform.validateFields().then(res => {
      const linkInfo = energyAndRejectData.map((obj: any) => {
        return {
          linkId: obj.id,
          type: obj.type,
          uetId: obj.uetId,
          uetTopologyType: obj.uetTopologyType,
          uetTopologyNodeId: obj.uetTopologyNodeId,
          uetTopologyRootNodeId: obj.uetTopologyRootNodeId,
          uetTopologyNodeType: obj.uetTopologyNodeType,
          uetTopologyNodeName: obj.uetTopologyNodeName,
        };
      });
      apiV2EnergyUnitSaveTopologyInfoPost({ linkInfo }).then(res => {
        setOpenModal(false);
        onOk?.();
      });
    });
  };

  return (
    <Modal
      destroyOnClose
      size="big"
      maskClosable={false}
      title="拓扑节点管理"
      open={openModal}
      footer={[
        <Button onClick={() => onCancel?.()} style={{ marginRight: 8 }}>
          取消
        </Button>,
        <Button htmlType="submit" type="primary" onClick={onEditEnergy}>
          保存
        </Button>,
      ]}
      onCancel={() => onCancel?.()}
    >
      <Form form={Editform} layout="vertical" labelCol={{ flex: '110px' }} labelAlign="left">
        <Space style={{ width: '100%' }} direction="vertical" size="middle">
          <Space size="middle" className={styles.middleSpace}>
            <Typography.Text>用能单元</Typography.Text>
            <Typography.Text>
              {unitConfGroupDetailEffectMirror?.name ? unitConfGroupDetailEffectMirror?.name : '--'}
            </Typography.Text>
          </Space>
          {!unitConfGroupDetailEffectMirror?.energyConsumptionDeviceList?.length &&
          !unitConfGroupDetailEffectMirror?.rejectDeviceList?.length &&
          !unitConfGroupDetailEffectMirror?.gridDeviceList?.length &&
          !unitConfGroupDetailEffectMirror?.bsaDeviceList?.length ? (
            <Empty />
          ) : (
            <>
              {unitConfGroupDetailEffectMirror?.energyConsumptionDeviceList?.length ? (
                <Space direction="vertical">
                  <Typography.Text>能耗设备</Typography.Text>
                  <Table rowKey="id" columns={getEnergyColumns('energy')} dataSource={energyListTable} />
                </Space>
              ) : null}
              {unitConfGroupDetailEffectMirror?.rejectDeviceList?.length ? (
                <Space direction="vertical">
                  <Typography.Text>剔除设备</Typography.Text>
                  <Table rowKey="id" columns={getEnergyColumns('reject')} dataSource={rejectDeviceTable} />
                </Space>
              ) : null}
              {unitConfGroupDetailEffectMirror?.gridDeviceList?.length ? (
                <Space direction="vertical">
                  <Typography.Text>光伏站并网点</Typography.Text>
                  <Table rowKey="id" columns={girdComlunms} dataSource={gridDeviceTable} />
                </Space>
              ) : null}
              {unitConfGroupDetailEffectMirror?.bsaDeviceList?.length ? (
                <Space direction="vertical" size="small">
                  <Typography.Text>储能站</Typography.Text>
                  <Table rowKey="id" columns={girdComlunms} dataSource={bsaDeviceListTable} />
                </Space>
              ) : null}
            </>
          )}
        </Space>
      </Form>
    </Modal>
  );
}
