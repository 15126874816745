import React, { FC, useEffect, useMemo, useState } from 'react';
import { Row, Col, Spin, Divider } from 'antd';
import { OuItem } from '../../../api/energyConsumption';
import { Form, Input, Modal, Select, useBreadcrumbRoutes } from '@maxtropy/components';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import EnergyCarousel from '../EnergyCarousel';
import { apiV2EnergyAnalysisCreatePost, apiV2OuPagePost } from '@maxtropy/device-customer-apis-v2';
import { V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse } from '@maxtropy/device-customer-apis-v2';
import { useWatch } from 'antd/es/form/Form';

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: nodeItem = {
      key: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};
interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}
interface nodeItem extends flattenNode {
  children: nodeItem[];
}

const flattenTreeData = (data: nodeItem[]) => {
  let treeList: nodeItem[] = [];
  let flatten = function (data: nodeItem[]) {
    for (let i = 0; i < data.length; i++) {
      treeList.push(data[i]);
      if (data[i].children.length) {
        flatten(data[i].children);
      }
    }
  };
  flatten(data);
  return treeList;
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface Params {
  createModalOpen: boolean;
  setCreateModalOpen: (v: boolean) => void;
  updateFn: () => void;
}
export type OuInfoProps = Exclude<V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse['list'], undefined>[number];
const CreateEnergyConsumptionModal: FC<Params> = ({ createModalOpen, setCreateModalOpen, updateFn }) => {
  const [form] = Form.useForm();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [loading, setLoading] = useState(false);
  const [ouItems, setOuItems] = useState<OuItem[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [allOuList, setAllOutList] = useState<OuInfoProps[]>([]);
  const mcidList = useWatch('mcidList', form);

  // 查询所有 ou
  useEffect(() => {
    apiV2OuPagePost({ page: 1, size: 500, mcids: mcidList ?? [], ifCancel: false }).then(res => {
      setAllOutList(res.list as any);
      console.log('all ous', res);
    });
  }, [mcidList]);

  const options = useMemo(() => {
    // 过滤被移除的 ou list, 显示未不可编辑状态
    return [...allOuList.map(v => ({ label: v.name, value: v.id, mcid: v.mcid }))];
  }, [allOuList]);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => setOrganition(res));
  }, []);

  const mcidTreeOptions = useMemo(() => {
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { key: k.key, title: k.title };
        });
    } else {
      return [];
    }
  }, [organization]);

  const handleMcidChange = (values: string[]) => {
    let newOuItems = ouItems.filter(m => values.includes(m.customerMcid));
    setOuItems([...newOuItems]);
  };

  const onCancel = () => {
    setCreateModalOpen(false);
    form.resetFields();
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      const params = {
        mcidList: values.mcidList,
        name: values.name,
        ouIds: values.ouIds,
      };
      const request = () => apiV2EnergyAnalysisCreatePost(params);
      setLoading(true);
      request()
        .then(() => {
          onCancel();
          updateFn();
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <Modal title="新建用能分析组" open={createModalOpen} size="big" onCancel={onCancel} onOk={onSubmit}>
      <Spin spinning={loading}>
        <Row>
          <Col span={9}>
            <p>
              使用【{breadcrumbRoutes && breadcrumbRoutes.routes ? breadcrumbRoutes.routes[2].name : '用能分析组'}
              】功能搭建标准能源管理边界
            </p>
            <Form form={form} layout="vertical" {...formLayout}>
              <Form.Item
                label="名称"
                name="name"
                rules={[
                  { required: true, message: '请输入名称' },
                  { max: 50, message: '名称不超过50个字符' },
                ]}
              >
                <Input placeholder="请输入名称，不超过50个字" />
              </Form.Item>
              <Form.Item label="所属组织" name="mcidList" rules={[{ required: true, message: '请选择所属组织' }]}>
                <Select
                  onChange={handleMcidChange}
                  onDeselect={value => {
                    const ouIds = form.getFieldValue('ouIds') || [];
                    const removedOuIds = options.filter(opt => opt.mcid === value).map(opt => opt.value);
                    const updatedOuIds = ouIds.filter((id: number) => !removedOuIds.includes(id));
                    form.setFieldsValue({ ouIds: updatedOuIds });
                  }}
                  mode="multiple"
                  placeholder="请选择"
                  style={{ width: '100%' }}
                >
                  {mcidTreeOptions?.map(i => (
                    <Select.Option key={i.key} value={i.key}>
                      {i.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="所属运营单元" name="ouIds" rules={[{ required: true, message: '请选择所属运营单元' }]}>
                <Select
                  onChange={handleMcidChange}
                  mode="multiple"
                  placeholder="请选择"
                  style={{ width: '100%' }}
                  options={options}
                ></Select>
              </Form.Item>
            </Form>
          </Col>
          <Col span={2}>
            <div>
              <Divider type="vertical" style={{ height: 290, marginLeft: '50%' }} />
            </div>
          </Col>
          <Col span={12}>
            <EnergyCarousel />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default CreateEnergyConsumptionModal;
