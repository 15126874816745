import { RestType } from '@/api/shiftCalendar';
import {
  EllipsisSpan,
  Table,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import { Checkbox, Col, Form, Input, Radio, Row, Space, DatePicker } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ShiftTag from '../ShiftTag';
import TimeGrid from '../TimeGrid';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { apiV2WorkShiftCalendarDetailPost, apiV2WorkShiftListPost } from '@maxtropy/device-customer-apis-v2';
import {
  ColorItem,
  ShiftCalendarItem,
  colorsItems,
  createRangeTimeStr,
  formateRestKey,
  formateRestType,
  options,
} from '../util';

const ShiftView: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  const colors = useRef<ColorItem[]>([...colorsItems]);
  const [form] = Form.useForm<ShiftCalendarItem>();
  const [selectShiftList, setSelectShiftList] = useState<any[]>([]);
  const [extraWorkShiftList, setExtraWorkShiftList] = useState<any>([]);
  const { id } = useParams<{ id: string }>();
  const { op } = useParams<{ op: string }>();
  const [detail, setDetail] = useState<any>({});
  const workShiftCalendarExtras = Form.useWatch('workShiftCalendarExtras', form); // 填报类型

  useEffect(() => {
    if (id && op === 'view') {
      // 编辑
      Promise.all([apiV2WorkShiftListPost({}), apiV2WorkShiftCalendarDetailPost({ id: Number(id) })]).then(
        ([list, detail]) => {
          setDetail(detail);
          let key = (formateRestKey as Record<string, string>)[detail.restType!];
          detail?.workShiftCalendarExtras?.forEach((item: any) => {
            item.dateList?.forEach((date: any) => {
              date.startDate = dayjs(date.startDate);
              date.endDate = dayjs(date.endDate);
            });
            item.dayOffFlag = item.dayOffFlag === 0 ? '否' : '是';
          });
          form.setFieldsValue({
            name: detail.name,
            restType: detail.restType,
            extra: {
              [key]: detail.restSeg?.split(',')!,
            },
            workShiftCalendarExtras: detail.workShiftCalendarExtras,
          });

          if (detail.workShiftCalendarExtras) {
            const workShiftCalendarExtras = detail.workShiftCalendarExtras?.map(i => i.workShifts);
            setExtraWorkShiftList(
              workShiftCalendarExtras.map(item => {
                return item?.map((subItem: any) => {
                  let tempColors = colors.current[0];
                  colors.current.shift();
                  return {
                    ...subItem,
                    colors: tempColors,
                  };
                });
              })
            );
          }
          if (detail.workShifts) {
            setSelectShiftList(
              detail.workShifts?.map(item => {
                let tempColors = colors.current[0];
                colors.current.shift();
                return {
                  ...item,
                  colors: tempColors,
                };
              })
            );
          }
        }
      );
      return;
    }
    // 新增
  }, [id, form, op]);

  const editBtn = () => {
    navigate(`/energy/teamShift/shiftCalendar/edit/${id}/view`);
  };

  const disabledDate = (currentDate: Dayjs, dateList: any[]) => {
    const today = dayjs();
    // 禁用当前日期之前的日期
    if (currentDate.isBefore(today, 'day')) {
      return true;
    }
    // 禁用已有的日期列表中的日期
    if (!isNil(dateList[0]?.startDate) && !isNil(dateList[0]?.endDate)) {
      const disabledDates = dateList?.map(dateItem => {
        const startDate = dayjs(dateItem?.startDate);
        const endDate = dayjs(dateItem?.endDate);
        return startDate?.isSameOrBefore(currentDate, 'day') && endDate?.isSameOrAfter(currentDate, 'day');
      });
      if (!isNil(disabledDates) && disabledDates.includes(true)) {
        return true;
      }
    }
    return false;
  };

  const getColumns = (remove: any, workShiftCalendarExtrasIndex: number) => {
    return [
      {
        title: '序号',
        dataIndex: 'id',
        key: 'id',
        width: 240,
        render: (_: any, record: any, index: number) => {
          return <EllipsisSpan value={index + 1} />;
        },
      },
      {
        title: '起始日期',
        dataIndex: 'startDate',
        key: 'startDate',
        width: 280,
        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item
              name={[index, 'startDate']}
              style={{
                marginBottom: 0,
              }}
              rules={[
                { required: true, message: '请选择开始日期' },
                {
                  validator: (_, value) => {
                    const { getFieldValue } = form;
                    const endDate = getFieldValue([
                      'workShiftCalendarExtras',
                      workShiftCalendarExtrasIndex,
                      'dateList',
                      index,
                      'endDate',
                    ]);

                    if (value && endDate && dayjs(value).isAfter(dayjs(endDate), 'day')) {
                      return Promise.reject('开始日期不能晚于结束日期');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker
                disabled={true}
                showNow={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) =>
                  disabledDate(
                    currentDate,
                    workShiftCalendarExtras?.flatMap((item: { dateList: any }) => item.dateList)
                  )
                }
                onChange={(currentDate: dayjs.Dayjs | null) => {
                  if (currentDate !== null) {
                    disabledDate(
                      currentDate,
                      workShiftCalendarExtras?.flatMap((item: { dateList: any }) => item.dateList)
                    );
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
      {
        title: '结束日期',
        dataIndex: 'endDate',
        key: 'endDate',
        width: 280,
        render: (_: any, record: any, index: number) => {
          return (
            <Form.Item
              name={[index, 'endDate']}
              style={{
                marginBottom: 0,
                width: '100%',
              }}
              rules={[
                { required: true, message: '请选择结束日期' },
                {
                  validator: (_, value) => {
                    const { getFieldValue } = form;
                    const startDate = getFieldValue([
                      'workShiftCalendarExtras',
                      workShiftCalendarExtrasIndex,
                      'dateList',
                      index,
                      'startDate',
                    ]);
                    if (value && startDate && dayjs(value).isBefore(dayjs(startDate), 'day')) {
                      return Promise.reject('结束日期不能早于开始日期');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <DatePicker
                disabled={true}
                showNow={false}
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) =>
                  disabledDate(
                    currentDate,
                    workShiftCalendarExtras?.flatMap((item: { dateList: any }) => item.dateList)
                  )
                }
                onChange={(currentDate: dayjs.Dayjs | null) => {
                  if (currentDate !== null) {
                    disabledDate(
                      currentDate,
                      workShiftCalendarExtras?.flatMap((item: { dateList: any }) => item.dateList)
                    );
                  }
                }}
              />
            </Form.Item>
          );
        },
      },
    ];
  };

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className="page_wrapper">
        <FormTitle title="查看班次日历" />
        <SubContent>
          <div className={styles.outer}>
            <div className={styles.form_content}>
              <Form
                form={form}
                initialValues={{
                  restType: RestType.WEEK,
                }}
                labelAlign="left"
                labelCol={{ style: { width: 85, marginRight: 50 } }}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="班次日历"
                      rules={[
                        { required: true, message: '' },
                        {
                          validator: async (_, value) => {
                            if (!value || !value.trim()) {
                              throw new Error('班次日历不能为空');
                            }
                            let regEn = /[\\<>*?:|"/]+/im;
                            if (regEn.test(value)) {
                              throw new Error('班次日历不能包含\\、/、:、*、?、"、<、>、|字符');
                            }
                          },
                        },
                      ]}
                    >
                      <Input placeholder="请输入名称" maxLength={15} style={{ width: 418 }} disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.workDay}>工作日</Col>
                </Row>
                <Row>
                  <Form.Item name="restType" label="休息日设置">
                    <>
                      {op !== 'view' ? (
                        <Radio.Group disabled={op === 'view' ? true : false}>
                          <Radio value={RestType.WEEK}>{formateRestType[RestType.WEEK]}</Radio>
                          <Radio value={RestType.MONTH}>{formateRestType[RestType.MONTH]}</Radio>
                        </Radio.Group>
                      ) : (
                        (() => {
                          return (
                            <div>{(formateRestType as Record<RestType, string>)[detail?.restType! as RestType]}</div>
                          );
                        })()
                      )}
                    </>
                  </Form.Item>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item noStyle dependencies={['restType']}>
                      {({ getFieldValue }) => {
                        let key = getFieldValue('restType') as RestType;
                        let ops = options[key];
                        let formName = formateRestKey[key];
                        return (
                          <>
                            <Form.Item
                              name={['extra', formName]}
                              colon={false}
                              label={<span style={{ opacity: 0 }}>none</span>}
                            >
                              <Checkbox.Group style={{ width: '100%' }} disabled={op === 'view' ? true : false}>
                                <Row>
                                  {form.getFieldValue('restType') === RestType.MONTH
                                    ? ops?.map(item => (
                                        <Col span={4} key={item.value}>
                                          <Checkbox value={item.value}>{item.label}</Checkbox>
                                        </Col>
                                      ))
                                    : ops?.map(item => <Checkbox value={item.value}>{item.label}</Checkbox>)}
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </>
                        );
                      }}
                    </Form.Item>
                  </Col>
                </Row>

                <div className={styles.drag_area}>
                  <div className={styles.title}>
                    <span>班次:</span>
                  </div>
                  <div className={styles.drag_box}>
                    <div className={styles.drag_right}>
                      <div className={styles.drag_right_top}>
                        <div>
                          <Space size={[5, 8]} wrap>
                            {selectShiftList.map((item, index) => (
                              <ShiftTag
                                key={index}
                                bg={item.colors?.bg}
                                borderBg={item.colors?.border}
                                showClose={false}
                              >
                                {item.name + createRangeTimeStr(item.timeSeg)}
                              </ShiftTag>
                            ))}
                          </Space>
                        </div>
                      </div>
                      <div className={styles.drag_right_bottom}>
                        <TimeGrid timeArr={selectShiftList} interDay />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.divider}></div>
                <Form.List name="workShiftCalendarExtras">
                  {(fields, { add, remove }) => (
                    <>
                      <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                        {fields.map((field, index) => {
                          return (
                            <Form.Item key={'workShiftCalendarExtras' + index} style={{ marginBottom: 0 }}>
                              <div className={styles.workDay}>{detail?.workShiftCalendarExtras[index]?.typeName}</div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  rowGap: 16,
                                  width: '100%',
                                  marginBottom: 24,
                                }}
                              >
                                <Space key={field.key} direction="vertical" style={{ width: '100%' }}>
                                  <Form.List name={[field.name, 'dateList']}>
                                    {(tableFields, { add, remove }) => {
                                      return (
                                        <>
                                          <Table
                                            dataSource={tableFields}
                                            columns={getColumns(remove, index)}
                                            pagination={false}
                                            scroll={{ y: 300 }}
                                          />
                                        </>
                                      );
                                    }}
                                  </Form.List>
                                </Space>
                              </div>
                              <Row>
                                <Col span={24}>
                                  <Form.Item
                                    name={[field.name, 'dayOffFlag']}
                                    label="是否休息日"
                                    style={{ marginBottom: 12 }}
                                  >
                                    <ShowInput></ShowInput>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                  prevValues[field.name]?.dayOffFlag !== currentValues[field.name]?.dayOffFlag
                                }
                              >
                                {() => {
                                  return form.getFieldsValue().workShiftCalendarExtras[index]?.dayOffFlag === '否' ? (
                                    <>
                                      <Form.Item name={[field.name, 'workShiftIds']}>
                                        <div className={styles.drag_box}>
                                          <div className={styles.drag_right}>
                                            <div className={styles.drag_right_top}>
                                              <div>
                                                <Space size={[5, 8]} wrap>
                                                  {extraWorkShiftList[index].map(
                                                    (
                                                      item: {
                                                        colors: { bg: string | undefined; border: string | undefined };
                                                        name: string;
                                                        timeSeg: string;
                                                      },
                                                      index: number
                                                    ) => (
                                                      <ShiftTag
                                                        bg={item.colors?.bg}
                                                        borderBg={item.colors?.border}
                                                        showClose={false}
                                                        key={index}
                                                      >
                                                        {item.name + createRangeTimeStr(item.timeSeg)}
                                                      </ShiftTag>
                                                    )
                                                  )}
                                                </Space>
                                              </div>
                                            </div>
                                            <div className={styles.drag_right_bottom}>
                                              <TimeGrid timeArr={extraWorkShiftList[index]} interDay />
                                            </div>
                                          </div>
                                        </div>
                                      </Form.Item>
                                    </>
                                  ) : null;
                                }}
                              </Form.Item>

                              <div className={styles.listDivider}></div>
                            </Form.Item>
                          );
                        })}
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </div>
        </SubContent>
        <Space className="sticky-footer" size={8} style={{ paddingLeft: 34 }}>
          <Button type="primary" onClick={editBtn}>
            编辑
          </Button>
          <Button
            onClick={() => {
              navigate(`/energy/teamShift/shiftCalendar`);
            }}
          >
            取消
          </Button>
        </Space>
      </Wrapper>
    </>
  );
};
export default ShiftView;
