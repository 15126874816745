import {
  Input,
  PopConfirm,
  Button,
  EllipsisSpan,
  Paging,
  Table,
  useSearchParams,
  useUpdate,
  Form,
  Tag,
  MultiSelect,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useState } from 'react';
import styles from './index.module.scss';
import ProcessModalOp from './ProcessModalOp';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useNavigate } from 'react-router-dom';
import { statusList } from '../WorkCenterInfo';
import { WorkStationState } from '@/api/workstationInfo';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2WorkProcedureDeletePost,
  apiV2WorkProcedureOperateStatePost,
  apiV2WorkProcedurePagePost,
  V2WorkProcedurePagePostRequest,
  V2WorkProcedurePagePostResponse,
} from '@maxtropy/device-customer-apis-v2';

const columns = [
  {
    title: '工序编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工序名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === WorkStationState.OPEN ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              禁用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '最后操作人',
    dataIndex: 'updateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

type SearchParamsProps = Omit<V2WorkProcedurePagePostRequest, 'page' | 'size'>;
export type TableRow = Exclude<V2WorkProcedurePagePostResponse['list'], undefined>[number];

const WorkProcessInfo: React.FC = () => {
  const [form] = Form.useForm();
  const [processModalVisible, setProcessModalVisible] = useState(false);
  const [opType, setOpType] = useState('add');
  const [currentRow, setCurrentRow] = useState<TableRow>();
  const [update, setUpdate] = useUpdate();
  const hasEditPers = useHasPermission(PermissionsType.B_EDIT_PROCESS_INFO);
  const hasViewPers = useHasPermission(PermissionsType.B_VIEW_PROCESS_INFO);
  const hasControlPers = useHasPermission(PermissionsType.B_CONTROL_PROCESS_INFO);
  const hasDropPers = useHasPermission(PermissionsType.B_DROP_PROCESS_INFO);
  const hasAddPers = useHasPermission(PermissionsType.B_ADD_PROCESS_INFO);
  const navigate = useNavigate();
  const pageInfo = useSearchParams<SearchParamsProps>(20, {
    state: [WorkStationState.OPEN],
  });
  const { searchParams, reset, finish, setTotalCount } = pageInfo;

  const { data: pageList, loading } = useRequest(
    () => {
      return apiV2WorkProcedurePagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [searchParams, update],
    }
  );
  // 启用/禁用
  const enableBtn = (status: WorkStationState, id?: number) => {
    if (!id) return;
    apiV2WorkProcedureOperateStatePost({
      id,
      state: status,
    }).then(setUpdate);
  };
  // 废弃
  const dropBtn = (id?: number) => {
    if (!id) return;
    apiV2WorkProcedureDeletePost({
      id,
    }).then(setUpdate);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      render: (_: any, record: TableRow) => {
        return (
          <Space size={16}>
            {hasViewPers && (
              <Button type="link" onClick={() => viewBtn(record)}>
                查看
              </Button>
            )}

            {hasEditPers && (
              <Button type="link" onClick={() => editBtn(record)}>
                编辑
              </Button>
            )}

            {record.state === WorkStationState.CLOSE && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.OPEN, record.id)}>
                启用
              </Button>
            )}
            {record.state === WorkStationState.OPEN && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.CLOSE, record.id)}>
                禁用
              </Button>
            )}
            {hasDropPers && (
              <PopConfirm
                title="废弃后不可再恢复，请确认。"
                onConfirm={() => {
                  dropBtn(record.id);
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button disabled={record.isUse} type="link">
                  废弃
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: any) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      state: [WorkStationState.OPEN],
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: [WorkStationState.OPEN],
      }}
    >
      <Form.Item name="codeOrName" label="工序编码/名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="state" label="工序状态">
        <MultiSelect options={statusList} />
      </Form.Item>
    </CustomFilter>
  );

  const addBtn = () => {
    setCurrentRow(undefined);
    setOpType('add');
    setProcessModalVisible(true);
  };

  const editBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('edit');
    setProcessModalVisible(true);
  };

  const viewBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('view');
    setProcessModalVisible(true);
  };

  return (
    <>
      <div className={styles.filterWrapper}>{filters}</div>
      <div className={styles.spaceStyle} />
      <div className={styles.filterWrapper}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 10 }}>
          {hasAddPers && (
            <Button type="primary" onClick={addBtn} icon={<PlusOutlined />}>
              新建工序
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate('/productionManagement/production/energyUnitMapping');
            }}
          >
            能耗单元映射
          </Button>
        </div>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={pageList} scroll={{ x: 1300 }} />
        <Paging pagingInfo={pageInfo} />
      </div>

      {processModalVisible && (
        <ProcessModalOp
          type={opType}
          data={currentRow}
          confirm={() => {
            if (opType === 'add') {
              onReset();
            } else {
              setUpdate();
            }
            setProcessModalVisible(false);
          }}
          cancel={() => setProcessModalVisible(false)}
        />
      )}
    </>
  );
};
export default WorkProcessInfo;
