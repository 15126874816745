import {
  EnergyMediumListItem,
  EnergyMediumSceneIds,
  EnergyPlanDetailPreviewListItem,
  PlanType,
} from '@/api/plustekEnergyPlan';
import dayjs from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { DataNode } from 'antd/es/tree';
import { isNil } from 'lodash';

interface Props {
  chartData: EnergyPlanDetailPreviewListItem[];
  selectedNode?: DataNode;
  selectedMedium?: EnergyMediumListItem;
  planType: PlanType;
}

const colorList = {
  [EnergyMediumSceneIds.WATER_SUPPLY_AND_DRAINAGE]: '#52E7FF',
  [EnergyMediumSceneIds.GAS_SUPPLY_AND_CONSUMPTION]: '#FFCB47',
  [EnergyMediumSceneIds.HOT_STEAM]: '#FF9247',
  [EnergyMediumSceneIds.COMPRESSED_AIR]: '#234ED9',
  [EnergyMediumSceneIds.INDUSTRIAL_GASES]: '#6F47FF',
  [EnergyMediumSceneIds.HEATING_VENTILATION_AND_AIR_CONDITIONING]: '#59D744',
  [EnergyMediumSceneIds.INDUSTRIAL_LIQUIDS]: '#FF477B',
  [EnergyMediumSceneIds.ELEC]: '#2D8DFF',
  [EnergyMediumSceneIds.STANDARD_COAL]: '#00ADFF',
};

const BarEcharts: React.FC<Props> = props => {
  const { chartData, selectedNode, selectedMedium, planType } = props;

  const getChartOption = () => {
    return {
      grid: {
        // left: 60,
        right: 0,
        top: 35,
        bottom: 20,
      },
      tooltip: {
        transitionDuration: 0,
        show: true,
        // trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        // borderRadius: 2,
        // borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
        },
        borderColor: 'rgba(0,0,0,0.8)',
        formatter: function (params: any) {
          if (planType === PlanType.QUARTER) {
            const { value, name } = params;
            return `
            <p style='width: 200px; height: 20px;font-size: 12px;font-weight: 400;color: rgba(255,255,255,0.65);line-height: 20px;' >${
              selectedNode?.title
            }</p>
            <p style='width: 200px; height: 20px;font-size: 12px;font-weight: 400;color: rgba(255,255,255,0.65);line-height: 20px;' >${name}</p><p style="width: 200px; margin-bottom: 0px; display:flex;justify-content: space-between;color: rgba(255,255,255,0.85)" >
            <span style=' '>${selectedMedium ? selectedMedium.energyMediumName : '标准煤'}</span> 
            ${isNil(value) ? '--' : Number(value).toFixed(2)} ${
              selectedMedium ? selectedMedium.generalName : 'kgce'
            } </p>`;
          } else {
            const { data } = params;
            const time = dayjs(data?.at(0));
            return `
            <p style='width: 200px; height: 20px;font-size: 12px;font-weight: 400;color: rgba(255,255,255,0.65);line-height: 20px;' >${
              selectedNode?.title
            }</p>
            <p style='width: 200px; height: 20px;font-size: 12px;font-weight: 400;color: rgba(255,255,255,0.65);line-height: 20px;' >${
              planType === PlanType.DAY
                ? time.format('YYYY-MM-DD')
                : planType === PlanType.MONTH
                ? time.format('YYYY-MM')
                : planType === PlanType.YEAR
                ? time.format('YYYY')
                : ''
            }</p><p style="width: 200px; margin-bottom: 0px; display:flex;justify-content: space-between;color: rgba(255,255,255,0.85)" >
            <span style=' '>${selectedMedium ? selectedMedium.energyMediumName : '标准煤'}</span> 
            ${isNil(data?.at(1)) ? '--' : Number(data?.at(1)).toFixed(2)} ${
              selectedMedium ? selectedMedium.generalName : 'kgce'
            } </p>`;
          }
        },
      },
      xAxis:
        planType === PlanType.QUARTER
          ? {
              type: 'category',
              data: ['第一季度', '第二季度', '第三季度', '第四季度'],
              axisLabel: {
                fontSize: 12,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            }
          : {
              type: 'time',
              minInterval: 60 * 1000 * 60 * 24,
              axisLabel: {
                formatter: function (e: number) {
                  if (planType === PlanType.DAY) return dayjs(e, 'x').format('MM-DD');
                  if (planType === PlanType.MONTH) return dayjs(e, 'x').format('MM-DD');
                  if (planType === PlanType.YEAR) return dayjs(e, 'x').format('YYYY');
                },
                fontSize: 12,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            },
      yAxis: {
        type: 'value',
        name: `${selectedMedium ? selectedMedium.generalName : 'kgce'}`,
        nameTextStyle: {
          align: 'left',
          padding: [0, 0, 0, -25],
          fontSize: 12,
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          lineStyle: { color: 'rgba(255,255,255,0.30)' },
        },
        axisLabel: {
          fontSize: 12,
          color: 'rgba(255,255,255,0.85)',
        },
      },
      legend: {
        show: false,
      },
      series: {
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: planType === PlanType.QUARTER ? chartData?.map(i => i?.value) : chartData?.map(i => [i?.ts, i?.value]),
        itemStyle: {
          color:
            selectedMedium && selectedMedium.energyMediumSceneId
              ? colorList[selectedMedium.energyMediumSceneId]
              : selectedMedium?.energyMediumId === 2030 // 电能
              ? colorList[EnergyMediumSceneIds.ELEC]
              : colorList[EnergyMediumSceneIds.STANDARD_COAL],
        },
      },
    };
  };

  return (
    <>
      <ReactEcharts option={getChartOption()} notMerge lazyUpdate={false} style={{ width: 692, height: 462 }} />
    </>
  );
};

export default BarEcharts;
