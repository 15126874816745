import { useEffect, useMemo, useState } from 'react';
import { Col, Row, Space } from 'antd';
import {
  createDataPointMirror,
  DataPropertyByDeviceIdRes,
  FindGatewayListInNetworkRes,
  findGatewayListInNetwork,
  getDataPropertyByDeviceTypeId,
  updateDataPointMirror,
  DataPointMirrorDetail,
  getDataPointMirrorDetail,
  getEdgeDevicePage,
  EdgeDevice,
} from '@/api/netWorking';
import { Button, Form, Modal, Select, ShowInput } from '@maxtropy/components';

interface Iprops {
  opType?: string; // add 添加; edit 编辑
  visible?: boolean;
  close?: () => void;
  netId?: string;
  update?: () => void;
  mirrorId?: number;
}
const formLayout = {
  labelCol: { style: { width: 130 } },
};
const DataMirrorOpModal: React.FC<Iprops> = ({ opType, visible, close, netId, update, mirrorId }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [detail, setDetail] = useState<DataPointMirrorDetail>();
  useEffect(() => {
    setOpenModal(!!visible);
  }, [visible]);

  const title = useMemo(() => {
    return opType === 'add' ? '添加数据点镜像' : '编辑数据点镜像';
  }, [opType]);
  // 数据发送网关列表
  const [sendGatewayIdList, setSendGatewayIdList] = useState<FindGatewayListInNetworkRes[]>([]);
  // 设备列表
  const [deviceList, setDeviceList] = useState<EdgeDevice[]>([]);
  // 数据属性列表
  const [dataPropertyIdList, setDataPropertyIdList] = useState<DataPropertyByDeviceIdRes[]>([]);
  // 数据接收网关列表
  const [receiveGatewayIdList, setReceiveGatewayIdList] = useState<FindGatewayListInNetworkRes[]>([]);

  const [currentSendGatewayId, setCurrentSendGatewayId] = useState<number>();
  const [currentDeviceId, setCurrentDeviceId] = useState<number>();
  const currentDeviceTypeId = useMemo(() => {
    return deviceList.find(item => item.deviceId === currentDeviceId)?.deviceTypeId;
  }, [currentDeviceId, deviceList]);
  // 查询的前置条件
  const preParams = useMemo(() => opType === 'add' && visible, [opType, visible]);
  // 数据接收表单是否禁用
  const receiveGatewayIdInputDisable = useMemo(() => {
    if (opType === 'add') {
      return !currentSendGatewayId;
    } else {
      return false;
    }
  }, [currentSendGatewayId, opType]);

  // 编辑时查询详情
  useEffect(() => {
    if (opType === 'edit' && mirrorId && visible) {
      getDataPointMirrorDetail(mirrorId).then(res => {
        setDetail(res);
        setCurrentSendGatewayId(res.sendGatewayId);
        form.setFieldsValue({
          receiveGatewayIdList: res.receiveGatewayList.map(item => item.receiveGatewayId),
        });
      });
    }
  }, [opType, mirrorId, form, visible]);
  // 查询数据发送网关列表
  useEffect(() => {
    if (preParams && netId) {
      findGatewayListInNetwork({ netId }).then(res => {
        setSendGatewayIdList(res?.list ?? []);
      });
    }
  }, [preParams, netId]);
  // 当前iot协议
  const currentIotProtocolType = useMemo(() => {
    return sendGatewayIdList.find(item => item.gatewayId === currentSendGatewayId)?.iotProtocol;
  }, [currentSendGatewayId, sendGatewayIdList]);

  // 查询设备列表
  useEffect(() => {
    if (preParams && currentIotProtocolType && currentSendGatewayId) {
      getEdgeDevicePage({
        iotProtocol: currentIotProtocolType,
        gatewayId: String(currentSendGatewayId),
        page: 1,
        size: 10000, // 暂时这样写
      }).then(res => setDeviceList(res.list));
    }
  }, [preParams, currentIotProtocolType, currentSendGatewayId]);

  // 查询数据属性
  useEffect(() => {
    if (preParams && currentDeviceTypeId && currentSendGatewayId && netId && currentDeviceId) {
      getDataPropertyByDeviceTypeId({
        deviceTypeId: currentDeviceTypeId,
        deviceId: currentDeviceId,
        sendGatewayId: currentSendGatewayId,
        netId,
      }).then(res => setDataPropertyIdList(res.list ?? []));
    }
  }, [currentDeviceTypeId, preParams, currentSendGatewayId, netId, currentDeviceId]);

  // 查询接收的网关
  useEffect(() => {
    if (visible && netId && currentSendGatewayId) {
      findGatewayListInNetwork({ netId, gatewayId: currentSendGatewayId }).then(res => {
        setReceiveGatewayIdList(res?.list ?? []);
      });
    }
  }, [visible, netId, currentSendGatewayId]);

  const submitForm = () => {
    return form.validateFields().then(res => {
      if (opType === 'add') {
        return createDataPointMirror({ ...res, netId });
      } else {
        return updateDataPointMirror({ ...res, dataPointMirrorId: mirrorId });
      }
    });
  };
  const continueBtn = () => {
    submitForm().then(() => {
      update?.();
      resetValues();
    });
  };
  const finishBtn = () => {
    submitForm().then(() => {
      update?.();
      close?.();
    });
  };
  const resetValues = () => {
    form.resetFields();
    setCurrentSendGatewayId(undefined);
    setCurrentDeviceId(undefined);
  };
  const afterClose = () => {
    resetValues();
  };
  const cancelBtn = () => {
    close?.();
  };
  return (
    <>
      <Modal
        open={openModal}
        size="normal"
        contentClassName="modal-form-content"
        afterClose={afterClose}
        title={title}
        onCancel={cancelBtn}
        footer={
          <Row justify="end">
            <Space size={8}>
              <Button onClick={cancelBtn}>取消</Button>
              <Button type="primary" onClick={continueBtn}>
                确定并继续
              </Button>
              <Button type="primary" onClick={finishBtn}>
                确定并完成
              </Button>
            </Space>
          </Row>
        }
      >
        <Form form={form} labelAlign="left" {...formLayout}>
          <Row>
            <Col span={24}>
              {opType === 'add' ? (
                <Form.Item label="数据发送网关:" name="sendGatewayId" rules={[{ required: true, message: '请选择' }]}>
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={sendGatewayIdList.map(item => ({ value: item.gatewayId, label: item.gatewayName }))}
                    onChange={setCurrentSendGatewayId}
                  />
                </Form.Item>
              ) : (
                <Form.Item label="数据发送网关:">
                  <ShowInput value={detail?.sendGatewayName} />
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              {opType === 'add' ? (
                <Form.Item label="设备:" name="deviceId" rules={[{ required: true, message: '请选择' }]}>
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={deviceList.map(item => ({ value: item.deviceId, label: item.deviceName }))}
                    onChange={setCurrentDeviceId}
                    disabled={!currentSendGatewayId}
                  />
                </Form.Item>
              ) : (
                <Form.Item label="设备:">
                  <ShowInput value={detail?.deviceName} />
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              {opType === 'add' ? (
                <Form.Item label="数据属性:" rules={[{ required: true, message: '请选择' }]} name="dataPropertyId">
                  <Select
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={dataPropertyIdList.map(item => ({ value: item.id, label: item.name }))}
                    disabled={!currentSendGatewayId || !currentDeviceTypeId}
                  />
                </Form.Item>
              ) : (
                <Form.Item label="数据属性:">
                  <ShowInput value={detail?.dataPropertyName} />
                </Form.Item>
              )}
            </Col>
            <Col span={24}>
              <Form.Item
                label="数据接收网关:"
                rules={[{ required: true, message: '请选择' }]}
                name="receiveGatewayIdList"
              >
                <Select
                  placeholder="请选择"
                  showSearch
                  optionFilterProp="label"
                  mode="multiple"
                  options={receiveGatewayIdList.map(item => ({ value: item.gatewayId, label: item.gatewayName }))}
                  disabled={receiveGatewayIdInputDisable}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DataMirrorOpModal;
