import { SubContent, Form, Select, InputNumber } from '@maxtropy/components';
import {
  V2OutputProductDetailPostResponse,
  apiV2ProcessFlowListByWorkCenterIdsPost,
  apiV2ProductionBaseCenterHierarchyPost,
} from '@maxtropy/device-customer-apis-v2';
import { Row, Col, Cascader } from 'antd';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { OptionItem, UnitItem } from '../../utils';
import styles from '../../index.module.scss';

interface Prop {
  form: any;
  detail?: V2OutputProductDetailPostResponse;
  unitCodeAndUnitNameList?: UnitItem[];
  unitCode?: string | number;
}

const getParents = (nodes: any[], key: string): any[] => {
  const res = [];
  for (let node of nodes ?? []) {
    if (node.id === key) {
      res.unshift(node);
      break;
    } else {
      const children = getParents(node.workCenterHierarchy ?? [], key);
      if (children && children.length) {
        res.unshift(node, ...children);
        break;
      }
    }
  }
  return res;
};

const ProductivityInfo = ({ form, detail, unitCodeAndUnitNameList, unitCode }: Prop) => {
  const [BaseCenterHierarchyOptions, setBaseCenterHierarchyOptions] = useState<any>([]);
  const [selectedBase, setSelectedBase] = useState<any>([]);
  const [choseWorkCenterIds, setChoseWorkCenterIds] = useState<number[]>([]);
  const [processOptions, setProcessOptions] = useState<OptionItem[]>([]);

  const characteristic = Form.useWatch('characteristic', form);
  const nationalEconomicIndustryId = Form.useWatch('nationalEconomicIndustryId', form);

  useEffect(() => {
    apiV2ProductionBaseCenterHierarchyPost({}).then(res => {
      const mappedData = res.list?.map(item => ({
        ...item,
        name: `${item.code}-${item.name}`,
        disabled: isNil(item.workCenterHierarchy) ? true : false,
        workCenterHierarchy: (item?.workCenterHierarchy ?? [])?.map(hierarchyItem => ({
          ...hierarchyItem,
          name: `${hierarchyItem.code}-${hierarchyItem.name}`,
        })),
      }));
      setBaseCenterHierarchyOptions(mappedData);

      if (detail) {
        const filteredInformation = detail?.outputProductivityInformation?.filter(
          item => item.productionBaseCode !== null
        );
        let Array = [] as any[];
        let ArrayArray: any[][] = [];

        for (const item of filteredInformation ?? []) {
          Array = [];
          const ids = item.workCenterId;
          Array.push(...getParents(mappedData as any, ids as any).map(item => item.id));
          ArrayArray.push(Array);
        }
        form.setFieldsValue({
          nationalEconomicIndustryId: ArrayArray as any[],
          outputProductivityInformation: filteredInformation,
        });

        // 禁用已选择工作中心
        if (detail.outputProductivityInformation && detail.outputProductivityInformation.length > 0) {
          setChoseWorkCenterIds(detail.outputProductivityInformation.map(i => i.workCenterId!));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  useEffect(() => {
    if (isNil(choseWorkCenterIds) || choseWorkCenterIds.length === 0) return;
    apiV2ProcessFlowListByWorkCenterIdsPost({ ids: choseWorkCenterIds }).then(res => {
      if (!isNil(res.list)) {
        const data = (res?.list ?? [])?.map(i => ({
          label: `${i.name}(${i.code})`,
          value: i.id,
        }));
        setProcessOptions(data);
      }
    });
  }, [choseWorkCenterIds]);

  return (
    <SubContent title="产能信息">
      <Row>
        <Col span={12}>
          <Form.Item
            label="生产基地"
            name="nationalEconomicIndustryId"
            rules={[{ required: true, message: '至少选择一个工作中心' }]}
          >
            <Cascader
              options={BaseCenterHierarchyOptions}
              multiple
              allowClear
              showSearch
              maxTagCount="responsive"
              onChange={(value, workerCenterOptions) => {
                setSelectedBase(workerCenterOptions);
                const ids = Array.from(new Set(workerCenterOptions.map(item => item[1].id)));
                setChoseWorkCenterIds(ids);
                const outputProductivityInformation = form.getFieldValue('outputProductivityInformation') || [];
                const cloneInformation = cloneDeep(outputProductivityInformation);

                if (isNil(cloneInformation) ? 0 : cloneInformation.length > workerCenterOptions.length) {
                  const updatedInformation = cloneInformation.filter((item: any) => {
                    const workCenterIdExists = workerCenterOptions.some(i => i[1].id === item.workCenterId);
                    return workCenterIdExists;
                  });
                  form.setFieldsValue({ outputProductivityInformation: updatedInformation });
                } else if (isNil(cloneInformation) ? 0 : cloneInformation.length < workerCenterOptions.length) {
                  const data = workerCenterOptions.map((item: any, index: number) => {
                    if (isNil(cloneInformation[index])) {
                      // 如果没有数据，创建一个新对象
                      cloneInformation[index] = {
                        capacity: null,
                        id: item[0].id,
                        workCenterId: item[1].id,
                        workCode: item[1].code,
                        workName: item[1].name.split('-')[1],
                        productionBaseCode: item[0].code,
                        productionBaseName: item[0].name.split('-')[1],
                      };
                    }
                    return {
                      ...cloneInformation[index],
                      capacity: isNil(cloneInformation[index].capacity) ? null : cloneInformation[index].capacity,
                      workCenterId: isNil(cloneInformation[index].workCenterId)
                        ? item[1].id
                        : cloneInformation[index].workCenterId,
                      id: isNil(cloneInformation[index].id) ? item[0].id : cloneInformation[index].id,
                    };
                  });
                  form.setFieldsValue({ outputProductivityInformation: data });
                }
              }}
              changeOnSelect
              showCheckedStrategy={'SHOW_CHILD'}
              fieldNames={{ label: `name`, value: 'id', children: 'workCenterHierarchy' }}
              placeholder="请选择工作中心"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="processFlowId"
            label="工艺流程"
            rules={[{ required: characteristic === 1 ? true : false, message: '请选择工艺流程' }]}
          >
            <Select placeholder="请选择工艺流程" options={processOptions} showSearch optionFilterProp="label" />
          </Form.Item>
        </Col>
      </Row>
      {!isEmpty(nationalEconomicIndustryId) && (
        <div className={styles.information}>
          <Form.List name="outputProductivityInformation">
            {fields => (
              <>
                {fields.map((field, index) => {
                  const outputProductivityInformation = form.getFieldValue('outputProductivityInformation');
                  return (
                    <Row gutter={12}>
                      <Col span={6}>
                        <div>
                          {!isNil(outputProductivityInformation[index])
                            ? `${outputProductivityInformation[index].productionBaseCode}-${outputProductivityInformation[index].productionBaseName}`
                            : `${selectedBase[index][0].name}`}
                        </div>
                      </Col>
                      <Col span={6}>
                        <div>
                          {!isNil(outputProductivityInformation[index])
                            ? `${outputProductivityInformation[index].workCode}-${outputProductivityInformation[index].workName}`
                            : `${selectedBase[index][1].name}`}
                        </div>
                      </Col>
                      <Col span={11}>
                        <Form.Item name={[field.name, 'capacity']} label={`标准产能`}>
                          <InputNumber
                            style={{ width: '280px' }}
                            min={0}
                            placeholder="请输入标准产能"
                            precision={2}
                            addonAfter={`${
                              unitCodeAndUnitNameList?.find(u => u.unitCode === unitCode)?.unitName ?? '--'
                            }/小时`}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Form.List>
        </div>
      )}
    </SubContent>
  );
};

export default ProductivityInfo;
