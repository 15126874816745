// 2001,新水
// 2011,软水
// 2020,天然气(CH4)
// 2021,压缩空气
// 2022,氧气
// 2023,氮气
// 2024,二氧化碳
// 2030,电能

export const colors: Record<string, string> = {
  PRODUCTION_BASE: '#7661F3', // 生产基地
  WORK_CENTER: '#477BFF', // 工作中心
  WORK_PROCEDURE: '#329F5E', // 工序
  WORK_STATION: '#7661F3', // 工站
  ENERGY_UNIT: '#57FBD5', // 用能单元
};
export const energyDisplay: Record<string, string> = {
  PRODUCTION_BASE: '生产基地', //生产基地
  WORK_CENTER: '工作中心', // 工作中心
  WORK_PROCEDURE: '工序', // 工序
  WORK_STATION: '工站', // 工站
  ENERGY_UNIT: '用能单元', // 用能单元
};

export enum TypeFormat {
  PRODUCTION_BASE = 'productionBase',
  WORK_CENTER = 'workCenter',
  WORK_PROCEDURE = 'procedure',
  WORK_STATION = 'workStation',
  ENERGY_UNIT = 'energyUnit',
}

// 介质颜色
export const colorCategory: Record<string, string> = {
  '2030': '#59DBFF',
  '2021': '#2CA02C',
  '2020': '#FFCB47',
  '2001': '#FF477B',
  '2022': '#59DBFF',
  '2023': '#2CA02C',
  '2024': '#FFCB47',
  '2011': '#FF477B',
};
export const colorCategoryEdge: Record<string, string> = {
  '2030': '#325560',
  '2021': '#264527',
  '2020': '#65562F',
  '2001': '#652E3F',
  '2022': '#325560',
  '2023': '#264527',
  '2024': '#65562F',
  '2011': '#652E3F',
};

export const iconMapSelected: Record<string, any> = {
  '100': require('@/assets/icons/energyConsumption-selected@2x.webp'),
  '2001': require('@/assets/icons/water-selected@2x.webp'),
  '2021': require('@/assets/icons/compressedAir-selected@2x.webp'),
  '2020': require('@/assets/icons/supply-selected@2x.webp'),
  '2030': require('@/assets/icons/electricity-selected@2x.webp'),
  '2011': require('@/assets/icons/water-selected@2x.webp'),
  '2022': require('@/assets/icons/IndustrialGas-selected@2x.webp'),
  '2023': require('@/assets/icons/IndustrialGas-selected@2x.webp'),
  '2024': require('@/assets/icons/IndustrialGas-selected@2x.webp'),
};
export const iconMapUnSelected: Record<string, any> = {
  '100': require('@/assets/icons/energyConsumption@2x.webp'),
  '2011': require('@/assets/icons/water@2x.webp'),
  '2001': require('@/assets/icons/water@2x.webp'),
  '2021': require('@/assets/icons/compressedAir@2x.webp'),
  '2020': require('@/assets/icons/supply@2x.webp'),
  '2030': require('@/assets/icons/electricity@2x.webp'),
  '2022': require('@/assets/icons/IndustrialGas@2x.webp'),
  '2023': require('@/assets/icons/IndustrialGas@2x.webp'),
  '2024': require('@/assets/icons/IndustrialGas@2x.webp'),
};
