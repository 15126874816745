import {
  CreateUETEMTProcessResponse,
  CreateUETEMTReq,
  CreateUETEMTResponse,
  checkUetEMTNameUnique,
  createUETEMT,
  updateUetEMT,
} from '@/api/uet';
import {
  Form,
  FormContent,
  FormTitle,
  Modal,
  SubContent,
  Wrapper,
  message,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import { Divider, FloatButton } from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import RelatedAccounts from './components/RelatedAccounts';
import BasicInfoForm from './components/BasicInfoForm';
import AddProcess from './components/AddProcess';
import { V2UetEmtProcessListPostResponse, apiV2UetEmtProcessListPost } from '@maxtropy/device-customer-apis-v2';

interface ICreateProps {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const Create: FC<ICreateProps> = props => {
  const { isEdit } = props;
  const { id, energyId } = useParams<{ id: string; energyId: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();

  const [eMTBasicInfo, setEMTBasicInfo] = useState<CreateUETEMTResponse>(); // 保存EMT拓扑基本信息
  const [editedProcesses, setEditedProcesses] = useState<V2UetEmtProcessListPostResponse['processVos']>([]); // 所有节点
  const [nameIsEdit, setNameIsEdit] = useState<boolean>(isEdit ? false : true); // 名称编辑态
  const [updatedName, setUpdatedName] = useState<string>(); // 更新后的名称
  const [createdProcessNumber, setCreatedProcessNumber] = useState<CreateUETEMTProcessResponse[]>([]); // 现有节点的数量

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}能源介质拓扑` }];
  }, [isEdit]);

  useEffect(() => {
    if (id && isEdit && energyId) {
      // 编辑页面查询list
      apiV2UetEmtProcessListPost({ id: energyId }).then(res => {
        form.setFieldValue('name', res?.energyMediumTopologyVo?.name);
        // 设置基础信息
        setEMTBasicInfo({
          ...res.energyMediumTopologyVo,
          // id: res.energyMediumTopologyVo?.id,
          // energyMediumSceneName: res.energyMediumTopologyVo?.energyMediumSceneName,
          // energyMediumSceneId: res.energyMediumTopologyVo?.energyMediumSceneId,
          // code: res.energyMediumTopologyVo?.code,
          // uetId: res.energyMediumTopologyVo?.uetId,
        });
        // 设置所有节点信息，并设定为未编辑字段
        setEditedProcesses(
          (res?.processVos ?? []).map(i => {
            return { ...i, isEdit: false };
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, energyId, id]);

  // 保存基本信息
  const onFinish = async (values: CreateUETEMTReq) => {
    if (eMTBasicInfo && eMTBasicInfo.id) {
      // 编辑
      if (values.name === updatedName || values.name === eMTBasicInfo.name) {
        setNameIsEdit(false);
      } else {
        editBasicInfo(values, eMTBasicInfo);
      }
    } else {
      createBsaicInfo(values);
    }
  };

  // 新建时校验名称是否重复并调用新建接口
  const createBsaicInfo = (values: CreateUETEMTReq) => {
    checkUetEMTNameUnique(values.name).then(res => {
      if (res) {
        form.setFields([
          {
            name: 'name',
            errors: ['拓扑名称重复！'],
          },
        ]);
      } else {
        // 新建
        createUETEMT(id!, values).then(res => {
          message.success('创建能源介质拓扑成功！');
          setEMTBasicInfo(res);
          setNameIsEdit(false);
          form.setFieldValue('name', res.name);
        });
      }
    });
  };

  // 编辑时校验名称是否重复并调用编辑接口
  const editBasicInfo = (values: CreateUETEMTReq, eMTBasicInfo: CreateUETEMTResponse) => {
    checkUetEMTNameUnique(values.name).then(res => {
      if (res) {
        form.setFields([
          {
            name: 'name',
            errors: ['拓扑名称重复！'],
          },
        ]);
      } else {
        // 调用编辑接口编辑,如果没有节点则不让保存并弹窗提示
        if (createdProcessNumber && createdProcessNumber.length) {
          updateUetEMTName(values.name, eMTBasicInfo.id!);
        } else {
          Modal.error({
            content: `请先创建至少一个节点！`,
          });
        }
      }
    });
  };

  // 调用编辑接口修改基本信息
  const updateUetEMTName = (name: string, emtId: number) => {
    updateUetEMT(id!, String(emtId), {
      name: name,
    }).then(res => {
      message.success('修改名称成功！');
      setUpdatedName(res.name);
      setNameIsEdit(false);
      form.setFieldValue('name', res.name);
    });
  };

  return (
    <div id="handelDocID" style={{ height: '100vh', overflowY: 'auto' }}>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
        <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
          <FormTitle title={routes[0].name} />
          <SubContent className="mb-8">
            <BasicInfoForm
              setNameIsEdit={setNameIsEdit}
              nameIsEdit={nameIsEdit}
              form={form}
              isEdit={isEdit}
              eMTBasicInfo={eMTBasicInfo}
              createdProcessNumber={createdProcessNumber}
            />
          </SubContent>
        </Form>
        <Divider dashed />
        <FormContent style={{ paddingTop: 10, position: 'relative' }}>
          <AddProcess
            isEdit={isEdit}
            editedProcesses={editedProcesses}
            eMTBasicInfo={eMTBasicInfo}
            setCreatedProcessNumber={setCreatedProcessNumber}
          />
        </FormContent>
        <Divider dashed />
        {isEdit && (
          <SubContent title="关联账户">
            <RelatedAccounts eMTBasicInfo={eMTBasicInfo} />
          </SubContent>
        )}
      </Wrapper>
      <FloatButton.BackTop
        visibilityHeight={300}
        target={() => document.getElementById('handelDocID') as HTMLElement}
      />
    </div>
  );
};

export default Create;
