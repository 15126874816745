import { DataAORIType, DataType, StatisticsPartition } from '@/api/energyMediaComparison';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { Key } from 'react';

export const DataTypeAOrIArr = [
  {
    indicatorId: DataType.IVFR,
    name: '瞬时体积流量',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.IMFR,
    name: '瞬时质量流量',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.AVFR,
    name: '累积体积流量',
    indicatorType: DataAORIType.ACCUMULATE,
  },
  {
    indicatorId: DataType.AMFR,
    name: '累积质量流量',
    indicatorType: DataAORIType.ACCUMULATE,
  },
  {
    indicatorId: DataType.PA,
    name: '压力',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.T,
    name: '温度',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.E,
    name: '电量',
    indicatorType: DataAORIType.ACCUMULATE,
  },
  {
    indicatorId: DataType.POWER,
    name: '功率',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.AHEAT,
    name: '累积热量',
    indicatorType: DataAORIType.ACCUMULATE,
  },
  {
    indicatorId: DataType.D,
    name: '密度',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.RH,
    name: '相对湿度',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.AH,
    name: '绝对湿度',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.LEVEL,
    name: '液位',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
  {
    indicatorId: DataType.PURITY,
    name: '纯度',
    indicatorType: DataAORIType.INSTANTANEOUS,
  },
];

export const judgeIndicatorAccumulateOrinstantaneous = (originIndicator: number, comparedIndicator: number) => {
  const findOriginIndicator = DataTypeAOrIArr.find(item => item.indicatorId === originIndicator);
  const findComparedIndicator = DataTypeAOrIArr.find(item => item.indicatorId === comparedIndicator);
  // 如果都是累积量
  if (
    findOriginIndicator?.indicatorType === findComparedIndicator?.indicatorType &&
    findOriginIndicator?.indicatorType === DataAORIType.ACCUMULATE
  ) {
    return [
      { aggrby: StatisticsPartition.MINUTE_1 },
      { aggrby: StatisticsPartition.MINUTE_15 },
      { aggrby: StatisticsPartition.DAY },
      { aggrby: StatisticsPartition.MONTH },
    ];
  } else if (
    // 如果都是瞬时量
    findOriginIndicator?.indicatorType === findComparedIndicator?.indicatorType &&
    findOriginIndicator?.indicatorType === DataAORIType.INSTANTANEOUS
  ) {
    return [
      { aggrby: StatisticsPartition.MINUTE_1 },
      { aggrby: StatisticsPartition.MINUTE_15 },
      { aggrby: StatisticsPartition.DAY },
    ];
  } else if (findOriginIndicator?.indicatorType !== findComparedIndicator?.indicatorType) {
    // 如果一个累积一个瞬时
    return [
      { aggrby: StatisticsPartition.MINUTE_1 },
      { aggrby: StatisticsPartition.MINUTE_15 },
      { aggrby: StatisticsPartition.DAY },
    ];
  } else {
    // return [];
    return [
      { aggrby: StatisticsPartition.MINUTE_1 },
      { aggrby: StatisticsPartition.MINUTE_15 },
      { aggrby: StatisticsPartition.DAY },
    ];
  }
};

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode: StatisticsPartition, date: Dayjs, time?: string | null) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
      if (time) {
        return {
          from: dayjs(time, 'x').startOf('day').valueOf(),
          to: dayjs(time, 'x').endOf('day').valueOf(),
        };
      }
      return {
        from: dayjs(date, 'x').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };

    case StatisticsPartition.DAY:
      return {
        from: dayjs(date, 'x').subtract(30, 'days').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        from: dayjs(date, 'x').subtract(11, 'month').startOf('month').valueOf(),
        to: dayjs(date, 'x').endOf('month').valueOf(),
      };
    default:
      return {
        from: dayjs(date, 'x').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };
  }
};

export const getTs = (mode: StatisticsPartition, start: Dayjs, end: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.DAY:
      return {
        from: dayjs(start, 'x').startOf('day').valueOf(),
        to: dayjs(end, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        from: dayjs(start, 'x').startOf('month').valueOf(),
        to: dayjs(end, 'x').endOf('month').valueOf(),
      };
    default:
      return {
        from: dayjs(start, 'x').startOf('day').valueOf(),
        to: dayjs(end, 'x').endOf('day').valueOf(),
      };
  }
};

/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: Key) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * 是否是有效的数字
 */
export const isNumber = (value: Key) => !isNaN(+value) && isFinite(+value);

/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

const divide = (a: Key, b: Key) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);

/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: Key[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

/**
 * 求数组中的最大值
 */
export const max = (set: any) => {
  const numbers = filterNumber(set);
  return Math.max(...numbers);
};

/**
 * 求数组中的最小值
 */
export const min = (set: any) => Math.min(...filterNumber(set));
