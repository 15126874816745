import { Empty, Key, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout } from 'antd';
import DragResize from '@/components/DragResize';
import { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ConfigList from '../components/ConfigList';
import AnalysisInfo from '../components/AnalysisInfo';
import EnergyComparison from '../components/EnergyComparison';
import dayjs from 'dayjs';
import DarkWrapper from '../DarkWrapper';
import { apiV2EnergyOptimizationProjectGetPost } from '@maxtropy/device-customer-apis-v2';

const { Content, Sider } = Layout;

const AnalysisOptimize = () => {
  const [openSider, setOpenSider] = useState(true);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [energyOptimizationProjectId, setEnergyOptimizationProjectId] = useState<Key>();
  const [isAnalysisFinish, setIsAnalysisFinish] = useState<boolean>(false);
  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);
  const [isShowRecent, setIsShowRecent] = useState<boolean>(true);

  // 获取优化项目详情
  useEffect(() => {
    if (energyOptimizationProjectId) {
      apiV2EnergyOptimizationProjectGetPost({ energyOptimizationProjectId }).then(res => {
        if (res) {
          dayjs().isSameOrBefore(dayjs(res.optimizationEndTime).add(1, 'day'))
            ? setIsAnalysisFinish(false)
            : setIsAnalysisFinish(true);
          dayjs(res.optimizationStartTime).add(30, 'day').isBefore(dayjs())
            ? setIsShowRecent(true)
            : setIsShowRecent(false);
        }
      });
    }
  }, [energyOptimizationProjectId]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const onTreeSelect = (selectedKeys: Key[]) => {
    setEnergyOptimizationProjectId(selectedKeys[0]);
  };

  return (
    <DarkWrapper routes={breadcrumbRoutes?.routes}>
      <Layout className={styles.layout}>
        <Sider width={openSider ? siderWidth : 0} style={{ transition: dragStatus ? 'none' : 'all 0.2s' }}>
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <ConfigList onTreeSelect={onTreeSelect} />
        </Sider>
        <Content className={styles.content} style={{ marginLeft: openSider ? 10 : 0 }}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          {energyOptimizationProjectId ? (
            isAnalysisFinish ? (
              <>
                <AnalysisInfo energyOptimizationProjectId={energyOptimizationProjectId} />
                <EnergyComparison
                  isShowRecent={isShowRecent}
                  energyOptimizationProjectId={energyOptimizationProjectId}
                />
              </>
            ) : (
              <Empty
                style={{
                  marginTop: 200,
                }}
                description="项目优化未完成，数据计算中。"
              />
            )
          ) : (
            <Empty
              style={{
                marginTop: 200,
              }}
            />
          )}
        </Content>
      </Layout>
    </DarkWrapper>
  );
};

export default AnalysisOptimize;
