import { Key, fetch } from '@maxtropy/components';
import qs from 'qs';
import { GatewayNetStatus } from '@/shared/types';
import { DevicePageRequest } from './gateway';
import { PageRequest, PageResponse } from './page';

export enum DistributeStatus {
  NOTSTART = 1,
  STARTING,
  SUCCESS,
  FAIL,
  // 1：待下发 2：进行中 3：成功 4：失败
}

export const DistributeStatusDisplay = {
  [DistributeStatus.NOTSTART]: '待下发',
  [DistributeStatus.STARTING]: '进行中',
  [DistributeStatus.SUCCESS]: '成功',
  [DistributeStatus.FAIL]: '失败',
};

export enum NetworkingType {
  STATIC_IP = 1,
}

export const NetworkingTypeDisPlay = {
  [NetworkingType.STATIC_IP]: '静态IP',
};
export interface gatewayBody {
  gatewayId: Key;
  gateway_ip: string | number[];
}
export interface NetworkingBody {
  netName: string;
  netType: NetworkingType;
  gatewayList: gatewayBody[];
}

export function createNetwork(body: NetworkingBody) {
  return fetch(
    '/api/v2/network/createNetwork',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface GatewayPageRequest extends PageRequest {
  gatewayNo?: string; //网关编号
  gatewayName?: string; //网关名称
  orgId?: Key; //所在组织id
  netId?: Key;
}

export interface Gateway {
  gatewayId: number; // 网关id
  gatewayNo: string; // 网关编号
  gatewayName: string; // 网关名称
  orgId: string; // 所在组织
  orgName: string; // 所在组织简称
  gatewayIp?: number[] | string; // 网关ip
  gatewayStatus: GatewayNetStatus; // 网关状态
  gatewayDistributeStatus: DistributeStatus; // 网关最新下发状态
  gatewayDistributeTime: string; // 网关最新下发时间
  gatewayDistributeFailureReason: string; // 网关最新下发失败原因
  isRepeat?: boolean;
}

export function findGateway(params: GatewayPageRequest): Promise<PageResponse<Gateway>> {
  return fetch(`/api/network/findGateway?${qs.stringify(params, { indices: false })}`);
}

export interface NetworkRequest extends PageRequest {
  netNo?: string; //组网编号
  netName?: string; // 组网名称
  netType?: NetworkingType; // 组网类型
  gatewayNo?: string; // 网关编号
  gatewayName?: string; // 网关名称
}

export function queryNetworks(params: NetworkRequest): Promise<PageResponse<NetworkDetail>> {
  return fetch(
    `/api/v2/network/queryNetworks`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export enum ColorStatus {
  RED = 1,
  BLUE = 2,
}

export interface NetworkDetail {
  id: Key; //组网id
  netNo: string; //组网编号
  netName: string; //组网名称
  netType: NetworkingType; //组网类型 1: 静态IP
  distributeStatus: DistributeStatus; //最新下发状态 1：未进行 2：进行中 3：成功 4：失败
  createTime: string; //创建时间
  updateTime: string; //修改时间
  updateByUserId: string; //最后操作人id
  updateByUserName: string; //最后操作人
  tenantMcid: string; //所属租户id
  netVersion: number; //组网版本
  gatewayList: Gateway[]; //组网网关
  colorStatus: ColorStatus;
}

export function findNetworkDetailById(netId: Key) {
  return fetch<NetworkDetail>(
    `/api/v2/network/findNetworkDetailById`,
    {
      method: 'POST',
      body: JSON.stringify({ id: netId }),
    },
    true
  );
}

export interface updateNetworkBody {
  netId: Key;
  netName: string;
  beforeGatewayList: gatewayBody[];
  afterGatewayList: gatewayBody[];
}

export function updateNetworkById(body: updateNetworkBody) {
  return fetch<NetworkDetail>(
    `/api/v2/network/updateNetworkById`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export function deleteNetworkById(netId: Key) {
  return fetch<{ flag: boolean }>(
    `/api/v2/network/deleteNetworkById`,
    {
      method: 'POST',
      body: JSON.stringify({ id: netId }),
    },
    true
  );
}

export function networkConfigsDistribute(netId: Key, gatewayIds?: Key[]) {
  return fetch<{ flag: boolean }>(
    `/api/v2/network/networkConfigsDistribute`,
    {
      method: 'POST',
      body: JSON.stringify({ netId, gatewayIds }),
    },
    true
  );
}
export interface NetworkDistributeRecordParams extends PageRequest {
  netId: Key;
}

export interface NetworkDistributeRecordDetail {
  netLogId: Key;
  netId: Key; // 组网id
  distributeStatus: DistributeStatus;
  time: string; // 下发时间
  updateByUserName: string; // 最后操作人
  tenantMcid: Key; // 所属租户id
  gatewayList: Gateway[]; //组网网关
}

export function networkDistributeRecord(
  params: NetworkDistributeRecordParams
): Promise<PageResponse<NetworkDistributeRecordDetail>> {
  return fetch(
    `/api/v2/network/networkDistributeRecord`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
// 新建数据点镜像
export interface DataPointMirrorReq {
  netId: number;
  sendGatewayId: number;
  deviceId: number;
  dataPropertyId: number;
  receiveGatewayIdList: number[];
}
export const createDataPointMirror = (data: DataPointMirrorReq) => {
  return fetch<boolean>(
    `/api/v2/dataPointMirror/createDataPointMirror`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );
};
// 删除数据点镜像
export const deleteDataPointMirror = (id: Key) => {
  return fetch<boolean>(
    `/api/v2/dataPointMirror/deleteDataPointMirror`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

// 获取数据点镜像详情
export interface ReceiveGatewayItem {
  receiveGatewayId: number;
  receiveGatewayNo: string;
  receiveGatewayName: string;
}

export interface DataPointMirrorDetail {
  mirrorId: number;
  netId: number;
  sendGatewayId: number;
  sendGatewayNo: string;
  sendGatewayName: string;
  deviceId: number;
  deviceName: string;
  dataPropertyId: number;
  dataPropertyName: string;
  receiveGatewayList: ReceiveGatewayItem[];
}

export const getDataPointMirrorDetail = (id: Key) => {
  return fetch<DataPointMirrorDetail>(
    `/api/v2/dataPointMirror/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

// 更新数据点
export const updateDataPointMirror = (data: { dataPointMirrorId: number; receiveGatewayIdList: number[] }) => {
  return fetch<boolean>(
    `/api/v2/dataPointMirror/update`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );
};
// 查询组网下的数据点镜像列表
export interface DataPointListItem {
  mirrorId: number;
  netId: number;
  sendGatewayId: number;
  sendGatewayNo: string;
  sendGatewayName: string;
  deviceId: number;
  deviceName: string;
  dataPropertyId: number;
  dataPropertyName: string;
  receiveGatewayList: ReceiveGatewayItem[];
}

export interface DataPointMirrorRes {
  netId: number;
  netName: string;
  tenantMcid: string;
  tenantName: string;
  netType: NetworkingType;
  dataPointList: DataPointListItem[];
}
export const getDataPointMirrorList = (id: Key) => {
  return fetch<DataPointMirrorRes>(
    `/api/v2/dataPointMirror/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

// 添加数据点镜像查询，组网下网关
export interface FindGatewayListInNetworkReq {
  netId: Key;
  gatewayName?: string;
  gatewayId?: Key;
}
export interface FindGatewayListInNetworkRes {
  netId: number;
  gatewayId?: number; // 查询数据接收网关时，填入当前的数据发送网关id
  gatewayNo?: string;
  gatewayName?: string;
  iotProtocol?: number;
}
export const findGatewayListInNetwork = (params: FindGatewayListInNetworkReq) => {
  return fetch<{ list: FindGatewayListInNetworkRes[] }>(
    `/api/v2/network/findGatewayListInNetwork`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
};

// 根据设备id查询数据属性列表
export interface DataPropertyByDeviceIdRes {
  id: number;
  type: number;
  name: string;
}
export const getDataPropertyByDeviceTypeId = (params: {
  deviceTypeId: Key;
  deviceId: Key;
  sendGatewayId: Key;
  netId: Key;
}) => {
  return fetch<{ list: DataPropertyByDeviceIdRes[] }>(
    `/api/v2/dataProperty/list-by-device-type-id-without-mirror`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
};
// 查询网关下设备接口
export interface EdgeDevice {
  deviceId: number;
  deviceName: string;
  deviceTypeId: number;
}

export function getEdgeDevicePage(params: DevicePageRequest): Promise<PageResponse<EdgeDevice>> {
  return fetch(`/api/gateway/edgeDevice/page?${qs.stringify(params, { indices: false })}`);
}
