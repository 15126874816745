import { Input, InputNumber, Key, Modal, Radio, Select, Form } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CarbonFootprintSourceOptions,
  MaterielTypeOptions,
  OutputUnitTypeOptions,
  SystemBoundaryOptions,
} from '../untils';
import { OutputUnitType, UnitTypeWithUnitList, getUnits } from '@/api/outputConfig';
import {
  MaterielManagementAddParams,
  MaterielStatus,
  addMateriel,
  editMateriel,
  getMaterielDetail,
} from '@/api/materielManagement';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedId?: Key;
  updateFn: () => void;
}

interface FormProps extends MaterielManagementAddParams {
  unit: {
    unitTypeCode: OutputUnitType; // 单位类型code （1 计量单位 2 计件单位）
    unitCode: string; // 单位code
  };
}

function formatNumber(v: any) {
  if (isNil(v) || v === '') return '';
  // 使用 toFixed 将数字格式化为字符串，保留原始小数位数
  let formattedNumber = parseFloat(v).toFixed(10);
  // 使用正则表达式去掉末尾多余的零
  formattedNumber = formattedNumber.replace(/\.?0+$/, '');
  return formattedNumber as any;
}

const AddMaterielModal: React.FC<Props> = ({ open, setOpen, selectedId, updateFn }) => {
  const [form] = Form.useForm();
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]); // 所有单位

  const unitTypeCode = Form.useWatch(['unit', 'unitTypeCode'], form); // 单位类型
  const unitCode = Form.useWatch(['unit', 'unitCode'], form);

  useEffect(() => {
    // 获取所有单位
    getUnits().then(res => setUnits(res.list));
  }, []);

  useEffect(() => {
    if (selectedId) {
      getMaterielDetail(selectedId).then(res => {
        form.setFieldsValue({
          ...res,
          unit: {
            unitTypeCode: res.unitTypeCode,
            unitCode: res.unitCode,
          },
        });
      });
    }
  }, [selectedId, form]);

  // 根据选择计量单位还是计件单位展示不同的单位选项
  const unitNameOptions = useMemo(() => {
    const matchedUnit = units.find(u => u.unitTypeCode === unitTypeCode);
    if (matchedUnit) {
      return (
        matchedUnit?.outputProductUnitList?.map(v => ({
          value: v?.unitCode,
          label: unitTypeCode === OutputUnitType.PIECE ? v.unitName : `${v.unitCode} (${v?.unitName})`,
        })) ?? []
      );
    } else {
      return [];
    }
  }, [unitTypeCode, units]);

  const onFinish = (v: FormProps) => {
    let body = {
      ...v,
      unitTypeCode: v.unit.unitTypeCode,
      unitCode: v.unit.unitCode,
    };
    if (selectedId) {
      // 编辑
      body = {
        ...body,
        id: selectedId,
      };
      editMateriel(body).then(() => {
        setOpen(false);
        updateFn();
      });
    } else {
      // 新建
      addMateriel(body).then(() => {
        setOpen(false);
        updateFn();
      });
    }
  };

  return (
    <Modal
      size="large"
      title={`${isNil(selectedId) ? '新建' : '编辑'}物料`}
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setOpen(false);
      }}
      okText="保存"
    >
      <Form
        isOrigin
        form={form}
        onFinish={onFinish}
        initialValues={{
          state: MaterielStatus.ENABLE,
        }}
        layout="vertical"
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="物料类型"
              name="type"
              rules={[
                {
                  required: true,
                  message: '请选择物料类型',
                },
              ]}
            >
              <Select placeholder="请选择" options={MaterielTypeOptions} disabled={!!selectedId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="物料编码"
              name="code"
              rules={[
                {
                  required: true,
                  message: '请输入物料编码',
                },
                {
                  pattern: /^[\w-.*/<>“”]+$/,
                  message: '仅支持数字、字母、下划线及- . * / <>  “”',
                },
                {
                  max: 20,
                  message: '最多20个字符',
                },
              ]}
            >
              <Input disabled={!!selectedId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="物料名称"
              name="name"
              rules={[
                {
                  required: true,
                  message: '请输入物料名称',
                },
                {
                  max: 50,
                  message: '最多50个字符',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="规格型号"
              name="spec"
              rules={[
                {
                  max: 50,
                  message: '最多50个字符',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="物料单位">
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name={['unit', 'unitTypeCode']}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请选择物料单位',
                      },
                    ]}
                  >
                    <Select
                      placeholder="请选择"
                      options={OutputUnitTypeOptions}
                      disabled={!!selectedId}
                      onChange={() => {
                        form.setFieldsValue({
                          unit: {
                            unitCode: undefined,
                          },
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['unit', 'unitCode']}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请选择物料单位',
                      },
                    ]}
                  >
                    <Select placeholder="请选择" disabled={!!selectedId} options={unitNameOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="单位重量（kg）"
              name="unitWeight"
              rules={[
                {
                  required: true,
                  message: '请输入单位重量',
                },
              ]}
            >
              <InputNumber min="0.0000000001" step="0.0000000001" formatter={formatNumber} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="碳足迹"
              name="carbonFootPrint"
              rules={[
                {
                  required: true,
                  message: '请输入碳足迹',
                },
              ]}
            >
              <InputNumber min="0.0000000001" step="0.0000000001" formatter={formatNumber} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="碳足迹单位">
              <Row>
                <Col span={5}>
                  <p style={{ marginTop: 5, marginBottom: 0 }}>kgCO2e /</p>
                </Col>
                <Col span={19}>
                  <Input value={unitNameOptions.find(i => i.value === unitCode)?.label} disabled />
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="碳足迹来源"
              name="footPrintOrigin"
              rules={[
                {
                  required: true,
                  message: '请选择碳足迹来源',
                },
              ]}
            >
              <Select placeholder="请选择" options={CarbonFootprintSourceOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="系统边界"
              name="boundary"
              rules={[
                {
                  required: true,
                  message: '请选择系统边界',
                },
              ]}
            >
              <Select placeholder="请选择" options={SystemBoundaryOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="状态" name="state">
              <Radio.Group>
                <Radio value={MaterielStatus.ENABLE}>启用</Radio>
                <Radio value={MaterielStatus.DISABLE}>禁用</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注" name="remark">
              <Input.TextArea autoSize={{ minRows: 3 }} maxLength={200} placeholder="请输入1-200字符" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddMaterielModal;
