import {
  Button,
  EllipsisSpan,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  Form,
  FormTitle,
  SubContent,
  Tag,
  TreeSelect,
  Select,
  PopConfirm,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import {
  V2ProductionBaseCompleteTreePostResponse,
  apiV2OeeConfigDeviceListPost,
  apiV2OeeProductionPlanAddPost,
  apiV2OeeProductionPlanDetailPost,
  apiV2OeeProductionPlanEditPost,
  apiV2ProductionBaseCompleteTreePost,
} from '@maxtropy/device-customer-apis-v2';
import { useNavigate, useParams } from 'react-router-dom';
import PlanShutdownModal from '../components/PlanShutdownModal';
import dayjs from 'dayjs';
import {
  PlanItem,
  StateItem,
  StateType,
  StateTypeName,
  StateTypeTag,
  TimeType,
  TimeUnitType,
  TimeUnitTypeName,
} from '../utils';
import CustomStateModal from '../components/CustomStateModal';
import { isNil } from 'lodash-es';

interface AddProductPlanProps {
  isEdit?: boolean;
}

type listTreeProp = Exclude<V2ProductionBaseCompleteTreePostResponse['list'], undefined>[number];
type workCenterTreeProp = Exclude<listTreeProp['workCenterTree'], undefined>[number];
type procedureTreeProp = Exclude<workCenterTreeProp['procedureTree'], undefined>[number];
type stationTreeProp = Exclude<procedureTreeProp['stationTree'], undefined>[number];
type unitTreeProp = Exclude<stationTreeProp['unitTree'], undefined>[number];

type NodeDataProp = listTreeProp & workCenterTreeProp & procedureTreeProp & stationTreeProp & unitTreeProp;

// 生产过程树最多五层，依次为 生产基地 -> 工作中心 -> 工序 -> 工站 -> 用能单元
const TREE_CHILD_KEYS = ['productionBase', 'workCenterTree', 'procedureTree', 'stationTree', 'unitTree'];

function formatToNodeData(data: any, depth: number) {
  return (data ?? []).map((i: any, index: number) => {
    let title = i.name ?? i.procedureName ?? i.stationName ?? i.energyUnitName;
    let id = i.id ?? i.procedureId ?? i.stationId ?? i.energyUnitId;
    let key = TREE_CHILD_KEYS[depth] + '-' + id;

    return {
      title,
      key,
      id,
      children: formatToNodeData(i[TREE_CHILD_KEYS[depth + 1] as keyof NodeDataProp], depth + 1),
      disabled: depth === 0 || i.isOuPermissions === undefined ? false : !i.isOuPermissions,
    };
  });
}

const AddProductPlan: React.FC<AddProductPlanProps> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [planModal, setPlanModal] = useState<boolean>(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [stateList, setStateList] = useState<StateItem[]>([]);
  const [planItem, setPlanItem] = useState<PlanItem>();
  const [stateItem, setStateItem] = useState<StateItem>();
  const [data, setData] = useState<any[]>();
  const [planList, setPlanList] = useState<PlanItem[]>([]);
  const nodeCode = Form.useWatch('nodeCode', form);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 获取关联生产过程
  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      setData(formatToNodeData(res.list, 0));
    });
  }, []);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiV2OeeProductionPlanDetailPost({ id })
      .then(res => {
        form.setFieldsValue({
          nodeCode: res.productionProcessCode,
          appAnalysisSubscriptionName: res.appAnalysisSubscriptionName,
          deviceId: res.deviceConfigId,
        });
        // 计划停机时间
        const pList = (res?.oeeShutdownPlanDtoList ?? []).map(m => {
          if (m.timeType === TimeType.DAY) {
            const timeRange = `${m.startDate}~${m.endDate} ${formTime(m?.startTime as string)}~${formTime(
              m?.endTime as string
            )}`;
            return {
              id: m.id!,
              name: m.name!,
              timeType: m.timeType as TimeType,
              dayDate: [dayjs(m.startDate), dayjs(m.endDate)],
              dayTime: [dayjs(m.startTime as string, 'HH:mm'), dayjs(m.endTime as string, 'HH:mm')],
              timeRange,
              reason: m.reason,
            };
          }
          const times = (m?.oeeShutdownPlanTimeList ?? [])
            .map(k => `${dayjs(k.startTime).format('YYYY-MM-DD HH:mm')}~${dayjs(k.endTime).format('YYYY-MM-DD HH:mm')}`)
            .join('，');
          return {
            id: m.id!,
            name: m.name!,
            timeType: m.timeType as TimeType,
            customTimes: (m?.oeeShutdownPlanTimeList ?? [])?.map(k => [dayjs(k.startTime), dayjs(k.endTime)]),
            timeRange: times,
            reason: m.reason,
          };
        });
        setPlanList(pList);
        // 设备自定义运行状态
        const sList = (res?.oeeCustomPlanDtoList ?? []).map(m => {
          if (m.timeType === TimeType.DAY) {
            const dateRange = `${dayjs(m.startDate).format('YYYY-MM-DD')}~${dayjs(m.endDate).format('YYYY-MM-DD')}`;
            return {
              id: m.id,
              name: m.name!,
              avgCount: m.avgCount,
              deviceType: m.deviceType,
              avgTime: m.avgTime,
              timeUnit: m.timeUnit as TimeUnitType,
              timeType: m.timeType,
              dayDate: [dayjs(m.startDate), dayjs(m.endDate)],
              dateRange,
            };
          }
          const dates = (m.oeeCustomPlanTimeList ?? [])
            .map((k: any) => `${dayjs(k.planDate).format('YYYY-MM-DD')}`)
            .join('，');
          return {
            id: m.id,
            name: m.name!,
            avgCount: m.avgCount,
            deviceType: m.deviceType,
            avgTime: m.avgTime,
            timeUnit: m.timeUnit as TimeUnitType,
            timeType: m.timeType,
            customTimes: (m.oeeCustomPlanTimeList ?? []).map(k => dayjs(k.planDate)),
            dateRange: dates,
          };
        });
        setStateList(sList);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isNil(nodeCode)) return;
    apiV2OeeConfigDeviceListPost({ code: nodeCode }).then(res => {
      setDeviceList(
        (res.list ?? []).map(item => ({
          label: item.name,
          value: item.id,
          ...item,
        }))
      );
    });
  }, [nodeCode]);

  const formTime = (time?: string) => {
    return time?.split(':')?.slice(0, 2)?.join(':');
  };

  // 关联过程树
  const processTreeData = useMemo(() => {
    const loop = (data: any[], node?: any): any[] =>
      data.map(item => {
        const label = node?.label ? node?.label + '-' + item.title : item.title;
        const ids = node?.ids ? node?.ids + '-' + item.id : item.id;
        return {
          title: item.title,
          label,
          ids,
          children: loop(item.children, { ...item, label, ids }),
          disabled: item.disabled,
        };
      });
    return data ? loop(data) : [];
  }, [data]);

  const planColumns = [
    {
      title: '计划名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '时间范围',
      dataIndex: 'timeRange',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setPlanModal(true);
                setPlanItem(record);
              }}
            >
              编辑
            </Button>
            <PopConfirm
              placement="top"
              title="是否确认删除"
              onConfirm={() => {
                const list = planList.filter(k => k.id !== record.id);
                setPlanList(list);
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  const stateColumns = [
    {
      title: '状态',
      dataIndex: 'name',
      width: 240,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '对应设备状态',
      dataIndex: 'deviceType',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string) => (
        <Tag border="solid" color={StateTypeTag[v as unknown as StateType]}>
          {StateTypeName[v as unknown as StateType]}
        </Tag>
      ),
    },
    {
      title: '日期',
      dataIndex: 'dateRange',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '平均单次时长',
      dataIndex: 'avgTime',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string, record: any) => (
        <EllipsisSpan value={String(v) + TimeUnitTypeName[record.timeUnit as TimeUnitType]} />
      ),
    },
    {
      title: '每天平均次数',
      dataIndex: 'avgCount',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setStateItem(record);
                setStateModal(true);
              }}
            >
              编辑
            </Button>
            <PopConfirm
              placement="top"
              title="是否确认删除"
              onConfirm={() => {
                const list = stateList.filter(k => k.id !== record.id);
                setStateList(list);
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  // 编辑计划停机时间
  const eidtPlanTable = (row: any) => {
    setPlanModal(false);
    const prevRowIndex = planList.findIndex(k => k.id === row.id);

    if (prevRowIndex > -1) {
      setPlanList(
        planList.map((p, i) => {
          if (i === prevRowIndex) {
            return row;
          }
          return p;
        })
      );
    } else {
      setPlanList([...planList, row]);
    }
  };

  // 编辑设备自定义状态
  const eidtStateTable = (row: any) => {
    setStateModal(false);
    const prevRowIndex = stateList.findIndex(k => k.id === row.id);

    if (prevRowIndex > -1) {
      setStateList(
        stateList.map((p, i) => {
          if (i === prevRowIndex) {
            return row;
          }
          return p;
        })
      );
    } else {
      setStateList([...stateList, row]);
    }
  };

  const onSave = () => {
    const { deviceId } = form.getFieldsValue();
    const { appAnalysisSubscriptionId, groupUid } = deviceList.find(k => deviceId === k.id);
    const params = {
      appAnalysisSubscriptionId,
      oeeGroupUid: groupUid,
      oeeShutdownPlanDtoList: planList.map(k => {
        if (k.timeType === TimeType.DAY) {
          return {
            name: k.name,
            timeType: k.timeType,
            startDate: dayjs(k.dayDate?.[0]).format('YYYY-MM-DD'),
            endDate: dayjs(k.dayDate?.[1]).format('YYYY-MM-DD'),
            startTime: dayjs(k.dayTime?.[0]).format('HH:mm'),
            endTime: dayjs(k.dayTime?.[1]).format('HH:mm'),
            reason: k.reason,
          };
        }
        return {
          name: k.name,
          timeType: k.timeType,
          reason: k.reason,
          oeeShutdownPlanTimeList: k.customTimes?.map(m => ({
            startTime: dayjs(m[0]).format('YYYY-MM-DD HH:mm'),
            endTime: dayjs(m[1]).format('YYYY-MM-DD HH:mm'),
          })),
        };
      }),
      oeeCustomPlanDtoList: stateList.map(k => {
        if (k.timeType === TimeType.DAY) {
          return {
            name: k.name,
            deviceType: k.deviceType,
            timeType: k.timeType,
            startDate: dayjs(k?.dayDate?.[0]).format('YYYY-MM-DD'),
            endDate: dayjs(k?.dayDate?.[1]).format('YYYY-MM-DD'),
            avgTime: k.avgTime,
            timeUnit: k.timeUnit,
            avgCount: k.avgCount,
          };
        }
        return {
          name: k.name,
          deviceType: k.deviceType,
          timeType: k.timeType,
          avgTime: k.avgTime,
          timeUnit: k.timeUnit,
          avgCount: k.avgCount,
          oeeCustomPlanTimeList: k?.customTimes?.map(n => ({ planDate: dayjs(n).format('YYYY-MM-DD') })),
        };
      }),
    };

    if (!isNil(id)) {
      // @ts-ignore startTime类型问题
      apiV2OeeProductionPlanEditPost({ id, ...params }).then(res => {
        navigate('/energy/analysis/equipmentProductPlan');
      });
    } else {
      // @ts-ignore startTime类型问题
      apiV2OeeProductionPlanAddPost(params).then(res => {
        navigate('/energy/analysis/equipmentProductPlan');
      });
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <FormTitle title={isEdit ? '编辑生产计划' : '新增生产计划'} />
      <SubContent>
        <Spin spinning={loading}>
          <Form form={form}>
            <Row>
              <Col span={12}>
                <Form.Item name="nodeCode" label="生产过程" rules={[{ required: true, message: '请选择生产过程' }]}>
                  <TreeSelect
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    allowClear
                    fieldNames={{ value: 'ids' }}
                    treeNodeLabelProp="label"
                    treeDefaultExpandAll
                    treeData={processTreeData}
                    onChange={() => {
                      form.setFieldValue('deviceId', undefined);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="deviceId" label="设备名称" rules={[{ required: true, message: '请选择设备' }]}>
                  <Select placeholder="请选择" options={deviceList}></Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.tableTitle}>
            <div>计划停机时间</div>
            <Button
              type="dashed"
              onClick={() => {
                setPlanItem(undefined);
                setPlanModal(true);
              }}
              ghost
              icon={<PlusOutlined />}
            >
              新建
            </Button>
          </div>

          <Table rowKey="id" columns={planColumns} dataSource={planList} />
          <div className={styles.tableTitle} style={{ marginTop: 32 }}>
            <div>设备自定义运行状态</div>
            <Button
              type="dashed"
              ghost
              icon={<PlusOutlined />}
              onClick={() => {
                setStateItem(undefined);
                setStateModal(true);
              }}
            >
              新建
            </Button>
          </div>
          <Table rowKey="id" columns={stateColumns} dataSource={stateList} />
          <Space size={8} style={{ marginTop: 32 }}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button
              onClick={() => {
                navigate('/energy/analysis/equipmentProductPlan');
              }}
            >
              取消
            </Button>
          </Space>
        </Spin>
      </SubContent>
      {planModal && (
        <PlanShutdownModal
          visible={planModal}
          planItem={planItem}
          planList={planList}
          onCancel={() => setPlanModal(false)}
          onOk={eidtPlanTable}
        ></PlanShutdownModal>
      )}
      {stateModal && (
        <CustomStateModal
          stateItem={stateItem}
          visible={stateModal}
          stateList={stateList}
          onCancel={() => setStateModal(false)}
          onOk={eidtStateTable}
        ></CustomStateModal>
      )}
    </Wrapper>
  );
};

export default AddProductPlan;
