import { MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse } from '@maxtropy/device-customer-apis';
import {
  apiV2EnergyCompareGetMediumIndicatorLossDataLossDetailPost,
  V2EnergyCompareGetMediumIndicatorLossDataLossDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { DatePicker, Modal } from '@maxtropy/components';
import { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import Chart from '../Chart';
import { getLossDetailChartOption } from '../Chart/lossChartOptions';
import { LossDataPostItemResponse } from '../LossDuringTransAndDisProcess';

interface ILossDetailModal {
  lossDetailVisible: boolean;
  onCancel: () => void;
  selectedProcessName?: string;
  processId?: React.Key;
  currentSelectedLossBasicDetail?: LossDataPostItemResponse;
  mediumIndicatorDisplayUnitData?: MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse;
}

const LossDetailModal: FC<ILossDetailModal> = props => {
  const {
    lossDetailVisible,
    onCancel,
    selectedProcessName,
    processId,
    currentSelectedLossBasicDetail,
    mediumIndicatorDisplayUnitData,
  } = props;
  const [chartLoading, setChartLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<V2EnergyCompareGetMediumIndicatorLossDataLossDetailPostResponse>();
  const [date, setDate] = useState<Dayjs>();

  useEffect(() => {
    if (lossDetailVisible) {
      if (
        currentSelectedLossBasicDetail &&
        currentSelectedLossBasicDetail.maxLossRateTs &&
        currentSelectedLossBasicDetail.energyMediumId
      ) {
        setDate(dayjs(currentSelectedLossBasicDetail.maxLossRateTs as string).startOf('day'));
      } else {
        setDate(dayjs().startOf('day'));
      }
    }
  }, [currentSelectedLossBasicDetail, lossDetailVisible]);

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const onChangePicker: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      setDate(date);
    }
  };

  useEffect(() => {
    if (processId && date) {
      setChartLoading(true);
      apiV2EnergyCompareGetMediumIndicatorLossDataLossDetailPost({
        energyMediumId: currentSelectedLossBasicDetail?.energyMediumId,
        processId: Number(processId),
        timeResolution: '15M',
        from: date.startOf('day').valueOf(),
        to: date.endOf('day').valueOf(),
      }).then(res => {
        setChartData(res);
        setChartLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processId, date]);

  const option = useMemo(
    () =>
      getLossDetailChartOption(
        currentSelectedLossBasicDetail?.energyMediumIndicatorId,
        chartData,
        mediumIndicatorDisplayUnitData
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartData]
  );

  return (
    <Modal
      open={lossDetailVisible}
      title={`${selectedProcessName ?? '--'}/${currentSelectedLossBasicDetail?.energyMediumName ?? '--'}损耗详情`}
      size="big"
      destroyOnClose
      footer={null}
      onCancel={onCancel}
    >
      <div>
        <span>时间：</span>
        <DatePicker value={date} disabledDate={disabledDate} onChange={onChangePicker} allowClear={false} />
      </div>
      <div style={{ marginTop: 20 }}>
        <Chart
          height={450}
          loading={chartLoading}
          chartData={chartData?.exitDataDetailList || chartData?.entryDataDetailList}
          option={option}
        />
      </div>
    </Modal>
  );
};

export default LossDetailModal;
