import { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { V2OeeApplyGetOeeDayPostResponse } from '@maxtropy/device-customer-apis-v2';
import PieDecorationImg from '../../assets/circlebg-small@2x.png';
import PieOuterImg from '../../assets/circlebg-big@2x.png';

import SubTitle from '../subTitle';
import { isEmpty } from 'lodash-es';

const rateInfoName = {
  moreThan80: 'OEE大于80%以上',
  moreThan50: 'OEE 50%~80%',
  lessThan50: 'OEE 50%以下',
  other: '无OEE设备',
};

type ChartData = {
  value?: number;
  key: string;
  name: string;
}[];

const DeviceStatistics = ({ data }: { data?: V2OeeApplyGetOeeDayPostResponse['oeeRateInfo'] }) => {
  const [chartData, setChartData] = useState<ChartData>([]);

  useEffect(() => {
    const oeeRateInfoData: ChartData = isEmpty(data)
      ? Object.entries(rateInfoName).map(([key]) => ({
          name: rateInfoName[key as keyof typeof rateInfoName],
          key,
          value: undefined,
        }))
      : Object.entries(data).map(([key, value]) => ({
          name: rateInfoName[key as keyof typeof rateInfoName],
          key,
          value,
        }));

    setChartData(oeeRateInfoData);
  }, [data]);

  return (
    <div>
      <SubTitle title="OEE统计" style={{ marginBottom: 8 }} />
      <ReactEcharts
        style={{
          width: '100%',
          height: 132,
        }}
        option={getChartOption(chartData, data)}
        notMerge
        lazyUpdate={false}
      />
    </div>
  );
};

const getChartOption = (chartData: ChartData, data: V2OeeApplyGetOeeDayPostResponse['oeeRateInfo']) => {
  return {
    color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: 156,
      top: 'center',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 8,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        rich: {
          name: {
            fontSize: 12,
            lineHeight: 20,
            width: 110,
            color: 'rgba(255,255,255,0.65)',
          },
          moreThan80: {
            color: '#FFCB47',
          },
          moreThan50: {
            color: '#16DD8E',
          },
          lessThan50: {
            color: '#2D8DFF',
          },
          other: {
            color: '#556583',
          },
        },
      },
      data: chartData,
      formatter: (name: string) => {
        const item = chartData?.find(item => item.name === name);
        return `{name| ${item?.name}:}{${item?.key}|${item?.value ?? '-'}}`;
      },
    },
    title: {
      text: isEmpty(data) ? '-' : chartData.reduce((pre, cur) => (cur.value || 0) + pre, 0),
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 14,
        lineHeight: 22,
      },
      subtext: '总设备',
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12,
      },
      left: 60,
      top: 38,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: PieOuterImg,
            x: 0,
            y: 0,
            width: 132,
            height: 132,
          },
        },
        {
          type: 'image',
          style: {
            image: PieDecorationImg,
            x: 28,
            y: 28,
            width: 76,
            height: 76,
          },
          keyframeAnimation: [
            {
              // 动画时长，单位 ms
              duration: 80000,
              // 设置旋转动画是否循环
              loop: true,
              easing: 'linear',
              // 动画的关键帧
              keyframes: [
                {
                  // percent为关键帧的位置,0为第一个,1为最后一个
                  percent: 0,
                  rotation: 0,
                  transition: ['rotation'],
                },
                {
                  percent: 1,
                  rotation: 360,
                  transition: ['rotation'],
                },
              ],
            },
          ],
          // 旋转的中心点
          origin: [66, 66],
          tooltip: {
            show: false,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: '总设备',
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [42, 52],
        left: 4,
        top: 4,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: chartData,
      },
      {
        data: chartData,
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 4,
        top: 4,
        name: '总设备',
        radius: [52, 60],
        width: 124,
        height: 124,
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};

export default DeviceStatistics;
