import useEqual from '@/shared/hooks/useEqual';
import { MultiSelect } from '@maxtropy/components';
import { apiV2OutputProductGetListByCategoryUnitWorkCenterPost } from '@maxtropy/device-customer-apis-v2';
import { Spin, Empty } from 'antd';
import { isNil } from 'lodash-es';
import React from 'react';
import { useEffect, useState } from 'react';

export interface OutputSelectProps {
  value?: number[];
  workCenterIds?: number[];
  categoryId?: number;
  unitCode?: string;
  disabled?: boolean;
  onChange?: (value?: number[]) => void;
}
const OutputSelect: React.FC<OutputSelectProps> = ({
  value,
  onChange,
  disabled,
  categoryId,
  unitCode,
  workCenterIds,
}) => {
  const [options, setOptions] = useState<any[]>();
  const [currentValue, setCurrentValue] = useState<number[]>();
  const newWorkCenterIds = useEqual(workCenterIds);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(unitCode) || isNil(categoryId) || !newWorkCenterIds || !(newWorkCenterIds as number[]).length) {
      setCurrentValue(undefined);
      setOptions([]);
    } else {
      // 获取产出物
      setLoading(true);
      setOptions([]);
      apiV2OutputProductGetListByCategoryUnitWorkCenterPost({
        categoryId,
        unitCode: unitCode,
        workCenterIds,
      })
        .then(res => {
          const list = (res.list ?? []).map(m => ({
            label: m.name,
            value: m.id,
          }));
          setOptions(list);
        })
        .finally(() => setLoading(false));
    }
  }, [newWorkCenterIds, categoryId, unitCode]);

  useEffect(() => {
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectChange = (val: number[]) => {
    setCurrentValue(val);
    onChange?.(val);
  };
  return (
    <MultiSelect
      placeholder="请选择"
      style={{ width: '100%' }}
      disabled={disabled}
      showSearch={false}
      options={options}
      value={currentValue}
      onChange={selectChange}
      notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    ></MultiSelect>
  );
};

export default React.memo(OutputSelect);
