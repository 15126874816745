import { Button, Form, Input, Modal, Select } from '@maxtropy/components';
import { Card, Col, Row } from 'antd';
import React, { FC, useContext } from 'react';
import { ShareDataContext, createdFormLayout } from '../..';
import { CreateUETEMTResponse } from '@/api/uet';
import styles from './index.module.scss';
import DeviceListForm from '../DeviceListForm';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

export interface IProcessExit {
  fieldName: number; // 某一个filedname
  handleTabsActiveKey?: (key: string) => void; // 处理tabs切换
  eMTBasicInfo?: CreateUETEMTResponse;
  entryEnergyMediumList?: {
    id: number;
    name: string;
  }[]; // 输入能源工质列表
  countInAndOutProcessNumber?: (number: number, key: string) => void;
}

const ProcessExit: FC<IProcessExit> = props => {
  const { fieldName, handleTabsActiveKey, entryEnergyMediumList, eMTBasicInfo, countInAndOutProcessNumber } = props;

  const { selectedOuIds, energyMediumIds } = useContext(ShareDataContext);

  return (
    <Form.List name={[fieldName, 'processExitCreateRequests']}>
      {(fields, { add, remove }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {fields.map((field, index) => {
            return (
              <Card
                key={fieldName + field.key}
                // style={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'gray' }}
                className={styles.processExitItemStyle}
                // style={{ height: 280 }}
              >
                <Row key={fieldName + field.key}>
                  <Col span={22}>
                    <Form.Item
                      label="出口名称"
                      {...createdFormLayout}
                      name={[field.name, 'name']}
                      rules={[
                        { required: true, message: '请输入出口名称' },
                        { max: 15, message: '最多输入15个字符！' },
                        { pattern: /^[^/:*?"<>|\\]*$/g, message: '提示：禁用/、\\、:、*、?、"、<、>、|符号' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            let namesList = getFieldValue(['addProcess', fieldName, 'processExitCreateRequests']);
                            let names = namesList?.filter(Boolean).map((item: { name: string }) => item?.name);
                            if (value && value.trim().length) {
                              // 删除自身
                              names.splice(field.name, 1);
                              if (names.includes(value)) {
                                handleTabsActiveKey?.('3');
                                return Promise.reject('出口名称重复！');
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>

                  <Col span={22}>
                    <Form.Item
                      label="能源介质"
                      {...createdFormLayout}
                      name={[field.name, 'energyMediumId']}
                      rules={[{ required: true, message: '请选择能源介质' }]}
                      initialValue={energyMediumIds?.[0] ?? undefined}
                    >
                      <Select
                        placeholder="请选择"
                        options={(entryEnergyMediumList ?? []).map(i => ({ label: i.name, value: i.id }))}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item
                    noStyle
                    dependencies={[
                      ['addProcess', fieldName, 'processExitCreateRequests', field.name, 'energyMediumId'],
                    ]}
                  >
                    {({ getFieldValue }) => {
                      let energyMediumId = getFieldValue([
                        'addProcess',
                        fieldName,
                        'processExitCreateRequests',
                        field.name,
                        'energyMediumId',
                      ]);

                      return (
                        <Col span={22}>
                          <Form.Item label="计量设备" {...createdFormLayout} name={[field.name, 'deviceVos']}>
                            <DeviceListForm
                              energyMediumId={energyMediumId}
                              eMTBasicInfo={eMTBasicInfo}
                              selectedOuIds={selectedOuIds}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>

                  <Col span={2} style={{ position: 'absolute', top: 0, right: 2 }}>
                    <Button
                      type="link"
                      onClick={() => {
                        remove(field.name);
                        countInAndOutProcessNumber?.(fields.length - 1, fieldName + 'out');
                      }}
                      icon={<CloseOutlined />}
                    />
                  </Col>
                </Row>
              </Card>
            );
          })}
          <Card className={styles.addport_exit_itemStyle}>
            <Button
              className={styles.addProcessBtnStyle}
              // style={{ height: 220 }}
              type="link"
              onClick={() => {
                if (fields.length + 1 > 99) {
                  Modal.error({
                    content: `最多添加99个`,
                  });
                } else {
                  add();
                  countInAndOutProcessNumber?.(fields.length + 1, fieldName + 'out');
                }
              }}
              block
              icon={<PlusOutlined />}
            >
              添加输出
            </Button>
          </Card>
        </div>
      )}
    </Form.List>
  );
};

export default ProcessExit;
