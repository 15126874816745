import React, { useState, useCallback, useMemo } from 'react';
import { Space, TreeDataNode, Tree, Checkbox, Typography } from 'antd';
import { Button, Empty, Modal } from '@maxtropy/components';
import { UnitConfGroupItem } from '.';

export type AdaptedUnitModalProps = {
  open: boolean;
  closeModal: () => void;
  setAdaptedUnits: (keys: React.Key[]) => void;
  checkedKeys: React.Key[];
  unitsTreeData: TreeDataNode[];
};

export const flattenUnitsTreeData = (data: TreeDataNode[]): any[] => {
  return data.flatMap(({ title, key, children }) => [
    { title, key, children },
    ...flattenUnitsTreeData(children || []),
  ]);
};

// 适配单元模态框, 用于选择用能分析组
export default function AdaptedUnitModal({
  open,
  closeModal,
  setAdaptedUnits,
  checkedKeys,
  unitsTreeData,
}: AdaptedUnitModalProps) {
  const [checkedUnitKeys, setCheckedUnitKeys] = useState<React.Key[]>(checkedKeys ?? []);
  // 扁平化一维的树
  const flattenedUnitsTree = useMemo(() => {
    return flattenUnitsTreeData(unitsTreeData);
  }, [unitsTreeData]);
  // 是否全选
  const checkedAll = useMemo(() => {
    return flattenedUnitsTree.length === checkedUnitKeys.length;
  }, [checkedUnitKeys.length, flattenedUnitsTree]);

  console.log('flattenedUnitsTree', flattenedUnitsTree);

  const formatUnitsTreeData = useCallback((data: UnitConfGroupItem[]) => {
    return data.map(item => {
      const res: TreeDataNode = {
        ...item,
        key: item.id ?? '',
        title: item.name,
        children: item.childList ? formatUnitsTreeData(item.childList) : [],
      };
      return res;
    });
  }, []);

  return (
    <Modal
      title="适配单元"
      open={open}
      onOk={() => {
        closeModal();
      }}
      onCancel={() => {
        closeModal();
      }}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {unitsTreeData?.length ? (
            <Space>
              <Checkbox
                indeterminate={checkedUnitKeys.length > 0 && checkedUnitKeys.length < flattenedUnitsTree.length}
                checked={checkedAll}
                onChange={e => {
                  if (e.target.checked) {
                    setCheckedUnitKeys(flattenedUnitsTree.map(u => u.key));
                  } else {
                    setCheckedUnitKeys([]);
                  }
                }}
              >
                全选
              </Checkbox>
              <span>
                已选择{' '}
                <Typography.Text style={{ color: 'var(--primary-color)' }}>
                  {checkedUnitKeys.length ?? 0}
                </Typography.Text>{' '}
                项
              </span>
            </Space>
          ) : (
            <div />
          )}
          <Space size="small">
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setAdaptedUnits(checkedUnitKeys);
                closeModal();
              }}
            >
              确定
            </Button>
          </Space>
        </div>
      }
    >
      {unitsTreeData?.length ? (
        <Tree
          checkable
          checkedKeys={checkedUnitKeys}
          checkStrictly
          defaultExpandAll
          treeData={unitsTreeData}
          onCheck={(checkedKeys: any, info) => {
            setCheckedUnitKeys(checkedKeys.checked);
          }}
        />
      ) : (
        <Empty description="暂无用能单元" />
      )}
    </Modal>
  );
}
