import React, { useState } from 'react';
import { Form, Modal, Upload } from '@maxtropy/components';
import { Col, Row, Spin, Typography, Upload as AntdUpload } from 'antd';
import { importSchedule } from '@/api/schedulePlan';
import { Dayjs } from 'dayjs';

type ImportScheduleModalProps = {
  open: boolean;
  closeModal: () => void;
  forceUpdate: () => void;
  unitId: number;
  panelValue: Dayjs;
};

// 导入排班
export default function ImportScheduleModal(props: ImportScheduleModalProps) {
  const { open, closeModal, forceUpdate, unitId, panelValue } = props;
  const [form] = Form.useForm();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  return (
    <>
      <Modal
        size="normal"
        contentClassName="modal-form-content"
        open={open}
        title="导入排班"
        onCancel={() => {
          closeModal();
        }}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{
          disabled: isUploading,
        }}
        cancelButtonProps={{
          disabled: isUploading,
        }}
        okText="导入"
      >
        <Form
          form={form}
          onFinish={(v: any) => {
            const fileKey = v.fileKey;
            if (fileKey) {
              setIsUploading(true);
              importSchedule({
                id: unitId,
                fileKey: fileKey,
              })
                .then(res => {
                  // 错误会抛出异常, 显示第几行第几行错误
                  if (res.code === 200) {
                    Modal.success({
                      content: '导入成功',
                    });
                  } else {
                    Modal.error({
                      title: '部分行导入失败',
                      content: res.msg,
                    });
                  }
                })
                .finally(() => {
                  setIsUploading(false);
                  forceUpdate();
                  closeModal();
                });
            } else {
              form.setFields([{ name: 'fileKey', errors: ['请选择文件'] }]);
            }
          }}
        >
          <Spin spinning={isUploading}>
            <Row justify="end" style={{ marginBottom: '20px' }}>
              <Col>
                <Typography.Link
                  onClick={() => {
                    window.open(
                      `/api/v2/energy/unit/work/shift/apply/export?energyUnitId=${unitId}&date=${panelValue
                        .startOf('month')
                        .format('YYYY-MM-DD')}`,
                      '__blank'
                    );
                  }}
                >
                  下载模板
                </Typography.Link>
              </Col>
            </Row>

            <Form.Item
              name="fileKey"
              label="上传文件"
              rules={[{ required: true, message: '请选择文件' }]}
              extra="支持扩展名：.xlsx, 导入已有排班日覆盖更新配置"
            >
              <Upload
                action="/api/file-center/upload"
                accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                maxCount={1}
                listType="text"
                beforeUpload={file => {
                  const isLt20M = file.size / 1024 / 1024 < 20;
                  const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                  if (!isLt20M) {
                    Modal.warning({
                      content: '上传文件的大小不得超过20M',
                    });
                  }
                  if (!isXLSX) {
                    Modal.warning({
                      content: '文件格式错误！仅支持.xlsx',
                    });
                  }
                  return (isLt20M && isXLSX) || AntdUpload.LIST_IGNORE;
                }}
              >
                {/*<Button icon={<UploadOutlined />}>选择文件</Button>*/}
              </Upload>
            </Form.Item>
          </Spin>
        </Form>
      </Modal>
    </>
  );
}
