import styles from './index.module.scss';
import { CSSProperties, FC, ReactNode } from 'react';
import { Breadcrumb, BreadcrumbProps } from '@maxtropy/components';

export interface BaseWrapperProps extends BreadcrumbProps {
  children: ReactNode;
  className?: string;
  filters?: ReactNode;
  filtersStyle?: CSSProperties;
  headerClassName?: string;
  isLoading?: boolean;
}

const DarkWrapper: FC<BaseWrapperProps> = props => {
  const { children, routes, filters, filtersStyle } = props;
  return (
    <div className={styles.page}>
      <div className={styles.page__header}>
        <Breadcrumb routes={routes} />
      </div>
      <div className={styles.border_wrapper}>
        <div className={styles.border_wrapper_inner}>
          {filters && (
            <div style={filtersStyle} className={styles.page__filter}>
              {filters}
            </div>
          )}
          <div className={styles.page__content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DarkWrapper;
