import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import styles from './index.module.scss';
import { useMemo, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import leftIcon from '../../assets/leftIcon.png';
import rightIcon from '../../assets/rightIcon.png';
import { getCircuitSum } from '@/api/energyOverview';
import { Button, Key } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { pollingInterval } from '../../utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { apiV2EnergyConsumptionOverviewMediumSceneNumberPost } from '@maxtropy/device-customer-apis-v2';
// install Autoplay modules
SwiperCore.use([Autoplay]);

interface CardItem {
  id: Key;
  name: string;
  num: Key;
}

enum EnergyMediumSceneEnum {
  WATER = 500,
  GAS = 503,
  INDUSTRIAL_GASES = 504,
  VENTILATION_AND_AIR = 505,
  INDUSTRIAL_LIQUID = 506,
  COMPRESSED_AIR = 501,
  HOT_STEAM = 502,
  ELEC = 'elec', // 电能
}

const EnergyMediumSceneEnumDisplay = {
  [EnergyMediumSceneEnum.WATER]: '用水监控数',
  [EnergyMediumSceneEnum.GAS]: '空压监控数',
  [EnergyMediumSceneEnum.INDUSTRIAL_GASES]: '工业气体监控数',
  [EnergyMediumSceneEnum.VENTILATION_AND_AIR]: '空调暖通监控数',
  [EnergyMediumSceneEnum.INDUSTRIAL_LIQUID]: '工业液体监控数',
  [EnergyMediumSceneEnum.COMPRESSED_AIR]: '用气监控数',
  [EnergyMediumSceneEnum.HOT_STEAM]: '蒸汽监控数',
  [EnergyMediumSceneEnum.ELEC]: '用电监控数',
};

const EnergyMediumSceneEnumPage = {
  [EnergyMediumSceneEnum.WATER]: '/energyAssets/energyMediumDashboard/water',
  [EnergyMediumSceneEnum.GAS]: '/energyAssets/energyMediumDashboard/compressedAir',
  [EnergyMediumSceneEnum.INDUSTRIAL_GASES]: '/energyAssets/energyMediumDashboard/industrialGas',
  [EnergyMediumSceneEnum.VENTILATION_AND_AIR]:
    '/energyAssets/energyMediumDashboard/heatingVentilationAndAirConditioning',
  [EnergyMediumSceneEnum.INDUSTRIAL_LIQUID]: '/energyAssets/energyMediumDashboard/industrialLiquids',
  [EnergyMediumSceneEnum.COMPRESSED_AIR]: '/energyAssets/energyMediumDashboard/gas',
  [EnergyMediumSceneEnum.HOT_STEAM]: '/energyAssets/energyMediumDashboard/hotSteam',
  [EnergyMediumSceneEnum.ELEC]: `${window.DMEPLATFORMSORIGIN}/dmes/statistics/circuit`,
};

interface MonitorInfoSwiperProps {
  configId?: Key;
}

const MonitorInfoSwiper: React.FC<MonitorInfoSwiperProps> = ({ configId }) => {
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();
  const hasMonitorDetail = useHasPermission(PermissionsType.B_MONITORDETAIL); //

  const { data: circuitSumData } = useRequest(
    () => {
      if (configId) {
        return getCircuitSum({ configId });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId],
    }
  );

  const { data: mediumSceneNumber } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewMediumSceneNumberPost({ energyOverviewConfigId: configId });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId],
    }
  );

  const cardList = useMemo(() => {
    const card: CardItem[] = [];
    if (circuitSumData && circuitSumData > 0) {
      card.push({
        id: EnergyMediumSceneEnum.ELEC,
        name: EnergyMediumSceneEnumDisplay[EnergyMediumSceneEnum.ELEC],
        num: circuitSumData,
      });
    }
    if (mediumSceneNumber && mediumSceneNumber.list && mediumSceneNumber.list.length > 0) {
      card.push(
        ...mediumSceneNumber.list.map(i => ({
          id: i.energyMediumSceneId as Key,
          name: EnergyMediumSceneEnumDisplay[i.energyMediumSceneId as EnergyMediumSceneEnum],
          num: i.mediumSceneSum ?? '--',
        }))
      );
    }
    const emptyLength = 3 - card.length;
    for (let i = 0; i < emptyLength; i++) {
      card.push({
        id: i,
        name: '--',
        num: '--',
      });
    }
    // if (emptyLength > 0) {
    //   const filteredList = card.filter(item => item.name !== '--');
    //   return filteredList;
    // }
    // if (emptyLength > 0) {
    // console.log(card);
    card.splice(card.length - 1, emptyLength);
    console.log(card);
    // console.log(filteredList, 'filteredList');
    // return filteredList;
    // }
    // console.log(card, '12313');
    return card;
  }, [circuitSumData, mediumSceneNumber]);

  const toOnePage = (sceneId: Key) => {
    window.open(EnergyMediumSceneEnumPage[sceneId as EnergyMediumSceneEnum], '_blank');
  };

  const slidesPerView = useMemo(() => {
    const filteredList = cardList.filter(item => item.name !== '--');
    if (filteredList.length === 2) {
      return 2;
    } else if (filteredList.length >= 3) {
      return 3;
    }
    return 1;
  }, [cardList]);

  return (
    <div className={styles.energyStatisticsSwiper}>
      <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slidePrev()}>
        <img src={leftIcon} alt="pic" />
      </div>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        spaceBetween={8}
        slidesPerView={slidesPerView}
        freeMode={true}
        observer={true}
        observeParents={true}
        onSwiper={swiper => setSwiperIntance(swiper)}
      >
        {cardList.map((m, index) => {
          const isIncludes = Object.values(EnergyMediumSceneEnum).includes(m.id);
          const isToPage = () => {
            if (isIncludes && hasMonitorDetail) {
              toOnePage(m.id);
            }
          };
          return (
            <SwiperSlide key={m.id}>
              <div className={styles.cardStyles} onClick={isToPage}>
                <div className={styles.singleCard}>
                  <p>{m?.num}</p>
                  <p>{m?.name}</p>
                </div>
                {hasMonitorDetail && (
                  <Button type="link" disabled={!isIncludes} onClick={isToPage}>
                    查看详情
                  </Button>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slideNext()}>
        <img src={rightIcon} alt="pic" />
      </div>
    </div>
  );
};

export default MonitorInfoSwiper;
