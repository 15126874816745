import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Space } from 'antd';
import { Button, Dropdown, EllipsisSpan, Empty, Modal, Tag, useUpdate } from '@maxtropy/components';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import {
  apiV2GasPreparationStationListPost,
  V2GasPreparationStationListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { UetInfoProps } from '@/api/uet';
import ACSModal from './components/AirCompressionStationModal';
import NGSModal from './components/NitrogenGenerationStationModal';
import FixedBottomButton from '@/components/FixedBottomButton';
import { ACSType, ACSTypeDisplay, OperationType, StationType, StationTypeDisplay, Status } from './utils';
import styles from './index.module.scss';
import { OuInfoProps } from '@/api/ou';
import TopolopyGraph from './components/topologyGraph';

export interface ModalProp {
  onCancel: () => void;
  onOk: () => void;
  ouList?: OuInfoProps[];
  operationType: OperationType;
  uetId?: string;
  stationId?: number;
}

interface Props {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const GasGenerationStation = ({ info, isDetail }: Props) => {
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const navigate = useNavigate();

  const [edit, setEdit] = useState<boolean>(false);
  const [openACSModal, setOpenACSModal] = useState<boolean>(false);
  const [openNGSModal, setOpenNGSModal] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<OperationType>(0);
  const [data, setData] = useState<V2GasPreparationStationListPostResponse['list']>([]);
  const [stationId, setStationId] = useState<number>();
  const [updateState, updateFn] = useUpdate();

  const hasAddAirCompressionStation = useHasPermission(PermissionsType.B_ADDAIRCOMPRESSIONSTATION); // 新建空压站
  const hasAddNitrogenStation = useHasPermission(PermissionsType.B_ADDNITROGENSTATION); // 新建制氮站
  const hasEditAirCompressionStation = useHasPermission(PermissionsType.B_EDITAIRCOMPRESSIONSTATION); // 编辑空压站
  const hasEditNitrogenStation = useHasPermission(PermissionsType.B_EDITNITROGENSTATION); // 编辑制氮站
  const hasEditStationTopology = useHasPermission(PermissionsType.B_EDITSTATIONTOPOLOGY); // 编辑站内拓扑
  const hasAddStationTopology = useHasPermission(PermissionsType.B_ADDSTATIONTOPOLOGY); // 新建站内拓扑

  useEffect(() => {
    // 获取制备站数据
    apiV2GasPreparationStationListPost({ uetId: id }).then(res => {
      setData(res.list ?? []);
    });
  }, [id, updateState]);

  const handleSubmit = () => {
    setOpenACSModal(false);
    setOpenNGSModal(false);
    updateFn();
  };

  const RenderEditStation = (stationType: number, stationId: number) => (
    <Button
      type="link"
      disabled={!edit}
      onClick={() => {
        stationType === StationType.ACS && setOpenACSModal(true);
        stationType === StationType.NGS && setOpenNGSModal(true);
        setOperationType(1);
        setStationId(stationId);
      }}
    >
      编辑站点
      <DoubleRightOutlined />
    </Button>
  );

  const menuItems = useMemo(() => {
    const items = [];
    if (hasAddAirCompressionStation) {
      items.push({
        key: '1',
        label: '新建空压站',
        onClick: () => {
          setOpenACSModal(true);
          setOperationType(0);
          setStationId(undefined);
        },
      });
    }
    if (hasAddNitrogenStation) {
      items.push({
        key: '2',
        label: '新建制氮站',
        onClick: () => {
          setOpenNGSModal(true);
          setOperationType(0);
          setStationId(undefined);
        },
      });
    }

    return items;
  }, [hasAddAirCompressionStation, hasAddNitrogenStation]);

  return (
    <>
      {(hasAddAirCompressionStation || hasAddNitrogenStation) && (
        <Space size={10} style={{ marginBottom: 10 }}>
          <Dropdown
            type="primary"
            disabled={!edit}
            icon={<PlusOutlined />}
            menu={{
              items: menuItems,
            }}
          >
            新建气体制备站
          </Dropdown>
        </Space>
      )}
      {data?.map((item, index: number) => {
        const { stationInfo = {}, topology } = item;
        const {
          stationName,
          stationCode,
          status,
          stationType,
          stationId,
          type,
          loopName,
          flowmeterName,
          ouId,
          ouName,
        } = stationInfo;

        return (
          <div className={styles.stationPanel} key={index}>
            <div className={styles.titleInfo}>
              <div>{stationName}</div>
              <Tag>
                编号：<span style={{ color: 'var(--mx-text-base-color)' }}>{stationCode}</span>
              </Tag>
              {stationType === StationType.ACS && (
                <Tag border="solid" color="#52E7FF">
                  空压站
                </Tag>
              )}
              {stationType === StationType.NGS && (
                <Tag border="solid" color="#ABD335">
                  制氮站
                </Tag>
              )}
              {status === Status.Disabled && (
                <Tag border="solid" color="invalid">
                  禁用
                </Tag>
              )}
              {status === Status.Enable && (
                <Tag border="solid" color="success">
                  启用
                </Tag>
              )}
              {hasEditAirCompressionStation &&
                stationType === StationType.ACS &&
                RenderEditStation(stationType!, stationId!)}
              {hasEditNitrogenStation && stationType === StationType.NGS && RenderEditStation(stationType!, stationId!)}
            </div>
            <div className={styles.stationInfo}>
              <div>
                <label>运营单元编号：</label>
                <EllipsisSpan value={ouId} />
              </div>
              <div>
                <label>运营单元名称：</label>
                <EllipsisSpan value={ouName} />
              </div>
              <div>
                <label>空压站类型：</label>
                <EllipsisSpan value={ACSTypeDisplay[type as keyof typeof ACSTypeDisplay]} />
              </div>
              <div>
                <label>计量回路：</label>
                <EllipsisSpan value={loopName} />
              </div>
              <div>
                <label>排气口流量计：</label>
                <EllipsisSpan value={flowmeterName} />
              </div>
            </div>
            {type === ACSType.SM && (
              <div className={styles.graphContainer}>
                {topology?.nodes && topology?.topologyDirectionList ? (
                  <>
                    {hasEditStationTopology && (
                      <Button
                        type="link"
                        disabled={!edit}
                        wrapStyle={{
                          position: 'absolute',
                          right: 0,
                          zIndex: 2,
                        }}
                        onClick={() =>
                          navigate(
                            `/energy/basic/uet/edit/${id}/${routerKey}/intrasiteTopology?stationId=${stationId}&stationType=${stationType}`
                          )
                        }
                      >
                        编辑站内拓扑
                        <DoubleRightOutlined />
                      </Button>
                    )}
                    <TopolopyGraph data={topology.nodes} />
                  </>
                ) : (
                  <div className={styles.empty}>
                    <Empty
                      description={`暂未创建${
                        StationTypeDisplay[stationType as keyof typeof StationTypeDisplay]
                      }站内拓扑`}
                    />
                    {hasAddStationTopology && (
                      <Button
                        type="link"
                        disabled={!edit}
                        onClick={() =>
                          navigate(
                            `/energy/basic/uet/edit/${id}/${routerKey}/intrasiteTopology?stationId=${stationId}&stationType=${stationType}`
                          )
                        }
                      >
                        开始创建
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
      {openACSModal && (
        <ACSModal
          ouList={info?.ous}
          uetId={id}
          stationId={stationId}
          operationType={operationType}
          onCancel={() => setOpenACSModal(false)}
          onOk={handleSubmit}
        />
      )}
      {openNGSModal && (
        <NGSModal
          ouList={info?.ous}
          uetId={id}
          stationId={stationId}
          operationType={operationType}
          onCancel={() => setOpenNGSModal(false)}
          onOk={handleSubmit}
        />
      )}
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
    </>
  );
};

export default GasGenerationStation;
