import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Space, Col, Row } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'qs';
import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Button,
  Key,
  useUpdate,
  Form,
  CustomFilter,
  Input,
  TreeSelect,
  Modal,
} from '@maxtropy/components';

import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission, useQuery } from '../../utils/utils';

import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../api/device';
import CreateEnergyConsumptionModal from './Create';
import EditEnergyConsumptionModal from './Edit';
import DetailEnergyConsumptionModal from './Detail';
import EnergyCarousel from './EnergyCarousel';
import {
  V2EnergyAnalysisPagePostRequest,
  V2EnergyAnalysisPagePostResponse,
  apiV2EnergyAnalysisDeletePost,
  apiV2EnergyAnalysisPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

interface FilterParams {
  codeOrName?: string;
  mcid?: string;
}

export type AnalysisUnit = Exclude<V2EnergyAnalysisPagePostResponse['list'], undefined>[number];

interface TableDataType extends AnalysisUnit {}

export type SearchParams = Omit<V2EnergyAnalysisPagePostRequest, 'page' | 'size'>;

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const EnergyConsumption: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [organization, setOrganization] = useState<OrganizationResponse>();
  let [urlSearchParams] = useSearchParams();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    codeOrName: urlSearchParams.get('codeOrName')?.length ? urlSearchParams.get('codeOrName')! : undefined,
    mcid: urlSearchParams.get('mcid')?.length ? urlSearchParams.get('mcid')! : undefined,
  });
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const canCreateUnit = useHasPermission(PermissionsType.B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_CREATE);
  const canDelUnit = useHasPermission(PermissionsType.B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_DEL);
  const canEditUnit = useHasPermission(PermissionsType.B_ENERGY_CONSUMPTION_ANALYSIS_UNIT_UPD);
  const canConfUnit = useHasPermission(PermissionsType.B_ENERGY_CONSUMPTION_ANALYSIS_CONF);
  const hasCostConfigPermission = useHasPermission(PermissionsType.B_COST_ALLOCATION);

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);

  const [energyConsumptionId, setEnergyConsumptionId] = useState<Key>();
  const [updateState, updateFn] = useUpdate();
  const [energyConsumptionDetail, setEnergyConsumptionDetail] = useState<AnalysisUnit>();
  const [energyInfoModal, setEnergyInfoModal] = useState<boolean>(false);

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => setOrganization(res));
  }, []);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2EnergyAnalysisPagePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total as number);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  useEffect(() => {
    if (treeData?.length) {
      form.setFieldsValue({
        codeOrName: urlSearchParams.get('codeOrName')?.length ? urlSearchParams.get('codeOrName')! : undefined,
        mcid: urlSearchParams.get('mcid')?.length ? urlSearchParams.get('mcid')! : undefined,
      });
    }
  }, [form, urlSearchParams, treeData]);

  const onFinish = (val: FilterParams) => {
    const params: SearchParams = {
      codeOrName: val.codeOrName,
      mcid: val.mcid,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?${qs.stringify(params)}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      codeOrName: undefined,
      mcid: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const onDelete = (id: number) => {
    Modal.confirm({
      title: '是否删除该用能分析组？',
      content: null,
      okText: '继续',
      onOk: () => {
        apiV2EnergyAnalysisDeletePost({ energyUnitId: id }).then(res => {
          setSearchParams({ ...searchParams });
        });
      },
    });
  };

  const columns = [
    {
      title: '编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属组织',
      dataIndex: 'customerNameList',
      ellipsis: { showTitle: true },
      width: 220,
      render: (v: string[]) => <EllipsisSpan value={(v ?? []).join('、')} />,
    },
    {
      title: '所属运营单元',
      dataIndex: 'ouNames',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string[]) => <EllipsisSpan value={(v ?? []).join('、')} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'lastUpdateUserName',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 420,
      fixed: 'right' as const,
      render: (value: undefined, record: AnalysisUnit) => {
        return (
          <Space size={16}>
            {canDelUnit && (
              <Button
                type="link"
                onClick={() => {
                  onDelete(record.id as number);
                }}
              >
                删除
              </Button>
            )}
            {canEditUnit && (
              <Button
                type="link"
                onClick={() => {
                  setEditModalOpen(true);
                  setEnergyConsumptionId(record.id);
                }}
              >
                编辑
              </Button>
            )}
            {canConfUnit && (
              <Button type="link">
                <Link to={`/energy/consumption/analysis/config/${record.id}?${urlSearchParams.toString()}`}>
                  用能单元配置
                </Link>
              </Button>
            )}
            <Button type="link">
              <Link to={`/energy/consumption/analysis/energyConsumingUnits?id=${record.id}`}>用能单元能耗配置</Link>
            </Button>
            {
              <Button
                type="link"
                onClick={() => {
                  setDetailModalOpen(true);
                  setEnergyConsumptionDetail(record);
                }}
              >
                查看
              </Button>
            }
            {hasCostConfigPermission && (
              <Button type="link">
                <Link to={`/energy/consumption/analysis/cost-config/${record.id}?${urlSearchParams.toString()}`}>
                  成本配置
                </Link>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder={'请输入编号或名称查询'} />
      </Form.Item>

      <Form.Item name="mcid" label="所属组织">
        <TreeSelect dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} treeData={treeData} placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Space style={{ width: '100%' }} direction="vertical" size={10}>
        <div>
          {canCreateUnit && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setCreateModalOpen(true);
                setEnergyConsumptionId(undefined);
              }}
            >
              新建用能分析组
            </Button>
          )}
          <span style={{ paddingLeft: 10 }}>
            基于《ISO 50001能源管理体系》和《GB/T 2589综合能耗计算通则》建立{' '}
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                setEnergyInfoModal(true);
              }}
            >
              能源管理范围边界
            </Button>
          </span>
        </div>

        <Table<TableDataType>
          rowKey="id"
          scroll={{ x: 1200 }}
          dataSource={data}
          loading={isLoading}
          columns={columns}
          sticky
        />
        <Paging pagingInfo={pagingInfo} />
      </Space>
      <CreateEnergyConsumptionModal
        createModalOpen={createModalOpen}
        setCreateModalOpen={setCreateModalOpen}
        updateFn={updateFn}
      />
      <EditEnergyConsumptionModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        energyConsumptionId={energyConsumptionId}
        updateFn={updateFn}
      />
      <DetailEnergyConsumptionModal
        detailModalOpen={detailModalOpen}
        setDetailModalOpen={setDetailModalOpen}
        energyConsumptionDetail={energyConsumptionDetail}
      />
      <Modal
        title="能源管理范围边界"
        open={energyInfoModal}
        size="big"
        width={1000}
        onCancel={() => {
          setEnergyInfoModal(false);
        }}
        onOk={() => {
          setEnergyInfoModal(false);
        }}
      >
        <Row>
          <Col span={9}>
            <div style={{ paddingLeft: 15 }}>
              <p>
                使用【{breadcrumbRoutes && breadcrumbRoutes.routes ? breadcrumbRoutes.routes[2].name : '用能分析组'}
                】功能搭建标准能源管理边界
              </p>
              <p>应该根据组织所界定能源绩效可量化单位，确定能源绩效指标(energy performance indicator,EnPI)。</p>
              <p style={{ display: 'flex', justifyContent: 'flex-end' }}>—— ISO 50001 能源管理体系</p>
              <p>
                针对综合能耗计算的目的和实际情况，边界可以是用能单位的整体，或者是次级用能单位，也可以是其组成部分。
              </p>
              <p style={{ display: 'flex', justifyContent: 'flex-end' }}>—— GB/T 2589 综合能耗计算通则</p>
            </div>
          </Col>
          <Col span={2}>
            <div>Modal</div>
          </Col>
          <Col span={12}>
            <EnergyCarousel />
          </Col>
        </Row>
      </Modal>
    </Wrapper>
  );
};

export default EnergyConsumption;
