import { Input, Modal, Select, Form } from '@maxtropy/components';
import { apiV2CarbonFootprintLcaUpdateProcedurePost } from '@maxtropy/device-customer-apis-v2';
import React, { useEffect } from 'react';
import { LifeCycleStageOptions, MainDataSourcesTypeOptions } from '../../utils';
import { ProcedureDtoType } from '../LifeCycleModel/const';
import { isNil } from 'lodash-es';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  basicInfo?: ProcedureDtoType;
  updateData?: () => void;
}

const BasicInfoModal: React.FC<Props> = ({ open, basicInfo, setOpen, updateData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isNil(basicInfo)) {
      form.setFieldsValue({
        name: basicInfo.name,
        dataOrigin: basicInfo.dataOrigin,
        lifeCycleStage: basicInfo.lifeCycleStage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  const onFinish = (v: any) => {
    if (isNil(basicInfo?.id)) return;
    apiV2CarbonFootprintLcaUpdateProcedurePost({
      id: basicInfo?.id,
      ...v,
    }).then(res => {
      setOpen(false);
      updateData?.();
    });
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      title="过程基本信息"
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ flex: '110px' }} labelAlign="left">
        <Form.Item
          label="过程名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请输入过程名称',
            },
            { max: 50, message: '最多输入50个字符' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="主要数据来源"
          name="dataOrigin"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
        >
          <Select placeholder="请选择" options={MainDataSourcesTypeOptions} />
        </Form.Item>
        <Form.Item label="生命周期阶段" name="lifeCycleStage">
          <Select placeholder="请选择" options={LifeCycleStageOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BasicInfoModal;
