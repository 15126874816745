import { EllipsisSpan, Key } from '@maxtropy/components';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../../index.module.scss';
import { useParams } from 'react-router-dom';
import {
  TimeSharingElectricityAfterAdjustTableDataItem,
  TotalTimeSharingElectricityAfterAdjustListItem,
  getTotalTimeSharingElectricityAfterAdjustTableData,
} from '@/api/universeMeterQuery';
import {
  changeTreeData,
  deleteToTreeData,
  getAllExpandedRow,
  insertExpandToTreeData,
} from '@/pages/UniverseMeterQuery/handleTreeDataUtils';

// 格式化调整后值
const formatAfterAdjustDataToTreeTableData = (
  data: TotalTimeSharingElectricityAfterAdjustListItem[],
  treeTableData: TimeSharingElectricityAfterAdjustTableDataItem[]
) => {
  data.forEach(project => {
    project.rows.forEach((row, index) => {
      treeTableData.push({
        id: `${project.meterReadingObjectId}-${row.deviceId}-${index}`,
        meterReadingObjectId: project.meterReadingObjectId,
        meterReadingObjectName: project.meterReadingObjectName,
        meterReadingLevel: project.meterReadingLevel,
        ...row,
        isHightLight: false,
        expand: index === 0 ? false : undefined,
        children: index === 0 ? (project.list && project.list.length > 0 ? [] : undefined) : undefined,
        child:
          index === 0
            ? project.list && project.list.length > 0
              ? formatAfterAdjustDataToTreeTableData(project.list, [])
              : undefined
            : undefined,
      });
    });
  });
  return treeTableData;
};

interface Props {
  isAllExpand: boolean;
  setIsAllExpand: (v: boolean) => void;
}

const AfterAdjustTable: React.FC<Props> = ({ isAllExpand, setIsAllExpand }) => {
  const { id } = useParams<{ id: string }>();
  const [table, setTable] = useState<TimeSharingElectricityAfterAdjustTableDataItem[]>([]);
  const [expandedTreeData, setExpandedTreeData] = useState<TimeSharingElectricityAfterAdjustTableDataItem[]>([]); // 是否展开的数据
  const [originTable, setOriginTable] = useState<TimeSharingElectricityAfterAdjustTableDataItem[]>([]); // 原始数据
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [allExpandRowKeys, setAllExpandRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTotalTimeSharingElectricityAfterAdjustTableData(id)
        .then(res => {
          if (res) {
            const tableData = formatAfterAdjustDataToTreeTableData(res.list ?? [], []);
            setTable(tableData);
            setExpandedTreeData(tableData);
            setOriginTable(tableData);
            setAllExpandRowKeys(getAllExpandedRow(tableData, []).map(i => i.id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isAllExpand) {
      const allExpandRow = getAllExpandedRow(originTable, []);
      let expandedTree = originTable;
      let tree = originTable;
      allExpandRow.forEach(i => {
        expandedTree = changeTreeData(expandedTree, i.id, true);
        tree = insertExpandToTreeData(expandedTree, tree, i);
      });
      setTable(tree);
      setExpandedTreeData(expandedTree);
      setExpandedRowKeys(allExpandRowKeys);
    } else {
      setExpandedRowKeys([]);
      setExpandedTreeData(originTable);
      setTable(originTable);
    }
  }, [allExpandRowKeys, isAllExpand, originTable]);

  useEffect(() => {
    if (
      expandedRowKeys.length > 0 &&
      allExpandRowKeys.length > 0 &&
      expandedRowKeys.length === allExpandRowKeys.length
    ) {
      setIsAllExpand(true);
      return;
    }
    if (expandedRowKeys.length === 0 && allExpandRowKeys.length !== 0) {
      setIsAllExpand(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRowKeys]);

  // 获取展开节点样式
  const getExpandStyle = (isHightLight?: boolean) => {
    return isHightLight
      ? {
          backgroundColor: '#29292C',
        }
      : {};
  };

  // 获取调整后值行合并
  const getAfterAdjustRowSpan = (record: TimeSharingElectricityAfterAdjustTableDataItem, index?: number) => {
    const firstIndex = table.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
    const objectOccupyRows = table.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
    if (index === firstIndex) {
      return objectOccupyRows;
    }
    return 0;
  };

  const columns = [
    {
      title: '层级',
      dataIndex: 'meterReadingLevel',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      onCell: (record: TimeSharingElectricityAfterAdjustTableDataItem, index?: number) => ({
        rowSpan: getAfterAdjustRowSpan(record, index),
        style: getExpandStyle(record.isHightLight),
      }),
      render: (v: number) => {
        return <EllipsisSpan value={v ? `${v}级` : v} />;
      },
    },
    {
      title: '抄表对象',
      dataIndex: 'meterReadingObjectName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        rowSpan: getAfterAdjustRowSpan(record, index),
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '类型',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
      isHide: true,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '对应拓扑节点',
      dataIndex: 'topologyNodeName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      isHide: true,
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '调整后 (尖)',
      dataIndex: 'summitAfterAdjustAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '调整后 (峰)',
      dataIndex: 'peakAfterAdjustAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '调整后 (平)',
      dataIndex: 'plainAfterAdjustAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '调整后 (谷) ',
      dataIndex: 'valleyAfterAdjustAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '尖峰平谷合计',
      dataIndex: 'totalAfterAdjustAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '调整后抄表值',
      dataIndex: 'afterAdjustMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
  ];

  return (
    <>
      <div className={styles.tableStyles}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={table}
          rowKey="id"
          pagination={false}
          bordered
          sticky
          expandable={{
            onExpand: (isShow, record) => {
              if (isShow) {
                setExpandedRowKeys([...expandedRowKeys, record.id]);
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, true));
                setTable(insertExpandToTreeData(expandedTreeData, table, record));
              } else {
                setExpandedRowKeys(expandedRowKeys.filter(i => i !== record.id));
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, false));
                setTable(deleteToTreeData(expandedTreeData, table, record));
              }
            },
            expandedRowKeys,
          }}
        />
      </div>
    </>
  );
};
export default AfterAdjustTable;
