import { getOrganizationWithCodeWithCurrent } from '@/api/device';
import { Customers } from '@/api/teamGroup';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Key,
  Paging,
  PopConfirm,
  Table,
  TreeSelect,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Space } from 'antd';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useEffect, useState } from 'react';
import CreateTeamModal from './CreateTeamModal';
import styles from './index.module.scss';
import {
  apiV2WorkTeamDeletePost,
  apiV2WorkTeamPagePost,
  V2WorkTeamPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { PlusOutlined } from '@ant-design/icons';

function formatTreeData(data: any[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
}

const columns = [
  {
    title: '班组名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '班组编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '人员数量',
    dataIndex: 'workerNum',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customers',
    ellipsis: { showTitle: true },
    render: (v: Customers[]) => <EllipsisSpan value={v.map(i => i.name).join('、')} />,
  },
  {
    title: '班组负责人',
    dataIndex: 'leaderWorkerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams {
  mcids?: string;
}

export type TeamGroupListRes = Exclude<V2WorkTeamPagePostResponse['list'], undefined>[number];

const TeamGroupManage: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [teamList, setTeamList] = useState<TeamGroupListRes[]>([]);
  const [teamId, setTeamId] = useState<Key>();

  const hasAddPermission = useHasPermission(PermissionsType.B_WORKTEAMCREATE); // 新建班组
  const hasUpdatePermission = useHasPermission(PermissionsType.B_WORKTEAMUPDATE); // 编辑班组
  const hasDetailPermission = useHasPermission(PermissionsType.B_WORKTEAMDETAIL); // 查看班组
  const hasDeletePermission = useHasPermission(PermissionsType.B_WORKTEAMDELETE); // 查看班组
  const [update, updateFn] = useUpdate();

  useEffect(() => {
    // 查询所属组织
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    apiV2WorkTeamPagePost({
      mcids: searchParams.mcids ? [searchParams.mcids] : undefined,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTeamList(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onFinish = (v: SearchParams) => {
    finish({ ...v });
  };
  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
      <Form.Item name="mcids" label="所属组织">
        <TreeSelect
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          allowClear
        />
      </Form.Item>
    </CustomFilter>
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string, record: TeamGroupListRes) => {
        return (
          <Space size={16}>
            {hasDetailPermission && (
              <Button
                type="link"
                onClick={() => {
                  setTeamId(record.id);
                  setDetailModalOpen(true);
                }}
              >
                查看
              </Button>
            )}

            {hasUpdatePermission && (
              <Button
                type="link"
                onClick={() => {
                  setEditModalOpen(true);
                  setTeamId(record.id);
                }}
              >
                编辑
              </Button>
            )}

            <PopConfirm
              placement="topLeft"
              title={
                <>
                  <p>确认删除该班组？</p>
                  <p>会清除未发生的排班设置，不影响历史数据。</p>
                </>
              }
              onConfirm={() => {
                apiV2WorkTeamDeletePost({ id: record.id }).then(() => {
                  updateFn();
                });
              }}
              okText="确定"
              cancelText="取消"
            >
              {hasDeletePermission && <Button type="link">删除</Button>}
            </PopConfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} filters={filters} className={styles.wrapper}>
      <Space style={{ marginBottom: 10 }}>
        {hasAddPermission && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            新建班组
          </Button>
        )}
      </Space>

      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1300 }}
        dataSource={teamList}
        loading={loading}
        columns={buildColumns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />

      {createModalOpen && (
        <CreateTeamModal
          updateFn={updateFn}
          modalOpen={createModalOpen}
          setModalOpen={setCreateModalOpen}
          treeData={treeData}
        />
      )}
      {editModalOpen && (
        <CreateTeamModal
          updateFn={updateFn}
          teamId={teamId}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          treeData={treeData}
          isEdit={true}
        />
      )}
      {detailModalOpen && (
        <CreateTeamModal
          teamId={teamId}
          modalOpen={detailModalOpen}
          setModalOpen={setDetailModalOpen}
          treeData={treeData}
          isDetail={true}
        />
      )}
    </Wrapper>
  );
};

export default TeamGroupManage;
