import { Col, Form, Row } from 'antd';
import { FC, useEffect } from 'react';
import { BaseElectricityBillResponse } from '../../../../api/electricity';
import { borderStyle } from '../utils';
import { ShowInput } from '@maxtropy/components';

export interface ElectricityOperatorProps {
  data?: BaseElectricityBillResponse;
}

const ElectricityOperator: FC<ElectricityOperatorProps> = ({ data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        operatingStaff: data.operatingStaff,
      });
    }
  }, [data, form]);

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={8} style={borderStyle}>
        <Col span={4}>
          <Form.Item label="抄表员" name={['operatingStaff', 'meterReader']}>
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="结算员" name={['operatingStaff', 'settlementClerk']}>
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="审核员" name={['operatingStaff', 'auditor']}>
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="复核员" name={['operatingStaff', 'reviewer']}>
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="销售单位" name={['operatingStaff', 'salesOrg']}>
            <ShowInput />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="开票地址" name={['operatingStaff', 'invoiceAddress']}>
            <ShowInput />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ElectricityOperator;
