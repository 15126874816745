import styles from './index.module.scss';
import CalculateTable from '../components/CalculateTable';
import LCAView from './../components/LCAView';
import { useNavigate } from 'react-router-dom';
import { Button, FormContent, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Space, Form } from 'antd';
import { CalculateDataType, FirstDataType } from '../components/AddFilling/const';
import { formatTreeData } from '../components/AddFilling';
import { useEffect, useState } from 'react';
import {
  apiV2CarbonFootprintCalculateDataDetailPost,
  apiV2CarbonFootprintCalculateDetailPost,
  V2CarbonFootprintCalculateDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

const CarbonFootprintDetailFiling: React.FC = () => {
  const routes = [{ name: '详情' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const urlSearchParams = new URLSearchParams(window.location.search);
  let id = urlSearchParams.get('id');
  const navigator = useNavigate();
  const [lcaData, setLcaData] = useState<V2CarbonFootprintCalculateDetailPostResponse>();

  const [calculateData, setCalculateData] = useState<CalculateDataType>();
  const [calculateTableData, setCalculateTableData] = useState<CalculateDataType[]>([]);
  const [firstStepData, setFirstStepData] = useState<FirstDataType>();

  useEffect(() => {
    apiV2CarbonFootprintCalculateDetailPost({ id: Number(id) }).then(res => {
      setLcaData(res);
      const newFirstData = {
        carbonLcaId: res.id,
        quantity: res.quantity,
        productionStartTime: dayjs(res.productionStartTime),
        productionEndTime: dayjs(res.productionEndTime),
        productSheetCode: res.productSheetCode,
        productSheetId: res.productSheetId,
        unitCode: res.unitCode,
      };
      setFirstStepData(newFirstData);
    });

    apiV2CarbonFootprintCalculateDataDetailPost({ id: Number(id) }).then(res => {
      const Array = [];
      Array.push(res);
      setCalculateData(res as CalculateDataType);
      setCalculateTableData(formatTreeData(Array));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperContent}>
      <FormContent title="LCA详情" style={{ padding: 0 }}>
        <Form labelAlign="left" layout={'vertical'}>
          <div className={styles.content}>
            {!isNil(lcaData) ? (
              <LCAView
                lcaData={lcaData}
                calculateData={calculateData}
                id={Number(id)}
                firstStepData={firstStepData}
              ></LCAView>
            ) : (
              <></>
            )}
            {!isNil(calculateData) ? (
              <CalculateTable
                firstStepData={firstStepData}
                calculateData={calculateData}
                calculateTableData={calculateTableData}
              ></CalculateTable>
            ) : (
              <></>
            )}
            <Space size="small" className={styles.stickyShadowFooter} style={{ paddingLeft: 0 }}>
              <Button
                onClick={() => {
                  navigator(`/productCarbonFootPrint/basic/reporting`);
                }}
              >
                返回列表
              </Button>
            </Space>
          </div>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default CarbonFootprintDetailFiling;
