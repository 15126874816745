import React from 'react';
import { ControlProps } from '.';
import { OptionsConstraintValue } from '../../types';
import { Select } from '@maxtropy/components';

const SingleSelect: React.FC<ControlProps> = ({
  placeholder,
  disabled,
  isDetail,
  constraintValue,
  value,
  onChange,
}) => {
  const items = constraintValue as OptionsConstraintValue;
  if (isDetail) {
    return <div>{value?.value as string | number}</div>;
  }
  return (
    <Select
      style={{ width: 160 }}
      placeholder={placeholder ?? '请选择'}
      disabled={disabled}
      value={value?.value as string | number}
      onChange={v => {
        onChange && onChange({ ...value, value: v });
      }}
    >
      {items.map(item => (
        <Select.Option key={item} value={item}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SingleSelect;
