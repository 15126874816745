import {
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useAsync,
  Form,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Cascader, Col } from 'antd';
import { ColumnType } from 'antd/es/table';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  ElectricAccountDevice,
  ElectricAccountDevicePageRequest,
  getSaleClientElectricityAccountList,
  getSaleMeterDevicePage,
  SaleMeterDevices,
} from '../../../../../api/sale';

import styles from './DeviceModal.module.scss';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { ElectricityAccountVo } from '../../../../../api/electricity';
import MeterTags from './MeterTags';
import { DefaultOptionType } from 'antd/es/cascader';
import { getDeviceTypeTree } from '@/api/deviceType';

export interface SearchParams extends Omit<ElectricAccountDevicePageRequest, 'page' | 'size' | 'saleClientId'> {}

export interface FilterParams extends Omit<SearchParams, 'deviceTypeIds'> {
  deviceTypeIds: number[][];
}

const columns: ColumnType<ElectricAccountDevice>[] = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DeviceModalProps {
  saleClientId: number;
  buttonStatus?: 'error' | 'warning' | '';
}

export interface DeviceModalRefProps {
  getValue: () => SaleMeterDevices[];
  getMeterTags: () => string[] | null;
}

export function getDeviceKey(data: SaleMeterDevices) {
  return `${data.deviceId}--@--${data.electricityAccountId}`;
}

const DeviceModal: ForwardRefRenderFunction<DeviceModalRefProps, DeviceModalProps> = (
  { saleClientId, buttonStatus },
  refs
) => {
  const [form] = Form.useForm();

  const [devices, setDevices] = useState<ElectricAccountDevice[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const [value, setValue] = useState<SaleMeterDevices[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const meterTagsRef = useRef<string[]>(null);

  useImperativeHandle(
    refs,
    () => ({
      getValue() {
        return value;
      },
      getMeterTags() {
        return meterTagsRef?.current;
      },
    }),
    [value, meterTagsRef]
  );

  useEffect(() => {
    if (saleClientId) {
      setLoading(true);
      getSaleMeterDevicePage({
        ...searchParams,
        saleClientId: saleClientId,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        setDevices(res.list);
        setTotalCount(res.total);
      });
    }
  }, [saleClientId, pageOffset, pageSize, searchParams, setTotalCount]);

  const [electricityAccount, setElectricityAccount] = useState<ElectricityAccountVo[]>([]);

  useEffect(() => {
    if (saleClientId) {
      getSaleClientElectricityAccountList(saleClientId).then(setElectricityAccount);
    }
  }, [saleClientId]);

  const onFinish = (val: FilterParams) => {
    // const deviceTypeIds = getAllChild(val.deviceTypeIds, formatOptionData(deviceTypeData));
    const deviceTypeIds = getChildNodesByParentIds(val.deviceTypeIds, deviceTypeData?.tree);
    setPageOffset(1);
    setSearchParams({
      ...val,
      deviceTypeIds,
    });
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams(undefined);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ElectricAccountDevice[]) => {
      const currentPageDeviceIds = devices.map(i => getDeviceKey(i));
      setValue?.([
        ...(value ?? []).filter(i => !currentPageDeviceIds.includes(getDeviceKey(i))),
        ...selectedRows.map(i => ({
          deviceId: i.deviceId,
          electricityAccountId: i.electricityAccountId,
        })),
      ]);
    },
    selectedRowKeys: (value ?? []).map(i => getDeviceKey(i)),
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter form={form} colSpan={8} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Form.Item label="编号/名称" name="codeOrName">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item label="用电账户" name="electricityAccountId">
        <Select placeholder="请选择">
          {electricityAccount.map(i => (
            <Select.Option value={i.id} key={i.id}>
              {i.number}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="标签" name="deviceTag">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="deviceTypeIds" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <div className={styles.wrapper}>
      {filters}
      <div className={styles.tips}></div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        showSelectedCount
        selectedCount={(value ?? []).length}
        scroll={{ y: 300 }}
        rowKey={record => getDeviceKey(record)}
        columns={columns}
        dataSource={devices}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>

      <Col span={24} style={{ marginTop: 20 }}>
        <MeterTags ref={meterTagsRef} saleClientId={saleClientId} buttonStatus={buttonStatus} />
      </Col>
    </div>
  );
};

export default forwardRef(DeviceModal);
