import { DatePicker, Radio } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { TimeGranularity, TimeGranularityDisplay, TimeGranularityInitialValue } from '../../ProductionUnitConsumption';
import { Space } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();

type DateSwitchProps = {
  timeGranularity: TimeGranularity;
  setTimeGranularity: (v: TimeGranularity) => void;
  value: RangeValue;
  setValue: (v: RangeValue) => void;
};

const DateSwitch = ({ timeGranularity, setTimeGranularity, value, setValue }: DateSwitchProps) => {
  const disabledDate: DatePickerProps['disabledDate'] = (current, { from, type }) => {
    if (current && current > dayjs().endOf('day')) return true;
    if (from) {
      // 按小时 - 最多选择3天，最少选择一天
      // 按天 - 最多选择31天，最少选择1天
      // 按月 - 最多选择12个月
      if (TimeGranularity.DAY === timeGranularity) {
        return Math.abs(current.diff(from, 'days')) >= 31;
      } else if (TimeGranularity.HOUR === timeGranularity) {
        return Math.abs(current.diff(from, 'days')) >= 3;
      } else if (TimeGranularity.MONTH === timeGranularity) {
        return (
          getYearMonth(current) < getYearMonth(from.add(-11, 'months')) ||
          getYearMonth(current) > getYearMonth(from.add(11, 'months'))
        );
      }
    }
    return false;
  };

  return (
    <Space size={8}>
      <Radio.Group
        options={Object.entries(TimeGranularityDisplay).map(([value, label]) => ({
          label,
          value,
        }))}
        onChange={e => {
          // 设置时间颗粒度和时间
          setTimeGranularity(e.target.value);
          setValue(TimeGranularityInitialValue[e.target.value]);
        }}
        value={timeGranularity}
        optionType="button"
        buttonStyle="solid"
      />
      <DatePicker.RangePicker
        allowClear={false}
        picker={timeGranularity === TimeGranularity.MONTH ? 'month' : 'date'}
        value={value}
        disabledDate={disabledDate}
        onChange={val => {
          if (val[0] && val[1]) {
            setValue([
              val[0]?.startOf(timeGranularity === TimeGranularity.MONTH ? 'month' : 'date'),
              val[1]?.endOf(timeGranularity === TimeGranularity.MONTH ? 'month' : 'date'),
            ]);
          }
        }}
      />
    </Space>
  );
};

export default DateSwitch;
