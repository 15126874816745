/**
 *
 * @param targetContainer 目标容器
 * @param currentClientWidth 目标容器所在的宽度
 * @param currentClientWidth 目标容器所在的高度
 */
export const setFitScale = (
  targetContainer?: HTMLDivElement,
  currentClientWidth?: number,
  currentClientHeight?: number
) => {
  if (!targetContainer || !currentClientWidth || !currentClientHeight) return;
  const designWidth = 1920;
  // const designHeight = 1030;

  let scale = currentClientWidth / designWidth;

  // 实际容器的高度(减去页面顶部导航栏的高度)
  let containerHeight = document.documentElement.clientHeight - 50;

  console.log('当前containerHeight', containerHeight);

  /**
   * 1. 当前容器的宽度>=设计稿的宽度时，才进行缩放
   * 2. 先等比缩放, 只考虑宽度, 高度未撑满的话, 取容器高度, 然后再进行flex布局
   *
   */

  if (currentClientWidth >= designWidth) {
    // let currentH = containerHeight >= designHeight ? containerHeight : designHeight;
    let currentH = containerHeight;

    targetContainer.style.width = `${designWidth}px`;

    targetContainer.style.height = `${currentH / scale}px`;
    targetContainer.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    targetContainer.style.transformOrigin = 'top left';

    return;
  }
  targetContainer.style.width = '';
  targetContainer.style.height = '';
  targetContainer.style.transform = '';
  targetContainer.style.transformOrigin = '';
};

export const setFitScaleFull = (
  targetContainer?: HTMLDivElement,
  currentClientWidth?: number,
  currentClientHeight?: number
) => {
  if (!targetContainer || !currentClientWidth || !currentClientHeight) return;
  const designWidth = 1920;
  // const designHeight = 1030;

  let scale = currentClientWidth / designWidth;

  // 实际容器的高度(减去页面顶部导航栏的高度)
  let containerHeight = document.documentElement.clientHeight;

  console.log('当前containerHeight', containerHeight);

  /**
   * 1. 当前容器的宽度>=设计稿的宽度时，才进行缩放
   * 2. 先等比缩放, 只考虑宽度, 高度未撑满的话, 取容器高度, 然后再进行flex布局
   *
   */

  if (currentClientWidth >= designWidth) {
    // let currentH = containerHeight >= designHeight ? containerHeight : designHeight;
    let currentH = containerHeight;

    targetContainer.style.width = `${designWidth}px`;

    targetContainer.style.height = `${currentH / scale}px`;
    targetContainer.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    targetContainer.style.transformOrigin = 'top left';

    return;
  }
  targetContainer.style.width = '';
  targetContainer.style.height = '';
  targetContainer.style.transform = '';
  targetContainer.style.transformOrigin = '';
};
