import useEqual from '@/shared/hooks/useEqual';
import { Select } from '@maxtropy/components';
import { apiV2OutputProductUnitsPost } from '@maxtropy/device-customer-apis-v2';
import { Empty, Spin } from 'antd';
import { isNil } from 'lodash-es';
import React from 'react';
import { useEffect, useState } from 'react';

export interface UnitSelectProps {
  value?: number;
  workCenterIds?: number[];
  categoryId?: number;
  disabled?: boolean;
  onChange?: (value?: number, typeCode?: number) => void;
}
const UnitSelect: React.FC<UnitSelectProps> = ({ value, onChange, disabled, categoryId, workCenterIds }) => {
  const [options, setOptions] = useState<any[]>();
  const [currentValue, setCurrentValue] = useState<number>();
  const newWorkCenterIds = useEqual(workCenterIds);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(categoryId) || !newWorkCenterIds || !(newWorkCenterIds as number[]).length) {
      setCurrentValue(undefined);
      setOptions([]);
    } else {
      // 获取产出物单位
      setLoading(true);
      setOptions([]);
      apiV2OutputProductUnitsPost({
        categoryId,
        workCenterIds: newWorkCenterIds,
      })
        .then(res => {
          const list = (res.list ?? [])
            .map(m =>
              m.outputProductUnitList?.map(k => ({
                unitTypeCode: m.unitTypeCode,
                unitTypeName: m.unitTypeName,
                ...k,
                label: k.unitName!,
                value: k.unitCode!,
              }))
            )
            .flat();
          setOptions([...list]);
        })
        .finally(() => setLoading(false));
    }
  }, [newWorkCenterIds, categoryId]);

  useEffect(() => {
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectChange = (val: number) => {
    setCurrentValue(val);
    const unitTypeCode = options?.find((item: any) => item.value === val)?.unitTypeCode;
    onChange?.(val, unitTypeCode);
  };
  return (
    <Select
      placeholder="请选择"
      style={{ width: '100%' }}
      allowClear
      disabled={disabled}
      options={options}
      value={currentValue}
      onChange={selectChange}
      notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    ></Select>
  );
};

export default React.memo(UnitSelect);
