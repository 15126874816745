import { FC } from 'react';
import {
  antiBookkeepingRequest,
  BaseElectricityBillResponse,
  electricityBillReverseProcess,
  invoicingBill,
} from '../../../../api/electricity';
import { Dayjs } from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { DatePicker, Form, Modal } from '@maxtropy/components';

export enum BillOperator {
  invoicing,
  Reverse,
  antiBookkeeping,
}

export const BillOperatorDisplay: Record<BillOperator, string> = {
  [BillOperator.invoicing]: '请选择开票日期',
  [BillOperator.Reverse]: '打回原因',
  [BillOperator.antiBookkeeping]: '反记账',
};

export interface BaseElectricityOperatorModalProps {
  record: BaseElectricityBillResponse;
  op: BillOperator;
}

interface Props {
  electricityOperatorRecord?: BaseElectricityOperatorModalProps;
  changeVisible: (v?: BaseElectricityOperatorModalProps) => void;
  refresh: () => void;
  setPageOffset?: (d: number) => void;
}

const ElectricityBillModal: FC<Props> = props => {
  const [form] = Form.useForm();
  const { changeVisible, refresh, electricityOperatorRecord, setPageOffset } = props;

  const handleOk = () => {
    if (!electricityOperatorRecord) return;
    form.validateFields().then((val: { invoiceTime: Dayjs; description: string }) => {
      const { op, record } = electricityOperatorRecord;
      if (val) {
        if (electricityOperatorRecord.op === BillOperator.invoicing) {
          invoicingBill({
            billId: record.id,
            status: record.status,
            invoiceTime: val.invoiceTime.format('YYYY-MM-DD'),
          }).then(() => {
            handleCancel();
            setPageOffset?.(1);
            refresh();
          });
        }
        if (op === BillOperator.antiBookkeeping) {
          antiBookkeepingRequest({
            description: val.description,
            id: record.id,
          }).then(() => {
            handleCancel();
            setPageOffset?.(1);
            refresh();
          });
        }
        if (op === BillOperator.Reverse) {
          electricityBillReverseProcess({
            billId: record.id,
            status: record.status,
            reason: val.description,
          }).then(() => {
            handleCancel();
            setPageOffset?.(1);
            refresh();
          });
        }
      }
    });
  };

  const handleCancel = () => {
    changeVisible(undefined);
    form.resetFields();
  };
  const formItemLayout = {
    // labelCol: {span: 6},
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Modal
        size="normal"
        title={electricityOperatorRecord ? BillOperatorDisplay[electricityOperatorRecord.op] : ''}
        centered
        open={!!electricityOperatorRecord}
        onCancel={handleCancel}
        destroyOnClose
        onOk={handleOk}
        okButtonProps={{ htmlType: 'submit' }}
      >
        <Form form={form} {...formItemLayout}>
          {electricityOperatorRecord?.op === BillOperator.invoicing ? (
            <Form.Item name="invoiceTime" label="开票日期" rules={[{ required: true, message: '请选择开票日期' }]}>
              <DatePicker />
            </Form.Item>
          ) : (
            <Form.Item name="description" label="原因">
              <TextArea autoSize={{ minRows: 5 }} maxLength={100} placeholder="请输入" />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ElectricityBillModal;
