import { UetInfoProps } from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import FixedBottomButton from '@/components/FixedBottomButton';
import BackUpPowerModal from '@/pages/UET/Edit/BackUpPower/components/BackUpPowerModal';
import { BackUpPowerType, BackUpPowerTypeLabel } from '@/pages/UET/Edit/BackUpPower/components/backUpPowerModalTypes';
import { List } from '@/pages/UET/Edit/BackUpPower/types';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  EllipsisSpan,
  Modal,
  Table,
  useTenantPermissions,
  useUpdate,
  message,
  Tag,
} from '@maxtropy/components';
import { apiV2UetBackupPowerListPost } from '@maxtropy/device-customer-apis-v2';
import { Space } from 'antd';

import { isNil } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const otherColumns = [
  {
    title: '电源编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={<Tag color={'blue'}>{v}</Tag>} />,
  },
  {
    title: '电源名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电源类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: BackUpPowerType) => <EllipsisSpan value={BackUpPowerTypeLabel[v]} />,
  },
  {
    title: '关联回路名称',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联设备',
    dataIndex: 'relatedDeviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface Props {
  info?: UetInfoProps;
  isDetail?: boolean;
}
const BackUpPower: React.FC<Props> = ({ info, isDetail }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id } = useParams<{ id: string }>();
  const [state, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [currentRowInfo, setCurrentRowInfo] = useState<List>();

  const [backUpPowerList, setBackUpPowerList] = useState<List[]>();

  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;

  const permission = useTenantPermissions();

  const showAddBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_ADDPOWERSUPPLY) && !isDetail;
  }, [isDetail, permission]);
  const showEditBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_EDITPOWERSUPPLY) && !isDetail;
  }, [isDetail, permission]);

  const onAddLoad = () => {
    setModalVisible(true);
  };

  const onEditBackUpPower = (record: List) => {
    setCurrentRowInfo(record);
    setModalVisible(true);
  };

  const onSave = () => {
    const unfinished = backUpPowerList?.some(i => !i.id);
    if (unfinished) {
      message.warning('有未保存的负载');
    } else {
      setEdit(false);
    }
  };

  const onCancel = () => {
    Modal.confirm({
      title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
      onOk: () => {
        forceUpdate();
        setEdit(false);
      },
    });
  };

  useEffect(() => {
    if (isNil(id)) return;
    apiV2UetBackupPowerListPost({
      id: +id,
    }).then(res => {
      setBackUpPowerList(res.list ?? []);
    });
  }, [id, state]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: any) =>
        showEditBtn && (
          <Button type="link" disabled={btnDisabled} onClick={() => onEditBackUpPower(record)}>
            编辑电源
          </Button>
        ),
    },
  ];

  return (
    <>
      {showAddBtn && (
        <Button type="primary" onClick={onAddLoad} disabled={btnDisabled}>
          <PlusOutlined />
          新建电源
        </Button>
      )}

      <Table style={{ marginTop: 12 }} rowKey="id" dataSource={backUpPowerList} columns={columns} pagination={false} />

      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>

      {modalVisible && (
        <BackUpPowerModal
          modalVisible={modalVisible}
          setModalVisible={val => setModalVisible(val)}
          ouList={info?.ous}
          currentRowInfo={currentRowInfo}
          setCurrentRowInfo={val => setCurrentRowInfo(val)}
          forceUpdate={() => forceUpdate()}
        />
      )}
    </>
  );
};

export default BackUpPower;
