import { V2AppAnalysisSubscriptionDeviceGetPostResponse } from '@maxtropy/device-customer-apis-v2';
import { isNil, isString } from 'lodash-es';

export enum ModalClickType {
  // 点击保存
  SAVE = 'save',
  // 点击取消
  CANCEl = 'cancel',
}

export enum ModalType {
  // 新增
  ADD = 'add',
  // 编辑
  EDIT = 'edit',
}

// 状态、动作特征
export enum FeatureType {
  // 瞬时量-爬升速率
  CLIMB_RATE = 1,
  // 瞬时量-下降速率
  DESCENT_RATE,
  // 瞬时量-瞬时值
  INSTANT_VALUE,
}

export const FeatureTypeDisPlay = {
  [FeatureType.CLIMB_RATE]: '瞬时量-爬升速率',
  [FeatureType.DESCENT_RATE]: '瞬时量-下降速率',
  [FeatureType.INSTANT_VALUE]: '瞬时量-瞬时值',
};

export const FeatureTypeOptions = Object.values(FeatureType)
  .filter(value => !isString(value))
  .map(value => ({
    label: FeatureTypeDisPlay[value as FeatureType],
    value,
  }));

// 状态类型
export enum StatusType {
  // 停机
  STOP = 1,
  // 待机
  WAIT,
  // 运行
  RUN,
}

export const StatusTypeDisPlay = {
  [StatusType.STOP]: '停机',
  [StatusType.WAIT]: '待机',
  [StatusType.RUN]: '运行',
};

export enum MeasurementType {
  ACCUM = 1,
  INSTANT = 2,
}

export const StatusTypeColor = {
  [StatusType.STOP]: 'invalid',
  [StatusType.WAIT]: 'info',
  [StatusType.RUN]: 'success',
};

// 时间颗粒度type
export enum TimeGranularityType {
  // 分钟
  MINUTE = '1M',
  // 小时
  HOUR = 'H',
  // 天
  DAY = 'D',
}

export const TimeGranularityTypeDisPlay = {
  [TimeGranularityType.MINUTE]: '分钟',
  [TimeGranularityType.HOUR]: '小时',
  [TimeGranularityType.DAY]: '天',
};

export const TimeGranularityTypeOptions = Object.values(TimeGranularityType).map(value => ({
  label: TimeGranularityTypeDisPlay[value as TimeGranularityType],
  value,
}));

// 应用分析类型
export enum AnalyticsType {
  //生产节拍
  PRODUCTION_BEAT = 1,
}

export const AnalyticsTypeDisplay = {
  [AnalyticsType.PRODUCTION_BEAT]: '生产节拍',
};

export interface FeatureTypeItem {
  startCharacteristics: FeatureType;
  startCharacteristicsTime?: number;
  startFromValueRange?: { startFromValueRangeMin: number; startFromValueRangeMax: number };
  startToValueRange?: { startToValueRangeMin: number; startToValueRangeMax: number };
  startFromValueRangeMin?: number;
  startFromValueRangeMax?: number;
  startToValueRangeMin?: number;
  startToValueRangeMax?: number;
  startEnergyUsageQuota?: number;
  endCharacteristics: FeatureType;
  endCharacteristicsTime?: number;
  endFromValueRange?: { endFromValueRangeMin: number; endFromValueRangeMax: number };
  endToValueRange?: { endToValueRangeMin: number; endToValueRangeMax: number };
  endFromValueRangeMin?: number;
  endFromValueRangeMax?: number;
  endToValueRangeMin?: number;
  endToValueRangeMax?: number;
  endEnergyUsageQuota?: number;
}
export interface NotProductionFormValues extends FeatureTypeItem {
  status: StatusType;
  useEnergyRange: string;
  useEnergyLowLimit: number;
  useEnergyUpperLimit: number;
}

export interface ActionFormValues extends FeatureTypeItem {
  actionName: string;
  durationRange: { durationRangeMin: number; durationRangeMax: number };
  durationRangeMin: number;
  durationRangeMax: number;
  useEnergy: { useEnergyUpperLimit: number; useEnergyLowLimit: number };
  useEnergyUpperLimit: number;
  useEnergyLowLimit: number;
  sort?: number;
  id: number;
}

// 获取table里相关特征字段
export function formatTableDataFeature(
  type: 'start' | 'end',
  tableDataItem: NotProductionFormValues | ActionFormValues,
  unitName: string, // 物理单位名称
  timeResolution: TimeGranularityType, // 时间颗粒度
  quotaTypeName?: string // 计量属性名称,
) {
  const feature = FeatureTypeDisPlay[tableDataItem[`${type}Characteristics`] as FeatureType].split('-')[1];

  if (tableDataItem[`${type}Characteristics`] === FeatureType.INSTANT_VALUE) {
    const useEnergyRange = `${tableDataItem.useEnergyLowLimit}~${tableDataItem.useEnergyUpperLimit}`;

    return `${feature}：${tableDataItem[`${type}EnergyUsageQuota`]}${TimeGranularityTypeDisPlay[timeResolution]}内${
      isNil(quotaTypeName) ? '--' : quotaTypeName
    }均${type === 'start' ? '' : '不'}在${useEnergyRange}${unitName}范围内`;
  } else {
    const featureName = feature.slice(0, 2);
    const fromValueRange = `${tableDataItem[`${type}FromValueRangeMin`]}~${tableDataItem[`${type}FromValueRangeMax`]}`;
    const toValueRange = `${tableDataItem[`${type}ToValueRangeMin`]}~${tableDataItem[`${type}ToValueRangeMax`]}`;

    return `${feature}：${tableDataItem[`${type}CharacteristicsTime`]}${
      TimeGranularityTypeDisPlay[timeResolution]
    }内由${fromValueRange}${unitName}${featureName}到${toValueRange}${unitName}`;
  }
}

export interface QuotaTypeListItem {
  dataPropertyId: number; //  数据属性
  dataPropertyName: string; //  数据属性名称
  physicalUnitName: string; //  默认单位
  measurementType: number; //  类型：1.累计值;2.瞬时值
}

export function formatTableDataFromFormValues(
  formValues: NotProductionFormValues | ActionFormValues,
  type: 'status' | 'action'
) {
  return {
    ...formValues,
    startFromValueRangeMin: formValues.startFromValueRange?.startFromValueRangeMin,
    startFromValueRangeMax: formValues.startFromValueRange?.startFromValueRangeMax,
    startToValueRangeMin: formValues.startToValueRange?.startToValueRangeMin,
    startToValueRangeMax: formValues.startToValueRange?.startToValueRangeMax,
    endFromValueRangeMin: formValues.endFromValueRange?.endFromValueRangeMin,
    endFromValueRangeMax: formValues.endFromValueRange?.endFromValueRangeMax,
    endToValueRangeMin: formValues.endToValueRange?.endToValueRangeMin,
    endToValueRangeMax: formValues.endToValueRange?.endToValueRangeMax,
    useEnergyLowLimit:
      type === 'status'
        ? Number((formValues as NotProductionFormValues).useEnergyRange.split('~')[0])
        : (formValues as ActionFormValues).useEnergy.useEnergyLowLimit,
    useEnergyUpperLimit:
      type === 'status'
        ? Number((formValues as NotProductionFormValues).useEnergyRange.split('~')[1])
        : (formValues as ActionFormValues).useEnergy.useEnergyUpperLimit,
    durationRangeMin: type === 'status' ? undefined : (formValues as ActionFormValues).durationRange.durationRangeMin,
    durationRangeMax:
      type === 'status' ? undefined : (formValues as ActionFormValues).durationRange.durationRangeMax ?? undefined,
  };
}

// 时间颗粒度type
export enum AccumType {
  // 分钟
  MINUTE = 1,
  MINUTE15 = 2,
  HOUR = 3,
  DAY = 4,
}

export const AccumTypeDispaly = {
  [AccumType.MINUTE]: '每分钟',
  [AccumType.MINUTE15]: '每15分钟',
  [AccumType.HOUR]: '每小时',
  [AccumType.DAY]: '每天',
};

export const statusOptions = Object.entries(StatusTypeDisPlay).map(i => ({
  label: i[1],
  key: Number(i[0]),
}));

export const AccumTypeOptions = Object.entries(AccumTypeDispaly).map(i => ({
  label: i[1],
  value: Number(i[0]),
}));

export const ActionColors = [
  '#52E7FF',
  '#2D8DFF',
  '#16DD8E',
  '#854ECA',
  '#ABD335',
  '#0DB6D9',
  '#FF9247',
  '#63BC7F',
  '#00ADFF',
  '#CE90D1',
];

export type DataListProp = {
  key: string;
  title: string;
  productionProcessName: string;
  productionBaseId?: number;
  workCenterId?: number;
  workProcedureId?: number;
  workStationId?: number;
  energyUnitId?: number;
}[];

export enum IsEqual {
  NO = 0,
  YES = 1,
}

export type DeviceItem = Exclude<V2AppAnalysisSubscriptionDeviceGetPostResponse['list'], undefined>[number] & {
  label: string;
  value?: number;
};

export type OutputItem = {
  id?: string;
  parentId?: string;
  selfId?: number;
  title?: string;
  name?: string;
  code?: string;
  type?: number;
  level?: number;
  children?: OutputItem[];
};

export interface TreeNode {
  title: string;
  label: string;
  ids: string;
  children: TreeNode[];
  disabled: boolean;
}

export enum TaktMode {
  FOLLOW_WAVEFORM = 1,
  FOLLOW_ACTION,
}

export const TaktModeDisplay = {
  [TaktMode.FOLLOW_WAVEFORM]: '按波形智能识别',
  [TaktMode.FOLLOW_ACTION]: '按节拍动作识别',
};
