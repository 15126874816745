import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Create/index.module.scss';
import classNames from 'classnames';
import { EdgeNetWorkType, FcsNetworkDetail } from '@/api/gateway';
import { isNil } from 'lodash-es';
export interface Gateway {
  deviceName?: string;
  id: number;
  fcsNetworkInfo?: FcsNetworkDetail;
  edgeNetwork?: EdgeNetWorkType;
}
const GatherDeviceList: React.FC<Gateway> = ({ id, deviceName, fcsNetworkInfo, edgeNetwork }) => {
  return (
    <div className={styles.colBoxChild}>
      <div className={styles.rowItemBox}>
        <div className={classNames(styles.rowItem, styles.rowItemLeft)}>网关元数据采集设备:</div>
        <div className={classNames(styles.rowItem, styles.rowItemCenter)}>{deviceName ?? '未配置元数据采集设备'}</div>
        {!deviceName && !isNil(id) ? (
          <div className={classNames(styles.rowItem, styles.rowItemRight)}>
            <Link to={`/device/config/gateway/update/${id}`} target="_blank">
              点击编辑网关
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={styles.rowItemBox}>
        <div className={classNames(styles.rowItem)}>所在组网:</div>
        <div className={classNames(styles.rowItem)}>
          {fcsNetworkInfo ? fcsNetworkInfo.netName : edgeNetwork ? edgeNetwork.netName : '--'}
        </div>
      </div>
      <div className={styles.rowItemBox}>
        <div className={classNames(styles.rowItem)}>ip地址:</div>
        <div className={classNames(styles.rowItem)}>
          {fcsNetworkInfo ? fcsNetworkInfo.gatewayIp : edgeNetwork ? edgeNetwork.gatewayIp : '--'}
        </div>
      </div>
    </div>
  );
};

export default GatherDeviceList;
