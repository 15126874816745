import React, { useMemo, useState, useEffect } from 'react';
import { Space, Divider } from 'antd';
import {
  IotProtocolType,
  ActionType,
  ActionTypeColors,
  ActionTypeDisplay,
  ESTUNPoint,
  PointType,
  DriveType,
} from '../../types';
import Mockingbird from './mockingbird';
import Estun from './estun';
import { PointContext } from './contextTypes';
import { Button, Key, MxColumnsType, PopConfirm } from '@maxtropy/components';

export type EdgeDeviceTemplatePoint = PointType & {
  actionType?: ActionType;
  // edgeDevicePointId?: string;
  // edgeData?: number;
  // edgeTime?: number;
};

export interface AttributeInfoProps {
  iotProtocol: IotProtocolType;
  deviceTypeId?: number;
  driveType?: DriveType;
  physicalModelId?: Key;
}

export interface EdgeDevicePointInfoProps {
  type: IotProtocolType;
  editable?: boolean;
  dataSource?: EdgeDeviceTemplatePoint[];
  setDataSource?: (dataSource: EdgeDeviceTemplatePoint[]) => void;
  loading?: boolean;
  info?: AttributeInfoProps;
  promptSlot?: React.ReactElement;
  isBatch?: boolean; // 是否是批量
  edgeGatewayId?: Key;
}

const EdgeDevicePointInfo: React.FC<EdgeDevicePointInfoProps> = ({
  type,
  editable = false,
  dataSource,
  setDataSource,
  loading,
  info,
  promptSlot,
  isBatch,
  edgeGatewayId,
}) => {
  const [row, setRow] = useState<Partial<EdgeDeviceTemplatePoint> | undefined>();
  const [searchValue, setSearchValue] = useState<string>();

  const data = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE)
        .filter(item => {
          if (searchValue) {
            return (item.dataPropertyName || item.identifier)!.indexOf(searchValue) > -1;
          } else {
            return true;
          }
        }),
    [dataSource, searchValue]
  );

  useEffect(() => {
    setSearchValue(undefined);
  }, [type]);

  const onDelete = async (record: EdgeDeviceTemplatePoint) => {
    const index = dataSource?.findIndex(i => i === record);
    if (index !== undefined) {
      if (record.actionType === ActionType.ADD) {
        // 若之前为 ADD 状态, 删除操作后直接舍弃该点
        const data = [...(dataSource ?? [])];
        data.splice(index, 1);
        setDataSource?.(data);
      } else {
        const copy = dataSource!.slice();
        copy[index].actionType = ActionType.DELETE;
        setDataSource!(copy);
      }
    }
  };

  const onUpdate = async (values: any) => {
    const copy = dataSource?.slice() || [];
    if (row!.id) {
      // 编辑
      const index = copy.findIndex(item => item.id === row!.id);
      if (row!.actionType === ActionType.ADD) {
        // 若之前为 ADD 状态, 编辑操作后仍旧为 ADD 状态
        copy[index] = {
          id: row!.id,
          ...values,
          actionType: ActionType.ADD,
        };
      } else {
        // 否则为正常的编辑操作, 改为编辑状态, 请求是带上 edgeDevicePointId 字段
        copy[index] = {
          id: row!.id,
          ...values,
          actionType: ActionType.EDIT,
          edgeDevicePointId: copy[index]!.edgeDevicePointId,
        };
      }
      setDataSource!(copy);
    } else if (row!.actionType === ActionType.ADD) {
      // 新增
      const index = dataSource!.indexOf(row as EdgeDeviceTemplatePoint);
      copy[index] = {
        ...values,
        actionType: ActionType.ADD,
      };
      setDataSource!(copy);
    } else {
      copy.push({ ...values, actionType: ActionType.ADD });
      setDataSource!(copy);
    }
  };

  const stateColumns: MxColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作状态',
      dataIndex: 'actionType',
      fixed: 'right',
      width: 80,
      render: (v: ActionType) => (
        <div
          style={{
            width: 45,
            height: 20,
            textAlign: 'center',
            borderRadius: 10,
            fontSize: 12,
            color: ActionTypeColors[v].color,
            background: ActionTypeColors[v].background,
          }}
        >
          {ActionTypeDisplay[v]}
        </div>
      ),
    },
  ];

  const opColumns: MxColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (_, record, index) => (
        <Space size={16}>
          <Button
            type="link"
            onClick={() => {
              setRow(record);
            }}
          >
            编辑
          </Button>
          <Divider type="vertical" />
          <PopConfirm
            okText="继续"
            placement="topRight"
            title={
              <>
                <div>确定删除？</div>
                <div style={{ color: '#f5222d' }}>
                  删除后不可恢复<span style={{ color: 'var(--disabled-color)' }}>，你还要继续吗？</span>
                </div>
              </>
            }
            onConfirm={() => onDelete(record)}
          >
            <Button type="link">删除</Button>
          </PopConfirm>
        </Space>
      ),
    },
  ];

  const editColumns = [...(editable ? stateColumns : []), ...(editable ? opColumns : [])];

  const renderContent = () => {
    if (type === IotProtocolType.ESTUN) {
      return <Estun loading={loading} onUpdate={onUpdate} editColumns={editColumns as MxColumnsType<ESTUNPoint>} />;
    } else if (type === IotProtocolType.MOCKINGBIRD) {
      return <Mockingbird loading={loading} onUpdate={onUpdate} editColumns={editColumns} onSearch={setSearchValue} />;
    }
  };

  return (
    <>
      <PointContext.Provider
        value={{ dataSource: data, row, setRow, info, promptSlot, editable, isBatch, edgeGatewayId }}
      >
        {renderContent()}
      </PointContext.Provider>
    </>
  );
};

export default EdgeDevicePointInfo;
