import {
  DeleteType,
  FieldType,
  FillingDataMap,
  FillingDataTypes,
  GuideClassificationProcessDtosTypes,
} from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions/types';
import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, ShowInput } from '@maxtropy/components';
import { Space } from 'antd';
import { Button, Form, InputNumber, Modal, Select, Table, Tag } from '@maxtropy/components';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 } from 'uuid';
import styles from './index.module.scss';
import cloneDeep from 'lodash-es/cloneDeep';
import { FixedType } from 'rc-table/lib/interface';
import {
  apiV2CarbonEmissionFillingAddDataPost,
  apiV2CarbonEmissionFillingEditDataPost,
  apiV2CarbonEmissionFillingRefreshDataPost,
  apiV2CarbonEmissionFillingRemoveDataPost,
  apiV2CarbonEmissionProcessGetFieldValueListPost,
  V2CarbonEmissionFillingAddDataPostRequest,
  V2CarbonEmissionFillingEditDataPostRequest,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  data: GuideClassificationProcessDtosTypes;
  classificationProcessId?: number; // 分类id
  emissionFillingId?: number; // 填报id
  update: () => void;
}

const ProcessTable: React.FC<Props> = ({ data, emissionFillingId, update }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [processData, setProcessData] = React.useState<GuideClassificationProcessDtosTypes>();
  const [editingKeys, setEditingKeys] = React.useState<React.Key[]>([]);

  const isEditing = useCallback(
    (record: FillingDataTypes) => {
      return editingKeys.includes(record.id!);
    },
    [editingKeys]
  );

  const handleCancle = useCallback(
    (record: FillingDataTypes) => {
      const index = editingKeys.findIndex(item => item === record.id);
      editingKeys.splice(index, 1);
      setEditingKeys([...editingKeys]);

      const tempFillingData = [...processData!.fillingData!]
        .map(item => {
          if (typeof item.id === 'string' && item.id?.startsWith('newId')) {
            return null;
          }
          return item;
        })
        .filter(Boolean);

      setProcessData({ ...processData, fillingData: tempFillingData as FillingDataTypes[] });
    },
    [editingKeys, processData]
  );

  const handleRemove = useCallback(
    (record: FillingDataTypes, deleteType: DeleteType) => {
      Modal.confirm({
        title: deleteType === DeleteType.Remove ? '移除数据' : '删除数据',
        content: deleteType === DeleteType.Remove ? '是否移除此行数据？' : '是否删除此行数据？',
        onOk: () => {
          setLoading(true);
          apiV2CarbonEmissionFillingRemoveDataPost({ id: String(record.id) }).then(state => {
            state && update();
            setLoading(false);
          });
        },
      });
    },
    [update]
  );

  const handleSave = useCallback(
    async (record: FillingDataTypes) => {
      // 校验当前row的namePaths
      const namePaths = Object.keys(record).map(key => `${key}-${record.id}`);
      const res = await form.validateFields(namePaths);
      const value: FillingDataMap = {};
      Object.keys(res).forEach(key => {
        if (key.endsWith(`-${String(record.id)}`)) {
          value[key.replace(`-${String(record.id)}`, '')] = res[key];
        }
      });

      if ([emissionFillingId, data?.emissionProcessId, data?.classificationId].some(item => isNil(item))) return;

      const postParams = {
        ...record,
        ...value,
        emissionFillingId: emissionFillingId ?? 0,
        classificationProcessId: data?.classificationId ?? 0,
        emissionProcessId: data?.emissionProcessId ?? 0,
      };

      setLoading(true);

      if (typeof record.id === 'string' && record.id?.startsWith('newId')) {
        // 新增时保存
        delete postParams.id;
        apiV2CarbonEmissionFillingAddDataPost(postParams as V2CarbonEmissionFillingAddDataPostRequest)
          .then(res => {
            if (res) {
              update();
              setEditingKeys(editingKeys.filter(item => item !== record.id));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // 编辑时保存
        apiV2CarbonEmissionFillingEditDataPost(postParams as V2CarbonEmissionFillingEditDataPostRequest)
          .then(res => {
            if (res) {
              update();
              setEditingKeys(editingKeys.filter(item => item !== record.id));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },

    [data?.classificationId, data?.emissionProcessId, editingKeys, emissionFillingId, form, update]
  );

  const handleAdd = () => {
    // 新增一行数据
    let newData: FillingDataTypes = {};
    if (!columns) {
      console.error('Columns is null or undefined');
      return;
    }
    columns
      ?.map(item => item.dataIndex)
      .forEach(key => {
        newData[key] = null;
      });
    newData.id = `newId-${v4()}`;
    setProcessData({ ...processData, fillingData: [...processData?.fillingData!, newData] });
    setEditingKeys([...editingKeys, newData.id!]);
  };

  const handleEdit = useCallback(
    (record: FillingDataTypes) => {
      // 设置form值
      const value: FillingDataMap = {};
      Object.keys(record).forEach(key => {
        value[`${key}-${record.id}`] = record[key];
      });
      form.setFieldsValue(value);

      setEditingKeys([...editingKeys, record.id!]);
    },
    [editingKeys, form]
  );

  const handleRefresh = useCallback(
    (record: FillingDataTypes) => {
      const { id } = record;
      Modal.confirm({
        title: '刷新数据',
        content: '是否刷新本行数据，确定后将规则重新获取数据计算碳排。',
        onOk: () => {
          setLoading(true);
          apiV2CarbonEmissionFillingRefreshDataPost({ id: String(id) })
            .then(state => {
              state && update();
            })
            .finally(() => setLoading(false));
        },
      });
    },
    [update]
  );

  const getDataInex = useCallback(
    (id: number | undefined, index: number | undefined) => {
      const obj = data?.fillingData && data.fillingData[0];
      for (const key in obj) {
        if (key.startsWith('formField') && obj.hasOwnProperty(key) && obj[key] === id) {
          return key.replace('Id', 'Value');
        }
      }
      // 新增时字段索引
      return `formField${index! + 1}Value`;
    },
    [data?.fillingData]
  );

  const handleSelectChange = useCallback(
    (id: number, index: number | undefined, record: FillingDataTypes) => {
      form.setFieldsValue({
        [(getDataInex(id, index!) + '-' + record.id).replace('Value', 'Id')]: id,
      });

      // 清除后面的值
      const afterColumns = columns!.filter(item => item.type === FieldType.DataAssociation);
      const currentIndex = afterColumns.findIndex(item => item.key === id);
      const afterDataIndex = afterColumns?.slice(currentIndex + 1).map(item => item.dataIndex);
      afterDataIndex?.forEach(item => {
        form.setFieldsValue({
          [item + '-' + record.id]: undefined,
        });
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, getDataInex, processData]
  );

  const handleInputClick = useCallback(
    (id: number, index: number | undefined, record: FillingDataTypes) => {
      form.setFieldsValue({
        [(getDataInex(id, index!) + '-' + record.id).replace('Value', 'Id')]: id,
      });
    },
    [form, getDataInex]
  );

  const handleSelectClick = useCallback(
    (id: number, index: number | undefined, record: FillingDataTypes) => {
      const formFieldList = (columns ?? [])
        .filter(item => item.type === FieldType.DataAssociation)
        .map(item => ({
          ...item,
          dataIndex: [item.dataIndex, record.id].join('-'),
        }));
      const currentFieldIndex = formFieldList.findIndex(item => item.key === id);
      const before = formFieldList.slice(0, currentFieldIndex!);
      const fields = before
        ?.map(item => ({
          formFieldName: item.copyTitle,
          formFieldValue: form.getFieldValue(item.dataIndex!),
        }))
        .filter(item => item.formFieldValue !== undefined);

      // 获取之前下拉框的值
      const preFieldValue = form.getFieldsValue([formFieldList[currentFieldIndex - 1]?.dataIndex]);

      // 如果前一个下拉框没有值，不请求
      if (Object.values(preFieldValue)[0] !== undefined || currentFieldIndex === 0) {
        apiV2CarbonEmissionProcessGetFieldValueListPost({
          targetFieldId: id,
          fields,
        }).then(res => {
          const list = res.list;
          const newData = [...processData!.fillingData!];
          const item = newData.find(item => item.id === record.id);
          item![getDataInex(id, index!) + '-' + record.id + '-Options'] = list?.map(item => ({
            label: item,
            value: item,
          }));

          setProcessData({ ...processData, fillingData: newData });
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.formFieldList, form, getDataInex, processData]
  );

  const columns = useMemo(() => {
    const columns = processData?.formFieldList?.map((item, index) => {
      return {
        title: <EllipsisSpan value={item.fieldName} />,
        copyTitle: item.fieldName,
        dataIndex: getDataInex(item.id, index),
        key: item.id,
        type: item.fieldType,
        fixed: false as FixedType,
        ellipsis: { showTitle: true },
        render: (text: string, record: FillingDataTypes) => {
          if (isEditing(record)) {
            switch (item.fieldType) {
              case FieldType.DataAssociation:
                return (
                  <>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-')}
                      style={{
                        marginBottom: 0,
                      }}
                      rules={[{ required: true, message: '请选择' }]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        placeholder="请选择"
                        options={record[[getDataInex(item.id, index), record.id].join('-') + '-Options']}
                        onChange={() => handleSelectChange(item.id!, index, record)}
                        onFocus={() => handleSelectClick(item.id!, index, record)}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-').replace('Value', 'Id')}
                      initialValue={item.id ?? ''}
                      hidden
                    >
                      <input value={item.id ?? ''} />
                    </Form.Item>
                  </>
                );
              case FieldType.Number:
                return (
                  <>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-')}
                      style={{
                        marginBottom: 0,
                      }}
                      rules={[{ required: true, message: '请输入' }]}
                    >
                      <InputNumber
                        style={{
                          width: '100%',
                        }}
                        onClick={() => handleInputClick(item.id!, index, record)}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-').replace('Value', 'Id')}
                      initialValue={item.id ?? ''}
                      hidden
                    >
                      <input value={item.id ?? ''} />
                    </Form.Item>
                  </>
                );
              case FieldType.Output:
                return (
                  <>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-')}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      <ShowInput />
                    </Form.Item>
                    <Form.Item
                      name={[getDataInex(item.id, index), record.id].join('-').replace('Value', 'Id')}
                      initialValue={item.id ?? ''}
                      hidden
                    >
                      <input value={item.id ?? ''} />
                    </Form.Item>
                  </>
                );
              default:
                break;
            }
          } else if (index === 0) {
            return (
              <>
                <EllipsisSpan value={text} />
                {!isNil(record.autoFillingRuleId) && (
                  <Tag color="success" border="solid" style={{ marginLeft: '8px' }}>
                    自动填报
                  </Tag>
                )}
              </>
            );
          } else {
            return <EllipsisSpan value={text} />;
          }
        },
      };
    });

    columns?.push({
      title: <EllipsisSpan value={'操作'} />,
      copyTitle: '操作',
      key: -999,
      dataIndex: 'action',
      type: -999,
      fixed: 'right' as FixedType,
      ellipsis: { showTitle: true },
      render: (_: string, record: FillingDataTypes) => {
        const { autoFillingRuleId } = record;
        if (!isNil(autoFillingRuleId)) {
          return (
            <>
              <Space size={16}>
                <Button type="link" onClick={() => handleRefresh(record)}>
                  刷新
                </Button>
                <Button type="link" onClick={() => handleRemove(record, DeleteType.Remove)} danger>
                  移除
                </Button>
              </Space>
            </>
          );
        } else if (isEditing(record)) {
          return (
            <>
              <Space size={16}>
                <Button type="link" onClick={() => handleSave(record)}>
                  保存
                </Button>
                <Button type="link" onClick={() => handleCancle(record)}>
                  取消
                </Button>
              </Space>
            </>
          );
        } else
          return (
            <>
              <Space size={16}>
                <Button type="link" onClick={() => handleEdit(record)}>
                  编辑
                </Button>
                <Button type="link" onClick={() => handleRemove(record, DeleteType.Remove)}>
                  删除
                </Button>
              </Space>
            </>
          );
      },
    });

    return columns;
  }, [
    processData?.formFieldList,
    getDataInex,
    isEditing,
    handleSelectChange,
    handleSelectClick,
    handleInputClick,
    handleRefresh,
    handleRemove,
    handleSave,
    handleCancle,
    handleEdit,
  ]);

  useEffect(() => {
    // 新增时，填充column的id
    const temp = columns?.reduce((prev: FillingDataTypes, curr: FillingDataTypes) => {
      if (!curr.dataIndex) return prev;
      prev[curr.dataIndex] = null;
      prev[curr.dataIndex.replace('Value', 'Id')] = curr.key;
      return prev;
    }, {});

    const editingList = editingKeys.filter(key => String(key).startsWith('newId')).map(key => ({ ...temp, id: key }));
    setProcessData({ ...data, fillingData: [...data.fillingData!, ...editingList] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, editingKeys]);

  useEffect(() => {
    setProcessData(pre => {
      const preAddRows =
        pre?.fillingData?.filter(item => typeof item.id === 'string' && item.id?.startsWith('newId')) ?? [];
      const newData = cloneDeep(data);
      newData.fillingData = [...newData.fillingData!, ...preAddRows];
      return newData;
    });
  }, [data]);

  return (
    <>
      <Form form={form} component={false}>
        <div className={styles.processText}>{data?.emissionProcessName ?? '--'}</div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={processData?.fillingData}
          pagination={false}
          loading={loading}
          scroll={{ x: 1500 }}
        />
        <Button
          onClick={handleAdd}
          type="dashed"
          icon={<PlusOutlined />}
          wrapClassName={styles.addRowBtn}
          style={{ width: '100%' }}
        >
          新增一行数据
        </Button>
      </Form>
    </>
  );
};

export default ProcessTable;
