import { Button, EllipsisSpan, Key, Paging, Table, usePaging, useUpdate, Form, Input } from '@maxtropy/components';
import { useEffect, useState } from 'react';
import BindOrderModal from '../BindOrderModal';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import {
  OrderEnum,
  WorkOrderPageRes,
  WorkOrderQuery,
  WorkOrderRes,
  bindWorkOrder,
  bindWorkStation,
  getWorkOrderDetailByLinkId,
  getWorkOrderPageById,
} from '@/api/energyUnitMapping';
import { isNil } from 'lodash';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';
import BindStationModal from '@/pages/EnergyUnitMapping/components/BindStationModal';
import { V2ProductionBaseDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
interface Props {
  onJumpToTreeItem: (v: Key[]) => void;
  workCenterId?: Key;
  contentType?: string;
  workCenterDetail?: V2ProductionBaseDetailPostResponse;
  treeUpdateFn: () => void;
  productionBaseId: number;
  searchUpdate: number;
  infoUpdateFn: () => void;
  activeKey: string;
}

const columns = [
  {
    title: '工序编码',
    dataIndex: 'workProcedureCode',
    ellipsis: { showTitle: true },
    sorter: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定工站数',
    dataIndex: 'workStationAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定用能单元数',
    dataIndex: 'energyUnitAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  // {
  //   title: '工序状态',
  //   dataIndex: 'state',
  //   ellipsis: { showTitle: true },
  //   render: (v: string) => <EllipsisSpan value={v} />,
  // },
  {
    title: '最后操作人',
    dataIndex: 'opUpdateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'opUpdateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<WorkOrderQuery, 'page' | 'size' | 'workCenterId'> {}

const WorkOrderTable: React.FC<Props> = ({
  treeUpdateFn,
  onJumpToTreeItem,
  workCenterId,
  contentType,
  workCenterDetail,
  searchUpdate,
  productionBaseId,
  infoUpdateFn,
  activeKey,
}) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [bindOrderModalOpen, setBindOrderModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<WorkOrderPageRes[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const hasBindOrder = useHasPermission(PermissionsType.B_BINDINGPROCESS); // 绑定工序
  const [form] = Form.useForm();

  const [workOrderDetail, setWorkOrderDetail] = useState<WorkOrderRes>();
  const [bindStationModalOpen, setBindStationModalOpen] = useState<boolean>(false);
  const [wordOrderLinkId, setWordOrderLinkId] = useState<Key>();

  // 切换工作中心时，清空搜索条件
  useEffect(() => {
    if (contentType === 'workCenter') {
      form.resetFields();
      setSearchParams(undefined);
      setPageOffset(1);
    }
  }, [searchUpdate, setPageOffset, form, contentType]);

  useEffect(() => {
    if (contentType === 'workCenter' && workCenterId && productionBaseId) {
      setLoading(true);
      getWorkOrderPageById({
        ...searchParams,
        productionBaseId,
        workCenterId,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (contentType === 'productionBase' && productionBaseId) {
      setLoading(true);
      getWorkOrderPageById({
        ...searchParams,
        page: pageOffset,
        productionBaseId,
        size: pageSize,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    contentType,
    workCenterId,
    searchParams,
    pageOffset,
    pageSize,
    setTotalCount,
    update,
    productionBaseId,
    activeKey,
  ]);

  const buildColumns = [
    {
      title: '工序名称',
      dataIndex: 'workProcedureName',
      ellipsis: { showTitle: true },
      render: (v: string, row: WorkOrderPageRes) => {
        return (
          <EllipsisSpan
            value={
              v ? (
                <Button
                  type="link"
                  onClick={() => {
                    onJumpToTreeItem([`workOrder-${row.workCenterId}-${row.linkId}-${row.workProcedureId}`]);
                  }}
                >
                  {v}
                </Button>
              ) : undefined
            }
          />
        );
      },
    },
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: WorkOrderPageRes) => (
        <Button type="link" onClick={() => onClickBindStation(record.linkId)}>
          绑定工站
        </Button>
      ),
    },
  ];

  // 绑定工序
  const onBindOrder = (v: Key[]) => {
    if (workCenterId) {
      bindWorkOrder({
        id: workCenterId,
        workProcedureIds: v,
      }).then(() => {
        updateFn();
        setPageOffset(1);
        treeUpdateFn();
        infoUpdateFn();
      });
    }
  };

  // 绑定工站
  const onClickBindStation = (id: Key) => {
    setWordOrderLinkId(id);
    getWorkOrderDetailByLinkId(id).then(res => {
      setWorkOrderDetail(res);
      setBindStationModalOpen(true);
    });
  };

  //绑定工站
  const onBindStation = (v: Key[]) => {
    if (wordOrderLinkId) {
      bindWorkStation({
        centerProcedureLinkId: wordOrderLinkId,
        workStationIds: v,
      }).then(() => {
        updateFn();
        setPageOffset(1);
        treeUpdateFn();
        infoUpdateFn();
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {hasBindOrder && contentType === 'workCenter' && (
          <div style={{ marginRight: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setBindOrderModalOpen(true);
              }}
            >
              绑定工序
            </Button>
          </div>
        )}
        {hasBindOrder && contentType === 'productionBase' && (
          <div style={{ marginRight: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setBindOrderModalOpen(true);
              }}
            >
              绑定工序
            </Button>
          </div>
        )}

        <Form form={form} className={styles.formItemStyles}>
          <Form.Item name="workProcedureCodeOrName" style={{ marginBottom: 10 }}>
            <div style={{ width: 280 }}>
              <Input.Search
                placeholder="工序编码/名称"
                onSearch={(v: string) => {
                  setSearchParams({
                    ...searchParams,
                    workProcedureCodeOrName: v === '' ? undefined : v,
                  });
                  setPageOffset(1);
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={loading}
        rowKey="linkId"
        dataSource={data}
        columns={buildColumns}
        onChange={(pagination, filters, sorter) => {
          const { order } = sorter as SorterResult<WorkOrderPageRes>;
          setSearchParams({
            ...searchParams,
            orderBy: isNil(order) ? undefined : order === 'ascend' ? OrderEnum.ASC : OrderEnum.DESC,
          });
          setPageOffset(1);
        }}
      />
      <Paging pagingInfo={pagingInfo} />
      {bindOrderModalOpen && (
        <BindOrderModal
          open={bindOrderModalOpen}
          setOpen={setBindOrderModalOpen}
          id={workCenterId}
          workCenterDetail={workCenterDetail}
          onBind={onBindOrder}
        />
      )}
      {bindStationModalOpen && (
        <BindStationModal
          open={bindStationModalOpen}
          setOpen={setBindStationModalOpen}
          workOrderDetail={workOrderDetail}
          workOrderLinkId={wordOrderLinkId}
          contentType="workOrder"
          onBind={onBindStation}
        />
      )}
    </>
  );
};

export default WorkOrderTable;
