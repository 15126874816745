import { useRequest } from 'ahooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/scss';
import styles from './index.module.scss';
import leftIcon from '../../assets/leftIcon.png';
import rightIcon from '../../assets/rightIcon.png';
import { useState } from 'react';
import mainTittleLine from '../../assets/mainTittleLine.png';
import { Key } from '@maxtropy/components';
import { pollingInterval } from '../../utils';
import {
  V2EnergyConsumptionOverviewEnergyOverviewConfigListPostResponse,
  apiV2EnergyConsumptionOverviewEnergyOverviewConfigListPost,
} from '@maxtropy/device-customer-apis-v2';

export type EnergyOverviewConfigListItem = Required<
  Exclude<V2EnergyConsumptionOverviewEnergyOverviewConfigListPostResponse['list'], undefined>[number]
>;

interface TitleSwiperProps {
  setConfigId: (id: Key) => void;
  configId?: Key;
}

const TitleSwiper: React.FC<TitleSwiperProps> = ({ setConfigId, configId }) => {
  const { data: energyOverviewConfigList } = useRequest(
    () => {
      return apiV2EnergyConsumptionOverviewEnergyOverviewConfigListPost().then(res => {
        const list = (res.list ?? []) as EnergyOverviewConfigListItem[];
        if (!list.length) return [];
        if (!configId) {
          setConfigId(list[0].id);
          return list;
        }
        const isExist = list.some(item => item.id === configId);
        if (!isExist) {
          setConfigId(list[0].id);
          return list;
        }
        return list;
      });
    },
    {
      pollingInterval,
    }
  );

  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <div className={styles.swiperStyle}>
      <img className={styles.imgStyle} src={mainTittleLine} alt="pic" />
      {energyOverviewConfigList && energyOverviewConfigList.length > 1 && (
        <div
          style={{ cursor: 'pointer', zIndex: '99' }}
          onClick={() => {
            if (currentIndex === 0) {
              const lastIndex = energyOverviewConfigList.length - 1;
              setCurrentIndex(lastIndex);
              setConfigId(energyOverviewConfigList[lastIndex].id);
            }
            if (currentIndex > 0) {
              setCurrentIndex(currentIndex - 1);
              setConfigId(energyOverviewConfigList[currentIndex - 1].id);
            }
            swiperIntance?.slidePrev();
          }}
        >
          <img src={leftIcon} alt="pic" />
        </div>
      )}

      <Swiper
        spaceBetween={8}
        slidesPerView={1}
        observer={true}
        observeParents={true}
        loop={true}
        loopedSlides={energyOverviewConfigList?.length ?? 1}
        onSwiper={swiper => {
          setSwiperIntance(swiper);
        }}
        allowTouchMove={false}
      >
        {energyOverviewConfigList?.map(item => {
          return (
            <SwiperSlide data-id={item.id as unknown as string} key={item.id}>
              <div className={styles.cardStyles}>{item.title}</div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {energyOverviewConfigList && energyOverviewConfigList.length > 1 && (
        <div
          style={{ cursor: 'pointer', zIndex: '99' }}
          onClick={() => {
            if (currentIndex === energyOverviewConfigList.length - 1) {
              setCurrentIndex(0);
              setConfigId(energyOverviewConfigList[0].id);
            }
            if (currentIndex < energyOverviewConfigList.length - 1) {
              setCurrentIndex(currentIndex + 1);
              setConfigId(energyOverviewConfigList[currentIndex + 1].id);
            }
            swiperIntance?.slideNext();
          }}
        >
          <img src={rightIcon} alt="pic" />
        </div>
      )}
    </div>
  );
};

export default TitleSwiper;
