import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, useTenantPermissions } from '@maxtropy/components';
import { Space } from 'antd';

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NetInfoProp, openUetPv } from '../../../../../api/pv';
import { PermissionsType } from '../../../../../common/permissionsConst';
import styles from '../index.module.scss';
interface Iprops {
  hasPutOn?: boolean;
  pvId?: number;
  isDetail?: boolean;
  gridNetList?: NetInfoProp[];
  update?: (index?: number) => void;
}
const DeliveryStatus: React.FC<Iprops> = props => {
  const hasNetPoint = useMemo(() => props.gridNetList && props.gridNetList.length > 0, [props.gridNetList]);
  // 获取uetid
  const { id } = useParams<{ id: string }>();

  // 点击投放
  const deliveryClick = () => {
    if (!hasNetPoint) {
      Modal.warning({ title: '需要添加至少一个并网点' });
      return;
    }
    Modal.confirm({
      title: '确定投放光伏站吗?',
      content: '投放后并网点不可以新增和移除',
      onOk() {
        openUetPv(id, props.pvId).then(res => {
          if (res) {
            message.success('投放成功');
            props.update && props.update();
          } else {
            message.error('投放失败');
          }
        });
      },
    });
  };
  const permission = useTenantPermissions();
  // 显示投放按钮
  const showDeliveryBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_DELIVER) && props.pvId && !props.isDetail && !props.hasPutOn;
  }, [permission, props.pvId, props.isDetail, props.hasPutOn]);

  // 显示已投放组件
  const showHasDelivery = useMemo(() => {
    return !props.isDetail && props.hasPutOn;
  }, [props.isDetail, props.hasPutOn]);
  return (
    <div className={styles.pvStatus}>
      <div className={styles.deliveryBox}>
        <Button disabled={!showDeliveryBtn || showHasDelivery} type="primary" onClick={deliveryClick}>
          {props.hasPutOn ? '已投放' : '投放'}
        </Button>

        <Space>
          <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
          <div className={styles.deliveryTip}>
            保存后可投放光伏站至光伏站列表，投放后, 并网点不可以新增和移除, 请谨慎操作。
          </div>
        </Space>

        <div className={styles.empty}></div>
      </div>
    </div>
  );
};

export default DeliveryStatus;
