import React from 'react';
import styles from './index.module.scss';
import { Divider } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { getEdgeDeviceStatistics, getGatewayStatistics } from '@/api/energyOverview';
import { useRequest } from 'ahooks';
import { Key } from '@maxtropy/components';
import { pollingInterval } from '../../utils';
import { isNil } from 'lodash';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
interface DeviceCardsProps {
  configId?: Key;
}

const ifOverTenThousand = (num: number) => {
  return num > 10000 ? (num / 10000).toFixed(2) : num;
};
const DeviceCards: React.FC<DeviceCardsProps> = ({ configId }) => {
  const hasDeviceDetail = useHasPermission(PermissionsType.B_DEVICEDEATIL);

  // 物联接入数据
  const { data: edgeDeviceStatistics } = useRequest(
    () => {
      return configId ? getEdgeDeviceStatistics(configId as number) : Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId],
    }
  );

  // 网关数据
  const { data: gatewayStatistics } = useRequest(
    () => {
      return configId ? getGatewayStatistics(configId as number) : Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId],
    }
  );

  return (
    <div className={styles.deviceCards}>
      <div
        className={styles.singleCard}
        onClick={() => {
          if (hasDeviceDetail) {
            window.open('/device/manage/device', '_blank');
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div className={styles.singleCardIn}>
          <div className={styles.card}>
            <div className={styles.firstRow}>
              <div className={styles.firstRow_first}>
                <p className={styles.firstRow_firstP}>
                  {Number(edgeDeviceStatistics?.edgeDeviceCount ?? 0) > 10000 ? '采集设备数 (万个)' : '采集设备数 (个)'}
                  {hasDeviceDetail && <DoubleRightOutlined className={styles.translationStyles} />}
                </p>
              </div>
              <p className={styles.firstRowP}>
                {edgeDeviceStatistics && !isNil(edgeDeviceStatistics.edgeDeviceCount)
                  ? ifOverTenThousand(Number(edgeDeviceStatistics.edgeDeviceCount))
                  : '--'}
              </p>
            </div>
            <Divider
              style={{ width: '4%', height: '75%', marginBottom: 26, borderColor: 'rgba(255,255,255,0.15)' }}
              type="vertical"
            />
            <div className={styles.secondRow}>
              <p>
                {Number(edgeDeviceStatistics?.edgeDevicePointCount ?? 0) > 10000
                  ? '采集点数量 (万个)'
                  : '采集点数量 (个)'}
              </p>
              <p>
                {edgeDeviceStatistics && !isNil(edgeDeviceStatistics.edgeDevicePointCount)
                  ? ifOverTenThousand(Number(edgeDeviceStatistics.edgeDevicePointCount))
                  : '--'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.singleCard}
        onClick={() => {
          if (hasDeviceDetail) {
            window.open('/device/config/gateway', '_blank');
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <div className={styles.singleCardIn}>
          <div className={styles.card}>
            <div className={styles.firstRow}>
              <div className={styles.firstRow_first}>
                <p className={styles.firstRow_firstP}>
                  {Number(gatewayStatistics?.totalCount ?? 0) > 10000 ? '物联设备数 (万个)' : '物联设备数 (个)'}
                  {hasDeviceDetail && <DoubleRightOutlined className={styles.translationStyles} />}
                </p>
              </div>
              <p className={styles.firstRowP}>
                {gatewayStatistics && !isNil(gatewayStatistics.totalCount)
                  ? ifOverTenThousand(Number(gatewayStatistics.totalCount))
                  : '--'}
              </p>
            </div>
            <Divider
              style={{ width: '4%', height: '75%', marginBottom: 26, borderColor: 'rgba(255,255,255,0.15)' }}
              type="vertical"
            />
            <div className={styles.otherRow}>
              <div className={styles.otherRowFirst}>
                <div className={styles.firstCircle} />
                <span className={styles.firstP}>在线</span>
                <span className={styles.secondP}>
                  {gatewayStatistics && !isNil(gatewayStatistics.onlineCount)
                    ? ifOverTenThousand(Number(gatewayStatistics.onlineCount))
                    : '--'}
                </span>
              </div>
              <div className={styles.otherRowFirst}>
                <div className={styles.secondCircle} />
                <span className={styles.firstP}>离线</span>
                <span className={styles.secondP}>
                  {gatewayStatistics && !isNil(gatewayStatistics.offlineCount)
                    ? ifOverTenThousand(Number(gatewayStatistics.offlineCount))
                    : '--'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceCards;
