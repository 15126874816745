import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { Button, EllipsisSpan, FormTitle, SubContent, Table, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import {
  apiV2WorkShiftCalendarConfigListPost,
  apiV2WorkShiftCalendarDetailPost,
  V2WorkShiftCalendarConfigListPostResponse,
} from '@maxtropy/device-customer-apis-v2';

interface Props {}

const formatString = 'YYYY-MM';

export enum ApplyObject {
  UNIT = 1,
  PROCEDURE,
}

const columns = [
  {
    title: '时间范围',
    dataIndex: 'startDate',
    ellipsis: { showTitle: true },
    render: (v: string, record: ConfigItem) => {
      const start = dayjs(v).format(formatString);
      const end = dayjs(record.endDate).format(formatString);
      return <EllipsisSpan value={`${start}至${end}`} />;
    },
  },
  {
    title: '类型',
    dataIndex: 'applyObject',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v === ApplyObject.PROCEDURE ? '工序' : '用能单元'} />,
  },
  {
    title: '应用对象数',
    dataIndex: 'energyUnitAmount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];

const routes = [{ name: `班次日历应用配置` }];

export type ConfigItem = Exclude<V2WorkShiftCalendarConfigListPostResponse['list'], undefined>[number];

const ShiftCalendarConfiguration: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [calendarName, setCalendarName] = useState<string>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [shiftAppConfigList, setShiftAppConfigList] = useState<ConfigItem[]>([]);

  const hasAddUnitPermission = useHasPermission(PermissionsType.B_WORKSHIFTCALENDARUSECONFIGCREATE); // 新增用能单元
  const hasAddProcessesPermission = useHasPermission(PermissionsType.B_WORKSHIFTCALENDARUSEPROCESSES); // 新增工序
  const hasUpdatePermission = useHasPermission(PermissionsType.B_WORKSHIFTCALENDARUSECONFIGUPDATE); // 修改应用配置
  const hasDetailPermission = useHasPermission(PermissionsType.B_WORKSHIFTCALENDARUSECONFIGDETAIL); // 查看应用配置

  useEffect(() => {
    if (id) {
      setLoading(true);

      apiV2WorkShiftCalendarDetailPost({ id: Number(id) }).then(res => {
        setCalendarName(res.name);
      });
      apiV2WorkShiftCalendarConfigListPost({ workShiftCalendarId: Number(id) })
        .then(res => {
          setShiftAppConfigList(res?.list ?? []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 250,
      ellipsis: { showTitle: true },
      render: (v: string, record: ConfigItem) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              disabled={!hasDetailPermission}
              onClick={() => {
                const path =
                  record.applyObject === ApplyObject.UNIT
                    ? `/energy/teamShift/shiftCalendar/configuration/detail/${record.id}?workShiftCalendarId=${id}`
                    : `/energy/teamShift/shiftCalendar/process/detail/${record.id}?workShiftCalendarId=${id}`;
                navigate(path);
              }}
            >
              查看
            </Button>
            <Button
              type="link"
              disabled={!hasUpdatePermission}
              onClick={() => {
                const path =
                  record.applyObject === ApplyObject.UNIT
                    ? `/energy/teamShift/shiftCalendar/configuration/edit/${record.id}?workShiftCalendarId=${id}`
                    : `/energy/teamShift/shiftCalendar/process/edit/${record.id}?workShiftCalendarId=${id}`;
                navigate(path);
              }}
            >
              修改应用配置
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="班次日历应用配置" />
      <SubContent>
        <div>
          班次日历：<span style={{ paddingLeft: 12 }}>{calendarName}</span>
        </div>
        <Space size={8} style={{ margin: '24px 0 10px' }}>
          {hasAddUnitPermission && (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/energy/teamShift/shiftCalendar/configuration/add?workShiftCalendarId=${id}`);
              }}
            >
              应用到单元
            </Button>
          )}
          {hasAddProcessesPermission && (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/energy/teamShift/shiftCalendar/process/add?workShiftCalendarId=${id}`);
              }}
            >
              应用到工序
            </Button>
          )}
        </Space>
        <Table rowKey="id" columns={buildColumns} dataSource={shiftAppConfigList} />
      </SubContent>
    </Wrapper>
  );
};

export default ShiftCalendarConfiguration;
