import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Spin, Space } from 'antd';
import { FormTitle, Modal, SubContent } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper, useBreadcrumbRoutes, Button } from '@maxtropy/components';
import { apiV2GatewayIdListEdgeDevicePost } from '@maxtropy/device-customer-apis-v2';
import { fetchGatewayById, updateGatewayDevice } from '../../../api/gateway';
import { DeviceList } from '../type';
import { useQuery } from '../../../utils/utils';
import GatewayDisplay from '../components/GatewayDisplay';
import DeviceTable, { DeviceListRef } from '../components/DeviceTable';
import DeviceModal, { DeviceModalRef } from './DeviceModal';
import styles from '../index.module.scss';

const routes = [{ name: '设备绑定' }];

const GatewayDetail: React.FC = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  const { id } = useParams<{ id: string }>();

  const [visible, setVisible] = useState(false);
  const [devices, setDevices] = useState<DeviceList[]>([]);
  const [bindDeviceIds, setBindDeviceIds] = useState<number[]>([]);
  const deviceRef: MutableRefObject<DeviceModalRef | null> = useRef(null);
  const deviceListRef: MutableRefObject<DeviceListRef | null> = useRef(null);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data: info, isLoading: isInfoLoading } = useQuery(
    useCallback(() => {
      if (id) return fetchGatewayById(id);
      return Promise.resolve(undefined);
    }, [id])
  );

  const getDeviceIds = () => {
    apiV2GatewayIdListEdgeDevicePost({ gatewayId: Number(id) }).then(res => {
      setBindDeviceIds(res.id ?? []);
    });
  };

  useEffect(() => {
    getDeviceIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOK = async () => {
    if (info) {
      const selectedRows: DeviceList[] = deviceRef?.current?.selectedRows || [];

      const deviceIds = bindDeviceIds.concat(selectedRows.map(item => item.id!));
      const params = {
        deviceIds,
        edgeGatewayId: info.id,
        protocol: info.iotProtocol,
      };
      await updateGatewayDevice(params);
      // 更新成功后重新获取所有绑定的设备ID，给单个取消绑定使用
      getDeviceIds();
      setVisible(false);
      deviceListRef?.current?.updateFn?.();
    }
  };

  const onUnBindAll = () => {
    Modal.confirm({
      title: '确定取消绑定网关下所有设备？',
      content: (
        <div>
          <span style={{ color: '#f00' }}>取消绑定后不可恢复</span>，确定取消绑定吗?
        </div>
      ),
      okText: '确定',
      onOk: () => {
        updateGatewayDevice({ deviceIds: [], edgeGatewayId: info?.id!, protocol: info?.iotProtocol! }).then(res => {
          deviceListRef?.current?.updateFn?.();
          getDeviceIds();
        });
      },
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Spin spinning={isInfoLoading}>
        <FormTitle title="设备绑定"></FormTitle>
        <SubContent title="基础信息">
          <GatewayDisplay row={info} />
        </SubContent>
        <SubContent title="关联设备">
          <div className={styles.actionBtn}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
              添加设备
            </Button>

            <Button type="link" onClick={() => onUnBindAll()}>
              取消绑定所有设备
            </Button>
          </div>
          <DeviceTable
            ref={deviceListRef}
            devices={devices}
            setDevices={setDevices}
            id={id}
            iotProtocol={info?.iotProtocol}
            bindDeviceIds={bindDeviceIds}
            getDeviceIds={getDeviceIds}
          />
        </SubContent>
      </Spin>
      <Space className="sticky-footer" size={8} style={{ paddingLeft: 40 }}>
        {query.previous === 'true' && (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/device/config/gateway/update/${id}?next=true`, {
                replace: true,
              })
            }
          >
            上一步
          </Button>
        )}
        <Button
          onClick={() =>
            navigate('/device/config/gateway', {
              replace: true,
            })
          }
        >
          返回
        </Button>
      </Space>
      <Modal
        destroyOnClose
        wrapClassName={styles.customModal}
        size="big"
        title="设备选择"
        open={visible}
        onOk={handleOK}
        onCancel={() => setVisible(false)}
      >
        <DeviceModal ref={deviceRef} bindDeviceIds={bindDeviceIds} iotProtocol={info?.iotProtocol} />
      </Modal>
    </Wrapper>
  );
};

export default GatewayDetail;
