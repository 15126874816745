import { FC, useEffect, useMemo, useState } from 'react';
import {
  Key,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useAsync,
  DatePicker,
  Form,
  Input,
  Select,
  TreeSelect,
  Radio,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { getSaleClientList } from '../../../api/sale';
import { Col, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import MeterForm, { MeterFormValue } from '../MeterForm/index';
import classnames from 'classnames';

import { SettlementCycleType, SettlementCycleTypeDisplay } from '@/shared/types';
import {
  createSalesContract,
  CreateSalesContractRequest,
  getElectricitySaleContractById,
  getElectricitySaleContractMeterList,
  updateSalesContract,
  UpdateSalesContractRequest,
  getPropertyList,
  PropertyItem,
  getClientAccountList,
  ClientAccountItem,
} from '../../../api/electricitySalesContract';
import { useNavigate, useParams } from 'react-router-dom';
import SettlementTimeForm from '../SettlementTimeForm';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { ElectricitySaleContractSettlementMeterType } from '../../../api/electricitySettlementRules';

const { RangePicker } = DatePicker;

interface FormProps {
  name: string;
  address: string;
  electricityMeters: Array<MeterFormValue>;
  demandMeters: Array<MeterFormValue>;
  childrenMeters: Array<MeterFormValue>;
  photovoltaicMeters: Array<MeterFormValue>;
  deductPhotovoltaicMeters: Array<MeterFormValue>;
  effectiveTime: [Dayjs, Dayjs];
  saleClientId: number;
  saleClientAccountId: number;
  settlementCycleType: number;
  settlementTime: string;
  propertyManagementConfigId: number;
  archiveCode: string;
  segmentAccountNumber: string;
  customer_mcid: string;
  useCustomizeBillTitle: boolean;
  billTitle: string;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children ?? []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};

const DATE_FORMAT = 'YYYY-MM-DD';
const FORM_DEFAULT_SETTLEMENT_CYCLE_TYPE_VALUE = SettlementCycleType.MONTH;

const formatPostRequest = (formValues: FormProps): CreateSalesContractRequest => {
  //不选择某个表计即值为undefined时，赋空值
  if (!formValues.demandMeters) formValues.demandMeters = [];
  if (!formValues.electricityMeters) formValues.electricityMeters = [];
  if (!formValues.photovoltaicMeters) formValues.photovoltaicMeters = [];
  if (!formValues.deductPhotovoltaicMeters) formValues.deductPhotovoltaicMeters = [];
  if (!formValues.childrenMeters) formValues.childrenMeters = [];
  return {
    name: formValues.name,
    saleClientId: formValues.saleClientId,
    saleClientAccountId: formValues.saleClientAccountId,
    startTime: formValues.effectiveTime[0].format(DATE_FORMAT),
    endTime: formValues.effectiveTime[1].format(DATE_FORMAT),
    address: formValues.address,
    settlementCycleType: formValues.settlementCycleType,
    settlementHour:
      formValues.settlementCycleType === SettlementCycleType.MONTH
        ? formValues.settlementTime.split('/')[1]
        : formValues.settlementTime,
    settlementDay:
      formValues.settlementCycleType === SettlementCycleType.MONTH ? formValues.settlementTime.split('/')[0] : '',
    propertyManagementConfigId: formValues.propertyManagementConfigId,
    customerMcid: formValues.customer_mcid,
    segmentAccountNumber: formValues.segmentAccountNumber,
    archiveCode: formValues.archiveCode,
    meters: [
      ...formValues.demandMeters,
      ...formValues.electricityMeters,
      ...formValues.photovoltaicMeters,
      ...formValues.deductPhotovoltaicMeters,
      ...formValues.childrenMeters,
    ],
    useCustomizeBillTitle: formValues.useCustomizeBillTitle,
    billTitle: formValues.billTitle ?? null,
  };
};

const formatPutRequest = (formValues: FormProps): UpdateSalesContractRequest => {
  if (!formValues.demandMeters) formValues.demandMeters = [];
  if (!formValues.electricityMeters) formValues.electricityMeters = [];
  if (!formValues.photovoltaicMeters) formValues.photovoltaicMeters = [];
  if (!formValues.deductPhotovoltaicMeters) formValues.deductPhotovoltaicMeters = [];
  if (!formValues.childrenMeters) formValues.childrenMeters = [];
  return {
    name: formValues.name,
    address: formValues.address,
    settlementCycleType: formValues.settlementCycleType,
    settlementHour:
      formValues.settlementCycleType === SettlementCycleType.MONTH
        ? formValues.settlementTime.split('/')[1]
        : formValues.settlementTime,
    settlementDay:
      formValues.settlementCycleType === SettlementCycleType.MONTH ? formValues.settlementTime.split('/')[0] : '',
    meters: [
      ...formValues.demandMeters,
      ...formValues.electricityMeters,
      ...formValues.photovoltaicMeters,
      ...formValues.deductPhotovoltaicMeters,
      ...formValues.childrenMeters,
    ],
    useCustomizeBillTitle: formValues.useCustomizeBillTitle,
    billTitle: formValues.billTitle ?? null,
    segmentAccountNumber: formValues.segmentAccountNumber,
    archiveCode: formValues.archiveCode,
  };
};

const AddSaleContract: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id: saleContractElectricityId } = useParams<{ id: string }>();
  const [settlementCycleType, setSettlementCycleType] = useState<SettlementCycleType>(
    FORM_DEFAULT_SETTLEMENT_CYCLE_TYPE_VALUE
  );
  const [saleClientId, setSaleClientId] = useState<number>();
  const [contractStatus, setContractStatus] = useState<number>();
  const [contractEffectiveTime, setContractEffectiveTime] = useState<[Dayjs, Dayjs]>();
  const customerData = useAsync(getSaleClientList);
  const [loading, setLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<OrganizationResponse>();
  const [property, setProperty] = useState<PropertyItem[]>(); // 所属物业管理列表
  const [clientAccount, setClientAccount] = useState<ClientAccountItem[]>(); // 客户账户
  const [noProperty, setNoProperty] = useState<boolean>(false); // 物业列表为0
  const [settlementDay, setSettlementDay] = useState<string>(''); // 结算时间：日
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 获取客户账户
  const getClientAccount = (saleClientId: Key) => {
    getClientAccountList(saleClientId).then(res => setClientAccount(res?.list ?? []));
  };

  // 所属客户修改，获取新客户账户
  const onChangeClient = (value: string) => {
    form.setFieldsValue({
      saleClientAccountId: undefined,
      demandMeters: undefined,
      electricityMeters: undefined,
      photovoltaicMeters: undefined,
      deductPhotovoltaicMeters: undefined,
      childrenMeters: undefined,
    });
    if (value) {
      getClientAccount(form.getFieldValue('saleClientId'));
    }
  };

  // 获取关联组织
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganization(res);
    });
  }, []);

  const treeData = useMemo(() => {
    if (!organization) return [];
    return formatTreeData([organization]);
  }, [organization]);

  // 获取物业列表
  const getProperty = (customer_mcid: Key) => {
    getPropertyList([customer_mcid]).then(res => {
      if (res) {
        setProperty(res);
      }
    });
  };

  // 关联组织唯一则默认选中
  // useEffect(()=> {
  //   if(!organization) return;
  //   if(organization.children.length === 0 && organization.data.hasPermission) {
  //     form.setFieldsValue({customer_mcid:organization.data?.mcid ?? ''})
  //     getProperty(form.getFieldValue('customer_mcid')); // 组织选中，则调用物业接口
  //   }
  // },[form,organization])

  // 关联组织改变，获取新物业列表
  const onChangeCustomerMcid = (value: string) => {
    form.setFieldsValue({ propertyManagementConfigId: undefined });
    if (value) {
      getProperty(form.getFieldValue('customer_mcid'));
    }
  };

  useEffect(() => {
    if (property?.length === 1) {
      form.setFieldsValue({ propertyManagementConfigId: property[0].id });
    }
  }, [property, form]);

  // 所属物业选项
  const propertyOptions = useMemo(() => {
    if (property?.length === 0) {
      setNoProperty(true);
    } else {
      setNoProperty(false);
    }
    return property?.map(item => ({ label: `${item.code} ${item.salesOrg}`, value: item.id }));
  }, [property]);

  const onFinish = (val: FormProps) => {
    if (isEdit && saleContractElectricityId) {
      setLoading(true);
      updateSalesContract(Number(saleContractElectricityId), formatPutRequest(val))
        .then(() => {
          navigate(`/operation/salesContract/electricity`);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      createSalesContract(formatPostRequest(val))
        .then(() => {
          navigate(`/operation/salesContract/electricity`);
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  //判断是否处于可以更改结算周期的时间
  const isInEditTime = (settlementDay: string) => {
    const currentDay = new Date().getDate();
    return currentDay >= Number(settlementDay) && currentDay <= Number(settlementDay) + 2;
  };

  useEffect(() => {
    (async () => {
      if (saleContractElectricityId && isEdit) {
        setLoading(true);
        const res = await getElectricitySaleContractById(saleContractElectricityId);
        const demandMeters = await getElectricitySaleContractMeterList(
          saleContractElectricityId,
          ElectricitySaleContractSettlementMeterType.DEMAND
        );
        const electricityMeters = await getElectricitySaleContractMeterList(
          saleContractElectricityId,
          ElectricitySaleContractSettlementMeterType.ELECTRICITY
        );
        const photovoltaicMeters = await getElectricitySaleContractMeterList(
          saleContractElectricityId,
          ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
        );
        const deductPhotovoltaicMeters = await getElectricitySaleContractMeterList(
          saleContractElectricityId,
          ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC
        );
        const childrenMeters = await getElectricitySaleContractMeterList(
          saleContractElectricityId,
          ElectricitySaleContractSettlementMeterType.SUBTABLE
        );

        getClientAccount(res.saleClientId);
        getProperty(res.customerMcid);
        setSettlementDay(res.settlementDay);
        form.setFieldsValue({
          demandMeters,
          electricityMeters,
          photovoltaicMeters,
          deductPhotovoltaicMeters,
          childrenMeters,
          name: res.name,
          address: res.address,
          effectiveTime: [dayjs(res.startTime), dayjs(res.endTime)],
          saleClientId: res.saleClientId,
          saleClientAccountId: res.saleClientAccountId,
          settlementCycleType: res.settlementCycleType,
          settlementTime: `${res.settlementDay}/${res.settlementHour}`,
          propertyManagementConfigId: res.propertyManagementConfigId,
          archiveCode: res.archiveCode,
          segmentAccountNumber: res.segmentAccountNumber,
          customer_mcid: res.customerMcid,
          useCustomizeBillTitle: res.useCustomizeBillTitle,
          billTitle: res.billTitle,
        });
        res.settlementCycleType && setSettlementCycleType(res.settlementCycleType as SettlementCycleType);
        res.saleClientId && setSaleClientId(res.saleClientId);
        res.startTime && res.endTime && setContractEffectiveTime([dayjs(res.startTime), dayjs(res.endTime)]);
        setContractStatus(res.status);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleContractElectricityId, isEdit]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}合同信息` }];
  }, [isEdit]);

  const onCancel = () => {
    navigate(`/operation/salesContract/electricity`);
  };

  const onFormValueChange = ({
    settlementCycleType,
    saleClientId,
    effectiveTime,
  }: {
    settlementCycleType: SettlementCycleType;
    saleClientId: number;
    effectiveTime: [Dayjs, Dayjs];
  }) => {
    if (settlementCycleType) {
      setSettlementCycleType(settlementCycleType);
      form.setFieldsValue({ settlementTime: undefined });
    }
    saleClientId && setSaleClientId(saleClientId);
    effectiveTime && setContractEffectiveTime(effectiveTime);
  };

  const customListOptions = useMemo(() => {
    if (customerData && customerData.length !== 0) {
      return customerData.map(i => ({ label: `${i.name}【${i.code}】`, value: i.id }));
    }
  }, [customerData]);

  const clientAccountOptions = useMemo(() => {
    if (clientAccount && clientAccount.length !== 0) {
      return clientAccount.map(i => ({ label: i.name, value: i.id }));
    }
  }, [clientAccount]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{
          settlementCycleType: FORM_DEFAULT_SETTLEMENT_CYCLE_TYPE_VALUE,
          useCustomizeBillTitle: false,
        }}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
        onValuesChange={onFormValueChange}
      >
        <FormTitle title={routes[0].name} />
        <Spin spinning={loading}>
          <SubContent title={'基础信息'} className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="archiveCode"
                  label="存档合同编号"
                  rules={[
                    { required: true, message: '请输入存档合同编号' },
                    { max: 50, message: '最大字数不能超过50' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="合同名称"
                  rules={[
                    { required: true, message: '请输入合同名称' },
                    { max: 20, message: '最大字数不能超过20' },
                  ]}
                >
                  <Input disabled={!!isEdit} maxLength={20} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="saleClientId" label="所属客户" rules={[{ required: true, message: '请选择' }]}>
                  <Select
                    disabled={!!isEdit}
                    placeholder={'请选择'}
                    allowClear
                    onChange={onChangeClient}
                    options={customListOptions}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle dependencies={['saleClientId']}>
                  {({ getFieldValue }) => {
                    let saleClientId = getFieldValue('saleClientId');
                    return (
                      <Form.Item
                        name="saleClientAccountId"
                        label="客户账户"
                        rules={[{ required: true, message: '请选择' }]}
                      >
                        <Select
                          disabled={!!isEdit || !saleClientId}
                          placeholder={'请选择'}
                          allowClear
                          options={clientAccountOptions}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="effectiveTime"
                  label="合同有效期"
                  rules={[{ required: true, message: '请选择合同有效期' }]}
                >
                  <RangePicker style={{ width: '100%' }} disabled={[!!isEdit, !!isEdit]} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="address"
                  label="位置/房号"
                  rules={[
                    { required: true, message: '请输入位置/房号' },
                    { max: 20, message: '最大字数不能超过20' },
                  ]}
                >
                  <Input disabled={!!isEdit} maxLength={20} placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="segmentAccountNumber"
                  label="段户号"
                  rules={[
                    { required: true, message: '请输入段户号' },
                    { max: 30, message: '最大字数不能超过30' },
                    { pattern: /^[^\u4E00-\u9FFF]*$/g, message: '不支持输入中文' },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="customer_mcid"
                  label="所属组织"
                  rules={[{ required: true, message: '请输入关联组织' }]}
                >
                  <TreeSelect
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeDefaultExpandAll
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    showArrow
                    allowClear
                    showSearch={false}
                    treeData={treeData}
                    placeholder="请选择"
                    onChange={onChangeCustomerMcid}
                    disabled={!!isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item noStyle dependencies={['customer_mcid']}>
                  {({ getFieldValue }) => {
                    let customer_mcid = getFieldValue('customer_mcid');
                    return (
                      <Form.Item label="所属物业管理" required>
                        <Form.Item
                          name="propertyManagementConfigId"
                          rules={[{ required: true, message: '请输入/选择' }]}
                        >
                          <Select
                            disabled={!!isEdit || !customer_mcid}
                            showSearch
                            optionFilterProp="label"
                            placeholder="请输入/选择"
                            options={propertyOptions}
                            allowClear
                            filterOption={(input, option) =>
                              (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                        {noProperty ? <p className={styles.prompt}>提示：请先去创建物业管理配置内容</p> : null}
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="是否自定义核查联表头"
                  name="useCustomizeBillTitle"
                  extra={<p className={styles.prompt}>注：如果要生成自定义核查联表头信息，在此处配置</p>}
                  rules={[{ required: true, message: '请选择' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Form.Item noStyle dependencies={['useCustomizeBillTitle']}>
                {({ getFieldValue }) => {
                  const useCustomizeBillTitle = getFieldValue('useCustomizeBillTitle');
                  if (useCustomizeBillTitle) {
                    return (
                      <Col span={8}>
                        <Form.Item
                          name="billTitle"
                          label="核查联表头"
                          rules={[
                            { required: true, message: '请输入核查联表头' },
                            {
                              pattern: /^[\u4e00-\u9fa5a-zA-Z0-9-()（）.%]+$/,
                              message: '请输入中文、英文、数字，支持输入特殊字符 - ()（） . %',
                            },
                            { max: 50, message: '最多输入50个字' },
                          ]}
                        >
                          <Input maxLength={100} placeholder="请输入" />
                        </Form.Item>
                      </Col>
                    );
                  }
                }}
              </Form.Item>
            </Row>
          </SubContent>
          <SubContent title={'结算周期'}>
            <Row>
              <Col span={8}>
                <Form.Item
                  name="settlementCycleType"
                  label="周期频次"
                  rules={[{ required: true, message: '请选择周期频次' }]}
                >
                  <Radio.Group>
                    {/* <Radio  value={SettlementCycleType.DAY}>{SettlementCycleTypeDisplay[SettlementCycleType.DAY]}</Radio> */}
                    <Radio value={SettlementCycleType.MONTH}>
                      {SettlementCycleTypeDisplay[SettlementCycleType.MONTH]}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={16} className={classnames(styles.col, styles.customInput)}>
                <Form.Item
                  name="settlementTime"
                  label="结算时间"
                  rules={[
                    { required: true, message: '请输入结算时间' },
                    { max: 18, message: '最多输入十八个字' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const _settlementCycleType = getFieldValue('settlementCycleType');
                        if (!value) {
                          return Promise.reject(new Error('请填写结算时间'));
                        }

                        switch (_settlementCycleType) {
                          case SettlementCycleType.DAY:
                            const _settlementTime = Number(value);
                            if (_settlementTime > 23 || _settlementTime < 0) {
                              return Promise.reject(new Error('请填写 0-23 的整点'));
                            }
                            break;
                          case SettlementCycleType.MONTH:
                            const dayAndHour = value.split('/');
                            if (!dayAndHour[1]) {
                              return Promise.reject(new Error('请填写 0-23 的整点'));
                            }
                            if (dayAndHour[1] > 23) {
                              return Promise.reject(new Error('请填写 0-23 的整点'));
                            }
                            break;
                          default:
                            break;
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <SettlementTimeForm disabled={!!isEdit && isInEditTime(settlementDay)} type={settlementCycleType} />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <SubContent title={'生效表计'}>
          <div className={styles.tips}>
            <span className={styles.attention}>注意：</span>
            <ul>
              <li>1、表计移除后，不影响已经结算过的历史数据。</li>
              <li>2、新加的表计，历史时间区间不重新计算和生成电费核查联</li>
              <li>3、结束时间的重新设置，不能早于已经结算过的时间。</li>
              <li>4、修改开始时间，不影响已经结算过的历史数据。</li>
              <li>5、生效中、已过期、已终止的合同不允许移除表计。</li>
            </ul>
          </div>
          <div>
            <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
              <MeterForm
                saleClientId={saleClientId}
                isEdit={isEdit}
                status={contractStatus}
                contractEffectiveTime={contractEffectiveTime}
                formTitle="添加电量-计量表计"
                meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
              <MeterForm
                saleClientId={saleClientId}
                isEdit={isEdit}
                status={contractStatus}
                contractEffectiveTime={contractEffectiveTime}
                formTitle="添加需量-计量表计"
                meterType={ElectricitySaleContractSettlementMeterType.DEMAND}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="childrenMeters" label="" wrapperCol={{ span: 24 }}>
              <MeterForm
                saleClientId={saleClientId}
                isEdit={isEdit}
                status={contractStatus}
                contractEffectiveTime={contractEffectiveTime}
                formTitle="添加子表-计量表计"
                meterType={ElectricitySaleContractSettlementMeterType.SUBTABLE}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="deductPhotovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
              <MeterForm
                saleClientId={saleClientId}
                isEdit={isEdit}
                status={contractStatus}
                contractEffectiveTime={contractEffectiveTime}
                formTitle="添加扣除反向电量-光伏计量表计"
                meterType={ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
              <MeterForm
                saleClientId={saleClientId}
                isEdit={isEdit}
                status={contractStatus}
                contractEffectiveTime={contractEffectiveTime}
                formTitle="添加不扣除反向电量-光伏计量表计"
                meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
              />
            </Form.Item>
          </div>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button htmlType="submit" type="primary" loading={loading}>
            保存
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AddSaleContract;
