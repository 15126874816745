import { EllipsisSpan, Key } from '@maxtropy/components';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../../index.module.scss';
import { useParams } from 'react-router-dom';
import {
  TotalTimeSharingElectricityFinalListItem,
  TotalTimeSharingElectricityFinalTableItem,
  getTotalTimeSharingElectricityFinalTableData,
} from '@/api/universeMeterQuery';
import {
  changeFinalTreeData,
  findFinalChild,
  getAllExpandedRow,
  getAllShowOrHideFinalChild,
  sortData,
} from '@/pages/UniverseMeterQuery/handleTreeDataUtils';

// 格式化最终值
const formatFinalDataToTreeTableData = (
  data: TotalTimeSharingElectricityFinalListItem[],
  treeTableData: TotalTimeSharingElectricityFinalTableItem[]
) => {
  data.forEach((i, index) => {
    treeTableData.push({
      ...i,
      isHightLight: false,
      expand: false,
      children: i.list && i.list.length > 0 ? [] : undefined,
      child: i.list && i.list.length > 0 ? formatFinalDataToTreeTableData(i.list, []) : undefined,
    });
  });
  return treeTableData;
};

interface Props {
  isAllExpand: boolean;
  setIsAllExpand: (v: boolean) => void;
}

const FinalTable: React.FC<Props> = ({ isAllExpand, setIsAllExpand }) => {
  const { id } = useParams<{ id: string }>();
  const [table, setTable] = useState<TotalTimeSharingElectricityFinalTableItem[]>([]);
  const [expandedTreeData, setExpandedTreeData] = useState<TotalTimeSharingElectricityFinalTableItem[]>([]); // 是否展开的数据
  const [originTable, setOriginTable] = useState<TotalTimeSharingElectricityFinalTableItem[]>([]); // 原始数据
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [allExpandRowKeys, setAllExpandRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTotalTimeSharingElectricityFinalTableData(id)
        .then(res => {
          if (res) {
            const tableData = formatFinalDataToTreeTableData(res.list ?? [], []);
            setTable(tableData);
            setExpandedTreeData(tableData);
            setOriginTable(tableData);
            setAllExpandRowKeys(getAllExpandedRow(tableData, []).map(i => i.meterReadingObjectId));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isAllExpand) {
      const allExpandRow = getAllExpandedRow(originTable, []);
      let expandedTree = originTable;
      let tree = originTable;
      allExpandRow.forEach(i => {
        expandedTree = changeFinalTreeData(expandedTree, i.meterReadingObjectId, true);
        const currentData = tree.map(item => {
          if (i.meterReadingObjectId === item.meterReadingObjectId) {
            if (i.meterReadingObjectId === item.meterReadingObjectId) {
              return {
                ...item,
                expand: true,
                isHightLight: true,
              };
            }
            return {
              ...item,
              isHightLight: true,
            };
          }
          return item;
        });
        const currentNodeIndex = currentData.findIndex(item => item.meterReadingObjectId === i.meterReadingObjectId);
        const currentNodeLength = currentData.filter(
          item => item.meterReadingObjectId === i.meterReadingObjectId
        ).length;
        const before = currentData.slice(0, currentNodeIndex + currentNodeLength);
        const after = currentData.slice(currentNodeIndex + currentNodeLength);
        const currentChild = findFinalChild(expandedTree, i.meterReadingObjectId);
        const insertChild = sortData(getAllShowOrHideFinalChild(currentChild, [])).map(i => ({
          ...i,
          isHightLight: true,
        }));
        tree = [...before, ...insertChild, ...after];
      });
      setTable(tree);
      setExpandedTreeData(expandedTree);
      setExpandedRowKeys(allExpandRowKeys);
    } else {
      setExpandedRowKeys([]);
      setExpandedTreeData(originTable);
      setTable(originTable);
    }
  }, [allExpandRowKeys, isAllExpand, originTable]);

  useEffect(() => {
    if (
      expandedRowKeys.length > 0 &&
      allExpandRowKeys.length > 0 &&
      expandedRowKeys.length === allExpandRowKeys.length
    ) {
      setIsAllExpand(true);
      return;
    }
    if (expandedRowKeys.length === 0 && allExpandRowKeys.length !== 0) {
      setIsAllExpand(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRowKeys]);

  // 获取展开节点样式
  const getExpandStyle = (isHightLight?: boolean) => {
    return isHightLight
      ? {
          backgroundColor: '#29292C',
        }
      : {};
  };

  // 获取最终值行合并
  const getFinalRowSpan = (record: TotalTimeSharingElectricityFinalTableItem, index?: number) => {
    const firstIndex = table.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
    const objectOccupyRows = table.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
    if (index === firstIndex) {
      return objectOccupyRows;
    }
    return 0;
  };

  const columns = [
    {
      title: '层级',
      dataIndex: 'meterReadingLevel',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      onCell: (record: TotalTimeSharingElectricityFinalTableItem, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
        rowSpan: getFinalRowSpan(record, index),
      }),
      render: (v: number) => {
        return <EllipsisSpan value={v ? `${v}级` : v} />;
      },
    },
    {
      title: '抄表对象',
      dataIndex: 'meterReadingObjectName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
        rowSpan: getFinalRowSpan(record, index),
      }),
    },
    {
      title: '最终值 (尖)',
      dataIndex: 'finalAmountSummit',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '最终值 (峰)',
      dataIndex: 'finalAmountPeak',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '最终值 (平)',
      dataIndex: 'finalAmountPlain',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '最终值 (谷) ',
      dataIndex: 'finalAmountValley',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '尖峰平谷合计',
      dataIndex: 'finalAmountTotal',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '分摊后抄表值',
      dataIndex: 'afterApportionMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
  ];

  return (
    <>
      <div className={styles.tableStyles}>
        <Table
          columns={columns}
          dataSource={table}
          loading={loading}
          rowKey="meterReadingObjectId"
          pagination={false}
          bordered
          sticky
          expandable={{
            onExpand: (isShow, record) => {
              if (isShow) {
                const newExpandTree = changeFinalTreeData(expandedTreeData, record.meterReadingObjectId, true);
                setExpandedTreeData(newExpandTree);
                const currentData = table.map(i => {
                  if (i.meterReadingObjectId === record.meterReadingObjectId) {
                    if (i.meterReadingObjectId === record.meterReadingObjectId) {
                      return {
                        ...i,
                        expand: true,
                        isHightLight: true,
                      };
                    }
                    return {
                      ...i,
                      isHightLight: true,
                    };
                  }
                  return i;
                });
                const currentNodeIndex = currentData.findIndex(
                  i => i.meterReadingObjectId === record.meterReadingObjectId
                );
                const currentNodeLength = currentData.filter(
                  i => i.meterReadingObjectId === record.meterReadingObjectId
                ).length;
                const before = currentData.slice(0, currentNodeIndex + currentNodeLength);
                const after = currentData.slice(currentNodeIndex + currentNodeLength);
                const currentChild = findFinalChild(newExpandTree, record.meterReadingObjectId);
                const insertChild = sortData(getAllShowOrHideFinalChild(currentChild, [])).map(i => ({
                  ...i,
                  isHightLight: true,
                }));
                const newData = [...before, ...insertChild, ...after];
                setTable(newData);
                setExpandedRowKeys([...expandedRowKeys, record.meterReadingObjectId]);
              } else {
                const newExpandTree = changeFinalTreeData(expandedTreeData, record.meterReadingObjectId, false);
                setExpandedTreeData(newExpandTree);
                const currentData = table.map(i => {
                  if (i.meterReadingObjectId === record.meterReadingObjectId) {
                    if (i.meterReadingLevel === 1) {
                      return {
                        ...i,
                        expand: false,
                        isHightLight: false,
                      };
                    }
                  }
                  return i;
                });
                const currentNodeIndex = currentData.findIndex(
                  i => i.meterReadingObjectId === record.meterReadingObjectId
                ); // 当前行的索引
                const currentNodeLength = currentData.filter(
                  i => i.meterReadingObjectId === record.meterReadingObjectId
                ).length;
                const beforePreserve = currentData.slice(0, currentNodeIndex + currentNodeLength); // 保留的数据
                const currentChild = findFinalChild(newExpandTree, record.meterReadingObjectId);
                const delChildLength = getAllShowOrHideFinalChild(currentChild, []).length;
                const afterPreserve = currentData.slice(currentNodeIndex + currentNodeLength + delChildLength); // 保留的数据
                const newData = [...beforePreserve, ...afterPreserve];
                setTable(newData);
                setExpandedRowKeys(expandedRowKeys.filter(i => i !== record.meterReadingObjectId));
              }
            },
            expandedRowKeys,
          }}
        />
      </div>
    </>
  );
};
export default FinalTable;
