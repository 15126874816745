import dayjs from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import { useMemo } from 'react';
import { scale } from '../../utils';
import { YearPlanListItem } from '../EnergyPlan';

interface Props {
  isOverTenThousand: boolean;
  data: YearPlanListItem[];
  outerRef: any;
}

const YearPlanBarEcharts: React.FC<Props> = ({ outerRef, isOverTenThousand, data }) => {
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  const fontZoomSize = useMemo(() => {
    return zoom > 1 ? 12 * zoom : 12;
  }, [zoom]);

  const getChartOption = () => {
    return {
      grid: {
        left: 20,
        // left:80,
        right: 0,
        top: 30,
        bottom: zoom * 20,
      },
      tooltip: {
        show: true,
        // trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        // borderRadius: 2,
        // borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: fontZoomSize,
        },
        borderColor: 'rgba(0,0,0,0.8)',
        formatter: function (params: any) {
          const currentMonthData = data[Number(params.name) - 1];
          const unit = isOverTenThousand ? 'tce' : 'kgce';
          return `<p>${dayjs(currentMonthData?.ts, 'x').format('YYYY-MM')}</p><p>
          <span style='width:${zoom * 8}px; height:${
            zoom * 8
          }px; background: #477BFF;border-radius:50%;display:inline-block;margin-right:${zoom * 4}px;'></span>计划用能
          <span style='margin-left:${zoom * 25}px'>
          ${currentMonthData?.planVal ?? '--'}${unit}</span>
          </p>
          <p>
          <span style='width:${zoom * 8}px; height:${zoom * 8}px; background:${
            currentMonthData?.exceedFlag
              ? 'linear-gradient(180deg, #FF8487 0%, #FF4D4F 100%)'
              : 'linear-gradient(180deg, #92ED79 0%, #59D744 100%)'
          };border-radius:50%;display:inline-block;margin-right:${zoom * 4}px;'></span>实际用能
          <span style='margin-left:${zoom * 25}px' >
          ${currentMonthData?.actualVal ?? '--'}${unit}</span>
         </p>`;
        },
      },
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        axisLabel: {
          fontSize: fontZoomSize,
        },
      },
      yAxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      graphic: ['月计划', '计划内', '超计划'].map((i, index) => {
        return {
          type: 'rect',
          top: 5,
          right: index === 0 ? zoom * 40 : index === 1 ? zoom * 100 : zoom * 160,
          shape: {
            // r: 4,
            width: zoom * 8,
            height: zoom * 8,
          },
          textContent: {
            type: 'text',
            style: {
              text: i,
              fill: 'white',
              fontSize: fontZoomSize,
            },
            cursor: 'default',
          },
          textConfig: {
            position: 'right',
          },
          style: {
            fill:
              index === 0
                ? '#477BFF'
                : index === 1
                ? {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#92ED79', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#59D744', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  }
                : {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#FF8487', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#FF4D4F', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
          },
          cursor: 'default',
        };
      }),
      series: [
        {
          type: 'bar',
          emphasis: {
            focus: 'series',
          },
          name: '计划内',
          data: data.map(item => ({
            value: item.actualVal,
            itemStyle: {
              color: item.exceedFlag
                ? {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#FF8487', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#FF4D4F', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  }
                : {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: '#92ED79', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: '#59D744', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
            },
          })),
        },
        {
          type: 'bar',
          emphasis: {
            focus: 'series',
          },
          name: '月计划',
          data: data.map(item => item.planVal),
          itemStyle: {
            color: '#477BFF',
          },
        },
      ],
    };
  };
  return (
    <>
      <ReactEcharts
        option={getChartOption()}
        notMerge
        lazyUpdate={false}
        style={
          document.documentElement.clientWidth > 2560
            ? {
                width: 700,
                height: 260,
                margin: '0 40px',
                zoom: `${1 / zoom}`,
                transform: `scale(${1 / scale}, ${scale})`,
              }
            : document.documentElement.clientWidth > 1920
            ? {
                width: 600,
                height: 200,
                margin: '0 20px',
                zoom: `${1 / zoom}`,
                transform: `scale(${1 / scale}, ${scale})`,
              }
            : { width: 600, height: 136 }
        }
      />
    </>
  );
};

export default YearPlanBarEcharts;
