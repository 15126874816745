import React, { useState } from 'react';
import { useBreadcrumbRoutes } from '@maxtropy/components';
import { Layout } from 'antd';
import DarkWrapper from '@/pages/EnergyUnitKanban/DarkWrapper';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import List from '@/pages/CarbonEmissionReporting/List/components/List';
import TabsSider from '@/pages/CarbonEmissionReporting/List/components/Tabs';
import styles from './index.module.scss';

const { Content, Sider } = Layout;

interface Props {}
const CarbonEmissionReportingList: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState<boolean>(true);
  const [selectedAccountingUnitId, setSelectedAccountingUnitId] = useState<string>();
  const [treeRequestStatus, setTreeRequestStatus] = useState<boolean>(false);

  return (
    <>
      <DarkWrapper routes={breadcrumbRoutes?.routes ?? []}>
        <Layout className={styles.layout}>
          <Sider className={styles.siderDemo} width={openSider ? 260 : 0}>
            <TabsSider
              selectedAccountingUnitId={selectedAccountingUnitId}
              setSelectedAccountingUnitId={id => setSelectedAccountingUnitId(id)}
              setTreeRequestStatus={boolean => setTreeRequestStatus(boolean)}
            />
          </Sider>
          <Content className={styles.content} style={{ marginLeft: openSider ? 10 : 0 }}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            <List treeRequestStatus={treeRequestStatus} selectedaccountingUnitId={selectedAccountingUnitId} />
          </Content>
        </Layout>
      </DarkWrapper>
    </>
  );
};

export default CarbonEmissionReportingList;
