import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { DevicePoint, RawData } from '../../../../api/history';
import { DatePickerType, StatisticsPartition } from '../DateSwitch';
import { chartOption, scatterChartOption } from './chartOption';
import { isNil } from 'lodash-es';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import styles from './index.module.scss';
import { Empty } from '@maxtropy/components';

export interface HistoryChartProps {
  loading?: boolean;
  data?: RawData[];
  mode: DatePickerType;
  activePoint?: DevicePoint;
  dateMode?: StatisticsPartition;
}

const HistoryChart = (props: HistoryChartProps) => {
  const { loading, data, activePoint, mode, dateMode } = props;

  const echartsTheme = useEchartsTheme();

  const filterData = data?.filter(item => !isNil(item.value));

  if (!loading && filterData?.length === 0) {
    return (
      <div style={{ marginTop: 40 }}>
        <Empty description={'暂无数据'} />
      </div>
    );
  }

  return (
    <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
      {filterData && filterData.length > 0 && (
        <ReactEcharts
          theme={echartsTheme}
          option={chartOption(data, mode, activePoint, dateMode)}
          notMerge
          lazyUpdate={false}
          style={{ height: 'calc(100vh - 340px)', minHeight: 360 }}
        />
      )}
    </Spin>
  );
};

export interface HistoryOriginalChartProps {
  loading?: boolean;
  data?: RawData[];
  activePoint?: DevicePoint;
}

export const HistoryOriginalChart = (props: HistoryOriginalChartProps) => {
  const { loading, data, activePoint } = props;
  const echartsTheme = useEchartsTheme();

  const filterData = data?.filter(item => !isNil(item.value));

  if (!loading && filterData?.length === 0) {
    return (
      <div style={{ marginTop: 40 }}>
        <Empty description={'暂无数据'} />
      </div>
    );
  }

  return (
    <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
      {filterData && filterData.length > 0 && (
        <ReactEcharts
          theme={echartsTheme}
          option={scatterChartOption(data, activePoint)}
          style={{ height: 'calc(100vh - 340px)', minHeight: 360 }}
          notMerge
          lazyUpdate={false}
        />
      )}
    </Spin>
  );
};

export default HistoryChart;
