import { PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Table,
  useUpdate,
  Button,
  Form,
  Checkbox,
  InputNumber,
  Modal,
  SubContent,
} from '@maxtropy/components';
import { Divider, Space, Row, Col } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import {
  createAdditionalPrice,
  deleteAdditionalPrice,
  ElectricityContractVo,
  getAdditionalPriceList,
  getAdditionalPriceType,
  RateAdditionalElectricityPriceRequest,
  RateAdditionalElectricityPriceVo,
  RegionAdditionalElectricityPriceTypeVo,
  updateAdditionalPrice,
} from '../../../../../api/electricity';
import Day, { TimeSpace } from '../components/Day';
import Month from '../components/Month';
import { toPrecision } from '../util';

import styles from './index.module.scss';

const formLayout = {
  labelCol: { flex: '140px' },
};
const formatString = 'YYYY-MM-DD';

export interface CreatePriceFormProps {
  feeType: number[];
  [key: number]: string;
}
export interface CreateMonthFormProps extends CreatePriceFormProps {
  months: number[];
}
export interface CreateDayFormProps extends CreatePriceFormProps {
  day: TimeSpace;
}
export interface ElectricAdditionalPriceProps {
  contract?: ElectricityContractVo;
  disabled?: boolean;
}

const ElectricAdditionalPrice: FC<ElectricAdditionalPriceProps> = ({ contract, disabled = false }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();

  const [priceTypes, setPriceTypes] = useState<RegionAdditionalElectricityPriceTypeVo[]>([]);

  const [prices, setPrices] = useState<RateAdditionalElectricityPriceVo[]>([]);

  useEffect(() => {
    if (contract) {
      getAdditionalPriceType(contract.id).then(res => setPriceTypes(res.list || []));
    }
  }, [contract]);

  useEffect(() => {
    if (contract) {
      setLoading(true);
      getAdditionalPriceList(contract.id).then(res => {
        setPrices(res.list || []);
        setLoading(false);
      });
    }
  }, [contract, updateState]);

  const onDelete = (record: RateAdditionalElectricityPriceVo) => {
    deleteAdditionalPrice(record.id).then(() => {
      updateFn();
    });
  };

  const buildColumns: ColumnType<RateAdditionalElectricityPriceVo>[] = [
    {
      title: '日期',
      dataIndex: 'startDate',
      width: 200,
      render: (v: string, record) => (
        <EllipsisSpan value={`${dayjs(v).format(formatString)} ~ ${dayjs(record.endDate).format(formatString)}`} />
      ),
    },
    {
      title: '附加费用',
      dataIndex: 'feeType',
      ellipsis: { showTitle: true },
      render: (v: number, record) => {
        const find = priceTypes.find(type => type.id === v);
        return (
          <Space>
            <Row>
              {find?.feeTypeName}: <span className={styles.color}>{record.price}</span> 元/kWh
            </Row>
          </Space>
        );
      },
    },
    ...(!disabled
      ? [
          {
            title: '操作',
            fixed: 'right' as 'right',
            width: 280,
            render: (record: RateAdditionalElectricityPriceVo) => (
              <Space size={16}>
                <Button
                  type="link"
                  onClick={() => {
                    onDelete(record);
                  }}
                >
                  删除
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    onEdit(record);
                  }}
                >
                  编辑
                </Button>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [monthVisible, setMonthVisible] = useState<boolean>(false);
  const [monthsForm] = Form.useForm();

  const onAddMonths = () => {
    setMonthVisible(true);
  };

  const onMonthCancel = () => {
    setMonthVisible(false);
    monthsForm.resetFields();
  };

  const onMonthSubmit = () => {
    monthsForm.validateFields().then((value: CreateMonthFormProps) => {
      if (contract) {
        const request: RateAdditionalElectricityPriceRequest[] = value.months
          .map(i => {
            const end = dayjs(i);
            let start = dayjs(end).startOf('month').valueOf();
            start = dayjs(start).isAfter(dayjs(contract.startDate).startOf('date'))
              ? start
              : dayjs(contract.startDate).startOf('date').valueOf();
            return value.feeType.map(feeType => {
              return {
                electricityContractId: contract.id,
                startDate: dayjs(start).format(formatString),
                endDate: dayjs(i).format(formatString),
                feeType,
                price: toPrecision(value[feeType], contract?.precision ?? 0)!,
              };
            });
          })
          .flat();

        createAdditionalPrice(request).then(() => {
          onMonthCancel();
          updateFn();
        });
      }
    });
  };

  const [daysForm] = Form.useForm();
  const [dayVisible, setDayVisible] = useState<boolean>(false);

  const onAddDay = () => {
    setDayVisible(true);
  };

  const onDayCancel = () => {
    daysForm.resetFields();
    setDayVisible(false);
  };

  const onDaySubmit = () => {
    daysForm.validateFields().then((value: CreateDayFormProps) => {
      if (contract) {
        const request: RateAdditionalElectricityPriceRequest[] = value.feeType.map(i => {
          return {
            electricityContractId: contract.id,
            startDate: dayjs(value.day[0]).format(formatString),
            endDate: dayjs(value.day[1]).format(formatString),
            feeType: i,
            price: toPrecision(value[i], contract?.precision ?? 0)!,
          };
        });

        createAdditionalPrice(request).then(() => {
          // 更新
          onDayCancel();
          updateFn();
        });
      }
    });
  };

  const [editForm] = Form.useForm();
  const [rateBasicPrice, setRateBasicPrice] = useState<RateAdditionalElectricityPriceVo>();

  const onEdit = (record: RateAdditionalElectricityPriceVo) => {
    setRateBasicPrice(record);
  };

  const onEditCancel = () => {
    editForm.resetFields();
    setRateBasicPrice(undefined);
  };

  useEffect(() => {
    if (rateBasicPrice && rateBasicPrice.id) {
      editForm.setFieldsValue({
        feeType: [rateBasicPrice.feeType],
        [rateBasicPrice.feeType]: rateBasicPrice.price,
        day: [
          dayjs(rateBasicPrice.startDate).startOf('date').valueOf(),
          dayjs(rateBasicPrice.endDate).endOf('date').valueOf(),
        ],
      });
    }
  }, [rateBasicPrice, editForm]);

  const onEditSubmit = () => {
    if (rateBasicPrice) {
      editForm.validateFields().then((value: CreateDayFormProps) => {
        if (contract) {
          const request: RateAdditionalElectricityPriceRequest = {
            electricityContractId: contract.id,
            rateBasicElectricityPriceId: rateBasicPrice.id,
            startDate: dayjs(value.day[0]).format(formatString),
            endDate: dayjs(value.day[1]).format(formatString),
            feeType: rateBasicPrice.feeType,
            price: toPrecision(value[rateBasicPrice.feeType], contract?.precision ?? 0)!,
          };

          updateAdditionalPrice(request).then(() => {
            onEditCancel();
            updateFn();
          });
        }
      });
    }
  };

  const getUtilFormItem = (priceTypes: RegionAdditionalElectricityPriceTypeVo[], feeTypes?: number[]) => {
    let newPriceTypes = [...priceTypes];
    if (feeTypes && feeTypes.length > 0) {
      newPriceTypes = newPriceTypes.filter(i => feeTypes.includes(i.feeType));
    }
    return (
      <>
        <Form.Item
          name="feeType"
          label="附加费用"
          hidden={feeTypes && feeTypes.length > 0}
          rules={[{ required: true, message: '请选择附加费用' }]}
        >
          {newPriceTypes.length > 0 ? (
            <Checkbox.Group style={{ width: '100%' }}>
              <Row>
                {newPriceTypes.map(i => (
                  <Col key={i.id} span={8}>
                    <Checkbox value={i.feeType}>{i.feeTypeName}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          ) : (
            '该户号所在地区无附加电价'
          )}
        </Form.Item>
        <Form.Item noStyle dependencies={['feeType']}>
          {({ getFieldValue }) => {
            const feeType: number[] | undefined = getFieldValue('feeType');
            if (!feeType) return null;
            return feeType.map(i => {
              const find = newPriceTypes.find(type => type.id === i);
              return find ? (
                <Form.Item name={i} label={`${find.feeTypeName}`} rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber stringMode min={0} addonAfter="元/kWh" precision={contract?.precision ?? 0} />
                </Form.Item>
              ) : null;
            });
          }}
        </Form.Item>
      </>
    );
  };

  return (
    <>
      <SubContent
        title={
          <Space size={8}>
            <span>附加电价</span>
            {!disabled && (
              <Space size={8}>
                <Button type="primary" onClick={onAddMonths} icon={<PlusOutlined />}>
                  <span>按月新增</span>
                </Button>
                <Button type="primary" onClick={onAddDay} icon={<PlusOutlined />}>
                  <span>按日新增</span>
                </Button>
              </Space>
            )}
          </Space>
        }
      >
        <Table rowKey="id" scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={prices} />
      </SubContent>
      <Modal
        size="large"
        title="按月新增-附加电价"
        contentClassName="modal-form-content"
        open={monthVisible}
        onCancel={onMonthCancel}
        onOk={onMonthSubmit}
        okText="保存"
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        footer={priceTypes.length > 0 ? undefined : null}
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={monthsForm}>
            <Form.Item name="months" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Month
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={[]}
              />
            </Form.Item>
            <Divider />
            {getUtilFormItem(priceTypes)}
          </Form>
        )}
      </Modal>
      <Modal
        size="large"
        title="按日新增-附加电价"
        open={dayVisible}
        onCancel={onDayCancel}
        contentClassName="modal-form-content"
        onOk={onDaySubmit}
        okText="保存"
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        footer={priceTypes.length > 0 ? undefined : null}
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={daysForm}>
            <Form.Item name="day" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Day
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={[]}
              />
            </Form.Item>
            <Divider />
            {getUtilFormItem(priceTypes)}
          </Form>
        )}
      </Modal>
      <Modal
        size="large"
        title="编辑附加电价"
        open={!!rateBasicPrice}
        onCancel={onEditCancel}
        onOk={onEditSubmit}
        okText="保存"
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={editForm}>
            <Form.Item name="day" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Day
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={[]}
              />
            </Form.Item>
            <Divider />
            {getUtilFormItem(priceTypes, rateBasicPrice ? [rateBasicPrice.feeType] : undefined)}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default ElectricAdditionalPrice;
