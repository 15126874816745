import { getImportLogByIdDate, ImportLogPageResDates } from '@/api/electricitySettlementRules';
import { Button, getRealUrl, Key, Modal, Upload } from '@maxtropy/components';
import { RcFile } from 'antd/lib/upload';
import { extname } from '@/shared/utils/utils';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Spin } from 'antd';

interface Props {
  batchEdgeOpen: boolean;
  setBatchEdgeOpen: (v: boolean) => void;
  refresh: () => void;
}

const fileSize = 50 * 1024 * 1024;

const FileLog: React.FC<Props> = ({ batchEdgeOpen, setBatchEdgeOpen, refresh }) => {
  const [importRes, setImportRes] = useState<ImportLogPageResDates>();
  const [importing, setImporting] = useState<boolean>(false);
  const [importState, setImportState] = useState<boolean>(false);
  const [logId, setLogId] = useState<Key>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (logId) {
      getImportLogByIdDate(logId).then(res => {
        setImportRes(res);
        setImportState(false);
        setLoading(false);
      });
    }
  }, [logId]);

  useEffect(() => {
    if (importRes) {
      setImporting(false);
    }
  }, [importRes]);

  const beforeUploadFile = (file: RcFile) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileSize = file.size <= fileSize;
    const limitFileType = extName === '.xlsx' || extName === '.xls';
    if (!limitFileSize) {
      Modal.warning({
        content: `上传文件的大小不得超过50M`,
      });
      return false;
    }
    if (!limitFileType) {
      Modal.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };

  const onCancel = () => {
    if (importState) {
      Modal.confirm({
        title: '关闭后请到导入日志中查看。',
        onOk: () => {
          setLogId(undefined);
          setBatchEdgeOpen(false);
          setImportState(false);
          setLoading(false);
        },
      });
      return;
    }
    setLogId(undefined);
    setBatchEdgeOpen(false);
  };
  const downloadFile = (url: string) => {
    var a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <>
      <Modal
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        title="抄表导入"
        onCancel={onCancel}
        open={batchEdgeOpen}
        footer={null}
        afterClose={() => {
          setImporting(false);
          setImportRes(undefined);
          refresh();
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            if (navigator.userAgent.indexOf('Edg/') !== -1) {
              downloadFile('/api/v2/universe/meter/reading/project/template/download');
            } else {
              window.open(`/api/v2/universe/meter/reading/project/template/download`);
            }
          }}
        >
          下载抄表导入模板
        </Button>
        <br />
        <br />
        <Upload
          action="/api/file-center/upload"
          accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          maxCount={1}
          beforeUpload={beforeUploadFile}
          listType="text"
          showUploadList={{
            showRemoveIcon: false,
          }}
          disabled={importing}
          uploadText="选择导入文件"
          onChange={(key, status) => {
            if (status === 'uploading') {
              setImporting(true);
              setImportRes(undefined);
              setLogId(undefined);
              setImportState(true);
              setLoading(true);
            }
            if (status === 'done') {
              setLogId(key as Key);
            }
          }}
        />

        <div className={styles.import}>
          {loading ? (
            <Spin spinning={loading}></Spin>
          ) : (
            <p>
              <span style={{ color: '#E64242' }}>{importRes?.msg}</span>
              {importRes?.fileKey && (
                <Button
                  type="link"
                  onClick={() => {
                    window.open(getRealUrl(importRes?.fileKey));
                  }}
                >
                  下载错误文件
                </Button>
              )}
            </p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FileLog;
