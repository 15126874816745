import { CabinetListCircuitWithDataRes } from '@/api/distributionCabinetManage';
import { Empty } from '@maxtropy/components';
import { Card, Col, Row, Spin } from 'antd';
import { isNil } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';

export interface ICircuitTabContentProps {
  circuitList?: CabinetListCircuitWithDataRes[];
}

const CircuitTabContent: FC<ICircuitTabContentProps> = props => {
  const { circuitList } = props;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (circuitList && circuitList.length) {
      setLoading(false);
    }
  }, [circuitList]);

  return (
    <div style={{ height: 'calc(100vh - 260px)', overflowY: 'auto', padding: '0 16px', overflowX: 'hidden' }}>
      {circuitList && circuitList?.length ? (
        <Spin spinning={loading}>
          <Row gutter={36} style={{ padding: '16px 0px', minWidth: 500 }}>
            {circuitList?.map(item => (
              <Col lg={6} md={8} sm={12} xs={12} key={item.circuitId} style={{ marginBottom: 32, minWidth: 210 }}>
                <Card bordered={false} size="small" className={styles.CardStyle} title={item.circuitName}>
                  <div className={styles.topLeftBorder}></div>
                  <div className={styles.bottomLeftBorder}></div>
                  <div className={styles.LeftBlueBorder}></div>
                  <div className={styles.topRightBorder}></div>
                  <div className={styles.bottomRightBorder}></div>
                  <div className={styles.rightBlueBorder}></div>
                  <div className={styles.topBorder}></div>
                  <div className={styles.bottomBorder}></div>

                  <p style={{ margin: 10, color: 'red', fontSize: '18px' }}>
                    {!isNil(item.electricity) ? item.electricity.toFixed(2) : '--'}kWh
                  </p>
                  <p style={{ margin: 10, color: 'skyblue', fontSize: '18px' }}>
                    {!isNil(item.activePower) ? item.activePower.toFixed(2) : '--'}kW
                  </p>
                </Card>
              </Col>
            ))}
          </Row>
        </Spin>
      ) : (
        <Empty description="该配电柜下暂无回路" />
      )}
    </div>
  );
};

export default CircuitTabContent;
