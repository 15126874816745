import { AccountUnitListItem, getAccountUnitList } from '@/api/CarbonAccountUnits';
import { SaveCarbonTaskBody, getCarbonTaskDetail, saveCarbonTask } from '@/api/CarbonDischargeTask';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Key, DatePicker, Form, Input, Modal, Select } from '@maxtropy/components';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

interface AddTaskModalProps {
  id?: Key;
  setId?: (id?: Key) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
}

const AddTaskModal: React.FC<AddTaskModalProps> = ({ open, setOpen, id, setId, updateFn }) => {
  const [form] = Form.useForm();
  const [accountUnitList, setAccountUnitList] = useState<AccountUnitListItem[]>([]);

  // 获取核算单元列表
  useEffect(() => {
    getAccountUnitList().then(setAccountUnitList);
  }, []);

  // 获取详情
  useEffect(() => {
    if (id) {
      getCarbonTaskDetail(id).then(res => {
        form.setFieldsValue({ ...res, inventoryYear: dayjs(String(res.inventoryYear)) });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const accountUnitOptions = useMemo(() => {
    return (accountUnitList ?? []).map(i => ({ label: i.unitName, value: i.id }));
  }, [accountUnitList]);

  const onFinish = (v: SaveCarbonTaskBody) => {
    if (id) {
      saveCarbonTask({ ...v, id, inventoryYear: dayjs(v.inventoryYear).get('year') }).then(() => {
        setOpen(false);
        setId?.(undefined);
        updateFn();
      });
      return;
    }
    saveCarbonTask({ ...v, inventoryYear: dayjs(v.inventoryYear).get('year') }).then(() => {
      setOpen(false);
      updateFn();
    });
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      open={open}
      title={!!id ? '编辑盘查任务' : '新建盘查任务'}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setId?.(undefined);
        setOpen(false);
      }}
      size="normal"
      okText={!!id ? '保存' : '确定新建'}
    >
      <Form
        onFinish={(v: SaveCarbonTaskBody) => {
          onFinish(v);
        }}
        labelAlign="left"
        form={form}
      >
        <Form.Item
          name="taskName"
          label="盘查任务名称"
          rules={[
            { required: true, message: '请输入盘查任务名称' },
            { min: 2, message: '至少输入2个字符' },
            { max: 50, message: '最多输入50个字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="accountingUnitId"
          label="所属核算单元"
          rules={[{ required: true, message: '请选择所属核算单元' }]}
        >
          <Select disabled={!!id} options={accountUnitOptions} placeholder="请选择" />
        </Form.Item>
        <Form.Item name="inventoryYear" label="盘查周期" rules={[{ required: true, message: '请选择盘查周期' }]}>
          <DatePicker disabled={!!id} style={{ width: '100%' }} picker="year" placeholder="请选择" />
        </Form.Item>
      </Form>
      <p
        style={{
          position: 'absolute',
          bottom: '5px',
          left: '13px',
        }}
      >
        <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} /> 盘查任务创建后不能删除!
      </p>
    </Modal>
  );
};

export default AddTaskModal;
