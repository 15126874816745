import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Input } from '@maxtropy/components';
import { DataPointType } from '../../../types';
import Point from './Point';
import Virtual from './Virtual';
import styles from './index.module.scss';
import { dataColumns } from '../estun/Detail';
import StaticSpot from './Static';
import useIsGateway from '../../../hooks/useIsGateway';
import { PointContext } from '../contextTypes';

const { TabPane } = Tabs;

export interface SearchParams {
  name?: string;
  pointType?: DataPointType;
}

interface MockingbirdPointDetailProps {
  loading?: boolean;
  setSearchParams?: (searchParams: SearchParams) => void;
  hasDataColumns?: boolean;
}

const MockingbirdPointDetail: React.FC<MockingbirdPointDetailProps> = props => {
  const { loading, setSearchParams, hasDataColumns } = props;

  const [activeKey, setActiveKey] = useState<DataPointType>();
  const [searchValue, setSearchValue] = useState('');
  const { info } = useContext(PointContext);
  const { loading: isGatewayLoading, isGateway } = useIsGateway(info?.deviceTypeId);

  useEffect(() => {
    if (isGatewayLoading) return;
    if (isGateway) {
      setActiveKey(DataPointType.STATIC_POINT);
    } else {
      setActiveKey(DataPointType.BASE_POINT);
    }
  }, [isGatewayLoading, isGateway]);

  useEffect(() => {
    if (activeKey) {
      setSearchParams?.({ pointType: +activeKey, name: searchValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, searchValue]);

  if (isGatewayLoading) return null;

  return (
    <div className={styles.container}>
      <Tabs
        activeKey={`${activeKey}`}
        onChange={(key: string) => {
          setActiveKey(Number(key));
        }}
        destroyInactiveTabPane={true}
        tabBarExtraContent={
          <Input.Search
            style={{ width: 260 }}
            placeholder="请输入数据属性名称/标识符"
            allowClear
            onSearch={setSearchValue}
          />
        }
      >
        {!isGateway && (
          <TabPane tab="采集点" key={DataPointType.BASE_POINT} forceRender>
            <Point loading={loading} editColumns={hasDataColumns ? dataColumns : []} />
          </TabPane>
        )}
        {!isGateway && (
          <TabPane tab="虚拟点" key={DataPointType.VIRTUAL_POINT}>
            <Virtual loading={loading} editColumns={hasDataColumns ? dataColumns : []} />
          </TabPane>
        )}
        <TabPane tab="静态点" key={DataPointType.STATIC_POINT}>
          <StaticSpot loading={loading} editColumns={hasDataColumns ? dataColumns : []} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MockingbirdPointDetail;
