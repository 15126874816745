import React, { useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAsync, Input, Select, Modal, TreeSelect, Form } from '@maxtropy/components';
import styles from '../index.module.scss';

import {
  copyScada,
  createScada,
  CreateScadaReq,
  getScadaInfo,
  TimelinessDisplay,
  TimelinessType,
  UpdateScada,
} from '../../../api/scada';
import { getOuListOwned, OuListResponse } from '../../../api/ou';
import { OuStatus } from '@/shared/const';

interface ModalComponentProps {
  title: string;
  isVisible: boolean;
  isEdit?: boolean;
  isCopy?: boolean;
  isAdd?: boolean;
  onCancel: () => void;
  id?: number;
}

interface OuGroup {
  [index: string]: OuListResponse[];
}

const ModalComponent: React.FC<ModalComponentProps> = props => {
  const { title, isVisible, isEdit, isCopy, isAdd, onCancel, id } = props;
  const [visible, setVisible] = useState<boolean>(isVisible);
  const [form] = Form.useForm();

  const ou = useAsync(getOuListOwned, []);
  const enableOu = ou.filter(i => i.status === OuStatus.ENABLE);

  useEffect(() => {
    if (id) {
      getScadaInfo(id).then(data => {
        const enableOuIds = enableOu.map(i => i.id);
        let copyOuIds: Array<number> = [];
        let editOuIds: Array<number> = [];
        if (data.ous !== undefined) {
          copyOuIds = data.ous.map(i => i.id).filter(i => enableOuIds.includes(i));
          editOuIds = data.ous.map(i => i.id);
        }
        form.setFieldsValue({
          ...data,
          ouIds: editOuIds,
          name: isEdit ? data.name : `${data.name}的复制`,
        });
        if (isCopy) {
          form.setFieldsValue({
            ouIds: copyOuIds,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, enableOu]);

  const onCancelAction = () => {
    setVisible(false);
    onCancel();
  };

  const onFinish = (values: CreateScadaReq) => {
    if (isAdd) {
      createScada(values).then(data => {
        onCancel();
      });
    } else if (isCopy) {
      copyScada(Number(id), values).then(data => {
        onCancel();
      });
    } else if (isEdit) {
      UpdateScada(Number(id), values).then(data => {
        onCancel();
      });
    }
  };

  const ouTree = useMemo(() => {
    let newData: OuGroup = {};
    const ous = ou && isEdit ? ou : enableOu;
    ous.forEach(e => {
      if (Object.keys(newData).indexOf('' + e.customerName) === -1) {
        newData[e.customerName] = [];
      }
      newData[e.customerName].push(e);
    });
    return Object.keys(newData).map(i => {
      return {
        title: i,
        value: i,
        key: i,
        children: newData[i].map(item => ({
          title: item.name,
          value: item.id,
          key: item.id,
        })),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ou, enableOu]);

  return (
    <>
      <Modal title={title} open={visible} onOk={() => form.submit()} onCancel={() => onCancelAction()}>
        <Form
          onFinish={onFinish}
          initialValues={{ dataTimeliness: TimelinessType.MINUTES15 }}
          form={form}
          style={{ padding: '0 32px' }}
        >
          <Form.Item
            label="组态名称"
            name="name"
            rules={[
              { required: true, message: '请输入组态名称' },
              { max: 15, message: '最多15个字符!' },
            ]}
          >
            <Input placeholder="最多15个字符，同一组织下名称唯一" />
          </Form.Item>
          <Form.Item
            label="运营单元"
            name="ouIds"
            rules={[{ required: true, message: '请选择运营单元' }]}
            extra={
              <>
                <InfoCircleOutlined className={styles.info} />
                若无运营单元请先新建运营单元或联系管理员进行添加
              </>
            }
          >
            <TreeSelect
              treeData={ouTree}
              treeCheckable={true}
              placeholder="请选择"
              treeDefaultExpandAll
              showSearch
              filterTreeNode={(inputValue: string, treeNode) => {
                return (treeNode?.title as string)?.includes(inputValue);
              }}
            />
          </Form.Item>
          <Form.Item label="数据时效性阀值">
            <Form.Item name="dataTimeliness" noStyle>
              <Select placeholder="请选择">
                <Select.Option value={TimelinessType.MINUTES15}>
                  {TimelinessDisplay[TimelinessType.MINUTES15]}
                </Select.Option>
                <Select.Option value={TimelinessType.MINUTES30}>
                  {TimelinessDisplay[TimelinessType.MINUTES30]}
                </Select.Option>
                <Select.Option value={TimelinessType.MINUTES45}>
                  {TimelinessDisplay[TimelinessType.MINUTES45]}
                </Select.Option>
                <Select.Option value={TimelinessType.HOUR1}>{TimelinessDisplay[TimelinessType.HOUR1]}</Select.Option>
                <Select.Option value={TimelinessType.HOUR4}>{TimelinessDisplay[TimelinessType.HOUR4]}</Select.Option>
              </Select>
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalComponent;
