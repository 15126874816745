import {
  EllipsisSpan,
  Key,
  Paging,
  Table,
  usePaging,
  Button,
  CustomFilter,
  Form,
  Input,
  TreeSelect,
  Modal,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import styles from './index.module.scss';
import { Link, useParams } from 'react-router-dom';
import { getOrganizationWithCodeWithCurrent } from '@/api/device';
import IpInput from '../IpInput';
import { findGateway, Gateway, GatewayPageRequest } from '@/api/netWorking';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

function formatTreeData(data: any[]) {
  return data.map(item => {
    const data = item?.data;
    const result: DefaultOptionType = {
      key: data?.mcid ?? '',
      value: data?.mcid ?? '',
      title: data?.name,
      children: formatTreeData(item.children),
      disabled: !data?.hasPermission,
    };
    return result;
  });
}
const modalColumns: ColumnsType<Gateway> = [
  {
    title: '网关编号',
    dataIndex: 'gatewayNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'gatewayName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'orgName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

interface Props {
  value?: Gateway[];
  onChange: (value: Gateway[]) => void;
}

export interface SearchParams extends Omit<GatewayPageRequest, 'page' | 'size' | ' netId'> {}

const AddGateway: React.FC<Props> = ({ value, onChange }) => {
  const { id } = useParams<{ id: string }>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [choseGatewayDataSource, setChoseGatewayDataSource] = useState<Gateway[]>([]); // 所选网关
  const [gatewayDataSource, setGatewayDataSource] = useState<Gateway[]>([]); // 所有网关
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<Gateway[]>([]);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    findGateway({
      ...searchParams,
      netId: id,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setGatewayDataSource(res.list);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, pageOffset, pageSize, searchParams, setTotalCount]);

  useEffect(() => {
    if (value && value.length > 0) {
      setChoseGatewayDataSource(value);
      setSelectRowKeys(value.map(i => i.gatewayId));
      setSelectedRows(value);
    }
  }, [value]);

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };
  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const buildColumns = [
    ...modalColumns,
    {
      title: 'IP地址',
      dataIndex: 'gatewayIp',
      ellipsis: { showTitle: true },
      width: 500,
      render: (v: any, record: Gateway) => {
        return (
          <IpInput
            record={record}
            setChoseGatewayDataSource={setChoseGatewayDataSource}
            choseGatewayDataSource={choseGatewayDataSource}
            onChange={onChange}
          />
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      render: (v: any, record: Gateway) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                const restChoseId = selectedRowKeys.filter(i => i !== record.gatewayId);
                const restChose = choseGatewayDataSource.filter(i => i.gatewayId !== record.gatewayId);
                setChoseGatewayDataSource(restChose);
                setSelectRowKeys(restChoseId);
                setSelectedRows(restChose);
                onChange(restChose);
              }}
            >
              移出
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter colSpan={8} form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="gatewayNo" label="编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>

      <Form.Item name="gatewayName" label="名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>

      <Form.Item name="orgId" label="所属组织">
        <TreeSelect
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
      </Form.Item>
    </CustomFilter>
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (rowKeys: Key[], rows: Gateway[]) => {
      setSelectRowKeys(rowKeys);
      setSelectedRows(rows);
    },
    selectedRowKeys,
  };

  const onCancel = () => {
    setOpenModal(false);
    setSelectRowKeys(choseGatewayDataSource.map(i => i.gatewayId));
    setSelectedRows(choseGatewayDataSource);
    form.resetFields();
    setSearchParams(undefined);
  };

  const onOk = () => {
    const restId = choseGatewayDataSource.filter(i => selectedRowKeys.includes(i.gatewayId)).map(i => i.gatewayId);
    const rest = choseGatewayDataSource.filter(i => selectedRowKeys.includes(i.gatewayId));
    const choseGateway = selectedRows.map(i => {
      if (restId.includes(i.gatewayId)) {
        return {
          ...i,
          gatewayIp: rest.find(item => item.gatewayId === i.gatewayId)?.gatewayIp,
          isRepeat: rest.find(item => item.gatewayId === i.gatewayId)?.isRepeat,
        };
      }
      return {
        ...i,
        gatewayIp: [],
      };
    });
    form.resetFields();
    setSearchParams(undefined);
    setChoseGatewayDataSource(choseGateway);
    setOpenModal(false);
    onChange(selectedRows);
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <Button style={{ padding: '0 20px' }} type="primary" onClick={() => setOpenModal(true)}>
          添加
        </Button>
      </div>

      {choseGatewayDataSource.length > 0 ? (
        <Table
          style={{ width: '100%' }}
          rowKey="gatewayId"
          columns={buildColumns}
          dataSource={choseGatewayDataSource}
        />
      ) : null}
      <Modal size="big" open={openModal} onOk={onOk} onCancel={onCancel} title="网关选择">
        {filters}
        <p style={{ float: 'right' }}>
          点击前往
          <Link target="_blank" to={'/device/config/gateway/create'}>
            添加网关
          </Link>
        </p>

        <div className={styles.tips}>
          <p className={styles.info}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', paddingRight: '2px' }} />
            <span style={{ color: 'rgba(255, 255, 255, 0.45)' }}>
              仅zapdos版本在4.0以上，才支持组网，否则会下发失败。
            </span>
          </p>
        </div>
        <Table
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          showSelectedCount
          selectedCount={(selectedRows ?? []).length}
          scroll={{ y: 300 }}
          rowKey="gatewayId"
          columns={modalColumns}
          dataSource={gatewayDataSource}
        >
          <Paging pagingInfo={pagingInfo} />
        </Table>

        <p style={{ marginTop: '15px' }}>
          <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', paddingRight: '2px' }} />
          <span style={{ color: 'rgba(255, 255, 255, 0.45)' }}>提示：已作废的网关，不在此处展示</span>
        </p>
      </Modal>
    </>
  );
};

export default AddGateway;
