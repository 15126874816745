import { triggerModal } from '@maxtropy/components';
import { Upload } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { useCallback, useState } from 'react';

export function generateLimitFileSize(fileSize: number) {
  return (info: RcFile) => {
    const conform = info.size / 1024 / 1024 <= fileSize;
    if (!conform) {
      triggerModal({
        type: 'error',
        props: {
          content: `上传文件的大小不得超过${fileSize}M`,
        },
      });
      // Modal.error({
      //   content: `上传文件的大小不得超过${fileSize}M`,
      // });
    }
    return conform ? conform : Upload.LIST_IGNORE;
  };
}

export function useUploadMaxDisabled(maxCount: number) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const uploadChange = useCallback(
    (info: UploadChangeParam) => {
      setDisabled(info.fileList.length >= maxCount);
    },
    [maxCount]
  );
  return [disabled, uploadChange] as [boolean, (info: UploadChangeParam) => void];
}

export const clentAccountFileUplodaMaxCount = 10;

export function normFile(e: any) {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
}

export const timeFormatString = 'YYYY-MM-DD HH:mm:ss';

export const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
