import React, { useMemo, useState } from 'react';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Empty,
  Input,
  Radio,
  Tabs,
  DatePicker,
  Tree,
  Button,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout, Space, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import {
  BoardChartData,
  BoardChartDataQuery,
  ConsumptionAttribute,
  ConsumptionAttributeDisplay,
  ConsumptionAttributeUnitDisplay,
  ConsumptionType,
  ConsumptionTypeDisplay,
  TimeGranularity,
  TimeGranularityDisplay,
  getBoardChartDayData,
  getBoardChartMonthData,
  getBoardChartQuarterData,
  getBoardTreeInfo,
} from '@/api/energyConsumptionBoardDemo';
import type { DataNode } from 'antd/es/tree';
import dayjs, { Dayjs } from 'dayjs';
import CardForData from '../WaterDashBoard/components/CardForData';
import DragResize from '@/components/DragResize';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { BarColor, MarkAreaConfig, MarkAreaLegend, getTimeIntervals } from './chartConfigs';
import energyUnit from './images/energyUnit.png';
import root from './images/root.png';
import workProcedure from './images/workProcedure.png';
import workStation from './images/workStation.png';
import generalActive from './images/generalActive.png';
import generalPassive from './images/generalPassive.png';
import electricityActive from './images/electricityActive.png';
import electricityPassive from './images/electricityPassive.png';

// Register the required components
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
]);

const { restIntervals, freeIntervals, lineStopIntervals, workIntervals } = getTimeIntervals();

const ConsumptionTypeIconsDisplay = {
  [ConsumptionType.GENERAL]: {
    active: generalActive,
    passive: generalPassive,
  },
  [ConsumptionType.ELECTRICITY]: {
    active: electricityActive,
    passive: electricityPassive,
  },
};

export const getFlattenTreeData = (
  data: TreeDataNode[]
): { title: any; originalName?: string; key: React.Key; children: any }[] => {
  return data.flatMap(({ title, originalName, key, children }) => [
    { title, originalName, key, children },
    ...getFlattenTreeData(children || []),
  ]);
};

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

type TreeDataNode = DataNode & { originalName?: string; img?: React.ReactNode };

export default function ProductionEnergyConsumptionBoardDemo() {
  const echartsTheme = useEchartsTheme();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);
  // 左侧树
  const [tree, setTree] = useState<TreeDataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const flattenedTree = useMemo(() => {
    return getFlattenTreeData(tree);
  }, [tree]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>(['energyUnit-1']);
  // 顶部选择综合能耗还是电
  const [selectedConsumption, setSelectedConsumption] = useState<ConsumptionType>(ConsumptionType.GENERAL);
  // 时间颗粒度上面的选项, 例如综合能耗下的发热量和标准煤, 电下的有功电能
  const [selectedTabItem, setSelectedTabItem] = useState<ConsumptionAttribute>(ConsumptionAttribute.HEAT);
  // 时间颗粒度, 15 分钟/天/月
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>(TimeGranularity.FIFTEEN_MINUTES);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [chartData, setChartData] = useState<BoardChartData[]>([]);
  const [loading, setLoading] = useState(false);

  // 完整左侧树, 包括 icon, 高亮
  const treeData = useMemo(() => {
    const loop = (data: TreeDataNode[]): TreeDataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.treeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            icon: item.icon,
            key: item.key,
            children: loop(item.children),
          };
        }

        return {
          icon: item.icon,
          title,
          key: item.key,
        };
      });

    return loop(tree);
  }, [searchValue, tree]);

  // tabs 项目, 发热量/标准煤/有功电能
  const tabsItems = useMemo(() => {
    if (selectedConsumption === ConsumptionType.GENERAL) {
      return [
        {
          key: ConsumptionAttribute.HEAT,
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color: selectedTabItem === ConsumptionAttribute.HEAT ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.HEAT]}
              </div>
            </div>
          ),
        },
        {
          key: ConsumptionAttribute.COAL,
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color: selectedTabItem === ConsumptionAttribute.COAL ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.COAL]}
              </div>
            </div>
          ),
        },
      ];
    } else if (selectedConsumption === ConsumptionType.ELECTRICITY) {
      return [
        {
          key: ConsumptionAttribute.ACTIVE_POWER,
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color:
                    selectedTabItem === ConsumptionAttribute.ACTIVE_POWER ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.ACTIVE_POWER]}
              </div>
            </div>
          ),
        },
      ];
    } else {
      return [];
    }
  }, [selectedConsumption, selectedTabItem]);

  // 卡片总能耗
  const totalConsumption = useMemo(() => {
    if (!chartData.length) {
      return '--';
    }
    if (selectedTabItem === ConsumptionAttribute.HEAT) {
      return chartData.map(d => d.calorificValue).reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
    } else if (selectedTabItem === ConsumptionAttribute.COAL) {
      return chartData.map(d => d.standardCoal).reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
    } else if (selectedTabItem === ConsumptionAttribute.ACTIVE_POWER) {
      return chartData.map(d => d.value).reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
    } else {
      return '--';
    }
  }, [chartData, selectedTabItem]);

  const options = useMemo(() => {
    return {
      // 坐标轴左右间距
      grid: {
        left: 80,
        right: 80,
      },
      backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
      title: {},
      graphic:
        timeGranularity === TimeGranularity.FIFTEEN_MINUTES
          ? [
              ...MarkAreaLegend.map(({ right, legendColor, text }) => ({
                type: 'rect',
                top: 5,
                right: selectedConsumption === ConsumptionType.GENERAL ? right : right - 65,
                shape: {
                  r: 4,
                  width: 24,
                  height: 15,
                },
                textContent: {
                  type: 'text',
                  style: {
                    text,
                  },
                },
                textConfig: {
                  position: 'right',
                },
                style: {
                  fill: legendColor,
                },
              })),
            ]
          : [],
      legend: {
        right: 70,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: 2,
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
        },
        formatter: (params: any) => {
          const [barParams, lineParams] = params;
          const [time, barValue] = barParams.data;
          let formattedTime;
          if (timeGranularity === TimeGranularity.FIFTEEN_MINUTES) {
            formattedTime = dayjs(time).format('YYYY-MM-DD[\n]HH:mm');
          } else if (timeGranularity === TimeGranularity.DAY) {
            formattedTime = dayjs(time).format('YYYY-MM-DD');
          } else {
            formattedTime = dayjs(time).format('YYYY-MM');
          }
          const lineValue = lineParams?.data?.[1];
          return `
            <div>
              <div>
                ${formattedTime}
              </div>
              <div style="display: flex; width: 200px; justify-content :space-between;">
                <span>${ConsumptionAttributeDisplay[selectedTabItem]}: </span>
                <strong>${barValue}${ConsumptionAttributeUnitDisplay[selectedTabItem]}</strong>
              </div>
              ${
                lineParams
                  ? `<div style="display: flex; width: 200px; justify-content :space-between;">
                      <span>能耗费用: </span>
                      <strong>${lineValue}万元</strong>
                    </div>
                  `
                  : `<div />`
              }
            </div>
          `;
        },
      },
      xAxis: {
        min: 'dataMin',
        max: 'dataMax',
        type: 'time',
        axisLabel: {
          formatter: (v: number) => {
            if (timeGranularity === TimeGranularity.FIFTEEN_MINUTES) {
              return dayjs(v).format('YYYY-MM-DD[\n]HH:mm');
            } else if (timeGranularity === TimeGranularity.DAY) {
              return dayjs(v).format('YYYY-MM-DD');
            } else {
              return dayjs(v).format('YYYY-MM');
            }
          },
        },
      },
      yAxis: [
        {
          type: 'value',
          name: ConsumptionAttributeUnitDisplay[selectedTabItem],
          position: 'left',
          // 和右边的 y 轴对齐
          alignTicks: true,
          axisLine: {
            show: true,
          },
          // axisLabel: {
          //   formatter: `{value} ${ConsumptionAttributeUnitDisplay[selectedTabItem]}`,
          // },
        },
        // 只有综合能耗才有费用
        selectedConsumption === ConsumptionType['GENERAL']
          ? {
              type: 'value',
              name: '万元',
              position: 'right',
              alignTicks: true,
              axisLine: {
                show: true,
              },
              // axisLabel: {
              //   formatter: `{value} 万元`,
              // },
            }
          : {},
      ],
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: 'none',
      //     },
      //     restore: {},
      //     saveAsImage: {},
      //   },
      // },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          bottom: 4,
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          name: '能耗',
          type: 'bar',
          data: chartData?.map(d => [
            d.ts,
            selectedTabItem === ConsumptionAttribute['HEAT']
              ? d.calorificValue
              : selectedTabItem === ConsumptionAttribute['COAL']
              ? d.standardCoal
              : d.value,
          ]),
          itemStyle: {
            color: BarColor[selectedConsumption],
          },
          markArea:
            timeGranularity === TimeGranularity.FIFTEEN_MINUTES
              ? {
                  data: [
                    ...restIntervals.map(r => [
                      {
                        // name: '休息',
                        xAxis: selectedDate.startOf('day').add(r[0], 'hours').valueOf(),
                        itemStyle: {
                          color: MarkAreaConfig.rest.color,
                        },
                      },
                      { xAxis: selectedDate.startOf('day').add(r[1], 'hours').valueOf() },
                    ]),
                    ...freeIntervals.map(r => [
                      {
                        // name: '空闲',
                        xAxis: selectedDate.startOf('day').add(r[0], 'hours').valueOf(),
                        itemStyle: {
                          color: MarkAreaConfig.free.color,
                        },
                      },
                      { xAxis: selectedDate.startOf('day').add(r[1], 'hours').valueOf() },
                    ]),
                    ...lineStopIntervals.map(r => [
                      {
                        // name: '停线',
                        xAxis: selectedDate.startOf('day').add(r[0], 'hours').valueOf(),
                        itemStyle: {
                          color: MarkAreaConfig.lineStop.color,
                        },
                      },
                      { xAxis: selectedDate.startOf('day').add(r[1], 'hours').valueOf() },
                    ]),
                    ...workIntervals.map(r => [
                      {
                        // name: '正常生产',
                        xAxis: selectedDate.startOf('day').add(r[0], 'hours').valueOf(),
                        itemStyle: {
                          color: MarkAreaConfig.work.color,
                        },
                      },
                      { xAxis: selectedDate.startOf('day').add(r[1], 'hours').valueOf() },
                    ]),
                  ],
                }
              : {},
        },
        ...(selectedConsumption === ConsumptionType['GENERAL']
          ? [
              {
                name: '费用',
                itemStyle: {
                  color: '#FFCB47',
                },
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: chartData?.map(d => [d.ts, d.amount]),
              },
            ]
          : []),
      ],
    };
  }, [chartData, selectedConsumption, selectedDate, selectedTabItem, timeGranularity]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 获取左侧树
  useEffect(() => {
    getBoardTreeInfo().then(res => {
      const formattedTree: TreeDataNode[] = [
        {
          title: res.name,
          icon: <img src={root} width={16} alt="" style={{ marginBottom: -6 }} />,
          key: `root-${res.id}`,
          children: res.workProcedures.map(wp => ({
            title: wp.name,
            icon: <img src={workProcedure} width={16} alt="" style={{ marginBottom: -6 }} />,
            key: `workProcedure-${wp.id}`,
            children: wp.workStations.map(ws => ({
              title: ws.name,
              icon: <img src={workStation} width={16} alt="" style={{ marginBottom: -6 }} />,
              key: `workStation-${ws.id}`,
              children: ws.energyUnits.map(eu => ({
                title: eu.energyUnitName,
                icon: <img src={energyUnit} width={16} alt="" style={{ marginBottom: -6 }} />,
                key: `energyUnit-${eu.energyUnitId}`,
              })),
            })),
          })),
        },
      ];

      setTree(formattedTree);
    });
  }, []);

  useEffect(() => {
    // 左侧树默认全部展开
    if (flattenedTree.length) {
      setExpandedKeys(flattenedTree.map(data => data.key));
    }
  }, [flattenedTree]);

  // 根据时间颗粒度, 选择的时间以及树节点获取所有请求
  useEffect(() => {
    // 清空之前的数据
    // setChartData([]);
    if (selectedKeys.length) {
      setLoading(true);

      const key = selectedKeys[0] as string;
      const query: BoardChartDataQuery = {
        energyUnitId: key.includes('energyUnit') ? key.split('-')[1] : undefined,
        workStationId: key.includes('workStation') ? key.split('-')[1] : undefined,
        workProcedureId: key.includes('workProcedure') ? key.split('-')[1] : undefined,
        date: timeGranularity === TimeGranularity.FIFTEEN_MINUTES ? selectedDate.format('YYYY-MM-DD') : undefined,
      };

      if (timeGranularity === TimeGranularity.FIFTEEN_MINUTES) {
        getBoardChartQuarterData(query)
          .then(res => {
            setChartData(res);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (timeGranularity === TimeGranularity.DAY) {
        getBoardChartDayData(query)
          .then(res => {
            setChartData(res);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (timeGranularity === TimeGranularity.MONTH) {
        getBoardChartMonthData(query)
          .then(res => {
            setChartData(res);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setChartData([]);
      }
    } else {
      setChartData([]);
    }
  }, [selectedDate, selectedKeys, timeGranularity]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          theme="light"
          className={styles.sider}
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', marginRight: openSider ? 10 : 0 }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <div className={styles.treeArea}>
            <div className={styles.searchArea}>
              <Input.Search
                placeholder="请输入"
                allowClear
                onSearch={value => {
                  const newExpandedKeys = flattenedTree
                    .map(item => {
                      if (item.title.indexOf(value)! > -1) {
                        return getParentKey(item.key, treeData);
                      }
                      return null;
                    })
                    .filter((item, i, self) => item && self.indexOf(item) === i);
                  setExpandedKeys(newExpandedKeys as React.Key[]);
                  setAutoExpandParent(true);
                  setSearchValue(value);
                }}
              />
            </div>

            <div className={styles.treeBox}>
              {tree.length ? (
                <Tree
                  showIcon
                  blockNode
                  onExpand={newExpandedKeys => {
                    setExpandedKeys(newExpandedKeys);
                    setAutoExpandParent(false);
                  }}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  style={{ height: 'calc(100vh - 180px)' }}
                  treeData={treeData}
                  // defaultExpandAll
                  selectedKeys={selectedKeys}
                  onSelect={(selectedKeys, info) => {
                    setSelectedKeys(selectedKeys);
                  }}
                />
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>

            <Space style={{ padding: '20px 12px 12px 20px' }} size="small">
              {Object.entries(ConsumptionTypeDisplay).map(([k, v]) => (
                <Button
                  style={{ paddingLeft: 12, paddingRight: 16 }}
                  onClick={() => {
                    // 切换综合能耗/电能的时候, 下面的数据属性 tab 默认切换到第一个
                    if (k === ConsumptionType.GENERAL) {
                      setSelectedTabItem(ConsumptionAttribute.HEAT);
                    } else {
                      setSelectedTabItem(ConsumptionAttribute.ACTIVE_POWER);
                    }
                    setSelectedConsumption(k as ConsumptionType);
                  }}
                  className={
                    selectedConsumption === (k as ConsumptionType)
                      ? styles.titleButtonChecked
                      : styles.titleButtonUnChecked
                  }
                >
                  <Space size="small" align="center">
                    <img
                      width={24}
                      src={
                        selectedConsumption === (k as ConsumptionType)
                          ? ConsumptionTypeIconsDisplay[k as ConsumptionType].active
                          : ConsumptionTypeIconsDisplay[k as ConsumptionType].passive
                      }
                      alt=""
                    />
                    <span>{v}</span>
                  </Space>
                </Button>
              ))}
            </Space>
            <Tabs
              activeKey={selectedTabItem}
              // tabPosition="bottom"
              items={tabsItems}
              onChange={activeKey => {
                setSelectedTabItem(activeKey as ConsumptionAttribute);
              }}
            />
            <div className={styles.tabContent}>
              <div className={styles.filter}>
                <div className={styles.dateSwitch}>
                  <div className={styles.switchBtn}>
                    <Radio.Group
                      options={Object.entries(TimeGranularityDisplay).map(([k, v]) => ({
                        label: v,
                        value: k,
                      }))}
                      onChange={e => {
                        setTimeGranularity(e.target.value);
                      }}
                      value={timeGranularity}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </div>
                  {timeGranularity === TimeGranularity.FIFTEEN_MINUTES ? (
                    <div className={styles.datePickerArea}>
                      <DatePicker
                        allowClear={false}
                        onChange={date => {
                          setSelectedDate(date!);
                        }}
                        value={selectedDate}
                        disabledDate={current => {
                          // 只能选近三天
                          return current && !current.isBetween(dayjs().subtract(3, 'days'), dayjs());
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className={styles.cardContainer}>
                <div className={styles.cardItem}>
                  <CardForData
                    name={`${ConsumptionTypeDisplay[selectedConsumption]}（${ConsumptionAttributeUnitDisplay[selectedTabItem]}）`}
                    // unit={`${ConsumptionAttributeUnitDisplay[selectedTabItem]}${ConsumptionAttributeDisplay[selectedTabItem]}`}
                    // @ts-ignore
                    value={totalConsumption}
                  />
                </div>
              </div>
            </div>

            <Spin spinning={loading}>
              <div className={styles.chartContainer}>
                {chartData?.length ? (
                  <ReactEChartsCore
                    notMerge
                    // showLoading={loading}
                    lazyUpdate={false}
                    style={{ height: '100%' }}
                    option={options}
                    echarts={echarts}
                    theme={echartsTheme}
                  />
                ) : (
                  <Empty />
                )}
              </div>
            </Spin>
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
}
