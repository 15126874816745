import { Input, InputNumber, Modal, Radio, Select, Form } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { getUetEMTAllEnergyMediumList } from '@/api/uet';
import { CarbonFootprintSourceOptions, SystemBoundaryOptions } from '@/pages/MaterielManagement/untils';
import {
  apiV2EnergyAddPost,
  apiV2EnergyDetailPost,
  apiV2EnergyEditPost,
  apiV2OutputProductBaseLinePhysicalUnitPost,
} from '@maxtropy/device-customer-apis-v2';
import { StatusType } from '..';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedId?: number;
  updateData: () => void;
}

function formatNumber(v: any) {
  if (isNil(v) || v === '') return '';
  // 使用 toFixed 将数字格式化为字符串，保留原始小数位数
  let formattedNumber = parseFloat(v).toFixed(10);
  // 使用正则表达式去掉末尾多余的零
  formattedNumber = formattedNumber.replace(/\.?0+$/, '');
  return formattedNumber as any;
}

type SelectOptionItem = { label: string; value: number };

const AddEnergyModal: React.FC<Props> = ({ open, setOpen, selectedId, updateData }) => {
  const [form] = Form.useForm();
  const [mediumList, setMediumList] = useState<SelectOptionItem[]>([]);
  const [unitList, setUnitList] = useState<SelectOptionItem[]>([]);
  const energyMediumId = Form.useWatch('energyMediumId', form);

  useEffect(() => {
    if (isNil(selectedId)) return;
    apiV2EnergyDetailPost({ id: selectedId }).then(res => {
      form.setFieldsValue({
        ...res,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  // 获取所有工质列表, 包含电能
  useEffect(() => {
    getUetEMTAllEnergyMediumList().then(res => {
      setMediumList(
        res.map(m => ({
          label: m.energyName,
          value: m.id,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (isNil(energyMediumId)) {
      setMediumList([]);
      return;
    }
    apiV2OutputProductBaseLinePhysicalUnitPost({ id: energyMediumId }).then(res => {
      setUnitList(
        (res.list ?? []).map(m => ({
          label: m.physicalUnitName!,
          value: m.physicalUnitId!,
        }))
      );
      if (isNil(selectedId)) {
        form.setFieldValue('physicalUnitId', undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumId]);

  const onFinish = (v: any) => {
    if (isNil(selectedId)) {
      apiV2EnergyAddPost({ ...v }).then(res => {
        setOpen(false);
        updateData();
      });
    } else {
      apiV2EnergyEditPost({ id: selectedId, ...v }).then(res => {
        setOpen(false);
        updateData();
      });
    }
  };

  return (
    <Modal
      size="large"
      title={`${isNil(selectedId) ? '新建' : '编辑'}能源`}
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
    >
      <Form
        className={styles.modalForm}
        form={form}
        onFinish={onFinish}
        initialValues={{
          state: StatusType.ENABLE,
        }}
        layout="vertical"
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="能源工质类型"
              name="energyMediumId"
              rules={[
                {
                  required: true,
                  message: '请选择能源工质类型',
                },
              ]}
            >
              <Select placeholder="请选择" disabled={!!selectedId} options={mediumList} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="能源编码"
              name="code"
              rules={[
                {
                  required: true,
                  message: '请输入能源编码',
                },
                {
                  pattern: /^[A-Za-z0-9_]+$/,
                  message: '仅支持数字、字母、下划线',
                },
                {
                  max: 20,
                  message: '最多20个字符',
                },
              ]}
            >
              <Input disabled={!!selectedId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="能源名称"
              name="name"
              rules={[
                {
                  required: true,
                  message: '请输入能源名称',
                },
                {
                  max: 50,
                  message: '最多50个字符',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="能源单位"
              name="physicalUnitId"
              rules={[
                {
                  required: true,
                  message: '请选择能源单位',
                },
              ]}
            >
              <Select placeholder="请选择" options={unitList} disabled={!!selectedId} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="碳足迹"
              name="carbonFootPrint"
              rules={[
                {
                  required: true,
                  message: '请输入碳足迹',
                },
              ]}
            >
              <InputNumber min="0.0000000001" step="0.0000000001" formatter={formatNumber} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item noStyle dependencies={['physicalUnitId']}>
              {({ getFieldValue }) => {
                const physicalUnitId = getFieldValue('physicalUnitId');
                const physicalUnitName = unitList.find(m => m.value === physicalUnitId)?.label;
                return (
                  <Form.Item label="碳足迹单位">
                    <div className={styles.footUnit}>
                      <span>kgCO2e</span>
                      <span>/</span>
                      <Input disabled value={physicalUnitName} />
                    </div>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="碳足迹来源"
              name="footPrintOrigin"
              rules={[
                {
                  required: true,
                  message: '请选择碳足迹来源',
                },
              ]}
            >
              <Select placeholder="请选择" options={CarbonFootprintSourceOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="系统边界"
              name="boundary"
              rules={[
                {
                  required: true,
                  message: '请选择系统边界',
                },
              ]}
            >
              <Select placeholder="请选择" options={SystemBoundaryOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="状态" name="state">
              <Radio.Group>
                <Radio value={StatusType.ENABLE}>启用</Radio>
                <Radio value={StatusType.DISABLE}>禁用</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注" name="remark">
              <Input.TextArea maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEnergyModal;
