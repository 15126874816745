import { Form, Modal, Radio } from '@maxtropy/components';
import { EquipmentState, EquipmentStateDisplay } from '../util';
import { apiV2DeviceLedgerStatusPost } from '@maxtropy/device-customer-apis-v2';
import { useEffect } from 'react';
interface Iprops {
  visible: boolean;
  ids: number[];
  currentState?: number;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const StateChangeModal: React.FC<Iprops> = ({ visible, ids = [], currentState, onCancel, onConfirm }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ status: currentState });
  }, [currentState]);

  const onFinish = async (v: { status: number }) => {
    apiV2DeviceLedgerStatusPost({ ids, status: v.status }).then(res => {
      onConfirm?.();
    });
  };

  return (
    <Modal
      title="状态变更"
      open={visible}
      contentClassName="modal-form-content"
      destroyOnClose
      onCancel={() => {
        onCancel && onCancel();
        form.resetFields();
      }}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item label="状态" name="status" rules={[{ required: true, message: '请选择状态' }]}>
          <Radio.Group>
            <Radio value={EquipmentState.INUSE}>{EquipmentStateDisplay[EquipmentState.INUSE]}</Radio>
            <Radio value={EquipmentState.IDLE}>{EquipmentStateDisplay[EquipmentState.IDLE]}</Radio>
            <Radio value={EquipmentState.MAINTAINED}>{EquipmentStateDisplay[EquipmentState.MAINTAINED]}</Radio>
            <Radio value={EquipmentState.DAMAGEDED}>{EquipmentStateDisplay[EquipmentState.DAMAGEDED]}</Radio>
            <Radio value={EquipmentState.SCRAPPED}>{EquipmentStateDisplay[EquipmentState.SCRAPPED]}</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StateChangeModal;
