import { DatePicker } from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, Progress, ProgressProps, Row } from 'antd';
import classNames from 'classnames';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import BeatLineChart from '../BeatLineChart.tsx';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  ChartDataBeatStandardRes,
  ChartDataRes,
  WorkProcedureType,
  WorkStatus,
  chartDataMock,
  getBeatStandard,
} from '../../mockData';

const normalTwoColors: ProgressProps['strokeColor'] = {
  '0%': '#92ED79',
  '100%': '#59D744',
};

const abnormalTwoColors: ProgressProps['strokeColor'] = {
  '0%': '#FF8487',
  '100%': '#FF4D4F',
};

interface RightContentProps {
  workProcedureType: WorkProcedureType;
}

const RightContent: React.FC<RightContentProps> = props => {
  const { workProcedureType } = props;
  const [date, setDate] = useState(dayjs().subtract(1, 'day'));
  const ref = useRef<HTMLDivElement>();
  const [cutChartMockData, setCutChartMockData] = useState<ChartDataRes>();
  // 节拍次数
  const [beatNum, setBeatNum] = useState<number>(0);
  const [beatStandardMockData, setBeatStandardMockData] = useState<ChartDataBeatStandardRes>();
  const [normalBeatNum, setNormalBeatNum] = useState<number>(0);
  const [beatTime, setBeatTime] = useState<number>(0); // 节拍时长
  const [waitTime, setWaitTime] = useState<number>(0); // 待机时长
  const [stopTime, setStopTime] = useState<number>(0); // 停机时长
  const [workTime, setWorkTime] = useState<number>(0); // 工作时长

  useEffect(() => {
    if (date && workProcedureType) {
      const dateStr = dayjs(date).format('YYYY-MM-DD 00:00:00');
      const cutChart = chartDataMock(dateStr, workProcedureType);
      const beatStandard = getBeatStandard(dateStr, workProcedureType).data;
      setCutChartMockData(cutChart.data);
      setBeatNum(cutChart.data.workTimes);
      setBeatStandardMockData(beatStandard);
      const cutChartData = cutChart.data.data;
      const allBeatTime = cutChart.data.timeNode.map(i => {
        return cutChartData.filter(j => j.ts <= i.end && j.ts >= i.start);
      });
      const normalBeatTime = allBeatTime.filter(i => i.every(j => j.isNormal));
      setNormalBeatNum(normalBeatTime.length);
      let beat = 0;
      let wait = 0;
      let stop = 0;
      cutChart.data.workTimeArr.forEach(i => {
        if (i.status === WorkStatus.stop) {
          stop = stop + i.time;
        } else if (i.status === WorkStatus.wait) {
          wait = wait + i.time;
        } else {
          beat = beat + i.time;
        }
      });
      setBeatTime(beat);
      setWaitTime(wait);
      setStopTime(stop);
      setWorkTime(beat + wait + stop);
    }
  }, [date, workProcedureType]);

  const onDateChange = (date: any) => {
    setDate(date);
  };

  return (
    <div
      ref={e => {
        if (e) ref.current = e;
      }}
      className={styles.rightContent}
    >
      <DatePicker
        defaultValue={dayjs().subtract(1, 'day')}
        onChange={onDateChange}
        style={{ width: 240 }}
        disabledDate={date => {
          return dayjs().isBefore(date);
        }}
      />
      <div className={styles.progressContent}>
        <Row gutter={16}>
          <Col span={7}>
            <Row className={styles.progressWrapper}>
              <Col span={8}>
                <Progress
                  type="circle"
                  percent={beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100}
                  strokeWidth={11}
                  size={108}
                  strokeColor={
                    (beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100 > 80) ? normalTwoColors : abnormalTwoColors
                  }
                  format={() => (
                    <div className={styles.progressCircleStyle}>
                      <div className={styles.progressCircleInlineStyle}>
                        <p className={styles.firstLine}>{beatNum}</p>
                        <p className={styles.secondLine}>节拍总数</p>
                      </div>
                    </div>
                  )}
                />
              </Col>
              <Col span={16}>
                <div className={styles.wordDescription}>
                  <div className={styles.firstLine}>
                    <p>正常节拍</p>
                    <p
                      className={classNames(
                        styles.numStyle,
                        (beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100 > 80) ? undefined : styles.numStyleErr
                      )}
                    >
                      {normalBeatNum}
                    </p>
                    <p
                      className={classNames(
                        styles.numStyle,
                        (beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100 > 80) ? undefined : styles.numStyleErr
                      )}
                    >
                      {beatNum === 0 ? 0 : ((normalBeatNum / beatNum) * 100).toFixed(2) + '%'}
                    </p>
                  </div>
                  <div className={classNames(styles.firstLine, styles.secondLine)}>
                    <p>异常节拍</p>
                    <p
                      className={classNames(
                        styles.numStyle,
                        (beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100 > 80) ? undefined : styles.numStyleErr
                      )}
                    >
                      {beatNum - normalBeatNum}
                    </p>
                    <p
                      className={classNames(
                        styles.numStyle,
                        (beatNum === 0 ? 0 : (normalBeatNum / beatNum) * 100 > 80) ? undefined : styles.numStyleErr
                      )}
                    >
                      {beatNum === 0 ? 0 : (((beatNum - normalBeatNum) / beatNum) * 100).toFixed(2) + '%'}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={17}>
            <div className={styles.progressWrapper}>
              <div className={styles.timeAndInfo}>
                <p className={styles.timeStyle}>
                  <span className={styles.word}>今日工时</span>
                  <span className={styles.time}>{(workTime / 60 / 1000).toFixed(0)}</span>
                  <span className={styles.unit}>min</span>
                </p>
                {(workTime === 0 ? 0 : Number(((beatTime / workTime) * 100).toFixed(0))) > 80 ? (
                  <div className={styles.infoCard}>
                    <p>
                      <CheckCircleFilled /> 生产时长正常，请继续保持！
                    </p>
                  </div>
                ) : (
                  <div className={styles.infoCardWorn}>
                    <p>
                      <ExclamationCircleFilled /> 非生产时间占比超过
                      {workTime === 0 ? 0 : Number((((waitTime + stopTime) / workTime) * 100).toFixed(0))}%，请重点关注
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.progressLine}>
                <Progress
                  percent={workTime === 0 ? 0 : Number((((beatTime + waitTime) / workTime) * 100).toFixed(0))}
                  showInfo={false}
                  strokeColor="#FAAD14"
                  success={{ percent: workTime === 0 ? 0 : Number(((beatTime / workTime) * 100).toFixed(0)) }}
                  trailColor="#5D7092"
                />
                <div className={styles.legendLine}>
                  <div className={styles.legend}>
                    <div
                      className={styles.legendColor}
                      style={{ background: `linear-gradient( 90deg, #51A1FF 0%, #51DBFF 100%)` }}
                    />
                    <p className={styles.legendWord}>
                      节拍时长
                      <span className={styles.time}>{(beatTime / 60 / 1000).toFixed(0)}min</span>
                      <span className={styles.rate}>
                        {workTime === 0 ? 0 : Number(((beatTime / workTime) * 100).toFixed(0))}%
                      </span>
                    </p>
                  </div>
                  <div className={classNames(styles.legendBorder, styles.legend)}>
                    <div className={styles.legendColor} style={{ background: `#FAAD14` }} />
                    <p className={styles.legendWord}>
                      待机时长
                      <span className={styles.time}>{(waitTime / 60 / 1000).toFixed(0)}min</span>
                      <span className={styles.rate}>
                        {workTime === 0 ? 0 : Number(((waitTime / workTime) * 100).toFixed(0))}%
                      </span>
                    </p>
                  </div>
                  <div className={styles.legend}>
                    <div className={styles.legendColor} style={{ background: `#5D7092` }} />
                    <p className={styles.legendWord}>
                      停机时长
                      <span className={styles.time}>{(stopTime / 60 / 1000).toFixed(0)}min</span>
                      <span className={styles.rate}>
                        {workTime === 0 ? 0 : Number(((stopTime / workTime) * 100).toFixed(0))}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <BeatLineChart
        cutChartMockData={cutChartMockData}
        beatStandardMockData={beatStandardMockData}
        date={dayjs(date).format('YYYY-MM-DD 00:00:00')}
        workProcedureType={workProcedureType}
      />
    </div>
  );
};

export default RightContent;
