import React, { Key, useState } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Button } from '@maxtropy/components';
import { Dayjs } from 'dayjs';
import CalcModal from './CalcModal';
import { MediumIndicatorStatisticsReq } from '@/api/energyMedium';
import styles from './index.module.scss';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
interface FooterTipProps {
  dates?: RangeValue;
  selectedKeys?: Key[];
  currentTopoId: number | undefined;
  query?: MediumIndicatorStatisticsReq;
}

const FooterTip: React.FC<FooterTipProps> = ({ dates, selectedKeys, currentTopoId, query }) => {
  const [showModal, setShowCModal] = useState<boolean>(false);
  return (
    <>
      <div className={styles.footer}>
        <QuestionCircleFilled style={{ color: 'var(--mx-warning-color)', fontSize: 18 }} />
        <span className={styles.tip}>本页面显示用能数据异常？</span>

        <Button
          type="link"
          className={styles.link}
          onClick={() => {
            setShowCModal(true);
          }}
        >
          了解详情
        </Button>
      </div>
      <CalcModal
        query={query}
        show={showModal}
        initDates={dates}
        selectedKeys={selectedKeys}
        currentTopoId={currentTopoId}
        close={() => setShowCModal(false)}
      />
    </>
  );
};

export default FooterTip;
