import {
  Button,
  EllipsisSpan,
  MultiSelect,
  Paging,
  Select,
  Table,
  useAsync,
  useSearchParams,
  useUpdate,
  Form,
  Input,
  Tag,
  PopConfirm,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import CreateBase from './CreateBase';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { WorkStationState } from '@/api/workstationInfo';
import { PlusOutlined } from '@ant-design/icons';
import { getOuListOwned } from '@/api/ou';
import { useNavigate } from 'react-router';
import {
  V2ProductionBasePagePostRequest,
  V2ProductionBasePagePostResponse,
  apiV2ProductionBaseDeletePost,
  apiV2ProductionBaseOperateStatePost,
  apiV2ProductionBasePagePost,
} from '@maxtropy/device-customer-apis-v2';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '@/api/device';

interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}
interface nodeItem extends flattenNode {
  children: nodeItem[];
}
const columns = [
  {
    title: '生产基地编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '生产基地名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === WorkStationState.OPEN ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              禁用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouNames',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={(v ?? []).join('，')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

type SearchParamsProps = Omit<V2ProductionBasePagePostRequest, 'page' | 'size'>;
export type TableRow = Exclude<V2ProductionBasePagePostResponse['list'], undefined>[number];

export const statusList = [
  {
    label: '启用',
    value: WorkStationState.OPEN,
  },
  {
    label: '禁用',
    value: WorkStationState.CLOSE,
  },
];

const ProductionBase: React.FC = () => {
  const [form] = Form.useForm();
  const [centerModalVisible, setCenterModalVisible] = useState(false);
  const [opType, setOpType] = useState('add');
  const [currentRow, setCurrentRow] = useState<TableRow>();
  const [update, setUpdate] = useUpdate();
  const hasEditPers = useHasPermission(PermissionsType.B_EDIT_WORKCENTER_INFO);
  const hasViewPers = useHasPermission(PermissionsType.B_VIEW_WORKCENTER_INFO);
  const hasControlPers = useHasPermission(PermissionsType.B_CONTROL_WORKCENTER_INFO);
  const hasDropPers = useHasPermission(PermissionsType.B_DROP_WORKCENTER_INFO);
  const hasAddPers = useHasPermission(PermissionsType.B_ADD_WORKCENTER_INFO);
  const navigate = useNavigate();
  const pageInfo = useSearchParams<SearchParamsProps>(20, {
    state: [WorkStationState.OPEN],
  });
  const { searchParams, reset, finish, setTotalCount } = pageInfo;
  const [organization, setOrganition] = useState<any>();
  // const { data: organization } = useRequest(getOrganizationWithCodeWithCurrent);
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);
  const formatTreeData = (data: OrganizationResponse[]) => {
    return data.map(item => {
      const res: nodeItem = {
        key: item.data?.mcid ?? '',
        title: item.data?.name,
        children: formatTreeData(item?.children || []),
        disabled: !item.data.hasPermission,
      };
      return res;
    });
  };

  const flattenTreeData = (data: nodeItem[]) => {
    let treeList: nodeItem[] = [];
    let flatten = function (data: nodeItem[]) {
      for (let i = 0; i < data.length; i++) {
        treeList.push(data[i]);
        if (data[i].children.length) {
          flatten(data[i].children);
        }
      }
    };
    flatten(data);
    return treeList;
  };

  const mcidTreeOptions = useMemo(() => {
    console.log([organization]);
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { value: k.key, label: k.title };
        });
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const { data: pageList, loading } = useRequest(
    () => {
      return apiV2ProductionBasePagePost({
        word: searchParams.word,
        state: searchParams.state,
        ouIds: searchParams.ouIds,
        mcid: searchParams.mcid,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [searchParams, update],
    }
  );

  const ouList = useAsync(getOuListOwned, []);

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  // 启用/禁用
  const enableBtn = (status: WorkStationState, id?: number) => {
    if (!id) return;

    apiV2ProductionBaseOperateStatePost({
      id,
      state: status,
    }).then(setUpdate);
  };

  // 废弃
  const dropBtn = (id?: number) => {
    if (!id) return;

    apiV2ProductionBaseDeletePost({
      id,
    }).then(setUpdate);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      render: (_: any, record: TableRow) => {
        return (
          <Space size={16}>
            {hasViewPers && (
              <Button type="link" onClick={() => viewBtn(record)}>
                查看
              </Button>
            )}

            {hasEditPers && (
              <Button type="link" onClick={() => editBtn(record)}>
                编辑
              </Button>
            )}

            {record.state === WorkStationState.CLOSE && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.OPEN, record.id)}>
                启用
              </Button>
            )}
            {record.state === WorkStationState.OPEN && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.CLOSE, record.id)}>
                禁用
              </Button>
            )}
            {hasDropPers && (
              <PopConfirm
                title="废弃后不可再恢复，请确认。"
                onConfirm={() => {
                  dropBtn(record.id);
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button disabled={record.userFlag} type="link">
                  废弃
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: any) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      state: [WorkStationState.OPEN],
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: [WorkStationState.OPEN],
      }}
    >
      <Form.Item name="word" label="生产基地名称/编码">
        <Input placeholder="请输入名称/编码" />
      </Form.Item>
      <Form.Item name="state" label="生产基地状态">
        <MultiSelect options={statusList} />
      </Form.Item>
      <Form.Item name="mcid" label="所属组织">
        <Select options={mcidTreeOptions ?? []} placeholder="请选择" />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <MultiSelect placeholder="请选择" options={ouOptions} />
      </Form.Item>
    </CustomFilter>
  );

  const addBtn = () => {
    setCurrentRow(undefined);
    setOpType('add');
    setCenterModalVisible(true);
  };

  const editBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('edit');
    setCenterModalVisible(true);
  };

  const viewBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('view');
    setCenterModalVisible(true);
  };

  return (
    <>
      <div className={styles.filterWrapper}>{filters}</div>
      <div className={styles.spaceStyle} />
      <div className={styles.filterWrapper}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 10 }}>
          {hasAddPers && (
            <Button type="primary" onClick={addBtn} icon={<PlusOutlined />}>
              新建生产基地
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate('/productionManagement/production/energyUnitMapping');
            }}
          >
            能耗单元映射
          </Button>
        </div>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={pageList} scroll={{ x: 1300 }} />
        <Paging pagingInfo={pageInfo} />
      </div>

      {centerModalVisible && (
        <CreateBase
          type={opType}
          data={currentRow}
          confirm={() => {
            if (opType === 'add') {
              onReset();
            } else {
              setUpdate();
            }
            setCenterModalVisible(false);
          }}
          cancel={() => setCenterModalVisible(false)}
        />
      )}
    </>
  );
};
export default ProductionBase;
