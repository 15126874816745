import { CarbonAccountGuideListRes, addAccountUnitBody, getAccountGuideList } from '@/api/CarbonAccountUnits';
import { OuListResponse, getOuListOwned } from '@/api/ou';
import { Form, Input, Modal, Select, ShowInput } from '@maxtropy/components';
import { useEffect, useMemo, useState } from 'react';
import {
  apiV2CarbonAccountingUnitAddOrUpdatePost,
  apiV2CarbonAccountingUnitDetailPost,
} from '@maxtropy/device-customer-apis-v2';

interface AddUnitModalProps {
  id?: number;
  setId?: (id?: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  updateFn: () => void;
  entityId: number;
  entityName: string;
}
const monthOptions = [
  { label: '1月', value: 1 },
  { label: '2月', value: 2 },
  { label: '3月', value: 3 },
  { label: '4月', value: 4 },
  { label: '5月', value: 5 },
  { label: '6月', value: 6 },
  { label: '7月', value: 7 },
  { label: '8月', value: 8 },
  { label: '9月', value: 9 },
  { label: '10月', value: 10 },
  { label: '11月', value: 11 },
  { label: '12月', value: 12 },
];

const AddUnitModal: React.FC<AddUnitModalProps> = ({ open, setOpen, id, setId, updateFn, entityId, entityName }) => {
  const [form] = Form.useForm();
  const [ouList, setOuList] = useState<OuListResponse[]>([]);
  const [accountGuideList, setAccountGuideList] = useState<CarbonAccountGuideListRes[]>([]);

  useEffect(() => {
    getOuListOwned().then(setOuList); // 所属运营单元下拉选择
    getAccountGuideList().then(setAccountGuideList); // 核算指南下拉选择
  }, []);

  useEffect(() => {
    if (id) {
      apiV2CarbonAccountingUnitDetailPost({ id }).then(res => {
        form.setFieldsValue(res);
      });
    }
  }, [id, form]);

  // ou下拉列表
  const ouOptions = useMemo(() => {
    return (ouList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [ouList]);

  // 核算指南下拉列表
  const accountGuideOptions = useMemo(() => {
    return (accountGuideList ?? []).map(i => ({ label: i.standard, value: i.id }));
  }, [accountGuideList]);

  const onFinish = (v: addAccountUnitBody) => {
    const body = {
      ...v,
      subjectName: entityName,
      carbonAccountingEntityId: entityId,
    };
    if (id) {
      apiV2CarbonAccountingUnitAddOrUpdatePost({ ...body, id }).then(() => {
        setOpen(false);
        setId?.(undefined);
        updateFn();
      });
    } else {
      apiV2CarbonAccountingUnitAddOrUpdatePost({ ...body }).then(() => {
        setOpen(false);
        updateFn();
      });
    }
  };

  return (
    <Modal
      open={open}
      title={!!id ? '编辑核算单元' : '新建核算单元'}
      contentClassName="modal-form-content"
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setId?.(undefined);
        setOpen(false);
      }}
      size="normal"
      okText="保存"
    >
      <Form
        form={form}
        onFinish={(v: addAccountUnitBody) => {
          onFinish(v);
        }}
        initialValues={{
          startMonth: 1,
        }}
        labelAlign="left"
      >
        <Form.Item label="所属核算主体">
          <ShowInput value={entityName} />
        </Form.Item>
        <Form.Item
          name="unitName"
          label="核算单元名称"
          rules={[
            { required: true, message: '请输入核算单元名称' },
            { min: 2, message: '至少输入2个字符' },
            { max: 50, message: '最多输入50个字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="accountingGuideId" label="核算指南" rules={[{ required: true, message: '请选择核算指南' }]}>
          <Select disabled={!!id} options={accountGuideOptions} placeholder="请选择" />
        </Form.Item>
        <Form.Item name="startMonth" label="财年起始月" rules={[{ required: true, message: '请选择财年起始月' }]}>
          <Select disabled={!!id} style={{ width: '100%' }} options={monthOptions} placeholder="请选择" />
        </Form.Item>
        <Form.Item name="ouIds" label="包含运营单元" rules={[{ required: true, message: '请选择运营单元' }]}>
          <Select mode="multiple" placeholder="请选择" options={ouOptions} showSearch optionFilterProp="label" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUnitModal;
