import { Key, fetch } from '@maxtropy/components';

export interface EnergyMediumListResponseListItem {
  id: Key;
  name: string;
}

export interface EnergyMediumListResponse {
  list: EnergyMediumListResponseListItem[];
}

// 根据分析组配置获取能源工质列表
export function getEnergyMediumList(energyUnitGroupId: Key) {
  return fetch<EnergyMediumListResponse>(
    '/api/v2/energy-flow/analysis/energy-medium/list-by-unit-group',
    {
      method: 'POST',
      body: JSON.stringify({ energyUnitGroupId }),
    },
    true
  );
}

export interface EnergyUnitTreeResponseListItem {
  id: Key;
  name: string;
  energyUnitGroupId: Key; // 分析组id
  parentId: Key; // 上级id
  rootId: Key; // 根结点id
  level: number; // 层级
  children: EnergyUnitTreeResponseListItem[]; // 子级
}

export interface EnergyUnitTreeResponse {
  list: EnergyUnitTreeResponseListItem[];
}

// 获取用能单元树
export function getEnergyUnitTree(energyUnitGroupId: Key) {
  return fetch<EnergyUnitTreeResponse>(
    '/api/v2/energy-flow/analysis/energy-unit/tree',
    {
      method: 'POST',
      body: JSON.stringify({ energyUnitGroupId }),
    },
    true
  );
}

export interface EnergyMediumDataListItem {
  energyMediumId: Key; //  能源工质id
  energyMediumName: string; //  能源工质名称
  physicalUnitId: Key; //  单位id
  physicalUnitName: string; //  单位名称
  energyConsumption: number; //  能耗用量
  previousEnergyConsumption: number; // 上期用能量
  momRatio: number; // 环比
  proportion: number; // 占比
}

export interface EnergyMediumAggregateResponse {
  energyMediumData: EnergyMediumDataListItem[]; // 各能源工质数据
  energyConsumptionStandardCoal: number; //   综合能耗值-标准煤
  preEnergyConsumptionStandardCoal: number; //   综合能耗值-标准煤（上期）
  momRatio: number; //   环比
}

export interface EnergyMediumAggregateParams {
  energyUnitGroupId: Key; // 用能分析组id
  fromTime: string; // 开始时间
  toTime: string; // 结束时间
}

// 获取能流能源工质下聚合数据
export function getEnergyMediumAggregate(params: EnergyMediumAggregateParams) {
  return fetch<EnergyMediumAggregateResponse>(
    '/api/v2/energy-flow/analysis/energy-medium/aggr-value',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface DownstreamConsumptionsListItem {
  energyMediumId: Key; //能源工质id
  energyMediumName: string; //能源工质名称
  physicalUnitId: Key; //单位id
  physicalUnitName: string; //单位名称
  energyConsumption: number; //能耗用量
  previousEnergyConsumption: number; //上期用能量
  momRatio: number; //环比
  proportion: number; //占比
  energyUnitId: Key; //用能单元ID
  energyUnitName: string; //用能单元名称
}

export interface EnergyUnitAggregateResponse {
  downstreamConsumptions: DownstreamConsumptionsListItem[]; //  下游单元数据
  value: number; //  聚合数据
  previousValue: number; //  上期聚合数据
  momRatio: number; //  环比
  physicalUnitId: Key; // 单位id
  physicalUnitName: string; // 单位名称
  loss: number; //  损耗
}

export interface EnergyUnitAggregateParams {
  energyUnitId: Key; //  用能单元id
  fromTime: string; //  开始时间
  toTime: string; //  结束时间
  energyUnitGroupId: Key; //  分析组id
  energyMediumId?: Key; //  能源工质id
}

// 获取用能单元下游聚合数据
export function getEnergyUnitAggregate(params: EnergyUnitAggregateParams) {
  return fetch<EnergyUnitAggregateResponse>(
    '/api/v2/energy-flow/analysis/energy-unit/mom-aggr-value',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 获取用能单元下游综合能耗（标准煤）聚合数据

export interface StandardCoal_MediumListItem {
  energyMediumId: Key; //  能源工质id
  energyMediumName: string; //  能源工质名称
  physicalUnitId: Key; //  单位id
  physicalUnitName: string; //  单位名称
  energyConsumption: number; //  能耗用量
  previousEnergyConsumption: number; //  上期用能量
  momRatio: number; //  环比
  proportion: number; //  占比
  standardCoal: number; //  综合能耗
}

export interface EnergyUnitAggregateStandardCoalResponse extends EnergyUnitAggregateResponse {
  energyMediumData?: StandardCoal_MediumListItem[];
}

export function getEnergyUnitAggregateStandardCoal(params: EnergyUnitAggregateParams) {
  return fetch<EnergyUnitAggregateStandardCoalResponse>(
    '/api/v2/energy-flow/analysis/energy-unit/standard-coal/mom-aggr-value',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface EnergyFlowChartParams {
  energyUnitGroupId: Key; // 用能分析组id
  energyMediumId?: Key; // 能源工质id
  fromTime: string; //  开始时间
  toTime: string; //  结束时间
}

export interface EnergyFlowChartNodeItem {
  energyUnitId: Key; // 用能单元id
  energyUnitName: string; // 用能单元名称
  depth: number; // 层级
  key: string;
  name: string;
  value: number;
}

export interface EnergyFlowChartLinkItem {
  source: Key; // 来源id
  target: Key; // 目标id
  value: number; // 流向值
}

export interface EnergyFlowChartResponse {
  nodes: EnergyFlowChartNodeItem[];
  links: EnergyFlowChartLinkItem[];
}

// 获取能流图图表数据源
export function getEnergyFlowChart(params: EnergyFlowChartParams) {
  return fetch<EnergyFlowChartResponse>(
    '/api/v2/energy-flow/analysis/diagram-data',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 下游能流图
export interface DownstreamEnergyFlowChartParams {
  energyUnitGroupId: Key; //  用能分析组id
  fromTime: string; //  开始时间
  toTime: string; //  结束时间
  energyMediumId?: Key; //  能源工质id
  energyUnitId: Key; //  用能单元id
}

export function getDownstreamEnergyFlowChart(params: DownstreamEnergyFlowChartParams) {
  return fetch<EnergyFlowChartResponse>(
    '/api/v2/energy-flow/analysis/energy-unit/diagram-data',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

//校验当前staff是否拥有分析组权限
export function checkEnergyUnitGroupPermission(energyUnitGroupId: Key) {
  return fetch<{ hasPermission: boolean }>(
    '/api/v2/energy-flow/analysis/energy-unit-group/check-permission',
    {
      method: 'POST',
      body: JSON.stringify({ energyUnitGroupId }),
    },
    true
  );
}

// 用能分析组列表全量
export interface EnergyUnitGroupListResponseListItem {
  id: number;
  name: string;
}

export interface EnergyUnitGroupListResponse {
  list: EnergyUnitGroupListResponseListItem[];
}

export function getEnergyUnitGroupList() {
  return fetch<EnergyUnitGroupListResponse>(
    '/api/v2/energy-flow/analysis/energy-unit-group/list',
    {
      method: 'POST',
    },
    true
  );
}
