import { Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Key, Input, Tree } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash-es';
import {
  V2EnergyOptimizationProjectListPostResponse,
  apiV2EnergyOptimizationProjectListPost,
} from '@maxtropy/device-customer-apis-v2';

const { Search } = Input;

interface Props {
  onTreeSelect?: (selectedKeys: Key[]) => void;
}

export type EnergyAnalysisOptimizePageRes = Exclude<
  V2EnergyOptimizationProjectListPostResponse['list'],
  undefined
>[number];

const ConfigList: React.FC<Props> = ({ onTreeSelect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnergyAnalysisOptimizePageRes[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_energyOptimizationProjectId = urlSearchParams.get('energyOptimizationProjectId') || undefined;

  useEffect(() => {
    if (url_energyOptimizationProjectId) {
      setSelectedKeys([Number(url_energyOptimizationProjectId)]);
      onTreeSelect?.([Number(url_energyOptimizationProjectId)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_energyOptimizationProjectId]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const treeData = useMemo(() => {
    return (data ?? []).map(item => {
      const rateColor = isNil(item.energySavingsRate)
        ? styles.nullWhite
        : item.energySavingsRate === 0
        ? styles.defaultWhite
        : item.energySavingsRate > 0
        ? styles.pulsGreen
        : styles.minusRed;
      return {
        title: (
          <div className={styles.treeNodeTitle}>
            <div className={styles.treeNodeName}>{item.name}</div>
            <div className={classNames(styles.treeNodeRate, rateColor)}>
              {isNil(item.energySavingsRate) ? '--' : (item.energySavingsRate * 100).toFixed(2)}%
            </div>
          </div>
        ),
        key: item.id as Key,
      };
    });
  }, [data]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    getList(value);
  };

  const getList = (v?: string) => {
    setLoading(true);
    apiV2EnergyOptimizationProjectListPost({ name: v === '' ? undefined : v })
      .then(res => {
        if (res) {
          setData(res.list ?? []);
          if (res.list && res.list.length > 0 && !url_energyOptimizationProjectId) {
            onTreeSelect?.([res.list[0].id as number]);
            setSelectedKeys([res.list[0].id as number]);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.leftSider}>
      <div className={styles.searchArea}>
        <Search placeholder="请输入优化项目" allowClear onChange={debounce(onChange, 500)} />
      </div>
      <div style={{ margin: '0 10px', height: 1, background: 'rgba(255,255,255,0.15)' }} />
      <div className={styles.treeArea}>
        <Spin
          style={{
            height: '100%',
          }}
          spinning={loading}
        >
          {treeData && treeData.length > 0 ? (
            <>
              <div className={styles.tree_box}>
                <Tree
                  className={styles.treeStyle}
                  showIcon
                  blockNode
                  treeData={treeData}
                  selectedKeys={selectedKeys}
                  onSelect={(selectedKeys, info) => {
                    url_energyOptimizationProjectId = undefined;
                    setSelectedKeys(selectedKeys);
                    onTreeSelect?.(selectedKeys);
                  }}
                />
              </div>
            </>
          ) : (
            <Empty style={{ paddingTop: 100 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ConfigList;
