import { circuitBatchImport } from '@/api/circuit';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Modal, message, Form } from '@maxtropy/components';
import { Col, Row, Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { extname } from '@/shared/utils/utils';
import { FC, useState } from 'react';

interface BatchUploadCreateCircuitProps {
  batchCreateCircuitVisible?: boolean;
  setBatchCreateCircuitVisible?: (visible: boolean) => void;
  refresh?: () => void;
}

const fileSize = 20 * 1024 * 1024;

const BatchUploadCreateCircuit: FC<BatchUploadCreateCircuitProps> = props => {
  const { batchCreateCircuitVisible, setBatchCreateCircuitVisible, refresh } = props;
  const [uploadForm] = Form.useForm();
  const [uploadState, setUploadState] = useState<boolean>(false);

  const beforeUploadFile = (file: RcFile) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileSize = file.size <= fileSize;
    const limitFileType = extName === '.xlsx' || extName === '.xls';
    if (!limitFileSize) {
      Modal.warning({
        content: `上传文件的大小不得超过20M`,
      });
      return false;
    }
    if (!limitFileType) {
      Modal.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };

  const onModalOk = async () => {
    const values = await uploadForm.validateFields();
    // 截取名字
    // const name = values.fileKey.file.name.slice(
    //   0,
    //   values.fileKey.file.name.split('').findLastIndex((v: string) => v === '.')
    // );
    const body = {
      ...values,
      fileKey: values.fileKey.file.response.key,
    };
    circuitBatchImport(body)
      .then(() => {
        setUploadState(false);
        setBatchCreateCircuitVisible?.(false);
        uploadForm.resetFields();
        refresh?.();
      })
      .catch(err => {
        message.error(err);
      });
  };

  return (
    <>
      <Modal
        width={600}
        open={batchCreateCircuitVisible}
        title="批量新建回路"
        onCancel={() => {
          setBatchCreateCircuitVisible?.(false);
          uploadForm.resetFields();
        }}
        onOk={onModalOk}
        okText="确认上传"
        contentClassName="modal-form-content"
      >
        <Form form={uploadForm}>
          <Row>
            <Button
              type="link"
              style={{ paddingLeft: 0, marginBottom: 20 }}
              onClick={() => window.open(`/api/circuit/download`, '__blank')}
            >
              下载模板
            </Button>
          </Row>
          <Spin spinning={uploadState}>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="fileKey"
                  label="上传文件"
                  rules={[{ required: true, message: '请选择文件' }]}
                  extra={'限制格式为xls或xlsx，不超过20MB。'}
                >
                  <Upload
                    action="/api/file-center/upload"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    maxCount={1}
                    beforeUpload={beforeUploadFile}
                  >
                    <Button icon={<UploadOutlined />}>点击上传</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
    </>
  );
};

export default BatchUploadCreateCircuit;
