import { DataAORIType, DataQuery, StatisticsPartition } from '@/api/energyMediaComparison';
import {
  EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse,
  MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse,
} from '@maxtropy/device-customer-apis';
import {
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { DataTypeAOrIArr } from '../../utils';

export interface ChartOptionProps {
  query: DataQuery;
  chartData: EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse;
}

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
interface LegendProps {
  show: boolean;
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'];
  isAcc: boolean;
  // timeRangeList: EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest['timeRangeList'];
}

// 设置历史对比基本配置项
export const getBasicChartOptionByHis = () => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    xAxis: getNormalAxisByHis(),
  };
};

export const getNormalAxisByHis = () => {
  return [
    {
      ...xAxis,
      type: 'category',
      // min: 1, // 起始
    },
  ];
};
// 获取图例
export const getLegendByHis = ({ show, chartData, isAcc }: LegendProps) => {
  return {
    show,
    right: isAcc ? 110 : 20,
    top: 20,
    textStyle: {
      color: '#AFBCC4',
    },
    data: chartData?.map((item, index) => {
      return {
        // name: `${dayjs(timeRangeList?.[index].from, 'x').format('MM-DD')}~${dayjs(
        //   timeRangeList?.[index].to,
        //   'x'
        // ).format('MM-DD')}`,
        name: `时间${index + 1}`,
        icon: 'rect',
      };
    }),
  };
};

// Tooltip基础配置
export const basicTooltip = {
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
};

export const basicYAxis = (originUnit: string) => {
  return {
    type: 'value',
    name: originUnit,
    nameTextStyle: {
      fontSize: 14,
      align: 'right',
    },
    nameGap: 30,
    splitLine: {
      lineStyle: {
        color: '#FFFFFF30',
      },
    },
  };
};

export const colors = ['#0DB6D9', '#63BC7F', '#CE90D1'];

const isSameIndicatorByHis = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  unit: string,
  indicatorType: DataAORIType,
  indicatorName?: string,
  timeResolution?: string
) => {
  const chartDataLength = chartData?.length;
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string = (chartData?.[0]?.entryExitName ?? '--') + (indicatorName ?? '--');
        if (indicatorType === DataAORIType.INSTANTANEOUS && timeResolution === StatisticsPartition.DAY) {
          items.forEach((item: any, index: number) => {
            // 箱型图
            const { data, marker } = item;
            const valueMax = !isNil(data[1]) ? `${data[1]}${unit}` : '--';
            const valueAvg = !isNil(data[2]) ? `${data[2]}${unit}` : '--';
            const valueMin = !isNil(data[3]) ? `${data[3]}${unit}` : '--';
            const dateFormat = !isNil(data[data.length - 1]) ? `${dayjs(data[data.length - 1]).format('MM-DD')}` : '--';
            str += `<br> ${marker}${dateFormat}`;
            str += `<br> 最大: ${valueMax}`;
            str += `<br> 均值: ${valueAvg}`;
            str += `<br> 最小: ${valueMin}`;
          });
        } else {
          items.forEach((item: any, index: number) => {
            const { data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
            const dateFormat = !isNil(data[data.length - 1])
              ? `${dayjs(data[data.length - 1]).format(
                  timeResolution === StatisticsPartition.MINUTE_15 ? 'MM-DD[\n]HH:mm' : 'MM-DD'
                )}`
              : '--';
            str += `<br> ${marker}${dateFormat}: ${value}`;
          });
        }
        return str;
      },
    },
    yAxis: basicYAxis(unit),

    toolbox:
      indicatorType === DataAORIType.ACCUMULATE
        ? {
            width: 200,
            height: 100,
            top: 16,
            right: 40,
            itemSize: 20, // 设置图标大小
            feature: {
              brush: {
                type: ['lineX', 'clear'], // 选择工具类型
                title: {
                  lineX: '横向选择',
                  clear: '清除',
                },
              },
            },
          }
        : undefined,
    brush:
      indicatorType === DataAORIType.ACCUMULATE
        ? {
            toolbox: ['lineX', 'clear'],
            xAxisIndex: 0,
          }
        : undefined,

    series: chartData?.map((item, index) => {
      if (indicatorType === DataAORIType.ACCUMULATE) {
        return {
          type: 'bar',
          color: colors[index],
          unit,
          name: `时间${index + 1}`,
          data: item.data?.map((a, index) => {
            return [index + 1, a.sum, a.ts];
          }),
        };
      } else {
        if (timeResolution === StatisticsPartition.MINUTE_15) {
          return {
            type: 'line',
            smooth: true,
            lineStyle: { normal: { width: 2 } },
            color: colors[index],
            name: `时间${index + 1}`,
            data: item.data?.map((a, index) => {
              return [index + 1, a.avg, a.ts];
            }),
          };
        } else {
          return {
            name: `时间${index + 1}`,
            type: 'custom',
            color: colors[index],
            dimensions: ['-', `最大${indicatorName}`, `平均${indicatorName}`, `最小${indicatorName}`],
            itemStyle: {
              borderWidth: 1.5,
            },
            renderItem: function (params: any, api: any) {
              var xValue = api?.value(0);
              var highPoint = api?.coord([xValue, api.value(1)]);
              var avgPoint = api?.coord([xValue, api.value(2)]);
              var lowPoint = api?.coord([xValue, api.value(3)]);
              // var halfWidth = api?.size([0, 1])[1] / 2;
              var offsetWidth =
                chartDataLength === 1 ? index : chartDataLength === 2 ? (2 * index - 1) * 8 : 16 * index - 16;
              console.log(offsetWidth);
              var halfWidth = 8;
              var style = api?.style({
                stroke: api?.visual('color'),
                fill: undefined,
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - halfWidth + offsetWidth,
                      y1: highPoint[1],
                      x2: highPoint[0] + halfWidth + offsetWidth,
                      y2: highPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] + offsetWidth,
                      y1: highPoint[1],
                      x2: lowPoint[0] + offsetWidth,
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: avgPoint[0] - halfWidth + offsetWidth,
                      y1: avgPoint[1],
                      x2: avgPoint[0] + halfWidth + offsetWidth,
                      y2: avgPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - halfWidth + offsetWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + halfWidth + offsetWidth,
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                ],
              };
            },
            encode: {
              x: 0,
              y: [1, 2, 3],
              tooltip: [1, 2, 3],
            },
            data: item.data?.map((a, index) => {
              return [index + 1, a.max?.toFixed(2), a.avg?.toFixed(2), a.min?.toFixed(2), a.ts];
            }),
          };
        }
      }
    }),
  };
};

export const getChartOptionByHistory = (
  chartData?: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  mediumIndicatorDisplayUnitData?: MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse,
  hisSearchParams?: V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest
) => {
  if (chartData && mediumIndicatorDisplayUnitData) {
    // 通过指标id查找单位
    const unit = mediumIndicatorDisplayUnitData.find(
      i => i.energyMediumIndicatorId === chartData[0].indicatorId
    )?.displayPhysicalUnitGeneralName;
    const indicatorType = DataTypeAOrIArr.find(i => i.indicatorId === chartData[0].indicatorId)?.indicatorType;
    const indicatorName = DataTypeAOrIArr.find(i => i.indicatorId === chartData[0].indicatorId)?.name;
    if (unit && indicatorType) {
      // 获取基本配置项
      const option = getBasicChartOptionByHis();
      return {
        ...option,
        legend: getLegendByHis({
          show: true,
          chartData,
          isAcc: indicatorType === DataAORIType.ACCUMULATE,
        }),
        ...isSameIndicatorByHis(chartData, unit, indicatorType, indicatorName, hisSearchParams?.timeResolution),
      };
    }
  }
};
