import classNames from 'classnames';
import styles from './index.module.scss';
import { TabsProps } from 'antd';
import { FC, useMemo } from 'react';
import { ProcessEntryOrExitMonitoringIndicatorsRes } from '@/api/energyMedium';
import { Tabs } from '@maxtropy/components';

export interface MonitoringIndicatorsTabProps extends Partial<TabsProps> {
  onTabChange?: (tabTile: string) => void;
  tabData?: ProcessEntryOrExitMonitoringIndicatorsRes[];
}

const MonitoringIndicatorsTab: FC<MonitoringIndicatorsTabProps> = props => {
  const { onTabChange, tabData = [], activeKey, className } = props;

  const items: TabsProps['items'] = useMemo(() => {
    return tabData?.map(item => ({
      key: item.indicatorId!.toString(),
      label: (
        <div className={styles.tab}>
          <div
            style={{
              color: activeKey === String(item.indicatorId) ? 'var(--mx-primary-color)' : 'rgba(255, 255, 255, 0.65)',
            }}
          >
            {item.indicatorName}
          </div>
        </div>
      ),
    }));
  }, [tabData, activeKey]);

  return (
    <div className={classNames(styles.tabs, className)}>
      <Tabs onChange={onTabChange} activeKey={activeKey} items={items}></Tabs>
    </div>
  );
};

export default MonitoringIndicatorsTab;
