import React, { useEffect, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import ResizeObserver from 'rc-resize-observer';
import dayjs from 'dayjs';
import {
  apiV2ProductionConsumptionConsumptionAmountPost,
  V2ProductionConsumptionConsumptionAmountPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { TimeGranularity, TimeGranularityFormatDisplay } from '../../ProductionUnitConsumption';
import { RangeValue } from '../DateSwitch';
import { Empty } from '@maxtropy/components';
import { isEmpty } from 'lodash-es';
import { Spin } from 'antd';
import styles from '../../index.module.scss';

type AnalysisChartProps = {
  showLabel: boolean;
  timeGranularity: TimeGranularity;
  generalQuery?: any;
  selectedGeneralName?: string;
  unitName?: string;
  energyMediumId?: number;
  value?: RangeValue;
  outputProductId?: number;
  physicalUnitId?: number;
  selectedOutputName?: string;
};

const AnalysisChart = ({
  showLabel,
  timeGranularity,
  generalQuery,
  selectedGeneralName,
  unitName,
  outputProductId,
  energyMediumId,
  value,
  selectedOutputName,
  physicalUnitId,
}: AnalysisChartProps) => {
  const chartRef = useRef<ReactEcharts>();
  const [consumptionData, setConsumptionData] = useState<V2ProductionConsumptionConsumptionAmountPostResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!energyMediumId || !outputProductId || !physicalUnitId) {
      setConsumptionData({});
      return;
    }
    setLoading(true);
    apiV2ProductionConsumptionConsumptionAmountPost({
      ...generalQuery,
      energyMediumId,
      physicalUnitId,
      outputProductId,
      timeResolution: timeGranularity,
      startDateTime: value?.[0]?.valueOf(),
      endDateTime: value?.[1]?.valueOf(),
    })
      .then(res => {
        setConsumptionData(res ?? {});
      })
      .finally(() => setLoading(false));
  }, [generalQuery, outputProductId, energyMediumId, physicalUnitId, timeGranularity, value]);

  return (
    <div style={{ flex: 1, marginTop: 16 }}>
      <ResizeObserver
        onResize={({ width, height }) => {
          chartRef.current?.getEchartsInstance().resize({ width });
        }}
      >
        <Spin spinning={loading} wrapperClassName={styles.analysisChartLoading}>
          {isEmpty(consumptionData) ? (
            <Empty style={{ paddingTop: 150 }} />
          ) : (
            <ReactEcharts
              ref={e => {
                if (e) chartRef.current = e;
              }}
              style={{
                minHeight: 375,
                height: '100%',
              }}
              option={getChartOption(
                showLabel,
                consumptionData,
                timeGranularity,
                unitName,
                selectedGeneralName,
                selectedOutputName
              )}
              notMerge
              lazyUpdate={false}
            />
          )}
        </Spin>
      </ResizeObserver>
    </div>
  );
};

const getChartOption = (
  showLabel: boolean,
  consumptionData?: V2ProductionConsumptionConsumptionAmountPostResponse,
  timeGranularity?: TimeGranularity,
  unitName?: string,
  selectedGeneralName?: string,
  selectedOutputName?: string
) => {
  const maxConsumption = Math.ceil(Math.max(...(consumptionData?.consumptionList?.map(i => i.value || 0) || [0])));
  const maxQualifiedProductCount = Math.ceil(
    Math.max(...(consumptionData?.qualifiedProductCountList?.map(i => i.value || 0) || [0]))
  );
  return {
    title: {
      text: selectedOutputName ? `${selectedOutputName}单耗产量分析` : '',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontWeight: 'normal',
        fontSize: 14,
      },
    },
    grid: {
      left: 20,
      right: 20,
      containLabel: true,
    },
    legend: [
      {
        right: 90,
        top: 6,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 12,
        itemHeight: 12,
        itemGap: 16,
        padding: [0, 8, 0, 0],
        data: [{ name: '单耗' }],
        selectedMode: false,
      },
      {
        top: 6,
        right: 0,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 16,
        itemHeight: 4,
        itemGap: 16,
        padding: [0, 8, 0, 0],
        data: [{ name: '合格品数' }],
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        left: 10,
        right: 20,
        height: 20,
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (tooltipData: any) => {
        return `
          <div style='color: rgba(255,255,255,0.65)'>${tooltipData[0].axisValueLabel}</div>
          <div  style='width: 200px;display: flex;align-items: center;margin-bottom: 4px;'><span>${
            tooltipData[0].seriesName
          }</span><span style='flex:1;text-align: right'>${
          tooltipData[0].value ?? '--'
        }${selectedGeneralName}/${unitName}</span></div>
          <div  style='display: flex;align-items: center;margin-bottom: 4px;'><span>${
            tooltipData[1].seriesName
          }</span><span style='flex:1;text-align: right'>${tooltipData[1].value ?? '--'}${unitName}</span></div>
          <div  style='display: flex;align-items: center;margin-bottom: 4px;'><span>${
            tooltipData[2].seriesName
          }</span><span style='flex:1;text-align: right'>${tooltipData[2].value ?? '--'}${unitName}</span></div>
          <div  style='display: flex;align-items: center;margin-bottom: 4px;'><span>${
            tooltipData[3].seriesName
          }</span><span style='flex:1;text-align: right'>${tooltipData[3].value ?? '--'}%</span></div>
              `;
      },
    },
    xAxis: [
      {
        type: 'category',
        data: consumptionData?.consumptionList?.map(i => {
          return dayjs(i.ts).format(TimeGranularityFormatDisplay[timeGranularity!]);
        }),
        axisTick: {
          show: false,
        },
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 14,
        },
        axisPointer: {
          type: 'shadow',
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: selectedGeneralName,
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        min: 0,
        max: maxConsumption,
        interval: Number((maxConsumption / 5).toFixed(2)),
      },
      {
        type: 'value',
        name: unitName,
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        min: 0,
        max: maxQualifiedProductCount,
        interval: Number((maxQualifiedProductCount / 5).toFixed(2)),
      },
    ],
    series: [
      {
        name: '单耗',
        type: 'bar',
        data: consumptionData?.consumptionList?.map(i => i.value),
        itemStyle: {
          color: '#2D8DFF',
        },
        label: {
          show: showLabel,
          position: 'top',
          color: 'rgba(255,255,255,0.85)',
          textBorderColor: 'transparent',
        },
      },
      {
        type: 'line',
        name: '合格品数',
        smooth: true,
        symbol: 'circle',
        yAxisIndex: 1,
        symbolSize: 10,
        data: consumptionData?.qualifiedProductCountList?.map(i => i.value),
        itemStyle: {
          color: '#57FB8B',
        },
      },
      {
        type: 'line',
        name: '不合格品数',
        smooth: true,
        showSymbol: false,
        data: consumptionData?.unqualifiedProductCountList?.map(i => i.value),
        lineStyle: {
          opacity: 0,
        },
        itemStyle: {
          opacity: 0,
        },
      },
      {
        type: 'line',
        name: '良品率',
        showSymbol: false,
        data: consumptionData?.qualifiedRateList?.map(i => i.value),
        itemStyle: {
          opacity: 0,
        },
        lineStyle: {
          opacity: 0,
        },
      },
    ],
  };
};

export default AnalysisChart;
