import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Button, Input, MxColumnsType, Tabs } from '@maxtropy/components';
import { DataPointType, StaticPoint, VirtualPoint } from '../../../types';
import { EdgeDeviceTemplatePoint } from '../index';
import CollectionPoint from './Point';
import VirtualSpot from './Virtual';
import StaticSpot from './Static';
import styles from './index.module.scss';
import useIsGateway from '../../../hooks/useIsGateway';
import { PointContext } from '../contextTypes';
import gatewayIcon from './imgs/gateway@2x.png';
import { Space } from 'antd';
import { apiV2GatewayBaseInfoPost } from '@maxtropy/device-customer-apis-v2';

const { TabPane } = Tabs;

interface PointTabsProps {
  loading?: boolean;
  onUpdate: (values: any) => void;
  onSearch: (values: string) => void;
  editColumns: MxColumnsType<EdgeDeviceTemplatePoint>;
}
const BindGateWay = ({ value }: { value?: ReactNode }) => {
  let [gateWayInfo, setGateWayInfo] = useState<any>();
  useEffect(() => {
    if (value) {
      apiV2GatewayBaseInfoPost({ id: value as number }).then(setGateWayInfo);
    }
  }, [value]);
  return (
    <>
      <Space>
        <div className={styles.img_box}>
          <img style={{ width: 16, marginBottom: 4 }} src={gatewayIcon} alt="" />
        </div>
        <div style={{ color: 'rgba(255,255,255,0.65)', paddingRight: 8 }}>已绑定网关:</div>

        <Space>
          <span style={{ color: 'rgba(255,255,255,0.85)' }}>{gateWayInfo?.name ?? '--'}</span>
          {value && (
            <Button
              style={{ paddingLeft: 0 }}
              type="link"
              onClick={() => window.open(`/device/config/gateway/detail/${value}`, '_blank')}
            >
              查看
            </Button>
          )}
        </Space>
      </Space>
    </>
  );
};
const PointTabs: React.FC<PointTabsProps> = props => {
  const { loading, onUpdate, editColumns, onSearch } = props;

  const [activeKey, setActiveKey] = useState<DataPointType>();
  const { info, edgeGatewayId, isBatch } = useContext(PointContext);
  const { loading: isGatewayLoading, isGateway } = useIsGateway(info?.deviceTypeId);

  useEffect(() => {
    if (isGatewayLoading) return;
    if (isGateway) {
      setActiveKey(DataPointType.STATIC_POINT);
    } else {
      setActiveKey(DataPointType.BASE_POINT);
    }
  }, [isGatewayLoading, isGateway]);

  if (isGatewayLoading) return null;

  return (
    <div className={styles.container}>
      {!isBatch && (
        <div className={styles.gateway_box}>
          <BindGateWay value={edgeGatewayId} />
        </div>
      )}

      <Tabs
        activeKey={`${activeKey}`}
        onChange={(key: string) => {
          setActiveKey(Number(key));
        }}
        destroyInactiveTabPane={true}
        tabBarExtraContent={
          <Input.Search
            style={{ width: 260 }}
            placeholder="请输入数据属性名称/标识符"
            allowClear
            onSearch={(value: any, event: any) => {
              event?.preventDefault();
              onSearch(value);
            }}
          />
        }
      >
        {!isGateway && (
          <TabPane tab="采集点" key={DataPointType.BASE_POINT} forceRender>
            <CollectionPoint loading={loading} editColumns={editColumns} onUpdate={onUpdate} />
          </TabPane>
        )}
        {!isGateway && (
          <TabPane tab="虚拟点" key={DataPointType.VIRTUAL_POINT} forceRender>
            <VirtualSpot
              loading={loading}
              onUpdate={onUpdate}
              editColumns={editColumns as MxColumnsType<VirtualPoint>}
            />
          </TabPane>
        )}
        <TabPane tab="静态点" key={DataPointType.STATIC_POINT} forceRender>
          <StaticSpot loading={loading} onUpdate={onUpdate} editColumns={editColumns as MxColumnsType<StaticPoint>} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PointTabs;
