import { EnergyUnitTreeItem, getEnergyUnitTree } from '@/api/energyUnitMapping';
import { OuListResponse, getOuListOwned } from '@/api/ou';
import { QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  InputNumber,
  Form,
  TreeSelect,
  Input,
  Select,
  Radio,
  Table,
  Checkbox,
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  Modal,
  SubContent,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import { Row, Col, Space, TreeSelectProps } from 'antd';
import { isNil } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlarmLevelDisplay } from '@/shared/types';
import {
  V2EnergyAnalysisListByOu2PostResponse,
  apiV2EnergyAnalysisListByOu2Post,
  apiV2EnergyUnitServerAlarmAddRulePost,
  apiV2EnergyUnitServerAlarmGetRulePost,
  apiV2EnergyUnitServerAlarmUpdateRulePost,
  apiV2EnergyUnitServerAlarmGetMeterTypesPost,
} from '@maxtropy/device-customer-apis-v2';

import styles from './index.module.scss';

interface EnergyCell {
  disabled: string;
  halfChecked: string;
  label: string;
  value: number;
}

const formatToNodeData = (data: EnergyUnitTreeItem[]): TreeSelectProps['treeData'] => {
  return data.map(i => {
    return {
      title: i.name,
      value: i.energyUnitId,
      disabled: i.disabled,
      children: i.children && i.children.length > 0 ? formatToNodeData(i.children) : [],
    };
  });
};

const alarmLevelOptions = () => {
  return Object.entries(AlarmLevelDisplay).map(([key, value]) => ({
    label: value,
    value: Number(key),
  }));
};

interface MaterTypeItem {
  energyMediumId?: number;
  energyMediumName?: string;
  physicalUnitId?: number;
  physicalUnitName?: string;
}

interface CreateEnergyAlarmRuleProps {
  isEdit?: boolean;
}

enum AlarmLogicItemEnum {
  ENERGY_UPPER_LIMIT = 7,
}

export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];

const CreateEnergyAlarmRule: React.FC<CreateEnergyAlarmRuleProps> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const [ouList, setOuList] = useState<OuListResponse[]>([]);
  const [unitGroup, setUnitGroup] = useState<GroupDetails[]>([]);
  const [measureList, setMeasureList] = useState<MaterTypeItem[]>([]);
  const [energyDisabled, setEnergyDisabled] = useState<boolean>(true);
  const [treeNodeData, setTreeNodeData] = useState<TreeSelectProps['treeData']>([]);
  const [energyCells, setEnergyCells] = useState<number[]>();
  const meterTypeIds: number[] = Form.useWatch('meterTypeIds', form);
  const openEarlyWarning: boolean = Form.useWatch('openEarlyWarning', form);
  const energyUnitGroupId: number = Form.useWatch('energyUnitGroupId', form);

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [
    {
      name: isEdit ? '编辑单元报警规则' : '新建单元报警规则',
    },
  ];

  const daysOfMonthForEarlyWarningOptions = useMemo(() => {
    const array = [];
    for (let i = 8; i < 31; i++) {
      array.push({ label: i, value: i });
    }
    return array;
  }, []);

  // 获取运营单元列表和用能分析组列表
  useEffect(() => {
    getOuListOwned().then(res => {
      setOuList(res ?? []);
    });
    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setUnitGroup(res.list ?? []);
    });
  }, []);

  const ouListOptions = useMemo(() => {
    return (ouList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [ouList]);

  // 编辑时获取详情数据
  useEffect(() => {
    if (!isEdit || isNil(id)) return;
    apiV2EnergyUnitServerAlarmGetRulePost({ ruleId: id }).then(res => {
      const prevTableList = (res?.meterTypes ?? []).map(item => {
        const meterTypeUnit = res.hourUpperLimitValue?.find(
          m => m.energyMediumId === item.meterTypeId
        )?.physicalUnitName;
        return {
          name: item.meterTypeName + `（${meterTypeUnit ?? '--'}）`,
          key: item.meterTypeId,
          hour: res.hourUpperLimitValue?.find(m => m.energyMediumId === item.meterTypeId)?.upperLimitValue,
          date: res.dayUpperLimitValue?.find(m => m.energyMediumId === item.meterTypeId)?.upperLimitValue,
          week: res.weekUpperLimitValue?.find(m => m.energyMediumId === item.meterTypeId)?.upperLimitValue,
          month: res.monthUpperLimitValue?.find(m => m.energyMediumId === item.meterTypeId)?.upperLimitValue,
          tenDay: res.tenDayUpperLimitValue?.find(m => m.energyMediumId === item.meterTypeId)?.upperLimitValue,
        };
      });

      form.setFieldsValue({
        ruleName: res.ruleName,
        alarmLevel: res.alarmLevel,
        ouId: res.ouId,
        energyUnitGroupId: res.energyUnitGroupId,
        openEarlyWarning: res.openEarlyWarning,
        daysOfMonthForEarlyWarning: res.daysOfMonthForEarlyWarning,
        energyUnitIds: (res.energyUnits ?? []).map(m => m.energyUnitId),
        meterTypeIds: res.meterTypes?.map(m => m.meterTypeId!),
        upperLimit: [...prevTableList],
      });

      setEnergyCells((res.energyUnits ?? []).map(m => Number(m.energyUnitId)));
    });
  }, [isEdit, id, form]);

  useEffect(() => {
    const cycleTable = form.getFieldValue('upperLimit');
    const newTable = meterTypeIds?.map((id, index) => {
      const prevRow = cycleTable?.find((k: any) => k.key === id);
      const rowItem = measureList.find(k => k.energyMediumId === id);
      return prevRow
        ? { ...prevRow }
        : {
            key: id,
            name: `${rowItem?.energyMediumName}（${rowItem?.physicalUnitName ?? '--'}）`,
            hour: undefined,
            date: undefined,
            week: undefined,
            month: undefined,
            tenDay: undefined,
          };
    });
    form.setFieldValue('upperLimit', newTable);
  }, [meterTypeIds, measureList, form]);

  const onFinish = (val: any) => {
    // 编辑时 不编辑用能单元，用的是回显的数组，params不需要map
    let isEditEnergyUnitGroupId = true;
    // @ts-ignore
    val.energyUnitIds.forEach(item => {
      if (typeof item === 'number') {
        isEditEnergyUnitGroupId = false;
        return;
      } else {
        isEditEnergyUnitGroupId = true;
        return;
      }
    });

    const selectMeters = meterTypeIds.map((typeId, index) => {
      const measure = measureList.find(k => k.energyMediumId === typeId);
      return {
        energyMediumId: measure?.energyMediumId!,
        energyMediumName: measure?.energyMediumName!,
        physicalUnitId: measure?.physicalUnitId!,
      };
    });

    const params = {
      ruleName: val.ruleName,
      alarmLevel: Number(val.alarmLevel),
      ouId: val.ouId,
      energyUnitGroupId: val.energyUnitGroupId,
      energyUnitIds: isEditEnergyUnitGroupId
        ? val.energyUnitIds.map((i: { value: number }) => i.value)
        : val.energyUnitIds,
      alarmType: val.alarmType,
      meterTypeIds: val.meterTypeIds,
      hourUpperLimitValue: selectMeters.map((i, index) => ({
        ...i,
        upperLimitValue: val.upperLimit[index].hour,
      })),
      dayUpperLimitValue: selectMeters.map((i, index) => ({
        ...i,
        upperLimitValue: val.upperLimit[index].date,
      })),
      weekUpperLimitValue: selectMeters.map((i, index) => ({
        ...i,
        upperLimitValue: val.upperLimit[index].week,
      })),
      monthUpperLimitValue: selectMeters.map((i, index) => ({
        ...i,
        upperLimitValue: val.upperLimit[index].month,
      })),
      tenDayUpperLimitValue: selectMeters.map((i, index) => ({
        ...i,
        upperLimitValue: val.upperLimit[index].tenDay,
      })),
      openEarlyWarning: val.openEarlyWarning,
      daysOfMonthForEarlyWarning: val.daysOfMonthForEarlyWarning,
    };

    // 有id为编辑更新
    if (id) {
      apiV2EnergyUnitServerAlarmUpdateRulePost({ ruleId: Number(id), ...params }).finally(() => {
        navigate(`/energyUnits/Alarm/rules?${urlSearchParams.toString()}`);
      });
    } else {
      apiV2EnergyUnitServerAlarmAddRulePost({ ...params }).then(res => {
        navigate(`/energyUnits/Alarm/rules?${urlSearchParams.toString()}`);
      });
    }
  };
  // 用能分析组切换
  const onchangeEnergy = (value: number) => {
    setMeasureList([]);
    form.setFieldsValue({
      meterTypeIds: undefined,
      energyUnitIds: undefined,
    });
  };

  // 用能单元切换
  const onChangeCell = (ids: EnergyCell[]) => {
    setEnergyCells((ids ?? []).map(i => i.value));
    form.setFieldsValue({ meterTypeIds: undefined });
  };

  // 获取用能单元树
  useEffect(() => {
    if (isNil(energyUnitGroupId)) return;
    getEnergyUnitTree(energyUnitGroupId).then(res => {
      const tree = formatToNodeData(res.list || []);
      setTreeNodeData(tree);
      setEnergyDisabled(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitGroupId]);

  // 获取计量类别列表
  useEffect(() => {
    if (isNil(energyUnitGroupId) || isNil(energyCells) || !energyCells.length) return;
    apiV2EnergyUnitServerAlarmGetMeterTypesPost({ energyGroupId: energyUnitGroupId, energyUnitIds: energyCells }).then(
      res => {
        setMeasureList(res.list ?? []);
      }
    );
  }, [energyUnitGroupId, energyCells]);

  const columns = [
    {
      title: '周期/能源类型',
      dataIndex: 'name',
      width: 200,
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            style={{ marginBottom: 0 }}
            name={['upperLimit', index, 'name']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const { hour, date, week, month, tenDay } = getFieldValue(['upperLimit', index]);
                  if (isNil(hour) && isNil(date) && isNil(week) && isNil(month) && isNil(tenDay)) {
                    return Promise.reject(new Error('请至少填写一项上限值'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <ShowInput />
          </Form.Item>
        );
      },
    },
    {
      title: '时',
      dataIndex: 'hour',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['upperLimit', index, 'hour']}>
            <InputNumber min={0} max={999999999} precision={4} placeholder="请输入上限值" style={{ width: '100%' }} />
          </Form.Item>
        );
      },
    },

    {
      title: '日',
      dataIndex: 'date',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['upperLimit', index, 'date']}>
            <InputNumber min={0} max={999999999} precision={4} placeholder="请输入上限值" style={{ width: '100%' }} />
          </Form.Item>
        );
      },
    },
    {
      title: '周',
      dataIndex: 'week',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['upperLimit', index, 'week']}>
            <InputNumber min={0} max={999999999} precision={4} placeholder="请输入上限值" style={{ width: '100%' }} />
          </Form.Item>
        );
      },
    },
    {
      title: '月',
      dataIndex: 'month',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['upperLimit', index, 'month']}>
            <InputNumber min={0} max={999999999} precision={4} placeholder="请输入上限值" style={{ width: '100%' }} />
          </Form.Item>
        );
      },
    },
    {
      title: '旬',
      dataIndex: 'tenDay',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['upperLimit', index, 'tenDay']}>
            <InputNumber min={0} max={999999999} precision={4} placeholder="请输入上限值" style={{ width: '100%' }} />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          alarmType: AlarmLogicItemEnum.ENERGY_UPPER_LIMIT,
          openEarlyWarning: true,
          daysOfMonthForEarlyWarning: [15],
        }}
      >
        <FormTitle title={`${id ? '编辑' : '新建'}单元报警规则`} />
        <SubContent title="规则基础信息" style={{ marginBottom: 8 }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="ruleName"
                label="规则名称"
                rules={[{ required: true, max: 15, message: '请输入15字符以内' }]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="alarmLevel" label="报警等级" rules={[{ required: true, message: '请选择报警等级' }]}>
                <Select placeholder="请选择" options={alarmLevelOptions()} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="ouId" label="所属运营单元" rules={[{ required: true, message: '请选择所属运营单元' }]}>
                <Select placeholder="请选择" options={ouListOptions} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="报警对象" style={{ marginBottom: 8 }}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="energyUnitGroupId"
                label="用能分析组"
                rules={[{ required: true, message: '请选择用能分析组' }]}
              >
                <Select
                  placeholder="请选择"
                  fieldNames={{ label: 'energyGroupName', value: 'energyGroupId' }}
                  options={unitGroup}
                  onChange={onchangeEnergy}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="energyUnitIds"
                label="用能单元"
                rules={[{ required: true, message: '请选择至少一项用能单元' }]}
              >
                <TreeSelect
                  onChange={onChangeCell}
                  disabled={energyDisabled}
                  treeData={treeNodeData}
                  maxTagCount={3}
                  treeCheckStrictly
                  treeCheckable
                  showCheckedStrategy="SHOW_ALL"
                  treeDefaultExpandAll
                  placeholder="请选择"
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <SubContent title="规则详情">
          <Col span={24}>
            <Form.Item name="alarmType" label="报警逻辑">
              <Radio.Group>
                <Radio value={7} children="用能上限" />
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="meterTypeIds"
              label="能源类型"
              rules={[{ required: true, message: '请至少选择一项能源类型' }]}
            >
              <Select
                placeholder="请选择"
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                options={measureList}
                optionFilterProp="label"
                fieldNames={{ label: 'energyMediumName', value: 'energyMediumId' }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="上限额设定"
              name="upperLimit"
              valuePropName="dataSource"
              tooltip={{
                title: '不填写上限额时，视为周期内该类型不报警',
                icon: <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />,
              }}
            >
              <Table rowKey="key" pagination={false} columns={columns} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="智能预警">
              <Form.Item name="openEarlyWarning" noStyle valuePropName={'checked'}>
                <Checkbox style={{ marginTop: 3 }} children="月度超限趋势预警" />
              </Form.Item>
              <span>
                <QuestionCircleOutlined style={{ color: '#FDBE13' }} />
                <span style={{ paddingLeft: '5px' }}>基于历史数据只能判断本月用能是否超上限。</span>
              </span>
            </Form.Item>
          </Col>
          {openEarlyWarning && (
            <Col span={24}>
              <Space align="baseline" size={8} style={{ marginLeft: 136 }}>
                <span>每月</span>
                <Form.Item
                  name="daysOfMonthForEarlyWarning"
                  colon={false}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value.length <= 0) {
                          return Promise.reject(new Error('勾选后必选'));
                        }
                        if (value.length <= 5) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('只能选择不超过 5 个选项!'));
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="label"
                    style={{ width: 320, height: 32 }}
                    options={daysOfMonthForEarlyWarningOptions}
                  />
                </Form.Item>
                <span>日，根据当前用能趋势判断预警</span>
              </Space>
            </Col>
          )}

          <Space size="small" className="sticky-footer-left">
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: <span>存在编辑信息，确定丢弃未保存信息并返回？</span>,
                  onOk: () => {
                    navigate(`/energyUnits/Alarm/rules?${urlSearchParams.toString()}`);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        </SubContent>
      </Form>
    </Wrapper>
  );
};
export default CreateEnergyAlarmRule;
