import { FC, useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Table,
  Paging,
  usePaging,
  EllipsisSpan,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Form,
  DatePicker,
  Select,
  Input,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { BalanceAlarmLogRequest } from '../../../../api/balanceAlarm';
import { getOuListOwned } from '../../../../api/ou';
import { apiV2ServerAlarmRecordPagePost, V2ServerAlarmRecordPagePostResponse } from '@maxtropy/device-customer-apis-v2';

//  选择日期
const { RangePicker } = DatePicker;

// 时间格式化
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const displayDateFormat = 'YYYY-MM-DD HH:mm:ss';

// 面包屑
// const routes = [
//   { name: "运营配置" },
//   { name: "余额报警" },
//   { name: "报警记录" }
// ];

interface FilterParams {
  tenantMcid?: string;
  ouId?: number; // 运营单元ID
  saleClientAccountName?: string; // 客户账户名称
  time?: [Dayjs, Dayjs]; // 报警日期
  alarmLevels?: number[];
}
export enum AlarmLevel {
  HIGHEST,
  HIGH,
  MIDDLE,
  LOW,
  LOWEST,
}

export const AlarmLevelDisplay = {
  [AlarmLevel.HIGHEST]: '最高级',
  [AlarmLevel.HIGH]: '高级',
  [AlarmLevel.MIDDLE]: '中级',
  [AlarmLevel.LOW]: '低级',
  [AlarmLevel.LOWEST]: '最低级',
};

// 查询参数类型
type SearchParams = Omit<BalanceAlarmLogRequest, 'page' | 'size'>;

export type AlarmRecordItem = Exclude<V2ServerAlarmRecordPagePostResponse['list'], undefined>[number];

// 表格：列名
export const columns = [
  {
    title: '报警客户账户',
    dataIndex: 'saleClientAccountName',
    ellipsis: { showTitle: true }, //超过宽度将自动省略
    render: (v: string) => <EllipsisSpan value={v} />,
    // 生成复杂数据的渲染函数，参数分别为当前行的值，当前行数据，行索引
  },
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={AlarmLevelDisplay[v as keyof typeof AlarmLevelDisplay]} />,
  },
  {
    title: '报警规则编号',
    dataIndex: 'ruleCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警规则名称',
    dataIndex: 'ruleName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警时间',
    dataIndex: 'alarmTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(displayDateFormat)} />,
  },
  {
    title: '恢复时间',
    dataIndex: 'recoverTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : '未恢复'} />,
  },
];

const BalanceAlarmRecordList: FC = () => {
  const [form] = Form.useForm();
  const [updateState] = useUpdate();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>({});

  // 获取运营单元
  const ou = useAsync(getOuListOwned, []);
  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const [records, setRecords] = useState<AlarmRecordItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 列表查询
  useEffect(() => {
    setLoading(true);

    apiV2ServerAlarmRecordPagePost({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      setLoading(false);
      if (res) {
        setTotalCount(res.total ?? 0);
        const list = (res.list ?? []).map(item => {
          return {
            id: item.id,
            saleClientAccountName: item.attribute?.saleClientAccountName, // 客户账户名称
            saleClientName: item.attribute?.saleClientName, //客户名称
            ruleCode: item.ruleCode, // 报警规则编号
            ruleName: item.ruleName, // 报警规则名称
            alarmTime: item.alarmTime, // 报警时间
            recoverTime: item.recoverTime, // 恢复时间
            alarmType: item.alarmLevel, //报警等级
          };
        });
        setRecords(list ?? []);
      }
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: FilterParams) => {
    const params: SearchParams = {
      ouId: value.ouId,
      saleClientAccountName: value.saleClientAccountName?.trim(),
      alarmStartTime: value.time ? dayjs(value.time[0]).valueOf() : undefined,
      alarmEndTime: value.time ? dayjs(value.time[1]).valueOf() : undefined,
      alarmLevels: value.alarmLevels,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    const params: FilterParams = {
      ouId: undefined,
      saleClientAccountName: undefined,
      time: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as const,
      render: (value: undefined, record: AlarmRecordItem) => {
        return (
          <Space>
            <Button type="link">
              <Link to={`detail/${record.id}`}>查看报警详情</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      onReset={onReset}
      colSpan={8}
    >
      <Form.Item name="alarmLevels" label="报警等级">
        <Select placeholder="请选择" maxTagCount={3} mode="multiple">
          <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
          <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
          <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
          <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
          <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="ouId" label="运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item name="saleClientAccountName" label="客户账户名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>
      <Form.Item name="time" label="报警时间">
        <RangePicker
          style={{ width: '100%' }} // defaultValue={[dayjs('2015/01/01', dateFormat), dayjs('2015/01/01', dateFormat)]}
          format={dateFormat}
          showTime
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Table sticky loading={loading} scroll={{ x: 1500 }} columns={buildColumns} dataSource={records} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default BalanceAlarmRecordList;
