import { FC, useEffect, useState } from 'react';
import {
  SubContent,
  Wrapper,
  Table,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Button,
  EllipsisSpan,
  Form,
  DatePicker,
  Select,
  Input,
  FormTitle,
  CustomFilter,
  ShowInput,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Col, Space, Row } from 'antd';

import {
  CreditLimitType,
  getSaleClientAccount,
  getSaleClientAccountTransactionPage,
  SaleClientAccountInfo,
  SaleClientAccountTransactionPageRequest,
  SaleClientAccountTransactionVo,
  TransactionType,
  TransactionTypeDisplay,
  TransactionTypeOperator,
} from '../../../api/sale';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { timeFormatString } from '../util';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { RangePicker } = DatePicker;

interface SearchParams extends Omit<SaleClientAccountTransactionPageRequest, 'page' | 'size' | 'saleClientAccountId'> {}
interface FilterParams extends Omit<SearchParams, 'startTime' | 'endTime'> {
  time?: RangeValue;
}

const billsColumns: ColumnType<SaleClientAccountTransactionVo>[] = [
  {
    title: '交易类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: TransactionType) => <EllipsisSpan value={TransactionTypeDisplay[v]} />,
  },
  {
    title: '说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '金额变化',
    dataIndex: 'amount',
    ellipsis: { showTitle: true },
    render: (v: number, record) => <EllipsisSpan value={`${TransactionTypeOperator[record.type]}${v}`} />,
  },
  {
    title: '操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(timeFormatString)} />,
  },
];

const routes = [{ name: '账户流水' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

function getDefaultDate(): RangeValue {
  const currentTime = dayjs();
  return [dayjs(currentTime).subtract(1, 'years'), currentTime];
}

const AccountBills: FC = () => {
  const [form] = Form.useForm();
  const [queryForm] = Form.useForm();
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);
  const [saleClientAccount, setSaleClientAccount] = useState<SaleClientAccountInfo>();
  const [data, setData] = useState<SaleClientAccountTransactionVo[]>([]);

  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const pagingInfo = usePaging(10);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const goList = () => {
    navigate('/operation/client-related/client-account');
  };

  useEffect(() => {
    const time = getDefaultDate();
    queryForm.setFieldsValue({
      time: getDefaultDate(),
    });
    setSearchParams({
      startTime: time![0]!.format(timeFormatString),
      endTime: time![1]!.format(timeFormatString),
    });
  }, [queryForm, updateState]);

  // 分页数据
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  useEffect(() => {
    if (id && searchParams.startTime && searchParams.endTime) {
      setLoading(true);
      getSaleClientAccountTransactionPage({
        ...searchParams,
        saleClientAccountId: id,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        setData(res.list);
        setTotalCount(res.total);
      });
    }
  }, [pageOffset, pageSize, id, searchParams, setTotalCount]);

  // 客户信息接口
  useEffect(() => {
    if (id) {
      getSaleClientAccount(id).then(setSaleClientAccount);
    }
  }, [id]);

  useEffect(() => {
    if (saleClientAccount) {
      form.setFieldsValue({
        name: saleClientAccount.name,
        saleClientName: saleClientAccount.saleClientName,
        creditLimitType: saleClientAccount.creditLimitType,
        creditLimitAmount:
          (saleClientAccount.creditLimitAmount ?? undefined) !== undefined
            ? `${saleClientAccount.creditLimitAmount}元`
            : saleClientAccount.creditLimitAmount,
        balance: (saleClientAccount.balance ?? undefined) !== undefined ? `${saleClientAccount.balance}元` : undefined,
      });
    }
  }, [saleClientAccount, form]);

  const onFinish = (val: FilterParams) => {
    const params: SearchParams = {
      type: val.type,
      username: val.username,
      startTime: val.time![0]!.format(timeFormatString),
      endTime: val.time![1]!.format(timeFormatString),
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    updateFn();
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter<FilterParams> onFinish={val => onFinish(val)} onReset={onReset} form={queryForm}>
      <Form.Item name="type" label="交易类型:">
        <Select placeholder="请选择">
          <Select.Option value={TransactionType.RECHARGE}>
            {TransactionTypeDisplay[TransactionType.RECHARGE]}
          </Select.Option>
          <Select.Option value={TransactionType.REFUND}>{TransactionTypeDisplay[TransactionType.REFUND]}</Select.Option>
          <Select.Option value={TransactionType.BILLDEDUCTION}>
            {TransactionTypeDisplay[TransactionType.BILLDEDUCTION]}
          </Select.Option>
          <Select.Option value={TransactionType.MANUAL_DEDYCTION}>
            {TransactionTypeDisplay[TransactionType.MANUAL_DEDYCTION]}
          </Select.Option>
          <Select.Option value={TransactionType.ANTI_BOOKKEEPING}>
            {TransactionTypeDisplay[TransactionType.ANTI_BOOKKEEPING]}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item rules={[{ required: true, message: '' }]} name="time" label="时间:">
        <RangePicker showTime />
      </Form.Item>
      <Form.Item name="username" label="操作人:">
        <Input placeholder="请输入" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title={routes[0].name} />
      <SubContent title="账户信息">
        <Form layout="vertical" className={styles.formWrapper} form={form} {...formLayout}>
          <Row>
            <Col span={6}>
              <Form.Item name="saleClientName" label="客户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="name" label="账户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="balance" label="账户余额">
                <ShowInput style={{ color: '#FF0000' }} />
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['creditLimitType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('creditLimitType') === CreditLimitType.LIMITED && (
                    <Col span={6}>
                      <Form.Item name="creditLimitAmount" label="信用额度">
                        <ShowInput style={{ color: '#FF0000' }} />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
          </Row>
        </Form>
      </SubContent>

      <SubContent title="账户流水">
        {filters}
        <Table rowKey="id" sticky loading={loading} dataSource={data} columns={billsColumns} />
        <Paging pagingInfo={pagingInfo} />
      </SubContent>

      <Space className="sticky-footer-c">
        <Button onClick={goList}> 返回 </Button>
      </Space>
    </Wrapper>
  );
};

export default AccountBills;
