import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useSearchParams,
  useUpdate,
  useBreadcrumbRoutes,
  Paging,
  CustomFilter,
} from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Form, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2CarbonFootprintSupplierDeletePost,
  apiV2CarbonFootprintSupplierPagePost,
  V2CarbonFootprintSupplierPagePostRequest,
  V2CarbonFootprintSupplierPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import AddSupplierModal from './AddSupplierModal';

export type SupplierItem = Exclude<V2CarbonFootprintSupplierPagePostResponse['list'], undefined>[number];

const columns: ColumnsType<SupplierItem> = [
  {
    title: '供应商编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '供应商名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '供应商地址',
    dataIndex: 'address',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams extends Omit<V2CarbonFootprintSupplierPagePostRequest, 'page' | 'size'> {}

const SupplierManage: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(20);
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierItem>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDSUPPLIER); // 新建
  const hasEdit = useHasPermission(PermissionsType.B_EDITSUPPLIER); // 编辑
  const hasDelete = useHasPermission(PermissionsType.B_DELSUPPLIER); // 删除
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const { data, loading } = useRequest(
    () =>
      apiV2CarbonFootprintSupplierPagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total ?? 0);
        return res.list;
      }),
    {
      refreshDeps: [searchParams, setTotalCount, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2CarbonFootprintSupplierDeletePost({ id }).then(res => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  setSelectedSupplier(record);
                  setModalOpen(true);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete && (
              <PopConfirm placement="top" title="是否删除此供应商？" onConfirm={() => onDelete(record.id)}>
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} colSpan={8} onReset={onReset}>
      <Form.Item label="供应商编号/名称" name="name">
        <Input placeholder="供应商编号/名称进行搜索" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      {hasAdd && (
        <Space style={{ marginBottom: 10 }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setSelectedSupplier(undefined);
              setModalOpen(true);
            }}
          >
            录入供应商
          </Button>
        </Space>
      )}
      <Table loading={loading} rowKey="id" columns={buildColumns} dataSource={data} />
      <Paging pagingInfo={pagingInfo} />
      {modalOpen && (
        <AddSupplierModal
          open={modalOpen}
          selectedSupplier={selectedSupplier}
          updateData={() => updateFn()}
          setOpen={setModalOpen}
        />
      )}
    </Wrapper>
  );
};

export default SupplierManage;
