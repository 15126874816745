import React, { useEffect, useState } from 'react';
import { Table, EllipsisSpan } from '@maxtropy/components';
import {
  UnitConfGroupSettingViewRecord,
  UnitConfGroupDeviceType,
  UnitConfGroupDeviceTypeDisplay,
  MeterTypeDisplay,
  MeterAttributeDisplay,
} from '@/api/energyConsumption';
import { Cascader, Select, Space, Tooltip } from 'antd';
import { getEnergyMediumSceneToMedium } from '@/api/workingSubstance';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  V2EnergyAnalysisDataPropertyListPostResponse,
  apiV2EnergyAnalysisAnalysisGroupMeasurementListPost,
  apiV2EnergyAnalysisDataPropertyListPost,
  apiV2EnergyAnalysisUnitConfGroupSettingViewPost,
} from '@maxtropy/device-customer-apis-v2';

type MeterSettingModalContentProps = {
  // 用能分析组 id
  energyGroupId: React.Key;
  // 用能单元 id
  confGroupId: string;
  meterSettingDetails: UnitConfGroupSettingViewRecord[];
  setMeterSettingDetails: React.Dispatch<React.SetStateAction<UnitConfGroupSettingViewRecord[]>>;
};

// 能源介质下拉框
type MediumMeterTypeOption = {
  value?: React.Key;
  label?: string;
  children?: MediumMeterTypeOption[];
};

export type EnergyUnitDataPropertyListResponse = Exclude<
  V2EnergyAnalysisDataPropertyListPostResponse['list'],
  undefined
>[number];

export default function MeterSettingModalContent(props: MeterSettingModalContentProps) {
  const { confGroupId, setMeterSettingDetails, meterSettingDetails, energyGroupId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [mediumMeterTypeOptions, setMediumMeterTypeOptions] = useState<MediumMeterTypeOption[]>([]);
  const [meterProperties, setMeterProperties] = useState<EnergyUnitDataPropertyListResponse[]>([]);
  const [disableMeterPropertySetting, setDisableMeterPropertySetting] = useState<boolean>(false);

  // 获取总分析组计量设置
  useEffect(() => {
    apiV2EnergyAnalysisAnalysisGroupMeasurementListPost({ energyUnitGroupId: energyGroupId }).then(res => {
      // 如果总分析组计量设置未填入, 禁止计量属性选择
      if (res?.list && res?.list.length > 0) {
        setDisableMeterPropertySetting(false);
      } else {
        setDisableMeterPropertySetting(true);
      }
    });
  }, [energyGroupId]);

  // 获取能源介质下拉列表
  useEffect(() => {
    getEnergyMediumSceneToMedium().then(res => {
      setMediumMeterTypeOptions(
        res.map(v => ({
          value: v.id,
          label: v.sceneName,
          children: v.energyMediums.map(m => ({
            value: m.id,
            label: m.energyName,
          })),
        }))
      );
    });
  }, []);

  // 根据 id, deviceId 和 typeId 获取所有可配置的计量属性下拉框
  useEffect(() => {
    // 传递的 map 格式为 { deviceId: meterTypeId }
    if (meterSettingDetails.length) {
      apiV2EnergyAnalysisDataPropertyListPost({
        energyUnitGroupId: energyGroupId,
        settings: meterSettingDetails
          ?.filter(
            d => d.type === UnitConfGroupDeviceType.ENERGY_CONSUMPTION || d.type === UnitConfGroupDeviceType.REMOVE
          )
          ?.filter(d => d.firstClassTypeId !== 5000)
          ?.map(d => ({
            id: d.id,
            deviceId: d.deviceId,
            energyMediumId: d.meterTypeId ?? undefined,
          })),
      }).then(res => {
        setMeterProperties(res.list ?? []);
      });
    }
  }, [confGroupId, energyGroupId, meterSettingDetails]);

  // 获取已配置的用能计量设置
  useEffect(() => {
    if (mediumMeterTypeOptions.length) {
      setLoading(true);
      apiV2EnergyAnalysisUnitConfGroupSettingViewPost({ energyUnitGroupId: confGroupId })
        .then(res => {
          setMeterSettingDetails((res.list ?? []) as UnitConfGroupSettingViewRecord[]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [confGroupId, setMeterSettingDetails, mediumMeterTypeOptions]);

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: UnitConfGroupDeviceType) => <EllipsisSpan value={UnitConfGroupDeviceTypeDisplay[v]} />,
    },
    {
      title: '计量类别',
      dataIndex: 'meterTypeId',
      ellipsis: { showTitle: true },
      render: (v: number, record: UnitConfGroupSettingViewRecord) => {
        if (loading) {
          return <Select style={{ width: '100%' }} placeholder="请选择" />;
        }
        return record.firstClassTypeId === 5000 ? (
          <Select
            placeholder="请选择"
            disabled={!record.staffHasOuAuthority}
            value={v}
            onChange={value => {
              setMeterSettingDetails(prevDetails =>
                prevDetails.map(d => (d.id === record.id ? { ...d, meterTypeId: value, meterAttributeId: null } : d))
              );
            }}
            style={{ width: '100%' }}
            options={Object.entries(MeterTypeDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
          />
        ) : (
          <Cascader
            allowClear={false}
            style={{ width: '100%' }}
            disabled={!record.staffHasOuAuthority}
            displayRender={label => {
              return label.join('-');
            }}
            options={mediumMeterTypeOptions}
            // 这里使用 value 而不是 defaultValue 是因为 defaultValue 只渲染一次
            // 接口若给的比较慢, 会存在延迟渲染的问题
            defaultValue={[record?.meterTypeParentId!, v].filter(value => Boolean(value)) ?? undefined}
            onChange={value => {
              setMeterSettingDetails(prevDetails =>
                prevDetails.map(d =>
                  d.id === record.id ? { ...d, meterTypeId: Number(value?.[1]), meterAttributeId: null } : d
                )
              );
            }}
            placeholder="请选择"
          />
        );
      },
    },
    {
      title: (
        <Space>
          <span>计量属性</span>
          <Tooltip title="非电能情况下，分析组计量设置须完成后可选择计量属性">
            <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'meterAttributeId',
      ellipsis: { showTitle: true },
      render: (v: number, record: UnitConfGroupSettingViewRecord) => {
        return (
          <Select
            placeholder="请选择"
            disabled={!record.staffHasOuAuthority || (disableMeterPropertySetting && record.firstClassTypeId !== 5000)}
            // 如果是 5000 电表直接显示, 否则的话先设为 undefined 防止返显出现数字问题
            value={record.firstClassTypeId === 5000 ? v : meterProperties?.length ? v : undefined}
            onChange={value => {
              setMeterSettingDetails(prevDetails =>
                prevDetails.map(d => (d.id === record.id ? { ...d, meterAttributeId: value } : d))
              );
            }}
            style={{ width: '100%' }}
            options={
              record.meterTypeId
                ? record.firstClassTypeId === 5000
                  ? [
                      ...Object.entries(MeterAttributeDisplay).map(([k, v]) => ({ label: v, value: Number(k) })),
                      { label: '不计入', value: null },
                    ]
                  : (
                      (record?.deviceId ? meterProperties.find(p => p.id === record.id)?.dataProperties : [])?.map(
                        p => ({
                          label: p.name!,
                          value: p.id!,
                        })
                      ) ?? []
                    )
                      // @ts-ignore
                      .concat([{ label: '不计入', value: null }]) ?? []
                : []
            }
          />
        );
      },
    },
  ];

  return <Table dataSource={meterSettingDetails} columns={columns} loading={loading} />;
}
