import { splitTime } from '@/pages/ShiftManage/TimeRangePicker';
import styles from './index.module.scss';
import { ShiftInfo } from '../util';

const TimeGrid: React.FC<{ timeArr: ShiftInfo[]; interDay?: boolean }> = ({ timeArr, interDay }) => {
  let timesAmount = splitTime(30);
  timesAmount.shift(); // 去掉00:00

  if (interDay) {
    const timesAmountNext = splitTime(30, true);
    timesAmountNext.shift(); // 去掉00:00
    timesAmount = timesAmount.concat(timesAmountNext);
  }

  return (
    <>
      <div className={styles.time_box}>
        {timesAmount.map(item => {
          const halfDay = item === '12:00' || item === '24:00' || item === '36:00';
          let has = timeArr?.find(info => {
            let timeArr = (info.timeSeg as string).split(';');
            return timeArr.some(d => {
              let rangeTime = d?.split('-');
              if (rangeTime) {
                if (item > rangeTime[0] && item <= rangeTime[1]) {
                  return true;
                }
              }
              return false;
            });
          });
          if (has) {
            return (
              <div
                key={item}
                className={`${styles.time_gap} ${interDay ? styles.smallGap : styles.bigGap} ${
                  halfDay && styles.interval
                }`}
                style={{ backgroundColor: has.colors?.border }}
              ></div>
            );
          } else {
            return (
              <div
                key={item}
                className={`${styles.time_gap} ${interDay ? styles.smallGap : styles.bigGap} ${
                  halfDay && interDay && styles.interval
                }`}
              ></div>
            );
          }
        })}
      </div>
      <div style={{ width: '100%', display: 'flex' }}>
        <div className={`${styles.time_fix} ${!interDay && styles.all}`}>
          <span>00:00</span>
          <span>12:00</span>
          <span>24:00</span>
        </div>
        {interDay && (
          <div className={`${styles.time_fix} ${styles.halfRight}`}>
            <span>次日00:00</span>
            <span>12:00</span>
            <span>次日24:00</span>
          </div>
        )}
      </div>
    </>
  );
};
export default TimeGrid;
