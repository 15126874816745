import {
  EnergyAssetsBillDetail,
  EnergyAssetsBillDetailItem,
  TimeIntervalTableType,
  TimeIntervalTableTypeDisplay,
} from '@/api/energyAssetsBill';
import { TimeIntervalType, TimeIntervalTypeDisplay } from '@/api/energyAssetsSettlementsRules';
import { EllipsisSpan, Empty, Key, SubContent, Table } from '@maxtropy/components';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { DatabaseOutlined } from '@ant-design/icons';

interface TimeIntervalTableProps {
  tableType: TimeIntervalTableType;
  detail?: EnergyAssetsBillDetail;
}

const timeInterval = [
  {
    title: '',
    dataIndex: 'timeInterval',
    ellipsis: { showTitle: true },
    render: (v: TimeIntervalType) => <EllipsisSpan value={TimeIntervalTypeDisplay[v]} />,
    width: 70,
  },
];

const presentMonthDisplay = [
  {
    title: '本月示数',
    dataIndex: 'presentMonthDisplay',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
];
const lastMonthDisplay = [
  {
    title: '上月示数',
    dataIndex: 'lastMonthDisplay',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v?.toFixed(2)} />,
  },
];
const times = [
  {
    title: '倍率',
    dataIndex: 'times',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
];
const generatingCapacity = [
  {
    title: '发电量',
    dataIndex: 'generatingCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}kWh`} />,
  },
];
const gridCapacity = [
  {
    title: '上网电量',
    dataIndex: 'gridCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}kWh`} />,
  },
];
const gridPercentageAmount = [
  {
    title: '上网分成',
    dataIndex: 'gridPercentageAmount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}元`} />,
  },
];

// 光伏发电量
const columns_PV_OUTPUT = [
  ...timeInterval,
  ...presentMonthDisplay,
  ...lastMonthDisplay,
  ...times,
  ...generatingCapacity,
];

// 光伏上网电量
const columns_PV_ONLINE = [
  ...timeInterval,
  ...presentMonthDisplay,
  ...lastMonthDisplay,
  ...times,
  ...gridCapacity,
  ...gridPercentageAmount,
];

// 光伏消纳电量
const columns_PV_CONSUMPTION = [
  ...timeInterval,
  ...generatingCapacity,
  ...gridCapacity,
  {
    title: '消纳电量',
    dataIndex: 'usedCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}kWh`} />,
  },
  {
    title: '消纳电费',
    dataIndex: 'usedAmount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}元`} />,
  },
];

// 储能充电量
const columns_BSA_CHARGE = [
  ...timeInterval,
  ...presentMonthDisplay,
  ...lastMonthDisplay,
  ...times,
  {
    title: '充电量',
    dataIndex: 'chargingCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}kWh`} />,
  },
  {
    title: '充电成本',
    dataIndex: 'chargingAmount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}元`} />,
  },
];

// 储能放电量
const columns_BSA_DISCHARGE = [
  ...timeInterval,
  ...presentMonthDisplay,
  ...lastMonthDisplay,
  ...times,
  {
    title: '放电量',
    dataIndex: 'dischargingCapacity',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}kWh`} />,
  },
  {
    title: '放电收入',
    dataIndex: 'dischargingAmount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={isNil(v) ? v : `${v.toFixed(2)}元`} />,
  },
];

const TimeIntervalTable: React.FC<TimeIntervalTableProps> = ({ tableType, detail }) => {
  const [tableElement, setTableElement] = useState<React.ReactNode>(<Empty />);

  const columns = useMemo(() => {
    switch (tableType) {
      case TimeIntervalTableType.PV_OUTPUT:
        return columns_PV_OUTPUT;
      case TimeIntervalTableType.PV_ONLINE:
        return columns_PV_ONLINE;
      case TimeIntervalTableType.PV_CONSUMPTION:
        return columns_PV_CONSUMPTION;
      case TimeIntervalTableType.BSA_CHARGE:
        return columns_BSA_CHARGE;
      case TimeIntervalTableType.BSA_DISCHARGE:
        return columns_BSA_DISCHARGE;
      default:
        return [];
    }
  }, [tableType]);

  useEffect(() => {
    if (detail && detail.details && detail.details.length > 0) {
      // 根据设备id分组
      const tableDataMap = new Map<Key, EnergyAssetsBillDetailItem[]>();
      const originalTableData = detail.details.filter(item => item.detailType === tableType);

      originalTableData.forEach(item => {
        if (item.deviceId) {
          tableDataMap.set(
            item.deviceId,
            originalTableData.filter(i => item.deviceId === i.deviceId)
          );
        }
      });

      // 生成表格
      const tableList =
        tableType !== TimeIntervalTableType.PV_CONSUMPTION ? (
          Array.from(tableDataMap.keys()).map(key => {
            const tableData = tableDataMap.get(key)?.sort((a, b) => a.timeInterval - b.timeInterval);
            return (
              <div key={key}>
                <div>
                  <DatabaseOutlined style={{ color: 'var(--primary-color)', fontSize: 18 }} />
                  设备名称：{tableData?.[0]?.deviceName ?? '--'}
                </div>

                <div className={styles.activePowerTable}>
                  <Table rowKey="timeInterval" pagination={false} dataSource={tableData} columns={columns} />
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.activePowerTable}>
            <Table
              rowKey="timeInterval"
              pagination={false}
              dataSource={originalTableData.sort((a, b) => a.timeInterval - b.timeInterval)}
              columns={columns}
            />
          </div>
        );
      setTableElement(tableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, tableType]);

  return <SubContent title={TimeIntervalTableTypeDisplay[tableType]}>{tableElement}</SubContent>;
};

export default TimeIntervalTable;
