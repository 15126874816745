import { Col, Row, Spin } from 'antd';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { Button, Empty, Radio, useUpdate } from '@maxtropy/components';
import AddPlanModal from '../AddPlanModal';
import { EnergyPlanDetailListItem, PlanRange, PlanType, getEnergyPlanDetailList } from '@/api/plustekEnergyPlan';
import CardItem from './CardItem';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';

interface LeftPlanListProps {
  setEnergyPlanDistributionList: (v: EnergyPlanDetailListItem[]) => void; // 设置计划分配列表
  setSelectedEnergyPlanDetail: (v?: EnergyPlanDetailListItem) => void; // 设置被选中的计划详情
  selectedEnergyPlanDetailId?: number; // 被选中的计划的id
  setSelectedEnergyPlanDetailId: (v?: number) => void; // 设置被选中的计划的id
  isEditPlanDetail: boolean; // 是否编辑计划详情
  setIsEditPlanDetail: (v: boolean) => void; // 设置是否编辑计划详情
  setIsSave: (v: boolean) => void; // 设置是否保存
  isEdit?: boolean;
}

const LeftPlanList: React.FC<LeftPlanListProps> = props => {
  const {
    setEnergyPlanDistributionList,
    setSelectedEnergyPlanDetail,
    selectedEnergyPlanDetailId,
    setSelectedEnergyPlanDetailId,
    isEditPlanDetail,
    setIsEditPlanDetail,
    setIsSave,
    isEdit,
  } = props;
  const { id: energyPlanId } = useParams<{ id: string }>(); // 计划id
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnergyPlanDetailListItem[]>([]);
  const [addPlanModalOpen, setAddPlanModalOpen] = useState<boolean>(false);
  const [planType, setPlanType] = useState<PlanType>();
  const [planRange, setPlanRange] = useState<PlanRange>();
  const [update, updateFn] = useUpdate();
  const [unitSelectedMonth, setUnitSelectedMonth] = useState<number[]>([]);
  const [deviceSelectedMonth, setDeviceSelectedMonth] = useState<number[]>([]);

  useEffect(() => {
    if (isNil(energyPlanId)) return;
    setLoading(true);

    getEnergyPlanDetailList({
      energyPlanId: Number(energyPlanId),
      planType,
      planRange,
    })
      .then(res => {
        if (res) {
          setData(res.list ?? []);
          // 不包括日计划
          setEnergyPlanDistributionList((res.list ?? []).filter(i => i.planType !== PlanType.DAY));
          // 已添加过的日计划
          setUnitSelectedMonth(
            (res.list ?? [])
              .filter(i => i.planType === PlanType.DAY && i.planRange === PlanRange.ENERGY_UNIT)
              .map(i => dayjs(i.planDate).get('month') + 1)
          );
          setDeviceSelectedMonth(
            (res.list ?? [])
              .filter(i => i.planType === PlanType.DAY && i.planRange === PlanRange.DEVICE)
              .map(i => dayjs(i.planDate).get('month') + 1)
          );
          if (res.list && res.list.length > 0) {
            setSelectedEnergyPlanDetailId(res.list[0].id);
            setSelectedEnergyPlanDetail(res.list[0]);
          } else {
            setSelectedEnergyPlanDetailId(undefined);
            setSelectedEnergyPlanDetail(undefined);
          }
        }
      })
      .finally(() => setLoading(false));
  }, [
    energyPlanId,
    planType,
    planRange,
    update,
    setEnergyPlanDistributionList,
    setSelectedEnergyPlanDetailId,
    setSelectedEnergyPlanDetail,
  ]);

  return (
    <div className={styles.leftPlanList}>
      <div className={styles.searchArea}>
        <Row gutter={[0, 8]}>
          <Col>
            <Radio.Group
              buttonStyle="solid"
              size="small"
              value={planType}
              onChange={(e: any) => {
                setPlanType(e.target.value);
              }}
            >
              <Radio.Button value={undefined}>全部</Radio.Button>
              <Radio.Button value={1}>日</Radio.Button>
              <Radio.Button value={2}>月</Radio.Button>
              <Radio.Button value={3}>季度</Radio.Button>
              <Radio.Button value={4}>年</Radio.Button>
            </Radio.Group>
          </Col>
          <Col span={24}>
            <Radio.Group
              buttonStyle="solid"
              size="small"
              value={planRange}
              onChange={(e: any) => {
                setPlanRange(e.target.value);
              }}
            >
              <Radio.Button value={undefined}>全部</Radio.Button>
              <Radio.Button value={1}>用能单元</Radio.Button>
              <Radio.Button value={2}>设备</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </div>
      <div className={styles.list}>
        <Spin spinning={loading}>
          <Row gutter={[0, 8]}>
            {data && data.length > 0 ? (
              <>
                {data.map(i => {
                  return (
                    <Col span={24} key={i.id}>
                      <CardItem
                        cardInfo={i}
                        updateFn={updateFn}
                        selectId={selectedEnergyPlanDetailId}
                        setSelectId={setSelectedEnergyPlanDetailId}
                        setSelectedEnergyPlanDetail={setSelectedEnergyPlanDetail}
                        isEditPlanDetail={isEditPlanDetail}
                        setIsEditPlanDetail={setIsEditPlanDetail}
                        isEdit={isEdit}
                        setIsSave={setIsSave}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              <Empty style={{ marginTop: 50, marginLeft: 90 }} />
            )}
          </Row>
        </Spin>
      </div>
      {isEdit && (
        <Button
          type="dashed"
          style={{ width: 258, margin: '16px 0px 10px 0' }}
          onClick={() => {
            setAddPlanModalOpen(true);
          }}
          icon={<PlusOutlined />}
        >
          添加计划
        </Button>
      )}

      {addPlanModalOpen && (
        <AddPlanModal
          open={addPlanModalOpen}
          setOpen={setAddPlanModalOpen}
          updateFn={updateFn}
          unitSelectedMonth={unitSelectedMonth}
          deviceSelectedMonth={deviceSelectedMonth}
        />
      )}
    </div>
  );
};

export default LeftPlanList;
