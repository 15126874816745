import { Space } from 'antd';
import { columns } from './util';
import { FC } from 'react';
import { ElectricitySaleContractMeterType } from '../../../../../api/electricitySalesContract';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from '@maxtropy/components';

export interface EffectiveMeterTableProps {
  id?: string;
  tableTitle?: string;
  tableValue?: ElectricitySaleContractMeterType[];
}

const EffectiveMeterTable: FC<EffectiveMeterTableProps> = props => {
  return (
    <>
      {
        <Space direction="vertical" style={{ marginBottom: 10 }}>
          <Button disabled type="primary">
            <PlusOutlined />
            {props.tableTitle}
          </Button>
          <div />
        </Space>
      }
      <Table
        sticky
        scroll={{ x: 1300, y: 400 }}
        columns={columns}
        rowKey="deviceId"
        dataSource={props.tableValue ?? []}
      />
    </>
  );
};

export default EffectiveMeterTable;
