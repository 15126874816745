import React, { useCallback, useEffect, useState } from 'react';
import { Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Select } from '@maxtropy/components';
import { apiV2ProductionConsumptionOutputListPost } from '@maxtropy/device-customer-apis-v2';
import { useQuery } from '@/utils/utils';
import styles from './index.module.scss';
import { RangeValue } from '../DateSwitch';

interface OutputProps {
  generalQuery: Record<string, any>;
  value: RangeValue;
  selectedOutputValue?: number;
  memoOutputValue?: number;
  setUnitName: (v?: string) => void;
  setSelectedOutputValue: (v?: number) => void;
  setMemoOutputValue: (v?: number) => void;
  setSelectedOutputName: (v?: string) => void;
}

const OutputBox = ({
  generalQuery,
  value,
  selectedOutputValue,
  memoOutputValue,
  setUnitName,
  setSelectedOutputValue,
  setSelectedOutputName,
  setMemoOutputValue,
}: OutputProps) => {
  const [isExpand, setExpand] = useState<boolean>(true);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2ProductionConsumptionOutputListPost({
          ...generalQuery,
          startDateTime: value?.[0]?.valueOf(),
          endDateTime: value?.[1]?.valueOf(),
        }).then(res => {
          if (!res.list?.find(i => i.id === memoOutputValue)) {
            setSelectedOutputValue(res.list?.[0]?.id);
            setUnitName(res.list?.[0]?.unitName);
            setSelectedOutputName(res.list?.[0]?.name);
            setMemoOutputValue(res.list?.[0]?.id);
          } else {
            setSelectedOutputValue(memoOutputValue);
          }

          return res.list ?? [];
        }),
      [generalQuery, value]
    )
  );

  useEffect(() => {
    const activeEle = document.getElementById('active');
    if (activeEle) {
      const tagBox = document.getElementById('tagBox')?.getBoundingClientRect();
      const activeBoxInfo = document.getElementById('active')?.getBoundingClientRect();
      if (activeBoxInfo?.bottom! > tagBox?.bottom!) {
        document.getElementById('tagBox')!.scrollTop = activeBoxInfo?.bottom! - tagBox?.height!;
      }
    }
  }, [selectedOutputValue]);

  return (
    <div>
      <Space size={8}>
        <Select
          style={{ width: 268 }}
          value={selectedOutputValue}
          placeholder="请选择"
          showSearch
          options={(data ?? []).map(i => ({ label: i.name, value: i.id, unitName: i.unitName }))}
          filterOption={(input: string, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(value, option) => {
            setSelectedOutputValue(value);
            setMemoOutputValue(value);
            setUnitName((option as { label: string; value: number; unitName?: string })?.unitName);
            setSelectedOutputName((option as { label: string; value: number; unitName?: string })?.label);
          }}
        />
        <div onClick={() => setExpand(!isExpand)}>
          {isExpand ? (
            <Button type="link">
              <UpOutlined />
              收起
            </Button>
          ) : (
            <Button type="link">
              <DownOutlined />
              展开
            </Button>
          )}
        </div>
      </Space>
      {data && data.length > 0 && isExpand && (
        <div className={styles.tags} id="tagBox">
          {data.map(item => (
            <div
              key={item.id}
              id={selectedOutputValue === item.id ? 'active' : 'default'}
              className={`${styles.tagItem} ${selectedOutputValue === item.id ? styles.active : ''}`}
              onClick={() => {
                setMemoOutputValue(item.id);
                setSelectedOutputValue(item.id);
                setUnitName(item.unitName);
                setSelectedOutputName(item.name);
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OutputBox;
