import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Input,
  Modal,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  BaseElectricityBillResponse,
  BillPaymentStatusEnumDisplay,
  electricityBillPay,
  getElectricityBillInfo,
  PaymentVoucherRequest,
} from '../../../api/electricity';

import styles from './index.module.scss';
import TransactionVoucher from './components/TransactionVoucher';

const routes = [{ name: '电费核查联缴费' }];

const formatString = 'YYYY-MM-DD';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export interface FormRequest {
  vouchers: PaymentVoucherRequest[];
  description?: string;
}

const ElectricityBillPayment: FC = () => {
  const [infoForm] = Form.useForm();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [data, setData] = useState<BaseElectricityBillResponse>();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [isWarn, setIsWarn] = useState<boolean>(false);
  const [accountBalance, setAccountBalance] = useState<number>(0);

  useEffect(() => {
    if (id) {
      getElectricityBillInfo(id).then(setData);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setAccountBalance(data.payClientAccount.balance);
      infoForm.setFieldsValue({
        billNo: data.billNo,
        paymentStatus: BillPaymentStatusEnumDisplay[data.paymentStatus],
        settlementDate: `${dayjs(data.settlementStartTime).format(formatString)} ~ ${dayjs(data.settlementEndTime)
          .subtract(1, 'days')
          .format(formatString)}`,
        amount: {
          amount: data.amount.amount?.toFixed(2),
          payedAmount: data.amount.payedAmount?.toFixed(2),
          oweAmount: data.amount.oweAmount?.toFixed(2),
        },
        saleClientCode: data.saleClientCode,
        saleClientName: data.saleClientName,
        payClientAccount: {
          name: data.payClientAccount.name,
          balance: data.payClientAccount.balance,
          voucherNumber: data.payClientAccount.voucherNumber,
          usableVoucherNumber: data.payClientAccount.usableVoucherNumber,
        },
      });
    }
  }, [infoForm, data]);

  const onFinish = (value: FormRequest) => {
    if (data && id) {
      const finish = () => {
        setLoading(false);
        const request = {
          saleClientAccountId: data.payClientAccount.id,
          vouchers: value.vouchers,
          description: value.description,
          id,
        };
        electricityBillPay(request).then(() => {
          setLoading(true);
          goList();
        });
      };
      const vouchers = value.vouchers ?? [];
      if (vouchers.length === 0) {
        // 添加警告
        Modal.warning({
          title: '未选择交易凭证或无交易凭证，无法保存',
        });
        return;
      }
      if (isWarn && vouchers.length > 0) {
        const deductionAmount = value.vouchers.map(i => i.amount).reduce((x, y) => x + y, 0);
        const num = Number(data.amount.oweAmount) - Number(deductionAmount);
        Modal.confirm({
          title: `所选交易凭证可用剩余金额不足，还需要扣款${num}元，本次只能扣款${deductionAmount}元，是否继续本次扣款？`,
          onOk: finish,
        });
      } else {
        finish();
      }
    }
  };

  const goList = () => {
    navigate(`/operation/settlementRules/electricityBill`);
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form layout="vertical" form={infoForm} {...formLayout}>
        <SubContent title="电费核查联基础信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item label="电费核查联编号">
                <Space style={{ width: '100%' }} size={8}>
                  <Form.Item name="billNo" style={{ marginBottom: 0 }}>
                    <ShowInput />
                  </Form.Item>
                  <Button type="link" style={{ padding: 0 }}>
                    <Link to={`/operation/settlementRules/electricityBill/electricityBillChargeSchedule/${id}`}>
                      查看电费核查联明细
                    </Link>
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="paymentStatus" label="缴费状态">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="settlementDate" label="结算周期">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['amount', 'amount']} label="应缴金额">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['amount', 'payedAmount']} label="累计已缴金额">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['amount', 'oweAmount']} label="剩余待缴金额">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="客户信息" className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item name="saleClientCode" label="客户编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleClientName" label="客户名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['payClientAccount', 'name']} label="缴费账户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['payClientAccount', 'balance']} label="账户余额">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['payClientAccount', 'voucherNumber']} label="交易凭证总数">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={['payClientAccount', 'usableVoucherNumber']} label="未用完的交易凭证数">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
      </Form>
      <Form layout="vertical" form={form} onFinish={onFinish} {...formLayout}>
        <SubContent title="待扣款的交易凭证">
          <Row className={styles.row_form_item}>
            <Col span={24}>
              <Form.Item name="vouchers" wrapperCol={{ span: 24 }}>
                <TransactionVoucher
                  saleVoucher={data?.payClientAccount.usableVouchers}
                  isWarn={isWarn}
                  setIsWarn={setIsWarn}
                  amount={data?.amount.oweAmount}
                />
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['vouchers']}>
              {({ getFieldValue }) => {
                if (data) {
                  const vouchers = (getFieldValue(['vouchers']) ?? []) as PaymentVoucherRequest[];
                  const setPaymentcount = vouchers.filter(i => i.amount !== undefined).length;
                  const payment = vouchers.map(i => i.amount ?? 0).reduce((a, b) => a + b, 0);
                  const residuePayment = accountBalance - payment;
                  return (
                    <>
                      <Col span={8}>
                        <Form.Item label="剩余可用金额">
                          <ShowInput value={vouchers.length > 0 ? residuePayment : undefined} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="交易凭证合计支付">
                          <ShowInput value={vouchers.length > 0 && setPaymentcount !== 0 ? payment : undefined} />
                        </Form.Item>
                      </Col>
                    </>
                  );
                }
              }}
            </Form.Item>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="其他说明" name="description">
                <Input.TextArea placeholder="请输入" maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button loading={loading} htmlType="submit" type="primary">
            保存
          </Button>
          <Button onClick={goList}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default ElectricityBillPayment;
