import { FC } from 'react';
import ElectricitySettlementRules from './ElectricitySettlementRulesList';

// const routes = [
//   { name: '运营配置' },
//   { name: '结算规则' },
//   { name: '电费结算规则' },
// ];

const SettlementRules: FC = () => {
  // const breadcrumbRoutes = useBreadcrumbRoutes();
  // const [curTabKey, setCurTabKey] = useState<string>('1');

  // const changeTabs = (activeKey:string) => {
  //   setCurTabKey(activeKey)
  // }

  return (
    // <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
    //   {/* <Tabs defaultActiveKey="1"
    //     className={styles.tabs}
    //     onChange={changeTabs}
    //   >
    //     <Tabs.TabPane tab="对外结算" key="1">
    //       {curTabKey === '1' ? <ElectricitySettlementRules filterConditions={'out'} /> : <></>}
    //     </Tabs.TabPane>
    //     <Tabs.TabPane tab="对内结算" key="2">
    //     {curTabKey === '2' ? <ElectricitySettlementRules filterConditions={'in'} /> : <></>}
    //     </Tabs.TabPane>
    //   </Tabs> */}
    //   <ElectricitySettlementRules />
    // </Wrapper>
    <ElectricitySettlementRules />
  );
};

export default SettlementRules;
