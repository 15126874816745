import { Divider } from 'antd';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useMemo } from 'react';
import { isNil } from 'lodash-es';
import { BackgroundColorMap } from '../MediumComparison';

interface Props {
  title?: string;
  num?: number | null;
  isSpecial?: boolean;
  rate?: number | null;
  recentRate?: number | null;
  beforeColor?: BackgroundColorMap;
  afterColor?: BackgroundColorMap;
  recentColor?: BackgroundColorMap;
}

const getRateColor = (num?: number | null) => {
  return isNil(num) ? styles.nullWhite : num === 0 ? styles.defaultWhite : num > 0 ? styles.pulsGreen : styles.minusRed;
};

const CommonInfoCard: React.FC<Props> = ({
  title,
  num,
  isSpecial,
  rate,
  recentRate,
  beforeColor,
  afterColor,
  recentColor,
}) => {
  const rateColor = useMemo(() => {
    return getRateColor(rate);
  }, [rate]);

  const recentRateColor = useMemo(() => {
    return getRateColor(recentRate);
  }, [recentRate]);

  const backColor = [beforeColor, afterColor, recentColor]
    .filter(i => i !== undefined && i !== BackgroundColorMap.default)
    .at(0);

  const getFontColor = () => {
    return backColor === BackgroundColorMap.blue
      ? styles.xxBlue
      : backColor === BackgroundColorMap.green
      ? styles.pulsGreen
      : backColor === BackgroundColorMap.red
      ? styles.minusRed
      : styles.defaultWhite;
  };

  const getBackColor = () => {
    return backColor === BackgroundColorMap.blue
      ? styles.blueBack
      : backColor === BackgroundColorMap.green
      ? styles.greenBack
      : backColor === BackgroundColorMap.red
      ? styles.redBack
      : undefined;
  };

  const getBorderColor = () => {
    return backColor === BackgroundColorMap.blue
      ? styles.xxBlueBack
      : backColor === BackgroundColorMap.green
      ? styles.pulsGreenBack
      : styles.minusRedBack;
  };

  return isSpecial ? (
    <div className={styles.rateCard}>
      <div className={styles.rateStyle}>
        <p className={classNames(styles.titleStyle, styles.rateTitleStyle)}>节能率</p>
        <p className={classNames(styles.numStyle, styles.rateNumStyle, rateColor)}>
          {isNil(rate) ? '--' : (rate * 100).toFixed(2)}%
        </p>
      </div>
      <Divider className={styles.dividerStyles} type="vertical" />
      <div className={styles.rateStyle}>
        <p className={classNames(styles.titleStyle, styles.rateTitleStyle)}>近期节能率</p>
        <p className={classNames(styles.numStyle, styles.rateNumStyle, recentRateColor)}>
          {isNil(recentRate) ? '--' : (recentRate * 100).toFixed(2)}%
        </p>
      </div>
    </div>
  ) : (
    <div
      style={{
        position: 'relative',
      }}
      className={classNames(styles.card, getBackColor())}
    >
      {backColor !== undefined && (
        <>
          <div className={styles.leftTop}>
            <div className={classNames(styles.borderSameStyle, getBorderColor())}></div>
            <div className={classNames(styles.otherBorderSameStyle, getBorderColor())}></div>
          </div>
          <div className={styles.rightTop}>
            <div className={classNames(styles.borderSameStyle, getBorderColor())}></div>
            <div className={classNames(styles.otherBorderSameStyle, getBorderColor())}></div>
          </div>
          <div className={styles.rightBottom}>
            <div className={classNames(styles.borderSameStyle, getBorderColor())}></div>
            <div className={classNames(styles.otherBorderSameStyle, getBorderColor())}></div>
          </div>
          <div className={styles.leftBottom}>
            <div className={classNames(styles.borderSameStyle, getBorderColor())}></div>
            <div className={classNames(styles.otherBorderSameStyle, getBorderColor())}></div>
          </div>
        </>
      )}
      <p className={styles.titleStyle}>{title}</p>
      <p className={classNames(styles.numStyle, getFontColor())}>{isNil(num) ? '--' : num.toFixed(2)}</p>
      {backColor !== undefined && <p className={classNames(getFontColor(), styles.lowerStyle)}>较低</p>}
    </div>
  );
};

export default CommonInfoCard;
