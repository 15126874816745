import { useBreadcrumbRoutes, useTenantPermissions, useUpdate, Wrapper, Empty, Tabs } from '@maxtropy/components';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import Top from './components/Top';
import BaseSetting from './BaseSetting';
import BSA from './BSA';
import { useQuery } from '@/shared/utils/utils';
import { getUetInfo, UetUnitType } from '../../../api/uet';
import { PermissionsType } from '../../../common/permissionsConst';
import PV from './PV';
import Pipeline from './Pipeline';
import MicrogridPowerTopoTab from './MicrogridPowerTopoTab';
import ProductionLoad from './ProductionLoad';
import EnergyWorkingMedium from './EnergyWorkingMedium';
import ChargingStation from './ChargingStation';
import Load from '@/pages/UET/Edit/Load';
import BackUpPower from '@/pages/UET/Edit/BackUpPower';
import BsaNew from '@/pages/UET/Edit/BsaList';
import GasGenerationStation from './GasGenerationStation';
import HVACStation from './HVACStation';

// const routes = [
//   {name: '全域能源管理'},
//   {name: '基础设置'},
//   {name: '全域能源拓扑管理'},
// ];

enum TabKeys {
  BASESETING = 'base-setting',
  PRODUCTIONLOAD = 'production-load',
  BSA = 'bsa',
  PVA = 'pva',
  PV = 'pv',
  PIPELINE = 'pipeline',
  MICROGRIDPOWERTOPO = 'microgridPowerTopo',
  ENERGY_MEDIUM_TOPOLOGY = 'energyMediumTopology',
  CHARGINGSTATION = 'chargingStation',
  // 负载
  LOAD = 'load',
  // 备用电源
  BACKUP = 'backup',
  // 储能站(新)
  BSA_NEW = 'bsaNew',
  // 气体制备站
  GAS_PREPARATION_STATION = 'GAS_PREPARATION_STATION',
  AIR_CONDITION = 'AIR_CONDITION',
}

interface PVEditProps {
  isDetail?: boolean;
}

const { TabPane } = Tabs;

const PVEdit: React.FC<PVEditProps> = ({ isDetail }) => {
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [x, forceUpdate] = useUpdate();

  const permission = useTenantPermissions();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { data: uetInfo } = useQuery(useCallback(() => getUetInfo(id!), [id, x]));

  useEffect(() => {
    if (!routerKey) {
      navigate(`${TabKeys.BASESETING}`, { replace: true });
    }
  }, [id, routerKey, navigate]);

  const publicPath = useMemo(() => {
    return location.pathname.split(id!)[0].slice(0, -1);
  }, [location, id]);

  const onChangeTab = (key: string) => {
    navigate(`${publicPath}/${id}/${key}`);
  };

  const baseTab = (
    <TabPane tab="拓扑基础设定" key={TabKeys.BASESETING}>
      {routerKey === TabKeys.BASESETING ? (
        <BaseSetting info={uetInfo} forceUpdate={forceUpdate} isDetail={isDetail} />
      ) : null}
    </TabPane>
  );

  const productionLoadTab = (
    <TabPane tab="负载/生产线" key={TabKeys.PRODUCTIONLOAD}>
      {routerKey === TabKeys.PRODUCTIONLOAD &&
        ((permission ?? []).includes(PermissionsType.P_LOAD_PROD) ? (
          <ProductionLoad info={uetInfo} isDetail={isDetail} />
        ) : (
          <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
        ))}
    </TabPane>
  );

  const bsaTab = (
    <TabPane tab="储能站" key={TabKeys.BSA}>
      {routerKey === TabKeys.BSA &&
        ((permission ?? []).includes(PermissionsType.P_BSA) ? (
          <BSA info={uetInfo} isDetail={isDetail} />
        ) : (
          <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
        ))}
    </TabPane>
  );
  // 光伏站tab
  const pvTab = (
    <TabPane tab="光伏站" key={TabKeys.PV}>
      {routerKey === TabKeys.PV &&
        ((permission ?? []).includes(PermissionsType.P_PV) ? (
          <PV info={uetInfo} isDetail={isDetail} />
        ) : (
          <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
        ))}
    </TabPane>
  );
  // 管线
  const pipelineTab = (
    <TabPane tab="管线" key={TabKeys.PIPELINE}>
      {routerKey === TabKeys.PIPELINE &&
        ((permission ?? []).includes(PermissionsType.B_LINE_PIPE) ? (
          <Pipeline info={uetInfo} isDetail={isDetail} />
        ) : (
          <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
        ))}
    </TabPane>
  );

  // 微网电能拓扑
  const microgridPowerTopoTab = (
    <TabPane tab="微网电能拓扑" key={TabKeys.MICROGRIDPOWERTOPO}>
      {routerKey === TabKeys.MICROGRIDPOWERTOPO &&
      (permission ?? []).includes(PermissionsType.P_MICROGRIDPOWERTOPOLOGY) ? (
        <MicrogridPowerTopoTab info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 能源介质拓扑
  const EnergyAndWorkingMediumTopoTab = (
    <TabPane tab="能源介质拓扑" key={TabKeys.ENERGY_MEDIUM_TOPOLOGY}>
      {routerKey === TabKeys.ENERGY_MEDIUM_TOPOLOGY && (permission ?? []).includes(PermissionsType.P_EMTMANAGE) ? (
        <EnergyWorkingMedium info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 充电站
  const ChargingStationTab = (
    <TabPane tab="充电站" key={TabKeys.CHARGINGSTATION}>
      {routerKey === TabKeys.CHARGINGSTATION && (permission ?? []).includes(PermissionsType.P_CHARGINGSTATION) ? (
        <ChargingStation info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 负载
  const LoadTab = (
    <TabPane tab="负载" key={TabKeys.LOAD}>
      {routerKey === TabKeys.LOAD && (permission ?? []).includes(PermissionsType.P_LOAD) ? (
        <Load info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 备用电源
  const BackupTab = (
    <TabPane tab="备用电源" key={TabKeys.BACKUP}>
      {routerKey === TabKeys.BACKUP && (permission ?? []).includes(PermissionsType.P_POWERSUPPLY) ? (
        <BackUpPower info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );
  // 储能站(新)
  const bsaTabNew = (
    <TabPane tab="储能站(新)" key={TabKeys.BSA_NEW}>
      {routerKey === TabKeys.BSA_NEW && (permission ?? []).includes(PermissionsType.P_BSA_NEW) ? (
        <BsaNew info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 气体制备站
  const gasGenerationStationTab = (
    <TabPane tab="气体制备站" key={TabKeys.GAS_PREPARATION_STATION}>
      {routerKey === TabKeys.GAS_PREPARATION_STATION && (permission ?? []).includes(PermissionsType.P_GASSTATION) ? (
        <GasGenerationStation info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  // 暖通空调站
  const HVACStationTab = (
    <TabPane tab="暖通空调站" key={TabKeys.AIR_CONDITION}>
      {routerKey === TabKeys.AIR_CONDITION && (permission ?? []).includes(PermissionsType.P_HVACSTATION) ? (
        <HVACStation info={uetInfo} isDetail={isDetail} />
      ) : (
        <Empty style={{ margin: 0, padding: '60px 0' }} description={'暂无功能权限，请联系系统管理员'} />
      )}
    </TabPane>
  );

  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      filters={<Top info={uetInfo} forceUpdate={forceUpdate} />}
      filtersStyle={{
        position: 'sticky',
        top: 0,
        padding: 0,
        // background: '#FFFFFF',
        zIndex: 1000,
        boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.35)',
      }}
      className={styles.content}
    >
      <Tabs activeKey={routerKey} onChange={onChangeTab} className={styles.tabs}>
        {baseTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.PROD_LOAD) && productionLoadTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.MICROGRID_POWER_TOPO) &&
          microgridPowerTopoTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.BSA) && bsaTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.PV_STATION) && pvTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.LINE_PIPE) && pipelineTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.ENERGY_MEDIUM_TOPOLOGY) &&
          EnergyAndWorkingMediumTopoTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.CHARGINGSTATION) && ChargingStationTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.LOAD) && LoadTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.BACKUP_POWER) && BackupTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.BSA_SECOND_EDITION) && bsaTabNew}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.GAS_PREPARATION_STATION) &&
          gasGenerationStationTab}
        {uetInfo?.businessUnits?.find(i => i.businessUnitType === UetUnitType.AIR_CONDITION) && HVACStationTab}
      </Tabs>
    </Wrapper>
  );
};

export default PVEdit;
