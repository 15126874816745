import { UetInfoProps } from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import FixedBottomButton from '@/components/FixedBottomButton';
import LoadModal from '@/pages/UET/Edit/Load/components/LoadModal';
import { LoadType, LoadTypeLabel } from '@/pages/UET/Edit/Load/components/loadModalTypes';
import { List } from '@/pages/UET/Edit/Load/types';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  EllipsisSpan,
  message,
  Modal,
  Table,
  Tag,
  useTenantPermissions,
  useUpdate,
} from '@maxtropy/components';
import { apiV2UetListLoadPost } from '@maxtropy/device-customer-apis-v2';
import { Space } from 'antd';

import { isNil } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

interface Props {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const otherColumns = [
  {
    title: '负载编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={<Tag color={'blue'}>{v}</Tag>} />,
  },
  {
    title: '负载名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '负载类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: LoadType) => <EllipsisSpan value={LoadTypeLabel[v]} />,
  },
  {
    title: '关联回路名称',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const Load: React.FC<Props> = ({ info, isDetail }) => {
  const { id } = useParams<{ id: string }>(); // 获取uetid
  const [state, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentRowInfo, setCurrentRowInfo] = useState<List>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadList, setLoadList] = useState<List[]>();

  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;

  const permission = useTenantPermissions();
  // 新建负载按钮
  const showAddLoadBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_ADDLOAD) && !isDetail;
  }, [isDetail, permission]);
  // // 编辑负载按钮
  const showEditLoadBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_EDITLOAD) && !isDetail;
  }, [isDetail, permission]);

  // 新建负载
  const onAddLoad = () => {
    setModalVisible(true);
  };

  // 编辑负载
  const onEditLoad = (record: List) => {
    setCurrentRowInfo(record);
    setModalVisible(true);
  };

  const onSave = () => {
    const unfinished = loadList?.some(i => !i.id);
    if (unfinished) {
      message.warning('有未保存的负载');
    } else {
      setEdit(false);
    }
  };

  const onCancel = () => {
    Modal.confirm({
      title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
      onOk: () => {
        forceUpdate();
        setEdit(false);
      },
    });
  };

  useEffect(() => {
    if (isNil(id)) return;
    apiV2UetListLoadPost({
      ids: [+id],
    }).then(res => {
      if (res) {
        setLoadList(res.list ?? []);
      }
    });
  }, [id, state]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: List) =>
        showEditLoadBtn && (
          <Button type="link" disabled={btnDisabled} onClick={() => onEditLoad(record)}>
            编辑负载
          </Button>
        ),
    },
  ];

  return (
    <>
      {showAddLoadBtn && (
        <Button type="primary" onClick={onAddLoad} disabled={btnDisabled}>
          <PlusOutlined />
          新建负载
        </Button>
      )}

      <Table style={{ marginTop: 12 }} rowKey="id" dataSource={loadList} columns={columns} pagination={false} />

      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <>
                <Button type="primary" onClick={() => setEdit(true)}>
                  编辑
                </Button>
              </>
            )}
          </>
        )}
      </FixedBottomButton>

      {modalVisible && (
        <LoadModal
          modalVisible={modalVisible}
          setModalVisible={val => setModalVisible(val)}
          ouList={info?.ous}
          currentRowInfo={currentRowInfo}
          setCurrentRowInfo={val => setCurrentRowInfo(val)}
          forceUpdate={() => forceUpdate()}
        />
      )}
    </>
  );
};

export default Load;
