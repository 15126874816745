import ProductionBaseIcon from './assets/productionBase.png';
import workCenterIcon from './assets/workCenter.png';
import workOrderIcon from './assets/productionProcesse.png';
import workStationIcon from './assets/workStation.png';
import styles from './index.module.scss';
import { DataNode } from 'antd/lib/tree';

// 工序enum
export enum WorkProcedureType {
  cut = 1, // 切割工序
  punch = 2, // 打孔工序
  glassPress = 3, // 玻璃压片
}

export const treeDataMock: DataNode[] = [
  {
    key: 'base1cut',
    title: '上海基地',
    icon: <img src={ProductionBaseIcon} alt="" className={styles.iconStyle} />,
    children: [
      {
        key: 'center1cut',
        title: '型材车间',
        icon: <img src={workCenterIcon} alt="" className={styles.iconStyle} />,
        children: [
          {
            key: 'procedure1cut',
            title: '切割工序',
            icon: <img src={workOrderIcon} alt="" className={styles.iconStyle} />,
            children: [
              {
                key: 'station1cut',
                title: '锯切中心',
                icon: <img src={workStationIcon} alt="" className={styles.iconStyle} />,
              },
            ],
          },
          {
            key: 'procedure2punch',
            title: '打孔工序',
            icon: <img src={workOrderIcon} alt="" className={styles.iconStyle} />,
            children: [
              {
                key: 'station2punch',
                title: '加工中心',
                icon: <img src={workStationIcon} alt="" className={styles.iconStyle} />,
              },
            ],
          },
        ],
      },
      {
        key: 'center2glassPress',
        title: '玻璃车间',
        icon: <img src={workCenterIcon} alt="" className={styles.iconStyle} />,
        children: [
          {
            key: 'procedure3glassPress',
            title: '中空玻璃压片工序',
            icon: <img src={workOrderIcon} alt="" className={styles.iconStyle} />,
            children: [
              {
                key: 'station3glassPress',
                title: '中空玻璃加工中心',
                icon: <img src={workStationIcon} alt="" className={styles.iconStyle} />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export interface ChartData {
  ts: number;
  value: number;
  isNormal: boolean;
  status: WorkStatus;
}

export interface ChartDataNode {
  start: number;
  end: number;
  status?: WorkStatus;
  isRest?: boolean;
}

export interface TimeArrItem {
  status: WorkStatus;
  time: number;
  isRest?: boolean;
}
export interface ChartDataRes {
  data: ChartData[];
  timeNode: ChartDataNode[];
  allTimeNode: ChartDataNode[];
  workTimes: number;
  stopOrWaitNode: ChartDataNode[];
  workTimeArr: TimeArrItem[];
}

export interface UpDataItem {
  ts: number;
  value: number;
  status: WorkStatus;
}

export interface ChartDataBeatStandardRes {
  upData: UpDataItem[];
  downData: UpDataItem[];
  allTimeNode?: ChartDataNode[];
}

export enum WorkStatus {
  stop = 1,
  wait = 2,
  feed = 3,
  cut = 4,
  out = 5,
}

export const WorkStatusDisplay = {
  [WorkStatus.stop]: '停机',
  [WorkStatus.wait]: '待机',
  [WorkStatus.feed]: '进料',
  [WorkStatus.cut]: '切割',
  [WorkStatus.out]: '出料',
};

export const PunchWorkStatusDisplay = {
  [WorkStatus.stop]: '停机',
  [WorkStatus.wait]: '待机',
  [WorkStatus.feed]: '进料',
  [WorkStatus.cut]: '打孔',
  [WorkStatus.out]: '出料',
};

export const GlassPressWorkStatusDisplay = {
  [WorkStatus.stop]: '停机',
  [WorkStatus.wait]: '待机',
  [WorkStatus.feed]: '涂胶',
  [WorkStatus.cut]: '合片',
  [WorkStatus.out]: '下片',
};

// 图例数据
export const graphicData = (workProcedureType: WorkProcedureType) => {
  return [
    {
      name: '停机',
      color: 'rgba(93,112,146,0.8)',
      right: 320,
      top: 21,
      bottom: undefined,
      left: undefined,
    },
    {
      name: '待机',
      color: 'rgba(250,173,20,0.8)',
      right: 250,
      top: 21,
      bottom: undefined,
      left: undefined,
    },
    {
      name: workProcedureType === WorkProcedureType.glassPress ? '涂胶' : '进料',
      color: 'rgba(82,231,255,0.8)',
      right: 180,
      top: 21,
      bottom: undefined,
      left: undefined,
    },
    {
      name:
        workProcedureType === WorkProcedureType.cut
          ? '切割'
          : workProcedureType === WorkProcedureType.punch
          ? '打孔'
          : '合片',
      color: 'rgba(45,141,255,0.8)',
      right: 110,
      top: 21,
      bottom: undefined,
      left: undefined,
    },
    {
      name: workProcedureType === WorkProcedureType.glassPress ? '下片' : '出料',
      color: 'rgba(22,221,142,0.8)',
      right: 40,
      top: 21,
      bottom: undefined,
      left: undefined,
    },
    {
      name: '标准',
      color: undefined,
      right: undefined,
      top: undefined,
      bottom: 58,
      left: -20,
    },
    {
      name: '实际',
      color: undefined,
      right: undefined,
      top: undefined,
      bottom: 80,
      left: -20,
    },
  ];
};

// 波动时间
const sameFluctuationTime =
  Math.random() > 0.9 ? Number((Math.random() * (5 * 60 * 1000 * 2) - 5 * 60 * 1000).toFixed(0)) : 0;

// 一次进料、切割、出料时间 + 波动时间
const getOnceWOTime = () => {
  // 进料时间
  let feedTime = 10 * 60 * 1000;
  // 进料波动时间 随机数
  const feedFluctuationTime = sameFluctuationTime;
  // 切割时间
  let cutTime = 30 * 60 * 1000;
  // 切割波动时间 随机数
  const cutFluctuationTime = sameFluctuationTime;
  // 出料时间
  let outTime = 10 * 60 * 1000;
  // 出料波动时间 随机数
  const outFluctuationTime = sameFluctuationTime;

  return {
    onceTime: feedTime + cutTime + outTime + feedFluctuationTime + cutFluctuationTime + outFluctuationTime,
    feedTime: feedTime + feedFluctuationTime,
    cutTime: cutTime + cutFluctuationTime,
    outTime: outTime + outFluctuationTime,
  };
};

// 获取工作后停机或者待机时间
const getStopOrWaitTime = () => {
  // 待机时间
  let waitTime = 10 * 60 * 1000;
  // 待机波动时间 随机数
  const waitFluctuationTime = sameFluctuationTime;
  // 工作后停机时间
  let stopTimeAfterWork = 10 * 60 * 1000;
  // 工作后停机波动时间 随机数
  const stopFluctuationTimeAfterWork = sameFluctuationTime;

  if (Math.random() > 0.6) {
    return {
      status: WorkStatus.stop,
      time: stopTimeAfterWork + stopFluctuationTimeAfterWork,
    };
  }

  return {
    status: WorkStatus.wait,
    time: waitTime + waitFluctuationTime,
  };
};

// 获取进料功率
const getFeedPower = () => {
  return Math.random() > 0.01
    ? Number((12.5 + (Math.random() * 2.5 * 2 - 2.5)).toFixed(2))
    : Number((12.5 + (Math.random() * 3.5 * 2 - 3.5)).toFixed(2));
};
// 获取切割功率
const getCutPower = () => {
  return Math.random() > 0.01
    ? Number((60 + (Math.random() * 10 * 2 - 10)).toFixed(2))
    : Number((60 + (Math.random() * 15 * 2 - 15)).toFixed(2));
};
// 获取出料功率
const getOutPower = () => {
  return Math.random() > 0.01
    ? Number((20 + (Math.random() * 5 * 2 - 5)).toFixed(2))
    : Number((20 + (Math.random() * 10 * 2 - 10)).toFixed(2));
};
// 获取待机功率
const getWaitPower = () => {
  return Number((7.5 + (Math.random() * 5 * 2 - 5)).toFixed(2));
};
// 获取停机功率
const getStopPower = () => {
  return 0;
};

// 一分钟波动
const oneMinuteFluctuationTime =
  Math.random() > 0.9 ? Number((Math.random() * (1 * 60 * 1000 * 2) - 1 * 60 * 1000).toFixed(0)) : 0;

// 2.5分钟波动
const twoHalfMinuteFluctuationTime =
  Math.random() > 0.9 ? Number((Math.random() * (2.5 * 60 * 1000 * 2) - 2.5 * 60 * 1000).toFixed(0)) : 0;

// 一次进料、打孔、出料时间 + 波动时间
const getOncePunchTime = () => {
  // 进料时间
  let feedTime = 2 * 60 * 1000;
  // 进料波动时间 随机数
  const feedFluctuationTime = oneMinuteFluctuationTime;
  // 打孔时间
  let cutTime = 15 * 60 * 1000;
  // 打孔波动时间 随机数
  const cutFluctuationTime = twoHalfMinuteFluctuationTime;
  // 出料时间
  let outTime = 3 * 60 * 1000;
  // 出料波动时间 随机数
  const outFluctuationTime = oneMinuteFluctuationTime;

  return {
    onceTime: feedTime + cutTime + outTime + feedFluctuationTime + cutFluctuationTime + outFluctuationTime,
    feedTime: feedTime + feedFluctuationTime,
    cutTime: cutTime + cutFluctuationTime,
    outTime: outTime + outFluctuationTime,
  };
};

// 获取打孔进料功率
const getPunchFeedPower = () => {
  return Math.random() > 0.01
    ? Number((7.5 + (Math.random() * 2.5 * 2 - 2.5)).toFixed(2))
    : Number((7.5 + (Math.random() * 3.5 * 2 - 3.5)).toFixed(2));
};
// 获取打孔功率
const getPunchCutPower = () => {
  return Math.random() > 0.01
    ? Number((110 + (Math.random() * 10 * 2 - 10)).toFixed(2))
    : Number((110 + (Math.random() * 15 * 2 - 15)).toFixed(2));
};

// 打孔工序出料功率
const getPunchOutPower = () => {
  return Math.random() > 0.01
    ? Number((20 + (Math.random() * 5 * 2 - 5)).toFixed(2))
    : Number((20 + (Math.random() * 10 * 2 - 10)).toFixed(2));
};

const getGlassPressFeedPower = () => {
  return Math.random() > 0.01
    ? Number((100 + (Math.random() * 20 * 2 - 20)).toFixed(2))
    : Number((100 + (Math.random() * 30 * 2 - 30)).toFixed(2));
};
const getGlassPressCutPower = () => {
  return Math.random() > 0.01
    ? Number((130 + (Math.random() * 20 * 2 - 20)).toFixed(2))
    : Number((130 + (Math.random() * 40 * 2 - 40)).toFixed(2));
};

const getGlassPressOutPower = () => {
  return Math.random() > 0.01
    ? Number((30 + (Math.random() * 10 * 2 - 10)).toFixed(2))
    : Number((30 + (Math.random() * 12 * 2 - 12)).toFixed(2));
};

// chart数据
export function chartDataMock(
  date: string,
  type: WorkProcedureType
): {
  data: ChartDataRes;
} {
  //  00:00:00 时间戳
  let timestamp = new Date(date).getTime();
  // 停机时间
  let stopTime = 8 * 60 * 60 * 1000;
  // 工作次数
  let workTimes = 0;
  // 一天中用掉的时间
  let usedTime = stopTime;
  // 时间数组
  const timeArr: TimeArrItem[] = [
    {
      status: WorkStatus.stop,
      time: stopTime,
      isRest: true,
    },
  ];

  // 所有前后时间点
  const allTimeNode: ChartDataNode[] = [
    {
      start: timestamp,
      end: timestamp + stopTime,
      status: WorkStatus.stop,
      isRest: true,
    },
  ];

  const getData = (ts: number, type: WorkProcedureType) => {
    for (let i = 0; i < 100; i++) {
      const { onceTime, feedTime, cutTime, outTime } =
        type === WorkProcedureType.punch ? getOncePunchTime() : getOnceWOTime();
      const { status, time } = getStopOrWaitTime();

      if (usedTime < ts) {
        allTimeNode.push(
          {
            start: usedTime + timestamp,
            end: usedTime + feedTime + timestamp,
            status: WorkStatus.feed,
            isRest: false,
          },
          {
            start: usedTime + feedTime + timestamp,
            end: usedTime + feedTime + cutTime + timestamp,
            status: WorkStatus.cut,
            isRest: false,
          },
          {
            start: usedTime + feedTime + cutTime + timestamp,
            end: usedTime + feedTime + cutTime + outTime + timestamp,
            status: WorkStatus.out,
            isRest: false,
          },
          {
            start: usedTime + feedTime + cutTime + outTime + timestamp,
            end: usedTime + feedTime + cutTime + outTime + time + timestamp,
            status,
            isRest: false,
          }
        );
        usedTime += time + onceTime;
        workTimes++;
        timeArr.push(
          {
            status: WorkStatus.feed,
            time: feedTime,
            isRest: false,
          },
          {
            status: WorkStatus.cut,
            time: cutTime,
            isRest: false,
          },
          {
            status: WorkStatus.out,
            time: outTime,
            isRest: false,
          },
          {
            status,
            time,
            isRest: false,
          }
        );
      } else {
        break;
      }
    }
  };
  // 8:00 - 12:00
  getData(12 * 60 * 60 * 1000, type);

  //中午休息时段 停机
  const secondStopTime = 13.5 * 60 * 60 * 1000 - usedTime;
  timeArr.push({
    status: WorkStatus.stop,
    time: secondStopTime,
    isRest: true,
  });

  allTimeNode.push({
    start: usedTime + timestamp,
    end: usedTime + secondStopTime + timestamp,
    status: WorkStatus.stop,
    isRest: true,
  });

  usedTime += secondStopTime;

  // 13:30 - 18:30
  getData(18.5 * 60 * 60 * 1000, type);

  // 晚上休息时段 停机
  const thirdStopTime = 24 * 60 * 60 * 1000 - usedTime;
  timeArr.push({
    status: WorkStatus.stop,
    time: thirdStopTime,
    isRest: true,
  });

  allTimeNode.push({
    start: usedTime + timestamp,
    end: usedTime + thirdStopTime + timestamp,
    status: WorkStatus.stop,
    isRest: true,
  });

  const data = [];

  const feedLow = type === WorkProcedureType.cut ? 10 : type === WorkProcedureType.punch ? 5 : 80;
  const feedHigh = type === WorkProcedureType.cut ? 15 : type === WorkProcedureType.punch ? 10 : 120;
  const cutLow = type === WorkProcedureType.cut ? 50 : type === WorkProcedureType.punch ? 100 : 110;
  const cutHigh = type === WorkProcedureType.cut ? 70 : type === WorkProcedureType.punch ? 120 : 150;
  const outLow = type === WorkProcedureType.cut ? 15 : type === WorkProcedureType.punch ? 15 : 20;
  const outHigh = type === WorkProcedureType.cut ? 25 : type === WorkProcedureType.punch ? 25 : 40;

  for (let i = 0; i < timeArr.length; i++) {
    for (let j = timestamp; j < timestamp + timeArr[i].time; j += 1000 * 60) {
      const feedPower =
        type === WorkProcedureType.cut
          ? getFeedPower()
          : type === WorkProcedureType.punch
          ? getPunchFeedPower()
          : getGlassPressFeedPower();
      const cutPower =
        type === WorkProcedureType.cut
          ? getCutPower()
          : type === WorkProcedureType.punch
          ? getPunchCutPower()
          : getGlassPressCutPower();
      const outPower =
        type === WorkProcedureType.cut
          ? getOutPower()
          : type === WorkProcedureType.punch
          ? getPunchOutPower()
          : getGlassPressOutPower();
      const value =
        timeArr[i].status === WorkStatus.stop
          ? getStopPower()
          : timeArr[i].status === WorkStatus.wait
          ? getWaitPower()
          : timeArr[i].status === WorkStatus.feed
          ? feedPower
          : timeArr[i].status === WorkStatus.cut
          ? cutPower
          : outPower;

      data.push({
        ts: j,
        value,
        isNormal:
          timeArr[i].status === WorkStatus.stop
            ? true
            : timeArr[i].status === WorkStatus.wait
            ? true
            : timeArr[i].status === WorkStatus.feed
            ? feedLow <= value && value <= feedHigh
            : timeArr[i].status === WorkStatus.cut
            ? cutLow <= value && value <= cutHigh
            : outLow <= value && value <= outHigh,
        status: timeArr[i].status,
      });
    }
    timestamp = timestamp + timeArr[i].time;
  }

  // 停线或者待机时间节点
  const stopOrWaitNode: ChartDataNode[] = allTimeNode.filter(
    i => i.status === WorkStatus.stop || i.status === WorkStatus.wait
  );

  const separated = [];
  const allBeatNode = allTimeNode.filter(
    i => !i.isRest && i.status !== WorkStatus.stop && i.status !== WorkStatus.wait
  );
  for (var i = 0; i < allBeatNode.length; i += 3) {
    separated.push(allBeatNode.slice(i, i + 3));
  }
  // 一个生产节拍。前后时间点
  const timeNode: ChartDataNode[] = separated.map(i => ({
    start: i[0].start,
    end: i[2].end,
  }));
  // 工作时间数组
  const workTimeArr: TimeArrItem[] = timeArr.filter(i => !i.isRest);

  return { data: { data, timeNode, allTimeNode, workTimes, stopOrWaitNode, workTimeArr } };
}

// 获得节拍标准
export function getBeatStandard(
  date: string,
  type: WorkProcedureType
): {
  data: ChartDataBeatStandardRes;
} {
  let timestamp = new Date(date).getTime(); //  00:00:00 时间戳
  const feedTime = type === WorkProcedureType.punch ? 2 * 60 * 1000 : 10 * 60 * 1000; // 进料时间
  const cutTime = type === WorkProcedureType.punch ? 15 * 60 * 1000 : 30 * 60 * 1000; // 切割时间
  const outTime = type === WorkProcedureType.punch ? 3 * 60 * 1000 : 10 * 60 * 1000; // 出料时间
  const stopTime = type === WorkProcedureType.punch ? 10 * 60 * 1000 : 10 * 60 * 1000; // 停机时间

  const upData = []; // 上限
  const downData = []; // 下限

  const timeArr = [
    {
      status: WorkStatus.stop,
      time: 8 * 60 * 60 * 1000,
    },
  ];
  // 一天中用掉的时间
  let usedTime = 8 * 60 * 60 * 1000;

  // 所有前后时间点
  const allTimeNode: ChartDataNode[] = [
    {
      start: timestamp,
      end: timestamp + usedTime,
      status: WorkStatus.stop,
      isRest: true,
    },
  ];

  const getData = (ts: number) => {
    for (let i = 0; i < 100; i++) {
      if (usedTime < ts) {
        allTimeNode.push(
          {
            start: usedTime + timestamp,
            end: usedTime + feedTime + timestamp,
            status: WorkStatus.feed,
            isRest: false,
          },
          {
            start: usedTime + feedTime + timestamp,
            end: usedTime + feedTime + cutTime + timestamp,
            status: WorkStatus.cut,
            isRest: false,
          },
          {
            start: usedTime + feedTime + cutTime + timestamp,
            end: usedTime + feedTime + cutTime + outTime + timestamp,
            status: WorkStatus.out,
            isRest: false,
          },
          {
            start: usedTime + feedTime + cutTime + outTime + timestamp,
            end: usedTime + feedTime + cutTime + outTime + stopTime + timestamp,
            status: WorkStatus.stop,
            isRest: false,
          }
        );
        usedTime += feedTime + cutTime + outTime + stopTime;
        timeArr.push(
          {
            status: WorkStatus.feed,
            time: feedTime,
          },
          {
            status: WorkStatus.cut,
            time: cutTime,
          },
          {
            status: WorkStatus.out,
            time: outTime,
          },
          {
            status: WorkStatus.stop,
            time: stopTime,
          }
        );
      } else {
        break;
      }
    }
  };
  // 8:00 - 12:00
  getData(12 * 60 * 60 * 1000);

  // 中午休息时段 停机
  timeArr.push({
    status: WorkStatus.stop,
    time: 90 * 60 * 1000,
  });

  allTimeNode.push({
    start: usedTime + timestamp,
    end: usedTime + 90 * 60 * 1000 + timestamp,
    status: WorkStatus.stop,
    isRest: true,
  });

  usedTime += 90 * 60 * 1000;

  // 13:30 - 18:30
  getData(18.5 * 60 * 60 * 1000);

  allTimeNode.push({
    start: usedTime + timestamp,
    end: 24 * 60 * 60 * 1000,
    status: WorkStatus.stop,
    isRest: true,
  });

  // 晚上休息时段 停机
  timeArr.push({
    status: WorkStatus.stop,
    time: 24 * 60 * 60 * 1000 - usedTime,
  });

  for (let i = 0; i < timeArr.length; i++) {
    for (let j = timestamp; j < timestamp + timeArr[i].time; j += 1000 * 60) {
      const upValue =
        type === WorkProcedureType.glassPress
          ? timeArr[i].status === WorkStatus.stop
            ? 0
            : timeArr[i].status === WorkStatus.wait
            ? 0
            : timeArr[i].status === WorkStatus.feed
            ? 40
            : timeArr[i].status === WorkStatus.cut
            ? 40
            : 20
          : timeArr[i].status === WorkStatus.stop
          ? 0
          : timeArr[i].status === WorkStatus.wait
          ? 0
          : timeArr[i].status === WorkStatus.feed
          ? 5
          : timeArr[i].status === WorkStatus.cut
          ? 20
          : 10;

      const downValue =
        type === WorkProcedureType.cut
          ? timeArr[i].status === WorkStatus.stop
            ? 0
            : timeArr[i].status === WorkStatus.wait
            ? 0
            : timeArr[i].status === WorkStatus.feed
            ? 10
            : timeArr[i].status === WorkStatus.cut
            ? 50
            : 15
          : type === WorkProcedureType.punch
          ? timeArr[i].status === WorkStatus.stop
            ? 0
            : timeArr[i].status === WorkStatus.wait
            ? 0
            : timeArr[i].status === WorkStatus.feed
            ? 5
            : timeArr[i].status === WorkStatus.cut
            ? 100
            : 15
          : timeArr[i].status === WorkStatus.stop
          ? 0
          : timeArr[i].status === WorkStatus.wait
          ? 0
          : timeArr[i].status === WorkStatus.feed
          ? 80
          : timeArr[i].status === WorkStatus.cut
          ? 110
          : 20;
      upData.push({
        ts: j,
        value: upValue,
        status: timeArr[i].status,
      });
      downData.push({
        ts: j,
        value: downValue,
        status: timeArr[i].status,
      });
    }
    timestamp = timestamp + timeArr[i].time;
  }

  return { data: { upData, downData, allTimeNode } };
}
