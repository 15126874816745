import { V2StationSignboardStationDetailIndicatorChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';
import {
  getInstantaneousFlowChart,
  getInstantaneousPowerChart,
  getPowerToElectricityRatioChart,
  getTotalFlowChart,
  getTotalPowerConsumptionChart,
} from './components/BottomSingleDevice/charts';
import { DatePickerType, IndicatorType } from './type';
import { ChartsDataReq } from './components/BottomSingleDevice';

export interface ParamsOption {
  chartData?: V2StationSignboardStationDetailIndicatorChartValuePostResponse;
  query?: ChartsDataReq;
  baselineConfigData?: {
    granularityType?: DatePickerType | string;
    value?: number;
  }[];
}

export interface DateBtn {
  aggrby: DatePickerType;
}

export interface IndicatorBtn {
  id: IndicatorType;
  btnName?: string;
  unit?: string;
  getChartOption: (value: ParamsOption) => any;
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
}

// 每个指标的配置项
export const indicatorBtns = (): IndicatorBtn[] => {
  return [
    {
      id: IndicatorType.AirPowerToElectricityRatio,
      // btnName: chartData.list?.[0].name,
      // btnName: '气电比',
      // unit: chartData.list?.[0].physicalUnitName,
      getChartOption: getPowerToElectricityRatioChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.AirTotalPowerConsumption,
      // btnName: '用电量',
      // unit: 'kWh',
      getChartOption: getTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.AirTotalFlow,
      // btnName: '总流量',
      // unit: 'kWh',
      getChartOption: getTotalFlowChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.AirInstantaneousPower,
      // btnName: '瞬时功率',
      // unit: 'kWh/m³',
      getChartOption: getInstantaneousPowerChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      id: IndicatorType.AirInstantaneousFlow,
      // btnName: '瞬时流量',
      // unit: 'kWh/m³',
      getChartOption: getInstantaneousFlowChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    // 制氮
    {
      id: IndicatorType.NGPowerToElectricityRatio,
      // btnName: chartData.list?.[0].name,
      // btnName: '气电比',
      // unit: chartData.list?.[0].physicalUnitName,
      getChartOption: getPowerToElectricityRatioChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.NGTotalPowerConsumption,
      // btnName: '用电量',
      // unit: 'kWh',
      getChartOption: getTotalPowerConsumptionChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.NGTotalFlow,
      // btnName: '总流量',
      // unit: 'kWh',
      getChartOption: getTotalFlowChart,
      dateBtn: [{ aggrby: DatePickerType.HOUR }, { aggrby: DatePickerType.DAY }, { aggrby: DatePickerType.MONTH }],
      defaultSelectBtn: { aggrby: DatePickerType.HOUR },
    },
    {
      id: IndicatorType.NGInstantaneousPower,
      // btnName: '瞬时功率',
      // unit: 'kWh/m³',
      getChartOption: getInstantaneousPowerChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
    {
      id: IndicatorType.NGInstantaneousFlow,
      // btnName: '瞬时流量',
      // unit: 'kWh/m³',
      getChartOption: getInstantaneousFlowChart,
      dateBtn: [
        { aggrby: DatePickerType.MINUTE_1 },
        { aggrby: DatePickerType.MINUTE_15 },
        { aggrby: DatePickerType.DAY },
      ],
      defaultSelectBtn: { aggrby: DatePickerType.MINUTE_15 },
    },
  ];
};
