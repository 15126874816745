import { Button, Empty, DatePicker, message, Tag, FormTitle } from '@maxtropy/components';
import { Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { EnergyMediumSceneId, ImgEnumDisplay, UrlEnumDisplay } from '../ProcessingEfficiency';
import styles from './index.module.scss';
import { EnergyMediumSceneIds } from '@/api/energyMedium';
import LossEcharts from '../LossEcharts';
import { DoubleRightOutlined, SettingOutlined } from '@ant-design/icons';
import LossRateSettingModal from '../LossRateSettingModal';
import { isNil } from 'lodash-es';
import { max } from '@/pages/EnergyMediaComparison/utils';
import LossDetailModal from '../LossDetailModal';
import {
  apiV2EnergyCompareGetMediumIndicatorLossDataPost,
  apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost,
  V2EnergyCompareGetMediumIndicatorLossDataPostResponse,
  V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export interface ILossDuringTransAndDisProcessProps {
  processId?: React.Key;
  selectedTopoSceneId?: number;
  selectedProcessName?: string;
  topoId?: number;
}
export type LossDataPostResponse = V2EnergyCompareGetMediumIndicatorLossDataPostResponse['viewVoTos'];
export type LossDataPostItemResponse = Exclude<
  V2EnergyCompareGetMediumIndicatorLossDataPostResponse['viewVoTos'],
  undefined
>[number];

const uetUnitType = 5; // 拓扑类型

const { RangePicker } = DatePicker;

const LossDuringTransAndDisProcess: FC<ILossDuringTransAndDisProcessProps> = props => {
  const { processId, selectedTopoSceneId, selectedProcessName, topoId } = props;
  const [rangeDate, setRangeDate] = useState<[Dayjs, Dayjs]>([
    dayjs(dayjs(), 'x').subtract(30, 'days').startOf('day'),
    dayjs(dayjs(), 'x').endOf('day'),
  ]);
  const [statisticsDataLoading, setStatisticsDataLoading] = useState<boolean>(false);
  const [processStatistics, setProcessStatistics] = useState<LossDataPostResponse>();
  const [lossRateSettingVisible, setLossRateSettingVisible] = useState<boolean>(false);
  const [mediumIndicatorDisplayUnitData, setMediumIndicatorDisplayUnitData] =
    useState<V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse['list']>();

  const [lossDetailVisible, setLossDetailVisible] = useState<boolean>(false);
  const [currentSelectedLossBasicDetail, SetCurrentSelectedLossBasicDetail] = useState<LossDataPostItemResponse>();

  // 单位
  useEffect(() => {
    if (!topoId) return;
    apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
      uetUnitType: uetUnitType,
      uetUnitId: topoId,
    }).then(res => {
      setMediumIndicatorDisplayUnitData(res.list);
    });
  }, [topoId]);

  useEffect(() => {
    if (processId) {
      setStatisticsDataLoading(true);
      apiV2EnergyCompareGetMediumIndicatorLossDataPost({
        processId: Number(processId),
        from: rangeDate[0].startOf('day').valueOf(),
        to: rangeDate[1].endOf('day').valueOf(),
      }).then(res => {
        setProcessStatistics(res.viewVoTos ?? []);
        setStatisticsDataLoading(false);
      });
    }
  }, [processId, rangeDate]);

  const navigateToEnergyMediumDashboard = () => {
    const jumpUrl = UrlEnumDisplay[selectedTopoSceneId as EnergyMediumSceneId];
    window.open(
      `${jumpUrl}?processId=${processId}&timeResolution=D&from=${rangeDate[0]
        .startOf('day')
        .valueOf()}&to=${rangeDate[1].endOf('day').valueOf()}`
    );
  };

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
      const mostDays = 31;
      if (diff >= mostDays) {
        return message.warning(`最大上限为${mostDays}天`);
      }
      setRangeDate([dayjs(beginDate), dayjs(endDate)]);
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const onShowLossDetail = (item: LossDataPostItemResponse) => {
    SetCurrentSelectedLossBasicDetail(item);
    setLossDetailVisible(true);
  };

  const unitDisplay = useCallback(
    (indicatorId?: number) => {
      return (mediumIndicatorDisplayUnitData ?? []).find(i => i.energyMediumIndicatorId === indicatorId)
        ?.displayPhysicalUnitGeneralName;
    },
    [mediumIndicatorDisplayUnitData]
  );

  const dateShow = (filterLossDataList: LossDataPostItemResponse['filterLossDataList']) => {
    if ((filterLossDataList ?? []).map(i => dayjs(i.ts as unknown as string).format('YYYY-MM-DD')).length > 3) {
      return (
        (filterLossDataList ?? [])
          .sort((a, b) => (a.lossRate ?? 0) - (b.lossRate ?? 0))
          ?.map(i => dayjs(i.ts as unknown as string).format('YYYY-MM-DD'))
          .slice(0, 3)
          .join(' 、 ') + '等'
      );
    } else {
      return (filterLossDataList ?? [])
        .sort((a, b) => (a.lossRate ?? 0) - (b.lossRate ?? 0))
        ?.map(i => dayjs(i.ts as unknown as string).format('YYYY-MM-DD'))
        .join(' 、 ');
    }
  };

  return (
    <>
      <FormTitle
        title={'输配节点损耗'}
        style={{ padding: '0px 20px', marginBottom: 18 }}
        extraContent={
          <div className={styles.titleExtraContent}>
            <div className={styles.rateSettingSty} onClick={() => setLossRateSettingVisible(true)}>
              <SettingOutlined color="var(--mx-primary-color)" />
              <span style={{ paddingLeft: 4 }}>损耗比例设置</span>
            </div>
            <Button type="primary" onClick={() => navigateToEnergyMediumDashboard()}>
              详情数据
            </Button>
          </div>
        }
      ></FormTitle>
      <div style={{ padding: '0px 20px' }}>
        <div className={styles.nameAndTimeSty}>
          <div className={styles.currentSelectedName}>
            <span style={{ color: '#ffffff65' }}>当前选择：</span>
            <span className={styles.nameTextStyle} title={selectedProcessName ?? '--'}>
              {selectedProcessName ?? '--'}
            </span>
          </div>
          <div>
            <span>时间：</span>
            <RangePicker
              value={rangeDate}
              disabledDate={disabledDate}
              onChange={onChangeRangePicker}
              picker={'date'}
              allowClear={false}
            />
          </div>
        </div>
        <div style={{ height: 'calc(80vh - 100px)', overflowY: 'auto' }}>
          {processStatistics && processStatistics.length > 0 ? (
            processStatistics.map((item, index) => (
              <div key={index}>
                <div className={styles.cardContent}>
                  <div className={styles.mainEntryCardContent}>
                    <div>
                      <img
                        src={ImgEnumDisplay[item.energyMediumSceneId as EnergyMediumSceneIds]}
                        className={styles.mainCardImg}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className={styles.mainCardTitle}>
                        {item.energyMediumName ?? '--'}（{unitDisplay(item.energyMediumIndicatorId)}）
                      </span>
                      <span className={styles.mainCardValue}>
                        {!isNil(item.entrySum) ? item.entrySum.toFixed(2) : '--'}
                      </span>
                    </div>
                    <div className={styles.tagSty}>
                      <Tag color="info" border="solid">
                        输入
                      </Tag>
                    </div>
                  </div>
                  <div className={styles.mainExitCardContent}>
                    <span className={styles.mainCardTitle}>
                      {item.energyMediumName ?? '--'}（{unitDisplay(item.energyMediumIndicatorId)}）
                    </span>
                    <span className={styles.mainCardValue}>
                      {!isNil(item.exitSum) ? item.exitSum.toFixed(2) : '--'}
                    </span>
                    <div className={styles.tagSty}>
                      <Tag color="success" border="solid">
                        输出
                      </Tag>
                    </div>
                  </div>
                  <div className={styles.restCardContent}>
                    <div className={styles.restCardLossContent} style={{ borderRight: '1px solid #ffffff60' }}>
                      <div className={styles.restCardContentTitle}>
                        损耗量（{unitDisplay(item.energyMediumIndicatorId)}）
                      </div>
                      <div className={styles.restCardContentValue}>
                        {!isNil(item.lossSum) ? item.lossSum.toFixed(2) : '--'}
                      </div>
                    </div>
                    <div className={styles.restCardLossContent}>
                      <div className={styles.restCardContentTitle}>损耗率</div>
                      <div className={styles.restCardContentValue}>
                        {!isNil(item.lossRate) ? (item.lossRate * 100).toFixed(2) + '%' : '--'}
                      </div>
                    </div>
                  </div>
                </div>
                {item.filterLossDataList && item.filterLossDataList?.length > 0 ? (
                  <div className={styles.lossInfoContent}>
                    <span style={{ color: '#E64242' }}>{dateShow(item.filterLossDataList)}</span>
                    <span>
                      【{selectedProcessName ?? '--'}】内【{item.energyMediumName ?? '--'}】上下游损耗率较高，最高达到
                      {max(item.filterLossDataList?.map(i => Number((i.lossRate && i.lossRate * 100)?.toFixed(2))))}%
                    </span>
                    {!isNil(item.lossAmount) && (
                      <span>
                        ，预计损失<span style={{ color: 'red' }}>{item.lossAmount}</span>元
                      </span>
                    )}

                    <Button
                      type="link"
                      onClick={() =>
                        // onShowLossDetail(item.energyMediumName, item.filterLossDataList, item.energyMediumId)
                        onShowLossDetail(item)
                      }
                    >
                      损耗详情
                      <DoubleRightOutlined />
                    </Button>
                  </div>
                ) : (
                  <div className={styles.nolossInfoContent}>
                    <span>
                      【{selectedProcessName ?? '--'}】内【{item.energyMediumName ?? '--'}
                      】上下游未发现明显损耗和跑冒滴漏现象。
                    </span>
                    <Button
                      type="link"
                      onClick={() =>
                        // onShowLossDetail(item.energyMediumName, item.filterLossDataList, item.energyMediumId)
                        onShowLossDetail(item)
                      }
                    >
                      损耗详情
                      <DoubleRightOutlined />
                    </Button>
                  </div>
                )}
                <LossEcharts
                  item={item}
                  statisticsDataLoading={statisticsDataLoading}
                  processId={processId}
                  rangeDate={rangeDate}
                  mediumIndicatorDisplayUnitData={mediumIndicatorDisplayUnitData}
                />
              </div>
            ))
          ) : (
            <Spin spinning={statisticsDataLoading}>
              <Empty style={{ marginTop: '20vh' }} />
            </Spin>
          )}
        </div>
      </div>
      <LossRateSettingModal
        lossRateSettingVisible={lossRateSettingVisible}
        onCancel={() => setLossRateSettingVisible(false)}
      />
      <LossDetailModal
        processId={processId}
        lossDetailVisible={lossDetailVisible}
        onCancel={() => setLossDetailVisible(false)}
        selectedProcessName={selectedProcessName}
        currentSelectedLossBasicDetail={currentSelectedLossBasicDetail}
        mediumIndicatorDisplayUnitData={mediumIndicatorDisplayUnitData}
      />
    </>
  );
};

export default LossDuringTransAndDisProcess;
