import { SubContent, useBreadcrumbRoutes, Wrapper, Button, Form, FormTitle, ShowInput } from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getElectricitySaleContractById } from '../../../api/electricitySalesContract';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementMeterType,
  ElectricitySaleContractSettlementType,
  ElectricitySettlementRulesDetails,
  getElectricitySaleContractSettlementRulesMeterList,
  getElectricitySettlementRulesById,
  HasPreferentialAmount,
  HasPreferentialAmountType,
  SettlementRulesStatusTypeValue,
} from '../../../api/electricitySettlementRules';
import DistributedAllFeedInInfoDetails from './components/DistributedAllFeedInInfoDetails';
import DistributedNewEnergyDetails from './components/DistributedNewEnergyDetails';
import EffectiveMeterForm from './components/EffectiveMeterForm';
import TwoPartSystemAndNewEnergyDetails from './components/TwoPartSystemAndNewEnergyDetails';
import TwoPartSystemDetails from './components/TwoPartSystemDetails';
import UnitarySystemDetails from './components/UnitarySystemDetails';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

const routes = [{ name: '查看详情' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const SettlementRulesDetails: FC = () => {
  const { id } = useParams<{ id: string }>(); // 读取地址栏的id
  const [form] = Form.useForm();
  const [data, setData] = useState<ElectricitySettlementRulesDetails>(); // 当前id的数据
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    if (id) {
      getElectricitySettlementRulesById(id)
        .then(res => {
          setData(res);
          form.setFieldsValue({
            status: SettlementRulesStatusTypeValue[res.status],
            code: res.code,
            name: res.name,
            saleElectricityContractName: res.saleElectricityContractName,
            customerMcid: res.customerName,
            propertyManagementConfigId: res.propertyManagementConfigName,
            saleClientId: res.saleClientName,
            saleElectricityContractId: res.saleElectricityContractName,
            settlementEffectTime: `${dayjs(res.startTime).format('YYYY-MM-DD')}~${dayjs(res.endTime).format(
              'YYYY-MM-DD'
            )}`,
            settlementType: res.settlementType,
            description: res.description,
          });
          if (res.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
            form.setFieldsValue({
              hasCapitalContributionRate: res.onePartInfo?.hasCapitalContributionRate ? '是' : '否',
              hasServiceCharge: res.onePartInfo?.hasServiceCharge,
              hasDiscountedRate: res.onePartInfo?.hasDiscountedRate,
              rateType: res.onePartInfo?.rateType,
              standingRate: res.onePartInfo?.standingRate + '元/kWh',
              timeOfDayRateChecked: res.onePartInfo?.timeOfDayRateChecked,
              summitRate: res.onePartInfo?.summitRate + '元/kWh',
              peakRate: res.onePartInfo?.peakRate + '元/kWh',
              plainRate: res.onePartInfo?.plainRate + '元/kWh',
              valleyRate: res.onePartInfo?.valleyRate + '元/kWh',
            });
            res.onePartInfo?.hasServiceCharge &&
              form.setFieldsValue({ serviceCharge: res.onePartInfo?.serviceCharge + '元/kWh' });
            if (res.onePartInfo?.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: HasPreferentialAmount[res.onePartInfo?.discountedType!] });
              form.setFieldsValue({ totalRateDiscountedRate: res.onePartInfo?.totalRateDiscountedRate + '元' });
              form.setFieldsValue({ unitRateDiscountedRate: res.onePartInfo?.unitRateDiscountedRate + '分' });
              form.setFieldsValue({ totalRateDiscountedRatio: res.onePartInfo?.totalRateDiscountedRatio + '%' });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.onePartInfo?.totalRateDiscountedQuantity + 'kWh',
              });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartInfo?.hasDiscountedRate,
              declarationType: res.twoPartInfo?.declarationType,
              rateType: res.twoPartInfo?.rateType,
              transformerCapacity: res.twoPartInfo?.transformerCapacity,
              unitCapacityRate: res.twoPartInfo?.unitCapacityRate,
              declaredDemand: res.twoPartInfo?.declaredDemand,
              unitDeclaredDemandRate: res.twoPartInfo?.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartInfo?.unitDemandRate,
              standingRate: res.twoPartInfo?.standingRate,
              timeOfDayRateChecked: res.twoPartInfo?.timeOfDayRateChecked,
              summitRate: res.twoPartInfo?.summitRate,
              peakRate: res.twoPartInfo?.peakRate,
              plainRate: res.twoPartInfo?.plainRate,
              valleyRate: res.twoPartInfo?.valleyRate,
              hasLightingTariff: res.twoPartInfo?.hasLightingTariff,
              lightingTariffRatio: res.twoPartInfo?.lightingTariffRatio + '%',
              unitLightingTariffRate: res.twoPartInfo?.unitLightingTariffRate + '元',
              subMeterUnitDemandRate: res.twoPartInfo?.subMeterUnitDemandRate,
              subMeterSummitRate: res.twoPartInfo?.subMeterSummitRate,
              subMeterPeakRate: res.twoPartInfo?.subMeterPeakRate,
              subMeterPlainRate: res.twoPartInfo?.subMeterPlainRate,
              subMeterValleyRate: res.twoPartInfo?.subMeterValleyRate,
              hasSubMeterRateDeduct: res.twoPartInfo?.hasSubMeterRateDeduct,
            });
            if (res.twoPartInfo?.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: HasPreferentialAmount[res.twoPartInfo?.discountedType!] });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartInfo?.totalRateDiscountedRate + '元' });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartInfo?.unitRateDiscountedRate + '分' });
              form.setFieldsValue({ totalRateDiscountedRatio: res.twoPartInfo?.totalRateDiscountedRatio + '%' });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.twoPartInfo?.totalRateDiscountedQuantity + 'kWh',
              });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
            form.setFieldsValue({
              hasServiceCharge: res.distributedNewEnergyInfo?.hasServiceCharge,
              hasDiscountedRate: res.distributedNewEnergyInfo?.hasDiscountedRate,
              timeOfDayRateChecked: res.distributedNewEnergyInfo?.timeOfDayRateChecked,
              summitRate: res.distributedNewEnergyInfo?.summitRate,
              peakRate: res.distributedNewEnergyInfo?.peakRate,
              plainRate: res.distributedNewEnergyInfo?.plainRate,
              valleyRate: res.distributedNewEnergyInfo?.valleyRate,
            });
            if (res.distributedNewEnergyInfo?.hasDiscountedRate) {
              form.setFieldsValue({
                discountedType: HasPreferentialAmount[res.distributedNewEnergyInfo?.discountedType!],
              });
              form.setFieldsValue({
                totalRateDiscountedRate: res.distributedNewEnergyInfo?.totalRateDiscountedRate + '元',
              });
              form.setFieldsValue({
                unitRateDiscountedRate: res.distributedNewEnergyInfo?.unitRateDiscountedRate + '分',
              });
              form.setFieldsValue({
                totalRateDiscountedRatio: res.distributedNewEnergyInfo?.totalRateDiscountedRatio + '%',
              });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distributedNewEnergyInfo?.totalRateDiscountedQuantity + 'kWh',
              });
            }
          } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
            form.setFieldsValue({
              hasServiceCharge: res.distEnergyAllFeedInInfo?.hasServiceCharge,
              hasDiscountedRate: res.distEnergyAllFeedInInfo?.hasDiscountedRate,
              timeOfDayRateChecked: res.distEnergyAllFeedInInfo?.timeOfDayRateChecked,
              summitRate: res.distEnergyAllFeedInInfo?.summitRate,
              peakRate: res.distEnergyAllFeedInInfo?.peakRate,
              plainRate: res.distEnergyAllFeedInInfo?.plainRate,
              valleyRate: res.distEnergyAllFeedInInfo?.valleyRate,
            });
            if (res.distEnergyAllFeedInInfo?.hasDiscountedRate) {
              form.setFieldsValue({
                discountedType: HasPreferentialAmount[res.distEnergyAllFeedInInfo?.discountedType!],
              });
              form.setFieldsValue({
                totalRateDiscountedRate: res.distEnergyAllFeedInInfo?.totalRateDiscountedRate + '元',
              });
              form.setFieldsValue({
                unitRateDiscountedRate: res.distEnergyAllFeedInInfo?.unitRateDiscountedRate + '分',
              });
              form.setFieldsValue({
                totalRateDiscountedRatio: res.distEnergyAllFeedInInfo?.totalRateDiscountedRatio + '%',
              });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.distEnergyAllFeedInInfo?.totalRateDiscountedQuantity + 'kWh',
              });
            }
          } else if (
            res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
          ) {
            form.setFieldsValue({
              hasDiscountedRate: res.twoPartAndEnergyInfo?.hasDiscountedRate,
              declarationType: res.twoPartAndEnergyInfo?.declarationType,
              rateType: res.twoPartAndEnergyInfo?.rateType,
              transformerCapacity: res.twoPartAndEnergyInfo?.transformerCapacity,
              unitCapacityRate: res.twoPartAndEnergyInfo?.unitCapacityRate,
              declaredDemand: res.twoPartAndEnergyInfo?.declaredDemand,
              unitDeclaredDemandRate: res.twoPartAndEnergyInfo?.unitDeclaredDemandRate,
              unitDemandRate: res.twoPartAndEnergyInfo?.unitDemandRate,
              standingRate: res.twoPartAndEnergyInfo?.standingRate,
              timeOfDayRateChecked: res.twoPartAndEnergyInfo?.timeOfDayRateChecked,
              summitRate: res.twoPartAndEnergyInfo?.summitRate,
              peakRate: res.twoPartAndEnergyInfo?.peakRate,
              plainRate: res.twoPartAndEnergyInfo?.plainRate,
              valleyRate: res.twoPartAndEnergyInfo?.valleyRate,
              hasLightingTariff: res.twoPartAndEnergyInfo?.hasLightingTariff,
              lightingTariffRatio: res.twoPartAndEnergyInfo?.lightingTariffRatio + '%',
              unitLightingTariffRate: res.twoPartAndEnergyInfo?.unitLightingTariffRate + '元',
              powerFactorRatioCalculationType:
                res.twoPartAndEnergyInfo?.powerFactorRatioCalculationType === 1
                  ? '无功总电量/有功总电量'
                  : '无功电量/（需量表有功电量+光伏用电量）',
            });
            if (res.twoPartAndEnergyInfo?.hasDiscountedRate) {
              form.setFieldsValue({ discountedType: HasPreferentialAmount[res.twoPartAndEnergyInfo.discountedType!] });
              form.setFieldsValue({ totalRateDiscountedRate: res.twoPartAndEnergyInfo.totalRateDiscountedRate + '元' });
              form.setFieldsValue({ unitRateDiscountedRate: res.twoPartAndEnergyInfo.unitRateDiscountedRate + '分' });
              form.setFieldsValue({
                totalRateDiscountedRatio: res.twoPartAndEnergyInfo.totalRateDiscountedRatio + '%',
              });
              form.setFieldsValue({
                totalRateDiscountedQuantity: res.twoPartAndEnergyInfo?.totalRateDiscountedQuantity + 'kWh',
              });
            }
          } else {
            form.setFieldsValue(undefined);
          }
          getElectricitySaleContractSettlementRulesMeterList(id).then(totalMeterList => {
            if (res.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
              form.setFieldsValue({
                electricityMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
                ),
              });
            } else if (res.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
              form.setFieldsValue({
                electricityMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
                ),
              });
              form.setFieldsValue({
                demandMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.DEMAND
                ),
              });
              form.setFieldsValue({
                subMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.SUBTABLE
                ),
              });
            } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
              form.setFieldsValue({
                electricityMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
                ),
              });
              form.setFieldsValue({
                photovoltaicMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
                ),
              });
            } else if (res.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
              form.setFieldsValue({
                deductPhotovoltaicMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC
                ),
              });
              form.setFieldsValue({
                photovoltaicMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
                ),
              });
            } else if (
              res.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
            ) {
              form.setFieldsValue({
                electricityMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.ELECTRICITY
                ),
              });
              form.setFieldsValue({
                demandMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.DEMAND
                ),
              });
              form.setFieldsValue({
                photovoltaicMeters: totalMeterList.filter(
                  item => item.meterType === ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC
                ),
              });
            } else {
              form.setFieldsValue(undefined);
            }
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      getElectricitySaleContractById(String(data.saleElectricityContractId)).then(res => {
        form.setFieldsValue({
          saleContractEffectTime: `${dayjs(res.startTime).format('YYYY-MM-DD')}~${dayjs(res.endTime).format(
            'YYYY-MM-DD'
          )}`,
        });
      });
    }
  }, [data, form]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout}>
        <FormTitle title={routes[0].name} />
        <SubContent title="基础信息">
          <Row>
            <Col span={8}>
              <Form.Item name="code" label="结算规则编号">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label="结算规则名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="customerMcid" label="关联组织">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name="propertyManagementConfigId" label="所属物业">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleClientId" label="所属客户">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="saleElectricityContractName" label="所属合同">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name="saleContractEffectTime" label="合同有效期">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="settlementEffectTime" label="结算规则生效区间">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="结算规则状态">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
          <Col span={8}>
            <Form.Item label="结算类型" dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
                  return (
                    <div>{ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM]}</div>
                  );
                } else if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
                  return (
                    <div>{ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM]}</div>
                  );
                } else if (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                ) {
                  return (
                    <div>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                        ]
                      }
                    </div>
                  );
                } else if (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                ) {
                  return (
                    <div>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                        ]
                      }
                    </div>
                  );
                } else if (
                  getFieldValue('settlementType') ===
                  ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                ) {
                  return (
                    <div>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                        ]
                      }
                    </div>
                  );
                } else {
                  return <></>;
                }
              }}
            </Form.Item>
          </Col>

          <UnitarySystemDetails />
          <TwoPartSystemDetails />
          <DistributedNewEnergyDetails />
          <DistributedAllFeedInInfoDetails />
          <TwoPartSystemAndNewEnergyDetails />

          <Row>
            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                    <Col span={8} style={{ marginTop: '30px' }}>
                      <Form.Item
                        name="hasCapitalContributionRate"
                        label="是否有公摊系数"
                        extra={
                          <Space size={4} style={{ width: 380 }}>
                            <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                            注：公摊电量需要在核查联生成后，手动在核查联中录入
                          </Space>
                        }
                      >
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
          </Row>

          {/* <Row>
            <Col span={8} >
              <Form.Item
                label="是否有服务费"
                dependencies={['hasServiceCharge']}
              >
                {
                  ({ getFieldValue }) => {
                    return (
                      <>
                        <Col span={8} >
                          {
                            getFieldValue('hasServiceCharge') === true ? '是' : '否'
                          }
                        </Col>
                      </>
                    )
                  }
                }
              </Form.Item>
            </Col>

            <Form.Item label='' dependencies={['hasServiceCharge']}>
              {
                ({ getFieldValue }) => {
                  if (getFieldValue('hasServiceCharge')) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="serviceCharge"
                            label="服务费"
                          >
                            <ShowInput />
                          </Form.Item>
                        </Col>
                      </>
                    )
                  } else {
                    return (
                      <></>
                    )
                  }
                }
              }
            </Form.Item>

          </Row> */}

          <Col span={8}>
            <Form.Item label="是否有优惠金额" dependencies={['hasDiscountedRate']}>
              {({ getFieldValue }) => {
                return (
                  <>
                    <Col span={8} style={{ marginBottom: '30px' }}>
                      {getFieldValue('hasDiscountedRate') === true ? '是' : '否'}
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Col>
          <Row>
            <Form.Item noStyle dependencies={['discountedType', 'hasDiscountedRate']}>
              {({ getFieldValue }) => {
                if (
                  getFieldValue('hasDiscountedRate') &&
                  getFieldValue('discountedType') ===
                    HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT]
                ) {
                  return (
                    <>
                      <Col flex={'200px'}>
                        <Form.Item name="discountedType" label="">
                          <ShowInput />
                        </Form.Item>
                      </Col>
                    </>
                  );
                } else if (
                  getFieldValue('hasDiscountedRate') &&
                  getFieldValue('discountedType') !==
                    HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT]
                ) {
                  return (
                    <Col flex={'200px'}>
                      <Form.Item name="discountedType" label="优惠金额">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return <Col></Col>;
                }
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('hasDiscountedRate') === true &&
                  getFieldValue('discountedType') ===
                    HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO] && (
                    <>
                      <Col span={8}>
                        <Form.Item name="totalRateDiscountedRatio" label={' '}>
                          <ShowInput />
                        </Form.Item>
                      </Col>
                      <Col span={13}></Col>
                    </>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('hasDiscountedRate') === true &&
                  getFieldValue('discountedType') ===
                    HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT] && (
                    <>
                      <Col span={16}>优惠电度数：{getFieldValue('totalRateDiscountedQuantity')}</Col>
                      <Col span={16} style={{ marginLeft: '200px' }}>
                        优惠金额：{getFieldValue('totalRateDiscountedRate')}
                      </Col>
                    </>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('hasDiscountedRate') === true &&
                  getFieldValue('discountedType') ===
                    HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT] && (
                    <>
                      <Col span={8}>
                        <Form.Item name="unitRateDiscountedRate" label={' '}>
                          <ShowInput />
                        </Form.Item>
                      </Col>
                      <Col span={13}></Col>
                    </>
                  )
                );
              }}
            </Form.Item>
          </Row>

          <SubContent title={'生效表计'} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                    <Row style={{ marginBottom: '30px' }}>
                      <Col span={24}>
                        <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                          <EffectiveMeterForm addType={'电量-'} />
                        </Form.Item>
                      </Col>
                    </Row>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                    <>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'需量-'} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'电量-'} />
                          </Form.Item>
                        </Col>
                      </Row>
                      {getFieldValue('hasSubMeterRateDeduct') === true && (
                        <Row style={{ marginBottom: '30px' }}>
                          <Col span={24}>
                            <Form.Item name="subMeters" label="" wrapperCol={{ span: 24 }}>
                              <EffectiveMeterForm addType={'子表-'} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') ===
                    ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                    <>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'电量-'} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'不扣除反向电量-光伏'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') ===
                    ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL && (
                    <>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="deductPhotovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'扣除反向电量-光伏'} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'不扣除反向电量-光伏'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                );
              }}
            </Form.Item>

            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') ===
                    ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY && (
                    <>
                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'需量-'} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'电量-'} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '30px' }}>
                        <Col span={24}>
                          <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                            <EffectiveMeterForm addType={'光伏-'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )
                );
              }}
            </Form.Item>
          </SubContent>

          <Col span={8} style={{ marginTop: '30px' }}>
            <Form.Item name="description" label="备注">
              <ShowInput />
            </Form.Item>
          </Col>
        </SubContent>
        <Space className="sticky-footer-c">
          <Button
            className={styles.button}
            // onClick={() => history.push(`/operation/settlementRules/electricity`)}
          >
            <Link to={'/operation/settlementRules/electricity'}>返回</Link>
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default SettlementRulesDetails;
