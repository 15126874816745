import { Modal, Select, InputNumber, DatePicker, Input } from '@maxtropy/components';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import {
  apiV2CarbonFootprintSupplierListPost,
  apiV2CarbonFootprintEntryUpdatePost,
  apiV2CarbonFootprintEntryDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import debounce from 'lodash/debounce';
import dayjs, { Dayjs } from 'dayjs';
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  id?: number;
  updateFn: () => void;
}

const EditMaterialWarehousing: React.FC<Props> = ({ open, setOpen, id, updateFn }) => {
  const [supplierMapList, setSupplierMapList] = useState<any>([]);

  const [form] = Form.useForm();
  const [prevRemain, setPrevRemain] = useState<number>(0);
  const [prevIn, setPrevIn] = useState<number>(0);

  const [unitCode, setUnitCode] = useState<string>('--');
  useEffect(() => {
    if (isNil(id)) return;
    if (open) {
      apiV2CarbonFootprintEntryDetailPost({ id }).then((res: any) => {
        form.setFieldsValue({
          ...res,
          storageTime: dayjs(res.storageTime),
          remainQuantity: res.inventoryQuantity - res.outboundQuantity,
        });
        setPrevRemain(res.inventoryQuantity - res.outboundQuantity);
        setPrevIn(res.inventoryQuantity);

        setUnitCode(res.unitCode);
        debounceSuppilerList('');
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, open]);

  const debounceSuppilerList = debounce(val => {
    apiV2CarbonFootprintSupplierListPost({ name: val }).then(res => {
      const mapList = res?.list?.map(i => {
        return {
          label: `${i.name}`,
          value: i.id,
        };
      });
      setSupplierMapList(mapList ?? []);
    });
  }, 500);

  const onClose = () => {
    setOpen(false);
    form.setFieldsValue({
      inventoryQuantity: undefined,
      supplierId: undefined,
      carbonFootPrint: undefined,
      storageTime: undefined,
    });
  };

  const onFinish = () => {
    form.validateFields().then(res => {
      if (res.remainQuantity < 0) {
        Modal.warning({ content: '剩余数量不能少于0' });
        return;
      }
      apiV2CarbonFootprintEntryUpdatePost({
        id,
        inventoryQuantity: res.inventoryQuantity,
        supplierId: res.supplierId,
        carbonFootPrint: res.carbonFootPrint,
        storageTime: dayjs(res.storageTime).format('YYYY-MM-DD HH:mm:ss'),
      }).then(() => {
        updateFn();
        setOpen(false);
        form.setFieldsValue({
          inventoryQuantity: undefined,
          supplierId: undefined,
          carbonFootPrint: undefined,
          storageTime: undefined,
        });
      });
    });
  };

  return (
    <Modal
      size="large"
      title="编辑物料入库信息"
      className={styles.content}
      open={open}
      onOk={onFinish}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="入库单号" name="entryNumber">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="物料编码" name="code">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="物料名称" name="name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="规格型号" name="spec">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="入库数量"
              name="inventoryQuantity"
              rules={[
                { required: true, message: '入库数量不能为空' },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('入库数量必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                addonAfter={unitCode}
                max={999999999}
                onChange={value => {
                  form.setFieldsValue({ remainQuantity: prevRemain + Number(value) - prevIn });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.remain}>
              <Form.Item label="剩余数量" name="remainQuantity">
                <InputNumber addonAfter={unitCode} disabled />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <Form.Item
              label="产品碳足迹"
              name="carbonFootPrint"
              rules={[
                { required: true, message: '产品碳足迹不能为空' },
                {
                  validator: (_, value) => {
                    if (value <= 0) {
                      return Promise.reject('产品碳足迹必须大于0');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber addonAfter={`kgCO2e/${unitCode ?? '--'}`} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="供应商" name="supplierId" rules={[{ required: true, message: '供应商不能为空' }]}>
              <Select
                options={supplierMapList}
                showSearch
                optionFilterProp="label"
                onSearch={val => {
                  debounceSuppilerList(val);
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="入库人" name="staff">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="入库时间" name="storageTime" rules={[{ required: true, message: '入库时间不能为空' }]}>
              <DatePicker
                format={'YYYY-MM-DD HH:mm:ss'}
                disabledDate={(currentDate: Dayjs) => {
                  return currentDate.isAfter(dayjs());
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditMaterialWarehousing;
