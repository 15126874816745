import { InputNumber, Space } from 'antd';
import { addNum } from '../../utils';
import { EnergyMediumTableDataItem } from '@/api/universeMeterQuery';
import { Key } from '@maxtropy/components';

interface EleAdjustInputProps {
  data: EnergyMediumTableDataItem[];
  currentRow: EnergyMediumTableDataItem;
  setData: (data: EnergyMediumTableDataItem[]) => void;
  adjustEditItemIds: Key[];
  setAdjustEditItemIds: (ids: Key[]) => void;
}

const inputNumberProps = {
  style: { width: '100%' },
  min: -999999,
  max: 999999,
  step: 0.01,
  precision: 2,
};

const EleAdjustInput: React.FC<EleAdjustInputProps> = ({
  currentRow,
  data,
  setData,
  adjustEditItemIds,
  setAdjustEditItemIds,
}) => {
  const getNewData = (data: EnergyMediumTableDataItem[]) => {
    let currentObject = data.filter(i => i.meterReadingObjectId === currentRow.meterReadingObjectId);
    let current = currentObject.find(i => i.id === currentRow.id);

    const allAfterAdjust = addNum(
      ...currentObject.map(i => addNum(i.fact, i.summitAdjust, i.peakAdjust, i.plainAdjust, i.valleyAdjust))
    );

    // 最终值
    const final = addNum(
      allAfterAdjust,
      current?.summitShare,
      current?.peakShare,
      current?.plainShare,
      current?.valleyShare
    );

    data.forEach(i => {
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        i.final = final;
      }
    });

    return data;
  };

  // 编辑尖调整值
  const onSummitChange = (value: number | null) => {
    let newData = data.map(i => {
      if (i.id === currentRow.id) {
        const summitAdjust = value ?? 0;
        const afterAdjust = addNum(i.fact, summitAdjust, i.peakAdjust, i.plainAdjust, i.valleyAdjust);
        return {
          ...i,
          summitAdjust,
          afterAdjust,
        };
      }
      return i;
    });
    setData(getNewData(newData));
  };

  // 编辑峰调整值
  const onPeakChange = (value: number | null) => {
    let newData = data.map(i => {
      if (i.id === currentRow.id) {
        const peakAdjust = value ?? 0;
        const afterAdjust = addNum(i.fact, peakAdjust, i.summitAdjust, i.plainAdjust, i.valleyAdjust);
        return {
          ...i,
          peakAdjust,
          afterAdjust,
        };
      }
      return i;
    });
    setData(getNewData(newData));
  };

  // 编辑平调整值
  const onPlainChange = (value: number | null) => {
    let newData = data.map(i => {
      if (i.id === currentRow.id) {
        const plainAdjust = value ?? 0;
        const afterAdjust = addNum(i.fact, i.peakAdjust, i.summitAdjust, plainAdjust, i.valleyAdjust);
        return {
          ...i,
          plainAdjust,
          afterAdjust,
        };
      }
      return i;
    });
    setData(getNewData(newData));
  };
  // 编辑谷调整值
  const onValleyChange = (value: number | null) => {
    let newData = data.map(i => {
      if (i.id === currentRow.id) {
        const valleyAdjust = value ?? 0;
        const afterAdjust = addNum(i.fact, i.peakAdjust, i.summitAdjust, i.plainAdjust, valleyAdjust);
        return {
          ...i,
          valleyAdjust,
          afterAdjust,
        };
      }
      return i;
    });
    setData(getNewData(newData));
  };

  return (
    <Space size={8}>
      <InputNumber
        {...inputNumberProps}
        value={currentRow.summitAdjust}
        addonBefore="尖"
        onChange={v => {
          onSummitChange(v);
          setAdjustEditItemIds([...adjustEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.peakAdjust}
        addonBefore="峰"
        onChange={v => {
          onPeakChange(v);
          setAdjustEditItemIds([...adjustEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.plainAdjust}
        addonBefore="平"
        onChange={v => {
          onPlainChange(v);
          setAdjustEditItemIds([...adjustEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.valleyAdjust}
        addonBefore="谷"
        onChange={v => {
          onValleyChange(v);
          setAdjustEditItemIds([...adjustEditItemIds, currentRow.itemId]);
        }}
      />
    </Space>
  );
};

export default EleAdjustInput;
