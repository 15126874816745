// 8位小数点
export const limitDecimalsF = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/;
  return `${value}`.replace(/\B(?=(\d{8})+(?!\d))/g, '').replace(reg, '$1$2.$3');
};
// 截断8位小数
export const limitDecimalsPEight = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d\d\d\d\d\d\d).*$/;
  return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
};

// 2位小数点
export const limitDecimalsFTwo = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d).*$/;
  return `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, '').replace(reg, '$1$2.$3');
};

// 截断2位小数
export const limitDecimalsPTwo = (value: any) => {
  let reg = /^(-)*(\d+)\.(\d\d).*$/;
  return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
