import React, { useEffect, useState } from 'react';
import { BaseInfoContent, Button, Form, Modal, ShowInput } from '@maxtropy/components';
import {
  apiV2CarbonFootprintInputDetailPost,
  V2CarbonFootprintInputDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { Spin } from 'antd';

interface Props {
  onClose: () => void;
  materialId?: number;
}

const DetailModal: React.FC<Props> = ({ onClose, materialId }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<V2CarbonFootprintInputDetailPostResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (materialId) {
      setLoading(true);
      apiV2CarbonFootprintInputDetailPost({ id: materialId })
        .then(res => {
          form.setFieldsValue(res);
          setData(res);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialId]);
  return (
    <Modal title="查看" open={true} onCancel={onClose} footer={<Button onClick={onClose}>关闭</Button>}>
      <Spin spinning={loading}>
        <Form form={form}>
          <BaseInfoContent colNum={2}>
            <Form.Item label="订单号" name="productSheetCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="最后更新时间">
              <ShowInput value={dayjs(data?.updateTime).format('YYYY-MM-DD HH:mm')} />
            </Form.Item>
            <Form.Item label="产出物编码" name="outputProductCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="产出物名称" name="outputProductName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="物料编码" name="materialCode">
              <ShowInput />
            </Form.Item>
            <Form.Item label="物料名称" name="materialName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="投料数量（单位）">
              <ShowInput value={`${data?.inputNumber} ${data?.materialUnitCode}`} />
            </Form.Item>
            <Form.Item label="投料日期">
              <ShowInput value={dayjs(data?.inputTime).format('YYYY-MM-DD')} />
            </Form.Item>
          </BaseInfoContent>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DetailModal;
