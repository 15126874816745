import {
  getUnits,
  OutputListRequest,
  OutputRecord,
  OutputType,
  OutputTypeDisplay,
  OutputUnitType,
  UnitTypeWithUnitList,
} from '@/api/outputConfig';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Paging,
  Select,
  Table,
  useBreadcrumbRoutes,
  useSearchParams,
  Wrapper,
} from '@maxtropy/components';
import { Layout, Space } from 'antd';
import { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { apiV2OutputProductPagePost, apiV2WorkCenterListByOuPost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash';
import StationTree from '../OutputConfig/components/StationTree';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

interface SearchParams extends Omit<OutputListRequest, 'page' | 'size'> {}

const OutputEntryManage: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [outputList, setOutputList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 所有单位
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  const [workCenterHasPermissionList, setWorkCenterHasPermissionList] = useState<any[]>([]);
  const [categoryId, setCategoryId] = useState<Key>(0);

  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态

  useEffect(() => {
    // 获取所有单位
    getUnits().then(res => {
      setUnits(res.list);
    });
    // 获取工作中心 有权限的
    apiV2WorkCenterListByOuPost({}).then(res => {
      if (res && res.list) {
        const mapList = res.list?.map(item => ({
          label: `${item.code}-${item.name}`,
          value: item.id,
        }));
        setWorkCenterHasPermissionList(mapList ?? []);
      }
    });
  }, []);
  const columns = [
    {
      title: '产出物名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '型号',
      dataIndex: 'spec',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工作中心',
      dataIndex: 'workCenterNames',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={isNil(v) ? '--' : v?.map((item: string) => item).join(';')} />,
    },
    {
      title: '单位',
      dataIndex: 'unitCode',
      ellipsis: { showTitle: true },
      render: (value: string, record: OutputRecord) => {
        const unitName = units
          ?.find(u => u?.unitTypeCode === record?.unitTypeCode)
          ?.outputProductUnitList?.filter(v => v?.unitCode === value)?.[0]?.unitName;
        return (
          <EllipsisSpan value={record?.unitTypeCode === OutputUnitType.PIECE ? unitName : `${value} (${unitName})`} />
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: OutputType) => <EllipsisSpan value={OutputTypeDisplay[v]} />,
    },
  ];
  useEffect(() => {
    setLoading(true);
    apiV2OutputProductPagePost({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
      categoryId,
    })
      .then(res => {
        setOutputList(!isNil(res.list) ? res?.list : []);
        setTotalCount(res?.total as number);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTotalCount, searchParams, categoryId]);

  const onFinish = (v: SearchParams) => {
    finish({ ...v });
  };

  const onReset = () => {
    reset();
  };

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string, record: OutputRecord) => {
        return (
          <Space>
            <Button
              type="link"
              onClick={() => {
                window.open(`/energy/output/outputEntryList/entry/${record.id}`, '_blank');
              }}
            >
              产量录入
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree setCategoryId={setCategoryId} />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={styles.filterTop}>
            <CustomFilter form={form} onFinish={(v: SearchParams) => onFinish(v)} onReset={onReset}>
              <Form.Item name="name" label="产出物名称">
                <Input placeholder="请输入" />
              </Form.Item>

              <Form.Item name="type" label="类型">
                <Select
                  placeholder="全部"
                  style={{ width: '100%' }}
                  options={Object.entries(OutputTypeDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
                />
              </Form.Item>

              <Form.Item name="workCenterIds" label="工作中心编码/名称">
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={workCenterHasPermissionList}
                  allowClear
                  showSearch
                  mode="multiple"
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item name="spec" label="型号">
                <Input placeholder="请输入" />
              </Form.Item>
            </CustomFilter>
          </div>
          <div className={styles.operationArea}>
            <Table columns={buildColumns} rowKey="id" dataSource={outputList} />
            <Paging pagingInfo={pagingInfo} />
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default OutputEntryManage;
