import { MeterPeriod } from '@/api/universeMeterQuery';
import { Key } from '@maxtropy/components';
import dayjs from 'dayjs';
import { isNil } from 'lodash';

// 获取保留两位小数的number类型
export function toFixNum(value?: number | null) {
  return isNil(value) ? null : Number(value.toFixed(2));
}

// 相加所传数
export function addNum(...nums: Array<number | null | undefined>) {
  return nums.reduce((pre, cur) => {
    return toFixNum((pre ?? 0) + (cur ?? 0));
  }, 0);
}

// 抄表周期所对应时间
export const MeterPeriodColor = {
  [MeterPeriod.PER_DAY]: '#52C41A',
  [MeterPeriod.PER_WEEK]: '#FAAD14',
  [MeterPeriod.PER_MONTH]: '#4A90E2',
  [MeterPeriod.PER_YEAR]: '#CE90D1',
};

export const MeterPeriodBorderColor = {
  [MeterPeriod.PER_DAY]: 'rgba(82,196,26,0.5)',
  [MeterPeriod.PER_WEEK]: 'rgba(250,173,20,0.5)',
  [MeterPeriod.PER_MONTH]: 'rgba(74,144,226,0.5)',
  [MeterPeriod.PER_YEAR]: 'rgba(206,144,209,0.5)',
};

export const MeterPeriodBackgroundColor = {
  [MeterPeriod.PER_DAY]: 'rgba(82,196,26,0.1)',
  [MeterPeriod.PER_WEEK]: 'rgba(250,173,20,0.1)',
  [MeterPeriod.PER_MONTH]: 'rgba(74,144,226,0.1)',
  [MeterPeriod.PER_YEAR]: 'rgba(206,144,209,0.1)',
};

export function onDownload(url: string, fileName: string, id?: Key) {
  let x = new XMLHttpRequest();
  x.open('POST', `${url}`, true);
  x.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  x.responseType = 'blob';
  x.onload = function () {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    a.click();
  };
  x.send(
    JSON.stringify({
      id,
      timestamp: dayjs().valueOf(),
    })
  );
}

export const formatTime = 'YYYY-MM-DD HH:mm';
