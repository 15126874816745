import { Modal } from '@maxtropy/components';
import SortModalList, { SortModalListModalRef } from './SortModalList';
import { useContext, useRef } from 'react';
import { ProductionBeatConfigContext } from '../../..';

interface SortModalProps {
  sortModalOpen: boolean;
  setSortModalOpen: (open: boolean) => void;
}

const SortModal: React.FC<SortModalProps> = props => {
  const { sortModalOpen, setSortModalOpen } = props;
  const sortModalListModalRef = useRef<SortModalListModalRef>(null);
  const { actionTableData, setActionTableData } = useContext(ProductionBeatConfigContext);

  const onOk = () => {
    setActionTableData?.(sortModalListModalRef.current?.tabDataList || []);
    setSortModalOpen(false);
  };

  return (
    <Modal
      size="small"
      open={sortModalOpen}
      onCancel={() => setSortModalOpen(false)}
      onOk={onOk}
      title="生产动作节拍排序"
      contentStyle={{
        overflowY: 'auto',
        maxHeight: 448,
      }}
    >
      <SortModalList ref={sortModalListModalRef} tabData={actionTableData} />
    </Modal>
  );
};

export default SortModal;
