import {
  Button,
  EllipsisSpan,
  FormTitle,
  SubContent,
  Switch,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
} from '@maxtropy/components';
import {
  V2StationSignboardIndicatorListPostResponse,
  apiV2StationSignboardConfigSavePost,
  apiV2StationSignboardIndicatorListPost,
} from '@maxtropy/device-customer-apis-v2';
import { Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IndexType } from '../utils/const';
import { isNil } from 'lodash-es';
import styles from './index.module.scss';

export type IndexItem = Exclude<V2StationSignboardIndicatorListPostResponse['list'], undefined>[number];

const routes = [{ name: '看板配置' }];

const DashBoardConfig: React.FC = () => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [allIndexList, setAllIndexList] = useState<IndexItem[]>([]);
  const [deviceIndexList, setDeviceIndexList] = useState<IndexItem[]>([]);

  useEffect(() => {
    if (isNil(id) || isNil(type)) return;
    setLoading(true);
    apiV2StationSignboardIndicatorListPost({ stationType: Number(type), stationId: Number(id) })
      .then(res => {
        setAllIndexList((res.list ?? []).filter(k => k.signBoardMonitoringType === IndexType.TOTAL));
        setDeviceIndexList((res.list ?? []).filter(k => k.signBoardMonitoringType === IndexType.NODE));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, type]);

  const columns = [
    {
      title: '监测指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '是否监测',
      dataIndex: 'needsMonitoring',
      ellipsis: { showTitle: true },
      render: (v: boolean, record: any, index: number) => {
        return (
          <Switch
            disabled={record.disabled}
            checked={v}
            onChange={(checked: boolean) => {
              setAllIndexList(
                allIndexList.map((k, i) => {
                  return index === i ? { ...k, needsMonitoring: checked } : k;
                })
              );
            }}
          />
        );
      },
    },
  ];

  const deviceColumns = [
    {
      title: '设备类型',
      dataIndex: 'nodeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '监测指标',
      dataIndex: 'indicatorName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '是否监测',
      dataIndex: 'needsMonitoring',
      ellipsis: { showTitle: true },
      render: (v: boolean, record: any, index: number) => {
        return (
          <Switch
            disabled={record.disabled}
            checked={v}
            onChange={(checked: boolean) => {
              setDeviceIndexList(
                deviceIndexList.map((k, i) => {
                  return index === i ? { ...k, needsMonitoring: checked } : k;
                })
              );
            }}
          />
        );
      },
    },
  ];

  const saveConfig = () => {
    setLoading(true);
    apiV2StationSignboardConfigSavePost({
      stationType: Number(type),
      stationId: Number(id),
      indicatorConfig: [
        ...allIndexList.map(k => ({ indicatorId: k.indicatorId!, needsMonitoring: k.needsMonitoring! })),
        ...deviceIndexList.map(k => ({ indicatorId: k.indicatorId!, needsMonitoring: k.needsMonitoring! })),
      ],
    })
      .then(res => {
        navigate(`/production/manage/gasMachineDashBoard?stationId=${id}&stationType=${type}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="看板配置" />
      <Spin spinning={loading}>
        <SubContent title="总监控指标">
          <Table rowKey="indicatorId" style={{ width: 480 }} columns={columns} dataSource={allIndexList}></Table>
        </SubContent>
        <SubContent title="设备监控指标">
          <Table
            rowKey="indicatorId"
            style={{ width: 600 }}
            columns={deviceColumns}
            dataSource={deviceIndexList}
          ></Table>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button onClick={saveConfig} type="primary">
            保存
          </Button>
          <Button
            onClick={() => {
              navigate(`/production/manage/gasMachineDashBoard?stationId=${id}&stationType=${type}`);
            }}
          >
            取消
          </Button>
        </Space>
      </Spin>
    </Wrapper>
  );
};

export default DashBoardConfig;
