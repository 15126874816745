import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Paging, Table, usePaging, useTenantPermissions, Button } from '@maxtropy/components';
import { Popconfirm, Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { forwardRef, Key, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { NetInfoProp, GenerateDirectionStatus } from '../../../../../api/pv';
import NewGridPoint, { levelListMap } from './NewGridPoint';
import styles from '../index.module.scss';
import DeviceModal from './DeviceModal';
import { Device } from '../../../../../api/device';
import { PermissionsType } from '../../../../../common/permissionsConst';
import CircuitBindModal from './CircuitBindModal';
import { CircuitProps } from '@/api/circuit';

interface Iprops {
  hasPutOn: boolean | undefined;
  circuits?: CircuitProps[];
  listData?: NetInfoProp[];
  isDetail?: boolean;
}
const GridConnectionPoint = forwardRef<NetInfoProp[], Iprops>((props, childRef) => {
  const pagingInfo = usePaging(10);
  const [netVisible, setNetVisible] = useState(false);
  const [deviceVisible, setDeviceVisible] = useState(false);
  const [circuitBindVisible, setCircuitBindVisible] = useState(false);

  const [opType, setOpType] = useState('add');
  const [tableList, setTableList] = useState<NetInfoProp[]>([]);
  const [selectMeterKey, setSelectMeterKey] = useState<number | undefined>();
  const [selectGatewayMeterKey, setSelectGatewayMeterKey] = useState<number | undefined>();
  const [isGatewayMeter, setIsGatewayMeter] = useState(false);
  const currentRow = useRef<NetInfoProp>();
  const currentIndex = useRef<number>(-1);
  const permission = useTenantPermissions();
  // 新增并网点按钮
  const showAddGridBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_GRID_ADD);
  }, [permission]);
  // 移除
  const showRemoveGridBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_GRID_REMOVE);
  }, [permission]);
  // 编辑
  const showEditGridBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_NET_EDIT);
  }, [permission]);
  // 编辑计量电表
  const showEditMeterBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_METER_EDIT);
  }, [permission]);

  const columns: ColumnType<NetInfoProp>[] = [
    {
      title: '序号',
      dataIndex: 'order',
      ellipsis: { showTitle: true },
      render: (v: string, rows: any, index: number) => <EllipsisSpan value={index + 1} />,
    },
    {
      title: '并网点名称',
      dataIndex: 'name',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '并网点地址',
      dataIndex: 'address',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '并网等级',
      dataIndex: 'level',
      render: (v: number) => {
        let info = levelListMap.find(item => item.value === +v);
        return <EllipsisSpan value={info?.label} />;
      },
    },
    {
      title: '并网点回路',
      dataIndex: 'circuitName',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '计量电表',
      dataIndex: 'meter',
      render: (v: Device) => <EllipsisSpan value={v?.name} />,
    },
  ];
  const buildColumns = props.isDetail
    ? columns
    : [
        ...columns,
        {
          title: '操作',
          fixed: 'right' as 'right',
          width: 380,
          render: (_: any, row: NetInfoProp, index: number) => (
            // 光伏站未投放可以移除并网点
            <Space size={16}>
              {!props.hasPutOn && showRemoveGridBtn && (
                <Popconfirm placement="top" title="确定移除并网点? 移除后不可恢复!" onConfirm={() => remove(index)}>
                  <Button type="link">移除并网点</Button>
                </Popconfirm>
              )}
              <Button
                type="link"
                onClick={() => {
                  bindCircuitBtn(row, index);
                }}
              >
                关联回路
              </Button>
              {showEditMeterBtn && (
                <Button
                  type="link"
                  onClick={() => {
                    setIsGatewayMeter(false);
                    editElec(row, index);
                  }}
                >
                  编辑计量电表
                </Button>
              )}
              {showEditGridBtn && (
                <Button
                  type="link"
                  onClick={() => {
                    gridPointsOp('edit', index, row);
                  }}
                >
                  编辑并网点信息
                </Button>
              )}
            </Space>
          ),
        },
      ];
  // 新建/编辑并网点
  const gridPointsOp = (op: string, index: number, row?: NetInfoProp) => {
    setNetVisible(true);
    setOpType(op);
    currentRow.current = row;
    currentIndex.current = index;
  };
  // 关联回路
  const bindCircuitBtn = (row: NetInfoProp, index: number) => {
    currentRow.current = row;
    currentIndex.current = index;
    setCircuitBindVisible(true);
  };
  // 移除并网点
  const remove = (index: number) => {
    tableList.splice(index, 1);
    setTableList([...tableList]);
  };
  // 编辑计量电表
  const [circuitId, setCircuitId] = useState<Key>();
  const editElec = (row: NetInfoProp, index: number) => {
    setDeviceVisible(true);
    if (row.meter) {
      setSelectMeterKey(row.meter.id);
    } else {
      setSelectMeterKey(undefined);
    }
    currentIndex.current = index;
    setCircuitId(row.circuitId);
  };

  // 回路
  const getBindCircuitInfo = (circuitInfo?: CircuitProps) => {
    setCircuitBindVisible(false);
    let rowInfo = tableList[currentIndex.current];
    // 回路换绑, 清空计量电表
    if (circuitInfo?.id !== rowInfo.circuitId) {
      const key = isGatewayMeter ? 'gatewayMeter' : 'meter';
      rowInfo[key] = undefined;
      rowInfo[(key + 'Id') as 'meterId' | 'gatewayMeterId'] = undefined;
      rowInfo[key === 'gatewayMeter' ? 'gatewayMeterGenerateDirection' : 'meterGenerateDirection'] = undefined;
    }
    rowInfo.circuitId = circuitInfo?.id;
    rowInfo.circuitName = circuitInfo?.name;

    setTableList([...tableList]);
  };
  // 已绑定/已选择的回路
  const hasSelectsCircuitIds = useMemo(() => {
    return tableList.filter(item => item.circuitId).map(item => item.circuitId!);
  }, [tableList]);
  // 获取计量表信息
  const getMeterInfo = (row?: Device) => {
    console.log(row);
    const key = isGatewayMeter ? 'gatewayMeter' : 'meter';
    if (row && currentIndex.current > -1) {
      tableList[currentIndex.current][key] = row;
      tableList[currentIndex.current][(key + 'Id') as 'meterId' | 'gatewayMeterId'] = row.id;
      tableList[currentIndex.current][
        key === 'gatewayMeter' ? 'gatewayMeterGenerateDirection' : 'meterGenerateDirection'
      ] = GenerateDirectionStatus.FORWARD;
    } else {
      tableList[currentIndex.current][key] = undefined;
      tableList[currentIndex.current][(key + 'Id') as 'meterId' | 'gatewayMeterId'] = undefined;
      tableList[currentIndex.current][
        key === 'gatewayMeter' ? 'gatewayMeterGenerateDirection' : 'meterGenerateDirection'
      ] = undefined;
    }

    setTableList([...tableList]);
    setDeviceVisible(false);
  };
  const onConfirm = (v: NetInfoProp) => {
    if (opType === 'add') {
      setTableList([...tableList, v]);
    } else {
      let current = tableList[currentIndex.current];
      tableList[currentIndex.current] = { ...current, ...v };
      setTableList([...tableList]);
    }
    setNetVisible(false);
  };
  useImperativeHandle(childRef, () => tableList);
  useEffect(() => {
    if (props.listData && props.listData.length > 0) {
      setTableList(props.listData);
    }
  }, [props.listData]);

  // 是否编辑
  // const onForwardOrReverseIsEdit = (index: number) => {
  //   let current = tableList[index];
  //   if (current.isForwOrRevEdit) {
  //     // 点击保存
  //     tableList[index] = { ...current, isForwOrRevEdit: false };
  //   } else {
  //     // 点击编辑
  //     tableList[index] = { ...current, isForwOrRevEdit: true };
  //   }
  //   setTableList([...tableList]);
  // };

  // const onForwardOrReverse = (e: RadioChangeEvent, index: number, meterType: string) => {
  //   let current = tableList[index];
  //   if (meterType === 'meter') {
  //     tableList[index] = { ...current, meterGenerateDirection: e.target.value };
  //   }
  //   if (meterType === 'gatewayMeter') {
  //     tableList[index] = {
  //       ...current,
  //       gatewayMeterGenerateDirection: e.target.value,
  //     };
  //   }
  //   setTableList([...tableList]);
  // };

  return (
    <>
      {!props.hasPutOn && showAddGridBtn && (
        <div className={styles.newNetBtn}>
          <Button type="dashed" onClick={() => gridPointsOp('add', -1)}>
            <PlusOutlined />
            新建并网点
          </Button>
          {Tips}
        </div>
      )}
      <Table
        sticky
        rowKey={record => `${record.id}_${record.name}`}
        scroll={{ x: 1000 }}
        columns={buildColumns}
        dataSource={tableList}
      />
      <Paging pagingInfo={pagingInfo} />
      {/* <Row gutter={36} style={{ padding: 16 }}>
        {tableList.map((item, index) => (
          <Col span={6} style={{ marginBottom: 32 }}>
            <Card
              bordered={false}
              size="small"
              className={styles.CardStyle}
              title={item.name}
              extra={
                <Button type="link" onClick={() => onForwardOrReverseIsEdit(index)}>
                  {item.isForwOrRevEdit ? '保存' : '编辑'}
                </Button>
              }
            >
              <div className={styles.topLeftBorder}></div>
              <div className={styles.bottomLeftBorder}></div>
              <div className={styles.LeftBlueBorder}></div>
              <div className={styles.topRightBorder}></div>
              <div className={styles.bottomRightBorder}></div>
              <div className={styles.rightBlueBorder}></div>
              <div className={styles.topBorder}></div>
              <div className={styles.bottomBorder}></div>
              <div className={styles.radioStyle} style={{ marginBottom: 10 }}>
                <p style={{ margin: 10 }}>计量电表光伏发电方向</p>
                <Radio.Group
                  disabled={!((item.meter?.id || item.meterId) && item.isForwOrRevEdit)}
                  value={item.meterGenerateDirection}
                  onChange={e => onForwardOrReverse(e, index, 'meter')}
                >
                  <Radio value={GenerateDirectionStatus.FORWARD}>正向</Radio>
                  <Radio value={GenerateDirectionStatus.REVERSE}>反向</Radio>
                </Radio.Group>
              </div>
              <div className={styles.radioStyle}>
                <p style={{ margin: 10 }}>关口表光伏发电方向</p>
                <Radio.Group
                  disabled={!((item.gatewayMeter?.id || item.gatewayMeterId) && item.isForwOrRevEdit)}
                  value={item.gatewayMeterGenerateDirection}
                  onChange={e => onForwardOrReverse(e, index, 'gatewayMeter')}
                >
                  <Radio value={GenerateDirectionStatus.FORWARD}>正向</Radio>
                  <Radio value={GenerateDirectionStatus.REVERSE}>反向</Radio>
                </Radio.Group>
              </div>
            </Card>
          </Col>
        ))}
      </Row> */}
      {/* 并网点弹框 */}
      {netVisible && (
        <NewGridPoint
          opType={opType}
          row={{ ...currentRow.current }}
          gridList={tableList}
          onConfirm={onConfirm}
          onCancel={() => setNetVisible(false)}
        />
      )}
      {/* 计量电表设备弹框 */}
      <DeviceModal
        circuitId={circuitId}
        deviceId={isGatewayMeter ? selectGatewayMeterKey : selectMeterKey}
        onConfirm={getMeterInfo}
        onCancel={() => setDeviceVisible(false)}
        visible={deviceVisible}
        isGatewayMeter={isGatewayMeter}
      />
      {/* 关联回路弹框 */}
      {circuitBindVisible && (
        <CircuitBindModal
          selectedIds={hasSelectsCircuitIds}
          row={{ ...currentRow.current }}
          onConfirm={getBindCircuitInfo}
          onCancel={() => setCircuitBindVisible(false)}
        />
      )}
    </>
  );
});
const Tips = (
  <div className={styles.tipsBox}>
    <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
    <div className={styles.tips}>
      <div className={styles.tipsText}>
        注意，创建并网点后可为并网点绑定对应的进线回路，并添加回路中计量电表作为并网点计量电表，该电表将影响并网点发电量和消纳收益计算
      </div>
    </div>
  </div>
);
export default GridConnectionPoint;
