import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useAsync,
  Form,
  Input,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { Col, Row, Space, TreeSelect } from 'antd';
import { StoreValue } from 'antd/es/form/interface';
import { RuleObject } from 'antd/es/form';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MemberSelect from '@/shared/components/MemberSelect';
import { CommonUserInfo } from '@/shared/components/MemberSelect/interface';
import { OuStaffType } from '@/shared/const';
import { getOrganization, OrganizationResponse } from '../../../api/customer-user';
import {
  addOu,
  getOuInfo,
  getStaffInfo,
  OuInfoProps,
  queryOuStaffList,
  queryOuStaffPage,
  queryOuTypeList,
  queryUserList,
  updateOu,
} from '../../../api/ou';
import TextOrSelect from '../components/TextOrSelect';
import usePageStatus, { PageStatus, PageStatusDisplay } from '../hooks/usePageStatus';
import styles from './index.module.scss';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const node: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data?.hasPermission,
    };
    return node;
  });
};

const membersValidator = (_: RuleObject, value: StoreValue) => {
  const hasAdmin = (value ?? []).some((item: any) => item.staffType === OuStaffType.ADMINISTRATOR);
  if (!hasAdmin) {
    return Promise.reject(new Error('最少选择一个管理员'));
  } else {
    return Promise.resolve();
  }
};

interface EditProductionUnitProps {}

const EditProductionUnit: React.FC<EditProductionUnitProps> = props => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState<DefaultOptionType[]>([]);
  const { id } = useParams<{ id: string }>();
  const pageStatus = usePageStatus();
  const [mcid, setMcid] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [detail, setDetail] = useState<OuInfoProps>();
  const tempMembersIds = useRef<number[]>([]);
  const [staffInfo, setStaffInfo] = useState<CommonUserInfo>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const routes = [
    // { name: "Iot配置" },
    // { name: "运营单元" },
    // { name: "单元管理" },
    { name: PageStatusDisplay[pageStatus] + '单元' },
  ];

  const types = useAsync(queryOuTypeList, []);

  console.log('types', types);

  const isEdit = pageStatus === PageStatus.UPDATE;
  const isDetail = pageStatus === PageStatus.DETAIL;

  useEffect(() => {
    if (pageStatus === PageStatus.CREATE) {
      getStaffInfo().then(info => {
        const staffInfo = { ...info, id: info.id, unbindable: true };
        setStaffInfo(staffInfo);
        form.setFieldsValue({ members: staffInfo ? [staffInfo] : [] });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageStatus]);

  useEffect(() => {
    if (!id) return;
    if (isEdit) {
      Promise.all([getOuInfo(id), queryOuStaffList(id)])
        .then(([info, members]) => {
          setDetail(info);
          form.setFieldsValue({
            name: info.name,
            ouTypeId: info.ouTypeName,
            mcid: info.customerName,
            directorName: info.directorName,
            directorPhone: info.directorPhone,
            remark: info.remark,
            members: members.list ?? [],
          });
          tempMembersIds.current = (members.list ?? []).map(item => item.id);
          setMcid(info.mcid);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      getOuInfo(id)
        .then(info => {
          setDetail(info);
          form.setFieldsValue({
            name: info.name,
            ouTypeId: info.ouTypeName,
            mcid: info.customerName,
            directorName: info.directorName,
            directorPhone: info.directorPhone,
            remark: info.remark,
          });
          setMcid(info.mcid);
        })
        .catch(error => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit]);

  useEffect(() => {
    getOrganization().then(organization => {
      if (organization) {
        setOrganization(formatTreeData([organization]));
      } else {
        setOrganization([]);
      }
    });
  }, []);

  const userDataSource = useMemo(() => {
    if (!mcid) {
      return () => Promise.resolve({ list: [], page: 1, size: 10, total: 0 });
    } else {
      return (params: any) => queryUserList({ ...params });
    }
  }, [mcid]);

  const memberDataSource = useMemo(() => {
    if (!id) {
      return () => Promise.resolve({ list: [], page: 1, size: 10, total: 0 });
    } else {
      return (params: any) => queryOuStaffPage({ request: id, ...params });
    }
  }, [id]);

  const handleMcidChange = (value: string) => {
    setMcid(value);
    form.setFieldsValue({ members: staffInfo ? [staffInfo] : [] });
  };

  const onFinish = (value: any) => {
    let { members = [], ...rest } = value;
    const add = members as CommonUserInfo[];
    // console.log(value)
    // const ids = (members as CommonUserInfo[]).filter(item => item.id).map(item => item.id);
    const adminIds = add.filter(item => item.staffType === OuStaffType.ADMINISTRATOR).map(item => item.id);
    const memberIds = add.filter(item => item.staffType === OuStaffType.MEMBER).map(item => item.id);
    // const cancelIds = tempMembersIds.current.filter(id => !ids.includes(id));

    if (isEdit && id) {
      updateOu({
        id: id,
        name: rest.name,
        directorName: rest.directorName,
        directorPhone: rest.directorPhone,
        remark: rest.remark,
        memberIds,
        adminIds,
      })
        .then(() => {
          navigate(`/device/ou/production-unit`);
        })
        .catch(error => {
          console.error('addOu', error);
        });
    } else {
      if (types.length === 1) {
        rest = { ...rest, ouTypeId: types[0].id };
      }
      addOu({ ...rest, adminIds, memberIds })
        .then(() => {
          navigate(`/device/ou/production-unit`);
        })
        .catch(error => {
          console.error('addOu', error);
        });
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <SubContent>
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="名称"
                rules={[
                  { required: !isDetail, message: '请输入名称' },
                  { max: 30, message: '名称不超过30个字符' },
                ]}
              >
                {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
              </Form.Item>
            </Col>
            {types.length !== 1 && (
              <Col span={8}>
                <Form.Item name="ouTypeId" label="类型" rules={[{ required: !isDetail }]}>
                  {isEdit || isDetail ? (
                    <ShowFormItem />
                  ) : (
                    <TextOrSelect
                      placeholder="请输入"
                      showSearch
                      optionFilterProp="label"
                      options={types.map(item => ({ label: item.name, value: item.id }))}
                    />
                  )}
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item name="mcid" label="所属组织" rules={[{ required: !isDetail, message: '请选择所属组织' }]}>
                {isEdit || isDetail ? (
                  <ShowFormItem />
                ) : (
                  <TreeSelect treeData={organization} placeholder="请选择" onChange={handleMcidChange} />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="directorName" label="负责人" rules={[{ max: 6, message: '负责人不超过6个字符' }]}>
                {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="directorPhone"
                label="负责人联系方式"
                rules={[{ max: 15, message: '负责人联系方式不超过15个字符' }]}
              >
                {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="remark" label="备注" rules={[{ max: 50, message: '备注不超过50个字符' }]}>
                {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                validateFirst
                name="members"
                label="管理员/成员"
                rules={
                  isEdit
                    ? []
                    : [
                        { required: !isDetail, type: 'array', message: '请选择管理员/成员' },
                        { validator: membersValidator },
                      ]
                }
                wrapperCol={{ span: 24 }}
              >
                <MemberSelect userDataSource={userDataSource} memberDataSource={memberDataSource} disabled={isDetail} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Row className="sticky-footer">
          <Space size={8}>
            {pageStatus !== PageStatus.DETAIL && (
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            )}
            <Button onClick={() => navigate(`/device/ou/production-unit`)}>{isDetail ? '返回' : '取消'}</Button>
          </Space>
        </Row>
      </Form>
    </Wrapper>
  );
};

const ShowFormItem: React.FC<{ value?: string }> = ({ value }) => <div>{value ? value : '--'}</div>;

export default EditProductionUnit;
