import { CircuitType, CircuitTypeFormat } from '@/api/circuit';
import { CustomFilter, EllipsisSpan, Form, Input, Paging, Select, Table, useSearchParams } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, Key, useEffect, useState } from 'react';
import { AddEnergyConsEquipmentModalContentProps } from '../type';

import styles from './index.module.scss';
import {
  V2EnergyAnalysisUnitConfGroupCircuitSearchPostRequest,
  V2EnergyAnalysisUnitConfGroupCircuitSearchPostResponse,
  apiV2EnergyAnalysisUnitConfGroupCircuitSearchPost,
} from '@maxtropy/device-customer-apis-v2';

export type UnitConfGroupCircuitResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupCircuitSearchPostResponse['list'],
  undefined
>[number];

const columns: ColumnType<UnitConfGroupCircuitResponse>[] = [
  {
    title: '总计量表设备',
    dataIndex: ['gaugeHost', 'name'],
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '当前所属回路',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '回路类型',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: CircuitType) => <EllipsisSpan value={CircuitTypeFormat[v]} />,
  },
];

interface SearchParams extends Omit<V2EnergyAnalysisUnitConfGroupCircuitSearchPostRequest, 'page, size'> {}

const CircuitDeviceModalContent: FC<AddEnergyConsEquipmentModalContentProps> = ({
  id,
  ouItems,
  selectKeys,
  onChangeSelectKeys,
}) => {
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;

  const [loading, setLoading] = useState<boolean>(false);

  const [circuits, setCircuits] = useState<UnitConfGroupCircuitResponse[]>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiV2EnergyAnalysisUnitConfGroupCircuitSearchPost({
        ...searchParams,
        ouIds: searchParams.ouIds ?? (ouItems ?? []).map(i => i.id as number),
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      })
        .then(data => {
          setCircuits((data.list ?? []) as unknown as UnitConfGroupCircuitResponse[]);
          setTotalCount(data.total as number);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, searchParams, setTotalCount, ouItems]);

  const onFinish = (v: SearchParams) => {
    finish({ ...v });
  };

  const onReset = () => {
    reset();
  };

  const filters = (
    <CustomFilter onFinish={onFinish} colSpan={8} form={form} onReset={onReset}>
      <Form.Item name="circuitName" label="回路名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="circuitType" label="回路类型">
        <Select
          placeholder="请选择"
          allowClear
          options={[
            { value: CircuitType.GRID_BILLING_CIRCUIT, label: CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT] },
            { value: CircuitType.OUTGOING_CIRCUIT, label: CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT] },
            { value: CircuitType.INCOMING_CIRCUIT, label: CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT] },
            { value: CircuitType.BUS_COUPLER_CIRCUIT, label: CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT] },
            { value: CircuitType.TRANSFORMER_CIRCUIT, label: CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT] },
          ]}
        />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <Select
          placeholder="请选择"
          allowClear
          optionFilterProp="label"
          mode="multiple"
          options={(ouItems ?? []).map(i => ({ value: i.id, label: i.name }))}
        />
      </Form.Item>
    </CustomFilter>
  );

  const onSelectChange = (rowKeys: Key[]) => {
    const currentPageIds = circuits.map(circuit => circuit?.gaugeHost?.id);
    onChangeSelectKeys?.([...(selectKeys ?? []).filter(id => !currentPageIds.includes(id)), ...(rowKeys as number[])]);
  };

  const rowSelection = {
    hideSelectAll: true,
    getCheckboxProps: (record: UnitConfGroupCircuitResponse) => ({
      disabled: !record.staffHasOuAuthority,
    }),
    onChange: onSelectChange,
    selectedRowKeys: selectKeys ?? [],
  };

  return (
    <>
      {filters}
      <div className={styles.table_text}>
        <Space>
          <span style={{ fontWeight: 700, fontSize: '16px' }}>
            已选择<span className={styles.red}>{(selectKeys ?? []).length}</span>项
          </span>
          <span>仅显示已配置总计量表的回路</span>
        </Space>
      </div>
      <Table
        loading={loading}
        pagination={false}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        dataSource={circuits}
        rowKey={record => record?.gaugeHost?.id as number}
        scroll={{ y: 300 }}
        columns={columns}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default CircuitDeviceModalContent;
