import {
  BaseInfoContent,
  Button,
  EllipsisSpan,
  Form,
  FormTitle,
  ShowInput,
  SubContent,
  Table,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { useNavigate, useParams } from 'react-router-dom';
import {
  apiV2HvacNodeDetailPost,
  apiV2HvacNodeIndicatorConfigListPost,
  V2HvacNodeIndicatorConfigListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { useState } from 'react';
import EditModal from './EditModal';
import DataLinkModal from './DataLinkModal';
import { ColumnType } from 'antd/es/table';
import { cloneDeep } from 'lodash-es';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { DataPropertyType, DataPropertyTypeDisplay } from '@/shared/types';

type DataItem = Exclude<V2HvacNodeIndicatorConfigListPostResponse['list'], undefined>[number];

enum MeasurementType {
  CUMULATIVE = 1,
  INSTANTANEOUS,
}

const MeasurementTypeDisplay = {
  [MeasurementType.CUMULATIVE]: '累积量',
  [MeasurementType.INSTANTANEOUS]: '瞬时量',
};

const HVACNodeIndicatorConfig = () => {
  const pageName = '节点指标配置详情';
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id, stationId, hvacId } = useParams<{ id: string; stationId: string; hvacId: string }>();
  const [detailForm] = Form.useForm();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editData, setEditData] = useState<DataItem>();
  const [dataLinkModalOpen, setDataLinkModalOpen] = useState(false);
  const [dataLinkData, setDataLinkData] = useState<DataItem>();
  const [update, updateFn] = useUpdate();
  const hasEditPermission = useHasPermission(PermissionsType.B_HVAC_STATION_PROPERTY_CONFIG_EDIT);
  const hasDataLinkPermission = useHasPermission(PermissionsType.B_HVAC_STATION_PROPERTY_CONFIG_DATALINK);

  const { data: detailData } = useRequest(async () => {
    const res = await apiV2HvacNodeDetailPost({
      nodeId: Number(id),
    });
    detailForm.setFieldsValue(res);
    return res;
  });

  const { loading, data: tableData } = useRequest(
    () => {
      return apiV2HvacNodeIndicatorConfigListPost({
        nodeId: Number(id),
      }).then(res => res.list);
    },
    {
      refreshDeps: [update],
    }
  );

  const columns: ColumnType<DataItem>[] = [
    {
      title: '监控指标',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '指标类型',
      dataIndex: 'dataPropertyType',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={DataPropertyTypeDisplay[v as DataPropertyType]}></EllipsisSpan>,
    },
    {
      title: '瞬时量/累积量',
      dataIndex: 'measurementType',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={MeasurementTypeDisplay[v as MeasurementType]}></EllipsisSpan>,
    },
    {
      title: '采集设备',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '数据属性',
      dataIndex: 'dataPropertyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
    },
    {
      title: '生效时间',
      dataIndex: 'effectiveTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'}></EllipsisSpan>,
    },
    {
      title: '操作',
      key: 'action',
      width: 120,
      render: (_: any, record) => (
        <Space size={16}>
          {hasEditPermission && (
            <Button
              type="link"
              disabled={!record.linkId}
              onClick={() => {
                setEditData(record);
                setEditModalOpen(true);
              }}
            >
              编辑
            </Button>
          )}
          {hasDataLinkPermission && (
            <Button
              type="link"
              disabled={!record.linkId}
              onClick={() => {
                setDataLinkData(record);
                setDataLinkModalOpen(true);
              }}
            >
              数据链路
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), { name: pageName }]} className={styles.wrapper}>
      <FormTitle title={pageName} />

      <SubContent>
        <Form form={detailForm} style={{ marginBottom: 24 }}>
          <BaseInfoContent layout="horizontal">
            <Form.Item label="名称：" name="name">
              <ShowInput />
            </Form.Item>
            <Form.Item label="所属站房：" name="stationName">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={columns}></Table>
      </SubContent>

      <Space className="sticky-footer">
        <Button
          onClick={() => {
            navigate(`/energy/basic/uet/hvacNodeList/${hvacId}/${stationId}`);
          }}
        >
          返回
        </Button>
      </Space>

      <EditModal
        open={editModalOpen}
        onClose={needUpdate => {
          setEditModalOpen(false);
          setEditData(undefined);
          if (needUpdate) updateFn();
        }}
        data={editData && cloneDeep(editData)}
      />
      <DataLinkModal
        open={dataLinkModalOpen}
        onClose={() => {
          setDataLinkModalOpen(false);
          setDataLinkData(undefined);
        }}
        data={dataLinkData && cloneDeep(dataLinkData)}
      />
    </Wrapper>
  );
};

export default HVACNodeIndicatorConfig;
