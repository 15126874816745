import {
  ColorStatus,
  deleteNetworkById,
  DistributeStatus,
  DistributeStatusDisplay,
  networkConfigsDistribute,
  NetworkDetail,
  NetworkingType,
  NetworkingTypeDisPlay,
  NetworkRequest,
  queryNetworks,
} from '@/api/netWorking';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { CloseOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Key,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Button,
  Form,
  Modal,
  CustomFilter,
  Input,
  Select,
} from '@maxtropy/components';
import { message, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DistributeRecordList from './components/DistributeRecordList';
import styles from './index.module.scss';

const columns: ColumnsType<NetworkDetail> = [
  {
    title: '组网编号',
    dataIndex: 'netNo',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '组网名称',
    dataIndex: 'netName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
  {
    title: '组网类型',
    dataIndex: 'netType',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={NetworkingTypeDisPlay[v as NetworkingType]} />,
  },
  {
    title: '最新下发状态',
    dataIndex: 'distributeStatus',
    ellipsis: { showTitle: true },
    render: (v: DistributeStatus, record: NetworkDetail) => (
      <Button
        type="link"
        onClick={() => {
          window.open(`/device/networking/networkingList/detail/${record.id}`, '_blank');
        }}
      >
        {DistributeStatusDisplay[v]}
      </Button>
    ),
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUserName',
    ellipsis: { showTitle: true },
    render: v => <EllipsisSpan value={v} />,
  },
];

const networkingTypeOptions = [
  { label: NetworkingTypeDisPlay[NetworkingType.STATIC_IP], value: NetworkingType.STATIC_IP },
];

export interface SearchParams extends Omit<NetworkRequest, 'page' | 'size' | ' netId'> {}

const NetworkingList: React.FC = () => {
  const hasNetworkNew = useHasPermission(PermissionsType.B_NETWORK_NEW); // 新建组网
  const hasNetworkEdit = useHasPermission(PermissionsType.B_NETWORK_EDIT); // 编辑组网
  const hasNetworkDelete = useHasPermission(PermissionsType.B_NETWORK_DELETE); // 删除组网
  const hasNetworkConfig = useHasPermission(PermissionsType.B_NETWORK_CONFIG); // 组网配置下发
  const hasNetworkConfigLog = useHasPermission(PermissionsType.B_NETWORK_CONFIG_LOG); // 下发记录
  const hasDataMirrorBtn = useHasPermission(PermissionsType.B_POINT_MIRROR); // 数据点镜像
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [applyRecordModalOpen, setApplyRecordModalOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<NetworkDetail[]>([]);

  const [updateState, updateFn] = useUpdate();

  const divRef = useRef<HTMLDivElement>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_netNo = urlSearchParams.get('netNo') || undefined;
  const [searchParams, setSearchParams] = useState<SearchParams | undefined>({ netNo: url_netNo });

  const [netId, setNetId] = useState<Key>();
  const [messageApi, messageContextHolder] = message.useMessage();

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      fixed: 'right' as const,
      width: 420,
      render: (v: any, record: NetworkDetail) => {
        return (
          <Space size={16}>
            {hasNetworkDelete && (
              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: '确定删除组网？',
                    content: (
                      <p>
                        <span style={{ color: '#f00' }}>删除后不可恢复</span>
                        ，你还要继续吗？
                      </p>
                    ),
                    onOk: () => {
                      deleteNetworkById(record.id).then(res => {
                        if (res.flag) {
                          Modal.success({
                            title: '删除成功',
                            okText: '好的',
                          });
                          updateFn();
                        }
                      });
                    },
                    okText: '继续',
                    cancelText: '取消',
                  });
                  // Modal.warning({
                  //   title: '组网已经被关联，不可删除',
                  //   okText: '好的',
                  // });
                }}
              >
                删除
              </Button>
            )}

            {hasNetworkEdit && (
              <Button
                disabled={record.distributeStatus === DistributeStatus.STARTING}
                type="link"
                onClick={() => {
                  if (record.distributeStatus === DistributeStatus.STARTING) {
                    return;
                  }
                  navigate(`/device/networking/networkingList/edit/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}

            <Button type="link">
              <Link to={`/device/networking/networkingList/detail/${record.id}`}>查看</Link>
            </Button>
            {hasNetworkConfig && (
              <Button
                type="link"
                className={record.colorStatus === ColorStatus.RED ? styles.danger : styles.info}
                disabled={record.distributeStatus === DistributeStatus.STARTING}
                onClick={() => {
                  if (record.id) {
                    networkConfigsDistribute(record.id).then(res => {
                      if (res.flag) {
                        messageApi.success({
                          content: (
                            <span>
                              组网配置开始下发，请在下发记录中查看下发结果。
                              <Button
                                style={{ marginLeft: '50px' }}
                                type="link"
                                size="small"
                                onClick={() => {
                                  messageApi.destroy(record.id);
                                }}
                                icon={<CloseOutlined style={{ color: '#a6a7a5' }} />}
                              />
                            </span>
                          ),
                          duration: 2,
                          key: record.id,
                        });
                        updateFn();
                      }
                    });
                  }
                }}
              >
                组网配置下发
              </Button>
            )}
            {hasDataMirrorBtn && (
              <Button type="link">
                <Link to={`/device/networking/networkingList/dataMirror/${record.id}`}>数据点镜像</Link>
              </Button>
            )}

            {hasNetworkConfigLog && (
              <Button
                type="link"
                onClick={() => {
                  setNetId(record.id);
                  setApplyRecordModalOpen(true);
                }}
              >
                下发记录
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    queryNetworks({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setDataSource(res.list ?? []);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams, updateState]);

  useEffect(() => {
    if (url_netNo) {
      form.setFieldsValue({
        netNo: url_netNo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_netNo]);

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
  };
  const onReset = () => {
    url_netNo = undefined;
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset} collapseOpen={false}>
      <Form.Item name="netNo" label="组网编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>

      <Form.Item name="netName" label="组网名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>

      <Form.Item name="netType" label="组网类型">
        <Select placeholder="请选择" options={networkingTypeOptions} />
      </Form.Item>

      <Form.Item name="gatewayNo" label="网关编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>

      <Form.Item name="gatewayName" label="网关名称">
        <Input placeholder="请输入名称查询" />
      </Form.Item>
    </CustomFilter>
  );
  return (
    <Wrapper filters={filters} routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasNetworkNew && (
          <Button type="primary" icon={<PlusOutlined />}>
            <Link to={`/device/networking/networkingList/add`}>新建组网</Link>
          </Button>
        )}
        <span>
          <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', paddingRight: '5px' }} />
          <span style={{ color: 'rgba(255, 255, 255, 0.45)' }}>
            提示：如果组网配置发生了变化，则组网配置下发按钮会变红，此时必须点击组网配置下发按钮，下发组网配置成功后，网关中的组网配置才可以生效。
          </span>
        </span>
      </Space>

      <Table rowKey="id" scroll={{ x: 1300 }} loading={loading} sticky columns={buildColumns} dataSource={dataSource} />
      <Paging pagingInfo={pagingInfo} />

      <div
        ref={(ref: HTMLDivElement) => {
          divRef.current = ref;
        }}
      />
      <Modal
        size="big"
        // bodyStyle={{
        //   height: 500,
        // }}
        getContainer={divRef.current}
        title="下发记录"
        open={applyRecordModalOpen}
        onCancel={() => setApplyRecordModalOpen(false)}
        destroyOnClose={true}
        footer={
          <Button type="primary" onClick={() => setApplyRecordModalOpen(false)}>
            确定
          </Button>
        }
        // bodyStyle={{
        //   maxHeight: 500,
        //   overflowY: 'auto',
        //   marginBottom: 15,
        // }}
      >
        <DistributeRecordList netId={netId} />
      </Modal>
      {messageContextHolder}
    </Wrapper>
  );
};

export default NetworkingList;
