import styles from './index.module.scss';
import { Node } from '@antv/x6';
import { Button } from '@maxtropy/components';
import { DoubleRightOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import { MachineType } from '../../utils/const';

// 自定义节点
const GroupNode = ({ node }: { node: Node }) => {
  const { name, id, stationType } = node.getData();

  const gotoDashBoard = () => {
    if (isNil(id)) return;

    if (stationType === MachineType.AIRCOMPRESSOR) {
      // 跳转到空压看板
      window.open(`/energyAssets/energyMediumDashboard/compressedAir?topoId=${id}`, '_blank');
    }
    if (stationType === MachineType.NGENERATOR) {
      // 跳转到工业气体看板
      window.open(`/energyAssets/energyMediumDashboard/industrialGas?topoId=${id}`, '_blank');
    }
  };

  return (
    <div className={styles.customNode}>
      <div className={styles.nodeTitle}>
        <div className={styles.title}>{name}</div>
        <Button style={{ padding: 0 }} type="link" onClick={gotoDashBoard}>
          查看更多
          <DoubleRightOutlined />
        </Button>
      </div>

      <div className={styles.nodeBox}></div>
    </div>
  );
};

export default GroupNode;
