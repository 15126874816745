import {
  PopConfirm,
  Button,
  EllipsisSpan,
  Input,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  Tooltip,
  Paging,
  CustomFilter,
} from '@maxtropy/components';
import { Form, Space } from 'antd';
import { useEffect, useState } from 'react';
import { MaterialWarehousingManagementParams } from '@/api/materielManagement';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';
import MaterialWarehousing from './components/MaterialWarehousing';
import EditMaterialWarehousing from './components/EditMaterialWarehousing';
import {
  apiV2CarbonFootprintEntryDeletePost,
  apiV2CarbonFootprintEntryPagePost,
  V2CarbonFootprintEntryPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useNavigate } from 'react-router-dom';
import UploadImport from '@/components/UploadImport';
import ImportLog from '@/components/ImportLog';
import { LogType } from '@/components/ImportLog/Prop';

const columns = [
  {
    title: '入库单号',
    dataIndex: 'entryNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '入库数量',
    dataIndex: 'inventoryQuantity',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '剩余数量',
    dataIndex: 'outboundQuantity',
    ellipsis: { showTitle: true },
    render: (v: string, record: any) => <EllipsisSpan value={record.inventoryQuantity - record.outboundQuantity} />,
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '入库人',
    dataIndex: 'staff',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '入库时间',
    dataIndex: 'storageTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

interface SearchParams extends Omit<MaterialWarehousingManagementParams, 'page' | 'size'> {}

const MaterialWarehousingManagement: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<V2CarbonFootprintEntryPagePostResponse['list']>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(20, {
    name: undefined,
  });
  const navigator = useNavigate();
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAdd = useHasPermission(PermissionsType.B_ADDMATERIALRECEIPT); // 新建入库
  const hasEdit = useHasPermission(PermissionsType.B_EDITMATERIALRECEIPT); // 编辑入库
  const hasDelete = useHasPermission(PermissionsType.B_DELMATERIALRECEIPT); // 删除入库
  const [id, setId] = useState<number>();
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openImportLogModal, setOpenImportLogModal] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2CarbonFootprintEntryPagePost({
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
      name: searchParams.name,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total as number);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const onDelete = (id: number) => {
    apiV2CarbonFootprintEntryDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 191,
      fixed: 'right' as 'right',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigator(`/productCarbonFootPrint/allocation/materialWarehousingManagement/detail?id=${record.id}`);
              }}
            >
              详情
            </Button>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  setId(record.id);
                  setEditModalOpen(true);
                }}
              >
                编辑
              </Button>
            )}
            {hasDelete &&
              (record.inventoryQuantity === record.inventoryQuantity - record.outboundQuantity ? (
                <PopConfirm
                  placement="top"
                  title="删除后本生产单已经填报的数据将被删除，且不能找回，是否确认删除？"
                  onConfirm={() => onDelete(record.id)}
                >
                  <Button type="link">删除</Button>
                </PopConfirm>
              ) : (
                <Tooltip placement="top" title="剩余数量小于入库数量，本入库记录已被使用，不支持删除。">
                  <>
                    <Button type="link" disabled>
                      删除
                    </Button>
                  </>
                </Tooltip>
              ))}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      name: undefined,
    });
  };

  const filters = (
    <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="name" label="物料">
        <Input placeholder="请输入入库单号/物料编码/物料名称/规格型号进行搜索" />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasAdd && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setAddModalOpen(true);
            }}
          >
            物料入库
          </Button>
        )}
        <Button
          type="primary"
          onClick={() => {
            setOpenImportModal(true);
          }}
        >
          导入物料入库单
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setOpenImportLogModal(true);
          }}
        >
          导入日志
        </Button>
      </Space>
      <Table columns={buildColumns} dataSource={data} rowKey="id" loading={loading} />
      <Paging pagingInfo={pagingInfo} />
      <MaterialWarehousing open={addModalOpen} setOpen={setAddModalOpen} updateFn={updateFn}></MaterialWarehousing>
      <EditMaterialWarehousing
        open={editModalOpen}
        setOpen={setEditModalOpen}
        updateFn={updateFn}
        id={id}
      ></EditMaterialWarehousing>
      {openImportModal && (
        <UploadImport title="物料入库单导入" type={LogType.ENTRY} setOpen={setOpenImportModal} updateFn={updateFn} />
      )}
      {openImportLogModal && <ImportLog onClose={() => setOpenImportLogModal(false)} type={LogType.ENTRY} />}
    </Wrapper>
  );
};

export default MaterialWarehousingManagement;
