import { Button, EllipsisSpan, Key, useUpdate } from '@maxtropy/components';
import { InputNumber, Space, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MeterProjectChangeButtonType } from '../MeterProjectDetail';
import styles from './index.module.scss';
import {
  ApportionListItem,
  SummaryTableDataItem,
  TotalSummaryTableDataListItem,
  apportionFunc,
  getTotalSummaryTableData,
} from '@/api/universeMeterQuery';
import { addNum } from '../../utils';
import { useParams } from 'react-router-dom';
import {
  changeTreeData,
  deleteToTreeData,
  getAllExpandedRow,
  getCellStyle,
  getRowSpan,
  insertExpandToTreeData,
} from '../../handleTreeDataUtils';
import TotalEleShareInput from '../TotalEleShareInput';
interface SummaryMeterTableProps {
  changeButtonType?: MeterProjectChangeButtonType;
  setChangeButtonType: (type?: MeterProjectChangeButtonType) => void;
  isAllExpand: boolean;
  setIsAllExpand: (isAllExpand: boolean) => void;
}

const formatDataToTreeTableData = (data: TotalSummaryTableDataListItem[], treeTableData: SummaryTableDataItem[]) => {
  data.forEach(project => {
    project.rows.forEach((row, index) => {
      treeTableData.push({
        id: `${project.meterReadingObjectId}-${row.energyMediumId}`,
        meterReadingObjectId: project.meterReadingObjectId,
        meterReadingObjectName: project.meterReadingObjectName,
        meterReadingLevel: project.meterReadingLevel,
        energyMediumId: row.energyMediumId,
        energyMediumName: row.energyMediumName,
        fact: row.realReadingAmount,
        adjust: row.adjustAmount,
        afterAdjust: row.afterAdjustAmount,
        share: row.apportionAmount,
        summitShare: row.summitApportionAmount,
        peakShare: row.peakApportionAmount,
        plainShare: row.plainApportionAmount,
        valleyShare: row.valleyApportionAmount,
        final: row.finalAmount,
        isHightLight: false,
        expand: index === 0 ? false : undefined,
        children: index === 0 ? (project.list && project.list.length > 0 ? [] : undefined) : undefined,
        child:
          index === 0
            ? project.list && project.list.length > 0
              ? formatDataToTreeTableData(project.list, [])
              : undefined
            : undefined,
      });
    });
  });
  return treeTableData;
};

const TotalSummaryMeterTable: React.FC<SummaryMeterTableProps> = ({
  isAllExpand,
  setIsAllExpand,
  changeButtonType,
  setChangeButtonType,
}) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<SummaryTableDataItem[]>([]);
  const [originData, setOriginData] = useState<SummaryTableDataItem[]>([]); // 原始数据
  const [beforeEditData, setBeforeEditData] = useState<SummaryTableDataItem[]>([]);
  const [expandedTreeData, setExpandedTreeData] = useState<SummaryTableDataItem[]>([]); // 是否展开的数据
  const [editedIds, setEditedIds] = useState<Key[]>([]); // 编辑中的id
  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [allExpandRowKeys, setAllExpandRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTotalSummaryTableData(id)
        .then(res => {
          if (res) {
            const tableData = formatDataToTreeTableData(res.list, []);
            setData(tableData);
            setBeforeEditData(tableData);
            setExpandedTreeData(tableData);
            setOriginData(tableData);
            setAllExpandRowKeys(getAllExpandedRow(tableData, []).map(i => i.id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, update]);

  useEffect(() => {
    if (isAllExpand) {
      const allExpandRow = getAllExpandedRow(originData, []);
      let expandedTree = originData;
      let tree = originData;
      allExpandRow.forEach(i => {
        expandedTree = changeTreeData(expandedTree, i.id, true);
        tree = insertExpandToTreeData(expandedTree, tree, i);
      });
      setData(tree);
      setBeforeEditData(tree);
      setExpandedTreeData(expandedTree);
      setExpandedRowKeys(allExpandRowKeys);
    } else {
      setExpandedRowKeys([]);
      setExpandedTreeData(originData);
      setData(originData);
      setBeforeEditData(originData);
    }
  }, [isAllExpand, originData, allExpandRowKeys]);

  useEffect(() => {
    if (
      expandedRowKeys.length > 0 &&
      allExpandRowKeys.length > 0 &&
      expandedRowKeys.length === allExpandRowKeys.length
    ) {
      setIsAllExpand(true);
      return;
    }
    if (expandedRowKeys.length === 0 && allExpandRowKeys.length !== 0) {
      setIsAllExpand(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRowKeys]);

  const columns = useMemo(() => {
    // 获取展开节点样式
    const getExpandStyle = (isHightLight?: boolean) => {
      return isHightLight
        ? {
            backgroundColor: '#29292C',
          }
        : {};
    };

    // 编辑分摊值
    const onChangeShare = (value: number | null, record: SummaryTableDataItem) => {
      const newData = data.map(i => {
        if (record.id === i.id) {
          const share = value ?? 0;
          const final = addNum(i.afterAdjust, share);
          return {
            ...i,
            share,
            final,
          };
        }
        return i;
      });
      setData(newData);
    };

    return [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => {
          return <EllipsisSpan value={v ? `${v}级` : v} />;
        },
      },
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string, record: SummaryTableDataItem) => {
          return <EllipsisSpan value={v} />;
        },
      },
      {
        title: '能源介质',
        dataIndex: 'energyMediumName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '实际抄表值',
        dataIndex: 'fact',
        ellipsis: { showTitle: true },
        onCell: (record: any, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整值',
        dataIndex: 'adjust',
        ellipsis: { showTitle: true },
        onCell: (record: any, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后值',
        dataIndex: 'afterAdjust',
        ellipsis: { showTitle: true },
        onCell: (record: any, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '分摊值',
        dataIndex: 'share',
        width: changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? 650 : 200,
        ellipsis: { showTitle: true },
        onCell: (record: any, index?: number) => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT, record.isHightLight),
        }),
        onHeaderCell: (record: any, index?: number) => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
        }),
        render: (v: number, record: SummaryTableDataItem) => {
          return changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? (
            record.energyMediumName === '电能' ? (
              <TotalEleShareInput
                data={data}
                currentRow={record}
                setData={setData}
                setEditedIds={setEditedIds}
                editedIds={editedIds}
              />
            ) : (
              <InputNumber
                value={v}
                style={{ width: 148 }}
                min={0}
                max={999999}
                step={0.01}
                precision={2}
                onChange={value => {
                  onChangeShare(value, record);
                  setEditedIds([...editedIds, record.meterReadingObjectId]);
                }}
              />
            )
          ) : (
            <EllipsisSpan value={v} />
          );
        },
        editable: true,
      },
      {
        title: '最终值',
        dataIndex: 'final',
        ellipsis: { showTitle: true },
        onCell: (record: any, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
    ];
  }, [changeButtonType, data, editedIds]);

  const onSave = () => {
    if (id) {
      const editData = data.filter(i => editedIds.includes(i.meterReadingObjectId));
      const apportionList: ApportionListItem[] = editData.map(i => {
        if (i.energyMediumName === '电能') {
          return {
            taskId: id,
            objectId: i.meterReadingObjectId,
            energyMediumId: i.energyMediumId,
            adjustType: 2, // 电能
            apportionAmountSummit: i.summitShare,
            apportionAmountPeak: i.peakShare,
            apportionAmountPlain: i.plainShare,
            apportionAmountValley: i.valleyShare,
          };
        }
        return {
          taskId: id,
          objectId: i.meterReadingObjectId,
          energyMediumId: i.energyMediumId,
          adjustType: 1, // 非电能
          apportionAmount: i.share,
        };
      });
      apportionFunc({
        list: apportionList,
      }).then(() => {
        setChangeButtonType(undefined);
        setBeforeEditData(data);
        setEditedIds([]);
        updateFn();
      });
    }
  };

  return (
    <>
      {changeButtonType && (
        <Space size={8}>
          <Button type="primary" onClick={onSave}>
            保存
          </Button>
          <Button
            onClick={() => {
              setChangeButtonType(undefined);
              setData(beforeEditData);
              setEditedIds([]);
            }}
          >
            取消
          </Button>
        </Space>
      )}

      <div className={styles.tableStyles}>
        <Table
          loading={loading}
          bordered
          columns={columns}
          rowKey="id"
          pagination={false}
          dataSource={data}
          sticky
          expandable={{
            onExpand: (isShow, record) => {
              if (isShow) {
                setExpandedRowKeys([...expandedRowKeys, record.id]);
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, true));
                setData(insertExpandToTreeData(expandedTreeData, data, record));
                setBeforeEditData(insertExpandToTreeData(expandedTreeData, beforeEditData, record));
              } else {
                setExpandedRowKeys(expandedRowKeys.filter(i => i !== record.id));
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, false));
                setData(deleteToTreeData(expandedTreeData, data, record));
                setBeforeEditData(deleteToTreeData(expandedTreeData, beforeEditData, record));
              }
            },
            expandedRowKeys,
          }}
        />
      </div>
    </>
  );
};

export default TotalSummaryMeterTable;
