import { Button, Empty, FormTitle, Select, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Space, Spin } from 'antd';
import { useNavigate } from 'react-router';
import styles from './index.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlarmNode, MachineType, MachineTypeDisplay } from './utils/const';
import N2Icon from './assets/N2@2x.png';
import N2SelectedIcon from './assets/N2Selected@2x.png';
import airCompressorIcon from './assets/aircompressor@2x.png';
import airCompressorSelectedIcon from './assets/aircompressorSelected@2x.png';
import { isNil, uniqBy } from 'lodash-es';
import FooterDrawer from './components/FooterDrawer';
import {
  V2StationSignboardStationListPostResponse,
  apiV2StationSignboardStationIndicatorValuePost,
  apiV2StationSignboardStationListPost,
} from '@maxtropy/device-customer-apis-v2';
import { useQuery } from '@/shared/utils/utils';
import MiddleGraph from './components/MiddleGraph';
import BenchmarkConfigModal from './BenchmarkConfigModal';
import { MeasurementType } from '../ProductionBeatConfig/utils';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';

export type StationItem = Exclude<V2StationSignboardStationListPostResponse['list'], undefined>[number];

const GasMachineDashBoard: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const url_stationId = urlSearchParams.get('stationId');
  const url_stationType = urlSearchParams.get('stationType');

  const [stationList, setStationList] = useState<StationItem[]>([]);
  const [currentType, setCurrentType] = useState<MachineType>(MachineType.AIRCOMPRESSOR);
  const [currentStationId, setCurrentStationId] = useState<number>();
  const [currentStationCode, setCurrentStationCode] = useState<string>();
  const [benConfigVisible, setBenConfigVisible] = useState<boolean>(false);
  const [alarmNodes, setAlarmNodes] = useState<AlarmNode[]>([]);
  const [init, setInit] = useState<boolean>(true);

  const hasBoardConfig = useHasPermission(PermissionsType.B_BOARDCONFIG); // 看板配置
  const hasBaseConfig = useHasPermission(PermissionsType.B_BASECONFIG); // 基础配置
  const hasStationDetail = useHasPermission(PermissionsType.B_STATIONDETAILSBTN); // 站点详情

  // 获取站房列表
  useEffect(() => {
    apiV2StationSignboardStationListPost({}).then(res => {
      setStationList(res?.list ?? []);
    });
  }, []);

  // 站房类型
  const stationTypeList = useMemo(() => {
    const uniqList = uniqBy(stationList, 'type').map(k => k.type);
    if (url_stationType) {
      setCurrentType(Number(url_stationType));
      urlSearchParams.delete('stationType');
    } else if (uniqList.length === 1) {
      setCurrentType(uniqList[0] as MachineType);
    } else {
      setCurrentType(MachineType.AIRCOMPRESSOR);
    }
    return uniqList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationList]);

  // 站房下拉框
  const stationOptions = useMemo(() => {
    if (!stationList.length) return [];
    const list = stationList
      .filter(k => k.type === currentType)
      .map(m => ({
        label: m.name,
        value: m.id,
        code: m.stationCode,
      }));
    if (init) {
      const defaultId = url_stationId ? Number(url_stationId) : list?.[0]?.value;
      const defaultCode = list.find(m => m.value === defaultId)?.code;
      setCurrentStationId(defaultId);
      setCurrentStationCode(defaultCode);
      setInit(false);
    } else {
      setCurrentStationId(list?.[0]?.value);
      setCurrentStationCode(list?.[0]?.code);
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationList, currentType]);

  // 获取指标
  const { data: indexList, isLoading } = useQuery(
    useCallback(async () => {
      if (isNil(currentType) || isNil(currentStationId)) {
        return Promise.resolve([]);
      }
      return await apiV2StationSignboardStationIndicatorValuePost({
        stationType: currentType,
        stationId: currentStationId,
      }).then(res => {
        return res.list ?? [];
      });
    }, [currentType, currentStationId])
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      {stationList.length ? (
        <>
          <div className={styles.headerRow}>
            <div className={styles.rowLeft}>
              {stationTypeList.length === 1 ? (
                <FormTitle
                  className={styles.titleSpan}
                  title={MachineTypeDisplay[stationTypeList[0] as MachineType]}
                ></FormTitle>
              ) : (
                <div className={styles.machineList}>
                  <div
                    onClick={() => {
                      setInit(false);
                      setCurrentType(MachineType.AIRCOMPRESSOR);
                    }}
                    className={`${styles.machineBtn} ${currentType === MachineType.AIRCOMPRESSOR && styles.active}`}
                  >
                    <img
                      className={styles.iconStyle}
                      src={currentType === MachineType.AIRCOMPRESSOR ? airCompressorSelectedIcon : airCompressorIcon}
                      alt=""
                    />
                    空压站看板
                  </div>
                  <div
                    onClick={() => {
                      setInit(false);
                      setCurrentType(MachineType.NGENERATOR);
                    }}
                    className={`${styles.machineBtn} ${currentType === MachineType.NGENERATOR && styles.active}`}
                  >
                    <img
                      className={styles.iconStyle}
                      src={currentType === MachineType.NGENERATOR ? N2SelectedIcon : N2Icon}
                      alt=""
                    />
                    制氮站看板
                  </div>
                </div>
              )}

              <Select
                className={styles.selectBox}
                value={currentStationId}
                placeholder="请选择"
                onChange={(val: number) => {
                  setInit(false);
                  setCurrentStationId(val);
                  setCurrentStationCode(stationOptions.find(k => k.value === val)?.code);
                }}
                options={stationOptions}
              />
            </div>
            <Space size={8}>
              {hasStationDetail && !!indexList?.length && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isNil(currentStationId) || isNil(currentType)) return;
                    navigate(
                      `/production/manage/gasMachineDashBoard/compressionStationDetail/${currentStationId}?stationType=${currentType}`
                    );
                  }}
                >
                  站房详情
                </Button>
              )}
              {hasBoardConfig && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (isNil(currentStationId) || isNil(currentType)) return;
                    navigate(
                      `/production/manage/gasMachineDashBoard/dashBoardConfig/${currentStationId}/${currentType}`
                    );
                  }}
                >
                  看板配置
                </Button>
              )}
              {hasBaseConfig && (
                <Button type="primary" onClick={() => setBenConfigVisible(true)}>
                  基准配置
                </Button>
              )}
            </Space>
          </div>
          <Spin spinning={isLoading}>
            <div className={styles.middleIndex}>
              {indexList?.map(item => {
                return (
                  <div className={styles.indexCard} key={item.indicatorId}>
                    <div className={styles.indexName}>
                      {item.indicatorName}（{item.physicalUnitName}）
                    </div>
                    {item.measurementType === MeasurementType.ACCUM ? (
                      <div className={styles.indexInfo}>
                        <div className={styles.left}>
                          <span>小时：</span>
                          <span className={styles.val}>{item?.accumHourValue?.toFixed(2) ?? '--'}</span>
                        </div>
                        <div className={styles.splitLine}></div>
                        <div className={styles.right}>
                          <span>当日：</span>
                          <span className={styles.val}>{item?.accumDayValue?.toFixed(2) ?? '--'}</span>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.indexInfo}>
                        <span className={styles.val}>{item?.instantValue?.toFixed(2) ?? '--'}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Spin>
          <MiddleGraph
            stationId={currentStationId}
            stationCode={currentStationCode}
            type={currentType}
            existIndex={!!indexList?.length}
            alarmNodes={alarmNodes}
          />
          <FooterDrawer stationId={currentStationId} type={currentType} callbackAlarms={setAlarmNodes} />
        </>
      ) : (
        <Empty description="暂无数据" style={{ marginTop: 200 }} />
      )}
      {benConfigVisible && (
        <BenchmarkConfigModal
          stationId={currentStationId}
          currentType={currentType}
          onCancel={() => setBenConfigVisible(false)}
        />
      )}
    </Wrapper>
  );
};

export default GasMachineDashBoard;
