import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from '@maxtropy/components';
import { Result } from 'antd';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseElectricityBillResponse, BillStatusEnum } from '../../../../api/electricity';
import styles from './index.module.scss';

interface Props {
  electricityBillInfo: BaseElectricityBillResponse;
}

const ElectricityBillErrorModal: FC<Props> = props => {
  const { electricityBillInfo } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const handleCancel = () => {
    const state = {
      contractId: electricityBillInfo.saleElectricityContractId,
      propMgmtDeptId: electricityBillInfo.propertyManagementConfigId,
      ouId: electricityBillInfo.ouId,
      customerMcid: electricityBillInfo.mcid,
    };
    setIsModalVisible(false);
    navigate(`/operation/meterReading/electricityRecord?${qs.stringify(state)}`);
  };

  useEffect(() => {
    if (electricityBillInfo.status === BillStatusEnum.EXCEPTION) setIsModalVisible(true);
  }, [electricityBillInfo]);

  return (
    <>
      <Modal
        title="操作失败"
        centered
        open={isModalVisible}
        onCancel={() => navigate(`/operation/settlementRules/electricityBill`)}
        footer={[
          <Button key="back" onClick={handleCancel}>
            查看抄表记录
          </Button>,
        ]}
        width={650}
        maskStyle={{ backgroundColor: 'black' }}
      >
        <Result
          className={styles.failReason}
          icon={<ExclamationCircleFilled style={{ color: '#FF4D4F', fontSize: '42px' }} />}
          title={electricityBillInfo.failReason}
        />
      </Modal>
    </>
  );
};

export default ElectricityBillErrorModal;
