import { Button, EllipsisSpan, Key, Paging, Table, usePaging, useUpdate, Form, Input } from '@maxtropy/components';
import { useEffect, useState } from 'react';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import {
  OrderEnum,
  WorkCenterPageRes,
  getWorkCenterPageById,
  WorkCenterQuery,
  bindWorkOrder,
} from '@/api/energyUnitMapping';
import { isNil } from 'lodash';
import styles from './index.module.scss';
import BindOrderModal from '../BindOrderModal';
import { V2ProductionBaseDetailPostResponse, apiV2WorkCenterDetailPost } from '@maxtropy/device-customer-apis-v2';
interface Props {
  onJumpToTreeItem: (v: Key[]) => void;
  workCenterId?: Key;
  contentType?: string;
  workCenterDetail?: V2ProductionBaseDetailPostResponse;
  treeUpdateFn: () => void;
  searchUpdate: number;
  infoUpdateFn: () => void;
  setWorkCenterDetail: (v: any) => void;
  productionBaseId: number;
  activeKey: string;
}

const columns = [
  {
    title: '工作中心编码',
    dataIndex: 'workCenterCode',
    ellipsis: { showTitle: true },
    sorter: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定工序数',
    dataIndex: 'workProcedureAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定工站数',
    dataIndex: 'workStationAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定用能单元数',
    dataIndex: 'energyUnitAmount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'opUpdateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'opUpdateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? '--' : dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface SearchParams extends Omit<WorkCenterQuery, 'page' | 'size'> {}

const ProductionBaseTable: React.FC<Props> = ({
  treeUpdateFn,
  contentType,
  workCenterDetail,
  setWorkCenterDetail,
  searchUpdate,
  productionBaseId,
  infoUpdateFn,
  activeKey,
}) => {
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [workCenterId, setWorkCenterId] = useState<Key>();
  const [bindOrderModalOpen, setBindOrderModalOpen] = useState<boolean>(false);

  const [data, setData] = useState<WorkCenterPageRes[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const [form] = Form.useForm();

  // 切换工作中心时，清空搜索条件
  useEffect(() => {
    if (contentType === 'productionBase') {
      form.resetFields();
      setSearchParams(undefined);
      setPageOffset(1);
    }
  }, [searchUpdate, setPageOffset, form, contentType]);

  useEffect(() => {
    if (contentType === 'productionBase' && productionBaseId) {
      setLoading(true);
      getWorkCenterPageById({
        ...searchParams,
        productionBaseId,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          setData(res.list);
          setTotalCount(res.total);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [contentType, searchParams, pageOffset, pageSize, setTotalCount, update, productionBaseId, activeKey]);

  const buildColumns = [
    {
      title: '工作中心名称',
      dataIndex: 'workCenterName',
      ellipsis: { showTitle: true },
      render: (v: string, row: WorkCenterPageRes) => {
        return <EllipsisSpan value={v} />;
      },
    },
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: WorkCenterPageRes) => (
        <Button
          type="link"
          onClick={() => {
            setBindOrderModalOpen(true);
            setWorkCenterId(record.workCenterId);
            apiV2WorkCenterDetailPost({ id: record.workCenterId }).then(res => {
              if (res) {
                setWorkCenterDetail(res);
              }
            });
          }}
        >
          绑定工序
        </Button>
      ),
    },
  ];

  // 绑定工作中心
  const onBindOrder = (v: Key[]) => {
    if (workCenterId) {
      bindWorkOrder({
        id: workCenterId,
        workProcedureIds: v,
      }).then(() => {
        updateFn();
        setPageOffset(1);
        treeUpdateFn();
        infoUpdateFn();
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Form form={form} className={styles.formItemStyles}>
          <Form.Item name="workCenterCodeOrName" style={{ marginBottom: 10 }}>
            <div style={{ width: 280 }}>
              <Input.Search
                placeholder="工作中心编码/名称"
                onSearch={(v: string) => {
                  setSearchParams({
                    ...searchParams,
                    workCenterCodeOrName: v === '' ? undefined : v,
                  });
                  setPageOffset(1);
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={loading}
        rowKey="linkId"
        dataSource={data}
        columns={buildColumns}
        onChange={(pagination, filters, sorter) => {
          const { order } = sorter as SorterResult<WorkCenterPageRes>;
          setSearchParams({
            ...searchParams,
            orderBy: isNil(order) ? undefined : order === 'ascend' ? OrderEnum.ASC : OrderEnum.DESC,
          });
          setPageOffset(1);
        }}
      />
      <Paging pagingInfo={pagingInfo} />
      {/* {productionBaseModalOpen && (
        <ProductionBaseModal
          open={productionBaseModalOpen}
          setOpen={setProductionBaseModalOpen}
          id={workCenterId}
          workCenterDetail={workCenterDetail}
          onBind={onBindOrder}
        />
      )} */}
      {bindOrderModalOpen && (
        <BindOrderModal
          open={bindOrderModalOpen}
          setOpen={setBindOrderModalOpen}
          id={workCenterId}
          workCenterDetail={workCenterDetail}
          onBind={onBindOrder}
        />
      )}
    </>
  );
};

export default ProductionBaseTable;
