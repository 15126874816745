import dayjs from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import React, { useMemo } from 'react';
import { scale } from '../../utils';
import { MonthPlanRes } from '../EnergyPlan';

interface Props {
  isOverTenThousand: boolean;
  data: MonthPlanRes;
  monthPlanColor:
    | {
        '0%': string;
        '100%': string;
      }
    | undefined;
  isTwoCol?: boolean;
  outerRef: any;
}

const MonthPlanLineEcharts: React.FC<Props> = ({ outerRef, isTwoCol, isOverTenThousand, data, monthPlanColor }) => {
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  // 获取data中最大的actualValue
  const maxActualValue = Math.max(...(data?.actualValue ?? []).map(i => i.value));
  const fontZoomSize = useMemo(() => {
    return zoom > 1 ? 12 * zoom : 12;
  }, [zoom]);

  const getChartOption = () => {
    return {
      grid: {
        left: 20 * zoom,
        // left:80,
        right: isTwoCol ? 10 : 0,
        top: 30,
        bottom: zoom * 20,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
          fontSize: fontZoomSize,
        },
        formatter: function (params: any) {
          const { value, axisValue } = params[0];
          const startColor = monthPlanColor?.['0%'];
          const endColor = monthPlanColor?.['100%'];
          const unit = isOverTenThousand ? 'tce' : 'kgce';
          return `<p>${dayjs(axisValue, 'x').format('MM-DD')}</p><p>
            <span style='width:${zoom * 8}px; height:${
            zoom * 8
          }px; background: #477BFF;border-radius:50%;display:inline-block;margin-right:${zoom * 4}px;'></span>月计划用能
            <span style='margin-left:${zoom * 25}px'>
            ${data.planValue ?? '--'}${unit}</span>
            </p>
            <p>
            <span style='width:${zoom * 8}px; height:${
            zoom * 8
          }px; background: linear-gradient(180deg, ${startColor} 0%, ${endColor} 100%)

            ;border-radius:50%;display:inline-block;margin-right:${zoom * 4}px;'></span>累计用能
            <span style='margin-left:${zoom * 25}px' >
            ${value[1] ?? '--'}${unit}</span>
           </p>`;
        },
      },
      xAxis: {
        // show: false,
        type: 'time',
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{MM}-{dd}',
          fontSize: fontZoomSize,
        },
      },
      yAxis: {
        show: false,
        min: 0,
        max: Math.max(data.planValue ?? 0, maxActualValue),
      },
      legend: {
        show: false,
      },
      graphic: ['累计用能', '计划线'].map((i, index) => {
        return {
          type: 'rect',
          top: 5,
          right: index === 0 ? zoom * 55 : zoom * 115,
          shape: {
            // r: 4,
            width: zoom * 8,
            height: zoom * 8,
          },
          textContent: {
            type: 'text',
            style: {
              text: i,
              fill: 'white',
              fontSize: fontZoomSize,
            },
            cursor: 'default',
          },
          textConfig: {
            position: 'right',
          },
          style: {
            fill:
              index === 0
                ? {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: monthPlanColor?.['0%'], // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: monthPlanColor?.['100%'], // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  }
                : '#477BFF',
          },
          cursor: 'default',
        };
      }),
      series: {
        type: 'line',
        symbol: 'none',
        showSymbol: false,
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: monthPlanColor?.['0%'], // 0% 处的颜色
            },
            {
              offset: 1,
              color: monthPlanColor?.['100%'], // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
        emphasis: {
          focus: 'series',
        },

        areaStyle: {
          opacity: 0.4,
        },
        markLine: {
          silent: true,
          symbol: ['none', 'none'],
          lineStyle: {
            type: 'dashed',
          },
          data: data.planValue
            ? [
                {
                  label: { show: false },
                  lineStyle: { color: '#477BFF', width: 2 },
                  yAxis: data?.planValue,
                },
              ]
            : [],
        },
        name: '月计划',
        data: (data?.actualValue ?? []).map(i => [i.ts, i.value]),
      },
    };
  };
  return (
    <>
      <ReactEcharts
        option={getChartOption()}
        notMerge
        lazyUpdate={false}
        style={
          document.documentElement.clientWidth > 2560
            ? {
                width: 700,
                height: 260,
                margin: '0 40px',
                zoom: `${1 / zoom}`,
                transform: `scale(${1 / scale}, ${scale})`,
              }
            : document.documentElement.clientWidth > 1920
            ? {
                width: 600,
                height: 200,
                margin: '0 20px',
                zoom: `${1 / zoom}`,
                transform: `scale(${1 / scale}, ${scale})`,
              }
            : { width: 600, height: 136 }
        }
      />
    </>
  );
};

export default MonthPlanLineEcharts;
