import { Select, Form, InputNumber, Button, ShowInput } from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import {
  VariableType,
  VariableTypeFormat,
  dataOriginOptions,
  statisticalModelOptions,
} from '@/pages/OutputConfig/Baseline/BaselineTs';
import { isNil } from 'lodash';
import {
  apiV2OutputProductBaseLineDetailPost,
  apiV2OutputProductBaseLineGetOutputProductMediumListPost,
  apiV2OutputProductBaseLinePhysicalUnitPost,
  apiV2OutputProductBaseLineUpsertPost,
} from '@maxtropy/device-customer-apis-v2';
import { UetEMTAllEnergyMediumListResponse, getUetEMTAllEnergyMediumList } from '@/api/uet';
import { useNavigate, useParams } from 'react-router';
import { BaseLineContext } from '../utils/common';
import { OutputUnitType } from '@/api/outputConfig';

interface MaintenanceInfoProps {
  prevStep: () => void;
  nextStep: () => void;
}

interface OptionItem {
  label?: string;
  value?: number;
}

export type MapList = Record<number, OptionItem[]>;

const MaintenanceInfo: React.FC<MaintenanceInfoProps> = ({ nextStep, prevStep }) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { lineItem, setLineItem, isEdit } = useContext(BaseLineContext);
  const [mediumList, setMediumList] = useState<UetEMTAllEnergyMediumListResponse[]>([]);
  const [physicalList, setphysicalList] = useState<any>([]);

  useEffect(() => {
    const baseLineId = lineItem?.baseLineId ?? id;
    if (!isNil(baseLineId)) {
      setLoading(true);
      apiV2OutputProductBaseLineDetailPost({ id: baseLineId })
        .then(res => {
          form.setFieldsValue({
            minOutput: res.minOutput,
            maxOutput: res.maxOutput,
            warningUpperLimit: res.warningUpperLimit,
            warningLowerLimit: res.warningLowerLimit,
            advancedValuePercent: res.advancedValuePercent,
            dataSource: res.dataSource,
            statisticalModel: res.statisticalModel,
            energyMediumId: res.energyMediumId,
            basePhysicalQuantityId: res.basePhysicalQuantityId,
          });
          updatePhysical(res.energyMediumId, true);
          setLineItem?.({
            ...lineItem,
            baseLineId: Number(baseLineId),
            outputProductId: res.outputProductId,
            name: res.outputProductName,
            unitTypeCode: res.unitTypeCode,
            unitCode: res.unitCode,
            unitName: res.unitName,
            unit: res.unitTypeCode === OutputUnitType.PIECE ? res.unitName : res.unitCode,
            minOutput: res.minOutput,
            maxOutput: res.maxOutput,
            dataSource: res.dataSource,
            code: res.outputProductCode,
            energyMediumName: res.energyMediumName,
            energyMediumId: res.energyMediumId,
            warningLowerLimit: res.warningLowerLimit,
            warningUpperLimit: res.warningUpperLimit,
            advancedValuePercent: res.advancedValuePercent,
            statisticalModel: res.statisticalModel,

            baseLineCycle: lineItem?.baseLineCycle ?? res.baseLineCycle,
            lineType: lineItem?.lineType ?? res.lineType,
            baseLineGranularity: lineItem?.baseLineGranularity ?? res.baseLineGranularity,
            baseLineTime: lineItem?.baseLineTime ?? res.baseLineTime,
            multiChartData: lineItem?.multiChartData ?? res.multiChartData,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lineItem?.baseLineId]);

  // 获取所有工质列表, 包含电能
  useEffect(() => {
    (async () => {
      const res = !isNil(lineItem?.outputProductId)
        ? await apiV2OutputProductBaseLineGetOutputProductMediumListPost({
            outputProductId: lineItem?.outputProductId,
          })
        : undefined;
      const allList = await getUetEMTAllEnergyMediumList();
      const restList = (allList ?? []).filter(
        m => !(res?.list ?? []).some(k => k.id === m.id) || m.id === lineItem?.energyMediumId
      );
      setMediumList(restList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem?.outputProductId]);

  const consumptionTypes = useMemo(() => [...mediumList], [mediumList]);

  const updatePhysical = (mediumId?: number, isInit?: boolean) => {
    if (isNil(mediumId)) return;
    apiV2OutputProductBaseLinePhysicalUnitPost({ id: mediumId }).then(res => {
      const physicalOptions = res?.list?.map(i => ({
        label: i.basePhysicalQuantityName,
        value: i.basePhysicalQuantityId,
      }));
      setphysicalList(physicalOptions);
      if (!isInit) {
        form.setFieldValue('basePhysicalQuantityId', physicalOptions?.[0]?.value);
      }
    });
  };

  const isEditForm = () => {
    const values = form.getFieldsValue();
    if (lineItem?.minOutput !== values.minOutput || lineItem?.maxOutput !== values.maxOutput) {
      return true;
    }
    if (
      lineItem?.warningLowerLimit !== values.warningLowerLimit ||
      lineItem?.warningUpperLimit !== values.warningUpperLimit
    ) {
      return true;
    }
    if (lineItem?.statisticalModel !== values.statisticalModel) {
      return true;
    }
    if (lineItem?.advancedValuePercent !== values.advancedValuePercent) {
      return true;
    }
    return false;
  };

  const saveNext = async () => {
    if (isEdit && !isEditForm()) {
      nextStep();
      return;
    }
    const values = await form.validateFields();
    setLoading(true);
    apiV2OutputProductBaseLineUpsertPost({
      id: lineItem?.baseLineId,
      outputProductId: lineItem?.outputProductId,
      minOutput: values.minOutput,
      maxOutput: values.maxOutput,
      warningUpperLimit: values.warningUpperLimit,
      warningLowerLimit: values.warningLowerLimit,
      advancedValuePercent: values.advancedValuePercent,
      variableType: values.variableType,
      dataSource: values.dataSource,
      statisticalModel: values.statisticalModel,
      energyMediumId: values.energyMediumId,
      basePhysicalQuantityId: values.basePhysicalQuantityId,
    }).then(res => {
      setLineItem?.({
        ...lineItem,
        minOutput: values.minOutput,
        maxOutput: values.maxOutput,
        dataSource: values.dataSource,
        warningLowerLimit: values.warningLowerLimit,
        warningUpperLimit: values.warningUpperLimit,
        statisticalModel: values.statisticalModel,
        baseLineId: res.id,
        energyMediumId: values.energyMediumId,
        advancedValuePercent: values.advancedValuePercent,
      });
      setLoading(false);
      nextStep();
    });
  };

  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="horizontal"
        initialValues={{
          minOutput: 0,
          warningUpperLimit: 20,
          warningLowerLimit: 20,
          advancedValuePercent: 5,
          variableType: VariableType.PRODUCTION_RATE,
        }}
      >
        <Row>
          <Col span={12}>
            <Form.Item label="产出物编码">
              <ShowInput value={lineItem?.code} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="产出物名称">
              <ShowInput value={lineItem?.name} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="statisticalModel" label="统计模型" rules={[{ required: true, message: '请选择统计模型' }]}>
              <Select options={statisticalModelOptions} placeholder="请选择" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="dataSource" label="数据来源" rules={[{ required: true, message: '请选择数据来源' }]}>
              <Select options={dataOriginOptions} placeholder="请选择" disabled={isEdit} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="variableType" label="X轴指标">
              {VariableTypeFormat[VariableType.PRODUCTION_RATE]}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Y轴指标" required style={{ marginBottom: 0 }}>
              <Row gutter={10}>
                <Col span={16}>
                  <Form.Item name="energyMediumId" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      showSearch
                      disabled={isEdit}
                      optionFilterProp="label"
                      options={consumptionTypes.map((ct: { energyName: any; id: any }) => ({
                        label: ct.energyName,
                        value: ct.id,
                      }))}
                      onChange={(val: number) => {
                        updatePhysical(val, false);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="basePhysicalQuantityId" rules={[{ required: true, message: '请选择' }]}>
                    <Select placeholder="请选择" disabled={isEdit} options={physicalList} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="minOutput"
              label="最小生产速率"
              rules={[
                { required: true, message: '请输入最小生产速率' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const upLimit = getFieldValue('maxOutput');
                    if (!isNil(value) && !isNil(upLimit) && value >= upLimit) {
                      return Promise.reject(new Error('最小速率需要小于最大速率'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                addonAfter={`${lineItem?.unit ?? '--'}/时`}
                placeholder="请输入"
                min={0}
                precision={4}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="maxOutput"
              label="最大生产速率"
              rules={[
                { required: true, message: '请输入最大生产速率' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const lowLimit = getFieldValue('minOutput');
                    if (!isNil(value) && !isNil(lowLimit) && value <= lowLimit) {
                      return Promise.reject(new Error('最大速率需要大于最小速率'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                addonAfter={`${lineItem?.unit ?? '--'}/时`}
                placeholder="请输入"
                min={0}
                precision={4}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="warningUpperLimit"
              label="单耗预警上限"
              rules={[{ required: true, message: '请输入单耗预警上限' }]}
            >
              <InputNumber addonAfter="%" min={0} precision={2} placeholder="请输入" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="warningLowerLimit"
              label="单耗预警下限"
              rules={[{ required: true, message: '请输入单耗预警下限' }]}
            >
              <InputNumber
                addonAfter="%"
                precision={2}
                min={0.01}
                max={100}
                placeholder="请输入"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="单耗先进值标准">
              <div className={styles.standardVal}>
                <span>比基准单耗低</span>
                <Form.Item name="advancedValuePercent" noStyle>
                  <InputNumber
                    addonAfter="%"
                    placeholder="请输入"
                    precision={2}
                    min={0.01}
                    max={99.99}
                    style={{ width: 130 }}
                  />
                </Form.Item>
                <span>及以上为先进单耗</span>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Space size={8}>
        {isEdit && (
          <Button
            onClick={() => {
              navigate('/systemConfig/productionTopo/baseLineManage');
            }}
          >
            返回
          </Button>
        )}
        {!isEdit && <Button onClick={prevStep}>上一步</Button>}
        <Button type="primary" onClick={saveNext}>
          保存后下一步
        </Button>
      </Space>
    </Spin>
  );
};
export default MaintenanceInfo;
