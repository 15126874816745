import {
  BusTieStatusTypeFormat,
  CircuitDetailProps,
  CircuitType,
  CircuitTypeFormat,
  VoltageLevelTypeFormat,
} from '@/api/circuit';
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import { FC } from 'react';

import { Form, ShowInput } from '@maxtropy/components';

interface BasicInfoFormProps {
  data?: CircuitDetailProps;
}

const BasicInfoForm: FC<BasicInfoFormProps> = props => {
  const { data } = props;

  return (
    <Row>
      <Col span={12}>
        <Form.Item label="回路名称">
          <ShowInput value={data?.name} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="所属运营单元">
          <ShowInput value={data?.ouName} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="回路类型">
          <ShowInput value={data?.type ? CircuitTypeFormat[data.type] : '--'} />
        </Form.Item>
      </Col>
      {data?.type !== CircuitType.TRANSFORMER_CIRCUIT && (
        <Col span={12}>
          <Form.Item label="电压等级">
            <ShowInput value={data?.voltageLevel ? VoltageLevelTypeFormat[data.voltageLevel] : '--'} />
          </Form.Item>
        </Col>
      )}
      {data?.type === CircuitType.TRANSFORMER_CIRCUIT && (
        <>
          <Col span={12}>
            <Form.Item label="进线电压等级">
              <ShowInput value={data?.voltageLevelIn ? VoltageLevelTypeFormat[data.voltageLevelIn] : '--'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="出线电压等级">
              <ShowInput value={data?.voltageLevelOut ? VoltageLevelTypeFormat[data.voltageLevelOut] : '--'} />
            </Form.Item>
          </Col>
        </>
      )}

      <Col span={12}>
        <Form.Item label="装机容量">
          <ShowInput addonAfter="kVA" value={data?.capacity} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="目标负载">
          <ShowInput addonAfter="kW" value={data?.targetLoad} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="目标负载率">
          <ShowInput addonAfter="%" value={data?.targetLoadRate ? (data?.targetLoadRate * 100).toFixed(2) : '--'} />
        </Form.Item>
      </Col>
      {data?.type === CircuitType.BUS_COUPLER_CIRCUIT && (
        <Col span={12}>
          <Form.Item label="母联状态">
            <ShowInput value={!isNil(data?.busTieStatus) ? BusTieStatusTypeFormat[data.busTieStatus] : '--'} />
          </Form.Item>
        </Col>
      )}
      <Col span={12}>
        <Form.Item label="所属配电柜">
          <ShowInput value={data?.distributionCabinetName} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="备注">
          <ShowInput value={data?.remark} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default BasicInfoForm;
