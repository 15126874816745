import React, { ComponentType, useMemo, useState, useEffect } from 'react';
import { Space } from 'antd';
import { Rule } from 'antd/es/form';
import SingleSelect from './SingleSelect';
import SingleInput from './SingleInput';
import RangeInput from './RangeInput';
import Unit from './Unit';
import {
  AttributeField,
  ConstraintValue,
  FieldType,
  FieldValue,
  InputType,
  MinMaxConstraintValue,
  PhysicalUnit,
  RangeFieldValue,
} from '../../types';
import { isNil } from 'lodash';
import { Checkbox, Form } from '@maxtropy/components';
import styles from './index.module.scss';

export interface FieldProps {
  field: AttributeField;
  disabled: boolean;
  isDetail?: boolean;
  required?: boolean;
  checkable?: boolean;
  onCheck?: (checked: boolean, field: AttributeField) => void;
}
export interface ControlWrapperProps {
  control: ComponentType<ControlProps>;
  units?: PhysicalUnit[];
  disabled: boolean;
  isDetail?: boolean;
  inputType?: InputType;
  digitalAccuracy?: number;
  constraintValue?: ConstraintValue;
  value?: FieldValue;
  onChange?: (value: FieldValue) => void;
  placeholder?: string;
}

export interface ControlProps {
  disabled: boolean;
  isDetail?: boolean;
  inputType?: InputType;
  digitalAccuracy?: number;
  constraintValue?: ConstraintValue;
  value?: FieldValue;
  onChange?: (value: FieldValue) => void;
  unit?: PhysicalUnit;
  placeholder?: string;
  addonAfter?: React.ReactNode;
}

const controlComponents = {
  [FieldType.ENUM]: SingleSelect,
  [FieldType.MULTIPLE]: SingleSelect,
  [FieldType.FIXED]: SingleInput,
  [FieldType.RANGE]: RangeInput,
};

const ControlWrapper: React.FC<ControlWrapperProps> = ({ control, units, ...props }) => {
  const ControlComponent = control;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const unit = useMemo(() => units?.find(item => item.id === props.value?.unitId), [props.value?.unitId]);

  const addonAfterRender = useMemo(() => {
    // 如果是输入框，且单位只有1，则不展示
    if (props.inputType === InputType.TEXT && (units ?? []).length === 1 && units![0].generalName === '1') {
      props.onChange && props.onChange({ ...props.value, unitId: units![0].id });
      return null;
    }

    return (
      <Unit
        units={units}
        disabled={props.disabled}
        value={props.value?.unitId}
        onChange={value => {
          props.onChange && props.onChange({ ...props.value, unitId: value });
        }}
      />
    );
  }, [units]);

  return (
    <Space size={10}>
      <ControlComponent unit={unit} {...props} addonAfter={addonAfterRender} />
    </Space>
  );
};

const FieldItem: React.FC<FieldProps> = ({ disabled: propDisabled, isDetail, field, required, checkable, onCheck }) => {
  const ControlComponent = controlComponents[field.type];

  const [disabled, setDisabled] = useState<boolean>(propDisabled);
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    setDisabled(propDisabled);
  }, [propDisabled]);

  const rules =
    !checkable || checked
      ? [
          () => ({
            validator(_: Rule, value: FieldValue) {
              if (field.physicalUnits?.length && !value.unitId) {
                return Promise.reject('请选择单位');
              }
              if (field.type === FieldType.FIXED && (isNil(value.value) || value.value === '')) {
                return Promise.reject(`请输入${field.name}`);
              }
              // 输入框组件
              if (field.type === FieldType.FIXED && field.inputType === InputType.TEXT) {
                if (
                  (field?.constraintValue as MinMaxConstraintValue)?.max &&
                  (value.value as string).length > (field?.constraintValue as MinMaxConstraintValue).max
                ) {
                  return Promise.reject(`最多输入${(field?.constraintValue as MinMaxConstraintValue).max!}个字符`);
                }
              }
              if (field.type === FieldType.ENUM && value.value === undefined) {
                return Promise.reject(`请选择${field.name}`);
              }
              if (field.type === FieldType.RANGE) {
                const v = value.value as RangeFieldValue | undefined;
                if (v?.min === undefined || v?.max === undefined) {
                  return Promise.reject(`请输入${field.name}最大最小值`);
                }
                if (v.min > v.max) {
                  return Promise.reject(`最小值不能大于最大值`);
                }
              }
              return Promise.resolve();
            },
          }),
        ]
      : [];

  return (
    <Form.Item label={field.name} className={styles.formLabel}>
      <Form.Item noStyle name={field.id} required={required && !disabled} rules={rules}>
        <ControlWrapper
          control={ControlComponent}
          units={field.physicalUnits}
          disabled={disabled}
          isDetail={isDetail}
          constraintValue={field.constraintValue}
          inputType={field.inputType}
          digitalAccuracy={field.digitalAccuracy}
          placeholder={!checked && checkable ? '若需要修改请勾选' : undefined}
        />
      </Form.Item>
      {checkable && (
        <Checkbox
          style={{ marginLeft: 20 }}
          onChange={e => {
            const checked = e.target.checked;
            setChecked(checked);
            setDisabled(!checked);
            onCheck?.(checked, field);
          }}
        />
      )}
    </Form.Item>
  );
};

export default FieldItem;
