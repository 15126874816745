import { FC, useEffect, useMemo, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { mcidItem } from '../../../api/energyConsumption';
import { Form, Input, Key, Modal, Select } from '@maxtropy/components';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '../../../api/device';
import { difference, flatten } from 'lodash-es';

import {
  apiV2EnergyAnalysisDetailPost,
  apiV2EnergyAnalysisLinkedOuPagePost,
  apiV2EnergyAnalysisUpdatePost,
  apiV2OuPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { OuItem } from '../Config';
import {
  V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse,
  V2EnergyAnalysisDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useWatch } from 'antd/es/form/Form';

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: nodeItem = {
      key: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};
interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}

interface mcidTree {
  key: string;
  title: string;
  disabled?: boolean;
}
interface nodeItem extends flattenNode {
  children: nodeItem[];
}
export type OuInfoProps = Exclude<V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse['list'], undefined>[number];

const flattenTreeData = (data: nodeItem[]) => {
  let treeList: nodeItem[] = [];
  let flatten = function (data: nodeItem[]) {
    for (let i = 0; i < data.length; i++) {
      treeList.push(data[i]);
      if (data[i].children.length) {
        flatten(data[i].children);
      }
    }
  };
  flatten(data);
  return treeList;
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface Params {
  editModalOpen: boolean;
  setEditModalOpen: (v: boolean) => void;
  energyConsumptionId?: Key;
  updateFn: () => void;
}

const EditEnergyConsumptionModal: FC<Params> = ({ editModalOpen, setEditModalOpen, energyConsumptionId, updateFn }) => {
  const [form] = Form.useForm();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [loading, setLoading] = useState(false);
  const [mcidOptions, setMcidOptions] = useState<mcidItem[]>([]);
  const [ouItems, setOuItems] = useState<OuItem[]>([]);
  const [otherMcidList, setOtherMcidList] = useState<string[]>([]);
  const [allOuList, setAllOutList] = useState<OuInfoProps[]>([]);
  const [detail, setDetail] = useState<V2EnergyAnalysisDetailPostResponse>();
  const [allOrganization, setAllOrganization] = useState<string[]>([]);
  const [mcidTreeOptions, setMcidTreeOptions] = useState<mcidTree[]>([]);
  const mcidList = useWatch('mcidList', form);
  const [firstFlag, setFirstFlag] = useState<boolean>(false);
  useEffect(() => {
    const uniqueArr = Array.from(new Set(mcidList));
    form.setFieldsValue({ mcidList: uniqueArr });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcidList]);

  // 查询所有 ou
  useEffect(() => {
    apiV2OuPagePost({ page: 1, size: 500, mcids: mcidList ?? [], ifCancel: false }).then(res => {
      setAllOutList(res.list as any);
    });
  }, [mcidList]);

  useEffect(() => {
    if (allOuList.length > 0 && editModalOpen && !firstFlag) {
      setFirstFlag(true);
      let displayMcidList = (detail?.customerList ?? [])
        .map(i => i.mcid)
        .filter(k => currentMcidList?.includes(k as string));

      const filteredUnits = allOuList.filter(unit => displayMcidList.includes(unit.mcid as string));
      const filtereData = filteredUnits.filter(unit => (detail?.ouIds ?? [])?.includes(unit.id as number));
      const mergedArray = (detail?.ouNames ?? []).map((item, index) => ({
        value: item.id,
        mcid: item.id,
        label: item.name,
        disabled: true,
      }));
      const filterData = mergedArray.filter(option => !allOuList.some(item => item.id === option.value));
      const filteredData = filterData.map(i => i.value);
      const filteredDetailData = filtereData.map(i => i.id) as number[];
      const mergeFilter = filteredData.concat(filteredDetailData);
      form.setFieldsValue({ ouIds: mergeFilter });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalOpen, allOuList]);

  useEffect(() => {
    if (organization && allOrganization && detail) {
      const organizationItem = flatten(
        //已选的所属组织
        detail?.customerList?.map(item => {
          return item.mcid;
        })
      );

      const differenceArr = difference(organizationItem, allOrganization);
      const disabledOrg = (detail?.customerList ?? [])
        .map(item => {
          if (differenceArr.includes(item.mcid)) {
            return {
              key: item.mcid,
              title: item.name,
              disabled: true,
            };
          }
          return null;
        })
        .filter(i => i !== null);
      const filterData = [...disabledOrg, ...mcidTreeOptions];
      const filteredData = filterData.filter((obj, index, self) => index === self.findIndex(t => t?.key === obj?.key));
      console.log(filteredData, 'filteredData');
      setMcidTreeOptions(filteredData as mcidTree[]);
      const uniqueArr = Array.from(new Set(organizationItem));
      form.setFieldsValue({ mcidList: uniqueArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, allOrganization, detail]);

  useEffect(() => {
    if (editModalOpen) {
      getOrganizationWithCodeWithCurrent().then(res => setOrganition(res));
    }
  }, [editModalOpen]);

  useEffect(() => {
    if (energyConsumptionId && organization && editModalOpen) {
      apiV2EnergyAnalysisDetailPost({ energyUnitId: energyConsumptionId as string }).then(res => {
        let { name, customerList } = res;
        const customer = (customerList ?? []).map(i => ({
          mcid: i.mcid as string,
          name: i.name as string,
        }));
        let displayMcidList = customer.map(i => i.mcid).filter(k => currentMcidList?.includes(k));
        let otherMcid = customer.map(i => i.mcid).filter(k => !currentMcidList?.includes(k));
        setOtherMcidList(otherMcid);
        setDetail(res);
        form.setFieldsValue({ name, mcidList: displayMcidList });
        setMcidOptions(customer);
      });
      apiV2EnergyAnalysisLinkedOuPagePost({ energyUnitId: energyConsumptionId as string }).then(res => {
        setOuItems(res.list ?? []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyConsumptionId, organization, editModalOpen]);

  const currentMcidList = useMemo(() => {
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map((k: nodeItem) => k.key);
    } else {
      return [];
    }
  }, [organization]);

  // 获取所属组织的key 用来和详情的组织key作比较
  const flattenTree = (tree: any, targetKey: string) => {
    const result: any = [];

    function traverse(node: any) {
      if (node[targetKey]) {
        result.push(node[targetKey]);
      }

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          traverse(child);
        }
      }
    }

    for (const node of tree) {
      traverse(node);
    }

    return result;
  };

  useEffect(() => {
    if (organization) {
      setAllOrganization(flattenTree(formatTreeData([organization]), 'key'));
      let tree = formatTreeData([organization]);
      const filterData = flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { key: k.key, title: k.title };
        });
      setMcidTreeOptions(filterData);
    } else {
      setMcidTreeOptions([]);
    }
  }, [organization]);

  const options = useMemo(() => {
    const mergedArray = (detail?.ouNames ?? []).map((item, index) => ({
      value: item.id,
      mcid: item.id,
      label: item.name,
      disabled: true,
    }));
    const filterData = mergedArray.filter(option => !allOuList.some(item => item.id === option.value));
    // 过滤被移除的 ou list, 显示未不可编辑状态
    return [
      ...allOuList.map(v => ({
        label: v.name,
        value: v.id,
        mcid: v.mcid,
      })),
      ...filterData,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOuList]);

  const handleMcidChange = (values: string[]) => {
    let currentOptions = mcidTreeOptions
      ?.filter((m: any) => values.includes(m.key))
      .map((m: any) => {
        return {
          mcid: m.key,
          name: m.title,
        };
      });
    let otherOptions = mcidOptions.filter(k => otherMcidList.includes(k.mcid));
    setMcidOptions([...currentOptions, ...otherOptions]);
    let newOuItems = ouItems.filter(
      m => values.includes(m.customerMcid as string) || otherMcidList.includes(m.customerMcid as string)
    );
    setOuItems([...newOuItems]);
  };

  const onCancel = () => {
    form.setFieldsValue({ mcidList: [], ouIds: undefined });
    setEditModalOpen(false);
    setFirstFlag(false);
  };

  const onSubmit = () => {
    form.validateFields().then(values => {
      const params = {
        mcidList: [...values.mcidList],
        name: values.name,
        ouIds: values.ouIds,
      };
      const request = () => apiV2EnergyAnalysisUpdatePost({ id: Number(energyConsumptionId), ...params });
      setLoading(true);
      request()
        .then(() => {
          onCancel();
          updateFn();
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Modal
      title="编辑用能分析组"
      open={editModalOpen}
      size="normal"
      destroyOnClose
      onCancel={() => {
        onCancel();
      }}
      onOk={onSubmit}
      contentClassName="modal-form-content"
    >
      <Spin spinning={loading}>
        <Form form={form} {...formLayout}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="名称"
                name="name"
                rules={[
                  { required: true, message: '请输入名称' },
                  { max: 50, message: '名称不超过50个字符' },
                ]}
              >
                <Input placeholder="请输入名称，不超过50个字" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="所属组织" name="mcidList" rules={[{ required: true, message: '请选择所属组织' }]}>
                <Select
                  onChange={handleMcidChange}
                  onDeselect={value => {
                    const ouIds = form.getFieldValue('ouIds') || [];
                    const removedOuIds = options.filter(opt => opt.mcid === value).map(opt => opt.value);
                    const updatedOuIds = ouIds.filter((id: number) => !removedOuIds.includes(id));
                    form.setFieldsValue({ ouIds: updatedOuIds });
                  }}
                  mode="multiple"
                  placeholder="请选择"
                  style={{ width: '100%' }}
                >
                  {mcidTreeOptions?.map((i: any) => (
                    <Select.Option key={i.key} value={i.key} disabled={i.disabled}>
                      {i.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="所属运营单元" name="ouIds" rules={[{ required: true, message: '请选择所属运营单元' }]}>
                <Select
                  onChange={handleMcidChange}
                  mode="multiple"
                  placeholder="请选择"
                  style={{ width: '100%' }}
                  options={options}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditEnergyConsumptionModal;
