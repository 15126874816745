import { EllipsisSpan, InputNumber, Modal, Select } from '@maxtropy/components';
import { Col, Form, Table } from 'antd';
import React, { useEffect } from 'react';
import { AllocationTypeOptions } from '../../utils';
import { ProcedureDtoType, ProductDtoType } from '../LifeCycleModel/const';
import { apiV2CarbonFootprintLcaAllocationPost } from '@maxtropy/device-customer-apis-v2';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateData?: () => void;
  productList?: ProductDtoType[];
  basicInfo?: ProcedureDtoType;
}

const CoefficientModal: React.FC<Props> = ({ open, setOpen, basicInfo, productList, updateData }) => {
  const [form] = Form.useForm();
  const tableData = Form.useWatch('table', form);

  useEffect(() => {
    form.setFieldsValue({
      method: basicInfo?.method,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  useEffect(() => {
    form.setFieldsValue({
      table: productList?.map(item => {
        return {
          ...item,
          quantityAndUnit: `${item.quantity}${item.unitCode}`,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  const onFinish = () => {
    form.validateFields().then(v => {
      const sum = v.table.reduce((prev: number, cur: any) => {
        return prev + cur.percent;
      }, 0);

      if (sum !== 100) {
        Modal.warning({
          title: '提示',
          content: '占比总和必须为100%',
        });
        return;
      }

      apiV2CarbonFootprintLcaAllocationPost({
        id: basicInfo?.id,
        method: v.method,
        allocationPercent: v.table.map((item: any) => ({
          id: item.id,
          percent: item.percent,
        })),
      }).then(res => {
        setOpen(false);
        updateData?.();
      });
    });
  };

  const columns = [
    {
      title: '类型',
      dataIndex: 'isMaster',
      width: 120,
      render: (v: string) => <EllipsisSpan value={v ? '主产品' : '副产品'} />,
    },
    {
      title: '名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数量/单位',
      dataIndex: 'quantityAndUnit',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '单位产品重量(KG)',
      dataIndex: 'unitWeight',
      width: 160,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '占比(%)',
      dataIndex: 'table',
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: true,
                message: '请输入占比',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const table = getFieldValue('table');

                  const byProductSum = table
                    ?.filter((k: any) => !k.isMaster)
                    .reduce((prev: number, cur: any) => {
                      return prev + cur.percent;
                    }, 0);

                  if (byProductSum >= 100) {
                    return Promise.reject(new Error('副产品占比总和需小于100'));
                  }

                  const masterIndex = tableData?.findIndex((k: { isMaster: number }) => k.isMaster === 1);
                  form.setFieldValue(['table', masterIndex, 'percent'], 100 - byProductSum);
                  return Promise.resolve();
                },
              }),
            ]}
            key={index}
            name={['table', index, 'percent']}
          >
            <InputNumber disabled={record?.isMaster} min={0} max={100} precision={2} placeholder="请输入" />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Modal title="设置分配系数" open={open} onOk={() => form.submit()} onCancel={() => setOpen(false)} size="large">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Col span={12}>
          <Form.Item
            label="分配方法"
            name="method"
            rules={[
              {
                required: true,
                message: '请选择',
              },
            ]}
          >
            <Select placeholder="请选择" options={AllocationTypeOptions} />
          </Form.Item>
        </Col>
        <Form.Item
          label="分配比例"
          name="table"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
          valuePropName="dataSource"
        >
          <Table rowKey="id" columns={columns} pagination={false} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CoefficientModal;
