import { Space, Tag } from 'antd';

export enum TimeIntervalType {
  SUMMIT = 1,
  PEAK = 2,
  PLAIN = 3,
  VALLEY = 4,
}

export const TimeIntervalTypeDisplay = {
  [TimeIntervalType.SUMMIT]: '尖',
  [TimeIntervalType.PEAK]: '峰',
  [TimeIntervalType.PLAIN]: '平',
  [TimeIntervalType.VALLEY]: '谷',
};

export const TimeIntervalTypeColor = {
  [TimeIntervalType.SUMMIT]: '#FF9247',
  [TimeIntervalType.PEAK]: '#CE90D1',
  [TimeIntervalType.PLAIN]: '#52E7FF',
  [TimeIntervalType.VALLEY]: '#57FB8B',
};

export const TimeIntervalTypeBorderColor = {
  [TimeIntervalType.SUMMIT]: 'rgba(255,146,71,0.5)',
  [TimeIntervalType.PEAK]: 'rgba(206,144,209,0.5)',
  [TimeIntervalType.PLAIN]: 'rgba(82,231,255,0.5)',
  [TimeIntervalType.VALLEY]: 'rgba(87,251,139,0.5)',
};

export const TimeIntervalTypeBackgroundColor = {
  [TimeIntervalType.SUMMIT]: 'rgba(255,146,71,0.1)',
  [TimeIntervalType.PEAK]: 'rgba(206,144,209,0.1)',
  [TimeIntervalType.PLAIN]: 'rgba(82,231,255,0.1)',
  [TimeIntervalType.VALLEY]: 'rgba(87,251,139,0.1)',
};

interface TimeIntervalShowTagProps {
  timeIntervalType: TimeIntervalType;
  value?: number | null;
}

const TimeIntervalShowTag: React.FC<TimeIntervalShowTagProps> = ({ timeIntervalType, value }) => {
  return (
    <Tag
      style={{
        backgroundColor: TimeIntervalTypeBackgroundColor[timeIntervalType],
        borderColor: TimeIntervalTypeBorderColor[timeIntervalType],
        color: TimeIntervalTypeColor[timeIntervalType],
      }}
    >
      <Space size={8}>
        <span>{TimeIntervalTypeDisplay[timeIntervalType]}</span>
        <span>{value ?? '--'}</span>
      </Space>
    </Tag>
  );
};

export default TimeIntervalShowTag;
