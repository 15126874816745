import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { DatePickerType } from '../../type';
import { ParamsOption } from '../../config';
import * as echarts from 'echarts';

export const icons = [
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM4QkQ0RUEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNCNkU3N0YiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjk4NkEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjZEMkMiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM0QTkwRTIiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
];

interface LegendProps {
  show: boolean;
  name?: string;
  baselineValue?: number;
  colors?: string[];
  isRect?: boolean;
  showWork?: boolean;
}

export type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
  seriesIndex: number;
};

// 通用化配置项
export const getNormalChartOption = (selectBtn?: DatePickerType, unit?: string, isBoxTip = false, max?: number) => {
  // const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 90,
      right: 30,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        left: 120,
        right: 120,
        height: 20,
        // backgroundColor: 'rgba(226,227,229,0.5)',
        // fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
      },
    ],
    // color: colors,
    tooltip:
      isBoxTip && selectBtn === DatePickerType.DAY
        ? {
            trigger: 'axis',
            // axisPointer: {
            //   type: 'cross',
            // },
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            textStyle: {
              color: '#fff',
            },
          }
        : getTooltip({
            unit,
            mode: selectBtn,
          }),
    xAxis: getNormalAxis(selectBtn),
    yAxis: {
      type: 'value',
      name: unit,
      nameTextStyle: {
        padding: -10,
        fontSize: 14,
        align: 'right',
        color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
      },
      nameGap: 30,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
      },
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

// tooltip 通用配置
export const getTooltip = ({ unit = '', mode }: { unit?: string; mode?: DatePickerType }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  padding: [8, 12],
  textStyle: {
    fontSize: 12,
    color: 'rgba(255,255,255,0.65)',
  },
  formatter(items: FormatterItem[]) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let dateStr: string;
    switch (mode) {
      case DatePickerType.MINUTE_1:
      case DatePickerType.MINUTE_15:
      case DatePickerType.HOUR:
        dateStr = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
        break;
      case DatePickerType.DAY:
        dateStr = dayjs(axisValue, 'x').format('MM-DD');
        break;
      default:
        dateStr = dayjs(axisValue, 'x').format('YYYY-MM');
    }
    let str = `<div style="width:140px;">${dateStr}</div>`;
    items.forEach(item => {
      const { seriesName, data } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
      str += `<div style="display:flex;justify-content:space-between;color:rgba(255,255,255,0.85);margin-top:8px;"><div>${seriesName}</div><div>${value}</div></div>`;
    });
    return str;
  },
});

// 图例通用配置
export const getLegend = ({ show, name, showWork }: LegendProps) => {
  let data = [
    {
      name: name,
      icon: 'rect',
    },
  ];
  if (showWork) {
    data.push(
      {
        name: '工作时间',
        icon: 'path://M228.864 64h566.784v896H228.864v-896z',
      },
      {
        name: '非工作时间',
        icon: 'path://M228.864 64h566.784v896H228.864v-896z',
      }
    );
  }

  return {
    show,
    right: 30,
    top: 50,
    itemGap: 24,
    textStyle: {
      color: '#AFBCC4',
    },
    data,
  };
};
const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
export const rangeCatXAxis = (key: string) => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: key === 'Hour' ? 60 * 1000 : key === 'Day' ? 60 * 1000 * 60 * 24 : 60 * 1000 * 60 * 24 * 28,
      axisLabel: {
        textStyle: {
          color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
        },
        formatter: function (e: number) {
          if (key === 'Hour') {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          } else if (key === 'Day') {
            return dayjs(e, 'x').format('MM-DD');
          } else {
            return dayjs(e, 'x').format('YYYY-MM');
          }
        },
      },
    },
  ];
};

export const getCommonMarkArea = (stateData: any, isWork: boolean) => {
  const color = isWork ? 'rgba(45,141,255,0.2)' : 'rgba(255,255,255,0.2)';
  return {
    markArea: {
      data: (stateData ?? [])
        .filter((m: any) => (isWork ? m.value === 1 : m.value === 0))
        .map((i: any) => {
          return [
            {
              xAxis: i.from,
              itemStyle: {
                color,
              },
            },
            {
              xAxis: i.to,
            },
          ];
        }),
    },
  };
};

export const getNormalAxis = (selectBtn?: DatePickerType) => {
  switch (selectBtn) {
    case DatePickerType.MINUTE_1:
    case DatePickerType.MINUTE_15:
    case DatePickerType.HOUR:
      return rangeCatXAxis('Hour');
    case DatePickerType.DAY:
      return rangeCatXAxis('Day');
    case DatePickerType.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

// 用电量
export const getTotalPowerConsumptionChart = ({ chartData, query, baselineConfigData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;

  const option = getNormalChartOption(query?.granularityType, unit);

  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,
    legend: getLegend({
      show: true,
      name: chartData?.[0]?.name, // 用电量
      colors,
    }),
    series: [
      ...(chartData ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#4F9FFF' },
            { offset: 1, color: '#2D8DFF' },
          ]),
        },
      })),
    ],
  };
};

// COP
export const getCOPChart = ({ chartData, query, stateData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);

  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,

    legend: {
      ...getLegend({
        show: true,
        name: chartData?.[0]?.name, // 总流量
        showWork: true,
      }),
      itemWidth: 14,
      itemHeight: 18,
    },

    series: [
      {
        type: 'line',
        color: 'rgba(45,141,255,0.2)',
        name: '工作时间',
        data: [],
        ...getCommonMarkArea(stateData, true),
      },
      {
        type: 'line',
        color: 'rgba(255,255,255,0.2)',
        name: '非工作时间',
        data: [],
        ...getCommonMarkArea(stateData, false),
      },
      ...(chartData ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,

        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#4F9FFF' },
            { offset: 1, color: '#2D8DFF' },
          ]),
        },
      })),
    ],
  };
};

// 制冷量
export const getCoolingChart = ({ chartData, query, stateData }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;
  const option = getNormalChartOption(query?.granularityType, unit);

  const colors = ['#1890ff', '#4fc7ec'];
  return {
    ...option,

    legend: getLegend({
      show: true,
      name: chartData?.[0]?.name, // 总流量
      colors,
    }),

    series: [
      ...(chartData ?? []).map(item => ({
        type: 'bar',
        color: colors,
        name: item.name,
        data: (item.chartValues ?? []).map(a => {
          return [a.ts, a.value];
        }),
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: '#4F9FFF' },
            { offset: 1, color: '#2D8DFF' },
          ]),
        },
      })),
    ],
  };
};

// 瞬时功率
export const getInstantaneousPowerChart = ({ chartData, query }: ParamsOption) => {
  const unit = chartData?.[0]?.physicalUnitName;

  const option = getNormalChartOption(query?.granularityType, unit, true);

  const colors = ['#00ADFF'];
  return {
    ...option,

    legend: getLegend({
      show: true,
      name: chartData?.[0]?.name, // 瞬时功率
      colors,
      isRect: false,
    }),
    series: [
      ...(chartData ?? []).map(item => {
        if (query?.granularityType === DatePickerType.DAY) {
          return {
            name: chartData?.[0]?.name,
            type: 'custom',
            color: colors,
            dimensions: [
              '-',
              `最大${chartData?.[0]?.name}`,
              `平均${chartData?.[0]?.name}`,
              `最小${chartData?.[0]?.name}`,
            ],
            itemStyle: {
              borderWidth: 1.5,
            },
            renderItem: function (params: any, api: any) {
              var xValue = api?.value(0);
              var highPoint = api?.coord([xValue, api.value(1)]);
              var avgPoint = api?.coord([xValue, api.value(2)]);
              var lowPoint = api?.coord([xValue, api.value(3)]);
              // var halfWidth = api?.size([0, 1])[1] / 2;
              // var offsetWidth =
              //   chartDataLength === 1 ? index : chartDataLength === 2 ? (2 * index - 1) * 8 : 16 * index - 16;
              // console.log(offsetWidth);
              var halfWidth = 8;
              var style = api?.style({
                stroke: api?.visual('color'),
                fill: undefined,
              });
              return {
                type: 'group',
                children: [
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0] - halfWidth,
                      y1: highPoint[1],
                      x2: highPoint[0] + halfWidth,
                      y2: highPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: highPoint[0],
                      y1: highPoint[1],
                      x2: lowPoint[0],
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: avgPoint[0] - halfWidth,
                      y1: avgPoint[1],
                      x2: avgPoint[0] + halfWidth,
                      y2: avgPoint[1],
                    },
                    style: style,
                  },
                  {
                    type: 'line',
                    transition: ['shape'],
                    shape: {
                      x1: lowPoint[0] - halfWidth,
                      y1: lowPoint[1],
                      x2: lowPoint[0] + halfWidth,
                      y2: lowPoint[1],
                    },
                    style: style,
                  },
                ],
              };
            },
            encode: {
              x: 0,
              y: [1, 2, 3],
              tooltip: [1, 2, 3],
            },
            data: (item?.chartValues ?? []).map((a, index) => {
              return [a.ts, a.value?.toFixed(2)];
            }),
          };
        } else {
          return {
            type: 'line',
            color: colors,
            name: item.name,
            data: (item.chartValues ?? []).map(a => {
              return [a.ts, a.value];
            }),
            smooth: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#89CFFF20' },
                { offset: 1, color: '#51A1FF10' },
              ]),
            },
          };
        }
      }),
    ],
  };
};
