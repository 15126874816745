import {
  useBreadcrumbRoutes,
  Button,
  Wrapper,
  Modal,
  Select,
  PopConfirm,
  Form,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Row, Space } from 'antd';

import styles from './index.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTemplateByVersion, getAllTemplateList, TemplateListProps, Template } from '../../../api/template';
import {
  getDeviceAttribute,
  DeviceAttributeResponse,
  updateDeviceAttribute,
  DevicePropertyResponse,
  getDeviceProperty,
} from '../../../api/device';
import AttributeItem from '@/shared/components/AttributeItem';

import BaseInfo from '../components/BaseInfo';

import { FieldValue } from '@/shared/types';
import { formatValueFn, buildValueFn } from '@/shared/components/FieldItem/util';
import { CustomAttribute } from '@/shared/components/CustomAttributes/Editor';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';

const routes = [{ name: '固定信息' }];

const CreateAttributeInfo: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [templateList, setTemplateList] = useState<TemplateListProps[]>([]);
  const [currentTemplateId, setTemplateId] = useState<number>();
  const [currentVersion, setCurrentVersion] = useState<number>();
  const [template, setTemplate] = useState<Template>();
  const [form] = Form.useForm();
  const [templateForm] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>([]);

  const [typeId, setTypeId] = useState<number>();

  const [deviceProperty, setDeviceProperty] = useState<DevicePropertyResponse>();

  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setDeviceProperty(res);
        setTypeId(res.typeId);
      });
    }
  }, [id]);

  useEffect(() => {
    if (typeId !== undefined) {
      getAllTemplateList(typeId, Number(deviceProperty?.physicalModelId)).then(res => setTemplateList(res.list ?? []));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeId]);

  useEffect(() => {
    setTemplate(undefined);
    if (currentTemplateId !== undefined && currentVersion !== undefined) {
      getTemplateByVersion(currentTemplateId, currentVersion).then(res => {
        setTemplate(res.template);
      });
    }
    templateForm.setFieldsValue({
      template: currentTemplateId,
    });
  }, [currentTemplateId, currentVersion, templateForm]);

  const onTemplateChange = (value: number) => {
    setTemplateId(undefined);
    setCurrentVersion(undefined);
    if (templateList.length) {
      const template = templateList.find(i => i.id === value);
      if (template) {
        setTemplateId(value);
        setCurrentVersion(template.lastVersion);
      }
    }
  };

  const [deviceAttribute, setDeviceAttribute] = useState<DeviceAttributeResponse>();

  useEffect(() => {
    if (id) {
      getDeviceAttribute(id).then(res => {
        setDeviceAttribute(res);
        if (res.templateId && res.templateVersion) {
          setTemplateId(res.templateId);
          setCurrentVersion(res.templateVersion);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (template) {
      setCustomAttributes(template?.description ? JSON.parse(template?.description) : []);
    }
  }, [template]);

  const templateFlatten = useMemo(() => {
    if (template) {
      return template.deviceAttributes.map(i => i.deviceFields).flat();
    } else {
      return [];
    }
  }, [template]);

  useEffect(() => {
    if (deviceAttribute) {
      if (deviceAttribute.list && deviceAttribute.list.length) {
        const data = deviceAttribute.list.reduce((accumulator, currentValue) => {
          const findTemplate = templateFlatten.find(i => i.id === currentValue.fieldId);
          const buildValue = buildValueFn(currentValue.value!, findTemplate);
          accumulator[currentValue.fieldId] = { unitId: currentValue.unitId, value: buildValue };
          return accumulator;
        }, {} as Record<string, FieldValue>);
        form.setFieldsValue(data);
      }
    }
  }, [deviceAttribute, form, templateFlatten]);

  useEffect(() => {
    if (deviceAttribute) {
      if (deviceAttribute.templateId) {
        setTemplateId(deviceAttribute.templateId);
        setCurrentVersion(deviceAttribute.templateVersion);
      }
    }
  }, [deviceAttribute]);

  const onFinish = (type: boolean) => {
    form
      .validateFields()
      .then(() => {
        const v: { [key: string]: FieldValue } = form.getFieldsValue();
        const list = Object.entries(v).map(([fieldId, value]) => {
          const findTemplate = templateFlatten.find(i => i.id === Number(fieldId));
          const formatValue = formatValueFn(value.value, findTemplate);
          return { fieldId: Number(fieldId), unitId: value.unitId, value: formatValue };
        });
        updateDeviceAttribute({
          deviceId: Number(id),
          templateId: currentTemplateId!,
          templateVersion: currentVersion!,
          list,
        }).then(res => {
          if (type) {
            navigate(`/device/manage/device/${res.deviceId}/dataMining/new?hasPrevious=true`);
          } else {
            navigate(`/device/manage/device`);
          }
        });
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      okText: '确定',
      onOk: () => {
        navigate(`/device/manage/device`);
      },
    });
  };

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title={`${isEdit ? '编辑' : '新建'}固定信息`}></FormTitle>
      <SubContent title="设备信息">
        <BaseInfo data={deviceProperty} />
      </SubContent>
      <SubContent title="固定信息">
        <Form form={templateForm} layout="vertical">
          <Row>
            <Col span={8}>
              <Form.Item name="template" label="模板选择">
                <Select placeholder="请选择" onChange={onTemplateChange}>
                  {templateList.map(i => (
                    <Select.Option key={i.id} value={i.id}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="版本号">
                {template && currentVersion
                  ? (
                      <Space>
                        <div className={styles.version}>{currentVersion}</div>
                        {template?.lastVersion > currentVersion && (
                          <div className={styles.tip}>
                            当前扩展信息模板版本号为 {currentVersion}，最新版本号为{template?.lastVersion}，可以
                            <PopConfirm
                              title={
                                <div className={styles.popConfirm}>
                                  <div className={styles.title}>确认更新?</div>
                                  <div className={styles.red}>更新至最新版本的模版，可能会导致</div>
                                  <div className={styles.gray}>
                                    <span className={styles.red}>部分信息丢失，</span>你还要继续吗？
                                  </div>
                                </div>
                              }
                              onConfirm={() => {
                                setCurrentVersion(template?.lastVersion);
                              }}
                              okText="继续"
                              cancelText="取消"
                            >
                              <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                点击更新
                              </Button>
                            </PopConfirm>
                            更新到最新版本
                          </div>
                        )}
                      </Space>
                    ) ?? '--'
                  : '--'}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="模版备注">{template?.remark ?? '--'}</Form.Item>
            </Col>
          </Row>
        </Form>
        {template && (
          <div>
            <div className={styles.body}>
              <div className={styles.list}>
                <Form form={form} layout="vertical" validateTrigger="onBlur">
                  {template.deviceAttributes.map((item, index) => (
                    <AttributeItem key={index} disabled={false} attribute={item} />
                  ))}
                </Form>
              </div>
              {!!customAttributes.length && (
                <CustomAttributesDisplay
                  style={{ padding: '16px 0 24px', borderTop: '1px dashed rgba(var(--base-text-color), .12)' }}
                  customAttributes={customAttributes}
                />
              )}
            </div>
            <Space size={8} className={styles.stickyFooter}>
              {!isEdit && (
                <>
                  <Button type="primary">
                    <Link to={`/device/manage/device/create/${id}`}>上一步</Link>
                  </Button>
                  {!deviceProperty?.thirdParty && (
                    <Button
                      type="primary"
                      onClick={() => {
                        onFinish(true);
                      }}
                    >
                      保存并添加数采信息模板
                    </Button>
                  )}
                </>
              )}
              <Button
                type="primary"
                onClick={() => {
                  onFinish(false);
                }}
              >
                确定
              </Button>
              <Button className={styles.button} onClick={onCacel}>
                取消
              </Button>
            </Space>
          </div>
        )}
      </SubContent>
    </Wrapper>
  );
};

export default CreateAttributeInfo;
