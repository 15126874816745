import { CircuitProps } from '@/api/uet';
import { Col, Row } from 'antd';
import styles from '../index.module.scss';
interface Iprops {
  data?: CircuitProps[];
}
const CircuitGrid = (props: Iprops) => {
  return (
    <Row gutter={[8, 8]}>
      {props.data?.map(item => {
        return (
          <Col span={6}>
            <div className={styles.circuit_box}>
              <div className={styles.left}>{item?.name ?? '--'}</div>
              <div className={styles.divider}></div>
              <div className={styles.right}>总计量表：{item?.gaugeHost?.name ?? '--'}</div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default CircuitGrid;
