import { FC, useEffect, useState } from 'react';

import { Divider, Space } from 'antd';

import Month from '../components/Month';
import Day, { TimeSpace } from '../components/Day';
import dayjs from 'dayjs';
import { TimeOfUseTariffType, PricedisplayType, PricedisplayTypeDisplay, EPriceTime } from '@/shared/types';
import PeakItem from '../components/EpriceTime/PeakItem';
import { getItemTime, hasType, isFullDay } from '../components/EpriceTime/utils';
import {
  EllipsisSpan,
  Table,
  useUpdate,
  Button,
  SubContent,
  Form,
  Radio,
  InputNumber,
  Modal,
  Button as AntdButton,
} from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import {
  createElectricRatePrice,
  deleteElectricRatePrice,
  ElectricityContractVo,
  getElectricRatePriceList,
  RateElectricityPriceDetailVo,
  RateElectricityPriceRequest,
  RateElectricityPriceVo,
  updateElectricRatePrice,
} from '../../../../../api/electricity';
import { ColumnType } from 'antd/es/table';
import EPricePeakItem from '../components/EpriceTime/PeakItem';

import styles from './index.module.scss';
import { toPrecision } from '../util';

const formLayout = {
  labelCol: { flex: '110px' },
};

const formatString = 'YYYY-MM-DD';

export const TimeOfUseTariffTypePrice = {
  [TimeOfUseTariffType.SUMMIT]: 'summitEnergyRate',
  [TimeOfUseTariffType.PEAK]: 'peakEnergyRate',
  [TimeOfUseTariffType.PLAIN]: 'plainEnergyRate',
  [TimeOfUseTariffType.VALLEY]: 'valleyEnergyRate',
};
export interface CreatePriceFormProps {
  displayType: PricedisplayType;
  recalculate: boolean;
  price: EPriceTime[];
  summitEnergyRate?: string;
  peakEnergyRate?: string;
  plainEnergyRate?: string;
  valleyEnergyRate?: string;
}

export interface CreateMonthFormProps extends CreatePriceFormProps {
  months: number[];
}

export interface CreateDayFormProps extends CreatePriceFormProps {
  day: TimeSpace;
}

export interface ElectricRatePriceProps {
  contract?: ElectricityContractVo;
  disabled?: boolean;
}

function getPriceTimeIndex(record: RateElectricityPriceVo) {
  const data: EPriceTime[] = (record.details ?? []).map(i => {
    const coefficient = record.displayType === PricedisplayType.HALF ? 2 : 1;
    const [startHoursStr, startMinuteStr] = i.startTime.split(':');
    const startIndex =
      Number(startHoursStr) * coefficient +
      (record.displayType === PricedisplayType.HALF ? (startMinuteStr === '00' ? 0 : 1) : 0);
    const [endHoursStr, endMinuteStr] = i.endTime.split(':');
    const endIndex =
      Number(endHoursStr) * coefficient +
      (record.displayType === PricedisplayType.HALF ? (endMinuteStr === '00' ? 0 : 1) : 0);
    return {
      type: i.type,
      intervalStart: startIndex,
      intervalEnd: endIndex === 0 ? 24 * coefficient - 1 : endIndex - 1,
    };
  });
  return data;
}

function getTimeString(record: CreatePriceFormProps, precision: number) {
  const price = record.price.map(i => {
    const end =
      record.displayType === PricedisplayType.HALF
        ? i.intervalEnd + 1 === 48
          ? 0
          : i.intervalEnd + 1
        : i.intervalEnd + 1 === 24
        ? 0
        : i.intervalEnd + 1;
    const res: RateElectricityPriceDetailVo = {
      type: i.type,
      price: toPrecision(
        record[
          TimeOfUseTariffTypePrice[i.type] as Exclude<
            keyof CreatePriceFormProps,
            'displayType' | 'recalculate' | 'price'
          >
        ]!,
        precision ?? 0
      )!,
      startTime: getItemTime(i.intervalStart, record.displayType),
      endTime: getItemTime(end, record.displayType),
    };
    return res;
  });
  return price;
}

const columns: ColumnType<RateElectricityPriceVo>[] = [
  {
    title: '日期',
    dataIndex: 'startDate',
    width: 200,
    render: (v: string, record) => (
      <EllipsisSpan value={`${dayjs(v).format(formatString)} ~ ${dayjs(record.endDate).format(formatString)}`} />
    ),
  },
  {
    title: '',
    dataIndex: 'details',
    ellipsis: { showTitle: true },
    render: (v: RateElectricityPriceDetailVo[], record) => {
      const data = getPriceTimeIndex(record);
      const price = new Map<Exclude<keyof CreatePriceFormProps, 'displayType' | 'recalculate' | 'price'>, string>();
      record.details?.forEach(i => {
        price.set(
          TimeOfUseTariffTypePrice[i.type] as Exclude<
            keyof CreatePriceFormProps,
            'displayType' | 'recalculate' | 'price'
          >,
          i.price
        );
      });
      return (
        <Space direction="vertical">
          <EPricePeakItem displayType={record.displayType} disabled value={data} />
          <Space>
            {price.get('summitEnergyRate') ? (
              <div style={{ marginRight: 10 }}>
                尖峰电价: <span className={styles.color}>{price.get('summitEnergyRate')}</span> 元/kWh
              </div>
            ) : null}
            {price.get('peakEnergyRate') ? (
              <div style={{ marginRight: 10 }}>
                高峰电价: <span className={styles.color}>{price.get('peakEnergyRate')}</span> 元/kWh
              </div>
            ) : null}
            {price.get('plainEnergyRate') ? (
              <div style={{ marginRight: 10 }}>
                平段电价: <span className={styles.color}>{price.get('plainEnergyRate')}</span> 元/kWh
              </div>
            ) : null}
            {price.get('valleyEnergyRate') ? (
              <div style={{ marginRight: 10 }}>
                低谷电价: <span className={styles.color}>{price.get('valleyEnergyRate')}</span> 元/kWh
              </div>
            ) : null}
          </Space>
        </Space>
      );
    },
  },
];

const ElectricRatePrice: FC<ElectricRatePriceProps> = ({ contract, disabled = false }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();

  const [rates, setRates] = useState<RateElectricityPriceVo[]>([]);

  useEffect(() => {
    if (contract) {
      setLoading(true);
      getElectricRatePriceList(contract.id).then(res => {
        setLoading(false);
        setRates(res.list || []);
      });
    }
  }, [contract, updateState]);

  const onDelete = (record: RateElectricityPriceVo) => {
    setLoading(true);
    deleteElectricRatePrice(record.id)
      .then(() => {
        updateFn();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buildColumns = [
    ...columns,
    ...(!disabled
      ? [
          {
            title: '操作',
            fixed: 'right' as 'right',
            width: 280,
            render: (record: RateElectricityPriceVo) => (
              <Space size={16}>
                <Button
                  type="link"
                  onClick={() => {
                    onDelete(record);
                  }}
                >
                  删除
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    onEdit(record);
                  }}
                >
                  编辑
                </Button>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const [monthVisible, setMonthVisible] = useState<boolean>(false);
  const [monthsForm] = Form.useForm();

  const onAddMonths = () => {
    setMonthVisible(true);
  };

  const onMonthCancel = () => {
    setMonthVisible(false);
    monthsForm.resetFields();
  };

  const onMonthSubmit = (recalculate: boolean) => {
    monthsForm.validateFields().then((value: CreateMonthFormProps) => {
      if (contract) {
        const price = getTimeString(value, contract.precision);
        const request = value.months.map(i => {
          const end = dayjs(i);
          let start = dayjs(end).startOf('month').valueOf();
          start = dayjs(start).isAfter(dayjs(contract.startDate).startOf('date'))
            ? start
            : dayjs(contract.startDate).startOf('date').valueOf();
          return {
            electricityContractId: contract.id,
            displayType: value.displayType,
            startDate: dayjs(start).format(formatString),
            endDate: dayjs(i).format(formatString),
            details: price,
            recalculate,
          } as RateElectricityPriceRequest;
        });

        createElectricRatePrice(request).then(() => {
          onMonthCancel();
          updateFn();
        });
      }
    });
  };

  const [daysForm] = Form.useForm();

  const [rateElectricityPrice, setRateElectricityPrice] = useState<Partial<RateElectricityPriceVo>>();

  const onAddDay = () => {
    setRateElectricityPrice({});
  };

  const onEdit = (record: RateElectricityPriceVo) => {
    setRateElectricityPrice(record);
  };

  const onDayCancel = () => {
    daysForm.resetFields();
    setRateElectricityPrice(undefined);
  };

  useEffect(() => {
    if (rateElectricityPrice && rateElectricityPrice.id) {
      const price = new Map<Exclude<keyof CreatePriceFormProps, 'displayType' | 'recalculate' | 'price'>, string>();
      rateElectricityPrice.details?.forEach(i => {
        price.set(
          TimeOfUseTariffTypePrice[i.type] as Exclude<
            keyof CreatePriceFormProps,
            'displayType' | 'recalculate' | 'price'
          >,
          i.price
        );
      });
      const data = getPriceTimeIndex(rateElectricityPrice as RateElectricityPriceVo);
      daysForm.setFieldsValue({
        displayType: rateElectricityPrice.displayType,
        price: data,
        summitEnergyRate: price.get('summitEnergyRate'),
        peakEnergyRate: price.get('peakEnergyRate'),
        plainEnergyRate: price.get('plainEnergyRate'),
        valleyEnergyRate: price.get('valleyEnergyRate'),
        day: [
          dayjs(rateElectricityPrice.startDate).startOf('date').valueOf(),
          dayjs(rateElectricityPrice.endDate).endOf('date').valueOf(),
        ],
      });
    }
  }, [rateElectricityPrice, daysForm]);

  const onDaySubmit = (recalculate: boolean) => {
    daysForm.validateFields().then((value: CreateDayFormProps) => {
      if (contract) {
        const price = getTimeString(value, contract.precision);
        const request: RateElectricityPriceRequest = {
          electricityContractId: contract.id,
          displayType: value.displayType,
          startDate: dayjs(value.day[0]).format(formatString),
          endDate: dayjs(value.day[1]).format(formatString),
          details: price,
          recalculate,
          rateElectricityPriceId: rateElectricityPrice?.id!,
        };
        console.log(request);

        if (rateElectricityPrice && rateElectricityPrice.id) {
          // 编辑
          updateElectricRatePrice(request).then(() => {
            onDayCancel();
            updateFn();
          });
        } else {
          createElectricRatePrice([request]).then(() => {
            // 更新
            onDayCancel();
            updateFn();
          });
        }
      }
    });
  };

  const [displayType, setDisplayType] = useState<PricedisplayType>();

  useEffect(() => {
    if (displayType !== undefined) {
      monthsForm.setFieldsValue({
        price: [],
      });
      daysForm.setFieldsValue({
        price: [],
      });
    }
  }, [displayType, monthsForm, daysForm]);

  const utilForms = (
    <>
      <Form.Item name="displayType" label="时间展示类型" rules={[{ required: true, message: '请选择时间展示类型' }]}>
        <Radio.Group
          onChange={e => {
            setDisplayType(e.target.value);
          }}
        >
          <Radio value={PricedisplayType.HALF}>{PricedisplayTypeDisplay[PricedisplayType.HALF]}展示</Radio>
          <Radio value={PricedisplayType.HOUR}>{PricedisplayTypeDisplay[PricedisplayType.HOUR]}展示</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle dependencies={['displayType']}>
        {({ getFieldValue }) => {
          const displayType = getFieldValue('displayType');
          if (displayType === undefined) return null;
          return (
            <Form.Item
              name="price"
              rules={[
                {
                  validator: (_, value) => {
                    if (isFullDay(value || [], displayType)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('请选满24小时');
                  },
                  validateTrigger: 'onBlur',
                },
              ]}
            >
              <PeakItem displayType={displayType} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          hasType(getFieldValue('price'), TimeOfUseTariffType.SUMMIT) && (
            <Form.Item name="summitEnergyRate" label="尖峰电价" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                stringMode
                precision={contract?.precision ?? 0}
                placeholder="请输入"
                style={{ width: 260 }}
                addonAfter="元/kWh"
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          hasType(getFieldValue('price'), TimeOfUseTariffType.PEAK) && (
            <Form.Item name="peakEnergyRate" label="高峰电价" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                stringMode
                precision={contract?.precision ?? 0}
                placeholder="请输入"
                style={{ width: 260 }}
                addonAfter="元/kWh"
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          hasType(getFieldValue('price'), TimeOfUseTariffType.PLAIN) && (
            <Form.Item name="plainEnergyRate" label="平段电价" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                stringMode
                precision={contract?.precision ?? 0}
                placeholder="请输入"
                style={{ width: 260 }}
                addonAfter="元/kWh"
              />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          hasType(getFieldValue('price'), TimeOfUseTariffType.VALLEY) && (
            <Form.Item name="valleyEnergyRate" label="低谷电价" rules={[{ required: true }]}>
              <InputNumber
                min={0}
                stringMode
                precision={contract?.precision ?? 0}
                placeholder="请输入"
                style={{ width: 260 }}
                addonAfter="元/kWh"
              />
            </Form.Item>
          )
        }
      </Form.Item>
    </>
  );

  return (
    <>
      <SubContent
        title={
          <Space size={8}>
            <span>电度电价</span>
            {!disabled && (
              <Space size={8}>
                <Button type="primary" onClick={onAddMonths} icon={<PlusOutlined />}>
                  <span>按月新增</span>
                </Button>
                <Button type="primary" onClick={onAddDay} icon={<PlusOutlined />}>
                  <span>按日新增</span>
                </Button>
              </Space>
            )}
          </Space>
        }
      >
        <Table rowKey="id" scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={rates} />
      </SubContent>
      <Modal
        size="large"
        title="按月新增-电度电价"
        contentClassName="modal-form-content"
        open={monthVisible}
        onCancel={onMonthCancel}
        okText="保存"
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        footer={
          <Space size={8}>
            <AntdButton
              type="primary"
              onClick={() => {
                onMonthSubmit(true);
              }}
            >
              保存后重算
            </AntdButton>
            <AntdButton
              type="primary"
              onClick={() => {
                onMonthSubmit(false);
              }}
            >
              保存不重算
            </AntdButton>
            <AntdButton onClick={onMonthCancel}>取消</AntdButton>
          </Space>
        }
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={monthsForm}>
            <Form.Item name="months" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Month
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={rates.map(i => [
                  dayjs(i.startDate).startOf('date').valueOf(),
                  dayjs(i.endDate).endOf('date').valueOf(),
                ])}
              />
            </Form.Item>
            <Divider />
            {utilForms}
          </Form>
        )}
      </Modal>
      <Modal
        size="large"
        title={rateElectricityPrice?.id ? '电度电价-编辑' : '按日新增-电度电价'}
        open={!!rateElectricityPrice}
        onCancel={onDayCancel}
        contentClassName="modal-form-content"
        okText="保存"
        bodyStyle={{
          maxHeight: 400,
          overflowY: 'auto',
        }}
        footer={
          <Space size={8}>
            <AntdButton
              type="primary"
              onClick={() => {
                onDaySubmit(true);
              }}
            >
              保存后重算
            </AntdButton>
            <AntdButton
              type="primary"
              onClick={() => {
                onDaySubmit(false);
              }}
            >
              保存不重算
            </AntdButton>
            <AntdButton onClick={onDayCancel}>取消</AntdButton>
          </Space>
        }
      >
        {!!contract && (
          <Form labelAlign="left" labelWrap {...formLayout} form={daysForm}>
            <Form.Item name="day" label="选择日期" rules={[{ required: true, message: '请选择合同生效日期' }]}>
              <Day
                startTime={dayjs(contract.startDate).startOf('date')}
                endTime={dayjs(contract.endDate).endOf('date')}
                disabledTimes={rates
                  .filter(i => i.id !== rateElectricityPrice?.id)
                  .map(i => [dayjs(i.startDate).startOf('date').valueOf(), dayjs(i.endDate).endOf('date').valueOf()])}
              />
            </Form.Item>
            <Divider />
            {utilForms}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default ElectricRatePrice;
