export interface DataBom {
  code: string;
  key: number;
  name: string;
  spec: string;
  quantity: number;
  children?: DataBom[];
  parentId: number;
}

export interface outputDataType {
  id: number;
  name: string;
  code: string;
  isUseCode: boolean;
  type: number;
  spec: string;
  unitTypeCode: number;
  unitCode: string;
  remark: string;
  characteristic: string;
  tenantMcid: string;
  createTime: string;
  updateTime: string;
  deleted: boolean;
  hasOutputAmount: boolean;
  state: number;
  categoryNames: string[];
  baseLines: any[];
  outputProductLink: any[];
  outputProductivityInformation: any[];
  categoryId: number;
  unitOutputValue: number;
}

export interface FinishModelType {
  outputProductId?: number;
  code?: string;
  name?: string;
  spec?: string;
  quantity?: number;
  unitTypeCode?: number;
  unitCode?: string;
  dataOrigin?: number;
  standardYear?: number;
  boundary?: number;
  madeIn?: string;
  remark?: string;
}

export enum StepsType {
  First = 0,
  Second,
  Third,
}
