import React, { useEffect, useState } from 'react';
import { Timeline } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import styles from './index.module.scss';
import { Alert, Button, Modal } from '@maxtropy/components';

export interface ErrorMsgModalProps {
  visible: boolean;
  onCancel: () => void;
  errorMsg?: string[];
  serial: string;
}

const ErrorMsgModal: React.FC<ErrorMsgModalProps> = ({ visible, onCancel, errorMsg, serial }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopyClick = () => {
    if (errorMsg) {
      const text = errorMsg.join('\n');
      const copyText = `模版编号：${serial}\n${text}`;
      copy(copyText);
      setCopied(true);
    }
  };

  useEffect(() => {
    let copyId: number;
    if (copied) {
      copyId = window.setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    return () => clearInterval(copyId);
  }, [copied]);

  return (
    <Modal
      destroyOnClose
      size="large"
      title="错误信息"
      open={visible}
      footer={[
        <Button type="primary" onClick={onCopyClick}>
          复制错误信息
        </Button>,
        <Button type="primary" onClick={onCancel}>
          确定
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <span className={styles.icon}>
            <FileOutlined />
          </span>
          模版编号：{serial}
        </div>
        <div className={styles.content}>
          <Timeline>
            {errorMsg?.map(item => (
              <Timeline.Item>{item}</Timeline.Item>
            ))}
          </Timeline>
        </div>
        {copied && (
          <div className={styles.alert}>
            <Alert message="复制成功！" type="success" showIcon closable />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ErrorMsgModal;
