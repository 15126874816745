import {
  EnergyMediumTreeFormatType,
  EnergyMediumTreeFormatTypeDisplay,
  EnergyMediumTreeFormatTypeDisplayLabelColor,
  getMediumIndicatorProcessStatistics,
  getMediumIndicatorStatistics,
  getProcessEntryOrExitMonitoringIndicators,
  MediumIndicatorStatisticsReq,
  MediumIndicatorStatisticsRes,
  ProcessEntryOrExitMonitoringIndicatorsRes,
} from '@/api/energyMedium';
import { PermissionsType } from '@/common/permissionsConst';
import DragResize from '@/components/DragResize';
import FooterTip from '@/pages/WaterDashBoard/components/FooterTip';
import { useHasPermission } from '@/utils/utils';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Empty, Tag, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse } from '@maxtropy/device-customer-apis';
import { Layout, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import qs from 'qs';
import { createContext, FC, Key, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CardForData from './components/CardForData';
import CardForProcessData from './components/CardForProcessData';
import Chart from './components/Chart';
import DateSwitch, { StatisticsPartition } from './components/DateSwitch';
import MonitoringIndicatorsTab from './components/MonitoringIndicatorsTab';
import ProcessChartsTab from './components/ProcessChartsTab';
import ProcessTree from './components/ProcessTree';
import ViewEnergyMediumTopo from './components/ViewEnergyMediumTopo';
import ViewProcessTopo from './components/ViewProcessTopo';
import { energyMediumBtn, energyMediumBtns, getCardData } from './config';
import styles from './index.module.scss';
import { formatProcessEntryOrExitCardData, getDefaultTime, getTs, isEntryExit } from './utils';
import {
  apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost,
  V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const { Content, Sider } = Layout;

export interface EnergyMediumInfoProps {
  topoId: number;
  id: number;
  name: string;
  type: EnergyMediumTreeFormatType;
  processId?: number;
}

export interface IEnergyMediumDashboard {
  sceneId: number;
}

const PROCESS = '过程';
const uetUnitType = 5; // 拓扑类型
export const MediumUnitDataContext = createContext(
  {} as MediumIndicatorDashboardGetMediumIndicatorDisplayUnitPostResponse | undefined
);

const EnergyMediumDashBoard: FC<IEnergyMediumDashboard> = props => {
  const { sceneId } = props;
  const routesContext = useBreadcrumbRoutes();

  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]); // 当前树KEY
  const [currentTopoId, setCurrentTopoId] = useState<number | undefined>(); // 选择的topo
  const [energyMediumInfo, setEnergyMediumInfo] = useState<EnergyMediumInfoProps>(); // 选择能源介质过程或者出口保存信息
  const [query, setQuery] = useState<MediumIndicatorStatisticsReq>();

  const [loading, setLoading] = useState(false); // 加载状态
  const [cardData, setCardData] = useState<any>(); // 卡片值
  const [activeKey, setActiveKey] = useState<string>(); // Tab选择
  const [processActiveKey, setProcessActiveKey] = useState<string>('0'); // 输入输出Tab选择
  const [tabData, setTabData] = useState<ProcessEntryOrExitMonitoringIndicatorsRes[]>([]);
  const [currentItem, setCurrentItem] = useState<energyMediumBtn>(); // 保存每一个选择的信息
  const [chartData, setChartData] = useState<MediumIndicatorStatisticsRes>(); // 图表数据
  const [noMonitorData, setNoMonitorData] = useState(false);

  const defaltProcessFlagRef = useRef<boolean>(false); // 控制 在切换Tab的时候记住 时间选择与颗粒度选择
  const defaltEntryExitFlagRef = useRef<boolean>(false); // 控制 在切换Tab的时候记住 时间选择与颗粒度选择

  const [dateChangeLoading, setDateChangeLoading] = useState<boolean>(false);

  const hasGasPermission = useHasPermission(PermissionsType.B_GASDBIMPORT); // 用气
  const hasSteamPermission = useHasPermission(PermissionsType.B_STEAMDBIMPORT); // 热蒸汽
  const hasWaterPermission = useHasPermission(PermissionsType.B_WATERDBIMPORT); // 用水
  const hasCompressedAirPermission = useHasPermission(PermissionsType.B_COMPRESSEDAIRDBIMPORT); //  空气看板
  const hasIndustryGasPermission = useHasPermission(PermissionsType.B_INDUSTRYGASDBIMPORT); //  工业气体
  const hasHVACairPermission = useHasPermission(PermissionsType.B_HVACAIRDBIMPORT); // HVAC看板
  const hasLiquidPermission = useHasPermission(PermissionsType.B_LIQUIDDBIMPORT); // 工业液体

  const [mediumIndicatorDisplayUnitData, setMediumIndicatorDisplayUnitData] =
    useState<V2MediumIndicatorDashboardGetIndicatorDisplayUnitPostResponse['list']>();

  const [topoSelectedKey, setTopoSelectedKey] = useState<Key>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  let url_processId = urlSearchParams.get('processId') || undefined;
  let url_timeResolution = urlSearchParams.get('timeResolution') || undefined;
  let url_from = urlSearchParams.get('from') || undefined;
  let url_to = urlSearchParams.get('to') || undefined;

  useEffect(() => {
    if (!energyMediumInfo) return;
    apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
      uetUnitType: uetUnitType,
      uetUnitId: energyMediumInfo.topoId,
      entryAndExitId: isEntryExit(energyMediumInfo.type) ? energyMediumInfo.id : undefined,
      // @ts-ignore
      type: isEntryExit(energyMediumInfo.type) ? energyMediumInfo.type : undefined,
    }).then(res => {
      setMediumIndicatorDisplayUnitData(res.list);
    });
  }, [energyMediumInfo]);

  useEffect(() => {
    setActiveKey(undefined);
  }, [energyMediumInfo?.type]);

  useEffect(() => {
    // 请求tab数据
    if (mediumIndicatorDisplayUnitData && energyMediumInfo && energyMediumInfo?.id) {
      setDateChangeLoading(true);
      if (
        energyMediumInfo.type === EnergyMediumTreeFormatType.ENTRY ||
        energyMediumInfo.type === EnergyMediumTreeFormatType.EXIT ||
        energyMediumInfo.type === EnergyMediumTreeFormatType.NODEINSIDE
      ) {
        getProcessEntryOrExitMonitoringIndicators({
          processEntryOrExitId: energyMediumInfo.id,
          type: energyMediumInfo.type,
        }).then(res => {
          setTabData(res.list);
          if (res.list && res.list.length > 0 && !res.list.map(i => i.indicatorId?.toString()).includes(activeKey)) {
            setNoMonitorData(false);
            setActiveKey(`${res.list[0].indicatorId}`);
          } else if (res.list.length === 0) {
            setNoMonitorData(true);
            setDateChangeLoading(false);
            setActiveKey(undefined);
          } else {
            setNoMonitorData(false);
            const target = energyMediumBtns(mediumIndicatorDisplayUnitData)?.find(
              item => item.id === Number(activeKey)
            );
            if (target) {
              const { aggrby } = target.defaultSelectBtn!;
              const defaultQuery = {
                indicatorId: Number(activeKey),
                processId: energyMediumInfo.processId,
                processEntryOrExitId: energyMediumInfo.id,
                type: energyMediumInfo.type,
                timeResolution: aggrby,
                ...getDefaultTime(aggrby, dayjs()),
              };
              // 第一次进来的时候有个默认选择
              if (!defaltEntryExitFlagRef.current) {
                setQuery(defaultQuery);
                defaltEntryExitFlagRef.current = true;
              } else {
                // 之后都是这个选择（记住之前所选）,若切换的指标无相应颗粒度，则展示默认颗粒度和时间范围。
                setQuery({
                  indicatorId: Number(activeKey),
                  processId: energyMediumInfo.processId,
                  processEntryOrExitId: energyMediumInfo.id,
                  type: energyMediumInfo.type,
                  timeResolution: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
                    ? query?.timeResolution
                    : aggrby,
                  from: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
                    ? query?.from!
                    : getDefaultTime(aggrby, dayjs()).from,
                  to: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
                    ? query?.to!
                    : getDefaultTime(aggrby, dayjs()).to,
                });
              }
            }
          }
          setDateChangeLoading(false);
        });
      } else if (energyMediumInfo.type === EnergyMediumTreeFormatType.PROCESS) {
        setNoMonitorData(false);
        let defaultQuery: SetStateAction<MediumIndicatorStatisticsReq | undefined>;
        if (url_processId && url_timeResolution && url_from && url_to) {
          defaultQuery = {
            processId: url_processId,
            timeResolution: url_timeResolution as StatisticsPartition,
            ...getTs(url_timeResolution as StatisticsPartition, dayjs(url_from, 'x'), dayjs(url_to, 'x')),
          };
        } else {
          defaultQuery = {
            processId: energyMediumInfo.id,
            timeResolution: StatisticsPartition.MINUTE_15,
            ...getDefaultTime(StatisticsPartition.MINUTE_15, dayjs()),
          };
        }

        // 第一次进来的时候有个默认选择
        if (!defaltProcessFlagRef.current) {
          setQuery(defaultQuery);
          defaltProcessFlagRef.current = true;
        } else {
          // 之后都是这个选择（记住之前所选）,若切换的指标无相应颗粒度，则展示默认颗粒度和时间范围。
          if (query) {
            setQuery({
              processId: energyMediumInfo.id,
              timeResolution: query.timeResolution,
              from: query.from,
              to: query.to,
            });
          }
        }
      } else if (energyMediumInfo.type === EnergyMediumTreeFormatType.TOPO) {
        setDateChangeLoading(false);
      }
    } else {
      setCurrentItem(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediumIndicatorDisplayUnitData]);

  // 初始状态有个默认的请求数据
  useEffect(() => {
    if (
      activeKey &&
      energyMediumInfo &&
      mediumIndicatorDisplayUnitData &&
      energyMediumInfo?.type !== EnergyMediumTreeFormatType.PROCESS
    ) {
      const target = energyMediumBtns(mediumIndicatorDisplayUnitData)?.find(item => item.id === Number(activeKey));
      if (target) {
        const { aggrby } = target.defaultSelectBtn!;
        setQuery({
          indicatorId: Number(activeKey),
          processId: energyMediumInfo.processId,
          processEntryOrExitId: energyMediumInfo.id,
          type: energyMediumInfo.type,
          timeResolution: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
            ? query?.timeResolution
            : aggrby,
          from: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
            ? query?.from!
            : getDefaultTime(aggrby, dayjs()).from,
          to: target.dateBtn.map(i => i.aggrby).includes(query?.timeResolution!)
            ? query?.to!
            : getDefaultTime(aggrby, dayjs()).to,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, mediumIndicatorDisplayUnitData]);

  // 查询图表数据
  useEffect(() => {
    (async () => {
      if (energyMediumInfo && !isNil(energyMediumInfo.id) && query) {
        if (
          energyMediumInfo.type === EnergyMediumTreeFormatType.ENTRY ||
          energyMediumInfo.type === EnergyMediumTreeFormatType.EXIT ||
          energyMediumInfo.type === EnergyMediumTreeFormatType.NODEINSIDE
        ) {
          setLoading(true);
          const target = energyMediumBtns(mediumIndicatorDisplayUnitData)?.find(item => item.id === query.indicatorId);
          target &&
            setCurrentItem({
              ...target,
              key:
                energyMediumInfo.id.toString() +
                (energyMediumInfo.processId ?? '--') +
                energyMediumInfo.type.toString(),
              indicatorId: Number(activeKey),
              processId: energyMediumInfo.processId,
              processEntryOrExitId: energyMediumInfo.id,
              timeResolution: query?.timeResolution,
              from: query?.from!,
              to: query?.to!,
              type: energyMediumInfo.type,
            });

          const response = await getMediumIndicatorStatistics(query);
          const data = getCardData({ chartData: response, query: query });
          setCardData(data);
          setChartData(response);
          setDateChangeLoading(false);
          setLoading(false);
        } else if (energyMediumInfo.type === EnergyMediumTreeFormatType.PROCESS) {
          let processTarget = energyMediumBtns(mediumIndicatorDisplayUnitData)?.find(item => item.btnName === PROCESS);
          if (processTarget && query) {
            getMediumIndicatorProcessStatistics(query).then(res => {
              const processEntryCardList = formatProcessEntryOrExitCardData(
                res.processEntryStatistics,
                EnergyMediumTreeFormatType.ENTRY,
                mediumIndicatorDisplayUnitData
              );
              const processExitCardList = formatProcessEntryOrExitCardData(
                res.processExitStatistics,
                EnergyMediumTreeFormatType.EXIT,
                mediumIndicatorDisplayUnitData
              );
              const processInsideCardList = formatProcessEntryOrExitCardData(
                res.processNodeStatistics ? [res.processNodeStatistics] : [],
                EnergyMediumTreeFormatType.NODEINSIDE,
                mediumIndicatorDisplayUnitData
              );
              processTarget!.cardData = [...processEntryCardList, ...processExitCardList, ...processInsideCardList];
              processTarget &&
                setCurrentItem({
                  ...processTarget,
                  key:
                    energyMediumInfo.id.toString() +
                    (energyMediumInfo.processId ?? '--') +
                    energyMediumInfo.type.toString(),
                  type: energyMediumInfo.type,
                  processId: energyMediumInfo.id,
                  timeResolution: query.timeResolution,
                  from: query.from,
                  to: query.to,
                });
              setDateChangeLoading(false);
            });
          }
        }
      }
    })().catch(e => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 选择树回调
  const changeTreeSelect = (value: Key[], info: any) => {
    if (info) {
      setEnergyMediumInfo(info);
    } else {
      setEnergyMediumInfo(undefined);
    }
  };

  const onChangeData = (value: any) => {
    if (query) {
      setDateChangeLoading(true);
      setQuery({
        ...query,
        ...value,
      });
    }
  };

  const exportAction = () => {
    if (energyMediumInfo && query) {
      const params = {
        indicatorId: query.indicatorId,
        processId: query.processId,
        processEntryOrExitId: query.processEntryOrExitId,
        type: query.type,
        timeResolution: query.timeResolution,
        from: query.from,
        to: query.to,
      };
      window.open(
        `/api/v2/mediumIndicatorDashboard/downloadMediumIndicatorStatistics` +
          qs.stringify(params, { addQueryPrefix: true })
      );
    }
  };

  // 判断是否是输入输出
  const isEntryOrExit = useMemo(() => {
    return (
      currentItem?.type === EnergyMediumTreeFormatType.ENTRY ||
      currentItem?.type === EnergyMediumTreeFormatType.EXIT ||
      currentItem?.type === EnergyMediumTreeFormatType.NODEINSIDE
    );
  }, [currentItem?.type]);

  // 获取拓扑点击的节点，选中左侧树
  const getKeyAndInfo = (key: Key) => {
    setTopoSelectedKey(key);
  };

  // 导出按钮
  const exportBtnRender = (
    <div>
      <Space>
        <Button type="primary" onClick={exportAction}>
          导出
        </Button>
      </Space>
    </div>
  );
  const exportBtnPermissionRender = (
    <>
      {isEntryOrExit && sceneId === 501 && hasGasPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 500 && hasWaterPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 502 && hasSteamPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 503 && hasCompressedAirPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 504 && hasIndustryGasPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 505 && hasHVACairPermission && exportBtnRender}
      {isEntryOrExit && sceneId === 506 && hasLiquidPermission && exportBtnRender}
    </>
  );

  const navigateToCompare = () => {
    if (energyMediumInfo && query) {
      const params = {
        energyMediumTopoId: energyMediumInfo.topoId,
        indicatorId: query.indicatorId,
        processId: query.processId,
        processEntryOrExitId: query.processEntryOrExitId,
        type: query.type,
        timeResolution: query.timeResolution,
        from: query.from,
        to: query.to,
      };
      window.open(`/energy/media/comparison?${qs.stringify(params, { indices: false })}`);
    }
  };

  return (
    <Wrapper routes={routesContext?.routes} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <ProcessTree
            style={{ width: '100%' }}
            changeTreeSelect={changeTreeSelect}
            sceneId={sceneId}
            topoSelectedKey={topoSelectedKey}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            setCurrentTopoId={setCurrentTopoId}
            setTopoSelectedKey={setTopoSelectedKey}
          />
        </Sider>
        <MediumUnitDataContext.Provider value={mediumIndicatorDisplayUnitData}>
          <Content className={styles.content}>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            {energyMediumInfo?.topoId && energyMediumInfo?.type === EnergyMediumTreeFormatType.TOPO ? (
              <ViewEnergyMediumTopo
                energyMediumTopoName={energyMediumInfo?.name}
                energyMediumTopoId={energyMediumInfo?.topoId}
                getKeyAndInfo={getKeyAndInfo}
                sceneId={sceneId}
              />
            ) : noMonitorData ? (
              <Spin spinning={dateChangeLoading}>
                <div style={{ position: 'relative', height: '85vh' }}>
                  <Empty description={'暂无指标配置'} className={styles.empty} />
                </div>
              </Spin>
            ) : currentItem?.key ? (
              <Spin spinning={dateChangeLoading}>
                <div className={styles.tabContent}>
                  <div className={styles.topDivSty}>
                    <div className={styles.topLeftDivSty}>
                      <div className={styles.currentSelected}>
                        <div className={styles.currentSelectedContentSty}>
                          当前选择：<span className={styles.currentSelectedName}>{energyMediumInfo?.name}</span>
                          <Tag
                            border="solid"
                            color={
                              EnergyMediumTreeFormatTypeDisplayLabelColor[
                                energyMediumInfo?.type as EnergyMediumTreeFormatType
                              ]
                            }
                          >
                            {EnergyMediumTreeFormatTypeDisplay[energyMediumInfo?.type as EnergyMediumTreeFormatType]}
                          </Tag>
                        </div>
                        <div className={styles.exportBtn}>
                          <div style={{ marginRight: 8 }}>
                            <Button type="primary" onClick={() => navigateToCompare()}>
                              对比
                            </Button>
                          </div>
                          {exportBtnPermissionRender}
                        </div>
                      </div>

                      {isEntryOrExit && (
                        <div className={styles.tab_box}>
                          <MonitoringIndicatorsTab
                            tabData={tabData}
                            onTabChange={v => setActiveKey(v)}
                            activeKey={activeKey}
                          />
                        </div>
                      )}

                      <div className={styles.filter}>
                        {query && (
                          <DateSwitch
                            btnGroup={currentItem.dateBtn}
                            selectBtn={query.timeResolution!}
                            value={[dayjs(query.from, 'x'), dayjs(query.to, 'x')]}
                            onQueryChange={onChangeData}
                            isEntryOrExit={isEntryOrExit}
                            query={query}
                          />
                        )}
                      </div>

                      <div
                        className={
                          (currentItem?.cardData ?? []).length > 3
                            ? styles.cardContainerMoreThanTree
                            : styles.cardContainer
                        }
                      >
                        {isEntryOrExit &&
                          ((currentItem?.cardData ?? []).length > 0 ? (
                            currentItem?.cardData?.map(card => (
                              <div
                                className={styles.cardItem}
                                key={(card.cardName ?? '--') + card.indicatorId + card.type}
                              >
                                <CardForData name={card.cardName!} value={cardData?.[card.key!]} unit={card.unit!} />
                              </div>
                            ))
                          ) : (
                            <Empty />
                          ))}
                        {currentItem.type === EnergyMediumTreeFormatType.PROCESS &&
                          ((currentItem?.cardData ?? []).length > 0 ? (
                            currentItem?.cardData?.map(card => (
                              <div
                                className={styles.cardItem}
                                key={(card.cardName ?? '--') + card.indicatorId + card.type}
                              >
                                <CardForProcessData cardData={card} />
                              </div>
                            ))
                          ) : (
                            <Empty imageStyle={{ height: 60 }} />
                          ))}
                      </div>
                    </div>

                    <div className={styles.topRightDivSty}>
                      <ViewProcessTopo
                        energyMediumTopoId={energyMediumInfo?.topoId}
                        processId={energyMediumInfo?.processId}
                        type={energyMediumInfo?.type}
                        getKeyAndInfo={getKeyAndInfo}
                        id={energyMediumInfo?.id}
                        noMonitorData={noMonitorData}
                      />
                    </div>
                  </div>
                  {isEntryOrExit && query && chartData && (
                    <div className={styles.chartContainer}>
                      <Chart
                        loading={loading}
                        chartData={chartData}
                        option={Object.assign(
                          {},
                          currentItem.getChartOption?.({
                            chartData,
                            query: query,
                            unit: currentItem.unit,
                          })
                        )}
                      />
                    </div>
                  )}
                  {currentItem.type === EnergyMediumTreeFormatType.PROCESS && query && (
                    <ProcessChartsTab
                      currentItem={currentItem}
                      query={query}
                      onTabChange={v => setProcessActiveKey(v)}
                      activeKey={processActiveKey}
                    />
                  )}
                </div>
              </Spin>
            ) : (
              <Spin spinning={dateChangeLoading}>
                <div style={{ position: 'relative', height: '85vh' }}>
                  <Empty description={'暂无数据'} className={styles.empty} />
                </div>
              </Spin>
            )}
            <FooterTip selectedKeys={selectedKeys} query={query} currentTopoId={currentTopoId} />
          </Content>
        </MediumUnitDataContext.Provider>
      </Layout>
    </Wrapper>
  );
};

export default EnergyMediumDashBoard;
