import { Col, Row } from 'antd';
import { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPrice,
  HasTimeOfUseElectricityPriceProps,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceType,
} from '../../../../../api/electricitySettlementRules';
import { Form, InputNumber, Radio, Checkbox } from '@maxtropy/components';

// interface UnitarySystemProps {
//   showInput?: boolean;
// }

const UnitarySystem: FC = () => {
  return (
    <div>
      <Row>
        <Form.Item noStyle dependencies={['settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <Col
                  span={24}
                  style={{
                    backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                    padding: '20px',
                    paddingBottom: '0px',
                  }}
                >
                  <Form.Item
                    name="rateType"
                    label="电度电价类型"
                    rules={[{ required: true, message: '请选择电度电价类型' }]}
                  >
                    <Radio.Group>
                      <Radio value={HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE}>
                        {HasTimeOfUseElectricityPrice[HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE]}
                      </Radio>
                      <Radio value={HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE}>
                        {HasTimeOfUseElectricityPrice[HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE]}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )
            );
          }}
        </Form.Item>
      </Row>

      <Form.Item noStyle dependencies={['rateType', 'settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
              <Row
                style={{
                  backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                  paddingLeft: '20px',
                  marginBottom: '30px',
                }}
              >
                <Col span={6}>
                  <Form.Item
                    name="standingRate"
                    label="电度电价"
                    rules={[
                      { required: true, message: '请输入不分时电度电价' },
                      // { validator: limitedIntegerValidator(99999999.99999999, 0.1) }
                    ]}
                  >
                    <InputNumber
                      placeholder="请输入"
                      style={{ width: '100%' }}
                      max={99999999.99999999}
                      min={0}
                      step="0.00000001"
                      precision={8}
                      // stringMode
                      addonAfter="元/kWh"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      <Row>
        <Form.Item noStyle dependencies={['rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <Col span={24} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Form.Item
                    name="timeOfDayRateChecked"
                    label="时段选择"
                    rules={[{ required: true, message: '请选择时段' }]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.SHARPPEAK],
                          value: HasTimeOfUseElectricityPricePropsType.SHARPPEAK,
                        },
                        {
                          label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.PEAK],
                          value: HasTimeOfUseElectricityPricePropsType.PEAK,
                        },
                        {
                          label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.AVERAGE],
                          value: HasTimeOfUseElectricityPricePropsType.AVERAGE,
                        },
                        {
                          label: HasTimeOfUseElectricityPriceProps[HasTimeOfUseElectricityPricePropsType.VALLEY],
                          value: HasTimeOfUseElectricityPricePropsType.VALLEY,
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).length > 0 &&
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <>
                  <Col
                    span={24}
                    style={{
                      paddingLeft: '20px',
                      fontWeight: 700,
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      paddingBottom: '10px',
                    }}
                  >
                    电度电价：
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Row>
        {/* 时段选择尖峰平谷输入框 */}
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <>
                  <Col span={7} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="summitRate" label="尖峰" rules={[{ required: true, message: '请输入尖峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={17}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <>
                  <Col span={7} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="peakRate" label="峰" rules={[{ required: true, message: '请输入峰值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={17}
                    style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Row>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <>
                  <Col span={7} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="plainRate" label="平" rules={[{ required: true, message: '请输入平值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={17}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      paddingLeft: '20px',
                      fontWeight: 700,
                    }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
              getFieldValue('rateType') === HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                <>
                  <Col span={7} style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                    <Form.Item name="valleyRate" label="谷" rules={[{ required: true, message: '请输入谷值' }]}>
                      <InputNumber
                        placeholder="请输入"
                        style={{ width: '100%' }}
                        max={99999999.99999999}
                        min={0}
                        step="0.00000001"
                        precision={8}
                        // stringMode
                        addonAfter="元/kWh"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={17}
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      paddingLeft: '20px',
                      fontWeight: 700,
                    }}
                  ></Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default UnitarySystem;
