import React, { Key, Ref, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Paging,
  Table,
  usePaging,
  EllipsisSpan,
  Button,
  Form,
  Modal,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { useQuery } from '../../../../../utils/utils';
import { MeterDevicePageRequest, getMeterDevicePage } from '../../../../../api/pv';
import { Device } from '../../../../../api/device';
import styles from '../index.module.scss';
import { getMeterDevice } from '../../../../../api/pv';
import { PvStationContext } from '../components/PvStation';

interface FilterParams {
  nameOrCode?: string;
  tag?: string;
  deviceType?: string;
}

export interface DeviceModalRef {
  selectedRows?: Device;
}

type SearchParams = Omit<MeterDevicePageRequest, 'page' | 'size' | 'nameOrCode' | 'tag' | 'deviceType' | 'circuitId'>;

interface DeviceModalProps {
  circuitId?: Key;
  deviceId?: number;
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: (row?: Device) => void;
  isGatewayMeter: boolean;
}

const DeviceModal = React.forwardRef(
  (
    { deviceId, visible, onCancel, onConfirm, isGatewayMeter, circuitId }: DeviceModalProps,
    ref: Ref<DeviceModalRef>
  ) => {
    const pagingInfo = usePaging(5);
    const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

    const [form] = Form.useForm();
    const { ou } = useContext(PvStationContext);
    const [searchParams, setSearchParams] = useState<SearchParams>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number>();
    const [selectedRows, setSelectedRows] = useState<Device>();

    const { data: deviceTypeTreeData } = useQuery(
      useCallback(async () => {
        if (!visible) return;
        const res = await getMeterDevice();
        if (res?.deviceTypes) {
          return res?.deviceTypes[0];
        }
      }, [visible])
    );

    const { data, isLoading } = useQuery(
      useCallback(async () => {
        if (!visible) return;
        if (deviceTypeTreeData) {
          const res = await getMeterDevicePage({
            circuitId,
            ...searchParams,
            page: pageOffset,
            size: pageSize,
          });
          if (res) setTotalCount(res.total);
          return res?.list;
        }
      }, [deviceTypeTreeData, searchParams, pageOffset, pageSize, setTotalCount, ou?.id, visible, circuitId])
    );
    useEffect(() => {
      if (visible) {
        setSelectedRowKeys(deviceId);
      }
    }, [deviceId, visible]);

    useImperativeHandle(ref, () => ({
      selectedRows,
    }));

    const onSelect = (record: Device) => {
      setSelectedRowKeys(record.id);
      setSelectedRows(record);
    };
    const cancelSelect = () => {
      setSelectedRowKeys(undefined);
      setSelectedRows(undefined);
    };
    const onReset = () => {
      form.resetFields();
      form.submit();
      setPageOffset(1);
    };

    const onFinish = (val: FilterParams) => {
      setSearchParams({ ...val });
      setPageOffset(1);
    };

    const columns = [
      {
        title: '设备编号',
        dataIndex: 'code',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '所属类目',
        dataIndex: 'typeName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备标签',
        dataIndex: 'tags',
        ellipsis: { showTitle: true },
        render: (v: string[]) => {
          const tags = v ? v.join(', ') : '';
          return <EllipsisSpan value={tags} />;
        },
      },
      {
        title: '所属组织',
        dataIndex: 'customerName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '操作',
        width: 100,
        render: (record: Device) => {
          return selectedRowKeys === record.id ? (
            <Button type="link" onClick={cancelSelect}>
              取消选择
            </Button>
          ) : (
            <Button type="link" onClick={() => onSelect(record)}>
              选择
            </Button>
          );
        },
      },
    ];

    return (
      <>
        <Modal
          size="big"
          title={isGatewayMeter ? '关口计量表选择' : '计量电表选择'}
          open={visible}
          destroyOnClose
          onCancel={() => {
            onCancel && onCancel();
            form.resetFields();
          }}
          onOk={() => onConfirm && onConfirm(selectedRows)}
        >
          <CustomFilter<FilterParams>
            form={form}
            onFinish={val => onFinish(val as FilterParams)}
            onReset={onReset}
            colSpan={8}
          >
            <Form.Item name="nameOrCode" label="编号/名称">
              <Input placeholder={'请输入编号或名称查询'} maxLength={20} />
            </Form.Item>

            <Form.Item label="一级类目">{deviceTypeTreeData?.name || '--'}</Form.Item>

            <Form.Item name="deviceType" label="二级类目">
              <Select placeholder="请选择">
                {deviceTypeTreeData &&
                  (deviceTypeTreeData.children || []).map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item name="tag" label="标签">
              <Input placeholder="请输入" maxLength={20} />
            </Form.Item>
          </CustomFilter>
          <div className={styles.table_text}>
            <div className={styles.action} style={{ textAlign: 'right', marginBottom: 8 }}>
              <span className={styles.text} style={{ marginRight: 8, color: 'rgba(255,255,255,0.45)' }}>
                点击前往
              </span>
              <Link to="/device/manage/device/create" target="_blank">
                添加设备
              </Link>
            </div>
          </div>
          <Table rowKey="id" loading={isLoading} columns={columns} dataSource={data} />
          <Paging pagingInfo={pagingInfo} />
        </Modal>
      </>
    );
  }
);

export default DeviceModal;
