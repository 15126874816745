import {
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  Select,
  DatePicker,
  Input,
  InputNumber,
  FormContent,
  Upload,
} from '@maxtropy/components';
import { Col, Form, Row, Cascader, Space } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { UnitNatureFormat, fetchAreaJson, formatDistrictData } from './const';
import { isNil } from 'lodash-es';
import {
  apiV2AccountingEntityDetailPost,
  apiV2AccountingEntityIndustryTreePost,
  apiV2AccountingEntityAddPost,
  V2AccountingEntityIndustryTreePostResponse,
  apiV2AccountingEntityEditPost,
} from '@maxtropy/device-customer-apis-v2';
import { getParents, useAreaJson } from '../../../utils/utils';
import { AreaItem } from '../../../api/electricity';

function formatNumber(v: any) {
  if (isNil(v) || v === '') return '';
  let formattedNumber = parseFloat(v).toFixed(6);
  formattedNumber = formattedNumber.replace(/\.?0+$/, '');
  return formattedNumber as any;
}

const AddAccountingEntity: React.FC = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const id = urlSearchParams.get('id');
  const routes = [{ name: !id ? '新建' : '编辑' }];
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [industryOptions, setIndustryOptions] = useState<V2AccountingEntityIndustryTreePostResponse['list']>([]);
  const districtData = useAreaJson();
  const organizationalChartKey = Form.useWatch('organizationalChartKey', form);
  useEffect(() => {
    fetchAreaJson().then(res => {
      setDistrictOptions(formatDistrictData(res?.districts));
    });
    apiV2AccountingEntityIndustryTreePost({}).then(res => {
      setIndustryOptions(res?.list);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ organizationalChartKey: organizationalChartKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationalChartKey]);

  const getParentsIndustry = (nodes: any, key: string): any => {
    const res = [];
    for (let node of nodes ?? []) {
      if (node.code === key) {
        res.unshift(node);
        break;
      } else {
        const children = getParentsIndustry(node.trees ?? [], key);
        if (children && children.length) {
          res.unshift(node, ...children);
          break;
        }
      }
    }
    return res;
  };

  useEffect(() => {
    if (id && districtData && industryOptions) {
      apiV2AccountingEntityDetailPost({ id: Number(id) }).then(res => {
        const codes = getParents(districtData as AreaItem[], res.adCode as string).map(item => item.adcode);
        const industry = getParentsIndustry(
          industryOptions as any,
          res.nationalEconomicIndustryId?.toString() as string
        ).map((item: any) => item.code);
        form.setFieldsValue({
          ...res,
          registrationDate: !isNil(res.registrationDate) ? dayjs(res?.registrationDate) : null,
          adCode: codes,
          nationalEconomicIndustryId: industry,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, districtData, industryOptions]);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const params = {
        ...values,
        registrationDate: !isNil(values.registrationDate) ? values.registrationDate?.format('YYYY-MM-DD') : null,
        adCode: !isNil(values.adCode) ? values.adCode[values.adCode.length - 1] : undefined,
        nationalEconomicIndustryId: !isNil(values.nationalEconomicIndustryId)
          ? values.nationalEconomicIndustryId[values.nationalEconomicIndustryId.length - 1]
          : undefined,
      };
      if (!id) {
        apiV2AccountingEntityAddPost({ ...params }).then(res => {
          navigate('/carbonManage/mainAccount/carbonAccountUnits');
        });
      } else {
        apiV2AccountingEntityEditPost({ ...params, id: Number(id) }).then(res => {
          navigate('/carbonManage/mainAccount/carbonAccountUnits');
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <Form
        form={form}
        labelAlign="left"
        layout={'vertical'}
        onFinish={v => {
          onFinish();
        }}
      >
        <FormContent title={id ? '编辑核算主体' : '新增核算主体'} style={{ padding: '5px 10px' }}>
          <div className={styles.content}>
            <div className={styles.title}>基本信息</div>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="企业名称"
                  name="companiesName"
                  rules={[
                    { required: true, message: '请输入企业名称' },
                    { max: 50, message: '最多输入50字' },
                  ]}
                >
                  <Input placeholder="请输入企业名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="单位性质" name="unitNatureId">
                  <Select
                    placeholder="请选择单位性质"
                    options={Object.entries(UnitNatureFormat).map(([k, v]) => ({
                      label: v,
                      value: +k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="所属行业" name="nationalEconomicIndustryId">
                  <Cascader
                    options={industryOptions}
                    style={{ width: 474 }}
                    changeOnSelect
                    fieldNames={{ label: 'name', value: 'code', children: 'trees' }}
                    placeholder="请选择所属行业"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Space size={'small'}>
                  <Form.Item label="注册地址" name="adCode">
                    <Cascader
                      options={districtOptions}
                      style={{ width: 180 }}
                      fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
                      placeholder="选择所属省/市区"
                    />
                  </Form.Item>
                  <Form.Item label="" name="address" rules={[{ max: 50, message: '最多输入50字' }]}>
                    <Input placeholder="请输入详细地址" style={{ width: 285, marginTop: 30 }} />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <div style={{ padding: 0, marginTop: 10 }}>
              <div className={styles.title}>详细信息</div>
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item name="uscc" label="统一社会信用代码" rules={[{ max: 20, message: '最多输入20字' }]}>
                    <Input placeholder="请输入统一社会信用代码" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="contact" label="联系人" rules={[{ max: 20, message: '最多输入20字' }]}>
                    <Input placeholder="请输入联系人" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="legalRepresentativeName"
                    label="法定代表人姓名"
                    rules={[{ max: 20, message: '最多输入20字' }]}
                  >
                    <Input placeholder="请输入法定代表人姓名" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="contactDuties" label="联系人职务" rules={[{ max: 20, message: '最多输入20字' }]}>
                    <Input placeholder="请输入联系人职务" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="registrationDate" label="注册日期">
                    <DatePicker placeholder="请输入注册日期" style={{ width: 474 }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="contactNumber" label="联系电话" rules={[{ max: 50, message: '最多输入50字' }]}>
                    <Input placeholder="请输入联系电话" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="registeredCapital" label="注册资本">
                    <InputNumber
                      formatter={formatNumber}
                      placeholder="请输入注册资本"
                      addonAfter={'万元'}
                      precision={6}
                      style={{ width: 474 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label="电子邮箱"
                    rules={[
                      { max: 50, message: '最多输入50字' },
                      {
                        type: 'email',
                        message: '请输入有效的邮箱地址！',
                      },
                    ]}
                  >
                    <Input placeholder="请输入电子邮箱" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="licenseKey" label="营业执照">
                    <Upload
                      accept={['.jpg', '.jpeg', '.png']}
                      uploadText="上传图片"
                      fileSize={1}
                      tip="仅支持.jpg、.jpeg、.png，大小不超过1M"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="organizationalChartKey"
                    label="组织结构图"
                    // getValueFromEvent={normFile}
                    // valuePropName="fileList"
                  >
                    <Upload
                      accept={['.jpg', '.jpeg', '.png']}
                      uploadText="上传图片"
                      fileSize={5}
                      tip="仅支持.jpg、.jpeg、.png，大小不超过5M"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="factoryPlanKey" label="厂区平面图">
                    <Upload
                      accept={['.jpg', '.jpeg', '.png']}
                      uploadText="上传图片"
                      fileSize={5}
                      tip="仅支持.jpg、.jpeg、.png，大小不超过5M"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          <Space className="sticky-footer" size={'small'} style={{ background: '#232324', width: '100%' }}>
            <Button htmlType="submit" type="primary">
              保存
            </Button>
            <Button
              onClick={() => {
                navigate('/carbonManage/mainAccount/carbonAccountUnits');
              }}
            >
              取消
            </Button>
          </Space>
        </FormContent>
      </Form>
    </Wrapper>
  );
};

export default AddAccountingEntity;
