import { EllipsisSpan, Tag } from '@maxtropy/components';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay, AlarmStatus, AlarmStatusDisplay } from '@/shared/types';

export const columns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmStatus) => <EllipsisSpan value={AlarmStatusDisplay[v]} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmLevel) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
            {AlarmLevelDisplay[v]}
          </Tag>
        }
      />
    ),
  },
];

export const modalColumns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型',
    dataIndex: 'physicalModelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: AlarmStatus) => <EllipsisSpan value={AlarmStatusDisplay[v]} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmLevel) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={AlarmLevelColorDisplay[v]}>
            {AlarmLevelDisplay[v]}
          </Tag>
        }
      />
    ),
  },
];
