import { FormInstance, RadioChangeEvent, Space } from 'antd';
import { DatePicker, message, Radio } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { FC } from 'react';
import { StatisticsPartition, DatePickerTypeDisplay } from '../../type';

import styles from '../index.module.scss';
import { getDefaultDate, transRangeDate } from '../util';

const { RangePicker } = DatePicker;

export interface DateSwitchProps {
  form: FormInstance<any>;
  timeResolution?: StatisticsPartition;
  dateRange?: [Dayjs, Dayjs];
  onQueryChange?: (value: { timeResolution: StatisticsPartition; fromAndTo: [Dayjs, Dayjs] }) => void;
}

const DateSwitch: FC<DateSwitchProps> = props => {
  const { timeResolution, dateRange, onQueryChange } = props;

  const onRadioChange = (e: RadioChangeEvent) => {
    onQueryChange?.({
      timeResolution: e.target.value,
      fromAndTo: getDefaultDate(e.target.value),
    });
  };

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      switch (timeResolution) {
        case StatisticsPartition.DAY:
          {
            const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
            if (diff >= 31) {
              return message.warning(`最大上限为31天`);
            }
            onQueryChange?.({
              timeResolution,
              fromAndTo: transRangeDate(timeResolution, range),
            });
          }
          break;
        case StatisticsPartition.MONTH:
          {
            const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
            if (diff >= 12) {
              return message.warning(`最大上限为12个月`);
            }
            onQueryChange?.({
              timeResolution,
              fromAndTo: transRangeDate(timeResolution, range),
            });
          }
          break;
        case StatisticsPartition.YEAR:
          {
            const diff = dayjs(endDate).diff(dayjs(beginDate), 'year');
            if (diff >= 12) {
              return message.warning(`最大上限为12年`);
            }
            onQueryChange?.({
              timeResolution,
              fromAndTo: transRangeDate(timeResolution, range),
            });
          }
          break;
      }
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  return (
    <Space className={styles.dateSwitch} size={16}>
      <Radio.Group onChange={onRadioChange} value={timeResolution} buttonStyle="solid">
        <Radio.Button value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio.Button>
        <Radio.Button value={StatisticsPartition.MONTH}>
          {DatePickerTypeDisplay[StatisticsPartition.MONTH]}
        </Radio.Button>
        <Radio.Button value={StatisticsPartition.YEAR}>{DatePickerTypeDisplay[StatisticsPartition.YEAR]}</Radio.Button>
      </Radio.Group>

      <RangePicker
        value={dateRange}
        disabledDate={disabledDate}
        onChange={onChangeRangePicker}
        picker={
          timeResolution === StatisticsPartition.DAY
            ? 'date'
            : timeResolution === StatisticsPartition.MONTH
            ? 'month'
            : 'year'
        }
        allowClear={false}
      />
    </Space>
  );
};

export default DateSwitch;
