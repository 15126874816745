import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CardItem from './CardItem';
import classNames from 'classnames';
import styles from './index.module.scss';
import { SwipperListItem } from '../..';
import { Key } from '@maxtropy/components';

interface Props {
  list: SwipperListItem[];
  selectedMediumId: Key;
  setSelectedMediumId: (id: Key) => void;
  setPhysicalUnitName: (v: string) => void;
  setIsMedium: (v: boolean) => void;
}

const MediumSwipper: React.FC<Props> = ({
  list,
  setIsMedium,
  selectedMediumId,
  setSelectedMediumId,
  setPhysicalUnitName,
}) => {
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  return (
    <div className={styles.swiperWrapper}>
      <Swiper
        spaceBetween={18}
        slidesPerView={4}
        freeMode={true}
        observer={true}
        observeParents={true}
        onSwiper={swiper => setSwiperIntance(swiper)}
        navigation={{
          nextEl: '.swiper-button-prev',
          prevEl: '.swiper-button-next',
        }}
      >
        {list.map((item, index) => (
          <SwiperSlide key={index}>
            <CardItem
              cardInfo={item}
              setPhysicalUnitName={setPhysicalUnitName}
              selectId={selectedMediumId}
              setSelectId={setSelectedMediumId}
              setIsMedium={setIsMedium}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={classNames(`swiper-button-prev`, styles.swiper_prev_btn)}
        onClick={() => swiperIntance?.slidePrev()}
      >
        <LeftOutlined />
      </div>
      <div
        className={classNames(`swiper-button-next`, styles.swiper_next_btn)}
        onClick={() => swiperIntance?.slideNext()}
      >
        <RightOutlined />
      </div>
    </div>
  );
};

export default MediumSwipper;
