import React, { Key, useEffect, useMemo, useState } from 'react';
import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Modal, Select } from 'antd';
import { Button } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { MediumIndicatorStatisticsReq } from '@/api/energyMedium';

import styles from './index.module.scss';
import {
  apiV2MediumIndicatorDashboardEnergyMediumTopologyVoListPost,
  apiV2MediumIndicatorDashboardProcessVoListPost,
  apiV2MediumIndicatorDashboardRerunDataPost,
} from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface CalcModalProps {
  show?: boolean;
  query?: MediumIndicatorStatisticsReq;
  initDates?: RangeValue;
  close: () => void;
  selectedKeys?: Key[];
  currentTopoId: number | undefined;
}

interface OptionProps {
  label: string | undefined;
  value: number | undefined;
}

const CalcModal: React.FC<CalcModalProps> = ({ show = false, close, currentTopoId, query }) => {
  const [topoOptions, setTopoOptions] = useState<OptionProps[]>([]);
  const [form] = Form.useForm();
  const [value, setValue] = useState<RangeValue>(null);
  const [dates, setDates] = useState<RangeValue>();
  const [tipModalOpen, setTipModalOpen] = useState<boolean>(false);
  const [calcModalOpen, setCalcModalOpen] = useState<boolean>(false);
  // const [defaultData, setDefaultData] = useState<EnergyUnitItem[]>([]);
  const [defaultData, setDefaultData] = useState<OptionProps[]>([]);
  const hasUsePermission = useHasPermission(PermissionsType.B_DATA_RECALCULATION);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') >= 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') >= 6;
    const afterToday = current && current > dayjs().endOf('day');
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const { energyMediumTopologyId, processId, date } = values;
      const params = {
        energyMediumTopologyId,
        processId,
        startTime: dayjs(date[0]).valueOf(),
        endTime: dayjs(date[1]).valueOf(),
      };
      await apiV2MediumIndicatorDashboardRerunDataPost(params);
      setCalcModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getDefaultData = async (currentTopoId: number) => {
    if (isNil(currentTopoId)) return;
    const emtId = currentTopoId.toString();
    const res = await apiV2MediumIndicatorDashboardProcessVoListPost({ id: emtId });
    const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id }));
    setDefaultData(options);
  };

  useEffect(() => {
    apiV2MediumIndicatorDashboardEnergyMediumTopologyVoListPost().then(res => {
      const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id }));
      setTopoOptions(options);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTipModalOpen(show);
    form.setFieldsValue({ processId: undefined });

    if (topoOptions.length > 0 && !isNil(currentTopoId)) {
      form.setFieldsValue({ energyMediumTopologyId: currentTopoId });
      getDefaultData(currentTopoId);
    }

    if (query?.from && query?.to) {
      form.setFieldsValue({ date: [dayjs(query.from), dayjs(query.to)] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, currentTopoId, query]);

  const footerBtns = useMemo(() => {
    let btns = [
      <Button
        key="back"
        onClick={() => {
          close();
        }}
        className={styles.cancelBtn}
      >
        取消
      </Button>,
    ];
    // TODO 按钮权限
    if (true) {
      btns.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setCalcModalOpen(true);
            close();
          }}
        >
          用能重计算
        </Button>
      );
    }
    return btns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUsePermission]);

  return (
    <>
      <Modal
        title={
          <div className={styles.titleTxt}>
            <ExclamationCircleFilled style={{ color: 'var(--mx-warning-color)', fontSize: 16 }} />
            <span>数据异常说明</span>
          </div>
        }
        open={tipModalOpen}
        width={320}
        closable={false}
        className={styles.tipModalBox}
        footer={footerBtns}
      >
        <p className={styles.descTxt}>采集用能数据异常、历史指标配置发生变更会影响数据准确性，需要重计算。</p>
        <p className={styles.descTxt}>重计算大约需要5-30分钟，数据依然异常可联系系统管理员排查。</p>
      </Modal>

      <Modal
        title="重计算"
        forceRender
        className={styles.calcModalBox}
        open={calcModalOpen}
        onCancel={() => setCalcModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => setCalcModalOpen(false)} className={styles.cancelBtn}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={onFinish}>
            确定
          </Button>,
        ]}
      >
        <div className={styles.modalBody}>
          <div className={styles.tipWrapper}>
            <ExclamationCircleOutlined style={{ color: 'var(--mx-warning-color)', fontSize: 12 }} />
            <span className={styles.txt}>重计算数值会覆盖现有数据</span>
          </div>
          <div style={{ padding: '32px 40px' }}>
            <Form form={form} labelAlign="right" labelCol={{ style: { width: 100 } }}>
              <Form.Item label="选择时间" name="date" rules={[{ required: true, message: '请选择时间' }]}>
                <RangePicker
                  value={value || dates}
                  disabledDate={disabledDate}
                  onCalendarChange={val => {
                    setDates(val);
                  }}
                  onChange={val => {
                    setValue(val);
                  }}
                  onOpenChange={onOpenChange}
                />
              </Form.Item>
              <Form.Item label="拓扑" name="energyMediumTopologyId" required>
                <Select placeholder="请选择" options={topoOptions} onChange={val => getDefaultData(val)}></Select>
              </Form.Item>
              <Form.Item label="过程" name="processId" rules={[{ required: true, message: '请选择用能单元' }]}>
                <Select placeholder="请选择" mode="multiple" options={defaultData}></Select>
                {/* <TreeSelect
                  popupClassName={styles.treeSelect}
                  treeData={defaultData}
                  fieldNames={{ label: 'name', value: 'energyUnitId', children: 'children' }}
                  treeCheckable
                  showCheckedStrategy={TreeSelect.SHOW_ALL}
                  treeCheckStrictly={true}
                  placeholder="请选择"
                /> */}
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CalcModal;
