import { EllipsisSpan, Table, Empty } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { PlusRed, MinusGreen } from '../../utils';
import {
  apiV2LeanEnergyFlowAnalysisFlowDetailProductionBasePost,
  apiV2LeanEnergyFlowAnalysisFlowDetailWorkCenterPost,
  apiV2LeanEnergyFlowAnalysisFlowDetailWorkProcedurePost,
  apiV2LeanEnergyFlowAnalysisFlowDetailWorkStationPost,
  V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse,
} from '@maxtropy/device-customer-apis-v2';
export function formatMomRatio(num?: number | null) {
  if (isNil(num)) return <span>--</span>;
  return num > 0 ? (
    <span style={{ color: PlusRed }}>+{num.toFixed(2)}%</span>
  ) : (
    <span style={{ color: MinusGreen }}>{num.toFixed(2)}%</span>
  );
}

interface Props {
  modalChoseCenterIds?: number[];
  mediumId?: number;
  date: string;
  resolution?: string;
  type: keyof TypeConfig;
  modalworkProcedure?: number;
  modalworkStation?: number;
  setData: (val: V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse['list']) => void;
  data: any;
  setMaxEnergy: any;
}

type TypeConfig = {
  productionBase?: { title: string; dataIndex: string };
  workCenter?: { title: string; dataIndex: string };
  procedure?: { title: string; dataIndex: string };
  workStation?: { title: string; dataIndex: string };
  energyUnit?: { title: string; dataIndex: string };
};

const typeConfig: TypeConfig = {
  productionBase: { title: '工作中心', dataIndex: 'workCenterName' },
  workCenter: { title: '工序', dataIndex: 'workProcedureName' },
  procedure: { title: '工站', dataIndex: 'workStationName' },
  workStation: { title: '用能单元', dataIndex: 'energyUnitName' },
  energyUnit: { title: '用能单元', dataIndex: 'energyUnitName' },
};

const EnergyTable: React.FC<Props> = ({
  modalChoseCenterIds,
  mediumId,
  date,
  resolution,
  type,
  modalworkProcedure,
  modalworkStation,
  setData,
  setMaxEnergy,
  data,
}) => {
  const findMaxAmount = (data: any) => {
    const maxEnergy = Math.max(...data.map((item: any) => item.energyAmount));
    const maxEnergyObject = data.find((item: any) => item.energyAmount === maxEnergy);
    setMaxEnergy(maxEnergyObject);
  };

  useEffect(() => {
    if (isNil(modalChoseCenterIds) || modalChoseCenterIds.length === 0 || isNil(mediumId)) return;
    const params = {
      mediumId: mediumId === 100 ? undefined : mediumId,
      date,
      resolution,
    };
    if (type === 'productionBase') {
      apiV2LeanEnergyFlowAnalysisFlowDetailProductionBasePost({
        workCenterIds: modalChoseCenterIds,
        ...params,
      }).then(res => {
        setData(res.list);
        findMaxAmount(res.list);
      });
    } else if (type === 'workCenter') {
      apiV2LeanEnergyFlowAnalysisFlowDetailWorkCenterPost({ workCenterId: modalChoseCenterIds[0], ...params }).then(
        res => {
          setData(res.list);
          findMaxAmount(res.list);
        }
      );
    } else if (type === 'procedure') {
      apiV2LeanEnergyFlowAnalysisFlowDetailWorkProcedurePost({
        workCenterId: modalChoseCenterIds[0],
        workProcedureId: modalworkProcedure,
        ...params,
      }).then(res => {
        findMaxAmount(res.list);
        setData(res.list);
      });
    } else if (type === 'workStation') {
      apiV2LeanEnergyFlowAnalysisFlowDetailWorkStationPost({
        workCenterId: modalChoseCenterIds[0],
        workStationId: modalworkStation,
        workProcedureId: modalworkProcedure,
        ...params,
      }).then(res => {
        setData(res.list);
        findMaxAmount(res.list);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalChoseCenterIds, mediumId, resolution, date]);

  const generateColumns = (type: keyof TypeConfig) => {
    const { title, dataIndex } = typeConfig[type] || { title: '', dataIndex: '' };

    return {
      title,
      dataIndex,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    };
  };
  const columns = useMemo(() => {
    if (data.length === 0) return;
    return [
      generateColumns(type),
      {
        title: `用能量${isNil(data[0].unitName) ? '' : `(${data[0].unitName})`}`,
        dataIndex: 'energyAmount',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={isNil(v) ? null : v.toFixed(2)} />,
      },
      {
        title: '占比',
        dataIndex: 'ratio',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={isNil(v) ? null : `${v.toFixed(2)}%`} />,
      },
      {
        title: '用能环比',
        dataIndex: 'relativeRatio',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={formatMomRatio(v)} />,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type]);

  return (
    <div>
      流向详情
      {type !== 'energyUnit' && data && data.length > 0 ? (
        <Table
          columns={columns}
          style={{
            maxHeight: 400,
            overflowY: 'auto',
            marginTop: 8,
          }}
          rowKey="id"
          dataSource={data}
        />
      ) : (
        <Empty></Empty>
      )}
    </div>
  );
};

export default EnergyTable;
