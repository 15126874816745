import React, { CSSProperties } from 'react';
import { CustomAttribute } from './Editor';
import { Tag } from '@maxtropy/components';

export interface CustomAttributesDisplayProps {
  customAttributes: CustomAttribute[];
  style?: CSSProperties;
  className?: string;
}

const CustomAttributesDisplay: React.FC<CustomAttributesDisplayProps> = ({ customAttributes, style, className }) => {
  const renderTree = (items: CustomAttribute[]): React.ReactNode => {
    if (!items.length) return;
    return items.map(item => (
      <>
        <div style={{ paddingLeft: item.depth * 20, color: 'var(--mx-form-item-label-color)', marginBottom: 8 }}>
          {item.isGroup ? (
            <div>
              {item.label} <Tag>属性组</Tag>
            </div>
          ) : (
            <div>
              {item.label}： <span style={{ color: 'var(--mx-text-base-color)' }}>{item.value || '--'}</span>
            </div>
          )}
        </div>
        {renderTree(customAttributes.filter(child => child.parentKey === item.key))}
      </>
    ));
  };

  return (
    <div style={style} className={className}>
      <div style={{ fontWeight: 'bold', color: 'var(--mx-text-base-color)', marginBottom: 16 }}>描述信息</div>
      {renderTree(customAttributes.filter(item => !item.parentKey))}
    </div>
  );
};

export default CustomAttributesDisplay;
