import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { DatePickerType } from './type';
import { Key } from 'react';
import { V2StationSignboardStationDetailIndicatorChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return data.toFixed(2);
  } else {
    return '--';
  }
};

export const getMax = (chartData?: V2StationSignboardStationDetailIndicatorChartValuePostResponse) => {
  const arr = (chartData?.list ?? []).map(i => i.chartValues?.map(c => c.value)).flat();
  console.log('arr', arr);
  return max(arr);
};

/**
 * 求数组中的最大值
 */
export const max = (set: any) => {
  const numbers = filterNumber(set);
  return Math.max(...numbers);
};

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: Key) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * 是否是有效的数字
 */
export const isNumber = (value: Key) => !isNaN(+value) && isFinite(+value);

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode?: DatePickerType, date?: Dayjs, time?: string | null) => {
  switch (mode) {
    case DatePickerType.MINUTE_1:
    case DatePickerType.MINUTE_15:
      if (time) {
        return {
          from: dayjs(time, 'x').startOf('day'),
          to: dayjs(time, 'x').endOf('day'),
        };
      }
      return {
        from: dayjs(date, 'x').startOf('day'),
        to: dayjs(date, 'x').endOf('day'),
      };
    case DatePickerType.HOUR:
      return {
        // from: dayjs(date, 'x').subtract(7, 'days').startOf('day'),
        // to: dayjs(date, 'x').endOf('day'),
        from: dayjs(date, 'x').startOf('day'),
        to: dayjs(date, 'x').endOf('day'),
      };
    case DatePickerType.DAY:
      return {
        from: dayjs(date, 'x').subtract(30, 'days').startOf('day'),
        to: dayjs(date, 'x').endOf('day'),
      };
    case DatePickerType.MONTH:
      return {
        from: dayjs(date, 'x').subtract(11, 'month').startOf('month'),
        to: dayjs(date, 'x').endOf('month'),
      };
    default:
      return {
        from: dayjs(date, 'x').startOf('day'),
        to: dayjs(date, 'x').endOf('day'),
      };
  }
};
