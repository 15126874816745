import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Select, MultiSelect, Upload, ShowInput, Radio } from '@maxtropy/components';
import { Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { TableRow } from '..';
import { useRequest } from 'ahooks';
import { getOrganizationWithCodeWithCurrent, OrganizationResponse } from '@/api/device';
import {
  V2OuListByProductionBasePostResponse,
  V2ProductionBaseListByOuPostResponse,
  apiV2OuListByProductionBasePost,
  apiV2ProductionBaseListByOuPost,
  apiV2WorkCenterAddPost,
  apiV2WorkCenterCheckCodeUniquePost,
  apiV2WorkCenterCheckNameUniquePost,
  apiV2WorkCenterDetailPost,
  apiV2WorkCenterEditPost,
} from '@maxtropy/device-customer-apis-v2';
import { debounce, isNil } from 'lodash-es';
import { DefaultOptionType } from 'antd/es/select';
import workCenter from '../../../assets/workCenter.png';

interface Iprops {
  type?: string;
  data?: TableRow;
  cancel?: () => void;
  confirm?: () => void;
}
interface flattenNode {
  key: string;
  title: string;
  disabled: boolean;
}

enum ReportType {
  STATION = 1,
  PROCESS = 2,
}

const ReportTypeDispaly = {
  [ReportType.STATION]: '基于工站',
  [ReportType.PROCESS]: '基于工序',
};

interface nodeItem extends flattenNode {
  children: nodeItem[];
}
const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: nodeItem = {
      key: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
      disabled: !item.data.hasPermission,
    };
    return res;
  });
};
const flattenTreeData = (data: nodeItem[]) => {
  let treeList: nodeItem[] = [];
  let flatten = function (data: nodeItem[]) {
    for (let i = 0; i < data.length; i++) {
      treeList.push(data[i]);
      if (data[i].children.length) {
        flatten(data[i].children);
      }
    }
  };
  flatten(data);
  return treeList;
};

export type ProductionBaseList = Exclude<V2ProductionBaseListByOuPostResponse['list'], undefined>[number];
export type OuList = Exclude<V2OuListByProductionBasePostResponse['list'], undefined>[number];

const CenterModalOp = ({ type, data, cancel, confirm }: Iprops) => {
  const [form] = useForm();
  const [selectedOuList, setSelectedOuList] = useState<
    {
      id?: number;
      name?: string;
    }[]
  >([]);
  const [productionBaseList, setProductionBaseList] = useState<ProductionBaseList[]>([]);

  const [ouList, setOuList] = useState<OuList[]>([]);
  const productionBaseId = Form.useWatch('productionBaseId', form);
  // 编辑时是否改变了生产基地
  const [isChangeProductionBaseId, setIsChangeProductionBaseId] = useState(false);
  // 已被选中的禁用的运营单元
  const [selectedDisOuIds, setSelectedDisOuIds] = useState<number[]>([]);
  const [Flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (type !== 'add' && data && data.id) {
      apiV2WorkCenterDetailPost({ id: data.id }).then(res => {
        form.setFieldsValue({
          ...res,
          reportTypeDisplay: ReportTypeDispaly[res.reportType as ReportType],
          ouNames: type === 'view' ? (res.ous ?? []).map(i => i.name).join('，') : undefined,
          ouIds: (res.ous ?? []).map(i => i.id),
        });
        setFlag(isNil(res.iconKey) ? true : false);
        if (res.ous) setSelectedOuList(res.ous);
      });
    }
  }, [type, data, form]);

  // 获取生产基地
  useEffect(() => {
    apiV2ProductionBaseListByOuPost().then(res => {
      setProductionBaseList(res?.list ?? []);
    });
  }, []);

  // 获取运营单元
  useEffect(() => {
    if (productionBaseId) {
      apiV2OuListByProductionBasePost({ id: productionBaseId }).then(res => {
        setOuList(res?.list ?? []);
      });
    }
  }, [productionBaseId]);

  // 生产基地
  const productionBaseOptions = useMemo(() => {
    return (productionBaseList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [productionBaseList]);

  // 编辑时：有权限与已选中的并集，新建时：有权限
  const ouOptions = useMemo(() => {
    if (type === 'edit' && ouList && selectedOuList) {
      const noPermission = (selectedOuList ?? [])
        .filter(item => !ouList.some(i => i.id === item.id))
        .map(item => ({
          label: item.name,
          value: item.id,
          disabled: true,
        }));
      setSelectedDisOuIds((noPermission ?? []).map(item => item.value as number));
      const hasPermission = ouList.map(item => ({
        label: item.name,
        value: item.id,
        disabled: false,
      }));
      return [...hasPermission, ...noPermission];
    }
    return (ouList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [ouList, selectedOuList, type]);

  const title = useMemo(() => {
    return type === 'add' ? '新建' : type === 'edit' ? '编辑' : '查看';
  }, [type]);

  const confirmBtn = () => {
    if (type === 'view') {
      cancel?.();
      return;
    }
    form
      .validateFields()
      .then(res => {
        if (data && data.id) {
          apiV2WorkCenterEditPost({ id: data.id, ...res }).then(res => {
            confirm?.();
          });
          return;
        }
        apiV2WorkCenterAddPost({
          id: data?.id,
          ...res,
        }).then(res => {
          confirm?.();
        });
      })
      .catch(e => {});
  };

  const { data: organization } = useRequest(getOrganizationWithCodeWithCurrent);
  const mcidTreeOptions = useMemo(() => {
    if (organization) {
      let tree = formatTreeData([organization]);
      return flattenTreeData(tree)
        ?.filter((m: nodeItem) => !m.disabled)
        ?.map(k => {
          return { value: k.key, label: k.title };
        });
    } else {
      return [];
    }
  }, [organization]);

  // 是否被映射
  const codeCanChange = useMemo(() => {
    return data?.isUse || type === 'view';
  }, [data, type]);

  return (
    <Modal
      title={`${title}工作中心信息`}
      open
      onCancel={() => cancel?.()}
      onOk={() => {
        const cannotSubmit =
          form.getFieldsError(['code'])[0].errors.length > 0 || form.getFieldsError(['name'])[0].errors.length > 0;
        if (cannotSubmit) return;
        form.submit();
      }}
    >
      <Form
        form={form}
        style={{ padding: '8px 48px' }}
        labelAlign="left"
        labelCol={{ flex: '128px' }}
        onFinish={confirmBtn}
        initialValues={{
          reportType: ReportType.STATION,
        }}
      >
        <Form.Item
          label="工作中心编码"
          name="code"
          rules={[
            { required: !codeCanChange },
            {
              validator: (_rule: any, value: any, _callback: any) => {
                if (!value) return Promise.resolve();
                let reg = /[\\\\/*?:"<>|\u4E00-\u9FFF]+/;
                if (reg.test(value)) {
                  return Promise.reject(new Error('编码为字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符'));
                }
                return Promise.resolve();
              },
            },
          ]}
          extra={
            type !== 'view' && (
              <Row>
                <Space>
                  <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
                  编码在能耗单元映射后不可被修改
                </Space>
              </Row>
            )
          }
        >
          {codeCanChange ? (
            <ShowInput />
          ) : (
            <Input
              maxLength={32}
              placeholder="请输入"
              onChange={debounce((e: any) => {
                if (isNil(e.target.value) || e.target.value === '') return;
                apiV2WorkCenterCheckCodeUniquePost({
                  id: data?.id,
                  code: e.target.value,
                }).then(res => {
                  if (isNil(res)) return;
                  if (!res.unique) {
                    form.setFields([
                      {
                        name: 'code',
                        errors: ['同租户下的工作中心编码重复'],
                      },
                    ]);
                  } else {
                    form.setFields([
                      {
                        name: 'code',
                        errors: [],
                      },
                    ]);
                  }
                });
              }, 500)}
            ></Input>
          )}
        </Form.Item>

        <Form.Item
          label="工作中心名称"
          name="name"
          rules={[
            { required: type !== 'view' },
            {
              validator: (_rule: any, value: any, _callback: any) => {
                if (!value) return Promise.resolve();
                let reg = /[\\\\/*?:"<>|]+/;
                if (reg.test(value)) {
                  return Promise.reject(new Error('名称为汉字，字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {type === 'view' ? (
            <ShowInput />
          ) : (
            <Input
              maxLength={32}
              placeholder="请输入"
              onChange={debounce((e: any) => {
                if (isNil(e.target.value) || e.target.value === '') return;
                apiV2WorkCenterCheckNameUniquePost({
                  id: data?.id,
                  name: e.target.value,
                }).then(res => {
                  if (isNil(res)) return;
                  if (!res.unique) {
                    form.setFields([
                      {
                        name: 'name',
                        errors: ['同租户下的工作中心名称重复'],
                      },
                    ]);
                  } else {
                    form.setFields([
                      {
                        name: 'name',
                        errors: [],
                      },
                    ]);
                  }
                });
              }, 500)}
            ></Input>
          )}
        </Form.Item>
        {type === 'view' ? (
          <>
            <Form.Item label="所属组织" name="customerName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="所属生产基地" name="productionBaseName">
              <ShowInput />
            </Form.Item>
            <Form.Item label="所属运营单元" name="ouNames">
              <ShowInput />
            </Form.Item>
            <Form.Item name="iconKey" label="工作中心图标">
              <img src={workCenter} alt="" style={{ width: '64px', height: '64px' }}></img>
            </Form.Item>
            <Form.Item label="产出物报工粒度" name="reportTypeDisplay" rules={[{ required: true }]}>
              <ShowInput />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="所属组织" name="customerMcid" rules={[{ required: true }]}>
              <Select options={mcidTreeOptions} placeholder="请选择" optionFilterProp="label" showSearch />
            </Form.Item>
            <Form.Item
              label="所属生产基地"
              name="productionBaseId"
              rules={[{ required: true, message: '请选择所属生产基地' }]}
            >
              <Select
                placeholder="请选择"
                options={productionBaseOptions}
                showSearch
                optionFilterProp="label"
                onChange={() => {
                  form.setFieldsValue({
                    ouIds: undefined,
                  });
                  if (type === 'edit') {
                    setIsChangeProductionBaseId(true);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="ouIds"
              label="所属运营单元"
              rules={[
                {
                  required: true,
                  message: '请选择所属运营单元',
                },
              ]}
            >
              <MultiSelect
                placeholder="请选择"
                options={ouOptions}
                onChange={(value, options) => {
                  // 是否全选
                  const isAllSel = ouOptions.length === value.length;
                  const ouIds =
                    type === 'add' || isChangeProductionBaseId
                      ? ouOptions.filter((item: DefaultOptionType) => !item.disabled).map(item => item.value)
                      : selectedDisOuIds.concat(
                          ouOptions
                            .filter((item: DefaultOptionType) => !item.disabled)
                            .map(item => item.value as number)
                        );

                  console.log(ouIds);
                  if (isAllSel) {
                    form.setFieldsValue({
                      ouIds,
                    });
                  }
                  if (value.length === 0) {
                    form.setFieldsValue({
                      ouIds: type === 'add' || isChangeProductionBaseId ? undefined : selectedDisOuIds,
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item name="iconKey" label="工作中心图标" extra="支持jpg、jpeg、png格式，文件小于1MB">
              <Upload disabled={type === 'view'} fileSize={1} />
            </Form.Item>
            <Form.Item label="产出物报工粒度" name="reportType" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value={ReportType.STATION}>{ReportTypeDispaly[ReportType.STATION]}</Radio>
                <Radio value={ReportType.PROCESS}>{ReportTypeDispaly[ReportType.PROCESS]}</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CenterModalOp;
