import { useEffect, useState } from 'react';
import { Modal, InputNumber, Table, Button, Select, EllipsisSpan, Form } from '@maxtropy/components';
import { apiV2OutputProductBomAddPost, V2OutputProductBomAddPostRequest } from '@maxtropy/device-customer-apis-v2';
import { SelectedRows } from './index';
import { BomItem } from '../BomTable';
import { AccessType } from '../type';
import { useUnit } from '../hook';
import { isEmpty } from 'lodash-es';

type SetQuantityProps = {
  data: SelectedRows;
  parentData: BomItem;
  outputProductId: number;
  updateFn: () => void;
  setVisible: (val: boolean) => void;
};

export const accessTypeOptions = Object.entries(AccessType).map(([k, v]) => ({
  label: v,
  value: Number(k),
}));

const baseColumns = [
  {
    title: '物料编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
  {
    title: '物料名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
];

const SetQuantity = ({ data, parentData, updateFn, setVisible, outputProductId }: SetQuantityProps) => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<SelectedRows>();
  const [getUnitName] = useUnit();

  useEffect(() => {
    form.setFieldsValue({ data });
    setDataSource(data);
  }, []);

  const onDel = (id: number) => {
    const newData = form.getFieldValue('data').filter((item: any) => item.id !== id);
    form.setFieldsValue({ data: newData });
    setDataSource(newData);
  };

  const columns = [
    ...baseColumns,

    {
      title: '数量',
      dataIndex: 'quantity',
      ellipsis: { showTitle: true },
      render: (text: number, _: Record<string, any>, index: number) => (
        <Form.Item
          style={{ marginBottom: 0 }}
          name={['data', index, 'quantity']}
          rules={[
            { required: true, message: '请填写数量' },
            {
              validator: (_, value) => {
                if (value <= 0) {
                  return Promise.reject('必须大于0');
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={text}
        >
          <InputNumber />
        </Form.Item>
      ),
    },
    {
      title: '单位',
      dataIndex: 'unitCode',
      ellipsis: { showTitle: true },
      render: (_: string, record: any) => <EllipsisSpan value={getUnitName(record.unitTypeCode, record.unitCode)} />,
    },
    {
      title: '获取方式',
      dataIndex: 'accessType',
      ellipsis: { showTitle: true },
      render: (text: number, _: Record<string, any>, index: number) => (
        <Form.Item style={{ marginBottom: 0 }} name={['data', index, 'accessType']} initialValue={text ?? 1}>
          <Select options={accessTypeOptions} />
        </Form.Item>
      ),
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      render: (_: any, record: Record<string, any>) => (
        <Button type="link" onClick={() => onDel(record.id)}>
          删除
        </Button>
      ),
    },
  ];

  const onOk = () => {
    form.validateFields().then(() => {
      const { data: formData } = form.getFieldsValue(true);

      if (isEmpty(formData)) {
        setVisible(false);
        return;
      }

      const { id = 0, rootId = 0, level = 0 } = parentData;
      const materials: V2OutputProductBomAddPostRequest['materials'] =
        formData?.map(
          ({ quantity, accessType, id: materialId }: { quantity: number; accessType: number; id: number }) => ({
            parentId: id,
            materialId,
            outputProductId,
            quantity: quantity,
            accessType: accessType,
            rootId: rootId,
            level: level + 1,
          })
        ) ?? [];

      apiV2OutputProductBomAddPost({ materials }).then(res => {
        setVisible(false);
        updateFn();
      });
    });
  };

  return (
    <>
      <Modal
        title="设置数量"
        okText="确定"
        open={true}
        size="big"
        bodyScroll
        onOk={onOk}
        onCancel={() => setVisible(false)}
      >
        <Form form={form}>
          <Table rowKey="id" columns={columns} dataSource={dataSource} />
        </Form>
      </Modal>
    </>
  );
};

export default SetQuantity;
