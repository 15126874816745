import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Layout } from 'antd';
import EnergyForecast from '../AIDiagnosis/EnergyConsumptionForecast';
import SiderContent from './Sider/index';
import {
  V2ProductSheetNextTwentyFourHourListPostResponse,
  apiV2ProductSheetNextTwentyFourHourListPost,
} from '@maxtropy/device-customer-apis-v2';

const AIDiagnosis = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes(); //面包屑
  const { Content, Sider } = Layout;

  const [workCenterList, setWorkCenterList] = useState<V2ProductSheetNextTwentyFourHourListPostResponse['list']>([]);

  const [sideWorkCenterId, setSideWorkCenterId] = useState<string>();

  const [currentKey, setCurrentKey] = useState<number>(); //抽屉和生产单的选中态同步

  const childProps = (itemKey: number) => {
    setCurrentKey(itemKey);
  };

  useEffect(() => {}, [currentKey]);

  // 获取工作中心生产单(未来24小时)
  useEffect(() => {
    apiV2ProductSheetNextTwentyFourHourListPost({}).then(res => {
      setWorkCenterList(res.list);
    });
  }, []);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <Layout className={styles.layout}>
        <Content className={styles.content} style={{ marginRight: 10 }}>
          <EnergyForecast
            workCenterList={workCenterList}
            setSideWorkCenterId={setSideWorkCenterId}
            setCurrentKey={setCurrentKey}
            currentKey={currentKey}
            childProps={childProps}
          />
        </Content>
        <Sider className={styles.siderDemo} width={320}>
          <SiderContent
            sideWorkCenterId={sideWorkCenterId as string}
            setCurrentKey={setCurrentKey}
            currentKey={currentKey}
          />
        </Sider>
      </Layout>
    </Wrapper>
  );
};

export default AIDiagnosis;
