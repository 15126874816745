import {
  Modal,
  EllipsisSpan,
  Table,
  Button,
  Wrapper,
  Paging,
  usePaging,
  useUpdate,
  useBreadcrumbRoutes,
  Input,
  Form,
  CustomFilter,
} from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  apiV2UnitConsumptionPagePost,
  V2UnitConsumptionPagePostResponse,
  apiV2UnitConsumptionLogicDeletePost,
} from '@maxtropy/device-customer-apis-v2';
import { UniconsumablePagingItem } from '@/api/productionConsumptionBoard';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { Space } from 'antd';

const columns: any = [
  {
    title: '产出物名称',
    dataIndex: 'outputProductName',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '产出物编码',
    dataIndex: 'outputProductCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工作中心',
    dataIndex: 'workCenterName',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工作中心编码',
    dataIndex: 'workCenterCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface FilterParams extends Omit<UniconsumablePagingItem, 'page' | 'size'> {}

const UnitConsumption: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [data, setData] = useState<V2UnitConsumptionPagePostResponse['list']>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<FilterParams>();

  const [updateState, updateFn] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [modal, contextHolder] = Modal.useModal();

  const hasCreatePermission = useHasPermission(PermissionsType.B_CREATECONSUMPTIONTHRESHOLD);
  const hasEditPermission = useHasPermission(PermissionsType.B_EDITCONSUMPTIONTHRESHOLD);
  const hasViewPermission = useHasPermission(PermissionsType.B_VIEWCOUNSUMPTIONTHRESHOLD);
  const hasCopyPermission = useHasPermission(PermissionsType.B_COPYCONSUMPTIONTHRESHOLD);
  const hasDeletePermission = useHasPermission(PermissionsType.P_DELETECONSUMPTIONTHRESHOLD);

  const onFinish = (val: any) => {
    const params = {
      outputNameOrCode: val.product,
      workCenterNameOrCode: val.workCenter,
    };
    setSearchParams(params);
  };

  const onReset = () => {
    setPageOffset(1);
  };

  // 新建单耗阈值
  const onExport = () => {
    navigate(`/productionManagement/production/unitConsumptionSetting/create`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 150,
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            {hasViewPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/productionManagement/production/unitConsumptionSetting/view/${record.id}`);
                }}
              >
                查看
              </Button>
            )}
            {hasEditPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/productionManagement/production/unitConsumptionSetting/${record.id}`);
                }}
              >
                编辑
              </Button>
            )}

            {hasCopyPermission && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/productionManagement/production/unitConsumptionSetting/copy/${record.id}`);
                }}
              >
                复制
              </Button>
            )}
            {hasDeletePermission && (
              <Button
                type="link"
                onClick={() => {
                  modal.confirm({
                    title: <span style={{ color: 'white' }}>是否删除该产出物阈值设定？删除后无法恢复</span>,
                    icon: <ExclamationCircleOutlined />,
                    onOk: () => {
                      apiV2UnitConsumptionLogicDeletePost({ id: v.id }).then(res => updateFn());
                    },
                    okText: '删除',
                  });
                }}
              >
                删除
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    apiV2UnitConsumptionPagePost({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      setData(res.list ?? []);
      setTotalCount(res.total ?? 0);
    });
  }, [searchParams, setTotalCount, pageOffset, pageSize, updateState]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.content}>
      <div className={styles.filterWrapper}>
        <CustomFilter<FilterParams> form={form} onFinish={onFinish} onReset={onReset}>
          <Form.Item name="product" label="产出物">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item name="workCenter" label="工作中心">
            <Input placeholder="请输入" />
          </Form.Item>
        </CustomFilter>
      </div>

      <div className={styles.spaceStyle} />

      <div className={styles.contentTable}>
        {hasCreatePermission && (
          <Button type="primary" onClick={onExport}>
            新建单耗阈值
          </Button>
        )}
        <Table
          rowKey={'id'}
          sticky
          scroll={{ x: 1300 }}
          // loading={loading}
          dataSource={data}
          columns={buildColumns}
          style={{ marginTop: 20 }}
        />

        <Paging pagingInfo={pagingInfo} />
      </div>
      {contextHolder}
    </Wrapper>
  );
};

export default UnitConsumption;
