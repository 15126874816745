import { CompareType, DataBtn, DatePickerTypeDisplay, StatisticsPartition } from '@/api/energyMediaComparison';
import { FormInstance, RadioChangeEvent } from 'antd';
import { DatePicker, Form, message, Radio } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { getDefaultTime, getTs, judgeIndicatorAccumulateOrinstantaneous } from '../../utils';
import styles from './index.module.scss';

const { RangePicker } = DatePicker;

export interface DateSwitchProps {
  form: FormInstance<any>;
  compareType: CompareType;
  // btnGroup?: DateBtn[];
  selectBtn: StatisticsPartition;
  value: [Dayjs, Dayjs];
  onQueryChange: (value: any) => void;
}

const DateSwitch: FC<DateSwitchProps> = props => {
  const { form, compareType, onQueryChange, selectBtn, value } = props;
  const [btnGroup, setBtnGroup] = useState<DataBtn[]>();
  const indicatorIdTop = Form.useWatch('indicatorIdTop', form);
  const indicatorIdBottom = Form.useWatch('indicatorIdBottom', form);

  useEffect(() => {
    // 如果是对象对比
    if (compareType === CompareType.OBJECT) {
      const btnGroup = judgeIndicatorAccumulateOrinstantaneous(indicatorIdTop, indicatorIdBottom);
      setBtnGroup(btnGroup);
    } else {
      // 如果是历史对比
      setBtnGroup([{ aggrby: StatisticsPartition.MINUTE_15 }, { aggrby: StatisticsPartition.DAY }]);
    }
  }, [indicatorIdTop, indicatorIdBottom, compareType]);

  const onRadioChange = (e: RadioChangeEvent) => {
    const target = btnGroup?.find(item => item.aggrby === e.target.value);
    if (target) {
      onQueryChange(
        compareType === CompareType.OBJECT
          ? {
              timeResolution: target.aggrby,
              ...getDefaultTime(target.aggrby, dayjs()),
            }
          : {
              timeResolution: target.aggrby,
            }
      );
    }
  };

  const onChangeRangePicker = (range: any) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (
        selectBtn === StatisticsPartition.DAY ||
        selectBtn === StatisticsPartition.MINUTE_15 ||
        selectBtn === StatisticsPartition.MINUTE_1
      ) {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
        const mostDays =
          selectBtn === StatisticsPartition.DAY ? 31 : selectBtn === StatisticsPartition.MINUTE_1 ? 1 : 3;
        if (diff >= mostDays) {
          return message.warning(`最大上限为${mostDays}天`);
        }
      } else if (selectBtn === StatisticsPartition.MONTH) {
        const diff = dayjs(endDate).diff(dayjs(beginDate), 'month');
        if (diff >= 24) {
          return message.warning('最大上限为24个月');
        }
      }
      onQueryChange({
        timeResolution: selectBtn,
        ...getTs(selectBtn, dayjs(beginDate), dayjs(endDate)),
      });
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  return (
    <div className={styles.dateSwitch}>
      <div className={styles.switchBtn}>
        <Radio.Group onChange={onRadioChange} value={selectBtn} buttonStyle="solid">
          {btnGroup?.map(btn => (
            <Radio.Button key={btn.aggrby} value={btn.aggrby}>
              {DatePickerTypeDisplay[btn.aggrby as StatisticsPartition]}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      {compareType === CompareType.OBJECT && (
        <div className={styles.datePickerArea}>
          {(selectBtn === StatisticsPartition.MINUTE_15 || selectBtn === StatisticsPartition.MINUTE_1) && (
            <>
              <RangePicker
                value={value}
                onChange={onChangeRangePicker}
                disabledDate={disabledDate}
                allowClear={false}
              />
            </>
          )}
          {(selectBtn === StatisticsPartition.DAY || selectBtn === StatisticsPartition.MONTH) && (
            <>
              <RangePicker
                value={value}
                disabledDate={disabledDate}
                onChange={onChangeRangePicker}
                picker={selectBtn === StatisticsPartition.DAY ? 'date' : 'month'}
                allowClear={false}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DateSwitch;
