import { Empty, FormContent } from '@maxtropy/components';
import React, { useCallback, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import ResizeObserver from 'rc-resize-observer';
import styles from './index.module.scss';
import center from '../icon/center@2x.png';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { V2ProductSheetNextTwentyFourHourListPostResponse } from '@maxtropy/device-customer-apis-v2';
dayjs.extend(duration);

interface Props {
  workCenterList: V2ProductSheetNextTwentyFourHourListPostResponse['list'];
  setSideWorkCenterId: (v: string) => void;
  currentKey?: number;
  setCurrentKey: (v: number) => void;
  childProps: (v: number) => void;
}

const ColorArray = ['#2A6948', '#7B6632', '#1F4F7C', '#7B4F32', '#403178', '#3B424A', '#7B3147', '#23346D'];

const EnergyForecast: React.FC<Props> = props => {
  const { workCenterList, setSideWorkCenterId, setCurrentKey, currentKey } = props;

  const checkBorder = '3px solid white';

  const [segment, setSegment] = useState<number>(0); //等分宽度
  const [width, setWidth] = useState<number>(0); //容器宽度

  const calcWidth = useCallback(
    (start: any, stop: any) => {
      const tomorrowStartTime = dayjs().add(1, 'day').startOf('day');
      const tomorrowEndTime = dayjs().add(1, 'day').endOf('day');

      let tmpStartTime = dayjs(start);
      let tmpEndTime = dayjs(stop);

      if (tmpStartTime.isBefore(tomorrowStartTime)) {
        tmpStartTime = tomorrowStartTime;
      }

      if (tmpEndTime.isAfter(tomorrowEndTime)) {
        tmpEndTime = tomorrowEndTime;
      }
      const interval = tmpEndTime.diff(tmpStartTime, 'seconds') + 1;
      const passageLen = 15 * 60; // 15分钟
      const cardWidth = (interval / passageLen) * segment;

      return cardWidth;
    },
    [segment]
  );

  const calcLeft = useCallback(
    (start: any) => {
      const tomorrowStartTime = dayjs().add(1, 'day').startOf('day');

      const tmpStartTime = dayjs(start);
      if (tmpStartTime.isBefore(tomorrowStartTime)) return 0;
      const interval = tmpStartTime.diff(tomorrowStartTime, 'seconds') + 1;
      const passageLen = 15 * 60; // 15分钟
      const cardLeft = (interval / passageLen) * segment;

      return cardLeft;
    },
    [segment]
  );

  // 点击卡片，拿到工作中心id 以获取侧边栏数据
  const checkDetail = (key: number, centerId: string) => {
    setCurrentKey(key);
    setSideWorkCenterId(centerId);
  };

  useEffect(() => {
    if (workCenterList?.length === 0) return;
    const centerId = String(workCenterList?.[0]?.workCenterId);
    setSideWorkCenterId(centerId);

    if (workCenterList?.[0].productSheets?.length === 0) return;
    const itemKey = workCenterList?.[0]?.workCenterId! + workCenterList?.[0].productSheets![0]?.id!;
    setCurrentKey(itemKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenterList]);

  const getChartOption = (item: any) => {
    return {
      grid: {
        left: 10,
        right: 8,
        top: 8,
        bottom: 20,
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any[]) {
          const value = params[0].value;
          const formattedValue = value + '(MJ)';
          const timeValue = dayjs(params[0].axisValueLabel).format('YYYY-MM-DD HH:mm');

          return `
          <div >
          <div>${timeValue}</div>
            <div><span>预计能耗</span> <span><strong>${formattedValue}</strong></span></div>
          </div>
        `;
        },
        transitionDuration: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.8)',
          fontSize: 12,
          fontWeight: 500,
        },
        axisPointer: {
          type: 'shadow', // 显示阴影效果
        },
      },
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false, // 隐藏 X 轴的底线
        },
        axisLabel: {
          formatter: function (value: any, index: any) {
            if (index % 2 === 0) {
              return dayjs(value).format('HH:mm');
            } else {
              return '';
            }
          },
          color: 'rgba(255,255,255,0.85)', // 设置 x 轴文字颜色
        },
        splitArea: {
          show: true, // 显示坐标轴之间的区域
          areaStyle: {
            color: 'rgba(45, 45, 45, 1)', // 设置 X 轴和 Y 轴之间区域的背景颜色
          },
        },

        data: item?.data?.map(
          (i: { ts: string | number | dayjs.Dayjs | Date | null | undefined }) =>
            // dayjs(i.ts!).format('HH:mm')
            i.ts
        ),
      },

      yAxis: {
        type: 'value',
        axisLabel: {
          show: false, // 不显示 Y 轴标签
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          data: item?.data?.map((i: { predictiveValue: any }) =>
            i.predictiveValue !== null || undefined ? i.predictiveValue?.toFixed(2) : '--'
          ),
          type: 'bar',
          barWidth: 5,
          showBackground: false,
          itemStyle: {
            color: 'rgba(83, 222, 244, 1)', // 设置柱状图的颜色为蓝色
          },
        },
      ],
    };
  };

  return (
    <>
      {
        <FormContent title="明日生产能耗预测" style={{ overflowY: 'auto', height: 795, padding: 20 }}>
          {workCenterList && workCenterList?.length > 0 ? (
            workCenterList?.map((item, centerIndex) => (
              <div style={{ marginBottom: 24 }} key={item.workCenterId}>
                <div className={styles.plantTitle}>
                  <span>
                    <img src={center} alt="" />
                    {item.workCenterName}
                  </span>
                </div>
                <div className={styles.plantCard}>
                  <div className={styles.codeInfo}>
                    <div className={styles.codeInfoLeft}>
                      <div>生产单编码</div>
                      <div>产出物编码</div>
                    </div>
                    <div className={styles.codeCard}>
                      <ResizeObserver onResize={({ width }) => setSegment(width / 96)}>
                        {item.productSheets?.some(v => v.projectStartTime !== null) ? (
                          <div className={styles.bgLine}>
                            {item.productSheets?.map((sheetItem, sheetIndex) => {
                              const itemKey = item.workCenterId! + sheetItem.id!;
                              const cardColor =
                                ColorArray[(centerIndex * workCenterList.length + sheetIndex) % ColorArray.length];

                              return (
                                <div
                                  key={itemKey}
                                  className={styles.codeCardItem}
                                  style={
                                    currentKey === itemKey
                                      ? {
                                          left: calcLeft(sheetItem.projectStartTime),
                                          backgroundColor: cardColor,
                                          border: checkBorder,
                                          width: calcWidth(sheetItem.projectStartTime, sheetItem.projectStopTime),
                                          height: 78,
                                          top: -3,
                                        }
                                      : {
                                          left: calcLeft(sheetItem.projectStartTime),
                                          backgroundColor: cardColor,
                                          width: calcWidth(sheetItem.projectStartTime, sheetItem.projectStopTime),
                                        }
                                  }
                                  onClick={() => checkDetail(itemKey, String(item.workCenterId))}
                                >
                                  <div title={sheetItem.code}>{sheetItem.code}</div>
                                  <div title={sheetItem.outputProductCode}>{sheetItem.outputProductCode}</div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className={styles.dataNullStyle}>暂无生产单数据</div>
                        )}
                      </ResizeObserver>
                    </div>
                  </div>
                  <div className={styles.histogramBox}>
                    <div className={styles.leftInfo}>预计能耗</div>
                    <ResizeObserver onResize={({ width }) => setWidth(width)}>
                      <div className={styles.echarts}>
                        {item.data?.some(v => v.predictiveValue !== null) ? (
                          <ReactEcharts notMerge style={{ height: 123, width }} option={getChartOption(item)} />
                        ) : (
                          <div className={styles.energyNullStyle}>暂无能耗数据</div>
                        )}
                      </div>
                    </ResizeObserver>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Empty style={{ padding: '36px 0' }} description="暂无数据" />
          )}
        </FormContent>
      }
    </>
  );
};

export default EnergyForecast;
