import { Key, useEffect, useState, useMemo } from 'react';
import { Button, SubContent, Select, Form, InputNumber, ShowInput } from '@maxtropy/components';
import { Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import { OutputState, getOutputAllHalfList } from '@/api/outputConfig';
import { OutputList, UnitItem } from '../../utils';
import { V2OutputProductDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

interface Prop {
  form: any;
  unitCodeAndUnitNameList?: UnitItem[];
  detail?: V2OutputProductDetailPostResponse;
}

const ProductLink = ({ form, unitCodeAndUnitNameList, detail }: Prop) => {
  const [choseOutputIds, setChoseOutputIds] = useState<Key[]>([]);
  const [outputList, setOutputList] = useState<OutputList[]>([]);

  const outputProductLink = Form.useWatch('outputProductLink', form);

  useEffect(() => {
    getOutputAllHalfList(detail?.id).then(res => {
      if (detail) {
        const disableList = res.list.filter(i => i.state === OutputState.DISABLE);
        const resOutIds = (detail?.outputProductLink ?? []).map(i => i.constituentOutputId);
        let ownDisableList: Array<OutputList> = [];
        disableList.forEach(i => {
          if (resOutIds.includes(i.id ? Number(i.id) : undefined)) {
            ownDisableList.push({
              ...i,
              disabled: false,
            });
          }
        });
        const ableList = res.list
          .filter(i => i.state === OutputState.ABLE)
          .map(i => ({
            ...i,
            disabled: false,
          }));
        setOutputList([...ownDisableList, ...ableList]);

        // 禁用已选择产出物
        if (detail?.outputProductLink && detail.outputProductLink.length > 0) {
          setChoseOutputIds(detail.outputProductLink.map(i => i.constituentOutputId!));
        }
      } else {
        let list = res.list
          .filter(i => i.state === OutputState.ABLE)
          .map(i => ({
            ...i,
            disabled: false,
          }));
        setOutputList(list);
      }
    });
  }, [detail]);

  const outputOption = useMemo(() => {
    if (outputList && outputList.length !== 0) {
      return outputList.map(i => ({
        label: `${isNil(i.code) ? '--' : i.code}/${i.name}`,
        value: i.id,
        disabled: choseOutputIds.some(v => i.id === v),
      }));
    }
  }, [outputList, choseOutputIds]);

  return (
    <>
      <SubContent title="物料表">
        <Form.List name="outputProductLink">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => {
                return (
                  <Row key={field.key} gutter={16}>
                    <Col span={7}>
                      <Form.Item
                        name={[field.name, 'constituentOutputId']}
                        label="产出物编码"
                        rules={[{ required: true, message: '请选择产出物编码' }]}
                        labelCol={{ span: 10 }}
                      >
                        <Select
                          showSearch
                          optionFilterProp="label"
                          style={{ width: '100%' }}
                          placeholder="请选择产出物编码"
                          options={outputOption}
                          onChange={v => {
                            setChoseOutputIds([...choseOutputIds, v]);
                          }}
                          onDropdownVisibleChange={open => {
                            const outputProductLink = form.getFieldValue('outputProductLink');
                            if (open) {
                              if (outputProductLink.at(index)) {
                                const out = choseOutputIds.filter(
                                  i => i !== outputProductLink.at(index).constituentOutputId
                                );
                                setChoseOutputIds(out);
                              }
                            } else {
                              const ids = outputProductLink
                                .filter((i: any) => i !== undefined)
                                .map((i: any) => i.constituentOutputId);
                              setChoseOutputIds(ids);
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item label="产出物名称" labelCol={{ span: 10 }}>
                        <ShowInput
                          value={
                            outputProductLink && outputProductLink.at(index)
                              ? outputList.find(i => i.id === outputProductLink.at(index).constituentOutputId)?.name
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={[field.name, 'constituentOutputDosage']}
                        label="物料用量"
                        rules={[{ required: true, message: '请输入物料用量' }]}
                        labelCol={{ span: 8 }}
                      >
                        <InputNumber style={{ width: '100%' }} min={0} placeholder="请输入" precision={2} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item label="单位" labelCol={{ span: 12 }}>
                        <ShowInput
                          value={
                            outputProductLink && outputProductLink.at(index)
                              ? unitCodeAndUnitNameList?.find(
                                  u =>
                                    u.unitCode ===
                                    outputList.find(i => i.id === outputProductLink.at(index).constituentOutputId)
                                      ?.unitCode
                                )?.unitName
                              : undefined
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="类型" labelCol={{ span: 10 }}>
                        <ShowInput value="半成品" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Button
                        type="link"
                        style={{ padding: '4px 0' }}
                        onClick={() => {
                          remove(index);
                          const outputProductLink = form.getFieldValue('outputProductLink');
                          const ids = outputProductLink
                            .filter((i: any) => i !== undefined)
                            .map((i: any) => i.constituentOutputId);
                          setChoseOutputIds(ids);
                        }}
                      >
                        删除
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <>
                <Button icon={<PlusOutlined />} type="dashed" ghost onClick={() => add()}>
                  新增
                </Button>
              </>
            </>
          )}
        </Form.List>
      </SubContent>
    </>
  );
};

export default ProductLink;
