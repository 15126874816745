import { InfoCircleFilled } from '@ant-design/icons';
import { Radio, Space, Tooltip } from 'antd';
import classnames from 'classnames/bind';
import { useMemo, useState } from 'react';
import {
  TimeOfUseTariffType,
  EPriceTime,
  TimeOfUseTariffTypeColor,
  TimeOfUseTariffTypeDisplay,
  PricedisplayType,
} from '@/shared/types';
import styles from './index.module.scss';
import { getItemTime, getPeriodTime, getPeriodTypes } from './utils';

const cx = classnames.bind(styles);

interface EPricePeakItemProps {
  value?: EPriceTime[];
  onChange?: (times: EPriceTime[]) => void;
  displayType?: PricedisplayType;
  disabled?: boolean;
}

const EPricePeakItem: React.FC<EPricePeakItemProps> = ({ value = [], onChange, displayType, disabled = false }) => {
  const [ePricePeakType, setEPricePeakType] = useState<TimeOfUseTariffType>();

  const [range, setRange] = useState<[number | undefined, number | undefined]>([undefined, undefined]);
  const [start, end] = range;

  // console.log(value)

  // useEffect(() => {
  //   onChange?.([]);
  // }, [displayType])

  const getItemColor = (index: number) => {
    const [tempStart, tempEnd] = [start, end].sort((a, b) => (a || 0) - (b || 0));
    if (tempStart !== undefined && index >= tempStart && tempEnd !== undefined && index <= tempEnd) {
      return `${TimeOfUseTariffTypeColor[ePricePeakType!]}`;
    }
    const time = value.find(time => time.intervalStart <= index && time.intervalEnd >= index);
    if (time) {
      return TimeOfUseTariffTypeColor[time.type];
    }
    return 'rgba(var(--base-text-color),0.2)';
  };

  const count = useMemo(() => {
    if (displayType === undefined) return 0;
    return displayType === PricedisplayType.HALF ? 48 : 24;
  }, [displayType]);

  const onItemClick = (index: number) => {
    if (ePricePeakType === undefined) {
      return;
    }
    if (displayType === undefined) {
      return;
    }
    if (start === undefined) {
      setRange([index, index]);
      return;
    }

    const [tempStart, tempEnd] = [start, index].sort((a, b) => a - b);
    onChange?.(
      getPeriodTypes(
        [
          ...value,
          {
            type: ePricePeakType!,
            intervalStart: tempStart,
            intervalEnd: tempEnd,
          },
        ],
        displayType
      ).filter((time: EPriceTime) => time.type !== undefined)
    );

    setRange([undefined, undefined]);
  };

  const onItemMouseEnter = (index: number) => {
    if (ePricePeakType === undefined) {
      return;
    }
    if (start === undefined) {
      return;
    }
    setRange([start, index]);
  };

  const onItemMouseLeave = () => {
    setRange([undefined, undefined]);
  };

  return (
    <>
      {!disabled ? (
        <Space direction="vertical">
          <Radio.Group
            value={ePricePeakType}
            onChange={e => {
              setEPricePeakType(e.target.value);
            }}
          >
            <Radio value={TimeOfUseTariffType.SUMMIT}>
              <div className={cx('radio-wrap')}>
                {TimeOfUseTariffTypeDisplay[TimeOfUseTariffType.SUMMIT]}
                <span
                  style={{ backgroundColor: TimeOfUseTariffTypeColor[TimeOfUseTariffType.SUMMIT] }}
                  className={cx('tip-bar')}
                />
              </div>
            </Radio>
            <Radio value={TimeOfUseTariffType.PEAK}>
              <div className={cx('radio-wrap')}>
                {TimeOfUseTariffTypeDisplay[TimeOfUseTariffType.PEAK]}
                <span
                  style={{ backgroundColor: TimeOfUseTariffTypeColor[TimeOfUseTariffType.PEAK] }}
                  className={cx('tip-bar')}
                />
              </div>
            </Radio>
            <Radio value={TimeOfUseTariffType.PLAIN}>
              <div className={cx('radio-wrap')}>
                {TimeOfUseTariffTypeDisplay[TimeOfUseTariffType.PLAIN]}
                <span
                  style={{ backgroundColor: TimeOfUseTariffTypeColor[TimeOfUseTariffType.PLAIN] }}
                  className={cx('tip-bar')}
                />
              </div>
            </Radio>
            <Radio value={TimeOfUseTariffType.VALLEY}>
              <div className={cx('radio-wrap')}>
                {TimeOfUseTariffTypeDisplay[TimeOfUseTariffType.VALLEY]}
                <span
                  style={{ backgroundColor: TimeOfUseTariffTypeColor[TimeOfUseTariffType.VALLEY] }}
                  className={cx('tip-bar')}
                />
              </div>
            </Radio>
          </Radio.Group>
          <div className={cx('peak-item')} onMouseLeave={() => onItemMouseLeave()}>
            {[...new Array(count)].map((_, index) => (
              <Tooltip
                title={`${getItemTime(index, displayType!)}~${getItemTime(index + 1, displayType!)}`}
                key={index}
              >
                <span
                  className={cx(
                    'bar',
                    displayType === PricedisplayType.HALF ? 'half' : '',
                    displayType === PricedisplayType.HOUR ? 'hour' : ''
                  )}
                  style={{ background: getItemColor(index) }}
                  onClick={() => onItemClick(index)}
                  onMouseEnter={() => onItemMouseEnter(index)}
                />
              </Tooltip>
            ))}
          </div>
          <Space>
            <InfoCircleFilled style={{ color: 'var(--primary-color)', marginRight: '5px' }} />
            {`先选中分段（尖峰平谷)，再在时间轴上分别单击选择起止时间块，直到无灰色时间块方可。`}
          </Space>
        </Space>
      ) : (
        getPeriodTypes(value, displayType!).map((item, index) => {
          if (item.type) {
            return (
              <Tooltip title={getPeriodTime(item, displayType!)} key={index}>
                {[...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
                  <span
                    key={i}
                    className={cx(
                      'bar',
                      displayType === PricedisplayType.HALF ? 'half' : '',
                      displayType === PricedisplayType.HOUR ? 'hour' : ''
                    )}
                    style={{
                      background: TimeOfUseTariffTypeColor[item.type],
                    }}
                  />
                ))}
              </Tooltip>
            );
          }
          return [...new Array(item.intervalEnd - item.intervalStart + 1)].map((_, i) => (
            <span key={i} className={cx('bar')} style={{ background: 'rgba(255,255,255,0.2)' }} />
          ));
        })
      )}
    </>
  );
};

export default EPricePeakItem;
