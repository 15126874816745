import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

// 执行周期枚举
export enum ReportType {
  SINGLE = 1,
  DAY = 2,
  MONTH = 3,
  YEAR = 4,
  WEEK,
}

// 执行周期显示
export const ReportTypeDisplay = {
  [ReportType.SINGLE]: '单次',
  [ReportType.DAY]: '按日执行',
  [ReportType.MONTH]: '按月执行',
  [ReportType.YEAR]: '按年执行',
  [ReportType.WEEK]: '按周执行',
};

export interface Report {
  id: number;
  name: string;
  ouName: string;
  reportStrategyName: string;
  statisticalBeginTime: string;
  statisticalEndTime: string;
  urlKey: string;
  // 执行周期
  reportType?: ReportType;
  ous: {
    id: number;
    name: string;
    serialNumber: string;
  }[];
}

export interface ReportPageReq extends PageRequest {
  ouIds?: number[];
  reportStrategyIds?: number[];
  statisticalBeginTimeFrom?: number;
  statisticalBeginTimeTo?: number;
  statisticalEndTimeFrom?: number;
  statisticalEndTimeTo?: number;
  // 执行周期
  reportType?: ReportType;
}

export function getReportPage(query: ReportPageReq) {
  return fetch<PageResponse<Report>>(
    `/api/v2/report-strategy/record/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export const removeReport = (id: number) =>
  fetch<boolean>(
    `/api/v2/report-strategy/record/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
