import { getAxisFormatter } from './helper';
import { Query } from '../../Electricity';
import { StatisticsPartition } from '../../../../api/statistics';
import { isNil } from 'lodash-es';
import { TimeOfUseTariffType, TimeOfUseTariffTypeDisplay, TimeOfUseTariffTypeColor } from '@/shared/types';
import { RateElectricityPriceDetailVo } from '../../../../api/electricity';
import dayjs from 'dayjs';
import { V2ElectricEnergyConsumptionListPostResponse } from '@maxtropy/device-customer-apis-v2';

export interface ParamsOption {
  chartData: V2ElectricEnergyConsumptionListPostResponse['list'];
  query: Query;
  prices?: RateElectricityPriceDetailVo[];
}

type FormatterItem = {
  name: string;
  value: number;
  marker: string;
  data: [string, number | null];
  seriesName: string;
  dataIndex: number;
  axisValue: string;
};

export const getChartOption = ({ chartData = [], query, prices }: ParamsOption) => {
  const textColor = window.getComputedStyle(document.documentElement).getPropertyValue('--text-color');

  const formatString = getAxisFormatter(query);
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 50,
      right: 50,
      top: 95,
      bottom: 80,
    },
    // dataZoom: [
    //   {
    //     type: 'slider',
    //     start: 0,
    //     end: 100,
    //     bottom: 15,
    //     height: 20,
    //     backgroundColor: 'rgba(226,227,229,0.5)',
    //     fillerColor: 'rgba(142,177,224,0.3)',
    //     textStyle: {
    //       fontSize: 10
    //     }
    //   },
    //   { type: 'inside' }
    // ],
    tooltip: {
      trigger: 'axis',
      formatter(items: FormatterItem[]) {
        const { axisValue } = items[0];
        let str = dayjs(+axisValue).format(formatString);
        items.forEach(item => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}kWh` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
    legend:
      query.resolution === StatisticsPartition.HOUR
        ? undefined
        : {
            right: 20,
            top: 50,
            textStyle: {
              color: textColor,
            },
          },
    color: [
      TimeOfUseTariffTypeColor[TimeOfUseTariffType.VALLEY],
      TimeOfUseTariffTypeColor[TimeOfUseTariffType.PLAIN],
      TimeOfUseTariffTypeColor[TimeOfUseTariffType.PEAK],
      TimeOfUseTariffTypeColor[TimeOfUseTariffType.SUMMIT],
    ],
    xAxis: {
      data: chartData.map(i => i.ts),
      axisLabel: {
        formatter: (value: string, index: number) => {
          return dayjs(+value).format(formatString);
        },
      },
    },
    yAxis: {
      type: 'value',
      name: 'kWh',
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
    visualMap:
      query.resolution === StatisticsPartition.HOUR
        ? {
            show: true,
            right: 20,
            top: 50,
            orient: 'horizontal',
            itemSymbol: 'roundRect',
            itemWidth: 25,
            formatter: (value: TimeOfUseTariffType) => {
              return `${TimeOfUseTariffTypeDisplay[value]}电量`;
            },
            categories: [
              `${TimeOfUseTariffType.VALLEY}`,
              `${TimeOfUseTariffType.PLAIN}`,
              `${TimeOfUseTariffType.PEAK}`,
              `${TimeOfUseTariffType.SUMMIT}`,
            ],
            inRange: {
              color: [
                TimeOfUseTariffTypeColor[TimeOfUseTariffType.VALLEY],
                TimeOfUseTariffTypeColor[TimeOfUseTariffType.PLAIN],
                TimeOfUseTariffTypeColor[TimeOfUseTariffType.PEAK],
                TimeOfUseTariffTypeColor[TimeOfUseTariffType.SUMMIT],
              ],
            },
            textStyle: {
              color: textColor,
            },
          }
        : undefined,

    series:
      query.resolution === StatisticsPartition.HOUR
        ? [
            {
              type: 'bar',
              name: '电量',
              data: chartData.map((a, index) => {
                const type = prices?.find(item => {
                  const date = dayjs(a.ts);
                  const startDate = dayjs(`${date.format('YYYY-MM-DD')} ${item.startTime}`);
                  let endDate = dayjs(`${date.format('YYYY-MM-DD')} ${item.endTime}`);
                  if (item.endTime === '00:00:00') {
                    endDate = dayjs(endDate).endOf('date');
                  }
                  return date.isBetween(startDate, endDate, null, '[)');
                })?.type;
                return [index, a.value, `${type ?? ''}`];
              }),
            },
          ]
        : [
            // 低谷电价
            {
              type: 'bar',
              stack: 'total',
              name: '低谷电价',
              data: chartData.map((a, index) => {
                return [index, a.valleyValue];
              }),
            },
            // 平段电价
            {
              type: 'bar',
              stack: 'total',
              name: '平段电价',
              data: chartData.map((a, index) => {
                return [index, a.plainValue];
              }),
            },
            // 高峰电价
            {
              type: 'bar',
              stack: 'total',
              name: '高峰电价',
              data: chartData.map((a, index) => {
                return [index, a.peakValue];
              }),
            },
            // 尖峰电价
            {
              type: 'bar',
              stack: 'total',
              name: '尖峰电价',
              data: chartData.map((a, index) => {
                return [index, a.summitValue];
              }),
            },
          ],
  };
};
