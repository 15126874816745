import { InputNumber, Space } from 'antd';
import { addNum } from '../../utils';
import { EnergyMediumTableDataItem } from '@/api/universeMeterQuery';
import { Key } from '@maxtropy/components';

interface EleShareInputProps {
  data: EnergyMediumTableDataItem[];
  currentRow: EnergyMediumTableDataItem;
  setData: (data: EnergyMediumTableDataItem[]) => void;
  shareEditItemIds: Key[];
  setShareEditItemIds: (ids: Key[]) => void;
}

const inputNumberProps = {
  style: { width: '100%' },
  min: 0,
  max: 999999,
  step: 0.01,
  precision: 2,
};

const EleShareInput: React.FC<EleShareInputProps> = ({
  currentRow,
  data,
  setData,
  shareEditItemIds,
  setShareEditItemIds,
}) => {
  const allAfterAdjust = addNum(
    ...data.filter(i => i.meterReadingObjectId === currentRow.meterReadingObjectId).map(i => i.afterAdjust)
  );

  // 编辑尖分摊值
  const onSummitShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const summitShare = value ?? 0;
      const final = addNum(allAfterAdjust, summitShare, i.peakShare, i.plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          summitShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑峰分摊值
  const onPeakShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const peakShare = value ?? 0;
      const final = addNum(allAfterAdjust, i.summitShare, peakShare, i.plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          peakShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑平分摊值
  const onPlainShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const plainShare = value ?? 0;
      const final = addNum(allAfterAdjust, i.summitShare, i.peakShare, plainShare, i.valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          plainShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  // 编辑谷分摊值
  const onValleyShareChange = (value: number | null) => {
    const newData = data.map(i => {
      const valleyShare = value ?? 0;
      const final = addNum(allAfterAdjust, i.summitShare, i.peakShare, i.plainShare, valleyShare);
      if (i.meterReadingObjectId === currentRow.meterReadingObjectId) {
        return {
          ...i,
          valleyShare,
          final,
        };
      }
      return i;
    });
    setData(newData);
  };

  return (
    <Space size={8}>
      <InputNumber
        {...inputNumberProps}
        value={currentRow.summitShare}
        addonBefore="尖"
        onChange={v => {
          onSummitShareChange(v);
          setShareEditItemIds([...shareEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.peakShare}
        addonBefore="峰"
        onChange={v => {
          onPeakShareChange(v);
          setShareEditItemIds([...shareEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.plainShare}
        addonBefore="平"
        onChange={v => {
          onPlainShareChange(v);
          setShareEditItemIds([...shareEditItemIds, currentRow.itemId]);
        }}
      />
      <InputNumber
        {...inputNumberProps}
        value={currentRow.valleyShare}
        addonBefore="谷"
        onChange={v => {
          onValleyShareChange(v);
          setShareEditItemIds([...shareEditItemIds, currentRow.itemId]);
        }}
      />
    </Space>
  );
};

export default EleShareInput;
