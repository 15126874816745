import React, { useContext, useMemo } from 'react';
import { Button, MxColumnsType } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { ActionType, ModbusPoint, DataPointType, DataProperty } from '../../../../../types';
import { PointContext, PointContextProps } from '../../../contextTypes';
import ModbusPointBase from './modbusPointBase';
import CreateForm from './CreateForm';
import Header from '../../Header';

export type EdgeDeviceTemplatePoint = ModbusPoint & { actionType?: ActionType };

interface ModbusPointProps {
  fixed?: boolean;
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
}

const ModbusPointCom: React.FC<ModbusPointProps> = props => {
  const { fixed, loading, editColumns, onUpdate, dataPropertiesAll, usedProperties, usedIdentifier } = props;

  const { row, setRow, info, promptSlot, editable } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;

  const _row = useMemo(() => {
    if (row) {
      const { parameters, ...rest } = row;
      return {
        ...rest,
        ...parameters,
      };
    } else {
      return row;
    }
  }, [row]);

  const onOk = async (values: any) => {
    const {
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      starting,
      ...rest
    } = values;

    const submitValues = {
      pointType: DataPointType.BASE_POINT,
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      parameters: {
        ...rest,
        driveType: info?.driveType,
        pointType: DataPointType.BASE_POINT,
        starting: parseInt(starting, 16),
      },
    };
    if (onUpdate) {
      await onUpdate(submitValues);
    }
  };

  return (
    <>
      <Header slot={promptSlot}>
        {editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setRow?.({
                pointType: DataPointType.BASE_POINT,
              });
            }}
          >
            添加采集点
          </Button>
        )}
      </Header>
      <ModbusPointBase
        fixed={fixed}
        editColumns={editColumns}
        loading={loading}
        dataPropertiesAll={dataPropertiesAll}
      />
      <CreateForm
        onCancel={() => setRow?.(undefined)}
        row={_row}
        dataPropertiesAll={dataPropertiesAll}
        usedProperties={usedProperties}
        usedIdentifier={usedIdentifier}
        onOk={onOk}
      />
    </>
  );
};

export default ModbusPointCom;
