import { ReportStrategyStatisticalCycleEnum } from '@/shared/types';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { ReportStrategyExecuteCycle } from '.';
import { MonthEnum, WeekEnum, YearEnum } from '../type';

interface Iprops {
  form: FormInstance<any>;
  executeCycle: ReportStrategyExecuteCycle;
  statisticalCycle: ReportStrategyStatisticalCycleEnum;
  isNeedSetDefaultData: boolean;
  isEdit: boolean;
}

export const useSetDefaultFormData = ({
  form,
  isEdit,
  executeCycle,
  statisticalCycle,
  isNeedSetDefaultData,
}: Iprops) => {
  useEffect(() => {
    if (isEdit && !isNeedSetDefaultData) return;
    // 设置默认值
    if (executeCycle === ReportStrategyExecuteCycle.DAY) {
      // 1小时
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
        form.setFieldsValue({
          statisticalTime: [
            { day: 0, hour: dayjs('00:00', 'HH:mm') },
            { day: 0, hour: dayjs('23:00', 'HH:mm') },
          ],
        });
      }
      // 15分钟
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.QUARTER) {
        form.setFieldsValue({
          statisticalTime: [
            { day: 0, hour: dayjs('00:00', 'HH:mm') },
            { day: 0, hour: dayjs('23:45', 'HH:mm') },
          ],
        });
      }
      return;
    }
    // 按月执行
    if (executeCycle === ReportStrategyExecuteCycle.MONTH) {
      // 1小时
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
        // day: 1 --> 1日 day: -1 --> 月末

        form.setFieldsValue({
          statisticalTime: [
            { month: MonthEnum.LAST, day: 1, hour: dayjs('00:00', 'HH:mm') },
            { month: MonthEnum.LAST, day: -1, hour: dayjs('23:00', 'HH:mm') },
          ],
        });
      }
      // 1日
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.DAY) {
        // day: 1 --> 1日 day: -1 --> 月末
        form.setFieldsValue({
          statisticalTime: [
            { month: MonthEnum.LAST, day: 1 },
            { month: MonthEnum.LAST, day: -1 },
          ],
        });
      }
      return;
    }
    // 按周执行
    if (executeCycle === ReportStrategyExecuteCycle.WEEK) {
      // dayOfWeek: 1 --> 周一 dayOfWeek: 7 --> 周日
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.QUARTER) {
        form.setFieldsValue({
          statisticalTime: [
            { week: WeekEnum.LAST, dayOfWeek: 1, hour: dayjs('00:00', 'HH:mm') },
            { week: WeekEnum.LAST, dayOfWeek: 7, hour: dayjs('23:45', 'HH:mm') },
          ],
        });
      }
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.HOUR) {
        form.setFieldsValue({
          statisticalTime: [
            { week: WeekEnum.LAST, dayOfWeek: 1, hour: dayjs('00', 'HH') },
            { week: WeekEnum.LAST, dayOfWeek: 7, hour: dayjs('23', 'HH') },
          ],
        });
      }
      if (statisticalCycle === ReportStrategyStatisticalCycleEnum.DAY) {
        form.setFieldsValue({
          statisticalTime: [
            { week: WeekEnum.LAST, dayOfWeek: 1 },
            { week: WeekEnum.LAST, dayOfWeek: 7 },
          ],
        });
      }
      return;
    }
    // 按年执行
    if (executeCycle === ReportStrategyExecuteCycle.YEAR) {
      // 1月/1日
      // day: 1 --> 1日 day: -1 --> 月末
      form.setFieldsValue({
        statisticalTime: [
          { year: YearEnum.LAST, month: 1, day: 1 },
          { year: YearEnum.LAST, month: 12, day: -1 },
        ],
      });
    }
  }, [statisticalCycle, executeCycle, isEdit]);
};
