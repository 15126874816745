import qs from 'qs';
import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';

// 回路类型
export enum CircuitType {
  GRID_BILLING_CIRCUIT = 1,
  OUTGOING_CIRCUIT = 2,
  INCOMING_CIRCUIT = 3,
  BUS_COUPLER_CIRCUIT = 4,
  TRANSFORMER_CIRCUIT = 5,
}
export const CircuitTypeFormat = {
  [CircuitType.GRID_BILLING_CIRCUIT]: '电网计费回路',
  [CircuitType.OUTGOING_CIRCUIT]: '出线回路',
  [CircuitType.INCOMING_CIRCUIT]: '进线回路',
  [CircuitType.BUS_COUPLER_CIRCUIT]: '母联回路',
  [CircuitType.TRANSFORMER_CIRCUIT]: '变压器回路',
};
// 回路标签类型
export enum CircuitLabelType {
  PLATFORM = 0,
  SELF = 1,
}
export const circuitLabelTypeDisplay = {
  [CircuitLabelType.PLATFORM]: '预置',
  [CircuitLabelType.SELF]: '自建',
};

// 电压等级枚举
export enum VoltageLevelType {
  ZERO_POINT_FOUR = 1, // 0.4kV
  TEN = 2, // 10kV
  TWENTY = 3, // 20kV
  THIRTY_FIVE = 4, // 35kV
  SIXTY_SIX = 5, // 66kV
  ONE_HUNDRED_TEN = 6, // 110kV
  TWO_HUNDRED_AND_TWO = 7, //220kV
  SIX,
}

export const VoltageLevelTypeFormat = {
  [VoltageLevelType.ZERO_POINT_FOUR]: '0.4kV',
  [VoltageLevelType.TEN]: '10kV',
  [VoltageLevelType.TWENTY]: '20kV',
  [VoltageLevelType.THIRTY_FIVE]: '35kV',
  [VoltageLevelType.SIXTY_SIX]: '66kV',
  [VoltageLevelType.ONE_HUNDRED_TEN]: '110kV',
  [VoltageLevelType.TWO_HUNDRED_AND_TWO]: '220kV',
  [VoltageLevelType.SIX]: '6kV',
};

export const VoltageLevelTypeFormatList = () => {
  return Object.entries(VoltageLevelTypeFormat).sort((a, b) => {
    // 防止按照枚举值排序, 按照实际大小排序
    let aLabel = a[1]?.replace('kV', '') ?? '0';
    let bLabel = b[1]?.replace('kV', '') ?? '0';
    let comp = Number(aLabel) - Number(bLabel);
    return isNaN(comp) ? 0 : comp;
  });
};

// 母联状态枚举
export enum BusTieStatusType {
  DISCONNECT = 0, // 断开
  LEFT_CONNECT = 1, // 从左上游回路得电
  RIGHT_CONNECT = 2, // 从右上游回路得电
}

export const BusTieStatusTypeFormat = {
  [BusTieStatusType.DISCONNECT]: '断开',
  [BusTieStatusType.LEFT_CONNECT]: '从左上游回路得电',
  [BusTieStatusType.RIGHT_CONNECT]: '从右上游回路得电',
};

export interface CircuitProps {
  id: number;
  name: string; // 回路名称
  ouId: number; // 运营单元ID
  ouName?: string; // 生成单元名称
  type: CircuitType; // 回路类型
  code?: string; // 编号
  capacity?: number; // 装机容量
  distributionCabinetId?: number; //配电柜id
  distributionCabinetName?: string; //配电柜名称
  voltageLevel?: VoltageLevelType; // 电压等级枚举
  voltageLevelIn?: VoltageLevelType; // 进线电压等级枚举
  voltageLevelOut?: VoltageLevelType; // 出线电压等级枚举
  targetLoad?: number; //目标负载
  targetLoadRate?: number; //目标负载率
  electricityAccountId?: number; //用电账户id
  electricityAccountNumber?: number; //用电账户户号
  upstreamCircuitId?: number; //上游回路
  upstreamCircuitRootId?: number; //根回路
  upstreamCircuitLeftId?: number; //左上游回路
  upstreamCircuitRightId?: number; //右上游回路
  updateByStaffId?: number; //操作人staffId
  updateByStaffName?: string; //操作人名称
  remark?: string;
  uetId?: number; //uet id
  uetName?: string; //uet名称
  busTieStatus?: BusTieStatusType; //母联合闸状态枚举
  linkType?: CircuitRelationType; //关联类型
  linkObjectId?: number; //关联对象的id
  linkObjectName?: string; //关联对象名称
  createTime?: string;
  updateTime?: string;
  labelName?: string[];
  labelId?: number[];
  gaugeHost?: { name: string };
  circuitId?: number[];
  circuitName?: string[];
}

export interface CircuitListQuery {
  ouId?: number; //运营单元id
  type?: CircuitType; //回路类型
  name?: string; //回路名称
  uetId?: number; // uetId
}

// 获取回路列表
export const getCircuitList = (query?: CircuitListQuery) =>
  fetch<CircuitProps[]>(`/api/circuit/list?${qs.stringify(query, { indices: false })}`);

export interface CircuitListByOuQuery {
  ouId?: number; //运营单元id
  circuitId?: number;
}
export interface CircuitListByOuResponse {
  id?: number;
  name?: string;
  uetName?: string;
  uetId?: number;
  electricityAccountId?: number; // 用电账户id
  electricityAccountNumber?: string; // 用电账户户号
}
// 获取回路列表
export const getCircuitListByOu = (query?: CircuitListByOuQuery) =>
  fetch<CircuitListByOuResponse[]>(`/api/circuit/inputList?${qs.stringify(query, { indices: false })}`);

// 获取回路分页查询
export interface CircuitListPageQuery extends PageRequest {
  codeOrName?: string; // 回路名称或者编号
  ouId?: number; //运营单元id
  type?: CircuitType; //回路类型
  name?: string; // 回路名称
  distributionCabinetId?: number; // 配电柜id
  labelId?: number[];
}

export interface CircuitExportListPageQuery extends PageRequest {
  codeOrName?: string; // 回路名称或者编号
  ouIds?: number[]; //运营单元id
  type?: CircuitType; //回路类型
  name?: string; // 回路名称
  distributionCabinetId?: number; // 配电柜id
  labelId?: number[];
}

// 获取回路分页查询
export const getCircuitListPage = (query: CircuitListPageQuery) =>
  fetch<PageResponse<CircuitProps>>(`/api/circuit/page?${qs.stringify(query, { indices: false })}`);

// 获取配电柜
export const getDistributionCabinetList = () =>
  fetch<{ list: { id: number; name: string }[] }>(
    `/api/v2/distribution-cabinet/list`,
    {
      method: 'POST',
    },
    true
  );

// 回路绑定设备
export interface DeviceProps {
  deviceId?: number; //设备id
  deviceType?: CircuitDeviceLinkType; //设备类型
  host?: boolean; //是否为总计量表
  deviceName?: string; //设备名称
  deviceCode?: string; //设备code
  deviceTypeName?: string; //设备类目名
  deviceTags?: string[]; //标签
}

export enum CircuitDeviceLinkType {
  GAUGE = 1,
  TRANSFORMER = 2,
  LOAD = 3,
}

export const CircuitDeviceLinkFormat = {
  [CircuitDeviceLinkType.GAUGE]: '计量表',
  [CircuitDeviceLinkType.TRANSFORMER]: '变压器',
  [CircuitDeviceLinkType.LOAD]: '生产负载表',
};

// 获取回路二级类目
export interface deviceTypesProps {
  id?: number;
  name?: string;
  allowAttribute?: boolean;
  children?: deviceTypesProps[];
  sceneNames?: string[];
  degree?: number;
  parentName?: string;
  parentId?: number;
  rootId?: number;
  key?: string;
}

// 回路绑定设备中的二级类目接口
export const getDeviceTypeTreeWithoutScene = () =>
  fetch<{ deviceTypes: deviceTypesProps[] }>(
    `/api/v2/deviceType/treeWithoutScene`,
    {
      method: 'POST',
    },
    true
  );

export enum BusinessUnitTypeType {
  PROD_LOAD = 0, // 生产/负载线
  BSA = 1, // 储能阵列
  PV_STATION = 2, // 光伏站
  LINE_PIPE = 3, // 管线
  MICROGRID_POWER_TOPOLOGY = 4, // 微网电能拓扑
}

export enum UETstatusType {
  DISABLE = 0, // 停用
  ENABLE = 1, // 启用
  INVALID = 2, // 作废
  DRAFT = 3, // 草稿
}

export const UETstatusTypeFormat = {
  [UETstatusType.DISABLE]: '停用',
  [UETstatusType.ENABLE]: '启用',
  [UETstatusType.INVALID]: '作废',
  [UETstatusType.DRAFT]: '草稿',
};

export interface UETListResponse {
  id: number;
  name?: string; // 名称
  serialNumber?: string; // 编号
  mcid?: string; // 所属组织
  customerName?: string; //组织简称
  customerFullName?: string; // 组织全称
  customerCode?: string; // 组织编号
  tenantMcid?: string; // 所属租户
  status?: number; // 状态(0-停用,1-启用,2-作废,3-草稿)
  createMuid?: string; // 创建者muid
  createCustomerUserName?: string;
  updateMuid?: string; // 修改者muid
  updateCustomerUserName?: string;
  createTime?: string;
  updateTime?: string;
  baseComplete?: boolean; // 是否填写完基础设定
  businessUnits?: BusinessUnitsType[]; // 包含的业务单元类型
  ous?: UETListousType[];
}

export interface UETListousType {
  id: number;
  name?: string; //名称
  serialNumber?: string; // 序列号
  ouTypeId?: number; //类型id
  status?: number; //0,禁用;1,启用;2,作废(原本作废用的是0,修改为2,并且OU状态值目前只有启用和作废)
  tenantMcid?: string; //租户mcid
  mcid?: string; //组织mcid
  directorName?: string; //负责人姓名
  directorPhone?: string; // 负责人电话
  remark?: string; //备注
  createTime?: string; //创建时间
  updateTime?: string; // 更新时间
  updateByUsername?: string; //操作人
  updateByUserId?: string; //操作人id
  updateSource?: number; //操作端
  manageUpdateByUsername?: string; //操作人(实施)
  manageUpdateByUserId?: string; //操作人id(实施)
  homePage?: string; //首页地址
  manageUpdateTime?: string; //更新时间(实施)
}

export interface BusinessUnitsType {
  id: number;
  uetId?: number;
  businessUnitType?: number; //业务单元类型id
  complete?: boolean; // 是否完成填写
  createTime?: string;
  updateTime?: string;
}

// 查询UET列表
export const getUETList = (query?: { businessUnitType: BusinessUnitTypeType }) =>
  fetch<UETListResponse[]>(`/api/uet/listByBusinessUnitType?${qs.stringify(query, { indices: false })}`);

// DC新建回路-回路关联
export enum CircuitRelationType {
  // ENERGY_STORAGE_LOAD = 1, // 储能负载
  PV_NETWORK_CONNECTION = 2, // 光伏并网点
}
export const CircuitRelationTypeFormat = {
  // [CircuitRelationType.ENERGY_STORAGE_LOAD]: '储能负载',
  [CircuitRelationType.PV_NETWORK_CONNECTION]: '光伏并网点',
};

export interface CircuitDetailProps extends CircuitProps {
  upstreamCircuitName?: string; //上游回路名称
  upstreamCircuitOuId?: number; //上游回路ou id
  upstreamCircuitOuName?: string; // 上游回路ou名称
  upstreamCircuitLeftName?: string; //左上游回路名称
  upstreamCircuitLeftOuId?: number; //左上游回路ou id
  upstreamCircuitLeftOuName?: string; //左上游回路ou名称
  upstreamCircuitRightName?: string; //右上游回路名称
  upstreamCircuitRightOuId?: number; //右上游回路ou id
  upstreamCircuitRightOuName?: string; //右上游回路ou名称
  devices?: DeviceProps[];
}

// 获取回路详情
export const getCircuitDetail = (id: string) => {
  return fetch<CircuitDetailProps>(
    `/api/v2/circuit/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
};

export interface BatchImportPageReq extends PageRequest {}

// 分页查询回路节能配置导入记录 返回数据
export interface BatchImportPageResponse {
  staffName?: string; // 导入人名称
  staffId?: number; // staff id
  dataAll?: string; // 导入excel在文件系统的key
  dataSuccess?: string; //导入excel成功数据生成的文件文件系统的key
  dataFail?: string; //导入excel失败数据生成的文件文件系统的key
  numAll?: number; //导入excel的行数
  numSuccess?: number; //导入excel成功的行数
  numFail?: number; //导入excel失败的行数
  createTime?: string;
  updateTime?: string;
  endTime?: string; //导入完全结束的时间
}
export interface MonitoringImportRecordRes extends BatchImportPageResponse {
  dataSuccess: string;
  dataFail: string;
}

// 批量操作日志分页
export function getCircuitMonitoringImportRecord(params: BatchImportPageReq) {
  return fetch<PageResponse<MonitoringImportRecordRes>>(
    `/api/circuit/importRecord/page?${qs.stringify(params, { indices: false })}`
  );
}

// 删除回路
export function deleteCircuitRecord(id: number): Promise<boolean> {
  return fetch(`/api/circuit/${id}`, {
    method: 'DELETE',
  });
}

// 批量创建回路
export function circuitBatchImport(params: { fileKey: string }): Promise<void> {
  return fetch(`/api/circuit/import`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export interface CircuitTreeUetProps extends CircuitProps {
  children?: CircuitTreeUetProps[];
}

// 根据回路id获取该回路的回路树，包括自己
export const getCircuitTreeById = (circuitId: string) => {
  return fetch<CircuitTreeUetProps[]>(`/api/circuit/${circuitId}/tree`);
};

// 回路关联类型枚举。 2：光伏站/并网点
// export enum CircuitLinkType {
//   PV_NETWORK_CONNECTION = 2, // 光伏站/并网点
// }

export interface CircuitLinkObj {
  id: number; //id
  name?: string; //名称
  children?: CircuitLinkObj[]; //子列表
}

// 专门用于回路新建和修改时，查询关联对象的列表
export const getCircuitLink = (params: { circuitLinkType: CircuitRelationType; circuitId?: number }) => {
  return fetch<CircuitLinkObj[]>(`/api/circuit/link?${qs.stringify(params, { indices: false })}`);
};

export interface CreateCircuitProps extends Omit<CircuitProps, 'id' | 'ouName' | 'distributionCabinetId'> {
  devices?: DeviceProps[];
}

// 创建回路
export function createCircuit(params: CreateCircuitProps): Promise<void> {
  return fetch(`/api/circuit`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 编辑回路
export function updateCircuit(id: string, params: CreateCircuitProps): Promise<void> {
  return fetch(`/api/circuit/${id}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

// 导出回路列表
export function exportCircuitList(params?: Omit<CircuitExportListPageQuery, 'page' | 'size'>): string {
  return `/api/circuit/export?${qs.stringify(params, { indices: false })}`;
}

// 根据多ou获取回路列表
export function getCircuitListByOus(ouIds: number[]) {
  return fetch<CircuitProps[]>(`/api/circuit/list_by_ou?${qs.stringify({ ouIds }, { indices: false })}`);
}
