import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { Divider, Space } from 'antd';
import { Table, Button, Modal, Paging, usePaging, useUpdate } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { apiV2GatewayPageEdgeDevicePost } from '@maxtropy/device-customer-apis-v2';
import { DeviceList } from '../type';
import { deviceColumns } from '../Detail';
import { updateGatewayDevice } from '../../../api/gateway';

interface TableDataType extends Omit<DeviceList, 'attributeList'> {}

export interface DeviceListRef {
  updateFn?: () => void;
}
interface DeviceTableProps {
  devices: DeviceList[];
  setDevices: (devicesList: DeviceList[]) => void;
  iotProtocol?: IotProtocolType;
  id?: string;
  bindDeviceIds: number[];
  getDeviceIds: () => void;
}

const DeviceTable = React.forwardRef((props: DeviceTableProps, ref: Ref<DeviceListRef>) => {
  const { devices, setDevices, iotProtocol, id, bindDeviceIds, getDeviceIds } = props;
  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, updateFn] = useUpdate();

  const getDeviceList = (page?: number) => {
    if (id) {
      apiV2GatewayPageEdgeDevicePost({
        gatewayId: Number(id),
        page: page ?? pageOffset,
        size: pageSize,
      }).then(res => {
        setDevices(res?.list ?? []);
        setTotalCount(res?.total ?? 0);
      });
    }
  };

  useEffect(() => {
    getDeviceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, update]);

  useEffect(() => {
    setPageOffset(1);
  }, [update]);

  useImperativeHandle(ref, () => ({
    updateFn,
  }));

  const reorderItems = (from: number, to: number) => {
    const items = devices.slice();
    items.splice(to, 0, items.splice(from, 1)[0]);
    setDevices(items);
  };

  const updateDevice = (deviceId: number) => {
    const deviceIds = bindDeviceIds.filter(o => o !== deviceId);
    updateGatewayDevice({ deviceIds, edgeGatewayId: Number(id), protocol: iotProtocol! }).then(res => {
      updateFn();
      getDeviceIds();
    });
  };

  const mergedColumns = [
    {
      title: '网关中的设备序号',
      dataIndex: 'sequence',
      ellipsis: { showTitle: true },
      render: (_: undefined, record: DeviceList, index: number) => index + 1,
    },
    ...deviceColumns(true),
    {
      title: '操作',
      dataIndex: 'operation',
      width: 300,
      render: (value: undefined, record: DeviceList) => {
        // const index = devices.findIndex(item => item.id === record.id);
        return (
          <Space split={<Divider type="vertical" />}>
            {/* {iotProtocol === IotProtocolType.ESTUN && (
              <>
                <Button type="link" disabled={index === 0} onClick={() => reorderItems(index, index - 1)}>
                  上移
                </Button>
                <Button
                  type="link"
                  disabled={index === devices.length - 1}
                  onClick={() => reorderItems(index, index + 1)}
                >
                  下移
                </Button>
              </>
            )} */}

            <Button
              type="link"
              // disabled={!record.enable}
              onClick={() => {
                Modal.confirm({
                  title: '确定取消绑定？',
                  content: (
                    <div>
                      <span style={{ color: '#f00' }}>取消绑定后不可恢复</span>，确定取消绑定吗?
                    </div>
                  ),
                  okText: '确定',
                  onOk: () => updateDevice(record.id!),
                });
              }}
            >
              取消绑定
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table<TableDataType>
        rowKey="id"
        dataSource={devices}
        columns={
          iotProtocol === IotProtocolType.MOCKINGBIRD ? mergedColumns.slice(1, mergedColumns.length) : mergedColumns
        }
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
});

export default DeviceTable;
