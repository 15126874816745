import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  EdgeDeviceInfo,
  queryEdgeDeviceInfo,
  queryEdgeDeviceRealTimeData,
  queryEdgeDeviceRealTimeDataForMockingbird,
} from '../../../../api/edgeDevice';
import { IotProtocolType } from '@/shared/types';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { chunk, flatten } from 'lodash-es';
import { getGatewayBaseInfoById } from '@/api/gateway';
import { Subscription } from 'rxjs';
import { getSingleRxStompClient } from '@maxtropy/components';
import dayjs from 'dayjs';
import EdgeDetail from './EdgeDetail';
interface EdgeTemplateDetailProps {
  id: string;
  physicalModelId?: React.Key;
}
interface ServerEventPointValue {
  deviceId: number;
  propertyId: number;
  key: string;
  value: number;
  time: number;
}
const EdgeTemplateDetail: FC<EdgeTemplateDetailProps> = ({ id, physicalModelId }) => {
  const client = useRef(getSingleRxStompClient());
  const [data, setData] = useState<EdgeDeviceInfo>();
  const [pointRealTimeMap, setPointRealTimeMap] = useState<Record<string, ServerEventPointValue>>({});
  const [edgeGatewaySerialNumber, setEdgeGatewaySerialNumber] = useState<string | undefined>(undefined);
  // websocket订阅后, 上来的数据时间间隔过长, 进入页面时先请求一次
  useEffect(() => {
    if (data && id) {
      const pointIds = (data.points ?? []).map(({ id }) => String(id));
      if (pointIds.length) {
        const query =
          data.iotProtocol === IotProtocolType.MOCKINGBIRD
            ? queryEdgeDeviceRealTimeDataForMockingbird
            : queryEdgeDeviceRealTimeData;
        const pointIdChunks = chunk(pointIds, 10);
        Promise.all(pointIdChunks.map(chunk => query({ pointIds: chunk })))
          .then(data => {
            let temp = data ? flatten(data) : [];
            temp.forEach(item => {
              pointRealTimeMap[item.pointId] = {
                ...item,
                time: item.ts,
                value: item.data,
              } as unknown as ServerEventPointValue;
            });
            setPointRealTimeMap({ ...pointRealTimeMap });
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);
  useEffect(() => {
    const subscriptions: Subscription[] = [];
    [id].forEach(id => {
      const subscription = client.current.watch(`/ws/device/value/${id}-${dayjs().unix()}`).subscribe(value => {
        const pointValue = JSON.parse(value.body) as ServerEventPointValue;
        // pointRealTimeMap?.set(pointValue.key, pointValue);
        pointRealTimeMap[pointValue.key] = pointValue;
        setPointRealTimeMap({ ...pointRealTimeMap });
      });
      subscriptions.push(subscription);
    });

    return () => subscriptions.forEach(subscription => subscription.unsubscribe());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isLoading = useMemo(() => {
    return Object.entries(pointRealTimeMap).length === 0;
  }, [pointRealTimeMap]);

  const dataSource = useMemo<EdgeDeviceTemplatePoint[]>(() => {
    let result: EdgeDeviceTemplatePoint[] = [];
    if (data?.points) {
      result = data?.points ?? [];
    }
    if (pointRealTimeMap) {
      result = result.map(item => {
        let edgeTime = pointRealTimeMap?.[String(item.edgeDevicePointId)]?.time;
        let edgeData = pointRealTimeMap?.[String(item.edgeDevicePointId!)]?.value;
        return {
          ...item,
          edgeTime,
          edgeData,
        };
      });
    }
    return result;
  }, [data?.points, pointRealTimeMap]);

  useEffect(() => {
    queryEdgeDeviceInfo(id).then(setData);
  }, [id]);

  useEffect(() => {
    // 拿到设备关联网关
    if (data?.edgeGatewayId) {
      getGatewayBaseInfoById(data?.edgeGatewayId).then(res => {
        setEdgeGatewaySerialNumber(res?.serialNumber);
      });
    }
  }, [data?.edgeGatewayId]);

  return (
    <>
      {data && data.edgeDeviceTemplateName && (
        <>
          <EdgeDetail
            data={data}
            loading={isLoading}
            dataSource={dataSource}
            hasDataColumns
            edgeGatewaySerialNumber={edgeGatewaySerialNumber}
            physicalModelId={physicalModelId}
          />
        </>
      )}
    </>
  );
};

export default EdgeTemplateDetail;
