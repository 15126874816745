import styles from './index.module.scss';
import { EllipsisSpan, Table } from '@maxtropy/components';

import type { ColumnsType } from 'antd/es/table';

import { CalculateDataType, Procedure, FirstDataType } from '../AddFilling/const';
import { formatChildrenTreeData } from '../AddFilling';
import { isNil } from 'lodash-es';
interface CalculateProps {
  firstStepData?: FirstDataType;
  calculateTableData: CalculateDataType[];
  calculateData?: CalculateDataType;
}

const Calculate: React.FC<CalculateProps> = ({ firstStepData, calculateTableData, calculateData }) => {
  const columnsAccumulated: ColumnsType<CalculateDataType> = [
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '碳足迹(kgCO2)',
      dataIndex: 'unitCarbonFootPrint',
      ellipsis: true,
      render(value, record, index) {
        return <EllipsisSpan value={`${!isNil(value) ? Number(value).toFixed(2) : '--'}`}></EllipsisSpan>;
      },
    },
    {
      title: '比例',
      dataIndex: 'percent',
      ellipsis: true,
      render(value, record, index) {
        return (
          <>
            <EllipsisSpan
              value={`${!isNil(value) ? (value * 100).toFixed(2) : '--'}${!isNil(value) ? '%' : ''}`}
            ></EllipsisSpan>
          </>
        );
      },
    },
  ];
  const columnsActivity: ColumnsType<Procedure> = [
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: '实际用量',
      dataIndex: 'actualQuantity',
      ellipsis: true,
      render(value, record, index) {
        if (!record.isProcedure) {
          return <EllipsisSpan value={`${Number(value).toFixed(2) ?? '--'}${record.unitCode ?? '--'}`} />;
        } else {
          return (
            <EllipsisSpan
              value={`${Number(record.theoreticalQuantity).toFixed(2) ?? '--'}${record.unitCode ?? '--'}`}
            />
          );
        }
      },
    },
    {
      title: '碳足迹',
      dataIndex: 'carbonFootPrint',
      ellipsis: true,
      render(value, record, index) {
        return (
          <>
            <EllipsisSpan
              value={`${!isNil(value) ? Number(value).toFixed(2) : '--'}${!isNil(value) ? ' kgCO2' : ''}`}
            ></EllipsisSpan>
          </>
        );
      },
    },
  ];
  return (
    <div className={styles.content}>
      <div className={styles.Title}>累计贡献分析</div>
      <Table
        rowKey={record => record.id}
        columns={columnsAccumulated}
        dataSource={calculateTableData}
        pagination={false}
        indentSize={16}
        defaultExpandAllRows={true}
      />
      <div className={styles.activityTitle}>活动数据清单</div>
      <div className={styles.activityDescription}>
        本次填报的生产单号为： <span className={styles.blueSpan}>{firstStepData?.productSheetCode} </span>，生产
        <span className={styles.blueSpan}>
          {calculateTableData[0]?.name ?? '--'} {firstStepData?.quantity}
          {firstStepData?.unitCode}
        </span>
        ，消耗的详细物料/能源清单如下：
      </div>
      <Table
        style={{ marginBottom: 32 }}
        rowKey={record => record.id}
        columns={columnsActivity}
        dataSource={formatChildrenTreeData(calculateData?.children!)}
        pagination={false}
        indentSize={16}
        defaultExpandAllRows={true}
      />
    </div>
  );
};

export default Calculate;
