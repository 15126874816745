import React, { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Modal, Select } from '@maxtropy/components';
import SetUpModal from '@/pages/FillingRules/CreateAndEdit/Modal/SetUpModal';
import { ValueType, ValueTypeDisplay } from '@/pages/FillingRules/CreateAndEdit/NumberInput/types';
import { useUpdateEffect } from 'ahooks';
import styles from './index.module.scss';
import { FieldProperty } from '@/pages/FillingRules/CreateAndEdit/types';

type DataAcquisitionSettingTextType = '数采设置' | '编辑数采设置';

interface Props {
  value?: FieldProperty;
  onChange?: (value: FieldProperty) => void;
  name?: string;
}
const NumberFormItem: React.FC<Props> = ({ value, onChange, name }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fieldForm] = Form.useForm();

  const [autoDataSettingForm] = Form.useForm();
  // modal中的数采设置
  const [dataAcquisitionSettingText, setDataAcquisitionSettingText] =
    useState<DataAcquisitionSettingTextType>('数采设置');

  const [collectedParameters, setCollectedParameters] = useState<FieldProperty>();

  const onModalOK = async () => {
    const params = await autoDataSettingForm.validateFields();
    delete params.dataAcquisitionField;

    const updatedCollectedParameters = {
      ...collectedParameters,
      autoDataSetting: params,
    };

    setCollectedParameters(updatedCollectedParameters);

    onChange?.(updatedCollectedParameters);

    setDataAcquisitionSettingText('编辑数采设置');
    setModalOpen(false);
  };

  useEffect(() => {
    if (!value) return;

    const { valueType, formFieldValue, autoDataSetting } = value;
    fieldForm.setFieldsValue({
      valueType,
      formFieldValue,
    });

    autoDataSetting && setDataAcquisitionSettingText('编辑数采设置');

    setCollectedParameters(value);
  }, [autoDataSettingForm, fieldForm, value]);

  useUpdateEffect(() => {
    modalOpen && autoDataSettingForm.resetFields();
  }, [modalOpen]);

  return (
    <>
      <div className={styles.wrapper}>
        <Form form={fieldForm}>
          <Form.Item name="valueType" rules={[{ required: true, message: '请选择数据类型' }]} noStyle>
            <Select
              className={styles.select}
              options={Object.entries(ValueTypeDisplay).map(([k, v]) => ({
                label: v,
                value: +k,
              }))}
              onChange={value => {
                const params = {
                  valueType: value,
                };
                setCollectedParameters(params);
                setDataAcquisitionSettingText('数采设置');
                onChange?.(params);
              }}
              allowClear
            />
          </Form.Item>
          {collectedParameters?.valueType === ValueType.fixed && (
            <>
              <Form.Item noStyle name="formFieldValue">
                <InputNumber
                  className={styles.itemInput}
                  placeholder="请输入数值"
                  onChange={value => {
                    if (!value) return;
                    const params = {
                      ...collectedParameters,
                      formFieldValue: `${value}`,
                    };
                    setCollectedParameters(params);
                    onChange?.(params);
                  }}
                />
              </Form.Item>
            </>
          )}
          {collectedParameters?.valueType === ValueType.auto && (
            <>
              <Form.Item noStyle name="autoDataSetting">
                <Button
                  style={{ width: '100%' }}
                  wrapClassName={styles.item}
                  type="dashed"
                  onClick={() => setModalOpen(true)}
                >
                  {dataAcquisitionSettingText}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>

        <Modal
          contentClassName="modal-form-content"
          title="自动数采设置"
          open={modalOpen}
          onOk={onModalOK}
          onCancel={() => setModalOpen(false)}
          destroyOnClose
        >
          <SetUpModal name={name} value={value} form={autoDataSettingForm} />
        </Modal>
      </div>
    </>
  );
};

export default NumberFormItem;
