import { WarningOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import {
  V2LeanEnergyFlowAnalysisBannerPostResponse,
  V2LeanEnergyFlowAnalysisDetailDataPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export const formateTimeMap: Record<string, string> = {
  D: 'YYYY[年]MM[月]DD[日]',
  M: 'YYYY[年]MM[月]',
  Y: 'YYYY[年]',
};
export const transformUnit = (value: number, baseUnit = 'kg') => {
  const unitMap: Record<string, string> = {
    kg: 't',
    kWh: 'MWh',
    KWh: 'MWh',
    'm³': '万m³',
    KJ: 'MJ',
    MJ: 'GJ',
    J: 'KJ',
    kgce: 'tce',
    t: '万t',
  };
  if (!value && typeof value !== 'number') return '-';
  if (value < 1000) return `${value.toFixed(2)}${baseUnit ?? ''}`;
  if (baseUnit === 'm³' || baseUnit === 't') {
    return `${(value / 10000).toFixed(2)}${unitMap[baseUnit]}`;
  }
  return `${(value / 1000).toFixed(2)}${unitMap[baseUnit]}`;
};
// 总用能单位转换
export const totalUseTransformUnit = (totalUse?: number) => {
  if (!totalUse && typeof totalUse !== 'number') {
    return '-';
  }
  if (totalUse >= 10000) {
    return `${(totalUse / 1000).toFixed(2)}tce`;
  }
  return `${totalUse.toFixed(2)}kgce`;
};
interface Iprops {
  currentDateType: string;
  currentDate: string;
  detailData?: V2LeanEnergyFlowAnalysisBannerPostResponse;
  detailDataOutSide?: V2LeanEnergyFlowAnalysisDetailDataPostResponse;
  maxEnergy: any;
  message: string;
}
const BannerInfo = ({ currentDate, currentDateType, detailData, message, detailDataOutSide, maxEnergy }: Iprops) => {
  console.log(message);
  return (
    <div className={styles.banner_box}>
      <div>
        {dayjs(currentDate).format(formateTimeMap[currentDateType])}
        {message}总用能为
        <span className={styles.text}>{totalUseTransformUnit(detailDataOutSide?.totalConsumptionValue)} </span>
        {detailDataOutSide?.mediumData && detailDataOutSide?.mediumData.length > 0 ? <span> ， 其中</span> : ''}
        {detailDataOutSide?.mediumData?.map(item => {
          return (
            <span key={item.energyMediumId}>
              {item.energyMediumName}用量
              <span className={styles.text}>{transformUnit(item.consumptionValue!, item.generalName)}</span>，
            </span>
          );
        })}
        <span className={styles.text2}>[{maxEnergy?.workCenterName ?? '-'}]</span>用能最高，占比
        <span className={styles.text}>{maxEnergy?.ratio ?? '-'}%。</span>
      </div>
      {detailData?.wrongWorkProcedureName && detailData?.wrongWorkProcedureName.length > 0 && (
        <div>
          <span className={styles.text3}>
            <WarningOutlined />
          </span>
          可能存在问题的工序:
          <span className={styles.text3}>
            [
            {detailData?.wrongWorkProcedureName.map((i, index) =>
              index + 1 < (detailData?.wrongWorkProcedureName?.length ?? 0) ? i.concat('，') : i
            ) ?? '-'}
            ]
          </span>
        </div>
      )}
    </div>
  );
};

export default BannerInfo;
