import React, { useCallback, useEffect, useReducer, useRef, useState, useMemo } from 'react';
import { Space } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'qs';
import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Wrapper,
  Button,
  message,
  Tooltip,
  Checkbox,
  Input,
  Modal,
  Form,
  Select,
  PopConfirm,
  useAsync,
  CustomFilter,
} from '@maxtropy/components';

import {
  IotProtocolType,
  GatewayNetStatus,
  GatewayNetStatusDisplay,
  GatewayStatus,
  OperatorDisplay,
  Operator,
} from '@/shared/types';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission, useQuery } from '../../utils/utils';
import { changeGatewayStatus, Gateway, GatewayRequest, getGatewayList, applyGateway } from '../../api/gateway';
import { getProtocol } from '../../api/protocol';
import ApplyLogModal from './components/ApplyLogModal';
import Operation from './components/Operation';
import ExportInfoModal from './components/ExportInfoModal';
import { DistributeStatus, NetworkingType, NetworkingTypeDisPlay } from '@/api/netWorking';
import styles from './index.module.scss';

const { Option } = Select;
const networkingTypeOptions = [
  { label: NetworkingTypeDisPlay[NetworkingType.STATIC_IP], value: NetworkingType.STATIC_IP.toString() },
];

interface TableDataType extends Gateway {}

export type SearchParams = Omit<GatewayRequest, 'page' | 'size'>;

// const routes = [
//   { name: "Iot配置" },
//   { name: "物联配置" },
//   { name: "网关管理" },
// ];

const GatewayList: React.FC = () => {
  const [form] = Form.useForm();
  const hasPermission = useHasPermission(PermissionsType.BEDGEGATEWAYMANAGEDISABLE);
  const canDeliveryConfig = useHasPermission(PermissionsType.B_GATEWAYDELIVERYCONFIG);
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const search = useMemo(() => {
    return {
      ...query,
      ifCancel: query.ifCancel === 'true',
      ifRed: query.ifRed === 'true',
    };
  }, [query]);

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<Gateway>();
  const [searchParams, setSearchParams] = useState<SearchParams>(search);
  const [x, forceUpdate] = useReducer(x => x + 1, 1);
  const [exportVisible, setExportVisible] = useState(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    form.setFieldsValue(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const iotProtocolData = useAsync(getProtocol);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getGatewayList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, x]
    )
  );

  const onFinish = (val: SearchParams) => {
    const params: SearchParams = val;
    setPageOffset(1);
    // setSearchParams(params);
    // const paramsObj = {
    //   name: val.name,
    //   rootMcid: val.rootMcid,
    //   iotProtocol: val.iotProtocol?.toString(),
    //   netStatus: val.netState?.toString(),
    //   check: val.check === undefined ? undefined : val.check.toString(),
    // };
    // const paramsMap = new Map<string, string>();
    // Object.entries(paramsObj).forEach(([key, value]) => {
    //   value && paramsMap.set(key, value);
    // });
    // navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
    navigate(`?${qs.stringify(params)}`);
    setSearchParams(val);
  };

  const onReset = () => {
    // const params: SearchParams = {
    //   name: undefined,
    //   iotProtocol: undefined,
    //   netState: undefined,
    //   ifCancel: undefined,
    // };
    // setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
    form.setFieldsValue({});
    setSearchParams({});
  };

  // 作废
  const changeStatus = (record: Gateway) => {
    Modal.confirm({
      title: `确定作废?`,
      content: (
        <div>
          <span style={{ color: '#f00' }}>作废后不可恢复</span>，你还要继续吗？
        </div>
      ),
      okText: '继续',
      cancelText: '取消',
      onOk: () => {
        changeGatewayStatus(record.id, GatewayStatus.DISABLE).then(() => {
          forceUpdate();
        });
        return Promise.resolve();
      },
    });
  };

  const unMountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unMountedRef.current = true;
    };
  }, []);

  // 配置下发
  const onApply = (id: number) => {
    return applyGateway(id).then(res => {
      if (unMountedRef.current) {
        return;
      }
      if (res?.ret) {
        message.error({
          content: res?.ret,
          duration: 2,
          key: id,
        });
      } else {
        message.success({
          content: '配置正在下发，请在下发记录中查看下发结果。',
          duration: 2,
          key: id,
        });
        forceUpdate();
      }
    });
  };

  const mergedColumns = [
    {
      title: '网关编号',
      dataIndex: 'serialNumber',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '网关名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '物联层协议',
      dataIndex: 'iotProtocol',
      ellipsis: { showTitle: true },
      width: 120,
      render: (v: number) => {
        const target = iotProtocolData?.list?.find(item => item.id === v);
        return <EllipsisSpan value={target?.name} />;
      },
    },
    {
      title: '所在组网',
      dataIndex: 'netName',
      ellipsis: { showTitle: true },
      width: 200,
      render: (v: string, record: Gateway) => {
        return record?.edgeNetwork?.netName ? (
          <Tooltip placement="topLeft" title={record.edgeNetwork.netName}>
            <Button
              type="link"
              onClick={() => {
                const params = { netNo: record?.edgeNetwork?.netNo };
                window.open(`/device/networking/networkingList?${qs.stringify(params)}`);
              }}
            >
              <div
                style={
                  record?.edgeNetwork?.distributeStatus === DistributeStatus.NOTSTART ? { color: '#f00' } : undefined
                }
              >
                {record.edgeNetwork.netName}
              </div>
            </Button>
          </Tooltip>
        ) : (
          '-'
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'netState',
      ellipsis: { showTitle: true },
      width: 120,
      render: (v: GatewayNetStatus, record: Gateway) => (
        <EllipsisSpan value={record.state === GatewayStatus.ENABLE ? GatewayNetStatusDisplay[v] : '作废'} />
      ),
    },
    {
      title: '所在组织简称',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      width: 150,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所在组织全称',
      dataIndex: 'customerFullName',
      ellipsis: { showTitle: true },
      width: 150,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      width: 180,
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'updateByUsername',
      ellipsis: { showTitle: true },
      width: 180,
      render: (value: string, record: Gateway) => {
        return (
          <EllipsisSpan
            value={record.updateSource === Operator.OPS ? `${OperatorDisplay[record.updateSource]}操作` : value}
          />
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 360,
      fixed: 'right' as const,
      render: (value: undefined, record: Gateway) => {
        return (
          <Space size={16}>
            {hasPermission &&
              (record?.edgeNetwork?.netName ? (
                <PopConfirm title="网关在组网中，请从组网中移出后再作废。">
                  <Button type="link" disabled={record.state === GatewayStatus.DISABLE}>
                    作废
                  </Button>
                </PopConfirm>
              ) : (
                <Button
                  type="link"
                  disabled={record.state === GatewayStatus.DISABLE}
                  onClick={() => changeStatus(record)}
                >
                  作废
                </Button>
              ))}
            <Button
              type="link"
              disabled={record.state === GatewayStatus.DISABLE}
              onClick={() => {
                navigate(`/device/config/gateway/update/${record.id}/bind`);
              }}
            >
              设备绑定
            </Button>
            <Button
              type="link"
              disabled={record.state === GatewayStatus.DISABLE}
              onClick={() => {
                navigate(`/device/config/gateway/update/${record.id}`);
              }}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => {
                navigate(`/device/config/gateway/detail/${record.id}`);
              }}
            >
              查看
            </Button>
            {record.iotProtocol === IotProtocolType.MOCKINGBIRD && canDeliveryConfig && (
              <>
                <Operation record={record} applyGateway={onApply} />
                <Button
                  type="link"
                  onClick={() => {
                    setCurrent(record);
                    setVisible(true);
                  }}
                >
                  下发记录
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter<SearchParams> form={form} onFinish={val => onFinish(val as SearchParams)} onReset={onReset}>
      <Form.Item name="serialNumber" label="网关编号">
        <Input placeholder={'请输入编号查询'} />
      </Form.Item>
      <Form.Item name="code" label="设备编号">
        <Input placeholder={'请输入编号查询'} />
      </Form.Item>
      <Form.Item name="name" label="网关名称">
        <Input placeholder={'请输入名称查询'} />
      </Form.Item>
      <Form.Item name="netState" label="状态">
        <Select placeholder="请选择">
          <Option value={GatewayNetStatus.ONLINE.toString()}>{GatewayNetStatusDisplay[GatewayNetStatus.ONLINE]}</Option>
          <Option value={GatewayNetStatus.OFFLINE.toString()}>
            {GatewayNetStatusDisplay[GatewayNetStatus.OFFLINE]}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item name="netName" label="组网名称">
        <Input placeholder={'请输入名称查询'} />
      </Form.Item>
      <Form.Item name="netType" label="组网类型">
        <Select placeholder="请选择" options={networkingTypeOptions} />
      </Form.Item>
      <Form.Item name="iotProtocol" label="物联层协议">
        <Select placeholder="请选择">
          {iotProtocolData?.list?.map(item => (
            <Option key={item.id} value={item.id.toString()}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="ifCancel" valuePropName="checked">
        <Checkbox>显示已作废网关</Checkbox>
      </Form.Item>
      <Form.Item name="ifRed" valuePropName="checked">
        <Checkbox>只显示未下发/下发失败的网关</Checkbox>
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper
      routes={breadcrumbRoutes?.routes}
      filters={filters}
      filtersStyle={{ marginTop: 10 }}
      className={styles.wrapper}
    >
      <Space size={8} style={{ marginBottom: 10 }}>
        {useHasPermission(PermissionsType.B_CREATEGATEWAY) && (
          <Link to="/device/config/gateway/create">
            <Button type="primary" icon={<PlusOutlined />}>
              新建网关
            </Button>
          </Link>
        )}
        {useHasPermission(PermissionsType.B_EXPORTGATEWAYDEVICE) && (
          <Button type="primary" onClick={() => setExportVisible(true)}>
            导出网关与设备关系
          </Button>
        )}
        <span>
          <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', paddingRight: '10px' }} />
          提示：如果组网配置发生了变化，则网关的所在组网名称会变红，需要前往该网关的组网进行配置下发。
        </span>
      </Space>
      <Table<TableDataType>
        rowKey="id"
        scroll={{ x: 1200 }}
        dataSource={data}
        loading={isLoading}
        columns={mergedColumns}
        sticky
      />
      <Paging pagingInfo={pagingInfo} />
      <ApplyLogModal
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setCurrent(undefined);
        }}
        id={current?.id}
      />
      <ExportInfoModal
        visible={exportVisible}
        iotProtocolData={iotProtocolData?.list}
        onCancel={() => setExportVisible(false)}
      />
    </Wrapper>
  );
};

export default GatewayList;
