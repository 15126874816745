import { DoubleRightOutlined, RedoOutlined } from '@ant-design/icons';
import { Table, Paging, usePaging, useUpdate, EllipsisSpan, Button, Input, Filter } from '@maxtropy/components';
import { Form, Col } from 'antd';
import { FC, Key, useEffect, useState } from 'react';
import { RuleGroupPageProps } from './types';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { BatchRuleGroupPageRequest } from '../../../../api/ruleGroup';
import { IotProtocolType } from '@/shared/types';
import { apiV2RuleGroupDevicePageRuleGroupPost } from '@maxtropy/device-customer-apis-v2';

const columns = [
  {
    title: '规则组编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则组名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则数量',
    dataIndex: 'ruleCount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface SelectRuleGroupModalChildrenProps {
  deviceId: Key;
  deviceTypeId?: number;
  iotProtocol?: number;
  value?: number;
  physicalModelId?: number;
  onChange?: (val: number) => void;
  unBind?: () => void;
}

type SearchParams = Omit<BatchRuleGroupPageRequest, 'iotProtocol' | 'size' | 'page'>;

const SelectRuleGroupModalChildren: FC<SelectRuleGroupModalChildrenProps> = ({
  deviceId,
  deviceTypeId,
  iotProtocol,
  value,
  physicalModelId,
  onChange,
  unBind,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [ruleGroups, setRuleGroups] = useState<RuleGroupPageProps[]>([]);
  const [updateState, updateFn] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (iotProtocol !== undefined) {
      setLoading(true);
      apiV2RuleGroupDevicePageRuleGroupPost({
        ...searchParams,
        deviceTypeId,
        physicalModelId,
        iotProtocol: iotProtocol as 1 | 2 | 3 | undefined,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total ?? 0);
          res.list && setRuleGroups(res.list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iotProtocol, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      nameOrCode: undefined,
    });
    setPageOffset(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      fixed: 'right' as const,
      render: (v: undefined, record: RuleGroupPageProps) => {
        return (
          <Button
            type="link"
            disabled={record.id === value}
            onClick={() => {
              record.id && onChange?.(record.id);
            }}
          >
            {record.id === value ? '已选择' : '选择'}
          </Button>
        );
      },
    },
  ];

  const filters = (
    <Filter<SearchParams>
      form={form}
      onFinish={val => {
        onFinish(val);
      }}
      onReset={onReset}
    >
      <Col span={12}>
        <Form.Item name="nameOrCode" label="编号/名称">
          <Input placeholder="请输入编号或名称查询" />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips}>
        <Button disabled={!Number.isFinite(value)} danger type="link" onClick={unBind}>
          解绑已选择的规则组
        </Button>
        <div>
          <Button icon={<RedoOutlined rotate={270} />} onClick={updateFn} type="link">
            刷新页面
          </Button>
          <Button type="link">
            <Link to={`/device/rule/list/group/create`} target="_blank">
              新建规则组
            </Link>
            <DoubleRightOutlined />
          </Button>
        </div>
      </div>
      {iotProtocol === IotProtocolType.MOCKINGBIRD && (
        <div className={styles.tip}>
          提示：新增或解绑设备的报警规则组信息，需要前往网关管理页面，进行配置下发后，才可以生效。
        </div>
      )}
      <Table loading={loading} sticky scroll={{ y: 300 }} rowKey="id" columns={buildColumns} dataSource={ruleGroups} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default SelectRuleGroupModalChildren;
