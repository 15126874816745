import styles from './index.module.scss';
import {
  Button,
  Form,
  FormTitle,
  InputNumber,
  Modal,
  Select,
  SubContent,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useUpdate,
  message,
  EllipsisSpan,
} from '@maxtropy/components';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import {
  V2AppAnalysisSubscriptionDataPropertyGetPostResponse,
  apiV2AppAnalysisSubscriptionDataPropertyGetPost,
  apiV2AppAnalysisSubscriptionThresholdConfigGetPost,
  apiV2AppAnalysisSubscriptionThresholdConfigSavePost,
} from '@maxtropy/device-customer-apis-v2';
import { AccumType, AccumTypeOptions, MeasurementType } from '../utils';
import { isNil } from 'lodash';
import { Space, Spin } from 'antd';

const routes = [{ name: '用能阈值配置' }];

export type DeviceItem = Exclude<V2AppAnalysisSubscriptionDataPropertyGetPostResponse['list'], undefined>[number];

const EnergyIntervalConfig: React.FC = () => {
  // 应用分析订阅id
  const { id: appAnalysisSubId } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await apiV2AppAnalysisSubscriptionDataPropertyGetPost({ appAnalysisSubId });
      const values = await apiV2AppAnalysisSubscriptionThresholdConfigGetPost({
        appAnalysisSubId: Number(appAnalysisSubId),
      });
      setLoading(false);
      const deviceList = res?.list ?? [];
      const list = deviceList.map((device, index) => {
        const prevConfig = (values.list ?? []).find(k => device.deviceId === k.deviceId);
        return {
          key: device.deviceId,
          rowIndex: index === 0 ? deviceList.length : 0,
          ...device,
          dataPropertyId: prevConfig?.dataPropertyId,
          upperLimit: prevConfig?.upperLimit,
          lowLimit: prevConfig?.lowLimit,
          accumulationThresholdStatType: prevConfig?.accumulationThresholdStatType,
        };
      });
      form.setFieldValue('list', list);
    })();
  }, [appAnalysisSubId, form, updateState]);

  const columns: ColumnsType<any> = [
    {
      title: '表计名称',
      dataIndex: 'deviceName',
      render: (v: string) => <EllipsisSpan value={v} />,
      ellipsis: { showTitle: true },
    },
    {
      title: '计量属性',
      render(_, record, index) {
        return (
          <Form.Item
            name={['list', index, 'dataPropertyId']}
            dependencies={[
              ['list', index, 'lowLimit'],
              ['list', index, 'upperLimit'],
            ]}
            style={{ marginBottom: 0 }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const { upperLimit, lowLimit } = getFieldValue(['list', index]);

                  if (!isNil(lowLimit) && !isNil(upperLimit) && isNil(value)) {
                    return Promise.reject(new Error('请选择计量属性'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select
              style={{ width: 208 }}
              placeholder="请选择"
              allowClear
              showSearch
              optionFilterProp="label"
              options={(record?.dataProperties ?? []).map((item: { dataPropertyName: any; dataPropertyId: any }) => ({
                label: item.dataPropertyName,
                value: item.dataPropertyId,
              }))}
              onChange={val => {
                const rowMeasurement = (record?.dataProperties ?? []).find(
                  (k: { dataPropertyId: any }) => k.dataPropertyId === val
                );
                const defaultVal =
                  rowMeasurement?.measurementType === MeasurementType.ACCUM ? AccumType.HOUR : undefined;
                form.setFieldValue(['list', index, 'accumulationThresholdStatType'], defaultVal);
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <div>
          <span>停机用能上限</span>
          <span style={{ marginLeft: 100 }}>待机用能上限</span>
        </div>
      ),
      width: 600,
      render(_, record, index) {
        return (
          <Form.Item
            name={['list', index, 'range']}
            dependencies={[
              ['list', index, 'lowLimit'],
              ['list', index, 'upperLimit'],
              ['list', index, 'dataPropertyId'],
            ]}
            style={{ marginBottom: 0 }}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const { upperLimit, lowLimit, dataPropertyId } = getFieldValue(['list', index]);

                  if (isNil(lowLimit) && (!isNil(upperLimit) || !isNil(dataPropertyId))) {
                    return Promise.reject(new Error('请填写停机用能上限'));
                  }
                  if (isNil(upperLimit) && (!isNil(lowLimit) || !isNil(dataPropertyId))) {
                    return Promise.reject(new Error('请填写待机用能上限'));
                  }
                  if (!isNil(lowLimit) && !isNil(upperLimit) && lowLimit > upperLimit) {
                    return Promise.reject(new Error('停机用能上限需要小于待机用能上限'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <div className={styles.intervalCell}>
              <Form.Item name={['list', index, 'lowLimit']} style={{ marginBottom: 0 }}>
                <InputNumber
                  style={{ width: 140 }}
                  min={0}
                  onChange={() => {
                    form.validateFields([['list', index, 'dataPropertyId']]);
                  }}
                  max={999999999.9999}
                  precision={4}
                  placeholder="请输入"
                />
              </Form.Item>
              <span>～</span>
              <Form.Item name={['list', index, 'upperLimit']} style={{ marginBottom: 0 }}>
                <InputNumber
                  style={{ width: 140 }}
                  min={0}
                  onChange={() => {
                    form.validateFields([['list', index, 'dataPropertyId']]);
                  }}
                  max={999999999.9999}
                  precision={4}
                  placeholder="请输入"
                />
              </Form.Item>

              <Form.Item dependencies={['dataPropertyId']} noStyle>
                {({ getFieldValue }) => {
                  const rowItem = getFieldValue(['list', index]);
                  const rowDataPropertyId = getFieldValue(['list', index, 'dataPropertyId']);
                  const rowMeasurement = (rowItem?.dataProperties ?? []).find(
                    (k: { dataPropertyId: any }) => k.dataPropertyId === rowDataPropertyId
                  );

                  return rowMeasurement?.measurementType === MeasurementType.ACCUM ? (
                    <>
                      <span>{rowMeasurement?.physicalUnitName ?? ''}/</span>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        initialValue={AccumType.HOUR}
                        name={['list', index, 'accumulationThresholdStatType']}
                      >
                        <Select style={{ width: 100 }} placeholder="请选择" options={AccumTypeOptions} />
                      </Form.Item>
                    </>
                  ) : (
                    <span>{rowMeasurement?.physicalUnitName ?? ''}</span>
                  );
                }}
              </Form.Item>
            </div>
          </Form.Item>
        );
      },
    },
  ];

  // 保存
  const onFinish = (values: any) => {
    const configs = values.list
      .map((k: any) => {
        const itemProperty = k?.dataProperties?.find(
          (m: { dataPropertyId: any }) => m.dataPropertyId === k.dataPropertyId
        );
        return {
          deviceId: k.deviceId,
          dataPropertyId: k.dataPropertyId,
          upperLimit: k.upperLimit,
          lowLimit: k.lowLimit,
          accumulationThresholdStatType:
            itemProperty?.measurementType === MeasurementType.ACCUM ? k.accumulationThresholdStatType : undefined,
        };
      })
      .filter((m: any) => !isNil(m.dataPropertyId) && !isNil(m.upperLimit) && !isNil(m.lowLimit));
    if (!configs.length) {
      Modal.warning({
        title: '请至少填写一条',
      });
      return;
    }
    setLoading(true);
    apiV2AppAnalysisSubscriptionThresholdConfigSavePost({
      appAnalysisSubId,
      configs,
    })
      .then(res => {
        message.success({
          content: '保存成功',
        });
        updateFn();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="用能阈值配置"></FormTitle>
      <SubContent>
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" isOrigin onFinish={onFinish}>
            <Form.Item name="list" required label="用能信息" valuePropName="dataSource">
              <Table bordered columns={columns} />
            </Form.Item>
            <Space size={8} className="sticky-footer-left">
              <Button type="primary" htmlType="submit">
                保存
              </Button>
              <Button
                onClick={() => {
                  navigate('/energy/appAnalyticsSubscription/productionProcess');
                }}
              >
                返回
              </Button>
            </Space>
          </Form>
        </Spin>
      </SubContent>
    </Wrapper>
  );
};

export default EnergyIntervalConfig;
