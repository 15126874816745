import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Tag,
  TreeSelect,
} from '@maxtropy/components';
import { isNil } from 'lodash-es';
import {
  V2ProductionBaseCompleteTreePostResponse,
  apiV2AppAnalysisSubscriptionDeviceGetPost,
  apiV2AppAnalysisSubscriptionOutputGetPost,
  apiV2OeeConfigAddPost,
  apiV2OeeConfigDetailPost,
  apiV2OeeConfigEditPost,
  apiV2ProductionBaseCompleteTreePost,
} from '@maxtropy/device-customer-apis-v2';
import { useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Space, Spin } from 'antd';
import { DeviceItem, IsEqual, OutputItem, TreeNode } from '../../utils';
import styles from './index.module.scss';
import OutputClassPng from '../../assets/ouputClass.png';
import OutputProductPng from '../../assets/outputProduct.png';

export interface EditOEEConfigModalProps {
  itemId?: number;
  visible: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

type listTreeProp = Exclude<V2ProductionBaseCompleteTreePostResponse['list'], undefined>[number];
type workCenterTreeProp = Exclude<listTreeProp['workCenterTree'], undefined>[number];
type procedureTreeProp = Exclude<workCenterTreeProp['procedureTree'], undefined>[number];
type stationTreeProp = Exclude<procedureTreeProp['stationTree'], undefined>[number];
type unitTreeProp = Exclude<stationTreeProp['unitTree'], undefined>[number];

type NodeDataProp = listTreeProp & workCenterTreeProp & procedureTreeProp & stationTreeProp & unitTreeProp;

// 生产过程树最多五层，依次为 生产基地 -> 工作中心 -> 工序 -> 工站 -> 用能单元
const TREE_CHILD_KEYS = ['productionBase', 'workCenterTree', 'procedureTree', 'stationTree', 'unitTree'];

function formatToNodeData(data: any, depth: number) {
  return (data ?? []).map((i: any, index: number) => {
    let title = i.name ?? i.procedureName ?? i.stationName ?? i.energyUnitName;
    let id = i.id ?? i.procedureId ?? i.stationId ?? i.energyUnitId;
    let key = TREE_CHILD_KEYS[depth] + '-' + id;

    return {
      title,
      key,
      id,
      disabled: depth === 0,
      children: formatToNodeData(i[TREE_CHILD_KEYS[depth + 1] as keyof NodeDataProp], depth + 1),
    };
  });
}

const EditOEEConfigModal: React.FC<EditOEEConfigModalProps> = ({ itemId, visible, onClose, onUpdate }) => {
  const { id: appAnalysisSubId } = useParams<{ id: string }>();
  const [deviceList, setDeviceList] = useState<DeviceItem[]>([]);
  const [outputList, setOutputList] = useState<OutputItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_code = urlSearchParams.get('code') || undefined;
  const [data, setData] = useState<any[]>();
  const [form] = Form.useForm();

  // 获取关联生产过程
  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      setData(formatToNodeData(res.list, 0));
    });
  }, []);

  // 获取计量设备和关联产出物
  useEffect(() => {
    if (!appAnalysisSubId) return;
    apiV2AppAnalysisSubscriptionDeviceGetPost({ appAnalysisSubId }).then(res => {
      setDeviceList(
        (res?.list ?? []).map(i => ({
          label: i.deviceName + i.deviceCode!,
          value: i.deviceId,
        }))
      );
    });

    apiV2AppAnalysisSubscriptionOutputGetPost({ appAnalysisSubId }).then(res => {
      setOutputList(
        (res?.list ?? []).map(k => ({
          ...k,
          selfId: k.id,
          id: `${k.id}-${k.type}`,
          parentId: k.parentId + '-1',
        }))
      );
    });
  }, [appAnalysisSubId]);

  // 编辑时获取详情
  useEffect(() => {
    if (isNil(itemId)) return;
    setLoading(true);
    apiV2OeeConfigDetailPost({ id: itemId })
      .then(res => {
        form.setFieldsValue({
          name: res?.name,
          deviceId: res.deviceId,
          effectiveDate: dayjs(res.effectiveDate),
          productionCode: res.productionCode,
          outputBeat: res.outputBeat,
          beatEquals: res.beatEquals,
          output: (res.outputList ?? []).map(k => k.outputProductId + '-2'),
        });
        (res.outputList ?? []).forEach(item => {
          form.setFieldValue(['outputBeatList', `${item.outputProductId}-2`], item.outputBeat);
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // 关联过程树
  const processAllTreeData = useMemo(() => {
    const loop = (data: any[], node?: any): any[] =>
      data.map(item => {
        const label = node?.label ? node?.label + '-' + item.title : item.title;
        const ids = node?.ids ? node?.ids + '-' + item.id : item.id;
        return {
          title: item.title,
          label,
          ids,
          children: loop(item.children, { ...item, label, ids }),
          disabled: item.disabled,
        };
      });
    return data ? loop(data) : [];
  }, [data]);

  // 关联过程树
  const processTreeData = useMemo(() => {
    let currentNode;
    const getNodeByIds = (nodes: TreeNode[]) => {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (String(node.ids) === url_code) {
          currentNode = node;
        } else if (node.children) {
          getNodeByIds(node.children);
        }
      }
    };
    getNodeByIds(processAllTreeData);
    return currentNode ? [currentNode] : [];
  }, [processAllTreeData, url_code]);

  // 关联产出物树
  const ouputTreeData = useMemo(() => {
    const loop = (parentId?: string) => {
      return outputList
        .filter(item => (isNil(parentId) ? item.level === 1 : item.parentId === parentId))
        .map(item => {
          item.title = item.name;
          item.children = loop(item.id);
          return item;
        });
    };
    return loop();
  }, [outputList]);

  const getPathByNodeIds = (data: any[], nodeIds: number): string | undefined => {
    const loopNode = (data: any[], path: string[] = []): string[] => {
      for (const node of data) {
        const id = node.ids;
        path.push(node.title as string);
        if (id === nodeIds) return path;
        if (node.children) {
          const findChildren = loopNode(node.children, path);
          if (findChildren?.length) return findChildren;
        }
        path.pop();
      }
      return [];
    };
    const path = loopNode(data);
    return path.join('-') || undefined;
  };

  // 保存
  const onOK = () => {
    form.validateFields().then(values => {
      const ids = isNil(values?.productionCode) ? [] : String(values?.productionCode)?.split('-');
      const namePath = getPathByNodeIds(processAllTreeData, values?.productionCode);
      const outputs = outputList.filter(k => k.type === 2 && values.output?.includes(k.id));
      const params = {
        name: values.name,
        deviceId: values.deviceId,
        effectiveDate: dayjs(values.effectiveDate).format('YYYY-MM-DD'),
        appAnalysisSubscriptionId: appAnalysisSubId,
        beatEquals: values.beatEquals,
        outputBeat: values.outputBeat,
        shopFlowProductionBaseId: ids?.[0],
        shopFlowWorkCenterId: ids?.[1],
        shopFlowWorkProcedureId: ids?.[2],
        shopFlowWorkStationId: ids?.[3],
        energyUnitId: ids?.[4],
        productionCode: values?.productionCode,
        productionProcessName: namePath,
        outputList: outputs.map(k => ({
          outputProductId: k.selfId,
          outputProductName: k.name,
          outputBeat: values?.outputBeatList?.[k.id!],
        })),
      };
      setLoading(true);
      if (itemId) {
        apiV2OeeConfigEditPost({
          id: itemId,
          ...params,
        })
          .then(res => {
            onUpdate();
            onClose();
          })
          .finally(() => setLoading(false));
      } else {
        apiV2OeeConfigAddPost({ ...params })
          .then(res => {
            onUpdate();
            onClose();
          })
          .finally(() => setLoading(false));
      }
    });
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      contentClassName="modal-form-content"
      onCancel={onClose}
      size="normal"
      bodyScroll
      onOk={onOK}
      title={`${itemId ? '编辑' : '新增'}设备OEE配置`}
      footer={[
        <Space size={8} key="custom">
          <Button onClick={onClose}>取消</Button>
          <Button type="primary" onClick={onOK} loading={loading}>
            确定
          </Button>
        </Space>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} initialValues={{ beatEquals: IsEqual.YES }}>
          <Form.Item
            name="name"
            label="设备名称"
            rules={[
              { required: true, message: '请输入设备名称' },
              { max: 20, message: '最多输入20个字符' },
            ]}
          >
            <Input placeholder="请输入" disabled={!isNil(itemId)} />
          </Form.Item>
          <Form.Item name="deviceId" label="计量设备" rules={[{ required: true, message: '请选择计量设备' }]}>
            <Select placeholder="请选择" options={deviceList} showSearch optionFilterProp="label" allowClear />
          </Form.Item>
          <Form.Item name="effectiveDate" label="生效日期" rules={[{ required: true, message: '请选择生效日期' }]}>
            <DatePicker style={{ width: '100%' }} placeholder="请选择" />
          </Form.Item>
          <Form.Item name="productionCode" label="关联生产过程">
            <TreeSelect
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              allowClear
              fieldNames={{ value: 'ids' }}
              treeNodeLabelProp="label"
              treeDefaultExpandAll
              treeData={processTreeData}
            />
          </Form.Item>
          <Form.Item name="output" label="关联产出物">
            <TreeSelect
              fieldNames={{ value: 'id' }}
              treeCheckable={true}
              treeData={ouputTreeData}
              maxTagCount={3}
              tagRender={(props: any) => {
                const nodeList = outputList.filter(k => k.id === props.value && k.type === 2);
                if (nodeList.length) {
                  return (
                    <Tag closable={props.closable} onClose={props.onClose}>
                      {nodeList[0].name}
                    </Tag>
                  );
                }
                return <div></div>;
              }}
              treeTitleRender={node => {
                return (
                  <div className={styles.title}>
                    <img className={styles.icon} src={node.type === 1 ? OutputClassPng : OutputProductPng} alt="" />
                    <span>{node.name}</span>
                  </div>
                );
              }}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item name="beatEquals" label="产出物理论节拍是否一致">
            <Radio.Group>
              <Radio value={IsEqual.YES}>是</Radio>
              <Radio value={IsEqual.NO}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['beatEquals', 'output']}>
            {({ getFieldValue }) => {
              const beatEquals = getFieldValue('beatEquals');
              if (beatEquals === IsEqual.YES) {
                return (
                  <Form.Item name="outputBeat" label="产出物-理论节拍">
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      max={999999999.99}
                      placeholder="请输入"
                      addonAfter="min/pcs"
                    />
                  </Form.Item>
                );
              } else {
                const output = getFieldValue('output');
                const outputs = outputList.filter(k => k.type === 2 && output?.includes(k.id));
                return outputs.map(item => (
                  <Form.Item key={item.id} name={['outputBeatList', item.id!]} label={`${item.name}-理论节拍`}>
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      max={999999999.99}
                      placeholder="请输入"
                      addonAfter="min/pcs"
                    />
                  </Form.Item>
                ));
              }
            }}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditOEEConfigModal;
