import { fetch } from '@maxtropy/components';
import { DeviceType } from '@/shared/types';

export interface DeviceTypeTree {
  aliasName: string[];
  tree: DeviceType[];
}

export const getDeviceTypeTree = () =>
  fetch<DeviceTypeTree>(
    `/api/v2/deviceType/tree`,
    {
      method: 'POST',
    },
    true
  );

export const getDeviceTypeTreeWithoutScene = (params?: { flag?: boolean }) =>
  fetch<{ deviceTypes: DeviceType[] }>(
    `/api/v2/deviceType/treeWithoutScene`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export function getDeviceTypeSubList(id: number) {
  return fetch<{ list: DeviceType[] }>(
    `/api/v2/deviceType/subList`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
