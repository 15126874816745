import { useCallback, useEffect, useRef, useState } from 'react';
import { Progress, Radio, Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import {
  apiV2CarbonFootprintOverviewCalculateDataPost,
  V2CarbonFootprintOverviewCalculateDataPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import PieDecorationImg from '../../assets/pieDecoration@2x.png';
import Top1Img from '../../assets/Top1@2x.png';
import Top2Img from '../../assets/Top2@2x.png';
import Top3Img from '../../assets/Top3@2x.png';
import RawMaterialImg from '../../assets/rawMaterial@2x.png';
import DeliveryTruckImg from '../../assets/deliveryTruck@2x.png';
import IndustryProduceImg from '../../assets/industryProduce@2x.png';
import Subtitle from '../Subtitle';
import styles from './index.module.scss';
import { Empty } from '@maxtropy/components';
import { transCarbonFootprintQuantity, transPercent } from '../../utils';

const stageImg = [
  {
    bg: Top1Img,
    icon: RawMaterialImg,
  },
  {
    bg: Top2Img,
    icon: DeliveryTruckImg,
  },
  {
    bg: Top3Img,
    icon: IndustryProduceImg,
  },
];

enum RadioValue {
  Summary = 1,
  Year = 2,
}

const ReportedCarbonEmissionsOfProducts = ({ isTwoCol }: { isTwoCol?: boolean }) => {
  const [data, setData] = useState<V2CarbonFootprintOverviewCalculateDataPostResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [radioValue, setRadioValue] = useState<RadioValue>(RadioValue.Summary);
  const echartRef = useRef<any>(null);
  const [update, setUpdate] = useState<number>(0);

  useEffect(() => {
    setLoading(true);
    apiV2CarbonFootprintOverviewCalculateDataPost({ type: radioValue })
      .then(res => {
        setData(res);
      })
      .finally(() => setLoading(false));
  }, [radioValue]);

  useEffect(() => {
    if (echartRef.current) {
      setUpdate(update => update + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [echartRef.current, data]);

  const getChartOption = useCallback(
    (data: V2CarbonFootprintOverviewCalculateDataPostResponse['productCarbonList'] = [], echartRef: any) => {
      const pieData =
        data?.map(item => ({
          value: item.carbonFootPrint,
          ...item,
        })) || [];

      let imgX = 50;
      let imgY = 50;
      let imgOrigin = [120, 120];
      let textX = 120;
      let textY = 120;
      if (echartRef.current) {
        // 计算中间graphic区域的xy轴
        let echartWidth = echartRef.current.ele.clientWidth;
        let echartHeight = echartRef.current.ele.clientHeight;
        // 图片x坐标 = 图表宽度 - 图片宽度 / 2
        imgX = (echartWidth - 140) / 2;
        imgY = (echartHeight - 140) / 2;
        // 图片旋转的中心点 = 图表宽度/高度  / 2
        imgOrigin = [echartWidth / 2, echartHeight / 2];
        // 文字坐标 = 图表宽度/高度 / 2
        textX = echartWidth / 2;
        textY = echartHeight / 2;
      }

      return {
        color: ['#52E7FF', '#16DD8E', '#2D8DFF', '#FFCB47', '#854ECA', '#FF477B', '#ABD335', '#FF6D2C'],
        tooltip: {
          position: 'right',
          backgroundColor: 'rgba(0,0,0,0.8)',
          borderColor: 'transparent',
          padding: [8, 12, 0, 12],
          className: 'carbon-chart-tooltip',
          formatter: (tooltipData: any) => {
            return `
              <div class='carbon-tooltip-title'><div style='width:8px;height:8px;background:${
                tooltipData?.color
              };border-radius:50%;margin-right:8px'></div>${tooltipData.name}</div>
              <div>生产量<span>${tooltipData?.data?.quantity ?? '--'}${tooltipData?.data?.unitCode ?? '--'}</span></div>
              <div>生产批次<span>${tooltipData?.data?.count ?? '--'}</span></div>
              <div>碳排量<span>${transCarbonFootprintQuantity(tooltipData?.data?.carbonFootPrint).join(
                ' '
              )}</span></div>
              <div>占比<span>${transPercent(tooltipData?.data?.percent)}</span></div>
            `;
          },
        },
        graphic: {
          elements: [
            {
              type: 'image',
              style: {
                image: PieDecorationImg,
                x: imgX,
                y: imgY,
                width: 140,
                height: 140,
              },
              keyframeAnimation: [
                {
                  // 动画时长，单位 ms
                  duration: 1000,
                  // 设置旋转动画是否循环
                  loop: true,
                  easing: 'linear',
                  // 动画的关键帧
                  keyframes: [
                    {
                      // percent为关键帧的位置,0为第一个,1为最后一个
                      percent: 0,
                      rotation: 0,
                      transition: ['rotation'],
                    },
                    {
                      percent: 1,
                      rotation: 360,
                      transition: ['rotation'],
                    },
                  ],
                },
              ],
              // 旋转的中心点
              origin: imgOrigin,
              tooltip: {
                show: false,
              },
            },
            {
              type: 'text',
              style: {
                x: textX,
                y: textY,
                text: '各产品碳足迹\n占比',
                textAlign: 'center',
                textVerticalAlign: 'middle',
                font: 'PingFangSC, PingFang SC',
                fill: 'rgba(255,255,255,0.85)',
                fontSize: 14,
                lineHeight: 22,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            name: '当前批次碳足迹',
            percentPrecision: 5,
            minAngle: 2,
            padAngle: 1,
            radius: [80, 90],
            label: {
              show: false,
            },
            data: pieData,
            emptyCircleStyle: {
              color: 'rgba(82,231,255,0.1)',
            },
          },
          {
            data: pieData,
            type: 'pie',
            percentPrecision: 5,
            padAngle: 1,
            minAngle: 2,
            name: '当前批次碳足迹',
            radius: [90, 110],
            emphasis: {
              disabled: true,
            },
            label: {
              show: false,
            },
            itemStyle: {
              opacity: 0.2,
            },
            tooltip: {
              show: false,
            },
            emptyCircleStyle: {
              color: 'rgba(82,231,255,0.05)',
            },
          },
        ],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );

  return (
    <div className={styles.reportedCECon}>
      <Subtitle
        title="已填报产品碳排"
        rightContent={
          <Radio.Group
            onChange={v => {
              setRadioValue(v.target.value);
            }}
            value={radioValue}
            className={styles.radioStyles}
            optionType="button"
          >
            <Radio value={RadioValue.Summary}>汇总</Radio>
            <Radio value={RadioValue.Year}>本年</Radio>
          </Radio.Group>
        }
      />
      <div className={styles.CarbonInfo}>
        <div className={styles.productCarbonInfo}>
          {loading ? (
            <Spin spinning={true} />
          ) : (
            <ReactEcharts
              style={
                isTwoCol
                  ? {
                      width: '100%',
                      height: '100%',
                    }
                  : {
                      width: 240,
                      height: 240,
                    }
              }
              ref={echartRef}
              option={getChartOption(data?.productCarbonList, echartRef)}
              notMerge
              lazyUpdate={false}
            />
          )}
        </div>
        <div className={styles.stageCarbonInfo}>
          <div className={styles.title}>各阶段碳足迹占比</div>
          <Spin spinning={loading}>
            {data?.stageCarbonList?.length ? (
              data.stageCarbonList.map((item, index) => (
                <div className={styles.stageItem}>
                  <div className={styles.left}>
                    <img src={stageImg[index].bg} alt="pic" />
                    <span className={styles.serial}>{index + 1}</span>
                    <img src={stageImg[index].icon} alt="pic" />
                    <span>{item.name}</span>
                  </div>
                  <div className={styles.right}>
                    <div className={styles.stageInfo}>
                      <span>{transPercent(item.percent)}</span>
                      <div>
                        <span style={{ color: 'rgba(255,255,255,0.65)' }}>碳排量：</span>
                        <span style={{ color: 'rgba(255,255,255,0.85)' }}>
                          {transCarbonFootprintQuantity(item.carbonFootPrint).join(' ')}
                        </span>
                      </div>
                    </div>
                    <Progress
                      percent={item.percent! * 100}
                      status="active"
                      showInfo={false}
                      strokeColor={{ '0%': '#32F0C2', '100%': '#16DD8E' }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <Empty style={{ marginTop: 50 }} />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default ReportedCarbonEmissionsOfProducts;
