import { BSADeviceItem } from '@/api/energyAssetsBill';
import { Table } from '@maxtropy/components';

interface Props {
  data: BSADeviceItem[];
  loading?: boolean;
}

const columns = [
  {
    title: '计量电表名称',
    dataIndex: 'name',
  },
  {
    title: '计量电表编号',
    dataIndex: 'code',
  },
];

const BsaDeviceTable: React.FC<Props> = ({ data, loading }) => {
  return <Table loading={loading} rowKey="id" columns={columns} dataSource={data} />;
};

export default BsaDeviceTable;
