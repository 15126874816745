import { Button, EllipsisSpan, Key, useUpdate } from '@maxtropy/components';
import { Form, Input, InputNumber, Modal, Space, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MeterProjectChangeButtonType, SingleOrDetail } from '../MeterProjectDetail';
import styles from './index.module.scss';
import EleAdjustInput from '../EleAdjustInput';
import EleShareInput from '../EleShareInput';
import AdjustModal from '../AdjustModal';
import TimeIntervalShowTag, { TimeIntervalType } from '../TimeIntervalShowTag';
import { addNum } from '../../utils';
import {
  AdjustBody,
  ApportionListItem,
  EnergyMediumTableDataItem,
  MeterTaskBaseInfo,
  apportionFunc,
  batchAdjustFunc,
  getEnergyMediumTableData,
  meterReadingAmountChangeFunc,
} from '@/api/universeMeterQuery';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { changeAdjust, changeCurrentCopyNum, changeShare, getCellStyle, getRowSpan } from '../../handleTreeDataUtils';

interface Props {
  changeButtonType?: MeterProjectChangeButtonType;
  setChangeButtonType: (type?: MeterProjectChangeButtonType) => void;
  singleOrDetail: SingleOrDetail;
  isElectricity: boolean;
  mediumId: number;
  basicInfo?: MeterTaskBaseInfo;
  unitName: string;
  level?: number;
}

// 输入框共同属性
const inputNumberProps = {
  style: { width: '100%' },
  max: 9999999999,
  step: 0.01,
  precision: 2,
};

const HierarchyEnergyMediumTable: React.FC<Props> = ({
  changeButtonType,
  setChangeButtonType,
  singleOrDetail,
  isElectricity,
  mediumId,
  basicInfo,
  unitName,
  level,
}) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<EnergyMediumTableDataItem[]>([]);
  const [beforeEditData, setBeforeEditData] = useState<EnergyMediumTableDataItem[]>([]); // 编辑前的数据
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [batchAdjustForm] = Form.useForm();
  const [adjustOpen, setAdjustOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<EnergyMediumTableDataItem>();
  const [adjustEditItemIds, setAdjustEditItemIds] = useState<Key[]>([]); // 调整编辑项 id
  const [copyNumEditItemIds, setCopyNumEditItemIds] = useState<Key[]>([]); // 本期抄见数编辑项 id
  const [shareEditItemIds, setShareEditItemIds] = useState<Key[]>([]); // 分摊编辑项 id
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const hasAdjust = useHasPermission(PermissionsType.B_GLOBALMETERREADINGADJUST); // 调整

  useEffect(() => {
    if (id && mediumId) {
      // 获取分项抄表
      setLoading(true);
      getEnergyMediumTableData({ taskId: id, mediumId, level })
        .then(res => {
          if (res) {
            const tableData: EnergyMediumTableDataItem[] = [];
            res.list.forEach(project => {
              project.rows.forEach((row, index) => {
                tableData.push({
                  id: `${project.meterReadingObjectId}-${row.itemId}-${index}`,
                  itemId: row.itemId,
                  meterReadingObjectId: project.meterReadingObjectId,
                  meterReadingObjectName: project.meterReadingObjectName,
                  meterReadingLevel: project.meterReadingLevel,
                  deviceTypeName: row.deviceTypeName,
                  deviceId: row.deviceId,
                  deviceCode: row.deviceCode,
                  deviceName: row.deviceName,
                  topologyNodeName: row.topologyNodeName,
                  dataPropertyName: row.dataPropertyName,
                  last: row.lastPeriodReadingValue,
                  current: row.currentPeriodReadingValue,
                  fact: row.realReadingAmount,
                  adjust: row.adjustAmount,
                  afterAdjust: row.afterAdjustAmount,
                  share: project.apportionAmount,
                  final: project.finalAmount,
                  summitAdjust: row.summitAdjustAmount,
                  peakAdjust: row.peakAdjustAmount,
                  plainAdjust: row.plainAdjustAmount,
                  valleyAdjust: row.valleyAdjustAmount,
                  adjustReason: row.adjustReason,
                  summitShare: project.summitApportionAmount,
                  peakShare: project.peakApportionAmount,
                  plainShare: project.plainApportionAmount,
                  valleyShare: project.valleyApportionAmount,
                });
              });
            });
            setData(tableData);
            setBeforeEditData(tableData);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, mediumId, update, level]);

  const singleOrDetailColumns = useMemo(() => {
    const levelColumns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        width: 100,
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
        }),
        render: (v: number) => <EllipsisSpan value={v ? `${v}级` : v} />,
      },
    ];

    let columns = [
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        width: 100,
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
        }),
      },

      {
        title: '类型',
        dataIndex: 'deviceTypeName',
        width: 100,
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        isHide: true,
      },
      {
        title: '设备编号',
        width: 100,
        dataIndex: 'deviceCode',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        isHide: true,
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备名称',
        width: 100,
        dataIndex: 'deviceName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '对应拓扑节点',
        width: 100,
        dataIndex: 'topologyNodeName',
        ellipsis: { showTitle: true },
        isHide: true,
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '数据属性',
        width: 100,
        dataIndex: 'dataPropertyName',
        ellipsis: { showTitle: true },
        isHide: true,
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '上期抄见数',
        width: 100,
        dataIndex: 'last',
        ellipsis: { showTitle: true },
        isHide: true,
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '本期抄见数',
        width: 160,
        dataIndex: 'current',
        ellipsis: { showTitle: true },
        onCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.CHANGE_COPY_NUM),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.CHANGE_COPY_NUM),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.CHANGE_COPY_NUM ? (
            <InputNumber
              {...inputNumberProps}
              value={v}
              min={0}
              onChange={value => {
                if (isNil(value)) return;
                setData(changeCurrentCopyNum(value, isElectricity, record, data));
                setCopyNumEditItemIds([...copyNumEditItemIds, record.itemId]);
              }}
            />
          ) : (
            <EllipsisSpan value={v} />
          );
        },
      },
      {
        title: '实际抄表值',
        width: 100,
        dataIndex: 'fact',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整值',
        dataIndex: isElectricity ? 'electricityAdjust' : 'adjust',
        ellipsis: { showTitle: true },
        width: isElectricity ? (changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST ? 650 : 450) : 200,
        onCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.BATCH_ADJUST),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.BATCH_ADJUST),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST ? (
            isElectricity ? (
              <EleAdjustInput
                adjustEditItemIds={adjustEditItemIds}
                setAdjustEditItemIds={setAdjustEditItemIds}
                data={data}
                setData={setData}
                currentRow={record}
              />
            ) : (
              <InputNumber
                {...inputNumberProps}
                value={v}
                min={-999999}
                onChange={value => {
                  setData(changeAdjust(value, record, data));
                  setAdjustEditItemIds([...adjustEditItemIds, record.itemId]);
                }}
              />
            )
          ) : isElectricity ? (
            <>
              <Space>
                {[
                  { value: record.summitAdjust, type: TimeIntervalType.SUMMIT },
                  { value: record.peakAdjust, type: TimeIntervalType.PEAK },
                  { value: record.plainAdjust, type: TimeIntervalType.PLAIN },
                  { value: record.valleyAdjust, type: TimeIntervalType.VALLEY },
                ].map((item, index) => {
                  return <TimeIntervalShowTag key={index} timeIntervalType={item.type} value={item.value} />;
                })}
              </Space>
            </>
          ) : (
            <EllipsisSpan value={v} />
          );
        },
      },
      {
        title: '调整后值',
        width: 100,
        dataIndex: 'afterAdjust',
        ellipsis: { showTitle: true },
        isHide: true,
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '分摊值',
        dataIndex: isElectricity ? 'eleShare' : 'share',
        width: isElectricity ? (changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? 650 : 450) : 200,
        ellipsis: { showTitle: true },
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? (
            isElectricity ? (
              <EleShareInput
                setShareEditItemIds={setShareEditItemIds}
                shareEditItemIds={shareEditItemIds}
                data={data}
                setData={setData}
                currentRow={record}
              />
            ) : (
              <InputNumber
                {...inputNumberProps}
                value={v}
                min={0}
                onChange={value => {
                  setData(changeShare(value, record, data));
                  setShareEditItemIds([...shareEditItemIds, record.itemId]);
                }}
              />
            )
          ) : isElectricity ? (
            <Space>
              {[
                { value: record.summitShare, type: TimeIntervalType.SUMMIT },
                { value: record.peakShare, type: TimeIntervalType.PEAK },
                { value: record.plainShare, type: TimeIntervalType.PLAIN },
                { value: record.valleyShare, type: TimeIntervalType.VALLEY },
              ].map((item, index) => {
                return <TimeIntervalShowTag key={index} timeIntervalType={item.type} value={item.value} />;
              })}
            </Space>
          ) : (
            <EllipsisSpan value={v} />
          );
        },
        editable: true,
      },
      {
        title: '最终值',
        width: 100,
        dataIndex: 'final',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
        }),
      },
      {
        title: '操作',
        fixed: 'right' as 'right',
        width: 100,
        render: (v: any, record: EnergyMediumTableDataItem) => {
          return (
            <Space>
              {hasAdjust && (
                <Button
                  type="link"
                  onClick={() => {
                    setAdjustOpen(true);
                    setCurrentRow(record);
                  }}
                >
                  调整
                </Button>
              )}
            </Space>
          );
        },
      },
    ];

    // 编辑态过滤操作行
    if (!!changeButtonType) {
      columns = columns.filter(i => i.title !== '操作');
    }

    // 表格过滤隐藏列
    if (singleOrDetail === SingleOrDetail.SINGLE) {
      columns = columns.filter(i => !i.isHide);
    }

    if (!isNil(level)) {
      return [...levelColumns, ...columns];
    }
    return columns;
  }, [
    isElectricity,
    changeButtonType,
    singleOrDetail,
    data,
    copyNumEditItemIds,
    adjustEditItemIds,
    shareEditItemIds,
    hasAdjust,
    level,
  ]);

  return (
    <>
      {changeButtonType && (
        <Space size={8}>
          <Button
            type="primary"
            onClick={() => {
              if (changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST) {
                modalApi.confirm({
                  title: '确定保存批量调整？',
                  content: (
                    <Form form={batchAdjustForm}>
                      <Form.Item
                        name="batchAdjustReason"
                        rules={[{ max: 20, message: '调整原因最多20个字符' }]}
                        label="调整原因"
                      >
                        <Input.TextArea
                          autoSize={{
                            minRows: 3,
                          }}
                        />
                      </Form.Item>
                    </Form>
                  ),
                  okText: '保存',
                  onOk: () => {
                    let adjustList: AdjustBody[] = [];
                    const editData = data.filter(i => adjustEditItemIds.includes(i.itemId));
                    if (isElectricity) {
                      adjustList = editData.map(i => ({
                        itemId: i.itemId,
                        adjustType: 2,
                        adjustAmountSummit: i.summitAdjust,
                        adjustAmountPeak: i.peakAdjust,
                        adjustAmountPlain: i.plainAdjust,
                        adjustAmountValley: i.valleyAdjust,
                      }));
                    } else {
                      adjustList = editData.map(i => ({
                        itemId: i.itemId,
                        adjustType: 1,
                        adjustAmount: i.adjust,
                      }));
                    }
                    batchAdjustFunc({
                      list: adjustList,
                      adjustReason: batchAdjustForm.getFieldValue('batchAdjustReason'),
                    }).then(() => {
                      setChangeButtonType(undefined);
                      setBeforeEditData(data);
                      setAdjustEditItemIds([]);
                      updateFn();
                      return;
                    });
                  },
                });
              }
              if (changeButtonType === MeterProjectChangeButtonType.CHANGE_COPY_NUM) {
                modalApi.confirm({
                  title: '确认抄见数变更？',
                  content: '抄见数变更会同步到下一期抄表抄见数，影响最终数据。请确认变更？',
                  okText: '确认变更',
                  onOk: () => {
                    let editData = data.filter(i => copyNumEditItemIds.includes(i.itemId));
                    const body = editData.map(i => ({
                      itemId: i.itemId,
                      updateMeterReadingAmount: i.current,
                    }));
                    meterReadingAmountChangeFunc({ list: body }).then(() => {
                      setChangeButtonType(undefined);
                      setBeforeEditData(data);
                      setCopyNumEditItemIds([]);
                      updateFn();
                      return;
                    });
                  },
                });
              }
              if (changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT) {
                if (id && mediumId) {
                  const editData = data.filter(i => shareEditItemIds.includes(i.itemId));
                  let apportionList: ApportionListItem[] = [];
                  if (isElectricity) {
                    apportionList = editData.map(i => ({
                      taskId: id,
                      objectId: i.meterReadingObjectId,
                      energyMediumId: mediumId,
                      adjustType: 2,
                      apportionAmountSummit: i.summitShare,
                      apportionAmountPeak: i.peakShare,
                      apportionAmountPlain: i.plainShare,
                      apportionAmountValley: i.valleyShare,
                    }));
                  } else {
                    apportionList = editData.map(i => ({
                      taskId: id,
                      objectId: i.meterReadingObjectId,
                      energyMediumId: mediumId,
                      adjustType: 1,
                      apportionAmount: i.share,
                    }));
                  }
                  apportionFunc({ list: apportionList }).then(() => {
                    setChangeButtonType(undefined);
                    setShareEditItemIds([]);
                    setBeforeEditData(data);
                    updateFn();
                    return;
                  });
                }
              }
            }}
          >
            保存
          </Button>
          <Button
            onClick={() => {
              setChangeButtonType(undefined);
              setAdjustEditItemIds([]);
              setCopyNumEditItemIds([]);
              setShareEditItemIds([]);
              setData(beforeEditData);
            }}
          >
            取消
          </Button>
        </Space>
      )}

      <div className={styles.tableStyles}>
        <Table
          loading={loading}
          bordered
          columns={singleOrDetailColumns}
          rowKey="id"
          pagination={false}
          dataSource={data}
          sticky
          scroll={{ x: 1300 }}
          summary={pageData => {
            let totalFact: number | null | undefined; // 总实际抄表值
            let totalAdjust: number | null | undefined; // 总调整值
            let totalAdjustAfter: number | null | undefined; // 总调整后值
            let totalShare: number | null | undefined; // 总分摊值
            let totalFinal: number | null | undefined; // 总最终值
            const objectMap = new Map<Key, EnergyMediumTableDataItem[]>();

            pageData.forEach(i => {
              if (!isNil(i.fact)) {
                totalFact = addNum(i.fact, totalFact);
              }
              if (!isNil(i.afterAdjust)) {
                totalAdjustAfter = addNum(i.afterAdjust, totalAdjustAfter);
              }
              totalAdjust = isElectricity
                ? addNum(i.summitAdjust, i.peakAdjust, i.plainAdjust, i.valleyAdjust, totalAdjust)
                : addNum(i.adjust, totalAdjust);
              objectMap.set(i.meterReadingObjectId, [...(objectMap.get(i.meterReadingObjectId) ?? []), i]);
            });

            totalShare = addNum(
              ...Array.from(objectMap.values())
                .map(i => i[0])
                .map(i => (isElectricity ? addNum(i.summitShare, i.peakShare, i.plainShare, i.valleyShare) : i.share))
            );

            totalFinal = addNum(
              ...Array.from(objectMap.values())
                .map(i => i[0])
                .map(i => i.final)
            );

            const currentEmptyIndex = isNil(level)
              ? singleOrDetail === SingleOrDetail.SINGLE
                ? [1]
                : [1, 2, 3, 4, 5, 6]
              : singleOrDetail === SingleOrDetail.SINGLE
              ? [1, 2]
              : [1, 2, 3, 4, 5, 6, 7];
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>累计</Table.Summary.Cell>
                  {currentEmptyIndex.map((i, index) => (
                    <Table.Summary.Cell key={index} index={i}>
                      --
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell
                    index={
                      isNil(level)
                        ? singleOrDetail === SingleOrDetail.SINGLE
                          ? 2
                          : 7
                        : singleOrDetail === SingleOrDetail.SINGLE
                        ? 3
                        : 8
                    }
                    className={
                      changeButtonType === MeterProjectChangeButtonType.CHANGE_COPY_NUM
                        ? styles.summaryCellStyle
                        : undefined
                    }
                  >
                    --
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={
                      isNil(level)
                        ? singleOrDetail === SingleOrDetail.SINGLE
                          ? 3
                          : 8
                        : singleOrDetail === SingleOrDetail.SINGLE
                        ? 4
                        : 9
                    }
                  >
                    {isNil(totalFact) ? '--' : totalFact}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={
                      isNil(level)
                        ? singleOrDetail === SingleOrDetail.SINGLE
                          ? 4
                          : 9
                        : singleOrDetail === SingleOrDetail.SINGLE
                        ? 5
                        : 10
                    }
                    className={
                      changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST
                        ? styles.summaryCellStyle
                        : undefined
                    }
                  >
                    {isNil(totalAdjust) ? '--' : totalAdjust}
                  </Table.Summary.Cell>
                  {singleOrDetail === SingleOrDetail.DETAIL && (
                    <Table.Summary.Cell index={isNil(level) ? 10 : 11}>
                      {isNil(totalAdjustAfter) ? '--' : totalAdjustAfter}
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell
                    index={
                      isNil(level)
                        ? singleOrDetail === SingleOrDetail.SINGLE
                          ? 5
                          : 11
                        : singleOrDetail === SingleOrDetail.SINGLE
                        ? 6
                        : 12
                    }
                    className={
                      changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT
                        ? styles.summaryCellStyle
                        : undefined
                    }
                  >
                    {isNil(totalShare) ? '--' : totalShare}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={
                      isNil(level)
                        ? singleOrDetail === SingleOrDetail.SINGLE
                          ? 6
                          : 12
                        : singleOrDetail === SingleOrDetail.SINGLE
                        ? 7
                        : 13
                    }
                  >
                    {isNil(totalFinal) ? '--' : totalFinal}
                  </Table.Summary.Cell>
                  {isNil(changeButtonType) && (
                    <Table.Summary.Cell index={isNil(level) ? 13 : 14}>--</Table.Summary.Cell>
                  )}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
      {adjustOpen && (
        <AdjustModal
          basicInfo={basicInfo}
          currentRow={currentRow}
          adjustOpen={adjustOpen}
          setAdjustOpen={setAdjustOpen}
          isElectricity={isElectricity}
          unitName={unitName}
          updateFn={updateFn}
        />
      )}
      {modalContextHolder}
    </>
  );
};
export default HierarchyEnergyMediumTable;
