export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

// 精确显示小数后2位
export const keepTwoDecimalWithReg = (num?: number | null) => {
  if (typeof num === 'number') {
    if (num > 0) {
      return Math.floor(num * 100) / 100;
    }
    let val = Math.ceil(num * 100) / 100;
    if (val === -0) return 0;
    return val;
  }
  return num;
};
// 精确显示小数点后2位，没有时补0
export const keepTwoDecimalStr = (num: number) => {
  const result = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/));
  let s = result.toString();
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
};

// 获取树深度
export const getDepth = (obj: any) => {
  var depth = 0;
  if (obj.children) {
    obj.children.forEach(function (d: any) {
      var tmpDepth = getDepth(d);
      if (tmpDepth > depth) {
        depth = tmpDepth;
      }
    });
  }
  return 1 + depth;
};

// format选项格式
export const formatLevelOptions = (maxDepth: number, disabledNum: number) => {
  const res = [];
  for (let i = 1; i < maxDepth + 1; i++) {
    let disabled = false;
    if (i <= disabledNum) {
      disabled = true;
    }
    res.push({
      label: `${i}级`,
      value: i,
      disabled,
    });
  }
  return res;
};

export function flexible(proportionalLayoutEl: any) {
  function setScale() {
    if (document.documentElement.clientWidth > 1920) {
      let deviceWidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      let scale = deviceWidth / 1920; // 分母——设计稿的尺寸
      // @ts-ignore
      proportionalLayoutEl.style.zoom = scale; //放大缩小相应倍数
      // document.documentElement.style.transform = `scale(${scale})`; //放大缩小相应倍数
    }
  }

  setScale();

  // reset rem unit on page resize
  window.addEventListener('resize', setScale);

  return function unsubscribe() {
    window.removeEventListener('resize', setScale);
  };
}

export const pollingInterval = 1000 * 60 * 5; // 5分钟轮询一次

export const getPer = (num: number) => {
  if (num >= 0.8) return num + 0.1;
  if (num >= 0.7) return num + 0.2;
  if (num >= 0.6) return num + 0.3;
  if (num >= 0.5) return num + 0.4;
  if (num >= 0.4) return num + 0.5;
  if (num >= 0.3) return num + 0.6;
  if (num >= 0.2) return num + 0.7;
  if (num >= 0.1) return num + 0.8;
  return num + 0.9;
};

export const scale =
  document.body.clientWidth > 1920 ? getPer(1920 / document.body.clientWidth) : 1920 / document.body.clientWidth;
