import { useEffect, useState } from 'react';
import {
  apiV2CarbonFootprintOverviewCalculateCountPost,
  V2CarbonFootprintOverviewCalculateCountPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import CircleImg from '../../assets/circle@2x.png';
import ApplicationImg from '../../assets/Application@2x.png';
import CertificationImg from '../../assets/Certification@2x.png';
import CO2Img from '../../assets/CO2@2x.png';
import styles from './index.module.scss';
import Subtitle from '../Subtitle';
import { transCarbonFootprintQuantity } from '../../utils';

const SubmitDataOverview = () => {
  const [data, setData] = useState<V2CarbonFootprintOverviewCalculateCountPostResponse>();
  useEffect(() => {
    apiV2CarbonFootprintOverviewCalculateCountPost({}).then(res => {
      setData(res ?? {});
    });
  }, []);

  return (
    <div className={styles.submitDataCon}>
      <Subtitle title="填报概览" />
      <div className={styles.submitInfo}>
        <div className={styles.submitInfoItem}>
          <div className={styles.left_img}>
            <img src={CircleImg} alt="pic" />
            <img src={ApplicationImg} alt="pic" />
          </div>
          <div className={styles.detail}>
            <div className={styles.count}>{data?.appliedCount}</div>
            <div className={styles.desc}>已申请产品碳足迹（个）</div>
          </div>
        </div>
        <div className={styles.submitInfoItem}>
          <div className={styles.left_img}>
            <img src={CircleImg} alt="pic" />
            <img src={CertificationImg} alt="pic" />
          </div>
          <div className={styles.detail}>
            <div className={styles.count}>{data?.filledCount}</div>
            <div className={styles.desc}>证书生成数（个）</div>
          </div>
        </div>
        <div className={styles.submitInfoItem}>
          <div className={styles.left_img}>
            <img src={CircleImg} alt="pic" />
            <img src={CO2Img} alt="pic" />
          </div>
          <div className={styles.detail}>
            <div className={styles.count}>{transCarbonFootprintQuantity(data?.carbonFootprint)[0]}</div>
            <div className={styles.desc}>碳足迹总量({transCarbonFootprintQuantity(data?.carbonFootprint)[1]})</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitDataOverview;
