import FirstImg from '../../assets/firstImg@2x.png';
import secondImg from '../../assets/secondImg@2x.png';
import thirdImg from '../../assets/thirdImg@2x.png';
import styles from './index.module.scss';
import { Empty, Tooltip } from '@maxtropy/components';
import SubTitle from '../subTitle';
import { V2OeeApplyGetOeeDayPostResponse } from '@maxtropy/device-customer-apis-v2';
import { isEmpty } from 'lodash-es';

export type BaseOeeInfoList = V2OeeApplyGetOeeDayPostResponse['baseOeeInfo'];
export type BaseOeeInfo = Exclude<BaseOeeInfoList, undefined>[number];

const RankImg = {
  0: FirstImg,
  1: secondImg,
  2: thirdImg,
};

const RenderTooltip = (item: BaseOeeInfo) => (
  <div className={styles.barTooltipContent}>
    <div>{item.deviceName || '--'}</div>
    <div>
      <span>
        <span className={styles.dot} />
        时间稼动率
      </span>
      {item.timeOee ?? '--'}%
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#FFCB47' }} />
        计划外停机时间
      </span>
      {item.unplannedShutdownTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#556583' }} />
        待机时长
      </span>
      {item.standbyTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} style={{ background: '#52E7FF' }} />
        稼动时长
      </span>
      {item.oeeTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} />
        负荷时长
      </span>
      {item.loadTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} />
        非工作时间运行时长
      </span>
      {item.unplannedRunTime ?? '--'}h
    </div>
    <div>
      <span>
        <span className={styles.dot} />
        计划停机时长
      </span>
      {item.plannedShutdownTime ?? '--'}h
    </div>
  </div>
);

const transPercent = (member: number | undefined, members: number[]) => {
  return member ? Number(((member / members.reduce((a, b) => a + b, 0)) * 100).toFixed(2)) : 0;
};

const RenderBar = (item: BaseOeeInfo, index: keyof typeof RankImg) => {
  const oeeTimeRate = transPercent(item.oeeTime!, [
    item.oeeTime || 0,
    item.unplannedShutdownTime || 0,
    item.standbyTime || 0,
  ]);
  const unplannedShutdownTimeRate = transPercent(item.unplannedShutdownTime!, [
    item.oeeTime || 0,
    item.unplannedShutdownTime || 0,
    item.standbyTime || 0,
  ]);

  return (
    <div className={styles.list}>
      {RankImg[index] ? (
        <img src={RankImg[index]} width={20} height={20} alt="" />
      ) : (
        <span className={styles.rank}>{index + 1}</span>
      )}
      <Tooltip title={item.deviceName}>
        <span className={styles.name}>{item.deviceName}</span>
      </Tooltip>
      <Tooltip overlayClassName={styles.barTooltip} title={RenderTooltip(item)} arrow={false}>
        <div style={{ flex: 1, height: 12, marginRight: 6, background: 'rgba(255, 255, 255, 0.1)' }}>
          <div
            style={{
              height: 12,
              width: `${item.timeOee ?? 0}%`,
              background: `linear-gradient(to right, #52E7FF 0%, #52E7FF ${oeeTimeRate}%, #FFCB47 ${oeeTimeRate}%, #FFCB47 ${(
                oeeTimeRate + unplannedShutdownTimeRate
              ).toFixed(2)}%,#556583 ${(oeeTimeRate + unplannedShutdownTimeRate).toFixed(2)}%, #556583 100%)`,
            }}
          />
        </div>
      </Tooltip>
      <div style={{ width: 156 }}>
        时间稼动率：<span style={{ color: '#2D8DFF' }}>{item.timeOee ?? '--'}%</span>
      </div>
    </div>
  );
};
const DeviceUptime = ({ data }: { data: BaseOeeInfoList }) => {
  return (
    <div>
      <SubTitle title="设备时间稼动" />
      {isEmpty(data) ? (
        <Empty style={{ margin: 50 }} />
      ) : (
        <>
          <div className={styles.legend}>
            <div className={styles.legendItem}>
              <span style={{ background: '#52E7FF' }} />
              稼动时长
            </div>
            <div className={styles.legendItem}>
              <span style={{ background: '#FFCB47' }} />
              计划外停机时间
            </div>
            <div className={styles.legendItem}>
              <span style={{ background: '#556583' }} />
              待机时长
            </div>
          </div>
          <div className={styles.content}>
            {data!.map((item, index) => RenderBar(item, index as keyof typeof RankImg))}
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceUptime;
