import {
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useSearchParams,
  useCurrent,
  Form,
  Modal,
  Input,
  Select,
  Table,
  CustomFilter,
  Tag,
} from '@maxtropy/components';
import { Space, Spin, Upload } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getTopologySelectList } from '@/api/quality-monitor';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import QuotaSetModal from './QuotaSetModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import DownLoadTemplateModal from '@/pages/QualityMonitor/Quota/DownLoadTemplateModal';
import { extname } from '@/shared/utils/utils';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import OperationLogModal from '@/pages/QualityMonitor/Quota/OperationLogModal';
import {
  apiV2IndicatorImportPost,
  apiV2IndicatorProcessDetailPagePost,
  V2IndicatorProcessDetailPagePostResponse,
  V2IndicatorProcessDetailPagePostRequest,
} from '@maxtropy/device-customer-apis-v2';

const FORMAT_TIME = 'YYYY-MM-DD HH:mm';

interface SearchParams extends Omit<V2IndicatorProcessDetailPagePostRequest, 'page' | 'size' | 'timestamp'> {}

enum TypeEnum {
  INPUT = 0, // 输入
  EXIT, // 输出
  NODE, // 节点内
}

const TypeLabelMap = {
  [TypeEnum.INPUT]: '输入',
  [TypeEnum.EXIT]: '输出',
  [TypeEnum.NODE]: '节点内',
};

const TypeTagColorMap = {
  [TypeEnum.INPUT]: 'info',
  [TypeEnum.EXIT]: 'success',
  [TypeEnum.NODE]: 'warning',
};

type DataItem = Exclude<V2IndicatorProcessDetailPagePostResponse['list'], undefined>[number];

const Quota: FC = () => {
  const staffId = useCurrent()?.staff?.id;
  const routesContext = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm(); // 搜索的表单
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [data, setData] = useState<DataItem[]>([]); // 后端返回数据

  const [uploadState, setUploadState] = useState<boolean>(false);
  const [downLoadTemplateModalOpen, setDownLoadTemplateModalOpen] = useState<boolean>(false);
  const [operationLogModalOpen, setOperationLogModalOpen] = useState<boolean>(false);

  const { data: topologySelectList } = useRequest(() =>
    getTopologySelectList().then(res => {
      if (!res.length) return;
      let emtId = res?.[0]?.id;
      if (emtId) {
        form.setFieldsValue({
          emtId,
        });
        finish({
          ...searchParams,
          emtId,
        });
      }
      return res.map(item => ({ label: item.name, value: item.id }));
    })
  );

  useEffect(() => {
    let isMounted = true;

    const stored = sessionStorage.getItem(`quotaSearchParams${staffId}`);
    let parsedStored: SearchParams | undefined;

    if (stored) {
      parsedStored = JSON.parse(stored);

      if (parsedStored?.emtId) {
        searchParams.emtId = parsedStored.emtId;
        form.setFieldValue('emtId', parsedStored.emtId);
      }
      if (parsedStored?.importAndExportName) {
        searchParams.importAndExportName = parsedStored.importAndExportName;
        form.setFieldValue('importAndExportName', parsedStored.importAndExportName);
      }
    }

    if (searchParams.emtId) {
      setLoading(true);

      apiV2IndicatorProcessDetailPagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      })
        .then(res => {
          if (isMounted) {
            setData(res.list ?? []);
            setTotalCount(res.total ?? 0);
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [setTotalCount, searchParams, staffId, form]);

  const onFinish = (v: SearchParams) => {
    sessionStorage.setItem(`quotaSearchParams${staffId}`, JSON.stringify(v));
    finish({ ...v });
  };
  const onReset = () => {
    let initParams = {
      emtId: topologySelectList?.[0]?.value,
      importAndExportName: undefined,
    };
    sessionStorage.setItem(`quotaSearchParams${staffId}`, JSON.stringify(initParams));
    form.setFieldsValue(initParams);
    reset(initParams);
  };
  const columns = [
    {
      title: '输入/节点内/输出',
      dataIndex: 'importAndExportName',
      width: 190,
      ellipsis: { showTitle: true },
      render: (v: string, record: any) => (
        <EllipsisSpan
          value={
            <>
              <span>{v}</span>
              <Tag border="solid" color={TypeTagColorMap[record.type as TypeEnum]} style={{ marginLeft: 8 }}>
                {TypeLabelMap[record.type as TypeEnum]}
              </Tag>
            </>
          }
        />
      ),
    },
    {
      title: '能源介质',
      dataIndex: 'energyName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属节点',
      dataIndex: 'processName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string, record: any) => {
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '所属拓扑',
      dataIndex: 'emtName',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: undefined, record: any) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      width: 160,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format(FORMAT_TIME)} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 60,
      render: (_: string, record: DataItem) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              navigate(`/quality/monitor/quota/${record.id}/${record.type}`);
            }}
          >
            配置
          </Button>
        </Space>
      ),
    },
  ];
  const currentEmtId = Form.useWatch('emtId', form);
  const hasConfigPers = useHasPermission(PermissionsType.B_INDICATORCONFIG);
  const currentEmtName = useMemo(() => {
    return topologySelectList?.find(item => item.value === currentEmtId)?.label;
  }, [currentEmtId, topologySelectList]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const showModalBtn = () => {
    setModalShow(true);
  };

  const beforeUpload = (file: File) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileType = extName === '.xlsx';
    if (!limitFileType) {
      Modal.warning({
        content: `仅支持上传excel文件`,
      });
      setUploadState(false);
    }
    return limitFileType;
  };

  const handleBatchImport = (uploadFile: UploadChangeParam<UploadFile>) => {
    const { file } = uploadFile;

    if (file.status === 'uploading') {
      setUploadState(true);
    }
    if (file.status === 'error') {
      setUploadState(false);
      Modal.warning({
        content: file.response.errorMessage || '未知错误！ 请联系管理员。',
      });
    }
    if (file.status === 'done') {
      file.response.key && apiV2IndicatorImportPost({ fileKey: file.response.key });
      setUploadState(false);
    }
  };

  return (
    <>
      <Wrapper
        routes={routesContext?.routes}
        className="page_wrapper"
        filters={
          <CustomFilter form={form} onFinish={(v: SearchParams) => onFinish(v)} onReset={onReset}>
            <Form.Item name="importAndExportName" label="输入/节点内/输出">
              <Input allowClear />
            </Form.Item>

            <Form.Item name="emtId" label="所属拓扑" rules={[{ required: true }]}>
              <Select
                placeholder="请选择所属拓扑"
                showSearch
                optionFilterProp="label"
                options={topologySelectList}
                allowClear
              />
            </Form.Item>
          </CustomFilter>
        }
      >
        <div>
          {hasConfigPers && (
            <div style={{ paddingBottom: 10 }}>
              <Space size={8}>
                <Button disabled={!currentEmtId} onClick={showModalBtn} type="primary">
                  配置计量指标
                </Button>
                <Button type="primary" onClick={() => setDownLoadTemplateModalOpen(true)}>
                  下载配置模板
                </Button>

                <Spin spinning={uploadState}>
                  <Upload
                    action="/api/file-center/upload"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    showUploadList={false}
                    beforeUpload={file => beforeUpload(file)}
                    onChange={handleBatchImport}
                  >
                    <Button type="primary">批量导入</Button>
                  </Upload>
                </Spin>

                <Button type="primary" onClick={() => setOperationLogModalOpen(true)}>
                  导入记录
                </Button>
              </Space>
            </div>
          )}

          <Table
            rowKey={record => record.id + '' + record.type}
            sticky
            scroll={{ x: 1300 }}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Wrapper>
      {modalShow && (
        <QuotaSetModal
          emtId={currentEmtId ?? '133'}
          emtName={currentEmtName ?? '-'}
          close={() => setModalShow(false)}
        />
      )}

      {downLoadTemplateModalOpen && <DownLoadTemplateModal close={() => setDownLoadTemplateModalOpen(false)} />}
      {operationLogModalOpen && <OperationLogModal close={() => setOperationLogModalOpen(false)} />}
    </>
  );
};

export default Quota;
