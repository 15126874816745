import React, { useEffect, useState } from 'react';
import { Timeline } from 'antd';
import copy from 'copy-to-clipboard';
import styles from './index.module.scss';
import { Button, Modal, Alert } from '@maxtropy/components';

export interface ErrorMsg {
  code: string;
  name: string;
  msg: string;
}

export interface ErrorMsgModalProps {
  visible: boolean;
  onCancel: () => void;
  errorMsg?: ErrorMsg[];
}

const ErrorMsgModal: React.FC<ErrorMsgModalProps> = ({ visible, onCancel, errorMsg }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopyClick = () => {
    if (errorMsg) {
      const copyText = errorMsg.map(item => `设备编号：${item.code}\n设备名称：${item.name}\n${item.msg}\n`).join('\n');
      copy(copyText);
      setCopied(true);
    }
  };

  useEffect(() => {
    let copyId: number;
    if (copied) {
      copyId = window.setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    return () => clearInterval(copyId);
  }, [copied]);

  return (
    <Modal
      destroyOnClose
      title="错误信息"
      open={visible}
      footer={[
        <Button type="primary" onClick={onCopyClick} className={styles.marginRight}>
          复制错误信息
        </Button>,
        <Button type="primary" onClick={onCancel}>
          确定
        </Button>,
      ]}
      onCancel={onCancel}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <Timeline>
            {errorMsg?.map(item => (
              <Timeline.Item>
                <div>设备编号：{item.code}</div>
                <div>设备名称：{item.name}</div>
                <div>{item.msg}</div>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
        {copied && (
          <div className={styles.alert}>
            <Alert message="复制成功！" type="success" showIcon closable />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ErrorMsgModal;
