import { Modal } from '@maxtropy/components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { Key, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { DragOutlined, MenuOutlined } from '@ant-design/icons';
import {
  apiV2WorkCenterSequencePost,
  apiV2ProductionBaseCompleteTreePost,
  apiV2ProductionBaseSequencePost,
  apiV2WorkCenterSequenceListPost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  open?: boolean;
  ok?: () => void;
  cancel?: () => void;
  contentType?: string;
}

interface CenterItem {
  id: Key;
  name: string;
}

const WorkCenterSortModal: React.FC<Props> = ({ open, cancel, ok, contentType }) => {
  const [data, setData] = useState<CenterItem[]>([]);

  useEffect(() => {
    console.log(contentType, '131');

    if (contentType === 'productionBase') {
      apiV2ProductionBaseCompleteTreePost({}).then(res => {
        const list = (res.list ?? [])?.map((item: any) => ({ id: item.id, name: item.name }));
        setData(list);
      });
    } else {
      apiV2WorkCenterSequenceListPost({}).then(res => {
        const list = (res.list ?? [])?.map((item: any) => ({ id: item.id, name: item.name }));
        setData(list);
      });
    }
  }, [contentType]);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
  };

  const submit = () => {
    if (contentType === 'productionBase') {
      const list = data.map((item, index) => ({ productionBaseId: item.id as number, sequenceNum: index + 1 }));
      apiV2ProductionBaseSequencePost({ list }).then(res => {
        ok?.();
      });
    } else {
      const list = data.map((item, index) => ({ workCenterId: item.id as number, sequenceNum: index + 1 }));
      apiV2WorkCenterSequencePost({ list }).then(res => {
        ok?.();
      });
    }
  };

  return (
    <Modal
      title={contentType === 'productionBase' ? '生产基地排序' : '工作中心排序'}
      open={open}
      onCancel={cancel}
      onOk={submit}
      destroyOnClose
    >
      <div className={styles.wrapper}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="menus" direction="vertical">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map((item: any, index: number) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.draggableProps} className={styles.itemWrapper}>
                        <div {...provided.dragHandleProps} className={styles.item}>
                          <MenuOutlined className={styles.icon} />
                          {item.name}
                          <DragOutlined className={styles.drag} />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Modal>
  );
};

export default WorkCenterSortModal;
