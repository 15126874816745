import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Modal, Space } from 'antd';
import { Table, Button, EllipsisSpan } from '@maxtropy/components';
import { WorkDivisionEnum, WorkDivisionEnumDisplay } from '@/shared/types';
import {
  BaseElectricityBillResponse,
  ElectricityBillAuditLogRequest,
  getElectricityBillAuditLog,
} from '../../../../api/electricity';
import { HasPreferentialAmount, HasPreferentialAmountType } from '../../../../api/electricitySettlementRules';
import { ColumnsType } from 'antd/es/table';

const logDetailCols = [
  {
    title: '字段',
    key: 'name',
    dataIndex: 'name',
    fixed: true,
    width: 120,
    className: `${styles.typeCol}`,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原始数据',
    key: 'originData',
    dataIndex: 'originData',
    width: 200,
    className: `${styles.befCol}`,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '',
    key: 'type',
    dataIndex: 'type',
    bordered: false,
    width: 20,
    className: `${styles.spaceCol}`,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '编辑后数据',
    key: 'currentData',
    dataIndex: 'currentData',
    width: 200,
    className: `${styles.editedCol}`,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface AuditLogProps {
  billInfo?: BaseElectricityBillResponse;
}

export interface ChangeDetail {
  finalDiscountType: HasPreferentialAmountType;
  finalDiscountAmount: string;
}

interface IElectricityBillAuditLogDetail {
  before?: ChangeDetail;
  after?: ChangeDetail;
}

const AuditLog: React.FC<AuditLogProps> = ({ billInfo }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [electricityBillAuditLog, setElectricityBillAuditLog] = useState<ElectricityBillAuditLogRequest[]>([]);
  const [electricityBillAuditLogDetail, setElectricityBillAuditLogDetail] = useState<IElectricityBillAuditLogDetail>();

  const logsColumns: ColumnsType<ElectricityBillAuditLogRequest> = [
    {
      title: '序号',
      key: 'id',
      dataIndex: 'id',
      ellipsis: { showTitle: true },
      render: (v: undefined, record, index) => <EllipsisSpan value={index + 1} />,
    },
    {
      title: '时间',
      key: 'createTime',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
    },
    {
      title: '分工类型',
      key: 'workDivision',
      dataIndex: 'workDivision',
      ellipsis: { showTitle: true },
      render: (v: WorkDivisionEnum) => <EllipsisSpan value={WorkDivisionEnumDisplay[v]} />,
    },
    {
      title: '审单人员姓名',
      dataIndex: 'name',
      key: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作描述',
      key: 'opDesc',
      dataIndex: 'opDesc',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      key: 'modifyContent',
      dataIndex: 'modifyContent',
      ellipsis: { showTitle: true },
      render: (record: string) => {
        return (
          record && (
            <Space>
              <Button
                type="link"
                style={{ border: 0, color: 'var(--primary-color)' }}
                onClick={() => {
                  setElectricityBillAuditLogDetail(JSON.parse(record) as IElectricityBillAuditLogDetail);
                  setIsVisible(true);
                }}
              >
                变更详情查看
              </Button>
            </Space>
          )
        );
      },
    },
  ];

  useEffect(() => {
    if (billInfo) {
      getElectricityBillAuditLog(billInfo?.id).then(res => setElectricityBillAuditLog(res.list || []));
    }
  }, [billInfo]);

  const getDetail = () => {
    if (electricityBillAuditLogDetail) {
      const before = electricityBillAuditLogDetail?.before,
        after = electricityBillAuditLogDetail?.after;
      return [
        {
          key: '优惠模式',
          name: '优惠模式',
          originData: before?.finalDiscountType
            ? `${HasPreferentialAmount[before.finalDiscountType as HasPreferentialAmountType]}`
            : '--',
          currentData: after?.finalDiscountType
            ? `${HasPreferentialAmount[after.finalDiscountType as HasPreferentialAmountType]}`
            : '--',
        },
        {
          key: '优惠金额',
          name: '优惠金额',
          originData:
            before?.finalDiscountAmount !== undefined ? Number(before.finalDiscountAmount).toFixed(2) + ' 元' : '--',
          currentData:
            after?.finalDiscountAmount !== undefined ? Number(after.finalDiscountAmount).toFixed(2) + ' 元' : '--',
        },
      ];
    }
  };

  return (
    <>
      <Table rowKey="id" sticky columns={logsColumns} dataSource={electricityBillAuditLog} />

      <Modal
        title="变更日志详情"
        open={isVisible}
        width={750}
        onCancel={() => setIsVisible(false)}
        className={styles.modal}
        footer={[
          <Button key="back" onClick={() => setIsVisible(false)}>
            关闭
          </Button>,
        ]}
      >
        <Table rowKey={record => record.key} sticky columns={logDetailCols} dataSource={getDetail()} bordered={false} />
      </Modal>
    </>
  );
};

export default AuditLog;
