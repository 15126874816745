import React, { useContext, useEffect, useState } from 'react';
import { Button, DataType, EllipsisSpan, Table } from '@maxtropy/components';
import styles from './index.module.scss';
import FooterTip from '../FooterTip';
import { UnitBasicProps } from '@/api/energyUnitDashboard';
import { Dayjs } from 'dayjs';
import { EnergyUnitContext } from '..';
import { isNil } from 'lodash-es';
import { PhysicalQuantity, PhysicalQuantityUnit } from '@/api/energyConsumption';
import { SorterResult } from 'antd/es/table/interface';
import { TableProps } from 'antd';
import {
  V2EnergyUnitBoardGeneralListPostResponse,
  apiV2EnergyUnitBoardGeneralListPost,
} from '@maxtropy/device-customer-apis-v2';

type RangeValue = [Dayjs | null, Dayjs | null] | null;
interface SubUnitTableProps {
  oneLevelUnits: UnitBasicProps[];
  swiperUnit?: {
    unitId?: number;
    unitName?: string;
    dates: RangeValue;
  };
}

export type EnergyMediumItem = Required<
  Exclude<V2EnergyUnitBoardGeneralListPostResponse['energyMediumItems'], undefined>[number]
>;
export type EnergyUnitGeneralBoardVo = Required<
  Exclude<V2EnergyUnitBoardGeneralListPostResponse['energyUnitGeneralBoardVos'], undefined>[number]
>;

export interface GeneralListProps {
  energyMediumItems: EnergyMediumItem[];
  energyUnitGeneralBoardVos: EnergyUnitGeneralBoardVo[];
}

const SubUnitTable: React.FC<SubUnitTableProps> = ({ oneLevelUnits, swiperUnit }) => {
  const { groupId, setUnitId, setShowDetail } = useContext(EnergyUnitContext);
  const [dataSource, setDataSource] = useState<any>([]);
  const [dataColumns, setDataColumns] = useState<any>([]);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
  const [generalData, setGeneralData] = useState<GeneralListProps>();
  const [loading, setLoading] = useState<boolean>(false);

  let columns: any[] = [
    {
      title: '用能单元',
      dataIndex: 'name',
      width: 150,
      key: 'name',
      fixed: 'left' as const,
    },
    {
      title: '层级',
      width: 120,
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 60,
      fixed: 'right' as const,
      render: (value: undefined, record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setShowDetail?.(true);
              setUnitId?.(record.key);
            }}
          >
            详情
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (isNil(generalData)) return;
    const { energyMediumItems, energyUnitGeneralBoardVos } = generalData;
    const headers = energyMediumItems?.map((m, index) => {
      const mId = m.name === '综合能耗' ? 'total' : m.id;
      const mTitle =
        m.name === '综合能耗'
          ? '综合能耗(kgce)'
          : m.name + `(${PhysicalQuantityUnit[m?.unit as PhysicalQuantity] ?? 'kWh'})`;
      return {
        title: mTitle,
        dataIndex: mId,
        key: mId,
        width: 170,
        sortOrder: sortedInfo.columnKey === m.id ? sortedInfo.order : null,
        sorter: (a: number[], b: number[]) => a[m.id] - b[m.id],
        render: (v: number) => (!isNil(v) ? <EllipsisSpan value={v.toFixed(2)} /> : '--'),
      };
    });
    columns.splice(2, 0, ...headers);
    setDataColumns([...columns]);
    const datas = energyUnitGeneralBoardVos?.map(item => {
      const columnDatas = {} as any;
      headers.forEach((column, index) => {
        columnDatas[column.key] = item.info[index];
      });

      return {
        key: item.initId,
        name: item.energyUnitName,
        level: item.level,
        ...columnDatas,
      };
    });
    setDataSource([...datas]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalData, sortedInfo]);

  useEffect(() => {
    const { unitId, dates } = swiperUnit || {};
    if (isNil(groupId) || isNil(unitId) || !dates || !dates[0] || !dates[1] || oneLevelUnits.length === 0) {
      setDataColumns([...columns]);
      setDataSource([]);
      return;
    }
    if (!oneLevelUnits.some(item => item.energyUnitGroupId === groupId && item.energyUnitId === unitId)) return;
    setLoading(true);
    apiV2EnergyUnitBoardGeneralListPost({
      energyUnitGroupId: String(groupId),
      startDate: dates[0].format('YYYY-MM-DD'),
      endDate: dates[1].format('YYYY-MM-DD'),
      energyUnitId: String(unitId),
    })
      .then(res => {
        setSortedInfo({});
        setGeneralData(res as GeneralListProps);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperUnit]);

  const handleChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<DataType>);
  };

  return (
    <div className={styles.tableBox}>
      <div className={styles.title}>{swiperUnit?.unitName}下级单元用能</div>

      <Table
        loading={loading}
        dataSource={dataSource}
        columns={dataColumns}
        pagination={false}
        scroll={{ y: 'calc(100vh - 500px)', x: 1000 }}
        onChange={handleChange}
      />

      <FooterTip dates={swiperUnit?.dates} />
    </div>
  );
};

export default SubUnitTable;
