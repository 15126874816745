import { UploadOutlined } from '@ant-design/icons';
import { SubContent, Button, Form, InputNumber, DatePicker, Input } from '@maxtropy/components';
import { Col, Row, Space, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/es/upload';
import { FC, Key, useState } from 'react';
import {
  accountRecharge,
  SaleClientAccountRechargeRequest,
  TransactionType,
  VoucherFileRequest,
} from '../../../../api/sale';
import dayjs, { Dayjs } from 'dayjs';
import {
  clentAccountFileUplodaMaxCount,
  generateLimitFileSize,
  normFile,
  timeFormatString,
  useUploadMaxDisabled,
} from '../../util';

export interface RechargeFormRequest
  extends Omit<SaleClientAccountRechargeRequest, 'files' | 'type' | 'specifiedDate'> {
  specifiedDate: Dayjs;
  files: RcFile[];
}

export interface RechargeProps {
  saleClientAccountId: Key;
  goList?: () => void;
}

const Recharge: FC<RechargeProps> = ({ saleClientAccountId, goList }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [uploadDisabled, uploadChange] = useUploadMaxDisabled(clentAccountFileUplodaMaxCount);

  const onFinish = (value: RechargeFormRequest) => {
    if (saleClientAccountId !== undefined) {
      const files: VoucherFileRequest[] = value.files.map((i: UploadFile) => ({
        fileKey: i.response.key,
        fileName: i.name,
      }));
      setLoading(true);
      accountRecharge(saleClientAccountId, {
        ...value,
        specifiedDate: dayjs(value.specifiedDate).format(timeFormatString),
        type: TransactionType.RECHARGE,
        files,
      })
        .then(() => {
          goList?.();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <SubContent title="收款凭证">
      <Form
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError={{
          block: 'center',
          behavior: 'smooth',
        }}
      >
        <Form.Item label="充值金额" name="amount" rules={[{ required: true, message: '请输入充值金额' }]}>
          <InputNumber style={{ width: 500 }} min={0} precision={2} addonAfter="￥" placeholder="请输入" />
        </Form.Item>
        <Form.Item label="收款日期" name="specifiedDate" rules={[{ required: true, message: '请选择收款日期' }]}>
          <DatePicker style={{ width: 500 }} showTime placeholder="请选择" />
        </Form.Item>

        <Form.Item
          label="凭证文件"
          name="files"
          rules={[{ required: true, message: '请选择凭证文件' }]}
          style={{ width: 500 }}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra={
            <Row>
              <Col span={24}>支持扩展名：.doc .docx .pdf .jpg .png .jpeg</Col>
              <Col span={24}>单个文件大小不超过5MB，最多上传十个文件。</Col>
            </Row>
          }
        >
          <Upload
            action="/api/file-center/upload"
            accept=".doc,.docx,.pdf,.jpg,.png,.jpeg"
            multiple
            maxCount={clentAccountFileUplodaMaxCount}
            beforeUpload={generateLimitFileSize(5)}
            onChange={uploadChange}
          >
            <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
              请选择
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="凭证说明" name="description">
          <Input.TextArea style={{ width: 500 }} placeholder="请输入" maxLength={200} />
        </Form.Item>
        <Space size={8} className="sticky-footer" style={{ paddingLeft: 0 }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            保存
          </Button>
          <Button onClick={goList}>取消</Button>
        </Space>
      </Form>
    </SubContent>
  );
};

export default Recharge;
