export enum CardConfigEnum {
  // 1 平台数据
  PLATFORM_DATA = 1,
  // 2 不配置
  NOT_CONFIGURED = 2,
}

export interface StaffListType {
  id?: number;
  /**
   * staffID
   */
  staffId?: number | null | undefined;
  /**
   * staffCode
   */
  staffCode?: string;
  /**
   * 姓名
   */
  customerName?: string;
  /**
   * 账号
   */
  customerAccount?: string;
  /**
   * 光伏卡片设置 1平台数据 2不配置
   */
  pvSourceType?: number;
  /**
   * 储能卡片设置 1平台数据 2不配置
   */
  storedEnergySourceType?: number;
  /**
   * 充电卡片设置 1平台数据 2不配置
   */
  chargeSourceType?: number;
  /**
   * 精益卡片设置 1平台数据 2不配置
   */
  leanSourceType?: number;
  /**
   * 基础能管卡片设置 1平台数据 2不配置
   */
  basicTubeSourceType?: number;
}
