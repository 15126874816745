import { useState } from 'react';
import { Form, Row, Col } from 'antd';
import { Modal, Button, Upload, getRealUrl, message } from '@maxtropy/components';
import { apiV2OutputProductBomImportPost } from '@maxtropy/device-customer-apis-v2';
import { EditBomData } from './BomTable';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type Props = {
  id: number;
  data: EditBomData;
  setVisible: (visible: boolean) => void;
  updateFn: () => void;
};

const ImportBom = ({ data, id, setVisible, updateFn }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = () => {
    const { fileKey } = form.getFieldsValue();
    setLoading(true);
    apiV2OutputProductBomImportPost({ fileKey, id })
      .then(res => {
        if (res?.fileKey) {
          Modal.error({
            title: '导入失败',
            content: (
              <div className={styles.importErrorTxt}>
                <span> 请现在下载错误信息,修改后重新导入。</span>
                <Button type="link" icon={<DownloadOutlined />} href={getRealUrl(res.fileKey)}>
                  下载错误信息
                </Button>
              </div>
            ),
            okText: '确定',
          });
          return;
        }

        message.success('导入成功');
        updateFn();
        setVisible(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title="导入BOM"
      open={true}
      contentClassName="modal-form-content"
      onCancel={() => setVisible(false)}
      onOk={() => {
        form.submit();
      }}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" className={styles.importBom}>
        <Row>
          <Col span={12}>
            <Form.Item label="产出物编码">{data.code ?? '--'}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="产出物名称">{data.name ?? '--'}</Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="规格型号">{data.spec ?? '--'}</Form.Item>
          </Col>
          <span className={styles.tip}>
            <InfoCircleOutlined />
            本产品未维护BOM，请导入BOM
            <Button type="link" size="small" href="/api/v2/outputProductBom/template/download">
              下载模版
            </Button>
          </span>
          <Col span={24}>
            <Form.Item rules={[{ required: true, message: '请先上传文件' }]} name="fileKey">
              <Upload
                listType="text"
                accept={['.xls', '.xlsx']}
                uploadText="上传BOM"
                fileSize={5}
                tip="仅支持.xls、.xlsx，大小不超过5M"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ImportBom;
