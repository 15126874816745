import { Empty, Tag } from '@maxtropy/components';
import {
  apiV2EnergyCompareProcessListPost,
  V2EnergyCompareProcessListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Space, Spin } from 'antd';
import React, { FC, Key, useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { EnergyWorkingProcessType, EnergyWorkingProcessTypeLabel, EnergyWorkingProcessTypeLabelColor } from '@/api/uet';

export interface IViewEnergyMediumList {
  energyMediumTopoId?: number;
  processIdRef?: React.Key;
  getProcessId?: (key: Key) => void;
  getProcessType?: (type: EnergyWorkingProcessType) => void;
  getProcessName?: (name: string) => void;
}

export type EnergyCompareProcessListPostResponseItem = Exclude<
  V2EnergyCompareProcessListPostResponse['list'],
  undefined
>[number];

const ViewEnergyMediumList: FC<IViewEnergyMediumList> = props => {
  const { energyMediumTopoId, getProcessId, getProcessType, getProcessName, processIdRef } = props;
  const [energyMediumProcessTopList, setEnergyMediumProcessTopList] =
    useState<EnergyCompareProcessListPostResponseItem[]>();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [selectedProcessId, setSelectedProcessId] = useState<number>();

  useEffect(() => {
    if (energyMediumTopoId) {
      setListLoading(true);
      setSelectedProcessId(undefined);
      apiV2EnergyCompareProcessListPost({ id: String(energyMediumTopoId) }).then(res => {
        setEnergyMediumProcessTopList(res.list || []);
        setListLoading(false);
        if (res.list && res.list.length > 0) {
          if (processIdRef) {
            setSelectedProcessId(Number(processIdRef));
            getProcessId?.(Number(processIdRef));
            getProcessType?.(res.list.find(item => item.id === Number(processIdRef))?.type as EnergyWorkingProcessType);
            getProcessName?.(res.list.find(item => item.id === Number(processIdRef))?.name!);
          } else {
            setSelectedProcessId(res.list[0].id);
            getProcessId?.(res.list[0].id as Key);
            getProcessType?.(res.list[0].type as EnergyWorkingProcessType);
            getProcessName?.(res.list[0].name!);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumTopoId]);

  const isSelectedSty = useCallback(
    (process: EnergyCompareProcessListPostResponseItem) => {
      if ((process.type as EnergyWorkingProcessType) === EnergyWorkingProcessType.STORAGE) {
        return styles.disabledSty;
      } else {
        return selectedProcessId === process.id ? styles.titleButtonChecked : styles.titleButtonUnChecked;
      }
    },
    [selectedProcessId]
  );

  return (
    <Spin spinning={listLoading}>
      {energyMediumProcessTopList && energyMediumProcessTopList.length ? (
        <Space className={styles.spaceContent} size="small" wrap>
          {energyMediumProcessTopList.map(process => (
            <div
              key={process.id}
              onClick={() => {
                setSelectedProcessId(process.id);
                getProcessId?.(process.id!);
                getProcessType?.(process.type!);
                getProcessName?.(process.name!);
              }}
              className={`${styles.btnSty} ${isSelectedSty(process)}`}
            >
              <div className={styles.btnContent}>
                <div className={styles.btnNameSty}>{process.name ?? '--'}</div>
                <div>
                  <Tag
                    className={styles.tagContent}
                    color={EnergyWorkingProcessTypeLabelColor[process.type as EnergyWorkingProcessType]}
                  >
                    <span style={{ color: '#fff' }}>
                      {EnergyWorkingProcessTypeLabel[process.type as EnergyWorkingProcessType]}
                    </span>
                  </Tag>
                </div>
              </div>
            </div>
          ))}
        </Space>
      ) : (
        <Empty style={{ marginTop: 60 }} />
      )}
    </Spin>
  );
};

export default ViewEnergyMediumList;
