import EnergyContent from '../EnergyContent';
import { Space } from 'antd';
import styles from './index.module.scss';
import { DoubleRightOutlined } from '@ant-design/icons';
import cube from '../../assets/cube.png';
import cubeCircle from '../../assets/cubeCircle.png';
import EnergyStatisticsSwiper from '../EnergyStatisticsSwiper';
import { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { useRequest } from 'ahooks';
import { Button, DatePicker, Key } from '@maxtropy/components';
import qs from 'qs';
import { pollingInterval } from '../../utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { apiV2EnergyConsumptionOverviewTotalCostPost } from '@maxtropy/device-customer-apis-v2';
import { PieCostItem } from '../EnergyCost';
const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const timeFormat00 = 'YYYY-MM-DD 00:00:00';
const timeFormatCurrent = 'YYYY-MM-DD HH:mm:ss';
const timeFormat59 = 'YYYY-MM-DD 23:59:59';

interface EnergyStatisticsProps {
  configId?: Key;
  outerRef: HTMLDivElement;
}

const formatDataColor = (data: any) => {
  return isNil(data) ? (
    <p style={{ color: 'rgba(255,255,255,0.85)' }}>--</p>
  ) : data === 0 ? (
    <p style={{ color: 'rgba(255,255,255,0.85)' }}> {data}% </p>
  ) : data > 0 ? (
    <p style={{ color: '#FF4D4F' }}> +{data}% </p>
  ) : (
    <p style={{ color: '#59D744' }}> {data}% </p>
  );
};

const EnergyStatistics: React.FC<EnergyStatisticsProps> = ({ configId, outerRef }) => {
  const [dates, setDates] = useState<RangeValue | null>([dayjs(), dayjs()]);
  const [value, setValue] = useState<RangeValue>([dayjs(), dayjs()]);
  const hasEnergtConsumDetail = useHasPermission(PermissionsType.B_ENERGYCONSUMDETAIL);

  const { data: totalCost } = useRequest(
    () => {
      if (configId && value && value[0] && value[1]) {
        let startDate = value[0].format(timeFormat00);
        let endDate = value[1].isToday() ? dayjs().format(timeFormatCurrent) : value[1].format(timeFormat59);
        return apiV2EnergyConsumptionOverviewTotalCostPost({
          energyOverviewConfigId: configId,
          fromTime: startDate,
          toTime: endDate,
        });
      }
      return Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId, value],
    }
  );

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 31;
    return current > dayjs().endOf('day') || !!tooEarly || !!tooLate;
  };

  useEffect(() => {
    setDates(value);
  }, [value]);

  return (
    <div>
      <EnergyContent
        title="用能统计"
        extraContent={
          <Space size={0}>
            <RangePicker
              value={value}
              className={styles.rangePicker}
              disabledDate={disabledDate}
              onCalendarChange={val => {
                setDates([val[0], null]);
              }}
              onOpenChange={open => {
                if (open) {
                  setDates([null, null]);
                }
              }}
              onChange={val => {
                setValue(val);
              }}
              allowClear={false}
              getPopupContainer={() => outerRef}
            />

            <Button
              type="link"
              onClick={() => {
                setDates([dayjs(), dayjs()]);
                setValue([dayjs(), dayjs()]);
              }}
              style={{
                padding: '0 0 0 8px',
              }}
            >
              当日
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDates([dayjs().startOf('M'), dayjs()]);
                setValue([dayjs().startOf('M'), dayjs()]);
              }}
              style={{
                padding: '0 0 0 2px',
              }}
            >
              本月
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDates([dayjs().subtract(1, 'month').startOf('M'), dayjs().subtract(1, 'month').endOf('M')]);
                setValue([dayjs().subtract(1, 'month').startOf('M'), dayjs().subtract(1, 'month').endOf('M')]);
              }}
              style={{
                padding: '0 2px',
              }}
            >
              上月
            </Button>
            {hasEnergtConsumDetail && (
              <Button
                type="link"
                style={{ padding: '0' }}
                onClick={() => {
                  if (totalCost && totalCost.energyGroupId && value && value[0] && value[1]) {
                    window.open(
                      `/energy/unit/kanban?${qs.stringify(
                        {
                          energyGroupId: totalCost.energyGroupId,
                          startDate: value[0].format(timeFormatCurrent),
                          endDate: value[1].format(timeFormatCurrent),
                        },
                        {
                          indices: false,
                        }
                      )}`,
                      '_blank'
                    );
                  } else {
                    window.open(`/energy/unit/kanban`, '_blank');
                  }
                }}
              >
                查看详情
                <DoubleRightOutlined className={styles.translationStyles} />
              </Button>
            )}
          </Space>
        }
      >
        <div className={styles.centerInfo}>
          <div className={styles.center_left_Info}>
            <div className={styles.center_left_img}>
              <img src={cubeCircle} alt="pic" />
              <img src={cube} alt="pic" />
            </div>
            <div className={styles.center_left_word}>
              <p className={styles.firstP}>用能总量 ({(totalCost?.totalValue ?? 0) > 10000 ? 'tce' : 'kgce'})</p>
              <p className={styles.secondP}>
                {totalCost
                  ? isNil(totalCost.totalValue)
                    ? '--'
                    : Number(totalCost.totalValue) > 10000
                    ? (Number(totalCost.totalValue) / 1000).toFixed(2)
                    : Number(totalCost.totalValue).toFixed(2)
                  : '--'}
              </p>
            </div>
          </div>
          <div className={styles.center_right_Info}>
            <Space size={32}>
              <Space size={16}>
                <div className={styles.center_right_line} />
                <div className={styles.center_right_word}>
                  <p style={{ color: 'rgba(255,255,255,0.85)' }}>上年同比</p>
                  {totalCost ? (
                    formatDataColor(totalCost.compare)
                  ) : (
                    <p style={{ color: 'rgba(255,255,255,0.85)' }}>--</p>
                  )}
                </div>
              </Space>
              <Space size={16}>
                <div className={styles.center_right_line} />
                <div className={styles.center_right_word}>
                  <p style={{ color: 'rgba(255,255,255,0.85)' }}>环比</p>
                  {totalCost ? (
                    formatDataColor(totalCost.circleCompare)
                  ) : (
                    <p style={{ color: 'rgba(255,255,255,0.85)' }}>--</p>
                  )}
                </div>
              </Space>
            </Space>
          </div>
        </div>
        <div>
          <EnergyStatisticsSwiper detail={totalCost ? ((totalCost.details ?? []) as PieCostItem[]) : []} />
        </div>
      </EnergyContent>
    </div>
  );
};

export default EnergyStatistics;
