import {
  EllipsisSpan,
  Paging,
  Table,
  useUpdate,
  Button,
  useSearchParams,
  Form,
  Input,
  Select,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import type { ColumnType } from 'antd/es/table';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHasPermission } from '../../../utils/utils';
import styles from '../utils.module.scss';
import { PermissionsType } from '../../../common/permissionsConst';
import { UetEnergyMediumSceneListResponse, getUetEnergyMediumSceneList } from '@/api/uet';
import { ActionType } from '../utils';
import CreateForm from './CreateForm';
import {
  WorkingSubstanceAccountPageRequest,
  WorkingSubstanceAccountPageRes,
  getWorkingSubstanceAccountPage,
} from '@/api/workingSubstance';

export interface SearchParams extends Omit<WorkingSubstanceAccountPageRequest, 'page' | 'size'> {}

const columns: Array<ColumnType<WorkingSubstanceAccountPageRes>> = [
  {
    title: '工质账户户号',
    dataIndex: 'accountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联组织',
    dataIndex: 'mcidListStr',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '能源介质场景',
    dataIndex: 'energyMediumSceneName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const WorkingSubstance: FC = () => {
  const hasB_INCREASEMEDIUMACCOUNT = useHasPermission(PermissionsType.B_INCREASEMEDIUMACCOUNT); // 新增工质账户
  const hasB_MODIFYMEDIUMACCOUNT = useHasPermission(PermissionsType.B_MODIFYMEDIUMACCOUNT); // 编辑工质账户
  const hasB_VIEWMEDIUMACCOUNT = useHasPermission(PermissionsType.B_VIEWMEDIUMACCOUNT); // 查看工质账户

  const [searchForm] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [sceneList, setSceneList] = useState<UetEnergyMediumSceneListResponse[]>([]); // 获取能源介质场景列表
  const [showModal, setShowModal] = useState<ActionType | undefined>();
  const [data, setData] = useState<WorkingSubstanceAccountPageRes[]>([]);
  const [row, setRow] = useState<WorkingSubstanceAccountPageRes>();

  useEffect(() => {
    // 获取能源 / 工质场景列表
    getUetEnergyMediumSceneList().then(setSceneList);
  }, []);

  const sceneOptions = useMemo(() => {
    if (sceneList && sceneList.length > 0) {
      return sceneList.map(i => ({ label: i.sceneName, value: i.id }));
    }
  }, [sceneList]);

  useEffect(() => {
    setLoading(true);
    getWorkingSubstanceAccountPage({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    finish({ ...val });
  };
  const onReset = () => {
    reset();
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 180,
      render: (_: any, record: WorkingSubstanceAccountPageRes) => (
        <Space size={16}>
          {hasB_MODIFYMEDIUMACCOUNT && (
            <Button
              type="link"
              onClick={() => {
                setRow(record);
                setShowModal(ActionType.UPDATE);
              }}
            >
              编辑
            </Button>
          )}
          {hasB_VIEWMEDIUMACCOUNT && (
            <Button
              type="link"
              onClick={() => {
                setRow(record);
                setShowModal(ActionType.DETAIL);
              }}
            >
              查看
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className={styles.filterWrapper}>
        <CustomFilter form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
          <Form.Item label="工质账户户号" name="accountNumber">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="能源介质场景" name="energyMediumSceneId">
            <Select placeholder="请选择" options={sceneOptions} />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        {hasB_INCREASEMEDIUMACCOUNT && (
          <div className={styles.operationArea}>
            <Button
              type="primary"
              onClick={() => {
                setRow(undefined);
                setShowModal(ActionType.CREATE);
              }}
            >
              新增工质账户
            </Button>
          </div>
        )}
        <Table sticky scroll={{ x: 1300 }} loading={loading} columns={buildColumns} rowKey="id" dataSource={data} />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <CreateForm row={row} showModal={showModal} setShowModal={setShowModal} updateFn={updateFn} />
    </>
  );
};

export default WorkingSubstance;
