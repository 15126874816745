import {
  Button,
  EllipsisSpan,
  Key,
  Paging,
  Table,
  Wrapper,
  Form,
  Input,
  PopConfirm,
  Select,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import AddTaskModal from './AddTaskModal';
import {
  CarbonTaskPageQuery,
  CarbonTaskPageRes,
  CarbonTaskState,
  CarbonTaskStateDisplay,
  getCarbonTaskPage,
  updateCarbonTaskState,
} from '@/api/CarbonDischargeTask';
import { isNil } from 'lodash-es';
import { AccountUnitListItem, getAccountUnitList } from '@/api/CarbonAccountUnits';
import { PlusOutlined } from '@ant-design/icons';

const columns = [
  {
    title: '任务名称',
    dataIndex: 'taskName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属核算单元',
    dataIndex: 'accountingUnitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '盘查周期',
    dataIndex: 'inventoryYear',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v} />,
  },
  {
    title: '进度',
    dataIndex: 'inventoryNum',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={`${v}/12`} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: CarbonTaskState) => <EllipsisSpan value={CarbonTaskStateDisplay[v]} />,
  },
];

interface SearchParams extends Omit<CarbonTaskPageQuery, 'page' | 'size'> {}

const CarbonDischargeTask: React.FC = () => {
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tableData, setTableData] = useState<CarbonTaskPageRes[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<Key>();
  const [update, updateFn] = useUpdate();
  const [accountUnitList, setAccountUnitList] = useState<AccountUnitListItem[]>([]);

  // 获取核算单元列表
  useEffect(() => {
    getAccountUnitList().then(setAccountUnitList);
  }, [update]);

  useEffect(() => {
    setLoading(true);
    getCarbonTaskPage({
      taskName: !isNil(searchParams.taskName) && searchParams.taskName !== '' ? searchParams.taskName : undefined,
      accountingUnitId: !isNil(searchParams.accountingUnitId)
        ? searchParams.accountingUnitId === 'allUnitIds'
          ? undefined
          : searchParams.accountingUnitId
        : undefined,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setTableData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update]);

  const accountUnitOptions = useMemo(() => {
    return (accountUnitList ?? []).map(i => ({ label: i.unitName, value: i.id }));
  }, [accountUnitList]);

  const onReset = () => {
    reset();
  };

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      width: 160,
      fixed: 'right' as 'right',
      render: (_: any, record: CarbonTaskPageRes) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setTaskId(record.id);
                setOpen(true);
              }}
            >
              编辑
            </Button>
            {record.state === CarbonTaskState.FILLING ? (
              <PopConfirm
                title="是否结束此盘查任务的填报？"
                onConfirm={() => {
                  updateCarbonTaskState(record.id, CarbonTaskState.FINISHED).then(() => {
                    updateFn();
                  });
                }}
              >
                <Button type="link">结束填报</Button>
              </PopConfirm>
            ) : (
              <PopConfirm
                title="是否重新开放此盘查任务的填报？"
                onConfirm={() => {
                  updateCarbonTaskState(record.id, CarbonTaskState.FILLING).then(() => {
                    updateFn();
                  });
                }}
              >
                <Button type="link">开放填报</Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter
      form={form}
      onReset={onReset}
      onFinish={(v: SearchParams) => {
        onFinish(v);
      }}
    >
      <Form.Item label="任务名称" name="taskName">
        <Input placeholder="请输入任务名称" />
      </Form.Item>
      <Form.Item label="所属核算单元" name="accountingUnitId">
        <Select
          placeholder="请选择核算单元"
          options={
            accountUnitOptions.length > 0
              ? [{ label: '全部核算单元', value: 'allUnitIds' }, ...accountUnitOptions]
              : accountUnitOptions
          }
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <Wrapper className={styles.wrapperStyle} filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <div style={{ marginBottom: 10 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setOpen(true);
          }}
        >
          新建盘查任务
        </Button>
      </div>
      <Table columns={buildColumns} rowKey="id" loading={loading} dataSource={tableData} />
      <Paging pagingInfo={pagingInfo} />
      {open && <AddTaskModal open={open} setOpen={setOpen} id={taskId} setId={setTaskId} updateFn={updateFn} />}
    </Wrapper>
  );
};

export default CarbonDischargeTask;
