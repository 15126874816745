import { PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useTenantPermissions,
  Wrapper,
  Button,
  useAsync,
  Tooltip,
  Form,
  Input,
  Select,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

import { useQuery } from '@/shared/utils/utils';
import { deleteFcs, FcsListProps, getFcsList } from '../../../api/fcs';
import { GatewayNetStatus, GatewayNetStatusDisplay, GatewayStatus, IotProtocolType } from '@/shared/types';
import dayjs from 'dayjs';
import { applyGateway } from '../../../api/gateway';
import Operation from './components/Operation';
import ApplyLogModal from './components/ApplyLogModal';
import { PermissionsType } from '../../../common/permissionsConst';

import { getOuListOwned } from '@/api/ou';
import { DistributeStatus } from '@/api/netWorking';
import qs from 'qs';

// const routes = [
//   {name: 'Iot配置'},
//   {name: 'FCS管理'},
//   {name: 'FCS列表'},
// ];

const columns = [
  {
    title: 'FCS编号',
    dataIndex: 'serialNumber',
    // width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'FCS名称',
    dataIndex: 'name',
    // width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联运营单元',
    // width: 150,
    ellipsis: { showTitle: true },
    render: (v: FcsListProps) => <EllipsisSpan value={v.ouVoV2.name} />,
  },
  {
    title: '软件版本',
    // width: 100,
    ellipsis: { showTitle: true },
    render: (v: FcsListProps) => <EllipsisSpan value={v.softwareVersion} />,
  },
  {
    title: '关联网关',
    // width: 150,
    ellipsis: { showTitle: true },
    render: (v: FcsListProps) => <EllipsisSpan value={v.edgeGatewayAbstractVo.name} />,
  },
  {
    title: '所在组网',
    // width: 150,
    ellipsis: { showTitle: true },
    // render: (v: FcsListProps) => <EllipsisSpan value={v.networkName} />,
    render: (v: string, record: FcsListProps) => {
      return (
        <Button
          type="link"
          onClick={() => {
            if (record.edgeGatewayAbstractVo.edgeNetwork?.netName) {
              const params = {
                netNo: record.edgeGatewayAbstractVo.edgeNetwork?.netNo,
              };
              window.open(`/device/networking/networkingList?${qs.stringify(params)}`);
            }
          }}
        >
          {record.edgeGatewayAbstractVo.edgeNetwork?.netName ? (
            record.edgeGatewayAbstractVo.edgeNetwork?.distributeStatus === DistributeStatus.NOTSTART ? (
              <Tooltip title={record.edgeGatewayAbstractVo.edgeNetwork.netName}>
                <span style={{ color: '#f00' }}>{record.edgeGatewayAbstractVo.edgeNetwork.netName}</span>
              </Tooltip>
            ) : (
              <Tooltip title={record.edgeGatewayAbstractVo.edgeNetwork?.netName}>
                {record.edgeGatewayAbstractVo.edgeNetwork?.netName}
              </Tooltip>
            )
          ) : (
            '--'
          )}
        </Button>
      );
    },
  },
  {
    title: '状态',
    // width: 100,
    ellipsis: { showTitle: true },
    render: (v: FcsListProps) => <EllipsisSpan value={GatewayNetStatusDisplay[v.edgeGatewayAbstractVo.netState]} />,
  },
  {
    title: '最后操作时间',
    // width: 200,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    // width: 150,
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParamsProps {
  nameOrCode?: string;
  status?: GatewayStatus;
}

const FcsList: React.FC = () => {
  const [form] = Form.useForm();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let serialNumber = urlSearchParams.get('serialNumber') || undefined;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>({ nameOrCode: serialNumber });
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<FcsListProps>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const permission = useTenantPermissions();
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const ou = useAsync(getOuListOwned, []);

  useEffect(() => {
    if (serialNumber) {
      form.setFieldsValue({
        nameOrCode: serialNumber,
      });
    }
  }, [serialNumber, form]);
  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getFcsList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const filters = (
    <CustomFilter<SearchParamsProps>
      form={form}
      onFinish={v => {
        setSearchParams(v);
        setPageOffset(1);
      }}
      onReset={() => {
        setSearchParams({});
        setPageOffset(1);
      }}
    >
      <Form.Item name="nameOrCode" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>

      <Form.Item name="status" label="状态">
        <Select placeholder="请选择">
          <Select.Option value={GatewayNetStatus.ONLINE}>在线</Select.Option>
          <Select.Option value={GatewayNetStatus.OFFLINE}>离线</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="edgeGatewayName" label="网关名称">
        <Input placeholder="请输入网关名称" />
      </Form.Item>

      <Form.Item name="ouId" label="运营单元">
        <Select
          style={{ width: '100%' }}
          placeholder="请选择"
          options={ouOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
    </CustomFilter>
  );

  const deleteAction = (id: number) => {
    Modal.confirm({
      title: '删除FCS不可恢复，会自动解绑网关。',
      content: '你还要继续吗',
      onOk: () => {
        deleteFcs(id).then(res => {
          if (res) {
            setSearchParams({ ...searchParams });
          }
        });
      },
    });
  };

  // 配置下发
  const onApply = (id: number) => {
    return applyGateway(id).then(res => {
      if (res?.ret) {
        Modal.warning({
          content: res.ret,
        });
      } else {
        Modal.success({
          content: '配置下发成功',
        });
        setSearchParams({ ...searchParams });
      }
    });
  };

  const actionColumns = {
    title: '操作',
    width: 420,
    fixed: 'right' as const,
    render: (v: FcsListProps, record: FcsListProps) => (
      <>
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.P_DELETEFCS) && (
            <Button type="link" onClick={() => deleteAction(v.id)}>
              删除
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_UPDATEFCS) && (
            <Button type="link">
              <Link to={`/device/fcs/list/edit/${v.id}`}>编辑</Link>
            </Button>
          )}
          <Button type="link">
            <Link to={`/device/fcs/list/detail/${v.id}`}>查看</Link>
          </Button>
          {/* {(permission ?? []).includes(PermissionsType.B_UPDATEFCS) && (
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/device/fcs/list/edit/${v.id}`}>日志</Link>
            </Button>
          )} */}
          {v.edgeGatewayAbstractVo.iotProtocol === IotProtocolType.MOCKINGBIRD && (
            <>
              {(permission ?? []).includes(PermissionsType.B_EDGEDEVICECONFIGPUBLISH) && (
                <Operation record={v} applyGateway={onApply} />
              )}
              {(permission ?? []).includes(PermissionsType.B_EDGEDEVICECONFIGPUBLISHRECORD) && (
                <Button
                  type="link"
                  onClick={() => {
                    setCurrent(v);
                    setVisible(true);
                  }}
                >
                  数采下发记录
                </Button>
              )}
            </>
          )}
          {(permission ?? []).includes(PermissionsType.B_PASSCODEBUTTON) && (
            <Button type="link">
              <Link to={`/device/fcs/list/lock/${v.id}`}>就地锁屏密码</Link>
            </Button>
          )}
        </Space>
      </>
    ),
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className="page_wrapper">
      {(permission ?? []).includes(PermissionsType.B_CREATEFCS) && (
        <div className={styles.operationArea}>
          <Button type="primary" icon={<PlusOutlined />}>
            <Link to={`/device/fcs/list/create`}>新建</Link>
          </Button>
        </div>
      )}
      <Table
        sticky
        scroll={{ x: 1900 }}
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        columns={[...columns, actionColumns]}
      />
      <Paging pagingInfo={pagingInfo} />
      <ApplyLogModal
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setCurrent(undefined);
        }}
        id={current?.gatewayId}
      />
    </Wrapper>
  );
};

export default FcsList;
