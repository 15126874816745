import React, { useRef, useState, useEffect, useContext, useMemo } from 'react';
import { Radio, DatePicker } from '@maxtropy/components';
import { DatePickerProps, FormInstance, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ReactEcharts from 'echarts-for-react';
import ResizeObserver from 'rc-resize-observer';
import {
  apiV2CassandraGetInstantaneousValueListPost,
  V2CassandraGetInstantaneousValueListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { ProductionBeatConfigContext } from '../..';
import { StatusType, TaktMode, TimeGranularityType } from '../../utils';
import { isEmpty } from 'lodash-es';

export interface LoadCurveProp {
  quotaType?: number;
  deviceId?: number;
  physicalUnitName?: string;
  hasBrush?: boolean;
}

const StateColors = {
  // 停机
  [StatusType.STOP]: 'rgba(250,173,20,0.2)',
  // 待机
  [StatusType.WAIT]: 'rgba(93,112,146,0.4)',
  // 运行
  [StatusType.RUN]: 'transparent',
};

const setTemplateDateFunc = (
  form?: FormInstance<any>,
  setTemplateDate?: (values: Dayjs[]) => void,
  data?: V2CassandraGetInstantaneousValueListPostResponse['list']
) => {
  const effectiveValues = data?.filter(i => !!i.value);
  form?.setFieldValue(['waveformRequest', 'templateStartTime'], undefined);
  form?.setFieldValue(['waveformRequest', 'templateEndTime'], undefined);
  setTemplateDate?.([]);
  if (effectiveValues && !isEmpty(effectiveValues)) {
    const templateStartDate = effectiveValues[Math.floor(effectiveValues?.length / 2)].ts;
    const templateEndDate = effectiveValues[effectiveValues?.length - 1].ts;
    setTemplateDate?.([dayjs(templateStartDate), dayjs(templateEndDate)]);
  }
};

const LoadCurve: React.FC<LoadCurveProp> = ({ deviceId, quotaType, physicalUnitName, hasBrush }) => {
  const chartRef = useRef<ReactEcharts>();
  const [data, setData] = useState<V2CassandraGetInstantaneousValueListPostResponse['list']>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initDate, setInitDate] = useState<boolean>(false);

  const {
    statusList,
    timeResolution,
    date,
    setDate,
    form,
    templateDate,
    setTemplateDate,
    type,
    setInitTemplateDate,
    initTemplateDate,
  } = useContext(ProductionBeatConfigContext);

  useEffect(() => {
    if (deviceId && quotaType && date) {
      setLoading(true);
      apiV2CassandraGetInstantaneousValueListPost({
        resolution: timeResolution,
        dataPropertyId: quotaType,
        deviceId,
        startTime: date?.[0]?.startOf('date').valueOf(),
        endTime: date?.[1]?.endOf('date').valueOf(),
      })
        .then(res => {
          setData(res.list ?? []);
          setInitTemplateDate?.(true);
          if ((!initTemplateDate && isEmpty(date)) || initTemplateDate) {
            // 每次请求后，清除上次选中的范围，在value有值的情况下，随机给到一组范围
            setTemplateDateFunc(form, setTemplateDate, res?.list);
          }
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, quotaType, date]);

  useEffect(() => {
    if (type === TaktMode.FOLLOW_WAVEFORM) {
      setTemplateDateFunc(form, setTemplateDate, data);
    }
  }, [type]);

  useEffect(() => {
    setInitDate(true);

    if (!isEmpty(date) && !initDate) return;

    const current = dayjs(); // 当天
    const before15 = dayjs().subtract(14, 'day'); // 近15天
    const before31 = dayjs().subtract(30, 'day'); // 近31天
    const curDate =
      timeResolution === TimeGranularityType.MINUTE
        ? [current, current]
        : timeResolution === TimeGranularityType.HOUR
        ? [before15, current]
        : [before31, current];

    setDate?.(curDate);
  }, [timeResolution]);

  const brushAction = () => {
    const Echarts = chartRef.current?.getEchartsInstance();
    if (hasBrush && Echarts) {
      Echarts?.dispatchAction(
        {
          type: 'brush',
          areas: [
            {
              brushType: 'lineX',
              brushMode: 'single',
              coordRange: templateDate?.map(i => dayjs(i).valueOf()) || [],
              xAxisIndex: 0,
            },
          ],
        },
        true
      );
    }
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (from) {
      return timeResolution === TimeGranularityType.MINUTE
        ? Math.abs(current.diff(from, 'days')) >= 3
        : timeResolution === TimeGranularityType.HOUR
        ? Math.abs(current.diff(from, 'days')) >= 15
        : Math.abs(current.diff(from, 'days')) >= 31;
    }

    return false;
  };

  const getChartOption = () => {
    setTimeout(() => {
      brushAction();
    }, 500);
    return {
      grid: [
        {
          left: 20,
          right: 15,
          height: 220,
          bottom: 60,
          containLabel: true,
        },
        {
          left: 15,
          right: 15,
          containLabel: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (tooltipData: any) => {
          const ts = dayjs(tooltipData[0].data[0]).format('YYYY-MM-DD HH:mm');
          const value = tooltipData[0]?.value?.[1];

          return `<div>
            <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${ts} </p>
            <div style="display:flex; justify-content: space-between; align-items: center;" > 
            <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);" >当前用能</p>
            ${value ?? '--'}${physicalUnitName}
          </div>
          </div>`;
        },
      },
      legend: {
        show: true,
        right: 136,
        top: 2,
        itemHeight: 2,
        itemWidth: 16,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },
        data: [{ name: '功率', icon: 'rect', itemStyle: { color: '#57FB8B' } }],
      },
      graphic: formatGraphicList(),
      toolbox: {
        show: false,
      },
      brush: {
        toolbox: ['lineX'],
        brushType: 'lineX',
        xAxisIndex: 'all',
        throttleType: 'debounce',
        throttleDelay: 300,
        brushStyle: {
          color: 'rgba(22,221,142,0.15)',
        },
      },
      xAxis: [
        {
          type: 'time',
          minInterval: 60 * 1000,
          data: data?.map(item => item.ts) || [],
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
            margin: 16,
            formatter: function (e: number) {
              return dayjs(e, 'x').format('MM-DD HH:mm');
            },
          },
        },
        {
          gridIndex: 1,
          type: 'time',
          position: 'top',
          data: (data ?? []).map(i => i.ts),
          show: false,
        },
      ],
      yAxis: [
        {
          name: physicalUnitName,
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          type: 'value',
          splitLine: {
            lineStyle: { color: 'rgba(255,255,255,0.30)' },
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
        },
        {
          gridIndex: 1,
          type: 'value',
          inverse: true,
          show: false,
        },
      ],
      series: [
        {
          name: '功率',
          type: 'line',
          symbol: 'none',
          data: (data ?? []).map(i => [i.ts, i.value ? Number((i.value as number).toFixed(4)) : i.value]),
          itemStyle: {
            color: '#16DD8E',
          },
          markArea: {
            data:
              (data?.find(i => i.value) &&
                statusList?.map((i, index) => {
                  return [
                    {
                      yAxis: i?.lowLimit,
                      itemStyle: {
                        color: StateColors[i?.state as StatusType],
                      },
                    },
                    {
                      yAxis: i?.upperLimit,
                      itemStyle: {
                        color: StateColors[i?.state as StatusType],
                      },
                    },
                  ];
                })) ||
              [],
          },
        },
      ],
      dataZoom: [
        {
          show: true,
          realtime: true,

          xAxisIndex: [0, 1],
          bottom: 10,
          height: 24,
          ...() => {
            if (isEmpty(templateDate)) {
              return {
                start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
                end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
              };
            } else {
              return {
                startValue: templateDate?.[0],
                xAxisIndex: [0, 1],
              };
            }
          },
        },
        {
          type: 'inside',
          realtime: true,
          xAxisIndex: [0, 1],
          ...() => {
            if (isEmpty(templateDate)) {
              return {
                start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
                end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
              };
            } else {
              return {
                startValue: templateDate?.[0],
              };
            }
          },
        },
      ],
    };
  };

  const RenderCharts = useMemo(
    () => (
      <ReactEcharts
        ref={e => {
          if (e) {
            chartRef.current = e;
          }
        }}
        option={getChartOption()}
        onEvents={{
          brushSelected: (params: any) => {
            if (params?.batch?.[0]?.areas?.[0]?.coordRange) {
              form?.setFieldValue(['waveformRequest', 'templateStartTime'], params.batch[0].areas[0].coordRange[0]);
              form?.setFieldValue(['waveformRequest', 'templateEndTime'], params.batch[0].areas[0].coordRange[1]);
            }
            return params;
          },
        }}
        style={{ height: 320 }}
        // notMerge
        lazyUpdate={false}
      />
    ),
    [data, statusList, physicalUnitName, templateDate]
  );

  return (
    <div>
      <Space size={16}>
        <Radio.Group buttonStyle="solid" value={timeResolution}>
          {timeResolution === TimeGranularityType.MINUTE && (
            <Radio.Button value={TimeGranularityType.MINUTE}>1分钟</Radio.Button>
          )}
          {timeResolution === TimeGranularityType.HOUR && (
            <Radio.Button value={TimeGranularityType.HOUR}>按小时</Radio.Button>
          )}
          {timeResolution === TimeGranularityType.DAY && (
            <Radio.Button value={TimeGranularityType.DAY}>按日</Radio.Button>
          )}
        </Radio.Group>
        <DatePicker.RangePicker
          style={{ width: 250 }}
          value={date as [Dayjs, Dayjs]}
          disabledDate={disabledDate}
          onChange={v => {
            if (v && v?.[0] && v?.[1]) {
              setDate?.([v[0], v[1]]);
            }
          }}
          allowClear={false}
        />
      </Space>
      <ResizeObserver
        onResize={({ width }) => {
          chartRef.current?.getEchartsInstance().resize({ width });
        }}
      >
        <Spin spinning={loading}>{RenderCharts}</Spin>
      </ResizeObserver>
    </div>
  );
};

const formatGraphicList = () => {
  const data = [
    { name: '停机', color: StateColors[StatusType.STOP] },
    { name: '待机', color: StateColors[StatusType.WAIT] },
  ].map((i, index) => ({
    type: 'rect',
    top: 7,
    right: (index + 1) * 44 + index * 24,
    shape: {
      width: 12,
      height: 12,
    },
    textContent: {
      type: 'text',
      style: {
        text: i.name,
        fill: 'rgba(255,255,255,0.85)',
        fontSize: 14,
      },
    },
    textConfig: {
      position: 'right',
    },
    style: {
      fill: i.color,
    },
  }));
  return data;
};

export default LoadCurve;
