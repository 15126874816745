import {
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  Form,
  Input,
  CustomFilter,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';

import type { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ThermalResponse,
  createThermal,
  updateThermal,
  ThermalPageRequest,
  getThermalPage,
} from '../../../api/thermal';
import { getParents, useAreaJson, useHasPermission } from '../../../utils/utils';

import styles from '../utils.module.scss';
import CreateForm from './CreateForm';
import type { FormProps } from './CreateForm';
import { PermissionsType } from '../../../common/permissionsConst';

export interface SearchParams extends Omit<ThermalPageRequest, 'page' | 'size'> {}

const ThermalList: FC = () => {
  const hasThermalAccountAdd = useHasPermission(PermissionsType.B_THERMAL_ACCOUNT_ADD);
  const hasThermalAccountDevice = useHasPermission(PermissionsType.B_THERMAL_ACCOUNT_DEVICE);
  const hasThermalAccountEdit = useHasPermission(PermissionsType.B_THERMAL_ACCOUNT_UPDATE);

  const [searchForm] = Form.useForm();
  const [updateState, updateFn] = useUpdate();

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ThermalResponse[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [current, setCurrent] = useState<Partial<ThermalResponse>>();

  const districtData = useAreaJson();

  useEffect(() => {
    setLoading(true);
    getThermalPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: SearchParams) => {
    const { regionCode } = val;
    const params = {
      number: val.number,
      regionCode: regionCode ? regionCode[regionCode.length - 1] : undefined,
      username: val.username,
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const onReset = () => {
    const params = {
      number: undefined,
      regionCode: undefined,
      username: undefined,
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const onAdd = () => {
    setCurrent({});
  };

  const onCancel = () => {
    setCurrent(undefined);
  };

  const onSubmit = async (values: FormProps) => {
    const { regionCode, customerMcids } = values;
    if (current) {
      ((current.id ?? undefined) === undefined
        ? createThermal({
            ...values,
            customerMcids: customerMcids.map(item => item.value as string),
            regionCode: regionCode[regionCode.length - 1],
          })
        : updateThermal({
            ...values,
            id: current.id!,
            customerMcids: customerMcids.map(item => item.value as string),
            regionCode: regionCode[regionCode.length - 1],
          })
      ).then(() => {
        onCancel();
        setPageOffset(1);
        updateFn();
      });
    }
  };

  const columns: Array<ColumnType<ThermalResponse>> = [
    {
      title: '用汽账户户号',
      dataIndex: 'number',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '关联组织',
      dataIndex: 'customerMcidNames',
      ellipsis: { showTitle: true },
      render: (v: string[]) => <EllipsisSpan value={(v ?? []).join('、')} />,
    },
    {
      title: '所在区域',
      dataIndex: 'regionCode',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        let value;
        if (districtData) {
          value = getParents(districtData, v).map(item => item.name);
        }
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '全域能源拓扑',
      dataIndex: 'uetName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '管线名称',
      dataIndex: 'linePipeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建人',
      dataIndex: 'createByUsername',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
  ];

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: ThermalResponse) => (
        <Space size={16}>
          {hasThermalAccountEdit && (
            <Button
              type="link"
              onClick={() => {
                setCurrent(record);
              }}
            >
              编辑
            </Button>
          )}
          {hasThermalAccountDevice && (
            <Button type="link">
              <Link to={`/operation/energy/account/thermal/device/${record.id}`}>设备管理</Link>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className={styles.filterWrapper}>
        <CustomFilter form={searchForm} onFinish={(val: SearchParams) => onFinish(val)} onReset={onReset}>
          <Form.Item label="用汽账户户号" name="number">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item label="所在区域" name="regionCode">
            <Cascader
              options={districtData}
              fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item label="创建人" name="username">
            <Input placeholder="请输入" />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        {hasThermalAccountAdd && (
          <div className={styles.operationArea}>
            <Button type="primary" onClick={onAdd}>
              新增用汽账户
            </Button>
          </div>
        )}
        <Table sticky scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={data} rowKey="id" />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <CreateForm
        current={current}
        districtData={districtData}
        visible={!!current}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ThermalList;
