import React, { Key, ReactText, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  Paging,
  Table,
  usePaging,
  Input,
  CustomFilter,
  Form,
  EllipsisSpan,
  useAsync,
  Select,
  TreeSelect,
} from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import { DataAcquisitionFormProps } from '../../components/DataAcquisitionForm';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { isBoolean, isEmpty } from 'lodash-es';
import { OrganizationResponse } from '@/api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganization } from '@/api/customer-user';
import {
  apiV2EdgeDeviceV2DeviceListPost,
  apiV2OuPagePost,
  V2EdgeDeviceV2DeviceListPostRequest,
  V2EdgeDeviceV2DeviceListPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export interface DeviceModalRef {
  selectedRowKeys: Array<ReactText> | undefined;
}

type SearchParams = Omit<V2EdgeDeviceV2DeviceListPostRequest, 'page' | 'size'>;

type FilterParams = Omit<SearchParams, 'deviceType' | 'physicalModelId'>;

type TableData = Exclude<V2EdgeDeviceV2DeviceListPostResponse['list'], undefined>[number];

interface DeviceModalProps extends DataAcquisitionFormProps {
  deviceIds: Array<number> | undefined;
  objectModalTypeOptions?: { label: string; value: Key }[];
}

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    width: 200,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'physicalModel',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ou',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '是否配置数采',
    dataIndex: 'hasEdgeConfig',
    width: 120,
    render: (v: boolean) => <EllipsisSpan value={isBoolean(v) && (v ? '是' : '否')} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
    };
    return res;
  });
};

const DeviceModal = React.forwardRef(
  ({ state, deviceIds, objectModalTypeOptions }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
    const pagingInfo = usePaging(500);
    const [form] = Form.useForm();
    const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<ReactText> | undefined>(deviceIds);
    const { deviceType: deviceTypeArray, objectModalType } = state;

    const deviceType = deviceTypeArray?.slice(-1);
    const [searchParams, setSearchParams] = useState<SearchParams>();

    const [organization, setOrganization] = useState<OrganizationResponse>();

    const mcids = Form.useWatch('mcids', form);

    useEffect(() => {
      getOrganization().then(res => {
        setOrganization(res);
      });
    }, []);

    const treeData = useMemo(() => {
      if (organization) {
        return formatTreeData([organization]);
      } else {
        return undefined;
      }
    }, [organization]);

    const onReset = () => {
      form.resetFields();
      form.submit();
      setPageOffset(1);
    };

    const onFinish = (val: FilterParams) => {
      setSearchParams({ ...val, deviceType });
      setPageOffset(1);
    };

    const onSelectChange = (rowKeys: ReactText[]) => {
      setSelectedRowKeys(rowKeys);
    };

    useImperativeHandle(ref, () => ({
      selectedRowKeys,
    }));

    const rowSelection = {
      preserveSelectedRowKeys: true,
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const { data, isLoading } = useQuery(
      useCallback(
        () =>
          apiV2EdgeDeviceV2DeviceListPost({
            physicalModelIdList: objectModalType ? [objectModalType] : undefined,
            ...searchParams,
            deviceType,
            page: pageOffset,
            size: pageSize,
          }).then(res => {
            setTotalCount(res.total ?? 0);
            return res.list ?? [];
            // return formatListData(res);
          }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pageOffset, pageSize, searchParams, setTotalCount]
      )
    );

    const { data: ouList } = useQuery(
      useCallback(() => {
        if (isEmpty(mcids)) {
          return Promise.resolve([]);
        }
        return apiV2OuPagePost({
          page: 1,
          size: 500,
          mcids,
        }).then(res => res.list ?? []);
      }, [mcids])
    );

    return (
      <div className={styles.deviceModal}>
        <CustomFilter<FilterParams>
          form={form}
          colSpan={8}
          onFinish={val => onFinish(val as FilterParams)}
          onReset={onReset}
        >
          <Form.Item name="name" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
          <Form.Item
            name="physicalModelIdList"
            initialValue={objectModalType ? [objectModalType] : undefined}
            label="物模型型号"
          >
            <Select
              allowClear
              optionFilterProp="label"
              showSearch
              mode="multiple"
              options={objectModalTypeOptions}
              disabled={!!objectModalType}
              placeholder={'请选择'}
            />
          </Form.Item>
          <Form.Item name="mcids" label="所属组织">
            <TreeSelect
              onChange={val => {
                form.setFieldsValue({ ouIds: undefined });
              }}
              treeData={treeData}
              multiple
              placeholder="请选择"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="ouIds" label="运营单元">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={ouList?.map(i => ({ label: i.name, value: i.id }))}
              optionFilterProp="label"
              mode="multiple"
              maxTagCount={1}
              showSearch
              allowClear
            />
          </Form.Item>
          <Form.Item name="hasEdgeConfig" label="是否配置数采">
            <Select placeholder="请选择">
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </Form.Item>
        </CustomFilter>
        <div className={styles.table_text}>
          <div className={styles.action}>
            <span>点击前往</span>
            <Link to={'/device/manage/device/create'} className={styles.href}>
              添加设备
            </Link>
          </div>
        </div>
        <Table<TableData>
          scroll={{ y: 300 }}
          rowKey="id"
          loading={isLoading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          selectedCount={selectedRowKeys?.length ?? 0}
          showSelectedCount
        >
          <Paging pagingInfo={pagingInfo} />
        </Table>
      </div>
    );
  }
);

export default DeviceModal;
