import { AuthorizedPage } from '@maxtropy/components';
import { ScadaProvider } from '@maxtropy/scada';
import { FC, ReactNode } from 'react';

const SelectStaffLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <AuthorizedPage>
      {/* <LayoutComponents menuData={menuData}> */}
      <ScadaProvider resourceBaseUrl="/scada-resources/storage/">{children}</ScadaProvider>
      {/* </LayoutComponents> */}
    </AuthorizedPage>
  );
};

export default SelectStaffLayout;
