import { MinusCircleOutlined, PayCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { SubContent, Button, Form, Select, InputNumber, Input, DatePicker, ShowInput } from '@maxtropy/components';
import { Col, Row, Space, Upload } from 'antd';
import { FC, Key, useEffect, useState } from 'react';

import { get } from 'lodash-es';
import dayjs, { Dayjs } from 'dayjs';

import styles from './index.module.scss';
import {
  accountRefund,
  getSaleAccountVoucherList,
  SaleClientAccountRefundRequest,
  SaleVoucherVo,
  TransactionType,
  VoucherFileRequest,
  VoucherRefundRequest,
} from '../../../../api/sale';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import {
  clentAccountFileUplodaMaxCount,
  generateLimitFileSize,
  useUploadMaxDisabled,
  timeFormatString,
  normFile,
} from '../../util';

export interface RefundFormRequest extends Omit<SaleClientAccountRefundRequest, 'files' | 'type' | 'specifiedDate'> {
  specifiedDate: Dayjs;
  files: RcFile[];
}
export interface RefundProps {
  saleClientAccountId: Key;
  goList?: () => void;
}

const Refund: FC<RefundProps> = ({ saleClientAccountId, goList }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadDisabled, uploadChange] = useUploadMaxDisabled(clentAccountFileUplodaMaxCount);

  const [saleVoucher, setSaleVoucher] = useState<SaleVoucherVo[]>([]);

  useEffect(() => {
    if (saleClientAccountId) {
      getSaleAccountVoucherList({ saleClientAccountId, softField: 0 }).then(res => {
        setSaleVoucher(res.list ?? []);
      });
    }
  }, [saleClientAccountId]);

  const onFinish = (value: RefundFormRequest) => {
    if (saleClientAccountId !== undefined) {
      const files: VoucherFileRequest[] = value.files.map((i: UploadFile) => ({
        fileKey: i.response.key,
        fileName: i.name,
      }));
      setLoading(true);
      accountRefund(saleClientAccountId, {
        ...value,
        specifiedDate: dayjs(value.specifiedDate).format('YYYY-MM-DD HH:mm:ss'),
        type: TransactionType.REFUND,
        files,
      })
        .then(() => {
          goList?.();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={{
        vouchers: [{}],
      }}
      scrollToFirstError={{
        block: 'center',
        behavior: 'smooth',
      }}
      onFinish={onFinish}
    >
      <SubContent title="需要退款的收款凭证">
        <Form.List name="vouchers">
          {(fields, { add, remove }) => (
            <Space direction="vertical" size={15} style={{ width: '100%' }}>
              {fields.map(({ key, name, ...restField }) => (
                <Row className={styles.formListWrapper} key={key}>
                  <Col span={23} style={{ marginTop: 14 }}>
                    <Row>
                      <Form.Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const vouchers: Array<Partial<VoucherRefundRequest>> = getFieldValue(['vouchers']);
                          const voucher: Partial<VoucherRefundRequest> = getFieldValue(['vouchers', name]);
                          const filterId = vouchers
                            .filter(i => i?.saleVoucherId !== undefined)
                            .filter(i => i.saleVoucherId !== voucher?.saleVoucherId)
                            .map(i => i.saleVoucherId);
                          const newData = saleVoucher.filter(i => !filterId.includes(i.id));
                          return (
                            <Col span={8}>
                              <Form.Item
                                {...restField}
                                wrapperCol={{ span: 18 }}
                                name={[name, 'saleVoucherId']}
                                label="收款凭证"
                                rules={[{ required: true, message: '请选择收款凭证' }]}
                              >
                                <Select>
                                  {newData.map(i => (
                                    <Select.Option key={i.id} value={i.id}>
                                      {i.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          );
                        }}
                      </Form.Item>
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          const path = `${'vouchers'}.${name}.${'saleVoucherId'}`;
                          return get(prevValues, path) !== get(curValues, path);
                        }}
                        noStyle
                      >
                        {({ getFieldValue }) => {
                          const saleVoucherId: number | undefined = getFieldValue(['vouchers', name, 'saleVoucherId']);
                          const findRecord = saleVoucher.find(i => i.id === saleVoucherId);
                          return (
                            <>
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  wrapperCol={{ span: 18 }}
                                  label={
                                    <Space>
                                      <PayCircleOutlined />
                                      剩余可用金额
                                    </Space>
                                  }
                                >
                                  <span style={{ color: '#FF0000' }}>{findRecord?.balance ?? '--'}</span>
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item {...restField} wrapperCol={{ span: 18 }} label="凭证收款日期">
                                  {findRecord?.specifiedDate
                                    ? dayjs(findRecord.specifiedDate).format(timeFormatString)
                                    : '--'}
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...restField}
                                  wrapperCol={{ span: 18 }}
                                  name={[name, 'amount']}
                                  label="退款金额"
                                  rules={[{ required: true, message: '请输入退款金额' }]}
                                >
                                  <InputNumber
                                    disabled={!findRecord}
                                    min={0}
                                    max={findRecord?.balance}
                                    precision={2}
                                    addonAfter="￥"
                                    placeholder="请输入"
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          );
                        }}
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={1} className={styles.delete}>
                    {fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="dashed" style={{ width: 125 }} onClick={() => add({})} block icon={<PlusOutlined />}>
                  添加
                </Button>
              </Form.Item>
            </Space>
          )}
        </Form.List>
        <Form.Item
          shouldUpdate={(prevValues, curValues) => {
            const path = `${'vouchers'}`;
            return get(prevValues, path) !== get(curValues, path);
          }}
          noStyle
        >
          {({ getFieldValue }) => {
            const vouchers: Array<Partial<VoucherRefundRequest>> = getFieldValue(['vouchers']);
            const ammounts: number[] = vouchers.filter(i => i.amount !== undefined).map(i => i.amount!);
            const totalAmount = ammounts.reduce((a: number, c: number) => a + c, 0);
            return (
              <Space className={styles.totalAmount} size={20}>
                <Space>
                  <PayCircleOutlined />
                  <span>总退款金额</span>
                </Space>
                <ShowInput value={ammounts.length !== 0 ? `${totalAmount} 元` : '--'} style={{ color: '#FF0000' }} />
              </Space>
            );
          }}
        </Form.Item>
      </SubContent>
      <SubContent title="退款凭证">
        <Form.Item label="凭证名称" name="voucherName" rules={[{ required: true, message: '请输入凭证名称' }]}>
          <Input style={{ width: 500 }} placeholder="请输入" maxLength={100} />
        </Form.Item>
        <Form.Item label="退款日期" name="specifiedDate" rules={[{ required: true, message: '请选择退款日期' }]}>
          <DatePicker style={{ width: 500 }} showTime placeholder="请选择" />
        </Form.Item>

        <Form.Item
          label="凭证文件"
          name="files"
          rules={[{ required: true, message: '请选择凭证文件' }]}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ width: 500 }}
          extra={
            <Row>
              <Col span={24}>支持扩展名：.doc .docx .pdf .jpg .png .jpeg</Col>
              <Col span={24}>单个文件大小不超过5MB，最多上传十个文件。</Col>
            </Row>
          }
        >
          <Upload
            action="/api/file-center/upload"
            accept=".doc,.docx,.pdf,.jpg,.png,.jpeg"
            multiple
            maxCount={clentAccountFileUplodaMaxCount}
            beforeUpload={generateLimitFileSize(5)}
            style={{ width: 500 }}
            onChange={uploadChange}
          >
            <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
              请选择
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label="凭证说明" name="description" rules={[{ required: true, message: '请输入凭证说明' }]}>
          <Input.TextArea style={{ width: 500 }} placeholder="请输入" maxLength={200} />
        </Form.Item>
      </SubContent>

      <Space size={8} className="sticky-footer-c">
        <Button htmlType="submit" type="primary" loading={loading}>
          保存
        </Button>
        <Button onClick={goList}>取消</Button>
      </Space>
    </Form>
  );
};

export default Refund;
