import { Divider, Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Input, Key, Tree } from '@maxtropy/components';
import { apiV2OutputProductCategoryListPost } from '@maxtropy/device-customer-apis-v2';
import { DataNode } from 'antd/es/tree';
interface Props {
  setCategoryId: (v: Key) => void;
  setProductValue?: (v: any) => void;
}

interface NewDataNode extends DataNode {
  name: string;
  id: number;
  parentId: number;
  children?: NewDataNode[];
}

const StationTree: React.FC<Props> = ({ setCategoryId, setProductValue }) => {
  const [data, setData] = useState<NewDataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>();
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]); // 选择树
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开

  useEffect(() => {
    setLoading(true);

    apiV2OutputProductCategoryListPost({})
      .then((res: any) => {
        if (res) {
          setData([res]);
          // 默认选中第一个
          setSelectedKeys([res.id]);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    const loop = (data: NewDataNode[]): NewDataNode[] =>
      data.map(item => {
        const strTitle = item.name as string;
        const searchName = searchVal ?? '';
        const index = strTitle.indexOf(searchName);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchName.length);
        const title =
          index > -1 ? (
            <>
              <span style={{ marginRight: '5px' }} id={`${item.id}`}>
                {beforeStr}
                <span style={{ color: 'red' }}>{searchName}</span>
                {afterStr}
              </span>
            </>
          ) : (
            <>
              <span style={{ marginRight: '5px' }} id={`${item.id}`}>
                <span>{strTitle}</span>
              </span>
            </>
          );
        if (item.children) {
          return { ...item, title, children: loop(item.children) };
        }
        return {
          ...item,
          title,
        };
      });
    return loop(data);
  }, [searchVal, data]);

  useEffect(() => {
    const searchName = searchVal ?? '';
    const loop = (nodes: NewDataNode[], keys?: Key[]) => {
      const keyList = keys ?? [];
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].name.indexOf(searchName) > -1) {
          keyList.push(nodes[i].parentId);
        }
        if (nodes[i].children) {
          loop(nodes[i].children!, keyList);
        }
      }
      return keyList;
    };
    setExpandedKeys([0, ...loop(data)]);
  }, [searchVal, data]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.searchArea}>
        <Input.Search
          placeholder="请输入名称"
          allowClear
          onSearch={v => {
            setSearchVal(v);
            setAutoExpandParent(true);
          }}
        />
        <Divider style={{ margin: '16px 0 0px' }} />
      </div>
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {treeData.length > 0 ? (
            <Tree
              className={styles.treeStyle}
              blockNode
              treeData={treeData}
              selectedKeys={selectedKeys}
              fieldNames={{ key: 'id' }}
              onSelect={(v, info) => {
                setCategoryId(v[0] ?? 0);
                setSelectedKeys(v);
                setProductValue?.(info.node.key);
              }}
              expandedKeys={expandedKeys}
              onExpand={v => {
                setExpandedKeys(v);
                setAutoExpandParent(false);
              }}
              autoExpandParent={autoExpandParent}
            />
          ) : (
            <Empty style={{ marginTop: 40 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default StationTree;
