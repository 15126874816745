import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  useTenantPermissions,
  useUpdate,
  Wrapper,
  Button,
  useAsync,
  Modal,
  CustomFilter,
  Checkbox,
  Form,
  Input,
  Select,
  Tabs,
  Tooltip,
  message,
} from '@maxtropy/components';
import { Divider, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ReportStrategyStatisticalCycleEnum, StrategyStatus, StrategyStatusDisplay } from '@/shared/types';
import styles from './index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  changeReportStrategyStatus,
  deleteReportStrategy,
  executingReportStrategy,
  ReportStrategyPageProps,
} from '../../../api/reportStrategy';
import { useQuery } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { ReportStrategyType } from '../Create';
import { getOuListOwned, OuListResponse } from '../../../api/ou';
import { apiV2ReportStrategyPagePost, apiV2ReportStrategyRecordNumPost } from '@maxtropy/device-customer-apis-v2';
import { V2ReportStrategyPagePostResponse } from '@maxtropy/device-customer-apis-v2/dist/ytt/apis/v2/reportStrategyPage';

const tableStatusHeadTitle = (
  <div>
    策略状态&nbsp;&nbsp;
    <Tooltip title={<div style={{ width: '180px' }}>策略配置完成后需启用, 启用后次日生效</div>}>
      <InfoCircleOutlined />
    </Tooltip>
  </div>
);

interface FilterProps {
  nameOrCode?: string;
  status?: StrategyStatus;
  ouId?: number;
}

type ReportStrategyListItem = Exclude<V2ReportStrategyPagePostResponse['list'], undefined>[number];

const ReportStrategyList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [updateState, update] = useUpdate();
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [ouSearchValue, setOuSearchValue] = useState<string>('');
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tab = urlSearchParams.get('type') || '0';
  const [currentTab, setCurrentTab] = useState(+tab);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const ou = useAsync(getOuListOwned, []);
  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const ouIds = Form.useWatch('ouIds', form);
  const allOuSelected = useMemo(() => ouIds?.length === ou?.length, [ou?.length, ouIds?.length]);

  const permission = useTenantPermissions();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2ReportStrategyPagePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
          single: currentTab === ReportStrategyType.ONETIME,
        }).then(res => {
          if (res) setTotalCount(res.total ?? 0);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentTab, pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  useEffect(() => {
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const onFinish = (v: FilterProps) => {
    setSearchParams(v);
    setPageOffset(1);
  };

  const onReset = () => {
    const params = {
      nameOrCode: undefined,
      status: undefined,
      ouId: undefined,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const filters = (
    <>
      <Tabs activeKey={String(currentTab)} onChange={tab => setCurrentTab(+tab)}>
        <Tabs.TabPane tab="单次策略" key={String(ReportStrategyType.ONETIME)} />
        <Tabs.TabPane tab="自动策略" key={String(ReportStrategyType.AUTOMATIC)} />
      </Tabs>
      <CustomFilter form={form} onFinish={onFinish} onReset={onReset}>
        <Form.Item name="nameOrCode" label="编号/名称">
          <Input placeholder="请输入编号或名称查询" />
        </Form.Item>
        <Form.Item name="status" label="策略状态">
          <Select style={{ width: '100%' }} placeholder="请选择">
            {currentTab === 0 ? (
              <>
                <Select.Option value={StrategyStatus.CUSTOMIZING}>
                  {StrategyStatusDisplay[StrategyStatus.CUSTOMIZING]}
                </Select.Option>
                <Select.Option value={StrategyStatus.EXECUTING}>
                  {StrategyStatusDisplay[StrategyStatus.EXECUTING]}
                </Select.Option>
                <Select.Option value={StrategyStatus.COMPLETED}>
                  {StrategyStatusDisplay[StrategyStatus.COMPLETED]}
                </Select.Option>
              </>
            ) : (
              <>
                <Select.Option value={StrategyStatus.TO_USE}>
                  {StrategyStatusDisplay[StrategyStatus.TO_USE]}
                </Select.Option>
                <Select.Option value={StrategyStatus.UNUSED}>
                  {StrategyStatusDisplay[StrategyStatus.UNUSED]}
                </Select.Option>
                <Select.Option value={StrategyStatus.USED}>{StrategyStatusDisplay[StrategyStatus.USED]}</Select.Option>
              </>
            )}
          </Select>
        </Form.Item>
        <Form.Item name="ouIds" label="所属运营单元">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="请选择"
            options={ouOptions}
            maxTagCount="responsive"
            showSearch
            optionFilterProp="label"
            searchValue={ouSearchValue}
            onSearch={value => setOuSearchValue(value)}
            onChange={() => {
              setOuSearchValue('');
            }}
            dropdownRender={menu => (
              <>
                {!ouSearchValue.length && ou.length ? (
                  <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                    <Checkbox
                      indeterminate={ouIds?.length && ouIds?.length < (ou?.length ?? 0)}
                      checked={allOuSelected}
                      onChange={e => {
                        if (e.target.checked) {
                          // 全选
                          form.setFieldValue(
                            'ouIds',
                            ou?.map(v => v.id)
                          );
                        } else {
                          // 反选
                          form.setFieldValue('ouIds', []);
                        }
                      }}
                    >
                      全选
                    </Checkbox>
                  </div>
                ) : null}
                <Divider style={{ margin: 0 }} />
                {menu}
              </>
            )}
          />
        </Form.Item>
      </CustomFilter>
    </>
  );

  const deleteAction = async (id: number) => {
    const data = await apiV2ReportStrategyRecordNumPost({ id });
    Modal.confirm({
      title: '是否确认删除该策略？',
      content: (
        <span style={{ color: 'var(--error-color)' }}>删除策略将会关联删除策略下已生成的{data.num ?? 0}份报表。</span>
      ),
      okText: '删除',
      onOk: () => {
        deleteReportStrategy(id).then(res => {
          if (res) {
            update();
            message.success('删除成功！');
          }
        });
      },
    });
  };

  const changeStatus = (id: number, use: boolean) => {
    changeReportStrategyStatus(id, use).then(() => {
      update();
      message.success(use ? '启用成功' : '停用成功');
    });
  };

  const executingAction = (id: number) => {
    executingReportStrategy(id).then(res => {
      if (res) {
        update();
        message.success('执行成功！');
      }
    });
  };

  const downLoadAction = (id?: number) => {
    if (!id) {
      message.error('该报表已经被删除，不可下载');
      return;
    }
    window.open(`/api/v2/report-strategy/downLoad?reportRecordId=${id}`);
  };

  const columns = [
    {
      title: '报表策略编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报表策略名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      fixed: 'left' as const,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属运营单元',
      dataIndex: 'ous',
      ellipsis: { showTitle: true },
      render: (v: OuListResponse[]) => <EllipsisSpan value={v.map(item => item.name).join(',')} />,
    },
    currentTab === 0
      ? {
          title: '统计周期',
          ellipsis: { showTitle: true },
          render: (v: ReportStrategyPageProps) => (
            <EllipsisSpan
              value={
                v.statisticalResolution === ReportStrategyStatisticalCycleEnum.DAY
                  ? `${dayjs.unix(v.statisticalBeginTime?.time ?? 0).format('YYYY-MM-DD')}~${dayjs
                      .unix(v?.statisticalEndTime?.time ?? 0)
                      .format('YYYY-MM-DD')}`
                  : `${dayjs.unix(v.statisticalBeginTime?.time ?? 0).format('YYYY-MM-DD HH:mm')}~${dayjs
                      .unix(v?.statisticalEndTime?.time ?? 0)
                      .format('YYYY-MM-DD HH:mm')}`
              }
            />
          ),
        }
      : {
          title: '下次执行时间',
          dataIndex: 'nextExecutionTime',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD')} />,
        },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'updateUserName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: currentTab === 0 ? '策略状态' : tableStatusHeadTitle,
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: StrategyStatus) => <EllipsisSpan value={StrategyStatusDisplay[v]} />,
    },
  ];

  const actionColumns = {
    title: '操作',
    width: 320,
    fixed: 'right' as const,
    render: (v: ReportStrategyPageProps) => (
      <>
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.B_DELETE_REPORT_STRATEGY) && (
            <Button onClick={() => deleteAction(v.id)} type="link">
              删除
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_EXECUTE_REPORT_STRATEGY) && (
            <Button
              disabled={v.status !== StrategyStatus.CUSTOMIZING}
              type="link"
              onClick={() => executingAction(v.id)}
            >
              执行
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_CREATE_REPORT_STRATEGY) && (
            <Button type="link">
              <Link to={`/data/report/strategy/create?copyId=${v.id}&type=0`}>复制</Link>
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_UPDATE_REPORT_STRATEGY) && (
            <Button
              disabled={v.status !== StrategyStatus.CUSTOMIZING}
              type="link"
              onClick={() => navigate(`/data/report/strategy/edit/${v.id}?type=0`)}
            >
              编辑
            </Button>
          )}
          <Button type="link">
            <Link to={`/data/report/strategy/detail/${v.id}?type=0`}>查看</Link>
          </Button>
          {(permission ?? []).includes(PermissionsType.B_REPORT_RECORD_DOWNLOAD) && (
            <Button
              disabled={v.status !== StrategyStatus.COMPLETED}
              type="link"
              onClick={() => downLoadAction(v.reportId)}
            >
              下载报表
            </Button>
          )}
        </Space>
      </>
    ),
  };

  const autoActionColumns = {
    title: '操作',
    width: 320,
    fixed: 'right' as const,
    render: (v: ReportStrategyListItem) => (
      <>
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.B_DELETE_REPORT_STRATEGY) && (
            <Button onClick={() => deleteAction(v.id!)} style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              删除
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_UPDATE_REPORT_STRATEGY) && (
            <Button
              disabled={v.status !== StrategyStatus.TO_USE}
              type="link"
              onClick={() => navigate(`/data/report/strategy/edit/${v.id}?type=1`)}
            >
              编辑
            </Button>
          )}
          <Button type="link">
            <Link to={`/data/report/strategy/detail/${v.id}?type=1`}>查看</Link>
          </Button>
          {(permission ?? []).includes(PermissionsType.B_CREATE_REPORT_STRATEGY) && (
            <Button type="link">
              <Link to={`/data/report/strategy/create?copyId=${v.id}&type=1`}>复制</Link>
            </Button>
          )}
          <Button
            disabled={v.status === StrategyStatus.TO_USE}
            type="link"
            onClick={() => {
              navigate(`/data/report/list?ouIds=${v.ous?.map(item => item.id).join(',')}&reportStrategyId=${v.id}`);
            }}
          >
            查看报表
          </Button>

          <Button type="link" onClick={() => changeStatus(v.id!, v.status !== StrategyStatus.USED)}>
            {v.status === StrategyStatus.USED ? '停用' : '启用'}
          </Button>
        </Space>
      </>
    ),
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
      <div className={styles.operationArea}>
        {(permission ?? []).includes(PermissionsType.B_CREATE_REPORT_STRATEGY) && (
          <Button type="primary" icon={<PlusOutlined />}>
            <Link to={`/data/report/strategy/create?type=${currentTab}`}>
              {currentTab === ReportStrategyType.ONETIME ? `新建单次策略` : `新建自动策略`}
            </Link>
          </Button>
        )}
      </div>
      <Table
        sticky
        rowKey="id"
        scroll={{ x: 1900 }}
        columns={[...columns, currentTab === 0 ? actionColumns : autoActionColumns]}
        dataSource={data}
        loading={isLoading}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ReportStrategyList;
