import {
  CircuitProps,
  CreateUETEMTProcessResponse,
  CreateUETEMTResponse,
  EnergyWorkingProcessType,
  getUetCircuitList,
  getUetEmtCandidateExit,
  getUetEMTCandidateExitList,
} from '@/api/uet';
import {
  apiV2UetCandidateGasStationPost,
  apiV2UetCandidateNitrogenStationPost,
} from '@maxtropy/device-customer-apis-v2';
import { Select } from 'antd';
import { isNil } from 'lodash-es';
import { FC, Key, useEffect, useMemo, useRef, useState } from 'react';

export interface IEnergyMediumRef {
  eMTBasicInfo?: CreateUETEMTResponse;

  onChange?: (value?: Key) => void;
  callOptions?: (items?: any[]) => void;
  value?: Key;
  createdProcesses?: CreateUETEMTProcessResponse[];
  processType?: EnergyWorkingProcessType;
  exitNames?: { name?: string; energyMediumId?: number }[];
  selfValues?: {
    energyMediumId?: number;
    refFlag?: boolean; // 自己引子自己所带来的后端返回回显问题
    refName?: string;
  };
}

export enum EnergyMediumType {
  //氮气
  NITROGEN = 2023,
  //压缩空气
  COMPRESSAir = 2021,
}

// 场景id枚举
export enum EnergyMediumSceneId {
  COMPRESSED_AIR = 503, // 压缩空气
  INDUSTRIAL_GASES = 504, // 工业气体
}
const EnergyMediumRef: FC<IEnergyMediumRef> = props => {
  const { eMTBasicInfo, onChange, callOptions, value, createdProcesses, processType, exitNames, selfValues } = props;
  const energyMediumId = selfValues?.energyMediumId;
  const refFlag = selfValues?.refFlag;
  const refName = selfValues?.refName;
  const [refValue, setRefValue] = useState<Key | undefined>(value); // 引子值
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]); // 引自中选择电能的回路list
  const [uetEMTCandidateExitList, setUetEMTCandidateExitList] = useState<any[]>([]); // 引自list
  const [airList, setAirList] = useState<any>([]); // 引自list
  const [status, setStatus] = useState<boolean>(false); // 切换能源介质的时候改变
  const [nitrogen, setNitrogen] = useState<any>([]);
  const firstRenderRef = useRef<boolean>(true);

  useEffect(() => {
    // console.log(value);
    // console.log(refFlag);
    // console.log(refName);
    // 当我发现输出里面没有当前引子的value的时候，清空
    if (
      typeof value === 'string' &&
      !(exitNames ?? [])
        .filter(i => !isNil(i?.name) || i?.name === '')
        .map(item => `${eMTBasicInfo?.name}--${item.name}`)
        .includes(value)
    ) {
      setRefValue(undefined);
    } else {
      if (refFlag && refName) {
        // 后端有返回name并且是自身的输出
        // 如果输出name修改了，直接清空引子
        if (
          !(exitNames ?? [])
            .filter(i => !isNil(i?.name) || i?.name === '')
            .map(item => item.name)
            .includes(refName.replace(`${eMTBasicInfo?.name}--`, ''))
        ) {
          setRefValue(undefined);
        } else {
          // 否则直接赋值后端的值
          setRefValue(value);
        }
      } else {
        setRefValue(value);
      }
    }
  }, [exitNames, value, refFlag, refName]);

  useEffect(() => {
    if (status) {
      onChange?.(undefined);
    }
    if (energyMediumId && eMTBasicInfo && createdProcesses) {
      if (energyMediumId === 2030) {
        // 查询回路
        getUetCircuitList(String(eMTBasicInfo.uetId)).then(setCircuitList);
      } else {
        const findEditProcess = createdProcesses.find(i => i.isEdit); // 找到有编辑的过程
        if (processType === EnergyWorkingProcessType.PREPARATION_AND_HANDLE) {
          getUetEMTCandidateExitList(
            String(eMTBasicInfo?.uetId),
            String(energyMediumId),
            findEditProcess && String(findEditProcess?.id)
          ).then(setUetEMTCandidateExitList);
        } else {
          //氮气
          if (
            energyMediumId === EnergyMediumType.NITROGEN &&
            eMTBasicInfo?.energyMediumSceneId === EnergyMediumSceneId.INDUSTRIAL_GASES
          ) {
            apiV2UetCandidateNitrogenStationPost({ uetId: eMTBasicInfo?.uetId }).then(res => {
              setNitrogen(res.list);
            });
          }
          //压缩空气
          if (
            energyMediumId === EnergyMediumType.COMPRESSAir &&
            eMTBasicInfo?.energyMediumSceneId === EnergyMediumSceneId.COMPRESSED_AIR
          ) {
            apiV2UetCandidateGasStationPost({ uetId: eMTBasicInfo?.uetId }).then(res => {
              setAirList(res.list);
            });
          }
          getUetEmtCandidateExit(
            String(eMTBasicInfo?.uetId),
            String(eMTBasicInfo?.id),
            String(energyMediumId),
            findEditProcess && String(findEditProcess?.id)
          ).then(setUetEMTCandidateExitList);
        }
      }
      setStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumId, eMTBasicInfo, createdProcesses, processType]);

  // 引自
  const refIdOptions = useMemo(() => {
    let refOptions: any[] = [];
    if ((uetEMTCandidateExitList && uetEMTCandidateExitList.length) || (airList && airList.length)) {
      let air = (airList ?? []).map((i: any) => ({ label: i.name, value: i.id, type: i.type }));
      let uet = uetEMTCandidateExitList.map(i => ({ label: `${i.energyMediumTopologyName}--${i.name}`, value: i.id }));
      let nir = nitrogen.map((i: any) => ({ label: i.name, value: i.id, type: i.type }));
      refOptions = [...air, ...uet, ...nir];
    }
    // 输入引子输出
    const exitNameList = (exitNames ?? [])
      .filter(i => !isNil(i?.name) || i?.name === '')
      .filter(x => x.energyMediumId === energyMediumId)
      .map(c => ({ label: `${eMTBasicInfo?.name}--${c?.name}`, value: `${eMTBasicInfo?.name}--${c?.name}` }));
    return [...refOptions, ...exitNameList];
  }, [uetEMTCandidateExitList, airList, nitrogen, exitNames, energyMediumId]);

  // 回路
  const circuitListOptions = useMemo(() => {
    let circuitOptions: any[] = [];
    if (circuitList && circuitList.length) {
      const circuitsList = circuitList.map(i => ({ label: i.name, value: i.id }));
      circuitOptions = [...circuitsList];
    }
    // 输入引子输出
    const exitNameList = (exitNames ?? [])
      .filter(i => !isNil(i?.name) || i?.name === '')
      .filter(x => x.energyMediumId === energyMediumId)
      .map(c => ({ label: `${eMTBasicInfo?.name}--${c?.name}`, value: `${eMTBasicInfo?.name}--${c?.name}` }));
    return [...circuitOptions, ...exitNameList];
  }, [circuitList, exitNames, energyMediumId]);

  // console.log('refIdOptions', refIdOptions);

  return (
    <Select
      placeholder="请选择"
      showSearch
      onChange={value => {
        console.log(value);
        onChange?.(value);
        // setRefValue(value);
        callOptions?.(refIdOptions);
      }}
      value={refValue}
      optionFilterProp="label"
      options={energyMediumId === 2030 ? circuitListOptions : refIdOptions}
      allowClear
    />
  );
};

export default EnergyMediumRef;
