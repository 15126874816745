// 负载类型
export enum LoadType {
  // 生产负载
  production = 1,
  // 柔性负载
  flexible = 2,
}

export const LoadTypeLabel = {
  [LoadType.production]: '生产负载',
  [LoadType.flexible]: '柔性负载',
};
