import { Granularity, StatisticalModel } from '@/pages/OutputConfig/Baseline/BaselineTs';
import { createContext } from 'react';

export interface Point {
  x?: number;
  y?: number;
  abnormalFlag?: boolean;
}

export interface TabItem {
  key: string;
  id?: number;
  label?: string;
  type?: number;
  valueList?: Point[];
}

export interface MultiChartDataProps {
  id?: number;
  name?: string;
  type?: number;
  standardValueFunction?: string;
  standardValueFunctionCoefficient?: string;
  xaxisData?: number[];
  yaxisOriginalData?: number[];
  yaxisFitData?: number[];
  warningUpper?: number[];
  warningLower?: number[];
  valueList?: Point[];
}

export interface LineItemProps {
  baseLineId?: number;
  code?: string;
  name?: string;
  dataSource?: Granularity;
  outputProductId?: number;
  minOutput?: number;
  maxOutput?: number;
  unitCode?: string;
  unitName?: string;
  unit?: string;
  unitTypeCode?: number;
  statisticalModel?: StatisticalModel;
  energyMediumName?: string;
  multiChartData?: MultiChartDataProps[];
  baseLineCycle?: string;
  lineType?: number;
  baseLineGranularity?: string;
  baseLineTime?: string;
  energyMediumId?: number;
  warningUpperLimit?: number;
  warningLowerLimit?: number;
  advancedValuePercent?: number;
  originTabItems?: TabItem[];
}

export interface BaseLineContextProps {
  isDetail?: boolean;
  isEdit?: boolean;
  lineItem?: LineItemProps;
  setLineItem?: (val: LineItemProps) => void;
}

export const BaseLineContext = createContext<BaseLineContextProps>({});

// 基线周期
export enum BaseLineFixCycle {
  YEAR = 'Y',
  QUARTER = 'Q',
  MONTH = 'M',
  WEEK = 'W',
  DAY = 'D',
}

// 基线周期
export enum BaseLineTimeType {
  YEAR = 'Y',
  MONTH = 'M',
  WEEK = 'W',
  DAY = 'D',
}

// 基线时间
export const BaseLineTimeTypeFormat = {
  [BaseLineTimeType.YEAR]: 'year',
  [BaseLineTimeType.MONTH]: 'month',
  [BaseLineTimeType.WEEK]: 'week',
  [BaseLineTimeType.DAY]: 'date',
};

export const BaseLineFixCycleFormat = {
  [BaseLineFixCycle.YEAR]: '年',
  [BaseLineFixCycle.MONTH]: '月',
  [BaseLineFixCycle.WEEK]: '周',
  [BaseLineFixCycle.DAY]: '日',
};

export const BaseLineScrollCycleFormat = {
  [BaseLineFixCycle.QUARTER]: '近三月',
  [BaseLineFixCycle.MONTH]: '近一月',
  [BaseLineFixCycle.WEEK]: '近一周',
};

export enum StatusType {
  DISABLE = 0, // 禁用
  ENABLE = 1, // 启用
}

export const StatusDisplay = {
  [StatusType.DISABLE]: '禁用',
  [StatusType.ENABLE]: '启用',
};

export const statusOptions = [
  { label: '全部', value: '' },
  { label: '启用', value: StatusType.ENABLE },
  { label: '禁用', value: StatusType.DISABLE },
];
