import { Table } from '@maxtropy/components';
import { TableColumnsType } from 'antd';
import { TableDataItem } from '../RightPlanContent';

interface StandardCoalTableProps {
  columns: TableColumnsType<TableDataItem>;
  standardCoalTableData: TableDataItem[];
}

const StandardCoalTable: React.FC<StandardCoalTableProps> = props => {
  const { columns, standardCoalTableData } = props;

  return (
    <div
      style={{
        height: `calc(100vh - 450px)`,
        minWidth: 535,
        overflow: 'auto',
      }}
    >
      <Table rowKey="key" columns={columns} dataSource={standardCoalTableData} scroll={{ x: 1300 }} />
    </div>
  );
};

export default StandardCoalTable;
