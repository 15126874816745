export const themeDataShow = {
  header: {
    color: '#1890ff',
    activeColor: '#FFFFFF',
    backgroundColor: '#000000',
    btnList: [
      {
        iconId: 'deviceID',
        part: 'left',
        defaultActive: 0,
        memo: '顶部设备序列号',
        isrender: 1,
      },
      {
        iconId: 'deviceName',
        part: 'left',
        defaultActive: 0,
        memo: '顶部设备名称',
        isrender: 1,
      },
      {
        iconId: 'cloudRec',
        part: 'right',
        defaultActive: 0,
        memo: '云存储',
        isrender: 0,
      },
      {
        iconId: 'rec',
        part: 'right',
        defaultActive: 0,
        memo: 'SD卡回放',
        isrender: 0,
      },
    ],
  },
  footer: {
    color: '#FFFFFF',
    activeColor: '#1890FF',
    backgroundColor: '#00000021',
    btnList: [
      {
        iconId: 'play',
        part: 'left',
        defaultActive: 1,
        memo: '播放',
        isrender: 1,
      },
      {
        iconId: 'capturePicture',
        part: 'left',
        defaultActive: 0,
        memo: '截屏按钮',
        isrender: 1,
      },
      {
        iconId: 'sound',
        part: 'left',
        defaultActive: 0,
        memo: '声音按钮',
        isrender: 1,
      },
      {
        iconId: 'pantile',
        part: 'left',
        defaultActive: 0,
        memo: '云台控制按钮',
        isrender: 1,
      },
      {
        iconId: 'recordvideo',
        part: 'left',
        defaultActive: 0,
        memo: '录制按钮',
        isrender: 1,
      },
      {
        iconId: 'talk',
        part: 'left',
        defaultActive: 0,
        memo: '对讲按钮',
        isrender: 1,
      },
      {
        iconId: 'zoom',
        part: 'left',
        defaultActive: 0,
        memo: '电子放大',
        isrender: 1,
      },
      {
        iconId: 'hd',
        part: 'right',
        defaultActive: 0,
        memo: '清晰度切换按钮',
        isrender: 1,
      },
      {
        iconId: 'webExpend',
        part: 'right',
        defaultActive: 0,
        memo: '网页全屏按钮',
        isrender: 1,
      },
      {
        iconId: 'expend',
        part: 'right',
        defaultActive: 0,
        memo: '全局全屏按钮',
        isrender: 1,
      },
    ],
  },
};
export const themeDataHide = {
  header: {
    color: '#1890ff',
    activeColor: '#FFFFFF',
    backgroundColor: '#000000',
    btnList: [
      {
        iconId: 'deviceID',
        part: 'left',
        defaultActive: 0,
        memo: '顶部设备序列号',
        isrender: 0,
      },
      {
        iconId: 'deviceName',
        part: 'left',
        defaultActive: 0,
        memo: '顶部设备名称',
        isrender: 0,
      },
      {
        iconId: 'cloudRec',
        part: 'right',
        defaultActive: 0,
        memo: '云存储',
        isrender: 0,
      },
      {
        iconId: 'rec',
        part: 'right',
        defaultActive: 0,
        memo: 'SD卡回放',
        isrender: 0,
      },
    ],
  },
  footer: {
    color: '#FFFFFF',
    activeColor: '#1890FF',
    backgroundColor: '#00000021',
    btnList: [
      {
        iconId: 'play',
        part: 'left',
        defaultActive: 1,
        memo: '播放',
        isrender: 0,
      },
      {
        iconId: 'capturePicture',
        part: 'left',
        defaultActive: 0,
        memo: '截屏按钮',
        isrender: 0,
      },
      {
        iconId: 'sound',
        part: 'left',
        defaultActive: 0,
        memo: '声音按钮',
        isrender: 0,
      },
      {
        iconId: 'pantile',
        part: 'left',
        defaultActive: 0,
        memo: '云台控制按钮',
        isrender: 0,
      },
      {
        iconId: 'recordvideo',
        part: 'left',
        defaultActive: 0,
        memo: '录制按钮',
        isrender: 0,
      },
      {
        iconId: 'talk',
        part: 'left',
        defaultActive: 0,
        memo: '对讲按钮',
        isrender: 0,
      },
      {
        iconId: 'zoom',
        part: 'left',
        defaultActive: 0,
        memo: '电子放大',
        isrender: 0,
      },
      {
        iconId: 'hd',
        part: 'right',
        defaultActive: 0,
        memo: '清晰度切换按钮',
        isrender: 0,
      },
      {
        iconId: 'webExpend',
        part: 'right',
        defaultActive: 0,
        memo: '网页全屏按钮',
        isrender: 0,
      },
      {
        iconId: 'expend',
        part: 'right',
        defaultActive: 0,
        memo: '全局全屏按钮',
        isrender: 0,
      },
    ],
  },
};
