import { Node } from '@antv/x6';
import React, { useMemo, useState, useEffect } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import img from '../assets/productionBase.png';
import styles from '../index.module.scss';
import { NodeData } from '../index';
import { commonNodeSize, nodeHeightAddStep } from '../utils/const';
import type { Animation } from '@antv/x6/es/model/animation';
import { apiV2EnergyConsumptionProductionBaseAggrValuePost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import { getRealUrl, EllipsisSpan } from '@maxtropy/components';
import { Tooltip as AntdTooltip } from 'antd';

const ProductionBaseNode = ({ node }: { node: Node }) => {
  const [isExpand, setIsExpand] = useState(true);
  const [showExpandIcon, setShowExpandIcon] = useState<boolean>(true);
  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const currentSettingTypeFromURL = searchParams.get('currentSettingType');
    if (isNil(currentSettingTypeFromURL)) return;
    if (Number(currentSettingTypeFromURL) !== 1) {
      setIsExpand(false);
      setShowExpandIcon(false);
      const height = unExpandHeight;
      const y = node.getPosition().y + diffHeight / 2;
      const opt: Animation.BaseOptions = {
        delay: 0,
        duration: 300,
        timing: 'linear',
      };
      node.transition('size/height', height, opt);
      node.transition('position/y', y, opt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const energyUnits = useMemo(() => {
    const data = node.getData() as NodeData;
    return data.unitList;
  }, [node]);

  const expandHeight = useMemo(() => {
    return getProductionBaseNodeExpandHeight(node.getData());
  }, [node]);

  const unExpandHeight = useMemo(() => {
    return getProductionBaseNodeUnexpandHeight(node.getData());
  }, [node]);

  const diffHeight = useMemo(() => {
    return expandHeight - unExpandHeight;
  }, [expandHeight, unExpandHeight]);

  const onExpand = () => {
    let changedIsExpand = !isExpand;
    setIsExpand(changedIsExpand);
    const height = changedIsExpand ? expandHeight : unExpandHeight;
    const y = changedIsExpand ? node.getPosition().y - diffHeight / 2 : node.getPosition().y + diffHeight / 2;
    const opt: Animation.BaseOptions = {
      delay: 0,
      duration: 300,
      timing: 'linear',
    };
    node.transition('size/height', height, opt);
    node.transition('position/y', y, opt);
  };
  const [unitAggr, setUnitAggr] = useState<any>([]);
  const [totalEnergyConsumptionStandardCoal, setTotalEnergyConsumptionStandardCoal] = useState<any>([]);

  const [totalcarbonEmissionConsumption, setTotalcarbonEmissionConsumption] = useState<any>([]);

  useEffect(() => {
    if (!isNil(unitAggr)) {
      const totalEnergyConsumptionStandardCoal = unitAggr.reduce((sum: number, item: any) => {
        return sum + item.energyConsumptionStandardCoal;
      }, 0);
      const totalcarbonEmissionConsumption = unitAggr.reduce((sum: number, item: any) => {
        return sum + item.carbonEmissionConsumption;
      }, 0);
      setTotalEnergyConsumptionStandardCoal(totalEnergyConsumptionStandardCoal);
      setTotalcarbonEmissionConsumption(totalcarbonEmissionConsumption);
    }
  }, [unitAggr]);

  const handleTitleNameHover = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const fromTime = searchParams.get('fromTime') || undefined;
    const toTime = searchParams.get('toTime') || undefined;
    const currentDate = searchParams.get('currentDate') || '--';

    setCurrentDate(currentDate);
    if (!isNil(fromTime) && !isNil(toTime)) {
      apiV2EnergyConsumptionProductionBaseAggrValuePost({
        productionBaseId: node.getData().id,
        workCenterIds: node.getData().children.map((item: any) => item.id),
        fromTime,
        toTime,
      }).then(res => {
        setUnitAggr(res.list);
        console.log(res.list);
      });
    }
  };

  return (
    <div
      className={classNames(styles.commonNode, styles.flexCenter)}
      style={{
        background: 'rgba(56, 77, 47, 1)',
        borderColor: 'rgba(88, 139, 63, 1)',
      }}
    >
      <div className={styles.nodeContent}>
        <div
          className={styles.nodeTitle}
          style={{
            height: isExpand ? undefined : '100%',
          }}
        >
          <img src={getRealUrl(node.getData()?.iconKey) ?? img} className={styles.nodeIcon} alt="" />
          <AntdTooltip
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
            title={
              <div className={styles.toolitipWrapper}>
                <div className={styles.toolitipTime}>{currentDate}</div>
                <div className={styles.name}>
                  <div className={styles.productionBaseBefore}></div>
                  <span className={styles.nameDetail}>{node.getData().name}</span>
                </div>
                <div className={styles.energyDetail}>
                  <div className={styles.totalConsumption}>
                    <div> 综合能耗 </div>
                    <div>
                      {totalEnergyConsumptionStandardCoal < 1000
                        ? Number(totalEnergyConsumptionStandardCoal).toFixed(2)
                        : Number(totalEnergyConsumptionStandardCoal / 1000).toFixed(2)}
                      {totalEnergyConsumptionStandardCoal >= 1000 ? 'tce' : 'kgce'}
                    </div>
                  </div>
                  <div className={styles.totalConsumption}>
                    <div> 碳排 </div>
                    <div>
                      {totalcarbonEmissionConsumption < 1000
                        ? Number(totalcarbonEmissionConsumption).toFixed(2)
                        : Number(totalcarbonEmissionConsumption / 1000).toFixed(2)}
                      {totalcarbonEmissionConsumption >= 1000 ? 'tCO2e' : 'kgCO2e'}
                    </div>
                  </div>
                  {unitAggr.map((item: any) => {
                    return (
                      <div className={styles.totalConsumption}>
                        <div>{item.energyMediumName}</div>
                        <div>
                          {Number(item.energyConsumption).toFixed(2)}
                          {item.physicalUnitGeneralName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
          >
            <div onMouseEnter={handleTitleNameHover} className={styles.titleName}>
              {node.getData().name}
            </div>
          </AntdTooltip>
          {showExpandIcon &&
            (isExpand ? (
              <CaretDownOutlined className={styles.nodeExpandIcon} onClick={onExpand} />
            ) : (
              <CaretUpOutlined className={styles.nodeExpandIcon} onClick={onExpand} />
            ))}
        </div>
        {isExpand && !!energyUnits?.length && (
          <>
            <div className={styles.nodeTitleLine}></div>
            <div
              className={styles.expandContent}
              onWheel={e => {
                e.stopPropagation();
              }}
            >
              {energyUnits?.map((energyUnit, index) => {
                return (
                  <div className={styles.contentItem} key={index}>
                    <div className={styles.itemRect}></div>
                    <EllipsisSpan value={energyUnit.name} className={styles.titleName}></EllipsisSpan>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductionBaseNode;

export function getProductionBaseNodeExpandHeight(data: NodeData) {
  let energyUnitCount = data.unitList?.length || 0;
  // 高度=标题头高度 + border(2) + 标题下分割线(1) + 底部margin(12) + 能源单元高度
  const height =
    commonNodeSize.height + 2 + 1 + 12 + (energyUnitCount > 10 ? 10 : energyUnitCount) * nodeHeightAddStep.expand;
  return Math.max(height, getProductionBaseNodeUnexpandHeight(data));
}

export function getProductionBaseNodeUnexpandHeight(data: NodeData) {
  if (data?.children?.length! > 2) {
    return commonNodeSize.height + nodeHeightAddStep.unexpand * (data?.children?.length! - 2);
  }
  return commonNodeSize.height;
}
