import 'swiper/scss';
import styles from './index.module.scss';
import { useState, useMemo } from 'react';
import { Tooltip } from '@maxtropy/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import compressedAir from '../../assets/compressedAir-selected@2x.png';
import steam from '../../assets/steam-selected@2x.png';
import electricity from '../../assets/electricity-selected@2x.png';
import IndustrialLiquid from '../../assets/IndustrialLiquid-selected@2x.png';
import other from '../../assets/other-selected@2x.png';
import water from '../../assets/water-selected@2x.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
// install Autoplay modules
SwiperCore.use([Autoplay]);

enum EnergyMediumSceneEnum {
  COMPRESSED_AIR = 2021, //压缩空气
  HOT_STEAM = 2015, //过热蒸汽
  ELEC = 2030, // 电能
  INDUSTRIAL_LIQUIDS = 2031, //工业液体
  WATER = 2001, //新水
}

const energyMediumImageMap = {
  [EnergyMediumSceneEnum.COMPRESSED_AIR]: compressedAir,
  [EnergyMediumSceneEnum.HOT_STEAM]: steam,
  [EnergyMediumSceneEnum.ELEC]: electricity,
  [EnergyMediumSceneEnum.INDUSTRIAL_LIQUIDS]: IndustrialLiquid,
  [EnergyMediumSceneEnum.WATER]: water,
};

export interface EnergyData {
  fromTime: string;
  toTime: string;
  energyConsumptionStandardCoal: number;
  energyMediumId: number;
  energyMediumName: string;
  energyConsumption: number;
  physicalUnitId: number;
  physicalUnitGeneralName: string;
  workCenterId: number;
}

interface MonitorInfoSwiperProps {
  aggrValue: EnergyData[];
  currentDate: string;
}

const WorkerCenterSwiper: React.FC<MonitorInfoSwiperProps> = ({ aggrValue, currentDate }) => {
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  const slidesPerView = useMemo(() => {
    if (aggrValue.length === 2) {
      return 2;
    } else if (aggrValue.length >= 3) {
      return 3;
    }
    return 1;
  }, [aggrValue]);

  return (
    <div style={{ height: 141 }}>
      <div className={styles.energyTitle}>
        用能总览
        <span className={styles.energyDate}>{`${'('}${!isNil(currentDate) ? currentDate : '--'}${')'}`}</span>
      </div>
      {aggrValue.length > 0 ? (
        <div className={styles.energyStatisticsSwiper}>
          <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slidePrev()}>
            <LeftOutlined></LeftOutlined>
          </div>
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            spaceBetween={8}
            slidesPerView={slidesPerView}
            freeMode={true}
            modules={[Autoplay]}
            observer={true}
            observeParents={true}
            onSwiper={swiper => setSwiperIntance(swiper)}
          >
            {aggrValue.map((m: EnergyData, index: number) => {
              const imagePath = energyMediumImageMap[m.energyMediumId as EnergyMediumSceneEnum] || other;

              return (
                <SwiperSlide key={m.energyMediumId}>
                  <div className={styles.cardStyles}>
                    <div className={styles.singleCard}>
                      <div className={styles.withBorder}>
                        <img src={imagePath} alt={m.energyMediumName} width={24} className={styles.carImg} />
                        <Tooltip
                          title={`${m.energyMediumName}(${
                            !isNil(m.physicalUnitGeneralName) ? m.physicalUnitGeneralName : '--'
                          })`}
                        >
                          <p className={styles.energyName}>{`${m.energyMediumName}(${
                            !isNil(m.physicalUnitGeneralName) ? m.physicalUnitGeneralName : '--'
                          })`}</p>
                        </Tooltip>
                        <Tooltip title={m.energyConsumption.toFixed(2)}>
                          <p className={styles.text}>{m.energyConsumption.toFixed(2)}</p>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div style={{ cursor: 'pointer' }} onClick={() => swiperIntance?.slideNext()}>
            <RightOutlined />
          </div>
          <div className={styles.verticalLineRight}></div>
        </div>
      ) : (
        <div className={styles.energyStatisticsSwiper}>
          <div className={styles.noData}> 暂无数据</div>
        </div>
      )}
    </div>
  );
};

export default WorkerCenterSwiper;
