import {
  Table,
  Paging,
  usePaging,
  useUpdate,
  EllipsisSpan,
  Button,
  useAsync,
  Modal,
  Select,
  Filter,
  Form,
  Input,
  Checkbox,
} from '@maxtropy/components';
import { Col, Divider } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isNil, uniqBy } from 'lodash-es';

import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { formatOptionData, getAllChild } from '@/shared/components/CascadingMultipleSelector/utils';
import {
  DataProperty,
  ReportStrategyDataPropertyList,
  ReportStrategyDevicePageResponse,
} from '../../../../api/reportStrategy';

import CheckDataProperty from './CheckDataProperty';
import { ReportStrategyStatisticalTypeEnum } from '@/shared/types';
import styles from './index.module.scss';
import {
  apiV2ReportStrategyDeviceListPost,
  V2ReportStrategyDeviceListPostRequest,
} from '@maxtropy/device-customer-apis-v2';
import { getOrganization, OrganizationResponse } from '@/api/customer-user';
import { OuListResponse } from '@/api/ou';
import { getDeviceTypeTree } from '@/api/deviceType';

interface TiledArrayProps {
  key: string;
  title: string;
}

const tiledArray = (data: OrganizationResponse[]) => {
  const res: TiledArrayProps[] = [];
  function loop(array: OrganizationResponse[]) {
    array.forEach(item => {
      if (item.data.name) {
        res.push({
          key: item.data.mcid,
          title: item.data?.name,
        });
      }
      if (item.children) loop(item.children);
    });
  }
  loop(data);
  return res;
};

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ous',
    ellipsis: { showTitle: true },
    render: (v: OuListResponse[]) => <EllipsisSpan value={v?.map(item => item.name).join(',')} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tagList',
    ellipsis: { showTitle: true },
    render: (v: string[]) => (
      <div style={{ minWidth: 50 }}>
        <EllipsisSpan value={(v ?? []).join(',') || undefined} />
      </div>
    ),
  },
];

export interface DevicesModalProps {
  open: boolean;
  onClose: (visible: boolean) => void;
  value?: ReportStrategyDataPropertyList[];
  onChange?: (value: ReportStrategyDataPropertyList[]) => void;
  ous?: OuListResponse[];
  statisticalType?: ReportStrategyStatisticalTypeEnum;
}

interface FilterParams {
  nameOrCode: string | undefined;
  deviceTypeIds: Array<Array<number>> | undefined;
  tagList?: string[];
}

const DevicesModal: FC<DevicesModalProps> = ({ open, value, onChange, onClose, ous, statisticalType }) => {
  const [form] = Form.useForm();
  const deviceTypeData = useAsync(getDeviceTypeTree);
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<V2ReportStrategyDeviceListPostRequest>();
  const [devices, setDevices] = useState<ReportStrategyDevicePageResponse[]>([]);
  const [tableDevices, setTableDevices] = useState<ReportStrategyDevicePageResponse[]>([]);
  const [updateState] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ReportStrategyDevicePageResponse[]>([]);
  const [currentCheckDevice, setCurrentCheckDevice] = useState<ReportStrategyDevicePageResponse>();
  const [cacheCheckData, setCacheCheckData] = useState<DataProperty[]>([]);
  const [organization, setOrganization] = useState<TiledArrayProps[]>([]);

  const ouOptions = useMemo(() => {
    if (ous?.length !== 0) {
      return ous?.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ous]);

  const [ouSearchValue, setOuSearchValue] = useState<string>('');
  const ouIds = Form.useWatch('ouIds', form);
  const allOuSelected = useMemo(() => ouIds?.length === ous?.length, [ous?.length, ouIds?.length]);

  useEffect(() => {
    getOrganization().then(organization => {
      if (organization) {
        setOrganization(tiledArray([organization]));
      } else {
        setOrganization([]);
      }
    });
  }, []);

  useEffect(() => {
    const rows = (value ?? []).map(i => {
      return (
        tableDevices.find(m => m.id === i.deviceId) ?? {
          id: i.deviceId,
          code: i.deviceCode,
          name: i.deviceName,
          dataPropertyList: i.dataPropertyList,
        }
      );
    });
    const newRows = open ? uniqBy([...rows, ...selectedRows], 'id') : rows;
    setSelectedRows(newRows);
  }, [value, tableDevices]);

  useEffect(() => {
    if (!isNil(statisticalType)) {
      setLoading(true);
      apiV2ReportStrategyDeviceListPost({
        ...searchParams,
        ouIds: searchParams?.ouIds?.length ? searchParams.ouIds : ous?.map(item => item.id),
        measurementType: statisticalType,
        page: pageOffset,
        size: pageSize,
      })
        .then(res => {
          if (res && res.list) {
            setTotalCount(res.total ?? 0);
            setDevices(res.list ?? []);
          } else {
            setTotalCount(0);
            setDevices([]);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [statisticalType, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  useEffect(() => {
    const newDevices = devices.map(m => {
      const checkedProps = value?.find(v => v.deviceId === m.id)?.dataPropertyList;
      return {
        ...m,
        checkedPropertyList: checkedProps ?? [],
      };
    });
    setTableDevices(newDevices);
  }, [devices, value]);

  const onOk = () => {
    const newValues = selectedRows.map(k => ({
      deviceId: k.id!,
      deviceCode: k.code!,
      deviceName: k.name!,
      dataPropertyList: k.checkedPropertyList,
    }));
    onChange?.([...newValues]);
    onClose(false);
  };

  const onFinish = (value: FilterParams) => {
    const deviceTypeIds = getAllChild(value.deviceTypeIds, formatOptionData(deviceTypeData));
    setSearchParams({
      ...value,
      deviceTypeIds: deviceTypeIds,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      deviceTypes: undefined,
      tagName: undefined,
    });
    setPageOffset(1);
  };

  const onCheckdevice = (record: ReportStrategyDevicePageResponse) => {
    setCurrentCheckDevice(record);
    const filterRecordValue = (tableDevices ?? []).find(i => i.id === record.id);
    if (filterRecordValue) {
      setCacheCheckData(filterRecordValue.checkedPropertyList ?? []);
    } else {
      setCacheCheckData([]);
    }
  };

  const onPropertyCancel = useCallback(() => {
    setCurrentCheckDevice(undefined);
    setCacheCheckData([]);
  }, []);

  const onPropertyOk = () => {
    const index = (tableDevices ?? []).findIndex(i => i.id === currentCheckDevice?.id);
    if (index > -1) {
      tableDevices[index].checkedPropertyList = cacheCheckData;
    }
    setTableDevices([...tableDevices]);
    onPropertyCancel();
  };

  const onSelectAll = (selected: boolean, newSelectedRows: any, changeRows: any) => {
    const changeRowKeys = changeRows.map((k: any) => k.id);
    if (selected) {
      setSelectedRows(uniqBy([...selectedRows, ...changeRows], 'id'));
    } else {
      const restRows = selectedRows.filter(m => !changeRowKeys.includes(m.id));
      setSelectedRows([...restRows]);
    }
  };

  const onRowSelect = (record: any, selected: boolean) => {
    if (selected) {
      setSelectedRows([...selectedRows, record]);
    } else {
      setSelectedRows(selectedRows.filter(m => m.id !== record.id));
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows.map(m => m.id!),
    onSelectAll: onSelectAll,
    onSelect: onRowSelect,
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 130,
      fixed: 'right' as const,
      render: (value: undefined, record: ReportStrategyDevicePageResponse) => {
        return (
          <Button
            type="link"
            onClick={() => {
              const isChecked = selectedRows.some(i => record.id === i?.id);
              if (!isChecked) {
                Modal.warning({ title: '请先勾选该设备' });
                return;
              }
              onCheckdevice(record);
            }}
          >
            选择统计的数据
          </Button>
        );
      },
    },
  ];

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      collapseOpen={true}
      onReset={onReset}
      collapseItems={
        <>
          <Col span={8}>
            <Form.Item name="nameOrCode" label="编号/名称">
              <Input placeholder="请输入编号或名称查询" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tagName" label="设备标签">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="mcids" label="所属组织">
              <Select placeholder="请选择" allowClear mode="multiple" maxTagCount="responsive">
                {organization.map(i => (
                  <Select.Option value={i.key} key={i.key}>
                    {i.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ouIds" label="所属运营单元">
              <Select
                placeholder="请选择"
                allowClear
                mode="multiple"
                options={ouOptions}
                maxTagCount="responsive"
                showSearch
                optionFilterProp="label"
                searchValue={ouSearchValue}
                onSearch={value => setOuSearchValue(value)}
                onChange={() => {
                  setOuSearchValue('');
                }}
                dropdownRender={menu => (
                  <>
                    {!ouSearchValue.length && ous?.length ? (
                      <div style={{ padding: '4px 8px 8px 12px', cursor: 'pointer' }}>
                        <Checkbox
                          indeterminate={ouIds?.length && ouIds?.length < (ous?.length ?? 0)}
                          checked={allOuSelected}
                          onChange={e => {
                            if (e.target.checked) {
                              // 全选
                              form.setFieldValue(
                                'ouIds',
                                ous?.map(v => v.id)
                              );
                            } else {
                              // 反选
                              form.setFieldValue('ouIds', []);
                            }
                          }}
                        >
                          全选
                        </Checkbox>
                      </div>
                    ) : null}
                    <Divider style={{ margin: 0 }} />
                    {menu}
                  </>
                )}
              ></Select>
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={24}>
          <Form.Item name="deviceTypeIds">
            <CascadingMultipleSelector
              size="big"
              labels={deviceTypeData?.aliasName}
              data={formatOptionData(deviceTypeData)}
              mode={['single']}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Modal
      title="设备选择"
      className={styles.deviceModal}
      destroyOnClose
      size="big"
      open={open}
      onOk={onOk}
      onCancel={() => onClose(false)}
    >
      {filters}
      <Table
        loading={loading}
        sticky
        scroll={{ y: 300, x: 'max-content' }}
        rowKey="id"
        columns={buildColumns}
        dataSource={tableDevices}
        rowSelection={rowSelection}
        showSelectedCount={true}
        selectedCount={selectedRows?.length ?? 0}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>

      <Modal
        title="设备选择"
        contentClassName="modal-form-content"
        destroyOnClose
        open={!!currentCheckDevice}
        onOk={onPropertyOk}
        onCancel={onPropertyCancel}
        size="big"
      >
        <CheckDataProperty
          data={currentCheckDevice}
          value={cacheCheckData}
          onChange={setCacheCheckData}
          statisticalType={statisticalType}
        />
      </Modal>
    </Modal>
  );
};

export default DevicesModal;
