import { Modal, Tag, Form, ShowInput } from '@maxtropy/components';
import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { isNil } from 'lodash';
import { apiV2EnergyDetailPost } from '@maxtropy/device-customer-apis-v2';
import { StatusType } from '..';
import styles from './index.module.scss';
import {
  CarbonFootprintSource,
  CarbonFootprintSourceDisplay,
  SystemBoundary,
  SystemBoundaryDisplay,
} from '@/api/materielManagement';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedId?: number;
}

const DetailMaterielModal: React.FC<Props> = ({ open, setOpen, selectedId }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isNil(selectedId)) return;
    apiV2EnergyDetailPost({ id: selectedId }).then(res => {
      form.setFieldsValue({
        ...res,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal size="large" title="能源详情" className={styles.detailModal} open={open} onOk={onClose} onCancel={onClose}>
      <Form form={form} layout="vertical">
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="能源工质类型" name="energyMediumName">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="能源编码" name="code">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="能源名称" name="name">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="能源单位" name="physicalUnitName">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="碳足迹" name="carbonFootPrint">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="碳足迹单位" dependencies={['physicalUnitName']}>
              {({ getFieldValue }) => {
                const physicalUnitName = getFieldValue('physicalUnitName');
                return `kgCO2e/${physicalUnitName ?? '--'}`;
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="碳足迹来源" dependencies={['footPrintOrigin']}>
              {({ getFieldValue }) => {
                const footerOrigin = getFieldValue('footPrintOrigin');
                return CarbonFootprintSourceDisplay[footerOrigin as CarbonFootprintSource];
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="系统边界" dependencies={['boundary']}>
              {({ getFieldValue }) => {
                const boundary = getFieldValue('boundary');
                return SystemBoundaryDisplay[boundary as SystemBoundary];
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="状态" dependencies={['state']}>
              {({ getFieldValue }) => {
                return getFieldValue('state') === StatusType.ENABLE ? (
                  <Tag border="solid" color="success">
                    启用
                  </Tag>
                ) : (
                  <Tag border="solid" color="invalid">
                    禁用
                  </Tag>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="备注" name="remark">
              <ShowInput />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DetailMaterielModal;
