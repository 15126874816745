import { Form, Button, ShowInput, Tabs, Empty, BaseInfoContent } from '@maxtropy/components';
import { Space } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import styles from './index.module.scss';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import { BaseLineContext } from '../utils/common';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router';

interface GenerateBaseLineProps {
  prevStep?: () => void;
}

const GenerateBaseLine: React.FC<GenerateBaseLineProps> = ({ prevStep }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { lineItem, isDetail } = useContext(BaseLineContext);
  const [activeKey, setActiveKey] = useState<string>();
  const [tabItems, setTabItems] = useState<any[]>([]);
  const echartsTheme = useEchartsTheme();

  useEffect(() => {
    if (!isNil(lineItem?.multiChartData)) {
      const tabs = (lineItem?.multiChartData ?? []).map(item => ({
        key: item.name + '-' + item.id,
        label: item.name,
        type: item.type,
        ...item,
      }));
      setTabItems(tabs);
      setActiveKey(tabs?.[0]?.key);
    }
  }, [lineItem]);

  const currentTab = useMemo(() => {
    return tabItems.find(k => k.key === activeKey);
  }, [activeKey, tabItems]);

  const onTabChange = (val: string) => {
    setActiveKey(val);
  };

  const option = useMemo(() => {
    return {
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'black',
        textStyle: {
          color: 'rgba(255, 255, 255, 0.85)',
          fontSize: 12,
        },
        borderColor: 'black',
        formatter: (items: any) => {
          let { axisValue } = items[0];
          let tipStr = `<div style="width:200px;line-height:20px;display:flex;justify-content:space-between;"><div>生产速率</div><div>${
            axisValue?.toFixed(2) ?? '--'
          }${lineItem?.unit}/时</div></div>`;
          items.forEach((item: any, index: number) => {
            if (index < 4) {
              const { seriesName, data, marker } = item;
              tipStr += `<div style="width:200px;line-height:20px;margin-top:8px;display:flex;justify-content:space-between;"><div>${marker}${seriesName}</div><div>${data?.[1]?.toFixed(
                2
              )}</div></div>`;
            }
          });
          return tipStr;
        },
      },
      // 网格距离上面距离
      grid: {
        top: 60,
        left: 60,
        right: 60,
      },
      backgroundColor: '#232324',
      xAxis: {
        type: 'value',
        splitLine: {
          show: true, // 显示分隔线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        nameTextStyle: {
          lineHeight: 24, //标题行高
          verticalAlign: 'top', //标题位置
        },
        name: `${lineItem?.unit}/时`,
        min: lineItem?.minOutput,
        max: lineItem?.maxOutput,
        data: currentTab?.xaxisData ?? [],
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        axisLine: {
          show: false,
        },
        name: '单耗',
        nameGap: 18,
        nameTextStyle: {
          padding: [0, 30, 0, 0],
        },
        axisTick: { show: false },
      },
      series: [
        {
          name: '单耗基准值基线',
          type: 'line',
          data: (currentTab?.yaxisFitData ?? []).map((item: any, index: number) => [currentTab.xaxisData[index], item]),
          areaStyle: {
            color: {
              //线性渐变
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(2, 164, 239, 1)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(35, 35, 35,0.5)', // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          z: 2,
          showSymbol: false, //显示线上的点
          lineStyle: {
            width: 2,
            type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
          },
          emphasis: { disabled: true },
          smooth: true,
          itemStyle: {
            color: '#59D744',
          },
        },
        {
          name: '单耗先进值基线',
          type: 'line',
          data: (currentTab?.advancedData ?? []).map((item: any, index: number) => [currentTab.xaxisData[index], item]),
          showSymbol: false, //显示线上的点
          emphasis: { disabled: true },
          z: 3,
          lineStyle: {
            width: 2,
          },
          smooth: true,
          itemStyle: {
            color: '#52E7FF',
          },
        },
        {
          name: '单耗基线上限',
          type: 'line',
          data: (currentTab?.warningUpper ?? []).map((item: any, index: number) => [currentTab.xaxisData[index], item]),
          showSymbol: false, //显示线上的点
          lineStyle: {
            width: 1,
            type: 'dashed',
          },
          areaStyle: {
            color: {
              //线性渐变
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(180, 63, 65, 1)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(35, 35, 35,0.5)', // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },

          z: 1,
          smooth: true,
          itemStyle: {
            color: '#FF4D4F',
          },
          emphasis: { disabled: true },
        },
        {
          name: '单耗基线下限',
          type: 'line',
          data: (currentTab?.warningLower ?? []).map((item: any, index: number) => [currentTab.xaxisData[index], item]),
          showSymbol: false, //显示线上的点
          areaStyle: {
            color: {
              //线性渐变
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(35, 35, 35, 1)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(35, 35, 35,1)', // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          emphasis: { disabled: true },
          z: 3,
          lineStyle: {
            width: 1,
            type: 'dashed',
          },
          smooth: true,
          itemStyle: {
            color: '#00ADFF',
          },
        },
        {
          type: 'scatter',
          data: (currentTab?.valueList ?? []).map((item: any, index: number) => [item.x, item.y]),
          itemStyle: {
            color: '#10AEFF',
            borderColor: 'rgba(16, 174, 255, 0.3)',
            borderWidth: 4,
          },
          symbol: 'circle', //折线节点为小圆点
          symbolSize: 10, //折线点大小
          z: 4,
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  return (
    <div className={styles.generateBaseLine}>
      <Form form={form} layout="horizontal">
        <BaseInfoContent colNum={3} layout="horizontal">
          <Form.Item label="产出物编码">
            <ShowInput value={lineItem?.code} />
          </Form.Item>
          <Form.Item label="产出物名称">
            <ShowInput value={lineItem?.name} />
          </Form.Item>
          <Form.Item label="能源类型">
            <ShowInput value={lineItem?.energyMediumName} />
          </Form.Item>
        </BaseInfoContent>
      </Form>
      {tabItems.length ? (
        <div style={{ marginTop: 24 }}>
          <Tabs
            activeKey={activeKey}
            items={tabItems.map(m => ({
              label: m.label,
              key: m.key,
            }))}
            onChange={onTabChange}
          />
          <div className={styles.middleInfo}>
            <div className={styles.infoCol}>
              {/* <div className={styles.colVal}>
                <span>基线公式：</span>
                {currentTab?.standardValueFunction ?? '--'}
              </div> */}
              <div className={styles.colVal}>
                <span>生产速率区间：</span> {`[${lineItem?.minOutput ?? '--'},${lineItem?.maxOutput ?? '--'}]`}
              </div>
            </div>
            <div className={styles.infoCol}>
              <div className={styles.colVal}>
                <span>基线带宽：</span>
                {`[${lineItem?.warningLowerLimit ?? '--'}% , ${lineItem?.warningUpperLimit ?? '--'}%]`}
              </div>
              {/* <Button type="link" style={{ padding: 0 }}>
                查看更多
                <DoubleRightOutlined />
              </Button> */}
            </div>
            <div>{/* <Progress type="circle" percent={30} size={80} /> */}</div>
          </div>
          <ReactEcharts
            className={styles.ReactEcharts}
            option={option}
            style={{ minHeight: 500, position: 'relative' }}
            notMerge
            lazyUpdate={false}
            theme={echartsTheme}
          />
        </div>
      ) : (
        <Empty style={{ margin: '80px 0' }} description="暂无数据" />
      )}

      {!isDetail && (
        <Space size={8}>
          <Button
            onClick={() => {
              prevStep?.();
            }}
          >
            上一步
          </Button>
          <Button
            onClick={() => {
              navigate('/systemConfig/productionTopo/baseLineManage');
            }}
            type="primary"
          >
            完成
          </Button>
        </Space>
      )}
    </div>
  );
};
export default GenerateBaseLine;
