import { Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { FC } from 'react';

import styles from './index.module.scss';

import ElectricityStatistics from './Electricity';

// const routes = [
//   {name: '运营配置'},
//   {name: '能源费率管理'},
//   {name: '用量统计'},
// ];

const Statistics: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      <Tabs defaultActiveKey="1" className={styles.tabs}>
        <Tabs.TabPane tab="用电用量" key="1">
          <ElectricityStatistics />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default Statistics;
