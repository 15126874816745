import styles from './index.module.scss';
import { Card } from 'antd';
import { ReportsUploadListRes } from '../../../../api/reportDownloadList';
import { getRealUrl, Button } from '@maxtropy/components';
import defaultImg from './default.png';
import dayjs from 'dayjs';
import classNames from 'classnames';

// 下载文件并重命名
function onDownload(url: string, fileName: string) {
  let x = new XMLHttpRequest();
  x.open('GET', `${url}`, true);
  x.responseType = 'blob';
  x.onload = function () {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.pdf`;
    a.click();
  };
  x.send();
}

interface Props {
  info: ReportsUploadListRes;
}

const dateFormat = 'YYYY-MM-DD';

const ReportCard: React.FC<Props> = ({ info }) => {
  return (
    <Card
      bodyStyle={{ padding: 0 }}
      className={styles.card}
      hoverable
      cover={
        info.coverPicResource ? (
          <div className={styles.picWrapper}>
            <img className={styles.pic} alt="封面图" src={getRealUrl(info.coverPicResource)} />
          </div>
        ) : (
          <div className={styles.picWrapper}>
            <img className={styles.pic} alt="封面图" src={defaultImg} />
          </div>
        )
      }
      onClick={() => {
        window.open(getRealUrl(info.resource) + '&preview=true');
      }}
    >
      <div className={styles.middleInfo}>
        <p className={classNames(styles.reportName, styles.infoStyle)}>{info.name}</p>
        <p className={styles.infoStyle}>{dayjs(info.reportTime).format(dateFormat)}</p>
      </div>
      <div className={styles.bottomInfo}>
        <div className={styles.sameStyleNames}>
          <span>{info.typeName}</span>
        </div>
        <div className={classNames(styles.btn, styles.sameStyle)}>
          <Button
            className={classNames(styles.downloadBtn, styles.sameStyle)}
            type="link"
            onClick={e => {
              e.stopPropagation();
              onDownload(getRealUrl(info.resource) as string, info.name);
            }}
          >
            下载
          </Button>
          <Button
            className={classNames(styles.lookBtn, styles.sameStyle)}
            type="link"
            onClick={e => {
              e.stopPropagation();
              window.open(getRealUrl(info.resource) + '&preview=true');
            }}
          >
            查看
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default ReportCard;
