import styles from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Graph } from '@antv/x6';
import ResizeObserver from 'rc-resize-observer';
import { isEmpty, isNil } from 'lodash-es';
import {
  apiV2HvacDashboardDetailGetNodeIndicatorValuePost,
  apiV2HvacTopologyDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import { AlarmNode, TopoNodeItem } from '../../utils/const';
import { IconKey, NodeType } from '@/pages/UET/Edit/HVACStation/utils';
import { Spin } from 'antd';
import { register } from '@antv/x6-react-shape';
import { LineType, LineTypeDisplay, LineTypeStyle } from '@/pages/UET/Edit/HVACStation/utils';
import { useUpdate } from '@maxtropy/components';
import DescNode from '../DescNode';

export interface MiddleGraphProps {
  existIndex: boolean;
  stationId?: number;
  stationCode?: string;
  alarmNodes?: AlarmNode[];
}

register({
  shape: 'custom-desc-node-2',
  effect: ['data'],
  component: DescNode,
});

const MiddleGraph: React.FC<MiddleGraphProps> = ({ stationId, existIndex, stationCode, alarmNodes }) => {
  const graph = useRef<Graph>();
  const refContainer = useRef<HTMLDivElement>();
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const timer = useRef<number>();

  // 每隔1min执行一次请求
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      getMonitorData();
      updateFn();
    }, 60000);

    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, alarmNodes, stationId]);

  // 初始化画布
  useEffect(() => {
    if (!refContainer.current) return;
    graph.current = new Graph({
      container: refContainer.current!,
      mousewheel: true,
      panning: true,
      interacting: false,
      connecting: {
        router: 'manhattan',
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isNil(stationId)) return;

    if (graph.current) {
      graph.current.clearCells();
    }
    setLoading(true);
    apiV2HvacTopologyDetailPost({ id: stationId })
      .then(res => {
        const topoJson = JSON.parse(res.topologyDiagram ?? '{}');
        drawData(topoJson);
        getMonitorData();
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  useEffect(() => {
    drawAlarmData(alarmNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmNodes]);

  const drawData = (topoData: any) => {
    if (isNil(graph.current)) return;

    if (!isEmpty(topoData)) {
      graph.current.fromJSON({ ...topoData });

      const topoNodes = graph.current.getNodes();
      topoNodes.forEach(node => {
        const nodePrevData = node.getData();

        const ports = node.getPorts();
        ports.forEach(item => {
          node.setPortProp(item.id!, 'attrs/rect/style/visibility', 'hidden');
        });
        node?.updateData({
          ...nodePrevData,
          iconKey: IconKey[nodePrevData.type as NodeType],
          stationId,
          stationCode,
          realTime: true,
        });
      });
    }

    graph.current.zoomToFit({
      padding: {
        left: 30,
        top: 20,
        right: 30,
        bottom: 20,
      },
      minScale: 0.4,
      maxScale: 1,
      preserveAspectRatio: true,
    });
  };

  const getMonitorData = () => {
    if (!isNil(stationId)) {
      apiV2HvacDashboardDetailGetNodeIndicatorValuePost({ id: stationId }).then(res => {
        drawMonitorData(res.list ?? []);
      });
    }
  };

  // 绘制监控数据
  const drawMonitorData = (topoMonitorData: TopoNodeItem[] = []) => {
    if (isNil(graph.current)) return;
    const topoNodes = graph.current.getNodes();

    topoNodes.forEach(node => {
      const { shape, id } = node.getProp();
      if (shape === 'custom-desc-node-2' && id) {
        graph.current?.removeNode(id);
        return;
      }
      const nodePrevData = node.getData();
      const monitorItem = topoMonitorData.find(m => m.id === nodePrevData.nodeId);

      // 获取节点展示指标
      const displayData = (monitorItem?.indicatorItemList ?? [])
        .sort((a, b) => a.position! - b.position!)
        .map(k => ({
          label: k.indicatorName,
          value: `${k?.value?.toFixed(2) ?? '--'}${k?.unitName ?? ''}`,
        }));

      const { x: nodeX, y: nodeY } = node.getBBox();

      graph.current?.addNode({
        shape: 'custom-desc-node-2',
        data: {
          ...nodePrevData,
          displayData,
          status: monitorItem?.status,
        },
        x: nodeX,
        y: nodeY - 24 - displayData?.length * 22,
        zIndex: 20,
      });

      node?.updateData({
        ...nodePrevData,
        canSkip: !!monitorItem?.detailAble,
      });
    });
  };

  const drawAlarmData = (alarmNodes?: AlarmNode[]) => {
    if (isNil(graph.current)) return;
    const topoNodes = graph.current.getNodes();

    topoNodes.forEach(node => {
      // 报警记录
      const nodePrevData = node.getData();
      const isAlarm =
        alarmNodes?.some(k => k.nodeId === nodePrevData?.nodeId && k.nodeType === nodePrevData?.type) ?? false;

      node?.updateData({
        ...nodePrevData,
        isAlarm,
      });
    });
  };

  return (
    <ResizeObserver
      onResize={({ width, height }) => {
        graph.current?.resize(width, height);
      }}
    >
      <Spin spinning={loading}>
        <div className={`${styles.drawWrapper} ${existIndex ? styles.normalWrapper : styles.highWrapper}`}>
          <div className={styles.legend}>
            {Object.entries(LineTypeDisplay).map(([k, v]) => {
              const lineItem = Number(k) as LineType;
              return (
                <div className={styles.pipeItem} key={v}>
                  <div className={styles.line} style={{ background: LineTypeStyle[lineItem] }}></div>
                  <div>{LineTypeDisplay[lineItem]}</div>
                </div>
              );
            })}
          </div>
          <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
        </div>
      </Spin>
    </ResizeObserver>
  );
};

export default MiddleGraph;
