import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { DataPropertyType } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface ScadaPageReq extends PageRequest {
  name?: string;
  ouIds?: number[];
}
export interface ScadaPageRes {
  createTime: string;
  dataScope?: number;
  id: number;
  json?: string;
  name: string;
  ous: Array<{
    id: number;
    name: string;
  }>;
  thumbnail?: string;
  updateTime: string;
  ouName?: string;
  ouIds?: number[];
}

export const getScadaPage = (query: ScadaPageReq) =>
  fetch<PageResponse<ScadaPageRes>>(
    `/api/v2/scada/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

export interface CreateScadaReq {
  name: string;
  ouIds: number[];
  json?: string;
  dataTimeliness?: number;
}

export const createScada = (query: CreateScadaReq) =>
  fetch(
    `/api/v2/scada/add`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

export const getScadaData = (id: number) =>
  fetch(
    `/api/v2/scada/data`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const UpdateScada = (id: number, query: CreateScadaReq) =>
  fetch(
    `/api/v2/scada/edit`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...query }),
    },
    true
  );

export const copyScada = (id: number, query: CreateScadaReq) =>
  fetch(
    `/api/v2/scada/copy`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...query }),
    },
    true
  );

export const exportScada = (id: number) => {
  window.open(`/api/v2/scada/export/${id}`);
};

export interface DeviceListProps {
  code: string;
  id: number;
  name: string;
  modelName?: string;
  modelId: number;
  sn: string;
}

export const getDeviceList = (id: number) => fetch<DeviceListProps[]>(`/api/scada/${id}/device/list`);

export const deleteScada = (id: number) =>
  fetch(
    `/api/v2/scada/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface EnumValueProps {
  name: string;
  value: number;
}

export interface PhysicalUnitProps {
  name: string;
  k: number;
  b: number;
}

export interface DevicePropertyProps {
  dataPropertyId: number;
  dataPropertyName: string;
  dataPropertyType: number;
  enumValue?: EnumValueProps[];
  id: string;
  physicalUnit: PhysicalUnitProps[];
}

export const getDeviceProperty = (id: number) =>
  fetch<{ list: DevicePropertyProps[] }>(
    `/api/v2/scada/device/property`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);

export const getScadaListOwned = () =>
  fetch<{ list: ScadaPageRes[] }>(
    '/api/v2/scada/list/owned',
    {
      method: 'POST',
    },
    true
  );

export const getScadaInfo = (id: number) =>
  fetch<ScadaPageRes>(
    `/api/v2/scada/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface ScadaFileDirectory {
  id: number;
  name: string;
  children?: ScadaFileDirectory[];
  resource: string[];
}

export const getScadaFileDirectory = () =>
  fetch<{ list: ScadaFileDirectory[] }>(
    `/api/v2/scada/file-directory`,
    {
      method: 'POST',
    },
    true
  );

// 根据ouIds查询组态可选设备列表
export const getDeviceListByOuIds = (ids: number[]) =>
  fetch<{ list: DeviceListProps[] }>(
    `/api/v2/scada/device/list`,
    {
      method: 'POST',
      body: JSON.stringify({ ids }),
    },
    true
  ).then(res => res.list ?? []);

// 电表设备
export const getScadaDeviceEMeterList = (ids: number[]) =>
  fetch<{ list: DeviceListProps[] }>(
    `/api/v2/scada/device/e-meter/list`,
    {
      method: 'POST',
      body: JSON.stringify({ ids }),
    },
    true
  ).then(res => res.list ?? []);

// 断路器设备
export const getScadaDeviceBreakerList = (ids: number[]) =>
  fetch<{ list: DeviceListProps[] }>(
    `/api/v2/scada/device/breaker/list`,
    { method: 'POST', body: JSON.stringify({ ids }) },
    true
  ).then(res => res.list ?? []);

interface PhysicalUnit {
  k: number;
  b: number;
}
export interface DevicePoint {
  id: string;
  // 默认单位k1,b1 所选单位k2,b2
  // k1v + b1 = k2x + b2
  // x = (k1v + b1 - b2) / k2;
  dataPropertyType: DataPropertyType;
  physicalUnit: PhysicalUnit;
  dataPropertyId: number;
  dataPropertyName: string;
  acquisitionTime?: number | null;
  value?: number | null;
  edgeDevicePointId?: string;
  enumValue?: Record<number, string>;
}
export interface Device {
  id: number;
  name: string;
  code?: string;
  modelName?: string | null;
  propertyData?: DevicePoint[];
}

// export enum CategoryType {
//   Device = 'device',
//   HotSpot = 'hotSpot',
//   Light = 'light',
//   Point = 'point',
//   MutiplePoint = 'mutiplePoint',
//   LowVoltageOutlet = 'lowVoltageOutlet',
//   LowVoltageCuswaysCircuitCutout = 'lowVoltageCuswaysCircuitCutout',
//   LowVoltageInlet = 'lowVoltageInlet',
//   LowVoltageCapacitorCabinet = 'lowVoltageCapacitorCabinet',
//   EmptyCircuit = 'emptyCircuit',
//   PlasticCircuitBreaker = 'plasticCircuitBreaker',
//   Image = 'image',
//   BatchLowVoltage = 'batchLowVoltage',
// }

export enum TimelinessType {
  MINUTES15 = 1,
  MINUTES30,
  MINUTES45,
  HOUR1,
  HOUR4,
}

export const TimelinessDisplay = {
  [TimelinessType.MINUTES15]: '15分钟',
  [TimelinessType.MINUTES30]: '30分钟',
  [TimelinessType.MINUTES45]: '45分钟',
  [TimelinessType.HOUR1]: '60分钟',
  [TimelinessType.HOUR4]: '240分钟',
};

interface KeyVal {
  edgeDevicePointId: string;
  value: string;
}

interface WriteValueReq {
  deviceId: number;
  keyValues: KeyVal[];
}

export const remoteSwitch = (param: WriteValueReq) =>
  fetch(
    `/api/v2/scada/write-value`,
    {
      method: 'POST',
      body: JSON.stringify(param),
    },
    true
  );

export function checkScadaPermission(id: Key) {
  return fetch<{ ret: boolean }>(
    `/api/v2/scada/permission`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
