import { FC, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Select, Space } from 'antd';

export type TimeSpace = [number, number];

export interface DayFormProps {
  startTime: Dayjs;
  endTime: Dayjs;
  disabledTimes?: TimeSpace[];
  value?: [number | undefined, number | undefined];
  onChange?: (v: [number | undefined, number | undefined]) => void;
}

const DayForm: FC<DayFormProps> = ({
  startTime,
  endTime,
  disabledTimes = [],
  value = [undefined, undefined],
  onChange,
}) => {
  const [selectYear, setSelectYear] = useState<number>();

  const [selectMonth, setSelectMonth] = useState<number>();

  const [startDate, endDate] = value;

  useEffect(() => {
    if (startDate !== undefined) {
      let time = dayjs(startDate).endOf('year');
      setSelectYear(time.isBetween(startTime, endTime, null, '[]') ? time.valueOf() : dayjs(endTime).valueOf());
    }
  }, [startDate, startTime, endTime]);

  useEffect(() => {
    if (startDate !== undefined) {
      let time = dayjs(startDate).endOf('month');
      setSelectMonth(time.isBetween(startTime, endTime) ? time.valueOf() : dayjs(endTime).valueOf());
    }
  }, [startDate, startTime, endTime]);

  // console.log(dayjs('09:30'), dayjs('09:30', 'HH:mm').format('YYYY-MM-DD HH:mm:ss'))

  const allYears = useMemo(() => {
    if (startTime && endTime) {
      const start = dayjs(startTime).year();
      const end = dayjs(endTime).year();
      return new Array(end - start + 1).fill(undefined).map((_, index) => {
        const time = dayjs(startTime).add(index, 'years').endOf('year');
        return time.isBetween(startTime, endTime) ? time.valueOf() : dayjs(endTime).valueOf();
      });
    } else {
      return [];
    }
  }, [startTime, endTime]);

  const selectYearsAllMonth = useMemo(() => {
    if (startTime && endTime && selectYear) {
      let s = dayjs(selectYear).startOf('year').valueOf();
      s = dayjs(s).isSameOrAfter(startTime) ? s : dayjs(startTime).valueOf();
      return new Array(dayjs(selectYear).month() - dayjs(s).month() + 1).fill(undefined).map((_, index) => {
        const time = dayjs(s).add(index, 'months').endOf('month');
        return time.isBetween(startTime, endTime) ? time.valueOf() : dayjs(endTime).valueOf();
      });
    } else {
      return [];
    }
  }, [selectYear, startTime, endTime]);

  const slectStartDates = useMemo(() => {
    if (startTime && endTime && selectMonth && disabledTimes) {
      let s = dayjs(selectMonth).startOf('month').valueOf();
      s = dayjs(s).isSameOrAfter(startTime) ? s : dayjs(startTime).valueOf();
      return new Array(dayjs(selectMonth).date() - dayjs(s).date() + 1)
        .fill(undefined)
        .map((_, index) => {
          const time = dayjs(s).add(index, 'days').startOf('date');
          return time.isBetween(startTime, endTime, null, '[]')
            ? time.valueOf()
            : dayjs(endTime).startOf('date').valueOf();
        })
        .filter(i => {
          return !disabledTimes.some(([start, end]) => {
            return dayjs(i).isBetween(dayjs(start).startOf('date'), dayjs(end).endOf('date'), null, '[]');
          });
        });
    } else {
      return [];
    }
  }, [selectMonth, startTime, endTime, disabledTimes]);

  const slectEndDates = useMemo(() => {
    if (startTime && endTime && startDate && selectMonth && disabledTimes) {
      return new Array(dayjs(selectMonth).date() - dayjs(startDate).date() + 1)
        .fill(undefined)
        .map((_, index) => {
          const time = dayjs(startDate).add(index, 'days').endOf('date');
          return time.isBetween(startTime, endTime, null, '[]') ? time.valueOf() : dayjs(endTime).valueOf();
        })
        .filter(i => {
          return !disabledTimes.some(([start, end]) => {
            return dayjs(i).isBetween(dayjs(start).startOf('date'), dayjs(end).endOf('date'), null, '[]');
          });
        })
        .reduce((a: (number | undefined)[], c: number) => {
          if (a.length === 0) {
            a.push(c);
          } else {
            const pre = a.slice(-1)[0];
            if (pre === undefined) {
              a.push(undefined);
            } else {
              dayjs(pre).add(1, 'days').valueOf() === c ? a.push(c) : a.push(undefined);
            }
          }
          return a;
        }, [])
        .filter(i => i) as number[];
    } else {
      return [];
    }
  }, [selectMonth, startDate, startTime, endTime, disabledTimes]);

  return (
    <Space style={{ width: '100%' }} size={20}>
      <Select
        placeholder="请选择"
        style={{ width: 130 }}
        value={selectYear}
        onChange={(v: number) => {
          onChange?.([undefined, undefined]);
          setSelectMonth(undefined);
          setSelectYear(v);
        }}
      >
        {allYears.map(i => (
          <Select.Option key={i} value={i}>
            {dayjs(i).year()}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="请选择"
        style={{ width: 130 }}
        value={selectMonth}
        onChange={(v: number) => {
          onChange?.([undefined, undefined]);
          setSelectMonth(v);
        }}
      >
        {selectYearsAllMonth.map(i => (
          <Select.Option key={i} value={i}>
            {dayjs(i).month() + 1}月
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="请选择"
        style={{ width: 130 }}
        value={startDate}
        onChange={(v: number) => {
          onChange?.([v, undefined]);
        }}
      >
        {slectStartDates.map(i => (
          <Select.Option key={i} value={i}>
            {dayjs(i).date()}
          </Select.Option>
        ))}
      </Select>
      <Select
        placeholder="请选择"
        style={{ width: 130 }}
        value={endDate}
        onChange={(v: number) => {
          onChange?.([startDate, v]);
        }}
      >
        {slectEndDates.map(i => (
          <Select.Option key={i} value={i}>
            {dayjs(i).date()}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

export default DayForm;
