import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import {
  AlarmLevel,
  AlarmStatus,
  AlarmType,
  GateAlarmLogic,
  GatewayAlarmCoolDownMode,
  GatewayAlarmType,
  IotProtocolType,
  Operator,
  AlarmChannel,
} from '@/shared/types';
import { Device } from './device';

export interface RulePageRequest extends PageRequest {
  nameOrCode?: string;
  status?: AlarmStatus;
  type?: AlarmType;
  alarmType?: AlarmLevel;
  // rootMcid?: string;
  deviceTypeId?: number;
  iotProtocol?: number;
  ruleCode?: Key; // 规则编号
  channel?: AlarmChannel; // 报警渠道
}

export interface RulePageProps {
  id: number;
  code: string;
  name: string;
  status: AlarmStatus;
  type: AlarmType;
  alarmName: string;
  alarmType: AlarmLevel;
  channel: AlarmChannel;
  rootMcid: string;
  rootName: string;
  rootFullName: string;
  tenantCode: string;
  useDeviceCount: number;
  iotProtocol: IotProtocolType;
  updateSource: Operator;
  updateByUserId: number;
  updateByUsername: string;
  updateTime: string;
  alarmCode?: string;
  faultCode?: string;
  deviceTypeName?: string;
  formula?: string;
  deviceTypeId?: number;
  duration?: number;
  relatedPropertyIds?: number[];
  // 网关报警方式
  alarmMode?: GatewayAlarmType;
  // 网关报警逻辑
  alarmLogic?: GateAlarmLogic;
  // 离线持续时间
  offlineDuration?: number;
  // 冷却方式
  coolDownMode?: GatewayAlarmCoolDownMode;
  // 冷却时间
  coolDownTime?: number;
  // 三方报警码
  thirdAlarmCode?: string;
}

export function getRulePage(query: RulePageRequest): Promise<PageResponse<RulePageProps>> {
  return fetch(`/api/rule/page?${qs.stringify(query, { indices: false })}`);
}

export function deleteRule(id: number) {
  return fetch(`/api/rule/${id}`, {
    method: 'DELETE',
  });
}

export interface CreateRuleRequest {
  name: string;
  type: AlarmType;
  alarmName: string;
  alarmType: AlarmLevel;
  rootMcid: string;
  iotProtocol: IotProtocolType;
  alarmCode: string;
  faultCode: string;
  duration: number;
  relatedPropertyIds?: number[];
}

export function createRule(body: CreateRuleRequest) {
  return fetch(`/api/rule/add`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export interface UpdateRuleRequest {
  id: number;
  iotProtocol: IotProtocolType;
  name: string;
  alarmName: string;
  duration: number;
  alarmType: AlarmLevel;
  addRelatedPropertyIds?: number[];
  deleteRelatedPropertyIds?: number[];
}

export function updateRule(body: UpdateRuleRequest) {
  return fetch(`/api/rule/update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function getRuleInfo(id: number): Promise<RulePageProps> {
  return fetch(`/api/rule/info?id=${id}`);
}

export function disableRule(id: number) {
  return fetch(`/api/rule/disable/${id}`, {
    method: 'PUT',
  });
}

export function enableRule(id: number) {
  return fetch(`/api/rule/enable/${id}`, {
    method: 'PUT',
  });
}

export function batchReadAlarmLog(ids: number[]) {
  return fetch<void>(
    `/api/v2/rule/alarm/log/batch/read`,
    {
      method: 'POST',
      body: JSON.stringify({ ids }),
    },
    true
  );
}

export interface unbindDeviceSearchParams extends PageRequest {
  codeOrName?: Key;
  customerMcid?: Key;
}

// 获取规则关联的设备分页详情
export function getRuleDevicePage(id: Key, query: unbindDeviceSearchParams): Promise<PageResponse<Device>> {
  return fetch(`/api/rule/${id}/device/page?${qs.stringify(query, { indices: false })}`);
}

export interface UnbindRuleDeviceBody {
  ruleId: Key;
  deviceRuleGroupList: Array<{
    deviceId: Key;
    ruleGroupId: Key;
  }>;
}

// 规则关联的规则组解绑设备
export function unbindRuleDevice(body: UnbindRuleDeviceBody) {
  return fetch(`/api/rule/device/unbind`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}
