import React, { useMemo, useState } from 'react';
import { Checkbox, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Layout } from 'antd';
import DragResize from '@/components/DragResize';
import styles from './index.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import StationTree from './components/StationTree';
import RadioConsumption from './components/RadioConsumption';
import DateSwitch, { RangeValue } from './components/DateSwitch';
import OutPutBox from './components/OutputBox';
import AnalysisChart from './components/AnalysisChart';
import { TimeGranularity, TimeGranularityInitialValue } from './ProductionUnitConsumption';

const ProductionUnitConsumptionBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);
  // 记录选中的 key
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [showLabel, setShowLabel] = useState<boolean>(true);
  // 顶部选择综合能耗还是能源介质, 综合能耗的 id 为 1, 其他能源介质为其 id
  const [selectedConsumption, setSelectedConsumption] = useState<number>();
  const [selectedBasePhysicalQuantityId, setSelectedBasePhysicalQuantityId] = useState<number | undefined>();
  // 能耗单位
  const [selectedGeneralName, setSelectedGeneralName] = useState<string>();
  // 产出物单位
  const [unitName, setUnitName] = useState<string>();
  const [selectedOutputValue, setSelectedOutputValue] = useState<number>();
  // 当左侧树切换时，需要记住之前选中的能源介质，产出物
  const [memoConsumption, setMemoConsumption] = useState<number>();
  const [memoBasePhysicalQuantityId, setMemoBasePhysicalQuantityId] = useState<number | undefined>();
  const [memoOutputValue, setMemoOutputValue] = useState<number>();
  const [selectedOutputName, setSelectedOutputName] = useState<string>();

  // 时间颗粒度, 小时/天/月
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity>(TimeGranularity.HOUR);
  const [value, setValue] = useState<RangeValue>(TimeGranularityInitialValue[TimeGranularity.HOUR]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 工作中心, 工站, 工序, 用能单元查询参数
  const generalQuery = useMemo(() => {
    const key = (selectedKeys[0] as string) ?? '';
    const arrayKey = key.split('-');

    return {
      // shopFlowType(工序类型): 1 :生产基地 2 :工作中心 3 :工序 4 :工站 5 :用能单元
      shopFlowType: arrayKey.at(0),
      // 生产基地
      productionBaseId: arrayKey.at(1),
      // 必传工作中心
      workCenterId: arrayKey.at(2),
      // 工序
      workProcedureId: arrayKey.at(3),
      // 工站
      workStationId: arrayKey.at(4),
      // 用能单元
      energyUnitId: arrayKey.at(5),
    };
  }, [selectedKeys]);

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Layout.Sider
          theme="light"
          className={styles.sider}
          width={openSider ? siderWidth : 0}
          style={{
            transition: dragStatus ? 'none' : 'all 0.2s',
            background: '#232324',
            marginRight: openSider ? 10 : 0,
          }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            setSelectedOutputValue={setSelectedOutputValue}
            setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
            setSelectedConsumption={setSelectedConsumption}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={styles.rightPane}>
            <RadioConsumption
              selectedKeys={selectedKeys}
              generalQuery={generalQuery}
              selectedConsumption={selectedConsumption}
              setSelectedConsumption={setSelectedConsumption}
              setSelectedGeneralName={setSelectedGeneralName}
              selectedBasePhysicalQuantityId={selectedBasePhysicalQuantityId}
              setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
              memoConsumption={memoConsumption}
              setMemoConsumption={setMemoConsumption}
              memoBasePhysicalQuantityId={memoBasePhysicalQuantityId}
              setMemoBasePhysicalQuantityId={setMemoBasePhysicalQuantityId}
            />
            <div className={styles.dateArea}>
              <DateSwitch
                timeGranularity={timeGranularity}
                setTimeGranularity={setTimeGranularity}
                value={value}
                setValue={setValue}
              />
              <Checkbox style={{ marginLeft: 16 }} defaultChecked={true} onChange={() => setShowLabel(!showLabel)}>
                显示单耗数值
              </Checkbox>
            </div>
            <OutPutBox
              generalQuery={generalQuery}
              value={value}
              setUnitName={setUnitName}
              memoOutputValue={memoOutputValue}
              selectedOutputValue={selectedOutputValue}
              setSelectedOutputValue={setSelectedOutputValue}
              setMemoOutputValue={setMemoOutputValue}
              setSelectedOutputName={setSelectedOutputName}
            />
            <AnalysisChart
              showLabel={showLabel}
              timeGranularity={timeGranularity}
              generalQuery={generalQuery}
              selectedGeneralName={selectedGeneralName}
              unitName={unitName}
              value={value}
              outputProductId={selectedOutputValue}
              energyMediumId={selectedConsumption}
              physicalUnitId={selectedBasePhysicalQuantityId}
              selectedOutputName={selectedOutputName}
            />
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default ProductionUnitConsumptionBoard;
