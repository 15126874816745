import { useState, useEffect, useCallback } from 'react';
import { getUnits, UnitTypeWithUnitList } from '@/api/outputConfig';

export type UnitType = [(unitTypeCode: number, unitCode: string) => string | undefined];

export const useUnit = (): UnitType => {
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);

  useEffect(() => {
    getUnits().then(res => {
      setUnits(res.list);
    });
  }, []);

  const getUnitName = useCallback(
    (unitTypeCode: number, unitCode: string) => {
      const unitType = units.find(item => item.unitTypeCode === unitTypeCode);
      const unitObj = unitType?.outputProductUnitList.find(item => item.unitCode === unitCode);
      // 计量单位（1）展示unitCode，记件单位（2）展示unitName
      const unitName = unitType?.unitTypeCode === 1 ? unitObj?.unitCode : unitObj?.unitName;

      return unitName;
    },
    [units]
  );

  return [getUnitName];
};
