import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout } from 'antd';
import { useState } from 'react';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import StationTree from './components/StationTree';
import RightContent from './components/RightContent';

const ProductionBeatView: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  // hasConfig
  const [hasConfig, setHasConfig] = useState<boolean>(false);

  const [appAnalysisSubscriptionId, setAppAnalysisSubscriptionId] = useState<number | null>(); // 应用分析订阅id

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree setHasConfig={setHasConfig} setAppAnalysisSubscriptionId={setAppAnalysisSubscriptionId} />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <RightContent
            hasConfig={hasConfig}
            setHasConfig={setHasConfig}
            appAnalysisSubscriptionId={appAnalysisSubscriptionId}
          />
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default ProductionBeatView;
