import { ValueType } from '@/pages/FillingRules/CreateAndEdit/NumberInput/types';
import { AutoDataSettingTypes } from './NumberInput/types';
export enum FieldType {
  // 数据关联 1
  DataAssociation = 1,
  // 数值 2
  Number = 2,
  // 输出 3
  Output = 3,
}

export interface FieldList {
  id?: number;
  /**
   * 排放过程id
   */
  emissionProcessId?: number;
  /**
   * 字段名称
   */
  fieldName?: string;
  /**
   * 字段类型(1:数据关联 2:数值 3:输出)
   */
  fieldType?: number;
  /**
   * 字段属性id
   */
  fieldPropertyId?: number;
  /**
   * 字段属性类型(1燃料 2碳酸盐...)
   */
  fieldPropertyType?: number;
  /**
   * 字段来源(1:排放因子)
   */
  fieldSource?: number;
  /**
   * 字段序号
   */
  fieldOrder?: number;
  /**
   * 小数位数
   */
  decimalDigit?: number;
  /**
   * 数值表达式(例:(0,100])
   */
  numericalExpression?: string;
  options?: {
    label?: string;
    value?: string;
  }[];
  /**
   * 筛选表达式
   */
  filterExpression?: string;
  createTime?: string;
  updateTime?: string;
  /**
   * 筛选逻辑
   */
  fieldFilterLogic?: {
    id?: number;
    /**
     * 表单字段id
     */
    formFieldId?: number;
    /**
     * 字段属性id
     */
    fieldPropertyId?: number;
    /**
     * 运算符号(1:等于 2:不等于)
     */
    operateSign?: number;
    /**
     * 匹配类型(1:匹配字段 2:自定义)
     */
    matchType?: number;
    /**
     * 匹配值(字段名称或自定义输入值)
     */
    matchValue?: string;
    createTime?: string;
    updateTime?: string;
  }[];
}

export interface FieldProperty {
  valueType?: ValueType;
  formFieldValue?: string;
  autoDataSetting?: AutoDataSettingTypes | null;
}

export interface FieldPropertyList {
  [key: string]: string | number | FieldProperty | undefined;
}

export interface SchemeTypes {
  id?: number;
  autoFillingRuleId?: number;
  formFieldId?: number;
  formFieldValue?: string;
  valueType?: number | null;
  autoDataSettingId?: number | null;
  tenantMcid?: string;
  createTime?: string;
  updateTime?: string;
  autoDataSetting?: AutoDataSettingTypes | null;
}
