import styles from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { cancelFullscreen, flexible, goFullscreen, pollingInterval } from './utils';
import MonitorInfoSwiper from './components/MonitorInfoSwiper';
import EnergyStatistics from './components/EnergyStatistics';
import EnergyCost from './components/EnergyCost';
import DeviceCards from './components/DeviceCards';
import TotalLoad from './components/TotalLoad';
import Flow from './components/Flow';
import { Col, Row } from 'antd';
import { useSize, useRequest } from 'ahooks';
import { Key, getRealUrl } from '@maxtropy/components';
import mainBg from './assets/mainbg.gif';
import TitleSwiper from './components/TitleSwiper';
import EnergyPlan from './components/EnergyPlan';
import { BarChartOutlined } from '@ant-design/icons';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { apiV2EnergyConsumptionOverviewDetailPost } from '@maxtropy/device-customer-apis-v2';

const EnergyOverview: React.FC = () => {
  const outerRef = useRef<HTMLDivElement>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const size = useSize(outerRef);
  const [isTwoCol, setIsTwoCol] = useState<boolean>(false);
  const [configId, setConfigId] = useState<Key>();
  const [energyAnalysisUnitGroupId, setEnergyAnalysisUnitGroupId] = useState<Key>();

  const hasProCondBtnPermission = useHasPermission(PermissionsType.B_PRODUCTCONDITION);

  // 自适应屏幕缩放
  const handleResize = () => {
    // flexible(outerRef.current);
    if (document.documentElement.clientWidth > 1920) {
      flexible(outerRef.current);
      // flowRef.current && flowRef.current.getEchartsInstance().resize();
    }
  };

  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (size && size.width <= 1600) {
      setIsTwoCol(true);
    } else {
      setIsTwoCol(false);
    }
  }, [size]);

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (outerRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(outerRef.current);
    }
  };

  const { data: picUrl } = useRequest(
    () => {
      return configId
        ? apiV2EnergyConsumptionOverviewDetailPost({ id: configId }).then(res => {
            setEnergyAnalysisUnitGroupId(res.energyAnalysisUnitGroupId);
            if (res && res.picUrl) {
              return getRealUrl(res.picUrl);
            }
          })
        : Promise.resolve(undefined);
    },
    {
      pollingInterval,
      refreshDeps: [configId],
    }
  );

  return (
    <div
      ref={dom => {
        if (dom) {
          outerRef.current = dom;
        }
      }}
      style={
        isTwoCol
          ? { backgroundColor: '#1c202380', overflowY: 'auto' }
          : {
              backgroundColor: '#1c202380',
              height: '100%',
            }
      }
    >
      {isTwoCol ? (
        <>
          <Row>
            <Col span={24}>
              <TitleSwiper configId={configId} setConfigId={setConfigId} />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <EnergyStatistics outerRef={outerRef.current as HTMLDivElement} configId={configId} />
              <EnergyCost
                configId={configId}
                outerRef={outerRef.current}
                isTwoCol={isTwoCol}
                isFullScreen={isFullScreen}
              />
              <EnergyPlan
                outerRef={outerRef.current}
                isFullScreen={isFullScreen}
                isTwoCol={isTwoCol}
                configId={configId}
              />
            </Col>
            <Col span={12}>
              <div className={styles.borderStyles}>
                <TotalLoad
                  configId={configId}
                  outerRef={outerRef.current}
                  isTwoCol={isTwoCol}
                  fullBtn={fullBtn}
                  isFullScreen={isFullScreen}
                />
                <div>
                  <Flow
                    configId={configId}
                    outerRef={outerRef.current}
                    isTwoCol={isTwoCol}
                    isFullScreen={isFullScreen}
                    energyAnalysisUnitGroupId={energyAnalysisUnitGroupId}
                  />
                </div>
                <MonitorInfoSwiper configId={configId} />
                <DeviceCards configId={configId} />
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row
          className={styles.bgImgStyle}
          style={{
            backgroundImage: `url(${picUrl ? picUrl : mainBg})`,
          }}
        >
          <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <EnergyStatistics outerRef={outerRef.current as HTMLDivElement} configId={configId} />
            <EnergyCost
              configId={configId}
              outerRef={outerRef.current as HTMLDivElement}
              isTwoCol={isTwoCol}
              isFullScreen={isFullScreen}
            />
          </Col>
          <Col span={8}>
            <div className={styles.borderStyles}>
              <TitleSwiper configId={configId} setConfigId={setConfigId} />
              <EnergyPlan
                outerRef={outerRef.current}
                isFullScreen={isFullScreen}
                isTwoCol={isTwoCol}
                configId={configId}
              />
              <div className={styles.imgDivJumpStyle}>
                {hasProCondBtnPermission && (
                  <div
                    onClick={() => {
                      window.open(`/productionManagement/report/board`);
                    }}
                    className={styles.btn}
                    style={{ marginRight: 24 }}
                  >
                    <BarChartOutlined />
                    &nbsp;
                    <span>生产工作实况</span>
                    <div className={styles.bottomLine} />
                  </div>
                )}
              </div>
              <div>
                <MonitorInfoSwiper configId={configId} />
              </div>
              <DeviceCards configId={configId} />
            </div>
          </Col>
          <Col span={8}>
            <div className={styles.rightContentStyles}>
              <TotalLoad
                configId={configId}
                outerRef={outerRef.current}
                fullBtn={fullBtn}
                isFullScreen={isFullScreen}
              />
              <Flow
                configId={configId}
                outerRef={outerRef.current}
                energyAnalysisUnitGroupId={energyAnalysisUnitGroupId}
                isTwoCol={isTwoCol}
                isFullScreen={isFullScreen}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default EnergyOverview;
