import { EllipsisSpan } from '@maxtropy/components';
import {
  apiV2EnergyCompareGetLossConfPost,
  apiV2EnergyCompareSetLossConfPost,
  V2EnergyCompareGetLossConfPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Form, InputNumber, message, Modal, Table } from '@maxtropy/components';
import React, { FC, useEffect, useState } from 'react';
// import styles from './index.module.scss';

interface ILossRateSettingModal {
  lossRateSettingVisible: boolean;
  onCancel: () => void;
}
type EnergyCompareGetLossConfItem = Exclude<V2EnergyCompareGetLossConfPostResponse['list'], undefined>[number];

const LossRateSettingModal: FC<ILossRateSettingModal> = props => {
  const { lossRateSettingVisible, onCancel } = props;
  const [form] = Form.useForm();
  const [lossRateConfigData, setLossRateConfigData] = useState<EnergyCompareGetLossConfItem[]>();

  const columns = [
    {
      title: '拓扑',
      dataIndex: 'energyMediumTopologyName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '损耗超限比例（%）',
      dataIndex: 'loss',
      ellipsis: { showTitle: true },
      render: (text: any, record: EnergyCompareGetLossConfItem) => (
        <Form.Item
          style={{ margin: 0 }}
          name={record.energyMediumTopologyId}
          initialValue={record.loss ? Number(record.loss.toFixed(2)) : undefined}
          rules={[
            () => ({
              validator(_, value) {
                if (value <= 100 && value > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('请输入0~100之间的数字'));
              },
            }),
          ]}
        >
          <InputNumber precision={2} style={{ width: 180, height: 32 }} placeholder="请输入损耗超限比例" />
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    if (lossRateSettingVisible) {
      apiV2EnergyCompareGetLossConfPost().then(res => setLossRateConfigData(res.list || []));
    }
  }, [lossRateSettingVisible]);

  const onFinish = (values: any) => {
    const arr = Object.entries(values).map(([k, v]) => ({
      energyMediumTopologyId: Number(k),
      loss: Number(v),
    }));
    apiV2EnergyCompareSetLossConfPost({ vos: arr }).then(res => {
      message.success('保存成功');
      onCancel();
    });
  };

  return (
    <>
      <Modal
        open={lossRateSettingVisible}
        title="损耗比例设置"
        size="small"
        destroyOnClose
        onOk={() => {
          form.submit();
        }}
        okText="保存"
        onCancel={onCancel}
      >
        <Form form={form} onFinish={onFinish}>
          <Table
            // className={styles.tableSty}
            bordered
            rowKey="energyMediumTopologyId"
            style={{ width: 480 }}
            dataSource={lossRateConfigData}
            pagination={false}
            columns={columns}
          />
        </Form>
      </Modal>
    </>
  );
};

export default LossRateSettingModal;
