import {
  CreateUETEMTProcessResponse,
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeDisplay,
  EnergyWorkingProcessTypeLabelColor,
  EnergyWorkingProcessTypeListDisplay,
} from '@/api/uet';
import { Badge, Card, Col, Collapse, Row } from 'antd';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Radio, ShowInput, Tag, Tooltip } from '@maxtropy/components';
import { ShareDataContext, createdFormLayout } from '../..';
import { apiV2UetEmtProcessDetailPost } from '@maxtropy/device-customer-apis-v2';

const { Panel } = Collapse;

export interface IShowItemInfo {
  item: CreateUETEMTProcessResponse;
  isEdit?: boolean;

  onEditProcess: (id: number, findCurrentEdit: CreateUETEMTProcessResponse) => void; // 点击编辑节点
  deleteProcess: (id: number) => void; // 点击删除节点
}

const ShowItemInfo: FC<IShowItemInfo> = props => {
  const { item, isEdit, onEditProcess, deleteProcess } = props;
  const { createdProcesses, setCreatedProcesses } = useContext(ShareDataContext);
  const [activeKey, setActiveKey] = useState<string | string[]>();
  const [tabsActiveKey, setTabsActiveKey] = useState<string>('1'); // 选择输出还是输入

  // 展示某一个节点详情
  useEffect(() => {
    if (isEdit && activeKey && activeKey.length) {
      apiV2UetEmtProcessDetailPost({ id: activeKey[0] }).then(res => {
        setCreatedProcesses?.(list => {
          let newList = [...list];
          const findIndex = newList.findIndex(i => String(i.id) === activeKey[0]);
          const find = newList.find(i => String(i.id) === activeKey[0]);
          newList.splice(findIndex, 1, {
            ...find,
            ...res,
            processEntryVos: [...(res.processEntryVos ?? [])],
            processExitVos: [...(res.processExitVos ?? [])],
            isEdit: false,
          });
          return newList;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, activeKey]);

  const getExtra = (id: number, hasPermission?: boolean) => {
    const findCurrentEdit = (createdProcesses ?? []).find(item => item.id === id);
    return (
      findCurrentEdit && (
        <div style={{ display: 'flex' }}>
          <Button disabled={!hasPermission} type="link" onClick={e => onEditProcess(id, findCurrentEdit)}>
            编辑
          </Button>
          <Button
            disabled={!hasPermission}
            type="link"
            onClick={e => {
              deleteProcess(id);
            }}
          >
            删除
          </Button>
        </div>
      )
    );
  };

  const tabs = useCallback(
    (item: CreateUETEMTProcessResponse) => {
      const tabsTemp = [
        {
          key: '1',
          label: (
            <>
              输入
              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '1' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '1' ? '#FFF' : '#00ADFF'}
                count={(item.processEntryVos ?? []).length}
              />
            </>
          ),
        },
        {
          key: '2',
          label: (
            <>
              节点内
              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '2' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '2' ? '#FFF' : '#00ADFF'}
                count={item.processNodeVo ? 1 : 0}
              />
            </>
          ),
        },
        {
          key: '3',
          label: (
            <>
              输出
              <Badge
                style={{ marginLeft: 8, color: tabsActiveKey === '3' ? '#00ADFF' : '#FFF' }}
                color={tabsActiveKey === '3' ? '#FFF' : '#00ADFF'}
                count={(item.processExitVos ?? []).length}
              />
            </>
          ),
        },
      ];
      if (item.type !== EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION) {
        return tabsTemp;
      } else {
        return tabsTemp.filter(i => i.key !== '2');
      }
    },
    [tabsActiveKey]
  );

  return (
    <Collapse
      key={item.id}
      accordion
      collapsible="icon"
      expandIcon={({ isActive }) => <span className={styles.expandTxt}>{isActive ? '收起' : '展开详情'}</span>}
      className={styles.CollapseStyle}
      style={{
        borderLeftColor: EnergyWorkingProcessTypeLabelColor[item.type as EnergyWorkingProcessType],
      }}
      onChange={key => setActiveKey(key)}
    >
      <Panel
        key={item.id!}
        style={{ borderBottom: 'transparent' }}
        extra={getExtra(item.id!, item.hasPermission)}
        header={
          <>
            <Row>
              <Col span={3} className={styles.itemCol}>
                <Tooltip title={item.name}>
                  <div className={styles.nameSty}>{item.name}</div>
                </Tooltip>
                <Tag border="solid" color={EnergyWorkingProcessTypeLabelColor[item.type as EnergyWorkingProcessType]}>
                  {EnergyWorkingProcessTypeDisplay[item.type as EnergyWorkingProcessType]}
                </Tag>
              </Col>
              <Col span={3} className={styles.ColStyle}>
                <div>
                  <Tooltip title={item.energyMediumVos?.map(i => i.energyName).join()}>
                    <p className={styles.ColSpanStyle}>{item.energyMediumVos?.map(i => i.energyName).join()}</p>
                  </Tooltip>

                  <p className={styles.subTitle_sty}>
                    {EnergyWorkingProcessTypeListDisplay[item.type as EnergyWorkingProcessType]}
                  </p>
                </div>
              </Col>
              <Col span={4} className={styles.ColStyle}>
                <div>
                  <Tooltip title={item.ouSimpleVos?.map(i => i.name).join()}>
                    <p className={styles.ColSpanStyle}>{item.ouSimpleVos?.map(i => i.name).join()}</p>
                  </Tooltip>

                  <p className={styles.subTitle_sty}>所属运营单元</p>
                </div>
              </Col>
              {item.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
                <Col span={2} className={styles.ColStyle}>
                  <div>
                    <p className={styles.ColSpanStyle}>{item.hasConversion ? '是' : '否'}</p>
                    <p className={styles.subTitle_sty}>是否发生转化</p>
                  </div>
                </Col>
              )}
              <Col span={3} className={styles.ColStyle}>
                <div>
                  <Tooltip title={item.processEntryVos?.map(i => i.name).join()}>
                    <p className={styles.ColSpanStyle}>{item.processEntryVos?.map(i => i.name).join()}</p>
                  </Tooltip>
                  <p className={styles.subTitle_sty}>入口</p>
                </div>
              </Col>
              <Col span={3} className={styles.ColStyle}>
                <div>
                  <Tooltip title={item.processExitVos?.map(i => i.name).join()}>
                    <p className={styles.ColSpanStyle}>{item.processExitVos?.map(i => i.name).join()}</p>
                  </Tooltip>
                  <p className={styles.subTitle_sty}>出口</p>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Col className={styles.AddProcessTabs} span={24}>
          <div className={styles.switchBtn}>
            <Radio.Group onChange={e => setTabsActiveKey(e.target.value)} value={tabsActiveKey} buttonStyle="solid">
              {tabs(item)?.map(item => (
                <Radio.Button key={item.key} value={item.key}>
                  {item.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          {tabsActiveKey === '1' && (
            <div className={styles.tab_content_wrapper}>
              {item?.processEntryVos?.map((i, index) => (
                <Card className={styles.CardDetailStyle} key={i.id}>
                  <Form layout="horizontal" {...createdFormLayout}>
                    <Form.Item label={<>入口{index + 1}</>} style={{ marginBottom: 16 }}>
                      <ShowInput value={i.name} />
                    </Form.Item>
                    <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                      <ShowInput value={i.energyMediumName} />
                    </Form.Item>
                    <Form.Item label="引自" style={{ marginBottom: 16 }}>
                      <ShowInput value={i.refName} />
                    </Form.Item>
                    <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                      {(i?.deviceOuVos ?? []).length > 0
                        ? i.deviceOuVos?.map(i => (
                            <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                              {i.name}
                            </Tag>
                          ))
                        : '--'}
                    </Form.Item>
                  </Form>
                </Card>
              ))}
            </div>
          )}
          {tabsActiveKey === '2' && (
            <div className={styles.tab_content_wrapper}>
              {(item?.processNodeVo ? [item?.processNodeVo] : []).map(i => (
                <Card key={i.id} className={styles.CardDetailStyle}>
                  <Form layout="horizontal" {...createdFormLayout}>
                    <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                      <ShowInput value={i.energyMediumName} />
                    </Form.Item>
                    <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                      {(i?.deviceOuVos ?? []).length > 0
                        ? i.deviceOuVos?.map(i => (
                            <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                              {i.name}
                            </Tag>
                          ))
                        : '--'}
                    </Form.Item>
                  </Form>
                </Card>
              ))}
            </div>
          )}
          {tabsActiveKey === '3' && (
            <div className={styles.tab_content_wrapper}>
              {item?.processExitVos?.map((i, index) => (
                <Card key={i.id} className={styles.CardDetailStyle}>
                  <Form layout="horizontal" {...createdFormLayout}>
                    <Form.Item label={<>出口{index + 1}</>} style={{ marginBottom: 16 }}>
                      <ShowInput value={i.name} />
                    </Form.Item>
                    <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                      <ShowInput value={i.energyMediumName} />
                    </Form.Item>
                    <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                      {(i?.deviceOuVos ?? []).length > 0
                        ? i.deviceOuVos?.map(i => (
                            <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                              {i.name}
                            </Tag>
                          ))
                        : '--'}
                    </Form.Item>
                  </Form>
                </Card>
              ))}
            </div>
          )}
        </Col>
      </Panel>
    </Collapse>
  );
};

export default ShowItemInfo;
