import dayjs, { Dayjs } from 'dayjs';
import { StatisticsPartition } from '../type';

export const colors = ['#0DB6D9', '#63BC7F', '#CE90D1'];

export const objectColors = ['#2D8DFF', '#52E7FF', '#6F47FF', '#FF477B', '#FFCB47'];

export const getDefaultDate = (timeResolution: StatisticsPartition): [Dayjs, Dayjs] => {
  switch (timeResolution) {
    case StatisticsPartition.MONTH:
      return [dayjs(dayjs(), 'x').subtract(5, 'month').startOf('month'), dayjs(dayjs(), 'x').endOf('month')];
    case StatisticsPartition.YEAR:
      return [dayjs(dayjs(), 'x').subtract(2, 'year').startOf('year'), dayjs(dayjs(), 'x').endOf('year')];
    default:
      return [
        dayjs(dayjs(), 'x').subtract(31, 'days').startOf('day'),
        dayjs(dayjs(), 'x').subtract(1, 'days').endOf('day'),
      ];
  }
};
export const transRangeDate = (mode: StatisticsPartition, range: [Dayjs, Dayjs]): [Dayjs, Dayjs] => {
  switch (mode) {
    case StatisticsPartition.YEAR:
      return [dayjs(range[0], 'x').startOf('year'), dayjs(range[1], 'x').endOf('year')];
    case StatisticsPartition.MONTH:
      return [dayjs(range[0], 'x').startOf('month'), dayjs(range[1], 'x').endOf('month')];
    default:
      return [dayjs(range[0], 'x').startOf('day'), dayjs(range[1], 'x').endOf('day')];
  }
};
