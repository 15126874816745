import { CircuitDetailProps, CircuitListByOuResponse, CircuitType, getCircuitListByOu } from '@/api/circuit';
import { ElectricityAccountVo, getElectricityAccounList } from '@/api/electricity';
import { getOuListOwned, OuListResponse } from '@/api/ou';
import { Form, Select } from '@maxtropy/components';
import { Col, FormInstance } from 'antd';
import { isNil } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../index.module.scss';

interface UpstreamCircuitFormProps {
  form: FormInstance;
  isEdit?: boolean;
  ouId?: number;
  data?: CircuitDetailProps;
  onCircuitChangeAutoUetInfo?: (circuitInfo: CircuitListByOuResponse) => void;
  onLeftCircuitChangeAutoUetInfo?: (circuitInfo: CircuitListByOuResponse) => void;
  onRightCircuitChangeAutoUetInfo?: (circuitInfo: CircuitListByOuResponse) => void;
}

const UpstreamCircuit: FC<UpstreamCircuitFormProps> = props => {
  const {
    form,
    isEdit,
    data,
    onCircuitChangeAutoUetInfo,
    onLeftCircuitChangeAutoUetInfo,
    onRightCircuitChangeAutoUetInfo,
  } = props;
  const [ouList, setOuList] = useState<OuListResponse[]>([]);
  const [circuitList, setCircuitList] = useState<CircuitListByOuResponse[]>([]);
  const [leftCircuitList, setLeftCircuitList] = useState<CircuitListByOuResponse[]>([]);
  const [rightCircuitList, setRightCircuitList] = useState<CircuitListByOuResponse[]>([]);
  const [ouId, setOuId] = useState<number>();
  const [ouName, setOuName] = useState<string>();
  const [leftOuId, setLeftOuId] = useState<number>();
  const [leftOuName, setLeftOuName] = useState<string>();
  const [rightOuId, setRightOuId] = useState<number>();
  const [rightOuName, setRightOuName] = useState<string>();
  const type: CircuitType = Form.useWatch('type', form);
  const { id } = useParams<{ id: string }>();
  const [electricityAccountList, setElectricityAccountList] = useState<ElectricityAccountVo[]>([]);
  const formOuId: number = Form.useWatch('ouId', form);

  useEffect(() => {
    getOuListOwned().then(setOuList); // 所属运营单元下拉选择
    getElectricityAccounList().then(res => setElectricityAccountList(res.list || [])); // 关联户号
  }, []);

  // 创建的时候下拉回路list
  useEffect(() => {
    if (ouId) {
      if (isEdit && id) {
        getCircuitListByOu({ ouId, circuitId: Number(id) }).then(setCircuitList); // 上游回路列表下拉选择
      } else {
        getCircuitListByOu({ ouId }).then(setCircuitList); // 上游回路列表下拉选择
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ouId, id]);

  useEffect(() => {
    if (leftOuId) {
      if (isEdit && id) {
        getCircuitListByOu({ ouId: leftOuId, circuitId: Number(id) }).then(setLeftCircuitList); // 上游回路列表下拉选择
      } else {
        getCircuitListByOu({ ouId: leftOuId }).then(setLeftCircuitList); // 左上游回路列表下拉选择
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftOuId, id]);

  useEffect(() => {
    if (rightOuId) {
      if (isEdit && id) {
        getCircuitListByOu({ ouId: rightOuId, circuitId: Number(id) }).then(setRightCircuitList); // 上游回路列表下拉选择
      } else {
        getCircuitListByOu({ ouId: rightOuId }).then(setRightCircuitList); // 左上游回路列表下拉选择
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightOuId, id]);

  const circuitOptions = useMemo(() => {
    if (circuitList && circuitList.length !== 0) {
      return circuitList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [circuitList]);

  const leftCircuitOptions = useMemo(() => {
    if (leftCircuitList && leftCircuitList.length !== 0) {
      return leftCircuitList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [leftCircuitList]);

  const rightCircuitOptions = useMemo(() => {
    if (rightCircuitList && rightCircuitList.length !== 0) {
      return rightCircuitList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [rightCircuitList]);

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length) {
      let options = ouList.map(i => ({ label: i.name, value: i.id }));
      if (!isNil(ouId) && ouName && !options.some(i => i.value === ouId)) {
        options = [{ label: ouName, value: ouId }, ...options];
      }
      return options;
    }
  }, [ouList, ouId, ouName]);

  const leftOuOptions = useMemo(() => {
    if (ouList && ouList.length) {
      let options = ouList.map(i => ({ label: i.name, value: i.id }));
      if (!isNil(leftOuId) && leftOuName && !options.some(i => i.value === leftOuId)) {
        options = [{ label: leftOuName, value: leftOuId }, ...options];
      }
      return options;
    }
  }, [ouList, leftOuId, leftOuName]);

  const rightOuOptions = useMemo(() => {
    if (ouList && ouList.length) {
      let options = ouList.map(i => ({ label: i.name, value: i.id }));
      if (!isNil(rightOuId) && rightOuName && !options.some(i => i.value === rightOuId)) {
        options = [{ label: rightOuName, value: rightOuId }, ...options];
      }
      return options;
    }
  }, [ouList, rightOuId, rightOuName]);

  // 关联户号
  const householdNoOptions = useMemo(() => {
    if (electricityAccountList && electricityAccountList.length !== 0) {
      return electricityAccountList.map(i => ({ label: i.number, value: i.id }));
    }
  }, [electricityAccountList]);

  useEffect(() => {
    if (data) {
      data.upstreamCircuitOuId && setOuId(data.upstreamCircuitOuId);
      data.upstreamCircuitOuName && setOuName(data.upstreamCircuitOuName);
      data.upstreamCircuitLeftOuId && setLeftOuId(data.upstreamCircuitLeftOuId);
      data.upstreamCircuitLeftOuName && setLeftOuName(data.upstreamCircuitLeftOuName);
      data.upstreamCircuitRightOuId && setRightOuId(data.upstreamCircuitRightOuId);
      data.upstreamCircuitRightOuName && setRightOuName(data.upstreamCircuitRightOuName);
    }
  }, [data]);

  return (
    <>
      {type === CircuitType.GRID_BILLING_CIRCUIT ? (
        <></>
      ) : type !== CircuitType.BUS_COUPLER_CIRCUIT ? (
        <Col span={8}>
          <Form.Item label={<span className={styles.required}>上游回路</span>} style={{ marginBottom: 0 }}>
            <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: 10 }}>
              <Select
                placeholder="请选择所属运营单元"
                options={ouOptions}
                disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitOuId)}
                value={ouId}
                showSearch
                optionFilterProp="label"
                onSelect={ouId => {
                  setOuId(ouId);
                  form.setFieldValue('upstreamCircuitId', null);
                }}
              />
            </Form.Item>
            <Form.Item
              name="upstreamCircuitId"
              rules={[{ required: true, message: '请选择回路' }]}
              style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
            >
              <Select
                placeholder="请选择回路"
                disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitOuId)}
                options={circuitOptions}
                showSearch
                optionFilterProp="label"
                onSelect={circuitId => {
                  let findCircuit = circuitList.find(i => i.id === circuitId);
                  findCircuit && onCircuitChangeAutoUetInfo?.(findCircuit);
                  findCircuit &&
                    findCircuit.electricityAccountId &&
                    form.setFieldValue('electricityAccountId', findCircuit.electricityAccountId); // 关联户号回填
                }}
              />
            </Form.Item>
          </Form.Item>
        </Col>
      ) : (
        <>
          <Col span={8}>
            <Form.Item label={<span className={styles.required}>左上游回路</span>} style={{ marginBottom: 0 }}>
              <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: 10 }}>
                <Select
                  placeholder="请选择所属运营单元"
                  options={leftOuOptions}
                  showSearch
                  optionFilterProp="label"
                  disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitLeftOuId)}
                  value={leftOuId}
                  onSelect={ouId => {
                    setLeftOuId(ouId);
                    form.setFieldValue('upstreamCircuitLeftId', null);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="upstreamCircuitLeftId"
                rules={[
                  { required: true, message: '请选择回路' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const upstreamCircuitRightId = getFieldValue('upstreamCircuitRightId');
                      if (upstreamCircuitRightId && value) {
                        let findLeftUet = leftCircuitList.find(i => i.id === value); // 左上游的UET
                        let findRightUet = rightCircuitList.find(i => i.id === upstreamCircuitRightId); // 右上游的UET
                        if (findLeftUet && findRightUet && findLeftUet.uetId === findRightUet.uetId) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('母联上游回路需要在同一个UET中'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
              >
                <Select
                  disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitLeftOuId)}
                  placeholder="请选择回路"
                  options={leftCircuitOptions}
                  showSearch
                  optionFilterProp="label"
                  onSelect={circuitId => {
                    let findCircuit = leftCircuitList.find(i => i.id === circuitId);
                    findCircuit && onLeftCircuitChangeAutoUetInfo?.(findCircuit);
                    findCircuit &&
                      findCircuit.electricityAccountId &&
                      form.setFieldValue('electricityAccountId', findCircuit.electricityAccountId); // 关联户号回填
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={<span className={styles.required}>右上游回路</span>} style={{ marginBottom: 0 }}>
              <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)', marginRight: 10 }}>
                <Select
                  placeholder="请选择所属运营单元"
                  options={rightOuOptions}
                  showSearch
                  optionFilterProp="label"
                  disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitRightOuId)}
                  value={rightOuId}
                  onSelect={ouId => {
                    form.setFieldValue('upstreamCircuitRightId', null);
                    setRightOuId(ouId);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="upstreamCircuitRightId"
                rules={[
                  { required: true, message: '请选择回路' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const upstreamCircuitLeftId = getFieldValue('upstreamCircuitLeftId');
                      if (upstreamCircuitLeftId && value) {
                        let findLeftUet = leftCircuitList.find(i => i.id === upstreamCircuitLeftId); // 左上游的UET
                        let findRightUet = rightCircuitList.find(i => i.id === value); // 右上游的UET
                        if (findLeftUet && findRightUet && findLeftUet.uetId === findRightUet.uetId) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('母联上游回路需要在同一个UET中'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
              >
                <Select
                  placeholder="请选择回路"
                  disabled={isEdit && !ouList.some(item => item.id === data?.upstreamCircuitRightOuId)}
                  options={rightCircuitOptions}
                  showSearch
                  optionFilterProp="label"
                  onSelect={circuitId => {
                    let findCircuit = rightCircuitList.find(i => i.id === circuitId);
                    findCircuit && onRightCircuitChangeAutoUetInfo?.(findCircuit);
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={8}>
        <Form.Item name="electricityAccountId" label="关联户号">
          <Select
            allowClear
            placeholder="请选择"
            style={{ width: '100%' }}
            disabled={!!!formOuId}
            options={householdNoOptions}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default UpstreamCircuit;
