import * as React from 'react';
import { Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Button, Paging, Table, usePaging } from '@maxtropy/components';
import { getEdgeTemplatePage } from '../../../api/template';
import { useQuery } from '../../../utils/utils';
import { UseDaqFormStateProps } from '../common/useDaqFormState';

export interface TemplateVersionModalRef {
  selectedRowKeys: number | undefined;
}

interface TemplateVersionModalProps {
  state: UseDaqFormStateProps;
}

interface TemplateVersionInfo {
  id: number;
  remark: string;
  status: number;
  usedByUsername: string;
  usedByDevice: number;
  updateTime: string;
  version: number;
}

const TemplateVersionModal = React.forwardRef((props: TemplateVersionModalProps, ref: Ref<TemplateVersionModalRef>) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const { templateVersionId } = props.state;
  const [selectedRowKeys, setSelectedRowKeys] = useState<number | undefined>(templateVersionId);

  const onSelect = (record: TemplateVersionInfo) => {
    setSelectedRowKeys(record.id);
  };

  useImperativeHandle(ref, () => ({
    selectedRowKeys,
  }));

  const columns = [
    {
      title: '模板编号',
      dataIndex: 'templateSerialNumber',
    },
    {
      title: '模板名称',
      dataIndex: 'templateName',
    },
    {
      title: '版本号',
      dataIndex: 'version',
    },
    {
      title: '已使用设备数量',
      dataIndex: 'usedByDevice',
    },
    {
      title: '版本备注',
      dataIndex: 'remark',
    },
    {
      title: '操作',
      width: 100,
      render: (record: TemplateVersionInfo) => {
        return selectedRowKeys === record.id ? (
          <span>已选择</span>
        ) : (
          <Button type="link" onClick={() => onSelect(record)}>
            选择
          </Button>
        );
      },
    },
  ];

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (templateVersionId) {
        const page = await getEdgeTemplatePage({
          templateVersionId: templateVersionId,
          page: pageOffset,
          size: pageSize,
        });
        if (page) setTotalCount(page.total);
        return page;
      }
    }, [pageOffset, pageSize, templateVersionId, setTotalCount])
  );

  return (
    <>
      <Table<TemplateVersionInfo> rowKey="id" loading={isLoading} columns={columns} dataSource={data?.list ?? []} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
});

export default TemplateVersionModal;
