import StaffAdminModal from '@/pages/EnergyAssetsPortalConfig/components/StaffCardModal/StaffAdminModal';
import { CardConfigEnum, StaffListType } from '@/pages/EnergyAssetsPortalConfig/types';
import { Table, Select, Form, Input, Modal, Button, EllipsisSpan, useUpdate, CustomFilter } from '@maxtropy/components';
import {
  apiPortalStaffDeletePost,
  apiPortalStaffListPost,
  apiPortalStaffPost,
  PortalStaffListPostRequest,
  PortalStaffListPostResponse,
} from '@maxtropy/device-customer-apis';
import { useBoolean, useUpdateEffect } from 'ahooks';
import React, { MutableRefObject, useEffect } from 'react';

export interface TableData {
  humanCode: string;
  id: number;
  name: string;
  orgName: string;
  phone: number;
  staffCode: string;
  staffType: number;
  username: string;
  staffId?: number;
}

export interface StaffAdminModalRef {
  selectedRows: Array<TableData> | undefined;
  data: Array<TableData> | undefined;
}

const otherColumns = [
  {
    title: '工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
  },
  {
    title: '姓名',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
  },
  {
    title: '账号',
    dataIndex: 'customerUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v}></EllipsisSpan>,
  },
];

interface Props {}
const StaffCardModal: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [adminItems, setAdminItems] = React.useState<Array<any>>([]);
  const [state, update] = useUpdate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [list, setList] = React.useState<PortalStaffListPostResponse>();
  const staffAdminRef: MutableRefObject<StaffAdminModalRef | null> = React.useRef(null);
  const [
    selectStaffModalVisible,
    { setTrue: setSelectStaffModalVisibleTrue, setFalse: setSelectStaffModalVisibleFalse },
  ] = useBoolean(false);

  const onDelete = (id: number) => {
    const idString = String(id);
    Modal.confirm({
      title: '是否从列表中移除此员工',
      content: '移除后，该员工将不再出现在列表中，你还要继续吗?',
      okText: '继续',
      onOk: () => {
        setLoading(true);
        apiPortalStaffDeletePost({ id: idString }).then(_res => {
          setLoading(false);
          update();
        });
      },
    });
  };

  const handleRowChangeType = async (value: CardConfigEnum, record: StaffListType, key: string) => {
    const name = record.customerName;
    const code = record.staffCode;

    const formName = form.getFieldValue('nameOrUsername');
    const formCode = form.getFieldValue('code');

    const isUpdateRowWithnameOrUsername = formName !== '' && formName !== undefined && formName === name;
    const isUpdateRowWithCode = formCode !== '' && formCode !== undefined && formCode === code;

    if (isUpdateRowWithnameOrUsername || isUpdateRowWithCode) {
      apiPortalStaffPost({ ...record, [key]: value }).then(_res => {
        apiPortalStaffListPost({ nameOrUsername: name, code }).then(setList);
      });
    } else {
      const newList = list?.map(item => {
        if (item.staffId === record.staffId) {
          return { ...item, [key]: value };
        }
        return item;
      });
      setList(newList);

      const index = newList?.findIndex(item => item.id === record.id);
      const newRecord = newList?.[index as number];

      await apiPortalStaffPost({ ...newRecord });
    }
  };

  const getList = async (params: PortalStaffListPostRequest) => {
    const nameOrCode = params.nameOrUsername;
    const code = params.code;
    if (nameOrCode === '') {
      delete params.nameOrUsername;
    }
    if (code === '') {
      delete params.code;
    }
    setLoading(true);
    const res = await apiPortalStaffListPost({ ...params });
    // setAdminItems(res);
    setLoading(false);
    setList(res);
  };

  const onFinish = (val: PortalStaffListPostRequest) => {
    getList(val);
  };

  const onOk = () => {
    const staffIds = staffAdminRef.current?.selectedRows?.map(item => item.staffId);
    const ids = adminItems?.map(item => item.staffId);
    const newStaffIds: any = staffIds?.filter(item => !ids?.includes(item));

    setLoading(true);
    apiPortalStaffPost({ staffIds: newStaffIds }).then(_res => {
      setLoading(false);
      update();
      setSelectStaffModalVisibleFalse();
    });
  };

  useUpdateEffect(() => {
    if (!selectStaffModalVisible) {
      form.resetFields();
      update();
    }
  }, [selectStaffModalVisible]);

  useEffect(() => {
    getList({});
  }, [state]);

  useEffect(() => {
    apiPortalStaffListPost({}).then(setAdminItems);
  }, [selectStaffModalVisible]);

  const columns = [
    ...otherColumns,
    {
      title: '重点展示区卡片',
      dataIndex: 'highlightDisplaySourceType',
      render: (v: CardConfigEnum, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'highlightDisplaySourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>同平台配置</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '光伏卡片',
      dataIndex: 'pvSourceType',
      render: (v: CardConfigEnum, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'pvSourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>平台数据</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '储能卡片',
      dataIndex: 'storedEnergySourceType',
      render: (v: number, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'storedEnergySourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>平台数据</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '充电卡片',
      dataIndex: 'chargeSourceType',
      render: (v: number, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'chargeSourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>平台数据</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '精益卡片',
      dataIndex: 'leanSourceType',
      render: (v: number, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'leanSourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>平台数据</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '基础能管卡片',
      dataIndex: 'basicTubeSourceType',
      render: (v: number, record: StaffListType) => (
        <Select defaultValue={v} onChange={value => handleRowChangeType(value, record, 'basicTubeSourceType')}>
          <Select.Option value={CardConfigEnum.PLATFORM_DATA}>平台数据</Select.Option>
          <Select.Option value={CardConfigEnum.NOT_CONFIGURED}>不启用</Select.Option>
        </Select>
      ),
    },
    {
      title: '操作',
      width: 80,
      render: (record: StaffListType) => (
        <Button
          type="link"
          onClick={() => {
            if (record.id) {
              onDelete(record.id);
            }
          }}
        >
          移除
        </Button>
      ),
    },
  ];
  return (
    <>
      <CustomFilter form={form} onFinish={onFinish}>
        <Form.Item name="nameOrUsername" label="姓名/账号">
          <Input placeholder="请输入姓名/账号" allowClear />
        </Form.Item>

        <Form.Item name="code" label="工号">
          <Input placeholder="请输入账号" allowClear />
        </Form.Item>
      </CustomFilter>
      <div style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={setSelectStaffModalVisibleTrue}>
          选择员工
        </Button>
      </div>
      <Table
        scroll={{ y: 446 }}
        columns={columns}
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={false}
      ></Table>

      <Modal
        destroyOnClose
        title="员工选择"
        size="big"
        open={selectStaffModalVisible}
        onCancel={setSelectStaffModalVisibleFalse}
        onOk={onOk}
      >
        <StaffAdminModal selectedItems={adminItems} ref={staffAdminRef}></StaffAdminModal>
      </Modal>
    </>
  );
};

export default StaffCardModal;
