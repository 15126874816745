import { Button, FormTitle, SubContent, Tag, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { Space } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NodeType, NodeTypeDisplay } from '../type';
import SingleDeviceContent from './components/SingleDeviceContent';

const SingleDeviceBoard = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_type = urlSearchParams.get('type') || undefined;
  let url_name = urlSearchParams.get('name') || undefined;
  let url_code = urlSearchParams.get('code') || undefined;
  let url_stationId = urlSearchParams.get('stationId') || undefined;
  let url_stationType = urlSearchParams.get('stationType') || undefined;

  const routes = useMemo(() => {
    if (url_type) {
      return [{ name: `${NodeTypeDisplay[Number(url_type) as NodeType]}` }];
    }
    return [];
  }, [url_type]);

  return (
    <Wrapper className={styles.wrapperPadding} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle
        title={
          <div className={styles.title_style}>
            <div style={{ marginRight: 8 }}>{`${url_name ?? '--'}`}</div>
            <Tag>
              <span style={{ fontWeight: 'normal' }}>
                编号：<span style={{ color: 'var(--mx-text-base-color)' }}>{url_code ?? '--'}</span>
              </span>
            </Tag>
          </div>
        }
        extraContent={
          <Space size={8}>
            <Button type="primary" onClick={() => window.open(`/device/alarm/record`, '_blank')}>
              查看报警信息
            </Button>
            <Button
              onClick={() => {
                navigate(
                  `/production/manage/gasMachineDashBoard?stationId=${url_stationId}&stationType=${url_stationType}`
                );
              }}
            >
              返回拓扑
            </Button>
          </Space>
        }
      />
      <SubContent>
        <SingleDeviceContent nodeType={url_type} nodeId={id} />
      </SubContent>
    </Wrapper>
  );
};

export default SingleDeviceBoard;
