import {
  Table,
  Button,
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  PopConfirm,
} from '@maxtropy/components';
import React, { FC, useState } from 'react';
import { useRequest } from 'ahooks';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import CreateOrEditAccountFormModal, { formSubmitProps } from './components/CreateOrEditAccountFormModal';
import {
  apiV2SupervisoryAccountAddPost,
  apiV2SupervisoryAccountDeletePost,
  apiV2SupervisoryAccountEditPost,
  apiV2SupervisoryAccountPagePost,
} from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';
import { AccessMode, AccessModeDisplay } from '../VideoMonitoring/type';

const columns = [
  {
    title: '账户名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '接入方式',
    dataIndex: 'accessMode',
    ellipsis: { showTitle: true },
    render: (v: AccessMode) => <EllipsisSpan value={AccessModeDisplay[v]} />,
  },
];

const MonitorAccount: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAddAccountPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTADD);
  const hasDelAccountPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTDELETE);
  const hasEditAccountPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTEDIT);
  const hasManageDevicePermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTDEVICEBUTTON);

  const navigate = useNavigate();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<number>();

  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountPagePost({
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total!);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, update],
    }
  );

  const onDelete = (id: number) => {
    apiV2SupervisoryAccountDeletePost({
      id,
    }).then(_ => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (_: any, record: any) => {
        return (
          <Space size={10}>
            {hasDelAccountPermission && (
              <PopConfirm
                title="是否删除该账户？"
                onConfirm={() => {
                  onDelete(record.id);
                }}
              >
                <Button type="link">删除</Button>
              </PopConfirm>
            )}
            {hasEditAccountPermission && (
              <Button
                type="link"
                onClick={() => {
                  setEditModalOpen(true);
                  setAccountId(record.id);
                }}
              >
                编辑账户
              </Button>
            )}
            {hasManageDevicePermission && (
              <Button type="link" onClick={() => navigate(`/monitor/account/configList/${record.id}`)}>
                管理监控设备
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onCancel = () => {
    setEditModalOpen(false);
    setAccountId(undefined);
  };

  const onSubmit = async (values: formSubmitProps) => {
    if (accountId) {
      await apiV2SupervisoryAccountEditPost({
        ...values,
        id: accountId,
      });
      onCancel();
      updateFn();
    } else {
      await apiV2SupervisoryAccountAddPost({
        ...values,
      });
      onCancel();
      setPageOffset(1);
      updateFn();
    }
  };

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
        <Space size={10} direction="vertical" className={styles.btnBlank}>
          {hasAddAccountPermission && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditModalOpen(true);
                setAccountId(undefined);
              }}
            >
              添加账户
            </Button>
          )}
        </Space>
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={pageList ?? []}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      <CreateOrEditAccountFormModal
        accountId={accountId}
        visible={editModalOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default MonitorAccount;
