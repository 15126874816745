import useEqual from '@/shared/hooks/useEqual';
import { Select } from '@maxtropy/components';
import { apiV2EnergyUnitGetEnergyMediumByIdsPost } from '@maxtropy/device-customer-apis-v2';
import { Empty, Spin } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';

export interface MediumSelectProps {
  value?: number;
  ids?: number[];
  disabled?: boolean;
  onChange?: (value?: number) => void;
}
const MediumSelect: React.FC<MediumSelectProps> = ({ value, onChange, disabled, ids }) => {
  const [options, setOptions] = useState<any[]>();
  const [currentValue, setCurrentValue] = useState<number>();
  const newIds = useEqual(ids);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!newIds || !(newIds as number[]).length) {
      setCurrentValue(undefined);
      setOptions([]);
    } else {
      setLoading(true);
      setOptions([]);
      apiV2EnergyUnitGetEnergyMediumByIdsPost({ ids: newIds })
        .then(res => {
          const list = (res.list ?? []).map(m => ({
            label: m.energyMediumName,
            value: m.energyMediumId,
          }));
          setOptions(list);
        })
        .finally(() => setLoading(false));
    }
  }, [newIds]);

  useEffect(() => {
    setCurrentValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const selectChange = (val: number) => {
    setCurrentValue(val);
    onChange?.(val);
  };
  return (
    <Select
      placeholder="请选择"
      style={{ width: '100%' }}
      allowClear
      disabled={disabled}
      options={options}
      value={currentValue}
      onChange={selectChange}
      notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    ></Select>
  );
};

export default React.memo(MediumSelect);
