/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

import { message } from '@maxtropy/components';

const iStreamType = 1;
const g_iWndIndex = 0;

export function initVideoPlugin(videoInfo, setChannelIds, setDisabled, setInit) {
  const { domId, width, height } = videoInfo;
  // 检查插件是否已经安装过
  let iRet = window.WebVideoCtrl.I_CheckPluginInstall();
  if (-1 === iRet) {
    alert('您还未安装过插件，双击开发包目录里的WebComponentsKit.exe安装！');
    return;
  }

  console.log('初始化摄像头');
  // 初始化插件参数及插入插件
  window.WebVideoCtrl.I_InitPlugin(width, height, domId, {
    szColorProperty: 'plugin-background:000; sub-background:000; sub-border:transparent; sub-border-select:transparent', // 插件的背景颜色
    bWndFull: true, //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
    iWndowType: 1, // 分屏类型
    bNoPlugin: true,
    cbInitPluginComplete: async function () {
      if (window.WebVideoCtrl.I_InsertOBJECTPlugin(domId) === 0) {
        console.log('插件嵌入成功');
        login(videoInfo, setChannelIds, setDisabled, setInit);
      } else {
        console.log('插件嵌入失败');
      }

      // 检查插件是否最新
      if (-1 == window.WebVideoCtrl.I_CheckPluginVersion()) {
        alert('检测到新的插件版本，双击开发包目录里的WebComponentsKit.exe升级！');
        return;
      }
    },
  });
}

function login(loginInfo, setChannelIds, setDisabled, setInit) {
  let { szIP, szPort, szUsername, szPassword, szDeviceIdentify } = loginInfo;

  // 参数1: 设备ip
  // 参数2: http协议，1 http协议；2 https协议
  window.WebVideoCtrl.I_Login(szIP, 1, szPort, szUsername, szPassword, {
    success: function (xmlDoc) {
      console.log(szDeviceIdentify + ' 登录成功！');
      setTimeout(function () {
        getChannelInfo(loginInfo, setChannelIds, setDisabled, setInit);
      }, 100);
    },
    error: function (oError) {
      setInit(false);
      console.log(szDeviceIdentify + ' 登录失败！', JSON.stringify(oError));
    },
  });
}

// 获取通道
function getChannelInfo(loginInfo, setChannelIds, setDisabled, setInit) {
  let { szDeviceIdentify } = loginInfo;

  if (!szDeviceIdentify) {
    return;
  }
  // 模拟通道
  window.WebVideoCtrl.I_GetAnalogChannelInfo(szDeviceIdentify, {
    async: false,
    success: function (xmlDoc) {
      console.log(szDeviceIdentify + ' 获取模拟通道成功！');
      if (setChannelIds && setDisabled) {
        let oChannels = $(xmlDoc).find('VideoInputChannel');
        const ids = [0];
        $.each(oChannels, function (i) {
          ids.unshift($(this).find('id').eq(0).text());
        });
        setChannelIds(ids);
        clickStartRealPlay({ ...loginInfo, iChannelID: ids?.[0] }, setDisabled, setInit);
      }
    },
    error: function () {
      setInit?.(false);
      console.log(szDeviceIdentify + ' 获取模拟通道失败！');
    },
  });
}

// 开始预览
export function clickStartRealPlay(loginInfo, setDisabled, setInit) {
  const { szDeviceIdentify, rtspPort, domId, iChannelID } = loginInfo;
  if (!szDeviceIdentify) {
    return;
  }
  window.WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
    iRtspPort: rtspPort,
    iStreamType,
    iChannelID,
    bZeroChannel: iChannelID === 0 ? true : false,
    success: function () {
      console.log('开始预览成功');
      setDisabled(false);
      setInit(false);
      getDeviceInfo(szDeviceIdentify, domId);
    },
    error: function (status, xmlDoc) {
      if (403 === status) {
        console.log('浏览器不支持Websocket取流！请更换ie浏览器');
      } else {
        setInit(false);
        console.log('开始预览失败 ', status, xmlDoc);
      }
    },
  });
}

export function stopVideoPlay(szDeviceIdentify) {
  if (!window.WebVideoCtrl) {
    return;
  }
  window.WebVideoCtrl.I_Stop({
    success: function () {
      console.log('停止预览成功');
      console.log('开始关闭摄像头');
      let szInfo = '';
      var iRet = window.WebVideoCtrl.I_Logout(szDeviceIdentify);
      if (0 == iRet) {
        szInfo = '退出成功！';
      } else {
        szInfo = '退出失败！';
      }
      console.log(szDeviceIdentify + ' ' + szInfo);
    },
    error: function () {
      console.log('停止预览失败');
    },
  });
}

// 获取设备信息
export function getDeviceInfo(szDeviceIdentify, domId) {
  if (!szDeviceIdentify) {
    return;
  }

  window.WebVideoCtrl.I_GetDeviceInfo(szDeviceIdentify, {
    success: function (xmlDoc) {
      let domparser = new DOMParser();
      let doc = domparser.parseFromString(xmlDoc, 'text/html');
      const serialNumber = $(xmlDoc).find('serialNumber').eq(0).text();
      const deviceName = $(xmlDoc).find('deviceName').eq(0).text();

      const deviceInfoBox = document.getElementById('deviceInfo');
      if (deviceInfoBox) {
        deviceInfoBox.innerHTML = `${serialNumber}&nbsp;${deviceName}`;
      }
    },
    error: function (status, xmlDoc) {
      console.log(szDeviceIdentify + ' 获取设备信息失败！', status, xmlDoc);
    },
  });
}

// 停止预览
export function clickStopRealPlay() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
  if (oWndInfo != null) {
    window.WebVideoCtrl.I_Stop({
      success: function () {
        console.log('停止预览成功！');
      },
      error: function () {
        console.log('停止预览失败！');
      },
    });
  }
}

// 抓图
export function clickCapturePic() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    var xmlDoc = window.WebVideoCtrl.I_GetLocalCfg();
    var szCaptureFileFormat = '0';
    if (xmlDoc != null) {
      szCaptureFileFormat = $(xmlDoc).find('CaptureFileFormat').eq(0).text();
    }

    var szChannelID = 1;
    var szPicName = oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();

    szPicName += '0' === szCaptureFileFormat ? '.jpg' : '.bmp';

    window.WebVideoCtrl.I2_CapturePic(szPicName, {
      bDateDir: true, //是否生成日期文件
    }).then(
      function () {
        console.log('抓图成功！');
      },
      function () {
        console.log('抓图失败！');
      }
    );
  }
}

// 打开声音
export function clickOpenSound(volume) {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
  let szInfo = '';

  if (oWndInfo != null) {
    var allWndInfo = window.WebVideoCtrl.I_GetWindowStatus();
    // 循环遍历所有窗口，如果有窗口打开了声音，先关闭
    for (var i = 0, iLen = allWndInfo.length; i < iLen; i++) {
      oWndInfo = allWndInfo[i];
      if (oWndInfo.bSound) {
        window.WebVideoCtrl.I_CloseSound(oWndInfo.iIndex);
        break;
      }
    }

    var iRet = window.WebVideoCtrl.I_OpenSound();

    if (0 == iRet) {
      szInfo = '打开声音成功！';
      clickSetVolume(volume);
    } else {
      szInfo = '打开声音失败！';
    }
    console.log(szInfo);
  }
}

// 关闭声音
export function clickCloseSound() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
  let szInfo = '';

  if (oWndInfo != null) {
    var iRet = window.WebVideoCtrl.I_CloseSound();
    if (0 == iRet) {
      szInfo = '关闭声音成功！';
    } else {
      szInfo = '关闭声音失败！';
    }

    console.log(szInfo);
  }
}

// 设置音量
export function clickSetVolume(iVolume = 0) {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
  let szInfo = '';
  if (oWndInfo != null) {
    var iRet = window.WebVideoCtrl.I_SetVolume(iVolume);
    if (0 == iRet) {
      szInfo = '音量设置成功！';
    } else {
      szInfo = '音量设置失败！';
    }
    console.log(szInfo + ':' + iVolume);
  }
}

// 开始录像
export function clickStartRecord() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    let szChannelID = 1;
    let szFileName = oWndInfo.szDeviceIdentify + '_' + szChannelID + '_' + new Date().getTime();

    window.WebVideoCtrl.I_StartRecord(szFileName, {
      bDateDir: true, //是否生成日期文件
      success: function () {
        console.log('开始录像成功！');
      },
      error: function () {
        console.log('开始录像失败！');
      },
    });
  }
}

// 停止录像
export function clickStopRecord() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    window.WebVideoCtrl.I_StopRecord({
      success: function () {
        console.log('停止录像成功！');
      },
      error: function () {
        console.log('停止录像失败！');
      },
    });
  }
}

// 全屏
export function clickFullScreen() {
  window.WebVideoCtrl.I_FullScreen(true);
}

// 启用电子放大
export function clickEnableEZoom() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    var iRet = window.WebVideoCtrl.I_EnableEZoom();
    if (0 == iRet) {
      console.log('启用电子放大成功！');
    } else {
      console.log('启用电子放大失败！');
    }
  }
}

// 禁用电子放大
export function clickDisableEZoom() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    var iRet = window.WebVideoCtrl.I_DisableEZoom();
    if (0 == iRet) {
      console.log('禁用电子放大成功！');
    } else {
      console.log('禁用电子放大失败！');
    }
  }
}

// PTZ控制 9为自动,1-上，2-下，3-左，4-右为方向PTZ
var g_bPTZAuto = false;
export function mouseDownPTZControl(iPTZIndex, setAutoControl) {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);
  let iPTZSpeed = 4;

  if (oWndInfo != null) {
    if (9 == iPTZIndex && g_bPTZAuto) {
      iPTZSpeed = 0; // 自动开启后，速度置为0可以关闭自动
    } else {
      g_bPTZAuto = false; // 点击其他方向，自动肯定会被关闭
    }

    window.WebVideoCtrl.I_PTZControl(iPTZIndex, false, {
      iPTZSpeed,
      success: function (xmlDoc) {
        if (9 == iPTZIndex && g_bPTZAuto) {
          console.log('停止云台成功！');
          setAutoControl?.(false);
        } else {
          console.log('开启云台成功！');
          setAutoControl?.(9 == iPTZIndex);
        }
        if (9 == iPTZIndex) {
          g_bPTZAuto = !g_bPTZAuto;
        }
      },
      error: function (status, xmlDoc) {
        console.log('开启云台失败！');
      },
    });
  }
}

// 搜索录像
let g_iSearchTimes = 0;
let playbackURI;
let fileName;
export function clickRecordSearch(iType, szDeviceIdentify, iChannelID, startTime, endTime, setSearchSuccess) {
  if (0 == iType) {
    // 首次搜索
    g_iSearchTimes = 0;
  }

  window.WebVideoCtrl.I_RecordSearch(szDeviceIdentify, iChannelID, startTime, endTime, {
    iStreamType,
    iSearchPos: g_iSearchTimes * 40,
    success: function (xmlDoc) {
      if ('MORE' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
        g_iSearchTimes++;
        clickRecordSearch(1, szDeviceIdentify, iChannelID, startTime, endTime, setSearchSuccess); // 继续搜索
      } else if ('OK' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
        var iLength = $(xmlDoc).find('searchMatchItem').length;
        for (var i = 0; i < iLength; i++) {
          var szPlaybackURI = $(xmlDoc).find('playbackURI').eq(i).text();
          if (szPlaybackURI.indexOf('name=') < 0) {
            break;
          }
          var szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf('name=') + 5, szPlaybackURI.indexOf('&size='));

          fileName = szFileName;
          playbackURI = szPlaybackURI;
        }
        console.log(szDeviceIdentify + ' 搜索录像文件成功！');
        setSearchSuccess(true);
      } else if ('NO MATCHES' === $(xmlDoc).find('responseStatusStrg').eq(0).text()) {
        setTimeout(function () {
          console.log(szDeviceIdentify + ' 没有录像文件！');
          message.warning('没有录像文件！');
        }, 50);
      }
    },
    error: function (status, xmlDoc) {
      message.warning(' 搜索录像文件失败！');

      console.log(szDeviceIdentify + ' 搜索录像文件失败！', status, xmlDoc);
    },
  });
}

// 下载录像
var g_iDownloadID = -1;
var g_tDownloadProcess = 0;
export function clickStartDownloadRecord(szDeviceIdentify) {
  if (!szDeviceIdentify) {
    return;
  }

  g_iDownloadID = window.WebVideoCtrl.I_StartDownloadRecord(szDeviceIdentify, playbackURI, fileName, {
    bDateDir: true, //是否生成日期文件
  });

  if (g_iDownloadID < 0) {
    var iErrorValue = window.WebVideoCtrl.I_GetLastError();
    if (34 == iErrorValue) {
      console.log(szDeviceIdentify + ' 已下载！');
    } else if (33 == iErrorValue) {
      console.log(szDeviceIdentify + ' 空间不足！');
    } else {
      console.log(szDeviceIdentify + ' 下载失败！');
    }
  } else {
    console.log(g_tDownloadProcess);
  }
}

// 开始回放
export function clickStartPlayback(loginInfo, szStartTime, szEndTime) {
  const { szDeviceIdentify, rtspPort, iChannelID } = loginInfo;
  if (!szDeviceIdentify) {
    return;
  }

  let oWndInfo = window.I_GetWindowStatus(g_iWndIndex);
  let szInfo = '';

  var startPlayback = function () {
    window.WebVideoCtrl.I_StartPlayback(szDeviceIdentify, {
      iRtspPort: rtspPort,
      iStreamType,
      iChannelID,
      szStartTime: szStartTime,
      szEndTime: szEndTime,
      success: function () {
        szInfo = '开始回放成功！';
        console.log(szDeviceIdentify + ' ' + szInfo);
      },
      error: function (status, xmlDoc) {
        if (403 === status) {
          szInfo = '设备不支持Websocket取流！';
        } else {
          szInfo = '开始回放失败！';
        }
        console.log(szDeviceIdentify + ' ' + szInfo);
      },
    });
  };

  if (oWndInfo != null) {
    // 已经在播放了，先停止
    window.WebVideoCtrl.I_Stop({
      success: function () {
        startPlayback();
      },
    });
  } else {
    startPlayback();
  }
}

// 停止回放
export function clickStopPlayback() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    window.WebVideoCtrl.I_Stop({
      success: function () {
        console.log(oWndInfo.szDeviceIdentify + ' ' + '停止回放成功！');
      },
      error: function () {
        console.log(oWndInfo.szDeviceIdentify + ' ' + '停止回放失败！');
      },
    });
  }
}

// 暂停
export function clickPause() {
  let oWndInfo = window.WebVideoCtrl.I_GetWindowStatus(g_iWndIndex);

  if (oWndInfo != null) {
    window.WebVideoCtrl.I_Pause({
      success: function () {
        console.log(oWndInfo.szDeviceIdentify + '暂停成功！ ');
      },
      error: function () {
        console.log(oWndInfo.szDeviceIdentify + '暂停失败！ ');
      },
    });
  }
}
