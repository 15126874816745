import { getUetEMTList, UetEMTListResponse, UetInfoProps } from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import { Button, Empty, Tag, useTenantPermissions } from '@maxtropy/components';
import { Descriptions, List } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './index.module.scss';

interface EnergyWorkingMediumProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const EnergyWorkingMedium: FC<EnergyWorkingMediumProps> = () => {
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const [list, setList] = useState<UetEMTListResponse[]>([]);
  const permission = useTenantPermissions();

  useEffect(() => {
    if (id) {
      getUetEMTList(id).then(setList);
    }
  }, [id]);

  return (
    <>
      {(permission ?? []).includes(PermissionsType.B_CREATEEMT) && (
        <div style={{ marginLeft: 10 }}>
          <Button type="primary">
            <Link to={`/energy/basic/uet/edit/${id}/${routerKey}/createEnergyWorkingTopo`}>新增能源介质拓扑</Link>
          </Button>
        </div>
      )}
      {list && list.length ? (
        <List
          className={styles.list}
          itemLayout="vertical"
          dataSource={list}
          rowKey="id"
          renderItem={item => (
            <List.Item className={styles.content}>
              <div>
                <Descriptions
                  labelStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                  layout="vertical"
                  colon={false}
                  column={2}
                  style={{ paddingBottom: 22 }}
                >
                  <Descriptions.Item label="能源介质拓扑名称">
                    <span className={styles.descTextStyle}>{item.name}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="能源介质拓扑编号">
                    <Tag border="solid" color="#177DDC">
                      <span className={styles.descTextStyle}>{item.code}</span>
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions
                  layout="vertical"
                  colon={false}
                  column={2}
                  labelStyle={{ color: 'rgba(255, 255, 255, 0.65)' }}
                >
                  <Descriptions.Item label="能源介质场景">
                    <span className={styles.descTextStyle}>{item.energyMediumSceneName}</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="过程数">
                    <span className={styles.descTextStyle}>{item.countOfProcess}</span>
                  </Descriptions.Item>
                </Descriptions>
                <div className={styles.actionContent}>
                  {(permission ?? []).includes(PermissionsType.B_EMTPREVIEW) && (
                    <div className={styles.action}>
                      <Button className={styles.btn} type="link">
                        <Link
                          to={`/energy/basic/uet/edit/${id}/${routerKey}/viewEnergyWorkingTopo/${item.id}`}
                          target="_blank"
                        >
                          预览
                        </Link>
                      </Button>
                    </div>
                  )}
                  {(permission ?? []).includes(PermissionsType.B_UPDATEEMT) && (
                    <div className={styles.action}>
                      <Button className={styles.btn} type="link">
                        <Link to={`/energy/basic/uet/edit/${id}/${routerKey}/editEnergyWorkingTopo/${item.id}`}>
                          编辑
                        </Link>
                      </Button>
                    </div>
                  )}
                  {(permission ?? []).includes(PermissionsType.B_EMTINFO) && (
                    <div className={styles.action}>
                      <Button className={styles.btn} type="link">
                        <Link
                          to={`/energy/basic/uet/edit/${id}/${routerKey}/detailEnergyWorkingTopo/${item.id}`}
                          target="_blank"
                        >
                          查看
                        </Link>
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : (
        <Empty />
      )}
    </>
  );
};

export default EnergyWorkingMedium;
