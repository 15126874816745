import { Form, Input, InputNumber, Modal, Select } from '@maxtropy/components';
import { useContext, useEffect, useState } from 'react';
import { ProductionBeatConfigContext } from '../..';
import {
  ModalType,
  ModalClickType,
  FeatureTypeOptions,
  FeatureType,
  TimeGranularityTypeDisPlay,
  ActionFormValues,
} from '../../utils';
import styles from './index.module.scss';
import { isEmpty, isNil } from 'lodash-es';
import classNames from 'classnames';

interface ActionModalProps {
  open: boolean;
  modalType: ModalType;
  editFormValues?: ActionFormValues;
  onButtonClick: (type: ModalClickType, formValues?: ActionFormValues) => void;
}

// 时间InputNumber相同props
const TimeSameProps = {
  step: '1',
  precision: 0,
  style: { width: '100%' },
  placeholder: '请输入',
  min: 1,
};

// 范围InputNumber相同Props
const RangeSameProps = {
  step: '0.0001',
  precision: 4,
  style: { width: '100%' },
  placeholder: '请输入',
  min: 0,
};

const ActionModal: React.FC<ActionModalProps> = props => {
  const { open, onButtonClick, modalType, editFormValues } = props;
  const [form] = Form.useForm();
  const { physicalUnitName, timeResolution, actionTableData, statusList } = useContext(ProductionBeatConfigContext);
  const [runningLimit, setRunningLimit] = useState<{ upperLimit?: number | string; lowLimit?: number }>();

  //状态开始特征
  const startCharacteristics = Form.useWatch('startCharacteristics', form);
  // 状态结束特征
  const endCharacteristics = Form.useWatch('endCharacteristics', form);
  // 时长范围max
  const durationRangeMax = Form.useWatch(['durationRange', 'durationRangeMax'], form);
  // 动作名称
  const actionName = Form.useWatch('actionName', form);

  useEffect(() => {
    if (statusList && statusList.find(status => status.state === 3)) {
      const { upperLimit, lowLimit } = statusList.find(status => status.state === 3)!;
      setRunningLimit({ upperLimit: upperLimit ?? '+∞', lowLimit });
    }
  }, [statusList]);

  useEffect(() => {
    if (modalType === ModalType.EDIT && editFormValues) {
      form.setFieldsValue(editFormValues);
    }
  }, [modalType, editFormValues, form]);

  const rangeValidator = (baseField: string, message: string, rangFiled = ['Min', 'Max']) => {
    const min = form.getFieldValue([baseField, `${baseField}${rangFiled[0]}`]);
    const max = form.getFieldValue([baseField, `${baseField}${rangFiled[1]}`]);

    if (isNil(min) && isNil(max)) {
      return Promise.reject(new Error(message));
    }
    if (isNil(min) || isNil(max)) {
      return Promise.reject(new Error('区间上下限需填写完整'));
    }
    if (min >= max) {
      return Promise.reject(new Error('最小值需要小于最大值'));
    }

    if (!isEmpty(runningLimit) && baseField === 'useEnergy' && min <= runningLimit?.lowLimit!) {
      return Promise.reject(new Error('用能范围不在运行状态的区间内'));
    }
    return Promise.resolve([min, max]);
  };

  const actionToRangeValidator = (
    baseField: string,
    rangeValues: [number, number],
    callback: (reason?: any) => void
  ) => {
    const formValueRangeMin = form.getFieldValue([`${baseField}FromValueRange`, `${baseField}FromValueRangeMin`]);
    const formValueRangeMax = form.getFieldValue([`${baseField}FromValueRange`, `${baseField}FromValueRangeMax`]);
    const characteristics = baseField.includes('start')
      ? form.getFieldValue('startCharacteristics')
      : form.getFieldValue('endCharacteristics');

    if (characteristics === FeatureType.CLIMB_RATE && formValueRangeMax && formValueRangeMax > rangeValues[0]) {
      callback('爬升速率：起始值最大值需要小于等于结束值最小值');
    }
    if (characteristics === FeatureType.DESCENT_RATE && formValueRangeMin && formValueRangeMin < rangeValues[1]) {
      callback('下降速率：起始值最小值需要大于等于结束值最大值');
    }

    if (
      characteristics === FeatureType.CLIMB_RATE &&
      !isEmpty(runningLimit) &&
      rangeValues[0] < runningLimit?.lowLimit!
    ) {
      callback('结束值范围需在运行状态用能区间内');
    }
  };

  const actionFromRangeValidator = (
    baseField: string,
    rangeValues: [number, number],
    callback: (reason?: any) => void
  ) => {
    const toValueRangeMin = form.getFieldValue([`${baseField}ToValueRange`, `${baseField}ToValueRangeMin`]);
    const toValueRangeMax = form.getFieldValue([`${baseField}ToValueRange`, `${baseField}ToValueRangeMax`]);
    const characteristics = baseField.includes('start')
      ? form.getFieldValue('startCharacteristics')
      : form.getFieldValue('endCharacteristics');

    if (characteristics === FeatureType.CLIMB_RATE && toValueRangeMax && rangeValues[1] > toValueRangeMin) {
      callback('爬升速率：起始值最大值需要小于等于结束值最小值');
    }
    if (characteristics === FeatureType.DESCENT_RATE && toValueRangeMin && rangeValues[0] < toValueRangeMax) {
      callback('下降速率：起始值最小值需要大于等于结束值最大值');
    }

    if (
      characteristics === FeatureType.DESCENT_RATE &&
      !isEmpty(runningLimit) &&
      rangeValues[0] < runningLimit?.lowLimit!
    ) {
      callback('起始值范围需在运行状态用能区间内');
    }
  };

  return (
    <Modal
      title={`${modalType === ModalType.ADD ? '新增' : '编辑'}动作`}
      open={open}
      onCancel={() => onButtonClick(ModalClickType.CANCEl)}
      onOk={() => {
        form.validateFields().then(() => {
          // 已有动作名称
          let actionNames = (actionTableData ?? [])
            .filter(i => i.id !== editFormValues?.id)
            .map(item => item.actionName);
          if (modalType === ModalType.EDIT) {
            actionNames = actionNames.filter(i => i !== editFormValues?.actionName);
          }
          if (actionNames.includes(actionName)) {
            form.setFields([
              {
                name: 'actionName',
                errors: ['动作名称已存在'],
              },
            ]);
            return;
          }
          onButtonClick(ModalClickType.SAVE, form.getFieldsValue());
        });
      }}
      contentClassName="modal-form-content"
      contentStyle={{
        overflowY: 'auto',
        maxHeight: 448,
      }}
    >
      <Form
        form={form}
        initialValues={{
          id: Math.random() * 1000000000000,
          startCharacteristics: FeatureType.CLIMB_RATE,
          endCharacteristics: FeatureType.DESCENT_RATE,
        }}
      >
        <Form.Item
          name="actionName"
          label="动作名称"
          rules={[
            { required: true, message: '请输入动作名称' },
            {
              max: 10,
              message: '最多输入10个字符',
            },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          required
          label="时长范围"
          dependencies={[
            ['durationRange', 'durationRangeMin'],
            ['durationRange', 'durationRangeMax'],
          ]}
          name="range"
          rules={[
            () => ({
              validator: () => rangeValidator('durationRange', '请填写时长范围'),
            }),
          ]}
        >
          <div>
            <Form.Item
              name={['durationRange', 'durationRangeMin']}
              className={classNames(styles.formItemStyle, styles.inputNumberStart)}
            >
              <InputNumber {...TimeSameProps} />
            </Form.Item>
            <Form.Item noStyle>
              <p className={styles.pInterval}>~</p>
            </Form.Item>
            <Form.Item
              name={['durationRange', 'durationRangeMax']}
              className={classNames(styles.formItemStyle, styles.inputNumberEnd)}
            >
              <InputNumber {...TimeSameProps} />
            </Form.Item>
            <Form.Item noStyle>
              <p className={styles.pInterval} style={{ width: 50 }}>
                {TimeGranularityTypeDisPlay[timeResolution]}
              </p>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          required
          name="useEnergyRange"
          label="用能范围"
          dependencies={[
            ['useEnergy', 'useEnergyLowLimit'],
            ['useEnergy', 'useEnergyUpperLimit'],
          ]}
          rules={[
            () => ({
              validator: () => rangeValidator('useEnergy', '请填写用能范围', ['LowLimit', 'UpperLimit']),
            }),
          ]}
        >
          <div>
            <Form.Item
              name={['useEnergy', 'useEnergyLowLimit']}
              className={classNames(styles.formItemStyle, styles.inputNumberStart)}
            >
              <InputNumber {...RangeSameProps} />
            </Form.Item>
            <Form.Item noStyle>
              <p className={styles.pInterval}>~</p>
            </Form.Item>
            <Form.Item
              name={['useEnergy', 'useEnergyUpperLimit']}
              className={classNames(styles.formItemStyle, styles.inputNumberEnd)}
            >
              <InputNumber {...RangeSameProps} />
            </Form.Item>
            <Form.Item noStyle>
              <p className={styles.pInterval} style={{ width: 50 }}>
                {physicalUnitName ?? '--'}
              </p>
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item label="运行用能区间">
          <Form.Item className={classNames(styles.formItemStyle, styles.inputNumberStart)}>
            <Input value={runningLimit?.lowLimit} disabled />
          </Form.Item>
          <Form.Item noStyle>
            <p className={styles.pInterval}>~</p>
          </Form.Item>
          <Form.Item className={classNames(styles.formItemStyle, styles.inputNumberEnd)}>
            <Input value={runningLimit?.upperLimit} disabled />
          </Form.Item>
          <Form.Item noStyle>
            <p className={styles.pInterval} style={{ width: 50 }}>
              {physicalUnitName ?? '--'}
            </p>
          </Form.Item>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: '请选择动作开始特征' }]}
          name="startCharacteristics"
          label="动作开始特征"
        >
          <Select
            placeholder="请选择"
            options={FeatureTypeOptions}
            onChange={() => {
              form.setFieldsValue({
                startCharacteristicsTime: undefined,
                startEnergyUsageQuota: undefined,
                startFromValueRange: undefined,
                startToValueRange: undefined,
              });
            }}
          />
        </Form.Item>
        {
          // 状态开始特征为瞬时量-爬升速率或者下降速率时
          startCharacteristics && startCharacteristics !== FeatureType.INSTANT_VALUE && (
            <div className={styles.featureDiv}>
              <Form.Item
                name="startCharacteristicsTime"
                label={startCharacteristics === FeatureType.CLIMB_RATE ? '爬升时间' : '下降时间'}
                rules={[
                  {
                    required: true,
                    message: `${startCharacteristics === FeatureType.CLIMB_RATE ? '请输入爬升时间' : '请输入下降时间'}`,
                  },
                ]}
              >
                <InputNumber
                  max={durationRangeMax}
                  {...TimeSameProps}
                  addonAfter={TimeGranularityTypeDisPlay[timeResolution]}
                />
              </Form.Item>
              <Form.Item
                required
                name="startFromRange"
                label="起始值范围"
                dependencies={[
                  ['startFromValueRange', 'startFromValueRangeMin'],
                  ['startFromValueRange', 'startFromValueRangeMax'],
                ]}
                rules={[
                  () => ({
                    validator: () => {
                      return new Promise((resolve, reject) => {
                        rangeValidator('startFromValueRange', '请填写起始值范围')
                          .then(([min, max]) => {
                            actionFromRangeValidator('start', [min, max], reject);
                            resolve(0);
                          })
                          .catch(error => {
                            return reject(error.message);
                          });
                      });
                    },
                  }),
                ]}
              >
                <div>
                  <Form.Item
                    name={['startFromValueRange', 'startFromValueRangeMin']}
                    className={classNames(styles.formItemStyle, styles.inputNumberStart)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval}>~</p>
                  </Form.Item>
                  <Form.Item
                    name={['startFromValueRange', 'startFromValueRangeMax']}
                    className={classNames(styles.formItemStyle, styles.inputNumberEnd)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval} style={{ width: 50 }}>
                      {physicalUnitName ?? '--'}
                    </p>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                required
                name="startToRange"
                label="结束值范围"
                className={styles.formItemStyle}
                dependencies={[
                  ['startToValueRange', 'startToValueRangeMin'],
                  ['startToValueRange', 'startToValueRangeMax'],
                ]}
                rules={[
                  () => ({
                    validator: () => {
                      return new Promise((resolve, reject) => {
                        rangeValidator('startToValueRange', '请填写结束值范围')
                          .then(([min, max]) => {
                            actionToRangeValidator('start', [min, max], reject);
                            resolve(0);
                          })
                          .catch(error => {
                            return reject(error.message);
                          });
                      });
                    },
                  }),
                ]}
              >
                <div>
                  <Form.Item
                    name={['startToValueRange', 'startToValueRangeMin']}
                    className={classNames(styles.inputNumberStart, styles.formItemStyle)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval}>~</p>
                  </Form.Item>
                  <Form.Item
                    name={['startToValueRange', 'startToValueRangeMax']}
                    className={classNames(styles.inputNumberEnd, styles.formItemStyle)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval} style={{ width: 50 }}>
                      {physicalUnitName ?? '--'}
                    </p>
                  </Form.Item>
                </div>
              </Form.Item>
            </div>
          )
        }
        {
          // 状态开始特征为瞬时量-瞬时值时
          startCharacteristics === FeatureType.INSTANT_VALUE && (
            <div
              style={{
                display: 'flex',
              }}
              className={styles.featureDiv}
            >
              <Form.Item
                rules={[{ required: true, message: '请输入用能指标' }]}
                name="startEnergyUsageQuota"
                label="用能指标"
                className={styles.formItemStyle}
              >
                <InputNumber
                  {...TimeSameProps}
                  style={{ width: '159px' }}
                  max={durationRangeMax}
                  addonAfter={TimeGranularityTypeDisPlay[timeResolution]}
                />
              </Form.Item>
              <Form.Item noStyle>
                <p className={styles.pInterval} style={{ width: 160 }}>
                  内均在状态用能范围
                </p>
              </Form.Item>
            </div>
          )
        }
        <Form.Item
          rules={[{ required: true, message: '请选择动作结束特征' }]}
          name="endCharacteristics"
          label="动作结束特征"
        >
          <Select
            placeholder="请选择"
            options={FeatureTypeOptions}
            onChange={() => {
              form.setFieldsValue({
                endCharacteristicsTime: undefined,
                endEnergyUsageQuota: undefined,
                endFromValueRange: undefined,
                endToValueRange: undefined,
              });
            }}
          />
        </Form.Item>
        {
          // 状态结束特征为瞬时量-爬升速率或者下降速率时
          endCharacteristics && endCharacteristics !== FeatureType.INSTANT_VALUE && (
            <div className={styles.featureDiv}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: `${endCharacteristics === FeatureType.CLIMB_RATE ? '请输入爬升时间' : '请输入下降时间'}`,
                  },
                ]}
                name="endCharacteristicsTime"
                label={endCharacteristics === FeatureType.CLIMB_RATE ? '爬升时间' : '下降时间'}
              >
                <InputNumber
                  {...TimeSameProps}
                  max={durationRangeMax}
                  addonAfter={TimeGranularityTypeDisPlay[timeResolution]}
                />
              </Form.Item>
              <Form.Item
                required
                name="endFromRange"
                label="起始值范围"
                dependencies={[
                  ['endFromValueRange', 'endFromValueRangeMin'],
                  ['endFromValueRange', 'endFromValueRangeMax'],
                ]}
                rules={[
                  () => ({
                    validator: () => {
                      return new Promise((resolve, reject) => {
                        rangeValidator('endFromValueRange', '请填写起始值范围')
                          .then(([min, max]) => {
                            actionFromRangeValidator('end', [min, max], reject);
                            resolve(0);
                          })
                          .catch(error => {
                            return reject(error.message);
                          });
                      });
                    },
                  }),
                ]}
              >
                <div>
                  <Form.Item
                    name={['endFromValueRange', 'endFromValueRangeMin']}
                    className={classNames(styles.formItemStyle, styles.inputNumberStart)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval}>~</p>
                  </Form.Item>
                  <Form.Item
                    name={['endFromValueRange', 'endFromValueRangeMax']}
                    className={classNames(styles.formItemStyle, styles.inputNumberEnd)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval} style={{ width: 50 }}>
                      {physicalUnitName ?? '--'}
                    </p>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                required
                name="endToRange"
                label="结束值范围"
                className={styles.formItemStyle}
                dependencies={[
                  ['endToValueRange', 'endToValueRangeMin'],
                  ['endToValueRange', 'endToValueRangeMax'],
                ]}
                rules={[
                  () => ({
                    validator: () => {
                      return new Promise((resolve, reject) => {
                        rangeValidator('endToValueRange', '请填写结束值范围')
                          .then(([min, max]) => {
                            actionToRangeValidator('end', [min, max], reject);
                            return resolve(0);
                          })
                          .catch(error => {
                            return reject(error.message);
                          });
                      });
                    },
                  }),
                ]}
              >
                <div>
                  <Form.Item
                    name={['endToValueRange', 'endToValueRangeMin']}
                    className={classNames(styles.inputNumberStart, styles.formItemStyle)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval}>~</p>
                  </Form.Item>
                  <Form.Item
                    name={['endToValueRange', 'endToValueRangeMax']}
                    className={classNames(styles.inputNumberEnd, styles.formItemStyle)}
                  >
                    <InputNumber {...RangeSameProps} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <p className={styles.pInterval} style={{ width: 50 }}>
                      {physicalUnitName ?? '--'}
                    </p>
                  </Form.Item>
                </div>
              </Form.Item>
            </div>
          )
        }

        {
          // 状态结束特征为瞬时量-瞬时值时
          endCharacteristics === FeatureType.INSTANT_VALUE && (
            <div
              style={{
                display: 'flex',
              }}
              className={styles.featureDiv}
            >
              <Form.Item
                name="endEnergyUsageQuota"
                rules={[{ required: true, message: '请输入用能指标' }]}
                label="用能指标"
                className={styles.formItemStyle}
              >
                <InputNumber
                  {...TimeSameProps}
                  max={durationRangeMax}
                  style={{ width: '159px' }}
                  addonAfter={TimeGranularityTypeDisPlay[timeResolution]}
                />
              </Form.Item>
              <Form.Item noStyle>
                <p className={styles.pInterval} style={{ width: 160 }}>
                  内均不在状态用能范围
                </p>
              </Form.Item>
            </div>
          )
        }
        <Form.Item noStyle name="id">
          <span></span>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActionModal;
