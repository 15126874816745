import { Key, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import DragResize from '@/components/DragResize';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import StationTree from './components/StationTree';
import { DataNode } from 'antd/es/tree';
import RightContent from './components/RightContent';
import { WorkProcedureType } from './mockData';

const ProductionBeatDemo: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle

  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [workProcedureType, setWorkProcedureType] = useState<WorkProcedureType>(WorkProcedureType.cut); // 工序类型

  useEffect(() => {
    setTreeSelectedKeys(['station1cut']);
  }, []);

  useEffect(() => {
    if (treeSelectedKeys.length > 0) {
      setWorkProcedureType(
        treeSelectedKeys[0].toString().includes('cut')
          ? WorkProcedureType.cut
          : treeSelectedKeys[0].toString().includes('punch')
          ? WorkProcedureType.punch
          : WorkProcedureType.glassPress
      );
    }
  }, [treeSelectedKeys]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 左部树选择
  const onTreeSelect = (v: Key[], selectedNodes: DataNode[]) => {
    setTreeSelectedKeys(v);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: '#232324' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree
            selectedKeys={treeSelectedKeys}
            expandedKeys={treeExpandedKeys}
            setExpandedKeys={setTreeExpandedKeys}
            autoExpandParent={autoExpandParent}
            setAutoExpandParent={setAutoExpandParent}
            onTreeSelect={onTreeSelect}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <RightContent workProcedureType={workProcedureType} />
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default ProductionBeatDemo;
