export enum Status {
  // 启用 1
  ENABLE = 1,
  // 禁用 2
  DISABLE = 2,
}

export const StatusDisplay = {
  [Status.ENABLE]: '启用',
  [Status.DISABLE]: '禁用',
};

export interface ListTypes {
  id?: number;
  /**
   * 规则名称
   */
  name?: string;
  /**
   * 核算单元id
   */
  accountingUnitId?: number;
  accountingUnitName?: string;
  /**
   * 状态（1:启用 2:禁用）
   */
  state?: number;
  /**
   * 排放过程id
   */
  emissionProcessId?: number;
  emissionProcessName?: number;
  tenantMcid?: string;
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 规则表单字段
   */
  fields?: {
    id?: number;
    /**
     * 自动填报规则id
     */
    autoFillingRuleId?: number;
    /**
     * 表单字段id
     */
    formFieldId?: number;
    /**
     * 表单字段值
     */
    formFieldValue?: string;
    /**
     * 值类型(1:固定值 2:自动数采取数)
     */
    valueType?: number;
    /**
     * 自动数采设置id
     */
    autoDataSettingId?: number;
    tenantMcid?: string;
    createTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    updateTime?: {
      /**
       * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
       */
      seconds?: number;
      /**
       * The number of nanoseconds, later along the time-line, from the seconds field.
       * This is always positive, and never exceeds 999,999,999.
       */
      nanos?: number;
    };
    /**
     * 自动数采设置
     */
    autoDataSetting?: {
      id?: number;
      /**
       * 要求物理量单位id
       */
      requirePhysicalUnitId?: number;
      /**
       * 用能分析组id
       */
      energyGroupId?: number;
      /**
       * 用能单元id
       */
      energyUnitId?: number;
      /**
       * 计量类别id
       */
      meterTypeId?: number;
      tenantMcid?: string;
      createTime?: {
        /**
         * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
         */
        seconds?: number;
        /**
         * The number of nanoseconds, later along the time-line, from the seconds field.
         * This is always positive, and never exceeds 999,999,999.
         */
        nanos?: number;
      };
      updateTime?: {
        /**
         * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
         */
        seconds?: number;
        /**
         * The number of nanoseconds, later along the time-line, from the seconds field.
         * This is always positive, and never exceeds 999,999,999.
         */
        nanos?: number;
      };
      /**
       * 默认物理量单位id
       */
      defaultPhysicalUnitId?: number;
    };
  }[];
}
