import { FC, useEffect, useState } from 'react';
import { Space } from 'antd';
import SelectRuleGroupModalChildren from './ModalChildren';
import styles from './index.module.scss';
import { IotProtocolType } from '@/shared/types';
import { Button, Modal } from '@maxtropy/components';
import { RuleGroupList } from '@/pages/Device/BatchBindRuleGroup/SelectRuleGroup/types';

interface SelectRuleGroupProps {
  value?: RuleGroupList;
  onChange?: (value?: RuleGroupList) => void;
  iotProtocol?: IotProtocolType;
  deviceTypeId?: number;
  objectModalType?: number;
}

const SelectRuleGroup: FC<SelectRuleGroupProps> = ({ value, onChange, iotProtocol, deviceTypeId, objectModalType }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<RuleGroupList>();

  useEffect(() => {
    setDataSource(value);
  }, [value]);

  const onAdd = () => {
    if (iotProtocol === undefined) {
      Modal.warning({
        title: '请先选择物联层协议',
      });
      return;
    }
    if (iotProtocol === IotProtocolType.MOCKINGBIRD && deviceTypeId === undefined) {
      Modal.warning({
        title: '请先选择适用设备类目',
      });
      return;
    }
    setVisible(true);
  };

  const onCancel = () => {
    setDataSource(value);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space>
        {value !== undefined && (value?.name ?? '')}
        <Button type="link" onClick={onAdd} style={{ paddingLeft: 0 }}>
          {value === undefined ? '点击' : '重新'}选择
        </Button>
      </Space>
      <Modal
        className={styles.modal}
        title="规则组选择"
        open={visible}
        destroyOnClose
        onOk={onOk}
        onCancel={onCancel}
        size="big"
      >
        <SelectRuleGroupModalChildren
          iotProtocol={iotProtocol}
          deviceTypeId={deviceTypeId}
          onChange={setDataSource}
          value={dataSource}
          objectModalType={objectModalType}
        />
      </Modal>
    </>
  );
};

export default SelectRuleGroup;
