import { Button, EllipsisSpan, Key, useUpdate } from '@maxtropy/components';
import { InputNumber, Space, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MeterProjectChangeButtonType } from '../MeterProjectDetail';
import styles from './index.module.scss';
import { ApportionListItem, SummaryTableDataItem, apportionFunc, getSummaryTableData } from '@/api/universeMeterQuery';
import { addNum } from '../../utils';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import TotalEleShareInput from '../TotalEleShareInput';
import { getCellStyle, getRowSpan } from '../../handleTreeDataUtils';

interface SummaryMeterTableProps {
  changeButtonType?: MeterProjectChangeButtonType;
  setChangeButtonType: (type?: MeterProjectChangeButtonType) => void;
  level?: number;
}

const HierarchySummaryMeterTable: React.FC<SummaryMeterTableProps> = ({
  changeButtonType,
  setChangeButtonType,
  level,
}) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<SummaryTableDataItem[]>([]);
  const [beforeEditData, setBeforeEditData] = useState<SummaryTableDataItem[]>([]);
  const [editedIds, setEditedIds] = useState<Key[]>([]); // 编辑中的id
  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getSummaryTableData(id, level)
        .then(res => {
          if (res) {
            const tableData: SummaryTableDataItem[] = [];
            res.list.forEach(project => {
              project.rows.forEach(row => {
                tableData.push({
                  id: `${project.meterReadingObjectId}-${row.energyMediumId}`,
                  meterReadingObjectId: project.meterReadingObjectId,
                  meterReadingLevel: project.meterReadingLevel,
                  meterReadingObjectName: project.meterReadingObjectName,
                  energyMediumId: row.energyMediumId,
                  energyMediumName: row.energyMediumName,
                  fact: row.realReadingAmount,
                  adjust: row.adjustAmount,
                  afterAdjust: row.afterAdjustAmount,
                  share: row.apportionAmount,
                  summitShare: row.summitApportionAmount,
                  peakShare: row.peakApportionAmount,
                  plainShare: row.plainApportionAmount,
                  valleyShare: row.valleyApportionAmount,
                  final: row.finalAmount,
                });
              });
            });
            setData(tableData);
            setBeforeEditData(tableData);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, update, level]);

  const columns = useMemo(() => {
    // 编辑分摊值
    const onChangeShare = (value: number | null, record: SummaryTableDataItem) => {
      const newData = data.map(i => {
        if (record.id === i.id) {
          const share = value ?? 0;
          const final = addNum(i.afterAdjust, share);
          return {
            ...i,
            share,
            final,
          };
        }
        return i;
      });
      setData(newData);
    };

    const levelColumns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
        }),
        render: (v: number) => {
          return <EllipsisSpan value={v ? `${v}级` : v} />;
        },
      },
    ];

    const columns = [
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
        }),
        render: (v: string, record: SummaryTableDataItem) => {
          return <EllipsisSpan value={v} />;
        },
      },

      {
        title: '能源介质',
        dataIndex: 'energyMediumName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '实际抄表值',
        dataIndex: 'fact',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整值',
        dataIndex: 'adjust',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整后值',
        dataIndex: 'afterAdjust',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '分摊值',
        dataIndex: 'share',
        width: changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? 650 : 200,
        ellipsis: { showTitle: true },
        onHeaderCell: () => {
          return {
            style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
          };
        },
        onCell: () => {
          return {
            style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
          };
        },
        render: (v: number, record: SummaryTableDataItem) => {
          return changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? (
            record.energyMediumName === '电能' ? (
              <TotalEleShareInput
                data={data}
                currentRow={record}
                setData={setData}
                setEditedIds={setEditedIds}
                editedIds={editedIds}
              />
            ) : (
              <InputNumber
                value={v}
                style={{ width: 148 }}
                min={0}
                max={999999}
                step={0.01}
                precision={2}
                onChange={value => {
                  onChangeShare(value, record);
                  setEditedIds([...editedIds, record.meterReadingObjectId]);
                }}
              />
            )
          ) : (
            <EllipsisSpan value={v} />
          );
        },

        editable: true,
      },
      {
        title: '最终值',
        dataIndex: 'final',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
    ];

    if (!isNil(level)) {
      return [...levelColumns, ...columns];
    }
    return columns;
  }, [changeButtonType, data, editedIds, level]);

  const onSave = () => {
    if (id) {
      const editData = data.filter(i => editedIds.includes(i.meterReadingObjectId));
      const apportionList: ApportionListItem[] = editData.map(i => {
        return {
          taskId: id,
          objectId: i.meterReadingObjectId,
          energyMediumId: i.energyMediumId,
          adjustType: 1, // 非电能
          apportionAmount: i.share,
        };
      });
      apportionFunc({
        list: apportionList,
      }).then(() => {
        setChangeButtonType(undefined);
        setBeforeEditData(data);
        setEditedIds([]);
        updateFn();
      });
    }
  };

  return (
    <>
      {changeButtonType && (
        <Space size={8}>
          <Button type="primary" onClick={onSave}>
            保存
          </Button>
          <Button
            onClick={() => {
              setChangeButtonType(undefined);
              setData(beforeEditData);
              setEditedIds([]);
            }}
          >
            取消
          </Button>
        </Space>
      )}

      <div className={styles.tableStyles}>
        <Table loading={loading} bordered columns={columns} rowKey="id" pagination={false} dataSource={data} sticky />
      </div>
    </>
  );
};

export default HierarchySummaryMeterTable;
