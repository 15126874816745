import React, { useState } from 'react';
import classNames from 'classnames';
import styles from '../index.module.scss';
import { FcsListProps } from '../../../../api/fcs';
import { DistributeStatus } from '@/api/netWorking';
import { GatewayStatus } from '@/shared/types';
import { Button } from '@maxtropy/components';

export interface OperationProps {
  record: FcsListProps;
  applyGateway: (id: number) => void;
}

const Operation: React.FC<OperationProps> = ({ record, applyGateway }) => {
  const [applying, setApplying] = useState<boolean>(false);

  // 配置下发
  const onApply = async (record: FcsListProps) => {
    setApplying(true);
    await applyGateway(record.gatewayId);
    setApplying(false);
  };
  return (
    <Button
      type="link"
      disabled={
        record.edgeGatewayAbstractVo.edgeNetwork?.distributeStatus === DistributeStatus.NOTSTART ||
        record.edgeGatewayAbstractVo.edgeNetwork?.distributeStatus === DistributeStatus.STARTING ||
        record.edgeGatewayAbstractVo.state === GatewayStatus.DISABLE ||
        applying
      }
      onClick={() => onApply(record)}
    >
      {applying ? (
        <div>数采正在下发</div>
      ) : (
        <div
          className={classNames(styles.txt, {
            [styles.danger]:
              !!record.edgeGatewayAbstractVo.configUpdated &&
              record.edgeGatewayAbstractVo.edgeNetwork?.distributeStatus !== DistributeStatus.NOTSTART &&
              record.edgeGatewayAbstractVo.edgeNetwork?.distributeStatus !== DistributeStatus.STARTING,
          })}
        >
          数采配置下发
        </div>
      )}
    </Button>
  );
};

export default Operation;
