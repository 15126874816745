import { AdjustmentMeterRecordsRes } from '@/api/electricity';
import { Card, Row, Col, Form } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { ShowInput } from '@maxtropy/components';

interface Props {
  data: AdjustmentMeterRecordsRes;
}

const Show: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <ShowInput
      value={value}
      style={{
        color: 'var(--primary-color)',
        fontSize: 18,
        fontWeight: '400',
        fontFamily: 'PingFangSC-Regular, PingFang SC',
      }}
      addonAfter="kWh"
    />
  );
};

const ReportCard: React.FC<Props> = ({ data }) => {
  return (
    <Card className={styles.cardStyles} style={{ background: '#262d37' }}>
      <p className={styles.pStyles}>
        抄表值 (
        <ShowInput style={{ display: 'inline' }} value={dayjs(data.meterReadingTs).format('YYYY-MM-DD HH:mm:ss')} />)
      </p>
      <Form className={styles.formStyles} layout="vertical">
        <Row>
          <Col span={4}>
            <Form.Item label="正向总">
              <Show value={data.epp} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="正向尖">
              <Show value={data.epp1} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="正向峰">
              <Show value={data.epp2} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="正向平">
              <Show value={data.epp3} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="正向谷">
              <Show value={data.epp4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Form.Item label="反向总">
              <Show value={data.epn} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="反向尖">
              <Show value={data.epn1} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="反向峰">
              <Show value={data.epn2} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="反向平">
              <Show value={data.epn3} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="反向谷">
              <Show value={data.epn4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ReportCard;
