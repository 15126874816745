import React, { CSSProperties } from 'react';
import styles from './index.module.scss';
import tittleDecoration from '../../assets/tittleDecoration.png';

export interface EnergyContentProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  extraContent?: React.ReactNode;
  style?: CSSProperties;
}

const EnergyContent: React.FC<EnergyContentProps> = props => {
  const { title, style, extraContent } = props;

  return (
    <div className={styles.personalFormContainer} style={style}>
      <div className={styles.headerWrapper}>
        {title ? (
          <div className={styles.formTitle}>
            <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
            {title}
          </div>
        ) : (
          <div />
        )}
        {extraContent ? extraContent : <div />}
      </div>
      <div className={styles.borderStyles} />

      {props.children}
    </div>
  );
};

export default EnergyContent;
