import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

export interface PageSearchParams extends PageRequest {
  typeIds?: Key[];
  name?: string;
}

export interface ReportsUploadListRes {
  id: Key;
  name: string; // 报告名称
  typeId: Key; // 报告类型id
  typeName: string; // 报告类型名称
  tenantMcid: string; // 所属租户
  tenantName: string; // 所属租户名称
  resource: string; // 附件阿里云key
  coverPicResource: string;
  reportTime: string; // 报告时间
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
}

export function getAnalysisReportPage(query: PageSearchParams): Promise<PageResponse<ReportsUploadListRes>> {
  return fetch(
    `/api/v2/analysis-report/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface ReportTypeDetail {
  id: Key;
  name: string; // 报告类型名称
  tenantMcid: string; // 所属租户
  tenantName: string; // 所属租户名称
  coverPicResource: string; // 封面图阿里云key
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
}

// 获取报告类型列表
export function getAnalysisReportTypeList(query: { tenantMcid?: string }) {
  return fetch<{ list: ReportTypeDetail[] }>(
    `/api/v2/analysis-report/type/list`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  ).then(res => res.list);
}
