import {
  EllipsisSpan,
  Paging,
  Table,
  usePaging,
  useUpdate,
  Button,
  Form,
  Modal,
  Select,
  Input,
  DatePicker,
  Radio,
  InputNumber,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, Key, useEffect, useState } from 'react';
import {
  apiV2ElectricityContractPagePost,
  V2ElectricityContractPagePostRequest,
  V2ElectricityContractPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { TransactionMeansType, TransactionMeansTypeDisplay } from '@/shared/types';
import {
  adjustEffectiveDate,
  createElectricityContract,
  ElectricityAccountVo,
  ElectricityContractRequest,
  ElectricityContractStatus,
  getElectricityAccounList,
  invalidElectricityContract,
  updateElectricityContract,
} from '../../../api/electricity';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';

const formatString = 'YYYY-MM-DD';

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const columns = [
  {
    title: '合同编号',
    dataIndex: 'number',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '签订对象',
    dataIndex: 'contractee',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '录入日期',
    dataIndex: 'createTime',
    width: 150,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(formatString)} />,
  },
  {
    title: '交易方式',
    dataIndex: 'transactionMeans',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: TransactionMeansType) => <EllipsisSpan value={TransactionMeansTypeDisplay[v]} />,
  },
  {
    title: '生效日期',
    dataIndex: 'startDate',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string, record: ElectricityContractItem) => (
      <EllipsisSpan value={`${dayjs(v).format(formatString)} ~ ${dayjs(record.endDate).format(formatString)}`} />
    ),
  },
  {
    title: '关联户号',
    dataIndex: 'electricityAccountName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '创建人',
    dataIndex: 'updateByUsername',
    width: 150,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    ellipsis: { showTitle: true },
    render: (v: ElectricityContractStatus, record: ElectricityContractItem) => {
      const currentTime = dayjs();
      const statusText =
        v === ElectricityContractStatus.INVALID
          ? '作废'
          : currentTime.isBefore(dayjs(record.startDate).startOf('date'))
          ? '待生效'
          : currentTime.isAfter(dayjs(record.endDate).endOf('date'))
          ? '已失效'
          : '生效中';
      return <EllipsisSpan value={statusText} />;
    },
  },
];

export interface SearchParams extends Omit<V2ElectricityContractPagePostRequest, 'page' | 'size'> {}
export type ElectricityContractItem = Exclude<V2ElectricityContractPagePostResponse['list'], undefined>[number];

const ElectricityContract: FC = () => {
  const hasContractAdd = useHasPermission(PermissionsType.B_CONTRACT_ADD);
  const hasContractEdit = useHasPermission(PermissionsType.B_CONTRACT_EDIT);
  const hasContractVoid = useHasPermission(PermissionsType.B_CONTRACT_VOID);
  const hasContractDateEdit = useHasPermission(PermissionsType.B_CONTRACT_DATE_EDIT);
  const hasPriceRateEdit = useHasPermission(PermissionsType.B_PRICE_RATE_EDIT);

  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [adjustRecordForm] = Form.useForm();
  const [updateState, updateFn] = useUpdate();

  const [electricityAccounds, setElectricityAccounds] = useState<ElectricityAccountVo[]>([]);

  useEffect(() => {
    getElectricityAccounList().then(res => setElectricityAccounds(res.list || []));
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ElectricityContractItem[]>([]);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();

  useEffect(() => {
    setLoading(true);
    apiV2ElectricityContractPagePost({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      setData(res.list || []);
      setTotalCount(res.total!);
    });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (val: Omit<SearchParams, 'createDateStart' | 'createDateEnd'> & { createDate: RangeValue }) => {
    const createDateStart =
      val.createDate === undefined || val.createDate === null ? undefined : val.createDate[0]?.format(formatString);
    const createDateEnd =
      val.createDate === undefined || val.createDate === null ? undefined : val.createDate[1]?.format(formatString);
    const params = {
      number: val.number,
      contractee: val.contractee,
      createDateStart,
      createDateEnd,
      transactionMeans: val.transactionMeans,
      electricityAccountId: val.electricityAccountId,
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const onReset = () => {
    const params = {
      number: undefined,
      contractee: undefined,
      createDateStart: undefined,
      createDateEnd: undefined,
      transactionMeans: undefined,
      electricityAccountId: undefined,
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const invalidContract = (v: ElectricityContractItem) => {
    if (v.hasRate) {
      Modal.confirm({
        title: '确认作废后将同步作废对应费率',
        okText: '确认作废',
        onOk() {
          invalidElectricityContract(v.id as Key).then(() => {
            updateFn();
          });
        },
      });
    } else {
      invalidElectricityContract(v.id as Key).then(() => {
        updateFn();
      });
    }
  };

  const [disabled, setDisabled] = useState<boolean>(false);

  const [current, setCurrent] = useState<Partial<ElectricityContractItem>>();

  const [adjustRecord, setAdjustRecord] = useState<ElectricityContractItem>();

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 320,
      render: (record: ElectricityContractItem) => (
        <Space size={16}>
          {hasContractEdit && (
            <Button
              disabled={
                !record.editable ||
                record.status === ElectricityContractStatus.INVALID ||
                !dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date'))
              }
              type="link"
              onClick={() => {
                setCurrent(record);
              }}
            >
              编辑
            </Button>
          )}
          <Button
            type="link"
            onClick={() => {
              setDisabled(true);
              setCurrent(record);
            }}
          >
            查看
          </Button>
          {hasContractVoid && (
            <Button
              disabled={
                record.status === ElectricityContractStatus.INVALID ||
                !dayjs().isSameOrBefore(dayjs(record.startDate).startOf('date'))
              }
              type="link"
              onClick={() => {
                invalidContract(record);
              }}
            >
              作废
            </Button>
          )}
          {hasContractDateEdit && (
            <Button
              disabled={!record.editable || record.status === ElectricityContractStatus.INVALID}
              type="link"
              onClick={() => {
                setAdjustRecord(record);
              }}
            >
              调整生效日期
            </Button>
          )}
          {hasPriceRateEdit && (
            <Button
              disabled={
                !record.editable ||
                record.status === ElectricityContractStatus.INVALID ||
                !dayjs().isSameOrBefore(dayjs(record.endDate).endOf('date'))
              }
              type="link"
            >
              <Link to={`/operation/energy/contract/electric-rate/edit/${record.id}`}>编辑费率</Link>
            </Button>
          )}
          <Button type="link">
            <Link to={`/operation/energy/contract/electric-rate/detail/${record.id}`}>查看费率</Link>
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (current === undefined || current?.id === undefined) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        number: current.number,
        contractee: current.contractee,
        electricityAccountId: current.electricityAccountId,
        transactionMeans: current.transactionMeans,
        precision: current.precision ?? 4,
        time: [
          current.startDate ? dayjs(current.startDate) : undefined,
          current.endDate ? dayjs(current.endDate) : undefined,
        ],
      });
    }
  }, [current, form]);

  const onAdd = () => {
    setCurrent({});
  };

  const onClose = () => {
    setDisabled(false);
    setCurrent(undefined);
  };

  const onSubmit = () => {
    if (disabled) {
      onClose();
      return;
    }
    form
      .validateFields()
      .then((values: Omit<ElectricityContractRequest, 'stateDate' | 'endDate'> & { time: RangeValue }) => {
        if (current?.id) {
          updateElectricityContract({
            id: current.id,
            number: values.number,
            contractee: values.contractee,
            precision: values.precision,
            electricityAccountId: values.electricityAccountId,
            transactionMeans: values.transactionMeans,
            startDate: values.time![0]!.format(formatString),
            endDate: values.time![1]!.format(formatString),
          }).then(() => {
            form.resetFields();
            updateFn();
            onClose();
          });
        } else {
          createElectricityContract({
            number: values.number,
            contractee: values.contractee,
            precision: values.precision,
            electricityAccountId: values.electricityAccountId,
            transactionMeans: values.transactionMeans,
            startDate: values.time![0]!.format(formatString),
            endDate: values.time![1]!.format(formatString),
          }).then(() => {
            form.resetFields();
            setPageOffset(1);
            updateFn();
            onClose();
          });
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const onAdjustCancel = () => {
    setAdjustRecord(undefined);
    adjustRecordForm.resetFields();
  };

  const onAdjustTimeSubmit = () => {
    adjustRecordForm.validateFields().then((values: { time: RangeValue }) => {
      adjustEffectiveDate({
        electricityContractId: adjustRecord?.id!,
        startDate: values.time![0]!.format(formatString),
        endDate: values.time![1]!.format(formatString),
      }).then(() => {
        onAdjustCancel();
        updateFn();
      });
    });
  };

  return (
    <>
      <div className={styles.filterWrapper}>
        <CustomFilter form={searchForm} collapseOpen={false} onFinish={(val: any) => onFinish(val)} onReset={onReset}>
          <Form.Item label="合同编号" name="number">
            <Input placeholder=" 请输入" />
          </Form.Item>
          <Form.Item label="签订对象" name="contractee">
            <Input placeholder=" 请输入" />
          </Form.Item>
          <Form.Item label="录入日期" name="createDate">
            <DatePicker.RangePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="交易方式" name="transactionMeans">
            <Select placeholder="请选择">
              <Select.Option value={TransactionMeansType.STATEGRID}>
                {TransactionMeansTypeDisplay[TransactionMeansType.STATEGRID]}
              </Select.Option>
              <Select.Option value={TransactionMeansType.PROXY}>
                {TransactionMeansTypeDisplay[TransactionMeansType.PROXY]}
              </Select.Option>
              <Select.Option value={TransactionMeansType.DIRECT}>
                {TransactionMeansTypeDisplay[TransactionMeansType.DIRECT]}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="electricityAccountId" label="关联户号">
            <Select placeholder="请选择">
              {electricityAccounds.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />
      <div className={styles.content}>
        {hasContractAdd && (
          <div className={styles.operationArea}>
            <Button type="primary" onClick={onAdd} icon={<PlusOutlined />}>
              新增合同
            </Button>
          </div>
        )}
        <Table rowKey="id" sticky scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={data} />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <Modal
        title={` ${current && current.id ? (disabled ? '查看' : '编辑') : '新增'}合同`}
        size="normal"
        open={!!current}
        contentClassName="modal-form-content"
        onCancel={onClose}
        onOk={onSubmit}
      >
        <Form form={form}>
          <Form.Item
            name="number"
            label="合同编号"
            rules={[{ required: true, message: '请输入合同编号', whitespace: true, type: 'string' }]}
          >
            <Input disabled={disabled} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="electricityAccountId" label="关联户号" rules={[{ required: true, message: '请选择户号' }]}>
            <Select disabled={disabled || current?.hasRate} placeholder="请选择">
              {electricityAccounds.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.number}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="contractee"
            label="签订对象"
            rules={[
              { required: true, message: '请输入签订对象', whitespace: true, type: 'string' },
              { max: 1000, message: '长度最多为1000字' },
            ]}
          >
            <Input disabled={disabled || current?.hasRate} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="transactionMeans" label="交易方式" rules={[{ required: true, message: '请选择交易方式' }]}>
            <Radio.Group disabled={disabled || current?.hasRate}>
              <Radio value={TransactionMeansType.STATEGRID}>
                {TransactionMeansTypeDisplay[TransactionMeansType.STATEGRID]}
              </Radio>
              <Radio value={TransactionMeansType.PROXY}>
                {TransactionMeansTypeDisplay[TransactionMeansType.PROXY]}
              </Radio>
              <Radio value={TransactionMeansType.DIRECT}>
                {TransactionMeansTypeDisplay[TransactionMeansType.DIRECT]}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="precision"
            label="电价精确位数"
            rules={[{ required: true, message: '请输入电价精确位数' }]}
            extra={disabled ? '' : current?.hasRate ? '关联费率后，仅允许修改为更高的位数' : '用于控制电价小数点后位数'}
            initialValue={4}
          >
            <InputNumber
              min={current?.hasRate ? current.precision : 0}
              max={100}
              disabled={disabled}
              placeholder="请输入"
            />
          </Form.Item>
          <Form.Item
            name="time"
            label="合同生效日期"
            rules={[{ required: true, message: '请选择合同生效日期' }]}
            hidden={current?.id !== undefined && !disabled}
          >
            <DatePicker.RangePicker disabled={disabled || !!current?.id} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="调整生效日期"
        contentClassName="modal-form-content"
        open={!!adjustRecord}
        onCancel={onAdjustCancel}
        onOk={onAdjustTimeSubmit}
      >
        <Form form={adjustRecordForm}>
          <Form.Item name="test" label="原生效日期">
            {`${dayjs(adjustRecord?.startDate!).format(formatString)} ~ ${dayjs(adjustRecord?.endDate!).format(
              formatString
            )}`}
          </Form.Item>
          <Form.Item name="time" label="更改为" rules={[{ required: true, message: '请选择更改时间' }]}>
            <DatePicker.RangePicker />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ElectricityContract;
