import { CircuitDetailProps, getCircuitDetail } from '@/api/circuit';
import { Button, Form, SubContent, FormTitle, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BasicInfoForm from './components/BasicInfoForm';
import CircuitRelationForm from './components/CircuitRelationForm';
import DeviceListForm from './components/DeviceListForm';

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const routes = [
  {
    name: `回路详情`,
  },
];

const CircuitDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [data, setData] = useState<CircuitDetailProps>();

  useEffect(() => {
    if (id) {
      getCircuitDetail(id).then(setData);
    }
  }, [id]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
      <FormTitle title={routes[0].name} />
      <Form layout="vertical" {...formLayout}>
        <SubContent title="基本信息" className="mb-8">
          <BasicInfoForm data={data} />
        </SubContent>
        <SubContent title="设备绑定">
          <Form.Item name="devices" wrapperCol={{ span: 24 }}>
            <DeviceListForm data={data} />
          </Form.Item>
        </SubContent>
        <SubContent title="回路关系关联" style={{ marginBottom: 8 }}>
          <CircuitRelationForm data={data} />
        </SubContent>
        <Space className="sticky-footer" size={8} style={{ height: 'auto', paddingLeft: 32 }}>
          <Button type="primary">
            <Link to={`/dc/circuit/circuitManage/edit/${data?.id}`}>编辑</Link>
          </Button>
          <Button
            onClick={() => {
              navigate(`/dc/circuit/circuitManage`);
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CircuitDetail;
