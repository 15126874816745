import {
  Button,
  EllipsisSpan,
  MultiSelect,
  Paging,
  Table,
  useAsync,
  useSearchParams,
  useUpdate,
  Form,
  Input,
  Tag,
  PopConfirm,
  CustomFilter,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import CenterModalOp, { ProductionBaseList } from './CenterModalOp';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { WorkStationState } from '@/api/workstationInfo';
import { PlusOutlined } from '@ant-design/icons';
import { getOuListOwned } from '@/api/ou';
import { useNavigate } from 'react-router';
import {
  V2WorkCenterPagePostRequest,
  V2WorkCenterPagePostResponse,
  apiV2ProductionBaseListByOuPost,
  apiV2WorkCenterDeletePost,
  apiV2WorkCenterOperateStatePost,
  apiV2WorkCenterPagePost,
} from '@maxtropy/device-customer-apis-v2';

const columns = [
  {
    title: '工作中心编码',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工作中心名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: number) => (
      <EllipsisSpan
        value={
          v === WorkStationState.OPEN ? (
            <Tag border="solid" color="success">
              启用
            </Tag>
          ) : (
            <Tag border="solid" color="default">
              禁用
            </Tag>
          )
        }
      />
    ),
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouNames',
    ellipsis: { showTitle: true },
    render: (v: string[]) => <EllipsisSpan value={(v ?? []).join('，')} />,
  },
  {
    title: '所属生产基地',
    dataIndex: 'productionBaseName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

type SearchParamsProps = Omit<V2WorkCenterPagePostRequest, 'page' | 'size'>;
export type TableRow = Exclude<V2WorkCenterPagePostResponse['list'], undefined>[number];

export const statusList = [
  {
    label: '启用',
    value: WorkStationState.OPEN,
  },
  {
    label: '禁用',
    value: WorkStationState.CLOSE,
  },
];

const WorkCenterInfo: React.FC = () => {
  const [form] = Form.useForm();
  const [centerModalVisible, setCenterModalVisible] = useState(false);
  const [opType, setOpType] = useState('add');
  const [currentRow, setCurrentRow] = useState<TableRow>();
  const [update, setUpdate] = useUpdate();
  const hasEditPers = useHasPermission(PermissionsType.B_EDIT_WORKCENTER_INFO);
  const hasViewPers = useHasPermission(PermissionsType.B_VIEW_WORKCENTER_INFO);
  const hasControlPers = useHasPermission(PermissionsType.B_CONTROL_WORKCENTER_INFO);
  const hasDropPers = useHasPermission(PermissionsType.B_DROP_WORKCENTER_INFO);
  const hasAddPers = useHasPermission(PermissionsType.B_ADD_WORKCENTER_INFO);
  const navigate = useNavigate();
  const pageInfo = useSearchParams<SearchParamsProps>(20, {
    state: [WorkStationState.OPEN],
  });
  const { searchParams, reset, finish, setTotalCount } = pageInfo;
  const [productionBaseList, setProductionBaseList] = useState<ProductionBaseList[]>([]);

  const { data: pageList, loading } = useRequest(
    () => {
      return apiV2WorkCenterPagePost({
        ...searchParams,
        page: searchParams.pageOffset,
        size: searchParams.pageSize,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [searchParams, update],
    }
  );

  // 获取生产基地
  useEffect(() => {
    apiV2ProductionBaseListByOuPost().then(res => {
      setProductionBaseList(res?.list ?? []);
    });
  }, []);

  // 生产基地
  const productionBaseOptions = useMemo(() => {
    return (productionBaseList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [productionBaseList]);

  const ouList = useAsync(getOuListOwned, []);

  const ouOptions = useMemo(() => {
    if (ouList && ouList.length !== 0) {
      return ouList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ouList]);

  // 启用/禁用
  const enableBtn = (status: WorkStationState, id?: number) => {
    if (!id) return;
    apiV2WorkCenterOperateStatePost({
      id,
      state: status,
    }).then(setUpdate);
  };

  // 废弃
  const dropBtn = (id?: number) => {
    if (!id) return;
    apiV2WorkCenterDeletePost({
      id,
    }).then(setUpdate);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      render: (_: any, record: TableRow) => {
        return (
          <Space size={16}>
            {hasViewPers && (
              <Button type="link" onClick={() => viewBtn(record)}>
                查看
              </Button>
            )}

            {hasEditPers && (
              <Button type="link" onClick={() => editBtn(record)}>
                编辑
              </Button>
            )}

            {record.state === WorkStationState.CLOSE && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.OPEN, record.id)}>
                启用
              </Button>
            )}
            {record.state === WorkStationState.OPEN && hasControlPers && (
              <Button type="link" onClick={() => enableBtn(WorkStationState.CLOSE, record.id)}>
                禁用
              </Button>
            )}
            {hasDropPers && (
              <PopConfirm
                title="废弃后不可再恢复，请确认。"
                onConfirm={() => {
                  dropBtn(record.id);
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button disabled={record.isUse} type="link">
                  废弃
                </Button>
              </PopConfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (v: any) => {
    finish(v);
  };

  const onReset = () => {
    reset({
      state: [WorkStationState.OPEN],
    });
  };

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      initialValues={{
        state: [WorkStationState.OPEN],
      }}
    >
      <Form.Item name="codeOrName" label="工作中心编码/名称">
        <Input placeholder="请输入" />
      </Form.Item>
      <Form.Item name="state" label="工作中心状态">
        <MultiSelect options={statusList} />
      </Form.Item>
      <Form.Item name="ouIds" label="所属运营单元">
        <MultiSelect options={ouOptions} />
      </Form.Item>
      <Form.Item name="productionBaseIds" label="所属生产基地">
        <MultiSelect options={productionBaseOptions} />
      </Form.Item>
    </CustomFilter>
  );

  const addBtn = () => {
    setCurrentRow(undefined);
    setOpType('add');
    setCenterModalVisible(true);
  };

  const editBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('edit');
    setCenterModalVisible(true);
  };

  const viewBtn = (row?: TableRow) => {
    setCurrentRow(row);
    setOpType('view');
    setCenterModalVisible(true);
  };

  return (
    <>
      <div className={styles.filterWrapper}>{filters}</div>
      <div className={styles.spaceStyle} />
      <div className={styles.filterWrapper}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15, marginBottom: 10 }}>
          {hasAddPers && (
            <Button type="primary" onClick={addBtn} icon={<PlusOutlined />}>
              新建工作中心
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate('/productionManagement/production/energyUnitMapping');
            }}
          >
            能耗单元映射
          </Button>
        </div>
        <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={pageList} scroll={{ x: 1300 }} />
        <Paging pagingInfo={pageInfo} />
      </div>

      {centerModalVisible && (
        <CenterModalOp
          type={opType}
          data={currentRow}
          confirm={() => {
            if (opType === 'add') {
              onReset();
            } else {
              setUpdate();
            }
            setCenterModalVisible(false);
          }}
          cancel={() => setCenterModalVisible(false)}
        />
      )}
    </>
  );
};
export default WorkCenterInfo;
