import { Input, InputNumber, Modal, Select, Form } from '@maxtropy/components';
import {
  apiV2CarbonFootprintLcaAddSubPost,
  apiV2CarbonFootprintLcaUpdateSubPost,
  apiV2MaterialListPost,
} from '@maxtropy/device-customer-apis-v2';
import { Col, Row, Spin } from 'antd';
import { debounce, isNil } from 'lodash-es';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MainDataSourcesTypeOptions } from '../../utils';
import styles from './index.module.scss';
import { ProcedureDtoType, ProductDtoType } from '../LifeCycleModel/const';
import { useUnit } from '@/pages/OutputConfig/Bom/hook';

interface Props {
  open: boolean;
  basicInfo?: ProcedureDtoType;
  setOpen: (open: boolean) => void;
  updateData?: () => void;
  productInfo?: ProductDtoType;
}

type OptionItem = { label: string; value: number; unitCode: string; unitTypeCode: number };

const ByProductModal: React.FC<Props> = ({ open, setOpen, basicInfo, productInfo, updateData }) => {
  const [form] = Form.useForm();
  const [materialList, setMaterialList] = useState<OptionItem[]>([]);
  const [getUnitName] = useUnit();
  const materialId = Form.useWatch('materialId', form);

  useEffect(() => {
    if (isNil(productInfo)) return;
    const unitName = getUnitName(productInfo?.unitTypeCode, productInfo?.unitCode);
    form.setFieldsValue({
      quantity: productInfo?.quantity,
      dataOrigin: productInfo?.dataOrigin,
      remark: productInfo?.remark,
      unit: unitName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo, getUnitName]);

  useEffect(() => {
    if (isNil(materialId)) return;
    const material = materialList.find(k => k.value === materialId);
    if (material?.unitCode && !isNil(material?.unitTypeCode)) {
      const unitName = getUnitName(material?.unitTypeCode, material?.unitCode);
      form.setFieldValue('unit', unitName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialId]);

  const onFinish = (v: any) => {
    if (isNil(productInfo)) {
      apiV2CarbonFootprintLcaAddSubPost({
        procedureId: basicInfo?.id,
        ...v,
      }).then(res => {
        setOpen(false);
        updateData?.();
      });
    } else {
      apiV2CarbonFootprintLcaUpdateSubPost({
        procedureId: basicInfo?.id,
        id: productInfo?.id,
        ...v,
      }).then(res => {
        setOpen(false);
        updateData?.();
      });
    }
  };

  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  useEffect(() => {
    apiV2MaterialListPost({ codeOrNameOrSpec: '' }).then(res => {
      const newOptions = (res?.list ?? []).map(k => ({
        label: `${k.code}/${k.name}/${k.spec}`,
        value: k.id!,
        unitCode: k.unitCode!,
        unitTypeCode: k.unitTypeCode!,
      }));
      setMaterialList(newOptions);
    });
  }, []);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setMaterialList([]);
      setFetching(true);

      apiV2MaterialListPost({ codeOrNameOrSpec: value }).then(res => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const newOptions = (res?.list ?? []).map(k => ({
          label: `${k.code ?? '--'}/${k.name ?? '--'}/${k.spec ?? '--'}`,
          value: k.id!,
          unitCode: k.unitCode!,
          unitTypeCode: k.unitTypeCode!,
        }));
        setMaterialList(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, 500);
  }, []);

  return (
    <Modal
      contentClassName="modal-form-content"
      title="添加副产品"
      className={styles.productModal}
      open={open}
      onOk={() => form.submit()}
      onCancel={() => setOpen(false)}
    >
      <Form form={form} onFinish={onFinish} labelCol={{ flex: '110px' }} labelAlign="left">
        <Form.Item label="过程名称" required>
          <div>{basicInfo?.name ?? '--'}</div>
        </Form.Item>
        {isNil(productInfo) ? (
          <Form.Item
            label={<div style={{ width: 100, whiteSpace: 'normal', height: 42 }}>物料编码/名称/规格型号</div>}
            name="materialId"
            rules={[
              {
                required: true,
                message: '请选择',
              },
            ]}
          >
            <Select
              onSearch={debounceFetcher}
              showSearch
              filterOption={false}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              placeholder="请选择"
              options={materialList}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={<div style={{ width: 100, whiteSpace: 'normal', height: 42 }}>物料编码/名称/规格型号</div>}
            required
          >
            <Input value={productInfo?.name} disabled />
          </Form.Item>
        )}

        <Form.Item label="数量" required style={{ marginBottom: 0 }}>
          <Row gutter={10}>
            <Col span={18}>
              <Form.Item noStyle name="quantity">
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="unit">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="主要数据来源"
          name="dataOrigin"
          rules={[
            {
              required: true,
              message: '请选择',
            },
          ]}
          style={{ marginBottom: 24 }}
        >
          <Select placeholder="请选择" options={MainDataSourcesTypeOptions} />
        </Form.Item>
        <Form.Item label="数据说明" name="remark">
          <Input.TextArea maxLength={200} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ByProductModal;
