import React from 'react';
import styles from '../MultiModalSelect/index.module.scss';
import DeviceTags from './index';

interface TagDisplayFormProps {
  value?: Array<string> | undefined;
}

const DeviceTagsDisplayForm: React.FC<TagDisplayFormProps> = props => {
  const tags = props.value ?? [];

  return <div className={styles.value}>{tags?.length ? <DeviceTags {...props} disabled /> : '--'}</div>;
};

export default DeviceTagsDisplayForm;
