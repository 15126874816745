import { Button, Radio } from '@maxtropy/components';
import { useState } from 'react';
import styles from './index.module.scss';
import CopyMeterTable from './components/CopyMeterTable';
import AfterAdjustTable from './components/AfterAdjustTable';
import FinalTable from './components/FinalTable';

enum RadioType {
  METER = 1,
  AFTER_ADJUST = 2,
  FINAL = 3,
}

const TotalTimeShareEleTable: React.FC = () => {
  const [radioValue, setRadioValue] = useState<RadioType>(RadioType.METER);
  const [isAllExpand, setIsAllExpand] = useState<boolean>(false); // 是否全部展开

  return (
    <>
      <p className={styles.title}>分时电量抄表</p>
      <Radio.Group
        value={radioValue}
        optionType="button"
        buttonStyle="solid"
        onChange={e => {
          setRadioValue(e.target.value);
        }}
      >
        <Radio value={RadioType.METER}>抄表值</Radio>
        <Radio value={RadioType.AFTER_ADJUST}>调整后值</Radio>
        <Radio value={RadioType.FINAL}>最终值</Radio>
      </Radio.Group>

      <Button
        type="link"
        onClick={() => {
          setIsAllExpand(!isAllExpand);
        }}
      >
        {isAllExpand ? '全部收起' : '全部展开'}
      </Button>
      {radioValue === RadioType.METER && <CopyMeterTable isAllExpand={isAllExpand} setIsAllExpand={setIsAllExpand} />}
      {radioValue === RadioType.AFTER_ADJUST && (
        <AfterAdjustTable isAllExpand={isAllExpand} setIsAllExpand={setIsAllExpand} />
      )}
      {radioValue === RadioType.FINAL && <FinalTable isAllExpand={isAllExpand} setIsAllExpand={setIsAllExpand} />}
    </>
  );
};
export default TotalTimeShareEleTable;
