import {
  SubContent,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Form,
  Modal,
  DatePicker,
  Radio,
  Select,
  InputNumber,
  FormTitle,
  Tooltip,
} from '@maxtropy/components';
import { Col, Row, Space, Spin, Result } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import {
  BillStatusEnum,
  getElectricityBillInfo,
  ManualCreateBill,
  ManualCreateBillRequest,
  PowerFactorRatioAdjustmentType,
} from '../../../api/electricity';
import {
  ElectricitySaleContractSettlementType,
  getElectricitySettlementRulesById,
  HasTimeOfUseElectricityPricePropsType,
  SaleElectricitySettlementRuleListByConIdRes,
} from '../../../api/electricitySettlementRules';
import SelectContract from '../components/SelectContract';
import CusSettlementRule from './components/CusSettlementRule';
import styles from './index.module.scss';
import { units } from '../components/utils';
import { getElectricitySaleContractById } from '../../../api/electricitySalesContract';

const { RangePicker } = DatePicker;

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const DATE_FORMAT = 'YYYY-MM-DD';

interface FormProps {
  deductPhotovoltaicMeters?: any[];
  lightingTariffRatio: number | undefined;
  hasLightingTariff?: boolean;
  unitLightingTariffRate?: number;
  customerMcid?: string; // 关联组织
  // propertyManagementConfigId?: number; // 所属物业
  // name: string; // 结算规则名称
  description: string; // 描述
  // effectiveTime?: [Dayjs, Dayjs]; // 生效区间
  saleElectricityContractId: number; // 所属合同
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  rateType?: number; // 电度电价类型
  standingRate?: number; // 电度单价
  timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 时段选择
  summitRate?: number; // 尖
  peakRate?: number; // 峰
  plainRate?: number; // 平
  valleyRate?: number; // 谷
  declarationType?: number; // 用电类型
  transformerCapacity?: number; // 变压器容量
  unitCapacityRate?: number; // 容量单价
  declaredDemand?: number; // 申报需量
  unitDeclaredDemandRate?: number; // 申报需量单价
  unitDemandRate?: number; // 需量单价
  hasCapitalContributionRate?: boolean; // 是否有公摊系数
  hasDiscountedRate?: number; // 是否有优惠金额
  discountedType?: number; // 优惠方式
  totalRateDiscountedRatio?: number; // 电度电费总折扣比例
  totalRateDiscountedRate?: number; // 电度电费总优惠金额
  unitRateDiscountedRate?: number; // 电度电费单价优惠金额
  electricityMeters?: any[]; // 电量计量表计
  demandMeters?: any[]; // 需量计量表计
  photovoltaicMeters?: any[]; // 光伏计量表计
  subMeters?: any[]; // 子表计量表计
  totalRateDiscountedQuantity?: number; // 电度电费总优惠量
  hasSubMeterRateDeduct?: boolean; // 是否有子表扣除
  subMeterUnitDemandRate?: number; // 子表需量单价
  subMeterSummitRate?: number; // 子表尖时电价
  subMeterPeakRate?: number; //	子表峰时电价
  subMeterPlainRate?: number; // 子表平时电价
  subMeterValleyRate?: number; //子表谷时电价

  settlementCycle: [Dayjs, Dayjs]; // 结算周期
  saleElectricitySettlementRuleId: number; // 结算规则id
  billDate: string; // 账期月份
  settlementRules: boolean; // 是否自定义结算规则
  settlementPeriod: string; // 账期
  selectContract: any; // 合同id
  chooseSettlementRules?: number; // 规则id
  hasDemandAdjust: boolean; // 是否进行需量调整
  expectedDemand?: number; // 需量调整数
  powerFactorRatioAdjustmentType: PowerFactorRatioAdjustmentType; // 力调系数调整类型
  reactivePower?: number; // 无功电量
  powerFactorRatioCalculationType?: number; //功率因数比值计算规则
  discardFromBill: number;
}

const formatPostRequest = (formValues: FormProps): ManualCreateBillRequest | undefined => {
  let params = {
    discardFromBill: formValues.discardFromBill,
    saleElectricityContractId: formValues.selectContract[0].id,
    isCustomizeRule: formValues.settlementRules,
    saleElectricitySettlementRuleId: formValues.chooseSettlementRules,
    billDate: dayjs(formValues.settlementPeriod).format('YYYY-MM'),
    startTime:
      Object.prototype.toString.call(formValues.settlementCycle) === '[object Array]'
        ? formValues.settlementCycle![0].format(DATE_FORMAT)
        : undefined,
    endTime:
      Object.prototype.toString.call(formValues.settlementCycle) === '[object Array]'
        ? formValues.settlementCycle![1].format(DATE_FORMAT)
        : undefined,
    hasDemandAdjust: formValues.hasDemandAdjust,
    expectedDemand: formValues.expectedDemand,
    powerFactorRatioAdjustmentType: formValues.powerFactorRatioAdjustmentType,
    reactivePower: formValues.reactivePower,
  };
  let saveMeterList = [
    ...(formValues.electricityMeters ?? []),
    ...(formValues.demandMeters ?? []),
    ...(formValues.photovoltaicMeters ?? []),
    ...(formValues.deductPhotovoltaicMeters ?? []),
    ...(formValues.subMeters ?? []),
  ];
  let meters = saveMeterList.map(item => {
    return {
      saleElectricityContractElectricityMeterId: item.saleElectricityContractElectricityMeterInfoId,
      saleElectricitySettlementRuleElectricityMeterId: item.saleElectricitySettlementRuleElectricityMeterInfoId,
    };
  });
  if (formValues.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
    return {
      ...params,
      saleElectricitySettlementRule: {
        onePartInfo: {
          timeOfDayRateChecked: formValues.timeOfDayRateChecked,
          rateType: formValues.rateType,
          standingRate: formValues.standingRate,
          summitRate: formValues.summitRate,
          peakRate: formValues.peakRate,
          plainRate: formValues.plainRate,
          valleyRate: formValues.valleyRate,
          hasCapitalContributionRate: formValues.hasCapitalContributionRate,
          hasDiscountedRate: formValues.hasDiscountedRate,
          discountedType: formValues.discountedType,
          totalRateDiscountedRate: formValues.totalRateDiscountedRate,
          unitRateDiscountedRate: formValues.unitRateDiscountedRate,
          totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
          totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
        },
        description: formValues.description,
        settlementType: formValues.settlementType,
        meters,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
    return {
      ...params,
      saleElectricitySettlementRule: {
        twoPartInfo: {
          timeOfDayRateChecked: formValues.timeOfDayRateChecked,
          declarationType: formValues.declarationType,
          rateType: formValues.rateType,
          transformerCapacity: formValues.transformerCapacity,
          unitCapacityRate: formValues.unitCapacityRate,
          declaredDemand: formValues.declaredDemand,
          unitDeclaredDemandRate: formValues.unitDeclaredDemandRate,
          unitDemandRate: formValues.unitDemandRate,
          standingRate: formValues.standingRate,
          summitRate: formValues.summitRate,
          peakRate: formValues.peakRate,
          plainRate: formValues.plainRate,
          valleyRate: formValues.valleyRate,
          hasLightingTariff: formValues.hasLightingTariff,
          lightingTariffRatio: formValues.lightingTariffRatio,
          unitLightingTariffRate: formValues.unitLightingTariffRate,
          hasDiscountedRate: formValues.hasDiscountedRate,
          discountedType: formValues.discountedType,
          totalRateDiscountedRate: formValues.totalRateDiscountedRate,
          unitRateDiscountedRate: formValues.unitRateDiscountedRate,
          totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
          hasSubMeterRateDeduct: formValues.hasSubMeterRateDeduct,
          subMeterUnitDemandRate: formValues.subMeterUnitDemandRate,
          subMeterSummitRate: formValues.subMeterSummitRate,
          subMeterPeakRate: formValues.subMeterPeakRate,
          subMeterPlainRate: formValues.subMeterPlainRate,
          subMeterValleyRate: formValues.subMeterValleyRate,
          totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
        },
        description: formValues.description,
        settlementType: formValues.settlementType,
        meters,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
    return {
      ...params,
      saleElectricitySettlementRule: {
        distributedNewEnergyInfo: {
          timeOfDayRateChecked: formValues.timeOfDayRateChecked,
          summitRate: formValues.summitRate,
          peakRate: formValues.peakRate,
          plainRate: formValues.plainRate,
          valleyRate: formValues.valleyRate,
          hasDiscountedRate: formValues.hasDiscountedRate,
          discountedType: formValues.discountedType,
          totalRateDiscountedRate: formValues.totalRateDiscountedRate,
          unitRateDiscountedRate: formValues.unitRateDiscountedRate,
          totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
          totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
        },
        description: formValues.description,
        settlementType: formValues.settlementType,
        meters,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
    return {
      ...params,
      saleElectricitySettlementRule: {
        distEnergyAllFeedInInfo: {
          timeOfDayRateChecked: formValues.timeOfDayRateChecked,
          summitRate: formValues.summitRate,
          peakRate: formValues.peakRate,
          plainRate: formValues.plainRate,
          valleyRate: formValues.valleyRate,
          hasDiscountedRate: formValues.hasDiscountedRate,
          discountedType: formValues.discountedType,
          totalRateDiscountedRate: formValues.totalRateDiscountedRate,
          unitRateDiscountedRate: formValues.unitRateDiscountedRate,
          totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
          totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
        },
        description: formValues.description,
        settlementType: formValues.settlementType,
        meters,
      },
    };
  } else if (formValues.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
    return {
      ...params,
      saleElectricitySettlementRule: {
        twoPartAndEnergyInfo: {
          timeOfDayRateChecked: formValues.timeOfDayRateChecked,
          declarationType: formValues.declarationType,
          rateType: formValues.rateType,
          transformerCapacity: formValues.transformerCapacity,
          unitCapacityRate: formValues.unitCapacityRate,
          declaredDemand: formValues.declaredDemand,
          unitDeclaredDemandRate: formValues.unitDeclaredDemandRate,
          unitDemandRate: formValues.unitDemandRate,
          standingRate: formValues.standingRate,
          summitRate: formValues.summitRate,
          peakRate: formValues.peakRate,
          plainRate: formValues.plainRate,
          valleyRate: formValues.valleyRate,
          hasLightingTariff: formValues.hasLightingTariff,
          lightingTariffRatio: formValues.lightingTariffRatio,
          unitLightingTariffRate: formValues.unitLightingTariffRate,
          hasDiscountedRate: formValues.hasDiscountedRate,
          discountedType: formValues.discountedType,
          totalRateDiscountedRate: formValues.totalRateDiscountedRate,
          unitRateDiscountedRate: formValues.unitRateDiscountedRate,
          totalRateDiscountedRatio: formValues.totalRateDiscountedRatio,
          totalRateDiscountedQuantity: formValues.totalRateDiscountedQuantity,
          powerFactorRatioCalculationType: formValues.powerFactorRatioCalculationType,
        },
        description: formValues.description,
        settlementType: formValues.settlementType,
        meters,
      },
    };
  } else {
    return {
      ...params,
    };
  }
};

const ManualCreateElectricityBill: React.FC = () => {
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [creatingVisible, setCreatingVisible] = useState<boolean>(false);
  const [successVisible, setSuccessVisible] = useState<boolean>(false);
  const chooseSettlementRules = Form.useWatch('chooseSettlementRules', form);
  const [settlementRuleList, setSettlementRuleList] = useState<SaleElectricitySettlementRuleListByConIdRes[]>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_electricityId = urlSearchParams.get('electricityId');

  const [electricityStatus, setElectricityStatus] = useState<BillStatusEnum>();

  const routes = useMemo(() => {
    return [{ name: '手动生成核查联' }];
  }, []);

  const currentSettlementRule = useMemo(() => {
    if (Array.isArray(settlementRuleList) && chooseSettlementRules) {
      return settlementRuleList.find(i => i.id === chooseSettlementRules);
    }
    return;
  }, [settlementRuleList, chooseSettlementRules]);

  const settlementRuleOptions = useMemo(() => {
    if (settlementRuleList?.length !== 0) {
      return settlementRuleList?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [settlementRuleList]);

  useEffect(() => {
    if (url_electricityId !== null) {
      getElectricityBillInfo(url_electricityId).then(res => {
        if (res) {
          setElectricityStatus(res.status);
          if (res.status === BillStatusEnum.RESCINDED) {
            form.setFieldsValue({
              discardFromBill: 0,
            });
          }
          let settlementPeriod = res.settlementPeriod.slice(0, 6).split('第').join('-');
          getElectricitySaleContractById(res.saleElectricityContractId).then(contract => {
            if (contract) {
              form.setFieldsValue({
                selectContract: [contract],
                settlementCycle: [dayjs(res.settlementStartTime), dayjs(res.settlementEndTime).subtract(1, 'day')],
                settlementPeriod: dayjs(settlementPeriod),
              });
              // 判断结算规则是否存在
              getElectricitySettlementRulesById(res.saleElectricitySettlementRuleId).then(settlementRule => {
                if (settlementRule) {
                  // 手动生成的核查联中自定义结算规则，不回显规则
                  if (settlementRule.name !== '【手动核查联规则】') {
                    form.setFieldsValue({
                      chooseSettlementRules: Number(res.saleElectricitySettlementRuleId),
                    });
                  } else {
                    form.setFieldsValue({
                      settlementRules: true,
                    });
                  }
                }
              });
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_electricityId]);

  const onFinish = (val: any) => {
    if (val.settlementType === ElectricitySaleContractSettlementType.UNITARYSYSTEM) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.TWOPARTSYSTEM) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.demandMeters?.length === 0 || !val.demandMeters) {
        Modal.error({
          title: '请添加需量计量表计',
        });
        return false;
      } else if (val.hasSubMeterRateDeduct === true) {
        if (val.subMeters?.length === 0 || !val.subMeters) {
          Modal.error({
            title: '请添加子表计量表计',
          });
          return false;
        }
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.photovoltaicMeters?.length === 0 || !val.photovoltaicMeters) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL) {
      let deductAndNoDeductPhotovoltaicMeters = [
        ...(val.deductPhotovoltaicMeters ?? []),
        ...(val.photovoltaicMeters ?? []),
      ];
      if (deductAndNoDeductPhotovoltaicMeters.length === 0) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    } else if (val.settlementType === ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY) {
      if (val.electricityMeters?.length === 0 || !val.electricityMeters) {
        Modal.error({
          title: '请添加电量计量表计',
        });
        return false;
      } else if (val.demandMeters?.length === 0 || !val.demandMeters) {
        Modal.error({
          title: '请添加需量计量表计',
        });
        return false;
      } else if (val.photovoltaicMeters?.length === 0 || !val.photovoltaicMeters) {
        Modal.error({
          title: '请添加光伏计量表计',
        });
        return false;
      }
    }

    // console.log(val)
    let params = {
      ...formatPostRequest(val),
    };
    if (url_electricityId) {
      params = {
        ...params,
        fromBillId: url_electricityId,
      };
    }
    // console.log(params);
    setCreatingVisible(true);
    ManualCreateBill(params as ManualCreateBillRequest)
      .then(() => {
        setCreatingVisible(false);
        setSuccessVisible(true);
      })
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setCreatingVisible(false);
      });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <Form
        layout="vertical"
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{
          settlementRules: false,
          hasDemandAdjust: false,
          powerFactorRatioAdjustmentType: PowerFactorRatioAdjustmentType.NO_ADJUST,
          powerFactorRatioCalculationType: 1,
          discardFromBill: 1,
        }}
        // onValuesChange={onFormValueChange}
      >
        <SubContent style={{ marginBottom: 8 }}>
          <Row>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="selectContract"
              rules={[{ required: true, message: '请选择合同' }]}
            >
              <SelectContract maxContract={1} setSettlementRuleList={setSettlementRuleList} />
            </Form.Item>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="结算规则" name="settlementRules" rules={[{ required: true, message: '请选择' }]}>
                <Radio.Group>
                  <Radio value={false}>选择合同中的结算规则</Radio>
                  <Radio value={true}>自定义</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const settlementRules = getFieldValue('settlementRules');
                if (!settlementRules) {
                  return (
                    <Col span={8}>
                      <Form.Item
                        label="规则选择"
                        required
                        extra={
                          currentSettlementRule && (
                            <p>
                              结算规则生效区间：
                              {`${dayjs(currentSettlementRule.startTime).format('YYYY-MM-DD')} ~ ${dayjs(
                                currentSettlementRule.endTime
                              ).format('YYYY-MM-DD')}`}
                            </p>
                          )
                        }
                      >
                        <Form.Item noStyle dependencies={['selectContract']}>
                          {({ getFieldValue }) => {
                            const hasContract = (getFieldValue('selectContract') ?? []).length > 0;
                            return (
                              <Form.Item
                                name="chooseSettlementRules"
                                rules={[{ required: true, message: '请选择' }]}
                                noStyle
                              >
                                <Select
                                  disabled={!hasContract}
                                  style={{ width: '70%' }}
                                  placeholder={hasContract ? '请选择结算规则' : '请先选择合同'}
                                  options={settlementRuleOptions}
                                  showSearch
                                  optionFilterProp="label"
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                        <Button type="link" disabled={!chooseSettlementRules}>
                          <Link
                            target="_blank"
                            to={`/operation/settlementRules/electricity/details/${chooseSettlementRules}`}
                          >
                            规则详情
                          </Link>
                        </Button>
                      </Form.Item>
                    </Col>
                  );
                }
              }}
            </Form.Item>
            <Col span={8}>
              <Form.Item noStyle dependencies={['selectContract']}>
                {({ getFieldValue }) => {
                  const hasContract = (getFieldValue('selectContract') ?? []).length > 0;
                  return (
                    <Form.Item
                      label={
                        <>
                          结算周期
                          <Tooltip title="注意：结算周期为核查联的账单起止日期，开始日期的0点到结束日期的23点59分59秒，例1: X月1日到X月31日，实际结算X月1日0点到X月31日23点59分59秒的数据。例2: X月25日到X月24日，实际结算X月25日0点到X月24日23点59分59秒的数据。">
                            <InfoCircleOutlined style={{ marginLeft: 4, color: 'var(--mx-warning-color)' }} />
                          </Tooltip>
                        </>
                      }
                      name="settlementCycle"
                      rules={[{ required: true, message: '请选择' }]}
                    >
                      <RangePicker
                        style={{ width: '70%' }}
                        disabled={!hasContract}
                        placeholder={
                          hasContract ? ['请选择结算周期', '请选择结算周期'] : ['请先选择合同', '请先选择合同']
                        }
                        disabledDate={date => {
                          const currentContract = form.getFieldValue('selectContract');
                          return (
                            date.isSameOrAfter(dayjs(currentContract.at(0).endTime).endOf('date')) ||
                            date.isBefore(dayjs(currentContract.at(0).startTime)) ||
                            date.isSameOrAfter(dayjs().subtract(1, 'day'))
                          );
                        }}
                        allowClear={false}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="账期月份" name="settlementPeriod" rules={[{ required: true, message: '请选择' }]}>
                <DatePicker
                  style={{ width: '70%' }}
                  picker="month"
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs().endOf('year')) || currentDate.isBefore(dayjs('1970-01-01'));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="是否进行需量调整"
                name="hasDemandAdjust"
                rules={[{ required: true, message: '请选择' }]}
              >
                <Radio.Group>
                  <Radio value={false}>否</Radio>
                  <Radio value={true}>是</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item noStyle dependencies={['hasDemandAdjust']}>
              {({ getFieldValue }) => {
                const hasDemandAdjust = getFieldValue('hasDemandAdjust');
                if (hasDemandAdjust) {
                  return (
                    <Col span={8}>
                      <Form.Item
                        label="需量示数调整为"
                        name="expectedDemand"
                        extra={<p>请输入需量示数，注意这里不是乘完倍率后的数值。</p>}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '70%' }}
                          min="0"
                          max="999999.999999"
                          // stringMode
                          step="0.000001"
                          precision={6}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
              }}
            </Form.Item>
            <Col span={8}>
              <Form.Item
                label="是否调整力调示数"
                name="powerFactorRatioAdjustmentType"
                rules={[{ required: true, message: '请选择' }]}
              >
                <Radio.Group>
                  <Radio value={PowerFactorRatioAdjustmentType.NO_ADJUST}>否</Radio>
                  <Radio value={PowerFactorRatioAdjustmentType.ADJUST}>是</Radio>
                  <Radio value={PowerFactorRatioAdjustmentType.NO_POWER_FACTOR}>免除力调费</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {url_electricityId && (
              <Col span={8}>
                <Form.Item
                  label={
                    <>
                      是否作废原核查联
                      <Tooltip title="注意：手动重新生成核查联时，若不作废原核查联，原核查联也会重新期间的计算暂估余额，暂估余额会计算两次，此时该账户下的暂估余额计算会产生误差。">
                        <InfoCircleOutlined style={{ marginLeft: 4, color: 'var(--mx-warning-color)' }} />
                      </Tooltip>
                    </>
                  }
                  name="discardFromBill"
                  rules={[{ required: true, message: '请选择' }]}
                >
                  <Radio.Group disabled={electricityStatus === BillStatusEnum.RESCINDED ? true : false}>
                    <Radio value={0}>否</Radio>
                    <Radio value={1}>是</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}

            <Form.Item noStyle dependencies={['powerFactorRatioAdjustmentType']}>
              {({ getFieldValue }) => {
                const powerFactorRatioAdjustmentType = getFieldValue('powerFactorRatioAdjustmentType');
                if (powerFactorRatioAdjustmentType === PowerFactorRatioAdjustmentType.ADJUST) {
                  return (
                    <Col span={8}>
                      <Form.Item
                        label="无功电量"
                        name="reactivePower"
                        extra={<p>请输入无功电量，需要输入乘完倍率后的数值。</p>}
                      >
                        <InputNumber
                          placeholder="请输入"
                          style={{ width: '70%' }}
                          min="0"
                          max="99999999"
                          // stringMode
                          step="0.0001"
                          precision={4}
                          addonAfter={units.千瓦时}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
              }}
            </Form.Item>
          </Row>
          <Form.Item noStyle dependencies={['settlementRules', 'selectContract']}>
            {({ getFieldValue }) => {
              const settlementRules = getFieldValue('settlementRules');
              if (settlementRules) {
                return (
                  <CusSettlementRule
                    isSelectContract={(getFieldValue('selectContract') ?? []).length > 0}
                    form={form}
                    contractId={form.getFieldValue('selectContract')}
                  />
                );
              }
            }}
          </Form.Item>
        </SubContent>

        <Space size={8} className="sticky-footer" style={{ height: 'auto' }}>
          <Button htmlType="submit" type="primary">
            生成
          </Button>
          <Button>
            <Link to={'/operation/settlementRules/electricityBill'}>返回</Link>
          </Button>
        </Space>
      </Form>
      <Modal width={480} open={creatingVisible} footer={null} closable={false} maskClosable={false} keyboard={false}>
        <Result
          icon={<Spin size="large" />}
          title={<span style={{ fontSize: 16 }}>正在生成中...</span>}
          subTitle={<span style={{ fontSize: 12 }}>请勿关闭本页。</span>}
        />
      </Modal>
      <Modal width={480} open={successVisible} footer={null} closable={false} maskClosable={false} keyboard={false}>
        <Result
          icon={
            <CheckCircleFilled
              style={{
                fontSize: 48,
                color: 'var(--success-color)',
              }}
            />
          }
          title={<span style={{ fontSize: 16 }}>生成成功！</span>}
          extra={
            <Button type="primary">
              <Link to={'/operation/settlementRules/electricityBill'}>返回核查联列表</Link>
            </Button>
          }
        />
      </Modal>
    </Wrapper>
  );
};

export default ManualCreateElectricityBill;
