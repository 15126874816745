import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  ComputationalLogic,
  computationalLogicDisplay,
  DataPointType,
  DataProperty,
  DataPropertyType,
} from '../../../../types';
import { Form } from 'antd';
import { useQuery } from '../../../../utils/utils';
import { getPhysicalUnits } from '../../../../api/options';
import { EdgeDeviceTemplatePoint } from './index';
import useIsGateway from '../../../../hooks/useIsGateway';
import { isEmpty, pick } from 'lodash-es';
import { PointContext } from '../../../EdgeDevicePointInfo/contextTypes';
import FormSubmit from '../../../EdgeDevicePointInfo/mockingbird/FormSubmit';
import { BatchEdgeContent } from '../../contentTypes';
import { PointOperateType } from '../../interface';
import { Input, Modal, Select } from '@maxtropy/components';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};

interface FormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties: number[];
  dataPropertiesAll: DataProperty[];
  usedComputationalLogic: number[];
}

const FormDialog: React.FC<FormDialogProps> = props => {
  const { row, onCancel, onOk, usedProperties, dataPropertiesAll, usedComputationalLogic } = props;
  const [form] = Form.useForm();
  const [dataPropertyId, setDataPropertyId] = useState<number>();
  const { setRow, info } = useContext(PointContext);
  const { isGateway } = useIsGateway(info?.deviceTypeId);
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType } = baseForm?.getFieldsValue();

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties.includes(item.id)),
    [dataPropertiesAll, usedProperties]
  );

  const computationalLogicOptions = useMemo(
    () =>
      Object.entries(
        isGateway
          ? pick(computationalLogicDisplay, [ComputationalLogic.POWER_SUPPLY])
          : pick(computationalLogicDisplay, [ComputationalLogic.CONNECTION])
      )
        .map(([key, value]) => ({ label: value, value: Number(key) }))
        .filter(item => !usedComputationalLogic.includes(item.value)),
    [usedComputationalLogic, isGateway]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units)) {
      form.setFieldsValue({
        physicalUnitId: units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName: units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units]);

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    if (shouldContinue) {
      onOk(values);
      form.resetFields();
      setRow?.({ pointType: DataPointType.STATIC_POINT });
    } else {
      onOk(values);
      onCancel();
    }
  };

  return (
    <Modal
      open={row?.pointType === DataPointType.STATIC_POINT}
      onCancel={onCancel}
      title={`${pointOperateType === PointOperateType.UPDATE ? '编辑' : '添加'}静态点`}
      centered={true}
      maskClosable={false}
      okText="确定"
      cancelText="取消"
      footer={<FormSubmit submit={submit} cancel={onCancel} />}
    >
      <Form form={form} initialValues={row} {...formLayout}>
        <Form.Item noStyle name="pointType" />
        <Form.Item noStyle name="dataPropertyName" />
        <Form.Item noStyle name="physicalUnitGeneralName" />

        <Form.Item name="dataPropertyId" label="数据属性" rules={[{ required: true, message: '请选择数据属性' }]}>
          <Select
            onChange={value => {
              form.setFieldsValue({
                dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
              });
              setDataPropertyId(value as number);
              form.setFieldsValue({
                physicalUnitId: undefined,
                physicalUnitGeneralName: '',
              });
            }}
            placeholder="请输入/选择"
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataProperties?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {dataPropertyType === DataPropertyType.YC && (
          <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
            <Select
              placeholder="请输入/选择"
              showSearch
              onSearch={value => units?.filter(item => item.generalName.includes(value))}
              onChange={value => {
                form.setFieldsValue({
                  physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                });
              }}
            >
              {units?.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.generalName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="computationalLogic" label="计算逻辑" rules={[{ required: true, message: '请选择计算逻辑' }]}>
          <Select placeholder="请输入" options={computationalLogicOptions} />
        </Form.Item>

        <Form.Item name="remark" label="备注">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FormDialog;
