import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  FormTitle,
  SubContent,
  Form,
  Input,
  Select,
  Modal,
  ShowInput,
} from '@maxtropy/components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from 'antd';

import styles from './index.module.scss';
import { getOuListMember } from '../../../api/alarm';

import { useNavigate, useParams } from 'react-router-dom';
import { PushCenterStaffLinkVoList, OutsiderResponse } from '../../../api/deviceAlarmPushStrategy';

import SelectPushStaff from '../../../components/SelectPushStaff';
import SelectPushOutSider from '../../../components/SelectPushOutsider';
import {
  createServerAlarmPushConfig,
  CreateServerAlarmPushConfigRequest,
  getServerAlarmConfigSceneType,
  getServerAlarmPushConfig,
  SceneTypeResponse,
  ServerAlarmPushConfigResponse,
  ServerAlarmPushConfigStaffsRequest,
  updateServerAlarmPushConfig,
  UpdateServerAlarmPushConfigRequest,
} from '../../../api/serverAlarm';
import SelectServerAlarmRules from '../components/SelectServerRule';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export interface FormDataProps extends Omit<ServerAlarmPushConfigResponse, 'pushStaffs' | 'pushExtContacts' | 'id'> {
  pushStaffs: PushCenterStaffLinkVoList[];
  pushExtContacts: OutsiderResponse[];
}

const CreateServerAlarmPushConfig: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [pushStaffs, setPushStaffs] = useState<PushCenterStaffLinkVoList[]>([]);
  const [pushContacts, setPushContacts] = useState<OutsiderResponse[]>([]);

  const [form] = Form.useForm();

  const [data, setData] = useState<ServerAlarmPushConfigResponse>();

  const [ouId, setOuId] = useState<number>();
  const [sceneType, setsceneType] = useState<number>();
  const [sceneTypes, setSceneTypes] = useState<SceneTypeResponse[]>([]);

  const ou = useAsync(getOuListMember, []);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getServerAlarmConfigSceneType().then(res => setSceneTypes(res.list || []));
  }, []);

  useEffect(() => {
    if (id) {
      getServerAlarmPushConfig(id).then(res => {
        setData(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const staffs = Array.from(
        (data.pushStaffs ?? [])
          .reduce((previousValue, currentValue) => {
            const value = previousValue.get(currentValue.staffId);
            if (value) {
              previousValue.set(value.staffId, {
                ...value,
                thirdPartyAppId: [...value.thirdPartyAppId, currentValue.pushChannel],
              });
            } else {
              previousValue.set(currentValue.staffId, {
                ...currentValue,
                thirdPartyAppId: [currentValue.pushChannel],
              });
            }
            return previousValue;
          }, new Map<number, PushCenterStaffLinkVoList>())
          .values()
      );
      const contacts: OutsiderResponse[] = (data.pushExtContacts ?? []).map(i => ({
        ...i,
        id: i.contactPhone,
        thirdPartyIdList: i.pushChannels ?? [],
      }));
      form.setFieldsValue({
        name: data?.name,
        ouId: data.ouName,
        sceneType: data.sceneType,
        pushStaffs: staffs,
        pushExtContacts: contacts,
        serverAlarmRules: data.serverAlarmRules,
      });
      setPushStaffs(staffs);
      setPushContacts(contacts);
      // setOuId(data.ouId);
      // setsceneType(data.sceneType)
    }
  }, [data, form]);

  useEffect(() => {
    if (sceneTypes && Array.isArray(sceneTypes)) {
      if (sceneTypes.length === 1) {
        const currentSceneType = sceneTypes[0].value;
        form.setFieldsValue({
          sceneType: currentSceneType,
        });
        setsceneType(currentSceneType);
      }
    }
  }, [sceneTypes, form]);

  useEffect(() => {
    if (sceneType) {
      form.setFieldsValue({
        serverAlarmRules: [],
      });
    }
  }, [sceneType, form]);

  useEffect(() => {
    if (ouId) {
      form.setFieldsValue({
        pushStaffs: [],
        serverAlarmRules: [],
      });
    }
  }, [ouId, form]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}服务端推送` }];
  }, [isEdit]);

  const goList = () => {
    navigate(`/device/alarm/serverPush`);
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const onFinish = (value: FormDataProps) => {
    const currentPushStaff = (value.pushStaffs ?? [])
      .map(i => {
        return (i.thirdPartyAppId ?? []).map(
          j =>
            ({
              staffId: i.staffId,
              pushChannel: j,
            } as ServerAlarmPushConfigStaffsRequest)
        );
      })
      .flat();
    const currentPushContacts = (value.pushExtContacts ?? []).map(i => ({
      contactName: i.contactName,
      contactPhone: i.contactPhone,
      pushChannels: i.thirdPartyIdList ?? [],
    }));
    if (isEdit && id) {
      const newValue: UpdateServerAlarmPushConfigRequest = {
        id: data!.id,
        name: value.name,
        sceneType: value.sceneType,
        serverAlarmRuleIds: (value.serverAlarmRules ?? []).map(i => i.id),
        pushStaffs: currentPushStaff.map(i => {
          const find = (data?.pushStaffs ?? []).find(j => i.staffId === j.staffId && i.pushChannel === j.pushChannel);
          return {
            ...i,
            serverAlarmPushConfigStaffId: find?.id ?? undefined,
          };
        }),
        pushExtContacts: currentPushContacts,
      };
      updateServerAlarmPushConfig(newValue).then(() => {
        goList();
      });
    } else {
      const newValue: CreateServerAlarmPushConfigRequest = {
        name: value.name,
        ouId: value.ouId,
        sceneType: value.sceneType,
        serverAlarmRuleIds: (value.serverAlarmRules ?? []).map(i => i.id),
        pushStaffs: currentPushStaff,
        pushExtContacts: currentPushContacts,
      };
      createServerAlarmPushConfig(newValue).then(() => {
        goList();
      });
    }
  };

  const pushStaffValidator = useCallback(
    (value: string[]) => {
      return pushContacts.map(i => i.contactPhone).some(i => value.includes(i));
    },
    [pushContacts]
  );

  const outSiderValidator = useCallback(
    (value: string[]) => {
      return pushStaffs.map(i => i.phone).some(i => value.includes(i));
    },
    [pushStaffs]
  );

  const ouOptions = useMemo(() => {
    if (ou.length !== 0) {
      return ou.map(i => ({ label: i.name, value: i.id }));
    }
  }, [ou]);

  const sceneTypesOptions = useMemo(() => {
    if (sceneTypes.length !== 0) {
      return sceneTypes.map(i => ({ label: i.name, value: i.value }));
    }
  }, [sceneTypes]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`${isEdit ? '编辑' : '新建'}服务端推送`} />
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="name"
                label="推送名称"
                rules={[
                  { required: true, message: '请输入推送名称' },
                  { max: 20, message: '最多输入二十个字' },
                ]}
              >
                <Input placeholder="请输入推送名称" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="ouId" label="运营单元" rules={[{ required: true, message: '请选择运营单元' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    onChange={(v: number) => {
                      setOuId(v);
                    }}
                    options={ouOptions}
                    showSearch
                    optionFilterProp="label"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="sceneType"
                label="报警规则场景"
                rules={[{ required: true, message: '请选择报警规则场景' }]}
              >
                {/* {
                  isEdit ? (
                    <ShowInput />
                  ) : ( */}
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  onChange={setsceneType}
                  options={sceneTypesOptions}
                />
                {/* )
                } */}
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="报警规则" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="serverAlarmRules" wrapperCol={{ span: 24 }}>
                <SelectServerAlarmRules ouId={ouId ?? data?.ouId} sceneType={sceneType ?? data?.sceneType} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="内部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushStaffs" wrapperCol={{ span: 24 }}>
                <SelectPushStaff onChange={setPushStaffs} ouId={ouId ?? data?.ouId} validator={pushStaffValidator} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="外部推送人员" className="mb-8">
          <Row>
            <Col span={24}>
              <Form.Item name="pushExtContacts" wrapperCol={{ span: 24 }}>
                <SelectPushOutSider hasContact onChange={setPushContacts} validator={outSiderValidator} />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space className="sticky-footer" size={8} style={{ zIndex: 99 }}>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateServerAlarmPushConfig;
