import { CustomFilter, EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { Form, Input, Modal, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { GatewayNetStatus, GatewayNetStatusDisplay } from '@/shared/types';
import { IotProtocol } from '../../../api/protocol';
import { useQuery } from '../../../utils/utils';
import { getGatewayList } from '../../../api/gateway';
import { SearchParams } from '../index';
import qs from 'qs';

const { Option } = Select;

interface ExportInfoModalProps {
  visible: boolean;
  onCancel: () => void;
  iotProtocolData?: Array<IotProtocol>;
}

const ExportInfoModal: React.FC<ExportInfoModalProps> = ({ visible, onCancel, iotProtocolData }) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(10);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        visible
          ? getGatewayList({
              ...searchParams,
              page: pageOffset,
              size: pageSize,
            }).then(res => {
              if (res) setTotalCount(res.total);
              return res.list;
            })
          : Promise.resolve([]),
      [pageOffset, pageSize, searchParams, setTotalCount, visible]
    )
  );

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    onSelect: (record: any, selected: any) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(i => i !== record.id));
      }
    },
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
    setSelectedRowKeys([]);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
    setSelectedRowKeys([]);
  };

  const onOk = () => {
    const params = {
      ids: selectedRowKeys,
    };
    window.open(`/api/gateway/download?${qs.stringify(params, { indices: false })}`);
    onCancelAction();
  };

  const onCancelAction = () => {
    onCancel();
    setSelectedRowKeys([]);
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  return (
    <Modal
      open={visible}
      destroyOnClose
      width={1000}
      title="网关选择"
      okText="导出选中的网关"
      onOk={onOk}
      onCancel={onCancelAction}
    >
      <CustomFilter form={form} colSpan={8} onFinish={onFinish} onReset={onReset}>
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
        <Form.Item name="iotProtocol" label="物联层协议">
          <Select placeholder="请选择">
            {iotProtocolData?.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="netState" label="状态">
          <Select placeholder="请选择">
            <Option value={GatewayNetStatus.ONLINE}>{GatewayNetStatusDisplay[GatewayNetStatus.ONLINE]}</Option>
            <Option value={GatewayNetStatus.OFFLINE}>{GatewayNetStatusDisplay[GatewayNetStatus.OFFLINE]}</Option>
          </Select>
        </Form.Item>
      </CustomFilter>
      <p>
        已选择<span style={{ color: '#d62500' }}>{selectedRowKeys.length}</span>项
      </p>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{ y: 300 }}
        rowSelection={rowSelection}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default ExportInfoModal;

const columns = [
  {
    title: '网关编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织简称',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织全称',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
