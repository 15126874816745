import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Paging,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
  Button,
  Key,
  usePaging,
  CustomFilter,
  Form,
  Input,
  Modal,
  Select,
  Table,
  TreeSelect,
} from '@maxtropy/components';
import { Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementType,
  SettlementRulesStatusType,
  SettlementRulesStatusTypeValue,
  TakeEffectStatusType,
  TakeEffectStatusTypeValue,
  getSaleElectricityContractList,
} from '@/api/electricitySettlementRules';
import {
  ElectricitySaleContractVo,
  PropertyItem,
  getPropertyList,
  getPropertyListOwned,
} from '@/api/electricitySalesContract';
import { SaleClientInfo, getSaleClientList } from '@/api/sale';
import { getOrganizationWithCodeWithCurrent } from '@/shared/components/MemberSelect/interface';
import qs from 'qs';
import {
  EnergyAssetsSettlementsRulesPageReq,
  EnergyAssetsSettlementsRulesPageRes,
  deleteEnergyAssetsSettlementsRules,
  disableEnergyAssetsSettlementsRules,
} from '@/api/energyAssetsSettlementsRules';

import styles from './index.module.scss';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash';
import {
  V2SaleElectricityMarketingOfEnergyAssetsPagePostRequest,
  V2SaleElectricityMarketingOfEnergyAssetsPagePostResponse,
  apiV2SaleElectricityMarketingOfEnergyAssetsPagePost,
} from '@maxtropy/device-customer-apis-v2';

const SettlementRulesStatusOptions = [
  { label: SettlementRulesStatusTypeValue[SettlementRulesStatusType.Enable], value: SettlementRulesStatusType.Enable },
  {
    label: SettlementRulesStatusTypeValue[SettlementRulesStatusType.Disable],
    value: SettlementRulesStatusType.Disable,
  },
];

const TakeEffectStatusOptions = [
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.IN_EFFECT],
    value: TakeEffectStatusType.IN_EFFECT,
  },
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.NOT_ACTIVE],
    value: TakeEffectStatusType.NOT_ACTIVE,
  },
  {
    label: TakeEffectStatusTypeValue[TakeEffectStatusType.EXPIRED],
    value: TakeEffectStatusType.EXPIRED,
  },
];

const columns = [
  {
    title: '结算规则编号',
    dataIndex: 'code',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算规则名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算类型',
    dataIndex: 'settlementType',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: ElectricitySaleContractSettlementType) => <EllipsisSpan value={ElectricitySaleContractSettlement[v]} />,
  },
  {
    title: '生效区间',
    dataIndex: 'effectiveTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: any) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format('YYYY-MM-DD')} ~ ${dayjs(record.endTime).format('YYYY-MM-DD')}`}
      />
    ),
  },
  {
    title: '生效状态',
    dataIndex: 'effectiveStatus',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: TakeEffectStatusType) => <EllipsisSpan value={TakeEffectStatusTypeValue[v]} />,
  },
  {
    title: '所属运营单元',
    dataIndex: 'ouName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属合同',
    dataIndex: 'saleElectricityContractName',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 60,
    ellipsis: { showTitle: true },
    render: (v: SettlementRulesStatusType) => <EllipsisSpan value={SettlementRulesStatusTypeValue[v]} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'lastModifyUser',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

function formatTreeData(data: any[]) {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
      disabled: !item.data?.hasPermission,
    };
    return res;
  });
}

interface ElectricitySettlementRulesProps {
  filterConditions?: string; // 过滤条件
}

const EnergyAssetsSettlementsRules: FC<ElectricitySettlementRulesProps> = props => {
  // const hasSettlementRuleAdd = useHasPermission(PermissionsType.B_ELECSETTLERULEADD); // 新增电费结算规则

  const [data, setData] = useState<V2SaleElectricityMarketingOfEnergyAssetsPagePostResponse['list']>([]); // 后端返回数据
  const [saleElectricityContractList, setSaleElectricityContractList] = useState<ElectricitySaleContractVo[]>([]); // 根据ou权限获取合同列表
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [refreshState, refresh] = useUpdate();
  const navigate = useNavigate();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset, antDPaginationOnShowSizeChange } = pagingInfo;

  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]); // 物业list
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const mcids: number[] | undefined = Form.useWatch('mcids', searchForm);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const [urlSearchParams] = useSearchParams();
  const url_pageOffset = urlSearchParams.get('pageOffset') || undefined;
  const url_pageSize = urlSearchParams.get('pageSize') || undefined;

  const [searchParams, setSearchParams] = useState<Partial<V2SaleElectricityMarketingOfEnergyAssetsPagePostRequest>>({
    name: urlSearchParams.get('name') ?? undefined,
    code: urlSearchParams.get('code') ?? undefined,
    propertyManagementConfigIds: urlSearchParams.getAll('propertyManagementConfigIds')
      ? urlSearchParams.getAll('propertyManagementConfigIds').map(Number)
      : undefined,
    saleElectricityContractIds: urlSearchParams.getAll('saleElectricityContractIds')
      ? urlSearchParams.getAll('saleElectricityContractIds').map(Number)
      : undefined,
    saleClientIds: urlSearchParams.getAll('saleClientIds')
      ? urlSearchParams.getAll('saleClientIds').map(Number)
      : undefined,
    mcids: urlSearchParams.getAll('mcids') ?? undefined,
    effectiveState: urlSearchParams.get('effectiveState')
      ? [Number(urlSearchParams.get('effectiveState'))]
      : [TakeEffectStatusType.IN_EFFECT],
    status: urlSearchParams.get('status')
      ? [Number(urlSearchParams.get('status'))]
      : [SettlementRulesStatusType.Enable],
  });

  const hasAdd = useHasPermission(PermissionsType.B_ENERGYPROPERTYCREATE);
  const hasEdit = useHasPermission(PermissionsType.B_ENERGYPROPERTYEDIT);
  const hasDelete = useHasPermission(PermissionsType.B_ENERGYPROPERTYDELETE);
  const hasBan = useHasPermission(PermissionsType.B_ENERGYPROPERTYBAN);
  const hasCopy = useHasPermission(PermissionsType.B_ENERGYPROPERTYCOPY);

  // 根据组织选择物业 默认选中第一个物业
  const getPropertyLists = useCallback((mcid: number[]) => {
    return getPropertyList(mcid).then(res => {
      setPropertyList(res);
      const search = {
        mcids: mcid,
        propertyManagementConfigIds: isNil(res.at(0)?.id) ? [] : [res.at(0)?.id],
      };
      return search;
    });
  }, []);

  useEffect(() => {
    if (!mcids || mcids.length === 0) {
      getPropertyListOwned().then(res => {
        setPropertyList(res);
      });
    }
  }, [mcids]);

  // 查询客户、合同、所属组织
  useEffect(() => {
    getSaleClientList().then(setCustomList);
    getSaleElectricityContractList().then(setSaleElectricityContractList);
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, [searchForm]);

  // 详情页带参返回
  useEffect(() => {
    const page = url_pageOffset ? Number(url_pageOffset) : pageOffset;
    const size = url_pageSize ? Number(url_pageSize) : pageSize;
    antDPaginationOnShowSizeChange(page, size);
    setPageOffset(page);
    searchForm.setFieldsValue({
      mcids: urlSearchParams.getAll('mcids') ?? undefined,
      name: urlSearchParams.get('name') ?? undefined,
      code: urlSearchParams.get('code') ?? undefined,
      propertyManagementConfigIds: urlSearchParams.getAll('propertyManagementConfigIds')
        ? urlSearchParams.getAll('propertyManagementConfigIds').map(Number)
        : undefined,
      saleElectricityContractIds: urlSearchParams.getAll('saleElectricityContractIds')
        ? urlSearchParams.getAll('saleElectricityContractIds').map(Number)
        : undefined,
      saleClientIds: urlSearchParams.getAll('saleClientIds')
        ? urlSearchParams.getAll('saleClientIds').map(Number)
        : undefined,
      effectiveState: urlSearchParams.get('effectiveState')
        ? [Number(urlSearchParams.get('effectiveState'))]
        : [TakeEffectStatusType.IN_EFFECT],

      status: urlSearchParams.get('status')
        ? [Number(urlSearchParams.get('status'))]
        : [SettlementRulesStatusType.Enable],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获取结算规则列表
  useEffect(() => {
    setLoading(true);
    apiV2SaleElectricityMarketingOfEnergyAssetsPagePost({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        res.list && setData(res.list);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, refreshState, setTotalCount, pageOffset, pageSize]);

  // 所属物业选项
  const propertyOptions = useMemo(() => {
    return (propertyList ?? []).map(item => ({ label: `${item.code}【${item.salesOrg}】`, value: item.id }));
  }, [propertyList]);

  // 客户选项
  const customListOptions = useMemo(() => {
    return (customList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [customList]);

  // 合同选项
  const saleElectricityContractListOptions = useMemo(() => {
    return (saleElectricityContractList ?? []).map(i => ({ label: `${i.code}【${i.name}】`, value: i.id }));
  }, [saleElectricityContractList]);

  const onFinish = (val: Partial<V2SaleElectricityMarketingOfEnergyAssetsPagePostRequest>) => {
    setSearchParams(val);
    setPageOffset(1);
    navigate(`?${qs.stringify({ ...val, pageOffset, pageSize })}`);
  };

  const onReset = () => {
    setSearchParams({
      effectiveState: [TakeEffectStatusType.IN_EFFECT],
      status: [SettlementRulesStatusType.Enable],
    });
    navigate('?');
    setPageOffset(1);
    antDPaginationOnShowSizeChange(1, 50);
  };

  /**
   * 改变结算规则状态（启用与禁用）
   * @param record
   */
  const onChangeStatus = (record: EnergyAssetsSettlementsRulesPageRes) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认${
        record.status === SettlementRulesStatusType.Enable
          ? SettlementRulesStatusTypeValue[SettlementRulesStatusType.Disable]
          : SettlementRulesStatusTypeValue[SettlementRulesStatusType.Enable]
      }该结算规则吗？`,
      okText: '继续',
      onOk() {
        const status =
          record.status === SettlementRulesStatusType.Enable
            ? SettlementRulesStatusType.Disable
            : SettlementRulesStatusType.Enable;
        disableEnergyAssetsSettlementsRules(record.id, status).then(() => {
          refresh();
        });
      },
    });
  };

  /**
   * 删除
   */
  const onDeleteRecord = (id: Key) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认删除该结算规则吗？`,
      okText: '继续',
      onOk() {
        deleteEnergyAssetsSettlementsRules(id).then(() => {
          refresh();
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 210,
      render: (record: EnergyAssetsSettlementsRulesPageRes) => (
        <Space size={16}>
          {
            // 权限控制与状态判断（根据前后端约定）
            hasDelete && (
              <Button
                disabled={record.status === SettlementRulesStatusType.Enable}
                type="link"
                onClick={() => onDeleteRecord(record.id)}
              >
                删除
              </Button>
            )
          }
          {hasBan && (
            <Button type="link" onClick={() => onChangeStatus(record)}>
              {record.status === SettlementRulesStatusType.Disable ? '启用' : '禁用'}
            </Button>
          )}
          {hasEdit && (
            <Button
              disabled={record.status === SettlementRulesStatusType.Disable}
              type="link"
              onClick={() => {
                navigate(`/operation/settlementRules/energyAssetsRules/edit/${record.id}`);
              }}
            >
              编辑
            </Button>
          )}
          {hasCopy && (
            <Button type="link">
              <Link to={`/operation/settlementRules/energyAssetsRules/copy/${record.id}`}>复制</Link>
            </Button>
          )}
          {
            <Button type="link">
              <Link
                to={`/operation/settlementRules/energyAssetsRules/details/${record.id}?${qs.stringify(
                  { ...searchParams, pageSize, pageOffset },
                  {
                    indices: false,
                  }
                )}`}
              >
                查看
              </Link>
            </Button>
          }
        </Space>
      ),
    },
  ];

  const filters = (
    <CustomFilter
      form={searchForm}
      onFinish={(val: EnergyAssetsSettlementsRulesPageReq) =>
        onFinish(val as Partial<V2SaleElectricityMarketingOfEnergyAssetsPagePostRequest>)
      }
      onReset={onReset}
      initialValues={{
        effectiveState: [TakeEffectStatusType.IN_EFFECT],
        status: [SettlementRulesStatusType.Enable],
      }}
    >
      <Form.Item name="mcids" label="所属组织">
        <TreeSelect
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          multiple
          maxTagCount="responsive"
          allowClear
          onChange={v => {
            if (v?.length === 0) return;
            searchForm.resetFields(['propertyManagementConfigIds']);
            getPropertyLists(v)?.then(search => {
              searchForm.setFieldsValue(search);
            });
          }}
        />
      </Form.Item>
      <Form.Item noStyle dependencies={['mcids']}>
        {() => {
          return (
            <Form.Item name="propertyManagementConfigIds" label="所属物业">
              <Select
                showSearch
                allowClear
                maxTagCount={1}
                optionFilterProp="label"
                mode="multiple"
                placeholder="请选择"
                options={propertyOptions}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item label="客户名称" name="saleClientIds">
        <Select
          mode="multiple"
          allowClear
          placeholder="请选择"
          maxTagCount={2}
          options={customListOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item name="saleElectricityContractIds" label="所属合同">
        <Select
          mode="multiple"
          allowClear
          maxTagCount={1}
          placeholder="请选择"
          options={saleElectricityContractListOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item label="结算规则编号" name="code">
        <Input placeholder=" 请输入编号查询" />
      </Form.Item>
      <Form.Item label="结算规则名称" name="name">
        <Input placeholder=" 请输入名称查询" />
      </Form.Item>
      <Form.Item label="生效状态" name="effectiveState">
        <Select placeholder="请选择" options={TakeEffectStatusOptions} mode="multiple" />
      </Form.Item>
      <Form.Item label="状态" name="status">
        <Select placeholder="请选择" options={SettlementRulesStatusOptions} mode="multiple" />
      </Form.Item>
    </CustomFilter>
  );

  // const onSelectChange = (newSelectedRowKeys: Key[]) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
        <Space style={{ width: '100%', marginBottom: 10 }}>
          {hasAdd && (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/operation/settlementRules/energyAssetsRules/create`);
              }}
              icon={<PlusOutlined />}
            >
              新建结算规则
            </Button>
          )}
        </Space>

        <Table
          // rowSelection={rowSelection}
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
    </>
  );
};

export default EnergyAssetsSettlementsRules;
