import { EllipsisSpan } from '@maxtropy/components';
import dayjs from 'dayjs';
import { SettlementRulesStatusType } from '../../../../../api/electricitySettlementRules';

const formatString = 'YYYY-MM-DD HH:mm:ss';

export const columns = [
  {
    title: '结算规则编号',
    dataIndex: 'code',
    width: 174,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '结算规则名称',
    dataIndex: 'name',
    width: 201,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  // {
  //   title: '计量场景',
  //   dataIndex: 'meteringScenarios',
  //   ellipsis: { showTitle: true },
  //   width: 152,
  //   render: (v: MeasurementScenarioType, record: any) => {
  //     let statusText = '';
  //     switch (v) {
  //       case MeasurementScenarioType.LIGHTING:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.LIGHTING];
  //         break;
  //       case MeasurementScenarioType.PHOTOVOLTAIC:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.PHOTOVOLTAIC];
  //         break;
  //       case MeasurementScenarioType.SPECIALPURPOSE:
  //         statusText = MeasurementScenarioTypeValue[MeasurementScenarioType.SPECIALPURPOSE];
  //         break;
  //       default:
  //         statusText = '-'
  //         break;
  //     }
  //     return <EllipsisSpan value={statusText} />
  //   }
  // },
  {
    title: '生效区间',
    dataIndex: 'effectiveTime',
    width: 330,
    ellipsis: { showTitle: true },
    render: (v: undefined, record: any) => (
      <EllipsisSpan
        value={`${dayjs(record.startTime).format('YYYY-MM-DD')} ~ ${dayjs(record.endTime).format('YYYY-MM-DD')}`}
      />
    ),
  },
  {
    title: '绑定时间',
    dataIndex: 'createTime',
    width: 190,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format(formatString)} `} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: SettlementRulesStatusType, record: any) => {
      let statusText = '';
      switch (v) {
        case SettlementRulesStatusType.Enable:
          statusText = '启用';
          break;
        case SettlementRulesStatusType.Disable:
          statusText = '禁用';
          break;
        default:
          statusText = '-';
          break;
      }
      return <EllipsisSpan value={statusText} />;
    },
  },
];
