import {
  BaseInfoContent,
  Button,
  DatePicker,
  Form,
  FormTitle,
  InputNumber,
  Key,
  Modal,
  PopConfirm,
  Select,
  ShowInput,
  SubContent,
  TreeSelect,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Col, Divider, Row, Badge, Calendar, Upload, Button as AntdButton } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import { getOutputDetail, getUnits, OutputTypeDisplay, OutputUnitType } from '@/api/outputConfig';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { extname } from '@/shared/utils/utils';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import RecordModal from '../components/RecordModal';
import qs from 'qs';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isArray, isNil } from 'lodash-es';
import {
  apiV2EnergyAnalysisAmountCalendarPost,
  apiV2EnergyAnalysisEmptyAmountPost,
  apiV2EnergyAnalysisImportAmountExcelPost,
  apiV2EnergyAnalysisListByOu2Post,
  apiV2EnergyAnalysisOutputAmountCheckinPost,
  apiV2EnergyAnalysisUnitConfGroupListByOuPost,
  V2EnergyAnalysisListByOu2PostResponse,
  V2EnergyAnalysisAmountCalendarPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { UnitConfGroupItem } from '@/pages/EnergyConsumption/CostConfig';
const { RangePicker } = DatePicker;

const routes = [{ name: `产量录入` }];

interface OptionType {
  value: Key;
  title: string;
  children: OptionType[];
}

function formatToTreeData(data: UnitConfGroupItem[]): OptionType[] {
  return data.map(i => {
    return {
      value: i.id as number,
      title: i.name as string,
      children: i.childList && i.childList.length > 0 ? formatToTreeData(i.childList) : [],
    };
  });
}

const generateList = (data: OptionType[], dataList: Key[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { value } = node;
    dataList.push(value);
    if (node.children) {
      generateList(node.children, dataList);
    }
  }
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface DotItem {
  id: Key;
  name: string;
  color: string;
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export type GroupDetails = Exclude<V2EnergyAnalysisListByOu2PostResponse['list'], undefined>[number];
export type AmountCalendarRes = Exclude<V2EnergyAnalysisAmountCalendarPostResponse['list'], undefined>[number];
export type ApplyWorkShift = Exclude<AmountCalendarRes['applyWorkShifts'], undefined>[number];
const OutputEntry: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // 产出物Id
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [loading, setLoading] = useState<boolean>(false);
  const [detailForm] = Form.useForm();
  const [energyForm] = Form.useForm();
  const [outputForm] = Form.useForm();
  const [exportForm] = Form.useForm();
  const [importForm] = Form.useForm();

  const [dateCellStyleClassName, setdateCellStyleClassName] = useState<Dayjs>();
  const [energyGroupList, setEnergyGroupList] = useState<GroupDetails[]>([]);
  const [energyGroupId, setEnergyGroupId] = useState<Key>();
  const [unitTree, setUnitTree] = useState<UnitConfGroupItem[]>([]);
  const [amountCalendar, setAmountCalendar] = useState<AmountCalendarRes[]>([]);
  const [selectedYear, setSelectedYear] = useState<string | number>();
  const [selectedMonth, setSelectedMonth] = useState<string | number>();
  const [confGroupId, setConfGroupId] = useState<Key>(); // 用能单元id
  const [unitName, seyUnitName] = useState<string>(); // 单位名

  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);
  const [recordModalOpen, setRecordModalOpen] = useState<boolean>(false);
  const [inputModalOpen, setInputModalOpen] = useState<boolean>(false);

  const [update, updateFn] = useUpdate();
  const [modalUnitTree, setModalUnitTree] = useState<UnitConfGroupItem[]>([]);
  const [modalEnergyGroupId, setModalEnergyGroupId] = useState<Key>();

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>([
    dayjs(dayjs().subtract(2, 'month'), 'YYYY-MM'),
    dayjs(dayjs(), 'YYYY-MM'),
  ]);

  const hasExportPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTENTRYOUTPUT); // 产量导出
  const hasImportPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTENTRYINPUT); // 产量导入
  const hasRecordListPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTENTRYINPUTRECORD); // 录入记录
  const hasClearPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTENTRYCLEAR); // 清空产量
  const hasInputPermission = useHasPermission(PermissionsType.B_BELTLINEOUTPUTENTRYCREATE); // 录入产量

  const [isLarger, setIsLarger] = useState<boolean>(false); // 总产量是否超过999999999

  const [fileList, setFileList] = useState<UploadFile>();
  const [inputTime, setInputTime] = useState<string>();
  const [inputFormItem, setInputFormItem] = useState<JSX.Element>();

  const [colorDots, setColorDots] = useState<Record<number, DotItem>>({});

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    exportForm.setFieldValue('time', dates || value);
  }, [dates, exportForm, value]);

  useEffect(() => {
    if (id) {
      getUnits().then(units => {
        getOutputDetail(id).then(res => {
          if (res) {
            const thatUnit = units?.list?.[res?.unitTypeCode! - 1]?.outputProductUnitList?.filter(
              v => v?.unitCode === res.unitCode
            );
            const unit =
              res.unitTypeCode === OutputUnitType.QUANTITY
                ? `${thatUnit?.[0].unitCode} (${thatUnit?.[0].unitName})`
                : thatUnit?.[0].unitName;
            const enUnit =
              res.unitTypeCode === OutputUnitType.QUANTITY ? `${thatUnit?.[0].unitCode}` : thatUnit?.[0].unitName;
            seyUnitName(enUnit);
            detailForm.setFieldsValue({
              name: res.name,
              category: (res?.categoryNames ?? [])?.join('-'),
              unitName: unit,
              type: res.type ? OutputTypeDisplay[res.type] : null,
            });
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    apiV2EnergyAnalysisListByOu2Post({}).then(res => {
      setEnergyGroupList(res.list ?? []);
    });
  }, []);

  const energyGroupOptions = useMemo(() => {
    if (energyGroupList && energyGroupList.length !== 0) {
      return energyGroupList.map(i => ({ label: i.energyGroupName, value: i.energyGroupId }));
    }
  }, [energyGroupList]);

  useEffect(() => {
    if (energyGroupId) {
      apiV2EnergyAnalysisUnitConfGroupListByOuPost({
        energyUnitId: energyGroupId as string,
      }).then(res => {
        setUnitTree((res.list ?? []) as UnitConfGroupItem[]);
      });
    }
  }, [energyGroupId]);

  // modal里unitTree
  useEffect(() => {
    if (modalEnergyGroupId) {
      apiV2EnergyAnalysisUnitConfGroupListByOuPost({
        energyUnitId: modalEnergyGroupId as string,
      }).then(res => {
        setModalUnitTree((res.list ?? []) as UnitConfGroupItem[]);
      });
    }
  }, [modalEnergyGroupId]);

  const modalUnitTreeOptions = useMemo(() => {
    if (modalUnitTree && modalUnitTree.length !== 0) {
      return formatToTreeData(modalUnitTree);
    }
  }, [modalUnitTree]);

  const unitTreeOptions = useMemo(() => {
    if (unitTree && unitTree.length !== 0) {
      return formatToTreeData(unitTree);
    }
  }, [unitTree]);

  useEffect(() => {
    if (confGroupId && id && selectedYear && selectedMonth) {
      setLoading(true);

      apiV2EnergyAnalysisAmountCalendarPost({
        energyUnitId: confGroupId,
        outputProductId: id, // 产出物id
        year: selectedYear, // 年
        month: selectedMonth, // 月
      })
        .then(res => {
          (res.list ?? []).forEach(i => {
            i.applyWorkShifts?.forEach((j: ApplyWorkShift, index) => {
              setColorDots((prev: any) => {
                const color = prev?.[j.workShiftId as number]?.color ?? getRandomColor();
                return {
                  ...prev,
                  [j.workShiftId as number]: {
                    id: j.workShiftId,
                    name: j.workShiftName,
                    color,
                  },
                };
              });
            });
          });
          setAmountCalendar((res.list ?? []) as AmountCalendarRes[]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [confGroupId, id, selectedYear, selectedMonth, update]);

  useEffect(() => {
    if (energyGroupList && energyGroupList.length !== 0) {
      energyForm.setFieldsValue({
        energyGroupId: energyGroupList[0].energyGroupId,
      });
      setEnergyGroupId(energyGroupList[0].energyGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyGroupList]);
  useEffect(() => {
    if (unitTree && unitTree.length !== 0) {
      energyForm.setFieldsValue({
        confGroupId: unitTree[0].id,
      });
      setConfGroupId(unitTree[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitTree]);

  const dotList = useMemo(() => {
    if (colorDots) {
      const allOutputColor = <Badge style={{ marginRight: 15, paddingTop: 5 }} color="#4A90E2" text="单日总产量" />;
      const shiftColors = Object.values(colorDots).map(i => {
        return <Badge style={{ marginRight: 15 }} color={i.color} text={i.name} />;
      });
      return (
        <div>
          {allOutputColor}
          {shiftColors}
        </div>
      );
    }
  }, [colorDots]);

  const dateCellRender = (value: Dayjs) => {
    if (value.isSameOrBefore(dayjs())) {
      const dateCellData = amountCalendar.find(
        i => dayjs(i.workDate).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
      );

      if (dateCellData) {
        const workDate = dayjs(dateCellData.workDate).format('YYYY-MM-DD');
        const cellYear = workDate.split('-').at(0);
        const cellMonth = workDate.split('-').at(1);
        const cellDay = workDate.split('-').at(2);

        // 休息日
        const dayOff = (
          <div className={styles.disabledDateStyle}>
            <p style={{ paddingTop: 40 }}>休息日</p>
          </div>
        );

        // 是否已录入产量
        const isInput = dateCellData.applyWorkShifts?.some(i => i.outputAmountId) || dateCellData.totalOutputAmountId;

        const outputFormElement = (
          <Form
            form={outputForm}
            layout="vertical"
            onFinish={() => {
              if (confGroupId && id && colorDots) {
                outputForm.validateFields().then(res => {
                  const workShiftAmounts = (dateCellData?.applyWorkShifts ?? []).map(i => {
                    return {
                      workShiftId: i.workShiftId as number,
                      workShiftAmount: res[i.workShiftId as number],
                    };
                  });
                  apiV2EnergyAnalysisOutputAmountCheckinPost({
                    confGroupId,
                    outputProductId: id,
                    date: dateCellData.workDate,
                    totalAmount: res['totalAmount'],
                    workShiftAmounts,
                  }).then(() => {
                    updateFn();
                    outputForm.resetFields();
                    setInputModalOpen(false);
                  });
                });
              }
            }}
          >
            {(dateCellData?.applyWorkShifts ?? []).map((i, index) => {
              return (
                <Form.Item label={i.workShiftName} name={i.workShiftId}>
                  <InputNumber
                    style={{ width: '90%' }}
                    min={0}
                    max={99999999}
                    step="0.0001"
                    precision={4}
                    addonAfter={unitName}
                    placeholder="请输入"
                    onChange={() => {
                      const totalArray = Object.values(colorDots ?? {}).map(i =>
                        outputForm.getFieldValue(i.id) ? Number(outputForm.getFieldValue(i.id)) : 0
                      );
                      const total = totalArray.reduce((x, y) => x + y);
                      if (total <= 999999999) {
                        setIsLarger(false);
                      }
                      if (total > 999999999) {
                        setIsLarger(true);
                        return;
                      }
                      outputForm.setFieldsValue({
                        totalAmount: total,
                      });
                    }}
                  />
                </Form.Item>
              );
            })}
            <Form.Item label="总产量" name="totalAmount">
              <InputNumber
                style={{ width: '90%' }}
                min={0}
                max={999999999}
                step="0.0001"
                precision={4}
                addonAfter={unitName}
                placeholder="请输入"
                onChange={() => {
                  (dateCellData?.applyWorkShifts ?? []).forEach(i => {
                    outputForm.setFieldValue([i.workShiftId], undefined);
                  });
                }}
              />
            </Form.Item>
          </Form>
        );

        const emptyModal = (
          <PopConfirm
            placement="top"
            title={`确认清空${value.format('YYYY-MM-DD')}产量信息？`}
            onConfirm={() => {
              if (confGroupId && id && cellYear && cellMonth && cellDay) {
                apiV2EnergyAnalysisEmptyAmountPost({
                  energyUnitId: confGroupId,
                  outputProductId: id,
                  year: cellYear,
                  month: cellMonth,
                  day: cellDay,
                }).then(() => {
                  updateFn();
                });
              }
            }}
            okText="确定"
            cancelText="取消"
          >
            {hasClearPermission && <Button type="link">清空</Button>}
          </PopConfirm>
        );

        const inputModal = (
          <>
            {hasInputPermission && (
              <Button
                type="link"
                onClick={() => {
                  const formValue = Object.keys(colorDots ?? {}).map(i => {
                    return {
                      id: i,
                      value: dateCellData?.applyWorkShifts?.find(j => j.workShiftId === Number(i))?.outputAmount,
                    };
                  });

                  outputForm.setFieldsValue({
                    totalAmount: dateCellData?.totalOutputAmount,
                    ...formValue.reduce((obj, item) => {
                      return {
                        ...obj,
                        [item.id]: item.value,
                      };
                    }, {}),
                  });

                  setInputTime(value.format('YYYY-MM-DD'));
                  setInputFormItem(outputFormElement);
                  setInputModalOpen(true);
                }}
              >
                录入
              </Button>
            )}
          </>
        );
        // 产出物产量列表
        const outputDataList = [
          <li key={'total_' + dateCellData.totalOutputAmountId}>
            <div className={styles.squareBadge}>
              <Badge
                color="#4A90E2"
                text={
                  <span style={{ color: '#4A90E2' }}>
                    {dateCellData.totalOutputAmount !== undefined && dateCellData.totalOutputAmount !== null
                      ? Number(dateCellData.totalOutputAmount)
                      : '--'}
                  </span>
                }
              />
            </div>
          </li>,
        ];
        (dateCellData?.applyWorkShifts ?? []).forEach(i => {
          outputDataList.push(
            <li key={i.workShiftId}>
              <Badge
                color={colorDots?.[i.workShiftId as number]?.color}
                text={i.outputAmount !== undefined && i.outputAmount !== null ? Number(i.outputAmount) : '--'}
              />
            </li>
          );
        });

        // 有班次
        if (dateCellData.hasWorkShift) {
          // 是工作日
          if (dateCellData.isWorkingDay) {
            return (
              <div
                className={styles.dateCell}
                onMouseEnter={e => {
                  setdateCellStyleClassName(value);
                }}
                onMouseLeave={e => {
                  setdateCellStyleClassName(undefined);
                }}
              >
                <ul className={styles.events}>
                  <div
                    className={
                      dateCellStyleClassName &&
                      dateCellStyleClassName.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
                        ? styles.btnMouseEnter
                        : styles.btnMouseLeave
                    }
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '60%' }}>
                      {isInput ? emptyModal : null}
                      {inputModal}
                    </div>
                  </div>
                  {outputDataList}
                </ul>
              </div>
            );
          } else {
            // 不是工作日
            return dayOff;
          }
          // 没有班次
        } else {
          // 有总产量
          if (dateCellData.totalOutputAmountId) {
            return (
              <div
                style={{ widows: 100, height: 85 }}
                className={styles.dateCell}
                onMouseEnter={e => {
                  setdateCellStyleClassName(value);
                }}
                onMouseLeave={e => {
                  setdateCellStyleClassName(undefined);
                }}
              >
                <ul className={styles.events}>
                  <div
                    className={
                      dateCellStyleClassName &&
                      dateCellStyleClassName.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
                        ? styles.btnMouseEnter
                        : styles.btnMouseLeave
                    }
                  >
                    {isInput ? emptyModal : null}
                    {inputModal}
                  </div>
                  {outputDataList}
                </ul>
              </div>
            );
          } else {
            return (
              <div
                style={{ widows: 100, height: 85 }}
                className={styles.dateCell}
                onMouseEnter={e => {
                  setdateCellStyleClassName(value);
                }}
                onMouseLeave={e => {
                  setdateCellStyleClassName(undefined);
                }}
              >
                <ul className={styles.events}>
                  <div
                    className={
                      dateCellStyleClassName &&
                      dateCellStyleClassName.format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
                        ? styles.btnMouseEnter
                        : styles.btnMouseLeave
                    }
                  >
                    {inputModal}
                  </div>
                  {outputDataList}
                </ul>
              </div>
            );
          }
        }
      }
    }

    // 未来时间置灰
    if (value.isAfter(dayjs())) {
      return <div className={styles.disabledDateStyle} />;
    }
  };

  const beforeUploadFile = (file: RcFile) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileSize = file.size <= 20 * 1024 * 1024;
    const limitFileType = extName === '.xlsx' || extName === '.xls';
    if (!limitFileSize) {
      Modal.warning({
        content: `上传文件的大小不得超过20M`,
      });
      return false;
    }
    if (!limitFileType) {
      Modal.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };

  const onClickInputType = (type: number) => {
    const unitIds: Key[] = [];
    if (unitTreeOptions) {
      generateList(unitTreeOptions, unitIds);
    }
    if (!energyGroupId || unitIds.length === 0) {
      Modal.warning({
        content: '请先选择用能分析组与用能单元！',
      });
      return;
    }
    const params = {
      outputProductId: id,
      type,
      energyUnitGroupId: energyGroupId,
      energyUnitIds: unitIds,
      year: selectedYear,
      month: selectedMonth,
    };
    window.open(
      `/api/v2/energy-analysis/importTemplateDownload?${qs.stringify(params, {
        indices: false,
      })}`
    );
  };

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />
      <SubContent>
        <div className={styles.content}>
          <Form form={detailForm}>
            <BaseInfoContent layout="horizontal">
              <Col span={4}>
                <Form.Item name="name" label="产出物名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="category" label="产出物分类">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="unitName" label="单位">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="type" label="类型">
                  <ShowInput />
                </Form.Item>
              </Col>
            </BaseInfoContent>
          </Form>
          <Divider />
          <div className={styles.form_col_gap}>
            <Form form={energyForm} labelCol={{ style: { width: 90 } }}>
              <Row gutter={10}>
                <Col span={6}>
                  <Form.Item name="energyGroupId" label="用能分析组">
                    <Select
                      onBlur={() => {
                        setEnergyGroupId(energyForm.getFieldValue('energyGroupId'));
                      }}
                      onChange={v => {
                        setEnergyGroupId(v);
                        energyForm.setFieldsValue({
                          confGroupId: undefined,
                        });
                        setColorDots({});
                      }}
                      options={energyGroupOptions}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="confGroupId" label="用能单元">
                    <TreeSelect
                      showSearch
                      style={{ width: '100%' }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                      placeholder="请选择"
                      treeDefaultExpandAll
                      treeData={unitTreeOptions}
                      onBlur={() => {
                        setConfGroupId(energyForm.getFieldValue('confGroupId'));
                      }}
                      onChange={v => {
                        setConfGroupId(v);
                        setColorDots({});
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ maxWidth: 'unset' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {hasExportPermission && (
                      <div style={{ marginRight: 8 }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            exportForm.setFieldsValue({
                              energyGroupId,
                              confGroupId,
                            });
                            setModalEnergyGroupId(energyGroupId);
                            setDates([dayjs(dayjs().subtract(2, 'month'), 'YYYY-MM'), dayjs(dayjs(), 'YYYY-MM')]);
                            setExportModalOpen(true);
                          }}
                        >
                          导出
                        </Button>
                      </div>
                    )}
                    {hasImportPermission && (
                      <div style={{ marginRight: 8 }}>
                        <Button type="primary" onClick={() => setImportModalOpen(true)}>
                          导入
                        </Button>
                      </div>
                    )}
                    {hasRecordListPermission && (
                      <Button type="primary" onClick={() => setRecordModalOpen(true)}>
                        导入记录
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </div>

          <Calendar
            disabledDate={current => {
              return current.isAfter(dayjs());
            }}
            style={{ marginTop: 28 }}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              const start = 0;
              const end = 12;
              const monthOptions = [];
              let current = value.clone();
              const localeData = value.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current = current.month(i);
                months.push(localeData.monthsShort(current));
              }
              for (let i = start; i < end; i++) {
                monthOptions.push(
                  <Select.Option key={i} value={i} className="month-item">
                    {months[i]}
                  </Select.Option>
                );
              }
              const year = value.year();
              const month = value.month();
              setSelectedMonth(Number(month) + 1); // month 从0开始计数，因此加一
              setSelectedYear(year);
              const options = [];
              for (let i = year - 10; i < year + 10; i += 1) {
                options.push(
                  <Select.Option key={i} value={i} className="year-item">
                    {i}
                  </Select.Option>
                );
              }
              return (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PopConfirm
                      placement="top"
                      title={`确认清空${value.format('YYYY-MM')}产量信息？`}
                      onConfirm={() => {
                        if (confGroupId && id && selectedYear && selectedMonth) {
                          apiV2EnergyAnalysisEmptyAmountPost({
                            energyUnitId: confGroupId,
                            outputProductId: id,
                            year: selectedYear,
                            month: selectedMonth,
                            // day: null,
                          }).then(() => {
                            updateFn();
                          });
                        }
                      }}
                      okText="确定"
                      cancelText="取消"
                    >
                      {hasClearPermission && (
                        <AntdButton style={{ marginRight: 10 }} type="dashed">
                          清空月产量
                        </AntdButton>
                      )}
                    </PopConfirm>
                    {dotList}
                  </div>

                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Select
                        style={{ marginRight: 10 }}
                        size="middle"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        value={year}
                        onChange={newYear => {
                          const now = value.clone().year(newYear);
                          setSelectedYear(now.year());
                          setColorDots({});
                          onChange(now);
                        }}
                      >
                        {options}
                      </Select>
                      <Select
                        size="middle"
                        dropdownMatchSelectWidth={false}
                        value={month}
                        onChange={newMonth => {
                          const now = value.clone().month(newMonth);
                          setSelectedMonth(Number(now.month()) + 1);
                          setColorDots({});
                          onChange(now);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </div>
                  </div>
                </div>
              );
            }}
            dateCellRender={dateCellRender}
            className={styles.calendarStyle}
          />
        </div>
      </SubContent>
      <Modal
        width={600}
        okText="导出"
        open={exportModalOpen}
        title="产量导出"
        onOk={() => {
          exportForm.submit();
        }}
        onCancel={() => {
          setExportModalOpen(false);
          exportForm.resetFields();
        }}
      >
        <Form
          labelCol={{ span: 5, offset: 2 }}
          wrapperCol={{ span: 14 }}
          labelAlign="left"
          form={exportForm}
          // initialValues={{}}
          onFinish={() => {
            const confGroupId = exportForm.getFieldValue('confGroupId');
            const time = exportForm.getFieldValue('time');
            if (confGroupId && time && id) {
              const energyUnitIds = isArray(confGroupId) ? confGroupId?.map((i: any) => i.value) : confGroupId;
              const params = {
                energyUnitIds,
                outputProductId: id,
                startYear: time[0].year(),
                startMonth: Number(time[0].month()) + 1,
                endYear: time[1].year(),
                endMonth: Number(time[1].month()) + 1,
              };
              window.open(
                `/api/v2/energy-analysis/amountExport?${qs.stringify(params, {
                  indices: false,
                })}`
              );
              setExportModalOpen(false);
            }
          }}
        >
          <Form.Item label="产出物">
            <ShowInput value={detailForm.getFieldValue('name')} />
          </Form.Item>
          <Form.Item name="energyGroupId" label="用能分析组" rules={[{ required: true, message: '请选择' }]}>
            <Select
              options={energyGroupOptions}
              onChange={v => {
                setModalEnergyGroupId(v);
                exportForm.setFieldsValue({
                  confGroupId: undefined,
                });
              }}
              placeholder="请选择"
            />
          </Form.Item>
          <Form.Item name="confGroupId" label="用能单元" rules={[{ required: true, message: '请选择' }]}>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="请选择"
              treeDefaultExpandAll
              treeData={modalUnitTreeOptions}
              treeCheckable
              treeCheckStrictly
            />
          </Form.Item>
          <Form.Item label="选择日期" name="time" rules={[{ required: true, message: '请选择' }]}>
            <RangePicker
              style={{ width: '100%' }}
              picker="month"
              disabledDate={(current: Dayjs) => {
                if (!dates) {
                  return false;
                }
                const tooLate = dates[0] && current.diff(dates[0], 'month') > 4;
                const tooEarly = dates[1] && dates[1].diff(current, 'month') > 4;
                return !!tooEarly || !!tooLate;
              }}
              onCalendarChange={val => setDates(val)}
              allowClear={false}
              onChange={val => setValue(val)}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={600}
        open={importModalOpen}
        title="产量导入"
        okText="导入"
        onOk={() => {
          importForm.submit();
        }}
        onCancel={() => {
          setImportModalOpen(false);
          importForm.resetFields();
        }}
      >
        <div style={{ marginLeft: 30, marginBottom: 36 }}>
          <Button
            type="link"
            onClick={() => {
              onClickInputType(1);
            }}
          >
            按日录入模板下载
          </Button>
          <Button
            type="link"
            onClick={() => {
              onClickInputType(2);
            }}
          >
            班次录入模板下载
          </Button>
        </div>
        <Form
          labelCol={{ span: 5, offset: 2 }}
          wrapperCol={{ span: 14 }}
          labelAlign="left"
          form={importForm}
          onFinish={() => {
            if (fileList) {
              const fileKey = fileList.response.key;
              const originalFilename = fileList.name;
              apiV2EnergyAnalysisImportAmountExcelPost({
                fileKey,
                originalFilename,
              })
                .then(res => {
                  importForm.resetFields();
                  setImportModalOpen(false);
                  updateFn();
                })
                .finally(() => {
                  setImportModalOpen(false);
                  updateFn();
                });
            } else {
              importForm.setFields([{ name: 'fileKey', errors: ['请选择文件'] }]);
            }
          }}
        >
          <Form.Item
            extra={
              <div style={{ marginTop: 10 }}>
                <p>限制格式为xls或xlsx，不超过20MB。</p>

                <p>
                  <InfoCircleOutlined style={{ marginRight: 10, color: 'var(--mx-warning-color)' }} />
                  班次录入后自动生成按日产量。
                </p>
              </div>
            }
            label="选择文件"
            name="fileKey"
            rules={[{ required: true, message: '请选择文件' }]}
          >
            <Upload
              withCredentials={true}
              action="/api/file-center/upload"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              maxCount={1}
              beforeUpload={beforeUploadFile}
              onChange={(uploadFile: UploadChangeParam<UploadFile>) => {
                const { file } = uploadFile;
                if (file.status === 'uploading') {
                }
                if (file.status === 'error') {
                }
                if (file.status === 'done') {
                  setFileList(file);
                }
              }}
              onRemove={() => {
                setFileList(undefined);
                return true;
              }}
            >
              <Button icon={<UploadOutlined />}>上传文件</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={inputModalOpen}
        title={<>产量录入{inputTime}</>}
        onOk={() => {
          if (isLarger) {
            return;
          }
          if (!isLarger) {
            if (
              !isNil(outputForm.getFieldValue('totalAmount')) &&
              Number(outputForm.getFieldValue('totalAmount')) <= 999999999
            ) {
              outputForm.submit();
            }
          }
        }}
        onCancel={() => {
          setIsLarger(false);
          outputForm.resetFields();
          setInputModalOpen(false);
        }}
        contentClassName="modal-form-content"
      >
        {inputFormItem}
        {isLarger ? <span style={{ paddingLeft: 24, color: '#E64242' }}>总产量超过999999999,无法录入。</span> : null}
      </Modal>
      {recordModalOpen && <RecordModal recordModalOpen={recordModalOpen} setRecordModalOpen={setRecordModalOpen} />}
    </Wrapper>
  );
};

export default OutputEntry;
