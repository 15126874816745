import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import { DeviceType, Operator } from '@/shared/types';
import { Device } from './device';
import { PageRequest, PageResponse } from './page';

export interface ThermalRequest {
  // 用汽账户户号
  number: string;
  // 区域code
  regionCode: string;
  // 全域能源拓扑id
  uetId: number;
  // 管线id
  linePipeId: number;
  // 组织mcid
  customerMcids: Array<string>;
  id?: number;
}

export function createThermal(request: ThermalRequest) {
  return fetch<void>(
    `/api/v2/thermal/account/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function updateThermal(request: ThermalRequest) {
  return fetch<void>(
    `/api/v2/thermal/account/update`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ThermalResponse extends ThermalRequest {
  id: number;
  createByUserId: string;
  updateByUserId: number;
  createByUsername: string;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  uetName: string;
  linePipeName: string;
  customerMcidNames: string[];
}

export interface ThermalPageRequest extends PageRequest {
  number?: string;
  regionCode?: string;
  username?: string;
}

export function getThermalPage(request: ThermalPageRequest) {
  return fetch<PageResponse<ThermalResponse>>(
    `/api/v2/thermal/account/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface UnBoundDevicePageRequest extends PageRequest {
  deviceTypeId?: string;
  thermalAccountId: Key;
  codeOrName?: string;
  deviceTag?: string;
}

export function getThermalUnBoundDevicePage(request: UnBoundDevicePageRequest) {
  return fetch<PageResponse<Device>>(
    `/api/v2/thermal/account/unbound-device/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getThermalInfo(id: Key) {
  return fetch<ThermalResponse>(
    `/api/v2/thermal/account/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function thermalBindDevices(id: Key, request: Array<number>) {
  return fetch<void>(
    `/api/v2/thermal/account/device/bind`,
    {
      method: 'POST',
      body: JSON.stringify({
        deviceIds: request,
        id,
      }),
    },
    true
  );
}

export function thermalUnBindDevices({ id, deviceId }: { id: Key; deviceId: number }) {
  return fetch<void>(
    `/api/v2/thermal/account/device/unbind`,
    {
      method: 'POST',
      body: JSON.stringify({
        deviceId,
        id,
      }),
    },
    true
  );
}

export interface ThermalBoundDeviceRequest extends PageRequest {
  deviceCode?: string;
  deviceName?: string;
  thermalAccountId: Key;
}

export function getThermalBoundDevicePage(request: ThermalBoundDeviceRequest) {
  return fetch<PageResponse<Device>>(
    `/api/v2/thermal/account/bound-device/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getThermalList() {
  return fetch<{ list: Array<ThermalResponse> }>(
    `/api/v2/thermal/account/list`,
    {
      method: 'POST',
    },
    true
  );
}

// 获取一级类目为积算仪的二级类目list
export function getThermalDeviceTypeList() {
  return fetch<{ list: DeviceType[] }>(
    `/api/v2/thermal/account/deviceType/integratorList`,
    {
      method: 'POST',
    },
    true
  );
}
