import { Button, Empty, Key, Modal, Select, TreeSelect, Form } from '@maxtropy/components';
import { Col, Divider, Row } from 'antd';
import dayjs from 'dayjs';
import { DateFormat, MonthFormat, isAllValueNull } from '../../utils';
import styles from './index.module.scss';
import PieCharts from './PieCharts';
import circlePng from '../../assets/circle.png';
import EnergyTable from './EnergyTable';
import SankeyCharts from './SankeyCharts';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import {
  EnergyFlowChartResponse,
  EnergyMediumListResponseListItem,
  EnergyUnitAggregateStandardCoalResponse,
  EnergyUnitTreeResponseListItem,
  getDownstreamEnergyFlowChart,
  getEnergyMediumList,
  getEnergyUnitAggregate,
  getEnergyUnitAggregateStandardCoal,
  getEnergyUnitTree,
} from '@/api/plustekSankey';
import { RadioType, getEmpty } from '../..';
import { formatMomRatio } from '../MediumSwipper/CardItem';
import { isNil } from 'lodash-es';
import type { DefaultOptionType } from 'rc-tree-select/es/TreeSelect';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  time: {
    fromTime: string;
    toTime: string;
  };
  energyUnitGroupId?: Key;
  radioType: RadioType;
  chartSelectedUnitId?: Key;
  setChartSelectedUnitId: (id?: Key) => void;
  selectedMediumId?: Key;
  isMedium: boolean;
}

function formatTreeData(data: EnergyUnitTreeResponseListItem[]): DefaultOptionType[] {
  if (data.length === 0) return [];
  return data.map(i => ({
    title: i.name,
    value: i.id,
    key: i.id,
    children: formatTreeData(i.children ?? []),
  }));
}

const UnitEnergyDetailModal: React.FC<Props> = ({
  open,
  setOpen,
  time,
  energyUnitGroupId,
  radioType,
  chartSelectedUnitId,
  setChartSelectedUnitId,
  selectedMediumId,
  isMedium,
}) => {
  const [form] = Form.useForm();
  const [unitTree, setUnitTree] = useState<DefaultOptionType[]>([]);
  const [mediumList, setMediumList] = useState<EnergyMediumListResponseListItem[]>([]);

  const energyUnitId = Form.useWatch('energyUnitId', form);
  const energyMediumId = Form.useWatch('energyMediumId', form);
  const [detail, setDetail] = useState<EnergyUnitAggregateStandardCoalResponse>();
  const [physicalUnitName, setPhysicalUnitName] = useState<string>();
  const [energyFlowChartData, setEnergyFlowChartData] = useState<EnergyFlowChartResponse>({
    nodes: [],
    links: [],
  });
  const [isMediumInModal, setIsMediumInModal] = useState<boolean>(false);

  useEffect(() => {
    setIsMediumInModal(!!isMedium);
  }, [isMedium]);

  useEffect(() => {
    if (chartSelectedUnitId) {
      form.setFieldsValue({
        energyUnitId: chartSelectedUnitId,
      });
    }
  }, [chartSelectedUnitId, form]);

  useEffect(() => {
    if (energyUnitGroupId) {
      // 获取用能单元树
      getEnergyUnitTree(energyUnitGroupId).then(res => {
        setUnitTree(formatTreeData(res.list ?? []));

        if ((res.list ?? []).length > 0 && !chartSelectedUnitId) {
          form.setFieldsValue({
            energyUnitId: res.list[0].id,
          });
        }
      });
      // 获取介质列表
      getEnergyMediumList(energyUnitGroupId).then(res => {
        setMediumList(res.list);
        if ((res.list ?? []).length > 0) {
          form.setFieldsValue({
            energyMediumId: selectedMediumId,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitGroupId, form]);

  const formatDetailRes = (res: EnergyUnitAggregateStandardCoalResponse, isMediumInModal: Boolean) => {
    // 判断res里每一项是否为null或者为空数组
    const isAllEmpty = Object.values(res).every(i => (Array.isArray(i) && i.length === 0) || isNil(i));
    if (isAllEmpty) return;
    setDetail(res);
    setPhysicalUnitName(isMediumInModal ? res.physicalUnitName : 'kgce');
  };

  useEffect(() => {
    if (energyUnitId && energyMediumId && energyUnitGroupId && time) {
      setDetail(undefined);
      setPhysicalUnitName(undefined);

      const params = {
        energyUnitId,
        energyMediumId: energyMediumId === 'total' ? undefined : energyMediumId,
        energyUnitGroupId,
        ...time,
      };

      if (isMediumInModal) {
        // 单一介质
        getEnergyUnitAggregate(params).then(res => {
          formatDetailRes(res, isMediumInModal);
        });
      } else {
        // 综合能耗
        getEnergyUnitAggregateStandardCoal(params).then(res => {
          formatDetailRes(res, isMediumInModal);
        });
      }

      // 获取能流图数据
      getDownstreamEnergyFlowChart(params).then(res => {
        if (res) {
          if (isAllValueNull(res) || (res.links ?? []).length === 0) {
            setEnergyFlowChartData({
              nodes: [],
              links: [],
            });
          } else {
            setEnergyFlowChartData({
              nodes: (res.nodes ?? [])
                .map(i => ({
                  ...i,
                  name: i.key,
                }))
                .filter(i => !isNil(i.value) && i.value !== 0),
              links: res.links.filter(i => !isNil(i.value) && i.value !== 0),
            });
          }
        }
      });
    }
  }, [isMediumInModal, energyUnitId, energyMediumId, time, energyUnitGroupId]);

  const mediumOptions = useMemo(() => {
    const res = (mediumList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
    if (res.length > 0) {
      res.unshift({
        label: '综合能耗',
        value: 'total',
      });
    }
    return res;
  }, [mediumList]);

  const mediumName = useMemo(() => {
    return mediumOptions.find(item => item.value === energyMediumId)?.label;
  }, [energyMediumId, mediumOptions]);

  const onClose = () => {
    setOpen(false);
    setChartSelectedUnitId(undefined);
  };

  return (
    <Modal
      size="big"
      open={open}
      title="单元能流详情"
      onCancel={onClose}
      style={{
        minWidth: '1024px',
      }}
      footer={
        <Button type="primary" onClick={onClose}>
          确定
        </Button>
      }
    >
      <Form form={form} isOrigin>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item label="用能单元选择" name="energyUnitId">
              <TreeSelect
                showSearch
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                allowClear={false}
                treeDefaultExpandAll
                onChange={() => {
                  form.setFieldsValue({
                    energyMediumId: 'total',
                  });
                  setIsMediumInModal(false);
                }}
                treeData={unitTree}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="介质选择" name="energyMediumId">
              <Select
                options={mediumOptions}
                onChange={v => {
                  setIsMediumInModal(v !== 'total');
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.infoLine}>
        <p>
          时间：
          <span>
            {radioType === RadioType.DAY
              ? `${dayjs(time.fromTime).format(DateFormat)} 至 ${dayjs(time.toTime).format(DateFormat)}`
              : `${dayjs(time.fromTime).format(MonthFormat)} 至 ${dayjs(time.toTime).format(MonthFormat)}`}
          </span>
        </p>
        <p>
          {mediumName}：
          <span>{isNil(detail) || isNil(detail.value) ? '--' : `${detail.value.toFixed(2)}${physicalUnitName}`}</span>
        </p>
        <p>
          环比：<span>{formatMomRatio(detail?.momRatio)}</span>
        </p>
      </div>
      {Object.values(energyFlowChartData).every(i => i.length === 0) && isNil(detail) ? (
        getEmpty()
      ) : (
        <div className={styles.detail}>
          {!isMediumInModal && (
            <div className={styles.pieInfo}>
              <p className={styles.title}>能源构成</p>
              <img alt="" src={circlePng} />
              <PieCharts data={detail?.energyMediumData ?? []} physicalUnitName={physicalUnitName} />
            </div>
          )}
          {!isMediumInModal && <Divider type="vertical" className={styles.divider} />}

          <div className={classNames(isMediumInModal ? styles.mediumWidth : null, styles.sankeyInfo)}>
            <p className={styles.title}>下游用能数据</p>
            {energyFlowChartData.links.length === 0 && energyFlowChartData.nodes.length === 0 ? (
              <Empty style={{ height: 177 }} />
            ) : (
              <SankeyCharts isMedium={isMediumInModal} data={energyFlowChartData} physicalUnitName={physicalUnitName} />
            )}

            <EnergyTable physicalUnitName={physicalUnitName} data={detail?.downstreamConsumptions ?? []} />

            <p className={styles.energyNumInfo}>
              {isNil(detail) || isNil(detail.loss) || detail.loss === 0 ? null : detail.loss < 0 ? (
                <>
                  未知能耗{' '}
                  <span>
                    {Math.abs(Number(detail.loss.toFixed(2)))}
                    {physicalUnitName}
                  </span>
                </>
              ) : (
                <>
                  用能损耗{' '}
                  <span>
                    {Math.abs(Number(detail.loss.toFixed(2)))}
                    {physicalUnitName}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UnitEnergyDetailModal;
