import ReactEcharts from 'echarts-for-react';
import { V2OeeApplyGetOeeCustomPostResponse } from '@maxtropy/device-customer-apis-v2';
import SubTitle from '../subTitle';
import { isEmpty } from 'lodash-es';
import { Empty } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';

const DeviceStopTime = ({
  data,
  date,
}: {
  data: V2OeeApplyGetOeeCustomPostResponse['shutdownTimeList'];
  date: Dayjs;
}) => {
  const getChartOption = () => {
    return {
      grid: {
        left: 10,
        right: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(255,255,255,0.1)',
          },
        },
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        padding: [8, 12, 8, 12],
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 12,
          lineHeight: 20,
        },
        formatter: (tooltipData: any) => {
          return `
              <div style='color: rgba(255,255,255,0.65)'>${dayjs(date).format('YYYY-MM-DD') ?? '--'}</div>
              <div >${tooltipData[0].name}</div>
              <div style='width: 174px;display: flex;align-items: center;margin-bottom: 4px;'><span style='display:inline-block;width:8px;height:8px;background:${
                tooltipData[0]?.color
              };border-radius:50%;margin-right:6px'></span> ${
            tooltipData[0]?.seriesName ?? '--'
          }<span style='flex:1;text-align: right'>${tooltipData[0]?.value ?? '--'}h</span></div></div>
            `;
        },
      },
      legend: {
        right: 0,
        top: 6,
        bottom: 8,
        icon: 'rect',
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        itemWidth: 12,
        itemHeight: 12,
      },
      xAxis: {
        type: 'category',
        data: data?.map(i => i.deviceName) ?? [],
        axisTick: {
          show: false,
        },
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 14,
        },
      },
      yAxis: {
        type: 'value',
        name: '%',
        nameGap: 8,
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
        axisLine: {},
        axisLabel: {
          margin: 8,
          color: 'rgba(255,255,255,0.85)',
          lineHeight: 20,
        },
      },
      dataZoom: [
        {
          type: 'slider',
          bottom: 20,
          height: 20,
          backgroundColor: 'rgba(226,227,229,0.5)',
          fillerColor: 'rgba(142,177,224,0.3)',
          textStyle: {
            fontSize: 10,
          },
        },
      ],
      series: [
        {
          data: data?.map(i => i.time),
          type: 'bar',
          name: '计划外停机时长',
          barWidth: 24,
          itemStyle: {
            color: '#FFCB47',
          },
        },
      ],
    };
  };

  return (
    <div>
      <SubTitle title="停机时长" />
      {isEmpty(data) ? (
        <Empty style={{ marginTop: 20 }} />
      ) : (
        <ReactEcharts
          style={{
            width: '100%',
            height: 356,
          }}
          option={getChartOption()}
          notMerge
          lazyUpdate={false}
        />
      )}
    </div>
  );
};

export default DeviceStopTime;
