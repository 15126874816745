import React, { Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Form, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Paging, Table, usePaging, EllipsisSpan, Input, Button, Select, Filter } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { useQuery } from '../../../utils/utils';
import { GatewayDevicePageRequest, getGatewayDevicePage } from '../../../api/gateway';
import { Device } from '../../../api/device';
import { getDeviceTypeTreeWithoutScene } from '../../../api/deviceType';
import styles from '../index.module.scss';

interface FilterParams {
  codeOrName?: string;
  tag?: string;
  primaryTypeId?: number;
  secondaryTypeId?: number;
}

export interface DeviceModalRef {
  selectedRows?: Device;
}

type SearchParams = Omit<
  GatewayDevicePageRequest,
  'page' | 'size' | 'edgeGatewayId' | 'iotProtocol' | 'codeOrName' | 'primaryTypeId' | 'secondaryTypeId' | 'tag'
>;

interface DeviceModalProps {
  iotProtocol?: IotProtocolType;
  deviceId?: number;
  edgeGatewayId?: number;
}

const DeviceModal = React.forwardRef(
  ({ iotProtocol, deviceId, edgeGatewayId }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
    const pagingInfo = usePaging();
    const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

    const [form] = Form.useForm();

    const [searchParams, setSearchParams] = useState<SearchParams>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<number | undefined>(deviceId);
    const [selectedRows, setSelectedRows] = useState<Device>();

    const { data: deviceTypeTreeData } = useQuery(
      useCallback(async () => {
        const res = await getDeviceTypeTreeWithoutScene({ flag: true });
        if (res?.deviceTypes) {
          return res?.deviceTypes[0];
        }
      }, [])
    );

    const { data, isLoading } = useQuery(
      useCallback(async () => {
        if (iotProtocol && deviceTypeTreeData) {
          const res = await getGatewayDevicePage({
            ...searchParams,
            primaryTypeId: deviceTypeTreeData.id,
            page: pageOffset,
            size: pageSize,
            iotProtocol,
            edgeGatewayId,
          });
          if (res) setTotalCount(res.total);
          return res?.list;
        }
      }, [iotProtocol, edgeGatewayId, deviceTypeTreeData, searchParams, pageOffset, pageSize, setTotalCount])
    );

    useImperativeHandle(ref, () => ({
      selectedRows,
    }));

    const onSelect = (record: Device) => {
      setSelectedRowKeys(record.id);
      setSelectedRows(record);
    };

    const onReset = () => {
      form.resetFields();
      form.submit();
      setPageOffset(1);
    };

    const onFinish = (val: FilterParams) => {
      const params: SearchParams = {
        codeOrName: val.codeOrName,
        secondaryTypeId: val.secondaryTypeId,
        tag: val.tag,
      };
      setSearchParams(params);
      setPageOffset(1);
    };

    const columns = [
      {
        title: '设备编号',
        dataIndex: 'code',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备名称',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '所属类目',
        dataIndex: 'typeName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备标签',
        dataIndex: 'tags',
        ellipsis: { showTitle: true },
        render: (v: string[]) => {
          const tags = v ? v.join(', ') : '';
          return <EllipsisSpan value={tags} />;
        },
      },
      {
        title: '所属组织',
        dataIndex: 'customerName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '操作',
        width: 100,
        render: (record: Device) => {
          return selectedRowKeys === record.id ? (
            <span>已选择</span>
          ) : (
            <Button type="link" onClick={() => onSelect(record)}>
              选择
            </Button>
          );
        },
      },
    ];

    return (
      <>
        <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
          <>
            <Col span={8}>
              <Form.Item name="codeOrName" label="编号/名称">
                <Input placeholder={'请输入编号或名称查询'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="primaryTypeId" label="一级类目">
                {deviceTypeTreeData?.name || '--'}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="secondaryTypeId" label="二级类目">
                <Select placeholder="请选择">
                  {deviceTypeTreeData &&
                    (deviceTypeTreeData.children || []).map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="tag" label="标签">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </>
        </Filter>
        <div className={styles.table_text}>
          <div className={styles.action} style={{ textAlign: 'right', margin: '10px 0' }}>
            <span className={styles.text} style={{ marginRight: 5 }}>
              点击前往
            </span>
            <Link to="/device/manage/device/create" target="_blank">
              添加设备
            </Link>
          </div>
        </div>
        <Table rowKey="id" loading={isLoading} columns={columns} dataSource={data} />
        <Paging pagingInfo={pagingInfo} />
      </>
    );
  }
);

export default DeviceModal;
