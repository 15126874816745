import { V2HvacDashboardDetailChartValuePostResponse } from '@maxtropy/device-customer-apis-v2';
import { DateBtn, ParamsOption } from './config';

export enum CenterTime {
  Yesterday = 1,
  LastMonth,
}

export enum BoardType {
  STATION = 1,
  NODE,
}

export const CenterTimeDisPlay = {
  [CenterTime.Yesterday]: '昨日',
  [CenterTime.LastMonth]: '上月',
};

export enum DatePickerType {
  MINUTE_1 = '1M',
  MINUTE_15 = '15M',
  HOUR = 'H',
  DAY = 'D',
  MONTH = 'M',
}

export const datePickerTypeDisplay = {
  [DatePickerType.MINUTE_1]: '1分钟',
  [DatePickerType.MINUTE_15]: '15分钟',
  [DatePickerType.HOUR]: '按小时',
  [DatePickerType.DAY]: '按日',
  [DatePickerType.MONTH]: '按月',
};

export enum IndicatorType {
  COP = 10000, // COP
  PowerConsumption = 1, // 用电量
  CoolingCapacity = 12, // 制冷量
  InstantaneousPower = 2, // 瞬时功率
}

export enum NodeType {
  COOLING_TOWER = 1,
  CHILLER,
  COOLING_WATER_PUMP,
  PRIMARY_CHILED_WATER_PUMP,
  SECOND_CHILED_WATER_PUMP,
  ICE_STORAGE_TANK,
  WATER_SEPARATOR,
  DECOUPLING_TANK,
  HYGROTHERMOGROGH,
  SUPPLY_WATER_PORT,
  RETURN_WATER_PORT,
}

export interface CurrentItemType {
  id?: IndicatorType;
  btnName?: string;
  unit?: string;
  name?: string; // tab名称
  dateBtn: DateBtn[];
  defaultSelectBtn?: DateBtn;
  getChartOption?: (value: ParamsOption) => any;
}

export type IndicatorItem = Exclude<V2HvacDashboardDetailChartValuePostResponse['indicatorList'], undefined>[number];

export type ChartItem = Exclude<V2HvacDashboardDetailChartValuePostResponse['chartList'], undefined>[number];
