import React, { Key, useEffect, useMemo } from 'react';
import { Empty, Tree } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Spin } from 'antd';
import styles from './index.module.scss';
import { apiV2CarbonAccountingUnitListPost } from '@maxtropy/device-customer-apis-v2';
import { useSearchParams } from 'react-router-dom';

interface Props {
  selectedAccountingUnitId?: string;
  setSelectedAccountingUnitId: (id: string) => void;
  setTreeRequestStatus: (boolean: boolean) => void;
}
const TabsSider: React.FC<Props> = ({
  selectedAccountingUnitId,
  setSelectedAccountingUnitId,
  setTreeRequestStatus,
}) => {
  const [urlSearchParams] = useSearchParams();
  let url_accountingUnitId = urlSearchParams.get('accountingUnitId') ?? undefined;

  useEffect(() => {
    if (url_accountingUnitId) {
      setSelectedAccountingUnitId(url_accountingUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_accountingUnitId]);

  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2CarbonAccountingUnitListPost({});
      return res.list;
    },
    {
      onFinally: () => {
        setTreeRequestStatus(true);
      },
    }
  );

  const treeData = useMemo(() => {
    if (!data) return [];
    return data.map(item => ({
      title: item.unitName,
      key: String(item.id),
    }));
  }, [data]);

  const onSelect = (selectedKeys: Key[]) => {
    setSelectedAccountingUnitId(selectedKeys[0] as string);
  };

  useEffect(() => {
    if (!selectedAccountingUnitId && treeData.length > 0) {
      setSelectedAccountingUnitId(treeData[0]?.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  return (
    <>
      <div className={styles.tree_box}>
        <Spin spinning={loading}>
          <Tree
            className={styles.treeStyle}
            selectedKeys={[selectedAccountingUnitId! ?? '']}
            blockNode
            showIcon
            treeData={treeData}
            onSelect={onSelect}
          />

          {treeData.length === 0 && <Empty style={{ marginTop: '100px' }} />}
        </Spin>
      </div>
    </>
  );
};

export default TabsSider;
