import { Space } from 'antd';
import { Ref, useEffect, useImperativeHandle, useState } from 'react';
import { AddOrRemoveType } from '../../../../../api/energyConsumption';
import React from 'react';
import { AddEnergyConsEquipmentModalProps } from './type';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DeviceModalContent from './components/DeviceModalContent';
import CircuitDeviceModalContent from './components/CircuitDeviceModalContent';
import {
  apiV2EnergyAnalysisUnitConfGroupLinkedDeviceAllPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost,
} from '@maxtropy/device-customer-apis-v2';
import { OuInfoProps } from '../AddChildOrEditModal';
import { Modal, Radio } from '@maxtropy/components';

export interface AddOrRemoveModalRef {
  selectedRowKeys: Array<number> | undefined;
}

enum EnergyDeviceType {
  DEVICE = 'device',
  CIRCUIT = 'circuit',
}

const energyDeviceTypeDisplay: Record<EnergyDeviceType, string> = {
  [EnergyDeviceType.DEVICE]: '按设备',
  [EnergyDeviceType.CIRCUIT]: '按回路',
};

const energyDeviceTypeModalTitleDisplay: Record<EnergyDeviceType, string> = {
  [EnergyDeviceType.DEVICE]: '设备选择',
  [EnergyDeviceType.CIRCUIT]: '添加能耗设备',
};

const options = [
  { label: energyDeviceTypeDisplay[EnergyDeviceType.DEVICE], value: EnergyDeviceType.DEVICE },
  { label: energyDeviceTypeDisplay[EnergyDeviceType.CIRCUIT], value: EnergyDeviceType.CIRCUIT },
];

const AddOrRemoveEnergyConsEquipmentModal = React.forwardRef(
  (props: AddEnergyConsEquipmentModalProps, ref: Ref<AddOrRemoveModalRef>) => {
    const { id, isAdd, visible, onOk, onCancel, mcidOptions, unitId } = props;
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

    const [ouItems, setOuItems] = useState<Array<OuInfoProps>>([]);

    const [value, setValue] = useState<EnergyDeviceType>(EnergyDeviceType.DEVICE);

    useEffect(() => {
      if (id) {
        apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost({
          energyUnitId: id,
        }).then(res => {
          setOuItems(res?.list ?? []);
        });
      }
    }, [id]);

    useEffect(() => {
      if (id) {
        if (isAdd) {
          // 查询已绑定的能耗设备
          apiV2EnergyAnalysisUnitConfGroupLinkedDeviceAllPost({
            energyUnitGroupId: id,
            deviceType: AddOrRemoveType.Add,
          }).then(res => {
            if (res) {
              setSelectedRowKeys(res.list ?? []);
            }
          });
        } else {
          // 查询需剔除的设备
          apiV2EnergyAnalysisUnitConfGroupLinkedDeviceAllPost({
            energyUnitGroupId: id,
            deviceType: AddOrRemoveType.Remove,
          }).then(res => {
            if (res) {
              setSelectedRowKeys(res.list ?? []);
            }
          });
        }
      }
    }, [isAdd, id]);

    useImperativeHandle(ref, () => ({ selectedRowKeys }));

    return (
      <>
        <Modal
          open={visible}
          title={
            <Space align="center">
              <span
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'var(--heading-color)',
                  marginRight: 10,
                }}
              >
                {energyDeviceTypeModalTitleDisplay[value]}
              </span>
              <Space align="center">
                <ExclamationCircleOutlined style={{ color: 'var(--warning-color)' }} />
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'ragb(255, 255, 255, 0.65)',
                  }}
                >
                  无运营单元权限不允许修改
                </span>
              </Space>
            </Space>
          }
          width={1200}
          destroyOnClose
          onOk={onOk}
          onCancel={() => {
            onCancel();
          }}
        >
          {isAdd ? (
            <Space direction="vertical" size={10} style={{ width: '100%' }}>
              <Radio.Group
                value={value}
                options={options}
                buttonStyle="solid"
                onChange={v => {
                  setValue(v.target.value);
                }}
                optionType="button"
              />
              {value === EnergyDeviceType.DEVICE && (
                <DeviceModalContent
                  id={id}
                  isAdd={isAdd}
                  ouItems={ouItems}
                  mcidOptions={mcidOptions}
                  selectKeys={selectedRowKeys}
                  onChangeSelectKeys={setSelectedRowKeys}
                />
              )}
              {value === EnergyDeviceType.CIRCUIT && (
                <CircuitDeviceModalContent
                  id={id}
                  isAdd={isAdd}
                  ouItems={ouItems}
                  mcidOptions={mcidOptions}
                  selectKeys={selectedRowKeys}
                  onChangeSelectKeys={setSelectedRowKeys}
                />
              )}
            </Space>
          ) : (
            <DeviceModalContent
              id={id}
              isAdd={isAdd}
              ouItems={ouItems}
              mcidOptions={mcidOptions}
              selectKeys={selectedRowKeys}
              onChangeSelectKeys={setSelectedRowKeys}
            />
          )}
        </Modal>
      </>
    );
  }
);

export default AddOrRemoveEnergyConsEquipmentModal;
