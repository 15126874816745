import { Form, Table, Input, Paging, usePaging, useAsync, CustomFilter } from '@maxtropy/components';
import { Cascader } from 'antd';
import { FC, Key, useEffect, useMemo, useState } from 'react';

import {
  AlrmPushDevice,
  AlarmPushDevicePageRequest,
  getAlarmPushDevicePage,
} from '../../../../api/deviceAlarmPushStrategy';
import { columns } from './util';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { getDeviceTypeTree } from '@/api/deviceType';

export interface DevicesModalProps {
  value?: AlrmPushDevice[];
  onChange?: (value: AlrmPushDevice[]) => void;
  ouId?: string;
  disabledIds?: Key[];
}

type SearchParams = Omit<AlarmPushDevicePageRequest, 'size' | 'page' | 'ouId'>;

interface FilterParams {
  name: string | undefined;
  deviceTypes: Array<Array<number>> | undefined;
}

const DevicesModal: FC<DevicesModalProps> = ({ value, onChange, ouId, disabledIds }) => {
  const [form] = Form.useForm();

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<AlrmPushDevice[]>([]);

  const deviceIds = useMemo(() => {
    return (value ?? []).map(i => i.deviceId);
  }, [value]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (ouId !== undefined) {
      setLoading(true);
      getAlarmPushDevicePage({ ...searchParams, ouId, page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setDevices(res.list);
        }
      });
    }
  }, [ouId, pageOffset, pageSize, searchParams, setTotalCount]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AlrmPushDevice[]) => {
      const rulesId = devices.map(i => i.deviceId);
      const buildDataMap = new Map<number, AlrmPushDevice>();
      (value ?? [])
        .filter(i => !rulesId.includes(i.deviceId))
        .forEach(i => {
          if (!buildDataMap.has(i.deviceId)) {
            buildDataMap.set(i.deviceId, i);
          }
        });
      selectedRows.forEach(i => {
        if (!buildDataMap.has(i.deviceId)) {
          buildDataMap.set(i.deviceId, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: AlrmPushDevice) => ({
      disabled: (disabledIds ?? []).includes(record.deviceId),
    }),
    selectedRowKeys: deviceIds,
  };

  const onFinish = (value: FilterParams) => {
    const deviceTypes = getChildNodesByParentIds(value.deviceTypes, deviceTypeData?.tree);
    setSearchParams({
      ...value,
      deviceTypes: deviceTypes,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      deviceTypes: undefined,
    });
    setPageOffset(1);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <CustomFilter
      colSpan={8}
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      onReset={onReset}
    >
      <Form.Item name="name" label="编号/名称">
        <Input placeholder="请输入编号或名称查询" />
      </Form.Item>
      <Form.Item name="deviceTypes" label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          multiple
          maxTagCount="responsive"
          showSearch={{ filter }}
          placeholder={'请选择所属类目'}
        />
      </Form.Item>
    </CustomFilter>
  );

  return (
    <>
      {filters}
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="deviceId"
        columns={columns}
        dataSource={devices}
        showSelectedCount
        selectedCount={(value ?? []).length}
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </>
  );
};

export default DevicesModal;
