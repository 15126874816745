import { getImportLogPage, ImportLogPageRes, ImportLogState } from '@/api/electricitySettlementRules';
import {
  Button,
  EllipsisSpan,
  getRealUrl,
  Modal,
  Paging,
  Table,
  useSearchParams,
  useUpdate,
} from '@maxtropy/components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { PageRequest } from '@/shared/const';

interface Props {
  batchEdgeBlogListOpen: boolean;
  setBatchEdgeBlogListOpen: (v: boolean) => void;
  refreshState: number;
}

const columns = [
  {
    title: '批量导入状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: ImportLogState, record: ImportLogPageRes) => {
      let value = `成功：${record.successCount} 失败：${record.failureCount}`;
      if (v === ImportLogState.IMPORTING) {
        value = '正在导入中';
      }
      if (v === ImportLogState.EXCEL_ERROR) {
        value = '导入表格错误';
      }
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '批量导入时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'createByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams extends PageRequest {}

const BatchEdgeBlogList: React.FC<Props> = ({ refreshState, batchEdgeBlogListOpen, setBatchEdgeBlogListOpen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount } = pagingInfo;
  const [data, setData] = useState<ImportLogPageRes[]>([]);
  const [update, updateFn] = useUpdate();

  useEffect(() => {
    setLoading(true);
    getImportLogPage({ page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, refreshState, update]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (v: string, record: ImportLogPageRes) => {
        return (
          <Button
            type="link"
            onClick={() => {
              window.open(getRealUrl(record.failureExcelSource));
            }}
            disabled={
              record.state === ImportLogState.IMPORTING ||
              record.state === ImportLogState.ALL_SUCCESS ||
              record.state === ImportLogState.EXCEL_ERROR
            }
          >
            下载失败数据
          </Button>
        );
      },
    },
  ];
  return (
    <Modal
      title="批量修改日志"
      size="large"
      onCancel={() => {
        setBatchEdgeBlogListOpen(false);
        updateFn();
      }}
      open={batchEdgeBlogListOpen}
      footer={null}
    >
      <Table
        rowKey="id"
        sticky
        loading={loading}
        scroll={{ y: 500 }}
        dataSource={data}
        columns={buildColumns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default BatchEdgeBlogList;
