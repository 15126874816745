import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import styles from './index.module.scss';
import { DefaultOptionType } from 'rc-select/es/Select';
import { Select } from '@maxtropy/components';

export interface CascadingMultipleData {
  label: string;
  value: number;
  child: Array<CascadingMultipleData> | undefined;
}

export interface CascadingMultipleSelector {
  labels: Array<string> | undefined;
  data: Array<CascadingMultipleData> | undefined;
  onChange?: (val: Array<Array<number>>) => void;
  value?: Array<Array<number>>;
  disabled?: boolean;
  size?: string;
  mode?: Array<'multiple' | 'tags' | 'single'>;
}

function deDuplication<T>(arr: Array<T>, key: keyof T) {
  let signMap = new Map<unknown, boolean>();
  const initVal: Array<T> = [];
  return arr.reduce((preVal, curVal) => {
    if (!signMap.get(curVal[key])) {
      signMap.set(curVal[key], true);
      preVal.push(curVal);
    }
    return preVal;
  }, initVal);
}

function getOption(
  data: Array<CascadingMultipleData>,
  index: number,
  values: Map<number, Array<CascadingMultipleData>>
): Array<CascadingMultipleData> | undefined {
  if (index === 0) return data;
  const value = values
    .get(index - 1)
    ?.filter(item => item.child)
    .map(item => item.child)
    .flat() as Array<CascadingMultipleData> | undefined;
  return value && deDuplication<CascadingMultipleData>(value, 'value');
}

const CascadingMultipleSelector: React.FC<CascadingMultipleSelector> = ({
  labels,
  data,
  onChange,
  value,
  disabled,
  mode,
  size,
}) => {
  const [values, setValues] = useState<Map<number, Array<CascadingMultipleData>>>(new Map());

  const onChangeSelect = (option: DefaultOptionType | DefaultOptionType[], index: number) => {
    const valueMap = new Map<number, Array<CascadingMultipleData>>();
    values.forEach((value, key) => valueMap.set(key, value));
    if (option && option.length) {
      valueMap.set(index, option as Array<CascadingMultipleData>);
    } else {
      valueMap.delete(index);
    }
    Array.from(valueMap.keys())
      .filter(k => k > index)
      .forEach(k => valueMap.delete(k));
    setValues(valueMap);
    let onChangeValue: Array<Array<number>> = [];
    valueMap.forEach(value => onChangeValue.push(value.map(item => item.value)));
    onChange && onChange(onChangeValue);
  };

  useEffect(() => {
    if (value && data) {
      const valuesMap = new Map<number, Array<CascadingMultipleData>>();
      let parentNode: Array<CascadingMultipleData> | undefined = data;
      value.forEach((item, index) => {
        if (parentNode) {
          const target = deDuplication<CascadingMultipleData>(
            parentNode.filter(child => item.includes(child.value)),
            'value'
          );
          valuesMap.set(index, target!);
          parentNode = target
            .filter(item => item.child)
            .map(item => item.child!)
            .flat();
          setValues(valuesMap);
        }
      });
    }
  }, [value, data]);

  return (
    <div className={styles.content}>
      {labels ? (
        labels.map((item, index) => {
          const _mode = (mode?.[index] ? (mode?.[index] === 'single' ? undefined : mode?.[index]) : 'multiple') as
            | 'multiple'
            | 'tags'
            | undefined;
          if (size === 'big') {
            return (
              <Col span={8} key={index}>
                <div key={item} className={styles.inline}>
                  <div className={styles.label}>
                    <div className={styles.text}>{item}</div>
                    <div className={styles.colon}>:</div>
                  </div>

                  <Select
                    disabled={disabled}
                    options={data ? getOption(data, index, values) : undefined}
                    mode={_mode}
                    onChange={(_, option: DefaultOptionType | DefaultOptionType[]) =>
                      onChangeSelect(Array.isArray(option) ? option : option ? [option] : [], index)
                    }
                    value={values.get(index)?.map(item => item.value)}
                    placeholder={'请选择'}
                    allowClear
                  />
                </div>
              </Col>
            );
          }
          return (
            <div key={item} className={styles.inline}>
              <div className={styles.label}>
                <div className={styles.text}>{item}</div>
                <div className={styles.colon}>:</div>
              </div>

              <Select
                disabled={disabled}
                options={data ? getOption(data, index, values) : undefined}
                mode={_mode}
                onChange={(_, option: DefaultOptionType | DefaultOptionType[]) =>
                  onChangeSelect(Array.isArray(option) ? option : option ? [option] : [], index)
                }
                value={values.get(index)?.map(item => item.value)}
                placeholder={'请选择'}
                style={{ width: 200 }}
                allowClear
              />
            </div>
          );
        })
      ) : (
        <div />
      )}
    </div>
  );
};

export default CascadingMultipleSelector;
