import { useEffect, useRef } from 'react';
import { CellView, Graph } from '@antv/x6';
import ResizeObserver from 'rc-resize-observer';
import styles from '../index.module.scss';
import { IconKey, NodeType } from '../utils';

const TopolopyGraph = ({ data }: { data: string }) => {
  const graph = useRef<Graph>();
  const refContainer = useRef<HTMLDivElement>();

  useEffect(() => {
    graph.current = new Graph({
      container: refContainer.current!,
      panning: true,
      connecting: {
        router: 'manhattan',
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },
      },
      interacting: function (cellView: CellView) {
        return false;
      },
    });

    graph.current.zoom(-0.5);
    // 回显
    const nodeJson = JSON.parse(data);
    graph.current.fromJSON(nodeJson as any);
    const nodes = graph.current?.getNodes();
    nodes.forEach(node => {
      const { type }: { type: NodeType } = node.getData();
      const ports = node.getPorts();
      ports.forEach(item => {
        node.setPortProp(item.id!, 'attrs/rect/style/visibility', 'hidden');
      });
      node.updateData({
        iconKey: IconKey[type],
      });
    });
  }, [data]);

  return (
    <ResizeObserver
      onResize={({ width, height }) => {
        graph.current?.resize(width, height);
      }}
    >
      <div style={{ height: '100%' }}>
        <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
      </div>
    </ResizeObserver>
  );
};

export default TopolopyGraph;
