import { Button, Empty, FormTitle, Key } from '@maxtropy/components';
import MediumComparison from '../MediumComparison';
import { useEffect, useState } from 'react';
import { Col, Divider, Row, Spin } from 'antd';
import LineEcharts from '../LineEcharts';

import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';
import {
  V2EnergyOptimizationProjectEnergyMediumDataPostResponse,
  apiV2EnergyOptimizationProjectEnergyMediumDataPost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  energyOptimizationProjectId: Key;
  isShowRecent: boolean;
}

export type EnergyAnalysisOptimizeEchartsRes = Exclude<
  V2EnergyOptimizationProjectEnergyMediumDataPostResponse['list'],
  undefined
>[number];

const EnergyComparison: React.FC<Props> = ({ energyOptimizationProjectId, isShowRecent }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<EnergyAnalysisOptimizeEchartsRes[]>([]);

  const hasDetail = useHasPermission(PermissionsType.B_OPTIMIZEANALYSISBUTTON);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2EnergyOptimizationProjectEnergyMediumDataPost({ energyOptimizationProjectId })
      .then(res => {
        if (res && res.list && res.list.length > 0) {
          const filterData = res.list.filter(
            item =>
              !(
                isNil(item.energyBeforeOptimization) &&
                isNil(item.energyAfterOptimizationData) &&
                isNil(item.energyRecentlyData)
              )
          );
          setData(filterData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [energyOptimizationProjectId]);

  return (
    <div style={{ marginTop: 10, padding: 20, overflowY: 'auto', height: 'calc(100vh - 320px)' }}>
      <FormTitle
        title="优化后分段对比"
        style={{ marginBottom: 18 }}
        extraContent={
          hasDetail && (
            <Button
              type="primary"
              onClick={() => {
                navigate(`/energy/analysis/optimizeAnalysis/detail/${energyOptimizationProjectId}`);
              }}
            >
              优化明细
            </Button>
          )
        }
      ></FormTitle>

      <Spin spinning={loading}>
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return (
              <React.Fragment key={item.energyMediumId}>
                <Row>
                  <Col span={18}>
                    <LineEcharts isShowRecent={isShowRecent} data={item} />
                  </Col>
                  <Col span={6}>
                    <MediumComparison isShowRecent={isShowRecent} data={item} />
                  </Col>
                </Row>
                {index !== data.length - 1 && <Divider style={{ borderColor: 'rgba(255,255,255,0.17)' }} />}
              </React.Fragment>
            );
          })
        ) : (
          <Empty
            style={{
              marginTop: 150,
            }}
          />
        )}
      </Spin>
    </div>
  );
};

export default EnergyComparison;
