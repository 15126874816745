import React from 'react';
import { Col } from 'antd';
import { Form, Input } from '@maxtropy/components';

const FormItem: React.FC = () => {
  return (
    <>
      <Col span={8}>
        <Form.Item
          name="alarmCode"
          label="边缘上报的报警编号"
          rules={[
            { required: true, message: '请输入边缘上报的报警编号' },
            { max: 20, message: '最多输入二十个字' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name="faultCode"
          label="故障代码"
          rules={[
            { required: true, message: '请输入故障代码' },
            { max: 20, message: '最多输入二十个字' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
    </>
  );
};

export default FormItem;
