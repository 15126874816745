import { useState, useEffect } from 'react';
import { Row, Col, List, Space, Typography } from 'antd';
import { Table, EllipsisSpan, Empty, Tag } from '@maxtropy/components';
import {
  UnitConfGroupDeviceType,
  UnitConfGroupDeviceTypeDisplay,
  UnitConfGroupSettingViewRecord,
} from '@/api/energyConsumption';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import classNames from 'classnames';
import {
  V2EnergyAnalysisUnitConfGroupHisPostResponse,
  apiV2EnergyAnalysisUnitConfGroupHisPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost,
  apiV2EnergyAnalysisUnitConfGroupSettingViewPost,
} from '@maxtropy/device-customer-apis-v2';
import { OuInfoProps } from '../AddChildOrEditModal';

type HistoryRecordModalProps = {
  confGroupId: string;
  unitId: string;
};

export type UnitConfGroupHistoryRecordResponse = Exclude<
  V2EnergyAnalysisUnitConfGroupHisPostResponse['list'],
  undefined
>[number];

// 用能单元历史记录
export default function HistoryRecordModalContent(props: HistoryRecordModalProps) {
  const { confGroupId } = props;
  const [selectedRecordTimeId, setSelectedRecordTimeId] = useState<number | undefined>(undefined);
  const [records, setRecords] = useState<UnitConfGroupHistoryRecordResponse[]>([]);
  const [unitConfGroupSettingView, setUnitConfGroupSettingView] = useState<UnitConfGroupSettingViewRecord[]>([]);
  const [ouList, setOuList] = useState<OuInfoProps[]>([]);

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: { showTitle: true },
      render: (v: UnitConfGroupDeviceType) => <EllipsisSpan value={UnitConfGroupDeviceTypeDisplay[v]} />,
    },
    {
      title: '计量类别',
      dataIndex: 'meterTypeName',
      ellipsis: { showTitle: true },
      render: (v: string, record: UnitConfGroupSettingViewRecord) => (
        <EllipsisSpan
          value={
            v ? (record.firstClassTypeId === 5000 ? v : `${record.meterTypeParentName}-${record.meterTypeName}`) : '--'
          }
        />
      ),
    },
    {
      title: '计量属性',
      dataIndex: 'meterAttributeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  // 查询左侧时间
  useEffect(() => {
    apiV2EnergyAnalysisUnitConfGroupHisPost({ energyUnitGroupId: confGroupId }).then(res => {
      setRecords(res.list ?? []);
    });
  }, [confGroupId]);

  // 默认选中第一条数据
  useEffect(() => {
    if (records.length) {
      setSelectedRecordTimeId(records?.[0]?.id);
    }
  }, [records]);

  // 查询右侧用能计量设置
  useEffect(() => {
    if (selectedRecordTimeId) {
      apiV2EnergyAnalysisUnitConfGroupSettingViewPost({ energyUnitGroupId: selectedRecordTimeId.toString() }).then(
        res => {
          setUnitConfGroupSettingView((res.list ?? []) as UnitConfGroupSettingViewRecord[]);
        }
      );
    }
  }, [selectedRecordTimeId]);

  // 关联的 ou
  useEffect(() => {
    if (selectedRecordTimeId) {
      apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost({
        energyUnitId: selectedRecordTimeId,
      }).then(res => {
        setOuList(res?.list ?? []);
      });
    }
  }, [selectedRecordTimeId]);

  return (
    <Row gutter={24}>
      <Col span={7}>
        <List
          className={styles.recordList}
          header={<div>生效时间</div>}
          bordered
          dataSource={records}
          renderItem={(item: UnitConfGroupHistoryRecordResponse) => (
            <List.Item
              key={item.id}
              className={classNames(styles.recordListItem, { [styles.active]: item.id === selectedRecordTimeId })}
              onClick={() => {
                setSelectedRecordTimeId(item.id);
              }}
            >
              <span>
                {dayjs(item.effectiveDate).format('YYYY-MM-DD')} ~{' '}
                {item.endDate ? dayjs(item.endDate).format('YYYY-MM-DD') : '无期限'}
              </span>
            </List.Item>
          )}
        />
      </Col>
      <Col span={17}>
        {selectedRecordTimeId ? (
          <Space direction="vertical" size="middle">
            <Space size="middle">
              <span>单元名称:</span>
              <Typography.Link>{records.filter(r => r.id === selectedRecordTimeId)?.[0]?.name}</Typography.Link>
            </Space>
            <Space size="middle" align="start">
              <span style={{ wordBreak: 'keep-all' }}>包含运营单元:</span>
              <Space wrap>
                {ouList.map(ou => (
                  <Tag>{ou?.name}</Tag>
                ))}
              </Space>
            </Space>
            <Table columns={columns} dataSource={unitConfGroupSettingView} />
          </Space>
        ) : (
          <Empty />
        )}
      </Col>
    </Row>
  );
}
