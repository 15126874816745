import {
  CreateUETEMTProcessResponse,
  CreateUETEMTResponse,
  getUetEnergyMediumSceneList,
  UetEnergyMediumSceneListResponse,
} from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import { Button, Form, Input, Modal, Select, ShowInput, Tag, useTenantPermissions } from '@maxtropy/components';
import { Col, FormInstance, Row, Space } from 'antd';
import { StoreValue } from 'antd/es/form/interface';
import { RuleObject } from 'antd/lib/form';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EnergyWorkingNameRegex } from '../../../utils';

interface BasicInfoFormProps {
  form: FormInstance;
  isEdit?: boolean;
  eMTBasicInfo?: CreateUETEMTResponse;
  createdProcessNumber?: CreateUETEMTProcessResponse[];
  nameIsEdit: boolean;
  setNameIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const BasicInfoForm: FC<BasicInfoFormProps> = props => {
  const { eMTBasicInfo, createdProcessNumber, nameIsEdit, setNameIsEdit } = props;
  const permission = useTenantPermissions();
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const [energyWorkingSceneList, setEnergyWorkingSceneList] = useState<UetEnergyMediumSceneListResponse[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUetEnergyMediumSceneList().then(setEnergyWorkingSceneList); // 能源介质场景下拉框
  }, []);

  // 场景下拉options
  const energyWorkingSceneOptions = useMemo(() => {
    if (energyWorkingSceneList && energyWorkingSceneList.length !== 0) {
      return energyWorkingSceneList.map(i => ({ label: i.sceneName, value: i.id }));
    }
  }, [energyWorkingSceneList]);

  // 判断是否有节点，没有节点添加则弹窗提示
  const onCheckHasProcess = () => {
    if (!createdProcessNumber?.length) {
      Modal.error({
        content: `当前拓扑预览未生成，请完成节点添加后预览！`,
      });
    } else {
      window.open(`/energy/basic/uet/edit/${id}/${routerKey}/viewEnergyWorkingTopo/${eMTBasicInfo!.id}`, '_blank');
    }
  };

  // 是否有未保存的节点
  const onCheckIsUnSavedProcess = () => {
    if (!nameIsEdit) {
      navigate(`/energy/basic/uet/edit/${id}/${routerKey}`);
    } else {
      Modal.confirm({
        title: null,
        content: '当前拓扑未保存，离开页面会丢失相关信息，请确认是否离开。',
        okText: '离开',
        onOk: () => {
          navigate(`/energy/basic/uet/edit/${id}/${routerKey}`);
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={7}>
          <Form.Item
            name="name"
            label="能源介质拓扑名称"
            rules={[
              { required: true, message: '请输入能源介质拓扑名称' },
              { max: 15, message: '最多输入15个字符！' },
              { pattern: /^[^/:*?"<>|\\]*$/g, message: '提示：禁用/、\\、:、*、?、"、<、>、|符号' },
            ]}
          >
            {!nameIsEdit ? <ShowInput /> : <Input placeholder="请输入能源介质拓扑名称" />}
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            name={eMTBasicInfo ? undefined : 'energyMediumSceneId'}
            label="能源介质场景"
            rules={[{ required: true, message: '请选择能源介质场景' }]}
          >
            {eMTBasicInfo ? (
              <ShowInput value={eMTBasicInfo.energyMediumSceneName} />
            ) : (
              <Select
                placeholder="请选择能源介质场景"
                options={energyWorkingSceneOptions}
                showSearch
                optionFilterProp="label"
              />
            )}
          </Form.Item>
        </Col>
        {eMTBasicInfo && eMTBasicInfo.code && (
          <Col span={6}>
            <Form.Item label="能源介质拓扑编号">
              <ShowInput
                value={
                  <Tag border="solid" color="#177DDC">
                    {eMTBasicInfo.code}
                  </Tag>
                }
              />
            </Form.Item>
          </Col>
        )}
        <Col span={4} style={{ position: 'absolute', top: 5, right: 20 }}>
          <Space size={8} className="sticky-footer" style={{ zIndex: 99 }}>
            {eMTBasicInfo && eMTBasicInfo.id && (permission ?? []).includes(PermissionsType.B_EMTPREVIEW) && (
              <Button type="primary" onClick={() => onCheckHasProcess()}>
                预览
              </Button>
            )}
            {nameIsEdit && (
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            )}
            {!nameIsEdit && (
              <Button type="primary" onClick={() => setNameIsEdit(true)}>
                编辑
              </Button>
            )}
            <Button onClick={() => onCheckIsUnSavedProcess()}>返回</Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default BasicInfoForm;

export const nameValidator = (_: RuleObject, name: StoreValue) => {
  if (EnergyWorkingNameRegex().test(name)) {
    return Promise.reject(new Error('提示：禁用/、\\、:、*、?、"、<、>、|符号，长度1位到15位之间。'));
  } else {
    return Promise.resolve();
  }
};
