import styles from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Graph } from '@antv/x6';
import ResizeObserver from 'rc-resize-observer';
import { DagreLayout } from '@antv/layout';
import { register } from '@antv/x6-react-shape';
import { isEmpty, isNil, uniqBy } from 'lodash-es';
import {
  V2StationSignboardTopologyPostResponse,
  apiV2StationSignboardTopologyDataPost,
  apiV2StationSignboardTopologyPost,
} from '@maxtropy/device-customer-apis-v2';
import PipeNode from '../PipeNode';
import GroupNode from '../GroupNode';
import {
  AlarmNode,
  HoverKey,
  IndicatorKey,
  IndicatorKeyName,
  LinkItem,
  MachineType,
  PipeEdgeItem,
  PipeNodeItem,
  TopoNodeItem,
} from '../../utils/const';
import { EnergyWorkingProcessType } from '@/api/uet';
import { IconKey, NodeType } from '@/pages/UET/Edit/GasGenerationStation/utils';
import { Spin } from 'antd';
import { useUpdate } from '@maxtropy/components';
import DescNode from '../DescNode';

export interface MiddleGraphProps {
  existIndex: boolean;
  stationId?: number;
  stationCode?: string;
  type: MachineType;
  alarmNodes?: AlarmNode[];
}

interface Size {
  width: number;
  height: number;
}

interface ColorItem {
  energyMediumId: number;
  energyMediumName: string;
  color: string;
}

type Coordinate = [number, number];

// 注册管网节点
register({
  shape: 'custom-pipe-node',
  effect: ['data'],
  component: PipeNode,
});

// 注册管网节点
register({
  shape: 'custom-desc-node',
  effect: ['data'],
  component: DescNode,
});

// 注册群组节点
register({
  shape: 'custom-group-node',
  effect: ['data'],
  component: GroupNode,
});

export type PipeTopoItem = Exclude<V2StationSignboardTopologyPostResponse['rightNodes'], undefined>[number];

const colors = [
  '#52E7FF',
  '#FF477B',
  '#FFCB47',
  '#16DD8E',
  '#CE90D1',
  '#2D8DFF',
  '#ABD335',
  '#FF9247',
  '#854ECA',
  '#63BC7F',
];

const MiddleGraph: React.FC<MiddleGraphProps> = ({ stationId, existIndex, stationCode, type, alarmNodes }) => {
  const [size, setSize] = useState<Size>();
  const graph = useRef<Graph>();
  const refContainer = useRef<HTMLDivElement>();
  const [loading, setLoading] = useState<boolean>(false);
  const [colorList, setColorList] = useState<ColorItem[]>([]);
  const [update, updateFn] = useUpdate();
  const timer = useRef<number>();

  // 每隔1min执行一次请求
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      getMonitorData();
      updateFn();
    }, 60000);

    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, alarmNodes, stationId, type]);

  // 初始化画布
  useEffect(() => {
    if (!refContainer.current) return;
    graph.current = new Graph({
      container: refContainer.current!,
      // autoResize: true,
      mousewheel: true,
      panning: true,
      interacting: false,

      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 60,
          },
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isNil(type) || isNil(stationId)) return;

    if (graph.current) {
      graph.current.clearCells();
    }
    setLoading(true);
    setColorList([]);
    apiV2StationSignboardTopologyPost({ id: stationId, type: type })
      .then(res => {
        const topoJson = JSON.parse(res.leftNodes ?? '{}');
        drawData(topoJson, res.rightNodes, res.links ?? []);
        getMonitorData();
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, stationId]);

  useEffect(() => {
    drawAlarmData(alarmNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alarmNodes]);

  const drawData = (topoData: any, pipeData?: PipeTopoItem[], linkData?: LinkItem[]) => {
    if (isNil(graph.current)) return;
    // 绘制左侧拓扑
    if (!isEmpty(topoData)) {
      graph.current.fromJSON({ ...topoData });

      const topoNodes = graph.current.getNodes();
      topoNodes.forEach(node => {
        const nodePrevData = node.getData();
        node?.updateData({
          ...nodePrevData,
          iconKey: IconKey[nodePrevData.type as NodeType],
          stationId,
          stationCode,
          stationType: type,
          realTime: true,
        });
      });
    }

    // 绘制右侧管网
    if (!isNil(pipeData)) {
      const groups = (pipeData ?? []).map(k => k.pipeNodes ?? []).flat();
      const nodeGroups = uniqBy(groups, 'id');
      if (!nodeGroups.length) return;

      const { width: boxWidth, x: boxX } = graph.current?.getContentArea();
      const nodesPositions = graph.current.getNodes().map(item => item.getPosition());

      const leftX = Math.min(...nodesPositions.map(k => k.x));
      const topY = Math.min(...nodesPositions.map(k => k.y));

      graph.current.positionPoint({ x: leftX, y: topY }, 30, 20);

      let newNodeJson = graph.current?.toJSON();

      // 右侧起始x坐标
      // +60 连线最小长度 +60群组内部padding左边间距 +60避免贴的太近
      const rightBeiginX = boxX + boxWidth + 60 + 60 + 60;
      let endPosition: Coordinate = [rightBeiginX, topY + 60]; // +60 去掉群组内部padding上边间距

      for (let i = 0; i < nodeGroups.length; i++) {
        const item = nodeGroups[i];
        const beginPosition: Coordinate = [rightBeiginX, i === 0 ? endPosition[1] : endPosition[1] + 80]; // 非第一个群组+80的群组上下间距
        const dagreLayout = new DagreLayout({
          begin: beginPosition,
          type: 'dagre',
          align: undefined,
          rankdir: 'LR',
          controlPoints: false,
          ranksep: 100,
          nodesepFunc: (node: any) => {
            return 20;
          },
        });

        const newEdges = getDisplayEdges(item.nodes, item.pointEdges);

        const data = {
          nodes: getPipeNodes(item.nodes),
          edges: getPipeEdges(newEdges),
        };
        const model = dagreLayout.layout(data);

        // 数据合并渲染
        graph.current?.fromJSON({ ...newNodeJson, ...model });
        // 重新绘制管网边
        drawPipeEdges(newEdges);
        // 绘制群组
        endPosition = createGroupNode(model, beginPosition, item.id, item.topologyName);

        newNodeJson = graph.current?.toJSON() ?? ([] as any);
      }
    }

    const nodes = graph.current?.getNodes();
    // 绘制两侧连线
    linkData?.forEach(item => {
      const sourceNode = graph.current?.getCellById(item.nodeId!);
      const targetNode = (nodes ?? []).find((p: { id: string }) => p.id === String(item.processId));

      if (sourceNode && targetNode) {
        const sourceNodeBox = sourceNode?.getBBox();
        const targetNodeBox = targetNode?.getBBox();
        const targetPorts = targetNode?.getPortsPosition('in');
        const targetPortPosition = targetPorts?.['in-port-' + item.targetId]?.position;

        const sourcePortPosition = {
          x: sourceNodeBox?.x + sourceNodeBox.width,
          y: sourceNodeBox?.y + sourceNodeBox?.height / 2,
        };

        // 路径点1
        const midPoint1 = {
          x: (sourcePortPosition!.x + targetNodeBox!.x) / 2,
          y: sourcePortPosition!.y,
        };

        const midPoint2 = {
          x: (sourcePortPosition!.x + targetNodeBox!.x) / 2,
          y: targetNodeBox!.y + targetPortPosition!.y,
        };

        const nodeColor = getMediumColorItem(item.energyMediumId!, item.energyName);

        const linkEdge = graph.current?.addEdge({
          source: { ...sourcePortPosition },
          target: { cell: String(item.processId), port: 'in-port-' + item.targetId! },
          vertices: [midPoint1, midPoint2],
          attrs: {
            line: {
              stroke: nodeColor,
              strokeWidth: 1,
              strokeDasharray: 6,
              targetMarker: 'classic',
              class: styles.antLine,
            },
          },
        });

        const entryVal = `${item?.sum?.toFixed(2) ?? '--'}${item?.unit ?? ''}`;
        linkEdge?.appendLabel({
          attrs: {
            label: {
              fill: nodeColor,
              fontSize: 12,
            },
            body: {
              fill: 'rgba(255,255,255,0)',
            },
            text: {
              text: entryVal,
              textAnchor: 'right',
            },
          },
          position: {
            offset: {
              y: -10,
            },
            distance: -60,
          },
        });
      }
    });

    graph.current.zoomToFit({
      padding: {
        left: 30,
        top: 20,
        right: 30,
        bottom: 20,
      },
      minScale: 0.4,
      maxScale: 1,
      preserveAspectRatio: true,
    });
  };

  const getMonitorData = () => {
    if (!isNil(type) && !isNil(stationId)) {
      apiV2StationSignboardTopologyDataPost({ id: stationId, type }).then(res => {
        drawMonitorData(res.list ?? []);
      });
    }
  };

  // 绘制监控数据
  const drawMonitorData = (topoMonitorData: TopoNodeItem[] = []) => {
    if (isNil(graph.current)) return;
    const topoNodes = graph.current.getNodes();

    topoNodes.forEach(node => {
      const { shape, id } = node.getProp();
      if (shape === 'custom-desc-node' && id) {
        graph.current?.removeNode(id);
        return;
      }
      if (shape === 'custom-pipe-node' || shape === 'custom-group-node') {
        return;
      }
      const nodePrevData = node.getData();
      const monitorItem = topoMonitorData.find(m => m.nodeId === node.id);

      // 获取节点状态
      const stateData = monitorItem?.nodeDataList?.find(
        k =>
          (k.key! as unknown as IndicatorKey) === IndicatorKey.Status ||
          (k.key! as unknown as IndicatorKey) === IndicatorKey.CD_STATUS
      );
      const status = stateData?.value;
      // 获取节点展示指标
      const displayData = (monitorItem?.nodeDataList ?? [])
        ?.filter(k => {
          const indicatorKey = k.key! as unknown as IndicatorKey;
          return (
            !HoverKey.includes(indicatorKey) &&
            indicatorKey !== IndicatorKey.Status &&
            indicatorKey !== IndicatorKey.CD_STATUS
          );
        })
        .map(k => ({
          label: IndicatorKeyName[k.key! as unknown as IndicatorKey],
          value: `${k?.value?.toFixed(2) ?? '--'}${k?.unit ?? ''}`,
        }));

      // 获取节点hover指标
      const hoverData = monitorItem?.nodeDataList
        ?.filter(k => {
          const indicatorKey = k.key! as unknown as IndicatorKey;
          return HoverKey.includes(indicatorKey);
        })
        .map(k => ({
          label: IndicatorKeyName[k.key! as unknown as IndicatorKey],
          value: `${k?.value?.toFixed(2) ?? '--'}${k?.unit ?? ''}`,
        }));

      const { x: nodeX, y: nodeY } = node.getBBox();

      graph.current?.addNode({
        shape: 'custom-desc-node',
        data: {
          ...nodePrevData,
          displayData,
          status,
        },
        x: nodeX,
        y: nodeY - 24 - displayData?.length * 22,
        zIndex: 20,
      });

      node?.updateData({
        ...nodePrevData,
        displayData,
        hoverData,
      });
    });
  };

  const drawAlarmData = (alarmNodes?: AlarmNode[]) => {
    if (isNil(graph.current)) return;
    const topoNodes = graph.current.getNodes();

    topoNodes.forEach(node => {
      // 报警记录
      const nodePrevData = node.getData();
      const isAlarm =
        alarmNodes?.some(k => k.nodeId === nodePrevData?.selfId && k.nodeType === nodePrevData?.type) ?? false;

      node?.updateData({
        ...nodePrevData,
        isAlarm,
      });
    });
  };

  // 获取管网节点
  const getPipeNodes = (nodes: PipeNodeItem[] = []) => {
    return nodes.map(k => ({
      id: `${k.id}`,
      shape: 'custom-pipe-node',
      width: 40,
      data: {
        ...k,
        entryCostVos: k.entryCostVos?.map(m => ({ ...m, color: getMediumColorItem(m.energyMediumId!, m.energyName!) })),
        exitCostVos: k.exitCostVos?.map(m => ({ ...m, color: getMediumColorItem(m.energyMediumId!, m.energyName!) })),
        iconKey: k.info?.pictureKey,
        type: k.info?.type,
      },
      zIndex: 5,
      ...getPipeNodePort(k),
    }));
  };

  // 获取工质颜色
  const getMediumColorItem = (energyMediumId: number, energyMediumName?: string) => {
    const mediumColorItem = colorList?.find(k => k.energyMediumId === energyMediumId);
    if (isNil(mediumColorItem)) {
      const nextColor = colors[colorList.length % colors.length];
      setColorList([
        ...colorList,
        {
          energyMediumId: energyMediumId,
          energyMediumName: energyMediumName!,
          color: nextColor,
        },
      ]);
      return nextColor;
    }

    return mediumColorItem?.color;
  };

  // 获取管网边
  const getPipeEdges = (edges: PipeEdgeItem[]) => {
    return edges.map(k => ({
      source: `${k.sourceId}`,
      target: `${k.targetId}`,
      attrs: {
        line: {
          stroke: '#1890ff',
          strokeWidth: 1,
          strokeOpacity: 0,
        },
      },
    }));
  };

  // 获取管网节点连接关系
  const getDisplayEdges = (nodes: PipeNodeItem[] = [], edges: PipeEdgeItem[] = []) => {
    return edges
      .map(e => {
        const realSourceNode = nodes.find(n => n.exitCostVos?.some(m => m.id === e.sourceId));
        const realTargetNode = nodes.find(n => n.entryCostVos?.some(m => m.id === e.targetId));
        return { sourceId: realSourceNode?.id, targetId: realTargetNode?.id, entryId: e.targetId, exitId: e.sourceId };
      })
      .filter(e => !isNil(e.sourceId) && !isNil(e.targetId));
  };

  // 获取管网节点两侧port及节点高度
  const getPipeNodePort = (node: PipeNodeItem) => {
    const portColor = node.info?.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE ? '#854ECA' : '#6DAFFC';
    const portAttrs = {
      rect: {
        fill: portColor,
        width: 8,
        height: 8,
        x: -4,
        y: -4,
        magnet: 'true',
      },
      circle: {
        fill: '#979797',
        r: 4,
        x: -4,
        y: -4,
        magnet: 'true',
      },
    };
    const portGroup = {
      in: {
        position: { name: 'left' },
        attrs: portAttrs,
        zIndex: 1,
      },
      out: {
        position: { name: 'right' },
        attrs: portAttrs,
        zIndex: 1,
      },
    };

    const inPorts = (node?.entryCostVos ?? []).map(m => ({
      group: 'in',
      id: 'in-port-' + m.id,
      markup: [
        {
          tagName: m.hasDevice ? 'rect' : 'circle',
          selector: m.hasDevice ? 'rect' : 'circle',
        },
      ],
    }));
    const outPorts = (node?.exitCostVos ?? []).map(m => ({
      group: 'out',
      id: 'out-port-' + m.id,
      markup: [
        {
          tagName: m.hasDevice ? 'rect' : 'circle',
          selector: m.hasDevice ? 'rect' : 'circle',
        },
      ],
    }));

    const maxPortNum = Math.max(inPorts.length, outPorts.length, 1);
    const nodeHeight = (maxPortNum - 1) * 20 + 40;

    return {
      height: nodeHeight,
      ports: {
        items: [...inPorts, ...outPorts],
        groups: portGroup,
      },
    };
  };

  // 绘制管网节点连线
  const drawPipeEdges = (edges: PipeEdgeItem[]) => {
    const nodes = graph.current?.getNodes();
    edges.forEach(k => {
      const startPoint = (nodes ?? []).find((p: { id: string }) => p.id === String(k.sourceId));
      const startPointBox = startPoint?.getBBox();
      const startPointData = startPoint?.getData();
      const startPorts = startPoint?.getPortsPosition('out');
      // 获取连接桩的位置，该位置的x,y信息是相对当前节点的坐标，不是画布上的x,y
      const startPortPosition = startPorts?.['out-port-' + k.exitId]?.position;
      const exitData = startPointData?.exitCostVos.find((e: PipeNodeItem) => e.id === k.exitId);
      const exitVal = `${exitData?.sum?.toFixed(2) ?? '--'}${exitData?.unit ?? ''}`;
      if (!startPortPosition) return;

      const endPoint = (nodes ?? []).find((p: { id: string }) => p.id === String(k.targetId));
      const endPointData = endPoint?.getData();
      const endPointBox = endPoint?.getBBox();
      const endPorts = endPoint?.getPortsPosition('in');
      const endPortPosition = endPorts?.['in-port-' + k.entryId]?.position;
      if (!endPortPosition) return;

      const entryData = endPointData?.entryCostVos.find((e: PipeNodeItem) => e.id === k.entryId);
      const entryVal = `${entryData?.sum?.toFixed(2) ?? '--'}${entryData?.unit ?? ''}`;

      const nodeColor = entryData.color;

      // 路径点1
      const midPoint1 = {
        x: (startPointBox!.x + endPointBox!.x) / 2,
        y: startPortPosition!.y + startPointBox!.y,
      };

      // 路径点2
      const midPoint2 = {
        x: (startPointBox!.x + endPointBox!.x) / 2,
        y: endPortPosition!.y + endPointBox!.y,
      };

      const edge = graph.current?.addEdge({
        source: { cell: String(k.sourceId), port: 'out-port-' + k.targetId! },
        target: { cell: String(k.targetId), port: 'in-port-' + k.sourceId! },
        vertices: [midPoint1, midPoint2],
        attrs: {
          line: {
            stroke: nodeColor,
            strokeWidth: 1,
            strokeDasharray: 6,
            targetMarker: 'classic',
            class: styles.antLine,
          },
        },
      });
      edge?.appendLabel({
        attrs: {
          label: {
            fill: nodeColor,
            fontSize: 12,
          },
          body: {
            fill: 'rgba(255,255,255,0)',
          },
          text: {
            text: exitVal,
            textAnchor: 'left',
          },
        },
        position: {
          offset: {
            y: -10,
          },
          distance: 20,
        },
      });
      edge?.appendLabel({
        attrs: {
          label: {
            fill: nodeColor,
            fontSize: 12,
          },
          body: {
            fill: 'rgba(255,255,255,0)',
          },
          text: {
            text: entryVal,
            textAnchor: 'right',
          },
        },
        position: {
          offset: {
            y: -10,
          },
          distance: -60,
        },
      });
    });
  };

  // 创建管网节点群组
  const createGroupNode = (
    model: any,
    position: Coordinate,
    topologyId?: number,
    topologyName?: string
  ): Coordinate => {
    const parentNode = graph.current?.addNode({
      shape: 'custom-group-node',
      data: {
        stationType: type,
        name: topologyName,
        id: topologyId,
      },
      zIndex: 1,
    });

    let x = position[0];
    let y = position[1];
    let cornerX = position[0];
    let cornerY = position[1];

    // 查找节点组左下 / 右上坐标;
    model.nodes?.forEach((n: any) => {
      const childNode = graph.current?.getCellById(n.id);

      if (childNode) {
        const bbox = childNode.getBBox().inflate(60);
        const corner = bbox.getCorner();
        if (bbox.x < x) {
          x = bbox.x;
        }
        if (bbox.y < y) {
          y = bbox.y;
        }
        if (corner.x > cornerX) {
          cornerX = corner.x;
        }
        if (corner.y > cornerY) {
          cornerY = corner.y;
        }
        parentNode?.addChild(childNode);
      }
    });

    parentNode?.prop(
      {
        position: { x, y },
        size: { width: cornerX - x, height: cornerY - y },
      },
      { skipParentHandler: true }
    );
    return [cornerX, cornerY];
  };

  return (
    <ResizeObserver
      onResize={({ width, height }) => {
        graph.current?.resize(width, height);
        setSize({ width, height });
      }}
    >
      <Spin spinning={loading}>
        <div className={`${styles.drawWrapper} ${existIndex ? styles.normalWrapper : styles.highWrapper}`}>
          <div className={styles.legend}>
            {colorList.map(item => (
              <div className={styles.item} key={item.energyMediumId}>
                <div className={styles.rect} style={{ backgroundColor: item.color }}></div>
                <div>{item.energyMediumName}</div>
              </div>
            ))}
          </div>
          <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
        </div>
      </Spin>
    </ResizeObserver>
  );
};

export default MiddleGraph;
