import {
  Button,
  Key,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  SubContent,
  Form,
  ShowInput,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import { DATE_FORMAT, formLayout } from '../CreateEnergyAssetsSettlementsRules';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import PhotovoltaicDeviceTable from '../components/PhotovoltaicDeviceTable';
import CustomTariffDetail from './components/CustomTariffDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  EnergyAssetsPVStationDevice,
  PriceType,
  PriceTypeValue,
  getEnergyAssetsPVStationDevice,
  getEnergyAssetsSettlementsRulesDetail,
} from '@/api/energyAssetsSettlementsRules';
import { isNil } from 'lodash-es';
import dayjs from 'dayjs';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementType,
  SettlementGeneratorModeName,
  SettlementRulesStatusTypeValue,
} from '@/api/electricitySettlementRules';
import { BSADeviceItem, getBSADeviceItem, getBSAList } from '@/api/energyAssetsBill';
import BsaDeviceTable from '../components/BsaDeviceTable';

const EnergyAssetsSettlementsRulesDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [{ name: `查看` }];
  const [loading, setLoading] = useState(false);
  const [priceType, setPriceType] = useState<PriceType>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [arrayId, setArrayId] = useState<Key>(); // 光伏站id
  const [bsaStationDeviceData, setBsaStationDeviceData] = useState<BSADeviceItem[]>([]);
  const [pvStationDeviceData, setPVStationDeviceData] = useState<EnergyAssetsPVStationDevice[]>([]); // 光伏站下的设备列表

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEnergyAssetsSettlementsRulesDetail(id)
        .then(res => {
          if (res) {
            const timeOfDayRateChecked = [res.summitPrice, res.peakPrice, res.plainPrice, res.valleyPrice]
              .map((i, index) => (isNil(i) ? 0 : index + 1))
              .filter(i => i !== 0);

            form.setFieldsValue({
              name: res.name,
              code: res.code,
              effectiveTime: `${dayjs(res.startTime).format(DATE_FORMAT)}~${dayjs(res.endTime).format(DATE_FORMAT)}`,
              customerMcid: res.customerName,
              propertyManagementConfigId: res.propertyManagementConfigName,
              saleClientId: res.saleClientName,
              saleElectricityContractId: res.saleElectricityContractName,
              saleContractEffectTime: `${dayjs(res.contractStartTime).format(DATE_FORMAT)}~${dayjs(
                res.contractEndTime
              ).format(DATE_FORMAT)}`,
              status: SettlementRulesStatusTypeValue[res.status],
              generationModeName: SettlementGeneratorModeName[res.generationMode],
              settlementType: res.settlementType,
              settlementTypeName: ElectricitySaleContractSettlement[res.settlementType],
              priceType: res.settlementTemplate ? PriceTypeValue[PriceType.TEMPLATE] : PriceTypeValue[PriceType.CUSTOM],
              settlementTemplateName: res.settlementTemplateName,
              timeOfDayRateChecked,
              summitPrice: res.summitPrice,
              peakPrice: res.peakPrice,
              plainPrice: res.plainPrice,
              valleyPrice: res.valleyPrice,
              gridPrice: res.gridPrice,
              discountForOwner: res.discountForOwner,
              gridPercentageForOwner: res.gridPercentageForOwner,
              arrayName: res.arrayName,
            });
            setPriceType(res.settlementTemplate ? PriceType.TEMPLATE : PriceType.CUSTOM);
            setArrayId(res.arrayId);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, form]);

  useEffect(() => {
    const settlementType = form.getFieldValue('settlementType');
    if (arrayId && settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
      getEnergyAssetsPVStationDevice(arrayId).then(setPVStationDeviceData);
    }
    if (arrayId && settlementType === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
      getBSAList().then(res => {
        const deviceName = (res ?? [])?.find(k => k.id === arrayId)?.name;
        form.setFieldsValue({ arrayName: deviceName });
      });
      getBSADeviceItem(arrayId).then(res => setBsaStationDeviceData([res]));
    }
  }, [arrayId, form]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title="查看结算规则"></FormTitle>

      <Form layout="vertical" form={form} {...formLayout}>
        <Spin spinning={loading}>
          <SubContent>
            <Row>
              <Col span={8}>
                <Form.Item name="code" label="结算规则编号">
                  <ShowInput />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="name" label="结算规则名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="customerMcid" label="关联组织">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="propertyManagementConfigId" label="所属项目公司">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="saleClientId" label="所属客户">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="saleElectricityContractId" label="所属合同">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="effectiveTime" label="结算规则生效区间">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="saleContractEffectTime" label="合同有效期">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="status" label="结算规则状态">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="结算场景">
                  <ShowInput value="能源资产营销" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="generationModeName" label="结算单生成方式">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="settlementTypeName" label="结算类型">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="priceType" label="结算电价">
                  <ShowInput />
                </Form.Item>
              </Col>
              {priceType === PriceType.CUSTOM && (
                <Col span={24}>
                  <CustomTariffDetail />
                </Col>
              )}
              {priceType === PriceType.TEMPLATE && (
                <Col span={8}>
                  <Form.Item name="settlementTemplateName" label="电价模板">
                    <ShowInput />
                  </Form.Item>
                </Col>
              )}
              <Form.Item noStyle dependencies={['settlementType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item name="gridPrice" label="上网电价">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="discountForOwner" label="电价折扣">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="gridPercentageForOwner" label="上网分成比例">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="arrayName" label="光伏站">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                  if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                    return (
                      <>
                        <Col span={8}>
                          <Form.Item name="discountForOwner" label="放电折扣">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name="arrayName" label="储能站">
                            <ShowInput />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                }}
              </Form.Item>
              <Col span={24}>
                <Form.Item noStyle dependencies={['settlementType']}>
                  {({ getFieldValue }) => {
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_PV) {
                      return <PhotovoltaicDeviceTable data={pvStationDeviceData} />;
                    }
                    if (getFieldValue('settlementType') === ElectricitySaleContractSettlementType.DISTRIBUTED_BSA) {
                      return <BsaDeviceTable data={bsaStationDeviceData} />;
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Spin>
        <Space className="sticky-footer">
          <Button onClick={() => navigate(`/operation/settlementRules/energyAssetsRules${search}`)}>返回</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default EnergyAssetsSettlementsRulesDetails;
