import { CheckCircleFilled } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  FormTitle,
  Steps,
  SubContent,
  Table,
  useBreadcrumbRoutes,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Form, Col, Row, Result, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import styles from './index.module.scss';
import SelectedProcessModal from './SelectedProcessModal';
import {
  apiV2WorkCenterListPost,
  apiV2WorkShiftCalendarConfigAddProcedureConfigPost,
  apiV2WorkShiftCalendarConfigEditProcedureConfigPost,
  apiV2WorkShiftCalendarConfigProcedureInfoPost,
  V2WorkShiftCalendarConfigProcedureInfoPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

interface Props {
  isEdit?: boolean;
}

const formatString = 'YYYY-MM';
const steps = ['选择时间', '选择工序', '完成'];
const items = steps.map((item, index) => ({ key: index, title: item }));

export type WorkCenterItem = Exclude<
  V2WorkShiftCalendarConfigProcedureInfoPostResponse['workCenterList'],
  undefined
>[number] & {
  addProcedureIds?: number[];
  delProcedureIds?: number[];
};

const AddToProcess: React.FC<Props> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [current, setCurrent] = useState<number>(0);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const workShiftCalendarId = urlSearchParams.get('workShiftCalendarId') || undefined;
  const [workCenterList, setWorkCenterList] = useState<WorkCenterItem[]>([]);
  const [workCenterItem, setWorkCenterItem] = useState<WorkCenterItem>();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [applyLoading, setApplyLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const [updateWork, updateWorkFn] = useUpdate();
  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '修改应用配置' : '应用到工序'}` }];
  }, [isEdit]);

  useEffect(() => {
    if (id && isEdit) {
      setLoading(true);
      apiV2WorkShiftCalendarConfigProcedureInfoPost({ id: Number(id) })
        .then(res => {
          form.setFieldsValue({
            startDate: dayjs(res.startDate),
            endDate: dayjs(res.endDate),
          });
          setWorkCenterList(
            (res.workCenterList ?? []).map(item => ({
              workCenterId: item.workCenterId,
              workCenterName: item.workCenterName,
              workProcedureList: item.workProcedureList,
              amount: item.amount,
            }))
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);

  // 工作中心列表
  useEffect(() => {
    if (!isEdit) {
      apiV2WorkCenterListPost({}).then(res => {
        setWorkCenterList(
          (res.list ?? []).map(item => ({
            workCenterId: item.id,
            workCenterName: item.name,
            workProcedureList: [],
            amount: 0,
          }))
        );
      });
    }
  }, [updateWork, isEdit]);

  const columns = [
    {
      title: '工作中心',
      dataIndex: 'workCenterName',
    },
    {
      title: '应用工序数',
      dataIndex: 'amount',
    },
    {
      title: '操作',
      width: 100,
      render: (record: any) => {
        return (
          <Button
            type="link"
            onClick={() => {
              setWorkCenterItem(record);
              setModalOpen(true);
            }}
          >
            选择
          </Button>
        );
      },
    },
  ];

  const next = () => {
    if (current === 0) {
      setStartDate(dayjs(form.getFieldValue('startDate')).startOf('M').format('YYYY-MM-DD'));
      setEndDate(dayjs(form.getFieldValue('endDate')).startOf('M').format('YYYY-MM-DD'));
      updateFn();
    }
    form.submit();
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onDateChange = () => {
    updateWorkFn();
  };

  const onFinish = () => {
    if (current !== 2 || isNil(startDate) || isNil(endDate)) return;
    setApplyLoading(true);
    if (!isEdit) {
      // 新增
      apiV2WorkShiftCalendarConfigAddProcedureConfigPost({
        workShiftCalendarId: Number(workShiftCalendarId),
        startDate: startDate,
        endDate: endDate,
        addLink: workCenterList.map(k => ({
          workCenterId: k.workCenterId!,
          workProcedureIds: (k.workProcedureList ?? []).map(n => n.workProcedureId!),
        })),
      })
        .then(res => {
          setStatus(true);
        })
        .finally(() => {
          setApplyLoading(false);
        });
    }
    if (id && isEdit) {
      apiV2WorkShiftCalendarConfigEditProcedureConfigPost({
        id: Number(id),
        addLink: workCenterList.map(k => ({
          workCenterId: k.workCenterId!,
          workProcedureIds: k?.addProcedureIds ?? [],
        })),
        delLink: workCenterList.map(k => ({
          workCenterId: k.workCenterId!,
          workProcedureIds: k?.delProcedureIds ?? [],
        })),
      })
        .then(res => {
          setStatus(true);
        })
        .finally(() => {
          setApplyLoading(false);
        });
    }
  };

  return (
    <Wrapper isLoading={loading} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={`${isEdit ? '修改应用配置' : '应用到工序'}`} />
      <SubContent>
        <div className={styles.stepsStyle}>
          <Steps style={{ width: 1000 }} current={current} items={items} />
        </div>
        <Row>
          <Col span={20} offset={1}>
            <Form
              form={form}
              initialValues={{
                startDate: dayjs(dayjs().add(1, 'month'), formatString),
                endDate: dayjs(dayjs().add(13, 'month'), formatString),
              }}
              onFinish={onFinish}
            >
              {current === 0 && (
                <>
                  <Form.Item noStyle dependencies={['endDate']}>
                    {({ getFieldValue }) => {
                      const endDate = getFieldValue('endDate');
                      return (
                        <Form.Item
                          style={{ width: '100%', paddingTop: 60 }}
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 6 }}
                          label="起始时间"
                          name="startDate"
                        >
                          <DatePicker
                            disabled={isEdit}
                            allowClear={false}
                            picker="month"
                            style={{ width: '100%' }}
                            placeholder="请选择"
                            onChange={onDateChange}
                            disabledDate={c => {
                              return c.isAfter(dayjs(endDate)) || c.isBefore(dayjs(endDate).subtract(5, 'years'));
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item noStyle dependencies={['startDate']}>
                    {({ getFieldValue }) => {
                      const startDate = getFieldValue('startDate');
                      return (
                        <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 6 }} label="结束时间" name="endDate">
                          <DatePicker
                            disabled={isEdit}
                            allowClear={false}
                            picker="month"
                            style={{ width: '100%' }}
                            placeholder="请选择"
                            onChange={onDateChange}
                            disabledDate={c => {
                              return c.isBefore(dayjs(startDate)) || c.isAfter(dayjs(startDate).add(5, 'years'));
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )}
              {current === 1 && (
                <Form.Item wrapperCol={{ span: 18, offset: 4 }}>
                  <Table rowKey="workCenterId" sticky loading={false} dataSource={workCenterList} columns={columns} />
                </Form.Item>
              )}
              {current === 2 && (
                <Form.Item wrapperCol={{ span: 5, offset: 11 }}>
                  <Spin spinning={applyLoading}>
                    {!applyLoading && (
                      <Result
                        status={status ? 'success' : 'error'}
                        title={
                          <span
                            style={{
                              fontSize: 12,
                              fontFamily: ' PingFangSC-Medium, PingFang SC',
                              fontWeight: 500,
                              color: 'rgba(255,255,255,0.8)',
                            }}
                          >
                            {status ? '应用完成' : '应用失败'}
                          </span>
                        }
                        extra={[
                          <div style={{ marginTop: 111 }}>
                            <Button>
                              <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>
                                返回
                              </Link>
                            </Button>
                          </div>,
                        ]}
                        icon={<CheckCircleFilled style={{ fontSize: 50 }} />}
                      />
                    )}
                  </Spin>
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
        <div style={{ marginTop: 24, display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center' }}>
          {current > 0 && current !== steps?.length - 1 && <Button onClick={prev}>上一步</Button>}
          {(current === 0 || current === 1) && (
            <Button type="primary" htmlType="submit" onClick={next}>
              下一步
            </Button>
          )}
          {current !== steps?.length - 1 && (
            <Button>
              <Link to={`/energy/teamShift/shiftCalendar/configuration/${workShiftCalendarId}`}>返回</Link>
            </Button>
          )}
        </div>
      </SubContent>
      {modalOpen && (
        <SelectedProcessModal
          open={modalOpen}
          dateRange={{
            startDate,
            endDate,
          }}
          workCenterInfo={workCenterItem}
          setOpen={setModalOpen}
          updateData={(workProcedureList, addProcedureIds, delProcedureIds) => {
            setWorkCenterList(
              workCenterList.map(m =>
                m.workCenterId === workCenterItem?.workCenterId
                  ? {
                      ...m,
                      workProcedureList: workProcedureList,
                      addProcedureIds,
                      delProcedureIds,
                      amount: workProcedureList.length,
                    }
                  : m
              )
            );
          }}
        ></SelectedProcessModal>
      )}
    </Wrapper>
  );
};
export default AddToProcess;
