/* eslint-disable no-useless-escape */
import { useState, useEffect } from 'react';
import { isNil } from 'lodash-es';
import { DeviceType } from '../types';
import { DefaultOptionType } from 'rc-cascader';

export const useQuery = <T>(action: () => Promise<T | undefined>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    setIsLoading(true);
    action()
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [action]);
  return { isLoading, data };
};

export interface Labels {
  [key: string]: string;
}

interface LabelValue<V extends string | number> {
  label: string;
  value: V;
}

export function generateLabelList<V extends string | number>(labels: Labels, values: V[]): LabelValue<V>[] {
  return values.map(value => ({
    label: labels[value],
    value,
  }));
}

export const getRange = (min: number | string | undefined, max: number | string | undefined) => {
  if (!isNil(min) && min !== '' && !isNil(max) && max !== '') {
    return `${min} ~ ${max}`;
  } else if (!isNil(min) && min !== '' && (isNil(max) || max === '')) {
    return `${min} ~`;
  } else if ((isNil(min) || min === '') && !isNil(max) && max !== '') {
    return `~ ${max}`;
  } else {
    return '--';
  }
};

export function formatDeviceTypeToDataNode(tree: DeviceType[]): DefaultOptionType[] {
  if (!tree) return [];
  const loop = (data: DeviceType[]): DefaultOptionType[] => {
    return data.map(item => ({
      name: item.name,
      key: item.key,
      id: item.id,
      label: item.name,
      value: item.id,
      children: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : undefined,
    }));
  };
  return loop(tree);
}

// 获取一级类目和二级类目
export const sliceDeviceTypeName = (name: string) => {
  const indexOfFirst = name.indexOf('/');
  return name.slice(indexOfFirst + 1);
};

function posixSplitPath(filename: string) {
  const splitPathRe = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/;
  return splitPathRe.exec(filename)!.slice(1);
}

export function extname(path: string) {
  return posixSplitPath(path)[3];
}

export const validatorValueRange = (form: any) => {
  const min = form.getFieldValue('valueFloor');
  const max = form.getFieldValue('valueCeiling');
  if ((isNil(min) && isNil(max)) || (min === '' && max === '')) {
    return Promise.reject(new Error('数据合理区间至少填写一侧阈值'));
  }
  if (min && max && Number(min) >= Number(max)) {
    return Promise.reject(new Error('最小值需要小于最大值'));
  }
  return Promise.resolve();
};
