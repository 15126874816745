import { Spin, DatePickerProps } from 'antd';
import EnergyContent from '../EnergyContent';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import PieEcharts from '../PieEcharts';
import styles from './index.module.scss';
import AllEnergyLineEcharts from '../AllEnergyLineEcharts';
import BarEcharts from '../BarEcharts';
import { RangePickerProps } from 'antd/es/date-picker';
import { DatePicker, Tabs, Empty, Key } from '@maxtropy/components';
import ElectricEcharts from '../ElectricEcharts';
import { useRequest } from 'ahooks';
import { pollingInterval, scale } from '../../utils';
import { isNil } from 'lodash-es';
import {
  V2EnergyConsumptionOverviewComprehensiveMonthCostPostResponse,
  V2EnergyConsumptionOverviewElectricDayCostPostResponse,
  apiV2EnergyConsumptionOverviewComprehensiveDayCostPost,
  apiV2EnergyConsumptionOverviewComprehensiveMonthCostPost,
  apiV2EnergyConsumptionOverviewCumulativeEnergyMediumListPost,
  apiV2EnergyConsumptionOverviewElectricDayCostPost,
  apiV2EnergyConsumptionOverviewMediumDayCostPost,
} from '@maxtropy/device-customer-apis-v2';

export type MediumCostItem = Required<
  Exclude<V2EnergyConsumptionOverviewElectricDayCostPostResponse['list'], undefined>[number]
>;

export type PieCostItem = Required<
  Exclude<V2EnergyConsumptionOverviewComprehensiveMonthCostPostResponse['list'], undefined>[number]
>;

export interface DayCostItem {
  ts: Number;
  details: PieCostItem[];
}

interface Props {
  isFullScreen: boolean;
  isTwoCol: boolean;
  outerRef: any;
  configId?: Key;
}

const sameStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const EnergyCost: React.FC<Props> = ({ isFullScreen, isTwoCol, outerRef, configId }) => {
  const [date, setDate] = useState<Dayjs>(
    [1, 2, 3, 4, 5].includes(dayjs().date()) ? dayjs().subtract(1, 'month') : dayjs()
  );

  const [tabActiveKey, setTabActiveKey] = useState<Key>('allCost');
  const [loading, setLoading] = useState<boolean>(false);
  const [allDataIsOverMillion, setAllDataIsOverMillion] = useState<boolean>(false);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate(date);
    }
  };

  useEffect(() => {
    if (!configId) return;
    setLoading(true);
    // 清空数据
    allData?.splice(0);
    eleCost?.splice(0);
    mediumCost?.splice(0);
    if (tabActiveKey === 'allCost') {
      allDataRun();
      eleCostCancel();
      mediumCostCancel();
    } else if (tabActiveKey === '2030') {
      // 2030 电能
      eleCostRun();
      allDataCancel();
      mediumCostCancel();
    } else if (tabActiveKey) {
      mediumCostRun();
      allDataCancel();
      eleCostCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, tabActiveKey, configId]);

  // 饼图
  const { data: pieData } = useRequest(
    () => {
      return configId
        ? apiV2EnergyConsumptionOverviewComprehensiveMonthCostPost({
            energyOverviewConfigId: configId,
            month: date.format('YYYY-MM-DD'),
          }).then(res => {
            return (res.list ?? []) as PieCostItem[];
          })
        : Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
    }
  );

  //tabs
  const { data: energyMediumList } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewCumulativeEnergyMediumListPost({
          energyOverviewConfigId: configId,
          month: date.format('YYYY-MM-DD'),
        }).then(res => {
          if (res) {
            return [
              {
                id: 'allCost',
                energyName: '综合能源成本',
              },
              ...(res.list ?? []),
            ];
          }
          return [
            {
              id: 'allCost',
              energyName: '综合能源成本',
            },
          ];
        });
      }
      return Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
    }
  );

  // 综合能源成本
  const {
    data: allData,
    run: allDataRun,
    cancel: allDataCancel,
  } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewComprehensiveDayCostPost({
          energyOverviewConfigId: configId,
          month: date.format('YYYY-MM-DD'),
        })
          .then(res => {
            if (res && res.list && res.list.length > 0) {
              const isOverMillion = res.list.some(i => {
                const chargesArr = i.details?.map(i => i.charges ?? 0) ?? [];
                const totalCharges = chargesArr.reduce((a: number, b: number) => Number(a) + Number(b), 0);
                if (totalCharges >= 1000000 || totalCharges < -1000000) return true;
                return false;
              });
              setAllDataIsOverMillion(isOverMillion);
              if (isOverMillion) {
                const data = res.list.map(i => ({
                  ts: i.ts,
                  details: (i.details ?? []).map(i => ({
                    ...i,
                    charges: isNil(i.charges) ? i.charges : Number((Number(i.charges) / 10000).toFixed(2)),
                  })),
                }));
                return data as DayCostItem[];
              }
              return (res.list ?? []) as DayCostItem[];
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
      manual: true,
    }
  );

  // 电能
  const {
    data: eleCost,
    run: eleCostRun,
    cancel: eleCostCancel,
  } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewElectricDayCostPost({
          energyOverviewConfigId: configId,
          month: date.format('YYYY-MM-DD'),
        })
          .then(res => {
            if (res && res.list && res.list.length > 0) {
              const isOverMillion = res.list.some(
                i => (i.mediumCharges && i.mediumCharges >= 1000000) || (i.mediumCharges && i.mediumCharges <= -1000000)
              );
              setAllDataIsOverMillion(isOverMillion);
              if (isOverMillion) {
                const data = res.list.map(i => ({
                  ...i,
                  mediumCharges: isNil(i.mediumCharges)
                    ? i.mediumCharges
                    : Number((Number(i.mediumCharges) / 10000).toFixed(2)),
                }));
                return data as MediumCostItem[];
              }
              return (res.list ?? []) as MediumCostItem[];
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [date, configId],
      manual: true,
    }
  );

  // 其他工质
  const {
    data: mediumCost,
    run: mediumCostRun,
    cancel: mediumCostCancel,
  } = useRequest(
    () => {
      if (configId) {
        return apiV2EnergyConsumptionOverviewMediumDayCostPost({
          energyOverviewConfigId: configId,
          month: date.format('YYYY-MM-DD'),
          energyMediumId: tabActiveKey,
        })
          .then(res => {
            if (res && res.list && res.list.length > 0) {
              const isOverMillion = res.list.some(
                i => (i.mediumCharges && i.mediumCharges >= 1000000) || (i.mediumCharges && i.mediumCharges <= -1000000)
              );
              setAllDataIsOverMillion(isOverMillion);
              if (isOverMillion) {
                const data = res.list.map(i => ({
                  ...i,
                  mediumCharges: Number((Number(i.mediumCharges) / 10000).toFixed(2)),
                }));
                return data as MediumCostItem[];
              }
              return res.list as MediumCostItem[];
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return Promise.resolve([]);
    },
    {
      pollingInterval,
      refreshDeps: [date, tabActiveKey, configId],
      manual: true,
    }
  );
  const zoom = outerRef && outerRef.style.zoom ? outerRef.style.zoom : 1;

  return (
    <>
      <EnergyContent
        title="用能成本"
        extraContent={
          <>
            <DatePicker
              allowClear={false}
              disabledDate={disabledDate}
              onChange={onDateChange}
              picker="month"
              value={date}
              getPopupContainer={() => outerRef}
            />
          </>
        }
      >
        {pieData && pieData.length > 0 ? (
          <div className={styles.pieAndLineflexStyles}>
            <div
              className={styles.topStyles}
              style={
                document.body.clientWidth > 1920
                  ? {
                      zoom: `${1 / zoom}`,
                      transform: `scale(${scale})`,
                      width: '100%',
                    }
                  : { width: '100%' }
              }
            >
              {/* <div className={styles.circleLine} /> */}
              <PieEcharts outerRef={outerRef} pieData={pieData} isTwoCol={isTwoCol} />
            </div>
            <div className={styles.bottomChartsStyles}>
              <div className={styles.bottomStyles}>
                <Tabs
                  getPopupContainer={() => outerRef}
                  className={styles.tabsStyle}
                  activeKey={tabActiveKey as string}
                  onChange={v => {
                    setTabActiveKey(v);
                  }}
                  items={
                    energyMediumList
                      ? energyMediumList.map(i => {
                          return {
                            label: i.energyName,
                            key: String(i.id),
                          };
                        })
                      : undefined
                  }
                />
              </div>
              <div style={{ flex: 1 }}>
                {tabActiveKey && tabActiveKey === 'allCost' ? (
                  <Spin spinning={loading}>
                    <AllEnergyLineEcharts
                      outerRef={outerRef}
                      allDataIsOverMillion={allDataIsOverMillion}
                      isTwoCol={isTwoCol}
                      allData={allData}
                      isFullScreen={isFullScreen}
                    />
                  </Spin>
                ) : tabActiveKey === '2030' ? (
                  <Spin spinning={loading}>
                    <ElectricEcharts
                      outerRef={outerRef}
                      allDataIsOverMillion={allDataIsOverMillion}
                      isTwoCol={isTwoCol}
                      eleCost={eleCost}
                      isFullScreen={isFullScreen}
                    />
                  </Spin>
                ) : (
                  <Spin spinning={loading}>
                    <BarEcharts
                      outerRef={outerRef}
                      allDataIsOverMillion={allDataIsOverMillion}
                      isTwoCol={isTwoCol}
                      mediumCost={mediumCost}
                      isFullScreen={isFullScreen}
                    />
                  </Spin>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={
              isTwoCol
                ? { height: 710, ...sameStyle }
                : isFullScreen
                ? { height: 400, ...sameStyle }
                : { height: 220, ...sameStyle }
            }
          >
            <Empty />
          </div>
        )}
      </EnergyContent>
    </>
  );
};

export default EnergyCost;
