import {
  Button,
  CustomFilter,
  Form,
  Input,
  Modal,
  Paging,
  Select,
  Table,
  usePaging,
  useUpdate,
} from '@maxtropy/components';
import { Space } from 'antd';
import React, { FC, Key, useEffect, useMemo, useState } from 'react';

import {
  getSaleClientAccountPage,
  getSaleClientListById,
  SaleClientAccountPageRequest,
  SaleClientInfo,
} from '../../../../../api/sale';
import styles from './index.module.scss';
import { columns } from './util';

export interface SelectCilentAccountProps {
  value?: any[];
  onChange?: (value: any[]) => void;
  ouId?: number;
  isMuti: boolean;
  isEdit: boolean;
}

type SearchParams = Omit<SaleClientAccountPageRequest, 'size' | 'page'>;

const SelectCilentAccount: FC<SelectCilentAccountProps> = ({ onChange, ouId, value, isMuti, isEdit }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [cilentAccounts, setCilentAccounts] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [updateState] = useUpdate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);

  // 点击取消的时候保证之前的数据没有变化，之后的数据不添加
  const selectCilentAccountIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  // value变化的时候重新set
  // useEffect(() => {
  //   setSelectedRowKeys((value ?? []).map(i => i.id));
  // }, [value]);

  useEffect(() => {
    if (ouId) {
      getSaleClientListById(ouId).then(setCustomList); // 所属客户下拉选择搜索
    }
  }, [ouId]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedRowKeys([]);
      onChange?.([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ouId]);

  useEffect(() => {
    if (ouId) {
      setLoading(true);
      getSaleClientAccountPage({ ...searchParams, ouId, page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setCilentAccounts(res.list);
        }
      });
    }
  }, [ouId, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  // 提交表单查询
  const onFinish = (search: any) => {
    setSearchParams(search);
    setPageOffset(1);
  };

  // 搜索表单重置
  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      saleClientId: undefined,
    });
    setPageOffset(1);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    // getCheckboxProps: (record: BalanceAlarmDetails) => ({
    //   disabled: (selectCilentAccountIds ?? []).includes(record.id)
    // }),
    selectedRowKeys: selectedRowKeys,
  };

  const filters = (
    <CustomFilter
      colSpan={8}
      form={form}
      onFinish={val => {
        onFinish(val);
      }}
      onReset={onReset}
    >
      <Form.Item name="saleClientId" label="所属客户">
        <Select placeholder="请选择">
          {customList.map(i => (
            <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="name" label="账户名称">
        <Input placeholder="请选择账户名称" />
      </Form.Item>
    </CustomFilter>
  );

  // 点击添加
  const onAdd = () => {
    if (ouId === undefined) {
      Modal.warning({
        title: '请先选择运营单元',
      });
      return;
    }
    setVisible(true);
  };

  // 点击提交
  const onOk = () => {
    // if (value?.length !== 0) {
    //   // let totalValueList = value!.map(i => i.id);
    //   // console.log(value);
    //   let totalArr = [...value!,...selectedRows];
    //   const res = new Map();
    //   let newArr = totalArr.filter((item) => !res.has(item.id) && res.set(item.id, 1));
    //   console.log(newArr);
    //   onChange?.(newArr);
    // } else {
    onChange?.(selectedRows);
    setVisible(false);
    onReset();
  };

  // 点击取消
  const onCancel = () => {
    setSelectedRowKeys(selectCilentAccountIds ?? []);
    setVisible(false);
    onReset();
  };

  return (
    <>
      {isMuti ? (
        (value ?? []).length !== 0 ? (
          <Space direction="horizontal">
            <span>
              已选择<span style={{ color: 'red' }}> {(value ?? []).length}</span>项
            </span>
            <span>
              <Button type="link" onClick={onAdd}>
                继续选择
              </Button>
            </span>
          </Space>
        ) : (
          <Space direction="vertical">
            <Button type="link" onClick={onAdd}>
              请选择
            </Button>
          </Space>
        )
      ) : (value ?? []).length !== 0 ? (
        <Space direction="horizontal">
          <span>{value ? <span style={{ color: 'red' }}>{`【${value[0].code}】${value[0].name}`}</span> : ''}</span>
          <span>
            <Button type="link" onClick={onAdd}>
              重新选择
            </Button>
          </span>
        </Space>
      ) : (
        <Space direction="vertical">
          <span>
            <Button type="link" onClick={onAdd}>
              请选择
            </Button>
          </span>
        </Space>
      )}
      <Modal
        className={styles.modal}
        title="客户账户选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        size="large"
      >
        {filters}

        <Table
          loading={loading}
          rowSelection={{
            type: isMuti ? 'checkbox' : 'radio',
            ...rowSelection,
          }}
          showSelectedCount
          selectedCount={(selectedRowKeys ?? []).length}
          sticky
          scroll={{ y: 300 }}
          rowKey="id"
          columns={columns}
          dataSource={cilentAccounts}
          pagination={false}
        >
          <Paging className={styles.pageSyle} pagingInfo={pagingInfo} />
        </Table>
      </Modal>
    </>
  );
};

export default SelectCilentAccount;
