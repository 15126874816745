import styles from './index.module.scss';
import classNames from 'classnames';
import { CSSProperties, FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import { Empty, Input, Tag, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { EnergyMediumTreeFormatType } from '@/api/energyMedium';
import { useUpdateEffect } from 'ahooks';
import processIcon from '../../imgs/process.png';
import entryIcon from '../../imgs/entry.png';
import exitIcon from '../../imgs/exit.png';
import nodeInsideIcon from '../../imgs/nodeInside.png';
import { useLocation } from 'react-router-dom';
import {
  V2MediumIndicatorDashboardGetEnergyMediumTopologyTreePostResponse,
  apiV2MediumIndicatorDashboardGetEnergyMediumTopologyTreePost,
} from '@maxtropy/device-customer-apis-v2';

export interface ProcessTreeProps {
  className?: string;
  style?: CSSProperties;
  changeTreeSelect?: (value: React.Key[], info: any) => void; // info就是节点或者出口入口的值
  sceneId: number;
  topoSelectedKey?: Key;
  selectedKeys: Key[];
  setCurrentTopoId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedKeys: React.Dispatch<React.SetStateAction<Key[]>>;
  setTopoSelectedKey: React.Dispatch<React.SetStateAction<Key | undefined>>;
}

const { Search } = Input;

const ProcessTree: FC<ProcessTreeProps> = props => {
  const {
    className,
    changeTreeSelect,
    style,
    sceneId,
    topoSelectedKey,
    selectedKeys,
    setSelectedKeys,
    setCurrentTopoId,
    setTopoSelectedKey,
  } = props;
  const [searchParams, setSearchParams] = useState<any>(); // 搜索
  const [loading, setLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [data, setData] = useState<DataNode[]>();
  const flattenTreeData = useRef<any>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_processId = urlSearchParams.get('processId') || undefined;
  let url_topoId = urlSearchParams.get('topoId') || undefined;

  // 请求数据
  useEffect(() => {
    if (sceneId) {
      setLoading(true);
      apiV2MediumIndicatorDashboardGetEnergyMediumTopologyTreePost({ id: sceneId })
        .then(res => {
          if (res) {
            const treeData = transformTree(res.list);
            flattenTreeData.current = flattenMap(treeData);
            setExpandedKeys(Array.from(flattenTreeData.current.keys()) as string[]);
            setData(treeData);

            if (!treeData.length) {
              setSelectedKeys([]);
              changeTreeSelect?.([], undefined);
            } else {
              for (let i = 0; i < treeData.length; i++) {
                if (treeData[i].children && treeData[i].children!.length) {
                  setSelectedKeys([treeData[i].key]);
                  // changeTreeSelect?.([treeData[i].key], flattenTreeData.current.get(treeData[i].key)?.info);
                  break;
                }
              }
            }
          }
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneId]);

  useEffect(() => {
    if (topoSelectedKey && data) {
      const selectedProcessKey = topoSelectedKey.toString();
      setSelectedKeys([selectedProcessKey]);
      const flattenTreeData = flattenMap(data);
      changeTreeSelect?.([selectedProcessKey], flattenTreeData.get(selectedProcessKey)?.info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoSelectedKey, data]);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      if (searchParams && searchParams.name) {
        const dataflatArr = flattenArray(data);
        const newExpandedKeys = dataflatArr
          ?.map(item => {
            if (item.title.includes(searchParams.name)) {
              return getPathByNodeId(data, item.key);
            }
            return null;
          })
          .filter(i => !!i);

        newExpandedKeys.forEach(i => i?.pop());
        setExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
      } else {
        const flattenTreeData = flattenMap(data);
        setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
        if (url_processId) {
          const urlProcess = url_processId + 'process';
          setSelectedKeys([urlProcess]);
          changeTreeSelect?.([urlProcess], flattenTreeData.get(urlProcess)?.info);
        } else if (url_topoId) {
          const urlTopo = url_topoId + 'topo';
          setSelectedKeys([urlTopo]);
          changeTreeSelect?.([urlTopo], flattenTreeData.get(urlTopo)?.info);
        } else {
          if (data[0]) {
            changeTreeSelect?.([data[0].key], flattenTreeData.get(data[0].key)?.info);
            setSelectedKeys([data[0].key]);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, data]);

  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (data && data.length && searchParams && searchParams.name) {
      const loop = (data: (DataNode & { info?: any })[]): DataNode[] =>
        data.map(item => {
          const strTitle = item.info.name as string;
          const searchName = searchParams ? searchParams.name : undefined;
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName.length);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span className={styles.filter_text}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.children) {
            return { ...item, title, children: loop(item.children) };
          }
          return {
            ...item,
            title,
          };
        });
      return loop(data);
    } else {
      return data;
    }
  }, [searchParams, data]);

  // 搜索树的选择框
  const onSelect = (selectedKeys: Key[], e: any) => {
    const currentTopoId = e.node.info.topoId;
    // 重置
    setTopoSelectedKey?.(undefined);
    setCurrentTopoId(currentTopoId);
    changeTreeSelect?.(selectedKeys, e.node.info);
    setSelectedKeys(selectedKeys);
  };

  // 回路名称搜索, 滚动到可视区域
  let timer: any = useRef<any>();
  useUpdateEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      const dom = document.querySelector(`.${styles.filterTxt}`);
      let parentDom = dom?.parentNode as HTMLDivElement;
      parentDom?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    return () => {
      clearTimeout(timer.current);
    };
  }, [setSearchParams]);

  return (
    <div className={classNames(className, styles.treeArea)} style={style}>
      <div className={styles.searchArea}>
        <Search
          placeholder="请输入拓扑名称、节点名称、输入输出节点内名称"
          allowClear
          onSearch={(v: any) => setSearchParams({ name: v })}
        />
      </div>

      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length ? (
            <Tree
              showIcon
              className={styles.treeStyle}
              blockNode
              treeData={treeData}
              expandedKeys={expandedKeys}
              selectedKeys={selectedKeys}
              onExpand={(expandedKeys: any) => {
                setExpandedKeys(expandedKeys as string[]);
              }}
              onSelect={onSelect}
            />
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ProcessTree;

// 使得返回的数据变成DataNode格式
export const transformTree = (
  tree?: V2MediumIndicatorDashboardGetEnergyMediumTopologyTreePostResponse['list']
): DataNode[] => {
  if (!tree) return [];
  return tree.map(item => {
    return {
      // icon: <img src={circuitIcon} alt="" />,
      key: item.id! + 'topo',
      title: item.name ? (
        <>
          <span style={{ marginRight: '5px' }} id={`${item.id}`}>
            {item.name}
          </span>
          <Tag color={'blue'}>总拓扑</Tag>
        </>
      ) : (
        '--'
      ),
      selectable: true,
      info: {
        topoId: item.id,
        name: item.name,
        id: item.id,
        type: EnergyMediumTreeFormatType.TOPO,
        processTreeVos: item.processTreeVos,
      },
      children: (item.processTreeVos ?? []).map(i => {
        return {
          icon: <img src={processIcon} title="节点" alt="" />,
          className: 'circuit-title',
          key: i.id! + 'process',
          title: (
            <span style={{ marginRight: '5px' }} id={`${i.id}`}>
              {i.name ?? '--'}
            </span>
          ),
          selectable: true,
          info: {
            topoId: item.id,
            processId: i.id,
            name: i.name,
            id: i.id,
            type: EnergyMediumTreeFormatType.PROCESS,
            processEntryTreeVos: i.processEntryTreeVos,
            processExitTreeVos: i.processExitTreeVos,
            processNodeTree: i.processNodeTree,
          },
          children: (i.processEntryTreeVos ?? [])
            .filter(c => c.hasDevice)
            .map(m => {
              return {
                icon: <img src={entryIcon} title="输入" alt="" />,
                className: 'circuit-title',
                key: m.id! + 'entry',
                title: m.name ? (
                  <>
                    <span style={{ marginRight: '5px' }} id={`${i.id}`}>
                      {m.name}
                    </span>
                  </>
                ) : (
                  '--'
                ),
                selectable: true,
                info: {
                  topoId: item.id,
                  name: m.name,
                  id: m.id,
                  processId: i.id,
                  type: EnergyMediumTreeFormatType.ENTRY,
                },
              };
            })
            .concat(
              (i.processNodeTree ? [i.processNodeTree] : [])
                .filter(c => c.hasDevice)
                .map(k => {
                  return {
                    icon: <img src={nodeInsideIcon} title="节点内" alt="" />,
                    className: 'circuit-title',
                    key: k.id! + 'inside',
                    title: k.name ? (
                      <>
                        <span style={{ marginRight: '5px' }} id={`${k.id}`}>
                          {k.name}
                        </span>
                      </>
                    ) : (
                      '--'
                    ),
                    selectable: true,
                    info: {
                      topoId: item.id,
                      name: k.name,
                      id: k.id,
                      processId: i.id,
                      type: EnergyMediumTreeFormatType.NODEINSIDE,
                    },
                  };
                })
            )
            .concat(
              (i.processExitTreeVos ?? [])
                .filter(c => c.hasDevice)
                .map(k => {
                  return {
                    icon: <img src={exitIcon} title="输出" alt="" />,
                    className: 'circuit-title',
                    key: k.id! + 'exit',
                    title: k.name ? (
                      <>
                        <span style={{ marginRight: '5px' }} id={`${k.id}`}>
                          {k.name}
                        </span>
                      </>
                    ) : (
                      '--'
                    ),
                    selectable: true,
                    info: {
                      topoId: item.id,
                      name: k.name,
                      id: k.id,
                      processId: i.id,
                      type: EnergyMediumTreeFormatType.EXIT,
                    },
                  };
                })
            ),
        };
      }),
    };
  });
};

// 根据节点的Id获取所有父节点
const getPathByNodeId = (data: DataNode[], key: React.Key, path: string[] = []): string[] => {
  for (const node of data) {
    path.push(node.key as string);
    if (node.key === key) return path;
    if (node.children) {
      const findChildren = getPathByNodeId(node.children, key, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

// 平铺所有树结构
export const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};
export const flattenArray = (data: (DataNode & { info?: any })[], arr: { key: string; title: string }[] = []) => {
  data.forEach(i => {
    arr.push({ key: i.key as string, title: i.info.name });
    if (i.children) {
      flattenArray(i.children, arr);
    }
  });
  return arr;
};
