import React, { useImperativeHandle, useState } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { Form } from '@maxtropy/components';
import { DriveType } from '../../../types';
import { MockingbirdDeviceProps } from '../../DataAcquisitionForm/types';
import CustomFormItem from './CustomFormItem';
import formItemsByDriveType from './Form';

interface DriveTypeFormProps {
  driveType: DriveType;
  disabled: boolean;
  updateCheckedItems?: (value: string[]) => void;
}

export const driveTypeLockItems = ['serialPort', 'stationNum', 'ip', 'address', 'leadByte', 'port'];

export interface DriveTypeFormRef {
  form: FormInstance<MockingbirdDeviceProps> | null;
  resetCheckbox?: () => void;
}

const DriveTypeForm = React.forwardRef(
  ({ driveType, disabled, updateCheckedItems }: DriveTypeFormProps, ref: React.Ref<DriveTypeFormRef>) => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);

    const [checkedNames, setCheckedNames] = useState<string[]>([]);
    const [clear, setClear] = useState<boolean>();

    useImperativeHandle(ref, () => ({
      form: formRef.current,
      resetCheckbox,
    }));

    const resetCheckbox = () => {
      setClear(true);
      updateCheckedItems?.([]);
    };

    const onCheck = (checked: boolean, name: string) => {
      form.resetFields([name]);
      const copyCheckedNames = [...checkedNames];
      const index = checkedNames.indexOf(name);
      if (checked && index === -1) {
        copyCheckedNames.push(name);
      } else if (!checked && index > -1) {
        copyCheckedNames.splice(index, 1);
      }
      setCheckedNames(copyCheckedNames);
      updateCheckedItems?.(copyCheckedNames);
    };

    const items = formItemsByDriveType[driveType];
    return (
      <Form form={form} ref={formRef}>
        <Row>
          {(items || []).map((item: any) => (
            <Col span={24}>
              <Form.Item label={item.label} key={item.label} required>
                <CustomFormItem
                  key={item.label}
                  {...item}
                  disabled={disabled}
                  onCheck={onCheck}
                  clear={clear}
                  setClear={() => setClear(false)}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Form>
    );
  }
);

export default DriveTypeForm;
