import { ActionType, ActionTypeDisplay } from '@/pages/Account/utils';
import { AddRateParams, RatePageRes, addRate, updateRate } from '@/api/workingSubstance';
import { Key, Modal, Form, DatePicker, InputNumber } from '@maxtropy/components';
import dayjs from 'dayjs';
import { useEffect } from 'react';

interface Props {
  isShow: ActionType;
  setIsShow: (v: ActionType | undefined) => void;
  updateFn: () => void;
  precision?: number;
  unit?: string;
  mediumContractId?: Key;
  data?: RatePageRes;
}

const CreateForm: React.FC<Props> = ({ isShow, setIsShow, updateFn, unit, precision, mediumContractId, data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        startTime: dayjs(data.startTime),
        price: data.price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onFinish = (v: AddRateParams) => {
    if (mediumContractId) {
      if (isShow === ActionType.CREATE) {
        const params = {
          mediumContractId,
          startTime: dayjs(v.startTime).format('YYYY-MM-DD'),
          price: v.price,
        };
        addRate(params).then(() => {
          updateFn();
          setIsShow(undefined);
        });
      }
    }
    if (data && data.id) {
      if (isShow === ActionType.UPDATE) {
        const params = {
          id: data.id,
          price: v.price,
        };
        updateRate(params).then(() => {
          updateFn();
          setIsShow(undefined);
        });
      }
    }
  };

  return (
    <>
      <Modal
        title={`${ActionTypeDisplay[isShow]}费率区间`}
        open={!!isShow}
        contentClassName="modal-form-content"
        onCancel={() => {
          setIsShow(undefined);
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={(v: AddRateParams) => {
            onFinish(v);
          }}
        >
          <Form.Item name="startTime" label="开始时间" rules={[{ required: true, message: '请选择开始时间' }]}>
            <DatePicker style={{ width: '100%' }} placeholder="请选择" disabled={isShow === ActionType.UPDATE} />
          </Form.Item>
          <Form.Item name="price" label="费率" rules={[{ required: true, message: '请输入费率' }]}>
            <InputNumber style={{ width: '100%' }} addonAfter={`元/${unit ?? '--'}`} precision={precision ?? 2} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateForm;
