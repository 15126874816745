import { Tabs, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { FC } from 'react';
import { TabsProps } from 'antd';
import styles from './index.module.scss';
import SaleMeterElectricityRecordList from './List';
import MeterRecord from './MeterRecord';

const ElectricityRecord: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '抄表记录',
      children: <SaleMeterElectricityRecordList />,
    },
    {
      key: '2',
      label: '抄表日志',
      children: <MeterRecord />,
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.content}>
      <Tabs defaultActiveKey="1" className={styles.tabs} items={tabItems} destroyInactiveTabPane />
    </Wrapper>
  );
};

export default ElectricityRecord;
