import React, { useEffect, useState } from 'react';
import { Spin, Space } from 'antd';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useUpdate,
  BaseInfoContent,
  SubContent,
  Form,
  FormTitle,
} from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { apiV2OutputProductDetailPost } from '@maxtropy/device-customer-apis-v2';
import AddBom, { SelectedRows } from './AddBom';
import BomTable, { BomItem } from './BomTable';
import SetQuantity from './AddBom/SetQuantity';
import styles from './index.module.scss';

export interface BasicData {
  code?: string;
  name?: string;
  spec?: string;
}

const BomDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [nextVisible, setNextVisible] = useState<boolean>(false);
  const [detail, setDetail] = useState<BasicData>({});
  const [selectedData, setSelectedData] = useState<SelectedRows>();
  const [addBomData, setAddBomData] = useState<BomItem>({});

  const [update, updateFn] = useUpdate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    apiV2OutputProductDetailPost({ id: Number(id) })
      .then(res => {
        if (res) {
          setDetail({
            code: res.code,
            name: res.name,
            spec: res.spec,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  const openNext = (selectedRows: SelectedRows) => {
    setSelectedData(selectedRows);
    setNextVisible(true);
  };

  const openAdd = (item: BomItem) => {
    setAddBomData(item);
    setVisible(true);
  };

  const routes = [{ name: 'BOM' }];

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <div>
        <Spin spinning={loading}>
          <FormTitle title="BOM" />
          <SubContent title="基础信息">
            <BaseInfoContent layout="horizontal">
              <Form.Item label="产出物编码">{detail.code ?? '--'}</Form.Item>
              <Form.Item label="产出物名称">{detail.name ?? '--'}</Form.Item>
              <Form.Item label="规格型号">{detail.spec ?? '--'}</Form.Item>
            </BaseInfoContent>
          </SubContent>
          <SubContent title="产品BOM">
            <div style={{ marginBottom: '10px' }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setVisible(true);
                  setAddBomData({});
                }}
              >
                添加一级物料
              </Button>
            </div>
            <BomTable update={update} updateFn={updateFn} id={Number(id)} basicData={detail} openAdd={openAdd} />
          </SubContent>
        </Spin>
        <Space className="sticky-footer" style={{ paddingLeft: 34 }}>
          <Button
            onClick={() =>
              navigate('/energy/output/config', {
                replace: true,
              })
            }
          >
            返回
          </Button>
        </Space>
        {visible && <AddBom id={Number(id)} setVisible={setVisible} openNext={openNext} />}
        {nextVisible && (
          <SetQuantity
            data={selectedData}
            parentData={addBomData}
            outputProductId={Number(id)}
            updateFn={updateFn}
            setVisible={setNextVisible}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default BomDetail;
