import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { Button, DatePicker, Form, Modal, Select, TreeSelect } from '@maxtropy/components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { EnergyUnitItem } from '@/api/energyUnitDashboard';
import { EnergyUnitContext } from '..';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import {
  apiV2EnergyAnalysisListByOuPost,
  apiV2EnergyAnalysisTreePost,
  apiV2EnergyUnitBoardRecomputePost,
} from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface CalcModalProps {
  show?: boolean;
  initDates?: RangeValue;
  close: () => void;
}

interface OptionProps {
  label: string;
  value: number;
}

const CalcModal: React.FC<CalcModalProps> = ({ show = false, close, initDates }) => {
  const { groupId } = useContext(EnergyUnitContext);
  const [groupOptions, setGroupOptions] = useState<OptionProps[]>([]);
  const [value, setValue] = useState<RangeValue>(null);
  const [dates, setDates] = useState<RangeValue>();
  const [tipModalOpen, setTipModalOpen] = useState<boolean>(false);
  const [calcModalOpen, setCalcModalOpen] = useState<boolean>(false);
  const [defaultData, setDefaultData] = useState<EnergyUnitItem[]>([]);
  const [form] = Form.useForm();
  const hasUsePermission = useHasPermission(PermissionsType.B_DATA_RECALCULATION);

  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setGroupOptions((res.list ?? []).map(item => ({ label: item.name as string, value: item.id as number })));
    });
  }, []);

  useEffect(() => {
    setTipModalOpen(show);
    form.setFieldsValue({ unit: undefined });

    if (groupOptions.length > 0 && !isNil(groupId)) {
      form.setFieldsValue({ group: groupId });
      getDefaultData(groupId);
    }
    if (initDates && initDates[0] && initDates[1]) {
      form.setFieldsValue({ date: [...initDates] });
    } else {
      form.setFieldsValue({ date: [null, null] });
    }
  }, [groupOptions, groupId, form, initDates, show]);

  const getDefaultData = (currentGroupId: number) => {
    if (isNil(currentGroupId)) return;
    apiV2EnergyAnalysisTreePost({ energyUnitGroupId: String(currentGroupId) }).then(res => {
      setDefaultData((res.list ?? []) as EnergyUnitItem[]);
    });
  };

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') >= 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') >= 6;
    const afterToday = current && current > dayjs().endOf('day');
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        const { date, unit } = values;
        apiV2EnergyUnitBoardRecomputePost({
          energyUnitIds: unit.map((i: { value: any }) => i.value),
          startDate: date[0].format('YYYY-MM-DD'),
          endDate: date[1].format('YYYY-MM-DD'),
        }).then(res => {
          setCalcModalOpen(false);
        });
      })
      .catch(err => {});
  };

  const footerBtns = useMemo(() => {
    let btns = [
      <Button
        key="back"
        onClick={() => {
          close();
        }}
        className={styles.cancelBtn}
      >
        取消
      </Button>,
    ];
    if (hasUsePermission) {
      btns.push(
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setCalcModalOpen(true);
            close();
          }}
        >
          用能重计算
        </Button>
      );
    }
    return btns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUsePermission]);

  return (
    <>
      <Modal
        title={
          <div className={styles.titleTxt}>
            <ExclamationCircleFilled style={{ color: 'var(--mx-warning-color)', fontSize: 16 }} />
            <span>数据异常说明</span>
          </div>
        }
        open={tipModalOpen}
        size="small"
        closable={false}
        className={styles.tipModalBox}
        footer={footerBtns}
      >
        <p className={styles.descTxt}>采集用能数据异常、历史指标配置发生变更会影响数据准确性，需要重计算。</p>
        <p className={styles.descTxt}>重计算大约需要5-30分钟，数据依然异常可联系系统管理员排查。</p>
      </Modal>
      <Modal
        title="重计算"
        forceRender
        className={styles.calcModalBox}
        open={calcModalOpen}
        afterClose={() => form.resetFields()}
        onCancel={() => setCalcModalOpen(false)}
        contentClassName="modal-form-content"
        footer={[
          <Button key="back" onClick={() => setCalcModalOpen(false)} className={styles.cancelBtn}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={onFinish}>
            确定
          </Button>,
        ]}
      >
        <div className={styles.modalBody}>
          <div className={styles.tipWrapper}>
            <ExclamationCircleOutlined style={{ color: 'var(--mx-warning-color)', fontSize: 12 }} />
            <span className={styles.txt}>重计算数值会覆盖现有数据</span>
          </div>

          <Form form={form}>
            <Form.Item label="选择时间" name="date" rules={[{ required: true, message: '请选择时间' }]}>
              <RangePicker
                value={value || dates}
                disabledDate={disabledDate}
                onCalendarChange={val => {
                  setDates(val);
                }}
                onChange={val => {
                  setValue(val);
                }}
                onOpenChange={onOpenChange}
              />
            </Form.Item>
            <Form.Item label="用能分析组" name="group" required>
              <Select
                placeholder="请选择"
                options={groupOptions}
                onChange={val => {
                  getDefaultData(val);
                  form.setFieldValue('unit', []);
                }}
              ></Select>
            </Form.Item>
            <Form.Item label="用能单元" name="unit" rules={[{ required: true, message: '请选择用能单元' }]}>
              <TreeSelect
                popupClassName={styles.treeSelect}
                treeData={defaultData}
                fieldNames={{ label: 'name', value: 'energyUnitId', children: 'children' }}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_ALL}
                treeCheckStrictly={true}
                placeholder="请选择"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default CalcModal;
