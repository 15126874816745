import { Button, Key, useUpdate, Tabs, Form, ShowInput } from '@maxtropy/components';
import { Card, Col, Divider, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import type { TabsProps } from 'antd';
import WorkOrderTable from '../WorkOrderTable';
import WorkStationTable from '../WorkStationTable';
import EnergyUnitTable from '../EnergyUnitTable';
import { DataNode } from 'antd/es/tree';
import ProductionBaseIcon from '../../assets/productionBase.png';
import workCenterIcon from '../../assets/workCenter.png';
import workOrderIcon from '../../assets/productionProcesse.png';
import workStationIcon from '../../assets/workStation.png';
import { WorkOrderRes, getWorkOrderDetailByLinkId, getWorkStationDetailByLinkId } from '@/api/energyUnitMapping';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { isNil } from 'lodash';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import qs from 'qs';
import WorkCenterSortModal from '../WorkCenterSortModal';
import ProductionBaseTable from '../ProductionBaseTable';
import {
  apiV2ProductionBaseDetailPost,
  apiV2WorkCenterDetailPost,
  V2ProductionBaseDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';
interface Props {
  selectTreeKey: Key[];
  selectedNode?: DataNode;
  onJumpToTreeItem: (v: Key[]) => void;
  treeUpdateFn: () => void;
  searchUpdate: number;
  titleIcon: string;
  productionBaseId: number;
}

const detailLabel = (v: string) => {
  return <span style={{ color: 'rgba(255,255,255,0.65)' }}>{v}</span>;
};

const RightContent: React.FC<Props> = ({
  searchUpdate,
  treeUpdateFn,
  selectTreeKey,
  selectedNode,
  onJumpToTreeItem,
  productionBaseId,
  titleIcon,
}) => {
  const [contentType, setContentType] = useState<string>();
  const [form] = Form.useForm();
  const [workCenterId, setWorkCenterId] = useState<Key>();
  const [workOrderLinkId, setWorkOrderLinkId] = useState<Key>();
  const [workStationLinkId, setWorkStationLinkId] = useState<Key>();
  const [infoLoading, setInfoLoading] = useState<boolean>(false);
  const [workCenterDetail, setWorkCenterDetail] = useState<V2ProductionBaseDetailPostResponse>();
  const [workOrderDetail, setWorkOrderDetail] = useState<WorkOrderRes>();
  const [infoUpdate, infoUpdateFn] = useUpdate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const hasTopo = useHasPermission(PermissionsType.B_WORKCENTERSTRUCTUREDIAGRAM);
  const [activeKey, setActiveKey] = useState<string>('1');

  const ProductionBaseTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `工作中心`,
      children: (
        <ProductionBaseTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          setWorkCenterDetail={setWorkCenterDetail}
          searchUpdate={searchUpdate}
          treeUpdateFn={treeUpdateFn}
          workCenterDetail={workCenterDetail}
          workCenterId={workCenterId}
          contentType={contentType}
          productionBaseId={productionBaseId as number}
          onJumpToTreeItem={onJumpToTreeItem}
        />
      ),
    },
    {
      key: '2',
      label: `工序`,
      children: (
        <WorkOrderTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          treeUpdateFn={treeUpdateFn}
          workCenterDetail={workCenterDetail}
          workCenterId={workCenterId}
          contentType={contentType}
          productionBaseId={productionBaseId as number}
          onJumpToTreeItem={onJumpToTreeItem}
        />
      ),
    },
    {
      key: '3',
      label: `工站`,
      children: (
        <WorkStationTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          treeUpdateFn={treeUpdateFn}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          onJumpToTreeItem={onJumpToTreeItem}
          productionBaseId={productionBaseId as number}
          workOrderDetail={workOrderDetail}
        />
      ),
    },
    {
      key: '4',
      label: `用能单元`,
      children: (
        <EnergyUnitTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          productionBaseId={productionBaseId as number}
          workStationLinkId={workStationLinkId}
        />
      ),
    },
  ];

  const workCenterTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `工序`,
      children: (
        <WorkOrderTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          productionBaseId={productionBaseId as number}
          treeUpdateFn={treeUpdateFn}
          workCenterDetail={workCenterDetail}
          workCenterId={workCenterId}
          contentType={contentType}
          onJumpToTreeItem={onJumpToTreeItem}
        />
      ),
    },
    {
      key: '2',
      label: `工站`,
      children: (
        <WorkStationTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          productionBaseId={productionBaseId as number}
          searchUpdate={searchUpdate}
          treeUpdateFn={treeUpdateFn}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          onJumpToTreeItem={onJumpToTreeItem}
          workOrderDetail={workOrderDetail}
        />
      ),
    },
    {
      key: '3',
      label: `用能单元`,
      children: (
        <EnergyUnitTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          productionBaseId={productionBaseId as number}
          searchUpdate={searchUpdate}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          workStationLinkId={workStationLinkId}
        />
      ),
    },
  ];

  const workOrderTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `工站`,
      children: (
        <WorkStationTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          treeUpdateFn={treeUpdateFn}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          productionBaseId={productionBaseId as number}
          onJumpToTreeItem={onJumpToTreeItem}
          workOrderDetail={workOrderDetail}
        />
      ),
    },
    {
      key: '2',
      label: `用能单元`,
      children: (
        <EnergyUnitTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          contentType={contentType}
          workCenterId={workCenterId}
          productionBaseId={productionBaseId as number}
          workOrderLinkId={workOrderLinkId}
          workStationLinkId={workStationLinkId}
        />
      ),
    },
  ];

  const workStationTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: `用能单元`,
      children: (
        <EnergyUnitTable
          activeKey={activeKey}
          infoUpdateFn={infoUpdateFn}
          searchUpdate={searchUpdate}
          productionBaseId={productionBaseId as number}
          contentType={contentType}
          workCenterId={workCenterId}
          workOrderLinkId={workOrderLinkId}
          workStationLinkId={workStationLinkId}
        />
      ),
    },
  ];

  useEffect(() => {
    if (selectTreeKey && selectTreeKey.length > 0) {
      const key = (selectTreeKey.at(0) as string).split('-');
      setContentType(key[0]);
      if (key[0] === 'productionBase') {
        setInfoLoading(true);
        apiV2ProductionBaseDetailPost({ id: key[1] })
          .then(res => {
            if (res) {
              form.setFieldsValue({
                code: res.code ?? undefined,
                customerName: res.customerName ?? undefined,
                updateName: res.opUpdateName ?? undefined,
                updateTime: isNil(res.opUpdateTime) ? undefined : dayjs(res.opUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          })
          .finally(() => {
            setInfoLoading(false);
          });
      } else if (key[0] === 'workCenter') {
        setWorkCenterId(Number(key[1]));
        // 工作中心详情
        setInfoLoading(true);
        apiV2WorkCenterDetailPost({ id: key[1] })
          .then(res => {
            if (res) {
              setWorkCenterDetail(res);
              form.setFieldsValue({
                code: res.code ?? undefined,
                customerName: res.customerName ?? undefined,
                updateName: res.opUpdateName ?? undefined,
                updateTime: isNil(res.opUpdateTime) ? undefined : dayjs(res.opUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          })
          .finally(() => {
            setInfoLoading(false);
          });
      } else if (key[0] === 'workOrder') {
        setWorkCenterId(Number(key[1]));
        setWorkOrderLinkId(Number(key[2]));
        // 工序详情
        setInfoLoading(true);
        getWorkOrderDetailByLinkId(key[2])
          .then(res => {
            if (res) {
              setWorkOrderDetail(res);
              form.setFieldsValue({
                code: res.code ?? undefined,
                updateName: res.opUpdateName ?? undefined,
                updateTime: isNil(res.opUpdateTime) ? undefined : dayjs(res.opUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          })
          .finally(() => {
            setInfoLoading(false);
          });
      } else if (key[0] === 'workStation') {
        setWorkCenterId(Number(key[1]));
        setWorkOrderLinkId(Number(key[2]));
        setWorkStationLinkId(Number(key[3]));
        // 工站详情
        setInfoLoading(true);
        getWorkStationDetailByLinkId(key[3])
          .then(res => {
            if (res) {
              form.setFieldsValue({
                code: res.code ?? undefined,
                updateName: res.opUpdateName ?? undefined,
                updateTime: isNil(res.opUpdateTime) ? undefined : dayjs(res.opUpdateTime).format('YYYY-MM-DD HH:mm:ss'),
              });
            }
          })
          .finally(() => {
            setInfoLoading(false);
          });
      }
    }
  }, [selectTreeKey, form, infoUpdate]);

  return (
    <div className={styles.rightContent}>
      <div className={styles.contentHeader}>
        <div>
          {contentType === 'productionBase' ? (
            <img src={titleIcon ?? ProductionBaseIcon} title="生产基地" alt="" className={styles.icon} />
          ) : contentType === 'workCenter' ? (
            <img src={titleIcon ?? workCenterIcon} title="工作中心" alt="" className={styles.icon} />
          ) : contentType === 'workOrder' ? (
            <img src={titleIcon ?? workOrderIcon} title="工序" alt="" className={styles.icon} />
          ) : (
            <img src={titleIcon ?? workStationIcon} title="工站" alt="" className={styles.icon} />
          )}
          <span className={styles.titleStyle}>{selectedNode?.title as React.ReactNode}</span>
        </div>

        <div>
          <Space size={8}>
            {/* <Button type="primary" onClick={() => infoUpdateFn()}>
              刷新
            </Button> */}
            <Button type="primary" onClick={() => setModalVisible(true)}>
              {contentType === 'productionBase' ? '生产基地排序' : '工作中心排序'}
            </Button>
            {hasTopo && contentType === 'workCenter' && (
              <div>
                <Button
                  type="primary"
                  href={`/productionManagement/production/WorkCenterTopoPage?${qs.stringify(
                    { prevWorkCenterId: workCenterId, productionBaseId: productionBaseId },
                    { indices: false }
                  )}`}
                  target="_blank"
                >
                  用能流向拓扑图
                </Button>
              </div>
            )}
            {hasTopo && contentType === 'productionBase' && (
              <div>
                <Button
                  type="primary"
                  href={`/productionManagement/production/WorkCenterTopoPage?${qs.stringify(
                    { prevWorkCenterId: undefined, productionBaseId: productionBaseId },
                    { indices: false }
                  )}`}
                  target="_blank"
                >
                  用能流向拓扑图
                </Button>
              </div>
            )}
          </Space>
        </div>
      </div>
      <Card className={styles.infoCard} size="small">
        <Spin spinning={infoLoading}>
          <Form form={form} layout="vertical" size="small">
            <Row>
              {contentType === 'productionBase' ? (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('生产基地编码')} name="code">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              ) : contentType === 'workCenter' ? (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('工作中心编码')} name="code">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              ) : contentType === 'workOrder' ? (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('工序编码')} name="code">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              ) : (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('工站编码')} name="code">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              )}
              {contentType === 'workCenter' && (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('所属组织')} name="customerName">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              )}
              {contentType === 'productionBase' && (
                <>
                  <Col span={4}>
                    <Form.Item label={detailLabel('所属组织')} name="customerName">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Divider className={styles.dividerStyle} type="vertical" />
                </>
              )}
              <Col span={4}>
                <Form.Item label={detailLabel('最后操作人')} name="updateName">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Divider className={styles.dividerStyle} type="vertical" />
              <Col span={4}>
                <Form.Item label={detailLabel('最后操作时间')} name="updateTime">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>

      <div className={styles.tab_box}>
        <div className={styles.line} />
        <div className={styles.tabs}>
          <Tabs
            items={
              contentType === 'productionBase'
                ? ProductionBaseTabItems
                : contentType === 'workCenter'
                ? workCenterTabItems
                : contentType === 'workOrder'
                ? workOrderTabItems
                : workStationTabItems
            }
            onChange={key => setActiveKey(key)}
            activeKey={activeKey}
          />
        </div>
      </div>
      <WorkCenterSortModal
        open={modalVisible}
        contentType={contentType}
        ok={() => {
          setModalVisible(false);
          treeUpdateFn();
        }}
        cancel={() => setModalVisible(false)}
      />
    </div>
  );
};

export default RightContent;
