import { Col, Form, Row } from 'antd';
import { FC } from 'react';
import {
  ElectricitySaleContractSettlementType,
  HasTimeOfUseElectricityPricePropsType,
  TwoPartSystemElectricityPriceType,
  TwoPartSystemElectricityPriceTypeValue,
  TwoPartSystemPowerConsumptionType,
  TwoPartSystemPowerConsumptionTypeValue,
} from '../../../../../api/electricitySettlementRules';
import { ShowInput } from '@maxtropy/components';

const TwoPartSystemDetails: FC = () => {
  return (
    <div>
      <Form.Item noStyle dependencies={['settlementType', 'declarationType', 'rateType']}>
        {({ getFieldValue }) => {
          let a: TwoPartSystemPowerConsumptionType = getFieldValue('declarationType');
          let b: TwoPartSystemElectricityPriceType = getFieldValue('rateType');
          if (a === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION) {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <div
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      padding: '20px',
                      paddingBottom: '0px',
                      fontWeight: 700,
                    }}
                  >
                    {' '}
                    基本电价：
                  </div>
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                    <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                      用电类型：
                    </Col>
                    <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemPowerConsumptionTypeValue[a]}
                    </Col>
                  </Row>
                </>
              )
            );
          } else if (a === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION) {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <div
                    style={{
                      backgroundColor: 'rgba(var(--base-text-color), 0.04)',
                      padding: '20px',
                      paddingBottom: '0px',
                      fontWeight: 700,
                    }}
                  >
                    {' '}
                    基本电价：
                  </div>
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                    <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemPowerConsumptionTypeValue[a]}：
                    </Col>
                    <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemElectricityPriceTypeValue[b]}
                    </Col>
                  </Row>
                </>
              )
            );
          } else {
            return <></>;
          }
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY && (
              <>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    变压器容量：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('transformerCapacity')} kVA
                  </Col>
                </Row>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    容量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('unitCapacityRate')} 元/kVA
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        dependencies={['declarationType', 'settlementType', 'rateType', 'declaredDemand', 'unitDeclaredDemandRate']}
      >
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND && (
              <>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    变压器容量：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('transformerCapacity')} kVA
                  </Col>
                </Row>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    申报需量：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('declaredDemand')}kW
                  </Col>
                </Row>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    申报需量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('unitDeclaredDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType', 'unitDemandRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND && (
              <>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    变压器容量：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('transformerCapacity')} kVA
                  </Col>
                </Row>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    实际需量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('unitDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['declarationType', 'settlementType', 'rateType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    变压器容量：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('transformerCapacity')}kVA
                  </Col>
                </Row>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    容量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('unitCapacityRate')}元/kVA
                  </Col>
                </Row>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    需量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('unitDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            (getFieldValue('timeOfDayRateChecked') ?? []).length > 0 && (
              <Row>
                <Col span={24} style={{ paddingBottom: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <div style={{ padding: '20px', fontSize: '14px', fontWeight: 700, paddingBottom: '0px' }}>
                    {' '}
                    电度电价：
                  </div>
                </Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      {/* 时段选择尖峰平谷输入框 */}
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'summitRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('summitRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex="130px" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>尖峰时段电价：</div>
                  </Col>
                  <Col flex="auto" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('summitRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
        <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'peakRate']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('peakRate') &&
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col flex="130px" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>峰时段电价：</div>
                  </Col>
                  <Col flex="auto" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('peakRate')} 元/kWh
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'plainRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('plainRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>平时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('plainRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'rateType', 'settlementType', 'valleyRate']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('valleyRate') &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>谷时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('valleyRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      {/* 两部制扣除子表费用 */}
      <Row style={{ marginTop: '30px' }}>
        <Form.Item noStyle dependencies={['hasSubMeterRateDeduct', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col span={8}>
                    <Form.Item label="是否扣除子表费用">
                      {getFieldValue('hasSubMeterRateDeduct') === true ? '是' : '否'}
                    </Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>

      <Form.Item noStyle dependencies={['hasSubMeterRateDeduct']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('hasSubMeterRateDeduct') === true &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <Row
                style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', marginTop: '30px' }}
              >
                <Col span={8}>
                  <div style={{ fontWeight: 600 }}>扣除子表费用：</div>
                </Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      <Form.Item noStyle dependencies={['hasSubMeterRateDeduct']}>
        {({ getFieldValue }) => {
          return (
            getFieldValue('hasSubMeterRateDeduct') === true && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex="130px" style={{ paddingLeft: '20px' }}>
                    需量单价：
                  </Col>
                  <Col flex="auto" style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      {/* <Form.Item noStyle dependencies={["hasSubMeterRateDeduct", 'isSubMeterRateConsistent', 'rateType', 'declarationType']}>
        {
          ({ getFieldValue }) => {
            let a: TwoPartSystemPowerConsumptionType = getFieldValue('declarationType');
            let b: TwoPartSystemElectricityPriceType = getFieldValue('rateType');
            let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
            let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
            if (a === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION && ishasSubMeterRateDeduct && isisSubMeterRateConsistent) {
              return getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                    <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                      用电类型：
                    </Col>
                    <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemPowerConsumptionTypeValue[a]}
                    </Col>
                  </Row>
                </>

              )
            } else if (a === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && ishasSubMeterRateDeduct && isisSubMeterRateConsistent) {
              return getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <div style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px', paddingBottom: '0px', fontWeight: 700 }}> 基本电价：</div>
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                    <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemPowerConsumptionTypeValue[a]}：
                    </Col>
                    <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                      {TwoPartSystemElectricityPriceTypeValue[b]}
                    </Col>
                  </Row>
                </>
              )
            } else {
              return <></>
            }
          }
        }
      </Form.Item> */}

      {/* <Form.Item noStyle dependencies={["declarationType", 'settlementType', 'rateType']}>
        {
          ({ getFieldValue }) => {
            return getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY && (
              <>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    容量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitCapacityRate')} 元/kVA
                  </Col>

                </Row>
              </>
            )
          }
        }
      </Form.Item>


      <Form.Item noStyle dependencies={["declarationType", 'settlementType', 'rateType']}>
        {
          ({ getFieldValue }) => {
            return getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND && (
              <>
                <Row style={{ padding: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    申报需量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitDeclaredDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          }
        }
      </Form.Item>


      <Form.Item noStyle dependencies={["declarationType", 'settlementType', 'rateType']}>
        {
          ({ getFieldValue }) => {
            return getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION && getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && getFieldValue('rateType') === TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px' }}>
                    实际需量单价：
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitDemandRate')}元/kW
                  </Col>
                </Row>
              </>
            )
          }
        }
      </Form.Item>

      <Form.Item noStyle dependencies={["declarationType", 'settlementType', 'rateType']}>
        {
          ({ getFieldValue }) => {
            return getFieldValue('declarationType') === TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION && getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col span={3} style={{ paddingLeft: '20px' }}>
                    容量单价：
                  </Col>
                  <Col span={21} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitCapacityRate')}元/kVA
                  </Col>

                </Row>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                  <Col span={3} style={{ paddingLeft: '20px' }}>
                    需量单价：
                  </Col>
                  <Col span={21} style={{ paddingLeft: '20px' }}>
                    {getFieldValue('subMeterUnitDemandRate')}元/kW
                  </Col>

                </Row>
              </>
            )
          }
        }
      </Form.Item> */}

      <Form.Item noStyle dependencies={['timeOfDayRateChecked', 'settlementType', 'hasSubMeterRateDeduct']}>
        {({ getFieldValue }) => {
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          return (
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM &&
            ishasSubMeterRateDeduct && (
              <Row>
                <Col span={24} style={{ paddingBottom: '20px', backgroundColor: 'rgba(var(--base-text-color), 0.04)' }}>
                  <div style={{ padding: '20px', fontSize: '14px', fontWeight: 700, paddingBottom: '0px' }}>
                    {' '}
                    电度电价：
                  </div>
                </Col>
              </Row>
            )
          );
        }}
      </Form.Item>

      {/* 时段选择尖峰平谷输入框 */}
      <Form.Item
        noStyle
        dependencies={[
          'timeOfDayRateChecked',
          'rateType',
          'settlementType',
          'subMeterSummitRate',
          'hasSubMeterRateDeduct',
        ]}
      >
        {({ getFieldValue }) => {
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          return (
            getFieldValue('subMeterSummitRate') &&
            ishasSubMeterRateDeduct &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.SHARPPEAK) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex="130px" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>尖峰时段电价：</div>
                  </Col>
                  <Col flex="auto" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('subMeterSummitRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
        <Form.Item
          noStyle
          dependencies={[
            'timeOfDayRateChecked',
            'rateType',
            'settlementType',
            'subMeterPeakRate',
            'hasSubMeterRateDeduct',
          ]}
        >
          {({ getFieldValue }) => {
            let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
            // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
            return (
              getFieldValue('subMeterPeakRate') &&
              ishasSubMeterRateDeduct &&
              (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.PEAK) &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col flex="130px" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>峰时段电价：</div>
                  </Col>
                  <Col flex="auto" style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('subMeterPeakRate')} 元/kWh
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
      <Form.Item
        noStyle
        dependencies={[
          'timeOfDayRateChecked',
          'rateType',
          'settlementType',
          'subMeterPlainRate',
          'hasSubMeterRateDeduct',
        ]}
      >
        {({ getFieldValue }) => {
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          return (
            getFieldValue('subMeterPlainRate') &&
            ishasSubMeterRateDeduct &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.AVERAGE) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>平时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('subMeterPlainRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>
      <Form.Item
        noStyle
        dependencies={[
          'timeOfDayRateChecked',
          'rateType',
          'settlementType',
          'subMeterValleyRate',
          'hasSubMeterRateDeduct',
        ]}
      >
        {({ getFieldValue }) => {
          let ishasSubMeterRateDeduct: boolean = getFieldValue('hasSubMeterRateDeduct') === true;
          // let isisSubMeterRateConsistent: boolean = getFieldValue('isSubMeterRateConsistent') === false;
          return (
            getFieldValue('subMeterValleyRate') &&
            ishasSubMeterRateDeduct &&
            (getFieldValue('timeOfDayRateChecked') ?? []).includes(HasTimeOfUseElectricityPricePropsType.VALLEY) &&
            getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
              <>
                <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', paddingLeft: '20px' }}>
                  <Col flex={'130px'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    <div>谷时段电价：</div>
                  </Col>
                  <Col flex={'auto'} style={{ paddingLeft: '20px', marginBottom: '30px' }}>
                    {getFieldValue('subMeterValleyRate')} 元/kWh
                  </Col>
                </Row>
              </>
            )
          );
        }}
      </Form.Item>

      <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
        <Form.Item noStyle dependencies={['settlementType', 'hasLightingTariff']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col span={5}>
                    <Form.Item label="是否有照明费用">{getFieldValue('hasLightingTariff') ? '是' : '否'}</Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>

        <Form.Item noStyle dependencies={['hasLightingTariff', 'settlementType']}>
          {({ getFieldValue }) => {
            return (
              getFieldValue('hasLightingTariff') === true &&
              getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                <>
                  <Col span={5}>
                    <Form.Item name="lightingTariffRatio" label="定比">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="unitLightingTariffRate" label="照明电费单价">
                      <ShowInput />
                    </Form.Item>
                  </Col>
                </>
              )
            );
          }}
        </Form.Item>
      </Row>
    </div>
  );
};

export default TwoPartSystemDetails;
