import { Button, EllipsisSpan, Key, Paging, Table, usePaging, useUpdate, Form, Input } from '@maxtropy/components';
import { useEffect, useState } from 'react';
import BindUnitModal from '../BindUnitModal';
import { OrderEnum, UnitPageRes, UnitQuery, getUnitPageById } from '@/api/energyUnitMapping';
import { SorterResult } from 'antd/es/table/interface';
import { isNil } from 'lodash';
import UnitDetailModal from '../UnitDetailModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import styles from './index.module.scss';
import { apiV2EnergyAnalysisUnitConfGroupEffectMirrorDetailPost } from '@maxtropy/device-customer-apis-v2';
import { UnitConfGroupDetailEffectMirrorResponse } from '@/pages/EnergyConsumption/Config';
interface Props {
  contentType?: string;
  workCenterId?: Key;
  workOrderLinkId?: Key;
  workStationLinkId?: Key;
  productionBaseId: number;
  searchUpdate: number;
  infoUpdateFn: () => void;
  activeKey: string;
}

const columns = [
  {
    title: '用能单元名称',
    dataIndex: 'energyUnitName',
    ellipsis: { showTitle: true },
    sorter: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属用能分析组',
    dataIndex: 'energyAnalysisGroupName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属工站',
    dataIndex: 'workStationName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工站编码',
    dataIndex: 'workStationCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属工序',
    dataIndex: 'workProcedureName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '工序编码',
    dataIndex: 'workProcedureCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
interface SearchParams extends Omit<UnitQuery, 'page' | 'size' | 'workCenterId'> {}

const EnergyUnitTable: React.FC<Props> = ({
  searchUpdate,
  workCenterId,
  workOrderLinkId,
  workStationLinkId,
  contentType,
  infoUpdateFn,
  productionBaseId,
  activeKey,
}) => {
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [unitModalOpen, setUnitModalOpen] = useState<boolean>(false);
  const [data, setData] = useState<UnitPageRes[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [unitDetailOpen, setUnitDetailOpen] = useState<boolean>(false);
  const [energyUnitId, setEnergyUnitId] = useState<Key>();
  const [unitDetail, setUnitDetail] = useState<UnitConfGroupDetailEffectMirrorResponse>();
  const hasBindUnit = useHasPermission(PermissionsType.B_BINDINGENERGYUNIT); // 绑定用能单元
  const hasLookUnit = useHasPermission(PermissionsType.B_VIEWENERGYCONSUMPTIONUNITMAPP); // 查看用能单元
  const [update, updateFn] = useUpdate();
  const [form] = Form.useForm();

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (_: any, record: UnitPageRes) => {
        return (
          <>
            {hasLookUnit && (
              <Button
                type="link"
                onClick={() => {
                  setEnergyUnitId(record.energyUnitId);
                  setUnitDetailOpen(true);
                }}
              >
                查看
              </Button>
            )}
          </>
        );
      },
    },
  ];

  // 切换工站时，清空搜索条件
  useEffect(() => {
    if (contentType === 'workStation') {
      form.resetFields();
      setSearchParams(undefined);
      setPageOffset(1);
    }
  }, [searchUpdate, setPageOffset, form, contentType]);

  useEffect(() => {
    if (energyUnitId) {
      apiV2EnergyAnalysisUnitConfGroupEffectMirrorDetailPost({
        energyUnitGroupId: energyUnitId,
      }).then(setUnitDetail);
    }
  }, [energyUnitId]);

  useEffect(() => {
    if (contentType === 'productionBase') {
      if (productionBaseId) {
        setLoading(true);
        getUnitPageById({
          productionBaseId,
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (contentType === 'workCenter') {
      if (workCenterId && productionBaseId) {
        setLoading(true);
        getUnitPageById({
          ...searchParams,
          workCenterId,
          productionBaseId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (contentType === 'workOrder') {
      if (workCenterId && workOrderLinkId && productionBaseId) {
        setLoading(true);
        getUnitPageById({
          ...searchParams,
          workCenterId,
          productionBaseId,
          workProcedureLinkId: workOrderLinkId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (contentType === 'workStation') {
      if (workCenterId && workOrderLinkId && workStationLinkId && productionBaseId) {
        setLoading(true);
        getUnitPageById({
          ...searchParams,
          productionBaseId,
          workCenterId,
          workProcedureLinkId: workOrderLinkId,
          workStationLinkId,
          page: pageOffset,
          size: pageSize,
        })
          .then(res => {
            setData(res.list);
            setTotalCount(res.total);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    contentType,
    workOrderLinkId,
    workCenterId,
    workStationLinkId,
    searchParams,
    pageOffset,
    productionBaseId,
    pageSize,
    setTotalCount,
    update,
    activeKey,
  ]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        {hasBindUnit && contentType === 'workStation' && (
          <div style={{ marginRight: 8 }}>
            <Button
              type="primary"
              onClick={() => {
                setUnitModalOpen(true);
              }}
            >
              绑定用能单元
            </Button>
          </div>
        )}

        <Form form={form} className={styles.formItemStyles}>
          <Form.Item name="energyUnitName" style={{ marginBottom: 10 }}>
            <div style={{ width: 280 }}>
              <Input.Search
                placeholder="用能单元名称"
                onSearch={(v: string) => {
                  setSearchParams({
                    ...searchParams,
                    energyUnitName: v === '' ? undefined : v,
                  });
                  setPageOffset(1);
                }}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
      <Table
        loading={loading}
        rowKey="linkId"
        dataSource={data}
        columns={buildColumns}
        onChange={(pagination, filters, sorter) => {
          const { order } = sorter as SorterResult<UnitPageRes>;
          setSearchParams({
            ...searchParams,
            orderBy: isNil(order) ? undefined : order === 'ascend' ? OrderEnum.ASC : OrderEnum.DESC,
          });
          setPageOffset(1);
        }}
      />
      <Paging pagingInfo={pagingInfo} />
      {unitModalOpen && (
        <BindUnitModal
          infoUpdateFn={infoUpdateFn}
          open={unitModalOpen}
          setOpen={setUnitModalOpen}
          workStationLinkId={workStationLinkId}
          updateFn={updateFn}
          setPageOffset={setPageOffset}
        />
      )}
      {unitDetailOpen && (
        <UnitDetailModal
          setUnitDetail={setUnitDetail}
          detail={unitDetail}
          open={unitDetailOpen}
          setOpen={setUnitDetailOpen}
        />
      )}
    </>
  );
};

export default EnergyUnitTable;
