import DeviceImg from './images/device.png';
import { Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {}

const DeviceStatus: React.FC<Props> = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();

  return (
    <Wrapper className={styles.wrapperContent} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <img style={{ width: '100%' }} src={DeviceImg} alt="" />
    </Wrapper>
  );
};

export default DeviceStatus;
