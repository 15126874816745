import { OuStaffType } from '../../const';
import { fetch } from '@maxtropy/components';

export interface CommonUserInfo {
  id: any;
  staffId: number;
  humanCode?: string;
  staffType: OuStaffType;
  muid: string;
  username: string;
  staffCode: string;
  name: string;
  phone: string;
  orgName: string;
  unbindable?: boolean;
}

export interface Organization {
  mcid: string;
  name: string;
  hasPermission: boolean;
}

export interface OrganizationResponse {
  data: Organization;
  children: OrganizationResponse[];
}

export function getOrganizationWithCodeWithCurrent(): Promise<OrganizationResponse> {
  return fetch(
    `/api/v2/device/organization-with-code-with-current`,
    {
      method: 'POST',
    },
    true
  );
}
