import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Key, Button, Modal, Table } from '@maxtropy/components';
import { Space } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, ReactText, useEffect, useState } from 'react';
import {
  getElectricitySaleContractMeterList,
  SaleContactMeterResponse,
} from '../../../../../api/electricitySalesContract';
import { MeterFormValue } from '../../../../SalesContract/MeterForm';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { ElectricitySaleContractSettlementMeterType } from '../../../../../api/electricitySettlementRules';

interface EffectiveMeterFormProps {
  disabled?: boolean;
  saleElectricityContractId?: Key;
  addType?: string; // 区分电量还是需量表计
  value?: any[]; // 数据
  onChange?: (val: any[]) => void;
  meterType?: number;
  isEdit?: boolean;
  meterDatas?: any[];
}

const columns: ColumnType<MeterFormValue>[] = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'deviceTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join(',')} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')}` : '--'} />,
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')} ` : '--'} />,
  },
];

const modalColumns: ColumnType<SaleContactMeterResponse>[] = [
  {
    title: '表计编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备S/N',
    dataIndex: 'deviceSn',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '表计标签',
    dataIndex: 'meterTags',
    ellipsis: { showTitle: true },
    render: (v: Array<string>) => <EllipsisSpan value={v?.join(',')} />,
  },
  {
    title: '用电账户',
    dataIndex: 'electricityAccountNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始计费时间',
    dataIndex: 'startTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')} ` : '--'} />,
  },
  {
    title: '结束计费时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? `${dayjs(v).format('YYYY-MM-DD')} ` : '--'} />,
  },
];

const EffectiveMeterForm: FC<EffectiveMeterFormProps> = props => {
  const { disabled, saleElectricityContractId, addType, value, onChange, meterType, isEdit, meterDatas } = props;
  const [visible, setVisible] = useState<boolean>(false); // 添加编辑弹窗
  const [selectedRows, setSelectedRows] = useState<Array<SaleContactMeterResponse>>(); // 多选框添加
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>();

  EffectiveMeterForm.defaultProps = {
    disabled: false,
  };

  useEffect(() => {
    if (value) {
      setSelectedRows(value);
    }
  }, [value]);

  // 选择合同的时候，查询合同中的表计放在下表
  useEffect(() => {
    setIsModalLoading(true);
    // 选择合同的时候表计清空，modal的合同表计重新查询
    onChange!([]);
    if (saleElectricityContractId && meterType) {
      getElectricitySaleContractMeterList(saleElectricityContractId, meterType).then(res => {
        setData(res);
        setIsModalLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleElectricityContractId, meterType]);

  const onConfirm = (record: any) => {
    if (isEdit) {
      if (meterDatas?.map((i: { id: number }) => i.id).includes(record.id)) {
        if (!dayjs(dayjs().format('YYYY-MM-DD')).isBetween(record.startTime, record.endTime, null, '[]')) {
          Modal.confirm({
            title: '确定移除？',
            onOk: () => onRemove(record),
            onCancel: () => {},
          });
        } else {
          Modal.error({
            title: '此表计在生效过程中，无法移除。',
          });
        }
      } else {
        Modal.confirm({
          title: '确定移除？',
          onOk: () => onRemove(record),
          onCancel: () => {},
        });
      }
    } else {
      Modal.confirm({
        title: '确定移除？',
        onOk: () => onRemove(record),
        onCancel: () => {},
      });
    }
  };

  const onRemove = (record: SaleContactMeterResponse) => {
    const data = value!.filter((item: { id: number }) => item.id !== record!.id);
    // console.log(data)
    onChange && onChange(data);
    setSelectedRows(data);
  };

  const editColumns = [
    ...columns,
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            // width: 200,
            fixed: 'right' as const,
            render: (value: undefined, record: MeterFormValue) => {
              return (
                <Button
                  // disabled={isEdit ? (status ? status != ElectricitySaleContractStatusType.INEFFECTIVE && !!record.saleElectricityContractElectricityMeterInfoId
                  //   : true) : false}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() => {
                    onConfirm(record);
                  }}
                >
                  删除表计
                </Button>
              );
            },
          },
        ]),
  ];

  const onClose = () => {
    setVisible(false);
  };

  const onOk = async () => {
    try {
      onChange &&
        selectedRows &&
        onChange(
          selectedRows.map(item => ({
            ...item,
          }))
        );
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const onSelectChange = (rowKeys: ReactText[], rows: SaleContactMeterResponse[]) => {
    setSelectedRows(rows.filter(item => item));
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedRows?.map(item => item.id),
    onChange: onSelectChange,
    getCheckboxProps: (record: SaleContactMeterResponse) => ({
      disabled: (value?.map((item: { id: number }) => item.id) ?? []).includes(record.id),
    }),
  };

  return (
    <>
      {!disabled && (
        <Space direction="vertical" style={{ marginBottom: 8 }}>
          <Button disabled={!saleElectricityContractId} type="primary" onClick={() => setVisible(true)}>
            <PlusOutlined />
            添加{addType}计量表计
          </Button>
          <div />
        </Space>
      )}
      <Table rowKey={`id`} sticky loading={false} dataSource={value ?? []} columns={editColumns} pagination={false} />
      <Modal title={`表计选择`} size="big" open={visible} onCancel={onClose} destroyOnClose onOk={onOk}>
        <div className={styles.content}>
          <div className={styles.tips}>
            <span>{`已选择`}</span>
            <span className={styles.num}>{selectedRows?.length || 0}</span>
            <span>{`项`}</span>
          </div>
          <Table
            rowKey="id"
            rowSelection={{
              type:
                meterType === ElectricitySaleContractSettlementMeterType.DEMAND ||
                meterType === ElectricitySaleContractSettlementMeterType.SUBTABLE
                  ? 'radio'
                  : 'checkbox',
              ...rowSelection,
            }}
            scroll={{ y: 300 }}
            sticky
            loading={isModalLoading}
            columns={modalColumns}
            pagination={false}
            dataSource={data}
          />
          {/* <Paging pagingInfo={pagingInfo} /> */}
        </div>
      </Modal>
    </>
  );
};

export default EffectiveMeterForm;
