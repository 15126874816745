import { useState } from 'react';
import { Row, Col } from 'antd';
import { Modal, Button, Upload, getRealUrl, message, Form } from '@maxtropy/components';
import { DownloadOutlined } from '@ant-design/icons';
import { apiV2ExcelImportPost } from '@maxtropy/device-customer-apis-v2';
import { LogType } from '../ImportLog/Prop';

type Props = {
  title: string;
  setOpen: (visible: boolean) => void;
  updateFn: () => void;
  type: LogType;
};

const UploadImport = ({ title, type, setOpen, updateFn }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = () => {
    const { fileKey } = form.getFieldsValue();
    setLoading(true);
    apiV2ExcelImportPost({ fileKey, type })
      .then(res => {
        if (res?.fileKey) {
          Modal.error({
            title: '导入失败',
            content: (
              <>
                <span> 请现在下载错误信息,修改后重新导入。</span>
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  style={{ paddingLeft: 0 }}
                  href={getRealUrl(res.fileKey)}
                >
                  下载错误信息
                </Button>
              </>
            ),
            okText: '确定',
          });
          return;
        }

        message.success('导入成功');
        updateFn?.();
        setOpen(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={title}
      open={true}
      onCancel={() => setOpen(false)}
      contentClassName="modal-form-content"
      onOk={() => {
        form.submit();
      }}
      confirmLoading={loading}
    >
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Button
            type="link"
            style={{ paddingLeft: 0, marginBottom: 20 }}
            href={`/api/v2/excel/template/download?type=${type}`}
          >
            下载导入模版
          </Button>
          <Col span={24}>
            <Form.Item label="选择文件" rules={[{ required: true, message: '请先上传文件' }]} name="fileKey">
              <Upload
                listType="text"
                accept={['.xlsx']}
                uploadText="选择导入文件"
                fileSize={50}
                tip="支持扩展名：.xlsx，不超过50MB"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UploadImport;
