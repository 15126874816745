import ReactEcharts from 'echarts-for-react';
import ResizeObserver from 'rc-resize-observer';
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { TimeGranularityType } from '@/pages/ProductionBeatConfig/utils';
import { LineChartBackgroundItem, WaveFormChartItem, StandardDataItem } from '../RightContent';
import { isNil } from 'lodash-es';

interface WaveFormChartProps {
  lineChartData: WaveFormChartItem[];
  lineChartBackground: LineChartBackgroundItem[];
  standardData: StandardDataItem[];
  generalName: string;
  timeResolution: TimeGranularityType;
}

const bgColor = {
  normalBeat: 'rgba(82,196,26,0.1)',
  abnormalBeat: 'rgba(230,66,66,0.2)',
  abnormalInterval: 'rgba(250,173,20,0.2)',
};

const WaveFormChart: React.FC<WaveFormChartProps> = props => {
  const { timeResolution, generalName, lineChartData, standardData, lineChartBackground } = props;
  const [chartHeight, setChartHeight] = useState<string>('55%');
  const chartRef = useRef<ReactEcharts>();
  const [maxNumLength, setMaxNumLength] = useState(0);
  const [startAndEedArray, setStartAndEedArray] = useState<LineChartBackgroundItem[]>([]);

  useEffect(() => {
    const max = Math.max(...lineChartData.map(i => i.value as number));
    setMaxNumLength(max.toFixed(0).toString().length);
  }, [lineChartData]);

  useEffect(() => {
    const data = lineChartBackground.filter(i => i.type === 2);
    setStartAndEedArray(data);
  }, [lineChartBackground]);

  // 自适应窗口变化
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 自适应屏幕缩放
  const handleResize = () => {
    const viewportHeight = document.documentElement.clientHeight;
    let chartHeight;

    if (viewportHeight <= 900) {
      chartHeight = '55%';
    } else if (viewportHeight <= 1080) {
      chartHeight = '70%';
    } else if (viewportHeight <= 1440) {
      chartHeight = '76%';
    } else if (viewportHeight <= 2160) {
      chartHeight = '80%';
    } else {
      chartHeight = '100%';
    }

    setChartHeight(chartHeight);
  };

  const formatGraphicList = () => {
    const data = [
      { name: '间隔异常', color: bgColor['abnormalInterval'] },
      { name: '节拍异常', color: bgColor['abnormalBeat'] },
      { name: '节拍正常', color: bgColor['normalBeat'] },
    ].map((i, index) => ({
      type: 'rect',
      top: 6,
      right: (index + 1) * 70 + index * 24,
      shape: {
        width: 16,
        height: 10,
      },
      textContent: {
        type: 'text',
        style: {
          text: i.name,
          fill: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        fill: i.color,
      },
    }));
    return data;
  };

  const getBgColor = (type: number, abnormalFlag: boolean) => {
    if (type === 1) {
      if (abnormalFlag) {
        return bgColor['abnormalInterval'];
      }
    } else {
      if (abnormalFlag) {
        return bgColor['abnormalBeat'];
      } else {
        return bgColor['normalBeat'];
      }
    }
  };

  const getChartOption = useMemo(() => {
    return {
      grid: [
        {
          left: maxNumLength > 1 ? maxNumLength * 10 + 30 : 55,
          right: 5,
          height: chartHeight,
          containerLabel: true,
        },
        {
          left: maxNumLength > 1 ? maxNumLength * 10 + 30 : 55,
          right: 5,
          bottom: 35,
          height: '4%',
        },
      ],
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (tooltipData: any) => {
          const seriesName = tooltipData[0].seriesName;
          const ts = tooltipData[0].axisValue;
          if (seriesName === '实际节拍数据') {
            const time = dayjs(ts).format('YYYY-MM-DD HH:mm');
            const fact = (lineChartData ?? []).find(i => i.ts === ts);
            const value = isNil(fact?.value) ? '--' : fact?.value.toFixed(4);
            const standardValue = isNil(fact?.standardValue) ? '--' : fact?.standardValue.toFixed(4);

            // 1: 状态; 2: 节拍
            if (fact?.type === 2) {
              return `
              <div style="width: 200px;" >
                <div style="display:flex; justify-content: space-between; align-items: center;" >
                <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${time} </p>
                  ${
                    fact?.abnormalFlag
                      ? `<p style="margin: 0px; font-size: 12px; color: #E64242;"> 节拍异常 </p>`
                      : `<p style="margin: 0px; font-size: 12px; color: #52C41A;"> 节拍正常 </p>`
                  }
                </div>
                <div style="display:flex; justify-content: space-between; align-items: center;" >
                  <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"><span style='display:inline-block;width:8px;height:8px;background:#16DD8E;border-radius:50%;margin-right:6px'></span>实际用能</p>
                  <p style="margin: 0px; font-size: 12px; color: ${
                    fact?.abnormalFlag ? '#E64242' : 'rgba(255,255,255,0.85)'
                  };" > ${value}${generalName} </p>
                  
                </div>
                <div style="display:flex; justify-content: space-between; align-items: center;" >
                <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"><span style='display:inline-block;width:8px;height:8px;background:#2D8DFF;border-radius:50%;margin-right:6px'></span>标准用能</p>
                <p style="margin: 0px; font-size: 12px; color:rgba(255,255,255,0.85);" > ${standardValue}${generalName} </p>
              </div>
              </div>`;
            } else if (fact?.type === 1) {
              return `
              <div style="width: 200px;" >
              <div style="display:flex; justify-content: space-between; align-items: center;" >
              <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.65);" > ${time} </p>
                ${fact?.abnormalFlag ? `<p style="margin: 0px; font-size: 12px; color: #FAAD14;"> 间隔异常 </p>` : ''}
              </div>
              ${
                !fact?.abnormalFlag
                  ? `<div  style="display:flex; justify-content: space-between; align-items: center;">
              <p style= "margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);" >${
                fact?.status === 1 ? '停机' : '待机'
              }持续时长</p>
              <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);">${
                fact?.realDuration ?? '--'
              }min </p>
              </div>`
                  : ''
              }
                <div style="display:flex; justify-content: space-between; align-items: center;" >
                  <p style= "margin: 0px;  font-size: 12px; color: rgba(255,255,255,0.85);"><span style='display:inline-block;width:8px;height:8px;background:#16DD8E;border-radius:50%;margin-right:6px'></span>实际用能</p>
                  <p style="margin: 0px; font-size: 12px; color: rgba(255,255,255,0.85);"> ${value}${generalName} </p>
                </div>
              </div>
              `;
            }
          }
        },
      },
      graphic: formatGraphicList(),
      legend: {
        show: true,
        right: 292,
        top: 0,
        itemHeight: 4,
        itemWidth: 16,
        itemGap: 24,
        textStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontSize: 14,
        },
        data: [
          { name: '实际节拍数据', icon: 'rect', itemStyle: { color: '#16DD8E' } },
          { name: '标准节拍数据', icon: 'rect', itemStyle: { color: '#2D8DFF' } },
        ],
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
          end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
          xAxisIndex: [0, 1],
          bottom: 10,
        },
        {
          type: 'inside',
          realtime: true,
          start: ((dayjs().hour() * 60 + dayjs().minute() - 4 * 60) / (24 * 60)) * 100,
          end: ((dayjs().hour() * 60 + dayjs().minute()) / (24 * 60)) * 100,
          xAxisIndex: [0, 1],
        },
      ],
      xAxis: [
        {
          type: 'time',
          minInterval: 60 * 1000,
          data: (lineChartData ?? []).map(i => i.ts),
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
            margin: 16,
            formatter: function (e: number) {
              return dayjs(e, 'x').format('MM-DD HH:mm');
            },
          },
        },
      ],
      yAxis: [
        {
          name: generalName,
          nameTextStyle: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
          type: 'value',
          splitLine: {
            lineStyle: { color: 'rgba(255,255,255,0.30)' },
          },
          axisLabel: {
            color: 'rgba(255,255,255,0.85)',
            fontSize: 14,
          },
        },
      ],
      series: [
        {
          name: '实际节拍数据',
          type: 'line',
          symbol: 'none',
          data: (lineChartData ?? []).map(i => [
            i.ts,
            isNil(i.value) ? i.value : Number((i.value as number).toFixed(4)),
          ]),
          itemStyle: {
            color: '#16DD8E',
          },
          markArea: {
            data: (lineChartBackground ?? [])
              // .filter(i => !(i.type === 1 && !i.abnormalFlag))
              .filter(i => i.type === 2 || i.abnormalFlag)
              .map((i, index) => {
                return [
                  {
                    xAxis: i.startDateTime,
                    itemStyle: {
                      //1 间隔 2 节拍
                      color: getBgColor(i.type as number, i.abnormalFlag as boolean),
                    },
                  },
                  {
                    xAxis: i.endDateTime,
                    itemStyle: {
                      //1 间隔 2 节拍
                      color: getBgColor(i.type as number, i.abnormalFlag as boolean),
                    },
                  },
                ];
              }),
          },
        },
        {
          name: '标准节拍数据',
          type: 'line',
          symbol: 'none',
          data: (standardData ?? []).map(i => [
            i.ts,
            isNil(i.value) ? i.value : Number((i.value as number).toFixed(4)),
          ]),
          itemStyle: {
            color: '#2D8DFF',
          },
        },
      ],
    };
  }, [
    chartHeight,
    generalName,
    lineChartBackground,
    lineChartData,
    standardData,
    maxNumLength,
    startAndEedArray,
    timeResolution,
  ]);

  return (
    <ResizeObserver
      onResize={({ width }) => {
        chartRef.current?.getEchartsInstance().resize({ width });
      }}
    >
      <ReactEcharts
        ref={e => {
          if (e) chartRef.current = e;
        }}
        option={getChartOption}
        style={{ height: `calc(100vh - 400px)` }}
        notMerge
        lazyUpdate={false}
      />
    </ResizeObserver>
  );
};

export default WaveFormChart;
