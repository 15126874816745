import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeLabel,
  EnergyWorkingProcessTypeLabelColor,
  UetEMTPreviewResponse,
} from '@/api/uet';
import G6, { Graph, Item } from '@antv/g6';
import { getRealUrl } from '@maxtropy/components';
import { Space } from 'antd';
import React, { FC, Key, useEffect, useMemo, useRef, useState } from 'react';
import { basicConfig, EnergyWorkingProcessPermissions, formatNodesAndEdges } from './basicConfig';
import styles from './index.module.scss';
import { apiV2UetEmtPreviewDashboardPost } from '@maxtropy/device-customer-apis-v2';

export interface IViewEnergyMediumTopo {
  energyMediumTopoId?: number;
  processIdRef?: React.Key;
  getProcessId?: (key: Key) => void;
  getProcessType?: (type: EnergyWorkingProcessType) => void;
  getProcessName?: (name: string) => void;
}

const ViewEnergyMediumTopo: FC<IViewEnergyMediumTopo> = props => {
  const { energyMediumTopoId, getProcessId, getProcessType, getProcessName, processIdRef } = props;
  const graph = useRef<Graph>(); // graph图的实例
  const [topoPreviewData, setTopoPreviewData] = useState<UetEMTPreviewResponse>();
  const [itemNodeState, setItemNodeState] = useState<Item>();
  const timerRef = useRef<number>();
  const [selectedProcessId, setSelectedProcessId] = useState<number>();

  // 选择两次不同拓扑的对比
  const [energyMediumBackId, setEnergyMediumBackId] = useState<number | undefined>(energyMediumTopoId);
  const [topoCanvasLoading, setTopoCanvasLoading] = useState<boolean>(false);

  // 查询拓扑图
  useEffect(() => {
    if (graph.current) {
      graph.current.clear();
      graph.current.destroy();
      graph.current = undefined;
      setItemNodeState(undefined);
      window.clearTimeout(timerRef.current);
    }
    if (energyMediumTopoId) {
      setTopoCanvasLoading(true);
      apiV2UetEmtPreviewDashboardPost({ id: +energyMediumTopoId }).then(res => {
        const resTemp = JSON.parse(JSON.stringify(res));
        for (let i = 0; i < resTemp.nodes.length; i++) {
          resTemp.nodes[i].info!.pictureKey = getRealUrl(resTemp.nodes[i].info?.pictureKey);
        }
        if (resTemp.nodes.length > 0) {
          if (processIdRef) {
            setSelectedProcessId(Number(processIdRef));
            getProcessId?.(Number(processIdRef));
            getProcessType?.(
              resTemp.nodes.find((item: any) => item.id === Number(processIdRef))?.info
                ?.type as EnergyWorkingProcessType
            );
            getProcessName?.(resTemp.nodes.find((item: any) => item.id === Number(processIdRef))?.name!);
          } else {
            const filterNoStrage = resTemp.nodes.filter((i: any) => i?.info?.type !== EnergyWorkingProcessType.STORAGE);
            setSelectedProcessId(filterNoStrage[0].id);
            getProcessType?.(filterNoStrage[0].info?.type as EnergyWorkingProcessType);
            getProcessId?.(filterNoStrage[0].id);
            getProcessName?.(filterNoStrage[0].name!);
          }
        }
        setTopoPreviewData(resTemp);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyMediumTopoId]);

  // useEffect(() => {
  //   if (processId && topoPreviewData && topoPreviewData.nodes.length > 0) {
  //     console.log(111);
  //     setSelectedProcessId(processId as number);
  //     getProcessId?.(processId as number);
  //     getProcessType?.(
  //       topoPreviewData.nodes.find(item => item.id === (processId as number))?.info?.type as EnergyWorkingProcessType
  //     );
  //     getProcessName?.(topoPreviewData.nodes.find(item => item.id === (processId as number))?.name!);
  //   }
  // }, [topoPreviewData]);

  const formatData = useMemo(() => {
    // 对返回数据进行处理
    if (topoPreviewData) {
      return formatNodesAndEdges(topoPreviewData);
    }
  }, [topoPreviewData]);

  useEffect(() => {
    if (formatData) {
      const tooltip = new G6.Tooltip({
        offsetX: 10,
        offsetY: 10,
        className: 'tooltip',
        // 允许出现 tooltip 的 item 类型
        itemTypes: ['node'],
        // 自定义 tooltip 内容
        getContent: e => {
          const outDiv = document.createElement('div');
          outDiv.style.width = 'fit-content';
          outDiv.style.zIndex = '99';
          outDiv.style.position = 'relative';
          outDiv.style.color = 'black';
          outDiv.style.backgroundColor = '#fff';
          outDiv.style.borderRadius = '3px';
          outDiv.innerHTML = `
            <h4>无权限</h4>
            `;
          return outDiv;
        },
        shouldBegin: e => {
          const item: any = e?.item?.getModel();
          let res = false;
          if (item.info.permissions === EnergyWorkingProcessPermissions.UNABLED) {
            res = true;
          }
          return res;
        },
      });
      // 创建实例
      graph.current = new G6.Graph(basicConfig(tooltip));
      // 读取数据
      graph.current.data(formatData);
      // 渲染图
      graph.current.render();

      // 绑定事件
      graph.current.on('nodeselectchange', ev => {
        const getNodes = (ev.selectedItems as any).nodes;
        if (getNodes.length) {
          const itemDto: any = getNodes[0].getModel();
          if (itemDto.info.type === EnergyWorkingProcessType.STORAGE) {
            // message.error('当前存储节点不可选择！');
          } else {
            if (itemDto && itemDto.info.permissions === EnergyWorkingProcessPermissions.ENABLED) {
              setSelectedProcessId(itemDto.id);
              getProcessId?.(itemDto.id);
              getProcessType?.(itemDto.info.type);
              getProcessName?.(itemDto.name);
            }
          }
        }
      });

      setEnergyMediumBackId(energyMediumTopoId);
      setTopoCanvasLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatData]);

  useEffect(() => {
    if (
      graph.current &&
      topoPreviewData &&
      energyMediumTopoId &&
      energyMediumBackId === energyMediumTopoId &&
      selectedProcessId
    ) {
      itemNodeState && graph.current.clearItemStates(itemNodeState);
      const itemNode = graph.current.findById(selectedProcessId.toString());
      setItemNodeState(itemNode);
      if (
        topoPreviewData.nodes.find(i => i.id === selectedProcessId)?.info?.permissions ===
        EnergyWorkingProcessPermissions.UNABLED
      ) {
        graph.current.setItemState(itemNode, 'selected', false);
      } else {
        graph.current.setItemState(itemNode, 'selected', true);
        graph.current.fitView([12]);
        graph.current.focusItem(itemNode, true, {
          easing: 'easeCubic',
          duration: 500,
        });
        timerRef.current = window.setTimeout(() => {
          const canvasWidth = graph.current!.getWidth();
          const canvasHeight = graph.current!.getHeight();
          graph.current!.zoomTo(0.9, { x: canvasWidth / 2, y: canvasHeight / 2 }, true, {
            duration: 100,
          });
        }, 650);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoPreviewData, energyMediumTopoId, topoCanvasLoading, selectedProcessId]);

  return (
    <div className={styles.wrapper}>
      <Space className={styles.block}>
        {Object.entries(EnergyWorkingProcessTypeLabel).map(([value, key]) => {
          const color = `${EnergyWorkingProcessTypeLabelColor[+value as EnergyWorkingProcessType]}`;
          return (
            <div key={key} className={styles.item}>
              <div
                style={{
                  background: color,
                }}
                className={styles.pipe}
              />
              <div>{key}</div>
            </div>
          );
        })}
      </Space>
      <div
        className={styles.bgImg}
        style={{ width: '100%', height: '100%', backgroundColor: '#232324' }}
        id="mountNode"
      ></div>
    </div>
  );
};

export default ViewEnergyMediumTopo;
