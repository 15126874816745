import { Button, Form, Modal, Upload, getRealUrl, message } from '@maxtropy/components';
import React, { useContext, useState } from 'react';
import styles from './index.module.scss';
import { Spin } from 'antd';
import { apiV2OutputProductBaseLineImportPost } from '@maxtropy/device-customer-apis-v2';
import { BaseLineContext } from '../utils/common';
import { isNil } from 'lodash';
import dayjs from 'dayjs';

interface Props {
  modalOpen: boolean;
  tabName: string;
  tabType: number;
  setModalOpen: (v: boolean) => void;
  setTabValueList: (val: any) => void;
}

const ImportDataModal: React.FC<Props> = ({ modalOpen = false, tabName, tabType, setModalOpen, setTabValueList }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { lineItem } = useContext(BaseLineContext);
  const [failWordKey, setFailWordKey] = useState<string>();

  const onOk = () => {
    if (isNil(lineItem?.baseLineId) || loading) return;
    form.validateFields().then(() => {
      const { fileKey } = form.getFieldsValue();
      setLoading(true);
      apiV2OutputProductBaseLineImportPost({ fileKey, id: lineItem?.baseLineId })
        .then(res => {
          if (isNil(res.fileKey)) {
            message.success('导入成功');
            setModalOpen(false);
            setTabValueList(res.valueList);
          } else {
            message.error('导入失败');
            setFailWordKey(res.fileKey);
          }
        })
        .finally(() => setLoading(false));
    });
  };

  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal size="normal" title="导入数据" open={modalOpen} onOk={onOk} onCancel={onClose}>
      <Spin spinning={loading}>
        <div className={styles.tipRow}>
          1、每次上传都要重新下载模板，下载模板时同时会下载平台中的最新数据，请
          <Button
            type="link"
            style={{ paddingLeft: 4 }}
            href={`/api/v2/outputProduct/base-line/template/download?tabName=${tabName}&type=${tabType}&baseLineTime=${dayjs(
              lineItem?.baseLineTime
            ).format('YYYY-MM-DD')}&id=${lineItem?.baseLineId}&lineType=${lineItem?.lineType}&baseLineCycle=${
              lineItem?.baseLineCycle
            }&baseLineGranularity=${lineItem?.baseLineGranularity}`}
          >
            下载模板
          </Button>
        </div>
        <div className={styles.tipRow}>
          2、请仔细阅读模板说明，并按要求填写数据，填写完成后再上传，上传后将用上传的数据覆盖平台内的数据{' '}
        </div>
        <div className={styles.uploadRow}>
          <Form form={form}>
            <Form.Item name="fileKey" rules={[{ required: true, message: '请上传数据' }]}>
              <Upload fileSize={5} listType="text" accept={['.xls', '.xlsx']} tip="仅支持.xls、.xlsx，大小不超过5M" />
            </Form.Item>
            {failWordKey && (
              <Button
                type="link"
                style={{ paddingLeft: 0 }}
                onClick={() => {
                  window.open(getRealUrl(failWordKey), '_blank');
                }}
              >
                下载错误文档
              </Button>
            )}
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default ImportDataModal;
