import { useState, useEffect } from 'react';
import {
  Paging,
  useSearchParams,
  EllipsisSpan,
  Table,
  Button,
  Tag,
  Wrapper,
  Select,
  PopConfirm,
  useUpdate,
  useBreadcrumbRoutes,
  Form,
  CustomFilter,
  Input,
} from '@maxtropy/components';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiV2CarbonFootprintLcaPagePost,
  V2CarbonFootprintLcaPagePostRequest,
  V2CarbonFootprintLcaPagePostResponse,
  apiV2CarbonFootprintLcaDeletePost,
  apiV2CarbonFootprintLcaUpdateStatePost,
} from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { State, StatusDisplay } from './type';
import { useNavigate, Link } from 'react-router-dom';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { BoundaryType, BoundaryTypeMap } from './NewModel/utils';
import styles from './index.module.scss';
// const routes = [
//   { name: "产品碳足迹 " },
//   { name: "碳足迹基础" },
//   { name: "碳足迹模型管理" },
// ];

interface SearchParams extends Omit<V2CarbonFootprintLcaPagePostRequest, 'page' | 'size'> {}

const statusOptions = [
  { label: '全部', value: null },
  ...Object.entries(StatusDisplay).map(([k, v]) => ({
    label: v,
    value: Number(k),
  })),
];

const columns = [
  {
    title: '产品编码',
    dataIndex: 'code',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规格型号',
    dataIndex: 'spec',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '系统边界',
    dataIndex: 'boundary',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: BoundaryType) => <EllipsisSpan value={BoundaryTypeMap[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: State) => (
      <EllipsisSpan
        value={
          <Tag border="solid" color={v === State.ENABLE ? 'success' : 'default'}>
            {StatusDisplay[v]}
          </Tag>
        }
      />
    ),
  },
  {
    title: '创建日期',
    dataIndex: 'createTime',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

const ModelManage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<V2CarbonFootprintLcaPagePostResponse['list']>();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = useSearchParams<SearchParams>(20, { state: State.ENABLE });
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const navigate = useNavigate();
  const [update, updateFn] = useUpdate();

  const hasState = useHasPermission(PermissionsType.B_ENABLEPCFMODEL); // 启用禁用模型
  const hasDelete = useHasPermission(PermissionsType.B_DELPCFMODEL); // 删除模型
  const hasAdd = useHasPermission(PermissionsType.B_ADDPCFMODEL); // 增加模型
  const hasEdit = useHasPermission(PermissionsType.B_MAINTENANCEPCFMODEL); // 编辑

  useEffect(() => {
    apiV2CarbonFootprintLcaPagePost({ ...searchParams, page: searchParams.pageOffset, size: searchParams.pageSize })
      .then(res => {
        setDataSource(res?.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, update]);

  const onFinish = (values: V2CarbonFootprintLcaPagePostRequest) => {
    finish(values);
  };

  const onReset = () => {
    reset({ state: State.ENABLE });
  };

  const onDelete = (id: number) => {
    apiV2CarbonFootprintLcaDeletePost({ id }).then(() => {
      updateFn();
    });
  };

  const onChangeState = (record: any) => {
    apiV2CarbonFootprintLcaUpdateStatePost({
      id: record.id,
      state: record.state === State.ENABLE ? State.DISABLE : State.ENABLE,
    }).then(() => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,

    {
      title: '操作',
      width: 120,
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      fixed: 'right' as 'right',
      render: (_: any, record: Record<string, any>) => {
        const stateTxt = record.state === State.DISABLE ? StatusDisplay[State.ENABLE] : StatusDisplay[State.DISABLE];

        return (
          <Space size={16}>
            {hasEdit && (
              <Button
                type="link"
                onClick={() => {
                  navigate(`/productCarbonFootPrint/basic/modelManage/newModel?id=${record.id}`);
                }}
              >
                完善模型
              </Button>
            )}
            {hasState && (
              <PopConfirm placement="top" title={`你是否${stateTxt}此模型`} onConfirm={() => onChangeState(record)}>
                <Button type="link">{stateTxt}</Button>
              </PopConfirm>
            )}
            {hasDelete &&
              (record.state === State.ENABLE ? (
                <Button type="link" disabled={record.state === State.ENABLE}>
                  删除
                </Button>
              ) : (
                <PopConfirm placement="top" title="你确定要删除此模型吗？" onConfirm={() => onDelete(record.id)}>
                  <Button type="link" disabled={record.state === State.ENABLE}>
                    删除
                  </Button>
                </PopConfirm>
              ))}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter
      form={form}
      onFinish={onFinish}
      initialValues={{
        state: State.ENABLE,
      }}
      onReset={onReset}
    >
      <Form.Item name="state" label="状态">
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item name="name" label="产出物">
        <Input placeholder="请输入产出物名称/编码/规格" />
      </Form.Item>
    </CustomFilter>
  );
  return (
    <Wrapper filters={filters} routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
      <Space style={{ marginBottom: 10 }}>
        {hasAdd && (
          <Button
            type="primary"
            // onClick={() => {
            //   navigate(`/productCarbonFootPrint/basic/modelManage/createModel`);
            // }}
            icon={<PlusOutlined />}
          >
            <Link to={`/productCarbonFootPrint/basic/modelManage/createModel`}>新建模型</Link>
          </Button>
        )}
      </Space>
      <Table rowKey="id" columns={buildColumns} dataSource={dataSource} loading={loading} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default ModelManage;
