import classNames from 'classnames';
import styles from './index.module.scss';
import { SwipperListItem } from './';
import { Key } from '@maxtropy/components';
import { isNil } from 'lodash-es';
import { MinusGreen, PlusRed, formatCompositeNumWithUnit, formatMediaNumWithUnit } from '../../utils';

interface Props {
  cardInfo: SwipperListItem;
  selectedMediumType?: Key;
  selectedBasePhysicalQuantityId?: number;
  setSelectedMediumType: (id: Key) => void;
  setCurrentSelectedIds: (id: number[]) => void;
  setSelectedBasePhysicalQuantityId: (id: number) => void;
}

export function formatMomRatio(num?: number | null) {
  if (isNil(num)) return <span>--</span>;
  return num > 0 ? (
    <span style={{ color: PlusRed }}>+{num.toFixed(2)}%</span>
  ) : (
    <span style={{ color: MinusGreen }}>{num.toFixed(2)}%</span>
  );
}

const CardItem: React.FC<Props> = ({
  selectedMediumType,
  cardInfo,
  selectedBasePhysicalQuantityId,
  setSelectedMediumType,
  setCurrentSelectedIds,
  setSelectedBasePhysicalQuantityId,
}) => {
  return (
    <div
      className={classNames(
        styles.cardItem,
        selectedMediumType === cardInfo.energyMediumName &&
          selectedBasePhysicalQuantityId === cardInfo.basePhysicalQuantityId
          ? styles.cardItemClicked
          : null
      )}
      onClick={() => {
        setCurrentSelectedIds((cardInfo.energyMediumId as number) === 100 ? [] : [cardInfo.energyMediumId as number]);
        setSelectedMediumType(cardInfo.energyMediumName as string);
        setSelectedBasePhysicalQuantityId(cardInfo.basePhysicalQuantityId as number);
      }}
    >
      <div className={styles.firstLine}>
        <p className={styles.firstLine_left}>{cardInfo.energyMediumName}</p>
        <p className={styles.firstLine_right}>环比{formatMomRatio(cardInfo.momPercent)}</p>
      </div>
      <p className={styles.secondLine}>
        {isNil(cardInfo.consumptionValue)
          ? '--'
          : cardInfo.energyMediumName === '综合能耗'
          ? formatCompositeNumWithUnit(cardInfo.consumptionValue)
          : formatMediaNumWithUnit(cardInfo.consumptionValue, cardInfo.generalName)}
      </p>
    </div>
  );
};

export default CardItem;
