import { useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import { Empty, Key } from '@maxtropy/components';
import { Tag, Tree } from 'antd';
import { MeterPeriodDisplay, MeterProjectListItem, getMeterProjectList } from '@/api/universeMeterQuery';
import { MeterPeriodBackgroundColor, MeterPeriodBorderColor, MeterPeriodColor } from '../../utils';

interface Props {
  onTreeSelect?: (selectedKeys: Key[]) => void;
  setIsHierarchicalMeterReading: (v: boolean) => void;
}

const MeterProjectList: React.FC<Props> = ({ onTreeSelect, setIsHierarchicalMeterReading }) => {
  const [data, setData] = useState<MeterProjectListItem[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  useEffect(() => {
    // 获取抄表项目列表
    getMeterProjectList().then(res => {
      if (res && res.list && res.list.length > 0) {
        setData(res.list);
        setSelectedKeys([res.list[0].id]);
        onTreeSelect?.([res.list[0].id]);
        setIsHierarchicalMeterReading(res.list[0].hierarchicalMeterReading === 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const treeData = useMemo(() => {
    return (data ?? []).map(item => {
      return {
        title: (
          <div className={styles.treeNodeTitle}>
            <div className={styles.treeNodeName}>{item.projectName}</div>
            <div className={styles.treeNodePeriod}>
              <Tag
                style={{
                  background: MeterPeriodBackgroundColor[item.readingPeriod],
                  borderColor: MeterPeriodBorderColor[item.readingPeriod],
                  color: MeterPeriodColor[item.readingPeriod],
                }}
              >
                {MeterPeriodDisplay[item.readingPeriod]}
              </Tag>
            </div>
          </div>
        ),
        key: item.id,
      };
    });
  }, [data]);

  return (
    <div className={styles.leftSider}>
      <div className={styles.treeArea}>
        {treeData && treeData.length > 0 ? (
          <div>
            <div className={styles.tree_box}>
              <Tree
                className={styles.treeStyle}
                showIcon
                blockNode
                treeData={treeData}
                selectedKeys={selectedKeys}
                onSelect={(selectedKeys, info) => {
                  setSelectedKeys(selectedKeys);
                  onTreeSelect?.(selectedKeys);
                  setIsHierarchicalMeterReading(
                    data.find(item => item.id === selectedKeys[0])?.hierarchicalMeterReading === 1
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <Empty style={{ paddingTop: 100 }} />
        )}
      </div>
    </div>
  );
};

export default MeterProjectList;
