import { fetch } from '@maxtropy/components';
import qs from 'qs';

// 能耗报警规则状态
export enum EnergyConsumptionAlarmStatusType {
  DISABLE = 0, // 禁用
  ENABLE = 1, // 启用
}

// 能耗报警规则状态值
export const EnergyConsumptionAlarmStatusTypeValue = {
  [EnergyConsumptionAlarmStatusType.DISABLE]: '禁用',
  [EnergyConsumptionAlarmStatusType.ENABLE]: '启用',
};

export function updateEnergyUnitAlarmStatus(
  serverAlarmRuleId: number,
  params: { status: EnergyConsumptionAlarmStatusType }
): Promise<void> {
  return fetch(`/api/server-alarm/rule/${serverAlarmRuleId}/status?${qs.stringify(params, { indices: false })}`, {
    method: 'PUT',
  });
}
