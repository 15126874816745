import { FC, Suspense, useEffect } from 'react';
import { activateTheme, TenantLayout as LayoutComponents, Themes } from '@maxtropy/components';
import TenantLayout from '@/components/Layout';
import { Outlet } from 'react-router-dom';

// scada路由出口
export const ScadaOutlet: FC = () => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <Suspense fallback={null}>
      <TenantLayout>
        <Outlet />
      </TenantLayout>
    </Suspense>
  );
};

// 其他页面路由出口
export const MainOutlet: FC = () => {
  useEffect(() => {
    activateTheme(Themes.DARK);
  }, []);

  return (
    <Suspense fallback={null}>
      <TenantLayout>
        <LayoutComponents>
          <Outlet />
        </LayoutComponents>
      </TenantLayout>
    </Suspense>
  );
};
