import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormTitle, Modal, Wrapper, message, useBreadcrumbRoutes, useUpdate } from '@maxtropy/components';
import { Graph, Shape, Node, Cell } from '@antv/x6';
import { Stencil } from '@antv/x6-plugin-stencil';
import { register } from '@antv/x6-react-shape';
import ResizeObserver from 'rc-resize-observer';
import {
  apiV2AirCompressorGetPost,
  apiV2NitrogenProductionGetPost,
  V2AirCompressorGetPostResponse,
  apiV2AirCompressorAddTopologyPost,
  apiV2AirCompressorEditTopologyPost,
  apiV2AirCompressorGetTopologyPost,
  V2AirCompressorGetTopologyPostResponse,
  apiV2NitrogenProductionAddTopologyPost,
  apiV2NitrogenProductionEditTopologyPost,
  apiV2NitrogenProductionGetTopologyPost,
  V2GasPreparationStationDeviceListPostResponse,
  apiV2GasPreparationStationCompressorDelPost,
  apiV2GasPreparationStationNitrogenGeneratorDelPost,
  apiV2GasPreparationStationGasTankDelPost,
  apiV2GasPreparationStationStationOutletDelPost,
  apiV2GasPreparationStationGasManifoldDelPost,
  apiV2GasPreparationStationThermometerDelPost,
  apiV2GasPreparationStationNodeDelPost,
  apiV2GasPreparationStationGetPost,
} from '@maxtropy/device-customer-apis-v2';
import gragProcessIcon from '../icons/gragProcess@2x.png';
import NodeComponent from './../components//NodeComponent';
import styles from './index.module.scss';
import CNModal from './components/CompressorNodeModal';
import NGNModal from './components/NitrogenGeneratorNodeModal';
import GSTNModal from './components/GasStorageTankNodeModal';
import SBENModal from './components/StationBuildingExitNodeModal';
import GCNModal from './components/GasCollectorNodeModal';
import TNModal from './components/ThermometerNodeModal';
import NodeModal from './components/NodeModal';
import { NodeType, OperationType, StationType, StationTypeDisplay, nodeSize } from '../utils';
import { BaseNodeList, customEdgeAttrs } from './config';

type DeviceDetail = Exclude<V2GasPreparationStationDeviceListPostResponse['list'], undefined>[number];
export type DeviceList = Exclude<DeviceDetail['deviceBasicDtoList'], undefined>[number];

export interface ModalProp {
  onCancel: () => void;
  onOk: (values: any) => void;
  stationData?: V2AirCompressorGetTopologyPostResponse;
  operationType: OperationType;
  selectedNode: any;
  uetId?: string;
}

const ports = {
  // 链接桩组定义
  groups: {
    top: {
      position: 'top',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 6,
          height: 6,
          stroke: '#57FB8B',
          fill: '#57FB8B',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
        line: {
          stroke: '#57FB8B',
        },
      },
    },
    right: {
      position: 'right',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 6,
          height: 6,
          stroke: '#57FB8B',
          fill: '#57FB8B',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    bottom: {
      position: 'bottom',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 6,
          height: 6,
          stroke: '#57FB8B',
          fill: '#57FB8B',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    left: {
      position: 'left',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 6,
          height: 6,
          stroke: '#57FB8B',
          fill: '#57FB8B',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
  },
  // 链接桩
  items: [
    {
      group: 'top',
    },
    {
      group: 'right',
    },
    {
      group: 'bottom',
    },
    {
      group: 'left',
    },
  ],
};

// 注册节点
register({
  shape: 'custom-node1',
  width: 160,
  height: 48,
  effect: ['data'],
  component: NodeComponent,
  ports: { ...ports },
});

const DEL_API: Record<number, Function> = {
  [NodeType.COMPRESSOR]: apiV2GasPreparationStationCompressorDelPost,
  [NodeType.NITROGEN_GENERATOR]: apiV2GasPreparationStationNitrogenGeneratorDelPost,
  [NodeType.GAS_TANK]: apiV2GasPreparationStationGasTankDelPost,
  [NodeType.STATION_OUTLET]: apiV2GasPreparationStationStationOutletDelPost,
  [NodeType.GAS_MANIFOLD]: apiV2GasPreparationStationGasManifoldDelPost,
  [NodeType.THERMOMETER]: apiV2GasPreparationStationThermometerDelPost,
};

// 新增/编辑 空压站/制氮站拓扑
function ApiList(): [{ stationType: StationType; operationType: OperationType }, Function][] {
  return [
    [{ stationType: StationType.ACS, operationType: OperationType.ADD }, apiV2AirCompressorAddTopologyPost],
    [{ stationType: StationType.ACS, operationType: OperationType.EDIT }, apiV2AirCompressorEditTopologyPost],
    [{ stationType: StationType.NGS, operationType: OperationType.ADD }, apiV2NitrogenProductionAddTopologyPost],
    [{ stationType: StationType.NGS, operationType: OperationType.EDIT }, apiV2NitrogenProductionEditTopologyPost],
  ];
}

const IntrasiteTopology = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id: uetId } = useParams<{ id: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const stationId = searchParams.get('stationId') || undefined;
  const stationType = searchParams.get('stationType') || undefined;

  // 画布相关
  const graph = useRef<Graph>();
  const refStencil = useRef<HTMLDivElement>();
  const refContainer = useRef<HTMLDivElement>();
  const [nodeList, setNodeList] = useState<Node<Node.Properties>[]>([]); // 画布上的节点
  const [stationData, setStationData] = useState<V2AirCompressorGetPostResponse>();
  const [topoData, setTopoData] = useState<V2AirCompressorGetTopologyPostResponse>();
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [topoId, setTopoId] = useState<number | undefined>();
  const [operationType, setOperationType] = useState<OperationType>(0);
  // 后续这一块会做改造，所有节点走一个接口，只会打开一个弹窗
  const [openModal, setOpenModal] = useState<number | undefined>();
  const [openNodeModal, setOpenNodeModal] = useState<boolean>(false);
  const [updateState, updateFn] = useUpdate();
  const hasTopoData = useRef<boolean>(false);
  const stationTopoId = useRef<number>();

  useEffect(() => {
    apiV2GasPreparationStationGetPost({ stationId, stationType }).then(res => {
      res.topology?.id && setTopoId(res.topology.id);
      stationTopoId.current = res.topology?.id;
    });
  }, [stationId, stationType]);

  useEffect(() => {
    // 1: 空压站 2: 制氮站
    if (Number(stationType) === StationType.ACS) {
      apiV2AirCompressorGetPost({ id: stationId }).then(res => {
        setStationData(res);
      });
    }
    if (Number(stationType) === StationType.NGS) {
      apiV2NitrogenProductionGetPost({ id: stationId }).then(res => {
        setStationData(res);
      });
    }
  }, [stationId, stationType]);

  useEffect(() => {
    if (topoId && Number(stationData?.stationType) === StationType.ACS) {
      apiV2AirCompressorGetTopologyPost({ id: topoId }).then(res => {
        setTopoData(res);
        hasTopoData.current = true;
      });
    }
    if (topoId && Number(stationData?.stationType) === StationType.NGS) {
      apiV2NitrogenProductionGetTopologyPost({ id: topoId }).then(res => {
        setTopoData(res);
        hasTopoData.current = true;
      });
    }
  }, [stationData, updateState, topoId]);

  useEffect(() => {
    graph.current = new Graph({
      container: refContainer.current!,
      panning: true,
      mousewheel: true,
      grid: {
        size: 2,
      },
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 60,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },

        createEdge() {
          return new Shape.Edge({
            zIndex: 0,
            markup: [
              {
                tagName: 'path',
                selector: 'stroke',
              },
              {
                tagName: 'path',
                selector: 'fill',
              },
            ],
            connector: { name: 'normal' },
            attrs: customEdgeAttrs('rgb(50, 240, 149)', 'rgb(22, 155, 221)'),
          });
        },
        validateConnection({ sourceCell, targetCell }) {
          if (sourceCell?.id) {
            //  站房出口不可以有拖出线
            // 每个节点不能指向本身
            const { type } = sourceCell.getData();
            if (type === NodeType.STATION_OUTLET || targetCell?.id === sourceCell.id) {
              return false;
            }
          }
          return true;
        },
      },
      interacting: function () {
        return true;
      },
    });

    const stencil = new Stencil({
      title: '设备',
      target: graph.current,
      stencilGraphWidth: 180,
      stencilGraphHeight: 0,
      stencilGraphPadding: 0,

      layoutOptions: {
        columns: 1,
        columnWidth: 160,
        rowHeight: 56,
      },
    });

    //将创建的实例添加到容器中
    refStencil.current?.appendChild(stencil.container);

    const leftNodeList =
      stationData?.stationType === StationType.ACS
        ? BaseNodeList.filter(k => k.type !== NodeType.NITROGEN_GENERATOR)
        : BaseNodeList.filter(
            k =>
              ![NodeType.COMPRESSOR, NodeType.REFRIGERATOR, NodeType.FLOW_METER, NodeType.PRESSURE_GAUGE].includes(
                k.type
              )
          );

    // 创建节点
    const nodeList = leftNodeList.map(k => {
      return graph.current?.createNode({
        shape: 'custom-node1',
        data: {
          selfId: k.selfId,
          name: k.name,
          iconKey: k.iconKey,
          area: 'left',
          type: k.type,
        },
      });
    });

    stencil.load(nodeList as Node<Node.Properties>[]);

    // 节点mouseenter
    graph.current?.on('node:mouseenter', ({ e, node }) => {
      e.stopPropagation();
      // 展示连接桩
      const container = refContainer.current!;
      const ports = container.querySelectorAll('.x6-port-body') as NodeListOf<SVGElement>;
      showPorts(ports, true);
      node.addTools([
        {
          name: 'boundary',
          args: {
            padding: 0,
            attrs: {
              stroke: '#57fb8b',
              'stroke-dasharray': '0',
              'stroke-width': 1,
            },
          },
        },
        {
          name: 'button-remove',
          args: {
            x: '100%',
            onClick({ cell }: { cell: Cell }) {
              const { name, selfId, type } = cell.getData();
              console.log('name, selfId, type', name, selfId, type);
              Modal.confirm({
                title: `是否删除${name}`,
                onOk() {
                  cell.remove();
                  removeNode(selfId, type);
                },
              });
            },
          },
        },
      ]);
    });

    // 节点mouseleave
    graph.current?.on('node:mouseleave', ({ e, node }) => {
      e.stopPropagation();
      const container = refContainer.current!;
      // 隐藏连接桩
      const ports = container.querySelectorAll('.x6-port-body') as NodeListOf<SVGElement>;
      showPorts(ports, false);

      cancelCellHover([node]);
    });

    // 节点node:moved
    graph.current?.on('node:moved', ({ e, node }) => {
      e.stopPropagation();
      const nodes = graph.current?.getNodes();
      cancelCellHover(nodes!);
      saveGraph();
    });

    // 节点click
    graph.current?.on('node:click', ({ e, node }) => {
      e.stopPropagation();
      const curNodeData = node.getData();
      if ([NodeType.REFRIGERATOR, NodeType.FLOW_METER, NodeType.PRESSURE_GAUGE].includes(curNodeData.type)) {
        setOpenNodeModal(true);
      } else {
        setOpenModal(curNodeData.type);
      }
      setOperationType(OperationType.EDIT);
      setSelectedNode(node);
    });

    // 节点added
    graph.current?.on('node:added', ({ node }) => {
      const curNodeData = node.getData();
      if ([NodeType.REFRIGERATOR, NodeType.FLOW_METER, NodeType.PRESSURE_GAUGE].includes(curNodeData.type)) {
        setOpenNodeModal(true);
      } else {
        setOpenModal(curNodeData.type);
      }
      setOperationType(OperationType.ADD);
      setSelectedNode(node);
    });

    // 边连接
    graph.current?.on('edge:connected', ({ e, currentCell, edge }) => {
      e.stopPropagation();
      // 在做边连接时，上一个节点的leave和边的leave事件会出现在该事件之后，导致节点和边的hover效果被保存下来，所以在连接后要清空hover效果
      const source = edge.getSourceCell();
      cancelCellHover([source!, currentCell!]);
      saveGraph();
    });

    // 边removed
    graph.current?.on('edge:removed', () => {
      saveGraph();
    });

    // 边mouseenter
    graph.current.on('edge:mouseenter', ({ e, cell }) => {
      e.stopPropagation();
      cell.setZIndex(1);
      cell.setAttrs(customEdgeAttrs('rgb(250,173,20)', 'rgb(250,173,20)'));
      cell.addTools([
        {
          name: 'button-remove',
          args: {
            distance: 0.5,
            onClick({ cell }: { cell: Cell }) {
              Modal.confirm({
                title: '是否确认删除？',
                onOk() {
                  cell.remove();
                },
              });
            },
          },
        },
      ]);
    });

    // 边mouseleave
    graph.current?.on('edge:mouseleave', ({ e, cell }) => {
      e.stopPropagation();
      cell.setZIndex(0);
      cell.setAttrs(customEdgeAttrs('rgb(50, 240, 149)', 'rgb(22, 155, 221)'));

      cancelCellHover([cell]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationData]);

  useEffect(() => {
    if (!isInit) return;

    // 回显
    if (topoData?.nodes && graph.current) {
      const nodeJson = JSON.parse(topoData?.nodes);
      graph.current.fromJSON(nodeJson as any);
      const nodes = graph.current?.getNodes();
      nodes.forEach(node => {
        const { type } = node.getData();
        const processItem = BaseNodeList.find(k => k.type === type);
        if (processItem) {
          node.updateData({
            iconKey: processItem.iconKey,
            area: 'right',
            update: true,
          });
        }
      });
      setNodeList(nodes);
      setIsInit(false);
    }
  }, [isInit, topoData]);

  const cancelCellHover = (cells: Cell[]) => {
    cells.forEach(cell => {
      if (cell.hasTool('button-remove')) {
        cell.removeTool('button-remove');
      }
      if (cell.hasTool('boundary')) {
        cell.removeTool('boundary');
      }
    });
  };

  const showPorts = (ports: NodeListOf<SVGElement>, show: boolean) => {
    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  };

  const removeNode = (selfId: number, type: NodeType) => {
    if ([NodeType.REFRIGERATOR, NodeType.FLOW_METER, NodeType.PRESSURE_GAUGE].includes(type)) {
      apiV2GasPreparationStationNodeDelPost({ nodeId: selfId, nodeType: type }).then(() => {
        message.success('删除成功');
        saveGraph();
      });
    } else {
      DEL_API[type]({ id: selfId }).then(() => {
        message.success('删除成功');
        saveGraph();
      });
    }
  };

  const saveGraph = (values?: Record<string, any>, isUpdateNodeData?: boolean) => {
    const nodes = graph.current?.getNodes();
    const directionList = nodes
      ?.map(node => {
        let { selfId, type: selfType } = node?.getData();

        let targetId: number | undefined = undefined;
        let targetType: number | undefined = undefined;

        // 获取到节点的出线边
        const outEdges = graph.current?.getOutgoingEdges(node);

        if (outEdges?.length) {
          targetId = outEdges?.[0]?.getTargetCell()?.getData().selfId;
          const targetNodes = outEdges?.map(edge => edge.getTargetCell());
          return targetNodes?.map(node => {
            const { selfId: targetId, type: targetType } = node?.getData() || {};
            return { selfId, selfType, targetId, targetType };
          });
        } else {
          // 没有出线的节点
          return [{ selfId, selfType, targetId, targetType }];
        }
      })
      .flat();

    nodes?.forEach(node => {
      node.updateData({ iconKey: null });
    });

    const graphJson = graph.current?.toJSON();

    // 只有拓扑无数据时第一次保存画布调用新增接口
    const [, Api] =
      ApiList().find(
        ([item]) =>
          item.stationType === stationData?.stationType &&
          item.operationType === (hasTopoData.current ? OperationType.EDIT : OperationType.ADD)
      ) || [];
    Api!({
      id: stationTopoId.current,
      stationId: stationData?.stationId,
      stationCode: stationData?.stationCode,
      nodes: JSON.stringify(graphJson),
      directionList,
    }).then((res: any) => {
      setTopoId(res.id);
      stationTopoId.current = res.id;
      updateFn();

      if (isUpdateNodeData) {
        setNodeList(nodes!);
        setOpenModal(undefined);
        setOpenNodeModal(false);
      }
    });
  };

  const saveNodeData = async (values: Record<string, any>) => {
    const nodes = graph.current?.getNodes();
    nodes?.forEach(node => {
      if (node.id === selectedNode?.id) {
        const { type } = selectedNode.getData();
        selectedNode.updateData({
          selfId: values.selfId,
          name: values.name,
          update: true,
          area: 'right',
        });
        selectedNode.resize(...nodeSize[type]);
      }
    });

    saveGraph(values, true);
  };

  const onCancel = () => {
    const { update } = selectedNode?.getData();
    // 新增时取消不会有update
    if (!update) {
      graph.current?.removeNode(selectedNode?.id!);
    }
    setOpenModal(undefined);
    setOpenNodeModal(false);
    setSelectedNode(null);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes}>
      <FormTitle
        title="编辑站内拓扑"
        extraContent={
          <div className={styles.extraContent}>
            <div className={styles.ggsTitleInfo}>
              <label>{StationTypeDisplay[Number(stationType)! as keyof typeof StationTypeDisplay]}名称：</label>
              {stationData?.stationName}
            </div>
            <Button onClick={() => navigate(-1)}>返回</Button>
          </div>
        }
      />
      <ResizeObserver
        onResize={({ width, height }) => {
          graph.current?.resize(width - 196, height);
        }}
      >
        <div className={styles.container}>
          <div className={styles.stencil} ref={dom => (refStencil.current = dom!)}></div>
          <div className={styles.graphBox}>
            <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
            {nodeList.length ? null : (
              <div className={styles.noMap}>
                <img className={styles.img} src={gragProcessIcon} alt="" />
                <div className={styles.desc}>请从左侧拖入设备开始创建拓扑</div>
              </div>
            )}
          </div>
        </div>
      </ResizeObserver>
      {openModal === NodeType.COMPRESSOR && (
        <CNModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openModal === NodeType.NITROGEN_GENERATOR && (
        <NGNModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openModal === NodeType.GAS_TANK && (
        <GSTNModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openModal === NodeType.STATION_OUTLET && (
        <SBENModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openModal === NodeType.GAS_MANIFOLD && (
        <GCNModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openModal === NodeType.THERMOMETER && (
        <TNModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
      {openNodeModal && (
        <NodeModal
          stationData={stationData}
          operationType={operationType}
          uetId={uetId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
    </Wrapper>
  );
};

export default IntrasiteTopology;
