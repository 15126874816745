import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Tooltip } from '@maxtropy/components';
import { Node } from '@antv/x6';
import ErrorImg from '../../icons/error-fill@2x.png';
import { isNil } from 'lodash-es';
import { nodeSize } from '../../utils';

// 自定义节点
const NodeComponent = ({ node }: { node: Node }) => {
  const {
    selfId,
    stationCode,
    stationType,
    stationId,
    type,
    name,
    iconKey,
    area,
    realTime,
    isAlarm = false,
    displayData = [],
    hoverData = [],
  } = node.getData();

  const [base64String, setBase64String] = useState<string>('');

  useEffect(() => {
    // 创建一个 Image 元素
    const img = new Image();
    // 设置图片源
    img.src = iconKey;
    // 图片加载完成后执行回调
    img.onload = function () {
      // 创建一个 Canvas 元素
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置 Canvas 尺寸为图片尺寸
      canvas.width = img.width;
      canvas.height = img.height;

      // 将图片绘制到 Canvas 上
      if (ctx) {
        ctx.drawImage(img, 0, 0);
      }
      // 将 Canvas 中的图像转换为 Base64 字符串
      const dataURL = canvas.toDataURL('image/png');
      setBase64String(dataURL); // 更新状态
    };
  }, [iconKey]);

  const title = realTime ? (
    <div className={styles.titleWrapper}>
      <div className={styles.nodeTitle}>{name}</div>
      {(hoverData ?? []).map((k: { label: string; value: string }) => (
        <div className={styles.row}>
          <div className={styles.nodeName}>{k.label}</div>
          <div className={styles.value}>{k.value}</div>
        </div>
      ))}
    </div>
  ) : null;

  const goToDeviceDeatail = () => {
    if (!realTime || isNil(selfId)) return;
    if (!displayData.length && !hoverData.length) return;
    window.open(
      `/production/manage/gasMachineDashBoard/SingleDeviceBoardDetail/${selfId}?name=${encodeURIComponent(
        name
      )}&stationId=${stationId}&code=${stationCode}&type=${type}&stationType=${stationType}`,
      '_self'
    );
  };

  return (
    <>
      {area === 'left' && (
        <div className={`${styles.customNode} ${styles.customNodeLeft}`}>
          <div className={styles.imgCon}>
            <img
              alt="Base64"
              src={base64String} // 使用状态中的 Base64 字符串
            />
          </div>
          {name}
        </div>
      )}
      {area === 'right' && (
        <div className={`${styles.customNode} ${styles.customNodeRight}`}>
          {!realTime && (
            <div>
              <span className={styles.nodeTitle}>{name}</span>
            </div>
          )}
          <Tooltip title={title} overlayClassName={styles.nodeTip} placement="bottom">
            <div className={styles.nodeIcon} onClick={goToDeviceDeatail}>
              <img
                className={styles.nodeImg}
                style={{ width: nodeSize[type][0], height: nodeSize[type][1] }}
                alt="Base64"
                src={base64String} // 使用状态中的 Base64 字符串
              />
              {realTime && isAlarm && (
                <>
                  <img className={styles.errorIcon} src={ErrorImg} alt="error" />
                  <div className={styles.errorItem}></div>
                </>
              )}
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default NodeComponent;
