import { Button, Empty, FormTitle, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import LeftPlanList from '../LeftPlanList';
import RightPlanContent from '../RightPlanContent';
import { Space } from 'antd';
import { useParams } from 'react-router-dom';
import { EnergyPlanDetailListItem, getEnergyPlanDetail } from '@/api/plustekEnergyPlan';
import PreviewModal from '../PreviewModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { isNil } from 'lodash-es';

interface EditPlanProps {
  isEdit?: boolean;
}

const EditPlan: React.FC<EditPlanProps> = props => {
  const { isEdit } = props;
  const { id: energyPlanId } = useParams<{ id: string }>();
  const routes = [{ name: `${isEdit ? '编辑' : '查看'}计划` }];
  const breadcrumbRoutes = useBreadcrumbRoutes();
  // 计划分配列表
  const [energyPlanDistributionList, setEnergyPlanDistributionList] = useState<EnergyPlanDetailListItem[]>([]);
  // 被选中的计划详情的id
  const [selectedEnergyPlanDetailId, setSelectedEnergyPlanDetailId] = useState<number>();
  // 被选中的计划详情
  const [selectedEnergyPlanDetail, setSelectedEnergyPlanDetail] = useState<EnergyPlanDetailListItem>();
  // 是否编辑计划详情
  const [isEditPlanDetail, setIsEditPlanDetail] = useState<boolean>(false);
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  // 预览按钮
  const has_B_ENERGY_PLAN_VIEW = useHasPermission(PermissionsType.B_ENERGY_PLAN_VIEW);
  const [planYear, setPlanYear] = useState<string>();

  useEffect(() => {
    if (isNil(energyPlanId)) return;
    // 获取用能计划详细信息
    getEnergyPlanDetail(Number(energyPlanId)).then(res => {
      if (isNil(res)) return;
      setPlanYear(res.energyYear);
    });
  }, [energyPlanId]);
  return (
    <Wrapper className={styles.editPlanWrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 20px 8px 20px' }}>
        <FormTitle title={`${isEdit ? '编辑' : '查看'}计划`}></FormTitle>
        <Space size={8}>
          {has_B_ENERGY_PLAN_VIEW && (
            <Button
              onClick={() => {
                setPreviewModalOpen(true);
              }}
            >
              预览
            </Button>
          )}

          <Button
            onClick={() => {
              window.open('/energy/analysis/plustekEnergyPlan', '_self');
            }}
          >
            返回
          </Button>
        </Space>
      </div>

      <div className={styles.planContent}>
        <LeftPlanList
          setEnergyPlanDistributionList={setEnergyPlanDistributionList}
          setSelectedEnergyPlanDetail={setSelectedEnergyPlanDetail}
          selectedEnergyPlanDetailId={selectedEnergyPlanDetailId}
          setSelectedEnergyPlanDetailId={setSelectedEnergyPlanDetailId}
          isEditPlanDetail={isEditPlanDetail}
          setIsEditPlanDetail={setIsEditPlanDetail}
          setIsSave={setIsSave}
          isEdit={isEdit}
        />
        <div className={styles.divider} />
        <div className={styles.rightStyle}>
          {selectedEnergyPlanDetailId ? (
            <RightPlanContent
              selectedEnergyPlanDetail={selectedEnergyPlanDetail}
              selectedEnergyPlanDetailId={selectedEnergyPlanDetailId}
              energyPlanDistributionList={energyPlanDistributionList}
              isEditPlanDetail={isEditPlanDetail}
              setIsEditPlanDetail={setIsEditPlanDetail}
              isSave={isSave}
              setIsSave={setIsSave}
              isEdit={isEdit}
            />
          ) : (
            <Empty style={{ width: '100%', marginTop: '10%' }} />
          )}
        </div>
      </div>
      {previewModalOpen && (
        <PreviewModal
          planYear={planYear}
          energyPlanId={isNil(energyPlanId) ? undefined : Number(energyPlanId)}
          open={previewModalOpen}
          setOpen={setPreviewModalOpen}
        />
      )}
    </Wrapper>
  );
};
export default EditPlan;
