import { getImportLogById, ImportLogPageRes, ImportLogState } from '@/api/electricitySettlementRules';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, getRealUrl, Key, Modal } from '@maxtropy/components';
import { Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { extname } from '@/shared/utils/utils';
import { useEffect, useState, useRef } from 'react';
import styles from './index.module.scss';

interface Props {
  batchEdgeOpen: boolean;
  setBatchEdgeOpen: (v: boolean) => void;
  refresh: () => void;
}

const fileSize = 50 * 1024 * 1024;

const BatchEdge: React.FC<Props> = ({ batchEdgeOpen, setBatchEdgeOpen, refresh }) => {
  const [importState, setImportState] = useState<ImportLogState>();
  const [importRes, setImportRes] = useState<ImportLogPageRes>();
  const [importing, setImporting] = useState<boolean>(false);
  const [logId, setLogId] = useState<Key>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (logId) {
        getImportLogById(logId).then(res => {
          setImportRes(res);
          setImportState(res.state);
        });
      }
    }, 1000);
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [logId]);

  useEffect(() => {
    if (importState && importState !== ImportLogState.IMPORTING) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setImporting(false);
    }
  }, [importState]);

  const beforeUploadFile = (file: RcFile) => {
    const { name } = file;
    const extName = extname(name);
    const limitFileSize = file.size <= fileSize;
    const limitFileType = extName === '.xlsx' || extName === '.xls';
    if (!limitFileSize) {
      Modal.warning({
        content: `上传文件的大小不得超过50M`,
      });
      return false;
    }
    if (!limitFileType) {
      Modal.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };

  const onCancel = () => {
    if (importing) {
      Modal.confirm({
        title: '正在导入中，关闭后请到批量修改日志页面中查看。',
        onOk: () => {
          setLogId(undefined);
          setBatchEdgeOpen(false);
        },
      });
      return;
    }
    setLogId(undefined);
    setBatchEdgeOpen(false);
  };

  return (
    <Modal
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      title="批量修改操作"
      onCancel={onCancel}
      open={batchEdgeOpen}
      footer={null}
      afterClose={() => {
        setImportState(undefined);
        setImporting(false);
        setImportRes(undefined);
        refresh();
      }}
    >
      <p>
        <ExclamationCircleOutlined style={{ color: `var(--primary-color)`, padding: 6 }} />
        请注意，在批量修改价格之前，要在本月核查联生成之后进行批量修改价格，下个月的核查联会以批量修改的价格进行结算，若在本月核查联
        未生成之后进行批量修改价格，本月的核查联会以批量修改的价格进行结算。
      </p>
      <p style={{ fontWeight: 1000 }}>根据筛选条件导出结算规则的电度电价等数据表格，修改相关数据后再进行导入操作；</p>
      <Upload
        action="/api/v2/sale-electricity/settlement-rule/import"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        maxCount={1}
        beforeUpload={beforeUploadFile}
        showUploadList={{
          showRemoveIcon: false,
        }}
        disabled={importing}
        onChange={v => {
          if (v.file.status === 'uploading') {
            setImporting(true);
            setImportState(undefined);
            setImportRes(undefined);
            setLogId(undefined);
          }
          if (v.file.status === 'done') {
            setLogId(v.file.response);
          }
        }}
      >
        <Button type="primary">选择导入文件</Button>
      </Upload>
      <div className={styles.import}>
        {!importState ? null : importState === ImportLogState.IMPORTING ? (
          <p>
            <Spin />
            正在导入中
          </p>
        ) : importState === ImportLogState.EXCEL_ERROR ? (
          <p>导入的表格错误</p>
        ) : (
          <p>
            导入成功{importRes?.successCount}条，导入失败{importRes?.failureCount}条。
            {importState === ImportLogState.ALL_SUCCESS ? null : (
              <Button
                type="link"
                onClick={() => {
                  window.open(getRealUrl(importRes?.failureExcelSource));
                }}
              >
                下载错误文件
              </Button>
            )}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default BatchEdge;
