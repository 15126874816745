import DragResize from '@/components/DragResize';
import { LeftOutlined, ReloadOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';

import {
  Button,
  Checkbox,
  EllipsisSpan,
  Empty,
  Modal,
  Paging,
  Radio,
  Select,
  Table,
  Themes,
  useAsync,
  useBreadcrumbRoutes,
  useCurrent,
  usePaging,
  useTheme,
  Wrapper,
} from '@maxtropy/components';
import { Col, Divider, Layout, Row, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { Key, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataPropertyType } from '@/shared/types';
import {
  Device,
  DevicePoint,
  exportHistoryData,
  FrequencyType,
  getDeviceCompany,
  getDevicePoints,
  getHistoryData,
  getRawChartData,
  getRawPageData,
  getRawStrPageData,
  MeasurementType,
  RawData,
  RawPageDataResponse,
} from '../../api/history';
import DateSwitch, {
  DatePickerType,
  getColumnConfig,
  getTimeDistance,
  getTsRange,
  OriginalType,
  RangePickerValue,
  StatisticsPartition,
} from './components/DateSwitch';
import DeviceTree, { specialCategoryList } from './components/DeviceTree';
import HistoryChart, { HistoryOriginalChart } from './components/HistoryChart';
import HistoryTab from './components/HistoryTab';
import styles from './index.module.scss';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  apiV2DataPropertyNonModelListByDeviceIdPost,
  apiV2HistoryDataNonModelListPost,
  apiV2HistoryDataNonModelPagePost,
} from '@maxtropy/device-customer-apis-v2';
import qs from 'qs';

const CheckboxGroup = Checkbox.Group;

const { Sider, Content } = Layout;

function getDevicePointDisplay(point: DevicePoint, value: Key, mode: StatisticsPartition) {
  if (value === null || value === undefined) {
    return value;
  }
  switch (point.type) {
    case DataPropertyType.YC:
      if (mode === OriginalType.ORIGINAL) {
        // 原始数据，无论是图表还是列表状态，都不限制数据的小数位数
        return value as number;
      }
      return (value as number).toFixed(2);

    case DataPropertyType.ENUM:
      if (!point.enumValue) {
        return '出现了点异常，当前枚举值未找到';
      }
      return point.enumValue[value as number]
        ? `${point.enumValue[value as number]}(${value})`
        : '出现了点异常，当前枚举值未找到';
    case DataPropertyType.STRING:
      return value;
    default:
      return value;
  }
}

enum DataType {
  EP = 1, // 电量
  PDMD = 2, // 最大需量
  LOAD_RATE = 3, // 负载率
  COSQ = 4, // 功率因数
  P = 5, // 有功功率
  U = 6, // 相电压
  LINE_VOLTAGE = 7, // 线电压
  I = 8, // 电流
  T = 9, // 线缆温度
  F = 11, //频率
}

function getActivePointName(dataType: DataType) {
  switch (dataType) {
    case DataType.U:
      return 'A相电压';
    case DataType.LINE_VOLTAGE:
      return 'AB线电压';
    case DataType.I:
      return 'A相电流';
    default:
      return '';
  }
}

// const routes = [
//   { name: '数据中心' },
//   { name: '历史数据' },
//   { name: '设备历史数据' },
// ];

enum PointTypeEnum {
  MODEL_POINT = 1,
  NONE_MODEL_POINT = 2,
}

export enum PageModeType {
  CHART = 'chart',
  TABLE = 'table',
}

export const partitionToQuery = {
  [DatePickerType.DAY]: FrequencyType.DAY,
  [DatePickerType.MONTH]: FrequencyType.MONTH,
  [DatePickerType.YEAR]: FrequencyType.YEAR,
  [OriginalType.ORIGINAL]: FrequencyType.ORIGINAL,
  [OriginalType.ORIGINALENUM]: FrequencyType.ORIGINAL,
};

interface EffectValue {
  activePoint?: DevicePoint;
  dateMode?: StatisticsPartition;
  rangePickerValue?: RangePickerValue;
  pageMode?: PageModeType;
}

const History: React.FC = () => {
  const current = useCurrent();
  const tenantMcid = current?.tenant?.mcid;
  const navigate = useNavigate();
  const activeKey = new URLSearchParams(window.location.search).get('activeKey');
  // 页面跳转来源
  const pageFrom = new URLSearchParams(window.location.search).get('from');

  const urlSearchParams = new URLSearchParams(window.location.search);
  const dataType = urlSearchParams.get('dataType') || undefined;
  const dataPropertyId = urlSearchParams.get('dataPropertyId') || undefined;
  const alarmTimeFromUrl = urlSearchParams.get('alarmTime') || undefined;
  const dateModeUrl = urlSearchParams.get('dateMode') || undefined;

  const deviceId = Number(urlSearchParams.get('deviceId')) || undefined;

  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [pageMode, setPageMode] = useState<PageModeType>(PageModeType.CHART);
  const [companyId, setCompanyId] = useState<number>();
  // const [deviceId, setDeviceId] = useState<number>();
  const [dateMode, setDateMode] = useState<StatisticsPartition>(
    dateModeUrl ? (dateModeUrl as DatePickerType | StatisticsPartition) : DatePickerType.DAY
  );
  const [rangePickerValue, setRangePickerValue] = useState<RangePickerValue>();
  const [activePoint, setActivePoint] = useState<DevicePoint>();
  const [titlePath, setTitlePath] = useState<string[]>([]);

  const [pointType, setPointType] = useState<PointTypeEnum>(PointTypeEnum.MODEL_POINT);

  // const [activePointIndex, setActivePointIndex] = useState<number>(0);
  const theme = useTheme();
  const ref = useRef<DevicePoint>();

  const setDeviceId = (id: number) => {
    const params = qs.stringify(
      {
        deviceId: id,
        activeKey: activeKey,
        specialCategoryId: specialCategoryId,
      },
      { addQueryPrefix: true }
    );
    navigate(`/data/history/device${params}`, {
      replace: true,
    });
  };

  const specialCategoryId = Number(urlSearchParams.get('specialCategoryId')) || undefined;

  const routes = useBreadcrumbRoutes();

  const [specialDevices, setSpecialDevices] = useState<Device[]>([]);

  // 枚举变量-仅查看变化时刻的值
  const [showChangeValue, setShowChangeValue] = useState<boolean>(true);

  useEffect(() => {
    setRangePickerValue(getTimeDistance(dateMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alarmTimeFromUrl) {
      let value = activePoint?.type === DataPropertyType.YC ? OriginalType.ORIGINAL : OriginalType.ORIGINALENUM;
      setDateMode(value);
      setRangePickerValue([dayjs(+alarmTimeFromUrl), dayjs(+alarmTimeFromUrl)]);
    }
  }, [alarmTimeFromUrl, activePoint]);
  // fix: 将多个依赖收集到一起，通过xx变化触发Effect
  const effectChange = useCallback((value: EffectValue) => {
    if (value.activePoint !== undefined) {
      setActivePoint(value.activePoint);
      ref.current = value.activePoint;
    }
    if (value.dateMode !== undefined) {
      setDateMode(value.dateMode);
    }
    if (value.rangePickerValue !== undefined) {
      setRangePickerValue(value.rangePickerValue);
    }
    if (value.pageMode !== undefined) {
      setPageMode(value.pageMode);
    }
    setPageOffset(1);
    forceUpdate();
  }, []);

  const onPointChange = (point?: DevicePoint) => {
    setTableData([]);
    setHistoryData([]);
    setChartData([]);
    if (point) {
      setPageOffset(1);
      const effectValue: EffectValue = {
        activePoint: point,
      };
      // 判断是否为枚举类型
      if (point.type === DataPropertyType.ENUM || point.type === DataPropertyType.STRING) {
        if (dateMode !== OriginalType.ORIGINALENUM) {
          effectValue.pageMode = PageModeType.TABLE;
          effectValue.dateMode = OriginalType.ORIGINALENUM;
          effectValue.rangePickerValue = getTimeDistance(OriginalType.ORIGINALENUM);
        }
      } else {
        if (dateMode === OriginalType.ORIGINALENUM) {
          effectValue.dateMode = OriginalType.ORIGINAL;
          effectValue.rangePickerValue = getTimeDistance(OriginalType.ORIGINAL);
        }
      }
      effectChange(effectValue);
    }
  };

  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const fetchCompanyRequest = useCallback(async () => {
    const response = await getDeviceCompany();
    if (response.list) {
      setCompanyId(response.list?.[0]?.id);
      return response;
    }
    return undefined;
  }, []);

  useAsync(fetchCompanyRequest);

  const [devicePoints, setDevicePoints] = useState<DevicePoint[]>([]);

  useEffect(() => {
    if (deviceId) {
      // 建模点tab列表
      if (pointType === PointTypeEnum.MODEL_POINT) {
        getDevicePoints(deviceId).then(res => {
          if (res.list) {
            const devicePoint = (res.list ?? []).find(item => item.id === Number(activeKey));
            onPointChange(devicePoint || res.list?.[0]);
            setDevicePoints(res.list ?? []);
          }
        });
      }
      // 非建模点tab列表
      else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
        apiV2DataPropertyNonModelListByDeviceIdPost({
          id: deviceId,
        }).then(res => {
          const list = res.list ?? [];
          const newDevicePoints = list
            .map(item => {
              return {
                id: item.id! as any,
                name: item.identifier!,
                measurementType: MeasurementType.INSTANT,
              };
            })
            .sort((a, b) => a.name.localeCompare(b.name));
          setDevicePoints(newDevicePoints);
          const devicePoint = newDevicePoints.find(item => item.id === activeKey);
          onPointChange(devicePoint || newDevicePoints[0]);
        });
      }
    } else {
      setActivePoint(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, pointType]);

  useEffect(() => {
    if (pageFrom === 'deviceDetail') {
      // 从设备详情跳转过来
      setDateMode(OriginalType.ORIGINAL);
    }
  }, [pageFrom]);

  useEffect(() => {
    if (dataType && devicePoints.length > 0) {
      const name = getActivePointName(Number(dataType));
      effectChange({
        activePoint: devicePoints.find(i => i.name === name),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType, devicePoints]);

  useEffect(() => {
    if (dataPropertyId && devicePoints.length > 0) {
      const point = devicePoints?.find(item => `${item.id}` === dataPropertyId);
      onPointChange(point);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, devicePoints]);

  // 按日按月按年的历史数据
  const [historyData, setHistoryData] = useState<RawData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setHistoryLatestData = (id: number, data: RawData[]) => {
    if (ref.current && ref.current.id === id) {
      setHistoryData(data);
    }
  };

  useEffect(() => {
    if (
      rangePickerValue &&
      deviceId &&
      activePoint &&
      activePoint.type === DataPropertyType.YC &&
      dateMode !== OriginalType.ORIGINAL &&
      dateMode !== OriginalType.ORIGINALENUM
    ) {
      setIsLoading(true);
      const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
      const activePointId = activePoint.id;
      getHistoryData({
        deviceId,
        propertyId: activePointId,
        startTime: tsStart,
        endTime: tsEnd,
        frequency: partitionToQuery[dateMode],
        measurementType: activePoint.measurementType,
      }).then(response => {
        setHistoryLatestData(activePointId, response.list || []);
        // setHistoryData(response);
        setIsLoading(false);
      });
    } else {
      setHistoryData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xx]);

  // 原始数据表格
  const [tableData, setTableData] = useState<RawData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const setTableLatestData = (id: number, response: RawPageDataResponse) => {
    if (ref.current && ref.current.id === id) {
      if (response) setTotalCount(response.total);
      setTableData(response.list);
    }
  };

  useEffect(() => {
    // 建模点
    if (pointType === PointTypeEnum.MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.TABLE &&
        (dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM)
      ) {
        setTableLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        const query = {
          page: pageOffset,
          size: pageSize,
          deviceId,
          propertyId: activePointId,
          startTime: tsStart,
          endTime: tsEnd,
          type: activePoint.type!,
        };
        if (activePoint.type === DataPropertyType.STRING) {
          getRawStrPageData(query).then(page => {
            setTableLatestData(activePointId, page);
            setTableLoading(false);
          });
        } else {
          // 枚举类型的时候，传入`仅查看变化时刻的值`参数
          if (activePoint.type === DataPropertyType.ENUM) {
            Object.assign(query, { filter: showChangeValue });
          }
          getRawPageData(query).then(page => {
            setTableLatestData(activePointId, page);
            setTableLoading(false);
          });
        }
      } else {
        setTableData([]);
      }
    }
    // 非建模点
    else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.TABLE &&
        (dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM)
      ) {
        setTableLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        apiV2HistoryDataNonModelPagePost({
          id: String(activePointId),
          from: tsStart,
          to: tsEnd,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          setTableLatestData(activePointId, res as RawPageDataResponse);
          setTableLoading(false);
        });
      }
    }
  }, [pageOffset, pageSize, setTotalCount, xx]);

  // 原始数据图表
  const [chartData, setChartData] = useState<RawData[]>([]);
  const [chartLoading, setChartLoading] = useState<boolean>(false);

  const setChartLatestData = (id: number, data: RawData[]) => {
    if (ref.current && ref.current.id === id) {
      setChartData(data);
    }
  };

  useEffect(() => {
    // 建模点
    if (pointType === PointTypeEnum.MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.CHART &&
        dateMode === OriginalType.ORIGINAL
      ) {
        setChartLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        getRawChartData({
          deviceId,
          propertyId: activePointId,
          startTime: tsStart,
          endTime: tsEnd,
        }).then(response => {
          setChartLatestData(activePointId, response.list || []);
          setChartLoading(false);
        });
      } else {
        setChartData([]);
      }
    }
    // 非建模点
    else if (pointType === PointTypeEnum.NONE_MODEL_POINT) {
      if (
        rangePickerValue &&
        deviceId &&
        activePoint &&
        pageMode === PageModeType.CHART &&
        dateMode === OriginalType.ORIGINAL
      ) {
        setChartLoading(true);
        const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
        const activePointId = activePoint.id;
        apiV2HistoryDataNonModelListPost({
          id: String(activePointId),
          from: tsStart,
          to: tsEnd,
        }).then(res => {
          setChartLatestData(activePointId, res.list as RawData[]);
          setChartLoading(false);
        });
      }
    }
  }, [xx]);

  const handleRangePickerChange = (value: RangePickerValue) => {
    effectChange({
      rangePickerValue: value,
    });
  };

  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const columnConfig = getColumnConfig(dateMode);

  const columns = [
    {
      title: columnConfig.name,
      dataIndex: 'time',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v ? dayjs(v).format(columnConfig.dateFormat) : null} />,
    },
    {
      title: activePoint?.name,
      dataIndex: 'value',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={getDevicePointDisplay(activePoint!, v, dateMode)} />,
    },
  ];

  const renderTableContent = () => {
    const isOriginal = dateMode === OriginalType.ORIGINAL || dateMode === OriginalType.ORIGINALENUM;
    return (
      <div>
        <div className={styles.unit}>单位：{activePoint?.generalName ?? '-'}</div>
        <Table
          loading={isOriginal ? tableLoading : isLoading}
          dataSource={isOriginal ? tableData : historyData}
          columns={columns}
          rowKey="time"
        />
        {isOriginal && <Paging pagingInfo={pagingInfo} />}
      </div>
    );
  };

  const renderChart = () => {
    if (dateMode === OriginalType.ORIGINALENUM) {
      return null;
    }
    const isOriginal = dateMode === OriginalType.ORIGINAL;
    if (isOriginal) {
      return (
        <HistoryOriginalChart key={activePoint?.id} loading={chartLoading} data={chartData} activePoint={activePoint} />
      );
    } else {
      return (
        <HistoryChart
          key={activePoint?.id}
          loading={isLoading}
          data={historyData}
          activePoint={activePoint}
          mode={dateMode as DatePickerType}
        />
      );
    }
  };

  const [openSider, setOpenSider] = useState(true);

  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const exportBtn = () => {
    if (deviceId && checkedList.length > 0 && rangePickerValue) {
      const { tsStart, tsEnd } = getTsRange(rangePickerValue, dateMode);
      exportHistoryData({
        deviceId,
        timeSolution: partitionToQuery[dateMode],
        propertyId: checkedList as number[],
        startTime: tsStart,
        endTime: tsEnd,
        type: String(pointType),
      });
    }
  };
  // 非原始数据, 枚举值disabled
  const checkDataList = useMemo(() => {
    return devicePoints.map(item => {
      return {
        ...item,
        disabled:
          ![OriginalType.ORIGINALENUM, OriginalType.ORIGINAL].includes(dateMode as any) &&
          item.type === DataPropertyType.ENUM,
      };
    });
  }, [devicePoints, dateMode]);

  useEffect(() => {
    if (showExportModal && activePoint?.id) {
      let activeId = activePoint?.id;
      let filters = checkDataList.filter(item => !item.disabled && item.id === activeId);
      setCheckedList(filters.map(item => item.id));
    }
  }, [activePoint, showExportModal]);
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? checkDataList.filter(item => !item.disabled).map(item => item.id) : []);
  };
  const checkAll = checkDataList.filter(item => !item.disabled).length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < checkDataList.filter(item => !item.disabled).length;
  const exportBtnIsDisabled = useMemo(() => {
    return !(deviceId && devicePoints.length > 0 && rangePickerValue);
  }, [deviceId, devicePoints, rangePickerValue]);

  return (
    <Wrapper className={styles.wrapperContent} routes={routes?.routes}>
      <Layout className={styles.container}>
        <Sider
          className={styles.sider}
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', marginRight: openSider ? 10 : 0 }}
          theme="light"
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <DeviceTree
            key={`${tenantMcid}-${companyId}`}
            selectDevice={(key, path) => {
              setTitlePath(path);
              setSpecialDevices([]);
            }}
            selectCategory={(key, path, devices) => {
              setTitlePath(path);
              setSpecialDevices(devices);
            }}
            tenantMcid={tenantMcid}
            companyId={companyId}
          />
        </Sider>
        <Content className={styles.content}>
          <div
            className={`${styles.toggleBtn} ${theme === Themes.DARK ? styles.darkToggleBtn : styles.lightToggleBtn}`}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>

          <div className={styles.topFilter}>
            <Radio.Group
              buttonStyle="solid"
              value={pointType}
              onChange={(e: any) => {
                setPointType(e.target.value);
                if (e.target.value === PointTypeEnum.NONE_MODEL_POINT) {
                  setDateMode(OriginalType.ORIGINAL);
                }
                setRangePickerValue(getTimeDistance(OriginalType.ORIGINAL));
              }}
            >
              <Radio.Button value={PointTypeEnum.MODEL_POINT}>查看建模点数据</Radio.Button>
              <Radio.Button value={PointTypeEnum.NONE_MODEL_POINT}>查看非建模点数据</Radio.Button>
            </Radio.Group>
            {specialDevices.length > 0 && (
              <div
                style={{
                  marginLeft: 20,
                  display: 'inline-block',
                }}
              >
                <span>{specialCategoryList.find(item => item.id === Number(specialCategoryId))?.label}：</span>
                <Select
                  style={{
                    width: 300,
                  }}
                  showSearch
                  filterOption={(input, option) => {
                    return (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  options={specialDevices.map(item => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  value={deviceId}
                  onChange={(value: number) => {
                    setDeviceId(value);
                  }}
                ></Select>
              </div>
            )}
          </div>

          {/*属性列表为空时，不显示tab栏*/}
          {!!devicePoints?.length && (
            <HistoryTab
              activeKey={activePoint ? `${activePoint.id}` : ''}
              onTabChange={key => {
                const pointIndex = (devicePoints ?? []).findIndex(item => `${item.id}` === key);
                const activeIndex = pointIndex !== -1 ? pointIndex : 0;
                const params = qs.stringify(
                  {
                    deviceId,
                    activeKey: key,
                    specialCategoryId: specialCategoryId,
                  },
                  { addQueryPrefix: true }
                );
                navigate(`/data/history/device${params}`, {
                  replace: true,
                });
                // setActivePointIndex(activeIndex);
                onPointChange(devicePoints[activeIndex]);
              }}
              tabData={(devicePoints || []).map(item => ({ key: `${item.id}`, name: item.name }))}
              extral={
                <Button
                  type="primary"
                  disabled={exportBtnIsDisabled}
                  onClick={() => setShowExportModal(true)}
                  icon={<UploadOutlined />}
                >
                  导出
                </Button>
              }
            />
          )}

          {activePoint ? (
            <Spin spinning={!activePoint}>
              {/*属性列表为空时，不显示选择框*/}
              {!!devicePoints?.length && (
                <div className={styles.toolBar}>
                  <div className={styles.filter}>
                    <div className={styles.title}>
                      {titlePath.map((item, index) =>
                        index === titlePath.length - 1 ? (
                          <span className={styles.highTxt} key={index}>
                            {item}
                          </span>
                        ) : (
                          <span key={index}>{item}/</span>
                        )
                      )}
                    </div>
                    <div className={styles.formItem}>
                      <span className={styles.label}>查询维度</span>
                      <Radio.Group
                        value={dateMode}
                        onChange={(e: RadioChangeEvent) => {
                          effectChange({
                            dateMode: e.target.value,
                            rangePickerValue: getTimeDistance(e.target.value),
                          });
                        }}
                      >
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.DAY}>
                          日
                        </Radio>
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.MONTH}>
                          月
                        </Radio>
                        <Radio disabled={activePoint?.type !== DataPropertyType.YC} value={DatePickerType.YEAR}>
                          年
                        </Radio>
                        <Radio
                          value={
                            pointType === PointTypeEnum.MODEL_POINT
                              ? // 建模点
                                activePoint?.type === DataPropertyType.YC
                                ? OriginalType.ORIGINAL
                                : OriginalType.ORIGINALENUM
                              : // 非建模点
                                OriginalType.ORIGINAL
                          }
                        >
                          原始数据
                        </Radio>
                      </Radio.Group>
                    </div>
                    <DateSwitch
                      mode={dateMode}
                      rangePickerValue={rangePickerValue}
                      handleRangePickerChange={handleRangePickerChange}
                    />

                    {activePoint.type === DataPropertyType.ENUM && (
                      <Checkbox
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 20,
                        }}
                        checked={showChangeValue}
                        onChange={e => {
                          setShowChangeValue(e.target.checked);
                          effectChange({});
                        }}
                      >
                        仅查看变化时刻的值
                      </Checkbox>
                    )}
                  </div>

                  <Space className={styles.toolBarRight}>
                    {(activePoint.type === DataPropertyType.YC || pointType === PointTypeEnum.NONE_MODEL_POINT) && (
                      <Button
                        onClick={() => {
                          if (pageMode === PageModeType.CHART) {
                            effectChange({
                              pageMode: PageModeType.TABLE,
                            });
                          } else if (pageMode === PageModeType.TABLE) {
                            effectChange({
                              pageMode: PageModeType.CHART,
                            });
                          }
                        }}
                      >
                        切换{pageMode === PageModeType.TABLE ? '图表' : '列表'}视图
                      </Button>
                    )}
                    <Button type="link" icon={<ReloadOutlined />} onClick={() => forceUpdate()}>
                      刷新数据
                    </Button>
                  </Space>
                </div>
              )}

              <div className={styles.main}>
                {pageMode === PageModeType.TABLE ? renderTableContent() : renderChart()}
              </div>
            </Spin>
          ) : (
            <div className={styles.emptyWrap}>
              <Empty description={'暂无数据'} />
            </div>
          )}
        </Content>
      </Layout>
      {showExportModal && (
        <Modal
          title="选择导出数据"
          open={true}
          onOk={() => {
            if (!checkedList.length) {
              Modal.error({
                title: '导出失败',
                content: '所选择的数据属性均为空，请重新选择！',
              });
              return;
            }
            exportBtn();
          }}
          onCancel={() => {
            setShowExportModal(false);
            setCheckedList([]);
          }}
        >
          <>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              全选
            </Checkbox>
            <Divider style={{ margin: '12px 0' }} />
            <div style={{ maxHeight: 500, overflowY: 'auto' }}>
              <CheckboxGroup value={checkedList} onChange={onChange}>
                <Row>
                  {(checkDataList || []).map(item => {
                    return (
                      <Col span={24} key={item.id}>
                        <div style={{ paddingBottom: 8 }}>
                          <Checkbox disabled={item.disabled} value={item.id}>
                            <Space size={8}>
                              <span>{item.name}</span>
                              <span style={{ fontSize: 12, color: 'red' }}>
                                {item.disabled ? '该类型数据只支持导出"原始数据"维度数据！' : ''}
                              </span>
                            </Space>
                          </Checkbox>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </CheckboxGroup>
            </div>
          </>
        </Modal>
      )}
    </Wrapper>
  );
};

export default History;
