import React from 'react';
import { Button, Form, Modal, Select } from '@maxtropy/components';
import { Space } from 'antd';
import { useRequest } from 'ahooks';
import { getTopologySelectList } from '@/api/quality-monitor';
import { isEmpty } from 'lodash-es';

interface Props {
  close: () => void;
}
const DownLoadTemplateModal: React.FC<Props> = ({ close }) => {
  const [form] = Form.useForm();

  const handleDownload = async () => {
    const params = await form.validateFields();
    const { ids } = params;
    const urlIds = ids.map((id: number) => `ids=${id}`).join('&');
    window.open('/api/v2/indicator/download?' + urlIds, '_blank');

    close();
  };

  const { data: topologySelectList } = useRequest(() =>
    getTopologySelectList().then(res => {
      if (isEmpty(res)) return [];
      return res.map(item => ({ label: item.name, value: item.id }));
    })
  );

  return (
    <>
      <Modal
        contentClassName="modal-form-content"
        title="下载模板"
        open
        onCancel={close}
        footer={
          <>
            <Space size={8}>
              <Button onClick={close}>取消</Button>
              <Button type="primary" onClick={handleDownload}>
                下载
              </Button>
            </Space>
          </>
        }
      >
        <Form form={form}>
          <Form.Item name="ids" label="选择拓扑" rules={[{ required: true, message: '请选择拓扑' }]}>
            <Select placeholder="请选择" mode="multiple" options={topologySelectList} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DownLoadTemplateModal;
