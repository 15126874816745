import { Spin } from 'antd';
import styles from './index.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { Empty, Key, Tree } from '@maxtropy/components';
import { DataNode } from 'antd/lib/tree';
import { apiV2CarbonFootprintLcaTreePost } from '@maxtropy/device-customer-apis-v2';
interface Props {
  onTreeSelect: (v: Key[]) => void;
  selectedKeys: Key[];
  setSelectedKeys: (v: Key[]) => void;
  expandedKeys: Key[];
  setExpandedKeys: (v: Key[]) => void;
  autoExpandParent: boolean;
  setAutoExpandParent: (v: boolean) => void;
  id?: string;
}
interface TreeType {
  id?: number;
  name?: string;
  parentId?: number;
  children?: TreeType[];
  isProcedure?: number;
}

function formatTree(data: TreeType[]) {
  let tree = [];
  const format = (child: any) => {
    return (child ?? []).map((i: any, index: number) => {
      return {
        key: i.id === 0 ? `${i.id}${i.parentId}${index}` : i.id,
        title: i.name,
        isProcedure: i.isProcedure,
        parentId: i.parentId,
        children: format(i?.children), // 递归所有子节点
      };
    });
  };
  tree = data?.map((i: any, index: number) => ({
    key: i.id === 0 ? `${i.id}${i.parentId}${index}` : i.id,
    title: i.name,
    isProcedure: i.isProcedure,
    parentId: i.parentId,
    children: format(i.children ?? []),
  }));
  return tree;
}

const dataList: { key: React.Key; title: string }[] = [];

const generateList = (data: DataNode[]) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key, title } = node;
    dataList.push({ key, title: title as string });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const ModelTree: React.FC<Props> = ({
  onTreeSelect,
  selectedKeys,
  setSelectedKeys,
  expandedKeys,
  setExpandedKeys,
  autoExpandParent,
  setAutoExpandParent,
  id,
}) => {
  const [data, setData] = useState<DataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2CarbonFootprintLcaTreePost({ id: Number(id) })
      .then(res => {
        const Array = [];
        Array.push(res);
        console.log(Array, 'Array');
        const dataNodes = formatTree(Array);
        setData(dataNodes);
        dataList.length = 0;
        generateList(dataNodes);
        const allDataNodeKeys = dataList.map(i => i.key);
        console.log(dataList);
        setExpandedKeys(allDataNodeKeys);
        // 默认选中第一个
        if (allDataNodeKeys && allDataNodeKeys.length > 0) {
          onTreeSelect([allDataNodeKeys[0]]);
          setSelectedKeys([allDataNodeKeys[0]]);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setExpandedKeys, id]);

  // 找parentKey
  useEffect(() => {
    if (data && data.length) {
      setExpandedKeys(dataList.map(i => i.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const treeData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <div className={styles.treeArea} style={{ width: '100%' }}>
      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data.length > 0 ? (
            <>
              <Tree
                className={styles.treeStyle}
                blockNode
                treeData={treeData}
                expandedKeys={expandedKeys}
                selectedKeys={selectedKeys}
                onExpand={v => {
                  setExpandedKeys(v);
                  setAutoExpandParent(false);
                }}
                onSelect={(v, info) => {
                  if (v[0].toString().slice(0, 1) === '0') return;
                  onTreeSelect(v);
                  setSelectedKeys(v);
                }}
                autoExpandParent={autoExpandParent}
              />
            </>
          ) : (
            <Empty style={{ marginTop: 40 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ModelTree;
